import React, { Fragment } from "react";
import BaseList from "../../BaseList";
import {
  Popconfirm,
  Icon,
  Row,
  Col,
  Button,
  Tooltip,
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";

class TemplateMsgList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns({ ...props });
  }
  setColumns = (props) => {
    this.columns = [
      {
        key: "event_name",
        title: "Event",
        dataIndex: "event_name",
        width: "50%",
        render: (data, record) => {
          return <div className="fontSize14">{data}</div>;
        },
      },
      {
        key: "count_action",
        title: "Messages Configured",
        dataIndex: "id",
        width: props.showActions === true ? "25%" : "50%",
        render: (data, record) => {
          const eventMessageCount = props.templates.filter(
            (rec) => rec.event_code === record.event_code
          ).length;
          return (
            <Tooltip
              placement="top"
              title={
                eventMessageCount > 0
                  ? `${eventMessageCount} Template(s) configured`
                  : "No Template configured."
              }
            >
              <Button className="buttonPacificGreen alignRight" size="small">
                <span style={{ fontSize: 13 }}>{eventMessageCount}</span>
              </Button>
            </Tooltip>
          );
        },
      },
    ];
    if (props.showActions === true) {
      this.columns.push({
        key: "actions",
        title: "",
        dataIndex: "id",
        width: "25%",
        className: "alignRight",
        render: (data, record) => {
          const eventMessageCount = props.templates.filter(
            (rec) => rec.event_code === record.event_code
          ).length;
          return (
            <div className="line-actions">
              {eventMessageCount > 0 ? (
                <Fragment>
                  <Tooltip placement="topLeft" title={I18n.t("templates.edit")}>
                    <Icon
                      type="edit"
                      style={{ marginLeft: 0 }}
                      theme="twoTone"
                      onClick={() => this.props.editClick(record)}
                      className="marginRight5"
                      twoToneColor="blue"
                    />
                  </Tooltip>
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t("messages.delete_confirm")}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.props.deleteClick(record)}
                  >
                    <Tooltip
                      placement="topLeft"
                      title={I18n.t("templates.delete")}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Icon
                        type="delete"
                        style={{ marginLeft: 0 }}
                        theme="twoTone"
                        twoToneColor="#FF0000"
                      />
                    </Tooltip>
                  </Popconfirm>
                </Fragment>
              ) : (
                <Fragment>
                  {this.props.isShowSave && (
                    <Tooltip
                      placement="topLeft"
                      title={I18n.t("templates.add")}
                    >
                      <Icon
                        type="plus-circle"
                        style={{ marginLeft: 0 }}
                        onClick={() => this.props.addClick(record)}
                      />
                    </Tooltip>
                  )}
                </Fragment>
              )}
            </div>
          );
        },
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default TemplateMsgList;
