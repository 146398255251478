/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import styles from '../common/mapStyle.json';

class MainMap extends React.PureComponent {
  render() {
    return (
      <GoogleMap
        ref={(c) => {
          this.props.onMapMounted(c, this.props);
        }}
        defaultOptions={{
          streetViewControl: false,
          scaleControl: true,
          mapTypeControl: false,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          zoomControl: this.props.zoomControl === true,
          fullscreenControl: this.props.fullscreenControl !== false,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_RIGHT,
          },
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          scrollwheel: true, // allow scroll wheel
          styles, // change default map styles
        }}
        {...this.props}
      >
        {
          this.props.children
        }
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MainMap));

MainMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onMapMounted: PropTypes.func,
  zoomControl: PropTypes.bool,
  fullscreenControl: PropTypes.bool,
};

MainMap.defaultProps = {
  zoomControl: false,
  fullscreenControl: true,
  onMapMounted: () => {

  },
};
