/* global google */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import Map from './Map';
import { alertMessage, isEmpty, customPasteSplit } from '../../../common/Common';
import { Row, Col, Input, FormItem, Modal, Spin, Button, Popconfirm, Radio } from '../../../common/UIComponents';
import expandIcon from '../../../../assets/images/file-expand-icon.png';
import { getZipcodes } from '../../../api/LocationsApi';
import FormButtons from '../../common/FormButtons';
import BaseModal from '../../BaseModal';
import I18n from '../../../common/I18n';
import { renderAlertMessage } from '../../../helpers/common';


class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geoFenceGeometryData: '',
      onDraw: !(props.currentZone.id && props.currentZone.polygon_coords && props.currentZone.polygon_coords.length > 0),
      currentZone: props.currentZone,
      showMaxWindow: false,
      inProgress: false,

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleReDraw = this.handleReDraw.bind(this);
  }

  componentDidMount() { }

  componentWillReceiveProps(props) {
    this.setState({
      currentZone: props.currentZone,
      onDraw: !(props.currentZone.id && props.currentZone.polygon_coords && props.currentZone.polygon_coords.length > 0),
    });
  }

  getZipcodesFromGeo = () => {
    if (this.state.currentZone.geoFenceGeometryData && this.state.currentZone.geoFenceGeometryData.length > 0) {
      const apiData = Object.assign({
        polygon_coords: this.state.currentZone.geoFenceGeometryData,
      });
      this.setState({
        inProgress: true,
      });
      getZipcodes(apiData).then((result) => {
        if (result.success) {
          const zipcodes = result.zip_codes;
          this.handleZipcodeChange('geo_zip_codes', zipcodes, zipcodes, 0);
          this.setState({
            onDraw: false,
            inProgress: false,
          });
        } else {
          this.setState({
            inProgress: false,
          });
          renderAlertMessage(result.errors)
        }
      });
    } else {
      alertMessage(`Please draw ${I18n.t('delivery_zones.geo_area')}`);
    }
  }

  handleChange(element, value) {
    this.setState({
      currentZone: Object.assign({}, this.state.currentZone, { [element]: value }),
    }, () => {
      if (element === 'isManualDraw' && !isEmpty(this.state.currentZone.radius) && window.google) {
        const geometryData = [];
        const centerSfo = new window.google.maps.LatLng(this.props.defaultCenter.lat, this.props.defaultCenter.lng);
        const radius = parseInt(this.state.currentZone.radius) * 1609.34; // miles to meter
        const spherical = window.google.maps.geometry.spherical;
        for (let i = 0; i <= 360; i += 10) {
          const point = spherical.computeOffset(centerSfo, radius, i);
          geometryData.push([point.lat(), point.lng()]);
        }
        // const circle = new window.google.maps.Circle({ radius, center: centerSfo });
        // const ne = circle.getBounds().getNorthEast();
        // const sw = circle.getBounds().getSouthWest();
        // const spherical = window.google.maps.geometry.spherical;
        // const nw = spherical.computeOffset(centerSfo, Math.sqrt(2) * radius, 315);
        // const se = spherical.computeOffset(centerSfo, Math.sqrt(2) * radius, 135);
        //
        // geometryData.push([ne.lat(), ne.lng()]);
        // geometryData.push([nw.lat(), nw.lng()]);
        // geometryData.push([sw.lat(), sw.lng()]);
        // geometryData.push([se.lat(), se.lng()]);
        this.handleSave({ geoFenceGeometryData: geometryData });
      }
    });
  }

  handleZipcodeChange = (element, value, changed, changedIndex) => {
    this.setState({
      currentZone: Object.assign({}, this.state.currentZone, { [element]: value }),
    });
  }

  handleOnSave() {
    const { currentZone } = this.state;
    if (isEmpty(currentZone.zip_codes)) {
      currentZone.zip_codes = [];
    }
    if (isEmpty(currentZone.geo_zip_codes)) {
      currentZone.geo_zip_codes = [];
    }
    if (isEmpty(currentZone.geoFenceGeometryData)) {
      currentZone.geoFenceGeometryData = [];
    }
    const errors = [];
    if (isEmpty(currentZone.name)) {
      errors.push(I18n.t('errors.field_required', { field: I18n.t('delivery_zones.name') }));
    }
    if (currentZone.zip_codes.length === 0 && currentZone.geo_zip_codes.length === 0) {
      errors.push(I18n.t('errors.fields_required', { field: `${I18n.t('general.zipcode')}s` }));
    } else {
      if (currentZone.geoFenceGeometryData.length > 0 && currentZone.geo_zip_codes.length === 0) {
        errors.push(I18n.t('errors.fields_required', { field: I18n.t('delivery_zones.geo_specific_zips') }));
      }
      if (currentZone.geo_zip_codes.length > 0 && currentZone.geoFenceGeometryData.length === 0) {
        errors.push(`Please draw ${I18n.t('delivery_zones.geo_area')}`);
      }
    }
    if (errors.length === 0) {
      this.props.handleOnSave({
        geoFenceGeometryData: this.state.geoFenceGeometryData, // this.state.onDraw ? this.state.geoFenceGeometryData : this.props.currentZone.polygon_coords,
        currentZone: this.state.currentZone,
      });
    } else {
      this.setState({
        errors,
      });
      Modal.error({
        title: 'Errors',
        content: <Fragment>
          {
            errors.map((err, index) => <Row><Col key={`err${index + 1}`} className="fontWeight500">{err}</Col></Row>)
          }
        </Fragment>,
        width: '40%',
      });
    }
  }

  handleSave(data) {
    const { geoFenceGeometryData } = data;
    this.setState({
      geoFenceGeometryData,
      onDraw: false,
      currentZone: Object.assign({}, this.state.currentZone, { geoFenceGeometryData }),
    }, () => {
      this.getZipcodesFromGeo();
    });
  }

  handleReDraw() {
    this.setState({
      onDraw: true, // !this.state.onDraw,
      geoFenceGeometryData: '',
      currentZone: Object.assign({}, this.state.currentZone, { geoFenceGeometryData: [], geo_zip_codes: [] }),
    });
  }

  showTagsWindow = (type) => {
    this.setState({
      showMaxWindow: true,
      currentZipType: type,
    });
  }

  closeTagsWindow = () => {
    this.setState({
      showMaxWindow: false,
      currentZipType: '',
    });
  }

  pasteSplit = data => customPasteSplit(data)

  render() {
    const { currentZone } = this.state;
    const zeoCodesLength = currentZone.geo_zip_codes ? currentZone.geo_zip_codes.length : 0;
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
        <Fragment>

          <div className="delivery_zone_form">
            <Row gutter={16}>
              <Col xs={8}>
                <FormItem label={I18n.t('delivery_zones.name')} require>
                  <Input
                    value={currentZone.name}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem label={I18n.t('map.drawing_mode')}>
                  <Radio.Group
                    onChange={e => this.handleChange('draw_type', e.target.value)}
                    value={currentZone.draw_type || 'manual'}
                    style={{ marginTop: 3 }}
                  >
                    <Radio value='manual'>Manual</Radio>
                    <Radio value='auto'>Radius based</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={6}>
                {currentZone.draw_type === 'auto' &&
                  <Fragment>

                    <Row>
                      <Col xs={20}>
                        <FormItem label={I18n.t('general.radius_in_meter')} require>
                          <Input
                            type="number"
                            value={currentZone.radius}
                            onChange={e => this.handleChange('radius', e.target.value)}
                            min={0}
                            precision={2}
                            style={{ width: '80%' }}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={4}>
                        <Button size="small" type="primary" className="zone-redraw marginTop25" onClick={() => this.handleChange('isManualDraw', true)}>
                          {I18n.t('map.draw')}
                        </Button>
                      </Col>
                    </Row>
                  </Fragment>
                }
              </Col>
              <Col xs={4} className="alignRight paddingRight10">
                <Popconfirm
                  placement="leftTop"
                  title={I18n.t('messages.clear_geo_confirm')}
                  onConfirm={() => this.handleReDraw()}
                  okText="Yes"
                  cancelText="No"
                  disabled={this.state.onDraw}
                >
                  <Button size="small" type="danger" className="zone-redraw marginTop20" disabled={this.state.onDraw}>
                    {I18n.t('general.clear')}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
            <Map
              handleSave={this.handleSave}
              onDraw={this.state.onDraw}
              {...this.props}
              handleChange={this.handleChange}
              handleReDraw={this.handleReDraw}
              handleOnSave={this.getZipcodesFromGeo}
              currentZone={this.state.currentZone}
              defaultCenter={this.props.defaultCenter}
              zones={this.props.zones}
            />
            <Row className="marginTop10">
              <Col xs={23} className="hideBoxes tagsInput">
                <span className="textBold">{I18n.t('delivery_zones.geo_specific_zips')}</span> {zeoCodesLength === 0 ? '' : <span className="textBold primaryText">({zeoCodesLength})</span>} :
                <TagsInput
                  value={currentZone.geo_zip_codes || []}
                  onChange={(e, changed, changedIndex) => this.handleZipcodeChange('geo_zip_codes', e, changed, changedIndex)}
                  validationRegex={/^(?=[0-9]*$)(?:.{3}|.{5})$/}
                  addKeys={[9, 13, 32]}
                  inputProps={{
                    placeholder: currentZone.geo_zip_codes && currentZone.geo_zip_codes.length === 0 ? `Draw ${I18n.t('delivery_zones.geo_specific_zips')} ` : '',
                  }}
                  onlyUnique
                  addOnPaste
                  pasteSplit={this.pasteSplit}
                  disabled
                  addOnBlur={ true }
                />
                {/* <Input.TextArea
                    value={!isEmpty(currentZone.geo_zip_codes) && currentZone.geo_zip_codes.length > 0 ? currentZone.geo_zip_codes.join(',') : ''}
                    disabled
                  />
                  */}
              </Col>
              <Col xs={1}>
                <img
                  src={expandIcon}
                  className="expandIcon"
                  onClick={() => this.showTagsWindow('geo_zip_codes')}
                  alt={I18n.t('general.expand')}
                />
              </Col>
            </Row>
            <Row className="marginTop10">
              <Col xs={23} className="tagsInput">
                <FormItem label={I18n.t('delivery_zones.other_zipcodes')}>
                  <TagsInput 
                    value={currentZone.zip_codes || []}
                    onChange={(e, changed, changedIndex) => this.handleZipcodeChange('zip_codes', e, changed, changedIndex)}
                    validationRegex={/^(?=[0-9]*$)(?:.{3}|.{5})$/}
                    addKeys={[9, 13, 32]}
                    inputProps={{
                      placeholder: 'Add a zipcode',
                    }}
                    onlyUnique
                    addOnPaste
                    pasteSplit={this.pasteSplit}
                    addOnBlur={ true }
                  />
                </FormItem>
              </Col>
              <Col xs={1}>
                <img
                  src={expandIcon}
                  className="expandIcon"
                  onClick={() => this.showTagsWindow('zip_codes')}
                  alt={I18n.t('general.expand')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {FormButtons(this.state.inProgress, this.handleOnSave, this.props.handleOnCancel)}
              </Col>
            </Row>
          </div>

          {this.state.showMaxWindow &&
            <BaseModal
              title={this.state.currentZipType === 'geo_zip_codes' ? I18n.t('delivery_zones.geo_specific_zips') : I18n.t('delivery_zones.other_zipcodes')}
              onCancel={() => this.closeTagsWindow()}
              className="surveyModal"
              width='60%'
              style={{ top: 40, overflowY: 'auto' }}
            >
              <Fragment>
                <Row gutter={16} className="tagssettingRow">

                  <Col sm={24} xs={24} md={24} lg={24} className={`overFlowAuto ${this.state.currentZipType === 'geo_zip_codes' ? 'hideBoxes' : ''}`}>
                    <TagsInput
                      value={this.state.currentZone[this.state.currentZipType] || []}
                      onChange={(e, changed, changedIndex) => this.handleZipcodeChange(this.state.currentZipType, e, changed, changedIndex)}
                      validationRegex={/^(?=[0-9]*$)(?:.{3}|.{4}|.{5})$/}
                      addKeys={[9, 13, 32]}
                      inputProps={{
                        placeholder: this.state.currentZipType === 'geo_zip_codes' ? '' : 'Add a zipcode',
                      }}
                      onlyUnique
                      addOnPaste
                      pasteSplit={this.pasteSplit}
                      disabled={this.state.currentZipType === 'geo_zip_codes'}
                      addOnBlur={ true }
                    />
                  </Col>
                </Row>
                {false &&
                  <Row>
                    <Col xs={24}>
                      {FormButtons(this.state.inProgress, this.handleDayWiseSave, this.closeDayWindow, I18n.t('general.cancel'), I18n.t('general.apply'))}
                    </Col>
                  </Row>
                }
              </Fragment>
            </BaseModal>
          }
        </Fragment>
      </Spin>
    );
  }
}
Main.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  currentZone: PropTypes.shape().isRequired,
  defaultCenter: PropTypes.shape().isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default Main;
