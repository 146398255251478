import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Tabs, Row, Col } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import CreditLimit from "../configurations/CreditLimit";
import { fetchAccount } from "../../api/Account";
import whiteArrow from '../../../assets/images/WhiteArrow.png'
import { renderAlertMessage } from "../../helpers/common";

const { TabPane } = Tabs;
class OutStandingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      accountId: props.match.params.accountId || "",
      inProgress: false,
    };
  }

  componentDidMount() {
    this.getAccountDetails()
  }

  getAccountDetails = () => {
    this.setState({ inProgress: true });
    const { accountId } = this.state;
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        this.setState({
          account: Object.assign({}, this.state.account, result.account),
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };
  
  navigateToList = () => {
    this.props.history.push("/account_out_standings")
  }

  render() {
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col span={10}>
            <img
                src={whiteArrow}
                alt="whitearrow"
                style={{
                  height: 20,
                  width: 30,
                  cursor: 'pointer',
                  marginTop: -2,
                }}
                onClick={this.navigateToList}
              />
              {I18n.t('account.credit_limit.label')} 
              {this.state.account && 
              <Fragment>
                &nbsp;of&nbsp;
                {this.state.account.name}
                {this.state.account.code ? ` (${this.state.account.code})` : ""}
              </Fragment>
              }
            </Col>
            <Col xs={14}>

            </Col>
          </Row>
          <div className="child-card-container">
            <CreditLimit account={this.state.account} />
          </div>
        </div>
      </div>
    );
  }
}

export default OutStandingDetails;
