import React from "react";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";

function ListInvoices(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      frequency_invoice_number: 1001,
      account_frequency_type: "Daily",
      wh_location_name: "Test Warehouse",
      account_id: "ABC123",
      account_code: "abc123",
      approval_date: "2022-09-01T12:00:00Z",
      customer_order_numbers: 1,
      // hawb: "123456",
      // mawb: "123456",
      // reference_date:"2022-09-15T12:00:00Z",
      frequency_invoice_amount: 99.45,
      invoice_approval_date: "2022-09-12T12:00:00Z",
      routing : "ABCD"
    },
    {
      key: 2,
      frequency_invoice_number: 1002,
      account_frequency_type: "Daily",
      wh_location_name: "Test Warehouse",
      account_id: "ABC456",
      account_code: "abc456",
      approval_date: "2022-09-03T12:00:00Z",
      customer_order_numbers: 2,
      // hawb: "123456",
      // mawb: "123456",
      // reference_date:"2022-09-21T12:00:00Z",
      frequency_invoice_amount: 102.65,
      invoice_approval_date: "2022-09-15T12:00:00Z",
      routing : "EFGH"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "invoice_number",
      title: I18n.t("account.billing.invoice_no"),
      dataIndex: "frequency_invoice_number",
    },
    {
      key: "account_frequency_type",
      title: I18n.t("account.billing.invoice_type"),
      dataIndex: "account_frequency_type",
    },
    {
      key: "wh_location_name",
      title: I18n.t("location.location"),
      dataIndex: "wh_location_name",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_id",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "billing_date",
      title: I18n.t("invoices.invoice_generated_date"),
      dataIndex: "approval_date",
      render: (data) => (
        <div>{data ? moment(data).format("MMM Do YYYY") : ""}</div>
      ),
    },
    {
      key: "orders_count",
      title: I18n.t("account.billing.orders_count"),
      dataIndex: "customer_order_numbers",
    },
    // {
    //   key: "hawb",
    //   title: I18n.t("general.hawb"),
    //   dataIndex: "hawb",
    // },
    // {
    //   key: "mawb",
    //   title: I18n.t("general.mawb"),
    //   dataIndex: "mawb",
    // },
    // {
    //   key: "reference_date",
    //   title: I18n.t("invoices.order_completion_date"),
    //   dataIndex: "reference_date",
    //   render: (data) => (
    //     <div>{data ? moment(new Date(data)).format("Do MMM YYYY") : ""}</div>
    //   ),
    // },
    {
      key: "invoice_total_amount",
      title: I18n.t("invoices.amount"),
      dataIndex: "frequency_invoice_amount",
    },
    {
      key: "invoice_approval_date",
      title: I18n.t("invoices.approved_date"),
      dataIndex: "invoice_approval_date",
      render: (data) => (
        <div>{data ? moment(data).format("MMM Do YYYY") : ""}</div>
      ),
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = 'invoice_list_columns'

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )
  
}

export default ListInvoices;
