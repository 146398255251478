import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { originFilter } from "../common/columnDefs";
import { fetchAddress, fetchLocAddress } from "../dispatches/helpers";
import { Icon, Tooltip } from "antd";
import { getDependencyOrders } from "../../helpers/preplan";
import AppConfig from "../../config/AppConfig";
import _ from "lodash";
import CustomDragIcon from '../../../images/drag_icon.svg';
import Copyable from "../common/Copyable";
import TypeOfOrder from "./TypeOfOrder";

const AssignableOrdersList = React.memo(({ orders = [], selectedOrders = [], updateKey, assignOrdersToRoute, handleRemoveOrder, isReadOnly = false, setUpdatedOrders, ordersSecHeight = "56vh"}) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: ordersSecHeight, width: "100%" }), [ordersSecHeight]);

  const originDestinationColumn = useMemo(() => ({
    headerName: "Origin -> Destination",
    field: "orgin_destination",
    isVisible: true,
    valueGetter: (params) => originFilter(params.data),
    cellRenderer: (params) => (
      <div style={{ display: "flex" }}>
        <div>{params.data.stop_id ? fetchAddress(params.data, "origin") : fetchLocAddress(params.data, "origin")}</div>
        <span>&nbsp;{" - "}&nbsp;</span>
        <div>{params.data.stop_id ? fetchAddress(params.data, "destination") : fetchLocAddress(params.data, "destination")}</div>
      </div>
    ),
  }), []);

  const [rowData, setRowData] = useState([]);
  const draggedRowIndex = useRef(null);
  const gridApiRef = useRef(null);
  const handleRemove = (order_id, location_id) => {
    handleRemoveOrder(order_id, location_id)
  }

  const columnDefs = useMemo(() => {
  const columns = [
    {
      headerName: "",
      field: "icon",
      pinned: "left",
      lockPosition: "left",
      width: 50,
      rowDrag: !isReadOnly,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      sortable: false,
      cellRendererFramework: () => <Icon type="user" />,
      cellStyle: params => {
        if (selectedOrders.includes(params.data.id)) {
          return { backgroundColor: '#607AD6' };
        } else {
          return { backgroundColor: '#AABDCD' };
        }
      }
    },
    {
      headerName: "",
      pinned: "left",
      lockPosition: "left",
      field: 'remove',
      width: 50,
      rowDrag: false,
      cellRenderer: (params) => (
        isReadOnly ? "" :
        <Tooltip title="Remove">
          <div onClick={(e) => handleRemove(params.data.id, params.data.location_id)}>
            <Icon type="minus-circle" className="fontSize20"/>
          </div>
        </Tooltip>
      )
    },
    { field: "customer_order_number", headerName: "Order #" , cellRenderer: (params) => (
      <div>{params.data.customer_order_number}{['T', 'LH'].includes(params.data.type_of_order) ? ` (${AppConfig.shortForms[params.data.order_type]})` : ''}&nbsp;<Copyable text={params.data.customer_order_number} /> </div>
    )},
    { field: "type_of_order", headerName: "Order Type", width: 132,cellRenderer:(params) => ( <TypeOfOrder
      order={params.data}
      orderTypeKey="type_of_order"
      relatedOrderKey="related_order"
     // placement="top"
      showBadge={true}
  />) },
    { field: "weight", headerName: "Weight (lbs)", width: 130 },
    { field: "hawb", headerName: "HAWB" },
    { field: "mawb", headerName: "MAWB" },
    originDestinationColumn,
  ];
  return isReadOnly ? columns.slice(2) : columns;
}, [originDestinationColumn, isReadOnly]);

  const defaultColDef = useMemo(() => ({
    width: 170,
    filter: true,
  }), []);

  const onGridReady = useCallback((params) => {
    gridApiRef.current = params.api;
    setRowData(orders);
  }, [orders]);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshCells();
    }
    setUpdatedOrders(rowData)
  }, [rowData]);

  useEffect(() => {
    if(updateKey && selectedOrders?.length > 0){
      assignOrdersToRoute(rowData)
    }
  }, [updateKey]);

  useEffect(() => {
    if (gridApiRef.current) {
      setRowData(orders);
    }
  }, [orders]);

  // const dragStopped = useCallback((ev) => {
  //   const newRowIndex = ev.node.rowIndex;
  //   if (
  //     draggedRowIndex.current !== null &&
  //     draggedRowIndex.current !== newRowIndex
  //   ) {
  //     const droppedRow = rowData[draggedRowIndex.current];
  //     const remainingRows = [...rowData];
  //     remainingRows.splice(draggedRowIndex.current, 1);
  //     remainingRows.splice(newRowIndex, 0, droppedRow);
  //     if(newRowIndex>0 && newRowIndex !== rowData.length -1){
  //       const prevRecord = remainingRows[newRowIndex-1];
  //       if(prevRecord.stop_id){
  //         const firstIndexWithSameStop = _.findIndex(remainingRows, function(o) { 
  //           return o.stop_id === prevRecord.stop_id }
  //         );
  //         const lastIndexWithSameStop = _.findLastIndex(remainingRows, function(o) { 
  //           return o.stop_id === prevRecord.stop_id }
  //         );
  //         if(lastIndexWithSameStop > 0 && firstIndexWithSameStop < newRowIndex &&  newRowIndex < lastIndexWithSameStop){
  //           remainingRows.splice(newRowIndex, 1);
  //           remainingRows.splice(firstIndexWithSameStop, 0, droppedRow);
  //         }
  //       }
  //     }

  //     if (droppedRow.stop_id) {
  //       const stopOrders = rowData.filter(row => row.id !== droppedRow.id && row.stop_id === droppedRow.stop_id);
  //       let groupOrderIndex = newRowIndex;
  //       stopOrders.forEach(order => {
  //         groupOrderIndex += 1;
  //         const presentOrderIndex = _.findIndex(remainingRows, { id: order.id });
  //         remainingRows.splice(presentOrderIndex, 1);
  //         const droppedRowIndex = _.findIndex(remainingRows, { id: droppedRow.id });
  //         remainingRows.splice(droppedRowIndex + 1, 0, order);
  //       });
  //     }

  //     if (!_.isEmpty(droppedRow.related_order)) {
  //       const dependencyItems = getDependencyOrders(
  //         rowData,
  //         "related_order",
  //         "id",
  //         droppedRow.id,
  //         "id"
  //       );
  //       let hasInCorrectOrder = false;
  //       if (dependencyItems.length > 0) {
  //         hasInCorrectOrder = dependencyItems.some((oId) => {
  //           const dependencyIndex = _.findIndex(remainingRows, { id: oId });
  //           if (
  //             AppConfig.deliveryOrderTypes.includes(droppedRow.type_of_order) &&
  //             newRowIndex < dependencyIndex
  //           ) {
  //             return true;
  //           } else if (
  //             AppConfig.pickOrderTypes.includes(droppedRow.type_of_order) &&
  //             dependencyIndex < newRowIndex
  //           ) {
  //             return true;
  //           }
  //           return false;
  //         });
  //         if (hasInCorrectOrder) {
  //           setRowData(remainingRows);
  //           setRowData(rowData);
  //         } else {
  //           setRowData(remainingRows);
  //         }
  //       } else {
  //         setRowData(remainingRows);
  //       }
  //     } else {
  //       setRowData(remainingRows);
  //     }
  //     draggedRowIndex.current = null;
  //   }
  // }, [rowData]);

  const dragStopped = useCallback(
    (ev) => {
      const newRowIndex = ev.node.rowIndex;
      if (
        draggedRowIndex.current !== null &&
        draggedRowIndex.current !== newRowIndex
      ) {
        const droppedRow = rowData[draggedRowIndex.current];
        const remainingRows = [...rowData];
        remainingRows.splice(draggedRowIndex.current, 1);
        remainingRows.splice(newRowIndex, 0, droppedRow);
        const pickupIndex = _.findIndex(remainingRows, {
          id: droppedRow.id,
          order_type: "PICKUP",
        });
        const deliveryIndex = _.findIndex(remainingRows, {
          id: droppedRow.id,
          order_type: "DELIVERY",
        });

        if (
          droppedRow.order_type === "DELIVERY" &&
          pickupIndex >= 0 &&
          pickupIndex > newRowIndex
        ) {
          setRowData(remainingRows);
          setRowData(rowData);
        } else if (
          droppedRow.order_type === "PICKUP" &&
          deliveryIndex >= 0 &&
          deliveryIndex < newRowIndex
        ) {
          setRowData(remainingRows);
          setRowData(rowData);
        } else {
          if (newRowIndex > 0 && newRowIndex !== rowData.length - 1) {
            const prevRecord = remainingRows[newRowIndex - 1];
            if (prevRecord.stop_id) {
              const firstIndexWithSameStop = _.findIndex(
                remainingRows,
                function (o) {
                  return o.stop_id === prevRecord.stop_id;
                }
              );
              const lastIndexWithSameStop = _.findLastIndex(
                remainingRows,
                function (o) {
                  return o.stop_id === prevRecord.stop_id;
                }
              );
              if (
                lastIndexWithSameStop > 0 &&
                firstIndexWithSameStop < newRowIndex &&
                newRowIndex < lastIndexWithSameStop
              ) {
                remainingRows.splice(newRowIndex, 1);
                remainingRows.splice(firstIndexWithSameStop, 0, droppedRow);
              }
            }
          }

          if (droppedRow.stop_id) {
            const stopOrders = rowData.filter(
              (row) =>
                row.id !== droppedRow.id && row.stop_id === droppedRow.stop_id
            );
            let groupOrderIndex = newRowIndex;
            stopOrders.forEach((order) => {
              groupOrderIndex += 1;
              const presentOrderIndex = _.findIndex(remainingRows, {
                id: order.id,
              });
              remainingRows.splice(presentOrderIndex, 1);
              const droppedRowIndex = _.findIndex(remainingRows, {
                id: droppedRow.id,
              });
              remainingRows.splice(droppedRowIndex + 1, 0, order);
            });
          }

          if (!_.isEmpty(droppedRow.related_order)) {
            const dependencyItems = getDependencyOrders(
              rowData,
              "related_order",
              "id",
              droppedRow.id,
              "id"
            );
            let hasInCorrectOrder = false;
            if (dependencyItems.length > 0) {
              hasInCorrectOrder = dependencyItems.some((oId) => {
                const dependencyIndex = _.findIndex(remainingRows, { id: oId });
                if (
                  AppConfig.deliveryOrderTypes.includes(
                    droppedRow.type_of_order
                  ) &&
                  newRowIndex < dependencyIndex
                ) {
                  return true;
                } else if (
                  AppConfig.pickOrderTypes.includes(droppedRow.type_of_order) &&
                  dependencyIndex < newRowIndex
                ) {
                  return true;
                }
                return false;
              });
              if (hasInCorrectOrder) {
                setRowData(remainingRows);
                setRowData(rowData);
              } else {
                setRowData(remainingRows);
              }
            } else {
              setRowData(remainingRows);
            }
          } else {
            setRowData(remainingRows);
          }
        }

        draggedRowIndex.current = null;
      }
    },
    [rowData]
  );

  const onRowDragStart = useCallback((params) => {
    draggedRowIndex.current = params.node.rowIndex;
  //  params.rowDragStartData = "Custom Select";
    console.log("params", params.node.rowIndex);
  }, []);

  const getRowStyle = useCallback((params) => {
    if (selectedOrders.includes(params.data.id)) {
      return { backgroundColor: "#eef2fb" };
    }
    return null;
  }, [selectedOrders]);

  return (
    <div style={containerStyle} className="marginTop10">
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowHeight={35}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowDragManaged={true}
          onGridReady={onGridReady}
          // icons={{
          //   menu: '<Icon type="user" />',
          // }}
          icons={{
            rowDrag: '<img src="' + CustomDragIcon + '" style="width: 18px; height: 18px;" />',
          }}
          onRowDragEnd={dragStopped}
          onRowDragEnter={onRowDragStart}
          getRowStyle={getRowStyle}
        />
      </div>
    </div>
  );
});

const checkIsEqualProp = (prevProps, nextProps, keys) => {
  return _.every(keys, (key) => _.isEqual(prevProps[key], nextProps[key]));
}

export default React.memo(AssignableOrdersList, (prevProps, nextProps) => {
  // return false;
  return checkIsEqualProp(prevProps, nextProps, [ 'orders', 'selectedOrders', 'updateKey', 'ordersSecHeight']);
})