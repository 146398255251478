/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable indent */
import React, { Fragment, useContext, useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "react-reflex/styles.css";
import { DragDropContext} from "react-beautiful-dnd";
import {
  Col,
  Drawer,
  Row,
  Spin,
  Icon,
  message,
  notification,
  Modal,
  Result,
  Button,
} from "../common/UIComponents";
import { alertMessage, randomNumber, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { fetchOrderDetails, fetchOrdersForPreplan } from "../api/OrdersApi";
import BaseModal from "../components/BaseModal";
import AppConfig from "../config/AppConfig";
import {
  autoPreplan,
  capacityValidationForRoute,
  createPreplan,
  fetchAvailableDrivers,
  fetchPreplan,
  getPreplanRouteResponse,
  updatePreplan,
} from "../api/PreplanApi";
import Assignment from "../components/Routes/PreplanAssignment";
import {
  deleteRoutes,
  fetchOptimizedStops,
  deleteOrderFromRoute,
  deleteStopFromRoute,
  updateStopsOrder,
  manageRoute,
  updateAllStopsServiceDuration,
  changeLockOfMultipleRoute,
  fetchPreplanOptimizedStops,
} from "../api/Routes";
import EditOrderForm from "../components/orders/EditOrderForm";
import {
  assignRouteInfo,
  handleRouteReponse,
  setRouteObject,
  setUsersWithRouteInfo,
  setOrderResponse,
  findDependencyStops,
  findDependencyOrders,
  checkDependancyOrderSeq,
  getDependencyOrders,
  retrieveCustomerStops,
  setRouteOrders,
  checkRouteIsEmpty,
  unlinkRouteDetailsFromUser,
  isRouteOptimized,
  _renderHeader,
  allocateToExistingRoute,
  onSearchNumbers,
  manageOrderAllocateProcess,
  handleDependencies,
  resetRouteNStop,
  takeConfirmation,
  getOptimizeInfo,
  getAddrErrOrders,
  addressResolveMsg,
  showErrorsonModal,
  checkAddressValidation,
  dependencyValidation,
  arrangeOrderByIdSeq,
  updateRouteOrder,
  updateOrderDetailsToRouteOrders,
  showUnassignedErrors,
} from "../helpers/preplan";
import {
  rearrangeStops,
  reverseStopsWithMoves,
  stopTypeFromObject,
} from "../helpers/stops";
import {  haveFreePackage, renderAlertMessage } from "../helpers/common";
import { fetchWHZones } from "../api/DeliveryZones";
import { getUniqvaluesFromArray, getValueFromArrayOfObjects } from "../helpers/array_functions";
import TimeEditWindow from "../components/preplan/TimeEditWindow";
import { fetchShortFormAccounts } from "../api/Account";
import GenerateRouteFile from "../components/preplan/GenerateRoueFile";
import { getAppointmentTimeFromSlots, getLocationIds } from "../helpers/orders";
import userStore from "../stores/UserStore";
import PreplanDrivers from "../components/preplan_management/PreplanDrivers";
import PreplanLeft from "../components/preplan_management/PreplanLeft";
import PreplanRoutesSection from "../components/preplan_management/PreplanRoutesSection";
import RouteProcessedErrors from "../components/common/RouteProcessedErrors";
import { fetchOrganizationConfigs } from "../api/Organisations";
import { findOverloadReasons } from "../helpers/capacityValidation";
// import preplanData from "../helpers/sample.json";
import ResolveMultiAddress from "./ResolveMultiAddress";
import RouteLabel from "../config/RouteLabel";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import { OrgContext } from "../context/OrgContext";
import { withDuplicateTabDetection } from "../Hocs/withDuplicateTabDetection";
import { VehicleTypeApi } from "../api/VehicleType";
import ActionCable from "../ActionCableComponent";
import { OrderConfigContext } from "../context/OrderConfigContext";
import { WarehouseContext } from "../context/WarehouseContext";

const { info } = Modal;
const PreplanRoute = (props) => {
  const displayConfiguration = useContext(DisplaySettingsContext)
const { orgSettings: organizationSettings, currentOrg } = useContext(OrgContext);
  const { orderConfig: orderSettings } = useContext(OrderConfigContext);
  const warehouseFilter = useContext(WarehouseContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'
  const [optimizationCheckAll , setOptimizationCheckAll] = useState(false);
  const [ orderInfo, setOrderInfo ] = useState([]);
  const [filterPlaceHolder , setFilterPlaceHolder] = useState({});
  const [ isZonesLoading, setIsZonesLoading ] = useState(false);
  const [ accounts, setAccounts ] = useState([]);
  const [ unassignedOrdersInfo, setUnassignedOrdersInfo ] = useState({
    selectedOrderKeys: [],
    selectedOrderRows: [],
  });
  const [ routeTrucksData, setRouteTrucksData ] = useState({
    selectedTruckKeys: "",
    selectedTruckRows: [],
  });
  const [ checkedAvailableDrivers, setCheckedAvailableDrivers ] = useState({
    selectedKeys: "",
    selectedRows: [],
  });
  const [ currentWarehouse, setCurrentWarehouse ] = useState({});
  const [ currentOrder, setCurrentOrder ] = useState({});
  const [ currentRoute, setCurrentRoute ] = useState({});
  const [ activeKey, setActiveKey ] = useState("1");
  const [ preplanId, setPreplanId ] = useState(props.match.params.preplanId || "");
  const [ routeInfo, setRouteInfo ] = useState([]);
  const [ inProgress, setInProgress ] = useState(false);
  const [ routeProgress, setRouteProgress ] = useState(false);
  const [ stopProgress, setStopProgress ] = useState(false);
  const [ orderProgress, setOrderProgress ] = useState(false);
  const [ preplanProgress, setPreplanProgress ] = useState(false);
  const [ zone_ids, setZone_ids ] = useState([]);
  const [ currentPreplan, setCurrentPreplan ] = useState({});
  const [ warehouse_id, setWarehouse_id ] = useState("");
  const [ mapData, setMapData ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const[displayCOnfiguration, setDisplayCOnfiugration] = useState({})
  const [ preferences, setPreferences ] = useState([
    {
      s_no: 1,
      item_preference: "",
      item_option: [],
    },
    {
      s_no: 2,
      item_preference: "",
      item_option: [],
    },
    {
      s_no: 3,
      item_preference: "",
      item_option: [],
    },
  ]);
  const [ driversInfo, setDriversInfo ] = useState(AppConfig.driversInfo);
  const [ assignModal, setAssignModal ] = useState(false);
  const [ assignTaskType, setAssignTaskType ] = useState("optimize");
  const [ selectedRoute, setSelectedRoute ] = useState("");
  const [ routeMouseOperation, setRouteMouseOperation ] = useState(false);
  const [ isInitial, setIsInitial ] = useState(true);
  const [ rearrangeForm, setRearrangeForm ] = useState(false);
  const [ orderType, setOrderType ] = useState("PREPLAN_ORDERS");
  const [ availableDrivers, setAvailableDrivers ] = useState([]);
  const [ showAvailableDrivers, setShowAvailableDrivers ] = useState(false);
  const [ preplanTenseTime, setPreplanTenseTime ] = useState("future");
  const [ assignInfo, setAssignInfo ] = useState({
    indeterminate: false,
    checkAll: false,
    checkedList: [],
  });
  const [ routeSelectedStops, setRouteSelectedStops ] = useState([]);
  const [ stopSelectedOrders, setStopSelectedOrders ] = useState({
    selectedKeys: [],
    selectedRows: [],
  });
  const [ preplanZones, setPreplanZones ] = useState([]);
  const [ optimizationFromMapstatus, setOptimizationFromMapstatus ] = useState([]);
  const [ currentStop, setCurrentStop ] = useState({});
  const [ moveInfo, setMoveInfo ] = useState({
    type: "",
    refs: [],
  });
  const [ totalOrders, setTotalOrders ] = useState([]);
  const [ showOnMap, setShowOnMap ] = useState(false);
  const [ selectedStopMarkerId, setSelectedStopMarkerId ] = useState("");
  const [ filter, setFilter ] = useState({
    startDate: null, // moment(moment().add(AppConfig.preplanShownDuration, "hours"),"YYYY-MM-DD"),
    endDate: null, // moment(moment().add(AppConfig.preplanShownDuration, "hours"),"YYYY-MM-DD"),
    sortBy: "none",
    accountCodes: [],
    groupBy: "none",
    search_order_token: "",
  });
  const [ processingRoute, setProcessingRoute ] = useState({});
  const [ routeKey, setRouteKey ] = useState(randomNumber());
  const [ routeView, setRouteView ] = useState("byOrders");
  const [ editRouteTimeModal, setEditRouteTimeModal ] = useState(false);
  const [ routeConfirmModal, setRouteConfirmModal ] = useState(false);
  const [ optimizationProgress, setOptimizationProgress ] = useState(false);
  const [ clickedRouteId, setClickedRouteId ] = useState("");
  const [ selectedStopCardId, setSelectedStopCardId ] = useState("");
  const [ openedStopInfoWindow, setOpenedStopInfoWindow ] = useState("");
  const [ routeProcessedErrors, setRouteProcessedErrors ] = useState([]);
  const [ editForm, setEditForm ] = useState(false);
  const [ accountsLoading, setAccountsLoading ] = useState(false);
  const [ accountCodes, setAccountCodes ] = useState([]);
  const [ orgConfig, setOrgConfig ] = useState({});
  const [ doCapacityValidation, setDoCapacityValidation ] = useState(false);
  const [showMultipleAddressResolveModal, setShowMultipleAddressResolveModal] = useState(false);
  const [madeAddressChanges, setMadeAddressChanges] = useState(false);
  const [callOrderDetails, setCallOrderDetails] = useState(false);
  const [serviceDurationReset , setServiceDurationReset] = useState(false);
  const [orderModified , setOrderModified] = useState(false);
  const [ driverZones, setDriverZones ] = useState([]);
  const [ unsavedServiceDurationChanges, setUnsavedServiceDurationChanges ] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [ orderVhTypes, setOrderVhTypes ] = useState([]);
  const [ showPreplanDeleteInfoModal, setShowPreplanDeleteInfoModal ] = useState(false);
  const [ showStopScreen, setShowStopScreen ] = useState(false);
  const [ orderTypeFilter, setOrderTypeFilter ] = useState(["ALL"]);
  const [ clusteredOrder, setClusteredOrder ] = useState([])
  const [ mapSelectedList, setMapSelectedList ] = useState([])
  const [routeLoading, setRouteLoading] = useState(false)
  const {accountsExceeded} = useContext(OrgContext);
  const accountCodesWithExceededLimit = accountsExceeded.map(account => account.account_code);

  // const { showBeta } = localStorage.getItem("preplanBetaShow") ? props.location

  // check if key is present in local storage if no key then set the key
  const [ showBeta, setShowBeta ] = useState(false);

  useEffect(() => {
    // const isRefreshed = !props.location.showBeta;
    // has key check
    const isRefreshed = !_.has(props.location, "showBeta");
    if (isRefreshed) {
      const localSvALUE = localStorage.getItem("preplanBetaLastVisited");
      setShowBeta(localSvALUE === "true");
    } else {
      if (props.location.showBeta) {
        localStorage.setItem("preplanBetaLastVisited", "true");
        setShowBeta(true);
      } else {
        localStorage.setItem("preplanBetaLastVisited", "false");
        setShowBeta(false);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (currentStop && currentStop.id) {
  //     handleCurrentStop({}, currentStop.id);
  //   }
  // }, [ currentRoute, routeSelectedStops ]);

  useEffect(() => {
    getOrgConfigs();
    getPreplanInfo();
    getAccounts();
    getVhTypes();
  }, []);

  const setMapRealtedData = (newList) => {
    const removedList = _.difference(mapSelectedList, newList);
    setMapSelectedList(orderInfo.filter(order => removedList.includes(order)));
  }



  useEffect(()=> {
    setDisplayCOnfiugration(displayConfiguration)
  },[displayConfiguration])

  useEffect(()=> {
    setOrgConfig(organizationSettings)
  }, [organizationSettings])

  useEffect(() => {
    if (selectedStopCardId !== "") {
      setSelectedStopCardId("");
    }
  }, [ selectedStopCardId ]);

  const getOrgConfigs = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    fetchOrganizationConfigs(orgId).then((result) => {
      if (result.success) {
        const config = {};
        const orgConfigurations = result.org_configurations || [];
        orgConfigurations.forEach((setting) => {
          if (
            setting.setting_name === "mobile_number_for_alerts" &&
            setting.setting_value === "false"
          ) {
            config[setting.setting_name] = "";
          } else {
            config[setting.setting_name] = setting.setting_value;
          }
        });
        setOrgConfig(config);
      }
    });
  }

  const getVhTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        setVehicleTypes(result.vehicle_types);
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  useEffect(() => {
    findCapacityValidation();
  }, [ orgConfig]);

  useEffect(() => {
    getAccounts();
  }, [warehouseFilter.warehouseWithAccounts]);

  const findCapacityValidation = () => {
    const requireValidation = orgConfig.capacity_optimization === "true";
    setDoCapacityValidation(requireValidation);
  }

  const getAccounts = (whId) => {
    const accountsData = warehouseFilter.findMatchingAccounts(whId);
    setAccounts(accountsData || []);
  };

  
  const promptUnprocessedOrders = (
  driver,
    unpocessed_errors,
    key,
    showType = "alert"
  ) => {
    if (unpocessed_errors.length > 0) {
      if (showType === "alert") {
        setRouteProcessedErrors(unpocessed_errors);
      } else {
        RouteProcessedErrors(
          unpocessed_errors,
          I18n.t("messages.route_processed_error_heading"),
          showType
        );
      }
    } else {
      setRouteProcessedErrors([]);
    }
  };

  const showRouteValidationError = (capacity_info, routeName, confirmationModalProps) =>{
    const capacityInfo = capacity_info || {};
    const overloadedBy = findOverloadReasons(capacityInfo);
    Modal.confirm({
      title: "Errors",
      content: (
        <Fragment>
          <h4 >{routeName} : <span className="textRed">Overloaded by {overloadedBy}</span></h4>
          <div className="marginTop10">{I18n.t('messages.proceed_confirm')}</div>
        </Fragment>
      ),
      width: "40%",
      onOk: () => {
        handleConfirmationModal(confirmationModalProps);
      },
      onCancel: () => {
        setStopProgress(false);
      }
    });
  }

  const doRouteValidation = (confirmationModalProps) => {
    /*
    if(doCapacityValidation){
      // setRouteProgress(true)
      // showFlashMessage('info', I18n.t('messages.preplan_process'));
      notification.open({
        key: preplanId,
        message: I18n.t('preplan.capacity.validation'),
        description: <div>{I18n.t('messages.capacity_process')}</div>,
        icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
        placement: 'bottomRight',
        duration: 20,
      });
        const { allocType, data } = confirmationModalProps;
        const payload = {
          driver_id: allocType === "assignToUser" ? data.primary_driver_id : data.primary_driver_id,
          order_ids: allocType === "moveOrder" ? [...data.order_id] : [...data.order_ids],
        };
        const routeName = allocType === "assignToUser" ? data.routeName : data.newRouteName;
        capacityValidationForRoute(payload, preplanId).then((result) => {
         
          if (result.success) {
             notification.close(preplanId)
            if (!isEmpty(result.preplan) && !result.preplan.capacity_overloaded) {
              handleConfirmationModal(confirmationModalProps);
            } else {
              showRouteValidationError(result.preplan.capacity_info, routeName, confirmationModalProps);
            }
          } else {
            setStopProgress(false);
             notification.error({
              key: preplanId,
              message: routeName,
              description: <div>{ result.errors[ 0 ] }</div>,
              placement: "bottomRight",
            });
          }
        });
    } else {
      handleConfirmationModal(confirmationModalProps);
    }
    */
    handleConfirmationModal(confirmationModalProps);
  }

  const handleConfirmationModal = (confirmationModalProps) => {
    const confirmationModalHelpers = { handleRouteDeleteClick, createRoute, handleServiceDuration, setStopProgress, setRouteProcessedErrors, resetPreplan, updateRouteResponse, totalOrders, orderInfo, setTotalOrders, setOrderInfo, setUnassignedOrdersInfo, promptUnprocessedOrders, updateRouteInfo, currentRoute, stopSelectedOrders, setStopSelectedOrders, routeInfo, handleCurrentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop , unlinkRouteFromUsers,setRouteInfo,setUsers,setRouteKey,setInProgress,currentStop,setCurrentRoute,users , setAssignInfo };
    return takeConfirmation(
      {
        ...confirmationModalProps,
        ...confirmationModalHelpers,
        doCapacityValidation,
        preplanId,

      }
    );
  };

  const renderDurationalert = () => {
    info({
        // title: 'Unsaved Changes',
        // className: 'confirmModal',
        // content: I18n.t("messages.save_before_proceed", { field: 'service duration ' }),
        // okText: 'Keep Editing',
        // cancelText: 'Discard Changes',
        // cancelButtonProps: { type: 'danger' },
        // onCancel: () => {
        //   setServiceDurationReset(true);
        // },
        title: 'Unsaved Changes',
        className: 'confirmModal',
        content: I18n.t("messages.save_before_proceed", { field: 'service duration ' }),
        onOk: () => {
          
        },
      });
  }

  const onDragEnd = (result) => {
    if(unsavedServiceDurationChanges){
       renderDurationalert()
      return;
    }
    if (!result.destination) {
      return;
    }
    if (result.source) {
      const sourceObj = result.source;
      const destinationObj = result.destination;
      const sourceEle = sourceObj.droppableId;
      const destinationEle = destinationObj.droppableId;
      if (sourceEle) {
        if (sourceEle.indexOf("stopCard") >= 0) {
          const stopId = result.draggableId;
          const oldRouteId = currentRoute.route_id;
          // Stop Resequence
          if (
            sourceEle === destinationEle &&
            rearrangeForm &&
            currentRoute.stops &&
            currentRoute.stops.length > 2
          ) {
            const stops = currentRoute.stops ? [ ...currentRoute.stops ] : [];
            const hasCorrectOrder = checkDependancyOrderSeq(
              currentRoute,
              sourceObj.index,
              destinationObj.index
            );
            if (hasCorrectOrder) {
              const newStops = rearrangeStops(
                stops,
                true,
                sourceObj.index,
                destinationObj.index
              );
              setCurrentRoute(Object.assign({}, currentRoute, {
                stops: newStops,
              }));
              if (currentStop && currentStop.id) {
                setCurrentStop({}, currentStop.id);
              }
            }
          } else {
            // move to Route
            const stops = !isEmpty(currentRoute.stops)
              ? [ ...currentRoute.stops ]
              : [];
            const destinationRouteIndex = destinationEle.indexOf(
              "droppableRouteItem"
            );
            const destinationUserIndex = destinationEle.indexOf(
              "droppableUserItem"
            );
            const currentStopInfo = _.find(stops, { id: stopId });
            const checkedList =
              routeSelectedStops.length > 0 &&
                routeSelectedStops.includes(currentStopInfo.id)
                ? [ ...routeSelectedStops ]
                : [ currentStopInfo.id ];
            let dependencies = [];
            checkedList.forEach((stop) => {
              const dependencyStops = findDependencyStops(
                currentRoute,
                stop,
                "id",
                "stops"
              );
              dependencies = [].concat(dependencies, dependencyStops);
            });
            dependencies = _.uniq(dependencies);
            const finalStops = stops
              .filter((stop) => dependencies.includes(stop.id));
            const stopNames = finalStops.map((stop) => stop.formatted_stop_name);
            const stopOrderIds = finalStops.map((stop) => stop.customer_order_ids);
              
            // const stopNames = stops.filter(stop => checkedList.includes(stop.id)).map(stop => stop.formatted_stop_name);
            // currentStop && currentStop.formatted_stop_name ? currentStop.formatted_stop_name : '';
            const data = {
              desc: "",
              stop_id: dependencies, // checkedList,
              preplan_id: preplanId,
              old_nav_route_id: oldRouteId || "",
              oldRouteName: currentRoute.displayName || "",
              stopName: stopNames,
              order_ids: getUniqvaluesFromArray(stopOrderIds),
            };
            let allocType = "";
            let content = "";
            // Stop Moved to Existing Route
            if (destinationRouteIndex >= 0) {
              const newRouteId = destinationEle.replace(
                "droppableRouteItem",
                ""
              );
              const routeIndex = _.findIndex(routeInfo, [
                "route_id",
                newRouteId,
              ]);
              if (routeIndex >= 0 && oldRouteId !== newRouteId) {
                data.primary_driver_id = routeInfo[
                  routeIndex
                ].driversInfo.primary.driverId;
                data.new_nav_route_id = newRouteId || "";
                data.newRouteName = routeInfo[
                  routeIndex
                ].displayName;
                data.order_ids_for_validation = [].concat(data.order_ids, routeInfo[ routeIndex ].customer_order_ids);
                allocType = "existingRoute";
                content = (
                  <span className="textBold">
                    Routes: { currentRoute.displayName }
                    &nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;
                    { routeInfo[ routeIndex ].displayName }
                  </span>
                );
              }
            } else if (destinationUserIndex >= 0) {
              // Move to user
              const newUserId = destinationEle.replace("droppableUserItem", "");
              const userIndex = _.findIndex(users, [
                "id",
                newUserId,
              ]);
              if (userIndex >= 0) {
                data.order_ids_for_validation = [...data.order_ids];
                allocType = "newRoute";
                data.new_nav_route_id = "";
                data.primary_driver_id = newUserId;
                data.newRouteName = users[ userIndex ].employee_code;
                content = (
                  <span>
                    Routes: { currentRoute.displayName }
                    &nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;
                    { users[ userIndex ].employee_code }
                  </span>
                );
              }
            }
            if (allocType !== "") {
              setStopProgress(true);
              doRouteValidation({
                allocType: "moveStop",
                routeId: currentRoute.route_id,
                data: data,
                title: (
                  <Row>
                    <Col className="marginBottom10">
                      Moving the stop
                      { stopNames.length > 1 ? "s" : "" }
                    </Col>
                    { stopNames.map((stop) => (
                      <Col className="marginBottom5 fontSize12 textOverFlowEllipse">
                        { stop }
                      </Col>
                    )) }
                  </Row>
                ),
                content: content,
              })
            }
          }
        } else if (sourceEle.indexOf("orderDroppable") >= 0) {
          // orders from stop sections
          const orderId = result.draggableId;
          const oldRoute = { ...currentRoute };
          const orderStops = oldRoute.stops.filter(
            (stopObj) =>
              stopObj.location &&
              stopObj.location.l_type === "CS" &&
              stopObj.customer_order_ids &&
              stopObj.customer_order_ids.includes(orderId)
          );
          const currentStopInfo =
            orderStops.length > 0 ? orderStops[ 0 ] : { orders: [] };
          const destinationRouteIndex = destinationEle.indexOf(
            "droppableRouteItem"
          );
          const destinationUserIndex = destinationEle.indexOf(
            "droppableUserItem"
          );
          let allocType = "";
          let content = "";
          const orders = findDependencyOrders(
            oldRoute.routeOrders,
            "related_order",
            "customer_order_id",
            orderId,
            "id",
            "type_of_order"
          );
          // use set to remove duplicate order numbers
          const orderNumbers = Array.from(new Set(
            oldRoute && oldRoute.routeOrders
              ? oldRoute.routeOrders
                .filter((order) => orders.includes(order.customer_order_id))
                .map((order) => order.customer_order_number)
              : []
          ));
          const data = {
            desc: "",
            order_id: orders,
            preplan_id: preplanId,
            stop_id: currentStopInfo.id,
            old_nav_route_id: oldRoute.route_id || "",
            orderNumber: orderNumbers,
            location_ids: getLocationIds([orderId], oldRoute.routeOrders, "stop_location_id", "string")
          };
          if (destinationRouteIndex >= 0) {
            // move stop orders to existing route
            const newRouteId = destinationEle.replace("droppableRouteItem", "");
            const routeIndex = _.findIndex(routeInfo, [
              "route_id",
              newRouteId,
            ]);
            if (routeIndex >= 0 && oldRoute.route_id !== newRouteId) {
              data.order_ids_for_validation = [].concat(data.order_id, routeInfo[ routeIndex ].customer_order_ids);
              data.new_nav_route_id = newRouteId || "";
              data.primary_driver_id = routeInfo[
                  routeIndex
                ].driversInfo.primary.driverId;
              data.oldRouteName = currentRoute.displayName;
              data.newRouteName = routeInfo[ routeIndex ].displayName;
              allocType = "existingRoute";
              content = (
                <span>
                  { currentRoute.displayName }&nbsp;
                  <Icon type="arrow-right" />
                  &nbsp;
                  { routeInfo[ routeIndex ].displayName }
                </span>
              );
            }
          } else if (destinationUserIndex >= 0) {

            // move stop orders to new user
            const newUserId = destinationEle.replace("droppableUserItem", "");
            const userIndex = _.findIndex(users, [ "id", newUserId ]);
            if (userIndex >= 0) {
              allocType = "newRoute";
              data.order_ids_for_validation = [...data.order_id];
              data.new_nav_route_id = "";
              data.primary_driver_id = newUserId;
              data.oldRouteName = currentRoute.displayName;
              data.newRouteName = users[ userIndex ].employee_code;
              content = (
                <span>
                  { currentRoute.displayName }&nbsp;
                  <Icon type="arrow-right" />
                  &nbsp;
                  { users[ userIndex ].employee_code }
                </span>
              );
            }
          }
          if (allocType !== "") {
            setStopProgress(true);
            doRouteValidation({
              allocType: "moveOrder", routeId: currentRoute.route_id, data: data,
              title: (
                <Row>
                  <Col className="marginBottom10">
                    Moving the order
                    { orderNumbers.length > 1 ? "s" : "" }
                  </Col>
                  <Col className="marginBottom5 fontSize12 textOverFlowEllipse">
                    { orderNumbers.length > 0
                      ? orderNumbers.join(", ")
                      : "" }
                  </Col>
                </Row>
              ),
              content: content, 
            })
          }
        } else if (sourceEle.indexOf("routeOrderDroppable") >= 0) {
          // move orders from route
          if (
            destinationEle === "stopCard" &&
            currentRoute.stops &&
            currentRoute.stops.length > 2
          ) {
            // sequencing
            // const currentRoute = currentRoute
            //   ? { ...currentRoute }
            //   : { routeOrders: [] };
            const stops = currentRoute.stops ? [ ...currentRoute.stops ] : [];
            const routeOrders = currentRoute.routeOrders
              ? [ ...currentRoute.routeOrders ]
              : [];
            const sourceStop = getValueFromArrayOfObjects(
              routeOrders,
              "customer_order_id",
              routeOrders[ sourceObj.index ].customer_order_id,
              "stop_id"
            );
            const destinationStop = getValueFromArrayOfObjects(
              routeOrders,
              "customer_order_id",
              routeOrders[ destinationObj.index ].customer_order_id,
              "stop_id"
            );
            if (sourceStop !== destinationStop) {
              const customerStops = retrieveCustomerStops(currentRoute, true);
              const sourceStopIndex = _.findIndex(customerStops, [
                "id",
                sourceStop,
              ]);
              const destinationStopIndex = _.findIndex(customerStops, [
                "id",
                destinationStop,
              ]);
              const hasCorrectOrder = checkDependancyOrderSeq(
                currentRoute,
                sourceStopIndex,
                destinationStopIndex
              );
              if (hasCorrectOrder) {
                const newStops = rearrangeStops(
                  stops,
                  true,
                  sourceStopIndex,
                  destinationStopIndex
                );
                const newRouteOrders = setRouteOrders(newStops);
                setCurrentRoute(
                  Object.assign({}, currentRoute, {
                    stops: newStops,
                    routeOrders: newRouteOrders,
                  })
                );
                if (currentStop && currentStop.id) {
                  setCurrentStop({}, currentStop.id);
                }
              }
            }
          } else {
            const orderId = result.draggableId;
            const oldRoute = currentRoute
              ? { ...currentRoute }
              : { routeOrders: [] };
            const destinationRouteIndex = destinationEle.indexOf(
              "droppableRouteItem"
            );
            const destinationUserIndex = destinationEle.indexOf(
              "droppableUserItem"
            );
            let allocType = "";
            let content = "";
            const selectedKeys = stopSelectedOrders.selectedKeys.includes(
              orderId
            )
              ? [ ...stopSelectedOrders.selectedKeys ]
              : [ orderId ];
            let dependencyOrders = [];
            selectedKeys.forEach((order) => {
              const dependencyItems = findDependencyOrders(
                oldRoute.routeOrders,
                "related_order",
                "customer_order_id",
                order,
                "id",
                "type_of_order"
              );
              dependencyOrders = [].concat(dependencyOrders, dependencyItems);
            });
            const totalOrders = _.uniq(dependencyOrders);
            const orderNumber = Array.from( new Set( oldRoute && oldRoute.routeOrders
              ? oldRoute.routeOrders
                .filter((order) => totalOrders.includes(order.customer_order_id))
                .map((order) => order.customer_order_number)
              : []));


            const data = {
              desc: "",
              order_id: totalOrders,
              preplan_id: preplanId,
              old_nav_route_id: oldRoute.route_id || "",
              orderNumber,
              location_ids: getLocationIds(totalOrders, oldRoute.routeOrders, "stop_location_id", "string")
            };
            if (destinationRouteIndex >= 0) {
              const newRouteId = destinationEle.replace(
                "droppableRouteItem",
                ""
              );
              const routeIndex = _.findIndex(routeInfo, [
                "route_id",
                newRouteId,
              ]);
              if (routeIndex >= 0 && oldRoute.route_id !== newRouteId) {
                // move to existing route
                data.order_ids_for_validation = [].concat(data.order_id, routeInfo[ routeIndex ].customer_order_ids);
                data.new_nav_route_id = newRouteId || "";
                data.primary_driver_id = routeInfo[
                  routeIndex
                ].driversInfo.primary.driverId;
                data.oldRouteName = currentRoute.displayName;
                data.newRouteName = routeInfo[
                  routeIndex
                ].displayName;
                allocType = "existingRoute";
                content = (
                  <span>
                    { currentRoute.displayName }
                    &nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;
                    { routeInfo[ routeIndex ].displayName }
                  </span>
                );
              }
            } else if (destinationUserIndex >= 0) {
              const newUserId = destinationEle.replace("droppableUserItem", "");
              const userIndex = _.findIndex(users, [
                "id",
                newUserId,
              ]);
              if (userIndex >= 0) {
                allocType = "newRoute";
                data.order_ids_for_validation = [...data.order_id];
                data.new_nav_route_id = "";
                data.primary_driver_id = newUserId;
                data.oldRouteName = currentRoute.displayName;
                data.newRouteName = users[ userIndex ].employee_code;
                content = (
                  <span>
                    { currentRoute.displayName }
                    &nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;
                    { users[ userIndex ].employee_code }
                  </span>
                );
              }
            }
            if (allocType !== "") {
              setStopProgress(true);
              doRouteValidation({
                allocType: "moveOrder", routeId: currentRoute.route_id, data: data,
                title: (
                  <Row>
                    <Col className="marginBottom10">
                      Moving the order
                      { orderNumber.length > 1 ? "s" : "" }
                    </Col>
                    <Col className="marginBottom5 fontSize12 textOverFlowEllipse">
                      { orderNumber.length > 0
                        ? orderNumber.join(", ")
                        : "" }
                    </Col>
                  </Row>
                )
                , content: content, 
              })
            }
          }
        } else if (sourceEle.indexOf("routedroppable") >= 0) {
          return null;
        } else if (sourceEle.indexOf("unassignedDroppable") >= 0) {
          const orderId = result.draggableId;
          const destinationRouteIndex = destinationEle.indexOf(
            "droppableRouteItem"
          );
          const destinationUserIndex = destinationEle.indexOf(
            "droppableUserItem"
          );
          let allocType = "";
          let content = "";

          const selectedKeys =
            orderId === "fromMap" ?
              [ ...unassignedOrdersInfo.selectedOrderKeys ]
              :
              unassignedOrdersInfo.selectedOrderKeys.includes(orderId)
              ? [ ...unassignedOrdersInfo.selectedOrderKeys ]
              : [ orderId ];
          let addrErrOrders = getAddrErrOrders(
            totalOrders,
            "id",
            "id",
            selectedKeys
          );
          let dependencyAddrErrOrders = {};
          let dependencies = [];
          selectedKeys
            .filter((order) => !addrErrOrders.includes(order))
            .forEach((order) => {
              const dependencyorders = findDependencyOrders(
                totalOrders,
                "related_order",
                "id",
                order,
                "id",
                "type_of_order"
              );
              const currentErrOrders = getAddrErrOrders(
                totalOrders,
                "id",
                "id",
                dependencyorders
              );
              if (currentErrOrders.length > 0) {
                addrErrOrders = [].concat(addrErrOrders, order);
                dependencyAddrErrOrders[ order ] = currentErrOrders.filter(
                  (rec) => order !== rec
                );
              } else {
                dependencies = [].concat(dependencies, dependencyorders);
              }
            });

          dependencies = _.uniq(dependencies);
          const orderNumbers = totalOrders
            .filter((order) => dependencies.includes(order.id))
            .map((order) => order.customer_order_number);
          const data = {
            preplan_id: preplanId,
            secondary_driver_id: "",
            orderNumbers,
            addrErrOrders,
            order_ids: [],
          };
          const title = `Allocating the order${orderNumbers.length > 1 ? "s" : ""
            } ${orderNumbers.join(", ")}`;
          if (destinationRouteIndex >= 0) {
            // Add orders to existing route
            const newRouteId = destinationEle.replace("droppableRouteItem", "");
            const routeIndex = _.findIndex(routeInfo, [
              "route_id",
              newRouteId,
            ]);
            if (routeIndex >= 0) {
              data.order_ids = dependencies;
              data.location_ids = getLocationIds(dependencies, totalOrders, 'locations', 'array');
              data.order_ids_for_validation = [].concat(dependencies, routeInfo[ routeIndex ].customer_order_ids);
              data.nav_route_id = newRouteId || "";
              data.routeName = routeInfo[ routeIndex ].displayName;
              data.primary_driver_id = routeInfo[
                  routeIndex
              ].driversInfo.primary.driverId
              allocType = "allocateToRoute";
              content = (
                <span>
                  Route: &nbsp;
                  { routeInfo[ routeIndex ].displayName }
                </span>
              );
            }
          } else if (destinationUserIndex >= 0) {
            // create a route
            const newUserId = destinationEle.replace("droppableUserItem", "");
            const userIndex = _.findIndex(users, [ "id", newUserId ]);
            if (userIndex >= 0) {
              allocType = "assignToUser";
              data.order_ids = dependencies;
              data.location_ids = getLocationIds(dependencies, totalOrders, 'locations', 'array');
              data.order_ids_for_validation = [...dependencies]
              data.primary_driver_id = newUserId;
              data.routeName = users[ userIndex ].employee_code;
              content = (
                <span>
                  Route: &nbsp;
                  { users[ userIndex ].employee_code }
                </span>
              );
            }
          }
          if (allocType !== "") {
            let errOrderObjects = [];
            let addressErrContent = "";
            addrErrOrders = _.uniq(addrErrOrders);
            if (addrErrOrders.length > 0) {
              errOrderObjects = totalOrders
                .filter((order) => addrErrOrders.includes(order.id))
                .map((order) => order.customer_order_number);
              addressErrContent = addressResolveMsg(errOrderObjects);
            }
            if (
              addrErrOrders.length > 0 &&
              data.order_ids.length === 0
            ) {
              showErrorsonModal("Errors", addressErrContent);
            } else {
            setStopProgress(true);
            doRouteValidation({
              allocType, routeId: "", data: data,
              title: (
                <Row>
                  <Col className="marginBottom10">
                    Allocating the order
                    { orderNumbers.length > 1 ? "s" : "" }
                  </Col>
                  <Col className="marginBottom5 fontSize12 textOverFlowEllipse">
                    { orderNumbers.length > 0 ? orderNumbers.join(", ") : "" }
                  </Col>
                </Row>
              ),
              content: (
                <div>
                  { content }
                  { errOrderObjects.length > 0 && (
                    <Row>
                      <Col
                        xs={ 24 }
                        className="marginTop20 marginBottom5 fontSize12 textOverFlowEllipse"
                      >
                        { addressErrContent }
                      </Col>
                    </Row>
                  ) }
                </div>
              ),
            })
          }
        }
        }
      }
    }

    return null;
  };

  // applying filters to set of total orders
  const applyFilters = () => {
    let currentOrdersInfo = [];
    if (filter && !isEmpty(filter.search_order_token)) {
      onSearchNumbers(
        filter, totalOrders, setOrderInfo, setZone_ids, setAccountCodes, setUnassignedOrdersInfo, setOrderVhTypes,accountCodesWithExceededLimit,setOrderTypeFilter
      );

    } else {
      // filter with order zones
      if (zone_ids.length === 0) {
        currentOrdersInfo = [ ...totalOrders ];
      } else {
        const filteredZoneIds = [...zone_ids]; //zone_ids.map(i => i.delivery_zone_id)
        let unassignedOrders = [];
        const filteredOrders = totalOrders.filter(
          (order) =>
            order.order_zones.length > 0 &&
            order.order_zones
              .map((zone) => zone.zone_id)
              .some((el) => filteredZoneIds.includes(el))
        );
        if (zone_ids.includes("UNASSIGNED")) {
          unassignedOrders = totalOrders.filter(
            (order) => !order.order_zones || order.order_zones.length === 0
          );
        }
        currentOrdersInfo = [].concat(filteredOrders, unassignedOrders);
      }
      // filter with filter accountCodes
      if (accountCodes.includes("ALL") || accountCodes.length === 0) {
      } else {
        currentOrdersInfo = currentOrdersInfo.filter(
          (order) =>
            order.account_code && accountCodes.includes(order.account_code)
        );
      }
      //filter with orderTypeFilter
      if(orderTypeFilter?.length > 0){
        let allOrderTypes = [];
        orderTypeFilter.forEach((item) => {
          let splitItems = item.split(",");
          allOrderTypes = allOrderTypes.concat(splitItems);
        });
        if(!orderTypeFilter.includes("ALL") || orderTypeFilter[0] !== "ALL"){
          currentOrdersInfo = currentOrdersInfo.filter((order)=> allOrderTypes.includes(order.type_of_order))        
        }
        // currentOrdersInfo = currentOrdersInfo.filter((order)=> allOrderTypes.includes(order.type_of_order))
      }else{

      }
      // filter with filter orderVhTypes
      if (orderVhTypes.includes("ALL") || orderVhTypes.length === 0) {
      } else {
        currentOrdersInfo = currentOrdersInfo.filter(
          (order) =>
            order.vehicle_type && orderVhTypes.includes(order.vehicle_type)
        );
      }
      const selectedOrders = currentOrdersInfo.filter((order) =>
        unassignedOrdersInfo.selectedOrderKeys.includes(order.id)
      ); // For slected orders  === 'PREPLAN'
      setOrderInfo(currentOrdersInfo);
      const sortedOrders = arrangeOrderByIdSeq(selectedOrders, unassignedOrdersInfo.selectedOrderKeys)
      setUnassignedOrdersInfo({
        selectedOrderKeys: sortedOrders.map((order) => order.id),
        selectedOrderRows: sortedOrders,
      });
    }
  };

  // state changes on filter changes
  // function for available drivers
  const getAvailableDrivers = () => {
    const startDeliveryTime =
      preplanTenseTime === "future"
        ? currentPreplan.delivery_start_date
        : moment().format();
    const endDeliveryTime = currentPreplan.delivery_end_date;
    setInProgress(true);
    fetchAvailableDrivers(startDeliveryTime, endDeliveryTime, "", warehouse_id).then((result) => {
      if (result.success) {
        const drivers = result.users || [];
        const availableDrivers = drivers.filter(
          (driver) => _.findIndex(users, [ "id", driver.id ]) < 0
        );
        setAvailableDrivers(availableDrivers);
        setShowAvailableDrivers(true);
        if (availableDrivers.length === 0) {
          alertMessage([ I18n.t("messages.no_drivers_found") ], "error", 10);
        }
      } else {
        renderAlertMessage(result.errors)
      }
    }).finally(() => {
      setInProgress(false);
    });
  };

  useEffect(() => {
    if(totalOrders.length){
      applyFilters();
      handleMapData();
    }}
    , [ totalOrders ]);
  // get orders wrt ot preplan
  const getOrders = (resetSelected = true, dependentKeys = null) => {
    setOrderProgress(true);
    const { sortBy, groupBy, startDate, endDate } = filter;
    const deliveryStartDate = startDate ? moment(startDate).format("YYYY-MM-DD 00:00:00 Z") : null;
    const deliveryEndDate = endDate ? moment(endDate).format("YYYY-MM-DD 23:59:59 Z") : null;
    const data = {
      sortBy: sortBy || "none",
      groupBy: groupBy || "none",
      accountCode: "",
      zone_ids: "", // zone_ids,
    };
    if (dependentKeys){
      data.customer_order_ids = dependentKeys;
    }
    if (orderType !== "RECEIVED") {
      data.deliveryStartDate = deliveryStartDate;
      data.deliveryEndDate = deliveryEndDate;
    }
    fetchOrdersForPreplan(
      orderType,
      preplanId,
      warehouse_id,
      data
    ).then((result) => {
      if (result.success) {
        const total_orders = result.orders || [];
        let orders = total_orders.map((customerOrder) => {
          const order = setOrderResponse(customerOrder);
          return order;
        });
        if (dependentKeys && dependentKeys.length > 0 ) {
          const allOrders = _.cloneDeep(totalOrders);
          const responseKeys = [];
          orders.forEach((order) => {
            const orderIndex = _.findIndex(allOrders, { id: order.id });
            if (orderIndex >= 0) {
              allOrders[orderIndex] = { ...order };
              responseKeys.push(order.id);
            }
          });
          const removedKeys =  dependentKeys.filter(key => !responseKeys.includes(key))
          orders = allOrders.filter(order => !removedKeys.includes(order.id));
          // orders = [...allOrders];
        }
        
        if (
          resetSelected &&
          unassignedOrdersInfo.selectedOrderKeys
        ) {
          const selectedOrders = orders.filter((order) =>
            unassignedOrdersInfo.selectedOrderKeys.includes(order.id)
          ); // For slected orders  === 'PREPLAN'
          const sortedOrders = arrangeOrderByIdSeq(selectedOrders, unassignedOrdersInfo.selectedOrderKeys)
          setUnassignedOrdersInfo({
            selectedOrderKeys: sortedOrders.map((order) => order.id),
            selectedOrderRows: sortedOrders,
          });
        }
        setOrderProgress(false);
        setIsInitial(false);
        setTotalOrders(orders);
        if (orders.length === 0) {
          setOrderInfo([]);
        }
        // applyFilters();
      } else {
        if (isInitial) {
          alertMessage(result.errors[ 0 ], "error", 5);
        }
        setOrderInfo([]);
        setOrderProgress(false);
        setIsInitial(false);
        // check for if appointment has changed
        if(dependentKeys && dependentKeys.length > 0 && totalOrders.length > 0){
          const orders  = totalOrders.filter(order => !dependentKeys.includes(order.id))
          setTotalOrders(orders);
        } else {
          setTotalOrders([]);
        }
      }
    });
  };
  // resetting edit order basic requirements
  const closeEditFormModal = (isModified) => {
    setEditForm(false);
    setPreferences([
      {
        s_no: 1,
        item_preference: "",
        item_option: [],
      },
      {
        s_no: 2,
        item_preference: "",
        item_option: [],
      },
      {
        s_no: 3,
        item_preference: "",
        item_option: [],
      } ]);
    if (isModified) {
      const dependentKeys = findDependencyOrders(
        totalOrders,
        "related_order",
        "id",
        currentOrder.id
      );
      getOrders(true, dependentKeys);
    }
    setCurrentOrder({});
    setOrderModified(false)
  };


  const handleOrderSaveSuccess = (
    callbackMessage,
    isOutOfPreplan,
    isModified,
    newData
  ) => {
    const {route_id , id } = currentOrder;
    setOrderModified(false);
    if (route_id && currentRoute.route_id) {
      if (isOutOfPreplan) {
        checkRouteOrders(
          id,
          currentRoute.route_id,
          isOutOfPreplan
        );
      } else {
        setEditForm(false);
        const routeId = currentRoute.route_id;
        refreshRoutes(routeId);

        // if (
        //   currentRoute.customer_order_ids &&
        //   currentRoute.customer_order_ids.includes(
        //     id
        //   )
        // ) {
        //   const routeOrders = currentRoute.routeOrders
        //     ? currentRoute.routeOrders
        //     : [];
        //   let routes = [ ...routeInfo ];
        //   const routeIndex = _.findIndex(routes, [
        //     "route_id",
        //     currentRoute.route_id,
        //   ]);
        //   if (routeIndex >= 0) {
        //     newData.forEach((updatedOrder) => {
        //       if(updatedOrder?.locations?.length > 0){
        //         updatedOrder.locations.forEach(loc => {
        //           const routeOrderIndex = _.findIndex(routeOrders, {
        //             "customer_order_id":  updatedOrder.id,
        //             "stop_location_id": loc.id
        //           });
        //           if (routeOrderIndex >= 0) {
        //             if ( routeOrders[ routeOrderIndex ].service_duration !== loc.service_duration) {
        //               isModified = true;
        //             }
        //             const processingOrder = routeOrders[ routeOrderIndex ];
        //             routes = updateOrderDetailsToRouteOrders(
        //               routes, routeIndex, routeOrderIndex, processingOrder, updatedOrder, currentWarehouse, loc
        //             );
        //           }
        //         })
        //       }else{
        //         const routeOrderIndex = _.findIndex(routeOrders, [
        //           "customer_order_id",
        //           updatedOrder.id,
        //         ]);
        //         if (routeOrderIndex >= 0) {
        //           if ( routeOrders[ routeOrderIndex ].service_duration !== updatedOrder.service_duration) {
        //             isModified = true;
        //           }
        //           const processingOrder = routeOrders[ routeOrderIndex ];
        //           routes = updateOrderDetailsToRouteOrders(
        //             routes, routeIndex, routeOrderIndex, processingOrder, updatedOrder, currentWarehouse
        //           );
        //         }
        //       }
        //     });

        //     if (isModified) {
        //       routes[ routeIndex ].optimized = false;
        //       // getOrders(); hidden for temporary purpose
        //     }
        //     setRouteInfo(routes);
        //     setCurrentRoute(routes[ routeIndex ]);
        //     setRouteKey(randomNumber());
        //   }
        // }
      }
    } else {
      setEditForm(false);
      if(isModified){
        const dependentKeys = findDependencyOrders(
          totalOrders,
          'related_order',
          "id",
          currentOrder.id
        );
        getOrders(true, dependentKeys);
      }
      
    }
    alertMessage(callbackMessage);
  };
  // edit order click handling
  const handleEditOrderClick = (id, routeId, _stopId) => {
    const route = _.find(routeInfo, { route_id: routeId });
    fetchOrderDetails(id, "DISPATCHER").then((result) => {
      if (result.success) {
        const orders = result.orders || [];
        const preferences = [ ...preferences ];
        const customerPreferences = result.orders.appointments || [];
        customerPreferences.forEach((preferenceObj, index) => {
          const preferenceIndex = _.findIndex(preferences, [ "s_no", index + 1 ]);
          if (preferenceIndex >= 0) {
            preferences[ preferenceIndex ].item_preference =
              preferenceObj.appt_date;
            preferences[ preferenceIndex ].item_option =
              preferenceObj.slots || [];
          }
        });
        const currentOrderInfo = _.find(orders, { id });
        setCurrentOrder(
          !isEmpty(currentOrderInfo) && _.isObject(currentOrderInfo)
            ? {
              ...currentOrderInfo,
              route_id: !isEmpty(routeId) ? routeId : '',
            } : {});
        setEditForm(true);
        setPreferences(preferences);
        if (!isEmpty(routeId)) {
          setCurrentRoute(!isEmpty(route) && _.isObject(route) ? route : {});
        }
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  const refreshRoutes = (routeId) => {
    setRouteLoading(true)
    getPreplanRouteResponse(routeId).then((result) => {
      if (result.success) {
        if (Object.keys(result.nav_route).length > 0) {
          updateRouteResponse(result.nav_route);
        } else {
          const routes = routeInfo.filter(
            (route) => route.route_id !== routeId
          );
          setRouteInfo(routes);
          setCurrentRoute({});
          setRouteKey(randomNumber());
        }
        setRouteLoading(false);
      }
    });
  };

  const getAllZones = () => {
    setIsZonesLoading(true);
    fetchWHZones(warehouse_id).then((result) => {
      if (result.success) {
        const preplanZones = result.zones || [];
        setPreplanZones(preplanZones.map((zone) => ({
          delivery_zone_id: zone.id,
          delivery_zone_name: zone.name,
        })));
      }
    }).finally(() => {
      setIsZonesLoading(false);
    });
  };

  useEffect(() => {
    const preplanZoneIds = preplanZones.map((zone) => (zone.delivery_zone_id));
    const ids = preplanZoneIds.filter(id => !zone_ids.includes(id)).length;
    if(ids === 0){
      setZone_ids( [...zone_ids, 'UNASSIGNED']);
    }
  }, [preplanZones]);

  useEffect(() => {
    if (!isEmpty(warehouse_id) && preplanProgress) {
      if (
        isInitial ||
        !(
          currentRoute &&
          currentRoute.route_id &&
          _.findIndex(routeInfo, {
            route_id: currentRoute.route_id,
          }) >= 0
        )
      ) {
        const routeId =
          routeInfo.length > 0
            ? routeInfo[ 0 ].route_id
            : "";
        handleCurrentRoute({}, routeId);
      }
      if (
        isInitial &&
        preplanZones.length === 0
      ) {
        getAllZones();
      }
      if(callOrderDetails){
        getOrders();
        setCallOrderDetails(false);
      }
      handleMapData();
      setPreplanDateValidity();
      setPreplanProgress(false);
    }
  }, [ routeKey ])
  
  // getting total preplan info
  const getPreplanInfo = (callOrders = true, cb = null) => {
    if (preplanId) {
      setPreplanProgress(true);
      fetchPreplan(preplanId).then((result) => {
        if (result.success) {
          //result.preplan = {...preplanData};
          const newState = handleRouteReponse(result, { filter , filterPlaceHolder });
          // check to reassign the same zones instead of reassigning zones, this is the check for showing all selected zones instead of preplan zones
          if (zone_ids.length > 0) {
            newState.zone_ids = [ ...zone_ids];
          }
          setDriverZones(newState.driver_zones);
          setRouteInfo(newState.routeInfo);
          setCurrentPreplan(newState.currentPreplan);
          setWarehouse_id(newState.warehouse_id);
          setCurrentWarehouse(newState.currentWarehouse);
          setFilter(newState.filter);
          setFilterPlaceHolder(newState.filterPlaceHolder);
          setUsers(newState.users);
          setZone_ids(newState.zone_ids);
          setCallOrderDetails(callOrders);
          setRouteKey(randomNumber());
          if (cb) {
            cb(newState.routeInfo);
          }

        } else {
          setInProgress(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };
  // setting preplan date validity
  const setPreplanDateValidity = () => {
    const presentDate = moment();
    const {
      delivery_start_date,
      delivery_end_date,
    } = currentPreplan;
    const isFeatureStart = moment(presentDate).isSameOrBefore(
      delivery_start_date
    );
    const isFeatureEnd = moment(presentDate).isSameOrBefore(delivery_end_date);
    let preplanTenseTime = "future";
    if (!isFeatureStart && !isFeatureEnd) {
      preplanTenseTime = "past";
    } else if (!isFeatureStart) {
      preplanTenseTime = "present";
    }
    setPreplanTenseTime(preplanTenseTime);
  };

  // to update uinassigned orders selected Info
  const handleUnassignOrdersInfo = (orders = []) => {
    setUnassignedOrdersInfo({
      selectedOrderKeys: orders.map((order) => order.id),
      selectedOrderRows: orders,
    });
    setClickedRouteId("");
  };
  // to update route orders selected Info
  const handleRouteOrdersInfo = (orders = [], element) => {
    setRouteSelectedStops(orders.map((order) => order.stop_id));
    setStopSelectedOrders({ selectedKeys: orders.map((order) => order[ element ]), selectedRows: orders });
  };

  // handling select all of route orders List (onChange of Selection)


  const goToTab = (step) => {
    setActiveKey(step);
    setCurrentStop({});
  };

  // auto preplan
  const saveAutoPreplan = (forceOptimize = false, latestUnassignedOrders) => {
    if (
      !forceOptimize &&
      preplanId &&
      _.isEqual(
        latestUnassignedOrders.selectedOrderKeys,
        currentPreplan.customer_order_ids
      ) &&
      _.isEqual(
        routeTrucksData.selectedTruckKeys,
        currentPreplan.fleet_ids
      )
    ) {
      resetPreplan();
    } else if (latestUnassignedOrders.selectedOrderKeys.length === 0) {
      // || routeTrucksData.selectedTruckKeys.length === 0
      alertMessage(I18n.t("messages.select_orders_trucks"), "error", 5);
    } else {
      const data = {
        order_ids: latestUnassignedOrders.selectedOrderKeys,
        fleet_ids: [], // routeTrucksData.selectedTruckKeys,
        driver_ids: users.map((user) => user.id),
        location_ids: latestUnassignedOrders.selectedOrderRows.map((order) => order.locations.map(loc => loc.id)).flat(),
        fleet_type: "driver",
        zone_ids: zone_ids,
      };
      setInProgress(true);
      setPreplanProgress(true);
      // showFlashMessage('info', I18n.t('messages.preplan_process'));
      message.loading({
        content: I18n.t("messages.preplan_process"),
        key: "preplan",
        duration: 2500,
      });
      const startDate = startDate ? moment(filter.startDate).format(
        "YYYY-MM-DD 00:00:00 Z"
      ) : null;
    
      createPreplan(
        data,
        preplanId,
        startDate,
        warehouse_id
      ).then((result) => {
        if (result.success) {
          setInProgress(false);
          if (result.preplan.optimized === false) {
            const errors =
              result.preplan.optimizer_readable_error_message &&
                result.preplan.optimizer_readable_error_message.length > 0
                ? result.preplan.optimizer_readable_error_message.join(", ")
                : I18n.t("messages.preplan_failure");
            // showFlashMessage('error', errors);
            message.success({
              content: errors,
              key: "preplan",
              duration: 5,
            });
          } else {
            // showFlashMessage('success', I18n.t('messages.preplan_success'));
            message.success({
              content: I18n.t("messages.preplan_success"),
              key: "preplan",
              duration: 5,
            });
            setCurrentPreplan(result.preplan);
            setPreplanId(result.preplan.id);
            setInProgress(false);
            getPreplanInfo();
            if(result?.unallocated_orders?.length){
              showUnassignedErrors(result.unallocated_orders)
            }
          }
          resetSelectedOrders();
        } else {
          Modal.error({
            title: "Errors",
            content: (
              <Fragment>
                { result.errors.map((err, index) => (
                  <Row key={ `rowErr${index + 1}` }>
                    <Col key={ `err${index + 1}` } className="fontWeight500">
                      { err }
                    </Col>
                  </Row>
                )) }
              </Fragment>
            ),
            width: "40%",
          });
          setInProgress(false);
          setPreplanProgress(false);
          message.destroy();
        }
      }).finally(() => {  });
    }
  };

  const handleResponse = (result) => {
    if (result.optimized === false) {
      const errors =
        result.optimizer_readable_error_message &&
        result.optimizer_readable_error_message.length > 0
          ? result.optimizer_readable_error_message.join(", ")
          : I18n.t("messages.preplan_failure");
      // showFlashMessage('error', errors);
      message.success({
        content: errors,
        key: "preplan",
        duration: 5,
      });
    } else {
      // showFlashMessage('success', I18n.t('messages.preplan_success'));
      message.success({
        content: I18n.t("messages.preplan_success"),
        key: "preplan",
        duration: 5,
      });
      this.setState(
        {
          currentPreplan: result,
          preplanId: result.id,
          inProgress: false,
        },
        () => {
          this.getPreplanInfo();
        }
      );
    }
  };

  
  const handleAutoPreplan = (zone_wise_orders = []) => {
    const data = {
      zone_wise_orders: zone_wise_orders || [],
      pre_plan_id: preplanId,
    };
    setPreplanProgress(true);
    // showFlashMessage('info', I18n.t('messages.preplan_process'));
    message.loading({
      content: I18n.t("messages.preplan_process"),
      key: "preplan",
      duration: 2500,
    });
    autoPreplan(data, preplanId).then((result) => {
      if (result.success) {
        setPreplanProgress(false);
        if (result.preplan.optimized === false) {
          const errors =
            result.preplan.optimizer_readable_error_message &&
            result.preplan.optimizer_readable_error_message.length > 0
              ? result.preplan.optimizer_readable_error_message.join(", ")
              : I18n.t("messages.preplan_failure");
          // showFlashMessage('error', errors);
          message.success({
            content: errors,
            key: "preplan",
            duration: 5,
          });
        } else {
          // showFlashMessage('success', I18n.t('messages.preplan_success'));
          message.success({
            content: I18n.t("messages.preplan_success"),
            key: "preplan",
            duration: 5,
          });
          setCurrentPreplan(result.preplan);
          setPreplanId(result.preplan.id);
          setPreplanProgress(false);
          getPreplanInfo();
          if(result?.unallocated_orders?.length){
            showUnassignedErrors(result.unallocated_orders)
          }
        }
        const processedOrders = _.flatMapDeep(
          _.compact(zone_wise_orders.map((zone) => zone.orders))
        );
        const orderRecords = unassignedOrdersInfo.selectedOrderRows.filter(
          (order) => !processedOrders.includes(order.id)
        );
        handleUnassignOrdersInfo(orderRecords);
      } else {
        Modal.error({
          title: "Errors",
          content: (
            <Fragment>
              {result.errors.map((err, index) => (
                <Row>
                  <Col key={`err${index + 1}`} className="fontWeight500">
                    {err}
                  </Col>
                </Row>
              ))}
            </Fragment>
          ),
          width: "40%",
        });
        //setInProgress(false);
        setPreplanProgress(false)
        message.destroy();
      }
    });
  };

  // deleting unassigned drivers click
  // handling route delete click
  const handleRouteDeleteClick = (id) => {
    const action_type = RouteLabel.actionLabels.ROUTE_DELETED
    setRouteProgress(true);
    setInProgress(true);
    deleteRoutes( id, action_type ).then((result) => {
      if (result.success) {
        const checkedList = assignInfo.checkedList
          ? [ ...assignInfo.checkedList ]
          : [];
        if (checkedList.includes(id)) {
          checkedList.splice(checkedList.indexOf(id), 1);
        }
        const unprocessed_orders = result.nav_route.unprocessed_orders || [];
        promptUnprocessedOrders(
          currentRoute.displayName,
          unprocessed_orders,
          currentRoute.displayName
        );
        alertMessage(I18n.t("messages.preplan_route_deleted"));
        const responseData = unlinkRouteFromUsers(id);
        let updatedRoute = currentRoute
        let showOnMapRoute = showOnMap;
        let currentstopInfo = currentStop
        if (
          currentRoute &&
          currentRoute.route_id === id
        ) {
          if (responseData.routeInfo.length > 0) {
            [updatedRoute ]= responseData.routeInfo;
            if (!checkedList.includes(updatedRoute.route_id)) {
              checkedList.push(updatedRoute.route_id);
            }
          } else {
            updatedRoute = {};
          }
          currentstopInfo = {}
          showOnMapRoute = false;
        }
        setStopSelectedOrders({ 
          selectedKeys: [], 
          selectedRows: [] 
        });
        setInProgress(false);
        handleCheckboxChange(checkedList);
        setRouteInfo(responseData.routeInfo);
        setRouteProgress(false);
        setUsers(responseData.users);
        setEditForm(false);
        setCurrentStop(currentstopInfo);
        setCurrentRoute(updatedRoute);
        setShowOnMap(showOnMapRoute);
        setRouteKey(randomNumber());
        manageDeleteResponse(result.nav_route, "route");
      } else {
        if (result && result.refresh === true) {
          resetPreplan(false, true);
        } else {
          resetPreplan(false, true);
        }
        renderAlertMessage(result.errors)
        setRouteProgress(false);
        setInProgress(false);
      }
    });
  };
  // deleting orders from route
  const checkRouteOrders = (id, routeId, isOutOfPreplan) => {
    const route = _.find(routeInfo, { route_id: routeId });
    const selectedOrders = _.isArray(id) ? [...id] : id.split(',');
    if(!isEmpty(route) && _.isObject(route)) {
      let removalIds = [];
      selectedOrders.forEach((currentRecord) => {
        const dependencies = findDependencyOrders(
          route.routeOrders,
          "related_order",
          "customer_order_id",
          currentRecord,
          "id",
          "type_of_order"
        );
        removalIds = [].concat(removalIds, dependencies);
     });
      removalIds = _.uniq(removalIds);
      if (route.customer_order_ids.length > removalIds.length) {
        handleOrderDeleteFromRoute(routeId, removalIds, isOutOfPreplan); 
      } else {
        handleRouteDeleteClick(routeId);
      }
    }
  };
  // handling unassigned_orders from the responses while apt upadte, route / stop / order delete
  const manageDeleteResponse = (result, type = "route", isOutOfPreplan = false) => {
    const unassigned_orders = result.unassigned_orders.map((customerOrder) => {
      const order = setOrderResponse(customerOrder);
      return order;
    });
    let allTotalOrders = totalOrders , allOrdersInfo = orderInfo
    if (!isOutOfPreplan) {
      allTotalOrders = [].concat(totalOrders, unassigned_orders)
      allOrdersInfo = [].concat(orderInfo, unassigned_orders)
    }
    if (type === "route") {
      setTotalOrders(allTotalOrders);
      setOrderInfo(allOrdersInfo);
      // handleMapData();
    } else {
      setTotalOrders(allTotalOrders);
      setOrderInfo(allOrdersInfo);
      const navRoute = result.nav_route ? result.nav_route : {};
      if (
        (isEmpty(navRoute.stops) || navRoute.stops.length === 0) &&
        navRoute.nav_route_id
      ) {
        handleRouteDeleteClick(navRoute.nav_route_id);
      } else {
        updateRouteInfo([ navRoute ], () => {
        resetRouteNStop(
          false, "", routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop,currentStop ,setCurrentRoute,setAssignInfo
        );
        },routeInfo , users
        );
      }
    }
  };

  useEffect(() => {
    handleMapData();
  }, [orderInfo])
  

  // handling order delete from route
  const handleOrderDeleteFromRoute = (routeId, ids, isOutOfPreplan) => {
    setInProgress(true)
    const route = _.find(routeInfo, { route_id: routeId });
    const currentRoute = !isEmpty(route) && _.isObject(route) ? route : {};
    setOrderProgress(true);
    setCurrentRoute(currentRoute);
    const data = {
      nav_route_id: routeId,
      customer_order_ids: ids,
      action_type: RouteLabel.actionLabels.ORDER_DELETED,
    };
    deleteOrderFromRoute(data).then((result) => {
      if (result.success) {
        setOrderProgress(false);
        setEditForm(false);
        setInProgress(false);
        const unprocessed_orders = result.nav_route.unprocessed_orders || [];
        promptUnprocessedOrders(
          currentRoute.displayName,
          unprocessed_orders,
          routeId
        );
        if(result.nav_route && result.nav_route.nav_route && result.nav_route.nav_route.customer_order_ids.length > 0){
          const selectedRows = stopSelectedOrders.selectedRows.filter(order => result.nav_route.nav_route.customer_order_ids.includes(order.customer_order_id))
          setStopSelectedOrders({ 
            selectedKeys: selectedRows.map((order) => order.customer_order_id), 
            selectedRows: [...selectedRows] 
          });
        } else {
          setStopSelectedOrders({ 
            selectedKeys: [], 
            selectedRows: [] 
          });
        }
        alertMessage(I18n.t("messages.route_order_deleted"));
        manageDeleteResponse(result.nav_route, "order", isOutOfPreplan);
      } else {
        renderAlertMessage(result.errors)
        resetPreplan(false, true);
        setOrderProgress(false);
        setInProgress(false);
      }
    });
  };
  // handling stop delete from route
  const handleStopDelete = (routeId, ids) => {
    const route = _.find(routeInfo, { route_id: routeId });
    const stops = route.stops ? route.stops : [];
    let stopIdsToDelete = [];
    // const removalIds = findDependencyStops(route, id, "id", "stops");
    ids.forEach((id) => {
      const removalIds = findDependencyStops(route, id, "id", "stops");
      stopIdsToDelete = [...stopIdsToDelete, removalIds];
    });
    stopIdsToDelete = _.flattenDeep(stopIdsToDelete);
    const csDepos = stops.filter(
      (stop) => stop.location && stop.location.l_type === "CS"
    );
    if (csDepos.length === stopIdsToDelete.length) {
      handleRouteDeleteClick(routeId);
    } else {
      const currentRoute = !isEmpty(route) && _.isObject(route) ? route : {};
      setStopProgress(true);
      setCurrentRoute(currentRoute);
      const data = {
        nav_route_id: routeId,
        stop_ids: stopIdsToDelete,
        action_type: RouteLabel.actionLabels.STOP_DELETED
      };
      deleteStopFromRoute(data).then((result) => {
        if (result.success) {
          const unprocessed_orders = result.nav_route.unprocessed_orders || [];
          promptUnprocessedOrders(
            currentRoute.displayName,
            unprocessed_orders,
            routeId
          );
          setStopProgress(false);
          setEditForm(false);
          alertMessage(I18n.t("messages.route_stop_deleted"));
          manageDeleteResponse(result.nav_route || {}, "stop");
        } else {
          resetPreplan(false, true);
          renderAlertMessage(result.errors)
          setStopProgress(false);
        }
      });
    }
  };

  const handleServiceDuration = (routeId) => {
    const service_durations = currentRoute.service_durations;
    const data = {
      nav_route_id: routeId,
      service_durations: Object.keys(service_durations).filter(stop => service_durations[stop].duration != service_durations[stop].actual_duration).map((stop) => ({
        stop_id: stop,
        service_duration: service_durations[ stop ]?.duration ? service_durations[ stop ].duration : 0,
      })),
    };
    if(data.service_durations.length > 0){
      updateAllStopsServiceDuration(data).then((result) => {
        if (result.success) {
          if (result.nav_route) {
            const navRoute = result.nav_route || {};
            optimizeRoute(routeId);
          }
          alertMessage(
            result.message
              ? result.message
              : I18n.t("messages.service_duration_updated")
          );
        } else {
          alertMessage(result.error, "error", 10);
        }
      }).finally(() => {
        setServiceDurationReset(true);
      });
    } else {
      setServiceDurationReset(true);
      alertMessage("No changes to save", "error", 10);
    }
    
  };


  useEffect(() => {
    if (optimizationCheckAll) {
      const nonProcessedRoutes = optimizationFromMapstatus.filter(
        (statusObj) => statusObj.isProcessed === false
      );
      const statusFalseRoutes = optimizationFromMapstatus.filter(
        (statusObj) => statusObj.status === false
      );
      const requireRefresh = optimizationFromMapstatus.filter(
        (statusObj) => statusObj.refresh === true
      );
      if (requireRefresh.length > 0) {
        resetPreplan(false, true);
      }

      if (nonProcessedRoutes.length === 0) {
        const unprocessedErrors = optimizationFromMapstatus.map(
          (err) => ({
            displayName: err.full_name,
            unprocessed_orders: err.unprocessed_orders || [],
          })
        );
        promptUnprocessedOrders(
          "Optimization",
          unprocessedErrors,
          "Optimization",
          "modal"
        );
        if (
          !statusFalseRoutes.length === optimizationFromMapstatus
        ) {
          message.success({
            content: I18n.t("messages.optimization_success"),
            duration: 5,
          });
        }

        setOptimizationProgress(false);
        goToTab("2");
      }
    }
  }, [optimizationFromMapstatus])
  

  const updateOptimizationStatus = (routeId, value, checkAll = false) => {
    const optimizationStatus = [ ...optimizationFromMapstatus ];
    const statusRecordIndex = _.findIndex(optimizationStatus, [
      "route_id",
      routeId,
    ]);
    optimizationStatus[ statusRecordIndex ] = Object.assign(
      {},
      optimizationStatus[ statusRecordIndex ],
      { ...value }
    );
    setOptimizationCheckAll(checkAll);
    setOptimizationFromMapstatus(optimizationStatus);
  };

  const doOptimizeBulkRoutes = (routes, callOrders = true, lockedRoutes = []) => {
    if (lockedRoutes.length > 0) {
      lockedRoutes.forEach((route) => {
        notification.error({
          key: route.route_id,
          message: route.displayName,
          description: (
            <div>{ I18n.t("messages.locked") }</div>
          ) /* getLockedUserName(route) */,
          placement: "bottomRight",
        });
      });
    }
    if (routes.length > 0) {
      setOptimizationFromMapstatus(routes);
      setOptimizationProgress(true);

      routes.forEach((route) => {
        notification.open({
          key: route.route_id,
          message: route.full_name,
          description: <div>{ I18n.t("messages.optimization_process") }</div>,
          icon: <Icon type="loading" style={ { color: "#108ee9" } } />,
          placement: "bottomRight",
          duration: 20,
        });
        const isPinned = route.route_id === currentRoute.route_id ? route.pinned : '';
        fetchPreplanOptimizedStops(
          route.route_id,
          isPinned,
          route.stick_start_time
        ).then((result) => {
          if (result.success) {
            setInProgress(false);
            const errorMessage =
              result.optimize_route.optimizer_readable_error_message;
            const unprocessed_errors =
              result.optimize_route.unprocessed_orders || [];
            const unprocessed_orders = [ ...unprocessed_errors ];
            if (result.optimize_route && result.optimize_route.nav_route) {
              updateRouteInfo([ result.optimize_route.nav_route ], () => {
                resetRouteNStop(
                  false, "", routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop,currentStop,setCurrentRoute,setAssignInfo
                );
              } , routeInfo,users);
            }
            if (result.optimize_route.optimized === false) {
              notification.error({
                key: route.route_id,
                message: route.full_name,
                description: <div>{ errorMessage }</div>,
                placement: "bottomRight",
              });
            } else {
              notification.success({
                key: route.route_id,
                message: route.full_name,
                description: (
                  <div>{ I18n.t("messages.route_optimization_success") }</div>
                ),
                placement: "bottomRight",
              });
            }
            updateOptimizationStatus(
              route.route_id,
              {
                inProgress: false,
                status: true,
                unprocessed_orders,
                isProcessed: true,
                refresh: result.refresh === true,
              },
              true,
              callOrders
            );
          } else {
            const errors = result.errors || [];
            if (
              result.optimize_route &&
              result.optimize_route.optimizer_readable_error_message
            ) {
              errors.push(
                result.optimize_route.optimizer_readable_error_message
              );
            }
            const unprocessed_orders =
              result.optimize_route && result.optimize_route.unprocessed_orders
                ? result.optimize_route.unprocessed_orders
                : [];
            notification.error({
              key: route.route_id,
              message: route.full_name,
              description: errors.join(","),
              placement: "bottomRight",
            });
            updateOptimizationStatus(
              route.route_id,
              {
                inProgress: false,
                status: false,
                unprocessed_orders,
                isProcessed: true,
                refresh: result.refresh === true,
              },
              true
            );
            // message.error({ content: result.optimize_route.optimizer_readable_error_message, key: 'preplan', duration: 5 });
          }
        });
      });
    } else {
      // this.closeAssignFormMap();
    }
  };

  const navigateMainScreen = (action = "list") => {
    
    const gotopath = showBeta ? "/preplan_management_beta" : "/preplan_management";
    // props.history.goBack();
    if (action === 'list') { //&& filteredInfo.length === 0
      props.history.push({
        pathname: gotopath,
      });
    } else if(action === 'back'){
      // history remove last route
      props.history.replace({ pathname: gotopath });
    } else {
      const preplanDate = moment(filter.startDate).format();
      props.history.push({
        pathname: "/routes",
        screenName: "PREPLAN",
        reqDate: preplanDate,
        relatedId: currentPreplan.id,
      });
    }
  };


  const handleAssignWindowClose = (
    navRoute = {},
    taskParamType = "",
    unprocessed_orders = []
  ) => {
    promptUnprocessedOrders(
      "",
      [ { displayName: "", unprocessed_orders } ],
      "",
      "modal"
    );
    const taskType = assignTaskType;
    const { selectedOrderKeys } = unassignedOrdersInfo;
    let totalOrdersInfo = [ ...totalOrders ];
    let currentOrderInfo = [ ...orderInfo ];
    let usersInfo = [ ...users];
    if (taskType === "optimize" && !isEmpty(navRoute.nav_route_id)) {
      totalOrdersInfo = totalOrders.filter(
        (order) => !selectedOrderKeys.includes(order.id)
      );
      currentOrderInfo = orderInfo.filter(
        (order) => !selectedOrderKeys.includes(order.id)
      );
    }
    if (
      taskType === "drivers" &&
      !isEmpty(navRoute) &&
      _.isObject(navRoute) &&
      Object.keys(navRoute).length > 0
    ) {
      const primaryIndex = _.findIndex(usersInfo, [
        "id",
        processingRoute.driversInfo.primary.driverId,
      ]);
      if (primaryIndex >= 0) {
        usersInfo[ primaryIndex ] = assignRouteInfo(
          usersInfo[ primaryIndex ],
          primaryIndex,
          {},
          true
        );
      }
      const secondaryIndex = _.findIndex(usersInfo, [
        "id",
        processingRoute.driversInfo.secondary.driverId,
      ]);
      if (secondaryIndex >= 0) {
        usersInfo[ secondaryIndex ] = assignRouteInfo(
          usersInfo[ secondaryIndex ],
          secondaryIndex,
          {},
          true
        );
      }
    }
    setAssignModal(false);
    setAssignTaskType("optimize");
    setDriversInfo(AppConfig.driversInfo);
    setUnassignedOrdersInfo({
      selectedOrderKeys: [],
      selectedOrderRows: [],
    });
    setTotalOrders(totalOrdersInfo);
    setOrderInfo(currentOrderInfo);
    setProcessingRoute({});
    setUsers(usersInfo);
    if (!isEmpty(navRoute.nav_route_id)) {
      updateRouteInfo([ navRoute ], () => {
        resetRouteNStop(
          false, "", routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, currentStop, setCurrentRoute ,setAssignInfo
        );
      },routeInfo,usersInfo
      );
    }
  };

  const renderAssignModal = () => {
    const title =
      assignTaskType !== "move"
        ? `${I18n.t("preplan.allocate")} ${I18n.t("general.Driver")}`
        : moveInfo.type === "move_stop"
          ? I18n.t("preplan.move_stop")
          : I18n.t("preplan.move_order");
    let showExistDrivers = false;
    let usersWithStops = [...users];
    if (assignTaskType === "drivers") {
      usersWithStops = users.filter(
        (user) => user.stops && user.stops.length === 0
      );
      showExistDrivers = false;
    }
    return (
      <BaseModal
        title={ title }
        width="450px"
        onCancel={ () => handleAssignWindowClose({}) }
        maskClosable={ false }
      >
        <Assignment
          routeInfo={ routeInfo }
          preplanId={ preplanId }
          routeId={ processingRoute.route_id }
          primaryDriver={ driversInfo.primary.driverId }
          secondaryDriver={ driversInfo.secondary.driverId }
          truck=""
          closeWindow={ handleAssignWindowClose }
          showTruck={ false }
          showExistDrivers={ showExistDrivers }
          users={ usersWithStops }
          assignTaskType={ assignTaskType }
          selectedOrders={
            unassignedOrdersInfo.selectedOrderKeys || []
          }
          selectedOrderRecords={
            unassignedOrdersInfo.selectedOrderRows || []
          }
          driver_stop_existed={ currentPreplan.driver_stop_existed }
          moveInfo={ moveInfo }
          doCapacityValidation={doCapacityValidation}
          setStopProgress={setStopProgress}
        />
      </BaseModal>
    );
  };

  const hideRouteConfirmModal = () => {
    setRouteConfirmModal(false);
  };
  const showRouteConfirmModal = () => {
    setRouteConfirmModal(true);
  };
  const handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  const getMarkerId = (stopId, route, type) => {
    if (type === "click" && !rearrangeForm) {
      if (currentRoute.route_id !== route) {
        setStopProgress(true);
        handleCurrentRoute({}, route, "" , null , {}, stopId, "other");
        setStopProgress(false);
      } else {
        handleCurrentStop({}, stopId, "other");
      }
    }
    setSelectedStopMarkerId(stopId);
  };

  const handleStopNOrders = (ids,latestCurrentRoute) => {
    let orders = []; // ...selectedRows
    const stopOrders =
      latestCurrentRoute && latestCurrentRoute.routeOrders
        ? latestCurrentRoute.routeOrders
        : [];
    const selectedRowOrders = stopOrders.filter((order) =>
      ids.includes(order.stop_id)
    );
    const selectedKeys = selectedRowOrders.map((d) => d.id);
    orders = handleDependencies(
      stopOrders,
      [],
      selectedKeys,
      selectedRowOrders,
      "id",
      "related_order"
    );
    orders = _.uniqBy(orders, "id");
    setStopSelectedOrders({ selectedKeys: orders.map((order) => order.id), selectedRows: orders });
  };

  const renderRouteConfirmModal = () => {
    const routes = routeInfo.map((route) => {
      const isOptimized = isRouteOptimized(route);
      return { ...route, isOptimized };
    });
    return (
      <BaseModal
        title={ I18n.t("preplan.route_file_generate") }
        width="80%"
        onCancel={ () => hideRouteConfirmModal() }
        style={ { top: 20 } }
      >
        <GenerateRouteFile
          routeInfo={ routes }
          currentPreplan={ currentPreplan }
          onCancel={ () => hideRouteConfirmModal() }
          onSuccess={ (message) => handleSaveSuccess(message) }
        />
      </BaseModal>
    );
  };
  const handleMapData = (latestRouteInfo) => {
    const routes = latestRouteInfo?.length > 0 ? latestRouteInfo : routeInfo;

    const cleanedRoutes = routes.filter(route => route.stops.length > 0)
      .map(route => {
        const cleanedStops = route.stops.filter(({ location }) => {
          const { l_address } = location || {};
          const { coordinates } = l_address || {};
          return coordinates && coordinates.length > 1;
      });

      const stop_markers = cleanedStops.map((stop) => ({
        lat: stop.location.l_address.coordinates[ 1 ],
        lng: stop.location.l_address.coordinates[ 0 ],
        location_type: stop.location.l_type,
        ...stop,
      }))

      return { ...route, stops: cleanedStops, stop_markers };
    });

    setMapData(cleanedRoutes);
    setStopProgress(false);
  };  

  // const handleMapData = (lastestRouteInfo) => {
  //   const cleanedRoutes = (lastestRouteInfo && lastestRouteInfo.length > 0) ? lastestRouteInfo.filter(
  //     (route) => route.stops.length > 0
  //   ) : routeInfo.filter(
  //     (route) => route.stops.length > 0
  //   );
  //   const cleanedStops = [];
  //   cleanedRoutes.forEach((route) => {
  //     const cleanedRoute = Object.assign({}, route, { stops: [] });
  //     route.stops.map((stop) => {
  //       if (
  //         !isEmpty(stop.location) &&
  //         !isEmpty(stop.location.l_address) &&
  //         !isEmpty(stop.location.l_address.coordinates) &&
  //         stop.location.l_address.coordinates.length > 1
  //       ) {
  //         cleanedRoute.stops.push(stop);
  //       }
  //     });
  //     cleanedStops.push(cleanedRoute);
  //   });

  //   setMapData(cleanedStops);
  //   setStopProgress(false);
  // };

  // const handleCheckboxChange = (checkedList) => {
  //   const unlockedRoutes = routeInfo.filter(route => !route.isLocked && checkedList.includes(route.route_id));
  //   // const checkedElementsInUnlocked = unlockedRoutes.filter(route => checkedList.includes(route.route_id));
  //   setAssignInfo({
  //     ...assignInfo,
  //     checkedList,
  //     indeterminate:
  //       checkedList.length > 0 &&
  //       checkedList.length < unlockedRoutes.length,
  //     checkAll: unlockedRoutes.length === checkedList.length,
  //   });
  //   //checkedList.length === routeInfo.length,
  //   setRouteMouseOperation(false);
  // };


  const handleCheckboxChange = (checkedList) => {
    setAssignInfo({
      ...assignInfo,
      checkedList,
      indeterminate:
        checkedList.length > 0 &&
        checkedList.length < routeInfo.length,
      checkAll: checkedList.length === routeInfo.length,
    });
    setRouteMouseOperation(false);
  };

  const handleOnChange = (element) => {
    if (element === "assignInfo") {
      if (assignInfo.checkAll) {
        handleCheckboxChange([]);
      } else {
        const checkedList = routeInfo
          .filter((route) => !route.isLocked)
          .map((route) => route.route_id);
        handleCheckboxChange(checkedList);
      }
    } else if (element === "routeView") {
      // setRouteView( routeView === "byStops" ? "byOrders" : "byStops");
      // setServiceDurationReset(true);
    }
  };

  const handleUpdateSequence = (actionFrom = "", reverse = false) => {
    setStopProgress(true);
    let rearranged_data = [];
    let errorStops = [];
    if (reverse) {
      const routeCopy = _.cloneDeep(currentRoute);
      const reversedStops = reverseStopsWithMoves(
        currentRoute.stops,
        "stop_number",
        "desc",
        1
      );
      routeCopy.stops = [...reversedStops];
      rearranged_data = reversedStops.map((stop, index) => ({
        stop_id: stop.id,
        seq_number: stop.stop_number,
      }));
      errorStops =  dependencyValidation(routeCopy);
    } else {
      errorStops =  dependencyValidation(currentRoute);
      rearranged_data = currentRoute.stops.map((stop, index) => ({
        stop_id: stop.id,
        seq_number: stop.stop_number,
      }));
    }
      if(errorStops.length === 0) {
         const updateStopsData = {
          stops: rearranged_data,
          nav_route_id: currentRoute.route_id,
          m_sequence_lock: true,
          stick_start_time: currentRoute.stick_start_time,
        };
        updateStopsData.action_type = actionFrom;
        updateStopsOrder(currentRoute.route_id, updateStopsData).then(
          (result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.stops_rearranged"));
              setStopProgress(false);
              setRearrangeForm(false);
              const navRoute = result.nav_route ? result.nav_route : {};
              updateRouteInfo([ navRoute ], () => {
                resetRouteNStop(
                  false, "", routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, currentStop, setCurrentRoute,setAssignInfo 
                );
              },routeInfo,users
              );
            } else {
              const message = result.errors[0];
              if(message === "Invalid stops" && !isEmpty(result.nav_route)){
                setRearrangeForm(false);
                Modal.confirm({
                  title: "Errors",
                  content: (
                    <Fragment>
                      <div>Route is updated in the background, do you want to load the updated route ?</div>
                      {/* <div className="marginTop10">{I18n.t('messages.still_proceed')}</div> */}
                    </Fragment>
                  ),
                  width: "40%",
                  onOk: () => {
                    const navRoute = result.nav_route ? result.nav_route : {};
                      updateRouteInfo([ navRoute ], () => {
                      resetRouteNStop(
                        false, "", routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, currentStop, setCurrentRoute,setAssignInfo 
                      );
                    },routeInfo,users
                    );
                  },
                  okText: "Yes",
                  cancelText: "No",
                  onCancel: () => {
                    setStopProgress(false);
                  }
                });
              } else{
                alertMessage(message, "error", 10);
              }
              setStopProgress(false);
              
            }
          }
        );
      }else {
         setStopProgress(false);
         Modal.error({
          title: `Pickup should be before the delivery`,
          content: <div>
          <h4>Please check the sequence of the following orders before proceeding.</h4>
           <span className="textBold">{errorStops.join(',')}</span>
          </div>,
          width: '40%',
        })
         
      }
       
  };

  const handleLockChange = (routeIds, lockRoute) => {
    const routes = [ ...routeInfo ];
    if(_.isString(routeIds)){
      routeIds = [routeIds];
    }
    const currentUser = userStore.getStateValue("id");
    setRouteProgress(true);
    changeLockOfMultipleRoute(routeIds, lockRoute).then((result) => {
      if (result.success) {
        const sucessMsg = result.message
          ? result.message
          : I18n.t("messages.lock_changed");
        alertMessage(sucessMsg);
        if(routeIds.length > 0) {
          routeIds.map(routeId => {
             const navRouteIndex = _.findIndex(routes, [ "route_id", routeId ]);
              if (navRouteIndex >= 0) {
                const navRoute = routes[ navRouteIndex ];
                navRoute.isLocked = false; // false;
                navRoute.locked = lockRoute; // !navRoute.locked;
                navRoute.locked_by = lockRoute ? currentUser : "";
                routes[ navRouteIndex ] = navRoute;
                if(currentRoute.id === navRoute.id){
                  setCurrentRoute(navRoute);
                }
              }
              setRouteProgress(false);
              setRouteInfo(routes);
          });  
        }
      } else {
        setStopProgress(false);
        alertMessage(result.error, "error", 10);
      }
    }).finally(() => {
    });
  };

  const handleCancelResequence = () => {
    const route = _.find(routeInfo, {
      route_id: currentRoute.route_id,
    });
    if (!_.isNil(route) && _.isObject(route)) {
      setCurrentRoute(route);
      setRearrangeForm(false);
    }
  };


  const resetPreplan = (
    refreshOrders = true,
    resetCurrentRoute = false,
    cb = null
  ) => {
    if (resetCurrentRoute) {
      getPreplanInfo(refreshOrders, (routeInfo) => {
        resetRouteNStop(
          false, "", routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, currentStop, setCurrentRoute,setAssignInfo
        );
      });
    } else {
      getPreplanInfo(refreshOrders, cb);
    }
  };




  const assignDriver = (id, _type) => {
    const route = _.find(routeInfo, { route_id: id });
    if (!_.isNil(route) && _.isObject(route)) {
      setProcessingRoute(route);
      setDriversInfo(route.driversInfo || {});
      setAssignTaskType("drivers");
      setAssignModal(true);
    }
  };

  const showResequence = (id) => {
    const route = _.find(routeInfo, { route_id: id });
    if (!_.isNil(route) && _.isObject(route)) {
      setCurrentRoute(route);
      setRearrangeForm(true);
      setShowOnMap(true);
    }
  };

  const optimizeRoute = (id) => {
    const route = _.find(routeInfo, { route_id: id });
    if (!_.isNil(route) && _.isObject(route)) {
      setCurrentRoute(route);
      getOptimizeInfo([ id ] , {} ,routeInfo, doOptimizeBulkRoutes, currentWarehouse);
    };
  };

  const resetSelectedOrders = () => {
    handleUnassignOrdersInfo([]);
  };

  const resetSelectedAvailableDrivers = () => {
    setCheckedAvailableDrivers({
      ...checkedAvailableDrivers,
      selectedKeys: [],
      selectedRows: [],
    });
  };

  const handleAvailableDriversChange = (selectedRowKeys, selectedRows) => {
    setCheckedAvailableDrivers({ checkedAvailableDrivers, selectedKeys: selectedRowKeys, selectedRows });
  };

  const handleAvailableDriversClose = () => {
    setShowAvailableDrivers(false);
    resetSelectedAvailableDrivers();
  };

  const renderDrivers = () => {
    const selectedRowKeys = checkedAvailableDrivers.selectedKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: handleAvailableDriversChange,
      getCheckboxProps: null,
    };
    return (
      <PreplanDrivers
        handleAvailableDriversClose={ handleAvailableDriversClose }
        availableDrivers={ availableDrivers }
        rowSelection={ rowSelection }
        resetSelectedAvailableDrivers={ resetSelectedAvailableDrivers }
        handleAddDriversToPreplan={ handleAddDriversToPreplan }
        inProgress={ inProgress }
        checkedAvailableDrivers={ checkedAvailableDrivers }
      />
    );
  };

  const showRouteTimeModal = () => {
    setEditRouteTimeModal(true);
  };

  const closeRouteTimeModal = (
    isModified,
    navRoute,
    startDateTime,
    stick_start_time
  ) => {
    // start_date_time
    setEditRouteTimeModal(false);
    if (isModified) {
      setCurrentRoute(Object.assign({}, currentRoute, { route_start_datetime: startDateTime, stick_start_time }));
      getOptimizeInfo([ navRoute ], { [ navRoute ]:  {stick_start_time}  } , routeInfo,doOptimizeBulkRoutes,currentWarehouse);
    }
  };

  const renderRouteTimeEditModal = () => (
    <TimeEditWindow
      route={ currentRoute }
      closeWindow={ closeRouteTimeModal }
    />
  );

  const handleAssignInfo = () => {
    setAssignModal(true);
    setProcessingRoute({});
  }

  const handleAddDriversToPreplan = () => {
    const { id } = currentPreplan;
    const { selectedKeys, selectedRows } = checkedAvailableDrivers;
    const { selectedOrderRows } = unassignedOrdersInfo;
    setInProgress(false);
    updatePreplan({ driver_ids: selectedKeys, pre_plan_id: id }).then(
      (result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          if (selectedKeys.length === 1 && selectedOrderRows.length > 0) {
            const driverRow = _.find(selectedRows, {
              id: selectedKeys[ 0 ],
            });
            const ids = selectedOrderRows.map((order) => order.id);
            const data = {
              orderNumbers: selectedOrderRows.map(
                (order) => order.customer_order_number
              ),
              order_ids: ids,
              location_ids: getLocationIds(ids, selectedOrderRows, 'locations', 'array'),
              org_id: userStore.getStateValue("selectedOrg"),
              preplan_id: currentPreplan.id,
              primary_driver_id: driverRow.id,
              routeName: driverRow.employee_code,
              secondary_driver_id: "",
            };
            checkAddressValidation(
              "add_driver", 
              data,
              unassignedOrdersInfo , 
              totalOrders, 
              resetPreplan , 
              createRoute , 
              handleUnassignOrdersInfo , 
              handleAssignInfo,
              saveAutoPreplan,
              doCapacityValidation,
              handleResponse,
              preplanId,
              handleAutoPreplan,
              setPreplanProgress,
              preplanZones,
              setStopProgress,
              setRouteProcessedErrors,
              currentPreplan,
              setUnassignedOrdersInfo
            );
          } else {
            resetPreplan(false);
          }

          handleAvailableDriversClose();
        } else {
          renderAlertMessage(result.errors)
        }
      }
    );
  };

  const updateRouteInfo = (routes, cb , latestRouteInfo , latestUsers) => {
    let userInfo = [ ...latestUsers];
    let currentRouteInfo = latestRouteInfo;
    routes.forEach((route, index) => {
      const routeId = route ? route.nav_route_id : "";
      const routeIndex = _.findIndex(currentRouteInfo, [ "route_id", routeId ]);
      if (!isEmpty(routeId)) {
        const actualRouteIndex =
          routeIndex >= 0 ? routeIndex : currentRouteInfo.length;
        const navRoute = setRouteObject(route, actualRouteIndex);
        if (navRoute) {
          navRoute.isLocked =
            navRoute.isLocked || navRoute.warehouse_id !== currentWarehouse.id;
          currentRouteInfo[ actualRouteIndex ] = navRoute;
        } else if (routeIndex >= 0) {
          // Route may not contains orders / stops
          currentRouteInfo.splice(routeIndex, 1);
          if (route.primary_driver && route.primary_driver.id) {
            userInfo = unlinkRouteDetailsFromUser(
              userInfo,
              route.primary_driver.id
            );
          }
          if (route.secondary_driver && route.secondary_driver.id) {
            userInfo = unlinkRouteDetailsFromUser(
              userInfo,
              route.secondary_driver.id
            );
          }
        }
      }
    });

    // set last route as current route
    let lastRoute = {}
    if (currentRouteInfo.length > 0) {
      const lastRouteId = routes[routes.length - 1].nav_route_id;
      lastRoute = currentRouteInfo.find((route) => route.route_id === lastRouteId);
        setCurrentRoute(lastRoute);
    }

    const sortedRouteInfo = _.sortBy(currentRouteInfo, "displayName")
    setRouteInfo(sortedRouteInfo);
    setUsers(setUsersWithRouteInfo(currentRouteInfo, userInfo));
    setRouteKey(randomNumber());
      if (cb) {
        cb(sortedRouteInfo,lastRoute);
      }
      handleMapData(sortedRouteInfo);
  };


  const removeRoutesFromRoutesData = (routeIds) => {
    const navRoutes = routeInfo.filter(
      (route) => !routeIds.includes(route.route_id)
    );
    return navRoutes;
  };

  const unlinkRouteFromUsers = (navRouteId) => {
    const filteredRoutes = removeRoutesFromRoutesData([ navRouteId ]);
    const route = _.find(routeInfo, { route_id: navRouteId });
    let unlinkedUsers = users;
    if (!isEmpty(route)) {
      if (route.driversInfo && route.driversInfo.primary) {
        unlinkedUsers = unlinkRouteDetailsFromUser(
          unlinkedUsers,
          route.driversInfo.primary.driverId
        );
      }
      if (route.driversInfo && route.driversInfo.secondary) {
        unlinkedUsers = unlinkRouteDetailsFromUser(
          unlinkedUsers,
          route.driversInfo.secondary.driverId
        );
      }
      return { routeInfo : filteredRoutes, users: unlinkedUsers };
    } else{
      return { routeInfo, users };
    }
  };


  const updateRouteResponse = (nav_route) => {
    if (!isEmpty(nav_route)) {
      const navRoute = nav_route || {};
      updateRouteInfo([ navRoute ], () => {
        resetRouteNStop(true, navRoute.nav_route_id, routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, currentStop, setCurrentRoute , setAssignInfo);
      } , routeInfo , users);
        resetRouteNStop(true, navRoute.nav_route_id, routeInfo, handleCurrentRoute, currentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, currentStop, setCurrentRoute ,setAssignInfo);
    }
  };

  const createRoute = (data) => {
    notification.open({
      key: data.primary_driver_id,
      message: data.routeName,
      description: <div>{ I18n.t("messages.creating_route") }</div>,
      icon: <Icon type="loading" style={ { color: "#108ee9" } } />,
      placement: "bottomRight",
      duration: 20,
    });
    data.action_type = RouteLabel.actionLabels.ROUTE_CREATED
    manageRoute(data, true).then((result) => {
      if (result.success) {
        const routeTasks = result.routeTasks || {};
        const resultData = {
          nav_route: routeTasks.nav_route ? routeTasks.nav_route : {},
          unprocessed_orders: routeTasks.unprocessed_orders || [],
        };
        manageOrderAllocateProcess(
          data,
          resultData,
          data.primary_driver_id,
           totalOrders, orderInfo, setStopProgress, setTotalOrders, setOrderInfo, setUnassignedOrdersInfo, promptUnprocessedOrders, updateRouteInfo,routeInfo , handleCurrentRoute , currentRoute , routeSelectedStops , setRouteSelectedStops , handleCurrentStop,currentStop , setCurrentRoute,users,setAssignInfo
        );
      } else {
        renderAlertMessage(result.errors)
        setStopProgress(false);
        if (result.refresh) {
          resetPreplan(false, true);
        } else if (result.nav_route) {
          updateRouteResponse(result.nav_route);
        }

        notification.error({
          key: data.primary_driver_id,
          message: data.routeName,
          description: <div>{ result.errors[ 0 ] }</div>,
          placement: "bottomRight",
        });
      }
    });
  };



  const setCurrentRouteOnMap = () => {
    setShowOnMap(!showOnMap);
    setRouteMouseOperation(false);
    setClickedRouteId("");
  };

  const handleCurrentRoute = (e, id, element = "", cb = null , stopEvent , stopId, ele = "other") => {
    if (element === "routeCard") {
      promptUnprocessedOrders("", [], "", "alert");
    }

    let checkedList = [];
    if (Object.keys(assignInfo).length) {
      checkedList = assignInfo.checkedList
        ? [ ...assignInfo.checkedList ]
        : [];
    }
    if (e.ctrlKey || e.metaKey || element === "checkbox") {
      if (checkedList.includes(id)) {
        checkedList.splice(checkedList.indexOf(id), 1);
      } else {
        checkedList.push(id);
      }
      handleCheckboxChange(checkedList);
    } else {
      const route = _.find(routeInfo, { route_id: id });
      const oldCurrentRoute = currentRoute;
      if (!_.isNil(route) && _.isObject(route)) {
        handleCheckboxChange([ id ]);
        setCurrentRoute(route);
        setCurrentStop({});
        setCurrentOrder({});
        setRearrangeForm(false);
        setRouteSelectedStops([]);
        setRouteMouseOperation(false);
        setClickedRouteId(element === "routeCard" ? id : "");
        if (oldCurrentRoute.route_id !== id) {
          handleRouteOrdersInfo([], []);
        }
        handleCurrentStop({}, stopId, ele , route , true);
      } else {
        handleCheckboxChange([]);
        setCurrentRoute({});
        setCurrentStop({});
        setCurrentOrder({});
        setRearrangeForm(false);
        setRouteSelectedStops([]);
        setRouteMouseOperation(false);
        setClickedRouteId("");
        handleRouteOrdersInfo([], []);
        if (cb) {
          cb();
        }
      }
      setServiceDurationReset(true);
    }
  };


  const handleCurrentStop = (e, id, element = "", latestCurrentRoute = currentRoute , resetPreviousRouteSelection = false ) => {
    let checkedList = [];
    if (!resetPreviousRouteSelection) {
      checkedList = routeSelectedStops
        ? [ ...routeSelectedStops ]
        : [];
    } 
    const stop = _.find(latestCurrentRoute.stops, { id });
    const stopType = stopTypeFromObject(stop);
    if (
      e.ctrlKey ||
      e.metaKey ||
      element === "checkbox" ||
      element === "other"
    ) {
      if (stopType === "CS") {
        const dependencyStops = findDependencyStops(
          latestCurrentRoute,
          id,
          "id",
          "stops"
        );
        if (element === "other") {
          if (!checkedList.includes(id)) {
            checkedList = _.uniq([].concat(checkedList, dependencyStops));
          }
        } else {
          if (checkedList.includes(id)) {
            // checkedList.splice(checkedList.indexOf(id), 1);
            checkedList = checkedList.filter(
              (stopId) => !dependencyStops.includes(stopId)
            );
          } else {
            // checkedList.push(id);
            checkedList = [].concat(checkedList, dependencyStops);
          }
        }

        setRouteSelectedStops(checkedList);
        handleStopNOrders(checkedList,latestCurrentRoute);
      }
    } else if (!_.isNil(stop) && _.isObject(stop)) {
      if (stopType === "CS") {
        // checkedList = [id]
        const dependencyStops = findDependencyStops(
          latestCurrentRoute,
          id,
          "id",
          "stops"
        );
        checkedList = [ ...dependencyStops ];
      } else {
        checkedList = [];
      }
      setCurrentStop(stop);
      setRouteSelectedStops(checkedList);
      setSelectedStopCardId(id);
      setOpenedStopInfoWindow(id);
      // handleStopNOrders(checkedList);
    } else {
      setCurrentStop({});
      setOpenedStopInfoWindow("");
    }
  };

  const resetHoveredStop = () => {
    setSelectedStopMarkerId("");
    setOpenedStopInfoWindow("");
    setSelectedStopCardId("");
  };

  const renderMultipleAddressResolveModal = () => {
    return (
      <BaseModal
        className="multipleAddressResolveModal"
        title={I18n.t("order.resolve_address")}
        onCancel={() => closeMultipleAddressResolve(madeAddressChanges)}
        width={"70%"}
        style={ {
          top: 50
        }}
      >
      <ResolveMultiAddress
          unassignedOrdersInfo={unassignedOrdersInfo}
          orderInfo={orderInfo}
          closeModal={closeMultipleAddressResolve}
          setMadeAddressChanges={setMadeAddressChanges}
        />
      </BaseModal>
    );
  }

  const showMultiAddressResolve = () => { setShowMultipleAddressResolveModal(true) }
  const closeMultipleAddressResolve = (madeChanges = false) => { 
    setShowMultipleAddressResolveModal(false)
    if (madeChanges) {
      getOrders(true, unassignedOrdersInfo.selectedOrderKeys);
      setMadeAddressChanges(false);
    }
  }

  // const updateMultiAddress = (data) => {
  //   const { orderIds, newAddress } = data;
  //   setOrderInfo(orderInfo.map(order => {
  //     if (orderIds.includes(order.id)) {
  //       return {
  //         ...order,
  //         location_partial_match: false,
  //         customer_address: newAddress,
  //       };
  //     }
  //     return order;
  //   })
  //   );
  // }

  const handleOrderModified = (modified) => {
    setOrderModified(modified);
  };

  if (props.isDuplicateTab) {
    return (
      <Modal
        title="Duplicate Tab Error"
        visible={ true }
        footer={ null }
        closable={ false }
        maskClosable={ false }
      >
        <Result
          status="error"
          title="Duplicate Tab"
          subTitle="You have already opened this tab elsewhere. Click on button to navigate to preplan management screen."
          extra={ [
            <Button type="primary" key="reload" onClick={ () =>
              props.history.replace({
                pathname: "/preplan_management",
              }) }>
              Go to Preplan Management
            </Button>,
          ] }
        />
      </Modal>
    );
  }

  const updateDelRoute = (id) =>{
    const checkedList = assignInfo.checkedList
      ? [...assignInfo.checkedList]
      : [];
    if (checkedList.includes(id)) {
      checkedList.splice(checkedList.indexOf(id), 1);
    }
    const responseData = unlinkRouteFromUsers(id);
    let updatedRoute = currentRoute;
    let showOnMapRoute = showOnMap;
    let currentstopInfo = currentStop;
    if (currentRoute && currentRoute.route_id === id) {
      if (responseData.routeInfo.length > 0) {
        [updatedRoute] = responseData.routeInfo;
        if (!checkedList.includes(updatedRoute.route_id)) {
          checkedList.push(updatedRoute.route_id);
        }
      } else {
        updatedRoute = {};
      }
      currentstopInfo = {};
      showOnMapRoute = false;
    }
    setStopSelectedOrders({
      selectedKeys: [],
      selectedRows: [],
    });
    setInProgress(false);
    handleCheckboxChange(checkedList);
    setRouteInfo(responseData.routeInfo);
    setRouteProgress(false);
    setUsers(responseData.users);
    setEditForm(false);
    setCurrentStop(currentstopInfo);
    setCurrentRoute(updatedRoute);
    setShowOnMap(showOnMapRoute);
    setRouteKey(randomNumber());
    // manageDeleteResponse(result.nav_route, "route");
  }

  const updateOperation = (brodcastMsg, receivedSource) => {
    const event = brodcastMsg.source;
    const operation = brodcastMsg.action;
    const subOperation = brodcastMsg.sub_action;

    if (event === "ROUTE") {
      if (operation === "CREATED") {
        refreshRoutes(brodcastMsg.source_ids[0]);
        getOrders();
      }
      else if(operation === "UPDATED"){
        if(!["SWAPPED", "TRANSMIT_ORDERS", "TRANSMIT_STOP"].includes(subOperation)){
          refreshRoutes(brodcastMsg.source_ids[0]);
          if (["ORDER_ADDED", "ORDER_DELETED"].includes(subOperation)) {
            getOrders();
          }
        }
        else{
          getPreplanInfo(false)
          if (currentRoute?.route_id) {
            refreshRoutes(currentRoute.route_id);
          }
        }
      }
      else if(operation === "DELETED"){
        const deletedOrders = routeInfo.filter((route) => route.route_id === brodcastMsg.source_ids[0]).customer_order_ids;
        // const filteredRoute = routeInfo.filter((route) => route.route_id === brodcastMsg.source_ids[0])
        // const deletedOrders = filteredRoute?.length ? filteredRoute[0].customer_order_ids : []
        if (brodcastMsg?.source_ids.length > 0) {
          updateDelRoute(brodcastMsg.source_ids[0]);
        }
        getOrders(true, deletedOrders);
      }
    } else if (event === "ORDER") {
      let totalDependencies = [];
      brodcastMsg.source_ids.forEach((orderId) => {
        const dependentKeys = findDependencyOrders(
          totalOrders,
          "related_order",
          "id",
          orderId
        );
        totalDependencies = [].concat(totalDependencies, dependentKeys);
      });
      getOrders(true, totalDependencies);
    } else if (event === "PREPLAN" && operation === 'DELETED') {
      if (preplanId === brodcastMsg.source_ids[0]) {
        setShowPreplanDeleteInfoModal(true);
      }
    }
  };

  const renderPreplanDeleteModal = () => {
    return (
      <Modal
        title="Preplan Deleted"
        visible={true}
        footer={null}
        closable={false}
        maskClosable={false}
        className="orderAlert"
      >
        <Result
          status="error"
          title="Preplan Deleted"
          subTitle="This preplan got deleted. Click on below button to navigate to preplan management screen."
          className="resultContent"
          extra={[
            <Button
              type="primary"
              key="reload"
              onClick={() =>
                props.history.replace({
                  pathname: "/preplan_management",
                })
              }
            >
              Go to Preplan Management
            </Button>,
          ]}
        />
      </Modal>
    );
  };

  return (
    <div className="preplanMgmt">
      <BaseModal
        width="100%"
        height="100%"
        bodyStyle={ { padding: "0px" } }
        style={ {
          top: 0,
          overflowY: "hidden",
          height: "100%",
        } }
        onCancel={ () => { } }
        closable={ false }
      >
        <div className="preplanMgmt blockBgColor">
          <div className="content blockBgColor">
            { _renderHeader(currentPreplan, currentWarehouse, routeInfo, filter, navigateMainScreen , isMilitaryTime , showBeta ) }
            <Spin spinning={ preplanProgress } delay={ 1000 }>
              <DragDropContext onDragEnd={ onDragEnd }>
                <Row gutter={ activeKey === "2" ? 2 : 5 }>
                  <Col
                    span={  12 }
                    // style={ { height: "91.7vh" } }
                  >
                    <PreplanLeft
                      showBeta={ showBeta }
                      currentOrg={ currentOrg }
                      saveAutoPreplan={ saveAutoPreplan }
                      setOrderProgress={setOrderProgress}
                      resetPreplan={ resetPreplan }
                      createRoute={ createRoute }
                      handleAssignInfo={ handleAssignInfo }
                      setOrderInfo={ setOrderInfo }
                      setZone_ids={ setZone_ids }
                      setAccountCodes={ setAccountCodes }
                      setUnassignedOrdersInfo={ setUnassignedOrdersInfo }
                      findDependencyOrders={ findDependencyOrders }
                      handleUnassignOrdersInfo={ handleUnassignOrdersInfo }
                      setAssignModal={ setAssignModal }
                      setProcessingRoute={ setProcessingRoute }
                      handleMapData={ handleMapData }
                      applyFilters={ applyFilters }
                      handleEditOrderClick={ handleEditOrderClick }
                      orderInfo={ orderInfo }
                      onSearchNumbers={ onSearchNumbers }
                      filter={ filter }
                      setFilter={ setFilter }
                      preplanZones={ preplanZones }
                      unassignedOrdersInfo={ unassignedOrdersInfo }
                      goToTab={ goToTab }
                      resetSelectedOrders={ resetSelectedOrders }
                      routeInfo={ routeInfo }
                      currentPreplan={ currentPreplan }
                      totalOrders={ totalOrders }
                      getOrders={ getOrders }
                      orderProgress={ orderProgress }
                      accountCodes={ accountCodes }
                      accounts={ accounts }
                      zone_ids={ zone_ids }
                      routeProgress={ routeProgress }
                      selectedRoute={ selectedRoute }
                      showOnMap={ showOnMap }
                      setCurrentRouteOnMap={ setCurrentRouteOnMap }
                      getMarkerId={ getMarkerId }
                      selectedStopMarkerId={ selectedStopMarkerId }
                      clickedRouteId={ clickedRouteId }
                      currentStop={ currentStop }
                      handleCurrentRoute={ handleCurrentRoute }
                      rearrangeForm={ rearrangeForm }
                      openedStopInfoWindow={ openedStopInfoWindow }
                      resetHoveredStop={ resetHoveredStop }
                      routeMouseOperation={ routeMouseOperation }
                      mapData={ mapData }
                      currentWarehouse={ currentWarehouse }
                      currentRoute={ currentRoute }
                      doCapacityValidation={doCapacityValidation}
                      handleResponse={handleResponse}
                      preplanId={preplanId}
                      handleAutoPreplan={handleAutoPreplan}
                      setPreplanProgress={setPreplanProgress}
                      setStopProgress={setStopProgress}
                      setRouteProcessedErrors={setRouteProcessedErrors}
                      showMultiAddressResolve={ showMultiAddressResolve }
                      closeMultipleAddressResolve={ closeMultipleAddressResolve }
                      unsavedServiceDurationChanges={unsavedServiceDurationChanges}
                      orgSettingsForTable={orgConfig}
                      displayConfiguration={displayCOnfiguration}
                      screen_from="preplan"
                      vhTypes={vehicleTypes}
                      orderVhTypes={orderVhTypes}
                      setOrderVhTypes={setOrderVhTypes}
                      showStopScreen={showStopScreen}
                      orderTypeFilter={orderTypeFilter}
                      setOrderTypeFilter={setOrderTypeFilter}
                      orderSettings={orderSettings}
                      isMilitaryTime={isMilitaryTime}
                      setClusteredOrder={setClusteredOrder}
                      clusteredOrder={clusteredOrder}
                      setMapSelectedList={setMapSelectedList}
                      dispContext = {props.dispContext}
                    />
                  </Col>
                  <Col
                    span={ activeKey === "2" ? 12 : 12 }
                    // style={ { height: "91.7vh" } }
                  >
                    <PreplanRoutesSection
                      unlinkRouteFromUsers={ unlinkRouteFromUsers }
                      setRouteKey={ setRouteKey }
                      setInProgress={ setInProgress }
                      inProgress= {inProgress}
                      setRouteInfo={ setRouteInfo }
                      handleUpdateSequence={ handleUpdateSequence }
                      showResequence={ showResequence }
                      handleCancelResequence={ handleCancelResequence }
                      doOptimizeBulkRoutes={ doOptimizeBulkRoutes }
                      setRouteProgress={ setRouteProgress }
                      setCurrentPreplan={ setCurrentPreplan }
                      setUsers={ setUsers }
                      getDependencyOrders={ getDependencyOrders }
                      checkRouteIsEmpty={ checkRouteIsEmpty }
                      currentPreplan={ currentPreplan }
                      users={ users }
                      routeInfo={ routeInfo }
                      goToTab={ goToTab }
                      preplanTenseTime={ preplanTenseTime }
                      getAvailableDrivers={ getAvailableDrivers }
                      haveFreePackage={ haveFreePackage }
                      stopProgress={ stopProgress }
                      rearrangeForm={ rearrangeForm }
                      optimizationProgress={ optimizationProgress }
                      assignInfo={ assignInfo }
                      showRouteConfirmModal={ showRouteConfirmModal }
                      routeProgress={ routeProgress }
                      handleOnChange={ handleOnChange }
                      routeKey={ routeKey }
                      setSelectedRoute={ setSelectedRoute }
                      setRouteMouseOperation={ setRouteMouseOperation }
                      setClickedRouteId={ setClickedRouteId }
                      handleLockChange={ handleLockChange }
                      assignDriver={ assignDriver }
                      optimizeRoute={ optimizeRoute }
                      handleCurrentRoute={ handleCurrentRoute }
                      currentRoute={ currentRoute }
                      currentWarehouse={ currentWarehouse }
                      routeView={ routeView }
                      routeProcessedErrors={ routeProcessedErrors }
                      showRouteTimeModal={ showRouteTimeModal }
                      handleEditOrderClick={ handleEditOrderClick }
                      selectedStopMarkerId={ selectedStopMarkerId }
                      stopSelectedOrders={ stopSelectedOrders }
                      setOpenedStopInfoWindow={ setOpenedStopInfoWindow }
                      handleCurrentStop={ handleCurrentStop }
                      currentStop={ currentStop }
                      handleStopDelete={ handleStopDelete }
                      handleServiceDuration={ handleServiceDuration }
                      routeSelectedStops={ routeSelectedStops }
                      checkRouteOrders={ checkRouteOrders }
                      resetHoveredStop={ resetHoveredStop }
                      setCurrentRoute={ setCurrentRoute }
                      handleRouteDeleteClick={ handleRouteDeleteClick }
                      allocateToExistingRoute={ allocateToExistingRoute }
                      createRoute={ createRoute }
                      setStopProgress={ setStopProgress }
                      setRouteProcessedErrors={ setRouteProcessedErrors }
                      resetPreplan={ resetPreplan }
                      updateRouteResponse={ updateRouteResponse }
                      totalOrders={ totalOrders }
                      orderInfo={ orderInfo }
                      setTotalOrders={ setTotalOrders }
                      setOrderInfo={ setOrderInfo }
                      setUnassignedOrdersInfo={ setUnassignedOrdersInfo }
                      promptUnprocessedOrders={ promptUnprocessedOrders }
                      updateRouteInfo={ updateRouteInfo }
                      setStopSelectedOrders={ setStopSelectedOrders }
                      setRouteOrdersInfo={handleRouteOrdersInfo}
                      doCapacityValidation={doCapacityValidation}
                      preplanId={preplanId}
                      serviceDurationReset={serviceDurationReset}
                      setServiceDurationReset={setServiceDurationReset}
                      resetServiceDurationChanges={() => setServiceDurationReset(false)}
                      unsavedServiceDurationChanges={unsavedServiceDurationChanges}
                      setUnsavedServiceDurationChanges={setUnsavedServiceDurationChanges}
                      renderDurationalert={renderDurationalert}
                      driverZones={driverZones}
                      displayConfiguration={displayCOnfiguration}
                      screen_from="preplan"
                      vhType={vehicleTypes}
                      showStopScreen={showStopScreen}
                      setShowStopScreen={setShowStopScreen}
                      refreshRoutes={refreshRoutes}
                      routeLoading={routeLoading}
                    />
                  </Col>
                </Row>
              </DragDropContext>
            </Spin>
          </div>
        </div>
      </BaseModal>

      { assignModal && renderAssignModal() }

      { showAvailableDrivers && renderDrivers() }
      { editRouteTimeModal && renderRouteTimeEditModal() }

      { editForm && (
        <Drawer
          className="surveyModal"
          placement="right"
          width="90%"
          closable={ false }
          onClose={ () => { closeEditFormModal(orderModified) } }
          visible={ editForm }
          bodyStyle={ { paddingTop: 5 } }
        >
          <EditOrderForm
            customerDetails={ currentOrder }
            customerOrderId={ currentOrder.id }
            preferences={ preferences }
            handleSuccess={ (message, isOutOfPreplan, isModified, newData) =>
              handleOrderSaveSuccess(
                message,
                isOutOfPreplan,
                isModified,
                newData
              )
            }
            onCancel={ closeEditFormModal }
            callFrom="preplan"
            currentPreplan={ currentPreplan }
            onModified={ handleOrderModified }
          />
        </Drawer>
      ) }
      { routeConfirmModal && renderRouteConfirmModal() }
      { showMultipleAddressResolveModal && renderMultipleAddressResolveModal() }
      { showPreplanDeleteInfoModal && renderPreplanDeleteModal() }
      <ActionCable
        currentDate={filterPlaceHolder.startDate}
        currentWhId={warehouse_id}
        updateOperation={updateOperation}
      />
    </div>
  );
};

export default withRouter(withDuplicateTabDetection(PreplanRoute));

PreplanRoute.propTypes = {
  match: PropTypes.shape().isRequired,
};