import React, { Component, Fragment } from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;
import {
  Button,
  Col,
  DatePicker,
  FormItem,
  Input,
  Row,
  Select,
  Spin,
} from "../common/UIComponents";
import { alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import { fetchOrganizations } from "../api/Organisations";
import {
  fetchRoutes,
  fetchOrderStatusFiles,
  fetchRouteOrders,
  regenerateStatusFiles,
  putNotification,
  removeRouteFromOrder,
} from "../api/Support";
import {
  fetchUsersForWeeklyReport,
  fetchDriverRoutes,
  moveRoutesToInactive,
} from "../api/DriversApi";
import _ from "lodash";
import moment from "moment";
// import RoutesList from '../components/support/RoutesList';
import OrdersList from "../components/support/OrdersList";
import RouteDriversList from "../components/dispatches/RouteDriversList";
import OperationDriversList from "../components/dispatches/OperationsDiversList";
import ShowOrderDetails from "../components/orders/ShowOrderDetails";
import { renderAlertMessage } from "../helpers/common";

class SearchFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: {},
      organizations: [],
      organization_id: null,

      date: moment(),
      pagination: {},
      inProgress: false,
      organization: "",
      nav_routes: [],
      routes: [],
      filter: I18n.t("order.default_status"),
      files: [],
      users: [],
      driverRoutesList: [],
      driver_id: null,
      routeFiles: [],
      orderFiles: null,
      orderNo: null,
      // driverNo: null,
      selectedRoute: null,
      checks: {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      },
      activeTab: "1",
      notify_type: "",
      // driver_ID: "",
    };
  }

  componentDidMount() {
    this.getOrganizations();
    this.getRoutes();
  }

  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const orgId = _.find(result.organizations.organizations, [
          "code",
          I18n.t("account.netmove_code"),
        ])._id;
        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id: orgId,
          },
          () => {
            this.getRoutes();
            this.getUsers(); //related drivers will be loaded
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getRoutes = () => {
    this.setState({
      inProgress: true,
      routes: [],
      orderNo: null,
      routeFiles: [],
      orderFiles: null,
      notify_type: "",
    });
    fetchRoutes(this.state.organization_id, this.state.date).then((result) => {
      if (result.success) {
        this.setState({
          routes:
            result.routes && _.isArray(result.routes) ? result.routes : [],
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false, routes: [] });
      }
    });
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      checks: Object.assign({}, this.state.checks, {
        selectedOrderKeys: selectedRowKeys,
        selectedOrderRows: selectedRows,
      }),
    });
  };

  //  getUsers function for getting drivers related with particular organization
  getUsers = (refreshReports = true) => {
    const { checks } = this.state;
    const orgId = this.state.organization_id;
    this.setState({ inProgress: true });
    // const { currentDriver } = this.state
    fetchUsersForWeeklyReport(orgId).then((result) => {
      if (result.success) {
        // const users = result.users || [];
        this.setState({
          users: result.users || [], // inProgress: false,
        });

        const selectedOrderKeys = checks?.selectedOrderKeys
          ? checks.selectedOrderKeys.filter((id) => orderIds.includes(id))
          : [];
        const selectedOrderRows = checks?.selectedOrderRows
          ? checks.selectedOrderRows.filter((order) =>
              orderIds.includes(order.id)
            )
          : [];
        this.handleSelectChange(selectedOrderKeys, selectedOrderRows);
        if (this.state.driver_id) {
          this.getDriverRoutesList();
        }
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  //getDriverRoutesList function for particular driverRoutesList

  getDriverRoutesList = (refreshReports = true) => {
    const driverid = this.state.driver_id;
    this.setState({ inProgress: true });
    fetchDriverRoutes(driverid).then((result) => {
      if (result.success) {
        const driverRoutesList = result.nav_routes || [];
        this.setState({
          driverRoutesList,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false, driverRoutesList: [] });
      }
      // if (this.state.driverRoutesList.length === 0) {
      //   this.setState({ error: "No data found for the selected organization" });
      // } else {
      //   this.setState({ error: "" });
      // }
    });
  };

  handleGenerateFiles = (orderId, status) => {
    const data = {
      order_id: orderId,
      org_id: this.state.organization_id,
      status: status,
    };
    regenerateStatusFiles(data).then((result) => {
      if (result.success) {
        this.refresh();
        alertMessage(result.message, "success", 10);
        this.setState({ inProgress: false });
      } else {
        this.setState({ errors: result.errors, inProgress: false });
      }
    });
  };

  handleDelinkRoute = (orderId) => {
    const data = {
      order_id: orderId,
      org_id: this.state.organization_id,
    };
    this.setState({ inProgress: true });
    removeRouteFromOrder(data).then((result) => {
      if (result.success) {
        this.refresh();
        alertMessage(result.message, "success", 10);
        this.setState({ inProgress: false });
      } else {
        alertMessage(result.error, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  refresh = () => {
    if (this.state.activeTab === "1") {
      this.fetchOrdersByRoute(this.state.selectedRoute);
    } else if (this.state.activeTab === "2") {
      this.searchByOrderNo();
    } else {
      this.getDriverRoutesList();
    }
  };

  changeTab = (activeKey) => {
    this.setState(
      {
        activeTab: activeKey,
      },
      () => {
        if (activeKey == "1") {
          this.getRoutes();
        }
        //
      }
    );
  };

  renderOrderStatusFiles = (files) => {
    return (
      <div>
        <Row type="flex" justify="end" className="paddingBottom20">
          <Col className="marginRight10">
            <Button onClick={this.refresh} icon='redo'>Refresh</Button>
          </Col>
        </Row>
        <OrdersList
          data={files}
          scroll = {{y:"calc(100vh - 420px)"}}
          size="small"
          pagination={{ position: "none" }}
          generateClick={(orderId, status) =>
            this.handleGenerateFiles(orderId, status)
          }
          delinkRoute={(orderId) => this.handleDelinkRoute(orderId)}
        />
      </div>
    );
  };

  handleCancel = () => {
    this.setState({
      checks: Object.assign({}, this.state.checks, {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      }),
    });
  };

  handleStatusChangeCall = () => {
    const { checks, driverRoutesList } = this.state;
    const driverId = this.state.driver_id;
    // const nav_route_id=this.state.nav_route_ids;

    // const nav_route_id=this.state.nav_route_id;
    const selectedRows = checks?.selectedOrderRows || [];

    // Extract driverId and navRouteIds from the selected rows
    const navRouteIds = selectedRows.map((row) => row.id);

    // Call the API to move routes to inactive status
    moveRoutesToInactive(driverId, navRouteIds).then((result) => {
      this.setState({ inProgress: true });
      if (result.success) {
        this.setState({ inProgress: false });
        alertMessage(result.message, "success", 10);
        this.handleCancel();
        this.getDriverRoutesList();
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  renderRoutesSelection = () => {
    return (
      <Row gutter={4} type="flex">
        <Col span={8}>
          <FormItem label={I18n.t("routes.routes")} require>
            <Select
              showSearch
              placeholder="Select a route"
              value={this.state.selectedRoute || undefined}
              onChange={(e) => this.handleSelectRoute(e)}
              filterOption={(input, option) =>
                option.props.children
                  .join("")
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {this.state.routes.map((route) => (
                <Select.Option key={route.id} value={route.id}>
                  {route.r_name} - {route.driver_code} (
                  {_.get(route, "warehouse.name", "")})
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        {this.state.selectedRoute ? (
          <>
            <Col span={6}>
              <FormItem label={I18n.t("searchFiles.notify_type")}>
                <Select
                  placeholder="Select notification type"
                  onChange={(e) => this.handleFilterChange("notify_type", e)}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={I18n.t("searchFiles.value.offline")}>
                    {I18n.t("searchFiles.offline_list")}
                  </Select.Option>
                  <Select.Option value={I18n.t("searchFiles.value.update")}>
                    {I18n.t("searchFiles.update_list")}
                  </Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={4} className="paddingTop20">
              <Button
                type="primary"
                onClick={this.handleNotifySearch}
                disabled={!this.state.notify_type}
                icon='check-circle'
              >
                {I18n.t("general.send")}
              </Button>
            </Col>
          </>
        ) : null}
      </Row>
    );
  };

  renderRoutes = () => {
    const selectedRowKeys = this.state.checks.selectedOrderKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    };
    return (
      <div>
        <Tabs
          defaultActiveKey="1"
          onChange={this.changeTab}
          activeKey={this.state.activeTab}
        >
          <TabPane tab={I18n.t("support.search_by_route")} key="1">
            {this.state.routes && this.state.routes.length >= 1 ? (
              this.renderRoutesSelection()
            ) : (
              <Row>
                <Col sm={24} xs={24} md={5} lg={5} className="marginLeft10">
                  No routes found
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                {this.state.routeFiles.length >= 1 &&
                  this.renderOrderStatusFiles(this.state.routeFiles)}
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={I18n.t("support.search_by_order_no")} key="2">
            <Row>
              <Col sm={24} xs={24} md={5} lg={5} className="marginLeft10">
                <FormItem label={I18n.t("support.order_no_search")} require>
                  <Input
                    value={this.state.orderNo}
                    onChange={(e) =>
                      this.handleFilterChange("orderNo", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col
                sm={24}
                xs={24}
                md={4}
                lg={4}
                className="paddingTop20 marginLeft10"
              >
                <Button type="primary" onClick={this.searchByOrderNo} icon='search'>
                  {I18n.t("general.search")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.orderFiles &&
                  this.renderOrderStatusFiles([this.state.orderFiles])}
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={I18n.t("support.search_by_drivers")} key="3">
            <Row>
              <Col sm={24} xs={24} md={5} lg={5} className="marginLeft10">
                <FormItem label={I18n.t("support.driver_search")} require>
                  <Select
                    value={this.state.driver_id || []}
                    placeholder="Select Driver"
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterOption={(input, option) =>
                    // typeof option.props.children === 'string' &&
                    //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    style={{ width: "100%", zIndex: 1 }}
                    onChange={(e) => this.handleFilterChange("driver_id", e)}
                    dropdownMatchSelectWidth={false}
                  >
                    {this.state.users.map((user) => (
                      <Select.Option key={user.id} value={user.id}>
                        {/* {user.first_name}(user.employee_code)
                        */}
                        {user.first_name} ({user.employee_code})
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <RouteDriversList
                scroll = {{y:"calc(100vh - 395px)"}}
                data={this.state.driverRoutesList}
                rowSelection={rowSelection}
              />
            </Row>

            <Row style={{ marginTop: "20px" }}>
              {" "}
              {/* {this.state.checks.selectedOrderKeys.length > 0 && ( */}
              {this.state.checks.selectedOrderKeys.length > 0 && (
                <OperationDriversList
                  handleCancel={this.handleCancel}
                  changeStatus={this.handleStatusChangeCall}
                />
              )}
            </Row>
              {/* )} */}
            {/* <Row>
              <Col>
                {this.state.orderFiles &&
                  this.renderOrderStatusFiles([this.state.orderFiles])}
              </Col>
            </Row> */}
          </TabPane>
        </Tabs>
      </div>
    );
  };

  handleNotifySearch = () => {
    const data = {
      driver_id: this.state.driver_id,
      nav_route_id: this.state.selectedRoute,
      notification_type: this.state.notify_type,
    };
    putNotification(data).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
      } else {
        alertMessage(result.errors, "error", 10);
      }
    });
  };

  fetchOrdersByRoute = (routeId) => {
    fetchRouteOrders(routeId).then((result) => {
      if (result.success) {
        this.setState({
          routeFiles: result.route_orders,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };
  handleSelectRoute = (id) => {
    const routeIndex = _.findIndex(this.state.routes, ["id", id]);
    if (routeIndex >= 0) {
      const Data = Object.assign({}, this.state.routes[routeIndex]);
      this.setState(
        {
          inProgress: true,
          selectedRoute: id,
          driver_id: Data.driver_id,
        },
        () => {
          this.fetchOrdersByRoute(id);
        }
      );
    }
  };

  searchByOrderNo = () => {
    this.setState({ inProgress: true });
    fetchOrderStatusFiles(this.state.organization_id, this.state.orderNo).then(
      (result) => {
        if (result.success) {
          this.setState({
            orderFiles: result.order_details,
            inProgress: false,
          });
        } else {
          alertMessage(result.error, "error", 10);
          this.setState({ inProgress: false });
        }
      }
    );
  };

  handleFilterChange = (element, value, result) => {
    if (element === "organization_id" || element === "date") {
      this.setState({ [element]: value }, () => {
        this.getRoutes();
        this.getUsers();
      });
      this.setState({ driver_id: null, driverRoutesList: [] });
    }
    if (element === "driver_id") {
      this.setState({ [element]: value, driverRoutesList: [] }, () => {
        this.getDriverRoutesList();
      });
    } else {
      this.setState({ [element]: value });
    }
  };

  render() {
    // const {filter} =this.state;
    return (
      <div className="content-outer">
        <Spin spinning={this.state.inProgress} delay={1000}>
          <div className="content">
            <Row className="page-header">
              <Col xs={12}>{I18n.t("menu.route_track")}</Col>
            </Row>
            <Row>
              <Col sm={24} xs={24} md={5} lg={5}>
                <FormItem label={I18n.t("organization.single")} require>
                  <Select
                    value={this.state.organization_id || ""}
                    showSearch
                    onChange={(e) =>
                      this.handleFilterChange("organization_id", e)
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                  >
                    <Select.Option key="-1" value="null">
                      -- options --
                    </Select.Option>
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              {/*Conditional rendering applied on dates field when tab 1 and 2 selected then only date field apear for when tab 3 selected field will be disappear   */}
              {["1", "2"].includes(this.state.activeTab) && (
                <Col sm={24} xs={24} md={5} lg={5}>
                  <FormItem
                    label={I18n.t("general.date")}
                    require
                    requireColumn={false}
                  >
                    <DatePicker
                      style={{ width: "100%", marginLeft: "1%" }}
                      placeholder="Select Date"
                      format="Do MMM YYYY"
                      onChange={(e) => this.handleFilterChange("date", e)}
                      value={moment(new Date(this.state.date), "YYYY-MM-DD")}
                      defaultValue={null}
                    />
                  </FormItem>
                </Col>
              )}
            </Row>
            {this.renderRoutes()}
            {/* {!this.state.inProgress && this.renderRoutes()} */}
            {/* <div>
            <ShowOrderDetails
               order_no={} 
               order={{
                 id: record.customer_order_id,
                 customer_order_number: record.customer_order_number
               }}
            />
          </div>  */}
          </div>
        </Spin>
      </div>
    );
  }
}

export default SearchFiles;
