/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import {
  deleteAdjustment,
  fetchAdjustments,
} from '../api/AdjustmentsApi'
import {
  Button,
  Col,
  Row,
  Spin,
} from '../common/UIComponents'
import AdjustmentsList from '../components/adjustments/List'
import BaseModal from '../components/BaseModal'
import AdjustmentForm from '../components/adjustments/Form'
import { alertMessage, isEmpty } from '../common/Common'
import I18n from '../common/I18n'
import AppConfig from '../config/AppConfig'
import Configuration from '../components/adjustments/Configuration'
import SpecialDayPrice from '../components/adjustments/SpecialDayPrice'
import { checkServiceExistance, renderAlertMessage } from '../helpers/common'
import AdjConfiguration from '../components/adjustments/DriverRateMatrix'
import BeyondAreaAdjConfig from "../components/adjustments/BeyongAreaAdjConfig"
import { VehicleTypeApi } from '../api/VehicleType'

class Adjustments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adjustments: [],
      pagination: {},
      account: this.props.account || {},
      showFormModal: false,
      showConfiguration: false,
      currentAdjustment: {},
      inProgress: false,
      filter: {},
      modalElement: '',
      vehicleTypes: []
    }
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
    }
  }

  componentDidMount() {
    this.getAdjustments()
    this.getVehicleTypes();
  }

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
			    vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getAdjustments = (initial, cb) => {
    this.setState({ inProgress: true })
    const { filter } = this.state
    const page = this.tableOptions.pagination.current
    const perPage = initial ? initial.perPage : AppConfig.perPage
    if (this.state.account.id) {
      fetchAdjustments(this.state.account.id, page, perPage).then((result) => {
        if (result.success) {
          this.setState(
            {
              adjustments: result.adjustments,
              pagination: result.pagination || {},
              inProgress: false,
            },
            () => {
              if (cb) {
                cb()
              }
            },
          )
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleAddClick = () => {
    this.setState({
      currentAdjustment: {},
      modalElement: 'showFormModal',
      isNew: true,
    })
  }

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.adjustments, ['id', id])
    const adjustment = Object.assign({}, this.state.adjustments[index])
    this.setState({
      currentAdjustment: adjustment,
      modalElement: 'showFormModal',
    })
  }

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true })
    deleteAdjustment(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.getAdjustments()
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleSaveSuccess = (message) => {
    alertMessage(message)
    this.setState(
      {
        showFormModal: false,
      },
      () => {
        this.getAdjustments()
      },
    )
  }

  handleOnModalClose = (element) => {
    if (this.state[element]) {
      this.getAdjustments()
    }
    this.setState({ modalElement: '', currentAdjustment: {} })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.tableOptions = {
      pagination,
      filters,
      sorter,
    }
    this.getAdjustments()
  }

  renderFormModal = () => {
    let title
    let isNew
    if (this.state.modalElement === 'showFormModal') {
      if (this.state.currentAdjustment.id) {
        title = I18n.t('adjustments.update')
        isNew = false
      } else {
        title = I18n.t('adjustments.add')
        isNew = true
      }
    } else {
      title = I18n.t('adjustments.configuration')
      isNew = false
    }
    return this.state.modalElement === 'showFormModal' ? (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose('showFormModal')}
        width={'40%'}
        style={{ top: 20 }}
        maskClosable={false}
        keyboard={false}
      >
        <AdjustmentForm
          adjustment={
            this.state.currentAdjustment.id ? this.state.currentAdjustment : {}
          }
          isNew={isNew}
          onCancel={() => this.handleOnModalClose('showFormModal')}
          onSuccess={(message) => this.handleSaveSuccess(message)}
          account={this.state.account}
          doConfig={(record) => this.handleConfuguration(record)}
        />
      </BaseModal>
    ) : (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose('showFormModal')}
        width={'100%'}
        style={{
          top: 0,
          height: '100%',
        }}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{
          height: 'calc(100vh - 50px)',
          overflowY: 'scroll',
        }}
      >
          <Fragment>
            {this.state.currentAdjustment.category_code === 'SPLD' ? (
              <SpecialDayPrice
                adjustment={
                  this.state.currentAdjustment.id
                    ? this.state.currentAdjustment
                    : {}
                }
                isNew={false}
                onCancel={() => this.handleOnModalClose('showConfiguration')}
                onSuccess={(message) => this.handleSaveSuccess(message)}
                account={this.state.account}
              />
            ) :  this.state.currentAdjustment.category_code === 'BA' ? 
            (
              <BeyondAreaAdjConfig
                adjustment={
                  this.state.currentAdjustment.id
                    ? this.state.currentAdjustment
                    : {}
                }
                isNew={false}
                onCancel={() => this.handleOnModalClose('showConfiguration')}
                onSuccess={(message) => this.handleSaveSuccess(message)}
                account={this.state.account}
                vehicleTypes={this.state.vehicleTypes}
              />
            ) : (
              this.state.currentAdjustment.category_code === 'DVR' ?  
              <AdjConfiguration
                adjustment={
                  this.state.currentAdjustment.id
                    ? this.state.currentAdjustment
                    : {}
                }
                isNew={false}
                onCancel={() => this.handleOnModalClose('showConfiguration')}
                onSuccess={(message) => this.handleSaveSuccess(message)}
                account={this.state.account}
              />
              :
              <Configuration
                adjustment={
                  this.state.currentAdjustment.id
                    ? this.state.currentAdjustment
                    : {}
                }
                isNew={false}
                onCancel={() => this.handleOnModalClose('showConfiguration')}
                onSuccess={(message) => this.handleSaveSuccess(message)}
                account={this.state.account}
            />

            )}
          </Fragment>
      </BaseModal>
    )
  }

  renderConfigurationModal = () => {
    const title = I18n.t('adjustments.configuration')
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose('showConfiguration')}
        width="60%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <Configuration
          adjustment={
            this.state.currentAdjustment.id ? this.state.currentAdjustment : {}
          }
          isNew={false}
          onCancel={() => this.handleOnModalClose('showConfiguration')}
          onSuccess={(message) => this.handleSaveSuccess(message)}
          account={this.state.account}
        />
      </BaseModal>
    )
  }

  handleFilterOnChange = (element, value) => {
    this.setState({
      filter: Object.assign({}, this.state.filter, { [element]: value }),
    })
  }

  handleSearch = () => {
    this.tableOptions.pagination.current = 1
    this.getAdjustments()
  }

  handleConfuguration = (record) => {
    // const index = _.findIndex(this.state.adjustments, ['id', id]);
    // const adjustment = Object.assign({}, this.state.adjustments[index]);
    this.setState({
      currentAdjustment: record,
      modalElement: 'showConfiguration',
    })
  }

  render() {
    const { pagination, filter } = this.state
    return (
      <div className="content-outer">
        <div className="content">
          <Row style={{marginTop: -20, marginBottom: 10}}>
            <Col xs={12}></Col>
            <Col xs={12} className="alignRight">
              { checkServiceExistance('AADC') &&
              <Button
                type="primary"
                onClick={() => this.handleAddClick()}
                icon="plus"
              >
                {I18n.t('adjustments.add')}{' '}
              </Button>
              }
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col>
                <AdjustmentsList
                  data={this.state.adjustments}
                  scroll = {{y:"calc(100vh - 375px)"}}
                  editClick={(id) => this.handleEditClick(id)}
                  doConfig={(record) => this.handleConfuguration(record)}
                  deleteClick={(id) => this.handleDeleteClick(id)}
                  setStatus={(id) => this.handleStatusChange(id)}
                  pagination={{
                    total: pagination.total_count,
                    current: pagination.current_page,
                    pageSize: pagination.per_page,
                  }}
                  tableChange={(tablePagination, filters, sorter) =>
                    this.handleTableChange(tablePagination, filters, sorter)
                  }
                />
              </Col>
            </Row>
          </Spin>
          {!isEmpty(this.state.modalElement) && this.renderFormModal()}
          {false &&
            this.state.showConfiguration &&
            this.renderConfigurationModal()}{' '}
        </div>
      </div>
    )
  }
}
export default Adjustments
