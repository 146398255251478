
import React from "react";
import { Icon } from "antd";

export const getStatsIcon = (status) => {
  switch (status) {
    case 'PENDING':
      return <Icon type="clock-circle" theme='twoTone' twoToneColor='#ed5857' />;
    case 'RELEASED':
      return <Icon type="check-circle" theme='twoTone' twoToneColor='#008000' />;
    case "ASSIGNED":
      return <Icon type="branches" className="textAllocated" />;
    case 'DISPATCHED':
      return <Icon type="schedule" className="textDispatched" />;
    case 'COMPLETED':
      return <Icon type="check-circle" theme='twoTone' twoToneColor='#008000' />;
    default:
      return null;
  }
}

