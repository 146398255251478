import { isEmpty } from "../common/Common";
import AppConfig from "../config/AppConfig";
import { Call, ApiUrl, ParseGeneralResponse, optionalPageParams, buildQueryString, setObjectToFormData } from "./ApiUtils";

export const creditLimitApi = {

  fetchComplatedOrders: (data) => {
    const { account_ids = "", organization_id, searchToken = "", page = 1, perPage = AppConfig.perPage, wh_location_id } = data;
    let url = ApiUrl(`v2/credit_limit?operation_code=AOAD&organization_id=${organization_id}&account_ids=${account_ids}&wh_location_id=${wh_location_id}&${optionalPageParams(page, perPage, false)}`);
    if (!isEmpty(searchToken)) {
      url = `${url}&invoice_number=${searchToken}`
    }
    if (!isEmpty(account_ids)) {
      url = `${url}&invoice_number=${account_ids}`
    }


    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
        pagination: "meta.pagination",
      },
    });
  },
  updateCreditLimits: (data) => {
    data.operation_code = 'ACLU';
    const url = ApiUrl(`v2/credit_limit`);
    const method = "post"
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  updateDueAmount: (data) => {
    data.operation_code = 'ACLD';
    const url = ApiUrl(`v2/credit_limit/clear_account_due_amount`);
    const method = "post"
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "account_credit_limit" },
    });
  },
  updateOrderDueAmount: (data) => {
    data.operation_code = 'AODU';
    const url = ApiUrl(`v2/credit_limit/clear_account_invoice_due_amount`);
    const method = "post"
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  fetchOrderDueDetails: (data) => {
    let url = ApiUrl(`v2/credit_limit/account_invoice_lines_details?operation_code=OIDD&${buildQueryString(data)}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { invoice_lines: "invoice_lines", total_amount: "total_amount" },
    });
  },
  fetchPaymentHistory: (data) => {
    let url = ApiUrl(`v2/credit_limit/invoice_credit_activities?operation_code=OIPH&${buildQueryString(data)}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { order_credit_due_activities: "order_credit_due_activities" },
    });
  },
  updateSaveDetails: (payload) => {
    let url = ApiUrl(`v2/credit_limit/account_credit_limit_creation`)
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  updateStatusDetails: (payload) => {
    let url = ApiUrl(`v2/credit_limit/update_credit_limit_status`)
    return Call("post", url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    })
  },
};