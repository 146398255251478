import React, { Fragment } from "react";
import BaseList from "../../BaseList";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import moment from "moment";
import Copyable from "../../common/Copyable";
import { checkServiceExistance } from "../../../helpers/common";
import { Col, Row } from "../../../common/UIComponents";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import I18n from "../../../common/I18n";
import ShowInvoice from "../../billing_screen/ShowInvoice";
import CustomerOrderDetails from "../../orders/CustomerOrderDetails";
import AppConfig from "../../../config/AppConfig";

class DunningReportsDetailsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "inv_number",
        title: "Invoice No",
        dataIndex: "inv_number",
        render: (text, record) => (
          <Row type="flex" gutter={4}>
            <Col>
              <ShowInvoice
                account={record.account_id}
                invoice_no={text}
                key={text}
                style={{ marginTop: -6 }}
                account_invoice_id={record.invoice_id}
                refreshList={() => {}}
                showActions={false}
                showInvoiceActions={false}
                approvalDate={record.invoice_approval_date}
                renderType="text"
              />
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
        width: 120,
      },
      {
        key: "cid",
        title: "Order No",
        dataIndex: "cid",
        render: (data, record) => {
          return (
            <div>
              {!isEmpty(data) && (
                <CustomerOrderDetails
                  order_no={data}
                  key={data}
                  order={{
                    id: record.customer_order_id,
                    customer_order_number: data,
                  }}
                  showEdit={false}
                />
              )}
            </div>
          );
        },
        width: 120,
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        width: 120,
        render: (text, record) => {
          return (
            <OrderFieldDisplay
              data={{
                ...record,
                id: record.customer_order_id,
                customer_order_number: record.cid,
              }}
              toDisplay="hawb"
              showTriggerEdit={false}
              showEditIcon={false}
              charsToLimit={null}
            />
          );
        },
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        width: 120,
        render: (text, record) => {
          return (
            <OrderFieldDisplay
              data={{
                ...record,
                id: record.customer_order_id,
                customer_order_number: record.cid,
              }}
              toDisplay="mawb"
              showTriggerEdit={false}
              showEditIcon={false}
              charsToLimit={null}
            />
          );
        },
      },
      {
        key: "effctive_service_date",
        title: "Service Date",
        dataIndex: "effctive_service_date",
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        width: 120,
        sorter: (a, b) => compareDate(a.effctive_service_date, b.effctive_service_date),
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        minWidth: 120,
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_1"
            charsToLimit={null}
          />
        ),
      },
      {
        key: "total",
        title: "Line Total",
        dataIndex: "total",
        render: (data) => (
          <div className="alignRight" style={{ paddingRight: 40 }}>
            {data !== undefined ? checkNegitive(data) : 0}
          </div>
        ),
      },
      {
        key: "balance",
        title: "Line Balance",
        dataIndex: "balance",
        render: (data) => (
          <div className="alignRight" style={{ paddingRight: 40 }}>
            {data !== undefined ? checkNegitive(data) : 0}
          </div>
        ),
      },
    ];
  }
}
export default DunningReportsDetailsList;
