/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import FormErrors from '../common/FormattedErrors';

const ExcepionMsgs = ({ errors }) => {
  if (errors) {
    return (
      <div>
        {FormErrors(errors)}
      </div>
    );
  }
  return null;
};

export default ExcepionMsgs;

ExcepionMsgs.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

