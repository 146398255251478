import { Col, Popconfirm, Row, Tooltip } from "antd";
import React, { Fragment } from "react";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { checkNegitive, isEmpty } from "../../common/Common";
import EditIcon from "../common/EditIcon";
import CustomIconButton from "../../common/CustomIconButton";
import cheque from "../../../assets/images/cheque.png";
import CopyableContent from "../common/CopyableContent";
import { checkServiceExistance } from "../../helpers/common";
import { Typography } from "antd";
import { Link } from "../../common/UIComponents";
import Copyable from "../common/Copyable";
import transaction from "../../../assets/images/transaction.png";
import AppConfig from "../../config/AppConfig";
import DeleteIcon from "../common/DeleteIcon";
import moment from "moment";
import InlineEdit from "../../common/InlineEditIcon";
import CustomShareButton from "../../common/CustomShareIcon";

class TransactionIndexList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: null,
    }
    const actionRestriction = checkServiceExistance(
      ["ARBCU", "ARBCIRI"],
      "ANY"
    );
    const { Paragraph } = Typography;
    const decimalPoints = window.localStorage.getItem('round_off_decimals');

    const columns = [
      {
        key: "batch_number",
        title: <b>{`${"#"} ${I18n.t("batch_management.batch_name")}`}</b>,
        dataIndex: "batch_number",
        render: (data, record) =>
          props.isManageChecksScreen ? (
            <Row type="flex" align="middle" gutter={4}>
              <Col>
                <Link
                  className="text-decoration-dashed cursorPointer"
                  onClick={() => props.openBatchDetails(record.ar_batch_id)}
                >
                  {data}
                </Link>
              </Col>
              <Col>
                <Copyable text={data} />
              </Col>
            </Row>
          ) : (
            <CopyableContent text={data} showTooltip />
          ),
        width: 120,
        ...AppConfig.sortingOptions,
      },
      {
        key: "check_ach_no",
        title: <b>{`${I18n.t("batch_management.check_ach_details")}`}</b>,
        dataIndex: "check_ach_no",
        render: (data, record) =>   
        <Row type="flex" align="middle" gutter={4}>
        <Col>
            {data}
        </Col>
        <Col>
          <Copyable text={data} />
        </Col>
      </Row>,
        minWidth: 170,
        ...AppConfig.sortingOptions,
      },
      {
        key: "pay_mode",
        title: <b>{`${I18n.t("batch_management.mode_of_payment")}`}</b>,
        dataIndex: "pay_mode",
        render: (data, record) => <span>{!isEmpty(data) && data === "CREDIT_NOTE" ? "CREDIT MEMO" : data}</span>,
        width: 70,
      },
      {
        key: "account_name",
        title: <b>{I18n.t("batch_management.account_name")}</b>,
        dataIndex: "account_name",
        render: (data, record) => <Tooltip title={data}>{data}</Tooltip>,
        className: "noWrap",
        width: 120,
        ...AppConfig.sortingOptions,
      },
      {
        key: "account_code",
        title: <b>{I18n.t("batch_management.account_code")}</b>,
        dataIndex: "account_code",
        render: (data, record) => <span>{data}</span>,
        width: 130,
        ...AppConfig.sortingOptions,
      },
      {
        key: "received_amount",
        title: <b>{I18n.t("batch_management.amount_received_header")}</b>,
        dataIndex: "received_amount",
        render: (data, record) => {
          const { paid_amount } = record;
          const amountData = parseFloat(data).toFixed(decimalPoints);
          if (
            // !isEmpty(paid_amount) &&
            // !["0", "0.0", "0.00"].includes(paid_amount)
            this.props.screenFrom === "manage_batch"
          ) {
            return (
              <>
              {
                this.state.editingKey !== record.id && (
                  <span>{checkNegitive(data)}</span>
                )
              }
                <InlineEdit
                  value={amountData}
                  onSave={(value) => {
                    this.setState({ editingKey: null });
                  }}
                  onCancel={() => {
                    this.setState({ editingKey: null });
                  }}
                  isEditing={this.state.editingKey === record.id}
                  onEdit={() => {this.setState({ editingKey: record.id }),this.props.setCheckId(record.id), this.props.setNewAmount(data)}}
                  setNewAmount={this.props.setNewAmount}
                  updatePaidAmount={this.props.updatePaidAmount}
                  isFailed={this.props.isFailed}
                />
              </>
            );
          } else {
            return <span className="paddingRight20">{checkNegitive(data)}</span>;
          }
        },
        width: 175,
        align: "right",
        ...AppConfig.sortingOptions,
      },
      {
        key: "paid_amount",
        title: <b>{I18n.t("batch_management.amount_applied")}</b>,
        dataIndex: "paid_amount",
        render: (data, record) => (
          <span className="paddingRight20">{checkNegitive(data)}</span>
        ),
        width: 170,
        align: "right",
        ...AppConfig.sortingOptions,
      },
      {
        key: "balanced_amount",
        title: <b>{`${I18n.t("batch_management.balance")}`}</b>,
        dataIndex: "balanced_amount",
        render: (data, record) => (
          <span className="paddingRight20">{checkNegitive(data)}</span>
        ),
        width: 110,
        align: "right",
        ...AppConfig.sortingOptions,
      },
      {
        key: "check_date",
        title: <b>{I18n.t("batch_management.check_date")}</b>,
        dataIndex: "check_date",
        render: (data, record) => (
          <span>{moment.utc(data).format("Do MMM YYYY")}</span>
        ),
        width: 130,
        align: "right",
        ...AppConfig.sortingOptions,
      },
      {
        key: "payment_date",
        title: <b>{I18n.t("batch_management.payment_date")}</b>,
        dataIndex: "payment_date",
        render: (data, record) => (
          <span>{moment.utc(data).format("Do MMM YYYY")}</span>
        ),
        align: "right",
        ...AppConfig.sortingOptions,

        width: 110,
      },
      {
        key: "status",
        title: <b>{I18n.t("batch_management.status")}</b>,
        dataIndex: "status",
        render: (data, record) => <span>{data}</span>,
        width: 70,
      },
    ];
    if (actionRestriction && this.props.showActions !== false) {
      columns.push({
        key: "manage",
        title: <b>{I18n.t("general.manage")}</b>,
        render: (data) => {
          const { handleDelete, handleEdit, showTransaction } = this.props;
          const {
            handleRedirect,
            redirectToTransaction,
            openAccountSharingModal,
          } = this.props;
          const { id, paid_amount, balanced_amount } = data;
          return (
            <div className="pageActions">
              <Row gutter={16}>
                <Col span={4}>
                  {checkServiceExistance("ARBCU") && this.props.showEdit && (
                    <Tooltip title={I18n.t("general.edit")}>
                      <EditIcon
                        handleClick={() => handleEdit(data)}
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          marginTop: "14px",
                        }}
                      />{" "}
                    </Tooltip>
                  )}
                </Col>
                <Col
                  span={
                    !this.props.showEdit
                      ? 12
                      : 4 || (!this.props.showEdit && !showTransaction && 24)
                  }
                >
                  {checkServiceExistance("ARBCIRI") && (
                    <Tooltip title={I18n.t("general.remittances")}>
                      <CustomIconButton
                        handleClick={() => handleRedirect(id)}
                        icon={cheque}
                        width={"16px"}
                        height={"16px"}
                      />{" "}
                    </Tooltip>
                  )}
                </Col>
                {/* <Col span={!this.props.showEdit ? 12 : 4}>
                  {!isEmpty(balanced_amount) &&
                    !["0", "0.0", "0.00"].includes(balanced_amount) && (
                      <Tooltip title={I18n.t("general.share")}>
                        <CustomShareButton
                          handleClick={() => openAccountSharingModal(data)}
                          icon={share}
                          width={"14px"}
                          height={"14px"}
                        />{" "}
                      </Tooltip>
                    )}
                </Col> */}
                <Col span={!this.props.showEdit ? 12 : 4}>
                  {showTransaction && (
                    <Tooltip
                      placement="topLeft"
                      title={I18n.t("general.transaction")}
                    >
                      <CustomIconButton
                        handleClick={() => redirectToTransaction(data)}
                        icon={transaction}
                        width={"16px"}
                        height={"16px"}
                      />{" "}
                    </Tooltip>
                  )}
                </Col>
                <Col
                  span={!["0", "0.0", "0.00"].includes(paid_amount) ? 12 : 4}
                >
                  {checkServiceExistance("ARBCIRD") &&
                    !isEmpty(paid_amount) &&
                    ["0", "0.0", "0.00"].includes(paid_amount) && (
                      <Tooltip title={I18n.t("general.delete")}>
                        <Popconfirm
                          placement="left"
                          title={I18n.t("messages.delete_confirm")}
                          onConfirm={() => handleDelete(data)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteIcon />{" "}
                        </Popconfirm>
                      </Tooltip>
                    )}
                </Col>
              </Row>
            </div>
          );
        },
        align: "center",
        width: 110,
        fixed: "right",
      });
    }
    if (this.props?.columnsToDisplay?.length > 0) {
      this.columns = columns.filter((column) =>
        this.props.columnsToDisplay.includes(column.key)
      );
    } else {
      this.columns = [...columns];
    }
  }
}
export default TransactionIndexList;
