/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from "react";
import _, { add } from "lodash";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Card,
  Popover,
  Icon,
  Divider,
  Tooltip,
  Button,
  Popconfirm,
  Timeline,
  Badge,
  Spin,
  Tag,
  Text
} from "../../common/UIComponents";
import {
  ellipseText,
  formatStopTime,
  isEmpty,
  formatStopUTcTime,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormattedErrors from "../common/FormattedErrors";
import StopDistanceInfo from "../stops/StopDistanceInfo";
import AppConfig from "../../config/AppConfig";
import { contrast } from "../../common/Colors";
import {
  findDelayTime,
  findResonObject,
  formatStopAddress,
  stopObjectIds,
  getStopObjectKeyName,
} from "../../helpers/stops";
import OrdersList from "./OrdersList";

import {
  checkServiceExistance,
  secondsToHms,
  stopLvlPicturesFormat,
} from "../../helpers/common";
import BaseModal from "../BaseModal";
import UploadGallery from "../common/UploadGallery";
import { RecoveriesApis } from "../../api/RecoveriesApi";
import { deleteOrderPic } from "../../api/OrdersApi";
import DeleteIcon from "../common/DeleteIcon";
import BranchesIcon from "../common/BranchesIcon";
import { OrgContext } from "../../context/OrgContext";
import { ReleasesApi } from "../../api/ReleasesApi";
import { DisplaySettingsContext } from "../../context/DisplaySettings";
import { renderStopIcon } from "../../helpers/dispatch";
import RecoveryList from "../recoveries/RecoveryList";
import ReleasesList from "../releases/ReleaseList";
import LineHaulList from "../../containers/LineHaul/LineHaulList";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import ItemListDetails from '../orders/ItemsFullList';
import ItemsExceptionModal from "../orders/ItemExceptionModal";
import Title from "antd/lib/typography/Title";
import possibleTimeImg from "../../../assets/images/possibleTimeImg.svg"
import { magicMinutes } from "../orders/helpers";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  ...draggableStyle,
});
const StopsTimeline = (props) => {
  const {
    rowNumber,
    route,
    setCurrentStop,
    currentStop,
    moveStop,
    deleteClick,
    orderDetailsViewClick,
    deleteOrderClick,
    // stops,
    selectedStops,
    editOrderClick,
    handleStopAttachmentsSuccess,
    deleteReleaseAttachments,
    handleCompleteStop,
    selectedWH,
    stopsToRefresh = [],
  } = props;
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [isMilitaryTime, setIsMilitaryTime] = useState(false);
  const [uploadAttachmentModal, showUploadAttachmentModal] = useState(false);
  const [selectedStop, setSelectedStop] = useState(null);
  const [dispSetting, setDispSetting] = useState({});
  const [ currentStopItems, setCurrentStopItems ] = useState([]);
  const [currentStopView , setCurrentStopView] = useState({});
  const [stops, setStops] = useState([]);
  const isRecoveryRoute = route.route_type == "recovery_route";
  const isLinehaulRoute = route.route_type == "line_haul"
  const [ showItemExceptionsModal, setShowItemExceptionsModal ] = useState(false);
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);


useEffect(() => {
  if (!_.isEmpty(props.stops)) {
    const stopsCopy = _.cloneDeep(props.stops); // Clone to avoid direct mutation
    const filteredStops = stopsCopy.map((stop, index) => {
      if (index < stopsCopy.length - 1) { // Check if not the last index
        const nextStop = stopsCopy[index + 1];
        const possible_delay = _.get(nextStop, 'possible_delay_time', '');
        const reason_for_delay = _.get(nextStop, 'reason_for_delay', '');

        if (!_.isEmpty(possible_delay) || !_.isEmpty(reason_for_delay)) {
          // Add delay info to the current stop
          stop = {
            ...stop,
            possible_delay: magicMinutes(possible_delay),
            reason_for_delay
          };

          // Remove delay info from the next stop
          nextStop.possible_delay_time = '';
          nextStop.possible_delay = '';
          nextStop.reason_for_delay = '';
        }
      }
      return stop;
    });
    setStops(filteredStops);
  }
}, [props.stops]);

  useEffect(() => {
    setDispSetting(displayConfiguration);
  }, [displayConfiguration]);

  useEffect(() => {
    if (
      organizationSettings &&
      organizationSettings.is_military_time === "true"
    ) {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [organizationSettings]);

  const onModalClose = () => {
    showUploadAttachmentModal(false);
  };

  const renderUploadsModal = () => {
    const savedImgs = stopLvlPicturesFormat(currentStop.pictures);
    const uploadPicCallback =
      currentStop.location_type === "RL"
        ? RecoveriesApis.uploadStopPod
        : ReleasesApi.uploadStopPod;
    const deletePicCallback =
      currentStop.location_type === "RL"
        ? RecoveriesApis.deleteStopAttachment
        : ReleasesApi.deleteStopAttachment;
    return (
      <BaseModal
        title={I18n.t("order.upload_images")}
        width="70%"
        visible={uploadAttachmentModal}
        onCancel={onModalClose}
        style={{ top: 30 }}
        maskClosable={false}
      >
        <UploadGallery
          order={{ pictures: savedImgs }}
          takeMultipleSigns={false}
          closeGallery={onModalClose}
          uploadApi={uploadPicCallback}
          refer="stop"
          selectedStop={selectedStop}
          handleStopAttachmentsSuccess={handleStopAttachmentsSuccess}
          deleteAttachments={deletePicCallback}
          deleteReleaseAttachments={deleteReleaseAttachments}
        />
      </BaseModal>
    );
  };

  const renderStopAddress = (isDepoStop, isDriverStop, address) => {
    if (isDepoStop || isDriverStop) {
      return null;
    }

    return (
      <Row>
        <Col xs={ 24 } className="paddingTop5">
          <div
            className="fontSize10 smallText alignLeft"
            style={ { overflow: "hidden", height: 15 } }
          >
            <Popover
              title={ I18n.t("general.address") }
              content={ address }
              overlayStyle={ { width: 150 } }
            >
              <Icon type="environment" /> &nbsp;{ address }
            </Popover>
          </div>
        </Col>
      </Row>
    );
  };

  const renderCompanyName = (company_name) => {
    return (
      <Row>
        <Col xs={ 24 }>
          <div
            className="fontSize10 smallText alignLeft"
            style={ { overflow: "hidden", height: 15 } }
          >
            <Tooltip title={`${I18n.t('general.company_name')}: ${company_name}`}>
              <Icon type="bank" />&nbsp;&nbsp;{company_name}
            </Tooltip>
          </div>
        </Col>
      </Row>
    );
  };

  const renderDeleteStopButton = (
    isDepoStop,
    isDriverStop,
    route,
    stop,
    deleteClick,
    stopEleIds,
  ) => {
    if (isDepoStop || isDriverStop || route.nav_route_status === "COMPLETED") {
      return null;
    }

    const canDelete = [ "PENDING", "UNALLOCATED" ].includes(stop.status);
    if (!canDelete) {
      return null;
    }

    return (
      <Popconfirm
        placement="topRight"
        title={ I18n.t("messages.delete_confirm") }
        onConfirm={ (e) => {
          e.stopPropagation();
          e.preventDefault();
          deleteClick(stopEleIds, "stop", stop.id, "", stop.location_type) 
        }}
        okText="Yes"
        cancelText="No"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onCancel={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Tooltip
          title={ I18n.t("general.delete_stop_from_route") }
          placement="left"
        >
          <DeleteIcon className="textBold" />
          &nbsp;&nbsp;
        </Tooltip>
      </Popconfirm>
    );
  };

  const renderStopDistance = (stop, isDepoStop = false) => {
    if (!stop.interconnection_status) {
      return null;
    }
    return (
      <Timeline.Item
        key={ `item_dis${stop.id}` }
        className="connectedItem paddingBottom5"
        dot={
          <div
            style={ {
              borderColor: `#${route.bgColor}`,
            } }
            className="connectedDot"
          />
        }
      >
        <Popover
          placement="bottom"
          content={
            <StopDistanceInfo stop={stop} />
          }
        >
          <Row className="distanceInfo">
            <Col xs={ 1 }>
              <Divider />
            </Col>
            <Col xs={ 22 }>
              <Row>
                <Col xs={ 12 }>
                  <Row>
                    <Col xs={ 24 } className="alignLeft">
                      <BranchesIcon />
                      { stop.distance }
                    </Col>
                  </Row>
                </Col>
                <Col xs={ 12 } className="alignRight">
                  <Row type="flex" justify="end" gutter={10}>
                    <Col>
                      { !_.isEmpty(stop.possible_delay) ?
                        <img
                          style={{
                            width: '1rem',
                            height: '1rem',
                          }}
                          src={possibleTimeImg}
                          alt=""
                        />
                        :
                        <Icon type="clock-circle" />
                      }
                      &nbsp;
                      { stop.time }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={ 1 }>
              <Divider />
            </Col>
          </Row>
        </Popover>
      </Timeline.Item>
    );
  };

  const stopObjects = (stop) => {
    let { customer_order_ids = [], recovery_ids = [], release_ids = [], linehaul_ids = [], deleted_order_ids = [] } = stop;
    const { customer_orders = [], recoveries = [], releases = [], linehauls = [] } = route;
    recovery_ids = [].concat(recovery_ids, deleted_order_ids);
    linehaul_ids = [].concat(linehaul_ids, deleted_order_ids);
    release_ids = [].concat(release_ids, deleted_order_ids); 
    const orders = stop?.customer_order_ids?.length > 0 && customer_orders.length > 0 ? customer_orders.filter((order) => customer_order_ids.includes(order.customer_order_id)) : [];
    const stop_recoveries = recovery_ids?.length > 0 && recoveries.length > 0 ? recoveries.filter((rec) => recovery_ids.includes(rec.id)) : [];
    const stop_releases = release_ids?.length > 0 && releases.length > 0 ? releases.filter((rec) => release_ids.includes(rec.id)) : [];
    const stop_linehauls = linehaul_ids?.length > 0 && linehauls.length > 0 ? linehauls.filter((rec) => linehaul_ids.includes(rec.id)) : [];
    return { orders , stop_recoveries, stop_releases, stop_linehauls}
  }

  const fetchItemExceptionsData = (stop) => {
    const pickups = stop.pickup_order_items.map(pickup => ({ ...pickup, type: 'pickup' }));
    const dropoffs = stop.drop_order_items.map(dropoff => ({ ...dropoff, type: 'dropoff' }));
    const pickupExceptions = pickups.filter(pickup => pickup.item_picture_data);
    const dropExceptions = dropoffs.filter(dropoff => dropoff.item_picture_data);

    const mergedExpectionItems = [ ...pickupExceptions, ...dropExceptions ].reduce((acc, item) => {
      if (acc[ item.customer_order_number ]) {
        if (item.type === 'pickup') {
          acc[ item.customer_order_number ].pickups.push(item);
        } else {
          acc[ item.customer_order_number ].dropoffs.push(item);
        }
      } else {
        acc[ item.customer_order_number ] = { pickups: [], dropoffs: [] };
        if (item.type === 'pickup') {
          acc[ item.customer_order_number ].pickups.push(item);
        }
        else {
          acc[ item.customer_order_number ].dropoffs.push(item);
        }
      }
      return acc;
    }
      , {});

    // sort by customer_order_number and pickup should be first
    const sortedMergedExpectionItems = Object.keys(mergedExpectionItems).reduce((acc, key) => {
      const item = mergedExpectionItems[ key ];
      const pickups = item.pickups || [];
      const dropoffs = item.dropoffs || [];
      acc.push({ customer_order_number: key, pickups, dropoffs });
      return acc;
    }
      , []);

    return {
      orders: sortedMergedExpectionItems,
      pickupExceptions,
      dropExceptions,
      pickupExceptionsCount: pickupExceptions.length,
      dropExceptionsCount: dropExceptions.length,
    };
  };

  const [ showeEXPimgs, setShoweEXPimgs ] = useState(false);
  const [ currentItemExp, setCurrentItemExp ] = useState({});

  const renderExceptionImgsModal = () => {
    return (
      <ItemsExceptionModal
        visible={ showeEXPimgs }
        onCancel={ () => {
          setShoweEXPimgs(false);
          setCurrentItemExp({});
        } }
        data={ currentItemExp }
        isMilitaryTime={ isMilitaryTime }
      />
    );
  };

  const renderItemExceptionsModal = () => {
    // if curentstopview havin stop having stop number 1 its first stop and if stops lengght is equail to stop number its last stop
    const isItFirstStop = currentStopView.stop_number === 1;
    const isItLastStop = stops.length === currentStopView.stop_number; 
    return (
      <BaseModal
        title={ "Order Items Exceptions" }
        width="85%"
        visible={ showItemExceptionsModal }
        onCancel={ () => setShowItemExceptionsModal(false) }
        style={ { top: 40 } }
        maskClosable={ true }
        destroyOnClose
      >
        { currentStopItems.orders.map((order, index) => {
          const { customer_order_number, pickups, dropoffs } = order;
          return (
            <div key={ index } style={ { marginBottom: 10 } }>
              <Text>Order No: <Text strong>{ customer_order_number }</Text></Text>
              { pickups.length > 0 && (
                <>
                  <Text strong> ({ isItFirstStop ? "Load" : isItLastStop ? "Unload" : "Pickups" }) </Text> 
                  <ItemListDetails
                    data={ pickups }
                    isVisible
                    showItemType={ false }
                    showActions={ true }
                    showException={ true }
                    pagination={ { position: "none" } }
                    size="small"
                    rowClassName={ (record, index) =>
                      record.item_picture_data && record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") ? "errRow" : ""
                    }
                    handleModalView={ (record) => {
                      setShoweEXPimgs(true);
                      setCurrentItemExp(record);
                    }
                    }
                  />
                </>
              ) }
              { dropoffs.length > 0 && (
                <>
                  <Text strong> ({ isItFirstStop ? "Load" : isItLastStop ? "Unload" : "Deliveries" }) </Text>
                  <ItemListDetails
                    data={ dropoffs }
                    isVisible
                    showItemType={ false }
                    showActions={ true }
                    showException={ true }
                    pagination={ { position: "none" } }
                    size="small"
                    rowClassName={ (record, index) =>
                      record.item_picture_data && record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") ? "errRow" : ""
                    }
                    handleModalView={ (record) => {
                      setShoweEXPimgs(true);
                      setCurrentItemExp(record);
                    }
                    }
                  />
                </>
              ) }
            </div>
          );
        })
        }
      </BaseModal>
    );
  };


  return (
    <div className="preplanMgmt">
      {stops.length > 0 && (
        <Timeline>
          {stops.map((stop, stopIndex) => {
            const name = stop.formatted_stop_name;
            const company_name = stop.company_name || '';
            const stopEleIds = stopObjectIds(stop)// stop.customer_order_ids || [];
            const stopObjectsKeyName = getStopObjectKeyName(stop)
            const routeStatus = route.nav_route_status;
            const stopErrors = stop.stop_errors || [];
            const stopLocType = stop.location && stop.location_type ? stop.location_type : "";
            const { orders = [], stop_recoveries = [], stop_releases = [], stop_linehauls = []} = stopObjects(stop);

            const stopColor = `${
              AppConfig.orderStatusColors[stop.status] &&
              [
                "ARRIVED",
                "DISPATCHED",
                "INCOMPLETE",
                "EXCEPTION",
                "COMPLETED",
                "CANCELLED",
                "UNALLOCATED",
              ].includes(stop.status)
                ? AppConfig.orderStatusColors[stop.status].color
                : "FFF"
            }`;
            const delayMins = findDelayTime(
              stop,
              route,
              "nav_route_status",
              stop.stop_tz
            );
            const totalOrders = _.uniq(
              _.compact(
                [].concat(stopEleIds, stop.deleted_order_ids)
              )
            );
            const totalOrdersCount = totalOrders.length;
            const deletionReason = findResonObject(
              stop.deletion_reason,
              stop.id,
              "stop"
            );
            const itemsExpData = fetchItemExceptionsData(stop);
            const address = formatStopAddress(stop.address);
            const isDragDisabled = false;
            const isWh = stopLocType === "WH";
            const isDepoStop =
              stopLocType === "WH" &&
              (stopIndex === 0 || stopIndex === stops.length - 1);
            const isDriverStop = stopLocType === "DV";
            const isCompleted =
              routeStatus === "COMPLETED" ||
              (routeStatus === "ASSIGNED" && stop.status !== "PENDING");
            const isStopAborted = (stop.status === "CANCELLED" || stop.status === "INCOMPLETE")
            const deleteOpCode = "NRRSFR";
            const stopTypes =  _.uniq(stops.map(st => st.location_type));
            const stopNo = !isDepoStop && stopTypes.length === 1 && stopTypes[0] === 'LH' ? stopIndex + 1 : stopIndex 

            return (
              <Fragment key={`timeline_fragment${stop.id}`}>
                <Timeline.Item
                  key={`timeline_item${stop.id}`}
                  dot={
                    <Tooltip title={stop.status} placement="bottom">
                     
                      <Button
                        style={{
                          backgroundColor: `#${stopColor}`,
                          color: `#${contrast(stopColor)}`,
                          border: `1px solid ${
                            stop.status === "CANCELLED"
                              ? `#${AppConfig.orderStatusColors.CANCELLED.bgColor}`
                              : "inherit"
                          }`,
                        }}
                        className="textBold fontSize11"
                        shape="circle"
                        size="small"
                        // onClick={() => this.getCurrentStop(stop.id)}
                        onClick={() => {}}
                      >
                         {/* {(stopIndex === 0 || stopIndex === stops.length - 1) &&
                        stop.location &&
                        stop.location.l_type === "WH" ? (
                          <Icon type="home" />
                        ) : (
                          stopIndex
                      
                        )} */}
                        {isDepoStop ? (
                          <Icon type="home" />
                        ) : (
                          stopNo
                        )}
                         {/* <Row>
                         <Col style={{ marginTop : -20 }}>{stopIndex}</Col>
                         <Col>{renderStopIcon(stop.location_type)}</Col>
                        </Row> */}
                      </Button>
                    </Tooltip>
                  }
                >
                  <Spin spinning={stopsToRefresh?.includes(stop.id)}>
                  <Card
                    size="small"
                    onClick={(e) => setCurrentStop(stop.id, e)}
                    className={
                      stop.id === currentStop.id
                        ? `boxShadowActive currentActiveStop stop${stop.id}`
                        : "boxShadow"
                    }
                    bodyStyle={{
                      padding: 0,
                    }}
                    style={{
                      marginLeft: 8,
                      border:
                        stop.id === currentStop.id ||
                        selectedStops.includes(stop.id)
                          ? `2px solid #${route.bgColor}`
                          : `1px solid ${
                              stop.status === "CANCELLED"
                                ? `#${AppConfig.orderStatusColors.CANCELLED.bgColor}`
                                : "#DADADA"
                            }`,
                      borderRadius: 10,
                      padding: "6px 8px 6px 8px",
                      backgroundColor:
                        stop.status === "CANCELLED"
                          ? `#${AppConfig.orderStatusColors.CANCELLED.color}`
                          : "inherit",
                    }}
                  >
                    <Draggable
                      key={stop.id}
                      draggableId={stop.id}
                      index={stopIndex} // WH Stop
                      isDragDisabled={
                        isStopAborted ||
                        isDepoStop ||
                        isDragDisabled ||
                        isCompleted ||
                        ["RL", "RC", "LH"].includes(
                          stop.location_type
                        )
                      }
                      // onClick={() => removeStopOrders(stop.id)}
                    >
                      {(provided, snapshot) => {
                        const isDragging = snapshot.isDragging;
                        const displayText =
                          isDragging &&
                          selectedStops.length > 1 &&
                          selectedStops.includes(stop.id)
                            ? `Moving ${selectedStops.length} stops`
                            : name //ellipseText(name, 15);

                          const whName = isWh ? name.replace(/Warehouse/g, '').replace(/\(/g, '').replace(/\)/g, '') : name;

                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Row
                              style={{
                                backgroundColor: isDragging
                                  ? "#607ad6"
                                  : "white",
                                color: isDragging ? "#FFF" : "inherit",
                                padding: isDragging ? "8px" : "inherit",
                                borderRadius: 10,
                                // paddingLeft: "6px",
                                // paddingRight: "6px",
                              }}
                            >
                              <Col
                                xs={isDragging ? 24 : (isDepoStop ? 12 : 14)}
                                className="fontsize11 smallText textBold alignLeft noWrap"
                              >
                                { !isStopAborted &&
                                  !isDragDisabled &&
                                  !isDepoStop &&
                                  !isCompleted &&
                                  !["RC", "RL", "LH"].includes(
                                    stop.location_type
                                  ) ? (
                                    <Tooltip
                                      title={I18n.t("preplan.move_stop")}
                                      placement="top"
                                    >
                                      <Icon
                                        type="menu"
                                        className="textBold"
                                        style={{
                                          width: 16,
                                        }}
                                      />
                                      &nbsp;&nbsp;
                                    </Tooltip>
                                  )
                                  :
                                  <span className="marginRight5">{renderStopIcon(stop.location_type)}</span>
                                }
                                { isWh ? (
                                  <Tooltip title={ displayText }>
                                    <Icon type="home" />
                                    { whName }
                                  </Tooltip>
                                ) : (
                                    <Tooltip title={ name }>{ displayText }</Tooltip>
                                ) }


                              </Col>
                              {!isDragging && (
                                <Col
                                  xs={isDepoStop ? 12 : 10}
                                  style={{ marginTop: -3 }}
                                  // className="blkCenterRight textBold paddingRight7 stopActions"
                                >
                                  <Row
                                    type="flex"
                                    justify="end"
                                    align="middle"
                                    gutter={ 2 }
                                  >
                                    <Col>
                                      {checkServiceExistance("DDO") &&
                                        !isEmpty(deletionReason) &&
                                        deletionReason.reason && (
                                          <Popover
                                            content={FormattedErrors(
                                              [
                                                `${I18n.t(
                                                  "messages.reason_to_delete"
                                                )} : ${deletionReason.reason}`,
                                              ],
                                              false
                                            )}
                                          >
                                            <Icon
                                              type="warning"
                                              className="textInfo"
                                              // style={{ marginTop: -2 }}
                                            />
                                          </Popover>
                                        )}
                                      {stopErrors.length > 0 && (
                                        <Popover
                                          content={FormattedErrors(
                                            stop.stop_errors || []
                                          )}
                                        >
                                          &nbsp;&nbsp;
                                          <Icon
                                            type="warning"
                                            className="textRed"
                                            // style={{ marginTop: -2 }}
                                          />
                                        </Popover>
                                      )}
                                    </Col>

                                    {checkServiceExistance("ORRUSP") &&
                                      !isDepoStop &&
                                      !isDriverStop &&
                                      [
                                        "RL",
                                        "RC",
                                        "LH",
                                      ].includes(stop.location_type) &&
                                      stop[stopObjectsKeyName]?.length === 0 &&
                                      (
                                        <Col>
                                          <Tooltip
                                            title={I18n.t(
                                              "order.upload_images"
                                            )}
                                            placement="bottom"
                                          >
                                            <Icon
                                              type="cloud-upload"
                                              onClick={() => {
                                                setSelectedStop(stop.id);
                                                showUploadAttachmentModal(true);
                                              }}
                                            />
                                          </Tooltip>
                                        </Col>
                                      )}
                                    <Col>
                                      {![
                                        "RC",
                                        "RL",
                                        "LH"
                                      ].includes(stop.location_type) &&
                                        !isDepoStop &&
                                        !isDriverStop &&
                                        ["ARRIVED", "PENDING"].includes(
                                          stop.status
                                        ) && (
                                          <Tooltip
                                            title={I18n.t("stop.complete_stop")}
                                            placement="top"
                                          >
                                            <Icon
                                              type="check"
                                              className="textBold"
                                              style={{
                                                width: 10,
                                              }}
                                              onClick={() =>
                                                handleCompleteStop("stop")
                                              }
                                            />
                                            &nbsp;&nbsp;
                                          </Tooltip>
                                        )}
                                    </Col>

                                    { renderDeleteStopButton(isDepoStop,
                                      isDriverStop,
                                      route,
                                      stop,
                                      deleteClick,
                                      stopEleIds) }

                                    {false && checkServiceExistance("NRTS") && (
                                      <Popconfirm
                                        placement="left"
                                        title={I18n.t("messages.move_confirm")}
                                        onConfirm={() =>
                                          moveStop(
                                            stop.id,
                                            "move_stop",
                                            route.route_id,
                                            route.route_id
                                          )
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Tooltip
                                          title={I18n.t("preplan.move_stop")}
                                          placement="bottom"
                                        >
                                          {/* <img src={unlink} style={{ width: 12, marginRight: 8 }} alt="unlink" />{I18n.t('general.remove')} */}
                                          <DeleteIcon className="textBold" />
                                          &nbsp;&nbsp;&nbsp;
                                          {/* <Icon
                                            type="menu"
                                            className="textBold"
                                            style={{ width: 16 }}
                                          /> */}
                                        </Tooltip>
                                      </Popconfirm>
                                    )}
                                    <Col>
                                      <Tooltip title="Total Orders">
                                        <p
                                          className="badgeDiv"
                                          style={ {
                                            backgroundColor: `#${route.bgColor}`,
                                            color: `#${route.fgColor}`,
                                            fontSize: 12,
                                          } }
                                        >
                                          { totalOrdersCount }
                                        </p>
                                      </Tooltip>
                                    </Col>
                                    {
                                      itemsExpData.pickupExceptionsCount + itemsExpData.dropExceptionsCount > 0 ? (
                                        <Col>
                                      <Tooltip title={ "Exceptions" }>
                                        <Tag
                                          onClick={ () => {
                                            if(itemsExpData.pickupExceptionsCount + itemsExpData.dropExceptionsCount > 0) {
                                            setShowItemExceptionsModal(true);
                                            setCurrentStopItems(itemsExpData);
                                            setCurrentStopView(stop);
                                            }
                                          } }
                                          style={ {
                                            backgroundColor: 'red',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                            cursor: 'pointer'
                                          } }
                                        >
                                          <Icon type="exclamation-circle" style={ { marginRight: '3px' } } />
                                          { itemsExpData.pickupExceptionsCount + itemsExpData.dropExceptionsCount }
                                        </Tag>
                                      </Tooltip>
                                    </Col>
                                      ) : 
                                      null
                                    }
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      }}
                    </Draggable>
                    {
                      company_name && renderCompanyName(company_name)
                    }
                    { renderStopAddress(isDepoStop, isDriverStop, address) }
                    <Row>
                      {((!isLinehaulRoute && !isDepoStop) || isLinehaulRoute) &&	
                        !isDriverStop && (
                        <Col
                          xs={24}
                          style={{
                            paddingTop: `${
                              ["PENDING", "UNALLOCATED"].includes(stop.status)
                                ? "5px"
                                : "3px"
                            }`,
                          }}
                        >
                          <div className="fontSize10 smallText alignLeft">
                            <Tooltip title={I18n.t("order.scheduled_time")}>
                              {I18n.t("routes.scheduled_time")}: &nbsp;
                              {!isEmpty(
                                stop.scheduled_start_date_time_with_tz
                              ) ||
                              !isEmpty(stop.scheduled_end_date_time_with_tz) ? (
                                <Fragment>
                                  {formatStopUTcTime(
                                    stop.scheduled_start_date_time_with_tz,
                                    stop.scheduled_end_date_time_with_tz,
                                    stop.stop_tz_short_name,
                                    false,
                                    isMilitaryTime
                                  )}
                                </Fragment>
                              ) : (
                                "Not available"
                              )}
                            </Tooltip>
                          </div>
                        </Col>
                      )}
                      <Col xs={24} style={{ paddingTop: 3 }}>
                        <div className="fontSize10 smallText alignLeft">
                          <Tooltip title={I18n.t("order.estimated_time")}>
                            {I18n.t("routes.estimated_time")}: &nbsp;
                            {!isEmpty(
                              stop.estimated_arrival_date_time_with_tz
                            ) ||
                            !isEmpty(
                              stop.estimated_departure_date_time_with_tz
                            ) ? (
                              <Fragment>
                                {formatStopUTcTime(
                                  stop.estimated_arrival_date_time_with_tz,
                                  stop.estimated_departure_date_time_with_tz,
                                  stop.stop_tz_short_name,
                                  false,
                                  isMilitaryTime
                                )}
                              </Fragment>
                            ) : (
                              "Not available"
                            )}
                          </Tooltip>
                          {["PENDING", "UNALLOCATED"].includes(stop.status) &&
                            delayMins > 0 && (
                              <span className="textRed textBold fontsize11 floatRight">
                                <Tooltip title={secondsToHms(delayMins)}>
                                  Late
                                </Tooltip>
                              </span>
                            )}
                        </div>
                      </Col>
                      {!["PENDING", "UNALLOCATED"].includes(stop.status) && (
                        <Col xs={24} style={{ paddingTop: 1 }}>
                          <div className="fontSize10 smallText alignLeft">
                            <Tooltip title={I18n.t("order.arrived_time")}>
                              {I18n.t("routes.actual_time")}: &nbsp;
                              {!isEmpty(stop.actual_start_date_time_with_tz) ||
                              !isEmpty(stop.actual_end_date_time_with_tz) ? (
                                <Fragment>
                                  {formatStopUTcTime(
                                    stop.actual_start_date_time_with_tz,
                                    stop.actual_end_date_time_with_tz,
                                    stop.stop_tz_short_name,
                                    false,
                                    isMilitaryTime
                                  )}
                                </Fragment>
                              ) : (
                                "Not available"
                              )}
                            </Tooltip>
                            {delayMins > 0 && (
                              <span className="textRed textBold fontsize11 floatRight">
                                <Tooltip title={secondsToHms(delayMins)}>
                                  Late
                                </Tooltip>
                              </span>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                    {orders.length > 0 && (
                      <Row className="marginTop10">
                        {isDepoStop && <Col xs={24}>  <h4 className="alignLeft fontSize12 textBold">{I18n.t('menu.orders')}</h4></Col>}
                        <Col xs={24}>
                          <OrdersList
                            data={orders}
                            pagination={{ position: "none" }}
                            scroll={{ x: 800, y: 400 }}
                            StopCard={true}
                            showMoveAction={false}
                            editOrderClick={editOrderClick}
                            rowClassName={(record, _index) =>
                              stop.deleted_order_ids.includes(
                                record.customer_order_id
                              )
                                ? "blockBgErrorColor"
                                : ""
                            }
                            currentStop={stop}
                            stopType={stopLocType}
                            stopIndex={stopIndex}
                            deleteOrderClick={deleteOrderClick}
                            showActions={routeStatus !== "COMPLETED"}
                            displayConfiguration={dispSetting}
                            screen_from="stops"
                          />
                        </Col>
                      </Row>
                    )}
                    {stop_recoveries.length > 0 && (
                      <Row className="marginTop10">
                        {isDepoStop && <Col xs={24}>  <h4 className="alignLeft  fontSize12 textBold">{I18n.t('menu.recovery')}</h4></Col>}
                        <Col xs={24}>
                        <RecoveryList
                          data={stop_recoveries}
                          showActions={routeStatus !== "COMPLETED"}
                          pagination={{ position: "none" }}
                          deleteClick={(id) => deleteOrderClick(id , "order", stop.id, "", stop.location_type)}
                          selectedWH={selectedWH}
                          showStatus={false}
                          stopType={stopLocType}
                          scroll={{ x:200 , y: 60  }}
                          showSno={false}
                          className="dispatch-empty-table-height"
                          rowClassName={(record, _index) =>
                            stop.deleted_order_ids.includes(
                              record.id
                            )
                              ? "blockBgErrorColor"
                              : ""
                          }
                          locale={{
                            emptyText: (
                              <h4 className="alignCenter">
                                No Records Found
                              </h4>
                            )
                          }}
                          columnsNotToDisplay={true}
                          currentStop={stop}
                          screenFrom="dispatch"
                          stopIndex={stopIndex}
                        />
                        </Col>
                      </Row>
                    )}
                    {stop_releases.length > 0 && (
                      <Row className="marginTop10">
                        {isDepoStop && <Col xs={24}>  <h4 className="alignLeft fontSize12 textBold">{I18n.t('menu.releases')}</h4></Col>}
                        <Col xs={24}>
                        <ReleasesList
                          data={stop_releases}
                          showActions={routeStatus !== "COMPLETED"}
                          deleteClick={(id) => deleteOrderClick(id , "order", stop.id, "", stop.location_type)}
                          pagination={{ position: "none" }}
                          selectedWH={selectedWH}
                          showStatus={false}
                          scroll={{ x:200 , y: 60  }}
                          showSno={false}
                          className="dispatch-empty-table-height"
                          rowClassName={(record, _index) =>
                            stop.deleted_order_ids.includes(
                              record.id
                            )
                              ? "blockBgErrorColor"
                              : ""
                          }
                          locale={{
                            emptyText: (
                              <h4 className="alignCenter">
                                No Records Found
                              </h4>
                            )
                          }}
                          currentStop={stop}
                          stopType={stopLocType}
                          columnsNotToDisplay={true}
                          screenFrom="dispatch"
                          stopIndex={stopIndex}
                        />
                        </Col>
                      </Row>
                    )}
                    {stop_linehauls.length > 0 && (
                      <Row className="marginTop10">
                        <Col xs={24}>
                        <LineHaulList
                          data={stop_linehauls}
                          showActions={false}
                          pagination={{ position: "none" }}
                          selectedWH={selectedWH}
                          showStatus={false}
                          scroll={{ x:200 , y: 60  }}
                          showSno={false}
                          className="dispatch-empty-table-height"
                          rowClassName={(record, _index) =>
                            stop.deleted_order_ids.includes(
                              record.id
                            )
                              ? "blockBgErrorColor"
                              : ""
                          }
                          screen_from="dispatch"
                          editOrderClick={editOrderClick}
                          size="small"
                          isMilitaryTime={isMilitaryTime}
                          handleStatusChange={() => {}}
                          locale={{
                            emptyText: (
                              <h4 className="alignCenter">
                                No Records Found
                              </h4>
                            )
                          }}
                          currentStop={stop}
                          // selectedWH={selectedWH}
                          columnsNotToDisplay={true}
                          screenFrom="dispatch"
                          orderConfig={orderConfig}
                        />
                        </Col>
                      </Row>
                    )}
                  </Card>
                  </Spin>
                </Timeline.Item>
                {renderStopDistance(stop, isDepoStop)}  
              </Fragment>
            );
          })}
        </Timeline>
      )}
      {showUploadAttachmentModal && renderUploadsModal()}
      { showItemExceptionsModal && renderItemExceptionsModal() }
      { showeEXPimgs &&  renderExceptionImgsModal() }
    </div>
  );
};
export default StopsTimeline;

StopsTimeline.propTypes = {
  rowNumber: PropTypes.number.isRequired,
  stops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  route: PropTypes.shape().isRequired,
  currentStop: PropTypes.shape().isRequired,
  setCurrentStop: PropTypes.func.isRequired,
};