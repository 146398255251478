import React  from "react";
import { PureComponent } from "react";
import AppConfig from "../../config/AppConfig";
import RouteMarker from "./RouteMarker";
import { contrast } from "../../common/Colors";
import { retrieveMapIdentity } from "../../helpers/orders";

class Stop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stopMarkers: [],
    };
  }
  removeDuplicateWHs(stopMarkers) {
    // make separate array of objects having location_type as 'WH' and others
    const whMarkers = stopMarkers.filter(
      (marker) => marker.location_type === "WH"
    );
    const otherMarkers = stopMarkers.filter(
      (marker) => marker.location_type !== "WH"
    );

    const newWHs = [];
    // remove duplicate whs
    const whs = whMarkers.filter((wh) => {
      const whIndex = newWHs.findIndex(
        ({ lat, lng }) => lat === wh.lat && lng === wh.lng
      );
      if (whIndex === -1) {
        newWHs.push(wh);
        return true;
      }
      return false;
    });
    // other markers should be at first so that they are on top
    return [...otherMarkers, ...whs];
  }
  componentDidMount() {
    const { route } = this.props;
    this.setState({
      stopMarkers: this.removeDuplicateWHs(route.stop_markers),
    });
  }
  // to get updated markers
  componentWillReceiveProps(nextProps) {
    const { route } = nextProps;
    this.setState({
      stopMarkers: this.removeDuplicateWHs(route.stop_markers),
    });
  }
  render() {
    const { stopMarkers } = this.state;
    if (stopMarkers.length === 0) {
      return null;
    }

    const {
      route,
      routeIndex,
      showNumbers,
      showPreplnControl,
      onToggleOpen,
      setCurrentRoute,
      // selectedStopCardId,
      openedStopInfoWindow,
      resetHoveredStop,
      isAirCargo
    } = this.props;
    return stopMarkers.map((marker, stopIndex) => {
      const orders =
        marker.orders && marker.orders.length > 0
          ? marker.orders.map((order) => retrieveMapIdentity(isAirCargo, order))
          : [];
      const isStopVisited =
        marker.status &&
        [
          "ARRIVED",
          "INCOMPLETE",
          "EXCEPTION",
          "COMPLETED",
          "CANCELLED",
        ].includes(marker.status) &&
        AppConfig.orderStatusColors[marker.status];
      const bgColor = isStopVisited
        ? AppConfig.orderStatusColors[marker.status].color
        : route.bgColor;
      const fgColor = isStopVisited ? contrast(bgColor) : route.fgColor;

      return (
        <RouteMarker
          key={`${routeIndex}-${stopIndex}`}
          marker={marker}
          showNumbers={showNumbers}
          bgColor={bgColor}
          fgColor={fgColor}
          onToggleOpen={onToggleOpen}
          stopIndex={stopIndex}
          showPreplnControl={showPreplnControl}
          orders={orders}
          route={route}
          setCurrentRoute={setCurrentRoute}
          // selectedStopCardId={selectedStopCardId}
          isSelectedStop={
            openedStopInfoWindow && openedStopInfoWindow === marker.id
          }
          rearrangeForm={this.props.rearrangeForm}
          resetHoveredStop={resetHoveredStop}
          showStopScreen={ this.props.showStopScreen }
        />
      );
    });
  }
}
export default Stop;
