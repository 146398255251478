import React from "react";
import BaseList from "../../BaseList";
import { checkNegitive, ellipseText, isEmpty } from "../../../common/Common";
import moment from 'moment';
import Copyable from "../../common/Copyable";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance } from "../../../helpers/common";
import { Col, Icon, Link, Popover, Row } from "../../../common/UIComponents";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import TypeOfOrder from "../../orders/TypeOfOrder";
import AddressInfo from "../../common/AddressInfo";
import DisplayTime from "../../common/DisplayTime";
import I18n from "../../../common/I18n";

class StatusReportList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "fleet_order_number",
        title: "Order No",
        dataIndex: "fleet_order_number",
        align: "center",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        width: 150,
        render: (data, record) => {
          return (
            <Row type="flex" align="middle" gutter={4}>
               <Col>
                <TypeOfOrder
                  order={record}
                  orderTypeKey="type_of_order"
                  relatedOrderKey="related_order"
                  placement="topLeft"
                  showBadge={true}
                />
              </Col>
              <Col>{data}</Col>
             
            </Row>
          );
        },
        // render: (data) => <CopyableContent text={data} showTooltip />,
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        render: (data) => <CopyableContent text={data} showTooltip />,
        align: "left",
        width: 120,
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        render: (data) => <span>{data ? data : ""}</span>,
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
      },
      {
        key: "pickup_date",
        title: "Pickup Date",
        dataIndex: "pickup_date",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "delivered_date",
        title: "Delivered Date",
        dataIndex: "delivered_date",
        render: (data, record) => (
          // <span>
          //   {data && data !== "NA"
          //     ? moment.utc(data).format("MMM Do YYYY")
          //     : ""}
          // </span>
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "signer_name",
        title: "Signer Name",
        dataIndex: "signer_name",
        render: (data) => ellipseText(data, 25, true),
        align: "left",
        width: 150,
        className: "noWrap",
      },
      {
        key: "signer_title",
        title: "Signer Title",
        dataIndex: "signer_title",
        render: (data) => ellipseText(data, 25, true),
        align: "left",
        width: 150,
        className: "noWrap",
      },
      {
        key: "signer_captured_at",
        title: `Signer Captured At`,
        dataIndex: "signer_captured_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "customer_name",
        title: "Customer Name",
        dataIndex: "customer_name",
        render: (data) => ellipseText(data, 25, true),
        align: "left",
        width: 190,
        className: "noWrap",
      },
      // {
      //   key: "type_of_order",
      //   title: I18n.t("general.order_type"),
      //   render: (data, record) => (
      //     <div style={{ textAlign: "center" }}>
      //       <TypeOfOrder
      //         order={record}
      //         orderTypeKey="type_of_order"
      //         relatedOrderKey="related_order"
      //         placement="topLeft"
      //         showBadge={true}
      //       />
      //     </div>
      //   ),
      // },
      {
        key: "wh_location",
        title: "Docker Location",
        dataIndex: "wh_location",
        render: (text, data) => (text && text !== "NA" ? text : ""),
        ellipsis: true,
      },
      {
        key: "account",
        title: "Account",
        dataIndex: "account",
        width: 220,
        render: (data) => data,
        align: "left",
      },
      {
        key: "receive_date",
        title: `Recei. Date`,
        dataIndex: "receive_date",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "dis_for_pu_sent_at",
        title: `Dis. For PU Sent At`,
        dataIndex: "dis_for_pu_sent_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },

      {
        key: "dipatch_sent_at",
        title: `Dispatch Sent At`,
        dataIndex: "dipatch_sent_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "csdt_csd_sent_at",
        title: `CSDT/CDT/CST Sent At`,
        dataIndex: "csdt_csd_sent_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "adl_sent_at",
        title: `ADL Sent At`,
        dataIndex: "adl_sent_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "del_sent_at",
        title: `DEL Sent At`,
        dataIndex: "del_sent_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "ohd_sent_at",
        title: `OHD Sent At`,
        dataIndex: "ohd_sent_at",
        render: (data, record) => (
          <span>
            {data  || "" }
          </span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "inv_sent_at",
        title: `Inv Sent At`,
        dataIndex: "inv_sent_at",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 170,
      },
      {
        key: "acknowledge",
        title: `Acknowledge`,
        dataIndex: "acknowledge",
        render: (text) => (
          <span>
            {text ? text : ""}
          </span>
        ),
        align: "left",
      },
    ];
  }
}
export default StatusReportList;
