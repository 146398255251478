/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _, { result } from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Card,
  Icon,
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  formatCode,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import { loadGoogleMaps } from "../../utils/Utils";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { BatchPaymentApi } from "../../api/BatchPaymentApi";
import { fetchShortFormAccounts } from "../../api/Account";
import PaymentsForm from "./Payments";
import BaseModal from "../BaseModal";
import userStore from "../../stores/UserStore";
import PaymentsList from "./PaymentsList";
import { renderAlertMessage } from "../../helpers/common";
import moment from "moment";

const { TextArea } = Input;

const requiredFields = [
  {
    form_field: "batch_name",
    ui_name: I18n.t("batch_payments.name"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "expected_total_amount",
    ui_name: I18n.t("batch_payments.expected_total"),
    isRequired: true,
    inputType: "price",
  },
  {
    form_field: "batch_total_amount",
    ui_name: I18n.t("batch_payments.batch_total"),
    isRequired: false,
    inputType: "price",
  },
  {
    form_field: "comments",
    ui_name: I18n.t("general.email"),
    isRequired: false,
    inputType: "text",
  },
];

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBatch: this.props.account ? this.props.account : {},
      accounts: this.props.accounts ? this.props.accounts : [],
      batchPayments: [],
      isNew: this.props.isNew,
      orgId: this.props.org_id,
      inProgress: false,
      errors: [],
      accounts: [],
      showForm: false,
    };
  }

  getAccounts = () => {
    fetchShortFormAccounts(this.state.orgId, "", true).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getPayments = () => {
    BatchPaymentApi.getBatchPayments(this.state.currentBatch.id).then(
      (result) => {
        if (result.success) {
          this.setState({
            batchPayments: result.batch_account_payments || [],
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      }
    );
  };

  closeFormWindow = () => {
    this.setState(
      {
        showForm: false,
        currentPayment: {},
      },
      () => {
        this.getPayments();
      }
    );
  };

  showFormWindow = (rec = {}) => {
    this.setState({
      showForm: true,
      currentPayment: { ...rec },
    });
  };

  componentDidMount() {
    this.getAccounts();
    if (this.state.currentBatch.id) {
      this.getPayments();
    }
  }

  clearForm = () => {
    this.setState({
      currentBatch: {},
      isNew: true,
    });
  };

  handleOnChange = (element, value) => {
    const { wareHouses } = this.state;
    const currentBatch = Object.assign({}, this.state.currentBatch);
    currentBatch[element] = value;
    this.setState({
      currentBatch,
    });
  };

  handleSave = () => {
    let data = Object.assign({}, this.state.currentBatch);
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      const currentWarehouse =
        typeof this.props.warehouseFilter.selectedWarehouses === "string"
          ? _.find(this.props.warehouseFilter.warehouses, {
              id: this.props.warehouseFilter.selectedWarehouses,
            })
          : _.find(this.props.warehouseFilter.warehouses, {
              id: this.props.warehouseFilter.selectedWarehouses[0],
            });

      data.organization_id = this.state.orgId;
      data.warehouse_id = currentWarehouse.id;
      data.current_user_id = userStore.getStateValue("id");
      this.setState({ inProgress: true });
      if (!this.state.isNew) {
        data.batch_payment_id = this.state.currentBatch.id;
      }
      BatchPaymentApi.manageBatch(data, this.state.isNew).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({
            errors: [],
            isNew: false,
            inProgress: false,
            currentBatch: result.batch_payment || {},
          });
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  handleChangeStatus = () => {
    let data = Object.assign({}, this.state.currentBatch);
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      const current_user_id = userStore.getStateValue("id");
      this.setState({ inProgress: true });
      BatchPaymentApi.chnageStatus(
        this.state.currentBatch.id,
        current_user_id
      ).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({
            errors: [],
            inProgress: false,
            currentBatch: { ...data, status: "POSTED" },
          });
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  renderFormModal = () => {
    const currentPayment = this.state.currentPayment.id
      ? {
          ...this.state.currentPayment,
          check_date: this.state.currentPayment.check_date
            ? moment(this.state.currentPayment.check_date)
            : moment(),
        }
      : {};

    return (
      <BaseModal
        title={`Add / Edit Payments of Batch : ${
          this.state.currentBatch.id
            ? `${this.state.currentBatch.batch_name}`
            : ""
        }`}
        onCancel={() => this.closeFormWindow()}
        width="100%"
        style={{
          top: 0,
          height: "100%",
        }}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "auto",
        }}
      >
        <PaymentsForm
          batch={this.state.currentBatch}
          currentPayment={currentPayment}
          accounts={this.state.accounts}
          primaryAccountCodes={this.state.primaryAccountCodes}
          isPrimaryCurrentAccount={this.state.isPrimaryCurrentAccount}
          isNew={this.state.isNew}
          org_id={this.state.orgId}
          onCancel={this.closeFormWindow}
          closeFormOnSubmit={this.closeFormWindow}
          showActions={this.state.currentBatch.status !== "POSTED"}
        />
      </BaseModal>
    );
  };

  handleDeletePayment = (id) => {
    this.setState({ inProgress: true });
    BatchPaymentApi.deleteBatchPayment(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getPayments();
        this.setState({
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  renderPayments = () => {
    const { currentBatch } = this.state;
    return (
      <Card
        size="small"
        title="Payments"
        extra={
          currentBatch.status !== "POSTED" ? (
            <Row>
              <Col>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.showFormWindow()}
                  className="buttonMitesse"
                >
                  {I18n.t("general.add")} Payment
                </Button>
              </Col>
            </Row>
          ) : (
            <Fragment />
          )
        }
        className="marginTop20"
      >
        <div className="minimizdTable">
          <PaymentsList
            data={this.state.batchPayments}
            pagination={{ position: "none" }}
            // onRow={(record) => ({
            //   onClick: () => this.showFormWindow(record),
            // })}
            viewClick={(record) => this.showFormWindow(record)}
            showActions={currentBatch.status !== "POSTED"}
            deleteClick={this.handleDeletePayment}
          />
        </div>
      </Card>
    );
  };
  renderForm = () => {
    const { currentBatch } = this.state;
    const isDisabled = currentBatch.status === 'POSTED';
    return (
      <div>
        <Row>
          <Col xs={18}>
            <Row gutter={16}>

              <Col md={8}>
                <FormItem label={I18n.t("batch_payments.name")} require>
                  <Input
                    value={currentBatch.batch_name}
                    onChange={(e) =>
                      this.handleOnChange("batch_name", e.target.value)
                    }
                    size="small"
                    disabled={isDisabled}
                  />
                </FormItem>
              </Col>
              <Col md={8}>
                <FormItem
                  label={
                    <Fragment>
                      <span>{I18n.t("batch_payments.expected_total")}</span>
                    </Fragment>
                  }
                  require
                >
                  <Input
                    value={currentBatch.expected_total_amount}
                    type="number"
                    onChange={(e) =>
                      this.handleOnChange(
                        "expected_total_amount",
                        e.target.value
                      )
                    }
                    className="textUpperCase"
                    size="small"
                    disabled={isDisabled}
                  />
                </FormItem>
              </Col>
              <Col md={8}>
                <FormItem
                  label={
                    <Fragment>
                      <span>{I18n.t("batch_payments.batch_total")}</span>
                    </Fragment>
                  }
                  
                >
                  <Input
                    value={currentBatch.batch_total_amount}
                    type="number"
                    onChange={(e) =>
                      this.handleOnChange("batch_total_amount", e.target.value)
                    }
                    className="textUpperCase"
                    size="small"
                    disabled
                  />
                </FormItem>
              </Col>
           
              <Col md={24}>
                <FormItem label={I18n.t("general.comments")}>
                  <TextArea
                    autoSize={true}
                    rows={1}
                    placeholder={I18n.t("general.comments")}
                    className="sop-form-textArea"
                    value={currentBatch.comments}
                    onChange={(e) =>
                      this.handleOnChange("comments", e.target.value)
                    }
                    disabled={isDisabled}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col xs={6} style={{ height : 120, paddingTop: 45 }}>
            {
              !isDisabled && <Row className="">
              <Col className="alignCenter">
                <Button
                  size={"small"}
                  type="danger"
                  disabled={this.state.inProgress}
                  onClick={this.props.onCancel}
                  className="marginRight5"
                >
                  {I18n.t("general.cancel")}
                </Button>
                <Button
                  size={"small"}
                  type="primary"
                  disabled={this.state.inProgress}
                  onClick={this.handleSave}
                  className="marginRight5"
                >
                  {I18n.t("batch_payments.save_batch")}
                </Button>
                {
                  currentBatch.id && <Button
                  size={"small"}
                  type="primary"
                  disabled={this.state.inProgress}
                  onClick={this.handleChangeStatus}
                  className="buttonGreen"
                  
                >
                  {I18n.t("batch_payments.post_batch")}
                </Button>
                }
  
              </Col>
            </Row>
            }
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
        {!this.state.isNew && this.renderPayments()}
        {this.state.showForm && this.renderFormModal()}
      </div>
    );
  };

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

Form.propTypes = {
  account: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const AccountForm = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const organizationSettings = useContext(OrgContext);
  return (
    <Form
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default AccountForm;
