import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
	Icon,
	Spin,
	FormItem,
	TextArea,
	Tooltip,
} from "../../common/UIComponents";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import BaseModal from "../BaseModal";
import { setRouteObject } from "../../helpers/preplan";
import { sendDriverNotification } from "../../api/NotificationApi";
import FormButtons from "./FormButtons";
import RouteLabel from "../../config/RouteLabel";
import { renderAlertMessage } from "../../helpers/common";

class NotifyDriver extends Component {
	constructor(props) {
		super(props);
		this.state = {
			driver_ids: props.driver_ids || [],
			message: "",
			inProgress: false,
			showWindow: false,
		};
		this.myViewer = React.createRef();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!_.isEqual(props.driver_ids, this.state.driver_ids)) {
			this.setState({
				driver_ids: props.driver_ids,
			});
		}
	}

	getUsers(cb) {
		this.setState({ inProgress: true });
		const page = this.tableOptions.pagination.current;
		const searchText = this.tableOptions.searchText;
		fetchUsers("driver", 1, null).then((result) => {
			if (result.success) {
				this.setState(
					{
						users: result.users || [],
						inProgress: false,
					},
					() => {
						if (cb) {
							cb();
						}
					}
				);
			}
		});
	}

	handleNotifySubmit = () => {
		const { driver_ids, message } = this.state;
		const drivers = _.compact(driver_ids);
		const actionType = RouteLabel.actionLabels.DRIVER_NOTIFICATION
		if (drivers && message) {
			this.setState({ inProgress: true });
			sendDriverNotification({ driver_ids: drivers, message, action_type: actionType, action_from: "DISPATCH" }).then(
				(result) => {
					if (result.success) {
						this.setState(
							{
								showWindow: false,
								inProgress: false,
								message: "",
							},
							() => {
								alertMessage(result.message, "success", 10);
							}
						);
					} else {
						renderAlertMessage(result.errors)
						this.setState({ inProgress: false });
					}
				}
			);
		} else {
			alertMessage(
				`${I18n.t("general.message")} is required`,
				"error",
				10
			);
		}
	};

	handleNotifyMessage = (element, value) => {
		this.setState({
			[element]: value,
		});
	};

	openModalWindow = () => {
		this.setState({
			showWindow: true,
		});
	};

	closeModalWindow = () => {
		this.setState({
			showWindow: false,
		});
	};

	renderModal() {
		return (
			<BaseModal
				title="Send Notification"
				onCancel={() => this.closeModalWindow()}
				width="40%"
				style={{ top: 50 }}
				bodyStyle={{ padding: 25 }}
				autoFocus
			>
				<Spin spinning={this.state.inProgress} delay={1000}>
					<div>
						{/* <span>{this.state.notifyDriverName}</span> */}
						{/*         <span><b>Driver: </b></span>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            onChange={val => this.addMultipleDriverNotifications('driver_ids', val)}
            className='ADD-CRO'
            value={this.state.notification.driver_ids}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.state.users.map(province =>
              <Option key={province.id} value={province.id}>{province.full_name}</Option>)
            }
          </Select>*/}
						<FormItem
							label={I18n.t("general.Driver")}
							style={{
								display: "flex",
								alignItems: "baseline",
								gap: "5px",
							}}
						>
							<h3>{this.props.driverName}</h3>
						</FormItem>
						<FormItem label={I18n.t("general.message")}>
							<TextArea
								rows={4}
								onChange={(e) =>
									this.handleNotifyMessage(
										"message",
										e.target.value
									)
								}
							/>
						</FormItem>

						<div>
							{FormButtons(
								this.state.inProgress,
								this.handleNotifySubmit,
								this.closeModalWindow,
								true,
								true,
								"default",
								I18n.t("general.send"),
								I18n.t("general.close")
							)}
						</div>
					</div>
				</Spin>
			</BaseModal>
		);
	}

	render() {
		return (
			<Fragment>
				<Tooltip
					title={`${I18n.t("general.send")} ${I18n.t(
						"general.notification"
					)} to ${I18n.t("users.driver.driver")}`}
				>
					{" "}
					<Icon
						type="notification"
						onClick={this.openModalWindow}
					/>{" "}
				</Tooltip>
				{this.state.showWindow && this.renderModal()}
			</Fragment>
		);
	}
}

NotifyDriver.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NotifyDriver;
