/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Icon,
  Button,
  Card,
  Divider,
  Select,
  Modal,
  Form,
} from "../../common/UIComponents";
import { alertMessage, checkNegitive, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import Profile from "../common/Profile";
import InvoiceContact from "./InvoiceContact";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import AddressInfo from "../common/AddressInfo";
import BranchesIcon from "../common/BranchesIcon";
import EditIcon from "../common/EditIcon";
import BaseModal from "../BaseModal";
import { fetchUsers } from "../../api/UsersApi";
import {
  UpdateDriver,
  fetchBillingDetails,
  fetchInvoices,
} from "../../api/Billing";
import { WarehouseContext } from "../../context/WarehouseContext";
import EndUserTitle from "../common/EndUserTitle";
import { importDeclaration } from "@babel/types";

const BillingDrivers = (props) => {
  const {
    invoice,
    currentObj,
    isReadyOnly,
    levelOfServices,
    handleOnChange,
    currentLos,
    reGenerate,
    showAddOrdersModal,
    showAddOrders,
    isLocked,
    showActions,
    getInvoices,
    showEmailModal,
    isSendingMail,
    driversList = []
  } = props;

  const [currentOrder, setCurrentOrder] = useState({...currentObj});
  const [currentDriverId, setCurrentDriverId] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [drivers, setDrivers] = useState(driversList);
  const [inProgress, setInProgress] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");
  const sortedDriverInfo = _.sortBy(
    currentOrder?.driver_details || [],
    "loc_order_sequence"
  );
  const [driversInfo, setDriversInfo] = useState(sortedDriverInfo);
  const warehouseFilter = useContext(WarehouseContext);
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
  }, [warehouseFilter.selectedWarehouses, warehouseFilter.warehouses]);

  useEffect(() => {
    setDrivers(driversList)
  },[driversList])

  useEffect(() => {
    const driversData = currentOrder?.driver_details || [];
    setDriversInfo(_.sortBy(driversData, "loc_order_sequence"));
  }, [invoice.drivers_info]);

  const currentWh =
    typeof warehouseFilter.selectedWarehouses === "string"
      ? _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses,
        })
      : _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses[0],
        });

  const handleDriverChnage = (value) => {
    setSelectedDriver(value);
  };

  const handleEdit = (id) => {
    setCurrentDriverId(id)
    setShowEditModal(true);
  };

  const handleSubmit = async () => {
    const orderIds = [currentOrder.customer_order_id];
    const payload = {
      order_ids: orderIds,
      old_driver_id: currentDriverId,
      new_driver_id: selectedDriver,
      warehouse_id: currentWh ? currentWh.id : "",
    };
    setInProgress(true)
    try {
      const updateResult = await UpdateDriver(payload);
      if (updateResult.success) {
        alertMessage("Driver Updated Successfully", "success", 10);
        setShowEditModal(false);
        const billingResult = await fetchBillingDetails(currentOrder.customer_order_id);
        if (billingResult.success) {
          const order_driver_details = billingResult.invoice.order_driver_details || [];
          const order_info = _.find(order_driver_details, { customer_order_id: currentOrder.customer_order_id})
          const drivers_info = order_info?.driver_details ? order_info.driver_details : [];
          setCurrentDriverId('');
          setDriversInfo(_.sortBy(drivers_info, "loc_order_sequence"));
          getInvoices();
          setSelectedDriver("");
        }
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally{
        setInProgress(false)
    }
  };

  const handelCancel = () => {
    setShowEditModal(false);
    setCurrentDriverId('')
  };

  const renderEditModal = () => {
    return (
      <Modal
        title={"Change Driver"}
        onOk={handleSubmit}
        onCancel={handelCancel}
        bodyStyle={{ padding: 10 }}
        visible={showEditModal}
        okButtonProps={{
            loading: inProgress
        }}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form>
                <span className="textBold">{I18n.t("general.Driver")}:</span>
                <Select
                  showSearch
                  value={selectedDriver ? selectedDriver : ""}
                  onChange={handleDriverChnage}
                  filterOption={(input, option) => {
                    if (option.props.children) {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  style={{ width: "80%", height: "20%", marginLeft: 10 }}
                >
                  <Select.Option key={"driver"} value={""}>
                    --select--
                  </Select.Option>
                  {drivers
                    .filter((driver) => driver.id !== currentDriverId)
                    .map((driver) => (
                      <Select.Option key={driver.id} value={driver.id}>
                        {driver.employee_code}
                      </Select.Option>
                    ))}
                </Select>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  return (
    invoice && (
      <Fragment>
        {renderEditModal()}

        <Row>
          {!isEmpty(driversInfo) && driversInfo?.length && (
            <Col
              md={24}
              className="paddingLeft5 "
            >
              <h4 className="main_heading textBold">Driver Details</h4>
              <Row>
                {driversInfo.map((rec) => (
                  <Col
                    md={24 / driversInfo.length}
                    className=""
                  >
                    {rec.type_of_location && (
                      <div className="main_heading textBold fontSize13">
                        {rec.type_of_location}
                      </div>
                    )}
                    <div className="main_heading textBold fontSize13">
                      {rec.driver_name}
                      &nbsp;&nbsp;
                      {!["LH", "T"].includes(rec.type_of_order) &&
                        invoice.status ===
                          I18n.t("invoices.ready_for_billing") &&
                        showActions === true && (
                          <EditIcon handleClick={() => handleEdit(rec.driver_id)} />
                        )}
                    </div>
                    <div className="main_heading textBold fontSize13">
                      <Icon type="number" />
                      &nbsp;
                      {rec.driver_code}
                    </div>
                    <div className="fontSize12">
                        <InvoiceContact
                        data={rec}
                        showIcon
                        driverNo={rec.driver_number}
                        />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </Fragment>
    )
  );
};

export default BillingDrivers;

BillingDrivers.propTypes = {
  invoice: PropTypes.shape().isRequired,
  isReadyOnly: PropTypes.bool.isRequired,
  levelOfServices: PropTypes.arrayOf(PropTypes.shape()),
  handleOnChange: PropTypes.func.isRequired,
  showAddOrdersModal: PropTypes.bool.isRequired,
};

BillingDrivers.defaultProps = {
  levelOfServices: [],
  showAddOrdersModal: false,
};
