import React, { Fragment, useContext, useEffect, useState } from "react";
import { Tooltip, Popconfirm, TextArea, FormItem } from "../../common/UIComponents";
import { Button, Col, Form, Icon, Input, Row } from "antd";
import DeleteIcon from "../common/DeleteIcon";
import { checkListApi } from "../../api/CheckList";
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";
import { alertMessage } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { renderAlertMessage } from "../../helpers/common";
const OrgBilling = (props) => {
  //const { getFieldDecorator,validateFields } = props.form;
  const [approvalCheckLists, setApprovalCheckLists] = useState([]);
  const [editableId, setEditableId] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [editedCode, setEditedCode] = useState("");
  const [showNewRow, setShowNewRow] = useState(false);
  const [newRowName, setNewRowName] = useState("");
  const [newRowCode, setNewRowCode] = useState("");
  const [nameError, setNameError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [invoiceMailConfig, setInvoiceMailConfig] = useState({});
  const [dunningNoticeConfig, setDunningNoticeConfig] = useState({});
  const orgContextData = useContext(OrgContext);
  const { currentOrg, orgSettings, setOrgSettings } = orgContextData;

  const getCheckListData = () => {
    const organization_id = currentOrg.id;
    checkListApi.fetchCheckList(organization_id).then((result) => {
      if (result.success) {
        setApprovalCheckLists(
          result.data ? result.data.approval_check_lists : [
          ]
        )
      } else {
        renderAlertMessage(result.errors)
      }
    })
  };

  const getDunningNoticeText = () =>{
    setDunningNoticeConfig({
      dunning_notice_text: orgContextData.orgSettings?.dunning_notice_text ? orgContextData.orgSettings.dunning_notice_text : ""
    });
  }

  const updateDunningNoticeText = () => {
    const organization_id = currentOrg.id;
    const setting_name = "dunning_notice_text";
    const setting_value = dunningNoticeConfig["dunning_notice_text"];
    const payload = {organization_id, setting_name, setting_value}

    checkListApi.updateOrgConfig(payload).then((result) => {
      if (result.success) {
        setOrgSettings({
          ...orgSettings,
          dunning_notice_text: setting_value,
        });
        alertMessage("Updated Succesfully", "success");
      } else {
        renderAlertMessage(result.errors);
      }
    });
  }

  const updateInvoiceBodyText = () => {
    const organization_id = currentOrg.id;
    const setting_name = "invoice_body_text"
    const setting_value = invoiceMailConfig["invoice_body_text"]
    const data = {organization_id,setting_name,setting_value}
    checkListApi.updateInvoiceBodyText(data).then((result) => {
        if (result.success) {
          setOrgSettings({
            ...orgSettings,
            invoice_body_text: setting_value
          });
          alertMessage("Updated Succesfully", "success");
        } else {
          renderAlertMessage(result.errors)
        }
      })
  };

  const getUpdateInvoiceBodyText = () => {
    setInvoiceMailConfig({
      invoice_body_text: orgContextData.orgSettings?.invoice_body_text
        ? orgContextData.orgSettings.invoice_body_text
        : I18n.t("configurations.default_email_body_message"),
    });
  }

  // const getUpdateInvoiceBodyText = () => {
  //     const organization_id = userStore.getStateValue("selectedOrg")
  //     const setting_name = "invoice_body_text"
  //     checkListApi.getUpdateInvoiceBodyText(organization_id,setting_name).then((result) => {
  //       if (result.success) {
  //         const setting = result.data || {};
  //         setInvoiceMailConfig({
  //           invoice_body_text: setting.setting_value || I18n.t('configurations.default_email_body_message'),
  //         })
  //       } else {
  //         setInvoiceMailConfig({
  //           invoice_body_text: I18n.t('configurations.default_email_body_message')
  //         })
  //       }
  //   })
  // };

  const handleEdit = (id) => {
    setEditableId(id);
    const itemToEdit = approvalCheckLists.find(item => item.id === id);
    if (itemToEdit) {
      setEditedName(itemToEdit.name);
      setEditedCode(itemToEdit.code);
    }
    setShowNewRow(false)
  };
  const handleSave = (id) => {
    const payLoad = { name: editedName, code: editedCode }
    checkListApi.updateCheckList(id, payLoad).then((result) => {
      if (result.success) {
        setApprovalCheckLists(prevState => prevState.map(item =>
          item.id === id ? { ...item, name: result.data.check_list.name, code: result.data.check_list.code } : item
        ));
      } else {
        renderAlertMessage(result.errors)
      }
    })
    setEditableId(null);
  };
  const handleCancelEdit = () => {
    setEditableId(null);
  };
  const handleDelete = (id) => {
    checkListApi.deleteCheckList(id).then((result) => {
      if (result.success) {
        const updatedList = approvalCheckLists.filter(item => item.id !== id);
        setApprovalCheckLists(updatedList);
      } else {
        renderAlertMessage(result.errors)
      }
    })
  };
  const handleSaveNewRow = () => {
    if (newRowName.trim() === '' || newRowCode.trim() === '') {
      if (newRowName.trim() === '') {
        setNameError(`${I18n.t("errors.name_error")}`);
      } else {
        setNameError('');
      }
      if (newRowCode.trim() === '') {
        setCodeError(`${I18n.t("errors.code_error")}`);
      } else {
        setCodeError('');
      }
    } else {
      const newRow = {
        name: newRowName,
        code: newRowCode,
        organization_id: userStore.getStateValue("selectedOrg"),
      };
      checkListApi.saveCheckList(newRow).then((result) => {
        if (result.success) {
          setApprovalCheckLists(prevState => [...prevState, result.data.check_list]);
          setNewRowName("");
          setNewRowCode("");
          setShowNewRow(false);
          setCodeError('');
          setNameError('');
        } else {
          if (result.errors && result.errors.code) {
            setNewRowName(newRowName);
            setNewRowCode(newRowCode);
            setCodeError('');
            setNameError('');
          } else {
            renderAlertMessage(result.errors);
          }
        }
      })
      
    }
  };
  
  useEffect(() => {
    getCheckListData()
    getUpdateInvoiceBodyText()
    getDunningNoticeText()
  }, [])

  const ApproveCheckList = () => {
    if (!approvalCheckLists || approvalCheckLists.length === 0) {
      return <div>
        <Row>
          <div className="settingHeader">
            <span className="textBold">Approve Check List</span>
          </div>
        </Row>
        <Row>
          No data to display.
        </Row>
      </div>;
    } else {
      return (
        <div>
          <Row style={{ margin: "0px 10px 10px 10px" }}>
            <Row>
              <div className="settingHeader">
                <span className="textBold">Approve Check List</span>
              </div>
            </Row>
            <Row></Row>
            <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
              <Col span={8}>
                <strong style={{ paddingLeft: "15px" }}>Name</strong>
              </Col>
              <Col span={8}>
                <strong style={{ paddingLeft: "15px" }}>Code</strong>
              </Col>
            </Row>
            {approvalCheckLists.map((item, index) => (
              <Row gutter={[16, 16]} key={item.id}>
                <Col span={8}>
                  {editableId === item.id ? (
                    <Input
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                    />
                  ) : (
                    <Input
                      value={item.name}
                      disabled
                    />
                  )}
                </Col>
                <Col span={8}>
                  {editableId === item.id && item.type.toLowerCase() === "custom" ? (
                    <Input
                      value={editedCode}
                      onChange={(e) => setEditedCode(e.target.value)}
                    />
                  ) : (
                    <Input
                      value={item.code}
                      disabled
                    />
                  )}
                </Col>
                <Col span={8}>
                  {
                    editableId === item.id ?
                      (
                        <>
                          <Tooltip title={I18n.t("general.save")}>
                            <Icon
                              type="check-circle"
                              style={{ color: 'green' }}
                              size="small"
                              className="marginLeft10 fontSize18"
                              onClick={() => handleSave(item.id)}
                            />
                          </Tooltip>
                          <Tooltip title={I18n.t("general.cancel")}>
                            <Icon
                              type="close-circle"
                              style={{ color: 'red' }}
                              onClick={() => handleCancelEdit()}
                              size="small"
                              className="marginLeft10 fontSize18"
                            />
                          </Tooltip>
                        </>
                      ) :
                      (
                        <>
                          <Tooltip title={I18n.t("general.edit")}>
                            <Icon
                              type="edit"
                              style={{ color: 'blue', marginRight: "10px" }}
                              size="small"
                              className="marginLeft10 fontSize18"
                              onClick={() => handleEdit(item.id)}
                            />
                          </Tooltip>
                          {item.type.toLowerCase() === "system" ? null :
                            <Popconfirm
                              placement="left"
                              title={I18n.t('messages.confirm')}
                              onConfirm={() => handleDelete(item.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteIcon className="textBold fontSize18" />
                              &nbsp;&nbsp;&nbsp;
                            </Popconfirm>
                          }
                        </>
                      )}
                  {!showNewRow && approvalCheckLists.length - 1 === index &&
                    <>
                      {editableId ?
                        null
                        :
                        <Tooltip title={I18n.t("general.add")}>
                          <Icon
                            type="plus-circle"
                            size="small"
                            style={{ marginTop: 7, color: 'black' }}
                            className="marginLeft10 fontSize18"
                            onClick={() => setShowNewRow(true)}
                          />
                        </Tooltip>
                      }
                    </>
                  }
                </Col>
              </Row>
            ))}
            {showNewRow &&
              <>
                {editableId ?
                  null
                  :
                  (
                    <Row gutter={[16, 16]} key="new-row">
                      <Col span={8} className="right-aligned">
                        <Input
                          placeholder="New Name"
                          value={newRowName}
                          onChange={(e) => setNewRowName(e.target.value)}
                          autoFocus
                        />
                        {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
                      </Col>
                      <Col span={8}>
                        <Input
                          placeholder="New Code"
                          value={newRowCode}
                          onChange={(e) => setNewRowCode(e.target.value)}
                        />
                        {codeError && <div style={{ color: 'red' }}>{codeError}</div>}
                      </Col>
                      <Col span={8}>
                        <Tooltip title={I18n.t("general.save")}>
                          <Icon
                            type="check-circle"
                            style={{ color: 'green' }}
                            size="small"
                            className="marginLeft10 fontSize18"
                            onClick={() => handleSaveNewRow()}
                          />
                        </Tooltip>
                        <Tooltip title={I18n.t("general.cancel")}>
                          <Icon
                            type="close-circle"
                            size="small"
                            style={{ color: 'red' }}
                            className="marginLeft10 fontSize18"
                            onClick={() => setShowNewRow(false)}
                          />
                        </Tooltip>
                        {showNewRow &&
                          <Tooltip title={I18n.t("general.add")}>
                            <Icon
                              type="plus-circle"
                              size="small"
                              style={{ marginTop: 7, color: 'black' }}
                              className="marginLeft10 fontSize18"
                              onClick={() => setShowNewRow(true)}
                            />
                          </Tooltip>
                        }
                      </Col>
                    </Row>
                  )
                }
              </>
            }
          </Row>
        </div>
      );
    };
  }

  
  const InvoiceMailConfiguration = () => {
      return (
        <>
        <Row>
          <div className="settingHeader">
            <span className="textBold">Invoice Mail Configuration</span>
          </div>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={5} lg={20}>
            <FormItem label={I18n.t('configurations.invoice_email_body_text')}>
            {/* {getFieldDecorator("setting_value", {
              initialValue: "Message",
              rules: [
                {
                  required: true,
                  message: "Invalid input",
                },
              ],
            }) */}
                <TextArea rows="3"  autoSize={{ minRows: 3, maxRows: 3 }} value={invoiceMailConfig.invoice_body_text} onChange={e => setInvoiceMailConfig({
                  ...invoiceMailConfig,
                  invoice_body_text: e.target.value
                })}
                   />
            </FormItem>
          </Col>
        </Row>
        <Row>
        <div style={{ textAlign: 'center',marginRight:'100px' }}>
          <Button
            htmlType="submit"
            icon="save"
            onClick={() => updateInvoiceBodyText()}
            type="primary"
          >
            Save
          </Button>
        </div>
        </Row>
        </>
      )
    };
    

  // const _dunningNoticeConfiguration = () => {
  //   return (
  //     <Fragment>
  //       <Row>
  //         <div className="settingHeader">
  //           <span className="textBold">Dunning Notice Configuration</span>
  //         </div>
  //       </Row>
  //       <Row gutter={16}>
  //         <Col sm={24} xs={24} md={5} lg={20}>
  //           <FormItem label="Default Body of Dunning Notice Text">
  //             <TextArea
  //               rows="3"
  //               autoSize={{ minRows: 3, maxRows: 3 }}
  //               value={dunningNoticeConfig.dunning_notice_text}
  //               onChange={(e) =>
  //                 setDunningNoticeConfig({
  //                   ...dunningNoticeConfig,
  //                   dunning_notice_text: e.target.value,
  //                 })
  //               }
  //             />
  //           </FormItem>
  //         </Col>
  //       </Row>
  //       <Row>
  //         <div style={{ textAlign: "center", marginRight: "100px" }}>
  //           <Button
  //             htmlType="submit"
  //             icon="save"
  //             onClick={() => updateDunningNoticeText()}
  //             type="primary"
  //           >
  //             Save
  //           </Button>
  //         </div>
  //       </Row>
  //     </Fragment>
  //   );
  // };


  return (
    <Row>
      <Col span={10}>
        <div style={{ maxHeight: "700px", overflowY: "auto" }}>
          {ApproveCheckList()}
        </div>
      </Col>
      <Col span={2}>{""}</Col>
      <Col span={10}>
        <Row>
          <Col>{InvoiceMailConfiguration()}</Col>
          {/* <Col style={{ marginTop: 5 }}>{_dunningNoticeConfiguration()}</Col> */}
        </Row>
      </Col>
    </Row>
  ); 
}


export default OrgBilling