/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Col, Row, Button, Popconfirm,Spin, Tooltip } from '../../common/UIComponents';
import {  alertMessage, customPasteSplit, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import FormButtons from '../common/FormButtons';
import { fetchAccountDeliveryZipcodes, saveDeliveryZipcodes } from '../../api/Account';
import FormErrors from '../common/FormErrors';
import AppConfig from '../../config/AppConfig';
import expandIcon from '../../../assets/images/file-expand-icon.png';
import BaseModal from '../BaseModal';
import userStore from '../../stores/UserStore';
import { checkServiceExistance, confirmationPopup } from '../../helpers/common';


class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      errors: [],
      showMaxWindow: false,
      currentDay: {
        dow: '',
        zipcodes: [],
      },
      dowPincodes: {},
      account: this.props.account || {},
      zipCodesFor: this.props.zipCodesFor ? this.props.zipCodesFor : '',
    };
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.fetchMappingDetails();
  }

  // componentWillReceiveProps(nextProps){
  //   let account = {};
  //   this.setState({
  //     account: nextProps.account,
  //   }, () => {
  //     this.fetchMappingDetails();
  //   });
  // }

  static getDerivedStateFromProps(props, state) {
    const { account } = state;
    return { account };
  }

  closeDayWindow = () => {
    this.setState({
      showMaxWindow: false,
      currentDay: {
        dow: '',
        zipcodes: [],
      },
    });
  }

  handleZipCodeChange = (element, value, dowInput) => {
    const dowPincodes = Object.assign({}, this.state.dowPincodes);
    dowPincodes[dowInput] = value;
    this.setState({ dowPincodes });
  }

  handleMaxWindowChange = (value) => {
    this.setState({
      currentDay: Object.assign({}, this.state.currentDay, { zipcodes: value }),
    });
  }

  handleDayWiseSave = () => {
    if (this.state.currentDay.dow) {
      const dowPincodes = Object.assign({}, this.state.dowPincodes);
      dowPincodes[this.state.currentDay.dow] = this.state.currentDay.zipcodes;
      this.setState({ dowPincodes }, () => {
        this.handleSave();
      });
    }
  }

  handleSave() {
    this.setState({ inProgress: true });
    const zipCodes = [];
    Object.keys(this.state.dowPincodes).forEach((dow) => {
      if (!isEmpty(this.state.dowPincodes[dow]) && this.state.dowPincodes[dow].length > 0) {
        zipCodes.push({ dow, zip_codes: this.state.dowPincodes[dow].join(',') });
      }
    });
    const data = {
      account_id: this.state.zipCodesFor === 'org' ? '' : this.state.account.id,
      dow_zip_codes: zipCodes,
      org_id: this.state.account.org_id,
      use_org_delivery_zips: this.state.zipCodesFor === 'org' ? true : this.state.account.org_delivery_zip,
    };
    saveDeliveryZipcodes(data)
      .then((result) => {
        if (result.success) {
          this.setState({ inProgress: false });
          alertMessage(I18n.t('messages.saved'));
          this.closeDayWindow();
          this.fetchMappingDetails();
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }

  fetchAccountMappingDetails = (cb) => {
    this.setState({ inProgress: true });
    fetchAccountDeliveryZipcodes(this.state.account.organization_id, this.state.account.id)
      .then((result) => {
        if (result.success) {
          const zipcodes = result.dow_zipcodes || [];
          const dowPincodes = {};
          zipcodes.forEach((dowPincode) => {
            dowPincodes[dowPincode.dow] = dowPincode.zip_codes.split(',');
          });

          this.setState({
            dowPincodes,
            inProgress: false,
          }, cb);
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }

  fetchOrgMappingDetails = (cb) => {
    this.setState({ inProgress: true });
    const orgId = userStore.getStateValue('selectedOrg');
    fetchAccountDeliveryZipcodes(orgId, '', true)
      .then((result) => {
        if (result.success) {
          const zipcodes = result.dow_zipcodes || [];
          const dowPincodes = {};
          zipcodes.forEach((dowPincode) => {
            dowPincodes[dowPincode.dow] = dowPincode.zip_codes.split(',');
          });

          this.setState({
            dowPincodes,
            inProgress: false,
          }, cb);
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });


        }
      });
  }

  fetchMappingDetails = (cb) => {
    if (this.state.zipCodesFor === 'org' || (this.state.zipCodesFor === 'account' && this.state.account.org_delivery_zip === true)) {
      this.fetchOrgMappingDetails(cb);
    } else {
      this.fetchAccountMappingDetails(cb);
    }
  }


  showDayWindow = (dow) => {
    this.setState({
      showMaxWindow: true,
      currentDay: {
        dow,
        zipcodes: this.state.dowPincodes[dow],
      },
    });
  }

  handleApplyButton = () => {
    const account = { ...this.state.account };
    if (this.state.account.org_delivery_zip === true) {
      account.org_delivery_zip = false;
    } else {
      account.org_delivery_zip = true;
    }
    this.setState({
      account,
    }, () => {
      this.fetchMappingDetails(() => {
        this.handleSave();
      });
    });
  }

  clearZipCodes = (dow) => {
    this.setState({
      dowPincodes: {
        ...this.state.dowPincodes,
        [ dow ]: [],
      },
    });
  }
  clearExpandedZipCodes = () => {
    this.setState({
      currentDay: {
        ...this.state.currentDay,
        zipcodes: [],
      },
    });
  }

  render() {
    const { dowPincodes } = this.state;
    const isShowSave = checkServiceExistance('SWHZC') && (
      this.state.zipCodesFor === 'org' || (this.state.zipCodesFor === 'account' && this.state.account.org_delivery_zip === false));
    return (
      <div className="padding10">
        <Spin spinning={this.state.inProgress} delay={1000}>
        {
          this.state.zipCodesFor === 'account' &&
          <Row className="configLableRow">
            <Col md={24} className="fontSize14 alignCenter">
              <span className="textBold">
              { 
                this.state.account.org_delivery_zip === true ? 
                 <Fragment>
                    {I18n.t('delivery_zones.overwrite_org_values')}&nbsp;
                    <Popconfirm
                      placement="topRight"
                      title={I18n.t('delivery_zones.overwrite_msg')}
                      onConfirm={() => this.handleApplyButton()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="link"
                        size="small"
                        className="fontSize14"
                      > 
                      <u>{I18n.t('general.click_here')}</u>
                      </Button>
                      </Popconfirm>
                    {I18n.t('delivery_zones.click_here_msg')}
                 </Fragment>
                :
                <Fragment>
                 <Popconfirm
                      placement="topRight"
                      title={I18n.t('delivery_zones.overwrite_msg')}
                      onConfirm={() => this.handleApplyButton()}
                      okText="Yes"
                      cancelText="No"
                    > <Button
                        type="link"
                        size="small"
                        className="fontSize14"
                      ><u>{I18n.t('general.click_here')}</u>
                      </Button>
                  </Popconfirm>
                  {I18n.t('delivery_zones.apply_org_values')}
                </Fragment>
              }
              </span>
              
            </Col>
          </Row>
        }
        { AppConfig.dows.map(dow => (
          
          <Row gutter={16} className="mappingRow">
            <Col sm={6} xs={6} md={4} lg={4}>
              <Row>
                <Col>
                  <h4 >{ I18n.t(`days.${dow}`) } </h4>
                </Col>
                {isShowSave && (
                  <Col>
                    { this.state.dowPincodes[ dow ] && this.state.dowPincodes[ dow ].length > 0 && (
                      <Button
                        type="link"
                        size="small"
                        onClick={ () => {
                          confirmationPopup({
                            title: I18n.t('messages.action_confirm', { action: 'clear', field: 'zipcodes' }),
                            onConfirm: () => {
                              this.clearZipCodes(dow);
                            }
                          });
                        }
                        }
                      >
                        <u>{ I18n.t('general.clear') }</u>
                      </Button>
                    ) }
                  </Col>
                )}
              </Row>
            </Col>
            <Col sm={18} xs={18} md={20} lg={20}>
              <Row className="zipCodeBox">
                <Col xs={23} className="padding2">
                  <TagsInput
                    value={dowPincodes[dow] || []}
                    onChange={e => this.handleZipCodeChange('dowPincodes', e, dow)}
                    validationRegex={/^(?=[0-9]*$)(?:.{3}|.{5})$/}
                    addKeys={[9, 13, 32]}
                    inputProps={{
                      placeholder: 'Add a zipcode',
                    }}
                    onlyUnique
                    addOnPaste
                    pasteSplit={customPasteSplit}
                    disabled={!isShowSave}
                    addOnBlur={ true }
                  />
                </Col>
                <Col xs={1} >
                <Tooltip title={I18n.t('general.views.detail')}>
                  <img
                    src={expandIcon}
                    className="expandIcon"
                    onClick={() => this.showDayWindow(dow)}
                    alt={I18n.t('general.expand')}
                  />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          
        ))
        }
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        { isShowSave &&
        <Row>
          <Col xs={24}>
            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel, false, true)}
          </Col>
        </Row>
        }

        {this.state.showMaxWindow &&

        <BaseModal
          title={
            `${this.state.currentDay ? `${I18n.t('location.mapping_heading')} on ${I18n.t(`days.${this.state.currentDay.dow}`)}` : ''}`
          }
          onCancel={() => this.closeDayWindow()}
          className="surveyModal"
          width='60%'
          style={{ top: 40, overflowY: 'auto' }}
        >
          <Fragment>
            <Row gutter={16} className="tagssettingRow">

              <Col sm={24} xs={24} md={24} lg={24}>
                <TagsInput
                  value={this.state.currentDay.zipcodes || []}
                  onChange={this.handleMaxWindowChange}
                  validationRegex={/^(?=[0-9]*$)(?:.{3}|.{4}|.{5})$/}
                  addKeys={[9, 13, 32]}
                  inputProps={{
                    placeholder: 'Add a zipcode',
                  }}
                  onlyUnique
                  addOnPaste
                  pasteSplit={customPasteSplit}
                  disabled={!isShowSave}
                  addOnBlur={ true }
                />
              </Col>
            </Row>
            { isShowSave &&
            <Row>
              <Col xs={24}>
                {FormButtons(this.state.inProgress, this.handleDayWiseSave, this.clearExpandedZipCodes,true,true,'default',I18n.t('general.apply'), I18n.t('general.clear') )}
              </Col>
            </Row>
            }
          </Fragment>
        </BaseModal>
        }
        </Spin>
      </div>
    );
  }
}

LocationMap.propTypes = {
  isNew: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

LocationMap.defaultPropTypes = {
  location: {},
  isNew: true,
};

export default LocationMap;
