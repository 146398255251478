/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import BaseList from '../BaseList';
import { DatePicker,  Radio, Row, Col, Input } from '../../common/UIComponents';
import MaterialTimePicker from '../orders/MaterialTimePicker';
import I18n from '../../common/I18n';
class OrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    const {isMilitaryTime} = props;
    this.columns = [
      {
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: "customer_order_number",
        render: (text, record, index) => {
          const isDelivery = record?.type_of_loc === "DELIVERY";
          const isPickup = record?.type_of_loc === "PICKUP";
          const textToDisplay = isDelivery ? text + "(D)" : isPickup ? text + "(P)" : text;
          return(
          <div>
            <Row>
              <Col>{textToDisplay}</Col>
            </Row>
          </div>
        )},
      },
      {
        key: 'contact_name',
        title: I18n.t('order.customer_name'),
        render: data => (
          <div>
            <Row>
              <Col>{data.contact_name}</Col>
            </Row>
          </div>
        ),
      },
      {
        key: 'status',
        title: I18n.t('general.status'),
        width: 240,
        render: (text, data, index) => (
          <div>
            <Radio.Group
              onChange={e => this.props.handleChange(data.customer_order_id, data.location_id, 'status', e.target.value)}
              value={data.status}
            >
              <Radio value={I18n.t('routes.statuses.completed_key')}>{I18n.t('routes.statuses.completed_label')}</Radio>
              <Radio value={I18n.t('routes.statuses.exception_key')}>{I18n.t('routes.statuses.exception_label')}</Radio>
            </Radio.Group>
          </div>
        ),
      },
      {
        key: 'date',
        title: I18n.t('general.date'),
        width: 140,
        render: (text, data, index) => (
          <div>
            {
              data.status !== I18n.t('routes.statuses.exception_key') && <DatePicker
                size="small"
                style={{ width: '100%', marginLeft: '1%' }}
                placeholder="Select Date"
                format='Do MMM YYYY'
                onChange={e => this.props.handleChange(data.customer_order_id, data.location_id, 'action_date', e)}
                value={data.action_date ? moment(new Date(data.action_date)) : null}
                disabledDate={
                  current => current && current > moment().endOf('day')
                }
              />
            }
          </div>
        ),
      },
      {
        key: 'time',
        title: I18n.t('general.time'),
        width: 120,
        render: (text, data, index) => (
          <div className='order_details_form'>
            {
              data.status !== I18n.t('routes.statuses.exception_key') &&
              <MaterialTimePicker
                style={ { width: '140px' }}
                onChange={e => this.props.handleChange(data.customer_order_id, data.location_id, 'action_time', e)}
                value={data.action_time}
                computeDisabledHours={ () => []}
                computeDisabledMinutes={() => []}
                isMilitaryTime={isMilitaryTime}
              />
            }
          </div>
        ),
      },
      {
        key: "signer_name",
        title: I18n.t("order.sign_by"),
        render: (text,data,index) => (
          <div>
            <Row>
              <Col>
              <Input
                  size="small"
                  value={data.sign_by}
                  onChange={e => this.props.handleChange(data.customer_order_id, data.location_id, 'sign_by', e.target.value)}
              />
              </Col>
            </Row>
          </div>
        ),
      },
      {
        key: "signer_title",
        title: I18n.t("configurations.signer_title"),
        render: (text,data,index) => (
          <div>
            <Row>
              <Col>
                <Input
                    value={data.signed_title}
                    size="small"
                    onChange={e => this.props.handleChange(data.customer_order_id, data.location_id, 'signed_title', e.target.value)}
                />
              </Col>
            </Row>
          </div>
        ),
      },
    ];
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default OrdersList;
