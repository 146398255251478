import {
  Col,
  Form,
  Row,
  Select,
  DatePicker,
  Button,
  Typography,
  Tooltip,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import I18n from "../../common/I18n";
import { fetchOrganizations } from "../../api/Organisations";
import { alertMessage, isEmpty } from "../../common/Common";
import { driverMonthlyReportApi } from "../../api/DriverMonthlyReport";
import DriverSummaryList from "./DriverSummaryList";
import AppConfig from "../../config/AppConfig";
import moment from "moment";
import exportIcon from "../../../assets/images/exportFile.png";
import DriverDetailedList from "./DriverDetailedList";
import { renderAlertMessage, responseTOCsv } from "../../helpers/common";

const DriverHomeScreen = (props) => {
  const [orgData, setOrgData] = useState([]);
  const [reqOrgId, setReqOrgId] = useState(null);
  const [screenType, setScreenType] = useState("SUMMARY");
  const [rawData, setRawData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enableExport, setEnableExport] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [sortedDatesData, setSortedDatesData] = useState([]);
  const { getFieldDecorator, validateFields } = props.form;
  const { RangePicker } = DatePicker;
  const gridSize = screenType === "SUMMARY" ? 16 : 20;
  useEffect(() => {
    getOrganizations();
    debounceHandleSubmit();
  }, []);

  useEffect(() => {
    debounceHandleSubmit();
  }, [screenType, enableExport]);

  useEffect(() => {
    if (trigger) {
      debounceHandleSubmit();
      setTrigger(false);
    }
  }, [trigger]);

  const debounceHandleSubmit = _.debounce(
    () => handleSubmit({ preventDefault: () => {} }),
    enableExport ? 1000 : 2000
  );

  const getOrganizations = () => {
    setIsLoading(true);
    fetchOrganizations(1, null)
      .then((result) => {
        if (result.success) {
          const orgRec = _.find(result.organizations.organizations, [
            "code",
            I18n.t("account.netmove_code"),
          ]);
          let orgId = !isEmpty(orgRec) ? orgRec._id : "";
          if (!orgId) {
            orgId =
              result.organizations?.organizations?.length > 0
                ? result.organizations.organizations[0].id
                : "";
          }
          setOrgData(result.organizations.organizations);
          setReqOrgId(orgId);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFilterChange = (val) => {
    setScreenType(val);
  };

  const handleExport = () => {
    setEnableExport(true);
  };
  const getTheUniqueDates = (data) => {
    const alterDataForDetailsScreen = data.map((item) => {
      return item.driver_details.map((item) => {
        return item.date;
      });
    });
    const uniqueDates = _.uniq(_.flatten(alterDataForDetailsScreen));

    const sortedDatesData = _.sortBy(uniqueDates, (date) => {
      return moment(date).format("YYYY-MM-DD");
    });
    setSortedDatesData(sortedDatesData);
  };

  const finalData = rawData.map((item) => {
    const obj = { ...item };
    item.driver_details.forEach((record) => {
      obj[record.date] = record.driver_routes;
    });
    return obj;
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      // check if the month date range is not more than 31 days
      const startDate = moment(values.month[0]).format("YYYY-MM-DD");
      const endDate = moment(values.month[1]).format("YYYY-MM-DD");
      const diff = moment(endDate).diff(moment(startDate), "days") + 1;
      if (diff > 31) {
        alertMessage("Range Cannot Be Greater Than 31 Days", "error", 5);
        return;
      }
      if (!err) {
        const payload = {
          organization_id: values.organizationId,
          from_date: moment(values.month[0]).format("YYYY-MM-DD"),
          to_date: moment(values.month[1]).format("YYYY-MM-DD"),
          as_export: enableExport,
          view_type: screenType,
        };
        setIsLoading(true);
        driverMonthlyReportApi
          .create(payload)
          .then((result) => {
            if (result.success) {
              if (enableExport) {
                const fileName = `DriverRoutes-${moment(values.month[0]).format(
                  "YYYY-MM-DD"
                )}_${moment(values.month[1]).format(
                  "YYYY-MM-DD"
                )}_${moment().format("HH:mm:ss")}`;
                responseTOCsv(result.data, fileName, "CSV");
              } else {
                setRawData(_.get(result, "data.driver_report_data", []));
                getTheUniqueDates(result.data.driver_report_data);
              }
            } else {
              renderAlertMessage(result.errors)
              setIsLoading(false);
              setEnableExport(false);
            }
          })
          .catch((error) => {
            alertMessage(error, "error", 10);
            setIsLoading(false);
            setEnableExport(false);
          })
          .finally(() => {
            setIsLoading(false);
            setEnableExport(false);
          });
      }
    });
  };

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("driver_monthly_report.title")}</Col>
        </Row>
        <Form>
          <Row gutter={8} type="flex" align="middle">
            <Col sm={24} xs={24} md={5} lg={5}>
              <Form.Item
                label={I18n.t("organization.single")}
                className="driver-monthly-pay"
              >
                {getFieldDecorator("organizationId", {
                  initialValue: reqOrgId,
                  rules: [
                    {
                      required: true,
                      message: I18n.t("driver_monthly_report.error"),
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder={I18n.t("organization.single")}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    loading={isLoading}
                    style={{ width: "100%" }}
                    onChange={() => setTrigger(true)}
                  >
                    {orgData.map((org) => (
                      <Select.Option key={org.id} value={org.id}>
                        {org.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={5} lg={5}>
              <Form.Item
                label={I18n.t("driver_monthly_report.month")}
                className="driver-monthly-pay"
              >
                {getFieldDecorator("month", {
                  initialValue: [moment().startOf("month"), moment()],
                  rules: [
                    {
                      required: true,
                      message: I18n.t("driver_monthly_report.error"),
                    },
                  ],
                })(
                  <RangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      "Today & Tomorrow": [moment(), moment().add(1, "day")],
                      "This Week": [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    placeholder={I18n.t("driver_monthly_report.month")}
                    style={{ width: "100%" }}
                    format={AppConfig.dateFormat}
                    onChange={() => setTrigger(true)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={4} lg={4}>
              {/* <Button
                type="primary"
                loading={isLoading}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {I18n.t("driver_monthly_report.details")}
              </Button> */}
              <Form.Item
                label={I18n.t("driver_monthly_report.view_type")}
                className="driver-monthly-pay"
              >
                <Select
                  showSearch
                  value={screenType}
                  onChange={(value) => handleFilterChange(value)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={isLoading}
                  size="default"
                  style={{ width: "100%" }}
                >
                  <Select.Option key="summary" value="SUMMARY">
                    {I18n.t("driver_monthly_report.summary")}
                  </Select.Option>
                  <Select.Option key="details" value="DETAILED">
                    {I18n.t("driver_monthly_report.details_opt")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={5} lg={5}>
              {rawData && rawData.length > 0 && (
                <Row>
                  <Col md={gridSize}>
                    <span className="filterSetIcon">
                      <Tooltip title="Export">
                        <img
                          src={exportIcon}
                          alt="export"
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={handleExport}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
        <Row gutter={[8, 8]}>
          {rawData && rawData.length > 0 && (
            // <Col offset={(24 - gridSize) / 2} ms={gridSize}>
            <Col>
              <Row type="flex" justify="space-between">
                <Col style={{ marginTop: 20 }}>
                  <Typography>
                    <Typography.Text strong>Total Drivers :</Typography.Text>{" "}
                    <Typography.Text strong>
                      {rawData ? rawData.length : ""}
                    </Typography.Text>
                  </Typography>
                </Col>
              </Row>
            </Col>
          )}
          {screenType === "SUMMARY" ? (
            // <Col offset={4} md={16}>
            <Col>
              <Spin spinning={isLoading}>
                <DriverSummaryList
                  data={rawData ? rawData : []}
                  scroll={{ y: 500 }}
                  pagination={{ position: "none" }}
                />
              </Spin>
            </Col>
          ) : (
            // <Col offset={2} md={20}>
            <Col>
              <Spin spinning={isLoading}>
                <DriverDetailedList
                  data={finalData ? finalData : []}
                  dateColumns={sortedDatesData}
                  scroll={{ x: "max-content", y: 500 }}
                  pagination={{ position: "none" }}
                  bordered
                />
              </Spin>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Form.create()(DriverHomeScreen);
