import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popconfirm, Button } from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import I18n from "../../common/I18n";

const ReleaseActions = ({
  selectedInfo,
  handleClear,
  filter,
  setIsListLoading,
  refreshList,
  multipleDeletion,
  changeStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const handleDelete = () => {
    multipleDeletion(selectedInfo.selectedKeys)
  }

  return (
    <Fragment>
    <Row className="bottom_fixed">
      <Col xs={24} style={{ padding: "10px 10px" }} className="alignCenter">
        <Button
          type="danger"
          onClick={handleClear}
          icon="close"
        >
          {I18n.t('list.clear_lable')}
        </Button>
        &nbsp;&nbsp;
        {/* {checkServiceExistance("OREDEL") &&
            <Popconfirm
              placement="rightTop"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" className="buttonStyle">
                {I18n.t('general.delete')}
              </Button>
            </Popconfirm>
        } */}
        {checkServiceExistance("ORECS") && filter !== "completed" && (
          <Button
            type="primary"
            className="assignDriver"
            onClick={() =>changeStatus()}
            disabled={!(selectedInfo.selectedKeys.length > 0)}
            icon="setting"
          >
            {I18n.t('general.change_status')}
          </Button>
        )}
      </Col>
    </Row>
    {
        errors.length > 0 && Modal.error({
          title: 'Errors',
          content: (
            <Row>
              <Col>
                <h4>{errors.join(", ")}</h4>
              </Col>
            </Row>
          ),
          onOk: () => {
            setErrors([])
            refreshList()
          },
        })
    }
    </Fragment>
  );
};

ReleaseActions.propTypes = {
    filter: PropTypes.string.isRequired,
    selectedInfo: PropTypes.shape.isRequired,
    handleClear: PropTypes.func.isRequired,
    setIsListLoading: PropTypes.func.isRequired,
    multipleDeletion: PropTypes.func.isRequired,
};

export default ReleaseActions;
