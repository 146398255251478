import React, { Fragment, useEffect, useState } from "react";
import { useContext } from "react";
import { alertMessage } from "../../../common/Common";
import {
  Row,
  Col,
  Button,
  FormItem,
  Radio,
  Tooltip,
  DatePicker,
  SideBySideFormItem,
    Icon,
  Select,
  Alert,
} from "../../../common/UIComponents";
import { base64ToPDF, base64ToExcel } from "../../../helpers/common";
import BaseModal from "../../BaseModal";
import { exportAgingFile } from "../../../api/Reports";
import { AccountSelect } from "../../common/SelectDropdowns/BaseSelect";
import { isEmpty, set } from "lodash";
import { WarehouseContext } from "../../../context/WarehouseContext";
import { OrgContext } from "../../../context/OrgContext";
import moment from "moment";
import I18n from "../../../common/I18n";
import WarehouseCodeFilter from "../../common/WarehouseCodeFilter";
import AccountCodesFilter from "../../../containers/Filters/AccountCodesFilter";

const labelCol={lg: 6, xl: 6, xxl: 6 };
const wrapperCol={ lg: 18, xl: 18, xxl: 18 };
const defaultExportType = "PDF";

const ExportReport = (props) => {
  const { accounts, filterPlaceHolder = {}} = props;
  const warehouseFilter = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const currentOrg = orgData.currentOrg || {};
  const warehouseId = filterPlaceHolder.warehouseIds ? (Array.isArray(warehouseFilter.selectedWarehouses)
  ? warehouseFilter.selectedWarehouses
  : warehouseFilter?.selectedWarehouses
  ? [warehouseFilter.selectedWarehouses]
  : null) : filterPlaceHolder.warehouseIds;
  const [filteredAccounts, setFilteredAccounts] =  useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [file_type, setFileType] = useState(defaultExportType);
  const [whIds, setWhIds] = useState(warehouseId);
  const [exportEmail, setExportEmail] = useState(props.exportEmail ? props.exportEmail : "");
  const [accountId, setAccountId] = useState(filterPlaceHolder?.accountCodes ? filterPlaceHolder.accountCodes: null);
  const [reportDate, setReportDate] = useState(filterPlaceHolder?.reportDate);
  const [viewType, setViewType] = useState('DETAILS');
  const [invoiceAmountFilter, setInvoiceAmountFilter] = useState(filterPlaceHolder.invoiceAmountFilter || []);
  const stopTypes = [
    { key: "SUMMARY", label: "Summary" },
    { key: "DETAILS", label: "Details" },
  ];

  const setFilterAccountIds = (filteredAccount = []) => {
    const filteredAccIds =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.id) : [];

    if (accountId?.length) {
      const filteredAccountIds = filteredAccIds.filter((acc_code) =>
      accountId.includes(acc_code)
      );
      setAccountId(filteredAccountIds)
    } else {
      setAccountId([])
    }
  };

  const handleAgingExport = () => {
    // const selectedwh =
    //   _.isArray(warehouseId) && warehouseId.length > 0
    //     ? warehouseId[0]
    //     : warehouseId;
    const organization_id = currentOrg.id;
    setIsLoading(true);
    exportAgingFile(
      organization_id,
      whIds,
      file_type.toLowerCase(),
      accountId,
      reportDate,
      viewType,
      invoiceAmountFilter,
      exportEmail,
    )
      .then((result) => {
        if (result.success) {
          const message = result.data?.message || "";
          if (isEmpty(message)) {
            if (file_type === "EXCEL") {
              base64ToExcel(
                result.data.file,
                result.data.filename,
                file_type
              );
            } else if (file_type === "PDF") {
              base64ToPDF(
                result.file,
                `${currentOrg.code}_${moment().format(
                  "MM-DD-YYYY_hh-mm-ss"
                )}_aging_report`
              );
            } else {
              alertMessage(`${file_type} is not found`, "error", 10);
            }     
            alertMessage(`File Exported Successfully!!`, "success", 10);
          } else {
            if (isEmpty(message)) {
              alertMessage(
                `${file_type} is not found`,
                "error",
                10
              );
            }
            else {
              alertMessage(
                message,
                "success",
                10
              );
            }
          }

          setShowExportModal(false);
          setDefaultValues();
        } else {
          alertMessage(
            result?.errors.length ? result.errors[0] : "failed to export file",
            "error",
            10
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        setDefaultValues();
      });
  };

  const setDefaultValues = () => {
    setFileType(defaultExportType);
    setAccountId(filterPlaceHolder.accountCodes || null);
    setReportDate(moment().format("YYYY-MM-DD"));
    setExportEmail([]);
  };

  const setInitialValues = () => {
    setDefaultValues()
    setFilteredAccounts(props.accounts || [])
    setAccountId(filterPlaceHolder?.accountCodes?.length ? filterPlaceHolder.accountCodes : null)
    setWhIds(filterPlaceHolder?.warehouseIds?.length ? filterPlaceHolder.warehouseIds : null)
    setInvoiceAmountFilter(filterPlaceHolder.invoiceAmountFilter)
  };

  const handleWhChange = (element, value) => {
    setWhIds(value);
    if (value?.length === 0) {
      setFilteredAccounts([...accounts]);
    } else {
      const accs = accounts.filter((account) =>
        account.warehouse_ids.some((id) => value?.includes(id))
      );
      setFilteredAccounts(accs?.length ? accs : [])
      setFilterAccountIds(accs)
    }
  };

  const handleAccountChange = (element, value) => {
    setAccountId( value ? value : null);
  };
  const handleDateChange = (value) => {
    if (value) {
      setReportDate(value.format("YYYY-MM-DD"));
    } else {
      setReportDate(moment().format("YYYY-MM-DD"));
    }
  };

  const renderWhFilter = () => {
    const { warehouses } = warehouseFilter;
    return (
      <SideBySideFormItem
      labelCol={{...labelCol}}
      wrapperCol={{...wrapperCol}}
      label={I18n.t("location.location")}>
        <WarehouseCodeFilter
          SelectedCodes={whIds}
          element={"whIds"}
          onValueChange={handleWhChange}
          warehouses={warehouses}
          size="default"
        />
      </SideBySideFormItem>
    );
  };

  const _renderAgingReportForm = () => {
    return (
      <Fragment>
        <Row gutter={[16, 16]}>
          <Col>
              {renderWhFilter()}
          </Col>
          <Col>
              {/*  <FormItem label={I18n.t("general.account")}>
              <AccountSelect
                onChange={handleAccountChange}
                data={filteredAccounts}
                size="default"
                element="selectedAccounts"
                selectKey="id"
                selectValue="id"
                selectedCode="code"
                selectedValue="name"
                style={{ width: "50%" }}
                value={accountId}
                showSearch={true}
                allowClear={true}
                dropdownMatchSelectWidth={false}
              /> </FormItem>*/}
              <SideBySideFormItem
              labelCol={{...labelCol}}
              wrapperCol={{...wrapperCol}}
              label={I18n.t("general.account")}>
              <AccountCodesFilter
                SelectedAccountCodes={accountId}
                accounts={filteredAccounts}
                size="default"
                onValueChange={handleAccountChange}
                valueField="id"
                styleObject={{ width: "100%" }}
                showLabel={false}
              />
              </SideBySideFormItem>

          </Col>
          <Col>
          <SideBySideFormItem
            labelCol={{...labelCol}}
            wrapperCol={{...wrapperCol}}
            label={I18n.t("aging.report_date")}>
              <DatePicker
                value={moment(reportDate)}
                style={{ width: "50%" }}
                onChange={handleDateChange}
                format="DD MMM YYYY"
                size="medium"
              />
            </SideBySideFormItem>
          </Col>

          <Col>
            <SideBySideFormItem
            labelCol={{...labelCol}}
            wrapperCol={{...wrapperCol}}
            label={I18n.t("general.file_type")} require>
              <Radio.Group
                onChange={(e) => setFileType(e.target.value)}
                value={file_type}

              >
                <Radio value="EXCEL"> {I18n.t("general.excel")}</Radio>
                <Radio value="PDF">{I18n.t("general.export_pdf")}</Radio>
              </Radio.Group>
            </SideBySideFormItem>
          </Col>
          <Col>
            <SideBySideFormItem
              labelCol={{...labelCol}}
              wrapperCol={{...wrapperCol}}
              label={"Type"} require>
              <Radio.Group
                onChange={(e) => setViewType(e.target.value)}
                value={viewType}
              >

                {stopTypes.map((rec) => (
                  <Radio key={rec.key} value={rec.key}>{rec.label}</Radio>
                ))}
              </Radio.Group>
            </SideBySideFormItem>
          </Col>
          <Col>
          <SideBySideFormItem
            labelCol={{...labelCol}}
            wrapperCol={{...wrapperCol}}
            label={I18n.t("account.billing.filter.filter_results_with")}>
              <Radio.Group
                onChange={(e) => {
                  setInvoiceAmountFilter(e.target.value);
                }}
                value={invoiceAmountFilter}
              >
                <Radio value={"WITH_AMOUNT"}>
                  {I18n.t("account.billing.filter.with_amount_label")}
                </Radio>
                <Radio value={"WITHOUT_AMOUNT"}>
                  {I18n.t("account.billing.filter.without_amount_label")}
                </Radio>
                <Radio value={"BOTH"}>{I18n.t("general.both")}</Radio>
              </Radio.Group>
            </SideBySideFormItem>
          </Col>
          <Col span={24}>
            <SideBySideFormItem
              labelCol={{ ...labelCol }}
              wrapperCol={{ ...wrapperCol }}
              label={I18n.t("general.emails")}
            >
              <Select
                value={exportEmail}
                onChange={(value) =>
                  setExportEmail(value)
                }
                mode="tags"
                maxTagCount={1}
                tokenSeparators={[","]}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Enter or select Emails"
              >
                {props.userContext?.currentUser?.email ? (
                  <Option key={props.userContext.currentUser.email}>
                    {props.userContext.currentUser.email}
                  </Option>
                ) : (
                  ""
                )}
              </Select>
            </SideBySideFormItem>
          </Col>
        </Row>
        <Row className="marginTop10">
          <Col className="alignCenter" xs={24}>
            <Button
              type="danger"
              icon="close"
              onClick={() => {
                setShowExportModal(false);
                setDefaultValues();
              }}
            >
              {I18n.t("general.close")}
            </Button>{" "}
            &nbsp;
            <Button
              type="primary"
              icon="export"
              onClick={handleAgingExport}
              loading={isLoading}
            >
              {isLoading ? 'Exporting' : I18n.t("general.export")}
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  const _renderExportModal = () => {
    return (
      <BaseModal
        title={I18n.t("general.export")}
        onCancel={() => {
          setShowExportModal(false);
          setDefaultValues();
        }}
        width="540px"
      >
        {_renderAgingReportForm()}
      </BaseModal>
    //     <div>
    //     <Col className="headerOptionDiv">
    //       <Button className="cursorPointer" type="primary" loading={isLoading} onClick={() => { setShowExportModal(true) }} shape="round" >
    //         {isLoading ? "Exporting..." : <span>Export  <Icon type="file-pdf" theme="filled" /></span>}
    //       </Button>
    //     </Col>
    //     {showExportModal && _renderExportModal()}
    //   </div>
    );
  };

  return (
    <div>
      <Col className="headerOptionDiv">
        <Button
          className="cursorPointer"
          type="primary"
          size="small"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setInitialValues();
            setShowExportModal(true);
          }}
          shape="round"
        >
          {isLoading ? (
            "Exporting"
          ) : (
            <span>
              Export <Icon type="file-pdf" theme="filled" />
            </span>
          )}
        </Button>
      </Col>

      {showExportModal && _renderExportModal()}
    </div>
  );
};

export default ExportReport;
