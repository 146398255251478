import React, { Component, Fragment } from "react";
import {
  Spin,
  Row,
  Col,
  Button,
  Popconfirm,
} from "../../../common/UIComponents";
import ReferenceTypesList from "./ReferenceTypesList";
import ReferenceTypesForm from "./ReferenceTypesForm";
import { alertMessage } from "../../../common/Common";
import { ReferenceTypeApi } from "../../../api/ReferenceTypes";
import userStore from "../../../stores/UserStore";
import { isEmpty } from "lodash";
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";

class ReferenceTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      account: this.props.account || {},
      reference_type: "",
      reference_types: [],
      activeReferenceData: {},
      reference: "",
      filter: {
        status: "",
        vin: "",
      },
      refMode: this.props.refMode || "org",
      usingOrgLevel: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  onHandleInputChanges = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  addReference = () => {
    const account = this.state?.account ? this.state.account : {};
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      order_reference_type: this.state.reference,
    };
    if (!this.state.usingOrgLevel) {
      data.account_id = account.id;
    }
    const errors = []; //doValidate(requiredFields, data);
    if (errors.length === 0) {
      this.setState({ inProgress: true });
      ReferenceTypeApi.create(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({ inProgress: false, reference: "" });
          this.getReferences();
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  updateReference = (id) => {
    const account = this.state?.account ? this.state.account : {};
    const { activeReferenceData } = this.state;
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      order_reference_type: activeReferenceData.order_reference_type,
    };
    if (!this.state.usingOrgLevel) {
      data.account_id = account.id;
    }
    if (data.order_reference_type === "") {
      alertMessage(
        I18n.t("messages.is_required", {
          field: I18n.t("errors.reference_is_required"),
        }),
        "error",
        10
      );
      return;
    }
    const errors = [];
    if (errors.length === 0) {
      this.setState({ inProgress: true });
      ReferenceTypeApi.update(activeReferenceData.id, data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({
            inProgress: false,
            reference: "",
            activeReferenceData: {},
          });
          this.getReferences();
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  componentDidMount() {
    this.getReferences();
  }
  handleCancel = () => {
    this.setState({
      reference: "",
    });
  };

  getReferences = (checkForRefs = true) => {
    this.setState({ inProgress: true });
    const organization_id = userStore.getStateValue("selectedOrg");
    const account = this.state?.account ? this.state.account : {};
    ReferenceTypeApi.fetch(organization_id, account.id).then((result) => {
      if (result.success) {
        const reference_types = result.reference_types || [];
        const newState = {
          reference_types,
          reference: "",
          inProgress: false,
        };
        if (checkForRefs) {
          newState.usingOrgLevel =
            reference_types.filter(
              (rec) => !isEmpty(rec.account_id) && rec.account_id === account.id
            ).length === 0;
        }
        this.setState({ ...newState });
      } else {
        this.setState({
          inProgress: false,
          reference_types: [],
        });
        // alertMessage(result.errors[0], 'error', 5);
      }
    });
  };

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    ReferenceTypeApi.delete(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.setState({ inProgress: false });
        this.getReferences();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleApplyButton = () => {
    if (this.state.usingOrgLevel) {
      this.setState({
        usingOrgLevel: false,
        reference_types: [],
      });
    } else {
      this.setState({ inProgress: true });
      const account = this.state?.account ? this.state.account : {};
      if (account.id) {
        ReferenceTypeApi.delete_all(account.id).then((result) => {
          if (result.success) {
            alertMessage(I18n.t("messages.deleted"));
            this.setState(
              {
                inProgress: false,
                usingOrgLevel: false,
                reference_types: [],
              },
              () => {
                this.getReferences();
              }
            );
          } else {
            renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
        });
      }
    }
  };

  handleEdit = (id) => {
    const activeData = this.state.reference_types.find((record) => {
      return id == record.id;
    });
    if (!isEmpty(activeData)) {
      this.setState({
        activeReferenceData: activeData,
      });
    }
  };
  closeEdit = () => {
    this.setState({
      activeReferenceData: {},
    });
  };
  editHandleChange = (value) => {
    this.setState({
      activeReferenceData: {
        ...this.state.activeReferenceData,
        order_reference_type: value,
      },
    });
  };

  render() {
    const isShowSave =
      this.state.refMode === "org" ||
      (this.state.refMode === "account" && this.state.usingOrgLevel === false);
    return (
      <div className="content">
        <Spin spinning={this.state.inProgress} delay={1000}>
          {this.state.refMode === "account" && (
            <Row className="configLableRow">
              <Col md={24} className="fontSize14 alignCenter">
                <span style={{ fontWeight: 500 }}>
                  {this.state.usingOrgLevel === true ? (
                    <Fragment>
                      {I18n.t("references.organization_overwrite")}
                      <Popconfirm
                        placement="topRight"
                        title={I18n.t("general.are_you_sure")}
                        onConfirm={() => this.handleApplyButton()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" size="small" className="fontSize14">
                          <u>Click here</u>
                        </Button>
                      </Popconfirm>
                      {I18n.t("references.organization_overwrite2")}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to overwrite the existing values"
                        onConfirm={() => this.handleApplyButton()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" size="small" className="fontSize14">
                          <u>Click here</u>
                        </Button>
                      </Popconfirm>
                      {I18n.t("references.account_overWrite")}
                    </Fragment>
                  )}
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={{ offset: 6, span: 12 }}>
              {isShowSave && (
                <ReferenceTypesForm
                  onHandleInputChanges={this.onHandleInputChanges}
                  addReference={this.addReference}
                  reference={this.state.reference}
                  handleCancel={this.handleCancel}
                />
              )}
              <div className="marginTop10">
                <ReferenceTypesList
                  size="small"
                  scroll = {{y:"calc(100vh - 375px)"}}
                  rowKey={(record) => record.id}
                  editClick={this.handleEdit}
                  handleUpdate={this.updateReference}
                  activeReferenceData={this.state.activeReferenceData}
                  data={this.state.reference_types}
                  editHandleChange={this.editHandleChange}
                  closeClick={this.closeEdit}
                  deleteClick={this.handleDeleteClick}
                  pagination={{ position: "none" }}
                  showActions={isShowSave}
                />
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default ReferenceTypes;
