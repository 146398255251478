import React, { Component, Fragment, useContext, } from "react";
import _ from "lodash";
import { Col, Row, Spin,Select, message } from "../../../common/UIComponents";
import {
  alertMessage, checkNegitive, isEmpty, navigateToAccount,
} from "../../../common/Common";
import AppConfig from "../../../config/AppConfig";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import OpenBalanceList from "./OpenBalanceList";
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../../context/WarehouseContext";
import { OpenBalApi } from "../../../api/OpenBalApi";
import { Button, Dropdown, Icon, Tooltip } from "antd"
import moment from "moment";
import OpenBalanceFilter from "./OpenBalanceFilter";
import { fetchLocations } from "../../../api/LocationsApi";
import { fetchShortFormAccounts } from "../../../api/Account";
import OpenBalanceSummaryList from "./OpenBalanceSummaryList";
import { UserContext } from "../../../context/UserContext";
import { base64ToExcel, convertToTags, limitString, renderAlertMessage } from "../../../helpers/common";
import I18n from "../../../common/I18n";
import { ScreenKeys } from "../../../containers/constants";
import { OrgContext } from "../../../context/OrgContext";
import userStore from "../../../stores/UserStore";
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import { formatWeight } from "../../../helpers/orders";

const defaultFilter = {
  fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
  toDate: moment(),
  accountCodes: null,
  warehouse_id: [],
  amountType : "WITH_AMOUNT",
};

class OpenBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false,
      balReports: [],
      pagination: {},
      inProgress: false,
      filterWindow: false,
      warehouses: [],
      accountsLoading: false,
      accounts: [],
      appliedFiltersTags: [],
      categoryType:'SUMMARY',
      recordsPerPage: props.recordsPerPage,
      warehouseData : this.props.warehouseData,
      summarySubTotal: "",
      summaryBalanceTotal: "",
      detailJobTotal: "",
      detailBalanceTotal: "",
      filterPlaceHolder: {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
        warehouse_id: [],
        amountType: "WITH_AMOUNT",
        accountCodes: null,
        // sortBy: "invalid_date",
        // sortOrder: "descend"
      },
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  getAmountTypeLabel = (amountType) => {
    switch (amountType) {
      case "WITH_AMOUNT":
        return "With Amount";
      case "WITHOUT_AMOUNT":
        return "Without Amount";
      case "BOTH":
        return "With & Without Amount";
      case "SHORT_PAY":
        return "Short Pay";
      default:
        return "";
    }
  };


  componentDidMount() {
    this.getWarehouses()
    this.updateWhFilter();
    this.getAccounts();
    this.computeTags(this.state.filterPlaceHolder);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseData.selectedWarehouses,
        this.props.warehouseData.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseData.warehouses,
        this.props.warehouseData.warehouses
      ) &&
        !this.props.warehouseData.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      this.updateWhFilter();
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }
  updateWhFilter = () => {
    const { warehouseData } = this.props;
    const filterVals = this.state.filterPlaceHolder;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
    ? warehouseData.selectedWarehouses
    : warehouseData?.selectedWarehouses
    ? [warehouseData.selectedWarehouses]
    : null;
    this.handleFilterPlaceHolderChange({
      ...filterVals,
      warehouse_id: currentWhId,
    }, () => {
      this.getBalanceReports();
    });
  };

  clearFilter = () => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          fromDate: moment().subtract(
            AppConfig.invoices_filter_duration,
            "day"
          ),
          toDate: moment(),
          warehouse_id: currentWhId,
          accountCodes: [],
          amountType : "WITH_AMOUNT"
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };


  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData?.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "amountType":
        filterVariable.amountType = defaultFilter.amountType;
        break;  
      case "warehouse_id":
        filterVariable.warehouse_id = currentWhId;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      this.handleSearch();
    });
  };


  computeTags = (filterPlaceHolder) => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;

    const keys = ["fromDate", "toDate", "accountCodes", "warehouse_id","amountType"];

    const filteredData = {
      amountType: {
        label: "Amount Type",
        value: this.getAmountTypeLabel(filterPlaceHolder.amountType),
        onClose: () => {
          this.handleOnFilterRemoval("amountType");
        },
        closeable: this.previousAmountType !== filterPlaceHolder.amountType,
      },
      fromDate: {
        label: "Open Balance Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(AppConfig.invoices_filter_duration, "day")
              .format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouse_id: {
        label: "Warehouse",
        value: limitString(
          warehouseData.warehouses
            .map((wh) => {
              if (filterPlaceHolder?.warehouse_id?.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouse_id");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouse_id?.length === 1 &&
          filterPlaceHolder?.warehouse_id[0] === currentWhId[0]
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder?.accountCodes?.includes(account.id)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },

    };
    this.previousAmountType = "WITH_AMOUNT";
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  onReset = () => {
    this.setState(
      {
        filterWindow: false,
      })
  }

  handleSearch = (filter) => {
    const filterObj = Object.assign({}, this.state.filterPlaceHolder, {...filter});
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: filterObj,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getBalanceReports();
      }
    );
  };
  handleFilterPlaceHolderChange = (filter, cb, element, value) => {
    this.setState(
      {
        filterPlaceHolder: Object.assign({}, filter),
      },
      () => {
        cb && cb();
      }
    );
  };

  getAccounts = () => {
    const { warehouseData } = this.props;;
    const whIds = warehouseData.warehouses.map(e =>e.id)
    this.setState({
      accountsLoading: true,
    });
    fetchShortFormAccounts('', whIds , true).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  getBalanceReports = () => {
    this.setState({
      inProgress: true,
    });
    const { warehouseData } = this.props;;
    const organization_id = userStore.getStateValue('selectedOrg')
    const { filterPlaceHolder, accounts } = this.state;
    const { selectedWarehouses } = warehouseData;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const whId = selectedwh;
    const allWhIds = this.state.warehouses.map((e) => e.id)
    const page = this.tableOptions.pagination.current;
    const categoryType  = this.state.categoryType;
    const perPage = this.state.recordsPerPage;
    const accountCodes = filterPlaceHolder?.accountCodes || [];
    // accounts.map(acc => filterPlaceHolder.accountCodes.includes(acc.code)).map(acc => acc.id) : [];
    OpenBalApi.fetchOpenBalReports(page, perPage, whId, organization_id, this.state.filterPlaceHolder, allWhIds, categoryType, accountCodes).then((result) => {
      if (result.success) {
        const balReports = result.data.open_invoices || [];
        const subTotal = this.state.categoryType === "SUMMARY" ? balReports.reduce((acc, report) => acc + (report.total || 0), 0) : 0;
        const balTotal = this.state.categoryType === "SUMMARY" ? balReports.reduce((acc, report) => acc + (report.balance || 0), 0) : 0;
        const detailBalTotal = this.state.categoryType === "DETAILS" ? _.sumBy(balReports, (invoice) => parseFloat(invoice.balance)) : 0;
        const detailSubTotal = this.state.categoryType === "DETAILS" ? _.sumBy(balReports, (invoice) => parseFloat(invoice.job_total)) : 0;
        this.setState({
          balReports: balReports,
          pagination: result.data.pagination.pagination || {},
          inProgress: false,
          summarySubTotal: checkNegitive(subTotal),
          summaryBalanceTotal: checkNegitive(balTotal),
          detailBalanceTotal: checkNegitive(detailBalTotal),
          detailJobTotal: checkNegitive(detailSubTotal)
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          inProgress: false,
          balReports: [],
          summarySubTotal: 0,
          summaryBalanceTotal: 0,
          detailBalanceTotal: 0,
          detailJobTotal: 0

        });
      }
    });
  }
  handleExport = () => {

    const currentOrg = this.props.orgData.currentOrg;
    const { filterPlaceHolder,warehouseData } = this.state;

    const organization_id = currentOrg.id
    const { selectedWarehouses } = warehouseData;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const whId = selectedwh;
    const allWhIds = this.state.warehouses.map((e) => e.id)
    const page = this.tableOptions.pagination.current;
    const perPage = this.state.recordsPerPage;
    this.setState({ btnLoading: true })
    const isExport = true;
    const categoryType  = this.state.categoryType;
    const accountCodes = filterPlaceHolder?.accountCodes || [];
    if(isExport){
      message.loading({
        key: "export",
        content: I18n.t("messages.exporting"),
      });
    }
    OpenBalApi.fetchOpenBalReports(page, perPage, whId, organization_id, this.state.filterPlaceHolder, allWhIds,categoryType, accountCodes, isExport).then((result) => {
      if(result.success) {
        const responseMessage = result.data?.message || "";
        if (isEmpty(responseMessage) && result.file) {
          base64ToExcel(
            result.file,
            `${currentOrg.code}_${moment().format("MM-DD-YYYY_hh-mm-ss")}_open_blance_report`
          );
          this.setState({ btnLoading: false })
          message.success({
            key: "export",
            content: I18n.t("messages.exporting_success"),
            duration: 5
          });

        }
        else {
          message.error({
            key: "export",
            content: responseMessage,
            duration: 10
          });
          this.setState({ btnLoading: false })
        }
      } else {
        message.error({
          key: "export",
          content: result.errors,
          duration: 10
        });
        this.setState({ btnLoading: false })
      }
    })
  }

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getBalanceReports();
    // } else if (sorter && !isEmpty(sorter.field)) {
    //   this.setState(
    //     {
    //       filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
    //         sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "invalid_date",
    //         sortOrder: !isEmpty(sorter.order) ? sorter.order : "descend",
    //       }),
    //     },
    //     () => {
    //       this.getBalanceReports();
    //     }
    //   );
    }
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getBalanceReports()
        updateRecordsPerPage(ScreenKeys.OPEN_BALANCE, value);
      }
    );
  };

  getWarehouses = () => {
    this.setState({ inProgress: true });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  _renderSearchBar = () => (
    <Dropdown
      disabled={this.state.btnLoading}
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 500,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <OpenBalanceFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            onCancel={this.onReset}
            warehouseFilter={this.props.warehouseData}
            //accounts={this.state.accounts || []}
            orgData={this.props.orgData}
            categoryType={this.state.categoryType}
          />
        </div>
      }
    >
   
     {/* <Icon style={{ color: "black", backgroundColor: "white", borderRadius: "60%", padding: "5px" }} type="filter" /> */}
      <Button
        className="cursorPointer"
        shape="round"
        // disabled={this.state.filterButtonDisable}
      >
        Filter
        <Icon type="caret-down" />
      </Button>
    </Dropdown>

  )

  // _renderHeader = () => (
  //   <Row style={{ marginLeft: "20%", padding: "15"}}>
  //     <Col>
  //       {this._renderSearchBar()}
  //     </Col>
  //   </Row>
  // );

  
  renderScreen() { 
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.recordsPerPage, // removed AppConfig.ordersPerPage to dynamically adjust record length 
    };
    switch (this.state.categoryType) {
      case "SUMMARY":
        return <Fragment>
          <Row style={{ marginTop: 10 }}>
            <Col xs={24} md={18} offset={3}>
            <Spin spinning={this.state.inProgress} delay={1000}>
              <OpenBalanceSummaryList
                className="tableFooterShowingTotal"
                scroll={{ y: "calc(100vh - 340px)", x: "max-content" }}
                navigateToAccount={(account_id) =>
                  navigateToAccount(this.state.accounts, account_id, this.props.history)
                }
                data={this.state.balReports ? this.state.balReports : []}
                pagination={pagination}
                tableChange={(pagination, filters, sorter) =>
                  this.onTableChange(pagination, filters, sorter)
                }
                footer={() => (
                  this.state?.balReports.length > 0 && (
                    <Fragment>
                    <Row type="flex" className="fontSize12 textBold">
                      <Col
                        style={{ width: "40%" }}
                        className="alignRight paddingRight10"
                      >
                        Total
                      </Col>
                      <Col style={{ width: "22%" }} className="alignRight">
                        <div>
                          {this.state.summarySubTotal}
                        </div>
                      </Col>
                      <Col style={{ width: "21%" }} className="alignRight">
                        <div>
                          {this.state.summaryBalanceTotal}
                          </div>
                      </Col>
                    </Row>
                    </Fragment>
                  )
                )}
              />
             </Spin> 
            </Col>
          </Row>
          </Fragment>;
      case "DETAILS":
        return <Fragment>
          <Row style={{ marginTop:30}}>
            <Col>
            <Spin spinning={this.state.inProgress} delay={1000}>
              <OpenBalanceList
                scroll={{ y: "calc(100vh - 360px)", x: "max-content" }}
                navigateToAccount={(account_id) =>
                  navigateToAccount(this.state.accounts, account_id, this.props.history)
                }
                data={this.state.balReports ? this.state.balReports : []}
                pagination={pagination}
                tableChange={(pagination, filters, sorter, currentTable) =>
                  this.onTableChange(pagination, filters, sorter, currentTable)
                }
                footer={() => (
                  this.state?.balReports.length > 0 && (
                    <Fragment>
                    <Row type="flex" className="fontSize12 textBold">
                      <Col
                        style={{ width: "70%" }}
                        className="alignRight paddingRight10"
                      >
                        
                      </Col>
                      <Col style={{ width: "15%" }} className="alignRight">
                        <div>
                          Job Total : {this.state.detailJobTotal}
                        </div>
                      </Col>
                      <Col style={{ width: "15%" }} className="alignRight">
                        <div>
                          Balance Total : {this.state.detailBalanceTotal}
                          </div>
                      </Col>
                    </Row>
                    </Fragment>
                  )
                )}
              />
             </Spin> 
            </Col>
          </Row>
        </Fragment>;
      default:
        return <Fragment />;
    }

  }

  _renderListOperation = () =>{
    const stopTypes = [
      { key: "SUMMARY", label: "Summary" },
      { key: "DETAILS", label: "Details" },
    ];
    return (
      <Row type="flex" align="middle" style={{ marginTop: 10 }} gutter={16}>
        <Col md={17} style={{ textAlign: "right" }}>
          <span className="text">{I18n.t("general.view_by")}:</span>
          &nbsp;&nbsp;
          <Select
            value={this.state.categoryType}
            showSearch
            onChange={(newValue) => {
              const newState = { categoryType: newValue, balReports: [] };
              if (
                newValue === "SUMMARY" &&
                this.state.filterPlaceHolder.amountType === "SHORT_PAY"
              ) {
                newState.filterPlaceHolder = {
                  ...this.state.filterPlaceHolder,
                  amountType: "WITH_AMOUNT",
                };
              }
              this.setState(newState, () => {
                this.tableOptions.pagination.current = 1;
                this.getBalanceReports();
              });
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: 200 }}
            size="small"
          >
            {stopTypes.map((rec) => (
              <Select.Option key={rec.key} value={rec.key}>
                {rec.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col md={4} style={{ textAlign: "right" }}>
          <RecordsPerPage
            onChange={this.onRecordChange}
            value={this.state.recordsPerPage}
            defaultValue={AppConfig.ordersPerPage}
          />
        </Col>
        <Col md={3} className="alignLeft">
          <Button
            className="cursorPointer"
            type="primary"
            size="small"
            disabled={this.state.btnLoading}
            loading={this.state.btnLoading}
            shape="round"
            onClick={this.handleExport}
          >
            {this.state.btnLoading ? (
              "Exporting..."
            ) : (
              <span>
                Export EXCEL <Icon type="file-excel" theme="filled" />
              </span>
            )}
          </Button>
        </Col>
      </Row>
    );
  }

  _renderAppliddFilter = () => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={24} style={{ marginTop: 15 }}>
          <AppliedFiltersTags
            tags={this.state.appliedFiltersTags}
            clearFilter={() => this.clearFilter()}
          />
        </Col>
      </Row>
    );
  };

  _renderHeaderInfo = () => {
    return (
      <Row className="page-header">
        <Col xs={12}>{I18n.t("aging.bal")}</Col>
        <Col xs={10}></Col>
        <Col xs={2} className="alignRight headerOptionDiv">
          {this._renderSearchBar()}
        </Col>
      </Row>
    );

    //<Row className="page-header" type="flex" justify="space-between">
          //   <Col>{I18n.t("aging.bal")}</Col>
          //   <Col>
          //     <this._renderSearchBar />
          //   </Col>
          // </Row>
  };

  render() {
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    const stopTypes = [
      { key: "SUMMARY", label: "Summary" },
      { key: "DETAILS", label: "Details" },
    ];
    return (
      <div className="content-outer">
        <div className="content">
          {this._renderHeaderInfo()}
          {this._renderListOperation()}
          {this._renderAppliddFilter()}
          {this.renderScreen()}
        </div>
      </div>
    );
  }
}

export const OpenBalanceComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.OPEN_BALANCE;
  const orgData = useContext(OrgContext);
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return <OpenBalance warehouseData={warehouseData} userContext={userContext} {...props} recordsPerPage={recordsPerPage} orgData={orgData} />;
});

export default OpenBalanceComponent;