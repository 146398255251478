import React, { Component } from "react";
import _ from "lodash";
import { Tabs } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { checkServiceExistance } from "../../helpers/common";
import ReferenceTypes from "./reference_types/ReferenceTypes";
import Instructions from "../instructions/Instructions";
import DimConfigurations from "./DimConfigurations";
import Disclaimer from "./Disclaimer";
import OrderListView from "../configurations/DisplaySettings/OrderListView";
import NotificationMsgTemplates from "./NotificationMsgTemplates";
import RejectionReason from "./RejectionReason";

const { TabPane } = Tabs;
class OrderSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account || {},
      accountId: this.props.accountId || "",
      setActiveKey: "reference_types",
    };
  }

  goToTab = (key) => {
    this.setState({ setActiveKey: key });
  };

  renderReferenceTypes = (refMode = "account") => {
    return refMode === "account" ? (
      <ReferenceTypes account={this.state.account} refMode={refMode} />
    ) : (
      <ReferenceTypes refMode={refMode} />
    );
  };
  renderNotificationTemplates = (refMode = "account") => {
    return refMode === "account" ? (
      // <NotificationTemplates account={this.state.account} refMode={refMode} />
      <NotificationMsgTemplates
        account={this.state.account}
        refMode={refMode}
      />
    ) : (
      // <NotificationTemplates refMode={refMode} />
      <NotificationMsgTemplates refMode={refMode} />
    );
  };

  render() {
    const { isOrgLevelSetting } = this.props;
    return (
      <div className="child-card-container">
        <Tabs
          onChange={this.goToTab}
          activeKey={this.state.setActiveKey}
          size="small"
          type="card"
        >
          <TabPane tab={I18n.t("references.types")} key="reference_types">
            {isOrgLevelSetting
              ? this.renderReferenceTypes("org")
              : this.renderReferenceTypes("account")}
          </TabPane>

          {/* {checkServiceExistance(["AINSI", "OINSI", "INSI"], "ANY") && (
            <TabPane tab={I18n.t("menu.instructions")} key="instructions">
              {isOrgLevelSetting ? (
                <Instructions isOrgLevelInstruction />
              ) : (
                <Instructions account={this.state.account} />
              )}
            </TabPane> 
          )} */}
          {checkServiceExistance("DIMI") && !isOrgLevelSetting && (
            <TabPane tab={I18n.t("menu.dim_config")} key="dim_config" >
              <DimConfigurations account={this.state.account} />
            </TabPane>
          )}
          {isOrgLevelSetting && (
          <TabPane
            tab={I18n.t("general.rejection_reasons")}
            key="rejection_reason"
          >
            <RejectionReason/>
          </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default OrderSettings;
