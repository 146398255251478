/* eslint-disable react/sort-comp */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Col,
  Input,
  FormItem,
  Row,
  DatePicker,
  Select,
  Radio,
  Button,
} from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { getRecoveryStatusIcon } from "../../helpers/recovery";
import ZonesList from "../../containers/ZonesList";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import { removeSpecialCharacters } from "../../helpers/common";
import { isEmpty } from "../../common/Common";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { RadioGroup } = Radio.Group;

const TransactionFilter = (props) => {
  const {
    filterPlaceHolder,
    setFilterPlaceHolder,
    onSearch,
    elementSize = "small",
    showDateFilter = false,
    defaultFilter,
  } = props;
  const [filter, setFilter] = useState(_.cloneDeep(defaultFilter));

  // useEffect(() => {
  //   setFilterPlaceHolder({...filter});
  // }, [filter])

  const updateFilter = (element, value) => {
    setFilter({ ...filter, [element]: value });
  };

  const resetFilter = () => {
    setFilter({ ...defaultFilter });
    setFilterPlaceHolder({ ...defaultFilter });
  };

  const updateParentFilter = (filter) => {
    setFilter({ ...filter });
    setFilterPlaceHolder({ ...filter });
  };

  const { search_by_type, search_value } = filter;
  return (
    <div className="padding10 recoveryFilter">
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Col>
          <FormItem label={"Search By"}>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
              }}
              gutter={5}
            >
              <Col span={9}>
                <Select
                  size="small"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      search_by_type: e,
                      search_value: "",
                    });
                  }}
                  className="recoveryFilter__dateSearchBy"
                  style={{
                    textAlign: "center",
                    lineHeight: "1",
                    width: "100%",
                  }}
                  value={search_by_type}
                >
                  <Select.Option key="INVOICE" value="INVOICE">
                    Invoice
                  </Select.Option>
                  <Select.Option key="ORDER" value="ORDER">
                    Order
                  </Select.Option>
                  <Select.Option key="HAWB" value="HAWB">
                    {I18n.t('general.hawb')}
                  </Select.Option>
                  <Select.Option key="MAWB" value="MAWB">
                    {I18n.t('general.mawb')}
                  </Select.Option>
                </Select>
              </Col>
              <Col span={15}>
                <Search
                  placeholder={I18n.t("general.search")}
                  className="seachWithDropDown recoverySearch search_with_prefix"
                  size={elementSize}
                  value={!isEmpty(search_value) ? search_value : ""}
                  onSearch={(value) => {
                    updateParentFilter({ ...filter, search_value: value });
                  }}
                  onChange={(e) => {
                    const filteredString = removeSpecialCharacters(
                      e.target.value
                    );
                    updateFilter("search_value", filteredString);
                  }}
                  style={{ width: 160, textTransform: "uppercase" }}
                  onClear={(e) => {
                    updateParentFilter({ ...filter, search_value: "" });
                  }}
                  allowClear
                />
              </Col>
            </Row>
          </FormItem>
        </Col>
        <Col>
          <Row
            type="flex"
            justify="center"
            gutter={4}
            style={{ marginTop: 20 }}
          >
            <Col>
              <Button
                onClick={() => {
                  // onSearch(filter)
                  setFilterPlaceHolder({ ...filter });
                }}
                type="primary"
                size={elementSize}
              >
                {I18n.t("general.search")}
              </Button>
            </Col>
            <Col>
              <Button type="danger" onClick={resetFilter} size={elementSize}>
                {I18n.t("general.reset")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

TransactionFilter.propTypes = {};
TransactionFilter.defaultProps = {};
export default withRouter(TransactionFilter);
