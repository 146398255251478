/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import moment from "moment";
import {
  Col,
  Row,
  Spin,
  Select,
  Icon,
  Drawer,
  Tabs,
  DatePicker,
  FormItem,
  Input,
  Statistic,
  Dropdown,
} from "../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  formatDate,
  navigateToAccount,
} from "../common/Common";
import I18n from "../common/I18n";
import BaseModal from "../components/BaseModal";
import InvoicesList from "../components/billing_screen/InvoicesList";
import InvoiceDetailsList from "../components/billing_screen/InvoiceDetailsList";
import {
  fetchInvoicesWithFilter,
  fetchAccountSummary,
  deleteInvoice,
} from "../api/BillingScreenAPI";
import AppConfig from "../config/AppConfig";
import OrderDetails from "../components/Tasks/OrderDetails";
import { fetchOrgAccounts, fetchShortFormAccounts } from "../api/Account";
import userStore from "../stores/UserStore";
import IndividualOrders from "./IndividualOrders";
import {
  checkServiceExistance,
  convertToTags,
  removeSpecialCharacters,
  renderAlertMessage,
  selectedWarehouseNames,
} from "../helpers/common";
import Filter from "../components/billing/Filter";
import InvoiceFilter from "../components/billing_screen/InvoiceFilter";
import AppliedFiltersTags from "./AppliedFiltersTags";
import { fetchLocations } from "../api/LocationsApi";
import { withRouter } from "react-router";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import ExportForm from "./ExportForm";
import SendInvoice from "../components/billing_screen/SendInvoice";
import { SearchConfigContext } from "../context/SearchConfigContext";

const { RangePicker } = DatePicker;
const { Search } = Input;
class BillingScreen extends Component {
  constructor(props) {
    const { screenData } = props.searchConfigContext;
    const INVOICE = _.get(screenData, "INVOICE", {}) || {};
    const { filteredData = {} } = INVOICE;
    super(props);
    this.state = {
      // pagination: {},
      inProgress: false,
      listProgress: false,
      account_invoices: [],
      currentInvoice : {},
      showSurvey: false,
      accounts: [],
      warehouses: [],
      filterPlaceHolder: {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
        accountCode: "",
        wh_location_id: "",
        viewType: filteredData?.viewType ? filteredData.viewType : "byOrder",
        currentStatus: "PENDING",
        sortBy: "none",
        sortByType: "descend",
        approvalDate: "billing_approval_date",
        frequencyType: ""
      },
      search_order_token: "",
      searchKeyPress: false,
      searchBy: "Invoice",
      activeKey: "account_wise",
      pagination: {},
      innerTablePagination: {},
      innerTableOptions: { pagination: {}, filters: {}, sorter: {} },
      activeAccounts: [],
      filterWindow: false,
      appliedFiltersTags: [],
      searchKeyPress: false,
      isSearching: false,
      updateSearchTags: false,
      displayConfiguration: {},
      enableFractionFormat: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
    this.onInnerTableChange = this.onInnerTableChange.bind(this);
    this.onTagClose = this.onTagClose.bind(this);
    this.getInvoices = _.debounce(() => {
      this.getInvoicesList();
    }, 500);
  }

  componentDidMount() {
    const fromOtherScreen = this.props?.history?.location?.calledFrom;
    const { screenData } = this.props.searchConfigContext;
    this.getOrgAccounts();
    // this.getWarehouses();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const order_number = urlParams.get("order_number");
    const filter = !isEmpty(fromOtherScreen) ? screenData.INVOICE.filteredData : this.state.filterPlaceHolder
    if (order_number) {
      // this.handleOnChange("filterWindow", true)
      // this.handleFilterChange('search_order_token', order_number , false)
      this.setState(
        {
          displayConfiguration: this.props.displayConfiguration,
          searchBy: "Order",
          search_order_token: order_number,
          filterPlaceHolder: {
            ...this.state.filterPlaceHolder,
            fromDate: null,
            toDate: null,
            accountCode: "",
            // wh_location_id:"",
            viewType: "byOrder",
            currentStatus: "PENDING",
            frequencyType: "",
          },
        },
        () => {
          this.computeTags(this.state.filterPlaceHolder);
        }
      );
    } else {
      this.computeTags(filter);
      this.updateStateFromContext();
    }
  }

  updateStateFromContext = () => {
    const { screenData } = this.props.searchConfigContext;
    const { INVOICE } = screenData;
    this.setState(
      {
        filterPlaceHolder: INVOICE.filteredData,
        search_order_token: INVOICE.searchedItem,
        searchBy: INVOICE.searchBy,
        isSearching: INVOICE.isSearching,
        activeAccounts: INVOICE.activeAccounts
      },
      () => {
        const currentPage =
          INVOICE.tableOptions.pagination &&
          INVOICE.tableOptions.pagination.current
            ? INVOICE.tableOptions.pagination.current
            : 1;
        this.tableOptions.pagination.current = currentPage;
        if (this.state.filterPlaceHolder.viewType === "byAccount") {
          this.getInvoicesList();
        }
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filterPlaceHolder.viewType === "byOrder" && (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder))) {
      this.tableOptions.pagination.current = 1;
    }
    if (
      !_.isEqual(
        prevState.displayConfiguration,
        this.props.displayConfiguration
      )
    ) {
      this.setState(
        {
          displayConfiguration: this.props.displayConfiguration,
        },
        () => {
          // Update OrdersList props with updated state values
          this.updateOrdersListProps();
        }
      );
    }
    if (this.state.searchKeyPress && this.state.filterPlaceHolder.viewType !== "byOrder") {
      this.getInvoicesList();
    }
  }

  updateOrdersListProps = () => {
    this.setState({
      ordersListProps: {
        displayConfiguration: this.state.displayConfiguration,
      },
    });
  };

  onTagClose = (tagsObj) => {
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          ...tagsObj,
        },
      },
      () => {
        this.updateContext(this.state.pagination, this.tableOptions);
        this.computeTags(this.state.filterPlaceHolder);
        this.getInvoices();
      }
    );
  };

  getLabel = (approvalDate) => {
    switch (approvalDate) {
      case "billing_approval_date":
        return "Invoice Generated Date";
      case "invoice_approval_date":
        return "Invoice Approval Date";
      default:
        return "Order Completed Date";
    }
  };

  computeTags = (filterPlaceHolder) => {
    const { orgAccounts = [] } = this.props;
    const keys = [
      "viewType",
      "fromDate",
      "toDate",
      "accountCode",
      "wh_location_id",
      "frequencyType" 
    ];
    const filteredData = {
      viewType: {
        label: "View By",
        value: filterPlaceHolder.viewType === "byOrder" ? "Invoice" : "Account",
        onClose: () => {this.onTagClose({ viewType: "byOrder" })},
        closeable: filterPlaceHolder.viewType === "byAccount",
      },
      fromDate: {
        label: this.getLabel(filterPlaceHolder.approvalDate),
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.onTagClose({
            fromDate: moment().subtract(
              AppConfig.invoices_filter_duration,
              "day"
            ),
            toDate: moment(),
          });
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(AppConfig.invoices_filter_duration, "day")
              .format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      toDate: {
        label: this.getLabel(filterPlaceHolder.approvalDate),
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.onTagClose({
            fromDate: moment().subtract(
              AppConfig.invoices_filter_duration,
              "day"
            ),
            toDate: moment(),
          });
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(AppConfig.invoices_filter_duration, "day")
              .format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      accountCode: {
        label: "Account",
        value: orgAccounts.find(
          (account) => account.id === filterPlaceHolder.accountCode
        )
          ? orgAccounts.find(
              (account) => account.id === filterPlaceHolder.accountCode
            ).code
          : "",
        onClose: () => {
          this.onTagClose({ accountCode: "" });
        },
        closeable: true,
      },
      wh_location_id: {
        label: I18n.t("location.location"),
        value: selectedWarehouseNames(this.props.warehouseFilter),
        onClose: () => {
          this.props.warehouseFilter.onWarehouseChange("");
        },
        closeable: false,
      },
      frequencyType: { 
        label: "Invoice Type",
        value: filterPlaceHolder.frequencyType, 
        onClose: () => { this.onTagClose({ frequencyType: "" }) },
        closeable: true,
      },
    };
  
    const tagsInfo = convertToTags(
      { ...filterPlaceHolder, wh_location_id: filteredData.wh_location_id },
      keys,
      filteredData
    );
    console.log("tagsInfo", tagsInfo);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  clearFilter = () => {
    // reset the filterPlaceHolder
    this.setState(
      {
        filterPlaceHolder: {
          fromDate: moment().subtract(
            AppConfig.invoices_filter_duration,
            "day"
          ),
          toDate: moment(),
          accountCode: "",
          wh_location_id: "",
          viewType: "byOrder",
          currentStatus: "PENDING",
          approvalDate: "billing_approval_date",
          frequencyType: "", 
        },
        search_order_token: "",
        isSearching: false,
        appliedFiltersTags: [],
      },
      () => {
        this.updateContext(this.state.pagination, this.tableOptions);
        this.computeTags(this.state.filterPlaceHolder);
        this.getOrgAccounts();
      }
    );
  };

  onInnerTableChange = (pagination, filters, sorter, id) => {
    const currentPage =
      this.state.innerTableOptions.pagination && this.state.innerTableOptions.pagination.current
        ? this.state.innerTableOptions.pagination.current
        : 1;
    const innerTableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getAccountSummary(true, id, innerTableOptions);
    }
    this.updateContext(this.state.pagination,this.tableOptions);
  };

  handleChildFilterChange = (filter) => {
    this.setState({
      filterPlaceHolder: filter,
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getInvoices();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "datetime",
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.getInvoices();
        }
      );
    }
    this.setState({ activeAccounts: [] }, () => {
      this.updateContext();
    });
  };

   getOrgAccounts = () => {
    const { currentOrg = {} } = this.props.organizationSettings;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
        });
      } else {
        this.setState({
          accounts: [],
        });
      }
    });
  };

  getWarehouses = () => {
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations || [],
        });
      }
    });
  };

  getInvoicesList = (cb) => {
    const { screenData } = this.props.searchConfigContext;
    const { INVOICE } = screenData;
    const { filterPlaceHolder, search_order_token } = this.state;
    this.setState({ listProgress: true , searchKeyPress: false });
    const page = this.tableOptions.pagination.current || 1;
    const { perPage } = AppConfig;
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    let data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      page,
      per_page: perPage,
      account_id: filterPlaceHolder.accountCode,
      wh_location_id: filterPlaceHolder.wh_location_id,
      status: filterPlaceHolder.currentStatus,
      sort_by: filterPlaceHolder.sortBy,
      sort_order: I18n.t(`general.sort_${filterPlaceHolder.sortByType}`),
      approval_date: filterPlaceHolder.approvalDate,
      frequency_type: filterPlaceHolder.frequencyType 
    }
    if(search_order_token){
      // const searchBy = this.state.searchBy;
      // if(searchBy === "Invoice"){
        data.search_value = search_order_token
          ? _.trim(search_order_token)
          : "";
      // } else {
      //   data.customer_order_number = search_order_token
      //     ? _.trim(search_order_token).replace(/\s/g, "")
      //     : "";
      // }
    } else {
      data.from_date= formattedFromDate;
      data.to_date= formattedToDate;
    }
      
    // fetchInvoicesWithFilter(data, searchKeyType, searchKeyValue).then(
    fetchInvoicesWithFilter(data)
      .then((result) => {
        if (result.success) {
          const accountInvoices = result.invoices || [];
          if (accountInvoices.length === 0) {
            alertMessage(I18n.t("messages.no_record_found"), "error", 5);
          }
          this.setState(
            {
              account_invoices: accountInvoices.map((invoice) =>
                Object.assign({}, invoice, {
                  pagination: {},
                  inProgress: false,
                  tableOptions: { pagination: {}, filters: {}, sorter: {} },
                })
              ),
              data: result.stats || {},
              pagination:
                accountInvoices.length > 0 && result.pagination
                  ? result.pagination
                  : {},
              statsData: result.status_counts || {
                pending: 0,
                approved: 0,
              },
            },
            () => {
              if (cb) {
                cb();
              }
              this.updateContext(this.state.pagination, this.tableOptions)
              if(INVOICE.activeAccounts?.length > 0){
                this.getAccountSummary(true, INVOICE.activeAccounts[0], INVOICE.innerTableOptions);
              }
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            listProgress: false,
            pagination: {},
            statsData: {
              pending: 0,
              approved: 0,
            },
          });
        }
      })
      .finally(() => {
        this.setState({ listProgress: false, inProgress: false });
      });
  };

  setCurrentExpandedRowPagination = (invoices) =>{
    const currentAccountInv = invoices?.length > 0 ? invoices.find(rec => rec.account_id === this.state.activeAccounts[0]) : {}
    if(!_.isEmpty(currentAccountInv)){
      this.setState({
        innerTablePagination: !_.isEmpty(currentAccountInv.pagination) ? currentAccountInv.pagination : {},
        innerTableOptions: !_.isEmpty(currentAccountInv.tableOptions) ? currentAccountInv.tableOptions : { pagination: {}, filters: {}, sorter: {} }
      }, ()=>{
        this.updateContext(this.state.pagination,this.tableOptions)
      });
    }
  }

  getAccountSummary = (
    isExpanded,
    id,
    tableOptions = { pagination: {}, filters: {}, sorter: {} }
  ) => {
    if (isExpanded) {
      const account_invoices = [...this.state.account_invoices];
      const accObjectIndex = _.findIndex(account_invoices, ["account_id", id]);
      if (accObjectIndex >= 0) {
        account_invoices[accObjectIndex].inProgress = true;
        account_invoices[accObjectIndex].tableOptions = tableOptions;
        const record = account_invoices[accObjectIndex].pagination;
        const page = tableOptions.pagination?.current && !isEmpty(tableOptions.pagination.current) ? tableOptions.pagination.current : 1;
        const perPage = AppConfig.perPage;
        const currentStatus = this.state.filterPlaceHolder.currentStatus;
        this.setState({
          account_invoices,
          activeAccounts: [id]
        });
        let payload = { account_id: id };
        payload.status = currentStatus;
        // attach from and to date
        const formattedFromDate = formatDate(
          this.state.filterPlaceHolder.fromDate
        );
        const formattedToDate = formatDate(this.state.filterPlaceHolder.toDate);
        payload.frequency_type = this.state.filterPlaceHolder.frequencyType;
        payload.approval_date = this.state.filterPlaceHolder.approvalDate;
        payload.wh_location_id = this.state.filterPlaceHolder.wh_location_id;
        payload.search_value = _.trim(this.state.search_order_token);
        if (this.state.filterPlaceHolder.viewType === "byAccount")
        if (!this.state.isSearching) {
          payload.from_date = formattedFromDate;
          payload.to_date = formattedToDate;
        }

        fetchAccountSummary(payload, page, perPage).then((result) => {
          if (result.success) {
            account_invoices[accObjectIndex].invoices = result.invoices || [];
            account_invoices[accObjectIndex].inProgress = false;
            account_invoices[accObjectIndex].pagination =
              result.pagination || {};
            this.setState({
              account_invoices,
            },()=>{
              this.setCurrentExpandedRowPagination(this.state.account_invoices);
            });
          } else {
            account_invoices[accObjectIndex].inProgress = false;
            renderAlertMessage(result.errors)
            this.setState({ account_invoices });
          }
        });
      }
    }else {
      this.setState({
        activeAccounts: this.state.activeAccounts.filter(acc_id => id !== acc_id)
      })
    }
  };

  handleDeleteClick = (ids, account) => {
    this.setState({ inProgress: true });
    const data = {
      invoice_id: ids,
    };
    deleteInvoice(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.deleted"));
          this.getInvoices(() => {
            this.getAccountSummary(true, account);
          });
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  _renderAccountsList = () => (
    <FormItem label={I18n.t("account.account")}>
      <Select
        value={this.state.filterPlaceHolder.accountCode}
        showSearch
        style={{ width: "100%" }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => this.handleFilterChange("accountCode", e)}
      >
        <Select.Option key="all" value="">
          -- Select Account --
        </Select.Option>
        {this.state.accounts.map((account) => (
          <Select.Option key={account.id} value={account.id}>
            {account.name}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  handleOnChange = (element, value) => {
    this.setState(
      {
        [element]: value,
      },
      () => {}
    );
  };

  handleSearch = (incomingKey, bool) => {
    this.setState(
      {
        filterWindow: false,
        search_order_token: "",
        isSearching: false
      },
      () => {
        if(incomingKey === "invoice_completed_date"){
          this.handleCompletedDateChanges(bool);
        }
        this.tableOptions.pagination.current = 1;
        if (this.state.filterPlaceHolder.viewType === "byAccount") {
          this.getInvoices();
        }
        this.updateContext();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  };

  handleFilterPlaceHolderChange = (filter, cb) => {
    this.setState(
      {
        filterPlaceHolder: Object.assign({}, filter),
      },
      () => {
        if (cb) {
          cb();
        }
      }
    );
  };

  handleCompletedDateChanges = (bool) => {
    this.setState({
      enableFractionFormat: bool,
    })
  }

  _renderMainSearchBar = () => {
    const { searchBy } = this.state;
    return (
      <Search
        // placeholder="Search orders Ex: Order No 1"
        placeholder={"Search Invoice No, Order No, HAWB, MAWB, Ref No"}
        // className="seachWithDropDown invoice-search-bar"
        // enterButton="Search"
        size="default"
        value={
          !isEmpty(this.state.search_order_token)
            ? this.state.search_order_token.toUpperCase()
            : ""
        }
        onSearch={(value) => {
          // this.handleFilterChange('search_order_token', value);
          this.setState(
            {
              search_order_token: value,
              searchKeyPress: true,
              isSearching: value.length > 0 ? true : false,
              updateSearchTags: true,
            },
            () => {
              this.computeTags(this.state.filterPlaceHolder);
            }
          );
        }}
        onChange={(e) => {
          // clear url search params
          if (window.location.search.indexOf("order_number") > -1) {
            const { history } = this.props;
            history.replace("/invoices");
          }
          const filteredString = removeSpecialCharacters(e.target.value);
          this.setState({ search_order_token: filteredString });
        }}
        style={{ width: "100%", textTransform: "uppercase" }}
        allowClear
        suffix={
          <Fragment>
            {/* { this.state.filterPlaceHolder.search_order_token !== "" && (
            <Fragment>
              { " " }
              <Icon
                type="close"
                onClick={ () =>
                  this.handleFilterValueChange("search_order_token", "", true)
                }
              />{ " " }
              &nbsp;&nbsp;
            </Fragment>
          ) } */}
            <Dropdown
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.filterWindow}
              trigger={["click"]}
              overlay={
                <div
                  style={{
                    backgroundColor: "white",
                    width: 600,
                    padding: 15,
                    marginTop: 10,
                    marginRight: -12,
                  }}
                  className="boxShadow"
                >
                  <InvoiceFilter
                    filter={this.state.filterPlaceHolder}
                    onChange={this.handleFilterPlaceHolderChange}
                    onSearch={this.handleSearch}
                    onOrderNoSearch={null}
                    warehouseFilter={this.props.warehouseFilter}
                    handleCompletedDateChanges={this.handleCompletedDateChanges}
                  />
                </div>
              }
            >
              <Icon
                type="caret-down"
                onClick={() => this.handleOnChange("filterWindow", true)}
              />
            </Dropdown>
          </Fragment>
        }
        prefix={
          <Row
            type="flex"
            align="middle"
            className="invoiceSearchPrefixes"
            gutter={4}
          >
            <Col>
              <Icon
                type="search"
                onClick={() =>
                  this.setState({
                    searchKeyPress: true,
                    isSearching:
                      _.trim(this.state.search_order_token).length > 0
                        ? true
                        : false,
                  })
                }
              />
            </Col>
            {/* <Col>
              <Select
                size="small"
                onChange={(e) => {
                  // clear search_order_token
                  // this.handleFilterChange('search_order_token', '');
                  this.handleOnChange("searchBy", e);
                  // this.setState({
                  //   search_order_token: "",
                  //   isSearching: false,
                  //   searchBy: e,
                  // });
                  if (!isEmpty(this.state.search_order_token) && this.state.isSearching) {
                    this.setState({
                      searchKeyPress: true,
                      searchBy: e,
                    });
                  }
                }}
                value={this.state.searchBy}
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "1",
                }}
                defaultValue={searchBy === "Invoice" ? "Invoice" : "Order"}
              >
                <Select.Option key="Invoice" value="Invoice">
                  {I18n.t("general.by_invoice").toUpperCase()}
                </Select.Option>
                <Select.Option key="Order" value="Order">
                  {I18n.t("general.by_order").toUpperCase()}
                </Select.Option>
              </Select>
            </Col> */}
          </Row>
        }
      />
    );
  };

  _renderHeader = () => (
    <Row className="page-header">
      <Col xs={16}>{I18n.t("menu.invoices")}</Col>
      <Col xs={8} className="headerOptionDiv alignRight">
        {checkServiceExistance("FBLOS") && this._renderMainSearchBar()}
      </Col>
    </Row>
  );

  _renderSearchOrder = () => (
    <Search
      placeholder={`${I18n.t("general.search")} ${I18n.t("order.order_no")}`}
      // enterButton
      // size="small"
      // value={
      //   !isEmpty(this.state.filterPlaceHolder.search_order_token)
      //     ? this.state.filterPlaceHolder.search_order_token.toUpperCase()
      //     : ""
      // }
      // onChange={(e) => {
      //   this.handleFilterChange("search_order_token", e.target.value, false);
      // }}
      onSearch={(value) => {
        // this.handleFilterChange('search_order_token', value)
        this.setState({ search_order_token: value });
      }}
      onChange={(e) => {
        // clear url search params
        if (window.location.search.indexOf("order_number") > -1) {
          const { history } = this.props;
          history.replace("/invoices");
        }
        // this.setState({ filterPlaceHolder: { ...this.state.filterPlaceHolder, search_order_token: e.target.value } });
        this.setState({ search_order_token: e.target.value });
      }}
      value={this.state.search_order_token}
      style={{ width: "100%", textTransform: "uppercase" }}
      className="textUpperCase"
      allowClear
    />
  );

  _renderSearchBar = () => (
    <div className="padding10">
      <Row gutter={16}>
        <Col md={24}>
          <FormItem label={I18n.t("general.view_by")}>
            <Select
              value={this.state.filterPlaceHolder.viewType}
              showSearch
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => this.handleFilterChange("viewType", e)}
            >
              <Select.Option value="byAccount">
                {`${I18n.t("general.group_by")} ${I18n.t("account.account")}`}
              </Select.Option>
              <Select.Option value="byOrder">
                {I18n.t("invoices.label")}
              </Select.Option>
            </Select>
          </FormItem>
        </Col>
        <Col md={24}>
          <FormItem label={I18n.t("invoices.approval_date")}>
            <RangePicker
              // disabledDate={disabledDate}
              defaultValue={[moment().startOf("month"), moment()]}
              value={[
                this.state.filterPlaceHolder.fromDate,
                this.state.filterPlaceHolder.toDate,
              ]}
              ranges={AppConfig.dateRanges}
              format={AppConfig.dateFormat}
              style={{ width: "100%" }}
              onChange={(dates, dateStrings) =>
                this.handleRangeChange(dates, dateStrings)
              }
            />
          </FormItem>
        </Col>
        <Col md={24}>{this._renderAccountsList()}</Col>
        {/* {this.state.filterPlaceHolder.viewType === "byOrder" && ( */}
        <Col md={24}>
          <FormItem
            label={`${I18n.t("general.search")} ${I18n.t("order.order_no")}`}
          >
            {this._renderSearchOrder()}
          </FormItem>
        </Col>
        {/* )} */}
      </Row>
    </div>
  );

  updateContext = (pagination = this.state.pagination, tableOptions = this.tableOptions) => {
    const { updateFilteredData } = this.props.searchConfigContext;

    const {
      filterPlaceHolder,
      innerTablePagination,
      innerTableOptions,
      search_order_token,
      searchBy,
      isSearching,
      activeAccounts,
    } = this.state;

    const dataToUpdate = {
      paginationData: pagination,
      filteredData: filterPlaceHolder,
      tableOptions,
      searchedItem: search_order_token,
      searchBy,
      isSearching,
      innerTableOptions: innerTableOptions,
      innerTablePaginationData: innerTablePagination,
      activeAccounts,
    };

    updateFilteredData("INVOICE", dataToUpdate);
  };

    setActiveAcc = (data) =>{
      this.setState({ activeAccounts: data });
    }

  handleAccountNavigation = (account_idOrCode, pagination = this.state.pagination, tableOptions = this.tableOptions) =>{
    this.updateContext(pagination, tableOptions);
    navigateToAccount(
      this.state.accounts,
      account_idOrCode,
      this.props.history,
      "invoices"
    );
  }

  _renderInvoices = () => {
    const dataPagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || AppConfig.perPage,
    };
    return (
      <InvoicesList
        rowKey="account_id"
        size="small"
        // from common mention file we use use it directly and pass the 3 parameters
        // navigateToAccount= {(account_code) => navigateToAccount(this.state.accounts, account_code, this.props.history)}
        navigateToAccount = {(account_code) => this.handleAccountNavigation(account_code)}
        expandedRowKeys={[...this.state.activeAccounts]}
        expandedRowRender={(record) => (
          <Fragment>
            <Spin spinning={record.inProgress} delay={1000}>
              {checkServiceExistance("ACSOB") && (
                <InvoiceDetailsList
                  rowKey={"invoice_number"}
                  data={record.invoices || []}
                  scroll = {{ y:200 }}
                  invoice_type={record.invoice_type}
                  pagination={{
                    total: record.pagination.total_count,
                    current: record.pagination.current_page,
                    pageSize: record.pagination.per_page || AppConfig.perPage,
                  }}
                  size="small"
                  account={record.account_id}
                  tableChange={(tablePagination, filters, sorter) =>
                    this.onInnerTableChange(
                      tablePagination,
                      filters,
                      sorter,
                      record.account_id
                    )
                  }
                  refreshList={() => {
                    checkServiceExistance("ACSOB")
                      ? this.getAccountSummary(true, record.account_id, this.state.innerTableOptions)
                      : null;
                  }}
                  deleteClick={this.handleDeleteClick}
                />
              )}
            </Spin>
          </Fragment>
        )}
        onExpand={(expanded, record) =>
          checkServiceExistance("ACSOB")
            ? this.getAccountSummary(expanded, record.account_id)
            : null
        }
        data={this.state.account_invoices}
        editClick={this.handleEditClick}
        onPrintClick={this.handlePrint}
        handleDriverPayList={this.handleDriverPayList}
        editManualInvoice={this.editManualInvoice}
        handleOrderDetailsViewClick={(order_id) =>
          this.handleOrderDetailsViewClick(order_id)
        }
        pagination={dataPagination}
        tableChange={(pagination, filter, sorter, currentTable) =>
          this.onTableChange(pagination, filter, sorter, currentTable)
        }
        scroll={{ y: "calc(100vh - 325px)" }}
      />
    );
  };

  handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filterPlaceHolder, {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filterPlaceHolder, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.handleStateOnChange("filterPlaceHolder", { ...dateFilter }, () => {
      this.getInvoices();
    });
  };

  handleFilterChange = (element, value, refreshData = true) => {
    let filter = {};
    filter = Object.assign({}, this.state.filterPlaceHolder, {
      [element]: value,
    });
    if (element === "viewType") {
      this.tableOptions.pagination.current = 1;
      refreshData = true;
    }
    // if (
    //   element !== "search_order_token" ||
    //   (element === "viewType" && value !== "byOrder")
    // ) {
    //   filter.search_order_token = "";
    // }
    this.handleStateOnChange("filterPlaceHolder", filter, () => {
      if (
        (refreshData &&
          element !== "viewType" &&
          filter.viewType === "byAccount") ||
        (element === "viewType" && value === "byAccount")
      ) {
        this.getInvoices();
      }
    });
  };

  handleStateOnChange = (element, value, cb = null) => {
    this.setState(
      {
        [element]: value,
        activeAccounts: []
      },
      () => {
        if (cb) {
          cb();
        }
        this.updateContext()
      }
    );
  };

  handleExpandClick = (id) => {
    const index = _.findIndex(this.state.account_invoices, [
      "customer_order_id",
      id,
    ]);
    this.setState({
      currentInvoice: {},
      showForm: true,
    });
  };

  showDetailsWindow = () => {
    this.setState({
      showForm: true,
    });
  };

  closeDetailsWindow = () => {
    this.setState(
      {
        currentInvoice:
          this.state.showGroupOrder === true ? this.state.currentInvoice : {},
        showForm: false,
      },
      () => {
        // this.getInvoices();
      }
    );
  };

  renderFormModal = () => (
    <BaseModal
      title={`${I18n.t("general.manage")} ${I18n.t("invoices.billing")} ${
        this.state?.currentInvoice?.order_number ? `#${this.state.currentInvoice.order_number}` : ""
      }`}
      onCancel={() => this.closeFormWindow()}
      width="70%"
      style={{ top: 50 }}
      bodyStyle={{ padding: 20 }}
    >
      <Spin
        spinning={this.state.inProgress || this.state.regenerateProgress}
        delay={1000}
      >
        <Form
          getInvoices={this.getInvoices}
          currentInvoice={this.state.currentInvoice}
          isNew={this.state.isNew}
          onCancel={() => this.closeFormWindow()}
          onChange={(element, value) => this.handleFilterChange(element, value)}
          getInvoiceDetails={(orderId) => this.getBillingDetails(orderId)}
          getManualInvoiceDetails={(invoice_number) =>
            this.getManualBillingDetails(invoice_number)
          }
          accounts={this.state.accounts}
          warehouses={this.state.warehouses}
          drivers={this.state.drivers}
          showHeaders={this.state.showGroupOrder !== true}
          handleOrderDetailsViewClick={(order_id) =>
            this.handleOrderDetailsViewClick(order_id)
          }
          regenerateInvoice={this.regenerateInvoiceWithLos}
        />
      </Spin>
    </BaseModal>
  );

  goToTab = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  updateStatsData = (data) => {
    this.setState({
      statsData: { ...data },
    });
  };

  _renderStats = () => {
    const { currentStatus } = this.state.filterPlaceHolder;
    return (
      <Row className="orderStats">
        {AppConfig.invoiceStatus.map((record) => (
          <Col
            span={12}
            key={record.key}
            onClick={() => this.handleFilterChange("currentStatus", record.key)}
            className="routeLegends"
          >
            <Statistic
              title={record.label}
              className={currentStatus === record.key && "active"}
              value={
                this.state.statsData
                  ? this.state.statsData[record.key.toLowerCase()]
                  : 0
              }
              prefix={<Icon type={record.icon} className={record.className} />}
            />
          </Col>
        ))}
      </Row>
    );
  };

  resetSearchTags = () => {
    this.setState({ updateSearchTags: false });
  };
  render() {
    const {
      filterPlaceHolder,
      searchBy,
      searchKeyPress,
      search_order_token,
      isSearching,
      updateSearchTags,
    } = this.state;
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    const showStats = checkServiceExistance("FBLOS"); // && filterPlaceHolder.viewType === 'byOrder'
    return (
      <div className="content-outer">
        <div className="content">
          <this._renderHeader />
          {checkServiceExistance(["AOIN", "FBLOS"], "ANY") && (
            <div className="invoices">
              {showStats && (
                <Row className="orderStats">
                  {/* <Col xs={18}>
                  {checkServiceExistance('FBLOS') && this._renderSearchBar()}
                </Col> */}
                  <Col md={{ offset: 8, span: 8 }}>
                    <this._renderStats />
                  </Col>
                </Row>
              )}

              <div style={{ marginTop: "10px" }}>
                <AppliedFiltersTags
                  tags={this.state.appliedFiltersTags}
                  clearFilter={() => this.clearFilter()}
                  isSearching={isSearching}
                  // searchBy={searchBy.toLocaleLowerCase()}
                  searchBy={
                    searchBy
                      ? searchBy.charAt(0).toUpperCase() + searchBy.slice(1)
                      : ""
                  }
                  searchText={search_order_token}
                  updateSearchTags={updateSearchTags}
                  resetSearchTags={this.resetSearchTags}
                />
              </div>
              {filterPlaceHolder.viewType === "byOrder" ? (
                <IndividualOrders
                  filter={filterPlaceHolder}
                  updateStatsData={this.updateStatsData}
                  searchBy={searchBy}
                  searchKeyPress={searchKeyPress}
                  searchedOrder={search_order_token}
                  resetSearchedKeyPress={() =>
                    this.setState({ searchKeyPress: false })
                  }
                  accounts={this.state.accounts}
                  warehouseFilter={this.props.warehouseFilter}
                  computeTags={this.computeTags}
                  displayConfiguration={displayConfiguration}
                  ordersListProps={ordersListProps}
                  screen_from="invoice"
                  handleChildFilterChange={this.handleChildFilterChange}
                  enableFractionFormat={this.state.enableFractionFormat}
                  handleCompletedDateChanges={this.handleCompletedDateChanges}
                  handleAccountNavigation = {this.handleAccountNavigation}
                  fromOtherScreen = {this.props?.history?.location?.calledFrom}
                  searchConfigContext = {this.props.searchConfigContext}
                  updateContext = {this.updateContext}
                />
              ) : (
                checkServiceExistance("FBLOS") && (
                  <Spin
                    spinning={this.state.listProgress || this.state.inProgress}
                    delay={1000}
                  >
                    <Col>
                      <Row>
                        <Col className="alignRight" style={{ marginTop: -5, marginBottom: 5, marginRight: 10 }}>
                          <Row type="flex" justify="end" gutter={16}>
                              <Col>
                                <SendInvoice />
                              </Col>
                              <Col className="alignRight" style={{ marginTop: -5 }}>
                              <ExportForm />
                              </Col>
                          </Row>
                        </Col>
                        <Col>
                          <this._renderInvoices />
                        </Col>
                      </Row>
                    </Col>
                  </Spin>
                )
              )}
            </div>
          )}

          {/* <Tabs
              activeKey={this.state.activeKey}
              onChange={this.goToTab}
              type="card"
            >
              <TabPane
                tab={`${I18n.t('general.group_by')} ${I18n.t(
                  'account.account',
                )}`}
                key="account_wise"
              >
                <Row>
                  <Col className="accessorials marginTop10">
                    <this._renderInvoices />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={`${I18n.t('order.label')} Wise`} key="order_wise">
                <IndividualOrders filter={this.state.filterPlaceHolder} />
              </TabPane>
            </Tabs> */}
        </div>
        {this.state.showForm && this.renderFormModal()}
        {this.state.detailsVisible && (
          <Drawer
            title={`${I18n.t("order.details")} ${
              this.state.currentOrder.customer_order_number
                ? `(#${this.state.currentOrder.customer_order_number})`
                : ""
            }`}
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.detailsVisible}
            width="85%"
          >
            <OrderDetails
              currentOrder={this.state.currentOrder}
              order_id={this.state.selectedOrderId}
              surveyDetails={(id) => this.handleSurveyDetails(id)}
            />
          </Drawer>
        )}
      </div>
    );
  }
}
export const BillingComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const searchConfigContextData = useContext(SearchConfigContext);  
  const { orgSettings : organizationSettings, accounts: orgAccounts  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);
  return (
    <BillingScreen
      searchConfigContext={searchConfigContextData}
      warehouseFilter={warehouseFilter}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      organizationSettings={organizationSettings}
      orgAccounts={orgAccounts}
      {...props}
    />
  );
});

export default BillingComponent;
