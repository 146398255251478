import React, { useContext, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import { formatByTimeConfig } from "../../../helpers/common";
import { RearrangableTable } from "../../common/RearrangableTable";
import carrierTruck from "../../../../assets/images/carrierTruck.png";
import UnloadingTruck from "../../../../assets/images/UnloadingTruck.png";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";
import { OrgContext } from "../../../context/OrgContext";

function Demo(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { currentOrg } = useContext(OrgContext)
  const isAirCargo = currentOrg.carrier_type === 'AIR_CARGO'
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      appointments: "2022-10-01T12:00:00Z",
      customer: "John Doe",
      orgin_destination: "New York -> Chicago",
      warehouse_address: "New York -> Chicago",
      cities : "New york",
      customer_address: "10001",
      account_code: "ABC123",
      account_name: "AbbbBbCcc",
      created_at: "2022-09-01T12:00:00Z",
      deliver_by : "2022-10-01T12:00:00Z",
      quantity: 1,
      priority: "true",
      weight: 100,
      driver: "Mike",
      exception_message: "This is a test exception message",
      // rejection_message: "This is a test rejection message",
      levelOfService: "Threshold",
      zoneName: "New York",
      status: "RECEIVED",
      hawb: "123456",
      mawb: "123456",
      reference_1: "123456",
      reference_2: "123456",
      vehicle_type: "SPRINTER VAN",
      company_name : "ABC123",
      full_address : '1234, Main Street, New York, NY 10001',
      routing : "ABCD",
      on_hold_reason : "ABCDEFGH",
      PickupAddress: "USA",
      DeliveryAddress : "California",
      created_by : "ABCD",
      estimate: 130,

    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      appointments: "2022-09-20T09:00:00Z",
      customer: "Jane Smith",
      orgin_destination: "Los Angeles -> Houston",
      warehouse_address: "Los Angeles -> Houston",
      cities : 'New jersy',
      customer_address: "77070",
      account_code: "DEF456",
      account_name: "DddEeeFee",
      created_at: "2022-09-15T10:00:00Z",
      deliver_by : "2022-09-20T09:00:00Z",
      quantity: 2,
      weight: 200,
      driver: "Tom",
      priority: "false",
      exception_message: "This is a test exception message",
      // rejection_message: "This is a test rejection message",
      levelOfService: "White Glove",
      zoneName: "California",
      hawb: "123456",
      mawb: "123456",
      reference_1: "700214577",
      reference_2: "123456",
      status: "ASSIGNED",
      vehicle_type: "BOB TRAILER",
      company_name : "ABC123",
      full_address : '1234, Main Street, New York, NY 10001',
      routing : "EFGH",
      on_hold_reason : "IJKLMNOP",
      PickupAddress : "California",
      DeliveryAddress : "USA",
      created_by : "EFGH",
      estimate: 220
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "appointment",
      title: I18n.t("general.scheduled_appointment"),
      dataIndex: "appointments",
      // hideAppointmentdate ? 'HH:mm' : 'DD MMM  HH:mm',
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      // key: isAirCargo ? 'shipper' : "consignee",
      // title: isAirCargo ? 'Shipper' : 'Consignee',
      key: "consignee",
      title: <EndUserTitle />,
      dataIndex: "customer",
    },
    {
      key: "full_address",
      title: "Address",
      dataIndex: "full_address",
    },
    {
      key: "orgin_destination",
      title: "Origin -> Destination",
      dataIndex: "orgin_destination",
    },
    {
      key: "cities",
      title: "City",
      dataIndex: "cities",
    },
    {
      key: "zipcode",
      title: "Zipcode",
      dataIndex: "customer_address",
    },
    {
      key: "is_pod_signed_bol_exist",
      title: "BOL",
      dataIndex: "is_pod_signed_bol_exist",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "priority",
      title: I18n.t("configurations.priority"),
      dataIndex: "priority",
    },
    {
      key: "created_at",
      title: I18n.t("order.age"),
      dataIndex: "created_at",
      render(text, record) {
        return <div>{moment.utc(text).fromNow()}</div>;
      },
    },
    {
      key: "deliver_by",
      title: I18n.t("general.deliver_by"),
      dataIndex: "deliver_by",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: "items_count",
      title: `${I18n.t("order.qty")}`,
      dataIndex: "quantity",
    },
    {
      key: "weight",
      title: `${I18n.t("order.weight")}`,
      dataIndex: "weight",
    },
    {
      key: "driver",
      title: I18n.t("general.Driver"),
      dataIndex:  "driver",
      // render: (record) => record.driver_name,
    },
    {
      key: "exception_message",
      title: I18n.t("general.exception_reason"),
      dataIndex: "exception_message",
    },
    // {
    //   key: "REJECTED",
    //   title: I18n.t("general.reason"),
    //   dataIndex: "rejection_message",
    // },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "levelOfService",
    },
    {
      key: "zone_name",
      title: "Zones",
      dataIndex: "zoneName",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "vehicle_type",
      title: I18n.t("order.vehicle_type"),
      dataIndex: "vehicle_type",
    },
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
    {
      key: "created_by",
      title: I18n.t("general.created_by"),
      dataIndex: "created_by",
    },
    {
      key: "on_hold_reason",
      title: I18n.t("general.onhold_reason"),
      dataIndex: "on_hold_reason",
    },
    {
      key: "PickupAddress",
      title: "Pickup Address",
      dataIndex: "PickupAddress",
    },
    {
      key: "DeliveryAddress",
      title: "Delivery Address",
      dataIndex: "DeliveryAddress",
    },
    {
      key: "vehicle_image",
      title: I18n.t("vehicleTypes.imgTitle"),
      render: (record) => {
        let imageResource;
        if (record.vehicle_type === "SPRINTER VAN") {
          imageResource = carrierTruck;
        } else if (record.vehicle_type === "BOB TRAILER") {
          imageResource = UnloadingTruck;
        }
        return (
          <img
            src={imageResource}
            alt="vehicle"
            style={{ width: 60, height: 60 }}
          />
        );
      },
    },
    {
      key: "estimate",
      title: I18n.t("order.quote"),
      dataIndex: "estimate",
    },
  ]);

  // currentOrg

  useEffect(() => {
    if (currentOrg.code !== 'HILL') {
      setColumns(columns.filter(col => col.key !== 'is_pod_signed_bol_exist'))
    }
  }
  , [currentOrg])

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "order_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
}

export default Demo;
