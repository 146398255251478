/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { isEmpty } from '../../common/Common';
import { formatUSPhoneNumber } from '../../helpers/common';

const InvoiceContact = ({
  data, displayType = 'line', showIcon = false, showAddr = false,driverNo = ""
}) => {
  if (data) {
    const {billing_address,address}=data;
    return (
      <div>
        {
          showAddr && <AddressInfo
            //address={(data && data.address) || {}}
            address={
              !isEmpty(billing_address)
                ? billing_address
                  : !isEmpty(address)
                  ? address
                : "NA" || {}
            }
            showIcon={showIcon}
            displayType={displayType}
          />
        }
        {!isEmpty(driverNo) &&
        <Row>
          <Col xs={24}>
            {showIcon && <Fragment><Icon type="phone" /> &nbsp;</Fragment>}
            {formatUSPhoneNumber(driverNo)}
          </Col>
        </Row>
        }
        {!isEmpty(data.email) &&
          <Row>
            <Col xs={24}>{showIcon && <Fragment><Icon type="mail" /> &nbsp; </Fragment>}
              {data.email}
            </Col>
          </Row>
        }
      </div>
    );
  }
  return null;
};

export default InvoiceContact;

InvoiceContact.propTypes = {
  data: PropTypes.shape().isRequired,
  displayType: PropTypes.string,
  showIcon: PropTypes.bool,
  showAddr: PropTypes.bool,
};

InvoiceContact.defaultProps = {
  displayType: 'line',
  showIcon: false,
  showAddr: false,
};
