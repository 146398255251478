import React, { useRef, useState } from "react";
import GridListComponent from "./GridListComponent";
import { ScreenKeys } from "../../containers/constants";
import { Spin, Button, Row, Col, Popconfirm } from "../../common/UIComponents";
import { DriverSettlementApi } from "../../api/DriverSettlementApi";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";

const ListWrapper = ({
  record,
  data,
  displayConfiguration,
  userContext,
  pagination,
  //handleFormChange,
  viewType,
  organizationSettings,
}) => {
  const [gridApiRef, setGridApiRef] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [changedKeys, setChangedKeys] = useState([]);


  // Set the grid API reference when the grid is ready
  const setCurrentGridRef = (ref) => {
    setGridApiRef(ref);
  };
  const saveOrderData = () => {
    console.log("data", data);
  };

  const handleSave = () => {
    const { customer_order_id, driver_id, location_id, organization_id } =
      record;
    const payload = {
      customer_order_id,
      driver_id,
      location_id,
      organization_id,
      driver_pay_invoice_lines: data.map((rec) => ({
        driver_pay_invoice_line_id: rec.id,
        item_value: rec.item_value,
      })),
    };
    DriverSettlementApi.saveOrderLines(payload).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        setErrors([]);
        setChangedKeys([]);
      } else {
        alertMessage(result.errors, "error", 10);
        setErrors(result.errors);
      }
    });
  };

  const handleFormChange = (key) => {
   const keys = [...changedKeys];
   keys.push(key)
   setChangedKeys(keys);
  };

  return (
    <Spin spinning={isLoading}>
      <div style={{ width: "90%", marginLeft: -25 }} className="marginTop10">
        <Row>
          <Col className="alignRight">
            <Popconfirm
              placement="topRight"
              title={I18n.t("messages.action_confirm", {
                action: "save",
                field: "",
              })}
              onConfirm={handleSave}
              okText="Yes"
              cancelText="No"
              disabled={changedKeys.length < 1}
            >
              <Button
                size="small"
                type="primary"
                disabled={changedKeys.length < 1}
              >
                {I18n.t("general.save")}
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            {changedKeys.join(',')}
          </Col>
          <Col style={{ height: 300 }} className="alignCenter">
            <GridListComponent
              tableName={ScreenKeys.AIR_CARGO_SETTLEMENTS}
              rawDataSource={data ? [...data] : []}
              userContext={userContext}
              gotoAccountSettings={(params) => () => {}}
              setCurrentGridRef={(ref) => setCurrentGridRef(ref)}
              pagination={pagination}
              navigateToAccount={() => {}}
              displayConfiguration={displayConfiguration}
              onChange={handleFormChange}
              organizationSettings={organizationSettings}
              viewType={viewType}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default ListWrapper;
