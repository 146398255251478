import React, { Component, Fragment } from "react";
import CSVReader from "react-csv-reader";
import AppConfig from "../config/AppConfig";
import { Col, Select, Row, FormItem, Icon } from "../common/UIComponents";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import FormButtons from "../components/common/FormButtons";
import { fetchShortFormAccounts } from "../api/Account";
import { fetchOrganizations } from "../api/Organisations";
import { fetchDbcolumn, saveSettings } from "../api/DbColumnApi";
import FormErrors from "../components/common/FormErrors";
import _ from "lodash";
import { withRouter } from "react-router";
import { renderAlertMessage } from "../helpers/common";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header,
  // header.toLowerCase().replace(/\W/g, '_'),
};

class CsvSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dbColumns: [],
      feColumn: [],
      fileColumns: [],
      processedColumns: {},
      accounts: [],
      accountCode: "",
      organizations: [],
      organization_id: null,
      AccountId: null,
      routes: [],
      errors: [],
      inProgress: true,
    };
  }

  getDbcolumn = () => {
    fetchDbcolumn().then((result) => {
      if (result.success) {
        const feColumn = result.FeCol.map((data) => data.fe_column);
        this.setState({
          dbColumns: result.FeCol || [],
          feColumn,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getAccounts = () => {
    const orgId = this.state.organization_id;
    fetchShortFormAccounts(orgId, "", true).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  componentDidMount() {
    this.getOrganizations();
    this.getDbcolumn();
  }

  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const organization_id = _.find(result.organizations.organizations, [
          "code",
          I18n.t("account.netmove_code"),
        ])._id;

        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id,
          },
          () => {
            this.getAccounts();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleForce = (data, fileInfo) => {
    if (data.length > 0) {
      this.setState({
        fileColumns: Object.keys(data[0]),
      });
    }
  };

  handleColumnOnChange = (dbColumn, targetColumn) => {
    this.setState({
      processedColumns: Object.assign({}, this.state.processedColumns, {
        [dbColumn]: targetColumn,
      }),
    });
  };

  clearForm = () => {
    this.setState({
      fileColumns: [],
      processedColumns: {},
      accountCode: "",
      errors: [],
      AccountId: null,
    });
  };

  handleSave = () => {
    const errors = [];
    const requiredDbColumns = AppConfig.dbColumns.filter(function (itm) {
      return itm.is_mandatory === true;
    });

    const selectedMapping = Object.keys(this.state.processedColumns);

    const requiredCol = requiredDbColumns.filter(
      (data) => !selectedMapping.includes(data.fe_column)
    );
    if (requiredCol.length > 0) {
      const err = requiredCol.map((item) => item.mapping_column).join(", ");
      errors.push(`${I18n.t("errors.field_required", { field: err })}`);
    }
    // if (isEmpty(this.state.accountCode)) {
    //   errors.push("please select Account");
    // }
    if (errors.length === 0) {
      const payload = {
        organization_id: this.state.organization_id,
        account_id: this.state.AccountId,
        columns: this.state.processedColumns,
        file_type: 'csv'
      };

      saveSettings(payload).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"), 10);
          this.setState({ inProgress: false, errors: [] });
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({
        errors,
      });
    }
  };

  handleOnChange = (element, value) => {
    if (element === "organization_id") {
      this.setState({ [element]: value }, () => {
        this.getAccounts(),
          this.setState({
            AccountId: null,
            accountCode: "",
          });
      });
    } else if (element === "accountCode") {
      this.setState({ [element]: value });
      const selectesAccIndex = _.findIndex(this.state.accounts, [
        "code",
        value,
      ]);
      if (selectesAccIndex >= 0) {
        const selectedAccount = Object.assign(
          {},
          this.state.accounts[selectesAccIndex]
        );
        const AccountId = selectedAccount.id;
        //setting State Account ID
        this.setState({ AccountId });
      } else {
        //setting State Account ID to null if account is not
        this.setState({ AccountId: null });
      }
    } else {
      this.setState({ [element]: value });
    }
  };

  render() {
    return (
      <>
        <div className="content-outer">
          <div className="content">
            <Row className="page-header">
              <Col xs={1} className="page-actions">
                <Icon
                  type="arrow-left"
                  className="cursorPointer"
                  onClick={() => this.props.history.push(`mapping_settings`)}
                />
              </Col>
              <Col xs={23} style={{ marginLeft: -30 }}>
                {I18n.t("menu.csvSetting")}
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={6} lg={6}>
                <FormItem
                  label={I18n.t("organization.single")}
                  style={{ marginBottom: "0px" }}
                  require
                >
                  <Select
                    value={this.state.organization_id}
                    showSearch
                    onChange={(e) => this.handleOnChange("organization_id", e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                  >
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={6} lg={6}>
                <FormItem
                  label={I18n.t("general.account")}
                  style={{ marginBottom: "0px" }}
                  //require
                >
                  <Select
                    value={this.state.accountCode || ""}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => this.handleOnChange("accountCode", e)}
                    dropdownMatchSelectWidth={false}
                  >
                    <Select.Option key="select" value="">
                      -- Select --
                    </Select.Option>
                    {this.state.accounts.map((account) => (
                      <Select.Option key={account.code} value={account.code}>
                        {`${account.name} (${account.code})`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={12} lg={12}>
                <FormItem
                  label={I18n.t("csvSetting.select_csv")}
                  style={{ marginBottom: "0px" }}
                  require
                >
                  <CSVReader
                    cssClass="react-csv-input"
                    inputStyle={{ margin: "0", padding: "5px" }}
                    onFileLoaded={this.handleForce}
                    parserOptions={papaparseOptions}
                    // disabled={isEmpty(this.state.accountCode)}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col>
                {this.state.fileColumns.length > 0 ? (
                  <Fragment>
                    <Row className="marginTop10">
                      <Col xs={4} />
                      <Col xs={4} className="alignCenter textBold">
                        Database Column
                      </Col>
                      <Col xs={12} className="alignCenter textBold">
                        CSV Column
                      </Col>
                    </Row>
                    <div style={{height: this.state.errors.length ? "calc(100vh - 390px)": "calc(100vh - 280px)", overflowY: "auto"}}>
                    {this.state.dbColumns.map((column) => (
                      <Row key={column.fe_column} className="marginTop10">
                        <Col xs={4} />
                        <Col xs={4} className="alignLeft">
                          {column.mandatory === true ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            " "
                          )}
                          &nbsp;
                          {column.label}
                          {/* {fe_column= "is_mandatory":ture, required:true} */}
                        </Col>
                        <Col xs={12}>
                          <Select
                            value={
                              this.state.processedColumns[column.fe_column] ||
                              ""
                            }
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) =>
                              this.handleColumnOnChange(column.fe_column, e)
                            }
                            style={{ width: "100%" }}
                          >
                            <Select.Option key="all" value="">
                              Select Column
                            </Select.Option>
                            {this.state.fileColumns
                              .filter(
                                (col) =>
                                  !Object.values(
                                    this.state.processedColumns
                                  ).includes(col)
                              )
                              .map((fileColumn) => (
                                <Select.Option
                                  key={fileColumn}
                                  value={fileColumn}
                                >
                                  {fileColumn}
                                </Select.Option>
                              ))}
                          </Select>
                        </Col>
                        <Col xs={4} />
                      </Row>
                    ))}
                    </div>
                    <Row>{FormErrors(this.state.errors)}</Row>
                  </Fragment>
                ) : (
                  ""
                )}
                {this.state.fileColumns.length > 0 && (
                  <Col xs={24}>
                    {FormButtons(
                      this.state.inProgress,
                      this.handleSave,
                      this.clearForm,
                      true,
                      true,
                      "default",
                      I18n.t("general.save"),
                      I18n.t("general.clear")
                    )}
                  </Col>
                )}
              </Col>
            </Row>
            {/* <Row>{FormErrors(this.state.errors)}</Row> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CsvSettings);
