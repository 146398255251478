import React,{ useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {TreeSelect} from "antd"
import { fetchLocations } from '../../api/LocationsApi';
const { TreeNode } = TreeSelect;
import AppConfig from '../../config/AppConfig';

function WarehouseCodeFilter (props) {
  const { SelectedCodes, onValueChange ,element = 'warehouse_id', valueField="id" } = props;

  const [warehouses, setWarehouses] = useState([]);
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    const getWarehouses = async () => {
      try {
        setInProgress(true);
        const result = await fetchLocations(AppConfig.warehouseCode, 1, null);
        if (result.success) {
          setWarehouses(result.locations.locations);
          setInProgress(false);
        } else {
          setInProgress(false);
        }
      } catch (error) {
        setInProgress(false);
      }
    };
    getWarehouses();
  }, []);

  return (
    <div className="AccountCodesFilterContainer">
      <TreeSelect
        value={ SelectedCodes }
        style={ { width: '100%' } }
        showSearch
        size="small"
        className="minWidth"
        dropdownStyle={ {
          maxHeight: 300,
          overflow: "auto",
        } }
        placeholder="--Select--"
        allowClear
        treeDefaultExpandAll
        dropdownMatchSelectWidth={ false }
        onChange={ (e) =>
          onValueChange(element, e)
        }
        treeCheckable
        maxTagCount={ 1 }
        treeNodeFilterProp="title"
      >
        <TreeNode value="ALL" title="All" key="0-1">
          { warehouses.sort((a, b) => a.name.localeCompare(b.name)).map((warehouse) => (
            <TreeNode
              value={ warehouse[valueField] }
              title={ `${warehouse.name}` }
              key={ warehouse.code }
            />
          )) }
        </TreeNode>
      </TreeSelect>
    </div>
  );
}

export default WarehouseCodeFilter;

WarehouseCodeFilter.propTypes = {
  SelectedCodes: PropTypes.array,
  onValueChange: PropTypes.func,
  warehouses: PropTypes.array,
};

WarehouseCodeFilter.defaultProps = {
  SelectedCodes: [],
  onValueChange: () => {},
  warehouses: [],
};