import React from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';
import I18n from '../../common/I18n';
import { Icon,   Tooltip, } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import FormIcon from '../common/FormIcon';

class SurveysList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
      },
      {
        key: 'customer_name',
        title: I18n.t('order.customer_name'),
        dataIndex: 'customer_name',
      },
      {
        key: 'item_name',
        title: I18n.t('order.item_name'),
        dataIndex: 'item_name',

      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'order_id',
        render: id => (
         checkServiceExistance('SRVYSO') && 
         <div className='line-actions'>
            <Tooltip title={I18n.t('survey.view_submitted')}><FormIcon handleClick={() => this.props.handleSurveyDetails(id)}/></Tooltip>
          </div>
        ),
        width: '75px',
      },
    ];
  }
}

export default SurveysList;
