import { Button, Col, Row } from "antd";
import React, { Component } from "react";
import InstructionsList from "./InstructionsList";
import PropTypes from "prop-types";
import Text from "antd/lib/typography/Text";
import { checkServiceExistance } from "../../helpers/common";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";

class InstructionsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isInstructionSelected: false,
			isNewInstruction: false,
			isEditing: false,
			instructionTypesData: [],
			instructionsData: [],
		};
		this.tableOptions = { pagination: {}, sorter: {} };
	}

	onTableChange = (pagination, sorter) => {
		const currentPage =
			this.tableOptions.pagination && this.tableOptions.pagination.current
				? this.tableOptions.pagination.current
				: 1;
		this.tableOptions = { pagination, sorter };
		if (pagination.current !== currentPage) {
			this.props.getInstructions(pagination.current);
		} else if (sorter && !isEmpty(sorter.field)) {
			const sortBy = sorter.field;
			const sortOrder = sorter.order;
			this.tableOptions.pagination.current = 1;
			this.props.getInstructions(pagination.current, sortBy, sortOrder);
		}
	};
	render() {
		const {
			isOrgLevelInstruction,
			handleInstructionUpdate,
			handleInstructionDeletion,
			fullInstructionTypesData,
			openInstructionModal,
			filteredInstructionsData,
			isLoading,
		} = this.props;
		return (
			<Col span={isOrgLevelInstruction ? 12 : 24}>
				<div className="instructionsWrapper__right">
					<Row className="instructionWrapperChild" gutter={16}>
						<Row
							type="flex"
							justify={isOrgLevelInstruction ? "space-between" : "end"}
							style={{
								padding: "8px",
							}}
						>
							{isOrgLevelInstruction && (
								<Col className="instructionsWrapper__right__header">
									<Text
										style={{
											fontSize: "14px",
											fontWeight: "bold",
										}}
									>
										{I18n.t("instructions.label")}
									</Text>
								</Col>
							)}
							{checkServiceExistance(isOrgLevelInstruction ? 'OINSC' : 'AINSC') && <Col>
								<Button
									type="primary"
									icon="plus-circle"
									className="instructionsWrapper__right__header__button"
									disabled={fullInstructionTypesData.length === 0}
									onClick={() => openInstructionModal()}
								>
									{I18n.t("instructions.add_instruction")}
								</Button>
							</Col>
							}
						</Row>

						<Col className="instructionsWrapper__right__body">
							<InstructionsList
								className="instructionsWrapper__left__table"
								scroll = {{y:"calc(100vh - 375px)"}}
								isLoading={isLoading}
								rowKey="_id"
								handleEdit={handleInstructionUpdate}
								handleDelete={handleInstructionDeletion}
								data={filteredInstructionsData}
								pagination={this.props.pagination}
								tableChange={(pagination, filter, sorter, currentTable) =>
									this.onTableChange(pagination, sorter, currentTable)
								}
								isOrgLevelInstruction={isOrgLevelInstruction}
							></InstructionsList>
						</Col>
					</Row>
				</div>
			</Col>
		);
	}
}
export default InstructionsTable;

InstructionsTable.propTypes = {
	isOrgLevelInstruction: PropTypes.bool,
	handleInstructionUpdate: PropTypes.func,
	handleInstructionDeletion: PropTypes.func,
	instructionTypesData: PropTypes.array,
};

InstructionsTable.defaultProps = {
	isOrgLevelInstruction: false,
	handleInstructionUpdate: () => {},
	handleInstructionDeletion: () => {},
	instructionTypesData: [],
};
