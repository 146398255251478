import { Col, Form, Row, Spin, Tabs, TreeSelect, Tag, Button, Switch, Icon, Result, Input } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from "lodash";
import PropTypes from "prop-types";
import I18n from '../../common/I18n';
import { checkServiceExistance, limitString } from '../../helpers/common';
import OrdersList from '../preplan/OrdersList';
import { Card, Search, Select } from '../../common/UIComponents';
import { alertMessage, isEmpty } from '../../common/Common';
import {  findDependencyOrders, rearrangeBySeq } from '../../helpers/preplan';
import FilterTag from '../orders/FilterTag';
import { OrgContext } from '../../context/OrgContext';
import UnassignedOrdersList from '../preplan/OrdersListRe';
import AppConfig from "../../config/AppConfig";
import UnallocatedOrdersGridList from '../dispatches/UnallocatedOrdersGridList';
import { ScreenKeys } from '../../containers/constants';
import { preplanData } from '../dispatches/preplanData';
import { UserContext } from '../../context/UserContext';
import { DisplaySettingsContext } from '../../context/DisplaySettings';

const { TreeNode } = TreeSelect

function UnassignedOrders(props) {
  const {
    unassignedOrdersInfo,
    totalOrders,
    getOrders,
    orderProgress,
    accountCodes,
    accounts,
    zone_ids,
    preplanZones,
    filter,
    onFilterValueChange,
    onSearchNumbers,
    orderInfo,
    handleEditOrderClick,
    applyFilters,
    handleUnassignOrdersInfo,
    setAccountCodes,
    setFilter,
    setZone_ids,
    setUnassignedOrdersInfo,
    setOrderInfo,
    unsavedServiceDurationChanges,
    orgSettingsForTable,
    displayConfiguration,
    screen_from,
    vhTypes,
    orderVhTypes,
    setOrderVhTypes,
    orderTypeFilter,
    setOrderTypeFilter,
    orderSettings,
    isMilitaryTime,
    clusteredOrder, 
    setCurrentGridRef,
    showBetaFeature,
    currentGridRef,
    dispContext,
    openRerrangeableModal,
    setOpenRerrangeableModal,
  } = props

  const {accountsExceeded} = useContext(OrgContext);
  const userContext = useContext(UserContext);

  const prevSelectedOrderKeys = useRef([]);
  //const [ currentGridRef, setCurrentGridRef ] = useState(null);
  const { orderTypeKeysForFilter = {}} = orderSettings;
  const [ madeWidthChanges, setMadeWidthChanges ] = React.useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [isItemVisible, setIsItemVisible] = React.useState(false);
  const handleSelectAllChange = (selected, selectedRows, changedRows) => {
    if (selected) {
      const orders = rearrangeBySeq(selectedRows)
      handleUnassignOrdersInfo(orders)
    } else {
      handleUnassignOrdersInfo([])
    }
  }
  const handleShiftSelectChange = (selectedRowKeys, selectedRows , event=null) => {
    let orders = []
    const previousRows = prevSelectedOrderKeys.current

    // const previousRows = [...unassignedOrdersInfo.selectedOrderRows]
    const diffOrders = selectedRows.filter((d) => !previousRows.includes(d.id))
    const removedOrders = previousRows.filter(
      (d) => !selectedRowKeys.includes(d.id),
    )
    let dependencyOrders = []
    removedOrders.forEach((currentRecord) => {
      const dependencies = findDependencyOrders(
        totalOrders,
        'related_order',
        'id',
        currentRecord.id,
        'object',
        'type_of_order',
      )
      dependencyOrders = [].concat(dependencyOrders, dependencies)
    })
    const dependencyIds = dependencyOrders.map((order) => order.id)
    dependencyOrders = []
    diffOrders
      .filter((showOrder) => !dependencyIds.includes(showOrder.id))
      .forEach((currentRecord) => {
        const dependencies = findDependencyOrders(
          totalOrders,
          'related_order',
          'id',
          currentRecord.id,
          'object',
          'type_of_order',
        )
        dependencyOrders = [].concat(dependencyOrders, dependencies)
        const dependencyIds = dependencyOrders.map((order) => order.id)
        if (!selectedRowKeys) {
          orders = previousRows.filter(
            (order) => !dependencyIds.includes(order.id),
          )
        } else if (selectedRowKeys) {
          const filteredItems = previousRows.filter((order) =>
            dependencyIds.includes(order.id),
          )
          if (dependencyOrders.length > 0) {
            orders = [].concat(filteredItems, dependencyOrders)
          } else {
            orders = [...filteredItems]
          }
        }
      })
    const unq = _.uniqBy(orders, 'id');
    prevSelectedOrderKeys.current = selectedRows;
    handleUnassignOrdersInfo(unq)
  }
  const selectedRowKeys = unassignedOrdersInfo.selectedOrderKeys;
  const rowSelection = {
    selectedRowKeys,
    onSelectAll: handleSelectAllChange,
    onChange: handleShiftSelectChange,
    columnWidth: 30,
    getCheckboxProps: (record) => ({
      id: `${record.id}`,
      className: `uid-${record.id}`,
      disabled: record.account_limit_exceeded === true,
    }),
  }

 
  const changeOrderStatus = (element, eleValue) => {
    setFilter({ ...filter, search_order_token: '' })
    setSearchValue('');
    if (element === 'zone_ids') {
      let value = eleValue
      if (eleValue.includes('ALL')) {
        value = preplanZones.map((zone) => zone.delivery_zone_id)
        value.push('UNASSIGNED')
      }
      setZone_ids(value)
      // let selectedZones = preplanZones.filter((zone) => eleValue.includes(zone.delivery_zone_name));
      // setZone_ids(selectedZones);
    } else if (element === 'accountCodes') {
      setAccountCodes(eleValue)
    } else if (element === 'orderVhTypes') {
      setOrderVhTypes(eleValue)
    } else if (element === 'orderTypeFilter') {
       const filteredorderType = Object.keys(orderTypeKeysForFilter).filter((order) => order !== "LH")
       let filteredValues = eleValue;
      if (eleValue?.length > 0){
        if (eleValue.includes("ALL")) {
          if (eleValue[0] === "ALL") {
            filteredValues = eleValue.filter((item) => item !== "ALL");
          } else if (eleValue[eleValue.length - 1] === "ALL") {
            filteredValues = ["ALL"];
          }
        }
        if (eleValue.length === filteredorderType.length) {
          filteredValues = ["ALL"];
        }
      }else{
        filteredValues = ["ALL"];
      }
        
      setOrderTypeFilter(filteredValues);
    }
  }

  useEffect(() => {
    if (!filter.search_order_token.length) applyFilters()
  }, [accountCodes, zone_ids, orderVhTypes, orderTypeFilter])

  const handleSearch = (value) => {
    const accountCodesWithExceededLimit = accountsExceeded.map(account => account.account_code);
    onFilterValueChange('search_order_token', value);
    onSearchNumbers({
      ...filter,
      search_order_token: value,
    }, totalOrders, setOrderInfo, setZone_ids, setAccountCodes, setUnassignedOrdersInfo, setOrderVhTypes, accountCodesWithExceededLimit, setOrderTypeFilter);
  };

  const getRoutesListClass = useCallback((record, index) => {
    const clusteredOrderIds = clusteredOrder.map(order => order.id)
    return clusteredOrderIds.includes(record.id)
      ? "blockBgOverColor activeOrderRow"
      : record.location_partial_match === true
      ? "blockBgErrorColor"
      : !(index % 2)
      ? "table-row-light"
      : "table-row-dark";
  }, [clusteredOrder]); 


  const recomputeData = useCallback(() => {
    const accountCodesWithExceededLimit = accountsExceeded.map(account => account.account_code);
    return orderInfo.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    });
  }, [ orderInfo, accountsExceeded ]);

  const NoDataFound = () => (
    <Result
      title="No Orders Found"
      subTitle="Sorry, we couldn't find any orders."
    />
  );


  const renderFilters = () => {
    return (
      <Fragment>
      <Row gutter={ 6 } type="flex" justify="space-between" align="middle" style={ { padding: 3 } }>
        <Col xs={ 24 } sm={ 24 } md={ 6 }>
          <div style={ { display: "flex", alignItems: "center" } }>
            <span style={ { marginRight: "3px" } }>
              { I18n.t("general.account") }:
            </span>
            <TreeSelect
              value={ accountCodes }
              showSearch
              size="small"
              className="minWidth"
              style={ { width: 125 } }
              dropdownStyle={ { maxHeight: 300, overflow: "auto" } }
              placeholder="--Select--"
              allowClear
              treeDefaultExpandAll
              dropdownMatchSelectWidth={ false }
              onChange={ (e) => changeOrderStatus("accountCodes", e) }
              treeCheckable
              maxTagCount={ 0 }
              treeNodeFilterProp="title"
            >
              <TreeNode value="ALL" title="All" key="0-1">
                { accounts.map((account) => (
                  <TreeNode
                    value={ account.code }
                    title={ `${account.name} (${account.code})` }
                    key={ account.code }
                  />
                )) }
              </TreeNode>
            </TreeSelect>
          </div>
        </Col>
        <Col xs={ 24 } sm={ 24 } md={ 6 }>
          { checkServiceExistance("DZI") && (
            <div style={ { display: "flex", alignItems: "center" } }>
              <span style={ { marginRight: "3px" } }>
                { I18n.t("zones.zone") }:
              </span>
              <TreeSelect
                value={ zone_ids }
                showSearch
                size="small"
                className="minWidth"
                style={ { width: 125 } }
                dropdownStyle={ { maxHeight: 300, overflow: "auto" } }
                placeholder="--Select--"
                allowClear
                treeDefaultExpandAll
                dropdownMatchSelectWidth={ false }
                onChange={ (e) => changeOrderStatus("zone_ids", e) }
                treeCheckable
                maxTagCount={ 0 }
                treeNodeFilterProp="title"
              >
                <TreeNode value="ALL" title="All" key="0-1">
                  { preplanZones.map((zone) => (
                    <TreeNode
                      value={ zone.delivery_zone_id }
                      title={ zone.delivery_zone_name }
                      key={ zone.delivery_zone_id }
                    />
                  )) }
                </TreeNode>
              </TreeSelect>
            </div>
          ) }
        </Col>
        <Col xs={ 24 } sm={ 24 } md={ 6 }>
          <div style={ { display: "flex", alignItems: "center" } }>
            <span style={ { marginRight: "3px" } }>
              { I18n.t("fleet.veh_type") }:
            </span>
            <TreeSelect
              value={ orderVhTypes }
              showSearch
              size="small"
              className="minWidth"
              style={ { width: 125 } }
              dropdownStyle={ { maxHeight: 300, overflow: "auto" } }
              placeholder="--Select--"
              allowClear
              treeDefaultExpandAll
              dropdownMatchSelectWidth={ false }
              onChange={ (e) => changeOrderStatus("orderVhTypes", e) }
              treeCheckable
              maxTagCount={ 0 }
              treeNodeFilterProp="title"
            >
              <TreeNode value="ALL" title="All" key="0-1">
                { vhTypes.map((vhType) => (
                  <TreeNode
                    value={ vhType.code }
                    title={ `${vhType.name} (${vhType.code})` }
                    key={ vhType.code }
                  />
                )) }
              </TreeNode>
            </TreeSelect>
          </div>
        </Col>
        <Col xs={ 24 } sm={ 24 } md={ 6 }>
            { showBetaFeature ? (
              <Input
                size="small"
                placeholder="Quick Filter"
                suffix={ <Icon type="search" /> }
                onChange={ event => currentGridRef.setQuickFilter(event.target.value) }
              />
            ) : (
                <Search
                  placeholder="Search Order"
                  size="small"
                  onSearch={handleSearch}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ width: '100%' }}
                  allowClear
                />
            ) }
        </Col>
      </Row>
      <Row
          gutter={6}
          type="flex"
          align="middle"
        >
          <Col>
            <div style={{ display: "flex", alignItems: "center", rowGap:"3px" }}>
              <span style={{ marginRight: "3px" }}>
                {I18n.t("order.type")}:
              </span>
              <Select
                mode="multiple"
                maxTagCount={1}
                style={{ width: 170 }}
                value={orderTypeFilter || ""}
                onChange={(e) => changeOrderStatus("orderTypeFilter", e)}
                size={"small"}
              >
                <Select.Option value="ALL" key="all">
                  -- All --
                </Select.Option>
                {Object.keys(orderTypeKeysForFilter)
                  .filter((order) => order !== "LH")
                  .map((opt) => (
                    <Select.Option value={opt}>
                      {orderTypeKeysForFilter[opt]}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
  

  const isRowSelectable = (row) => {
    return !row.data.account_limit_exceeded;
  };
  // const rowSelectionVal = checkServiceExistance('NRURO') ? handleShiftSelectChange : undefined
  return (
    <div className="bgWhite borderRadius10 borderLine unassignedSection" style={{opacity: isItemVisible ? 0.3 : null}}>
      <Spin delay={ 1000 } spinning={ orderProgress }>
        { !madeWidthChanges ? (
         <>
         {renderFilters()}
        <Row style={{ margin: 3}}>
          {
            !isEmpty(filter.search_order_token) ?
            <Col className='textBold paddingLeft5'>
              Results for Orders : <Tag
                  color="blue"
                  closable={false}
                  onClose={ () => {}}
                  key={ `tags_search_order_token` }
                >
                  { _.trim(filter.search_order_token, " ")}
                </Tag>
            </Col>
          :
          <Col>
            { accountCodes?.length > 0 && <FilterTag
              InternationalizationText={ I18n.t("general.accounts") }
              closable={false}
              onClose={ () => {}}
              filterValue={accountCodes}
            >
              {
                accountCodes.length === accounts.length ? I18n.t('general.all') :
                limitString(accountCodes &&
                  accounts
                    .filter((acc) =>
                      accountCodes.includes(acc.code)
                    )
                    .map((acc) => acc.name)
                    .join(", "), 2)
              }
            </FilterTag>
          }
          { zone_ids?.length > 0 && <FilterTag
              InternationalizationText={ I18n.t("zones.zone")}
              closable={false}
              onClose={ () => {}}
              filterValue={zone_ids}
            >
              {
                zone_ids.length === preplanZones.length ? I18n.t('general.all') :
                limitString(zone_ids &&
                  preplanZones
                    .filter((zone) =>
                      zone_ids.includes(zone.delivery_zone_id)
                    )
                    .map((zone) => zone.delivery_zone_name)
                    .join(", "), 2)
              }
            </FilterTag>
          }
          { orderVhTypes?.length > 0 && <FilterTag
              InternationalizationText={ I18n.t("fleet.veh_type") }
              closable={false}
              onClose={ () => {}}
              filterValue={orderVhTypes}
            >
              {
                orderVhTypes.length === vhTypes.length ? I18n.t('general.all') :
                limitString(orderVhTypes &&
                  vhTypes
                    .filter((rec) =>
                      orderVhTypes.includes(rec.code)
                    )
                    .map((rec) => rec.name)
                    .join(", "), 2)
              }
            </FilterTag>
          }
          { orderTypeFilter?.length > 0 && <FilterTag
              InternationalizationText={ I18n.t("order.type") }
              closable={false}
              onClose={ () => {}}
              filterValue={orderTypeFilter}
            >
              {Object.keys(orderTypeKeysForFilter).filter((order) => order !== "LH").length === orderTypeFilter.length || orderTypeFilter[0] === "ALL"
                  ? I18n.t("general.all") :
                  limitString(orderTypeFilter &&
                    orderTypeFilter.map((rec) => orderTypeKeysForFilter[rec]).join(", "),2
                  )
              }
            </FilterTag>
          }
          </Col>
          }
        </Row>
          </>) : null }
        { !props.showBetaFeature ? (
          <UnassignedOrdersList
          // data={ orderInfo }
          data={ recomputeData(orderInfo) }
            rowSelection={ rowSelection }
          size="small"
          handleEditOrder={ (id) => handleEditOrderClick(id, '', '') }
          showDelete={ false }
          pagination={ { position: 'none' } }
          selectedList={ unassignedOrdersInfo.selectedOrderKeys }
          scroll={ { x: 980, y: "calc(100vh - 230px)" } }
          rowClassName={(record, index) => getRoutesListClass(record, index)}
          totalOrders={ totalOrders }
          resolvedAddressCallback={ getOrders }
          setOrderInfo={ setOrderInfo }
          unsavedServiceDurationChanges={ unsavedServiceDurationChanges }
          orgSettingsForTable={ orgSettingsForTable }
          displayConfiguration={ displayConfiguration }
          screen_from={ screen_from }
          vhTypes={ vhTypes }
          onColWidthChange={ (columnWidths , unsavedChanged) => {
              setMadeWidthChanges(unsavedChanged)
          } }
          isMilitaryTime={isMilitaryTime}
          setIsItemVisible={setIsItemVisible}
          />
        ) : (
          totalOrders.length > 0 ? (
            <div style={ { height: "100vh" } }>
            <UnallocatedOrdersGridList
              setCurrentGridRef={ setCurrentGridRef }
              source="unassignedOrders"
              rawDataSource={ orderInfo }
              accountCodesWithExceededLimit={ accountsExceeded.map(account => account.account_code) }
              onRowSelection={handleShiftSelectChange}
              accountsList={ [] }
              displayConfiguration={displayConfiguration}
              showTriggerEdit={ true }
              showDelete={ false }
              showActions={ false }
              showDetailsLink={ false }
              showWeight={ true }
              hideAppointmentdate={ true }
              isRowSelectable={ isRowSelectable }
              tableName={ ScreenKeys.PREPLAN.UNASSIGNED_ORDERS }
              selectedIds={ unassignedOrdersInfo.selectedOrderKeys }
              handleEditOrder={ (id) => {
                alertMessage("Loading Order Details", "info");
                handleEditOrderClick(id, '', '');
              } }
              navigateToAccount={ (accountCode) => { console.log("navigateToAccount", accountCode); } }
              resolvedAddressCallback={ getOrders }
              showRerrangeableModal={ openRerrangeableModal }
              closeModal = { () => setOpenRerrangeableModal(false) }
              userContext={userContext}
              dispContext={dispContext}
              showDetailIcon={true}
            />
              </div>
          ) : (
            <NoDataFound />
          )
        ) }

      </Spin>
    </div>

  )
}

export default UnassignedOrders

UnassignedOrders.propTypes = {
  unassignedOrdersInfo: PropTypes.object.isRequired,
  goToTab: PropTypes.func.isRequired,
  resetSelectedOrders: PropTypes.func.isRequired,
  routeInfo: PropTypes.object.isRequired,
  currentPreplan: PropTypes.object.isRequired,
  totalOrders: PropTypes.array.isRequired,
  getOrders: PropTypes.func.isRequired,
  orderProgress: PropTypes.bool.isRequired,
  accountCodes: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  zone_ids: PropTypes.array.isRequired,
  preplanZones: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  currentGridRef: PropTypes.object.isRequired,
  onFilterValueChange: PropTypes.func.isRequired,
  onSearchNumbers: PropTypes.func.isRequired,
  orderInfo: PropTypes.array.isRequired,
  handleEditOrderClick: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  setCurrentGridRef: PropTypes.func.isRequired,

}

UnassignedOrders.defaultProps = {
  unassignedOrdersInfo: {},
  goToTab: () => {},
  resetSelectedOrders: () => {},
  routeInfo: {},
  currentPreplan: {},
  totalOrders: [],
  getOrders: () => {},
  orderProgress: false,
  accountCodes: [],
  accounts: [],
  zone_ids: [],
  preplanZones: [],
  filter: {},
  onFilterValueChange: () => {},
  onSearchNumbers: () => {},
  orderInfo: [],
  handleEditOrderClick: () => {},
  applyFilters: () => {},
  setCurrentGridRef: () => {},
  currentGridRef: {},
}
