import React from "react";
import { Button, Col, Icon, Popconfirm, Row, Tag, Tooltip } from "antd";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import { processOptionData } from "./helpers";
import { orderTypeColorMapping } from "../dispatches/helpers";
import TypeOfOrder from "../orders/TypeOfOrder";
import _ from "lodash";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { checkServiceExistance } from "../../helpers/common";
import { Link } from "../../common/UIComponents";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import { isEmpty } from "../../common/Common";

const processtime = (data, type, orgSettings) => {
  const { order_type } = data;
  const isMilitaryTime = orgSettings.is_military_time === "true";
  const { pickup_time, delivery_time } = processOptionData(
    data,
    isMilitaryTime
  );
  const result = "N/A";

  let time;
  switch (order_type) {
    case 'R':
    case 'D':
    case 'M':
      time = type === 'from' ? pickup_time : delivery_time;
      break;
    case "T":
      time = type === 'from' ? pickup_time : delivery_time;
      break;
    case "LH":
      time = type === 'from' ? pickup_time : delivery_time;
      break;
    default:
      time = result;
  }
  if (!time) return result;
  if (!time.start_time || !time.end_time) return result;
  return `${time.start_time} - ${time.end_time}`;
};

class TemplatesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  setColumns = (props) => {
    this.columns = [
      {
        key: "template_name",
        title: "Name",
        dataIndex: "template_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          if (!data) {
            return (
              <Button type="link" onClick={ () => this.props.handleEdit(record) } style={{ padding: 0 }}>
                Add Name
              </Button>
            );
          }
          return <span>{data}</span>;
        },
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <div>
            <OrderFieldDisplay
              data={record}
              toDisplay="hawb"
              showTriggerEdit={true}
              editClick={() => this.props.handleEdit(record)}
            />
          </div>
        ),
      },
      {
        key: "mawb",
        dataIndex: "mawb",
        title: I18n.t("general.mawb"),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <div>
            <OrderFieldDisplay
              data={record}
              toDisplay="mawb"
              showTriggerEdit={true}
              editClick={() => this.props.handleEdit(record)}
            />
          </div>
        ),
      },
      {
        key: "from_time",
        title: "From Time",
        dataIndex: "from_time",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          return (
            <span>
              {processtime(record, "from", this.props.organizationSettings)}
            </span>
          );
        },
      },
      {
        key: "to_time",
        title: "To Time",
        dataIndex: "to_time",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          return (
            <span>
              {processtime(record, "to", this.props.organizationSettings)}
            </span>
          );
        },
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  <Tooltip title={record.account_name}>
                    {!isEmpty(data) ? data : record.account_code}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  <Tooltip title={record.account_code}>
                    {!isEmpty(data) ? data : record.account_code}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
      },
      {
        key: "los",
        title: "LOS",
        dataIndex: null,
        render: (data, record) => {
          if (data.order_type !== "T") return <div>{ data.los }</div>;

          const originLos = _.get(data, 'locations[0].type_of_loc') === "PICKUP"
            ? _.get(data, 'locations[0].los_code')
            : null;
          const destinationLos = _.get(data, 'locations[1].type_of_loc') === "DELIVERY"
            ? _.get(data, 'locations[1].los_code')
            : null;

          const los = [ originLos, destinationLos ].filter(_.identity).join(' - ') || null;

          return <div>{ los }</div>;
        },
        width: "10%",
      },
      {
        key: "order_type",
        title: I18n.t("general.order_type"),
        dataIndex: "order_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          return (
            <TypeOfOrder
              order={record}
              orderTypeKey="order_type"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
              key={`TypeOfOrder${record.id}`}
            />
          );
        },
        // width: "5%",
      },
      {
        key: "actions",
        render: (data, record) => {
          const { handleDelete, handleEdit, createTemplateOrder } = this.props;
          const { id } = record;
          return (
            // <div className="pageActions line-actions">
            <Row gutter={8} className="line-actions">
              <Col span={8}>
                <Tooltip title={I18n.t("templates.create_order")}>
                  <Icon
                    type="snippets"
                    // onClick={() => createTemplateOrder(id)}
                    onClick={() => this.props.handledateModal(id)}
                    className="SnippetsIcon"
                  />
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip title={I18n.t("general.edit")}>
                  {/* <Icon type="edit" onClick={() => handleEdit(record)}/> */}
                  <EditIcon handleClick={() => handleEdit(record)} />
                  &nbsp;
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip title={I18n.t("general.delete")}>
                  <Popconfirm
                    placement="left"
                    title={I18n.t("messages.delete_confirm")}
                    onConfirm={() => handleDelete(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon />
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
            // </div>
          );
        },
        width: "10%",
      },
    ].map((item) => {
      item.align = "left";
      return item;
    });
    const isRecurringOrderPage = props.screen_from === "templates";
    const isFromSearching = props.screen_from === "templateSearch"
    if (!_.isEmpty(props.displayConfiguration) && isRecurringOrderPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const recurringOrders_list_view_columns =
        userConfiguration.recurringOrders_list_view_columns ||
        orgConfiguration.recurringOrders_list_view_columns;
      const columns = [];
      if (
        typeof recurringOrders_list_view_columns !== undefined &&
        Array.isArray(recurringOrders_list_view_columns)
      ) {
        recurringOrders_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "actions");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }else if(isFromSearching){
      // remove actions
      const actions = this.columns.find((col) => col.key === "actions");
      if (actions) {
        this.columns = this.columns.filter((col) => col.key !== "actions");
      }
      // add a column which contains "+ Add" button
      this.columns.push({
        key: "actions",
        render: (data, record) => {
          const { addTemplate } = this.props;
          const { id } = record;
          return (
            <Row gutter={8} className="line-actions">
              <Col>
                <Tooltip title="ADD">
                  <Button size="small" type="primary" onClick={() => addTemplate(id)}>+</Button>
                </Tooltip>
              </Col>
            </Row>
          );
        },
        width: "10%",
      });
    
      // remove sorter
      this.columns = this.columns.map((column) => {
        if (column.sorter) {
          delete column.sorter;
          delete column.sortDirections;
        }
        return column;
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default TemplatesList;

TemplatesList.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  createTemplateOrder: PropTypes.func.isRequired,
};

TemplatesList.defaultProps = {
  handleDelete: () => {},
  handleEdit: () => {},
  pagination: {
    position: "none",
  },
  createTemplateOrder: () => {},
};
