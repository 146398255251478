/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Spin,
  Button,
  Popconfirm,
  Form,
  Input,
  Select,
  InputNumber,
} from '../../common/UIComponents'
import {
  alertMessage,
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import { saveGrossOrdersData } from '../../api/Reports'
import BaseSelect from '../common/SelectDropdowns/BaseSelect'

const { Option } = Select
class AddOrdersToWeeklyReport extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      // pagination: {},
      inProgress: false,
      currentRecord: this.props.currentRecord || {},
      errors: [],
      drivers: this.props.drivers || [],
      deductions: this.props.deductions,
      decimalPoints,
      zeroValue,
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    // this.getDeductions()
    if (this.state.currentRecord.id) {
      // this.props.form.setFieldsValue({...this.state.currentRecord});
    }
  }

  getDeductions = () => {
    this.setState({ inProgress: true })
    const orgId = userStore.getStateValue('selectedOrg')
    fetchDeductions(orgId).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false })
        const deductions = result.deductions || []
        this.setState({
          deductions: deductions.filter((rec) => !isEmpty(rec.standard_code)),
        })
      } else {
        this.setState({ inProgress: false, deductions: [] })
      }
    })
  }

  handleFormChange = (element, value) => {
    const currentRecord = Object.assign({}, this.state.currentRecord, {
      [element]: value,
    })
    if (element === 'deduction_master_id') {
      const deductionRec = _.find(this.state.deductions, { id: value })
      if (!isEmpty(deductionRec) && !isEmpty(value)) {
        currentRecord.deduction_name = deductionRec.deduction_name
        currentRecord.gl_code = deductionRec.gl_code
      } else {
        currentRecord.deduction_name = ''
        currentRecord.gl_code = ''
      }
    }
    this.setState({
      currentRecord,
    })
  }

  handleSaveDetails = (fieldsValue) => {
    // this.props.setLoading(true);
    const { zeroValue } = this.state;
    const grossOrders = [...this.props.grossOrders];
    const existingRecords = grossOrders.map(record => (Object.assign({}, {
        id: record.id,
        gross_revenue: !isEmpty(record.gross_revenue) ? record.gross_revenue : zeroValue,
        gross_pay: !isEmpty(record.gross_pay) ? record.gross_pay : zeroValue,
      })));
    const newRecord = {...fieldsValue};
    const data = {
      driver_id: this.props.driverId,
      driver_pay_report_id: this.props.driverPayId,
      gross_order_reports: [...existingRecords, {...newRecord}],
    };
    this.setState({ inProgress: true });
    saveGrossOrdersData(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({ inProgress: false });
          this.props.handleResponse(result, true, true, true);
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({
            errors: result.errors,
            inProgress: false,
          });
        }
      });
  }

  getFields() {
    const count = this.state.expand ? 10 : 6;
    const {accounts} = this.props;
    const { getFieldDecorator } = this.props.form
    const size = this.props.size;

    const { currentRecord, drivers, deductions, decimalPoints } = this.state

    const children = [
      <Row gutter={16} >
      <Col span={6}>
        <Form.Item label={I18n.t('order.order_no')}>
           {getFieldDecorator('customer_order_number', {
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('order.order_no')}`,
              },
            ],
          })(
            <Input
              size={size}
              onChange={(e) => this.handleFormChange('customer_order_number', e.target.value)}
              className="width100Per"
              autoFocus={true}
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={6}>
        <Form.Item label={I18n.t('order.customer_name')}>
          {getFieldDecorator('consignee_name', {
            initialValue: currentRecord.consignee_name ? currentRecord.consignee_name : '',
            rules: [
              {
                required: false,
                message: `Enter ${I18n.t('order.customer_name')}`,
              },
            ],
          })(
            <Input
              size={size}
              onChange={(e) => this.handleFormChange('consignee_name', e.target.value)}
              className="width100Per"
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={11}>
        <Form.Item label={I18n.t('account.account')}>
          {getFieldDecorator('account_code', {
            initialValue: currentRecord.account_code ? currentRecord.account_code : (accounts.length > 0 ? accounts[0].code : ''),
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('account.code')}`,
              },
            ],
          })(
            <BaseSelect
              data={accounts}
              onChange={(e) => {
                this.handleFormChange('account_code', e)
              }}
              selectKey={'code'}
              selectValue={'code'}
              selectedValue={'name'}
              size={size}
              className="width100Per"
              showSearch={true}
            />
          )}
        </Form.Item>
      </Col>,
      </Row>,
      <Col span={6}>
        <Form.Item label={`${I18n.t('reports.gross_revenue')} (${I18n.t('general.price_symbol')})`}>
          {getFieldDecorator('gross_revenue', {
            initialValue: currentRecord.gross_revenue ? currentRecord.gross_revenue : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('reports.gross_revenue')}`,
              },
            ],
          })(
            <InputNumber
              size={size}
              onChange={(e) => this.handleFormChange('gross_revenue', e)}
              precision={decimalPoints}
              className="width100Per"
              min={0}
              addOnBefore={"$"}
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={6}>
        <Form.Item label={`${I18n.t('reports.gross_pay')} (${I18n.t('general.price_symbol')})`}>
          {getFieldDecorator('gross_pay', {
            initialValue: currentRecord.gross_pay ? currentRecord.gross_pay : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('reports.gross_pay')}`,
              },
            ],
          })(
            <InputNumber
              size={size}
              onChange={(e) => this.handleFormChange('gross_pay', e)}
              precision={decimalPoints}
              className="width100Per"
              min={0}
              addOnBefore={"$"}
            />,
          )}
        </Form.Item>
      </Col>,

      // <Col span={16}>
      //   <Form.Item label={I18n.t('customer.address')}>
      //     {getFieldDecorator('consignee_address', {
      //       initialValue: currentRecord.consignee_address
      //         ? currentRecord.consignee_address
      //         : '',
      //       rules: [
      //         {
      //           required: true,
      //           message: `Enter ${I18n.t('customer.address')}`,
      //         },
      //       ],
      //     })(
      //       <Input
      //         size={size}
      //         onChange={(e) => this.handleFormChange('consignee_address', e)}
      //         className="width100Per"
      //       />,
      //     )}
      //   </Form.Item>
      // </Col>,


    ]
    return children
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.handleSaveDetails(values)
      }
    })
  }

  render() {
    return (
      <div className="content-outer">
        <div>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Form
              className="ant-advanced-search-form customForm"
              onSubmit={this.handleSearch}
            >
              <Row gutter={24} className="fontSize12">{this.getFields()}</Row>
              <div className='alignRight'>
               <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.cancel_confirm')}
                  onConfirm={this.props.onCancel}
                  okText="Yes"
                  cancelText="No"
                >
                 <Button
                  style={{ marginRight: 8 }}
                  type="danger"
                  size="small"
                  icon='close'
                  >
                    Cancel
                </Button>
                </Popconfirm>
              
               <Button type="primary" htmlType="submit" size="small" icon='plus'>
                    {I18n.t('general.add')}
                  </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    )
  }
}
AddOrdersToWeeklyReport.propTypes = {
  account: PropTypes.shape().isRequired,
}
const WrappedForm = Form.create({ name: 'horizontal_login' })(AddOrdersToWeeklyReport)

export default WrappedForm