import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FormItem, DatePicker, Button } from "../../common/UIComponents";
import { fetchOrgAccounts, fetchShortFormAccounts } from "../../api/Account";
import AppConfig from "../../config/AppConfig";
import AccountCodesFilter from "../../containers/Filters/AccountCodesFilter";
import WarehouseCodeFilter from "../common/WarehouseCodeFilter";
import I18n from "../../common/I18n";
import _ from "lodash";

const { RangePicker } = DatePicker;
const defaultFilterValues = {
  fromDate: moment(),
  toDate: moment(),
  accountCodes: [],
  warehouseIds: [],
};
class StatusReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || Object.assign({}, defaultFilterValues),
      accounts: this.props.accounts,
      filteredAccounts: [],
      accountsLoading: false,
    };
  }

  componentDidMount() {
    // this.getAccounts();
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.handleFilterPlaceHolderChange("warehouseIds", currentWhId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.setState({ filter: _.cloneDeep(this.props.filter) });
    }
  }

  // getAccounts = () => {
  //   const { warehouseFilter } = this.props;
  //   const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
  //     ? warehouseFilter.selectedWarehouses
  //     : warehouseFilter?.selectedWarehouses
  //     ? [warehouseFilter.selectedWarehouses]
  //     : null;

  //   this.setState({
  //     accountsLoading: true,
  //   });
  //   const { currentOrg = {} } = this.props.orgData;
  //   const orgId = currentOrg.id;
  //   fetchOrgAccounts(orgId).then((result) => {
  //     if (result.success) {
  //       this.setState(
  //         {
  //           accounts: result.accounts || [],
  //           accountsLoading: false,
  //         },
  //         () => {
  //           this.handleFilterPlaceHolderChange("warehouseIds", currentWhId);
  //         }
  //       );
  //     } else {
  //       this.setState({
  //         accountsLoading: false,
  //         accounts: [],
  //       });
  //     }
  //   });
  // };

  setFilteredAccounts = (filteredAccount = []) => {
    const { accountCodes } = this.state.filter;
    const filteredAccCodes =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.code) : [];

    if (accountCodes?.length) {
      const filteredAccountCodes = filteredAccCodes.filter((acc_code) =>
        accountCodes.includes(acc_code)
      );
      this.handleFilterPlaceHolderChange("accountCodes", filteredAccountCodes);
    } else {
      this.handleFilterPlaceHolderChange("accountCodes", []);
    }
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function") {
        this.props.handleChildFilterChange(filter);
      }
    });
  };

  handleDateRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: moment(),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.setState({
      filter: dateFilter,
    });
  };

  handleFilterPlaceHolderChange = (element, value) => {
    const filter = Object.assign({}, this.state.filter, {
      [element]: value,
    });

    this.setState(
      {
        filter,
      },
      () => {
        if (element === "warehouseIds") {
          const { accounts } = this.state;
          const { warehouses } = this.props.warehouseFilter;
          const { warehouseIds } = this.state.filter;

          if (warehouseIds.length === 0) {
            const filteredAccounts = accounts;
            this.setState({ filteredAccounts });
          } else {
            const filteredAccounts = accounts.filter((account) =>
              account.warehouse_ids.some((id) => warehouseIds.includes(id))
            );
            this.setState({ filteredAccounts }, () => {
              this.setFilteredAccounts(filteredAccounts);
            });
          }
        }
      }
    );
  };

  clearForm = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const filter = {
      fromDate: moment(),
      toDate: moment(),
      accountCodes: [],
      warehouseIds: currentWhId,
    };
    this.setState({ filter }, () => {
      this.handleFilterPlaceHolderChange("warehouseIds", currentWhId);
      this.props.onChange(filter, () => {
        this.props.onSearch();
      });
    });
  };

  _renderRangePicker = () => {
    return (
      <FormItem label="Date Range">
        <RangePicker
          value={[this.state.filter.fromDate, this.state.filter.toDate]}
          ranges={AppConfig.dateRanges}
          format={AppConfig.dateFormat}
          style={{ width: "100%" }}
          allowClear
          onChange={this.handleDateRangeChange}
        />
      </FormItem>
    );
  };

  _renderWhFilter = () => {
    const { warehouses } = this.props.warehouseFilter;
    return (
      <FormItem label={I18n.t("location.location")}>
        <WarehouseCodeFilter
          SelectedCodes={this.state.filter.warehouseIds}
          element={"warehouseIds"}
          onValueChange={this.handleFilterPlaceHolderChange}
          warehouses={warehouses}
          size="default"
        />
      </FormItem>
    );
  };

  handleSearch = (isExport = false) => {
    this.props.onSearch(this.state.filter, isExport);
  };

  render() {
    return (
      <Fragment>
        <div>
          {this._renderWhFilter()}
          <AccountCodesFilter
            SelectedAccountCodes={this.state.filter.accountCodes}
            accounts={this.state.filteredAccounts}
            size="default"
            onValueChange={this.handleFilterPlaceHolderChange}
            showLabel={true}
            valueField="code"
            styleObject={{ width: "100%", marginBottom: 5 }}
          />
          {this._renderRangePicker()}
          <div style={{ padding: "10px 16px", textAlign: "center" }}>
            <Button
              type="danger"
              onClick={this.clearForm}
              icon="redo"
              disabled={this.props.disableButton}
            >
              Reset
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              onClick={() => this.handleSearch()}
              type="primary"
              icon="search"
              disabled={this.props.disableButton}
            >
              Search
            </Button>
            &nbsp;&nbsp;
            {
              !this.props.hideExport ? (
                <Button
                  onClick={() => this.handleSearch(true)}
                  type="primary"
                  icon="export"
                  className="buttonTeal"
                  disabled={this.props.disableButton}
                >
                  Export
                </Button>
              ) : null
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

StatusReportFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
StatusReportFilter.defaultProps = {
  hideExport: false,
};
export default withRouter(StatusReportFilter);
