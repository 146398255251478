import { Row, Modal } from "antd";
import React, { Component } from "react";
import {
	AccountInstructionsApi,
	instructionType,
	OrgInstructionsApi,
} from "../../api/InstructionsApi";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import userStore from "../../stores/UserStore";
import InstructionsForm from "./InstructionsForm";
import InstructionTypeForm from "./InstructionTypeForm";
import PropTypes from "prop-types";
import BaseModal from "../BaseModal";
import { checkServiceExistance } from "../../helpers/common";
import InstructionTypes from "./InstructionTypes";
import InstructionsTable from "./InstructionsTable";
import AppConfig from "../../config/AppConfig";

const { confirm } = Modal;

class Instructions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			instructionsData: [],
			isLoading: false,
			account: this.props.account,
			isOrgLevelInstruction: this.props.isOrgLevelInstruction,
			isInstructionTypeModalOpen: false,
			usedCodes: [],
			instructionTypesData: [],
			activeInstructionTypeId: null,
			activeInstruction: null,
			isInstructionModalOpen: false,
			pagination: {},
			filteredInstructionTypesData: [],
			fullInstructionTypesData: [],
			filteredInstructionsData: [],
			instructionTypesPagination: {
				current: 1,
				pageSize: AppConfig.perPage,
				total: 0,
			},
			instructionsPagination: {
				current: 1,
				pageSize: AppConfig.perPage,
				total: 0,
			},
		};
	}

	componentDidMount() {
		if(checkServiceExistance('INSI')) {
			this.getFullInstructionTypes();
		}
		 // get all the instruction types
		this.props.isOrgLevelInstruction && this.getInstructionTypes(); // get all the instruction types with pagination
		this.getInstructions();
	}
	onDeleteType = (data) => {
		const { id } = data;
		instructionType.delete(id).then((result) => {
			if (result.success) {
				alertMessage(I18n.t("instructions.modal.deleteSuccess"), "success");

				// find data.code in usedCodes,filteredInstructionsData arrays and remove it
				const usedCodesIndex = this.state.usedCodes.indexOf(data.code);
				const filteredIndex = this.state.filteredInstructionsData.findIndex(
					(instruction) => instruction.code === data.code
				);
				if (filteredIndex > -1) {
					this.state.filteredInstructionsData.splice(filteredIndex, 1);
				}
				if (usedCodesIndex > -1) {
					this.state.usedCodes.splice(usedCodesIndex, 1);
				}

				this.setState({
					fullInstructionTypesData: this.state.fullInstructionTypesData.filter(
						(instructionType) => instructionType.id !== id
					),
					filteredInstructionTypesData:
						this.state.filteredInstructionTypesData.filter(
							(instructionType) => instructionType.id !== id
						),
					usedCodes: this.state.usedCodes,
					filteredInstructionsData: this.state.filteredInstructionsData,
				});
			} else {
				result.errors.map((error) => {
					alertMessage(error, "error");
				});
			}
		});
	};

	handleInstructionTypeDelete = (data) => {
		confirm({
			title: "Do you want to delete this Instruction Type? ",
			content: <span className="">{"Do you want to proceed ?"}</span>,
			onOk: () => {
				this.onDeleteType(data);
			},
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onCancel: () => {},
		});
	};

	handleInstructionTypeEdit = (data) => {
		const { id } = data;
		this.setState({
			isInstructionTypeModalOpen: true,
			activeInstructionTypeId: id,
		});
	};
	handleInstructionUpdate = (id) => {
		this.setState({
			activeInstruction: id,
		});
		this.setState({
			isInstructionModalOpen: true,
		});
	};
	closeInstructionTypeModal = () => {
		this.setState({
			isInstructionTypeModalOpen: false,
			activeInstructionTypeId: null,
		});
	};
	closeInstructionModal = () => {
		this.setState({
			isInstructionModalOpen: false,
			activeInstruction: null,
		});
	};
	renderInstructionModal = () => {
		const {
			activeInstructionTypeId,
			filteredInstructionsData,
			fullInstructionTypesData,
			activeInstruction,
		} = this.state;
		return (
			<BaseModal
				title={
					this.state.activeInstruction
						? I18n.t("instructions.modal.edit_instruction")
						: I18n.t("instructions.modal.create_instruction")
				}
				className="instruction-modal"
				onCancel={this.closeInstructionModal}
			>
				<InstructionsForm
					instructionTypeId={activeInstructionTypeId}
					instructionsData={filteredInstructionsData}
					instructionTypesData={fullInstructionTypesData}
					appendUsedCode={this.appendUsedCode}
					account={this.state.account}
					activeInstructionId={activeInstruction}
					closeInstructionModal={this.closeInstructionModal}
					addInstruction={this.addInstruction}
					updateInstruction={this.updateInstruction}
					isOrgLevelInstruction={this.state.isOrgLevelInstruction}
					isUsedCode={this.isUsedCode}
				/>
			</BaseModal>
		);
	};

	renderInstructionTypeModal = () => {
		return (
			<BaseModal
				title={
					this.state.activeInstructionTypeId
						? I18n.t("instructions.type.modal.edit")
						: I18n.t("instructions.type.modal.create")
				}
				className="addInstructionTypeModal"
				onCancel={() => {
					this.setState({
						isInstructionTypeModalOpen: false,
						activeInstructionTypeId: null,
					});
				}}
			>
				<InstructionTypeForm
					account={this.state.account}
					onCancel={this.closeInstructionTypeModal}
					activeInstructionTypeId={this.state.activeInstructionTypeId}
					instructionTypesData={this.state.fullInstructionTypesData}
					addInstructionType={this.addInstructionType}
					updateInstructionType={this.updateInstructionType}
				/>
			</BaseModal>
		);
	};

	getInstructions = (currentPage = 1, sortBy = "code", sortOrder = "ASC") => {
		const organization_id = userStore.getStateValue("selectedOrg");
		const accountId = this.state.account.id;
		this.setState({ isLoading: true });

		this.state.isOrgLevelInstruction
			? OrgInstructionsApi.fetch(
					organization_id,
					true,
					currentPage,
					sortBy,
					sortOrder
			  )
					.then((result) => {
						if (result.success) {
							this.setState({
								filteredInstructionsData: result.organization_instructions,
								instructionsPagination: result.pagination,
								usedCodes: result.organization_instructions.map(
									(instruction) => instruction.code
								),
							});
						} else {
							if (result.errors) {
								result.errors.map((error) => {
									alertMessage(error, "error");
								});
							}
						}
					})
					.finally(() => {
						this.setState({ isLoading: false });
					})
			: AccountInstructionsApi.fetch(
					organization_id,
					accountId,
					true,
					currentPage,
					sortBy,
					sortOrder
			  )
					.then((result) => {
						if (result.success) {
							this.setState({
								filteredInstructionsData: result.account_instructions,
								instructionsPagination: result.pagination,
								usedCodes: result.account_instructions.map(
									(instruction) => instruction.code
								),
							});
						} else {
							if (result.errors) {
								result.errors.map((error) => {
									alertMessage(error, "error");
								});
							}
						}
					})
					.finally(() => {
						this.setState({ isLoading: false });
					});
	};

	getFullInstructionTypes = () => {
		// here full refers to all the instruction types i.e all the instruction types without pagination
		this.setState({ isLoading: true });
		const organization_id = userStore.getStateValue("selectedOrg");
		instructionType
			.fetch(organization_id, false)
			.then((result) => {
				if (result.success) {
					this.setState({
						fullInstructionTypesData: result.instruction_types,
					});
				} else {
					if (result.errors) {
						result.errors.map((error) => {
							alertMessage(error, "error");
						});
					}
				}
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	getInstructionTypes = (
		currentPage = 1,
		sortBy = "name",
		sortOrder = "ASC"
	) => {
		const organization_id = userStore.getStateValue("selectedOrg");
		this.setState({ isLoading: true });

		instructionType
			.fetch(organization_id, true, currentPage, sortBy, sortOrder)
			.then((result) => {
				if (result.success) {
					this.setState({
						filteredInstructionTypesData: result.instruction_types,
						instructionTypesPagination: result.pagination,
					});
				} else {
					if (result.errors) {
						result.errors.map((error) => {
							alertMessage(error, "error");
						});
					}
				}
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};
	openInstructionTypeModal = () => {
		this.setState({ isInstructionTypeModalOpen: true });
	};
	openInstructionModal = () => {
		this.setState({
			isInstructionModalOpen: true,
		});
	};
	addUsedCode = (code) => {
		this.setState({ usedCodes: [...this.state.usedCodes, code] });
	};
	isUsedCode = (code) => {
		return this.state.usedCodes.includes(code);
	};
	deleteInstruction = (instructionId) => {
		const { filteredInstructionsData } = this.state;
		const newInstructionsList = filteredInstructionsData.filter(
			(instruction) => instruction.id !== instructionId
		);
		const deletedInstruction = filteredInstructionsData.find(
			(instruction) => instruction.id === instructionId
		);
		const newUsedCodes = this.state.usedCodes.filter(
			(code) => code !== deletedInstruction.code
		);
		this.setState({
			filteredInstructionsData: newInstructionsList,
			usedCodes: newUsedCodes,
		});
	};
	onDeleteInstruction = (instructionId) => {
		this.setState({ isLoading: true });
		this.state.isOrgLevelInstruction
			? OrgInstructionsApi.delete(instructionId)
					.then((result) => {
						if (result.success) {
							this.deleteInstruction(instructionId);
							alertMessage(
								I18n.t("instructions.modal.deleteSuccess"),
								"success"
							);
						} else {
							if (result.errors) {
								result.errors.map((error) => {
									alertMessage(error, "error");
								});
							}
						}
					})
					.finally(() => {
						this.setState({ isLoading: false });
					})
			: AccountInstructionsApi.delete(instructionId)
					.then((result) => {
						if (result.success) {
							this.deleteInstruction(instructionId);
							alertMessage(
								I18n.t("instructions.modal.deleteSuccess"),
								"success"
							);
						} else {
							if (result.errors) {
								result.errors.map((error) => {
									alertMessage(error, "error");
								});
							}
						}
					})
					.finally(() => {
						this.setState({ isLoading: false });
					});
	};
	handleInstructionDeletion = (instructionId) => {
		confirm({
			title: "Do you want to delete this Instruction? ",
			content: <span className="">{"Do you want to proceed ?"}</span>,
			onOk: () => {
				this.onDeleteInstruction(instructionId);
			},
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onCancel: () => {},
		});
	};

	addInstruction = (instruction) => {
		const newInstructionsList = [
			instruction,
			...this.state.filteredInstructionsData,
		];
		this.setState({
			filteredInstructionsData: newInstructionsList,
			isInstructionModalOpen: false,
			usedCodes: [...this.state.usedCodes, instruction.code],
		});
	};
	updateInstruction = (instruction) => {
		const newInstructionsList = this.state.filteredInstructionsData.map(
			(item) => {
				if (item.id === instruction.id) {
					return {
						...item,
						...instruction,
					};
				}
				return item;
			}
		);
		this.setState({
			filteredInstructionsData: newInstructionsList,
			isInstructionModalOpen: false,
			activeInstruction: null,
		});
	};

	addInstructionType = (instructionType) => {
		this.setState({
			filteredInstructionTypesData: [
				...this.state.filteredInstructionTypesData,
				instructionType,
			],
			fullInstructionTypesData: [
				...this.state.fullInstructionTypesData,
				instructionType,
			],
			isInstructionTypeModalOpen: false,
		});
	};

	updateInstructionType = (data) => {
		const { fullInstructionTypesData, filteredInstructionTypesData } =
			this.state;
		const newInstructionTypesData = fullInstructionTypesData.map(
			(instructionType) => {
				if (instructionType.id === data.id) {
					return data;
				}
				return instructionType;
			}
		);
		const newFilteredInstructionTypesData = filteredInstructionTypesData.map(
			(instructionType) => {
				if (instructionType.id === data.id) {
					return data;
				}
				return instructionType;
			}
		);

		this.setState({
			fullInstructionTypesData: newInstructionTypesData,
			filteredInstructionTypesData: newFilteredInstructionTypesData,
			isInstructionTypeModalOpen: false,
			activeInstructionTypeId: null,
		});
	};
	render() {
		const {
			isLoading,
			isOrgLevelInstruction,
			isInstructionTypeModalOpen,
			isInstructionModalOpen,
			fullInstructionTypesData,
			filteredInstructionTypesData,
			instructionTypesPagination,
			instructionsPagination,
			filteredInstructionsData,
			instructionTypesData
		} = this.state;
		const instructionTypesListPagination = {
			total: instructionTypesPagination.total_count,
			current: instructionTypesPagination.current_page,
			pageSize: instructionTypesPagination.per_page || AppConfig.perPage,
		};
		const instructionsListPagination = {
			total: instructionsPagination.total_count,
			current: instructionsPagination.current_page,
			pageSize: instructionsPagination.per_page || AppConfig.perPage,
		};

		return (
			<div
				className="instructionsWrapper"
				style={{
					padding: "0 20px",
				}}
			>
				<Row
					gutter={isOrgLevelInstruction ? 320 : 0}
					type="flex"
					justify="space-between"
				>
					{ isOrgLevelInstruction && (
						<InstructionTypes
							isLoading={isLoading}
							openInstructionTypeModal={this.openInstructionTypeModal}
							filteredInstructionTypesData={filteredInstructionTypesData}
							handleInstructionTypeEdit={this.handleInstructionTypeEdit}
							handleInstructionTypeDelete={this.handleInstructionTypeDelete}
							pagination={instructionTypesListPagination}
							getInstructionTypes={this.getInstructionTypes}
							isOrgLevelInstruction={isOrgLevelInstruction}
						></InstructionTypes>
					)}
					<InstructionsTable
						isLoading={isLoading}
						openInstructionModal={this.openInstructionModal}
						isOrgLevelInstruction={isOrgLevelInstruction}
						handleInstructionUpdate={this.handleInstructionUpdate}
						handleInstructionDeletion={this.handleInstructionDeletion}
						fullInstructionTypesData={fullInstructionTypesData}
						filteredInstructionsData={filteredInstructionsData}
						pagination={instructionsListPagination}
						getInstructions={this.getInstructions}
					></InstructionsTable>
				</Row>
				{isInstructionModalOpen && this.renderInstructionModal()}
				{isInstructionTypeModalOpen &&
					isOrgLevelInstruction &&
					this.renderInstructionTypeModal()}
			</div>
		);
	}
}
export default Instructions;

Instructions.propTypes = {
	account: PropTypes.object,
	isOrgLevelInstruction: PropTypes.bool,
};

Instructions.defaultProps = {
	account: {},
	isOrgLevelInstruction: false,
};
