import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popover } from "../../common/UIComponents";
import { withRouter } from "react-router";
import userStore from "../../stores/UserStore";
import { checkNegitive } from "../../common/Common";
import BaseList from "../BaseList";
import CopyableContent from "../common/CopyableContent";
import I18n from "../../common/I18n";

const Remitances = ({ data = [], pagination = {} }) => {
  const columns = [
    {
      title: I18n.t("batch_management.check_no"),
      dataIndex: "check_number",
      key: "check_number",
      render: (data) => <CopyableContent text={data} />,
      width: 110,
    },
    {
      title: I18n.t("batch_management.remittances_amt"),
      dataIndex: "remittance_amount",
      key: "remittances_amt",
      width: 140,
      render: (data) => (data ? checkNegitive(data) : ""),
      align: "right",
    },
    // {
    //   title: I18n.t("batch_management.remittances_cleared"),
    //   dataIndex: "cleared_amount",
    //   key: "cleared_amount",
    //   render: (data) => (data ? checkNegitive(data) : ""),
    //   width: 140,
    //   align: "right",
    // },
  ];
  return (
    <BaseList
      columns={columns}
      data={data}
      size="small"
      pagination={{ ...pagination }}
      scroll={{ y: 200  }}
    />
  );
};

const InvoiceRemitances = ({ remittances = [] }) => {
  const checkNos = _.uniq(remittances.map((rec) => rec.check_number));
  return checkNos.length > 0 ? (
    <Popover
      content={
        <div style={{ width: 260, maxWidth: 260, textAlign: 'center' }}>
          <Remitances data={remittances} pagination={{ position: "none" }} />
        </div>
      }
      title={I18n.t("batch_management.remittances")}
      getPopupContainer={(triggerNode) =>
        triggerNode.parentNode
      }>
      <u className="text-decoration-dashed cursorPointer">{checkNos[0]} {checkNos.length > 1 && <span> ,+{checkNos.length - 1}</span>}</u>
    </Popover>
  ) : (
    <Fragment />
  );
};

InvoiceRemitances.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(InvoiceRemitances);
