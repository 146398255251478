import React, { useContext, useEffect, useState } from "react";
import { setOrganizationConfig } from "../../api/Organisations";
import {
  Button,
  Icon,
  Row,
  Col,
  Spin,
  TextArea,
} from "../../common/UIComponents";
import userStore from "../../stores/UserStore";
import { alertMessage } from "../../common/Common";
import { fetchOrganizationConfigs } from "../../api/Organisations";
import { OrgContext } from "../../context/OrgContext";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";

const Disclaimer = (props) => {
  const [org_quote_disclaimer, setDisclaimer] = useState("");
  const [config, setConfig] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [previousConfig, setPreviousConfig] = useState({});
  const { setOrgSettings  }= useContext(OrgContext);

  useEffect(() => {
    getOrganizationConfigs();
  }, []);

  const getOrganizationConfigs = () => {
    setInProgress(true);
    const CONFIG = config;
    const PREVCONFIG = previousConfig;
    const orgId = userStore.getStateValue("selectedOrg");
    fetchOrganizationConfigs(orgId).then((result) => {
      if (result.success) {
        const orgConfigurations = result.org_configurations || [];
        orgConfigurations.forEach((setting) => {
          CONFIG[setting.setting_name] = setting.setting_value;
        });
        setInProgress(false);
        setConfig({...CONFIG});
        setPreviousConfig({...CONFIG});
      } else {
        renderAlertMessage(result.errors)
        setInProgress(false);
      }
    });
  };

  const handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  const handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    setInProgress(true);
    const data = {
      organization_config: config,
    };
    setOrganizationConfig(data, orgId).then((result) => {
      if (result.success) {
        setInProgress(false);
        setConfig(
          Object.assign({}, config, {
            [result.org_config.org_config.setting_name]:
              result.org_config.org_config.setting_value,
          })
        );
        setOrgSettings(config);
        //window.location.reload();
        handleSaveSuccess(result.org_config.message);
      } else {
        renderAlertMessage(result.errors)
        setInProgress(false);
      }
    });
  };

  const renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => handleSave()}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  const handleChange = (element, value) => {
    setDisclaimer(value);
    setConfig(Object.assign({}, config, { [element]: value }));
  };

  const renderConfig = () => {
    return (
      <Spin spinning={inProgress} delay={1000}>
        <Row style={{ margin: "10px 10px" }}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={4} lg={3} className="textBold">
                {I18n.t("quotes.quote_disclaimer")}&nbsp;:
              </Col>
              <Col xs={24} sm={24} md={19} lg={19}>
                <div style={{ display: "flex" }}>
                  <TextArea
                    rows={2}
                    placeholder={I18n.t("menu.disclaimer")}
                    style={{ width: '85%' }}
                    value={config.org_quote_disclaimer}
                    onChange={(e) =>
                      handleChange("org_quote_disclaimer", e.target.value)
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={4} lg={3} className="textBold">
                {I18n.t("quotes.invoice_disclaimer")}&nbsp;:
              </Col>
              <Col xs={24} sm={24} md={19} lg={19}>
                <div style={{ display: "flex" }}>
                  <TextArea
                    rows={2}
                    placeholder={I18n.t("menu.disclaimer")}
                    style={{ width: '85%' }}
                    value={config.org_invoice_disclaimer}
                    onChange={(e) =>
                      handleChange("org_invoice_disclaimer", e.target.value)
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  };

  return (
    <div>
      {renderConfig()}
      {renderFloatingBtn()}
    </div>
  );
};
export default Disclaimer;
