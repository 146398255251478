import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popconfirm, Row, Col } from '../../../common/UIComponents';
import { checkServiceExistance } from '../../../helpers/common';
import DeleteIcon from '../../common/DeleteIcon';
import I18n from '../../../common/I18n';

const ListRows = ({
  data, deleteClick, editClick,
}) => (
  data.map((record, index) => (
    <Row
      key={`row${record.id}`}
      className={`borderBottom ${index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}`}
      onClick={() => checkServiceExistance('DZU') ? editClick(record.id) : null }
    >
      <Col xs={18} className="cursorPointer">
        <u className="text-decoration-dashed">{record.name}</u>
      </Col>
      <Col xs={6}>
        { checkServiceExistance('DZD') && <div className='line-actions'>
          <Popconfirm
            placement="topRight"
            title={I18n.t('messages.delete_confirm')}
            onConfirm={() => deleteClick(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteIcon/>
            &nbsp;&nbsp;&nbsp;
          </Popconfirm>
        </div>
        }
      </Col>
    </Row>
  ))
);
ListRows.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default ListRows;
