import { Tabs } from "antd";
import React from "react";
import ListDispatch from "./ListDispatch";
import DispatchStopOrders from "./DispatchStopOrders";
import { DispatchStops } from "./DispatchStops";
import I18n from "../../../common/I18n";
import ManifestFileList from "./ManifestFile";
import ManifestIncludeItemsList from "./ManifestIncludeItems";

export const DispatchViewList = (props) => {
  const onChange = (key) => {
  };
  return (
    <div className="card-container tabHover">
      <Tabs
        defaultActiveKey="UnallocatedOrders"
        type="card"
        onChange={onChange}
      >
        <Tabs.TabPane
          tab={I18n.t("menu.dispatch_unallocated")}
          key="UnallocatedOrders"
        >
          <ListDispatch dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={I18n.t("menu.dispatch_stops")}
          key="Stops"
        >
          <DispatchStops dispContext={props.dispContext}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("menu.dispatch_stopOrders")} key="StopOrders">
          <DispatchStopOrders dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t('general.manifest_orders')} key="ManifestFile">
          <ManifestFileList dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t('general.manifest_items')} key="ManifestIncludeItems">
          <ManifestIncludeItemsList dispContext={props.dispContext} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
