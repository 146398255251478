import React from "react";
import BaseList from "../BaseList";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import moment from "moment";
import {
  Icon,
} from "../../common/UIComponents";
import { formatByTimeConfig } from "../../helpers/common";

class RoutesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "r_name",
        title: I18n.t("general.name"),
        dataIndex: "route_name",
        render: (text, data) => (
          <div className="blkCenterLeft">
            {text} &nbsp;
            <Icon
              type="check-circle"
              className="fontSize18"
              theme="twoTone"
              twoToneColor={
                !isEmpty(data.route_confirmed_at) ? "#52C41A" : "grey"
              }
              style={{ marginRight: 8 }}
            />
          </div>
        ),
      },
      {
        key: "driver",
        title: I18n.t("general.Driver"),
        render: (data) => <div>{data.displayName}</div>,
      },
      {
        key: "warehouse",
        title: I18n.t("location.location"),
        dataIndex: "warehouse_details",
        render: (data) => (
          <div>{data && _.isObject(data) ? data.name : ""}</div>
        ),
      },
      {
        key: "starts_at",
        title: I18n.t("routes.starts_at"),
        dataIndex: "route_start_datetime",
        render: (starts_at) => (
          <div>{starts_at ? formatByTimeConfig(moment(starts_at) , props.isMilitaryTime , "HH:mm" , "hh:mm A" ) : "NA"}</div>
        ),
      },
      {
        key: "Stops",
        title: I18n.t("preplan.stops"),
        render: (data) => <div>{!isEmpty(data.stops) ? data.stops : 0}</div>,
        align: "center",
      },
      {
        key: "Orders",
        title: I18n.t("menu.orders"),
        render: (data) => (
          <div>{data.customer_orders ? data.customer_orders : 0}</div>
        ),
        align: "center",
      },
      {
        key: "time",
        title: I18n.t("general.travel_time"),
        dataIndex: "total_time",
      },
      {
        key: "distance",
        title: I18n.t("general.distance"),
        dataIndex: "total_distance",
      },
      {
        key: "r_status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        align: "center",
      },
      {
        key: "created_at",
        title: I18n.t("preplan.confirmed_at"),
        //dataIndex: 'route_start_datetime',
        align: "center",
        render: (data) => (
          <div>
            {!isEmpty(data.route_confirmed_at)
              ? moment.utc(data.route_confirmed_at).format("Do MMM, YYYY")
              : ""}
          </div>
        ),
      },
      {
        key: "notified_prior_day_at",
        title: "Notified At",
        //dataIndex: "notified_prior_day_at",
        align: "center",
        render: (data) => (
          <div>
              {!isEmpty(data.notified_prior_day_at) ? formatByTimeConfig(moment(data.notified_prior_day_at) , props.isMilitaryTime , "Do MMM , HH:mm" , 'Do MMM , hh:mm a') : ""}
          </div>
        ),
      },
    ];
  }

   // on military time change , update 
   componentDidUpdate(prevProps) {
      if (prevProps.isMilitaryTime !== this.props.isMilitaryTime) {
         this.columns.find((column) => column.key === 'starts_at').render = (starts_at) => (
            <div> {starts_at ? formatByTimeConfig( moment(starts_at) , this.props.isMilitaryTime , 'HH:mm' , 'hh:mm A' ) : 'NA'} </div>
         );
      }
   }
}

export default RoutesList;
