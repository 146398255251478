import React, { Fragment, useEffect, useState } from 'react'
import I18n from '../../common/I18n'
import driverDefaultImag from '../../../assets/images/man.png'
import { ReleasesApi } from '../../api/ReleasesApi'
import { alertMessage, isEmpty } from '../../common/Common'
import refresh from '../../../assets/images/refresh.png'
import moment from 'moment'
import FormErrors from '../../components/common/FormErrors'
import { checkAccessExistance, getDriverDisplayName, getDriverFullDisplayName, renderAlertMessage } from '../../helpers/common'
import { getValueFromArrayOfObjects } from '../../helpers/array_functions'
import ReleasesList from '../dispatches/ReleasesList'
import {   
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Card,
  Spin,
  Tooltip,
  Modal,
  Link, 
  Alert,
  Icon,
  Badge
} from '../../common/UIComponents'
import { withRouter } from 'react-router'
import { navigateToRoute } from '../../helpers/routes'
import { RecoveriesApis } from '../../api/RecoveriesApi'

function ReleasesPlan(props) {
  const {
    warehouses,
    form,
    drivers,
    selectedInfo,
    filterPlaceHolder,
    onClose,
    showWarehouses,
    showReleaseDate,
    showDrivers,
    screenName,
    route_id,
    selectedReleaseLocations = [],
    defaultWhId = "",
    history
  } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [locations, setLocations] = useState([])
  const [errors, setErrors] = useState([])


  const[datesOnMount, setDatesOnMount] = useState(true)
  const[etaOnMount, setEtaOnMount] = useState(false)
  const [driverCount, setDriverCount] = useState([])  
  const[selectedOrders, setSelectedOrders] = useState({
    selectedKeys: [...selectedInfo.selectedKeys],
    selectedRows: [...selectedInfo.selectedRows],
  })

  useEffect(() => {
    checkDatesOnMount()
    checkEtaOnMount()
  }, [formData.release_date,selectedOrders.selectedRows])


  const checkEtaOnMount =() => {
    const dateList = selectedOrders.selectedRows.map((order) => {
      return moment(order.scheduled_release_time).format('YYYY-MM-DD');
    });
  
    const uniqueDates = _.uniq(dateList);
  
    const areAllDatesEqual = uniqueDates.length === 1;
    setEtaOnMount(areAllDatesEqual)
  }


  const checkDatesOnMount =(val='') => {
    const dateList = selectedOrders.selectedRows.map((order) => {
      return moment(order.scheduled_release_time).format('YYYY-MM-DD');
    });
  
    const formDate = _.compact(formData.release_date ? [!isEmpty(val) ? ( moment.isMoment(val) ? val.format('YYYY-MM-DD') : val): formData.release_date.format('YYYY-MM-DD')] : []);
    const datesBunch = [...dateList, ...formDate];
  
    const uniqueDates = _.uniq(datesBunch);
  
    const areAllDatesEqual = uniqueDates.length === 1;
    setDatesOnMount(areAllDatesEqual)
  }

  const { getFieldDecorator, getFieldValue } = form
  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        // formData.release_date = values.release_date
        if (screenName !== 'dispatch') {
          createRoute()
        } else {
          addStopsToRoute()
        }
      }
    })
  }

  const getCountDrivers = () => {
    const {releaseDate } = formData
      const dateString = moment(releaseDate).format()
      setIsLoading(true)
      RecoveriesApis.driverSelection(dateString).then(
        (result) => {
          if (result.success) {
            const driverCount = result.data || []
            setDriverCount(driverCount)
            setIsLoading(false)
          } else {
            setDriverCount([])
            setIsLoading(false)
            renderAlertMessage(result.errors)
          }
        },
      )
  }

  const getWarehouseId = () => {
    if(selectedInfo.selectedRows[0]){
      const order = selectedInfo.selectedRows[0];
      const warehouse_id = getValueFromArrayOfObjects(warehouses, 'location_code', order.warehouse_code, 'id');
      return warehouse_id;
    }
    return "";
  }

  const createRoute = () => {
    setIsLoading(true)
    const isReleasesSelected = selectedInfo.selectedKeys.length > 0
    let payload = {}
    if (isReleasesSelected) {
      // const warehouse_id = formData.wh_id && formData.wh_id!== 'ALL' ? formData.wh_id : getWarehouseId();
      const warehouse_id = formData.wh_id ? formData.wh_id : getWarehouseId();
      payload = {
        driver_ids: [formData.selectedDrivers],
        release_ids: selectedInfo.selectedKeys,
        release_date: formData.releaseDate ? formData.releaseDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        wh_location_id: warehouse_id,
        location_ids : selectedInfo.selectedRows.map(rec => rec.drop_location_id)
      }
    } else {
      payload = {
        driver_ids: formData.selectedDrivers,
        wh_location_id: formData.wh_id,
        release_date: formData.release_date.format('YYYY-MM-DD'),
        location_ids: formData.location_ids,
      }
    }
    ReleasesApi.createRoute(payload).then((result) => {
      if (result.success) {
       
        setIsLoading(false)
        if(result.route?.route){
          Modal.info({
            key: 'dispathButton',
            // title: `Route #${
            //   !isEmpty(dirver.employee_code) ? dirver.employee_code : (formatFullName(dirver))
            // } has been created`,
            title: `Route has been created`,
            content: (
              <div>
                {
                  checkAccessExistance('DISPATCH') && <Fragment> 
                 <u>
                  <Link
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={() => {
                      navigateToRoute(result.route.route, history)
                      Modal.destroyAll()
                    }}
                  >
                    Click here
                  </Link>
                </u>
                &nbsp;&nbsp; to check the details
                </Fragment>
              }
              </div>
            ),
            onOk() { onClose(true)},
          })
        }else {
          alertMessage(result.route.message)
          onClose(true)
        }
       
      } else {
        setIsLoading(false)
        setErrors(result.errors)
      }
    })
  }

  const addStopsToRoute = () => {
    const payload = {
      nav_route_id: route_id,
      location_ids: formData.location_ids,
    }
    setIsLoading(false)
    ReleasesApi.addStopsToRoute(payload).then((result) => {
      if (result.success) {
        setIsLoading(false)
        onClose(true, route_id)
      } else {
        setIsLoading(false)
        renderAlertMessage(result.errors)
      }
    })
  }

  const refreshIcon = (cb) => (
    <Fragment>
      <img
        src={refresh}
        className="cursorPointer textPrimary marginRight10 "
        onClick={cb}
        style={{ width: 15, marginTop: -3 }}
      />
    </Fragment>
  )

  useEffect(() => {
    const initialValues = {
      wh_id: filterPlaceHolder.warehouse_id === "ALL" ? defaultWhId : filterPlaceHolder.warehouse_id,
      release_date: moment(), // filterPlaceHolder.dateSearchValue || moment(),
      selectedDrivers:
        screenName === 'dispatch' && drivers.length > 0 ? [drivers[0].id] : [],
      releaseDate: moment(),
    }
    form.setFieldsValue(initialValues)
    setFormData({ ...formData, ...initialValues })
  }, [filterPlaceHolder])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  useEffect(() => {
    if (!isEmpty(formData.release_date) && !isEmpty(formData.wh_id)) {
      getReleaseLocations()
    }
  }, [formData.release_date, formData.wh_id])

  useEffect(() => {
    if (!isEmpty(formData.releaseDate)) {
      getCountDrivers()
    }
  }, [formData.releaseDate])
  const onFieldChange = (key, value) => {
    const { form } = props
    form.setFieldsValue({ [key]: value })
    updateFormData(key, value)
    if(key ==="releaseDate"){
      checkDatesOnMount(value)
    }
  }

  const updateFormData = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const getReleaseLocations = () => {
    const { wh_id, release_date } = formData
    if (!isEmpty(wh_id) && !isEmpty(release_date)) {
      const releaseDate = moment(release_date).format()
      setIsLoading(true)
      ReleasesApi.fetchReleaseLocation(wh_id, releaseDate).then(
        (result) => {
          if (result.success) {
            const locations = result.locations || []
            setLocations(locations)
            setIsLoading(false)
          } else {
            setLocations([])
            setIsLoading(false)
            renderAlertMessage(result.errors)
          }
        },
      )
    }
  }

  const driversSelected = getFieldValue('selectedDrivers')
  const isReleasesSelected = selectedInfo.selectedKeys.length > 0
  const cantBeBlank = {
    required: true,
    message: I18n.t('messages.cannot_be_blank', {
      label: I18n.t('general.field'),
    }),
  }
  const renderLocation = () => (
    <Fragment>
      {!isReleasesSelected && (
        <Form.Item label={'Location'} required>
          <Row gutter={8}>
            <Col span={22}>
              {getFieldDecorator('location_ids', {
                rules: [cantBeBlank],
              })(
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    onFieldChange('location_ids', value)
                  }}
                  mode={'multiple'}
                  placeholder="-- Select --"
                >
                  {/* <Select.Option key="warehouse" value="">
                    -- Select --
                  </Select.Option> */}
                  {locations.map((loc) => (
                    <Select.Option
                      key={loc.id}
                      value={loc.id}
                      disabled={
                        selectedReleaseLocations &&
                        selectedReleaseLocations.length && selectedReleaseLocations.includes(
                          loc.l_address.id
                        )
                      }
                    >
                      {loc.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Col>
            <Col span={2}>
              <Tooltip title={I18n.t('general.refresh')}>
                {refreshIcon(() => getReleaseLocations())}
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>
      )}
    </Fragment>
  )
  return (
    <Spin spinning={isLoading}>
      {isReleasesSelected ? (
        <div className="marginBottom20 fontSize12">
          {!etaOnMount && (
            <Alert
              message={I18n.t("general.release_message")}
              type="error"
              showIcon
              icon={<Icon type="info-circle" theme="filled" />}
              closable
            />
          )}
          {
            !datesOnMount && (
              <div className='marginTop10'>
                <Alert
                  message="Scheduled Date is not matching with the selected Release ETA's."
                  type="error"
                  showIcon
                  icon={<Icon type="info-circle" theme="filled" />}
                  closable
                />
              </div>
            )
          }
          <h4 className='fontSize13'>Selected Orders</h4>
          <div className='smallTable'>
            <ReleasesList
              data={selectedInfo?.selectedRows ? [...selectedInfo.selectedRows] : []}
              size="small"
              showMiniDetails={true}
              pagination={{ position: 'none'}}

              />
          </div>
        </div>
      ) : (
        <div>
          {/* <Row>
             <Col xs={12}>{I18n.t('locations.location')}: &nbsp; AMB</Col>
             <Col xs={12}>{I18n.t('recoveries.release_date')}:  &nbsp; 11 Aug 2022</Col>
          </Row>
          <hr/> */}
        </div>
      )}
      <div className="marginTop10">
        <Form {...formItemLayout} onSubmit={handleSubmit} layout={'vertical'}>
          {screenName !== 'dispatch' ? (
            <Fragment>
              {showWarehouses !== false && !isReleasesSelected && (
                <Form.Item label={I18n.t('location.location')} required>
                  {getFieldDecorator('wh_id', {
                    rules: [cantBeBlank],
                    initialValue: '',
                  })(
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        onFieldChange('wh_id', value)
                      }}
                      placeholder="-- Select --"
                    >
                      {/* <Select.Option key="warehouse" value="">
                    -- Select --
                  </Select.Option> */}
                      {warehouses.map((warehouse) => (
                        <Select.Option key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              )}
              {showReleaseDate !== false && !isReleasesSelected && (
                <Form.Item label={I18n.t('releases.release_date')} required>
                  {getFieldDecorator('release_date', {
                    rules: [cantBeBlank],
                  })(
                    <DatePicker
                      placeholder={I18n.t('general.select_date')}
                      format={'DD MMM YYYY'}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        onFieldChange('release_date', value)
                      }}
                      disabledDate={(current) =>
                        current && current < moment().startOf('day')
                      }
                    />,
                  )}
                </Form.Item>
              )}
              {renderLocation()}
              <Form.Item label={I18n.t('releases.schedule_date')} required>
                { getFieldDecorator('releaseDate', {
                  rules: [ cantBeBlank ],
                  initialValue: moment(),
                })(
                  <DatePicker
                    format="Do MMM YYYY"
                    style={ { width: '100%' } }
                    disabledDate={ (current) => current && current.isBefore(moment().startOf('day')) }
                    onChange={ (value) => {
                      onFieldChange('releaseDate', value)
                    }
                    }
                  />
                ) }
              </Form.Item>
              {showDrivers !== false && (
                <Form.Item label={I18n.t('general.Driver')} required>
                  {getFieldDecorator('selectedDrivers', {
                    rules: [cantBeBlank],
                    initialValue: isReleasesSelected ? '' : [],
                  })(
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) => {
                        const children = option.props.children;
                        if (children && children.length > 0) {
                          if (Array.isArray(children)) {
                            return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          } else {
                            return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }
                        }
                      }}
                      mode={isReleasesSelected ? 'default' : 'multiple'}
                      maxTagCount={3}
                      onChange={(e) => {
                        onFieldChange('selectedDrivers', e)
                      }}
                      placeholder="-- Select --"
                    >
                      {driverCount?.drivers?.map((driver) => (
                        <Select.Option key={driver.driver_id} value={driver.driver_id}>
                          {getDriverDisplayName(driver)}
                          &nbsp;&nbsp;
                          <span className="floatRight">
                            <Badge count={driver.total_stops} />
                          </span>
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              )}
              {!isEmpty(driversSelected) && driversSelected.length > 0 && (
                <div>
                  <h3>
                    {I18n.t('general.selected')}{' '}
                    {isReleasesSelected ? (
                      <Fragment> {I18n.t('general.Driver')}</Fragment>
                    ) : (
                      <Fragment> {I18n.t('general.drivers')}</Fragment>
                    )}
                  </h3>
                  <Row gutter={16}>
                    {driverCount?.drivers
                      .filter((driver) => driversSelected.includes(driver.driver_id))
                      .map((driver) => {
                        const driverImage =
                          driver.driver_image
                            ? driver.driver_image
                            : driverDefaultImag
                        return (
                          <Col xs={isReleasesSelected ? 12 : 6}>
                            <Card size="small" className="alignCenter">
                              <img src={driverImage} height="50px" />
                              <div>
                                {driver.driver_name}
                              </div>
                              <div>{driver.employee_code}</div>
                            </Card>
                          </Col>
                        )
                      })}
                  </Row>
                </div>
              )}
              <div className="alignCenter marginTop10">
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button type="primary" htmlType="submit" icon="plus-circle">
                    {I18n.t('routes.new')}
                  </Button>
                </Form.Item>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {renderLocation()}

              <Form.Item wrapperCol={{ span: 7, offset: 10 }}>
                {/* {...{
                //   labelCol: { span: 8 },
                //   wrapperCol: { span: 7, offset: 10 },
                // } */}

                <Button type="primary" htmlType="submit" size="small">
                  {I18n.t('routes.update')}
                </Button>
              </Form.Item>
            </Fragment>
          )}
        </Form>
        <div>{FormErrors(errors)}</div>
      </div>
    </Spin>
  )
}
const ReleasesPlanForm = withRouter(Form.create({ name: 'release_plan_form' })(
  ReleasesPlan,
))
export {ReleasesPlanForm}

export default (ReleasesPlanForm)
