/* eslint-disable no-nested-ternary */
import React, { Fragment, Component } from 'react'
import {Result} from "antd"
import _ from 'lodash'
import { isEmpty, alertMessage } from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Icon,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Spin,
  Modal,
  Empty,
} from '../../common/UIComponents'
import userStore from '../../stores/UserStore'
import FormErrors from '../common/FormErrors'
import FormButtons from '../common/FormButtons'
import {
  fetchOrderAccessorials,
} from '../../api/OrdersApi'
import {
  fetchAccessorialsComponents,
  fetchAccountAccessorials as fetchAccountComponentAccessorials,
} from '../../api/Accessorials'
import { setAccessorialResponse } from '../../helpers/orders'
import QuoteAccessorialRowList from './QuoteAccessorialRowList'
import { QuotesApis } from '../../api/QuotesApi'
import OrderAccessorialRowList from '../orders/OrderAccessorialRowList'
import { renderAlertMessage } from '../../helpers/common'

const { confirm } = Modal
class QuoteAccessorial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountComponentAccessorial: [],
      orgComponents: [],
      orderAccessorials: [],
      accountId: props.account_id,
      orgId: props.org_id,
      orderId: props.customer_order_id,
      displayComponents: [],
      inProgress: false,
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    }
  }

  componentDidMount() {
    this.getOrgComponents()
    // this.getOrderAccessorials();
    // this.getAccountComponentAccessorials();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account_id !== this.state.accountId) {
      this.setState(
        {
          accountId: nextProps.account_id,
        },
        () => {
          this.getAccountComponentAccessorials()
        },
      )
    }
    if(nextProps.clickedSave){
        if(this.props.account_id.length){
          this.handleSave()
        }else{
          this.props.handleSuccess()
        }

    }
  }

  getDeifinitions = (accessorial) => {
    const { orgComponents, displayComponents } = this.state
    if (!isEmpty(accessorial)) {
      const components = accessorial.components || []
      const accComponents = components.map(
        (component) => component.component_code,
      )
      const componentDefs = orgComponents.filter(
        (component) =>
          accComponents.includes(component.code) &&
          displayComponents.includes(component.code),
      )
      return componentDefs
    }
    return []
  }

  getAccountComponentAccessorials = () => {
    if (!isEmpty(this.props.account_id)) {
      this.setState({ inProgress: true })
      const { orgId, orgComponents, displayComponents } = this.state
      fetchAccountComponentAccessorials(orgId, this.props.account_id, 'DISPATCHER').then(
        (result) => {
          if (result.success) {
            const accessorials = result.accessorials || []
            const accountComponentAccessorial = accessorials.map(
              (accessorial) => {
                const componentDefs = this.getDeifinitions(accessorial)
                return Object.assign({}, accessorial, {
                  componentDefs,
                })
              },
            )
            this.setState(
              {
                inProgress: false,
                accountComponentAccessorial: _.sortBy(accountComponentAccessorial, ["standard_code", "accessorial_code"]),
              },
              () => {
                this.getOrderAccessorials()
              },
            )
          }else {
            this.setState({ inProgress: false, accountComponentAccessorial: [] })
          }
        },
      )
    }
  }

  getOrderAccessorials = () => {
    // fetchOrderAccessorials(this.state.orderId, 'QUOTE').then((result) => {
    const location_id = this.props.quoteFormData?.type_of_order === "T"  ? this.props.location_id : null
        fetchOrderAccessorials(this.state.orderId, 'QUOTE',location_id).then((result) => {
      if (result.success) {
        const orderAccessorial = result.accessorials || {}
        const accessorials = orderAccessorial.account_accessorial_params || [];
        const allowedIds = {};
        this.state.accountComponentAccessorial.forEach(
          (accessorial) => {
            const components = accessorial.components ? 
            accessorial.components.map(record => record.component_code)
            :
            [];
            allowedIds[accessorial.id] = [...components];
        })
        const orderAccessorials = setAccessorialResponse(
          accessorials,
          true,
          allowedIds,
        )
        this.setState(
          {
            orderAccessorials,
          },
          // () => {
          //   if (orderAccessorials.length === 0) {
          //     this.state.accountComponentAccessorial
          //       .filter(
          //         (accss) =>
          //           !isEmpty(accss.standard_code) && accss.is_clone === "false"
          //       )
          //       .forEach((accessorial) => {
          //         this.handleCheckStandardAccessorial(
          //           accessorial.id,
          //           accessorial.accessorial_code,
          //           true,
          //           !isEmpty(accessorial.standard_code),
          //         );
          //       });
          //   }
          // }
        )
      } else {
        renderAlertMessage(result.errors)
      }
    })
  }

  getOrgComponents = () => {
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const orgComponents = result.components || []
        const displayComponents = []
        const noParamComponents = []
        orgComponents.forEach((component) => {
          if (
            component.component_params &&
            component.component_params.length > 0
          ) {
            const componentParams = component.component_params || []
            const visibleParams = componentParams.filter((param) =>
              param.visible_to.includes('DISPATCHER'),
            )
            if (visibleParams.length > 0) {
              displayComponents.push(component.code)
            } else {
              noParamComponents.push(component.code)
            }
          } else {
            noParamComponents.push(component.code)
          }
        })
        this.setState(
          {
            orgComponents,
            displayComponents,
            noParamComponents,
          },
          () => {
            this.getAccountComponentAccessorials()
          },
        )
      }
    })
  }
  _renderCollectionComp = () => <div />

  handleOnChange = (
    accParam,
    componentCode,
    accountAccessorialId,
    eleValue,
  ) => {
    const { orderAccessorials } = this.state
    const accountAccessorialParamIndex = _.findIndex(orderAccessorials, [
      'account_accessorial_id',
      accountAccessorialId,
    ])
    if (accountAccessorialParamIndex >= 0) {
      const accountAccessorialParam = _.cloneDeep(
        orderAccessorials[accountAccessorialParamIndex],
      )
      const componentParams = accountAccessorialParam.component_params
        ? accountAccessorialParam.component_params
        : []
      const componentIndex = _.findIndex(componentParams, [
        'component_code',
        componentCode,
      ])
      let currentComponent = {}
      if (componentIndex >= 0) {
        currentComponent = componentParams[componentIndex]
        if (isEmpty(currentComponent.component_values)) {
          currentComponent.component_values = []
        }
        const paramObjIndex = _.findIndex(currentComponent.component_values, [
          'accessorial_key',
          accParam,
        ])
        if (paramObjIndex >= 0) {
          currentComponent.component_values[
            paramObjIndex
          ].accessorial_value = eleValue
        } else {
          currentComponent.component_values.push({
            accessorial_key: accParam,
            accessorial_value: eleValue,
          })
        }
        componentParams[componentIndex] = { ...currentComponent }
      } else {
        currentComponent = {
          component_code: componentCode,
          component_values: [],
        }
        currentComponent.component_values.push({
          accessorial_key: accParam,
          accessorial_value: eleValue,
        })
        componentParams.push({ ...currentComponent })
      }
      accountAccessorialParam.component_params = [...componentParams]
      orderAccessorials[accountAccessorialParamIndex] = {
        ...accountAccessorialParam,
      }
      this.setState({
        orderAccessorials,
      })
      // this.props.handleStateOnchange('orderAccessorials', orderAccessorials);
    }
  }

  askConfirmMessage = (title, content, cb) => {
    confirm({
      title,
      content: <span className="">{content}</span>,
      onOk: () => {
        cb()
      },
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onCancel: () => {},
    })
  }

  handleCheckStandardAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode,
  ) => {
    const { accountComponentAccessorial, orderAccessorials } = this.state
    const accountAccessorial = _.find(accountComponentAccessorial, {
      id: accountAccessorialId,
    })
    if (eleValue) {
      const otherAccessorials = orderAccessorials
        .filter(
          (accessorial) =>
            accessorial.standard_code === accountAccessorial.standard_code,
        )
        .map((accessorial) => accessorial.accessorial_code)
      if (otherAccessorials.length > 0) {
        this.askConfirmMessage(
          I18n.t('messages.standard_change_confirm', {
            field: otherAccessorials.join(", "),
          }),
          I18n.t('messages.proceed_confirm'),
          () => {
            this.handleAddOrderCompStandardAccessorial(
              accountAccessorialId,
              accessorialCode,
              eleValue,
              isStandardCode,
            )
          },
        )
      } else {
        this.handleAddOrderCompStandardAccessorial(
          accountAccessorialId,
          accessorialCode,
          eleValue,
          isStandardCode,
        )
      }
    } else {
      const standardCode = accountAccessorial.standard_code
      const standardAccessorialIds = accountComponentAccessorial
        .filter((accessorial) => accessorial.standard_code === standardCode)
        .map((accessorial) => accessorial.id)

      // const standardAccessorialIds = standardAccessorial.map(
      //   (accessorial) => accessorial.id
      // );
      const otherAccessorials = orderAccessorials.filter(
        (accessorial) =>
          !standardAccessorialIds.includes(accessorial.account_accessorial_id),
      )
      this.setState({
        orderAccessorials: otherAccessorials,
      })
    }
  }

  handleAddOrderCompStandardAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode,
  ) => {
    const { accountComponentAccessorial, orderAccessorials } = this.state
    const accountAccessorial = _.find(accountComponentAccessorial, {
      id: accountAccessorialId,
    })
    if (!isEmpty(accountAccessorial)) {
      const standardCode = accountAccessorial.standard_code
      const standardAccessorialIds = accountComponentAccessorial
        .filter(
          (accessorial) =>
            accessorial.id !== accountAccessorialId &&
            accessorial.standard_code === standardCode,
        )
        .map((accessorial) => accessorial.id)
      const otherAccessorials = orderAccessorials.filter(
        (accessorial) =>
          !standardAccessorialIds.includes(accessorial.account_accessorial_id),
      )
      this.setState(
        {
          orderAccessorials: otherAccessorials,
        },
        () => {
          this.handleAddOrderCompAccessorial(
            accountAccessorial.id,
            accountAccessorial.accessorial_code,
            true,
          )
        },
      )
    }
  }

  handleAddOrderCompAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode,
  ) => {
    const { orderAccessorials } = this.state
    if (eleValue) {
      const accountAccessorial = orderAccessorials.find(
        (acc) => acc.id === accountAccessorialId,
      )
      if (accountAccessorial) {
      } else {
        const { accountComponentAccessorial } = this.state;
        const accountAccessorialObj = _.find(accountComponentAccessorial, { id: accountAccessorialId });
        const accessorialObj = {};
        accessorialObj.account_accessorial_id = accountAccessorialId;
        accessorialObj.accessorial_code = accessorialCode;
        accessorialObj.standard_code = !isEmpty(accountAccessorialObj) ? accountAccessorialObj.standard_code : '';
        accessorialObj.component_params = [];
        // this.props.handleStateOnchange('orderAccessorials', [
        //   ...orderAccessorials,
        //   accessorialObj,
        // ]);
        this.setState({
          orderAccessorials: [...orderAccessorials, accessorialObj],
        })
      }
    } else {
      const newAccountAccessorials = orderAccessorials.filter(
        (acc) => acc.account_accessorial_id !== accountAccessorialId,
      )
      this.setState({
        orderAccessorials: [...newAccountAccessorials],
      })
      // this.props.handleStateOnchange('orderAccessorials', [...newAccountAccessorials]);
    }
  }

  handleSave = () => {
    const { orderAccessorials } = this.state
    this.setState({ inProgress: true, errors: [] })
    const accountAccessorialParams = []
    // orderAccessorials.forEach((orderAccessorial) => {
    //   const object = {
    //     account_accessorial_id: orderAccessorial.account_accessorial_id,
    //     accessorial_code: orderAccessorial.accessorial_code,
    //     accessorial_type: orderAccessorial.accessorial_type,
    //     component_params: [],
    //   };
    //   if (orderAccessorial.component_params.length > 0) {
    //     orderAccessorial.component_params.map((param) => {
    //       accountAccessorialParams.push(
    //         Object.assign({}, object, { component_params: { ...param } })
    //       );
    //     });
    //   } else {
    //     accountAccessorialParams.push(object);
    //   }
    // });
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      account_id: this.props.account_id,
      customer_order_id: this.props.customer_order_id,
      account_accessorial_params: [...orderAccessorials],
      //location_id : this.props.location_id,
    }
    if(['T', 'LH'].includes(this.props.quoteFormData?.type_of_order)){
      data.location_id = this.props.location_id;
    }
    QuotesApis.saveQuoteAccessorials(data).then((result) => {
      if (result.success) {
        const response = result.data || {};
        const accessorials = response.accessorials || [];
        this.setState({
          inProgress: false,
          errors: [],
        })
        alertMessage(I18n.t('messages.saved'));
        this.props.handleSuccess()
        
      } else {
        this.setState({
          inProgress: false,
          errors: result.errors || [],
        })
        this.props.handleFailure()
      }
    })
  }

  renderField = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj,
  ) => {
    const size = 'small'
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === 'los';
    const isDisabled = isEmpty(orderAccObject) || isLos;
    const { decimalPoints} = this.state;

    switch (paramDefinition.data_type) {
      case 'string':
        return (
          <Row>
            <Col>
              {paramDefinition.code === 'charge_type' ? (
                <Radio.Group
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={isDisabled}
                  size={size}
                >
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="flat">Flat</Radio>
                </Radio.Group>
              ) : paramDefinition.code === 'charge_value' ? (
                <Input
                  type="number"
                  addonBefore={
                    currentConfiguration[component.code].charge_type ===
                    'flat' ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentConfiguration[component.code].charge_type ===
                    'percentage' ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                  value={currentConfiguration[component.code][param.code]}
                  onChange={(e) =>
                    this.handleOnChange(
                      component.code,
                      param.code,
                      e.target.value,
                    )
                  }
                  className="textUpperCase"
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                  disabled={isDisabled}
                />
              ) : (
                <Input
                  size={size}
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={isDisabled}
                />
              )}
            </Col>
          </Row>
        )
      case 'number':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                disabled={isDisabled}
                min={0}
                precision={
                  paramDefinition.code === 'min_charge' ||
                  paramDefinition.code === 'max_charge'
                    ? decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        )
      case 'price':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                addonBefore={<Icon type="dollar" />}
                disabled={isDisabled}
                min={0}
                precision={decimalPoints}
              />
            </Col>
          </Row>
        )
      case 'Boolean':
        return (
          <Row>
            <Radio.Group
              value={currentParamObj && currentParamObj.accessorial_value}
              onChange={(e) =>
                this.handleOnChange(
                  paramDefinition.code,
                  accountAccComponent.component_code,
                  accessorial.id,
                  e.target.value,
                )
              }
              size={size}
              disabled={isDisabled}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        )
      case 'Select':
        return (
          <Select
            showSearch
            size={size}
            value={currentParamObj && currentParamObj.accessorial_value}
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              this.handleOnChange(
                paramDefinition.code,
                accountAccComponent.component_code,
                accessorial.id,
                e,
              )
            }
            disabled={isDisabled}
          >
            {paramDefinition.options.map((qoption, qindex) => (
              <Select.Option
                key={qoption.option_key}
                value={qoption.option_key}
              >
                {qoption.option_value}
              </Select.Option>
            ))}
          </Select>
        )
      default:
        break
    }
  }

  render() {
    const {
      accountComponentAccessorial,
      orgComponents,
      orderAccessorials,
      displayComponents,
    } = this.state
    const losAccessorials = this.state.orderAccessorials
      ? this.state.orderAccessorials
          .filter((accessorial) => accessorial.accessorial_type === 'los')
          .map((accessorial) => accessorial.account_accessorial_id)
      : []
    const predefinedAccessorial = accountComponentAccessorial.filter(
      (accessorial) => losAccessorials.includes(accessorial.id),
    )
    const additionalAccessorial = accountComponentAccessorial.filter(
      (accessorial) => !losAccessorials.includes(accessorial.id),
    )
    return (
      <Spin
        spinning={this.state.inProgress}
        delay={1000}
        className="accessorial_block"
      >
        {!this.props.account_id.length  ? (
          <Result
            status="warning"
            title="No Accessorials Found"
          />
        ): (
        <div>
          {accountComponentAccessorial.length > 0 ? (
            <Fragment>
              {predefinedAccessorial.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                  <h3>{I18n.t('accessorials.pre_selected')}</h3>
                    {/* QuoteAccessorialRowList */}
                   <OrderAccessorialRowList 
                    data={predefinedAccessorial}
                    renderField={this.renderField}
                    orderAccessorials={this.state.orderAccessorials}
                    handleAddOrderCompAccessorial={
                      this.handleAddOrderCompAccessorial
                    }
                    handleAddOrderCompStandardAccessorial={
                      this.handleAddOrderCompStandardAccessorial
                    }
                    handleCheckStandardAccessorial={
                      this.handleCheckStandardAccessorial
                    }
                    displayComponents={displayComponents}
                    size="small"
                    pagination={{ position: 'none' }}
                    isReadOnly
                    reqFrom="quotes"
                    onChange={
                      this.handleOnChange
                    }
                  />
                </div>
              )}
              {additionalAccessorial.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                  {this.props.location === this.props.quoteFormData?.pickup_params ? <h3 style={{fontWeight:"bold"}}>Pickup Accessorial</h3> : <h3 style={{fontWeight:"bold"}}>Delivery Accessorial</h3>}
                  <h3>{I18n.t('accessorials.additional')}</h3>
                  <OrderAccessorialRowList
                    data={additionalAccessorial}
                    onChange={
                      this.handleOnChange
                    }
                    renderField={this.renderField}
                    orderAccessorials={this.state.orderAccessorials}
                    handleAddOrderCompAccessorial={
                      this.handleAddOrderCompAccessorial
                    }
                    handleAddOrderCompStandardAccessorial={
                      this.handleAddOrderCompStandardAccessorial
                    }
                    handleCheckStandardAccessorial={
                      this.handleCheckStandardAccessorial
                    }
                    displayComponents={displayComponents}
                    size="small"
                    pagination={{ position: 'none' }}
                    isReadOnly={false}
                    reqFrom="quotes"
                  />
                </div>
              )}
              {FormErrors(this.state.errors)}
              {/* {
                // ['NEW', ...AppConfig.notStartedList, 'PREPLAN'].includes(customerDetails.status) &&
                <Row className="marginTop10">
                  <Col xs={24} className="alignLeft">
                    { FormButtons(
                      this.state.inProgress,
                      this.handleSave,
                      this.props.onCancel,
                      false,
                      true,
                      'small',
                      I18n.t('general.save'),
                      "",
                      "",
                    ) }
                  </Col>
                </Row>
              } */}
            </Fragment>
          ):
          <Empty
          description={
            <span>
              {!isEmpty(this.props.account_id) ? "No account selected" : "No accessorial configured"}
            </span>
          }
          />}
        </div>
        )}
      </Spin>
    )
  }
}

export default QuoteAccessorial
