/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment, useContext } from 'react'
import { withRouter } from 'react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import 'moment/locale/en-gb'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'
import refresh from '../../../assets/images/refresh.png'

moment.locale('en-gb', {
  week: {
    dow: 1, /// Date offset
  },
})
import {
  Col,
  Row,
  Spin,
  Avatar,
  Select,
  DatePicker,
  Button,
  Form,
  FormItem,
  Tooltip,
  Input,
  Search,
} from '../../common/UIComponents'
import { alertMessage,checkIfDriverLoggedIn,isEmpty, randomNumber } from '../../common/Common'
import I18n from '../../common/I18n'
import FormErrors from '../common/FormErrors'
import {
  fetchWeekReport,
  fetchDriverPayReport,
  approveReport,
  saveReportDetails,
  addWeekPeriodToDriver,
} from '../../api/Reports'
import NewWeeklyReport from '../reports/NewWeeklyReport'
import ReportDetails from '../reports/ReportDetails'
import { fetchActiveUsers, fetchOrderDriverUsers, fetchUsersForWeeklyReport } from '../../api/UsersApi'
import BaseModal from '../BaseModal'
import { checkServiceExistance, removeSpecialCharacters, renderAlertMessage } from '../../helpers/common'
import userStore from '../../stores/UserStore'
import AppConfig from '../../config/AppConfig'
import { formatRoles } from '../../helpers/user'
import { WarehouseContext } from '../../context/WarehouseContext'
import { OrgContext } from '../../context/OrgContext'
import { UserContext } from '../../context/UserContext'
const { Option } = Select
const { WeekPicker } = DatePicker

class WeeklyReportsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      reports: [],
      driverPayReports: [],
      // driver_pay_reports : [],
      users: [],
      currentRecord: {},
      currentDriverPayRecord: {},
      currentDriver: {},
      weekString: '',
      driverDeductions: [],
      showWeekForm: false,
      weekPeriodString: '',
      searched_order: "",
      perPage: 5,
      totalCount: 0,
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    // this.setInitialData();
    this.getUsers()
  }
  
  onChange = (dateValue, dateString) => {
    // if (!isEmpty(dateValue)) {
    //   dateValue = moment(dateValue).subtract(7, 'day');
    // }
    this.setState(
      {
        weekString: dateValue,
      },
      () => {
        const { perPage } = this.state;
        this.getReports(1, perPage);
      },
    )
  }

  refreshIcon = (cb) => (
    <Tooltip title={I18n.t('general.refresh')}>
      <img
        src={refresh}
        className="cursorPointer textPrimary marginRight10 "
        onClick={cb}
        style={{ width: 15, marginTop: 5 }}
      />
    </Tooltip>
  )

  setDeductions = (reports, cb = null) => {
    this.setState(
      {
        reports: reports.map((deduction) =>
          Object.assign({}, deduction, { temp_id: randomNumber() }),
        ),
      },
      cb,
    )
  }

  onSearchOrderNumber = (text) => {
    const filteredString = removeSpecialCharacters(text);
    const searchValue = filteredString.trim();
    this.setState(
      {
        searched_order: searchValue,
      },
      () => {
        if (searchValue === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = filteredString;
        }
        // this.tableOptions.pagination.current = 1;
        this.getReports()
      }
    );
  };

  // getUsersOrderNumbers = async (initial, cb) => {
  //   this.setState({ inProgress: true });
  //   const page = this.tableOptions.pagination.current;
  //   const searchText = this.state.searched_order || "";
  //   const org_id = userStore.getStateValue('selectedOrg');
  //   const perPage = this.state.recordsPerPage;
  //   // Wait until driverId is available
  //   while (_.isEmpty(_.get(this.state, "currentDriver.id"))) {
  //     await new Promise(resolve => setTimeout(resolve, 1000)); // wait for 1 second before checking again
  //   }
  
  //   const driverId = this.state.currentDriver.id;
  
  //   try {
  //     const result = await fetchOrderDriverUsers(
  //       page,
  //       perPage,
  //       searchText,
  //       org_id,
  //       driverId
  //     );
  
  //     if (result.success) {
  //       this.setState(
  //         {
  //           driver_pay_reports: result.driver_pay_reports,
  //           pagination: result.pagination,
  //           inProgress: false,
  //           driverPayReports: [],
  //         },
  //         () => {
  //           if (cb) {
  //             cb();
  //           }
  //         }
  //       );
  //     } else {
  //       renderAlertMessage(result.errors);
  //       this.setState({ inProgress: false, driverPayReports: [] });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     this.setState({ inProgress: false });
  //   }
  // }

  getUsers = (refreshReports = true) => {
    const { currentDriver } = this.state

    // checking for Driver Login.....
    const { currentUser, currentRole } = this.props.userData;
    const isDriverLogin = checkIfDriverLoggedIn(currentRole);
    const { id } = currentUser;
    //.....
    const currentDriverId = _.get(currentDriver,"id",null)
    if (!isDriverLogin && currentDriverId) {
      return;
    }
    this.setState({ inProgress: true });
    fetchUsersForWeeklyReport().then((result) => {
      if (result.success) {
        const users = result.users || []
        const loggedInDriver = isDriverLogin && id ? users.find(dv => dv.id === id) : {};
        this.setState(
          {
            users,
            inProgress: false,
            currentDriver: isDriverLogin
              ? loggedInDriver
              : currentDriverId
              ? currentDriver
              : users.length > 0
              ? users[0]
              : {},
          },
          () => {
            if (refreshReports) {
              this.getReports();
            }
          }
        );
      } else {
        this.setState({ inProgress: false })
        if (refreshReports) {
          this.getReports()
        }
      }
    })
  }

  getReports = (page = 1, perPage = 5, fromLoadMore = false) => {
    if (!fromLoadMore) {
      this.setState({
        perPage: 5
      })
    }
    const { currentDriver, weekString, searched_order } = this.state
    const searchText = searched_order || "";
    let year_number = ''
    let week_number = ''
    if (!isEmpty(weekString)) {
      week_number = moment(weekString, 'MM-DD-YYYY').week()
      year_number = moment(weekString).format('YYYY')
    }
    const currentDriverId = _.get(currentDriver,"id",null)
    if(_.isEmpty(currentDriverId)){
      return;
    }
    this.setState({ inProgress: true });
    fetchWeekReport(currentDriverId, week_number, year_number, searchText, page, perPage).then(
      (result) => {
        if (result.success) {
          const driverPayReports = result.driverPayReports || []
          const totalCount = result.total_count
          this.setState({
            driverPayReports,
            totalCount,
            // driverPayReports: _.orderBy(
            //   driverPayReports,
            //   ['week_number'],
            //   ['desc'],
            // ),
            inProgress: false
          })
          // if(fromLoadMore){
          //   {(this.state.driverPayReports.length < this.state.perPage) && renderAlertMessage("No more data found to load more")}
          // }
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, driverPayReports: [] })
        }
      },
    );
  }

  handleLoadMore = () => {
    this.setState(prevState => ({
      perPage: prevState.perPage + 5
    }), () => {
      const { perPage } = this.state;
      this.getReports(1, perPage, true);
    });
  }

  handleChange = (element, value) => {
    if (element === 'driver') {
      const currentDriver = _.find(this.state.users, { id: value })
      this.setState(
        {
          currentDriver: !isEmpty(currentDriver) ? currentDriver : {},
        },
        () => {
          if (!isEmpty(this.state.searched_order)) {
            this.onSearchOrderNumber("");
          } else{
            this.getReports();
          }
        },
      )
    } else {
      this.setState({
        [element]: value,
      })
    }
  }

  showAddWeekForm = () => {
    this.setState({
      showWeekForm: true,
    })
  }

  _renderSearchOptions = () => {
    const { currentRole } = this.props.userData;
    const isDriverLogin = checkIfDriverLoggedIn(currentRole);
    return (
    <Row className="marginTop10" gutter={8}>
      <Col xs={2} style={{paddingTop:'5px', marginTop: -3}} className='alignRight'> <span style={{fontWeight: 700}}>{I18n.t('general.Driver')}</span></Col>
      <Col xs={4}>
        <Select
          style={{ width: '100%' }}
          className="routes-input"
          onChange={(e) => this.handleChange('driver', e)}
          value={this.state.currentDriver.id || ''}
          filterOption={(input, option, index) =>
            option.props.children.props.children[2]
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          size="small"
          disabled={isDriverLogin}
        >
          {this.state.users?.map((province) => {
            const rolesList = formatRoles(province.roles);
              return (
                <Option key={province.id}>
              <div>
                {province.image && province.image.url ? (
                  <Avatar src={`${province.image.url}`} size={20} />
                ) : (
                  <Avatar
                    src="./../icons/user_100*100.png"
                    alt="DriverProfile"
                    size={20}
                  />
                )}
                &nbsp;&nbsp;{province.employee_code || province.full_name} {!isEmpty(rolesList) ? ` (${rolesList})` : ''}
              </div>
            </Option>
          )})}
        </Select>
      </Col>
      <Col xs={1} >
        {this.refreshIcon(() => this.getReports(1, this.state.perPage, true))}
      </Col>
      { !isDriverLogin && <Col xs={2} style={{paddingTop:'5px', marginTop: -3}} className='alignRight'><span style={{fontWeight: 700}}>Order Number</span></Col>}
      { !isDriverLogin &&<Col xs={4}>
         <Search
            onSearch={this.onSearchOrderNumber}
            onChange={(e) =>
              this.handleChange("searched_order", e.target.value)
            }
            allowClear
            size="small"  
            value={this.state.searched_order || ""}          
         />
      </Col>}
        {/* <Col xs={9} className="alignRight" >
          <Button
            className='alignRight'
            type="primary"
            size='small'
            icon='double-right'
            onClick={this.handleLoadMore}
            loading={this.state.inProgress}
            disabled={this.state.driverPayReports.length === this.state.totalCount || this.state.driverPayReports.length == 0}
          >
            More
          </Button>
        </Col> */}
      {!isDriverLogin && <Col xs={11} className="alignRight">
        <Button type="primary" size="small" onClick={this.showAddWeekForm} icon="plus">
          {I18n.t('general.add')} {I18n.t('general.pay')}
        </Button>
      </Col>}
      {/* <Col xs={2} className="alignRight">Week :</Col>
      <Col xs={6}>
        <WeekPicker size="small" placeholder="Select Week" onChange={this.onChange} value={this.state.weekString} />
      </Col> */}
    </Row>
    )
  }


  renderFloatingButton() {
    return (
      <Button
        className='floatBtnConfig'
        type="primary"
        size='small'
        icon="reload"
        onClick={this.handleLoadMore}
        style={{zIndex: 1000}}
        loading={this.state.inProgress}
        disabled={this.state.driverPayReports.length < this.state.perPage}
      >
        Load More
      </Button>
    );
  }

  handleView = (recordNo) => {
    const reports = [...this.state.reports]
    const currentRecord = reports[recordNo]
    this.setState({
      showDetails: true,
      currentRecord,
    })
  }

  handleDriverPayView = (driverPayId) => {
    this.setState({ inProgress: true, driverPayId })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = driverPayReports.find(
      (rept) => rept.id === driverPayId,
    )
    if (currentDriverPayRecord) {
      fetchDriverPayReport(
        currentDriverPayRecord.customer_order_ids,
        currentDriverPayRecord.id,
      ).then((result) => {
        if (result.success) {
          const reports = result.reports || []
          const driverDeductions = result.driverDeductions || []
          const currentRecord = reports[0] ? { ...reports[0] } : {}
          currentRecord.claim_balance = result.claim_balance
          currentRecord.loan_balance = result.loan_balance
          currentRecord.advance_balance = result.advance_balance
          this.setState({
            currentRecord,
            inProgress: false,
            showDetails: true,
            currentDriverPayRecord,
            driverDeductions
          })
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleApprove = (driverPayId) => {
    this.setState({
      inProgress: true,
      driverPayId,
    })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = _.find(driverPayReports, { id: driverPayId })
    if (!isEmpty(currentDriverPayRecord)) {
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: driverPayId,
        status: 'APPROVED',
      }
      approveReport(data).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            reports: result.reports || [],
            driverPayReports: result.driverPayReports || [],
            currentDriverPayRecord,
          })
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleSaveDetails = (driverPayId) => {
    this.setState({
      inProgress: true,
      driverPayId,
    })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = _.find(driverPayReports, { id: driverPayId })
    if (!isEmpty(currentDriverPayRecord)) {
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: driverPayId,
        status: 'APPROVED',
      }
      saveReportDetails(data).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            reports: result.reports || [],
            driverPayReports: result.driverPayReports || [],
            currentDriverPayRecord,
          })
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  addWeekToDriver = () => {
    if (!isEmpty(this.state.weekPeriodString)) {
      this.setState({
        inProgress: true,
      })
      const weekPeriodString = this.state.weekPeriodString
      const { getWHDetails  } = this.props.warehouseFilter;
      const whDetails = getWHDetails()  
      let year_number = ''
      let week_number = ''
      if (!isEmpty(weekPeriodString)) {
        week_number = moment(weekPeriodString, 'MM-DD-YYYY').week()
        year_number = moment(weekPeriodString).format('YYYY')
      }
      const data = {
        organization_id: userStore.getStateValue('selectedOrg'),
        driver_id: this.state.currentDriver.id,
        week_number,
        year: year_number,
        wh_location_id: _.get(whDetails, "id"),
        wh_name: _.get(whDetails, "name"),
      }
      addWeekPeriodToDriver(data).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            showWeekForm: false,
            weekPeriodString: '',
          })
          alertMessage(I18n.t('messages.done_successfully', { field: 'Added' }))
          this.getReports()
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    } else {
      alertMessage(`Please select ${I18n.t('general.week')}`)
    }
  }

  closeDetails = () => {
    const { perPage } = this.state;
    this.setState(
      {
        showDetails: false,
        currentRecord: {},
      },
      () => {
        this.getReports(1, perPage, true);
      },
    )
  }

  renderDetailsModal = () => {
    const { currentRole } = this.props.userData;
    const deductions = this.state.driverDeductions.filter(
      (deduction) =>
        deduction.driver_pay_report_id === this.state.driverPayId &&
        deduction.driver_id === this.state.currentDriverPayRecord?.driver_id
    );
    const isDriverLogin = checkIfDriverLoggedIn(currentRole);
    return (
      <BaseModal
        title={I18n.t('general.details')}
        onCancel={() => this.closeDetails()}
        width="100%"
        style={{
          top: 0,
          overflowY: 'hidden',
          height: '100%',
        }}
        bodyStyle={{ padding: 0, height: 'calc(100vh - 50px)' }}
        className="reportDetailsBlock"
        wrapClassName="reportDetailsBlock"
      >
        <ReportDetails
          report={this.state.currentRecord}
          driverId={this.state.currentDriver.id}
          driverPayId={this.state.driverPayId}
          currentDriverPayRecord={this.state.currentDriverPayRecord}
          driverPayReports={this.state.driverPayReports}
          deductions={deductions}
          currentDriver={this.state.currentDriver}
          warehouseFilter={this.props.warehouseFilter}
          drivers={this.state.users || []}
          refreshDriverPayView={this.handleDriverPayView}
          orgData={this.props.orgData}
          isDriverLogin={isDriverLogin}
        />
      </BaseModal>
    )
  }
  renderWeekModal = () => {
    return (
      <BaseModal
        title={<Fragment>
          {I18n.t('general.add')} {I18n.t('general.pay').toLowerCase()} to the
          driver {this.state.currentDriver.employee_code}
        </Fragment>}
        onCancel={() => {
          this.setState({
            showWeekForm: false,
            weekPeriodString: '',
          })
        }}
        width="30%"
        visible={this.state.showWeekForm}
        style={{
          top: '25%',
        }}
        className="reportDetailsBlock"
        wrapClassName="reportDetailsBlock"
        maskClosable={false}
      >
        <Row gutter={16}>
          <Col xs={20}>
            <FormItem label={`Select ${I18n.t('general.week')}`}>
              <WeekPicker
                size="small"
                style={{ width: '100%' }}
                placeholder="Select Week"
                onChange={(date, dateString) =>
                  this.handleChange('weekPeriodString', date)
                }
                value={this.state.weekPeriodString}
              />
              {/* <LocaleProvider locale={locales.de_DE}></LocaleProvider>/LocaleProvider> */}
            </FormItem>
          </Col>
          <Col xs={4}>
            <Button
              type="primary"
              className="marginTop20"
              size="small"
              onClick={this.addWeekToDriver}
              icon='save'
              style={{marginLeft: -5}}
            >
              {I18n.t('general.save')}
            </Button>
          </Col>
          {this.state.weekPeriodString && (
            <Col
              xs={24}
              className="notes_content textBold"
              style={{ fontSize: 15 }}
            >
              {`${moment(this.state.weekPeriodString).format(
                'Wo',
              )} week - ${moment(this.state.weekPeriodString)
                .startOf('week')
                .format(AppConfig.dateFormat)} ~ ${moment(
                this.state.weekPeriodString,
              )
                .endOf('week')
                .format(AppConfig.dateFormat)}`}
            </Col>
          )}
        </Row>
      </BaseModal>
    )
  }

  _renderReports = () => {
    const { searchText } = this.tableOptions;
    const isSearching = searchText;
    const { currentRole } = this.props.userData;
    const isDriverLogin = checkIfDriverLoggedIn(currentRole);
    return (
    <div>
      <Row className="marginTop10">
        <Col>
          <Fragment>
            <NewWeeklyReport
              size="small"
              pagination={{ position: 'none' }}
              data = {this.state.driverPayReports}
              onSearch ={isSearching}
              onChange={this.handleFormChange}
              handleView={this.handleDriverPayView}
              handleApprove={this.handleApprove}
              handleSaveDetails={this.handleSaveDetails}
              deleteClick={(index, id, isNew) =>
                this.handleDeleteClick(index, id, isNew)
              }
              currentDriver={this.state.currentDriver}
              scroll = {{y:"calc(100vh - 320px)"}}
              isDriverLogin={isDriverLogin}
            />
          </Fragment>
          <Col xs={24} className="marginTop10">
            {FormErrors(this.state.errors)}
          </Col>
        </Col>
      </Row>
    </div>
  )
  }
  // _renderReports = () => (
  //   <div>
  //     <Row className="marginTop10">
  //       <Col >
  //         <Fragment>
  //           <WeeklyReport
  //             size="small"
  //             pagination={{ position: 'none' }}
  //             data={this.state.reports}
  //             onChange={this.handleFormChange}
  //             handleView={this.handleView}
  //             deleteClick={(index, id, isNew) => this.handleDeleteClick(index, id, isNew)}
  //           />
  //         </Fragment>
  //         <Col xs={24} className="marginTop10">
  //           {FormErrors(this.state.errors)}
  //         </Col>
  //       </Col>

  //     </Row>
  //   </div>
  // )

  handleDeleteClick = (index, id, isNew) => {
    if (isNew === true) {
      const { reports } = this.state
      reports.splice(index, 1)
      this.setState(
        {
          reports,
        },
        () => {
          this.doValidation(false)
        },
      )
    } else {
      this.handleDelete(id)
    }
  }

  render() {
    const { currentRole } = this.props.userData;
    const isDriverLogin = checkIfDriverLoggedIn(currentRole);
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress}>
            {isDriverLogin && <Row className="page-header">
              <Col xs={12}>Weekly Settlement Report</Col>
            </Row>}
            <Row>
              {checkServiceExistance('GOR') && (
                <Col className="accessorials">
                  <this._renderSearchOptions />
                  <this._renderReports />
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  style={{ marginRight: "10px" }}
                  className='alignRight'
                  type="primary"
                  size='Large'
                  icon='double-right'
                  onClick={this.handleLoadMore}
                  loading={this.state.inProgress}
                  disabled={this.state.driverPayReports.length === this.state.totalCount || this.state.driverPayReports.length == 0}
                >
                  More
                </Button>
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.showDetails && this.renderDetailsModal()}
        {this.state.showWeekForm && this.renderWeekModal()}
        {/* {this.renderFloatingButton()} */}
      </div>
    )
  }
}

export const WeeklyReports = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const  orgData = useContext(OrgContext);
  const userData = useContext(UserContext)
  return (
    <WeeklyReportsComponent
      warehouseFilter={warehouseFilter}
      orgData={orgData}
      userData = {userData}
      {...props}
    />
  );
});

WeeklyReports.propTypes = {
  account: PropTypes.shape().isRequired,
}

export default WeeklyReports
