import { Call, ApiUrl, PageParams, ParseGeneralResponse } from "./ApiUtils";
import {  isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import RouteLabel from "../config/RouteLabel";

export function fetchPreplans(
  status = "",
  page,
  perPage,
  sortBy = "none",
  sortOrder = "ascend",
  searchText = "",
  filter = {}
) {
  // const url = ApiUrl(status ? `v2/nav_routes?status=${status}&caller_type=LONG` : '/v2/nav_routes&caller_type=LONG');
  let url = ApiUrl(`v2/pre_plan?status=${status}&${PageParams(page, perPage)}`);
  if (!isEmpty(sortBy) && sortBy !== "none") {
    url = `${url}&sort_by=${sortBy}&sort_order=${I18n.t(
      `general.sort_${sortOrder}`
    )}`;
  }
  if (!isEmpty(searchText)) {
    url = `${url}&search_text=${searchText}`;
  }
  const warehouses = filter.warehouses;
  if (!isEmpty(warehouses)) {
    url = `${url}&warehouses=${warehouses.join()}`;
  }
  url = `${url}&operation_code=PPI`;
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan", pagination: "meta.pagination" },
  });
}

export function fetchPreplan(id) {
  const url = ApiUrl(`v2/pre_plan/pre_plan_details?id=${id}&operation_code=PPDS`); // pre_plan_details
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { preplan: "" },
  });
}

export function createPreplan(data, preplan_id, searchDate, warehouse) {
  data.delivery_start_date = searchDate;
  data.warehouse_id = warehouse;
  data.action_type = RouteLabel.actionLabels.AUTO_PREPLAN;
  data.operation_code = !preplan_id ? "PPC" : "PPAP";
  const method = !preplan_id ? "post" : "put";
  const url = !preplan_id
    ? ApiUrl("v2/pre_plan")
    : ApiUrl(`v2/pre_plan/${preplan_id}`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan" , unallocated_orders: "unallocated_orders"},
  });
}

export function autoPreplan(data, preplan_id) {
  data.operation_code = "PPAP";
  const method = "POST";
  const url = ApiUrl(`v2/pre_plan/${preplan_id}/auto_preplan`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan", unallocated_orders: "unallocated_orders"},
  });
}


export function capacityValidation(data, preplan_id) {
  // data.delivery_start_date = searchDate;
  // data.warehouse_id = warehouse;
  data.operation_code = 'PPACV';
  const method = "post";
  const url = ApiUrl(`v2/pre_plan/${preplan_id}/validate_pre_plan_capacity`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "" },
  });
}

export function createPreplanInitial(data) {
  const url = ApiUrl("v2/pre_plan");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan" },
  });
}

export function deletePreplan(data) {
  const url = ApiUrl(`v2/pre_plan/${data}`);
  return Call("delete", url, { operation_code: "PPD" }, ParseGeneralResponse);
}

export function deleteDriversFromPreplan(data) {
  data.operation_code = "PPRD";
  const url = ApiUrl(`v2/pre_plan/remove_driver_from_pre_plan`);
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "" },
  });
}

export function fetchStatus() {
  const url = ApiUrl("v2/pre_plan/stats?");
  return Call("get", url, {operation_code: "PPSTATS"}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function updateStatus(data) {
  data.operation_code = "UPPD";
  const method = "put";
  const url = ApiUrl("v2/pre_plan/update_pre_plan_status");
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan" },
  });
}

export function sendNotification(data) {
  const method = "post";
  data.operation_code = "PPMDR";
  data.warehouse_ids = localStorage.getItem("selectedWarehouses");
  const url = ApiUrl("v2/pre_plan/manual_dispatch_routes");
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan" },
  });
}




export function sendNotificationinDispatch(data) {
  const method = "post";
  data.operation_code = "PPMDR";
  data.action_type = RouteLabel.actionLabels.ASSIGN_NOTIFICATION
  data.action_from = "DISPATCH"
  const url = ApiUrl("v2/pre_plan/assign_notification_to_driver");
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan",  message: "message"},
  });
}

export function updatePreplan(data) {
  const method = "put";
  data.operation_code = "PPUD";
  const url = ApiUrl("v2/pre_plan/update_drivers");
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "pre_plan" },
  });
}

export function fetchAvailableDrivers(startTime, endTime, zone_ids = "", warehouse_id="") {
  let url = ApiUrl(
    `v2/pre_plan/available_drivers?start_datetime=${startTime}&end_datetime=${endTime}&zone_ids=${zone_ids}&operation_code=PPADS`
  );
  if(!isEmpty(warehouse_id)){
    url = `${url}&warehouse_ids=${warehouse_id}`
  }
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { users: "users", pagination: "meta.pagination" } },
    true
  );
}

export function fetchExceptionMessages() {
  const url = ApiUrl("v2/pre_plan/exception_messages");
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        exceptions: "exception_messages",
        pagination: "meta.pagination",
      },
    },
    true
  );
}

export function allocateDrivers(primary_driver, secondary_driver, route , actionType = "") {
  const actionFrom = "DISPATCH"
  const url = ApiUrl(
    `v2/pre_plan/reassign_driver?primary_driver_id=${primary_driver}&secondary_driver_id=${secondary_driver}&nav_route_id=${route}&action_type=${actionType}&action_from=${actionFrom}&operation_code=PPAD`
  );
  return Call("put", url, {}, ParseGeneralResponse, {
    responseDataKeys: { driver: "" },
  });
}

export function getWarehousesForDropDown() {
  const url = ApiUrl(`v2/locations/get_locations_by_type`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      locations: "",
    },
  });
}

export function fetchPreplansRoutes(data) {
  let url = ApiUrl(
    `v2/pre_plan/dated_pre_plans?organization_id=${data.organization_id}`
  );
  if (!isEmpty(data.pre_plan_id)) {
    url = `${url}&pre_plan_id=${data.pre_plan_id}`;
  }
  if (!isEmpty(data.start_date)) {
    url = `${url}&preplan_date=${data.start_date}`;
  }
  if (!isEmpty(data.warehouse_ids)) {
    url = `${url}&warehouse_ids=${data.warehouse_ids}`;
  }

  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { preplan: "nav_route_details" },
  });
}

// to send notification
export function sendPriorDayNotification(data) {
  const url = ApiUrl(
    `v2/delivery_zones/trigger_prior_day_notifications?nav_route_ids=${data}`
  );
  const method = "get";
  return Call(
    method,
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { message: "message" } },
    true
  );
}

export function swapDrivers(data) {
  data.action_type = RouteLabel.actionLabels.ROUTE_SWAP
  const url = ApiUrl(`v2/pre_plan/swap_drivers`);
  return Call("put", url, data, ParseGeneralResponse, {
    responseDataKeys: {
      nav_stop: "nav_stop",
      nav_route: "details",
      message: "message",
    },
  });
}

export function capacityValidationForRoute(data, preplan_id) {
  // data.delivery_start_date = searchDate;
  // data.warehouse_id = warehouse;
  data.operation_code = 'PPRCV';
  const method = "post";
  const url = ApiUrl(`v2/nav_routes/validate_route_capacity`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { preplan: "" },
  });
}

export function revokeRoute(data) {
  const method = "put";
  const url = ApiUrl("v2/pre_plan/revoke_nav_routes");
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function getPreplanRouteResponse(data) {
  const url = ApiUrl(`v2/nav_routes/get_preplan_route_response?nav_route_id=${data}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { nav_route: "nav_route" },
  });
}