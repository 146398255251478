/* eslint-disable react/sort-comp */
import React, { Component, useContext } from "react";
import _ from "lodash";
import {
	deleteTruck,
	fetchTrucks,
	updateStatus,
} from "../api/TrucksApi";
import {
	Button,
	Col,
	Row,
	Spin,
	Select,
	FormItem,
	Input,
} from "../common/UIComponents";
import TrucksList from "../components/trucks/List";
import BaseModal from "../components/BaseModal";
import TruckForm from "../components/trucks/Form";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import AppConfig from "../config/AppConfig";
import { checkServiceExistance, removeSpecialCharacters, renderAlertMessage } from "../helpers/common";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { VehicleTypeApi } from "../api/VehicleType";
import { ScreenKeys } from "./constants";
import { UserContext } from "../context/UserContext";
import { withRouter } from "react-router";

const truckStatuses = {
	active: "Active",
	inactive: "In Active",
};
class Trucks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trucks: [],
      pagination: {},
      reviewPagination: {},
      showFormModal: false,
      showReviews: false,
      currentTruck: {},
      inProgress: false,
      filter: {
        status: "",
        vin: "",
		    vehicle_type: "",
        sortBy: "none",
        sortByType: "descend",
      },
      vehicleTypes: [],
      recordsPerPage: props.recordsPerPage,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount() {
    this.getTrucks();
	this.getVehicleTypes();
  }

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getTrucks();
        updateRecordsPerPage(ScreenKeys.TRUCKS_LISTING, value);
      }
    );
  };

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
			    vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getTrucks = (initial, cb) => {
    this.setState({ inProgress: true });
    const { filter } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : this.state.recordsPerPage;
    fetchTrucks(filter, page, perPage).then((result) => {
      if (result.success) {
        this.setState(
          {
            trucks: result.trucks,
            pagination: result.pagination,
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleAddClick = () => {
    this.setState({ currentTruck: {}, showFormModal: true, isNew: true });
  };

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.trucks, ["id", id]);
    const truck = Object.assign({}, this.state.trucks[index]);
    this.setState({ currentTruck: truck, showFormModal: true });
  };

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteTruck(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getTrucks();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false }, () => {
      this.getTrucks();
    });
  };

  handleOnModalClose = () => {
    if (this.state.showFormModal && this.state.currentTruck.id) {
      this.getTrucks();
    }
    this.setState({ showFormModal: false, showReviews: false });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions?.pagination?.current
        ? this.tableOptions.pagination.current
        : 1
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getTrucks();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filter: Object.assign({}, this.state.filter, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
          }),
        },
        () => {
          this.getTrucks();
        }
      );
    }
  };

  renderFormModal = () => {
    let title;
    let isNew;
    if (this.state.currentTruck.id) {
      title = I18n.t("truck.edit");
      isNew = false;
    } else {
      title = I18n.t("truck.add");
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        width="60%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <TruckForm
          truck={this.state.currentTruck.id ? this.state.currentTruck : {}}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={(message) => this.handleSaveSuccess(message)}
          zones={this.state.zones}
		  vehicleTypes={this.state.vehicleTypes}
        />
      </BaseModal>
    );
  };

  handleFilterOnChange = (element, value) => {
    this.setState({
      filter: Object.assign({}, this.state.filter, { [element]: value }),
    });
  };

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.trucks, ["id", id]);
    const currentTruck = Object.assign({}, this.state.trucks[index]);
    currentTruck.status =
      currentTruck.status === "active" ? "inactive" : "active";
    this.setState({ currentTruck, isNew: false }, () => {
      updateStatus(id, currentTruck.status).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.getTrucks();
        } else {
          alertMessage(result.errors, "error", 10);
        }
      }).finally(() => {
        this.setState({ inProgress: false });
      });
    });
  };

  handleStatusChangeWithProgress = (id) => {
    this.setState({ inProgress: true });
    this.handleStatusChange(id);
  };

  handleSearch = () => {
    this.tableOptions.pagination.current = 1;
    this.getTrucks();
  };

  resetFilter = () => {
    this.setState(
      {
        filter: {
          status: "",
          vin: "",
        },
      },
      () => {
        this.getTrucks();
      }
    );
  };

  render() {
    const { pagination, filter } = this.state;
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("menu.trucks")}</Col>
            {checkServiceExistance("FLTC") && (
              <Col xs={12} className="page-actions">
                <Button
                  type="primary"
                  onClick={() => this.handleAddClick()}
                  icon="plus"
                >
                  {I18n.t("truck.add")}
                </Button>
              </Col>
            )}
          </Row>
          {/* <Row>
            <Col xs={24} className='page-actions'>
              <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('truck.new')}
              </Button>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col sm={24} xs={24} md={5} lg={5}>
              <FormItem label={I18n.t("truck.status")}>
                <Select
                  value={filter.status}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleFilterOnChange("status", e)}
                >
                  <Select.Option key="-1" value="">
                    -- All --
                  </Select.Option>
                  {Object.keys(truckStatuses).map((truckType) => (
                    <Select.Option key={truckType} value={truckType}>
                      {truckStatuses[truckType]}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col sm={24} xs={24} md={5} lg={5}>
              <FormItem label={I18n.t("truck.vin")}>
                <Input
                  value={filter.vin}
                  onChange={(e) => {
                    const filteredString = removeSpecialCharacters(
                      e.target.value
                    );
                    this.handleFilterOnChange("vin", filteredString);
                  }}
                />
              </FormItem>
            </Col>
            <Col sm={24} xs={24} md={5} lg={5}>
              <FormItem label={I18n.t("vehicleTypes.title")}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear={true}
                  onChange={(e) => {
                    this.handleFilterOnChange("vehicle_type",e);
                  }}
                  value={filter.vehicle_type ? filter.vehicle_type : ""}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key={"code"} value={""}>
                    ---Select---
                  </Select.Option>
                  {this.state.vehicleTypes.map((code) => (
                    <Select.Option key={code.name} value={(code.code).replace(/\s/g," ")}>
                      {code.code}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col sm={24} xs={24} md={4} lg={4} className="paddingTop20">
              <Button type="primary" onClick={this.handleSearch} icon='search'>
                Search
              </Button>{" "}
              &nbsp;&nbsp;
              <Button type="danger" onClick={this.resetFilter} icon='close-circle'>
                Clear
              </Button>
            </Col>
          </Row>
		  <Row className="page-content">
              <Col xs={24}>
                <Row style={{ float: "right", marginBottom: "-18px" }}>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Row>
              </Col>
            </Row>
          <Spin spinning={this.state.inProgress}>
            {checkServiceExistance("FLTI") && (
              <Row>
                <Col>
                  <TrucksList
                    data={this.state.trucks}
                    //   scroll = {{y:600}}
                    scroll={{ y: "calc(100vh - 320px)" }}
                    editClick={(id) => this.handleEditClick(id)}
                    deleteClick={(id) => this.handleDeleteClick(id)}
                    setStatus={(id) => this.handleStatusChangeWithProgress(id)}
                    inProgress={this.state.inProgress}
                    pagination={{
                      total: pagination.total_count,
                      current: pagination.current_page,
                      pageSize: pagination.per_page || this.state.recordsPerPage,
                    }}
                    tableChange={(tablePagination, filters, sorter) =>
                      this.handleTableChange(tablePagination, filters, sorter)
                    }
                  />
                </Col>
              </Row>
            )}
          </Spin>
          {this.state.showFormModal && this.renderFormModal()}
          {this.state.showReviews && this.renderReviewsModal()}
        </div>
      </div>
    );
  }
}

export const TrucksWrapper = withRouter((props) => {
  const userContext= useContext(UserContext);
  const perPageKey = ScreenKeys.TRUCKS_LISTING
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);

  return (
    <Trucks
      recordsPerPage={recordsPerPage}
      userContext={userContext}
      {...props}
    />
  );
});

export default TrucksWrapper;

