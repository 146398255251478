import { Avatar, Badge, Select } from 'antd';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import { Option } from '../../common/UIComponents';
import { sortData } from '../../helpers/common';

function DriverSelect (props) {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const { data } = props;
  const sortedAlphabetically = sortData(data, "displayName");
  const { screenFrom = ""} = props;
  return (
    <Select
      style={ { width: "100%" } }
      className="routes-input"
      filterOption={ (input, option, index) => {
        return option.props.children.props.children[ 2 ]
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0;
      }
      }
      showSearch
      allowClear
      onChange={ (e) => {
        setSelectedDriver(e);
        props.onChange(e);
      } }
      value={ selectedDriver }
    >
      { screenFrom !== 'dispatch' ? sortedAlphabetically.map((driver) => (
        <Option key={ driver.id } value={ driver.id }>
          <div>
            { driver.image && driver.image.url ? (
              <Avatar src={ `${driver.image.url}` } size={ 30 } />
            ) : (
              <Avatar
                src="./../icons/user_100*100.png"
                alt="DriverProfile"
                size={ 30 }
              />
            ) }
            &nbsp;
            { driver.displayName ? driver.displayName : 'NA'}
            &nbsp;&nbsp;
            <span className="floatRight">
              <Badge count={ driver.nav_stops_count > 2 ? driver.nav_stops_count - 2 : 0 } />
            </span>
          </div>
        </Option>
      ))
      :
      sortedAlphabetically.map((driver) => (
        <Option key={`${driver.id}~${driver.route_id}`} value={ `${driver.id}~${driver.route_id}` }>
          <div>
            { driver.image && driver.image.url ? (
              <Avatar src={ `${driver.image.url}` } size={ 30 } />
            ) : (
              <Avatar
                src="./../icons/user_100*100.png"
                alt="DriverProfile"
                size={ 30 }
              />
            ) }
            &nbsp;
            { driver.displayName ? driver.displayName : 'NA'}{driver.route_status ? `-${driver.route_status}` : ''}
            &nbsp;&nbsp;
            <span className="floatRight">
              <Badge count={ driver.nav_stops_count > 2 ? driver.nav_stops_count - 2 : 0 } />
            </span>
          </div>
        </Option>
      ))
      
      }
    </Select>
  );
}

export default DriverSelect;

DriverSelect.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
DriverSelect.defaultProps = {
  data: [],
  onChange: () => {
  }
};