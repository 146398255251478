import { isEmpty } from "../common/Common";
import userStore from "../stores/UserStore";
import {
  ApiUrl,
  Call,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";

export const BatchManagementApi = {
  create: (payload) => {
    payload.operation_code = "ARBC";
    const url = ApiUrl("v2/account_receivables/ar_batch_create");
    const formData = setObjectToFormData(payload);
    return Call("post", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },

  fetch: (
    page = 1,
    perPage = "",
    batch_number = "",
    from_date = "",
    to_date = "",
    sortBy = "",
    sortByType = ""
  ) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(
      `v2/account_receivables/get_ar_batches?${PageParams(
        page,
        perPage
      )}&organization_id=${organization_id}&operation_code=ARBI`
    );
    if(!isEmpty(batch_number)){
      url = `${url}&batch_number=${batch_number}`
    }
    if(!isEmpty(from_date) && !isEmpty(to_date)){
      url = `${url}&from_date=${from_date}&to_date=${to_date}`
    }
    if(!isEmpty(sortBy) && !isEmpty(sortByType)){
      url = `${url}&sort_by=${sortBy}&sort_order=${sortByType}`;
    }

    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "", pagination: "meta.pagination" },
    });
  },

  updateBatch: (payload) => {
    payload.operation_code = "ARBU";
    const url = ApiUrl("v2/account_receivables/update_ar_batch");
    const formData = setObjectToFormData(payload);
    return Call("put", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },

  createBatchCheckDetails: (payload) => {
    payload.operation_code = "ARBCC";
    const url = ApiUrl("v2/account_receivables/create_ar_batch_check_details");
    const formData = setObjectToFormData(payload);
    return Call("post", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },

  fetchBatchCheckDetails: (
    ar_batch_id = "",
    check_ach_no = "",
    account_id = "",
    status = "BOTH",
    page = 1,
    perPage = "",
    sortBy = "",
    sortOrder = ""
  ) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(
      `v2/account_receivables/get_ar_batch_check_details?${PageParams(
        page,
        perPage
      )}&ar_batch_id=${ar_batch_id}&check_ach_no=${check_ach_no}&account_id=${account_id}&status=${status}&organization_id=${organization_id}&operation_code=ARBCI`
    );
    if (!isEmpty(sortBy) && !isEmpty(sortOrder)) {
      url = `${url}&sort_by=${sortBy}&sort_order=${sortOrder}`;
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "", pagination: "meta.pagination" },
    });
  },

  updateBatchCheckDetails: (payload) => {
    payload.operation_code = "ARBCU";
    const url = ApiUrl("v2/account_receivables/update_ar_batch_check_details");
    const formData = setObjectToFormData(payload);
    return Call("put", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },

  createRemittances: (payload) => {
    payload.operation_code = "ARBCIRC";
    const url = ApiUrl("v2/account_receivables/create_check_remittances");
    const formData = setObjectToFormData(payload);
    return Call("post", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },

  fetchRemittances: (checkId, account_id, page = 1, perPage = "",sortBy = "",sortByType = "") => {
    const organization_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(
      `v2/account_receivables/get_ar_check_remittances?${PageParams(
        page,
        perPage
      )}&ar_batch_check_id=${checkId}&account_id=${account_id}&organization_id=${organization_id}&operation_code=ARBCIRI`
    );
    if (!isEmpty(sortBy) && !isEmpty(sortByType)) {
      url = `${url}&sort_by=${sortBy}&sort_order=${sortByType}`;
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "", pagination: "meta.pagination" },
    });
  },
  updateRemittances: (payload) => {
    payload.operation_code = "ARBCIRU";
    const url = ApiUrl("v2/account_receivables/update_check_remittances");
    const formData = setObjectToFormData(payload);
    return Call("put", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  deleteRemittance: (remittance_id) => {
    const url = ApiUrl(
      `v2/account_receivables/delete_check_remittances/${remittance_id}`
    );
    return Call(
      "delete",
      url,
      { operation_code: "ARBCIRD" },
      ParseGeneralResponse,
      {
        responseDataKeys: { data: "" },
      }
    );
  },
  deleteBatch: (id) => {
    const url = ApiUrl(
      `v2/account_receivables/reverting_ar_batch_check`
    );
    return Call("delete", url, { id: id }, ParseGeneralResponse, {
      responseDataKeys: { message: "message" },
    });
  },
  findReferenceNo: (account_id, reference_type, reference_no) => {
    const url = ApiUrl(
      `v2/account_receivables/get_account_invoice_number?account_id=${account_id}&reference_type=${reference_type}&reference_no=${reference_no}&operation_code=ARBCIRI`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  downloadSampleFile: () => {
    const url = ApiUrl(`v2/account_receivables/create_sample_remittence_sheet`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { file: "file" },
    });
  },
  exportCheckRemittance: (data) => {
    const formData = setObjectToFormData(data);
    const url = ApiUrl(`v2/account_receivables/import_check_remittances`);
    return Call("POST", url, formData, ParseGeneralResponse, {
      responseDataKeys: {
        msg: "message"
      },
    });
  },

  editPayAmount: (payload) => {
    const url = ApiUrl("v2/account_receivables/check_amounts_updating_post_payments");
    const formData = setObjectToFormData(payload);
    return Call("put", url, formData, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
},

accountSharing: (payload) => {
  const url = ApiUrl("v2/account_receivables/amount_switching_to_other_accounts");
  const formData = setObjectToFormData(payload);
  return Call("put", url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

};
