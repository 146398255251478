const ScreenKeys = {
  SEARCH_ORDERS_MINIMIZED: 'search_orders_minimized',
  ACCOUNTS_LISTING: 'accounts_listing',
  ACCOUNT_RECEIVABLES: 'account_receivables',
  AGING_REPORT: 'aging_report',
  BATCH_HOME_SCREEN: 'batch_home_screen',
  BILLING_SCREEN_LISTING: 'billing_screen_listing',
  CL_REPORT: 'cl_report',
  COLLECTION_REPORT: 'collection_report',
  CREDIT_LIMIT: 'credit_limit',
  CONSOLIDATED_MAWBS: 'consolidated_mawbs',
  GLCODE_REPORT : "gl_code_report",
  DMS: 'dms',
  DRIVER_LOG_REPORT: 'driver_log_report',
  DOCK_SCAN_REPORT: 'dock_scan_report',
  INVOICES_LISTING: 'invoices_listing',
  LINEHAUL_LISTING: 'linehaul_listing',
  MONTHLY_CUTOFF: 'monthly_cutoff',
  OPEN_BALANCE: 'open_balance',
  ORDER_LISTING: 'order_listing',
  ORDER_LISTING_V2: 'order_list_view_columns',
  ORDER_LISTING_V3: 'order_list_view_columns_v3',
  ORGANIZATIONS_LISTING: 'organizations_listing',
  PENDING_ORDER_LISTING: 'pending_order_listing',
  PREDEFINED_STOPS_LISTING: 'predefined_stops_listing',
  PREPLAN_MANAGEMENT_MODAL: 'preplan_management_modal',
  QUOTES_LISTING: 'quotes_listing',
  RECOVERIES_LISTING: 'recoveries_listing',
  RECOVERIES_LISTING_DISPATCH: 'recoveries_listing_dispatch',
  RELEASES_LISTING: 'releases_listing',
  RELEASES_LISTING_DISPATCH: 'releases_listing_dispatch',
  ROLES_LISTING: 'roles_listing',
  ROUTE_ORDERS_VIEW_LISTING: 'route_orders_view_listing',
  SEARCH_ORDERS: 'search_orders',
  SUMMARY_VIEW: 'summary_view',
  TEMPLATES_LISTING: 'templates_listing',
  TRUCKS_LISTING: 'trucks_listing',
  USERS_LISTING: 'users_listing',
  USERS_LISTING_SUPER_ADMIN: 'users_listing_super_admin',
  VEHICLE_TYPES_LISTING: 'vehicle_types_listing',
  VEHICLE_TYPE_MAPPING: 'vehicle_type_mapping',
  WAREHOUSE_LISTING: 'warehouse_listing',
  ACCESSORIAL_LISTING : 'accessorial_listing',
  WAREHOUSE_LISTING_SUPER_ADMIN: 'warehouse_listing_super_admin',
  DISPATCH : {
    UNASSIGNED_ORDERS: 'dispatch_unassigned_orders',
    UNASSIGNED_ORDERS_V2: 'dispatch_unassigned_orders_v2',
  },
  PREPLAN:{
    UNASSIGNED_ORDERS: 'unassigned_list_view_columns',
    UNASSIGNED_ORDERS_V2: 'preplan_unassigned_orders_v2',
  },
  CAPACITY_REPORTS_LISTING : 'capacity_report_listing',
  DUNNING_NOTICE: "dunning_notice_listing",
  INVOICE_STATUS_REPORT: 'invoice_status_report_listing',
  POD_REPORT: 'pod_report',
  EDI_STATUS_REPORT: 'edi_status_report_listing',
  QB_REPORT: 'qb_report_listing',
  AIR_CARGO_SETTLEMENTS: 'air_cargo_driver_settlements'
};

export { ScreenKeys };
