import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Link } from "../../common/UIComponents";
import BaseModal from "../BaseModal";
import { withRouter } from "react-router";
import { ARApi } from "../../api/ARApi";
import TransactionsInfoList from "./TransactionsInfoList";
import Copyable from "../common/Copyable";
import CopyableContent from "../common/CopyableContent";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";
import TransactionsCheckList from "./TranscationCheckList";
import AppConfig from "../../config/AppConfig";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
const ChequeDetails = ({ record, data, account }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transcationDetails, setTranscationDetails] = useState([]);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const paymentNo = record.pay_mode === 'CREDIT_NOTE' ? record.ar_credit_note_no : record.check_ach_no;
  const resetValues = () => {
    setTranscationDetails([]);
    setShowInfoWindow(false);
  };

  const fetchTranscationDetails = () => {
    setLoading(true);
    const payLoad = {};
    payLoad.page = tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    payLoad.per_page = AppConfig.perPage;
    ARApi.fetchCheckDetailsByCheckNo(
      record.account_id,
      record.ar_batch_check_id,
      payLoad,
      record.ar_credit_note_id
    ).then((result) => {
      if (result.success) {
        setTranscationDetails(result.data.ar_check_transactions);
        setShowInfoWindow(true);
        setPagination(result.pagination);
        setLoading(false);
      } else {
        setLoading(false);
        setTranscationDetails([]);
        renderAlertMessage(result.errors)
      }
    });
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      fetchTranscationDetails();
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      // const filter = Object.assign({}, filterPlaceHolder, {
      //   sortBy: sorter.columnKey,
      //   sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      // });
      // setFilterPlaceHolder(filter);
    }
  };

  const renderModalWindow = () => {
    const tablePagination = {
      total: pagination.total_count,
      current: pagination.current_page,
      pageSize: pagination.per_page || AppConfig.perPage,
    };
    return (
      <BaseModal
        title={
          <Row>
            {/* <Col xs={24}> {`${I18n.t("account_receivables.cheque_details")}: #${record.check_ach_no}`}</Col> */}
            <Col xs={24}>
              {" "}
              {I18n.t("batch_management.transactions_details")}
              &nbsp;
              {`${"#"}${paymentNo}`}
              &nbsp;{account?.name ? `( ${account.name}) ` : ""}{" "}
            </Col>
          </Row>
        }
        className="customer-orders-modal"
        onCancel={() => {
          resetValues();
        }}
        width="100%"
        style={{
          top: 0,
          height: "100%",
        }}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "auto",
        }}
      >
        {/* <TransactionsInfoList
          showEdit={false}
          data={transcationDetails ? transcationDetails : []}
          hideColumns={["checkAchNo", "batchNo"]}
        /> */}
        <TransactionsCheckList
          data={transcationDetails ? transcationDetails : []}
          scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
          pagination={tablePagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(pagination, filter, sorter, currentTable)
          }
        />
      </BaseModal>
    );
  };

  return (
    <Row type="flex" gutter={4}>
      <Col>
        <Link onClick={fetchTranscationDetails}>{paymentNo}</Link>
      </Col>
      { paymentNo && <Col>
        <Copyable text={paymentNo} />
      </Col>
      }
      {showInfoWindow && renderModalWindow()}
    </Row>
  );
};

ChequeDetails.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(ChequeDetails);