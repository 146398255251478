import { ApiUrl, Call, PageParams, ParseGeneralResponse, setObjectToFormData } from "./ApiUtils"

export const getDockScanReport = (data) => {
    const formData =setObjectToFormData(data)
    const url = ApiUrl(`v2/dock_scan_reports/process_dock_scan`);
    return Call("PUT", url, formData, ParseGeneralResponse,{
        responseDataKeys: {
            dockData: "",
        }
    });
}

export const getIncomingDocks = (data) => {
    const {page,perPage,whId,orgId,isExport} = data
    const url = ApiUrl(`v2/dock_scan_reports/wh_dock_scan_report?${PageParams(page,perPage)}&wh_location_id=${whId}&organization_id=${orgId}&is_export=${isExport}`);
    return Call("GET", url, {}, ParseGeneralResponse,{
        responseDataKeys: { data: "" },
    });
}