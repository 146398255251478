/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row, Spin } from '../common/UIComponents';
import DeliveryCharges from '../components/configurations/DeliveryCharges';

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
        inProgress: false,
        account: this.props.account || {},
    };
  }

  _renderTabs = () => (
    <div className="bgWhite borderRadius10 borderLine">
      <DeliveryCharges account={this.state.account} goToTab={this.props.goToTab} />
    </div>
  )

  render() {
    return (

          <div className=' blockBgColor'>
            <Spin spinning={this.state.inProgress} delay={1000} >
              <Row gutter={10}>
                <Col span={24}>
                  <this._renderTabs />
                </Col>
              </Row>
            </Spin>
          </div>
    );
  }
}

Billing.propTypes = {
  account: PropTypes.shape().isRequired,
  goToTab: PropTypes.func.isRequired,
};

export default withRouter(Billing);
