import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { checkNegitive, isEmpty } from '../../common/Common'
import QuoteSummary from './QuotesSummary'
import { Col, Row } from 'antd'
import I18n from '../../common/I18n'
import QuotesActivitiesList from "./QuotesActivitiesList"

const QuotesActivities = ({ activities, size, styleObj, account_accessorials = [],updateAddOns=[],orderType , warehouseDetails ={}}) => {
  const renderField = (fieldName, record, type, mode) =>
    checkNegitive(record[fieldName])

    const renderAccessorial = (record, type = 'activities', mode = 'view') => {

      return (
        <div className="amountUpdate textBold">
          <Row gutter={[8,16]}>
            {record.ori_accessorial_charges &&
              record.ori_accessorial_charges.length > 0 && (
                <Col span={24}>
                  <div>
                    <h3 className="label_heading textBold">
                      {I18n.t("general.accessorial")}
                    </h3>
                    <hr style={{ width: 75 }} />
                    <div className="marginTop10">
                      <span className="fontWeight500 textPrimary">
                        {record.ori_accessorial_charges
                          .map((rec) => rec.accessorial_name)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
            {record.add_on_details && record.add_on_details.length > 0 && (
              <Col span={24}>
                <div>
                  <h3 className="label_heading textBold">Custom Charges</h3>
                  <hr style={{ width: 75 }} />
                  <div className="marginTop10">
                    <span className="fontWeight500 textPrimary">
                      {record.add_on_details
                        .map((rec) => rec.add_on_name)
                        .join(", ")}
                    </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
      </div>
    );
  }

  const renderList = () => (
    <QuotesActivitiesList
      rowKey="_id"
      warehouseDetails={warehouseDetails}
      data={activities}
      size={size}
      style={{ ...styleObj }}
      pagination={{ position: 'none' }}
      expandedRowRender={(record) => {
        if (!_.isEmpty(record.quotes)) {
          let updatedRecord = { ...record.quotes };
          if (isEmpty(updatedRecord.ori_accessorial_charges)) {
            updatedRecord.ori_accessorial_charges = _.isArray(record.quotes.ind_accessorial_charges) ?
              [...record.quotes.ind_accessorial_charges] :
              []
          }
          return (<Fragment>
            {renderAccessorial(updatedRecord)}
            <QuoteSummary
              summaryDetails={updatedRecord}
              type="activities"
              mode="view"
              account_accessorials={account_accessorials}
              updateAddOns={updateAddOns}
              orderType={orderType}
            />
          </Fragment>
          )
        }
      }}
      expandable={ {
        rowExpandable: record => !_.isEmpty(record.quotes),
      } }
      rowClassName={ (record) => (_.isEmpty(record.quotes) ? 'no-expand-icon' : '') }
    />
  )

  return <Fragment>{renderList()}</Fragment>
}

QuotesActivities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape).isRequired,
}

export default QuotesActivities
