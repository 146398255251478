import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import PropTypes from 'prop-types';
import { Button, Col, Checkbox, Row, Switch, Alert } from "antd";
import _ from 'lodash';

const AccessorialGridComponent = (props) => {
  const {
    columnDefs,
    rowData,
    additionalColumnDefs = {},
    onRowSelection,
    saveColumnWidth,
    onColumnMoved,
    onSortChanged,
    setCurrentGridRef,
    showRerraangeModal,
    closeModal,
    handleColumnVisibilityChange,
    rawDataSource
  } = props;
  const gridApiRef = useRef(null);
  const [ visibleCols, setVisibleCols ] = useState([]);

  const defaultColDef = React.useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      lockPinned : true,
      suppressMenu: true,
      floatingFilter: true,
      ...additionalColumnDefs
    };
  }, [ additionalColumnDefs ]);

  const onGridReady = params => {
    gridApiRef.current = params.api;
    setCurrentGridRef(params.api);
  };


  const handleColumnResized = useCallback((event) => {
    if(event.finished) {
      const column = event.column;
      if(!column) return;
      const newWidth = column.actualWidth;
      const colId = column.colId;
      const colState = gridApiRef.current.getColumnState();
      saveColumnWidth({colId, newWidth , colState , columnDefs});

    }
  }, []);


  const selectAllColumns = () => {
    const allColumns = _.get(props, 'totalCols', []).filter(obj => obj.headerName || obj.headerName === '' || !_.has(obj, "headerName"));
    setVisibleCols(allColumns);
  };

  const handleColumnMoved = useCallback((event) => {
    if(event.finished) {
      const column = event.column;
      if(!column) return;
      const colId = column.colId;
      const colIndex = event.toIndex;
      const colState = gridApiRef.current.getColumnState();
      onColumnMoved(colState);
    }
  }, []);

  const onSelectionChanged = useCallback((event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => {
      return {
        id: node.data.id,
        data: node.data
      };
    });
    const selectedIds = selectedData.map(node => node.id);
    const selectedOrderData = selectedData.map(node => node.data);

    onRowSelection(selectedIds, selectedOrderData);
  }, []);

  const clearSort = useCallback(() => {
    gridApiRef.current.applyColumnState({
      defaultState: { sort: null },
    });
  }, []);

  const saveSort = useCallback(() => {
    var colState = gridApiRef.current.getColumnState();
    var sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    // savedSort = sortState;
    // onSortChanged(sortState);
    // if sortState is empty then just reset sort to default
    if (sortState.length === 0) {
      clearSort();
    } else {
      onSortChanged(sortState);
    }
  }, []);

  useEffect(() => {
    setVisibleCols(columnDefs);
  }, [ columnDefs ]);

  const isRowSelectable = useCallback((rowNode) => {
    return props.isRowSelectable ? props.isRowSelectable(rowNode) : true;
  }
    , []);

  return (
    <div style={ { height: '100%' } }>
      <Col span={ 24 } style={ { height: '100%' } }>
        <div className="ag-theme-alpine" style={ { height: '100%', width: '100%', '--ag-font-size': '10px' } }>
          <AgGridReact
            headerHeight={ 30 }
            floatingFiltersHeight={ 33 }
            rowHeight={ 22 }
            columnDefs={ visibleCols }
            rowData={ rowData }
            onSelectionChanged={ onSelectionChanged }
            onSortChanged={ saveSort }
            onGridReady={ onGridReady }
            rowSelection="multiple"
            defaultColDef={ defaultColDef }
            onColumnResized={ handleColumnResized }
            onColumnMoved={ handleColumnMoved }
            onColumnVisible={ handleColumnVisibilityChange }
            suppressDragLeaveHidesColumns
            isRowSelectable={ isRowSelectable } 
          />
        </div>
      </Col>
    </div>
  );
};

const checkIsEqualProp = (prevProps, nextProps, keys) => {
  return _.every(keys, (key) => _.isEqual(prevProps[key], nextProps[key]));
}

export default React.memo(AccessorialGridComponent, (prevProps, nextProps) => {
  return checkIsEqualProp(prevProps, nextProps, [ 'columnDefs', 'rowData', 'showRerraangeModal' ]);
})


AccessorialGridComponent.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  additionalColumnDefs: PropTypes.object,
  fetchData: PropTypes.func,
  saveSort: PropTypes.func,
  onRowSelection : PropTypes.func,
  onSortChanged : PropTypes.func,
  saveColumnWidth: PropTypes.func,
  onColumnMoved: PropTypes.func,
  showQuickFilter: PropTypes.bool,
  totalCols: PropTypes.array,
  setCurrentGridRef: PropTypes.func,
  showRerraangeModal: PropTypes.bool,
};

AccessorialGridComponent.defaultProps = {
  additionalColumnDefs: {},
  fetchData: () => { },
  saveSort: () => { },
  onRowSelection: () => { },
  onSortChanged : () => {},
  saveColumnWidth: () => { },
  onColumnMoved: () => { },
  showQuickFilter: false,
  totalCols: [],
  setCurrentGridRef: () => { },
  showRerraangeModal: false,
};