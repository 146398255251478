import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card,  Icon } from '../../common/UIComponents';
import moment from 'moment';
import {  doFormate } from '../../common/Common';
import Leg from '../../components/Tasks/Legs';
import load_grey_img from '../../../assets/images/load_grey';
import load_green_img from '../../../assets/images/load_green';
import unload_grey_img from '../../../assets/images/unload_grey';
import unload_green_img from '../../../assets/images/unload_green';
import install_grey_img from '../../../assets/images/install_grey';
import install_green_img from '../../../assets/images/install_green';
import uninstall_grey_img from '../../../assets/images/uninstall_grey';
import uninstall_green_img from '../../../assets/images/uninstall_green';
import FormIcon from '../common/FormIcon';

class StopItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_item: this.props.order_item,

        };
    }

    fomatString = (str, format = '') => {
        return doFormate(str, format);
    }

    static getDerivedStateFromProps(props,state){
        return props.order_item;
    }


    render() {
        const { order_item } = this.state;
        const item = {
            id: order_item.id,
            item_name: this.fomatString(order_item.item_name),
            quantity: this.fomatString(order_item.item_quantity),
            type: 'drop',
            return_model: '',
            return_serial: '',
            new_model: this.fomatString(order_item.item_model),
            new_serial: this.fomatString(order_item.serial_number),
            services_ids: order_item.service_ids,
            item_model: this.fomatString(order_item.item_model),
            item_serial: this.fomatString(order_item.serial_number),
            service_load_opted: order_item.service_load_opted && order_item.service_load_opted ? true : false,
            service_load_completed: order_item.service_load_completed ? moment(new Date(order_item.service_load_completed)).format('lll') : '',
            service_unload_opted: order_item.service_load_opted && order_item.service_load_opted ? true : false,
            service_unload_completed: order_item.service_unload_completed ? moment(new Date(order_item.service_unload_completed)).format('lll') : '',
            service_install_opted: order_item.service_install_opted && order_item.service_install_opted ? true : false,
            service_install_completed: order_item.service_install_completed ? moment(new Date(order_item.service_install_completed)).format('lll') : '',
            service_uninstall_opted: order_item.service_uninstall_opted && order_item.service_uninstall_opted ? true : false,
            service_uninstall_completed: order_item.service_uninstall_completed ? moment(new Date(order_item.service_uninstall_completed)).format('lll') : '',
            signature: order_item.signature ? order_item.signature : '',
            pictures: order_item.pictures ? order_item.pictures : [],
            failed_notes: order_item.failed_notes,

        };




        if (item.service_load_opted || item.service_unload_opted || item.service_install_opted || item.service_uninstall_opted) {
            item["is_services_opted"] = true;
        }
        item["leg_details"] = order_item.leg_details ? order_item.leg_details : [];
        item["pictures"] = order_item.pictures.map(pic => {
            return pic.picture.url;
        });
        item['signature'] = order_item.signature && order_item.signature.url ? order_item.signature.url : '';

        return (
            <div>
                <Card style={{ marginBottom: 10 }} >

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row>
                                <Col sm={2} xs={2} md={2} lg={2}>
                                    <div className="textCenter">
                                        <Icon type="shopping" />
                                    </div>
                                </Col>
                                <Col sm={6} xs={6} md={4} lg={4}>
                                    <div className="info-text textBold">Item</div>
                                </Col>
                                <Col xs={2}>:</Col>
                                <Col sm={15} xs={15} md={16} lg={16}>
                                    {
                                        <div className="info-text">
                                            <span>{item.item_name}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row>
                                <Col sm={2} xs={2} md={2} lg={2}>
                                    <div className="textCenter">
                                        <Icon type="number" />
                                    </div>
                                </Col>
                                <Col sm={10} xs={13} md={7} lg={7}>
                                    <div className="info-text textBold">Serial No</div>
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col sm={11} xs={8} md={12} lg={12}>
                                    {
                                        <div className="info-text">
                                            <span>{item.item_serial}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row>
                                <Col sm={2} xs={2} md={2} lg={2}>
                                    <div className="textCenter">
                                        <Icon type="number" />
                                    </div>
                                </Col>
                                <Col sm={6} xs={6} md={4} lg={4}>
                                    <div className="info-text textBold">Quantity</div>
                                </Col>
                                <Col xs={2}>:</Col>
                                <Col sm={15} xs={15} md={12} lg={12}>
                                    {
                                        <div className="info-text">
                                            <span>{item.quantity}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Row>
                                <Col sm={2} xs={2} md={2} lg={2}>
                                    <div className="textCenter">
                                        <Icon type="qrcode" />
                                    </div>
                                </Col>
                                <Col sm={10} xs={13} md={7} lg={7}>
                                    <div className="info-text textBold">Model No</div>
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col sm={11} xs={8} md={12} lg={12}>
                                    {
                                        <div className="info-text">
                                            <span>{item.item_model}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Row>
                                <Col sm={2} xs={2} md={1} lg={1}>
                                    <div className="textCenter">
                                        <FormIcon/>
                                         &nbsp;
                                    </div>
                                </Col>
                                <Col sm={10} xs={10} md={2} lg={2}>
                                    <div className="info-text textBold">Notes</div>
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col sm={11} xs={11} md={20} lg={20}>
                                    {
                                        <div className="info-text">
                                            <span>{item.notes ? item.notes : 'NA'}</span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            {item['is_services_opted'] && <Row>
                                <Col sm={2} xs={2} md={1} lg={1}>
                                    <div className="textCenter">
                                        <Icon type="control" />
                                    </div>
                                </Col>
                                <Col sm={10} xs={10} md={2} lg={2}>
                                    <div className="info-text textBold">Services</div>
                                </Col>
                                <Col xs={1}>:</Col>

                                <Col sm={11} xs={11} md={24} lg={24} className="textBlack marginTop20">
                                    <Row>
                                       
                                        {item.service_load_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_load_completed ? load_green_img : load_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col>Load</Col>
                                                <Col>{item.service_load_completed} </Col>
                                            </Col>
                                        }
                                        {item.service_uninstall_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_uninstall_opted ? uninstall_green_img : uninstall_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col> Uninstall</Col>
                                                <Col>{item.service_uninstall_completed}</Col>
                                            </Col>
                                        }
                                        {item.service_install_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_install_opted ? install_green_img : install_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col>Install</Col>
                                                <Col>{item.service_install_completed}</Col>
                                            </Col>
                                        }

                                        {item.service_unload_opted &&
                                            <Col xs={12} sm={8} lg={6} md={6} className="alignCenter">
                                                <img src={item.service_unload_opted ? unload_green_img : unload_grey_img} style={{ width: 40, height: 40 }} /><br />
                                                <Col> UnInstall</Col>
                                                <Col>{item.service_unload_completed}</Col>
                                            </Col>
                                        }
                                        <Col xs={2}></Col>
                                    </Row>

                                </Col>
                            </Row>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Leg pictures={item.pictures} signature={item.signature} pagination={{ position: 'none' }} size={'small'} />
                        </Col>

                    </Row>



                </Card>
            </div>
        );
    }
}
StopItemDetails.propTypes = {
    order_item: PropTypes.shape().isRequired,
};
export default StopItemDetails;