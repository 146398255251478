import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import React from "react";
import moment from 'moment';

class DriverDetailedList extends BaseList {
  constructor(props) {
    super(props);

    this.state = {
      dateColumns: this.props.dateColumns,
    }

    this.setColumns(this.state.dateColumns);
  }

  setColumns(dateColumns) {
    const dynamicDateColumns = dateColumns.map((item) => ({
      title: <b>{moment(item).format("MMM DD")}</b>,
      dataIndex: `${item}`,
      key: `date_${item}`,
    }));

    this.columns = [
      {
        key: "driver_name",
        title: <b>{I18n.t("driver_monthly_report.driver_name")}</b>,
        dataIndex: "driver_name",
        render: (data, record) => <span>{data}</span>,
        fixed: dynamicDateColumns.length > 8 ? 'left' : false,
      },
      {
        key: "driver_code",
        title: <b>{I18n.t("driver_monthly_report.driver_code")}</b>,
        dataIndex: "driver_code",
        render: (data, record) => <span>{data}</span>,
        fixed: dynamicDateColumns.length > 8 ? 'left' : false,
      },
      {
        key: "total_routes",
        title: <b>{I18n.t("driver_monthly_report.driver_routes")}</b>,
        dataIndex: "total_routes",
        render: (data, record) => <span>{data}</span>,
        fixed: dynamicDateColumns.length > 8 ? 'left' : false,
      },
    ].concat(dynamicDateColumns);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateColumns !== this.props.dateColumns) {
      this.setState({
        dateColumns: this.props.dateColumns,
      }, () => {
        this.setColumns(this.state.dateColumns);
      });
    }
  }
}

export default DriverDetailedList;