/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Icon,Tooltip } from "../../common/UIComponents";

const StopAddress= ({ address, showTooltip = true }) => {
  return (
    <Row>
      <Col xs={24} className="paddingTop5">
        <div
          className="fontSize10 smallText alignLeft"
          style={{ overflow: "hidden", height: 15 }}
        >
          <Tooltip
            title={showTooltip ? address : undefined}
            // content={address}
            // overlayStyle={{ width: 250 }}
            placement="topLeft"
          >
            <Icon type="environment" /> &nbsp;{address}
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};

export default StopAddress;

StopAddress.propTypes = {
  address: PropTypes.string.isRequired,
};

StopAddress.defaultProps = {
  address: "",
};