/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  Row,
  Spin,
  Empty,
  Button,
  Icon,
  Collapse,
  Card,
  Popconfirm,
  Tooltip,
  Checkbox,
} from "../../common/UIComponents";
import { alertMessage, randomNumber, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../common/FormErrors";
// import RateCard from "./RateCard";
import BaseModal from "../BaseModal";
import WeightForm from "./WeightForm";
import { fetchAccountZones, saveZoneMinCharge } from "../../api/BillingZones";
import {
  deleteLos,
} from "../../api/Los";
import LosForm from "../billing/LosForm";
import AppConfig from "../../config/AppConfig";
import MatrixList from "./MatrixList";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import { fetchAdjustmentCategoryDetails, fetchCategories, saveCategoryData } from "../../api/AdjustmentsApi";

const { Panel } = Collapse;
class DriverRateMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weights: [],
      zones: [],
      data: [],
      inProgress: false,
      account: this.props.account || [],
      currentConfigObj: {},
      errors: [],
      rateConfig: [],
      losIsNew: false,
      editEnabledLos: {},
      isEditing: false,
      driver_rate_params: this.props.driver_rate_params || [],
      currentAdjustment: this.props.adjustment || {},
      currentCategory: {},
      currentParamDetails: {},
      category_configuration: {},
    };
  }

  clearForm = () => {
    this.setState({
      currentAccessorial: {},
      isNew: true,
    });
  };

  componentDidMount() {
    this.setInitializeData();
  }

  // componentWillReceiveProps() {
  //   this.setInitializeData();
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.category_configuration,
        this.state.category_configuration
      )
    ) {
     // this.setInitializeData();
    }
  }

  setInitializeData = () => {
    this.getAccountZones(() => {
      this.getCategories();
    });
  };

  getAccountZones = (cb = null) => {
    fetchAccountZones(this.state.account.id).then((result) => {
      if (result.success) {
        const zones = result.zones || [];
        this.setState(
          {
            zones: zones.map((zone) => ({
              zone_id: zone.id,
              zone_name: zone.name,
              // min_charge: zone.min_charge,
            })),
            inProgress: false,
          },
          () => {
            cb();
          }
        );
      }
    });
  };

  getCategories = (cb) => {
    const { currentAdjustment } = this.state;
    this.setState({ inProgress: true });
    fetchCategories().then((result) => {
      if (result.success) {
        const categories = result.categories || [];
        const currentCategory = _.find(categories, {
          code: currentAdjustment.category_code,
        });
        this.setState(
          {
            categories,
            inProgress: false,
            currentCategory: isEmpty(currentCategory) ? {} : currentCategory,
          },
          () => {
            this.getParamDetails();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getParamDetails = () => {
    fetchAdjustmentCategoryDetails(
      this.state.account.id,
      this.state.currentAdjustment.id,
      this.state.currentAdjustment.code
    ).then((result) => {
      if (result.success) {
        const currentParamDetails = result?.details ? result.details : {};
        const weight_params =  currentParamDetails?.weight_params &&  currentParamDetails.weight_params.length > 0 ?  currentParamDetails.weight_params[0] : {};
        this.setState(
          {
            currentParamDetails,
            category_configuration: {
                with_cwt: weight_params?.with_cwt ? weight_params.with_cwt : "false",
                is_smp_applied: weight_params?.is_smp_applied ? weight_params.is_smp_applied : "false",
            }
          },
          () => {
            this.getRateObjects();
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
        this.getRateObjects();
      }
    });
  };

  formPayload = (rateConfig) => {
    const { account, category_configuration } = this.state;
    const driver_rate_params = [];
    const rateParam = {};
    rateConfig.forEach((rateConfigObj) => {
      rateParam[rateConfigObj.config_id] = [];
      const weights = rateConfigObj.weights
        ? rateConfigObj.weights.filter((wt) => wt.isNew !== true)
        : [];
      const zones = rateConfigObj.zones || [];
      zones.forEach((zone) => {
        const obj = {
          zone_id: zone.zone_id,
          min_charge: zone?.min_charge ? parseFloat(zone.min_charge) : "",
          max_charge: zone?.max_charge ? parseFloat(zone.max_charge) : "",
          weight_params: [],
        };
        const weight_params = [];
        weights.forEach((wt) => {
          const zoneCost = zone[wt.id]?.cost ? zone[wt.id].cost : 0.0;
          weight_params.push({
            min_weight: wt.min_weight,
            max_weight: wt.max_weight,
            value: parseFloat(zoneCost),
          });
        });
        obj.weight_params = weight_params;
        rateParam[rateConfigObj.config_id].push(obj);
      });
    });
    driver_rate_params.push({...rateParam, ...category_configuration});
    const data = {
      organization_id: account.organization_id,
      org_id: account.organization_id,
      account_id: account.id,
      account_adjustment_id: !isEmpty(this.state.currentAdjustment)
            ? this.state.currentAdjustment.id
            : "",
      adjustment_code: !isEmpty(this.state.currentAdjustment)
            ? this.state.currentAdjustment.code
            : "",
      weight_params: driver_rate_params,
    };
    this.saveRate(rateConfig, data);
  };

  saveRate = (rateConfig, data = {}) => {
    const errors = []; //doValidate(requiredFields, data);
    if (errors.length === 0) {
    saveCategoryData(data).then((result) => {
          if (result.success) {
            alertMessage("Saved Successfully!");
            const driverRate = result?.account_adjustment_param ? result.account_adjustment_param : {};
            this.setState({
              currentParamDetails: driverRate,
              rateConfig,
              driver_rate_params: data.driver_rate_params || [],
              isNew: false,
              errors: [],
            });
          } else {
            this.setState({
              errors: result.errors || [],
            });
          }
        });
    } else {
      this.setState({
        errors,
      });
    }
  };

  getRateObjects = () => {
    this.setState({ inProgress: true });
    const { currentParamDetails = {}, category_configuration= {}} = this.state;
    const result =  currentParamDetails?.weight_params?.length > 0 ?  currentParamDetails.weight_params[0] : {};
    const driver_rate_params = {
        fmp: result.fmp ? result.fmp : []
    };
    if (category_configuration?.is_smp_applied != "false") {
        driver_rate_params.smp = result.smp ? result.smp : []
    } 
    this.setState({
        category_configuration,
        driver_rate_params,
    }, () => {
        this.setRateData(driver_rate_params);
    })
    
  };

  setRateData = (result) => {
    const rateConfig = result;
    this.setState(
      {
        rateConfig: Object.keys(rateConfig).map((record) => {
          const rateConfigObj = {
            id: record,
            config_id: record,
            zones: _.cloneDeep(this.state.zones),
            weights: [],
            rates: rateConfig[record],
          };
          return rateConfigObj;
        }),
        inProgress: false,
      },
      () => {
        this.renderRateData();
      }
    );
  };

  updateRateData = (result, configId, zoneId) => {
    const { rateConfig, editEnabledLos } = this.state;
    const newLoses = result.los || [];
    const existingLosIndex = _.findIndex(rateConfig, ["config_id", configId]);
    if (existingLosIndex >= 0) {
      const existingLos = _.find(rateConfig, { config_id: configId });
      const rateConfigObj = _.find(newLoses, { config_id: configId });
      const zoneRates = rateConfigObj.rates.filter(
        (rate) => rate.zone_id === zoneId
      );
      const losZones = existingLos.zones || [];
      const weightsData = !isEmpty(existingLos.weights)
        ? existingLos.weights
        : [];
      zoneRates.forEach((record) => {
        let tempId = randomNumber();
        const existRecord = _.find(weightsData, {
          min_weight: record.min_weight,
          max_weight: record.max_weight,
        });
        if (
          isEmpty(existRecord) &&
          !isEmpty(record.min_weight) &&
          !isEmpty(record.max_weight)
        ) {
          weightsData.push({
            id: tempId,
            min_weight: record.min_weight,
            max_weight: record.max_weight,
            isNew: false,
            with_cwt: !isEmpty(record.with_cwt) ? record.with_cwt : "",
            range_type: !isEmpty(record.range_type) ? record.range_type : "",
          });
        } else if (!isEmpty(existRecord)) {
          tempId = existRecord.id;
        }
        const zoneIndex = _.findIndex(losZones, { zone_id: record.zone_id });
        if (zoneIndex < 0) {
          losZones.push({
            zone_id: record.zone_id,
            zone_name: "",
            [tempId]: {
              key: record.id,
              cost: parseFloat(record.amount),
              breakpoint_weight: !isEmpty(record.breakpoint_weight)
                ? record.breakpoint_weight
                : "",
              isEditable: false,
              min_amount: parseFloat(record.min_amount),
              max_amount: parseFloat(record.max_amount),
              weight_min_max_charge_editable: false,
            },
          });
        } else {
          losZones[zoneIndex][tempId].cost = parseFloat(record.amount);
          losZones[zoneIndex][tempId].min_amount = parseFloat(
            record.min_amount
          );
          losZones[zoneIndex][tempId].max_amount = parseFloat(
            record.max_amount
          );
          losZones[zoneIndex][tempId].breakpoint_weight = !isEmpty(
            record.breakpoint_weight
          )
            ? record.breakpoint_weight
            : "";
        }
      });

      const zoneInfo = existingLos.zone_info || [];

      losZones.forEach((zone, index) => {
        const minWeightRecord = _.find(zoneInfo, { zone_id: zone.zone_id });
        const isValidRecord =
          !isEmpty(minWeightRecord) && _.isObject(minWeightRecord);
        losZones[index].min_charge =
          isValidRecord && minWeightRecord.min_charge
            ? parseFloat(minWeightRecord.min_charge)
            : 0.0;
        losZones[index].max_charge =
          isValidRecord && minWeightRecord.max_charge
            ? parseFloat(minWeightRecord.max_charge)
            : 0.0;
      });
      const weights = _.sortBy(weightsData, "min_weight");
      const hasMaxWeight =
        weights.filter(
          (weight) => weight.max_weight === AppConfig.rangeMaxValue
        ).length > 0;
      // if(!hasMaxWeight){
      //   weights.push({
      //     id: randomNumber(),
      //     min_weight: null,
      //     max_weight: null,
      //     isNew: true,
      //   });
      // }

      existingLos.zones = losZones;
      existingLos.weights = weights;
      rateConfig[existingLosIndex] = _.cloneDeep(existingLos);
      this.setState({
        rateConfig,
      });
    }
  };

  renderRateData = () => {
    const { rateConfig, editEnabledLos, category_configuration = {}} = this.state;
    const zones = [...this.state.zones];
    this.setState({ inProgress: true });
    rateConfig.forEach((rateConfigObj, configIndex) => {
      const rates = rateConfigObj.rates ? [...rateConfigObj.rates] : [];
      const losZones = rateConfigObj.zones;
      const los = Object.assign({}, rateConfigObj);
      const weightsData = isEmpty(los.weights) ? los.weights : [];
      rates.forEach((record) => {
        const zoneIndex = _.findIndex(losZones, { zone_id: record.zone_id });
        if (zoneIndex >= 0) {
          losZones[zoneIndex].min_charge = record.min_charge
            ? parseFloat(record.min_charge)
            : "";
          losZones[zoneIndex].max_charge = record.max_charge
            ? parseFloat(record.max_charge)
            : "";

          const weightParams = record.weight_params || [];
          weightParams.forEach((wp) => {
            let tempId = randomNumber();
            const existRecord = _.find(weightsData, {
              min_weight: wp.min_weight,
              max_weight: wp.max_weight,
            });
            if (
              isEmpty(existRecord) &&
              !isEmpty(wp.min_weight) &&
              !isEmpty(wp.max_weight)
            ) {
              const with_cwt =
                rateConfigObj?.id === "smp"
                  ? "with_cwt"
                  : rateConfigObj?.id === "fmp"
                  ? "with_cwt"
                  : "";
              weightsData.push({
                id: tempId,
                min_weight: wp.min_weight,
                max_weight: wp.max_weight,
                isNew: false,
                with_cwt:
                  !isEmpty(with_cwt) &&
                  !isEmpty(category_configuration[with_cwt])
                    ? category_configuration[with_cwt]
                    : "",
                range_type: !isEmpty(record.range_type)
                  ? record.range_type
                  : "",
              });
            } else if (!isEmpty(existRecord)) {
              tempId = existRecord.id;
            }
            losZones[zoneIndex][tempId] = {
              key: record.id,
              cost: parseFloat(wp.value),
              breakpoint_weight: !isEmpty(wp.breakpoint_weight)
                ? wp.breakpoint_weight
                : "",
              min_amount: !isEmpty(wp.min_amount)
                ? parseFloat(wp.min_amount)
                : "",
              max_amount: !isEmpty(wp.max_amount)
                ? parseFloat(wp.max_amount)
                : "",
              weight_min_max_charge_editable:
                editEnabledLos[rateConfigObj.config_id] &&
                editEnabledLos[rateConfigObj.config_id][record.zone_id] &&
                editEnabledLos[rateConfigObj.config_id][record.zone_id][
                  tempId
                ] &&
                !isEmpty(
                  editEnabledLos[rateConfigObj.config_id][record.zone_id][
                    tempId
                  ]["min_amount"]
                )
                  ? true
                  : false,
              isEditable:
                editEnabledLos[rateConfigObj.config_id] &&
                editEnabledLos[rateConfigObj.config_id][record.zone_id] &&
                editEnabledLos[rateConfigObj.config_id][record.zone_id][
                  tempId
                ] &&
                !isEmpty(
                  editEnabledLos[rateConfigObj.config_id][record.zone_id][
                    tempId
                  ]["cost"]
                )
                  ? true
                  : false,
            };
          });
        }
      });
      losZones.forEach((zone, index) => {
        losZones[index].min_max_charge_editable = false;
        weightsData.forEach((weightRecord) => {
          if (isEmpty(losZones[index][weightRecord.id])) {
            losZones[index][weightRecord.id] = {
              key: "",
              cost: "",
              breakpoint_weight: "",
              isEditable: false,
            };
          }
        });
      });
      const weights = _.sortBy(weightsData, "min_weight");
      const hasMaxWeight =
        weights.filter(
          (weight) => weight.max_weight === AppConfig.rangeMaxValue
        ).length > 0;
      if (!hasMaxWeight) {
        weights.push({
          id: randomNumber(),
          min_weight: null,
          max_weight: null,
          isNew: true,
          los_range: los.configurations?.los_range
            ? los.configurations.los_range
            : "",
        });
      }

      los.zones = losZones;
      los.weights = weights;
      rateConfig[configIndex] = los;
    });
    this.setState({
      inProgress: false,
      rateConfig,
    });
  };

  handleOnChange = (index, element, value) => {
    this.props.onChange(index, element, value);
  };

  handleDeleteWeight = (record, configId) => {
    const { rateConfig, currentConfigObj, category_configuration = {} } = this.state;
    const editEnabledLos = _.cloneDeep(this.state.editEnabledLos);
    const configIndex = _.findIndex(rateConfig, {
      config_id: configId,
    });
    if (configIndex >= 0) {
      const { weights = [], zones = [] } = rateConfig[configIndex];
      zones.forEach((zone, index) => {
        if (editEnabledLos?.configId?.[zone.zone_id]?.record.id) {
          delete editEnabledLos.configId[zone.zone_id].record.id;
        }
        delete zone[record.id];
        zones[index] = zone;
      });
      const newWeights = weights.filter((wt) => wt.id !== record.id);
      if (record.max_weight === AppConfig.rangeMaxValue) {
        const with_cwt = this.getAppltCwtValue(currentConfigObj);
        newWeights.push({
          id: randomNumber(),
          min_weight: null,
          max_weight: null,
          isNew: true,
          with_cwt: !isEmpty(category_configuration[with_cwt])
            ? category_configuration[with_cwt]
            : "",
          range_type: !isEmpty(record.range_type) ? record.range_type : "",
        });
      }

      rateConfig[configIndex].weights = [...newWeights];
      rateConfig[configIndex].zones = zones;
      this.setState(
        {
          inProgress: false,
          showWeightForm: false,
          editEnabledLos,
        },
        () => {
          this.formPayload(rateConfig);
        }
      );
    }
  };

  editWeightRange = (configId, currentVals, configurations) => {
    const { los_range } = configurations;
    const currentConfigObj = _.find(this.state.rateConfig, {
      config_id: configId,
    });
    this.setState({
      currentConfigObj,
      showWeightForm: true,
      currentRatecard: {
        ...this.state.currentRatecard,
        ...currentVals,
        los_range,
      },
      oldWeightVals: currentVals,
      losIsNew: false,
      isEditing: true,
    });
  };
  computeModalTitle = () => {
    const { currentRatecard } = this.state;
    switch (currentRatecard.los_range) {
      case "PALLET":
        return currentRatecard.isNew
          ? I18n.t("account.billing.pallet.add")
          : I18n.t("account.billing.pallet.update");
      case "WEIGHT":
        return currentRatecard.isNew
          ? I18n.t("account.billing.weights.add")
          : I18n.t("account.billing.weights.update");
      case "CABINET":
        return currentRatecard.isNew
          ? I18n.t("account.billing.cabinet.add")
          : I18n.t("account.billing.cabinet.update");
      case "HOURS":
        return currentRatecard.isNew
          ? I18n.t("account.billing.hours.add")
          : I18n.t("account.billing.hours.update");
      case "MILES":
        return currentRatecard.isNew
          ? I18n.t("account.billing.miles.add")
          : I18n.t("account.billing.miles.update");
      case "ITEMS":
        return currentRatecard.isNew
          ? I18n.t("account.billing.orderItem.add")
          : I18n.t("account.billing.orderItem.update");
      case "PER_CUBE":
        return currentRatecard.isNew
          ? I18n.t("account.billing.perCube.add")
          : I18n.t("account.billing.perCube.update");
      default:
        return currentRatecard.isNew
          ? I18n.t("account.billing.weights.add")
          : I18n.t("account.billing.weights.update");
    }
  };

  renderWeightModal = () => {
    return (
      <BaseModal
        title={this.computeModalTitle()}
        onCancel={() => this.closeWeightForm()}
        width="40%"
        style={{ top: 50 }}
      >
        <WeightForm
          currentRatecard={this.state.currentRatecard}
          currentConfigObj={this.state.currentConfigObj}
          handleSave={this.handleWeightSave}
          weights={
            this.state.currentConfigObj?.weights
              ? this.state.currentConfigObj.weights
              : []
          }
          deleteClick={this.handleDeleteWeight}
          isEditing={this.state.isEditing}
        />
      </BaseModal>
    );
  };

  closeWeightForm = () => {
    this.setState({
      currentRatecard: {},
      showWeightForm: false,
      isEditing: false,
    });
  };

  handleWeightForm = (configId, record) => {
    const currentConfigObj = _.find(this.state.rateConfig, {
      config_id: configId,
    });
    this.setState({
      currentConfigObj,
      currentRatecard: record,
      showWeightForm: true,
    });
  };

  // clearing input values for existing ones to show only once at a time
  showInputValue = (configId, zoneID, weightRecordId, value, type) => {
    const rateConfig = [...this.state.rateConfig];
    const { editEnabledLos } = this.state;
    const configIndex = _.findIndex(rateConfig, { config_id: configId });
    if (
      configIndex >= 0 &&
      !isEmpty(editEnabledLos[configId]) &&
      _.isObject(editEnabledLos[configId])
    ) {
      const currentConfigObj = rateConfig[configIndex];
      const zones = [...currentConfigObj.zones];
      Object.keys(editEnabledLos[configId]).forEach((zone) => {
        const currentZoneIndex = _.findIndex(zones, ["zone_id", zone]);
        if (
          currentZoneIndex >= 0 &&
          !isEmpty(editEnabledLos[configId][zone]) &&
          _.isObject(editEnabledLos[configId][zone])
        ) {
          const currentZone = zones[currentZoneIndex];
          Object.keys(editEnabledLos[configId][zone]).forEach((element) => {
            if (["min_charge", "max_charge"].includes(element)) {
              currentZone.min_max_charge_editable = false;
            } else {
              if (isEmpty(currentZone[element])) {
                currentZone[element] = {};
              }
              if (type === "range_min_max_charge") {
                currentZone[element].weight_min_max_charge_editable = false;
              } else {
                currentZone[element].isEditable = false;
              }
            }
          });
          zones[currentZoneIndex] = currentZone;
        }
      });
      rateConfig[configIndex] = currentConfigObj;
      delete editEnabledLos[configId];
      this.setState(
        {
          editEnabledLos,
          rateConfig,
        },
        () => {
          this.navigateToFunction(
            configId,
            zoneID,
            weightRecordId,
            value,
            type
          );
        }
      );
    } else {
      this.navigateToFunction(configId, zoneID, weightRecordId, value, type);
    }
  };

  navigateToFunction = (configId, zoneID, weightRecordId, value, type = "") => {
    if (type === "min_max_charge") {
      this.handleZoneMinCharge(configId, zoneID, value);
    } else {
      this.handleZoneWeightForm(configId, zoneID, weightRecordId, value, type);
    }
  };

  handleZoneWeightForm = (
    configId,
    zoneID,
    weightRecordId,
    value,
    type = "weight",
    cb = null
  ) => {
    const rateConfig = [...this.state.rateConfig];
    const configIndex = _.findIndex(rateConfig, { config_id: configId });
    if (configIndex >= 0) {
      const currentConfigObj = rateConfig[configIndex];
      const zones = [...currentConfigObj.zones];
      const currentZoneIndex = _.findIndex(zones, ["zone_id", zoneID]);
      if (currentZoneIndex >= 0) {
        const currentZone = zones[currentZoneIndex];
        if (currentZone[weightRecordId]) {
          currentZone[weightRecordId] = {
            ...currentZone[weightRecordId],
          };
        }
        if (type === "weight") {
          currentZone[weightRecordId].isEditable = value;
          this.handleOnChangeRateMatrix(
            currentConfigObj.config_id,
            zoneID,
            weightRecordId,
            "cost",
            currentZone[weightRecordId].cost
          );
        } else if (type === "range_min_max_charge") {
          currentZone[weightRecordId].weight_min_max_charge_editable = value;
          this.handleOnChangeRateMatrix(
            currentConfigObj.config_id,
            zoneID,
            weightRecordId,
            "range_min_max_charge",
            {
              min_amount: currentZone[weightRecordId].min_amount,
              max_amount: currentZone[weightRecordId].max_amount,
            }
          );
        }

        zones[currentZoneIndex] = currentZone;
      }
      currentConfigObj.zones = [...zones];
      rateConfig[configIndex] = currentConfigObj;
      this.setState(
        {
          rateConfig,
        },
        cb
      );
    }
  };

  getAppltCwtValue = (currentConfigObj) => {
    const with_cwt =
      currentConfigObj.config_id === "smp"
        ? "with_cwt"
        : currentConfigObj.config_id === "fmp"
        ? "with_cwt"
        : "";
    return with_cwt;
  };

  handleWeightSave = (record) => {
    if (this.state.isEditing) {
      this.setState({ inProgress: true });
      const { currentConfigObj, rateConfig } = this.state;
      const configIndex = _.findIndex(rateConfig, {
        config_id: currentConfigObj.config_id,
      });
      if (configIndex >= 0) {
        const { weights = [], zones = [] } = rateConfig[configIndex];
        const weightIndex = _.findIndex(weights, { id: record.id });
        if (weightIndex >= 0) {
          weights[weightIndex].min_weight = record.min_weight;
          weights[weightIndex].max_weight = record.max_weight;
          let newWeights = [...weights];
          if (record.max_weight === AppConfig.rangeMaxValue) {
            newWeights = weights.filter((wt) => wt.isNew !== true);
          } else {
            const hasMaxWeight =
              weights.filter(
                (weight) => weight.max_weight === AppConfig.rangeMaxValue
              ).length > 0;
            const hasNewWeight = newWeights.find((wt) => wt.isNew !== true);
            if (!hasMaxWeight && !hasNewWeight) {
              const { category_configuration = {} } = this.state;
              const with_cwt = this.getAppltCwtValue(currentConfigObj);
              newWeights.push({
                id: randomNumber(),
                min_weight: null,
                max_weight: null,
                isNew: true,
                with_cwt: !isEmpty(category_configuration[with_cwt])
                  ? category_configuration[with_cwt]
                  : "",
                range_type: !isEmpty(record.range_type)
                  ? record.range_type
                  : "",
              });
            }
          }
          rateConfig[configIndex].weights = _.sortBy(newWeights, "min_weight");
          rateConfig[configIndex].zones = zones;
          this.setState(
            {
              inProgress: false,
              showWeightForm: false,
              isEditing: false,
            },
            () => {
              this.formPayload(rateConfig);
            }
          );
        }
      }
    } else {
      const { currentConfigObj, rateConfig } = this.state;
      const configIndex = _.findIndex(rateConfig, {
        config_id: currentConfigObj.config_id,
      });
      if (configIndex >= 0) {
        const { weights = [], zones = [] } = rateConfig[configIndex];
        const { category_configuration } = this.state;
        const tempId = randomNumber();
        const with_cwt = this.getAppltCwtValue(currentConfigObj);
        weights.push({
          id: tempId,
          min_weight: record.min_weight,
          max_weight: record.max_weight,
          isNew: false,
          with_cwt: !isEmpty(category_configuration[with_cwt])
            ? category_configuration[with_cwt]
            : "",
          range_type: !isEmpty(record.range_type) ? record.range_type : "",
        });
        zones.forEach((zone, index) => {
          zone[tempId] = {
            key: record.id,
            cost: "",
            breakpoint_weight: "",
            isEditable: false,
            min_amount: "",
            max_amount: "",
            weight_min_max_charge_editable: false,
          };
          zones[index] = zone;
        });
        let newWeights = [...weights];
        if (record.max_weight === AppConfig.rangeMaxValue) {
          newWeights = weights.filter((wt) => wt.isNew !== true);
        }
        rateConfig[configIndex].weights = _.sortBy(newWeights, "min_weight");
        rateConfig[configIndex].zones = zones;
        this.setState(
          {
            inProgress: false,
            showWeightForm: false,
          },
          () => {
            this.formPayload(rateConfig);
          }
        );
      }
    }
  };

  handleZoneWeightSave = (configId, zoneId, record) => {
    const { editEnabledLos, account } = this.state;
    if (
      !isEmpty(editEnabledLos[configId]) &&
      !isEmpty(editEnabledLos[configId][zoneId]) &&
      !isEmpty(editEnabledLos[configId][zoneId][record.id])
    ) {
      const rateConfig = [...this.state.rateConfig];
      const configIndex = _.findIndex(this.state.rateConfig, {
        config_id: configId,
      });
      if (configIndex >= 0) {
        const currentConfigObj = rateConfig[configIndex];
        const zones = [...currentConfigObj.zones];
        const currentZoneIndex = _.findIndex(zones, ["zone_id", zoneId]);
        if (currentZoneIndex >= 0 && zones[currentZoneIndex][record.id]) {
          const currentZone = zones[currentZoneIndex];
          const minMaxRec = currentZone[record.id]
            .weight_min_max_charge_editable
            ? editEnabledLos[configId][zoneId][record.id]
            : currentZone[record.id];
          const min_amount =
            !isEmpty(minMaxRec) && !isEmpty(minMaxRec["min_amount"])
              ? parseFloat(minMaxRec.min_amount)
              : "";
          const max_amount =
            !isEmpty(minMaxRec) && !isEmpty(minMaxRec["max_amount"])
              ? parseFloat(minMaxRec.max_amount)
              : "";
          let isValid = true;
          if (
            !isEmpty(minMaxRec) &&
            !isEmpty(min_amount, true) &&
            !isEmpty(max_amount, true) &&
            min_amount >= max_amount
          ) {
            isValid = false;
          }
          if (isValid) {
            const costRec = currentZone[record.id].isEditable
              ? editEnabledLos[configId][zoneId][record.id]
              : currentZone[record.id];
            const cost =
              !isEmpty(costRec) && !isEmpty(costRec["cost"])
                ? costRec.cost
                : "";
            const driver_rate_params = [];
            const rateParam = {};
            rateConfig.forEach((rateConfigObj) => {
              rateParam[rateConfigObj.config_id] = [];
              const weights = rateConfigObj.weights
                ? rateConfigObj.weights.filter((wt) => wt.isNew !== true)
                : [];
              const zones = rateConfigObj.zones || [];
              zones.forEach((zone) => {
                const obj = {
                  zone_id: zone.zone_id,
                  min_charge: zone?.min_charge
                    ? parseFloat(zone.min_charge)
                    : "",
                  max_charge: zone?.max_charge
                    ? parseFloat(zone.max_charge)
                    : "",
                  weight_params: [],
                };
                const weight_params = [];
                weights.forEach((wt) => {
                  const zoneCost =
                    wt.id === record.id && zone.zone_id === zoneId
                      ? cost
                      : zone[wt.id]?.cost
                      ? zone[wt.id].cost
                      : 0.0;
                  weight_params.push({
                    min_weight: wt.min_weight,
                    max_weight: wt.max_weight,
                    value: parseFloat(zoneCost),
                  });
                });
                obj.weight_params = weight_params;
                rateParam[rateConfigObj.config_id].push(obj);
              });
            });
            driver_rate_params.push({...rateParam, ...this.state.category_configuration});
            const data = {
              organization_id: account.organization_id,
              org_id: account.organization_id,
              account_id: account.id,
              account_adjustment_id: !isEmpty(this.state.currentAdjustment)
                    ? this.state.currentAdjustment.id
                    : "",
              adjustment_code: !isEmpty(this.state.currentAdjustment)
                    ? this.state.currentAdjustment.code
                    : "",
              category_configuration: this.state.category_configuration,
              weight_params: driver_rate_params,
            };
            //this.saveRate(data, {});
            saveCategoryData(data)
              .then((result) => {
                if (result.success) {
                  alertMessage(I18n.t("messages.saved"));
                  const driverRate = result?.account_adjustment_param ? result.account_adjustment_param : {};
                  zones[currentZoneIndex][record.id].cost = cost;
                  currentConfigObj.zones = [...zones];
                  rateConfig[configIndex] = _.cloneDeep(currentConfigObj);
                  this.setState(
                    { 
                        inProgress: false, 
                        rateConfig, driver_rate_params,
                        currentParamDetails: driverRate,
                    },
                    () => {
                      this.handleClearRateMatrix(
                        configId,
                        zoneId,
                        record.id,
                        currentZone[record.id].isEditable
                          ? "weight"
                          : "range_min_max_charge"
                      );
                    }
                  );
                } else {
                  alertMessage(result.errors, "error", 10);
                }
              });
          } else {
            alertMessage(
              `Max charge ${I18n.t("los.gt_min_charge")}`,
              "error",
              10
            );
          }
        }
      }
    }
  };

  handleMinChargeSave = (configId, zoneId) => {
    const { editEnabledLos, account } = this.state;
    if (
      !isEmpty(editEnabledLos[configId]) &&
      !isEmpty(editEnabledLos[configId][zoneId])
    ) {
      const rateConfig = _.cloneDeep(this.state.rateConfig);
      const configIndex = _.findIndex(rateConfig, {
        config_id: configId,
      });
      if (configIndex >= 0) {
        const currentConfigObj = rateConfig[configIndex];
        const zones = [...currentConfigObj.zones];
        const currentZoneIndex = _.findIndex(zones, ["zone_id", zoneId]);
        if (currentZoneIndex >= 0) {
          const minCost = !isEmpty(editEnabledLos[configId][zoneId].min_charge)
            ? parseFloat(editEnabledLos[configId][zoneId].min_charge)
            : "";
          const maxCost = !isEmpty(editEnabledLos[configId][zoneId].max_charge)
            ? parseFloat(editEnabledLos[configId][zoneId].max_charge)
            : "";
          let isValid = true;
          if (
            !isEmpty(minCost, true) &&
            !isEmpty(maxCost, true) &&
            minCost >= maxCost
          ) {
            isValid = false;
          }

          if (isValid) {
            const driver_rate_params = [];
            const rateParam = {};
            rateConfig.forEach((rateConfigObj) => {
              rateParam[rateConfigObj.config_id] = [];
              const weights = rateConfigObj?.weights
                ? rateConfigObj.weights.filter((wt) => wt.isNew !== true)
                : [];
              const zones = rateConfigObj.zones || [];
              zones.forEach((zone) => {
                const obj = {
                  zone_id: zone.zone_id,
                  min_charge:
                    configId === rateConfigObj.config_id &&
                    zone.zone_id === zoneId
                      ? minCost
                      : zone?.min_charge
                      ? parseFloat(zone.min_charge)
                      : "",
                  max_charge:
                    configId === rateConfigObj.config_id &&
                    zone.zone_id === zoneId
                      ? maxCost
                      : zone?.max_charge
                      ? parseFloat(zone.max_charge)
                      : "",
                  weight_params: [],
                };
                const weight_params = [];
                weights.forEach((wt) => {
                  const cost = zone[wt.id]?.cost ? zone[wt.id].cost : 0.0;
                  weight_params.push({
                    min_weight: wt.min_weight,
                    max_weight: wt.max_weight,
                    value: parseFloat(cost),
                  });
                });
                obj.weight_params = weight_params;
                rateParam[rateConfigObj.config_id].push(obj);
              });
            });
            driver_rate_params.push({...rateParam, ...this.state.category_configuration});
            const data = {
              organization_id: account.organization_id,
              org_id: account.organization_id,
              account_id: account.id,
              account_adjustment_id: !isEmpty(this.state.currentAdjustment)
                    ? this.state.currentAdjustment.id
                    : "",
              adjustment_code: !isEmpty(this.state.currentAdjustment)
                    ? this.state.currentAdjustment.code
                    : "",
              weight_params: driver_rate_params,
            };
            //this.saveRate(data, {});

            saveCategoryData(data)
              .then((result) => {
                if (result.success) {
                  alertMessage(I18n.t("messages.saved"));
                  const driverRate = result?.account_adjustment_param ? result.account_adjustment_param : {};
                  zones[currentZoneIndex].min_charge = minCost;
                  zones[currentZoneIndex].max_charge = maxCost;
                  currentConfigObj.zones = [...zones];
                  rateConfig[configIndex] = _.cloneDeep(currentConfigObj);
                  this.setState(
                    { 
                        currentParamDetails: driverRate,
                        inProgress: false, rateConfig, driver_rate_params },
                    () => {
                      this.handleClearRateMatrix(
                        configId,
                        zoneId,
                        "",
                        "min_max_charge"
                      );
                    }
                  );
                } else {
                  alertMessage(result.errors, "error", 10);
                }
              });
          } else {
            alertMessage(
              `Max charge ${I18n.t("los.gt_min_charge")}`,
              "error",
              10
            );
          }
        }
      }
    }
  };

  handleOnChangeRateMatrix = (
    configId,
    zoneId,
    weightId,
    element,
    value,
    e
  ) => {
    if (e && _.isObject(e)) {
    }

    const { editEnabledLos } = this.state;
    if (isEmpty(editEnabledLos[configId])) {
      editEnabledLos[configId] = {};
    }
    if (isEmpty(editEnabledLos[configId][zoneId])) {
      editEnabledLos[configId][zoneId] = {};
    }
    if (["min_charge", "max_charge"].includes(element)) {
      editEnabledLos[configId][zoneId][element] = value;
    } else if (element === "min_max_charge") {
      editEnabledLos[configId][zoneId]["min_charge"] = value.min_charge;
      editEnabledLos[configId][zoneId]["max_charge"] = value.max_charge;
    } else if (
      ["range_min_max_charge", "min_amount", "max_amount"].includes(element)
    ) {
      if (isEmpty(editEnabledLos[configId][zoneId][weightId])) {
        editEnabledLos[configId][zoneId][weightId] = {};
      }
      if (element === "range_min_max_charge") {
        editEnabledLos[configId][zoneId][weightId] = value;
      } else {
        editEnabledLos[configId][zoneId][weightId] = {
          ...editEnabledLos[configId][zoneId][weightId],
          [element]: value,
        };
      }

      // editEnabledLos[configId][zoneId][weightId]['min_amount'] = value.min_amount
      // editEnabledLos[configId][zoneId][weightId]['max_amount'] = value.max_amount
    } else if (element === "cost") {
      if (isEmpty(editEnabledLos[configId][zoneId][weightId])) {
        editEnabledLos[configId][zoneId][weightId] = {};
      }
      editEnabledLos[configId][zoneId][weightId]["cost"] = value;
    }
    this.setState({
      editEnabledLos,
    });
  };

  handleKeyDown = (configId, zoneId, record, element, e) => {
    if (["min_charge", "max_charge"].includes(element)) {
      if (e.key === "Enter") {
        this.handleMinChargeSave(configId, zoneId);
      } else if (e.key === "Escape") {
        this.handleClearRateMatrix(configId, zoneId, "", "min_max_charge");
      }
    } else {
      if (e.key === "Enter") {
        this.handleZoneWeightSave(configId, zoneId, record);
      } else if (e.key === "Escape") {
        if (["min_amount", "max_amount"].includes(element)) {
          this.handleClearRateMatrix(
            configId,
            zoneId,
            record.id,
            "range_min_max_charge"
          );
        } else {
          this.handleClearRateMatrix(configId, zoneId, record.id, "weight");
        }
      }
    }
  };

  handleClearRateMatrix = (
    configId,
    zoneId,
    weightId = "",
    element,
    cb = null
  ) => {
    const { editEnabledLos } = this.state;
    if (editEnabledLos[configId] && editEnabledLos[configId][zoneId]) {
      if (element === "min_max_charge") {
        delete editEnabledLos[configId][zoneId].min_charge;
        delete editEnabledLos[configId][zoneId].max_charge;
        this.handleZoneMinCharge(configId, zoneId, false);
      } else {
        if (
          editEnabledLos[configId] &&
          editEnabledLos[configId][zoneId] &&
          !isEmpty(editEnabledLos[configId][zoneId][weightId])
        ) {
          delete editEnabledLos[configId][zoneId][weightId];
          this.handleZoneWeightForm(configId, zoneId, weightId, false, element);
        } else {
          this.handleZoneWeightForm(configId, zoneId, weightId, false, element);
        }
      }
      this.setState({
        editEnabledLos,
      });
    }
  };

  handleZoneMinCharge = (configId, zoneId, value) => {
    const rateConfig = [...this.state.rateConfig];
    const configIndex = _.findIndex(this.state.rateConfig, {
      config_id: configId,
    });
    if (configIndex >= 0) {
      const currentConfigObj = rateConfig[configIndex];
      const zones = [...currentConfigObj.zones];
      const currentZoneIndex = _.findIndex(zones, ["zone_id", zoneId]);
      if (currentZoneIndex >= 0) {
        const currentZone = zones[currentZoneIndex];
        currentZone.min_max_charge_editable = value;
        if (value) {
          this.handleOnChangeRateMatrix(
            currentConfigObj.config_id,
            currentZone.zone_id,
            "",
            "min_max_charge",
            {
              min_charge: currentZone.min_charge,
              max_charge: currentZone.max_charge,
            }
          );
        }
        zones[currentZoneIndex] = currentZone;
      }
      currentConfigObj.zones = [...zones];
      rateConfig[configIndex] = currentConfigObj;
      this.setState({
        rateConfig,
      });
    }
  };

  showLosFrom = (event, id) => {
    let currentConfigObj = {};
    if (!isEmpty(id)) {
      currentConfigObj = _.find(this.state.rateConfig, { config_id: id });
    }
    this.setState({
      showLos: true,
      currentConfigObj: !isEmpty(currentConfigObj) ? currentConfigObj : {},
      losIsNew: !!isEmpty(id),
    });
    event.stopPropagation();
  };

  closeLosForm = () => {
    this.setState({
      showLos: false,
      losIsNew: false,
    });
  };

  renderLosForm = () => (
    <BaseModal
      title={this.state.losIsNew ? I18n.t("los.add") : I18n.t("los.update")}
      onCancel={() => this.handleSaveSuccessOfLos()}
      width="60%"
      style={{ top: 50 }}
    >
      <LosForm
        account={this.state.account}
        currentConfigObj={this.state.currentConfigObj}
        handleSaveSuccess={this.handleSaveSuccessOfLos}
        onCancel={this.handleSaveSuccessOfLos} // closeLosForm
        isNew={this.state.losIsNew}
      />
    </BaseModal>
  );

  handleDeleteLos = (event, id) => {
    const { rateConfig } = this.state;
    this.setState({ inProgress: true });
    deleteLos(id, { id }).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        const configIndex = _.findIndex(rateConfig, ["config_id", id]);
        if (configIndex >= 0) {
          rateConfig.splice(configIndex, 1);
        }
        this.setState({ inProgress: false, rateConfig });
      } else {
        alertMessage(result.errors, "error", 10);
      }
    });
    event.stopPropagation();
  };

  handleSaveSuccessOfLos = () => {
    this.setState(
      {
        showLos: false,
        losIsNew: false,
      },
      () => {
        this.setInitializeData();
      }
    );
  };

  handleDriverRateFormInputChage = (element, value) => {
    let category_configuration = this.state?.category_configuration ? this.state.category_configuration  : {};
    category_configuration = {
        ...category_configuration,
        ...value,
    };
    this.setState({
        category_configuration,
    }, () => {
        this.getRateObjects()
    })
  }

  render() {
    const { category_configuration = {} } = this.state;
    return (
      <div className="marginTop10 rate_matrix ordersList">
        <Spin spinning={this.state.inProgress} delay={1000}>
        <Row>
           <Col xs={8}>
            <Checkbox
              onChange={(e) =>
                this.handleDriverRateFormInputChage("category_configuration", {
                  "with_cwt": e.target.checked+"",
                })
              }
              checked={category_configuration?.with_cwt  == "true"}
            >
              Apply CWT
            </Checkbox>
          </Col>
          <Col xs={8}>
            <Checkbox
              onChange={(e) =>
                this.handleDriverRateFormInputChage("category_configuration", {
                  "is_smp_applied": e.target.checked+"",
                })
              }
              checked={category_configuration?.is_smp_applied == "true"}
            >
              Require SMP
            </Checkbox>
          </Col>
          <Col xs={8} className="alignRight">
            <Button
              type="primary"
              onClick={() =>  this.formPayload(this.state.rateConfig)}
              style={{ marginLeft: "1rem"}}
              size="small"
              icon="save"
            >
              Save
            </Button>
          </Col>
       </Row>
          <Row gutter={16}>
            <Col xs={24}>
              {checkServiceExistance("LOSI") && this.state.zones.length > 0 ? (
                this.state.rateConfig.length > 0 ? (
                  this.state.rateConfig.map((los) => (
                    <div className="padding10 marginBottom10">
                      <Collapse
                        defaultActiveKey={["1"]}
                        style={{ height: "auto" }}
                        expandIconPosition="right"
                        className="marginBottom20"
                      >
                        <Panel
                          size="small"
                          header={
                            <Row>
                              <Col xs={24} className="textBold">
                                {I18n.t(`driver_rates.${los.config_id}`)}
                              </Col>
                            </Row>
                          }
                          key="1"
                        >
                          <Row gutter={8}>
                            <Col xs={24} md={24} lg={24} sm={24}>
                              <MatrixList
                                isEditWeightRange
                                bordered
                                size="small"
                                los={los}
                                rowKey={"zone_id"}
                                data={los.zones}
                                weights={los.weights}
                                pagination={{
                                  position: "none",
                                }}
                                editableRecord={
                                  !isEmpty(
                                    this.state.editEnabledLos[los.config_id]
                                  )
                                    ? this.state.editEnabledLos[los.config_id]
                                    : {}
                                }
                                editWeightRange={(
                                  weightRange,
                                  configurations
                                ) => {
                                  this.editWeightRange(
                                    los.config_id,
                                    weightRange,
                                    configurations
                                  );
                                }}
                                takeMaxRangeValue
                                handleClear={(zone, weightId, element) =>
                                  this.handleClearRateMatrix(
                                    los.config_id,
                                    zone,
                                    weightId,
                                    element
                                  )
                                }
                                onChange={(zone, weightId, element, value) =>
                                  this.handleOnChangeRateMatrix(
                                    los.config_id,
                                    zone,
                                    weightId,
                                    element,
                                    value
                                  )
                                }
                                handleWeight={(weight) =>
                                  this.handleWeightForm(los.config_id, weight)
                                }
                                handleZoneWeight={(zoneId, weightId, value) =>
                                  this.showInputValue(
                                    los.config_id,
                                    zoneId,
                                    weightId,
                                    value,
                                    "weight"
                                  )
                                }
                                handleRangeMinMaxRangeChange={(
                                  zoneId,
                                  weightId,
                                  value
                                ) =>
                                  this.showInputValue(
                                    los.config_id,
                                    zoneId,
                                    weightId,
                                    value,
                                    "range_min_max_charge"
                                  )
                                }
                                handleZoneMinCharge={(zone, value) =>
                                  this.showInputValue(
                                    los.config_id,
                                    zone,
                                    "",
                                    value,
                                    "min_max_charge"
                                  )
                                }
                                deleteClick={this.handleDeleteWeight}
                                handleMinChargeSave={(zone) =>
                                  this.handleMinChargeSave(los.config_id, zone)
                                }
                                handleZoneWeightSave={(zone, record) =>
                                  this.handleZoneWeightSave(
                                    los.config_id,
                                    zone,
                                    record
                                  )
                                }
                                handleKeyDown={(zone, record, element, e) =>
                                  this.handleKeyDown(
                                    los.config_id,
                                    zone,
                                    record,
                                    element,
                                    e
                                  )
                                }
                                showMincharge={false}
                                showMinMaxcharge={
                                  los?.configurations
                                    ?.apply_min_max_charge_on_each_range != true
                                }
                              />
                            </Col>
                            {false && (
                              <Col xs={6}>
                                <Card
                                  size="small"
                                  title={I18n.t("menu.accessorial")}
                                  bodyStyle={{ minHeight: 100 }}
                                  extra={
                                    <Button shape="circle">
                                      <Icon
                                        type="plus-circle"
                                        theme="twoTone"
                                      />
                                    </Button>
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  ))
                ) : (
                  <Empty
                    description={
                      <h3>
                        Please add <b>Rate Matrix</b>
                      </h3>
                    }
                  >
                    {/* <Button
                      type="primary"
                      onClick={(event) => this.showLosFrom(event)}
                    >
                      Create Now
                    </Button> */}
                  </Empty>
                )
              ) : (
                <Empty
                  description={
                    <h3>
                      Please add zones to create <b>Rate Matrix</b>
                    </h3>
                  }
                >
                  {/* <Button
                    type="primary"
                    onClick={() => this.props.goToTab("3")}
                  >
                    Create Now
                  </Button> */}
                </Empty>
              )}
            </Col>
          </Row>
          <Row>{FormErrors(this.state.errors)}</Row>
        </Spin>
        {this.state.showWeightForm && this.renderWeightModal()}
        {this.state.showLos && this.renderLosForm()}
      </div>
    );
  }
}

DriverRateMatrix.propTypes = {
  account: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  goToTab: PropTypes.func.isRequired,
};

export default DriverRateMatrix;
