import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "../../common/UIComponents";
import I18n from "../../common/I18n";

const OperationsVehicleTypeMapping = ({ handleExport, handleCancel }) => {
  const size = 2;
  return (
    <Row className="bottom_fixed">
      <Col className="alignCenter">
        <Button
          type="default"
          className="clearButtonStyle"
          onClick={handleCancel}
          icon="close"
        >
          Clear
        </Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={handleExport} icon="export">
          {I18n.t("vehicleTypes.export")}
        </Button>
      </Col>
    </Row>
  );
};

OperationsVehicleTypeMapping.propTypes = {
  handleCancel: PropTypes.func.isRequired,

  // changeStatus: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
};

OperationsVehicleTypeMapping.defaultProps = {
  handleStatusResult: () => {},
  enableLoading: () => {},
  handleEditOrder: () => {},
};

export default OperationsVehicleTypeMapping;
