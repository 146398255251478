import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, Modal, TextArea } from '../../common/UIComponents';
import moment from 'moment';
import I18n from '../../common/I18n';
import {calculateTimeDuration} from '../tracking/Utils';
import OrdersList from '../../components/Tasks/OrdersList';

class InfoTask extends PureComponent {
  state={
    previewImage: '',
    previewVisible: false,
  }

  handlePreview = (data) => {
    const { task } = this.props;
    if (data === 'photo') {
      const previewImage = task.t_photo.url;
      this.setState({
        previewImage,
        previewVisible: true,
      });
    }
    if (data === 'signature') {
      const previewImage = task.t_signature.url;
      this.setState({
        previewImage,
        previewVisible: true,
      });
    }
  }

  handlePreviewModal = () => {
    this.setState({ previewVisible: false });
  }
  handleOrderViewClick = (order_id) => {

  }
  render() {
    const { task } = this.props;
    return (
      <div>
        <Card className="info_task_card" style={{ marginBottom: 10, textAlign: 'left' }}>
          <Row>
            <Col xs={8}>
              <div className="info-text">
                <h4> {I18n.t('info.location')} </h4>
                {task.location.name ?
                  (task.location.name) : 'NA'
                }
              </div>
            </Col>
            <Col xs={8}>
              <div className="info-text">
                <h4> {I18n.t('info.type')} </h4>
                {task.location.l_type ?
                  (task.location.l_type) : 'NA'
                }
              </div>
            </Col>
            <Col>
              <div className="info-text">
                <h4> {I18n.t('general.status')} </h4>
                {task.t_status ?
                  (task.t_status) : 'NA'
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <div className="info-text">
                <h4>{I18n.t('info.scheduled_time')}</h4>
                { task.t_start_datetime ?
                  (moment(new Date(task.t_start_datetime)).format('LT'))
                  : 'NA' }
              </div>
            </Col>
            <Col xs={8}>
              <div className="info-text">
                <h4>{I18n.t('info.arrival_time')}</h4>
                { task.t_reached_datetime ?
                  (moment(new Date(task.t_reached_datetime)).format('LT'))
                  : 'NA'
                }
              </div>
            </Col>
            <Col xs={8}>
              <div className="info-text">
                <h4>{I18n.t('info.end_time')}</h4>
                { task.t_end_datetime ?
                  (moment(new Date(task.t_end_datetime)).format('LT'))
                  : 'NA' }
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <div className="info-text">
                <h4>{I18n.t('info.duration')}</h4>
                { calculateTimeDuration(task.t_reached_datetime, task.t_end_datetime) }
              </div>
            </Col>
            <Col xs={8}>
              <div className="info-text">
                <h4>{I18n.t('info.delay')}</h4>
                { calculateTimeDuration(task.t_start_datetime, task.t_reached_datetime) }
              </div>
            </Col>
          </Row> 
          <Row className="info-task-notes">
            <h4>{I18n.t('info.notes')}</h4>
            <TextArea
              rows={4}
              value={task.t_notes ? task.t_notes : 'NA'}
              readOnly
            />
          </Row>
          <Row className="info-task-notes">
            <h4>{I18n.t('order.details')}</h4>
                 { task.orders.length >0 ?
                     <OrdersList 
                        data={task.orders} 
                        pagination={{ position: 'none' }}
                        // orderViewClick={id => this.handleOrderViewClick(id)}
                        bordered={true}
                        />
                    :
                    "NA"
                 }
          </Row>
          <Row>
            <Col xs={8}>
              <h4>{I18n.t('info.signature')}</h4>
              <div className="info-task-image">
                {task.t_signature.url ?
                  <img src={task.t_signature.url} alt="Signature Image" onClick={()=>this.handlePreview('signature')} />
                  : 'NA'
                }
              </div>
            </Col>
            <Col xs={8}>
              <h4> {I18n.t('info.photo')} </h4>
              {/* {task.t_photo.url !== null ? */}
              <div className="info-task-image" >
                {task.t_photo.url ?
                  <img src={task.t_photo.url} alt="PhotoImage" onClick={()=>this.handlePreview('photo')} />
                  : 'NA'
                }
              </div>
            </Col>
            <Col xs={8}>
              <h4>{I18n.t('info.barcode_scan')}</h4>
              {task.t_barcode_info ?
              (task.t_barcode_info)
                : 'NA'
              }
            </Col>
          </Row>
          <div className="info_task_number">
            {this.props.cardNumber}
          </div>
        </Card>
        <Modal className="previewTaskModal" width={350} height={350} visible={this.state.previewVisible} footer={null} onCancel={this.handlePreviewModal}>
          <img style={{ width: '100%' }} alt="previewImage" src={this.state.previewImage} />
        </Modal>
      </div>
    );
  }
}
InfoTask.propTypes = {
  task: PropTypes.shape().isRequired,
};
export default InfoTask;