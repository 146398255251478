import { Col, Icon, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";
import { TableImageColumn } from "../../common/TableImageColumn";
import AppConfig from "../../config/AppConfig";

class VehicleTypeList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "name",
        title: I18n.t("vehicleTypes.name"),
        dataIndex: "name",
        render: (data, record) => <span>{data}</span>,
        width: "40%",
        ...AppConfig.sortingOptions,
      },
      {
        key: "code",
        title: I18n.t("vehicleTypes.code"),
        dataIndex: "code",
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        width: "40%",
        ...AppConfig.sortingOptions,
      },
      {
        key: "images",
        title: I18n.t("vehicleTypes.image"),
        dataIndex: "vehicle_image",
        render: (record) => (
          <TableImageColumn
            record={record.url}
            width={"50"}
            height={"50"}
            borderRadius={"3"}
          />
        ),
        width: "10%",
      },
      {
        key: "actions",
        Fixed:"right",
        render: (data) => {
          const { handleDelete, handleEdit } = this.props;
          const { id } = data;
          return (
            <div className="line-actions">          
                  <Tooltip title={I18n.t("general.edit")}>
                    <span><EditIcon handleClick={() => handleEdit(id)} /></span>
                  </Tooltip>             
                  <Popconfirm
                    placement="left"
                    title={I18n.t("messages.delete_confirm")}
                    onConfirm={() => handleDelete(id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title={I18n.t("general.delete")}>
                      <span><DeleteIcon /></span>
                    </Tooltip>
                  </Popconfirm>          
            </div>
          );
        },
        align: "center",
        width: "10%",
      },
    ];
  }
}
export default VehicleTypeList;
