import { ApiUrl, Call, ParseGeneralResponse } from "./ApiUtils";

export const stateCodeApi = {
  createStateCodeConfig: (data,orgId) => {
    const payload = {
     "setting_name" :  "state_code_config",
      "setting_value" : JSON.stringify(data), 
      "organization_id": orgId
    }
    let url = ApiUrl(
      `v2/organization/update_org_config`
    );
    return Call("POST", url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
};
