import React, { Fragment } from 'react';
import BaseList from '../BaseList';
import {  compareString, isEmpty, ellipseText, checkNegitive } from '../../common/Common';
import I18n from '../../common/I18n';
import { Icon, Input, Popconfirm, Popover, Tooltip,Row,Col } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { checkServiceExistance, formatByTimeConfig } from '../../helpers/common';
import SettingIcon from '../common/SettingIcon';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';
import { momentTime } from '../../helpers/date_functions';
import AppConfig from '../../config/AppConfig';
import CopyableContent from '../common/CopyableContent';
import InvoiceRemitances from './InvoiceRemitances';
import { generateReqDecimal } from '../../helpers/array_functions';
import Copyable from "../common/Copyable";
import DisplayTime from '../common/DisplayTime';
import replay from '../../../assets/images/replay.png';

class UnpaidOrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    const stepValue = generateReqDecimal(1, decimalPoints);

    const columns = [
      {
        key: 'frequency_invoice_number',
        title: I18n.t('invoices.number'),
        dataIndex: 'frequency_invoice_number',
        render: (data, record) => (
          <Row type="flex"  gutter={4}>
            <Col>{data}</Col>
              <span><Copyable text={data} tooltipPosition="right" /></span>
          </Row>
        ),
        width: 120,
        className: 'noWrap',
        ...AppConfig.sortingOptions
      },
     
      {
        key: "approval_date",
        title: I18n.t("invoices.invoice_approval_date"),
        dataIndex: "approval_date",
        render: (data, record) => {
          return data ? (
            <span>
              <DisplayTime
                dateTimeString={data}
                timeFormat={AppConfig.dateFormat}
                displayWithDate={true}
                timeZoneId={props.currentWhTimeZoneId}
                isUTC={false}
              />
            </span>
          ) : (
            ""
          );
        },
        // render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : "",
        width: 130,
        ...AppConfig.sortingOptions
      },
      {
        key: 'remitances',
        title: I18n.t("batch_management.remittances"),
        render: (data) => data?.remittances?.length > 0 && <InvoiceRemitances remittances={data.remittances} key={`rem${data.customer_order_id}`}/>,
        width: 90,
      },
      
      {
        key: 'customer_order_number',
        title: I18n.t('general.order'),
        dataIndex: 'customer_order_number',
        render: (text, data) => (
          <Row type="flex" gutter={4} align="middle">
            <Col>{text}</Col>
            <span>
              <Copyable text={text} tooltipPosition="right" />
            </span>
          </Row>
        ),
        ...AppConfig.sortingOptions
      },
      {
        key: 'account_code',
        title: I18n.t("batch_management.account_code"),
        dataIndex: "account_code",
        render: (text) => text ?  text : "",
        width: 130,
        ...AppConfig.sortingOptions
      },
      {
        key: 'hawb',
        title: I18n.t('general.hawb'),
        dataIndex: 'hawb',
        render: (text, data) => <CopyableContent text={text} showTooltip/>,
        ...AppConfig.sortingOptions
      },
      {
        key: 'mawb',
        title: I18n.t('general.mawb'),
        dataIndex: 'mawb',
        render: (text, data) => <CopyableContent text={text} showTooltip/>,
        ...AppConfig.sortingOptions
      },
      {
        key: 'frequency_invoice_amount',
        title: I18n.t('account_receivables.invoice_amount'),
        dataIndex: 'frequency_invoice_amount',
        render: (text, data) => checkNegitive(text),
        align: 'right',
        width:100,
        ...AppConfig.sortingOptions
      },
      {
        key: 'job_total',
        title: I18n.t('batch_payments.line_total_amount'),
        dataIndex: 'job_total',
        render: (text, data) => checkNegitive(text),
        align:'right',
        width: 100,
      },
      {
        key: 'balance_order_amount',
        title: I18n.t('account_receivables.balance_amount'),
        dataIndex: 'balance_order_amount',
        render: (text, data) => <div className="paddingRight10">{checkNegitive(text)}</div>,
        align: 'right',
        width: 160,
        ...AppConfig.sortingOptions
      },
      {
        key: 'discount',
        title: I18n.t('account_receivables.discount'),
        dataIndex: 'discount',
        width: 120,
        render: (text, data, index) => {
          const line_balance_amount = data.balance_order_amount ? Number(data.balance_order_amount) : zeroValue;
          const isExceeds = false; //!isEmpty(data.line_balance_amount) ? data.line_balance_amount > line_balance_amount : false
          const fieldData = data.discount;
          return (
            line_balance_amount != 0 ?
            <Fragment>
            {/* {line_balance_amount}..{data.paid_amount} */}
            <Input 
              onChange={(e) => this.props.handlePaymentChange(index, 'discount', e.target.value)} 
              value={data.discount}
              style={{ width: 110, fontSize: 12 }}
              size="small"
              type="number"
              className={`${isExceeds ? 'errorBorder' : ''}`}
              prefix={<Icon type="dollar" />}
              min={0}
              step={stepValue}
              max={line_balance_amount}
              onClick={() => props.showInput(data)}
              // onFocus={() => props.showInput(data)}
              onBlur={(e) => props.handlePaymentChange(index, 'discount', fieldData ? parseFloat(fieldData).toFixed(decimalPoints): zeroValue)}
            />
             {isExceeds && <div className='fontSize10 textRed' >{'Exceeded Due.'}</div>}
            </Fragment>
            :
            (props.screen_name === "paid" ? 
            <span className='textBold'>{checkNegitive(text)}</span>
            :
            <span className='textBold anchor_cursor' onClick={() => props.showInput(data)}>{checkNegitive(text)}</span>)
            
          )
        },
        align: 'right',
        ...AppConfig.sortingOptions
      },
      {
        key: 'credits',
        title: I18n.t('account_receivables.credits'),
        dataIndex: 'credits',
        width: 120,
        render: (text, data, index) => {
          const line_balance_amount = data.balance_order_amount ? Number(data.balance_order_amount) : zeroValue;
          const isExceeds = false; //!isEmpty(data.line_balance_amount) ? data.line_balance_amount > line_balance_amount : false
          const fieldData = data.credits;
          return (
            line_balance_amount != 0 ?
            <Fragment>
            {/* {line_balance_amount}..{data.paid_amount} */}
            <Input 
              onChange={(e) => props.handlePaymentChange(index, 'credits', e.target.value)} 
              value={data.credits}
              style={{ width: 110, fontSize: 12 }}
              size="small"
              type="number"
              className={`${isExceeds ? 'errorBorder' : ''}`}
              prefix={<Icon type="dollar" />}
              min={0}
              step={stepValue}
              max={line_balance_amount}
              onClick={() => props.showInput(data)}
              onBlur={(e) => props.handlePaymentChange(index, 'credits', fieldData ? parseFloat(fieldData).toFixed(decimalPoints): zeroValue)}
            />
             {isExceeds && <div className='fontSize10 textRed' >{'Exceeded Due.'}</div>}
            </Fragment>
            :
            (props.screen_name === "paid" ? 
            <span className='textBold'>{checkNegitive(text)}</span>
            :
            <span className='textBold anchor_cursor' onClick={() => props.showInput(data)}>{checkNegitive(text)}</span>)
            
          )
        },
        align: 'right',
        ...AppConfig.sortingOptions
      },
      {
        key: 'paid_amount',
        title: I18n.t('batch_payments.pay_amount'),
        dataIndex: 'paid_amount',
        width: 130,
        render: (text, data, index) => {
          const line_balance_amount = data.balance_order_amount ? Number(data.balance_order_amount) : zeroValue;
          const paid_amount =  data.paid_amount ? Number(data.paid_amount) : zeroValue;
          const isExceeds = false //paid_amount > line_balance_amount;
          const fieldData = data.paid_amount;
          return (
            line_balance_amount != 0 ?
            <Fragment>
            {/* {line_balance_amount}..{data.paid_amount} */}
            <Input 
              onChange={(e) => props.handlePaymentChange(index, 'paid_amount', e.target.value)} 
              value={props.selectedKeys.includes(data.customer_order_id) ? data.paid_amount : "0"}
              style={{ width: 110, fontSize: 12 }}
              size="small"
              type="number"
              className={`${isExceeds ? 'errorBorder' : ''}`}
              prefix={<Icon type="dollar" />}
              min={0}
              step={stepValue}
              max={line_balance_amount}
              onFocus={() => props.showInput(data)}
              // onClick={() => props.showInput(data)}
              onBlur={(e) => props.handlePaymentChange(index, 'paid_amount', fieldData ? parseFloat(fieldData).toFixed(decimalPoints): zeroValue)}
            />
             {isExceeds && <div className='fontSize10 textRed' >{'Exceeded Due.'}</div>}
            </Fragment>
            :
            (props.screen_name === "paid" ? 
            <span className='textBold'>{checkNegitive(text)}</span>
            :
            <span className='textBold anchor_cursor' onClick={() => props.showInput(data)}>{checkNegitive(text)}</span>)
            
          )
        },
        align: 'right',
        ...AppConfig.sortingOptions
      },
      {
        key: 'net_balance',
        title: I18n.t('account_receivables.net_balance'),
        dataIndex: 'net_balance ',
        render: (text, data, index) => {
          const line_balance_amount = data.balance_order_amount ? Number(data.balance_order_amount) : zeroValue;
          const paid_amount = data.paid_amount ? Number(data.paid_amount) : zeroValue;
          const discount = data.discount ? Number(data.discount) :zeroValue;
          const credits = data.credits ? Number(data.credits) : zeroValue;
          const net_balance = (line_balance_amount-(paid_amount+discount+credits))
          const isExceeds = false; //!isEmpty(text) ? line_balance_amount > text : false
          return (
            <Fragment>
            {
            <span className={`textBold ${net_balance > 0 ? 'textRed' : ''}`}>{checkNegitive(net_balance)}</span>
            }
            </Fragment>)
        },
        align: 'right',
        ...AppConfig.sortingOptions
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (id, data) => (
          <Row type="flex" gutter={8}>
            <Col>
             <Popconfirm
              placement="left"
              title={I18n.t('messages.confirm')}
              onConfirm={() => props.revertPaidPayments(data.customer_order_id)}
              okText="Yes"
              cancelText="No"
            >
             <Tooltip title={"Revert"}> <img src={replay} width="25px" /></Tooltip>
            </Popconfirm>
            </Col>
          </Row>),
         width: 70,
         fixed: 'right'
      }
     
    ];
    if(props.screen_name === "paid"){
      columns.push({
        key: "paid_at",
        title: I18n.t("batch_payments.paid_at"),
        dataIndex: "created_at",
        render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : "",
        className: "paddingLeft5",
      })
    }
    if(props.showActions === true){
      columns.push({
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (id, data) => (
          <div className='line-actions'>
            { checkServiceExistance('ARBAD') &&
            <Tooltip title={I18n.t("general.delete")} >
             <Popconfirm
              placement="left"
              title={I18n.t('messages.delete_confirm')}
              onConfirm={() => props.deleteClick(data)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon style={{ fontSize: 12 }} />
              &nbsp;&nbsp;&nbsp; 
            </Popconfirm>
            </Tooltip>
            }
          </div>),
         width: 50,
      })
    }

    this.columns = [...columns];
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };
}

export default UnpaidOrdersList;
