/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col, FormItem, Select, TextArea, Icon, Button, Checkbox, // Checkbox, Radio, Popconfirm , Input, Modal, Upload
} from '../../common/UIComponents';
import FormErrors from '../../components/common/FormErrors';
import BaseModal from '../../components/BaseModal';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
// import imagePlaceholder from '../../../images/img_placeholder.png';
import Questionnare from './Questionnare';
// import MobileInput from '../common/MobileInput';
// import { loadGoogleMaps } from '../../utils/Utils';
// import AddressForm from '../common/AddressForm';

const { Option } = Select;
const optionFields = [ 'single_choice', 'multiple_choice', 'dropdown', 'multi_choicd_dropdown', 'populate', 'signature' ];
const Question = () => ({
  parentId: '',
  content: '',
  option_type: '',
  options: [],
  qn_index: '',
  has_options: false,
  notes_type_text: '',
  notes_type: '',
});

class CreateQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isNew: !(this.props.currentQuestion && this.props.currentQuestion._id),
      data: this.props.currentQuestion && this.props.currentQuestion._id ? this.props.currentQuestion : Question(),
      types: [],
      currentIndex: '',
      parentId: this.props.parentId ? this.props.parentId : '',
      loading: false,
      questions: this.props.questions,
    };
  }

  componentDidMount() {
    this.getQuestionTypes();
  }

  // on update of date send it to parent
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.data, this.state.data)) {
      this.props.onUpdate(this.state.data);
    }
  }

  // content, email_field, address_field, one_line_content, multiple_choice, checkboxes, combo_checkboxes, multi_select_dropdown, contentbox, phone_field
  getQuestionTypes=() => {
    this.setState({
      types: [
        {
          questionTypeId: 1,
          text: 'Text',
          status: 1,
          option_type: 'text',
        },
        // {
        //   questionTypeId: 2,
        //   text: 'Yes or No',
        //   status: 1,
        //   option_type: 'yes_no',
        // },
        {
          questionTypeId: 3,
          text: 'Multiple Choice',
          status: 1,
          option_type: 'multiple_choice',
        },
        {
          questionTypeId: 4,
          text: 'Single Choice',
          status: 1,
          option_type: 'single_choice',
        },
        // {
        //   questionTypeId: 5,
        //   text: 'Combo Checkbox',
        //   status: 1,
        //   option_type: 'combo_checkbox',
        // },
        // {
        //   questionTypeId: 6,
        //   text: 'Dropdown',
        //   status: 1,
        //   option_type: 'dropdown',
        // },
        // {
        //   questionTypeId: 7,
        //   text: 'Multi Select Dropdown',
        //   status: 1,
        //   option_type: 'multi_select_dropdown',
        // },
        // {
        //   questionTypeId: 8,
        //   text: 'Email',
        //   status: 1,
        //   option_type: 'email_field',
        // },
        // {
        //   questionTypeId: 9,
        //   text: 'Phone Field',
        //   status: 1,
        //   option_type: 'phone_field',
        // },
        {
          questionTypeId: 10,
          text: 'Text Box',
          status: 1,
          option_type: 'textbox',
        },
        {
          questionTypeId: 12,
          text: 'Prepopulate',
          status: 1,
          option_type: 'populate',
        },
        // {
        //   questionTypeId: 13,
        //   text: 'Signature',
        //   status: 1,
        //   option_type: 'signature',
        // },
        // {
        //   questionTypeId: 11,
        //   text: 'Address Field',
        //   status: 1,
        //   option_type: 'address_field',
        // },

      ].filter((type) => {
        const excludeInSurvey = [ 'populate', 'signature' ];
        if (this.props.parentType === 'surveys') {
          return !excludeInSurvey.includes(type.option_type);
        }
        return true;
      }),
    });
    // QuestionTypes()
    //   .then((result) => {
    //     if (result.success) {
    //       this.setState({
    //         types: result.types,
    //       });
    //     } else {
    //       alertMessage(result.errors);
    //     }
    //   });
  };

  setData = (data) => {
    this.setState({
      data,
    });
  };

  static getDerivedStateFromProps(props, state) {
    const stateData = {};
    stateData.data = props.currentQuestion;
    if (!_.isEqual(props.currentQuestion, state.data)) {
      //  stateData.data = props.currentQuestion;
    }
    if (state.isNew !== props.isNew) {
      stateData.isNew = props.isNew;
    }
    return stateData;
  }


  getBase64=(img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  beforeFileUpload = (file) => {

  };


  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleClear = () => {
    if (this.state.isNew) {
      this.setState({
        data: Question(),
      });
    } else {
      const { id } = this.state.data;
      this.setState({
        data: Object.assign({}, Question(), { id }),
      });
    }
  };

  handleChangeImage = (i, e) => {
    const { data } = this.state;
    [data[i].formFile] = e.fileList;
    this.setState({
      data,
    });
  };

  handleCancel=() => {
    this.setState({
      visible: false,
    });
  };

  handleOnChange = (element, value) => {
    const { data } = this.state;
    // this.setState({data:Object.assign({}, data, { [element]: value })})
    data[element] = value;
    this.setState({ data });
  };

  handleQuestionTypeChange = (element, value) => {
    const { data } = this.state;
    data[element] = value;
    if (value === 'populate') {
      data.pre_populate_field = '';
    } else {
    data.options = [];
    if (optionFields.includes[data.option_type]) {
      data.options = [{ option_value: '' }];
    }
    }
    this.setState({
      data,
    });
  };

  handleSave = () => {
    const { data } = this.state;
    data.formFile = data.formFile ? data.formFile.originFileObj : data.formFile;
    data.parentId = this.state.parentId;
    data.qn_index = this.state.questions.length;
    const optionValue = [];
    if (data && optionFields.includes(data.option_type)) {
      if(data.option_type === 'populate'){
        if(data.pre_populate_field === ''){
          alertMessage('Please Select Valid Option', 'error');
          return;
        }
        else {
          data.has_options = false;
          this.props.saveData(this.state.isNew, data);
          return;
        }
      }
      if(data.option_type === 'signature'){
        data.has_options = false;
        this.props.saveData(this.state.isNew, data);
        return;
      }
      if (data.options !== undefined) {
        if (data.options.length === 0) {
          alertMessage('Please enter the option', 'error');
          return;
        }
        data.options.forEach((option, index) => {
          if (option.option_value === '') {
            optionValue.push(`Option ${index + 1}`);
          }
        });
        if (optionValue.length > 0) {
          alertMessage(`Please enter ${optionValue.join(', ')}`, 'error');
        } else {
          data.has_options = true;
          this.props.saveData(this.state.isNew, data);
        }
      } else {
        alertMessage('Please enter the option', 'error');
      }
    } else if (data.option_type === '' || data.content === '') {
      alertMessage('Please enter the Details', 'error');
    } else {
      data.has_options = false;
      this.props.saveData(this.state.isNew, data);
    }
  };

  handleSaveSuccess=(message) => {
    alertMessage(message, 'success');
    this.getSopDataafterDelete();
  };

  fileList=(image, i) => [{ url: image, uid: i }];

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (formUrl) => {
      this.state.data.formUrl = formUrl;
      this.setState({
        data: this.state.data,
      });
    });
    const { data } = this.state;
    data.formFile = info.file;
    this.setState({
      data,
    });
  };

  handleQnAChange = (data) => {
    this.setState({ data });
  }

  renderImage=image => (
    <BaseModal title=' image' width='60%' visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
      <img alt='example' style={{ width: '100%', height: '70%' }} src={image} />
    </BaseModal>
  );


  render() {
    const { types } = this.state;
    const uploadButton = (
      <div >
        <div>
          <Icon type='plus' />
          <div className='ant-upload-text'>Upload</div>
        </div>
      </div>
    );

    return (
      <div className="questionaries">
        <Row>
          <Col
            xxl={24}
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >

            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={4} lg={6} xl={6}>
                    <FormItem label={I18n.t('questionaries.type')} require>
                      <Select
                        showSearch
                        value={this.state.data.option_type ? this.state.data.option_type : 'Type of question'}
                        placeholder='Type of question'
                        onChange={e => this.handleQuestionTypeChange('option_type', e)}
                        className="width100Per"
                      >
                        <Option
                          key="ques"
                          value=""
                        >
                          -- Select --
                        </Option>
                        { types.map(type => (
                          <Option
                            key={type.questionTypeId}
                            value={type.option_type}
                            // disabled={type.option_type === 'populate' && this.props.questions.filter(q => q.option_type === 'populate').length > 0 }
                          >
                            { type.text }
                          </Option>
                        ))
                        }
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xl={18} lg={18} md={18} sm={24} xs={24} >
                    <FormItem label={I18n.t('questionaries.enter_question')} require>
                      <TextArea
                        rows={1}
                        onChange={e => this.handleOnChange('content', e.target.value)}
                        value={this.state.data.content}
                        className='Rectangle-Copy-3'
                        placeholder={I18n.t('questionaries.type_question')}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row className='pt-4'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Questionnare
                      optionType={this.state.data}
                      QnAChange={this.handleQnAChange}
                      dbColumns={ this.props.dbColumns }
                      feColumn={ this.props.feColumn }
                    />
                  </Col>
                </Row>
                <Row />
                <Row className='pt-3 marginTop10'>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} className="paddingLeft10">
                    <Checkbox
                      checked={this.state.data.mandatory}
                      onChange={e => this.handleOnChange('mandatory', e.target.checked)}
                    >
                      <span className='Should-be-answered-b'>{I18n.t('questionaries.mandatory')}</span>
                    </Checkbox>
                  </Col>

                </Row>

                {/* <Row className='pt-3' >
                  <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} className='Oval-div'>
                      <span className='oval1' />
                    </Col>
                    <div className='clearfix marginTop20'>
                      <Upload
                        name='avatar'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        onChange={e => this.handleChange(e)}
                      >

                        { (this.state.data.formUrl) || (this.state.data.questionImages && this.state.data.questionImages[0] && this.state.data.questionImages[0].path) ?
                        // <div className='borderRadius'>
                          <img
                            // const image = this.state.image[i] ? this.state.image[i] : this.state.data.formFile ? this.state.data.formFile : '';
                            src={this.state.data.formUrl ? this.state.data.formUrl : this.state.data.questionImages && this.state.data.questionImages[0] && this.state.data.questionImages[0].path ? `${this.state.data.questionImages[0].path}` : imagePlaceholder}
                            style={{ height: 150, width: 150 }}
                            alt='person'
                            className='bearded-young-man'
                          />
                        // </div>
                          :
                          uploadButton }

                      </Upload>
                    </div>
                  </Col>
                </Row> */}

                <Row>
                  { (this.state.data.notes_type && this.state.data.notes_type !== '') &&
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} >
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} className='Oval-div'>
                      <span className=' oval1' />
                    </Col>
                    <Col xl={14} lg={14} md={14} sm={14} xs={14} >
                      <TextArea
                        rows={5}
                        className='notes'
                        onChange={e => this.handleOnChange('notes_type_text', e.target.value)}
                        value={this.state.data.notes_type_text}
                        placeholder={this.state.data.notes_type}
                      />
                    </Col>
                  </Col>
                  }
                </Row>
                <Row className='alignCenter margin10'>
                  <Button
                    type="danger"
                    className="margin10"
                    onClick={() => this.props.handleClear()}
                    loading={this.state.loading}
                    icon="close-circle"
                  > Clear
                  </Button>
                  <Button
                    type="primary"
                    className="margin10"
                    onClick={this.handleSave}
                    loading={this.state.loading}
                    icon='save'
                  ><span className='Save-Add-New-Quest'>{ this.state.data.id ? 'Update' : 'Save' }</span>
                  </Button>

                </Row>
                { this.state.data.errors &&

                    FormErrors(this.state.data.errors)
                }

              </Col>
            </Row>

            <div className=''>
              { (this.state.visible) && (this.state.currentIndex >= 0) &&
                this.equipmentTable(this.state.currentIndex)
              }
              { this.state.previewVisible &&
                this.renderImage()
              }
            </div>

          </Col>
        </Row>
      </div>
    );
  }
}
CreateQuestion.propTypes = {
  parentId: PropTypes.string,
  currentQuestion: PropTypes.shape().isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  saveData: PropTypes.func.isRequired,
  dbColumns: PropTypes.array,
  feColumn: PropTypes.array,
};

CreateQuestion.defaultProps = {
  parentId: '',
  dbColumns: [],
  feColumn: [],
};
export default withRouter(CreateQuestion);
