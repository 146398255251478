import React, { Fragment } from 'react';
import BaseList from '../BaseList';
import {  compareString, isEmpty, ellipseText, checkNegitive } from '../../common/Common';
import I18n from '../../common/I18n';
import { Icon, Input, Link, Popconfirm, Popover, Tooltip, Button, Row, Col } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { checkServiceExistance, formatByTimeConfig } from '../../helpers/common';
import SettingIcon from '../common/SettingIcon';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';
import { momentTime } from '../../helpers/date_functions';
import AppConfig from '../../config/AppConfig';
import CopyableContent from '../common/CopyableContent';
import { withRouter } from 'react-router';
import ChequeDetails from './ChequeDetails';
import InvoiceRemitances from './InvoiceRemitances';
import replay from '../../../assets/images/replay.png';

class OrderPayments extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    
    const columns = [
      // {
      //   key: 'account',
      //   title: I18n.t('general.account'),
      //   dataIndex: 'account_name',
      //   render: (text, data) => text ? ellipseText(text, 20, true) : ''
      // },
      // {
      //   key: "ar_batch_number",
      //   title:I18n.t("batch_management.batch_name"),
      //   dataIndex: "ar_batch_number",
      //   render: (data, record) => <CopyableContent text={data} showTooltip/>,
      //   width: 140,
      // },
      {
        key: "chAchDetails",
        title: I18n.t("batch_management.check_ach_details"),
        dataIndex: "check_ach_no",
        render: (data, record) => <ChequeDetails record={record} data={data} key={record.ar_batch_check_id} account={this.props.account}/>,
        // render: (data, record) => <Link onClick={()=> {
        //   props.history.push(`/check_transaction_info/${record.account_id}/${record.ar_batch_check_id}`)
        // }}>{data}</Link>,
        className:'noWrap',
        width: 140,
      },
      {
        key: "modeOfPayement",
        title:I18n.t("batch_management.mode_of_payment"),
        dataIndex: "pay_mode",
        render: (data, record) => <span>{!isEmpty(data) && data === "CREDIT_NOTE" ? "CREDIT MEMO" : data}</span>,
        className: 'textUpperCase'
      },
      {
        key: 'hawb',
        title: I18n.t('general.hawb'),
        dataIndex: 'hawb',
        render: (text, data) => <CopyableContent text={text} showTooltip/>,
        ...AppConfig.sortingOptions
      },
      {
        key: 'mawb',
        title: I18n.t('general.mawb'),
        dataIndex: 'mawb',
        render: (text, data) => <CopyableContent text={text} showTooltip/>,
        ...AppConfig.sortingOptions
      },
      // {
      //   key: "invoice_date",
      //   title: I18n.t("invoices.invoice_date"),
      //   dataIndex: "invoice_date",
      //   render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : ""
      // },
      // {
      //   key: "shipment_date",
      //   title: I18n.t("batch_payments.shipment_date"),
      //   dataIndex: "shipment_date",
      //   render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : ""
      // },
      {
        key: 'account_invoice_number',
        title: I18n.t('invoices.number'),
        dataIndex: 'account_invoice_number',
        render: (text, data) => <CopyableContent text={text} showTooltip/>,
        width: 140,
        ...AppConfig.sortingOptions
      },
      {
        key: 'remitances',
        title: I18n.t("batch_management.remittances"),
        render: (data) => data?.remittances?.length > 0 && <InvoiceRemitances remittances={data.remittances} key={`rem${data.id}`}/>,
      },
      {
        key: 'account_code',
        title: I18n.t("batch_management.account_code"),
        dataIndex: "account_code",
        render: (text) => text ?  text : "",
        width: 120,
        ...AppConfig.sortingOptions
      },
      {
        key: 'invoice_amount',
        title: I18n.t('account_receivables.invoice_amount'),
        dataIndex: 'invoice_amount',
        render: (text, data) => checkNegitive(text),
        align: 'right',
        width: 120,
        ...AppConfig.sortingOptions
      },
     
      {
        key: 'customer_order_number',
        title: I18n.t('account_receivables.order_no'),
        dataIndex: 'customer_order_number',
        render: (text, data) => <CopyableContent text={text} showTooltip/>,
        align: 'right',
        width: 100,
        ...AppConfig.sortingOptions
      },
      {
        key: 'invoice_order_amount',
        title: I18n.t('batch_payments.line_total_amount'),
        dataIndex: 'invoice_order_amount',
        render: (text, data) => checkNegitive(text),
        align:'right',
        ...AppConfig.sortingOptions
      },
      
      
      // {
      //   key: 'invoice_balance_amount',
      //   title: I18n.t('batch_payments.invoice_balance_amount'),
      //   dataIndex: 'invoice_balance_amount',
      //   render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
      //   align: 'right',
      // },
      // {
      //   key:'credits',
      //   title: I18n.t('account_receivables.credits'),
      //   render:(text, data) => <span className='textBold'>{checkNegitive(data)}</span>,
      //   align:"right"
      // },
      // {
      //   key:'discounts',
      //   title: I18n.t('account_receivables.discount'),
      //   render:(text, data) => <span className='textBold'>{checkNegitive(data)}</span>,
      //   align:"right"
      // },
      {
        key: 'order_received_amount',
        title: I18n.t('account_receivables.recv_amount'),
        dataIndex: 'order_received_amount',
        render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
        align: 'right',
        width: 120,
        ...AppConfig.sortingOptions
      },
      {
        key: 'invoice_extra_paid_amount',
        title: I18n.t('credit_notes.extra_amount'),
        dataIndex: 'invoice_extra_paid_amount',
        render: (text, data) => <div className="paddingRight30">{ checkNegitive(text) }</div>,
        width: 190,
        align:'right',
        ...AppConfig.sortingOptions
      },
      {
        key: "converted_credit_amount",
        title: I18n.t("credit_notes.credit_amount"),
        dataIndex: "converted_credit_amount",
        render: (text, data) => <span>{checkNegitive(text)}</span>,
        width: 160,
        align: "right",
        className: "noWrap",
      },
      // {
      //   key:'balance_amount',
      //   title: I18n.t('account_receivables.bal_amount'),
      //   render:(text, data) => <span className='textBold'>{checkNegitive(data)}</span>,
      //   align:"right",
      //   width:100
      // }
      // {
      //   key: 'pay_amount',
      //   title: I18n.t('batch_payments.pay_amount'),
      //   dataIndex: 'pay_amount',
      //   render: (text, data, index) => {
      //     const line_balance_amount = data.line_balance_amount ? Number(data.line_balance_amount) : 0.00;
      //     const isExceeds = !isEmpty(data.paid_amount) ? data.paid_amount > line_balance_amount : false
      //     return (
      //       props.selectedKeys.includes(data.id) && line_balance_amount != 0 ?
      //       <Fragment>
      //       {/* {line_balance_amount}..{data.paid_amount} */}
      //       <Input 
      //         onChange={(e) => this.props.handlePaymentChange(index, 'paid_amount', e.target.value)} 
      //         value={data.paid_amount}
      //         style={{ width: 90, fontSize: 12 }}
      //         size="small"
      //         type="number"
      //         className={`${isExceeds ? 'errorBorder' : ''}`}
      //       />
      //        {isExceeds && <div className='fontSize10 textRed' >{'Exceeds with Bal.'}</div>}
      //       </Fragment>
      //       :
      //       (props.screen_name === "paid" ? 
      //       <span className='textBold'>{checkNegitive(text)}</span>
      //       :
      //       <span className='textBold anchor_cursor' onClick={() => props.showInput(data)}>{checkNegitive(text)}</span>)
            
      //     )
      //   },
      //   align: 'right',
        
      // },
     
    ];
    if(props.screen_name === "paid"){
      columns.push({
        key: "created_by",
        title: I18n.t("general.created_by"),
        dataIndex: "created_by",
        render: (text, data) => text ? text : "",
        align: 'center',
        className: "noWrap",
      })
      columns.push({
        key: "created_at",
        title: I18n.t("batch_payments.paid_at"),
        dataIndex: "created_at",
        width: 110,
        render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : "",
        className: "paddingLeft5",
        ...AppConfig.sortingOptions
      })
    }
    columns.push({
      key: 'actions',
      title: '',
      dataIndex: 'id',
      render: (id, data) => (
        <Row type="flex" gutter={8}>
          { checkServiceExistance('ARBAD') && <Col>
           <Popconfirm
            placement="left"
            title={I18n.t('messages.confirm')}
            onConfirm={() => props.getExtraCheques(data)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" size="small" >{I18n.t('general.pay')}</Button>
          </Popconfirm>
          </Col>
          }
          { checkServiceExistance('ARBAD') && data.converted_credit_amount > 0 ? (
            <Col>
                <Tooltip title="Download">
                    <Icon
                        type="file-pdf"
                        style={{ color: "#fa8c16", fontSize: 20 }}
                        onClick={() => this.props.downloadCreditMemo(data.id)}
                    />
                </Tooltip>
            </Col>
            ) : (
                <Col>
           <Popconfirm
            placement="left"
            title={I18n.t('messages.confirm')}
            onConfirm={() => props.revertPaidPayments(id)}
            okText="Yes"
            cancelText="No"
          >
           <Tooltip title={"Revert"}> <img src={replay} width="25px" /></Tooltip>
          </Popconfirm>
          </Col>
          )}
        </Row>),
       width: 100,
       fixed: 'right'
    })
    // if(props.screen_name === "paid"){
    //   columns.push({
    //     key: 'actions',
    //     title: '',
    //     dataIndex: 'id',
    //     render: (id, data) => (
    //       <div className='line-actions'>
    //         { checkServiceExistance('ARBAD') &&
    //         <Tooltip title={I18n.t("general.delete")} >
    //          <Popconfirm
    //           placement="left"
    //           title={I18n.t('messages.delete_confirm')}
    //           onConfirm={() => props.deleteClick(data)}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <DeleteIcon style={{ fontSize: 12 }} />
    //           &nbsp;&nbsp;&nbsp; 
    //         </Popconfirm>
    //         </Tooltip>
    //         }
    //       </div>),
    //      width: 50,
    //   })
    //}

    this.columns = [...columns];
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };
}

export default withRouter(OrderPayments);
