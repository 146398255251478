import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import React from "react";

class DriverSummaryList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "driver_name",
        title: <b>{I18n.t("driver_monthly_report.driver_name")}</b>,
        dataIndex: "driver_name",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "driver_code",
        title: <b>{I18n.t("driver_monthly_report.driver_code")}</b>,
        dataIndex: "driver_code",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "total_routes",
        title: <b>{I18n.t("driver_monthly_report.driver_routes")}</b>,
        dataIndex: "total_routes",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
    ];
  }
}

export default DriverSummaryList;
