import React, { Fragment, useState } from "react";
import { Button, Modal, DatePicker, Popconfirm, Row, Col } from "antd";
import moment from "moment";
import { FormItem } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { DriverSettlementApi } from "../../api/DriverSettlementApi";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage } from "../../common/Common";

const ApproveSettlements = ({
  totalSelectedRows,
 // handleApprove,
  reports,
  currentOrg,
  filterPlaceHolder,
  handleApproveSuccess,
  setReportLoading,
  size="small"
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    setModalVisible(true);
  };

  const handleSave = () => {
    // Handle saving selected date
    console.log("Selected Date:", selectedDate);
    setModalVisible(false);
  };

  const handleCancel = () => {
    // Handle canceling action
    setModalVisible(false);
  };

  const handleDateChange = (date) => {
    // Handle date change
    setSelectedDate(date);
  };

  const handleApprove = () => {
    // const newKeys = _.uniq(
    //   reports
    //     .filter((row) => selectedKeys.includes(row.id))
    //     .map((row) => row.gross_order_report_id)
    // );
    const newKeys = _.uniq(
      totalSelectedRows
        .map((row) => row.gross_order_report_id)
    );
    const payload = {
      organization_id: currentOrg.id,
      gross_order_report_ids: [...newKeys],
      settlement_date: moment(selectedDate).format("YYYY-MM-DD"),
      week_number: moment(selectedDate).week(),
      from_date: moment(filterPlaceHolder.fromDate).format("YYYY-MM-DD"),
      to_date: moment(filterPlaceHolder.toDate).format("YYYY-MM-DD"),
    };
    setIsLoading(true);
    setReportLoading(true)
    try {
      DriverSettlementApi.approveLines(payload).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          handleCancel()
          handleApproveSuccess();
        } else {
          renderAlertMessage(result.errors, "error", 10);
        }
      });
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Button
        size={size}
        type="primary"
        icon="file-done"
        disabled={totalSelectedRows.length === 0}
        onClick={handleButtonClick}
      >
        {I18n.t("general.approve")}&nbsp;({totalSelectedRows.length})
      </Button>
      <Modal
        title="Approve Settlements"
        visible={modalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        size="small"
        footer={null}
        width={400}
      >
        <Row type="flex" gutter={16}>
          <Col className="textBold">
            {I18n.t("reports.settlement_date")}<sup className="textRed">*</sup>
          </Col>
          <Col>:</Col>
          <Col>
            <DatePicker
              style={{ width: "100%" }}
              onChange={handleDateChange}
              format={AppConfig.dateFormat}
              size="small"
              defaultValue={selectedDate}
              allowClear={false}
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            />
          </Col>
        </Row>
        <div className="paddingTop20 alignCenter">
           

            <Button
              type="danger"
              icon="close"
              size="small"
              onClick={handleCancel}
              
            >
              Cancel
            </Button>
          
            {/* <Popconfirm
              placement="topRight"
              title={I18n.t("messages.approve_confirm")}
              onConfirm={handleApprove}
              okText="Yes"
              cancelText="No"
              disabled={selectedKeys.length === 0}
            > */}
              <Button type="primary" icon="save" size="small" className="marginLeft10"
              loading={isLoading} disabled={totalSelectedRows.length === 0}
                onClick={handleApprove}>
                Save
              </Button>
            {/* </Popconfirm> */}
          
           
          
        </div>
      </Modal>
    </Fragment>
  );
};

export default ApproveSettlements;
