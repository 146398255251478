import React, { Component, Fragment, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Popconfirm,
  Icon,
  Spin,
  Divider,
  Tooltip,
} from "../../common/UIComponents";
import { fetchPrimaryAccountCodes } from "../../api/Account";
import { alertMessage, isEmpty} from "../../common/Common";
import I18n from "../../common/I18n";
import _ from "lodash";
import {
  fetchScheduledOrderCount,
  saveScheduledOrderCount,
  deleteScheduledOrderCount,
} from "../../api/ScheduledCountNotification";
import FormErrors from "../common/FormErrors";
import AppConfig from "../../config/AppConfig";
import MaterialTimePicker from "../orders/MaterialTimePicker";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { withRouter } from "react-router";
import { OrgContext } from "../../context/OrgContext";
import moment from 'moment'
import { renderAlertMessage } from "../../helpers/common";
class ScheduledCountNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      notifications: [],
      primaryAccountCodes: [],
      organizationSettings: {},
      isMilitaryTime: false,
      Errors: [],
      isDisplayAdd: true,
      timeZones: AppConfig.timeZones,
      currentProccessedIndex: -1,
      selectedRecord: {},
    };
    this.getPrimaryAccountCodes = _.debounce(this.getPrimaryAccountCodes, 500);
  }

  componentDidMount = () => {
    this.getPrimaryAccountCodes();
    this.getScheduledOrderCount();
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
      });
    }
  }

  getPrimaryAccountCodes = () => {
    this.setState({ inProgress: true });
    fetchPrimaryAccountCodes().then((result) => {
      if (result.success) {
        const accounts = result.accounts || [];
        this.setState({
          primaryAccountCodes: accounts,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getScheduledOrderCount = () => {
    this.setState({ inProgress: true });
    fetchScheduledOrderCount().then((result) => {
      if (result.success) {
        this.setState({
          notifications: result.scheduled_orders_count || [],
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false, notifications: [] }, () =>
          this.addNotificationCount()
        );
      }
    });
  };

  handleOnChange = (index, element, value) => {
    const notifications = [...this.state.notifications];
    if (element === "scheduled_time") {
      notifications[index][element] = !isEmpty(value) ? moment(value) : null;
      this.setState({ notifications });
    } else {
      notifications[index][element] = value;
      this.setState({ notifications });
    }
  };

  handleSave = (index) => {
    const notifications = [...this.state.notifications];
    const currentData = Object.assign({}, notifications[index]);
    let errors = [];
    if (currentData.account_ids.length < 1) {
      errors.push(
        I18n.t("messages.is_required", {
          field: I18n.t("general.account"),
        })
      );
    }
    if (isEmpty(currentData.no_of_days)) {
      errors.push(
        I18n.t("messages.is_required", {
          field: I18n.t("general.no_of_prior_days"),
        })
      );
    }
    if (isEmpty(currentData.scheduled_time)) {
      errors.push(
        I18n.t("messages.is_required", {
          field: I18n.t("general.scheduled_time"),
        })
      );
    }
    if (errors.length === 0) {
      const payload = {
        account_ids: currentData.account_ids,
        scheduled_time: moment(currentData.scheduled_time, "HH:mm").format(
          "HH:mm"
        ),
        no_of_days: currentData.no_of_days,
        time_zone: currentData.time_zone,
        id: currentData.id ? currentData.id : null,
      };
      this.setState({ inProgress: true });
      saveScheduledOrderCount(payload).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          notifications[index] = { ...result.scheduled_orders };
          this.setState({
            inProgress: false,
            Errors: [],
            notifications,
            isDisplayAdd: true,
            currentProccessedIndex: -1,
            selectedRecord: {},
          });
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({
            inProgress: false,
          });
        }
      });
    } else {
      this.setState({
        Errors: errors,
        inProgress: false,
      });
    }
  };

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteScheduledOrderCount(id).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        this.setState(
          {
            inProgress: false,
            Errors: [],
            isDisplayAdd: true,
          },
          () => this.getScheduledOrderCount()
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleCancel = (index) => {
    const { selectedRecord, notifications } = this.state;
    if (notifications[index] && isEmpty(notifications[index].id)) {
      notifications.splice(index, 1);
    } else {
      notifications[index] = selectedRecord;
    }
    this.setState({
      notifications,
      Errors: [],
      isDisplayAdd: true,
      currentProccessedIndex: -1,
      selectedRecord: {},
    });
  };

  handleEdit = (index) => {
    const { notifications } = this.state;
    const selectedRecord = notifications[index]
      ? { ...notifications[index] }
      : {};
    this.setState({
      selectedRecord,
      notifications,
      currentProccessedIndex: index,
      isDisplayAdd: false,
    });
  };

  addNotificationCount = () => {
    const { notifications } = this.state;
    notifications.push({
      account_ids: [],
      scheduled_time: moment("00:00", "HH:mm"),
      no_of_days: "0",
      time_zone: "America/New_York",
    });

    this.setState({
      notifications,
      isDisplayAdd: false,
      currentProccessedIndex: notifications.length - 1,
    });
  };

  render() {
    const {
      notifications,
      primaryAccountCodes,
      isMilitaryTime,
      currentProccessedIndex,
      selectedRecord,
      timeZones,
    } = this.state;
    return (
      <div className="content">
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            {this.state.isDisplayAdd && (
              <Col xs={24} className="alignRight">
                <Button
                  type="primary"
                  onClick={this.addNotificationCount}
                  icon="plus"
                >
                  {I18n.t("general.add")}
                </Button>
              </Col>
            )}
          </Row>
          <Row className="marginTop10" gutter={32}>
            <Col xs={24}>
              <Col xs={9}>
                <span style={{ fontWeight: 700 }}>
                  <sup style={{ color: "red" }}>*</sup>
                  {I18n.t("general.account")}:
                </span>
              </Col>
              <Col xs={3}>
                <span style={{ fontWeight: 700 }}>
                  <sup style={{ color: "red" }}>*</sup>
                  {I18n.t("general.no_of_prior_days")}:
                </span>
              </Col>
              <Col xs={3}>
                <span style={{ fontWeight: 700 }}>
                  <sup style={{ color: "red" }}>*</sup>
                  {I18n.t("general.scheduled_time")}:
                </span>
              </Col>
              <Col xs={4}>
                <span style={{ fontWeight: 700 }}>
                  <sup style={{ color: "red" }}>*</sup>
                  {I18n.t("general.time")} {I18n.t("general.zone")}:
                </span>
              </Col>
            </Col>
          </Row>
          <div>
          {notifications.map((notificationRow, index) => {
            const isEditable = currentProccessedIndex === index;
            return (
              <Row
                className="marginTop10"
                key={`notificationRow${index}`}
                gutter={32}
              >
                <Col xs={24} style={{ marginBottom: 6 }}>
                  <Col xs={9}>
                    {isEditable ? (
                      <Select
                        placeholder="Select Accounts"
                        value={notificationRow.account_ids}
                        showSearch
                        mode="multiple"
                        onChange={(e) =>
                          this.handleOnChange(index, "account_ids", e)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        maxTagCount={2}
                        style={{ width: "100%" }}
                      >
                        {primaryAccountCodes.map((accKey) => (
                          <Select.Option key={accKey.id} value={accKey.id}>
                            {`${accKey.name} (${accKey.code})`}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Fragment>
                        {primaryAccountCodes
                          .filter((rec) =>
                            notificationRow.account_ids.includes(rec.id)
                          )
                          .map((rec) => rec.name)
                          .join(",")}
                      </Fragment>
                    )}
                  </Col>
                  <Col xs={3}>
                    {isEditable ? (
                      <Input
                        placeholder="Enter no of Prior days"
                        type="number"
                        value={notificationRow.no_of_days}
                        onChange={(e) =>
                          this.handleOnChange(
                            index,
                            "no_of_days",
                            e.target.value
                          )
                        }
                        min={0}
                        precision={2}
                        defaultValue={0}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <Fragment>{notificationRow.no_of_days}</Fragment>
                    )}
                  </Col>
                  <Col xs={3}>
                    {isEditable ? (
                      <MaterialTimePicker
                        inputStyle={ { padding: "7px" } }
                        onChange={(e) => this.handleOnChange(index, "scheduled_time", e)}
                        value={notificationRow.scheduled_time ? moment(notificationRow.scheduled_time, "HH:mm") : null}
                        computeDisabledHours={ () => []}
                        computeDisabledMinutes={() => []}
                        isMilitaryTime={isMilitaryTime}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <Fragment>
                        {notificationRow.scheduled_time
                          ? moment(
                              notificationRow.scheduled_time,
                              "HH:mm"
                            ).format(
                              isMilitaryTime
                                ? AppConfig.milatary_time_format
                                : AppConfig.time_format
                            )
                          : "NA"}
                      </Fragment>
                    )}
                  </Col>
                  <Col xs={4}>
                    {isEditable ? (
                      <Select
                        placeholder="Select Time Zone"
                        value={notificationRow.time_zone}
                        showSearch
                        onChange={(e) =>
                          this.handleOnChange(index, "time_zone", e)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {timeZones.map((accKey) => (
                          <Select.Option
                            key={accKey.value}
                            value={accKey.value}
                          >
                            {accKey.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Fragment>
                        {timeZones
                          .filter(
                            (rec) => rec.value === notificationRow.time_zone
                          )
                          .map((rec) => rec.name)}
                      </Fragment>
                    )}
                  </Col>
                  <Col xs={3}>
                    <Row type="flex">
                      <Col>
                        {isEditable ? (
                          <Icon
                            type="save"
                            onClick={() => this.handleSave(index)}
                            style={{ color: "#1890ff" }}
                          />
                        ) : (
                          _.isEmpty(selectedRecord) &&
                          this.state.isDisplayAdd && (
                            <Tooltip title={ I18n.t("general.edit")}>
                              <span><EditIcon handleClick={() => this.handleEdit(index)}/></span>
                            </Tooltip>
                          )
                        )}
                      </Col>
                      <Col className="paddingLeft10">
                        {notificationRow.id && !isEditable ? (
                          _.isEmpty(selectedRecord) &&
                          this.state.isDisplayAdd && (
                            <Tooltip title={ I18n.t("general.delete")}>
                            <Popconfirm
                              title={I18n.t("messages.delete_confirm")}
                              onConfirm={() =>
                                this.handleDelete(notificationRow.id)
                              }
                              placement="left"
                            >
                              <span><DeleteIcon/></span>
                            </Popconfirm>
                            </Tooltip>
                          )
                        ) : (
                          <Popconfirm
                            title={I18n.t("messages.cancel_confirm")}
                            onConfirm={() =>
                              this.handleCancel(index, notificationRow.id)
                            }
                            placement="topLeft"
                          >
                            <Icon
                              type="close"
                              style={{
                                color: "red",
                              }}
                            />
                          </Popconfirm>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col xs={20} className="scheduleCount">
                  <Divider />
                </Col>
              </Row>
            );
          })}
          </div>
          <Row>{FormErrors(this.state.Errors)}</Row>
        </Spin>
      </div>
    );
  }
}
const ScheduledCountNotificationComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <ScheduledCountNotification
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default ScheduledCountNotificationComponent;