import React from "react";
import { Checkbox, Col, Icon, Input, Row } from "antd";
import I18n from "../../common/I18n";
import { FormItem } from "../../common/UIComponents";

export const FlatRateForm = (props) => {
  const handleChange = (ele, val) => {
    if (val === "checkBoxValue") {
      props.onChange('checkBoxValue', ele.target.checked);
    } else {
      let value  = parseFloat(ele.target.value)
      props.onChange('amount', value);
    }
  };

  return (
    <div>
      <Row gutter={16}>
        <Col className="marginTop10">
          <Checkbox
            checked={props.checkBoxValue === "false" ? false : true} 
            onChange={(e)=> handleChange(e,"checkBoxValue")}
          >
            {I18n.t("order.configurations.include_fuel_surcharge")}
          </Checkbox>
        </Col>
        <Col className="marginTop10">
          <FormItem label={I18n.t("general.amount")}>
            <Input
              value={props.amount}
              onChange={(e)=> handleChange(e,"amount")}
              onClick={(e) => {
                if (e.target.value === "0") {
                  e.target.value = null;
                }
              }}              
              prefix={<Icon type="dollar" />}
              style={{ width: "30%" }}
              type="number"
            />
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};