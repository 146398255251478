/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import moment from 'moment';
import React, { Component, useContext } from "react";
import _ from "lodash";
import {
  Button,
  Col,
  DatePicker,
  FormItem,
  Row,
  Search,
  Spin,
} from "../common/UIComponents";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import List from "../components/ar_module/List";
import userStore from "../stores/UserStore";
import AppConfig from "../config/AppConfig";
import {
  checkServiceExistance,
  removeSpecialCharacters,
  renderAlertMessage,
} from "../helpers/common";

import { withRouter } from "react-router";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { ARApi } from "../api/ARApi";
import { fetchOrgAccounts } from "../api/Account";
import AccountCodesFilter from "./Filters/AccountCodesFilter";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";



// const { RangePicker } = DatePicker;
class AccountReceivables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      inProgress: false,
      showForm: false,
      account_invoices_details: [],
      currentAccount: {},
      orgId: userStore.getStateValue("selectedOrg"),
      isNew: true,
      sortOrder: "ascend",
      token:
        props && props.location && !isEmpty(props.location.token)
          ? props.location.token
          : "",
      recordsPerPage: props.recordsPerPage,
      accountsLoading: false,
      accounts: [],
      filter: {
        selectedAccounts: [],
        // dateRange: null,
      },
      // activateButton: true,
      filterPlaceHolder: {
        sortBy: "none",
        sortByType: "descend",
      },
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.getOpenInvoices = _.debounce(this.getOpenInvoices, 500);
  }

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOpenInvoices();
        updateRecordsPerPage(ScreenKeys.ACCOUNT_RECEIVABLES, value);
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        filter: {
          selectedAccounts: [],
          // dateRange: null,
        },
        // activateButton: true,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOpenInvoices();
      }
    );
  };

  handleSearch = () => {
    this.tableOptions.pagination.current = 1;
    this.getOpenInvoices();
  };

  componentDidMount() {
    this.getOpenInvoices();
    this.getAccounts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseFilter.selectedWarehouses,
        this.props.warehouseFilter.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseFilter.warehouses,
        this.props.warehouseFilter.warehouses
      ) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      this.getOpenInvoices();
      this.getAccounts();
    }
  }

  getAccounts = () => {

    this.setState({
      accountsLoading: true,
    });
    //fetchShortFormAccounts().then
    fetchOrgAccounts(this.props.currentOrg.id).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,

        });
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  checkToken = () => {
    if (
      this.props &&
      this.props.location &&
      !isEmpty(this.props.location.token)
    ) {
      this.setState(
        {
          token: this.props.location.token,
        },
        () => {
          this.setInitialData();
        }
      );
    } else {
      this.setInitialData();
    }
  };

  setInitialData = () => {};

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getOpenInvoices();
    }
     else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.getOpenInvoices();
        }
      );
    }
  };

  onSearch = (text) => {
    // if(!!text === false) return;
    const token = text.trim();
    this.setState(
      {
        token,
      },
      () => {
        if (token === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = text;
        }
        this.tableOptions.pagination.current = 1;
        this.getOpenInvoices();
      }
    );
  };
  getOpenInvoices = () => {
    const { selectedAccounts } = this.state.filter;
    const page = this.tableOptions.pagination.current;
    const searchText = this.state.token || "";
    const perPage = this.state.recordsPerPage;
    const sortBy = this.state.filterPlaceHolder.sortBy;
    const sortByType = I18n.t(`general.sort_${this.state.filterPlaceHolder.sortByType}`);  
    
    this.setState({ inProgress: true, currentAccount: {} });
    ARApi.fetchOpenInvoices({
      organization_id: this.state.orgId,
      page,
      perPage,
      account_ids: selectedAccounts ? selectedAccounts.join(",") : [],
      sortBy,
      sortByType
      // from_date: this.state.filter.dateRange
      //   ? this.state.filter.dateRange[0].format("DD-MM-YYYY")
      //   : null,
      // to_date: this.state.filter.dateRange
      //   ? this.state.filter.dateRange[1].format("DD-MM-YYYY")
      //   : null,
    }).then((result) => {
      if (result.success) {
        const account_invoices_details = result.account_invoices_details || [];
        this.setState({
          account_invoices_details,
          inProgress: false,
          pagination:
            account_invoices_details.length > 0 && result.pagination
              ? result.pagination
              : {},
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };
  getRelatedUsers = () => {
    const view = this.state.view;
    const initial =
      view === "map"
        ? { perPage: 10000, signin: true }
        : { perPage:  this.state.recordsPerPage };
    this.getOpenInvoices(initial);
  }

  handlePayments = (id) => {
    this.props.history.push("/account_receivables/" + id);
  };

  handleRedirect = (id) => {
    this.props.history.push(`/manage_checks?account_id=${id}`);
    localStorage.setItem("lastPath", "account_receivables");
  };  

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
  }
  );
  };
  handleFilterChange = (element, value) => {
    this.setState({
      filter: { ...this.state.filter, [element]: value },
      // activateButton: false,
    }, () => {
      if(element === 'selectedAccounts' && value?.length === 0){
        this.handleSearch();
      }
      // else if(element === 'dateRange' && value?.length === 0){
      //   this.handleSearch();
      // }
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
        this.tableOptions.pagination && this.tableOptions.pagination.current
            ? this.tableOptions.pagination.current
            : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
          this.tableOptions && this.tableOptions.searchText
              ? this.tableOptions.searchText
              : "",
    };
    if (pagination.current !== currentPage) {
      this.getRelatedUsers();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
          {
            sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "none",
            sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
          },
          () => {
            this.tableOptions.pagination.current = 1;
            this.getRelatedUsers();
          }
      );
    }
  };
 
  render() {
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col span={10}>{I18n.t("menu.account_receivables")}</Col>
            {/* <Col xs={14}>
              <Row
                justify="end"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Col xs={14}>
                  <Search
                    size="default"
                    value={this.state.token || ""}
                    placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                      "menu.account"
                    )}`}
                    onChange={(e) => {
                      const filteredString = removeSpecialCharacters(
                        e.target.value
                      );
                      this.handleOnChange("token", filteredString);
                    }}
                    onSearch={this.onSearch}
                    allowClear
                  />
                </Col>
              </Row>
            </Col>
            */}
          </Row>
          <Row>
            <Col xs={1}/>
            <Col xs={22} className="accounts">
              <Row className="page-content" type="flex">
                <Col sm={24} xs={24} md={14} lg={10}>
                  {/* <FormItem label={I18n.t("general.account")}> */}
                    <AccountCodesFilter
                      SelectedAccountCodes={this.state.filter.selectedAccounts}
                      onValueChange={this.handleFilterChange}
                      accounts={this.state.accounts}
                      size="default"
                      element="selectedAccounts"
                      valueField="id"
                      showLabel={true}
                      styleObject={{ width: "81%" }}
                    />
                  {/* </FormItem> */}
                </Col>
                {/* <Col sm={24} xs={24} md={9} lg={10}>
                <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  "Today & Tomorrow": [moment(), moment().add(1, "day")],
                  "This Week": [moment().startOf("week"), moment().endOf("week")],
                  "This Month": [moment().startOf("month"), moment().endOf("month")],
                }}
                format={AppConfig.dateFormat}
                allowClear={true}
                  onChange={(e) => {
                    this.handleFilterChange("dateRange", e);
                  }}
                  value={this.state.filter.dateRange}
                >
                </RangePicker>
                </Col> */}
                <Col sm={24} xs={24} md={2} lg={2} className="paddingTop5">
                  <Button type="primary" onClick={this.handleSearch} size="small" icon="search">
                    {I18n.t("general.search")}
                  </Button>{" "}
                  &nbsp;&nbsp;
                  {/* <Button type="danger" onClick={this.resetFilter} size="small" disabled={this.state.activateButton}>
                    Clear
                  </Button> */}
                </Col>
                <Col xs={6} />
                <Col xs={4} className="alignRight paddingTop10">
                    <RecordsPerPage
                      onChange={this.onRecordChange}
                      onSearch={this.onSearch}
                      value={this.state.recordsPerPage}
                      defaultValue={this.state.recordsPerPage}
                    />

                </Col>
              </Row>
           </Col>
          </Row>

          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col lg={1} md={1} xl={1}/>
              <Col xs={23} lg={22} xl={22} className="accounts">
                <List
                  rowKey={'account_id'}
                  size="small"
                  scroll={{  y: "calc(100vh - 280px)" }}
                  data={this.state.account_invoices_details}
                  handlePayments={this.handlePayments}
                  pagination={pagination}
                  //pagination={{ position: 'none'}}
                  tableChange={(pagination,filter,sorter,currentTable) =>
                    this.onTableChange(pagination,filter,sorter,currentTable)
                  }
                  handleRedirect={this.handleRedirect}
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}

export const AccountReceivablesComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, currentOrg } = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.ACCOUNT_RECEIVABLES;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return (
    <AccountReceivables
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      currentOrg={currentOrg}
      {...props}
    />
  );
});

export default AccountReceivablesComponent;
