import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Spin,
} from '../../common/UIComponents'
import {
  alertMessage,
  checkNegitive,
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import _ from 'lodash';
import BaseModal from '../BaseModal'
import QuoteDetails from './QuoteDetails'
import { QuotesApis } from '../../api/QuotesApi'
import { renderAlertMessage } from '../../helpers/common'

const defaultSummary = {
  accessorial_charges: 0.0,
  accessorials: [],
  additional_charges: 0.0,
  fuel_surcharge: 0.0,
  pickup_fuel_surcharge: 0.0,
  delivery_fuel_surcharge: 0.0,
  quote_amount: 0.0,
  quoted_by: '',
  quoted_user_name: '',
  pickup_transportation_charge: 0.0,
  delivery_transportation_charge: 0.0,
  transportation_charge: 0.0,
  linehaul_charge: 0.0,
  ind_accessorial_charges: {},
  ori_accessorial_charges: [],
}

const ViewQuote = (props) => {
  const {
    quote_id,
    quote_no,
    quoteViewId,
    setQuoteViewId,
    refreshQuotes,
    validateQuote,
    content,
    setCurrentViewQuote,
    currentViewQuote,
    pickupLocationId,
    deliveryLocationId
  } = props
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [currentQuote, setCurrentQuote] = useState(null)
  const [summaryDetails, setSummaryDetails] = useState({ ...defaultSummary })
  const [revisions, setRevisions] = useState([])
  const [quote_activities,setQuote_activities]=useState([]);
  const [emptyNewFieldVals, setEmptyNewFieldVals] = useState(false)
  const [updateAddOns, setUpdateAddOns] = useState([])
  const [enableDelete, setEnableDelete] = useState(false)
  const [apiCallMade, setApiCallMade] = useState(false)

  const resetValues = (closeModal = false) => {
    setShowInfoWindow(false)
    setErrors(false)
  }
  useEffect(() => {
    const newSummaryDetails =
      revisions.length > 0
        ? revisions[revisions.length - 1]
        : {
            ...defaultSummary,
          }
    // const ind_accessorial_charges = {}
    // if (
    //   !isEmpty(newSummaryDetails.ind_accessorial_charges) &&
    //   _.isArray(newSummaryDetails.ind_accessorial_charges)
    // ) {
    //   newSummaryDetails.ind_accessorial_charges.forEach((charge) => {
    //     ind_accessorial_charges[charge.accessorial] = charge.accessorial_charge
    //   })
    // }
    // newSummaryDetails.ind_accessorial_charges = ind_accessorial_charges
    setSummaryDetails(newSummaryDetails)
    setUpdateAddOns(newSummaryDetails.add_on_details ? newSummaryDetails?.add_on_details : [])
  }, [revisions])

  useEffect(() => {
    // if (quoteViewId && quoteViewId == quote_id) {
    getQuoteDetails(quote_id)
    // }
  }, [])

  useEffect(() => {
    if (!showInfoWindow) {
      setQuoteViewId(null)
    }
  }, [showInfoWindow])

  useEffect(() => {
    setTotal()
  }, [
    summaryDetails.pickup_transportation_charge,
    summaryDetails.delivery_transportation_charge,
    summaryDetails.pickup_fuel_surcharge,
    summaryDetails.delivery_fuel_surcharge,
    summaryDetails.transportation_charge,
    summaryDetails.fuel_surcharge,
    summaryDetails.linehaul_charge,
    summaryDetails.accessorial_charges,
    summaryDetails.additional_charges,
    summaryDetails.ind_accessorial_charges,
    updateAddOns,
  ])

  const updateIndAccessorials = (revisionsData) => {
    const revs = revisionsData.map((revsionObj) => {
      revsionObj.ori_accessorial_charges = revsionObj?.ind_accessorial_charges && _.isArray(revsionObj.ind_accessorial_charges) ? [...revsionObj.ind_accessorial_charges] : []
      const ind_accessorial_charges = {}
      if (
        !isEmpty(revsionObj.ind_accessorial_charges) &&
        _.isArray(revsionObj.ind_accessorial_charges)
      ) {
        revsionObj.ind_accessorial_charges.forEach((charge) => {
          let loc_id = charge.location_id;
          // if(isEmpty(loc_type)){
          //   const typeOfOrder = _.get(currentQuote, "quote.type_of_order", '')
          //   loc_type = ['D','TD'].includes(typeOfOrder) ? 'DELIVERY' : 'PICKUP'
          // }
          if(loc_id){
            if(!ind_accessorial_charges[charge.accessorial]){
              ind_accessorial_charges[charge.accessorial] = {}
            }
            ind_accessorial_charges[charge.accessorial][loc_id] = 
            charge.accessorial_charge
          }else{
            ind_accessorial_charges[charge.accessorial] = 
            charge.accessorial_charge
          }
          
        })
      }
      revsionObj.ind_accessorial_charges = ind_accessorial_charges
      return { ...revsionObj }
    })
    return revs
  }

  const getQuoteDetails = (quote_id) => {
    try {
      setIsLoading(true)
      QuotesApis.fetchone(quote_id).then((result) => {
        if (result.success) {
          const quoteDetails = result.data || {}
          const revisions =
            !isEmpty(quoteDetails.quote) && quoteDetails.quote.quotes
              ? quoteDetails.quote.quotes
              : []
          const quotesActivities =
            !isEmpty(quoteDetails.quote_activities) ? quoteDetails.quote_activities
              : []
              setQuote_activities(quotesActivities);
          
         setCurrentQuote(quoteDetails)
          
          const revs = updateIndAccessorials(revisions)
          // const quotesActiv = updateIndAccessorials(quotesActivities)
          setRevisions([...revs])

          setQuote_activities(quotesActivities)
          //setSummaryDetails(newSummaryDetails);
          setShowInfoWindow(true)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          renderAlertMessage(result.errors)
        }
      })
    } catch (error) {
      setIsLoading(false)
      alertMessage(error.message, 'error', 10)
    }
  }

  const storeUpdatedAmount = () => {
    try {
      setIsLoading(true)
      console.log("summaryDetails", summaryDetails)
      const accessorialData = [];
      if (summaryDetails.ind_accessorial_charges) {
          const charges = summaryDetails.ind_accessorial_charges;
          const oriCharges = summaryDetails.ori_accessorial_charges;
          Object.entries(charges).forEach(([acc, amount]) => {
              if (_.isObject(amount)) {
                Object.entries(amount).forEach(([loc, locAmount]) => {
                    const locObj = _.find(oriCharges, { location_id: loc });
                    accessorialData.push([
                        acc,
                        locAmount,
                        loc,
                        locObj?.location_type || ''
                    ]);
                });
              } else {
                accessorialData.push([
                    acc,
                    amount
                ]);
              }
          });
        }

        
        const filteredAddOns = updateAddOns.filter(addOn => addOn.add_on_name);
        if (updateAddOns.length > 0) {
          const latestAddOn = updateAddOns[updateAddOns.length - 1];
          if (_.isEmpty(latestAddOn['add_on_name']) && latestAddOn['add_on_value'] > 0.00) {
            alertMessage("Description is Required", 'error', 4)
            return;
          }
        }
      const payload = {
        quote_id,
        linehaul_charges: summaryDetails.linehaul_charge,
        additional_charges: summaryDetails.additional_charges,
        accessorial_charges: summaryDetails.accessorial_charges,
        quote_amount: summaryDetails.quote_amount,
        ind_accessorial_charges: accessorialData,
        add_on_details: filteredAddOns
      }
      if(currentQuote.quote.type_of_order === 'T') {
        payload.transportation_charges= summaryDetails.pickup_transportation_charge + summaryDetails.delivery_transportation_charge;;
        payload.pickup_transportation_charges=summaryDetails.pickup_transportation_charge;
        payload.delivery_transportation_charges=summaryDetails.delivery_transportation_charge;
        payload.fuel_surcharge= summaryDetails.pickup_fuel_surcharge + summaryDetails.delivery_fuel_surcharge;;
        payload.pickup_fuel_surcharge=summaryDetails.pickup_fuel_surcharge;
        payload.delivery_fuel_surcharge=summaryDetails.delivery_fuel_surcharge;
      } else{
        payload.transportation_charges= summaryDetails.transportation_charge;
        payload.fuel_surcharges= summaryDetails.fuel_surcharge;
      }
      
       QuotesApis.changeQuoteAmount(payload).then((result) => {
        if (result.success) {
          const quoteDetails = result.data || {}
          const revisions =
            !isEmpty(quoteDetails.quote_order) &&
            quoteDetails.quote_order.quotes
              ? quoteDetails.quote_order.quotes
              : []

          const QuotesActivite =
            !isEmpty(quoteDetails.quote_activities) ? quoteDetails.quote_activities
              : []
          setQuote_activities(QuotesActivite);
          //get the last record of the quotes from the result data
          const latest_quote = result.data.quote_order.quotes[result.data.quote_order.quotes.length - 1]
          const add_on_details = latest_quote['add_on_details']

          // const newSummaryDetails = revisions.length > 0 ? revisions[revisions.length-1] : {
          //   ...defaultSummary
          // };
          setUpdateAddOns(add_on_details)
          setApiCallMade(true)
          alertMessage(I18n.t('messages.saved'))
          setEnableDelete(true)
          const revs = updateIndAccessorials(revisions)
          const quotesActiv = updateIndAccessorials(QuotesActivite)
          setRevisions([...revs])
          setQuote_activities([...quotesActiv]);
          //setShowInfoWindow(true)
          setIsLoading(false)
          setEnableDelete(false)
        } else {
          setIsLoading(false)
          renderAlertMessage(result.errors)
        }
      })
    } catch (error) {
      setIsLoading(false)
      alertMessage(error.message, 'error', 10)
    } finally {
      setEnableDelete(false)
        }
  }

  const onAmountChange = (element, value) => {
    setSummaryDetails({ ...summaryDetails, [element]: value })
  }
  const onAccessorialAmountChange = (element, value, location_id = null) => {
    if(location_id){
      setSummaryDetails({
        ...summaryDetails,
        ind_accessorial_charges: {
          ...summaryDetails.ind_accessorial_charges,
          [element]: {
            ...summaryDetails.ind_accessorial_charges[element],
            [location_id]: value,
          }
        },
      })
    }else {
      setSummaryDetails({
        ...summaryDetails,
        ind_accessorial_charges: {
          ...summaryDetails.ind_accessorial_charges,
          [element]: value,
        },
      })
    }
    
  }

  const setTotal = () => {
    const orderType = currentQuote?.quote?.type_of_order;
    let total = 0;
    if (orderType === "T") {
      total =
        parseFloat(summaryDetails.pickup_transportation_charge || 0) +
        parseFloat(summaryDetails.delivery_transportation_charge || 0) +
        parseFloat(summaryDetails.pickup_fuel_surcharge || 0) +
        parseFloat(summaryDetails.delivery_fuel_surcharge || 0) +
        parseFloat(summaryDetails.linehaul_charge || 0) +
        parseFloat(summaryDetails.additional_charges || 0);
    } else {
      total =
        // parseFloat(summaryDetails.pickup_transportation_charge || 0) +
        // parseFloat(summaryDetails.delivery_transportation_charge || 0) +
        parseFloat(summaryDetails.transportation_charge || 0) +
        parseFloat(summaryDetails.linehaul_charge || 0) +
        //  parseFloat(summaryDetails.accessorial_charges || 0) +
        parseFloat(summaryDetails.fuel_surcharge || 0) +
        parseFloat(summaryDetails.additional_charges || 0);
    }
    Object.keys(summaryDetails.ind_accessorial_charges).forEach(
      (accessorial) => {
        if (!isEmpty(summaryDetails.ind_accessorial_charges[accessorial])) {
          const acc = summaryDetails.ind_accessorial_charges[accessorial];
          if(_.isObject(acc)){
            total += parseFloat(_.sum(Object.values(acc)));
          } else{
            total += parseFloat(acc);
          }
          
        }
      }
    );
    if (!_.isEmpty(updateAddOns)) {
      updateAddOns.forEach((addOn) => {
        if (!isEmpty(addOn["add_on_value"])) {
          total += parseFloat(addOn['add_on_value']);
        }
      });
    }    
    setSummaryDetails({
      ...summaryDetails,
      quote_amount: checkNegitive(total, false),
    });
  };

  const closeModal = () => {
    resetValues()
    refreshQuotes()
    setCurrentViewQuote(null)
    setQuoteViewId(null)
  }

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={`${I18n.t('quotes.details')}: #${quote_no}`}
        className="customer-orders-modal"
        onCancel={() => {
          closeModal()
        }}
        width="90%"
        style={{
          top: 10,
        }}
      >
        <QuoteDetails
          currentQuote={currentQuote}
          summaryDetails={summaryDetails}
          updateAddOns={updateAddOns}
          setUpdateAddOns={setUpdateAddOns}
          emptyNewFieldVals={emptyNewFieldVals}
          onChange={onAmountChange}
          onAccessorialAmountChange={onAccessorialAmountChange}
          storeUpdatedAmount={storeUpdatedAmount}
          revisions={revisions}
          quote_activities= {quote_activities}
          closeModal={closeModal}
          editQuote={props.editQuote}
          cloneQuote={props.cloneQuote}
          validateQuote={validateQuote}
          enableDelete={enableDelete}
          setEnableDelete={setEnableDelete}
          apiCallMade={apiCallMade}
          setApiCallMade={setApiCallMade}
          currentViewQuote = {currentViewQuote}
          pickupLocationId = {pickupLocationId}
          deliveryLocationId={deliveryLocationId}
        />
      </BaseModal>
    )
  }

  return (
    <Fragment>
      {/* <Spin spinning={isLoading} delay={1000}> */}
      {/* <span
          onClick={() => getQuoteDetails(props.quote_id)}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {content}
        </span> */}
      {/* </Spin> */}
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

ViewQuote.propTypes = {
  quote_id: PropTypes.string.isRequired,
  quote_no: PropTypes.string.isRequired,
}

export default ViewQuote
