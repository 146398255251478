/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Spin,
  Select,
  Drawer,
  Collapse,
  Radio,
  Input,
  TextArea,
  Tooltip,
  Popconfirm,
  Checkbox,
  Icon,
  Empty,
  Button,
} from "../common/UIComponents";
import { alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import {
  fetchQuestions,
  saveQuestions,
  fetchQuestion,
  deleteQuestion,
} from "../api/QuestionsApi";
import CreateQuestion from "../components/questions/CreateQuestion";
import MobileInput from "../components/common/MobileInput";
import AddressForm from "../components/common/AddressForm";
import { checkServiceExistance, renderAlertMessage } from "../helpers/common";
import { fetchPopulateFields } from "../api/FormsApi";
import EditIcon from "../components/common/EditIcon";
import DeleteIcon from "../components/common/DeleteIcon";
import { sequenceQuestion } from "../api/QuestionsApi";

const { Panel } = Collapse;
const Question = () => ({
  parentId: "",
  content: "",
  option_type: "",
  options: [],
  qn_index: "",
  has_options: false,
  notes_type_text: "",
  notes_type: "",
  show_other: false,
  is_private: false,
  enable_select_all: false,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
class Questionaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      inProgress: false,
      showForm: false,
      questionaries: [],
      arrangedQuestionaries: [],
      currentQuestion: Question(),
      // parentId: this.props.match.params.routeId,
      // parentType: this.props.location.parentType || I18n.t('questionaries.surveys'),
      parentId: this.props.parentId,
      // parentRecord: this.props.currentRecord || {},
      parentType: this.props.parentType || I18n.t("questionaries.surveys"),
      isNew: true,
      fetchingdbColumns: false,
      dbColumns: [],
      feColumn: [],
      qnsdata: Question(),
      rearrangeForm: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  getDbcolumn = () => {
    this.setState({ fetchingdbColumns: true });
    fetchPopulateFields().then((result) => {
      if (result.success) {
        const feColumn = result.FeCol.map((data) => data.fe_column);
        this.setState({
          dbColumns: result.FeCol || [],
          feColumn,
          fetchingdbColumns: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ fetchingdbColumns: false });
      }
    });
  };

  componentDidMount() {
    this.getQuestionaries(this.state.parentId);
    this.getDbcolumn();
  }

  getQuestionaries = (id) => {
    this.setState({ inProgress: true, currentQuestion: Question() });
    fetchQuestions(id, this.state.parentType).then((result) => {
      if (result.success) {
        const questions = result.questions || [];
        const signOption = questions.find(
          (data) => data.option_type === "signature"
        );
        const sortedQsn = _.sortBy(questions, "qn_index");
        const arrangedQsn = sortedQsn.map((qn, index) => ({
          ...qn,
          qn_index: index,
        }));
        this.setState(
          {
            questionaries: arrangedQsn,
            takeSign: signOption ? true : false,
            inProgress: false,
          },
          () => {}
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  // eslint-disable-next-line react/sort-comp
  closeManageQuestionsWindow = () => {
    this.setState({
      showForm: false,
    });
  };

  showManageQuestionsWindow = () => {
    this.setState({
      currentQuestion: Question(),
      showForm: true,
    });
  };

  // eslint-disable-next-line consistent-return
  getIsolationAnswers = (question, index) => {
    switch (question.option_type) {
      case "text":
        return (
          <Row>
            <Col>
              <Input
                type="text"
                placeholder="Answer"
                style={{ marginRight: 4 }}
                disabled
              />
            </Col>
          </Row>
        );
      case "textbox":
        return (
          <Row>
            <Col>
              <TextArea
                type="text"
                placeholder="Answer"
                style={{ marginRight: 4 }}
                disabled
              />
            </Col>
          </Row>
        );
      case "email_field":
        return (
          <Row>
            <Col>
              <Input
                type="text"
                placeholder="Enter Email"
                style={{ marginRight: 4 }}
                disabled
              />
            </Col>
          </Row>
        );
      case "phone_field":
        return (
          <Row>
            <Col>
              <MobileInput
                handleInputChange={this.handleInputChange}
                disabled
              />
            </Col>
          </Row>
        );
      case "yes_no":
        return (
          <Row>
            <Col>
              <Radio disabled>Yes</Radio>
              <Radio disabled>No</Radio>
            </Col>
          </Row>
        );
      case "address_field":
        return (
          <Row>
            <Col>
              <AddressForm
                autoComplete
                address={{}}
                disabled
                // onChange={this.handleOnAddressChange}
                twoRows
              />
            </Col>
          </Row>
        );
      case "single_choice":
        return (
          <Row>
            <Radio.Group value="">
              {question.options.map((qoption, qindex) => (
                <Col xs={24} sm={24} md={8} lg={8} key={`radio${qindex + 1}`}>
                  <Radio disabled>{qoption.opt_value}</Radio>
                </Col>
              ))}
            </Radio.Group>
          </Row>
        );
      case "multiple_choice":
        return (
          <Row>
            <Checkbox.Group>
              {question.options.map((qoption, qindex) => (
                <Col xs={24} sm={24} md={8} lg={8} key={`check${qindex + 1}`}>
                  <Checkbox disabled /> {qoption.opt_value}&nbsp;&nbsp;
                </Col>
              ))}
            </Checkbox.Group>
          </Row>
        );
      case "dropdown":
        return (
          <Row>
            <Col xs={24} sm={24} md={8} lg={6}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                //  onChange={e => this.handleOnChange('zoneid', e)}
              >
                {question.options.map((qoption, qindex) => (
                  <Select.Option key={qoption.id} value={qoption.id}>
                    {qoption.opt_value}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        );
      case "multi_select_dropdown":
        return (
          <Row>
            <Col xs={24} sm={24} md={8} lg={6}>
              <Select
                mode="multiple"
                showSearch
                style={{ width: "100%" }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {question.options.map((qoption) => (
                  <Select.Option key={qoption.id} value={qoption.id}>
                    {qoption.option_value}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        );
      case "populate":
        return (
          <Row>
            <Col>
              <span className="textBold">
                {I18n.t("questionaries.poulate_field")}:
              </span>
              <Select
                placeholder="Select"
                style={{ width: 200, marginLeft: 15 }}
                disabled
                value={question.pre_populate_field}
                size="small"
              >
                {this.state.dbColumns
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .filter((data) => data.use_in_form)
                  .map((data) => (
                    <Option value={data.fe_column}>{data.label}</Option>
                  ))}
                {/* <Option value="order_number">Order Number</Option>
                <Option value="hawb">HAWB</Option> */}
              </Select>
            </Col>
          </Row>
        );
      // case "signature":
      //   return (
      //     <Row>
      //       <Radio disabled >Yes</Radio>
      //       <Radio disabled >No</Radio>
      //     </Row>
      //   )
      default:
        break;
    }
    // if (quesCode === 'Q101') {
    //   return (<Radio.Group
    //     name={quesCode}
    //     value={this.getCurrentIsolationValue(quesCode, quesType)}
    //     onChange={e => this.handleChange(quesCode, e.target.value)}
    //     disabled
    //   >
    //     <Radio value="YES">YES</Radio>
    //     <Radio value="NO">NO</Radio>
    //   </Radio.Group>);
    // } else if (quesCode === 'Q102') {
    //   return (<Radio.Group
    //     name={quesCode}
    //     value={this.getCurrentIsolationValue(quesCode, quesType)}
    //     onChange={e => this.handleChange(quesCode, e.target.value)}
    //     disabled
    //   >
    //     <Radio value="YES">YES</Radio>
    //     <Radio value="NO">NO</Radio>
    //   </Radio.Group>);
    // } else if (quesCode === 'Q103') {
    //   return (<Radio.Group
    //     name="radiogroup"
    //     value={this.getCurrentIsolationValue(quesCode, quesType)}
    //     onChange={e => this.handleChange(quesCode, e.target.value)}
    //     disabled
    //   >
    //     <Radio value="YES">YES</Radio>
    //     <Radio value="NO">NO</Radio>
    //   </Radio.Group>);
    // } else if (quesCode === 'Q104') {
    //   return (<Radio.Group
    //     name={quesCode}
    //     value={this.getCurrentIsolationValue(quesCode, quesType)}
    //     onChange={e => this.handleChange(quesCode, e.target.value)}
    //     disabled
    //   >
    //     <Radio value="YES">YES</Radio>
    //     <Radio value="NO">NO</Radio>
    //   </Radio.Group>);
    // } else if (quesCode === 'Q105') {
    //   return (<Radio.Group
    //     name={quesCode}
    //     value={this.getCurrentIsolationValue(quesCode, quesType)}
    //     onChange={e => this.handleChange(quesCode, e.target.value)}
    //     disabled
    //   >
    //     <Radio value="YES">YES</Radio>
    //     <Radio value="NO">NO</Radio>
    //   </Radio.Group>);
    // } else if (quesCode === 'Q106') {
    //   return (<Radio.Group
    //     name={quesCode}
    //     value={this.getCurrentIsolationValue(quesCode, quesType)}
    //     onChange={e => this.handleChange(quesCode, e.target.value)}
    //     disabled
    //   >
    //     <Radio value="SELF">SELF</Radio>
    //     <Radio value="FAMILY MEMBERS">FAMILY MEMBERS</Radio>
    //     <Radio value="FOOD DELIVERY">FOOD DELIVERY</Radio>
    //     <Radio value="GOING OUT FOR FOOD">GOING OUT FOR FOOD</Radio>

    //   </Radio.Group>);
    // } else if (quesCode === 'Q107') {
    //   return (<FamilyMembers
    //     rowKey="temporary_id"
    //     data={this.state.family_members}
    //     itemDeleteClick={index => this.handleFamilyMemberDeleteClick(index, quesCode)}
    //     itemOnChange={(index, element, value) => this.handleFamilyMemberOnChange(index, element, value, quesCode)}
    //     size="small"
    //     pagination={{ position: 'none' }}
    //     isNew={this.state.isNew}
    //     isShow="No"
    //   />);
    // } else if (quesCode === 'Q109') {
    //   return (<ContactedPersons
    //     rowKey="temporary_id"
    //     data={this.state.contacted_persons}
    //     itemDeleteClick={index => this.handleContactedPersonDeleteClick(index, quesCode)}
    //     itemOnChange={(index, element, value) => this.handleContactedPersonOnChange(index, element, value, quesCode)}
    //     size="small"
    //     pagination={{ position: 'none' }}
    //     isNew={this.state.isNew}
    //     isShow="No"
    //   />);
    // } else if (quesCode === 'Q108') {
    //   return (<VisitedPlaces
    //     rowKey="temporary_id"
    //     data={this.state.visited_places}
    //     itemDeleteClick={index => this.handleVisitedPlaceDeleteClick(index, quesCode)}
    //     itemOnChange={(index, element, value) => this.handleVisitedPlaceOnChange(index, element, value, quesCode)}
    //     size="small"
    //     pagination={{ position: 'none' }}
    //     isNew={this.state.isNew}
    //     isShow="No"
    //   />);
    // }
  };

  handleInputChange = () => {};

  handleEdit = (event, id) => {
    // const index = _.findIndex(this.state.questionaries, ['id', id]);
    // const currentQuestion = Object.assign({}, this.state.questionaries[index]);
    // this.setState({ currentQuestion, showForm: true });
    this.setState({ inProgress: true });
    fetchQuestion(id, this.state.parentType).then((result) => {
      if (result.success) {
        const currentQuestion =
          result.question_details.question_details.question;
        let options = [];
        currentQuestion.options = options;
        if (
          result.question_details &&
          result.question_details.question_details &&
          result.question_details.question_details.options &&
          _.isArray(result.question_details.question_details.options)
        ) {
          options = result.question_details.question_details.options
            ? result.question_details.question_details.options
            : [];
          options.forEach((option, index) => {
            option.option_value = option.opt_value;
            delete option.opt_value;
            options[index] = option;
          });
          currentQuestion.options = options;
        }

        this.setState({
          currentQuestion,
          inProgress: false,
          showForm: false,
          isNew: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
    event.stopPropagation();
  };

  handleDelete = (id) => {
    // this.setState({ inProgress: true });
    deleteQuestion(id, this.state.parentType).then((result) => {
      if (result.success) {
        // if id belongs to signature question, then dont alert
        const isSignatureQuestion = _.findIndex(
          this.state.questionaries,
          (question) =>
            question.question_id === id && question.option_type === "signature"
        );
        if (isSignatureQuestion === -1) {
          alertMessage(I18n.t("messages.deleted"));
        }
        this.getQuestionaries(this.state.parentId);
        this.setState({
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  saveData = (isNew, data = {}) => {
    if (!isNew) {
      data.question_id = data.id;
    } else {
      data.qn_index = this.state.questionaries.length;
    }
    data[I18n.t(`questionaries.keys.${this.state.parentType}.id`)] =
      data.parentId;
    // data[I18n.t(`questionaries.keys.${this.state.parentType}.options`)] = data.options;
    delete data.parentId;
    this.setState({ inProgress: true });
    saveQuestions(isNew, data, this.state.parentType).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        this.getQuestionaries(this.state.parentId);
        this.setState({
          showForm: false,
          inProgress: false,
          currentQuestion: Question(),
          isNew: true,
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };
  showRoutes = () => {
    if (this.state.parentType === I18n.t("questionaries.surveys")) {
      this.props.history.push(`/${I18n.t("questionaries.surveys")}`);
    } else if (this.state.parentType === I18n.t("questionaries.forms")) {
      this.props.history.push(`/${I18n.t("questionaries.forms")}`);
    }
  };

  handleClear = () => {
    this.setState({
      currentQuestion: Question(),
      isNew: true,
    });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sequencedQuestions = reorder(
      this.state.arrangedQuestionaries,
      result.source.index,
      result.destination.index
    );
    this.setState({ arrangedQuestionaries: sequencedQuestions });
  };

  renderQuestions = (opCodes) => {
    const questions = this.state.rearrangeForm
      ? this.state.arrangedQuestionaries
      : this.state.questionaries;
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={`qsnDroppable`}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions.length > 0 &&
                  questions
                    // .filter((q) => q.option_type !== "signature")
                    .map((question, index) => (
                      <Draggable
                        key={question.question_id}
                        draggableId={question.question_id}
                        index={index}
                        isDragDisabled={!this.state.rearrangeForm}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Collapse
                              key={`h${index + 1}`}
                              accordion
                              defaultActiveKey={[`${index + 1}`]}
                              style={{ height: "auto" }}
                              className="marginTop10"
                            >
                              <Panel
                                showArrow={false}
                                header={
                                  <Fragment>
                                    {`${index + 1}. ${question.content}`}
                                    <sup className="textRed">
                                      {question.mandatory ? "*" : ""}
                                    </sup>
                                  </Fragment>
                                }
                                key={`${index + 1}`}
                                extra={
                                  <Fragment>
                                    {this.state.rearrangeForm ? (
                                      <Fragment>
                                        <span className="alighRight">
                                          <Tooltip
                                            title={I18n.t("form.move_qsn")}
                                          >
                                            <Icon
                                              type="menu"
                                              className="textBold"
                                              style={{
                                                width: 16,
                                              }}
                                              onClick={(event) => {
                                                // If you don't want click extra trigger collapse, you can prevent this:
                                                event.stopPropagation();
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {checkServiceExistance(
                                          opCodes.edit
                                        ) && question.option_type !== "signature" && (
                                          <Tooltip
                                            placement="topRight"
                                            title={I18n.t("general.edit")}
                                            getPopupContainer={(triggerNode) =>
                                              triggerNode.parentNode
                                            }
                                          >
                                    <EditIcon handleClick={(event) =>
                                        this.handleEdit(event, question.question_id)}/>
                                            &nbsp;&nbsp;
                                          </Tooltip>
                                        )}
                                        {checkServiceExistance(
                                          opCodes.delete
                                        ) && (
                                          <Tooltip
                                            placement="topRight"
                                            title={I18n.t("general.delete")}
                                          >
                                            <Popconfirm
                                              placement="topRight"
                                              title={I18n.t(
                                                "messages.delete_confirm"
                                              )}
                                              onConfirm={() =>
                                                this.handleDelete(
                                                  question.question_id
                                                )
                                              }
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                            <DeleteIcon/>
                                              &nbsp;&nbsp;
                                            </Popconfirm>
                                          </Tooltip>
                                        )}
                                      </Fragment>
                                    )}
                                  </Fragment>
                                  // question.id
                                }
                              >
                                {this.getIsolationAnswers(question, index)}
                              </Panel>
                            </Collapse>
                          </div>
                        )}
                      </Draggable>
                    ))}
                {provided && provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  handleSignSave = () => {
    const isNew = !(
      this.state.currentQuestion && this.state.currentQuestion._id
    );
    const { qnsdata: data } = this.state;
    data.parentId = this.state.parentId;
    data.qn_index = this.state.questionaries.length;
    data.has_options = false;
    data.option_type = "signature";
    data.content = "Driver Signature";
    this.saveData(isNew, data);
    return;
  };

  handleTakeSign = () => {
    if (this.state.takeSign) {
      // delete sign
      const signQuestion = this.state.questionaries.find(
        (q) => q.option_type === "signature"
      );
      if (signQuestion) {
        this.handleDelete(signQuestion.question_id);
      }
    } else {
      // take sign
      this.handleSignSave();
    }
    this.setState({ takeSign: !this.state.takeSign });
  };

  showFormResequence = () => {
    const arrangedQuestionaries = _.cloneDeep(this.state.questionaries);
    this.setState({
      rearrangeForm: true,
      arrangedQuestionaries,
    });
  };

  handleUpdateSequence = () => {
    this.setState({ inProgress: true });
    const arrangedQuestions = this.state.arrangedQuestionaries.map(
      (qn, index) =>
        Object.assign({ question_id: qn.question_id, qn_index: index })
    );
    const payload = { sequence: arrangedQuestions };
    sequenceQuestion(payload, this.state.parentType).then((result) => {
      if (result.success) {
        alertMessage("Saved successfully", "success", 10);
        this.setState(
          {
            inProgress: false,
            rearrangeForm: false,
            arrangedQuestionaries: [],
          },
          () => {
            this.getQuestionaries(this.state.parentId);
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  cancelSequence = () => {
    this.setState({ rearrangeForm: false, arrangedQuestionaries: [] });
  };

  onQuestionsDataChange = (data) => {
    this.setState({ qnsdata: data });
  };

  render() {
    const isSurveys = this.state.parentType === I18n.t("questionaries.surveys");
    const isForms = this.state.parentType === I18n.t("questionaries.forms");
    const opCodes = {
      index: isSurveys ? "SQUI" : "FQUI",
      create: isSurveys ? "SQUC" : "FQUU",
      edit: isSurveys ? "SQUU" : "FQUU",
      delete: isSurveys ? "SQUD" : "FQUD",
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              {this.state.rearrangeForm ? (
                <Row>
                  <Col className="alignRight">
                    <Button
                      size="small"
                      onClick={this.cancelSequence}
                      icon='close'
                    >
                      {I18n.t("general.cancel")}
                    </Button>
                    &nbsp;
                    <Button
                      size="small"
                      type="primary"
                      onClick={this.handleUpdateSequence}
                      icon='save'
                    >
                      {I18n.t("general.save")}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Fragment>
                  {this.state.parentType !==
                    I18n.t("questionaries.surveys") && (
                    <Col>
                      <div className="marginBottom20 alignRight textBold">
                        {checkServiceExistance(opCodes.index) && (
                          <Checkbox
                            checked={this.state.takeSign}
                            onChange={this.handleTakeSign}
                          >
                            {I18n.t("questionaries.take_sign")}
                          </Checkbox>
                        )}
                      </div>
                    </Col>
                  )}
                </Fragment>
              )}
              <Col className="questionaries">
                {checkServiceExistance(
                  [opCodes.create, opCodes.update],
                  "ANY"
                ) &&
                  !this.state.rearrangeForm && (
                    <div className="fieldSet borderPrimary marginBottom20">
                      <div className="legend">
                        {this.state.isNew
                          ? I18n.t("questionaries.add")
                          : I18n.t("questionaries.update")}{" "}
                      </div>
                      <CreateQuestion
                        currentQuestion={this.state.currentQuestion}
                        saveData={this.saveData}
                        handleSuccess={(message) =>
                          this.handleSaveSuccess(message)
                        }
                        handleClear={this.handleClear}
                        questions={this.state.questionaries}
                        parentId={this.state.parentId}
                        isNew={this.state.isNew}
                        dbColumns={this.state.dbColumns}
                        feColumn={this.state.feColumn}
                        onUpdate={this.onQuestionsDataChange}
                        parentType={this.state.parentType}
                      />
                    </div>
                  )}

                {checkServiceExistance(opCodes.index) &&
                this.state.questionaries.length > 0 ? (
                  <>
                    {!this.state.rearrangeForm &&
                      this.state.isNew &&
                      this.state.questionaries.length > 1 && (
                        <div className="cursorPointer alignRight">
                          <Tooltip
                            title={I18n.t("form.sequence_qsn")}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placement="topRight"
                          >
                            <span onClick={this.showFormResequence}>
                              <Icon type="swap" />
                              &nbsp;{I18n.t("routes.resequence")}
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    {this.renderQuestions(opCodes)}
                  </>
                ) : (
                  <div className="marginTop20">
                    <Empty
                      description={
                        <h3>{I18n.t("questionaries.no_question_found")}</h3>
                      }
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Spin>

          {this.state.showForm && (
            <Drawer
              title="Confirm Route "
              placement="left"
              closable="false"
              onClose={this.closeManageQuestionsWindow}
              visible={this.state.showForm}
              width="60%"
            >
              <CreateQuestion
                currentQuestion={this.state.currentQuestion}
                saveData={this.saveData}
                handleSuccess={(message) => this.handleSaveSuccess(message)}
                questions={this.state.questionaries}
                parentId={this.state.parentId}
                isNew={this.state.isNew}
              />
            </Drawer>
          )}
        </div>
      </div>
    );
  }
}

Questionaries.propTypes = {
  // match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  // location: PropTypes.shape().isRequired,
  parentId: PropTypes.string.isRequired,
  parentType: PropTypes.string.isRequired,
  // currentRecord: PropTypes.shape().isRequired,
};

export default Questionaries;
