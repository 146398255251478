import React, { Fragment, Component, useContext, useState, useEffect } from "react";
import { Button, Col, Icon, Row, Spin, Tooltip } from "antd";
import CustomerLogList from "../CustomerLogs/CustomerLogList";
import AppConfig from "../../config/AppConfig";
import { RecordsPerPage } from "../orders/RecordsPerPage";

import { Search, Dropdown } from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import {base64ToExcel, checkServiceExistance, convertToTags, limitString, responseTOCsv } from "../../helpers/common";
import moment from "moment";
import DriverLogFilter from "./DriverLogFilter";
import { fetchLocations } from "../../api/LocationsApi";
import AppliedFiltersTags from "../../containers/AppliedFiltersTags";
import { UserContext } from "../../context/UserContext";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { DisplaySettingsContext } from "../../context/DisplaySettings";
import {DriverLogs } from "../../api/CustomerLogs";
import DriverLogList from "./DriverLogList";
import { ScreenKeys } from "../../containers/constants";
import _ from "lodash";
import I18n from "../../common/I18n";
import { fetchUsers } from "../../api/UsersApi";

const defaultFilter = {
  fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
  toDate: moment(),
  warehouse_id: [],
  as_export : false,
  search_order_token: "",
  searchKeyPressed: false,
  sortBy: "none",
  sortByType: "descend",
  driver_ids: []
};

class DriverLogReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_logs_report: [],
      appliedFiltersTags : [],
      warehouses: [],
      organization: "",
      warehouse_id: [],
      organization_id : "",
      inProgress: false,
      listProgress: false,
      accounts: [],
      recordsPerPage: props.recordsPerPage,
      pagination: { current_page: 1 },
      filterWindow: false,
      searchText : "",
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      isLoading:false,
      isFetchingDrivers: false,
      drivers: []
    };
    this.tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  }

  componentDidMount() {
    const { warehouseFilter } = this.props;

    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    this.setState(
      (prevState) => ({
        ...prevState,
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          warehouseIds: currentWhId,
        },
      }),
      () => {
        this.getWarehouses()
        this.getDrivers();
        this.updateWhFilter();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
}

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses)) {
      const { warehouseFilter } = this.props;

      const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
        ? warehouseFilter.selectedWarehouses
        : warehouseFilter?.selectedWarehouses
          ? [warehouseFilter.selectedWarehouses]
          : null;

      this.setState(
        (prevState) => ({
          ...prevState,
          filterPlaceHolder: {
            ...prevState.filterPlaceHolder,
            warehouseIds: currentWhId,
          },
        }),
        () => {
          this.getDrivers();
          this.updateWhFilter();
          this.computeTags(this.state.filterPlaceHolder);
        }
      );
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }

  clearFilter = () => {
    const {warehouseFilter} = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          fromDate: moment().subtract(
            AppConfig.invoices_filter_duration,
            "day"
          ),
          toDate: moment(),
          warehouse_id: currentWhId,
          driver_ids: []
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };


  handleOnFilterRemoval = (key) => {
    const {warehouseFilter} = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break; 
      case  "driver_ids": 
        filterVariable.driver_ids = defaultFilter.driver_ids;
      case "warehouse_id":
        filterVariable.warehouse_id = currentWhId;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      this.handleSearch();
    });
  };


  computeTags = (filterPlaceHolder) => {
    const {warehouseFilter} = this.props;
    const { warehouses } = warehouseFilter;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const keys = ["fromDate", "toDate","warehouse_id", "driver_ids"];

    const filteredData = {
      fromDate: {
        label: "Driver Log Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(AppConfig.invoices_filter_duration, "day")
              .format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouse_id: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouse_id.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          2
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouse_id");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouse_id?.length === 1 &&
          filterPlaceHolder?.warehouse_id[0] === currentWhId[0]
            ? false
            : true,
      },
      driver_ids: {
        label: "Driver(s)",
        value: limitString(
          this.state.drivers
            .map((user) => {
              if (filterPlaceHolder?.driver_ids?.includes(user.id)) {
                return user.employee_code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          2
        ),
        onClose: () => {
          this.handleOnFilterRemoval("driver_ids");
        },
        closeable: true,
      },

    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  updateWhFilter = () => {
    const { warehouseFilter } = this.props;
    const filterVals = this.state.filterPlaceHolder;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
    ? warehouseFilter.selectedWarehouses
    : warehouseFilter?.selectedWarehouses
    ? [warehouseFilter.selectedWarehouses]
    : null;
    this.handleFilterPlaceHolderChange({
      ...filterVals,
      warehouse_id: currentWhId,
    }, () => {
      this.getDriverLogReport();
    });
  };

  onSearch = (value) => {
    console.log("onSearch", value);
  };

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
        // alertMessage(result.errors[0], "error", 10);
      }
    });
  }; 

  getDrivers = async () => {
    this.setState({ isFetchingDrivers: true });
    const result = await fetchUsers('driver', 1, null);
    if (result.success) {
      const drivers = result.users || [] 
      this.setState({ drivers, isFetchingDrivers: false });
    } else {
      this.setState({ isFetchingDrivers: false, drivers: [] });
    }
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.tableOptions.pagination.current = 1;
    this.setState({ recordsPerPage: value },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getDriverLogReport();
        updateRecordsPerPage(ScreenKeys.DRIVER_LOG_REPORT, value);
      }
      );
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getDriverLogReport();
    } else if (sorter && !isEmpty(sorter.field)) {
      const filterVals = this.state.filterPlaceHolder;
      this.tableOptions.pagination.current = 1;
      this.handleFilterPlaceHolderChange(
        {
          ...filterVals,
          sortBy: sorter.columnKey,
          sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.getDriverLogReport();
        }
      );
    }
    // this.getDriverLogReport();
  };

  tablePagination = () => {
    const { pagination, recordsPerPage } = this.state;
    return {
      total: pagination ? pagination.total_count : 0,
      current: pagination ? pagination.current_page : 1,
      pageSize: pagination
        ? pagination.per_page || recordsPerPage
        : recordsPerPage,
    };
  };


  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };



  onTagClose = (tagsObj) => {
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          ...tagsObj,
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.getDriverLogReport();
      }
    );
  };

  setDefaultvalues = () => {
    setFromDate(moment().subtract(AppConfig.invoices_filter_duration, "day"));
    setToDate(moment());

  }

  handleExport = () => {
    const page =
      this.tableOptions.pagination?.current &&
      !isEmpty(this.tableOptions.pagination.current)
        ? this.tableOptions.pagination.current
        : 1;
    const perPage = this.state.recordsPerPage;
    this.setState({ isLoading: true});
    DriverLogs.fetch(page,perPage,{ ...this.state.filterPlaceHolder,as_export : true }).then((result) => {
      if(result.success) {
       const response = result.file;
       const fileName = result.fileName;
        if(response) {
          base64ToExcel(response, fileName, "EXCEL");
          this.setState({ isLoading: false});
        } 
      } else {
        alertMessage(result.message, "error", 5)
        this.setState({ isLoading: false});
      }
    })
  }


  handleSearch = (filter = {}) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder,filter)
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getDriverLogReport();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  _renderSearchBar = () => (
    <Dropdown
          onVisibleChange={this.handleVisibleChange}
          visible={this.state.filterWindow}
          trigger={["click"]}
          overlay={
            <div
              style={{
                backgroundColor: "white",
                width: 550,
                padding: 15,
                marginTop: 10,
              }}
              className="boxShadow"
            >
              <DriverLogFilter
                filter={this.state.filterPlaceHolder}
                onChange={this.handleFilterPlaceHolderChange}
                onSearch={this.handleSearch}
                warehouseFilter={this.props.warehouseFilter}
                drivers={this.state.drivers}
              />
            </div>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          className="cursorPointer"
          shape="round"
          disabled={this.state.isLoading}
        >
          Filter
          <Icon type="caret-down" />
        </Button>
          </div>
        </Dropdown>
  );

  _renderHeader = () => (
    <Row className="page-header" type="flex" justify="space-between" >
      <Col >{I18n.t("driver_log_report.menu")}</Col>
      <Col  className="headerOptionDiv">
        {this._renderSearchBar()}
      </Col>
    </Row>
  );

  getDriverLogReport = () => {
    const page =
      this.tableOptions.pagination?.current &&
      !isEmpty(this.tableOptions.pagination.current)
        ? this.tableOptions.pagination.current
        : 1;
    const perPage = this.state.recordsPerPage;
    this.setState({ listProgress: true });
    DriverLogs.fetch(page, perPage,this.state.filterPlaceHolder).then((result) => {
      if (result.success) {
        const report = result.customer_logs_report || []
        this.setState({
          customer_logs_report: report,
          pagination: result.pagination,
          listProgress: false,
        });
      } else {
        alertMessage(result.errors, "error", 5);
        this.setState({
          listProgress: false,
          customer_logs_report:[],
          pagination: {},
        })
      }
    });
  };

  resetSearchTags = () => {
    this.setState({ updateSearchTags: false });
  };
  combinedKey = (record,index) => `${record.id}-${index}`;

  render() {
    const { customerLogData,customer_logs_report, recordsPerPage,filterPlaceHolder, updateSearchTags } = this.state;
    return (
      <Spin spinning={this.state.inProgress || this.state.listProgress} delay={1000}>
      <div className="content-outer">
        <div className="content">
          <this._renderHeader />

            <Row
              type="flex"
              justify="space-between"
              style={{
                marginBottom: 15,
                marginTop: 15
              }}>
              <Col>
                <Row type="flex" justify="space-between">
                <Col span={24}>
                  <AppliedFiltersTags
                    tags={this.state.appliedFiltersTags}
                    clearFilter={() => this.clearFilter()}
                    isSearching={filterPlaceHolder.searchKeyPressed}
                    searchText={filterPlaceHolder.search_order_token}
                    searchBy={"Order"}
                    updateSearchTags={updateSearchTags}
                    resetSearchTags={this.resetSearchTags}
                    clearSearch={this.clearSearch}
                  />
                </Col>
              </Row>
              </Col>
              <Col>
                <Row type="flex" justify="end" >
                  <Col>
                    <Button
                      size="small"
                      className="cursorPointer"
                      type="primary"
                      loading={this.state.isLoading}
                      disabled={this.state.isLoading}
                      onClick={this.handleExport}
                      shape="round"
                    >
                      {this.isLoading ? (
                        "Exporting..."
                      ) : (
                        <span>
                          Export EXCEL <Icon type="file-excel" theme="filled" />
                        </span>
                      )}
                    </Button>
                    &nbsp;&nbsp;
                  </Col>

                  <Col>
                    <RecordsPerPage
                      onChange={this.onRecordChange}
                      onSearch={this.onSearch}
                      value={recordsPerPage}
                      defaultValue={recordsPerPage}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

          <Row>
            <DriverLogList
              rowKey={this.combinedKey}
              data={customer_logs_report}
              scroll={{ x: "max-content",y:"calc(100vh - 300px)" }}
              pagination={this.tablePagination()}
              tableChange={(pagination, filter, sorter, currentTable) =>
                this.onTableChange(pagination, filter, sorter, currentTable)
              }
            />
          </Row>
        </div>
      </div>
      </Spin>
    );
  }
}


export const DriverLogReport = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const userContext= useContext(UserContext);
  const { currentOrg } = userContext;
  const orderConfigContextData = useContext(OrderConfigContext);

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);

  const perPageKey = ScreenKeys.DRIVER_LOG_REPORT;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return (
    <DriverLogReportComponent
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      currentOrg={ currentOrg }
      userContext={userContext}
      orderConfigContext = {orderConfigContextData}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default DriverLogReport;
