import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import I18n from "../../common/I18n";
import userStore from "../../stores/UserStore";
import { BatchManagementApi } from "../../api/BatchManagement";
import { alertMessage } from "../../common/Common";
import { withRouter } from "react-router-dom";
import { renderAlertMessage } from "../../helpers/common";

const BatchCreateModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form;
  const { existingBatchId } = props;

  useEffect(() => {
    if (existingBatchId) {
      BatchManagementApi.fetch().then((result) => {
        if (result.success) {
          const matchingBatch = result.data.ar_batches.find(
            (batch) => batch.id === existingBatchId
          );
          if (matchingBatch) {
            setFieldsValue({
              batchNumber: matchingBatch.batch_number,
              cappedAmount: matchingBatch.capped_amount,
            });
          } else {
            alertMessage("No matching batch found", "error");
          }
        } else {
          renderAlertMessage(result.errors)
        }
      });
    }
  }, []);

  useEffect(() => {
    setFieldsValue({
      batchNumber: props.batchNumber,
      cappedAmount : props.cappedAmount,
    });
  }, []);

  const validateForm = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      // Prepare payload for batch creation or update
      const payload = {
        organization_id: userStore.getStateValue("selectedOrg"),
        ar_batch_number: values.batchNumber.toUpperCase(),
        capped_amount : values.cappedAmount,
        current_user_id: userStore.getStateValue("id"),
      };
      if (existingBatchId) {
        payload.ar_batch_id = existingBatchId;
        setLoading(true);
        BatchManagementApi.updateBatch(payload).then((result) => {
          setLoading(false);
          if (result.success) {
            alertMessage("Updated Successfully", "success");
            props.closeModal(true);
            props.getCreatedBatches();
          } else {
            renderAlertMessage(result.errors)
          }
        });
      } else {
        setLoading(true);
        BatchManagementApi.create(payload).then((result) => {
          setLoading(false);
          if (result.success) {
            alertMessage("Batch created successfully", "success");
            props.history.push({
              pathname: `/manage_batch/${result.data.ar_batch.id}`,
            });
            props.closeModal(true);
            props.getCreatedBatches();
          } else {
            renderAlertMessage(result.errors)
          }
        });
      }
    });
  };

  return (
    <div>
      <Form>
        <Form.Item
          label={I18n.t("batch_management.batch_name")}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
        >
          {getFieldDecorator("batchNumber", {
            rules: [
              { required: true, message: "Enter Batch Number" },
              {
                validator: (_, value, callback) => {
                  if (
                    value &&
                    (value.includes(" ") ||
                      /[!@#$%`^&*(),.?"':;{}[\]|<>]/.test(value))
                  ) {
                    callback("Spaces or special characters are not allowed");
                  } else {
                    callback();
                  }
                },
              },
            ],
          })(<Input style={{ textTransform: "uppercase" }} />)}
        </Form.Item>
        <Form.Item
              label={I18n.t("batch_management.max_amount")}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
            >
              {getFieldDecorator('cappedAmount', {
                rules: [
                  {
                    required: false,
                    message: 'invalid input',
                  },
                ],
              })(
                <Input type="number" />,
              )}
            </Form.Item>
        <Row className="marginTop10">
          <Col xs={24} className="alignCenter">
            <Button
              className="marginRight5"
              size="small"
              onClick={() => props.closeModal()}
              icon='close'
            >
              {I18n.t("general.cancel")}
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={validateForm}
              className="primary-save"
              loading={loading}
              icon="save"
            >
              {existingBatchId ? "Update" : "Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const WrappedBatchCreateModal = withRouter(
  Form.create({ name: "BatchCreateModal" })(BatchCreateModal)
);

export default WrappedBatchCreateModal;
