/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AddressInfo from '../common/AddressInfo';
import { Col, Row, Icon } from '../../common/UIComponents';
import { doFormate } from '../../common/Common';
import { formatUSPhoneNumber } from '../../helpers/common';
// import { formatUSPhoneNumber } from '../../../../../assets/javascripts/common';


const StopContact = ({
  stop, withLables, showAddress = true, showEmail = true,
}) => {
  const contact_details = stop.contact_details ? stop.contact_details : {};
  const contact_phone_number_1 = fomatString(contact_details.contact_phone_number_1);
  const contact_phone_number_2 = fomatString(contact_details.contact_phone_number_2);
  const contact_name = fomatString(contact_details.contact_name);
  const contact_email = fomatString(contact_details.contact_email);
  return withLables ?
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}>
            <div className="textCenter">
              <Icon type="user" />
            </div>
          </Col>
          <Col sm={10} xs={13} md={9} lg={9}>
            <div className="info-text textBold">Contact Name</div>
          </Col>
          <Col xs={1}>:</Col>
          <Col sm={11} xs={8} md={12} lg={12}>
            <div className="info-text">
              <span>{contact_name}</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}>
            <div className="textCenter">
              <Icon type="phone" />
            </div>
          </Col>
          <Col sm={10} xs={13} md={9} lg={9}>
            <div className="info-text textBold">Contact No.1</div>
          </Col>
          <Col xs={1}>:</Col>
          <Col sm={11} xs={8} md={12} lg={12}>
            <div className="info-text">
              <span>{contact_phone_number_1 !== 'NA' ? formatUSPhoneNumber(contact_phone_number_1) : contact_phone_number_1}</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}>
            <div className="textCenter">
              <Icon type="phone" />
            </div>
          </Col>
          <Col sm={10} xs={13} md={9} lg={9}>
            <div className="info-text textBold">Contact No.2</div>
          </Col>
          <Col xs={1}>:</Col>
          <Col sm={11} xs={8} md={12} lg={12}>
            <div className="info-text">
              <span>{contact_phone_number_2 !== 'NA' ? formatUSPhoneNumber(contact_phone_number_2) : contact_phone_number_2}</span>
            </div>
          </Col>
        </Row>
      </Col>
      {showEmail !== false &&
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row>
            <Col sm={2} xs={2} md={2} lg={2}>
              <div className="textCenter">
                <Icon type="mail" />
              </div>
            </Col>
            <Col sm={10} xs={13} md={9} lg={9}>
              <div className="info-text textBold">Email</div>
            </Col>
            <Col xs={1}>:</Col>
            <Col sm={11} xs={8} md={12} lg={12}>
              <div className="info-text wordWrap">
                <span>{contact_email}</span>
              </div>
            </Col>
          </Row>
        </Col>
      }
      {showAddress !== false &&
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row>
            <Col sm={2} xs={2} md={2} lg={2}>
              <div className="textCenter">
                <Icon type="environment" />
              </div>
            </Col>
            <Col sm={10} xs={13} md={9} lg={9}>
              <div className="info-text textBold">Address</div>
            </Col>
            <Col xs={1}>:</Col>
            <Col sm={11} xs={8} md={12} lg={12}>
              <div className="info-text wordWrap">
                {stop.location && stop.location.l_address ? <AddressInfo address={stop.location.l_address} /> : 'NA'}
              </div>
            </Col>
          </Row>
        </Col> }
    </Row>
    :
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} className="textBold">
            <Icon type="user" />&nbsp;&nbsp;<span>{contact_name}</span>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={24} xs={24} md={24} lg={24}>
            <div className="info-text">
              <Icon type="phone" /> &nbsp;&nbsp;
              <span>{contact_phone_number_1 !== 'NA' ? formatUSPhoneNumber(contact_phone_number_1) : contact_phone_number_1}</span>
              <span>{contact_phone_number_2 !== 'NA' &&
              <Fragment>,&nbsp;
                {formatUSPhoneNumber(contact_phone_number_2)}
              </Fragment>}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} className="textBold">
            <span>
                {stop.location && stop.location.l_address ? <AddressInfo showIcon={true} address={stop.location.l_address} /> : 'NA'}
            </span>
            
          </Col>
        </Row>
      </Col>
      {showEmail !== false && <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={24} xs={24} md={24} lg={24}>
            <div>
              <Icon type="mail" />&nbsp;&nbsp;
              <span>{contact_email}</span>
            </div>
          </Col>
        </Row>
      </Col>
      }
      {showAddress !== false && <Col xs={24} sm={24} md={24} lg={24}>
        <Row>
          <Col sm={24} xs={24} md={24} lg={24}>
            <div className="info-text wordWrap">
              <Icon type="environment" className="marginTop5" />&nbsp;&nbsp;

              {stop.location && stop.location.l_address ? <AddressInfo address={stop.location.l_address} /> : 'NA'}
            </div>
          </Col>
        </Row>
      </Col>
    }

    </Row>;
};

function fomatString(str, format = '') {
  return doFormate(str, format);
}
export default StopContact;

StopContact.propTypes = {
  stop: PropTypes.shape().isRequired,
};

