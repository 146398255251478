import React, { useContext, useEffect, useState, Fragment } from 'react'
import { Button, Col, Drawer, Dropdown, Icon, Modal, Row, Select, Skeleton, Spin } from 'antd';
import moment from 'moment'
import { withRouter } from 'react-router'
import { fetchLocations } from '../api/LocationsApi'
import { RecoveriesApis } from '../api/RecoveriesApi'
import { fetchUsers } from '../api/UsersApi'
import { alertMessage, refreshIcon } from '../common/Common'
import I18n from '../common/I18n'
import { Search } from '../common/UIComponents'
import BaseModal from '../components/BaseModal'
import RecoveryList from '../components/recoveries/RecoveryList'
import RecoveryFilter from '../components/recoveries/RecoveryFilter'
import AppConfig from '../config/AppConfig'
import RecoveryPlanForm from './RecoveryPlanForm'
import { isEmpty } from '../common/Common'
import AppliedFiltersTags from './AppliedFiltersTags'
import { checkServiceExistance, convertToTags, selectedWarehouseNames, limitString, removeSpecialCharacters, getOrderInfo, renderAlertMessage } from '../helpers/common';
import RecoveryActions from '../components/recoveries/RecoveryActions'
import ChangeStatusForm from '../components/recoveries/ChangeStatusForm'
import { fetchShortFormAccounts } from '../api/Account'
import RecoveryStats from '../components/recoveries/RecoveryStats'
import { fetchLocationsByName } from '../api/PredefinedStops'
import { WarehouseContext } from '../context/WarehouseContext';
import { OrgContext } from '../context/OrgContext';
import { DisplaySettingsContext } from '../context/DisplaySettings'
import { RecordsPerPage } from '../components/orders/RecordsPerPage'
import { defaultSearchRecoveryfilter } from '../components/dispatches/helpers'
import EditOrderFormComponent from '../components/orders/EditOrderForm';
import { addAnyStopToRoute } from '../api/Routes';
import { checkIfOrdershasExceededCreditLimit } from '../components/orders/helpers';
import { ScreenKeys } from './constants';
import _ from 'lodash';
import { UserContext } from '../context/UserContext';

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} }

function Recovery(props) {
  const { reqFrom = "menu",  route = null } = props;
  const isDispatchScreen = reqFrom === "dispatch";
  const defaultStatus = isDispatchScreen  ? 'in_hand' : 'in_transit'
  const defaultFilter = defaultSearchRecoveryfilter(props)
  const [warehouses, setWarehouses] = useState(props.warehouseFilter.warehouses)
  const [freightForwarders, setFreightForwarders] = useState([])
  const [accounts, setAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [drivers, setDrivers] = useState([])
  const [showRecoveryForm, setShowRecoveryForm] = useState(false)
  const [recoveries, setRecoveries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAssigning, setIsAssigning] = useState(false)
  const [pagination, setPagination] = useState({ current_page: 1 })
  // const [isInitial, setIsInitial] = useState(true)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState({
    selectedKeys: [],
    selectedRows: [],
  })
  const [showFilterWindow, setShowFilterWindow] = useState(false)
  const [updateSearchTags, setUpdateSearchTags] = useState(false)
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    // warehouse_id: props.warehouseFilter.selectedWarehouse,
    ...defaultFilter,
  })
  const [searchBy, setSearchBy] = useState('Order')
  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchKeyPress, setSearchKeyPress] = useState(false)
  const [appliedFiltersTags, setAppliedFiltersTags] = useState([])
  const [currentStatus, setCurrentStatus] = useState(defaultStatus)
  const [statsData, setStatsData] = useState({})
  const [searchButtonClicked, setSearchButtonClicked] = useState(false)
  const[dispSetting,setDispSetting] = useState({})
  const userContext = useContext(UserContext)
  const { currentUser , updateRecordsPerPage } = userContext
  const perPageKey = isDispatchScreen ?  ScreenKeys.RECOVERIES_LISTING_DISPATCH : ScreenKeys.RECOVERIES_LISTING 
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved)
  const [ showEditForm, setShowEditForm ] = useState(false)
  const[selectedWarehouse, setSelectedWarehouse] = useState(props.warehouseFilter.selectedWarehouses)


  const dynamicDateObject = {
    fromDate: moment(filterPlaceHolder.dateSearchValue.fromDate).format("YYYY-MM-DD"),
    toDate: moment(filterPlaceHolder.dateSearchValue.toDate).format("YYYY-MM-DD"),
  };

  const defaultDateObject = {
    fromDate: moment(defaultFilter.dateSearchValue.fromDate).format("YYYY-MM-DD"),
    toDate: moment(defaultFilter.dateSearchValue.toDate).format("YYYY-MM-DD"),
  };

 const hasWareHouseChanged = (prevWarehouse, currentWarehouse) => {
      return prevWarehouse !== currentWarehouse;
    }

  useEffect(()=>{
    setDispSetting(props.displayConfiguration)
  },[props.displayConfiguration])

  const computeTags = (filterVariable) => {
    const keys = [
      'account_code',
      'warehouse_id',
      'freight_forwarder',
      'zone_ids',
      'currentStatus',
      'dateSearchBy',
      'sortBy',
      I18n.t('recoveries.schedule_times.type'),
    ]
    const filteredData = {
      account_code: {
        label: I18n.t('general.account'),
        value:
          accounts.find((acc) => acc.code === filterVariable.account_code)
            ?.name || '',
            closeable: true,
        onClose: () => {handleOnFilterRemoval('account_code')},
      },
      zone_ids: {
        label: I18n.t('general.zone'),
        value:
          filterVariable?.zone_ids && filterVariable.zone_ids.length > 0
            ? limitString(filterVariable.zone_ids.map((zone) => zone.label).join(', '),5)
            : 'ALL',
            closeable: filterPlaceHolder.zone_ids.length > 0 ? true : false,
        onClose: () => {handleOnFilterRemoval('zone_ids')},
      },
      warehouse_id: {
        label: 'Warehouse',
        value:
          selectedWarehouseNames(props.warehouseFilter),
        onClose: () => {},
        // closeable: props.warehouseFilter.warehouses.length > 1 ? true : false,
        closeable : false,
      },
      freight_forwarder: {
        label: I18n.t('location.freight_forwarder'),
        value:
          freightForwarders.find(
            (ff) => ff.id === filterPlaceHolder.freight_forwarder,
          )?.name || '',
          closeable: filterPlaceHolder.freight_forwarder === "" ? false : true,
        onClose: () => {handleOnFilterRemoval('freight_forwarder')},
      },
      currentStatus: {
        label: 'Status',
        value:
          currentStatus !== ''
            ? I18n.t(`recoveries.${currentStatus}`)
            : I18n.t('general.all'),
            closeable:currentStatus === "in_transit" ? false : true,
        onClose: () => {handleOnFilterRemoval('currentStatus')},
      },
      dateSearchBy: {
        label:
          filterVariable.dateSearchBy === 'recovery_eta'
            ? I18n.t('recoveries.recovery_date')
            : I18n.t('recoveries.deadline'),
        value: `${moment(filterVariable.dateSearchValue.fromDate).format(
          'MMM DD, YYYY',
        )} - ${moment(filterVariable.dateSearchValue.toDate).format(
          'MMM DD, YYYY',
        )}`,
        closeable: _.isEqual(dynamicDateObject, defaultDateObject) ? false : true,
        onClose: () => {handleOnFilterRemoval('dateSearchBy')},
      },
      // sort_by: {
      //   label: I18n.t('general.sort_by'),
      //   value: I18n.t(`order.filters.sortBy.${filterPlaceHolder.sort_by}`),
      //   onClose: () => {},
      //   closeable: false,
      // },
    }
    if (filterVariable.dateSearchBy === 'recovery_eta') {
      filteredData[I18n.t('recoveries.schedule_times.type')] = {
        label: I18n.t('recoveries.schedule_times.label'),
        value: I18n.t(
          `recoveries.schedule_times.${
            filterVariable[I18n.t('recoveries.schedule_times.type')]
          }_label`,
        ),
        closeable: filterPlaceHolder.schedule_orders_type === "BOTH" ? false : true ,
        onClose: () => {handleOnFilterRemoval(I18n.t('recoveries.schedule_times.type'))},
      }
    }
    const warehouse_id = filteredData.warehouse_id
    const tagsInfo = convertToTags({...filterVariable, currentStatus , warehouse_id } , keys, filteredData)
    setAppliedFiltersTags(tagsInfo)
  }

  const handleOnFilterRemoval = (key) => {
    const filterVariable = { ...filterPlaceHolder }
    switch (key) {
      case 'account_code':
        filterVariable.account_code = defaultFilter.account_code
        break
        case 'freight_forwarder':
        filterVariable.freight_forwarder = defaultFilter.freight_forwarder
        break
      case 'zone_ids':
        filterVariable.zone_ids = defaultFilter.zone_ids
        break
      case 'currentStatus':
        setCurrentStatus('')
        break
      case 'dateSearchBy':
        filterVariable.dateSearchBy = defaultFilter.dateSearchBy
        filterVariable.dateSearchValue = {fromDate:defaultFilter.dateSearchValue.fromDate, toDate:defaultFilter.dateSearchValue.toDate}
        break
      case I18n.t('recoveries.schedule_times.type'):
        filterVariable[I18n.t('recoveries.schedule_times.type')] = defaultFilter.schedule_orders_type
        break
      default:
        break
    }
    computeTags(filterVariable)
    setFilterPlaceHolder(filterVariable)
  }

  useEffect(() => {
    debouncedRecoveries();
  }, [recordsPerPage]);
  
  const recordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };
  
  const onSearch = (value) => {
  };
    

  const handleStatusChange = (status, ids) => {
    if (ids.length > 0) {
      const data = {
        recovery_ids: ids,
        status: status,
      }
      RecoveriesApis.changeStatus(data).then((result) => {
        if (result.success) {
          alertMessage(
            `${I18n.t('messages.changed_successfully', {
              item: I18n.t('general.status'),
            })}`,
            'success',
            5,
          )
          setRecoveries(
            recoveries.map((item, i) => {
              if (ids.includes(item.id)) {
                return { ...item, status: status }
              }
              return item
            }),
          )
        }
      })
    }
  }
  const navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(accounts, ['code', account_code])
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, accounts[accountIndex])
      const account_id = !isEmpty(accountData.primary_account_id)
        ? accountData.primary_account_id
        : accountData.id
      props.history.push(`/accounts/${account_id}/settings`)
    }
  }

  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag)
  }

  const updateFilterSearchValues = (filter, cb) => {
    const acc = accounts.find((account) => account.code === filter.account_code)
    setSelectedAccount(acc)
    setFilterPlaceHolder(Object.assign({}, filter))
    handleSearch(filter)
  }

  const handleSearch = (filter) => {
    setSearchText('')
    setIsSearching(false);
    tableOptions.pagination.current = 1
    computeTags(filter);
    setShowFilterWindow(false)
  }

  useEffect(() => {
    getDrivers()
    getFreightForwarders()
    debouncedRecoveries()
  }, [])
  
  useEffect(() => {
    tableOptions.pagination.current = 1
    setWarehouses(props.warehouseFilter.warehouses)
    getAccounts(props.warehouseFilter.selectedWarehouses)
    computeTags(filterPlaceHolder)
    debouncedRecoveries()
  }, [filterPlaceHolder])


  useEffect(() => {
    getAccounts(props.warehouseFilter.selectedWarehouses);
  }, [props.warehouseFilter.selectedWarehouses, props.warehouseFilter.warehouseWithAccounts])



  useEffect(() => {
    if (hasWareHouseChanged(props.warehouseFilter.selectedWarehouses, selectedWarehouse)) {
      setSelectedWarehouse(props.warehouseFilter.selectedWarehouses)
      const defFilter = defaultSearchRecoveryfilter(props, defaultStatus)
      const updatedFilter = {
        ...filterPlaceHolder,
         ...defFilter,
      }
      setFilterPlaceHolder(updatedFilter)
     }
  }, [props.warehouseFilter.selectedWarehouses])


  useEffect(() => {
    if (searchKeyPress) {
      debouncedRecoveries()
      computeTags(filterPlaceHolder)
    }
  }, [searchKeyPress])
  

  useEffect(() => {
    // if (!isInitial) {
      tableOptions.pagination.current = 1
      debouncedRecoveries()
      computeTags(filterPlaceHolder)
    // }
  }, [currentStatus])


  const renderSearchBar = (showTitle = false) => {
    return (
      <div className="headerOptionDiv headerFilter">
        {
            showTitle && <span className="textBold">
              {I18n.t("general.search")}:&nbsp;&nbsp;
            </span>
          }
        <Search
          placeholder={I18n.t('order.filters.placeholder')}
          className="seachWithDropDown recoverySearch search_with_prefix"
          size="default"
          value={!isEmpty('searchText') ? searchText.toUpperCase() : ''}
          onSearch={(value) => {
            setSearchText(value);
            if (!isDispatchScreen) {
              setCurrentStatus("");
            }
            setSearchKeyPress(true);
            setUpdateSearchTags(true);
            setIsSearching(value.length > 0);
          }}
          onChange={(e) => {
            const filteredString = removeSpecialCharacters(e.target.value);
            setSearchText(filteredString)
          }}
          style={{ width: 500, textTransform: 'uppercase' }}
          // onClear={(e) => {
          //   setSearchText('')
          // }}
          allowClear
          suffix={
            <>
              <Dropdown
                onVisibleChange={handleVisibleChange}
                visible={showFilterWindow}
                trigger={['click']}
                overlay={
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: 500,
                      padding: 15,
                      marginTop: 10,
                      marginRight: -12,
                    }}
                    className="boxShadow"
                  >
                    <RecoveryFilter
                      accounts={accounts}
                      warehouses={warehouses}
                      filterPlaceHolder={filterPlaceHolder}
                      defaultFilter={{ ...defaultFilter }}
                      setFilterPlaceHolder={updateFilterSearchValues}
                      onSearch={handleSearch}
                      onOrderNoSearch={null}
                      setSearchText={setSearchText}
                      resetFilter={clearFilter}
                      freightForwarders={freightForwarders}
                      showDateFilter={!isDispatchScreen}
                    />
                  </div>
                }
              >
                <Icon
                  type="control"
                  onClick={() => setShowFilterWindow(true)}
                />
              </Dropdown>
            </>
          }
          prefix={
            <Row type="flex" align="middle" gutter={4}>
              <Col>
                <Icon
                  type="search"
                  onClick={() => {
                    setSearchKeyPress(true);
                    setIsSearching(searchText.length ? true : false);
                    setUpdateSearchTags(true);
                  }}
                />
              </Col>
              {/* <Col>
                <Select
                  size="small"
                  onChange={(e) => {
                    //setSearchOrderToken('');
                    setSearchBy(e)
                  }}
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    lineHeight: '1',
                    width: '100%',
                  }}
                  defaultValue={searchBy}
                >
                  <Select.Option key="hawb" value="hawb">
                    {I18n.t('general.hawb')}
                  </Select.Option>
                  <Select.Option key="mawb" value="mawb">
                    {I18n.t('general.mawb')}
                  </Select.Option>
                  <Select.Option key="order_number" value="order_number">
                    {I18n.t('general.order')}
                  </Select.Option>
                </Select>
              </Col> */}
            </Row>
          }
        />
      </div>
    )
  }



  const [isFetchingFF, setIsFetchingFF] = useState(false)
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false)
  const [isfetchingDrivers, setIsFetchingDrivers] = useState(false)

  const getFreightForwarders = () => {
    setIsFetchingFF(true)
    fetchLocationsByName({ name: '', requestFrom: 'recovery' })
      .then((result) => {
        if (result.success) {
          if (
            result.predefined_stops?.predefined_stops &&
            result.predefined_stops.predefined_stops.length > 0
          ) {
            setFreightForwarders(result.predefined_stops.predefined_stops)
          } else {
            setFreightForwarders([])
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsFetchingFF(false)
      })
  }

  const getAccounts = (whId) => {
    const {warehouseFilter} = props;
    const accountsData =  warehouseFilter.findMatchingAccounts(whId)
     setAccounts(accountsData);
  }

  const getDrivers = () => {
    setIsFetchingDrivers(true)
    fetchUsers('driver', 1, 100000)
      .then((result) => {
        if (result.success) {
          const users = result.users || []
          setDrivers(users)
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsFetchingDrivers(false)
      })
  }

  const getRecoveries = () => {
    setIsLoading(true)
    const sortByType = filterPlaceHolder.sortByType || "ascend";
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1
    const perPage = recordsPerPage
    const payload = {}
    if (!isEmpty(searchText) && !searchButtonClicked) {
      payload['search_value'] = _.trim(searchText) || ""
      payload.status = currentStatus
    } else {
      if (!isEmpty(filterPlaceHolder.freight_forwarder)) {
        payload.ps_location_ids = filterPlaceHolder.freight_forwarder
      }
      if (!isEmpty(filterPlaceHolder.account_code)) {
        payload.account_code = filterPlaceHolder.account_code
      }
      payload.status = currentStatus
      payload[`${filterPlaceHolder.dateSearchBy}_from_date`] = !isEmpty(
        filterPlaceHolder.dateSearchValue,
      )
        ? moment(filterPlaceHolder.dateSearchValue.fromDate).format(
            'YYYY-MM-DD',
          )
        : null
      payload[`${filterPlaceHolder.dateSearchBy}_to_date`] = !isEmpty(
        filterPlaceHolder.dateSearchValue,
      )
        ? moment(filterPlaceHolder.dateSearchValue.toDate).format('YYYY-MM-DD')
        : null
      payload.sort_by = filterPlaceHolder.sortBy
      payload.sort_order = I18n.t(`general.sort_${sortByType}`);
      payload.zone_ids = filterPlaceHolder.zone_ids
        .map((rec) => rec.value)
        .join(',')
      if (filterPlaceHolder.dateSearchBy === 'recovery_eta') {
        payload[I18n.t('recoveries.schedule_times.type')] =
          filterPlaceHolder[I18n.t('recoveries.schedule_times.type')]
      }
    }
    RecoveriesApis.fetch(true, '', page, perPage, '', payload)
      .then((result) => {
        // computeTags(filterPlaceHolder)
        if (result.success) {
          setIsLoading(false)
          setRecoveries(result.recoveries)
          setPagination(result.pagination)
          // setIsInitial(false)
          setStatsData(!isEmpty(result.stats) ? result.stats : {})
        } else {
          // setIsInitial(false)
          setIsLoading(false)
          setStatsData({})
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setSearchKeyPress(false)
      })
  }

  const debouncedRecoveries = _.debounce(getRecoveries, 500)

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  }

  const handleOnFilterElementChange = (element, value) => {
    if (element === "sortBy") {
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || "ascend",
      });
      setFilterPlaceHolder(filter);
    } else {
      const filter = Object.assign({}, filterPlaceHolder, {
        [element]: value,
      });
      setFilterPlaceHolder(filter);
    }
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }
    if (tableOptions.pagination.current !== currentPage) {
      setSearchKeyPress(true);
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      handleOnFilterElementChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
    }
    // debouncedRecoveries()
  }

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedInfo({
      ...selectedInfo,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    })
  }

  const handleDelete = (id) => {
    setIsLoading(true)
    RecoveriesApis.delete(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'), 'success', 10)
          debouncedRecoveries()
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [currentOrderObject, setCurrentOrder] = useState({})

  const assignRoute = () => {
    if(route && route.id){
      setIsAssigning(true);
      const data = Object.assign({}, data, {
        nav_route_id: route.id,
        required_ids: selectedInfo.selectedKeys,
        stop_type: 'RC',
      });
      addAnyStopToRoute(data).then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.allocated_successfully'), 'success', 10);
            resetRecoveries()
          //   props.closeSearchOrders(
          //   true,
          //   route.id,
          //   props.actionType
          // );
          setIsAssigning(false)
        } else {
          renderAlertMessage(result.errors) 
          setIsAssigning(false)
        }
      });
    }
  }

  const handleRecoveryEdit = (data) => {
    const { customer_order_number } = data
    getOrderInfo(data.customer_order_id).then((orderInfo) => {
      setCurrentOrder(orderInfo);
      setShowEditForm(true);
    })

    // props.history.push({
    //   pathname: '/orders',
    //   search: '?activeTab=recoveries',
    //   filterPlaceHolder: {
    //     search_order_token: customer_order_number,
    //   },
    //   searchType: 'byOrders',
    //   orderObject: { ...data, id: data.customer_order_id },
    //   showQuoteOrder: true,
    // })
  }
  const clearFilter = () => {
    setSearchText('')
    setIsSearching(false)
    setSelectedAccount(null)
    computeTags(defaultFilter)
    setFilterPlaceHolder({ ...defaultFilter })
    setShowFilterWindow(false)
  }

  const clearSearch = () =>{
    setSearchText("");
    setIsSearching(false);
    clearFilter();
  }

  //  on filter change and made appropriate changes

  const navigateToRoute = (routeId, routeStatus, date) => {
    if (routeStatus !== "ALLOCATED") {
      this.props.history.push({
        pathname: "/routes",
        filter: routeStatus,
        routeId,
        dateFilter: {
          fromDate: date,
          toDate: date,
        },
      });
    }
  };

  const renderStatusChangeModal = () => (
    <BaseModal
      title={I18n.t('general.change_status')}
      onCancel={() => {
        setShowChangeStatusModal(false)
        debouncedRecoveries()
      }}
      visible={showChangeStatusModal}
      maskClosable={false}
      width={600}
    >
      <ChangeStatusForm
        currentStatus={currentStatus}
        saveStatus={() => {
          setShowChangeStatusModal(false)
          handleSelectChange([], [])
          debouncedRecoveries()
        }}
        closeModal={() => {
          setShowChangeStatusModal(false)
          debouncedRecoveries()
        }}
        recoveries={[...selectedInfo.selectedKeys]}
        processErrors={[...selectedInfo.selectedRows]}
        hasBulkOrders={true}
        isMilitaryTime={true}
        responseData={[...selectedInfo.selectedRows]}
        selectedWarehouse={selectedWarehouse}
      />
    </BaseModal>
  )

  const resetRecoveries = () => {
    handleSelectChange([], [])
    debouncedRecoveries()
  }

  const checkRouteData = () => {
    if (selectedInfo.selectedRows.length == 0) {
      setShowRecoveryForm(true)
    } else {
      const whCount = _.uniq(
        selectedInfo.selectedRows.map((rec) => rec.warehouse_code),
      ).length
      if (whCount > 1) {
        alertMessage(
          'Please select recoveries specific to only one warehouse to create the route',
          'error',
          5,
        )
      } else {
        const validatedOrdersLimits = checkIfOrdershasExceededCreditLimit(selectedInfo.selectedRows, props.creditsExceededAccnts);
        const orderErrors = validatedOrdersLimits.filter(order => order.account_limit_exceeded).map(order => `${order.customer_order_number}`);
        if (orderErrors.length > 0) {
          Modal.error({
            title: 'Orders Exceeded Credit Limit',
            content: (
              <div className="marginTop10">
                {/* <h4>{ I18n.t('order.lh_err_limit_orders') }</h4> */}
                <span className="textRed textBold">{ orderErrors.join(', ') }</span>
              </div>
            ),
            onOk () {
              if (orderErrors.length !== selectedInfo.selectedKeys.length) {
                setShowRecoveryForm(true);
              }
            },
          });
        }else{
          setShowRecoveryForm(true);
        }
      }
    }
  };

  // this is to recompute the selected info which removed the orders which exceeded the credit limit
  const recomputeSelectedInfo = (selectedInfo) => {
    const validatedOrdersLimits = checkIfOrdershasExceededCreditLimit(selectedInfo.selectedRows, props.creditsExceededAccnts);
    const orderErrors = validatedOrdersLimits.filter(order => order.account_limit_exceeded).map(order => `${order._id}`);
    if (orderErrors.length > 0) {
      const newSelectedKeys = selectedInfo.selectedKeys.filter(key => !orderErrors.includes(key));
      const newSelectedRows = selectedInfo.selectedRows.filter(row => !orderErrors.includes(row._id));
      return {
        selectedKeys: newSelectedKeys,
        selectedRows: newSelectedRows,
      };
    } else {
      return selectedInfo;
    }
  };

  const recomputeData = (data) => {
    const accountCodesWithExceededLimit = props.creditsExceededAccnts.map(account => account.account_code)
    return data?.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    }
    );
  }

  return (
    <div className="content-outer routes-classes">
      <div className="content">
      <Fragment>
        {!isDispatchScreen ? <Row className="page-header">
          <Col xs={12}>{I18n.t('menu.recovery')}</Col>
          <Col xs={12} className="alignRight">
            <Row
              justify="end"
              gutter={16}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Col className="alignLeft">{renderSearchBar()}</Col>
              <Col className="alignRight page-actions">
                {
                checkServiceExistance('ORRC') && 
                <Button
                  onClick={() => checkRouteData()}
                  type={'primary'}
                  icon="plus"
                  size={'default'}
                  className=""
                  disabled={
                    currentStatus === 'in_transit' &&
                    selectedInfo.selectedRows.length > 0
                  }
                >
                  {I18n.t('routes.create')}
                </Button>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        :
        
        <Row>
          <Col className="alignLeft" xs={18}>{renderSearchBar(true)}</Col>
          <Col xs={6} className="alignRight">
              <Button
                size="small"
                type="primary"
                onClick={assignRoute}
                loading={isAssigning}
                disabled={selectedInfo?.selectedKeys.length === 0}
              >
                {I18n.t("preplan.allocate")}
              </Button>
            </Col>
        </Row>
        }
          <Row className="page-content">
            <Col xs={24}>
              {!isDispatchScreen && checkServiceExistance('ORI') && (
                <RecoveryStats
                  filter={currentStatus}
                  handleOnFilterChange={(element, value) => {
                    setSelectedInfo({
                      selectedKeys: [],
                      selectedRows: [],
                    })
                    // setSearchText('')
                    setCurrentStatus(value)
                  }}
                  data={statsData}
                />
              )}
            </Col>
            {
              checkServiceExistance('ORI') &&
              <Col className="marginTop20" span={24}>
              <Row type='flex' justify='space-between'>
                <Col span={24}>
                <AppliedFiltersTags
                  tags={appliedFiltersTags}
                  clearSearch={() => clearSearch()}
                  clearFilter={() => clearFilter()}
                  // isSearching={!isEmpty(searchText) ? true : false}
                  isSearching={isSearching}
                  searchText={searchText}
                  searchBy={"Order"}
                  updateSearchTags={updateSearchTags}
                  resetSearchTags={() => setUpdateSearchTags(false)}
                />
                </Col>
              </Row>
              <Row type="flex" justify="end" gutter={props.actionType ? 16 : 8 }>
              <Col>
               <RecordsPerPage
               onChange={recordChange}
               onSearch={onSearch}
               value={recordsPerPage}
               defaultValue={recordsPerPage}
               />
              </Col>
              {props.actionType === "currentRoute" &&
               <Col>
                  {refreshIcon(debouncedRecoveries, true, false)}
                </Col>
                }
              </Row>
              <RecoveryList
                data={recomputeData(recoveries)}
                scroll = {{x:"max-content" ,y:"calc(100vh - 410px)"}}
                displayConfiguration={dispSetting}
                screen_from='recovery'
                isLoading={isLoading}
                isSorting={false}
                size="small"
                showActions={isDispatchScreen ? false : true}
                showStatus={true}
                handleEditClick={handleRecoveryEdit}
                navigateToAccount ={navigateToAccount}
                deleteClick={handleDelete}
                pagination={tablePagination}
                // rowClassName={ (record, index) =>
                //   record.account_limit_exceeded ? "errRow" : ""
                // }
                showActionExceedField={props.showActionExceedField === true}

                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, filter, sorter, currentTable)
                }
                navigateToRoute={navigateToRoute}
                handleStatusChange={handleStatusChange}
                currentStatus={currentStatus}
                rowSelection={
                  (currentStatus === '' || !checkServiceExistance(['ORDEL', 'ORCRT', 'ORCS'], 'ANY'))
                    ? null
                    : {
                        selectedRowKeys:
                          selectedInfo.selectedKeys.length > 0
                            ? selectedInfo.selectedKeys
                            : [],
                        onChange: handleSelectChange,
                        getCheckboxProps: (record) => ({
                          id: `${record.id}`,
                          disabled:
                            props.actionType === "currentRoute"  && record.account_limit_exceeded ? true :
                            (currentStatus === '' &&
                              record.status !== 'in_transit') ||
                            (currentStatus === '' &&
                              record.status !== 'in_transit'),
                        }),
                      }
                }
              />
              {!isDispatchScreen && currentStatus !== '' && selectedInfo.selectedKeys.length > 0 && (
                <RecoveryActions
                  filter={currentStatus}
                  selectedInfo={selectedInfo}
                  multipleDeletion={handleDelete}
                  handleClear={() => handleSelectChange([], [])}
                  refreshList={() => debouncedRecoveries()}
                  setIsLoading={(value) => setIsLoading(value)}
                  changeStatus={() => setShowChangeStatusModal(true)}
                  resetRecoveries={resetRecoveries}
                  setStausChangeModal={() => setShowChangeStatusModal(true)}
                  warehouseFilter={props.warehouseFilter}
                  organizationSettings={props.organizationSettings}
                />
              )}
              </Col>
            }
          </Row>
      </Fragment>
  
        {/* )} */}
        <BaseModal
          title={I18n.t('routes.create')}
          visible={showRecoveryForm}
          onCancel={() => setShowRecoveryForm(false)}
          width={selectedInfo.selectedKeys.length > 0 ? '50%' : '55%'}
          style={{
            top: 10,
          }}
          bodyStyle={{
            height: selectedInfo.selectedKeys.length > 0 ? 'auto' : 'auto',
          }}
          maskClosable={false}
        >
          <RecoveryPlanForm
            warehouses={warehouses}
            // selectedInfo={selectedInfo}
            selectedInfo={ recomputeSelectedInfo(selectedInfo) }
            drivers={drivers}
            filterPlaceHolder={filterPlaceHolder}
            onClose={(requireUpdate) => {
              setShowRecoveryForm(false)
              if (requireUpdate) {
                handleSelectChange([], [])
                debouncedRecoveries()
              }
            }}
            screenName={'recovery'}
            defaultWhId={props.warehouseFilter.selectedWarehouses}
            displayConfiguration={dispSetting}
            accounts={accounts}
          />
        </BaseModal>

        {showChangeStatusModal && renderStatusChangeModal()}  
        { showEditForm && (
          <Drawer
            placement="right"
            closable={ false }
            maskClosable={ false }
            onClose={ () => {
              setShowEditForm(false);
              debouncedRecoveries() 
            }}
            visible={ showEditForm }
            width="90%"
          >
            <EditOrderFormComponent
              callFrom="recoveries"
              customerDetails={ currentOrderObject }
              customerOrderId={ currentOrderObject.id }
              preferences={ [] }
              onCancel={ () => setShowEditForm(false) }
              handleSuccess={ () => {
                setShowEditForm(false);
                debouncedRecoveries()
               } }
              isNew={ false }
            />
          </Drawer>
        ) }
      </div>
    </div>
  )
}

export const RecoveryComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, accountsExceeded } = useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext)

  return (
    <Recovery
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      creditsExceededAccnts={ accountsExceeded }
      {...props}
    />
  );
});

export default RecoveryComponent;