import React from "react";
import _ from "lodash";
import { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";
import carrierTruck from "../../../../assets/images/carrierTruck.png";
import UnloadingTruck from "../../../../assets/images/UnloadingTruck.png";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";


function ListPreplan(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      hawb: "123456",
      mawb: "123456",
      username: "John Doe",
      account_code: "ABC123",
      account_name: "abc123",
      priority: "True",
      order_delivery_time: "2:00AM-9:00PM",
      zoneName: "New York",
      quantity: 1,
      weight: 100,
      pallets:1,
      customer_city: "New York",
      customer_zipcode: 1234,
      reference_1: "700214577",
      reference_2: "123456",
      los : "White Glove",
      status : "VERIFIED",
      street : "Gold",
      vehicle_type : "SPRINTER VAN",
      customer_address:"1234, New York, USA",
      company_name : "ABC123",
      routing : "ABCD",
      created_at: 32,
    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      hawb: "123456",
      mawb: "123456",
      username: "John Wick",
      account_code: "ABC234",
      account_name: "abc234",
      priority: "False",
      order_delivery_time: "12:00AM-5:00PM",
      zoneName: "New York",
      quantity: 2,
      weight: 200,
      pallets:2,
      customer_city: "Las Vegas",
      customer_zipcode: 5678,
      reference_1: "700214577",
      reference_2: "123456",
      los : "Threshold",
      status : "EXCEPTION",
      street : "Bridge",
      vehicle_type : "BOB TRAILER",
      customer_address:"5678, Las Vegas, USA",
      company_name : "ABC123",
      routing : "EFGH",
      created_at: 42,
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
      // onHeaderCell: () => {
      //   return {
      //     className: "gray-background",
      //     style: { cursor: "not-allowed" },
      //   };
      // },
      // onCell: () => {
      //   return {
      //     className: "gray-background",
      //     style: { cursor: "not-allowed" },
      //   };
      // },
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "username",
      title: <EndUserTitle/>,
      dataIndex: "username",
    },
    {
      key: "account_code",
      title: I18n.t("account.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "order_delivery_time",
      title: I18n.t("order.scheduled_short_form"),
      dataIndex: "order_delivery_time",
    },
    {
      key: "zones",
      title: `${I18n.t("zones.zone")}`,
      dataIndex: "zoneName",
    },
    {
      key: "items_count",
      title: `${I18n.t("order.qty")}`,
      dataIndex: "quantity",
    },
    {
      key: "weight",
      title: I18n.t("order.weight_short_form"),
      dataIndex: "weight",
    },
    {
      key: "pallets",
      title: I18n.t("order.pallets"),
      dataIndex: "pallets",
    },
    {
      key: "customer_city",
      title: I18n.t("general.city"),
      dataIndex: "customer_city",
    },
    {
      key: "customer_zip",
      title: I18n.t("general.zipcode_short_form"),
      dataIndex: "customer_zipcode",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "los",
      title: I18n.t('los.label'),
      dataIndex: "los",
    },
    {
      key: "priority",
      title: I18n.t('configurations.priority'),
      dataIndex: "priority",
    },
    {
      key: "status",
      title: I18n.t('general.status'),
      dataIndex: "status",
    },
    {
      key: "street",
      title: I18n.t('general.street'),
      dataIndex: "street",
    },
     {
      key: "vehicle_type",
      title: I18n.t("order.vehicle_type"),
      dataIndex: "vehicle_type",
    },
    {
      key: "vehicle_image",
      title: I18n.t("vehicleTypes.imgTitle"),
      render: (record) => {
        let imageResource;
        if (record.vehicle_type === "SPRINTER VAN") {
          imageResource = carrierTruck;
        } else if (record.vehicle_type === "BOB TRAILER") {
          imageResource = UnloadingTruck;
        }
        return (
          <img
            src={imageResource}
            alt="vehicle"
            style={{ width: 60, height: 60 }}
          />
        );
      },
    },
    {
      key: "cs_address",
      title: I18n.t("general.address"),
      dataIndex: "customer_address",        
    },
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
    {
      key: "created_at",
        title: I18n.t("order.age"),
        dataIndex: "created_at",
    }
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "unassigned_list_view_columns"

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )
}

export default ListPreplan;
