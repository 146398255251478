import { Button,Form, Input } from "antd";
import React from "react";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import { alertMessage } from "../../common/Common";
import { instructionType } from "../../api/InstructionsApi";

class InstructionTypeForm extends React.Component {
	constructor() {
		super();
		this.state = {
			roleName: "",
			roleCode: "",
			isLoading: false,
			isEdit: false,
		};
	}

	componentDidMount() {
		const { instructionTypesData, form, activeInstructionTypeId } = this.props;
		if (activeInstructionTypeId) {
			this.setState({ isLoading: true });
			const editableData = instructionTypesData.find(
				(instructionType) => instructionType.id === activeInstructionTypeId
			);
			form.setFieldsValue({
				instructionName: editableData.name,
				instructionCode: editableData.code,
			});
			this.setState({ isEdit: true, isLoading: false });
		} else {
			// reset form
			form.resetFields();
			this.setState({ isEdit: false, isLoading: false });
		}
	}

	handleSubmit = (e) => {
		const { form, addInstructionType, updateInstructionType } = this.props;
		const { isEdit } = this.state;
		e.preventDefault();
		this.setState({ isLoading: true });
		form.validateFields((err, values) => {
			values.instructionCode = values.instructionCode.toUpperCase();
			if (!err) {
				if (isEdit) {
					instructionType
						.update({ id: this.props.activeInstructionTypeId, ...values })
						.then((result) => {
							if (result.success) {
								alertMessage(
									I18n.t("instructions.modal.updateSuccess"),
									"success"
								);
								updateInstructionType(result.data);
							} else {
								result.errors.map((error) => {
									alertMessage(error, "error");
								});
							}
						})
						.finally(() => {
							this.setState({ isLoading: false });
						});
				} else {
					instructionType
						.create(values)
						.then((result) => {
							if (result.success) {
								addInstructionType(result.data);
								alertMessage(I18n.t("instructions.modal.success_msg"));
							} else {
								if (result.errors.length > 0) {
									result.errors.map((error) => {
										alertMessage(error, "error", 10);
									});
								}
							}
						})
						.finally(() => {
							this.setState({ isLoading: false });
						});
				}
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isEdit } = this.state;
		return (
			<Form
				// labelCol={{ span: 5 }}
				// wrapperCol={{ span: 24 }}
				onSubmit={this.handleSubmit}
			>
				<Form.Item label={I18n.t("instructions.modal.instruction_name")}>
					{getFieldDecorator("instructionName", {
						rules: [
							{
								required: true,
								message: "Please input valid instruction name!",
							},
						],
					})(<Input />)}
				</Form.Item>
				<Form.Item label={I18n.t("instructions.modal.instruction_code")}>
					{getFieldDecorator("instructionCode", {
						rules: [
							{
								required: true,
								message: "Please input valid instruction code!",
							},
						],
					})(
						<Input
							style={{
								textTransform: "uppercase",
							}}
							disabled={isEdit}
						/>
					)}
				</Form.Item>
				<Form.Item
					style={{
						textAlign: "center",
					}}
				>
					<Button style={{ marginRight: 8 }} onClick={this.props.onCancel}>
						{I18n.t("general.cancel")}
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						loading={this.state.isLoading}
					>
						{isEdit ? I18n.t("general.update") : I18n.t("general.add")}
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

const InstructionTypeFormWrapper = Form.create({
	name: I18n.t(`instructions.add_type`),
})(InstructionTypeForm);
export default InstructionTypeFormWrapper;

InstructionTypeForm.propTypes = {
	addInstructionType: PropTypes.func,
	updateType: PropTypes.func,
	editableData: PropTypes.object,
	onCancel: PropTypes.func,
	activeInstructionTypeId: PropTypes.string,
	instructionTypesData: PropTypes.array,
};
InstructionTypeForm.defaultProps = {
	addInstructionType: () => {},
	updateType: () => {},
	editableData: {},
	onCancel: () => {},
	activeInstructionTypeId: null,
	instructionTypesData: [],
};
