/* eslint-disable indent */
import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Col, Icon, Radio, Row, Select, DatePicker, Card } from '../common/UIComponents';
import I18n from '../common/I18n'; 
import Map from '../components/Tasks/Map';


const { Option } = Select;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;
const dateFormat = 'Do MMM YYYY';
function disabledDate(current) {
    return current && current > moment().endOf('day');
}

class Poc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routeData: [{
                id: "5e37e031d807466033c50eec",
                name: "WH1",
                location: {
                    l_address: {
                        coordinates: [-94.11324189999999, 36.1674836],
                        address_line1: "1602 E Robinson Ave suite E",
                        address_line2: null,
                        city: "Springdale"
                    }
                }
            },
            {
                id: "5e37e05bd807466033c50ef0",
                name: "CS1",
                location: {
                    l_address: {
                        coordinates: [-94.21346899999999, 36.364489],
                        address_line1: "xxxxxxxxxx Ave suite E",
                        address_line2: null,
                        city: "Springdale"
                    }
                }
            },
            {
                id: "5e37e031d807466033c50eee",
                name: "CS2",
                location: {
                    l_address: {
                        coordinates: [-94.127729, 36.1846658],
                        address_line1: "yyyy E Robinson Ave suite E",
                        address_line2: null,
                        city: "Springdale"
                    }
                }
            },
            {
                id: "5e37e031d807466033c50eec",
                name: "WH",
                location: {
                    l_address: {
                        coordinates: [-94.11324189999999, 36.1674836],
                        address_line1: "1602 E Robinson Ave suite E",
                        address_line2: null,
                        city: "Springdale"
                    }
                }
            }
            ], disabled: false,
        };


    }


    render() {

        const { status, routeData } = this.state;
        return (
            <div className='content-outer routes-classes'>
                <div className='content'>
                    <Row className='page-header'>
                        <Col xs={12}>
                            {I18n.t('menu.orders')}
                        </Col>
                        <Col xs={12} className="textRight">
                            <Icon type="filter" theme="twoTone" className="filterIcon" onClick={this.showFilterWindow} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={10} sm={24} lg={10}>
                            <Card title={"Route"}>
                            </Card>

                        </Col>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Card title={"Route Map"}>

                                        {
                                            (routeData.length > 0) && false &&
                                            <Map data={this.state.routeData} kms={this.state.disabled} mapElement={<div style={{ height: 250, width: 'auto' }} />} />
                                        }
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Card title={"Stops Info"}>

                                    </Card>

                                </Col>
                            </Row>

                        </Col>
                    </Row>


                    <div id="directions-panel" />

                </div>


            </div>
        );
    }
}

export default withRouter(Poc);