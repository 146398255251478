import React from "react";
import { Button, Popconfirm } from "../../common/UIComponents";
import userStore from "../../stores/UserStore";
import { alertMessage } from "../../common/Common";
import { changeStatusOfInvoicesToHold } from "../../api/Billing";
import I18n from "../../common/I18n";

const OnHoldInvoices = (props) => {
    const {
        label,
        handleSuccess,
        action,
        selectedInfo,
        is_pickup_by_driver,
    } = props;

    const handleSave = async () => {
        const selectedIds = selectedInfo?.selectedKeys
            ? _.compact(selectedInfo.selectedKeys)
            : [];
        try {
            const payload = {
                customer_order_ids: selectedIds,
                organization_id: userStore.getStateValue("selectedOrg"),
            };
            if (is_pickup_by_driver === "true") {
                payload.is_pickup_by_driver = "true";
                payload.pickup_order_driver_id = pickup_order_driver_id;
                payload.pickup_order_amount = pickup_order_amount;
            }
            let response = {};
            payload.status = action == 'ON_HOLD' ? 'HOLD' : action;
            response = await changeStatusOfInvoicesToHold(payload);
            if (response.success) {
                const result = response?.invoices ? response.invoices : {};
                const successMessage = result?.message
                    ? result.message
                    : I18n.t("messages.saved");
                //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
                alertMessage(successMessage);
                handleSuccess();
            } else {
                throw new Error(response.errors[0]);
            }
        } catch (error) {
            alertMessage(error.message, "error", 10);
        }
    };

    return (
        <Button className='buttonYellow' icon={action == "ON_HOLD" ? "pause-circle" : "file-done" } onClick={() => handleSave()}>{label}</Button>
    );
};

export default OnHoldInvoices;
