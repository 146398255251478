import React from 'react';
import PropTypes from "prop-types";
import { Droppable } from 'react-beautiful-dnd';
import RouteOrdersList from "../../components/preplan/rows/RouteOrders";
import { findDependencyOrders, rearrangeBySeq } from '../../helpers/preplan';
import SortingRouteOrders from '../preplan/rows/SortingRouteOrders';
import { Popconfirm, Tooltip, Spin } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import I18n from '../../common/I18n';

function RouteOrders (props) {
  const {
    setInProgress,
    inProgress,
    currentRoute,
    stopSelectedOrders,
    rearrangeForm,
    handleEditOrderClick,
    selectedStopMarkerId
    , setOpenedStopInfoWindow
    , setRouteOrdersInfo,
    getDependencyOrders,
    checkRouteOrders,setCurrentRoute,handleCurrentStop,currentStop, displayConfiguration,screen_from,vhType,isMilitaryTime,setIsItemVisible,routeLoading
  } = props;


  const selectedInfo =
    checkServiceExistance("NRROFR") && stopSelectedOrders
      ? stopSelectedOrders.selectedRows
          .filter((record) =>
            ["PENDING", "UNALLOCATED"].includes(record.status)
          )
          .map((record) => record.customer_order_id)
      : [];

  // handling select all of route orders List
  const handleRouteOrderSelectAllChange = (selected, selectedRows) => {
    if (selected) {
      const orders = rearrangeBySeq(selectedRows, "customer_order_id",getDependencyOrders);
      setRouteOrdersInfo(orders, "customer_order_id");
    } else {
      setRouteOrdersInfo([], "customer_order_id");
    }
  };

  const formCombinedKey = (record) => `${record.customer_order_id}-${record.stop_id}`;

  // Single route order onSelect
  const handleSingleRouteOrderChange = (record, selected, selectedRows) => {
    let orders = [ ...selectedRows ];
    const routeOrders =
      currentRoute && currentRoute.routeOrders
        ? currentRoute.routeOrders
        : [];
    const previousRows = [ ...stopSelectedOrders.selectedRows ];
    const dependencyOrders = findDependencyOrders(
      routeOrders,
      "related_order",
      "customer_order_id",
      record.customer_order_id,
      "object",
      "type_of_order"
    );
    const dependencyIds = dependencyOrders.map(
      (order) => order.customer_order_id
    );
    const orderIndex = _.findIndex(selectedRows, [
      "customer_order_id",
      record.customer_order_id,
    ]);
    if (selected === false) {
      orders = previousRows.filter(
        (order) => !dependencyIds.includes(order.customer_order_id)
      );
    } else if (selected && orderIndex >= 0) {
      const filteredItems = previousRows.filter(
        (order) => !dependencyIds.includes(order.customer_order_id)
      );
      if (dependencyOrders.length > 0) {
        const selectedItems = routeOrders.filter(
          (order) => dependencyIds.includes(order.customer_order_id)
        );
        //orders = [].concat(filteredItems, dependencyOrders);
        orders = [].concat(filteredItems, selectedItems);
      } else {
        orders = [ ...filteredItems ];
      }
    }
    setRouteOrdersInfo(orders, "customer_order_id");
  };
  const stopOrders = currentRoute.routeOrders || [];
  //const selectedRowKeys = stopSelectedOrders.selectedKeys;
  const selectedRowKeys = stopSelectedOrders.selectedRows.map(record => `${record.customer_order_id}-${record.stop_id}`);
  const stopOrdersSelection = !currentRoute.isLocked
    ? {
      selectedRowKeys,
      onSelect: handleSingleRouteOrderChange,
      onSelectAll: handleRouteOrderSelectAllChange,
      columnWidth: 20,
      getCheckboxProps: null,
    }
    : null;

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? '#e8e8e8' : 'white',
    // padding: grid,
    height: isDraggingOver ? 75 : "auto",
    borderStyle: isDraggingOver ? "dashed" : "",
  });

  const isLinehaulRoute = currentRoute.route_type === 'line_haul'
  let yHeight = "70vh";
  if (props.hasRouteProcessedErrors) {
    if (props.doCapacityValidation) {
      yHeight = "50vh";
    } else {
      yHeight = "55vh";
    }
  }
  const scrollObj = {
    x: 1140,
    y: yHeight
  };

  const getRoutesListClass = (record) => {
    return (
      record.stop_id === selectedStopMarkerId
        ? 'blockBgOverColor activeRow'
        : record.status === 'UNALLOCATED' ||
          (record.stop_errors &&
            _.isArray(record.stop_errors) &&
            record.stop_errors.length > 0)
          ? 'blockBgErrorColor'
          : 'table-row-light'
    );
  };

  const handleRowActions = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setOpenedStopInfoWindow(record.stop_id);
      },
      onMouseLeave: (event) => {
        setOpenedStopInfoWindow("");
      },
    };
  };

  return (
    <div className="routeOrders fontSize11">
      { !rearrangeForm ? (
        <Droppable
          droppableId={ `routeOrderDroppable${currentRoute.route_id}` }
          // isDropDisabled={isLinehaulRoute}
        >
          { (provided, snapshot) => (
            <div
              { ...provided.droppableProps }
              ref={ provided.innerRef }
              style={ getListStyle(snapshot.isDraggingOver) }
            >
              <Spin spinning={inProgress || routeLoading}>
                  <RouteOrdersList
                    // rowKey="customer_order_id"
                    rowKey={formCombinedKey}
                    key={`routelist${currentRoute.route_id}`}
                    data={stopOrders}
                    size="small"
                    route={currentRoute}
                    showMoveAction={!currentRoute.isLocked && !isLinehaulRoute }
                    showActions={!currentRoute.isLocked}
                    stopType="CS"
                    deleteOrderClick={(id) =>
                      checkRouteOrders(id, currentRoute.route_id)
                    }
                    orderDetailsViewClick={handleEditOrderClick}
                    rowSelection={stopOrdersSelection}
                    selectedList={selectedRowKeys}
                    selectedInfo={stopSelectedOrders}
                    pagination={{ position: "none" }}
                    currentRoute={currentRoute}
                    scroll={scrollObj}
                    displayConfiguration={displayConfiguration}
                    screen_from={screen_from}
                    rowClassName={getRoutesListClass}
                    onRow={handleRowActions}
                    vhType={vhType}
                    isMilitaryTime={isMilitaryTime}
                    setIsItemVisible={setIsItemVisible}
                    renderExtraHeader={() => {
                      return (
                        selectedInfo.length > 0 && (
                          <div className="anchor_cursor alignRight" style={ { margin: 4 } }>
                            <Popconfirm
                              placement="topRight"
                              title={ I18n.t("messages.delete_selected_order") }
                              onConfirm={ () =>
                                checkRouteOrders(selectedInfo, currentRoute.route_id)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title={ I18n.t('general.delete_selected_orders_from_route') } placement="bottomRight" overlayStyle={ { maxWidth: 350 } }>
                                <span
                                  style={ {
                                    color: "red",
                                    fontSize: 12,
                                    fontStyle: "italic",
                                    fontWeight: 500,
                                  } }
                                >
                                  { I18n.t('routes.remove_orders') }
                                </span>
                              </Tooltip>
                            </Popconfirm>
                          </div>
                        )
                      );
                    }
                    }
                  />
              </Spin> 
            </div>
          ) }
        </Droppable>
      ) : (
          <SortingRouteOrders
            data={ stopOrders }
            size="small"
            route={ currentRoute }
            showMoveAction={ false }
            showActions={ false }
            stopType="CS"
            deleteOrderClick={ (id) =>
              checkRouteOrders(id, currentRoute.route_id)
            }
            scrollObj={ {
              ...scrollObj,
              x : 750,
            }}
            orderDetailsViewClick={ handleEditOrderClick }
            selectedList={ [] }
            pagination={ { position: "none" } }
            currentRoute={ currentRoute }
            setCurrentRoute={ setCurrentRoute }
            handleCurrentStop={ handleCurrentStop }
            currentStop={currentStop}
            selectedInfo={ stopSelectedOrders }
            displayConfiguration={displayConfiguration}
            screen_from={screen_from}
            vhType={vhType}
            isMilitaryTime={isMilitaryTime}
            
          />
      ) }
    </div>
  );

}

export default RouteOrders;

RouteOrders.propTypes = {
  currentRoute: PropTypes.object,
  stopSelectedOrders: PropTypes.object,
  rearrangeForm: PropTypes.bool,
  handleEditOrderClick: PropTypes.func,
  selectedStopMarkerId: PropTypes.string,
  setOpenedStopInfoWindow: PropTypes.func,
  setRouteOrdersInfo: PropTypes.func,
  getDependencyOrders: PropTypes.func,
  checkRouteOrders: PropTypes.func,
  setCurrentRoute: PropTypes.func,
  handleCurrentStop: PropTypes.func,
  currentStop: PropTypes.object,
};

RouteOrders.defaultProps = {
  currentRoute: {},
  stopSelectedOrders: {},
  rearrangeForm: false,
  handleEditOrderClick: () => {},
  selectedStopMarkerId: "",
  setOpenedStopInfoWindow: () => {},
  setRouteOrdersInfo: () => {},
  getDependencyOrders: () => {},
  checkRouteOrders: () => {},
  setCurrentRoute: () => {},
  handleCurrentStop: () => {},
  currentStop: {},
};