import React, { Fragment, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import {
  Row,
  Col,
  Input,
  Button,
  Tooltip,
  Spin,
  Tabs,
  Icon,
  Typography,
} from '../../common/UIComponents'
import {
  checkNegitive,
  doFormate,
  ellipseText,
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
// import { _ } from 'core-js'
import AddressInfo from '../common/AddressInfo'
import Profile from '../common/Profile'
import imagePlaceholder from '../../../assets/images/logo_placeholder.png'
import moment from 'moment'
import AppConfig from '../../config/AppConfig'
import QuoteOrderItemsList from './QuoteOrderItemsList'
import ViewQuoteAccessorial from './ViewQuoteAccessorial'
import { formatWeight } from '../../helpers/orders'
import { fetchOrganizationDetails } from '../../api/Organisations'
import QuotesRevisions from './QuotesRevisions'
import { formatFullName } from '../../helpers/common'
import { setAppointmentDetails } from '../../helpers/quotes'
import EmailQuote from './EmailQuote'
import DownloadQuote from './DownloadQuote'
import ApproveQuote from './ApproveQuote'
import DisplayTime from '../common/DisplayTime'
import CustomerOrderDetails from '../orders/CustomerOrderDetails'
import QuoteSummary from './QuotesSummary'
import { OrgContext } from '../../context/OrgContext';
import QuotesActivities from "./QuotesActivities"

const { TabPane } = Tabs

const QuoteDetails = (props) => {
  const {
    currentQuote,
    summaryDetails,
    onChange,
    storeUpdatedAmount,
    revisions,
    closeModal,
    editQuote,
    cloneQuote,
    validateQuote,
    onAccessorialAmountChange,
    emptyNewFieldVals,
    updateAddOns,
    setUpdateAddOns,
    enableDelete,
    apiCallMade,
    setEnableDelete,
    setApiCallMade,
    quote_activities,
    currentViewQuote,
    pickupLocationId,
    deliveryLocationId
  } = props
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [orgDetails, setOrgDetails] = useState(null)
  const [activeKey, setActiveKey] = useState('details')
  const {
    quote,
    pickup_location_details,
    delivery_location_details,
    order_items,
    wh_location_details,
    appointment,
    orders,
    account_accessorials = [],
    // quote_activities = []
  } = currentQuote
  const pickupAddress = pickup_location_details?.l_address
    ? pickup_location_details.l_address
    : {}
  const deliveryAddress = delivery_location_details?.l_address
    ? delivery_location_details.l_address
    : {}
  const [isMilitaryTime , SetIsMilitary] = useState(organizationSettings.is_military_time === 'true')

  useEffect(() => {
    SetIsMilitary(organizationSettings.is_military_time === 'true')
  },[organizationSettings])

  const ORDERS = !isEmpty(orders) && _.isArray(orders) ? orders : [];
  const order_no = ORDERS.map((order) => order.order_number);
  const orderObj = ORDERS.length > 0 ? ORDERS[0] : null;
  const getOrgLogo = () => {
    const orgId = userStore.getStateValue('selectedOrg')
    fetchOrganizationDetails(orgId).then((result) => {
      if (result.success) {
        const organization = result.organization || {}
        organization.logo =
          !isEmpty(organization.logo) && organization.logo.url
            ? organization.logo.url
            : ''
        setOrgDetails(organization)
        if (!organization.logo && !isEmpty(orgId)) {
          const urlToFile = `${AppConfig.baseUrl}/org_logos/${orgId}/org_logo.png`
          fetch(urlToFile).then((response) => {
            if (response.status === 200) {
              organization.logo = urlToFile
              setOrgDetails(organization)
            }
          })
        }
      }
    })
  }

  useEffect(() => {
    getOrgLogo()
  }, [])

  const renderPickupDropTime = (type,data) => {
    return (
      <>
        { !_.isEmpty(data)  ? <span className="textBold">{ type } Time : </span> : null}
        {
          !_.isEmpty(data) && data.item_option ? (
            <span className="textBold">
              {/* {moment.utc(quote.expiry_date).format(AppConfig.dateFormat)} */ }
              { data.item_option.includes('ANY') ? (
                <Fragment>
                  { moment
                    .utc(data.item_preference)
                    .format(AppConfig.dateFormat) }{ ' ' }
                  { isMilitaryTime
                    ? I18n.t('appointmentForm.military_any_time')
                    : I18n.t('appointmentForm.any_time_with_zero') }
                  {/* {moment.utc(data.item_preference).format('08:00 AM')} */ }
                </Fragment>
              ) : (
                <Fragment>
                  <DisplayTime
                    isUTC={ true }
                    displayWithDate={ true }
                    dateTimeString={ data.start_time }
                    key="drop_start_time"
                  />
                  -
                  <DisplayTime
                    isUTC={ true }
                    displayWithDate={ false }
                    dateTimeString={ data.end_time }
                    key="drop_end_time"
                  />
                </Fragment>
              ) }
            </span>
          ) : (
            null
          )
        }
      </>
    );
  }

  const renderQuoteDetails = (order) => {
    const whName = wh_location_details?.name
      ? doFormate(wh_location_details.name)
      : ''
      const los =
      _.get(quote, 'type_of_order') === "T"
        ? `${doFormate(_.get(quote, 'los_name'))} - ${doFormate(
            _.get(quote, 'delivery_los_name')
          )}`
        : `${doFormate(_.get(quote, 'los_name'))}`;
    return (
      <div className="fontSize12 quoteDetails">
        <table>
          <tbody>
          <tr>
            <td>{I18n.t('general.warehouse')}</td>
            <td>:</td>
            <td>{whName}</td>
          </tr>
          <tr>
            <td>{I18n.t('general.account')}</td>
            <td>:</td>
            <td>
              <Tooltip placement="topLeft" title={quote.account_name}>
              {ellipseText(quote.account_name, 15)}
              </Tooltip>
              </td>
          </tr>
          <tr>
            <td>{I18n.t('los.label')}</td>
            <td>:</td>
            <td>{los} </td>
          </tr>
          <tr>
            <td>{I18n.t('general.weight')}</td>
            <td>:</td>
            <td>{formatWeight(quote.weight)}</td>
          </tr>
          {!isEmpty(quote.mawb) && (<tr>
            <td>{I18n.t('general.mawb')}</td>
            <td>:</td>
            <td>{doFormate(quote.mawb)}</td>
          </tr>
          )}
          {!isEmpty(quote.hawb) && (<tr>
            <td>{I18n.t('general.hawb')}</td>
            <td>:</td>
            <td>{doFormate(quote.hawb)}</td>
          </tr>
          )}
         </tbody>
        </table>
      </div>
    )
  }
  const targetDetails = () => {
    return (
      <div className="fontSize12 transferDetails">
        {/* <h3 className="label_heading">{I18n.t('quotes.transfer_details')}</h3> */}
        <table>
          <tbody>
            <tr>
              <td>{I18n.t("quotes.linehaul")}</td>
              <td>:</td>
              <td>
                {_.get(currentQuote, "quote.is_line_haul") ? (
                  <span>
                    <Icon
                      type="check-circle"
                      className="cursorPointer textGreen"
                    />
                    &nbsp;
                    <>Selected </>
                  </span>
                ) : (
                  <span>
                    <Icon
                      type="close-circle"
                      className="cursorPointer textRed"
                    />
                    &nbsp;
                    <>Not Opted </>
                  </span>
                )}
              </td>
            </tr>
            {_.get(currentQuote, "quote.type_of_order") === "T" && (
              <tr>
                <td>{I18n.t("quotes.warehouse_intermediate_storage")}</td>
                <td>:</td>
                <td>
                  {_.get(currentQuote, "quote.wh_storage") ? (
                    <span>
                      <Icon
                        type="check-circle"
                        className="cursorPointer textGreen"
                      />
                      <> Required </>
                    </span>
                  ) : (
                    <span>
                      <Icon
                        type="close-circle"
                        className="cursorPointer textRed"
                      />
                      &nbsp;
                      <>Not Required </>
                    </span>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td>{I18n.t("quotes.target_warehouse")}</td>
              <td>:</td>
              <td>
                {currentQuote?.quote?.target_warehouse_code
                  ? currentQuote?.quote?.target_warehouse_name
                  : "NA"}
              </td>
            </tr>
            <tr>
              <td>{I18n.t("quotes.target_account")}</td>
              <td>:</td>
              <td>
                {currentQuote?.quote?.target_account_code
                  ? currentQuote?.quote?.target_account_name
                  : "NA"}
              </td>
            </tr>
            <tr>
              <td>{I18n.t("quotes.target_los")}</td>
              <td>:</td>
              <td>
                {currentQuote?.quote?.target_level_of_service
                  ? currentQuote?.quote?.target_los_name
                  : "NA"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const renderAccessorial = () => (
    <>
    { currentViewQuote.type_of_order !== "D" && (
      <div>
      <ViewQuoteAccessorial
        org_id={userStore.getStateValue('selectedOrg')}
        customer_order_id={quote.id} // "62d94243772c9619063e4244"
        account_id={ quote.account_id }
        quote_id={currentQuote.id}
        currentQuote = {currentViewQuote}
        location_id = {currentViewQuote?.pickup_location_details?.id || pickupLocationId}
        pickupLocationId={pickupLocationId}
      />
      </div>
      )}
      { currentViewQuote.type_of_order !== "R" && (
      <div>
      <ViewQuoteAccessorial 
        org_id={userStore.getStateValue('selectedOrg')}
        customer_order_id={quote.id} // "62d94243772c9619063e4244"
        account_id={ quote.account_id }
        quote_id={currentQuote.id}
        currentQuote = {currentViewQuote}
        location_id = {currentViewQuote?.delivery_location_details?.id || deliveryLocationId}
        deliveryLocationId = {deliveryLocationId}
      />
    </div>
      )}
    </>
  )

  const renderItems = () =>
    order_items.length > 0 && (
      <div className="marginTop20 fontSize12 itemDetails">
        <h3 className="label_heading">{I18n.t('quotes.items')}</h3>
        <hr />

        <QuoteOrderItemsList
          data={order_items ? order_items : []}
          showInitialItem={false}
          resetShowInitialItem={null}
          quoteId={quote.id}
          showActions={false}
          style={{ fontSize: 12 }}
          handleSave={() => {}}
          size="small"
          viewOnly={true}
        />
      </div>
    )

  const renderRevisions = () =>
    _.isArray(revisions) && (
      <div className="marginTop20 fontSize12 itemDetails">
        <QuotesRevisions
          revisions={revisions}
          size="small"
          style={{ fontSize: 12 }}
          account_accessorials={account_accessorials}
          updateAddOns={updateAddOns}
          mode="view"
          orderType={quote?.type_of_order}
        />
      </div>
    )
  const renderActivities = () =>

    _.isArray(quote_activities) && (
      <div className="marginTop20 fontSize12 itemDetails">
        <QuotesActivities
          activities={quote_activities}
          size="small"
          style={{ fontSize: 12 }}
          account_accessorials={account_accessorials}
          updateAddOns={updateAddOns}
          mode="view"
          orderType={quote?.type_of_order}
          warehouseDetails={wh_location_details}
        />
      </div>
    )

    
    
  const renderField = (fieldName, type, mode) =>
    mode === 'edit' ? (
      <Row className={fieldName === 'quote_amount' ? 'fontSize14' : ''}>
        <Col xs={2}>$</Col>
        <Col xs={22}>
          <Input
            value={summaryDetails[fieldName]}
            onChange={(e) => onChange(fieldName, e.target.value)}
            disabled={fieldName === 'quote_amount'}
          />
        </Col>
      </Row>
    ) : (
      checkNegitive(summaryDetails[fieldName])
    )
  const renderAccossorialamount = (fieldName, type, mode) => {
    const ind_accessorial_charges = summaryDetails['ind_accessorial_charges']
    return mode === 'edit' ? (
      <Row>
        <Col xs={2}>$</Col>
        <Col xs={22}>
          <Input
            value={ind_accessorial_charges[fieldName]}
            onChange={(e) =>
              onAccessorialAmountChange(fieldName, e.target.value)
            }
          />
        </Col>
      </Row>
    ) : (
      checkNegitive(ind_accessorial_charges[fieldName])
    )
  }
  const renderSummary = (type = 'quote', mode = 'edit') => (
    <div className="amountUpdate">

       <QuoteSummary
          summaryDetails={summaryDetails}
          onChange={onChange}
          onAccessorialAmountChange={onAccessorialAmountChange}
          mode="edit"
          type="quote"
          account_accessorials={account_accessorials}
          orderType={quote?.type_of_order}
          currentQuote={currentQuote}
          emptyNewFieldVals={emptyNewFieldVals}
          storeUpdatedAmount={storeUpdatedAmount}
          updateAddOns={updateAddOns}
          setUpdateAddOns={setUpdateAddOns}
          enableDelete={enableDelete}
          setEnableDelete={setEnableDelete}
          apiCallMade={apiCallMade}
          setApiCallMade={setApiCallMade}
        />
      {/* <table>
        <tr>
          <td className="textBold">
            {I18n.t('invoices.invoice_types.transportation_charges')} Charges
          </td>
          <td>:</td>
          <td className="alignRight" width="120px">
            {renderField('transportation_charge', type, mode)}
          </td>
        </tr>
        <tr>
          <td className="textBold">
            {I18n.t('invoice_lines.fuel_surcharges')}
          </td>
          <td>:</td>
          <td className="alignRight" width="120px">
            {renderField('fuel_surcharge', type, mode)}
          </td>
        </tr>
        {Object.keys(summaryDetails.ind_accessorial_charges).map((rec) => {
          const accRec = _.find(account_accessorials, { code: rec});
          return (
          <tr key={rec}>
            <td className="textBold">{accRec?.name || rec}</td>
            <td>:</td>
            <td className="alignRight" width="120px">
              {' '}
              {renderAccossorialamount(rec, type, mode)}
            </td>
          </tr>
        )
        })}

        <tr>
          <td className="textBold">{I18n.t('invoices.accessorial_charges')}</td>
          <td>:</td>
          <td className="alignRight" width="120px">
            {' '}
            {renderField('accessorial_charges', type, mode)}
          </td>
        </tr>
        <tr>
          <td className="textBold">
            {I18n.t('invoice_lines.additional_charge')}
          </td>
          <td>:</td>
          <td className="alignRight" width="120px">
            {renderField('additional_charges', type, mode)}
          </td>
        </tr>
        <tr>
          <td className="textBold alignRight paddingLeft5 paddingTop10 fontSize16">
            {I18n.t('invoices.total_amount')}
          </td>
          <td>:</td>
          <td className="alignRight paddingTop10" width="120px">
            {renderField('quote_amount', type, mode)}
          </td>
        </tr>
        <td></td>
      </table> */}
    </div>
  )

  const goToTab = (key) => {
    setActiveKey(key)
  }

  const renderDetails = () => {
    const pickTime = setAppointmentDetails(
      appointment,
      'R',
      pickup_location_details.time_zone_id,
    )
    const dropTime = setAppointmentDetails(
      appointment,
      'D',
      delivery_location_details.time_zone_id,
    )
    return (
      <div className="quote-box">
        <table width="100%">
         <tbody>
          <tr>
            <td width="65%">
              {orgDetails && orgDetails.logo && (
                <Fragment>
                  <img src={orgDetails.logo} style={{ height: 50 }} />
                  <h4 className="textBold">
                    <AddressInfo address={orgDetails.address} />
                  </h4>
                </Fragment>
              )}
            </td>
            <td className="alignRight textBold textGrey">
              <h3 style={{ fontSize: 26, color: '#2A3F54' }}>
                QUOTATION
                <br />
              </h3>
              <h3
                style={{
                  fontSize: 22,
                  color: '#2A3F54',
                  letterSpacing: '0.1rem',
                }}
              >
                #{quote.quote_order_number}
              </h3>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr />{' '}
            </td>
          </tr>
          </tbody>
        </table>
        <table width="100%" className="marginTop20">
          <tbody>
            {/* <tr>
                <td width="50%" className="fontSize15 textBold">
                  <span className="textBold">Quotation No</span>:{' '}
                  <span className="textBold">{quote.quote_order_number}</span>
                </td>
              </tr> */}
            <tr>
              <td width="50%" className="fontSize15 textBold">
                <span className="textBold">Quotation Date</span>:{' '}
                <span className="textBold">
                  {moment.utc(quote.quote_date).format(AppConfig.dateFormat)}
                </span>
              </td>
              <td align="right" width="50%" className="fontSize15 textBold">
                { !(quote.type_of_order === 'D') && (renderPickupDropTime("Pickup", pickTime)) }
              </td>
              {/* <td><span>Quotation Date</span>: <span>{moment.utc(quote.quote_date)}</span></td>
          <td><span>Expiry Date</span>: <span>{moment.utc(quote.quote_date)}</span></td> */}
            </tr>
            <tr>
              <td width="50%" className="fontSize15 textBold">
                <span className="textBold">Expiry Date</span>:{' '}
                <span className="textBold">
                  {moment.utc(quote.expiry_date).format(AppConfig.dateFormat)}
                </span>
              </td>
              <td align="right" width="50%" className="fontSize15 textBold">
                { !(quote.type_of_order === 'R') && (renderPickupDropTime("Drop", dropTime)) }
              </td>
              {/* <td><span>Quotation Date</span>: <span>{moment.utc(quote.quote_date)}</span></td>
          <td><span>Expiry Date</span>: <span>{moment.utc(quote.quote_date)}</span></td> */}
            </tr>
          </tbody>
        </table>
        <table width="100%" className="marginTop20" cellSpacing={10}>
          <thead>
            <tr>
              <th style={{ width: '33.33' }} className="label_heading">
                Quote Details
                <hr style={{ width: 90 }} />
              </th>
              <th style={{ width: '33.33' }} className="label_heading">
                Pickup Location
                <hr style={{ width: 110 }} />
              </th>
              <th style={{ width: '33.33' }} className="label_heading">
                Delivery Location
                <hr style={{ width: 110 }} />
              </th>
              {/* <th style={{width :'25'}} className="label_heading">
                {I18n.t('quotes.transfer_details')}
                <hr style={{ width: 100 }} />
              </th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{renderQuoteDetails()}</td>
              <td className="fontSize12" style={{ verticalAlign: 'top' }}>
                <Profile
                  address={pickupAddress}
                  profile={(({
                    name,
                    phone_number_one,
                    phone_number_two,
                    email,
                    company_name,
                    first_name,
                  }) => ({
                    image: imagePlaceholder,
                    full_name: isEmpty(first_name) ? name : formatFullName(pickup_location_details),
                    mobile_number: `${phone_number_one} ${
                      !isEmpty(phone_number_two) ? `,${phone_number_two}` : ''
                    }`,
                    email: doFormate(email),
                    company_name: company_name,
                  }))(pickup_location_details)}
                  displayType="line"
                  showImage={false}
                  withLables={false}
                  inBilling={true}
                  showAddress={false}
                />
                <div className="paddingLeft5">
                  <AddressInfo address={pickupAddress} showIcon={true} />
                </div>
              </td>
              <td className="fontSize12" style={{ verticalAlign: 'top' }}>
                <Profile
                  address={deliveryAddress}
                  profile={(({
                    name,
                    phone_number_one,
                    phone_number_two,
                    email,
                    company_name,
                    first_name,
                  }) => ({
                    image: imagePlaceholder,
                    full_name: isEmpty(first_name) ? name : formatFullName(delivery_location_details),
                    mobile_number: `${phone_number_one} ${
                      !isEmpty(phone_number_two) ? `,${phone_number_two}` : ''
                    }`,
                    email: doFormate(email),
                    company_name: company_name,
                  }))(delivery_location_details)}
                  displayType="line"
                  showImage={false}
                  withLables={false}
                  inBilling={true}
                  showAddress={false}
                />
                <div className="paddingLeft5">
                  <AddressInfo address={deliveryAddress} showIcon={true} />
                </div>
              </td>
              {/* <td>{targetDetails()}</td> */}
            </tr>
          </tbody>
        </table>
        <table width="100%" className="marginTop20">
          <thead>
          <th style={{width :'100'}} className="label_heading">
                {I18n.t('quotes.transfer_details')}
                <hr style={{ width: 100 }} />
              </th>
          </thead>
        <tbody>
          <tr>
          <td>{targetDetails()}</td>
          </tr>
        </tbody>
        </table>

        
        {renderAccessorial()}
        {renderItems()}
        <div>
          <table width="100%" className="marginTop20">
            <tbody>
              <tr>
                <td width="50%" style={{ verticalAlign: 'top' }}>
                <div>
                  <h3 className="label_heading textBold">Notes: </h3>
                  <hr style={{ width: 40 }} />
                    <div>{quote.notes}</div>
                  <h3 className="textBold" style={{fontSize: 13, marginTop: 20}}>{I18n.t("menu.disclaimer")}: </h3>
                  <hr style={{ width: 65 }} />
                    <div style={{ whiteSpace: 'pre-line'}}>{quote.quote_disclaimer}</div>
                </div>
                </td>
                <td width="50%" align="right" className="">
                <div>
                  {renderSummary()}
                </div>    
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="alignCenter marginTop20">
          <Button type="danger" icon="close" onClick={closeModal}>
            {I18n.t('general.close')}
          </Button>{' '}
          <Button type="primary" icon={'save'} onClick={storeUpdatedAmount}>
            {revisions.length === 0
              ? I18n.t('general.save')
              : I18n.t('general.update')}{' '}
            {I18n.t('quotes.label')}
          </Button>
        </div>
      </div>
    )
  }

  const renderActions = () => {
    

    return (
      <div className="alignCenter buttonsList" style={{ padding: 0 }}> 
        
          <Button
            type="primary"
            onClick={() => cloneQuote(quote.id)}
            style={{ marginTop: '2px', marginLeft: 10, marginRight: 10 }}
            className="buttonTeal"
            icon="copy"
            size={'small'}
          >
            {I18n.t('order.clone')}
          </Button>
     
        {quote.status === "PENDING" && (
          <Button
            type="primary"
            icon="edit"
            size="small"
            onClick={() => {
              closeModal();
              editQuote(quote.id);
            }}
          >
            {" "}
            Edit{" "}
          </Button>
        )}
        {revisions.length > 0 && (
          <Fragment>
            <DownloadQuote
              key={`dwnlkd${quote.id}`}
              quote_id={quote.id}
              quote_no={quote.quote_order_number}
              className="textBold"
              displayType="button"
              size="small"
            />
            <EmailQuote
              key={`email${quote.id}`}
              quote_id={quote.id}
              quote_no={quote.quote_order_number}
              className="textBold"
              displayType="button"
              size="small"
            />
            {quote.status === "PENDING" && revisions.length > 0 && (
              <ApproveQuote
                key={`qpprove${quote.id}`}
                quote_id={quote.id}
                quote_no={quote.quote_order_number}
                className="buttonMitesse"
                displayType="button"
                validateQuote={validateQuote}
                size="small"
              />
            )}
            {quote.status === "APPROVED" &&
              revisions.length > 0 &&
              orders.length > 0 && (
                <CustomerOrderDetails
                  order_no={"View Order"}
                  key={orders[0].order_id}
                  order={{
                    id: orders[0].order_id,
                    customer_order_number: orders[0].order_number,
                  }}
                  showEdit={false}
                  data={
                    <Tooltip
                      title={
                        <div>
                          {I18n.t("order.click_to_view_order")}
                        </div>
                      }
                    >
                      <Button
                        className="buttonMitesse"
                        size="small"
                        icon="shopping-cart"
                      >
                        {I18n.t("menu.order")}: {order_no.join(", ")}
                      </Button>
                    </Tooltip>
                  }
                />
              )}
          </Fragment>
        )}
      </div>
    )
  }
  return (
    <Fragment>
      <Spin spinning={isLoading} delay={1000}>
        <Tabs
          activeKey={activeKey}
          onChange={goToTab}
          type="card"
          tabBarExtraContent={<Fragment>{renderActions()}</Fragment>}
        >
          <TabPane tab={I18n.t('general.details')} key="details">
            {renderDetails()}
          </TabPane>
          <TabPane tab={I18n.t('quotes.revisions')} key="revisions">
            {renderRevisions()}
          </TabPane>
          <TabPane tab={I18n.t('order.activities')} key="activities">
            {renderActivities()}
          </TabPane>
        </Tabs>
      </Spin>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

QuoteDetails.propTypes = {
  currentQuote: PropTypes.object.isRequired,
}

export default QuoteDetails
