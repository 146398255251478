import React, { Fragment } from "react";
import moment from "moment";
import _ from "lodash";
import { List, Table } from 'antd';
import { Row, Col } from "antd";
import BaseList from "../BaseList";
import { checkNegitive,compareNumber } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Popconfirm,
  Popover,
  Input,
  Typography,
  Text,
  Tooltip,
  Link,
} from '../../common/UIComponents'
import ShowInvoice from './ShowInvoice'
import ShowSummary from './ShowSummary'
import { checkServiceExistance, renderAddress } from '../../helpers/common'
import { isEmpty } from '../../common/Common'
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import Copyable from "../common/Copyable"
import UnlockInvoice from './UnlockInvoice'
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import { findIfInvoicePartiallyPaid } from "../../helpers/billing";
import { momentTime } from "../../helpers/date_functions";
import AppConfig from "../../config/AppConfig";
import EditInvGenDate from "./EditInvGenDate";
import MonthlyCutoffIcon from "./MonthlyCutoffIcon";
const { Paragraph } = Typography;
class GroupByInvoice extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [];
    if(props) {
      this.setColumns(props);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  setColumns = (props) => {
    const { warehouseFilter = {}} = props;
    const selectedwh = warehouseFilter?.selectedWarehouses?.length &&
    _.isArray( warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses[0]
      : warehouseFilter.selectedWarehouses;
    const timeZoneId = getValueFromArrayOfObjects(
      warehouseFilter.warehouses, "id", selectedwh, "timeZoneId"
    )
    this.columns = [
      {
        key: "invoice_number",
        title: I18n.t("account.billing.invoice_no"),
        dataIndex: "frequency_invoice_number",
        width: 180,
        render: (data, record, index) => {
          const isEditable = this.props.currentProcessedId === record.id;
          const isCutoffCompleted = record.is_cutoff_completed && record.is_cutoff_completed === "true";

          return (
            <div>
              {isEditable ? (
                <Row type="flex" align="middle">
                  <Col span={18}>
                    <Input
                      value={this.props.editedInvoiceNo}
                      size="small"
                      onChange={(e) =>
                        this.props.onEditInvoiceNo(
                          index,
                          "editedInvoiceNo",
                          e.target.value
                        )                      
                      }
                      onKeyPress={(e)=>this.props.onKeyPress(e)}
                      className="textUpperCase"
                      onKeyDown={(e) =>
                        this.props.handleKeyDown(index, record.id, e)
                      }
                      style={{ width: "95%" }}
                      autoFocus
                    />
                  </Col>
                  <Col span={3}>
                    <Icon
                      type="save"
                      onClick={() =>
                        this.props.handleInvoiceNoSave(index, record.id)
                      }
                      style={{ color: "#1890ff" }}
                    />
                  </Col>
                  <Col span={3}>
                    <Popconfirm
                      title={I18n.t("messages.cancel_confirm")}
                      onConfirm={() =>
                        this.props.handleInvoiceNoCancel(index, record.id)
                      }
                      placement="topLeft"
                    >
                      <Icon
                        type="close"
                        style={{
                          color: "red",
                        }}
                      />
                    </Popconfirm>
                  </Col>{" "}
                </Row>
              ) : (
                <Row type="flex" gutter={8}>
                    <Col span={16}>
                      <Typography.Text>{data}</Typography.Text>
                      {isCutoffCompleted && (
                        <Tooltip title="Monthly Cutoff">
                            <sup className="textRed textBold" style={{fontSize: "100%"}}>
                              C
                            </sup>
                        </Tooltip>

                      )}
                    </Col>
                  <Col span={3}>
                    <Copyable text={data} />
                  </Col>
                  
                  {record.is_locked !=='true' && <Col span={4}>
                    {(isEmpty(this.props.currentProcessedId) ||
                      record.id === this.props.currentProcessedId) && (
                        <Tooltip title={ I18n.t("general.edit") }>
                      <EditIcon handleClick={() =>
                            this.props.handleInvoiceNoEdit(index, record.id)}/>
                            &nbsp;
                            </Tooltip>
                      // <Icon
                      //   type="edit"
                      //   onClick={() =>
                      //     this.props.handleInvoiceNoEdit(index, record.id)
                      //   }
                      //   style={{ color: "#1890ff", marginTop: "3px" }}
                      // />
                    )}
                  </Col>}
                </Row>
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "account_frequency_type",
        title: I18n.t("account.billing.invoice_type"),
        dataIndex: "account_frequency_type",
      },
      {
        key: "wh_location_name",
        title: I18n.t("location.location"),
        dataIndex: "wh_location_name",
        render: (data) => <div>{data ? data : ""}</div>,
        className: 'noWrap',
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routings|| ''}</div>
        ),
        width: 100
      },
      // {
      //   key: "account",
      //   title: I18n.t("general.account"),
      //   dataIndex: "account_id",
      //   render: (data, record) => {
      //     const accounts = this.props.accounts || [];
      //     const acccount = _.find(accounts, { id: record.account_id });
      //     return <div>{data && !isEmpty(acccount) ? acccount.code : ""}</div>;
      //   },
      // },

      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_id",
        className: "noWrap",      
        render: (data, record) => {
          // const accounts = this.props.accounts || [];
          // const acccount = _.find(accounts, { id: record.account_id });
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          const accoutName =
            getValueFromArrayOfObjects(
              this.props.accounts,
              "id",
              data,
              "name"
            ) || "";
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                 <Tooltip title={accoutName} placement="topLeft">{!isEmpty(accoutName) ? accoutName : data} </Tooltip>
                </Link>
              ) : !isEmpty(accoutName) ? (
                accoutName
              ) : (
                data
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
          // const accounts = this.props.accounts || [];
          // const acccount = _.find(accounts, { id: record.account_id });
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          const accoutName =
            getValueFromArrayOfObjects(
              this.props.accounts,
              "id",
              data,
              "name"
            ) || "";
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(accoutName) ? accoutName : data}
                </Link>
              ) : !isEmpty(accoutName) ? (
                accoutName
              ) : (
                data
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "billing_date",
        title: I18n.t("invoices.invoice_generated_date"),
        dataIndex: "approval_date",
        render: (data, record, index) => {
          return (
            <div>
              {data ? (
                <Fragment>
                  {momentTime(AppConfig.dateFormat, data, timeZoneId)}
                  &nbsp;&nbsp;&nbsp;
                  {record.status === 'PENDING' && 
                  (<EditInvGenDate
                    key={record.id}
                    date={data ? moment.utc(data) : ""}
                    record={record}
                    handleSuccess={(updatedInvGenDate) =>
                        this.props.resetParticularFied(updatedInvGenDate, record.id)
                      }
                    timeZoneId={timeZoneId}
                  />)
                  }
                </Fragment>
              ) : (
                <Fragment/>
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "orders_count",
        title: I18n.t("account.billing.orders_count"),
        dataIndex: null,
        sorter:(a, b) => compareNumber(a.customer_order_numbers.length, b.customer_order_numbers.length),
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {

          const currentFilterBy = _.get(this.props, "filterPlaceHolder.approvalDate", "");
          if (currentFilterBy === "invoice_completed_date") {

            const { fromDate, toDate } = this.props.filterPlaceHolder;
            const orders_in_range = [];
            const orders_out_of_range = [];

            const start_date = moment(fromDate).startOf('day')
            const end_date = moment(toDate).endOf('day')

            record.invoice_details.forEach(invoice => {
              const order_completion_date = momentTime(null, invoice.order_completion_date, timeZoneId).startOf('day');

              if (order_completion_date.isSameOrAfter(start_date) && order_completion_date.isSameOrBefore(end_date)) {
                orders_in_range.push(invoice);
              } else {
                orders_out_of_range.push(invoice);
              }
            });
            const number_of_orders_falls_in_range = orders_in_range.length;
            const number_of_orders = record.invoice_details.length;

            const columns = [
              {
                title: 'Order',
                dataIndex: 'customer_order_number',
                key: 'customer_order_number',
              },
              {
                title: 'Completion Date',
                dataIndex: 'order_completion_date',
                key: 'order_completion_date',
                // render: text => moment(text).format(AppConfig.dateFormat),
                render: (text) => momentTime(AppConfig.dateFormat, text, timeZoneId),
              },
            ];

            const orderRangeTooltipContent = (
              <div style={ { width: '100%' } }>
                <Row>
                  <Col span={ 2 }>
                    <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                  </Col>
                  <Col span={ 22 }>
                    <Text strong>In-Range Orders:</Text>
                    <Table
                      size="small"
                      dataSource={ orders_in_range }
                      columns={ columns }
                      rowKey="number"
                      pagination={ false }
                      scroll={ { y: 200 } }
                    />
                  </Col>
                </Row>
                <Row style={ { marginTop: 10 } }>
                  <Col span={ 2 }>
                    <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
                  </Col>
                  <Col span={ 22 }>
                    <Text strong>Out-of-Range Orders:</Text>
                    { orders_out_of_range.length === 0 ? 
                      <div><Text>No orders found</Text></div>
                      :
                      <Table
                        size="small"
                        dataSource={ orders_out_of_range }
                        columns={ columns }
                        rowKey="number"
                        pagination={ false }
                        scroll={ { y: 200 } }
                      />
                    }
                  </Col>
                </Row>
              </div>
            );

            return (
              <div>
                <Popover
                  content={ orderRangeTooltipContent }
                  overlayClassName="popoverWidth"
                  style={ { width: '100%' } }
                  trigger="hover"
                >
                  <u className="text-decoration-dashed hover:text-blue-500">{ number_of_orders_falls_in_range + "/" + number_of_orders }</u>
                </Popover>
              </div>
            );
          }

          return (
          <div>
            {record?.customer_order_numbers && _.isArray(record.customer_order_numbers) ? (
              <Popover
                content={record.customer_order_numbers.join(", ")}
                title={I18n.t("menu.orders")}
                overlayClassName="popoverWidth"
              >
                <u className="text-decoration-dashed">{record.customer_order_numbers.length}</u>
              </Popover>
            ) : (
              0
            )}
          </div>
          );

        },
      },
      {
        key: "invoice_total_amount",
        title: I18n.t("invoices.amount"),
        dataIndex: "frequency_invoice_amount",
        render: (data) => (
          <div style={{ width: 100 }} className="alignRight">
            {data ? checkNegitive(data) : ""}
          </div>
        ),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "expand",
        title: "",
        render: (record) => {
          const isPartiallyPaid = findIfInvoicePartiallyPaid(record);
          const seletectedInfo = {
            selectedRows : [record],
          }
          return (
          <Row gutter={8} justify="space-between" align="middle" className="alignRight">
            <Col span={6} style={{ marginTop: -5 }}>
            {
              !isPartiallyPaid && record.is_locked ==='true' ?
              <>
                <UnlockInvoice
                  buttonType="primary"
                  isDisabled={!(record.is_locked ==='true')}
                  label={I18n.t('invoices.unlock')}
                  invoiceIds={record.id}
                  handleUnmount={this.props.onUnmount}
                  displayType="icon"
                  selectedInfo={seletectedInfo}
                  indexList = {true}
                />{" "}
              </>
              :
              (
                record.ar_status === 'OPEN' &&  record.status === 'APPROVED' ? 
                  <Tooltip title={I18n.t('invoices.opened')}>
                  <Icon type="safety-certificate"  className="textGrey textBold" style={{ marginRight: 10 , fontSize: 16 }}/>{" "}
                 </Tooltip>
                :
                record.ar_status === 'CLOSE' ? <Tooltip title={I18n.t('invoices.closed')}>
                  <Icon type="safety-certificate"  className="textGreen textBold" style={{ marginRight: 10 , fontSize: 16 }}/>{" "}
                 </Tooltip>
                :
                <Fragment />
              )
            }
            </Col>
            <Col span={6}>
            <ShowInvoice
              account={record.account_id}
              invoice_no={record.frequency_invoice_number}
              key={record.frequency_invoice_number}
              style={{ marginTop: -6 }}
              invoice_type={record.account_frequency_type}
              account_invoice_id={record.id}
              status={record.status}
              refreshList={this.props.refreshList}
              is_locked= {record.is_locked == 'true'}
              checkList={this.props.checkList}
              approvalDate={record.approval_date}
              invoiceApprovalDate={record.invoice_approval_date}
              enableChecklistApproval={this.props.enableChecklistApproval}
              renderType="icon"
              onUnmount={this.props.onUnmount}
              timeZoneId={timeZoneId}
              selectedInfo={seletectedInfo}
            />
            </Col>
            <Col span={6}>
            {checkServiceExistance("SOIN") && (
              <span>
                <ShowSummary
                    currentOrg={this.props.currentOrg}
                    userContext={this.props.userContext}
                    status={record.status}
                    account={record.account_id}
                    invoice={{
                    invoice_number: record.frequency_invoice_number,
                    billing_date: record.approval_date,
                    invoice_approval_date: record.invoice_approval_date,
                    invoice_amount: record.frequency_invoice_amount,
                    is_locked: record.is_locked == 'true'
                  }}
                />
              </span>
            )}
            </Col>
            <Col span={6}>
            {record.is_locked !=='true' && checkServiceExistance("ID") && (

              <Tooltip title={I18n.t("general.delete") }>
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() =>
                  this.props.deleteClick(record.id, record.account_id)
                }
                okText="Yes"
                cancelText="No"
              >
                &nbsp; &nbsp;
                <DeleteIcon className="fontSize17"/>
              </Popconfirm>
              </Tooltip>
            )}
            </Col>
          </Row>
          )
       },
      },
    ];

    if(this.props.currentStatus === "APPROVED"){
      this.columns.splice(2,0,{
        key: "invoice_approval_date",
        title: I18n.t("invoices.approved_date"),
        dataIndex: "invoice_approval_date",
        render: (data) => (
          <div>{ data ? momentTime(AppConfig.dateFormat, data, timeZoneId) : ""}</div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });
    }

    const isInvoiceScreen = props.screen_from === "invoice";
    if (!_.isEmpty(props.displayConfiguration) && isInvoiceScreen) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const invoice_list_columns = userConfiguration.invoice_list_columns || orgConfiguration.invoice_list_columns;
      const columns = [];
      if (
        typeof invoice_list_columns !== undefined &&
        Array.isArray(invoice_list_columns)
      ) {
        invoice_list_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "expand");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  }

}

export default GroupByInvoice;