/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { isEmpty } from '../common/Common'
import { Modal, Tooltip } from 'antd'
import AppConfig from '../config/AppConfig'
import { momentTime } from './date_functions'
import I18n from '../common/I18n'

export const checkForSMP = (record) => {
  if (record?.is_smp_applied) {
    return (
      <Tooltip title={I18n.t('invoices.second_machine_price_applied')}>
        <sup className="textRed textBold fontSize10">&nbsp;&nbsp;(SMP)</sup>
      </Tooltip>
    )
  }
  return <Fragment />
}

const findConversionErrors = (id, record, showQuoteForm) => {
  const requireFields = {
    wh_location_id: I18n.t('location.location'),
    account_code: I18n.t('general.account'),
    level_of_service: I18n.t('los.label'),
  }
  const locationDetails = {
    pickup_location_details: I18n.t('general.pickup_address'),
    delivery_location_details: I18n.t('general.delivery_address'),
  }
  let errors = []
  if (!isEmpty(record)) {
    errors = Object.keys(requireFields)
      .filter((item) => isEmpty(record[item]))
      .map((item) => requireFields[item])
    if (showQuoteForm) {
      if (
        isEmpty(record.pickup_location_id)
      ) {
        errors.push(locationDetails.pickup_location_details)
      }
      if (
        isEmpty(record.delivery_location_id)
      ) {
        errors.push(locationDetails.delivery_location_details)
      }
    } else {
      if (
        isEmpty(record.pickup_location_details) ||
        isEmpty(record.pickup_location_details.id)
      ) {
        errors.push(locationDetails.pickup_location_details)
      }
      if (
        isEmpty(record.delivery_location_details) ||
        isEmpty(record.delivery_location_details.id)
      ) {
        errors.push(locationDetails.delivery_location_details)
      }
    }

    if (errors.length > 0) {
      return errors
    }
  }
  return errors
}

export const orderConversionValidation = (
  id,
  record,
  approveQuote,
  editQuote,
  showQuoteForm = false,
) => {
  let errors = findConversionErrors(id, record, showQuoteForm)
  if (errors.length > 0) {
    Modal.error({
      key: `${record.id}`,
      title: 'Error',
      content: (
        <div className="fontWeight500">
          {errors.join(',')} {errors.length > 1 ? 'are' : 'is'} required to
          create order. Please update & approve them to proceed
        </div>
      ),
      width: '40%',
      onOk: () => (!showQuoteForm ? editQuote(record) : null),
    })
  } else {
    approveQuote(record.id)
  }
}

export const setAppointmentDetails = (source, typeOfOrder, timezone) => {
  // const appointment = source && source.length > index ? source[index] : null;
  const appointment = _.find(source, { type_of_order: typeOfOrder })
  if (appointment) {
    return {
      s_no: 1,
      item_preference: moment(appointment.start_datetime).isValid()
        ? moment.utc(appointment.start_datetime)
        : null,
      item_option: [appointment.slot],
      has_expedite: appointment.has_expedite === true,
      start_time:
        appointment.slot !== 'ANY' &&
        moment(appointment.start_datetime).isValid()
          ? momentTime(
              AppConfig.dateTimeFormat,
              appointment.start_datetime,
              timezone,
            )
          : null,
      end_time:
        appointment.slot !== 'ANY' && moment(appointment.end_datetime).isValid()
          ? momentTime(
              AppConfig.dateTimeFormat,
              appointment.end_datetime,
              timezone,
            )
          : null,
    }
  }
  return null
}
