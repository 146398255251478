/* eslint-disable react/sort-comp */
import React, { Component, } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  Col, Input, FormItem, Row, DatePicker,
  Select, Button,
} from '../../common/UIComponents';
import I18n from '../../common/I18n';
import { fetchShortFormAccounts } from '../../api/Account';
import { fetchLocations } from '../../api/LocationsApi';
import AppConfig from '../../config/AppConfig';
import { Radio } from 'antd';

const { Search } = Input;
const { RangePicker } = DatePicker;

class InvoiceFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      filter: this.props.filter || {},
      accounts: [],
      warehouses: this.props.warehouses || [],
      accountsLoading: true,
      levelOfServices: [],
      frequencyTypeOptions: [
        { key: 'GROUP', value: 'GROUP', label: 'Custom Grouping' },
        { key: 'WEEK', value: 'WEEK', label: 'Week' },
        { key: 'DAILY', value: 'DAILY', label: 'Daily' },
        { key: 'STOP', value: 'STOP', label: 'Stop' },
        { key: 'INDV', value: 'INDV', label: 'Individual' },
      ],
    };
  }

  componentDidMount () {
    this.getAccounts();
    // this.getWarehouses();
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    } else if (
      !_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) ||
      (!_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    }
  }


  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const accountsData = this.props.warehouseFilter.findMatchingAccounts();
    this.setState({
      accounts: accountsData,
      accountsLoading: false,
    });
  };

  getWarehouses = () => {
    fetchLocations(AppConfig.warehouseCode, 1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            warehouses: result.locations.locations,
          });
        }
      });
  };

  _renderSearchOrder = () => (
    <Search
      placeholder={ `${I18n.t('general.search')} ${I18n.t('order.order_no')}` }
      // enterButton
      // size="small"
      // value={
      //   !isEmpty(this.state.filter.search_order_token)
      //     ? this.state.filter.search_order_token.toUpperCase()
      //     : ""
      // }
      // onChange={(e) => {
      //   this.handleFilterChange("search_order_token", e.target.value, false);
      // }}
      onSearch={ (value) => {
        this.handleFilterChange('search_order_token', value);
      } }
      onChange={ (e) => {
        // clear url search params
        if (window.location.search.indexOf("order_number") > -1) {
          const { history } = this.props;
          history.replace("/invoices");
        }
        this.setState({ filter: { ...this.state.filter, search_order_token: e.target.value } });
      } }
      value={ this.state.filter.search_order_token }
      style={ { width: "100%", textTransform: "uppercase" } }
      className="textUpperCase"
      allowClear
    />
  );

  _renderAccountsList = () => (
    <FormItem label={ I18n.t('account.account') }>
      <Select
        value={ this.state.filter.accountCode }
        showSearch
        style={ { width: '100%' } }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={ this.state.accountsLoading }
        onChange={ (e) => this.handleFilterChange('accountCode', e) }
      >
        <Select.Option key='all' value=''>
              -- All --
            </Select.Option>
        {!_.isEmpty(this.state.accounts) && this.state.accounts.map(account => (
              <Select.Option key={account.id} value={account.id}>
                {`${account.name} (${account.code})`}
              </Select.Option>
            ))}
      </Select>
    </FormItem>
  );

  _renderFilterType = () => (
    <FormItem label={I18n.t('general.invoice_type')}>
      <Select
        value={this.state.filter.frequencyType}
        showSearch
        style={{ width: '100%' }}
        placeholder="Invoice Type"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => this.handleFilterChange('frequencyType', e)}
      >
        <Select.Option key='all' value=''>
          -- All --
        </Select.Option>
        {this.state.frequencyTypeOptions.map(option => (
          <Select.Option key={option.key} value={option.value}>
            {option.label}
              </Select.Option>
            ))}
      </Select>
    </FormItem>
  );

  _renderWarehouse = () => (
    <FormItem label={ I18n.t('location.location') }>
      <Select
        value={ this.state.filter.wh_location_id || "" }
        showSearch
        style={ { width: '100%' } }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={ (e) => this.handleFilterChange('wh_location_id', e) }
      >
        <Select.Option key='all' value=''>
              -- All --
            </Select.Option>
            {this.state.warehouses.map(wh => (
              <Select.Option key={wh.id} value={wh.id}>
                {`${wh.name} (${wh.location_code})`}
              </Select.Option>
            ))}
      </Select>
    </FormItem>
  );

  clearForm = () => {
    const filter = {
      fromDate:  moment().subtract(AppConfig.invoices_filter_duration, "day"),
      toDate: moment(),
      accountCode: '',
      wh_location_id: "",
      viewType: 'byOrder', // byOrder
      search_order_token: '',
      currentStatus: 'PENDING',
      approvalDate: "billing_approval_date",
      frequencyType: ""
    };
    this.props.onChange(filter , () => { this.props.onSearch(); });
  };

  handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[ 0 ] !== '' && dateStrings[ 1 ] !== '')) {
      dateFilter = Object.assign({}, this.state.filter, { fromDate:  moment().subtract(AppConfig.invoices_filter_duration, "day"), toDate: moment() });
    } else {
      dateFilter = Object.assign({}, this.state.filter, { fromDate: dates[ 0 ], toDate: dates[ 1 ] });
    }
    this.setState({
      filter: {
        ...this.state.filter,
        fromDate: dateFilter.fromDate,
        toDate: dateFilter.toDate,
      }
    });
  };

  componentWillReceiveProps (nextProps) {
    this.setState({
      filter: nextProps.filter || {},
    });
  }

  handleFilterChange = (element, value, isSearch = true) => {
    const filter = Object.assign({}, this.state.filter, { [ element ]: value });
    if (element === I18n.t('order.filters.schedule_orders.type') && value === I18n.t('order.filters.schedule_orders.unscheduled_key')) {
      filter.fromDate = null;
      filter.toDate = null;
    }

    if (element === I18n.t('general.invoice_type')) {
      filter.frequencyType = value;
    }

    this.setState({ filter });


  };
  handleSearch = () => {
    if (this.state.filter.approvalDate == "invoice_approval_date") {
      const filter = { ...this.state.filter, currentStatus: 'APPROVED' };
      this.setState({ filter },()=>
      {
        this.props.onChange(this.state.filter, () => { this.props.onSearch(); });
      })
    } else if (this.state.filter.approvalDate == "invoice_completed_date") {
      this.props.onChange(this.state.filter, () => { this.props.onSearch("invoice_completed_date", true); });
    }
    else{
      this.props.onChange(this.state.filter, () => { this.props.onSearch(); });
    }
  }

  render () {
    return (<div className="padding10">
      <Row gutter={ 16 }>
       
        <Col md={ 24 }>
          <FormItem label={ I18n.t('general.view_by') }>
            <Select
              value={ this.state.filter.viewType }
              showSearch
              style={ { width: '100%' } }
              filterOption={ (input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={ (e) => this.handleFilterChange('viewType', e) }
            >
              <Select.Option value="byAccount">
                { `${I18n.t('general.group_by')} ${I18n.t('account.account')}` }
              </Select.Option>
              <Select.Option value="byOrder">
                { I18n.t('invoices.label') }
              </Select.Option>
            </Select>
          </FormItem>
        </Col>
        <Col md={ 24 }>{ this._renderAccountsList() }</Col>
        <Col md={24}>{this._renderFilterType()}</Col>
        {/* <Col md={ 24 }>{ this._renderWarehouse() }</Col> */}
        <Col md={24} className="marginBottom10">
         <Radio.Group
           onChange={(e) => this.handleFilterChange("approvalDate", e.target.value)}
           value={this.state.filter.approvalDate}
         >
           <Row type="flex" gutter={8}>
             <Col span={8}>
               <Radio value={"billing_approval_date"}>
                  <strong>Invoice Generated Date</strong>
               </Radio>
             </Col>
             <Col span={8}>
               <Radio value={"invoice_approval_date"}>
                 <strong>Invoice Approval Date</strong>
               </Radio>
             </Col>
             <Col span={8}>
               <Radio value={"invoice_completed_date"}>
                 <strong>Order Completion Date</strong>
               </Radio>
             </Col>
           </Row>
         </Radio.Group>
        </Col>
        <Col md={24} className='marginBottom10'>
            <RangePicker
              defaultValue={ [ moment().startOf('month'), moment() ] }
              value={ [
                this.state.filter.fromDate,
                this.state.filter.toDate,
              ] }
              ranges={AppConfig.dateRanges}
              format={ AppConfig.dateFormat }
              style={ { width: '100%' } }
              onChange={ (dates, dateStrings) =>
                this.handleRangeChange(dates, dateStrings)
              }
          />
        </Col>
        {/* {this.state.filter.viewType === "byOrder" && ( */ }
        {/* <Col md={ 24 }>
          <FormItem
            label={ `${I18n.t('general.search')} ${I18n.t('order.order_no')}` }
          >
            { this._renderSearchOrder() }
          </FormItem>
        </Col> */}
        {/* )} */ }
        <Row type='flex' justify='center' gutter={ 4 }>
          <Col>
            <Button type="danger" onClick={ this.clearForm } icon='redo'>
              { I18n.t('general.reset') }
            </Button>
          </Col>
          <Col>
            <Button onClick={() => this.handleSearch()} type="primary" icon='search'>
              { I18n.t('general.search') }
            </Button>
          </Col>
        </Row>
      </Row>
    </div>
    );
  }
}

InvoiceFilter.propTypes = {
};
InvoiceFilter.defaultProps = {
};
export default withRouter(InvoiceFilter);
