import { isEmpty } from "../common/Common";
import AppConfig from "../config/AppConfig";
import { Call, ApiUrl, ParseGeneralResponse, buildQueryString } from "./ApiUtils";

export const WeeklyReportApi = {
  fetchReports: (payload) => {
    let url = ApiUrl("v2/weekly_drivers_reports?operation_code=GOR");
    const queryString = buildQueryString(payload);
    if(!isEmpty(queryString)){
      url = `${url}&${queryString}`;
    }
    let responseKeys = { reports: ""}
    if(payload.pdf_file === 'true'){
      responseKeys = { drivers_report_pdf: "drivers_report_pdf", file_name: "file_name"}
    }
    return Call("get", url, "", ParseGeneralResponse, {
        responseDataKeys: {...responseKeys},
    });
  },
  saveDriverReports: (data) => {
    data.operation_code = "UGOR";
    const url = ApiUrl("v2/weekly_drivers_reports/update_driver_gross_and_deduction")
    const method = "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { reports: "" },
    });
  },
  approveDriverReports: (data) => {
    data.operation_code = "AGOR";
    const url = ApiUrl("v2/weekly_drivers_reports/approve_multiply_drivers_weekly_settlement")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { reports: "" },
    });
  },
  fetchInvoices: (payload) => {
    let url = ApiUrl("v2/weekly_drivers_reports/get_drivers_week_settlement_invoices?operation_code=GOR");
    const queryString = buildQueryString(payload);
    if(!isEmpty(queryString)){
      url = `${url}&${queryString}`;
    }
    return Call("get", url, "", ParseGeneralResponse, {
        responseDataKeys: {
          data: '',
          pagination: 'pagination.pagination'
        },
    });
  },
  changeStatus: (data) => {
    data.operation_code = "AGOR";
    const url = ApiUrl("v2/weekly_drivers_reports/changing_driver_pay_invoice_status")
    const method = "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { reports: "" },
    });
  },

  
  
};
