import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  PageParams
} from "./ApiUtils";

export const IntegrationApi = {
  fetch: (paginationQuery = {}) => {
    const { currentPage, sortBy, sortOrder } = paginationQuery;
    const url = ApiUrl(`v2/integrations`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        integrations: "integrations",
        pagination: "meta.pagination",
      },
    });
  },
  create: (integrationData) => {
    const url = ApiUrl(`v2/integrations`);
    return Call("post", url, integrationData, ParseGeneralResponse, {
      responseDataKeys: {
        integration: "integration",
      },
    });
  },
  update: (integrationId, integrationData) => {
    const url = ApiUrl(`v2/integrations/${integrationId}`);
    return Call("put", url, integrationData, ParseGeneralResponse, {
      responseDataKeys: {
        integration: "integration",
      },
    });
  },
  delete: (id) => {
    const url = ApiUrl(`v2/integrations/${id}`);
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        integration: "integration",
      },
    });
  },
};

export const OrgIntegrationApi = {
  fetchOrg: (organization_id) => {
    const url = ApiUrl(
      `v2/integrations/org_level_integration_list?organization_id=${organization_id}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { org_integrations: "org_integrations" },
    });
  },
  authorizeCompany: (data) => {
    const url = ApiUrl(`v2/integrations/authorize_integration_companies`);
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: {
        message: "message",
        target_url: "target_url",
      },
    });
  },
  authorize: (organization_id, integration_code) => {
    const url = ApiUrl(`v2/integrations/authorize_integration`);
    return Call(
      "post",
      url,
      { organization_id, integration_code },
      ParseGeneralResponse,
      {
        responseDataKeys: {
          success: "success",
          errors: "errors",
          target_url: "target_url",
          message: "message",
        },
      }
    );
  },
  integrateService: (orgIntegrationPayload = {}) => {
    const { organization_id, integration_id, integration_code, status } =
      orgIntegrationPayload;
    const url = ApiUrl(`v2/integrations/add_as_org_integration`);
    return Call(
      "post",
      url,
      { organization_id, integration_id, integration_code, status },
      ParseGeneralResponse,
      {
        responseDataKeys: {
          integration: "integration",
        },
      }
    );
  },
  delete: ({ org_integration_id, organization_id }) => {
    const url = ApiUrl(
      `v2/integrations/delete_org_integration?org_integration_id=${org_integration_id}&organization_id=${organization_id}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        integration: "integration",
      },
    });
  },
  changeStatus: (orgIntegrationPayload = {}) => {
    const { organization_id, integration_id, integration_code, status } =
      orgIntegrationPayload;
    const url = ApiUrl(`v2/integrations/add_as_org_integration`);
    return Call(
      "post",
      url,
      { organization_id, integration_id, integration_code, status },
      ParseGeneralResponse,
      {
        responseDataKeys: {
          integration: "integration",
        },
      }
    );
  },
  unLinkIntegration: (org_integration_id) => {
    const url = ApiUrl(
      `v2/integrations/unlink_unauthorize_integration?org_integration_id=${org_integration_id}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        integration: "integration",
      },
    });
  },
};

export const CarrierIntegrationApi = {
  createIntegrationConfig: (data) => {
    const url = ApiUrl(`v2/account_io_configs/update_integration_config`);
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: {
        data: "configuration",
      },
    });
  },
  updateIntegrationConfig: (data) => {
    const url = ApiUrl(`v2/account_io_configs/update_integration_config`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: {
        data: "configuration",
      },
    });
  }
  ,
  getIntegrationConfig: (params = {}) => {
    const { organization_id, account_id, page, perPage } = params;
    let url = ApiUrl(
      `v2/account_io_configs/get_integration_configration?organization_id=${organization_id}&${PageParams(page, perPage)}`
    );
    if(account_id){
      url = url + `&account_id=${account_id}`
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "configurations",
        pagination: 'meta.pagination',
      },
    });
  },
  deleteIntegrationConfig: (id) => {
    const url = ApiUrl(`v2/account_io_configs/${id}`);
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        errors: "Message",
      },
    });
  }
};
