import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Text,
  Col,
  Icon,
  Row,
  Spin,
  Upload,
  message,
  Popconfirm,
  Link,
} from "./UIComponents";
import { dummyRequest } from "../helpers/common";
import imagePlaceholder from "../../assets/images/img_placeholder.png";
import I18n from "./I18n";

const ImageUpload = ({ imageUrl, placeholder, setImage, setFormData, imageProps = {} }) => {
  const dafaultImgProps = {
    width: "100px",
    height: "100px",
    objectFit: "cover",
  };
  const imgProps = { ...dafaultImgProps, ...imageProps};

  const getBase64 = (img, callback) => {
    // const data1 = Object.assign({}, this.state.user, { image: img });
    // this.setState({
    //   user: data1,
    // });
    setFormData(img);
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (image) => setImage(image));
  };

  const handleClear = () => {
    setFormData(null);
    setImage(null);
  };
  return (
    <Fragment>
      <Upload
        showUploadList={false}
        onChange={handleChange}
        customRequest={dummyRequest}
      >
        {imageUrl ? (
          <div className="borderRadius">
            <img
              src={imageUrl}
              style={{ ...imgProps }}
              alt="person"
              className="bearded-young-man"
            />
          </div>
        ) : (
          <div className="borderRadius">
            <img
              src={placeholder || imagePlaceholder}
              style={{ ...imgProps, height: 90 }}
              alt="person"
              className="bearded-young-man"
            />
          </div>
        )}
        {!imageUrl && (<div className="upload-btn-wrapper textBold alignCenter marginTop5">
          <Icon type="upload" />
          <span className="btn-text">Upload Image</span>
        </div>)}
      </Upload>
      {imageUrl && (
        <div
          className="ant-upload-list-item-actions textBold alignCenter marginTop5"
        >
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.clear_confirm")}
            onConfirm={handleClear}
            okText="Yes"
            cancelText="No"
          >
            <Link>Clear Image</Link>
          </Popconfirm>
        </div>
      )}
    </Fragment>
  );
};

export default ImageUpload;

ImageUpload.propTypes = {
  fileList: PropTypes.array,
  showUploadList: PropTypes.bool,
  previewFile: PropTypes.bool,
  disabled: PropTypes.bool,
  customRequest: PropTypes.func,
  uploadText: PropTypes.string,
  uploadHint: PropTypes.string,
  isLoading: PropTypes.bool,
  deleteFile: PropTypes.func,
  // isMultiUpload : PropTypes.bool,
};

ImageUpload.defaultProps = {
  fileList: [],
  showUploadList: false,
  previewFile: false,
  disabled: false,
  customRequest: () => {},
  uploadText: "Click or drag file to this area to upload",
  uploadHint: "Support for bulk orders upload.",
  isLoading: false,
  // isMultiUpload : false,
  onChange: () => {},
};
