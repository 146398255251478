import React  from 'react';
import PropTypes from 'prop-types';
import {isEmpty } from '../../common/Common';
import { Icon, Checkbox, Row, Col, Card, Input, Select, Option } from '../../common/UIComponents';
import userStore from '../../stores/UserStore';

const OrderAccessorials = ({
  order, orderAccessorials, accessorials, onChange, isReadOnly, showHeading = false, title = '', isMobile = false, perRow = 2,
}) => {
  const isAuthorised = userStore.superAdmin() || userStore.admin();
  const selectedAccessorials = orderAccessorials;
  const accPerRow = perRow ? 24 / perRow : 2;

  const _renderAccessorialOptions = (accessorial) => {
    let inputOptions = [];
    if (accessorial.input_options && accessorial.input_options.length > 0) {
      inputOptions = accessorial.input_options.map(optn => <Option key={optn.id}>{`${optn.first_name} ${optn.last_name}`}</Option>);
    }

    return inputOptions;
  };

  const _renderInputField = (accessorial) => {
    if (!isEmpty(accessorial.input_type) && accessorial.input_type != 'Select') {
      return (
        <Input
          size="small"
          type="number"
          min="0"
          value={accessorial.inputValue}
          className="accInputType"
          onChange={e => onChange('inputValue', e.target.value, accessorial.accessorial_key)}
          disabled={!orderAccessorials.includes(accessorial.accessorial_key) || accessorial.accessorial_type === 'los'}
        />
      );
    } else if (!isEmpty(accessorial.input_type) && accessorial.input_type == 'Select') {
      return (
        <Select
          mode="multiple"
          placeholder="Please select"
          style={{ width: '100%' }}
          maxTagCount={0}
          size="small"
          value={Array.isArray(accessorial.inputValue) ?
            accessorial.inputValue.filter(Boolean) :
            (accessorial.inputValue && typeof (accessorial.inputValue)) ? JSON.parse(accessorial.inputValue).filter(Boolean) : []}
          onChange={e => onChange('inputValue', e, accessorial.accessorial_key)}
          disabled={!orderAccessorials.includes(accessorial.accessorial_key) || accessorial.accessorial_type === 'los'}
        >
          {
            _renderAccessorialOptions(accessorial)
          }
        </Select>
      );
    }
  };

  // const _getExtaMenList = (inputValue) => {
  //   const extraMenIds = JSON.parse(inputValue);
  //   if (extraMenIds && extraMenIds.length > 0) {
  //     const accountAccessorials = order.account_accessorials;
  //     let userNames = extraMenIds.map((user_id) => {
  //       const extraMenObj = accountAccessorials.find((accs) => accs.code === 'EXM' || accs.code === 'EXL' || accs.code === 'EXLO');
  //       if (extraMenObj) {
  //         const user = extraMenObj.input_options.find((user) => user.id === user_id);
  //         if(!isEmpty(user)){
  //           return `${user.first_name}`;
  //         }

  //       }
  //     });
  //     userNames = userNames.filter(Boolean);
  //     if (userNames.length > 1) {
  //       return `${userNames[0]} (+${userNames.length - 1})`
  //     } else if (userNames.length == 1) {
  //       return `${userNames[0]}`
  //     } else {
  //       return '-NA-'
  //     }
  //   }
  // }

  const _renderAccessorialList = accessorial =>
    // if (accessorial.accessorial_key === 'extra_men' || accessorial.accessorial_key === 'extra_labor' || accessorial.accessorial_key === 'extra_labor_other') {
    //   return (
    //     <Col
    //       xs={24}
    //       sm={24}
    //       lg={accPerRow}
    //       md={accPerRow}
    //       key={accessorial.accessorial_key}
    //       className="marginTop5 borderBottom paddingBottom5"
    //     >
    //       <Row>
    //         <Col xs={1}>
    //           {accessorial.is_completed && accessorial.is_completed === 'true' ?
    //             <Icon type="check-circle" theme="twoTone" className='textGreen' />
    //             :
    //             <Icon type="check-circle" className='textGrey' />
    //           }
    //         </Col>
    //         <Col xs={13} className="alignLeft paddingLeft5">
    //           &nbsp;{accessorial.name}
    //         </Col>
    //         <Col xs={10} className="alignLeft textBold">
    //           {!isEmpty(accessorial.inputValue) ?
    //             _getExtaMenList(accessorial.inputValue)
    //             :
    //             <div style={{ width: 75 }} />
    //           }
    //         </Col>
    //         <Col xs={6} />
    //       </Row>
    //     </Col>
    //   );
    // }
    (
      <Col
        xs={24}
        sm={24}
        lg={accPerRow}
        md={accPerRow}
        key={accessorial.accessorial_key}
        className="marginTop5 borderBottom paddingBottom5"
      >
        <Row>
          <Col xs={1}>
            {accessorial.is_completed && accessorial.is_completed === 'true' ?
              <Icon type="check-circle" theme="twoTone" className='textGreen' />
              :
              <Icon type="check-circle" className='textGrey' />
            }
          </Col>
          <Col xs={13} className="alignLeft paddingLeft5">
            {accessorial.name}
          </Col>
          <Col xs={10} className="alignLeft textBold">
            {!isEmpty(accessorial.inputValue) ?
              accessorial.inputValue
              :
              <div style={{ width: 75 }} />
            }
          </Col>
          <Col xs={6} />
        </Row>
      </Col>
    );


  return (
    <Card
      key={order.id}
      size="small"
      title={showHeading ? title : ''}
      className="marginBottom10"
      bodyStyle={{
        minHeight: isReadOnly ? 165 : 145,
      }}
    >
      { isReadOnly ?
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details">
            <Row>
              <Col sm={24} xs={24} md={24} lg={24}>
                <Row>
                  <Col xs={24}>
                    <Checkbox.Group
                      style={{ width: '100%' }}
                      value={selectedAccessorials || []}
                      onChange={e =>
                        onChange(
                          'orderAccessorials',
                          e,
                        )
                      }
                    >
                      <Row className="padding5" gutter={16}>
                        {
                          accessorials.map(accessorial => (
                            _renderAccessorialList(accessorial)
                          ))
                        }
                      </Row>
                    </Checkbox.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        :
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details">
            <Row>
              <Col sm={24} xs={24} md={isAuthorised ? 12 : 24} lg={isAuthorised ? 22 : 24}>
                <Row>
                  <Col xs={24}>
                    <Checkbox.Group
                      style={{ width: '100%' }}
                      value={selectedAccessorials || []}
                      onChange={e =>
                        onChange(
                          'orderAccessorials',
                          e,
                        )
                      }
                    >
                      <Row className="padding5" gutter={16}>
                        {
                          accessorials.map(accessorial => (
                            <Col
                              xs={24}
                              sm={24}
                              lg={accPerRow}
                              md={accPerRow}
                              key={accessorial.accessorial_key}
                              className="marginTop5 borderBottom paddingBottom5 accColHeight"
                            >
                              <Row>
                                <Col xs={1}>
                                  <Checkbox
                                    value={accessorial.accessorial_key}
                                    disabled={accessorial.accessorial_type === 'los'}
                                  />
                                </Col>
                                <Col xs={13} className="alignLeft paddingLeft5 accInputName">
                                  &nbsp;{accessorial.name}
                                </Col>
                                <Col xs={10}>
                                  {/* { !isEmpty(accessorial.input_type) ?
                                    <Input
                                      size="small"
                                      type="number"
                                      min="0"
                                      value={accessorial.inputValue}
                                      className="accInputType"
                                      onChange={e => onChange('inputValue', e.target.value, accessorial.accessorial_key)}
                                      disabled={!orderAccessorials.includes(accessorial.accessorial_key) || accessorial.accessorial_type === 'los'}
                                    />
                                    :
                                    <div style={{ width: 75 }} />
                                  } */
                                    _renderInputField(accessorial)
                                  }
                                </Col>
                                <Col xs={5} />
                              </Row>
                            </Col>
                          ))
                        }
                      </Row>


                    </Checkbox.Group>
                  </Col>
                </Row>
              </Col>
            </Row>


          </Col>


        </Row>
      }
    </Card>);
};

OrderAccessorials.propTypes = {
  order: PropTypes.shape().isRequired,
  accessorials: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderAccessorials: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  title: PropTypes.string,
  showHeading: PropTypes.bool,
  perRow: PropTypes.number,
};
OrderAccessorials.defaultProps = {
  isReadOnly: false,
  title: '',
  showHeading: true,
  perRow: 2,
};

export default OrderAccessorials;
