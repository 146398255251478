/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import BaseList from "../BaseList";
import { Icon, Link, Tooltip } from "../../common/UIComponents";
import PropTypes from "prop-types";
import { checkServiceExistance } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import I18n from "../../common/I18n";
class FuelSurchargeList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "name",
        title: I18n.t("fuelSurcharges.name"),
        dataIndex: "name",
        render: (name, data) => (
          <span>
            {data._surcharge_type === "LINE_HAUL" ||
            data._surcharge_type === "MILES" ? (
              <Link onClick={() => this.props.settingsClick(data.id)}>
                {name}
              </Link>
            ) : (
              name
            )}
          </span>
        ),
      },
      {
        key: "code",
        title: I18n.t("general.code"),
        dataIndex: "code",
      },
      {
        key: "surcharge_type",
        title: I18n.t("fuelSurcharges.surcharge_type"),
        dataIndex: "_surcharge_type",
      },
      {
        key: "region",
        title: I18n.t("fuelSurcharges.region"),
        dataIndex: "region",
      },
      {
        key: "_frequency",
        title: I18n.t("fuelSurcharges.frequency"),
        dataIndex: "_frequency",
      },
      {
        key: "frequency_value",
        title: I18n.t("general.day_date"),
        dataIndex: "frequency_value",
        render: (frequency_value, data) => (
          <span>
            {["WEEKLY", "MONTHLY"].includes(data._frequency) ? (
              frequency_value
            ) : (
              <Icon type="minus" />
            )}
          </span>
        ),
      },
      {
        key: "is_default",
        title: I18n.t("fuelSurcharges.default"),
        dataIndex: "is_default",
        render: (primary) => (
          <div className="line-actions">
            {primary ? <Icon type="check" /> : <Icon type="minus" />}
          </div>
        ),
      },
      {
        title: I18n.t("general.actions"),
        key: "actions",
        dataIndex: "id",
        align:"right",
        fixed: "right",
        render: (id, data) => {
          const editCode = this.props.isAccConfig ? "FSCDU " : "FSCMU";
          return (
            <div className="line-actions">
              {checkServiceExistance("FSCDI") &&
                (data._surcharge_type === "LINE_HAUL" ||
                  data._surcharge_type === "MILES") && (
                    <Tooltip title={ I18n.t("tooltip.setting") }>
                  <Icon
                    type="table"
                    className="TableIcon"
                    onClick={() => this.props.settingsClick(id)}
                  />
                  </Tooltip>
                )}
              {checkServiceExistance(editCode) && !this.props.hideEdit && (
                <Tooltip title={ I18n.t("general.edit") }>
                <EditIcon handleClick={() => this.props.onEditClick(id)}/>
                &nbsp;
                </Tooltip>
              )}
              {!this.props.hideDelete && (
                <Tooltip title={ I18n.t("general.delete") }>
                <DeleteIcon handleClick={() => this.props.OnDeleteClick(id)}/>
                &nbsp;
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];
  }
}

export default FuelSurchargeList;
FuelSurchargeList.propTypes = {
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool,
  settingsClick: PropTypes.func,
  onEditClick: PropTypes.func,
  OnDeleteClick: PropTypes.func,
};

FuelSurchargeList.defaultProps = {
  hideEdit: false,
  hideDelete: false,
  settingsClick: () => {},
  onEditClick: () => {},
  OnDeleteClick: () => {},
};
