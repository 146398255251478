import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';
import moment from "moment";


export function fetchSupport(orgID, searchType, searchParam, type) {
  const url = ApiUrl(`v2/customer_order_files?organization_id=${orgID}&${searchType}=${searchParam}&file_type=${type}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: {orders: 'order_files'},
  }, false);
}

export function fetchRoutes(orgId, date) {
  const formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
  const url = ApiUrl(`v2/search_routes?organization_id=${orgId}&date=${formattedDate}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { routes: 'routes'},
  }, false);
}

export function fetchOrderStatusFiles(orgId, orderNo) {
  const url = ApiUrl(`v2/order_status_files?organization_id=${orgId}&customer_order_no=${orderNo}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { order_details: 'order_details'},
  }, false);
}

export function fetchRouteOrders(routeId) {
  const url = ApiUrl(`v2/route_orders?route_id=${routeId}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { route_orders: 'route_orders'},
  }, false);
}

export function regenerateStatusFiles(data) {
  const url = ApiUrl('v2/regenerate_files');
  return Call('POST', url, data, ParseGeneralResponse, { responseDataKeys: { message: 'message' } }, false);
}

export function removeRouteFromOrder(data) {
  const url = ApiUrl('v2/remove_route_from_order');
  return Call('PUT', url, data, ParseGeneralResponse, { responseDataKeys: { message: 'message' } }, false);
}

export function putNotification(data) {
  const url = ApiUrl('v2/drivers/notify_driver_manually');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { message: 'message' } }, false);
}