import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { Card, Col,Icon , Row, Tooltip } from 'antd';
import moment from 'moment';
import I18n from '../common/I18n';
import Legs from '../components/Tasks/Legs';
import { checkServiceExistance, formatByTimeConfig } from '../helpers/common';
import PrintGallery from '../components/common/PrintGallery';
import photoIcon from "../../assets/images/upload_photo.png";
import BaseModal from '../components/BaseModal';
import UploadGallery from '../components/common/UploadGallery';
import { deleteOrderPic, uploadPod } from '../api/OrdersApi';

// Function to format the image based on its type
const formatImage = (pic, pictures, signatureImgs, printPics, signatureObject) => {
  if (_.get(pic, 'picture.url')) {
    const formattedImage = _.get(pic, 'picture.url');
    if (pic.image_type === "normal") {
      pictures.push(formattedImage);
    } else if (pic.image_type === "signature") {
      signatureImgs.push(formattedImage);
      signatureObject = { ...pic };
    } else if (pic.image_type === "print") {
      printPics.push(formattedImage);
    }
  }
};

// Function to render the card extra content
const renderCardExtra = (showActions, currentOrder, printPics , imageAction) => {
  return (
    <>
      { showActions === true && (
        <div className="center">
          { checkServiceExistance("NRASOP") &&
            (
              <Tooltip title={ I18n.t("order.upload_images") }>
                <img
                  src={ photoIcon }
                  onClick={ () => {
                    // this.setState({ imagesVisible: true });
                    imageAction(
                      currentOrder.customer_order_id
                    );
                  } }
                  style={ { width: 20, height: 20 } }
                  alt="upload"
                  className="marginRight5"
                />
              </Tooltip>
            ) }
          { printPics && printPics.length > 0 && (
            <Tooltip title="Print Images">
              <PrintGallery pictures={ printPics } />
            </Tooltip>
          ) }
        </div>
      ) }
    </>
  );
};

// Function to render the signature details
const renderSignatureDetails = (signatureObject, currentOrder , organizationSettings) => {
  if (!_.isEmpty(signatureObject) &&
    _.isObject(signatureObject) &&
    signatureObject.sign_by) {
    return (
      <Fragment>
        <Row>
          <Col xs={ 24 }>
            <h4 className="alignLeft">
              { I18n.t("order.sign_by") }{ " " }
              <span className="textCaptilize">
                { signatureObject.sign_by }
              </span>{ " " }
              { !_.isEmpty(
                signatureObject.title_by_relation
              ) && (
                  <span className="textCaptilize">
                    ( { signatureObject.title_by_relation } )
                  </span>
                ) }
              { signatureObject.captured_at
                ? `at ${formatByTimeConfig(
                  moment.utc(signatureObject.captured_at),
                  _.get(organizationSettings, 'is_military_time'),
                  "Do MMM YYYY, HH:mm",
                  "Do MMM YYYY, hh:mm A"
                )} ${currentOrder.order_tz_short_name
                  ? currentOrder.order_tz_short_name
                  : ""
                }`
                : "" }
            </h4>
          </Col>
        </Row>
      </Fragment>
    );
  }
  return null;
};

// Main render function
const OrderPods = (props) => {
  const {organizationSettings , invoice, showActions, order ,refreshDetails , config, getSignatureAndCreds } = props;
  const [currentOrderPics, setCurrentOrderPics] = useState({});
  const [imagesVisible, setImagesVisible] = useState(false);

  const { pod_pictures: podPics } = order;
  const onCloseUploadGallery = () => {
    setImagesVisible(false);
    setCurrentOrderPics({});
    refreshDetails();
  };
  const renderUploadGallery = () => {
    return (
      <BaseModal
        title={I18n.t("order.upload_images")}
        onCancel={onCloseUploadGallery}
        visible={imagesVisible}
        width="70%"
        style={{ top: 30 }}
        maskClosable={false}
      >
        <UploadGallery
          config={config}
          refreshOrder={refreshDetails}
          closeGallery={onCloseUploadGallery}
          uploadApi={uploadPod}
          deleteAttachments={deleteOrderPic}
          currentOrderPics={currentOrderPics}
          refer={"invoice"}
          invoice={invoice}
        />
      </BaseModal>
    );
  };


  const imageAction = (id) => {
    const currentOrderPics = _.find(podPics,{
      customer_order_id: id,
    });
    setImagesVisible(true);
    setCurrentOrderPics(!isEmpty(currentOrderPics)
      ? _.cloneDeep(currentOrderPics)
      : {});
  };
  if (
    invoice.order_group_type !== "MANUAL" &&
    podPics &&
    podPics.length > 0
  ) {
    return (
        <Row>

          <Col xs={ 24 }>
          {imagesVisible && renderUploadGallery()}
          </Col>
          <Col xs={ 24 }>
          { podPics.map((currentOrder, index) => {
            const signatureImgs = [];
            const printPics = [];
            const pictures = [];
            const orderPictures = currentOrder?.pictures
              ? [ ...currentOrder.pictures ]
              : [];
            let signatureObject = {};
            if (orderPictures.length > 0) {
              orderPictures.forEach((pic) => {
                formatImage(pic, pictures, signatureImgs, printPics, signatureObject);
              });
            }
            const signature =
              signatureImgs.length > 0 ? signatureImgs[ 0 ] : "";
            const cardStyle =
              index % 2 === 0 ? { marginRight: "2px" } : { marginLeft: "2px" };
            return (
              <Col>
                <Card
                  style={{ 
                    ...cardStyle, 
                    backgroundColor: '#f0f2f5', // Change the background color
                    border: '2px solid #1890ff', // Add a border
                  }}
                  size="small"
                  title={
                    <div>
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> 
                      <span style={{ marginLeft: 8 }}>Proof of Delivery (POD)</span> 
                    </div>
                  }
                  extra={ renderCardExtra(showActions, currentOrder, printPics , imageAction) }
                >
                  <Legs
                    pictures={ pictures }
                    signature={ signature }
                    showActions={ showActions }
                    pagination={ { position: "none" } }
                    size="small"
                    showType="seperate"
                    imageAction={ showActions === false ? null : () => {
                      // this.setState({ imagesVisible: true });
                      imageAction(currentOrder.customer_order_id);
                    } }
                  />
                  { renderSignatureDetails(signatureObject, currentOrder, organizationSettings) }
                  {getSignatureAndCreds}
                </Card>
              </Col>            );
          }) }
          </Col>
        </Row>
    );
  }
  return <Fragment />;
};

export default OrderPods;