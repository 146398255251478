import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {  deleteZone, fetchZones } from '../../api/ZonesApi';
import { Button, Col, Row, Spin, Icon, Empty, Modal } from '../../common/UIComponents';
import ZonesList from './zones/ListRows';
import Main from './zones/Main';
import AllZonesMap from './zones/AllZonesMap';
import { alertMessage} from '../../common/Common';
import { deleteWHZone, fetchWHZones, saveWHZones } from '../../api/DeliveryZones';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import I18n from '../../common/I18n';

class Zones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      pagination: {},
      currentZone: {},
      inProgress: false,
      currentLocation: this.props.currentLocation ? this.props.currentLocation : {},
      defaultCenter: this.props.defaultCenter,
      showAll: true,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  componentDidMount() {
    this.getDeliveryZones(false);
  }

  getDeliveryZones = (setIntial = false) => {
    fetchWHZones(this.state.currentLocation.id)
      .then((result) => {
        if (result.success) {
          const zones = result.zones || [];
          if (zones.length === 0) {
            this.setState({ zones: [] });
          } else {
            this.setState({ zones }, () => {
              if (setIntial && this.state.zones[0] && this.state.zones[0].id) {
                this.handleEditClick(this.state.zones[0].id);
              }
            });
          }
        } else {
          // renderAlertMessage(result.errors)
          this.setState({ zones: [] });
        }
      });
  }

  getZones() {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    fetchZones(page)
      .then((result) => {
        if (result.success) {
          this.setState({
            zones: result.zones,
            pagination: result.pagination,
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleAddClick() {
    this.setState({
      currentZone: {},
      showMap: true,
      showAll: false,
    });
  }

  handleShowClick = () => {
    this.setState({
      currentZone: {},
      showAll: true,
      onDraw: false,
    });
  }

  handleEditClick(id) {
    const index = _.findIndex(this.state.zones, ['id', id]);
    const zone = Object.assign({}, this.state.zones[index]);
    if (zone.polygon_coords) {
      zone.geoFenceGeometryData = zone.polygon_coords;
    } else {
      zone.polygon_coords = [];
      zone.geoFenceGeometryData = [];
    }
    this.setState({
      currentZone: zone,
      showAll: false,
    });
  }

  handleDeleteClick(id) {
    this.setState({ inProgress: true });
    deleteZone(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getZones();
          this.setState({
            showAll: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleOnSave = (data) => {
    const apiData = Object.assign(
      {
        name: data.currentZone.name,
        polygon_coords: data.currentZone.geoFenceGeometryData ? data.currentZone.geoFenceGeometryData : [],
        geo_zip_codes: data.currentZone.geo_zip_codes || [],
        zip_codes: data.currentZone.zip_codes || [],
      },
      { id: data.currentZone.id },
    );
    const payload = {
      warehouse_id: this.state.currentLocation.id,
      zones: [apiData],
    };
    this.setState({
      inProgress: true,
      currentZone: data.currentZone,
    }, () => {
      this.saveZones(payload);
    });
  }

  saveZones = (payload) => {
    saveWHZones(payload)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({
            inProgress: false,
            currentZone: {},
            showAll: true,
          }, () => {
            this.getDeliveryZones();
          });
        } else {
          this.setState({
            inProgress: false,
          });
          Modal.error({
            title: 'Errors',
            content: <Fragment>
              {
                result.errors.map((err, index) => <Row><Col key={`err${index + 1}`} className="fontWeight500">{err}</Col></Row>)
              }
            </Fragment>,
            width: '40%',
          });
        }
      });
  }
  handleSaveSuccess(message) {
    alertMessage(message);
    this.setState({ showMap: false });
    this.getZones();
  }

  navigateMainScreen = () => {
    this.props.handleClose();
  }

  _renderHeader = () => (
    <Row className='page-header borderRadius10' style={{ fontSize: 16, height: 40 }}>
      <Col xs={8}>
        {`${this.state.currentLocation.name} ${I18n.t('location.delivery_zones')}`}
      </Col>
      <Col xs={16} className="alignRight">
        <Icon type="close" onClick={this.navigateMainScreen} />
      </Col>
    </Row>);

  handleOnClose() {
    const geoFenceGeometryData = this.state.currentZone.polygon_coords ? this.state.currentZone.polygon_coords : [];
    this.setState({
      currentZone: Object.assign({}, this.state.currentZone, { geoFenceGeometryData }),
    });
  }

  handleOnCancel = () => {
    this.setState({
      currentZone: {},
      showAll: true,
    });
  }

  handleTableChange(pagination, filters, sorter) {
    this.tableOptions = { pagination, filters, sorter };
    this.getZones();
  }


  handleZoneDelete = (id) => {
    this.setState({ inProgress: true });
    deleteWHZone({ id })
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.setState({
            inProgress: false,
            currentZone: {},
          });
          this.getDeliveryZones();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  render() {
    const { pagination } = this.state;
    return (
      <div className='content-outer'>
        <div className='content'>
          <this._renderHeader />
          <Fragment>
            <Row gutter={16}>
              <Col xs={6} className="leftPanel ">
                <Spin spinning={this.state.inProgress} delay={1000}>
                  <Row>
                    <Col xs={12} className="alignLeft">
                      <Fragment>
                        <Button size="small" disabled={this.state.showAll} type="link" onClick={() => this.handleShowClick()}>
                          {I18n.t('messages.show_all')}
                        </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                      </Fragment>
                    </Col>
                    <Col xs={12} className="alignRight">
                      {checkServiceExistance(['DZC', 'BZUMC'], 'ALL') && <Fragment>
                        <Button size="small" disabled={!this.state.showAll && !this.state.currentZone.id} type="primary" onClick={() => this.handleAddClick()}>
                          {I18n.t('general.new')} {I18n.t('location.delivery_zones')}
                        </Button> &nbsp;&nbsp;&nbsp;&nbsp;
                      </Fragment>
                      }
                    </Col>
                  </Row>
                  <Row className="marginTop20">
                    <Col xs={24}>
                      {this.state.zones.length > 0 ?
                        <ZonesList
                          size="small"
                          data={this.state.zones}
                          editClick={id => this.handleEditClick(id)}
                          deleteClick={id => this.handleZoneDelete(id)}
                        />
                        :
                        <Empty
                          description={
                            <h3>Please add {I18n.t('location.delivery_zones')}</h3>
                          }
                        />
                      }
                    </Col>
                  </Row>
                </Spin>

              </Col>
              { this.state.showAll ?
                <Col xs={18} className="paddingLeft15">
                  <AllZonesMap
                    defaultCenter={this.state.defaultCenter}
                    zones={this.state.zones}
                  />
                </Col>
                :
                <Col xs={18} className="paddingLeft15">
                  <Main
                    currentZone={this.state.currentZone}
                    handleOnSave={this.handleOnSave}
                    handleOnClose={this.handleOnClose}
                    handleOnCancel={this.handleOnCancel}
                    defaultCenter={this.state.defaultCenter}
                    zones={this.state.zones}
                  />
                </Col>
              }
            </Row>
          </Fragment>
        </div>
      </div>
    );
  }
}

export default Zones;
