/* eslint-disable camelcase */
import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import {
   fetchDriverRatesList,
  fetchDriverRate, deleteDriverRate,
  fetchAccessorialMasterData,
} from '../api/DriverRatesApi';
import { Button, Col, Row } from '../common/UIComponents';
import BaseModal from '../components/BaseModal';
import DriverRateForm from '../components/driverRates/DriverRateForm';
import DriverRateConfigurationForm from '../components/driverRates/DriverRateConfigurationForm';
import DriverRatesList from '../components/driverRates/DriverRatesList';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import { renderAlertMessage } from '../helpers/common';

class DriverRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDriverRateForm: false,
      showDriverRateConfigurationForm: false,
      currentDriverRate: {},
      driverRatesList: [],
      fetchData: {},
      masterAccessorials: [],
      driverRateDetails: [],
      driverRateConfigurationFormId: '',
      driverRatesCommonForm: { charge_type: 'percentage', charge_value: '0' },
      isDriverRateDetailsLoading: false,
      isNew: false,
    };
  }

  componentDidMount() {
    this.getDriverRatesList();
    this.getAccessorialMasterData();
  }

  getAccessorialMasterData = () => {
    fetchAccessorialMasterData()
      .then((result) => {
        if (result.success) {
          this.setState({
            masterAccessorials: result.master_accessorials,
          }, () => {
            if (this.state.masterAccessorials.length > 0) {
              let driverRateDetails = [];
              this.state.masterAccessorials.forEach((accessorial) => {
                const driverRate = {};
                driverRate.driver_rate_id = '';
                driverRate.driver_rate_name = '';
                driverRate.driver_rate_code = '';
                driverRate.charge_type = '';
                driverRate.charge_value = '';
                driverRate.accessorial_type = 'Accessorial';
                driverRate.accessorial_master_id = accessorial.id;
                driverRate.accessorial_name = accessorial.name;
                driverRate.accessorial_code = accessorial.code;
                driverRateDetails = [...driverRateDetails, driverRate];
              });
              this.setState({
                driverRateDetails: [...driverRateDetails],
              });
            }
          });
        }
      });
  }

  getDriverRatesList = () => {
    fetchDriverRatesList()
      .then((result) => {
        if (result.success) {
          this.setState({
            driverRatesList: result.driver_rates,
          });
        }
      });
  }

  handleDriverRateFormModal = () => {
    this.setState({
      showDriverRateConfigurationForm: true,
      currentDriverRate: { ...{} },
      isNew: true,
    });
  }

  getAccessorialCode = (accessorial_master_id) => {
    const { masterAccessorials } = this.state;
    const accessorial = masterAccessorials.find(accessrl => accessrl.id === accessorial_master_id);
    if (accessorial) {
      return accessorial.code;
    }
    return null;
  }

  handleDriverRateConfigurationFormModal = (driver_rate_id) => {
    this.setState({
      showDriverRateConfigurationForm: !this.state.showDriverRateConfigurationForm,
      driverRateConfigurationFormId: driver_rate_id,
    }, () => {
      this.getDriverRatesList();
      if (!this.state.showDriverRateConfigurationForm) {
        const driverRateDetails = this.state.driverRateDetails;
        const newDriverRateDetails = [];
        driverRateDetails.forEach((driveRate) => {
          const dvRate = {};
          dvRate.accessorial_master_id = driveRate.accessorial_master_id;
          dvRate.accessorial_name = driveRate.accessorial_name;
          dvRate.accessorial_code = driveRate.accessorial_code ? driveRate.accessorial_code : this.getAccessorialCode(driveRate.accessorial_master_id);
          dvRate.charge_type = '';
          dvRate.charge_value = '';
          dvRate.driver_rate_code = '';
          dvRate.driver_rate_id = '';
          dvRate.driver_rate_name = '';
          newDriverRateDetails.push(dvRate);
        });
        if (newDriverRateDetails.length > 0) {
          this.setState({
            driverRateDetails: [...newDriverRateDetails],
            driverRatesCommonForm: {
              charge_type: 'percentage',
              charge_value: '0',
            },
          });
        }
      }
    });
  }

  handleEditClick = (driver_rate_id) => {
    fetchDriverRate({ driver_rate_id })
      .then((result) => {
        if (result.success) {
          this.setState({
            currentDriverRate: Object.assign({}, this.state.currentDriverRate, result.driver_rate),
            showDriverRateConfigurationForm: true,
            isNew: false,
          });
        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  handleDeleteClick = (driver_rate_id) => {
    deleteDriverRate({ driver_rate_id })
      .then((result) => {
        if (result.success) {
          alertMessage('Deleted Successfully!');
          this.getDriverRatesList();
        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  handleSettingsClick = (driver_rate_id) => {
    this.handleDriverRateConfigurationFormModal(driver_rate_id);
    this.getDriverRateConfigurations(driver_rate_id);
  }





  _renderDriverRateConfigurationForm = () => {
    if (this.state.showDriverRateConfigurationForm) {
      return (
        <BaseModal
          title="Configure Driver Rates"
          style={{ top: 30 }}
          width="70%"
          onCancel={() => this.handleDriverRateConfigurationFormModal()}
        >
          <DriverRateConfigurationForm
            currentDriverRate={this.state.currentDriverRate}
            driverRatesList={this.state.driverRatesList}
            isNew={this.state.isNew}
            driverRateDetails={this.state.driverRateDetails}
            handleDriverRateInput={(key_name, value, accessorial_master_id) => this.handleDriverRateInput(key_name, value, accessorial_master_id)}
            handleDriverRateConfigurationSave={accessorial_master_id => this.handleDriverRateConfigurationSave(accessorial_master_id)}
            handleDriverRateConfigurationSaveAll={() => this.handleDriverRateConfigurationSaveAll()}
            handleCommonDriverRate={(element, value) => this.handleCommonDriverRate(element, value)}
            driverRatesCommonForm={this.state.driverRatesCommonForm}
            isDriverRateDetailsLoading={this.state.isDriverRateDetailsLoading}
            masterAccessorials={this.state.masterAccessorials}
          />
        </BaseModal>
      );
    }
    return null;
  }

  _renderDriverRateForm = () => {
    if (this.state.showDriverRateForm) {
      return (
        <BaseModal
          title="Create Driver Rate"
          style={{ top: 30 }}
          width="50%"
          onCancel={() => this.handleDriverRateFormModal()}
        >
          <DriverRateForm
            driverRate={this.state.currentDriverRate}
            handleDriverRateFormInputChage={(element, value) => this.handleDriverRateFormInputChage(element, value)}
            handleDriverRateSave={() => this.handleDriverRateSave()}
            handleDriverRateClear={() => this.handleDriverRateClear()}
          />
        </BaseModal>
      );
    }
    return null;
  }

  _renderAddDriverRateButton = () => (
    <Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button
          type='primary'
          size="small"
          onClick={() => this.handleDriverRateFormModal()}
          icon="plus"
        >
          Add Driver Rate
        </Button>
      </div>
    </Row>
  )

  _renderAddDriverRateList = () => (<Row>
    <DriverRatesList
      size="small"
      data={this.state.driverRatesList}
      pagination={{ position: 'none' }}
      scroll={{ y: 400 }}
      showDescription={false}
      handleEditClick={driver_rate_id => this.handleEditClick(driver_rate_id)}
      handleDeleteClick={driver_rate_id => this.handleDeleteClick(driver_rate_id)}
      handleSettingsClick={driver_rate_id => this.handleEditClick(driver_rate_id)}
    />
  </Row>)

  render() {
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col xs={12}>
              {I18n.t('menu.driver_rates')}
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '70%' }}>
              <this._renderAddDriverRateButton />
              <this._renderDriverRateForm />
              <this._renderDriverRateConfigurationForm />
              <this._renderAddDriverRateList />
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(DriverRates);
