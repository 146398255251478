/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import _ from "lodash";
import {
  Divider,
  Col,
  Row,
  Tabs,
  Checkbox,
  Select,
} from "../../common/UIComponents";
import { Input } from 'antd';
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import {  saveRoleActions } from "../../api/ModuleActions";
const { Search } = Input;

const { TabPane } = Tabs;
const { Option } = Select;

class ModuleActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedMasterModules: props.masterModules,
      searchValue: "",
      masterModules: props.masterModules || [],
      currentModules: props.currentModule || {},
      inProgress: false,
      isNew: false,
      activeKey: "1",
      errors: [],
    };
  }


  // componentDidUpdate(prevProps) {
  //   if (!_.isEqual(this.props.currentRole, prevProps.currentRole)) {
  //     this.getCurrentRoleActions();
  //   }
  // }

  handleActionOnChange = (moduleCode, opCodes) => {
    this.props.handleActionOnChange(moduleCode, opCodes);
  };

  handleSelectAllInModule = (moduleCode, value) => {
    const { masterModules } = this.props;
    const currentModule = _.find(masterModules, { module_code: moduleCode });
    if (!isEmpty(currentModule)) {
      if (value === true) {
        const operations = currentModule.operations || [];
        const opCodes = operations.map((op) => op.operation_code);
        this.handleActionOnChange(moduleCode, opCodes);
      } else {
        this.handleActionOnChange(moduleCode, []);
      }
    }
  };

  handleSelectAllModules = (value) => {
    const { masterModules } = this.props;
    if (value === true) {
      const currentModules = {};
      masterModules.forEach((currentModule) => {
        const operations = currentModule.operations || [];
        currentModules[currentModule.module_code] = operations.map(
          (op) => op.operation_code
        );
      });
      this.props.handleOnChange("currentModules", currentModules);
    } else {
      this.props.handleOnChange("currentModules", {});
    }
  };

  handleSave = () => {
    const data = {};
    const access = [];
    Object.keys(this.state.currentModules).forEach((key) => {
      const currentModule = _.uniq(this.state.currentModules[key]);
      currentModule.forEach((op) => {
        access.push({
          module_code: key,
          operation_code: op,
        });
      });
    });
    data.access = access;
    data.role= !isEmpty(this.state.currentRole) ? this.state.currentRole.toLowerCase() : '';
    this.setState({ inProgress: true });
    saveRoleActions(false, data).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false, errors: [] });
        alertMessage(I18n.t("messages.saved"));
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ errors: result.errors, inProgress: false });
      }
    });
  };

  handleSearch = () => {
    const { searchValue } = this.state;
    const { masterModules } = this.props;

    if (_.isEmpty(searchValue)) {
      this.setState({ searchedMasterModules: masterModules });
      return;
    }

    const searchedVal = _.lowerCase(searchValue);

    const filteredModules = masterModules.filter((module) => {
      return (
        module.operations.some((op) => {
          return _.includes(_.lowerCase(op.operation_name), searchedVal);
        }) ||
        _.includes(_.lowerCase(module.module_name), _.lowerCase(searchValue))
      );
    });

    this.setState({ searchedMasterModules: filteredModules }, () => {
      console.log(this.state.searchedMasterModules);
    });
  }    


  render() {
    const { masterModules, currentModules } = this.props;
    const { searchedMasterModules } = this.state;
    return (
      <div>
        
        <Row>
          <Col xs={24} className="alignLeft fontSize14" >

            <Row gutter={[0,24]}>
              <Col>
                <Search
                  placeholder="Search"
                  onChange={(e) => this.setState({ searchValue: e.target.value }, this.handleSearch)}
                  style={{ width: 200 }}
                />
              </Col>
            </Row>

            <Row gutter={[0,24]}>
              <Col>
                <Checkbox
                  onChange={(e) => this.handleSelectAllModules(e.target.checked)}
                >
                  Select {I18n.t("general.all")}
                </Checkbox>
              </Col>
            </Row>

          </Col>
        </Row>
          <Row className="marginTop10">
            <Col>
            {
              masterModules.length > 0 ? (
                <Fragment>
                  {(!_.isEmpty(this.state.searchValue)
                    ? searchedMasterModules
                    : masterModules
                  ).map((modul, index) => {
                    const isSelectAllInModule =
                      currentModules && currentModules[modul.module_code]
                        ? currentModules[modul.module_code].length ===
                        modul.operations.length
                        : false;
                    return (
                      <Fragment>
                        <Row style={{ zoom: "90%" }}>
                          <Col xs={4} className="textBold">
                            {modul.module_name}
                          </Col>
                          <Col xs={20}>
                            {modul.operations.length > 0 && (
                              <Row>
                                <Col xs={24}>
                                  <Checkbox
                                    onChange={(e) =>
                                      this.handleSelectAllInModule(
                                        modul.module_code,
                                        e.target.checked
                                      )
                                    }
                                    checked={isSelectAllInModule}
                                  >
                                    {I18n.t("general.all")}
                                  </Checkbox>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Checkbox.Group
                                value={
                                  currentModules && currentModules[modul.module_code]
                                    ? currentModules[modul.module_code]
                                    : []
                                }
                                onChange={(e) =>
                                  this.handleActionOnChange(modul.module_code, e)
                                }
                                style={{ width: "100%" }}
                              >
                                {modul.operations.map((op) => (
                                  <Col xs={6}>
                                    <Checkbox value={op.operation_code}>
                                      {op.operation_name}
                                      {/* &nbsp;({op.operation_code}) */}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Checkbox.Group>
                            </Row>
                          </Col>
                        </Row>
                        <Divider />
                      </Fragment>
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment />
              )
            }

            </Col>
          </Row>
      </div>
    );
  }
}
export default ModuleActions;
