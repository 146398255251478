import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Upload,
    Icon,
    Spin,
    Popconfirm,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import vhTypePlaceHolder from "../../../assets/images/traffic.png";
  import userStore from "../../stores/UserStore";
  import I18n from "../../common/I18n";
//   import { VehicleTypeApi } from "../../api/VehicleType";
import { VehicleTypeMappingApi } from "../../api/VehicleTypeMapping";
  import { alertMessage } from "../../common/Common";
  import { Link } from "../../common/UIComponents";
import { renderAlertMessage } from "../../helpers/common";
  
  const VehicleTypeMappingForm = (props) => {
    const { getFieldDecorator, validateFields, resetFields } = props.form;
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
  
    const { existingVehicleId } = props;
  
    useEffect(() => {
      if (existingVehicleId) {
        VehicleTypeMappingApi.fetchOne(existingVehicleId)
          .then((response) => {
            if (response.success) {
              //   setImage(response.data.master_vehicle_image.url);
              //   setImageFile(response.data.master_vehicle_image);
              const imageUrl = response.data?.master_vehicle_image?.url || null;
              setImage(imageUrl);
              setImageFile(response.data.master_vehicle_image);
              props.form.setFieldsValue({
                vhName: response.data.name,
                vhCode: response.data.code,
              });
            } else {
              setImage(null);
              setImageFile({});
              props.form.setFieldsValue({
                vhName: "",
                vhCode: "",
              });
            }
          })
          .catch((response) => {
            renderAlertMessage(response.message)
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, [existingVehicleId]);
  
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        callback(reader.result);
        setImage(reader.result); // Set the state of the image here
        const updatedImgType = { ...imageFile, image: img }; // assigning the file to the imageFile object
        setImageFile(updatedImgType);
      });
      reader.readAsDataURL(img);
    };
  
    const handleChange = (info) => {
      getBase64(info.file.originFileObj, (base64Image) => {
        setImage(base64Image);
      });
    };
  
    const handleDelete = () => {
      setImage(null);
      setImageFile({});
    };
  
    const validateForm = () => {
      validateFields((err, values) => {
        if (err) {
          return;
        }
        const payload = {
          name: values.vhName,
          code: values.vhCode.toUpperCase(),
        };
        if (imageFile?.image) {
          payload.master_vehicle_image = imageFile.image;
        } else if (!imageFile?.image && !image) {
          payload.master_vehicle_image = null;
        }
        if (existingVehicleId) {
          setIsLoading(true);
          VehicleTypeMappingApi.update(existingVehicleId, payload)
            .then((response) => {
              if (response.success) {
                alertMessage("Updated Successfully", "success");
                props.closeModal();
                props.getVhTypes();
              } else {
                alertMessage(response.errors[0].join(", "), "error", 10);
              }
            })
            .catch((response) => {
              renderAlertMessage(response.message)
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(true);
          VehicleTypeMappingApi.create(payload)
            .then((response) => {
              if (response.success) {
                alertMessage("Added Successfully", "success");
                props.getVhTypes();
                props.closeModal();
              } else {
                renderAlertMessage(response.errors)
              }
            })
            .catch((response) => {
              renderAlertMessage(response.message)
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    };
    return (
      <div>
        <Spin spinning={isLoading}>
          <Form>
            <Row gutter={16} type="flex" justify="space-between">
              <Col sm={24} xs={24} md={12} lg={12}>
                <Col className="paddingZero">
                  <Form.Item label="Vehicle Name">
                    {getFieldDecorator("vhName", {
                      rules: [{ required: true, message: "Enter vehicle Name!" }],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col className="paddingZero">
                  <Form.Item label="Vehicle Code">
                    {getFieldDecorator("vhCode", {
                      rules: [
                        { required: true, message: "Enter vehicle Code!" },
                        {
                          validator: (_, value, callback) => {
                            if (
                              value &&
                              (value.includes(" ") ||
                                /[!@#$%`^&*(),.?"':;{}[\]|<>]/.test(value))
                            ) {
                              callback(
                                "Spaces or special characters are not allowed"
                              );
                            } else {
                              callback();
                            }
                          },
                        },
                      ],
                    })(<Input style={{ textTransform: "uppercase" }} />)}
                  </Form.Item>
                </Col>
              </Col>
              <Col sm={24} xs={24} md={12} lg={12}>
                <Col style={{ float: "right" }}>
                  <Form.Item label="Upload Vehicle Type Picture">
                    <Upload onChange={handleChange} showUploadList={false}>
                      <div className="borderRadiusDotted">
                        {image ? (
                          <img
                            src={image}
                            style={{ height: 110, width: 120, cursor: "pointer" }}
                            alt="person"
                          />
                        ) : (
                          <img
                            src={vhTypePlaceHolder}
                            style={{ height: 110, width: 120, cursor: "pointer" }}
                            alt="person"
                          />
                        )}
                      </div>
                    </Upload>
                    {image && (
                      <div
                        className="ant-upload-list-item-actions"
                        style={{ marginLeft: "auto" }}
                      >
                        <Popconfirm
                          placement="topRight"
                          title={I18n.t("messages.clear_confirm")}
                          onConfirm={handleDelete}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Link>Clear Image</Link>
                        </Popconfirm>
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row className="marginTop10">
              <Col xs={24} className="alignCenter">
                <Button
                  className="marginRight5"
                  onClick={() => props.closeModal()}
                  icon="close"
                >
                  {I18n.t("general.cancel")}
                </Button>
                <Button type="primary" onClick={() => validateForm()} icon="save">
                  {existingVehicleId
                    ? I18n.t("general.update")
                    : I18n.t("general.save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  };
  const WrappedVehicleTypeMappingForm = Form.create({ name: "VehicleTypeMappingForm" })(
    VehicleTypeMappingForm
  );
  export default WrappedVehicleTypeMappingForm;
  