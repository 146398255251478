import React, { useContext, useState } from 'react';
import PropTypes from "prop-types";
import { Alert, Button, Col, Row, Spin } from 'antd';
import UploadFiles from '../../containers/UploadFiles';
import { dummyRequest, handlePublicDownloads } from '../../helpers/common';
import { alertMessage, getFileType } from '../../common/Common';
import { uploadAccountsCsv } from '../../api/Account';
import { Link } from '../../common/UIComponents';
import { WarehouseContext } from '../../context/WarehouseContext';
import I18n from '../../common/I18n';


function AccountUploadForm (props) {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ fileList, setFileList ] = useState([]);
  const [ errors, setErrors ] = useState([]);
  const [ currentFileInfo, setCurrentFileInfo ] = useState({});
  const warehouseContextData = useContext(WarehouseContext);
  const { selectedWarehouses } = warehouseContextData


  const handleFileChange = (info) => {
    setIsLoading(true);
    setErrors([]);

    const { status } = info.file;
    let uploadedFileList = [ ...info.fileList ];
    // in array case
    const fileNamesList = uploadedFileList.map((file) => {
      return file.name;
    });
    const filesType = fileNamesList.map((fileName) => {
      return getFileType(fileName);
    });
    if (filesType.some((item) => ![ "doc" ].includes(item))) {
      setErrors([ I18n.t('messages.account_file_type_err_msg') ]);
      setIsLoading(false);
    } else {
      if (status !== "uploading") {
        // info.fileList.splice(0,1);
        setCurrentFileInfo({ csv_file: info.file.originFileObj });
      }
      const updatedFileList = uploadedFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });

      setFileList(updatedFileList);
      setIsLoading(false);
    }
  };
  const draggerOptions = {
    name: "file",
    onChange: handleFileChange,
    multiple: false,
  };

  const handleSave = () => {
    setIsLoading(true);
    setErrors([]);
    const payload = {
      csv_file: currentFileInfo.csv_file,
      organization_id: props.org_id,
      warehouse_id: selectedWarehouses
    }
    uploadAccountsCsv(payload).then((result) => {
      if (result.success) {
        const { response } = result 
          alertMessage(I18n.t('messages.uploaded'), "success");
          setIsLoading(false);
          setFileList([]);
          setErrors(Array.isArray(response.errors) ? response.errors : []);
          props.handleMadeChanges();
      }
    }).catch((error) => {
      setIsLoading(false);
      setErrors([ error.message ]);
    }
    );
  };

  const buildErrorsElement = (errorsList) => {
    const errorsElement = errorsList.map((error, index) => {
      return (
        <div key={index}>
          <p style={{
            marginBottom: "2px",
          }}>{error}</p>
        </div>
      );
    }
    );
    return errorsElement;
  }

  return (
    <Spin spinning={ isLoading } delay={ 1000 }>
      <Row type='flex' gutter={ [ 4, 4 ] }>
        <Col xs={ 24 }>
          <Row type='flex' align='middle' justify='center' gutter={ 4 }>
            <Col>
              { I18n.t("order.sample_download_link") }
            </Col>
            <Col>
              :
            </Col>
            <Col>
              <Link onClick={ () => handlePublicDownloads("SampleAccountFile.csv") }>
                <span
                  className="anchor_cursor"
                  style={ { fontSize: "15px", fontWeight: 500 } }
                >
                  CSV
                </span>
              </Link>
            </Col>
          </Row>
        </Col>
        { errors.length > 0 && (<Col xs={ 24 }>
          <Alert
            description={ buildErrorsElement(errors) }
            type="error"
            closable
            onClose={ () => setErrors([]) }
          />
        </Col>
        ) }
        <Col xs={ 24 }>
          <UploadFiles
            draggerOptions={ draggerOptions }
            fileList={ fileList }
            showUploadList={ !(fileList.length > 0) }
            previewFile={ false }
            disabled={ fileList.length > 0 }
            customRequest={ dummyRequest }
            uploadText="Click or drag file to this area to upload"
            uploadHint="Support for bulk accounts upload."
          />
        </Col>
        <Col xs={ 24 }>
          <Row type='flex' justify='center' gutter={ 4 }>
            <Col>
              <Button icon='delete' onClick={ () => {setFileList([]) ,setErrors([])} }>
                { I18n.t('general.clear') }
              </Button>
            </Col>
            <Col>
              <Button type="danger" icon="close" onClick={() => {
                props.onCancel();
              }}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={handleSave} icon="save" disabled={
                fileList.length === 0
              }>
                Save
              </Button>
            </Col>
          </Row>
        </Col>

      </Row>
    </Spin>
  );
}

export default AccountUploadForm;
AccountUploadForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  org_id: PropTypes.string.isRequired,
  handleMadeChanges : PropTypes.func.isRequired,
};

AccountUploadForm.defaultProps = {
  onCancel: () => {},
  handleMadeChanges : () => {},
}
