import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, FormItem, Input, Row, Button } from '../../common/UIComponents';
import FormButtons from '../common/FormButtons';
import FormErrors from '../common/FormErrors';
import I18n from '../../common/I18n';

class ZoneForm extends Component {
  constructor(props) {
    super(props);
    this.state = { inProgress: false, errors: '' };
  }

  render() {
    return (
      <div className="zone-form">
        <Row>
          <Col>
            <FormItem label={I18n.t('zones.zonename')}>
              <Input
                placeholder="Enter Name"
                onChange={this.props.handleChange}
                value={this.props.zoneName}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        {
          this.props.currentZone.id ?
            <Row gutter={8}>
              <Col span={24} className="text-right">
                <Button className="zone-redraw" disabled={this.props.onDraw} onClick={this.props.handleReDraw}>
                  {I18n.t('zones.redraw')}
                </Button>
              </Col>
            </Row> :
            <Fragment />
        }
        <Row>
          <Col xs={24} id="zone-Map-Buttons">
            {FormButtons(this.state.inProgress, this.props.handleOnSave, this.props.handleOnClose)}
          </Col>
        </Row>

      </div>
    );
  }
}

ZoneForm.propTypes = {
  currentZone: PropTypes.shape().isRequired,
  zoneName: PropTypes.string.isRequired,
  onDraw: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleReDraw: PropTypes.func.isRequired,
};

ZoneForm.defaultPropTypes = {
  zone: {},
  isNew: true,
};

export default ZoneForm;
