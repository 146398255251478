import React from "react";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";


function DispatchStopOrders(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      hawb: "123456",
      mawb: "123456",
      items_count: 1,
      weight: 100,
      account_code: "ABC123",
      account_name: "abc123",
      los: "White Glove",
      // billing_number: 999,
      accessorials: "XYZ",
      pod: "Demo",
      // exception_message: "This is a test exception message",
      reference_1: "700214577",
      reference_2: "123456",
      status : "DISPATCHED",
      consignee : "Bob",
      company_name : "ABC123",
      routing : "ABCD"
    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      hawb: "123456",
      mawb: "123456",
      items_count: 2,
      weight: 400,
      account_code: "ABC456",
      account_name: "abc456",
      los: "Threshold",
      // billing_number: 447,
      accessorials: "XYZ",
      pod: "Demo",
      // exception_message: "This is a test exception message",
      reference_1: "700214577",
      reference_2: "123456",
      status : "COMPLETED",
      consignee : "Mary",
      company_name : "ABC123",
      routing : "EFGH"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "quantity",
      title: I18n.t("order.qty"),
      dataIndex: "items_count",
    },
    {
      key: "weight",
      title: I18n.t("order.weight"),
      dataIndex: "weight",
    },
    {
      key: "account_code",
      title: I18n.t("account.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "los",
    },
    // {
    //   key: "billing_number",
    //   title: "Bill Number",
    //   dataIndex: "billing_number",
    // },
    {
      key: "accessorials",
      title: I18n.t("general.accessorial"),
      dataIndex: "accessorials",
    },
    {
      key: "pod",
      title: "PoD",
      dataIndex: "pod",
    },
    // {
    //   key: "exception",
    //   title: "Exception Reason",
    //   dataIndex: "exception_message",
    // },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "status",
      title: I18n.t("order.status"),
      dataIndex: "status",
    },
    {
      key: "consignee",
      title: <EndUserTitle/>,
      dataIndex: "consignee",
    },
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = 'dispatch_routeOrders_columns'

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )
  
}

export default DispatchStopOrders;
