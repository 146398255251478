import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import AppConfig from "../../../config/AppConfig";
import {
  FormItem,
  DatePicker,
  Button,
  Radio,
  Select,
} from "../../../common/UIComponents";
import AccountCodesFilter from "../../../containers/Filters/AccountCodesFilter";
import I18n from "../../../common/I18n";
import { fetchOrgAccounts, fetchShortFormAccounts } from "../../../api/Account";
import WarehouseCodeFilter from "../../common/WarehouseCodeFilter";

const { RangePicker } = DatePicker;
const dateFormat = "Do MMM YYYY";

class PodReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || {},
      warehouses: this.props.warehouses || [],
      accountsLoading: true,
      accounts: [],
      filteredAccounts: [],
      accountsLoading: false,
    };
    this.isComponentMounted = false; // To track whether the component is mounted
  }

  componentDidMount() {
    this.isComponentMounted = true; // Component is now mounted
    this.getAccounts();
    const WarehouseFilter = this.props;
    if (!WarehouseFilter.isWarehouseMultiSelect) {
      this.setState({
        warehouses: WarehouseFilter.warehouses,
      });
    }
  }

  getAccounts = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState(
          {
            accounts: result.accounts || [],
            accountsLoading: false,
          },
          () => {
            this.handleFilterPlaceHolderChange("warehouse_id", currentWhId);
          }
        );
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  setFilteredAccounts = (filteredAccount = []) => {
    const { accountCodes } = this.state.filter;
    const filteredAccCodes =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.code) : [];

    if (accountCodes?.length) {
      const filteredAccountCodes = filteredAccCodes.filter((acc_code) =>
        accountCodes.includes(acc_code)
      );
      this.handleFilterPlaceHolderChange("accountCodes", filteredAccountCodes);
    } else {
      this.handleFilterPlaceHolderChange("accountCodes", []);
    }
  };

  componentWillUnmount() {
    this.isComponentMounted = false; // Component is unmounting, set the flag to false
  }

  componentDidUpdate(prevProps, prevState) {
    const { warehouseFilter } = this.props;
    if (
      warehouseFilter?.warehouses &&
      !_.isEqual(prevState.warehouses, warehouseFilter.warehouses)
    ) {
      this.setState({
        warehouses: warehouseFilter.warehouses || [],
      });
    }
    if (
      prevProps.warehouseFilter?.selectedWarehouses !==
      this.props.warehouseFilter?.selectedWarehouses
    ) {
      this.getAccounts();
    }
  }

  disabledDate = (current) =>
    // Can not select days before today and today
    current && current > moment().endOf("day");

  handleFilterPlaceHolderChange = (element, value) => {
    const filter = Object.assign({}, this.state.filter, {
      [element]: value,
    });

    this.setState(
      {
        filter,
      },
      () => {
        if (element === "warehouse_id") {
          const { accounts } = this.state;
          const { warehouses } = this.props.warehouseFilter;
          const { warehouse_id } = this.state.filter;

          if (warehouse_id.length === 0) {
            const filteredAccounts = accounts;
            this.setState({ filteredAccounts });
          } else {
            const filteredAccounts = accounts.filter((account) =>
              account.warehouse_ids.some((id) => warehouse_id.includes(id))
            );
            this.setState({ filteredAccounts }, () => {
              this.setFilteredAccounts(filteredAccounts);
            });
          }
        }
      }
    );
  };

  clearForm = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    const filter = {
      fromDate: moment().subtract(1, "day"),
      toDate: moment(),
      accountCodes: [],
      warehouse_id: currentWhId,
      orderType: "",
    };
    this.setState(
      {
        filter,
      },
      () => {
        this.handleSearch();
      }
    );
    // this.props.onChange(filter, () => {
    //   this.props.onCancel();
    // });
  };

  handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: moment().subtract(1, "day"),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.setState({
      filter: dateFilter,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.isComponentMounted) {
      this.setState({
        filter: nextProps.filter || {},
      });
    }
  }

  handleSearch = () => {
    this.props.onSearch(this.state.filter);
  };

  _renderOrderTypeFilter = () => {
    const { orderConfig } = this.props.orderConfigContext;
    const { orderTypeKeysForFilter = {} } = orderConfig;
    return (
      <FormItem label={I18n.t("order.type")}>
        <Select
          value={this.state.filter.orderType || ""}
          onChange={(e) => this.handleFilterPlaceHolderChange("orderType", e)}
          className="width100Per"
        >
          <Select.Option value="">-- All --</Select.Option>
          {Object.keys(orderTypeKeysForFilter).map((opt) => (
            <Select.Option value={opt}>
              {orderTypeKeysForFilter[opt]}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    );
  };

  render() {
    const { showAmountCheck = true } = this.props;
    return (
      <Fragment>
        <div>
          <FormItem label={I18n.t("location.location")}>
            <WarehouseCodeFilter
              SelectedCodes={this.state.filter.warehouse_id}
              onValueChange={this.handleFilterPlaceHolderChange}
              size="default"
            />
          </FormItem>
          <FormItem label={I18n.t("general.account")}>
            <AccountCodesFilter
              SelectedAccountCodes={this.state.filter.accountCodes}
              accounts={this.state.filteredAccounts}
              size="default"
              onValueChange={this.handleFilterPlaceHolderChange}
              showLabel={false}
              valueField="code"
              styleObject={{ width: "100%", marginBottom: 5 }}
            />
          </FormItem>
          {this._renderOrderTypeFilter()}
          <FormItem label={"Date Range"}>
            <RangePicker
              defaultValue={[moment().startOf("month"), moment()]}
              value={[this.state.filter.fromDate, this.state.filter.toDate]}
              ranges={AppConfig.dateRanges}
              format={dateFormat}
              style={{ width: "100%" }}
              onChange={(dates, dateStrings) =>
                this.handleRangeChange(dates, dateStrings)
              }
              disabled={
                this.state.filter[
                  I18n.t("order.filters.schedule_orders.type")
                ] === I18n.t("order.filters.schedule_orders.unscheduled_key")
              }
            />
          </FormItem>
          <div
            style={{
              padding: "10px 16px",
              textAlign: "center",
            }}
          >
            <Button type="danger" onClick={this.clearForm}>
              Reset
            </Button>{" "}
            &nbsp;&nbsp;
            <Button onClick={this.handleSearch} type="primary">
              Search
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

PodReportFilter.propTypes = {
  filter: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onOrderNoSearch: PropTypes.func,
  warehouses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
PodReportFilter.defaultProps = {
  filter: {},
  onOrderNoSearch: null,
};
export default withRouter(PodReportFilter);
