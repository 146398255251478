import React, { useContext, useEffect, useState } from "react";
import {
  Tooltip,
  Popconfirm,
} from "../../common/UIComponents";
import { Button, Col, Form, Icon, Input, Row, Spin } from "antd";
import DeleteIcon from "../common/DeleteIcon";
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";
import { alertMessage } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { RejectedReasonApi } from "../../api/RejectedReason";
import { renderAlertMessage } from "../../helpers/common";
const RejectionReason = (props) => {
  const [approvalRejections, setApprovalRejections] = useState([]);
  const [editableId, setEditableId] = useState(null);
  const [editedRejectionMessage, setEditedRejectionMessage] = useState("");
  const [editedRejectionCode, setEditedRejectionCode] = useState("");
  const [showNewRow, setShowNewRow] = useState(false);
  const [newRowRejectionMessage, setNewRowRejectionMessage] = useState("");
  const [newRowRejectionCode, setNewRowRejectionCode] = useState("");
  const [messageError, setMessageError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [showAddButton, setShowAddButton] = useState(true);
  const orgContextData = useContext(OrgContext);
  const { currentOrg } = orgContextData;
  const [inProgress, setInProgress] = useState(false);

  const getRejectionReason = () => {
    const organization_id = currentOrg.id;
    setInProgress(true);
    RejectedReasonApi.fetchRejectedReason(organization_id).then((result) => {
      if (result.success) {
        setApprovalRejections(
          result.rejection_messages ? result.rejection_messages : [],
          setInProgress(false)
        );
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  const handleEdit = (id) => {
    setEditableId(id);
    const itemToEdit = approvalRejections.find((item) => item.id === id);
    if (itemToEdit) {
      setEditedRejectionMessage(itemToEdit.rejection_message);
      setEditedRejectionCode(itemToEdit.rejection_code);
    }
    setShowNewRow(false);
  };
  const handleSave = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const payLoad = {
      rejection_message: editedRejectionMessage,
      rejection_code: editedRejectionCode,
    };
    setInProgress(true);
    RejectedReasonApi.updateRejectedReason(id, payLoad, organization_id).then(
      (result) => {
        if (result.success) {
          setInProgress(false);
          setApprovalRejections((prevState) =>
            prevState.map((item) =>
              item.id === id
                ? {
                    ...item,
                    rejection_message: editedRejectionMessage, //result.data.rejection_messages.map(message => message.rejection_message),
                    rejection_code: editedRejectionCode, // result.data.rejection_messages.map(code => code.rejection_code)
                  }
                : item
            )
          );
          alertMessage("Updated Successfully","success",5)
        } else {
          renderAlertMessage(result.errors)
        }
      }
    );
    setEditableId(null);
  };
  const handleCancelEdit = () => {
    setEditableId(null);
    if (showNewRow) {
      if (editableId === null) {
        setNewRowRejectionMessage("");
        setNewRowRejectionCode("");
        setMessageError("");
        setCodeError("");
        setShowAddButton(true);
      }
      setShowNewRow(false);
    }
  };

  useEffect(() => {
    if (approvalRejections.length === 0) {
      setShowAddButton(true);
    }
  }, [approvalRejections]);

  const handleDelete = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    setInProgress(true);
    RejectedReasonApi.deleteRejectedReason(id, organization_id).then(
      (result) => {
        if (result.success) {
          setInProgress(false);
          const updatedList = approvalRejections.filter(
            (item) => item.id !== id
          );
          setApprovalRejections(updatedList);
          alertMessage(result.data.message, "success", 5);
        } else {
          renderAlertMessage(result.errors)
        }
      }
    );
  };
  const handleSaveNewRow = () => {
    if (
      newRowRejectionMessage.trim() === "" ||
      newRowRejectionCode.trim() === ""
    ) {
      if (newRowRejectionMessage.trim() === "") {
        setMessageError(`${I18n.t("errors.message_error")}`);
      } else {
        setMessageError("");
      }
      if (newRowRejectionCode.trim() === "") {
        setCodeError(`${I18n.t("errors.code_error")}`);
      } else {
        setCodeError("");
      }
    } else {
      const newRow = {
        rejection_message: newRowRejectionMessage,
        rejection_code: newRowRejectionCode,
        organization_id: userStore.getStateValue("selectedOrg"),
      };
      setInProgress(true);
      RejectedReasonApi.saveRejectedReason(newRow).then((result) => {
        if (result.success) {
          setInProgress(false);
          setApprovalRejections((prevState) => [
            ...prevState,
            result.data.rejection_messages,
          ]);
        } else {
          renderAlertMessage(result.errors)
        }
      });
      setNewRowRejectionMessage("");
      setNewRowRejectionCode("");
      setShowNewRow(false);
      setCodeError("");
      setMessageError("");
    }
  };
  useEffect(() => {
    getRejectionReason();
  }, []);

  const handleAddNewRow = () => {
    setShowNewRow(true);
    setNewRowRejectionMessage("");
    setNewRowRejectionCode("");
    setMessageError("");
    setCodeError("");
    setShowAddButton(false);
  };

  const renderRejectionTable = () => {
    return (
      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col span={5}>
          <strong style={{ paddingLeft: "10px" }}>Rejection Code</strong>
        </Col>
        <Col span={13}>
          <strong style={{ paddingLeft: "15px" }}>
            {I18n.t("general.rejection_message")}
          </strong>
        </Col>
        <Col span={6}>
          {/* {showAddButton && approvalRejections.length === 0 && ( */}
            <Button type="primary" onClick={handleAddNewRow} icon="plus" size="small">
              Add New Reason
            </Button>
          {/* )} */}
        </Col>
      </Row>
    );
  };

  const renderInputs = () => {
    return (
      <>
        {approvalRejections.map(
          (item, index) =>
            item && (
              <Row gutter={[16, 16]} key={item.id || item._id}>
                <Col span={5}>
                  {editableId === item.id ? (
                    <Input
                      value={editedRejectionCode}
                      onChange={(e) =>
                        setEditedRejectionCode(e.target.value.toUpperCase())
                      }
                    />
                  ) : (
                    <Input value={item.rejection_code} disabled />
                  )}
                </Col>
                <Col span={13}>
                  {editableId === item.id ? (
                    <Input
                      value={editedRejectionMessage}
                      onChange={(e) =>
                        setEditedRejectionMessage(e.target.value)
                      }
                    />
                  ) : (
                    <Input value={item.rejection_message} disabled />
                  )}
                </Col>
                <Col span={6} style={{padding:'15px'}}>
                  {editableId === item.id ? (
                    <>
                      <Tooltip title={I18n.t("general.save")}>
                        <Icon
                          type="check-circle"
                          style={{ color: "green" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleSave(item.id)}
                        />
                      </Tooltip>
                      <Tooltip title={I18n.t("general.cancel")}>
                        <Icon
                          type="close-circle"
                          style={{ color: "red" }}
                          onClick={() => handleCancelEdit()}
                          size="small"
                          className="marginLeft10 fontSize18"
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={I18n.t("general.edit")}>
                        <Icon
                          type="edit"
                          style={{ color: "blue", marginRight: "10px" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleEdit(item.id)}
                        />
                      </Tooltip>
                      <Popconfirm
                        placement="left"
                        title={I18n.t("messages.confirm")}
                        onConfirm={() => handleDelete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon className="textBold fontSize18" />
                        &nbsp;&nbsp;&nbsp;
                      </Popconfirm>
                    </>
                  )}
                  {!showNewRow && approvalRejections.length - 1 === index && (
                    <>
                      {editableId ? null : (
                        <Tooltip title={I18n.t("general.add")}>
                          <Icon
                            type="plus-circle"
                            size="small"
                            style={{ marginTop: 7, color: "black" }}
                            className="marginLeft10 fontSize18"
                            onClick={() => setShowNewRow(true)}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )
        )}
      </>
    );
  };

  const renderNewRow = () => {
    return (
      <>
        {showNewRow && (
          <>
            {editableId ? null : (
              <Row gutter={[16, 16]} key="new-row">
                <Col span={5}>
                  <Input
                    placeholder="Rejection code"
                    value={newRowRejectionCode}
                    onChange={(e) =>
                      setNewRowRejectionCode(e.target.value.toUpperCase())
                    }
                    autoFocus
                  />
                  {codeError && <div style={{ color: "red" }}>{codeError}</div>}
                </Col>
                <Col span={13} className="right-aligned">
                  <Input
                    placeholder="Rejection Message"
                    value={newRowRejectionMessage}
                    onChange={(e) => setNewRowRejectionMessage(e.target.value)}
                  />
                  {messageError && (
                    <div style={{ color: "red" }}>{messageError}</div>
                  )}
                </Col>
                <Col span={6} style={{padding:"15px"}}>
                  <Tooltip title={I18n.t("general.save")}>
                    <Icon
                      type="check-circle"
                      style={{ color: "green" }}
                      size="small"
                      className="marginLeft10 fontSize18"
                      onClick={() => handleSaveNewRow()}
                    />
                  </Tooltip>
                  <Tooltip title={I18n.t("general.cancel")}>
                    <Icon
                      type="close-circle"
                      size="small"
                      style={{ color: "red" }}
                      className="marginLeft10 fontSize18"
                      onClick={() => handleCancelEdit()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  };

  const renderRejectReason = () => {
    return (
      <div>
        <Row style={{ margin: "0px 10px 10px 10px" }}>
          {renderRejectionTable()}
          {renderInputs()}
          {renderNewRow()}
        </Row>
      </div>
    );
  };

  return (
    <Spin spinning={inProgress} delay={1000}>
      <Row>
        <Col>
          <div style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}>
            {renderRejectReason()}
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default RejectionReason;
