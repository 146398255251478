
import React, { Component, useContext, } from "react";
import _ from "lodash";
import { Col, Row, Search, Select, Spin, Tooltip } from "../../../common/UIComponents";
import { alertMessage, isEmpty, navigateToAccount,} from "../../../common/Common";
import AppConfig from "../../../config/AppConfig";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../../context/WarehouseContext";
import PaymentJournalList from "./PaymentJournalList";
import { PaymentApi } from "../../../api/PaymentReportsApi";
import { AccountSelect } from "../../common/SelectDropdowns/BaseSelect";
import { fetchShortFormAccounts } from "../../../api/Account";
import { base64ToExcel, removeSpecialCharacters, renderAlertMessage } from "../../../helpers/common";
import { OrgContext } from "../../../context/OrgContext";
import I18n from "../../../common/I18n";
import { Button, Icon } from "antd";


class PaymentJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentReports: [],
      pagination: {},
      accounts: [],
      search_order_token: "",
      searchKeyPress: false,
      searchBy: "Invoice",
      orgId:this.props.orgData.currentOrg.id,
      wh_location_id: this.props.warehouseData.selectedWarehouses,
      inProgress: false,
      isLoading:false,
      account_id: "",
      recordsPerPage: AppConfig.ordersPerPage,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }
  componentDidMount() {
    this.getPaymentReports();
    this.getAccounts();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseData.selectedWarehouses,
        this.props.warehouseData.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseData.warehouses,
        this.props.warehouseData.warehouses
      ) &&
        !this.props.warehouseData.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      this.getPaymentReports();
      this.getAccounts();
    }

  }

  handleExport = () => {
    const currentOrg = this.props.orgData.currentOrg;
    const { selectedWarehouses } = this.props.warehouseData;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const data = {
      organization_id: this.state.orgId,
      page: this.tableOptions.pagination.current,
      perPage: this.state.recordsPerPage,
      account_id: this.state.account_id,
      whId: selectedwh,
      searchKey: this.state.searchBy,
      searchValue: this.state.search_order_token,
    }
    this.setState({ isLoading: true })
    const isExport = true
    PaymentApi.fetchPaymentReports(data,isExport).then((result) => {
      if(result.success) {
        const message = result.data?.message || "";
        if (isEmpty(message)) {
          base64ToExcel(
            result.data,
            `${currentOrg.code}_${moment().format("MM-DD-YYYY_hh-mm-ss")}_payment_journal_report`,
            "EXCEL"
          );
          this.setState({isLoading: false})
        }
        else {
          alertMessage(message, "error", 10)
          this.setState({ isLoading: false })
        }
      }else {
        alertMessage(result.errors, "error", 5)
        this.setState({isLoading: false})
      }
    })
  }
  getPaymentReports = () => {
    const { selectedWarehouses } = this.props.warehouseData;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const data = {
      organization_id: this.state.orgId,
      page: this.tableOptions.pagination.current,
      perPage: this.state.recordsPerPage,
      account_id: this.state.account_id,
      whId: selectedwh,
      searchKey: this.state.searchBy,
      searchValue: this.state.search_order_token,
    }
    this.setState({ inProgress: true })
    PaymentApi.fetchPaymentReports(data).then((result) => {
      if (result.success) {
        const paymentReports = result.data.payment_journal_reports || [];
        this.setState({
          paymentReports: paymentReports,
          pagination: result?.data?.pagination?.pagination ?? {},
          inProgress: false,


        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          inProgress: false,
          paymentReports: [],
        });
      }
    }).finally(() => {
      this.setState({ inProgress: false })
    });
  }

  getAccounts = (
    page = this.tableOptions.pagination.current,
    organization_id = this.state.orgId,
    whId = this.state.wh_location_id
  ) => {
    this.setState({
      inProgress: true,

    })
    fetchShortFormAccounts(organization_id, whId, false).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts ? result.accounts : [],
          inProgress: false,

        })
      } else {
        this.setState({
          inProgress: false,
        })
        renderAlertMessage(result.errors)
      }
    }
    )
  }
  handleAccountChange = (value) => {
    this.setState({
      account_id: value,
      page: this.tableOptions.pagination.current = 1,
      search_order_token: ""
    }, () => {
      this.getAccounts()
      this.getPaymentReports()
    })
  }

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getPaymentReports();
    // } else if (sorter && !isEmpty(sorter.field)) {
    //   this.setState(
    //     {
    //       filters: Object.assign({}, this.state.filters, {
    //         sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "invoice_date",
    //         sortOrder: !isEmpty(sorter.order) ? sorter.order : "descend",
    //       }),
    //     },
    //     () => {
    //       this.getPaymentReports()
    //     }
    //   );
    }
  };

  onRecordChange = (value) => {
    if (!value) {
      value = AppConfig.ordersPerPage;
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getPaymentReports();
      }
    );
  };
  _renderMainSearchBar = () => {
    const { searchBy } = this.state;
    return (
      <Search
        placeholder={
          searchBy === "Invoice" ? "Search Invoice No" : `Search ${I18n.t("general.by_check")} no`
        }
        className="invoice-holder"
        size="default"
        value={
          !isEmpty(this.state.search_order_token)
            ? this.state.search_order_token.toUpperCase()
            : ""
        }
        onSearch={(value) => {
          this.setState(
            {
              search_order_token: value,
              searchKeyPress: value.length > 0 ? true : false,
            },
          );
          this.getPaymentReports()
        }}
        onChange={(e) => {
          // const filteredString = removeSpecialCharacters(e.target.value);
          const filteredString = e.target.value;
          this.setState({ search_order_token: filteredString });
        }}
        style={{ width: "100%", textTransform: "uppercase" }}
        prefix={
          <Row
            type="flex"
            align="middle"
            className="invoiceSearchPrefixes"
            gutter={4}
          >
            <Col>
              <Select
                size="small"
                onChange={(e) => {
                  this.setState({
                    search_order_token: "",
                    searchBy: e,
                  });
                }}
                value={this.state.searchBy}
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "1",
                }}
                defaultValue={searchBy === "Invoice" ? "Invoice" : "Check"}
              >
                <Select.Option key="Invoice" value="Invoice">
                  {I18n.t("general.by_invoice").toUpperCase()}
                </Select.Option>
                <Select.Option key="Check" value="Check">
                  {I18n.t("general.by_check").toUpperCase()}
                </Select.Option>
              </Select>
            </Col>
          </Row>
        }
      />
    );
  };

  _renderTab = () => {
    const { account_id, accounts } = this.state

    return (
      <Row>
        <Col xs={6} >
          <span className="textBold">{I18n.t("general.account")}:</span>
          &nbsp;&nbsp;
          <AccountSelect
            onChange={this.handleAccountChange}
            data={accounts}
            size="default"
            element="selectedAccounts"
            selectKey="id"
            selectValue="id"
            selectedCode="code"
            selectedValue="name"
            style={{ width: "75%" }}
            value={account_id}
            showSearch={true}
            allowClear={true}
          />
        </Col>
        <Row type="flex" justify="end">
          <Col className="alignRight" style={{ marginBottom: 5, marginRight: 10 }}>
            <Button
              className="cursorPointer"
              type="primary"
              disabled={this.state.isLoading}
              loading={this.state.isLoading}
              onClick={this.handleExport}
              shape="round"
              size="small"
            >
              {this.state.isLoading ? (
                "Exporting..."
              ) : (
                <span>
                  Export EXCEL <Icon type="file-excel" theme="filled" />
                </span>
              )}
            </Button>
          </Col>
          <Col>
            <RecordsPerPage
              onChange={this.onRecordChange}
              value={this.state.recordsPerPage}
              defaultValue={AppConfig.ordersPerPage}
            />
          </Col>
        </Row>
        

      </Row>
    )
  }

  render() {
    const pagination = {
      total: this.state.pagination.total_count || "",
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress}>
          <Row className="page-header" type="flex" justify="space-between">
            <Col>{I18n.t("payment.journal")}</Col>
            <Col><this._renderMainSearchBar /></Col>
          </Row>
          <div style={{ marginTop: 15 }}>
            <this._renderTab />
            <Row>
              <Col className="accounts" style={{ marginTop: 15 }} >
                <PaymentJournalList
                  scroll={{ y: "calc(100vh - 280px)", x: "max-content" }}
                  navigateToAccount={(account_id) =>
                    navigateToAccount(this.state.accounts, account_id, this.props.history)
                  }
                  data={this.state.paymentReports ? this.state.paymentReports : []}
                  pagination={pagination}
                  tableChange={(pagination, filters, sorter) =>
                    this.onTableChange(pagination, filters, sorter)

                  }
                />
              </Col>
            </Row>
          </div>
          </Spin>
        </div>
      </div>
    );
  }
}
export const PaymentComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext)
  return <PaymentJournal warehouseData={warehouseData} orgData={orgData} {...props} />;
});


export default PaymentComponent;
