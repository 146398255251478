import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Radio,
  Icon,
  Spin,
  Card,
} from '../../common/UIComponents';
import DriverRateConfigurationList from './DriverRateConfigurationList';
import { isEmpty, alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import DriverRateForm from './DriverRateForm';
import { fetchDriverRatesConfigurationDetails, saveDriverRate, saveDriverRateConfigurations } from '../../api/DriverRatesApi';
import { renderAlertMessage } from '../../helpers/common';

class DriverRateConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverRateDetails: this.props.driverRateDetails,
      transportRateDetails: [{
        charge_type: 'percentage',
        charge_value: '',
        accessorial_name: 'Transportation charges',
        // driver_rate_code: '',
        // driver_rate_id: '',
        // driver_rate_name: '',
      }],
      currentDriverRate: this.props.currentDriverRate || {},
      driverRatesCommonForm: this.props.driverRatesCommonForm || { charge_type: 'percentage', charge_value: '0' },
      isNew: this.props.isNew,
      isDriverRateDetailsLoading: false,
      masterAccessorials: this.props.masterAccessorials || [],
      driverRateConfigurationFormId: this.props.isNew ? '' : this.props.currentDriverRate.id,
      driverRatesList: this.props.driverRatesList || [],
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.getDriverRateConfigurations(this.state.currentDriverRate.id);
    }
  }

  getAccessorialCode = (accessorial_master_id) => {
    const { masterAccessorials } = this.state;
    const accessorial = masterAccessorials.find(accessrl => accessrl.id === accessorial_master_id);
    if (accessorial) {
      return accessorial.code;
    }
    return null;
  }

  // getDriverRateConfigurations = (driver_rate_id) => {
  //   this.setState({ isDriverRateDetailsLoading: true });
  //   fetchDriverRatesConfigurationDetails(driver_rate_id)
  //     .then((result) => {
  //       if (result.success && result.driver_rate_details && result.driver_rate_details.length > 0) {
  //         const transportRateDetails = result.driver_rate_details
  //           .filter(rate => isEmpty(rate.accessorial_master_id))
  //           .map(rate => Object.assign({}, rate, { accessorial_name: 'Transportation charges' }));
  //         if (transportRateDetails.length > 0) {
  //           this.setState({
  //             transportRateDetails,
  //           });
  //         }

  //         const accessorialRateDetails = result.driver_rate_details.filter(rate => !isEmpty(rate.accessorial_master_id));
  //         accessorialRateDetails.forEach((driverRate) => {
  //           const driverRateDetails = this.state.driverRateDetails;
  //           const dvRateDetails = driverRateDetails.find(dvRate => driverRate.accessorial_master_id === dvRate.accessorial_master_id);
  //           const dvRateDetailsExpt = driverRateDetails.filter(dvRate => driverRate.accessorial_master_id !== dvRate.accessorial_master_id);
  //           if (dvRateDetails) {
  //             dvRateDetails.accessorial_master_id = driverRate.accessorial_master_id;
  //             dvRateDetails.accessorial_name = driverRate.accessorial_name;
  //             dvRateDetails.accessorial_code = driverRate.accessorial_code ? driverRate.accessorial_code : this.getAccessorialCode(driverRate.accessorial_master_id);
  //             dvRateDetails.charge_type = driverRate.charge_type;
  //             dvRateDetails.charge_value = driverRate.charge_value;
  //             dvRateDetails.driver_rate_code = driverRate.driver_rate_code;
  //             dvRateDetails.driver_rate_id = driverRate.driver_rate_id;
  //             dvRateDetails.driver_rate_name = driverRate.driver_rate_name;
  //             this.setState({
  //               driverRateDetails: [dvRateDetails, ...dvRateDetailsExpt],
  //             });
  //           }
  //         });
  //       }
  //       this.setState({ isDriverRateDetailsLoading: false });
  //     });
  // }

  getDriverRateConfigurations = (driver_rate_id) => {
    this.setState({ isDriverRateDetailsLoading: true });
    fetchDriverRatesConfigurationDetails(driver_rate_id)
      .then((result) => {
        if (result.success && result.driver_rate_details && result.driver_rate_details.length > 0) {
          const transportRateDetails = result.driver_rate_details
            .filter(rate => isEmpty(rate.accessorial_master_id))
            .map(rate => Object.assign({}, rate, { accessorial_name: 'Transportation charges' }));
          const newState = {};
          if (transportRateDetails.length > 0) {
            newState.transportRateDetails = transportRateDetails;
          }
          const driverRateDetails = [...this.state.driverRateDetails];
          const accessorialRateDetails = result.driver_rate_details.filter(rate => !isEmpty(rate.accessorial_master_id));
          accessorialRateDetails.forEach((driverRate) => {
            const dvRateDetailsIndex = _.findIndex(driverRateDetails, ['accessorial_master_id', driverRate.accessorial_master_id]);
            if (dvRateDetailsIndex >= 0) {
              const dvRateDetails = driverRateDetails[dvRateDetailsIndex];
              dvRateDetails.accessorial_master_id = driverRate.accessorial_master_id;
              dvRateDetails.accessorial_name = driverRate.accessorial_name;
              dvRateDetails.accessorial_code = driverRate.accessorial_code ? driverRate.accessorial_code : this.getAccessorialCode(driverRate.accessorial_master_id);
              dvRateDetails.charge_type = driverRate.charge_type;
              dvRateDetails.charge_value = driverRate.charge_value;
              dvRateDetails.driver_rate_code = driverRate.driver_rate_code;
              dvRateDetails.driver_rate_id = driverRate.driver_rate_id;
              dvRateDetails.driver_rate_name = driverRate.driver_rate_name;
              driverRateDetails[dvRateDetailsIndex] = dvRateDetails;
            }
          });
          newState.driverRateDetails = [...driverRateDetails];
          this.setState(newState);
        }
        this.setState({ isDriverRateDetailsLoading: false });
      });
  }

  handleDriverRateInput = (key_name, value, accessorial_master_id, accessorial_type) => {
    if (accessorial_type === 'Accessorial') {
      const driverRateDetails = this.state.driverRateDetails;
      const driverRateIndex = _.findIndex(driverRateDetails, ['accessorial_master_id', accessorial_master_id]);
      if (driverRateIndex >= 0) {
        const driverRate = driverRateDetails[driverRateIndex];
        driverRate[key_name] = value;
        if (this.state.driverRateConfigurationFormId) {
          const dvrRate = this.state.driverRatesList.find(driverRate => driverRate._id === this.state.driverRateConfigurationFormId);
          if (driverRate) {
            driverRate.driver_rate_id = dvrRate._id;
            driverRate.driver_rate_code = dvrRate.code;
            driverRate.driver_rate_name = dvrRate.name;
          }
        }
        driverRateDetails[driverRateIndex] = driverRate;
        this.setState({
          driverRateDetails: [...driverRateDetails],
        });
      }
    } else {
      const rateDetails = this.state.transportRateDetails;
      const driverRate = rateDetails.length > 0 ? rateDetails[0] : '';
      if (driverRate) {
        driverRate[key_name] = value;
        if (this.state.driverRateConfigurationFormId) {
          const dvrRate = this.state.driverRatesList.find(driverRate => driverRate._id === this.state.driverRateConfigurationFormId);
          if (driverRate) {
            driverRate.driver_rate_id = dvrRate._id;
            driverRate.driver_rate_code = dvrRate.code;
            driverRate.driver_rate_name = dvrRate.name;
          }
        }
      }
      this.setState({
        transportRateDetails: [driverRate],
      });
    }
  }

  handleDriverRateConfigurationSaveAll = () => {
    if (!isEmpty(this.state.driverRatesCommonForm) && !isEmpty(this.state.driverRatesCommonForm.charge_value)) {
      const driverRatesCommonForm = { ...this.state.driverRatesCommonForm };
      if (isEmpty(driverRatesCommonForm).charge_type) {
        driverRatesCommonForm.charge_type = 'percentage';
      }
      const driverRate = this.state.driverRatesList.find(driverRate => driverRate.id === this.state.driverRateConfigurationFormId);
      if (driverRate) {
        const newDriverRateDetails = [];
        this.state.driverRateDetails.forEach((dRate) => {
          const newDRate = {};
          newDRate.accessorial_master_id = dRate.accessorial_master_id;
          newDRate.accessorial_name = dRate.accessorial_name;
          newDRate.accessorial_code = dRate.accessorial_code ? dRate.accessorial_code : this.getAccessorialCode(dRate.accessorial_master_id);
          newDRate.charge_type = this.state.driverRatesCommonForm.charge_type;
          newDRate.charge_value = this.state.driverRatesCommonForm.charge_value;
          newDRate.driver_rate_code = driverRate.code;
          newDRate.driver_rate_id = driverRate.id;
          newDRate.driver_rate_name = driverRate.name;
          newDRate.accessorial_type = 'Accessorial';
          newDRate.apply_on= driverRate.apply_on; //written here .... dont knwo yrr .... i have done which i haveodea
          newDriverRateDetails.push(newDRate);
        });
        const transportRate = {};
        transportRate.accessorial_master_id = '';
        transportRate.accessorial_name = '';
        transportRate.accessorial_code = '';
        transportRate.charge_type = this.state.driverRatesCommonForm.charge_type;
        transportRate.charge_value = this.state.driverRatesCommonForm.charge_value;
        transportRate.driver_rate_code = driverRate.code;
        transportRate.driver_rate_id = driverRate.id;
        transportRate.driver_rate_name = driverRate.name;
        transportRate.accessorial_type = 'Transportation';
        newDriverRateDetails.push(transportRate);
        if (newDriverRateDetails.length > 0) {
          const data = {
            configurations: newDriverRateDetails,
          };
          saveDriverRateConfigurations(data)
            .then((result) => {
              if (result.success) {
                alertMessage('Saved Successfully!');
                this.getDriverRateConfigurations(this.state.driverRateConfigurationFormId);
              } else {
                renderAlertMessage(result.errors)
              }
            });
        }
      }
    } else {
      if (isEmpty(this.state.driverRatesCommonForm.charge_type)) {
        alertMessage('Please provide charge type', 'error', 10);
      }

      if (isEmpty(this.state.driverRatesCommonForm.charge_value)) {
        alertMessage('Please provide charge value', 'error', 10);
      }
    }
  }

  handleDriverRateConfigurationsSingleSave = () => {
    const driverRates = [...this.state.driverRateDetails];
    const driverRate = this.state.transportRateDetails.length > 0 ? this.state.transportRateDetails[0] : {};
    driverRate.accessorial_type = 'Transportation';
    driverRates.push(driverRate);
    if (driverRates) {
      const data = {
        configurations: driverRates,
      };
      saveDriverRateConfigurations(data)
        .then((result) => {
          if (result.success) {
            alertMessage('Saved Successfully!');
            this.getDriverRateConfigurations(this.state.driverRateConfigurationFormId);
          } else {
            renderAlertMessage(result.errors)
          }
        });
    }
  }

  handleDriverRateConfigurationSave = (accessorial_master_id, accessorial_type) => {
    if (accessorial_type === 'Accessorial') {
      const driverRate = this.state.driverRateDetails.find(driverRate => driverRate.accessorial_master_id === accessorial_master_id);
      if (driverRate) {
        const data = {
          configurations: [driverRate],
        };
        saveDriverRateConfigurations(data)
          .then((result) => {
            if (result.success) {
              alertMessage('Saved Successfully!');
              this.getDriverRateConfigurations(this.state.driverRateConfigurationFormId);
            } else {
              renderAlertMessage(result.errors)
            }
          });
      }
    } else {
      const driverRate = this.state.transportRateDetails.length > 0 ? this.state.transportRateDetails[0] : {};
      driverRate.accessorial_type = 'Transportation';
      if (driverRate) {
        const data = {
          configurations: [driverRate],
        };
        saveDriverRateConfigurations(data)
          .then((result) => {
            if (result.success) {
              alertMessage('Saved Successfully!');
              this.getDriverRateConfigurations(this.state.driverRateConfigurationFormId);
            } else {
              renderAlertMessage(result.errors)
            }
          });
      }
    }
  }

  handleDriverRateClear = () => {
    this.setState({
      currentDriverRate: Object.assign({}, this.state.currentDriverRate, { name: '', code: '' }),
    });
  }

  handleDriverRateFormInputChage = (element, value) => {
    if (element === 'code') {
      value = value.toUpperCase();
    }
    this.setState({
      currentDriverRate: Object.assign({}, this.state.currentDriverRate, { [element]: value }),
    });
  }

  handleDriverRateSave = () => {
    saveDriverRate(this.state.currentDriverRate)
      .then((result) => {
        if (result.success) {
          alertMessage('Saved Successfully!');
          const currentDriverRate = result.driver_rate || {};
          const isNew = this.state.isNew;
          const driverRatesList = [...this.state.driverRatesList];
          if (result.driver_rate) {
            driverRatesList.push(currentDriverRate);
          }
          this.setState({
            isNew: false,
            currentDriverRate,
            driverRateConfigurationFormId: currentDriverRate.id ? currentDriverRate.id : '',
            driverRatesList,
          }, () => {
            if (isNew) {
              this.getDriverRateConfigurations(this.state.currentDriverRate.id);
            }
          });
        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  handleCommonDriverRate = (element, value) => {
    this.setState({
      driverRatesCommonForm: Object.assign({}, this.state.driverRatesCommonForm, {
        [element]: value,
      }),
    });
  }

  _renderCommonAccessorialForm = () => {
    const { driverRatesCommonForm, decimalPoints } = this.state;
    return (
      <Row gutter={8}>
        <Col xs={12}>
          <FormItem label="Rate Type">
            <Radio.Group
              onChange={e => this.handleCommonDriverRate('charge_type', e.target.value)}
              value={driverRatesCommonForm.charge_type || 'percentage'}
            >
              <Radio value="percentage">Percentage</Radio>
              <Radio value="flat">Flat</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label="Rate Value">
            {/* <span className="chargeValueTxt">{extraMenForm.charge_type === 'percentage' ? '(Based on Transportation Charges)' : null}</span> */}
            <Input
              addonBefore={driverRatesCommonForm.charge_type === 'flat' ? <Icon type="dollar" /> : null}
              addonAfter={isEmpty(driverRatesCommonForm.charge_type) || driverRatesCommonForm.charge_type === 'percentage' ? <Icon type="percentage" /> : null}
              type="number"
              value={parseFloat(driverRatesCommonForm.charge_value)}
              onChange={e => this.handleCommonDriverRate('charge_value', e.target.value)}
              min={0}
              precision={decimalPoints}
              defaultValue={0}
              size="small"
            />
          </FormItem>
        </Col>
        <Col xs={24}>
          <div
          // style={{
          //   display: 'flex', height: '100%', alignItems: 'center', alignContent: 'center',
          // }}
            className="alignCenter"
          >
            <Button
              type="primary"
              onClick={() => this.handleDriverRateConfigurationSaveAll()}
              disabled={this.state.isNew}
              icon='check-circle'
            >
              Apply for all
            </Button>
          </div>
        </Col>
      </Row>);
  }

  render() {
    const { driverRateDetails, transportRateDetails } = this.state;
    return (
      <React.Fragment>
        <Row gutter={32}>
          <Col xs={12}>
            <Card
              size="small"
              title="Driver Rate"
              bodyStyle={{ textAlign: 'left', padding: 10 }}
            >
              <DriverRateForm
                driverRate={this.state.currentDriverRate}
                handleDriverRateFormInputChage={(element, value) => this.handleDriverRateFormInputChage(element, value)}
                isNew={this.state.isNew}
                handleDriverRateSave={() => this.handleDriverRateSave()}
                handleDriverRateClear={() => this.handleDriverRateClear()}
              />
            </Card>
          </Col>
          <Col xs={12}>
            <Card
              size="small"
              title="Accessorial Settings"
              bodyStyle={{ textAlign: 'left', padding: 10 }}
            >
              <this._renderCommonAccessorialForm />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 20 }} />
        </Row>
        <Spin spinning={this.state.isDriverRateDetailsLoading} >
          <div style={{ marginTop: 25 }}>
            <DriverRateConfigurationList
              data={transportRateDetails || []}
              handleDriverRateInput={this.handleDriverRateInput}
              handleDriverRateConfigurationSave={this.handleDriverRateConfigurationSave}
              size="small"
              pagination={{ position: 'none' }}
              displayType="Transportation"
              isDisabled={this.state.isNew}

            />
          </div>
          <div style={{ marginTop: 25 }}>
            <DriverRateConfigurationList
              data={driverRateDetails || []}
              handleDriverRateInput={this.handleDriverRateInput}
              handleDriverRateConfigurationSave={this.handleDriverRateConfigurationSave}
              size="small"
              pagination={{ position: 'none' }}
              displayType="Accessorial"
              isDisabled={this.state.isNew}

            />
          </div>
          <div className="alignCenter marginTop20">
            <Button type="primary" onClick={this.handleDriverRateConfigurationsSingleSave}>{I18n.t('general.save')}</Button>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}

export default withRouter(DriverRateConfigurationForm);
