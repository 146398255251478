import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Statistic, Icon } from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { getStatsIcon } from "../../containers/LineHaul/helpers";

const LinehaulStats = ({ filter, handleOnFilterChange, data }) => {
  const size = Math.floor(24 / (AppConfig.linehaulStatuses.length + 1));
  return (
    <Row className="orderStats">
      <Col span={2} />
      <Col
        span={size}
        onClick={() => handleOnFilterChange("filter", "")}
        className="routeLegends"
      >
        <Statistic
          title="All"
          className={filter === "" && "active"}
          value={data.total_count}
          prefix={<Icon type="ordered-list" className="textAll" />}
        />
      </Col>
      {AppConfig.linehaulStatuses.map((rec) => (
        <Col
          span={size}
          onClick={() => {
            handleOnFilterChange("filter", rec.value);
          }}
          className="routeLegends"
        >
          <Statistic
            title={rec.text}
            className={filter === rec.value && "active"}
            value={rec?.value && data[rec.value.toUpperCase()] ? data[rec.value.toUpperCase()] : 0}
            prefix={getStatsIcon(rec.value.toUpperCase())}
          />
        </Col>
      ))}
    </Row>
  );
};

LinehaulStats.propTypes = {
  filter: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  handleOnFilterChange: PropTypes.func.isRequired,
};

export default LinehaulStats;
