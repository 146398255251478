import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Popover, Checkbox, Modal } from '../../common/UIComponents'
import { checkServiceExistance } from '../../helpers/common'
import PostInvoiceToQB from './PostInvoiceToQB'
import UnlockInvoice from './UnlockInvoice'
import I18n from '../../common/I18n'
const BulkOperations = ({
  checkList,
  selectedInfo,
  handleCancel,
  handleSuccess,
  currentStatus,
  enableChecklistApproval
}) => {
  const size = 2
  const selectedKeys = [...selectedInfo.selectedKeys];
  return (
    <Row className="bottom_fixed">
      <Col xs={24} style={{ padding: '10px 10px' }} className="alignCenter">
        <Button type="danger" onClick={handleCancel} icon='close-circle'>
          Clear
        </Button>
        &nbsp;&nbsp;
        {checkServiceExistance('PITQB') && (
          <Fragment>
            {/* <ApproveInvoices
            selectedInfo={{ ...selectedInfo }}
            action={currentStatus === 'READY_FOR_BILLING' ? 'APPROVED' : 'REAPPROVED'}
            buttonType="danger"
            className="assignDriver"
            loading={loading}
            isDisabled={!(selectedInfo.selectedKeys.length > 0)}
            label={currentStatus === 'READY_FOR_BILLING' ? I18n.t('invoices.approve') : I18n.t('invoices.reapprove')}
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
          /> */}
            {
              currentStatus === 'PENDING' ?
                <PostInvoiceToQB
                  buttonType="primary"
                  isDisabled={selectedKeys.length === 0}
                  label={I18n.t('invoices.approve')}
                  invoiceIds={selectedKeys}
                  handleSuccess={handleSuccess}
                  handleCancel={handleCancel}
                  checkListData={checkList}
                  enableChecklistApproval={enableChecklistApproval}
                  selectedInfo={selectedInfo}
                />
                :
                <UnlockInvoice
                  buttonType="primary"
                  isDisabled={selectedKeys.length === 0}
                  label={I18n.t('invoices.unlock')}
                  invoiceIds={selectedKeys.join(',')}
                  handleSuccess={handleSuccess}
                  handleCancel={handleCancel}
                  selectedInfo={selectedInfo}
                />
            }
          </Fragment>
        )}
      </Col>
    </Row>
  )
}
BulkOperations.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  statusChange: PropTypes.func.isRequired,
}
export default BulkOperations