import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col,Spin, Row } from '../../common/UIComponents';
import List from './SubmittedSrveysList';
import SurveyResponse from './SurveyResponse';
import BaseModal from '../BaseModal';

class SubmittedSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: this.props.surveys ? this.props.surveys : [],
      inProgress: false,
      errors: [],
      showSurvey: false,
      surveyResponseId: '',
    };
  }

  handleSurveyDetails = (id) => {
    this.setState({
      showSurvey: true,
      surveyResponseId: id,
    });
  }

closeSurveyDetails = () => {
  this.setState({
    showSurvey: false,
  });
}

render() {
  const { surveys } = this.state;
  return (
    <div>
      <Spin spinning={this.state.inProgress} delay={1000}>
        <Row>
          <Col>
            <List
              data={surveys}
              handleSurveyDetails={this.handleSurveyDetails}
              pagination={{ position: 'none' }}
            />
          </Col>
        </Row>
      </Spin>
      {this.state.showSurvey &&
        <BaseModal
          title="Survey Details"
          onCancel={() => this.closeSurveyDetails()}
          className="surveyModal"
          width='70%'
          style={{ top: 40, overflowY: 'auto' }}
        >
          <SurveyResponse surveyId={this.state.surveyResponseId} />
        </BaseModal>
      }
    </div>
  );
}
}

SubmittedSurveys.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

SubmittedSurveys.defaultPropTypes = {
  surveys: [],
};

export default SubmittedSurveys;
