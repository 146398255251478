import React, { useState } from 'react'
import { formatByTimeConfig } from '../../../helpers/common';
import { RearrangableTable } from '../../common/RearrangableTable';
import EndUserTitle from '../../common/EndUserTitle';
import I18n from '../../../common/I18n';
import moment from 'moment';

export const PendingOrdersList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order:'Pickup',
      appointments: "2022-10-01T12:00:00Z",
      consignee: "John Doe",
      warehouse_address: "New York -> Chicago",
      customer_address: "10001",
      account_name: "ABC123",
      account_code: "Ab12",
      created_at: "2022-09-01T12:00:00Z",
      quantity: 1,
      weight: 100,
      levelOfService: "Threshold",
      zoneName: "New York",
      hawb: "123456",
      mawb: "123456",
      rejection_message:'Pickup is not available for this order',
      reference_1: "700214577",
      reference_2: "123456",
      status : "PENDING",
      cities : "America",
      street : "1-12",
      warehouse : "Regional Warehouse",
      company_name : "ABC123",
      full_address : '1234, Main Street, New York, NY 10001',
      routing : "ABCD",
      source : "PDF"
    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order:'Delivery',
      appointments: "2022-09-20T09:00:00Z",
      consignee: "Jane Smith",
      warehouse_address: "Los Angeles -> Houston",
      customer_address: "77070",
      account_name: "DEF456",
      account_code: "DE45",
      created_at: "2022-09-15T10:00:00Z",
      quantity: 2,
      weight: 200,
      levelOfService: "White Glove",
      zoneName: "California",
      hawb: "123456",
      mawb: "123456",
      rejection_message:'Delivery is not available for this order',
      reference_1: "980214577",
      reference_2: "123456",
      status : "REJECTED",
      cities : "New York",
      street : "1-23",
      warehouse : "Regional Warehouse",
      company_name : "ABC123",
      full_address : '1234, Main Street, New York, NY 10001',
      routing : "EFGH",
      source : "EDI"

    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "appointment",
      title: I18n.t("general.scheduled_appointment"),
      dataIndex: "appointments",
      // hideAppointmentdate ? 'HH:mm' : 'DD MMM  HH:mm',
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: "consignee",
      title: <EndUserTitle/>,
      dataIndex: "consignee",
    },
    {
      key: "city",
      title: "Origin -> Destination",
      dataIndex: "warehouse_address",
    },
    {
      key: "Zipcode",
      title: "Zipcode",
      dataIndex: "customer_address",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "created_at",
      title: I18n.t("order.age"),
      dataIndex: "created_at",
      render(text, record) {
        return <div>{moment.utc(text).fromNow()}</div>;
      },
    },
    {
      key: "items_count",
      title: `${I18n.t("order.qty")}`,
      dataIndex: "quantity",
    },
    {
      key: "weight",
      title: `${I18n.t("order.weight")}`,
      dataIndex: "weight",
    },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "levelOfService",
    },
    {
      key: "zone_name",
      title: "Zones",
      dataIndex: "zoneName",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "REJECTED",
      title: I18n.t("general.reason"),
      dataIndex: "rejection_message",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "warehouse",
      title: I18n.t("general.warehouse"),
      dataIndex: "warehouse",
    },
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key : 'full_address',
      title : 'Address',
      dataIndex: "full_address",
    },
    {
      key : 'routing',
      title : I18n.t("order.routing"),
      dataIndex: "routing",
    },
    {
      key : 'source',
      title : I18n.t("general.source"),
      dataIndex: "source",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "pendingOrders_list_view_columns";

  return (
    <div>
       <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
    </div>
  )
}
