import React from "react";
import BaseList from "../../BaseList";
import I18n from "../../../common/I18n";

export default class DockScreenReportList extends BaseList {
  constructor(props) {
    super(props);
   this.columns = [
    {
      key: "cus_no",
      title: <b>{I18n.t("dock_scan_report.cus_no")}</b>,
      dataIndex: "customer_order_number",
      render: (data, record) => <span>{data}</span>,
      width: 100,

    },
    {
      key: "hawb",
      title: <b>{I18n.t("dock_scan_report.hawb")}</b>,
      dataIndex: "hawb",
      render: (data, record) => <span>{data}</span>,
      width: 100,
    },
    {
      key: "dock_location",
      title: <b>{I18n.t("dock_scan_report.dock_location")}</b>,
      dataIndex: "dock_location",
      render: (data, record) => <span>{data}</span>,
      width: 100,
    },
    {
      key:"notes",
      title: <b>{I18n.t("dock_scan_report.notes")}</b>,
      dataIndex: "notes",
      render: (data, record) => <span>{data}</span>,
      width: 100,
    }
   ];
  }
}
