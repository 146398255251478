import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Tabs } from "../../common/UIComponents";
import {  alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import { checkServiceExistance } from "../../helpers/common";
import FuelSurcharges from "./FuelSurcharges";
import USEnergyInfo from "./USEnergyInfo";

const { TabPane } = Tabs;
class FuelSurchargeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setActiveKey: "fuel_surcharge",
    };
  }

  goToTab = (key) => {
    this.setState({ setActiveKey: key });
  };

  render() {
    return (
      <div className="child-card-container">
        <Tabs
          onChange={this.goToTab}
          activeKey={this.state.setActiveKey}
          size="small"
          type="card"
        >
          {checkServiceExistance("USEI") && (
            <TabPane
              tab={I18n.t("menu.us_energy_info")}
              key="national_fuel_charge"
            >
              <USEnergyInfo />
            </TabPane>
          )}
          <TabPane tab={I18n.t("menu.fuelSurcharge")} key="fuel_surcharge">
            <FuelSurcharges />
          </TabPane>
          
        </Tabs>
      </div>
    );
  }
}

export default FuelSurchargeSettings;
