import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Button, DatePicker } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import AppConfig from "../../config/AppConfig";

const BatchFilter = ({
  accounts,
  form,
  clearFilter,
  filterPlaceHolder,
  setFilterPlaceHolder,
  defaultFilter,
}) => {
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } =
    form;
  const { RangePicker } = DatePicker;
  const [filter, setFilter] = useState({ ...defaultFilter });
  const path = window.location.href;
  const landingPage = path.includes("batches");
  const allResultsPage = path.includes("manage_checks");
  const managePage = path.includes("manage_batch");

  useEffect(() => {
    setFilter({ ...filterPlaceHolder });
  }, [filterPlaceHolder]);

  useEffect(() => {
    if (
      (filterPlaceHolder && allResultsPage) ||
      (filterPlaceHolder && managePage)
    ) {
      setFieldsValue({
        account_code: filterPlaceHolder.account_code,
        status: filterPlaceHolder.status,
      });
    } else {
      const haveDateValues = filterPlaceHolder?.dateSearchValue && filterPlaceHolder.dateSearchValue?.formattedFrom_date && filterPlaceHolder.dateSearchValue?.formattedTo_date
      setFieldsValue({
        dateRange: haveDateValues ? [
          moment(
            filterPlaceHolder.dateSearchValue.formattedFrom_date,
            "MMM DD, YYYY"
          ),
          moment(
            filterPlaceHolder.dateSearchValue.formattedTo_date,
            "MMM DD, YYYY"
          ),
        ]: [null, null],
      });
    }
  }, [filterPlaceHolder]);

  const validateForm = () => {
    validateFields((err, values) => {
      if (!err) {
        if (managePage || allResultsPage) {
          const account_id = values.account_code;
          const status = values.status;
          if (
            filter.hasOwnProperty("account_code") &&
            filter.hasOwnProperty("status")
          ) {
            const updatedFilter = {
              ...filter,
              account_code: account_id,
              status: status,
            };
            setFilterPlaceHolder(updatedFilter);
          }
        } else {
          const [formattedFrom_date, formattedTo_date] = values.dateRange;
          if (
            filter.hasOwnProperty("dateSearchValue") &&
            filter.dateSearchValue.hasOwnProperty("formattedFrom_date") &&
            filter.dateSearchValue.hasOwnProperty("formattedTo_date")
          ) {
            const updatedFilter = {
              ...filter,
              dateSearchValue: {
                formattedFrom_date: formattedFrom_date,
                formattedTo_date: formattedTo_date,
              },
            };
            setFilterPlaceHolder(updatedFilter);
          }
        }
      } else {
        return;
      }
    });
  };

  const handleClear = () => {
    resetFields();
    clearFilter();
    if (landingPage) {
      setFilter({ ...defaultFilter });
    }
  };

  const renderAccountsList = () => (
    <Form.Item label="Account :">
      {getFieldDecorator("account_code", {
        initialValue: "",
      })(
        <Select
          showSearch
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Select.Option key="ACCOUNT_ALL" value={""}>
            -- All --
          </Select.Option>
          {accounts.map((account) => (
            <Select.Option key={account.code} value={account.id}>
              {`${account.name} (${account.code})`}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );

  const renderRangePicker = () => (
    <Form.Item label="Date Range :">
      {getFieldDecorator("dateRange", {
        initialValue: [moment().subtract(6, "days"), moment()],
      })(
        <RangePicker
          ranges={AppConfig.dateRanges}
          format={AppConfig.dateFormat}
          style={{ width: "100%" }}
          allowClear
        />
      )}
    </Form.Item>
  );

  const renderStatusFilter = () => (
    <Form.Item label="Status :">
      {getFieldDecorator("status", {
        initialValue: "BOTH",
      })(
        <Select
          showSearch
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Select.Option key="BOTH" value={""}>
            -- BOTH --
          </Select.Option>
          <Select.Option key="OPEN" value={"OPEN"}>
            OPEN
          </Select.Option>
          <Select.Option key="CLOSE" value={"CLOSE"}>
            CLOSE
          </Select.Option>
        </Select>
      )}
    </Form.Item>
  );

  return (
    <Form layout="vertical">
      <div className="padding10">
        <Row style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
          {(managePage && <Col md={24}>{renderAccountsList()}</Col>) ||
            (allResultsPage && <Col md={24}>{renderAccountsList()}</Col>)}
          {(managePage && <Col md={24}>{renderStatusFilter()}</Col>) ||
            (allResultsPage && <Col md={24}>{renderStatusFilter()}</Col>)}
          {landingPage && <Col md={24}>{renderRangePicker()}</Col>}
          <Col>
            <Row type="flex" gutter={16} justify="center">
              <Col>
                <Button type="danger" onClick={handleClear} icon="redo">
                  Reset
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={validateForm} icon="search">
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

const wrappedBatchFilter = withRouter(Form.create()(BatchFilter));
export default wrappedBatchFilter;
