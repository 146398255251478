/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Input,
} from '../../common/UIComponents'
import I18n from "../../common/I18n";
import MaterialTimePicker from '../orders/MaterialTimePicker'
import moment from 'moment'
import { daysData } from '../../constants/daynmonths'
import BaseSelect from '../common/SelectDropdowns/BaseSelect'
import SwitchComponent from '../../common/switchComponent'

const ETARules = ({
  config = {},
  handleOnInputChange,
  title = I18n.t('configurations.headings.shipper_eta_rules')
}) => {
  const renderForm = () => (
    <Fragment>
      <Row>
        <div className="settingHeader">
          <span className="textBold">
            {title}
          </span>
        </div>
      </Row>
      <Row className="configLableRow marginTop10">
        <Col xs={23} sm={23} md={23} lg={4}>
          {I18n.t(
            'configurations.org_rules.enable_shipper_eta',
          )}
        </Col>
        <Col xs={1} sm={1} md={1} lg={1}>
          :
        </Col>
        <Col xs={24} sm={24} md={24} lg={14}>
          <SwitchComponent
            handleChange={(value) =>
              handleOnInputChange(
                'enable_shipper_eta',
                value,
              )
            }
            isChecked={
              config.enable_shipper_eta === 'true'
            }
            checkedChildren={'Yes'}
            unCheckedChildren={'No'}
            checkedValue={'true'}
            unCheckedValue={'false'}
          />
        </Col>
      </Row>
      {
        config.enable_shipper_eta === 'true' && 
        <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.eta_rules.cut_off_time')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <MaterialTimePicker
                onChange={(e) => {
                  const cut_off_time = e ? moment(e).format('HH:mm') : ''
                  handleOnInputChange('cut_off_time', cut_off_time)
                }}
                value={config.cut_off_time ? moment(config.cut_off_time, "HH:mm") : null}
                computeDisabledHours={() => []}
                computeDisabledMinutes={() => []}
                isMilitaryTime={false}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={24}>
              {I18n.t('configurations.eta_rules.schedule_for_next')}
              <Input
                value={config['schedule_next_n_days'] || ''}
                onChange={(e) =>
                  handleOnInputChange('schedule_next_n_days', e.target.value)
                }
                type="number"
                className="marginRight10 marginLeft10 singleLineInput"
                style={{ width: 100 }}
                min={0}
              />
              days
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={24}>
              {I18n.t('configurations.eta_rules.move_weekend_orders', {
                field: 'Saturday',
              })}
              
              <BaseSelect
                data={daysData.filter(d => d.value !== 'SATURDAY')}
                className="marginRight10 marginLeft10 singleLineInput"
                value={config['move_saturday_orders']}
                onChange={(e) => {
                   handleOnInputChange('move_saturday_orders', e)
                }}
                selectKey={'value'}
                selectValue={'value'}
                selectedValue={'label'}
                style={{ width: 140 }}
                showSearch={true}
              />
               or same as the above
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={24}>
              {I18n.t('configurations.eta_rules.move_weekend_orders', {
                field: 'Sunday',
              })}
              
              <BaseSelect
                data={daysData.filter(d => d.value !== 'SUNDAY')}
                value={config['move_sunday_orders']}
                onChange={(e) => {
                   handleOnInputChange('move_sunday_orders', e)
                }}
                selectKey={'value'}
                selectValue={'value'}
                selectedValue={'label'}
                style={{ width: 140 }}
                showSearch={true}
                className="marginRight10 marginLeft10 singleLineInput"
              />
              or same as the above
            </Col>
          </Row>
        </Col>
        
        </Row>
      }
    </Fragment>
  )
  return <Fragment>{renderForm()}</Fragment>
}
export default ETARules

ETARules.propTypes = {
  config: PropTypes.shape().isRequired,
  handleOnInputChange: PropTypes.func.isRequired,
}