import { isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { Call, ApiUrl, ParseGeneralResponse, PageParams,setObjectToFormData, buildQueryString } from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import userStore from "../stores/UserStore";
import _ from "lodash"
import moment from "moment";

export function fetchWeekReport(driverId = '', weekNumber = '', yearNumber = '', searchText = '', page = 1, perPage = 5) {
  let url = ApiUrl(`v2/gross_order_reports?operation_code=GOR`);
  if (!isEmpty(weekNumber) && !isEmpty(yearNumber)) {
    url = `${url}&week_number=${weekNumber}&year_number=${yearNumber}`;
  }
  if(!isEmpty(searchText)){
    url = `${url}&customer_order_number=${encodeURIComponent(searchText)}`;
  }
  if(!isEmpty(driverId) && isEmpty(searchText)){
    url = `${url}&driver_id=${driverId}`;
  }
  url = `${url}&page=${page}&perPage=${perPage}`;
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { reports: 'weekly_report', driverPayReports: 'driver_pay_reports', driverDeductions: 'driver_deduction_reports',total_count: 'total_count' },
  });
}

export function fetchDriverPayReport(customerOrderIds = [], driverPayId = '') {
  const url = ApiUrl(`v2/gross_order_reports/driver_pay_order_reports?driver_pay_report_id=${driverPayId}&operation_code=DPOR`);
  return Call('get', url, { customer_order_ids: customerOrderIds }, ParseGeneralResponse, {
    responseDataKeys: { reports: 'weekly_report', claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance', driverDeductions: 'driver_deduction_reports' },
  });
}

export function fetchDriverInvoiceDetail(driverPayId = '') {
  const url = ApiUrl(`v2/weekly_drivers_reports/get_driver_invoice_details?driver_pay_report_id=${driverPayId}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { reports: 'driver_report'},
  });
}

export function saveGrossOrdersData(data) {
  const url = ApiUrl('v2/gross_order_reports/update_gross_amount');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { reports: 'weekly_report', driverPayReports: 'driver_pay_reports', driverDeductions: 'driver_deduction_reports', claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance' } });
}

export function saveDriverDeductionData(data) {
  const url = ApiUrl('v2/gross_order_reports/update_driver_deduction_reports');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { reports: 'weekly_report', driverDeductions: 'driver_deduction_reports', claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance' } });
}

export function approveReport(data) {
  const url = ApiUrl('v2/gross_order_reports/update_driver_report_status');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { reports: 'weekly_report', driverPayReports: 'driver_pay_reports', driverDeductions: 'driver_deduction_reports' , claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance'} });
}

export function deleteGrossOrder(data) {
  const url = ApiUrl(`v2/gross_order_reports/delete_gross_order?organization_id=${data.organization_id}&driver_pay_report_id=${data.driver_pay_report_id}&gross_order_id=${data.gross_order_id}`);
  const method = 'get';
  return Call(method, url, {}, ParseGeneralResponse, { responseDataKeys: { reports: 'weekly_report', driverPayReports: 'driver_pay_reports', driverDeductions: 'driver_deduction_reports' , claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance'} });
}

export function deleteDeduction(data) {
  const url = ApiUrl(`v2/gross_order_reports/delete_driver_deduction_reports?org_id=${data.org_id}&driver_pay_report_id=${data.driver_pay_report_id}&driver_id=${data.driver_id}&driver_deduction_report_id=${data.driver_deduction_report_id}`);
  const method = 'get';
  return Call(method, url, {}, ParseGeneralResponse, { responseDataKeys: { reports: 'weekly_report', driverPayReports: 'driver_pay_reports', driverDeductions: 'driver_deduction_reports' , claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance'} });
}



export function saveReportDetails(data) {
  const url = ApiUrl('v2/gross_order_reports/update_driver_pay_report_details');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { reports: 'weekly_report', driverPayReports: 'driver_pay_reports', driverDeductions: 'driver_deduction_reports', claim_balance: 'claim_balance', loan_balance: 'loan_balance', advance_balance: 'advance_balance' } });
}
export function addWeekPeriodToDriver(data) {
  const url = ApiUrl('v2/gross_order_reports/add_default_driver_pay_report');
  const method = 'post';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: {  driver_pay_reports: 'driver_pay_report' } });
}

export function emailWeeklyReport(id, payload){
    payload.operation_code = 'WREMAIL'
    const url = ApiUrl(`v2/gross_order_reports/email_driver_pay_invoice`)
    return Call('POST', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
}
export function downloadWeeklyReport(id){
    const url = ApiUrl(`v2/gross_order_reports/view_driver_pay_invoice?driver_pay_report_id=${id}`)
    return Call('get', url, { operation_code: 'WRD'}, ParseGeneralResponse, {
      responseDataKeys: {
        report_file: 'driver_pay_invoice',
      },
    })
}

export function fetchAgingReports(
  page = 1,
  perPage = AppConfig.perPage,
  wh_id,
  searchText = "",
  filter = {}
) {
  const org_id = userStore.getStateValue("selectedOrg");
  let url = ApiUrl(
    `v2/billing_aging_reports/get_aging_reports?organization_id=${org_id}&${PageParams(
      page,
      perPage
    )}`
  );
  if (!isEmpty(searchText)) {
    url = `${url}&search_text=${searchText}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.sortBy) && !isEmpty(filter.sortByType)) {
    url = `${url}&sort_by=${filter.sortBy}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.accountCodes) && filter.accountCodes.length > 0) {
    url = `${url}&account_ids=${filter.accountCodes}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.warehouseIds) && filter.warehouseIds.length > 0) {
    url = `${url}&wh_location_id=${filter.warehouseIds}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.fromDate) && !isEmpty(filter.toDate)) {
    const fromDate = moment(filter.fromDate).format("YYYY-MM-DD");
    const toDate = moment(filter.toDate).format("YYYY-MM-DD")
    url = `${url}&from_date=${fromDate}&to_date=${toDate}`;
  }
  if(!_.isEmpty(filter) && !isEmpty(filter.invoiceAmountFilter)){
    url = `${url}&invoice_amount_filter=${filter.invoiceAmountFilter}`;
  }
  if(!_.isEmpty(filter) && !isEmpty(filter.reportDate)){
    const reportDate = moment(filter.reportDate).format("YYYY-MM-DD");
    url = `${url}&report_date=${reportDate}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      agingReports: "aging_reports",
      pagination: "pagination.pagination",
    },
  });
}
export function fetchCollectionReports(
  page = 1,
  perPage = AppConfig.perPage,
  wh_id,
  searchText = "",
  filter = {}
) {
  const org_id = userStore.getStateValue("selectedOrg");
  let url = ApiUrl(
    `v2/reports/get_aging_report_based_on_age?organization_id=${org_id}&${PageParams(
      page,
      perPage
    )}`
  );

  if (filter.isExporting) {
    url = `${url}&is_exporting=true`;
  }

  if (!isEmpty(searchText)) {
    url = `${url}&search_text=${searchText}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.sortBy) && !isEmpty(filter.sortByType)) {
    url = `${url}&sort_by=${filter.sortBy}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.accountCodes) && filter.accountCodes.length > 0) {
    url = `${url}&account_ids=${filter.accountCodes}`;
  }
  if (!_.isEmpty(filter) && !isEmpty(filter.warehouseIds) && filter.warehouseIds.length > 0) {
    url = `${url}&wh_location_id=${filter.warehouseIds}`;
  }

  // Age Filter
  if (!_.isEmpty(filter.ageFilter)) {
    switch (filter.ageFilter) {
      case "BETWEEN":
        url = `${url}&from_age=${filter.fromAge !== null ? Number(filter.fromAge) : 0}&to_age=${filter.toAge !== null ? Number(filter.toAge) : 30}`;
        break;
      case "GREATER_THAN_EQUAL":
        url = `${url}&age_gte=${filter.ageGte !== null ? Number(filter.ageGte) : 0}`;
        break;
      case "LESS_THAN_EQUAL":
        url = `${url}&age_lte=${filter.ageLte !== null ? Number(filter.ageLte) : 30}`;
        break;
      default:
        url = `${url}&from_age=0&to_age=30`;
        break;
    }
  } else {
    url = `${url}&from_age=0&to_age=30`;
  }

  if (!_.isEmpty(filter.reportDate)) {
    const reportDate = moment(filter.reportDate).format("YYYY-MM-DD");
    url = `${url}&report_date=${reportDate}`;
  }

  if (!_.isEmpty(filter.invoiceAmountFilter)) {
    url = `${url}&invoice_amount_filter=${filter.invoiceAmountFilter}`;
  }

  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      collectionReports: "reports",
      pagination: "pagination.pagination",
      pdf: "pdf",
    },
  });
}



export function fetchAccountAgingReports(
  data,
  page = 1,
  perPage = AppConfig.perPage
) {
  const url = ApiUrl(
    `v2/billing_aging_reports/get_account_aging_reports?${Object.keys(data)
      .map((key) => `${key}=${data[key]}`)
      .join("&")}&${PageParams(page, perPage)}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      accountAgingReports: "account_reports",
      pagination: "pagination.pagination",
    },
  });
}

export function fetchAccountCollectionReports(
  page = 1,
  perPage = AppConfig.perPage,
  filter = {}
) {
  const org_id = userStore.getStateValue("selectedOrg");
  let url = ApiUrl(
    `v2/reports/get_account_aging_report_based_on_age?organization_id=${org_id}&${PageParams(
      page,
      perPage
    )}`
  );

  if (!_.isEmpty(filter.isExporting)) {
    url = `${url}&is_exporting=true`;
  }

  if (!_.isEmpty(filter.searchText)) {
    url = `${url}&search_text=${filter.searchText}`;
  }

  if (!_.isEmpty(filter.sortBy) && !_.isEmpty(filter.sortByType)) {
    url = `${url}&sort_by=${filter.sortBy}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}`;
  }

  if (!_.isEmpty(filter.accountCodes)) {
    url = `${url}&account_ids=${filter.accountCodes}`;
  }

  if (!_.isEmpty(filter.warehouseIds)) {
    url = `${url}&wh_location_id=${filter.warehouseIds}`;
  }

  // Age Filter
  if (!_.isEmpty(filter.ageFilter)) {
    switch (filter.ageFilter) {
      case "BETWEEN":
        url = `${url}&from_age=${Number(filter.fromAge) || 0}&to_age=${Number(filter.toAge) || 30}`;
        break;
      case "GREATER_THAN_EQUAL":
        url = `${url}&age_gte=${Number(filter.ageGte) || 0}`;
        break;
      case "LESS_THAN_EQUAL":
        url = `${url}&age_lte=${Number(filter.ageLte) || 30}`;
        break;
      default:
        url = `${url}&from_age=0&to_age=30`;
        break;
    }
  } else {
    url = `${url}&from_age=0&to_age=30`;
  }

  if (!_.isEmpty(filter.reportDate)) {
    const reportDate = moment(filter.reportDate).format("YYYY-MM-DD");
    url = `${url}&report_date=${reportDate}`;
  }

  if (!_.isEmpty(filter.invoiceAmountFilter)) {
    url = `${url}&invoice_amount_filter=${filter.invoiceAmountFilter}`;
  }

  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      accountAgingReports: "reports",
      pagination: "pagination.pagination",
    },
  });
}




export function exportAgingFile(organization_id, wh_location_id, download_as,account_id,report_date, view_type = 'DETAILS', invoice_amount_filter = 'BOTH',exportEmail='') {
  let url = ApiUrl(`v2/reports/get_reports_api?organization_id=${organization_id}&download_as=${download_as}&report_date=${report_date}&view_type=${view_type}`)
  if(wh_location_id?.length){
    url= `${url}&wh_location_id=${wh_location_id.join(',')}`
  }
  if(account_id?.length){
    url= `${url}&account_id=${account_id.join(',')}`
  }
  if(invoice_amount_filter){
    url= `${url}&invoice_amount_filter=${invoice_amount_filter}`
  }
  if(exportEmail){
    url= `${url}&emails=${exportEmail.join(',')}`
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      file: "aging_report",
      data:""
    },
  });
}

export function exportAgingFileAsEmail(payload) {
console.log('gotcha')
  const {
    organization_id,
    page,
    per_page,
    sort_by = "none",
    sort_order = "DESC",
    from_date,
    to_date,
    invoice_amount_filter,
    report_date,
    current_role,
    warehouse_ids,
    emails,
    download_as = 'excel'
  } = payload;
  let url = ApiUrl(`v2/billing_aging_reports/get_aging_reports?organization_id=${organization_id}&page=${page}&per_page=${per_page}&sort_by=${sort_by}&sort_order=${sort_order}&warehouse_ids=${warehouse_ids}&from_date=${from_date}&to_date=${to_date}&invoice_amount_filter=${invoice_amount_filter}&report_date=${report_date}&emails=${emails}&current_role=${current_role}&download_as=${download_as}`);

  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      file: "aging_report_email",
      data: "",
    },
  });
}

export function getStatusReport(data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl(`v2/customer_orders/export_order_statuses_from_csv`);
  return Call("POST", url, formData, ParseGeneralResponse, {
    responseDataKeys: {
      orders: "data",
      pagination: "meta.pagination",
      file: "file",
      fileName: "filename"
    },
  });
}
export function exportStatusReport(data) {
  let url = ApiUrl(
    `v2/customer_orders/export_unprocessed_orders?`
  );

  const queryString = buildQueryString(data, false);
  if (!isEmpty(queryString)) {
    url = `${url}&${queryString}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      orders: "data",
      file: "",
      pagination: "meta.pagination",
    },
  });
}

export function fetchDunningReport(data) {
  let url = ApiUrl(`v2/billing_aging_reports/get_dunning_notice?`);

  const queryString = buildQueryString(data, false);
  if (!isEmpty(queryString)) {
    url = `${url}&${queryString}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      reports_data: "invoice_details",
      pagination: "pagination",
    },
  });
}

export function exportDunningNotice(data) {
  // const formData = setObjectToFormData(data);
  let url = ApiUrl(
    `v2/billing_aging_reports/export_dunning_notice?${buildQueryString(data)}`
  );
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      file: "dunning_notice_report",
    },
  });
}


export function fetchInvoiceStatusReport (data) {
  let url = ApiUrl(`v2/account_invoices/edi_invoice_status_report?`);

  const queryString = buildQueryString(data, false);
  if (!isEmpty(queryString)) {
    url = `${url}&${queryString}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      reports_data: "edi_invoice_status_report",
      pagination: "pagination.pagination",
    },
  });
}

export function fetchQbReport(data) {
  let url = ApiUrl(`v2/account_invoices/get_approved_invoices_with_qb_status?`);

  const queryString = buildQueryString(data, false);
  if (!isEmpty(queryString)) {
    url = `${url}&${queryString}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      reports_data: "order_invoices",
      pagination: "meta.pagination",
    },
  });
}