import {
    Call,
    ApiUrl,
    PageParams,
    ParseGeneralResponse,
  } from './ApiUtils';
  import { isEmpty } from "../common/Common";
  import I18n from '../common/I18n';

  export const CustomerLogs = {
    fetch: (page =1, perPage ='', searchParams) => {  
        const { fromDate='',toDate = '',warehouse_id = '',as_export ='',sortBy ='',sortByType=''} = searchParams;
        const start_date_time = fromDate.format('YYYY-MM-DD HH:mm:ss Z')
        const start_end_time = toDate.format('YYYY-MM-DD HH:mm:ss Z')
        const url = ApiUrl(
          `v2/reports/order_log_report?${PageParams(
            page,
            perPage
          )}&start_date_time=${start_date_time}&end_date_time=${start_end_time}&warehouse_ids=${warehouse_id}&report_type=${"CS_LOG"}&as_export=${as_export}&sort_by=${sortBy}&sort_order=${I18n.t(`general.sort_${sortByType}`)}`
        );
        if(as_export){
            return Call('get', url, {}, ParseGeneralResponse, {
                responseDataKeys: { file: 'file', fileName: "filename"},
            })
        }
        return Call('get', url, {}, ParseGeneralResponse, {
          responseDataKeys: { customer_logs_report: 'reports', pagination: "meta.pagination" },
        })
      }
  }
  export const DriverLogs = {
    fetch: (page =1, perPage ='', searchParams) => {  
        const { fromDate='',toDate = '',warehouse_id = '',driver_ids = [], as_export ='',sortBy ='',sortByType ='' } = searchParams;
        const start_date_time = fromDate.format('YYYY-MM-DD HH:mm:ss Z')
        const start_end_time = toDate.format('YYYY-MM-DD HH:mm:ss Z')
        const url = ApiUrl(`v2/driver_log_reports/driver_log_report?${PageParams(page,perPage)}&start_date_time=${start_date_time}&end_date_time=${start_end_time}&warehouse_ids=${warehouse_id}&driver_ids=${driver_ids}&report_type=${"DV_LOG"}&as_export=${as_export}&sort_by=${sortBy}&sort_order=${I18n.t(`general.sort_${sortByType}`)}`);
        if(as_export){
            return Call('get', url, {}, ParseGeneralResponse, {
                responseDataKeys: { file: 'file', fileName: 'filename'},
            })
        }
        return Call('get', url, {}, ParseGeneralResponse, {
          responseDataKeys: { customer_logs_report: 'driver_log_reports', pagination: "meta.pagination" },
        })
      }
  }