import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import { Col, Row, Button, Spin, Search, Select, Dropdown, Icon, FormItem, SideBySideFormItem, Radio } from "../../../common/UIComponents";
import {
  alertMessage,
  checkNegitive,
  isEmpty,
  navigateToAccount,
} from "../../../common/Common";
import { convertToTags, limitString, removeSpecialCharacters, renderAlertMessage } from "../../../helpers/common";
import AppConfig from "../../../config/AppConfig";
import { checkServiceExistance } from "../../../helpers/common";
import {
  fetchAgingReports,
  fetchAccountAgingReports,
  exportAgingFileAsEmail,
} from "../../../api/Reports";
import { fetchShortFormAccounts } from "../../../api/Account";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../../context/WarehouseContext";
import AgingReportList from "./AgingReportsList";
import AccountAgingReportsList from "./AccountAgingReportsList";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import { UserContext } from "../../../context/UserContext";
import I18n from "../../../common/I18n";
import { OrgContext } from "../../../context/OrgContext";
import ExportReport from "./ExportReport";
import { ScreenKeys } from "../../../containers/constants";
import { fetchOrgAccounts } from "../../../api/Account";
import AccountCodesFilter from "../../../containers/Filters/AccountCodesFilter";
import AgingReportFilter from "./AgingReportFilter";
import moment from 'moment';
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import BaseModal from "../../../components/BaseModal";
import FormButtons from "../../common/FormButtons";
import { validateEmailList } from "../../billing_screen/helpers";
import userStore from "../../../stores/UserStore";

const defaultFilter = {
  reportDate: moment(),
  accountCodes: null,
  warehouseIds: [],
  invoiceAmountFilter : "WITH_AMOUNT",
};

class AgingReports extends Component {
  constructor(props) {
    super(props);
    const { selectedWarehouses = null } = props.warehouseData;
    const warehouseIds = Array.isArray(selectedWarehouses)
      ? selectedWarehouses
      : selectedWarehouses
      ? [selectedWarehouses]
      : null;
    this.state = {
      accounts: [],
      pagination: {},
      reportLoading: false,
      appliedFiltersTags: [],
      warehouseData : this.props.warehouseData,
      accountsLoading: false,
      agingReports: [],
      recordsPerPage: props.recordsPerPage,
      token: "",
      filterPlaceHolder: {
        sortBy: "none",
        sortByType: "descend",
        accountCodes: [],
        fromDate: moment(),
        toDate: moment(),
        warehouseIds ,
        invoiceAmountFilter: "WITH_AMOUNT",
        reportDate: moment(),
      },
      activeAccounts: [],
      filterWindow: false,
      whLoading: false,
      isInitial: true,
      isExportModalVisible: false,
      exportEmail: [],
      exportMailfile_type: 'excel'
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  getAmountTypeLabel = (invoiceAmountFilter) => {
    switch (invoiceAmountFilter) {
      case "WITH_AMOUNT":
        return "With Amount";
      case "WITHOUT_AMOUNT":
        return "Without Amount";
      case "BOTH":
        return "Both";
      default:
        return "";
    }
  };

  componentDidMount() {
    this.getAgingReports();
    this.getAccounts();
    this.computeTags(this.state.filterPlaceHolder);
  }
  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseData.selectedWarehouses,
        this.props.warehouseData.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseData.warehouses,
        this.props.warehouseData.warehouses
      ) &&
        !this.props.warehouseData.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      this.setState({
        ...this.state,
        filterPlaceHolder:
        {
          ...this.state.filterPlaceHolder,
          warehouseIds: this.props.warehouseData.selectedWarehouses
        }
      },()=>
    {
      this.getAgingReports();
      this.getAccounts();
      this.computeTags(this.state.filterPlaceHolder);
    })
      if(this.props.warehouseData && this.state.isInitial){
        const { selectedWarehouses } = this.props.warehouseData;
        const warehouseIds = Array.isArray(selectedWarehouses)
          ? selectedWarehouses
          : selectedWarehouses
          ? [selectedWarehouses]
          : null;
        // this.setState({
        //   filterPlaceHolder: { 
        //     ...this.state.filterPlaceHolder,
        //     warehouseIds: warehouseIds
        //   }
        // })
      }
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }


  clearFilter = () => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          reportDate: moment(),
          warehouseIds: currentWhId,
          accountCodes: [],
          invoiceAmountFilter : "WITH_AMOUNT"
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData?.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "reportDate":
        filterVariable.reportDate = defaultFilter.reportDate;
        break;
      case "invoiceAmountFilter":
        filterVariable.invoiceAmountFilter = defaultFilter.invoiceAmountFilter;
        break;  
      case "warehouseIds":
        filterVariable.warehouseIds = currentWhId;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      this.handleSearch();
    });
  };

  computeTags = (filterPlaceHolder) => {
    const previousAmountType = "WITH_AMOUNT";
    const { warehouseData } = this.props;
    const {warehouses} = warehouseData
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;

    const keys = ["reportDate", "toDate", "accountCodes", "warehouseIds","invoiceAmountFilter"];

    const filteredData = {
      invoiceAmountFilter: {
        label: "Amount Type",
        value: this.getAmountTypeLabel(filterPlaceHolder.invoiceAmountFilter),
        onClose: () => {
          this.handleOnFilterRemoval("invoiceAmountFilter");
        },
        closeable: previousAmountType !== filterPlaceHolder.invoiceAmountFilter,
      },
      reportDate: {
        label: "Reports Date",
        value:
          moment(filterPlaceHolder.reportDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("reportDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.reportDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          ) 
            ? false
            : true,
      },
      warehouseIds: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouseIds.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouseIds");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouseIds?.length === 1 &&
          filterPlaceHolder?.warehouseIds[0] === currentWhId[0]
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder?.accountCodes?.includes(account.id)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },

    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  // getAccounts = () => {
  //   this.setState({
  //     accountsLoading: true,
  //   });
  //   fetchShortFormAccounts().then((result) => {
  //     if (result.success) {
  //       this.setState({
  //         accounts: result.accounts || [],
  //         accountsLoading: false,
  //       });
  //     } else {
  //       this.setState({
  //         accountsLoading: false,
  //         accounts: [],
  //       });
  //     }
  //   });
  // };

  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const orgId = this.props.orgData.currentOrg.id
    // Call fetchShortFormAccounts without passing the warehouse ID (whId)
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
       } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  getAgingReports = () => {
    this.setState({
      reportLoading: true,
    });
    const searchText = this.state.token || "";
    const { selectedWarehouses } = this.props.warehouseData;
    const { recordsPerPage, filterPlaceHolder } = this.state;
    const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
    const whId = selectedwh;
    const page = this.tableOptions.pagination.current;
    const perPage = recordsPerPage;
    fetchAgingReports(page, perPage, whId, searchText, this.state.filterPlaceHolder).then((result) => {
      if (result.success) {
        const agingReports = result.agingReports || [];
        this.setState({
          agingReports: agingReports.map((rec) => ({
            ...rec,
            pagination: {},
            // inProgress: false,
          })),
          pagination: result.pagination,
          reportLoading: false,
          activeAccounts: []
        });
      } else {
        this.setState({
          reportLoading: false,
          agingReports: [],
          activeAccounts: []
        });
      }
    });
  };

  getAccountAgingReport = (
    isExpanded,
    id,
    tableOptions = { pagination: {}, filters: {}, sorter: {} }
  ) => {
    if (isExpanded) {
      const agingReports = [...this.state.agingReports];
      const accObjectIndex = _.findIndex(agingReports, ["account_id", id]);
      this.setState({
        activeAccounts: [id],
      });
      if (accObjectIndex >= 0) {
        agingReports[accObjectIndex].inProgress = true;
        agingReports[accObjectIndex].tableOptions = tableOptions;
        const { selectedWarehouses } = this.props.warehouseData;

        const page = tableOptions.pagination
          ? tableOptions.pagination.current
          : 1;
        const perPage = this.state.recordsPerPage;
        this.setState({
          agingReports,
        });
        const selectedwh =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;
        let payload = { account_id: id, wh_location_id: selectedwh };
        payload.organization_id = this.props.orgData.currentOrg.id;
        if(this.state.filterPlaceHolder.reportDate){
          payload.report_date=  moment(this.state.filterPlaceHolder.reportDate).format("YYYY-MM-DD");
        }
        fetchAccountAgingReports(payload, page, perPage).then((result) => {
          if (result.success) {
            agingReports[accObjectIndex].accountAgingReports =
              result.accountAgingReports || [];
            agingReports[accObjectIndex].inProgress = false;
            agingReports[accObjectIndex].pagination = result.pagination || {};
            this.setState({
              agingReports,
            });
          } else {
            agingReports[accObjectIndex].inProgress = false;
            renderAlertMessage(result.errors)
            this.setState({ agingReports });
          }
        });
      }
    }
    else{
      this.setState({
        activeAccounts: this.state.activeAccounts.filter(acc_id => id !== acc_id)
      })
    }
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getAgingReports();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getAgingReports();
        }
      );
    }
  };

  onInnerTableChange = (pagination, filters, sorter, id) => {
    const innerTableOptions = { pagination, filters, sorter };
    this.getAccountAgingReport(true, id, innerTableOptions);
  };

  _renderAgingLists = () => {
    const dataPagination = {
      total: this.state.pagination?.total_count || "",
      current: this.state.pagination?.current_page || 1,
      pageSize: this.state.pagination?.per_page || this.state.recordsPerPage,
    };
    return (
      <AgingReportList
        orgData={this.props.orgData}
        rowKey="account_id"
        size="small"
        scroll={{ y: "calc(100vh - 306px)" }}
        // from common mention file we use use it directly and pass the 3 parameters
        navigateToAccount={(account_id) =>
          navigateToAccount(this.state.accounts, account_id, this.props.history)
        }
        expandedRowRender={(record) => (
          <Fragment>
            <Spin spinning={record.inProgress} delay={1000}>
              <AccountAgingReportsList
                orgData={this.props.orgData}
                rowKey={"invoice_number"}
                data={record.accountAgingReports || []}
                scroll ={{ x:"max-content",y:200 }}
                pagination={{
                  total: record.pagination.total_count,
                  current: record.pagination.current_page,
                  pageSize: record.pagination.per_page || this.state.recordsPerPage,
                }}
                size="small"
                account={record.account_id}
                tableChange={(tablePagination, filters, sorter) =>
                  this.onInnerTableChange(
                    tablePagination,
                    filters,
                    sorter,
                    record.account_id
                  )
                }
              />
            </Spin>
          </Fragment>
        )}
        expandedRowKeys={[...this.state.activeAccounts]}
        onExpand={(expanded, record) =>
          this.getAccountAgingReport(expanded, record.account_id)
        }
        data={this.state.agingReports}
        pagination={dataPagination}
        tableChange={(pagination, filter, sorter, currentTable) =>
          this.onTableChange(pagination, filter, sorter, currentTable)
        }
      />
    );
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
        activeAccounts: []
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getAgingReports();
        updateRecordsPerPage(ScreenKeys.AGING_REPORT, value);
      }
    );
  };

  onSearch = (text) => {
    const token = text.trim();
    this.setState(
      {
        token,
        activeAccounts:[]
      },
      () => {
        if (token === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = text;
        }
        this.tableOptions.pagination.current = 1;
        this.getAgingReports();
      }
    );
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };
  handleSearch = (filter) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, {...filter});
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getAgingReports();
      }
    );
  };


  onReset = () => {
    this.setState(
      {
        filterWindow: false,
      })
  }

  render() {
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header" type="flex" justify="space-between">
            <Col >{I18n.t("aging.label")}</Col>
            <Col className="headerOptionDiv">
              {/* <AccountCodesFilter
                SelectedAccountCodes={ this.state.filterPlaceHolder.accountCodes }
                onValueChange={ this.handleFilterPlaceHolderChange }
                accounts={ this.state.accounts }
                size="default"
              /> */}
              {/* <Search
                size="default"
                value={this.state.token || ""}
                placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                  "menu.account"
                )}`}
                onChange={(e) => {
                  const filteredString = removeSpecialCharacters(
                    e.target.value
                  );
                  this.handleOnChange("token", filteredString);
                }}
                onSearch={this.onSearch}
                allowClear
              /> */}
              <Dropdown
                  onVisibleChange={this.handleVisibleChange}
                  visible={this.state.filterWindow}
                  trigger={["click"]}
                  overlay={
                    <div
                      style={{
                        backgroundColor: "white",
                        width: 400,
                        padding: 15,
                        marginTop: 10,
                        marginRight: -12,
                      }}
                      className="boxShadow"
                    >
                      <AgingReportFilter
                        filter={this.state.filterPlaceHolder}
                        onChange={this.handleFilterPlaceHolderChange}
                        onSearch={this.handleSearch}
                        orgData={this.props.orgData}
                        onCancel={this.onReset}
                        warehouseFilter={this.props.warehouseData}
                      />
                    </div>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <Button className="cursorPointer" shape="round">
                    Filter
                    <Icon type="caret-down" />
                  </Button>
                  </div>
                </Dropdown>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Col span={18}>
              <Row type="flex" justify="space-between">
                <Col span={24} align="middle">
                  <AppliedFiltersTags
                    tags={this.state.appliedFiltersTags}
                    clearFilter={() => this.clearFilter()}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row type="flex" gutter={10}  align="end">
                <Col>
                  <ExportReport
                    accounts={this.state.accounts}
                    filterPlaceHolder={this.state.filterPlaceHolder}
                    exportEmail={this.state.exportEmail}
                    handleInputChange={this.handleInputChange}
                    userContext={this.props.userContext}
                  />
                </Col>
                <Col>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    value={this.state.recordsPerPage}
                    defaultValue={AppConfig.ordersPerPage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.reportLoading} delay={1000}>
            <Row>
              <Col>
                <this._renderAgingLists />
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.isExportModalVisible && this.renderEmailExportModal()}
      </div>
    );
  }
}

export const AgingReportsComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.AGING_REPORT;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return <AgingReports warehouseData={warehouseData} {...props} userContext={userContext} recordsPerPage={recordsPerPage} orgData={orgData} />

});

export default AgingReportsComponent;