import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
export const DocumentTypeApi = {
  fetchDocumentType: (organizationId) => {
    let url = ApiUrl(`v2/document_types?type=${"driver"}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { driver_doc_type: "doc_type" },
    });
  },
  saveDocumentType: (data) => {
    let url = ApiUrl(`v2/document_types?type=${'driver'}`)
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  updateDocumentType: (id, data,organizationId) => {
    let url = ApiUrl(`v2/document_types/${id}?type=${"driver"}`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  deleteDocumentType: (id,organizationId) => {
    let url = ApiUrl(`v2/document_types/${id}?type=${"driver"}`)
    return Call("delete", url,{}, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
};