import React, { Component } from "react";
import _ from "lodash";
import { Tabs } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { checkServiceExistance } from "../../helpers/common";
import FuelSurcharges from "./FuelSurcharges";
import BillingConfigurations from "./BillingConfigurations";
import Discounts from "./Discounts";
import CreditLimit from "./CreditLimit";

const { TabPane } = Tabs;
class BillingEventSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account || {},
      accountId: this.props.accountId || "",
      setActiveKey: "billing_setting",
    };
  }

  goToTab = (key) => {
    this.setState({ setActiveKey: key });
  };

  render() {
    return (
      <div className="child-card-container">
        <Tabs
          activeKey={this.state.setActiveKey}
          onChange={this.goToTab}
          type="card"
        >
          {checkServiceExistance("BLCONF") && (
            <TabPane tab={I18n.t("menu.setup")} key="billing_setting" className="settingSubTabScroll">
              <BillingConfigurations
                account={this.state.account}
                goToTab={this.props.goToTab}
                glCodesList={this.props.glCodesList}
                updateBillingConfigs={this.props.updateBillingConfigs}
              />
            </TabPane>
          )}
          {checkServiceExistance("GSIP") && (
            <TabPane tab={I18n.t("discounts.lable")} key="discount">
              <Discounts account={this.state.account} />
            </TabPane>
          )}
          <TabPane tab={I18n.t("menu.fuelSurcharge")} key="fuelSurcharge">
            <FuelSurcharges account={this.state.account} />
          </TabPane>
           {false && checkServiceExistance("ACL") && (
            <TabPane tab={I18n.t("account.credit_limit.label")} key="credit_limit">
              <CreditLimit account={this.state.account} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default BillingEventSettings;
