import React from "react";
import { Button, Col, Icon, Popconfirm, Row, Switch, Tooltip } from "antd";
import PropTypes from "prop-types";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import DeleteIcon from "../../common/DeleteIcon";

class OrgIntegrationsList extends BaseList {
	constructor (props) {
		super(props);
		this.columns = [
			{
				key: "integration_code",
				title: I18n.t("general.code"),
				dataIndex: "integration_code",
				render: (data, record) => <span className="textUpperCase">{ data }</span>,
				width: "45%",
			},
			// {
			// 	key: "integration_code",
			// 	title: I18n.t("general.status"),
			// 	dataIndex: "status",
			// 	render: (data , record) => (
			// 		<span>
			// 			<Tooltip placement="topLeft" title="Change Status">
			// 				<Popconfirm
			// 					placement="topRight"
			// 					title={ I18n.t('messages.change_status') }
			// 					onConfirm={ () => this.props.statusChange(record.id) }
			// 					okText="Yes"
			// 					cancelText="No"
			// 				>
			// 					<Switch checked={ data.toLowerCase() === 'active' } />
			// 				</Popconfirm>
			// 			</Tooltip>
			// 		</span>
			// 	),
			// 	width: "22.5%",
			// },
			// {
			// 	key: I18n.t("general.authorization"),
			// 	title: I18n.t("general.authorization"),
			// 	dataIndex: "settings",
			// 	render: (data, record) => {
			// 		if (data) {
			// 			return (
			// 				<Button disabled={true} style={{
			// 					background: "rgb(76 209 55 / 65%)",
			// 					color: "#fff",
			// 				}}>
			// 					{ I18n.t("general.authorized") }</Button>
			// 			);

			// 		} else {
			// 			return (
			// 				<Button loading={ this.props.currentAuthorization && this.props.currentAuthorization == record.integration_code } disabled={ !record.status } type="primary"
			// 					onClick={ () => this.props.onAuthorization(record) }>
			// 					{ I18n.t("general.authorize") }</Button>

			// 			);
			// 		} 
			// 	},
			// 	width: "45%",
			// },
			{
				key: I18n.t("general.authorization"),
				title: I18n.t("general.authorization"),
				render: (data, record) => {
					const {id} = data;
					return(
						<Button type="primary" onClick={()=> this.props.billingAccountIntegration(id)} icon='deployment-unit'>
							{I18n.t('general.integrate')}
						</Button>
					)
				},
			},
			{
				key: "actions",
				fixed:"right",
				render: (data , record) => {
					const { handleDelete, handleUnlink } = this.props;
					const { id } = data;
					return (
						<div className="line-actions orgIntegrationsPageActions">
							<Row gutter={8} type="flex">
								<Col> 
								 {record.settings && (
										<Popconfirm
											title={ I18n.t("messages.sure_to" , {field : "Unlink"}) }
											placement="topRight"
											onConfirm={ () => handleUnlink(id) }
										>
										<Tooltip  title={I18n.t("general.unlink")}>
												<Icon type="unlock" style={{
													color : "blue"
												}}  />
												</Tooltip>
										</Popconfirm>
								 )}
								</Col>
								<Col> 
									<Popconfirm
										title={ I18n.t("messages.sure_to" , {field : "Delete"}) }
										placement="left"
										onConfirm={ () => handleDelete(id) }
									>
										<Tooltip  title={I18n.t("general.delete")}>
											<DeleteIcon/>
											&nbsp;&nbsp;&nbsp;
										</Tooltip>
									</Popconfirm>
								</Col>
							</Row>
						</div>
					);
				},
				align: "center",
				width: "10%",
			},
		];
	}
}
export default OrgIntegrationsList;

OrgIntegrationsList.propTypes = {
	handleDelete: PropTypes.func.isRequired,
	handleUnlink: PropTypes.func.isRequired,
	statusChange: PropTypes.func.isRequired,
	currentAuthorization: PropTypes.string,
	onAuthorization: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
};
OrgIntegrationsList.defaultProps = {
	data: [],
	currentAuthorization: "",
	handleDelete: () => { },
	handleUnlink: () => { },
	statusChange: () => { },
	onAuthorization: () => { },
};
