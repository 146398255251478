import React, { Component } from "react";
import _ from "lodash";
import equal from "fast-deep-equal";
// import moment from 'moment';
import Map from "../Tasks/Map";
import {Col, Row } from "../../common/UIComponents";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import { updateStopsOrder } from "../../api/Routes";
import { checkDependancyOrderSeq } from "../../helpers/dispatch";
import Stops from "./Stops";
import RouteLabel from "../../config/RouteLabel";
import { renderAlertMessage } from "../../helpers/common";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class RearrangeStops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeId: props.routeId,
      totalStops: props.stopsData,
      updateStatus: props.updateStatus,
      currentRoute: props.currentRoute || {},
      disabled: false,
      render: false,
      ignoredStopsLength: 0, // to calculate the stop sequence
      stopsData: [],
      driverStops: [],
      ignoredStopTypes: props.currentRoute && props.currentRoute.route_type !== 'line_haul' ? ['WH', 'DV'] : ['DV'],

    };
  }

  componentDidMount() {
    this.setStopsData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!equal(prevProps.stopsData, this.props.stopsData)) {
      this.setData();
    }
  };

  setData = () => {
    this.setState(
        {
          ignoredStopTypes: this.props.currentRoute && this.props.currentRoute.route_type !== 'line_haul' ? ['WH', 'DV'] : ['DV'],
          totalStops: this.props.stopsData,
        },
        () => {
          this.setStopsData();
        }
    );
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { currentRoute } = this.state;
    const route = Object.assign({}, currentRoute, { stops: [...this.state.totalStops] });
    const hasCorrectOrder = checkDependancyOrderSeq(route, result.source.index, result.destination.index);
    if (hasCorrectOrder) {
      const totalStops = [];
      let i = 0;
      if(this.state.firstStop){
        totalStops.push(this.state.firstStop);
        i = 1;
      }

      this.state.completedStops.forEach((stop, index) => {
        i += 1;
        stop.stop_number = i;
        totalStops.push(stop);
      });
      const stopsData = reorder(
          this.state.stopsData,
          result.source.index,
          result.destination.index,
      );
      const sortedStops = [];
      stopsData.forEach((stop, index) => {
        i += 1;
        stop.stop_number = i; // this.state.ignoredStopsLength + index + 1
        totalStops.push(stop);
        sortedStops.push(stop);
      });
      this.state.driverStops.forEach((stop, index) => {
        i += 1;
        stop.stop_number = i;
        totalStops.push(stop);
      });
      if(this.state.lastStop){
        totalStops.push(this.state.lastStop);
      }

      this.setState({ stopsData: sortedStops, totalStops });
    }
  }

  setStopsData = () => {
    const isLinehaulRoute = this.state.currentRoute?.route_type === 'line_haul';
    if(isLinehaulRoute){
      const totalLength = this.state.totalStops.length;
      const firstStop = null;
      const lastStop = null;
      const driverStops = this.state.totalStops.filter(stop => (stop.location && stop.location_type === 'DV' && (!['COMPLETED', 'ARRIVED', 'INCOMPLETE', 'CANCELLED'].includes(stop.status)))); // considering driver stops which are not yet started
      const completedStops = this.state.totalStops.filter(stop => ((['COMPLETED', 'ARRIVED', 'INCOMPLETE', 'CANCELLED'].includes(stop.status))));
      const stopsData = this.state.totalStops.filter(stop => stop.location && !this.state.ignoredStopTypes.includes(stop.location_type) && !['COMPLETED', 'ARRIVED', 'INCOMPLETE', 'CANCELLED'].includes(stop.status));
      const ignoredStopsLength = driverStops.length + completedStops.length + 1; // For warehouse
      this.setState({
        firstStop,
        lastStop,
        completedStops,
        stopsData,
        render: true,
        ignoredStopsLength,
        driverStops,
      });
    } else {
      const totalLength = this.state.totalStops.length;
      const firstStop = this.state.totalStops[0];
      const lastStop = this.state.totalStops[this.state.totalStops.length - 1];
      const driverStops = this.state.totalStops.filter(stop => (stop.location && stop.location_type === 'DV' && (!['COMPLETED', 'ARRIVED', 'INCOMPLETE', 'CANCELLED'].includes(stop.status)))); // considering driver stops which are not yet started
      const completedStops = this.state.totalStops.filter(stop => (stop.location && stop.location_type !== 'WH' && (['COMPLETED', 'ARRIVED', 'INCOMPLETE', 'CANCELLED'].includes(stop.status))));
      const stopsData = this.state.totalStops.filter(stop => stop.location && !this.state.ignoredStopTypes.includes(stop.location_type) && !['COMPLETED', 'ARRIVED', 'INCOMPLETE', 'CANCELLED'].includes(stop.status));
      const ignoredStopsLength = 2 + driverStops.length + completedStops.length - 1; // For warehouse
      this.setState({
        firstStop,
        lastStop,
        completedStops,
        stopsData,
        render: true,
        ignoredStopsLength,
        driverStops,
      });
    }
  }


  back = () => {
    this.props.history.push(`/tasks/${this.props.routeId}`);
  }

  handleDisabledChange = (disabled) => {
    this.setState({ disabled });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message, "success", 10);
    this.props.handleSuccess();
  };

  updateOrder = () => {
    this.setState({
      updateStatus: true,
    });
    const rearranged_data = this.state.totalStops.map((stop, index) =>
        ({ stop_id: stop.id, seq_number: stop.stop_number }));
    const updateStopsData = {
      stops: rearranged_data,
      nav_route_id: this.state.routeId,
      m_sequence_lock: true,
      action_type: RouteLabel.actionLabels.ROUTE_RESEQUENCE,
      action_from: "DISPATCH"
    };
    updateStopsOrder(this.state.routeId, updateStopsData)
        .then((result) => {
          if (result.success) {
            this.handleSaveSuccess(I18n.t('messages.stops_rearranged'));
            this.setState({
              updateStatus: false,
            });
          } else {
            this.setState({
              updateStatus: false,
            });
            renderAlertMessage(result.errors)
          }
        });
  }


  render() {
    const { stopsData } = this.state;
    const mapStops = this.state.totalStops.filter(x => x.location && x.location.l_address && x.location.l_address.coordinates && x.location.l_address.coordinates.length >= 2);
    return (
        <div className='content' style={{ marginTop: '-20px' }}>
          <Row type="flex" justify="space-between">
            {/* <Col sm={18} xs={14}>
             <div id="GoogleMaps">
              {
                (stopsData.length > 0) &&
                <Map data={this.state.stopsData} kms={this.state.disabled} mapElement={<div style={{ height: 480, width: 'auto' }} />} />
              }
            </div>
            </Col> */}
            <Col xs={10}>
              {this.state.render && (
                  <div>
                    <Stops
                        stopsData={this.state.stopsData}
                        onDragEnd={this.onDragEnd}
                        onUpdateOrder={this.updateOrder}
                        isLoading={this.state.updateStatus}
                        ignoredStopsLength={this.state.ignoredStopsLength}
                    />
                  </div>
              )}
            </Col>
            <Col xs={14}>
              {mapStops.length > 0 && <Map
                  data={mapStops}
                  kms={this.state.disabled}
                  // getCurrentStop={id => this.getCurrentStop(id)}
                  getCurrentStop={id => {}}
                  mapElement={<div style={{ height: '85vh', width: 'auto' }} />}
                  driver={this.state.routeStatus !== 'COMPLETED' ? this.state.driverInfo : ''}
                  routeStatus={this.state.routeStatus}
                  screen="update_stops"
                  route_type={this.state.currentRoute?.route_type ? this.state.currentRoute.route_type : ''}

              />
              }
            </Col>
          </Row>
        </div>
    );
  }
}
export default RearrangeStops;
