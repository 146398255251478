import { isEmpty } from "../common/Common";
import AppConfig from "../config/AppConfig";
import userStore from "../stores/UserStore";
import {
  ApiUrl,
  buildQueryString,
  Call,
  ParseGeneralResponse,
  PageParams,
  setObjectToFormData,
  optionalPageParams,
} from "./ApiUtils";

export const ARApi = {
  fetchOpenInvoices: ({
    organization_id,
    page = 1,
    perPage = null,
    account_ids = "",
    sortBy = "",
    sortByType = "",
    from_date = "",
    to_date = "",
  }) => {
    let url = ApiUrl(
      `v2/account_receivables/ar_get_account_open_balances?organization_id=${organization_id}&operation_code=ARI&${optionalPageParams(
        page,
        perPage,
        false
      )}`
    );
    if (!isEmpty(account_ids)) {
      url = `${url}&account_ids=${account_ids}`;
    }else if(from_date && to_date){
      url = `${url}&from_date=${from_date}&to_date=${to_date}`;
    }
    if (!isEmpty(sortBy) && !isEmpty(sortByType)) {
      url = `${url}&sort_by=${sortBy}&sort_order=${sortByType}`;
    }
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        account_invoices_details: "account_invoices_details",
        pagination: "meta.pagination",
      },
    });
  },
  fetchInvoiceDetails: ({
    organization_id = "",
    account_id = "",
    check_ach_no = "",
    view_type= "SUMMARY",
    page = 1,
    perPage = null,
    search_type= 'CHECK',
    sortBy= "",
    sortByType= ""
  }) => {
    let url = ApiUrl(
      `v2/account_receivables/get_ar_open_account_invoices?organization_id=${organization_id}&account_id=${account_id}&view_type=${view_type}&${optionalPageParams(page, perPage)}`
    );
    if (check_ach_no) {
      url = `${url}&check_ach_no=${check_ach_no}&search_type=${search_type}`;
    }
    if(!isEmpty(sortBy) && !isEmpty(sortByType)){
	    url = `${url}&sort_by=${sortBy}&sort_order=${sortByType}`;
    }
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        ar_invoices: "ar_open_account_invoices",
        pagination: "meta.pagination",
      },
    });
  },
  fetchOpenCheques: ({ organization_id = "", account_id = "", account_invoice_ids=[] }) => {
    let url = ApiUrl(
      `v2/account_receivables/ar_get_opned_account_checks?organization_id=${organization_id}&account_id=${account_id}`
    );
    if(account_invoice_ids.length > 0){
      url = `${url}&account_invoice_ids=${account_invoice_ids.join(',')}`;
    }
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        ar_invoices: "ar_check_details",
        ar_credit_notes: "ar_credit_notes",
        pagination: "meta.pagination",
      },
    });
  },
  applyPayment: (payload) => {
    const url = ApiUrl("v2/account_receivables/ar_clear_invoice_amounts");
    //const formData = setObjectToFormData(payload);
    const formData = payload
    return Call("post", url, formData, ParseGeneralResponse, {
      responseDataKeys: { 
        batch_account_payment: "batch_account_payment",         
        warnings: 'warnings',
    },
    });
  },
  addExtraCheckToInvoice: (payload) => {
    const url = ApiUrl("v2/account_receivables/add_extra_check_to_invoice");
    const formData = setObjectToFormData(payload);
    return Call("post", url, formData, ParseGeneralResponse, {
      responseDataKeys: { 
        batch_account_payment: "batch_account_payment",         
        warnings: 'warnings',
    },
    });
  },
  paidInvoiceLines: ({
    organization_id = "",
    account_id = "",
    check_ach_no = "",
    page = 1,
    perPage = null,
    search_type= 'CHECK',
    sortBy = "",
    sortByType = ""
  }) => {
    let url = ApiUrl(
      `v2/account_receivables/get_closed_account_invoices?${PageParams(page,perPage)}&organization_id=${organization_id}&account_id=${account_id}`
    );
    if (check_ach_no) {
      url = `${url}&check_ach_no=${check_ach_no}&search_type=${search_type}`;
    }
    if(!isEmpty(sortBy) && !isEmpty(sortByType)){
	    url = `${url}&sort_by=${sortBy}&sort_order=${sortByType}`;
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { lines: "ar_check_invoices", 
      pagination: "meta.pagination"
    },
    });
  },
  create: (payload) => {
    payload.operation_code = "ROCR";
    const url = ApiUrl("v2/order_templates");
    return Call("post", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        template: "order_template",
      },
    });
  },
  update: (id, payload) => {
    payload.operation_code = "ROU";
    const url = ApiUrl(`v2/order_templates/${id}`);
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        template: "order_template",
      },
    });
  },
  revertDetailedPaidPayments: ({
    customer_order_ids = "",
    user_id = ""
  }) => {
    const url = ApiUrl(
      `v2/account_receivables/reverting_the_check_invoices?customer_order_ids=${customer_order_ids}&user_id=${user_id}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "", 
     },
    });
  },
  delete: (id) => {
    const url = ApiUrl("v2/order_templates/" + id);
    return Call(
      "delete",
      url,
      { operation_code: "ROD" },
      ParseGeneralResponse,
      {
        responseDataKeys: { data: "" },
      }
    );
  },
  fetchCheckDetails: (account_id, payload) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(
      `v2/account_receivables/get_account_check_details?organization_id=${organization_id}&account_id=${account_id}`
    );
    const queryString = buildQueryString(payload);
    if(!isEmpty(queryString)){
      url = `${url}&${queryString}`;
    }
   
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  },
  fetchCheckDetailsByCheckNo: (account_id,ar_batch_check_id ='', payload = {}, ar_credit_note_id = '') => {
    const organization_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(
      `v2/account_receivables/get_check_transaction_payments?organization_id=${organization_id}&account_id=${account_id}`
    );
    if(ar_batch_check_id){
      url = `${url}&ar_batch_check_id=${ar_batch_check_id}`; 
    }
    if(ar_credit_note_id){
      url = `${url}&ar_credit_note_id=${ar_credit_note_id}`; 
    }
    if(!payload){
      payload = {}
    }
    const queryString = buildQueryString(payload);
    if(!isEmpty(queryString)){
      url = `${url}&${queryString}`;
    }
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
        pagination: "meta.pagination"
      },
    });
  },
  getExtraPaidChecks: ({
    account_invoice_id = "",
  }) => {
    const url = ApiUrl(
      `v2/account_receivables/get_extra_paid_checks?account_invoice_id=${account_invoice_id}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { lines: "ar_check_details", 
     },
    });
  },
  revertPaidPayments: ({
    ar_check_invoice_id = "",
    user_id = ""
  }) => {
    const url = ApiUrl(
      `v2/account_receivables/reverting_the_check_invoices?ar_check_invoice_id=${ar_check_invoice_id}&user_id=${user_id}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "", 
     },
    });
  },
};
