import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button,Popconfirm } from "../../common/UIComponents";
import I18n from "../../common/I18n";

const OperationsDms = ({ handleExport, handleCancel,handleDelete
}) => {
  const size = 2;
  return (
    <Row className="bottom_fixed">
      <Col className="alignCenter">
        <Button
          type="default"
          className="clearButtonStyle"
          onClick={handleCancel}
          icon="close"
        >
          Clear
        </Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={handleExport} icon="export">
          {I18n.t("general.proceed")}
        </Button>
       
        <Popconfirm
          placement="rightTop"
          title={I18n.t("messages.delete_confirm")}
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" className="buttonStyle" icon="delete">
            Delete
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};

OperationsDms.propTypes = {
  handleCancel: PropTypes.func.isRequired,

  // changeStatus: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,

};

OperationsDms.defaultProps = {
  handleStatusResult: () => {},
  enableLoading: () => {},
  handleEditOrder: () => {},
};

export default OperationsDms;
