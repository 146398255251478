import React from "react";
import PropTypes from "prop-types";

const CustomIconButton = (props) => {
  const { handleClick, width, height, icon } = props;
  return (
    <img
      src={icon}
      alt="cheque"
      onClick={handleClick}
      style={{ width, height, cursor: "pointer" }}
    />
  );
};

CustomIconButton.propTypes = {
  handleClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
};

CustomIconButton.defaultProps = {
  handleClick: () => {},
  width: "18px",
  height: "18px",
};

export default CustomIconButton;
