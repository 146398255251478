import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Alert, Table, Badge, Button, Checkbox, Col, Form, Icon, Input, Modal, Row, Select, Typography } from "antd";
import { v4 as uuidv4 } from 'uuid';
import TypeOfOrder from "../components/orders/TypeOfOrder";
import MaterialTimePicker from "../components/orders/MaterialTimePicker";
import { getDriverFullDisplayName } from "../helpers/common";
import { fetchUsers } from "../api/UsersApi";
import { alertMessage } from "../common/Common";
import DeleteIcon from "../components/common/DeleteIcon";
import { DummyDriverApi, RecurringRouteApi, TemplatesApi } from "../components/Templates/Api";
import TemplateCronConfig from "../components/Templates/TemplateCronConfig";
import moment from "moment";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import AppConfig from "../config/AppConfig";
import TemplatesList from "../components/Templates/TemplatesList";
import { add, get } from "lodash";
import userStore from "../stores/UserStore";
import I18n from "../common/I18n";
import FormErrors from "../components/common/FormErrors";

const { Text } = Typography;

const RearrangableList = (props) => {
  const { data, onOrderChange, showIndex } = props;
  const [ listData, setListData ] = useState(data);

  useEffect(() => {
    setListData(data);
  }, [ data ]);

  useEffect(() => {
    if (onOrderChange) {
      onOrderChange(listData);
    }
  }, [ listData ]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // Cloning listData to avoid direct state mutation
    const items = [...listData];

    // Reordering logic
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Checks for order constraints
    const isOrderValid = () => {
      // Logic to check the order type and location type
      // For "T" and "M" order types, ensure that 'delivery' is not before 'pickup'
      if (['T', 'M'].includes(reorderedItem.template.order_type)) {
        const prevDeliveryIndex = items.findIndex(i => i.currentLocation.type_of_loc === 'DELIVERY' && i.template.id === reorderedItem.template.id);
        const prevPickupIndex = items.findIndex(i => i.currentLocation.type_of_loc === 'PICKUP' && i.template.id === reorderedItem.template.id);
        if (prevDeliveryIndex !== -1 && prevPickupIndex !== -1 && prevDeliveryIndex < prevPickupIndex) {
          alertMessage('Delivery cannot be before Pickup for the given order type', 'error');
          return false; // Invalid order, don't update the list
        }
      }
      return true; // Order is valid
    };

    // Update the state only if the order is valid
    if (isOrderValid()) {
      setListData(items);
      // Call the onOrderChange prop to update the parent component
      // if (onOrderChange) {
      //   onOrderChange(items);
      // }
    }
  };

  const handleDelete = (templocId) => {
    const getDependentTemplatelocs = (templocId) => {
      // first get the template id from templocId and find similar templocIds
      let similarTemplocs = [];
      const temploc = listData.find((item) => item.id === templocId);
      if (temploc) {
        const templateId = temploc.template.id;
        similarTemplocs = listData.filter((item) => item.template.id === templateId);
      }
      return similarTemplocs;
    };
    const dependentTemplatelocs = getDependentTemplatelocs(templocId);
    // if the lenght is greater than 1, then show the modal
    if (dependentTemplatelocs.length > 1) {
      const uniqueTemplateNames = _.uniqBy(dependentTemplatelocs, 'template.template_name');
      const dependentTemplateNamesString = uniqueTemplateNames.map((item) => item.template.template_name).join(", ");
      Modal.confirm({
        title: I18n.t("messages.delete_confirm"),
        content: <div>Dependent locations from <strong>{ dependentTemplateNamesString }</strong> will also be deleted. Are you sure?</div>,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          // remove the template name matching from the list
          const filteredTemplates = listData.filter((item) => item.template.template_name !== dependentTemplateNamesString);
          setListData(filteredTemplates);
        }
      });
    } else {
      Modal.confirm({
        title: I18n.t("messages.delete_confirm"),
        content: I18n.t("messages.delete_confirm"),
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          const items = listData.filter((item) => item.id !== templocId);
          setListData(items);
        }
      });
    }
  };

  const renderContent = (item, index) => {
    const { content } = item;
    return (
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <Row type="flex" gutter={ 4 } align="middle">
            <Col>
              { content }
            </Col>
          </Row>
        </Col>
        <Col>
          <Icon type="drag" />
        </Col>
      </Row>
    );
  };

  return (
    <div style={ {} }>
      <DragDropContext onDragEnd={ handleDragEnd }>
        <Droppable droppableId="orders">
          { (provided) => (
            <div
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              { listData.map((item, index) => (
                <Draggable key={ item.id } draggableId={ item.id } index={ index }>
                  { (provided, snapshot) => (
                    <Row type="flex" gutter={ 4 } align="middle">
                      <Col>
                        { showIndex && (
                          <Badge count={ index + 1 } style={ { backgroundColor: '#1890ff', color: 'white' } } />
                        ) }
                      </Col>
                      <Col span={ 22 }>
                        <div
                          className={ `order-item ${snapshot.isDragging ? "dragging" : ""}` }
                          ref={ provided.innerRef }
                          { ...provided.draggableProps }
                          { ...provided.dragHandleProps }
                        >
                          { renderContent(item, index) }
                        </div>
                      </Col>
                      { item?.id && !snapshot.isDragging && (
                        <Col>
                          <Row type="flex" gutter={ 4 }>
                            <Col>
                              <DeleteIcon handleClick={ () => handleDelete(item.id) } />
                              &nbsp;&nbsp;&nbsp;
                            </Col>
                          </Row>
                        </Col>
                      ) }
                    </Row>
                  ) }
                </Draggable>
              )) }
              { provided.placeholder }
            </div>
          ) }
        </Droppable>
      </DragDropContext>
      <style>
        { `
          .order-item {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 0px 10px;
                margin-bottom: 5px;
                border-radius: 20px;
                user-select: none;
          }

          .order-item.dragging {
            opacity: 0.5;
            background-color: #e6f7ff;
            border: 1px dashed #1890ff;
          }
        `}
      </style>
    </div>
  );
};

const generateLocationContent = (location, template) => {
  if (location && location.type_of_loc && location.address) {
    const { type_of_loc, address } = location;
    const getAddressString = (address) => {
      return _.compact([ address.address_line1, address.city, address.state, address.zipcode ]).join(", ");
    };
    return (
      <Row>
        <Col>
          <Row type="flex" align="middle" gutter={ 4 } >

            <Col>
              <Text strong>{ template.template_name }</Text>
            </Col>
            <Col>
              <TypeOfOrder
                order={ template }
                orderTypeKey="order_type"
                relatedOrderKey="related_order"
                placement="topLeft"
                showBadge={ true }
                key={ `TypeOfOrder${location.id}` }
              />
            </Col>
            { [ 'T', 'LH', 'M' ].includes(template.order_type) && (
              <Col>
                <Text>({ type_of_loc })</Text>
              </Col>
            ) }
          </Row>
          <Row>
            <Col>
              <Icon type="environment" />
              <Text>{ getAddressString(address) }</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  // Return null if location data is missing or incomplete
  return null;
};


function TemplateRouteCreationForm (props) {
  const { selectedTemplates, form, organization_id, warehouseId, onRouteCreation, drivers: driversList, activeRecord } = props;
  const [ templateOrders, setTemplateOrders ] = useState([]);
  const [ schedulingConfig, setSchedulingConfig ] = useState({});
  const [ isSaving, setIsSaving ] = useState(false);
  const [ showSearchTemplates, setShowSearchTemplates ] = useState(false);
  const [ displayCOnfiguration, setDisplayCOnfiugration ] = useState({});
  const [ errorsList, setErrorsList ] = useState([]);
  const [ isValidatingRoute, setIsValidatingRoute ] = useState(false);
  const [holidaysDates, setHolidaysDates] = useState([]);
  const [workingDates, setWorkingDates] = useState([]);

  useEffect(() => {
    setDisplayCOnfiugration(props.displayConfiguration);
  }, [ props.displayConfiguration ])

  const saveRoute = (payload) => {
    const apiCall = activeRecord ? RecurringRouteApi.update(activeRecord.id, payload) : RecurringRouteApi.create(payload);
    apiCall.then((res) => {
      if (res.success) {
        alertMessage("Recurring route created successfully", "success", 5);
        props.onRouteCreation(res.recurringRoute);
      } else {
        alertMessage(res.errors[ 0 ], "error", 5);
      }
    }
    ).finally(() => {
      setIsSaving(false);
      props.refreshDrivers()
    });
  };

  const validateRoute = (id) => {
    setIsValidatingRoute(true);
    RecurringRouteApi.validate({ id }).then((res) => {
      if (res.success) {
        alertMessage("Route validated successfully", "success", 5);
      } else {
        const { errors } = res;
        if(_.isArray(errors)){
          setErrorsList(errors);
        }
      }
    }
    ).finally(() => {
      setIsValidatingRoute(false);
    });
  };

  const fetchDummyDriver = () => {
    // Replace with your actual API call to fetch a dummy driver
    return DummyDriverApi.get({organization_id: organization_id , warehouse_id: warehouseId});
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (templateOrders.length === 0) {
      if (activeRecord) {
        alertMessage("No locations Found", "error", 5);
      } else {
        alertMessage("Please select at least one template", "error", 5);
      }
      return;
    }

    form.validateFields((err, values) => {
      const startDatetime = `${moment(
        values.routeStartTime
      ).format("HH:mm")}`;
      if (!err) {
        const payload = {
          holidays: holidaysDates,
          en_route_dates: workingDates,
          organization_id: organization_id,
          warehouse_id: warehouseId,
          driver_id: values.selectedDriver,
          route_start_time: startDatetime,
          recurring_templates: templateOrders.map(({ template, currentLocation }) => { return { template_id: template.id, type_of_loc: currentLocation.type_of_loc, currentLocation }; }),
          schedule_config: schedulingConfig.config,
          route_name: values.routeName,
          // include_wh: values.useWh
        };
        setIsSaving(true);
        // const apiCall = activeRecord ? RecurringRouteApi.update(activeRecord.id, payload) : RecurringRouteApi.create(payload);
        // apiCall.then((res) => {
        //   if (res.success) {
        //     alertMessage("Recurring route created successfully", "success", 5);
        //     props.onRouteCreation(res.recurringRoute);
        //   } else {
        //     alertMessage(res.errors[ 0 ], "error", 5);
        //   }
        // }
        // ).finally(() => {
        //   setIsSaving(false);
        // });
        if (!payload.driver_id) {
          fetchDummyDriver().then(data=> {
            const { dummyDriver } = data;
            payload.driver_id = dummyDriver; // Assuming the dummy driver object has an id field
            saveRoute(payload);
          }).catch(error => {
            console.error("Failed to fetch dummy driver:", error);
            alertMessage("Failed to fetch dummy driver", "error", 5);
          });
        } else {
          // If driver_id is not empty, proceed to save route
          saveRoute(payload);
        }
      } else {
        console.log("Form data:", err);
      }
    });
  };


  useEffect(() => {
    let locationsData = [];
    const { form } = props;
    if (activeRecord) {
      locationsData = _.map(activeRecord.recurring_templates, (item) => {
        const fullTemplate = _.find(activeRecord.templates, { _id: item.template_id });
        return {
          id: uuidv4(),
          content: generateLocationContent(item.template.locations, fullTemplate),
          template: fullTemplate,
          currentLocation: item.template.locations
        };
      }
      );

      form.setFieldsValue({
        routeName: activeRecord.route_name,
        selectedDriver: activeRecord.driver_id,
        routeStartTime: moment(activeRecord.route_start_time, AppConfig.time_format),
        // useWh: activeRecord.include_wh,
      });
    }
    else {
      locationsData = _.flatMap(selectedTemplates, (template) => {
        return template.locations.map((location) => {
          return {
            id: uuidv4(),
            content: generateLocationContent(location, template),
            template: template,
            currentLocation: location
          };
        });
      }
      );
    }
    setTemplateOrders(locationsData);
  }, [ selectedTemplates ]);

  const onItemDelete = (id) => {
    console.log("onItemDelete", id);
  };

  const onConfigChange = (config) => {
    setSchedulingConfig(config);
  };

  const onHolidayChange = (dates) => {
    setHolidaysDates(dates);
  };

  const onWorkingDateChange = (dates) => {
    setWorkingDates(dates);
  };

  const onOrderChange = (data) => {
    setTemplateOrders(data);
  };

  const addTemplate = (template) => {
    const locationsData = _.flatMap([ template ], (template) => {
      return template.locations.map((location) => {
        return {
          id: uuidv4(),
          content: generateLocationContent(location, template),
          template: template,
          currentLocation: location
        };
      });
    }
    );
    alertMessage("Template added successfully", "success", 5);
    setTemplateOrders([ ...templateOrders, ...locationsData ]);
    setShowSearchTemplates(false);
  };

  const attachCodes = (data) => {
    const { accounts = [], warehouses = [] } = props;
    return data.map((template) => {
      const account = accounts.find(
        (account) => account.id === template.account_id
      );
      const warehouse = warehouses.find(
        (warehouse) => warehouse.id === template.warehouse_id
      );
      return {
        ...template,
        selected_account: account ? account : "",
        selected_warehouse: warehouse ? warehouse : "",
      };
    });
  };

  const TemplateSearchForm = () => {
    const [ searchText, setSearchText ] = useState('');
    const [ selectedTemplates, setSelectedTemplates ] = useState([]);
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ searchedTemplates, setSearchedTemplates ] = useState([]);

    useEffect(() => {
      if (showSearchTemplates) {
        getTemplates(searchText);

      }
    }, [ showSearchTemplates ]);

    const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    };

    const handleAddingMultipleTemplates = () => {
      const templates = _.filter(searchedTemplates, (template) => {
        return _.includes(selectedRowKeys, template._id);
      });
      const locationsData = templates.flatMap((template) => {
        return template.locations.map((location) => {
          return {
            id: uuidv4(),
            content: generateLocationContent(location, template),
            template: template,
            currentLocation: location
          };
        });
      });

      alertMessage("Templates added successfully", "success", 5);
      setTemplateOrders([ ...templateOrders, ...locationsData ]);
      setSelectedRowKeys([]);
      setShowSearchTemplates(false);
    };

    const getTemplates = (searchText) => {
      setIsLoading(true);
      let page = 1;
      let perPage = null;
      let sort = "id";
      let order = "descend";
      const orgId = userStore.getStateValue("selectedOrg");
      const payload = {
        page,
        per_page: perPage,
        sort,
        order,
        organization_id: orgId,
        template_name: searchText
      };

      TemplatesApi.fetch(payload)
        .then((result) => {
          if (result.success) {
            setSearchedTemplates(
              attachCodes(result.templates)
            );
            console.log("result", result);
          } else {
            alertMessage(result.errors[ 0 ], "error", 5);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };


    return (
      <Modal
        visible={ showSearchTemplates }
        title="Search Templates"
        onCancel={ () => setShowSearchTemplates(false) }
        footer={ null }
        width={1250}
      >
        <Input.Search
          placeholder="Search by template name"
          value={ searchText }
          onChange={ (e) => setSearchText(e.target.value) }
          // allowClear
          onSearch={ () => {
            getTemplates(searchText);
          } }
          enterButton
          style={ { marginBottom: 16 } }
        />

        <TemplatesList
          data={ searchedTemplates }
          scroll = {{y:"calc(100vh - 270px)",x: "max-content"}}
          displayConfiguration={ displayCOnfiguration }
          screen_from={ "templateSearch" }
          isLoading={ isLoading }
          navigateToAccount={ () => { } }
          tableChange={ (pagination, filter, sorter, currentTable) =>
            onTableChange(pagination, sorter, currentTable)
          }
          addTemplate={ (id) => {
            const template = _.find(searchedTemplates, { _id: id });
            setSelectedTemplates([ ...selectedTemplates, template ]);
            setSelectedRowKeys([]);
            addTemplate(template);
          } }
          rowSelection={ {
            selectedRowKeys,
            onChange: handleRowSelectionChange,
          } }
          
          organizationSettings={ {} }
          
        />
        { selectedRowKeys.length > 0 && (
          <Button type="primary" onClick={ () => { handleAddingMultipleTemplates(); } }>
            Add Selected Templates
          </Button>
        ) }
      </Modal>
    );
  };
  return (
    <Form onSubmit={ (e) => handleSave(e) } className="template-route-creation-form" layout="vertical" >
      <Row gutter={ 8 } >
        <Col span={ 16 } style={ { paddingRight: 20, paddingLeft: 20 } }>
          <Row>
            <Col>
              <Row type="flex" justify="space-between" align="middle" gutter={ 8 } style={ { marginBottom: 10 } } >
                <Col>
                  <Title level={ 4 } strong>Selected locations</Title>
                </Col>
                <Col >
                  {  (
                  <Alert
                    message={ templateOrders.length > 0 ? "Drag and drop locations in the order to be visited" : "Please select or add templates to create route." }
                    type={ templateOrders.length > 0 ? "info" : "warning"}
                    showIcon
                    style={ { marginBottom: 10 } }
                  />
                  ) }
                </Col>
                <Col>
                  <Button type="primary" icon="plus" onClick={ () => setShowSearchTemplates(true) } >
                    Add Templates
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <RearrangableList data={ templateOrders } onOrderChange={ onOrderChange } onItemDelete={ onItemDelete } onSave={ handleSave } showIndex={ true } />
            </Col>
          </Row>
        </Col>
        <Col span={ 8 }>
          <Row>
            <Col>
              <Row gutter={ [ 8, 8 ] } justify="center">
                <Col>
                  <Form.Item label="Template Route Name">
                    { form.getFieldDecorator("routeName", {
                      rules: [ { required: true, message: "Please enter valid input!" } ]
                    })(
                      <Input />
                    ) }
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Driver">
                    { form.getFieldDecorator("selectedDriver", {
                      rules: [ { message: "Please select driver!", initialValue: "" } ]
                    })(
                      <Select
                        style={ { width: "100%" } }
                        showSearch
                        filterOption={ (input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select Driver"
                      >
                        <Select.Option key={ "" } value={ "" }>
                          { "-- Select Driver --" }
                        </Select.Option>
                        { driversList.map((driver) => (
                          <Select.Option key={ driver.id } value={ driver.id }>
                            { getDriverFullDisplayName(driver) }
                          </Select.Option>
                        )) }
                      </Select>

                    ) }
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Route Start Time" required>
                    { form.getFieldDecorator("routeStartTime", {
                      rules: [ { required: true, message: "Please select route start time!" } ]
                    })(
                      <MaterialTimePicker
                        className="smallMuiTimePicker"
                        style={ { width: "100%" } }
                        // onChange={ (e) => setRouteStartTime(e) }
                        computeDisabledHours={ () => []
                        }
                        computeDisabledMinutes={ () => [] }
                        isMilitaryTime={ false }
                      />
                    ) }
                  </Form.Item>
                </Col>
                <Col>
                  <TemplateCronConfig activeRecord={ activeRecord } isFromRouteCreation onConfigChange={ onConfigChange } onHolidayChange={onHolidayChange}    onWorkingDateChange={onWorkingDateChange}          />
                </Col>
                {/* <Col>
                  { form.getFieldDecorator("useWh", {
                    valuePropName: "checked",
                    initialValue: true
                  })(<Checkbox>Include Warehouse</Checkbox>)
                  }
                </Col> */}
              </Row>
            </Col>

            <Col style={ { marginTop: 20 } }>
              <Row gutter={ 4 } type="flex" justify="center">
                <Col>
                  <Button type="danger" onClick={ props.onCancel } icon="close" disabled={ isValidatingRoute || isSaving } >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" loading={ isSaving } icon="save" disabled={ isValidatingRoute } >
                    Save 
                  </Button>
                </Col>
                { activeRecord && (
                  <Col>
                    <Button type="primary" loading={ isValidatingRoute } icon="branches" onClick={ () => validateRoute(activeRecord.id) } >
                      {isValidatingRoute ? "Validating" : "Validate"}
                    </Button>
                  </Col>
                ) }
              </Row>
            </Col>
          </Row>
        </Col>

      </Row>
      <Row>{ FormErrors([ ...errorsList ]) }</Row>
      <TemplateSearchForm />
    </Form>
  );
}

const WrappedTemplateRouteCreationForm = Form.create()(TemplateRouteCreationForm);


export default WrappedTemplateRouteCreationForm;

