import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popover } from "../../common/UIComponents";
import { withRouter } from "react-router";
import userStore from "../../stores/UserStore";
import { checkNegitive } from "../../common/Common";
import BaseList from "../BaseList";
import CopyableContent from "../common/CopyableContent";
import I18n from "../../common/I18n";

const CreditInvoiceTable = ({ data = [], pagination = {} }) => {
  const columns = [
    {
      title: I18n.t("invoices.number"),
      dataIndex: "account_invoice_number",
      key: "account_invoice_number",
      render: (data) => <CopyableContent text={data} />,
      width: 110,
    },
    {
      title: I18n.t("credit_notes.credit_amount"),
      dataIndex: "invoice_credit_amount",
      key: "invoice_credit_amount",
      width: 140,
      render: (data) => (data ? checkNegitive(data) : ""),
      align: "right",
    },
    // {
    //   title: I18n.t("batch_management.remittances_cleared"),
    //   dataIndex: "cleared_amount",
    //   key: "cleared_amount",
    //   render: (data) => (data ? checkNegitive(data) : ""),
    //   width: 140,
    //   align: "right",
    // },
  ];
  return (
    <BaseList
      rowKey={"account_invoice_id"}
      columns={columns}
      data={data}
      size="small"
      pagination={{ ...pagination }}
      scroll={{ y: 200  }}
    />
  );
};

const CreditInvoices = ({ invoice_details = [] }) => {
  const invoiceNos = _.uniq(invoice_details.map((rec) => rec.account_invoice_number));
  return invoiceNos.length > 0 ? (
    <Popover
      content={
        <div style={{ width: 260, maxWidth: 260, textAlign: 'center' }}>
          <CreditInvoiceTable 
            data={invoice_details} 
            pagination={{ position: "none" }}
          />
        </div>
      }
      title={I18n.t("credit_notes.info")}
      getPopupContainer={(triggerNode) =>
        triggerNode.parentNode
      }>
      <u className="text-decoration-dashed cursorPointer">{invoiceNos[0]} {invoiceNos.length > 1 && <span> ,+{invoiceNos.length - 1}</span>}</u>
    </Popover>
  ) : (
    <Fragment />
  );
};

CreditInvoices.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(CreditInvoices);
