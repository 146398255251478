import React, { Fragment } from "react";
import moment from "moment";
import BaseList from "../BaseList";
import {  compareString, checkNegitive, formatStopUTcTime, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Tooltip, Popover, Link, Row, Text, Col, Button, Typography } from "../../common/UIComponents";
import ExcepionMsgs from "./Exceptions";
import { checkServiceExistance } from "../../helpers/common";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import { checkForSMP } from "../../helpers/billing";
import ConsigneeDetails from "../common/ConsigneeDetails";
import DeleteIcon from '../common/DeleteIcon';
import { renderDriverNotes } from "../../helpers/orders";
import UnlockInvoice from "../billing_screen/UnlockInvoice";
import { getValueFromArrayOfObjects } from '../../helpers/array_functions';
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import TypeOfOrder from "../orders/TypeOfOrder";
import EditBillingDate from "./EditBillingDate";
import ConsolidationButton from "./ConsolidateButton";
import EndUserTitle from "../common/EndUserTitle";
import AppConfig from "../../config/AppConfig";
import { toLower } from "lodash";
import { OrgContext } from "../../context/OrgContext";
import { formatWeight } from '../../helpers/orders';
import { momentTime } from "../../helpers/date_functions";
import ImageViewer from "../common/ImageViewer";
import Copyable from "../common/Copyable";
import ShowInvoice from "../billing_screen/ShowInvoice";

// import { navigateToAccount } from '../../common/Common';

class InvoicesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    if (props) {
      this.setColumns(props);
    }
    this.state = {
      visible: {},
    };
  }
  static contextType = OrgContext;

  handleVisibleChange = (record, popoverVisible) => {
    this.setState((prevState) => ({
      visible: {
        ...prevState.visible,
        [record.order_number]: popoverVisible,
      },
    }));
  };

  onPopOverClose = (order) => {
    this.setState((prevState) => ({
      visible: {
        ...prevState.visible,
        [order]: false,
      },
    }));
  }
  componentDidUpdate(prevProps) {
    const orgContextData = this.context;
    const { endUserName } = orgContextData;
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props , endUserName);
    }
  }

  setColumns = (props , endUserName) => {
    const isMilitaryTime = props?.organizationSettings?.is_military_time == 'true'
    const { filterPlaceHolder, warehouseFilter } = props;
    const selectedwh = warehouseFilter?.selectedWarehouses?.length &&
      _.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses[0]
      : (warehouseFilter?.selectedWarehouses || "");
    const timeZoneId = getValueFromArrayOfObjects(
      (warehouseFilter?.warehouses || []), "id", selectedwh, "timeZoneId"
    )
    this.columns = [
      // {
      //   key: 'order_invoice_number',
      //   title: 'Invoice Number',
      //   dataIndex: 'order_invoice_number',
      // },
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "order_number",
        render: (text, record) => {
          const sch = formatStopUTcTime(
            record.start_date_time,
            record.end_date_time,
            record.tz_short_form,
            false,
            isMilitaryTime
          )

          const actl = formatStopUTcTime(
            record.actual_start_date_time,
            record.actual_end_date_time,
            record.tz_short_form,
            false,
            isMilitaryTime
          )
         const isTransfer =  toLower(record.type_of_order) == "t"
          return (
            <Popover
              placement="topLeft"
              overlayStyle={{ zIndex: 1, width:"22%"}}
              mouseEnterDelay={AppConfig.tooltipDelay}
              visible={this.state.visible[record.order_number] || false}
              onVisibleChange={(popoverVisible) => this.handleVisibleChange(record, popoverVisible)}
              content={isTransfer ? 
                  <Row> <span>Please Verify Order Details</span></Row>
                   :
                  <div>
                  <Row>

                    <Col xs={24}>
                      Scheduled Time :
                      {sch ? sch : "NA"}
                    </Col>
                    <Col xs={24}>
                      Actual Time :
                      {actl ? actl : "NA"}

                    </Col>
                  </Row>
                </div>

              }

            >
              <div>
                {record.order_group_type === "MANUAL" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Tooltip title="Manual Order">
                  <span
                    style={{
                      width: "9px",
                      height: "9px",
                      display: "block",
                      backgroundColor: "#909335",
                      borderRadius: "50%",
                      marginRight: "5px",
                      // marginTop:'15px'
                    }}
                  />
                </Tooltip> */}
                    <span>{record.is_attempted === "true" ? <span className='textRed'>{text} </span> : { text }}</span>{checkForSMP(record)}
                  </div>
                ) : record.order_group_type === "GROUP" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Tooltip title="Manual Order">
                  <span
                    style={{
                      width: "9px",
                      height: "9px",
                      display: "block",
                      backgroundColor: "#395484",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                </Tooltip> */}
                    <CustomerOrderDetails
                      order_no={text}
                      order={{
                        id: record.customer_order_id,
                        customer_order_number: text,
                      }}
                      showEdit={true}
                      editClick={() =>
                        this.props.handleEditOrder(record.customer_order_id)
                      }
                      isAttempted={record.is_attempted}
                        forPopOverClose={this.onPopOverClose}
                    />
                    {checkForSMP(record)}
                    {/* <span
                  onClick={() =>
                    this.props.handleOrderDetailsViewClick(record.customer_order_id)
                  }
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {text}
                </span> */}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Tooltip title="Manual Order">
                  <span
                    style={{
                      width: "9px",
                      height: "9px",
                      display: "block",
                      backgroundColor: "#228a50",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  />
                </Tooltip> */}
                    <CustomerOrderDetails
                      order_no={text}
                      order={{
                        id: record.customer_order_id,
                        customer_order_number: text,
                      }}
                      showEdit={record?.order_group_type !== "TEMPLATE" ? true : false}
                      editClick={() =>
                        this.props.handleEditOrder(record.customer_order_id)
                      }
                      isAttempted={record.is_attempted}
                          forPopOverClose={this.onPopOverClose}
                    />
                    {checkForSMP(record)}
                    {/* <span
                  onClick={() =>
                    this.props.handleOrderDetailsViewClick(record.customer_order_id)
                  }
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {text}
                </span> */}
                  </div>
                )}
              </div>
            </Popover>

            // <div>
            //   {" "}
            //   {text}{" "}
            //   {record.order_group_type !== "ORDER" ? (
            // <Tooltip title={I18n.t("invoices.group_order")}>
            //   {" "}
            //   <img src={docs} style={{ height: 20 }} alt="docs" />
            // </Tooltip>
            //   ) : (
            //     ""
            //   )}
            // </div>
          )
        },

        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'customer_order_number' && filterPlaceHolder.sortOrder,
        minWidth: 150,
        // sorter: (a, b) => compareString(a.order_number, b.order_number),
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex:"type_of_order",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'type_of_order' && filterPlaceHolder.sortOrder,
        render: (data, record) => (
          <div style={{ textAlign: "center" }}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex:"hawb",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'hawb' && filterPlaceHolder.sortOrder,
        render: (text, record) => (
          <CustomerOrderDetails
            displayedItem={record.hawb}
            toShow="hawb"
            order_no={record.order_number}
            order={{
              id: record.customer_order_id,
              customer_order_number: record.order_number,
            }}
            showEdit={true}
            editClick={() =>
              this.props.handleEditOrder(record.customer_order_id)
            }
            isAttempted={record.is_attempted}
          />
        ),
        width: 120
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'mawb' && filterPlaceHolder.sortOrder,
        render: (text, record) => (
          <CustomerOrderDetails
            displayedItem={record.mawb}
            toShow="mawb"
            order_no={record.order_number}
            order={{
              id: record.customer_order_id,
              customer_order_number: record.order_number,
            }}
            showEdit={true}
            editClick={() =>
              this.props.handleEditOrder(record.customer_order_id)
            }
            isAttempted={record.is_attempted}
          />
        ),
        width: 120
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'reference_1' && filterPlaceHolder.sortOrder,
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1" />
        ),
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'reference_2' && filterPlaceHolder.sortOrder,
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2" />
        ),
      },
      {
        key: "customer",
        title: endUserName,
        render: (data , record) => {
          const consigneeRecord = record.customer_details
            ? record.customer_details
            : null;
          return consigneeRecord ? (
            <div>
              <Popover
                title = {<EndUserTitle stringToAppend={I18n.t("general.details")} />}
                content={
                  <ConsigneeDetails
                    record={consigneeRecord}
                    placement="topLeft"
                    key={`consigneeDetails${record.id}`}
                    showAddress={true}
                  />
                }
                // getPopupContainer={(triggerNode) => triggerNode}
              >
                {isEmpty(consigneeRecord.first_name) ? (
                  "N/A"
                ) : (
                  <u className="text-decoration-dashed">
                    {`${consigneeRecord.first_name} ${!isEmpty(consigneeRecord.last_name)
                      ? consigneeRecord.last_name
                      : ""
                      }`}
                  </u>
                )}
              </Popover>
            </div>
          ) : (
            <Fragment />
          );
        },
        minWidth: 150,
        className: "noWrap",
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: true,
        sortOrder: filterPlaceHolder?.sortBy === 'customer' && filterPlaceHolder.sortOrder,
        dataIndex: "customer_details",
      },
      {
        key: "warehouse_name",
        title: I18n.t("location.locations"),
        dataIndex: "warehouse_name",
        render: (text, record) => (
          <div>
            {text}{" "}
            {record.warehouse_code ? `(${record.warehouse_code})` : ""}
          </div>
        ),
        minWidth: 150,
        className: "noWrap"
      },
      {
        key: "account_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        align: "left",
        render: (data) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          const accoutName = getValueFromArrayOfObjects(
            this.props.accounts,
            "code",
            data,
            "name"
          );
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(data);
                      // navigateToAccount(this.props.accounts, data, this.props.history)
                    }
                  }}
                >
                  {!isEmpty(accoutName) ? accoutName : data}
                </Link>
              ) : !isEmpty(accoutName) ? (
                accoutName
              ) : (
                data
              )}
            </div>
          );
        },
        className: 'noWrap',
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),        
        dataIndex: "account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'account_name' && filterPlaceHolder.sortOrder,
        align: "left",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          const accoutName = getValueFromArrayOfObjects(
            this.props.accounts,
            "code",
            data,
            "name"
          );
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                 <Tooltip title={record.account_name} placement="topLeft">{!isEmpty(accoutName) ? accoutName : data}</Tooltip>
                </Link>
              ) : !isEmpty(accoutName) ? (
                accoutName
              ) : (
                data
              )}
            </div>
          );
        },
        className: 'noWrap',
        width: 170,
      },
      {
        key: "datetime",
        title: I18n.t("invoices.completion_date"),
        dataIndex: "datetime",
        render: (data, record) => {
          return (
            <div>{data ? momentTime("MMM Do YYYY" , data , record.time_zone_id) : ""}
              &nbsp;&nbsp;&nbsp;
              {record.status !== "PAYMENT_CONFIRMED" && record.status !== "APPROVED" && (
                <EditBillingDate
                  key={record.id}
                  date={data ? moment.utc(data) : ""}
                  orderId={record.customer_order_id}
                  order_number={record.order_number}
                  onBillingDateUpdated={this.props.getInvoices}
                  time_zone_id={record.time_zone_id}
                  onFinish={this.props.onFinish}
                />
              )}
            </div>
          )
        } /* moment(data).format('MMM Do YYYY') */,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'datetime' && filterPlaceHolder.sortOrder,
        width: 120
        // sorter: (a, b) => new Date(a.datetime) - new Date(b.datetime),
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "los",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'los' && filterPlaceHolder.sortOrder,
        align: "left",
      },
      {
        key: "driver_code",
        title: I18n.t("general.Driver"),
        dataIndex: "driver_code",
        render: (data, record) => {
          const driver_codes = _.uniq(record?.drivers_info ? record.drivers_info.map(rec => rec.driver_code) : []);
          const codesToShow = driver_codes?.length ? driver_codes.join(', ') : data;
          return (
          <div>
            {!isEmpty(record.driver_notes) && record.driver_notes !== "N/A" ? (
              <Popover
                title={I18n.t("order.driver_notes")}
                content={renderDriverNotes(record.driver_notes)}
                placement={"leftTop"}
              >
                <u className="anchor_cursor primaryText">{codesToShow}</u>
              </Popover>
            ) : (
              codesToShow
            )}
          </div>
        )
        },
      },
      {
        key: "amount",
        title: I18n.t("invoices.amount"),
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'amount' && filterPlaceHolder.sortOrder,
        render: (data) => (
          <div style={{ width: 115 }} className="alignRight">
            {data ? checkNegitive(data) : ""}
          </div>
        ),
        className: "alignRight",
        align: "center",
      },
      {
        key: 'status',
        title: I18n.t('general.status'),
        dataIndex: 'status',
        // render: data => (
        //   <div>{data && `${I18n.t(`invoices.status.${data}`)}`}
        //   </div>
        // ),
        render: (data, record) => (
          <div style={{ display: "flex" }}>
            <div>{record.status || ''}</div>
          </div>
        ),
      },
      {
        key: "quantity",
        title: I18n.t("order.qty"),
        dataIndex: "items_count",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'quantity' && filterPlaceHolder.sortOrder,
        render: (data,record) => (
            <div className="alignCenter">{record.quantity || ''}</div>
        ),
        width : 60,
        align: "center",
      },
      {
        key: "driver_notes",
        title: I18n.t("order.driver_notes"),
        dataIndex: "driver_notes",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'driver_notes' && filterPlaceHolder.sortOrder,
        render: (data, record) => (
          <div>{_.get(record, "driver_notes", "")}</div>

        ),
        width: 150
      },
      {
        key: "exception_message",
        title: I18n.t("general.exception_message"),
        dataIndex: "exception_message",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'exception_message' && filterPlaceHolder.sortOrder,
        render: (data, record) => (
          <div style={{color:'red'}}>{_.get(record, "exception_message", "")}</div>

        ),
        width: 150
      },
      {
        key: "company_name",
        title: I18n.t("general.company_name"),
        dataIndex: "company_name",
        render: (data,record) => (
          <div>{_.get(record, "customer_details.company_name", "")}</div>
        ),
        width : 130,
        align: "left",
        sorter:true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'company_name' && filterPlaceHolder.sortOrder,
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
          <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: 'vehicle_type',
        title: "Vehicle type",
        dataIndex: 'vehicle_type',
        render: (data,record) => (
          <div>{record.drivers_info ? record.drivers_info.map(rec => rec.vehicle_type).join(', ') : ''}</div>
        ),
        width: 140
      },
      {
        key: "dim_weight",
        title: I18n.t("aging.dim_weight"),
        dataIndex: "dim_weight",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data) => formatWeight(data),
        align: "right",
        width: 150,
        sortOrder: filterPlaceHolder?.sortBy === 'dim_weight' && filterPlaceHolder.sortOrder,
      },
      {
        key: "weight",
        title: I18n.t("general.weight"),
        dataIndex: "weight",
        render: (data) => formatWeight(data),
        align: "right",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'weight' && filterPlaceHolder.sortOrder,
        width: 80,
      },
      {
        key: "street",
        title: I18n.t("general.street"),
        dataIndex: "street",
        width: 120,
        render: (data, record) => (
          <div style={{ display: "flex" }}>
            <div>
              {record?.customer_details?.l_address?.address_line1 ? record.customer_details.l_address.address_line1 : ""}
            </div>
          </div>
        ),
      },
      {
        key: "city",
        title: I18n.t("general.city"),
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'city' && filterPlaceHolder.sortOrder,
        render: (data, record) => (
          <div style={{ display: "flex" }}>
            <div>
              {record?.customer_details?.l_address?.city ? record.customer_details.l_address.city : ""}
            </div>
          </div>
        ),
        width: 120,
      },
      {
        key: "zipcode",
        title: I18n.t("general.zipcode"),
        dataIndex: "zipcode",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'zipcode' && filterPlaceHolder.sortOrder,
        render: (data, record) => (
          <div style={{ display: "flex" }}>
            <div>
              {record?.customer_details?.l_address?.zipcode ? record.customer_details.l_address.zipcode : ""}
            </div>
          </div>
        ),
      },
      {
        key: "is_pod_signed_bol_exist",
        title: "POD/Signed BOL",
        dataIndex: "is_pod_signed_bol_exist",
        render: (is_pod_signed_bol_exist) => (
          <div style={{ color: is_pod_signed_bol_exist ? 'green' : 'red' , textAlign: 'center' }}>
            { is_pod_signed_bol_exist ? 'Exists' : 'Does Not Exist' }
          </div>
        ),
      },
      {
        key: "actions",
        title: "",
        dataIndex: "customer_order_id",
        render: (id, record, index) => (
          <div>
            <Row gutter={24} type="flex" justify="space-between" align="middle" style={{ overflow: 'hidden' }}>
              <Col span={4}>
                  {checkServiceExistance("BLIND") && (
                    <Tooltip
                      placement="topLeft"
                      title={
                        record.status !== "PAYMENT_CONFIRMED"
                          ? I18n.t("general.manage")
                          : I18n.t("general.view")
                      }
                    >
                      <Icon
                        type="file-text"
                        theme="twoTone"
                        twoToneColor="#1890ff"
                        className="line-actions"
                        onClick={
                          () =>
                            // {

                            record.order_group_type === "MANUAL"
                              ? this.props.editManualInvoice(
                                  record.order_number
                                )
                              : this.props.editClick(
                                  record.customer_order_id,
                                  record
                                )
                          //   this.props.setCurrentSelectedInvoice(record)
                          // }
                        }
                      />
                    </Tooltip>
                  )}
              </Col>
              <Col span={4}>
                {record.is_locked === "true" && (
                  <Tooltip title={I18n.t("general.locked")}>
                    <UnlockInvoice
                      buttonType="primary"
                      isDisabled={!(record.is_locked === "true")}
                      label={I18n.t("invoices.unlock")}
                      invoiceIds={record.id}
                      handleSuccess={this.props.refreshList}
                      displayType="icon"
                      requireAction={false}
                      // styleObj={{ marginTop: -3 }}
                    />{" "}
                  </Tooltip>
                )}
              </Col>
              <Col span={4}>
                {!isEmpty(record.consolidate_order_id) && (
                  <Tooltip
                    title={I18n.t("consolidation.consolidation_details")}
                  >
                    <ConsolidationButton
                      consolidate_order_id={record.consolidate_order_id}
                      consolidation_no={record.consolidation_number}
                      handleSuccess={this.props.getInvoices}
                      displayType="icon"
                      // className={"marginLeft10"}
                      label={I18n.t("consolidation.consolidation_details")}
                      orders={[record]}
                      key={`conslodiate${record.consolidate_order_id}`}
                    />{" "}
                  </Tooltip>
                )}
              </Col>
              <Col span={4}>
                {record.error_messages && record.error_messages.length > 0 && (
                  <Popover
                    title={I18n.t("invoices.exceptions")}
                    content={<ExcepionMsgs errors={record.error_messages} />}
                  >
                    <Icon type="warning" className="textRed fontSize16" />{" "}
                  </Popover>
                )}
              </Col>
              <Col span={4}>
                {checkServiceExistance("BLCONB") && 
                  record.status !== I18n.t("invoices.ready_for_billing") && props.currentStatus !== "ON_HOLD" &&(
                    <Tooltip placement="topLeft" title="View & Print">
                      <Icon
                        type="printer"
                        onClick={() =>
                          record.order_group_type === "MANUAL"
                            ? this.props.onPrintClick(record.order_number)
                            : this.props.onPrintClick(record, id)
                        }
                        className="fontSize18"
                      />{" "}
                    </Tooltip>
                  )}
              </Col>
              <Col span={4}>
                {false && (
                  <Tooltip title="Delete">
                    <Popconfirm
                      placement="left"
                      title={I18n.t("messages.delete_confirm")}
                      onConfirm={() => this.props.deleteClick(id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteIcon className="fontSize20" />
                      &nbsp;&nbsp;&nbsp;
                    </Popconfirm>
                  </Tooltip>
                )}
              </Col>
            </Row>
          </div>
        ),
        fixed: props.isFixed === true ? "right" : "",
        width: 135,
      },
    ];

    const invoiceColAppearance = {
      key: "frequency_invoice_number",
      title: I18n.t("account.billing.invoice_no"),
      dataIndex: "frequency_invoice_number",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: filterPlaceHolder?.sortBy === 'frequency_invoice_number' && filterPlaceHolder.sortOrder,
      render: (text, record) => {
        const seletectedInfo = {
          selectedRows: [record],
        }
        // is_locked should be true beacause it is in billing screen
        return (
          <Row type="flex" gutter={5}>
            {!_.isEmpty(text) &&
              <Col>
                <ShowInvoice
                  showActions={false}
                  inBillingScreen={true}
                  showInvoiceActions={false}
                  account={record.account_id}
                  invoice_no={record.frequency_invoice_number}
                  key={record.frequency_invoice_number}
                  style={{ marginTop: -6 }}
                  invoice_type={record.account_frequency_type}
                  account_invoice_id={record.account_invoice_id}
                  status={record.status}
                  is_locked={true}
                  // checkList={this.props.checkList}
                  approvalDate={record.approval_date}
                  invoiceApprovalDate={record.invoice_approval_date}
                  // enableChecklistApproval={this.props.enableChecklistApproval}
                  renderType="string"
                  // onUnmount={this.props.onUnmount}
                  timeZoneId={timeZoneId}
                  selectedInfo={seletectedInfo}
                />
              </Col>
            }
            <Col span={4}>
              <Copyable text={text} />
            </Col>
          </Row>
        )
      },
      minWidth: 140
    }


    if (props.currentStatus === "APPROVED") {
      this.columns = [invoiceColAppearance, ...this.columns]
    }



    const isBillingPage = props.screen_from === "billing";
    if (!_.isEmpty(props.displayConfiguration) && isBillingPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const billing_list_columns = userConfiguration.billing_list_columns || orgConfiguration.billing_list_columns;
      let columnsToDisplay = [];
      if (
        typeof billing_list_columns !== undefined &&
        Array.isArray(billing_list_columns)
      ) {
        billing_list_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columnsToDisplay.push(foundColumn);
          }
        });
        const columnToAdd = this.columns.filter((col) => ['manage','actions'].includes(col.key));
        if (columnToAdd.length > 0) {
          columnsToDisplay = [...columnsToDisplay, ...columnToAdd]
        }
        this.columns = columnsToDisplay;
      } else {
      }
    }
  };
}

InvoicesList.defaultProps = {
  isFixed: true,
}

export default InvoicesList;