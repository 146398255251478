import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Tooltip } from "../common/UIComponents";
import { renderAlertMessage } from "../helpers/common";
import { saveInvoice } from "../api/Billing";
import { alertMessage, isEmpty } from "./Common";
import I18n from "./I18n";
import ReloadIcon from "./ReloadIcon";
import RegenerationalConfirmationModal from "./RegenerationalConfirmationModal";

const EstimateData = (props) => {
  const { record, showRecalculate, updateEstimateVal } = props;
  const { estimate } = record;
  const [isLoading, setIsLoading] = useState(false);
  // const [modalVisible, setModalVisible] = useState(false);
  // const appointmentDate = _.get(record, "appointments[0].start_datetime", null) || null;

  const handleGenerateQuotation = async (id) => {
    setIsLoading(true);
    const isForQuote = false;
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    try {
      const result = await saveInvoice({
        order_id: record.id,
        is_for_invoice_quote: "true",
        // generated_date,
        current_date: formattedDate
      });

      if (result.success) {
        alertMessage(I18n.t("messages.quote_generated"));
        const invoice =
          !isEmpty(result.invoice) && _.isObject(result.invoice)
            ? result.invoice
            : {};
        const { total_amount = 0 } = invoice;
        console.log("total_amount", total_amount);
        updateEstimateVal(record.id, total_amount);
        setIsLoading(false);
      } else if (result.errors) {
        renderAlertMessage(result.errors);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error generating quotation:", error);
      setIsLoading(false);
    }
  };

  const handleUpdateQuotation =  (result) => {
    const isForQuote = false;
    try {
      if (result.success) {
        alertMessage(I18n.t("messages.quote_generated"));
        const invoice =
          !isEmpty(result.invoice) && _.isObject(result.invoice)
            ? result.invoice
            : {};
        const { total_amount = 0 } = invoice;
        console.log("total_amount", total_amount);
        updateEstimateVal(record.id, total_amount);
        //setIsLoading(false);
      } else if (result.errors) {
        renderAlertMessage(result.errors);
       // setIsLoading(false);
      }
    } catch (error) {
      console.error("Error generating quotation:", error);
      //setIsLoading(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {!isEmpty(estimate) ? <>{estimate} </> : "NA"}
      {showRecalculate ? (
        <Tooltip title={I18n.t("general.recalculate")}>
          <div>
          {/* <RegenerationalConfirmationModal
              onConfirm={(result) => {
                // handleGenerateQuotation(id, generated_date);
                // setModalVisible(false);
                handleUpdateQuotation(result)
              }}
              orderId={record.id}
              handleOpen={() => setModalVisible(true)}
              showReloadIcon={true}
              isLoading={isLoading}
              defaultDate={appointmentDate}
            /> */}
            <ReloadIcon
              spin={isLoading}
              onClick={() => handleGenerateQuotation(record.id)}
              color="blue"
              width="14px"
              height="14px"
              style={{
                marginLeft: 7,
                marginTop: 3,
                cursor: "pointer",
              }}
            />
          </div>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};

EstimateData.prototypes = {
  showRecalculate: PropTypes.bool,
  updateEstimateVal: PropTypes.func,
};
EstimateData.defaultProps = {
  showRecalculate: true,
  updateEstimateVal: () => {},
};
export default EstimateData;
