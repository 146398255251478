import React, { Component } from 'react'
import { Row, Col, Search, Button, Spin } from '../../../common/UIComponents'
import AppConfig from '../../../config/AppConfig'
import BaseModal from '../../BaseModal'
import ContactGroupForm from './ContactGroupForm'
import ContactGroupList from './ContactGroupList'
import { isEmpty, alertMessage } from '../../../common/Common'
import {
  deleteContactGroup,
  fetchContactGroup,
  fetchContactGroupDetails,
  saveContactGroup,
} from '../../../api/ContactGroup'
import I18n from '../../../common/I18n';
import { renderAlertMessage } from '../../../helpers/common'
class ContactGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showFormModal: false,
      account: this.props.account || [],
      data: [],
      currentData: {},
      errors: [],
      pagination: {},
      inProgress: false,
      isAlertClicked: false,
      isEdit: false,
    }
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: '',
    }
  }

  componentDidMount() {
    if (!this.props.isOrgLevel) {
      localStorage.setItem('accountId', this.props.account.id)
    }
    this.getContactGroup()
  }

  componentWillUnmount() {
    localStorage.removeItem('accountId')
  }

  getContactGroup = (initial, cb) => {
    this.setState({ inProgress: true })
    const page = this.tableOptions.pagination.current
    const perPage = initial ? initial.perPage : AppConfig.perPage
    const render_type = 'list'
    const isOrgLevel = this.props.isOrgLevel;
    const account = this.props.account?.id ? this.props.account.id : ''
    fetchContactGroup(page, perPage, render_type, isOrgLevel, account).then((result) => {
      if (result.success) {
        this.setState(
          {
            data: result.contacts_groups || [],
            pagination: result.pagination || {},
            inProgress: false,
          },
          () => {
            if (cb) {
              cb()
            }
          },
        )
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false, data: [], pagination: {} })
      }
    })
  }

  renderFormModal = () => {
    let title
    let isNew
    if (this.state.currentData.id) {
      title = I18n.t('contactGroup.edit')
      isNew = false
    } else {
      title = I18n.t('contactGroup.add')
      isNew = true
    }
    return (
      <BaseModal
        title={title}
        onCancel={() => this.closeFormModal()}
        width="60%"
        style={{ top: 30 }}
        maskClosable={false}
      >
        <ContactGroupForm
          Data={this.state.currentData.id ? this.state.currentData : {}}
          onSuccess={(message) => this.handleSuccess(message)}
          onCancel={() => this.closeFormModal()}
          isNew={isNew}
          selectedContact={this.state.currentData}
          isAlertClicked={this.state.isAlertClicked}
          isOrgLevel={this.props.isOrgLevel}
          isEdit={this.state.isEdit}
          account={this.props.account}
        />
      </BaseModal>
    )
  }

  handleSuccess = (message) => {
    alertMessage(message)
    this.setState({ showFormModal: false, isAlertClicked: false }, () => {
      this.getContactGroup()
    })
  }

  handleEditClick = (id) => {
    this.setState({ isEdit: true })
    fetchContactGroupDetails(id).then((result) => {
      if (result.success) {
        this.setState({
          currentData: result.contacts_group,
          isEdit:true,
          showFormModal: true,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ isEdit: false })
      }
    })
  }

  handleDelete = (id) => {
    this.setState({ inProgress: true })
    deleteContactGroup(id).then((result) => {
      if (result.success) {
        alertMessage(result.message, 'success', 10)
        this.getContactGroup()
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  onClickSubscribe = (id) => {
    fetchContactGroupDetails(id).then((result) => {
      if (result.success) {
        this.setState({
          currentData: result.contacts_group,
          showFormModal: true,
          isAlertClicked: true,
        })
      } else {
        renderAlertMessage(result.errors)
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
        this.tableOptions && this.tableOptions.searchText
          ? this.tableOptions.searchText
          : '',
    }
    if (pagination.current !== currentPage) {
      this.getContactGroup()
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : 'none',
          sortOrder: !isEmpty(sorter.order) ? sorter.order : 'ascend',
        },
        () => {
          this.tableOptions.pagination.current = 1
          this.getContactGroup()
        },
      )
    }
  }

  closeFormModal = () => {
    this.setState(
      {
        showFormModal: false,
        isAlertClicked: false,
        isEdit: false,
        currentData: {},
      },
      () => {
        this.getContactGroup()
      },
    )
  }

  handleAddClick = () => {
    this.setState({ showFormModal: true, currentData: {} })
  }

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.data, ['id', id])
    const currentContactGroup = Object.assign({}, this.state.data[index])
    currentContactGroup.active =
      currentContactGroup.active === true ? false : true
    const accountId = this.props.account?.id ? this.props.account.id : ''
    this.setState({ currentData: currentContactGroup, isNew: false }, () => {
      saveContactGroup(
        this.state.isNew,
        this.state.currentData,
        this.props.isOrgLevel,
        accountId,
      ).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'))
          this.getContactGroup()
        } else {
          alertMessage(result.errors, 'error', 10)
        }
      })
    })
  }

  render() {
    const { pagination } = this.state
    return (
      <div className="content">
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row className="marginBottom20">
            <Col xs={24} className="alignRight">
              <Button
                type="primary"
                onClick={() => this.handleAddClick()}
                icon="plus"
              >
                {I18n.t('contactGroup.add')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContactGroupList
                data={this.state.data}
                scroll = {{y:"calc(100vh - 375px)"}}
                editClick={(id) => this.handleEditClick(id)}
                deleteClick={(id) => this.handleDelete(id)}
                onClickSubscribe={this.onClickSubscribe}
                pagination={{
                  total: pagination.total_count,
                  current: pagination.current_page,
                  pageSize: pagination.per_page,
                }}
                tableChange={(paginationInfo, filters, sorter) =>
                  this.handleTableChange(paginationInfo, filters, sorter)
                }
                statusChange={(id) => this.handleStatusChange(id)}
              />
            </Col>
          </Row>
        </Spin>
        {this.state.showFormModal && this.renderFormModal()}
        {/* {this.state.showEventSubscriptionForm &&
          this.renderEventSubscriptionForm()} */}
      </div>
    )
  }
}
export default ContactGroup
