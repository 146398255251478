import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "../../common/UIComponents";

const OperationDriversList = ({ handleCancel, changeStatus }) => {
  const size = 2;
  return (
    <Row className="bottom_fixed">
      <Col className="alignCenter">
        <Button
          type="danger"
          className="clearButtonStyle"
          onClick={handleCancel}
          icon="close-circle"
        >
          Clear
        </Button>
        &nbsp;&nbsp;
        <Button
          className="assignDriver buttonMitesse"
          onClick={() => changeStatus()}
          // disabled={!(routes.selectedOrderKeys.length > 0)}
          icon="setting"
        >
          Inactive
        </Button>
      </Col>
    </Row>
  );
};

OperationDriversList.propTypes = {
  handleCancel: PropTypes.func.isRequired,

  changeStatus: PropTypes.func.isRequired,
};

OperationDriversList.defaultProps = {
  handleStatusResult: () => {},
  enableLoading: () => {},
  handleEditOrder: () => {},
};

export default OperationDriversList;
