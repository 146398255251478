/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Col,
  Row,
  Icon,
  Input,
  FormItem,
  Modal,
  Checkbox,
  Radio,
  Select,
  Option,
  Spin,
} from "../common/UIComponents";
import { alertMessage, formatePhoneNo, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { fetchSurveys } from "../api/SurveysApi";
import userStore from "../stores/UserStore";
import {
  setOrganizationConfig,
  fetchOrganizationConfigs,
} from "../api/Organisations";
import {
  setAccountConfig,
  fetchAccountConfigs,
  fetchAccount,
} from "../api/Account";
import TimePicker from "../common/TimePicker";
import { Fragment } from "react";
import { fetchForms } from "../api/FormsApi";
import MobileInput from "../components/common/MobileInput";
import { checkServiceExistance, renderAlertMessage, validateMobile } from "../helpers/common";
import ShipperForm from "../components/configurations/ShipperForm";
import ConsigneeForm from "../components/configurations/ConsigneeForm";
import SwitchComponent from "../common/switchComponent";
import MaterialTimePicker from "../components/orders/MaterialTimePicker";
import LabelInfo from "../components/configurations/LabelInfo";

const { confirm } = Modal;

class GeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      surveys: [],
      config: {
        item_remarks_label: "Meter Reading",
      },
      previousConfig: {},
      accountId: this.props.accountId || "",
      account: {},
      podPicConfig: {
        pic_code: "",
        mandatory_for: [],
        required_for: "both"
      },
      communicationPreferenceValues: {
        EMAIL: I18n.t("contactGroup.email_only"),
        SMSES: I18n.t("contactGroup.sms_only"),
        BOTH : I18n.t("general.both") 
      },
      forms: [],
    };
  }

  componentDidMount() {
    this.getSurveys();
    this.getForms();
    if (this.state.accountId !== "") {
      this.getAccountConfigs();
      this.getAccountDetails();
    } else {
      this.getOrganizationConfigs();
    }
  }

  getAccountDetails = () => {
    this.setState({ inProgress: true });
    const { accountId } = this.state;
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        this.setState({
          account: Object.assign({}, this.state.account, result.account),
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getAccountConfigs = () => {
    this.setState({ inProgress: true });
    const { config, previousConfig } = this.state;
    const orgId = userStore.getStateValue("selectedOrg");
    const { accountId } = this.state;
    const  organizationSettings  = this.props;
    fetchAccountConfigs(accountId, orgId).then((result) => {
      if (result.success) {
        const accountConfigurations = result.account_configurations || [];
        accountConfigurations.forEach((setting) => {
          if (
            setting.setting_name === "mobile_number_for_alerts" &&
            setting.setting_value === "false"
          ) {
            config[setting.setting_name] = "";
            previousConfig[setting.setting_name] = "";
          } else {
            config[setting.setting_name] = setting.setting_value;
            previousConfig[setting.setting_name] = setting.setting_value;
          }
        });
        if (
          isEmpty(config.route_delivery_start_time) ||
          isEmpty(config.route_delivery_end_time)
        ) {
          config.route_delivery_start_time =
            organizationSettings?.route_delivery_start_time
              ? organizationSettings.route_delivery_start_time
              : null;
          config.route_delivery_end_time =
            organizationSettings?.route_delivery_end_time
              ? organizationSettings.route_delivery_end_time
              : null;
        }
        if (
          !isEmpty(config.pod_pic_settings) &&
          _.isString(config.pod_pic_settings) &&
          config.pod_pic_settings === "[]"
        ) {
          config.pod_pic_settings = [];
          previousConfig.pod_pic_settings = [];
        }
        if (isEmpty(config.communication_preference)) {
          config.communication_preference = "SMSES";
          previousConfig.communication_preference = "SMSES";
        }
        // if (isEmpty(config.shipper_communication_preference)) {
        //   config.shipper_communication_preference = "SMSES";
        //   previousConfig.shipper_communication_preference = "SMSES";
        // }
        this.setState({
          inProgress: false,
          config,
          previousConfig,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getOrganizationConfigs = () => {
    this.setState({ inProgress: true });
    const { config, previousConfig } = this.state;
    const orgId = userStore.getStateValue("selectedOrg");
    fetchOrganizationConfigs(orgId).then((result) => {
      if (result.success) {
        const orgConfigurations = result.org_configurations || [];
        orgConfigurations.forEach((setting) => {
          if (
            setting.setting_name === "mobile_number_for_alerts" &&
            setting.setting_value === "false"
          ) {
            config[setting.setting_name] = "";
            previousConfig[setting.setting_name] = "";
          } else {
            config[setting.setting_name] = setting.setting_value;
            previousConfig[setting.setting_name] = setting.setting_value;
          }
        });
        if (
          !isEmpty(config.pod_pic_settings) &&
          _.isString(config.pod_pic_settings) &&
          config.pod_pic_settings === "[]"
        ) {
          config.pod_pic_settings = [];
          previousConfig.pod_pic_settings = [];
        }
        this.setState({
          inProgress: false,
          config,
          previousConfig,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getSurveys = () => {
    this.setState({ inProgress: true });
    fetchSurveys("active").then((result) => {
      if (result.success) {
        this.setState({
          surveys: result.surveys.surveys,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getForms = () => {
    this.setState({ inProgress: true });
    fetchForms("active").then((result) => {
      if (result.success) {
        this.setState({
          forms: result.forms.forms,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    this.setState({
      inProgress: true,
    });
    if (this.state.accountId === "") {
      const data = {
        organization_config: this.state.config,
      };
      const digitRegex = /^\d+$/;

      if (data.organization_config.email_for_alerts !== "") {
        const emailRegx =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegx.test(data.organization_config.email_for_alerts)) {
          this.setState({ inProgress: false });
          return alertMessage("Invalid email for alerts", "error", 10);
        }
      }
      const isPassed = validateMobile(
        data.organization_config.mobile_number_for_alerts,
        false
      );
      if (!isPassed) {
        this.setState({ inProgress: false });
        return alertMessage("Invalid mobile number for alerts", "error", 10);
      }
      const isSupportPassed = validateMobile(
        data.organization_config.mobile_number_for_support,
        false
      );
      if (!isSupportPassed) {
        this.setState({ inProgress: false });
        return alertMessage("Invalid mobile number for support", "error", 10);
      }
      data.organization_config.mobile_number_for_alerts = formatePhoneNo(
        data.organization_config.mobile_number_for_alerts
      );

      if (
        data.organization_config.customer_send_feedback === "true" &&
        data.organization_config.feedback === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select feedback", "error", 10);
      }

      if (data.organization_config.customer_send_feedback === "false") {
        data.organization_config.feedback = "";
      }

      if (
        data.organization_config.order_form === "true" &&
        data.organization_config.form_id === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select form", "error", 10);
      }

      if (data.organization_config.order_form === "false") {
        data.organization_config.form_id = "";
      }

      if (data.organization_config.max_orders_per_day_per_driver !== "") {
        if (
          !digitRegex.test(
            data.organization_config.max_orders_per_day_per_driver
          )
        ) {
          this.setState({ inProgress: false });
          return alertMessage("Max orders should be a number", "error", 10);
        }
      }

      if (data.organization_config.max_orders_per_day_per_warehouse !== "") {
        if (
          !digitRegex.test(
            data.organization_config.max_orders_per_day_per_warehouse
          )
        ) {
          this.setState({ inProgress: false });
          return alertMessage("Max orders should be a number", "error", 10);
        }
      }
      if (
        !isEmpty(
          data.organization_config[I18n.t("configurations.driver_pay_gl_code")]
        )
      ) {
        const convertedValue = data.organization_config[
          I18n.t("configurations.driver_pay_gl_code")
        ].replace(" ", "");
        data.organization_config[I18n.t("configurations.driver_pay_gl_code")] =
          convertedValue.toUpperCase();
      }

      setOrganizationConfig(data, orgId).then((result) => {
        if (result.success) {
          this.setState(
            {
              inProgress: false,
              config: Object.assign({}, this.state.config, {
                [result.org_config.org_config.setting_name]:
                  result.org_config.org_config.setting_value,
              }),
            },
            () => {
              window.location.reload();
            }
          );
          this.handleSaveSuccess(result.org_config.message);
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    } else {
      const data = {
        account_config: this.state.config,
        organization_id: orgId,
      };

      const getShipperConfigs = Object.keys(data.account_config)
        .filter((k) => k.indexOf("shipper") == 0)
        .reduce((newData, k) => {
          newData[k] = data.account_config[k];
          return newData;
        }, {});

      if (Object.values(getShipperConfigs).includes("true")) {
        if (
          data.account_config.mobile_number_for_alerts === "" &&
          data.account_config.email_for_alerts === ""
        ) {
          this.setState({ inProgress: false });
          return alertMessage(
            "Please configure either mobile number or email for the shipper updates",
            "error",
            10
          );
        }
      }

      if (data.account_config.email_for_alerts !== "") {
        const emailRegx =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegx.test(data.account_config.email_for_alerts)) {
          this.setState({ inProgress: false });
          return alertMessage("Invalid email for alerts", "error", 10);
        }
      }

      const isPassed = validateMobile(
        data.account_config.mobile_number_for_alerts,
        false
      );
      if (!isPassed) {
        this.setState({ inProgress: false });
        return alertMessage("Invalid mobile number for alerts", "error", 10);
      }
      data.account_config.mobile_number_for_alerts = formatePhoneNo(
        data.account_config.mobile_number_for_alerts
      );

      if (
        data.account_config.customer_send_feedback === "true" &&
        data.account_config.feedback === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select feedback", "error", 10);
      }
      if (
        data.account_config.pickup_customer_send_feedback === "true" &&
        data.account_config.pickup_feedback === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select Pickup feedback", "error", 10);
      }

      if (
        data.account_config.order_form === "true" &&
        data.account_config.form_id === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select form", "error", 10);
      }

      if (
        data.account_config.pickup_order_form === "true" &&
        data.account_config.pickup_form_id === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select pickup form", "error", 10);
      }

      if (data.account_config.customer_send_feedback === "false") {
        data.account_config.feedback = "";
      }

      if (
        data.account_config.order_form === "true" &&
        data.account_config.form_id === ""
      ) {
        this.setState({ inProgress: false });
        return alertMessage("Please select form", "error", 10);
      }

      if (data.account_config.order_form === "false") {
        data.account_config.form_id = "";
      }

      setAccountConfig(data, this.state.accountId).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            accountConfig: Object.assign({}, this.state.accountConfig, {
              [result.acct_config.acct_config.setting_name]:
                result.acct_config.acct_config.setting_value,
            }),
          });
          this.handleSaveSuccess(result.acct_config.message);
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    }
  };

  // gotoAccounts = () => {
  //   this.props.history.push("/accounts");
  // };

  handleCheckBtn = (element, value) => {
    const { config } = this.state;
    if (
      !isEmpty(this.state.accountId) &&
      element === "show_survey_on_driver" &&
      value === "true" &&
      this.state.config.feedback === ""
    ) {
      return alertMessage(
        `Please select feedback on ${I18n.t("customer.label")} notifications`,
        "error",
        10
      );
    }

    if (element === "pickup_order_form" && value === "false") {
      config.pickup_form_id = "";
    }
    if (element === "order_form" && value === "false") {
      config.form_id = "";
    }

    if (element === "customer_send_feedback" && value === "false") {
      this.setState({
        config: Object.assign({}, this.state.config, {
          [element]: value,
          feedback: "",
        }),
      });
    } else if (
      element === "pickup_customer_send_feedback" &&
      value === "false"
    ) {
      this.setState({
        config: Object.assign({}, this.state.config, {
          [element]: value,
          pickup_feedback: "",
        }),
      });
    } else {
      this.setState({
        config: Object.assign({}, this.state.config, {
          [element]: value,
        }),
      });
    }
  };

  _renderShipperUpdates = () => (
    <Row style={{ margin: "10px 10px" }}>
      <ShipperForm
        config={this.state.config}
        handleOnInputChange={this.handleOnInputChange}
        handleCheckBtn={this.handleCheckBtn}
        handleOnSelect={this.handleOnSelect}
        forms={this.state.forms}
        communicationPreferenceValues={this.state.communicationPreferenceValues}
      />
    </Row>
  );

  _renderCustomerNotifications = () => (
    <Row style={{ margin: "10px 10px" }}>
      <ConsigneeForm
        config={this.state.config}
        handleOnChange={this.handleOnInputChange}
        handleCheckBtn={this.handleCheckBtn}
        handleOnSelect={this.handleOnSelect}
        surveys={this.state.surveys}
        communicationPreferenceValues={this.state.communicationPreferenceValues}
      />
    </Row>
  );

  handleTimeChange = (element, value) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: !isEmpty(value) ? moment(value).format("HH:mm") : "",
      }),
    });
  };

  handleOnInputChange = (element, value) => {
    if (
      element === "service_duration" ||
      element === "pod_count_for_pictures" ||
      element === "expedite_time_duration" ||
      element === "time_window_grace_period" ||
      element === "time_window_time_period"
    ) {
      let duration_count = value;
      if (!/^\d+$/.test(duration_count)) {
        duration_count = "";
      }
      this.setState({
        config: Object.assign({}, this.state.config, {
          [element]: duration_count,
        }),
      });
    } else if (element !== "service_duration") {
      this.setState({
        config: Object.assign({}, this.state.config, {
          [element]: value,
        }),
      });
    }
  };

  _renderRouteConfig = () => {
    const { organizationSettings, isMilitaryTime } = this.props;

    return (
      <Row style={{ margin: "10px 10px" }}>
        <Row>
          <div className="settingHeader">
            <span className="textBold">Route Configuration</span>
          </div>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.route_delivery_start_time_lable")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.route_delivery_start_time_desc"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <div style={{ display: "flex" }}>
                      {/* <TimePicker
                      value={
                        this.state.config.route_delivery_start_time
                          ? moment(
                              this.state.config.route_delivery_start_time,
                              "HH:mm"
                            )
                          : null
                      }
                      onChange={(e) =>
                        this.handleTimeChange(
                          I18n.t("configurations.route_delivery_start_time"),
                          e
                        )
                      }
                      minuteStep={1}
                      format={isMilitaryTime ? "HH:mm" : "hh:mm A"}
                      use12Hours={!isMilitaryTime}
                      placeholder="Select Time"
                      showSecond={false}
                    /> */}
                      <MaterialTimePicker
                        style={{ width: "60%" }}
                        value={
                          this.state.config.route_delivery_start_time
                            ? moment(
                                this.state.config.route_delivery_start_time,
                                "HH:mm"
                              )
                            : null
                        }
                        onChange={(e) =>
                          this.handleTimeChange(
                            I18n.t("configurations.route_delivery_start_time"),
                            e
                          )
                        }
                        computeDisabledHours={() => []}
                        computeDisabledMinutes={() => []}
                        isMilitaryTime={isMilitaryTime}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.route_delivery_end_time_lable")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.route_delivery_end_time_desc"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <div style={{ display: "flex" }}>
                      {/* <TimePicker
                      value={
                        this.state.config.route_delivery_end_time
                          ? moment(
                              this.state.config.route_delivery_end_time,
                              "HH:mm"
                            )
                          : null
                      }
                      onChange={(e) =>
                        this.handleTimeChange(
                          I18n.t("configurations.route_delivery_end_time"),
                          e
                        )
                      }
                      minuteStep={1}
                      format={isMilitaryTime ? "HH:mm" : "hh:mm A"}
                      use12Hours={!isMilitaryTime}
                      placeholder="Select Time"
                      showSecond={false}
                    /> */}
                      <MaterialTimePicker
                        style={{ width: "60%" }}
                        value={
                          this.state.config.route_delivery_end_time
                            ? moment(
                                this.state.config.route_delivery_end_time,
                                "HH:mm"
                              )
                            : null
                        }
                        onChange={(e) =>
                          this.handleTimeChange(
                            I18n.t("configurations.route_delivery_end_time"),
                            e
                          )
                        }
                        computeDisabledHours={() => []}
                        computeDisabledMinutes={() => []}
                        isMilitaryTime={isMilitaryTime}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col xs={24} sm={24} md={24} lg={24}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={9}>
                      {I18n.t("configurations.expedite_time_duration_label")}
                      <span className="subTextLable">
                        {I18n.t("configurations.expedite_time_subtext_label")}
                      </span>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14}>
                      <Input
                        value={this.state.config.expedite_time_duration}
                        onChange={(e) =>
                          this.handleOnInputChange(
                            "expedite_time_duration",
                            e.target.value
                          )
                        }
                        type="number"
                        className="marginRight10"
                        style={{ width: "60%" }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col> */}
              {isEmpty(this.state.accountId) && (
                <Fragment>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Row className="configLableRow">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t("configurations.grace_peroid_time_lable")}
                        <span className="subTextLable">
                          {I18n.t(
                            "configurations.service_duration_subtext_label"
                          )}
                        </span>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <Input
                          value={this.state.config.time_window_grace_period}
                          onChange={(e) =>
                            this.handleOnInputChange(
                              "time_window_grace_period",
                              e.target.value
                            )
                          }
                          type="number"
                          className="marginRight10"
                          style={{ width: "60%" }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Row className="configLableRow">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t("configurations.time_window_label")}

                        <span className="subTextLable">
                          {I18n.t(
                            "configurations.service_duration_subtext_label"
                          )}
                        </span>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <Input
                          value={this.state.config.time_window_time_period}
                          onChange={(e) =>
                            this.handleOnInputChange(
                              "time_window_time_period",
                              e.target.value
                            )
                          }
                          type="number"
                          className="marginRight10"
                          style={{ width: "60%" }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Fragment>
              )}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.service_duration_label")}
                    <span className="subTextLable">
                      {I18n.t("configurations.service_duration_subtext_label")}
                    </span>
                      <LabelInfo
                        title={I18n.t(
                          "configurations.definitions.service_duration_desc"
                        )}
                      />{" "}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <Input
                      value={this.state.config.service_duration}
                      onChange={(e) =>
                        this.handleOnInputChange(
                          I18n.t("configurations.service_duration"),
                          e.target.value
                        )
                      }
                      type="number"
                      className="marginRight10"
                      style={{ width: "60%" }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.delivery_zipcodes_label")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.delivery_zipcodes_desc"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <SwitchComponent
                      handleChange={(value) =>
                        this.handleCheckBtn(
                          I18n.t("configurations.delivery_zipcodes"),
                          value
                        )
                      }
                      isChecked={
                        this.state.config[
                          I18n.t("configurations.delivery_zipcodes")
                        ] === "true"
                      }
                      checkedChildren={"Yes"}
                      unCheckedChildren={"No"}
                      checkedValue={"true"}
                      unCheckedValue={"false"}
                    />
                  </Col>
                </Row>
              </Col>
              {isEmpty(this.state.accountId) && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={9}>
                      {I18n.t("configurations.capacity_optimization_label")}
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14}>
                      <SwitchComponent
                        handleChange={(value) =>
                          this.handleCheckBtn(
                            I18n.t("configurations.capacity_optimization_key"),
                            value
                          )
                        }
                        isChecked={
                          this.state.config[
                            I18n.t("configurations.capacity_optimization_key")
                          ] === "true"
                        }
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        checkedValue={"true"}
                        unCheckedValue={"false"}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {isEmpty(this.state.accountId) && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={10}>
                      {I18n.t("configurations.show_sd_mv_jobs_at_wh_label")}
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={13}>
                      <SwitchComponent
                        handleChange={(value) =>
                          this.handleCheckBtn(
                            I18n.t("configurations.show_sd_mv_jobs_at_wh_key"),
                            value
                          )
                        }
                        isChecked={
                          this.state.config[
                            I18n.t("configurations.show_sd_mv_jobs_at_wh_key")
                          ] === "true"
                        }
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        checkedValue={"true"}
                        unCheckedValue={"false"}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {isEmpty(this.state.accountId) && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={10}>
                      {I18n.t("configurations.optimization_based_on_vehicle_type_label")}
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={13}>
                      <SwitchComponent
                        handleChange={(value) =>
                          this.handleCheckBtn(
                            I18n.t("configurations.optimization_based_on_vehicle_type_key"),
                            value
                          )
                        }
                        isChecked={
                          this.state.config[
                            I18n.t("configurations.optimization_based_on_vehicle_type_key")
                          ] === "true"
                        }
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        checkedValue={"true"}
                        unCheckedValue={"false"}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={10}>
                      {I18n.t("configurations.order_priority")}
                      <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.enable_order_priority"
                          )}
                      />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={13}>
                      <SwitchComponent
                        handleChange={(value) =>
                          this.handleCheckBtn(
                            I18n.t("configurations.priority"),
                            value
                          )
                        }
                        isChecked={
                          this.state.config[
                            I18n.t("configurations.priority")
                          ] === "true"
                        }
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        checkedValue={"true"}
                        unCheckedValue={"false"}
                      />
                    </Col>
                  </Row>
                </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              disabled={this.state.config.service_duration ? false : true}
              type="primary"
              className="marginRight10"
              onClick={() => this.handleSave(I18n.t('configurations.route_config'))}
            >Save</Button>
          </div>
        </Row> */}
      </Row>
    );
  };

  handleOnSelect = (element, value) => {
    const { config } = this.state;
    if (element === "pickup_form_id") {
      if (!isEmpty(value)) {
        config.pickup_order_form = "true";
      } else {
        config.is_shipper_pickup_form_mandatory = "false";
        config.pickup_order_form = "false";
      }
    }

    if (element === "form_id") {
      if (!isEmpty(value)) {
        config.order_form = "true";
      } else {
        config.is_shipper_delivery_form_mandatory = "false";
        config.order_form = "false";
      }
    }

    if (element === "feedback") {
      if (!isEmpty(value)) {
        config.customer_send_feedback = "true";
      } else {
        config.customer_send_feedback = "false";
      }
    }

    if (element === "pickup_feedback") {
      if (!isEmpty(value)) {
        config.pickup_customer_send_feedback = "true";
      } else {
        config.pickup_customer_send_feedback = "false";
      }
    }

    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: value,
      }),
    });
  };

  _renderPostDeliveryConfig = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">Proof Of Delivery Configuration</span>
        </div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.pod_delivery_item_pictures_lable")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.pod_delivery_item_pictures_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.pod_delivery_item_pictures"),
                    value
                  )
                }
                isChecked={
                  this.state.config[
                    I18n.t("configurations.pod_delivery_item_pictures")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          {this.state.config.pod_delivery_item_pictures ===
            I18n.t("common.true") && (
            <>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.pod_picture_settings")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.pod_picture_settings_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
              </Row>
              <Row>
                {this._renderAllPodPicConfigs()}
                {this._renderPodPicSettings()}
                {this._renderIncludePodsOnOrderCompletionMails()}
                {this._renderIncludePodsOnInvoiceMails()}
                {this._renderIncludePodsOnBolMails()}
              </Row>
            </>
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.pod_signature_label")}
              <LabelInfo
                title={I18n.t("configurations.definitions.pod_signature_desc")}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.pod_signature"),
                    value
                  )
                }
                isChecked={
                  this.state.config[I18n.t("configurations.pod_signature")] ===
                  "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          {this.state.config.pod_signature &&
          this.state.config.pod_signature === I18n.t("common.true") ? (
            <>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.signer_title_label")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.signer_title_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn(
                        I18n.t("configurations.is_signature_title_enabled"),
                        value
                      )
                    }
                    isChecked={
                      this.state.config[
                        I18n.t("configurations.is_signature_title_enabled")
                      ] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
              {this.state.config.is_signature_title_enabled &&
              this.state.config.is_signature_title_enabled ===
                I18n.t("common.true") ? (
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.signer_title")}
                    <LabelInfo
                      title={I18n.t("configurations.definitions.signer_desc")}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Row type="flex" justify="space-around">
                    <Col>
                      <Input
                        className="inputConfig"
                        value={this.state.config.signature_label}
                        onChange={(e) => {
                          this.setState({
                            config: {
                              ...this.state.config,
                              signature_label: e.target.value,
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Row>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
      {/* <Row>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              // disabled={this.state.config.service_duration ? false : true}
              type="primary"
              className="marginRight10"
              onClick={() => this.handleSave(I18n.t('configurations.pod_count_for_pictures'), this.state.config.pod_count_for_pictures)}
            >Save</Button>
          </div>
        </Row> */}
    </Row>
  );

  _renderAllPodPicConfigs = () => {
    if (
      this.state.config.pod_pic_settings &&
      this.state.config.pod_pic_settings.length > 0
    ) {
      return this.state.config.pod_pic_settings.map((podSet, index) => (
        <Row style={{ marginBottom: "3px" }} key={index}>
          {/* <Col lg={8} style={{ paddingRight: '3px' }}>
            <Input
              className="disabledInput"
              value={podSet.pic_title}
              disabled
            />
          </Col> */}
          <Col lg={6} style={{ paddingRight: "1rem" }}>
            <Input className="disabledInput" value={podSet.pic_code} disabled />
          </Col>
          {/* <Col lg={7}>
            <Button
              disabled
              className={
                podSet.mandatory === I18n.t('common.false') ? 'btnConfig btnConfigActive disabledBtn' : 'btnConfig'
              }
              onClick={() => this.handlePodInputChange('mandatory', I18n.t('common.false'))}
            >Optional
            </Button>
            <Button
              disabled
              className={
                podSet.mandatory === I18n.t('common.true') ? 'btnConfig btnConfigActive disabledBtn' : 'btnConfig'
              }
              onClick={() => this.handlePodInputChange('mandatory', I18n.t('common.true'))}
            >Mandatory
            </Button>
          </Col> */}
          <Col lg={6} className="padding5 paddingLeft20">
            <Checkbox
              disabled
              checked={
                !!(
                  podSet.mandatory_for &&
                  podSet.mandatory_for.includes("driver")
                )
              }
            >
              Driver
            </Checkbox>
            <Checkbox
              disabled
              checked={
                !!(
                  podSet.mandatory_for &&
                  podSet.mandatory_for.includes("billing")
                )
              }
            >
              Billing
            </Checkbox>
          </Col>
          <Col
            lg={4}
          >
            <Select
              disabled
              style={{ width: '6rem' }}
              value={podSet.required_for ?? null}
              onChange={(val) => this.handlePodOrderTypeChange(val)}
            >
              <Option key="delivery" value="delivery">Delivery</Option>
              <Option key="pickup" value="pickup">Pickup</Option>
              <Option key="both" value="both">Both</Option>
            </Select>
          </Col>
          <Col lg={4}>
            <Button
              className="btnConfig podBtn"
              onClick={() => this.handleRemovePodConfig(index)}
            >
              <Icon type="close" />
            </Button>
          </Col>
        </Row>
      ));
    }
    return <React.Fragment />;
  };

  _renderIncludePodsOnOrderCompletionMails = () => (
    <Row className="configLableRow">
    <Col xs={23} sm={23} md={23} lg={9}>
      {I18n.t("configurations.include_pods_on_order_completion_mails_label")}
      <LabelInfo
         title={I18n.t("configurations.definitions.include_pods_on_order_completion_mails")}
      />
    </Col>
    <Col xs={1} sm={1} md={1} lg={1}>
      :
    </Col>
    <Col xs={24} sm={24} md={24} lg={14}>
      <SwitchComponent
        handleChange={(value) =>
          this.handleCheckBtn(
            I18n.t("configurations.include_pods_on_order_completion_mails"),
            value
          )
        }
        isChecked={
          this.state.config[
            I18n.t("configurations.include_pods_on_order_completion_mails")
          ] === "true"
        }
        checkedChildren={"Yes"}
        unCheckedChildren={"No"}
        checkedValue={"true"}
        unCheckedValue={"false"}
      />
    </Col>
  </Row>
  );

  _renderIncludePodsOnInvoiceMails = () => (
    <Row className="configLableRow">
    <Col xs={23} sm={23} md={23} lg={9}>
      {I18n.t("configurations.include_pods_on_invoice_mails_label")}
      <LabelInfo
        title={I18n.t("configurations.definitions.include_pods_on_invoice_mails")}
      />
    </Col>
    <Col xs={1} sm={1} md={1} lg={1}>
      :
    </Col>
    <Col xs={24} sm={24} md={24} lg={14}>
      <SwitchComponent
        handleChange={(value) =>
          this.handleCheckBtn(
            I18n.t("configurations.include_pods_on_invoice_mails"),
            value
          )
        }
        isChecked={
          this.state.config[
            I18n.t("configurations.include_pods_on_invoice_mails")
          ] === "true"
        }
        checkedChildren={"Yes"}
        unCheckedChildren={"No"}
        checkedValue={"true"}
        unCheckedValue={"false"}
      />
    </Col>
  </Row>
  );

  _renderIncludePodsOnBolMails = () => (
    <Row className="configLableRow">
    <Col xs={23} sm={23} md={23} lg={9}>
      {I18n.t("configurations.include_pods_on_bol_mails_label")}
      <LabelInfo
        title={I18n.t("configurations.definitions.include_pods_on_bol_mails")}
      />
    </Col>
    <Col xs={1} sm={1} md={1} lg={1}>
      :
    </Col>
    <Col xs={24} sm={24} md={24} lg={14}>
      <SwitchComponent
        handleChange={(value) =>
          this.handleCheckBtn(
            I18n.t("configurations.include_pods_on_bol_mails"),
            value
          )
        }
        isChecked={
          this.state.config[
            I18n.t("configurations.include_pods_on_bol_mails")
          ] === "true"
        }
        checkedChildren={"Yes"}
        unCheckedChildren={"No"}
        checkedValue={"true"}
        unCheckedValue={"false"}
      />
    </Col>
  </Row>
  );
  handleRemovePodConfig = (index) => {
    const podSettings = [...this.state.config.pod_pic_settings];
    podSettings.splice(index, 1);
    this.setState({
      config: Object.assign({}, this.state.config, {
        pod_pic_settings: [...podSettings],
      }),
    });
  };
  _renderPodPicSettings = () => (
      <Row>
        {/* <Col lg={8} style={{ paddingRight: '3px' }}>
        <FormItem label="Title">
          <Input
            value={this.state.podPicConfig.pic_title}
            onChange={e => this.handlePodInputChange('pic_title', e.target.value)}
            placeholder="Title"
          />
        </FormItem>
      </Col> */}
      <Col lg={6} style={{ paddingRight: "1rem" }}>
        <FormItem
          label={I18n.t("configurations.pod_delivery_item_picture_code_label")}
        >
          <Input
            value={this.state.podPicConfig.pic_code}
            onChange={(e) =>
              this.handlePodInputChange("pic_code", e.target.value)
            }
            placeholder={I18n.t(
              "configurations.pod_delivery_item_picture_code_label"
            )}
          />
        </FormItem>
      </Col>
      {/* <Col lg={7} style={{ marginTop: '1.3rem' }}>
        <Button
          className={
            this.state.podPicConfig.mandatory === I18n.t('common.false') ? 'btnConfig btnConfigActive' : 'btnConfig'
          }
          onClick={() => this.handlePodInputChange('mandatory', I18n.t('common.false'))}
        >Optional
        </Button>
        <Button
          className={
            this.state.podPicConfig.mandatory === I18n.t('common.true') ? 'btnConfig btnConfigActive' : 'btnConfig'
          }
          onClick={() => this.handlePodInputChange('mandatory', I18n.t('common.true'))}
        >Mandatory
        </Button>
      </Col> */}
      <Col
        lg={6}
        className="padding5 paddingLeft20"
      >
        <FormItem
          label={I18n.t("configurations.pod_item_mandatory_for_label")}
        >
        <Checkbox
          checked={this.state.podPicConfig.mandatory_for.includes("driver")}
          onChange={(e) => this.handlePodReferenceChange(e, "driver")}
          >
          Driver
        </Checkbox>
        <Checkbox
          checked={this.state.podPicConfig.mandatory_for.includes("billing")}
          onChange={(e) => this.handlePodReferenceChange(e, "billing")}
          >
          Billing
        </Checkbox>
        </FormItem>
      </Col>
      <Col
        lg={4}
      >
        <FormItem
          label={I18n.t("configurations.pod_item_required_for_label")}
        >
        <Select
          style={{ width: '6rem' }}
          value={this.state.podPicConfig?.required_for ?? "both"}
          onChange={(val) => this.handlePodOrderTypeChange(val)}
        >
          <Option key="delivery" value="delivery">Delivery</Option>
          <Option key="pickup" value="pickup">Pickup</Option>
          <Option key="both" value="both">Both</Option>
        </Select>
        </FormItem>
      </Col>
      <Col lg={4} style={{ marginTop: "1.3rem" }}>
        <Button
          disabled={
            !(
              this.state.podPicConfig.pic_code !== "" &&
              this.state.podPicConfig.mandatory_for.length >= 0
            )
          }
          className="btnConfig podBtn"
          onClick={() => this.handleAddPodConfig()}
        >
          <Icon type="check" />
        </Button>
      </Col>
    </Row>
  );






  handleAddPodConfig = () => {
    const pic_code =  this.state.podPicConfig?.pic_code ? this.state.podPicConfig.pic_code : ''
    const sameCodeRecords = pic_code && this.state.config?.pod_pic_settings ? 
    this.state.config.pod_pic_settings.filter(pic => pic.pic_code && pic.pic_code.toLowerCase() === pic_code.toLowerCase()) : [];
    if(sameCodeRecords.length){
      alertMessage( `Tag with code ${pic_code} already exists`, 'error', 10)
    } else {
      this.setState({
        config: Object.assign({}, this.state.config, {
          pod_pic_settings: this.state.config.pod_pic_settings
            ? [...this.state.config.pod_pic_settings, this.state.podPicConfig]
            : [this.state.podPicConfig],
        }),
        podPicConfig: Object.assign(
          {},
          {
            pic_code: "",
            mandatory_for: [],
            required_for: "both"
          }
        ),
      });
    }
   
  };

  handlePodInputChange = (element, value) => {
    this.setState({
      podPicConfig: Object.assign({}, this.state.podPicConfig, {
        [element]: value,
      }),
    });
  };

  handlePodReferenceChange = (e, value) => {
    let mandatory_for = [...this.state.podPicConfig.mandatory_for, value];
    if (!e.target.checked) {
      mandatory_for = mandatory_for.filter((e) => e !== value);
    }
    this.setState({
      podPicConfig: Object.assign({}, this.state.podPicConfig, {
        mandatory_for,
      }),
    });
  };

  handlePodOrderTypeChange = (value) => {
    let required_for = value;
    this.setState({
      podPicConfig: Object.assign({}, this.state.podPicConfig, {
        required_for,
      }),
    });
  };

  handleLiveNotification = (confirmType) => {
    if (confirmType === "true") {
      this.takeConfirmation("YES");
    }
    if (confirmType === "false") {
      this.takeConfirmation("NO");
    }
  };

  takeConfirmation = (confirmType) => {
    let title = "";
    let content = "";
    if (confirmType === "YES") {
      title = "Enable Live Notification";
      content =
        "Enabling this settings will send text messages to the phone numbers in the order";
    } else {
      title = "Disable Live Notification";
      content =
        "Disabling this settings will send text messages to the configured phone numbers";
    }
    confirm({
      title,
      content,
      onOk: () => {
        if (confirmType === "YES") {
          this.handleCheckBtn(
            I18n.t("configurations.live_notification"),
            I18n.t("common.true")
          );
        } else {
          this.handleCheckBtn(
            I18n.t("configurations.live_notification"),
            I18n.t("common.false")
          );
        }
      },
      onCancel: () => {},
    });
  };

  _render_live_notification = () => {
    if (this.state.accountId === "") {
      return (
        <Row>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={23} lg={9}>
                {I18n.t("configurations.live_notification_label")}
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={14}>
                {/* <Button
                  className={
                    this.state.config.live_notification &&
                    this.state.config.live_notification ===
                      I18n.t("common.true")
                      ? "btnConfig btnConfigActive"
                      : "btnConfig"
                  }
                  onClick={() => this.takeConfirmation("YES")}
                >
                  Yes
                </Button>
                <Button
                  className={
                    this.state.config.live_notification &&
                    this.state.config.live_notification ===
                      I18n.t("common.false")
                      ? "btnConfig btnConfigActive"
                      : "btnConfig"
                  }
                  onClick={() => this.takeConfirmation("NO")}
                >
                  No
                </Button> */}
                <SwitchComponent
                  handleChange={(value) => this.handleLiveNotification(value)}
                  isChecked={this.state.config.live_notification === "true"}
                  checkedChildren={"Yes"}
                  unCheckedChildren={"No"}
                  checkedValue={"true"}
                  unCheckedValue={"false"}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} />
        </Row>
      );
    }
    return <Fragment />;
  };

  _renderIntgrationsConfig = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">Integrations</span>
        </div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.notify_order_status_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.notify_order_status_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.notify_order_status"),
                    value
                  )
                }
                isChecked={
                  this.state.config[
                    I18n.t("configurations.notify_order_status")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={12}>
        <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.move_api_orders_to_pending_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("move_api_orders_to_pending", value)
                }
                isChecked={
                  this.state.config["move_api_orders_to_pending"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Row>
  );

  _renderOrderItemsConfig = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">Order Items Configuration</span>
        </div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              Item Remark
              <LabelInfo title = {I18n.t("configurations.definitions.item_remarks")} />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("item_remarks", value)
                }
                isChecked={this.state.config["item_remarks"] === "true"}
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {this.state.config.item_remarks && this.state.config.item_remarks === I18n.t("common.true") ? (
        <Row>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={23} lg={9}>
                Item Remark Label Name
                <LabelInfo title={I18n.t("configurations.definitions.item_remarks_label")} />
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Row type="flex" justify="space-around">
                <Col>
                  <Input
                    className="inputConfig"
                    name="label_name"
                    value={this.state.config.item_remarks_label}
                    onChange={(e) => {
                      this.setState({
                        config: {
                          ...this.state.config,
                          item_remarks_label: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Row>
  );
    
  _renderCommunicationPreferences = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">Communication Preferences</span>
        </div>
      </Row>
      {this._render_live_notification()}
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.email_for_alerts_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Input
                value={this.state.config.email_for_alerts}
                style={{ width: "60%" }}
                className="marginRight10"
                onChange={(e) =>
                  this.handleOnInputChange(
                    I18n.t("configurations.email_for_alerts"),
                    e.target.value
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} />
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.mobile_number_for_alerts_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <MobileInput
                handleInputChange={(value, data) => {
                  this.handleOnInputChange(
                    I18n.t("configurations.mobile_number_for_alerts"),
                    value
                  );
                }}
                inputStyle={{ width: "60%" }}
                value={this.state.config.mobile_number_for_alerts}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} />
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.mobile_number_for_support_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <MobileInput
                handleInputChange={(value, data) => {
                  this.handleOnInputChange(
                    I18n.t("configurations.mobile_number_for_support"),
                    value
                  );
                }}
                inputStyle={{ width: "60%" }}
                value={this.state.config.mobile_number_for_support}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} />
      </Row>
      {/* <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.max_orders_per_day_per_driver_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>:</Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Input
                  value={this.state.config.max_orders_per_day_per_driver}
                  style={{ width: '60%', marginRight: '10px' }}
                  onChange={e => this.handleOnInputChange(I18n.t('configurations.max_orders_per_day_per_driver'), e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} />
      </Row> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.max_orders_per_day_per_warehouse_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Input
                value={this.state.config.max_orders_per_day_per_warehouse}
                style={{ width: "60%", marginRight: "10px" }}
                onChange={(e) =>
                  this.handleOnInputChange(
                    I18n.t("configurations.max_orders_per_day_per_warehouse"),
                    e.target.value
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} />
      </Row>
      {/* <Row>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              // disabled={this.state.config.service_duration ? false : true}
              type="primary"
              className="marginRight10"
              onClick={() => this.handleSave(I18n.t('configurations.email_and_mobile_alert'))}
            >Save</Button>
          </div>
        </Row> */}
    </Row>
  );

  _miscellaneousConfig = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">
            {I18n.t("configurations.miscellaneous_config")}
          </span>
        </div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.military_time_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.is_military_time"),
                    value
                  )
                }
                isChecked={
                  this.state.config[
                    I18n.t("configurations.is_military_time")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.move_api_orders_to_pending_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("move_api_orders_to_pending", value)
                }
                isChecked={
                  this.state.config["move_api_orders_to_pending"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>  
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.edit_weights_and_pieces_for_pickup_order")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("edit_weights_and_pieces_for_pickup_order", value)
                }
                isChecked={
                  this.state.config["edit_weights_and_pieces_for_pickup_order"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.show_hold_bucket")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("show_hold_bucket", value)
                }
                isChecked={
                  this.state.config["show_hold_bucket"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          {/* <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.predefined_locations_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.predefined_locations"),
                    value
                  )
                }
                isChecked={
                  this.state.config[
                    I18n.t("configurations.predefined_locations")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row> */}
        
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.require_real_time_updates")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.require_real_time_updates"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("require_real_time_updates", value)
                }
                isChecked={
                  this.state.config["require_real_time_updates"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.require_consignee_details")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.require_consignee_details"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("require_consignee_details", value)
                }
                isChecked={
                  this.state.config["require_consignee_details"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={10}>
              {I18n.t("configurations.move_pending_to_received")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.move_pending_to_received"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={13}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("move_pending_to_received", value)
                }
                isChecked={
                  this.state.config["move_pending_to_received"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
          {/* <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.require_insights")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.require_insights"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("require_insights", value)
                }
                isChecked={
                  this.state.config["require_insights"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Row>
  );

  _driverConfig = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">
            {I18n.t("configurations.headings.driver")}
          </span>
        </div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.geofence_exception_for_driver_lable")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.geofence_exception_for_driver_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.geofence_exception_for_driver"),
                    value
                  )
                }
                isChecked={
                  this.state.config[
                    I18n.t("configurations.geofence_exception_for_driver")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.show_survey_on_driver_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.show_survey_on_driver_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn(
                    I18n.t("configurations.show_survey_on_driver"),
                    value
                  )
                }
                isChecked={
                  this.state.config[
                    I18n.t("configurations.show_survey_on_driver")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        {!isEmpty(this.state.accountId) && (<Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.primary_key_of_order")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.show_primary_key_on_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
                <Select
                  style={{ width: "60%" }}
                  value={this.state.config["order_primary_key"]}
                  onChange={(value) =>
                    this.handleOnInputChange("order_primary_key", value)
                  }
                >
                  <Option value="order_number">{I18n.t("order.order_no")}</Option>
                  <Option value="hawb">{I18n.t("general.hawb")}</Option>
                  <Option value="mawb">{I18n.t("general.mawb")}</Option>
                  <Option value="reference_1">{I18n.t("order.reference")}1 </Option>
                  <Option value="reference_2">{I18n.t("order.reference")}2 </Option>
                </Select>
            </Col>
          </Row>
        </Col>)
        }
      </Row>
      {isEmpty(this.state.accountId) && (
        <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.driver_route_notification_label")}
                    <span className="subTextLable">
                      {I18n.t(
                        "configurations.driver_route_notification_subtext_label"
                      )}
                    </span>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <Input
                      value={
                        this.state.config[
                          I18n.t("configurations.driver_route_notification")
                        ] || ""
                      }
                      onChange={(e) =>
                        this.handleOnInputChange(
                          I18n.t("configurations.driver_route_notification"),
                          e.target.value
                        )
                      }
                      type="number"
                      className="marginRight10"
                      style={{ width: "60%" }}
                      min={0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.driver_pay_gl_code_label")}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <Input
                      value={this.state.config.driver_pay_gl_code}
                      style={{ width: "60%", marginRight: "10px" }}
                      className="textUpperCase"
                      onChange={(e) =>
                        this.handleOnInputChange(
                          I18n.t("configurations.driver_pay_gl_code"),
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.show_select_all_at_stop_label")}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <SwitchComponent
                      handleChange={(value) =>
                        this.handleCheckBtn(
                          I18n.t(
                            "configurations.show_select_all_at_stop_key"
                          ),
                          value
                        )
                      }
                      isChecked={
                        this.state.config[
                          I18n.t(
                            "configurations.show_select_all_at_stop_key"
                          )
                        ] === "true"
                      }
                      checkedChildren={"Yes"}
                      unCheckedChildren={"No"}
                      checkedValue={"true"}
                      unCheckedValue={"false"}
                      />
                      {/* <Input
                      value={this.state.config.driver_pay_gl_code}
                      style={{ width: "60%", marginRight: "10px" }}
                      className="textUpperCase"
                      onChange={(e) =>
                        this.handleOnInputChange(
                          I18n.t("configurations.driver_pay_gl_code"),
                          e.target.value
                        )
                      }
                    /> */}
                    </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={10}>
                    {I18n.t("configurations.is_missing_pod_enabled_label")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.is_missing_pod_enabled"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={13}>
                    <SwitchComponent
                      handleChange={(value) =>
                        this.handleCheckBtn(
                          I18n.t(
                            "configurations.is_missing_pod_enabled_key"
                          ),
                          value
                        )
                      }
                      isChecked={
                        this.state.config[
                          I18n.t(
                            "configurations.is_missing_pod_enabled_key"
                          )
                        ] === "true"
                      }
                      checkedChildren={"Yes"}
                      unCheckedChildren={"No"}
                      checkedValue={"true"}
                      unCheckedValue={"false"}
                      />
                      {/* <Input
                      value={this.state.config.driver_pay_gl_code}
                      style={{ width: "60%", marginRight: "10px" }}
                      className="textUpperCase"
                      onChange={(e) =>
                        this.handleOnInputChange(
                          I18n.t("configurations.driver_pay_gl_code"),
                          e.target.value
                        )
                      }
                    /> */}
                    </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={9}>
                      {I18n.t("configurations.show_send_the_driver_settlement_report_label")}
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14}>
                      <Radio.Group
                        value={
                          this.state.config[
                          I18n.t("configurations.show_send_the_driver_settlement_report_key")
                          ] || "driver"
                        }
                        onChange={(e) =>
                          this.handleOnInputChange(
                            I18n.t("configurations.show_send_the_driver_settlement_report_key"),
                            e.target.value
                          )
                        }
                      >
                        <Radio value="driver">Driver</Radio>
                        <Radio value="manager">Manager</Radio>
                        <Radio value="both">Both</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {/* <Row>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              // disabled={this.state.config.service_duration ? false : true}
              type="primary"
              className="marginRight10"
              onClick={() => this.handleSave(I18n.t('configurations.feedback'), this.state.config.feedback)}
            >Save</Button>
          </div>
        </Row> */}
    </Row>
  );

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => this.handleSave()}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  render() {
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
      <div>
        {this.state.accountId !== "" ? this._renderShipperUpdates() : null}
        {this.state.accountId !== ""
          ? this._renderCustomerNotifications()
          : null}
        {this._renderRouteConfig()}
        {checkServiceExistance("PODS") && this.state.accountId !== ""
          ? this._renderPostDeliveryConfig()
          : null}
        {this._driverConfig()}
        {this.state.accountId !== "" ? null : this._miscellaneousConfig()}
        {this.state.accountId !== "" ? this._renderOrderItemsConfig() : ""}
        {this.state.accountId !== "" ? this._renderIntgrationsConfig() : ""}
        {this.state.accountId === ""
          ? this._renderCommunicationPreferences()
          : null}
        {this._renderFloatingBtn()}
      </div>
      </Spin>
    );
  }
}

export default GeneralSettings;
