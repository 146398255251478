import React from "react";
import { Col, Row, Skeleton, Spin } from "antd";

const LogisticsLoadingScreen = () => {
  return (
    // <div className="content-outer">
    //   <div className="content">
    //     <Row className="page-header" type="flex" justify="space-between">
    //       <Col>
    //         <h1><Skeleton active /></h1>
    //       </Col>
    //     </Row>
        <div className="templatesWrapper" style={ { display: "flex", justifyContent: "center", alignItems: "center", height: "100%" } }>
          <Spin size="default" />
        </div>
    //   </div>
    // </div>
  );
};

export default LogisticsLoadingScreen;



