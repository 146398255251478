/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import { compareString } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Popconfirm,
  Switch,
  Tooltip,
  Popover,
} from "../../common/UIComponents";
import question from "../../../assets/images/question.png";
import { checkServiceExistance } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";

class SurveysList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "name",
        title: I18n.t("general.name"),
        dataIndex: "name",
        render: (text) => ({
          props: {
            className: "wordWrap",
          },
          children: text,
        }),
        sorter: (a, b) => compareString(a.name, b.name),
      },
      {
        key: "description",
        title: I18n.t("surveys.description"),
        dataIndex: "description",
        render: (text, data) => {
          if (text && text.length > 30) {
            return (
              <Popover
                content={
                  <div className="table-contact-info">
                    <span>{text}</span>
                  </div>
                }
                title="Description"
              >
                <u className="text-decoration-dashed">
                  {text.substring(0, 30)}.......
                </u>
              </Popover>
            );
          }

          return <span>{text}</span>;
        },
      },
      {
        key: "status",
        title: I18n.t("general.active"),
        render: (data) => (
          <span>
            {checkServiceExistance("SRVYU") &&  
            <Tooltip placement="topLeft" title="Change Status">
              <Popconfirm
                placement="topRight"
                title={I18n.t("messages.change_status")}
                onConfirm={() => this.props.statusChange(data.id)}
                okText="Yes"
                cancelText="No"
              >
                {/* onChange={() => this.props.statusChange(data.id)} */}
                <Switch 
                  checked={data.status === "active"} 
                  checkedChildren={'Yes'}
                  unCheckedChildren={'No'}
                  className={data.status !== "active" ? "switchComponent" : null}
                />
              </Popconfirm>
            </Tooltip>
            }
          </span>
        ),
        width: 300,
      },
      {
        key: "actions",
        title: "",
        dataIndex: "_id",
        fixed: "right",
        render: (id) => (
          <div className="line-actions">
          {/* SRVYOR */}
          {checkServiceExistance("SQUI") &&  
            <Tooltip
              placement="topLeft"
              title={I18n.t("questionaries.manage_questions")}
            >
              <img
                src={question}
                className="listImage cursorPointer"
                alt="question"
                onClick={() => this.props.questionnare(id)}
                style={{ color: "#113859" }}
              />
            </Tooltip>
            }
            {checkServiceExistance("SRVYOR") && (
              <Tooltip placement="topLeft" title={I18n.t("survey.customer")}>
                {" "}
                <Icon
                  type="rise"
                  style={{color:"#b37feb"}}
                  onClick={() => this.props.openSubmittedSurveys(id)}
                />
              </Tooltip>
            )}
            {checkServiceExistance("SRVYU") && (
              <Tooltip placement="topLeft" title="Edit">
                <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
              </Tooltip>
            )}
            {checkServiceExistance("SRVYD") && (
              <Tooltip placement="topLeft" title="Delete">
                <Popconfirm
                  placement="left"
                  title={I18n.t("messages.delete_confirm")}
                  onConfirm={() => this.props.deleteClick(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span><DeleteIcon/></span>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        ),
        width: "130px",
      },
    ];
  }
}

export default SurveysList;
