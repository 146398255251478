import React from 'react'
import equal from "fast-deep-equal";
import { withRouter } from 'react-router-dom'
import { Result, Button } from '../common/UIComponents'
import { postErrorToSlack } from '../api/ApiUtils'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.catchPromiseErrors);
    window.onerror = this.catchUnhandledError;
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.catchPromiseErrors);
  }

  catchUnhandledError = (message, url, line, column, errorMsg = "") => {
    const errorMessage = errorMsg ? errorMsg.toString() : ''
    const prevErrorMessage =  window.localStorage.getItem('errObj')
    if (!(errorMessage === prevErrorMessage) && !errorMessage.includes('overlayMouseTarget')) {
      postErrorToSlack({ url: window.location.href, error: errorMsg });
      localStorage.setItem('errObj', errorMessage);
    }
  };

  catchPromiseErrors = (event) => {
    const errorMessage = event?.reason ? event.reason.toString() : "";
    const prevErrorMessage =  window.localStorage.getItem('errObj')
    if (!(errorMessage === prevErrorMessage)) {
      postErrorToSlack({ url: window.location.href, error: event.reason });
      localStorage.setItem('errObj', errorMessage);
    }
  };

  componentDidCatch(error, errorInfo) {
     this.setState({
      error: error,
      errorInfo: errorInfo,
    },()=>{
      setTimeout(() => this.setState({ error: '', errorInfo: "" }), 10000);
    });
    // Get error message or error stack trace
    const errorMessage = error.message || error.toString();
    const errorStack = errorInfo.componentStack;

    // Get previous error message or error stack trace from local storage
    const prevErrorMessage = localStorage.getItem('errObj');
    // Check if current error is the same as previous error
    if (!(errorMessage === prevErrorMessage)) {
       // Post error message to Slack channel
        postErrorToSlack({
          url: window.location.href,
          error, 
          errorInfo,
        })
        // Store current error message and error stack trace in local storage
        localStorage.setItem('errObj', errorMessage);
    }
  }

  goToDashBoard = () => {
    this.props.history.push('/dashboard')
    this.setState({ error: '', errorInfo: '' })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="errorLogo">
          <Result
            status="500"
            subTitle="Sorry, Something Went Wrong."
            extra={
              <Button type="primary" onClick={() => this.goToDashBoard()}>
                Back Home
              </Button>
            }
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
