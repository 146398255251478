import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import { Col, Row, Button, Spin, Dropdown, Icon,  } from "../../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  navigateToAccount,
} from "../../../common/Common";
import { convertToTags, limitString,} from "../../../helpers/common";
import AppConfig from "../../../config/AppConfig";
import { fetchCollectionReports, fetchAccountCollectionReports} from "../../../api/Reports";
import { base64ToPDF } from "../../../helpers/common";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../../context/WarehouseContext";
import CollectionsReportList from "./CollectionsReportsList";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import { UserContext } from "../../../context/UserContext";
import I18n from "../../../common/I18n";
import { OrgContext } from "../../../context/OrgContext";
import { ScreenKeys } from "../../../containers/constants";
import { fetchOrgAccounts } from "../../../api/Account";
import CollectionsReportFilter from "./CollectionsReportFilter";
import moment from 'moment';
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import AccountCollectionReportsList from "./AccountCollectionReportsList";

const defaultFilter = {
  reportDate: moment(),
  accountCodes: null,
  warehouseIds: [],
  invoiceAmountFilter : "WITH_AMOUNT",
  ageFilter: "BETWEEN",
  fromAge: 0,
  toAge: 30,
  ageGte: null,
  ageLte: null,
  sortBy: "none",
  sortByType: "ascend",
};

class CollectionsReports extends Component {
  constructor(props) {
    super(props);
    const { selectedWarehouses = null } = props.warehouseData;
    const warehouseIds = Array.isArray(selectedWarehouses)
      ? selectedWarehouses
      : selectedWarehouses
      ? [selectedWarehouses]
      : null;
    this.state = {
      currentFilter: { ...defaultFilter },
      appliedFilter: { ...defaultFilter },
      accounts: [],
      pagination: {},
      reportLoading: false,
      appliedFiltersTags: [],
      warehouseData: this.props.warehouseData,
      accountsLoading: false,
      collectionsReports: [],
      recordsPerPage: props.recordsPerPage || 20,
      token: "",
      filterPlaceHolder: {
        ...defaultFilter,
        warehouseIds,
      },
      appliedFilter: {
        ...defaultFilter,
        warehouseIds,
      },
      activeAccounts: [],
      filterWindow: false,
      whLoading: false,
      isInitial: true,
      isExportModalVisible: false,
      exportEmail: [],
      exportMailfile_type: 'excel',
      isExporting: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  getAmountTypeLabel = (invoiceAmountFilter) => {
    switch (invoiceAmountFilter) {
      case "WITH_AMOUNT":
        return "With Amount";
      case "WITHOUT_AMOUNT":
        return "Without Amount";
      case "BOTH":
        return "Both";
      default:
        return "";
    }
  };

  componentDidMount() {
    this.getCollectionsReports();
    this.getAccounts();
    this.computeTags(this.state.appliedFilter);
  }
  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseData.selectedWarehouses,
        this.props.warehouseData.selectedWarehouses
      )
    ) {
      const warehouseIds = Array.isArray(this.props.warehouseData.selectedWarehouses)
        ? this.props.warehouseData.selectedWarehouses
        : this.props.warehouseData.selectedWarehouses
        ? [this.props.warehouseData.selectedWarehouses]
        : [];
      
      this.setState(
        {
          filterPlaceHolder: {
            ...this.state.filterPlaceHolder,
            warehouseIds,
          },
          appliedFilter: {
            ...this.state.appliedFilter,
            warehouseIds,
          },
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getCollectionsReports();
          this.computeTags(this.state.appliedFilter);
        }
      );
    }
  }

  clearFilter = () => {
    const { warehouseData } = this.props; 
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : [];
  
    const filter = {
      ...defaultFilter,
      warehouseIds: currentWhId,
    };
    
    this.setState({ 
      appliedFilter: filter,
      currentFilter: filter,
      filterPlaceHolder: filter,
      ageError: null,
    }, () => {
      this.tableOptions.pagination.current = 1;
      this.getCollectionsReports();
      this.computeTags(filter);
    });
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData?.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.appliedFilter };
    switch (key) {
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "reportDate":
        filterVariable.reportDate = defaultFilter.reportDate;
        break;
      case "invoiceAmountFilter":
        filterVariable.invoiceAmountFilter = defaultFilter.invoiceAmountFilter;
        break;  
      case "warehouseIds":
        filterVariable.warehouseIds = currentWhId;
        break;
      case "ageFilter":
        filterVariable.ageFilter = defaultFilter.ageFilter;
        filterVariable.fromAge = defaultFilter.fromAge;
        filterVariable.toAge = defaultFilter.toAge;
        filterVariable.ageGte = defaultFilter.ageGte;
        filterVariable.ageLte = defaultFilter.ageLte;
        break;
      default:
        break;
    }
    this.setState(
      { 
        appliedFilter: filterVariable,
        filterPlaceHolder: filterVariable,
      },
      () => {
        this.computeTags(filterVariable);
        this.getCollectionsReports();
      }
    );
  };

  computeTags = (filterPlaceHolder) => {
    if (!filterPlaceHolder) return;
    
    const previousAmountType = "WITH_AMOUNT";
    const { warehouseData } = this.props;
    const { warehouses } = warehouseData;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : [];

    const keys = ["reportDate", "accountCodes", "warehouseIds", "invoiceAmountFilter", "ageFilter"];

    const filteredData = {
      // invoiceAmountFilter: {
      //   label: "Amount Type",
      //   value: this.getAmountTypeLabel(filterPlaceHolder.invoiceAmountFilter),
      //   onClose: () => {
      //     this.handleOnFilterRemoval("invoiceAmountFilter");
      //   },
      //   closeable: previousAmountType !== filterPlaceHolder.invoiceAmountFilter,
      // },
      reportDate: {
        label: "Reports Date",
        value: moment(filterPlaceHolder.reportDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("reportDate");
        },
        closeable: !_.isEqual(
          filterPlaceHolder.reportDate.format("MMM DD, YYYY"),
          moment().format("MMM DD, YYYY")
        ),
      },
      warehouseIds: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouseIds.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouseIds");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouseIds?.length === 1 &&
          filterPlaceHolder?.warehouseIds[0] === currentWhId[0]
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder?.accountCodes?.includes(account.id)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },
      ageFilter: {
        label: "Age Filter",
        value: this.getAgeFilterLabel(filterPlaceHolder),
        onClose: () => {
          this.handleOnFilterRemoval("ageFilter");
        },
        closeable: !this.isDefaultAgeFilter(filterPlaceHolder),
      },
    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  isDefaultAgeFilter(filter) {
    return filter.ageFilter === "BETWEEN" && filter.fromAge === 0 && filter.toAge === 30;
  }
  
  getAgeFilterLabel = (filter) => {
    switch (filter.ageFilter) {
      case "BETWEEN":
        return `Between ${filter.fromAge} and ${filter.toAge}`;
      case "GREATER_THAN_EQUAL":
        return `Greater than or Equal ${filter.ageGte}`;
      case "LESS_THAN_EQUAL":
        return `Less than or Equal ${filter.ageLte}`;
      default:
        return "";
    }
  };
  
  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const orgId = this.props.orgData.currentOrg.id
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
       } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  getCollectionsReports = () => {
    this.setState({
      reportLoading: true,
    });
    const searchText = this.state.token || "";
    const { recordsPerPage, appliedFilter } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = recordsPerPage;
    
    fetchCollectionReports(page, perPage, appliedFilter.warehouseIds, searchText, appliedFilter)
      .then((result) => {
        if (result.success) {
          const collectionReports = result.collectionReports || [];
          if (collectionReports.length === 0) {
             alertMessage("No Records Found", "error", 10);
          }
          this.setState({
            collectionsReports: collectionReports.map((rec) => ({
              ...rec,
              pagination: {},
            })),
            pagination: result.pagination,
            reportLoading: false,
            activeAccounts: []
          });
        } else {
          this.setState({
            reportLoading: false,
            collectionsReports: [],
            activeAccounts: []
          });
        }
      }).catch(error => {
        console.error("Fetch error:", error);
        this.setState({
          reportLoading: false,
          collectionsReports: [],
          activeAccounts: []
        });
      });
  };

  getAccountCollectionsReport = (
    isExpanded,
    id,
    tableOptions = { pagination: {}, filters: {}, sorter: {} }
  ) => {
    if (isExpanded) {
      this.setState({ activeAccounts: [id] });
  
      const collectionsReports = [...this.state.collectionsReports];
      const accObjectIndex = _.findIndex(collectionsReports, ["account_id", id]);
  
      if (accObjectIndex >= 0) {
        collectionsReports[accObjectIndex].inProgress = true;
        collectionsReports[accObjectIndex].tableOptions = tableOptions;
  
        const page = tableOptions.pagination ? tableOptions.pagination.current : 1;
        const perPage = this.state.recordsPerPage;
        this.setState({
          collectionsReports,
        });
  
        const filter = {
          ...this.state.appliedFilter, 
          accountCodes: [id],
        };   
  
        fetchAccountCollectionReports(page, perPage, filter).then((result) => {
          if (result && result.success) {
            const mappedReports = result.accountAgingReports.map(report => ({
              id: report.id,
              age: report.age,
              balance_due: report.balance,
              comment: report.comment,
              invoice_number: report.frequency_invoice_number,
              approval_date: report.invoice_approval_date,
              total_amount_due: report.total,
              account_invoice_id: report.account_invoice_id,
              account_id: report.account_id
            }));
  
            collectionsReports[accObjectIndex].accountCollectionsReports = mappedReports;
            collectionsReports[accObjectIndex].inProgress = false;
            collectionsReports[accObjectIndex].pagination = result.pagination || {};
            this.setState({
              collectionsReports,
            });
          } else {
            console.error("Failed to fetch account collection reports or no data returned.");
            collectionsReports[accObjectIndex].inProgress = false;
            collectionsReports[accObjectIndex].accountCollectionsReports = [];
            this.setState({ collectionsReports });
          }
        }).catch(error => {
          collectionsReports[accObjectIndex].inProgress = false;
          collectionsReports[accObjectIndex].accountCollectionsReports = [];
          this.setState({ collectionsReports });
        });
      }
    } else {
      this.setState({ activeAccounts: [] });
  
      const collectionsReports = [...this.state.collectionsReports];
      const accObjectIndex = _.findIndex(collectionsReports, ["account_id", id]);
      if (accObjectIndex >= 0) {
        collectionsReports[accObjectIndex].accountCollectionsReports = [];
        collectionsReports[accObjectIndex].pagination = {};
        this.setState({
          collectionsReports,
        });
      }
    }
  };
  
  
  


  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getCollectionsReports();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          appliedFilter: {
            ...this.state.appliedFilter,
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
          },
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getCollectionsReports();
        }
      );
    }
  };

  onInnerTableChange = (pagination, filters, sorter, id) => {
    const innerTableOptions = { pagination, filters, sorter };
    this.getAccountCollectionsReport(true, id, innerTableOptions);
  };

  _renderAgingLists = () => {
    const dataPagination = {
      total: this.state.pagination?.total_count || "",
      current: this.state.pagination?.current_page || 1,
      pageSize: this.state.pagination?.per_page || this.state.recordsPerPage,
    };
    return (
      <CollectionsReportList
        orgData={this.props.orgData}
        rowKey="account_id"
        size="small"
        scroll={{ y: "calc(100vh - 306px)" }}
        navigateToAccount={(account_id) =>
          navigateToAccount(this.state.accounts, account_id, this.props.history)
        }
        expandedRowRender={(record) => (
          <Fragment>
            <Spin spinning={record.inProgress} delay={1000}>
              <AccountCollectionReportsList
                orgData={this.props.orgData}
                rowKey={"account_id"}
                data={record.accountCollectionsReports || []}
                scroll ={{ x:"max-content",y:200 }}
                pagination={{
                  total: record.pagination.total_count || "",
                  current: record.pagination.current_page || 1,
                  pageSize: this.state.recordsPerPage || 20,
                }}
                size="small"
                account={record.account_id}
                tableChange={(tablePagination, filters, sorter) =>
                  this.onInnerTableChange(
                    tablePagination,
                    filters,
                    sorter,
                    record.account_id
                  )
                }
              />
            </Spin>
          </Fragment>
        )}
        expandedRowKeys={[...this.state.activeAccounts]}
        onExpand={(expanded, record) =>
          this.getAccountCollectionsReport(expanded, record.account_id)
        }
        data={this.state.collectionsReports}
        pagination={dataPagination}
        tableChange={(pagination, filter, sorter, currentTable) =>
          this.onTableChange(pagination, filter, sorter, currentTable)
        }
      />
    );
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
        activeAccounts: []
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getCollectionsReports();
        updateRecordsPerPage(ScreenKeys.COLLECTION_REPORT, value);
      }
    );
  };

  onSearch = (text) => {
    const token = text.trim();
    this.setState(
      {
        token,
        activeAccounts:[]
      },
      () => {
        if (token === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = text;
        }
        this.tableOptions.pagination.current = 1;
        this.getCollectionsReports();
      }
    );
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState({
      currentFilter: filter,
    }, cb);
  };
  handleSearch = (filter) => {
    if (filter && filter.isExporting) {
      this.handleExport(filter);
    } else {
      const newFilter = {
        ...this.state.appliedFilter,
        ...filter,
      };
      this.setState(
        {
          filterWindow: false,
          appliedFilter: newFilter,
          currentFilter: newFilter,
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getCollectionsReports();
          this.computeTags(newFilter);
        }
      );
    }
  };
  handleExport = (filter) => {
    const newFilter = { ...filter, isExporting: false };
    this.setState({ isExporting: true,
      appliedFilter: newFilter 
    }, () => {

     this.computeTags(newFilter);
    
     fetchCollectionReports(1, this.state.recordsPerPage, null, "", { ...filter, isExporting: true })
     .then((result) => {
        if (result.success && result.pdf) {
          base64ToPDF(
            result.pdf,
            `${this.props.orgData.currentOrg.code}_${moment().format("MM-DD-YYYY_hh-mm-ss")}_collection_report.pdf`
          );
          alertMessage("File Exported Successfully!", "success", 10);
        } else {
          alertMessage("No Records Found to Export", "info", 10);
        }
      })
      .catch((error) => {
        console.error("Export error:", error);
        alertMessage("Error exporting file", "error", 10);
      })
      .finally(() => {
        this.setState({ 
          isExporting: false,
          filterWindow: false,
        }, () => {
          // Refresh the reports data without showing an additional alert
          this.getCollectionsReportsAfterExport();
        });
      });
    })
  };
  
  getCollectionsReportsAfterExport = () => {
    const searchText = this.state.token || "";
    const { recordsPerPage, appliedFilter } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = recordsPerPage;
    
    this.setState({ reportLoading: true });
  
    fetchCollectionReports(page, perPage, appliedFilter.warehouseIds, searchText, appliedFilter)
      .then((result) => {
        if (result.success) {
          const collectionReports = result.collectionReports || [];
          this.setState({
            collectionsReports: collectionReports.map((rec) => ({
              ...rec,
              pagination: {},
            })),
            pagination: result.pagination,
            reportLoading: false,
            activeAccounts: []
          });
        } else {
          this.setState({
            reportLoading: false,
            collectionsReports: [],
            activeAccounts: []
          });
        }
      })
      .catch(error => {
        console.error("Fetch error:", error);
        this.setState({
          reportLoading: false,
          collectionsReports: [],
          activeAccounts: []
        });
      });
  };

  onReset = () => {
    this.setState(
      {
        filterWindow: false,
      })
  }

  render() {
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header" type="flex" justify="space-between">
            <Col >{I18n.t("reports.collection_report")}</Col>
            <Col className="headerOptionDiv">
              <Dropdown
                  onVisibleChange={this.handleVisibleChange}
                  visible={this.state.filterWindow}
                  trigger={["click"]}
                  overlay={
                    <div
                      style={{
                        backgroundColor: "white",
                        width: 500,
                        padding: 28,
                        marginTop: 10,
                        marginRight: -12,
                      }}
                      className="boxShadow"
                    >
                      <CollectionsReportFilter
                        currentFilter={this.state.currentFilter}
                        onChange={this.handleFilterPlaceHolderChange}
                        onSearch={this.handleSearch}
                        onExport={this.handleExport}
                        isExporting={this.state.isExporting}
                        orgData={this.props.orgData}
                        onCancel={this.onReset}
                        warehouseFilter={this.props.warehouseData}
                      />
                    </div>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <Button className="cursorPointer" shape="round">
                    Filter
                    <Icon type="caret-down" />
                  </Button>
                  </div>
                </Dropdown>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Col span={18}>
              <Row type="flex" justify="space-between">
                <Col span={24} align="middle">
                  <AppliedFiltersTags
                    tags={this.state.appliedFiltersTags}
                    clearFilter={() => this.clearFilter()}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row type="flex" gutter={10}  align="end">
                <Col>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    value={this.state.recordsPerPage}
                    defaultValue={AppConfig.ordersPerPage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.reportLoading} delay={1000}>
            <Row>
              <Col>
                <this._renderAgingLists />
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.isExportModalVisible && this.renderEmailExportModal()}
      </div>
    );
  }
}

export const CollectionsReportsComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.COLLECTION_REPORT;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return <CollectionsReports warehouseData={warehouseData} {...props} userContext={userContext} recordsPerPage={recordsPerPage} orgData={orgData} />

});

export default CollectionsReportsComponent;