import { Button, Card, Col, Icon, message, Modal, notification, Popconfirm, Row, Spin, Switch, Tabs, Tooltip, Typography } from 'antd';
import PropTypes from "prop-types";
import React, { useContext,Fragment } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import SwapDrivers from '../preplan/SwapDrivers';
import RouteRow from "../../components/preplan/rows/Routes";
import StopRow from "../../components/preplan/rows/StopResequence";
import StopResequence from "../../components/preplan/rows/Stop";
import AvailableDriver from '../preplan/rows/AvailableDriver';
import { formatDateTime } from '../../helpers/date_functions';
import unpinIcon from "../../../assets/images/unpin.png";
import pinIcon from "../../../assets/images/pin.png";
import RouteOrders from './RouteOrders';
import RouteProcessedErrors, { findRouteErrors } from '../common/RouteProcessedErrors';
import { takeConfirmation } from '../../helpers/preplan';
import { deleteDriversFromPreplan, sendNotification, revokeRoute } from '../../api/PreplanApi';
import { alertMessage, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import UnallocatedStops from '../preplan/UnallocatedStops';
import { formatSurfaceArea,formatWeight } from '../../helpers/orders';
import RouteLabel from '../../config/RouteLabel';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';
import undo_logo from "../../../assets/images/replay.png";
import { OrgContext } from '../../context/OrgContext';
import ReloadIcon from '../../common/ReloadIcon';

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Text } = Typography;

function PreplanRoutesSection (props) {
  const [isItemVisible, setIsItemVisible] = React.useState(false);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'
  const {
    currentPreplan, users, routeInfo, goToTab, preplanTenseTime, getAvailableDrivers, haveFreePackage, stopProgress, rearrangeForm, 
    optimizationProgress, assignInfo, showRouteConfirmModal, routeProgress, handleOnChange, routeKey, setSelectedRoute, setRouteMouseOperation, 
    setClickedRouteId, handleLockChange, assignDriver, optimizeRoute, handleCurrentRoute, currentRoute, currentWarehouse, checkRouteIsEmpty, routeProcessedErrors, 
    showRouteTimeModal, handleEditOrderClick, selectedStopMarkerId, stopSelectedOrders, setOpenedStopInfoWindow, handleCurrentStop, handleStopDelete, handleServiceDuration, 
    routeSelectedStops, checkRouteOrders, resetHoveredStop, currentStop, setCurrentRoute, handleRouteDeleteClick, allocateToExistingRoute, createRoute, 
    setStopProgress, setRouteProcessedErrors, resetPreplan, updateRouteResponse, totalOrders, orderInfo, setTotalOrders, setOrderInfo, setUnassignedOrdersInfo, promptUnprocessedOrders, 
    updateRouteInfo, setStopSelectedOrders, setRouteOrdersInfo, getDependencyOrders, setRouteProgress, setCurrentPreplan, setUsers, doOptimizeBulkRoutes, 
    showResequence, handleCancelResequence, handleUpdateSequence, setRouteInfo,setInProgress,setRouteSelectedStops,unlinkRouteFromUsers,setRouteKey, doCapacityValidation, preplanId,
    serviceDurationReset, resetServiceDurationChanges, setUnsavedServiceDurationChanges, unsavedServiceDurationChanges,renderDurationalert,inProgress,driverZones,displayConfiguration,screen_from,vhType,setServiceDurationReset,showStopScreen,setShowStopScreen, refreshRoutes, routeLoading
  } = props;

  const confirmationModalHelpers = { handleRouteDeleteClick, createRoute, handleServiceDuration, setStopProgress, setRouteProcessedErrors, resetPreplan, updateRouteResponse, totalOrders, orderInfo, setTotalOrders, setOrderInfo, setUnassignedOrdersInfo, promptUnprocessedOrders, updateRouteInfo, currentRoute, stopSelectedOrders, setStopSelectedOrders, routeInfo, handleCurrentRoute, routeSelectedStops, setRouteSelectedStops, handleCurrentStop, unlinkRouteFromUsers, setRouteInfo, setUsers, setRouteKey, setInProgress, currentStop, setCurrentRoute, inProgress };
  const showUpgradeVersion = () => {
    notification[ "warning" ]({
      message: "Please upgrade to Pro version",
      description: "This feature avialble in pro version.",
      placement: "bottomRight",
    });
  };

  const handleRouteObjectChange = (element, value) => {
    const routes = [ ...routeInfo ];
    const routeIndex = _.findIndex(routes, {
      route_id: currentRoute.route_id,
    });
    if (routeIndex >= 0) {
      routes[ routeIndex ][ element ] = value;
    }
    setRouteInfo(routes);
    setCurrentRoute(routes[ routeIndex ]);
    if (element === "pinned" && value === true) {
      handleUpdateSequence(RouteLabel.actionLabels.ROUTE_LOCK_FOR_SEQUENCE);
    }
  };
  const handleDriversDeleteClick = (id) => {
    setRouteProgress(true);
    const data = {
      driver_id: id,
      pre_plan_id: currentPreplan.id,
    };
    deleteDriversFromPreplan(data).then((result) => {
      if (result.success) {
        const userIndex = _.findIndex(users, [ "id", id ]);
        const driverIds = currentPreplan.driver_ids || [];
        const zones = currentPreplan.driver_zones || [];
        const drivers = currentPreplan.drivers || [];
        const driverIdIndex = driverIds.indexOf(id);
        const driversIndex = _.findIndex(drivers, [ "id", id ]);
        const zoneIndex = _.findIndex(zones, [ "driver_id", id ]);
        alertMessage(I18n.t("messages.deleted"), "success", 5);
        if (userIndex >= 0) {
          users.splice(userIndex, 1);
        }
        if (driverIdIndex >= 0) {
          driverIds.splice(driverIdIndex, 1);
        }
        if (driversIndex >= 0) {
          drivers.splice(driversIndex, 1);
        }
        if (zoneIndex >= 0) {
          zones.splice(zoneIndex, 1);
        }
        currentPreplan.driver_ids = [ ...driverIds ];
        currentPreplan.drivers = [ ...drivers ];
        currentPreplan.driver_zones = [ ...zones ];
        setCurrentPreplan(currentPreplan);
        setUsers(users);
        setRouteProgress(false);
      } else {
        resetPreplan(false, true);
        renderAlertMessage(result.errors)
        setRouteProgress(false);
      }
    });
  };
  const saveServiceDurations = (serviceDurations) => {
    currentRoute.service_durations = serviceDurations;
    setCurrentRoute(currentRoute);
  };
  const handleSwapping = (result) => {
    if (result) {
      resetPreplan(false, true);
    }
  };
  // showing service duration update buttons
  const renderServiceDurationButtons = () => (
    (
      <Row type='flex' justify='end' align='middle' gutter={4} className="marginBottom10">
        <Col>
          <Button
            size="small"
            type="danger"
            onClick={ () => {
              setServiceDurationReset(true);
            } }
          >
            { I18n.t("general.discard_changes")}
          </Button>
          <Popconfirm
            title="Do you Want to Continue? This will optimize the route"
            placement="bottomRight"
            onConfirm={ () => {
              handleServiceDuration(currentRoute.route_id);
            } }
            okText="Yes"
            cancelText="No"
          >
              <Button
                size="small"
                type="primary"
                style={ { marginLeft: 8 } }
              >
                { I18n.t("messages.save", { field: "Changes" }) }
              </Button>
          </Popconfirm>
        </Col>
    </Row>)
  )
  // Sending Assignment notification
  const handleNotifyDrivers = () => {
    const data = assignInfo.checkedList;
    const allRouteIndex = data.indexOf("all_routes");
    if (allRouteIndex >= 0) {
      data.splice(allRouteIndex, 1);
    }
    const driverRoutes = [];
    const errors = [];
    data.forEach((route) => {
      const routeIndex = _.findIndex(routeInfo, [ "route_id", route ]);
      if (routeIndex >= 0) {
        const currentRoute = routeInfo[ routeIndex ];
        const isNotOptimized =
          currentRoute.optimized === false &&
          (!currentRoute.optimizer_readable_error_message ||
            (_.isArray(currentRoute.optimizer_readable_error_message) &&
              currentRoute.optimizer_readable_error_message.length === 0));
        if (isNotOptimized) {
          errors.push(currentRoute.displayName);
        } else {
          driverRoutes.push({
            nav_route_id: route,
            driver_id:
              currentRoute.driversInfo && currentRoute.driversInfo.primary
                ? currentRoute.driversInfo.primary.driverId
                : "",
          });
        }
      }
    });
    if (errors.length === 0) {
      setInProgress(true);
      const action_type = RouteLabel.actionLabels.ROUTE_ASSIGNED;
      const requiredData = {
        driver_routes: driverRoutes,
        action_type: action_type,
      };
      sendNotification(requiredData).then((result) => {
        if (result.success) {
          const routes = [ ...routeInfo ];
          data.forEach((routeID) => {
            const routeIndex = _.findIndex(routes, [ "route_id", routeID ]);
            if (routeIndex >= 0) {
              routes[ routeIndex ].status = "ASSIGNED";
            }
          });
          if (
            currentRoute &&
            !isEmpty(currentRoute.route_id) &&
            data.includes(currentRoute.route_id)
          ) {
            currentRoute.status = "ASSIGNED";
            setCurrentRoute(currentRoute);
          }
          setRouteInfo(routes);
          setInProgress(false);
          alertMessage(I18n.t("messages.assigned_successfully"));
        } else {
          renderAlertMessage(result.errors)
          setInProgress(false);
        }
      });
    } else {
      alertMessage(
        `Please optimize routes ${errors.join(", ")} before assigning`,
        "error",
        10
      );
    }
  };

  const handleRevokeRoute = (routeId) => {
    const data = assignInfo.checkedList;
    // const selectedRoutes = routeInfo.filter(route => data.includes(route.route_id))
    // const assignedRoutes = selectedRoutes.filter (route => route.status === "ASSIGNED")
    // const assignedRouteIds = assignedRoutes.map((route) => route.route_id);

    if (routeId) {
      setInProgress(true);
       const payload = {
         nav_route_ids: routeId,
       };
      revokeRoute(payload).then((result) => {
        if (result.success) {
          const routes = _.cloneDeep(routeInfo);
          const routeIndex = _.findIndex(routes, ["route_id", routeId]);
          if (routeIndex >= 0) {
            routes[routeIndex].status = "ALLOCATED";
          }
          // data.forEach((routeID) => {
          //   const routeIndex = _.findIndex(routes, ["route_id", routeID]);
          //   if (routeIndex >= 0) {
          //     routes[routeIndex].status = "ALLOCATED";
          //   }
          // });
          setRouteInfo(routes);
          if(currentRoute && _.findIndex(routes, {route_id:  currentRoute.route_id }) >= 0) {
            setCurrentRoute({...currentRoute, status: "ALLOCATED"});
          }
          setInProgress(false);
          alertMessage(result.message, "success", 10);
        } else {
          alertMessage(result.error, "error", 10);
          setInProgress(false);
        }
      });
    }
    else{
      alertMessage("Current Route is not assigned" , "error" );
    }

  }

  const onMoveDrivers = currentPreplan.on_move_drivers || [];
  const onMoveDriverIds = onMoveDrivers.map((driver) => driver.id);
  const freeUsers = _.sortBy(
    users.filter(
      (user) => user.stops.length === 0 && !onMoveDriverIds.includes(user.id)
    ),
    "displayName"
  );
  const lockedRoutes = routeInfo.filter(
    (route) => route.isLocked || route.warehouse_id !== currentWarehouse.id
  );
  const optPendingRoutes = routeInfo
    .filter(
      (route) => !route.isLocked && route.warehouse_id === currentWarehouse.id
    )
    .map((route) => ({
      route_id: route.route_id,
      inProgress: false,
      status: false,
      full_name: route.displayName,
      pinned: route.pinned,
      stick_start_time: route.stick_start_time === true,
    }));
  const locakableRoutes = routeInfo
    .filter(
      (route) => 
      // assignInfo.checkedList.includes(route.route_id) && !route.isLocked && route.warehouse_id === currentWarehouse.id
      assignInfo.checkedList.includes(route.route_id) && route.locked && route.warehouse_id === currentWarehouse.id
    ).map(route => route.route_id);
  const hasFreePackage = haveFreePackage();
  return (
    <div className="bgWhite block1 borderRadius10 borderLine">
      <Tabs
        activeKey="2"
        onChange={ goToTab }
        type="card"
        tabBarExtraContent={
          <>
            <div className="alignCenter">
              {
                locakableRoutes.length > 0 &&
                <Fragment>
                  {/* <Popconfirm
                    placement="topRight"
                    title={I18n.t("messages.confirm")}
                    onConfirm={() => handleLockChange(locakableRoutes, true)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      title={`Click to ${I18n.t("routes.lock")}`}
                      placement="bottom"
                    >
                      <img src={lockIcon} style={{ width: 15 }} />
                    </Tooltip>
                  </Popconfirm> */}
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t("messages.confirm")}
                    onConfirm={() => handleLockChange(locakableRoutes, false)}
                    okText="Yes"
                    cancelText="No"
                    disabled={unsavedServiceDurationChanges}

                  >
                    <Tooltip
                      title={`Click to ${I18n.t("routes.unlock")}`}
                      placement="top"
                    >
                      <Button 
                        size="small"
                        type="primary"
                        className="marginRight5 fontSize12"
                        disabled={unsavedServiceDurationChanges}
                      >
                      {I18n.t("routes.unlock")}
                      </Button>
                    </Tooltip>
                </Popconfirm>
                </Fragment>
              }
              <SwapDrivers
                currentWarehouse= {currentWarehouse || {}}
                users={ users }
                routes={ routeInfo }
                handleSwapping={ handleSwapping}
                isDisabled={unsavedServiceDurationChanges}
              />{ " " }
              { checkServiceExistance("NRC") &&
                preplanTenseTime !== "past" && (
                  <Button
                    type="primary"
                    size="small"
                    className="marginRight5 fontSize12"
                    onClick={ getAvailableDrivers }
                    disabled={unsavedServiceDurationChanges}
                  >
                    { I18n.t("routes.add") }
                  </Button>
                ) }
              { checkServiceExistance("NROPR") && (
                <Button
                  size="small"
                  type="danger"
                  className="marginRight5"
                  onClick={ () =>
                    !hasFreePackage
                      ? doOptimizeBulkRoutes(
                        optPendingRoutes,
                        false,
                        lockedRoutes
                      )
                      : showUpgradeVersion()
                  }
                  disabled={
                    // optPendingRoutes.length === 0 ||
                    routeInfo.length === 0 ||
                    stopProgress ||
                    rearrangeForm ||
                    optimizationProgress ||
                    unsavedServiceDurationChanges
                  }
                >
                  { I18n.t("routes.optimize") }
                </Button>
              ) }
              { checkServiceExistance("PPMDR") && (
                <Button
                  size="small"
                  type="primary"
                  className="marginRight5"
                  onClick={ () => handleNotifyDrivers() }
                  disabled={
                    routeInfo.length === 0 ||
                    assignInfo.checkedList.length === 0 ||
                    unsavedServiceDurationChanges
                  }
                >
                  { I18n.t("preplan.assign_route") }
                </Button>
              ) }
              {/* <Tooltip
                title={I18n.t("preplan.click_to_revert_back")}
                placement="top"
                overlayStyle={{minWidth: 260}}
              >
                <Button
                  size="small"
                  className="marginRight5 buttonBluishCyan"
                  onClick={() => handleRevokeRoute()}
                  disabled={
                    routeInfo.length === 0 ||
                    assignInfo.checkedList.length === 0 ||
                    unsavedServiceDurationChanges
                  }
                >
                  {I18n.t("preplan.revoke")}
                </Button>
              </Tooltip> */}
              { currentPreplan.org_code ===
                I18n.t("account.netmove_code") &&
                checkServiceExistance("PPCNR") && (
                  <Button
                    size="small"
                    className="marginRight5 buttonMitesse"
                    onClick={ () => showRouteConfirmModal() }
                    disabled={ routeInfo.length === 0 || unsavedServiceDurationChanges}
                  >
                    { I18n.t("preplan.manifest_file") }
                  </Button>
                ) }
            </div>
          </>
        }
      >
        <TabPane
          tab={ I18n.t("preplan.route_assignment") }
          key="2"
          className="preplanRoutes"
          style={ { padding: "5px 3px" } }
        >
          <Row gutter={ 5 }>
            <Col xs={ 7 }>
              <>
                <div className="routeBlock">
                  <Spin spinning={ routeProgress } delay={ 1000 }>
                    <Card
                      size="small"
                      title=""
                      className="borderRadius10 borderLine"
                      bodyStyle={ {
                        height: "78vh",
                        overflowY: "auto",
                        padding: "10px 5px",
                        backgroundColor: "rgb(244, 245, 247)",
                      } }
                      extra={
                        <>
                          { routeInfo.length > 1 && (
                            <Switch
                              size="default"
                              checkedChildren="Clear All"
                              unCheckedChildren="Select All"
                              checked={ assignInfo.checkAll }
                              onChange={ (e) =>
                                handleOnChange("assignInfo", e)
                              }
                              className={
                                !assignInfo.checkAll ? "switchComponent" : null
                              }
                            />
                          ) }
                        </>
                      }
                    >
                      <Droppable
                        droppableId={ `routedroppable${routeKey}` }
                      >
                        { (provided, snapshot) => (
                          <div
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }
                          >
                            { routeInfo.length > 0 &&
                              routeInfo.map((route, index) => (
                                <Draggable
                                  key={ route.route_id }
                                  draggableId={ route.route_id }
                                  index={ index }
                                >
                                  { (provided, snapshot) => (
                                    <div
                                      ref={ provided.innerRef }
                                      { ...provided.draggableProps }
                                      { ...provided.dragHandleProps }
                                    >
                                      <RouteRow
                                        route={ route }
                                        rowNumber={ index }
                                        mouseEnter={ () => {

                                          setSelectedRoute(route.route_id);
                                          setRouteMouseOperation(true);
                                          setClickedRouteId("");
                                        }
                                        }
                                        mouseLeave={ () => {
                                          setSelectedRoute("");
                                          setRouteMouseOperation(true);
                                          setClickedRouteId("");
                                        }
                                        }
                                        deleteClick={ (id) =>
                                          handleRouteDeleteClick(id)
                                        }
                                        handleLockChange={
                                           unsavedServiceDurationChanges ? renderDurationalert :  handleLockChange
                                        }
                                        assignDriver={ assignDriver }
                                        optimizeRoute={ (id) =>
                                          optimizeRoute(id)
                                        }
                                        preplnDriverZones={
                                          currentPreplan
                                            .driver_zones || []
                                        }
                                        setCurrentRoute={ unsavedServiceDurationChanges ? renderDurationalert :  handleCurrentRoute}
                                        currentRoute={ currentRoute }
                                        selectedInfo={
                                          assignInfo.checkedList
                                        }
                                        currentWarehouse={ currentWarehouse.id }
                                        doCapacityValidation={doCapacityValidation}
                                        unsavedServiceDurationChanges={ unsavedServiceDurationChanges }
                                        setUnsavedServiceDurationChanges={ setUnsavedServiceDurationChanges }
                                        serviceDurationReset={ serviceDurationReset }
                                        resetServiceDurationChanges={ resetServiceDurationChanges }
                                        renderDurationalert={renderDurationalert}
                                        driverZones={driverZones}
                                        isMilitaryTime={isMilitaryTime}
                                      />
                                    </div>
                                  ) }
                                </Draggable>
                              )) }
                            { provided && provided.placeholder }
                          </div>
                        ) }
                      </Droppable>
                      { checkServiceExistance("PPRD") &&
                        freeUsers.length > 0 &&
                        freeUsers.map((user, index) => (
                          <AvailableDriver
                            showCodeWithName={true}
                            user={ user }
                            goToTab={ goToTab }
                            key={ `availabeDriver${user.id}` }
                            deleteDriverClick={ handleDriversDeleteClick }
                            doCapacityValidation={doCapacityValidation}
                            driverZones={driverZones}
                          />
                        )) }
                    </Card>
                  </Spin>
                </div>
              </>
            </Col>
            <Col xs={ 17 }>
              <Droppable droppableId="stopCard">
                { (provided, snapshot) => (
                  <div { ...provided.droppableProps } ref={ provided.innerRef }>
                    <div
                      style={ { marginRight: "5px" } }
                      className="routeBlock routeDetails"
                    >
                      <Spin
                        spinning={
                          stopProgress ||
                          optimizationProgress
                        }
                      >
                        <Card
                          size="small"
                          title={
                            <>
                              <div>
                                { showStopScreen
                                  ? "Stops"
                                  : "Orders" }
                                { currentRoute &&
                                  !checkRouteIsEmpty(
                                    currentRoute,
                                    "stop"
                                  ) && (
                                    <>
                                      { " " }
                                      <span className="fontsize11">
                                        for { currentRoute.displayName }
                                      </span>
                                    </>
                                  ) }
                              </div>
                              { currentRoute &&
                                checkServiceExistance("PPCST") &&
                                !checkRouteIsEmpty(currentRoute, "stop") && (
                                  <div>
                                    <Tooltip title="Route Start time">
                                      <span className="fontsize11 colorGreen">
                                        &nbsp;&nbsp;Starts at:
                                        { !unsavedServiceDurationChanges && !currentRoute.isLocked && !rearrangeForm ? (
                                          <Button
                                            type="link"
                                            size="small"
                                            className="fontsize11 colorGreen"
                                            onClick={ showRouteTimeModal }
                                          >
                                            <u className="text-decoration-dashed">
                                              {currentRoute.route_start_datetime ? formatDateTime(
                                                currentRoute.route_start_datetime,
                                                organizationSettings['is_military_time'] === 'true' ? "HH:mm" : "hh:mm A" 
                                              ) : "NA"}
                                            </u>
                                            <EditIcon/>
                                          </Button>
                                        ) : (
                                          <>
                                            {currentRoute.route_start_datetime ? formatDateTime(
                                              currentRoute.route_start_datetime,
                                              organizationSettings['is_military_time'] === 'true' ? "HH:mm" : "hh:mm A" ,
                                            ) : "NA" }
                                          </>
                                        ) }
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) }
                            </>
                          }
                          className="borderRadius10 borderLine"
                          bodyStyle={ {
                            padding:
                              rearrangeForm ||
                                // routeView === "byStops"
                                showStopScreen
                                ? "10px 8px 10px 10px"
                                : "2px",
                            height: "78vh",
                            overflowY: "auto",
                            backgroundColor: "rgb(244, 245, 247)",
                          } }
                          extra={
                            unsavedServiceDurationChanges && checkServiceExistance("NSUSD") ?
                            renderServiceDurationButtons() :
                            <>
                              { currentRoute && currentRoute.route_id && (
                                <Row type="flex" gutter={ 8 } align="middle">
                                  { !rearrangeForm ? (
                                    <>
                                      { !currentRoute.isLocked && (
                                        <>
                                        { checkServiceExistance("NRUSS") && (
                                            <span
                                              className="marginRight12 cursorPointer"
                                              key="actionIcon7"
                                            >
                                              <Tooltip
                                                title="Refresh Orders"
                                              >
                                                <div>
                                                <ReloadIcon
                                                  spin={routeLoading}
                                                  onClick={() => refreshRoutes(
                                                    currentRoute.route_id
                                                  )}
                                                  color="black"
                                                  width="14px"
                                                  height="14px"
                                                  style={{
                                                    marginLeft: 7,
                                                    marginTop: 3,
                                                    cursor: "pointer",
                                                  }}
                                                />
                                                </div>
                                              </Tooltip>
                                            </span>
                                          ) }
                                          { checkServiceExistance("NRUSS") && currentRoute.route_type !== 'line_haul' &&
                                            <span
                                              className="marginRight12 marginTopM5 textBold textGreen cursorPointer"
                                              key="actionIcon1"
                                            >
                                              { !currentRoute.pinned ? (
                                                <Popconfirm
                                                  placement="topRight"
                                                  title={ I18n.t(
                                                    "messages.confirm"
                                                  ) }
                                                  onConfirm={ () =>
                                                    handleRouteObjectChange(
                                                      "pinned",
                                                      true
                                                    )
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <Tooltip
                                                    title={ `Click to ${I18n.t(
                                                      "routes.lock_sequence"
                                                    )}` }
                                                    placement="bottom"
                                                  >
                                                    <img
                                                      src={ unpinIcon }
                                                      style={ {
                                                        width: 18,
                                                      } }
                                                      alt="Lock Route"
                                                    />
                                                  </Tooltip>
                                                </Popconfirm>
                                              ) : (
                                                <Tooltip
                                                  title={ `Click to ${I18n.t(
                                                    "routes.unlock_sequence"
                                                  )}` }
                                                >
                                                  <img
                                                    alt="lock"
                                                    src={ pinIcon }
                                                    style={ {
                                                      width: 18,
                                                    } }
                                                    onClick={ (e) =>
                                                      handleRouteObjectChange(
                                                        "pinned",
                                                        false
                                                      )
                                                    }
                                                  />
                                                </Tooltip>
                                              ) }
                                            </span>
                                          }
                                          {/* { checkServiceExistance("NSUSD") &&
                                            showStopScreen ===
                                            "byStops" && (
                                              <span className="marginRight12">
                                                <Tooltip title="Save Service Duration">
                                                  <Icon
                                                    type="clock-circle"
                                                    // theme="filled"
                                                    style={ { color: "blue" } }
                                                    onClick={ () => {
                                                      takeConfirmation(
                                                        {
                                                          allocType: "serviceDuration", currentRouteId: currentRoute.route_id, data: {},
                                                          title: "Do you Want to Continue?",
                                                          content: "This will optimize the route.",
                                                          doCapacityValidation,
                                                          preplanId,
                                                          ...confirmationModalHelpers
                                                        }
                                                      );
                                                    } }
                                                    className="cursorPointer"
                                                  />
                                                </Tooltip>
                                              </span>
                                            ) } */}
                                          { checkServiceExistance("NRUSS") && (
                                            <span
                                              className="marginRight12 cursorPointer"
                                              key="actionIcon2"
                                              onClick={ (e) =>
                                                showResequence(
                                                  currentRoute.route_id
                                                )
                                              }
                                            >
                                              <Tooltip
                                                title={ I18n.t(
                                                  "routes.resequence"
                                                ) }
                                              >
                                                <Icon type="swap" />{ " " }
                                              </Tooltip>
                                            </span>
                                          ) }
                                          { checkServiceExistance("NROPR") && (
                                            <span
                                              className="marginRight12 cursorPointer"
                                              key="actionIcon3"
                                              onClick={ (e) =>
                                                !hasFreePackage
                                                  ? optimizeRoute(
                                                    currentRoute.route_id
                                                  )
                                                  : showUpgradeVersion()
                                              }
                                            >
                                              <Tooltip
                                                title={ I18n.t(
                                                  "routes.optimize"
                                                ) }
                                              >
                                                <Icon type="radius-setting" />{ " " }
                                              </Tooltip>
                                            </span>
                                          ) }
                                          { checkServiceExistance("NRUSS") &&
                                            <span
                                              className="marginRight12 cursorPointer"
                                              key="reverseIcon"
                                            >
                                              <Popconfirm
                                                placement="topRight"
                                                title={ I18n.t(
                                                  "messages.confirm"
                                                ) }
                                                onConfirm={ () =>
                                                  handleUpdateSequence(
                                                    RouteLabel.actionLabels.REVERSE_STOP_SEQUENCE,
                                                    true
                                                  )
                                                }
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Tooltip
                                                  title={ `${I18n.t(
                                                    "routes.reverse"
                                                  )}` }
                                                  placement="bottom"
                                                >
                                                  <Icon type="sort-descending" />{ " " }
                                                </Tooltip>
                                              </Popconfirm>
                                            </span>
                                          }
                                          { checkServiceExistance("PPAD") && <span
                                            className="marginRight12 cursorPointer"
                                            key="actionIcon4"
                                            onClick={ (e) =>
                                              assignDriver(
                                                currentRoute.route_id,
                                                "primary"
                                              )
                                            }
                                          >
                                            <Tooltip
                                              title={ I18n.t(
                                                "preplan.reallocate"
                                              ) }
                                            >
                                              <Icon type="check" />{ " " }
                                            </Tooltip>
                                          </span>
                                          }
                                          {currentRoute.status === "ASSIGNED" && 
                                            (
                                              <Popconfirm
                                                  placement="topRight"
                                                  title={ I18n.t(
                                                    "messages.confirm"
                                                  ) }
                                                  onConfirm={ () =>
                                                    handleRevokeRoute(currentRoute.route_id)
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <span
                                                      className="marginRight12 cursorPointer"
                                                      key="revertActionIcon"
                                                    >
                                                      <Tooltip
                                                        title={I18n.t("preplan.click_to_revert_back")}
                                                        placement="bottom"
                                                      >
                                                        {/* <Icon type="rollback" /> */}
                                                        <Icon
                                                          component={() => (
                                                            <img style={{ height: 18, marginBottom: 2 }} src={undo_logo} />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                   </span>

                                                </Popconfirm>
                                            )
                                          }
                                          { checkServiceExistance("NRD") && <span
                                            className="marginRight12 cursorPointer"
                                            key="actionIcon5"
                                            onClick={ (e) => {
                                              return takeConfirmation(
                                                {
                                                  allocType: "routeDelete", currentRouteId: currentRoute.route_id, data: "", title:
                                                    "Do you want to delete it",
                                                  content:
                                                    "When clicked the OK button, this route will be deleted from this preplan",
                                                  ...confirmationModalHelpers
                                                });
                                            } }
                                          >
                                            <Tooltip
                                              title={ I18n.t("routes.delete") }
                                            >
                                              <DeleteIcon/>
                                              &nbsp;&nbsp;
                                            </Tooltip>
                                          </span>
                                          }
                                        </>
                                      ) }
                                      <Switch
                                        size="default"
                                        checkedChildren="Orders"
                                        unCheckedChildren="Stops"
                                        checked={
                                          showStopScreen
                                        }
                                        onChange={ (e) => {
                                          // handleOnChange("routeView", e);
                                          // setRouteView( e === "byStops" ? "byOrders" : "byStops");
                                          setShowStopScreen(e)
                                          setServiceDurationReset(true);
                                        }
                                        }
                                        className={
                                          // routeView !== "byStops"
                                          !showStopScreen
                                            ? "switchComponent"
                                            : null
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        size="small"
                                        type="danger"
                                        onClick={ handleCancelResequence }
                                      >
                                        { I18n.t("general.cancel") }
                                      </Button>
                                      &nbsp;
                                      <Button
                                        size="small"
                                        type="primary"
                                        onClick={ () =>
                                          handleUpdateSequence(RouteLabel.actionLabels.ROUTE_RESEQUENCE)
                                        }
                                      >
                                        { I18n.t("general.save") }
                                      </Button>
                                      &nbsp;
                                    </>
                                  ) }
                                </Row>
                              )}
                            </>
                          }
                        >
                          <div className="routesSection">
                          { routeProcessedErrors.length > 0 && (
                            <>
                              { RouteProcessedErrors(
                                routeProcessedErrors,
                                I18n.t(
                                  "messages.route_processed_error_heading"
                                ),
                                "alert"
                              ) }
                            </>
                          )}
                          
                          {
                            <UnallocatedStops
                              route={currentRoute}
                              stopKey="stops"
                              isDisabled={unsavedServiceDurationChanges}
                              deleteClick={ unsavedServiceDurationChanges ? renderDurationalert : handleStopDelete}
                            />
                          }


                          { currentRoute && currentRoute.route_id && (
                            <>
                                
                                { doCapacityValidation ? (<Row className="textBold padding10px fontsize11">
                                <Col xs={12}>
                                  <Icon type="shopping" /> &nbsp;
                                  {I18n.t("preplan.capacity.vehicle_weight")}:{" "}
                                  {formatWeight(currentRoute.vehicle_weight)}
                                </Col>
                                <Col xs={12} className="alignRight">
                                  <Icon type="inbox" />{" "}
                                  {I18n.t("preplan.surface_area.vehicle")}:{" "}
                                  {formatSurfaceArea(currentRoute.vehicle_volume)}
                                </Col>
                                <Col xs={12}>
                                  <Icon type="dollar" /> &nbsp;
                                  {I18n.t('preplan.surface_area.est_truck_revenue')}:{" "}
                                  {currentRoute.estimated_truck_revenue ? `$${currentRoute.estimated_truck_revenue}` : '$0.00' }
                                </Col>
                                </Row>
                                    ) : (
                                      <Row className="textBold padding10px fontsize11">
                                      <Col xs={12}>
                                        <Icon type="dollar" /> &nbsp;
                                        {I18n.t("preplan.surface_area.est_truck_revenue")}:{" "}
                                        {currentRoute.estimated_truck_revenue ? `$${currentRoute.estimated_truck_revenue}` : '$0.00' }
                                      </Col>
                                      </Row>
                                    )
                                  }

                              { showStopScreen && rearrangeForm && <div className="paddingTop10">
                                  <StopResequence
                                    route={ currentRoute }
                                    onClickStop={ handleCurrentStop }
                                    currentStop={ currentStop }
                                    deleteClick={ unsavedServiceDurationChanges ? renderDurationalert : handleStopDelete }
                                    serviceDurationChange={ handleServiceDuration }
                                    rearrangeForm={ rearrangeForm }
                                    selectedList={ routeSelectedStops }
                                    orderDetailsViewClick={ (id) =>
                                      handleEditOrderClick(
                                        id,
                                        currentRoute.route_id,
                                        currentStop.id
                                      )
                                    }
                                    deleteOrderClick={ (id) =>
                                      checkRouteOrders(
                                        id,
                                        currentRoute.route_id
                                      )
                                    }
                                    displayConfiguration={ displayConfiguration }
                                    screen_from={ screen_from }
                                  />
                                </div>
                                }
                                {showStopScreen && !rearrangeForm && <div className="paddingTop10" style={{ opacity:isItemVisible ? 0.3 : null }}>
                                  <StopRow
                                    saveServiceDurations={ saveServiceDurations }
                                    route={ currentRoute }
                                    onClickStop={ handleCurrentStop }
                                    currentStop={ currentStop }
                                    deleteClick={ unsavedServiceDurationChanges ? renderDurationalert : handleStopDelete }
                                    serviceDurationChange={ handleServiceDuration }
                                    rearrangeForm={ rearrangeForm }
                                    selectedList={ routeSelectedStops }
                                    orderDetailsViewClick={ (id) =>
                                      handleEditOrderClick(
                                        id,
                                        currentRoute.route_id,
                                        currentStop.id
                                      )
                                    }
                                    deleteOrderClick={ (id) =>
                                      checkRouteOrders(
                                        id,
                                        currentRoute.route_id
                                      )
                                    }
                                    hoveredMarker={ selectedStopMarkerId }
                                    resetHoveredStop={ resetHoveredStop }
                                    currentWarehouse={ currentWarehouse }
                                    deliveryStartDate={ currentPreplan.delivery_start_date }
                                    doCapacityValidation={ doCapacityValidation }
                                    serviceDurationReset={ serviceDurationReset }
                                    resetServiceDurationChanges={ resetServiceDurationChanges }
                                    setUnsavedServiceDurationChanges={ setUnsavedServiceDurationChanges }
                                    displayConfiguration={ displayConfiguration }
                                    screen_from={ screen_from }
                                    setIsItemVisible={setIsItemVisible}
                                  />
                                </div>
                                }
                                <div style={{ display: !showStopScreen ? "block" : "none" , opacity: isItemVisible ? 0.3 : null}}>
                                  <RouteOrders
                                    hasRouteProcessedErrors={ findRouteErrors(routeProcessedErrors).length > 0 }
                                    doCapacityValidation={ doCapacityValidation }
                                    setInProgress={ setInProgress }
                                    inProgress={ inProgress }
                                    currentStop={ currentStop }
                                    setCurrentRoute={ setCurrentRoute }
                                    handleCurrentStop={ handleCurrentStop }
                                    checkRouteOrders={ checkRouteOrders }
                                    getDependencyOrders={ getDependencyOrders }
                                    handleEditOrderClick={ handleEditOrderClick }
                                    selectedStopMarkerId={ selectedStopMarkerId }
                                    currentRoute={ currentRoute }
                                    stopSelectedOrders={ stopSelectedOrders }
                                    rearrangeForm={ rearrangeForm }
                                    setOpenedStopInfoWindow={ setOpenedStopInfoWindow }
                                    setRouteOrdersInfo={ setRouteOrdersInfo }
                                    displayConfiguration={ displayConfiguration }
                                    screen_from={ screen_from }
                                    vhType={ vhType }
                                    isMilitaryTime={isMilitaryTime}
                                    setIsItemVisible={setIsItemVisible}
                                    routeLoading={routeLoading}
                                  />
                                </div>
                            </>
                          ) }
                          </div>
                        </Card>
                      </Spin>
                    </div>
                    { provided && provided.placeholder }
                  </div>
                ) }
              </Droppable>
            </Col>

          </Row>
        </TabPane>
      </Tabs>
    </div>
  );

}

export default PreplanRoutesSection;

PreplanRoutesSection.propTypes = {
    currentPreplan : PropTypes.object,
    users : PropTypes.array,
    routeInfo : PropTypes.object,
    goToTab : PropTypes.func,
    preplanTenseTime : PropTypes.string,
    getAvailableDrivers : PropTypes.func,
    haveFreePackage : PropTypes.func,
    stopProgress : PropTypes.object,
    rearrangeForm : PropTypes.func,
    optimizationProgress : PropTypes.bool,
    assignInfo : PropTypes.object,
    showRouteConfirmModal : PropTypes.func,
    routeProgress : PropTypes.bool,
    handleOnChange : PropTypes.func,
    routeKey : PropTypes.string,
    setSelectedRoute : PropTypes.func,
    setRouteMouseOperation : PropTypes.func,
    setClickedRouteId : PropTypes.func,
    handleLockChange : PropTypes.func,
    assignDriver : PropTypes.func,
    optimizeRoute : PropTypes.func,
    handleCurrentRoute : PropTypes.func,
    currentRoute : PropTypes.object,
  currentWarehouse: PropTypes.object,
    checkRouteIsEmpty : PropTypes.func,
    routeProcessedErrors : PropTypes.array,
    showRouteTimeModal : PropTypes.func,
    handleEditOrderClick : PropTypes.func,
    selectedStopMarkerId : PropTypes.string,
    stopSelectedOrders : PropTypes.array,
    setOpenedStopInfoWindow : PropTypes.func,
    handleCurrentStop : PropTypes.func,
    handleStopDelete : PropTypes.func,
    handleServiceDuration : PropTypes.func,
    routeSelectedStops : PropTypes.array,
    checkRouteOrders : PropTypes.func,
    resetHoveredStop : PropTypes.func,
    currentStop : PropTypes.object,
    setCurrentRoute : PropTypes.func,
    handleRouteDeleteClick : PropTypes.func,
    allocateToExistingRoute : PropTypes.func,
    createRoute : PropTypes.func,
    setStopProgress : PropTypes.func,
    setRouteProcessedErrors : PropTypes.func,
    resetPreplan : PropTypes.func,
    updateRouteResponse : PropTypes.func,
    totalOrders : PropTypes.array,
    orderInfo : PropTypes.object,
    setTotalOrders : PropTypes.func,
    setOrderInfo : PropTypes.func,
    setUnassignedOrdersInfo : PropTypes.func,
    promptUnprocessedOrders : PropTypes.func,
    updateRouteInfo : PropTypes.func,
    setStopSelectedOrders : PropTypes.func,
    setRouteOrdersInfo : PropTypes.func,
    getDependencyOrders : PropTypes.func,
    setRouteProgress : PropTypes.func,
    setCurrentPreplan : PropTypes.func,
    setUsers : PropTypes.func,
    doOptimizeBulkRoutes : PropTypes.func,
    showResequence : PropTypes.func,
    handleCancelResequence : PropTypes.func,
    handleUpdateSequence : PropTypes.func,
    setRouteInfo : PropTypes.func,
    setInProgress : PropTypes.bool,
    setRouteSelectedStops : PropTypes.func,
    renderDurationalert: PropTypes.func,
};

PreplanRoutesSection.defaultProps = {
    currentPreplan : {},
    users : [],
    routeInfo : [],
    goToTab : () => {},
    preplanTenseTime : '',
    getAvailableDrivers : () => {},
    haveFreePackage : () => {},
    stopProgress : {},
    rearrangeForm : () => {},
    optimizationProgress : false,
    assignInfo : {},
    showRouteConfirmModal : () => {},
    routeProgress : false,
    handleOnChange : () => {},
    routeKey : '',
    setSelectedRoute : () => {},
    setRouteMouseOperation : () => {},
    setClickedRouteId : () => {},
    handleLockChange : () => {},
    assignDriver : () => {},
    optimizeRoute : () => {},
    handleCurrentRoute : () => {},
    currentRoute : {},
  currentWarehouse: {},
    checkRouteIsEmpty : () => {},
    routeProcessedErrors : [],
    showRouteTimeModal : () => {},
    handleEditOrderClick : () => {},
    selectedStopMarkerId : '',
    stopSelectedOrders : [],
    setOpenedStopInfoWindow : () => {},
    handleCurrentStop : () => {},
    handleStopDelete : () => {},
    handleServiceDuration : () => {},
    routeSelectedStops : [],
    checkRouteOrders : () => {},
    resetHoveredStop : () => {},
    currentStop : {},
    setCurrentRoute : () => {},
    handleRouteDeleteClick : () => {},
    allocateToExistingRoute : () => {},
    createRoute : () => {},
    setStopProgress : () => {},
    setRouteProcessedErrors : () => {},
    resetPreplan : () => {},
    updateRouteResponse : () => {},
    totalOrders : [],
    orderInfo : {},
    setTotalOrders : () => {},
    setOrderInfo : () => {},
    setUnassignedOrdersInfo : () => {},
    promptUnprocessedOrders : () => {},
    updateRouteInfo : () => {},
    setStopSelectedOrders : () => {},
    setRouteOrdersInfo : () => {},
    getDependencyOrders : () => {},
    setRouteProgress : () => {},
    setCurrentPreplan : () => {},
    setUsers : () => {},
    doOptimizeBulkRoutes : () => {},
    showResequence : () => {},
    handleCancelResequence : () => {},
    handleUpdateSequence : () => {},
    setRouteInfo : () => {},
    setInProgress : false,
    setRouteSelectedStops : () => {},
    renderDurationalert: () => {}
};