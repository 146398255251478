/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import BaseList from "../../BaseList";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {isEmpty, ellipseText } from "../../../common/Common";
import {
  Icon,
  Popover,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Tag,
  Link,
  Text,
} from "../../../common/UIComponents";
import { Spin, Table } from "antd";
import AppConfig from "../../../config/AppConfig";
import FormattedErrors from "../../common/FormattedErrors";
import {
  checkServiceExistance,
  formatByTimeConfig,
} from "../../../helpers/common";
import TypeOfOrder from "../../orders/TypeOfOrder";
import _ from "lodash";
import PreplanOrderAccessorial from "../../orders/PreplanOrderAccessorial";
import PreplanOrderNotes from "../../orders/PreplanOrderNotes";
import Copyable from "../../common/Copyable";
import { OrgContext } from "../../../context/OrgContext";
import OrderQuantity from "../OrderQuantity";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import { TableImageColumn } from "../../../common/TableImageColumn";
import { VehicleImageData } from "../../../common/VehicleImageData";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";
import BaseList2 from "../../BaseList2";
import { ScreenKeys } from "../../../containers/constants";
import CustomerOrderDetails from "../../orders/CustomerOrderDetails";
import OrderAttachments from "../../orders/OrderAttachments";

const tootlTipDelay = AppConfig.tooltipDelay;

const RouteOrdersList = (props) => {
  const [ resizablecolumns, setResizablecolumns ] = useState([]);
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const fromAnotherWh = props.currentRoute.isLocked;

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
  });
  useEffect(() => {

    let finalColumns = [
    ];

    if (props.showMoveAction === true && checkServiceExistance("NRTO")) {
      finalColumns.push({
        title: <Icon type="home" className="fontSize12" />,
        key: "stop_no",
        dataIndex: "stop_no",
        fixed: "left",
        render: (text, record, index) => (
          <div>
            {/* <span>{!isEmpty(text) ? text - 1 : ''}</span> */}
            <Draggable
              key={record.customer_order_id}
              draggableId={record.customer_order_id}
              index={index}
            >
              {(provided, snapshot) => {
                const selectedList = props.selectedList
                  ? [...props.selectedList]
                  : [];
                const { isDragging } = snapshot;
                const displayText = isDragging
                  ? selectedList.length > 1 &&
                    selectedList.includes(record.customer_order_id)
                    ? `Moving ${selectedList.length} orders`
                    : record.customer_order_number
                  : "";
                const isUnallocated = record.status === "UNALLOCATED";
                const hasStopErrors =
                  record.stop_errors &&
                  _.isArray(record.stop_errors) &&
                  record.stop_errors.length > 0;
                const hasErrors = hasStopErrors || isUnallocated;
                const stopErrors = hasStopErrors ? [...record.stop_errors] : [];

                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? "#607ad6"
                          : isUnallocated
                          ? `#${AppConfig.unallocated_color}`
                          : "white",
                        color: snapshot.isDragging ? "#FFF" : "inherit",
                        width: snapshot.isDragging ? 150 : "inherit",
                        height: snapshot.isDragging ? 30 : "inherit",
                        fontSize: snapshot.isDragging ? 12 : "inherit",
                        padding: snapshot.isDragging ? 4 : "inherit",
                        borderRadius: snapshot.isDragging ? 10 : "inherit",
                      }}
                    >
                      {!snapshot.isDragging ? (
                        <Fragment>
                          {hasErrors ? (
                            <div>
                              <Popover
                                className="fontsize11 marginTopM3"
                                content={FormattedErrors(stopErrors)}
                              >
                                <span
                                  className={`textBold`}
                                  style={{
                                    color: isUnallocated ? "white" : "red",
                                  }}
                                >
                                  {!isEmpty(text) ? text - 1 : ""}
                                </span>
                              </Popover>
                            </div>
                          ) : (
                            <p
                              className="badgeDiv cursorPointer"
                              style={{
                                backgroundColor: `#${props.currentRoute.bgColor}`,
                                color: `#${props.currentRoute.fgColor}`,
                                fontSize: 10,
                              }}
                            >
                              {!isEmpty(text) ? text - 1 : ""}
                            </p>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          &nbsp;&nbsp;
                          <Icon type="menu" /> &nbsp; {displayText}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </Fragment>
                      )}
                    </div>
                  </div>
                );
              }}
            </Draggable>
          </div>
        ),
        width: 25,
        align: "center",
      });
    } else {
      finalColumns.push({
        title: <Icon type="home" className="fontSize12" />,
        key: "stop_no",
        dataIndex: "stop_no",
        render: (text, record, index) => {
          const isUnallocated = record.status === "UNALLOCATED";
          const hasStopErrors =
            record.stop_errors &&
            _.isArray(record.stop_errors) &&
            record.stop_errors.length > 0;
          const hasErrors = hasStopErrors || isUnallocated;
          const stopErrors = hasStopErrors ? [...record.stop_errors] : [];
          return (
            <div>
              <Fragment>
                {hasErrors ? (
                  <div>
                    <Popover
                      className="fontsize11 marginTopM3"
                      content={FormattedErrors(stopErrors || [])}
                    >
                      <span className="textRed textBold">
                        {!isEmpty(text) ? text - 1 : ""}
                      </span>
                    </Popover>
                  </div>
                ) : (
                  <p
                    className="badgeDiv cursorPointer"
                    style={{
                      backgroundColor: `#${props.currentRoute.bgColor}`,
                      color: `#${props.currentRoute.fgColor}`,
                      fontSize: 10,
                    }}
                  >
                    {!isEmpty(text) ? text - 1 : ""}
                  </p>
                )}
              </Fragment>
            </div>
          );
        },
        width: 25,
        align: "center",
      });
    }

    const items = [
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        render: (text, record, index) => {
          const isTransfer = ["T", "LH"].includes(record.type_of_order);
          const isPickup = record.type_of_loc === "PICKUP";
          const textToDisplay = isTransfer ? ( isPickup ? text + "(P)" : text + "(D)") : text;
          return (<Row
            type="flex"
            // justify="space-between"
            align="middle"
            style={{
              padding: "0 10px 0 5px",
            }}
            gutter={8}
          >
            <Col>
              <TypeOfOrder
                order={record}
                orderTypeKey="type_of_order"
                relatedOrderKey="related_order"
                key={`${record.customer_order_id}`}
                showBadge={true}
                triggerNodePosition={false}
                showTooltip={
                  props.showMoveAction === true || fromAnotherWh
                    ? true
                    : false
                }
              />
            </Col>
            <Col>
              <Row type="flex" gutter={4}>
                <Col>
                  <Link
                    onClick={() =>
                      props.orderDetailsViewClick(
                        record.customer_order_id,
                        props.route.route_id,
                        record.stop_id
                      )
                    }
                  >
                    <Tooltip
                      mouseEnterDelay={tootlTipDelay}
                      title={
                        props.showMoveAction === true ||
                        fromAnotherWh
                          ? textToDisplay
                          : undefined
                      }
                    >
                      {textToDisplay}
                    </Tooltip>
                  </Link>
                </Col>
                <Col>
                  <Copyable text={text} />
                </Col>
              </Row>
            </Col>
          </Row>
          );

        },
        className: "minWidth65",
        width: 120,
        ellipsis: true,
        align: "left",
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        render: (data, record) => (
          <div style={{ textAlign: "center" }}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        width: 120,
        render: (text, record) => (
          <Col>
            <Row type="flex" style={{ flexFlow: "unset" }} gutter={2}>
              <Col>
                <Link
                  onClick={() =>
                    props.orderDetailsViewClick(
                      record.customer_order_id,
                      props.route.route_id,
                      record.stop_id
                    )
                  }
                >
                  <Tooltip
                    mouseEnterDelay={tootlTipDelay}
                    title={
                      props.showMoveAction === true || fromAnotherWh
                        ? text
                        : undefined
                    }
                  >
                    {ellipseText(text, null)}
                  </Tooltip>
                </Link>
              </Col>
              {!isEmpty(text) && (
                <Col>
                  <Copyable text={text} />
                </Col>
              )}
            </Row>
          </Col>
        ),
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        width: 120,
        render: (text, record) => (
          <Col>
            <Row type="flex" style={{ flexFlow: "unset" }} gutter={2}>
              <Col>
                <Link
                  onClick={() =>
                    props.orderDetailsViewClick(
                      record.customer_order_id,
                      props.route.route_id,
                      record.stop_id
                    )
                  }
                >
                  <Tooltip
                    mouseEnterDelay={tootlTipDelay}
                    title={
                      props.showMoveAction === true || fromAnotherWh
                        ? text
                        : undefined
                    }
                  >
                    {ellipseText(text, null)}
                  </Tooltip>
                </Link>
              </Col>
              {!isEmpty(text) && (
                <Col>
                  <Copyable text={text} />
                </Col>
              )}
            </Row>
          </Col>
        ),
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_1"
            tootlTipDelay={tootlTipDelay}
            charsToLimit={null}
          />
        ),
        width: 120,
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_2"
            tootlTipDelay={tootlTipDelay}
            charsToLimit={null}
          />
        ),
        width: 120,
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: "username",
        title: (
          <Tooltip title={<EndUserTitle />}>
            <EndUserTitle />
          </Tooltip>
        ),
        dataIndex: "consignee",
        render: (text, record) => {
          const showTooltip = props.showMoveAction === true;
          return (
            <Fragment>
              {showTooltip ? (
                <Tooltip mouseEnterDelay={tootlTipDelay} title={text}>
                  {text}
                 </Tooltip>
              ) : (
                text
              )}
            </Fragment>
          );
        },
        width: 120,
        ellipsis: true,
      },
      {
        key: "company_name",
        title: I18n.t("general.company_name"),
        dataIndex: "company_name",
        render: (data, record) => 
          (
            <div style={{ display: "flex" }}>
                {_.get(record, "stop_location.company_name", "")}
            </div>
          ),
        width: 100,
        ellipsis: true,
      },
      // {
      //   key: "type_of_order",
      //   title: "Order Type",
      //   dataIndex: "type_of_order",
      //   render: (data, record) => {
      //     return (
      //       <div>
      //         <TypeOfOrder
      //           order={record}
      //           orderTypeKey="type_of_order"
      //           relatedOrderKey="related_order"
      //           key={`${record.customer_order_id}`}
      //           showTooltip={
      //             false
      //           } /* props.showMoveAction === true ? true :  */
      //         />
      //       </div>
      //     );
      //   },
      //   align: "center",
      //   width: 34,
      // },
      {
        key: "account_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        width: 50,
      },
      {
        key: "account_name",
        className: "noWrap",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        width: 70,
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "levelOfService",
        render: (data, record) => (
          <div style={{ display: "flex" }}>
            <div>{record.los || ""}</div>
          </div>
        ),
        width: 50,
      },
      {
        key: "vehicle_type",
        title: I18n.t("order.vehicle_type"),
        dataIndex: "vehicle_type",
        width: 100,
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={props.vhType}
              showVehicleTitle
            />
          );
        },
      },
      {
        key: "vehicle_image",
        title: I18n.t("vehicleTypes.imgTitle"),
        dataIndex: "vehicle_image",
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={props.vhType}
              width={"30"}
              borderRadius={"5"}
              align={"center"}
              showImage
            />
          );
        },
      },
      {
        key: "sc_time",
        title: (
          <Tooltip title={I18n.t("order.scheduled_time")}>
            {I18n.t("order.scheduled_short_form")}
          </Tooltip>
        ),
        dataIndex: "stop_sch_start_time",
        render: (text, record) => {
          let hasValues = false;
          let hours = 0;
          if (
            !isEmpty(record.cs_sch_start_time) &&
            !isEmpty(record.cs_sch_end_time)
          ) {
            const duration = moment.duration(
              moment(record.cs_sch_end_time, "hh:mma").diff(
                moment(record.cs_sch_start_time, "hh:mma")
              )
            );
            hours = duration.asHours();
            hasValues = true;
          }

          let formmatedStartTime = !isEmpty(record.cs_sch_start_time)
            ? formatByTimeConfig(
                moment(record.cs_sch_start_time, "hh:mma"),
                organizationSettings["is_military_time"],
                "HH:mm",
                "hh:mm a"
              )
            : "";
          let formmatedEndTime = !isEmpty(record.cs_sch_end_time)
            ? formatByTimeConfig(
                moment(record.cs_sch_end_time, "hh:mma"),
                organizationSettings["is_military_time"],
                "HH:mm",
                "hh:mm a"
              )
            : "";

          if (organizationSettings["is_military_time"] === "true") {
            formmatedStartTime.replace("m", "");
            formmatedEndTime.replace("m", "");
          }

          return (
            <Text
              className={
                hasValues && hours <= 4
                  ? "fontWeightBold fontSize11 highlightText minWidth"
                  : "minWidth"
              }
            >
              {formmatedStartTime + " - " + formmatedEndTime}
            </Text>
          );
        },
        className: "minWidth",
        width: 105,
      },
      {
        key: "time",
        title: (
          <Tooltip
            mouseEnterDelay={tootlTipDelay}
            title={I18n.t("preplan.eta")}
          >
            {I18n.t("preplan.eta")}
          </Tooltip>
        ),
        dataIndex: "stop_arrival_time",
        render: (text, record) => (
          <div>
            {!isEmpty(record.stop_arrival_time)
              ? formatByTimeConfig(
                  moment(record.stop_arrival_time, "hh:mma"),
                  organizationSettings["is_military_time"],
                  "HH:mm",
                  "hh:mm a"
                ).replace("m", "")
              : ""}
          </div>
        ),
        width: 40,
      },
      {
        key: "weight",
        title: (
          <Fragment>
            <Row>
              <Col className="alignCenter">
                <Tooltip
                  mouseEnterDelay={tootlTipDelay}
                  title={I18n.t("order.weight")}
                >
                  {I18n.t("order.weight_short_form")}
                </Tooltip>
              </Col>
              <Col className="alignCenter"> ({AppConfig.weight_unit})</Col>
            </Row>
          </Fragment>
        ),
        dataIndex: "weight",
        render: (data, record) => {
          const weight = !isEmpty(data) ? data : "";
          const itemsCount = record.items_count ? record.items_count : "";
          return (
            <Fragment>
              {weight ? (
                <OrderQuantity
                  record={record}
                  key={record.id}
                  keyId={`ro${record.id}`}
                  itemsRequired={
                    props.showMoveAction === true || fromAnotherWh
                  }
                  showActions={true}
                  isMilitaryTime={props.isMilitaryTime}
                  setIsItemVisible={props.setIsItemVisible}
                >
                  {`${weight}${itemsCount ? ` (${itemsCount})` : ""}`}
                </OrderQuantity>
              ) : (
                "NA"
              )}
            </Fragment>
          );
        },
        width: 42,
      },
      // {
      //   key: "pallets",
      //   title: I18n.t("order.pallets"),
      //   dataIndex: "pallets",
      //   // render: (data, record) => (
      //   //   <div className="alignRight paddingRight10">
      //   //     {!isEmpty(data) ? data : ""}
      //   //   </div>
      //   // ),
      //   width: 40,
      //   align: "right",
      // },
      {
        key: "service_duration",
        title: (
          <Fragment>
            <Row>
              <Col className="alignCenter">
                <Tooltip
                  mouseEnterDelay={tootlTipDelay}
                  title={I18n.t("stop.service_duration")}
                >
                  {I18n.t("stop.service_duration_short_cut")}
                </Tooltip>
              </Col>
              <Col className="alignCenter">
                {" "}
                ({I18n.t("general.min_short_cut")})
              </Col>
            </Row>
          </Fragment>
        ),
        dataIndex: "service_duration",
        render: (data) => <Fragment>{!isEmpty(data) ? data : ""}</Fragment>,
        width: 40,
        align: "center",
      },
      {
        key: "customer_street",
        title: I18n.t("general.street"),
        ellipsis: true,
        dataIndex: "street",
        className: "text-capitalize",
        align: "left",
        // width: "auto",
        // ellipsis: true,
        width: 120,
        render: (data, record) => {
          const street = record.street ? record.street.toLowerCase() : "";
          return (
            <Row>
              <Col>
                <Tooltip
                  mouseEnterDelay={tootlTipDelay}
                  title={
                    props.showMoveAction === true ? record.street : undefined
                  }
                >
                  {street}
                </Tooltip>
              </Col>
            </Row>
          );
        },
      },
      {
        key: 'priority',
        title: I18n.t("configurations.priority"),
        dataIndex: 'priority',
        render: (data, record) => (
          <div>
            {record.priority === null || record.priority === false ? "No" : (
              <span>
                Yes &nbsp; <Icon type="flag" style={{ color: 'red',fontSize:'15px' }} />
              </span>
            )}
          </div>
        ),
        width: 100,

      },
      {
        key: "customer_city",
        title: I18n.t("general.city"),
        ellipsis: true,
        dataIndex: "customer_city",
        className: "text-capitalize",
        render: (data, record) => {
          const cityValue = record.customer_city || record.city;
          const city = cityValue
            ? cityValue.toLowerCase()
            : "";
          return (
            <Row>
              <Col>
                <Tooltip
                  mouseEnterDelay={tootlTipDelay}
                  title={
                    props.showMoveAction === true
                      ? cityValue
                      : undefined
                  }
                >
                  {city}
                </Tooltip>
              </Col>
            </Row>
          );
        },
        width: 60,
        align: "left",
      },
      {
        key: "customer_zip",
        title: I18n.t("general.zipcode_short_form"),
        dataIndex: "zipcode",
        width: 40,
        render: (data, record) => <div>{data}</div>,
        align: "center",
      },
      {
        key: "pallets",
        title: I18n.t("order.pallets"),
        dataIndex: "pallets",
        align : "center"
      },
      {
        key: "created_at",
        title: I18n.t("order.age"),
        dataIndex: "created_at",
        align: "right",
        render: (created_at) => (
          <div className="paddingRight25">
              <span>{ moment().diff(moment(created_at), "days") }</span>
          </div>
        ),
        align: "center",
      }
    ];
    finalColumns.push(...items);

    if (props?.data?.length && props.showActions !== false) {
      finalColumns.push({
        key: "actions",
        title: "",
        dataIndex: "customer_order_id",
        fixed: !fromAnotherWh ? "right" : null,
        render: (id, record, index) => {
          return (
            <div>
              <Row
                type="flex"
                gutter={8}
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={e => e.stopPropagation()}
              >
                {(props.showMoveAction === true || fromAnotherWh) && (
                  <Fragment>
                    <Col span={6}>
                      <PreplanOrderAccessorial
                        record={record}
                        showPopupContainer={false}
                      />
                    </Col>
                    <Col span={6}>
                      <PreplanOrderNotes
                        record={record}
                        placement="right"
                        showPopupContainer={false}
                      />
                    </Col>
                    <Col span={ 6} style={{ paddingTop: 2}}>
                      <CustomerOrderDetails
                          order_no={ record.customer_order_number }
                          order={ {
                            id: record.customer_order_id,
                            customer_order_number: record.customer_order_number,
                          } }
                          showEdit={ false }
                          showIcon={true}
                          eyeIconStyle={{ fontSize: 15 }}
                        />
                    </Col>
                    <Col span={ 6 } >
                      <OrderAttachments
                        record={ record }
                        placement={
                          index !== 0 && index === props?.data?.length - 1
                            ? "topRight"
                            : "left"
                        }
                        showPopupContainer={ false }
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
            </div>
          );
        },
      });
    }

    const isPreplanPage = props.screen_from === "preplan";
    if (!_.isEmpty(props.displayConfiguration) && isPreplanPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const route_orders_list_columns =
        userConfiguration.route_orders_list_columns ||
        orgConfiguration.route_orders_list_columns;
      const columns = [];
      if (
        typeof route_orders_list_columns !== undefined &&
        Array.isArray(route_orders_list_columns)
      ) {
        const initialColumns = finalColumns.filter((col) =>
          ["stop_no"].includes(col.key)
        );
        if (initialColumns.length > 0) {
          columns.push(...initialColumns);
        }
        route_orders_list_columns.forEach((column) => {
          const foundColumn = finalColumns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = finalColumns.filter((col) =>
          ["actions"].includes(col.key)
        );
        if (actions.length > 0) {
          columns.push(...actions);
        }
        finalColumns = columns;
      } else {
      }
    }

  setResizablecolumns(finalColumns);
  }, [props.displayConfiguration]);

  return (
  <BaseList2
    data={ props.data }
    columns={ resizablecolumns }
    nonResizableCols={ !props.bodyRow ? ["stop_no", "actions"] : resizablecolumns.map((col) => col.key) } 
    pagination={props.pagination}
    rowSelection={props.rowSelection}
    tableName={ScreenKeys.ROUTE_ORDERS_VIEW_LISTING}
    rowKey={props.rowKey}
    key={props.key}
    onRow={props.onRow}
    showtitle={false}
    renderExtraHeader={props.renderExtraHeader}
    bodyRow={props.bodyRow}
    rowClassName={props?.rowClassName || null}
    size={props.size || "small"}
  />);
};



export default RouteOrdersList;