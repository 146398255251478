import React, { Fragment } from "react";
import BaseList from "../BaseList";
import {
  compareString,
  isEmpty,
  ellipseText,
  checkNegitive,
} from "../../common/Common";
import { Icon, Popconfirm, Typography, Tooltip, Row, Col } from "../../common/UIComponents";
import AddressInfo from "../common/AddressInfo";
import CopyableContent from "../common/CopyableContent";
import DisplayTime from "../common/DisplayTime";
import CreditInvoices from "../ar_module/CreditInvoices";
import replay from '../../../assets/images/replay.png';
import { checkServiceExistance } from "../../helpers/common";
import I18n from "../../common/I18n";
import moment from "moment";


class List extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    let filteredColumns = [];
    const columns = [
      {
        key: "credit_no",
        title: I18n.t("credit_notes.credit_no"),
        dataIndex: "credit_no",
        render: (text) => (
          <CopyableContent text={text} />
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 160,
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        width: 100,
        className: "noWrap",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      // {
      //   key: "wh_name",
      //   title: I18n.t("location.location"),
      //   dataIndex: "wh_name",
      //   width: 150,
      //   className: "noWrap",
      // },
      // {
      //   key: "wh_name",
      //   title: I18n.t("location.location"),
      //   dataIndex: "wh_name",
      //   width: 100,
      //   className: "noWrap",
      //   // sorter: true,
      //   // sortDirections: ["ascend", "descend", "ascend"],
      // },
      {
        key: 'account_invoice_number',
        title: I18n.t("invoices.number"),
        dataIndex: "account_invoice_number",
        render: (text, record) => record?.invoice_details?.length > 0 && 
        <CreditInvoices invoice_details={record.invoice_details} key={`inv${record.credit_no}`}/>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 150,
      },
      {
        key: "total_credit_amount",
        title: I18n.t("credit_notes.credit_amount"),
        dataIndex: "total_credit_amount",
        render: (text) => (
          <Fragment>
              <span className="textBold">{checkNegitive(text)}</span>
              {/* <div className="textBold alignRight" style={{ width : 120 }}>{checkNegitive(text)}</div> */}
          </Fragment>
        ),
        align: "right",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 150,

      },
      {
        key: "balance_credit_amount",
        title: I18n.t("credit_notes.balance_credit_amount"),
        dataIndex: "balance_credit_amount",
        render: (text) => (
          <span className="textBold">{checkNegitive(text)}</span>
        ),
        align: "right",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 150,
      },
      {
        key: "comments",
        title: I18n.t("credit_notes.memo_descr"),
        dataIndex: "credit_description",
        render: (text, data) =>  <div className="wordWrap" style={{ width : 150 }}>
        <Typography.Paragraph ellipsis={{rows:1, expandable:true }} style={{ marginBottom: 0 }}>
          {text}
        </Typography.Paragraph>
        </div>,
         width: 150,
      },
      {
        key: "created_by",
        title: "Created By",
        dataIndex: "user_name",
        width: 100,
        className: "noWrap",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "created_at",
        title: I18n.t("general.created_at"),
        dataIndex: "created_at",
        width: 150,
        render: (data) =>
        (!isEmpty(data) && moment(data).isValid() ? (
          <DisplayTime
            isUTC={false}
            displayWithDate={true}
            dateTimeString={data}
            key={'updated_on'}
            timeZoneId={null}
          />
        ) : (
          'NA'
        )),
        // sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (id, data) => {
          const isCreditAmountExceeded = checkNegitive(data.total_credit_amount) > checkNegitive(data.balance_credit_amount);
        return(
          <Row type="flex" align="middle" gutter={4}>
            <Col>
              <Tooltip
                title="Send Emails"
              >
                <Icon
                  type="mail"
                  style={{ color: "black", fontSize: 18 }}
                  onClick={() => this.props.openModal(data)}
                />
                &nbsp;&nbsp;&nbsp;
              </Tooltip>
            </Col>
            <Col>
              <Tooltip
                title="Download"
                // getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Icon
                  type="file-pdf"
                  style={{ color: "#fa8c16", fontSize: 18 }}
                  onClick={() => this.props.downloadCreditMemo(data.id)}
                />
                &nbsp;&nbsp;&nbsp;
              </Tooltip>
            </Col>
            { checkServiceExistance('ACNR') && !isCreditAmountExceeded && <Col>
             <Popconfirm
              placement="left"
              title={I18n.t('messages.confirm')}
              onConfirm={() => this.props.revertCreditNote(id)}
              okText="Yes"
              cancelText="No"
            >
             <Tooltip title={"Revert"}> <img src={replay} width="25px" /></Tooltip>
            </Popconfirm>
            </Col>
            }
          </Row>)
        },
        width: 80,
      }
    ];
    if(this.props?.columnsToDisplay?.length > 0){
      filteredColumns = columns.filter(column => this.props.columnsToDisplay.includes(column.key))
    }else{
      filteredColumns = [...columns];
    }
    this.columns = filteredColumns.map((column) => ({
      ...column,
      //width: this.getMaxWidth(column.dataIndex),
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };

  getMaxWidth = (title) => {
    const maxWidth = 400; // Set a maximum width to avoid column being too wide
    const minWidth = 90; // Set a minimum width to avoid column being too narrow
    const padding = 24; // Set the padding to match the table's default padding
    const columnData =
      title !== "id"
        ? this.props.data.map((row) => row[title])
        : [" Action Icons "];
    let maxLength = Math.max(
      ...columnData.map((text) => text && text.toString().length)
    );

    if (title === "contact_person") {
      if (maxLength > 25) {
        maxLength = 25;
      }
    }
    const width = Math.min(
      maxWidth,
      Math.max(minWidth, maxLength * 8 + padding)
    ); // Set a width based on the maximum length of the characters
    return width;
  };
}

export default List;
