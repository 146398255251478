import React, { Component, Fragment } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import fleetIcon from "../../../assets/images/purple_filled.png";
import greenMarker from "../../../assets/images/cyan_filled.png";
import redMarker from "../../../assets/images/red_filled.png";
import OrderCheckbox from "./OrderCheckbox";
import {
	generateLetterMarkerWithSize,
	generateLetterMarker,
} from "../../helpers/map";
import { Checkbox } from "antd";
import { OrgContext } from '../../context/OrgContext';
import { retrieveMapIdentity } from "../../helpers/orders";
import { pinLikeMarker } from "../dispatches/helpers";


class OrderMarker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isInfoWindowOpen: false,
			clusteredOrders: [],
			isChecked: false,
		};
	}
	static contextType = OrgContext;
	componentDidMount() {
		const { marker, fetchClusteredOrders } =
			this.props;
		this.setState(
			{
				clusteredOrders: fetchClusteredOrders(marker),
			},
			() => {
			}
		);
	}

	// to get cluster marker with its new values
	componentWillReceiveProps(nextProps) {
		const {
			marker,
			fetchClusteredOrders,
			fetchSelectedClustersInfo,
			activeHoveredCluster,
		} = nextProps;
		this.setState(
			{
				clusteredOrders: fetchClusteredOrders(marker),
				isInfoWindowOpen:
					activeHoveredCluster.lat === marker.lat &&
					activeHoveredCluster.lng === marker.lng,
			},
		);
	}

	// to get cluster marker with its selection status
	clusterIcon = (marker) => {
		const { clusteredOrders } = this.state;
		const { fetchSelectedClustersInfo } = this.props;
		const clusterLength = clusteredOrders.length;
		const clusterStatus = fetchSelectedClustersInfo(marker);
		let letterMarker = "";
		if (clusterStatus.isFullySelected) {
			letterMarker = pinLikeMarker(clusterLength, "9b59b6", "FFFFFF", 24);
      // generateLetterMarker(
			// 	clusterLength,
			// 	"9b59b6",
			// 	"FFFFFF"
			// );
			// letterMarker = generateLetterMarkerWithSize(clusterLength, 'F08080');
		} else if (clusterStatus.isPartiallySelected) {
			letterMarker = pinLikeMarker(clusterLength, "F08080", "000000", 24);
			// letterMarker = generateLetterMarkerWithSize(clusterLength, 'F4978E');
		} else {
			letterMarker = pinLikeMarker(clusterLength, "ffbc00", "000000", 24);
			// letterMarker = generateLetterMarkerWithSize(clusterLength, 'F8AD9D');
		}
		return letterMarker;
	};
	setActiveHoverState = () => {
		const { setActiveHoveredCluster, marker, setClusteredOrder } = this.props;
		const { lat, lng } = marker;
		const isCluster = this.state.clusteredOrders.length > 1;
		isCluster
			? setActiveHoveredCluster({ lat, lng })
			: this.resetActiveHoverState();
    setClusteredOrder(this.state.clusteredOrders);
	};

	resetActiveHoverState = () => {
    const { setClusteredOrder } = this.props;
		this.props.setActiveHoveredCluster({ lat: null, lng: null });
    setClusteredOrder([])
	};
	render() {
		const { props } = this;
		const { setActiveHoveredCluster, activeHoveredCluster } = props;
		const { marker, fetchSelectedClustersInfo, isAirCargo } = props;
		const { isInfoWindowOpen, clusteredOrders } = this.state;
		const isCluster = this.state.clusteredOrders.length > 1;
		const clusterStatus = fetchSelectedClustersInfo(marker);
		return (
      <Marker
        position={{ lat: marker.lat, lng: marker.lng }}
        key={marker.id}
        icon={
          isCluster
            ? { url: this.clusterIcon(marker) }
            : {
                url: props.selectedOrderKeys.includes(marker.id)
                  ? fleetIcon
                  : ["R", "TR"].includes(marker.type_of_order)
                  ? redMarker
                  : greenMarker,
                size: { width: 20, height: 20 },
                scaledSize: { width: 20, height: 20 },
              }
        }
        onMouseOver={(e) =>
          // if marker is in closed then toggle open
          {
            this.setActiveHoverState();
            !isInfoWindowOpen && this.setState({ isInfoWindowOpen: true });
             const hoveredOrder = document.querySelector(`.activeOrderRow`);
             if (hoveredOrder) {
              //hoveredOrder.scrollIntoViewIfNeeded();
               hoveredOrder.scrollIntoView({
                 block: "end",
               });
             }
          }
        }
        onMouseOut={(e) => {
          // 3 seconds after mouse out, close info window (only for non cluster marker)
          if (clusteredOrders.length == 1) {
            this.setState({ isInfoWindowOpen: false });
            this.resetActiveHoverState();
          } else {
            // check if its not the active cluster which is hovered then close info window
            if (
              activeHoveredCluster.lat != marker.lat ||
              activeHoveredCluster.lng != marker.lng
            ) {
              this.setState({ isInfoWindowOpen: false });
            }
          }
        }}
        onClick={ (e) => {
          const { currentOrg } = this.context;
          const isTextAirOrg = currentOrg && currentOrg.code === 'TA';
          if (!isTextAirOrg) {
            const { accountsExceeded } = this.context;
            if (isInfoWindowOpen) {
              setActiveHoveredCluster({ lat: null, lng: null });
            }
            this.setState({ isInfoWindowOpen: !isInfoWindowOpen });

            const exceededAccountCodes = new Set(accountsExceeded.map(acc => acc.account_code));

            const ordersBelongingToExceededAccount = clusteredOrders.filter(
              (order) => exceededAccountCodes.has(order.account_code)
            );

            if (clusteredOrders.length === 1) {
              const order = clusteredOrders[ 0 ];
              // Check if the single order belongs to an exceeded account
              if (!ordersBelongingToExceededAccount.includes(order)) {
                props.markSelected(order.id);
              } else {
                // alertMessage(
                //   "This order belongs to an exceeded account and cannot be selected."
                // );
                ("");
              }
            }
          } else {
            if (isInfoWindowOpen) {
              setActiveHoveredCluster({ lat: null, lng: null });
            }
            this.setState({ isInfoWindowOpen: !isInfoWindowOpen });
            if (clusteredOrders.length === 1) {
              const order = clusteredOrders[ 0 ];
              // Check if the single order belongs to an exceeded account
                props.markSelected(order.id);
            }
          }
        } }
      >
        {isInfoWindowOpen && (
          <InfoWindow
            onCloseClick={(e) => {
              this.setState({ isInfoWindowOpen: false });
              this.resetActiveHoverState();
            }}
            options={{
              alignTop: true,
              closeBoxURL: "",
              enableEventPropagation: true,
            }}
            className="orderMapInfo"
          >
            <div
              style={{
                height: "auto",
                width: "auto",
                padding: 0,
              }}
              className="mapInfoWindow alignLeft"
            >
              <div className="hintText">
                {clusteredOrders.length == 1 ? (
                  <div>{retrieveMapIdentity(isAirCargo, clusteredOrders[0])}</div>
                ) : (
                    <Fragment>
                    <Checkbox
                      style={{ marginTop: "10px" }}
                      key={clusteredOrders[0].customer_order_number}
                      checked={
                        clusteredOrders.length > 0 &&
                        (clusterStatus.isPartiallySelected ||
                          clusterStatus.isFullySelected)
                      }
                      onChange={(e) => {
                        const { accountsExceeded } = this.context;
                        const exceededAccountCodes = accountsExceeded.map(
                          (exceededAccount) => exceededAccount.account_code
                        );

                        const allOrderIds = clusteredOrders.map(
                          (order) => order.id
                        );
                        const selectedOrderIds = clusteredOrders
                          .filter(
                            (order) =>
                              !(
                                e.target.checked &&
                                exceededAccountCodes.includes(
                                  order.account_code
                                )
                              )
                          )
                          .map((order) => order.id);
                        props.markMultiple(e.target.checked, selectedOrderIds);
                      }}
                    >
                      Select All
                    </Checkbox>           

                    <br />
                    {clusteredOrders.map((order, index) => {
                      const { customer_order_number, id, hawb } = order;
                      const { accountsExceeded } = this.context;
                      const isDisabled = accountsExceeded.some(
                        (exceededAccount) =>
                          order.account_code === exceededAccount.account_code
                      );
                      const displayValue = retrieveMapIdentity(isAirCargo, order)
                      return (
                        <OrderCheckbox
                          key={id}
                          orderId={id}
                          marker={marker}
                          orderNumber={displayValue}
                          selectedOrderKeys={props.selectedOrderKeys}
                          selectMarker={props.markSelected}
                          checkForCluster={props.checkForCluster}
                          unselectMarkerFromCluster={
                            props.unselectMarkerFromCluster
                          }
                          isDisabled={isDisabled}
                        />
                      );
                    })}
                  </Fragment>
                )}
              </div>
              {false && (
                <Card
                  style={{
                    minWidth: 250,
                    marginTop: 12,
                    fontSize: "14px",
                    color: "#000000",
                    padding: "10px 5px",
                  }}
                  title={`Order ${marker.customer_order_number}`}
                >
                  <Profile
                    address={marker.customer_address}
                    profile={(({
                      imagePlaceholder,
                      customer_first_name,
                      customer_last_name,
                      customer_phone_one,
                      customer_phone_two,
                      customer_email,
                    }) => ({
                      image: imagePlaceholder,
                      full_name: `${customer_first_name} ${customer_last_name}`,
                      mobile_number: `${customer_phone_one} ${
                        customer_phone_two && `,${customer_phone_two}`
                      }`,
                      email: customer_email,
                    }))(marker)}
                    showImage={false}
                    isMobileScreen
                    withLables={false}
                  />
                </Card>
              )}
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
	}
}
export default OrderMarker;