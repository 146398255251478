import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Tooltip,
  Icon,
} from '../../common/UIComponents'
import { alertMessage, base64ToPdf,isEmpty } from '../../common/Common'
import { downloadWeeklyReport } from '../../api/Reports'
import { renderAlertMessage } from '../../helpers/common'

const DownloadWeeklyReport = ({ driver_pay_report_id, driver_code, week_no, className, displayType, size }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const resetValues = (closeModal = false) => {
    setErrors([])
  }

  const downloadReport = () => {
    setIsLoading(false)
    downloadWeeklyReport(driver_pay_report_id)
      .then((result) => {
        if (result.success) {
          base64ToPdf(result.report_file, `Report_${driver_code}_${week_no}.pdf`)
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
        <Tooltip title="Download Report">
          { displayType === 'icon' ? <Icon
            onClick={() => downloadReport(true)}
            type="download"
            className={className ? className : ''}
          />
          :
          <Button type="primary" size={!isEmpty(size) ? size : 'default'} icon='download' onClick={() => downloadQuote(true)}> Download</Button>
          }
        </Tooltip>
    </Fragment>
  )
}

DownloadWeeklyReport.propTypes = {
  driver_pay_report_id: PropTypes.string.isRequired,
  driver_code: PropTypes.string.isRequired,
  week_no: PropTypes.string.isRequired,
}

export default DownloadWeeklyReport
