/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Col, FormItem, Input, Row, Select, Button, Popconfirm, Alert, Checkbox } from '../../common/UIComponents';
import { alertMessage, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import FormErrors from '../common/FormErrors';
import { completeRoute, completeStop} from '../../api/Routes';
import PendingOrdersList from './PendingOrdersList';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import { fetchExceptionMessages } from '../../api/PreplanApi';

const { TextArea } = Input;
class CompleteRouteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      errors: [],
      statusErrors: [],
      exceptionId: '',
      exceptionMessage: '',
      nav_route_id: this.props.nav_route_id,
      navRoute: this.props.navRoute,
      exceptions: [],
      pendingOrders: [],
      orderContacts: {},
      organizationSettings: {},
      isMilitaryTime: false,
      stopPendingOrders: [],
      toComplete: !isEmpty(this.props.toComplete) ? this.props.toComplete : "route",
      notify: true,
      customMessage: false, 
    };
  }

  componentDidMount() {
    if(checkServiceExistance('NRNRE')){
      this.getExceptionMessages();
    }
    this.findIncompleteOrders();
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
  }


  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }

  getPendingOrderObject = (navRoute, stop = null, order, orderContacts, loc = null) => {
    const orderObject = {
      customer_order_id: order.customer_order_id,
      customer_order_number: order.customer_order_number,
      status: '',
      contact_name: loc ? loc.name : !isEmpty(orderContacts[order.customer_order_id]) ? orderContacts[order.customer_order_id] : '',
      action_date: navRoute.route_start_time ? navRoute.route_start_time : null,
      action_time: null,
      requireSign: !order.nsr,
    }
    return orderObject;
  }

  getKeysOfStop = (type) => {
    switch (type) {
      case "RC": return 'recoveries';
      case "LH": return 'linehauls';
      case "RL": return 'releases';
      default: return 'customer_order_ids';
    }
  }

  findIncompleteOrders = () => {
    const { currentStop } = this.props;
    const navRoute = { ...this.state.navRoute };
    const stops = navRoute.nav_stops ? [...navRoute.nav_stops] : [];
    const customerStops = stops.filter(stop => ['CS', 'PS', 'LH', 'RC', 'RL'].includes(stop.location_type));
    const orderContacts = {};
    customerStops.forEach((stop) => {
      // stop.customer_order_ids
      const orders = _.compact([].concat(stop.customer_order_ids, stop.linehauls, stop.recoveries, stop.releases))
      orders.forEach((order) => {
        const key = order.customer_order_id ? order.customer_order_id : order.id
        orderContacts[key] = stop.contact_name;
      });
    });
    const pendingOrders = [];
    const incompletedStops = stops.filter(stop => (stop.stop_order_sequence === 0 && stop.location_type === 'LH' || stop.stop_order_sequence !== 0) && stop.status !== 'COMPLETED' && stop.status !== 'INCOMPLETE');
    incompletedStops.forEach((stop) => {
      const key = this.getKeysOfStop(stop.location_type); // === 'LH' ? "linehauls" : "customer_order_ids" ;
      if (navRoute.route_location_ids && stop[key]) {
        stop[key].forEach((order) => {
          if(order?.order_locations?.length > 0){
            const routeOrderLocations = order.order_locations.filter(loc => navRoute.route_location_ids.includes(loc.id))
            routeOrderLocations.forEach(loc => {
              const orderIndex = _.findIndex(pendingOrders, {customer_order_id: order.customer_order_id, location_id: loc.id });
              if (orderIndex < 0) {
                const orderObject = this.getPendingOrderObject(navRoute, stop, order,  orderContacts, loc)
                pendingOrders.push({...orderObject, location_id: loc.id, type_of_loc: loc.type_of_loc });
              }
            });
          }else {
            const orderIndex = _.findIndex(pendingOrders, {customer_order_id: order.customer_order_id});
            const orderObject = this.getPendingOrderObject(navRoute, null, order, orderContacts)
            if (orderIndex < 0) {
              pendingOrders.push(orderObject);
            }
          }          
        });
      }
    });
      
    // const stopCustomerIds =
    //   !_.isEmpty(currentStop) && currentStop.customer_order_ids.length
    //     ? currentStop.customer_order_ids
    //     : [];
    // const stopPendingOrders = pendingOrders.filter((order) =>
    //   stopCustomerIds.includes(order.customer_order_id)
    // );
      const stopCustomerIds =
        !_.isEmpty(currentStop) && currentStop?.location_ids?.length
          ? currentStop.location_ids
          : [];
      const stopPendingOrders = pendingOrders.filter((order) =>
        stopCustomerIds.includes(order.location_id)
      );
    this.setState({
      pendingOrders,
      stopPendingOrders,
      orderContacts,
    });
  }


  handleChange = (key, value) => {
    if (key === 'exceptionId') {
      if (value === 'CUSTOM') {
        this.setState({ customMessage: true, exceptionId: value, exceptionMessage: '' });
      } else {
        const selectedOption = this.state.exceptions.find(excep => excep.exception_code === value);
        const exceptionMessage = selectedOption ? selectedOption.exception_message : '';
        const exceptionCode = selectedOption ? selectedOption.exception_code : '';
        this.setState({ customMessage: false, exceptionId:value, exceptionMessage, exceptionCode });
      }
    } else {
      this.setState({ [key]: value });
    }
  }

  handleCustomMessageChange = (e) => {
    this.setState({ customMessage: e.target.checked });

    if (e.target.checked) {
      this.setState({ exceptionId: '', exceptionMessage: '' });
    }
}


handleOrdersChange = (id, location_id, element, value) => {
  const pendingOrders = [...this.state.pendingOrders];
  const currentObjectIndex = _.findIndex(pendingOrders, { customer_order_id: id, location_id: location_id });
  if (currentObjectIndex >= 0) {
    pendingOrders[currentObjectIndex][element] = value;
    this.setState({ pendingOrders });
  }
}

getExceptionMessages = () => {
  this.setState({ inProgress: true });
  fetchExceptionMessages()
    .then((result) => {
      if (result.success) {
        const exceptions = [...result.exceptions, { exception_code: 'CUSTOM', exception_message: 'Custom Message', id: 'CUSTOM' }];
        this.setState({
          exceptions,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false, exceptions: [{ exception_code: 'CUSTOM', exception_message: 'Custom Message', id: 'CUSTOM' }] });
        renderAlertMessage(result.errors)
      }
    });
};

handleSave = () => {
  this.setState({ inProgress: true, errors: [] });
  const errors = [];
  const orders = [];
  const incompleteOrders = this.state.toComplete === 'stop' ? this.state.stopPendingOrders : this.state.pendingOrders
  const exceptionOrders = incompleteOrders.filter(order => order.status === 'EXCEPTION');
  const completedOrders = this.state.pendingOrders.filter(order => order.status === 'COMPLETED');
  // if (this.state.pendingOrders.length !== exceptionOrders.length + completedOrders.length) {
  //   errors.push('Please select status of orders');
  // }
  if (exceptionOrders.length>0 && this.state.exceptionId === '' && !this.state.customMessage) {
    errors.push('Please select an exception or provide a custom message.');
  }

  incompleteOrders.forEach((order) => {
    const tempErrors = [];
    if (isEmpty(order.status)) {
      tempErrors.push(I18n.t('general.status'));
    } else if (order.status === I18n.t('routes.statuses.completed_key')) {
      if (isEmpty(order.action_date)) {
        tempErrors.push(I18n.t('general.date'));
      }
      if (isEmpty(order.action_time)) {
        tempErrors.push(I18n.t('general.time'));
      }
      if (order.requireSign && isEmpty(order.sign_by)) {
        tempErrors.push(I18n.t('general.sign_by'));
      }
      // if (isEmpty(order.signed_title)) {
      //   tempErrors.push(I18n.t('general.signed_title'));
      // }
    }

    if (this.state.customMessage && this.state.exceptionMessage=== '') {
      errors.push('Custom message cannot be empty.');
    }
  

    if (tempErrors.length > 0) {
      errors.push(`Please select ${tempErrors.join(',')} for an order ${order.customer_order_number}`);
    } else if (order.status === I18n.t('routes.statuses.completed_key')) {
      const actionTime = moment(order.action_time).format('HH:mm');
      const presentTime = moment().format();
      const orderActionTime = moment(`${moment(order.action_date).format('YYYY-MM-DD')} ${actionTime}`, 'YYYY-MM-DD HH:mm').format();
      if (moment(presentTime).isBefore(orderActionTime)) {
        errors.push(`Future time should not be selected for an order ${order.customer_order_number}`);
      } else {
        orders.push({
          actual_end_datetime: orderActionTime, // moment(order.action_date).format('YYYY-MM-DD'),
          // action_time: actionTime,
          customer_order_id: order.customer_order_id,
          status: order.status,
          location_id: order.location_id ? order.location_id : '',
          signer_name: order.sign_by,
          signer_title: order.signed_title,
        });
      }
    } else {
      orders.push({
        actual_end_datetime: "", // moment(order.action_date).format('YYYY-MM-DD'),
        // action_time: actionTime,
        customer_order_id: order.customer_order_id,
        status: order.status,
        location_id: order.location_id ? order.location_id : "",
        signer_name: order.sign_by,
        signer_title: order.signed_title,
      });
    }
  });
  // if (exceptionOrders.length > 0 && this.state.exceptionId === '') {
  //   errors.push('Please select exception');
  // } else if (this.state.exceptionId === 'CUSTOM' && isEmpty(this.state.exceptionMessage)) {
  //   errors.push('Custom Message is required');
  // }

  if (errors.length === 0) {
    let exceptionObj = _.find(this.state.exceptions, { id: this.state.exceptionId });
    exceptionObj = !isEmpty(exceptionObj) ? exceptionObj : {};
    console.log(this.state)
    const data = {
      orders,
      nav_route_id: this.state.nav_route_id,
      // exception_id: this.state.customMessage ? null : this.state.exceptionId,
      exception_code: this.state.customMessage ? 'CUSTOM' : this.state.exceptionCode,
      exception_message: this.state.customMessage ? this.state.exceptionMessage : this.state.exceptionMessage,
      notify: this.state.notify,
    };

    if (this.state.customMessage) {
      data.exception_message = this.state.exceptionMessage;
    }

    if (this.state.toComplete === 'stop') {
      data.nav_stop_id = this.props.currentStop.id;
      delete data.nav_route_id;
    }
    this.setState({ inProgress: true });
    if(this.state.toComplete === 'stop'){
      completeStop(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState(
            {
              inProgress: false,
              pendingOrders: [],
              stopPendingOrders: [],
              exceptionId: "",
              exceptionMessage: "",
            },
            () => {
              this.props.closeModal(true);
            }
          );
        } else {
          this.setState({ inProgress: false });
          this.processErrors(result.errors);
        }
      });
    }
    else{
      completeRoute(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState(
            {
              inProgress: false,
              pendingOrders: [],
              exceptionId: "",
              exceptionMessage: "",
            },
            () => {
              this.props.closeModal(true);
            }
          );
        } else {
          this.setState({ inProgress: false });
          this.processErrors(result.errors);
        }
      });
    }
  } else {
    this.setState({ errors, inProgress: false });
  }
}

processErrors = (errors) => {
  if (errors?.length > 0) {
    const errorValue = errors[0];
    if (_.isString(errorValue)) {
      this.setState({
        errors,
        statusErrors: [],
      });
    } else if (_.isObject(errorValue)) {
      const statusErrors = [];
      errors.forEach((errObj) => {
        if (_.isObject(errObj) && errObj.order_number) {
          statusErrors.push(errObj);
        }
      });
      this.setState({
        errors: [],
        statusErrors,
      });
    }
  }
}


render() {
  const sortedExceptions = this.state.exceptions.filter(excep => excep.exception_code !== 'CUSTOM')
                    .sort((a, b) => a.exception_message.localeCompare(b.exception_message))
  return (
    <div className="marginTop10">
      <Row gutter={16}>
        <Col xs={24}>
          <PendingOrdersList
            isMilitaryTime={this.state.isMilitaryTime}
            data={this.state.toComplete === 'stop' ? this.state.stopPendingOrders : this.state.pendingOrders}
            pagination={{ position: 'none' }}
            handleChange={this.handleOrdersChange}
            size="small"
          />
        </Col>
        <Col xs={24} className="marginTop10">
          <Row>
            <Row>
              <Col xs={24} style={{ paddingBottom: 10 }}>           
               <FormItem label='Select Exception'>
                <Select
                  style={{ width: '100%' }}
                  className="routes-input"
                  onChange={e => this.handleChange('exceptionId', e)}
                  defaultValue="Change Status"
                  value={this.state.exceptionId ? this.state.exceptionId : ''}
                  size="small"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                >
                  <Select.Option value="" key="">---Select---</Select.Option>
                    {sortedExceptions.map(excep => (
                      <Select.Option value={excep.exception_code} key={excep.exception_code}>
                        {excep.exception_message}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
                  <div>
                  <Row>
                    <Col xs={24} className="fontBold600">
                      <Checkbox
                        onChange={this.handleCustomMessageChange}
                        checked={this.state.customMessage}
                      >
                        Custom Message
                      </Checkbox>
                    </Col>
                  </Row>
                  {this.state.customMessage &&
                    <TextArea
                      rows={4}
                      placeholder={I18n.t('general.message')}
                      className='sop-form-textArea'
                      value={this.state.exceptionMessage}
                      onChange={e => this.handleChange('exceptionMessage', e.target.value)}
                    />
                  }
                </div>
              </Col>
            </Row>
            
            <Row>
              <Col xs={24} className="fontBold600">
                <Checkbox onChange={(e) => this.handleChange('notify', e.target.checked )}
                checked = {this.state.notify}
                >{I18n.t('routes.send_notify_to_consignee_shipper')} </Checkbox>
              </Col>
            </Row>
            
            <Row>
              <Col xs={24} style={{ padding: 10, textAlign: 'center' }}>
                <Button type="danger" onClick={this.props.closeModal}>
                  Cancel
                </Button>

          &nbsp; &nbsp;
                <Popconfirm
                  placement="rightTop"
                  title={I18n.t('messages.complete_route_or_stop', {item: this.state.toComplete === 'stop' ? 'stop' : 'route'})}
                  onConfirm={this.handleSave}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    loading={this.state.inProgress}
                    // disabled={!this.state.currentStatus}
                  >
                    {I18n.t('general.save')}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Row>

        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {/* FormErrors(this.state.errors) */}
          {
            this.state.statusErrors.length > 0 &&
            <Alert
              message="Error"
              description={
                <div>
                  {this.state.statusErrors.map(err =>
                    (
                      _.isObject(err) ?
                        <Row>
                          <Col xs={4}>{err.order_number}</Col>
                          <Col xs={20}>{_.isArray(err.errors) ? err.errors.join(',') : ''}</Col>
                        </Row>
                        :
                        <Fragment />
                    ))
                  }
                </div>
              }
              type="error"
              closable
            />
          }
          {
            this.state.errors.length > 0 &&
          FormErrors(this.state.errors)
          }
        </Col>
      </Row>
    </div>
  );
}
}

CompleteRouteForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  navRoute: PropTypes.shape().isRequired,
  nav_route_id: PropTypes.string.isRequired,
};

export default CompleteRouteForm;
