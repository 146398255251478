import React, { useState } from 'react';
import ZonesListComponent from '../../containers/ZonesList';
import AccountCodesFilter from '../../containers/Filters/AccountCodesFilter';


const AccountsFilter = (props) => {
  const [selectedAccountCodes, setSelectedAccountCodes] = useState([]);
  const {accountsList = []} = props;

  const handleFilterPlaceHolderChange = (ele , value) => {
    setSelectedAccountCodes(value);
    props.onValueChange(value);
  };

  return (
    <AccountCodesFilter
      SelectedAccountCodes={selectedAccountCodes}
      onValueChange={handleFilterPlaceHolderChange}
      accounts={accountsList}
      element="account_codes"
      valueField="code"
    />
  );
}

const ZonesFilter = (props) => {
  const [selectedZones, setSelectedZones] = useState([]);

  const handleFilterPlaceHolderChange = (ele, value) => {
    setSelectedZones(value);
    props.onValueChange(value);
  };

  return (
    <ZonesListComponent
      SelectedZones={selectedZones}
      onValueChange={handleFilterPlaceHolderChange}
      element="zone_ids"
      valueField="id"
    />
  );
}


export { AccountsFilter, ZonesFilter }