import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FormItem, DatePicker, Button, Radio, Input, Icon } from "../../../common/UIComponents";
import { fetchOrgAccounts } from "../../../api/Account";
import AppConfig from "../../../config/AppConfig";
import AccountCodesFilter from "../../../containers/Filters/AccountCodesFilter";
import WarehouseCodeFilter from "../../common/WarehouseCodeFilter";
import I18n from "../../../common/I18n";

const defaultFilterValues = {
  accountCodes: [],
  warehouseIds: [],
  invoiceAmountFilter: "WITH_AMOUNT",
  reportDate: moment(),
  ageFilter: "BETWEEN",
  fromAge: 0,
  toAge: 30,
  ageGte: null,
  ageLte: null,
};  

class CollectionsReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.currentFilter || { ...defaultFilterValues },
      accounts: [],
      filteredAccounts: [],
      accountsLoading: false,
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.currentFilter, this.props.currentFilter)) {
      this.setState({ filter: _.cloneDeep(this.props.currentFilter) });
    }
  }

  getAccounts = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState(
          {
            accounts: result.accounts || [],
            accountsLoading: false,
          },
          () => {
            this.handleFilterPlaceHolderChange("warehouseIds", currentWhId);
          }
        );
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  setFilteredAccounts = (filteredAccount = []) => {
    const { accountCodes } = this.state.filter;
    const filteredAccCodes =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.id) : [];

    if (accountCodes?.length) {
      const filteredAccountCodes = filteredAccCodes.filter((acc_code) =>
        accountCodes.includes(acc_code)
      );
      this.handleFilterPlaceHolderChange("accountCodes", filteredAccountCodes);
    } else {
      this.handleFilterPlaceHolderChange("accountCodes", []);
    }
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function") {
        this.props.handleChildFilterChange(filter);
      }
    });
  };

  handleDateRangeChange = (dates, dateStrings) => {
    this.setState({
      filter: {
        ...this.state.filter,
        fromDate: dateStrings[0] !== "" ? dates[0] : null,
        toDate: dateStrings[1] !== "" ? dates[1] : null,
      },
    });
  };

  handleFilterPlaceHolderChange = (element, value) => {
    const filter = { ...this.state.filter };
    let ageError = null;
  
    if (element === "ageFilter") {
      filter.ageFilter = value;
      switch (value) {
        case "BETWEEN":
          filter.fromAge = 0;
          filter.toAge = 30;
          filter.ageGte = null;
          filter.ageLte = null;
          break;
        case "GREATER_THAN_EQUAL":
          filter.ageGte = 0;
          filter.fromAge = null;
          filter.toAge = null;
          filter.ageLte = null;
          break;
        case "LESS_THAN_EQUAL":
          filter.ageLte = 30;
          filter.fromAge = null;
          filter.toAge = null;
          filter.ageGte = null;
          break;
      }
    } else if (["fromAge", "toAge", "ageGte", "ageLte"].includes(element)) {
      const numValue = value === "" ? null : Number(value);
      if (numValue !== null && numValue < 0) {
        ageError = "Age values must be non-negative numbers";
      } else {
        filter[element] = numValue;
  
        if (filter.ageFilter === "BETWEEN") {
          if (element === "fromAge" && filter.toAge !== null && numValue > filter.toAge) {
            ageError = "From Age cannot be greater than To Age";
          } else if (element === "toAge" && filter.fromAge !== null && numValue < filter.fromAge) {
            ageError = "To Age cannot be less than From Age";
          }
        }
      }
    } else if (element === "reportDate") {
      filter.reportDate = value;
    } else if (element === "invoiceAmountFilter") {
      filter.invoiceAmountFilter = value;
    } else if (element === "warehouseIds") {
      filter.warehouseIds = value;
    } else if (element === "accountCodes") {
      filter.accountCodes = value;
    } else {
      filter[element] = value;
    }
  
    this.setState({ filter, ageError }, () => {
      if (element === "warehouseIds") {
        const { accounts } = this.state;
        const { warehouses } = this.props.warehouseFilter;
        const { warehouseIds } = this.state.filter;

        if (warehouseIds.length === 0) {
          this.setState({ filteredAccounts: accounts });
        } else {
          const filteredAccounts = accounts.filter((account) =>
            account.warehouse_ids.some((id) => warehouseIds.includes(id))
          );
          this.setState({ filteredAccounts }, () => {
            this.setFilteredAccounts(filteredAccounts);
          });
        }
      }
      
      if (this.props.onChange) {
        this.props.onChange(this.state.filter);
      }
    });
  };

  handleSearch = () => {
    const filter = {
      ...this.state.filter,
    };
    this.props.onSearch(filter);
  };

  clearForm = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : [];
  
    const filter = {
      ...defaultFilterValues,
      warehouseIds: currentWhId,
    };
    this.setState({ filter, ageError: null }, () => {
      if (this.props.onChange) {
        this.props.onChange(filter);
      }
      this.props.onSearch(filter);    });
  };
  _renderDatePicker = () => {
    return (
      <FormItem label={I18n.t("aging.report_date")}>
        <DatePicker
          value={this.state.filter.reportDate}
          format={AppConfig.dateFormat}
          style={{ width: "50%" }}
          allowClear={false}
          onChange={(value) => this.handleFilterPlaceHolderChange("reportDate", value)}
        />
      </FormItem>
    );
  };

  _renderWhFilter = () => {
    const { warehouses } = this.props.warehouseFilter;
    return (
      <FormItem label={I18n.t("location.location")}>
        <WarehouseCodeFilter
          SelectedCodes={this.state.filter.warehouseIds}
          element={"warehouseIds"}
          onValueChange={this.handleFilterPlaceHolderChange}
          warehouses={warehouses}
          size="default"
        />
      </FormItem>
    );
  };

  _renderAmountFilter = () => {
    return (
      <FormItem label={I18n.t("account.billing.filter.filter_results_with")} style={{ marginTop: 10 }}>
        <Radio.Group
          onChange={(e) => this.handleFilterPlaceHolderChange("invoiceAmountFilter", e.target.value)}
          value={this.state.filter.invoiceAmountFilter}
        >
          <Radio value={"WITH_AMOUNT"}>{I18n.t("account.billing.filter.with_amount_label")}</Radio>
          <Radio value={"WITHOUT_AMOUNT"}>{I18n.t("account.billing.filter.without_amount_label")}</Radio>
          <Radio value={"BOTH"}>{I18n.t("general.both")}</Radio>
        </Radio.Group>
      </FormItem>
    );
  };

  isAgeRangeValid = () => {
    const { filter } = this.state;
    switch (filter.ageFilter) {
      case "BETWEEN":
        return filter.fromAge !== null && filter.toAge !== null;
      case "GREATER_THAN_EQUAL":
        return filter.ageGte !== null;
      case "LESS_THAN_EQUAL":
        return filter.ageLte !== null;
      default:
        return false;
    }
  };
  
  getAgeRangeErrorMessage = () => {
    if (!this.isAgeRangeValid()) {
      return "Please enter Age Range";
    }
    return null;
  };
  
  _renderAgeFilter = () => {
    const { filter, ageError } = this.state;
    const labelStyle = {
      fontWeight: 'bold',
      marginRight: '5px'
    };
  
    const ageRangeError = this.getAgeRangeErrorMessage();
  
    return (
      <Fragment>
        <FormItem label={I18n.t("Age Range")} style={{ marginTop: 10 }}>
          <Radio.Group
            onChange={(e) => this.handleFilterPlaceHolderChange("ageFilter", e.target.value)}
            value={filter.ageFilter}
          >
            <Radio value="BETWEEN">{I18n.t("Between")}</Radio>
            <Radio value="GREATER_THAN_EQUAL">{I18n.t("Age >=")}</Radio>
            <Radio value="LESS_THAN_EQUAL">{I18n.t("Age <=")}</Radio>
          </Radio.Group>
        </FormItem>
        
        {filter.ageFilter === "BETWEEN" && (
          <div style={{ display: 'flex', alignItems: 'center', width: '80%', marginTop: 10 }}>
            <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
              <span style={labelStyle}>{I18n.t("From Age")}:</span>
              <Input
                size="small"
                style={{ width: '70px' }}
                type="number"
                min="0"
                value={filter.fromAge !== null ? filter.fromAge : ""}
                onChange={(e) => this.handleFilterPlaceHolderChange("fromAge", e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={labelStyle}>{I18n.t("To Age")}:</span>
              <Input
                size="small"
                style={{ width: '70px' }}
                type="number"
                min="0"
                value={filter.toAge !== null ? filter.toAge : ""}
                onChange={(e) => this.handleFilterPlaceHolderChange("toAge", e.target.value)}
              />
            </div>
          </div>
        )}
        
        {filter.ageFilter === "GREATER_THAN_EQUAL" && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <span style={labelStyle}>{I18n.t("Greater Than or Equal")}:</span>
            <Input
              size="small"
              style={{ width: '70px' }}
              type="number"
              min="0"
              value={filter.ageGte !== null ? filter.ageGte : ""}
              onChange={(e) => this.handleFilterPlaceHolderChange("ageGte", e.target.value)}
            />
          </div>
        )}
        
        {filter.ageFilter === "LESS_THAN_EQUAL" && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <span style={labelStyle}>{I18n.t("Less Than or Equal")}:</span>
            <Input
              size="small"
              style={{ width: '70px' }}
              type="number"
              min="0"
              value={filter.ageLte !== null ? filter.ageLte : ""}
              onChange={(e) => this.handleFilterPlaceHolderChange("ageLte", e.target.value)}
            />
          </div>
        )}
        
        {(ageError || ageRangeError) && (
          <div style={{ color: 'red', marginTop: 5 }}>{ageError || ageRangeError}</div>
        )}
      </Fragment>
    );
  };

  _handleExport = () => {
    this.setState({ isExporting: true });
    const filter = {
      ...this.state.filter,
      isExporting: true,
    };
    this.props.onExport(filter);
  };
    

  render() {
    const { filter, ageError } = this.state;
    const isAgeRangeValid = this.isAgeRangeValid();
    const isButtonDisabled = !!ageError || !isAgeRangeValid;
    return (
      <Fragment>
        <div>
          {this._renderWhFilter()}
          <AccountCodesFilter
            SelectedAccountCodes={this.state.filter.accountCodes}
            accounts={this.state.filteredAccounts}
            size="small"
            onValueChange={this.handleFilterPlaceHolderChange}
            showLabel={true}
            valueField="id"
            styleObject={{ width: "100%", marginBottom: 5 }}
          />
          {this._renderDatePicker()}
          {/* {this._renderAmountFilter()} */}
          {this._renderAgeFilter()}
          <div style={{ padding: "10px 16px", textAlign: "center", marginTop: 10 }}>
        <Button
          type="danger"
          onClick={this.clearForm}
          icon="redo"
        >
          Reset
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          onClick={this.handleSearch}
          type="primary"
          icon="search"
          disabled={isButtonDisabled}
        >
          Search
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          className="cursorPointer"
          type="primary"
          disabled={this.props.isExporting || isButtonDisabled}
          loading={this.props.isExporting}
          onClick={this._handleExport}
          shape="round"
        >
          {this.props.isExporting ? (
            "Exporting"
          ) : (
            <span>
              Export <Icon type="file-pdf" theme="filled" />
            </span>
          )}
        </Button>
      </div>
        </div>
      </Fragment>
    );
  }
}

CollectionsReportFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  orgData: PropTypes.object.isRequired,
  warehouseFilter: PropTypes.object.isRequired,
};

CollectionsReportFilter.defaultProps = {};

export default withRouter(CollectionsReportFilter);
