import { Call, ApiUrl, PageParams, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import { isEmpty } from '../common/Common';
import I18n from '../common/I18n';

export function fetchAccounts(
  orgId,
  page = 1,
  perPage = AppConfig.perPage,
  sortOrder = "ascend",
  searchText = "",
  filter = {}
) {
  let url = ApiUrl(`v2/account?${PageParams(page, perPage)}`);
  if (!isEmpty(orgId)) {
    url = `${url}&org_id=${orgId}`;
  }
  // if (!isEmpty(sortOrder)) {
  //   url = `${url}&sort_order=${I18n.t(`general.sort_${sortOrder}`)}`;
  // }
  if (!_.isEmpty(filter) && !isEmpty(filter.sortBy) && !isEmpty(filter.sortByType)) {
     url = `${url}&sort_by=${filter.sortBy}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}`;
  }
  if (!isEmpty(searchText)) {
    url = `${url}&search_text=${searchText}`;
  }
  url = `${url}&operation_code=AI`;
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { accounts: 'accounts', pagination: 'meta.pagination' },
  }, true);
}

export function fetchPrimaryAccountCodes(data = {}) {
  let url = ApiUrl(`v2/account/primary_accounts?operation_code=PA&skip_wh_check=${data?.skip_wh_check === false ? false : true}`);
  if (data?.orgId && !isEmpty(data.orgId)) {
    url = `${url}&org_id=${orgId}`;
  }
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accounts: "accounts", pagination: "meta.pagination" },
    },
    true
  );
}

export function fetchShortFormAccounts(orgId,whId,skip_wh = false) {
  let url = ApiUrl("v2/account/ddl_accounts?operation_code=DA");
  if (!isEmpty(orgId)) {
    url = `${url}&org_id=${orgId}`;
  }
  if(!isEmpty(whId)){
    url = `${url}&warehouse_ids=${whId}`;
  }
  if(skip_wh){
    url = `${url}&skip_wh_check=${skip_wh}`;
  }
  
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accounts: "accounts" },
    },
    true
  );
}
export function fetchAccount(accountId) {
  const url = ApiUrl(`v2/account/${accountId}?account_id=${accountId}&operation_code=AS`);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        account: "account",
        is_primary_account: "is_primary_account",
      },
    },
    true
  );
}

export function fetchSubAccounts(accountId) {
  const url = ApiUrl(`v2/account/sub_accounts?account_id=${accountId}&operation_code=SA`);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accounts: "accounts" },
    },
    true
  );
}

export function saveAccount(isNew, data) {
  if (
    data.phone_number &&
    data.phone_number.length > 0 &&
    data.phone_number[0] !== "+"
  ) {
    data.phone_number = `+${data.phone_number}`;
  }
  data.operation_code = isNew ? 'AC' : 'AU';
  const formData = setObjectToFormData(data);
  const url = isNew ? ApiUrl("v2/account") : ApiUrl(`v2/account/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: {account : "account"},  }, true);
}

export function generateStandardAccessorials(data) {
  data.operation_code = 'AAGSA'
  const url = ApiUrl(`v2/account_accessorials/generate_standard_accessorials`);
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}

export function deleteAccount(id, data = {}) {
  data.operation_code = 'AD';
  const url = ApiUrl(`v2/account/${id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, true);
}

export function setAccountConfig(data, accountId) {
  data.operation_code = 'ACNF';
  const url = ApiUrl(`v2/account/${accountId}/configuration`);
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { acct_config: "" },
  });
}

export function fetchAccountConfigs(accountId, orgId, settingType = 'ACCOUNT') {
  const url = ApiUrl(
    `v2/account/${accountId}/configuration_details?organization_id=${orgId}&operation_code=ACNFD&setting_type=${settingType}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { account_configurations: "account_configurations" },
  });
}

export function fetchAccountAccessorials(accountId, visibleTo = "ALL") {
  // const url = ApiUrl(`v2/account_accessorials?account_id=${accountId}&visible_to=${visibleTo}`);
  const url = ApiUrl(
    `v2/account_accessorials/account_accessorial_collection?account_id=${accountId}&visible_to=${visibleTo}&operation_code=AAAAC`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { accessorials: "account_accessorials" },
  });
}

export function fetchAccountAccessorialWithParams(
  accountId,
  visibleTo = "ALL"
) {
  const url = ApiUrl(
    `v2/account_accessorials/account_accessorial?account_id=${accountId}&visible_to=${visibleTo}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { accessorials: "account_accessorials" },
  });
}

export function setAccountAccessorial(data) {
  const url = ApiUrl("v2/account_accessorials");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { accessorials: "" },
  });
}

export function setBillingConfig(data) {
  const url = ApiUrl("v2/billing/configuration");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { acct_config: "" },
  });
}

export function fetchAccountBillingConfigs(orgId, accountId) {
  let url = ApiUrl(
    `v2/billing/configuration_details?organization_id=${orgId}&operation_code=BLCONFD`
  );
  if(!isEmpty(accountId)){
    url = `${url}&account_id=${accountId}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { configurations: "account_billing_configurations" },
  });
}

export function fetchAccountDeliveryZipcodes(
  orgId,
  accountId,
  use_org_delivery_zips
) {
  let url = ApiUrl(`v2/delivery_zip?org_id=${orgId}&operation_code=DZI`);
  if (!isEmpty(accountId)) {
    url = `${url}&account_id=${accountId}`;
  }
  if (!isEmpty(use_org_delivery_zips)) {
    url = `${url}&use_org_delivery_zips=${use_org_delivery_zips}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { dow_zipcodes: "dow_zip_codes" },
  });
}

export function saveDeliveryZipcodes(data) {
  data.operation_code = 'SWHZC';
  const url = ApiUrl("v2/delivery_zip/save_wh_zip_codes");
  return Call("post", url, data, ParseGeneralResponse);
}

export function fetchAccountDims(orgId, accountId) {
  const url = ApiUrl(
    `v2/dims?organization_id=${orgId}&account_ids=${accountId}&operation_code=DIMI`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { configurations: "dims" },
  });
}

export function saveDimValues(data, isNew) {
  data.operation_code = isNew ? 'DIMC' : 'DIMU';
  const method = isNew ? "post" : "put";
  const url = ApiUrl(`v2/dims/${isNew ? "" : data.id}`);
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { acct_config: "" },
  });
}

export function generateBol(account, org) {
  const url = ApiUrl(`v2/billing/account_bols?account_id=${account}&organization_id=${org}&operation_code=ABOLG`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { response: "" },
  });
}

export function copyAccount(data) {
  const url = ApiUrl("v2/account_cloning/create_acc_details");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { account_configurations: "" , billing_zones: "" , level_of_services: "" },
  });
} 
export function uploadAccountsCsv(data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/import_accounts/create_accounts");
  return Call("post", url, formData, ParseGeneralResponse, {
    responseDataKeys: { response: "" },
  });
}

export function fetchOrgRules(organization_id) {
  const url = ApiUrl(
    `v2/organization_rules?organization_id=${organization_id}&operation_code=OCNFR`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { organization_rules: "organization_rules" },
  });
}

export function updateOrgRules(data) {
  const url = ApiUrl("v2/organization_rules");
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { organization_rule: "organization_rule", message: "message"},
  });
} 

export function fetchOrgAccounts(orgId,whId,skip_wh = false) {
  let url = ApiUrl("v2/account/org_accounts?operation_code=DA");
  if (!isEmpty(orgId)) {
    url = `${url}&org_id=${orgId}`;
  }
  
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { accounts: "accounts" },
    },
    true
  );
}

export function fetchOrderTypeLos(orgId,account_id) {
  let url = ApiUrl(`v2/account/${account_id}/order_type_los_details?operation_code=DA`);
  if (!isEmpty(orgId)) {
    url = `${url}&org_id=${orgId}`;
  }
  
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}

export function saveOrderTypeLos(payload) {
  payload.operation_code = 'DA';
  let url = ApiUrl(`v2/account/${payload.account_id}/order_type_los?operation_code=DA`);
  return Call(
    "put",
    url,
    payload,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}