import { Col, Icon, Popconfirm, Row, Tooltip, Input } from "antd";
import React from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import { checkNegitive } from "../../../common/Common";

class DriversWeeklyDeductionList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.columns = [
      {
        key: "Deduction name",
        title: <b>{I18n.t("drivers_weekly_report.deduction_name")}</b>,
        dataIndex: "deduction_name",
        render: (data, record) => (
          record.payment_type !== 'FIXED' && !['CLAIM', 'LOAN', 'ADVANCE'].includes(record.deduction_standard_code) ?
          <span className="textUpperCase">{data}</span>
          :
          <div>
          {data} {['CLAIM', 'LOAN', 'ADVANCE'].includes(record.deduction_standard_code) && <span className='notes_content textBold'>
            ( {this.getAmount(record, this.props)} )
          </span>}
          </div>
        ),
        width: 200,
      },
      {
        key: "gl_code",
        title: <b>{I18n.t("drivers_weekly_report.gl_code")}</b>,
        dataIndex: "gl_code",
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        width: 100,
      },
      {
        key: "amount",
        title: <b>{I18n.t("general.amount")}($)</b>,
        dataIndex: "amount",
        //render: (data, record) => data ? checkNegitive(data) : '',
        render: (data, record, index) => 
        props.isApproved ?
        <div>
          {checkNegitive(data)}
        </div>
        :
        <Input
            onChange={(e) =>
              props.handleOnChange(index, "amount", e.target.value)
            }
            value={data}
            style={{ width: 100, fontSize: 12 }}
            size="small"
            type="number"
            prefix={<Icon type="dollar" />}
            min={0}
            onBlur={(e) => 
              props.handleOnChange(
                index,
                "amount",
                record.amount ? parseFloat(record.amount).toFixed(2) : 0.00
              )
            }
            disabled={["PERCENTAGE_ON_GROSS_PAY", "PERCENTAGE_ON_GROSS_REVENUE"].includes(record.payment_type)}
          />,
        width: 100,
        align: 'right' 
      },
      // {
      //   key: "Summary",
      //   title: <b>{I18n.t("drivers_weekly_report.summary")}</b>,
      //   dataIndex: "summary",
      //   render: (data, record) => <span className="textUpperCase">{data}</span>,
      //   width: 100,
      // },
      {
        key: 'actions',
        title: '',
        dataIndex: 'isNew',
        render: (data, record, index) => (
          props.isApproved ?
          null
          :
          <>
          {record.isNew ?
            <div className='line-actions'>
              <Tooltip placement="topLeft" title="Delete">
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm')}
                  onConfirm={() => props.deleteClick(index, '', record.isNew === true)}
                  okText="Yes"
                  cancelText="No"
                >

                  <DeleteIcon className="fontSize14"/>
                  &nbsp;&nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
            </div>
            :
            <div className='line-actions fontsize11'>

              {index + 1 === props.totalRecords &&
                <Tooltip placement="topLeft" title={'Add Deduction'}>
                  <Icon type="plus-circle" theme="filled" onClick={props.addRow} style={{ fontSize: 18 }} />
                </Tooltip>
              }
            </div>
      }
      </>
        ),
        width: 40,
        align: 'left',
      }
    ];
  }

  getAmount = (record, props) => {
    if(record.deduction_standard_code === 'CLAIM') {
      return checkNegitive(props.claimBalance)
    } else if(record.deduction_standard_code === 'LOAN') {
      return checkNegitive(props.loanBalance)
    } else if(record.deduction_standard_code === 'ADVANCE') {
      return checkNegitive(props.advanceBalance)
    }
    return "";
  }

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default React.memo(DriversWeeklyDeductionList);
