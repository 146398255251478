/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component, Fragment, useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Col,
  Drawer,
  Icon,
  Row,
  Spin,
  Search,
  Dropdown,
  Statistic,
  message,
  Modal,
  FormItem,
  Select,
  Option,
} from '../common/UIComponents'
import {
  alertMessage,
  formatDate,
  isEmpty,
  base64ToPdf,
} from '../common/Common'
import I18n from '../common/I18n'
import OrdersList from '../components/Tasks/OrdersList'
import {
  fetchOrders,
  deleteOrder,
  changeOrderStatus,
  changeStatusOfOrders,
  deleteMultipleOrder,
  fetchOrdersStatusByFilter,
  moveOrdersToNewFromPending,
  exportOrders,
} from '../api/OrdersApi'
import { fetchShortFormAccounts } from '../api/Account'
import { fetchLocations } from '../api/LocationsApi'
import BaseModal from '../components/BaseModal'
import OrderDetails from '../components/Tasks/OrderDetails'
import AppConfig from '../config/AppConfig'
import userStore from '../stores/UserStore'
import EditOrderForm from '../components/orders/EditOrderForm'
import ChangeStatusForm from '../components/orders/ChangeStatusForm'
import OrderFilter from '../components/orders/OrderFilter'
import OrderTitle from '../components/orders/OrderTitle'
import { getSortingKey } from '../helpers/orders'
import OrdersBulkOperations from '../components/orders/OrdersBulkOperations'
import BillOfRatingModal from './BillOfRatingModal'
import { checkServiceExistance, dynamicFileDownload, renderAlertMessage } from '../helpers/common'
import { fetchBolPdf } from '../api/BillingScreenAPI'
import AppliedFilters from '../components/orders/AppliedFilters'
import { OrgContext } from '../context/OrgContext';
import { WarehouseContext } from '../context/WarehouseContext';
import { DisplaySettingsContext } from '../context/DisplaySettings'
import { RecordsPerPage } from '../components/orders/RecordsPerPage'
import { BarCodeModal } from './commonModals';
import { ScreenKeys } from './constants';
import { UserContext } from '../context/UserContext';
import { fetchFilteredUser } from '../api/UsersApi'
import { validateEmailList } from '../components/billing_screen/helpers'
import { placeholder } from '@babel/types'

const defaultFilterValues = {
  search_order_token: '',
  // fromDate: moment(),
  // toDate: moment().add(6, 'day'),
  currentStatus: 'PENDING',
  sortBy: 'none',
  sortByType: 'descend',
  account_codes: [],
  warehouse_id: '',
  // search_key_type: "",
  // search_key_value: "",
  [I18n.t('order.filters.schedule_orders.type')]: I18n.t(
    'order.filters.schedule_orders.both_key',
  ),
  zone_ids: [],
  users: [],
  created_by_ids: [],
  // search_type: 'EQUALS',
}
// const sessionOrgId = userStore.getStateValue('selectedOrg')
class PendingOrdersComponent extends Component {
  // static contextType = OrganizationSettingsContext
  constructor(props) {
    super(props)
    const ordersToken =
      props.location && props.location?.filterPlaceHolder
        ? props.location.filterPlaceHolder.search_order_token
        : ''
    const currentStatus = !isEmpty(ordersToken)
      ? ''
      : !isEmpty(props.location.filter) || props.location.filter === ''
      ? props.location.filter
      : 'PENDING'
    const filterHolder =
      props.location && props.location.filterPlaceHolder
        ? props.location.filterPlaceHolder
        : Object.assign({}, defaultFilterValues, {
            currentStatus,
            search_order_token: ordersToken,
          })
    this.state = {
      options: [
        { value: 'All', label: 'All' },
        { value: 'PDF', label: 'PDF' },
        { value: 'EDI', label: 'EDI' },
      ],
      selectedSource: 'All',
      orderInfo: [],
      currentOrder: {},
      orderObject: {},
      inProgress: false,
      status: {
        All: '',
        Pending: 'PENDING',
        // Approved: "APPROVED",
        Rejected: 'REJECTED',
      },
      filterHolder,
      filterPlaceHolder: { ...filterHolder },
      search_order_token: ordersToken,
      detailsVisible: false,
      routes: {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      },
      editForm: false,
      filterWindow: false,
      statsData: {},
      pagination: {},
      statusChangeModal: false,
      currentStatus: '',
      accounts: [],
      // currentAccount: {},
      currentWarehouse: {},
      showStatusErrors: false,
      warehouses: [],
      processOrders: [],
      hasBulkOrders: false,
      displayConfiguration: {},
      organizationSettings: {},
      isMilitaryTime: false,
      //searchKeyPressed: false,
      showBillOfRatingModal: false,
      recordsPerPage: props.recordsPerPage,
      showBarCodeModal: false,
      users: [],
      isExporting: false,
      isModalVisible: false,
      billingEmails: [],
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
    this.onTableChange = this.onTableChange.bind(this)
    // debounce
    this.getOrders = _.debounce(this.getOrders, 500)
  }

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1
        this.getOrders();
        updateRecordsPerPage(ScreenKeys.PENDING_ORDER_LISTING, value);
      }
    );
  };

  getFilteredUsers = () => {
    fetchFilteredUser()
      .then((result) => {
        if (result.success) {
          this.setState({users: result.users || []});
        } else {
          renderAlertMessage(result.errors)
          this.setState({ users : [] });
      }
    });
  };

  onSearch = (value) => {
  };

  componentDidMount() {
    const propsLocationData = this.props.location
    this.getOrders(
      propsLocationData.filter ? propsLocationData.filter : this.state.filter,
    )

    if (
      !isEmpty(propsLocationData.showQuoteOrder) &&
      propsLocationData.showQuoteOrder === true
    ) {
      this.showQuoteOrder()
    }
    this.getAccounts()
    this.getFilteredUsers()
    // this.getWarehouses()

    // this.getOrdersStatus();
    // this.getUsers();
    this.setState({
      organizationSettings: this.props.organizationSettings,
      displayConfiguration: this.props.displayConfiguration,
      // warehouses: this.props.warehouseFilter.warehouses,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == 'true',
    } 
    // this.setWarehouse()
    )
  }

  handleBarCodePrint = (visible) => {
    this.setState({ showBarCodeModal: visible });
  }; 

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.displayConfiguration,
        this.props.displayConfiguration
      )
    )
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings,
      )
    ) {
      this.setState({
        displayConfiguration: this.props.displayConfiguration,
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == 'true',
      },() => {
        // Update OrdersList props with updated state values
        this.updateOrdersListProps();
      })
    }

    // check for warehouse filter change
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) || !_.isEqual(prevProps.warehouseFilter.warehouses , this.props.warehouseFilter.warehouses) && (!this.props.warehouseFilter.isFetchingWarehouses)) {
      this.tableOptions.pagination.current = 1
      this.getOrders()
    }
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts();
    } else if (
      !_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) ||
      (!_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    }
  }

  updateOrdersListProps = () => {
    this.setState({
      ordersListProps: {
        displayConfiguration: this.state.displayConfiguration,
      },
    });
  };

  showQuoteOrder = () => {
    const { orderObject } = this.props.location
    if (!isEmpty(orderObject)) {
      this.setState(
        {
          orderObject,
        },
        () => {
          this.setState({ editForm: true, isNew: false })
        },
      )
    }
  }

  getAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState({
      accounts: accountsData,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.filterPlaceHolder &&
      !isEmpty(nextProps.searchType) &&
      (nextProps.searchType !== this.props.searchType ||
        !_.isEqual(nextProps.filterPlaceHolder, this.props.filterPlaceHolder))
    ) {
      // && !_.isEqual(nextProps.filterPlaceHolder, this.props.filterPlaceHolder
      if (!isEmpty(nextProps.filterPlaceHolder)) {
        const filterHolder = nextProps.filterPlaceHolder
          ? nextProps.filterPlaceHolder
          : defaultFilterValues
        const currentStatus =
          nextProps.searchType === 'byOrders'
            ? filterHolder.search_order_token === ''
              ? 'PENDING'
              : ''
            : this.state.filterHolder.currentStatus
        this.setState(
          {
            filterHolder: Object.assign({}, filterHolder, {
              currentStatus,
            }),
          },
          () => {
            if (nextProps.searchType === 'byOrders') {
              this.handleOrderNumberSearch()
            } else if (nextProps.searchType === 'byFilter') {
              this.handleSearch()
            }
          },
        )
      }
    }
  }

  onOk = (value) => {
    this.getOrders()
  }

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1
    this.tableOptions = { pagination, filters, sorter }
    if (pagination.current !== currentPage) {
      this.getOrders()
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1
      this.handleOnFilterChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : 'ascend',
      })
    }
  }

  // getOrdersStatus = () => {
  //   const { filterHolder } = this.state
  //   this.setState({ inProgress: true })
  //   const order_number = !isEmpty(filterHolder.search_order_token)
  //     ? filterHolder.search_order_token.toUpperCase()
  //     : ''
  //   const formattedFromDate = formatDate(filterHolder.fromDate)
  //   const formattedToDate = formatDate(filterHolder.toDate)
  //   const searchKeyType = filterHolder.search_key_type
  //   const searchKeyValue = filterHolder.search_key_value
  //   const scheduleOrdersType = filterHolder.schedule_orders_type
  //   const zoneIds = filterHolder.zone_ids || []
  //   fetchOrdersStatusByFilter(
  //     formattedFromDate,
  //     formattedToDate,
  //     order_number,
  //     filterHolder.account_codes,
  //     filterHolder.warehouse_id,
  //     searchKeyType,
  //     searchKeyValue,
  //     scheduleOrdersType,
  //     true,
  //     zoneIds,
  //   ).then((result) => {
  //     if (result.success) {
  //       this.setState({
  //         statsData:
  //           result.data && result.data.length > 0 ? result.data[0] : {},
  //       })
  //     } else {
  //       renderAlertMessage(result.errors)
  //     }
  //   })
  // }

  navigateToRoute = (routeId, routeStatus, date) => {
    // this.props.history.push(`/stops/${routeId}`);
    if (routeStatus !== 'ALLOCATED') {
      this.props.history.push({
        pathname: '/routes',
        filter: routeStatus,
        routeId,
        dateFilter: {
          fromDate: date,
          toDate: date,
        },
      })
    }
  }

  navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(this.state?.accounts, [
      'code',
      account_code,
    ])
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, this.state?.accounts[accountIndex])
      const account_id = !isEmpty(accountData.primary_account_id)
        ? accountData.primary_account_id
        : accountData.id
      this.props.history.push(`/accounts/${account_id}/settings`)
    }
  }

  getOrders = (initial, cb = null) => {
    const { filterHolder,filterPlaceHolder } = this.state
    this.setState({ inProgress: true })
    const { currentStatus } = filterHolder
    const page = this.tableOptions.pagination.current
    const perPage = initial ? initial.perPage : this.state.recordsPerPage
    const orderNumber = !isEmpty(filterHolder.search_order_token)
      ? filterHolder.search_order_token.toUpperCase()
      : ''
    const formattedFromDate = formatDate(filterHolder.fromDate)
    const formattedToDate = formatDate(filterHolder.toDate)
    const searchKeyType = filterHolder.search_key_type
    const searchKeyValue = filterHolder.search_key_value
    const scheduleOrdersType = filterHolder.schedule_orders_type
    // this.getOrdersStatus()
    fetchOrders({
      search_type: filterPlaceHolder.search_type || 'EQUALS',
      status: currentStatus,
      source: this.state.selectedSource,
      fromDate: "",
      todate: "",
      page,
      perPage,
      orderNumber,
      sortBy: filterHolder.sortBy,
      sortByType: filterHolder.sortByType || 'ascend',
      account_codes: filterHolder.account_codes,
      warehouseId: filterHolder.warehouse_id,
      searchKeyType,
      searchKeyValue,
      scheduleOrdersType,
      globalSearch: true,
      zone_ids: filterHolder.zone_ids,
      order_type: filterHolder.order_type,
    }).then((result) => {
      if (result.success) {
        // const filter = this.state.filter;
        const total_orders = result.orders.customer_orders
        const orders = total_orders.map((x) =>
          Object.assign({}, x.order, {
            driver_name: x.driver_name,
            appointments: x.order_appointments || [],
            nav_route_id: x.nav_route_id,
            nav_route_name: x.nav_route_name,
            nav_route_status: x.nav_route_status,
            scheduled_start_datetime_with_tz:
              x.scheduled_start_datetime_with_tz,
            scheduled_end_datetime_with_tz: x.scheduled_end_datetime_with_tz,
            timeZoneName: x.timeZoneName,
            tz_short_form: x.tz_short_form,
            company_name:
              x.cs_location && !isEmpty(x.cs_location.company_name)
                ? x.cs_location.company_name
                : '',
            customer_address:
              x.cs_location && x.cs_location.l_address
                ? {
                    ...x.cs_location.l_address,
                    location_code: x.cs_location.l_address.city,
                  }
                : {},
            warehouse_address:
              x.wh_location && x.wh_location.l_address
                ? {
                    ...x.wh_location.l_address,
                    location_code: x.wh_location.location_code,
                  }
                : {},
            zoneName:
              !isEmpty(x.order.order_zone_name) &&
              (_.isArray(x.order.order_zone_name)
                ? x.order.order_zone_name.join(', ')
                : ''),
            startTime: getSortingKey(x.order_appointments),
            wh_timezone: x.wh_location ? x.wh_location.timeZoneId : 'EST',
            origin : x.origin && !isEmpty(x.origin.l_address) ? {
              ...x.origin.l_address,
              status: x.origin.status,
              level_of_service: x.origin.level_of_service ? x.origin.level_of_service : "None",
              company_name: x.origin.company_name,
            } : {},
            destination : x.destination && !isEmpty(x.destination.l_address) ? {
              ...x.destination.l_address,
              status: x.destination.status,
              level_of_service: x.destination.level_of_service ? x.destination.level_of_service : "None",
              company_name: x.destination.company_name,
            } : {},
          }),
        )
        // if (order_number && orders.length > 0) {
        //   filter = orders[0].status;
        // }
        this.setState(
          {
            orderInfo: orders,
            inProgress: false,
            statsProgress: false,
            statsData: result.stats || {},
            pagination: orders.length > 0 ? result.pagination : {},
            filterHolder: Object.assign({}, this.state.filterHolder, {
              currentStatus,
            }),
          },
          () => {
            if (cb) {
              cb()
            }
          },
        )
      } else {
        // alertMessage(result.errors[0], 'error', 5);
        this.setState({
          orderInfo: [],
          inProgress: false,
          pagination: {},
          statsData: result.stats || {},
        })
      }
    })
  }

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb,
    )
  }

  applyFilterPlaceHolder = (filter, cb = null) => {
    let placeHolder = {};
    if(!isEmpty(filter)) {
      placeHolder = {...filter}
    }else {
      placeHolder = {...this.state.filterPlaceHolder}
    }
    
    this.setState(
      {
        filterHolder: placeHolder,
      },
      cb,
    )
  }

  handleCurrentOrderChange = (element, value) => {
    this.setState({
      currentOrder: Object.assign({}, this.state.currentOrder, {
        [element]: value,
      }),
    })
  }

  handleSaveSuccess = (message, reflectDetails = false, id = '') => {
    alertMessage(message)
    if (!reflectDetails) {
      this.setState({ editForm: false }, () => {
        this.getOrders()
      })
    } else {
      this.handleEditOrderClick(id)
    }
  }

  handleOnFilterChange = (element, value) => {
    if (element === 'sortBy') {
      const filter = Object.assign({},this.state.filterHolder,{
          sortBy: value.sortKey,
          sortByType: value.sortKeyType || "ascend",
        });
          this.setState({filterHolder: filter},
            () => {
              this.tableOptions.pagination.current = 1;
              this.getOrders();
            }
          );
        
    } else {
      this.setState(
        {
          filterHolder: Object.assign({}, this.state.filterHolder, {
            [element]: value,
          }), // { [element]: value, search_order_token: '' }
          routes: Object.assign({}, this.state.routes, {
            selectedOrderRows: [],
            selectedOrderKeys: [],
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getOrders();
        }
      );
    }
    
  }

  handleOnOrderTokenClose = (orderNo) => {
    const orderToken = this.state.filterHolder.search_order_token
    if (!isEmpty(orderToken)) {
      const searchOrderToken = orderToken.replaceAll(' ', '');
      let tokenArray = _.uniq(_.split(searchOrderToken, ','));
      const count = tokenArray.length;
      const trimmedToken = _.trim(orderNo);
      const orderIndex = _.indexOf(tokenArray, trimmedToken)
      if (orderIndex >= 0) {
        const otherTokens = tokenArray.filter(token =>  token !== trimmedToken)
        const newOrderToken = _.join(otherTokens, ',')
        const filter = Object.assign({}, this.state.filterHolder, {
          search_order_token: newOrderToken,
        })
        this.applyFilterPlaceHolder(filter);
        this.updateFilter(filter, () => {}, true);
        //this.handleChange('search_order_token', newOrderToken)
        if (count === 1 && orderIndex == 0) {
          this.clearFilter()
        }
      }
    }
  }

  // handleOnTokenClose = (element, value) => {
  //   if (!isEmpty(element)) {
  //     const filter = Object.assign({}, this.state.filterHolder, {
  //       [element]: value,
  //     })
  //     this.applyFilterPlaceHolder(filter);
  //     this.updateFilter(filter, () => {});
  //   }
  // }

  handleOrderDetailsViewClick = (id) => {
    const index = _.findIndex(this.state.orderInfo, ['id', id])
    const orderObject = Object.assign({}, this.state.orderInfo[index])
    this.setState(
      {
        // result.orders.customer_order
        orderObject,
      },
      () => {
        this.setState({ detailsVisible: true })
      },
    )
  }

  handleEditOrderClick = (id) => {
    const index = _.findIndex(this.state.orderInfo, ['id', id])
    const orderObject = Object.assign({}, this.state.orderInfo[index])
    this.setState(
      {
        orderObject,
      },
      () => {
        this.setState({ editForm: true, isNew: false })
      },
    )
  }

  handleAddOrder = () => {
    this.setState(
      {
        currentOrder: {},
        orderObject: {},
      },
      () => {
        this.setState({ editForm: true, isNew: true })
      },
    )
  }
  handleSourceChange = (value) => {
    this.setState({ selectedSource: value });
    const filter = Object.assign({}, this.state.filterHolder)
    filter.selectedSource = value
    this.applyFilterPlaceHolder(filter)
    this.getOrders();
  };
  handleExceptionOrder = (id) => {
    this.setState({
      inProgress: true,
    })
    changeOrderStatus(id).then((result) => {
      if (result.success) {
        alertMessage('Moved Successfully')
        this.setState(
          {
            inProgress: false,
          },
          () => {
            this.getOrders()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          inProgress: false,
        })
      }
    })
  }

  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    })
  }

  showFilterWindow = () => {
    this.setState({
      filterWindow: true,
    })
  }

  onClose = () => {
    this.setState({
      detailsVisible: false,
    })
  }

  editFormDetails = (id) => {
    this.onClose()
    this.handleEditOrderClick(id)
  }

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedOrderKeys: selectedRowKeys,
        selectedOrderRows: selectedRows,
      }),
    })
  }

  handleEditOrder = (id) => {
    const orderIndex = _.findIndex(this.state.orderInfo, ['id', id])
    const currentOrder = Object.assign({}, this.state.orderInfo[orderIndex])
    this.setState(
      {
        currentOrder,
      },
      () => {
        this.setState({ editForm: true })
      },
    )
  }

  closeEditFormModal = () => {
    this.setState({ editForm: false }, () => {
      this.getOrders()
    })
  }

  closeFilterWindow = () => {
    this.setState({ filterWindow: false })
  }

  handleSearch = () => {
    // let currentAccount = {}
    // const accountIndex = _.findIndex(this.state.accounts, {
    //   code: this.state.filterPlaceHolder.accountCode,
    // })
    // if (accountIndex >= 0) {
    //   currentAccount = this.state.accounts[accountIndex]
    // }
    // let currentWarehouse = {}
    // const whIndex = _.findIndex(this.state.warehouses, {
    //   id: this.state.filterPlaceHolder.warehouse_id,
    // })
    // if (whIndex >= 0) {
    //   currentWarehouse = this.state.warehouses[whIndex]
    // }
    const filter = Object.assign({}, this.state.filterPlaceHolder)
    filter.search_order_token = ''
    this.setState(
      {
        // vin filter: this.state.filterHolder.filter,
        filterHolder: { ...this.state.filterHolder, ...filter },
        filterPlaceHolder: { ...filter },
        // currentAccount,
        // currentWarehouse,
        //searchKeyPressed: false,
      },
      () => {
        this.tableOptions.pagination.current = 1
        this.getOrders()
        this.closeFilterWindow()
      },
    )
  }

  handleOrderNumberSearch = (value) => {
    this.setState(
      {
        // currentAccount: {},
        currentWarehouse: {},
        //searchKeyPressed: !isEmpty(value) ? true : false,
        filterHolder: {
          ...defaultFilterValues,
          search_order_token: value,
        },
      },
      () => {
        this.tableOptions.pagination.current = 1
        this.getOrders()
        this.closeFilterWindow()
      },
    )
  }

  handleCancel = () => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      }),
    })
  }

  handleDeleteOrder = (id) => {
    this.setState({ inProgress: true })
    deleteOrder(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.getOrders()
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  multipleOrderDeletion = () => {
    this.setState({
      inProgress: true,
    })
    const id = this.state.routes.selectedOrderKeys
    deleteMultipleOrder(id).then((result) => {
      if (result.success) {
        this.setState({
          routes: {
            selectedOrderKeys: [],
            selectedOrderRows: [],
          },
        })
        alertMessage(I18n.t('messages.deleted'))
        this.getOrders()
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  resetOrders = () => {
    this.setState(
      {
        routes: {
          selectedOrderKeys: [],
          selectedOrderRows: [],
        },
      },
      () => {
        this.getOrders()
      },
    )
  }

  handleStatusChangeResponse = (result, orderIds) => {
    const { orderInfo, pagination = {} } = this.state
    if (result.success) {
      alertMessage(I18n.t('messages.processed'), 10)
      this.setState(
        {
          loading: false,
          routes: {
            selectedOrderKeys: [],
            selectedOrderRows: [],
          },
        },
        () => {
          if (
            pagination &&
            orderInfo.length === orderIds.length &&
            isEmpty(pagination.next_page) &&
            pagination.current_page !== 1
          ) {
            this.tableOptions.pagination.current = pagination.current_page - 1
          }
          this.getOrders()
        },
      )
    } else if (result.errors) {
      if (result.errors.length > 0) {
        const errorValue = result.errors[0]
        if (_.isString(errorValue)) {
          this.setState({
            loading: false,
          })
          alertMessage(result.errors, 'error', 10)
        } else if (_.isObject(errorValue)) {
          const routes = {
            selectedOrderKeys: [],
            selectedOrderRows: [],
          }
          const statusErrors = []
          result.errors.forEach((errObj) => {
            if (_.isObject(errObj) && errObj.order_number) {
              const errorOrder = _.find(this.state.orderInfo, {
                customer_order_number: errObj.order_number,
              })
              if (!isEmpty(errorOrder)) {
                routes.selectedOrderKeys.push(errorOrder.id)
                routes.selectedOrderRows.push(errorOrder)
                statusErrors.push(errObj)
              }
            }
          })
          this.setState({
            routes,
            loading: false,
            statusErrors,
            showStatusErrors: true,
          })
          // alertMessage('One or more required fields are missing', 'error', 10);
        }
      }
      this.getOrders()
    }
  }

  handleStatusChangeApiCalls = (func, orderIds) => {
    func().then((result) => {
      this.handleStatusChangeResponse(result, orderIds)
    })
  }
  handleMultipleOrdersStatusChange = (status, reasonMessage = '') => {
    this.setState({
      loading: true,
    })
    const orderIds = this.state.routes.selectedOrderKeys || []

    if (status === 'PENDINGTONEW') {
      const data = {
        order_ids: orderIds,
        status: 'APPROVED',
        rejection_reason: 'Approved',
      }
      this.handleStatusChangeApiCalls(
        () => moveOrdersToNewFromPending(data),
        orderIds,
      )
    } else if (status === 'PENDINGTOREJECT') {
      const data = {
        order_ids: orderIds,
        status: 'REJECTED',
        rejection_reason: 'Rejected',
      }
      this.handleStatusChangeApiCalls(
        () => moveOrdersToNewFromPending(data),
        orderIds,
      )
    } else {
      const data = { order_ids: orderIds, status }
      this.handleStatusChangeApiCalls(
        () => changeStatusOfOrders(data),
        orderIds,
      )
    }
  }

  handleSingleOrderStatusChangeCall = (id) => {
    const orderIndex = _.findIndex(this.state.orderInfo, ['id', id])
    const orderObject = Object.assign({}, this.state.orderInfo[orderIndex])
    if (orderIndex >= 0) {
      this.setState({
        statusChangeModal: true,
        processOrders: [orderObject],
        hasBulkOrders: false,
        // vin currentStatus: this.state.filterHolder.filter,
      })
    }
  }
  handleBulkOrderStatusChangeCall = () => {
    this.setState({
      // vin currentStatus: this.state.filterHolder.filter,
      statusChangeModal: true,
      processOrders: [...this.state.routes.selectedOrderRows],
      hasBulkOrders: true,
    })
  }

  closeStatusChangeModal = () => {
    this.setState({
      statusChangeModal: false,
      currentStatus: '',
      processOrders: [],
      hasBulkOrders: false,
    })
  }

  renderStatusChangeModal = () => (
    <BaseModal
      title={`Change Status`}
      onCancel={() => this.closeStatusChangeModal()}
    >
      <ChangeStatusForm
        currentStatus={this.state.filterHolder.currentStatus}
        saveStatus={this.callbackOfStatusChange}
        orderObject={this.state.orderObject}
        closeModal={this.closeStatusChangeModal}
        orders={this.state.processOrders}
        processErrors={this.processErrors}
        hasBulkOrders={this.state.hasBulkOrders}
        isPending={true}
      />
    </BaseModal>
  )

  handleSurveyDetails = (id) => {
    this.setState({
      showSurvey: true,
      surveyResponseId: id,
    })
  }

  closeSurveyDetails = () => {
    this.setState({
      showSurvey: false,
    })
  }

  callbackOfStatusChange = () => {
    this.setState(
      {
        loading: false,
        routes: {
          selectedOrderKeys: [],
          selectedOrderRows: [],
        },
        orderObject: {},
        currentStatus: '',
        statusChangeModal: false,
      },
      () => {
        this.getOrders()
      },
    )
  }

  processErrors = (errors) => {
    const routes = {
      selectedOrderKeys: [],
      selectedOrderRows: [],
    }
    if (errors?.length > 0) {
      errors.forEach((order) => {
        const errorOrder = _.find(this.state.orderInfo, {
          customer_order_number: order,
        })
        if (!isEmpty(errorOrder)) {
          routes.selectedOrderKeys.push(errorOrder.id)
          routes.selectedOrderRows.push(errorOrder)
        }
      })
    }
    this.setState(
      {
        routes,
      },
      () => {
        this.getOrders()
      },
    )
  }

  handleChange = (element, value, cb = null) => {
    this.setState({ [element]: value }, () => {
      if (cb) {
        cb()
      }
    })
  }

  navigateToPreplan = () => {
    this.props.history.push('/preplan')
  }

  // renderPreplanModal = () => (
  //   <BaseModal
  //     title={`${I18n.t("preplan.create")}`}
  //     onCancel={() => this.closePreplanModal()}
  //     width="80%"
  //     style={{ top: "30" }}
  //     maskClosable={false}
  //   >
  //     <PreplanForm />
  //   </BaseModal>
  // );
  // closePreplanModal = () => {
  //   this.setState({ showPreplan: false });
  // };
  // showPreplanModal = () => {
  //   this.setState({
  //     showPreplan: true,
  //   });
  // };

  printBol = () => {
    this.setState({
      isGeneratingPdf: true,
    })
    fetchBolPdf(this.state.currentOrder.id)
      .then((result) => {
        if (result.success) {
          base64ToPdf(
            result.bol_file,
            `OrderBol${this.state.currentOrder.id}.pdf`,
          )
        } else {
        }
      })
      .finally(() => {
        this.setState({
          isGeneratingPdf: false,
        })
      })
  }

  setRef = (ref) => (this.componentRef = ref)

  clearFilter = () => {
    const filter = {...defaultFilterValues}
    this.applyFilterPlaceHolder(filter);
    this.updateFilter(filter, () => {})
    this.setState({ selectedSource: "All" })
  }

  updateFilter = (filter, cb, ) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (cb) {
        this.tableOptions.pagination.current = 1
        this.getOrders()
      }
    })
  }

  handleOnFilterElementChange = (element, value, isSearch = false, cb) => {
    if (element === 'dateFilter') {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        fromDate: value.fromDate,
        toDate: value.toDate,
      })
      if(isSearch){
        this.applyFilterPlaceHolder(filter)
      }
      this.updateFilter(filter, isSearch === true);
    } else if (element === 'search_key_type') {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        search_key_type: value.search_key_type,
        search_key_value: value.search_key_type,
      })
      if(isSearch){
        this.applyFilterPlaceHolder(filter)
      }
      this.updateFilter(filter, isSearch === true)
    } else if (element === 'sortBy') {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || 'ascend',
      })
      if(isSearch){
        this.applyFilterPlaceHolder(filter)
      }
      this.updateFilter(filter, isSearch === true)
    } else if (element === 'search_order_token') {
      let filter = {}
      if (!isSearch) {
        filter = Object.assign({}, this.state.filterPlaceHolder, {
          [element]: value,
        })
      } else {
        filter = Object.assign({}, defaultFilterValues)
      }
      this.updateFilter(filter, cb)
    } else if (element === 'account_codes') {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        account_codes: value.account_codes,
      })
      if (isSearch) {
        this.applyFilterPlaceHolder(filter)
      }
      this.updateFilter(filter, isSearch === true)
    }
    else if (element === 'selectedSource') {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        selectedSource: value,
      })
      this.setState({ selectedSource: "All" })
      if (isSearch) {
        this.applyFilterPlaceHolder(filter)
      }
      this.updateFilter(filter, isSearch === true)
    } 
    else {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        [element]: value,
      })
      this.updateFilter(filter, cb)
    }
  }

  closeStatusErrors = () => {
    this.setState({
      statusErrors: [],
      showStatusErrors: false,
    })
  }

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    })
  }

  renderStatusErrors = () => (
    <BaseModal
      title={I18n.t('errors.errors')}
      placement="right"
      closable
      onCancel={this.closeStatusErrors}
      visible={this.state.showStatusErrors}
      width="50%"
      style={{ top: 35 }}
    >
      {this.state.statusErrors.map((err) => (
        <Row>
          <Col xs={6} className="textBold">
            {err.order_number}
          </Col>
          <Col xs={18}>{_.isArray(err.errors) ? err.errors.join(',') : ''}</Col>
        </Row>
      ))}
    </BaseModal>
  )
  handleBillOfRating = (orderId, orderNumber) => {
    this.setState({
      currentOrder: { id: orderId, currentOrderNumber: orderNumber },
      showBillOfRatingModal: true,
    })
  }

  _renderSearchBar = () => {
    const { filterPlaceHolder } = this.state
    return (
      <Search
        placeholder={I18n.t("order.filters.placeholder")}
        // enterButton="Search"
        size="default"
        value={
          filterPlaceHolder?.search_order_token &&
          !isEmpty(filterPlaceHolder.search_order_token)
            ? filterPlaceHolder.search_order_token.toUpperCase()
            : ''
        }
        onChange={(e) => {
          this.handleFilterPlaceHolderChange({
            ...filterPlaceHolder,
            search_order_token: e.target.value,
          })
        }}
        onSearch={(value) => this.handleOrderNumberSearch(value)}
        style={{ textTransform: 'uppercase', paddingLeft: '5px', width: 550 }}
        suffix={
          <Fragment>
            {filterPlaceHolder.search_order_token !== '' && (
              <Fragment>
                {' '}
                <Icon
                  type="close"
                  onClick={() => {
                    this.handleOnFilterElementChange('search_order_token', '', true)
                    this.handleOrderNumberSearch('')
                  }}
                />{' '}
                &nbsp;&nbsp;
              </Fragment>
            )}
            <Dropdown
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.filterWindow}
              trigger={['click']}
              overlay={
                <div
                  style={{
                    backgroundColor: 'white',
                    width: 550,
                    padding: 15,
                    marginTop: 10,
                    marginRight: -12,
                  }}
                  className="boxShadow"
                >
                  <OrderFilter
                    filter={this.state.filterPlaceHolder}
                    onChange={this.handleFilterPlaceHolderChange}
                    onSearch={this.handleSearch}
                    onOrderNoSearch={this.handleOrderNumberSearch}
                    showOrderNoSearch={false}
                    isPending={true}
                    showScheduleOption={true}
                    showDateFilter={false}
                    warehouseFilter={this.props.warehouseFilter}
                  />
                </div>
              }
            >
              <Icon
                type="control"
                onClick={() => this.setState({ filterWindow: true })}
              />
            </Dropdown>
          </Fragment>
        }
        prefix={
          <Icon
            type="search"
            onClick={() =>
              this.handleOrderNumberSearch(
                this.state.filterPlaceHolder.search_order_token,
              )
            }
          />
        }
        className="textUpperCase"
      />
    )
  }

  enableLoading = (loadingStatus) => {
    this.setState({
      inProgress: loadingStatus,
    })
  }

  handleBillOfRating = (orderId, orderNumber) => {
    this.setState({
      currentOrder: { id: orderId, currentOrderNumber: orderNumber },
      showBillOfRatingModal: true,
    })
  }

  closeBillOfRatingModel = () => {
    this.setState({
      showBillOfRatingModal: false,
    })
  }

exportPendingOrder = () => {
    const { selectedWarehouses } = this.props.warehouseFilter;
    const currentOrg = this.props.userContext.currentOrg;
    const { currentRole } = this.props.userContext;
    const { filterPlaceHolder, filterHolder } = this.state;
    const organization_id = currentOrg.id;
    const selectedwh = _.isArray(selectedWarehouses) && selectedWarehouses.length > 0 ? selectedWarehouses[0] : selectedWarehouses;
    const emailIds = this.state.billingEmails || [];
    const selectedOrderKeys = this.state.routes.selectedOrderKeys;
    const payload = {
      accountCode: filterPlaceHolder.account_codes || "",
      zone_ids: filterPlaceHolder.zone_ids || [],
      orders_count: this.state.pagination.total_count || "",
      scheduleOrdersType: filterPlaceHolder.schedule_orders_type,
      vehicle_type: filterPlaceHolder.vehicle_type || "",
      order_type: filterPlaceHolder.order_type || "",
      status: filterHolder.currentStatus,
      sortByType: filterHolder.sortByType || "ascend"
    };
    if(this.state.isModalVisible && emailIds?.length === 0){
      return alertMessage("please provide Email(s)..", "error", 5)
    }
    this.setState({inProgress: true})
    let url = "";
    // if (selectedOrderKeys.length > 0) {
    //   url = `${AppConfig.baseUrl}/api/v2/export_pending_orders/pending_orders_export?customer_order_ids=${selectedOrderKeys}&warehouse_ids=${selectedwh}`;
    // } else {
    //   url = `${AppConfig.baseUrl}/api/v2/export_pending_orders/pending_orders_export?status=${payload.status}&account_code=${payload.accountCode}&schedule_orders_type=${payload.scheduleOrdersType}&zone_ids=${payload.zone_ids}&order_type=${payload.order_type}&vehicle_type=${payload.vehicle_type}&order_count=${payload.orders_count}&current_role=${currentRole}`;
    //   this.toggleEmailModal(false);
    //   if (!_.isNil(selectedWarehouses)) {
    //     url += `&warehouse_ids=${selectedwh}`;
    //   }
    // }
    url = `${
      AppConfig.baseUrl
    }/api/v2/export_pending_orders/pending_orders_export?status=${
      payload.status
    }&account_code=${payload.accountCode}&schedule_orders_type=${
      payload.scheduleOrdersType
    }&zone_ids=${payload.zone_ids}&order_type=${
      payload.order_type
    }&vehicle_type=${payload.vehicle_type}&order_count=${
      payload.orders_count
    }&current_role=${currentRole}&sort_by=${
      filterHolder.sortBy
    }&sort_order=${I18n.t(`general.sort_${payload.sortByType}`)}`;
    if (!_.isNil(selectedWarehouses)) {
      url += `&warehouse_ids=${selectedwh}`;
    }
    url += `&org_id=${organization_id}&response_type=csv`;

    if(emailIds.length === 0 ){
      this.setState({ isExporting: true });
      dynamicFileDownload(url, {}, null, "get").then((response) => {
        this.setState({ isExporting: false, inProgress: false });
      });
    } else {
      url=`${url}&emails=${emailIds}`;
      exportOrders(url).then((result) => {
        if (result.success) {
          alertMessage((result.message || "File will be emailed to your inbox"),"success",5)
          this.setState({ inProgress: false }, () =>
            this.toggleEmailModal(false)
          );
        } else {
          renderAlertMessage(result.errors);
          this.setState({ inProgress: false });
        }
      });
    }
  };


  toggleEmailModal = (value) => {
    this.setState({
      isModalVisible: value,
      billingEmails:[],
    })
  };

  addBilligEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ billingEmails: validEmails });
  };

  renderEmailModal = () => {
    const { userContext } = this.props;
    return (
      <Modal
        title="Export Orders"
        visible={this.state.isModalVisible}
        onCancel={() => this.toggleEmailModal(false)}
        maskClosable={false}
        closable={!this.state.inProgress}
        footer={[
          <Button
            key="cancel"
            onClick={() => this.toggleEmailModal(false)}
            disabled={this.state.inProgress}
          >
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={this.exportPendingOrder}
            disabled={this.state.inProgress}
          >
            OK
          </Button>,
        ]}
      >
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            <Col span={24}>
              <h4 style={{ fontWeight: "bold", fontSize: "13px" }}>
                {I18n.t("general.export_orders")}
              </h4>
            </Col>

            <Col span={24}>
              <FormItem
                label={I18n.t("general.emails")}
                className="tagsInput emailForm"
              >
                <Select
                  value={this.state.billingEmails}
                  onChange={this.addBilligEmail}
                  mode="tags"
                  maxTagCount={1}
                  validationRegex={this.addBilligEmail}
                  tokenSeparators={[","]}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Enter or select Emails"
                >
                  {userContext?.currentUser?.email ? (
                    <Option key={userContext.currentUser.email}>
                      {userContext.currentUser.email}
                    </Option>
                  ) : (
                    ""
                  )}
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Spin>
      </Modal>
    );
  }

  handleExportClick = () => {
    const { pagination, routes } = this.state;
    const { selectedOrderKeys } = routes;

    const hasSelectedOrderKeys = selectedOrderKeys && selectedOrderKeys.length > 0 && selectedOrderKeys.length < 100;
    const hasPagination = pagination && pagination.total_count > 0 && pagination.total_count < 100;

    if (hasSelectedOrderKeys || hasPagination) {
      this.exportPendingOrder();
    } else {
      this.toggleEmailModal(true);
    }
  }


  renderBillOfRatingModal = () => (
    <BaseModal
      title={`${I18n.t("BillOfRating.bill_of_rating_title")} : #${this.state.currentOrder.currentOrderNumber
      }`} 
      onCancel={this.closeBillOfRatingModel}
      width={'70%'}
      style={{ top: 50 }}
    >
      {checkServiceExistance('GOBP') && (
        <div
          className="alignCenter marginBottom10"
          style={{ cursor: 'pointer' }}
          onClick={this.printBol}
        >
          <Icon type="printer" /> Print
        </div>
      )}
      <BillOfRatingModal orderId={this.state.currentOrder.id} order={this.state.currentOrder} />
    </BaseModal>
  )

  render() {
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    const { currentStatus } = this.state.filterHolder
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    }
    const selectedRowKeys = this.state.routes.selectedOrderKeys
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: null,
    }
    const { isExporting } = this.state
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("menu.pending_orders")}</Col>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 10,
              }}
              className="alignRight headerOptionDiv"
            >
              {this._renderSearchBar()}
            </Col>
            {/* <Col xs={2} className="alignRight page-actions">
              <Button
                type="primary"
                onClick={() => this.handleAddOrder()}
                style={{ marginTop: "2px", marginLeft: 10 }}
              >
                {I18n.t("order.create")}
              </Button>
            </Col> */}
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row className="orderStats">
              <Col md={{ offset: 4, span: 16 }}>
                <Row className="orderStats">
                  <Col span="6" />
                  {AppConfig.pendingOrderStatus.map((record) => (
                    <Col
                      span={6}
                      key={record.key}
                      onClick={() =>
                        this.handleOnFilterChange("currentStatus", record.key)
                      } 
                      className="routeLegends"
                    >
                      <Statistic
                        title={record.label}
                        className={
                          this.state.filterHolder.currentStatus ===
                            record.key && "active"
                        }
                        value={_.get(this.state.statsData, record.key, 0)}
                        prefix={
                          <Icon
                            type={record.icon}
                            className={record.className}
                          />
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="marginTop marginBottom40">
                <Row type="flex" justify="space-between">
                  <Col>
                    <AppliedFilters
                      handleOnFilterElementChange={(element, value) =>
                        this.handleOnFilterElementChange(element, value, true)
                      }
                      filterPlaceHolder={this.state.filterHolder}
                      // currentAccount={this.state.currentAccount}
                      currentWarehouse={this.state.currentWarehouse}
                      filter={currentStatus}
                      handleOnOrderTokenClose={this.handleOnOrderTokenClose}
                      clearFilter={this.clearFilter}
                      handleOnFilterChange={this.handleOnFilterChange}
                      //isSearching={this.state.searchKeyPressed}
                      isPending={true}
                      accounts={this.state?.accounts}
                      warehouseFilter={this.props.warehouseFilter}
                      usersData={this.state.users || []}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="end">
                  {this.state.orderInfo.length > 0 && (
                    <Col>
                      <Button
                        size="small"
                        className="cursorPointer marginRight10"
                        type="primary"
                        loading={isExporting}
                        disabled={isExporting}
                        onClick={() =>
                          this.state.pagination?.total_count <= 100
                            ? this.exportPendingOrder()
                            : this.toggleEmailModal(true)
                        }
                        shape="round"
                      >
                        {isExporting ? (
                          "Exporting..."
                        ) : (
                          <span>
                            Export CSV <Icon type="file-text" theme="filled" />
                          </span>
                        )}
                      </Button>
                    </Col>
                  )}  
                  <Col
                    className="textBold"
                    style={{ marginRight: '8px' }}
                  >
                    Source
                  </Col>
                  <Col
                    style={{ marginRight: '8px' }}
                  >
                    <Select
                      size='small'
                      value={this.state.selectedSource}
                      style={{ width: 100 }}
                      onChange={this.handleSourceChange}
                    >
                      {this.state.options.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <RecordsPerPage
                      onChange={this.onRecordChange}
                      onSearch={this.onSearch}
                      value={this.state.recordsPerPage}
                      defaultValue={this.state.recordsPerPage}
                    />
                  </Col>
                </Row>
                <div style={{ zoom: "95%" }} className="marginTop10">
                  <OrdersList
                    organizationSettings={this.state.organizationSettings}
                    displayConfiguration={displayConfiguration}
                    ordersListProps={ordersListProps}
                    screen_from="pendingOrders"
                    data={this.state.orderInfo}
                    scroll={{ x: "max-content", y: "calc(100vh - 320.58px)" }}
                    generateInvoice={this.handleGenerateInvoice}
                    deleteClick={(id) => this.handleDeleteOrder(id)}
                    moveToPending={(id) => this.handleExceptionOrder(id)}
                    assignDriver={(id) => this.assignDriver(id)}
                    processToNextStep={(id, orderStatus) =>
                      this.setChangeStatusValues(id, orderStatus)
                    }
                    statusChange={(id) =>
                      this.handleSingleOrderStatusChangeCall(id)
                    }
                    orderDetailsViewClick={(id) =>
                      this.handleOrderDetailsViewClick(id)
                    }
                    navigateToRoute={(id, orderStatus, date) =>
                      this.navigateToRoute(id, orderStatus, date)
                    }
                    navigateToAccount={(account_code) =>
                      this.navigateToAccount(account_code)
                    }
                    handleEditOrder={(id) => this.handleEditOrderClick(id)}
                    handleSurveyDetails={this.handleSurveyDetails}
                    rowSelection={!isEmpty(currentStatus) ? rowSelection : null}
                    pagination={pagination}
                    tableChange={(pagination, filter, sorter, currentTable) =>
                      this.onTableChange(
                        pagination,
                        filter,
                        sorter,
                        currentTable
                      )
                    }
                    currentPage={pagination.current ? pagination.current : 1}
                    size="small"
                    currentFilter={currentStatus}
                    handleBillOfRating={this.handleBillOfRating}
                    resolvedAddressCallback={this.getOrders}
                    refreshCallback={this.getOrders}
                  />
                </div>
              </Col>
            </Row>

            {this.state.routes.selectedOrderKeys.length > 0 &&
              !isEmpty(currentStatus) && (
                <OrdersBulkOperations
                  filter={currentStatus}
                  routes={this.state.routes}
                  loading={this.state.loading}
                  multipleOrderDeletion={this.multipleOrderDeletion}
                  handleCancel={this.handleCancel}
                  resetOrders={this.resetOrders}
                  handleMultipleOrdersStatusChange={
                    this.handleMultipleOrdersStatusChange
                  }
                  handleReceiveToConfirm={() => {}}
                  changeStatus={this.handleBulkOrderStatusChangeCall}
                  handleStatusResult={this.handleStatusChangeResponse}
                  enableLoading={this.enableLoading}
                  handleEditOrder={(id) => this.handleEditOrderClick(id)}
                  handleBarCodePrint={this.handleBarCodePrint}
                />
              )}
          </Spin>
        </div>

        {this.state.detailsVisible && (
          <Drawer
            title={
              <OrderTitle
                order={this.state.orderObject}
                showEdit={true}
                editClick={() =>
                  this.editFormDetails(this.state.orderObject.id)
                }
              />
            }
            placement="right"
            closable
            onClose={this.onClose}
            visible={this.state.detailsVisible}
            width="85%"
          >
            <OrderDetails
              order_id={this.state.orderObject.id}
              surveyDetails={this.handleSurveyDetails}
              handleSearch={this.handleSearch}
            />
          </Drawer>
        )}

        {this.state.statusChangeModal && this.renderStatusChangeModal()}

        {this.state.editForm && (
          <Drawer
            placement="right"
            closable={false}
            maskClosable={false}
            onClose={this.closeEditFormModal}
            visible={this.state.editForm}
            width="90%"
          >
            <EditOrderForm
              customerDetails={this.state.orderObject}
              customerOrderId={this.state.orderObject.id}
              preferences={[]}
              onCancel={this.closeEditFormModal}
              handleSuccess={this.handleSaveSuccess}
              isNew={this.state.isNew}
            />
          </Drawer>
        )}

        {this.state.showStatusErrors && this.renderStatusErrors()}
        {this.state.showBillOfRatingModal && this.renderBillOfRatingModal()}
        {this.state.showBarCodeModal && (
          <BarCodeModal
            title="Print Barcodes"
            placement="right"
            closable
            onCancel={() => this.handleBarCodePrint(false)}
            visible={this.state.showBarCodeModal}
            style={{ top: 35 }}
            width="70%"
            selectedOrders={this.state.routes.selectedOrderRows}
            org_id={this.props.appContexts.userContext.currentOrg.id}
            handleBarCodePrint={this.handleBarCodePrint}
          />
        )}
        {this.state.isModalVisible && this.renderEmailModal()}
      </div>
    );
  }
}


export const PendingOrders = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const userContext= useContext(UserContext);
  const perPageKey = ScreenKeys.PENDING_ORDER_LISTING
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);

  return (
    <PendingOrdersComponent
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      recordsPerPage={recordsPerPage}
      userContext={userContext}
      {...props}
    />
  );
});

export default PendingOrders

PendingOrders.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  filterPlaceHolder: PropTypes.shape(),
}

PendingOrders.defaultProps = {
  filterPlaceHolder: {},
}
