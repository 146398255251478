import React, { useState, Fragment, useEffect } from "react";
import { Tooltip, Icon, message, Spin, Row, Col } from "antd";
import OrderPayments from "./OrderPayments";
import { BatchPaymentApi } from "../../api/BatchPaymentApi";
import { alertMessage,refreshIcon } from "../../common/Common";
import I18n from "../../common/I18n";
import { ARApi } from "../../api/ARApi";
import AppConfig from "../../config/AppConfig";
import { isEmpty } from "lodash";
import BaseModal from "../BaseModal";
import TransactionIndexList from "../account_ar/TransactionIndexList";
import BulkOperationsComponent from "./BulkOperations";
import userStore from "../../stores/UserStore";
import FormErrors from "../common/FormErrors";
import { base64ToPDF, renderAlertMessage } from "../../helpers/common";
import { CreditNotesApi } from "../../api/CreditNotesApi";


let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
const PaidPayments = ({
  account = {},
  showActions = true,
  refresTheList = () => {},
  updatedTime = "",
  searchToken = "",
  orgId,
  searchBy = "CHECK",
  recordsPerPage = AppConfig.perPage,
  userContextData = {}
}) => {
  const defaultFilter = {
    sortBy: "none",
    sortByType: "descend",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [chequesLoading, setChequesLoading] = useState(false);
  const [paidLines, setPaidLines] = useState([]);
  const [extraCheques, setExtraChecks] = useState([]);
  const [errors, setErrors] = useState([]);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [showExtraChecks, setShowExtraChecks] = useState(false);
  const [selectedCheckInfo, setSelectedCheckInfo] = useState({keys: [], rows: []});
  const [ filterPlaceHolder, setFilterPlaceHolder ] = useState({ ...defaultFilter });

  const [currentRec, setCurrentRec] = useState(null);
  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      getPaidLines();
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
    }
    // getPaidLines();
  };

  useEffect(() => {
    tableOptions.pagination.current = 1;
    getPaidLines();
  }, [updatedTime,filterPlaceHolder ]);

  const getPaidLines = () => {
    setIsLoading(true);
    const page = tableOptions?.pagination?.current
      ? tableOptions.pagination.current
      : 1;
    const perPage = recordsPerPage;
    const sortOrder = filterPlaceHolder.sortByType || "ascend";
    ARApi.paidInvoiceLines({
      account_id: account?.id ? account.id : "",
      organization_id: orgId,
      check_ach_no: searchToken,
      page,
      perPage,
      search_type: !isEmpty(searchToken) ? searchBy : "",
      sortBy: filterPlaceHolder.sortBy,
      sortByType: I18n.t(`general.sort_${sortOrder}`),
    }).then((result) => {
      if (result.success) {
        setIsLoading(false);
        const paidLines = result.lines || [];
        const pagination = paidLines.length > 0 ? result.pagination : {};
        setPaidLines(paidLines);
        setPagination(pagination);
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
        setPagination({})
      }
    });
  };

  const deleteLine = (rec) => {
    setIsLoading(true);
    BatchPaymentApi.deletePaidInvoiceLine(rec.id).then((result) => {
      if (result.success) {
        const currentRec = result.batch_account_payment
          ? result.batch_account_payment
          : currentPayment;
        alertMessage(I18n.t("messages.deleted"));
        getPaidLines();
        setIsLoading(false);
        refresTheList({
          ...currentRec,
          applied_total: Number(currentRec.applied_amount), // Number(currentPayment.applied_amount)- Number(rec.pay_amount),
          unapplied_total: Number(currentRec.unapplied_amount), //Number(currentPayment.unapplied_amount) + Number(rec.pay_amount),
        });
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const getExtraPaidChecks = (data) => {
    setIsLoading(true);
    ARApi.getExtraPaidChecks({
      account_invoice_id: data.account_invoice_id,
    }).then((result) => {
      if (result.success) {
        setCurrentRec(data);
        setIsLoading(false);
        setExtraChecks(result.lines || []);
        setShowExtraChecks(true);
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const downloadCreditMemo = (id) => {
    const organization_id = orgId;
    message.loading({
      key: "download",
      content: "Downloading PDF...",
    });
    const payload = {
      organization_id,
      ar_check_invoice_id: id,
    }
    CreditNotesApi.downloadCreditNote(payload).then((result) => {
      if (result.success) {
        message.destroy();
        base64ToPDF(result.file, result.fileName);
        alertMessage("PDF Downloaded Successfully" , "success", 10);
      } else {
        message.destroy();
        renderAlertMessage(result.errors)
      }
    });
  };



  const revertPaidPayments = (id) => {
    const { currentUser } = userContextData
    const userId = currentUser ? currentUser.id : null;
    setIsLoading(true);
    ARApi.revertPaidPayments({
      ar_check_invoice_id: id,
      user_id: userId
    }).then((result) => {
      if (result.success) {
        setIsLoading(false);
        alertMessage(result?.data?.message ? result.data.message : I18n.t('messages.saved'));
        getPaidLines();
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const resetChequesData = () => {
    handleChequesChange([],[])
    setCurrentRec(null);
    setShowExtraChecks(false);
    setExtraChecks([]);
    setErrors([])
  };

  const handleChequesChange = (selectedRowKeys, selectedRows) => {
    setSelectedCheckInfo({
      keys: selectedRowKeys,
      rows: selectedRows,
    })
  };

  const addExtraCheckToInvoice = (checkWarnings = false) => {

    const errors = [];
    if (errors.length === 0) {
      const payLoad = {
        organization_id: orgId,
        current_user_id: userStore.getStateValue("id"),
        ar_batch_check_ids: selectedCheckInfo.keys,
        account_invoice_id: currentRec?.account_invoice_id ? currentRec.account_invoice_id : '',
        ar_check_invoice_id: currentRec?.id ? currentRec.id : ""
      };
      setChequesLoading(true)
      ARApi.addExtraCheckToInvoice(payLoad).then((result) => {
        if (result.success) {
          setChequesLoading(false)
          alertMessage(I18n.t("messages.saved"));
          setErrors([])
          handleChequesChange([],[])
          getPaidLines();
          resetChequesData();
          // getExtraPaidChecks(currentRec);
        } else {
          setErrors(result.errors || [])
          setChequesLoading(false)
        }
      });
    } else {
      setErrors(result.errors || [])    }
  };

  const renderExtraCheques = () => {
    const selectedRowKeys = selectedCheckInfo.keys;
    const rowSelection = {
      selectedRowKeys,
      onChange: handleChequesChange,
      getCheckboxProps: null
    };
    return (
      <BaseModal
        title={
          <Row>
            <Col xs={24}>
               {currentRec?.account_invoice_number
                ? currentRec.account_invoice_number
                : ""} {I18n.t('account_receivables.cheque_details')}
            </Col>
          </Row>
        }
        className="customer-orders-modal"
        onCancel={() => {
          resetChequesData();
        }}
        width="800px"
        style={{
          top: 20,
          height: "50%",
        }}
        bodyStyle={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        <TransactionIndexList
          data={extraCheques}
          rowSelection={rowSelection}
          pagination={{ position: "none" }}
          showActions={false}
          columnsToDisplay={[
            "check_ach_no",
            "received_amount",
            "paid_amount",
            "balanced_amount",
          ]}
        />
         {selectedCheckInfo.keys.length > 0 && (
            <BulkOperationsComponent
              selectedInfo={selectedCheckInfo}
              loading={chequesLoading}
              applyAmount={addExtraCheckToInvoice}
              handleCancel={() => handleChequesChange([],[])}
              requireFix={false}
            />
          )}
          {
           errors.length > 0 && FormErrors(errors)
          }
      </BaseModal>
    );
  };

  const renderPayments = () => {
    return (
      <div className="minimizdTable">
        {/* <div className="alignRight">
          <span className="cursorPointer" onClick={() => getPaidLines()}>
          {refreshIcon(() => {})}
          &nbsp;Refresh
          </span>
        </div> */}

        <OrderPayments
          data={paidLines}
          deleteClick={deleteLine}
          pagination={tablePagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(pagination,filter, sorter, currentTable)
          }
          showActions={showActions}
          selectedKeys={[]}
          screen_name={"paid"}
          scroll={{ y: "calc(100vh - 320px)", x: "max-content" }}
          account={account}
          getExtraCheques={getExtraPaidChecks}
          revertPaidPayments={revertPaidPayments}
          downloadCreditMemo={downloadCreditMemo}
        />
      </div>
    );
  };

  return (
    <Spin spinning={isLoading}>
      {renderPayments()}
      {showExtraChecks && renderExtraCheques()}
    </Spin>
  );
};

export default PaidPayments;
