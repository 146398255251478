import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import {
  Col,
  Icon,
  FormItem,
  Input,
  Row,
  Select,
  Radio
} from '../../common/UIComponents'
import {  alertMessage, doValidate, formatCode } from '../../common/Common'
import I18n from '../../common/I18n'
import FormButtons from '../common/FormButtons'
import FormErrors from '../common/FormErrors'
import AppConfig from '../../config/AppConfig'
import { saveOrgDriverRate } from '../../api/DriverRates'
import { isEmpty } from 'lodash'


const charges = ['Transportation', I18n.t('driver_rates.fuel_surcharge'), 'Accessorial']
const requiredFields = [
  {
    form_field: 'name',
    ui_name: `${I18n.t('general.name')}`,
    isRequired: true,
    inputType: 'name',
  },
  {
    form_field: 'code',
    ui_name: I18n.t('general.code'),
    isRequired: true,
    inputType: 'code',
  },
  {
    form_field: 'driver_rate_params',
    ui_name: `${charges.join(', ')} values required`,
    isRequired: true,
    inputType: 'array',
  },
]

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rateRecord: this.props.rateRecord ? this.props.rateRecord : {},
      isNew: this.props.isNew,
      inProgress: false,
      errors: [],
      organization_id: this.props.organization_id || '',
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    }
  }

  handleOnChange = (element, value) => {
    if(element === 'is_default'){
      value = value ? 'YES' : 'NO'
    }
    this.setState({
      rateRecord: Object.assign({}, this.state.rateRecord, {
        [element]: value,
      }),
    })
  }

  handleOnTypeChange = (type, element, value) => {
    const { rateRecord } = this.state
    const driverRateParams = rateRecord.driver_rate_params || []
    const currentRecordIndex = _.findLastIndex(driverRateParams, {
      accessorial_type: type,
    })
    const defaultAccessorialChargeType = 'PERCENTAGE';
    if (currentRecordIndex >= 0) {
      driverRateParams[currentRecordIndex][element] = value
      if(element !== "accessorial_charge_type" && isEmpty(driverRateParams[currentRecordIndex].accessorial_charge_type)){
        driverRateParams[currentRecordIndex].accessorial_charge_type = defaultAccessorialChargeType;
      }
      if(type === I18n.t('driver_rates.fuel_surcharge') && element === "accessorial_charge_type" && value == 'nfc'){
        driverRateParams[currentRecordIndex].apply_on = 'fuel';
      }
    } else {
      const obj = { accessorial_type: type,[element]: value }
      if(element !== "accessorial_charge_type" ){
        obj.accessorial_charge_type = defaultAccessorialChargeType;
      } else{
        if(type === I18n.t('driver_rates.fuel_surcharge')){
          obj.apply_on = 'fuel';
        }
      }    
      driverRateParams.push(obj)
    }
    
    rateRecord.driver_rate_params = _.cloneDeep(driverRateParams)
    this.setState({
      driverRateParams,
    })
  }

  handleSave = () => {
    const { rateRecord } = this.state
    this.setState({ inProgress: true })
    const data = {
      id: this.state.isNew ? '' : rateRecord.id,
      organization_id: this.state.organization_id,
      name: rateRecord.name,
      code: rateRecord.code,
      is_default: rateRecord.is_default,
      driver_rate_params: rateRecord.driver_rate_params || [],
    }
    const errors = doValidate(requiredFields, data)
    if (errors.length === 0) {
      data.code = formatCode(data.code)
      saveOrgDriverRate(this.state.isNew, data).then((result) => {
        if (result.success) {
          this.setState({ inProgress: false, errors: [] })
          alertMessage(I18n.t('messages.saved'))
          this.props.onCancel();
        } else {
          this.setState({ errors: [result.errors], inProgress: false })
        }
      })
    } else {
      this.setState({ errors, inProgress: false })
    }
  }

  renderRateParam = (type) => {
    const { rateRecord, decimalPoints } = this.state
    const driverRateParams = rateRecord.driver_rate_params || []
    const currentRecordIndex = _.findIndex(driverRateParams, [
      'accessorial_type',
      type,
    ])
    let currentRecord = {}
    if (currentRecordIndex >= 0) {
      currentRecord = driverRateParams[currentRecordIndex]
    }
    return (
      <Row gutter={16}>
        <Col md={4} className="textBold paddingTop15">
          <span>{type} :</span>
        </Col>
        <Col md={8}>
          <FormItem label={I18n.t('driver_pay.rate_type')}>
             <Select
              value={currentRecord.accessorial_charge_type || 'PERCENTAGE'}
              showSearch
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) =>
                this.handleOnTypeChange(type, 'accessorial_charge_type', e)
              }
            >
             {
              
              Object.keys(AppConfig.amountTypes).map((key) => (
                <Select.Option key={key} value={AppConfig.amountTypes[key]}>
                  {key}
                </Select.Option>
              ))
              }
              {
              type === I18n.t('driver_rates.fuel_surcharge') &&
                <Select.Option key={"NFC"} value={"NFC"}>NFC</Select.Option>
              }
            </Select>
          </FormItem>
        </Col>
        <Col md={8}>
        {type=== I18n.t('driver_rates.fuel_surcharge') && currentRecord.accessorial_charge_type ==="NFC"  ? 
          <FormItem label={'Apply For'}>
            <Select
              value={currentRecord.apply_on || 'fuel'}
              showSearch
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) =>
                this.handleOnTypeChange(type, 'apply_on', e)
              }
            >
            <Select.Option key='fuel' value={'fuel'}>Fuel</Select.Option>
              <Select.Option key='Driver_net' value={'driver_net'}>Driver Net</Select.Option>
            </Select>
        </FormItem>
        :
          <FormItem
            label={`${I18n.t('driver_pay.rate_value')}`}
          >
            <Input
              type="number"
              value={parseFloat(currentRecord.accessorial_charge_value)}
              onChange={(e) =>
                this.handleOnTypeChange(
                  type,
                  'accessorial_charge_value',
                  e.target.value,
                )
              }
              min={0}
              precision={decimalPoints}
              addonBefore={
                currentRecord.accessorial_charge_type === 'FLAT' ? (
                  <Icon type="dollar" />
                ) : null
              }
              addonAfter={
                isEmpty(currentRecord.accessorial_charge_type) ||  ['PERCENTAGE'].includes(currentRecord.accessorial_charge_type) ? (
                  <Icon type="percentage" />
                ) : null
              }
            />           
          </FormItem>
          }
        </Col>
      </Row>
    )
  }

  render() {
    const { rateRecord } = this.state
    return (
      <div>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t('general.name')} require>
              <Input
                value={rateRecord.name}
                onChange={(e) => this.handleOnChange('name', e.target.value)}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t('general.code')} require>
              <Input
                value={rateRecord.code}
                onChange={(e) => this.handleOnChange('code', e.target.value)}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
        </Row>
        {charges.map((charge) => this.renderRateParam(charge))}
        {this.state.isNew ? <Row>
          <Col xs={24}>
            <Checkbox
                checked={rateRecord.is_default === 'YES'}
                onChange={(e) =>
                    this.handleOnChange('is_default', e.target.checked)
                }
            >
              Set as Default
            </Checkbox>
          </Col>
        </Row> : null}

        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel,
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

Form.propTypes = {
  rateRecord: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

Form.defaultPropTypes = {
  rateRecord: {},
  isNew: true,
}

export default Form
