import { Call, ApiUrl, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';

const params = {
  forms: {
    index: 'form_questions?form_id',
    details: 'form_questions',
    save: 'form_questions',
    update: 'form_questions',
    delete: 'form_questions',
    index_opCode: 'FQUI',
    details_opCode: 'FQUS',
    save_opCode: 'FQUC',
    update_opCode: 'FQUU',
    delete_opcode: 'FQUD'
  },
  surveys: {
    index: 'questions?survey_id',
    details: 'questions',
    save: 'questions',
    update: 'questions',
    delete: 'questions',
    index_opCode: 'SQUI',
    details_opCode: 'SQUS',
    save_opCode: 'SQUC',
    update_opCode: 'SQUU',
    delete_opcode: 'SQUD'
  },
};

export function fetchQuestions(id, parentType) {
  const url = ApiUrl(`v2/${params[parentType].index}=${id}&operation_code=${params[parentType].index_opCode}`);
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { questions: 'questions' } });
}
export function fetchQuestion(id, parentType) {
  const url = ApiUrl(`v2/${params[parentType].details}/${id}?operation_code=${params[parentType].details_opCode}`);
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { question_details: '' } });
}

export function saveQuestions(isNew, data, parentType) {
  data.operation_code = isNew ? params[parentType].save_opCode : params[parentType].update_opCode;
  const formData = setObjectToFormData(data);
  const method = isNew ? 'post' : 'put';
  const url = isNew ? ApiUrl(`v2/${params[parentType].save}`) : ApiUrl(`v2/${params[parentType].save}/${data.id}`);
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { questions: '' } }, false);
}

export function deleteQuestion(id, parentType) {
  const url = ApiUrl(`v2/${params[parentType].delete}/${id}`);
  return Call('delete', url, { operation_code : params[parentType].delete_opcode }, ParseGeneralResponse);
}

export function sequenceQuestion(data, parentType) {
  const url = parentType==="forms" ? ApiUrl("v2/form_questions/update_fqn_order") : ApiUrl("v2/survey/update_survey_qsn_sequence");
  return Call("PUT", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}
