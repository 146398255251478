import React from "react";
import {
	Select,
	Button,
	Form,
	Input,
	Row,
	Col,
} from "antd";
import PropTypes from "prop-types";
import userStore from "../../stores/UserStore";
import {
	AccountInstructionsApi,
	OrgInstructionsApi,
} from "../../api/InstructionsApi";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";

const { Option } = Select;
class InstructionsFormWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isInstructionSelected: false,
			selectedInstructionData: {},
			isNewInstruction: false,
			isEditing: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		const { instructionsData, form, activeInstructionId } = this.props;
		if (activeInstructionId) {
			this.setState({ isLoading: true });
			const editableData = instructionsData.find(
				(instructionType) => instructionType.id === activeInstructionId
			);
			form.setFieldsValue({
				instructionType: editableData.code,
				instructionDescription: editableData.description,
			});
			this.setState({ isEditing: true, isLoading: false });
		} else {
			// reset form
			form.resetFields();
			this.setState({ isEditing: false, isLoading: false });
		}
	}
	componentDidUpdate(prevProps, prevState) {
		// if (
		// 	prevState.selectedInstructionData &&
		// 	this.state.selectedInstructionData
		// ) {
		// 	if (
		// 		prevState.selectedInstructionData.code !==
		// 		this.state.selectedInstructionData.code
		// 	) {
		// 		prevState.selectedInstructionData.code !== ""
		// 			? this.props.appendUsedCode(
		// 					prevState.selectedInstructionData.code,
		// 					this.state.selectedInstructionData.code
		// 			  )
		// 			: this.props.addUsedCode(this.state.selectedInstructionData.code);
		// 	}
		// }
	}

	onSubmit = (e) => {
		e.preventDefault();
		const { validateFields } = this.props.form;
		const { updateInstruction, addInstruction, isOrgLevelInstruction } =
			this.props;
		const orgId = userStore.getStateValue("selectedOrg");
		const accountId = this.props.account.id;
		validateFields((err, values) => {
			if (!err) {
				this.setState({ isLoading: true });
				if (!this.state.isEditing) {
					// new type
					const newInstructionObj = {
						code: values.instructionType,
						description: values.instructionDescription,
					};
					isOrgLevelInstruction
						? OrgInstructionsApi.create(orgId, newInstructionObj).then(
								(result) => {
									if (result.success) {
										addInstruction(result.data);
										alertMessage(
											I18n.t("instructions.modal.createSuccess"),
											"success"
										);
									} else {
										result.errors.map((error) => {
											alertMessage(error, "error");
										});
									}
								}
						  )
						: AccountInstructionsApi.create(
								orgId,
								accountId,
								newInstructionObj
						  ).then((result) => {
								if (result.success) {
									addInstruction(result.data, this.props.instruction.id);
									alertMessage(
										I18n.t("instructions.modal.createSuccess"),
										"success"
									);
								} else {
									result.errors.map((error) => {
										alertMessage(error, "error");
									});
								}
						  });
				} else {
					// update
					const updateInstructionObj = {
						description: values.instructionDescription,
						code: values.instructionType,
						organization_id: orgId,
						account_id: accountId,
					};
					isOrgLevelInstruction
						? OrgInstructionsApi.update(
								this.props.activeInstructionId,
								updateInstructionObj
						  )
								.then((result) => {
									if (result.success) {
										alertMessage(
											I18n.t("instructions.modal.updateSuccess"),
											"success"
										);
										this.setState({
											isEditing: false,
										});
										updateInstruction(result.data);
									} else {
										result.errors.map((error) => {
											alertMessage(error, "error");
										});
									}
								})
								.finally(() => {
									this.setState({
										isLoading: false,
									});
								})
						: AccountInstructionsApi.update(
								this.props.activeInstructionId,
								updateInstructionObj
						  )
								.then((result) => {
									if (result.success) {
										alertMessage(
											I18n.t("instructions.modal.updateSuccess"),
											"success"
										);
										updateInstruction(result.data);
										this.setState({
											isEditing: false,
										});
									} else {
										result.errors.map((error) => {
											alertMessage(error, "error");
										});
									}
								})
								.finally(() => {
									this.setState({
										isLoading: false,
									});
								});
				}
			}
		});
	};
	deleteInstruction = () => {
		const { deleteInstruction } = this.props;
		const { id } = this.props.selectedInstruction;

		const { isOrgLevelInstruction } = this.props;

		isOrgLevelInstruction
			? OrgInstructionsApi.delete(id).then((result) => {
					if (result.success) {
						alertMessage(I18n.t("instructions.modal.deleteSuccess"), "success");
						deleteInstruction(id);
					} else {
						result.errors.map((error) => {
							alertMessage(error, "error");
						});
					}
			  })
			: AccountInstructionsApi.delete(id).then((result) => {
					if (result.success) {
						alertMessage(I18n.t("instructions.modal.deleteSuccess"), "success");
						deleteInstruction(id);
					} else {
						result.errors.map((error) => {
							alertMessage(error, "error");
						});
					}
			  });
	};
	enableEditing = (instructionData) => {
		this.setState({
			isEditing: true,
			isNewInstruction: false,
			selectedInstructionData: instructionData,
		});
	};

	render() {
		const {
			instructionsData,
			instruction,
			form: { getFieldDecorator },
			formTitle,
			onCancelNewInstruction,
			instructionTypesData,
			closeInstructionModal,
		} = this.props;
		const { selectedInstructionData, isEditing, isNewInstruction } = this.state;
		return (
			<Form onSubmit={this.onSubmit} title={formTitle}>
				<Row gutter={16}>
					<Col>
						<Form.Item label={I18n.t("general.type")}>
							{getFieldDecorator("instructionType", {
								rules: [
									{
										required: true,
										message: "Please Select Instruction Type",
									},
								],
							})(
								<Select
									placeholder="Select Instruction Type"
									disabled={isEditing}
									onChange={(code) => {
										this.setState({
											isInstructionSelected: true,
											selectedInstructionData: {
												code,
											},
										});
									}}
								>
									{instructionTypesData
										.filter(
											(instructionType) =>
												!this.props.isUsedCode(instructionType.code)
										)
										.map((type) => (
											<Option key={type.id} value={type.code}>
												{type.code}
											</Option>
										))}
								</Select>
							)}
						</Form.Item>
					</Col>
					<Col>
						<Form.Item label={I18n.t("general.description")}>
							{getFieldDecorator("instructionDescription", {
								rules: [
									{
										required: true,
										message: "Please Enter Instructions",
									},
								],
							})(
								<Input.TextArea
									rows={4}
									placeholder={I18n.t("instructions.description")}
								/>
							)}
						</Form.Item>
					</Col>
					<Col>
						<Row type="flex" gutter={16} justify="center">
							<Col>
								<Button
									onClick={() => {
										closeInstructionModal();
										this.setState({
											isEditing: false,
										});
									}}
								>
									Cancel
								</Button>{" "}
							</Col>
							<Col>
								<Button
									type="primary"
									htmlType="submit"
									loading={this.state.isLoading}
								>
									{isEditing ? I18n.t("general.save") : I18n.t("general.add")}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		);
	}
}

const InstructionsForm = Form.create({ name: I18n.t(`roles.form`) })(
	InstructionsFormWrapper
);
export default InstructionsForm;

InstructionsFormWrapper.propTypes = {
	form: PropTypes.object,
	instructionsData: PropTypes.array,
	account: PropTypes.object,
	instruction: PropTypes.object,
	onCancelNewInstruction: PropTypes.func,
	updateInstruction: PropTypes.func,
	isUsedCode: PropTypes.func,
	instructionAlreadyExists: PropTypes.bool,
	deleteInstruction: PropTypes.func,
	addInstruction: PropTypes.func,
	isOrgLevelInstruction: PropTypes.bool,
	instructionTypesData: PropTypes.array,
	closeInstructionModal: PropTypes.func,
};
InstructionsFormWrapper.defaultProps = {
	instructionsData: [],
	instructionTypesData: [],
	account: {},
	instruction: {},
	onCancelNewInstruction: () => {},
	updateInstruction: () => {},
	isUsedCode: () => {},
	deleteInstruction: () => {},
	addInstruction: () => {},
	isOrgLevelInstruction: false,
	closeInstructionModal: () => {},
};
