import React from "react";
import moment from "moment";
import BaseList from "../BaseList";
import { checkNegitive } from "../../common/Common";
import I18n from "../../common/I18n";
import { Col, Row, Typography } from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import userStore from "../../stores/UserStore";
import { formatByTimeConfig } from "../../helpers/common";
import { momentTime } from "../../helpers/date_functions";

const { Text } = Typography;

const QuotesActivitiesList = (props) => {
    const {warehouseDetails} = props;
    const timeZoneId = _.get(warehouseDetails, "time_zone_id", "");
    const columns = [
        {
            title: "#",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            key: "description",
            title: I18n.t("quotes.description"),
            dataIndex: "description",
            render: (data) => (
                <div>
                    <Row gutter={10} >
                        <Col xs={1}>
                            <div className="paddingTop3">
                                <div
                                    style={{
                                        borderColor: "#009bff",
                                    }}
                                    className="connectedDot"
                                />
                            </div>
                        </Col>
                        <Col xs={23}>
                            {data}
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            key: "activity_by",
            title: I18n.t("quotes.activity_by"),
            dataIndex: "activity_by",
            render: (text, record) => (
                <div>
                    <span>{text}</span>
                </div>
            ),
            className: "textCaptilize",

            width: 120,
        },
        {
            key: "activity_on",
            title: I18n.t("quotes.activity_on"),
            dataIndex: "activity_on",
            sorter: (a, b) => new Date(a.activity_on) - new Date(b.activity_on),

            sortDirections: ["ascend", "descend", "ascend"],
            render: (data) => {
                return (


                    <span>
                        {formatByTimeConfig(momentTime("", data, timeZoneId), false, "MMM Do, YYYY HH:mm", 'MMM Do, YYYY hh:mm A')}
                    </span>
                );
            },
        },
    ];

    return <BaseList columns={columns} {...props} />;
};

export default QuotesActivitiesList;
