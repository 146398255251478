import React from 'react'
import { Tabs } from 'antd'
import ListDriverSettlement from './LisrDriverSettlement';

export const DriverViewList = (props) => {
    const onChange = (key) => {
    };
  return (
    <div className="card-container tabHover">
        <Tabs defaultActiveKey="drivers"
        type="card"
        onChange={onChange}>
            <Tabs.TabPane tab={"Weekly Settlement"} key="drivers">
                <ListDriverSettlement  dispContext={props.dispContext} />
            </Tabs.TabPane>
        </Tabs>

    </div>
  )
}