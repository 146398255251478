/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import {
  Button,
  Row,
  Col,
  DatePicker,
  TimePicker,
  FormItem,
} from '../../common/UIComponents'
import {  alertMessage } from '../../common/Common'
import I18n from '../../common/I18n'
import OpinionForm from './OpinionFom'
import BaseModal from '../BaseModal'
import FormButtons from '../common/FormButtons'
import FormErrors from '../common/FormErrors'
import { RecoveriesApis } from '../../api/RecoveriesApi'
import { OrgContext } from '../../context/OrgContext';

class ChangeRecoveryTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recoveries: this.props.recoveries || [],
      inProgress: false,
      showForm: false,
      recovery_time: moment(),
      organizationSettings: {},
      isMilitaryTime: false,
      errorOrderNos: [],
      errors: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings,
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == 'true',
      })
    }
  }

  componentDidMount() {
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == 'true',
    })
  }

  enableForm = () => {
    this.setState({
      showForm: true,
    })
  }
  disabelForm = () => {
    this.setState(
      {
        showForm: false,
        errors: [],
        orderErrors: [],
        errorOrderNos: [],
      },
      () => {
        // this.props.resetRecoveries();
      },
    )
  }

  setAppointmentSchedule = () => {
    this.setState({
      inProgress: true,
    })
    const { recovery_time, recoveries} = this.state;

    const data = {
      recovery_time: moment(recovery_time).format(
        'YYYY-MM-DD HH:mm',
      ),
      recovery_ids: recoveries || [],
    }
    RecoveriesApis.changeRecoveryTime(data).then((result) => {
      // result.success = false;
      // result.errors = ["11111111111: false"];
      if (result.success) {
        this.setState(
          {
            recovery_time: null,
            orderErrors: [],
            errors: [],
            errorOrderNos: [],
          },
          () => {
            this.props.resetRecoveries()
          },
        )
      } else {
        if (result.errors.length > 0) {
          const errorValue = result.errors[0]
          if (_.isString(errorValue)) {
            this.setState({
              errors: result.errors || [],
            })
          } else if (_.isObject(errorValue)) {
            const errors = []
            const errorOrderNos = []
            result.errors.forEach((errObj) => {
              if (_.isObject(errObj) && errObj.order_number) {
                errorOrderNos.push(errObj.order_number)
                errors.push(errObj)
              }
            })
            this.setState({
              errorOrderNos,
              inProgress: false,
              errors,
              showErrors: errors.length > 0,
            })
            // alertMessage('One or more required fields are missing', 'error', 10);
          }
        }
        this.setState({ inProgress: false })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      recoveries: nextProps.recoveries || [],
    })
  }

  handleStateChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  _renerAppointmentWindow = () => {
    const { organizationSettings, isMilitaryTime } = this.state
    return (
      <Fragment>
        <BaseModal
          title=<Fragment>Change {I18n.t('recoveries.eta')}</Fragment>
          onCancel={this.disabelForm}
          width="520px"
        >
          <div>
            {/* <OpinionForm
              record={this.state.preference}
              itemOnChange={(index, element, value) =>
                this.handleItemOnChange(index, element, value)
              }
              itemOptionOnChange={(index, element, value, checked, type) =>
                this.handleItemOptionOnChange(
                  index,
                  element,
                  value,
                  checked,
                  type,
                )
              }
              confirmChange={(index, element, value) =>
                this.handleConfirmChange(index, element, value)
              }
              clearPreference={(index) => this.clearPreference(index)}
              isDisabled={false}
              feasibleDow={[]}
              feasibleDates={[]}
              deliveryZipcodes={false}
              showClear={false}
              disabledStartHours={dispatcherDisabledStartHours}
              disabledEndHours={dispatcherDisabledEndHours}
              endHour={endTimes.hourValue}
              endMinute= {endTimes.minuteValue}
              startHour={startTimes.hourValue}
              startMinute= {startTimes.minuteValue}
              optingType={'radio'}
              showExpedite
              isMilitaryTime={this.state.isMilitaryTime}
            /> */}

              <Row>
                <Col xs={24} sm={24} lg={12} md={12} className="padding5">
                  <FormItem label="Recovery Date">
                    <DatePicker
                      style={{ width: '100%', marginLeft: '1%' }}
                      placeholder="Select Date"
                      format="Do MMM YYYY"
                      onChange={(e) =>
                        this.handleStateChange('recovery_time', e)
                      }
                      value={
                        this.state.recovery_time
                          ? moment(this.state.recovery_time)
                          : null
                      }
                      defaultValue={null}
                      onOk={this.onOk}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={12} md={12} className="padding5">
                  <FormItem label="Recovery Time">
                    <TimePicker
                      format={isMilitaryTime ? 'HH:mm' : 'hh:mm A'}
                      use12Hours={!isMilitaryTime}
                      placeholder="Select Time"
                      value={
                        this.state.recovery_time
                          ? moment(this.state.recovery_time)
                          : null
                      }
                      onChange={(e) =>
                        this.handleStateChange('recovery_time', e)
                      }
                      // defaultValue={null}
                      // onOk={this.onOk}
                      style={{ width: '100%', marginLeft: '1%' }}
                      showSecond={false}
                    />
                  </FormItem>
                </Col>
              </Row>
            <Row>
              <Col className="marginTop20 schedule">
                {FormButtons(
                  this.state.inProgress,
                  this.setAppointmentSchedule,
                  this.clearPreference,
                  true,
                  true,
                  'small',
                  I18n.t('general.save'),
                  I18n.t('general.clear'),
                )}
              </Col>
            </Row>
            {!this.state.showErrors && this.state.errors.length > 0 && (
              <Row>
                <Col xs={24} className="marginTop10">
                  {FormErrors(this.state.errors)}
                </Col>
              </Row>
            )}
          </div>
        </BaseModal>
      </Fragment>
    )
  }

  clearPreference = () => {
    this.handleStateChange('recovery_time', moment())
  }

  closeErrors = () => {
    this.setState({
      orderErrors: [],
      showErrors: false,
    })
  }

  renderStatusErrors = () => (
    <BaseModal
      title={I18n.t('errors.errors')}
      placement="right"
      closable
      onCancel={this.closeErrors}
      visible={this.state.showErrors}
      width="50%"
      style={{ top: 35 }}
    >
      {this.state.errors.map((err) => (
        <Row>
          <Col xs={4}>{err.order_number}</Col>
          <Col xs={20}>{_.isArray(err.errors) ? err.errors.join(',') : ''}</Col>
        </Row>
      ))}
    </BaseModal>
  )

  render() {
    return (
      <Fragment>
        <Button
          type="primary"
          onClick={() => this.enableForm()}
          style={{ marginTop: '2px', marginLeft: 10 }}
          className="buttonYellow"
          icon="calendar"
        >
          Change {I18n.t('recoveries.eta')}
        </Button>
        {this.state.showForm && this._renerAppointmentWindow()}
        {this.state.showErrors && this.renderStatusErrors()}
      </Fragment>
    )
  }
}

ChangeRecoveryTime.propTypes = {
  resetRecoveries: PropTypes.func.isRequired,
  recoveries: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.string.isRequired,
}
// OrderFilter.defaultProps = {
// };

 const ChangeRecoveryTimeComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <ChangeRecoveryTime
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default ChangeRecoveryTimeComponent;