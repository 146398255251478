/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Spin,
  Button,
  Popconfirm,
  Form,
  Input,
  Select,
  InputNumber,
} from '../../../common/UIComponents'
import {
  alertMessage,
  isEmpty,
} from '../../../common/Common'
import { saveDriverDeductionData } from '../../../api/Reports'
import I18n from '../../../common/I18n';
import BaseSelect from '../../common/SelectDropdowns/BaseSelect'
import GLCodeSelect from '../../common/SelectDropdowns/GLCodeSelect'

const { Option } = Select
class AddDeductionsToWeeklyReport extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      // pagination: {},
      inProgress: false,
      currentRecord: this.props.currentRecord || {},
      errors: [],
      drivers: this.props.drivers || [],
      deductions: this.props.deductions,
      decimalPoints,
      zeroValue,
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    // this.getDeductions()
    if (this.state.currentRecord.id) {
      // this.props.form.setFieldsValue({...this.state.currentRecord});
    }
  }

  handleFormChange = (element, value) => {
    const { setFieldsValue } = this.props.form
    const currentRecord = Object.assign({}, this.state.currentRecord, {
      [element]: value,
    })
    if (element === 'deduction_master_id') {
      const deductionRec = _.find(this.state.deductions, { id: value })
      if (!isEmpty(deductionRec) && !isEmpty(value)) {
        currentRecord.deduction_name = deductionRec.deduction_name
        currentRecord.gl_code = deductionRec.gl_code
      } else {
        currentRecord.deduction_name = ''
        currentRecord.gl_code = ''
      }
    }
    if(element === 'gl_code'){
      setFieldsValue({
        gl_code: value
      })
    }
    this.setState({
      currentRecord,
    })
  }


  handleSaveDetails = (fieldsValue) => {
    // this.props.setLoading(true);
    const { zeroValue } = this.state;
    const deductions = [...this.props.deductions]
    const existingRecords = deductions.map(record => ({
      id: record.id || '',
      deduction_master_id: record.deduction_master_id || '',
      amount: !isEmpty(record.amount) ? record.amount : zeroValue,
      deduction_name: !isEmpty(record.deduction_name)
        ? record.deduction_name
        : '',
      gl_code: !isEmpty(record.gl_code) ? record.gl_code : '',
    }));
    const newRecord = {...fieldsValue};
    const data = {
      driver_id: this.props.driverId,
      driver_pay_report_id: this.props.driverPayId,
      driver_deductions: [...existingRecords, {...newRecord}],
    }
    this.setState({ inProgress: true });
    saveDriverDeductionData(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({ inProgress: false });
          this.props.handleResponse(result);
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({
            errors: result.errors,
            inProgress: false,
          });
        }
      });
  }

  getFields() {
    const count = this.state.expand ? 10 : 6;
    const {accounts} = this.props;
    const { getFieldDecorator } = this.props.form
    const size = this.props.size;

    const { currentRecord, drivers, deductions, decimalPoints } = this.state

    const children = [
      <Col span={12}>
        <Form.Item label={I18n.t("deductions.name")}>
          {getFieldDecorator("deduction_name", {
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t("deductions.name")}`,
              },
            ],
          })(
            <Input
              size={size}
              onChange={(e) =>
                this.handleFormChange("deduction_name", e.target.value)
              }
              className="width100Per"
              autoFocus={true}
            />
          )}
        </Form.Item>
      </Col>,
      <Col span={6}>
        <Form.Item label={`${I18n.t("deductions.gl_code_label")}`}>
          {getFieldDecorator("gl_code", {
            initialValue: currentRecord.gl_code ? currentRecord.gl_code : "",
            rules: [
              {
                required: true,
                message: `Select ${I18n.t("deductions.gl_code_label")}`,
              },
            ],
          })(
            <>
              {/* // <Input
            //   size={size}
            //   onChange={(e) => this.handleFormChange('gl_code', e.target.value)}
            //   className="width100Per textUpperCase"
            // /> */}
               <GLCodeSelect
                  data={this.props.glCodesList || []}
                  className="textUpperCase fontSize12"
                  value={currentRecord["gl_code"]}
                  onChange={(e) => this.handleFormChange("gl_code", e)}
                  selectKey={"gl_code"}
                  selectValue={"gl_code"}
                  selectedValue={"gl_code"}
                  showSearch={true}
                  style={{ width: '100%'}}
                  dropdownMatchSelectWidth={false}
                  selectedCode={"description"}
                  size="small"
                />
              {/* <Select
                style={{ width: "100%" }}
                onChange={(e) => this.handleFormChange("gl_code", e)}
                size="small"
                showSearch
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) =>
                  option.props?.children &&
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.props.glCodesList.map((rec) => (
                  <Select.Option value={rec.gl_code} key={rec.gl_code}>
                    {`${rec.gl_code} ${
                      rec.description ? `(${rec.description})` : ""
                    }`}
                  </Select.Option>
                ))}
              </Select> */}
            </>
          )}
        </Form.Item>
      </Col>,
      <Col span={6}>
        <Form.Item
          label={`${I18n.t("deductions.amount")} (${I18n.t(
            "general.price_symbol"
          )})`}
        >
          {getFieldDecorator("amount", {
            initialValue: currentRecord.gross_revenue
              ? currentRecord.gross_revenue
              : "",
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t("deductions.amount")}`,
              },
            ],
          })(
            <InputNumber
              size={size}
              onChange={(e) => this.handleFormChange("amount", e)}
              precision={decimalPoints}
              className="width100Per textUpperCase"
              min={0}
              addOnBefore={"$"}
            />
          )}
        </Form.Item>
      </Col>,
    ];
    return children
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.handleSaveDetails(values)
      }
    })
  }

  render() {
    return (
      <div className="content-outer">
        <div>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Form
              className="ant-advanced-search-form customForm"
              onSubmit={this.handleSearch}
            >
              <Row gutter={24} className="fontSize12">{this.getFields()}</Row>
              <div className='alignRight'>
               <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.cancel_confirm')}
                  onConfirm={this.props.onCancel}
                  okText="Yes"
                  cancelText="No"
                >
                 <Button
                  style={{ marginRight: 8 }}
                  type="danger"
                  size="small"
                  icon='close'
                  >
                    Cancel
                </Button>
                </Popconfirm>
              
               <Button type="primary" htmlType="submit" size="small" icon='plus'>
                    {I18n.t('general.add')}
                  </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    )
  }
}
AddDeductionsToWeeklyReport.propTypes = {
  account: PropTypes.shape().isRequired,
}
const WrappedForm = Form.create({ name: 'horizontal_login' })(AddDeductionsToWeeklyReport)

export default WrappedForm