import { Call, ApiUrl,ParseGeneralResponse, setObjectToFormData } from './ApiUtils';
import userStore from '../stores/UserStore';

export function saveDriverRate(data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl('v2/driver_rates');
  const method = 'post';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { driver_rate: 'driver_rate' } }, true);
}


export function fetchDriversRateDetails(id) {
  const organizationId = userStore.getStateValue('selectedOrg');
  const url = ApiUrl(`v2/driver_rates/driver_rate_details?driver_id=${id}&organization_id=${organizationId}&operation_code=DRDS`);
  const method = 'get';
  return Call(method, url, {}, ParseGeneralResponse, { responseDataKeys: { driver_rate: 'driver_rate' } }, true);
}


//   // // 

export function saveDriverRateConfigurations(data) {
  data.operation_code = 'DRCONF';
  const formData = setObjectToFormData(data);
  const url = ApiUrl('v2/driver_rates/configurations');
  const method = 'post';
  return Call(method, url, formData, ParseGeneralResponse, {}, true);
}

export function fetchDriverRatesList() {
  const formData = setObjectToFormData({});
  const url = ApiUrl('v2/driver_rates');
  const method = 'get';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { driver_rates: 'driver_rates' } }, true);
}

export function fetchDriverRatesConfigurationDetails(driver_rate_id) {
  const formData = setObjectToFormData({});
  const url = ApiUrl(`v2/driver_rates/configuration_details?driver_rate_id=${driver_rate_id}&operation_code=DRCONFD`);
  const method = 'get';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { driver_rate_details: 'driver_rate_details' } }, true);
}

export function fetchAccessorialMasterData() {
  const formData = setObjectToFormData({});
  const url = ApiUrl('v2/accessorial_masters');
  const method = 'get';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { master_accessorials: 'master_accessorials' } }, true);
}

export function fetchDriverRate(data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl(`v2/driver_rates/${data.driver_rate_id}?driver_rate_id=${data.driver_rate_id}`);
  const method = 'get';
  return Call(method, url, {}, ParseGeneralResponse, { responseDataKeys: { driver_rate: 'driver_rate' } }, true);
}

export function deleteDriverRate(data) {
  const formData = setObjectToFormData(data);
  const url = ApiUrl(`v2/driver_rates/${data.driver_rate_id}?driver_rate_id=${data.driver_rate_id}`);
  const method = 'delete';
  return Call(method, url, {}, ParseGeneralResponse, {}, true);
}

export const driverRateApi =  {

  saveDriverRate: (data, isNew) => {
    const formData = data; //setObjectToFormData(data);
    const url = isNew ? ApiUrl('v2/account_driver_rates/create_driver_rate') : ApiUrl('v2/account_driver_rates/update_driver_rate');
    const method = 'post';
    return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { driver_rate: 'account_driver_rate' } }, true);
  },
  getDriverRate: (id) => {
    const url = ApiUrl(`v2/account_driver_rates/id=${id}`);
    const method = 'get';
    return Call(method, url, {}, ParseGeneralResponse, { responseDataKeys: { driver_rate: 'account_driver_rate' } }, true);
  }

}
