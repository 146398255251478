/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  Icon,
  Input,
  Row,
  Popconfirm,
  InputNumber,
  Typography,
  Tooltip,
} from "../../../common/UIComponents";
import { isEmpty } from "../../../common/Common";
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import AppConfig from "../../../config/AppConfig";
import { checkServiceExistance } from "../../../helpers/common";
import DeleteIcon from "../../common/DeleteIcon";
import I18n from "../../../common/I18n";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: this.props.zones ? this.props.zones : [],
      inProgress: false,
      errors: [],
    };
  }

  handleOnChange = (index, element, value) => {
    this.props.onChange(index, element, value);
  };

  handleSave = () => {
    const zones = [...this.state.zones];
    let errors = [];
    zones.forEach((zone, index) => {
      const currentRecordErrors = [];
      if (isEmpty(zone.name)) {
        currentRecordErrors.push(`Name in zone ${index + 1} is required`);
      }
      if (isEmpty(zone.min_distance)) {
        currentRecordErrors.push(
          `Min distance in zone ${index + 1} is required`
        );
      }
      if (isEmpty(zone.max_distance)) {
        currentRecordErrors.push(
          `Max distance in zone ${index + 1} is required`
        );
      }

      if (
        !isEmpty(zone.min_distance) &&
        !isEmpty(zone.max_distance) &&
        parseFloat(zone.min_distance) >= parseFloat(zone.max_distance)
      ) {
        currentRecordErrors.push(
          `Min distace must be less than max distance in zone ${index + 1}`
        );
      }

      if (currentRecordErrors.length === 0) {
        this.state.zones
          .filter((currentRecord, currentIndex) => index !== currentIndex)
          .map((record, recIndex) => {
            if (
              !isEmpty(record.min_distance) &&
              !isEmpty(record.max_distance)
            ) {
              if (
                parseFloat(record.min_distance) <=
                  parseFloat(zone.min_distance) &&
                parseFloat(zone.min_distance) <= parseFloat(record.max_distance)
              ) {
                currentRecordErrors.push(
                  ` zone ${index + 1} min distance ${
                    zone.min_distance
                  } is matched with  zone  distance range ${
                    record.min_distance
                  } - ${record.max_distance} `
                );
              }
              if (
                parseFloat(record.min_distance) <=
                  parseFloat(zone.max_distance) &&
                parseFloat(zone.max_distance) <= parseFloat(record.max_distance)
              ) {
                currentRecordErrors.push(
                  ` zone ${index + 1} max distance ${
                    zone.max_distance
                  } is matched with  zone  distance range ${
                    record.min_distance
                  } - ${record.max_distance} `
                );
              }
            }
          });
      }
      errors = [].concat(errors, currentRecordErrors);
    });
    if (errors.length > 0) {
      this.setState({
        errors,
      });
    } else {
      this.setState({
        errors: [],
      });
      this.props.handleSave();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (_.isArray(props.zones) && !_.isEqual(props.zones, state.zones)) {
      const data = {};
      data.zones = props.zones;
      return data;
    }
    return {};
  }

  render() {
    const haveAccess = checkServiceExistance("BZC");
    return (
      <div className="marginTop10">
        <Row gutter={16}>
          <Col xs={24}>
            <Row gutter={16} className="marginTop10 textBold">
              <Col xs={8} className="alignCenter">
                {I18n.t("general.name")}
              </Col>
              <Col xs={6} className="alignCenter">
                {I18n.t("general.distance")} {I18n.t("general.from")} <br />
                (in {AppConfig.distance_unit})
              </Col>
              <Col xs={6} className="alignCenter">
                {I18n.t("general.distance")} {I18n.t("general.to")}
                <br />
                (in {AppConfig.distance_unit})
              </Col>
              <Col xs={4} className="alignCenter" />
            </Row>
            {this.state.zones.map((zone, index) => (
              <Row gutter={16} className="marginTop10" key={`zone${index}`}>
                <Col xs={8}>
                  <div>
                    <Input
                      value={zone.name}
                      size="small"
                      onChange={(e) =>
                        this.handleOnChange(index, "name", e.target.value)
                      }
                      placeholder={I18n.t("general.name")}
                      disabled={!haveAccess}
                    />
                  </div>
                </Col>
                <Col xs={6}>
                  <InputNumber
                    value={zone.min_distance}
                    size="small"
                    onChange={(e) =>
                      this.handleOnChange(index, "min_distance", e)
                    }
                    placeholder={`${I18n.t("general.from")} value`}
                    min={0}
                    precision={2}
                    className="width100Per"
                    disabled={!haveAccess}
                  />
                </Col>
                <Col xs={6}>
                  <InputNumber
                    value={zone.max_distance}
                    size="small"
                    onChange={(e) =>
                      this.handleOnChange(index, "max_distance", e)
                    }
                    placeholder={`${I18n.t("general.to")} value`}
                    min={0}
                    precision={2}
                    className="width100Per"
                    disabled={!haveAccess}
                  />
                </Col>
                {checkServiceExistance("BZD") && (
                  <Col xs={4}>
                    <Popconfirm
                      placement="left"
                      title={I18n.t("messages.delete_confirm")}
                      onConfirm={() =>
                        this.props.handleDelete(index, zone.id, zone.isNew)
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title={I18n.t("general.delete")}>
                      <DeleteIcon/>
                      &nbsp;&nbsp;&nbsp;
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            ))}
          </Col>
          {haveAccess && (
            <Col xs={24}>
              {FormButtons(
                this.state.inProgress,
                this.handleSave,
                this.clearForm,
                false,
                true,
                "default",
                I18n.t("general.save"),
                I18n.t("general.clear")
              )}
            </Col>
          )}
          <Col xs={24} className="marginTop20 alignLeft notes_text">
            <i>
              <Typography.Paragraph>
                {" "}
                <b>
                  {" "}
                  <span className="textBold">Note</span>{" "}
                </b>{" "}
                : {I18n.t("billing_zones.max_min_distance_note")}{" "}
              </Typography.Paragraph>
            </i>
          </Col>
          <Col xs={24}>{FormErrors(this.state.errors)}</Col>
        </Row>
      </div>
    );
  }
}

Form.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Form;
