/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Col, Row } from '../../common/UIComponents';
import {  alertMessage, customPasteSplit } from '../../common/Common';
import FormButtons from '../common/FormButtons';
import { saveMapping, getMappingDetails } from '../../api/LocationsApi';
import FormErrors from '../common/FormErrors';
import AppConfig from '../../config/AppConfig';
import expandIcon from '../../../assets/images/file-expand-icon.png';
import BaseModal from '../BaseModal';
import I18n from '../../common/I18n';


class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {},
      currentLocation: this.props.location,
      inProgress: false,
      errors: [],
      showMaxWindow: false,
      currentDay: {
        dow: '',
        zipcodes: [],
      },
      dowPincodes: {},
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.fewtchMappingDetails();
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = state;
    return location;
  }

  closeDayWindow = () => {
    this.setState({
      showMaxWindow: false,
      currentDay: {
        dow: '',
        zipcodes: [],
      },
    });
  }


  handleOnChange(element, value) {
    this.setState({ location: Object.assign({}, this.state.location, { [element]: value }) });
  }

  handleZipCodeChange = (element, value, dowInput) => {
    const dowPincodes = Object.assign({}, this.state.dowPincodes);
    dowPincodes[dowInput] = value;
    this.setState({ dowPincodes });
  }

  handleMaxWindowChange = (value) => {
    this.setState({
      currentDay: Object.assign({}, this.state.currentDay, { zipcodes: value }),
    });
  }

  handleDayWiseSave = () => {
    if (this.state.currentDay.dow) {
      const dowPincodes = Object.assign({}, this.state.dowPincodes);
      dowPincodes[this.state.currentDay.dow] = this.state.currentDay.zipcodes;
      this.setState({ dowPincodes }, () => {
        this.handleSave();
      });
    }
  }

  handleSave() {
    this.setState({ inProgress: true });
    const zipCodes = [];
    Object.keys(this.state.dowPincodes).forEach((dow) => {
      if (this.state.dowPincodes[dow].length > 0) {
        zipCodes.push({ dow, zip_codes: this.state.dowPincodes[dow].join(',') });
      }
    });
    const data = {
      warehouse_id: this.state.currentLocation.id,
      dow_zip_codes: zipCodes,
    };
    saveMapping(data)
      .then((result) => {
        if (result.success) {
          this.setState({ inProgress: false });
          alertMessage(I18n.t('messages.saved'));
          this.closeDayWindow();
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }

  fewtchMappingDetails = () => {
    getMappingDetails(this.state.currentLocation.id)
      .then((result) => {
        if (result.success) {
          const zipcodes = result.dow_zipcodes || [];
          const dowPincodes = {};
          zipcodes.forEach((dowPincode) => {
            dowPincodes[dowPincode.dow] = dowPincode.zip_codes.split(',');
          });

          this.setState({
            dowPincodes,
          });
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }


  showDayWindow = (dow) => {
    this.setState({
      showMaxWindow: true,
      currentDay: {
        dow,
        zipcodes: this.state.dowPincodes[dow],
      },
    });
  }


  render() {
    const { dowPincodes } = this.state;
    return (
      <div>
        { AppConfig.dows.map(dow => (
          <Row gutter={16} className="mappingRow">
            <Col sm={12} xs={12} md={4} lg={4}>
              <h4 className="marginTop10">{I18n.t(`days.${dow}`)} </h4>
            </Col>
            <Col sm={12} xs={12} md={20} lg={19}>
              <Row className="zipCodeBox">
                <Col xs={23} className="padding2">
                  <TagsInput
                    value={dowPincodes[dow] || []}
                    onChange={e => this.handleZipCodeChange('dowPincodes', e, dow)}
                    validationRegex={/^(?=[0-9]*$)(?:.{3}|.{4}|.{5})$/}
                    addKeys={[9, 13, 32]}
                    inputProps={{
                      placeholder: 'Add a zipcode',
                    }}
                    onlyUnique
                    addOnPaste
                    pasteSplit={customPasteSplit}
                    addOnBlur={ true }
                  />
                </Col>
                <Col xs={1} >
                  <img
                    src={expandIcon}
                    className="expandIcon"
                    onClick={() => this.showDayWindow(dow)}
                    alt={I18n.t('general.expand')}
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col xs={1} className="alignTotalCenter">
              <img src={expandIcon} className="marginTop10" style={{ width: 20, right: 5 }} onClick={() => this.showDayWindow(dow)} />
                  </Col> */}
          </Row>
        ))
        }


        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        <Row>
          <Col xs={24}>
            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)}
          </Col>
        </Row>

        {this.state.showMaxWindow &&

        <BaseModal
          title={
            `${this.state.currentDay ? `${I18n.t('location.mapping_heading')} on ${I18n.t(`days.${this.state.currentDay.dow}`)}` : ''}`
          }
          onCancel={() => this.closeDayWindow()}
          className="surveyModal"
          width='60%'
          style={{ top: 40, overflowY: 'auto' }}
        >
          <Fragment>
            <Row gutter={16} className="tagssettingRow">

              <Col sm={24} xs={24} md={24} lg={24}>
                <TagsInput
                  value={this.state.currentDay.zipcodes || []}
                  onChange={this.handleMaxWindowChange}
                  validationRegex={/^(?=[0-9]*$)(?:.{3}|.{4}|.{5})$/}
                  addKeys={[9, 13, 32]}
                  inputProps={{
                    placeholder: 'Add a zipcode',
                  }}
                  onlyUnique
                  addOnPaste
                  pasteSplit={customPasteSplit}
                  addOnBlur={ true }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {FormButtons(this.state.inProgress, this.handleDayWiseSave, this.closeDayWindow, I18n.t('general.cancel'), I18n.t('general.apply'))}
              </Col>
            </Row>
          </Fragment>
        </BaseModal>
        }
      </div>
    );
  }
}

LocationMap.propTypes = {
  location: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

LocationMap.defaultPropTypes = {
  location: {},
  isNew: true,
};

export default LocationMap;
