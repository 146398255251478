import { Popover, Tooltip } from "antd";
import React from "react";
import { ellipseText, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import ShipperConsigneeDetails from "../common/ShipperConsigneeDetails";
import ShipperLogDetails from "../common/ShipperLogDetails";
import moment from "moment";
import AppConfig from "../../config/AppConfig";
import Copyable from "../common/Copyable";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import { checkServiceExistance } from "../../helpers/common";
import { Link } from "../../common/UIComponents";
import PreplanOrderAccessorial from "../orders/PreplanOrderAccessorial";

class DriverLogList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "customer_order_number",
        title: <b>{I18n.t("driver_log_report.cid")}</b>,
        dataIndex: "customer_order_number",
        render: (text, record) => (
          <div>
            <CustomerOrderDetails
              order_no={text}
              order={{
                id: record.id,
                customer_order_number: text,
              }}
              showEdit={false}
            />
          </div>
        ),
        width: "110px",
        align : "left",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "consignee_name",
        title: <b>{I18n.t("driver_log_report.consignee_name")}</b>,
        dataIndex: "customer_first_name",
        render: (data, record) => (
          <div>
            {/* <Popover
              title={I18n.t("general.customer_details")}
              content={
                <ShipperConsigneeDetails
                  record={record}
                  key={`consigneeDetails${record.id}`}
                />
              }
            > */}
              {isEmpty(record.customer_first_name)
                ? "N/A"
                : <span>{record.customer_first_name}</span>}
            {/* </Popover> */}
          </div>
        ),
        width: "130px",
        align :"left"
      },
      {
        key: "company_name",
        title: <b>{I18n.t("general.company_name")}</b>,
        dataIndex: "company_name"
      },
      {
        key: "hawb",
        title: <b>{I18n.t("driver_log_report.hawb")}</b>,
        dataIndex: "hawb",
        render: (data, record) => (
          <OrderFieldDisplay
            data={{...record, id: record.id, customer_order_number: record.customer_order_number}}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
        width: "120px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "mawb",
        title: <b>{I18n.t("driver_log_report.mawb")}</b>,
        dataIndex: "mawb",
        render: (data, record) => (
          <OrderFieldDisplay
            data={{...record, id: record.id, customer_order_number: record.customer_order_number}}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
        width: "120px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "reference_1",
        title: <b>{I18n.t("driver_log_report.reference_1")}</b>,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1" showPopupContainer={true}/>
        ),
        width: "110px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "reference_2",
        title: <b>{I18n.t("driver_log_report.reference_2")}</b>,
        dataIndex: "reference_2",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2" showPopupContainer={true}/>
        ),
        width: "100px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "type_of_order",
        title: <b>{I18n.t("driver_log_report.movetype")}</b>,
        dataIndex: "type_of_order",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "pieces",
        title: <b>{I18n.t("driver_log_report.pieces")}</b>,
        dataIndex: "quantity",
        render: (data, record) => <span>{data}</span>,
        width: "55px",
        align :"center",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "pallet",
        title: <b>{I18n.t("driver_log_report.pallets")}</b>,
        dataIndex: "pallets",
        render: (data, record) => <span>{data}</span>,
        width: "55px",
        align :"center"
      },
      {
        key: "dim_weight",
        title: <b>{I18n.t("driver_log_report.dim_weight")}</b>,
        dataIndex: "dim_weight",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        align :"center",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "weight",
        title: <b>{I18n.t("driver_log_report.weight")}</b>,
        dataIndex: "weight",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        align :"center",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "level_of_service",
        title: <b>{I18n.t("driver_log_report.level_of_service")}</b>,
        dataIndex: "levelOfService",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      // {
      //   key: "pod",
      //   title: <b>{I18n.t("driver_log_report.pod")}</b>,
      //   dataIndex: "pod",
      //   render: (data, record) => <span>{data}</span>,
      //   width: "90px"
      // },
      {
        key: "wait_time",
        title: <b>{I18n.t("driver_log_report.wait_time")}</b>,
        dataIndex: "wait_time",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "signer_name",
        title: <b>{I18n.t("driver_log_report.signer_name")}</b>,
        dataIndex: "signer_name",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "signed_at",
        title: <b>{I18n.t("driver_log_report.signed_at")}</b>,
        dataIndex: "signed_at",
        render: (data, record) => (
          <span>{data ? moment(data).format(AppConfig.dateTimeFormat) : ""}</span>
        ),
        width: "180px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      // {
      //   key: "company_name",
      //   title: <b>{I18n.t("driver_log_report.company_name")}</b>,
      //   dataIndex: "company_name",
      //   render: (data, record) => <span>{data}</span>,
      //   width: "90px"
      // },
      // {
      //   key: "shipper_name",
      //   title: <b>{I18n.t("driver_log_report.shipper_names")}</b>,
      //   dataIndex: "shipper_name",
      //   ellipses: true,
      //   render: (data, record) => (
      //     <div>
      //       <Popover
      //         title={I18n.t("customer_log_report.shipper_details")}
      //         content={
      //           <ShipperLogDetails
      //             record={record}
      //             key={`consigneeDetails${record.id}`}
      //           />
      //         }
      //       >
      //          <span>{ellipseText(record.shipper_name, null)}</span>
      //       </Popover>
      //     </div>
      //   ),
      //   // width: "100px"
      // },
      {
        key:"account_name",
        title : <b>{I18n.t("driver_log_report.account_name")}</b>,
        dataIndex:"account_name",
        render:(data,record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key:"account_code",
        title : <b>{I18n.t("driver_log_report.account_code")}</b>,
        dataIndex:"account_code",
        render:(data,record) => <span>{data}</span>,
        width: "110px"
      },
      {
        key: "address_type",
        title: <b>{I18n.t("driver_log_report.address_type")}</b>,
        dataIndex: "address_type",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "driver_name",
        title: <b>{I18n.t("driver_log_report.driver_name")}</b>,
        dataIndex: "driver_name",
        render: (data, record) => <span>{data}</span>,
        width: "110px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "employee_code",
        title: <b>{I18n.t("driver_log_report.employee_code")}</b>,
        dataIndex: "driver_code",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "status",
        title: <b>{I18n.t("driver_log_report.dispcomplete")}</b>,
        dataIndex: "status",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "type_of_location",
        title: <b>{I18n.t("driver_log_report.pdtype")}</b>,
        dataIndex: "type_of_order",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "effective_service_date",
        title: <b>{I18n.t("driver_log_report.effective_service_date")}</b>,
        dataIndex: "appointment_date",
        render: (data, record) => (
        <span>{data ? moment(data).format(AppConfig.dateTimeFormat) : ""}</span>
        ),
        width : "170px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "warehouse_code",
        title: <b>{I18n.t("driver_log_report.warehouse_code")}</b>,
        dataIndex: "warehouse_code",
        render: (data, record) => <span>{data}</span>,
        width : "90px"
      },
      {
        key: "ship_count",
        title: <b>{I18n.t("driver_log_report.items_count")}</b>,
        dataIndex: "ship_count",
        render: (data, record) => <span>{data}</span>,
        width : "90px",
        align :"center"
      },
      {
        key: "type_of_locations",
        title: <b>{I18n.t("driver_log_report.stop_type")}</b>,
        dataIndex: "stop_type",
        render: (data, record) => <span>{data}</span>,
        width : "90px"
      },
      {
        key: "actual_start_time",
        title: <b>{I18n.t("driver_log_report.time_in")}</b>,
        dataIndex: "time_in",
        render: (data, record) => <span>{data}</span>,
        width : "150px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "actual_end_time",
        title: <b>{I18n.t("driver_log_report.time_out")}</b>,
        dataIndex: "time_out",
        render: (data, record) => <span>{data}</span>,
        width : "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      // {
      //   key: "special",
      //   title: <b>{I18n.t("driver_log_report.special")}</b>,
      //   dataIndex: "special",
      //   render: (data, record) => <span>{data}</span>,
      //   width : "90px"
      // },
      {
        key: "accessorials",
        title: <b>{I18n.t("driver_log_report.accessorials")}</b>,
        dataIndex: "accessorials",
        render: (data, record, index) => data && (_.isString(data)) && data !== '-NA-' ? <PreplanOrderAccessorial
        record={
          {
            ...record,
            order_accessorials: _.split(data, ',').map(rec => ({ accessorial_code: rec})),
          }
        }
        placement={
          index !== 0 && index === props.data.length - 1
            ? "topRight"
            : "left"
        }
        showPopupContainer={false}
      /> : '-NA-',
        width : "100px"
      },

    ];
  }
}

export default DriverLogList;
