import {
  Button,
  Col,
  Input,
  Row,
  Form,
  FormItem,
  Popconfirm,
  Icon,
  Empty,
  Tooltip,
} from "../../common/UIComponents";
import React, { Fragment, useContext } from "react";
import _ from "lodash";
import { fetchAccountConfigs } from "../../api/Account";
import userStore from "../../stores/UserStore";
import BaseList from "../BaseList";
import MaterialTimePicker from "../orders/MaterialTimePicker";
import { getDisabledHours, isBetweenTimes, momentTime } from "../../helpers/date_functions";
import FormButtons from "./FormButtons";
import { isEmpty,alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import moment from "moment";
import { saveLocation } from "../../api/LocationsApi";
import { getDisabledHoursFromStartTime } from "../../helpers/orders";
import FormErrors from "./FormErrors";
import DeleteIcon from "./DeleteIcon";
class ConfigurationForm extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      isMilitaryTime: false,
      location: {},
      organizationSettings: {},
      inProgress: false,
      showError: false,
      Errors: [],
    };
  }

  sortSlotsByTime (slots) {
    return slots.sort((a, b) => {
      const startA = moment(a.start_time, "HH:mm");
      const startB = moment(b.start_time, "HH:mm");
      const endA = moment(a.end_time, "HH:mm");
      const endB = moment(b.end_time, "HH:mm");

      if (startA.isSame(startB)) {
        return endA.isBefore(endB) ? -1 : 1;
      }

    return startA.isBefore(startB) ? -1 : 1;
  });
  }

  componentDidMount = () => {
    let isMilitaryTime = this.props.organizationSettings.is_military_time == "true";
    const sortedSlots = this.props.location?.slot_details
      ? this.sortSlotsByTime(this.props.location.slot_details)
      : [];
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime,
      location: this.props.location ? Object.assign({}, this.props.location, {
        order_capacity: this.props.location?.order_capacity ? parseInt(this.props.location.order_capacity) : 0,
        slot_details: sortedSlots,
      }) : {}
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
      });
    }
    // on change of slot details sort the slot details
    if (!_.isEqual(prevState.location.slot_details, this.state.location.slot_details)) {
      const { location } = this.state;
      const sortedSlots = this.props.location?.slot_details ? this.sortSlotsByTime(this.props.location.slot_details) : [];
      this.setState({
        location: Object.assign({}, location, {
          slot_details : sortedSlots,
        }),
      });
    }
  }

  // find start time and end time is overlapping with other slot
  findIsBetweenSlots = (slots = [], index = -1) => {
    if(slots && slots[index]){
      const slotsToCheck = slots.filter((slot , slotIndex) => slotIndex < index);
      let errorValue = false;
      if(slotsToCheck.length > 0){
        const slotObject = slots[index];
        slotsToCheck.forEach((slot , slotIndex)=> {
          const hasError = isBetweenTimes(slot.start_time, slot.end_time, slotObject.start_time, "HH:mm");
          if(hasError){
            errorValue = true;
          }
        })
      }
      return errorValue;
    }
    return false;
  }

  handleSave = (isDeletedCalled = false) => {
    const { isMilitaryTime } = this.state;
    this.setState({ inProgress: true });
    const { location } = this.state;
    const { slot_details = [] } = location;
    const startTimeEmpty = _.findIndex(slot_details, { start_time: null }) >= 0;
    const endTimeEmpty = _.findIndex(slot_details, { end_time: null }) >= 0;
    let errors = [];
    let totalSlotCapacity = 0;
    if (slot_details && slot_details.length > 0) {
      slot_details.map((slot) => {
        totalSlotCapacity = totalSlotCapacity + Number(slot.order_capacity);
      });
    }

    if (totalSlotCapacity > 0) {
      if (totalSlotCapacity > location.order_capacity) {
        errors.push(
          "Total slot capacity should not greater than order capacity per day."
        );
      }
    }
    if (startTimeEmpty || endTimeEmpty) {
      errors.push("Please fill all required fields.");
      this.setState({
        showError: true,
      });
    } else {
      if (slot_details.length > 0) {
        slot_details.forEach((slot, index) => {
          const hasError = this.findIsBetweenSlots(slot_details, index);
          if (hasError) {
            errors.push(I18n.t("appointmentForm.over_lapping", {
              startValue: moment(slot.start_time, "HH:mm").format(isMilitaryTime ? "HH:mm" : "hh:mm A"),
              endValue: moment(slot.end_time, "HH:mm").format(isMilitaryTime ? "HH:mm" : "hh:mm A")
            }));
          }
        });
      }
    }

    const invalidSlotTimeErrors = this.checkInvalidSlotTime();
    if (invalidSlotTimeErrors.length) {
      errors = [ ...errors, ...invalidSlotTimeErrors ];
    }
    if (location.start_time === "Invalid date") {
      errors.push("Invalid Delivery Start time.");
    }
    if (location.end_time === "Invalid date") {
      errors.push("Invalid Delivery End time.");
    }

    if (invalidSlotTimeErrors.length === 0 && errors.length === 0) {
      const payload = {
        ...location,
        order_capacity: location.order_capacity || 0,
        slot_details: location.slot_details,
        start_time: location.start_time ? location.start_time : moment("08:00", "HH:mm").format("HH:mm"),
        end_time: location.end_time ? location.end_time : moment("20:00", "HH:mm").format("HH:mm"),
      };
      if(userStore.superAdmin()){
        payload.org_id = location.organization_id;
      }
      saveLocation(false, payload).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            showError: false,
          });
          if (isDeletedCalled === false) {
            this.props.onSave(I18n.t("messages.saved"));
          } else {
            alertMessage(I18n.t("errors.deleted_successfully", { field: "Slot" }), "success", 10);
          }
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });

    } else {
      this.setState({
        Errors: errors,
        inProgress: false,
      });
    }
  };

  handleOnSlotChange = (index, element, value) => {
    const { location } = this.state;
    const slot_details = [...location.slot_details];
    slot_details[index][element] = value;
    this.setState({
      location: Object.assign({}, location, {
        slot_details,
      }),
      Errors: [],
    });
  };

  handleOnChange = (element, value) => {
    const { location } = this.state;
    this.setState({
      location: Object.assign({}, location, {
        [element]: value,
      }),
    });
  };

  checkInvalidSlotTime = () => {
    const { location } = this.state;
    let errors = []
     let invalidStartTimeIndexes = [];
     let invalidEndTimeIndexes = [];
     if (location && location.slot_details && location.slot_details.length) {
      location.slot_details.map((slot, index) => {
        if (slot.start_time === "Invalid date") {
          invalidStartTimeIndexes.push(index + 1);
        }
        if (slot.end_time === "Invalid date") {
          invalidEndTimeIndexes.push(index + 1);
        }
      });
      if(invalidStartTimeIndexes.length > 0 || invalidEndTimeIndexes.length > 0){
        if (invalidStartTimeIndexes.length > 0) {
          errors.push(
            `Invalid From Time at slot no: ${invalidStartTimeIndexes.join(
              ", "
            )}`
          );
        }
        if (invalidEndTimeIndexes.length > 0) {
          errors.push(
            `Invalid To Time at slot no: ${invalidEndTimeIndexes.join(
              ", "
            )}`
          );
        }
      }
       // check the if last slot end time is less than start time
       if (errors.length === 0) {
         const lastSlot = location.slot_details[ location.slot_details.length - 1 ];
         const isMilitaryTime = this.state.isMilitaryTime;
         if (moment(lastSlot.end_time, isMilitaryTime ? "HH:mm" : "hh:mm A").isBefore(moment(lastSlot.start_time, isMilitaryTime ? "HH:mm" : "hh:mm A"))) {
           errors.push('Invalid slot time. Last slot end time should be greater than start time.');
         }
       }
     }
     this.setState({
       Errors: errors 
     });
    
     return errors;
  }

  addSlot = () => {
    const { location } = this.state;
    let errors = [];

    if (!location.slot_details) {
      location.slot_details = [];
    }

    const invalidSlotTimeErrors = this.checkInvalidSlotTime();

    if (invalidSlotTimeErrors.length === 0) {
      location.slot_details.push({
        start_time: null,
        end_time: null,
        order_capacity: 0,
      });
    }

    this.setState({
      location,
    });
  };

  deleteSlot = (index) => {
    const { location } = this.state;
    if (location.slot_details.length > 0 && index >= 0) {
      // const deletedConfig = slot_details[index] ? slot_details[index] : {};
      location.slot_details.splice(index, 1);

      this.setState({
        location,
        Errors: [],
      });
      this.handleSave(true);
    }
  };

  _computeDisabledHours (location, currentSlotIndex) {
    const disabledHours = [];
    const prevSlots = location.slot_details.slice(0, currentSlotIndex);

    // Disable hours before start time
    const startTime = location.start_time ? moment(location.start_time, "HH:mm") : moment("08:00", "HH:mm");
    for (let hour = 0; hour < startTime.hours(); hour++) {
      disabledHours.push(hour);
    }

    // Disable hours after end time
    // const endTime = moment(location.end_time, "HH:mm");
    const endTime = location.end_time ? moment(location.end_time, "HH:mm") : moment("20:00", "HH:mm");
    for (let hour = endTime.hours() + 1; hour <= 24; hour++) {
      disabledHours.push(hour);
    }

    // Disable hours in between slots
    prevSlots.forEach((slot) => {
      const start = moment(slot.start_time, "HH:mm");
      const end = moment(slot.end_time, "HH:mm");
      for (let hour = start.hours(); hour < end.hours(); hour++) {
        disabledHours.push(hour);
      }
    });

    // Combine and sort the disabled hours array
    const combinedDisabledHours = [ ...new Set(disabledHours) ];
    combinedDisabledHours.sort((a, b) => a - b);

    return combinedDisabledHours;
  }

  _computeDisabledMinutes = (location, slotIndex, currentTime) => {
    const { start_time, end_time, slot_details } = location;
    const disabledMinutes = [];

    const currentHour = moment(currentTime).format("HH");
    const currentMinute = moment(currentTime).format("mm");

    // Disable minutes before start_time and after end_time
    const whstartTimeMoment = moment(start_time, "HH:mm");
    const whendTimeMoment = moment(end_time, "HH:mm");

    if (currentHour === whstartTimeMoment.format("HH")) {
      disabledMinutes.push(
        ...Array(parseInt(whstartTimeMoment.format("mm"))).keys()
      );
    } 
    if (currentHour === whendTimeMoment.format("HH")) {
      disabledMinutes.push(
        ...[...Array(60).keys()].filter(min => min > parseInt(whendTimeMoment.format("mm"))) 
      )
    }

    const prevSlots = slot_details.slice(0, slotIndex);
    prevSlots.forEach((slot) => {
      const start = moment(slot.start_time, "HH:mm");
      const end = moment(slot.end_time, "HH:mm");
      if (currentHour === start.format("HH")) {
        disabledMinutes.push(
          ...Array(parseInt(start.format("mm"))).filter(min => min < parseInt(currentMinute)).keys() 
        );
      }
      if (currentHour === end.format("HH")) {
        disabledMinutes.push(
             ...[...Array(60).keys()].filter(min => min < parseInt(end.format("mm")))
        );
      }
    });

    return disabledMinutes;
  };

  _renderSlots = () => {
    const { isMilitaryTime, location } = this.state;
    const { slot_details } = location;
    return (
      <Row>
        <Col xs={24}>
          {slot_details && slot_details.length > 0 ? (
            <Fragment>
              <Row>
                <Col>
                  <Row className="marginTop10">
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div
                        style={{ fontWeight: 700 }}
                        className="marginBottom15"
                      >
                        {I18n.t("configurations.slots")}:
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row>
                    <Col xs={24} md={6}>
                      <span style={{ fontWeight: 700 }}>
                        <sup className="textRed">*</sup>
                        {I18n.t("configurations.from_time")}
                      </span>
                    </Col>
                    <Col xs={24} md={6}>
                      <span style={{ fontWeight: 700 }}>
                        <sup className="textRed">*</sup>
                        {I18n.t("configurations.to_time")}
                      </span>
                    </Col>
                    <Col xs={24} md={6}>
                      <span style={{ fontWeight: 700 }}>
                        <sup className="textRed">*</sup>
                        {I18n.t("configurations.capacity")}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  {slot_details.map((slot, index) => {
                    const startTime = slot.start_time
                      ? moment(slot.start_time, "HH:mm")
                      : null;
                    const endTime = slot.end_time
                      ? moment(slot.end_time, "HH:mm")
                      : null;
                    return (
                      <Row
                        className="marginTop10 marginBottom10"
                        key={`slot_details${index}`}
                      >
                        <Col xs={24} md={6}>
                          <MaterialTimePicker
                            value={startTime}
                            onChange={(e) => {
                              const currentValue = e
                                ? moment(e).format("HH:mm")
                                : null;
                              this.handleOnSlotChange(
                                index,
                                "start_time",
                                currentValue
                              );
                            }}
                            style={{ width: "70%" }}
                            computeDisabledHours={ () =>
                              this._computeDisabledHours(location, index)
                            }
                            computeDisabledMinutes={ () =>
                              this._computeDisabledMinutes(location, index, startTime)
                            }
                            isMilitaryTime={isMilitaryTime}
                          />
                          <Col>
                            {isEmpty(startTime) && this.state.showError && (
                              <span className="textRed font500 marginTop5">
                                {"Please Select!"}
                              </span>
                            )}
                          </Col>
                        </Col>
                        <Col xs={24} md={6}>
                          <Col>
                            <MaterialTimePicker
                              value={endTime}
                              onChange={(e) => {
                                const currentValue = e
                                  ? moment(e).format("HH:mm")
                                  : null;
                                this.handleOnSlotChange(
                                  index,
                                  "end_time",
                                  currentValue
                                );
                              }}
                              computeDisabledHours={ () =>
                               this._computeDisabledHours(location, index)
                              }
                              computeDisabledMinutes={ () =>
                                this._computeDisabledMinutes(location, index, endTime)
                              }
                              style={{ width: "70%" }}
                              isMilitaryTime={isMilitaryTime}
                            />
                          </Col>
                          <Col>
                            {isEmpty(endTime) && this.state.showError && (
                              <span className="textRed font500 marginTop5">
                                {"Please Select!"}
                              </span>
                            )}
                          </Col>
                        </Col>
                        <Col xs={24} md={6}>
                          <Input
                            value={slot.order_capacity}
                            onChange={(e) =>
                              this.handleOnSlotChange(
                                index,
                                "order_capacity",
                                parseInt(e.target.value)
                              )
                            }
                            min={0}
                            type="number"
                            style={{ width: "70%" }}
                          />
                        </Col>
                        <Col
                          xs={24}
                          md={3}
                          className="alignLeft"
                          style={{ height: 23 }}
                        >
                          <Popconfirm
                            placement="left"
                            title={I18n.t("messages.confirm")}
                            onConfirm={() => this.deleteSlot(index)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip title="Delete Slot">
                              {/* <Icon
                                type="delete"
                                className="textRed textBold fontSize18"
                              /> */}
                              <DeleteIcon className="textBold fontSize18"/>
                              &nbsp;&nbsp;
                            </Tooltip>
                          </Popconfirm>
                          {slot_details.length - 1 === index &&
                            !isEmpty(startTime) &&
                            !isEmpty(endTime) && (
                              <Tooltip title="Add Slot">
                                <Icon
                                  type="plus-circle"
                                  onClick={this.addSlot}
                                  size="small"
                                  className="marginLeft10 fontSize18"
                                />
                              </Tooltip>
                            )}
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="settingHeader"
                style={{ marginBottom: 20, paddingBottom: 12 }}
              >
                <Row>
                  <Col xs={12}>
                    <span className="textBold">
                      {I18n.t("configurations.slot_config")}
                    </span>
                  </Col>
                  <Col xs={12} className="alignRight">
                    <Tooltip title={I18n.t("general.add")}>
                      <Button
                        icon="plus"
                        type="primary"
                        onClick={this.addSlot}
                        size="small"
                      >
                        {I18n.t("general.add")}
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </div>
              <Empty
                description={
                  <Fragment>
                    <h4>
                      No Slots Configuration is done.{" "}
                      <Button type="link" onClick={this.addSlot}>
                        Click here to configure
                      </Button>
                    </h4>
                  </Fragment>
                }
              />
            </Fragment>
          )}
        </Col>
      </Row>
    );
  };
  render = () => {
    const { isMilitaryTime, location } = this.state;

    const {organizationSettings} = this.props;
    const { route_delivery_start_time = "" , route_delivery_end_time = "" } = organizationSettings;

    return (
      <Row>
        <Col>
          <Row>
            <div className="settingHeader">
              <span className="textBold">
                {I18n.t("configurations.route_configuration")}
              </span>
            </div>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  <sup className="textRed">*</sup>
                  {I18n.t("configurations.route_delivery_start_time_lable")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div style={{ display: "flex" }}>
                    <MaterialTimePicker
                      style={{ width: "70%" }}
                      value={
                        location.start_time
                          ? moment(location.start_time, "HH:mm")
                          : moment(route_delivery_start_time, "HH:mm")
                      }
                      onChange={(e) => {
                        const currentValue = e
                          ? moment(e).format("HH:mm")
                          : null;
                        this.handleOnChange("start_time", currentValue);
                      }}
                      computeDisabledHours={() => []}
                      computeDisabledMinutes={() => []}
                      isMilitaryTime={isMilitaryTime}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} xs={12} md={12} lg={12}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  <sup className="textRed">*</sup>
                  {I18n.t("configurations.route_delivery_end_time_lable")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div style={{ display: "flex" }}>
                    <MaterialTimePicker
                      style={{ width: "70%" }}
                      value={
                        location.end_time
                          ? moment(location.end_time, "HH:mm")
                          : moment(route_delivery_end_time, "HH:mm")
                      }
                      onChange={(e) => {
                        const currentValue = e
                          ? moment(e).format("HH:mm")
                          : null;
                        this.handleOnChange("end_time", currentValue);
                      }}
                      computeDisabledHours={() => []}
                      computeDisabledMinutes={() => []}
                      isMilitaryTime={isMilitaryTime}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="marginTop10">
            <div className="settingHeader">
              <span className="textBold">
                {I18n.t("configurations.capacity_configuration")}
              </span>
            </div>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  <sup className="textRed">*</sup>
                  {I18n.t("configurations.capacity_perday")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div style={{ display: "flex" }}>
                    <Input
                      type="number"
                      min={0}
                      value={location.order_capacity}
                      onChange={(e) =>
                        this.handleOnChange("order_capacity", parseInt(e.target.value))
                      }
                      style={{ width: "70%" }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>{this._renderSlots()}</Col>
        <Col>
          <Row>{FormErrors(this.state.Errors)}</Row>
          <Row>{FormButtons(this.state.inProgress, () => this.handleSave(false), this.props.onCancel)}</Row>
        </Col>
      </Row>
    );
  };
}

export default ConfigurationForm;


