import React, { Component } from "react";
import PropTypes from "prop-types";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { Row, Col, Button } from "../../common/UIComponents";
class MenifestOrderItemsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    if (props) {
      this.setColumns(props);
    }
  }
  setColumns = (props) => {
    this.columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => index + 1,
        width: 35,
      },
      {
        title: "Item Name",
        dataIndex: "item_name",
        key: "item_name",
        // className: "width15Per",
      },
      {
        title: "Pieces",
        dataIndex: "pieces",
        key: "pieces",
        // className: "width15Per",
      },

      {
        title: "Dim.wt",
        dataIndex: "dim_weight",
        key: "dim_weight",
        // className: "width15Per",
      },
      {
        key: "dim_factor",
        title: "Dim Factor",
        dataIndex: "dim_factor",
      },
      {
        title: "Model Number",
        key: "model_number",
        dataIndex: "model_number",
      },
      {
        title: "Serial Number",
        key: "serial_number",
        dataIndex: "serial_number",
        // className: "width15Per",
      },
      {
        title: "Weight",
        key: "weight",
        dataIndex: "weight",
        render: (data, record) => {
          return `${data} Lbs`;
        },
      },
      {
        title: "Warehouse Dock",
        key: "wh_dock",
        dataIndex: "wh_dock",
      },
      {
        title: "Dimensions(L*W*H)",
        key: "item_width",
        render: (data, record) => {
          return `${record.item_length} * ${record.item_width} * ${record.item_height} ( Units : in )`;
        },
      },
    ];
    if (!_.isEmpty(props.displayConfiguration)) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const dispatch_manifest_file_include_items_columns =
        userConfiguration.dispatch_manifest_file_include_items_columns ||
        orgConfiguration.dispatch_manifest_file_include_items_columns;
      const columns = [];
      if (
        typeof dispatch_manifest_file_include_items_columns !== undefined &&
        Array.isArray(dispatch_manifest_file_include_items_columns)
      ) {
        const initialColumns = this.columns.filter((col) =>
          ["index"].includes(col.key)
        );
        if (initialColumns.length > 0) {
          columns.push(...initialColumns);
        }

        dispatch_manifest_file_include_items_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "actions");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.displayConfiguration,
        this.props.displayConfiguration
      )
    ) {
      this.setColumns(this.props);
    }
  }
}
export default MenifestOrderItemsList;
