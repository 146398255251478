import React, { useEffect, useState } from "react";
import { Col, Icon, Input, Row } from "antd";
import PropTypes from "prop-types";

const InlineEdit = ({
  value,
  onSave,
  onCancel,
  isEditing,
  onEdit,
  setNewAmount,
  updatePaidAmount,
  isFailed,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
    if (typeof setNewAmount === "function") {
      setNewAmount(value);
    }
  }, [value]);

  useEffect(() => {
    if (isFailed) {
      setCurrentValue(value);
    }
  }, [isFailed]);

  const handleSave = () => {
    onSave(currentValue);
    updatePaidAmount();
  };

  const handleCancel = () => {
    onCancel();
    setNewAmount(0);
    setCurrentValue(value);
  };

  return isEditing ? (
    <Row gutter={8} type="flex" justify="center">
      <Col className="disableArrows">
        <Input
          value={currentValue}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            setNewAmount(e.target.value);
          }}
          size="small"
          type="number"
          style={{ width: 100 }}
          min={0}
          className={!_.isEmpty(currentValue) ? "" : "custom-error-input"}
        />
      </Col>
      <Col>
        {!_.isEmpty(currentValue) ? (
          <Icon type="save" theme="twoTone" onClick={handleSave} />
        ) : (
          <Icon
            type="save"
            theme="twoTone"
            style={{ cursor: "not-allowed", opacity: 0.5 }}
          />
        )}
        &nbsp;&nbsp;&nbsp;
        <Icon type="close" onClick={handleCancel} />
      </Col>
    </Row>
  ) : (
    <>
      <Icon
        type="edit"
        theme="twoTone"
        onClick={onEdit}
        style={{
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      />
    </>
  );
};

InlineEdit.defaultProps = {
  value: 0,
  onSave: () => {},
  onCancel: () => {},
  isEditing: false,
  onEdit: () => {},
  setNewAmount: () => {},
  updatePaidAmount: () => {},
  isFailed: false,
};

InlineEdit.propTypes = {
  value: PropTypes.number || PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func,
  setNewAmount: PropTypes.func,
  updatePaidAmount: PropTypes.func,
  isFailed: PropTypes.bool,
};

export default InlineEdit;
