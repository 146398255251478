import React, { Fragment, useEffect, useState, useContext } from 'react';
// import _ from 'lodash';
import PropTypes from "prop-types";
import { Button, Col, Icon, Modal,  Row,  Steps, Tabs, } from 'antd';
import { QuoteDetailsForm } from './QuoteOrderDetails';
import {
  Collapse,
} from "../../common/UIComponents";
import _,{ isEmpty } from 'lodash';
import { getValueFromArrayOfObjects } from '../../helpers/array_functions';
import { fetchAccountShortLos } from '../../api/Los';
import I18n from '../../common/I18n';
import { loadGoogleMaps } from '../../utils/Utils';
import AppConfig from '../../config/AppConfig';
import QuoteAccessorial from './QuoteAccessorial';
import userStore from '../../stores/UserStore';
import { fetchAccountConfigs, fetchAccountDims } from '../../api/Account';
import QuoteOrderItemsList from './QuoteOrderItemsList';
import {  QuotesApis } from '../../api/QuotesApi';
import { alertMessage } from '../../common/Common';
import QuoteOrderConsigneeDetails from './QuoteOrderConsigneeDetails';
import moment from 'moment';
import { setAppointmentDetails } from '../../helpers/quotes';
import ApproveQuote from './ApproveQuote';
import { OrgContext } from '../../context/OrgContext';
import { verifyBillingZone } from '../../api/OrdersApi';
import { OrderConfigContext } from '../../context/OrderConfigContext';
import { renderAlertMessage } from '../../helpers/common';

const { Panel } = Collapse;
const { Step } = Steps;
const { TabPane } = Tabs;


function QuoteForm (props) {
  const { warehouses, accounts, currentQuote, activeRecordId , setActiveRecordId,setShowQuoteForm, setRevisions ,openQuoteView, validateQuote , refreshAccounts,targetAccounts,refreshTargetAccounts,showQuoteDetails } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errors, setErrors ] = useState([])
  const [accountConfig , setAccountConfig] = useState({});
  const stepTitles = [I18n.t("quotes.details"), I18n.t("general.item_information", {item : I18n.t("general.shipment")}), I18n.t('general.accessorial'), I18n.t("order.item_details")];
  const [ currentStep, setCurrentStep ] = useState(0);
  const [ clickedSave, setClickedSave ] = useState(false);
  const [ accountDim, setAccountDim ] = useState(null);

  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === 'true'
  const [quoteDetailsOnSave,setQuoteDetailsOnSave] = useState([])
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);
  

  const obtainSaveDetails = (data) => {
    if(!isEmpty(data)) {
      setQuoteDetailsOnSave(data)
    }else{
      setQuoteDetailsOnSave([])
    }
  }

  function showUnsavedChangesError (action = "nextStep") {
    Modal.error({
      title: I18n.t("general.unsaved_changes"),
      content: action == 'nextStep' ? I18n.t("messages.invalid_next_step") : I18n.t("messages.invalid_save"),
    });
  }


  // on change of current set check if there are unsaved changes
  const checkForUnsavedChanges = () => {
      showUnsavedChangesError();
      setClickedSave(false);
  }

  const checkIfValidStepAcess = (stepNum) => {
    if(unsavedChanges) {
      checkForUnsavedChanges();
      return false;
    }

    switch (stepNum) {
      case 0:
        return true;
      case 1:
        if (activeRecordId) return true;
        else return false;
      case 2:
        if (!_.isEmpty(currentQuote.delivery_location_id) && !_.isEmpty(currentQuote.pickup_location_id) || (!_.isEmpty(currentQuote.delivery_location_details) && !_.isEmpty(currentQuote.pickup_location_details))) return true;
        else return false;
      case 3:
        if (!_.isEmpty(currentQuote.delivery_location_id) && !_.isEmpty(currentQuote.pickup_location_id) || (!_.isEmpty(currentQuote.delivery_location_details) && !_.isEmpty(currentQuote.pickup_location_details))) return true;
        else return false;
      default:
        return false;
    }
  }

  const verifyZipCode = (zip) => {
    const orgId = userStore.getStateValue("selectedOrg");
    const accountId = quoteFormData.account_id;
    const zipCode = !isEmpty(zip) ? zip : null;
    const zipCodeLength = !isEmpty(zipCode) ? zipCode.length : 0;
    if (zipCodeLength >= 5) {
      verifyBillingZone(orgId, accountId, zipCode).then((result) => {
        if (result.success && !_.isEmpty(result.data.warnings)) {
          alertMessage(result.data.warnings, "warning", 5);
        } else {
          null;
        }
      });
    }
  };

  useEffect(() => {
    verifyZipCode();
  }, [ quoteFormData?.pickup_params?.l_address?.zipcode, quoteFormData?.drop_params?.l_address?.zipcode]);


  const defaultAppointment = [
      {
        s_no: 1,
        // item_preference: "",
        item_option: ['ANY'],
        has_expedite: false,
        item_preference: moment(),


      } ];
  const [ quoteFormData, setQuoteFormData ] = useState({
    pickup_params: {
      preferences: defaultAppointment
    },
    drop_params: {
      preferences: defaultAppointment
    },
    account_id: "",
    target_account_id: "",
    wh_location_id: "",
    target_wh_location_id: "",
    account_code: "",
    target_account_code: "",
    warehouse_code : "",
    target_warehouse_code : "",
    type_of_order : "D",
  });
  const [ levelOfServices, setLevelOfServices ] = useState([]);
  const [targetLevelOfServices, setTargetLevelOfServices] = useState([]);
  const [ isMapLoaded, setIsMapLoaded ] = useState(false);
  const [ unsavedChanges, setUnsavedChanges ] = useState(false);

  const getLos = (accountCode) => {
    const { account_code } = quoteFormData;
    const accountIndex = _.findIndex(accounts, {
      code: account_code,
    });
    if (accountIndex >= 0) {
      const account = accounts[ accountIndex ];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(accounts, "id", account.primary_account_id, "code");
      }
    }
    if (!isEmpty(accountCode)) {
      setIsLoading(true);
      fetchAccountShortLos(accountCode, quoteFormData.type_of_order).then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || [];
          const updatedLevelOfServices = [
            ...levelOfServices,
            {
              name: "None",
              code: "NONE",
              duration: "0",
              id: "NONE",
            },
          ]
          const newFormData = _.cloneDeep(quoteFormData)
          if(!isEmpty(quoteFormData.level_of_service) && _.findIndex(updatedLevelOfServices, { code : quoteFormData.level_of_service}) < 0){
            newFormData.level_of_service = '' 
          }
          if(!isEmpty(quoteFormData.pickup_level_of_service) && _.findIndex(updatedLevelOfServices, { code : quoteFormData.pickup_level_of_service}) < 0 ){
            newFormData.pickup_level_of_service = '' 
          }
          if(!isEmpty(quoteFormData.delivery_level_of_service) && _.findIndex(updatedLevelOfServices, { code : quoteFormData.delivery_level_of_service}) < 0){
            newFormData.delivery_level_of_service = '' 
          }
          setQuoteFormData(newFormData)
          setLevelOfServices(updatedLevelOfServices);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  //get target los for the target drop down los
  const getTargetlos = (accountCode) => {
    const { target_account_code } = quoteFormData;
    const accountIndex = _.findIndex(targetAccounts, {
      code: target_account_code,
    });
    if (accountIndex >= 0) {
      const account = targetAccounts[ accountIndex ];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(targetAccounts, "id", account.primary_account_id, "code");
      }
    }
    if (!isEmpty(accountCode)) {
      setIsLoading(true);
      fetchAccountShortLos(accountCode, quoteFormData.type_of_order).then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || [];
          const updatedLevelOfServices = [
            ...levelOfServices,
            {
              name: "None",
              code: "NONE",
              duration: "0",
              id: "NONE",
            },
          ]
          setTargetLevelOfServices(updatedLevelOfServices);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  useEffect(() => {
    if (window.google) {
      setIsMapLoaded(true);
    } else {
      loadGoogleMaps(() => {
        setIsMapLoaded(true);
      });
    }

  }, []);

  useEffect(() => {
    if (activeRecordId) {
      setIsLoading(true);
      QuotesApis.fetchone(activeRecordId).then((result) => {
        if (result.success) {
          const { quote, pickup_location_details, delivery_location_details, appointment ,order_items } = result.data;
          const  pickAppointment = setAppointmentDetails(appointment, 'R', pickup_location_details.time_zone_id);
          const  dropAppointment = setAppointmentDetails(appointment, 'D', delivery_location_details.time_zone_id);
          setQuoteFormData({
            ...quoteFormData,
            ...quote,
            order_items,
            pickup_params: {
              ...quoteFormData.pickup_params,
              ...pickup_location_details,
              first_name: pickup_location_details?.first_name ? pickup_location_details.first_name : pickup_location_details.name,
              preferences: pickAppointment ? [pickAppointment] : _.cloneDeep(defaultAppointment)
            },
            drop_params: {
              ...quoteFormData.drop_params,
              ...delivery_location_details,
              first_name: delivery_location_details?.first_name ? delivery_location_details.first_name : delivery_location_details.name,
              preferences: dropAppointment ? [dropAppointment] : _.cloneDeep(defaultAppointment)
            },
          });
          const revisions = !isEmpty(quote.quotes) ? quote.quotes : [];
          setRevisions(revisions);
        } else {
          renderAlertMessage(result.errors)
        }
      }
      ).finally(() => {
        setIsLoading(false);
      }
      );
    }
  }, [ activeRecordId ]);

  useEffect(() => {
    if (!_.isEmpty(quoteFormData.account_code)) {
      let account = {};
      const accountIndex = _.findIndex(accounts, {
        code: quoteFormData.account_code,
      });
      if (accountIndex >= 0) {
        account = accounts[ accountIndex ];
      }
      if (Object.keys(account).length > 0) {
        getAccountConfigs(account);
        getLos(quoteFormData.account_code);
      }
    } else {
      setLevelOfServices([]);
      setAccountDim(null)
    }
  }, [ quoteFormData.account_code ]);

  useEffect(() => {
    if (!_.isEmpty(quoteFormData.account_code) && !_.isEmpty(quoteFormData.type_of_order)) {
        getLos(quoteFormData.account_code);
    } else {
      setLevelOfServices([]);
    }
  }, [ quoteFormData.type_of_order ]);

  //getting los for the target account code
  useEffect(() => {
    if (!_.isEmpty(quoteFormData.target_account_code)) {
      let account = {};
      const accountIndex = _.findIndex(targetAccounts, {
        code: quoteFormData.target_account_code,
      });
      if (accountIndex >= 0) {
        account = targetAccounts[ accountIndex ];
      }
      if (Object.keys(account).length > 0) {
        getTargetlos(quoteFormData.target_account_code);
      }
    } else {
      setTargetLevelOfServices([]);
    }
  }, [ quoteFormData.target_account_code ]);

  const getAccountConfigs = (account) => {
    const orgId = userStore.getStateValue("selectedOrg");
    const accountId = account.primary_account_id || account.id;
    fetchAccountDims(orgId, accountId).then((result) => {
      if (result.success) {
        const accountDims = result.configurations || [];
        const accountDim = accountDims.length > 0 ? accountDims[ 0 ] : {};
        setAccountDim(accountDim)
      } else {
        setAccountDim({accountDim})
      }
    });
    if (account.code) {
      fetchAccountConfigs(account.id, orgId).then((result) => {
        if (result.success) {
          const accountConfigurations = result.account_configurations || [];
          const config = {};
          accountConfigurations.forEach((setting) => {
            config[ setting.setting_name ] = setting.setting_value;
          });
          if (
            !_.isEmpty(config.pod_pic_settings) &&
            _.isString(config.pod_pic_settings) &&
            config.pod_pic_settings === "[]"
          ) {
            config.pod_pic_settings = [];
          }
          if (isEmpty(config.communication_preference)) {
            config.communication_preference = "SMSES";
          }
          setAccountConfig(config);
        } else {
          renderAlertMessage(result.errors)
        }
      }
      );
    }
  }

  useEffect(() => {
    // Call account API when warehouse code changes
    if (!_.isEmpty(quoteFormData.warehouse_code)) {
      // Find the ID of the selected warehouse
      const whRecord =  warehouses.find(
        (wh) => wh.location_code === quoteFormData.warehouse_code
      )
      const whId = whRecord?.id ? whRecord.id : '';

      // Call the API to refresh the accounts
      refreshAccounts(whId);

      // Clear account info from the quote form
      setQuoteFormData({
        ...quoteFormData,
        account_code: "",
        account_id: "",
        wh_location_id: whId,
        level_of_service: "",
        pickup_level_of_service: "",
        delivery_level_of_service: "",
      });
    }
  }, [ quoteFormData.warehouse_code]);

  // calling when the target warehouse changes
  useEffect(() => {
    // Call account API when warehouse code changes
    if (!_.isEmpty(quoteFormData.target_warehouse_code)) {
      // Find the ID of the selected warehouse
      const whRecord =  warehouses.find(
        (wh) => wh.location_code === quoteFormData.target_warehouse_code
      )
      const whId = whRecord?.id ? whRecord.id : '';

      // Call the API to refresh the accounts
      refreshTargetAccounts(whId);

      // Clear account info from the quote form
      setQuoteFormData({
        ...quoteFormData,
        target_account_code: "",
        target_account_id: "",
        target_wh_location_id: whId,
        target_level_of_service: "",
      });
    }
  }, [ quoteFormData.target_warehouse_code]);

  useEffect(() => {
    // Update account ID when account code changes
    const newFormData = { ...quoteFormData };
    if (!_.isEmpty(quoteFormData.account_code)) {
      const accountIndex = _.findIndex(accounts, { code: quoteFormData.account_code });
      if (accountIndex >= 0) {
        const account = accounts[ accountIndex ];
        newFormData.account_id = account.id;
      }
    } else {
      newFormData.account_id = "";
    }

    setQuoteFormData(newFormData);
  }, [ quoteFormData.account_code, accounts]);

  //update account id when target account code changes
  useEffect(() => {
    const newFormData = { ...quoteFormData };
    if (!_.isEmpty(quoteFormData.target_account_code)) {
      const accountIndex = _.findIndex(targetAccounts, { code: quoteFormData.target_account_code });
      if (accountIndex >= 0) {
        const account = targetAccounts[ accountIndex ];
        newFormData.target_account_id = account.id;
      }
    } else {
      newFormData.target_account_id = "";
    }

    setQuoteFormData(newFormData);
  }, [ quoteFormData.target_account_code, targetAccounts]);

  const handleOnAddressChange = (orderType, element, value, obj) => {
    if(element === 'zipcode' && !isEmpty(value)) {
      const obtainedVal = parseInt(value);
    }
    const currentOrder = quoteFormData[ orderType ]
      ? quoteFormData[ orderType ]
      : {};
    const l_address = currentOrder &&
      currentOrder.l_address ? currentOrder.l_address
      : {};
      const prevZip = l_address.zipcode;
    let newAddress = { ...l_address };
    if (obj) {
      newAddress = { ...l_address, ...obj };
    } else if (element) {
      newAddress[ element ] = value;
    }
    if(!isEmpty(newAddress) && (newAddress.zipcode !== prevZip)) {
      verifyZipCode(newAddress.zipcode);
    }
    const orderTypeDetails = { ...currentOrder, l_address: { ...currentOrder.l_address, ...newAddress } };
    setQuoteFormData({ ...quoteFormData, [ orderType ]: orderTypeDetails });
  };

  const handleOrderDetailsChange = (orderType, key, value) => {
    const currentOrder = quoteFormData[ orderType ]
      ? quoteFormData[ orderType ]
      : {};
    const orderTypeDetails = { ...currentOrder, [ key ]: value };
    setQuoteFormData({ ...quoteFormData, [ orderType ]: orderTypeDetails });
  };

  const handleDateChange = (orderType, index, element, value) => {

    const currentOrder = quoteFormData[ orderType ]
      ? quoteFormData[ orderType ]
      : {};
    const orderTypeDetails = { ...currentOrder };
    if (element === "item_preference") {
      const formattedValue = moment(value).format("YYYY-MM-DD");
      if (isEmpty(orderTypeDetails.feasible_dates)) {
        orderTypeDetails.feasible_dates = [];
      }
      if (!orderTypeDetails.feasible_dates.includes(formattedValue)) {
        orderTypeDetails.feasible_dates.push(formattedValue);
      }
    }
    orderTypeDetails.preferences[index][ element ] = value;
    setQuoteFormData({ ...quoteFormData, [ orderType ]: orderTypeDetails });

  }
  const askConfirmMessage = (orderType, index, element, value) => {
    if (element === "item_preference" && !isEmpty(value)) {
      const { config } = quoteFormData;
      const currentOrder = quoteFormData[ orderType ]
        ? quoteFormData[ orderType ]
        : {};
      const formattedValue = moment(value).format("YYYY-MM-DD");
      if (isEmpty(currentOrder.feasible_dates)) {
        currentOrder.feasible_dates = [];
      }
        handleDateChange(orderType, index, element, value);
    } else {
      handleDateChange(orderType, index, element, value);
    }
  };
  const handleConfirmChange = (orderType, key, value) => {
  };

  const clearPreference = (orderType , rowPosition) => {
    const preferences = [...quoteFormData[orderType].preferences];
    preferences[rowPosition].item_preference = "";
    preferences[rowPosition].item_option = [];
    preferences[rowPosition].confirmed = false;
    preferences[rowPosition].start_time = null;
    preferences[rowPosition].end_time = null;
    preferences[rowPosition].has_expedite = false;
    handleOrderDetailsChange(orderType, "preferences", preferences);
  }




  const handleMultipleFieldsChange = (orderType, data) => {
    const currentOrder = quoteFormData[ orderType ]
      ? quoteFormData[ orderType ]
      : {};
      const zipCodeData = !_.isEmpty(data?.l_address?.zipcode) ? data?.l_address?.zipcode : null;
      verifyZipCode(zipCodeData);
    setQuoteFormData({ ...quoteFormData, [ orderType ]: { ...currentOrder, ...data, type_of_loc:  orderType === 'pickup_params' ? 'R' : 'D'} });
  };

  const handleItemOptionOnChange = ( orderType, index, element, value, checked, type = "radio" ) => {
    const currentOrder = quoteFormData[ orderType ]
      ? quoteFormData[ orderType ]
      : {};
    const orderTypeDetails = { ...currentOrder };
    if (index <= orderTypeDetails.preferences.length - 1) {
      const optionIndex = orderTypeDetails.preferences[index][element].indexOf(value);
      if (optionIndex >= 0) {
        if (value !== "CUSTOM") {
          orderTypeDetails.preferences[index].start_time = null;
          orderTypeDetails.preferences[index].end_time = null;
        }
        if (checked) {
          if (type === "radio" || ["ANY", "CUSTOM"].includes(value)) {
            orderTypeDetails.preferences[index][element] = [value];
          } else {
            orderTypeDetails.preferences[index][element] = orderTypeDetails.preferences[index][element].filter(
              (ele) => !["ANY", "CUSTOM"].includes(ele)
            );
            orderTypeDetails.preferences[index][element].push(value);
          }
        } else {
          if (value === "CUSTOM") {
            orderTypeDetails.preferences[index].start_time = null;
            orderTypeDetails.preferences[index].end_time = null;
          }
          orderTypeDetails.preferences[index][element].splice(optionIndex, 1);
        }}
      else {
        if (value !== "CUSTOM") {
          orderTypeDetails.preferences[index].start_time = null;
          orderTypeDetails.preferences[index].end_time = null;
        }
        // eslint-disable-next-line no-lonely-if
        if (type === "radio" || ["ANY", "CUSTOM"].includes(value)) {
          orderTypeDetails.preferences[index][element] = [value];
        } else {
          // Assuming getting A, M , E
          orderTypeDetails.preferences[index][element] = orderTypeDetails.preferences[index][element].filter(
            (ele) => !["ANY", "CUSTOM"].includes(ele)
          );
          orderTypeDetails.preferences[index][element].push(value);
        }
    
        handleMultipleFieldsChange( orderType, { preferences: orderTypeDetails.preferences } );
      }

    }
  }

  // update dummy data
  const updateItemList = (data) => {
    setQuoteFormData({ ...quoteFormData, order_items: data });
  }

  const handleNextStep = () => {
    setClickedSave(true);
  }

  const toggleClickedSave = (status) => {
    setClickedSave(status);
  }

  const onStepChange = (step) => {
    if(!checkIfValidStepAcess(step)) {
      if(!unsavedChanges) alertMessage(I18n.t("messages.incomplete_current_step") , "error" , 5);
    }else {
      setCurrentStep(step);
    }
  }

  const checkFormDta = () => {
    if(
      !isEmpty(quoteFormData.level_of_service) &&
      !isEmpty(quoteFormData.account_code) &&
      !isEmpty(quoteFormData.warehouse_code) &&
      !isEmpty(quoteFormData.delivery_location_id) &&
      !isEmpty(quoteFormData.pickup_location_id) &&
      !isEmpty(quoteFormData.quotes) &&
      (_.isArray(quoteFormData.quotes)) &&
      quoteFormData.quotes.length > 0)
     {
      return true;
    } 
    return false;
  }

  return (
    <div className="quoteFormContainer">
      <Row gutter={ [ 8, 8 ] } type="flex">
          <Col span={ 24 }>
            <Steps current={ currentStep } onChange={ currentQuote ?  onStepChange : null}>
              { stepTitles.map((title,index) => (
                <Step  key={ title } title={ title } icon={
                  isLoading && index == currentStep ? <Icon type="loading" /> : null
                }/>
              )) }
            </Steps>
          </Col>
          <Col span={ 24 }>
            <div className="steps-content">
              { currentStep === 0 && (
                <QuoteDetailsForm 
                  warehouseFilter={ props.warehouseFilter }
                  warehouses={ warehouses }
                  accounts={ accounts }
                  targetAccounts={ targetAccounts }
                  levelOfServices={ levelOfServices }
                  targetLevelOfServices={ targetLevelOfServices }
                  currentQuote={ currentQuote }
                  setQuoteFormData={ setQuoteFormData }
                  quoteFormData={ quoteFormData }
                  setCurrentQuote={ props.setCurrentQuote }
                  activeRecordId={ activeRecordId }
                  setActiveRecordId={ setActiveRecordId }
                  clickedSave={ clickedSave }
                  setIsLoading={ setIsLoading }
                  handleSuccess={ () => {
                    setIsLoading(false);
                    setClickedSave(false);
                    setCurrentStep(currentStep + 1);
                    setUnsavedChanges(false);
                  } }
                  handleFailure={ () => {
                    setIsLoading(false);
                    setClickedSave(false);
                  } }
                  toggleClickedSave={ toggleClickedSave }
                  unsavedChanges={ unsavedChanges }
                  setUnsavedChanges={ setUnsavedChanges }
                  checkForUnsavedChanges={ checkForUnsavedChanges }
                  obtainSaveDetails={obtainSaveDetails}
                />
              ) }
              { currentStep === 1 && (
                    <QuoteOrderConsigneeDetails
                      currentQuote={ currentQuote }
                      accountConfig={ accountConfig }
                      details={orderConfig.orderTypes['D'].types[0] } 
                      handleMultipleFieldsChange={ handleMultipleFieldsChange }
                      handleOrderDetailsChange={ handleOrderDetailsChange }
                      isMapLoaded={ isMapLoaded }
                      handleDateChange={ handleDateChange }
                      askConfirmMessage={ askConfirmMessage }
                      handleConfirmChange={ handleConfirmChange }
                      handleItemOptionOnChange={ handleItemOptionOnChange }
                      clearPreference={ clearPreference }
                      quoteFormData={ quoteFormData }
                      handleOnAddressChange={ handleOnAddressChange }
                      clickedSave={ clickedSave }
                      setIsLoading={ setIsLoading }
                      handleSuccess={ () => {
                        setIsLoading(false);
                        setClickedSave(false);
                        setCurrentStep(currentStep + 1);
                        setUnsavedChanges(false);
                      } }
                      handleFailure={ () => {
                        setIsLoading(false);
                        setClickedSave(false);
                      } }
                      setCurrentQuote={ props.setCurrentQuote }
                      isMilitaryTime={isMilitaryTime}
                      unsavedChanges={ unsavedChanges }
                      setUnsavedChanges={ setUnsavedChanges }
                      quoteDetailsOnSave={quoteDetailsOnSave}
                    />
              ) }
              { currentStep === 2 && (
                <Fragment>
                  <Row gutter={16}>
                   {quoteFormData.type_of_order !== "D" && (
                    <Col span={12}>
                    <QuoteAccessorial
                    org_id={ userStore.getStateValue("selectedOrg") }
                    customer_order_id={ currentQuote.id } 
                    account_id={ getValueFromArrayOfObjects(accounts, 'code', currentQuote.account_code, 'id') }
                    quote_id={ currentQuote.id }
                    clickedSave={ clickedSave }
                    setIsLoading={ setIsLoading }
                    handleSuccess={ () => {
                      setIsLoading(false);
                      setClickedSave(false);
                      setCurrentStep(currentStep + 1);
                      setUnsavedChanges(false);
                    } }
                    handleFailure={ () => {
                      setIsLoading(false);
                      setClickedSave(false);
                    } }
                    unsavedChanges={ unsavedChanges }
                    setUnsavedChanges={ setUnsavedChanges }
                    quoteFormData = {quoteFormData}
                    location = {quoteFormData.pickup_params}
                    location_id={ currentQuote.pickup_location_id }
                  />
                  </Col>
                   )} 
                   {quoteFormData.type_of_order !== "R" && (
                    <Col span={12}>
                    <QuoteAccessorial
                    org_id={ userStore.getStateValue("selectedOrg") }
                    customer_order_id={ currentQuote.id } 
                    account_id={ getValueFromArrayOfObjects(accounts, 'code', currentQuote.account_code, 'id') }
                    quote_id={ currentQuote.id }
                    clickedSave={ clickedSave }
                    setIsLoading={ setIsLoading }
                    handleSuccess={ () => {
                      setIsLoading(false);
                      setClickedSave(false);
                      setCurrentStep(currentStep + 1);
                      setUnsavedChanges(false);
                    } }
                    handleFailure={ () => {
                      setIsLoading(false);
                      setClickedSave(false);
                    } }
                    unsavedChanges={ unsavedChanges }
                    setUnsavedChanges={ setUnsavedChanges }
                    quoteFormData = {quoteFormData}
                    location = {quoteFormData.drop_params}
                    location_id={ currentQuote.delivery_location_id }
                  />
                    </Col>
                   )}
                  </Row>
                </Fragment>
                
              ) }
              { currentStep === 3 && (
                <QuoteOrderItemsList data={
                  quoteFormData.order_items ? quoteFormData.order_items : []
                } addAtLastColumn
                  key={ "id" }
                  accountDim={
                    accountDim
                  }
                  updateItemList={ updateItemList }
                  quoteId={ quoteFormData.id }
                  clickedSave={ clickedSave }
                  setIsLoading={ setIsLoading }
                  handleSuccess={ () => {
                    setIsLoading(false);
                    setClickedSave(false);
                    setUnsavedChanges(false);
                  } }
                  handleFailure={ () => {
                    setIsLoading(false);
                    setClickedSave(false);
                  } }
                  unsavedChanges={ unsavedChanges }
                  setUnsavedChanges={ setUnsavedChanges }
                  activeRecordId={ activeRecordId }
                />
              ) }
            </div>
            <div className="steps-action buttonsList">
              <Fragment>
              {!isEmpty(activeRecordId) && !isEmpty(quoteFormData) &&
                quoteFormData.status === 'PENDING' && checkFormDta() &&
                  <ApproveQuote
                    key={`qpprove${quoteFormData.id}`}
                    quote_id={quoteFormData.id}
                    quote_no={quoteFormData.quote_order_number}
                    className="buttonMitesse"
                    displayType="button"
                    validateQuote={ () => {
                    if (unsavedChanges) {
                      showUnsavedChangesError("approve");
                    } else {
                      validateQuote(quoteFormData.id, quoteFormData);
                    }
                  } }
                    size="default"
                />
              }
            </Fragment>
              { currentStep > 0 && (
                <Button onClick={ () => setCurrentStep(currentStep - 1) } disabled={isLoading} icon="left-circle">
                  Previous
                </Button>
              ) }
              { currentStep < stepTitles.length - 1 && (
                <Button type="primary" onClick={ () => handleNextStep() } disabled={isLoading} icon="right-circle">
                  Save & Next
                  {/* <Icon type="right" /> */}
                </Button>
              ) }
              { currentStep === stepTitles.length - 1 && (
                <>
                <Button type="primary" onClick={ () => {
                  showQuoteDetails(currentQuote.id)
                  setShowQuoteForm(false);
                  openQuoteView(currentQuote.id);
                } } disabled={isLoading} icon="plus-square">
                  Close & Generate
                </Button>
                </>
              ) }
              <Button type="danger" onClick={ () => setShowQuoteForm(false) } disabled={isLoading} icon="close">
                Close
              </Button>
            </div>
          </Col>
        </Row>
    </div>
  );
}

export default QuoteForm;

QuoteForm.propTypes = {
  currentQuote: PropTypes.shape(),
  isNew: PropTypes.bool,
};


QuoteForm.defaultProps = {
  currentQuote: {},
  isNew: false,
};