/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Col,
  Row,
  Spin,
  Button,
  Popconfirm,
  Modal,
  Collapse,
} from '../../common/UIComponents'
import {
  alertMessage,
  randomNumber,
  isEmpty,
  checkNegitive,
  formatDate,
} from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import DedutionsList from './DeductionsList'
import DetailsList from './DetailsList'
import {
  approveReport,
  saveDriverDeductionData,
  saveGrossOrdersData,
  saveReportDetails,
  deleteGrossOrder,
} from '../../api/Reports'
import { formateTwoUTCDates, getDriverName, renderAlertMessage } from '../../helpers/common'
import AddOrdersToWeeklyReport from './AddOrdersToWeeklyReport'
import { fetchShortFormAccounts } from '../../api/Account'
import { formatRoles } from '../../helpers/user'
import ParticularDriverWeeklyReport from './weekly_reports/ParticularDriverWeeklyReport'
import ParticularDriverDeduction from './weekly_reports/ParticularDriverDeduction'
import moment from 'moment'

const { Panel } = Collapse
class DriverInvoiceDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      report: this.props.report || {},
      data : this.props.data,
      driverId: this.props.driverId,
      currentDriver: this.props.currentDriver || {},
      currentDriverPayRecord: this.props.currentDriverPayRecord || {},
      driverPayId: this.props.driverPayId,
      driverPayReports: this.props.driverPayReports || [],
      deductions: this.props.deductions || [],
      errors: [],
      accounts: [],
      summary:this.props.summary
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    if (this.state.deductions.length === 0) {
      this.addDeductionRow()
    } else {
      this.setDeductions(this.state.deductions)
    }
    console.warn("Driver invoice report-->",this.state.report)
  }

  setDeductions = (deductions = [], cb = null) => {
    this.setState(
      {
        deductions: deductions.map((deduction) =>
          Object.assign({}, deduction, { temp_id: randomNumber() }),
        ),
      },
      cb,
    )
  }

  setResponseData = (
    result,
    takeReports = true,
    takeDriverPayRecords = true,
    takeDeductions = true,
  ) => {
    const data = {}
    if (takeReports === true) {
      const grossOrders = result.reports
      data.report = grossOrders.length > 0 ? grossOrders[0] : {}
        if(data.report){
          if (!isEmpty(result.claim_balance) ) {
            data.report.claim_balance = result.claim_balance
          }
          if (!isEmpty(result.loan_balance)) {
            data.report.loan_balance = result.loan_balance
          }
          if (!isEmpty(result.advance_balance)) {
            data.report.advance_balance = result.advance_balance
          }
      }
    }
    if (takeDeductions === true) {
      const driverDeductions = result.driverDeductions || []
      const updatedDeductions = driverDeductions.filter(
        (deduction) =>
          deduction.driver_pay_report_id === this.state.driverPayId &&
          deduction.driver_id === this.state.currentDriver.id,
      )
      data.deductions = updatedDeductions
    }
    if (takeDriverPayRecords === true) {
      const driverPayReports = result.driverPayReports || []
      const currentDriverPayRecord = _.find(driverPayReports, {
        id: this.state.driverPayId,
      })
      if (!isEmpty(currentDriverPayRecord)) {
        data.currentDriverPayRecord = currentDriverPayRecord
        data.driverPayId = currentDriverPayRecord.id
      } else {
        data.currentDriverPayRecord = {}
        data.driverPayId = ''
      }
    }
    if (result.warnings) {
      Modal.warning({
        title: 'Warning',
        content: <div>{result.warnings.join(',')}</div>,
        width: '60%',
        onOk() {},
      })
    }
    return data
  }

  handleFormChange = (index, element, value) => {
    const gross_orders = [...this.state.report.gross_orders]
    gross_orders[index][element] = value
    this.setState({
      report: Object.assign({}, this.state.report, { gross_orders }),
    })
  }

  handleDeductionChange = (index, element, value) => {
    const deductions = [...this.state.deductions]
    deductions[index][element] = value
    this.setState({
      deductions,
    })
  }

  handleDeductionUpdate = () => {
    const deductions = [...this.state.deductions]
    const data = {
      driver_id: this.state.currentDriver.id,
      driver_pay_report_id: this.state.driverPayId,
      driver_deductions: deductions.map((record) =>
        Object.assign(
          {},
          {
            id: record.id || '',
            deduction_master_id: record.deduction_master_id || '',
            amount: !isEmpty(record.amount) ? record.amount : 0.0,
            deduction_name: !isEmpty(record.deduction_name)
              ? record.deduction_name
              : '',
            gl_code: !isEmpty(record.gl_code) ? record.gl_code : '',
          },
        ),
      ),
    }
    this.setState({ inProgress: true })
    saveDriverDeductionData(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.saved'))
        // const driverDeductions = result.driverDeductions || [];
        // const updatedDeductions = driverDeductions.filter(deduction => deduction.driver_pay_report_id === this.state.driverPayId && deduction.driver_id === this.state.currentDriver.id);
        const newState = this.setResponseData(result, true, false, true)
        this.setState(
          {
            ...newState,
            inProgress: false,
            errors: [],
          },
          () => {
            this.setDeductions(newState.deductions)
          },
        )
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({
          errors: result.errors,
          inProgress: false,
        })
      }
    })
  }

  handleApprove = () => {
    this.setState({
      inProgress: true,
    })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = _.find(driverPayReports, {
      id: this.state.driverPayId,
    })
    if (!isEmpty(currentDriverPayRecord)) {
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: this.state.driverPayId,
        status: 'APPROVED',
      }
      approveReport(data).then((result) => {
        if (result.success) {
          const newState = this.setResponseData(result, true, true, true)
          this.setState({
            ...newState,
            inProgress: false,
          })
          alertMessage(I18n.t('messages.saved'))
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleSaveDetails = () => {
    this.setState({
      inProgress: true,
    })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = _.find(driverPayReports, {
      id: this.state.driverPayId,
    })
    if (!isEmpty(currentDriverPayRecord)) {
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: this.state.driverPayId,
        status: 'APPROVED',
      }
      saveReportDetails(data).then((result) => {
        if (result.success) {
          const newState = this.setResponseData(result, true, true, true)
          this.setState({
            ...newState,
            inProgress: false,
          })
          alertMessage(I18n.t('messages.saved'))
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleGrossOrderUpdate = () => {
    const grossOrders = [...this.state.report.gross_orders]
    const data = {
      driver_id: this.state.driverId,
      driver_pay_report_id: this.state.driverPayId,
      gross_order_reports: grossOrders.map((record) =>
        Object.assign(
          {},
          {
            id: record.id,
            gross_revenue: !isEmpty(record.gross_revenue)
              ? record.gross_revenue
              : 0.0,
            gross_pay: !isEmpty(record.gross_pay) ? record.gross_pay : 0.0,
          },
        ),
      ),
    }
    this.setState({ inProgress: true })
    saveGrossOrdersData(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.saved'))
        const newState = this.setResponseData(result, true, true, false)
        this.setState({
          ...newState,
          inProgress: false,
          errors: [],
        })
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({
          errors: result.errors,
          inProgress: false,
        })
      }
    })
  }

  _renderTotalDetails = () => (
    <div className="marginTop15 padding10 net_details marginLeft5">
      <Row className="marginTop10">
        
        <Col md={20} className="borderLine">
          <h4 className="padding5">Summary</h4>
          <Row className="custom_line padding5">
            <Col xs={13} className="textBold">
              {I18n.t('reports.total_net')}({I18n.t('general.price_symbol')})
            </Col>
            <Col xs={1}>: </Col>
            <Col xs={10}>{checkNegitive(this.state.summary.total_net)}</Col>
          </Row>
          <Row className="custom_line padding5">
            <Col xs={13} className="textBold">
              {I18n.t('reports.total_deductions')}
            </Col>
            <Col xs={1}>: </Col>
            <Col xs={10}>
              {checkNegitive(this.state.summary.total_deductions)}
            </Col>
          </Row>
          <Row className="custom_line padding5">
            <Col xs={13} className="textBold">
              {I18n.t('reports.payable_amount')}({I18n.t('general.price_symbol')})
            </Col>
            <Col xs={1}>: </Col>
            <Col xs={10}>
              {checkNegitive(this.state.summary.payable_amount)}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

  addDeductionRow = () => {
    const deductions = [...this.state.deductions]
    deductions.push({
      deduction_name: '',
      amount: 0.0,
      gl_code: '',
      isNew: true,
      temp_id: randomNumber(),
    })
    this.setState({
      deductions,
    })
  }

  addOrderRow = () => {
    const gross_orders = this.state.report?.gross_orders
      ? [...this.state.report.gross_orders]
      : []
    gross_orders.push({
      customer_order_number: '',
      account_code: '',
      consignee_name: '',
      consignee_address: '',
      gross_revenue: '',
      gross_pay: '',
      isNew: true,
    })
    this.setState(
      {
        report: Object.assign({}, this.state.report, { gross_orders }),
      },
      () => {
        const currentActiveView = document.querySelector(`#newOrderRow`)

        if (currentActiveView) {
          $('#containerDiv').animate({ scrollTop: 0 }, 'fast')
        }
      },
    )
  }

  canelAddOrderRow = (isNew, id) => {
    const gross_orders = [...this.state.report.gross_orders]
    let filteredOrders = []
    if (isNew) {
      filteredOrders = gross_orders.filter((rec) => !rec.isNew)
    } else {
      filteredOrders = gross_orders.filter((rec) => rec.id !== id)
    }
    this.setState({
      report: Object.assign({}, this.state.report, {
        gross_orders: [...filteredOrders],
      }),
    })
  }

  handleDeleteClick = (index, id, isNew) => {
    if (isNew === true) {
      const { deductions } = this.state
      deductions.splice(index, 1)
      this.setState({
        deductions,
      })
    }
  }

  _renderHeading = () => {
    const { currentDriverPayRecord, currentDriver, report,data } = this.state
    const currentRole = currentDriver?.roles ? formatRoles(currentDriver.roles) : ''
    return (
      <div>
        <Row>
          <Col md={11}>
            <div className="info-text">
              <h4>Report for the week :</h4>
              {moment(data.start_week).format('MMM DD,YYYY')} -{moment(data.end_week).format('MMM DD,YYYY')}
             {/* {formateTwoUTCDates(data.start_week,data.end_week)} */}
            </div>
            { currentDriverPayRecord.wh_name && <div className="info-text">
              <h4>{I18n.t('location.location')}:</h4>
              {currentDriverPayRecord.wh_name }
            </div>
            
            }
          </Col>
          <Col md={8}>
            <div className="info-text">
              <h4> {I18n.t('general.status')} :</h4>
              Approved
            </div>
             { currentDriverPayRecord.invoice_number && <div className="info-text textBold">
              <h4> {I18n.t('general.invoice')} :</h4>
              #{currentDriverPayRecord.invoice_number}
            </div>
             }
          </Col>
          <Col md={5} className="alignRight">
            <div className="info-text">
              <h4>{I18n.t('general.Driver')} :</h4>
              {data.driver_name}
            </div>
            <div className="info-text">
              <h4>
                <Icon type="phone" />{' '}
              </h4>
              {this.state.data.mobile_number}
            </div>
          </Col>
        </Row>
        <hr />
      </div>
    )
  }

  _renderDeductions = () => {
    const isEditable = this.state.currentDriverPayRecord.status === 'PENDING'
    return (
      <div className="marginTop15 marginLeft5 deductionsBlock">
        <Row>
          <Col xs={12}>
            <h4>Deductions</h4>
          </Col>
          {false && this.state.report && (
            <Col xs={12} className="alignRight">
              <span className="textBold">
                {I18n.t('deductions.claim_balance')}:&nbsp;
              </span>
              {checkNegitive(this.state.report.claim_balance)}
            </Col>
          )}
        </Row>
        <Fragment>
          <DedutionsList
            size="small"
            pagination={{ position: 'none' }}
            data={this.state.deductions}
            onChange={this.handleDeductionChange}
            handleSave={this.handleSave}
            scroll={{ y: 300 }}
            deleteClick={(index, id, isNew) =>
              this.handleDeleteClick(index, id, isNew)
            }
            totalRecords={this.state.deductions.length}
            addRow={this.addDeductionRow}
            claim_balance={
              this.state.report?.claim_balance
                ? this.state.report.claim_balance
                : 0.0
            }
            loan_balance={
              this.state.report?.loan_balance
                ? this.state.report.loan_balance
                : 0.0
            }
            advance_balance={
              this.state.report?.advance_balance
                ? this.state.report.advance_balance
                : 0.0
            }
            // isEditable={isEditable}
            footer={() => (
              <div className="alignRight textBold">
                <Button
                  size="small"
                  type="primary"
                  onClick={this.handleDeductionUpdate}
                >
                  {I18n.t('general.save')}
                </Button>
              </div>
            )}
          />
        </Fragment>
      </div>
    )
  }

  handleAddOrdersResponse = (
    response,
    takeReports = true,
    takeDriverPayRecords = true,
    takeDeductions = false,
    cb = null,
  ) => {
    const newState = this.setResponseData(
      response,
      takeReports,
      takeDriverPayRecords,
      takeDeductions,
    )
    this.setState(
      {
        ...newState,
        errors: [],
      },
      () => {
        if (cb) {
          cb()
        }
      },
    )
  }
  deleteOrder = (id) => {
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      driver_pay_report_id: this.state.driverPayId,
      gross_order_id: id,
    }
    this.setState({ inProgress: true })
    deleteGrossOrder(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        const newState = this.setResponseData(result, true, true, false)
        this.setState({
          ...newState,
          inProgress: false,
          errors: [],
        })
        // this.handleAddOrdersResponse(result, true, true, true, () => {
        //   this.canelAddOrderRow(false, id)
        // })
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({
          inProgress: false,
        })
      }
    })
  }

  _renderDetails = () => {
    const { currentDriverPayRecord } = this.state
    const driverPayStatus = currentDriverPayRecord.status
    const grossRecords = this.state.report.gross_orders || []
    const newRecord = _.find(grossRecords, { isNew: true })
    const existingRecords = grossRecords.filter((rec) => !rec.isNew)
    return (
      <div>
        <div className="marginTop15 marginRight5 lefftBlock customTable">
          <Row>
            <Col xs={12}>
              <h4>{I18n.t('menu.orders')}</h4>
            </Col>
            <Col xs={12} className="alignRight">
              {grossRecords.length === 0 && (
                <Button type="primary" size="small" onClick={this.addOrderRow}>
                  {I18n.t('order.add')}
                </Button>
              )}
            </Col>
          </Row>
          <Fragment>
            <DetailsList
              size="small"
              pagination={{ position: 'none' }}
              data={existingRecords}
              onChange={this.handleFormChange}
              handleSave={this.handleGrossOrderUpdate}
              driverPayStatus={driverPayStatus}
              //scroll={{ y: document.body.clientHeight - 260 }}
              // isEditable={isEditable}
              addOrderRow={this.addOrderRow}
              hasNew={!isEmpty(newRecord)}
              deleteClick={this.deleteOrder}
              footer={() =>
                grossRecords.length > 0 ? (
                  <div id="newOrderRow">
                    {newRecord && (
                      <div className="appointmentForm">
                        <Collapse
                          defaultActiveKey={['1']}
                          style={{ height: 'auto' }}
                          expandIconPosition="right"
                          className="customCollapse"
                        >
                          <Panel
                            header={<Fragment>{I18n.t('order.add')}</Fragment>}
                            key="1"
                            size="small"
                          >
                            <AddOrdersToWeeklyReport
                              currentRecord={newRecord ? newRecord : {}}
                              size="small"
                              grossOrders={existingRecords}
                              driverId={this.state.driverId}
                              driverPayId={this.state.driverPayId}
                              handleResponse={this.handleAddOrdersResponse}
                              onCancel={() => this.canelAddOrderRow(true, '')}
                              accounts={this.state.accounts}
                            />
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                    {!newRecord && (
                      <div className="alignRight">
                        <Button
                          size="small"
                          type="primary"
                          onClick={this.handleGrossOrderUpdate}
                        >
                          {I18n.t('general.save')}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Fragment />
                )
              }
            />
          </Fragment>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="content-outer reportDetailsBlock">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col xs={24}>
                <this._renderHeading />
                <Row gutter={16}>
                  <Col
                    span={12}
                    style={{ height: 'calc(100vh - 120px)', overflowY: 'auto' }}
                  >
                    {/* <this._renderDetails />? */}
                    <ParticularDriverWeeklyReport
                    tableLayout="fixed"
                    rowKey="customer_order_id"
                    data={this.state.report}
                    size="small"
                    style={{ width: 800 }}

                    pagination={{ position: "none" }}
                    
                    />
                  </Col>
                  <Col span={8} >
                    {/* <this._renderDeductions /> */}
                    <ParticularDriverDeduction
                    
                    size="small"
                    tableLayout="fixed"
                    data={this.state.deductions|| []}
                    pagination={{ position: "none" }}
                    style={{ width: 520 }}

                    />
                  </Col>
                  <Col span={8}>

                  <this._renderTotalDetails />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
      </div>
    )
  }
}
DriverInvoiceDetails.propTypes = {
  account: PropTypes.shape().isRequired,
}

export default DriverInvoiceDetails
