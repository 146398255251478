
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";

const GearIcon = (props) => {
  const { handleClick,color="black" } = props;
  return (
    <>
      <Icon
        type="setting"
        theme="twoTone"
        twoToneColor={color}
        onClick={handleClick}
      />
    </>
  );
};

GearIcon.propTypes = {
  handleClick: PropTypes.func,
};

GearIcon.defaultProps = {
  handleClick: () => {},
};
export default GearIcon;
