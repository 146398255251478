import React, { Component } from "react";
import BaseModal from "../components/BaseModal";
import {
  LableSettingApi,
  saveLabels,
  deleteLabels,
} from "../api/LableSettingApi";
import {
  Row,
  Col,
  Button,
  FormItem,
  Select,
  Spin,
} from "../common/UIComponents";
import { fetchOrganizations } from "../api/Organisations";

import LabelSettingList from "../components/order_labels/LabelSettingList";
import LabelForm from "../components/order_labels/LabelForm";
import { alertMessage, isEmpty } from "../common/Common";
import { getValueFromArrayOfObjects } from "../helpers/array_functions";
import AppConfig from "../config/AppConfig";
import I18n from "../common/I18n";

class LableSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      labels: [],
      isModalVisible: false,
      organization: "",
      inProgress: false,
      isNew: true,
      currentData: {},
      pagination: {},
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: "",
    };
  }

  componentDidMount() {
    this.getOrganizations();
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false, isNew: true, currentData: {} });
    this.getLableSetting();
  };

  handleFilterChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      this.tableOptions.pagination.current = 1;
      this.getLableSetting();
    });
  };

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteLabels(id).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        if (this.state.labels.length === 1) {
          if (
            this.tableOptions.pagination &&
            this.tableOptions.pagination.current > 1
          ) {
            this.tableOptions.pagination.current =
              this.tableOptions.pagination.current - 1;
            this.getLableSetting();
          } else {
            this.getLableSetting();
          }
        } else {
          this.getLableSetting();
        }
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.labels, ["id", id]);
    const Data = Object.assign({}, this.state.labels[index]);
    this.setState({
      currentData: Data,
      isModalVisible: true,
      isNew: false,
    });
  };

  handleSuccess = (record) => {
    this.setState({ inProgress: true });
    record.organization_id = this.state.organization;
    saveLabels(this.state.isNew, record).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        this.setState(
          {
            errors: [],
            inProgress: false,
            isNew: true,
            isModalVisible: false,
          },
          () => {
            this.getLableSetting();
          }
        );
      } else {
        this.setState({ errors: result.errors, inProgress: false });
      }
    });
  };

  renderFormModal = () => (
    <BaseModal
      title={
        this.state.isNew
          ? I18n.t("lable_settings.add_title")
          : I18n.t("lable_settings.edit_title")
      }
      visible={this.state.isModalVisible}
      onCancel={this.handleCancel}
      maskClosable={false}
    >
      <LabelForm
        onCancel={this.handleCancel}
        organizations={this.state.organizations}
        Data={this.state.currentData}
        organization={this.state.organization}
      />
    </BaseModal>
  );

  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations().then((result) => {
      if (result.success) {
        const organizations = result.organizations.organizations || [];
        const organization =
          organizations.length > 0
            ? getValueFromArrayOfObjects(
                organizations,
                "code",
                I18n.t("account.netmove_code"),
                "_id"
              )
            : "";
        this.setState(
          {
            organizations,
            organization,
            inProgress: false,
          },
          () => {
            this.getLableSetting();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  getLableSetting = (initial) => {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    LableSettingApi.fetch(this.state.organization, page, perPage).then(
      (response) => {
        if (response.success) {
          // const fetchedData = data.record || [];
          this.setState({
            labels: response.data || [],
            pagination: response.pagination ? response.pagination : {},
            inProgress: false,
          });
        } else {
          alertMessage(response.errors, "error", 10);
          this.setState({ inProgress: false, labels: [], pagination: {} });
        }
      }
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
        this.tableOptions && this.tableOptions.searchText
          ? this.tableOptions.searchText
          : "",
    };
    if (pagination.current !== currentPage) {
      this.getLableSetting();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "none",
          sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getLableSetting();
        }
      );
    }
  };

  render() {
    const { pagination } = this.state;
    return (
      <>
        <div className="content-outer">
          <div className="content">
            <Row className="page-header">
              <Col xs={12}>{I18n.t("menu.lable_settings")}</Col>
              <Col xs={12} className="page-actions">
                <Button
                  type="primary"
                  icon="plus"
                  className="OrderApiWrapper__left__header__button"
                  onClick={this.showModal}
                >
                  {I18n.t("lable_settings.add")}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col sm={24} xs={24} md={5} lg={5}>
                <FormItem label={I18n.t("organization.single")} require>
                  <Select
                    value={this.state.organization}
                    showSearch
                    onChange={(e) => this.handleFilterChange("organization", e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                  >
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Spin spinning={this.state.inProgress} delay={1000}>
              <Row>
                <Col>
                  <LabelSettingList
                    data={this.state.labels}
                    editClick={(id) => this.handleEditClick(id)}
                    deleteClick={(id) => this.handleDelete(id)}
                    pagination={{
                      total: pagination.total_count,
                      current: pagination.current_page,
                      pageSize: pagination.per_page,
                    }}
                    tableChange={(paginationInfo, filters, sorter) =>
                      this.handleTableChange(paginationInfo, filters, sorter)
                    }
                  />
                </Col>
              </Row>
            </Spin>
            {this.state.isModalVisible && this.renderFormModal()}
          </div>
        </div>
      </>
    );
  }
}
export default LableSetting;
