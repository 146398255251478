import React from "react";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";


function ManifestIncludeItemsList(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      item_name : "abc123",
      pieces : "1",
      dim_weight : "0",
      model_number : "1245",
      serial_number : "7890",
      weight : "1",
      wh_dock : "abc111",
      item_width : "1*1*1",
      dim_factor : "100"
    },
    {
       key: 2,
        item_name : "abc456",
        pieces : "2",
        dim_weight : "1",
        model_number : "4567",
        serial_number : "1234",
        weight : "2",
        wh_dock : "abc456",
        item_width : "2*2*2",
        dim_factor : "200"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "item_name",
      title: "Item Name",
      dataIndex: "item_name",
    },
    {
      key: "pieces",
      title: "Pieces",
      dataIndex: "pieces",
    },
    {
      key: "dim_weight",
      title: "Dim.wt",
      dataIndex: "dim_weight",
    },
    {
        key: "dim_factor",
        title: "Dim Factor",
        dataIndex: "dim_factor",
      },
    {
      key: "model_number",
      title: "Model Number",
      dataIndex: "model_number",
    },
    {
      key: "serial_number",
      title: "Serial Number",
      dataIndex: "serial_number",
    },
    {
      key: "weight",
      title: I18n.t("order.weight"),
      dataIndex: "weight",
    },
    {
      key: "wh_dock",
      title: "Warehouse Dock",
      dataIndex: "wh_dock",
    },
    {
      key: "item_width",
      title: "Dimensions(L*W*H)",
      dataIndex: "item_width",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = 'dispatch_manifest_file_include_items_columns'

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )
  
}

export default ManifestIncludeItemsList;
