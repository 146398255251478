import React from 'react';
import BaseList from '../BaseList';
import { compareString } from '../../common/Common';
import { Icon, Popconfirm, Tooltip, Popover } from '../../common/UIComponents';
import SettingIcon from '../common/SettingIcon';
import DeleteIcon from '../common/DeleteIcon';
import I18n from '../../common/I18n';

class DriverRatesList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
        render: text => ({
          props: {
            className: 'wordWrap',
          },
          children: text,
        }),
        sorter: (a, b) => compareString(a.name, b.name),
      },
      {
        key: 'code',
        title: I18n.t('general.code'),
        dataIndex: 'code',
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: id => (
          <div className='line-actions' style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip placement="topLeft" title="Settings">
              <SettingIcon handleClick={() => this.props.handleSettingsClick(id)}/>
            </Tooltip>
            {/* 
                <Tooltip placement="topLeft" title="Edit">
                <Icon
                  type='edit'
                  onClick={() => this.props.handleEditClick(id)}
                />
              </Tooltip>
            */}
            <Tooltip placement="topLeft" title="Delete">
              <Popconfirm
                placement="topRight"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => this.props.handleDeleteClick(id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon/>
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
  }
}

export default DriverRatesList;
