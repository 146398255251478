import moment from "moment";
import { isEmpty } from "../common/Common";
import { Call, ApiUrl,ParseGeneralResponse } from "./ApiUtils";

export function fetchStatus(
  dateFilter = {},
  screenFrom = "",
  route_type = '',
) {
  let url = ApiUrl(`v2/dashboard_stats?operation_code=NRDS`);
  if (!isEmpty(dateFilter)) {
    if (dateFilter.fromDate) {
      url = `${url}&from_date=${moment(new Date(dateFilter.fromDate)).format(
        "YYYY-MM-DD 00:00:00 Z"
      )}`;
    }
    if (dateFilter.toDate) {
      url = `${url}&to_date=${moment(new Date(dateFilter.toDate)).format(
        "YYYY-MM-DD 00:00:00 Z"
      )}`;
    }
  }
  if (!isEmpty(screenFrom)) {
    url = `${url}&screen_from=${screenFrom}`;
  }
  if (!isEmpty(route_type)) {
    url = `${url}&route_type=${route_type}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function fetchRouteLogs(page = 1) {
  const url = ApiUrl(`v2/route_logs?page=${page}&operation_code=NRLOGS`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      routeLogs: "route_logs",
      pagination: "meta.pagination",
    },
  });
}
