import React, { Fragment } from "react";
import BaseList from "../BaseList";
import {isEmpty, ellipseText } from "../../common/Common";
import { Icon, Popconfirm, Tooltip } from "../../common/UIComponents";
import fileIcon from "../../../assets/images/file_send.png";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance } from '../../helpers/common';
import { momentTime } from "../../helpers/date_functions";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import EyeIcon from "../common/EyeIcon";
import I18n from "../../common/I18n";

class RoutesList extends BaseList {
  constructor(props) {
    super(props);
    this.setColumns(props);
  }
  setColumns = (props) => {
    const currentOrg = props.currentOrg;
    this.columns = [
      {
        key: "name",
        title: I18n.t("general.name"),
        dataIndex: "name",
        render: (data) => <div>{data}</div>,
      },
      {
        key: "delivery_start_date",
        title: I18n.t("preplan.deliverable_date"),
        dataIndex: "delivery_start_date",
        render: (data, record) => (
          <div>
            {data ? momentTime("ddd, Do MMM YYYY", data, record.warehouse_timezone_id) : "NA"}
          </div>
        ),
        ...AppConfig.sortingOptions,
      },
      {
        key: "warehouse",
        title: I18n.t("location.location"),
        dataIndex: "warehouse_name",
        render: (data) => <div>{data}</div>,
      },
      {
        key: "created_by",
        title: I18n.t("general.created_by"),
        dataIndex: "created_user_name",
        render: (data) =>
          !isEmpty(data) && (
            <div>
              <Tooltip title={data} overlayClassName="textCaptilize">
                {ellipseText(data, 15)}
              </Tooltip>
            </div>
          ),
        className: "textCaptilize",
      },
    ];
    if (checkServiceExistance(['PPCNR', 'PPOD'], 'ALL') && 
    !isEmpty(currentOrg) && 
    currentOrg === I18n.t("account.netmove_code")) {
      this.columns.push({
        key: "status",
        title: I18n.t("preplan.confirm_routes"),
        dataIndex: "id",
        render: (data) => (
          <div className="line-actions">
            <span>
              <img
                src={fileIcon}
                className="listImage"
                onClick={() => this.props.generateClick(data)}
              />
            </span>
          </div>
        ),
        align: "center",
      });
    }
    this.columns.push({
      key: "actions",
      title: I18n.t("general.actions"),
      width: 100,
      render: (data) => (
        <div className="line-actions">
          {data.r_status === "COMPLETED" ? (
            <span>
              <EyeIcon handleClick={() => this.props.editClick(data.id)}/>
            </span>
          ) : (
            <Fragment>
              {  checkServiceExistance('PPDS') && 
              <Tooltip title={ I18n.t("general.edit") }>
              <span>
                <EditIcon handleClick={() => this.props.editClick(data.id)}/>
              </span>
              &nbsp;
              </Tooltip>
              }
              { checkServiceExistance('PPD') && <span>
                {(isEmpty(data.driver_codes) || data.driver_codes.length === 0) ? (
                  <Popconfirm
                    placement="left"
                    title={<span>{I18n.t("messages.delete_confirm")}</span>}
                    onConfirm={() => this.props.deleteClick(data.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title={ I18n.t("general.delete") }>
                    <DeleteIcon/>
                  &nbsp;&nbsp;
                  </Tooltip>
                  </Popconfirm>
                ) : (
                  <Tooltip title={ I18n.t("general.delete") }>
                  <DeleteIcon handleClick={() => this.props.deleteClick(data.id)}/>
                  &nbsp;
                  </Tooltip>
                )}
              </span>
              }
            </Fragment>
          )}
        </div>
      ),
    });
  };
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default RoutesList;
