import { Col, Icon, Row, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import PropTypes from "prop-types";
import React from "react";
import { compareString } from "../common/Common";
import I18n from "../common/I18n";
import BaseList from "../components/BaseList";
import AccurateIcon from '../../../src/assets/images/accurate.svg';
import AccurateTargetingIcon from "../../../src/assets/images/accurate-targeting.svg";
import EndUserTitle from "../components/common/EndUserTitle";

class WrongAddressList extends BaseList {
	constructor (props) {
		super(props);
		this.columns = [
			{
				key: "order_no",
				title: I18n.t("order.order_no"),
				dataIndex: "customer_order_number",
				render: (data, record) => <span className="textUpperCase">{ data }</span>,
				sorter: (a, b) =>
					compareString(a.customer_order_number, b.customer_order_number),
				width : "15%"
			},
			{
				key: 'customer_address',
				title: <EndUserTitle  stringToAppend={I18n.t('general.address')}/>,
				dataIndex: "customer_address",
				render: (data) => {
					const address = `${data.address_line1} ${data.address_line2} ${data.city} ${data.state} ${data.zipcode}`;
					return (
						<div>
							{ data ? (
								<Row style={{
									display: 'flex',
									gap: '3px',
									alignItems: 'center',
								}}>
									<Col>
										<Icon
											type="environment"
											style={ {
												fontSize: "16px",
											} }
										/>
									</Col>
									<Col> 
										<Paragraph ellipsis={ address.length > 90 ? { rows: 1, expandable: true } : false } >
											{ address }
										</Paragraph>
									</Col>
								</Row>
							) : '' }
						</div>
					);
				},
				sorter: (a, b) => compareString(a.customer_address.address_line1, b.customer_address.address_line1),
				width : "75%"
			},
			{
				key: "actions",
				render: (data , record) => {
					const { changeActiveAddress , selectedRecordId } = this.props;
					const { customer_order_id } = record;
					return (
						<div className="pageActions">
							<Row gutter={ 8 }>
								<Col span={ 12 }>
									<Tooltip title={"Use this Location"}>
										<Icon
											component={ () => (
												selectedRecordId === customer_order_id ? <img style={{height : "25px"}} src={ AccurateTargetingIcon } alt="Accurate Targeting" /> : <img style={{height : "25px"}} src={ AccurateIcon } alt="Accurate" /> 
											) }
											onClick={ () => changeActiveAddress(customer_order_id) }
											className="paddinRight5"
										/>
									</Tooltip>
								</Col>
							</Row>
						</div>
					);
				},
				align: "center",
				width : "10%"
			},
		];
	}
}
export default WrongAddressList;

WrongAddressList.propTypes = {
	changeActiveAddress: PropTypes.func,
	selectedRecordId: PropTypes.string,
};
