import React, { Fragment } from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import { getFileIcon, getFileType } from "../../common/Common";
import { Icon, Link } from "../../common/UIComponents";
import { TableImageColumn } from "../../common/TableImageColumn";

class DmsUnprocessedList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "File",
        // title: {I18n.t("dms.document_title")},
        title: "Documents",
        dataIndex: "picture_url",
        render: (data, record) => {
          const url = record.picture_url ? record.picture_url : "";
          const fileType = getFileType(url);
          return (
            <span>
              {fileType === "image" ? (
                <TableImageColumn
                  record={url}
                  alt="org logo"
                  width="100"
                  height="30"
                />
              ) : (
                // <img src={url} alt="Image" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                <Fragment>
                  <Link onClick={() => window.open(url, "_blank")}>
                    <Icon type={getFileIcon(fileType)} /> 
                    {this.getFileName(record)}
                    &nbsp;&nbsp;&nbsp;
                    <Icon type="download" />{" "}
                  </Link>
                </Fragment>
              )}
            </span>
          );
        },
        width: "100",
      },
    ];
  }

  getFileName = (record) => {
    if(record?.picture !== 'N/A'){
      return record.picture;
    } else{
      return "File";
    }
  }
}

export default DmsUnprocessedList;
