import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Modal, FormItem, Input, Row, Select} from '../../common/UIComponents';
import {  alertMessage, doValidate, formatCode } from '../../common/Common';
import I18n from '../../common/I18n';

import FormButtons from '../common/FormButtons';
import { saveAdjustment, fetchCategories } from '../../api/AdjustmentsApi';
import FormErrors from '../common/FormErrors';
import { renderAlertMessage } from '../../helpers/common';

const { confirm } = Modal;

const requiredFields = [{
  form_field: 'name',
  ui_name: `${I18n.t('adjustments.name')}`,
  isRequired: true,
  inputType: 'name',
},
{
  form_field: 'code',
  ui_name: I18n.t('adjustments.code'),
  isRequired: true,
  inputType: 'code',
},
{
  form_field: 'category_code',
  ui_name: I18n.t('adjustments.category'),
  isRequired: true,
  inputType: 'text',
},
];

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustment: this.props.adjustment ? this.props.adjustment : {},
      isNew: this.props.isNew,
      inProgress: false,
      errors: [],
      categories: [],
      account: this.props.account || {},
    };
    this.handleSave = this.handleSave.bind(this);
  }
  componentDidMount() {
    this.getCategories();
  }

  getCategories = (cb) => {
    this.setState({ inProgress: true });
    fetchCategories()
      .then((result) => {
        if (result.success) {
          this.setState({
            categories: result.categories,
            inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleOnChange(element, value) {
    this.setState({ adjustment: Object.assign({}, this.state.adjustment, { [element]: value }) }, () => {
      if (element === 'category_params') {

      }
    });
  }

  handleSave() {
    const data = this.state.adjustment;
    data.account_id = this.state.account.id
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      data.code = formatCode(data.code)
      this.setState({ inProgress: true });
      saveAdjustment(this.state.isNew, data)
        .then((result) => {
          if (result.success) {
            const adjustment = result.adjustment || {};
            this.setState({ inProgress: false });
            this.props.onSuccess(I18n.t('messages.saved'));
            this.props.doConfig(adjustment);
          } else {
            alertMessage(result.errors, 'error', 10);
            this.setState({ inProgress: false });
          }
        });
    } else {
      this.setState({ errors, inProgress: false });
    }
  }

  askConfirmMessage = (element, value) => {
    if (!this.state.isNew) {
      confirm({
        title: I18n.t('adjustments.change_waring', { field: 'adjustment ' }),
        content: <span className="">{"Do you want to proceed ?"}</span>,
        onOk: () => {
          this.handleOnChange(element, value);
        },
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onCancel: () => { },
      });
    } else {
      this.handleOnChange(element, value);
    }
  }

  render() {
    const { adjustment } = this.state;
    return (
      <div>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t('adjustments.name')} require>
              <Input
                value={adjustment.name}
                onChange={e => this.handleOnChange('name', e.target.value)}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t('adjustments.code')} require>
              <Input
                value={adjustment.code}
                onChange={e => this.handleOnChange('code', e.target.value)}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t('adjustments.category')} require>
              <Select
                value={adjustment.category_code || ''}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                // onChange={e => this.handleOnChange('category_code', e)}
                onChange={e => this.askConfirmMessage('category_code', e)}
              >
                <Select.Option key={'categorycode'} value=''>
                  ----- Select -----
                </Select.Option>
                {this.state.categories.map(category => (
                  <Select.Option key={category.code} value={category.code}>
                    {`${category.name} (${category.code})`}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>

        </Row>
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        <Row>
          <Col xs={24}>
            {/* {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)} */}
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel,
              true, true, 'default',
              I18n.t('general.next'),
              I18n.t('general.close'),
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

Form.propTypes = {
  adjustment: PropTypes.shape(),
  isNew: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Form.defaultPropTypes = {
  adjustment: {},
  isNew: true,
};

export default Form;
