/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Col,
  Row,
  Spin,
  Empty,
  Button,
  Icon,
  Collapse,
  Card,
  Popconfirm,
  Tooltip,
  Link,
} from '../../common/UIComponents'
import {  alertMessage, randomNumber, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import FormErrors from '../common/FormErrors'
// import RateCard from "./RateCard";
import BaseModal from '../../components/BaseModal'
import WeightForm from './zones/WeightForm'
import {
  deleteWeights,
  fetchBillingRates,
  saveWeights,
  saveZoneCost,
  updateLosWeightRange,
} from '../../api/BillingRates'
import { fetchAccountZones, saveZoneMinCharge } from '../../api/BillingZones'
import {
  deleteLos,
  fetchLos,
  fetchLosDetails,
  cloneLosDetails,
} from '../../api/Los'
import LosForm from '../billing/LosForm'
import AppConfig from '../../config/AppConfig'
import LosList from './LosList'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import AddNewContract from './AddNewContract'
import EditLos from './EditLos'
import moment from 'moment'

const { Panel } = Collapse
class DeliveryCharges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      weights: [],
      zones: [],
      data: [],
      inProgress: false,
      account: this.props.account || [],
      currentLos: {},
      errors: [],
      levelOfServices: [],
      losIsNew: false,
      editEnabledLos: {},
      isEditing: false,
      applyAdditional: false,
      freePallets:null, 
      losCategories: [],
      currentRatecard: {}
    }
  }

  clearForm = () => {
    this.setState({
      currentAccessorial: {},
      isNew: true,
    })
  }

  componentDidMount() {
    this.setInitializeData()
  }

  componentWillReceiveProps() {
    this.setInitializeData()
  }

  getAccountZones = (cb = null) => {
    this.setState({ inProgress: true })
    fetchAccountZones(this.state.account.id).then((result) => {
      if (result.success) {
        const zones = result.zones || []
        this.setState(
          {
            zones: zones.map((zone) => ({
              zone_id: zone.id,
              zone_name: zone.name,
              // min_charge: zone.min_charge,
            })),
            inProgress: false,
          },
          cb,
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  fetchLos = () => {
    this.setState({ inProgress: true })
    fetchBillingRates(this.state.account.id).then((result) => {
      if (result.success) {
        const rates = result.rates || []
        this.setState(
          {
            data: rates,
            inProgress: false,
          },
          () => {
            this.renderRateData()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  getLosDetails = (event, id) => {
    this.setState({ inProgress: true })
    fetchLosDetails(id, this.state.account.id).then((result) => {
      if (result.success) {
        const currentLos = result.los || {}
        this.setState({
          showLos: true,
          currentLos: !isEmpty(currentLos) ? {
            ...currentLos,
            effective_start_date: currentLos?.effective_start_date ? 
            moment.utc(currentLos.effective_start_date) : null,
            effective_end_date: currentLos?.effective_end_date ? 
            moment.utc(currentLos.effective_end_date) : null,

           } : {},
          losIsNew: !!isEmpty(id),
          inProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
    event.stopPropagation()
  }

  getWeightZones = () => {
    this.setState({ inProgress: true })
    fetchBillingRates(this.state.account.id).then((result) => {
      if (result.success) {
        const rates = result.rates || []
        this.setState(
          {
            // data: rates,
            inProgress: false,
          },
          () => {
            this.renderRateData()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  getCategories = (levelOfServices) => {
    const losCategories = []
    levelOfServices.forEach((los) => {
      const losIndex = _.findIndex(losCategories, {los_code: los.los_code});
      if(losIndex < 0){
        losCategories.push({
          los_code: los.los_code,
          los_name: los.los_name,
          noOfContracts: levelOfServices.filter(rec => rec.los_code === los.los_code).length
        });
      }
    });
    return losCategories;
  }

  getLos = (setWeights = true, losId = '', zoneId = '') => {
    this.setState({ inProgress: true })
    fetchLos(this.state.account.id).then((result) => {
      if (result.success) {
        const levelOfServices = result.los || [];
        const losCategories = this.getCategories(levelOfServices)
        
        this.setState({ inProgress: false, losCategories });
        if (setWeights) {
          this.setLosData(result)
        } else {
          this.updateLosData(result, losId, zoneId)
        }
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  setLosData = (result) => {
    const levelOfServices = result.los || []
    this.setState(
      {
        levelOfServices: levelOfServices.map((record) => {
          const levelOfService = Object.assign({}, record, {
            zones: _.cloneDeep(this.state.zones),
            weights: [],
          })
          return levelOfService
        }),
        inProgress: false,
      },
      () => {
        this.renderRateData()
      },
    )
  }

  updateLosData = (result, losId, zoneId) => {
    const { levelOfServices, editEnabledLos } = this.state
    const newLoses = result.los || []
    const existingLosIndex = _.findIndex(levelOfServices, ['los_id', losId])
    if (existingLosIndex >= 0) {
      const existingLos = _.find(levelOfServices, { los_id: losId })
      const levelOfService = _.find(newLoses, { los_id: losId })
      const zoneRates = levelOfService.rates.filter(
        (rate) => rate.zone_id === zoneId,
      )
      const losZones = existingLos.zones || []
      const weightsData = !isEmpty(existingLos.weights)
        ? existingLos.weights
        : []
      zoneRates.forEach((record) => {
        let tempId = randomNumber()
        const existRecord = _.find(weightsData, {
          min_weight: record.min_weight,
          max_weight: record.max_weight,
        })
        if (
          isEmpty(existRecord) &&
          !isEmpty(record.min_weight) &&
          !isEmpty(record.max_weight)
        ) {
          weightsData.push({
            id: tempId,
            min_weight: record.min_weight,
            max_weight: record.max_weight,
            isNew: false,
            apply_cwt: !isEmpty(record.apply_cwt) ? record.apply_cwt : '',
            range_type: !isEmpty(record.range_type) ? record.range_type : '',
            apply_additional: record.apply_additional,
            free_pallets:!isEmpty(record.free_pallets) ? record.free_pallets :'',
          })
        } else if (!isEmpty(existRecord)) {
          tempId = existRecord.id
        }
        const zoneIndex = _.findIndex(losZones, { zone_id: record.zone_id })
        if (zoneIndex < 0) {
          losZones.push({
            zone_id: record.zone_id,
            zone_name: '',
            [tempId]: {
              key: record.id,
              cost: parseFloat(record.amount),
              breakpoint_weight: !isEmpty(record.breakpoint_weight)
                ? record.breakpoint_weight
                : '',
              isEditable: false,
              min_amount: parseFloat(record.min_amount),
              max_amount: parseFloat(record.max_amount),
              weight_min_max_charge_editable: false,
            },
          })
        } else {
          losZones[zoneIndex][tempId].cost = parseFloat(record.amount)
          losZones[zoneIndex][tempId].min_amount = parseFloat(record.min_amount)
          losZones[zoneIndex][tempId].max_amount = parseFloat(record.max_amount)
          losZones[zoneIndex][tempId].breakpoint_weight = !isEmpty(
            record.breakpoint_weight,
          )
            ? record.breakpoint_weight
            : ''
        }
      })

      const zoneInfo = existingLos.zone_info || []

      losZones.forEach((zone, index) => {
        const minWeightRecord = _.find(zoneInfo, { zone_id: zone.zone_id });
		const isValidRecord = !isEmpty(minWeightRecord) &&
          _.isObject(minWeightRecord);
        losZones[index].min_charge =
          isValidRecord &&
          minWeightRecord.min_charge
            ? parseFloat(minWeightRecord.min_charge)
            : 0.0
		losZones[index].max_charge =
          isValidRecord &&
          minWeightRecord.max_charge
            ? parseFloat(minWeightRecord.max_charge)
            : 0.0
      })
      const weights = _.sortBy(weightsData, 'min_weight')
      const hasMaxWeight =
        weights.filter(
          (weight) => weight.max_weight === AppConfig.rangeMaxValue,
        ).length > 0
      // if(!hasMaxWeight){
      //   weights.push({
      //     id: randomNumber(),
      //     min_weight: null,
      //     max_weight: null,
      //     isNew: true,
      //   });
      // }

      existingLos.zones = losZones
      existingLos.weights = weights
      levelOfServices[existingLosIndex] = _.cloneDeep(existingLos)
      this.setState({
        levelOfServices,
      })
    }
  }

  setInitializeData = () => {
    this.getAccountZones(() => {
      // this.getWeightZones();
      this.getLos()
    })
  }

  renderRateData = () => {
    const { levelOfServices, editEnabledLos } = this.state
    const zones = [...this.state.zones]
    this.setState({ inProgress: true })
    levelOfServices.forEach((levelOfService, losIndex) => {
      const rates = levelOfService?.rates ? [...levelOfService.rates] : [];
      const losZones = levelOfService.zones
      const los = Object.assign({}, levelOfService)
      const weightsData = isEmpty(los.weights) ? los.weights : []
      rates.forEach((record) => {
        let tempId = randomNumber()
        const existRecord = _.find(weightsData, {
          min_weight: record.min_weight,
          max_weight: record.max_weight,
        })
        if (
          isEmpty(existRecord) &&
          !isEmpty(record.min_weight) &&
          !isEmpty(record.max_weight)
        ) {
          weightsData.push({
            id: tempId,
            min_weight: record.min_weight,
            max_weight: record.max_weight,
            isNew: false,
            apply_cwt: !isEmpty(record.apply_cwt) ? record.apply_cwt : '',
            range_type: !isEmpty(record.range_type) ? record.range_type : '',
            apply_additional: record?.apply_additional === 'true',
            free_pallets: !isEmpty(record.free_pallets) ? Number(record.free_pallets) : 0,
          })
        } else if (!isEmpty(existRecord)) {
          tempId = existRecord.id
        }
        const zoneIndex = _.findIndex(losZones, { zone_id: record.zone_id })
        if (zoneIndex < 0) {
          losZones.push({
            zone_id: record.zone_id,
            zone_name: record.name || zones.length + 1,
            [tempId]: {
              key: record.id,
              cost: parseFloat(record.amount),
              breakpoint_weight: !isEmpty(record.breakpoint_weight)
                ? record.breakpoint_weight
                : '',
              isEditable: false,
              min_amount: !isEmpty(record.min_amount) ? parseFloat(record.min_amount) : '',
              max_amount: !isEmpty(record.max_amount) ? parseFloat(record.max_amount) : '',
              weight_min_max_charge_editable: false,
            },
          })
        } else {
          losZones[zoneIndex][tempId] = {
            key: record.id,
            cost: parseFloat(record.amount),
            breakpoint_weight: !isEmpty(record.breakpoint_weight)
              ? record.breakpoint_weight
              : '',
            min_amount: !isEmpty(record.min_amount) ? parseFloat(record.min_amount) : '',
            max_amount: !isEmpty(record.max_amount) ? parseFloat(record.max_amount) : '',
            weight_min_max_charge_editable: editEnabledLos[levelOfService.los_id] &&
            editEnabledLos[levelOfService.los_id][record.zone_id] &&
            editEnabledLos[levelOfService.los_id][record.zone_id][tempId] &&
              !isEmpty(
                editEnabledLos[levelOfService.los_id][record.zone_id][tempId]['min_amount'],
              )
              ? true
              : false,
            isEditable:
              editEnabledLos[levelOfService.los_id] &&
              editEnabledLos[levelOfService.los_id][record.zone_id] &&
              editEnabledLos[levelOfService.los_id][record.zone_id][tempId] &&
              !isEmpty(
                editEnabledLos[levelOfService.los_id][record.zone_id][tempId]['cost'],
              )
                ? true
                : false,
          }
        }
      })

      const zoneInfo = los.zone_info || []

      losZones.forEach((zone, index) => {
        const minWeightRecord = _.find(zoneInfo, { zone_id: zone.zone_id });
        const isValidRecord = !isEmpty(minWeightRecord) && _.isObject(minWeightRecord);
            losZones[index].min_charge =
              isValidRecord &&
              minWeightRecord.min_charge
                ? parseFloat(minWeightRecord.min_charge)
                : 0.0
            losZones[index].max_charge =
                  isValidRecord &&
                  minWeightRecord.max_charge
                    ? parseFloat(minWeightRecord.max_charge)
                    : 0.0
                losZones[index].min_max_charge_editable = false
                weightsData.forEach((weightRecord) => {
                  if (isEmpty(losZones[index][weightRecord.id])) {
                    losZones[index][weightRecord.id] = {
                      key: '',
                      cost: '',
                      breakpoint_weight: '',
                      isEditable: false,
                    }
                  }
                })
              })
            const weights = _.sortBy(weightsData, 'min_weight')
            const hasMaxWeight =
              weights.filter(
                (weight) => weight.max_weight === AppConfig.rangeMaxValue,
              ).length > 0
            if (!hasMaxWeight) {
              weights.push({
                id: randomNumber(),
                min_weight: null,
                max_weight: null,
                isNew: true,
                los_range: los.configurations?.los_range
                  ? los.configurations.los_range
                  : '',
              })
            }

            los.zones = losZones
            los.weights = weights
            levelOfServices[losIndex] = los
    })
    this.setState({
      inProgress: false,
      levelOfServices,
    })
  }

  handleOnChange = (index, element, value) => {
    this.props.onChange(index, element, value)
  }

  handleDeleteWeight = (record, losId) => {
    this.setState({ inProgress: true })
    const data = {
      account_id: this.state.account.id,
      min_weight: record.min_weight,
      max_weight: record.max_weight,
      level_of_service_id: losId,
    }
    this.setState({ inProgress: true })
    deleteWeights(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.setState({ inProgress: false })
        this.setInitializeData()
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false })
      }
    })
  }

  editWeightRange = (losId, currentVals, configurations) => {
    const { los_range } = configurations
    const currentLos = _.find(this.state.levelOfServices, { los_id: losId })
    this.setState({
      currentLos,
      showWeightForm: true,
      currentRatecard: {
        ...this.state.currentRatecard,
        ...currentVals,
        los_range,
        apply_additional:currentVals.apply_additional,
        free_pallets: currentVals.free_pallets,
        range_type:
          los_range !== "WEIGHT"
            ? !isEmpty(currentVals.range_type)
              ? ["true", "false"].includes(currentVals.range_type)
                ? "PER"
                : currentVals.range_type
              : ""
            : !isEmpty(currentVals.range_type)
            ? currentVals.range_type
            : "false",
      },
      oldWeightVals: currentVals,
      losIsNew: false,
      isEditing: true,
    })
  }
  computeModalTitle = () => {
    const { currentRatecard } = this.state
    switch (currentRatecard.los_range) {
      case 'PALLET':
        return currentRatecard.isNew
          ? I18n.t('account.billing.pallet.add')
          : I18n.t('account.billing.pallet.update')
      case 'WEIGHT':
        return currentRatecard.isNew
          ? I18n.t('account.billing.weights.add')
          : I18n.t('account.billing.weights.update')
      case 'CABINET':
        return currentRatecard.isNew
          ? I18n.t('account.billing.cabinet.add')
          : I18n.t('account.billing.cabinet.update')
      case 'HOURS':
        return currentRatecard.isNew
          ? I18n.t('account.billing.hours.add')
          : I18n.t('account.billing.hours.update')
      case 'MILES':
        return currentRatecard.isNew
          ? I18n.t('account.billing.miles.add')
          : I18n.t('account.billing.miles.update')
      case 'ITEMS':
        return currentRatecard.isNew
          ? I18n.t("account.billing.orderItem.add")
          : I18n.t("account.billing.orderItem.update");
      case 'PER_CUBE':
        return currentRatecard.isNew
          ? I18n.t("account.billing.perCube.add")
          : I18n.t("account.billing.perCube.update");
      case 'PIECES':
        return currentRatecard.isNew
          ? I18n.t("account.billing.perPiece.add")
          : I18n.t("account.billing.perPiece.update");
      default:
        return ''
    }
  }

  renderWeightModal = () => {
    return (
      <BaseModal
        title={this.computeModalTitle()}
        onCancel={() => this.closeWeightForm()}
        width="50%"
        style={{ top: 50 }}
        maskClosable={false}
      >
        <WeightForm
          currentRatecard={this.state.currentRatecard}
          currentLos={this.state.currentLos}
          handleSave={this.handleWeightSave}
          weights={this.state.weights || []}
          deleteClick={this.handleDeleteWeight}
          isEditing={this.state.isEditing}
          applyAdditional={this.state.applyAdditional}
          freePallets={this.state.freePallets}
          handleReceiveData={this.handleReceiveData}
        />
      </BaseModal>
    )
  }

  closeWeightForm = () => {
    this.setState({
      currentRatecard: {},
      showWeightForm: false,
      isEditing: false,
    })
  }

  handleWeightForm = (losId, record) => {
    console.log("record--->", record)
    const currentLos = _.find(this.state.levelOfServices, { los_id: losId })
    this.setState({
      currentLos,
      currentRatecard: record,
      showWeightForm: true,
    })
  }

  // clearing input values for existing ones to show only once at a time
  showInputValue = (losId, zoneID, weightRecordId, value, type) => {
    const levelOfServices = [...this.state.levelOfServices]
    const { editEnabledLos } = this.state
    const losIndex = _.findIndex(levelOfServices, { los_id: losId })
    if (
      losIndex >= 0 &&
      !isEmpty(editEnabledLos[losId]) &&
      _.isObject(editEnabledLos[losId])
    ) {
      const currentLos = levelOfServices[losIndex]
      const zones = [...currentLos.zones]
      Object.keys(editEnabledLos[losId]).forEach((zone) => {
        const currentZoneIndex = _.findIndex(zones, ['zone_id', zone])
        if (
          currentZoneIndex >= 0 &&
          !isEmpty(editEnabledLos[losId][zone]) &&
          _.isObject(editEnabledLos[losId][zone])
        ) {
          const currentZone = zones[currentZoneIndex]
          Object.keys(editEnabledLos[losId][zone]).forEach((element) => {
            if (['min_charge', 'max_charge'].includes(element)) {
              currentZone.min_max_charge_editable = false
            }  else {
              if (isEmpty(currentZone[element])) {
                currentZone[element] = {}
              }
              if(type === 'range_min_max_charge'){ 
                currentZone[element].weight_min_max_charge_editable = false
              }else {
                currentZone[element].isEditable = false
              }
              
            }
          })
          zones[currentZoneIndex] = currentZone
        }
      })
      levelOfServices[losIndex] = currentLos
      delete editEnabledLos[losId]
      this.setState(
        {
          editEnabledLos,
          levelOfServices,
        },
        () => {
          this.navigateToFunction(losId, zoneID, weightRecordId, value, type)
        },
      )
    } else {
      this.navigateToFunction(losId, zoneID, weightRecordId, value, type)
    }
  }

  navigateToFunction = (losId, zoneID, weightRecordId, value, type = '') => {
    if (type === 'min_max_charge') {
      this.handleZoneMinCharge(losId, zoneID, value)
    } else {
      this.handleZoneWeightForm(losId, zoneID, weightRecordId, value, type)
    }
  }

  handleZoneWeightForm = (losId, zoneID, weightRecordId, value, type = "weight", cb = null) => {
    const levelOfServices = [...this.state.levelOfServices]
    const losIndex = _.findIndex(levelOfServices, { los_id: losId })
    if (losIndex >= 0) {
      const currentLos = levelOfServices[losIndex]
      const zones = [...currentLos.zones]
      const currentZoneIndex = _.findIndex(zones, ['zone_id', zoneID])
      if (currentZoneIndex >= 0) {
        const currentZone = zones[currentZoneIndex]
        if (currentZone[weightRecordId]) {
          currentZone[weightRecordId] = {
            ...currentZone[weightRecordId],
          }
        }
        if( type === 'weight'){
          currentZone[weightRecordId].isEditable = value
          this.handleOnChangeRateMatrix(
            currentLos.los_id,
            zoneID,
            weightRecordId,
            'cost',
            currentZone[weightRecordId].cost,
          )

        } else if(type === 'range_min_max_charge'){
          currentZone[weightRecordId].weight_min_max_charge_editable = value
          this.handleOnChangeRateMatrix(
            currentLos.los_id,
            zoneID,
            weightRecordId,
            'range_min_max_charge',
            {
              min_amount:  currentZone[weightRecordId].min_amount,
              max_amount:  currentZone[weightRecordId].max_amount,
            }
          )

        }
        
        zones[currentZoneIndex] = currentZone
      }
      currentLos.zones = [...zones]
      levelOfServices[losIndex] = currentLos
      this.setState(
        {
          levelOfServices,
        },
        cb,
      )
    }
  }

  handleReceiveData = (data) => {
    this.setState({applyAdditional: data?.applyAdditional, freePallets: data?.freePallets})
  }

  handleWeightSave = (record) => {
    if (this.state.isEditing) {
      const { currentLos, oldWeightVals } = this.state
      const data = {
        old_min_weight: oldWeightVals.min_weight,
        old_max_weight: oldWeightVals.max_weight,
        new_min_weight: record.min_weight,
        new_max_weight: record.max_weight,
        account_id: this.state.account.id,
        level_of_service_id: currentLos.los_id,
        apply_cwt: record.apply_cwt,
        range_type: record.range_type,
      }
      // if (record.los_range === 'WEIGHT') {
      //   delete data.range_type
      // }
      if (record.los_range === 'PALLET' && record.range_type=='PER') {
        data.apply_additional = record.apply_additional;
        data.free_pallets = record.free_pallets
      }
      this.setState({ inProgress: true })
      updateLosWeightRange(data)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'))
            this.setInitializeData()
            this.setState({
              showWeightForm: false,
              isEditing: false,
            })
          } else {
            alertMessage(result.errors, 'error', 10)
          }
        })
        .finally(() => {
          this.setState({
            inProgress: false,
          })
        })
    } else {
      const { weights, currentLos } = this.state
      const data = {
        account_id: this.state.account.id,
        min_weight: record.min_weight,
        max_weight: record.max_weight,
        level_of_service_id: currentLos.los_id,
        apply_cwt: !isEmpty(record.apply_cwt) ? record.apply_cwt : '',
        range_type: record.range_type ? record.range_type : 'PER',
        apply_additional: record.apply_additional,
        free_pallets:record.free_pallets,
      }
      if (
        record.los_range === "PALLET" ||
        record.los_range === "HOURS" ||
        record.los_range === "MILES" ||
        record.los_range === "ITEMS" ||
        record.los_range === "PER_CUBE"
      ) {
        delete data.apply_cwt;
      }
      // if (record.los_range === 'WEIGHT') {
      //   delete data.range_type
      // }
      this.setState({ inProgress: true })
      saveWeights(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'))
          this.setState({ inProgress: false, showWeightForm: false })
          this.setInitializeData()
        } else {
          alertMessage(result.errors, 'error', 10)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleZoneWeightSave = (losId, zoneId, record) => {
    const { editEnabledLos } = this.state
    if (
      !isEmpty(editEnabledLos[losId]) &&
      !isEmpty(editEnabledLos[losId][zoneId]) &&
      !isEmpty(editEnabledLos[losId][zoneId][record.id])
    ) {
      const levelOfServices = [...this.state.levelOfServices]
      const losIndex = _.findIndex(this.state.levelOfServices, {
        los_id: losId,
      })
      if (losIndex >= 0) {
        const currentLos = levelOfServices[losIndex]
        const zones = [...currentLos.zones]
        const currentZoneIndex = _.findIndex(zones, ['zone_id', zoneId])
        if (currentZoneIndex >= 0 && zones[currentZoneIndex][record.id]) {
          const currentZone = zones[currentZoneIndex];
          const minMaxRec = currentZone[record.id].weight_min_max_charge_editable ? editEnabledLos[losId][zoneId][record.id] : currentZone[record.id]
          const min_amount = !isEmpty(minMaxRec) && !isEmpty(minMaxRec['min_amount']) ? parseFloat(minMaxRec.min_amount) : '';
          const max_amount = !isEmpty(minMaxRec) && !isEmpty(minMaxRec['max_amount']) ? parseFloat(minMaxRec.max_amount) : '';
          let isValid = true;
          if(!isEmpty(minMaxRec) && !isEmpty(min_amount, true) && 
              !isEmpty(max_amount, true) && min_amount >= max_amount ){
              isValid = false;
          }
          if(isValid){
            const costRec = currentZone[record.id].isEditable ? editEnabledLos[losId][zoneId][record.id] : currentZone[record.id];
            const cost = !isEmpty(costRec) && !isEmpty(costRec['cost']) ? costRec.cost : '';
            const data = {
              billing_rate_id:
                zones[currentZoneIndex][record.id] &&
                zones[currentZoneIndex][record.id].key
                  ? zones[currentZoneIndex][record.id].key
                  : '',
              amount: cost ? parseFloat(cost) : 0.0,
              min_weight: record.min_weight,
              max_weight: record.max_weight,
              zone_id: zoneId,
              level_of_service_id: losId,
              account_id: this.state.account.id,
              min_amount,
              max_amount,
            }
            saveZoneCost(data).then((result) => {
              if (result.success) {
                alertMessage(I18n.t('messages.saved'))
                zones[currentZoneIndex][record.id].cost = cost
                currentLos.zones = [...zones]
                levelOfServices[losIndex] = _.cloneDeep(currentLos)
                this.setState({ inProgress: false, levelOfServices }, () => {
                  this.handleClearRateMatrix(losId, zoneId, record.id, currentZone[record.id].isEditable ? 'weight' : 'range_min_max_charge')
                  this.getLos(false, losId, zoneId)
                })
              } else {
                alertMessage(result.errors, 'error', 10)
              }
            })
          } else {
            alertMessage(`Max charge ${I18n.t('los.gt_min_charge')}`, 'error', 10)
          }

        }
        }
      }
    }

  handleMinChargeSave = (losId, zoneId) => {
    const { editEnabledLos } = this.state
    if (
      !isEmpty(editEnabledLos[losId]) &&
      !isEmpty(editEnabledLos[losId][zoneId])
    ) {
      const levelOfServices = [...this.state.levelOfServices]
      const losIndex = _.findIndex(this.state.levelOfServices, {
        los_id: losId,
      })
      if (losIndex >= 0) {
        const currentLos = levelOfServices[losIndex]
        const zones = [...currentLos.zones]
        const currentZoneIndex = _.findIndex(zones, ['zone_id', zoneId])
        if (currentZoneIndex >= 0) {
          const minCost = !isEmpty(editEnabledLos[losId][zoneId].min_charge)
            ? parseFloat(editEnabledLos[losId][zoneId].min_charge)
            : ''
		  const maxCost = !isEmpty(editEnabledLos[losId][zoneId].max_charge)
            ? parseFloat(editEnabledLos[losId][zoneId].max_charge)
            : ''
		  let isValid = true;
		  if( !isEmpty(minCost, true) && 
		  	  !isEmpty(maxCost, true) && minCost >= maxCost ){
			isValid = false;
		  }
		  if(isValid){
        const data = {
          min_charge: minCost,
          max_charge: maxCost,
          zone_id: zoneId,
          level_of_service_id: losId,
          account_id: this.state.account.id,
        }
        saveZoneMinCharge(data).then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'))
            zones[currentZoneIndex].min_charge = minCost
            zones[currentZoneIndex].max_charge = maxCost
            currentLos.zones = [...zones]
            levelOfServices[losIndex] = _.cloneDeep(currentLos)
            this.setState({ inProgress: false, levelOfServices }, () => {
              this.handleClearRateMatrix(losId, zoneId, '', 'min_max_charge')
              this.getLos(true, losId, zoneId)
            })
          } else {
            alertMessage(result.errors, 'error', 10)
          }
        })
		  } else {
			  alertMessage(`Max charge ${I18n.t('los.gt_min_charge')}`, 'error', 10)
		  }
          
        }
      }
    }
  }

  handleOnChangeRateMatrix = (losId, zoneId, weightId, element, value, e) => {
    if (e && _.isObject(e)) {
    }

    const { editEnabledLos } = this.state
    if (isEmpty(editEnabledLos[losId])) {
      editEnabledLos[losId] = {}
    }
    if (isEmpty(editEnabledLos[losId][zoneId])) {
      editEnabledLos[losId][zoneId] = {}
    }
    if (['min_charge', 'max_charge'].includes(element)) {
      editEnabledLos[losId][zoneId][element] = value
    } else if(element === 'min_max_charge'){
      editEnabledLos[losId][zoneId]['min_charge'] = value.min_charge
      editEnabledLos[losId][zoneId]['max_charge'] = value.max_charge
    } else if(['range_min_max_charge', 'min_amount','max_amount'].includes(element)){
      if (isEmpty(editEnabledLos[losId][zoneId][weightId])) {
        editEnabledLos[losId][zoneId][weightId] = {}
      }
      if(element === "range_min_max_charge"){
        editEnabledLos[losId][zoneId][weightId]= value
      } else{
        editEnabledLos[losId][zoneId][weightId]= {
          ... editEnabledLos[losId][zoneId][weightId],
          [element]: value,
        }
      }
     
      // editEnabledLos[losId][zoneId][weightId]['min_amount'] = value.min_amount
      // editEnabledLos[losId][zoneId][weightId]['max_amount'] = value.max_amount
    } else if(element === 'cost') {
      if (isEmpty(editEnabledLos[losId][zoneId][weightId])) {
        editEnabledLos[losId][zoneId][weightId] = {}
      }
      editEnabledLos[losId][zoneId][weightId]['cost'] = value;
    }
    this.setState({
      editEnabledLos,
    })
  }

  handleKeyDown = (losId, zoneId, record, element, e) => {
    if (['min_charge', 'max_charge'].includes(element)) {
      if (e.key === 'Enter') {
        this.handleMinChargeSave(losId, zoneId)
      } else if (e.key === 'Escape') {
        this.handleClearRateMatrix(losId, zoneId, '', 'min_max_charge')
      }
    } else {
      if (e.key === 'Enter') {
        this.handleZoneWeightSave(losId, zoneId, record)
      } else if (e.key === 'Escape') {
        if(['min_amount', 'max_amount'].includes(element)){
          this.handleClearRateMatrix(losId, zoneId, record.id, 'range_min_max_charge')
        } else{
          this.handleClearRateMatrix(losId, zoneId, record.id, 'weight')
        } 
      }
    }
  }

  handleClearRateMatrix = (losId, zoneId, weightId= "", element, cb = null) => {
    const { editEnabledLos } = this.state
    if (editEnabledLos[losId] && editEnabledLos[losId][zoneId]) {
      if (element === 'min_max_charge') {
        delete editEnabledLos[losId][zoneId].min_charge
		    delete editEnabledLos[losId][zoneId].max_charge
        this.handleZoneMinCharge(losId, zoneId, false)
      } else {
        if (
          editEnabledLos[losId] &&
          editEnabledLos[losId][zoneId] &&
          !isEmpty(editEnabledLos[losId][zoneId][weightId])
        ) {
          delete editEnabledLos[losId][zoneId][weightId]
          this.handleZoneWeightForm(losId, zoneId, weightId,false, element)
        } else {
          this.handleZoneWeightForm(losId, zoneId, weightId, false, element)
        }
      }
      this.setState({
        editEnabledLos,
      })
    }
  }

  handleZoneMinCharge = (losId, zoneId, value) => {
    const levelOfServices = [...this.state.levelOfServices]
    const losIndex = _.findIndex(this.state.levelOfServices, { los_id: losId })
    if (losIndex >= 0) {
      const currentLos = levelOfServices[losIndex]
      const zones = [...currentLos.zones]
      const currentZoneIndex = _.findIndex(zones, ['zone_id', zoneId])
      if (currentZoneIndex >= 0) {
        const currentZone = zones[currentZoneIndex]
        currentZone.min_max_charge_editable = value
        if (value) {
          this.handleOnChangeRateMatrix(
            currentLos.los_id,
            currentZone.zone_id,
            '',
            'min_max_charge',
            {
              min_charge: currentZone.min_charge,
              max_charge: currentZone.max_charge,
			      }
          )
        }
        zones[currentZoneIndex] = currentZone
      }
      currentLos.zones = [...zones]
      levelOfServices[losIndex] = currentLos
      this.setState({
        levelOfServices,
      })
    }
  }

  showLosFrom = (event, id) => {
    let currentLos = {}
    if (!isEmpty(id)) {
      currentLos = _.find(this.state.levelOfServices, { los_id: id })
    }
    this.setState({
      showLos: true,
      currentLos: !isEmpty(currentLos) ? currentLos : {},
      losIsNew: !!isEmpty(id),
    })
    event.stopPropagation()
  }

  closeLosForm = () => {
    this.setState({
      showLos: false,
      losIsNew: false,
    })
  }

  renderLosForm = () => {
    const contracts = this.state.currentLos?.id ? 
    this.state.levelOfServices.filter(los => 
      this.state.currentLos.code === los.los_code && 
      this.state.currentLos.id !== los.los_id
    ) : 
    []
    return (<BaseModal
      title={this.state.losIsNew ? I18n.t('los.add') : I18n.t('los.update')}
      onCancel={() => this.handleSaveSuccessOfLos()}
      width="67%"
      style={{ top: 50 }}
      maskClosable={false}
    >
      <LosForm
        account={this.state.account}
        currentLos={this.state.currentLos}
        handleSaveSuccess={this.handleSaveSuccessOfLos}
        onCancel={this.handleSaveSuccessOfLos} // closeLosForm
        isNew={this.state.losIsNew}
        contracts={contracts}
      />
    </BaseModal>
  )
}

  handleDeleteLos = (event, id) => {
    const { levelOfServices } = this.state
    this.setState({ inProgress: true })
    deleteLos(id, { id }).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        const losIndex = _.findIndex(levelOfServices, ['los_id', id])
        if (losIndex >= 0) {
          levelOfServices.splice(losIndex, 1)
        }
        const losCategories = this.getCategories(levelOfServices)
        this.setState({ inProgress: false, levelOfServices, losCategories })
      } else {
        alertMessage(result.errors, 'error', 10)
      }
    })
    event.stopPropagation()
  }

  handleSaveSuccessOfLos = () => {
    this.setState(
      {
        showLos: false,
        losIsNew: false,
      },
      () => {
        this.setInitializeData()
      },
    )
  }

  setLosResult = (levelOfServices) => {
    const losCategories = this.getCategories(levelOfServices)
    this.setState(
      {
        levelOfServices: levelOfServices.map((levelOfService) =>
          Object.assign({}, levelOfService, {
            zones: _.cloneDeep(this.state.zones),
            weights: [],
          }),
        ),
        losCategories,
        // data: rates,
        inProgress: false,
      },
      () => {
        this.renderRateData()
      },
    )
  }

  handleLosCodeUpdate = (oldLosCode, newLosCode, losName, levelOfServices) => {
    const newLevelOfServices = levelOfServices.map(los => {
      if(los.los_code === oldLosCode){
        return {...los, los_code: newLosCode, los_name: losName }
      }
      return los;
    })
    this.setLosResult(newLevelOfServices)

  }

  handleCloneLos = (event, id) => {
    this.setState({ inProgress: true })
    cloneLosDetails(id, this.state.account.id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.cloned'))
        const levelOfServices = result.los || [];
        this.setLosResult(levelOfServices)
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
    event.stopPropagation()
  }

  showLOSData = (losCode) => {
    const currentLOSs = this.state.levelOfServices.filter(los => los.los_code === losCode);
    return currentLOSs.length > 0 ? (
      <Collapse
            defaultActiveKey={[currentLOSs[0].los_id]}
            style={{ height: 'auto' }}
            expandIconPosition="right"
            className="marginBottom20 custom-sub-collapse"
            accordion
          >
      {currentLOSs.map((los) => (
            <Panel
              size="small"
              header={
                <Row>
                  <Col xs={6} className='paddingTop5 textBold'>
                   {los.effective_start_date ? moment.utc(los.effective_start_date).format(AppConfig.dateFormat) : ''}
                   {los.effective_start_date ? " - " : ''}
                   {los.effective_end_date ? moment.utc(los.effective_end_date).format(AppConfig.dateFormat) : 'Till Now.'}
                  </Col>
                  {/* <Col xs={6} className="paddingTop5">
                    <span className="los_heading">
                      {I18n.t('los.service')}
                    </span>
                    :&nbsp;&nbsp;{' '}
                    <span className="los_value">
                      {los.los_name}
                    </span>
                  </Col>
                  <Col xs={6} className="paddingTop5">
                    <span className="los_heading">
                      {I18n.t('los.service_code')}
                    </span>
                    :&nbsp;&nbsp;{' '}
                    <span className="los_value">
                      {los.los_code}
                    </span>
                  </Col> */}
                  <Col xs={6} className="paddingTop5">
                    <span className="los_heading">
                      {I18n.t('los.service_duration')}
                    </span>
                    :&nbsp;&nbsp;{' '}
                    <span className="los_value">
                      {los.los_duration
                        ? `${los.los_duration} ` + 'min'
                        : 'NA'}
                    </span>
                  </Col>
                  <Col xs={3} className="paddingTop5">
                    <span className="los_heading">
                      {I18n.t('los.accessorial')}
                    </span>
                    :&nbsp;&nbsp;{' '}
                    <span className="los_value">
                      {los?.accessorial?.length || 0}
                    </span>
                  </Col>
                  <Col xs={3} className="alignRight" onClick={e => e.stopPropagation()}>
                    {checkServiceExistance('LOSD') && (
                      <Tooltip title={ I18n.t("general.delete") }>
                      <Popconfirm
                        placement="left"
                        title={I18n.t('messages.delete_confirm')}
                        onConfirm={(event) =>
                          this.handleDeleteLos(event, los.los_id)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button shape="circle">
                          <DeleteIcon/>
                          
                        </Button>
                      </Popconfirm>
                      </Tooltip>
                    )}
                    &nbsp;
                    {checkServiceExistance('LOSU') && (
                      <Tooltip title={ I18n.t("general.edit") }>
                      <Button
                        shape="circle"
                        onClick={(event) =>
                          this.getLosDetails(event, los.los_id)
                        }
                      >
                        <EditIcon/>
                      </Button>
                      </Tooltip>
                    )}
                    &nbsp;
                    {checkServiceExistance('LOSCL') && (
                      <Tooltip title={ I18n.t("tooltip.clone") }>
                      <Button
                        shape="circle"
                        onClick={(event) =>
                          this.handleCloneLos(event, los.los_id)
                        }
                      >
                        {' '}
                        <Icon type="copy" />{' '}
                      </Button>
                      </Tooltip>
                    )}
                    &nbsp;
                    {checkServiceExistance('MLOSC') && (
                      <AddNewContract 
                        contract={los}
                        account_id={this.state.account.id}
                        handleSuccess={(levelOfServices) => this.setLosResult(levelOfServices)}
                        contracts={currentLOSs}
                      />
                    )}
                    &nbsp;
                    {/* {los.accessorial && los.accessorial.length > 0 &&
                     <Tooltip
                       title={los.accessorial ? <Fragment> <p>{los.accessorial.join(', ')} </p></Fragment> : ''}
                     >
                       <Button type="primary" onClick={event => event.stopPropagation()}>{I18n.t('menu.accessorial')}</Button>
                     </Tooltip>
                     } */}
                  </Col>
                </Row>
              }
              key={los.los_id}
            >
              <Row gutter={8}>
                <Col xs={24} md={24} lg={24} sm={24}>
                  <LosList
                    isEditWeightRange
                    bordered
                    size="small"
                    los={los}
                    rowKey={'zone_id'}
                    data={los.zones}
                    weights={los.weights}
                    pagination={{
                      position: 'none',
                    }}
                    editableRecord={
                      !isEmpty(
                        this.state.editEnabledLos[los.los_id],
                      )
                        ? this.state.editEnabledLos[los.los_id]
                        : {}
                    }
                    editWeightRange={(
                      weightRange,
                      configurations,
                    ) => {
                      this.editWeightRange(
                        los.los_id,
                        weightRange,
                        configurations,
                      )
                    }}
                    takeMaxRangeValue
                    handleClear={(zone, weightId, element) =>
                      this.handleClearRateMatrix(
                        los.los_id,
                        zone,
                        weightId,
                        element,
                      )
                    }
                    onChange={(zone, weightId, element, value) =>
                      this.handleOnChangeRateMatrix(
                        los.los_id,
                        zone,
                        weightId,
                        element,
                        value,
                      )
                    }
                    handleWeight={(weight) =>
                      this.handleWeightForm(los.los_id, weight)
                    }
                    handleZoneWeight={(zoneId, weightId, value) =>
                      this.showInputValue(
                        los.los_id,
                        zoneId,
                        weightId,
                        value,
                        'weight',
                      )
                    }
                    handleRangeMinMaxRangeChange={(zoneId, weightId, value) =>
                      this.showInputValue(
                        los.los_id,
                        zoneId,
                        weightId,
                        value,
                        'range_min_max_charge',
                      )
                    }
                    handleZoneMinCharge={(zone, value) =>
                      this.showInputValue(
                        los.los_id,
                        zone,
                        '',
                        value,
                        'min_max_charge',
                      )
                    }
                    deleteClick={this.handleDeleteWeight}
                    handleMinChargeSave={(zone) =>
                      this.handleMinChargeSave(los.los_id, zone)
                    }
                    handleZoneWeightSave={(zone, record) =>
                      this.handleZoneWeightSave(
                        los.los_id,
                        zone,
                        record,
                      )
                    }
                    handleKeyDown={(zone, record, element, e) =>
                      this.handleKeyDown(
                        los.los_id,
                        zone,
                        record,
                        element,
                        e,
                      )
                    }
                    showMincharge={false}
                    showMinMaxcharge={los?.configurations?.apply_min_max_charge_on_each_range != true}
                  />
                </Col>
                {false && (
                  <Col xs={6}>
                    <Card
                      size="small"
                      title={I18n.t('menu.accessorial')}
                      bodyStyle={{ minHeight: 100 }}
                      extra={
                        <Button shape="circle">
                          <Icon
                            type="plus-circle"
                            theme="twoTone"
                          />
                        </Button>
                      }
                    />
                  </Col>
                )}
              </Row>
            </Panel>
          
      ))}
      </Collapse>
    ) : (
      <Empty
        description={
          <h3>
            Please add Level of service to create <b>Rate Matrix</b>
          </h3>
        }
      >
        <Button
          type="primary"
          onClick={(event) => this.showLosFrom(event)}
        >
          Create Now
        </Button>
      </Empty>
    )
  }

  render() {
    return (
      <div className="rate_matrix ordersList">
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row gutter={16}>
            {checkServiceExistance('LOSC') &&
              this.state.zones.length > 0 ?
               (
                this.state.losCategories.length >0 &&  <Col xs={24} className="alignRight">
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={(event) => this.showLosFrom(event)}
                    size="small"
                    className='marginBottom10'
                  >
                    {I18n.t('los.add')}
                  </Button>
                </Col>
              )
              :
              <Fragment/>
            }
            <Col xs={24}>
              {checkServiceExistance('LOSI') && this.state.zones.length > 0 ? (
                <div>
                  { this.state.losCategories?.length ? 
                    <Collapse accordion 
                    className='customCollapse' 
                    expandIconPosition="right" 
                    defaultActiveKey={[this.state.losCategories[0].los_code]}

                  >
                      {
                        this.state.losCategories.map(los =>
                          (<Panel size="small" 
                          key={los.los_code}
                          header={
                            <Row>
                              <Col xs={6} className=''>
                                {`${los.los_name} - (${los.los_code})`}&nbsp;
                                <span onClick={e => e.stopPropagation()}>
                                <EditLos
                                  los={los}
                                  account_id={this.state.account.id}
                                  handleSuccess={this.handleLosCodeUpdate}
                                />
                                </span>
                              </Col>
                              <Col xs={14} >
                              </Col>
                              <Col xs={4} className='alignRight textBold'>
                                  <span>No. of Contracts : {los.noOfContracts}</span>
                              </Col>
                            </Row>
                          }
                        >
                          {this.showLOSData(los.los_code)}
                        </Panel>
                        ))
                      }
                    </Collapse>
                  :
                  <Empty
                  description={
                    <h3>
                      Please {I18n.t('los.add')} to view the <b>Rate Matrix</b>
                    </h3>
                  }
                >
                  <Button
                    type="primary"
                    onClick={(event) => this.showLosFrom(event)}
                    icon='plus-circle'
                  >
                    Create Now
                  </Button>
                </Empty>
  }
                </div>
              ) : (
                <Empty
                  description={
                    <h3>
                      Please add zones to create <b>Rate Matrix</b>
                    </h3>
                  }
                >
                  <Button
                    type="primary"
                    onClick={() => this.props.goToTab('3')}
                    icon='plus-circle'
                  >
                    Create Now
                  </Button>
                </Empty>
              )}
            </Col>
          </Row>
          <Row>{FormErrors(this.state.errors)}</Row>
        </Spin>
        {this.state.showWeightForm && this.renderWeightModal()}
        {this.state.showLos && this.renderLosForm()}
      </div>
    )
  }
}

DeliveryCharges.propTypes = {
  account: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  goToTab: PropTypes.func.isRequired,
}

export default DeliveryCharges
