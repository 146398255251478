import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
} from '../../common/UIComponents'
import {
  alertMessage,
  formatDate,
} from '../../common/Common'
import { _ } from 'core-js'
import { fetchOrders } from '../../api/OrdersApi'
import I18n from '../../common/I18n'

const UnassignedCount = ({
  dateFilter,
  warehouse_id,
  showUnassigned = false,
  buttonType,
  className = '',
  isDisabled = false,
  size = 'default',
  changesOrdersState,
  doRefreshUnallocated,
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])  
  const [startDate, setStartDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [refreshUnallocated, setRefreshUnallocated] = useState(false)
  const [ warehouseId, setWarehouseId] = useState('');
  const [totalUnassignedCount, setTotalUnassignedCount] = useState(0);

  React.useEffect(() => {
    let isChanged = false;
    if( !_.isEqual(dateFilter.fromDate, startDate)){
       isChanged = true;
       setStartDate(dateFilter.fromDate);
    }
    if( !_.isEqual(dateFilter.toDate, toDate)){
       isChanged = true;
       setToDate(dateFilter.toDate);
    }
    if(warehouseId !== warehouse_id){
       isChanged = true;
       setWarehouseId(warehouse_id);
    }
    if( doRefreshUnallocated === true){
       isChanged = true;
    }
    if(isChanged){
     getOrders()
    }
    
  }, [dateFilter, warehouseId, doRefreshUnallocated])

  const getOrders = async () => {

    try {
      const page = 1
      const perPage = 'ALL'
      const orderNumber = ''
      const formattedFromDate = formatDate(dateFilter.fromDate)
      const formattedToDate = formatDate(dateFilter.toDate)
      const searchKeyType = ''
      const searchKeyValue = ''
      const scheduleOrdersType = I18n.t(
        'order.filters.schedule_orders.scheduled_key',
      )
      setLoading(true)
      const zone_ids = []
      const globalSearch = true
      const response = await fetchOrders({
        status: 'PREPLAN_ORDERS',
        fromDate: formattedFromDate,
        todate: formattedToDate,
        page,
        perPage,
        orderNumber,
        sortBy: 'none',
        sortByType: 'descend',
        accountCode: '',
        warehouseId,
        searchKeyType,
        searchKeyValue,
        scheduleOrdersType,
        globalSearch,
        zone_ids,
      })
      if (response.success) {
        // const filter = this.state.filter;
        const orders = response.orders.customer_orders;
        setTotalUnassignedCount(orders.length,)
      } else {
        setErrors([...response.errors])
        // throw new Error(response.errors[0])
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Button 
        type={buttonType ? buttonType : 'primary'}
        size={size ? size : 'small'}
        onClick={changesOrdersState}
        className={className ? className : ''}
        loading={loading}
        disabled={isDisabled}
      >
        {showUnassigned
          ? `Hide ${I18n.t('order.unallocated')}`
          : `${I18n.t('order.unallocated')}: ${
              totalUnassignedCount
            }`}{' '}
      </Button>
    </Fragment>
  )
}

UnassignedCount.propTypes = {

  changesOrdersState: PropTypes.func.isRequired,
}

export default UnassignedCount
