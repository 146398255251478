import React, { Component, useContext, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import "antd/dist/antd.css";
import { Table, Search, Tooltip, Button, Row, Col, Select, Option } from "../common/UIComponents";
import _ from "lodash";
import { Resizable } from "react-resizable";
import AppConfig from "../config/AppConfig";
import TablePopupContext from "../containers/TablePopupContext";
import { alertMessage } from "../common/Common";
import { UserContext } from "../context/UserContext";
import { toScreenConfigFormat } from "../helpers/common";

const ResizeableTitle = (props) => {
  const { onResize, width, borderColor, shouldResize , ...restProps } = props;
  const [ isResizing, setIsResizing ] = useState(false);

  const handleResize = (event, { size }) => {
    onResize && onResize(event, { size });
  };

  const handleResizeStart = (event) => {
    setIsResizing(true);
  };

  const handleResizeStop = (event) => {
    setIsResizing(false);
  };

  const columnStyle = {
    position: 'relative',
    borderRight: shouldResize ? isResizing ? `4px solid ${borderColor || '#3E5C76'}` : '1px solid rgba(62, 92, 118, 0.5)' : 'none',
    paddingRight: '8px', // Add extra padding to accommodate the resizing indicator
    ...restProps.style,
  };

  const handleStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '5px',
    zIndex: 1,
  };

  return (
    <Resizable
      width={ width }
      height={ 0 }
      onResize={ shouldResize ? handleResize : null}
      onResizeStart={ handleResizeStart }
      onResizeStop={ handleResizeStop }
      draggableOpts={ { enableUserSelectHack: false } }

    >
      <th { ...restProps } style={ columnStyle } className={`resizable-header-cell ${shouldResize ? '' : 'non-resizable-column'} ${restProps.className}`}>
        { isResizing && <div style={ handleStyle } /> }
        { restProps.children }
      </th>

    </Resizable>
  );
};



const BaseList2 = ({
  showSearch,
  searchPlaceholder,
  data,
  onSearch,
  rowKey,
  rowClassName,
  bordered,
  pagination,
  size,
  onRow,
  tableChange,
  rowSelection,
  expandedRowRender,
  onExpand,
  scroll = {y :  "calc(100vh - 400px)"},
  locale,
  footer,
  title,
  components,
  isLoading,
  tableName,
  containerStyle,
  renderExtraHeader = () => null,
  nonResizableCols,
  onColWidthChange,
  bodyRow,
  ...rest
}) => {
  const { columns: initialColumns = [] } = rest;
  const [ resizableColumns, setResizableColumns ] = useState(initialColumns);
  const [ draggingColumnIndex, setDraggingColumnIndex ] = useState(-1);
  const [ madeChanges, setMadeChanges ] = useState(false);
  const {currentUser , setCurrentUser , updateColWidths}= useContext(UserContext);
  const [ popup, setPopup ] = useState({
    visible: false,
    x: 0,
    y: 0,
    record: null,
  });
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);
  // const onRow = (record) => ({
  //   onContextMenu: (event) => {
  //     event.preventDefault();
  //     if (!popup.visible) {
  //       const onClickOutside = () => {
  //         setPopup({ ...popup, visible: false, record: null });
  //         document.removeEventListener('click', onClickOutside);
  //       };
  //       document.addEventListener('click', onClickOutside);
  //     }
  //     const containerRect = tableContainerRef.current.getBoundingClientRect();
  //     const x = event.clientX - containerRect.left;
  //     const y = event.clientY - containerRect.top;

  //     setPopup({
  //       record,
  //       visible: true,
  //       x,
  //       y,
  //     });
  //   },
  // });


  // Reset dragging state when resizing stops
  const handleStopResize = () => {
    setTimeout(() => {
      setDraggingColumnIndex(-1);
    }, 0);
  };

  const handleResizeWidth = (col, resizeWidth) => {
    // check if column has min width if yes then set min width as resize width
    if (col.minWidth && (col.minWidth > resizeWidth)) {
      return col.minWidth;
    }
    // check if column has max width if yes then set max width as resize width
    if (col.maxWidth && (col.maxWidth < resizeWidth)) {
      return col.maxWidth;
    }
    if(resizeWidth < 20){
      return 20;
    }
    return resizeWidth;
  };

  const handleResize = (index) => (e, { size }) => {
    setMadeChanges(true);
    setDraggingColumnIndex(index);
    const updatedColumns = [ ...resizableColumns ];
    updatedColumns[ index ] = {
      ...updatedColumns[ index ],
      width: handleResizeWidth(updatedColumns[ index ],size.width),
    };
    setResizableColumns(updatedColumns);
    if(onColWidthChange){
      const keyWidthMap = updatedColumns.map((col) => { return { key: col.key, width: col.width }; });
      onColWidthChange(keyWidthMap , true)
    }

    window.addEventListener('mouseup', handleStopResize);
  };

  useEffect(() => {
    const saveWidths = _.get(currentUser , `column_widths.${tableName}` , {});
    const storedColumns = Object.keys(saveWidths).map((key) => {
      return {
        key,
        width: saveWidths[key],
      };
    });
    if (storedColumns) {
      const updatedColumns = [ ...initialColumns ];
      storedColumns.forEach((col) => {
        const index = updatedColumns.findIndex((column) => column.key === col.key);
        if (index !== -1) {
          updatedColumns[ index ] = {
            ...updatedColumns[ index ],
            width: col.width,
          };
        }
      }
      );
      setResizableColumns(updatedColumns);
    } else {
      let updatedColumns = [ ...initialColumns ];
      updatedColumns = updatedColumns.map((col) => {
        return {
          ...col,
          width: col.width || 100,
        };
      });
      setResizableColumns(updatedColumns);
    }
    return () => {
      window.removeEventListener('mouseup', handleStopResize);
    };
  }, [ initialColumns ]);

  const saveColumnWidths = () => {
    const storableColumns = resizableColumns.map((col) => { return { key: col.key, width: col.width || 100 }; });
    setMadeChanges(false);
    updateColWidths(tableName , toScreenConfigFormat(storableColumns));
    onColWidthChange(storableColumns , false);
    alertMessage("Saved successfully", "success");
  };

  // Render the table footer
  const renderHeader = () => {
    return (
      <Row type="flex" justify="space-between" gutter={ 16 } >
        <Col>
          { madeChanges && (
            <Button
              icon="save"
              size="small"
              onClick={ saveColumnWidths }
              style={ {
                background: "#3f7196",
                opacity: 1,
                color: "white",
              } }
            >
              Save Column Widths
            </Button>
          ) }
        </Col>
        <Col>
          { renderExtraHeader() }
        </Col>
      </Row>
    );
  }

  // Prepare the columns configuration
  const preparedColumns = resizableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width || 100,
      onResize: handleResize(index),
      className: draggingColumnIndex === index ? 'dragging-column' : '',
      shouldResize: !nonResizableCols.includes(column.key),
    }),
    // hide all sorters when resizing
    sorter: draggingColumnIndex !== -1 ? false : col.sorter,
  }));

  // Render the search component
  const renderSearch = () => {
    if (showSearch) {
      return (
        <div>
          <Search
            placeholder={ searchPlaceholder }
            onSearch={ (value) => onSearch(value) }
            style={ { width: 200 } }
          />
        </div>
      );
    }
    return null;
  };

  const calcScroll = (scroll, columns) => {
    let x = 0;
    columns.forEach((col) => {
      x += col.width || 100;
    });
    const scrollEle = { ...scroll, x }
    if (data.length > 0 && data.length < 5) {
      delete scrollEle.y;
    }
    return scrollEle;
  };

  const localeObject = {
    emptyText: (
      <h4 className="alignCenter">
        No Records Found
      </h4>
    ),
    ...locale,
  };

  return (
    <div className="table-wrapper" ref={ tableContainerRef } style={ containerStyle }>
      { renderSearch() }

      <Table
        ref={ tableRef }
        loading={ isLoading ? isLoading : false }
        rowKey={ rowKey ? rowKey : 'id' }
        bordered={ bordered }
        className="table resizable-table"
        rowClassName={ rowClassName || ((record, index) => (!index & 1 ? 'table-row-light' : 'table-row-dark')) }
        dataSource={Array.isArray(data) ? data : []}
        columns={ preparedColumns }
        pagination={ pagination?.position !== 'none' ? { ...pagination, pageSize: pagination?.pageSize ?? AppConfig.perPage } : { position: 'none', pageSize: data.length ?? 20 } }
        size={ size ? size : 'middle' }
        onRow={ onRow }
        onChange={ tableChange }
        rowSelection={
          rowSelection && !isLoading 
            ? {
              ...rowSelection,
              order: 2,
            }
            : undefined
        }
        expandedRowRender={ expandedRowRender }
        onExpand={ onExpand }
        scroll={calcScroll(scroll, resizableColumns)}
        locale={ localeObject }
        title={ () => renderHeader() }
        footer={footer ? footer : undefined}
        components={{
          header: {
            cell: ResizeableTitle,
          },
          // body: {
          //   row: {},
          // },
          ...(bodyRow ? { body: { row: bodyRow } } : {}),
        }}
      // { ...rest }
      />
      {/* <TablePopupContext popup={ popup } saveWidth={saveColumnWidths} /> */}

    </div>
  );
};





BaseList2.propTypes = {
  rowKey: PropTypes.string,
  size: PropTypes.string,
  expandedRowKeys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape(),
  tableChange: PropTypes.func,
  showSearch: PropTypes.bool,
  onRow: PropTypes.func,
  footer: PropTypes.func,
  title: PropTypes.func,
  onSearch: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  rowClassName: PropTypes.string,
  bordered: PropTypes.bool,
  isLoading: PropTypes.bool,
  onExpand: PropTypes.func,
  nonResizableCols: PropTypes.arrayOf(PropTypes.string),
  onColWidthChange: PropTypes.func,
  
};

BaseList2.defaultProps = {
  rowKey: 'id',
  size: 'middle',
  pagination: {},
  tableChange: null,
  showSearch: false,
  onSearch: null,
  onRow: null,
  searchPlaceholder: 'search',
  footer: null,
  title: null,
  rowClassName: '',
  bordered: false,
  isLoading: false,
  onExpand: () => { },
  nonResizableCols: [],
  onColWidthChange: () => { },
};

export default BaseList2;