import React from "react";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";
import { ScreenKeys } from "../../../containers/constants";

function ListDriverSettlement(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: "789045",
      hawb: "123ABC",
      mawb: "456DEF",
      eligible_percentage : "12%",
      eligible_charge : "$ 111",
      settlement_percentage : "100%",
      item_value : "$500",
      item_type : "555",
      item_charge : "$112",
      item_name : "111",
      item_description : "1234",
      driver_code: "562",
      warehouse: "Location 1",
      account_code : 'ABBC',
      service_date : "1-2-2200",
      settlement_date : "8-5-2024",
      customer_name: "ABCD",
      company_name : "ABC123",
      status : "Pending",
      vehicle_type : "ABC123"
    },
    {
      key: 2,
      customer_order_number: "123456",
      hawb: "ABC111",
      mawb: "DEF456",
      eligible_percentage : "12%",
      eligible_charge : "$ 111",
      settlement_percentage : "100%",
      item_value : "$500",
      item_type : "555",
      item_charge : "$112",
      item_name : "111",
      item_description : "1234",
      driver_code: "402",
      warehouse: "Location 2",
      account_code : 'ABC',
      service_date : "1-2-2200",
      settlement_date : "8-5-2024",
      customer_name: "ABCD",
      company_name : "ABC123",
      status : "Approved",
      vehicle_type : "ABC123"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: "Order No",
      dataIndex: "customer_order_number",
    },
    {
      key: "hawb",
      title: "HAWB",
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: "MAWB",
      dataIndex: "mawb",
    },
    {
      key: "eligible_percentage",
      title: "Eligibility(%)",
      dataIndex: "eligible_percentage",
    },
    {
      key: "eligible_charge",
      title: "Eligible($)",
      dataIndex: "eligible_charge",
    },
    {
      key: "settlement_percentage",
      title: "Settlement Percentage(%)",
      dataIndex: "settlement_percentage",
    },
    {
      key: "item_value",
      title: "Settlement($)",
      dataIndex: "item_value",
    },
    {
      key: "vehicle_type",
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
    },
    {
      key: "item_type",
      title: "Charge Type",
      dataIndex: "item_type",
    },
    {
      key: "item_charge",
      title: "Charge($)",
      dataIndex: "item_charge",
    },
    {
      key: "item_name",
      title: "Charge Description",
      dataIndex: "item_name",
    },
    {
      key: "item_description",
      title: "Description",
      dataIndex: "item_description",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      key: "driver_code",
      title: "Driver",
      dataIndex: "driver_code",
    },
    {
      key: "warehouse",
      title: "Warehouse",
      dataIndex: "warehouse",
    },
    {
      key: "account_code",
      title: "Account Code",
      dataIndex: "account_code",
    },
    {
      key: "service_date",
      title: "Service Date",
      dataIndex: "service_date",
    },
    {
      key: "settlement_date",
      title: "Settlement Date",
      dataIndex: "settlement_date",
    },
    {
      key: "customer_name",
      title: <EndUserTitle/>,
      dataIndex: "customer_name",
    },
    {
      key: "company_name",
      title: "Company",
      dataIndex: "company_name",
    },
    

  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = ScreenKeys.AIR_CARGO_SETTLEMENTS;

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )

}

export default ListDriverSettlement;