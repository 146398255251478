/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react'
import moment from 'moment'
import I18n from "../../common/I18n";
import {
  DatePicker,
  Checkbox,
  Row,
  Col,
  Tooltip,
  Select,
  FormItem,
  //TimePicker,
} from '../../common/UIComponents'
import AppConfig from '../../config/AppConfig'
import {
  getDisabledHours,
  getDisabledMinutes,
} from '../../helpers/date_functions'
import MaterialTimePicker from '../orders/MaterialTimePicker';

const QuoteAppointment = (props) => {
  const { appointment = { item_option : []}, itemOnChange, isReadOnly, isMilitaryTime } = props
  const item_option = appointment?.item_option && appointment.item_option.length > 0 ? appointment.item_option : [];
  return (
    <Row gutter={16} type="flex">
      <Col>
        <FormItem label="Type" require={true}>
          <Select
            value={item_option.length > 0 ? item_option[0] : "ANY"}
            showSearch
            size="small"
            style={{
              width: "100%",
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              props.itemOptionOnChange("item_option", e, true, "checkbox")
            }
          >
            {AppConfig.quoteApptTypes.map((aptRecord) => (
              <Select.Option key={aptRecord.type} value={aptRecord.type}>
                {aptRecord.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Col>
      <Col>
        <FormItem label={I18n.t("general.date")} require={true}>
          <DatePicker
            size="small"
            style={{ width: "100%", marginLeft: "1%" }}
            placeholder="Select Date"
            format="Do MMM YYYY"
            onChange={(e) => itemOnChange("item_preference", e)}
            value={
              appointment.item_preference
                ? moment(appointment.item_preference)
                : null
            }
            dateRender={(current) => {
              const style = {};
              const pickerDate = current.format("YYYY-MM-DD");
              return (
                <div className="ant-calendar-date fontSize12" style={style}>
                  {current.date()}
                </div>
              );
            }}
            defaultValue={null}
            // onOk={this.onOk}
            disabledDate={
              // current => current && (current < moment())
              (current) =>
                current &&
                current <=
                  moment(
                    moment().add(AppConfig.orderFieldDuration - 24, "hours"),
                    "YYYY-MM-DD"
                  )
            }
            disabled={isReadOnly}
          />
        </FormItem>
      </Col>
      {/* <Col>  */}
      <Col xs={item_option.includes("ANY") ? 4 : 7}>
        {item_option.includes("ANY") ? (
          <FormItem label="Time">
            <div className="textBold fontSize14" style={{ marginTop: 5 }}>
              {I18n.t("quotes.any_time")}
            </div>
          </FormItem>
        ) : (
          <FormItem
            label={
              <Fragment>
                {I18n.t("general.from")} - {I18n.t("general.to")}
                <span className="alignCenter fontsize11 smallText">
                  (h:mm AM/PM)
                </span>
              </Fragment>
            }
            require={true}
          >
            <Fragment>
              <Tooltip title="h:mm AM/PM">
                <MaterialTimePicker
                  style={{ width: "140px", marginLeft: "1%", marginTop: "5px" }}
                  onChange={(e) => props.itemChange("start_time", e)}
                  value={
                    appointment.start_time
                      ? moment(appointment.start_time)
                      : null
                  }
                  computeDisabledHours={() =>
                    appointment.start_time || appointment.item_preference
                      ? getDisabledHours(
                          "start_time",
                          props.disabledStartHours,
                          appointment
                        )
                      : []
                  }
                  computeDisabledMinutes={(pickedTime) => {
                    const selectedHour = pickedTime ? pickedTime.hour() : 0;
                    return appointment.start_time || appointment.item_preference
                      ? getDisabledMinutes(
                          "start_time",
                          selectedHour,
                          appointment,
                          props.endHour
                        )
                      : [];
                  }}
                  isMilitaryTime={isMilitaryTime}
                />
              </Tooltip>
              <MaterialTimePicker
                style={{ width: "140px", marginLeft: "1%", marginTop: "5px" }}
                onChange={(e) => props.itemChange("end_time", e)}
                value={
                  appointment.end_time ? moment(appointment.end_time) : null
                }
                computeDisabledHours={() =>
                  appointment.end_time || appointment.item_preference
                    ? getDisabledHours(
                        "end_time",
                        props.disabledEndHours,
                        appointment
                      )
                    : []
                }
                computeDisabledMinutes={(pickedTime) => {
                  const selectedHour = pickedTime ? pickedTime.hour() : 0;
                  return appointment.end_time || appointment.item_preference
                    ? getDisabledMinutes(
                        "end_time",
                        selectedHour,
                        appointment,
                        props.endHour
                      )
                    : [];
                }}
                isMilitaryTime={isMilitaryTime}
              />
            </Fragment>
          </FormItem>
        )}
      </Col>
      {((props.currentQuote.type_of_order === "T" &&
      props.details.key !== "pickup_params") || props.currentQuote.type_of_order !== "T") ? (
        <Col>
          <div className="textBold fontSize12" style={{ marginTop: 22 }}>
            <Checkbox
              onChange={(e) =>
                props.itemChange("has_expedite", e.target.checked)
              }
              checked={appointment.has_expedite}
              disabled={isReadOnly}
            />
            &nbsp;
            {I18n.t("order.expedite")}
          </div>
        </Col>
      ) : null}
    </Row>
  );
}

export default QuoteAppointment
