import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MobileConfiguration } from "../../../api/MobileConfiguration";
import { alertMessage, isEmpty, validateFields } from "../../../common/Common";
import { FormItem, TextArea } from "../../../common/UIComponents";
import userStore from "../../../stores/UserStore";
import {
  fetchOrganizationConfigs,
  fetchOrganizations,
} from "../../../api/Organisations";
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";

const MobileConfigurationForms = (props) => {
  const { getFieldDecorator,validateFields } = props.form;
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { existingMobileId, closeModal,getMobileConfigFetch } = props;
  useEffect(() => {
    if (existingMobileId) {
      MobileConfiguration.fetchOne(existingMobileId)
        .then((response) => {
          if (response.success) {
            props.form.setFieldsValue({
              organizationId: response.data.mobile_configuration.organization_id,
              configuration_name: response.data.mobile_configuration.configuration_name,
              configuration: response.data.mobile_configuration.configuration,
              platform : response.data.mobile_configuration.platform,
            });
          } else {
            props.form.setFieldsValue({
              organizationId: null,
              configuration_name: "",
              configuration: "",
              platform :  "",
            });
          }
        })
        .catch((response) => {
          renderAlertMessage(response.message)
        })
        .finally (() =>{
          setIsLoading(false)
        })
    }
  }, [existingMobileId]);

  const handleFilterChange = (element, value) => {
    setOrganizationId(value);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = () => {
    // setInProgress(true);
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const orgRec = _.find(result.organizations.organizations, [
          "code",
          I18n.t("account.netmove_code"),
        ]);
        let orgId = !isEmpty(orgRec) ? orgRec._id : "";
        if (!orgId) {
          orgId =
            result.organizations?.organizations?.length > 0
              ? result.organizations.organizations[0].id
              : "";
        }
        setOrganizations(result.organizations.organizations);
        setOrganizationId(orgId);
        // setInProgress(false);
      } else {
        renderAlertMessage(result.errors)
        // setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const validateForm = () => {  
    props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const payload = {
        configuration_name: values.configuration_name,
        configuration: values.configuration,
        platform: values.platform,
        organization_id: values.organizationId,
      };

    if(existingMobileId) {
        setIsLoading(true);
        MobileConfiguration.update(existingMobileId,payload)
        .then((response) => {
            if(response.success) {
                alertMessage("Updated Succesfully", "success");
                closeModal();
                getMobileConfigFetch()
            } else {
              renderAlertMessage(response.errors)
            }
        })
        .catch((response) => {
          renderAlertMessage(response.message)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }  else {
        setIsLoading(true);
        MobileConfiguration.create(payload)
          .then((response) => {
            if (response.success) {
              alertMessage("Added Successfully", "success");
              getMobileConfigFetch()
              closeModal();
            } else {
              alertMessage("Unexpected response", "error", 10);
            }
          })
          .catch((response) => {
            renderAlertMessage(response.message)
          })
          .finally(() => {
            setIsLoading(false);
          });
    
      }
    })
}

  return (
    <div>
      <Spin spinning={isLoading}> 
      <Form onSubmit={validateForm}>
        <Row gutter={8}>
          <Col sm={24} xs={24} md={5} lg={12}>
            <FormItem label={I18n.t("organization.single")} require>
              {getFieldDecorator("organizationId", {
                initialValue: organizationId,
                rules: [
                  {
                    required: true,
                    message: "Invalid input",
                  },
                ],
              })(
                <Select
                  showSearch
                  onChange={(e) => handleFilterChange("organization", e)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {organizations.map((orgtype) => (
                    <Select.Option key={orgtype.code} value={orgtype._id}>
                      {orgtype.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={5} lg={12}>
          <FormItem label={I18n.t("MobileConfiguration.mobile_config_name")} require>
              {getFieldDecorator("configuration_name", {
                rules: [
                  {
                    required: true,
                    message: "Invalid input",
                  },
                ],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
        <Col sm={24} xs={24} md={5} lg={12}>
          <FormItem label={I18n.t("MobileConfiguration.platform")} require>
              {getFieldDecorator("platform", {
                rules: [
                  {
                    required: true,
                    message: "Invalid input",
                  },
                ],
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={5} lg={20}>
            <Form.Item label={I18n.t("MobileConfiguration.json_input")}>
              {getFieldDecorator("configuration", {
                rules: [
                  {
                    required: true,
                    message: "Invalid input",
                  },
                ],
              })(<TextArea rows="20" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => closeModal()} icon="close">
          Cancel
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          htmlType="submit"
          icon="save"
          // onClick={() => validateForm()}
          type="primary"
        >
          {existingMobileId ? I18n.t("general.update") : I18n.t("general.save")}
        </Button> 
        </Row>    
      </Form>
      </Spin>
    </div>
  );
};

export default Form.create({ name: "mobile_configuration" })(
  MobileConfigurationForms
);
