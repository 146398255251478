import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Col,
  FormItem,
  InputNumber,
  Row
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import { saveFuelSurchargeDetail } from '../../../api/FuelSurcharges';

class FuelSurchargeDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fsMasterId: this.props.fsMasterId,
      detail: {},
      inProgress: false,
      errors: [],
    };
  }

  handleOnChange = (element, value) => {
    this.setState({
      detail: Object.assign({}, this.state.detail, { [element]: value }),
    });
  }

  handleSave = () => {
    this.setState({ inProgress: true });
    const data = this.state.detail;
    data.fs_master_id = this.state.fsMasterId;
    data.organization_id = this.props.organizationId;
    saveFuelSurchargeDetail(true, data)
      .then((result) => {
        if (result.success) {
          this.setState({ errors: [] })
          this.props.onSuccess(I18n.t('messages.saved'));
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
  }

  render() {
    const { detail } = this.state;
    return (
      <div>
        <Row gutter={8}>
          <Col xs={8}>
            <FormItem label={I18n.t("general.from")} require>
              <InputNumber
                value={detail.from}
                onChange={(e) =>
                  this.handleOnChange("from", e)
                }
                min={0}
              />
            </FormItem>
          </Col>
          <Col xs={8}>
            <FormItem label={I18n.t("general.to")} require>
              <InputNumber
                value={detail.to}
                onChange={(e) =>
                  this.handleOnChange("to", e)
                }
                min={0}
              />
            </FormItem>
          </Col>
          <Col xs={8}>
            <FormItem label={I18n.t("general.value")} require>
              <InputNumber
                value={detail.value}
                min={0}
                onChange={(e) =>
                  this.handleOnChange("value", e)
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          {FormButtons(
            false,
            this.handleSave,
            this.props.onCancel,
            true,
            true,
            "default",
            I18n.t("general.save"),
            I18n.t("general.cancel")
          )}
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

export default FuelSurchargeDetailForm;

FuelSurchargeDetailForm.propTypes = {
  fsMasterId: PropTypes.string.isRequired,
};
