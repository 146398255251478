import React, { Component } from 'react'
import {
  Button,
  Col,
  Row,
  Select,
  FormItem,
  Input,
  Radio,
  Spin,
} from '../common/UIComponents'
import { alertMessage,  isEmpty } from '../common/Common'
import I18n from '../common/I18n'
import { fetchOrganizations } from '../api/Organisations'
// import { fetchSupport } from "../api/Support";
import { setAccountSetting } from '../api/AccountSetting'
import { fetchShortFormAccounts } from '../api/Account'
import { renderAlertMessage } from '../helpers/common'
import AppConfig from '../config/AppConfig'

class SettingBulkUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: {},
      organization: [],
      accounts: [],
      config_type : "ACCOUNT",
      account_ids: ['ALL'],
      organizations: [],
      // organization_id: null,

      formErrors: { Key: '', Value: '' },
      search_key_type: 'customer_order_number',
      search_key_value: '',
      pagination: {},
      inProgress: false,
      file_type: 'ALL',
      result_file_type: '',
      selectedOption: '',
      valueType: 'string',
    }
    // this.onChangeValue = this.onChangeValue.bind(this);
  }

  getAccounts = () => {
    const orgId = this.state.organization
    fetchShortFormAccounts(orgId,"",true).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
        })
      } else {
        // renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  componentDidMount() {
    this.getOrganizations()
  }
  onValueChange(key, value) {
    this.setState({
      [key]: value,
    })
  }

  handleFilterChange = (element, value) => {
    if (element === 'search_key_value') {
      value = value.trim()
    }
    this.setState({ [element]: value }, () => {})
  }

  getOrganizations = (cb) => {
    this.setState({ inProgress: true })
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const organization_id = _.find(result.organizations.organizations, [
          'code',
          I18n.t('account.netmove_code'),
        ])._id

        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id,
          },
          () => {
            this.getAccounts()
            if (cb) {
              cb()
            }
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  // Here handleUpdate function which called in ValidateTheFields.After validateTheFields then handleUpdate function will call
  ValidateTheFields = (e) => {
    const { organization, setting_key, setting_value } = this.state
    if (isEmpty(setting_key) || isEmpty(setting_value)) {
      alertMessage(I18n.t('errors.fill_all_required_fields'), 'error', 5)
    } else {
      this.handleUpdate(e)
    }
  }

  typeConvertion = (setting_value, valueType) => {
    try {
      switch (valueType) {
        case 'string': {
          return (setting_value = String(setting_value))
        }
        case 'boolean': {
          if (setting_value.toLowerCase() === 'true' || setting_value.toLowerCase() === 'false') {
            return setting_value.toLowerCase() === 'true';
          } else {
            return null;
          }
        }
        case 'number': {
          return (setting_value = Number(setting_value))
        }

        case 'array': {
          return (setting_value = Array(setting_value))
        }
        case 'object': {
          return (setting_value = JSON.parse(setting_value))
          // return (setting_value = Object(setting_value));
        }
      }
    } catch (error) { alertMessage(error.message, 'error', 10)}

  }

  handleUpdate = (e) => {
    const {
      organization,
      setting_key,
      setting_value,
      valueType,
      account_ids,
      accounts,
      config_type
    } = this.state
    e.preventDefault()

    //   if (setting_value === "string") {
    //     props.onSubmit(String(value));
    // } else {
    //     props.onSubmit((parseFloat(value) || 0));
    // }
    const getAccountValue = () => {
      return  ['',"ALL"].includes(organization) ? 'ALL' : 
        account_ids?.includes('ALL') ? accounts.map((acc) => acc.id) : account_ids
      }

    const convertedValue = this.typeConvertion(setting_value, valueType)

    const data = {
      org_id: organization,
      organization_id: organization || "ALL",
      account_ids: getAccountValue(),
      setting_name: setting_key,
      setting_value: convertedValue,
      config_type : config_type
    }
    this.setState({ inProgress: true })
    setAccountSetting(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.saved'), 'success')
        this.setState({ inProgress: false })
        // onCancsetAccountSettingel();
      } else {
        if (result.errors) {
          alertMessage(result.errors, 'error', 10)
        }
        this.setState({ inProgress: false })
      }
    })
  }
  // ...................................

  handleOnChange = (element, value) => {
    if (element === 'organization') {
      this.setState({ [element]: value }, () => {
        this.getAccounts(),
          this.setState({
            account_ids: null,
          })
      })
    } else {
      this.setState({ [element]: value })
    }
  }

  render() {
    return (
      <div className="content-outer">
        <Spin spinning={this.state.inProgress} delay={100}>
          <div className="content">
            <Row className="page-header">
              <Col xs={12}>{I18n.t('menu.update_setting')}</Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={5} lg={5}>
                <FormItem label={I18n.t('organization.single')} require>
                  <Select
                    value={this.state.organization}
                    showSearch
                    // onChange={(e) => this.handleFilterChange("organization", e)}
                    onChange={(e) => this.handleOnChange('organization', e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                  >
                    <Select.Option key="-1" value="">
                      -- ALL --
                    </Select.Option>
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={6} lg={6}>
                <FormItem
                  label={I18n.t('general.account')}
                  style={{ marginBottom: '0px' }}
                  require
                >
                  <Select
                    value={this.state.account_ids || ['ALL']}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    maxTagCount={2}
                    style={{ width: '100%' }}
                    onChange={(e) => this.handleOnChange('account_ids', e)}
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                  >
                    <Select.Option key="-2" value="ALL">
                      -- ALL --
                    </Select.Option>
                    {this.state.accounts.map((account) => (
                      <Select.Option key={account.id} value={account.id}>
                        {`${account.name} (${account.code})`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={5} lg={5}>
                <FormItem label={I18n.t('general.key')} require>
                  <Input
                    value={this.state.setting_key}
                    onChange={(e) =>
                      this.handleFilterChange('setting_key', e.target.value)
                    }
                  />
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={4} lg={4}>
                <FormItem label={I18n.t('general.value')} require>
                  <Input
                    value={this.state.setting_value}
                    onChange={(e) =>
                      this.handleFilterChange('setting_value', e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={4} lg={4}>
                <FormItem label={"Config Type"}>
                  <Select
                    value={this.state.config_type || "ACCOUNT"}
                    showSearch
                    onChange={(e) => this.handleOnChange('config_type', e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                  >
                    {AppConfig.configType.map((orgtype) => (
                      <Select.Option key={orgtype.config_value} value={orgtype.config_value}>
                        {orgtype.config_name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row className="configLableRow">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={8}
                style={{ fontWeight: 700, width: '6.33333333%' }}
              >
                {I18n.t('general.value_type')}
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={14}>
                <Radio.Group
                  value={this.state.valueType}
                  onChange={(e) =>
                    this.handleOnChange('valueType', e.target.value)
                  }
                >
                  <Radio value="boolean">{I18n.t('valueType.boolean')}</Radio>
                  <Radio value="number">{I18n.t('valueType.number')}</Radio>
                  {/* <Radio value="decimal">{I18n.t("valueType.decimal")}</Radio> */}
                  <Radio value="string">{I18n.t('valueType.string')}</Radio>
                  <Radio value="object">{I18n.t('valueType.object')}</Radio>
                  <Radio value="array">{I18n.t('valueType.array')}</Radio>
                </Radio.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={24} xs={24} md={4} lg={4} className="paddingTop20">
                <Button type="primary" onClick={this.ValidateTheFields} icon="save">
                  {I18n.t('general.save')}
                </Button>
              </Col>
            </Row>

            {/* {this.renderAPI()} */}
          </div>
        </Spin>
      </div>
    )
  }
}

export default SettingBulkUpdate
