/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  Row,
  Spin,
  Empty,
  Button,
  Icon,
  Collapse,
  Card,
} from "../../common/UIComponents";
import { alertMessage, randomNumber, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../common/FormErrors";
import LosList from "./LosList";
import { deleteWeights, saveWeights } from "../../api/BillingRates";
import { fetchAccountZones } from "../../api/BillingZones";
import { renderAlertMessage } from "../../helpers/common";

const { Panel } = Collapse;
class RateMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weights: this.props.weights || [],
      zones: this.props.zones || [],
      data: [],
      inProgress: false,
      account: this.props.account || [],
      errors: [],
      showZoneMinChargeForm: false,
      levelOfServices: [],
      editEnabledLos: {},
    };
  }

  clearForm = () => {
    this.setState({
      currentAccessorial: {},
      isNew: true,
    });
  };

  componentDidMount() {
    // this.setInitializeData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.zones, this.state.zones) ||
      !_.isEqual(nextProps.weights, this.state.weights)
    ) {
      this.setState({
        zones: nextProps.zones || [],
        weights: nextProps.weights || [],
        editEnabledLos: {},
      });
    }
  }

  getAccountZones = (cb = null) => {
    this.setState({ inProgress: true });
    fetchAccountZones(this.state.account.id).then((result) => {
      if (result.success) {
        const zones = result.zones || [];
        this.setState(
          {
            zones: zones.map((zone) => ({
              zone_id: zone.id,
              zone_name: zone.name,
              // min_charge: zone.min_charge,
            })),
            inProgress: false,
          },
          cb
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  setInitializeData = () => {
    this.getAccountZones(() => {
      // this.getWeightZones();
      this.getLos();
    });
  };

  renderRateData = () => {
    const { levelOfServices } = this.state;
    const zones = [...this.state.zones];
    this.setState({ inProgress: true });
    levelOfServices.forEach((levelOfService, losIndex) => {
      const rates = [...levelOfService.rates];
      const losZones = levelOfService.zones;
      const los = Object.assign({}, levelOfService);
      const weightsData = [];
      rates.forEach((record) => {
        let tempId = randomNumber();
        const existRecord = _.find(weightsData, {
          min_weight: record.min_weight,
          max_weight: record.max_weight,
        });
        if (
          isEmpty(existRecord) &&
          !isEmpty(record.min_weight) &&
          !isEmpty(record.max_weight)
        ) {
          weightsData.push({
            id: tempId,
            min_weight: record.min_weight,
            max_weight: record.max_weight,
            isNew: false,
          });
        } else if (!isEmpty(existRecord)) {
          tempId = existRecord.id;
        }
        const zoneIndex = _.findIndex(losZones, { zone_id: record.zone_id });
        if (zoneIndex < 0) {
          losZones.push({
            zone_id: record.zone_id,
            zone_name: record.name || zones.length + 1,
            [tempId]: {
              key: record.id,
              cost: parseFloat(record.amount),
              breakpoint_weight: !isEmpty(record.breakpoint_weight)
                ? record.breakpoint_weight
                : "",
            },
          });
        } else {
          losZones[zoneIndex][tempId] = {
            key: record.id,
            cost: parseFloat(record.amount),
            breakpoint_weight: !isEmpty(record.breakpoint_weight)
              ? record.breakpoint_weight
              : "",
          };
        }
      });

      const zoneInfo = los.zone_info || [];

      losZones.forEach((zone, index) => {
        const minWeightRecord = _.find(zoneInfo, { zone_id: zone.zone_id });
        losZones[index].min_charge =
          !isEmpty(minWeightRecord) &&
          _.isObject(minWeightRecord) &&
          minWeightRecord.min_charge
            ? parseFloat(minWeightRecord.min_charge)
            : 0.0;
        weightsData.forEach((weightRecord) => {
          if (isEmpty(losZones[index][weightRecord.id])) {
            losZones[index][weightRecord.id] = {
              key: "",
              cost: "",
              breakpoint_weight: "",
              isEditable: false,
            };
          }
        });
      });
      const weights = _.sortBy(weightsData, "min_weight");
      weights.push({
        id: randomNumber(),
        min_weight: null,
        max_weight: null,
        isNew: true,
      });
      los.zones = losZones;
      los.weights = weights;
      levelOfServices[losIndex] = los;
    });
    this.setState({
      inProgress: false,
      levelOfServices,
    });
  };

  handleOnChange = (index, element, value) => {
    this.props.onChange(index, element, value);
  };

  handleDeleteWeight = (record) => {
    this.setState({ inProgress: true });
    const data = {
      account_id: this.state.account.id,
      min_weight: record.min_weight,
      max_weight: record.max_weight,
    };
    this.setState({ inProgress: true });
    deleteWeights(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.setState({ inProgress: false });
        this.setInitializeData();
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  closeWeightForm = () => {
    this.setState({
      currentRatecard: {},
      showWeightForm: false,
    });
  };

  closeZoneWeightForm = () => {
    this.setState({
      currentRatecard: {},
      showZoneWeightForm: false,
    });
  };

  handleWeightForm = (record) => {
    this.setState({
      currentRatecard: record,
      showWeightForm: true,
    });
  };

  // clearing input values for existing ones to show only once at a time
  // clearing input values for existing ones to show only once at a time
  showInputValue = (zoneID, weightRecordId, value, type) => {
    const { editEnabledLos } = this.state;
    if (_.isObject(editEnabledLos)) {
      const zones = [...this.state.zones];
      Object.keys(editEnabledLos).forEach((zone) => {
        const currentZoneIndex = _.findIndex(zones, ["zone_id", zone]);
        if (
          currentZoneIndex >= 0 &&
          !isEmpty(editEnabledLos[zone]) &&
          _.isObject(editEnabledLos[zone])
        ) {
          const currentZone = zones[currentZoneIndex];
          Object.keys(editEnabledLos[zone]).forEach((element) => {
            if (element === "min_charge") {
              currentZone.min_charge_editable = false;
            } else {
              currentZone[element].isEditable = false;
            }
          });
          zones[currentZoneIndex] = currentZone;
        }
        delete editEnabledLos[zone];
      });

      this.setState(
        {
          editEnabledLos,
          zones,
        },
        () => {
          this.navigateToFunction(zoneID, weightRecordId, value, type);
        }
      );
    } else {
      this.navigateToFunction(zoneID, weightRecordId, value, type);
    }
  };

  navigateToFunction = (zoneID, weightRecordId, value, type = "") => {
    if (type === "min_charge") {
      this.handleZoneMinCharge(zoneID, value);
    } else {
      this.handleZoneWeightForm(zoneID, weightRecordId, value);
    }
  };

  handleZoneMinCharge = (zoneId, value) => {
    const zones = [...this.state.zones];
    const currentZoneIndex = _.findIndex(zones, ["zone_id", zoneId]);
    if (currentZoneIndex >= 0) {
      const currentZone = zones[currentZoneIndex];
      currentZone.min_charge_editable = value;
      if (value) {
        this.handleOnChangeRateMatrix(
          currentZone.zone_id,
          "min_charge",
          currentZone.min_charge
        );
      }
      zones[currentZoneIndex] = currentZone;
    }
    this.setState({
      zones,
    });
  };

  handleZoneWeightForm = (zoneID, weightRecordId, value, cb = null) => {
    const zones = [...this.state.zones];
    const currentZoneIndex = _.findIndex(zones, ["zone_id", zoneID]);
    if (currentZoneIndex >= 0) {
      const currentZone = zones[currentZoneIndex];
      if (!currentZone[weightRecordId]) {
        currentZone[weightRecordId] = {};
      }
      currentZone[weightRecordId].isEditable = value;
      this.handleOnChangeRateMatrix(
        zoneID,
        weightRecordId,
        currentZone[weightRecordId].cost
      );
      zones[currentZoneIndex] = currentZone;
    }
    this.setState({
      zones,
    });
  };

  handleWeightSave = (record) => {
    const { weights } = this.state;
    const data = {
      account_id: this.state.account.id,
      min_weight: record.min_weight,
      max_weight: record.max_weight,
    };
    this.setState({ inProgress: true });
    saveWeights(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        this.setState({ inProgress: false, showWeightForm: false });
        this.setInitializeData();
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false, showWeightForm: false });
      }
    });
  };

  //   handleZoneWeightSave = (record) => {
  //     this.setState({
  //       showZoneWeightForm: false,
  //     })
  //     this.props.onChange(record);
  //   }

  handleZoneWeightSave = (zoneId, record) => {
    const { editEnabledLos } = this.state;
    const cost = !isEmpty(editEnabledLos[zoneId][record.id])
      ? editEnabledLos[zoneId][record.id]
      : "";
    record.zone_id = zoneId;
    record.cost = cost;
    this.props.onChange(record);
  };

  handleMinChargeSave = (zoneId) => {
    const { editEnabledLos } = this.state;
    const record = {
      zone_id: zoneId,
      min_charge: !isEmpty(editEnabledLos[zoneId].min_charge)
        ? editEnabledLos[zoneId].min_charge
        : "",
    };
    this.props.onMinChargeChange(record);
  };

  handleOnChangeRateMatrix = (zoneId, element, value) => {
    const { editEnabledLos } = this.state;
    if (isEmpty(editEnabledLos[zoneId])) {
      editEnabledLos[zoneId] = {};
    }
    if (element === "min_charge") {
      editEnabledLos[zoneId][element] = value;
    } else {
      editEnabledLos[zoneId][element] = value;
    }
    this.setState({
      editEnabledLos,
    });
  };

  handleClearRateMatrix = (zoneId, element, cb = null) => {
    const { editEnabledLos } = this.state;
    if (editEnabledLos[zoneId]) {
      if (element === "min_charge") {
        delete editEnabledLos[zoneId].min_charge;
        this.handleZoneMinCharge(zoneId, false);
      } else {
        if (
          editEnabledLos[zoneId] &&
          !isEmpty(editEnabledLos[zoneId][element])
        ) {
          delete editEnabledLos[zoneId][element];
          this.handleZoneWeightForm(zoneId, element, false);
        } else {
          this.handleZoneWeightForm(zoneId, element, false);
        }
      }
      this.setState({
        editEnabledLos,
      });
    }
  };

  handleKeyDown = (zoneId, record, element, e) => {
    if (element === "min_charge") {
      if (e.key === "Enter") {
        this.handleMinChargeSave(zoneId);
      } else if (e.key === "Escape") {
        this.handleClearRateMatrix(zoneId, "min_charge");
      }
    } else {
      if (e.key === "Enter") {
        this.handleZoneWeightSave(zoneId, record);
      } else if (e.key === "Escape") {
        this.handleClearRateMatrix(zoneId, record.id);
      }
    }
  };

  render() {
    return (
      <div className="marginTop10 rate_matrix ordersList">
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row gutter={16}>
            <Col xs={24}>
              {this.state.zones.length > 0 ? (
                <div className="padding10 marginBottom10">
                  <Row gutter={8}>
                    <Col xs={24} md={24} lg={24} sm={24}>
                      <LosList
                        bordered
                        size="small"
                        data={this.state.zones}
                        weights={this.state.weights}
                        pagination={{
                          position: "none",
                        }}
                        handleWeight={(weight) => this.handleWeightForm(weight)}
                        handleZoneMinCharge={(zone, value) =>
                          this.showInputValue(zone, "", value, "min_charge")
                        }
                        deleteClick={this.handleDeleteWeight}
                        showDeleteIcon={false}
                        showMincharge
                        takeMaxRangeValue
                        onChange={(zone, element, value) =>
                          this.handleOnChangeRateMatrix(zone, element, value)
                        }
                        handleClear={(zone, element) =>
                          this.handleClearRateMatrix(zone, element)
                        }
                        handleZoneWeight={(zone, weightId, value) =>
                          this.showInputValue(zone, weightId, value, "weight")
                        }
                        editableRecord={
                          !isEmpty(this.state.editEnabledLos)
                            ? this.state.editEnabledLos
                            : {}
                        }
                        handleMinChargeSave={this.handleMinChargeSave}
                        handleZoneWeightSave={(zone, record) =>
                          this.handleZoneWeightSave(zone, record)
                        }
                        handleKeyDown={(zone, record, element, e) =>
                          this.handleKeyDown(zone, record, element, e)
                        }
                        showMinMaxcharge={false}
                      />
                    </Col>
                    {false && (
                      <Col xs={6}>
                        <Card
                          size="small"
                          title={I18n.t("menu.accessorial")}
                          bodyStyle={{ minHeight: 100 }}
                          extra={
                            <Button shape="circle">
                              <Icon type="plus-circle" theme="twoTone" />
                            </Button>
                          }
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              ) : (
                <Empty
                  description={
                    <h3>
                      Please add zones / weight ranges to create{" "}
                      <b>Rate Matrix</b>
                    </h3>
                  }
                ></Empty>
              )}
            </Col>
          </Row>
          <Row>{FormErrors(this.state.errors)}</Row>
        </Spin>
        {this.state.showZoneWeightForm && this.renderZoneWeightModal()}
        {this.state.showWeightForm && this.renderWeightModal()}
        {this.state.showZoneMinChargeForm && this.renderMinChargeModal()}
        {this.state.showLos && this.renderLosForm()}
      </div>
    );
  }
}

RateMatrix.propTypes = {
  account: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  weights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default RateMatrix;
