import React, { Fragment, useContext, useEffect, useState } from "react";
import { Tooltip, Popconfirm, TextArea, FormItem } from "../../common/UIComponents";
import { Button, Col, Form, Icon, Input, Row } from "antd";
import { checkListApi } from "../../api/CheckList";
import { alertMessage } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { renderAlertMessage } from "../../helpers/common";

const OrgDunningNotice = (props) => {
  const [dunningNoticeConfig, setDunningNoticeConfig] = useState({});
  const orgContextData = useContext(OrgContext);
  const { currentOrg, orgSettings, setOrgSettings } = orgContextData;


  const getDunningNoticeText = () =>{
    setDunningNoticeConfig({
      dunning_notice_header: orgContextData.orgSettings?.dunning_notice_header ? orgContextData.orgSettings.dunning_notice_header : "" ,
      dunning_notice_footer: orgContextData.orgSettings?.dunning_notice_footer ? orgContextData.orgSettings.dunning_notice_footer : "" ,
    });
  }


  const updateDunningNotice = () => {
    const organizationId = currentOrg.id;
    const settingNames = ["dunning_notice_header", "dunning_notice_footer"];
    const settingValues = [
        dunningNoticeConfig["dunning_notice_header"],
        dunningNoticeConfig["dunning_notice_footer"]
    ];

    const validSettings = settingNames.reduce((acc, setting_name, index) => {
        const setting_value = settingValues[index];
        if (setting_value && setting_value.trim() !== "") {
            acc.push({ setting_name, setting_value });
        }
        return acc;
    }, []);

    const promises = validSettings.map(({ setting_name, setting_value }) => {
        const payload = { organization_id: organizationId, setting_name, setting_value };
        return checkListApi.updateOrgConfig(payload);
    });

    Promise.all(promises)
        .then(results => {
            const success = results.every(result => result.success);
            if (success) {
                const updatedSettings = validSettings.reduce((acc, { setting_name, setting_value }) => {
                    acc[setting_name] = setting_value;
                    return acc;
                }, {});
                setOrgSettings({
                  ...orgSettings,
                  ...updatedSettings,
                });
                alertMessage("Updated Successfully", "success");
            } else {
                const errors = results.reduce((acc, result) => {
                    if (!result.success) {
                        acc.push(...result.errors);
                    }
                    return acc;
                }, []);
                renderAlertMessage(errors);
            }
        })
        .catch(error => {
            renderAlertMessage(["Error occurred while updating settings. Please try again."]);
        });
}
  
  useEffect(() => {
    getDunningNoticeText()
  }, [])

  const dunningNoticeConfiguration = () => {
    return (
      <Fragment>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={5} lg={20}>
            <FormItem label="Dunning Notice Header">
              <TextArea
                rows="6"
                value={dunningNoticeConfig.dunning_notice_header}
                onChange={(e) =>
                  setDunningNoticeConfig({
                    ...dunningNoticeConfig,
                    dunning_notice_header: e.target.value,
                  })
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Fragment>
    );
  };
  const dunningNoticeFooterConfiguration = () => {
    return (
      <Fragment>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={5} lg={20}>
            <FormItem label="Dunning Notice Footer">
              <TextArea
                rows="6"
                value={dunningNoticeConfig.dunning_notice_footer}
                onChange={(e) =>
                  setDunningNoticeConfig({
                    ...dunningNoticeConfig,
                    dunning_notice_footer: e.target.value,
                  })
                }
              />
            </FormItem>
          </Col>
        </Row>
      </Fragment>
    );
  };


  return (
    <>
    <Row>
    <Col span={24} className="settingHeader">
        <span className="textBold">Dunning Notice Configuration</span>
    </Col>
    </Row>
    <Row>
      <Col span={10}>
          <Col style={{ marginTop: 5 }}>{dunningNoticeConfiguration()}</Col>
      </Col>
      <Col span={2}>{""}</Col>
      <Col span={10}>
      <Col style={{marginTop:5}}> {dunningNoticeFooterConfiguration()}
    </Col>
    </Col>
      </Row>
      <Row style={{marginRight:'100px', marginTop : "30px"}}>
        <Col span={10}>{" "}
        </Col>

      <Col span={1} >
            <Button
              htmlType="submit"
              icon="save"
              onClick={() => updateDunningNotice()}
              type="primary"
            >
              Save
            </Button>
          </Col>
          <Col span={10}> {" "}
          </Col>
      </Row>
      </>
  ); 
}


export default OrgDunningNotice