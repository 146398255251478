import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row, FormItem, Select, Avatar, Button, Spin } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import { fetchActiveUsers } from '../../api/UsersApi';
import { fetchTrucks } from '../../api/TrucksApi';
import { allocateAllDrivers, allocateTruck } from '../../api/Routes';
import truckImg from '../../../assets/images/truck.png';
import { allocateDrivers, fetchAvailableDrivers } from '../../api/PreplanApi';
import RouteLabel from '../../config/RouteLabel';
import { renderAlertMessage } from '../../helpers/common';

const { Option } = Select;
class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      trucks: [],
      inProgress: false,
      driversInfo: {
        primary: {
          driverId: this.props.primaryDriver || '',
          selectedDriverId: this.props.showExistDrivers === true && this.props.primaryDriver ? this.props.primaryDriver : '',
        },
        secondary: {
          driverId: this.props.secondaryDriver || '',
          selectedDriverId: this.props.showExistDrivers === true && this.props.secondaryDriver ? this.props.secondaryDriver : '',
        },
      },
      truckInfo: {
        assignedId: this.props.truck || '',
        selectedId: '',
      },
      currentRoute: this.props.currentRoute || {},
      routeId: this.props.routeId,
    };
  }


  componentDidMount() {
    this.getUsers();
    this.getTrucks();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.routeId !== this.state.routeId) {
      this.setState({
        routeId: nextProps.routeId || '',
        currentRoute: nextProps.currentRoute || {},
      });
    }
  }

  getUsers = () => {
    this.setState({ inProgress: true });
    fetchActiveUsers(this.userTypes, 1, 1000)
      .then((result) => {
        if (result.success) {
          this.setState({
            users: result.users,
            inProgress: false,
          });
        } else {
          this.setState({ inProgress: false });
          renderAlertMessage(result.errors)
        }
      });
    // fetchAvailableDrivers(this.state.currentRoute.r_start_datetime, this.state.currentRoute.r_end_datetime)
    //   .then((result) => {
    //     if (result.success) {
    //       this.setState({
    //         users: result.users,
    //         inProgress: false,
    //       });
    //     } else {
    //       this.setState({ inProgress: false });
    //       renderAlertMessage(result.errors)
    //     }
    //   });
  };

  getTrucks = (initial, cb) => {
    // this.setState({ inProgress: true });
    fetchTrucks({ status: 'active' }, 1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            trucks: result.trucks,
            //  inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          //  this.setState({ inProgress: false });
        }
      });
  }

  handleDriverChange = (element, value, type) => {
    this.setState({
      driversInfo: Object.assign({}, this.state.driversInfo, {
        [type]: {
          ...this.state.driversInfo[type],
          [element]: value,
        },
      }),
    });
  }

  handleTruckChange = (element, value) => {
    this.setState({
      truckInfo: Object.assign({}, this.state.truckInfo, {
        [element]: value,
      }),
    });
  }


  // assignDrivertoRoute = () => {
  //   if (this.state.driversInfo.primary.selectedDriverId || this.state.driversInfo.secondary.selectedDriverId) {
  //     this.setState({
  //       assignStatus: true,
  //     });
  //     allocateAllDrivers(this.state.driversInfo.primary.selectedDriverId, this.state.driversInfo.secondary.selectedDriverId, this.state.routeId)
  //       .then((result) => {
  //         if (result.success) {
  //           if (this.state.truckInfo.selectedId) {
  //             this.assignTrucktoRoute();
  //           } else {
  //             alertMessage(I18n.t('messages.assigned_successfully'));
  //             this.resetFields();
  //           }
  //         } else {
  //           this.setState({
  //             assignStatus: false,
  //           });
  //           renderAlertMessage(result.errors)
  //         }
  //       });
  //   } else if (this.state.truckInfo.selectedId) {
  //     this.assignTrucktoRoute();
  //   } else {
  //     alertMessage('Plesse select either driver or vehicle', 'error', 5);
  //   }
  // };

  assignDrivertoRoute = () => {
    if (this.state.driversInfo.primary.selectedDriverId || this.state.driversInfo.secondary.selectedDriverId) {
      this.setState({
        assignStatus: true,
      });
      const actionType = RouteLabel.actionLabels.REASSIGN_DRIVER;
      allocateDrivers(this.state.driversInfo.primary.selectedDriverId, this.state.driversInfo.secondary.selectedDriverId, this.state.routeId, actionType)
        .then((result) => {
          if (result.success) {
            if (this.state.truckInfo.selectedId) {
              this.assignTrucktoRoute();
            } else {
              alertMessage(I18n.t('messages.assigned_successfully'));
              this.resetFields();
            }
          } else {
            this.setState({
              assignStatus: false,
            });
            renderAlertMessage(result.errors)
          }
        });
    } else if (this.state.truckInfo.selectedId) {
      this.assignTrucktoRoute();
    } else {
      alertMessage('Plesse select either driver or vehicle', 'error', 5);
    }
  };

  assignTrucktoRoute = () => {
    if (this.state.truckInfo.selectedId) {
      this.setState({
        assignStatus: true,
      });
      allocateTruck(this.state.routeId, this.state.truckInfo.selectedId)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.assigned_successfully'));
            this.resetFields();
          } else {
            this.setState({
              assignStatus: false,
            });
            renderAlertMessage(result.errors)
          }
        });
    } else {
      this.setState({
        assignStatus: false,
      });
      alertMessage('Please Select Vehicle', 'error', 5);
    }
  };

  resetFields = () => {
    this.setState({
      assignStatus: false,
      driversInfo: {
        primary: {
          driverId: this.state.driversInfo.primary.selectedDriverId,
          selectedDriverId: '',
        },
        secondary: {
          driverId: this.state.driversInfo.secondary.selectedDriverId,
          selectedDriverId: '',
        },
      },
    });
    this.props.closeWindow();
  }

  render() {
    const { driversInfo } = this.state;
    const assignString = I18n.t(`general.${driversInfo.primary.driverId ? 'reassign' : 'assign'}`);
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000} >
          <Row>
            <Col xs={24}>
              <Row>
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  <FormItem label={`${I18n.t('general.Driver')}`}>
                    <Select
                      style={{ width: '100%' }}
                      className="routes-input"
                      onChange={e => this.handleDriverChange('selectedDriverId', e, 'primary')}
                      defaultValue={`${assignString}`}
                      value={this.state.driversInfo.primary.selectedDriverId}
                      filterOption={(input, option, index) => option.props.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {this.state.users.map(province =>
                        (this.props.showExistDrivers === true || (province.id !== driversInfo.primary.driverId && province.id !== driversInfo.secondary.driverId)) &&
                        <Option key={province.id}>
                          <div>
                            {
                              province.image && (province.image.url) ?
                                <Avatar src={`${province.image.url}`} size={30} /> : <Avatar src="./../icons/user_100*100.png" alt="DriverProfile" size={30} />
                            }
                            &nbsp;{province.employee_code || province.full_name}
                          </div>
                        </Option>)}
                    </Select>
                  </FormItem>
                </Col>
                {/* <Col xs={8} style={{ padding: 10 }}>
                  <Button className="assignDriver" onClick={()=>this.assignDrivertoRoute('primary')} loading={this.state.assignStatus} disabled={routeStatus !== "COMPLETED" ? false : true}>{`${assignString}`}</Button>
                </Col> */}
              </Row>
            </Col>
            { false &&
              <Col xs={24}>
                <Row>
                  <Col xs={24} style={{ paddingBottom: 10 }}>
                    <FormItem label={`Secondary ${I18n.t('general.Driver')}`} style={{ marginTop: 10 }}>
                      <Select
                        style={{ width: '100%' }}
                        className="routes-input"
                        onChange={e => this.handleDriverChange('selectedDriverId', e, 'secondary')}
                        defaultValue={`${assignString}`}
                        value={this.state.driversInfo.secondary.selectedDriverId}
                        filterOption={(input, option, index) => option.props.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {this.state.users.map(province =>
                          (this.props.showExistDrivers === true || (province.id !== driversInfo.primary.driverId && province.id !== driversInfo.secondary.driverId)) &&
                          <Option key={province.id}>
                            <div>
                              {
                                province.image && (province.image.url) ?
                                  <Avatar src={`${province.image.url}`} size={30} /> : <Avatar src="./../icons/user_100*100.png" alt="DriverProfile" size={30} />
                              }
                              {province.full_name}
                            </div>
                          </Option>)}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            }
            {this.props.showTruck !== false && <Col xs={24} style={{ paddingBottom: 10 }}>
              <FormItem label={I18n.t('truck.single')} style={{ marginTop: 10 }}>
                <Select
                  style={{ width: '100%' }}
                  className="routes-input"
                  onChange={e => this.handleTruckChange('selectedId', e)}
                  defaultValue=""
                  value={this.state.truckInfo.selectedId}
                  filterOption={(input, option, index) => option.props.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {this.state.trucks.map(province =>
                    (
                      province.id !== this.state.truckInfo.assignedId &&
                      <Option key={province.id}>
                        <div>
                          {
                            province.image && (province.image.url) ?
                              <Avatar src={`${province.image.url}`} size={30} /> : <Avatar src={truckImg} alt="Truck" size={30} />
                          }
                          &nbsp;{province.name}
                        </div>
                      </Option>
                    ))
                  }
                </Select>
              </FormItem>
            </Col>
            }


            <Col xs={24}>
              <Row>
                <Col xs={24} className="alignCenter">
                  <Button className="assignDriver" onClick={() => this.assignDrivertoRoute()} loading={this.state.assignStatus} disabled={this.state.routeStatus === 'COMPLETED'}>{`${assignString}`}</Button>

                </Col>
              </Row>
            </Col>

          </Row>
        </Spin>
      </div>
    );
  }
}
Assignment.propTypes = {
  truck: PropTypes.string,
  primaryDriver: PropTypes.string,
  secondaryDriver: PropTypes.string,
  routeId: PropTypes.string.isRequired,
  closeWindow: PropTypes.func.isRequired,
};

Assignment.defaultProps = {
  truck: '',
  primaryDriver: '',
  secondaryDriver: '',
};
export default withRouter(Assignment);
