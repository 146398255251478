import { Button, Col, Dropdown, Icon, Row, Spin, Tag } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import AppConfig from "../config/AppConfig";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import BatchIndexList from "../components/account_ar/BatchIndexList";
import WrappedBatchCreateModal from "../components/account_ar/BatchCreateModal";
import BaseModal from "../components/BaseModal";
import I18n from "../common/I18n";
import { BatchManagementApi } from "../api/BatchManagement";
import { withRouter } from "react-router-dom";
import { Search } from "../common/UIComponents";
import BatchFilter from "../components/account_ar/BatchFilter";
import { checkServiceExistance, convertToTags, renderAlertMessage } from "../helpers/common";
import { defaultBatchHomeScreenFilter } from "../components/dispatches/helpers";
import AppliedFiltersTags from "./AppliedFiltersTags";
import moment from "moment";
import { ScreenKeys } from "./constants";
import _ from "lodash";
import { isEmpty } from "../common/Common";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
const BatchHomeScreen = ({ history, appContexts }) => {
  const { userContext } = appContexts
  const { currentUser , updateRecordsPerPage } = userContext
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const perPageKey = ScreenKeys.BATCH_HOME_SCREEN
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved)
  const [batchData, setBatchData] = useState([]);
  const [existingBatchId, setExistingBatchId] = useState(null);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [batchNumber, setBatchNumber] = useState("");
  const [showFilterWindow, setShowFilterWindow] = useState(false);
  const defaultFilter = defaultBatchHomeScreenFilter();
  const [updateSearchTags, setUpdateSearchTags] = useState(false);
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    ...defaultFilter,
  });
  const [appliedFiltersTags, setAppliedFiltersTags] = useState([]);

  const dynamicDateObject = {
    formattedFrom_date: moment(
      filterPlaceHolder.dateSearchValue.formattedFrom_date
    ).format("DD-MM-YYYY"),
    formattedTo_date: moment(
      filterPlaceHolder.dateSearchValue.formattedTo_date
    ).format("DD-MM-YYYY"),
  };

  const defaultDateObject = {
    formattedFrom_date: moment(
      defaultFilter.dateSearchValue.formattedFrom_date
    ).format("DD-MM-YYYY"),
    formattedTo_date: moment(
      defaultFilter.dateSearchValue.formattedTo_date
    ).format("DD-MM-YYYY"),
  };

  const computeTags = (filterData) => {
    const keys = ["dateSearchValue"];
    const haveDateValues =  filterData?.dateSearchValue ? findHaveDateValues(filterData.dateSearchValue, 'formattedFrom_date', 'formattedTo_date') : false;
    const filteredData = {
      dateSearchValue: {
        label: "Date Range",
        value: haveDateValues ? `${moment(filterData.dateSearchValue.formattedFrom_date).format(
          "MMM DD, YYYY"
        )} - ${moment(filterData.dateSearchValue.formattedTo_date).format(
          "MMM DD, YYYY"
        )}` : 'All Dates',
        closeable: !haveDateValues 
          ? false
          : true,
        onClose: () => {
          handleFilterRemoval("dateSearchValue");
        },
      },
    };
    //|| _.isEqual(dynamicDateObject, defaultDateObject) for closable
    const tagInfo = convertToTags({ ...filteredData }, keys, filteredData);
    setAppliedFiltersTags(tagInfo);
  };

  const handleFilterRemoval = (key) => {
    const filterData = { ...filterPlaceHolder };
    switch (key) {
      case "dateSearchValue":
        filterData.dateSearchValue.formattedFrom_date = null;
        filterData.dateSearchValue.formattedTo_date = null;
        // filterData.dateSearchValue.formattedFrom_date =
        //   defaultFilter.dateSearchValue.formattedFrom_date;
        // filterData.dateSearchValue.formattedTo_date =
        //   defaultFilter.dateSearchValue.formattedTo_date;
        break;
      default:
        break;
    }
    setFilterPlaceHolder(filterData);
    updateFilterSearchValues(filterData);
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      debounceCreateBatches();
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
      // debounceCreateBatches();
    }
    // debounceCreateBatches();
  };

  useEffect(() => {
    getCreatedBatches();
  }, [recordsPerPage]);

  useEffect(() => {
    computeTags(filterPlaceHolder);
  }, [filterPlaceHolder]);

  const handleAddClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    debounceCreateBatches();
  }, [filterPlaceHolder.sortBy, filterPlaceHolder.sortByType]);

  const closeModal = () => {
    setShowModal(false);
    setExistingBatchId(null);
  };

  const editBatch = (id) => {
    setShowModal(true);
    setExistingBatchId(id);
  };

  const updateFilterSearchValues = (filteredValue) => {
    setFilterPlaceHolder(Object.assign({}, filteredValue));
    const from_date = filteredValue.dateSearchValue.formattedFrom_date;
    const to_date = filteredValue.dateSearchValue.formattedTo_date;
    handleSearch("", from_date, to_date);
  };

  const handleSearch = (batch_number, from_date, to_date) => {
    setSearchText("");
    setIsSearching(false);
    if (!moment.isMoment(from_date)) {
      from_date = null;
    }
    if (!moment.isMoment(to_date)) {
      to_date = null;
    }
    setFilterPlaceHolder({
      ...filterPlaceHolder,
      dateSearchValue: {
        formattedFrom_date: from_date,
        formattedTo_date: to_date,
      },
    });
    const dataForTags = {
      dateSearchValue: {
        formattedFrom_date: from_date,
        formattedTo_date: to_date,
      },
    };
    computeTags(dataForTags);
    const batchNumber = batch_number;
    const fromDate = from_date ? moment(from_date).format("DD-MM-YYYY") : null;
    const toDate = to_date ? moment(to_date).format("DD-MM-YYYY") : null;
    onSearch(batchNumber, fromDate, toDate);
    setShowFilterWindow(false);
    setUpdateSearchTags(true);
  };

  const onSearch = async (batch_number, from_date, to_date) => {
    setSearchText(batch_number);
    setShowFilterWindow(false);
    try {
      setLoading(true);
      const result = await BatchManagementApi.fetch(
        1,
        recordsPerPage,
        batch_number,
        from_date,
        to_date
      );
      if (result.success) {
        setBatchData(result.data.ar_batches);
        setPagination(result?.pagination ? result.pagination : {});
        setLoading(false);
      }
    } catch (error) {
      alertMessage(error, "error");
      setLoading(false);
    }
  };

  const handleTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const redirectToManage = (id) => {
    const selectedBatch = batchData.find((batch) => batch.id === id);
    if (selectedBatch) {
      history.push({
        pathname: `/manage_batch/${id}`,
      });
      localStorage.setItem("rootPath", "batches");
      localStorage.setItem("lastPath", "batches");
    } else {
      history.push({ pathname: `/manage_checks` });
      localStorage.setItem("rootPath", "batches");
      localStorage.setItem("lastPath", "batches");
    }
  };

  const findHaveDateValues = (dateSearchObj = {}, fromDate = '', toDate = '') => {
    const haveDateValues =  dateSearchObj && dateSearchObj[fromDate] && dateSearchObj[toDate];
    return haveDateValues
  }

  const getCreatedBatches = () => {

    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const sortOrder = filterPlaceHolder.sortByType || "ascend";
    const perPage = recordsPerPage;
    const batch_number = searchText ? searchText : "";
    const haveDateValues =  filterPlaceHolder?.dateSearchValue ? findHaveDateValues(filterPlaceHolder.dateSearchValue, 'formattedFrom_date', 'formattedTo_date') : false;
    const from_date = haveDateValues ? moment(
      filterPlaceHolder.dateSearchValue.formattedFrom_date
    ).format("DD-MM-YYYY") : null;
    const to_date = filterPlaceHolder?.dateSearchValue ? moment(
      filterPlaceHolder.dateSearchValue.formattedTo_date
    ).format("DD-MM-YYYY") : null;
    const sortBy = filterPlaceHolder?.sortBy ? filterPlaceHolder.sortBy : "none"
    const sortByType = I18n.t(`general.sort_${sortOrder}`)
    setLoading(true);
    BatchManagementApi.fetch(
      page,
      perPage,
      batch_number,
      from_date,
      to_date,
      sortBy,
      sortByType
    ).then((result) => {
      if (result.success) {
        setBatchData(result.data.ar_batches);
        setBatchNumber(result.data.batch_number);
        setPagination(result.pagination);
        setLoading(false);
      } else {
        renderAlertMessage(result.errors)
        setLoading(false);
      }
    });
  };

  const debounceCreateBatches = _.debounce(getCreatedBatches, 100);

  const clearFilter = () => {
    setSearchText("");
    setIsSearching(false);
    const resetFromDate = defaultFilter.dateSearchValue.formattedFrom_date;
    const resetToDate = defaultFilter.dateSearchValue.formattedTo_date;
    handleSearch("", resetFromDate, resetToDate);
    computeTags(defaultFilter);
    setFilterPlaceHolder({ ...defaultFilter });
  };

  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag);
  };

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };

  const BatchCreationForm = () => {
    return (
      <BaseModal
        title={
          !existingBatchId
            ? I18n.t("batch_management.add_button")
            : I18n.t("batch_management.update")
        }
        visible={showModal}
        footer={null}
        onCancel={closeModal}
        maskClosable={false}
      >
        <WrappedBatchCreateModal
          closeModal={closeModal}
          getCreatedBatches={getCreatedBatches}
          existingBatchId={existingBatchId}
          batchNumber={batchNumber}
        />
      </BaseModal>
    );
  };
  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("batch_management.batches")}</Col>
          <Col xs={12}>
            <Row justify="end" type="flex" gutter={16}>
              <div className="headerOptionDiv headerFilter">
                <Search
                  size="default"
                  className="seachWithDropDown recoverySearch search_with_prefix"
                  value={searchText}
                  placeholder={I18n.t(
                    "batch_management.batch_name_placeholder"
                  )}
                  onChange={handleTextChange}
                  // onSearch={(value) => handleSearch(value, null, null)}
                  onSearch={(value) => {
                    handleSearch(value, null, null);
                    setIsSearching(value.length > 0);
                  }}
                  onInput={e => e.target.value = e.target.value.toUpperCase()}
                  onClear={() => {
                    setSearchText("");
                    clearFilter();
                  }}
                  suffix={
                    <Fragment>
                      {searchText !== "" && (
                        <Icon
                          type="close-circle"
                          theme="filled"
                          size="small"
                          onClick={() => {
                            setSearchText("");
                            clearFilter();
                          }}
                        />
                      )}
                      &nbsp;
                      <Dropdown
                        onVisibleChange={handleVisibleChange}
                        visible={showFilterWindow}
                        trigger={["click"]}
                        placement="bottomRight"
                        overlay={
                          <div
                            style={{
                              backgroundColor: "white",
                              width: 350,
                              padding: 15,
                              marginTop: 10,
                              marginRight: -12,
                            }}
                            className="boxShadow"
                          >
                            <BatchFilter
                              onSearch={onSearch}
                              clearFilter={clearFilter}
                              filterPlaceHolder={filterPlaceHolder}
                              defaultFilter={{ ...defaultFilter }}
                              setFilterPlaceHolder={updateFilterSearchValues}
                            />
                          </div>
                        }
                      >
                        <Icon
                          type="control"
                          onClick={() => setShowFilterWindow(true)}
                        />
                      </Dropdown>
                    </Fragment>
                  }
                  prefix={
                    <Row type="flex" align="middle" gutter={4}>
                      <Col>
                        <Icon type="search" />
                      </Col>
                    </Row>
                  }
                />
              </div>
              {checkServiceExistance("ARBC") && (
                <Col className="page-actions">
                  <Button type="primary" onClick={handleAddClick} icon="plus">
                    {I18n.t("batch_management.add_button")}
                  </Button>
                </Col>
              )}
              {checkServiceExistance("ARBCI") && (
                <Col className="page-actions">
                  <Button type="primary" icon="plus" onClick={redirectToManage}>
                    {I18n.t("batch_management.create_check")}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row type="flex" justify="end">
              <Col>
                <RecordsPerPage
                  onChange={onRecordChange}
                  value={recordsPerPage}
                  defaultValue={recordsPerPage}
                />
              </Col>
            </Row>
            <Row>
              <AppliedFiltersTags
                tags={appliedFiltersTags}
                clearFilter={() => clearFilter()}
                // isSearching={!isEmpty(searchText) ? true : false}
                isSearching={isSearching}
                searchText={searchText}
                updateSearchTags={updateSearchTags}
                resetSearchTags={() => setUpdateSearchTags(false)}
                searchBy={"Check"}
                hideClearButton
              />
            </Row>
            <Spin spinning={loading}>
              <div style={{ marginTop: "10px" }}>
                <BatchIndexList
                  scroll={{ y:"calc(100vh - 360px)" }}
                  data={batchData}
                  handleRedirect={(id) => redirectToManage(id)}
                  handleEdit={(id) => editBatch(id)}
                  pagination={tablePagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    onTableChange(pagination,filter, sorter, currentTable)
                  }
                />
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
      {showModal && BatchCreationForm()}
    </div>
  );
};
export default withRouter(BatchHomeScreen);
