import React from "react";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";

function ListAccessorials(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      name: "ABCD",
      code: "Daily",
      gl_code: "Test Warehouse",
      accessible_to: "ABC123",
      description: "abc123",
      edi_code : "123",
      sources: "Flat"
    },
    {
      key: 2,
      name: 1002,
      code: "Daily",
      gl_code: "Test Warehouse",
      accessible_to: "ABC456",
      description: "abc456",
      edi_code : "456",
      sources: "Flights"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "name",
      title: I18n.t("general.name"),
      dataIndex: "name",
    },
    {
      key: "code",
      title: I18n.t("general.code"),
      dataIndex: "code",
    },
    {
      key: "gl_code",
      title: I18n.t("accessorials.gl_code"),
      dataIndex: "gl_code",
    },
    {
      key: "accessible_to",
      title: "Accessible To",
      dataIndex: "accessible_to",
    },
    {
      key: "edi_code",
      title: "X12 Edi Code",
      dataIndex: "edi_code",
    },
    {
      key: "sources",
      title: "Sources",
      dataIndex: "sources",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = 'accessorial_listing'

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )
  
}

export default ListAccessorials;
