import React from "react";
import BaseList from "../../BaseList";
import { Row, Col } from "../../../common/UIComponents";
import Copyable from "../../common/Copyable";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import ShowInvoice from "../../billing_screen/ShowInvoice";
import I18n from "../../../common/I18n";
import AppConfig from "../../../config/AppConfig";

class AccountAgingReportsList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      aging_report_display_config: [],
    };
    this.columns = [
      {
        title: "Invoice Date",
        dataIndex: "approval_date",
        key: "approval_date",
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        sorter: (a, b) => compareDate(a.approval_date, b.approval_date)
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_number",
        key: "invoice_number",
        render: (text, record) => (
          <Row type="flex" gutter={4}>
            <Col>
              <ShowInvoice
                account={record.account_id}
                invoice_no={text}
                key={text}
                style={{ marginTop: -6 }}
                invoice_type={record.account_frequency_type}
                account_invoice_id={record.account_invoice_id}
                status={record.status}
                refreshList={() => {}}
                is_locked={record.is_locked}
                showActions={false}
                approvalDate={record.approval_date}
                renderType="text"
              />
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        title: I18n.t("batch_management.age"),
        dataIndex: "age",
        key: "age",
        render: (data) => (
          <div className="paddingRight30">{(data)}</div>
        ),
        className: "alignleft",
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("batch_management.total_billed_amount"),
        dataIndex: "total_billed_amount",
        key: "total_billed_amount",
        render: (data) => (
          <div className="paddingRight30">{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions
      },
      {
        title: I18n.t("batch_management.balance_amount"),
        dataIndex: "inv_balance",
        key: "inv_balance",
        render: (data) => (
          <div className="paddingRight30">{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions,
      },
    ];
  }

  componentDidMount() {
    this.getAgingConfigurations();
  }

  getAgingConfigurations = () => {
    const { orgSettings } = this.props.orgData;
    const defaultConfig = "[[0,30],[31,45],[46,60],[61,90],[91,180],[181]]";
    const configString =
      orgSettings?.aging_report_display_config ?? defaultConfig;
    const configArray = JSON.parse(configString.replace(/'/g, '"'));

    this.setState(
      {
        aging_report_display_config: configArray,
      },
      () => {
        this.setAgingColumns();
      }
    );
  };

  setAgingColumns = () => {
    const { aging_report_display_config } = this.state;

    if (!Array.isArray(aging_report_display_config)) {
      console.error("Aging report configuration is not an array:", aging_report_display_config);
      return;
    }

    const agingColumns = aging_report_display_config.map((range, index) => {      const [from, to] = range;
      let title;
      if (index === 0) {
        title = "Current";
      } else {
        title = to ? `${from}-${to} days` : `Over ${from - 1} days`;
      }
      const dataIndex = to ? `${from}-${to}` : `${from}-999999`;
      return {
        title,
        dataIndex,
        key: dataIndex,
        render: (data) => (
          <div className="paddingRight30">{checkNegitive(data)}</div>
        ),
        className: "alignRight",
      };
    });

    this.columns.splice(4, 0, ...agingColumns);
    this.forceUpdate();
  };


}

export default AccountAgingReportsList;
