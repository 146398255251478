import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { Row, Col, Button } from "../../common/UIComponents";
import EndUserTitle from "../common/EndUserTitle";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import Copyable from "../common/Copyable";
import { ellipseText } from "../../common/Common";
import { TableImageColumn } from "../../common/TableImageColumn";
class MenifestOrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    if (props) {
      this.setColumns(props);
    }
  }
  setColumns = (props) => {
    this.columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => index + 1,
        width: 35,
      },
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        width: 115,
        render: (text, record) => {
          return (
            <Row
              type="flex"
              style={{ flexFlow: "unset" }}
              gutter={4}
              align="middle"
            >
              <Fragment>
                {ellipseText(text, 10, true, null, true)}
                {text && (
                  <Col>
                    <Copyable text={text} />
                  </Col>
                )}
              </Fragment>
            </Row>
          );
        },
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        width: 115,
        render: (text, record) => {
          return (
            <Row
              type="flex"
              style={{ flexFlow: "unset" }}
              gutter={4}
              align="middle"
            >
              <Fragment>
                {ellipseText(text, 10, true, null, true)}
                {text && (
                  <Col>
                    <Copyable text={text} />
                  </Col>
                )}
              </Fragment>
            </Row>
          );
        },
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        minWidth: 120,
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_1"
            charsToLimit={null}
          />
        ),
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        minWidth: 120,
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_2"
            charsToLimit={null}
          />
        ),
      },
      {
        key: "company_name",
        title: I18n.t("general.company"),
        dataIndex: "company_name",
      },
      {
        title: I18n.t("general.order_type_short_form"),
        key: "type_of_order",
        dataIndex: "type_of_order",
        render: (data, record) => props.getConvertedOrderType(data),
      },
      {
        title: I18n.t("order.measurements"),
        key: "measurements",
        width: 250,
        render: (data, record) => props.renderMeasurements(record),
      },
      {
        key: "bar_code",
        title: I18n.t("general.barCode"),
        dataIndex: "bar_code",
        render: (data, record) => (
          data ? <TableImageColumn
            record={data}
            width={"50"}
            height={"50"}
            borderRadius={"3"}
            isBase64Data={true}
          /> : "NA"
        ),
        width: 80,
      },
      {
        title: I18n.t("account.account_code"),
        key: "account_code",
        dataIndex: "account_code",
      },
      {
        key: "notes",
        title: I18n.t("general.notes_info"),
        width: 390,
        render: (data, record) => props.renderShipperNotes(record),
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "los",
      },
      {
        key: "accessorials",
        title: I18n.t("general.accessorial"),
        dataIndex: "accessorials",
        render: (text, record, index) =>
          record.order_accessorial.map((order) => order.code).join(", "),
      },
      {
        key: "status",
        title: I18n.t("order.status"),
        dataIndex: "status",
      },
      {
        key: "consignee",
        title: <EndUserTitle />,
        dataIndex: "consignee",
        render: (text, record, index) => record.customer_first_name,
      },
      {
        key: "routing",
        title: I18n.t("order.routing"),
        dataIndex: "routing",
      },
    ];
    if(props.stopType !== "WH"){
      this.columns.push({
          key:"comments",
          title:I18n.t("order.comments"),
          dataIndex:"comments"
      })
    }
    if (!_.isEmpty(props.displayConfiguration)) {
      const { userConfiguration = {}, orgConfiguration = {} } =
        props.displayConfiguration;
      const dispatch_manifest_file_columns =
        userConfiguration.dispatch_manifest_file_columns ||
        orgConfiguration.dispatch_manifest_file_columns;
      const columns = [];

      if (Array.isArray(dispatch_manifest_file_columns)) {
        const initialColumns = this.columns.filter((col) =>
          ["index"].includes(col.key)
        );
        if (initialColumns.length > 0) {
          columns.push(...initialColumns);
        }

        dispatch_manifest_file_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });

        const actions = this.columns.find((col) => col.key === "actions");
        if (actions) {
          columns.push(actions);
        }

        this.columns = columns;
      } else {
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.displayConfiguration,
        this.props.displayConfiguration
      )
    ) {
      this.setColumns(this.props);
    }
  }
}
export default MenifestOrdersList;
