/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext,useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Card,
  Popover,
  Icon,
  Divider,
  Timeline,
  Tooltip,
  Button,
  Popconfirm,
} from "../../../common/UIComponents";
import {
  ellipseText,
  findNumberTotal,
  formatStopUTcTime,
  isEmpty,
} from "../../../common/Common";
import FormattedErrors from "../../common/FormattedErrors";
import StopDistanceInfo from "../../stops/StopDistanceInfo";
import { formatStopAddress, rearrangeStops, stopTypeFromObject } from "../../../helpers/stops";
import AppConfig from "../../../config/AppConfig";
import OrdersList from "./Orders";
import { getDependencyStops } from "../../../helpers/preplan";
import StopAddress from "../../stops/StopAddress";
import { Input } from "antd";
import { useEffect } from "react";
import { checkServiceExistance, secondsToHms } from "../../../helpers/common";
import StopCapacityInfo from "../../stops/StopCapacityInfo";
import { momentTime } from "../../../helpers/date_functions";
import StopSurfaceArea from "../../stops/StopSurfaceArea";
import { checkErrors } from "../../../helpers/routes";
import lateImagIcon from "../../../../assets/images/time_warning.png";
import DeleteIcon from "../../common/DeleteIcon";
import BranchesIcon from "../../common/BranchesIcon";
import { OrgContext } from "../../../context/OrgContext";
import { renderStopIcon } from "../../../helpers/dispatch";
import I18n from "../../../common/I18n";

const findStopDuration = (stop) => {
  const stopOrders = stop.orders || [];
  const sum = findNumberTotal(stopOrders, "service_duration", 1, 0);
  return sum;
};

const getDefaultServiceDurations = (route) => {
  let serviceDuration = {};
  route.stops.forEach((stop) => {
    const stopType = stopTypeFromObject(stop)
    if (stopType === "CS") {
      const duration = !isEmpty(stop.service_duration)
        ? Number(stop.service_duration)
        : 0;
      const durationInMins = Math.floor(duration / 60);
      serviceDuration[stop.id] = {
        duration: durationInMins, // Number(findStopDuration(stop));
        actual_duration: durationInMins,
      }
    }
  });
  return serviceDuration;
};

const Stop = (props) => {
  const {
    dragStarts,
    selectedList,
    route,
    onClickStop,
    currentStop,
    moveStop,
    deleteClick,
    rearrangeForm,
    moveOrder,
    orderDetailsViewClick,
    deleteOrderClick,
    serviceDurationChange,
    saveServiceDurations,
    deliveryStartDate,
    currentWarehouse,
    doCapacityValidation,
    displayConfiguration,
    screen_from,
    setIsItemVisible
  } = props;
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const [isMilitaryTime , setIsMilitaryTime] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (organizationSettings && organizationSettings.is_military_time ===
      "true") {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [ organizationSettings ]);


  const [reseqProcess, setReseqProcess] = useState(true);
  const [showOrdersList, setShowOrdersList] = useState(route?.stops ? route.stops.map((stop) => stop.id): []);  
  const [serviceDuration, setServiceDuration] = useState({});
  const refs = {};
  useEffect(() => {
    if (props.serviceDurationReset) {
      setServiceDuration(getDefaultServiceDurations(route));
      props.setUnsavedServiceDurationChanges(false);
      props.resetServiceDurationChanges();
    }
  }, [ props.serviceDurationReset ]);
  // onchange of route set service duration to default
  useEffect(() => {
    // onchange of route set service duration to default
    setServiceDuration(getDefaultServiceDurations(route));
    setShowOrdersList(route.stops.map((stop) => stop.id));
  }, [route]);

  useEffect(() => {
    saveServiceDurations(serviceDuration);
  }, [serviceDuration]);

  const isLinehaulRoute = route.route_type === 'line_haul';
  const ignoredStopTypes = ["WH", "DV"];
  const isNotOptimized =
    route.optimization_type === "auto" &&
    route.optimized === false &&
    (!route.optimizer_readable_error_message ||
      (_.isArray(route.optimizer_readable_error_message) &&
        route.optimizer_readable_error_message.length === 0)); // || (route.optimization_type === 'manual' && route.optimized === false)
  // let tempStops = [...(route.stops || [])]
  let tempStops = Array.isArray(route.stops) ? route.stops.map((stop) => {
    if (stop.location && stop.location.l_type === "CS") {
      stop.orders = stop.orders.map((order) => {
        if (["T", "LH"].includes(order.type_of_order)) {
          order.type_of_loc = stop.location.type_of_loc;
        }else{
          order.type_of_loc = ''
        }
        return order;
      });
    }
    return stop;
  }) : [];
  if (tempStops.length > 2 && rearrangeForm) {
    tempStops = rearrangeStops(tempStops, false);
    const stopsData = tempStops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location.l_type) &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    );
    tempStops = [...stopsData];
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // const onDragEnd = (result) => {
  //   // dropped outside the list
  //   if (!result.destination) {
  //     return;
  //   }

  //   tempStops = reorder(
  //     tempStops,
  //     result.source.index,
  //     result.destination.index,
  //   );
  // };

  // const getListStyle = isDraggingOver => ({
  //   userSelect: 'none',
  // });

  const beforeDrag = () => {
    setReseqProcess(false);
  };

  const showStopOrders = (id) => {
    const ordersList = showOrdersList;
    if (ordersList.includes(id)) {
      ordersList.splice(ordersList.indexOf(id), 1);
    } else {
      ordersList.push(id);
    }

    setShowOrdersList(ordersList);
  };

  const removeStopOrders = (id) => {
    const ordersList = showOrdersList;
    if (ordersList.includes(id)) {
      ordersList.splice(ordersList.indexOf(id), 1);
    }
    setShowOrdersList(ordersList);
  };

  const renderOrders = (stop, stopType, isDragging, stopIndex) => {
    return (
    <div className="stopBlock marginTop10 marginLeftM5">
      {!isDragging && (
        <Droppable droppableId={`orderDroppable${stop.id}`}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <OrdersList
                rowKey="customer_order_id"
                scroll={{x: "max-content"}}
                data={stop.orders || []}
                rowSelection={null}
                moveOrder={(id) =>
                  moveOrder(id, "move_order", stop.id, route.route_id)
                }
                orderDetailsViewClick={(id) =>
                  orderDetailsViewClick(id, route.route_id, stop.id)
                }
                deleteOrderClick={(id) => deleteOrderClick(id, route.route_id)}
                showAccessorial={false}
                showPod={false}
                showExceptions={false}
                showLos={false}
                showBilling={false}
                showActions={!route.isLocked}
                showMoveAction={!route.isLocked && !isLinehaulRoute}
                size="small"
                currentStop={stop}
                currentRoute={route}
                stopType={stopType}
                stopIndex={stopIndex}
                pagination={{ position: "none" }}
                displayConfiguration={displayConfiguration}
                screen_from={screen_from}
                isMilitaryTime={isMilitaryTime}
                setIsItemVisible={setIsItemVisible}
              >
                {Row}
              </OrdersList>
              {provided && provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>

    );
  };

  const onChangeStopDuration = (value, stopId) => {

    setServiceDuration({
      ...serviceDuration,
      [stopId]: {
        ...(serviceDuration[stopId] ? serviceDuration[stopId]: {}),
        duration: Number(value) ? Number(value) : 0,
      }
    });
  };

  // const setFocus = (stpId) => {
  //   refs[`textInput${stpId}`].focus();
  // };

  const setFocus = (stpId) => {
    const element = refs[`textInput${stpId}`]
    if (element) {
      element.focus();
    } else {
      console.error(`Element with ID 'textInput${stpId}' not found.`);
    }
  };
  const getOrderTypeCount = (orders) => {
    const orderTypeCount = orders.reduce(
      (acc, order) => {
        if (!isEmpty(order.type_of_loc)){
          if(order.type_of_loc === 'PICKUP'){
            acc.pickupCount++;
          } else{
            acc.deliveryCount++;
          }
        }else if (AppConfig.deliveryOrderTypes.includes(order.type_of_order)) {
          acc.deliveryCount++;
        } else if (AppConfig.pickOrderTypes.includes(order.type_of_order)) {
          acc.pickupCount++;
        }
        return acc;
      },
      { deliveryCount: 0, pickupCount: 0 }
    );
    return orderTypeCount;
  };

  const renderCompanyName = (company_name) => {
    return (
      <Row>
        <Col xs={ 24 } className="paddingTop5">
          <div
            className="fontSize10 smallText alignLeft"
            style={ { overflow: "hidden", height: 15 } }
          >
            <Tooltip title={`${I18n.t('general.company_name')}: ${company_name}`}>
              <Icon type="bank" />&nbsp;&nbsp;{company_name}
            </Tooltip>
          </div>
        </Col>
      </Row>
    );
  };

  const handleChange = (e, stopId) => {
    const newValue = parseFloat(e.target.value); // Parse the input value as a floating-point number
    if (isNaN(newValue) || newValue < 1) { // Check if the parsed value is NaN or less than 1
      setError("Minimum value should be 1");
      onChangeStopDuration(0, stopId);
    } else {
      setError("");
      onChangeStopDuration(newValue, stopId);
      props.setUnsavedServiceDurationChanges(true);
    }
  };

  const renderStyle = (stop, isSelected) => {
    let styleObj = {
      marginLeft: 8,
      padding: 8,
      // backgroundColor: "white",
    };

    if (stop.status !== "UNALLOCATED") {
      styleObj = {
        ...styleObj,
        border: isSelected
          ? `2px solid #${route.bgColor}`
          : `1px solid #DADADA`,
        borderRadius: 10,
      };
    } else {
      styleObj = {
        ...styleObj,
        borderWidth: "2px 2px 2px 10px",
        borderColor: `#${AppConfig.unallocated_color}`, // '933015'
        borderStyle: "solid",
      };
    }
    return { ...styleObj };
  };
  const buildStopCard = (stop, stopIndex) => {
    const address =
      stop.location && stop.location.l_address
        ? formatStopAddress(stop.location.l_address)
        : "";
    const estimated_departure_date_time_with_tz = !isEmpty(
      stop.estimated_departure_date_time_with_tz
    )
      ? stop.estimated_departure_date_time_with_tz
      : "";
    const estimated_arrival_date_time_with_tz = !isEmpty(
      stop.estimated_arrival_date_time_with_tz
    )
      ? stop.estimated_arrival_date_time_with_tz
      : "";
    const scheduled_start_date_time_with_tz = !isEmpty(
      stop.scheduled_start_date_time_with_tz
    )
      ? stop.scheduled_start_date_time_with_tz
      : "";
    const scheduled_end_date_time_with_tz = !isEmpty(
      stop.scheduled_end_date_time_with_tz
    )
      ? stop.scheduled_end_date_time_with_tz
      : "";
    const service_duration = stop.service_duration
      ? secondsToHms(parseInt(stop.service_duration), true, true, false)
      : 0;
    // const isShowScheduled = scheduled_start_date_time_with_tz !== 'NA' || scheduled_end_date_time_with_tz !== 'NA';
    // const isShowEstimated = estimated_arrival_date_time_with_tz !== 'NA' || estimated_departure_date_time_with_tz !== 'NA';
    const isShowScheduled =
      scheduled_start_date_time_with_tz !== "" ||
      scheduled_end_date_time_with_tz !== "";
    const isShowEstimated =
      estimated_arrival_date_time_with_tz !== "" ||
      estimated_departure_date_time_with_tz !== "";
    const hasStopErrors =
      stop.stop_errors &&
      _.isArray(stop.stop_errors) &&
      stop.stop_errors.length > 0;
    const isUnallocated = stop.status === "UNALLOCATED";
    const timeZone = stop.stop_tz_short_name;
    const stopType = stopTypeFromObject(stop)
    const isSelected =
      stopType === "CS"
        ? selectedList.includes(stop.id)
        : stop.id === currentStop.id;
    const company_name  = _.get(stop, "location.company_name", "");
    const dependencyStops = getDependencyStops(route, stop.id, "id", "stops");
    const durationTotal = findStopDuration(stop);
    const OrderTypeCount = getOrderTypeCount(stop.orders);
    const isWarehouseStop = stop.location && stop.location.l_type === "WH";
    const stopErrors = hasStopErrors ? [...stop.stop_errors] : [];
    const hasErrors = hasStopErrors || isUnallocated;
    const apptErrors = checkErrors(stopErrors, 'appointment')
    let remDays = "";
    if (
      moment(deliveryStartDate).isValid() &&
      !isEmpty(estimated_arrival_date_time_with_tz)
    ) {
      const routeStartDate = moment(
        momentTime("YYYY-MM-DD", deliveryStartDate, currentWarehouse.timeZoneId)
      );
      const estimatedDate = moment(
        moment.utc(estimated_arrival_date_time_with_tz).format("YYYY-MM-DD")
      );
      remDays = estimatedDate.diff(routeStartDate, "days") || "";
    }
    return (
      <Card
        size="small"
        onClick={(e) => {
          props.resetHoveredStop();
          onClickStop(e, stop.id);
        }}
        className={
          // stop.id === currentStop.id
          // 	? "boxShadowActive currentActiveStop"
          // 	: "boxShadow"
          stop.id === props.hoveredMarker
            ? "blockBgOverColor boxShadowActive currentActiveStop"
            : "boxShadow"
        }
        bodyStyle={{
          paddingLeft: 6,
        }}
        style={renderStyle(stop, isSelected)}
      >
        <Draggable
          key={stop.id}
          draggableId={stop.id}
          index={stop.stop_number - 2} // WH Stop
          isDragDisabled={stopType !== "CS" || route.isLocked || isLinehaulRoute}
          onClick={() => removeStopOrders(stop.id)}
        >
          {(provided, snapshot) => {
            const isDragging = !rearrangeForm && snapshot.isDragging;
            const displayText =
              isDragging &&
              selectedList.length > 1 &&
              selectedList.includes(stop.id)
                ? `Moving ${selectedList.length} stops`
                : ellipseText(stop.formatted_stop_name, 40);

            return (
              <div
                ref={provided.innerRef}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Row
                  style={{
                    backgroundColor: isDragging
                      ? "#607ad6"
                      : stop.id === props.hoveredMarker
                      ? "#cbe3f3"
                      : "white",
                    // backgroundColor: isDragging
                    // 	? "#607ad6"
                    // 	: "white",
                    color: isDragging ? "#FFF" : "inherit",
                    padding: isDragging ? "8px" : "inherit",
                    borderRadius: 10,
                  }}
                >
                  <Col xs={19} className="fontsize11 smallText textBold">
                    <span>
                      {isDragging ? (
                        <span>
                          <Icon type="home" /> &nbsp; {displayText}
                          {/* {!isEmpty(stop.location.company_name)?` --- ${stop.location.company_name}`:""} --- {stop.status} */}
                        </span>
                      ) : (
                        <>
                         <span className="marginRight5">{renderStopIcon(stop.location_type)}</span>
                         <Tooltip title={stop.formatted_stop_name}>
                          {displayText}
                          {/* {!isEmpty(stop.location.company_name)?` --- ${stop.location.company_name}`:""} --- {stop.status} */}
                        </Tooltip>
                        </>
                      )}
                    </span>
                    &nbsp;&nbsp;
                  </Col>
                  <Col xs={5} className="blkCenterRight textBold paddingRight7">
                    {hasErrors && (
                      <Popover
                        className="fontsize11 marginTopM3"
                        content={FormattedErrors(stopErrors || [])}
                      >
                       {
                        apptErrors.length === 0 ? 
                        <Icon
                          type="warning"
                          className="textRed stopWarningBlk"
                          style={{ marginTop: -1 }}
                        />
                        :
                        <img src={lateImagIcon} style={{ width: 18 }} className="stopWarningBlk" />
                       }
                        
                        &nbsp;&nbsp;
                      </Popover>
                    )}
                    {stop.location &&
                      stop.location.l_type === "CS" &&
                      !route.isLocked && (
                        <span
                          style={{
                            marginTop: -5,
                            marginRight: -16,
                          }}
                        >
                          {checkServiceExistance("NRRSFR") && !isDragging && (
                            <>
                              <Popconfirm
                                placement="left"
                                title={
                                  dependencyStops.length === 0
                                    ? I18n.t("messages.delete_confirm")
                                    : I18n.t(
                                        "messages.dependency_delete_confirm",
                                        {
                                          field: "stop",
                                        }
                                      )
                                }
                                onConfirm={() =>
                                  deleteClick(route.route_id, [stop.id])
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Tooltip
                                  title={I18n.t(
                                    "general.delete_stop_from_route"
                                  )}
                                  placement="bottomRight"
                                >
                                  {/* <img src={unlink} style={{ width: 12, marginRight: 8 }} alt="unlink" />{I18n.t('general.remove')} */}
                                  <DeleteIcon className="textBold"/>
                                  &nbsp;&nbsp;
                                </Tooltip>
                              </Popconfirm>
                            </>
                          )}
                          {checkServiceExistance("NRTS") && !isLinehaulRoute && (
                            <span {...provided.dragHandleProps}>
                              {/* <Popconfirm
                        placement="topRight"
                        title={I18n.t('messages.move_confirm', { field: '' })}
                        onConfirm={() => moveStop(stop.id, 'move_stop', route.route_id, route.route_id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title={I18n.t('preplan.move_stop')} placement="bottom">
                          <Icon type="menu" className="textBold" style={{ width: 16 }} /> &nbsp;&nbsp;
                        </Tooltip>
                      </Popconfirm> */}
                              <Tooltip
                                title={I18n.t("preplan.move_stop")}
                                placement="bottom"
                              >
                                <Icon
                                  type="menu"
                                  className="textBold"
                                  style={{
                                    width: 16,
                                  }}
                                />{" "}
                                &nbsp;&nbsp;
                              </Tooltip>
                            </span>
                          )}
                        </span>
                      )}
                  </Col>
                </Row>
              </div>
            );
          }}
        </Draggable>
        <Row justify="center">
          <Col
            xs={checkServiceExistance("NSUSD") && stopType === "CS" ? 20 : 24}
          >
            {company_name && renderCompanyName(company_name)}
            {stopType === "CS" && <StopAddress address={address} />}
            {stopType === "CS" && (
              <Row style={{ paddingTop: 5 }}>
                <Col xs={21} style={{ paddingTop: 1 }}>
                  <div className="fontsize11 smallText">
                    <Tooltip title={I18n.t("order.scheduled_time")}>
                      {I18n.t("routes.scheduled_time")}:&nbsp;
                      {isShowScheduled ? (
                        <Fragment>
                          {formatStopUTcTime(
                            scheduled_start_date_time_with_tz,
                            scheduled_end_date_time_with_tz,
                            timeZone,false,isMilitaryTime
                          )}
                        </Fragment>
                      ) : (
                        "Not available"
                      )}
                    </Tooltip>
                  </div>
                </Col>
                <Col xs={3} className="blkCenterRight paddingRight5" />
              </Row>
            )}
          </Col>
          {checkServiceExistance("NSUSD") &&
            !route.isLocked &&
            stopType === "CS" && (
              <Col xs={4} className="fontSize13 marginTop8">
                <Row>
                  <Col xs={6}>
                    <Tooltip title="Service Duration" placement="top">
                      <Icon type="clock-circle" />
                    </Tooltip>
                  </Col>
                  <Col xs={18}>
                    <Tooltip title={error} visible={!!error && serviceDuration[stop.id] === 0} placement="topLeft">
                      <Input
                        style={{
                          width: "100%",
                          marginRight: "10px",
                          borderWidth: "0px 0px 1px 0px",
                          zIndex: 1,
                          textAlign: "right",
                        }}
                        onChange={(e) => handleChange(e, stop.id)}
                        ref={(inputRef) => {
                          refs[`textInput${stop.id}`] = inputRef;
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setFocus(stop.id);
                        }}
                        value={serviceDuration[stop.id]?.duration ? serviceDuration[stop.id].duration : 0 }
                        size="small"
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            )}
        </Row>

        {
          <Row style={{ paddingTop: 5 }}>
            <Col xs={20} style={{ paddingTop: 1 }}>
              <div className="fontsize11 smallText">
                <Tooltip title={I18n.t("order.estimated_time")}>
                  {I18n.t("routes.estimated_time")}:&nbsp;
                  {!isNotOptimized &&
                  (estimated_arrival_date_time_with_tz !== "" ||
                    estimated_departure_date_time_with_tz !== "") ? (
                    <Fragment>
                      {isShowEstimated ? (
                        <Fragment>
                          {formatStopUTcTime(
                            estimated_arrival_date_time_with_tz,
                            estimated_departure_date_time_with_tz,
                            timeZone,false,isMilitaryTime
                          )}
                          :&nbsp;
                          {remDays ? (
                            <span className="textBold">
                              ({"+" + remDays}
                              {remDays > 1 ? " Days" : " Day"})
                            </span>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ) : (
                        "NA"
                      )}
                    </Fragment>
                  ) : (
                    "Not available"
                  )}
                </Tooltip>
              </div>
            </Col>
            {/* <Col xs={3} className="blkCenterRight paddingRight5">
              <p
                className="badgeDiv cursorPointer"
                style={{
                  backgroundColor: `#${route.bgColor}`,
                  color: `#${route.fgColor}`,
                  fontSize: 12,
                  marginTop: -5,
                }}
                onClick={() => showStopOrders(stop.id)}
              >
                {stop.orders.length}
              </p>
            </Col> */}
            <Col
              xs={4}
              onClick={() => showStopOrders(stop.id)}
              className="alignRight"
            >
              <Row type="flex" align="middle" gutter={4} justify="end">
                <Tooltip
                  placement="bottom"
                  title={I18n.t("preplan.order_count", {
                    orderType: "Delivery",
                  })}
                >
                  <Col
                    className="badgeDiv"
                    style={{
                      backgroundColor: `green`,
                      color: `white`,
                      fontSize: 12,
                      marginTop: -5,
                      width: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {OrderTypeCount.deliveryCount}
                  </Col>
                </Tooltip>
                <Col>{""}</Col>
                <Tooltip
                  placement="bottom"
                  title={I18n.t("preplan.order_count", {
                    orderType: "Pickup",
                  })}
                >
                  <Col
                    className="badgeDiv"
                    style={{
                      backgroundColor: `red`,
                      color: `white`,
                      fontSize: 12,
                      marginTop: -5,
                      width: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {OrderTypeCount.pickupCount}
                  </Col>
                </Tooltip>
              </Row>
            </Col>
          </Row>
        }
        { doCapacityValidation && !isNotOptimized && stopType !== "DV" && (
          <Fragment>
          {/* <Divider style={{ margin: '5px 0px 5px 0px'}}>Capacity</Divider> */}
          <Row gutter={10} className="marginTop10 fontsize11">
            <Col xs={12} style={{ paddingTop: 1 }}>
              <div className="fontsize11 smallText">
                <Fragment>
                  <Fragment>
                    <StopCapacityInfo
                      stop={{
                        ...stop.capacity_info,
                        stopType: stopType,
                        stop_number: stop.stop_number,
                        status: stop.status,
                      }}
                      route={route}
                    />
                  </Fragment>
                </Fragment>
              </div>
            </Col>
            <Col xs={12} style={{ paddingTop: 1, borderRight: '1px solid #CCC' }}>
              <div className="fontsize11 smallText">
                <Fragment>
                  <Fragment>
                    <StopSurfaceArea
                      stop={{
                        ...stop.capacity_info,
                        stopType: stopType,
                        stop_number: stop.stop_number,
                        status: stop.status,
                      }}
                      route={route}
                    />
                  </Fragment>
                </Fragment>
              </div>
            </Col>
          </Row>
          </Fragment>
        )}

        {
          // (selectedList.includes(stop.id) || stop.id === currentStop.id) && stop.orders.length > 0 &&
        }
        {['WH', 'CS'].includes(stop.location_type) && showOrdersList.includes(stop.id) &&
          renderOrders(stop, stopType, false, stopIndex)
        }
      </Card>
    );
  };

  const buildDistanceinfo = (stop) =>
    stop.interconnection_status && (
      <Timeline.Item
        key={`item_dis${stop.id}`}
        className="connectedItem paddingBottom5"
        dot={
          <div
            style={{
              borderColor: `#${route.bgColor}`,
            }}
            className="connectedDot"
          />
        }
      >
        <Popover
          placement="bottom"
          title="Distance  between stops"
          content={<StopDistanceInfo stop={stop} />}
        >
          <Row className="distanceInfo">
            <Col xs={1}>
              <Divider />
            </Col>
            <Col xs={22}>
              <Row>
                <Col xs={12}>
                  <Row>
                    {" "}
                    <Col xs={24}>
                      &nbsp;&nbsp;
                      <BranchesIcon/>
                      &nbsp;&nbsp;Distance&nbsp;&nbsp;:&nbsp;&nbsp;
                      {stop.distance}&nbsp;
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="alignRight">
                  <Row>
                    {" "}
                    <Col xs={24}>
                      <Icon type="clock-circle" />
                      &nbsp;&nbsp;Time&nbsp;&nbsp;:&nbsp;&nbsp;
                      {stop.time}&nbsp;&nbsp;
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={1}>
              <Divider />
            </Col>
          </Row>
        </Popover>
      </Timeline.Item>
    );

  const buildTimeLineStops = () =>
    tempStops.map((stop, stopIndex) => (
      <Fragment key={`timeline_fragment${stop.id}`}>
        <Timeline.Item
          key={`timeline_item${stop.id}`}
          dot={
            <Button
              style={{
                backgroundColor: `#${route.bgColor}`,
                color: `#${route.fgColor}`,
              }}
              shape="circle"
              size="small"
              // onClick={() => this.getCurrentStop(stop.id)}
              onClick={() => {}}
            >
              {stop.location && stop.location_type === "WH" ? (
                <Icon type="home" />
              ) : (
                stop.stop_number - 1
              )}
            </Button>
          }
          color={route.fgColor}
          // onMouseOver={() => {
          // 	props.setHoveredMarker(stop.id);
          // }}
        >
          <Fragment>{buildStopCard(stop, stopIndex)}</Fragment>
        </Timeline.Item>
        {!rearrangeForm && !isNotOptimized && buildDistanceinfo(stop)}
      </Fragment>
    ));

  return (
    <Fragment>
      {tempStops && tempStops.length > 0 && (
        <div>
            <Timeline>{buildTimeLineStops()}</Timeline>
        </div>
      )}
    </Fragment>
  );
};
export default Stop;

Stop.propTypes = {
  route: PropTypes.shape().isRequired,
  currentStop: PropTypes.shape().isRequired,
  rearrangeForm: PropTypes.bool.isRequired,
  selectedList: PropTypes.arrayOf(PropTypes.string).isRequired,
  moveStop: PropTypes.func,
  deleteClick: PropTypes.func.isRequired,
  onClickStop: PropTypes.func.isRequired,
  serviceDurationChange: PropTypes.func.isRequired,
};
Stop.defaultProps = {
  moveStop: null,
};