import { Col, Icon, Popconfirm, Row, Tooltip } from "antd";
import React, { Fragment } from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import Copyable from "../common/Copyable";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";

class DmsSearchOrderList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      // {
      //   title: "#",
      //   key: "index",
      //   align: "center",
      //   // render: (text, record, index) => index + 1,
      //   // render: (text, record, index) => (props.page-1 * props.perPage + index+1),
      //   width: 50,
      // },
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "order.customer_order_number",
        width: 120,
        render: (text, record) => (
          <div>
            <CustomerOrderDetails
              order_no={text}
              order={{
                id: record.order.id,
                customer_order_number: record.order.customer_order_number,
              }}
              showEdit={false}
            />
          </div>
        ),
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        width: 115,
        render: (data, record) => (
          <OrderFieldDisplay
            data={{
              ...record.order,
              id: record.order.id,
              customer_order_number: record.order.customer_order_number,
            }}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        width: 115,
        render: (data, record) => (
          <OrderFieldDisplay
            data={{
              ...record.order,
              id: record.order.id,
              customer_order_number: record.order.customer_order_number,
            }}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
      },
    ];
  }
}
export default DmsSearchOrderList;