import { Call, ApiUrl, PageParams, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";
import userStore from "../stores/UserStore";

export function fetchFuelSurcharges(
  accountId,
  orgId,
  page = 1,
  perPage = AppConfig.perPage
) {
  let url = "";
  if (isEmpty(accountId)) {
    url = ApiUrl("v2/fuel_surcharges/get_org_fuel_surcharges?operation_code=GOFSC");
  } else {
    url = ApiUrl(
      `v2/fuel_surcharges/get_fuel_surcharges?account_id=${accountId}&${PageParams(
        page,
        perPage
      )}&operation_code=GFSC`
    );
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      fuelsurcharges: "fuel_surcharges",
      pagination: "meta.pagination",
    },
  });
}

export function saveFuelSurcharges(data) {
  let url = "";
  if (isEmpty(data.account_id)) {
    data.operation_code= 'COFSC';
    url = ApiUrl('v2/fuel_surcharges/create_org_fuel_surcharges');
  } else {
    data.operation_code= 'FSCC';
    url = ApiUrl('v2/fuel_surcharges');
  }
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: { fuel_sur_charges: "" },
  });
}

export function fetchFuelSurchargeMasters(
  accountId,
  orgId,
  page = 1,
  perPage = null,
) {
  let url = ApiUrl(
    `v2/fuel_surcharge_masters?organization_id=${orgId}&${optionalPageParams(
      page,
      perPage,
      false,
    )}`
  );
  if (accountId) {
    url += `&account_id=${accountId}&operation_code=FSCMI`
  }else {
    url += `&operation_code=FSCI`
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      fuel_surcharge_masters: "fuel_surcharge_masters",
      pagination: "meta.pagination",
    },
  });
}

export function saveFuelSurchargeMaster(isNew, data) {
  data.operation_code = isNew ? 'FSCMC' : 'FSCMU';
  const url = isNew
    ? ApiUrl("v2/fuel_surcharge_masters")
    : ApiUrl(`v2/fuel_surcharge_masters/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { fuel_surcharge_masters: "" },
  });
}

export function fetchFuelSurchargeDetails(master_id) {
  let url = ApiUrl(`v2/fuel_surcharge_details?fs_master_id=${master_id}&operation_code=FSCDI`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      fuel_surcharge_details: "fuel_surcharge_details",
      pagination: "meta.pagination",
    },
  });
}

export function saveFuelSurchargeDetail(isNew, data) {
  data.operation_code = isNew ? 'FSCDC' : 'FSCDU';
  const url = isNew
    ? ApiUrl("v2/fuel_surcharge_details")
    : ApiUrl(`v2/fuel_surcharge_details/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { fuel_surcharge_detail: "" },
  });
}

export function saveFuelSurchargeAccountConfig(isNew, data) {
  data.operation_code = isNew ? 'FSCMSAC' : 'FSCMUAC';
  if(data.orgnization_id){
    data.orgnization_id = userStore.getStateValue('selectedOrg');
  }
  const url = isNew
    ? ApiUrl("v2/fuel_surcharge_masters/save_account_config")
    : ApiUrl(`v2/fuel_surcharge_masters/update_account_config`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { fuel_surcharge_detail: "" },
  });
}

export function deleteFuelSurchargeAccountConfig(data) {
  const url = ApiUrl(
    `v2/fuel_surcharge_masters/delete_account_config_fuel_surcharge`
  );
  return Call("delete", url, data, ParseGeneralResponse, {
    responseDataKeys: { fuel_surcharge_detail: "" },
  });
}

export function deleteFuelSurchargeMaster(data) {
  const url = ApiUrl(`v2/fuel_surcharge_masters/delete_organization_config_fuel_surcharge`);
  return Call("delete", url, data, ParseGeneralResponse, {
    responseDataKeys: { fuel_surcharge_detail: "" },
  });
}