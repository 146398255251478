/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types'
import { Col, Row, Input, Icon, Select, InputNumber, Radio } from '../../common/UIComponents'
import I18n from "../../common/I18n";
import AppConfig from '../../config/AppConfig'
import LabelInfo from "../configurations/LabelInfo"
import { isEmpty } from "../../common/Common";
import SwitchComponent from "../../common/switchComponent";

const { Option } = Select;
const invoice_email_types = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};
const InvoiceConfigForm = ({ config = {}, onChange, updateConfig, handleCheckBtn }) => {
  const groupType = config[I18n.t("configurations.order_grouping_type_key")];

  const getInvoiceLimitVal = () => {
    const value =
      config && config.invoice_limit_by === "by_amount"
        ? config.max_amount_per_invoice
        : config.max_orders_per_invoice;
    return value;
  };

  const handleInvoiceLimitChange = (e) => {
    const newValue = e.target.value;
    const keyToUpdate =
      config.invoice_limit_by === "by_order"
        ? I18n.t("configurations.order_choice_key")
        : I18n.t("configurations.invoice_amount_key");
    onChange(keyToUpdate, newValue);
  };

  const renderForm = () => {
    const limit_value = getInvoiceLimitVal();
    return (
      <Fragment>
        <Row md={24} className="marginTop10">
          <div className="settingHeader">
            <span className="textBold">
              {I18n.t("configurations.headings.invoice")}
            </span>
          </div>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={23} lg={11}>
                {I18n.t("configurations.order_grouping_type_label")}
                <LabelInfo
                  title={I18n.t(
                    "configurations.definitions.order_grouping_type_desc"
                  )}
                />
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Select
                  className="width100Per"
                  onChange={(val) =>
                    onChange(
                      I18n.t("configurations.order_grouping_type_key"),
                      val
                    )
                  }
                  value={
                    config[I18n.t("configurations.order_grouping_type_key")]
                  }
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "80%", marginRight: "10px" }}
                >
                  {Object.keys(AppConfig.order_grouping_types).map(
                    (province) => (
                      <Option key={province} value={province}>
                        {AppConfig.order_grouping_types[province]}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        {groupType === "WEEK" && (
          <Row>
            <Col xs={24} sm={24} md={24} lg={11}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.start_of_week_label")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Select
                    className="width100Per"
                    onChange={(val) =>
                      onChange(I18n.t("configurations.start_of_week_key"), val)
                    }
                    value={
                      config[I18n.t("configurations.start_of_week_key")] || ""
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "80%", marginRight: "10px" }}
                  >
                    <Option key="form" value="">
                      -- Select --
                    </Option>
                    {Object.keys(AppConfig.dayNames).map((province) => (
                      <Option key={province} value={province}>
                        {province}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={23} lg={11}>
                {I18n.t("configurations.cap_value_label")}
                <LabelInfo
                  title={I18n.t("configurations.definitions.cap_value_desc")}
                />
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Input
                  value={config[I18n.t("configurations.cap_value_key")]}
                  style={{ width: "80%" }}
                  type="number"
                  className="marginRight10"
                  onChange={(e) =>
                    onChange(
                      I18n.t("configurations.cap_value_key"),
                      e.target.value
                    )
                  }
                  addonBefore={<Icon type="dollar" />}
                />
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row className="configLableRow">
              <Col xs={23} sm={23} md={23} lg={11}>
                {/* {I18n.t("configurations.invoice_email_frequency_label")} */}
                Invoice Email Frequency
                <LabelInfo
                  // title={I18n.t(
                  //   "configurations.definitions.invoice_email_frequency"
                  // )}
                  title={
                    "By selecting the option from dropdown, like invoice should be email daily, every Week, or a particular day"
                  }
                />
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Select
                  className="width100Per"
                  onChange={(val) =>
                    onChange(
                      I18n.t("configurations.invoice_email_frequency_key"),
                      val
                    )
                  }
                  value={
                    config[I18n.t("configurations.invoice_email_frequency_key")]
                  }
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "80%", marginRight: "10px" }}
                >
                  {Object.keys(invoice_email_types).map((province) => (
                    <Option key={province} value={province}>
                      {invoice_email_types[province]}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row>
              <Col xs={23} sm={23} md={23} lg={11}>
                {I18n.t("configurations.invoice_choice_label")}
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Radio.Group
                  onChange={(e) => {
                    updateConfig(e.target.value);
                  }}
                  value={config.invoice_limit_by}
                >
                  <Radio value="by_order">By Order</Radio>
                  <Radio value="by_amount">By Amount</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row className="configLableRow">
              <Col xs={24} sm={24} md={24} lg={12}></Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Input
                  value={limit_value}
                  style={{ width: "80%" }}
                  onChange={handleInvoiceLimitChange}
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...(config.invoice_limit_by === "by_amount"
                    ? { addonBefore: <Icon type="dollar" /> }
                    : {})}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row>
              <Col xs={23} sm={23} md={23} lg={11}>
                {I18n.t("configurations.take_vendor_no")}
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <SwitchComponent
                  handleChange={(value) => handleCheckBtn("take_vendor_no", value)}
                  isChecked={config['take_vendor_no']  === "true"}
                  checkedChildren={"Yes"}
                  unCheckedChildren={"No"}
                  checkedValue={"true"}
                  unCheckedValue={"false"}
                />
              </Col>
            </Row>
          </Col>
        </Row>

       {config["take_vendor_no"] === "true" && (
          <Row style={{ paddingTop: 10 }}>
            <Col xs={24} sm={24} md={24} lg={11}>
              <Row>
                <Col xs={23} sm={23} md={23} lg={11}>
                {I18n.t("general.vendor_no")}
              </Col>
              <Col xs={1} sm={1} md={1} lg={1}>
                :
              </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Input
                    value={config.vendor_no}
                    onChange={(e) => onChange("vendor_no", e.target.value)}
                    style={{ width: "80%", marginRight: "10px" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  };
  return <Fragment>{renderForm()}</Fragment>;
};
export default InvoiceConfigForm;

InvoiceConfigForm.propTypes = {
  config: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};
