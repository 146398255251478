/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { fetchOrganizations } from '../api/Organisations';
import { Divider, Col, Row, Spin, Tabs, Checkbox, Select } from '../common/UIComponents';
import { alertMessage, isEmpty } from '../common/Common';
import I18n from '../common/I18n';
import AppConfig from '../config/AppConfig';
import { fetchAllModules, fetchPackageDetails, fetchPackages, savePackage } from '../api/Packages';
import FormButtons from '../components/common/FormButtons';
import FormErrors from '../components/common/FormErrors';
import { renderAlertMessage } from '../helpers/common';

const { TabPane } = Tabs;
const { Option } = Select;

class ModuleMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            masterModules: [],
            currentModules: {},
            pagination: {},
            currentPackage: props.currentPackage ? props.currentPackage : {},
            inProgress: false,
            isNew: false,
            activeKey: '1',
            packages: [],
            errors: [],
            packages: [],
            accesses: [],
            credits: [],
        };
        this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    }

    componentDidMount() {
        this.getModuleDetails();
        this.getPackages();
        this.getCurrentPackageDetails();
    }

    getModuleDetails = () => {
        this.setState({ inProgress: true });
        fetchAllModules()
            .then((result) => {
                if (result.success) {
                    const masterModules = result.modules || [];
                    this.setState({
                        masterModules,
                        inProgress: false,
                    });
                } else {
                    renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }
    getPackages = (cb = null) => {
        this.setState({ inProgress: true });
        fetchPackages()
            .then((result) => {
                if (result.success) {
                    const packages = result.packages || [];
                    this.setState({
                        packages,
                        inProgress: false,
                    }, () => {
                        if (cb) {
                            cb();
                        }
                    });
                } else {
                    // renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }

    getCurrentPackageDetails = (cb) => {
        if (this.state.currentPackage.id) {
            fetchPackageDetails(this.state.currentPackage.id)
                .then((result) => {
                    if (result.success) {
                        const details = result.package_details || {};
                        const accesses = details.accesses || [];
                        const credits = details.credits || [];
                        const modules = _.uniq(accesses.map(access => access.module_code));
                        const currentModules = {};
                        modules.map(moduleCode => {
                            currentModules[moduleCode] = accesses.filter(access => access.module_code === moduleCode).map(acess => acess.operation_code);
                        });
                        this.setState({
                            currentModules,
                            accesses,
                            credits,
                            inProgress: false,
                        }, () => {
                            if (cb) {
                                cb();
                            }
                        });
                    } else {
                        // renderAlertMessage(result.errors)
                        this.setState({ inProgress: false });
                    }
                });
        }
    }

    goToTab = (key) => {
        this.setState({
            activeKey: key
        })
    }
    getOrganizations = (initial, cb) => {
        this.setState({ inProgress: true });
        const page = this.tableOptions.pagination.current;
        const perPage = initial ? initial.perPage : AppConfig.perPage;
        fetchOrganizations(page, perPage)
            .then((result) => {
                if (result.success) {
                    this.setState({
                        organizations: result.organizations.organizations,
                        pagination: result.pagination,
                        inProgress: false,
                    }, () => {
                        if (cb) {
                            cb();
                        }
                    });
                } else {
                    renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }

    handleOnChange = (moduleCode, opCodes) => {
        const currentModules = { ...this.state.currentModules };
        if (!currentModules.moduleCode) {
            currentModules[moduleCode] = [];
        }
        currentModules[moduleCode] = opCodes;
        this.setState({
            currentModules,
        });
    }

    handleSelectAllInModule = (moduleCode, value) => {
        const { masterModules } = this.state;
        const currentModule = _.find(masterModules, { module_code: moduleCode});
        if(!isEmpty(currentModule)){
            if(value === true){
                const operations = currentModule.operations || [];
                const opCodes = operations.map(op => op.operation_code);
                this.handleOnChange(moduleCode, opCodes);
            } else{
                this.handleOnChange(moduleCode, []);
            }
        }
    }

    handleSelectAllModules = (value) => {
        const { masterModules } = this.state;
        if(value === true){
            const currentModules = {};
            masterModules.forEach(currentModule => {
                const operations = currentModule.operations || [];
                currentModules[currentModule.module_code] = operations.map(op => op.operation_code);
            })
            this.setState({
              currentModules,
            });
        }else{
            this.setState({
              currentModules: {},
            });
        }
    }

    handleSave = () => {
        const credits = this.state.credits || [];
        const data = this.state.currentPackage;
        const access = [];
        Object.keys(this.state.currentModules).forEach(key => {
            const currentModule = this.state.currentModules[key];
            currentModule.forEach(op => {
                access.push({
                module_code: key,
                operation_code: op
            });
            });
        });
        data.access = access;
        const currentCredit = _.find(credits, { package_id: data.id });
        data.credits = !isEmpty(currentCredit) ? currentCredit : {};
        savePackage(false, data)
        .then((result) => {
          if (result.success) {
            this.setState({ inProgress: false, errors: [] });
            alertMessage(I18n.t('messages.saved'));
          } else {
            alertMessage(result.errors, 'error', 10);
            this.setState({ errors: result.errors, inProgress: false });
          }
        });
    }

    handleStateOnChange = (element, value) => {
        const { packages } = this.state;
        const data = {
            [element]: value,
        };
        if (element === 'package_id') {
            const currentPackage = _.find(packages, { id: value });
            if (!isEmpty(currentPackage)) {
                data.currentPackage = { ...currentPackage };
            }
            data.currentModules = {};
        }
        this.setState(data, () => {
            if (element === 'package_id') {
                // this.getCurrentPackageDetails();
            }
        });
    }

    render() {
        const { masterModules, currentModules } = this.state;
        return (
            <div>
                <Spin spinning={this.state.inProgress} delay={1000}>
                    <Row>
                        <Col xs={24} className="alignLeft fontSize14">
                            <Checkbox
                                onChange={(e) => this.handleSelectAllModules(e.target.checked)}
                            >
                              Select {I18n.t('general.all')}
                            </Checkbox>
                            
                            {/* 
                            <span className="textBold">{I18n.t('package.label')}</span> : {this.state.currentPackage.name}
                            <Select
                                value={this.state.currentPackage.id}
                                showSearch
                                style={{ width: 200, margin: '10px 10px 0px 10px' }}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={e => this.handleStateOnChange('package_id', e)}
                                disabled
                            >
                                {this.state.packages.map(packag => (
                                    <Select.Option key={packag.id} value={packag.id}>
                                        {packag.name}
                                    </Select.Option>
                                ))}
                            </Select>*/}
                           
                        </Col>
                    </Row>
                    <Row className="marginTop10">
                        <Col>
                            {
                                masterModules.length > 0 ?
                                   <Fragment>
                                        { masterModules.map((modul, index) => {
                                            const isSelectAllInModule = currentModules && currentModules[modul.module_code] ? currentModules[modul.module_code].length === modul.operations.length : false;
                                            return ( 
                                            <Fragment>
                                            <Row style={{ zoom: '90%'}}>
                                            <Col xs={4} className="textBold">{modul.module_name}</Col>
                                            <Col xs={20}>
                                                  { modul.operations.length > 0 &&
                                                  <Row>
                                                      <Col xs={24}>
                                                          <Checkbox
                                                                 onChange={(e) => this.handleSelectAllInModule(modul.module_code, e.target.checked)}
                                                                 checked={isSelectAllInModule}
                                                                >{I18n.t('general.all')}
                                                                </Checkbox>
                                                      </Col>
                                                  </Row> 
                                                  }
                                                 <Row>
                                                 <Checkbox.Group
                                                        value={currentModules && currentModules[modul.module_code] ? currentModules[modul.module_code] : []}
                                                        onChange={(e) => this.handleOnChange(modul.module_code, e)}
                                                        style={{ width: "100%" }}
                                                    >
                                                        {modul.operations.map(op => (
                                                            <Col xs={6}>
                                                                <Checkbox
                                                                    value={op.operation_code}
                                                                >{op.operation_name}
                                                                </Checkbox>
                                                            </Col>
                                                        ))}
                                                    </Checkbox.Group>
                                                  </Row>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        </Fragment>);
                                        })
                                        }
                                        { false && <Tabs
                                        activeKey={this.state.activeKey}
                                        onChange={this.goToTab}

                                        tabPosition={"left"}
                                    >
                                        {
                                            masterModules.map((modul, index) => (<TabPane tab={modul.module_name} key={index}>
                                                <div className="center">
                                                    <Checkbox.Group
                                                        value={currentModules && currentModules[modul.module_code] ? currentModules[modul.module_code] : []}
                                                        onChange={(e) => this.handleOnChange(modul.module_code, e)}
                                                        style={{ width: "100%" }}
                                                    >
                                                        {modul.operations.map(op => (<Row className="marginTop10">
                                                            <Col xs={24}>
                                                                <Checkbox
                                                                    value={op.operation_code}
                                                                >{op.operation_name}
                                                                </Checkbox>
                                                            </Col>
                                                        </Row>
                                                        ))}
                                                    </Checkbox.Group>
                                                </div>
                                            </TabPane>))
                                        }

                                    </Tabs>
                                        }
                                   </Fragment>
                                    :
                                    <Fragment />
                            }
                        </Col>
                    </Row>
                    <Row>
                        {FormErrors(this.state.errors)}
                    </Row>
                    <Row>
                        <Col xs={24}>
                            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)}
                        </Col>
                    </Row>
                </Spin>
            </div>
        );
    }
}
export default ModuleMapping;
