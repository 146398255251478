import {
  Col,
  Form,
  Row,
  Select,
  Card,
  DatePicker,
  Button,
  Input,
  Descriptions,
  Typography,
  Tooltip,
  Icon,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import userStore from "../../stores/UserStore";
import { BatchManagementApi } from "../../api/BatchManagement";
import { alertMessage, findNumberTotal, isEmpty } from "../../common/Common";
import { Search } from "../../common/UIComponents";
import { checkNegitive } from "../../common/Common";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import { generateReqDecimal } from "../../helpers/array_functions";
import moment from "moment";
import I18n from "../../common/I18n";

const RemittanceCreateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const zeroValue = Number((0).toFixed(decimalPoints));
  const stepValue = generateReqDecimal(1, decimalPoints);
  const regex = new RegExp(`^\\d+(\\.\\d{1,${decimalPoints}})?$`);
  const dateFormat = "MM/DD/YYYY";
  const {
    getFieldDecorator,
    validateFields,
    setFieldsValue,
    resetFields,
    getFieldValue,
  } = props.form;
  const checkId = props.match.params.checkId;
  const batchId = props.match.params.batchId;
  const [batchInfo, setBatchInfo] = useState([]);
  const [checkInfo, setCheckInfo] = useState([]);
  const { remittanceId, filterPlaceHolder, setPagination = {}, page = "", perPage = "", refreshRemittenceList, setRefreshRemittenceList } = props;
  const [checkInforesult, setCheckInforesult] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [buttonClicked, setButtonClicked] = useState(null);
  const [loadOnSubmit, setLoadOnSubmit] = useState(false);
  const [remittanceIdState, setRemittanceIdState] = useState(remittanceId);
  const [eneteredAmount, setEnteredAmount] = useState(
    checkNegitive(getFieldValue("amount"))
  );
  const paidTotal = props.remittancesData
    ? Number(findNumberTotal(props.remittancesData, "net_amount", null, decimalPoints))
    : (0.0).toFixed(decimalPoints);
  const receivedAmount = props.remittancesData
    ? Number(checkInforesult.received_amount)
    : (0.0).toFixed(decimalPoints);

  useEffect(() => {
    // const { amount, discounts, credits } = formValues;
    const amount = getFieldValue("amount");
    const discounts = getFieldValue("discounts");
    const credits = getFieldValue("credits");
    const sum =
      parseFloat(amount || 0) -
      (parseFloat(discounts || 0) + parseFloat(credits || 0));
      if(sum < 0){
        setEnteredAmount(zeroValue);
      }else{
       
    setEnteredAmount(sum.toFixed(decimalPoints));
      }
  }, [formValues]);

  const handleFieldChange = (element, value) => {
    setFormValues({
      ...formValues,
      [element]: value,
    });
    if(element === "amount" && value === ""){
      setFieldsValue({
        ...formValues,
        net_amount: zeroValue,
        discounts: zeroValue,
        credits: zeroValue,
      });
      setEnteredAmount(zeroValue);
    }
    if(element === "referenece_number" && value === ""){
      setFieldsValue({
        ...formValues,
        invoice_amount: zeroValue,
        invoice_date: "",
        amount: zeroValue,
      });
      setEnteredAmount(zeroValue);
    }
  };

  useEffect(() => {
    if (remittanceIdState?.id) {
      setFieldsValue({
        reference_type: remittanceIdState.reference_type,
        referenece_number: remittanceIdState.reference_no,
        amount: remittanceIdState.paid_amount,
        invoice_date: remittanceIdState.invoice_date
          ? moment(remittanceIdState.invoice_date)
          : "",
        discounts: remittanceIdState.discount,
        credits: remittanceIdState.credits,
        invoice_amount: remittanceIdState.invoice_amount,
        net_amount: remittanceIdState.net_amount,
      });
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        amount: remittanceIdState.paid_amount,
        discounts: remittanceIdState.discount,
        credits: remittanceIdState.credits,
      }));
      setButtonClicked(remittanceIdState.id);
    }
  }, [remittanceIdState, setFieldsValue]);

  useEffect(() => {
    if (checkInforesult) {
      setFieldsValue({
        payment_method: checkInforesult.pay_mode,
        warehouse: checkInforesult.wh_name,
        account_code: checkInforesult.account_name,
        amount_received: checkInforesult.received_amount,
        check_ach_details: checkInforesult.check_ach_no,
        check_date: checkInforesult.check_date
          ? moment(checkInforesult.check_date)
          : "",
        payment_date: checkInforesult.payment_date
          ? moment(checkInforesult.payment_date)
          : "",
      });
    }
  }, [checkInforesult, setFieldsValue]);

  useEffect(() => {
    getCreatedBatches();
    getArBatchCheckDetails();
  }, []);

  useEffect(() => {
    setRemittanceIdState(remittanceId);
  }, [remittanceId]);

  useEffect(() => {
    if (checkInfo && checkInfo.account_id) {
      getRemittances();
    }
  }, [checkInfo, filterPlaceHolder, refreshRemittenceList]);

  const getCreatedBatches = () => {
    setLoading(true);
    BatchManagementApi.fetch().then((result) => {
      if (result.success) {
        const matchingBatch = result.data.ar_batches.find(
          (batch) => batch.id === batchId
        );
        if (matchingBatch) {
          setBatchInfo(matchingBatch);
        } else {
          alertMessage("No matching batch found", "error");
        }
        setLoading(false);
      } else {
        renderAlertMessage(result.errors)
        setLoading(false);
      }
    });
  };

  const getArBatchCheckDetails = () => {
    setLoading(true);
    BatchManagementApi.fetchBatchCheckDetails(batchId).then((result) => {
      if (result.success) {
        const matchingCheck = result.data.ar_batch_checks.find(
          (check) => check.id === checkId
        );
        if (matchingCheck) {
          setCheckInfo(matchingCheck);
        } else {
          renderAlertMessage("No matching check found");
        }
        setLoading(false);
      } else {
        setLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const getRemittances = () => {
    const account_id = checkInfo.account_id;
    let { sortBy, sortByType } = filterPlaceHolder;
    const sort_order = I18n.t(`general.sort_${filterPlaceHolder.sortByType}`)
    BatchManagementApi.fetchRemittances(
      checkId,
      account_id,
      page,
      perPage,
      sortBy,
      sortByType = sort_order
    ).then((result) => {
      if (result.success) {
        props.handleLoading(true);
        props.handleRemittanceDetails(result.data.check_remittances);
        setCheckInforesult(result.data.check_information);
        props.handleDialogVisible(result.data.warning_message);
        props.handleLoading(false);
        setPagination(result.pagination);
      } else {
        props.handleLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const validateForm = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      const payload = {
        organization_id: userStore.getStateValue("selectedOrg"),
        reference_type: values.reference_type,
        reference_no: values.referenece_number,
        paid_amount: values.amount,
        invoice_date: values.invoice_date
          ? values.invoice_date.format("Do MMM YYYY")
          : null,
        discount: values.discounts,
        credits: values.credits,
        invoice_amount: values.invoice_amount,
        net_amount: eneteredAmount,
        ar_batch_check_id: checkId,
        ar_batch_id: batchId,
        batch_number: batchInfo.batch_number,
        account_id: checkInfo.account_id,
        check_ach_no: checkInfo.check_ach_no,
      };
      if (remittanceIdState?.id) {
        payload.remittance_id = remittanceIdState.id;
        BatchManagementApi.updateRemittances(payload)
          .then((result) => {
            setLoadOnSubmit(true);
            if (result.success) {
              alertMessage(result.data.message, "success");
              getRemittances();
              resetFields();
              setFormValues({});
              setRemittanceIdState({});
              setLoadOnSubmit(false);
            } else {
              result.errors.forEach((error) => {
                alertMessage(error, "error");
              });
            }
          })
          .catch((error) => {
            alertMessage(error, "error");
          })
          .finally(() => {
            setLoadOnSubmit(false);
          });
      } else {
        BatchManagementApi.createRemittances(payload)
          .then((result) => {
            setLoadOnSubmit(true);
            if (result.success) {
              alertMessage(result.data.message, "success");
              getRemittances();
              resetFields();
              setFormValues({});
              setLoadOnSubmit(false);
            } else {
              result.errors.forEach((error) => {
                alertMessage(error, "error");
              });
              setLoadOnSubmit(false);
            }
          })
          .finally(() => {
            setLoadOnSubmit(false);
          });
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 12 },
      xl: { span: 12 },
      xxl: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: 12 },
      xl: { span: 12 },
      xxl: { span: 12 },
    },
  };

  const gridSize = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 8,
  };

  const findReferenceNo = () => {
    const account_id = checkInfo.account_id;
    const reference_type = getFieldValue("reference_type");
    const reference_no = getFieldValue("referenece_number");
    BatchManagementApi.findReferenceNo(
      account_id,
      reference_type,
      !isEmpty(reference_no) ? reference_no : ""
    ).then((result) => {
      if (result.success) {
        // setEnteredAmount(checkNegitive(result.data.account_invoice.frequency_invoice_amount, false));
        setFieldsValue({
          invoice_amount: checkNegitive(
            result.data.account_invoice.frequency_invoice_amount,
            false
          ),
          invoice_date: moment(result.data.account_invoice.approval_date),
          // amount: checkNegitive(
          //   result.data.account_invoice.frequency_invoice_amount,
          //   false
          // ),
        });
        updatePaidAmountVal(reference_type, result.data )
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  const updatePaidAmountVal = (reference_type, data) =>{
    const reference_no = getFieldValue("referenece_number");

    if (reference_type !== "INVOICE") {
      const keyTrack = {
        ORDER: "customer_order_number",
        HAWB: "hawb",
        MAWB: "mawb",
        REFERENCE: ["reference_1", "reference_2"],
      };

      const keyToSearch = reference_type === "REFERENCE" ? keyTrack.REFERENCE : keyTrack[reference_type];
      const invoiceDetails = data?.account_invoice?.invoice_details ?  data.account_invoice.invoice_details : [];

       const searchedInvoice = invoiceDetails?.length
        ? invoiceDetails.find((invoice) => {
            if (reference_type === "REFERENCE") {
              return keyToSearch.some(key => invoice[key] === reference_no);
            } else {
              return invoice[keyToSearch] === reference_no;
            }
          })
        : [];
      const balance_invoice_amount = searchedInvoice?.balance_invoice_amount ? searchedInvoice.balance_invoice_amount : 0 
      setFieldsValue({
        amount: checkNegitive(balance_invoice_amount, false),
      });
      setEnteredAmount(checkNegitive(balance_invoice_amount, false));
    } else {
      setFieldsValue({
        amount: checkNegitive(
          data.account_invoice.balance_invoice_amount,
          false
        ),
      });
      setEnteredAmount(checkNegitive(data.account_invoice.balance_invoice_amount, false));
    }
  }

  return (
    checkServiceExistance(["ARBCIRC", "ARBCIRU"], "ANY") && (
      <div className="formHeader">
        <Row gutter={8} type="flex">
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Card
              className="customCard checkFormCard"
              bordered={false}
              bodyStyle={{
                padding: 5,
                borderRadius: 10,
                // backgroundColor: "#f5f5f5",
              }}
            >
              <Descriptions
                title="Check Details"
                size="small"
                column={{ xl: 3, lg: 3, md: 12, sm: 24, xs: 24 }}
              >
                <Descriptions.Item
                  label={
                    <strong>{`${I18n.t(
                      "batch_management.mode_of_payment_label"
                    )}`}</strong>
                  }
                >
                  {checkInforesult ? checkInforesult.pay_mode : null}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <strong>{`${I18n.t(
                      "batch_management.payment_date"
                    )}`}</strong>
                  }
                >
                  {checkInforesult.payment_date
                    ? moment(checkInforesult.payment_date).format("Do MMM YYYY")
                    : ""}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <strong>{`${I18n.t(
                      "batch_management.check_date"
                    )}`}</strong>
                  }
                >
                  {checkInforesult.check_date
                    ? moment(checkInforesult.check_date).format("Do MMM YYYY")
                    : ""}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <strong>{`${I18n.t(
                      "batch_management.amount_received"
                    )}`}</strong>
                  }
                >
                  {checkInforesult.received_amount
                    ? checkInforesult.received_amount
                    : null}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <strong>{`${I18n.t(
                      "batch_management.check_ach_details"
                    )}`}</strong>
                  }
                >
                  {checkInforesult.check_ach_no
                    ? checkInforesult.check_ach_no
                    : null}
                </Descriptions.Item>

                {/* <Descriptions.Item
                  label={
                    <strong>{`${I18n.t("batch_management.warehouse")}`}</strong>
                  }
                  className="no-word-break"
                >
                  {checkInforesult.wh_name ? (
                    <Tooltip title={checkInforesult.wh_name}>
                      <Typography.Text ellipsis>
                        {checkInforesult.wh_name}
                      </Typography.Text>
                    </Tooltip>
                  ) : null}
                </Descriptions.Item> */}

                <Descriptions.Item
                  label={
                    <strong>{`${I18n.t(
                      "batch_management.account_details"
                    )}`}</strong>
                  }
                >
                  {checkInforesult.account_name
                    ? checkInforesult.account_name
                    : null}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              className="customCard checkFormCard"
              bordered={false}
              bodyStyle={{
                padding: 5,
                borderRadius: 10,
                // backgroundColor: "#f5f5f5",
              }}
            >
              <Form {...formItemLayout}>
                <h3 style={{ marginTop: "7px", marginLeft: "4px" }}>
                  <b>Remittance Form</b>
                </h3>
                <Row gutter={8} type="flex" align="middle">
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.reference_type"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("reference_type", {
                        initialValue: "INVOICE",
                        rules: [
                          {
                            required: false,
                            message: "Please select an option",
                          },
                        ],
                      })(
                        <Select
                          size="small"
                          style={{ width: "100%" }}
                          // onChange={(e) =>
                          //   handleFieldChange("reference_type", e.target.value)
                          // }
                        >
                          <Select.Option value={"INVOICE"}>
                            INVOICE
                          </Select.Option>
                          <Select.Option value={"HAWB"}>HAWB</Select.Option>
                          <Select.Option value={"MAWB"}>MAWB</Select.Option>
                          <Select.Option value={"ORDER"}>ORDER</Select.Option>
                          <Select.Option value={"REFERENCE"}>
                            REFERENCE
                          </Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.referenece_number"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("referenece_number", {
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          // {
                          //   validator: (_, value, callback) => {
                          //     if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
                          //       callback("Please enter a valid amount");
                          //     }
                          //     callback();
                          //   },
                          // },
                        ],
                      })(
                        // (<Input style={{ width: "80%" }} size="small" />)
                        <Search
                          onSearch={findReferenceNo}
                          style={{ width: "100%" }}
                          size="small"
                          onChange={(e) =>
                            handleFieldChange(
                              "referenece_number",
                              e.target.value
                            )
                          }
                          disabled={remittanceIdState?.id}
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.invoice_amount_label"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("invoice_amount", {
                        initialValue: zeroValue,
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && !regex.test(value)) {
                                callback("Please enter a valid amount");
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <Input
                          onChange={(e) =>
                            handleFieldChange("invoice_amount", e.target.value)
                          }
                          style={{ width: "100%" }}
                          size="small"
                          addonBefore={<Icon type="dollar" />}
                          step={stepValue}
                          readOnly
                          onBlur={(e) => {
                            if (e.target.value) {
                              let invAmount = parseFloat(e.target.value);
                              setFieldsValue({
                                invoice_amount: invAmount.toFixed(decimalPoints),
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.invoice_date"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("invoice_date", {
                        initialValue: "",
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          onChange={(e) =>
                            handleFieldChange("invoice_date", e.target.value)
                          }
                          style={{ width: "100%" }}
                          format={dateFormat}
                          size="small"
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col {...gridSize}>
                  <Form.Item
                    label={`${I18n.t(
                      "batch_management.invoice_amount_label"
                    )} ${":"}`}
                  >
                    {apiResult && apiResult.success  ? (
                      <span>
                        {apiResult.data.account_invoice.frequency_invoice_amount}
                      </span>
                    ) : (
                      <span>{apiResult && apiResult.errors[0]}</span>
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize}>
                  <Form.Item
                    label={`${I18n.t("batch_management.invoice_date")} ${":"}`}
                  >
                    {apiResult && apiResult.success ? (
                      <span>
                        {moment(
                          apiResult.data.account_invoice.approval_date
                        ).format(dateFormat)}
                      </span>
                    ) : (
                      <span>{apiResult && apiResult.errors[0]}</span>
                    )}
                  </Form.Item>
                </Col> */}

                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.paid_amount_label"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("amount", {
                        initialValue: zeroValue,
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && !regex.test(value)) {
                                callback("Please enter a valid amount");
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <Input
                          style={{ width: "100%" }}
                          size="small"
                          addonBefore={<Icon type="dollar" />}
                          step={stepValue}
                          onChange={(e) =>
                            handleFieldChange("amount", e.target.value)
                          }
                          onClick={(e) => {
                            if (e.target.value === zeroValue) {
                              setFieldsValue({
                                amount: "",
                                net_amount: "",
                              });
                            }else if(e.target.value === ""){
                              setFieldsValue({
                                amount: "",
                                net_amount: zeroValue,
                                discounts: zeroValue,
                                credits: zeroValue,
                              });
                            }
                          }}
                          onFocus={(e) => {
                            if (e.target.value === zeroValue) {
                              setFieldsValue({
                                amount: "",
                                net_amount:zeroValue,
                                discounts: zeroValue,
                                credits: zeroValue,
                              });
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              let amount = parseFloat(e.target.value);
                              setFieldsValue({
                                amount: amount.toFixed(decimalPoints),
                              });
                            } else {
                              setFieldsValue({
                                amount: zeroValue,
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t("batch_management.discounts")} ${":"}`}
                    >
                      {getFieldDecorator("discounts", {
                        initialValue: zeroValue,
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && !regex.test(value)) {
                                callback("Please enter a valid amount");
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <Input
                          style={{ width: "100%" }}
                          size="small"
                          addonBefore={<Icon type="dollar" />}
                          step={stepValue}
                          onChange={(e) =>
                            handleFieldChange("discounts", e.target.value)
                          }
                          onClick={(e) => {
                            if (e.target.value === zeroValue) {
                              setFieldsValue({
                                discounts: "",
                              });
                            }
                          }}
                          onFocus={(e) => {
                            if (e.target.value === zeroValue) {
                              setFieldsValue({
                                discounts: "",
                              });
                            }
                          }}
                          disabled={
                            !getFieldValue("amount") ||
                            getFieldValue("amount") === zeroValue
                          } // Disable if "amount" is empty
                          onBlur={(e) => {
                            if (e.target.value) {
                              let disc = parseFloat(e.target.value);
                              setFieldsValue({
                                discounts: disc.toFixed(decimalPoints),
                              });
                            } else {
                              setFieldsValue({
                                discounts: zeroValue,
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t("batch_management.credits")} ${":"}`}
                    >
                      {getFieldDecorator("credits", {
                        initialValue: zeroValue,
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && !regex.test(value)) {
                                callback("Please enter a valid amount");
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <Input
                          style={{ width: "100%" }}
                          size="small"
                          addonBefore={<Icon type="dollar" />}
                          step={stepValue}
                          onChange={(e) =>
                            handleFieldChange("credits", e.target.value)
                          }
                          onClick={(e) => {
                            if (e.target.value === zeroValue) {
                              setFieldsValue({
                                credits: "",
                              });
                            }
                          }}
                          onFocus={(e) => {
                            if (e.target.value === zeroValue) {
                              setFieldsValue({
                                credits: "",
                              });
                            }
                          }}
                          disabled={
                            !getFieldValue("amount") ||
                            getFieldValue("amount") === zeroValue
                          } // Disable if "amount" is empty
                          onBlur={(e) => {
                            if (e.target.value) {
                              let cred = parseFloat(e.target.value);
                              setFieldsValue({
                                credits: cred.toFixed(decimalPoints),
                              });
                            } else {
                              setFieldsValue({
                                credits: zeroValue,
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.net_amount_label"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("net_amount", {
                        initialValue: 0,
                        rules: [
                          {
                            required: false,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && !regex.test(value)) {
                                callback("Please enter a valid amount");
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <Fragment>
                          {/* <Input
                        style={{ width: "100%", border: "none" }}
                        size="small"
                        addonBefore={<Icon type="dollar" />}
                        step={stepValue}
                        readOnly
                      /> */}
                          <Typography>
                            <Typography.Text strong>
                              $ {eneteredAmount}
                            </Typography.Text>
                          </Typography>
                        </Fragment>
                      )}
                    </Form.Item>
                  </Col>

                  <Col {...gridSize}>
                    <Row type="flex" gutter={8} justify="end">
                      <Col>
                        <Col {...gridSize}>
                          <Button
                            type="danger"
                            onClick={() => {
                              resetFields();
                              setFormValues({});
                              setButtonClicked(null);
                              setRemittanceIdState({});
                              getRemittances();
                            }}
                            size="small"
                            icon="undo"
                          >
                            Reset
                          </Button>
                        </Col>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => validateForm()}
                          size="small"
                          icon="plus-circle"
                          loading={loadOnSubmit}
                        >
                          {remittanceIdState &&
                          remittanceIdState.id &&
                          buttonClicked !== null
                            ? I18n.t("general.update")
                            : I18n.t("general.save")}
                          {/* buttonClicked !== null
                          ? I18n.t("batch_management.update_remittance")
                          : I18n.t("batch_management.save_remittance") */}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Card
              className="customCard checkFormCard"
              bordered={false}
              bodyStyle={{
                padding: 5,
                borderRadius: 10,
                fontSize: 12,
                // backgroundColor: "#f5f5f5",
              }}
            >
              <h3 style={{ marginTop: "7px", marginLeft: "4px" }}>
                <b>Summary</b>
              </h3>
              <Row>
                <Col xs={15}>
                  <strong>{`${I18n.t(
                    "batch_management.amount_received"
                  )}`}</strong>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8} className="alignRight textBold">
                  {checkNegitive(receivedAmount, false)}
                </Col>
              </Row>

              <Row>
                <Col xs={15}>
                  <strong>{`${I18n.t(
                    "batch_management.remittance_net"
                  )}`}</strong>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8} className="alignRight textBold">
                  {checkNegitive(paidTotal, false)}
                </Col>
              </Row>
              <hr className="marginTop5" />
              <Row className="marginTop5">
                <Col xs={15}>
                  <strong>Balance ($)</strong>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8} className="alignRight textBold">
                  {checkNegitive(receivedAmount - paidTotal, false)}
                </Col>
              </Row>
              <Row className="marginTop20 marginBottom15">
                <Col xs={24} className="alignCenter">
                  <Button
                    size="small"
                    type="primary"
                    icon="dollar"
                    onClick={() => {
                      props.history.push({
                        pathname: "/open_invoices",
                        search: `?batch=${batchId}&check=${checkId}`,
                        state: {
                          key: "remittance",
                          check_ach_no: checkInforesult.check_ach_no,
                        },
                      });
                    }}
                  >
                    Pay Due
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
};

const WrappedRemittanceCreateForm = withRouter(
  Form.create()(RemittanceCreateForm)
);
export default WrappedRemittanceCreateForm;
