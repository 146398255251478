/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
// import moment_tz from 'moment-timezone';
import { withRouter } from 'react-router-dom'
import {
  updateRoute,
  deleteRoutes,
  fetchRouteStops,
  fetchOptimizedStops,
  deleteOrderFromRoute,
  updateStop,
} from '../api/Routes'
import FormErrors from '../components/common/FormErrors'
import { fetchActiveUsers } from '../api/UsersApi'
import { fetchRouteOrders, fetchOrderDetails } from '../api/OrdersApi'
import {
  Alert,
  Col,
  Row,
  Spin,
  Icon,
  FormItem,
  Divider,
  DatePicker,
  Button,
  Popconfirm,
  Avatar,
  Drawer,
  Timeline,
  Card,
  Popover,
  Tooltip,
  Input,
} from '../common/UIComponents'
import TimePicker from '../common/TimePicker'
import whiteArrow from '../../assets/images/WhiteArrow.png'
import { alertMessage,  doFormate } from '../common/Common'
import I18n from '../common/I18n'
import BaseModal from '../components/BaseModal'
import OrdersList from '../components/Tasks/RouteOrdersList'
import OrderDetails from '../components/Tasks/OrderDetails'
import EditOrderDetails from '../components/Tasks/EditOrderDetails'
import EditOrderForm from '../components/orders/EditOrderForm'
import Map from '../components/Tasks/Map'
import ItemsList from '../components/stops/ItemsList'
import RearrangeStops from '../components/stops/RearrangeStops'
import travelTimeImg from '../../assets/images/travel_time.png'
import travelDistanceImg from '../../assets/images/travel_distance.png'
import truckImg from '../../assets/images/truck.png'
import SurveyResponse from '../components/surveys/SurveyResponse'
import Assignment from '../components/Routes/Assignment'
import Profile from '../components/common/Profile'
import StopDetails from '../components/stops/StopDetails'
import { OrgContext } from '../context/OrgContext';
import { WarehouseContext } from '../context/WarehouseContext';
import FormIcon from '../components/common/FormIcon'
import BranchesIcon from '../components/common/BranchesIcon'
import { checkAccessExistance, metersToOtherMeasurements, renderAlertMessage, secondsToHms } from '../helpers/common'

const { TextArea } = Input
const userTypes = {
  '/drivers': 'driver',
  '/customers': 'customer',
  '/admins': 'admin',
}

class Stops extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inProgress: false,
      stops: [],
      errors: [],
      loading: false,
      routeId: this.props.match.params.routeId
        ? this.props.match.params.routeId
        : this.props.routeId || '',
      routeStatus: '',
      users: [],
      driverId: '',
      showSurvey: false,
      surveyResponseId: '',
      routeInfo: '',
      routeName: '',
      orderInfo: [],
      detailsVisible: false,
      editForm: false,
      listVisible: false,
      routes: {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      },
      optimize_error: '',
      optimize_error_status: true,
      is_optimized: false,
      currentOrder: {},
      orderObject: {},
      total_time: 0,
      total_distance: 0,
      showOdrersByDefault: this.props.location.showOdrersByDefault
        ? this.props.location.showOdrersByDefault
        : false,
      currentStop: {},
      driverInfo: '',
      showEditStopModal: false,
      editStopObject: {},
      driversInfo: {
        primary: {
          driverId: '',
          allotedDriver: {},
          selectedDriverId: '',
        },
        secondary: {
          driverId: '',
          allotedDriver: {},
          selectedDriverId: '',
        },
      },
      truckInfo: {
        assignedId: '',
        allotedTruck: {},
        selectedId: '',
      },
      rearrangeForm: false,
      reqFrom: this.props.reqFrom || '',
      organizationSettings: {},
      isMilitaryTime: false,
    }

    this.userType = userTypes[_.get(props, 'match.path', 'driver')]
  }

  UNSAFE_componentWillMount() {
    this.getRouteInfo()
    this.getUsers()
    if (this.state.showOdrersByDefault) {
      this.getRouteOrders()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings,
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == 'true',
      })
    }
  }
  componentDidMount() {
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == 'true',
    })
  }

  closeEditFormModal = () => {
    this.setState({ editForm: false }, () => {
      this.getRouteOrders()
    })
  }

  confirmRoute = () => {
    // this.props.history.push({
    //   pathname: '/routes',
    //   filter: this.state.routeStatus
    // });
    this.setState({
      assignWindow: true,
    })
  }

  closeListDrawer = () => {
    if (this.state.routeStatus !== 'COMPLETED') {
      this.handleSave()
    }
    this.setState({
      listVisible: false,
    })
  }

  deleteClick = (id) => {
    this.setState({ inProgress: true })
    deleteRoutes(id).then((result) => {
      if (result.success) {
        this.props.history.push({
          pathname: '/orders',
          filter: 'VERIFIED',
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleAddStop = () => {
    this.props.history.push({
      pathname: '/orders',
      filter: 'VERIFIED',
      routeId: this.state.routeId,
      routeStatus: this.state.routeStatus,
    })
  }

  handleUpdate = () => {
    this.props.history.push({
      pathname: '/orders',
      filter: 'VERIFIED',
      routeId: this.state.routeId,
    })
  }

  handleSaveSuccess = (message) => {
    alertMessage(message)
    this.setState({ editForm: false, listVisible: false }, () => {
      this.getRouteInfo()
    })
  }

  handleOrderDetailsViewClick = (id) => {
    // const index = _.findIndex(this.state.orderInfo, ['id', id]);
    // const currentOrder = Object.assign({}, this.state.orderInfo[index]);
    // this.setState({ currentOrder: currentOrder, detailsVisible: true });
    const index = _.findIndex(this.state.orderInfo, ['id', id])
    const orderObject = Object.assign({}, this.state.orderInfo[index])

    fetchOrderDetails(id).then((result) => {
      if (result.success) {
        const currentOrder = result.orders
        currentOrder.id = id
        currentOrder.order_appointments = orderObject.appointments || []
        this.setState(
          {
            // result.orders.customer_order
            currentOrder,
            orderObject,
          },
          () => {
            this.setState({ detailsVisible: true })
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          orderObject,
          loading: false,
        })
      }
    })
  }

  // handleEditOrderClick = (id) => {
  //   const index = _.findIndex(this.state.orderInfo, ['id', id]);
  //   const currentOrder = Object.assign({}, this.state.orderInfo[index]);
  //   this.setState({ currentOrder: currentOrder, editForm: true });
  // }

  handleEditOrderClick = (id) => {
    const index = _.findIndex(this.state.orderInfo, ['id', id])
    const orderObject = Object.assign({}, this.state.orderInfo[index])

    fetchOrderDetails(id).then((result) => {
      if (result.success) {
        this.setState(
          {
            // result.orders.customer_order
            currentOrder: result.orders,
            orderObject,
          },
          () => {
            this.setState({ editForm: true })
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          orderObject,
          loading: false,
        })
      }
    })
  }

  getRouteOrders = () => {
    this.setState({ inProgress: true })
    fetchRouteOrders(this.state.routeId).then((result) => {
      if (result.success) {
        const customerOrders = result.orders.orders_in_route
          ? result.orders.orders_in_route
          : []
        const orders = customerOrders.map((order) =>
          Object.assign({}, order, {
            appointments: order.order_appointments || [],
          }),
        )
        this.setState(
          {
            orderInfo: orders,
            inProgress: false,
            routes: {
              selectedOrderKeys: customerOrders.map((x) => x.id),
              selectedOrderRows: customerOrders,
            },
          },
          () => {
            this.setState({ listVisible: true, inProgress: false })
          },
        )
      } else {
        this.setState({ listVisible: true, inProgress: false })
      }
    })
  }

  getUsers = () => {
    this.setState({ inProgress: true })
    fetchActiveUsers(this.userTypes, 1, 1000).then((result) => {
      if (result.success) {
        this.setState(
          {
            users: result.users,
            inProgress: false,
          },
          () => {
            this.setAllotedDriver()
          },
        )
      } else {
        this.setState({ inProgress: false })
        renderAlertMessage(result.errors)
      }
    })
  }

  getRouteInfo = (callRearrange) => {
    if (this.state.routeId) {
      this.setState({ inProgress: true })
      fetchRouteStops(this.state.routeId).then((result) => {
        if (result.success) {
          const route_stops = []
          if(result?.stops?.stops){
            result.stops.stops.forEach((route_stop) => {
              route_stops.push(route_stop.stop_details)
            })
          }
          
          const sortedRoutes = _.sortBy(route_stops, [
            function (o) {
              return o.stop_order_sequence
            },
          ])
          if (sortedRoutes.length > 0) {
            sortedRoutes[sortedRoutes.length - 1].completion_class =
              'inCompletedRoute'
          }
          const { interconnections } = result.stops
          sortedRoutes.forEach((stop, index) => {
            if (index > 0) {
              if (stop.status === 'COMPLETED') {
                sortedRoutes[index - 1].completion_class = 'completedRoute'
              } else {
                sortedRoutes[index - 1].completion_class = 'inCompletedRoute'
              }
            }
            sortedRoutes[index].stop_number = index + 1
            const contact_details = stop.contact_details
              ? stop.contact_details
              : {}
            sortedRoutes[index].formatted_stop_name = this.formatStopName(
              contact_details,
              stop.location,
              index + 1,
            )
            const interconnection_index = _.findIndex(interconnections, [
              'fromWaypoint',
              stop.id,
            ])
            if (interconnection_index >= 0) {
              sortedRoutes[index].interconnection_status = true
              sortedRoutes[index].distance = interconnections[
                interconnection_index
              ].distance
                ? metersToOtherMeasurements(
                    interconnections[interconnection_index].distance,
                    'miles',
                  )
                : 'NA'
              sortedRoutes[index].time = interconnections[interconnection_index]
                .time
                ? secondsToHms(
                    interconnections[interconnection_index].time,
                    true,
                    true,
                    false,
                  )
                : 0
              sortedRoutes[index].waiting = interconnections[
                interconnection_index
              ].waiting
                ? secondsToHms(
                    interconnections[interconnection_index].waiting,
                    true,
                    true,
                    false,
                  )
                : '-'
              sortedRoutes[index].rest = interconnections[interconnection_index]
                .rest
                ? secondsToHms(
                    interconnections[interconnection_index].rest,
                    true,
                    true,
                    false,
                  )
                : '-'
            } else {
              sortedRoutes[index].distance = 'NA'
              sortedRoutes[index].time = 'NA'
              sortedRoutes[index].waiting = 'NA'
              sortedRoutes[index].rest = 'NA'
              sortedRoutes[index].interconnection_status = false
            }
          })

          this.setState(
            {
              inProgress: false,
              routeInfo: route_stops,
              stops: sortedRoutes,
              routeStatus: result.stops.nav_route_status,
              routeName: result.stops.nav_route_name,
              is_optimized: result.stops.optimized,
              total_time: result.stops.total_time ? result.stops.total_time : 0,
              total_distance: result.stops.total_distance
                ? result.stops.total_distance
                : 0,
              currentStop:
                sortedRoutes.length > 0
                  ? Object.assign(sortedRoutes[0], {
                      order_items: sortedRoutes[0].pickup_order_items.concat(
                        sortedRoutes[0].drop_order_items,
                      ),
                    })
                  : {},
              driversInfo: {
                primary: {
                  driverId: result.stops.primary_driver
                    ? result.stops.primary_driver.id
                    : '',
                  allotedDriver: result.stops.primary_driver
                    ? result.stops.primary_driver
                    : {},
                  selectedDriverId: '',
                },
                secondary: {
                  driverId: result.stops.secondary_driver
                    ? result.stops.secondary_driver.id
                    : '',
                  allotedDriver: result.stops.secondary_driver
                    ? result.stops.secondary_driver
                    : {},
                  selectedDriverId: '',
                },
              },
              truckInfo: {
                assignedId: result.stops.fleet_id ? result.stops.fleet_id : '',
                allotedTruck: result.stops.fleet_id
                  ? {
                      fleet_id: result.stops.fleet_id,
                      name: result.stops.fleet_name,
                    }
                  : {},
                selectedId: '',
              },
            },
            () => {
              if (callRearrange === true) {
                this.callToRearrange()
              }
              const driver = result.stops.primary_driver
                ? result.stops.primary_driver
                : ''
              const driver_last_location = result.stops.driver_last_location
                ? result.stops.driver_last_location
                : ''
              let driverInfo = ''
              if (driver && driver.id) {
                if (
                  driver_last_location &&
                  driver_last_location.latitude &&
                  driver_last_location.longitude
                ) {
                  driverInfo = {
                    id: driver._id,
                    lat: driver_last_location.latitude,
                    lng: driver_last_location.longitude,
                    name: `${driver.first_name} ${driver.last_name}`,
                    mobile_name: driver.mobile_number,
                    email: driver.email,
                    shape: { coords: [15, 15, 18], type: 'circle' },
                    icon: {
                      url:
                        driver.image && driver.image.url
                          ? driver.image.url
                          : './../icons/user_100*100.png',
                      size: { width: 30, height: 30 },
                      anchor: { x: 15, y: 50 },
                      scaledSize: { width: 30, height: 40 },
                      shape: { coords: [17, 17, 18], type: 'circle' },
                      className: 'custom-div-icon',
                    },
                  }
                }
                this.setState(
                  {
                    driverId: driver.id,
                    driverInfo,
                  },
                  () => {
                    this.setAllotedDriver()
                  },
                )
              }
            },
          )
        } else {
          this.setState({ inProgress: false })
          renderAlertMessage(result.errors)
        }
      })
    }
  }

  getOptimizeInfo = () => {
    if (this.state.routeId) {
      this.setState({ inProgress: true })
      fetchOptimizedStops(this.state.routeId).then((result) => {
        if (result.success) {
          this.setState({ inProgress: false })
          const error_message =
            result.optimize_route.optimizer_readable_error_message

          const route_stops = []
          result.optimize_route.stops.map((route_stop) => {
            route_stops.push(route_stop.stop_details)
          })
          const sortedRoutes = _.sortBy(route_stops, [
            function (o) {
              return o.stop_order_sequence
            },
          ])
          if (sortedRoutes.length > 0) {
            sortedRoutes[sortedRoutes.length - 1].completion_class =
              'inCompletedRoute'
          }
          const { interconnections } = result.optimize_route
          sortedRoutes.forEach((stop, index) => {
            if (index > 0) {
              if (stop.status === 'COMPLETED') {
                sortedRoutes[index - 1].completion_class = 'completedRoute'
              } else {
                sortedRoutes[index - 1].completion_class = 'inCompletedRoute'
              }
            }
            sortedRoutes[index].stop_number = index + 1
            const contact_details = stop.contact_details
              ? stop.contact_details
              : {}
            sortedRoutes[index].formatted_stop_name = this.formatStopName(
              contact_details,
              stop.location,
              index + 1,
            )
            const interconnection_index = _.findIndex(interconnections, [
              'fromWaypoint',
              stop.id,
            ])
            if (interconnection_index >= 0) {
              sortedRoutes[index].interconnection_status = true
              sortedRoutes[index].distance = interconnections[
                interconnection_index
              ].distance
                ? metersToOtherMeasurements(
                    interconnections[interconnection_index].distance,
                    'miles',
                  )
                : 'NA'
              sortedRoutes[index].time = interconnections[interconnection_index]
                .time
                ? secondsToHms(
                    interconnections[interconnection_index].time,
                    true,
                    true,
                    false,
                  )
                : 'NA'
              sortedRoutes[index].waiting = interconnections[
                interconnection_index
              ].waiting
                ? secondsToHms(
                    interconnections[interconnection_index].waiting,
                    true,
                    true,
                    false,
                  )
                : 'NA'
              sortedRoutes[index].rest = interconnections[interconnection_index]
                .rest
                ? secondsToHms(
                    interconnections[interconnection_index].rest,
                    true,
                    true,
                    false,
                  )
                : 'NA'
            } else {
              sortedRoutes[index].distance = 'NA'
              sortedRoutes[index].time = 'NA'
              sortedRoutes[index].waiting = 'NA'
              sortedRoutes[index].rest = 'NA'
              sortedRoutes[index].interconnection_status = false
            }
          })
          let currentStop = ''
          if (
            !result.optimize_route.optimized &&
            error_message &&
            error_message.customer_name_location
          ) {
            const index = _.findIndex(sortedRoutes, [
              'location.name',
              error_message.customer_name_location,
            ])
            if (index > 0) {
              currentStop = Object.assign(sortedRoutes[index], {
                order_items: sortedRoutes[0].pickup_order_items.concat(
                  sortedRoutes[0].drop_order_items,
                ),
              })
            } else {
              currentStop =
                sortedRoutes.length > 0
                  ? Object.assign(sortedRoutes[0], {
                      order_items: sortedRoutes[0].pickup_order_items.concat(
                        sortedRoutes[0].drop_order_items,
                      ),
                    })
                  : {}
            }
          } else {
            currentStop =
              sortedRoutes.length > 0
                ? Object.assign(sortedRoutes[0], {
                    order_items: sortedRoutes[0].pickup_order_items.concat(
                      sortedRoutes[0].drop_order_items,
                    ),
                  })
                : {}
          }
          this.setState(
            {
              routeInfo: route_stops,
              stops: sortedRoutes,
              optimize_error_status: result.optimize_route.optimized,
              optimize_error: error_message,
              is_optimized: result.optimize_route.optimized,
              routeStatus: result.optimize_route.nav_route_status,
              routeName: result.optimize_route.nav_route_name,
              inProgress: false,
              total_time: result.optimize_route.total_time
                ? result.optimize_route.total_time
                : 0,
              total_distance: result.optimize_route.total_distance
                ? result.optimize_route.total_distance
                : 0,
              currentStop,
            },
            () => {
              // if (result.optimize_route.optimize_route.nav_routes.driver && result.nav_routes.driver.id) {
              //   this.setState({
              //     driverId: result.nav_route.driver.id
              //   }, () => {
              //     this.setAllotedDriver();
              //   })
              // }
            },
          )
        } else {
          this.setState({ inProgress: false })
          renderAlertMessage(result.errors)
        }
      })
    }
  }

  onClose = () => {
    this.setState({
      detailsVisible: false,
    })
  }

  onErrorClose = () => {
    this.setState({
      optimize_error_status: false,
    })
  }

  onOk = (value) => {
  }

  handleChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  handleDriverChange = (element, value, type) => {
    this.setState({
      driversInfo: Object.assign({}, this.state.driversInfo, {
        [type]: {
          ...this.state.driversInfo[type],
          [element]: value,
        },
      }),
    })
  }

  handleTruckChange = (element, value) => {
    this.setState({
      truckInfo: Object.assign({}, this.state.truckInfo, {
        [element]: value,
      }),
    })
  }

  handleSurveyDetails = (id) => {
    this.setState({
      showSurvey: true,
      surveyResponseId: id,
    })
  }

  closeSurveyDetails = () => {
    this.setState({
      showSurvey: false,
    })
  }

  handleOrderViewClick = (order_id) => {
  }

  handleSave = () => {
    this.setState({
      loading: true,
    })
    let data = {}
    if (this.state.routeId) {
      data = Object.assign({}, data, {
        nav_route_id: this.state.routeId,
        customer_order_ids: this.state.routes.selectedOrderKeys,
      })
    } else {
      data = Object.assign({}, data, {
        customer_order_ids: this.state.routes.selectedOrderKeys,
      })
    }
    updateRoute(data).then((result) => {
      if (result.success) {
        this.setState(
          {
            loading: false,
            listVisible: false,
          },
          () => {
            this.getRouteInfo()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          loading: false,
        })
      }
    })
  }

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState(
      {
        routes: Object.assign({}, this.state.routes, {
          selectedOrderKeys: selectedRowKeys,
          selectedOrderRows: [],
        }),
      },
      () => {
      },
    )
  }

  handleUpdateStop = () => {
    this.setState({
      loading: true,
    })
    const data = Object.assign({}, data, {
      stop_id: this.state.editStopObject.id,
      estimated_arrival_datetime: this.state.editStopObject
        .estimated_arrival_datetime
        ? moment(this.state.editStopObject.estimated_arrival_datetime).format(
            'YYYY-MM-DD HH:mm:00',
          )
        : '',
      estimated_departure_datetime: this.state.editStopObject
        .estimated_departure_datetime
        ? moment(this.state.editStopObject.estimated_departure_datetime).format(
            'YYYY-MM-DD HH:mm:00',
          )
        : '',
      geo_fence_exception: this.state.editStopObject.geo_fence_exception,
    })
    updateStop(data).then((result) => {
      if (result.success) {
        this.setState(
          {
            loading: false,
            showEditStopModal: false,
          },
          () => {
            this.getRouteInfo()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          loading: false,
        })
      }
    })
  }

  back = () => {
    this.props.history.push('/routes')
  }

  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    })
  }

  showListDrawer = () => {
    this.setState({
      listVisible: true,
    })
  }

  showEditForm = () => {
    this.setState({
      editForm: true,
    })
  }

  setAllotedDriver = () => {
    if (
      this.state.driversInfo.primary.driverId ||
      this.state.driversInfo.secondary.driverId
    ) {
      const primaryIndex = _.findIndex(this.state.users, [
        'id',
        this.state.driversInfo.primary.driverId,
      ])
      const primaryDriver = Object.assign({}, this.state.users[primaryIndex])

      const index = _.findIndex(this.state.users, [
        'id',
        this.state.driversInfo.secondary.driverId,
      ])
      const secondaryDriver = Object.assign({}, this.state.users[index])

      this.setState({
        driversInfo: Object.assign({}, this.state.driversInfo, {
          primary: {
            ...this.state.driversInfo.primary,
            allotedDriver: primaryDriver,
          },
          secondary: {
            ...this.state.driversInfo.secondary,
            allotedDriver: secondaryDriver,
          },
        }),
      })
    }
  }

  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    })
  }

  onClose = () => {
    this.setState({
      detailsVisible: false,
    })
  }

  fomatString = (str, format = '') => doFormate(str, format)

  navigateRouteScreen = () => {
    this.props.history.push({
      pathname: '/routes',
      filter: this.state.routeStatus ? this.state.routeStatus : '',
    })
  }

  getCurrentStop = (id, cb) => {
    const index = _.findIndex(this.state.stops, ['id', id])
    const currentStop = Object.assign({}, this.state.stops[index])
    currentStop.order_items = currentStop.pickup_order_items.concat(
      currentStop.drop_order_items,
    )
    this.setState(
      {
        currentStop,
      },
      cb,
    )
  }

  handleVisibleChange = (visible, element) => {
    this.setState({ [element]: visible })
  }

  handleOrderDeleteFromRoute = (id) => {
    this.setState({ inProgress: true })
    const data = {
      nav_route_id: this.state.routeId,
      customer_order_id: id,
    }
    deleteOrderFromRoute(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        const index = _.findIndex(this.state.orderInfo, ['id', id])
        const { orderInfo } = this.state
        if (index >= 0) {
          orderInfo.splice(index, 1)
          this.setState({
            inProgress: false,
            orderInfo,
            routes: {
              selectedOrderKeys: orderInfo.map((x) => x.id),
              selectedOrderRows: orderInfo,
            },
          })
        }
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  formatStopName = (contact_details, location, stop_number) => {
    const name =
      contact_details && contact_details.contact_name
        ? contact_details.contact_name
        : `Stop ${stop_number}`
    let city_state =
      location && location.l_address
        ? `${location.l_address.city}, ${location.l_address.state}`
        : ''
    if (city_state) {
      city_state = city_state.replace(/(^,)|(,$)/g, '')
      city_state = ` (${city_state})`
    }
    return name + city_state
  }

  handleEditStop = (id) => {
    const index = _.findIndex(this.state.stops, ['id', id])
    const currentStop = Object.assign({}, this.state.stops[index])
    currentStop.estimated_arrival_datetime =
      currentStop.estimated_arrival_date_time_with_tz
    currentStop.estimated_departure_datetime =
      currentStop.estimated_departure_date_time_with_tz
    this.setState({
      editStopObject: currentStop,
      showEditStopModal: true,
    })
  }

  handleCurrentStopChange = (element, value) => {
    // if (element === "estimated_arrival_date_time_with_tz") {
    //   const scheduled_end_datetime = value ? moment(new Date(value)).add(4, 'hours') : null;
    //   this.setState({
    //     editStopObject: Object.assign({}, this.state.editStopObject, {
    //       [element]: value,
    //       estimated_departure_date_time_with_tz: scheduled_end_datetime
    //     }),
    //   });
    // } else {
    //   this.setState({
    //     editStopObject: Object.assign({}, this.state.editStopObject, { [element]: value }),
    //   });
    // }
    this.setState({
      editStopObject: Object.assign({}, this.state.editStopObject, {
        [element]: value,
      }),
    })
  }

  handleEditStopClose = () => {
    this.setState({
      showEditStopModal: false,
      editStopObject: {},
    })
  }

  handleAssignWindowClose = () => {
    this.setState(
      {
        assignWindow: false,
      },
      () => {
        this.getRouteInfo()
      },
    )
  }

  renderStopsEditModal = () => {
    const { isMilitaryTime } = this.state
    return (
      <BaseModal
        title={`${this.formatStopName(
          this.state.editStopObject.contact_details,
          this.state.editStopObject.location,
          this.state.editStopObject.stop_number,
        )}`}
        width="720px"
        onCancel={() => this.handleEditStopClose()}
      >
        <FormItem label="Estimated Arrival">
          <Row>
            <Col xs={24} sm={24} lg={12} md={12} className="padding5">
              <FormItem label="Date">
                <DatePicker
                  style={{ width: '100%', marginLeft: '1%' }}
                  placeholder="Select Date"
                  format="Do MMM YYYY"
                  onChange={(e) =>
                    this.handleCurrentStopChange(
                      'estimated_arrival_datetime',
                      e,
                    )
                  }
                  value={
                    this.state.editStopObject.estimated_arrival_datetime
                      ? moment(
                          this.state.editStopObject.estimated_arrival_datetime,
                        )
                      : null
                  }
                  defaultValue={null}
                  onOk={this.onOk}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} lg={12} md={12} className="padding5">
              <FormItem label="Time">
                <TimePicker
                  format={isMilitaryTime ? 'HH:mm' : 'hh:mm A'}
                  use12Hours={!isMilitaryTime}
                  placeholder="Select Time"
                  value={
                    this.state.editStopObject.estimated_arrival_datetime
                      ? moment(
                          this.state.editStopObject.estimated_arrival_datetime,
                        )
                      : null
                  }
                  onChange={(e) =>
                    this.handleCurrentStopChange(
                      'estimated_arrival_datetime',
                      e,
                    )
                  }
                  // defaultValue={null}
                  // onOk={this.onOk}
                  style={{ width: '100%', marginLeft: '1%' }}
                  showSecond={false}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem label="Estimated Departure">
          <Row>
            <Col xs={24} sm={24} lg={12} md={12} className="padding5">
              <FormItem label="Date">
                <DatePicker
                  style={{ width: '100%', marginLeft: '1%' }}
                  placeholder="Select Date"
                  format="Do MMM YYYY"
                  onChange={(e) =>
                    this.handleCurrentStopChange(
                      'estimated_departure_datetime',
                      e,
                    )
                  }
                  value={
                    this.state.editStopObject.estimated_departure_datetime
                      ? moment(
                          this.state.editStopObject
                            .estimated_departure_datetime,
                        )
                      : null
                  }
                  defaultValue={null}
                  onOk={this.onOk}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} lg={12} md={12} className="padding5">
              <FormItem label="Time">
                <TimePicker
                  format={isMilitaryTime ? 'HH:mm' : 'hh:mm A'}
                  use12Hours={!isMilitaryTime}
                  placeholder="Select Time"
                  value={
                    this.state.editStopObject.estimated_departure_datetime
                      ? moment(
                          this.state.editStopObject
                            .estimated_departure_datetime,
                        )
                      : null
                  }
                  onChange={(e) =>
                    this.handleCurrentStopChange(
                      'estimated_departure_datetime',
                      e,
                    )
                  }
                  // defaultValue={null}
                  // onOk={this.onOk}
                  style={{ width: '100%', marginLeft: '1%' }}
                  showSecond={false}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem label="Geo Fence Exception">
          <Row>
            <Col xs={24} sm={24} lg={24} md={24} className="padding5">
              <TextArea
                rows={2}
                value={this.state.editStopObject.geo_fence_exception}
                onChange={(e) =>
                  this.handleCurrentStopChange(
                    'geo_fence_exception',
                    e.target.value,
                  )
                }
              />
            </Col>
          </Row>
        </FormItem>
        <Row>
          <Col xs={24} sm={24} lg={24} md={24} className="padding5 alignCenter">
            <Button
              type="danger"
              className="margin5"
              onClick={this.handleEditStopClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="margin5"
              onClick={this.handleUpdateStop}
            >
              Save
            </Button>
          </Col>
        </Row>
      </BaseModal>
    )
  }

  renderAssignModal = () => (
    <BaseModal
      title={`Assign ${I18n.t('general.Driver')} / Vehicle`}
      width="450px"
      onCancel={() => this.handleAssignWindowClose()}
    >
      <Assignment
        routeId={this.state.routeId}
        primaryDriver={this.state.driversInfo.primary.driverId}
        secondaryDriver={this.state.driversInfo.secondary.driverId}
        truck={this.state.truckInfo.assignedId}
        closeWindow={this.handleAssignWindowClose}
      />
    </BaseModal>
  )

  closeRearrangeForm = () => {
    this.setState({ rearrangeForm: false }, () => {
      this.getRouteInfo()
    })
  }
  callToRearrange = () => {
    this.setState({ rearrangeForm: true })
  }

  render() {
    const { routeStatus } = this.state
    const hasSelected = this.state.routes.selectedOrderKeys
      ? this.state.routes.selectedOrderKeys.length > 0
      : false
    const { selectedOrderKeys } = this.state.routes

    const rowSelection = {
      selectedRowKeys: selectedOrderKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.editable === false, // Column configuration not to be checked
      }),
    }

    let sec_hrs = secondsToHms(this.state.total_time, true, false, false)
    sec_hrs = sec_hrs ? `${sec_hrs}(Approx.)` : 'NA'
    const time_distance = this.state.total_distance
      ? `${parseFloat(this.state.total_distance / 1609).toFixed(2)} Miles`
      : 'NA'
    const mapStops = this.state.stops.filter(
      (x) =>
        x.location &&
        x.location.l_address &&
        x.location.l_address.coordinates &&
        x.location.l_address.coordinates.length >= 2,
    )

    return (
      <div className="content-outer">
        <div
          className="content routes-tasks"
          style={{ backgroundColor: '#f0f2f5' }}
        >
          <Row className="page-header" style={{ marginBottom: 0 }}>
            <Col xs={10}>
              {this.state.reqFrom !== 'preplan' && (
                <Row>
                  { checkAccessExistance('DISPATCH') && <img
                    src={whiteArrow}
                    alt="whitearrow"
                    style={{ height: 20, width: 30, cursor: 'pointer' }}
                    onClick={this.navigateRouteScreen}
                  />
                  }
                  {!this.state.routeName
                    ? I18n.t('tasks.routeSummary')
                    : `${this.state.routeName} (${this.state.routeStatus})`}
                </Row>
              )}
            </Col>
            <Col xs={14} className="alignRight">
              {!this.state.is_optimized &&
                this.state.stops.length > 0 &&
                routeStatus !== 'COMPLETED' && (
                  <Button
                    className="route_optimize_button"
                    type="primary"
                    onClick={this.getOptimizeInfo}
                  >
                    Optimize Route
                  </Button>
                )}
              {this.state.stops.length > 0 && routeStatus !== 'COMPLETED' && (
                <Fragment>
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t('messages.cancel_confirm')}
                    onConfirm={() => this.deleteClick(this.state.routeId)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="danger" className="margin5">
                      Delete
                    </Button>
                  </Popconfirm>
                  <Button
                    onClick={this.getRouteOrders}
                    className="margin5 buttonYellow"
                    loading={this.state.loading}
                    disabled={!(this.state.stops.length > 0)}
                  >
                    Update
                  </Button>
                  {this.state.routeStatus !== 'COMPLETED' && (
                    <Tooltip title="Change Stops Order">
                      <Button
                        className="margin5 header-btn"
                        onClick={() => this.getRouteInfo(true)}
                      >
                        {I18n.t('routes.resequence')}
                      </Button>
                    </Tooltip>
                  )}
                  &nbsp;&nbsp;
                </Fragment>
              )}
              <Tooltip title="Reload">
                <Icon
                  type="reload"
                  className="cursorPointer textYellow"
                  onClick={this.getRouteInfo}
                />
              </Tooltip>
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            {!this.state.optimize_error_status &&
              _.isArray(this.state.optimize_error) &&
              this.state.optimize_error.length > 0 && (
                <Row className="marginZero">
                  <Col
                    className="marginTop10"
                    xs={24}
                    style={{ padding: '0px 10px 10px 10px' }}
                  >
                    <Alert
                      message="Error"
                      description={
                        <div>
                          {this.state.optimize_error.map((error) => (
                            <Row>
                              <Col>{error}</Col>
                            </Row>
                          ))}
                        </div>
                      }
                      type="error"
                      closable
                    />
                  </Col>
                </Row>
              )}
            <Row>
              <Col
                lg={10}
                md={10}
                xs={24}
                sm={24}
                style={{ height: '84vh', overflowY: 'scroll', padding: 10 }}
              >
                <Row className="blackBorder">
                  <Col xs={24}>
                    <Row style={{ margin: '10px 2px' }}>
                      <Col xs={24} style={{ paddingLeft: 10 }}>
                        <Row style={{ lineHeight: '35px' }}>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Tooltip title="Travel Distance">
                              <Row>
                                <Col xs={9} className="textBold">
                                  <img
                                    src={travelDistanceImg}
                                    alt="travelDistanceImg"
                                    className="routeDetailsImg"
                                  />{' '}
                                  &nbsp;&nbsp; Distance
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col xs={14}>
                                  <div className="info-text">
                                    <span>{time_distance}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Tooltip>
                          </Col>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Tooltip title={I18n.t('general.travel_time')}>
                              <Row>
                                <Col xs={10} className="textBold">
                                  <img
                                    src={travelTimeImg}
                                    alt="travelTimeImg"
                                    className="routeDetailsImg"
                                  />
                                  &nbsp;{I18n.t('general.travel_time')}
                                </Col>
                                <Col xs={1}>:</Col>
                                <Col xs={13}>
                                  <div className="info-text">
                                    <span>{sec_hrs}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} className="alignCenter padding5">
                        <Row>
                          <Col
                            lg={6}
                            md={8}
                            sm={24}
                            xs={24}
                            className="driverInfo"
                          >
                            <Popover
                              placement="rightTop"
                              className="routeDriverInfo"
                              overlayStyle={{
                                minWidth: '300px',
                              }}
                              content={
                                <Fragment>
                                  <Profile
                                    profile={
                                      this.state.driversInfo.primary
                                        .allotedDriver
                                    }
                                    withLables={false}
                                    isMobileScreen={false}
                                  />
                                </Fragment>
                              }
                              title={`Primary ${I18n.t('general.Driver')}`}
                              style={{ minWidth: 300 }}
                            >
                              <div>
                                <Avatar
                                  src={`${
                                    this.state.driversInfo.primary.allotedDriver
                                      .image &&
                                    this.state.driversInfo.primary.allotedDriver
                                      .image.url
                                      ? this.state.driversInfo.primary
                                          .allotedDriver.image.url
                                      : './../icons/user_100*100.png'
                                  }`}
                                  size={40}
                                />
                                <div style={{ fontSize: 10 }}>Primary</div>
                                <div className="alignCenter textBold">
                                  {this.state.driversInfo.primary.allotedDriver
                                    .full_name
                                    ? this.state.driversInfo.primary
                                        .allotedDriver.full_name
                                    : I18n.t('users.not_assigned')}
                                </div>
                              </div>
                            </Popover>
                          </Col>
                          <Col lg={6} md={8} sm={24} xs={24}>
                            <Popover
                              placement="rightTop"
                              className="routeDriverInfo"
                              overlayStyle={{
                                minWidth: '300px',
                              }}
                              content={
                                <Fragment>
                                  <Profile
                                    profile={
                                      this.state.driversInfo.secondary
                                        .allotedDriver
                                    }
                                    withLables={false}
                                    isMobileScreen={false}
                                  />
                                </Fragment>
                              }
                              title={`Secondary ${I18n.t('general.Driver')}`}
                              style={{ width: 500 }}
                            >
                              <div>
                                <Avatar
                                  src={`${
                                    this.state.driversInfo.secondary
                                      .allotedDriver.image &&
                                    this.state.driversInfo.secondary
                                      .allotedDriver.image.url
                                      ? this.state.driversInfo.secondary
                                          .allotedDriver.image.url
                                      : './../icons/user_100*100.png'
                                  }`}
                                  size={40}
                                />
                                <div style={{ fontSize: 10 }}>Secondary</div>
                                <div className="alignCenter textBold">
                                  {this.state.driversInfo.secondary
                                    .allotedDriver.full_name
                                    ? this.state.driversInfo.secondary
                                        .allotedDriver.full_name
                                    : I18n.t('users.not_assigned')}
                                </div>
                              </div>
                            </Popover>
                          </Col>
                          <Col lg={6} md={8} sm={24} xs={24}>
                            <div>
                              <Avatar
                                src={
                                  this.state.truckInfo.allotedTruck.image
                                    ? this.state.truckInfo.allotedTruck.image
                                    : truckImg
                                }
                                size={40}
                              />
                              <div style={{ fontSize: 10 }}>
                                {I18n.t('truck.single')}
                              </div>
                              <div className="alignCenter textBold">
                                {this.state.truckInfo.allotedTruck.name ||
                                  'Not Assigned'}
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={8} sm={24} xs={24}>
                            {this.state.stops.length > 0 &&
                              routeStatus !== 'COMPLETED' && (
                                <Row className="route_action_buttons">
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    style={{ padding: '10px' }}
                                    className="alignLeft"
                                  >
                                    {this.state.is_optimized && (
                                      <Button
                                        type="primary"
                                        onClick={this.confirmRoute}
                                        className="margin5"
                                        loading={this.state.loading}
                                        disabled={
                                          !(this.state.stops.length > 0)
                                        }
                                      >
                                        Dispatch
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="blackBorder" style={{ marginTop: 15 }}>
                  <Col xs={24} className="paddingTop10">
                    <Row
                      className="center timeLine route_action_buttons"
                      style={{ margin: '14px 2px' }}
                    >
                      <Col sm={20} xs={20} md={22} lg={22}>
                        {this.state.stops.length > 0 && (
                          <Timeline>
                            {this.state.stops.map((task, index) => (
                              <Fragment key={`timeline_fragment${task.id}`}>
                                <Timeline.Item
                                  key={`timeline_item${task.id}`}
                                  className={task.completion_class}
                                  dot={
                                    <Button
                                      size="small"
                                      className={
                                        task.status === 'COMPLETED'
                                          ? 'completedStop'
                                          : task.status === 'ARRIVED'
                                          ? 'inProgessStop'
                                          : 'inCompletedStop'
                                      }
                                      shape="circle"
                                      onClick={() =>
                                        this.getCurrentStop(task.id)
                                      }
                                    >
                                      {index + 1}
                                    </Button>
                                  }
                                  color="red"
                                >
                                  <StopDetails
                                    stop={task}
                                    currentStop={this.state.currentStop}
                                    handleEditStop={this.handleEditStop}
                                    getCurrentStop={this.getCurrentStop}
                                  />
                                  {task.interconnection_status && (
                                    <Popover
                                      placement="bottom"
                                      title="Distance  between stops"
                                      content={
                                        <Row className="distanceInfo">
                                          <Col xs={24}>
                                            <Row>
                                              {' '}
                                              <Col xs={9}>
                                                <BranchesIcon/>
                                                &nbsp;&nbsp;Distance
                                              </Col>
                                              <Col xs={1}>:</Col>
                                              <Col xs={14}>{task.distance}</Col>
                                            </Row>
                                            <Row>
                                              {' '}
                                              <Col xs={9}>
                                                <Icon type="clock-circle" />
                                                &nbsp;&nbsp;
                                                {I18n.t('general.travel_time')}
                                              </Col>
                                              <Col xs={1}>:</Col>
                                              <Col xs={14}>{task.time}</Col>
                                            </Row>
                                            <Row>
                                              {' '}
                                              <Col xs={9}>
                                                <Icon type="clock-circle" />
                                                &nbsp;&nbsp;Rest
                                              </Col>
                                              <Col xs={1}>:</Col>
                                              <Col xs={14}>{task.rest}</Col>
                                            </Row>
                                            <Row>
                                              {' '}
                                              <Col xs={9}>
                                                <Icon type="clock-circle" />
                                                &nbsp;&nbsp;Waiting
                                              </Col>
                                              <Col xs={1}>:</Col>
                                              <Col xs={14}>{task.waiting}</Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      }
                                    >
                                      <Row
                                        className="distanceInfo"
                                        style={{ marginLeft: -30 }}
                                      >
                                        <Col xs={3}>
                                          <Divider />
                                        </Col>
                                        <Col xs={19} className="padding05">
                                          <Row>
                                            <Col xs={12}>
                                              <Row>
                                                {' '}
                                                <Col xs={24}>
                                                  &nbsp;&nbsp;
                                                  <BranchesIcon/>
                                                  &nbsp;&nbsp;Distance&nbsp;&nbsp;:&nbsp;&nbsp;
                                                  {task.distance}&nbsp;
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col xs={12} className="alignRight">
                                              <Row>
                                                {' '}
                                                <Col xs={24}>
                                                  <Icon type="clock-circle" />
                                                  &nbsp;&nbsp;Time&nbsp;&nbsp;:&nbsp;&nbsp;
                                                  {task.time}&nbsp;&nbsp;
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>

                                        <Col xs={2}>
                                          <Divider />
                                        </Col>
                                      </Row>
                                    </Popover>
                                  )}
                                </Timeline.Item>
                              </Fragment>
                            ))}
                          </Timeline>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg={14} md={14} xs={24} sm={24}>
                <Row
                  className="blackBorder"
                  style={{ minHeight: 250, margin: '10px 0px' }}
                >
                  <Col xs={24} className="mapArea">
                    <Card>
                      {mapStops.length > 0 && (
                        <Map
                          data={mapStops}
                          kms={this.state.disabled}
                          getCurrentStop={(id) => this.getCurrentStop(id)}
                          mapElement={
                            <div style={{ height: 250, width: 'auto' }} />
                          }
                          driver={
                            this.state.routeStatus !== 'COMPLETED'
                              ? this.state.driverInfo
                              : ''
                          }
                          routeStatus={this.state.routeStatus}
                          screen="stops"
                        />
                      )}
                      {/* <div id="directions-panel" /> */}
                    </Card>
                  </Col>
                </Row>
                <Row className="blackBorder" style={{ minHeight: 250 }}>
                  <Col xs={24}>
                    {this.state.currentStop &&
                      Object.keys(this.state.currentStop).length > 0 && (
                        <Card
                          size="small"
                          title={`Stop : ${
                            this.state.currentStop.stop_number
                              ? this.state.currentStop.stop_number
                              : 'NA'
                          }`}
                        >
                          <ItemsList
                            data={this.state.currentStop.order_items}
                            size="small"
                            orderDetailsViewClick={(id) =>
                              this.handleOrderDetailsViewClick(id)
                            }
                            stopType={
                              this.state.currentStop.location &&
                              this.state.currentStop.location.l_type
                                ? this.state.currentStop.location.l_type
                                : ''
                            }
                            expandedRowRender={(record) => (
                              <Row>
                                <Col xs={24}>
                                  <Row>
                                    <Col sm={2} xs={2} md={1} lg={1}>
                                      <div className="textCenter">
                                        <FormIcon/>
                                        &nbsp;
                                      </div>
                                    </Col>
                                    <Col sm={10} xs={10} md={4} lg={3}>
                                      <div className="info-text textBold">
                                        Notes
                                      </div>
                                    </Col>
                                    <Col xs={1}>:</Col>
                                    <Col sm={11} xs={11} md={18} lg={19}>
                                      <div className="info-text">
                                        <span>
                                          {record.notes ? record.notes : 'NA'}
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                  {record.failed_notes && (
                                    <Row>
                                      <Col sm={2} xs={2} md={1} lg={1}>
                                        <div className="textCenter">
                                          <FormIcon/>
                                          &nbsp;
                                        </div>
                                      </Col>
                                      <Col sm={10} xs={10} md={4} lg={3}>
                                        <div className="info-text textBold">
                                          Failed Notes
                                        </div>
                                      </Col>
                                      <Col xs={1}>:</Col>
                                      <Col sm={11} xs={11} md={18} lg={19}>
                                        <div className="info-text">
                                          <span>{record.failed_notes}</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                  {this.state.currentStop.location &&
                                    this.state.currentStop.location.l_type &&
                                    this.state.currentStop.location.l_type !==
                                      'WH' && (
                                      <Row>
                                        <Col sm={2} xs={2} md={1} lg={1}>
                                          <div className="textCenter">
                                            <FormIcon/>
                                            &nbsp;
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={10} md={4} lg={3}>
                                          <div className="info-text textBold">
                                            Services
                                          </div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={11} md={18} lg={19}>
                                          {record.service_load_opted ||
                                          record.service_uninstall_opted ||
                                          record.service_install_opted ||
                                          record.service_unload_opted ? (
                                            <Row>
                                              {record.service_uninstall_opted && (
                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  lg={8}
                                                  md={8}
                                                >
                                                  <Icon
                                                    type="check-circle"
                                                    theme="filled"
                                                    className={
                                                      record.service_uninstall_completed
                                                        ? 'colorGreen'
                                                        : 'colorGrey'
                                                    }
                                                  />{' '}
                                                  &nbsp; UnInstall
                                                </Col>
                                              )}
                                              {record.service_load_opted && (
                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  lg={8}
                                                  md={8}
                                                >
                                                  <Icon
                                                    type="check-circle"
                                                    theme="filled"
                                                    className={
                                                      record.service_load_completed
                                                        ? 'colorGreen'
                                                        : 'colorGrey'
                                                    }
                                                  />{' '}
                                                  &nbsp; Load
                                                </Col>
                                              )}
                                              {record.service_unload_opted && (
                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  lg={8}
                                                  md={8}
                                                >
                                                  <Icon
                                                    type="check-circle"
                                                    theme="filled"
                                                    className={
                                                      record.service_unload_completed
                                                        ? 'colorGreen'
                                                        : 'colorGrey'
                                                    }
                                                  />{' '}
                                                  &nbsp; UnLoad
                                                </Col>
                                              )}

                                              {record.service_install_opted && (
                                                <Col
                                                  xs={24}
                                                  sm={24}
                                                  lg={8}
                                                  md={8}
                                                >
                                                  <Icon
                                                    type="check-circle"
                                                    theme="filled"
                                                    className={
                                                      record.service_install_completed
                                                        ? 'colorGreen'
                                                        : 'colorGrey'
                                                    }
                                                  />{' '}
                                                  &nbsp; Install
                                                </Col>
                                              )}
                                            </Row>
                                          ) : (
                                            'Not Opted'
                                          )}
                                        </Col>
                                      </Row>
                                    )}
                                </Col>
                              </Row>
                            )}
                          />

                          {/* {this.state.currentStop &&
                      this.state.currentStop.drop_order_items &&
                      this.state.currentStop.drop_order_items.length > 0 > 0 &&
                        <Card size={"small"}
                        title={`Drop  items (${this.state.currentStop.drop_order_items.length})`}
                        className="marginTop20">
                          {
                            this.state.currentStop.drop_order_items.map(x => {
                              return <StopItemDetails order_item={x} />
                            })
                          }
                        </Card>
                      } */}
                        </Card>
                      )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
          {this.state.errors && <Row>{FormErrors(this.state.errors)}</Row>}

          {this.state.showEditStopModal && this.renderStopsEditModal()}
          {this.state.assignWindow && this.renderAssignModal()}
        </div>

        {this.state.detailsVisible && (
          <Drawer
            title={`${I18n.t('order.details')} ${
              this.state.currentOrder.customer_order_number
                ? `(#${this.state.currentOrder.customer_order_number})`
                : ''
            }`}
            placement="left"
            closable={false}
            onClose={this.onClose}
            visible={this.state.detailsVisible}
            width="85%"
          >
            <OrderDetails
              currentOrder={this.state.currentOrder}
              surveyDetails={(id) => this.handleSurveyDetails(id)}
            />
          </Drawer>
        )}

        {this.state.editForm && (
          <Drawer
            placement="left"
            closable // false
            onClose={this.closeEditFormModal}
            visible={this.state.editForm}
            width="85%"
          >
            {false && (
              <EditOrderDetails
                orderObject={this.state.orderObject}
                currentOrder={this.state.currentOrder}
                handleCancel={this.closeEditFormModal}
                handleSuccess={this.handleSaveSuccess}
              />
            )}
            {
              <EditOrderForm
                customerDetails={this.state.currentOrder}
                preferences={[]}
                handleCancel={this.closeEditFormModal}
                handleSuccess={this.handleSaveSuccess}
              />
            }
          </Drawer>
        )}

        {this.state.rearrangeForm && (
          <Drawer
            title={`${I18n.t('routes.resequence')} the stops`}
            placement="left"
            closable
            onClose={this.closeRearrangeForm}
            visible={this.state.rearrangeForm}
            width="85%"
          >
            <RearrangeStops
              routeId={this.state.routeId}
              stopsData={this.state.stops}
              currentOrder={this.state.currentOrder}
              handleCancel={this.closeRearrangeForm}
              handleSuccess={this.handleSaveSuccess}
              formatStopName={(contact_details, location, stop_number) =>
                this.formatStopName(contact_details, location, stop_number)
              }
            />
          </Drawer>
        )}

        {this.state.showSurvey && (
          <BaseModal
            title="Survey Details"
            onCancel={() => this.closeSurveyDetails()}
            className="surveyModal"
            width="70%"
            style={{ top: 40, overflowY: 'auto' }}
          >
            <SurveyResponse surveyId={this.state.surveyResponseId} />
          </BaseModal>
        )}

        {this.state.listVisible && (
          <Drawer
            title="Orders"
            placement="right"
            closable={false}
            onClose={this.closeEditFormModal}
            visible={this.state.listVisible}
            width="80%"
          >
            <Row>
              <Col xs={24} className="alignRight margin5">
                <Button type="primary" onClick={this.handleAddStop}>
                  Add Stop
                </Button>
              </Col>
            </Row>
            <OrdersList
              isMilitaryTime={this.state.isMilitaryTime}
              data={this.state.orderInfo}
              editClick={(id) => this.handleEditClick(id)}
              openInfoModal={(id) => this.openInfoModal(id)}
              openTrackScreen={this.openTrackScreen}
              orderDetailsViewClick={(id) =>
                this.handleOrderDetailsViewClick(id)
              }
              handleEditOrder={(id) => this.handleEditOrderClick(id)}
              screen_from="routes"
              handleSuccess={(message) => this.handleSaveSuccess(message)}
              deleteOrderClick={(id) => this.handleOrderDeleteFromRoute(id)}
              handleSurveyDetails={(id) => this.handleSurveyDetails(id)}
              rowSelection={rowSelection}
            />

            {(this.state.routes.selectedOrderKeys.length > 0 ||
              routeStatus === 'COMPLETED') && (
              <Row>
                <Col
                  xs={24}
                  style={{ padding: '10px 10px' }}
                  className="alignCenter"
                >
                  <Button
                    className="buttonModal"
                    onClick={this.closeListDrawer}
                    style={{
                      color: '#F5d358',
                      borderRadius: 25,
                      backgroundColor: '#040404',
                    }}
                  >
                    {routeStatus !== 'COMPLETED' ? 'Cancel' : 'Close'}
                  </Button>

                  {routeStatus !== 'COMPLETED' &&
                    routeStatus !== 'INPROGRESS' &&
                    routeStatus !== 'PREPARE' &&
                    routeStatus !== 'DISPATCHED' && (
                      <Button
                        className="buttonModal"
                        onClick={this.handleSave}
                        loading={this.state.loading}
                        disabled={
                          !(this.state.routes.selectedOrderKeys.length > 0)
                        }
                      >
                        {this.state.routeId ? 'Update' : 'Create'} Route
                      </Button>
                    )}
                </Col>
              </Row>
            )}
          </Drawer>
        )}
      </div>
    )
  }
}

Stops.propTypes = {
  match: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
  routeId: PropTypes.string,
  reqFrom: PropTypes.string,
}

Stops.defaultProps = {
  match: {},
  routeId: '',
  reqFrom: '',
}

 const StopsComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <Stops
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default StopsComponent;