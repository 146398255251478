import React, { Component } from "react";
import { TemplateEventsApi } from "../../../api/TemplateEventsApi";
import { alertMessage, doValidate, formatCode } from "../../../common/Common";
import {
  Row,
  Col,
  Input,
  FormItem,
  Select,
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import { renderAlertMessage } from "../../../helpers/common";

const requiredFields = [
  {
    form_field: "event_code",
    ui_name: I18n.t("template_events.event_code"),
    isRequired: true,
    inputType: "code",
  },
  {
    form_field: "event_name",
    ui_name: I18n.t("template_events.event_name"),
    isRequired: true,
    inputType: "text",
  },
  
  {
    form_field: "template_params",
    ui_name: I18n.t("template_events.params"),
    isRequired: true,
    inputType: "array",
  }
];
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      currentEvent: this.props.currentEvent ? this.props.currentEvent : {},
      errors: [],
      isNew: this.props.isNew,
      templates: this.props.templates ? this.props.templates : {},
    };
  }
  handleSave = () => {
    let eventParam = this.state.currentEvent || {};
    if (eventParam && eventParam.template_params && eventParam.template_params.includes("ALL")) {
      eventParam.template_params = this.props.templates.map(
        (template) => template.code
      );
    }
    const errors = doValidate(requiredFields, eventParam);
    if (errors.length === 0) {
      eventParam.event_code = formatCode(eventParam.event_code);
      this.setState({ inProgress: true });
      TemplateEventsApi.manage(this.state.isNew, eventParam).then((result) => {
        if (result.success) {
          this.setState({ 
            inProgress: false, 
            errors: result.errors,
          });
          this.props.onSuccess(I18n.t("template_events.save"));
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    }else{
      this.setState({
        errors,
      })
    }
  };
  handleChange = (element, value) => {
    if (element === "template_params") {
      if (
        value.includes("ALL") ||
        value.length === this.props.templates.length
      ) {
        value = ["ALL"];
      }
    }

    this.setState({
      currentEvent: Object.assign({}, this.state.currentEvent, {
        [element]: value,
      }),
    });
  };
  render() {
    const { currentEvent } = this.state;
    return (
      <div>
        <Row gutter={16}>
          <Col sm={12} xs={12} md={8} lg={24}>
            <FormItem label={I18n.t("template_events.event_name")} require>
              <Input
                value={currentEvent.event_name}
                onChange={(e) =>
                  this.handleChange("event_name", e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12} xs={12} md={8} lg={24}>
            <FormItem label={I18n.t("template_events.event_code")} require>
              <Input
                value={currentEvent.event_code}
                onChange={(e) =>
                  this.handleChange("event_code", e.target.value)
                }
                className="textUpperCase"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem label={I18n.t("template_events.params")} require>
            <Select
              style={{ width: "100%" }}
              showSearch
              mode="multiple"
              placeholder={"---Select---"}
              onChange={(e) => this.handleChange("template_params", e)}
              value={currentEvent.template_params}
            >
              {this.props.templates.length > 0 && (
                <Select.Option key="ALL" value="ALL"> All</Select.Option>
              )}
              {this.props.templates.map((template) => (
                <Select.Option key={template.id} value={template.code}>
                  {template.code}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form;
