import React, { Fragment, useEffect, useState } from 'react'
import {
  Radio,
  Row,
  Col,
  Collapse,
  Select,
  Modal,
  Spin,
  DatePicker,
  Button,
  Popconfirm,
  Alert
} from 'antd'
import _, { isEmpty } from 'lodash';
import AppConfig from '../../config/AppConfig'
import userStore from '../../stores/UserStore'
import moment from 'moment'
import { TemplatesApi } from './Api'
import { dayNumbersData, daysData } from '../../constants/daynmonths'
import I18n from '../../common/I18n'
import FormErrors from '../common/FormErrors'
import { alertMessage } from '../../common/Common'
import { momentTime } from '../../helpers/date_functions'
import MultipleDatePicker from '../../containers/MultipleDatePicker';

const { Option } = Select
const { confirm } = Modal
const { Panel } = Collapse

const schedulerTypes = [
  {
    key: 'date',
    label: 'Specific Date',
  },
  {
    key: 'daily',
    label: 'Daily',
  },
  {
    key: 'days',
    label: 'Days',
  },
  {
    key: 'monthly',
    label: 'Monthly',
  },
]

function TemplateCronConfig(props) {
  const { activeRecordId = null, activeRecord ,  isFromRouteCreation, api } = props
  const [config, setConfig] = useState({
    date: moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
  })
  const [currentSchedule, setCurrentSchedule] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [configType, setConfigType] = useState('date')
  const [errors, setErrors] = useState(false)
  const [holidaysDates, setHolidaysDates] = useState([]);
  const [workingDates, setWorkingDates] = useState([]);
  const orgId = userStore.getStateValue('selectedOrg')

  const getScheduleType = (configData= {}) => {
    const scheduleKeys = schedulerTypes.map(rec => rec.key);
    const configKeys = Object.keys(configData);
    const commonKeys =  _.intersection(scheduleKeys, configKeys);
    if(commonKeys.length > 0){
      return commonKeys[0]
    }
    return "";
  }

  const setDeafaultValues = () => {
    setConfigType('date');
    setDeafaultConfigValues('date')
  }

  const getScheduleInfo = () => {
    api.getTemplateSchedule(activeRecordId).then((response) => {
      if (response.success) {
        const schedule = response.data || {};
        setCurrentSchedule(schedule);
        setHolidaysDates(schedule?.holidays?.length ? schedule.holidays : []);
        setWorkingDates(schedule?.en_order_dates?.length ? schedule.en_order_dates : []);
        const configTypeValue = getScheduleType(schedule.config);
        if(!isEmpty(configTypeValue)){
          setConfigType(configTypeValue);
          const configValue = configTypeValue === 'date' ? moment(schedule.config[configTypeValue], "YYYY-MM-DD") : configTypeValue === 'monthly' ? Number(schedule.config[configTypeValue]) : schedule.config[configTypeValue]
          setConfig({ [configTypeValue]: configValue})
        } else {
          setDeafaultValues();
        }
        setIsLoading(false)
        setErrors([])
      } else {
        setDeafaultValues();
        setIsLoading(false)
        setErrors(result.errors)
      }
    })

  }

  // on mount
  useEffect(() => {
    if (!isFromRouteCreation) {
    getScheduleInfo()
    } else {
      if(!activeRecord){ return }
      const configTypeValue = getScheduleType(activeRecord.schedule_config);
      if(!isEmpty(configTypeValue)){
        setConfigType(configTypeValue);
        const configValue = configTypeValue === 'date' ? moment(activeRecord.schedule_config[configTypeValue], "YYYY-MM-DD") : configTypeValue === 'monthly' ? Number(activeRecord.schedule_config[configTypeValue]) : activeRecord.schedule_config[configTypeValue]
        setConfig({ [configTypeValue]: configValue})
        setHolidaysDates(activeRecord?.holidays?.length > 0 ? activeRecord.holidays : [])
        setWorkingDates(activeRecord?.en_route_dates?.length > 0 ? activeRecord.en_route_dates : [])
      } else {
        setDeafaultValues();
      }
    }
  }, [])

  const setDeafaultConfigValues = (configTypeValue = 'date') => {
    const dafaultValue = getDefaultValue(configTypeValue)
    setConfig({ [configTypeValue]: dafaultValue })
  }

  const handleSave = () => {
    const data = {
      organization_id: orgId,
      order_template_id: activeRecordId,
      config: {},
      holidays: holidaysDates,
      en_order_dates: workingDates
    }
    if(configType === 'date'){
      data.config[configType] = config[configType] ? moment(config[configType]).format("YYYY-MM-DD") : null;
    }else{
      data.config = {...config};
    }
    setIsLoading(true)
    api.saveTemplateSchedule(data).then((response) => {
      if (response.success) {
        alertMessage(I18n.t('messages.saved'))
        setIsLoading(false)
        setErrors([])
      } else {
        setIsLoading(false)
        setErrors(result.errors)
      }
    })
  }
  const renderDateConfig = (key) => {
    return (
      <Row>
        <Col span={ 24 } className="textBold paddingTop5">
          {I18n.t(`templates.select_${key}`)}
        </Col>
        <Col span={ 24 }>
          <DatePicker
            value={config[key]}
            format={AppConfig.dateFormat}
            onChange={(e) => handleOnChange(key, e)}
            style={{ width: 280}}
            // open
          />
          {/* { !isFromRouteCreation && <span className='marginLeft10'>{ renderSaveButton() }</span> } */}
        </Col>
      </Row>
    )
  }
  const renderDailyConfig = (key) => {
    return <Fragment>
    <Row>
      <Col xs={24} className="marginBottom20">
        <Alert
          message={I18n.t('templates.daily_alert_msg')}
          type="info"
          showIcon
        />
      </Col>
        {/* { !isFromRouteCreation && <Col xs={ 24 }> <span className='marginLeft10'>{ renderSaveButton() }</span></Col> } */}
    </Row>
   </Fragment>
  }
  const renderMonthlyConfig = (key) => {
    return (
      <Row>
        <Col span={ 24 } className="textBold paddingTop5">
          {I18n.t(`templates.select_${key}`)}:{' '}
        </Col>
        <Col span={ 24 }> 
          <Select
            value={config[key]}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '60%' }}
            onChange={(e) => handleOnChange(key, e)}
            //  open
          >
            {dayNumbersData.map((rec) => (
              <Select.Option key={rec.value} value={rec.value}>
                {rec.label}
              </Select.Option>
            ))}
          </Select>
          {/* { !isFromRouteCreation && <span className='marginLeft10'>{ renderSaveButton() }</span> } */}
        </Col>
      </Row>
    )
  }
  const renderDaysConfig = (key) => {
    return (
      <Row>
        <Col span={ 24 } className="textBold paddingTop5">
          {I18n.t(`templates.select_${key}`)}:
        </Col>
        <Col span={ 24 }>
          <Select
            value={config[key] || ''}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            // style={{ width: '60%' }}
            style={ isFromRouteCreation ? { width: '100%' } : { width: '60%' } }
            onChange={(e) => handleOnChange(key, e)}
            mode="multiple"
            // open
          >
            {daysData.map((rec) => (
              <Select.Option key={rec.value} value={rec.value}>
                {rec.label}
              </Select.Option>
            ))}
          </Select>
          {/* { !isFromRouteCreation && <span className='marginLeft10'>{ renderSaveButton() }</span> } */}
        </Col>
      </Row>
    )
  }

  const renderConfigParams = (configType) => {
    switch (configType) {
      case 'date':
        return renderDateConfig(configType)
      case 'days':
        return renderDaysConfig(configType)
      case 'daily':
        return renderDailyConfig(configType)
      case 'monthly':
        return renderMonthlyConfig(configType)
      default:
        return null
        break
    }
  }

  const getDefaultValue = (configType) => {
    switch (configType) {
      case 'date':
        const dateValue = moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        return dateValue;
      case 'days':
        return ['SUNDAY']
      case 'daily':
        return daysData.map((rec) => rec.value)
      case 'monthly':
        return '01'
      default:
        return null
        break
    }
  }

  const handleOnChange = (key, value) => {
    if (key === 'date') {
      value = value.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    }
    setConfig({
      [key]: value,
    })
  }
  // on change of config call props.onChange
  useEffect(() => {
    if (props.onConfigChange) {
      const data = {
        config: {}
      };
      if (configType === 'date') {
        data.config[ configType ] = config[ configType ] ? moment(config[ configType ]).format("YYYY-MM-DD") : null;
      }
      else {
        data.config = { ...config };
      }
      props.onConfigChange(data);
    }
  }, [ config ])

  useEffect(() =>{
    if (props.onHolidayChange) {
      props.onHolidayChange(holidaysDates)
    }
  }, [holidaysDates])

  useEffect(() => {
    if (props.onWorkingDateChange) {
      props.onWorkingDateChange(workingDates);
    }
  }, [workingDates]);

  const renderSaveButton = () => {
    return (
      <Popconfirm
        placement="topRight"
        title={I18n.t('general.are_you_sure')}
        onConfirm={() => handleSave()}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary">Save</Button>
      </Popconfirm>
    )
  }

  const renderScheduleForm = () => (
    <Row gutter={8}>
      <Col xs={24}>
        <h3>
          <span className="textBold marginRight10">
            {I18n.t('templates.schedule_type')} :
          </span>
          <Radio.Group
            onChange={(e) => {
              setConfigType(e.target.value)
              setDeafaultConfigValues(e.target.value)
            }}
            value={configType}
            style={{ marginTop: 3 }}
          >
            {schedulerTypes.map((rec) => (
              <Radio value={rec.key}>{rec.label}</Radio>
            ))}
          </Radio.Group>
        </h3>
      </Col>
      <Col xs={ 24 }>
        {renderConfigParams(configType)}
      </Col>
      <Col xs={24}>
        <Row>
          <Col xs={isFromRouteCreation ? 24 : 12} style={{ marginTop: 20 }}>
            <MultipleDatePicker
              onHolidayChange={setHolidaysDates}
              holidaysDates={holidaysDates}
              onWorkingDateChange={setWorkingDates}
              workingDates={workingDates}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <Spin spinning={isLoading} delay={1000}>
      <div className="templatesSchedule">
        <Row>
          <Col span={24}>{renderScheduleForm()}</Col>
          {!isFromRouteCreation && <Col span={24} className='alignCenter margintop10'>
            <Row>
              {renderSaveButton()}
            </Row>
          </Col>}
          <Col span={ 24 }>{ FormErrors(errors) }</Col>
        </Row>
      </div>
    </Spin>
  )
}

export default TemplateCronConfig
