import React, { Component } from 'react'
import {
  Col,
  Select,
  Row,
  FormItem,
  Button,
  Spin,
} from '../common/UIComponents'
import {  alertMessage } from '../common/Common'
import I18n from '../common/I18n'
import {  fetchShortFormAccounts } from '../api/Account'
import { fetchOrganizations } from '../api/Organisations'
import { withRouter } from 'react-router'
import { MappingApi } from '../api/CsvcolumnMapping'
import Listform from '../components/Listform'
import AppConfig from '../config/AppConfig'
import { renderAlertMessage } from '../helpers/common'

class MappingSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accounts: [],
      accountCode: '',
      organizations: [],
      organization_id: null,
      AccountId: '',
      inProgress: true,
      fileType: 'csv',
      csv_column_mappings: [],
    }
  }

  componentDidMount() {
    this.getOrganizations()
  }

  getOrganizations = (cb) => {
    this.setState({ inProgress: true })
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const organization_id = _.find(result.organizations.organizations, [
          'code',
          I18n.t('account.netmove_code'),
        ])._id

        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id,
          },
          () => {
            this.getAccounts()
            if (cb) {
              cb()
            }
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  getAccounts = () => {
    const orgId = this.state.organization_id
    fetchShortFormAccounts(orgId,"",true).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  getMapping = () => {
    const { organization_id, AccountId, fileType } = this.state
    MappingApi.fetch(organization_id, AccountId, fileType).then((result) => {
      this.setState({
        csv_column_mappings: result.data,
      })
    })
  }

  handleOnChange = (element, value) => {
    if (element === 'organization_id') {
      this.setState({ [element]: value }, () => {
        this.getAccounts(),
          this.setState({
            AccountId: '',
            accountCode: '',
          })
      })
    } else if (element === 'accountCode') {
      this.setState({ [element]: value })
      const selectesAccIndex = _.findIndex(this.state.accounts, ['code', value])
      if (selectesAccIndex >= 0) {
        const selectedAccount = Object.assign(
          {},
          this.state.accounts[selectesAccIndex],
        )
        const AccountId = selectedAccount.id
        //setting State Account ID
        this.setState({ AccountId })
      } else {
        //setting State Account ID to null if account is not
        this.setState({ AccountId: '' })
      }
    } else {
      this.setState({ [element]: value })
    }
  }

  render() {
    return (
      <>
        <div className="content-outer">
          <div className="content">
            <Row className="page-header">
              <Col xs={12}>{I18n.t('menu.mappingSetting')}</Col>
              <Col xs={12} className="page-actions">
                <Button
                  type="primary"
                  icon="plus"
                  className="Wrapper__left__header__button"
                  onClick={() => this.props.history.push(`csv_settings`)}
                >
                  {I18n.t('mappingSetting.add')}
                </Button>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={6} lg={6}>
                <FormItem label={I18n.t('organization.single')} require>
                  <Select
                    value={this.state.organization_id}
                    showSearch
                    onChange={(e) => this.handleOnChange('organization_id', e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                  >
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={6} lg={6}>
                <FormItem label={I18n.t('general.account')}>
                  <Select
                    value={this.state.accountCode || ''}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    onChange={(e) => this.handleOnChange('accountCode', e)}
                    dropdownMatchSelectWidth={false}
                  >
                    <Select.Option key="select" value="">
                      -- Select --
                    </Select.Option>
                    {this.state.accounts.map((account) => (
                      <Select.Option key={account.code} value={account.code}>
                        {`${account.name} (${account.code})`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={6} lg={4}>
                <FormItem label={I18n.t('general.file_type')} require>
                  <Select
                    value={this.state.fileType || ''}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    onChange={(e) => this.handleOnChange('fileType', e)}
                    dropdownMatchSelectWidth={false}
                  >
                    {AppConfig.uploadOrderFileTypes.map((typeOfFile) => (
                      <Select.Option key={typeOfFile} value={typeOfFile}>
                        {typeOfFile.toUpperCase()}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={6} lg={6} className="marginTop20">
                <Button
                  type="primary"
                  onClick={() => this.getMapping()}
                  icon='search'
                >
                  {I18n.t('general.search')}
                </Button>
              </Col>
            </Row>

            <Spin spinning={this.state.inProgress} delay={1000}>
              <Row>
                <Col>
                  <Listform data={this.state.csv_column_mappings} />
                </Col>
              </Row>
            </Spin>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(MappingSettings)
