import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Tooltip,
  Popconfirm,
} from "../../common/UIComponents";
import { Button, Col, Form, Icon, Input, Row, Spin } from "antd";
import DeleteIcon from "../common/DeleteIcon";
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";
import { alertMessage, isEmpty } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { DriverTypesApi } from "../../api/DriverType";
import { renderAlertMessage } from "../../helpers/common";
const DriverTypes = (props) => {
  const [approvalDescription, setApprovalDescription] = useState([]);
  const [editableId, setEditableId] = useState(null);
  const [editedDriverType, setEditedDriverType] = useState("");
  const [showNewRow, setShowNewRow] = useState(false);
  const [newRowDriverType, setNewRowDriverType] = useState("");
  const [messageError, setMessageError] = useState("");
  const [showAddButton, setShowAddButton] = useState(true);
  const orgContextData = useContext(OrgContext);
  const { currentOrg } = orgContextData;
  const [inProgress, setInProgress] = useState(false);
  const inputRef = useRef(null);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getDriverType = () => {
    const organization_id = currentOrg.id;
    setInProgress(true);
      DriverTypesApi.fetchDriverTypes(organization_id).then((result) => {
      if (result.success) {
        setApprovalDescription(
          result.driver_types
            ? result.driver_types.map((item) => ({
                ...item,
                name: capitalizeFirstLetter(item.name),
              }))
            : []
        );
        setShowNewRow(result?.driver_types?.length === 0);
        setInProgress(false);
      } else {
        renderAlertMessage(result.errors)
        setInProgress(false);
      }
    });
  };

  const handleEdit = (id) => {
    setEditableId(id);
    const itemToEdit = approvalDescription.find((item) => item.id === id);
    if (itemToEdit) {
      setEditedDriverType(itemToEdit.name);
    }
    setShowNewRow(false);
  };
  const handleSave = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const payLoad = {
      name: editedDriverType,
    };
    setInProgress(true);
    DriverTypesApi.updateDriverTypes(id, payLoad, organization_id).then(
      (result) => {
        if (result.success) {
          setInProgress(false);
          setApprovalDescription((prevState) =>
            prevState.map((item) =>
              item.id === id
                ? {
                    ...item,
                    name: editedDriverType,
                  }
                : item
            )
          );
          alertMessage("Updated Successfully","success",5)
        } else {
          renderAlertMessage(result.errors)
        }
      }
    );
    setEditableId(null);
  };
  const handleCancelEdit = () => {
    setEditableId(null);
    if (showNewRow) {
      if (editableId === null) {
        setNewRowDriverType("");
        setMessageError("");
        setShowAddButton(true);
      }
      setShowNewRow(false);
    }
  };

  useEffect(() => {
    if (approvalDescription.length === 0) {
      setShowAddButton(true);
      setShowNewRow(true);
    }
  }, [approvalDescription]);

  useEffect(() => {
    if (!isEmpty(editableId)) {
      inputRef.current.focus();
    }
  }, [editableId]);

  const handleDelete = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    setInProgress(true);
    DriverTypesApi.deleteDriverTypes(id, organization_id).then(
      (result) => {
        if (result.success) {
          setInProgress(false);
          const updatedList = approvalDescription.filter(
            (item) => item.id !== id
          );
          setApprovalDescription(updatedList);
          alertMessage(result.data.message, "success", 5);
        } else {
          setInProgress(false);
          renderAlertMessage(result.errors)
        }
      }
    );
  };
  const handleSaveNewRow = () => {
    if (
      newRowDriverType.trim() === "" 
    ) {
      if (newRowDriverType.trim() === "") {
        setMessageError(`${I18n.t("errors.driver_type")}`);
      } else {
        setMessageError("");
      }
     
    } else {
      const newRow = {
        name: newRowDriverType,
        organization_id: userStore.getStateValue("selectedOrg"),
      };
      setInProgress(true);
      DriverTypesApi.saveDriverTypes(newRow).then((result) => {
        if (result.success) {
          setInProgress(false);
          setApprovalDescription((prevState) => [
            ...prevState,
            result.data.driver_types,
          ]);
          alertMessage("Added Successfully",'success',5)
          getDriverType();
        } else {
          renderAlertMessage(result.errors)
        }
      });
      setNewRowDriverType("");
      setShowNewRow(false);
      setMessageError("");
    }
  };
  useEffect(() => {
    getDriverType();
  }, []);

  const handleAddNewRow = () => {
    setShowNewRow(true);
    setNewRowDriverType("");
    setMessageError("");
    setShowAddButton(false);
  };

  const handleKeyPress = (event, id, saveItem = "newRow") => {
    if (event.key === "Enter") {
      if(saveItem === "newRow"){
        handleSaveNewRow()
      }else{
        handleSave(id);
      }
    }
  };

  const handleChangeNewRowDriverType = (e) => {
    const value = e.target.value || "";
    setNewRowDriverType(value.charAt(0).toUpperCase() + value.slice(1));
  };

  const renderDriverTypeTable = () => {
    return (
      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col span={10}>
          <span style={{ fontWeight: 700, paddingLeft: "15px" }}>
            <sup style={{ color: "red" }}>*</sup>
              {"Driver Types"}
          </span>
        </Col>
        {(!showNewRow && isEmpty(editableId)) && (<Col span={6}>
            <Button type="primary" onClick={handleAddNewRow} icon="plus" size="small">
              Add New Driver Type
            </Button>
        </Col>)}
      </Row>
    );
  };

  const renderInputs = () => {
    return (
      <>
        {approvalDescription.map(
          (item, index) =>
            item && (
              <Row gutter={[16, 16]} key={item.id || item._id}>
                <Col span={10}>
                  {editableId === item.id ? (
                    <Input
                      value={editedDriverType}
                      onChange={(e) =>
                        setEditedDriverType(e.target.value)
                      }
                      autoFocus
                      ref={inputRef} 
                      onKeyPress = {(e) => handleKeyPress(e, item.id, "editableRow")}
                    />
                  ) : (
                    <Input value={item.name} disabled />
                  )}
                </Col>
                <Col span={6} style={{padding:'15px'}}>
                  {editableId === item.id ? (
                    <>
                      <Tooltip title={I18n.t("general.save")}>
                        <Icon
                          type="check-circle"
                          style={{ color: "green" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleSave(item.id)}
                        />
                      </Tooltip>
                      <Tooltip title={I18n.t("general.cancel")}>
                        <Icon
                          type="close-circle"
                          style={{ color: "red" }}
                          onClick={() => handleCancelEdit()}
                          size="small"
                          className="marginLeft10 fontSize18"
                        />
                      </Tooltip>
                    </>
                  ) : isEmpty(editableId) && !showNewRow && (
                    <>
                      <Tooltip title={I18n.t("general.edit")}>
                        <Icon
                          type="edit"
                          style={{ color: "blue", marginRight: "10px" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleEdit(item.id)}
                        />
                      </Tooltip>
                      <Popconfirm
                        placement="left"
                        title={I18n.t("messages.confirm")}
                        onConfirm={() => handleDelete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon className="textBold fontSize18" />
                        &nbsp;&nbsp;&nbsp;
                      </Popconfirm>
                    </>
                  )}
                  {!showNewRow && approvalDescription.length - 1 === index && (
                    <>
                      {editableId ? null : (
                        <Tooltip title={I18n.t("general.add")}>
                          <Icon
                            type="plus-circle"
                            size="small"
                            style={{ marginTop: 7, color: "black" }}
                            className="marginLeft10 fontSize18"
                            onClick={() => setShowNewRow(true)}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )
        )}
      </>
    );
  };

  const renderNewRow = () => {
    return (
      <>
        {showNewRow && (
          <>
            {editableId ? null : (
              <Row gutter={[16, 16]} key="new-row">
                <Col span={10} className="right-aligned">
                  <Input
                    placeholder= "Driver Type"
                    value={newRowDriverType}
                    onChange={handleChangeNewRowDriverType}
                    onKeyPress = {(e) => handleKeyPress(e, "")}
                    autoFocus
                  />
                  {messageError && (
                    <div style={{ color: "red" }}>{messageError}</div>
                  )}
                </Col>
                <Col span={6} style={{padding:"15px"}}>
                  <Tooltip title={I18n.t("general.save")}>
                    <Icon
                      type="check-circle"
                      style={{ color: "green" }}
                      size="small"
                      className="marginLeft10 fontSize18"
                      onClick={() => handleSaveNewRow()}
                    />
                  </Tooltip>
                  <Tooltip title={I18n.t("general.cancel")}>
                    <Icon
                      type="close-circle"
                      size="small"
                      style={{ color: "red" }}
                      className="marginLeft10 fontSize18"
                      onClick={() => handleCancelEdit()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  };

  const renderDriverType = () => {
    return (
      <div>
        <Row style={{ margin: "0px 10px 10px 10px" }}>
          {renderDriverTypeTable()}
          {renderInputs()}
          {renderNewRow()}
        </Row>
      </div>
    );
  };

  return (
    <Spin spinning={inProgress} delay={1000}>
      <Row>
        <Col>
          <div style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}>
            {renderDriverType()}
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default DriverTypes;
