import React, { Fragment } from 'react'
import _ from 'lodash'
import BaseList from '../BaseList'
import {isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'

import {
  Icon, Tooltip,
} from '../../common/UIComponents'
import { checkServiceExistance, formateTwoDates } from '../../helpers/common'
import DownloadWeeklyReport from './DownloadWeeklyReport'
import EmailWeeklyReport from './EmailWeeklyReport'
import { recordExpression } from '@babel/types'
import EditIcon from '../common/EditIcon'
import EyeIcon from '../common/EyeIcon'

class NewWeeklyReport extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [];
    this.setColumns(props);

    if (props) {
      this.setColumns(props);
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }


  setColumns = (props) => {
    this.columns = [
      {
        title: I18n.t('general.week'),
        key: 'week_dates',
        render: (record) => <span>
        {record.start_of_week && record.end_of_week ? formateTwoDates(
          record.start_of_week,
          record.end_of_week,
          false,
          true
        )
        :
        <Fragment />
        }
        </span>,
      },
      {
        title: I18n.t("general.invoice"),
        key: "invoice",
        dataIndex: "invoice_number",
        render: (data) => {
          return !isEmpty(data) ? data : "-";
        },
      },
      {
        title: I18n.t("general.warehouse"),
        key: "wh_name",
        dataIndex: "wh_name",
        render: (data) => {
          return !isEmpty(data) ? data : "NA";
        },
      },
      {
        title: I18n.t('reports.no_of_orders'),
        key: 'no_of_orders',
        render: (data) => <span>{data.customer_order_numbers ? data.customer_order_numbers.length : 0 }</span>,
      },
      {
        title: I18n.t('reports.gross_revenue'),
        key: 'gross_revenue',
        // dataIndex: 'gross_revenue',
        render: (data) => (
          <span>
            {data.status === 'PENDING' && isEmpty(data.gross_revenue)
              ? 'NA'
              : data.gross_revenue}
          </span>
        ),
      },
      {
        title: I18n.t('reports.gross_driver_pay'),
        key: 'gross_driver_pay',
        // dataIndex: 'gross_pay',
        render: (data) => (
          <span>
            {data.status === 'PENDING' && isEmpty(data.gross_pay)
              ? 'NA'
              : data.gross_pay}
          </span>
        ),
      },
      {
        title: I18n.t('reports.total_deductions'),
        key: 'total_deductions',
        // dataIndex: 'total_deductions',
        render: (data) => (
          <span>
            {data.status === 'PENDING' && isEmpty(data.total_deductions)
              ? 'NA'
              : data.total_deductions}
          </span>
        ),
      },
      {
        title: I18n.t('reports.net_payment'),
        key: 'net_payment',
        // dataIndex: 'total_new_payment',
        render: (data) => (
          <span>
            {data.status === 'PENDING' && isEmpty(data.total_new_payment)
              ? 'NA'
              : data.total_new_payment}
          </span>
        ),
      },
      {
        title: 'Status',
        key: 'status',
        // dataIndex: 'total_new_payment',
        render: (data) => <span>{data.status}</span>,
      },
      {
        key: 'actions',
        title: '',
        // dataIndex: 'id',
        render: (text, record, index) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {record.status !== 'PENDING' && (
              <Fragment>
                <DownloadWeeklyReport
                  key={recordExpression.id}
                  driver_pay_report_id={record.id}
                  driver_code={
                    this.props?.currentDriver
                      ? this.props.currentDriver.employee_code
                      : {}
                  }
                  week_no={record.week_number}
                  displayType={'icon'}
                />
                &nbsp;&nbsp;
                <EmailWeeklyReport
                  key={recordExpression.id}
                  driver_pay_report_id={record.id}
                  driver_code={
                    this.props?.currentDriver
                      ? this.props.currentDriver.employee_code
                      : {}
                  }
                  week_no={record.week_number}
                  displayType={'icon'}
                  className="textVold"
                />
                &nbsp;&nbsp;
              </Fragment>
            )}
            {checkServiceExistance('DPOR') && (
              this.props.isDriverLogin ?
              <Tooltip title={I18n.t("general.view")}>
                <span><EyeIcon handleClick={() => this.props.handleView(record.id)} /></span>
              </Tooltip>
              :
              <Tooltip title={I18n.t("general.edit")}>
                <span><EditIcon handleClick={() => this.props.handleView(record.id)}/></span>
              </Tooltip>
            )}
          </div>
        ),
      },
    ];
    if (props.onSearch) {
      this.columns.splice(0, 0, {
        key: "driver",
        title: I18n.t("general.Driver"),
        render: (record) => record.driver_code,
      });
    }
  }
  
}

export default NewWeeklyReport
