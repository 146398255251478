import moment from 'moment';

export const getAddress = (event, job) => {
  let address = '';
  if (event === 'CMPKUP') {
    address = job.pickup_address;
  } else if (event === 'SSDELY') {
    // eslint-disable-next-line prefer-destructuring
    address = job.service_center.address;
  } else if (event === 'SSPKUP') {
    // eslint-disable-next-line prefer-destructuring
    address = job.service_center.address;
  } else if (event === 'CMDELY') {
    address = job.drop_address;
  }
  return address;
};
export const calculateTimeDuration = (start, end) => {
  const startTime = moment(start).isValid();
  const endTime = moment(end).isValid();
  if (startTime && endTime) {
    const StartTime = moment(start).seconds(0).toISOString();
    const EndTime = moment(end).seconds(0).toISOString();
    let duration = '';
    const hours = moment(EndTime).diff(moment(StartTime), 'hours');
    const minutes = moment(EndTime).diff(moment(StartTime), 'minutes') % 60;
    if (hours > 0) duration += `${hours} hour(s) `;
    duration += `${minutes} min(s)`;
    return duration;
  }
  if (startTime) return `${moment(start).format('hh:mm A')} - NA`;
  if (endTime) return `NA - ${moment(end).format('hh:mm A')}`;
  return 'NA';
};


export default {
  getAddress,
  calculateTimeDuration,
};

