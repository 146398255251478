import React, { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";
import I18n from "../../../common/I18n";

export const InsightsBilling = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      account_code: "ABC123",
      organization_id: 1234567890,
      customer_order_id: 9876543210,
      date: "25 April 11:00 AM",
      order_no: 51131,
      order_type: "Pickup",
      attempts: 1,
      configuration_charge: 500,
      fuel_surcharge: 100,
      transportation_charges: 1000,
      delivery_install: 100,
      detention_loading: 100,
      detention_unloading: 110,
      driver_install: 410,
      expedite: 995,
      extra_labor: 1000,
      hard_drive_removal: 100,
      labor_loading: 146,
      labor_unloading: 246,
      scheduling_fee: 351,
      stair_charge: 223,
      stairs: 5,
      storage: 100,
    },
    {
      key: 2,
      account_code: "XYZ456",
      organization_id: 9876543210,
      customer_order_id: 7654321098,
      date: "01 may 12:00 PM",
      order_no: 888888888,
      order_type: "Repair",
      attempts: 4,
      configuration_charge: 600,
      fuel_surcharge: 150,
      transportation_charges: 1100,
      delivery_install: 200,
      detention_loading: 200,
      detention_unloading: 220,
      driver_install: 510,
      expedite: 1995,
      extra_labor: 1100,
      hard_drive_removal: 200,
      labor_loading: 156,
      labor_unloading: 256,
      scheduling_fee: 451,
      stair_charge: 323,
      stairs: 6,
      storage: 200,
    },
  ]);
  const [columns, setColumns] = useState([
    {
      key: "account_code",
      title: "Account Code",
      dataIndex: "account_code",
    },
    {
      key: "organization_id",
      title: "Organization ID",
      dataIndex: "organization_id",
    },
    {
      key: "customer_order_id",
      title: "Customer Order ID",
      dataIndex: "customer_order_id",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "order_no",
      title: "Order No",
      dataIndex: "order_no",
    },
    {
      key: "order_type",
      title: "Order Type",
      dataIndex: "order_type",
    },
    {
      key: "attempts",
      title: "Attempts",
      dataIndex: "attempts",
    },
    {
      key: "configuration_charge",
      title: "Configuration Charge",
      dataIndex: "configuration_charge",
    },
    {
      key: "fuel_surcharge",
      title: I18n.t('driver_rates.fuel_surcharge'),
      dataIndex: "fuel_surcharge",
    },
    {
      key: "transportation_charges",
      title: "Transportation Charges",
      dataIndex: "transportation_charges",
    },
    {
      key: "delivery_install",
      title: "Delivery & Install",
      dataIndex: "delivery_install",
    },
    {
      key: "detention_loading",
      title: "Detention Loading",
      dataIndex: "detention_loading",
    },
    {
      key: "detention_unloading",
      title: "Detention Unloading",
      dataIndex: "detention_unloading",
    },
    {
      key: "driver_install",
      title: "Driver Install",
      dataIndex: "driver_install",
    },
    {
      key: "expedite",
      title: "Expedite",
      dataIndex: "expedite",
    },
    {
      key: "extra_labor",
      title: "Extra Labor",
      dataIndex: "extra_labor",
    },
    {
      key: "hard_drive_removal",
      title: "Hard Drive Removal",
      dataIndex: "hard_drive_removal",
    },
    {
      key: "labor_loading",
      title: "Labor Loading",
      dataIndex: "labor_loading",
    },
    {
      key: "labor_unloading",
      title: "Labor Unloading",
      dataIndex: "labor_unloading",
    },
    {
      key: "scheduling_fee",
      title: "Scheduling Fee",
      dataIndex: "scheduling_fee",
    },
    {
      key: "stair_charge",
      title: "Stair Charge",
      dataIndex: "stair_charge",
    },
    {
      key: "stairs",
      title: "Stairs",
      dataIndex: "stairs",
    },
    {
      key: "storage",
      title: "Storage",
      dataIndex: "storage",
    },
  ]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "InsightsBilling_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
};
