import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Spin, Row, } from '../../common/UIComponents';
import List from './SubmittedFormsList';
import FormResponse from './FormResponse';
import BaseModal from '../BaseModal';

class SubmittedForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forms: this.props.forms ? this.props.forms : [],
      inProgress: false,
      errors: [],
      showForm: false,
      formResponseId: '',
    };
  }

  handleFormDetails = (id) => {
    this.setState({
      showForm: true,
      formResponseId: id,
    });
  }

closeFormDetails = () => {
  this.setState({
    showForm: false,
  });
}

render() {
  const { forms } = this.state;
  return (
    <div>
      <Spin spinning={this.state.inProgress} delay={1000}>
        <Row>
          <Col>
            <List
              data={forms}
              handleFormDetails={this.handleFormDetails}
              pagination={{ position: 'none' }}
            />
          </Col>
        </Row>
      </Spin>
      {this.state.showForm &&
        <BaseModal
          title="Form Details"
          onCancel={() => this.closeFormDetails()}
          className="formModal"
          width='70%'
          style={{ top: 40, overflowY: 'auto' }}
        >
          <FormResponse recordId={this.state.formResponseId} />
        </BaseModal>
      }
    </div>
  );
}
}

SubmittedForms.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

SubmittedForms.defaultPropTypes = {
  forms: [],
};

export default SubmittedForms;
