/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router-dom";
import _, { concat } from "lodash";
import {
  Col,
  Row,
  Spin,
  Tooltip,
  Icon,
  DatePicker,
  Button,
  notification,
  Drawer,
  message,
  Card,
  Menu,
  Divider,
  Badge,
  Empty,
  Modal,
  Select,
  Dropdown,
  Typography,
  Checkbox
} from '../common/UIComponents'
// deleteRoutes, allocateAllDrivers, fetchRoute,
import {
  alertMessage,
  isEmpty,
  formatStopTime,
  findNumberTotal,
  randomNumber,
  formatDate,
  base64ToPdf
} from "../common/Common";
import I18n from "../common/I18n";
import {
  fetchRoutes,
  fetchRouteStops,
  completeRoute,
  deleteStopFromDispatch,
  updateRouteInMiddle,
  deleteRoutes,
  fetchUpdatedStops,
} from "../api/Routes";
import RoutesList from "../components/dispatches/RoutesList";
import StopsList from "../components/dispatches/StopsList";


// import {sendNotification} from '../api/PreplanApi'
import OrdersList from '../components/dispatches/OrdersList'
import EditOrderForm from '../components/orders/EditOrderForm'
import RoutesMap from '../components/dispatches/RoutesMap'
import AppConfig from '../config/AppConfig'
import { fetchStatus } from '../api/Dashboard'
import StopContact from '../components/stops/StopContact'
// import StopDistanceInfo from '../components/stops/StopDistanceInfo';
import refresh from "../../assets/images/refresh.png";
import truckImage from "../../assets/images/truck_white.png";
import syncRoute from "../../assets/images/sync-map-location-icon.svg";
import whiteExpandIcon from "../../assets/images/white-expand-icon.svg";
import {
  checkServiceExistance,
  drivercodeFromRoute,
  driverNameFromRoute,
  formatByTimeConfig,
  formatFullName,
  metersToOtherMeasurements,
  renderAlertMessage,
  secondsToHms,
} from "../helpers/common";
import { fetchAccessorials } from "../api/Accessorials";
import BaseModal from "../components/BaseModal";
import RearrangeStops from "../components/dispatches/RearrangeStops";
import {
  findResonObject,
  formatStopName,
  stopObjectIds,
  stopTypeFromObject,
} from "../helpers/stops";
import { contrast, formateColor } from "../common/Colors";
import Assignment from "../components/Routes/Assignment";
import DownloadContainer from "../components/dispatches/DownloadContainer";
import CompleteRouteForm from "../components/dispatches/CompleteRouteForm";
import userStore from "../stores/UserStore";
import SearchOrders from "../components/dispatches/SearchOrders";
import StopsTimeline from "../components/dispatches/StopsTimeline";
import RouteCard from "../components/dispatches/RouteCard";
import whiteArrow from "../../assets/images/WhiteArrow.png";
import TrackingScreen from "../components/dispatches/TrackingScreen";
import ItemsList from "../components/stops/ItemsList";
import ReasonWindow from "../components/common/ReasonWindow";
import { momentTime } from "../helpers/date_functions";
import { formatWeight, renderDriverNotes } from "../helpers/orders";
import NotifyDriver from "../components/common/NotifyDriver";
import { fetchLocations } from "../api/LocationsApi";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  findDependencyOrders,
  findDependencyStops,
  markMultipleOrders,
} from "../helpers/preplan";
import AddOrdersToRoute from "../components/dispatches/AddUnassignedOrdersToRoute";
import { fetchOrders } from "../api/OrdersApi";
import UnassignedCount from "../components/dispatches/UnassignedCount";
import RecoveryPlanForm from "./RecoveryPlanForm";
import RecoveryList from "../components/recoveries/RecoveryList";
import { RecoveriesApis } from "../api/RecoveriesApi";
import FormIcon from '../components/common/FormIcon'
import {sendNotificationinDispatch } from '../api/PreplanApi';
import { getAvailableDriversInfo } from "../components/dispatches/helpers";
import RouteSummary from "../components/dispatches/RouteSummary";
import WarehouseFilter from "../components/common/WarehouseFilter";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
// import { metersToOtherMeasurements, secondsToHms } from '../../../../assets/javascripts/common';
// import { mapViewMenu } from "../helpers/dispatch";
import ReleasesList from "../components/dispatches/ReleasesList";
import ReleasesPlanForm from "../components/releases/ReleasesPlanForm";
import { ReleasesApi } from "../api/ReleasesApi";
import { LineHaulApi } from "../api/LineHaulApi";
import { putNotification } from "../api/Support";
import AddStop from "../components/dispatches/AddStop";
import LineHaulList from "./LineHaul/LineHaulList";
import { UserContext } from "../context/UserContext";
import { ReflexContainer, ReflexElement, ReflexHandle, ReflexSplitter } from "react-reflex";
import ViewToggleButton from "./ViewToggleButton";
import { VehicleTypeApi } from "../api/VehicleType";
import ActionCable from "../ActionCableComponent";
import { DispatchesApi } from "../api/DispatchesApi";
import MenifestDownloadContainer from "../components/dispatches/MenifestDownloadContainer";
import { OrderConfigContext } from "../context/OrderConfigContext";
import ItemsExceptionModal from "../components/orders/ItemExceptionModal";

const { Text } = Typography;
const { confirm } = Modal;
const mapTypeOptions = {
  ALL: I18n.t("messages.show_all"),
  SELECTED: I18n.t("messages.show_selected"),
  HIDE_ALL: I18n.t("messages.hide_all"),
};

const routeTypes = [ 
  {key: "nav_route", label: 'Regular'},
  {key: "recovery_route", label: 'Recovery'},
  {key: "release_route", label: 'Release'},
  {key: "lh_route", label: 'Linehaul'},
];


const viewOptions = [ 'map', 'list', 'both' ];

const setDateFilter = (loc = {}) => {
  if (loc.dateFilter) {
    return {
      fromDate: loc.dateFilter.fromDate,
      toDate: loc.dateFilter.toDate,
    };
  }
  const dateObj =
      loc.screenName === "PREPLAN"
          ? loc.reqDate
          : loc.screenName === "DASHBOARD" || loc.routeId
              ? moment() //null
              : moment();
  return {
    fromDate: dateObj,
    toDate: dateObj,
  };
};
const setFilter = (loc = {}) => {
  const filter =
      loc.screenName === "PREPLAN" ? "ALL" : loc.filter ? loc.filter : "ALL";
  return filter;
};
const MAP_PANEL_SIZE = 0.6;
const STOPLIST_PANEL_SIZE = 0.4;
class DispatchesComponent extends Component {
  // static contextType = OrganizationSettingsContext
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      inProgress: false,
      stops: [],
      routeStatus: "",
      routeName: "",
      currentStop: {},
      orderInfo: [],
      currentOrder: {},
      orderObject: {},
      routeStatusInfo: {},
      selectedRouteObject: {},
      filter: setFilter(props.location),
      reqFrom: {
        screenName: !isEmpty(props.location.screenName)
            ? props.location.screenName
            : "",
        relatedId: !isEmpty(props.location.relatedId)
            ? props.location.relatedId
            : "",
        reqDate: !isEmpty(props.location.reqDate)
            ? props.location.reqDate
            : null,
      },
      dateFilter: setDateFilter(props.location),
      selectedRoute:
          !isEmpty(props.location) && !isEmpty(props.location.routeId)
              ? props.location.routeId
              : "",
      accessorials: [],
      rearrangeForm: false,
      stopsInProgress: false,
      assignModal: false,
      downloadContainer: false,
      orgId: userStore.getStateValue('selectedOrg'),
      showReasonForm: false,
      route_completetion_reason: '',
      currentDriver: {},
      searchOrders: false,
      reasonObject: {
        source: "",
        sourceId: "",
        ids: [],
        show: false,
        reason: "",
      },
      editForm: false,
      warehouses: [],
      assignInfo:[],
      routeInfo:[],
      warehouse_id: '',
      // warehouse_id: "",
      total_routes: [],
      adSort: true,
      // filterableWarehouses: [],
      statsProgress: false,
      completeScreen: "",
      mapViewType: "SELECTED",
      routeKey: randomNumber(),
      routeSelectedStops: [],
      moveProgress: false,
      organizationSettings: {},
      displayConfiguration: {},
      unassignedOrders: [],
      unassignedOrdersInfo: {
        selectedKeys: [],
        selectedRows: [],
      },
      refreshUnallocated: false,
      totalUnassignedCount: 0,
      showRouteAssigner: false,
      availableDriversInfo: [],
      ordersProgress: false,
      betaOrderProgress: false,
      showRouteSummary: false,
      currentRouteSummary: {},
      isMilitaryTime: false,
      panelSize: {
        pane_1: MAP_PANEL_SIZE,
        pane_2: STOPLIST_PANEL_SIZE
      },
      showUnassigned : false,
      currentView: 'both',
      vehicleTypes: [],
      refreshSearchOrders: false,
      routeToReload: null,
      includeBol: false,
      includeManifest: true,
      includeItems: false,
      isUsingBetaVersion: false,
      showItemExceptionModal: false,
      currentRecord: {},
    };
  }
  componentDidMount() {
    const { selectedWarehouses } = this.props.warehouseFilter;
    const selectedwhId =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;

    this.getRoutes(
        this.props.location.filter
            ? this.props.location.filter
            : this.state.filter
    );
    this.getStatus();
    this.getAccessorials();
    this.getVehicleTypes();
    // this.getWarehouses()
    // if location having routes-beta 
    // const isUsingBetaVersion = this.props.location.pathname.includes('routes-beta');
    this.setState({
      warehouse_id: selectedwhId,
      organizationSettings: this.props.organizationSettings,
      displayConfiguration: this.props.displayConfiguration,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
      // isUsingBetaVersion,
    });
    this.getOrders();
    // this.setFilterableWhs();
    if (_.isObject(this.props.warehouseFilter)) {
      this.setRoutesFilter(
          "warehouse_id",
          this.props.warehouseFilter.selectedWarehouses
      );
    }
  }

  componentDidUpdate(prevProps,prevState) {
    const { selectedWarehouses } = this.props.warehouseFilter;
    const selectedwhId =
      _.isArray(selectedWarehouses) && selectedWarehouses.length > 0
        ? selectedWarehouses[0]
        : selectedWarehouses;

    if (
        !_.isEqual(
            prevProps.warehouseFilter.selectedWarehouses,
            this.props.warehouseFilter.selectedWarehouses
        ) ||
        (!_.isEqual(
                prevProps.warehouseFilter.warehouses,
                this.props.warehouseFilter.warehouses
            ) &&
            !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.setRoutesFilter(
          "warehouse_id",
          this.props.warehouseFilter.selectedWarehouses
      );
      this.setState({ warehouse_id: selectedwhId });
    }
    if (
        !_.isEqual(
            prevState.displayConfiguration,
            this.props.displayConfiguration
        )
    )

    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState(
        {
          displayConfiguration: this.props.displayConfiguration,
          organizationSettings: this.props.organizationSettings,
          isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
        },
        () => {
          // Update OrdersList props with updated state values
          this.updateOrdersListProps();
        }
      );
    }
  }

  updateOrdersListProps = () => {
    this.setState({
      ordersListProps: {
        displayConfiguration: this.state.displayConfiguration,
      },
    });
  };

  fetchavailableDriversInfo = () => {
    const startDate = this.state.dateFilter.fromDate;
    const endDate = this.state.dateFilter.toDate;
    const startTime = this.props.organizationSettings.route_delivery_start_time;
    const endTime = this.props.organizationSettings.route_delivery_end_time;
    getAvailableDriversInfo(startDate, endDate, startTime, endTime).then(
        (res) => {
          this.setState({ availableDriversInfo: res.drivers });
        }
    );
  };

  getOrders = (screenType) => {
    const { dateFilter, warehouse_id } = this.state;

    const page = 1;
    const perPage = "ALL";
    const orderNumber = "";
    const formattedFromDate = formatDate(dateFilter.fromDate);
    const formattedToDate = formatDate(dateFilter.toDate);
    const searchKeyType = "";
    const searchKeyValue = "";
    const scheduleOrdersType = I18n.t(
        "order.filters.schedule_orders.scheduled_key"
    );
    const zone_ids = [];
    const globalSearch = true;
    if (screenType == "betaOrderProgress") {
      this.setState({
        betaOrderProgress: true,
      });
    } else if (screenType == "ordersProgress") {
      this.setState({
        ordersProgress: true,
      });
    } else {
      this.setState({
        betaOrderProgress: true,
        ordersProgress: true,
      });
    }
    fetchOrders({
      status: "PREPLAN_ORDERS",
      fromDate: formattedFromDate,
      todate: formattedToDate,
      page,
      perPage,
      orderNumber,
      sortBy: "none",
      sortByType: "descend",
      accountCode: "",
      warehouseId: warehouse_id,
      searchKeyType,
      searchKeyValue,
      scheduleOrdersType,
      globalSearch,
      zone_ids,
      stats_only : true,
      search_type: 'EQUALS',
    })
        .then((result) => {
          if (result.success) {
            const { stats } = result.orders;
            this.setState({
              totalUnassignedCount: ((stats.VERIFIED || 0) + (stats.EXCEPTION || 0)),
            });
          }
          if (result.errors) {
            this.setState({
              totalUnassignedCount: 0,
            });
          }
        })
        .catch((errors) => {
          alertMessage(errors[0], "error");
          this.setState({
            totalUnassignedCount: 0,
          });
        })
        .finally(() => {
          this.setState({
            betaOrderProgress: false,
            ordersProgress: false,
          });
        });
  };

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
			   vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };


  // this.timer = setInterval(() => {
  //   if(!this.state.searchOrders && this.state.selectedRouteObject && this.state.selectedRouteObject.id){
  //     this.getRouteStopsInfo(this.state.selectedRouteObject.id)
  //   }
  // }, AppConfig.fetchRefreshTime);

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  // getWarehouses = () => {
  //   fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
  //     if (result.success) {
  //       this.setState({
  //         warehouses: result.locations.locations,
  //       });
  //     }
  //   });
  // };

  handleStateChange = (key, value) => {
    if (key === 'includeItems') {
      if (value) {
        this.setState({
          includeItems: value,
          includeManifest: true
        });
      } else {
        this.setState({
          includeItems: value,
        });
      }
    } else if (key === 'includeManifest') {
      if (value) {
        this.setState({
          includeManifest: true
        });
      } else {
        this.setState({
          includeManifest: value,
          includeItems: false,
        });
      }
    } else {
      this.setState({
        [key]: value
      });
    }
  };


  handleRouteDeleteClick = (id,route_type) => {
    this.setState({ inProgress: true });
    const routeTypeDeletion = route_type == "release_route" ? ReleasesApi.deleteReleaseRoute : route_type === "lh_route" ? LineHaulApi.deleteRoute : RecoveriesApis.deleteRecoveryRoute;
    routeTypeDeletion(id).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false });
        this.refreshRoutes();
        alertMessage(I18n.t("messages.deleted"), "success", 10);
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: true });
      }
    });
  };


  getAccessorials = () => {
    this.setState({ inProgress: true, currentAccessorial: {} });
    fetchAccessorials(this.state.orgId).then((result) => {
      const accessorials = result.accessorials || [];
      if (result.success) {
        this.setState(
            {
              accessorials: accessorials.map((accessorial) =>
                  Object.assign({}, accessorial, {
                    org_accessorial_id: accessorial.id,
                  })
              ),
              inProgress: false,
            },
            () => {}
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  mapViewMenu = () => (
      <Menu>
        <Menu.Item onClick={() => this.handleStateChange("mapViewType", "ALL")}>
          {this.state.mapViewType === "ALL" && <Icon type="check" />}&nbsp; Show
          All
        </Menu.Item>
        <Menu.Item
            onClick={() => this.handleStateChange("mapViewType", "SELECTED")}
        >
          {this.state.mapViewType === "SELECTED" && <Icon type="check" />}
          &nbsp;Show Selected
        </Menu.Item>
        <Menu.Item
            onClick={() => this.handleStateChange("mapViewType", "HIDE_ALL")}
        >
          {this.state.mapViewType === "HIDE_ALL" && <Icon type="check" />}
          &nbsp;Hide All
        </Menu.Item>
      </Menu>
  );

  getRoutes = (filter, cb = null, skipWarehouseinFilter = false) => {
    const selectedWH = !skipWarehouseinFilter
        ? !isEmpty(this.state.warehouse_id)
            ? this.state.warehouse_id
            : ""
        : "";
    this.setState({
      inProgress: true,
      // selectedRoute: "",
      stops: [],
      currentStop: {},
      selectedRouteObject: {},
    });
    fetchRoutes(
        filter === "ALL" ? "" : filter,
        this.state.dateFilter,
        "DISPATCH",
        selectedWH
    ).then((result) => {
      if (result.nav_routes && result.nav_routes.nav_routes.length > 0) {
        const total_nav_routes = result.nav_routes.nav_routes;
        const total_routes = total_nav_routes.map((x, routeIndex) => {
          const navRoute = x.nav_route || {};
          const formattedName = driverNameFromRoute(navRoute, "string");
          const formattedCode = drivercodeFromRoute(navRoute, "string");
          const bgColor = formateColor(navRoute.color_code);
          const fgColor = contrast(bgColor);
          return Object.assign({}, navRoute, {
            driver: x.driver,
            bgColor,
            fgColor,
            status: navRoute.r_status,
            displayName: formattedCode || formattedName,
          });
        });
        this.setState(
            {
              total_routes,
              inProgress: false,
            },
            () => {
              this.handleFiltering(false, cb);
            }
        );
      } else {
        if (result.errors) renderAlertMessage(result.errors)
        this.setState(
            {
              inProgress: false,
              routes: [],
              stops: [],
              currentStop: {},
              selectedRouteObject: {},
              selectedRoute: "",
              refreshUnallocated: true,
            },
            () => {
              this.resetUnassignedInfo();
            }
        );
      }
    });
  };

  refreshOneRoute = (cb = null, routeId = null) => {
    this.setState({
      routeToReload: routeId,
    });
    const selectedWH = !isEmpty(this.state.warehouse_id)
        ? this.state.warehouse_id
        : "";
    const requiredRouteId = routeId ? routeId : this.state.selectedRoute;
    fetchRoutes(
        this.state.filter === "ALL" ? "" : this.state.filter,
        this.state.dateFilter,
        "DISPATCH",
        selectedWH,
        requiredRouteId
    ).then((result) => {
      if (result.nav_routes && result.nav_routes.nav_routes.length > 0) {
        const total_routes = result.nav_routes.nav_routes || [];
        const newRoutes = total_routes.map((x, routeIndex) => {
          const navRoute = x.nav_route || {};
          const formattedName = driverNameFromRoute(navRoute, "string");
          const formattedCode = drivercodeFromRoute(navRoute, "string");
          const bgColor = formateColor(navRoute.color_code);
          const fgColor = contrast(bgColor);
          return Object.assign({}, navRoute, {
            driver: x.driver,
            bgColor,
            fgColor,
            status: navRoute.r_status,
            displayName: formattedCode || formattedName,
          });
        });
        const newRouteIndex = _.findIndex(newRoutes, [
          "id",
          requiredRouteId, 
        ]);
        if (newRouteIndex >= 0) {
          const newRoute = Object.assign({}, newRoutes[newRouteIndex]);
          const routes = [...this.state.routes];
          const existRouteIndex = _.findIndex(routes, [
            "id",
            requiredRouteId, 
          ]);
          if (existRouteIndex >= 0) {
            routes[existRouteIndex] = newRoute;
          }
          this.setState(
              {
                routes,
                routeToReload: null,
                inProgress: false
              },
              cb
          );
        }
      } else {
        if (result.errors) renderAlertMessage(result.errors)
        this.setState({
          routeToReload: null,
          inProgress: false,
          routes: [],
          stops: [],
          currentStop: {},
          selectedRouteObject: {},
          selectedRoute: "",
        });
      }
    });
  };

  getStatus = () => {
    this.setState({ statsProgress: true });
    fetchStatus(this.state.dateFilter, "DISPATCH", this.state.route_type).then(
        (result) => {
          if (result.success) {
            this.setState({
              routeStatusInfo: result.data[0],
              statsProgress: false,
            });
          } else {
            renderAlertMessage(result.errors)
            this.setState({ statsProgress: false });
          }
        }
    );
  };

  formatStopName = (contact_details, location, stop_number) => {
    const name =
        contact_details && contact_details.contact_name
            ? contact_details.contact_name
            : `Stop ${stop_number}`;
    let city_state =
        location && location.l_address
            ? `${location.l_address.city}, ${location.l_address.state}`
            : "";
    if (city_state) {
      city_state = city_state.replace(/(^,)|(,$)/g, "");
      city_state = ` (${city_state})`;
    }
    return name + city_state;
  };

  handleEditOrderClick = (id) => {
    const orderInfo =
        this.state.selectedRouteObject &&
        !_.isEmpty(this.state.selectedRouteObject.customer_orders)
            ? this.state.selectedRouteObject.customer_orders
            : this.state.selectedRouteObject.linehauls;
    const index = _.findIndex(orderInfo, ["id", id]);
    const currentOrder = Object.assign({}, orderInfo[index]);
    this.setState(
        {
          // result.orders.customer_order
          currentOrder,
          editForm: true,
        },
        () => {}
    );
  };


  getRouteStopsInfo = (id, stopId = "", cb) => {
    this.setState(
      {
        stopsInProgress: true,
        currentStop: {},
        stops: [],
        selectedRouteObject: {},
        routeSelectedStops: [],
      },
      () => {
        fetchRouteStops(id)
          .then((result) => {
            if (result.success) {
              const route_stops = [];
              const routes = [...this.state.routes];
              const routeIndex = _.findIndex(routes, ["id", id]);
              let currentRoute = {};
              if (routeIndex >= 0) {
                currentRoute = routes[routeIndex];
              }
              if( result?.stops &&  result.stops.stops){
                result.stops.stops.forEach((route_stop) => {
                  route_stops.push(route_stop.stop_details);
                });
              }
              
              const sortedStops = _.sortBy(route_stops, [
                function (o) {
                  return o.stop_order_sequence;
                },
              ]);
              if (sortedStops.length > 0) {
                sortedStops[sortedStops.length - 1].completion_class =
                  "inCompletedRoute";
              }
              const { interconnections } = result.stops;
              sortedStops.forEach((stop, index) => {
                // to update map`s stop status
                const routeStopIndex = _.findIndex(currentRoute.nav_stops, [
                  "stop_id",
                  stop.id,
                ]);
                if (routeStopIndex >= 0) {
                  currentRoute.nav_stops[routeStopIndex].status = stop.status;
                }
                // end to update map`s stop status
                if (index > 0) {
                  if (stop.status === "COMPLETED") {
                    sortedStops[index - 1].completion_class = "completedRoute";
                  } else {
                    sortedStops[index - 1].completion_class =
                      "inCompletedRoute";
                  }
                }
                sortedStops[index].stop_number = index + 1;
                const contact_details = stop.contact_details
                  ? stop.contact_details
                  : {};
                sortedStops[index].formatted_stop_name = this.formatStopName(
                  contact_details,
                  stop.location,
                  index + 1
                );
                sortedStops[index].address =
                  stop.location && stop.location.l_address
                    ? stop.location.l_address
                    : {};
                const interconnection_index = _.findIndex(interconnections, [
                  "fromWaypoint",
                  stop.id,
                ]);
                if (interconnection_index >= 0) {
                  sortedStops[index].interconnection_status = true;
                  sortedStops[index].distance = interconnections[
                    interconnection_index
                  ].distance
                    ? metersToOtherMeasurements(
                        interconnections[interconnection_index].distance,
                        "miles"
                      )
                    : "NA";
                  sortedStops[index].time = interconnections[
                    interconnection_index
                  ].time
                    ? secondsToHms(
                        interconnections[interconnection_index].time,
                        true,
                        true,
                        false
                      )
                    : 0;
                  sortedStops[index].waiting = interconnections[
                    interconnection_index
                  ].waiting
                    ? secondsToHms(
                        interconnections[interconnection_index].waiting,
                        true,
                        true,
                        false
                      )
                    : "-";
                  sortedStops[index].rest = interconnections[
                    interconnection_index
                  ].rest
                    ? secondsToHms(
                        interconnections[interconnection_index].rest,
                        true,
                        true,
                        false
                      )
                    : "-";
                } else {
                  sortedStops[index].distance = "NA";
                  sortedStops[index].time = "NA";
                  sortedStops[index].waiting = "NA";
                  sortedStops[index].rest = "NA";
                  sortedStops[index].interconnection_status = false;
                }
              });
              let currentDriver = {};
              if (result.stops && result.stops.driver_last_location) {
                currentDriver = result.stops.driver_last_location;
              }
              currentDriver.name = currentRoute.displayName;
              currentDriver.route_status = currentRoute.r_status;
              currentDriver.timeZoneId = currentRoute.timeZoneId;
              if (routeIndex >= 0) {
                routes[routeIndex] = currentRoute;
              }
              const currentLength =
                routeIndex >= 0 ? routeIndex : routes.length + 1;
              const bgColor = !isEmpty(currentRoute.bgColor)
                ? currentRoute.bgColor
                : formateColor(result.stops.color_code);
              const fgColor = contrast(bgColor);
              const selectedRouteObject = {
                id,
                bgColor,
                fgColor,
                displayName: currentRoute.displayName,
                primary_driver: currentRoute.primary_driver_name,
                secondary_driver: currentRoute.secondary_driver_name,
                r_terminating_reason: result.r_terminating_reason,
                r_terminated_user_name: result.r_terminated_user_name,
                optimized: currentRoute.optimized,
                optimizer_readable_error_message:
                  currentRoute.optimizer_readable_error_message || [],
                route_start_time: currentRoute.route_start_time,
                timeZoneId: currentRoute.timeZoneId,
                route_type: currentRoute.route_type,
                ...result.stops,
                recoveries:
                  result.stops.recoveries && _.isArray(result.stops.recoveries)
                    ? result.stops.recoveries
                    : [],
                releases:
                    result.stops.releases && _.isArray(result.stops.releases)
                      ? result.stops.releases
                      : [],
                linehauls:
                    result.stops.linehauls && _.isArray(result.stops.linehauls)
                      ? result.stops.linehauls
                      : [],
                nav_route_status: currentRoute.r_status
              };
              const customerOrders =
                result.stops.customer_orders &&
                _.isArray(result.stops.customer_orders)
                  ? result.stops.customer_orders
                  : [];
              selectedRouteObject.customer_orders = customerOrders.map(
                (order) => ({ ...order, id: order.customer_order_id })
              );
              const { linehauls, releases, recoveries, customer_orders } = result.stops;
              const ordersToCalculate = [ ...linehauls, ...releases, ...recoveries , ...customer_orders];
              selectedRouteObject.total_weight = findNumberTotal(
                ordersToCalculate,
                "weight",
                null,
                0
              );
              let currentStop = {};
              if (!isEmpty(stopId)) {
                const stopIndex = _.findIndex(sortedStops, { id: stopId });
                if (stopIndex >= 0) {
                  currentStop = sortedStops[stopIndex];
                } else if (sortedStops.length > 0) {
                  currentStop = sortedStops[0];
                }
              } else if (sortedStops.length > 0) {
                currentStop = sortedStops[0];
              }
              this.setState(
                {
                  routes,
                  stopsInProgress: false,
                  stops: sortedStops,
                  currentStop: {},
                  routeStatus: result.stops.nav_route_status,
                  routeName: result.stops.nav_route_name,
                  selectedRouteObject,
                  selectedRoute: id,
                  currentDriver,
                },
                () => {
                  this.setCurrentStop(currentStop.id);
                }
              );
            } else {
              this.setState({
                stopsInProgress: false,
                selectedRouteObject: {},
              });
              renderAlertMessage(result.errors)
            }
          })
          .finally(() => {
            if (cb) {
              cb();
            }
          });
      }
    );
  };

  updateStopsInfo = (routeId, stopIds = [], cb = null) => {
    this.setState(
      {
        stopsToRefresh: stopIds,
      },
      () => {
        fetchUpdatedStops(routeId, stopIds)
          .then((result) => {
            if (result.success) {
              const sortedStops = [];
              const routes = [...this.state.routes];
              const stops = [...this.state.stops];
              const routeIndex = _.findIndex(routes, ["id", routeId]);
              let currentRoute = {};
              if (routeIndex >= 0) {
                currentRoute = routes[routeIndex];
              }
              if( result?.stops &&  result.stops.stops){
                result.stops.stops.forEach((route_stop) => {
                  sortedStops.push(route_stop.stop_details);
                });
              }
              
             
              if (sortedStops.length > 0) {
                sortedStops[sortedStops.length - 1].completion_class =
                  "inCompletedRoute";
              }
              const { interconnections } = result.stops;
              sortedStops.forEach((stop, index) => {
                // to update map`s stop status
                const routeStopIndex = _.findIndex(currentRoute.nav_stops, [
                  "stop_id",
                  stop.id,
                ]);
                const stopIndex = _.findIndex(stops, ["id", stop.id]);
                if (routeStopIndex >= 0) {
                  currentRoute.nav_stops[routeStopIndex].status = stop.status;
                }
                // end to update map`s stop status
                if (index > 0) {
                  if (stop.status === "COMPLETED") {
                    sortedStops[index - 1].completion_class = "completedRoute";
                  } else {
                    sortedStops[index - 1].completion_class =
                      "inCompletedRoute";
                  }
                }
                sortedStops[index].stop_number = stopIndex + 1;
                const contact_details = stop.contact_details
                  ? stop.contact_details
                  : {};
                sortedStops[index].formatted_stop_name = this.formatStopName(
                  contact_details,
                  stop.location,
                  index + 1
                );
                sortedStops[index].address =
                  stop.location && stop.location.l_address
                    ? stop.location.l_address
                    : {};
                stops[stopIndex]=sortedStops[index];
                // const interconnection_index = _.findIndex(interconnections, [
                //   "fromWaypoint",
                //   stop.id,
                // ]);
                // if (interconnection_index >= 0) {
                //   sortedStops[index].interconnection_status = true;
                //   sortedStops[index].distance = interconnections[
                //     interconnection_index
                //   ].distance
                //     ? metersToOtherMeasurements(
                //         interconnections[interconnection_index].distance,
                //         "miles"
                //       )
                //     : "NA";
                //   sortedStops[index].time = interconnections[
                //     interconnection_index
                //   ].time
                //     ? secondsToHms(
                //         interconnections[interconnection_index].time,
                //         true,
                //         true,
                //         false
                //       )
                //     : 0;
                //   sortedStops[index].waiting = interconnections[
                //     interconnection_index
                //   ].waiting
                //     ? secondsToHms(
                //         interconnections[interconnection_index].waiting,
                //         true,
                //         true,
                //         false
                //       )
                //     : "-";
                //   sortedStops[index].rest = interconnections[
                //     interconnection_index
                //   ].rest
                //     ? secondsToHms(
                //         interconnections[interconnection_index].rest,
                //         true,
                //         true,
                //         false
                //       )
                //     : "-";
                // } else {
                //   sortedStops[index].distance = "NA";
                //   sortedStops[index].time = "NA";
                //   sortedStops[index].waiting = "NA";
                //   sortedStops[index].rest = "NA";
                //   sortedStops[index].interconnection_status = false;
                // }
              });
              let currentDriver = {};
              if (result.stops && result.stops.driver_last_location) {
                currentDriver = result.stops.driver_last_location;
              }
              currentDriver.name = currentRoute.displayName;
              currentDriver.route_status = currentRoute.r_status;
              currentDriver.timeZoneId = currentRoute.timeZoneId;
              if (routeIndex >= 0) {
                routes[routeIndex] = currentRoute;
              }
              const bgColor = !isEmpty(currentRoute.bgColor)
                ? currentRoute.bgColor
                : formateColor(result.stops.color_code);
              const fgColor = contrast(bgColor);
              const selectedRouteObject = {
                id: routeId,
                bgColor,
                fgColor,
                displayName: currentRoute.displayName,
                primary_driver: currentRoute.primary_driver_name,
                secondary_driver: currentRoute.secondary_driver_name,
                r_terminating_reason: result.r_terminating_reason,
                r_terminated_user_name: result.r_terminated_user_name,
                optimized: currentRoute.optimized,
                optimizer_readable_error_message:
                  currentRoute.optimizer_readable_error_message || [],
                route_start_time: currentRoute.route_start_time,
                timeZoneId: currentRoute.timeZoneId,
                route_type: currentRoute.route_type,
                ...result.stops,
                recoveries:
                  result.stops.recoveries && _.isArray(result.stops.recoveries)
                    ? result.stops.recoveries
                    : [],
                releases:
                    result.stops.releases && _.isArray(result.stops.releases)
                      ? result.stops.releases
                      : [],
                linehauls:
                    result.stops.linehauls && _.isArray(result.stops.linehauls)
                      ? result.stops.linehauls
                      : [],
              };
              const customerOrders =
                result.stops.customer_orders &&
                _.isArray(result.stops.customer_orders)
                  ? result.stops.customer_orders
                  : [];
              selectedRouteObject.customer_orders = customerOrders.map(
                (order) => ({ ...order, id: order.customer_order_id })
              );
              const { linehauls, releases, recoveries, customer_orders } = result.stops;
              const ordersToCalculate = [ ...linehauls, ...releases, ...recoveries , ...customer_orders];
              selectedRouteObject.total_weight = findNumberTotal(
                ordersToCalculate,
                "weight",
                null,
                0
              );
              let currentStop = {};
              if (stops.length > 0) {
                currentStop = stops[0];
              }
              this.setState(
                {
                  routes,
                  stopsToRefresh: [],
                  stops,
                  currentStop: {},
                  routeStatus: result.stops.nav_route_status,
                  routeName: result.stops.nav_route_name,
                  //selectedRouteObject,
                  selectedRoute: routeId,
                  currentDriver,
                },
                () => {
                  this.setCurrentStop(currentStop.id);
                }
              );
            } else {
              this.setState({
                stopsInProgress: false,
                selectedRouteObject: {},
              });
              renderAlertMessage(result.errors)
            }
          })
          .finally(() => {
            if (cb) {
              cb();
            }
          });
      }
    );
  };


  handleSort = (adSort) => {
    this.setState(
        {
          adSort,
        },
        () => {
          this.handleFiltering(true);
        }
    );
  };

  handleFiltering = (isClickonSortIcon, cb) => {
    // const total_routes = this.state.total_routes;
    const {
      total_routes,
      warehouse_id: WHid,
      adSort,
      selectedRoute,
      dateFilter,
      route_type,
    } = this.state;

    let filteredRoutes = !isEmpty(WHid)
        ? total_routes.filter(({ warehouse_id }) => {
          return warehouse_id === WHid;
        })
        : total_routes;
    if (!isEmpty(route_type)) {
      filteredRoutes = filteredRoutes.filter((route) => {
        return route.route_type === route_type;
      });
    }

    let SortedRoutes = _.sortBy(filteredRoutes, [
      (o) => {
        return o.displayName;
      },
    ]);
    if (!adSort) {
      SortedRoutes = SortedRoutes.reverse();
    }
    this.setState(
        {
          stops: [],
          currentStop: {},
          selectedRouteObject: {},
          // selectedRoute: "",
          routes: SortedRoutes,
        },
        () => {
          if (cb) {
            cb();
          }
          if (this.state.routes.length > 0) {
            if (
                !isClickonSortIcon &&
                !isEmpty(selectedRoute) &&
                _.findIndex(this.state.routes, ["id", selectedRoute]) >= 0
            ) {
              this.getRouteStopsInfo(selectedRoute);
            } else {
              this.getRouteStopsInfo(this.state.routes[0].id);
            }
          }
        }
    );
  };

  handleSelectedRoutes = (element, value) => {
    this.setState(
        {
          [element]: value,
        },
        () => {
          if (element === "selectedRoute" && value === "") {
            this.setState({
              stops: [],
              currentStop: {},
              selectedRouteObject: {},
              selectedRoute: "",
            });
          }
        }
    );
  };

  renderWarehouse = () => (
    <WarehouseFilter
      isDisabled={false}
      style={{ width: "100%", marginLeft: "1%" }}
    />
  );

  renderStatusFilter = () => {
    const { filter, statsProgress, routeStatusInfo } = this.state;
    return (
      <Select
        value={ filter }
        style={ { width: 150 } }
        size="small"
        dropdownMatchSelectWidth={ false }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={ statsProgress }
        onChange={ (e) => this.setRoutesFilter("filter", e) }
      >
        { AppConfig.routeStatus.map((status) => (
          <Select.Option value={ status.key } key={ status.key }>
            { status.label } 
            <Badge
              count={ routeStatusInfo[ status.backend_count_key ] }
              style={ {
                backgroundColor: status.backgroundColor,
                color: "#fff",
                fontSize: '1.5em',
                marginLeft: '10px'
              } }
            />
          </Select.Option>
        )) }
      </Select>
    );
  };


  renderDateFilter = () => (
    <DatePicker
      // style={{ width: "78%" }}
      placeholder="Select Date"
      format="Do MMM YYYY"
      onChange={(e) => this.setRoutesFilter("fromDate", e)}
      value={
        this.state.dateFilter.fromDate
          ? moment(new Date(this.state.dateFilter.fromDate))
          : null
      }
      // value={ moment(new Date('2022-04-14')) } // for testing
      defaultValue={null}
      size="small"
      allowClear={false}
      disabled={this.state.inProgress}
    />
  );

  navigatePreplanScreen = () => {
    this.props.history.push(`/preplan/${this.state.reqFrom.relatedId}`);
  };

  _toggleRouteAssigner = (visiblity = !this.state.showRouteAssigner) => {
    this.setState({ showRouteAssigner: visiblity });
  };

  _handleViewToggle = (newView) => {
    if (newView === "map") {
      this.setState({
        panelSize: {
          pane_1: 1,
          pane_2: 0
        }
      });
    } else if (newView === "list") {
      this.setState({
        panelSize: {
          pane_1: 0,
          pane_2: 1
        }
      });
    } else {
      this.setState({
        panelSize: {
          pane_1: MAP_PANEL_SIZE,
          pane_2: STOPLIST_PANEL_SIZE
        }
      });
    }
  };

  _renderHeader = () => {
    const { warehouse_id, dateFilter, showUnassigned, refreshUnallocated, currentView } = this.state;
    const startTime = dateFilter && dateFilter.fromDate
      ? moment(dateFilter.fromDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    const endTime = startTime;
    return (
      <Row className="page-header" justify="space-between" type="flex">
        <Col>
          { this.state.reqFrom.screenName === "PREPLAN" && !isEmpty(this.state.reqFrom.relatedId) && (
            <img
              src={ whiteArrow }
              alt="whitearrow"
              style={ {
                height: 20,
                width: 30,
                cursor: "pointer",
                marginTop: -2,
              } }
              onClick={ this.navigatePreplanScreen }
            />
          ) }
         
           {I18n.t("menu.routes")}
        </Col>
        <Col>
          <Row type="flex" align="middle" gutter={ 16 }>
            <Col >
              <Row type="flex" gutter={ 4 } align="middle" style={ {
                marginRight: 10,
                fontSize: 14,
              } }>
                <Col>
                  <Button
                    type="default"
                    size="small"
                    icon="shopping-cart"
                    onClick={ () => this.changesOrdersState(false,true) }
                    className="textBold marginLeft20"
                    loading={ this.state.betaOrderProgress }
                    disabled={ this.state.betaOrderProgress }
                  >

                    {this.state.isUsingBetaVersion
                      ? `Hide ${I18n.t("order.unallocated")}`
                      : `${I18n.t("order.unallocated")}: ${this.state.totalUnassignedCount}`}
                  </Button>
                </Col>
                <Col>
                  {!this.state.betaOrderProgress && (
                    <Tooltip placement="top" title="Full Screen">
                      <img
                        src={whiteExpandIcon}
                        style={{ color: "#fff", width: 16, marginTop: -2 }}
                        onClick={() => {
                          this.resetUnassignedInfo();
                          this.getOrders();
                          this.setState(
                            {
                              showUnassigned: false,
                            },
                            () => {
                              this.setState({
                                showUnassigned: true,
                                searchOrders: false,
                                showRouteAssigner: true,
                                isUsingBetaVersion: true,
                              });
                            }
                          );
                        }}
                      />
                    </Tooltip>
                  )}
                </Col>
                {/* <Col>
                  <Button
                    type="default"
                    size="small"
                    icon="shopping-cart"
                    onClick={() => this.changesOrdersState(false,false)}
                    className="textBold marginLeft20"
                    loading={this.state.ordersProgress}
                    disabled={this.state.ordersProgress}
                  >
                    { showUnassigned
                      ? `Hide ${I18n.t("order.unallocated")}`
                      : `${I18n.t("order.unallocated")}: ${this.state.totalUnassignedCount}` }
                  </Button>
                </Col> */}
                {/* <Col>
                  { !this.state.ordersProgress && (
                    <Tooltip placement="top" title="Full Screen">
                      <img
                        src={ whiteExpandIcon }
                        style={ { color: "#fff", width: 16, marginTop: -2 } }
                        onClick={ () => {
                          this.resetUnassignedInfo();
                          this.getOrders();
                          this.setState(
                            {
                              showUnassigned: false,
                            },
                            () => {
                              this.setState({
                                showUnassigned: true,
                                searchOrders: false,
                                showRouteAssigner: true,
                                isUsingBetaVersion: false,
                              });
                            }
                          );
                        } }
                      />
                    </Tooltip>
                  ) }
                </Col> */}
              </Row>
            </Col>
            {/* <Col>
              <Row type="flex" justify="space-between" align="middle" gutter={ 4 }>
                <Col>{ "Route Type:" }</Col>
                <Col>{ this.renderRouteTypes() }</Col>
              </Row>
            </Col> */}
            <Col>
              <Row type="flex" justify="space-between" align="middle" gutter={ 4 }>
                <Col className="fontSize16">{ I18n.t("general.date") }:</Col>
                <Col>{ this.renderDateFilter() }</Col>
              </Row>
            </Col>
            <Col>
              <Row type="flex" justify="space-between" align="middle" gutter={ 4 }>
                <Col className="fontSize16">{ I18n.t("general.status") }:</Col>
                <Col>{ this.renderStatusFilter() }</Col>
              </Row>
            </Col>


            <Col>
              <ViewToggleButton
                initialView={ currentView }
                onViewToggle={ this._handleViewToggle }
                viewOptions={ viewOptions }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  _renderRouteStats = () => (
    <Row className="marginBottom20">
      <Col xs={2} />
      <Col xs={4} className="cursorPointer">
        <div
          onClick={() =>
            this.setRoutesFilter("filter", `${I18n.t("routes.all_cap")}`)
          }
          style={{
            width: "10rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="alignLeft">{`${I18n.t("routes.all")}`}</span>
          <Badge

            count={this.state.routeStatusInfo.total_routes_count}
            style={{ backgroundColor: "rgb(54, 178, 238)", color: "#fff" }}
          />
        </div>
      </Col>
      <Col xs={4} className="cursorPointer">
        <div
          onClick={() =>
            this.setRoutesFilter("filter", `${I18n.t("routes.assigned_cap")}`)
          }
          style={{
            width: "10rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="alignLeft">{`${I18n.t("routes.assigned")}`}</span>
          <Badge

            count={this.state.routeStatusInfo.assigned_count}
            style={{ backgroundColor: "rgb(214, 118, 96)", color: "#fff" }}
          />
        </div>
      </Col>
      <Col xs={4} className="cursorPointer">
        <div
          onClick={() =>
            this.setRoutesFilter("filter", `${I18n.t("routes.prepare_cap")}`)
          }
          style={{
            width: "10rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="alignLeft">{`${I18n.t("routes.prepare")}`}</span>
          <Badge

            count={this.state.routeStatusInfo.prepare_count}
            style={{ backgroundColor: "rgb(96, 122, 214)", color: "#fff" }}
          />
        </div>
      </Col>
      <Col xs={4} className="cursorPointer">
        <div
          onClick={() =>
            this.setRoutesFilter("filter", `${I18n.t("routes.dispatched_cap")}`)
          }
          style={{
            width: "10rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="alignLeft">{`${I18n.t("routes.dispatched")}`}</span>
          <Badge

            count={this.state.routeStatusInfo.dispatched_count}
            style={{ backgroundColor: "rgb(96, 214, 181)", color: "#fff" }}
          />
        </div>
      </Col>
      <Col xs={4} className="cursorPointer">
        <div
          onClick={() =>
            this.setRoutesFilter("filter", `${I18n.t("routes.completed_cap")}`)
          }
          style={{
            width: "10rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="alignLeft">{`${I18n.t("routes.completed")}`}</span>
          <Badge

            count={this.state.routeStatusInfo.completed_count}
            style={{ backgroundColor: "rgb(96, 214, 181)", color: "#fff" }}
          />
        </div>
      </Col>
    </Row>
  );

  setRoutesFilter = (element, value) => {
    if (element === "route_type") {
      this.setState(
          {
            [element]: value,
          },
          () => {
            this.getOrders();
            this.refreshRoutes();
            // this.getStatus()
            this.handleFiltering(false);
          }
      );
    } else if (element === "filter") {
      this.setState(
          {
            stops: [],
            currentStop: {},
            selectedRouteObject: {},
            selectedRoute: "",
            [element]: value,
          },
          () => {
            this.refreshRoutes();
          }
      );
    }
    if ((element === "fromDate" || element === "toDate") && _.isEmpty(value)) {
      return;
    } else if (element === "warehouse_id") {
      this.getOrders();
      this.refreshRoutes();
    } else if (element === "fromDate" || element === "toDate") {
      this.setState(
          {
            dateFilter: Object.assign({}, this.state.dateFilter, {
              [element]: value,
              toDate: value,
            }),
          },
          () => {
            if (
                _.isEmpty(this.state.dateFilter.fromDate) ||
                _.isEmpty(this.state.dateFilter.toDate)
            ) {
              return;
            }
            this.getOrders();
            this.refreshRoutes();
          }
      );
    }
  };

  refreshRoutes = () => {
    this.getStatus();
    if (checkServiceExistance("NRI")) {
      this.getRoutes(this.state.filter);
    }
  };

  refreshIcon = (cb) => (
      <img
          src={refresh}
          className="cursorPointer textPrimary marginRight10 "
          onClick={cb}
          style={{ width: 15, marginTop: -3 }}
      />
  );

  renderNotification = (type, data, placement = "bottomRight") => {
    const notifyData = {
      key: data.key,
      message: data.message,
      description: data.description,
      placement,
    };
    if (data.icon) {
      notifyData.icon = data.icon;
    }
    notification[type](notifyData);
  };

  takeConfirmation = (action, id, data, messages) =>
      confirm({
        title: messages.title,
        content: messages.content,
        onOk: () => {
          if (action === "moveStop") {
            const { stopName } = data;
            this.renderNotification("open", {
              key: data.old_nav_route_id,
              message: (
                  <span className="fontSize14">{`Stop${
                      stopName.length > 1 ? "s" : ""
                  }: ${stopName}`}</span>
              ),
              description: (
                  <div>
                    {data.oldRouteName}&nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;{data.newRouteName}
                  </div>
              ),
              icon: <Icon type="loading" style={{ color: "#108ee9" }} />,
              placement: "bottomRight",
              duration: 20,
            });
            this.handleStopDeleteClick({
              actionType: "move",
              navRoute: data.new_nav_route_id,
              data: { ...data },
            });
          }
        },
        onCancel: () => {
          if (action === "moveStop") {
            this.setState({
              moveProgress: false,
              routeProcessedErrors: [],
              reasonObject: {},
            });
          }
        },
      });

  onDragEnd = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.source) {
      const sourceObj = result.source;
      const destinationObj = result.destination;
      const sourceEle = sourceObj.droppableId;
      const destinationEle = destinationObj.droppableId;

      if (sourceEle.indexOf("stopCard") >= 0) {
        const currentRoute = _.cloneDeep(this.state.selectedRouteObject);
        const routes = [...this.state.routes];
        const stops = [...this.state.stops];
        const stopId = result.draggableId;
        const oldRouteId = currentRoute.id;
        if (sourceEle !== destinationEle && stops && stops.length > 2) {
          currentRoute.stops = stops.map((stop) => ({
            ...stop,
            orders: currentRoute.customer_orders.filter((order) =>
                stop.customer_order_ids.includes(order.id)
            ),
          }));
          const destinationRouteIndex =
              destinationEle.indexOf("droppableRouteItem");
          if (destinationRouteIndex >= 0) {
            const newRouteId = destinationEle.replace("droppableRouteItem", "");
            const routeIndex = _.findIndex(routes, ["id", newRouteId]);
            if (routeIndex >= 0 && oldRouteId !== newRouteId) {
              const newRoute = routes[routeIndex];
              const currentStop = _.find(stops, { id: stopId });
              const checkedList =
                  this.state.routeSelectedStops.length > 0 &&
                  this.state.routeSelectedStops.includes(currentStop.id)
                      ? [...this.state.routeSelectedStops]
                      : [currentStop.id];
              let dependencies = [];
              checkedList.forEach((stop) => {
                const dependencyStops = findDependencyStops(
                    currentRoute,
                    stop,
                    "id",
                    "stops"
                );
                dependencies = [].concat(dependencies, dependencyStops);
              });
              dependencies = _.uniq(dependencies);
              const stopNames = stops
                  .filter((stop) => dependencies.includes(stop.id))
                  .map((stop) => stop.formatted_stop_name);
              const totalOrders = _.flatten(
                  _.compact(
                      stops
                          .filter((record) => dependencies.includes(record.id))
                          .map((record) => record.customer_order_ids)
                  )
              );
              const data = {
                desc: "",
                stop_id: dependencies,
                old_nav_route_id: currentRoute.id || "",
                oldRouteName: currentRoute.displayName || "",
                stopName: stopNames,
                new_nav_route_id: newRouteId || "",
                newRouteName: newRoute.displayName,
              };
              const content = (
                  <span className="textBold">
                  Routes: {currentRoute.displayName}
                    &nbsp;
                    <Icon type="arrow-right" />
                    &nbsp;
                    {newRoute.displayName}
                </span>
              );
              const reasonObject = {};
              reasonObject.ids = [...totalOrders];
              reasonObject.source = "stop";
              reasonObject.stop_type = currentStop.location_type
              reasonObject.sourceId = [...dependencies];
              reasonObject.reason = `Moved to ${newRoute.displayName}`;
              this.setState(
                  {
                    showReasonCancel: false,
                    reasonObject,
                    moveProgress: true,
                  },
                  () => {
                    this.takeConfirmation("moveStop", currentRoute.id, data, {
                      title: (
                          <Row>
                            <Col className="marginBottom10">
                              Moving the stop
                              {stopNames.length > 1 ? "s" : ""}
                            </Col>
                            {stopNames.map((stop) => (
                                <Col className="marginBottom5 fontSize12 textOverFlowEllipse">
                                  {stop}
                                </Col>
                            ))}
                          </Row>
                      ),

                      content,
                    });
                  }
              );
            }
            /* const deletionReason = [];
            stops
              .filter((stop) => checkedList.includes(stop.id))
              .forEach((stop) => {
                if (!isEmpty(stop)) {
                  const deletedOrders = stop.deleted_order_ids || [];
                  const stopOrders = stop.customer_order_ids || [];
                  const remainingOrders = stopOrders.filter(
                    (order) => !deletedOrders.includes(order)
                  );
                  if (remainingOrders.length === stopOrders.length) {
                    deletionReason.push({
                      id: stop.id,
                      reason: `Orders Moved to ${newRoute.displayName}`,
                      type: "stop",
                    });
                  }
                  remainingOrders.forEach((order) => {
                    deletionReason.push({
                      id: order,
                      reason: `Order Moved to ${newRoute.displayName}`,
                      type: "order",
                    });
                  });
                }
              });
              */
          }
        }
      }
    }
  };

  renderRouteTypes = () => (
    <Select
      value={this.state.route_type || ""}
      // style={{ width: "49%", marginRight: "15px" }}
      size="small"
      dropdownMatchSelectWidth={false}
      showSearch
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(e) => this.setRoutesFilter("route_type", e)}
    >
      <Select.Option key="route_types" value="">
        -- All --
      </Select.Option>
      {
        routeTypes.map(rec => 
        <Select.Option key={rec.key} value={rec.key}>
        {rec.label}
      </Select.Option>)
      }
    </Select>
  );

  _renderRoutesList = () => {
    const menu = (
      <Menu>
        <Menu.Item>
          <div
            onClick={() =>
              this.setRoutesFilter("filter", `${I18n.t("routes.all_cap")}`)
            }
            style={{
              width: "10rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="alignLeft">{`${I18n.t("routes.all")}`}</span>
            <Badge

              count={this.state.routeStatusInfo.total_routes_count}
              style={{ backgroundColor: "rgb(54, 178, 238)", color: "#fff" }}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={() =>
              this.setRoutesFilter("filter", `${I18n.t("routes.assigned_cap")}`)
            }
            style={{
              width: "10rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="alignLeft">{`${I18n.t("routes.assigned")}`}</span>
            <Badge

              count={this.state.routeStatusInfo.assigned_count}
              style={{ backgroundColor: "rgb(214, 118, 96)", color: "#fff" }}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={() =>
              this.setRoutesFilter("filter", `${I18n.t("routes.prepare_cap")}`)
            }
            style={{
              width: "10rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="alignLeft">{`${I18n.t("routes.prepare")}`}</span>
            <Badge

              count={this.state.routeStatusInfo.prepare_count}
              style={{ backgroundColor: "rgb(96, 122, 214)", color: "#fff" }}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={() =>
              this.setRoutesFilter(
                "filter",
                `${I18n.t("routes.dispatched_cap")}`
              )
            }
            style={{
              width: "10rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="alignLeft">{`${I18n.t(
                "routes.dispatched"
            )}`}</span>
            <Badge

              count={this.state.routeStatusInfo.dispatched_count}
              style={{ backgroundColor: "rgb(96, 214, 181)", color: "#fff" }}
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={() =>
              this.setRoutesFilter(
                "filter",
                `${I18n.t("routes.completed_cap")}`
              )
            }
            style={{
              width: "10rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="alignLeft">{`${I18n.t("routes.completed")}`}</span>
            <Badge

              count={this.state.routeStatusInfo.completed_count}
              style={{ backgroundColor: "rgb(96, 214, 181)", color: "#fff" }}
            />
          </div>
        </Menu.Item>
      </Menu>
    );
    let routeName = "";
    if (this.state.filter === `${I18n.t("routes.assigned_cap")}`)
      routeName = `${I18n.t("routes.assigned")}`;
    if (this.state.filter === `${I18n.t("routes.prepare_cap")}`)
      routeName = `${I18n.t("routes.prepare")}`;
    if (this.state.filter === `${I18n.t("routes.dispatched_cap")}`)
      routeName = `${I18n.t("routes.dispatched")}`;
    if (this.state.filter === `${I18n.t("routes.completed_cap")}`)
      routeName = `${I18n.t("routes.completed")}`;

    return (
        <div style={{ marginRight: "5px" }} className="routeBlock">
          <Spin
              spinning={this.state.inProgress || this.state.moveProgress}
              delay={1000}
          >
            <Card
                size="small"
                title={`${routeName} Routes`}
                style={{ height: "calc(100vh - 128px)", overflowY: "auto" }}
                className="borderRadius10 borderLine"
                extra={
                  <Fragment>
                    <Tooltip title={I18n.t("general.refresh")}>
                      {" "}
                      {this.refreshIcon(() => this.refreshRoutes())}
                    </Tooltip>
                  </Fragment>
                }
            >
              <Row>
                <Col>
                  <div className="dispatchTableFonts">
                    {this.state.routes.length > 0 && (
                        <Row
                            className="marginBottom5"
                            type="flex"
                            justify="space-between"
                        >
                          <Row type="flex" justify="space-between">
                            <Col>
                              <Text
                                  strong
                                  style={{
                                    fontSize: "12px",
                                    marginRight: "5px",
                                  }}
                              >
                                Drivers
                              </Text>
                            </Col>
                            <Col>
                              <Col>
                                <Icon
                                    onClick={() => this.handleSort(true)}
                                    type="caret-up"
                                    style={{
                                      position: "absolute",
                                      bottom: "6px",
                                      left: "-4px",
                                      color: this.state.adSort
                                          ? "rgb(54, 178, 238)"
                                          : "grey",
                                    }}
                                />
                                &nbsp;
                              </Col>
                              <Col>
                                <Icon
                                    onClick={() => this.handleSort(false)}
                                    type="caret-down"
                                    style={{
                                      position: "absolute",
                                      top: "-12px",
                                      left: "-4px",
                                      color: !this.state.adSort
                                          ? "rgb(54, 178, 238)"
                                          : "grey",
                                    }}
                                />
                              </Col>
                            </Col>
                          </Row>
                          <Col>
                            <Dropdown overlay={this.mapViewMenu()}>
                              <div
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                  }}
                              >
                                {mapTypeOptions[this.state.mapViewType]}{" "}
                                <Icon type="down" />
                              </div>
                            </Dropdown>
                          </Col>
                        </Row>
                    )}
                    <Row>
                      <Col span={24}>
                        {/* <RoutesList
                        data={this.state.routes}
                        // getRouteStopsInfo={id => this.getRouteStopsInfo(id)}
                        rowClassName={(route, _index) => (route.id === this.state.selectedRoute ? 'blockBgColor' : '')}
                        pagination={{ position: 'none' }}
                        stripes='even'
                        size="middle"
                        assignDriver={this.assignDriver}
                        handleComplete={this.showReasonModal}
                        onRow={record => ({
                          onClick: () => this.getRouteStopsInfo(record.id),
                        })}
                        filter={this.state.filter}
                      />
                      */}
                      <div
                        style={{
                          height: "calc(100vh - 240px)",
                          overflowY: "auto",
                          padding: 10,
                        }}
                      >
                        {this.state.routes.length > 0 &&
                          this.state.routes.map((route, index) => (
                            <RouteCard
                              key={`card${route.id}`}
                              route={route}
                              rowNumber={index}
                              getRouteStopsInfo={this.getRouteStopsInfo}
                              assignDriver={this.assignDriver}
                              handleComplete={this.showReasonModal}
                              currentRoute={this.state.selectedRouteObject}
                              currentStatus={this.state.filter}
                              deleteClick={(id) => this.handleRouteDeleteClick(id, route.route_type)}
                              isMilitaryTime={this.state.isMilitaryTime}
                              cardLoading={this.state.routeToReload === route.id}
                            />
                          ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
        </Spin>
      </div>
    );
  };

  setSelectedStop = (route, stop) => {
    const { selectedRouteObject } = this.state;
    if (!isEmpty(route) && selectedRouteObject.id === route) {
      this.setCurrentStop(stop);
    } else {
      this.getRouteStopsInfo(route, stop);
    }
  };

  navigateToStop = (id) => {
    const currentActiveStop = document.querySelector(`.stop${id}`);
    if (!isEmpty(currentActiveStop)) {
      // scrollIntoViewIfNeeded
      currentActiveStop.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  };

  setCurrentStop = (id, e = {}) => {
    const currentRoute = _.cloneDeep(this.state.selectedRouteObject);
    const stop = this.state.stops.find((stopObj) => stopObj.id === id);
    if (stop) {
      const stopType = stopTypeFromObject(stop);
      let checkedList = this.state.routeSelectedStops
          ? [...this.state.routeSelectedStops]
          : [];
      if (stopType === "CS") {
        if (e.ctrlKey || e.metaKey) {
          const stops = this.state.stops || [];
          currentRoute.stops = _.cloneDeep(stops).map((stop) => ({
            ...stop,
            orders: currentRoute.customer_orders.filter((order) =>
                stop.customer_order_ids.includes(order.id)
            ),
          }));
          const dependencyStops = findDependencyStops(
              currentRoute,
              id,
              "id",
              "stops"
          );
          if (checkedList.includes(id)) {
            checkedList = checkedList.filter(
                (stopId) => !dependencyStops.includes(stopId)
            );
          } else {
            checkedList = [].concat(checkedList, dependencyStops);
          }
          this.setState(
              {
                currentStop: stop,
                routeSelectedStops: [...checkedList],
              },
              () => {
                this.navigateToStop(id);
              }
          );
        } else {
          checkedList = [id];
          this.setState(
              {
                currentStop: stop,
                routeSelectedStops: [...checkedList],
              },
              () => {
                this.navigateToStop(id);
              }
          );
        }
      } else {
        checkedList = [];
        this.setState(
            {
              routeSelectedStops: [...checkedList],
              currentStop: stop,
            },
            () => {
              this.navigateToStop(id);
            }
        );
      }
    }
  };

  handleSequenceSuccess = () => {
    //alertMessage("messages.saved");
    //this.getRouteStopsInfo(this.state.selectedRouteObject.id);

    this.refreshOneRoute(() => {
      this.getRouteStopsInfo(this.state.selectedRouteObject.id);
    });
  };

  closeReasonModal = (refreshRoutes = false) => {
    this.setState(
        {
          route_completetion_reason: "",
          showReasonForm: false,
          inProgress: false,
          completeScreen: "",
        },
        () => {
          if (refreshRoutes) {
            this.refreshRoutes();
          }
        }
    );
  };
  showReasonModal = (id) => {
    this.setState({
      showReasonForm: true,
      selectedRoute: id,
      selectedRouteObject: {},
    });
  };

  showStopReasonModal = (screen) => {
    this.setState({
      showReasonForm: true,
      completeScreen: screen,
    });
  };

  renderRouteCompleteModal = () => {
    const routes = [...this.state.routes];
    const navRoute = _.find(routes, { id: this.state.selectedRoute });
    return (
      <BaseModal
        title={
          this.state.completeScreen === "stop"
            ? I18n.t("stop.complete_stop")
            : I18n.t("routes.complete_route")
        }
        onCancel={() => this.closeReasonModal()}
        width="70%"
      >
        <div>
          <CompleteRouteForm
            currentStop={this.state.currentStop}
            toComplete={this.state.completeScreen}
            nav_route_id={this.state.selectedRoute}
            navRoute={navRoute}
            closeModal={this.closeReasonModal}
            organizationSettings={this.props.organizationSettings}
          />
        </div>
      </BaseModal>
    );
  };

  handleReasonChange = (element, value) => {
    const reasonObject = Object.assign({}, this.state.reasonObject);
    if (element === "show") {
      if (value) {
        reasonObject.ids = [];
        reasonObject.source = "";
        reasonObject.sourceId = "";
        reasonObject.reason = "";
      } else {
        reasonObject.ids = [];
        reasonObject.sourceId = "";
        reasonObject.source = "";
        reasonObject.reason = "";
        this.setState({
          showReasonCancel: true,
        });
      }
    }
    reasonObject[element] = value;
    this.handleStateChange("reasonObject", reasonObject);
  };

  renderReasonModal = () => (
      <BaseModal
          title={I18n.t("messages.reason_to_delete")}
          onCancel={() => this.handleReasonChange("show", false)}
          width="50%"
          maskClosable={false}
          closable={false}
      >
        <div>
          <ReasonWindow
              reasonElement="reason"
              reasonValue={this.state.reasonObject.reason}
              handleOnChange={this.handleReasonChange}
              closeReasonModal={() => this.handleReasonChange("show", false)}
              saveReason={this.handleStopDeleteClick}
              inProgress={this.state.inProgress}
              showCancel={this.state.showReasonCancel}
          />
        </div>
      </BaseModal>
  );

  handleCompleteRoute = () => {
    const data = {
      nav_route_id: this.state.selectedRoute,
      r_terminating_reason: this.state.route_completetion_reason,
    };
    this.setState({ inProgress: true });
    completeRoute(data).then((result) => {
      if (result.success) {
        this.setState(
            {
              route_completetion_reason: "",
              showReasonForm: false,
              inProgress: false,
            },
            () => {
              // this.getRoutes(this.state.filter);
              this.refreshRoutes();
            }
        );
      } else {
        this.setState({ inProgress: false });
      }
    });
  };

  renderSequenceChangeForm = () => {
    const route = Object.assign({}, this.state.selectedRouteObject, {
      stops: this.state.stops,
    });
    return (
        <Drawer
            title={`${I18n.t("routes.resequence")} the stops`}
            placement="left"
            closable
            onClose={this.closeRearrangeForm}
            visible={this.state.rearrangeForm}
            width="85%"
        >
          <RearrangeStops
              currentRoute={route}
              routeId={this.state.selectedRouteObject.id}
              stopsData={this.state.stops}
              //handleCancel={this.closeRearrangeForm}
              //handleSuccess={this.handleSequenceSuccess}
              handleSuccess={this.closeRearrangeForm}
              formatStopName={(contact_details, location, stop_number) =>
                  formatStopName(contact_details, location, stop_number)
              }
          />
        </Drawer>
    );
  };

  formatForDownload = () => {
    this.setState({
      downloadContainer: true,
    });
  };

  closeDownloadContainer = () => {
    this.setState({
      downloadContainer: false,
    });
  };

  openSearchOrders = () => {
    this.setState(
        {
          showUnassigned: false,
          searchOrders: true,
        },
        () => {
          this.resetUnassignedInfo();
        }
    );
  };
  getSelectedRecoveryLocations = (routeObj) => {
    const { stops } = routeObj;
    const pickupLocationIds = stops.map((stop) => {
      return stop.stop_details.location.l_address._id;
    });
    return pickupLocationIds;
  };

  openLocationsWindow = () => {
    const { selectedRouteObject } = this.state;
    return (
      <BaseModal
        title={`Add ${I18n.t("general.location")} to the route ${
          selectedRouteObject.displayName
        }`}
        width="45%"
        onCancel={() => {
          this.closeRecoveryForm(false, selectedRouteObject.id);
        }}
        maskClosable={false}
      >
        {
          selectedRouteObject.route_type  === 'recovery_route' ? 
        <RecoveryPlanForm
          warehouses={this.props.warehouseFilter.warehouses}
          selectedInfo={{
            selectedKeys: [],
            selectedRows: [],
          }}
          drivers={[selectedRouteObject.primary_driver]}
          filterPlaceHolder={{
            dateSearchValue: !isEmpty(
              selectedRouteObject.r_scheduled_start_datetime
            )
              ? selectedRouteObject.r_scheduled_start_datetime
              : null,
            warehouse_id: selectedRouteObject.warehouse_id,
          }}
          onClose={this.closeRecoveryForm}
          showWarehouses={false}
          showRecoveryDate={false}
          showDrivers={false}
          screenName="dispatch"
          route_id={selectedRouteObject.id}
          selectedRecoveryLocations={this.getSelectedRecoveryLocations(
            selectedRouteObject
          )}
          defaultWhId={this.props.warehouseFilter.selectedWarehouses}
        />
        :
        <ReleasesPlanForm
          warehouses={this.props.warehouseFilter.warehouses}
          selectedInfo={{
            selectedKeys: [],
            selectedRows: [],
          }}
          drivers={[selectedRouteObject.primary_driver]}
          filterPlaceHolder={{
            dateSearchValue: !isEmpty(
              selectedRouteObject.r_scheduled_start_datetime
            )
              ? selectedRouteObject.r_scheduled_start_datetime
              : null,
            warehouse_id: selectedRouteObject.warehouse_id,
          }}
          onClose={this.closeRecoveryForm}
          showWarehouses={false}
          showRecoveryDate={false}
          showDrivers={false}
          screenName="dispatch"
          route_id={selectedRouteObject.id}
          selectedRecoveryLocations={this.getSelectedRecoveryLocations(
            selectedRouteObject
          )}
          defaultWhId={this.props.warehouseFilter.selectedWarehouses}
        />
          }

      </BaseModal>
    );
  };

  addStopsToRoute = () => {
    const { selectedRouteObject } = this.state;
    if (selectedRouteObject.route_type === "nav_route") {
      this.openSearchOrders();
    } else {
      this.setState({
        showLocations: true,
      });
    }
  };

  resetUnassignedInfo = () => {
    this.setState({
      unassignedOrders: [],
      unassignedOrdersInfo: {
        selectedKeys: [],
        selectedRows: [],
      },
    });
  };
  closeSearchOrders = (refreshRoute = false, routeId, actionType) => {
    const refreshRouteId = !isEmpty(routeId)
        ? routeId
        : this.state.selectedRouteObject.id;
    this.setState(
        {
          searchOrders: false,
          selectedRoute: refreshRouteId,
        },
        () => {
          this.resetUnassignedInfo();
          this.getOrders();
          if (actionType === "routeSelection") {
            this.setState({
              refreshUnallocated: true,
            });
          }
          if (refreshRoute) {
            this.refreshOneRoute(() => {
              this.getRouteStopsInfo(refreshRouteId);
            });
          }
        }
    );
  };
  closeRecoveryForm = (refreshRoute = false, routeId, actionType) => {
    const refreshRouteId = !isEmpty(routeId)
        ? routeId
        : this.state.selectedRouteObject.id;
    this.setState(
        {
          showLocations: false,
          selectedRoute: refreshRouteId,
        },
        () => {
          if (actionType === "routeSelection") {
            this.getOrders();
            this.resetUnassignedInfo();
            this.setState({
              refreshUnallocated: true,
            });
          }
          if (refreshRoute) {
            this.refreshOneRoute(() => {
              this.getRouteStopsInfo(refreshRouteId);
            });
          }
        }
    );
  };

  setDeletionElements = (ids, source, sourceId, reason = "", location_type = "CS") => {
    const reasonObject = Object.assign({}, this.state.reasonObject);
    reasonObject.ids = ids;
    reasonObject.source = source;
    reasonObject.sourceId = [sourceId];
    reasonObject.stop_type = location_type;
    reasonObject.show = true;
    if (!isEmpty(reason)) {
      reasonObject.reason = reason;
      this.setState({
        showReasonCancel: false,
      });
    }
    this.handleStateChange("reasonObject", reasonObject);
  };



  handleStopDeleteClick = (actionDetails = { actionType: 'delete' }) => {
    this.setState({ inProgress: true })
    const { reasonObject, selectedRouteObject } = this.state
    const { stops } = this.state
    let deletionReason = []
    // const stop = _.find(stops, { id: reasonObject.sourceId });
    const sourceStops = stops.filter((stop) =>
      reasonObject.sourceId.includes(stop.id)
    );
    let ordersToDelete = [];
    if (sourceStops.length > 0) {
      sourceStops.forEach((stop) => {
        deletionReason =
          !isEmpty(stop.deletionReason) && stop.deletionReason.length > 0
            ? [].concat(deletionReason, stop.deletionReason)
            : [...deletionReason];
        const deletedOrders = stop.deleted_order_ids || [];
        const stopOrders =  stopObjectIds(stop) ;// stop.customer_order_ids || [];
        const ids = _.isArray(reasonObject.ids)
          ? reasonObject.ids
          : [reasonObject.ids];
        const remainingOrders = stopOrders.filter(
          (order) => !deletedOrders.includes(order) && ids.includes(order)
        );
        if (
          reasonObject.source === "stop" ||
          ids.length === stopOrders.length
        ) {
          deletionReason.push({
            id: stop.id,
            reason: reasonObject.reason,
            type: "stop",
          });
        }
        ordersToDelete = [].concat(ordersToDelete, remainingOrders);
        remainingOrders.forEach((order) => {
          deletionReason.push({
            id: order,
            reason: reasonObject.reason,
            type:
              selectedRouteObject.route_type === "nav_route"
                ? "order"
                : "recovery",
          });
        });
      });
      let data = {};
      let func = () => {};
      if (selectedRouteObject.route_type === "nav_route") {
        data = {
          nav_route_id: selectedRouteObject.id,
          order_ids_to_delete: ordersToDelete,
          deletion_reason: [...deletionReason],
          stop_type: reasonObject.stop_type,
        };
        // need to add location ids to deleted orders 

        if (reasonObject.source === 'order') {
          const location_orders = this.state.routes
            .map((route) => route.nav_stops)
            .flat()
            .filter((stop) => stop.location_type !== "WH")
            .map((stop) => {
              switch (data.stop_type) {
                case "RC":
                  const stop_recoveries = _.filter(stop.recoveries, (order) =>
                    data.order_ids_to_delete.includes(order.customer_order_id)
                  ).map((order) => order.order_locations).flat().filter((location) => location.type_of_loc === "PICKUP");
                  return stop_recoveries;
                // case "RL":
                //   const releases = _.filter(stop.releases, (order) =>
                //     data.order_ids_to_delete.includes(order.customer_order_id)
                //   );
                //   return _.flatMap(releases, (order) => order.order_locations);
                default:
                  return [];
              }
            })
            .flat();
          data.location_ids_to_delete = location_orders.map((order) => order.id);
        }


        if(reasonObject.source === 'stop'){
          data.stop_id = reasonObject.sourceId && reasonObject.sourceId.length > 0 ? reasonObject.sourceId.join(',') : '';
        }

        func = () => deleteStopFromDispatch(data);
      }else if(selectedRouteObject.route_type === "recovery_route"){
        data = {
          nav_route_id: selectedRouteObject.id,
          stop_id: sourceStops.map((stop) => stop.id).join(","),
          deletion_reason: [...deletionReason],
        };
        func = () => RecoveriesApis.deleteStopFromDispatch(data);
      }else if (selectedRouteObject.route_type === "release_route") {
        data = {
          nav_route_id: selectedRouteObject.id,
          stop_id: sourceStops.map((stop) => stop.id).join(","),
          deletion_reason: [...deletionReason],
        };
        func = () => ReleasesApi.deleteReleaseStop(data);
      }

      func().then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.deleted"), "success", 5);
          this.setState(
            {
              inProgress: false,
              refreshUnallocated: this.state.showUnassigned,
            },
            () => {
              if (result.nav_route && result.nav_route.result === "DELETED") {
                const routes = [...this.state.routes];
                const routeIndex = _.findIndex(routes, [
                  "id",
                  selectedRouteObject.id,
                ]);
                if (routeIndex >= 0) {
                  routes.splice(routeIndex, 1);
                }

                this.setState(
                  {
                    routes,
                    selectedRoute: "",
                    selectedRouteObject: {},
                    currentStop: {},
                    currentOrder: {},
                    stops: [],
                    currentDriver: {},
                    orderInfo: [],
                  },
                  () => {
                    if (routes.length > 0) {
                      if (actionDetails.actionType === "move") {
                        this.moveRouteStops(
                          actionDetails.navRoute,
                          ordersToDelete,
                          actionDetails.data
                        );
                      } else {
                        this.getRouteStopsInfo(routes[0].id);
                      }
                    }
                  }
                );
              } else {
                if (actionDetails.actionType === "move") {
                  this.moveRouteStops(
                    actionDetails.navRoute,
                    ordersToDelete,
                    actionDetails.data
                  );
                } else {
                  this.refreshOneRoute(() => {
                    this.getRouteStopsInfo(selectedRouteObject.id);
                  });
                }
              }
              this.handleReasonChange("show", false);
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, moveProgress: false });
        }
      });
    }
  };

  handleStopAttachmentsSuccess = (pictures) => {
    //update pictures array in stops state array using currentStop id
    const { stops } = this.state;
    this.setState({
      stops: stops.map((stop) => {
        if (stop.id === this.state.currentStop.id) {
          stop.pictures = pictures;
        }
        return stop;
      }),
    });
  };

  deleteReleaseAttachments = (ackId) => {
    const { currentStop, stops } = this.state;
    this.setState({
      currentStop: {
        ...currentStop,
        pictures: currentStop.pictures.filter(
            (picture) => picture.ack_id !== ackId
        ),
      },
      stops: stops.map((stop) => {
        if (stop.id === currentStop.id) {
          stop.pictures = stop.pictures.filter(
              (picture) => picture.ack_id !== ackId
          );
        }
        return stop;
      }),
    });
  };

  updateRouteNotify = (selectedRouteObject) => {
    const data = {
      driver_id:  selectedRouteObject.primary_driver.id ,
      nav_route_id: selectedRouteObject.id,
      notification_type: I18n.t("searchFiles.value.update"),
    };
    putNotification(data).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
      } else {
        alertMessage(result.errors, "error", 10);
      }
    });
  };


  _renderStopsList = () => {
    const { stops, selectedRouteObject } = this.state;
    const hasRouteObject =
      this.state.selectedRouteObject && this.state.selectedRouteObject.id;
    const isRecoveryType =
      hasRouteObject && selectedRouteObject.route_type === "recovery_route"
        ? true
        : false;
    const isReleaseType =
        hasRouteObject && selectedRouteObject.route_type === "release_route"
          ? true
          : false;
    const selectedWH = _.find(this.props.warehouseFilter.warehouses, {
            warehouse_id: selectedRouteObject.warehouse_id,
          });
    const hasLinehaulStops = hasRouteObject && stops.filter(stop => stop.location_type === "LH").length > 0;
    const addStopOPCode = isRecoveryType ? "ORRAS" : ( isReleaseType ? "ORLRAS" : "DAO");
    return (
      <div style={{ margin: "0px 5px 0px 3px" }} className="stopBlock">
        <Spin spinning={this.state.stopsInProgress} delay={1000}>
          <Card
            size="small"
            title={<Fragment>{I18n.t("preplan.stops")}</Fragment>}
            style={{ height: "calc(100vh - 130px)", overflowY: "hidden" }}
            className={`borderRadius10 borderLine ${
              hasRouteObject ? "hasRouteObject" : ""
            }`}
            bodyStyle={{ padding: 0 }}
            extra={
              !this.state.stopsInProgress && (
                <Row gutter={ 8 } type="flex" align="middle">
                  { hasRouteObject && selectedRouteObject.nav_route_status === 'ASSIGNED' && (
                    <Col>
                      <Tooltip title={ I18n.t("preplan.assign_route") } placement="left">
                        <Icon
                          type="bell"
                          className="textBold cursorPointer"
                          style={ { fontSize: 18, color: "#d48806" } }
                          onClick={ this.handleNotifyDrivers }
                        />
                      </Tooltip>

                    </Col>
                  ) }
                  { hasRouteObject && selectedRouteObject.nav_route_status !== "COMPLETED"  && (
                    <Col>
                        <Tooltip title={ "Send " + I18n.t("searchFiles.update_list") } placement="top">
                          <img src={syncRoute} className="cursorPointer textPrimary" style={{ width: 18, marginTop: -6 }} onClick={ () => this.updateRouteNotify(selectedRouteObject)} alt="refresh" />
                        </Tooltip>
                    </Col>
                  ) }
                  { checkServiceExistance(addStopOPCode) && !hasLinehaulStops && hasRouteObject && selectedRouteObject.nav_route_status !== "COMPLETED" && (
                    <Col>
                      {/* <Tooltip title={ I18n.t("stop.add") }>
                        <img
                          src={ stopIcon }
                          className="cursorPointer textPrimary"
                          style={ { width: 18, marginTop: -6 } }
                          onClick={ this.addStopsToRoute }
                          alt="add stop"
                        />
                      </Tooltip> */}
                      <AddStop
                        route={selectedRouteObject}
                        getRouteStopsInfo={this.getRouteStopsInfo}
                        warehouses={this.props.warehouseFilter.warehouses}
                        // warehouse_id={this.props.warehouse_id}
                        warehouse_id={this.state.warehouse_id}
                        dateFilter={this.state.dateFilter}
                        closeSearchOrders={this.closeSearchOrders}
                      />
                    </Col>
                  ) }
                  { checkServiceExistance("SNOTTDS") && hasRouteObject && this.state.selectedRouteObject.primary_driver && (
                    <Col >
                      <NotifyDriver
                        driver_ids={ [ this.state.selectedRouteObject.primary_driver.id ] }
                        driverName={ this.state.selectedRouteObject.displayName || "" }
                      />
                    </Col>
                  ) }
                  { hasRouteObject && [ "DISPATCHED", "COMPLETED" ].includes(this.state.selectedRouteObject.nav_route_status) && checkServiceExistance([ "NRSIR", "TRCROUT" ], "ALL") && (
                    <Col >
                      <TrackingScreen id={ this.state.selectedRoute } />
                    </Col>
                  ) }
                  { hasRouteObject && (
                    <Col>
                      <Tooltip title={ I18n.t("general.print") }>
                        <Icon
                          type="printer"
                          onClick={ this.formatForDownload }
                          className="textBold"
                          style={ { fontSize: 18, color: "#8f48ff" } }
                        />
                      </Tooltip>
                    </Col>
                  ) }
                  { hasRouteObject && (
                    <Col>
                      <Tooltip title={ I18n.t("general.refresh") }>
                        { this.refreshIcon(() => {
                          this.getRouteStopsInfo(this.state.selectedRouteObject.id);
                        }) }
                      </Tooltip>
                    </Col>
                  ) }
                </Row>
              )
            }
          >
            <div
              className="dispatchTableFonts"
              style={{ padding: "0px 12px 12px 12px" }}
            >
              <Row className="fontSize12">
                <Col span={12} className="alignLeft">
                  {this.state.selectedRoute && hasRouteObject ? (
                    <Fragment>
                      {" "}
                      <span className="textBold">
                        {I18n.t("general.Driver")}&nbsp;&nbsp;:{" "}
                      </span>{" "}
                      {`${this.state.selectedRouteObject.displayName}`}
                    </Fragment>
                  ) : (
                    ""
                  )}
                </Col>

                <Col
                  span={12}
                  className="alignRight"
                  style={{
                    // marginBottom: '5px', fontSize: '12px', fontWeight: 'bold',
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    {checkServiceExistance("NRUSS") && !hasLinehaulStops &&
                      this.state.selectedRoute &&
                      !['recovery_route', 'release_route'].includes(selectedRouteObject.route_type) && (
                        <Fragment>
                          {this.state.selectedRouteObject.nav_route_status !==
                            "COMPLETED" &&
                            this.state.stops.length > 0 && (
                              <Tooltip title={I18n.t("routes.resequence")}>
                                {" "}
                                <span onClick={this.showResequence}>
                                  <Icon type="swap" />{" "}
                                  {I18n.t("routes.resequence")} &nbsp;&nbsp;
                                </span>
                              </Tooltip>
                            )}
                        </Fragment>
                      )}
                  </div>
                </Col>
              </Row>
              <Row className="fontSize12">
                <Col span={11} className="alignLeft">
                  {this.state.selectedRoute &&
                  this.state.selectedRouteObject &&
                  this.state.selectedRouteObject.nav_route_status ? (
                    <Fragment>
                      <span className="textBold">Status : </span>
                      {this.state.selectedRouteObject.nav_route_status}
                    </Fragment>
                  ) : (
                    ""
                  )}
                </Col>
                {hasRouteObject && (
                  <Col md={13} className="alignRight">
                    {
                      <Fragment>
                        <span className="textBold">
                          {I18n.t("routes.weight")}: :{" "}
                        </span>
                        {formatWeight(
                          this.state.selectedRouteObject.total_weight
                        )}
                      </Fragment>
                    }
                  </Col>
                )}
                {hasRouteObject && (
                  <Col span={18} className="alignLeft">
                    <Fragment>
                      <span className="textBold">{I18n.t('preplan.surface_area.est_truck_revenue')}:{" "}</span>
                      {this.state.selectedRouteObject.estimated_truck_revenue ? `$${this.state.selectedRouteObject.estimated_truck_revenue}` : '$0.00'}
                    </Fragment>
                </Col>
                )}
              </Row>
              {false && hasRouteObject && (
                <div className="textBold fontSize12 padding5">
                  <Icon type="shopping" />
                  &nbsp; {I18n.t("routes.weight")}:{" "}
                  {formatWeight(this.state.selectedRouteObject.total_weight)}
                </div>
              )}
              {hasRouteObject && <Divider style={{ margin: "6px 0px 6px" }} />}

              <Row className="fontSize12">
                {this.state.filter === "COMPLETED" &&
                  this.state.selectedRouteObject.r_terminated_user_name && (
                    <Col span={24} className="alignLeft">
                      <Fragment>
                        <span className="textBold">Completed by : </span>
                        {this.state.selectedRouteObject.r_terminated_user_name}
                      </Fragment>
                    </Col>
                  )}
                {this.state.filter === "COMPLETED" &&
                  this.state.selectedRouteObject.r_terminating_reason && (
                    <Col span={24} className="alignLeft">
                      <Fragment>
                        <span className="textBold">Completed reason : </span>
                        {this.state.selectedRouteObject.r_terminating_reason}
                      </Fragment>
                    </Col>
                  )}
              </Row>
              {false && (
                <StopsList
                  rowKey="id"
                  data={stops}
                  setCurrentStop={(id) => this.setCurrentStop(id)}
                  currentStop={this.state.currentStop}
                  currentRoute={this.state.currentRoute}
                  pagination={{ position: "none" }}
                  onRow={(record) => ({
                    onClick: () => this.setCurrentStop(record.id),
                  })}
                  deleteClick={this.handleStopDeleteClick}
                  locale={{
                    emptyText: (
                      <Empty
                        description={I18n.t("messages.click_route_message")}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    ),
                  }}
                />
              )}
              { this.state.stops.length > 0 && (
                <div
                className="marginTop10"
                style={{
                  height: "calc(100vh - 240px)",
                  overflowY: "auto",
                  padding: 10,
                }}
              >
                <Droppable droppableId="stopCard">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <StopsTimeline
                        handleStopAttachmentsSuccess={
                          this.handleStopAttachmentsSuccess
                        }
                        rowKey="id"
                        stops={stops}
                        setCurrentStop={this.setCurrentStop}
                        currentStop={this.state.currentStop}
                        route={this.state.selectedRouteObject}
                        pagination={{ position: "none" }}
                        onRow={(record) => ({
                          onClick: () => this.setCurrentStop(record.id),
                        })}
                        deleteClick={this.setDeletionElements}
                        handleCompleteStop={this.showStopReasonModal}
                        editOrderClick={(id) => this.handleEditOrderClick(id)}
                        deleteOrderClick={this.setDeletionElements}
                        selectedStops={this.state.routeSelectedStops}
                        deleteReleaseAttachments={this.deleteReleaseAttachments}
                        locale={{
                          emptyText: (
                            <Empty
                              description={I18n.t(
                                "messages.click_route_message"
                              )}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                        selectedWH={selectedWH}
                        stopsToRefresh={this.state.stopsToRefresh}
                      />
                      {provided && provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              ) }

            </div>
          </Card>
        </Spin>
      </div>
    );
  };

//Method updated
  changesOrdersState = (showRouteAssigner = !this.state.showRouteAssigner,isUsingBetaVersion =this.state.isUsingBetaVersion) => {
    this.setState({
      showUnassigned: isUsingBetaVersion ? false : !this.state.showUnassigned,
      searchOrders: false,
      showRouteAssigner: showRouteAssigner,
      isUsingBetaVersion: isUsingBetaVersion ? !this.state.isUsingBetaVersion : isUsingBetaVersion,
    });
    this.resetUnassignedInfo();
    this.getOrders(isUsingBetaVersion ? "betaOrderProgress" : "ordersProgress");
  };

  renderCartIcon = () => {
    const { unassignedOrdersInfo, showUnassigned, isUsingBetaVersion } = this.state;
    return isUsingBetaVersion ? (
        <Droppable droppableId="unassignedDroppablefrommap">
          {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Draggable
                    key="fromMap"
                    draggableId="fromMap"
                    index={8}
                    isDragDisabled={true}
                >
                  {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                          )}
                      >
                        <div
                            style={{
                              width: 50,
                              height: 30,
                              borderRadius: 50,
                              backgroundColor: "#607AD6",
                              fontSize: 15,
                              zIndex: 9,
                            }}
                            className="draggingBlock center textWhite"
                        >
                          <img src={truckImage} style={{ width: 25 }} />
                          <sup>{unassignedOrdersInfo.selectedKeys.length}</sup>
                        </div>
                      </div>
                  )}
                </Draggable>
                {provided && provided.placeholder}
              </div>
          )}
        </Droppable>
    ) : (
        <Fragment />
    );
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    color: isDragging ? "red" : "green",
    ...draggableStyle,
  });

  // to update uinassigned orders selected Info
  handleUnassignOrdersInfo = (orders = []) => {
    this.setState({
      unassignedOrdersInfo: {
        selectedKeys: orders.map((order) => order.id),
        selectedRows: orders,
      },
    });
  };

  markMultiple = (checked, ordersList) => {
    // let orders = []; // ...selectedRows
    // const { unassignedOrdersInfo, unassignedOrders } = this.state;
    // const previousRows = [...unassignedOrdersInfo.selectedRows];
    // let dependencyOrders = [];
    // ordersList.forEach((currentRecord) => {
    //   const dependencies = findDependencyOrders(
    //     unassignedOrders,
    //     "related_order",
    //     "id",
    //     currentRecord,
    //     "object",
    //     "type_of_order"
    //   );
    //   dependencyOrders = [].concat(dependencyOrders, dependencies);
    // });
    // const dependencyIds = dependencyOrders.map((order) => order.id);
    // if (checked === false) {
    //   orders = previousRows.filter(
    //     (order) => !dependencyIds.includes(order.id)
    //   );
    // } else {
    //   const filteredItems = previousRows.filter(
    //     (order) => !dependencyIds.includes(order.id)
    //   );
    //   if (dependencyOrders.length > 0) {
    //     orders = [].concat(filteredItems, dependencyOrders);
    //   } else {
    //     orders = [...filteredItems];
    //   }
    // }
    const { unassignedOrdersInfo, unassignedOrders } = this.state;
    const orders = markMultipleOrders(
        checked,
        ordersList,
        [...unassignedOrdersInfo.selectedRows],
        unassignedOrders
    );
    this.handleUnassignOrdersInfo(orders);
  };

  _renderMapView = () => {
    let routes = [...this.state.routes];
    let filteredRoutes = [...this.state.routes];
    const {
      mapViewType,
      selectedRoute,
      unassignedOrders,
      showUnassigned,
      selectedRouteObject,
    } = this.state;
    let showNumbers = false;
    if (!isEmpty(mapViewType) && !isEmpty(selectedRoute)) {
      if (mapViewType === "SELECTED") {
        filteredRoutes = this.state.routes.filter(
            (route) => route.id === selectedRoute
        );
        showNumbers = true;
      } else if (mapViewType === "HIDE_ALL") {
        filteredRoutes = [];
      }
    }
    const noncompletedRoutes = routes.filter(
        (route) =>
            route.status !== "COMPLETED" && route.route_type === "nav_route"
    );
    const orders =
        showUnassigned && unassignedOrders ? [...unassignedOrders] : [];
    const isAirCargoOrg = this.props.isAirCargo()
    return (
      <div
        style={{
          margin: "0px 5px 5px 5px",
          textAlign: "center",
          height: "100%",
        }}
        className="borderLine borderRadius10"
      >
        <div
          className="alignRight paddingRight10"
          style={{ position: "absolute", top: "5px", right: "0px", zIndex: 9 }}
        >
          &nbsp; &nbsp;
          {this.state.isUsingBetaVersion && (
            <AddOrdersToRoute
              routes={noncompletedRoutes}
              label={"Allocate"}
              currentSelected={selectedRouteObject.id}
              buttonType="danger"
              closeSearchOrders={this.closeSearchOrders}
              selectedInfo={this.state.unassignedOrdersInfo}
              size="small"
              isDisabled={
                this.state.unassignedOrdersInfo.selectedKeys.length === 0 ||
                noncompletedRoutes.length === 0
              }
              actionType={"routeSelection"}
            />
          )}
        </div>
        <RoutesMap
          navRoutes={filteredRoutes}
          selectedRoute={this.state.selectedRoute}
          showNumbers={showNumbers}
          driver={this.state.currentDriver}
          setCurrentRoute={this.setSelectedStop}
          mapViewType={mapViewType}
          orderInfo={orders}
          showOrders={this.state.showUnassigned}
          selectedOrderKeys={this.state.unassignedOrdersInfo.selectedKeys || []}
          markSelected={this.markSelected}
          markMultiple={this.markMultiple}
          organizationSettings={this.props.organizationSettings}
          onPanelClick={ this.onPanelClick }
          panelSize={ this.state.panelSize }
          noncompletedRoutes={noncompletedRoutes}
          selectedRouteObject={selectedRouteObject}
          closeSearchOrders={this.closeSearchOrders}
          unassignedOrdersInfo={this.state.unassignedOrdersInfo}
          showUnassigned={this.state.showUnassigned}
          isAirCargo={isAirCargoOrg}
        />
        <div
          style={{
            width: 30,
            float: "right",
            position: "relative",
            bottom: "14vh",
            right: 30,
          }}
        >
          {this.state.unassignedOrdersInfo.selectedKeys.length > 0 &&
            this.renderCartIcon()}
        </div>
      </div>
    );
  };

  markSelected = (id) => {
    // const unassignedOrdersInfo = {...this.state.unassignedOrdersInfo};
    // const unassignedOrders = [...this.state.unassignedOrders];
    // if (checkServiceExistance('NRURO')) {
    //   // document.getElementById(`${id}`).focus();
    //   const selectedOrderKeys = [
    //     ...unassignedOrdersInfo.selectedKeys,
    //   ];
    //   const selectedOrderRows = [
    //     ...unassignedOrdersInfo.selectedRows,
    //   ];
    //   const orderIndex = _.findIndex(unassignedOrders, [ "id", id ]);
    //   if (selectedOrderKeys.includes(id)) {
    //     selectedOrderKeys.splice(selectedOrderKeys.indexOf(id), 1);
    //     selectedOrderRows.splice(_.findIndex(selectedOrderRows, [ "id", id ]), 1);
    //   } else if (orderIndex >= 0) {
    //     selectedOrderRows.push(unassignedOrders[ orderIndex ]);
    //     selectedOrderKeys.push(id);
    //   }
    //   this.setState({
    //     unassignedOrdersInfo: {
    //       selectedKeys: selectedOrderKeys,
    //       selectedRows: selectedOrderRows,
    //     },
    //   })
    // }
    if (checkServiceExistance("NRURO")) {
      document.getElementById(`${id}`).focus();
      const { unassignedOrdersInfo, unassignedOrders } = this.state;
      const selectedOrderKeys = [...unassignedOrdersInfo.selectedKeys];
      const isChecked = selectedOrderKeys.includes(id);
      const orders = markMultipleOrders(
          !isChecked,
          [id],
          [...unassignedOrdersInfo.selectedRows],
          unassignedOrders
      );
      this.handleUnassignOrdersInfo(orders);
    }
  };

  handleOrderSaveSuccess = (
      callbackMessage,
      isOutOfDispatch,
      isModified,
      newData
  ) => {
    if (isOutOfDispatch) {
      // this.setDeletionElements(
      //   [this.state.currentOrder.id],
      //   "order",
      //   this.state.currentStop.id,
      //   "Appointment has changed"
      // );
      this.refreshOneRoute(() => {
        this.getRouteStopsInfo(this.state.selectedRouteObject.id);
      });
    }
    this.closeEditFormModal();
  };
  closeEditFormModal = () => {
    this.setState({
      editForm: false,
      currentOrder: {},
    });
  };

  renderItemsExceptionModal = () => {
    const { currentRecord,showItemExceptionModal } = this.state;
    return (
      <ItemsExceptionModal
        visible={showItemExceptionModal}
        onCancel={() => {
          this.setState({ showItemExceptionModal: false });
        }}
        data={currentRecord}
        isMilitaryTime={this.state.isMilitaryTime}
      />
    );
  };

  _renderOrdersList = (ordersList, routeType , stopItems , displayConfiguration , stop) => {
    const currentRoute = this.state.selectedRouteObject;
    const uniqueItems = [
      ...new Set(stopItems.map((item) => JSON.stringify(item))),
    ].map((item) => JSON.parse(item));
    const stopLocType = stop.location && stop.location_type ? stop.location_type : "";
    const orderListingProps = {
      rowKey: "customer_order_id",
      className: "dispatch-empty-table-height",
      accessorials: this.state.accessorials,
      data: ordersList,
      currentStop: stop,
      // style: { height: "calc(30vh - 125px)", overflowY: "auto" },
      // scroll: { x: "max-content", y: "calc(32vh - 145px)" },
      style: { overflowY: "auto" },
      scroll: { x: "max-content", y : this.state.panelSize.pane_2 === 1 ? "calc(90vh - 125px)" : "calc(30vh - 145px)" },
      pagination: { position: "none" },
      size: "small",
      deleteOrderClick: this.setDeletionElements,
      showActions: this.state.routeStatus !== "COMPLETED",
      showMoveAction: false,
      editOrderClick: (id) => this.handleEditOrderClick(id),
      displayConfiguration: displayConfiguration,
      screen_from: "dispatches",
      stopType:
        this.state.currentStop.location &&
          this.state.currentStop.location.l_type
          ? this.state.currentStop.location.l_type
          : "",
      showBilling: false,
      rowClassName: (record, _index) =>
        stop.deleted_order_ids.includes(record.customer_order_id)
          ? "blockBgErrorColor"
          : "",
      stopIndex: stop.stop_number - 1,
      stopType: stopLocType
    };
    return routeType !== "lh_route" && ordersList.length > 0 && <OrdersList
      { ...orderListingProps }
      expandedRowRender={ (record) => {

        const ordeItems = uniqueItems.filter(
          (itemObj) => itemObj.customer_order_id === record.customer_order_id
        );
        const subOrders = currentRoute.sub_orders.filter(
          (rec) => stop?.sub_order_ids.includes(rec.customer_order_id)
        );
        const deletionReason = findResonObject(
          stop.deletion_reason,
          record.customer_order_id,
          "order"
        );
        return (
          <Row style={ { marginLeft: -40 } }>
            { !isEmpty(deletionReason) &&
              deletionReason.reason && (
                <Col xs={ 24 } className="marginTop10">
                  { I18n.t("messages.reason_to_delete") }
                  &nbsp;:&nbsp;
                  { deletionReason.reason }
                </Col>
              ) }
            <Col xs={ 24 }>
             { record.type_of_order !=='LH' && <Fragment>
              { ordeItems.length > 0 ? (
                <>
                  <h4>{ I18n.t("order.items") }</h4>
                  <ItemsList
                    data={ ordeItems }
                    size="small"
                    pagination={ { position: "none" } }
                    showQuantity
                    showPod={ false }
                    showActions={ true }
                    showSerialNo
                    showOrderNo={ false }
                    orderDetailsViewClick={ (id) => this.handleEditOrderClick(id) }
                    rowClassName={(record, index) =>
                      record.item_picture_data && record.item_picture_data?.some(item => _.get(item, "exception_code") !== "") ? "errRow" : ""
                  }
                  handleModalView={(record) => {
                    this.setState({
                      showItemExceptionModal: true,
                      currentRecord: record,
                    });
                  }}
                  />
                </>
              ) :
              (
                <Col
                  xs={ 24 }
                  className="marginTop10"
                >
                  <h4>No Items available for this order.</h4>
                </Col>
              )
              }
              {this.state.showItemExceptionModal && this.renderItemsExceptionModal()}
              </Fragment>
              }
              { record.type_of_order ==='LH' && subOrders.length > 0 && (
                <Col
                  xs={ 24 }
                  className="marginTop10 marginBottom15"
                >
                  <h4>{ I18n.t("menu.orders") }</h4>
                  <OrdersList
                    data={ subOrders }
                    size="small"
                    pagination={ { position: "none" } }
                    currentStop={stop}
                    scroll={{ x: "max-content", y : 200 }}
                    showActions={false}
                    showMoveAction={false}
                    editOrderClick = {(id) => this.handleEditOrderClick(id)}
                  />
                </Col>
              )
              }
              { record.notes && (
                <Row className="marginTop20">
                  <Col sm={ 2 } xs={ 2 } md={ 1 } lg={ 1 }>
                    <div className="textCenter">
                      {/* <Icon type="form" /> */ }
                      <FormIcon />
                    </div>
                  </Col>
                  <Col sm={ 10 } xs={ 10 } md={ 4 } lg={ 3 }>
                    <div className="info-text textBold">
                      { I18n.t("info.notes") }
                    </div>
                  </Col>
                  <Col xs={ 1 }>:</Col>
                  <Col sm={ 11 } xs={ 11 } md={ 18 } lg={ 19 }>
                    <div className="info-text">
                      <span>{ record.notes }</span>
                    </div>
                  </Col>
                </Row>
              ) }
              { record.comments &&
                _.isArray(record.comments) &&
                record.comments
                  .filter(
                    (comment, index) =>
                      (index === record.comments.length) === 1
                  )
                  .map((comment) => (
                    <Fragment>
                      { comment.customer_comments && (
                        <Row>
                          <Col sm={ 2 } xs={ 2 } md={ 1 } lg={ 1 }>
                            <div className="textCenter">
                              {/* <Icon type="form" /> */ }
                              <FormIcon />
                            </div>
                          </Col>
                          <Col sm={ 10 } xs={ 10 } md={ 4 } lg={ 3 }>
                            <div className="info-text textBold">
                              { I18n.t("order.customer_notes") }
                            </div>
                          </Col>
                          <Col xs={ 1 }>:</Col>
                          <Col sm={ 11 } xs={ 11 } md={ 18 } lg={ 19 }>
                            <div className="info-text">
                              <span>
                                { comment.customer_comments }
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ) }
                      { comment.driver_notes && (
                        <div>
                          <Row>
                            <Col sm={ 2 } xs={ 2 } md={ 1 } lg={ 1 }>
                              <div className="textCenter">
                              // <Icon type="form" />
                                <FormIcon />
                              </div>
                            </Col>
                            <Col sm={ 10 } xs={ 10 } md={ 4 } lg={ 3 }>
                              <div className="info-text textBold">
                                { I18n.t("order.driver_notes") }
                              </div>
                            </Col>
                            <Col xs={ 1 }>:</Col>
                            <Col sm={ 11 } xs={ 11 } md={ 18 } lg={ 19 }>
                              <div className="info-text">
                                <span>
                                  { renderDriverNotes(
                                    comment.driver_notes
                                  ) }
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) }
                    </Fragment>
                  )) }
              { record.failed_notes && (
                <Row>
                  <Col sm={ 2 } xs={ 2 } md={ 1 } lg={ 1 }>
                    <div className="textCenter">
                    // <Icon type="form" />
                      <FormIcon />
                    </div>
                  </Col>
                  <Col sm={ 10 } xs={ 10 } md={ 4 } lg={ 3 }>
                    <div className="info-text textBold">
                      { I18n.t("routes.failed_notes") }
                    </div>
                  </Col>
                  <Col xs={ 1 }>:</Col>
                  <Col sm={ 11 } xs={ 11 } md={ 18 } lg={ 19 }>
                    <div className="info-text">
                      <span>{ record.failed_notes }</span>
                    </div>
                  </Col>
                </Row>
              ) }
            </Col>
          </Row>
        );
      } }
    /> 
  }

  _renderStopDetails = () => {
    const stop = this.state.currentStop ? { ...this.state.currentStop } : {};
    const { selectedRouteObject = {}, warehouses = [] } = this.state;
    const routeType = selectedRouteObject.route_type;
    const selectedWH = _.find(this.props.warehouseFilter.warehouses, {
      warehouse_id: selectedRouteObject.warehouse_id,
    });
    const isDepoStop = stop.location_type === "WH"
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    const isMilitaryTime =
      this.state.organizationSettings["is_military_time"] === "true";
    const actual_start_date_time_with_tz = stop.actual_start_date_time_with_tz
      ? formatByTimeConfig(
          moment.utc(stop.actual_start_date_time_with_tz),
          isMilitaryTime,
          "HH:mm",
          "hh:mm A"
        )
      : "NA";
    const actual_end_date_time_with_tz = stop.actual_end_date_time_with_tz
      ? formatByTimeConfig(
          moment.utc(stop.actual_end_date_time_with_tz),
          isMilitaryTime,
          "HH:mm",
          "hh:mm A"
        )
      : "NA";
    const scheduled_start_date_time_with_tz =
      stop.scheduled_start_date_time_with_tz
        ? formatByTimeConfig(
            moment.utc(stop.scheduled_start_date_time_with_tz),
            isMilitaryTime,
            "HH:mm",
            "hh:mm A"
          )
        : "NA";
    const scheduled_end_date_time_with_tz = stop.scheduled_end_date_time_with_tz
      ? formatByTimeConfig(
          moment.utc(stop.scheduled_end_date_time_with_tz),
          isMilitaryTime,
          "HH:mm",
          "hh:mm A"
        )
      : "NA";
    const estimated_arrival_date_time_with_tz =
      stop.estimated_arrival_date_time_with_tz
        ? formatByTimeConfig(
            moment.utc(stop.estimated_arrival_date_time_with_tz),
            isMilitaryTime,
            "HH:mm",
            "hh:mm A"
          )
        : "NA";
    const estimated_departure_date_time_with_tz =
      stop.estimated_departure_date_time_with_tz
        ? formatByTimeConfig(
            moment.utc(stop.estimated_departure_date_time_with_tz),
            isMilitaryTime,
            "HH:mm",
            "hh:mm A"
          )
        : "NA";
    // const service_duration = stop.service_duration ? secondsToHms(parseInt(stop.service_duration), true, true, false) : '0';
    const timeZone = stop.stop_tz_short_name;
    const stopIds = stopObjectIds(stop);
    const stopOrderIds = _.uniq(
      _.compact([].concat(stopIds, stop.deleted_order_ids))
    );
    const geoException = stop.geo_fence_exception;
    const serviceDuration = stop.service_duration;
    let deliveryDate = "";
    if (stop.stop_number === 1) {
      deliveryDate = stop.estimated_departure_date_time_with_tz
        ? moment
            .utc(stop.estimated_departure_date_time_with_tz)
            .format("MMMM, Do YYYY")
        : "NA";
    } else {
      deliveryDate = stop.estimated_arrival_date_time_with_tz
        ? moment
            .utc(stop.estimated_arrival_date_time_with_tz)
            .format("MMMM, Do YYYY")
        : "NA";
    }

    let ordersList = [];
    let recoveries = [];
    let releases = [];
    let linehauls = [];
    if (
      stopOrderIds &&
      stopOrderIds.length > 0 &&
      selectedRouteObject &&
      ( stop.location_type  === "WH" ||
        (stop.location_type  === "CS" && !isEmpty(selectedRouteObject.customer_orders)) ||
        (stop.location_type  === "RC" && !isEmpty(selectedRouteObject.recoveries)) || 
        (stop.location_type  === "RL" && !isEmpty(selectedRouteObject.releases)) ||
        (stop.location_type  === "LH" && !isEmpty(selectedRouteObject.linehauls))
      )
    ) {
      if (["WH", "CS"].includes(stop.location_type)) {
        stopOrderIds.map((id) => {
          const order = selectedRouteObject?.customer_orders ? selectedRouteObject.customer_orders.find(
            (order) => id === order.customer_order_id
          ) : null;
          if (!isEmpty(order)) {
            ordersList = [...ordersList, order];
          }
        });
      }
      if (["WH", "RC"].includes(stop.location_type)) {
        stopOrderIds.map((id) => {
          const recovery = selectedRouteObject?.recoveries ? selectedRouteObject.recoveries.find(
            (rec) => id === rec.id
          ) : null ;
          if (!isEmpty(recovery)) {
            recoveries = [...recoveries, recovery];
          }
        });
      }
      if (["WH", "RL"].includes(stop.location_type)) {
        stopOrderIds.map((id) => {
          const release = selectedRouteObject?.releases ? selectedRouteObject.releases.find(
            (rec) => id === rec.id
          ) : null;
          if (!isEmpty(release)) {
            releases = [...releases, release];
          }
        });
      }
      if (["LH"].includes(stop.location_type)) {
        stopOrderIds.map((id) => {
          const lh = selectedRouteObject?.linehauls ? selectedRouteObject.linehauls.find(
            (rec) => id === rec.id
          ) : null;
          if (!isEmpty(lh)) {
            linehauls = [...linehauls, lh];
          }
        });
      }
    }
    const stopItems = [].concat(stop.pickup_order_items, stop.drop_order_items);
    if (Object.keys(stop).length !== 0) {
      if (stop && isEmpty(stop.deleted_order_ids)) {
        stop.deleted_order_ids = [];
      }
      return (
        <div>
          <Card
            size="small"
            title={I18n.t("routes.stop_details")}
            //style={{ height: "calc(50vh - 132px)", overflowY: "hidden" }}
            className="borderRadius10 borderLine"
            extra={
              <Row gutter={ 8 } type="flex" justify="end" align="middle">
                {!isEmpty(stop.formatted_stop_name) && (
                <Col>
                  <Text strong style={ { fontSize: "14px" } }>
                    <Icon type="environment" /> { stop.formatted_stop_name }
                  </Text>
                </Col>
                )}
                {!isEmpty(stop.company_name) && (
                <Col>
                  <Text strong style={ { fontSize: "14px" } }>
                    <Icon type="bank" /> { stop.company_name }
                  </Text>
                </Col>
                )}
              </Row>
            }
            bodyStyle={{
              height: this.state.panelSize.pane_2 === 1 ? "calc(100vh - 166px)" : "calc(50vh - 166px)", overflowY: "auto"
            }}
          >
            <Row className="">
              <Col xs={24} sm={24} md={18} lg={16}>
                <Row>
                  {/* {stop.interconnection_status &&
                    <Row>
                      <Col xs={24}>
                        <Popover
                          placement="bottom"
                          title={I18n.t('routes.distance_between_stops')}
                          content={
                            <StopDistanceInfo stop={stop} />
                          }
                        >
                          <Row className="distanceInfo marginTop5">
                            <Col span={24} className="padding05">
                              <Row style={{ fontSize: '11px' }}>
                                <Col xs={12} className="alignLeft">
                                  <Row> <Col xs={24}>&nbsp;&nbsp;<Icon type="branches" />&nbsp;&nbsp;{I18n.t('routes.distance')}&nbsp;&nbsp;:&nbsp;&nbsp;{stop.distance}&nbsp;</Col></Row>
                                </Col>
                                <Col xs={12} className="alignLeft">
                                  // <Row> <Col xs={24}><Icon type="clock-circle" />&nbsp;&nbsp;{I18n.t('routes.time')}&nbsp;&nbsp;:&nbsp;&nbsp;{stop.time}&nbsp;&nbsp;</Col></Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Popover>
                      </Col>
                    </Row>
                  } */}
                  {deliveryDate !== "NA" && (
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                      lg={24}
                      className="alignLeft marginTop5 textBold"
                    >
                      <span style={{ fontSize: "14px" }}>
                        <Icon type="calendar" />
                        &nbsp;&nbsp;{deliveryDate}
                      </span>
                    </Col>
                  )}
                  <Row>
                    {(estimated_arrival_date_time_with_tz !== "NA" ||
                      estimated_departure_date_time_with_tz !== "NA") && (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        className="alignLeft paddingLeft5"
                      >
                        <span className="fontsize11">
                          {I18n.t("routes.estimated_time")}:&nbsp;
                          {formatStopTime(
                            estimated_arrival_date_time_with_tz,
                            estimated_departure_date_time_with_tz,
                            timeZone
                          )}
                        </span>
                      </Col>
                    )}
                    {(scheduled_start_date_time_with_tz !== "NA" ||
                      scheduled_end_date_time_with_tz !== "NA") && (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        className="alignLeft paddingLeft5"
                      >
                        <span className="fontsize11">
                          {I18n.t("routes.scheduled_time")}:&nbsp;
                          {formatStopTime(
                            scheduled_start_date_time_with_tz,
                            scheduled_end_date_time_with_tz,
                            timeZone
                          )}
                        </span>
                      </Col>
                    )}
                    {(actual_start_date_time_with_tz !== "NA" ||
                      actual_end_date_time_with_tz !== "NA") && (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        className="alignLeft paddingLeft5"
                      >
                        <span className="fontsize11">
                          {I18n.t("routes.actual_time")}:&nbsp;
                          {formatStopTime(
                            actual_start_date_time_with_tz,
                            actual_end_date_time_with_tz,
                            timeZone
                          )}
                        </span>
                      </Col>
                    )}
                    {false && serviceDuration && serviceDuration !== 0 && (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        className="alignLeft paddingLeft5"
                      >
                        <span className="fontsize11">
                          <Icon type="clock-circle" />
                          &nbsp;{I18n.t("stop.service_duration")}:&nbsp;
                          {serviceDuration}
                        </span>
                      </Col>
                    )}
                  </Row>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={8}
                className="fontsize11 alignLeft"
              >
                {/* <h4><u>{I18n.t('order.customer_details')}</u></h4> */}
                <StopContact
                  stop={stop}
                  withLables={false}
                  showAddress={false}
                  showEmail={false}
                />
              </Col>
            </Row>

            <Row>
              {/* <h4 className="alignLeft marginTop10">Orders</h4> */}
              <div className="dispatchTableFonts marginTop15">
                {['WH', 'CS'].includes(stop.location_type) && ordersList.length > 0 &&   (
                  <div>
                    {isDepoStop && <h4 className="alignLeft">{I18n.t('menu.orders')}</h4>}
                    { this._renderOrdersList(ordersList, routeType, stopItems, displayConfiguration , stop)}
                  </div>
                )}
                {(['WH', 'RC'].includes(stop.location_type) &&
                 (!isDepoStop  || isDepoStop && recoveries.length > 0)) &&
                  <div className="marginTop10">
                     {isDepoStop && <h4 className="alignLeft">{I18n.t('menu.recovery')}</h4>}
                     <RecoveryList
                      data={recoveries}
                      showActions={false}
                      pagination={{ position: "none" }}
                      selectedWH={selectedWH}
                      showStatus={false}
                     // scroll={{ x: "max-content", y: "calc(32vh - 145px)" }}
                      showSno={true}
                      className="dispatch-empty-table-height"
                      rowClassName={(record, _index)=>stop.deleted_order_ids.includes(record.id) ? "blockBgErrorColor": ""}
                      locale={{
                        emptyText: (
                          <h4 className="alignCenter">
                            No Records Found
                          </h4>
                        )
                      }}
                    columnsNotToDisplay={true}
                    />
                  </div>
                 }
                 {(['WH', 'RL'].includes(stop.location_type) &&
                 (!isDepoStop  || isDepoStop && releases.length > 0)) &&
                  <div className="marginTop10">
                     {isDepoStop && <h4 className="alignLeft">{I18n.t('menu.releases')}</h4>}
                     <ReleasesList
                      data={releases}
                      showActions={false}
                      pagination={{ position: "none" }}
                      selectedWH={selectedWH}
                      showStatus={false}
                     // scroll={{ x: "max-content", y: "calc(32vh - 145px)"  }}
                      showSno={true}
                      className="dispatch-empty-table-height"
                      rowClassName={(record, _index)=>stop.deleted_order_ids.includes(record.id) ? "blockBgErrorColor": ""}
                      locale={{
                        emptyText: (
                          <h4 className="alignCenter">
                            No Records Found
                          </h4>
                        )
                      }}
                      
                    />
                  </div>
                 }
                 {['LH'].includes(stop.location_type) && (
                  <div>
                    { this._renderOrdersList(linehauls, routeType, stopItems, displayConfiguration , stop)}
                  </div>
                 )}
                 {false && ['LH'].includes(stop.location_type) &&
                  <div className="marginTop10">
                     <LineHaulList
                      data={linehauls}
                      showActions={false}
                      pagination={{ position: "none" }}
                      selectedWH={selectedWH}
                      showStatus={false}
                      //scroll={{ x: "max-content", y: "calc(32vh - 145px)"  }}
                      showSno={true}
                      className="dispatch-empty-table-height"
                      rowClassName={(record, _index)=>stop.deleted_order_ids.includes(record.id) ? "blockBgErrorColor": ""}
                      screen_from="dispatch"
                      size="small"
                      isMilitaryTime={isMilitaryTime}
                      handleStatusChange={() => {}}
                      locale={{
                        emptyText: (
                          <h4 className="alignCenter">
                            No Records Found
                          </h4>
                        )
                      }}
                      orderConfig={this.props.orderConfig}
                      // selectedWH={selectedWH}
                    />
                  </div>
                  }
              </div>
              {geoException && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  className="alignLeft paddingLeft5 marginTop15"
                >
                  <Icon className="textRed fontsize11" type="warning" />
                  &nbsp;
                  <span className="fontsize11">
                    {I18n.t(
                      "configurations.geofence_exception_for_driver_lable"
                    )}
                    :&nbsp;
                    {geoException}
                  </span>
                </Col>
              )}
            </Row>
          </Card>


            {this.state.editForm && (
                <Drawer
                    placement="right"
                    closable={false}
                    maskClosable={false}
                    onClose={this.closeEditFormModal}
                    visible={this.state.editForm}
                    width="90%"
                >
                  <EditOrderForm
                      customerDetails={this.state.currentOrder}
                      customerOrderId={this.state.currentOrder.id}
                      preferences={[]}
                      onCancel={this.closeEditFormModal}
                      handleSuccess={(
                          message,
                          isOutOfDispatch,
                          isModified,
                          newData
                      ) =>
                          this.handleOrderSaveSuccess(
                              message,
                              isOutOfDispatch,
                              isModified,
                              newData
                          )
                      }
                      currentRoute={this.state.selectedRouteObject}
                      isNew={false}
                      callFrom="dispatch"
                  />
                </Drawer>
            )}
          </div>
      );
    }
    return (
        <Row>
          <Card
            size="small"
            title="Stop Details"
            style={ { height: "calc(50vh - 132px)", overflowY: "auto" } }
            className="borderRadius10 borderLine emptyBlock"
            // style={{height : "100%"}}
            // extra={ // Add the button to the extra section
            //   <Button
            //     size="small"
            //     icon={ this.state.panelSize.pane_2 === 1 ? "minus-square" : "fullscreen" }
            //     onClick={ () => this.onPanelClick("pane_2") }
            //     >
            //     { this.state.panelSize.pane_2 === 1 ? "Minimize" : "Maximize" }
            //   </Button>
            // }
        >
          <h2>{ I18n.t("messages.click_stop_message") }</h2>
          </Card>
      </Row>
    );
  };

  _renderStopsInfo = () => (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div style={{ margin: "5px 0px 5px 3px" }}>
            {this._renderStopDetails()}
          </div>
        </Col>
      </Row>
  );

  closeRearrangeForm = () => {
    this.setState({ rearrangeForm: false }, () => {
      this.refreshOneRoute(() => {
        this.getRouteStopsInfo(this.state.selectedRouteObject.id);
      });
    });
  };

  showResequence = () => {
    this.setState({
      rearrangeForm: true,
    });
  };

  assignDriver = (id, _type) => {
    const route = _.find(this.state.routes, { id });
    if (!_.isNil(route) && _.isObject(route)) {
      this.setState({
        selectedRouteObject: route,
        selectedRoute: id,
        // assignModal: true,
      } , () => { this.setState({ assignModal: true }) });
    }
  };

  handleAssignWindowClose = () => {
    this.setState(
        {
          assignModal: false,
        },
        () => {
          this.refreshRoutes();
        }
    );
  };

  handleNotifyDrivers = async () => {
    // const { routeInfo, assignInfo } = this.state;
    // Prepare data to send to the API
    const data = {
      // organization_id: "<organization_id",
      nav_route_ids: [this.state.selectedRoute],
    }
    try {
      // Call the API to re-assign drivers for the selected routes
      const response = await sendNotificationinDispatch(data);
      if (response.success) {
        alertMessage(I18n.t('messages.allocated_successfully'));
      }
      else {
        alertMessage(response.errors[ 0 ], 'error');
      }
    } catch (error) {
      // message.error("An error occurred while assigning drivers for the selected routes.");
      message.error(I18n.t('messages.error_occured_while_assigning_route'))
    }
  };

  renderAssignModal = () => (
      <BaseModal
          title={`Reassign ${I18n.t("general.Driver")}`}
          width="450px"
          onCancel={this.handleAssignWindowClose}
          maskClosable={false}
      >
        <Assignment
            routeId={this.state.selectedRouteObject.id}
            primaryDriver={this.state.selectedRouteObject.primary_driver_id}
            secondaryDriver={this.state.selectedRouteObject.secondary_driver_id}
            truck={this.state.selectedRouteObject.assignedId || ""}
            closeWindow={this.handleAssignWindowClose}
            showTruck={false}
            currentRoute={this.state.selectedRouteObject}
            showExistDrivers
        />
      </BaseModal>
  );

  setRef = (ref) => {
    this.componentRef = ref;
  };

  moveRouteStops = (routeId, orders, routeData) => {
    this.setState({
      inProgress: true,
    });
    const data = {
      nav_route_id: routeId,
      order_ids_to_add: orders,
    };
    updateRouteInMiddle(data).then((result) => {
      if (result.success) {
        this.renderNotification("success", {
          key: routeData.old_nav_route_id,
          message: (
              <span className="fontSize14">{`Stop${
                  routeData.stopName.length > 1 ? "s" : ""
              }: ${routeData.stopName}`}</span>
          ),
          description: (
              <Fragment>
                <div>
                  {routeData.oldRouteName}&nbsp;
                  <Icon type="arrow-right" />
                  &nbsp;{routeData.newRouteName}
                </div>
                <div>{I18n.t("messages.moved_successfully")}</div>
              </Fragment>
          ),
        });
        this.setState(
            {
              selectedRoute: routeId,
              moveProgress: false,
              refreshUnallocated: true,
            },
            () => {
              this.refreshOneRoute(() => {
                this.getRouteStopsInfo(routeId);
              });
            }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          moveProgress: false,
          inProgress: false,
        });
      }
    });
  };

  changeIncludeBolState = () => {
    this.setState({ includeBol: !this.state.includeBol });
  };

  changeIncludeItemsState = () => {
    this.setState({ includeItems: !this.state.includeItems });
  };

  printManifest = () => {
    this.setState({ isGeneratingPdf: true });
    const navRouteId = this.state.selectedRouteObject.nav_route_id;
    const navRouteName = this.state.selectedRouteObject.nav_route_name;
    DispatchesApi.printManifestFile(navRouteId, this.state.includeBol, this.state.includeItems, this.state.includeManifest)
      .then((result) => {
        if (result.success) {
          base64ToPdf(
              result.manifest_file,
              `Manifest_${navRouteName}.pdf`
          );
        } else {
          alertMessage("failed to generate pdf","error", 10)
        }
      })
      .finally(() => {
        this.setState({
          isGeneratingPdf: false,
        });
      });
  };

  renderDownloadContainer = () => (
    <Fragment>
      <BaseModal
        title={`Print ${I18n.t("general.route")}`}
        width="80%"
        style={{ top: 20 }}
        onCancel={this.closeDownloadContainer}
        bodyStyle={{
          height: "calc(100vh - 110px)"
        }}
      >
        {/* <ReactToPrint
          trigger={() => (
            <div className="alignCenter" style={{ cursor: "pointer" }}>
              <Icon type="printer" /> Print
            </div>
          )}
          // onBeforeGetContent={() => this.setImageSpecifications()}
          // onAfterPrint={this.resetImageSettings}
          content={() => this.componentRef}
        /> */}
        <Row gutter={16} type="flex" justify="center" align="middle">
          <Col>
            <Checkbox 
              onChange={(e) => this.handleStateChange('includeManifest', e.target.checked)}
              checked={this.state.includeManifest}
            >
              Manifest
            </Checkbox>
          </Col>
          <Col>
            <Checkbox 
              onChange={(e) => this.handleStateChange('includeBol', e.target.checked)}
              checked={this.state.includeBol}
            >
             {I18n.t('order.bol')}
            </Checkbox>
          </Col>
          <Col>
          <Checkbox 
              onChange={(e) => this.handleStateChange('includeItems', e.target.checked)}
              checked={this.state.includeItems}
            >
             Items
            </Checkbox>
            {/* <label><input type='checkbox' onChange={ this.changeIncludeItemsState } /> Include Items</label> */}
          </Col>
          <Col>
          <Button 
            type="primary" 
            loading={this.state.isGeneratingPdf} 
            icon="printer" 
            onClick={this.printManifest} 
            style={{ marginRight: "10px" }}
            disabled={!this.state.includeBol && !this.state.includeManifest && !this.state.includeItems}
          > 
            Print 
          </Button>
          </Col>
        </Row>

        <Row ref={this.setRef} className="margin10">
          <Col>
            {/* <DownloadContainer
              currentRoute={this.state.selectedRouteObject}
              primaryDriver={this.state.stops}
              organizationSettings={this.props.organizationSettings}
              currentOrg={this.props.currentOrg}
              includeItems={this.state.includeItems}
            /> */}
            <MenifestDownloadContainer
              currentRoute={this.state.selectedRouteObject}
              primaryDriver={this.state.stops}
              organizationSettings={this.props.organizationSettings}
              currentOrg={this.props.currentOrg}
              includeItems={this.state.includeItems}
              displayConfiguration = {this.props.displayConfiguration}
            />
          </Col>
        </Row>
      </BaseModal>
    </Fragment>
  );


  setOrders = (orders) => {
    this.setState({
      unassignedOrders: orders,
      refreshUnallocated: false,
    });
  };
  setUnassignedSelectedInfo = (info) => {
    this.setState({
      unassignedOrdersInfo: {
        selectedKeys: info.selectedOrderKeys,
        selectedRows: info.selectedOrderRows,
      },
    });
  };

  renderUnassignedOrders = (action) => {
    let startTime = "";
    let endTime = "";
    if (action === "currentRoute") {
      startTime =
          this.state.selectedRouteObject &&
          this.state.selectedRouteObject.r_scheduled_start_datetime
              ? moment(
                  this.state.selectedRouteObject.r_scheduled_start_datetime
              ).format("YYYY-MM-DD")
              : moment();
      endTime =
          this.state.selectedRouteObject &&
          this.state.selectedRouteObject.r_scheduled_start_datetime
              ? moment(
                  this.state.selectedRouteObject.r_scheduled_start_datetime
              ).format("YYYY-MM-DD")
              : moment();
    } else {
      startTime =
          this.state.dateFilter && this.state.dateFilter.fromDate
              ? moment(this.state.dateFilter.fromDate).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD");
      endTime = startTime;
    }
    const { selectedRouteObject, unassignedOrdersInfo, warehouse_id, currentView } =
      this.state;

    // dont need to add route to already completed routes
    const noncompletedRoutes = _.filter(this.state.total_routes, (route) => {
      return route.r_status !== "COMPLETED";
    });
    const completedRoutes = _.filter(this.state.total_routes, (route) => {
      return route.r_status === "COMPLETED";
    });
    const assignedDrivers = _.map(noncompletedRoutes, (obj) => {
      const newObj = _.pick(obj, [
        "primary_driver_id",
        "displayName",
        "nav_stops_count",
        "id",
        "status",
      ]);
      newObj.route_id = newObj.id;
      newObj.id = newObj.primary_driver_id;
      newObj.route_status = newObj.status;
      delete newObj.primary_driver_id;
      delete newObj.status;

      return newObj;
    });

    // filter the unassigned drivers from the available drivers
    const unassignedDrivers = this.state.availableDriversInfo.filter((item) => {
      if (
          this.state.total_routes.find(
              (driver) => driver.primary_driver_id === item.id
          )
      ) {
        // if already assigned then remove from the list
        return false;
      }
      return true;
    });
    const pickUnassignedDrivers = unassignedDrivers.map((item) => {
      const { id, employee_code, image } = item;
      return { id, displayName: employee_code || formatFullName(item), image, nav_stops_count: 0 };
    });
    const completedRouteDrivers = _.map(completedRoutes, (obj) => {
      const newObj = _.pick(obj, [
        "primary_driver_id",
        "displayName",
        "nav_stops_count",
        "id",
        "status",
      ]);
      newObj.route_id = newObj.id;
      newObj.id = newObj.primary_driver_id;
      newObj.route_status = newObj.status;
      delete newObj.primary_driver_id;
      delete newObj.status;

      return newObj;
    });
    // concat the assigned and unassigned drivers
    const driversInfo = assignedDrivers.concat(pickUnassignedDrivers).concat(completedRouteDrivers);

    const routes = this.state.total_routes.map((route, routeIndex) => ({
      stop_markers: route.nav_stops
          .filter((stop) => stop.lat_long && stop.lat_long.length > 1)
          .map((stop, index) => ({
            stop_number: stop.stop_order_sequence + 1,
            id: stop.stop_id,
            formatted_stop_name: stop.contact_name,
            lat: stop.lat_long[1],
            lng: stop.lat_long[0],
            ...stop,
          })),
      ...route,
    }));
    return (
        <Fragment>
          {action === "currentRoute" && this.state.selectedRouteObject && (
              <Row>
                <Col xs={8}>
              <span className="textBold">
                {I18n.t("general.Driver")}&nbsp;:&nbsp;
                {this.state.selectedRoute &&
                this.state.selectedRouteObject &&
                this.state.selectedRouteObject.id &&
                _.isObject(this.state.selectedRouteObject.primary_driver)
                    ? selectedRouteObject.primary_driver.employee_code ||
                    `${this.state.selectedRouteObject.primary_driver.first_name} ${this.state.selectedRouteObject.primary_driver.last_name}`
                    : ""}
              </span>
                </Col>
                <Col xs={8} className="alignCenter">
              <span className="textBold">
                {I18n.t("info.start_time")}&nbsp;:&nbsp;
                {selectedRouteObject.route_start_time ? (
                    <Fragment>
                      {momentTime(
                          "MMM, Do",
                          selectedRouteObject.route_start_time,
                          selectedRouteObject.timeZoneId
                      )}
                    </Fragment>
                ) : (
                    "NA"
                )}
              </span>
                </Col>
                <Col xs={8} className="alignRight">
              <span className="textBold">
                {I18n.t("general.status")} :{" "}
                {this.state.selectedRouteObject.nav_route_status}
              </span>
            </Col>
          </Row>
        )}
        <div className="headerOptionDiv">
          <SearchOrders
            isUsingBetaVersion={this.state.isUsingBetaVersion}
            refreshSearchOrders={this.state.refreshSearchOrders}
            actionType={action}
            availableDriversInfo={this.state.availableDriversInfo}
            changesOrdersState={this.changesOrdersState}
            closeSearchOrders={this.closeSearchOrders}
            dateFilter={this.state.dateFilter}
            driversInfo={driversInfo}
            endTime={moment(endTime)}
            fetchavailableDriversInfo={this.fetchavailableDriversInfo}
            getRoutes={() => this.getRoutes(this.state.filter, null, true)}
            refreshUnallocated={this.state.refreshUnallocated}
            route={this.state.selectedRouteObject}
            routeId={this.state.selectedRoute}
            routes={routes}
            setOrders={this.setOrders}
            setUnassignedInfo={this.setUnassignedSelectedInfo}
            showRouteAssigner={this.state.showRouteAssigner}
            showRouteSummary={(route) =>
              this.setState({
                showRouteSummary: true,
                currentRouteSummary: route,
              })
            }
            startTime={moment(startTime)}
            toggleRouteAssigner={this._toggleRouteAssigner}
            unassignedOrdersInfo={unassignedOrdersInfo}
            updateSelectedRouteInfo={() =>
              this.getRouteStopsInfo(this.state.selectedRoute)
            }
            warehouses={this.props.warehouseFilter.warehouses}
            warehouse_id={warehouse_id}
            panelSize={this.state.panelSize}
            onPanelClick={this.onPanelClick}
            vhType={this.state.vehicleTypes}
            dispContext={this.props.dispContext}
          />
          { this.state.panelSize.pane_1 == 0 && 
          <div
            className="alignRight paddingRight10"
            style={{ position: "absolute", bottom: "5px", left: "0px", zIndex: 9 }}
          >
          &nbsp; &nbsp;
          {this.state.showUnassigned && (
            <AddOrdersToRoute
              routes={noncompletedRoutes}
              label={"Allocate"}
              currentSelected={selectedRouteObject.id}
              buttonType="danger"
              closeSearchOrders={this.closeSearchOrders}
              selectedInfo={this.state.unassignedOrdersInfo}
              size="small"
              isDisabled={
                this.state.unassignedOrdersInfo.selectedKeys.length === 0 ||
                noncompletedRoutes.length === 0
              }
              actionType={"routeSelection"}
            />
          )}
        </div>}
        </div>
      </Fragment>
    );
  };

  renderSearchOrders = () => {
    const { showUnassigned, isUsingBetaVersion, showRouteAssigner } = this.state;
    // const action = !showUnassigned ? 'currentRoute' : !showRouteAssigner ? 'routeSelection' : 'routeAssigner'
    return (
        <Fragment>
        {!showUnassigned && isUsingBetaVersion == false ? (
          <BaseModal
            title={`${I18n.t("general.add")} to ${I18n.t("general.route")}`}
            width="90%"
            height="100%"
            bodyStyle={{ padding: 10, height: "88vh" }}
            style={{
                    top: 10,
                    overflowY: "hidden",
                    height: "100%",
                  }}
                  onCancel={this.closeSearchOrders}
              >
                <div
                    className="content"
                    style={{ padding: "10px 20px", marginLeft: -20 }}
                >
                  {this.renderUnassignedOrders("currentRoute")}
                </div>
              </BaseModal>
          ) : (
              this.renderUnassignedOrders("routeSelection")
          )}
        </Fragment>
    );
  };

  navigateToRoute = (routeId) => {
    const routeCard = document.querySelector(`.routeCard-${routeId}`);
    if (routeCard) {
      routeCard.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  };

  onPanelClick = (value) => {
    const { panelSize } = this.state;
    if (value === "pane_1") {
      if (panelSize.pane_1 === 1) {
        this.setState({
          panelSize: {
            pane_1: MAP_PANEL_SIZE,
            pane_2: STOPLIST_PANEL_SIZE
          }
        });
      } else {
        this.setState({
          panelSize: {
            pane_1: 1,
            pane_2: 0
          }
        });
      }
    }
    if (value === "pane_2") {
      if (panelSize.pane_2 === 1) {
        this.setState({
          panelSize: {
            pane_1: MAP_PANEL_SIZE,
            pane_2: STOPLIST_PANEL_SIZE
          }
        });
      } else {
        this.setState({
          panelSize: {
            pane_1: 0,
            pane_2: 1
          }
        });
      }
    }
  };
  onResize = (e) => {
    if (e.domElement) {
      e.domElement.classList.add("resizing");
    }
  };

  onStopResize = (e) => {
    if (e.domElement) {
      e.domElement.classList.remove("resizing");
    }
  };


  //Refreshing Unallocated orders count and Unallocated orders list
  refreshDispatchOrders = () =>{
    this.getOrders();
    if (this.state.showUnassigned) {
      this.setState({ refreshSearchOrders: !this.state.refreshSearchOrders });
    }
  }

  updateOperation = (brodcastMsg, receivedSource) => {
    const event = brodcastMsg.source
    const operation = brodcastMsg.action;
    const subOperation = brodcastMsg.sub_action;
    const currentRouteID = this.state.selectedRouteObject.nav_route_id;

    if (event === "ROUTE") {
      const routeStatus =
        brodcastMsg?.properties &&
        brodcastMsg.properties.length &&
        brodcastMsg.properties[0].status
          ? brodcastMsg.properties[0].status
          : "";

      if (routeStatus === "ALLOCATED") {
        if (!["ROUTE_OPTIMIZED", "ORDER_UPDATED", "ROUTE_REVERTED"].includes(subOperation)){
          this.refreshDispatchOrders();
        }
        if(["ROUTE_REVERTED","ORDER_DELETED", "ORDER_ADDED"].includes(subOperation)){
          this.refreshRoutes();
        }
      }
      else {
        if (["CREATED", "DELETED"].includes(operation)) {
          this.refreshRoutes();
          this.refreshDispatchOrders();
        }
        if (operation === "UPDATED") {
          if (["ROUTE_ASSIGNED", "SWAPPED", "TRANSMIT_ORDERS", "TRANSMIT_STOP"].includes(subOperation)) {
            this.refreshRoutes();
          }
          else if(["STOP_DELETED", "STOP_ADDED"].includes(subOperation)){
            this.refreshOneRoute(null, brodcastMsg.source_ids[0]);
            if (currentRouteID === brodcastMsg.source_ids[0]) {
              this.getRouteStopsInfo(brodcastMsg.source_ids[0]);
            }
          }
          else if (currentRouteID === brodcastMsg.source_ids[0] && !["DRIVER_REASSIGNED", "STATUS_CHANGED"].includes(subOperation)) {
            this.getRouteStopsInfo(brodcastMsg.source_ids[0]);
          } 
          else {
            this.refreshOneRoute(null, brodcastMsg.source_ids[0]); 
          }
          
          if (["STOP_ADDED", "STOP_DELETED"].includes(subOperation)) {
            this.refreshDispatchOrders();
          }
        }
      }
    } 
    
    else if (event === "STOP") {
      const navRouteId =
        brodcastMsg?.properties?.length &&
        brodcastMsg.properties[0].nav_route_id
          ? brodcastMsg.properties[0].nav_route_id
          : "";

      if(subOperation === "STATUS_CHANGED"){
        this.refreshOneRoute(null, navRouteId);
        if (currentRouteID === navRouteId) {
          // this.getRouteStopsInfo(navRouteId);
          this.updateStopsInfo(navRouteId, brodcastMsg.source_ids)
        }
      }
      else {
        if (currentRouteID === navRouteId) {
          this.getRouteStopsInfo(navRouteId);
        } 
        else {
          this.refreshOneRoute(null, navRouteId);
        }
      }
      this.refreshDispatchOrders();
    }

    else  if (event === "PREPLAN" && operation === 'DELETED' ){
      this.refreshRoutes();
      this.refreshDispatchOrders();
    }
    
    else if (event === "ORDER") {
      this.refreshDispatchOrders();
    }
  };

  render() {
    const { panelSize } = this.state;
    return (<div className="content-outer dispatch_screen">
      <this._renderHeader />
      <DragDropContext onDragEnd={ this.onDragEnd }>
        <Row>
          <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 5 }>
            <this._renderRoutesList />
          </Col>
          <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 6 }>
            <this._renderStopsList />
          </Col>
          <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 13 }>
            <div style={ { height: "calc(100vh - 132px)" } }>
              <ReflexContainer orientation="horizontal">
                <ReflexElement
                  flex={ panelSize.pane_1 }
                  onStopResize={ this.onStopResize }
                  onResize={ this.onResize }
                  direction={ 1 }
                  propagateDimensionsRate={ 200 }
                  propagateDimensions={ true }
                  style={ { overflow: "hidden" } }
                >
                  <div className="pane-content">
                    { this._renderMapView() }
                  </div>
                </ReflexElement>

                <ReflexSplitter />

                <ReflexElement
                  flex={ panelSize.pane_2 }
                  onStopResize={ this.onStopResize }
                  onResize={ this.onResize }
                  direction={ -1 }
                  propagateDimensionsRate={ 200 }
                  propagateDimensions={ true }
                  style={ { overflow: "hidden" } }
                >
                  <div className="pane-content">
                    {this.state.showUnassigned || this.state.isUsingBetaVersion ? (
                      this.renderSearchOrders()
                    ) : (
                      <this._renderStopsInfo />
                    ) }
                  </div>
                </ReflexElement>
              </ReflexContainer>
              {/* { this.renderSearchOrders() } */}
            </div>
          </Col>
        </Row>
      </DragDropContext>

      { this.state.rearrangeForm && this.renderSequenceChangeForm() }
      { this.state.assignModal && this.renderAssignModal() }
      { this.state.downloadContainer && this.renderDownloadContainer() }
      { this.state.showReasonForm && this.renderRouteCompleteModal() }
      { this.state.reasonObject.show && this.renderReasonModal() }
      { this.state.searchOrders && !this.state.showUnassigned && this.renderSearchOrders() }
      { this.state.showLocations && this.openLocationsWindow() }
      { this.state.showRouteSummary && (
        <Drawer
          title="Route Summary"
          placement="right"
          closable
          onClose={ () => {
            this.setState({
              showRouteSummary: false,
              currentRouteSummary: {},
            });
          } }
          visible={ this.state.showRouteSummary }
          width="300px"
        >
          <RouteSummary
            currentRoute={ this.state.currentRouteSummary }
            // here id is route id
            selectRoute={ ({ id }) => {
              this.changesOrdersState(false);
              this.setState(
                {
                  showRouteSummary: false,
                  currentRouteSummary: {},
                  warehouse_id: "",
                },
                () => {
                  // refresh routes
                  this.getRoutes(this.state.filter);
                  this.getRouteStopsInfo(id, "", () => {
                    this.navigateToRoute(id);
                  });
                }
              );
            } }
          />
        </Drawer>
      ) }
        <ActionCable
          currentDate={this.state.dateFilter.fromDate}
          currentWhId={this.state.warehouse_id}
          updateOperation={this.updateOperation}
        />
    </div>

    );

  }
}

export const Dispatches = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings, isAirCargo  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const currentUser = useContext(UserContext);
  const { currentOrg } = currentUser;
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);

  return (
    <DispatchesComponent
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      currentOrg={currentOrg}
      orderConfig={orderConfig}
      isAirCargo={isAirCargo}
      {...props}
    />
  );
});


// default export
export default Dispatches;