import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";

export const OrganizationApi = {
  fetch: () => {
    const url = ApiUrl(
      `v2/organization?page=1&per_page=20&operation_code=ORGI&current_role=super_admin`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "organizations",
      },
    });
  },
};

export const MappingApi = {
  fetch: (org_id, account_id, fileType) => {
    const url = ApiUrl(
      `v2/csv_column_mappings?organization_id=${org_id}&account_id=${account_id}&type_of_file=${fileType}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "csv_column_mappings",
      },
    });
  },
};
