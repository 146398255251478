import React, { useContext, useEffect } from "react";
import { Col, Row, Select, Typography } from "antd";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import I18n from "../../common/I18n";
const { Text } = Typography;

export const RecordsPerPage = (props) => {
  const { showInfinite , onChange, onSearch, value, defaultValue, isDisabled , isUsingBetaVersion } = props;
  let recordsPerPage = [ 10, 20, 40, 60, 80, 100 ];
  if (showInfinite) {
    // add a thousand records option
    recordsPerPage = [ 10, 20, 40, 60, 80, 100 , 200 , 300 , 400 ];
  }
  if(isUsingBetaVersion){
    recordsPerPage = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
  }

  const finalDefaultValue = defaultValue || isUsingBetaVersion ? 100 : 20;

  // useEffect(() => {
  //   if (currentUser && currentUser.per_page_saved) {
  //     onChange(currentUser.per_page_saved.main_order_listing);
  //   }
  // }
  //   , [ currentUser.per_page_saved ]);

  return (
    <div>
      <Row type="flex" align="middle" gutter={ 5 }>
        <Col>
          <Text strong>
          {I18n.t('general.records_per_page')}
          </Text>
        </Col>
        <Col>
          <Select
            style={ { width: 65 } }
            // showSearch
            // allowClear={ true }
            onChange={ onChange }
            onSearch={ onSearch }
            defaultValue={ finalDefaultValue }
            value={ value }
            size="small"
            disabled={isDisabled}
          >
            { recordsPerPage.map((item) => (
              <Select.Option key={ item } value={ item }>
                { item }
              </Select.Option>
            )) }
          </Select>
        </Col>
      </Row>
    </div>
  );
};

RecordsPerPage.defaultProps = {
  value: 20,
  onSearch: () => { },
  onChange: () => { },
  showInfinite: false,
  isDisabled: false
};

RecordsPerPage.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  showInfinite: PropTypes.bool,
  isDisabled: PropTypes.bool
};