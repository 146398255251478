import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Divider,
  FormItem,
  Input,
  Row,
  Upload,
  Select,
  Spin,
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  formatCode,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import {
  saveOrganization,
  fetchOrganizationDetails,
} from "../../api/Organisations";
import FormErrors from "../common/FormErrors";
import AddressForm from "../common/AddressForm";
import { loadGoogleMaps } from "../../utils/Utils";
import MobileInput from "../common/MobileInput";
import imagePlaceholder from "../../../assets/images/logo_placeholder.png";
import { fetchPackages } from "../../api/Packages";
import AddressSearch from "../../stories/components/AddressSearch";
import AppConfig from "../../config/AppConfig";
import userStore from "../../stores/UserStore";
import { renderAlertMessage } from "../../helpers/common";

const { TextArea } = Input;
const imgProps = { height: 100, width: "100%" };

const requiredFields = [
  {
    form_field: "name",
    ui_name: I18n.t("general.name"),
    isRequired: true,
    inputType: "name",
  },
  {
    form_field: "code",
    ui_name: I18n.t("general.code"),
    isRequired: true,
    inputType: "code",
  },
  {
    form_field: "phone_number",
    ui_name: I18n.t("general.contact_no"),
    isRequired: false,
    inputType: "mobile",
  },
  {
    form_field: "email",
    ui_name: I18n.t("general.email"),
    isRequired: false,
    inputType: "email",
  },
  {
    form_field: "website",
    ui_name: I18n.t("general.website"),
    isRequired: false,
    inputType: "url",
  },
  {
    form_field: "carrier_type",
    ui_name: "Carrier Type",
    isRequired: false,
    inputType: "carrier_type",
  },
];

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: {},
      map: false,
      isNew: this.props.isNew || false,
      inProgress: false,
      errors: [],
      orgId:
        !this.props.isNew && this.props.organization
          ? this.props.organization
          : "",
      packages: [],
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOnAddressChange = this.handleOnAddressChange.bind(this);
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.setMapObject();
    } else {
      this.getOrgDetails(() => {
        this.setMapObject();
      });
    }
    this.getPackages();
  }

  getPackages = (cb = null) => {
    this.setState({ inProgress: true });
    fetchPackages(1, null).then((result) => {
      if (result.success) {
        const packages = result.packages || [];
        this.setState(
          {
            packages,
            pagination:
              packages.length > 0 && result.pagination ? result.pagination : {},
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        // alertMessage(result.errors[0], 'error', 10);
        this.setState({ inProgress: false });
      }
    });
  };

  getOrgDetails = (cb) => {
    this.setState({ inProgress: true });
    if (!isEmpty(this.state.orgId)) {
      fetchOrganizationDetails(this.state.orgId).then((result) => {
        if (result.success) {
          const organization = result.organization || {};
          // organization.logo =
          //   !isEmpty(organization.logo) && organization.logo.url
          //     ? organization.logo.url
          //     : "";
          this.setState(
            {
              organization,
              inProgress: false,
            },
            () => {
              if (cb) {
                cb();
              }
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    }
  };

  modifyInputData = () => {
    // const { organization } = this.state;
    // this.setState({
    //   organization,
    // }, () => {
    //   this.setMapObject();
    // });
  };

  setMapObject = () => {
    loadGoogleMaps(() => {
      this.setState({
        map: true,
      });
    });
  };

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (image) =>
      this.setState({
        logo: image,
      })
    );
  };

  getBase64 = (img, callback) => {
    const data = Object.assign({}, this.state.organization, { logo: img });
    this.setState({
      organization: data,
    });
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleOnChange(element, value) {
    this.setState({
      organization: Object.assign({}, this.state.organization, {
        [element]: value,
      }),
    });
  }

  handleMobileChange = (element, value) => {
    this.setState({
      organization: Object.assign({}, this.state.organization, {
        [element]: value,
      }),
    });
  };

  handleOnAddressChange(element, value, obj) {
    const address = this.state.organization.address || {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign(address, obj);
    } else if (element) {
      newAddress[element] = value;
    }
    this.setState({
      organization: Object.assign({}, this.state.organization, {
        address: newAddress,
      }),
    });
  }

  handleSave() {
    this.setState({ inProgress: true });
    const data = this.state.organization;
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      data.code = formatCode(data.code);
      this.setState({ inProgress: false });
      saveOrganization(this.state.isNew, data).then((result) => {
        if (result.success) {
          this.setState({ inProgress: false, errors: [] });
          this.props.onSuccess(I18n.t("messages.saved"));
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  }

  render() {
    const { organization } = this.state;
    const logo = this.state.logo || this.state.organization.logo;
    return (
      <div className="padding20 borderLine paddingBottom20">
        <Spin spinning={this.state.inProgress} delay={1000}>
        <Row gutter={32}>
          <Col md={18}>
            <Row gutter={16}>
              <Col md={12}>
                <FormItem label={I18n.t("general.name")} require>
                  <Input
                    value={organization.name}
                    onChange={(e) =>
                      this.handleOnChange("name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={12}>
                <FormItem label={I18n.t("general.code")} require>
                  <Input
                    value={organization.code}
                    onChange={(e) =>
                      this.handleOnChange("code", e.target.value)
                    }
                    disabled={!this.state.isNew}
                  />
                </FormItem>
              </Col>
              <Col md={8}>
                <FormItem label={I18n.t("general.email")}>
                  <Input
                    value={organization.email}
                    onChange={(e) =>
                      this.handleOnChange("email", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={8}>
                <FormItem label={I18n.t("general.contact_no")}>
                  <MobileInput
                    handleInputChange={(value, data) => {
                      this.handleMobileChange("phone_number", value, data);
                    }}
                    value={organization.phone_number || ""}
                  />
                </FormItem>
              </Col>
              <Col md={8}>
                <FormItem label={I18n.t("general.website")}>
                  <Input
                    value={organization.website}
                    onChange={(e) =>
                      this.handleOnChange("website", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={8}>
               <FormItem label = {"Carrier Type"}>
                <Select  key="carrier_type"
                  value={organization.carrier_type}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                    style={{ width: '100%' }}
                    onChange={(value) =>
                      this.handleOnChange("carrier_type", value)
                    }
                    size="medium"
                    disabled={!userStore.superAdmin()}
                >
                  <Option key="carrier_type" value="">-- Select --</Option>
                  <Option value="FINAL_MILE">{AppConfig.carrierTypes.FINAL_MILE}</Option>
                  <Option value="AIR_CARGO">{AppConfig.carrierTypes.AIR_CARGO}</Option>
                </Select>
            </FormItem>
          </Col>
              <Col md={24}>
                <FormItem label={I18n.t("organization.description")}>
                  <TextArea
                    rows={3}
                    onChange={(e) =>
                      this.handleOnChange("description", e.target.value)
                    }
                    value={organization.description}
                    placeholder={I18n.t("organization.description")}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            {/* <FormItem label={I18n.t("package.label")}>
              <Select
                value={organization.package_id}
                showSearch
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.handleOnChange("package_id", e)}
              >
                <Select.Option key={"all"} value="">
                  {" "}
                  -- Select --{" "}
                </Select.Option>
                {this.state.packages.map((packag) => (
                  <Select.Option key={packag.id} value={packag.id}>
                    {packag.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem> */}
            <FormItem label={I18n.t("general.image")}>
              <Upload showUploadList={false} onChange={this.handleChange} accept="image/png, image/jpeg">
                {organization.logo && !this.state.logo && (
                  <div className="borderRadius">
                    <img
                      src={logo.url || imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {this.state.logo && (
                  <div className="borderRadius">
                    <img
                      src={logo || imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {!this.state.logo && !this.state.organization.logo && (
                  <div className="borderRadius">
                    <img
                      src={imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
              </Upload>
            </FormItem>
          </Col>
        </Row>
        <Divider orientation="left">{I18n.t("general.address")}</Divider>
        <Row gutter={16}>
          <Col md={24}>
            {this.state.map &&
            (this.state.isNew ||
              Object.keys(this.state.organization).length) ? (
          <>
              <AddressSearch onSelect={ (address) => this.handleOnAddressChange("", "", address.l_address)} />
              <AddressForm
                autoComplete
                address={organization.address}
                onChange={this.handleOnAddressChange}
                isAdmin
              />
          </>
            ) : (
              <Fragment />
            )}
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel ? this.props.onCancel : null,
              this.props.onCancel ? true : false,
            )}
          </Col>
        </Row>
        </Spin>
      </div>
    );
  }
}

Form.propTypes = {
  organization: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Form.defaultPropTypes = {
  organization: {},
  isNew: true,
};

export default Form;
