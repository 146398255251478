import React from 'react';

import BaseList from '../BaseList';

import I18n from '../../common/I18n';import { Divider, Icon, Link, Popconfirm } from '../../common/UIComponents';
import CustomerOrderDetails from '../orders/CustomerOrderDetails';
import BranchesIcon from '../common/BranchesIcon';
import CopyableContent from '../common/CopyableContent';
import { isEmpty } from '../../common/Common';
class OrdersList extends BaseList {
  constructor(props) {
    super();
    this.columns = [
      {
        key: 'customer_order_no',
        title: I18n.t('support.order_no_search'),
        dataIndex: 'customer_order_no',
        render: (order_no, data) => (
          <div>
            <CustomerOrderDetails
              key={order_no}
              order_no={order_no}
              order={{
                id: data.order_id,
                customer_order_number: order_no,
              }}
              showEdit={false}
            />
          </div>
      )
      },
      {
        key: 'order_id',
        title: I18n.t('support.order_id_search'),
        dataIndex: 'order_id',
        render: (data, record) => <CopyableContent text={data} showTooltip />
      },
      {
        key: 'status',
        title: I18n.t('general.status'),
        dataIndex: 'status'
      },
      {
        title: I18n.t('notify_order_status_code.pod'),
        key: 'pod',
        dataIndex: 'pod_generated',
        render: (pod, data) => (
          <span>
            { data.status === 'COMPLETED' &&
              <span key={`spanPOD${data.order_id}`}>
                <Icon type="check-circle" theme="twoTone" twoToneColor={pod ? '#52c41a' : 'grey'} />
                <Divider type="vertical" />
                <Popconfirm
                placement="rightTop"
                title={I18n.t('messages.confirm')}
                onConfirm={() => this.props.generateClick(data.order_id, 'POD')}
                okText="Yes"
                cancelText="No"
              >
                <Link key={`POD${data.order_id}`}>Re generate</Link>
                </Popconfirm>
              </span>
            }
          </span>
        ),
      },
      {
        title: I18n.t('notify_order_status_code.del'),
        key: 'del',
        dataIndex: 'del_generated',
        render: (del, data) => (
          <span>
            { data.status === 'COMPLETED' &&
              <span key={`spanDEL${data.order_id}`}>
                <Icon type="check-circle" theme="twoTone" twoToneColor={del ? '#52c41a' : 'grey'} />
                <Divider type="vertical" />
                <Popconfirm
                placement="rightTop"
                title={I18n.t('messages.confirm')}
                onConfirm={() => this.props.generateClick(data.order_id, 'DEL')}
                okText="Yes"
                cancelText="No"
              >
                <Link key={`DEL${data.order_id}`}>Re generate</Link>
                </Popconfirm>
              </span>
            }
          </span>
        ),
      },
      {
        title: I18n.t('general.route'),
        key: 'delink',
        dataIndex: 'nav_route_id',
        render: (routeId, data) => (
          <span>
            { !isEmpty(routeId) &&
              <span>
                <BranchesIcon/>&nbsp;
                <Popconfirm
                    placement="leftTop"
                    title={I18n.t('messages.confirm')}
                    onConfirm={() => this.props.delinkRoute(data.order_id)}
                    okText="Yes"
                    cancelText="No"
                >
                <Link>De Link</Link>
                </Popconfirm>
              </span>
            }
          </span>
        ),
      },
    ];
  }
}

export default OrdersList;
