import { Col, Icon, Popover, Row } from "antd";
import React from "react";
import BaseList from "../components/BaseList";
import Copyable from "../components/common/Copyable";
import { formatByTimeConfig } from "../helpers/common";
import { momentTime } from "../helpers/date_functions";
import { OrderNumberList } from "../components/dispatches/helpers";
import I18n from "../common/I18n";

class TemplateActivityLogsList extends BaseList {
  constructor (props) {
    super(props);

    this.columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        align: "center",
      },
    ];

    const recurringRouteCols = [
      {
        key: "route_name",
        title: I18n.t("templates.route_name"),
        dataIndex: "route_name",
        align: "center",
        render: (data) => data,
      },
      {
        key: "scheduled_on",
        title: I18n.t("templates.scheduled_on"),
        dataIndex: "scheduled_date",
        align: "center",
        render: (data) =>
          data
            ? `${formatByTimeConfig(
              momentTime("", data, props.timeZone),
              props.isMilitaryTime,
              "MMM Do, YYYY HH:mm",
              "MMM Do, YYYY hh:mm A"
            )} ${props.timeZoneName ? ` ${props.timeZoneName}` : ""
            }`
            : "",
      },
      {
        key: "scheduled_for",
        title: I18n.t("templates.scheduled_for"),
        dataIndex: "route_scheduled_date",
        align: "center",
        render: (data) =>
          data
            ? `${formatByTimeConfig(
              momentTime("", data, props.timeZone),
              props.isMilitaryTime,
              "MMM Do, YYYY HH:mm",
              "MMM Do, YYYY hh:mm A"
            )} ${props.timeZoneName ? ` ${props.timeZoneName}` : ""
            }`
            : "",
      },
      {
        key: "order_number",
        // title: I18n.t("order.order_no"),
        title: `Order No's`,
        dataIndex: "customer_order_numbers",
        align: "center",
        width: 200,
        render: (data, record) => {
          // const maxDisplayedOrders = 5; // You can adjust the number as needed
          const orderNumbers = data.map((orderNumber, index) => (
            <div key={ orderNumber }>
              { orderNumber }
              <Copyable text={ orderNumber } />
            </div>
          ));

          // const remainingOrderNumbers = data.slice(maxDisplayedOrders);
          const content = (
            <div>
              { orderNumbers }
              {/* { remainingOrderNumbers.length > 0 && (
                <div>
                  <strong>And { remainingOrderNumbers.length } more...</strong>
                </div>
              ) } */}
            </div>
          );

          return (
            <Col>
            <Row type="flex" gutter={4} justify="end" className="paddingRight30">
              <OrderNumberList data={ data } />
            </Row>
            </Col>
          );
        }
      },
      {
        key: "driver_code",
        title: I18n.t("los.driver_code"),
        dataIndex: "driver_code",
        align: "center",
        render: (data) => data,
      },
    ];

    const recurringOrderCols = [
      {
        key: "scheduled_on",
        title: I18n.t("templates.scheduled_on"),
        dataIndex: "created_at",
        align: "center",
        render: (data) =>
          data
            ? `${formatByTimeConfig(
              momentTime("", data, props.timeZone),
              props.isMilitaryTime,
              "MMM Do, YYYY HH:mm",
              "MMM Do, YYYY hh:mm A"
            )} ${props.timeZoneName ? ` ${props.timeZoneName}` : ""
            }`
            : "",
      },
      {
        key: "order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "order_number",
        align: "center",
        render: (data, record) => {
          return (
            <Row type="flex" justify="center" align="middle" gutter={ 8 }>
              <Col>{ data }</Col>
              <Col>
                <Icon
                  type="form"
                  onClick={ () => this.props.handleEditOrder(record.order_id) }
                  style={ { color: "#1890ff" } }
                />
              </Col>
              <Col>
                <Copyable text={ data } />
              </Col>
            </Row>
          );
        },
      },
    ];
    if (props.currentStat !== 'ORDERS') {
      this.columns = this.columns.concat(recurringRouteCols);
    } else {
      this.columns = this.columns.concat(recurringOrderCols);
    }
  }
}
export default TemplateActivityLogsList;
