import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, Spin, Tooltip, Tabs, Divider } from '../../common/UIComponents'
import { alertMessage, checkNegitive,  isEmpty } from '../../common/Common'
import I18n from '../../common/I18n';
import userStore from '../../stores/UserStore'
import BaseModal from '../BaseModal'
import { checkServiceExistance, formatFullName, renderAlertMessage } from '../../helpers/common'
import DueUpdateForm from './DueUpdateForm'
import { creditLimitApi } from '../../api/CreditLimitApi'
import OrderBillingDetails from './OrderBillingdetails'
import PaymentHistory from './PaymentHistory'

const { TabPane } = Tabs

const OrderBalanceSheet = ({
  handleCancel,
  handleSuccess,
  className,
  currentCreditLimit = {},
  account = {},
  currentOrderRec = {},
  refreshList = () => {},
}) => {
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const zeroValue = Number((0).toFixed(decimalPoints));
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState('details')
  const [invoiceData, setInvoiceData] = useState({})
  const [paymentHistory, setPaymentHistory] = useState([])
  const [historyLoading, setHistoryLoading] = useState([])
  const [creditLimitRec, setCreditLimitRec] = useState({...currentCreditLimit})
  const [orderRec, setOrderRec] = useState({...currentOrderRec})

  const refreshData = (newData) => {
    setCreditLimitRec(newData.account_credit_limit ? newData.account_credit_limit : {});
    setOrderRec(newData.orderRec ? newData.orderRec : {});
    getPaymentHistory()
  }
  
  const getOrderDueDetais = () => {
    setLoading(true)
    const data = {
      account_id: orderRec.account_id,
      organization_id: orderRec.organization_id,
      account_invoice_id: orderRec.account_invoice_id,
    }
    creditLimitApi.fetchOrderDueDetails(data).then((result) => {
      if (result.success) {
        const responseData = result.invoice_lines ? [...result.invoice_lines] : [];
        setInvoiceData({
          total_amount: result.total_amount ? parseFloat(result.total_amount) : zeroValue,
          invoice_lines: responseData,
        })
        setLoading(false)
      } else {
        renderAlertMessage(result.errors)
        setLoading(false)
        setInvoiceData({
          total_amount: zeroValue,
          invoice_lines: [],
        })
      }
    })
  }

  const getPaymentHistory = () => {
    setHistoryLoading(true)
    const data = {
      account_id: orderRec.account_id,
      organization_id: orderRec.organization_id,
      account_invoice_id: orderRec.account_invoice_id,
    }
    creditLimitApi.fetchPaymentHistory(data).then((result) => {
      if (result.success) {
        const responseData = result.order_credit_due_activities ? [...result.order_credit_due_activities] : [];
        setPaymentHistory(responseData)
        setHistoryLoading(false)
      } else {
        renderAlertMessage(result.errors)
        setHistoryLoading(false)
      }
    })
  }

  const resetValues = (closeModal = false) => {
    setLoading(false)
    setInvoiceData([])
    setShowInfoWindow(false)
    refreshList()
  }

  const setInitialData = () => {
    setShowInfoWindow(true)
    getOrderDueDetais()
    getPaymentHistory()
  }

  const renderModalWindow = () => {
    const dueAmount = orderRec.out_standing_amount ? parseFloat(orderRec.out_standing_amount) : ''
    return (
      <BaseModal
        title={
          <Fragment>
            {I18n.t('account.credit_limit.due_details')} of{' '}
            {orderRec.invoice_number}
            &nbsp;({account.name})
          </Fragment>
        }
        className="customer-orders-modal"
        onCancel={() => {
          resetValues()
        }}
        width={'100%'}
        style={{
          top: 0,
          height: '100%',
        }}
        bodyStyle={{
          height: 'calc(100vh - 50px)',
          overflowY: 'scroll',
        }}
      >
        <Spin spinning={loading}>
          <Tabs
            activeKey={activeKey}
            onChange={(key) => setActiveKey(key)}
            type="card"
            tabBarExtraContent={
              <span className='textBold fontSize20'>
                {I18n.t('account.credit_limit.out_standing_amount')}:{' '}
                 <span className={`textBold fontSize20 ${dueAmount ? 'textRed' : ''}`}>{checkNegitive(dueAmount)}</span>
              </span>
            }
          >
            <TabPane tab={I18n.t('general.details')} key="details">
              { dueAmount > 0 && <DueUpdateForm
                currentCreditLimit={creditLimitRec}
                account={account}
                currentOrderRec={orderRec}
                refreshData={refreshData}
              />
              }
             
              <div>
                <OrderBillingDetails invoiceData={invoiceData} pagination={{ position: 'none'}}/>
              </div>
            </TabPane>
            <TabPane
              tab={I18n.t('account.credit_limit.payment_history')}
              key="payment_history"
            >
              <spin spinning={historyLoading}>
                <PaymentHistory data={paymentHistory} pagination={{ position: 'none'}} />
              </spin>
            </TabPane>
          </Tabs>
        </Spin>
      </BaseModal>
    )
  }

  return (
    <span>
      <Tooltip
        title={
          <Fragment>{I18n.t('account.credit_limit.invoice_details')}</Fragment>
        }
        placement="top"
      >
        <Icon
          type="dollar"
          onClick={setInitialData}
          className={className ? className : ''}
        />
      </Tooltip>
      {showInfoWindow && renderModalWindow()}
    </span>
  )
}

OrderBalanceSheet.propTypes = {
  currentCreditLimit: PropTypes.shape().isRequired,
  currentOrderRec: PropTypes.shape().isRequired,
  account: PropTypes.shape().isRequired,
}

export default OrderBalanceSheet
