import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Col, Dropdown, Row, Icon, Button, Drawer, Popover, Tooltip, Result, Modal } from 'antd';
import { LineHaulApi } from '../../api/LineHaulApi';
import { alertMessage, isEmpty } from '../../common/Common';
import AppliedFiltersTags from '../AppliedFiltersTags';
import LinehaulStats from './LinehaulStats';
import LineHaulList from './LineHaulList';
import AppConfig from '../../config/AppConfig';
import { Link, Search } from '../../common/UIComponents';
import { checkServiceExistance, convertToTags, getOrderInfo, limitString, removeSpecialCharacters, renderAlertMessage } from '../../helpers/common';
import BaseModal from '../../components/BaseModal';
import { LinehaulRouteForm } from './LineHaulForm';
import { withRouter } from 'react-router';
import { WarehouseContext } from '../../context/WarehouseContext';
import { UserContext } from '../../context/UserContext';
import { fetchShortFormAccounts } from '../../api/Account';
import LhFilter from './LhFilter';
import { fetchUsers } from '../../api/UsersApi';
import UsersList from '../../components/preplan/rows/UsersList';
import { DisplaySettingsContext } from '../../context/DisplaySettings';
import { getCurrentOrderInfo } from '../../helpers/common';
import EditOrderFormComponent from '../../components/orders/EditOrderForm';
import ChangeStatusForm from '../../components/recoveries/ChangeStatusForm';
import LineHualActions from './LineHualActions';
import LhChangeStatus from './LhChangeStatus';
import ReasonWindow from '../../components/common/ReasonWindow';
import moment from 'moment';
import { navigateToRoute } from '../../helpers/routes';
import { RecordsPerPage } from '../../components/orders/RecordsPerPage';
import { defaultSearchLHFilter } from '../../components/dispatches/helpers';
import { addAnyStopToRoute } from '../../api/Routes';
import { OrgContext } from '../../context/OrgContext';
import { deleteMultipleOrder } from '../../api/OrdersApi';
import I18n from '../../common/I18n';
import { ScreenKeys } from '../constants';
import { OrderConfigContext } from '../../context/OrderConfigContext';
import _ from "lodash";

const defaultStatus = 'pending';
let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };

function Linehaul (props) {
  
  const { reqFrom = "menu",  route = null } = props;
  const isDispatchScreen = reqFrom === "dispatch";
  const [ searchKeyPress, setSearchKeyPress ] = useState(false);
  const [ lhData, setLhData ] = useState([]);
  const [ isfetchingLhs, setIsfetchingLhs ] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false)
  const [ statsData, setStatsData ] = useState({});
  const [ searchBy, setSearchBy ] = useState('order_number');
  const [ searchText, setSearchText ] = useState('');
  const [ appliedFiltersTags, setAppliedFiltersTags ] = useState([]);
  const [ currentStatus, setCurrentStatus ] = useState(defaultStatus);
  const [ updateSearchTags, setUpdateSearchTags ] = useState(false);
  const [ pagination, setPagination ] = useState({ current_page: 1 });
  const [ showFilterWindow, setShowFilterWindow ] = useState(false);
  const [ showLinehaulModal, setShowLinehaulModal ] = useState(false);
  const [ selectedAccount, setSelectedAccount ] = useState(null);
  const { orgSettings: organizationSettings } = props?.appContexts?.orgContext ? props.appContexts.orgContext : {}
  const displayConfiguration = useContext(DisplaySettingsContext)
  const[dispSetting, setDispSetting] = useState({})
  const isMilitaryTime = organizationSettings?.is_military_time === 'true';
  const [ selectedInfo, setSelectedInfo ] = useState({
    selectedKeys: [],
    selectedRows: [],
  });
  const [ reasonValue, setReasonValue ] = useState('');
  const [ showEditOrderDrawer, setShowEditOrderDrawer ] = useState(false);
  const [ routeDetails, setRouteDetails ] = useState({});
  const [ showRouteSummaryModal, setShowRouteSummaryModal ] = useState(false);
  const warehouseFilter = useContext(WarehouseContext);
  const { selectedWarehouses , warehouses } = warehouseFilter;
  const currentUser = useContext(UserContext);
  const { currentOrg , updateRecordsPerPage , per_page_saved } = currentUser;

  const otherWarehouses = warehouses.filter((wh) => wh.id !== (Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses)); 
  const destinationPoint = otherWarehouses?.[ 0 ]?.id || '';
  const defaultFilter = defaultSearchLHFilter(props, selectedWarehouses, destinationPoint);
  const [ filterPlaceHolder, setFilterPlaceHolder ] = useState({ ...defaultFilter });
  const [ showChangeStatusModal, setShowChangeStatusModal ] = useState(false)
  const [isSearching, setIsSearching] = useState(false);
  const [ accounts, setAccounts ] = useState([]);
  const [ isfetchingAccounts, setIsfetchingAccounts ] = useState(false);
  const [ drivers, setDrivers ] = useState([]);
  const [ isfetchingDrivers, setIsfetchingDrivers ] = useState(false);
  const perPageKey = ScreenKeys.LINEHAUL_LISTING;
  const recordsPerPageSaved = _.get(currentUser, `per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved)
  const [showEditForm, setShowEditForm] = useState(false)
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);

  const dynamicDateObject = {
      fromDate: moment(filterPlaceHolder.dateSearchValue.fromDate).format(
        "YYYY-MM-DD"
      ),
      toDate: moment(filterPlaceHolder.dateSearchValue.toDate).format(
        "YYYY-MM-DD"
      ),
    };

    const defaultDateObject = {
      fromDate: moment(defaultFilter.dateSearchValue.fromDate).format(
        "YYYY-MM-DD"
      ),
      toDate: moment(defaultFilter.dateSearchValue.toDate).format("YYYY-MM-DD"),
    };
    
    

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  useEffect(() => {
    if (!showEditForm) {
      debouncedgetLhs()
    }
  }, [showEditForm])

  useEffect(() => {
    debouncedgetLhs();
  },[recordsPerPage])

  useEffect(()=> {
    setDispSetting(displayConfiguration)
  },[displayConfiguration])

  const recordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };
  
  const onSearch = (value) => {
  };

  const assignRoute = () => {
    if(route && route.id){
      setIsAssigning(true);
      const data = Object.assign({}, data, {
        nav_route_id: route.id,
        required_ids: selectedInfo.selectedKeys,
        stop_type: 'LH',
      });
      addAnyStopToRoute(data).then((result) => {
          if (result.success) {
            // props.closeSearchOrders( true, route.id, props.actionType);
            alertMessage(I18n.t('messages.allocated_successfully'), 'success', 10);
            handleSelectChange([], []);
            debouncedgetLhs();
            setIsAssigning(false)
        } else {
          renderAlertMessage(result.errors) 
          setIsAssigning(false)
        }
      });
    }
  }

  const handleDelete = (deletionIds) => {
    setIsfetchingLhs(true)
    LineHaulApi.bulkDelete(deletionIds)
      .then((result) => {
        if (result.success) {
          debouncedgetLhs()
          alertMessage("Deleted Successfully", 'success', 10)
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsfetchingLhs(false)
        setSelectedInfo({
          selectedKeys: [],
          selectedRows: [],
        });
      })
  }

  const renderReasonModal = () => {
    return (
      <BaseModal
        title={I18n.t('messages.reason_to_delete')}
        onCancel={() => {
          resetDeletionElements()
        }}
        visible={showReasonWindow}
      >
        <div>
          <ReasonWindow
            reasonElement="reason"
            reasonValue={reasonValue}
            handleOnChange={(elemant, value) => setReasonValue(value)}
            closeReasonModal={resetDeletionElements}
            showButtons={true}
            saveReason={handleDelete}
          />
        </div>
      </BaseModal>
    )
  }

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedInfo({
      ...selectedInfo,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  };

  const getDrivers = () => {
    setIsfetchingDrivers(true);
    fetchUsers('driver', 1, 100000)
      .then((result) => {
        if (result.success) {
          const users = result.users || [];
          setDrivers(users);
        } else {
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      })
      .finally(() => {
        setIsfetchingDrivers(false);
      });
  };

  const _renderStatusChangeModal = () => (
    <BaseModal
      title={ I18n.t('general.change_status') }
      onCancel={ () => {
        setShowChangeStatusModal(false);
        debouncedgetLhs();
      } }
      visible={ showChangeStatusModal }
    >
      <LhChangeStatus
        currentStatus={ currentStatus }
        saveStatus={ () => {
          setShowChangeStatusModal(false);
          handleSelectChange([], []);
          debouncedgetLhs();
        } }
        closeModal={ () => {
          setShowChangeStatusModal(false);
          debouncedgetLhs();
        } }
        screen="linehaul"
        data={ [ ...selectedInfo.selectedRows ] }
        processErrors={ [ ...selectedInfo.selectedRows ] }
        hasBulkOrders={ true }
      />
    </BaseModal>
  )

  const getAccounts = (whId) => {
    const accountsData = warehouseFilter.findMatchingAccounts(whId);
    setAccounts(accountsData || []);
  };

  const computeTags = (filterVariable) => {
    const keys = [
      'account_code',
      'dateSearchValue',
      // "startingPoint",
      // "destinationPoint",
      'sort_by',
    ];
    const filteredData = {
      account_code: {
        label: I18n.t('general.account'),
        value:
          accounts.find((acc) => acc.code === filterVariable.account_code)
            ?.name || '',
        onClose: () => {handleFilterOnRemoval('account_code')},
        closeable: true,
      },
      dateSearchValue: {
        label: "Appointment Date",
        value: (isEmpty(filterVariable.dateSearchValue.fromDate) && isEmpty(filterVariable.dateSearchValue.toDate)) ? '' : `${moment(filterVariable.dateSearchValue.fromDate).format(
          'MMM DD, YYYY',
        )} - ${moment(filterVariable.dateSearchValue.toDate).format(
          'MMM DD, YYYY',
        )}`
        , onClose: () => {handleFilterOnRemoval('dateSearchValue')},
        closeable: _.isEqual(dynamicDateObject, defaultDateObject) ? false : true,
      },
      startingPoint: {
        label: "Origin",
        value: _.find(warehouses, { id: filterVariable.startingPoint })?.name,
        onClose: () => {handleFilterOnRemoval('startingPoint')},
        closeable:  _.isEqual(filterVariable.startingPoint, defaultFilter.startingPoint) ? false : true
      },
      destinationPoint: {
        label: "Destination",
        value: _.find(warehouses, { id: filterVariable.destinationPoint })?.name,
        onClose: () => {handleFilterOnRemoval('destinationPoint')},
        closeable: _.isEqual(filterVariable.destinationPoint, defaultFilter.destinationPoint) ? false : true
      },
    };
    const tagsInfo = convertToTags({ ...filterVariable }, keys, filteredData);
    setAppliedFiltersTags(tagsInfo);
  };

  const getLocation = (locationId) => {
    // find in warehouses
    const warehouse = warehouses.find((wh) => wh.id === locationId);
    if (warehouse) {
      return warehouse.name;
    } else {
      return "";
    }
  };

  const handleFilterOnRemoval = (key) => {
    const filterVariable = { ...filterPlaceHolder };
    switch (key) {
      case "account_code":
        filterVariable.account_code = defaultFilter.account_code;
        break;
      case "dateSearchValue":
        filterVariable.dateSearchValue = {
          fromDate: defaultFilter.dateSearchValue.fromDate,
          toDate: defaultFilter.dateSearchValue.toDate,
        };
        break;
      case "startingPoint":
        filterVariable.startingPoint = defaultFilter.startingPoint;
        break;
      case "destinationPoint":
        filterVariable.destinationPoint = defaultFilter.destinationPoint;
        break;
      default:
        break;
    }
    setFilterPlaceHolder(filterVariable);
    computeTags(filterVariable);
  };

  const getLhs = () => {
    setIsfetchingLhs(true);
    const sortByType = filterPlaceHolder.sortByType || "ascend";
    if (warehouses.length > 0) {
      const page =
        tableOptions.pagination?.current &&
          !isEmpty(tableOptions.pagination.current)
          ? tableOptions.pagination.current
          : 1;
      const perPage = recordsPerPage;
      const payload = {
      };
      if (!isEmpty(searchText)) {
        payload.search_value = _.trim(searchText);
        payload.status = "";
      } else {
        payload.status = currentStatus;
        payload.account_id = selectedAccount?.id || "";
        payload.appointment_from_date = filterPlaceHolder.dateSearchValue.fromDate;
        payload.appointment_to_date = filterPlaceHolder.dateSearchValue.toDate;
        payload.sort_by = filterPlaceHolder.sortBy;
        payload.sort_order = I18n.t(`general.sort_${sortByType}`);
        // payload.pickup_location_id = filterPlaceHolder.startingPoint,
        // payload.drop_location_id = filterPlaceHolder.destinationPoint
      }

      payload.organization_id = currentOrg.id;
      payload.type_of_order = 'LH'
      LineHaulApi.fetch(true, '', page, perPage, '', payload)
        .then((result) => {
          if (result.success) {
            let linehauls = result.linehauls || [];
            linehauls = linehauls.map((lh) => {
              return {
                ...lh,
                pickup_location: getLocation(lh.pickup_location_id),
                delivery_location: getLocation(lh.drop_location_id),
              };
            });
            setLhData(linehauls);
            setSearchKeyPress(false);
            setPagination(result.pagination);
            setStatsData(!isEmpty(result.stats) ? result.stats : {});
          } else {
            setStatsData({});
            setSearchKeyPress(false);
            alertMessage(result.errors[ 0 ], 'error', 10);
          }
        })
        .finally(() => {
          setIsfetchingLhs(false);
          //setCurrentStatus(filterPlaceHolder.currentStatus)
        });
    }
  };
  const debouncedgetLhs = _.debounce(getLhs, 100);

  useEffect(() => {
    if (searchKeyPress) {
      debouncedgetLhs();
      computeTags(filterPlaceHolder);
    }
  }, [ searchKeyPress ]);

  // mount
  useEffect(() => {
    getAccounts();
    getDrivers();
    debouncedgetLhs();
    computeTags(filterPlaceHolder);
  }, []);

  useEffect(() => {
    debouncedgetLhs();
    // getAccounts();
    computeTags(filterPlaceHolder)
  }, [ filterPlaceHolder ]);

  useEffect(() => {
    const otherWarehouses = warehouses.filter((wh) => wh.id !== (Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses)); 
    const destinationPoint = otherWarehouses?.[ 0 ]?.id || '';
    const startingPoint = Array.isArray(selectedWarehouses) ? selectedWarehouses[ 0 ] : selectedWarehouses;
    setFilterPlaceHolder({ ...defaultFilter, startingPoint, destinationPoint });
  }, [ warehouseFilter.selectedWarehouses, warehouseFilter.warehouses ]);

  useEffect(() => {
    getAccounts();
  }, [warehouseFilter.warehouseWithAccounts,warehouseFilter.selectedWarehouses])


  const handleOnFilterElementChange = (element, value) => {
    if (element === "sortBy") {
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || "ascend",
      });
      setFilterPlaceHolder(filter);
    } else {
      const filter = Object.assign({}, filterPlaceHolder, {
        [element]: value,
      });
      setFilterPlaceHolder(filter);
    }
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      setSearchKeyPress(true);
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      handleOnFilterElementChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
    }
    // debouncedgetLhs();
  };

  const handleOnStatusChange = (element, value) => {
    setSelectedInfo({
      selectedKeys: [],
      selectedRows: [],
    });
    setCurrentStatus(value);
  };

  const clearSearch = () => {
    setSearchText("");
    setIsSearching(false);
    clearFilter();
  };
  const handleSearch = (filter) => {
    setCurrentStatus(filter.currentStatus || defaultStatus);
    setShowFilterWindow(false);
    setSearchText('');
    setIsSearching(false);
    setFilterPlaceHolder(Object.assign({}, filter));
    tableOptions.pagination.current = 1;
  };

  const updateFilterSearchValues = (filter, cb) => {
    const acc = accounts.find((account) => account.code === filter.account_code);
    setSelectedAccount(acc);
    handleSearch(filter);
  };

  const multipleOrderDeletion = (ids) => {
    setIsfetchingLhs(false)
    deleteMultipleOrder(ids).then((result) => {
      if (result.success) {
        debouncedgetLhs()
        alertMessage("Deleted Successfully", 'success', 10)
      } else {
        renderAlertMessage(result.errors)
      }
    })
    .finally(() => {
      setIsfetchingLhs(false)
      setSelectedInfo({
        selectedKeys: [],
        selectedRows: [],
      });
    })
  };
  
  const handleLhDelete = (id) => {
    setIsfetchingLhs(true)
    LineHaulApi.deleteLh(id)
      .then((result) => {
        if (result.success) {
          alertMessage('Linehaul deleted successfully', 'success', 10)
          debouncedgetLhs()
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsfetchingLhs(false)
      })
  }


  const renderSearchBar = (showTitle = false) => {
    return (
      <div className="headerOptionDiv headerFilter">
         {
            showTitle && <span className="textBold">
              {I18n.t("general.search")}:&nbsp;&nbsp;
            </span>
          }
        <Search
          placeholder={ I18n.t('order.filters.placeholder') }
          className="seachWithDropDown recoverySearch search_with_prefix"
          size="default"
          value={ !isEmpty('searchText') ? searchText.toUpperCase() : '' }
          onSearch={ (value) => {
            setCurrentStatus(defaultStatus);
            setSearchText(value);
            setSearchKeyPress(true);
            setUpdateSearchTags(true)
            setIsSearching(value.length > 0);
          } }
          onChange={ (e) => {
            const filteredString = removeSpecialCharacters(e.target.value);
            setSearchText(filteredString);
          } }
          style={ { width: 500, textTransform: 'uppercase' } }
          onClear={ (e) => {
            setSearchText('');
          } }
          allowClear
          suffix={
            <>
              <Dropdown
                onVisibleChange={ handleVisibleChange }
                visible={ showFilterWindow }
                trigger={ [ 'click' ] }
                overlay={
                  <div
                    style={ {
                      backgroundColor: 'white',
                      width: 500,
                      padding: 15,
                      marginTop: 10,
                      marginRight: -12,
                    } }
                    className="boxShadow"
                  >
                    <LhFilter
                      accounts={ accounts }
                      warehouseFilter={ warehouseFilter }
                      filterPlaceHolder={ filterPlaceHolder }
                      defaultFilter={ { ...defaultFilter } }
                      setFilterPlaceHolder={ updateFilterSearchValues }
                      onSearch={ handleSearch }
                      onOrderNoSearch={ null }
                      setSearchText={ setSearchText }
                      resetFilter={ clearFilter }
                      showDateFilter={!isDispatchScreen}
                    />
                  </div>
                }
              >
                <Icon
                  type="control"
                  onClick={ () => setShowFilterWindow(true) }
                />
              </Dropdown>
            </>
          }
          prefix={
            <Row type="flex" align="middle" gutter={ 4 }>
              <Col>
                {/* <Icon type="search" onClick={ () => setSearchKeyPress(true) } /> */}
                <Icon
                  type="search"
                  onClick={() => {
                    setSearchKeyPress(true);
                    setIsSearching(searchText.length ? true : false);
                    setUpdateSearchTags(true);
                  }}
                />
              </Col>
            </Row>
          }
        />
      </div>
    );
  };
  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag);
  };

  const clearFilter = () => {
    setSearchText('');
    setIsSearching(false);
    const updatedFilter = {
      ...filterPlaceHolder,
      ...defaultFilter,
    };

    setCurrentStatus(defaultStatus);
    setSelectedAccount(null);
    setFilterPlaceHolder(updatedFilter);
    computeTags(updatedFilter);
    setSearchKeyPress(true);

  };

  const RouteNavigation = (props) => {
    const { routeDetails, handleCreationFormClose } = props;
    const { r_name, r_scheduled_start_datetime, r_status, customer_order_ids } = routeDetails;
    return (
      <Result
        status="success"
        title={ `Route with name ${r_name} has been scheduled successfully` }
        subTitle={ `Route Scheduled on ${moment(r_scheduled_start_datetime).format(AppConfig.dateFormat)}` }
        extra={ [
          <Button
            type="primary"
            key="console"
            onClick={ () => {
              props.history.push({
                pathname: "/routes",
                filter: "ASSIGNED",
                routeId: routeDetails.id,
                dateFilter: {
                  fromDate: moment(routeDetails.r_scheduled_start_datetime).format('YYYY-MM-DD'),
                  toDate: moment(routeDetails.r_scheduled_start_datetime).format('YYYY-MM-DD'),
                },
              });
            } }
          >
            View Route
          </Button>,
          <Button key="buy" onClick={ () => {
            setShowRouteSummaryModal(false);
            debouncedgetLhs();
          } }>
            Close
          </Button>,

        ] }
      />
    );
  };

  const renderRouteSummary = () => {
    return (
      <BaseModal title="Route Summary"
        visible={ true }
        onCancel={ () => {
          setShowRouteSummaryModal(false);
          debouncedgetLhs();
        } }
        width="40%"
        style={ {
          top: 50,
        } }
        bodyStyle={ {
          height: 'auto',
        } }
      >
        <RouteNavigation
          routeDetails={ routeDetails }
          handleCreationFormClose={ handleCreationFormClose }
          history={ props.history }
        />
      </BaseModal>
    );
  };

  const handleCreationFormClose = (routeDetails) => {
    setShowLinehaulModal(false);
    setShowRouteSummaryModal(true);
    setSelectedInfo({
      selectedKeys: [],
      selectedRows: [],
    });
    // Update the state and render the route summary directly
    // setRouteDetails(routeDetails);
    Modal.success({
      title: 'Route has been created',
      visible: showRouteSummaryModal,
      content: (
        <div>
          <u>
            <Link
              style={ { cursor: 'pointer', fontWeight: 'bold' } }
              onClick={ () => {
                // navigateToRoute(routeDetails, props.history);
                Modal.destroyAll()
              props.history.push({
                pathname: "/routes",
                filter: "ASSIGNED",
                routeId: routeDetails.id,
                dateFilter: {
                  fromDate: moment(routeDetails.r_scheduled_start_datetime).format('YYYY-MM-DD'),
                  toDate: moment(routeDetails.r_scheduled_start_datetime).format('YYYY-MM-DD'),
                },
              });
              } }
            >
              Click here
            </Link>
          </u>
          &nbsp;&nbsp; to view the route
        </div>
      ),
      closable: true,
      onCancel: () => {
        // Handle close event
      },
      onOk: () => {
        setShowRouteSummaryModal(false);
        debouncedgetLhs();
      }
    })
  };

  const renderLinehaulModal = () => {
    return (
      <BaseModal
        title={I18n.t('routes.create')}
        // visible={ showLinehaulModal }
        onCancel={ () => {
          setShowLinehaulModal(false);
          debouncedgetLhs();
        } }
        // width={ selectedInfo.selectedKeys.length > 0 ? '40%' : '50%' }
        width={ '80%' }
        style={ {
          top: 10,
        } }
        bodyStyle={ {
          // height: selectedInfo.selectedKeys.length > 0 ? 'auto' : 'auto',
          padding: 10
        } }
      >
        <LinehaulRouteForm
          warehouses={ warehouses }
          drivers={ drivers }
          onClose={ () => {
            setShowLinehaulModal(false);
          } }
          isMilitaryTime={ isMilitaryTime }
          selectedLhs={ selectedInfo.selectedRows }
          organizationId={ currentOrg.id }
          appliedFiltersTags={ appliedFiltersTags }
          filterPlaceHolder={ filterPlaceHolder }
          handleCreationFormClose={ handleCreationFormClose }
        />
      </BaseModal>
    );
  };

  useEffect(() => {
    tableOptions.pagination.current = 1;
    debouncedgetLhs();
  }, [ currentStatus ]);

  const _handleCreateShipment = () => {
    const selectedOrders = selectedInfo.selectedRows;
    const uniqueAppointmentDates = [ ...new Set(selectedOrders.map(order => order.schedule_date.split('M')[0]))];
    if (uniqueAppointmentDates.length > 1) {
      alertMessage("Please select orders with same scheduled date", 'error', 10)
      return;
    }
    setShowLinehaulModal(true);
  }

  const [currentOrderObject, setCurrentOrder] = useState({})
  const handleLhEdit = async (data) => {
    getOrderInfo(data.customer_order_id).then((orderInfo) => {
      setCurrentOrder(orderInfo);
      setShowEditForm(true);
    })
    // const { customer_order_number, customer_order_id } = data;
    // // const result = await getCurrentOrderInfo(customer_order_id);
    // // setCurrentOrderInfo(result);
    // props.history.push({
    //   pathname: '/orders',
    //   search: '?activeTab=linehaul',
    //   filterPlaceHolder: {
    //     search_order_token: customer_order_number,
    //   },
    //   searchType: 'byOrders',
    //   orderObject: { ...data, id: data.customer_order_id },
    //   showQuoteOrder: true,
    // });
  };
  return (
    <div className="content-outer routes-classes">
      <div className="content">
       { 
       !isDispatchScreen ? 
        <Row className="page-header">
          <Col xs={12}>{"Linehaul"}</Col>
          <Col xs={12} className="alignRight">
            <Row
              justify="end"
              gutter={16}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Col className="alignLeft">{renderSearchBar()}</Col>
              {/* <Col className="alignRight page-actions">
                {
                  <Tooltip
                    title={
                      selectedInfo.selectedRows.length === 0
                        ? "Please select orders to create shipment"
                        : ""
                    }
                    placement="bottom"
                  >
                    <Button
                      onClick={_handleCreateShipment}
                      type={"primary"}
                      icon="plus"
                      size={"default"}
                      className=""
                      disabled={
                        currentStatus !== "pending" ||
                        selectedInfo.selectedRows.length === 0
                      }
                    >
                      {I18n.t('routes.create')}
                    </Button>
                  </Tooltip>
                } 
              </Col>*/}
            </Row>
          </Col>
        </Row>
        :
        <Row>
          <Col className="alignLeft" xs={18}>{renderSearchBar(true)}</Col>
          <Col xs={6} className="alignRight">
              { isDispatchScreen && <Button
                size="small"
                type="primary"
                onClick={assignRoute}
                loading={isAssigning}
                disabled={selectedInfo?.selectedKeys.length === 0}
              >
                {I18n.t("preplan.allocate")}
              </Button>}
          </Col>
        </Row>
       }
        <Row className="page-content">
          { !isDispatchScreen && <Col xs={24}>
            <LinehaulStats
              filter={currentStatus}
              handleOnFilterChange={(element, value) =>
                handleOnStatusChange(element, value)
              }
              data={statsData}
            />
          </Col>
          }
          <Col xs={24} className="marginTop marginBottom40">
            <Row type="flex" justify="space-between">
              <Col span={24}>
                <AppliedFiltersTags
                  tags={appliedFiltersTags}
                  clearSearch={() => clearSearch()}
                  clearFilter={() => clearFilter()}
                  isSearching={isSearching}
                  // isSearching={!isEmpty(searchText) ? true : false}
                  searchText={searchText}
                  searchBy={"Order"}
                  updateSearchTags={updateSearchTags}
                  resetSearchTags={() => setUpdateSearchTags(false)}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
            <Col>
                <RecordsPerPage
                  onChange={recordChange}
                  onSearch={onSearch}
                  value={recordsPerPage}
                  defaultValue={recordsPerPage}
                />
              </Col>
            </Row>
            <LineHaulList
              refreshList={() => {
                handleSelectChange([], [])
                debouncedgetLhs()
              }}
              data={lhData}
              scroll={{y: "calc(100vh - 410px)", x: "max-content" }}
              displayConfiguration={dispSetting}
              screen_from="linehaul"
              isLoading={isfetchingLhs}
              size="small"
              isMilitaryTime={isMilitaryTime}
              showActions={!isDispatchScreen}
              showStatus={true}
              handleEditClick={handleLhEdit}
              deleteClick={multipleOrderDeletion} //handleLhDelete
              pagination={tablePagination}
              tableChange={(pagination, filter, sorter, currentTable) =>
                onTableChange(pagination, filter, sorter, currentTable)
              }
              selectedWH={_.find(warehouses, {
                id: filterPlaceHolder.startingPoint,
              })}
              handleStatusChange={() => {}}
              currentStatus={currentStatus}
              rowSelection={
                currentStatus === "" ||
                !checkServiceExistance(["OREDEL", "ORECS"], "ANY")
                  ? null
                  : {
                      selectedRowKeys:
                        selectedInfo.selectedKeys.length > 0
                          ? selectedInfo.selectedKeys
                          : [],
                      onChange: handleSelectChange,
                      getCheckboxProps: (record) => ({
                        id: `${record.id}`,
                      }),
                    }
              }
              orderConfig={orderConfig}
              // selectedWH={selectedWH}
            />
            {!isDispatchScreen && selectedInfo.selectedKeys.length > 0 && (
              <LineHualActions
                filter={currentStatus}
                selectedInfo={selectedInfo}
                handleClear={() => handleSelectChange([], [])}
                refreshList={() => debouncedgetLhs()}
                resetList={() => {
                  handleSelectChange([], [])
                  debouncedgetLhs()
                }}
                setIsfetchingLhs={(value) => setIsfetchingLhs(value)}
                changeStatus={() => setShowChangeStatusModal(true)}
                isMilitaryTime={isMilitaryTime}
                selectedRows={selectedInfo.selectedRows}
                // handleDelete={() => handleDelete(selectedInfo.selectedKeys)}
                handleDelete={() => multipleOrderDeletion(selectedInfo.selectedKeys)}
                warehouseFilter={warehouseFilter}
                organizationSettings={organizationSettings}
              />
            )}
          </Col>
        </Row>
        {showLinehaulModal && renderLinehaulModal()}
        { showChangeStatusModal && _renderStatusChangeModal() }  
        {/* { (Object.keys(routeDetails).length > 0) && renderRouteSummary() } */ }
        { showEditForm && (
          <Drawer
            placement="right"
            closable={ false }
            maskClosable={ false }
            onClose={ () => setShowEditForm(false) }
            visible={ showEditForm }
            width="90%"
          >
            <EditOrderFormComponent
              callFrom="linehaul"
              customerDetails={ currentOrderObject }
              customerOrderId={ currentOrderObject.id }
              preferences={[]}
              onCancel={ () => setShowEditForm(false) }
              handleSuccess={ () => { } }
              isNew={ false }
            />
          </Drawer>
        ) }
      </div>
    </div>
  );
}

export const LinehaulComponent = withRouter((props) => {
  const warehouseContext = useContext(WarehouseContext);
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const appContexts= {
    userContext,
    orgContext,
    warehouseContext,
  };
  return (
    <Linehaul
      appContexts={appContexts}
      {...props}
    />
  );
}
);

export default LinehaulComponent;
