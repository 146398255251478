
import AppConfig from '../config/AppConfig';
import userStore from '../stores/UserStore';
import { ApiUrl, Call, optionalPageParams, PageParams, ParseGeneralResponse } from './ApiUtils';
import { I18n, isEmpty } from '../common/Common';

export function fetchRolesList (info = {}) {
  const { org_id = "", page = 1, per_page = "", sort_by = "", sort_order = "" } = info;
  let url = ApiUrl(
    `v2/org_roles?org_id=${org_id}&${optionalPageParams(page, per_page)}`
  ); //records per page to be checked
  if(!isEmpty(sort_by) && !isEmpty(sort_order)){
	url = `${url}&sort_by=${sort_by}&sort_order=${sort_order}`;
  }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "org_roles", pagination: "meta.pagination" },
  });
}
export function fetchSingleRole (id) {
	const url = ApiUrl(`v2/org_roles/${id}`);
	return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { 'data': '' } });
}

export function createRole ({ roleName, roleCode, access }) {
	const orgId = userStore.getStateValue("selectedOrg");
	const url = ApiUrl(`v2/org_roles`);
	return Call('post', url, { role_name: roleName, role_code: roleCode, org_id: orgId, access }, ParseGeneralResponse, { responseDataKeys: { 'data': '' } });
}
export function deleteRole (id) {
	const url = ApiUrl(`v2/org_roles/${id}`);
	return Call('delete', url, {}, ParseGeneralResponse, { responseDataKeys: { 'data': '' } });
}
export function updateRole ({ id, roleName, roleCode, access }) {
	const orgId = userStore.getStateValue("selectedOrg");
	const url = ApiUrl(`v2/org_roles/${id}?org_id=${orgId}`);
	return Call('put', url, { role_name: roleName, role_code: roleCode, access }, ParseGeneralResponse, { responseDataKeys: { 'data': '' } });
}
