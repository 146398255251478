import React, { Fragment, useState } from "react";
import PropTypes, { element } from "prop-types";
import { Link } from "../../common/UIComponents"
import { fetchOrderDetails } from "../../api/OrdersApi";
import { alertMessage } from "../../common/Common";
import BaseModal from "../BaseModal";
import ResolveAddressModal from "../../containers/ResolveAddressModal";
import I18n from "../../common/I18n";

const ValidateLocationErr = (props) => {
  const [ showResolveAddressModal, setShowResolveAddressModal ] = useState(false);
  const [ wrongOrderInfo, setWrongOrderInfo ] = useState({});
  const [ activeWrongAddressInfo, setActiveWrongAddressInfo ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const {
    record,
    displayKey,
    errorKey,
    errorValue,
    refreshCallback,
    loadMaps,
    setOrderInfo,
  } = props;

  const isPreplanScreen = window.location.pathname.includes("preplan");
  const resolveAddress = (orderInfo) => {
    const address = orderInfo.l_address || {};
    setWrongOrderInfo(orderInfo);
    setActiveWrongAddressInfo(address);
    setShowResolveAddressModal(true);
  };

  const closeAddressResolveModal = (isUpdated = false, data) => {
    setShowResolveAddressModal(false);
    // if page is preplan
    // http://localhost:3000/preplan/638856e366e0d010716caa31
      if(isPreplanScreen){
      if (typeof data === "object" && data !== null) {
        if (isUpdated === true) {
          refreshCallback();
        }
      } else if (typeof data === "string") {
        // clearing false negative
        if (isUpdated === true) {
          // refreshCallback();
          const orderId = data;
          setOrderInfo((prev) => {
            return prev.map((order) => {
              if (order.id === orderId) {
                order[ "location_partial_match" ] = false;
              }
              // check in locations array 
              if (order.locations && order.locations.length > 0) {
                order.locations = order.locations.map((location) => {
                  if (location.id === orderId) {
                    location[ "location_partial_match" ] = false;
                  }
                  return location;
                });
              }
              return order;
            });
          }
          );
        }
      }
    } else {
      // other pages
      if (isUpdated === true) {
        refreshCallback();
      }
    }
  };

  const renderResolveAddressModal = () => {
    return (
      <BaseModal
        title={ I18n.t("order.resolve_address") }
        onCancel={ closeAddressResolveModal }
        width={ "70%" }
        style={ { top: 50 } }
      >
        <ResolveAddressModal
          activeWrongAddressInfo={ activeWrongAddressInfo }
          wrongOrderinfo={ wrongOrderInfo }
          closeModal={ closeAddressResolveModal }
          loadMaps={ loadMaps }
        />
      </BaseModal>
    );
  };
  return (
    record && errorKey && record[ displayKey ] ? (
      <span>
        { (record[ errorKey ] === errorValue || isPreplanScreen) ? (
            <Link className={ record[ errorKey ] === errorValue ? "textRed" : ""} onClick={ () => {
            resolveAddress(record);
          } }>
              { record[ displayKey ] }
            </Link>
        ) : (
          <span>{ record[ displayKey ] }</span>
        ) }
        { showResolveAddressModal && renderResolveAddressModal() }
      </span>
    ) : (
      <Fragment />
    )
  );
};

ValidateLocationErr.propTypes = {
  record: PropTypes.shape().isRequired,
  displayKey: PropTypes.string.isRequired,
  errorKey: PropTypes.string.isRequired,
  errorValue: PropTypes.string.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

ValidateLocationErr.defaultProps = {
  record: {},
  displayKey: "",
  errorKey: "",
  errorValue: "",
  refreshCallback: () => { },
};


export default ValidateLocationErr;