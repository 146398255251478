import React, { Component, Fragment, useContext, useCallback } from "react";
import _ from "lodash";
import { Col, Row, Button, Spin, Search, Select, Dropdown, Icon, message, Input, Skeleton } from "../common/UIComponents";
import {
  alertMessage,
  checkNegitive,
  isEmpty,
  navigateToAccount,
} from "../common/Common";
import { base64ToExcel, base64ToPDF, convertToTags, limitString, removeSpecialCharacters, renderAlertMessage } from "../helpers/common";
import AppConfig from "../config/AppConfig";
import { withRouter } from "react-router";
import { WarehouseContext } from "../context/WarehouseContext";
import { UserContext } from "../context/UserContext";
import I18n from "../common/I18n";
import { ScreenKeys } from "./constants";
import moment from 'moment';
import AppliedFiltersTags from "./AppliedFiltersTags";
import { fetchOrgAccounts } from "../api/Account";
import { OrgContext } from "../context/OrgContext";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import ExportReport from "../components/reports/aging_reports/ExportReport";
import Filters from "../components/manage_settlements/Filters";
import GridListComponent from "../components/manage_settlements/GridListComponent";
import { fetchUsersForWeeklyReport } from "../api/UsersApi";
import { fetchBillingAccounts } from "../api/BillingAcountsApis";
import { DriverSettlementApi } from "../api/DriverSettlementApi";
import SummaryList from "../components/manage_settlements/DetailsList";
import ListWrapper from "../components/manage_settlements/ListWrapper";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import GridPagination from "../components/common/GridPagination";
import { fetchAgent } from "../api/Agent";
import userStore from "../stores/UserStore";
import ApproveSettlements from "../components/manage_settlements/ApproveSettlements";
import SettlementAdjustments from "../components/manage_settlements/SettlementAdjustments";
import { VehicleTypeApi } from "../api/VehicleType";

const defaultFilter = {
  warehouseIds: [],
  companyIds: [],
  accountIds: [],
  driverIds: [],
  searchDateType: "SERVICE_DATE",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  filterAmount: "BOTH",
  showAmount: null,
  status: 'PENDING',
  viewType: 'DETAILED',
  sortBy: "none",
  selectedOption: 'driverIds',
  sortByType: "descend",
  agentIds : [],
  download_as: 'pdf',
  vehicle_types: [],
  driver_type: '',
};

class ManageDriverSettlementsComponent extends Component {
  constructor(props) {
    super(props);
    const { selectedWarehouses = null } = props.warehouseData;
    const warehouseIds = Array.isArray(selectedWarehouses)
      ? selectedWarehouses
      : selectedWarehouses
      ? [selectedWarehouses]
      : null;
    this.state = {
      accounts: [],
      pagination: {},
      reportLoading: false,
      appliedFiltersTags: [],
      warehouseData : this.props.warehouseData,
      accountsLoading: false,
      agingReports: [],
      selectedOption : "driverIds",
      recordsPerPage: props.recordsPerPage,
      token: "",
      filterPlaceHolder: {
       ..._.cloneDeep(defaultFilter),
       warehouseIds: warehouseIds,
      },
      activeAccounts: [],
      filterWindow: false,
      whLoading: false,
      agents : [],
      isInitial: true,
      drivers: [],
      currentGridRef: null,
      orderGridRef: null,
      billingAccounts: [],
      showRerrangeableModal: false,
      selectedKeys: [],
      changedKeys: [],
      totalSelectedRows: [],
      isInitialCall: true,
      searched_order: "",
      vehicleTypes: []
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  setCurrentGridRef = (ref) => {
    this.setState({ currentGridRef: ref });
  };

  setChildGridRef = (ref) => {
    this.setState({ orderGridRef: ref });
  };

  getPageWiseData = ( page ) => {
    this.onTableChange({
      ...this.state.pagination,
      current: page
    })
  }
  handleSelectedOptionChange = (newSelectedOption) => {
    this.setState({
      selectedOption: newSelectedOption
    });
  };

  handleSave = () => {
    const { currentOrg } = this.props.userContext
    const data = this.state.agingReports || [];
    const payload  = {
      organization_id: currentOrg.id,
      driver_pay_invoice_lines: data.filter(rec => this.state.changedKeys.includes(rec.id)).map(rec => ({
          driver_pay_invoice_line_id : rec.id,
          item_value: rec.item_value
      }))
    }
    this.setState({
      reportLoading: true,
    })
     DriverSettlementApi.saveOrderLines(payload).then((result) => {
       if (result.success) {
         alertMessage(I18n.t('messages.saved'))
         this.setState({
          errors: [],
          changedKeys: [],
          reportLoading: false,
         })
       } else {
         renderAlertMessage(result.errors, 'error', 10)
         this.setState({
          errors: result.errors || [],
          reportLoading: false,
         })
       }
     })
 }

 getAgent = () => {
  this.setState({ inProgress: true });
  const { currentOrg } = this.props.userContext

  const page = this.tableOptions.pagination.current;
  const searchText = this.state.token || '';
  const perPage = 10000
  const { isSuperAdmin } = this.props
  const org_id = currentOrg?.id || "";
  fetchAgent(org_id, page, perPage)
    .then((result) => {
      if (result.success) {
        this.setState({
          agents: result.agents || [],
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
      }
    });
 }

 handleCancel  = () => {
  const { currentGridRef } = this.state;
  if (currentGridRef) {
    currentGridRef.deselectAll();
  }
  this.setState({
    totalSelectedRows: []
  })
 }

 setSelectedRows = () => {
  const { totalSelectedRows, agingReports, currentGridRef } = this.state;
  // Extract the IDs from agingReports
  const agingReportIds = agingReports.map(report => report.id);
  const selectedIds = totalSelectedRows.map(report => report.id);
  if (currentGridRef) {
    currentGridRef.getRowNode(0).setSelected(true)
  }
  // Filter totalSelectedRows to get the records whose ID matches with agingReportIds
  const selectedRowsMatchingAgingReports = totalSelectedRows.filter(row => agingReportIds.includes(row.id));
  // console.log("agingReportIds", agingReportIds, selectedIds)
  // if (currentGridRef) {
  //   currentGridRef.getRowNode(0).setSelected(true)
  //   // currentGridRef.forEachNode(node => {
  //   //   //node.setSelected(selectedIds.includes(node.data.id));
  //   //   if (selectedIds.includes(node.data.id)) {
  //   //     node.setSelected(true); // Assuming `setSelected` is available on your node object (check documentation)
  //   //   }s
  //   // });
  //  // currentGridRef.setNodesSelected({nodes: selectedRowsMatchingAgingReports, newValue: true});
  // }
 }

handleApproveSuccess = () => {
  this.getReports()
  this.setState({
    errors:[],
    selectedKeys: [],
    totalSelectedRows: [],
    reportLoading: false,
   })
}
setReportLoading = () => {
  this.setState({
   reportLoading: true
  })
}

  getAmountTypeLabel = (filterAmount) => {
    switch (filterAmount) {
      case "WITH_AMOUNT":
        return "With Amount";
      case "WITHOUT_AMOUNT":
        return "Without Amount";
      case "BOTH":
        return "Both";
      default:
        return "";
    }
  };

  componentDidMount() {
    this.getReports();
    this.getAccounts();
    this.getAgent();
    this.computeTags(this.state.filterPlaceHolder);
    this.getUsers()
    this.getVehicleTypes();
    this.getBillingAccounts()
  }

  getBillingAccounts = () => {
    const { currentOrg } = this.props.userContext
    // const organization_id = this.props.organization_id ? this.props.organization_id : userStore.getStateValue("selectedOrg");
    this.setState({inProgress:true})
    fetchBillingAccounts(currentOrg.id).then((result) => {
      if (result.success) {
        this.setState({
          billingAccounts: result.integration_companies || [],
          inProgress:false
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({
          inProgress: false,
          billingAccounts:[]
        });
      }
    });
  };

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        console.log("vehicle",result)
        this.setState({
          vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getUsers = (refreshReports = false) => {
    this.setState({ inProgress: true });
    fetchUsersForWeeklyReport().then((result) => {
      if (result.success) {
        const drivers = result.users || []
        this.setState(
          {
            drivers,
            inProgress: false,
          },
          () => {
            if (refreshReports) {
              this.getReports()
            }
          },
        )
      } else {
        this.setState({ inProgress: false, drivers: [] })
        if (refreshReports) {
          this.getReports()
        }
      }
    })
  }

  navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(this.state.accounts, [
      "code",
      account_code,
    ]);
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, this.state.accounts[accountIndex]);
      const account_id = accountData.id;
      this.props.history.push(`/accounts/${account_id}/settings`);
    }
  };


  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseData.selectedWarehouses,
        this.props.warehouseData.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseData.warehouses,
        this.props.warehouseData.warehouses
      ) &&
        !this.props.warehouseData.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      // this.getReports();
      // this.getAccounts();
      if(this.props.warehouseData?.warehouses?.length && this.state.isInitial){
        const { selectedWarehouses } = this.props.warehouseData;
        const warehouseIds = Array.isArray(selectedWarehouses)
          ? selectedWarehouses
          : selectedWarehouses
          ? [selectedWarehouses]
          : null;
        this.setState({
          filterPlaceHolder: { 
            ...this.state.filterPlaceHolder,
            warehouseIds: warehouseIds
          },
          isInitial: false,
        }, () => {
          this.getReports()
        })
      }
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }


  handleFormChange = (id) => {
    this.setState({
      changedKeys: [...this.state.changedKeys,id]
    })
  }

  calculatePercentage = (amount, newAmount) => {
    if(!isEmpty(amount) &&  !isEmpty(newAmount) && parseFloat(amount) !== 0){
      const per =  (parseFloat(newAmount) * 100) / parseFloat(amount);
      return per;
    }
    return 0.00
  }

  handleUpdateRowData = (id, element , newValue) => {
    const gross_orders = [...this.state.agingReports]
    const rowIndex = _.findIndex(gross_orders, { id: id});
    if(rowIndex>=0){
      if(element === "eligible_charge"){
        gross_orders[rowIndex]["prev_item_value"] = gross_orders[rowIndex].item_value;
        gross_orders[rowIndex]["settlement_percentage"] = this.calculatePercentage(gross_orders[rowIndex].eligible_charge, newValue)
      } else{
        gross_orders[rowIndex][element] = newValue
      }
      this.setState({
        agingReports: [...gross_orders],
      },() => {
        const { currentGridRef } = this.state;
        if(currentGridRef){
         // currentGridRef.setRowData(gross_orders);
         currentGridRef.refreshCells();
        }
      })
    }
  }

  handleUpdateSettlemntData = (id, newValue) => {
    const gross_orders = [...this.state.agingReports]
    const rowIndex = _.findIndex(gross_orders, { id: id});
    if(rowIndex>=0){
        gross_orders[rowIndex] = {
          ...gross_orders[rowIndex],
          ...newValue
        }
      this.setState({
        agingReports: [...gross_orders],
      },() => {
        const { currentGridRef } = this.state;
        if(currentGridRef){
         // currentGridRef.setRowData(gross_orders);
         currentGridRef.refreshCells();
        }
      })
    }
  }


  clearFilter = () => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          warehouseIds: currentWhId,
          accountIds: [],
          vehicle_types:[],
          driver_type: '',
          filterAmount: "BOTH",
          driverIds:[],
          selectedOption: "driverIds",
        },
        selectedOption: "driverIds",
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData?.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "accountIds":
        filterVariable.accountIds = defaultFilter.accountIds;
        break;
      case "filterAmount":
        filterVariable.filterAmount = defaultFilter.filterAmount;
        break;
      case "warehouseIds":
        filterVariable.warehouseIds = currentWhId;
        break;
      case "vehicle_types":
        filterVariable.vehicle_types = defaultFilter.vehicle_types;
        break;
      case "driver_type":
        filterVariable.driver_type = defaultFilter.driver_type;
        break;
      case "driverIds":
        filterVariable.driverIds = defaultFilter.driverIds;
        break;
      default:
        break;
    }
    const newState = { filterPlaceHolder: filterVariable };
    if (['driver_type', 'driverIds', 'vehicle_types'].includes(key)) {
      newState.selectedOption = "driverIds";
    }
    this.computeTags(filterVariable);
    this.setState({ ...newState }, () => {
      this.handleSearch();
    });
  };

  computeTags = (filterPlaceHolder) => {
    const previousAmountType = "WITH_AMOUNT"
    const { warehouseData } = this.props;
    const {warehouses} = warehouseData
    const currentWhId = Array.isArray(warehouseData.selectedWarehouses)
      ? warehouseData.selectedWarehouses
      : warehouseData?.selectedWarehouses
      ? [warehouseData.selectedWarehouses]
      : null;

    const keys = ["fromDate", "toDate", "driverIds", "warehouseIds", "filterAmount", "searchDateType", "status", "viewType", "vehicle_types", "driver_type", "accountIds"];
    const filteredData = {
      filterAmount: {
        label: "Amount Type",
        value: this.getAmountTypeLabel(filterPlaceHolder.filterAmount),
        onClose: () => {
          this.handleOnFilterRemoval("filterAmount");
        },
        // closeable: previousAmountType !== filterPlaceHolder.filterAmount,
        closeable: false,
      },
      searchDateType: {
        label:
          filterPlaceHolder.searchDateType === 'INVOICE_DATE'
            ? "Invoice Date"
            : "Setllement Date",
        // value: filter.dateSearchValue ? moment(filter.dateSearchValue).format(
        //   'MMM DD, YYYY') : null,
        value: `${moment(filterPlaceHolder.fromDate).format(
        'MMM DD, YYYY',
      )} - ${moment(filterPlaceHolder.toDate).format(
        'MMM DD, YYYY',
      )}`,
      onClose: () => this.handleOnFilterRemoval("warehouseIds"),
      },
      warehouseIds: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouseIds.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouseIds");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouseIds?.length === 1 &&
          filterPlaceHolder?.warehouseIds[0] === currentWhId[0]
            ? false
            : true,
      },
      driverIds: {
        label: "Driver(s)",
        value: limitString(
          this.state.drivers
            .map((driver) => {
              if (filterPlaceHolder?.driverIds?.includes(driver.id)) {
                return driver.employee_code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          1
        ),
        onClose: () => {
          this.handleOnFilterRemoval("driverIds");
        },
        closeable: true,
      },
      vehicle_types: {
        label: "Vehicle Types",
        value: limitString(
          this.state.vehicleTypes
            .map((vehicle) => {
              if (filterPlaceHolder?.vehicle_types?.includes(vehicle.code)) {
                return vehicle.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          1
        ),
        onClose: () => {
          this.handleOnFilterRemoval("vehicle_types");
        },
        closeable: true,
      },
      driver_type: {
        label: "Driver Type",
        value: _.capitalize(filterPlaceHolder.driver_type),
        onClose: () => {
          this.handleOnFilterRemoval("driver_type");
        },
        closeable: true,
      },
      accountIds: {
        label: "Account(s)",
        value: limitString(
          this.state.accounts
            .map((acc) => {
              if (filterPlaceHolder?.accountIds?.includes(acc.id)) {
                return acc.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          1
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountIds");
        },
        closeable: true,
      },
      status: {
        label: "Status",
        value: filterPlaceHolder.status,
        onClose: () => {
          this.handleOnFilterRemoval("status");
        },
        closeable: false,
      },
      viewType: {
        label: "View Type",
        value: filterPlaceHolder.viewType,
        onClose: () => {
          this.handleOnFilterRemoval("viewType");
        },
        closeable: false,
      },

    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const orgId = this.props.orgData.currentOrg.id
    // Call fetchShortFormAccounts without passing the warehouse ID (whId)
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
       } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  formPayload = () => {
    const { filterPlaceHolder, searched_order, searchOrderToken, selectedOption } = this.state;
    const data = {
      organization_id: _.get(this.props.orgData, "currentOrg.id", "") || "",
      sort_by: filterPlaceHolder.sortBy || 'none',
      sort_by_type: filterPlaceHolder.sortByType || 'ASC',
      customer_order_numbers: searched_order || ""
    };
  
    if (!isEmpty(searchOrderToken)) {
      data.search_order_token = searchOrderToken;
    } else {
      const { warehouseIds, companyIds, accountIds, agentIds, driverIds, vehicle_types, driver_type, ...rest } = filterPlaceHolder;
      data.wh_location_ids = warehouseIds?.join(',') || '';
      data.company_ids = companyIds?.join(',') || '';
      data.account_ids = accountIds?.join(',') || '';
      data.agent_ids = agentIds?.join(',') || '';
      data.search_date_type = rest.searchDateType;
      data.from_date = rest.fromDate ? moment(rest.fromDate).format("YYYY-MM-DD") : null;
      data.to_date = rest.toDate ? moment(rest.toDate).format("YYYY-MM-DD") : null;
      data.filter_amount = rest.filterAmount || 'BOTH';
      if (rest.filterAmount === "WITH_AMOUNT" && !isEmpty(rest.showAmount)) {
        data.show_amount = rest.showAmount;
      }
      data.status = rest.status || 'PENDING';
      data.view_type = rest.viewType || 'SUMMARY';
      data.search_order_token = "";
      if (rest.print_options?.length > 0) {
        data.print_options_to_hide = rest.print_options;
      }
      if (rest.report_format?.length > 0) {
        data.download_as = rest.report_format;
      }
    }
  
    switch (selectedOption) {
      case "driverIds":
        data.driver_ids = filterPlaceHolder.driverIds?.join(',') || '';
        break;
      case "vehicle_types":
        data.vehicle_types = filterPlaceHolder.vehicle_types?.join(",") || "";
        break;
      default:
        data.driver_type = filterPlaceHolder.driver_type || '';
    }
  
    return data;
  }

  getReports = () => {
    this.setState({
      reportLoading: true,
    });
    const searchText = this.state.token || "";
    const { selectedWarehouses } = this.props.warehouseData;
    const { recordsPerPage, filterPlaceHolder } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = recordsPerPage;
    const payload = this.formPayload()
    DriverSettlementApi.fetch({page, perPage , payload}).then((result) => {
      if (result.success) {
        const agingReports = result.reports || [];
        this.setState({
          agingReports: agingReports.map(rec => ({...rec, prev_item_value: rec.item_value})),
          pagination: result.pagination || {},
          reportLoading: false,
          activeAccounts: [],
          selectedKeys: [],
          isInitialCall: false,
        }, () => {
          // this.setSelectedRows();
        });
      } else {
        this.setState({
          reportLoading: false,
          agingReports: [],
          activeAccounts: [],
          isInitialCall: false,
        });
      }
    });
  };

  handleExport = () => {
    const { currentOrg } = this.props.userContext;
    const { filterPlaceHolder } = this.state;
    this.setState({
      reportLoading: true,
    });
    const payload = this.formPayload()
    payload.download_as = filterPlaceHolder.download_as || 'pdf';
    message.loading({
      key: "generate",
      content: I18n.t("messages.generating"),
    });
    DriverSettlementApi.export({payload}).then((result) => {
      if (result.success) {
        this.setState({
          reportLoading: false,
        });
        message.destroy();
        if( payload.download_as == 'pdf'){
          base64ToPDF(
            result.file,
            `${currentOrg.code}_${moment().format(
              "MM-DD-YYYY_hh-mm-ss"
            )}_report`
          );
        }else{
          base64ToExcel(
            result.file,
            `${currentOrg.code}_${moment().format(
              "MM-DD-YYYY_hh-mm-ss"
            )}_report`
          );
        }
        
        this.getReports()
      } else {
        message.destroy();
        this.setState({
          reportLoading: false,
        });
        renderAlertMessage(result.errors || [])
        this.getReports()
      }
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getReports();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getReports();
        }
      );
    }
  };

  _renderLists = ({viewType}) => {
      switch (viewType) {
        case "SUMMARY": {
          return this.summaryList();
        }
        case "DETAILED": {
          return this.detailsList(this.state.agingReports);
        }
        default: {
          return <Fragment />
        }
      }
    }

    isRowSelectable = (row) => {
      return row.data.status === "PENDING";
    };

  onRowDataUpdated = (params) => {
    const nodesToSelect = [];
    const selectedIds = this.state.totalSelectedRows.map(report => report.id);
    params.api.forEachNode((node) => {
      if (node.data && selectedIds.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };
  
  getRowStyle = (params) => {
    
    if (this.state.filterPlaceHolder.status === "ALL" && params.data.status === "APPROVED") {
      return { 'background-color': '#ebf2ed' }; // #c7e3ce
    }
  };

  detailsList = (agingReports = [], viewType = "DETAILS") => {
    const {  organizationSettings } = this.props.orgData;
    const { totalSelectedRows, filterPlaceHolder } = this.state
    const { currentOrg } = this.props.userContext
    const showDelete = agingReports.filter(rec => rec.type_of_order === "MANUAL").length > 0;
    const pagination = {
      totalPages: this.state.pagination?.total_pages || 1,
      totalRecords: this.state.pagination?.total_count || 0,
      pageSize:  this.state.pagination?.per_page || this.props.recordsPerPage,
      currentPage:  this.state.pagination?.current_page || 1
    };
    return (
      <>
      <div style={{height: totalSelectedRows?.length ? "54vh" : "59vh" ,width:'100%'}}>
      <GridListComponent
      tableName={ScreenKeys.AIR_CARGO_SETTLEMENTS}
      rawDataSource={agingReports ? [...agingReports] : []}
      // rawDataSource={[...this.state.agingReports]}
      userContext={this.props.userContext}
      gotoAccountSettings={(params) => () => {}}
      editClick={(params) => () => {}}
      deleteClick={(params) => () => {}}
      showDeliveryZones={(params) => () => {}}
      generateBol={(params) => () => {}}
      accountCodesWithExceededLimit={ this.props.accountCodesWithExceededLimit }
      setCurrentGridRef = {(ref) => this.setCurrentGridRef(ref)}
      // tableChange={(pagination, filter, sorter) =>
      //   this.onTableChange(pagination, filter, sorter)
      // }
      navigateToAccount={this.navigateToAccount}
      displayConfiguration = {this.props.displayConfiguration}
      onChange={this.handleFormChange}
      organizationSettings={organizationSettings}
      billingAccounts={this.state.billingAccounts}
      closeModal={() => this.setState({ showRerrangeableModal: false })}
      showRerrangeableModal={ this.state.showRerrangeableModal }
      drivers={this.state.drivers || []}
      viewType={viewType}
      pagination={pagination}
      showPagination={true}
      getPageWiseData={this.getPageWiseData}
      onPerPageChanged={this.onRecordChange}
      isRowSelectable={this.isRowSelectable}
      onRowSelection={(keys, rows) => {
        this.handleSelectChange(keys, rows)
      }}
      enableRowClick={false}
      showDelete={showDelete}
      showSettlementDate={filterPlaceHolder.status === "APPROVED"}
      dispContext={ this.props.dispContext }
      status={filterPlaceHolder.status}
      onRowDataUpdated={this.onRowDataUpdated}
      getRowStyle={this.getRowStyle}
      handleUpdateRowData={this.handleUpdateRowData}
      fetchData={this.getReports}
      handleUpdateSettlemntData={this.handleUpdateSettlemntData}
    />
    </div>
    {totalSelectedRows?.length ? 
    <Row className="bottom_fixed">
      <Col xs={24} className="alignCenter">
      <Button
          type="danger"
          onClick={this.handleCancel}
          icon="close"
          size="default"
          className="marginRight10"
        >
          Clear
        </Button>
     
        <ApproveSettlements
          totalSelectedRows={this.state.totalSelectedRows}
          reports={this.state.agingReports}
          currentOrg={currentOrg}
          filterPlaceHolder={filterPlaceHolder}
          handleApproveSuccess={this.handleApproveSuccess}
          setReportLoading={this.setReportLoading}
          size="default"
        />

      </Col>
    </Row> : <Fragment />
  }
    </>
    );
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    const selectedKeys = [...this.state.selectedKeys]
    let prevSelectedRows = [...this.state.totalSelectedRows]
    let removedKeys = selectedKeys.filter(key => !selectedRowKeys.includes(key));
    removedKeys = [].concat(removedKeys, selectedRows.map(rec => rec.id))
    prevSelectedRows = prevSelectedRows.filter(rec => !removedKeys.includes(rec.id))
    this.setState({
      selectedKeys: [...selectedRowKeys],
      totalSelectedRows: [].concat(prevSelectedRows, selectedRows)
    });
  };
  summaryList = () => {
    const {  organizationSettings, isAirCargo } = this.props.orgData;
    const pagination = {
      total: this.state.pagination?.total_count,
      current: this.state.pagination?.current_page,
      pageSize: this.state.pagination?.per_page || this.state.recordsPerPage, // removed AppConfig.ordersPerPage to dynamically adjust record length 
    };
    const rowSelection = {
      selectedRowKeys: [...this.state.selectedKeys],
      onChange: this.handleSelectChange,
    };
    return (
      <Fragment>
      <SummaryList
      // rowKey="driver_shipment_charge_id"
      tableName={ScreenKeys.AIR_CARGO_SETTLEMENTS}
      isAirCargo={isAirCargo}
      data={[...this.state.agingReports]}
      userContext={this.props.userContext}
      gotoAccountSettings={(params) => () => {}}
      editClick={(params) => () => {}}
      deleteClick={(params) => () => {}}
      showDeliveryZones={(params) => () => {}}
      generateBol={(params) => () => {}}
      accountCodesWithExceededLimit={ this.props.accountCodesWithExceededLimit }
      setCurrentGridRef = {this.setCurrentGridRef}
      pagination={{
        position: 'none'
      }} //pagination
      tableChange={(
        pagination,
        filter,
        sorter,
        currentTable
      ) =>
        this.onTableChange(
          pagination,
          filter,
          sorter,
          currentTable
        )
      }
      navigateToAccount={this.navigateToAccount}
      displayConfiguration = {this.props.displayConfiguration}
      onChange={this.handleFormChange}
      organizationSettings={organizationSettings}
      billingAccounts={this.state.billingAccounts}
      drivers={this.state.drivers || []}
     
      // rowClassName={(record, index) =>
      //   !record?.driver_pay_invoice_lines?.length
      //     ? "no-expand-icon"
      //     : (index % 2 === 0
      //     ? "table-row-light"
      //     : "table-row-dark")
      // }
      scroll={{ y: "calc(100vh - 45vh)", x: 'max-content'  }}
      rowSelection={rowSelection}
      expandedRowRender={(record, index) => {
        return (<div style={{ height: 300, width: '85%' }}>
          <ListWrapper
            data={record.driver_pay_invoice_lines}
            userContext={this.props.userContext}
            pagination={{}}
            displayConfiguration={this.props.displayConfiguration}
            handleFormChange={this.handleFormChange}
            organizationSettings={organizationSettings}
            viewType={"SUMMARY"}
            record={record}
            key={record.driver_shipment_charge_id}
          />
          </div>);
      }}
    />
    <div className="custom-pagination paddingRight20">
      <GridPagination
        size="small"
        total={pagination.total}
        showSizeChanger={true}
        showQuickJumper
        current={pagination.current || 1}
        onChange={(page, pageSize) =>
          this.getPageWiseData(page, pageSize)
        }
        onShowSizeChange={(current, size) => this.onRecordChange(size)}
        showTotal={(total, range) => {
          //console.log("total, range", total, range);
          return `${range[0]}-${range[1]} of ${total} items`;
        }}
        pageSizeOptions={[50,100,200,400,600]}
        pageSize={pagination.pageSize}
      />
          </div>
    </Fragment>
    );
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
        activeAccounts: []
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getReports();
        updateRecordsPerPage(ScreenKeys.AIR_CARGO_SETTLEMENTS, value);
      }
    );
  };

  openRerrangeableModal = () => {
    this.setState({ showRerrangeableModal: true })
  }

  onSearchOrderNumber = (text) => {
    const filteredString = removeSpecialCharacters(text);
    const searchValue = filteredString.trim();
    this.setState(
      {
        searched_order: searchValue,
      },
      () => {
        if (searchValue === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = filteredString;
        }
        // this.tableOptions.pagination.current = 1;
        this.getReports()
      }
    );
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    }),
    () => {
      if (!isEmpty(this.state.searched_order)) {
        this.onSearchOrderNumber("");
      } else{
        this.getReports();
      }

    }

  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };
  handleSearch = (filter,isExport=false) => {
    const oldStatus = this.state.filterPlaceHolder.status;
    const updatedFilter = Object.assign({}, this.state.filterPlaceHolder, {...filter});
    const newState = {
      filterWindow: false,
      filterPlaceHolder: updatedFilter,
    }
    if(filter?.status && oldStatus !== filter.status){
      newState.totalSelectedRows = [];
      newState.selectedKeys = [];
    }
    this.setState(
      {...newState}
      ,
      () => {
        if(!isExport){
          this.tableOptions.pagination.current = 1;
          this.getReports();
        } else {
          this.handleExport()
        }
      }
    );
  };


  onReset = () => {
    this.setState(
      {
        filterWindow: false,
      })
  }


  render() {
    const { changedKeys,filterPlaceHolder } = this.state
    const { viewType, status } = filterPlaceHolder;
    const { currentOrg } = this.props.userContext
    return (
      <div className="">
        <div className="content">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Col span={24}>
              <Row type="flex" justify="space-between">
                <Col span={24} align="middle">
                  <AppliedFiltersTags
                    tags={this.state.appliedFiltersTags}
                    clearFilter={() => this.clearFilter()}
                  />
                </Col>
              </Row>
            </Col>
            </Row>
            <Row
              style={{ marginTop: 5, marginBottom: 5 }}
              gutter={16}
            >
              {/* <Col xs={8}>
                <Row type="flex"  gutter={16}>
                  
               </Row>

             </Col> */}
             <Col xs={24}>
               <Row type="flex" justify="end" gutter={16}>
               {
                    viewType === "DETAILED" && <Col> 
                     
                        <Button size="small" type="primary" onClick={this.handleSave}
                         className={changedKeys.length ? "buttonGreen" : ""} icon="save" disabled={changedKeys.length < 1}>
                          {I18n.t('general.save')}
                        </Button>
                    </Col>
                  }
                 { status === "PENDING" && <Col>
                    <SettlementAdjustments
                      reports={this.state.agingReports}
                      currentOrg={currentOrg}
                      filterPlaceHolder={filterPlaceHolder}
                      handleApproveSuccess={this.handleApproveSuccess}
                      setReportLoading={this.setReportLoading}
                      accounts={this.state.accounts}
                      warehouseFilter={this.props.warehouseData}
                      drivers={this.state.drivers}
                    />
                  </Col>
                 }
                {viewType === "DETAILED" && (
                  <>
                   <Col>
                   <Button onClick={ this.openRerrangeableModal } type='primary' size="small" icon="eye">
                     Toggle Columns
                   </Button>
                 </Col>
                  </>
                )}
                  <Col>
                  <Dropdown
                    onVisibleChange={this.handleVisibleChange}
                    visible={this.state.filterWindow}
                    trigger={["click"]}
                    overlay={
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "900px",
                          padding: 15,
                          marginTop: 10,
                          marginRight: -12,
                        }}
                        className="newBoxShadow"
                      >
                        <Filters
                          filter={this.state.filterPlaceHolder}
                          selectedOption={this.state.selectedOption}
                          onChange={this.handleFilterPlaceHolderChange}
                          onSearch={this.handleSearch}
                          orgData={this.props.orgData}
                          onCancel={this.onReset}
                          warehouseFilter={this.props.warehouseData}
                          billingAccounts={this.state.billingAccounts}
                          drivers={this.state.drivers}
                          agents = {this.state.agents || []}
                          onSelectedOptionChange={this.handleSelectedOptionChange}
                          
                        />
                      </div>
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <Button className="cursorPointer" shape="round" size="small" type="primary">
                      Filter
                      <Icon type="caret-down" />
                    </Button>
                    </div>
                  </Dropdown>
                </Col>
                {viewType === "DETAILED" && (
                  <>
                   <Col style={{paddingTop:'5px', marginTop: -3}} className='alignRight'><span style={{fontWeight: 700}}>Order Number</span></Col>
                    <Col>
                      <Search
                          onSearch={this.onSearchOrderNumber}
                          onChange={(e) =>
                            this.handleOnChange("searched_order", e.target.value)
                          }
                          allowClear
                          size="small"  
                          value={this.state.searched_order || ""}          
                      />
                    </Col>
                  </>
                )}
               </Row>
             </Col>
          </Row>
          <Spin spinning={this.state.reportLoading} delay={1000}>
            <Row>
              <Col style={{height: "63vh",width:'100%'}}>
                {
                  this.state.isInitialCall || this.state.drivers.length === 0? 
                  <Skeleton /> :
                  <this._renderLists viewType={viewType}/>
                }
                
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}

export const ManageDriverSettlements = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.AIR_CARGO_SETTLEMENTS;
  const displayConfiguration = useContext(DisplaySettingsContext);

  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.recordsPerPageForGrid);
  return <ManageDriverSettlementsComponent
   warehouseData={warehouseData}
    {...props} userContext={userContext} 
    recordsPerPage={recordsPerPage} 
    orgData={orgData}
    displayConfiguration = {displayConfiguration} />

});

export default ManageDriverSettlements;