import React, { useState, useEffect, Fragment, useCallback, useContext } from 'react';
import { Card, Button, Row, Col, Icon, Tooltip, Input, Badge, Select, Modal, Popover, Spin, Drawer, Typography , Form } from 'antd';
import _get from 'lodash/get';
import _ from 'lodash';
import { SendInvoiceEmail, addOrdersForBillingGroup, applyFMP, applySMP, deleteInvoiceLine, getContactEmails, getOrderBillingDetails, reapproveBillings, regenerateAnOrderBilling, removeOrderFromBillingGroup, saveNewInvoice, updateInvoice, updateInvoiceLines } from '../api/Billing';
// import { isEmpty } from 'lodash';
import InvoicesList from '../components/billing/InvoicesItemList';
import I18n from '../common/I18n';
import { fetchAccountAccessorials, fetchAccountConfigs } from '../api/Account';
import { fetchAccountShortLos } from '../api/Los';
import { fetchOrganizationDetails } from '../api/Organisations';
import userStore from '../stores/UserStore';
import { alertMessage, checkNegitive, customPasteSplit, doValidate, findNumberTotal, isEmpty } from '../common/Common';
import OrderPods from './PodRenderer';
import AppConfig from '../config/AppConfig';
import { checkServiceExistance, formatByTimeConfig, renderAlertMessage, updatePicSettingsWithBol } from '../helpers/common';
import ZoneDetails from './BillingComponents';
import TagsInput from 'react-tagsinput';
import BaseModal from '../components/BaseModal';
import BillingPdfDownload from '../components/billing/BillingPdfDownload';
import { FormItem, TextArea } from '../common/UIComponents';
import { validateEmailList } from '../components/billing_screen/helpers';
import DetailsConfigComponent from '../components/Tasks/DetailsConfig';
import { getFMPOrderFromInvoice, showFMP } from '../helpers/billing';
import Copyable from '../components/common/Copyable';
import ApproveInvoices from '../components/billing/ApproveInvoices';
import EditIcon from '../components/common/EditIcon';
import { fetchUsers } from '../api/UsersApi';
import { WarehouseContext } from '../context/WarehouseContext';
import Title from 'antd/lib/typography/Title';
import EndUserTitle from '../components/common/EndUserTitle';
import Profile from '../components/common/Profile';
import imagePlaceholder from "../../assets/images/not_available.png";
import AddressInfo from '../components/common/AddressInfo';
import NewBillingDrivers from '../components/billing/NewBillingDrivers';
import ConsolidationButton from "../components/billing/ConsolidateButton"

const { confirm } = Modal;

function renderChargesSection (chargeType, { order, isInvoiceApproved, showActions, handladdItem,handleEditItem, handleInputSaveItem, glCodesList, handleDeleteclick, isInvoiceLocked, isReadyOnly, accessorials, handleItemsChange }) {
  const { transportation_charges, fuel_surcharges, accessorial_charges, adjustment_charges } = order;

  const currentChargeTotal = (chargeType) => {
    const totalPriceList = _.concat(
      _get(order, chargeType, []),
    );
    const sum = findNumberTotal(totalPriceList, 'invoice_value', null);
    return checkNegitive(sum);
  };

  let chargesData = [];
  switch (chargeType) {
    case 'transportation_charges':
      chargesData = transportation_charges;
      break;
    case 'fuel_surcharges':
      chargesData = fuel_surcharges;
      break;
    case 'accessorial_charges':
      chargesData = accessorial_charges;
      break;
    case 'adjustment_charges':
      chargesData = adjustment_charges;
      break;
    default:
      break;
  }

  chargesData = isEmpty(chargesData) ? [] : chargesData;


  return (
    <Col sm={ 24 } xs={ 24 } md={ 24 } lg={ 24 } className="marginTop10">
      <h4>{ I18n.t(`invoice_lines.${chargeType}`) }</h4>
      <Row>
        <Col
          xs={ 24 }
          className="textBold alignRight"
        >
          { chargesData.length === 0 &&
            (!isInvoiceLocked || !isReadyOnly && showActions === true) && (
              <Button
                type="link"
                size="small"
                onClick={ () =>
                  handladdItem(chargeType, order.customer_order_id, order.locations)
                }
              >
                <Icon type="plus-circle" />
                &nbsp;{ I18n.t("accessorials.add") }
              </Button>
            ) }
        </Col>
      </Row>
      <Row>
        <Col xs={ 24 }>
          <InvoicesList
            isInvoiceApproved={ isInvoiceApproved }
            data={ chargesData || [] }
            currentSection={ order }
            showActions={ showActions }
            invoice_type={ chargeType }
            handleOnChange={ (index, element, value, orderNo) => {
              handleItemsChange(
                index,
                element,
                value,
                chargeType,
                orderNo
              );
            }
            }
            size="small"
            footer={ () => (
              <div
                className="alignRight textBold"
                style={ { paddingRight: 70 } }
              >
                { I18n.t("invoices.sub_total") }
                :&nbsp;&nbsp;
                { currentChargeTotal(
                  chargeType
                ) }
              </div>
            ) } /* {checkNegitive(invoice.total_amount)} */
            pagination={ { position: "none" } }
            deleteClick={ handleDeleteclick }
            saveItem={ handleInputSaveItem }
            totalRecords={ chargesData.length || 0 }
            addItem={ () => {
              handladdItem(chargeType, order.customer_order_id, order.locations);
            } }
            showAddIcon={ true }
            isReadyOnly={ false }
            isLocked={
              isInvoiceLocked || order.is_locked == "true"
            }
            reqData={ accessorials }
            hasNew={
              chargesData.filter((item) => item.isNew === true)
                .length === 0
            }
            bordered={ true }
            orderData={ order }
            glCodesList={ glCodesList }
            handleEditItem={ handleEditItem }
            showEditIcon={ true }
          />
        </Col>
      </Row>
    </Col>
  );
}

function renderAllCharges (props) {
  const chargeTypes = [ 'transportation_charges', 'fuel_surcharges', 'accessorial_charges', 'adjustment_charges' ];

  return (
    <Row>
      { chargeTypes.map(type => renderChargesSection(type, props)) }
    </Row>
  );
}
const BillingScreen = (props) => {
  const {
    orders: initialOrders,
    account,
    currentInvoice,
    isDriverSettlementApproved,
    isNew,
    organizationSettings,
    glCodesList,
    isLocked,
    showActions,
    getInvoices, getManualInvoiceDetails, getInvoiceDetails, isInvoiceLocked, handleEditOrder, getOrderDetails, regenerateInvoice, refreshBetaScreen,
  } = props;

  const [ orders, setOrders ] = useState(initialOrders);
  const [ selectedOrder, setSelectedOrder ] = useState(orders[ 0 ]);
  const [ searchTerm, setSearchTerm ] = useState('');
  const [ isAscending, setIsAscending ] = useState(true);
  const [ selectedOrderId, setSelectedOrderId ] = useState(orders[ 0 ].customer_order_id);
  const [ accessorials, setAccessorials ] = useState([]);
  const [ levelOfServices, setLevelOfServices ] = useState([]);
  const [ config, setConfig ] = useState({});
  const [ logo, setLogo ] = useState('');
  const [ invoiceLos, setInvoiceLos ] = useState({});
  const [ invoice, setInvoice ] = useState(currentInvoice || {});
  const [ inProgress, setInProgress ] = useState(false);
  const [ errors, setErrors ] = useState([]);
  const [ currentStatus, setCurrentStatus ] = useState("");
  const [ disableRemarksButton, setDisableRemarksButton ] = useState(true);
  const [ currentLos, setCurrentLos ] = useState("");
  const [ currentOrderPics, setCurrentOrderPics ] = useState({});
  const [ imagesVisible, setImagesVisible ] = useState(false);
  const [ showAddOrders, setShowAddOrders ] = useState(false);
  const [ showEmailModal, setShowEmailModal ] = useState(false);
  const [ isEmailLoading, setIsEmailLoading ] = useState(false);
  const [ billingEmails, setBillingEmails ] = useState([]);
  const [ contacts, setContacts ] = useState([]);
  const [ subject, setSubject ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ isSendingMail, setIsSendingMail ] = useState(false);
  const [ additionalOrders, setAdditionalOrders ] = useState("");
  const [ isRemovingGroupOrder, setIsRemovingGroupOrder ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ ordersAddingProgress, setOrdersAddingProgress ] = useState(false);
  const [ is_pickup_by_driver, setIsPickupByDriver ] = useState(_get(currentInvoice, 'pickup_driver_pay_details.is_pickup_by_driver', 'false'));
  const [ pickup_order_driver_id, setPickupOrderDriverId ] = useState(_get(currentInvoice, 'pickup_driver_pay_details.pickup_order_driver_id', ''));
  const [ pickup_order_amount, setPickupOrderAmount ] = useState(_get(currentInvoice, 'pickup_driver_pay_details.pickup_order_amount', null));
  const [ decimalPoints, setDecimalPoints ] = useState(window.localStorage.getItem('round_off_decimals'));
  const [ isRepproveLoading, setIsRepproveLoading ] = useState(false);
  const [ invoiceTotal, setInvoiceTotal ] = useState(invoice.total_amount);
  const [ detailsVisible, setDetailsVisible ] = useState(false);
  const [ comments, setComments ] = useState("");
  const isInvoiceApproved = invoice.status === "APPROVED";
  const hasConsolidatedOrders = orders.find(order => order.is_consolidated === 'true');
  const isReadyOnly = isLocked || invoice.status === "PAYMENT_CONFIRMED";
  const [drivers, setDrivers] = useState([]);
  const [warehouses, setWarehouses] = useState([]); 
  const warehouseFilter = useContext(WarehouseContext);
  const [selectedInfo, setSelectedInfo] = useState({});


  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
  }, [
    warehouseFilter.selectedWarehouses,
    warehouseFilter.warehouses,
  ]);

  const currentWh =
  typeof warehouseFilter.selectedWarehouses === "string"
    ? _.find(warehouseFilter.warehouses, {
        id: warehouseFilter.selectedWarehouses,
      })
    : _.find(warehouseFilter.warehouses, {
        id: warehouseFilter.selectedWarehouses[0],
      });

  useEffect(() => {
    driversList();
    updateSelectedInfo()
  }, []);

  const updateSelectedInfo = () => {
    const selectedInfo = {
      selectedKeys: orders.map((order) => order.customer_order_id),
      selectedRows: [...orders],
    };
    setSelectedInfo( selectedInfo );
  }

  const driversList = () => {
    setInProgress(true);
    fetchUsers("driver")
      .then((result) => {
        if (result.success) {
          const updatedDrivers = result.users.map((driver) => ({
            id: driver.id,
            employee_code: driver.employee_code,
            full_name: driver.full_name,
          }));
  
          setDrivers(updatedDrivers);
          // setSelectedDriver(updatedDrivers[0]?.id);
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setInProgress(false);
      });
  };
  
  useEffect(() => {
    setOrders(initialOrders);
  }, [ initialOrders ]);

  useEffect(() => {
    setInvoice(currentInvoice);
  }, [ currentInvoice ]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleSort = () => {
    setIsAscending(!isAscending);
  };

  const calculateJobTotal = useCallback((order) => {
    const totalPriceList = _.concat(
      _get(order, 'transportation_charges', []),
      _get(order, 'fuel_surcharges', []),
      _get(order, 'accessorial_charges', []),
      _get(order, 'adjustment_charges', [])
    );
    return findNumberTotal(totalPriceList, 'invoice_value', null);
  }, []);

  // whenever order.transportation_charges,fuel_surcharges,accessorial_charges,adjustment_charges changes
  // update invoice total
  useEffect(() => {
    const total = orders.reduce((acc, order) => {
      const orderTotal = calculateJobTotal(order);
      return acc + parseFloat(orderTotal);
    }, 0);
    setInvoiceTotal(total);
  }, [ orders ]);


  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setSelectedOrderId(order.customer_order_id);
  };

  const sortedOrders = [ ...orders ].sort((a, b) => isAscending ? a.customer_order_number.localeCompare(b.customer_order_number) : b.customer_order_number.localeCompare(a.customer_order_number));
  const filteredOrders = sortedOrders.filter(order => order.customer_order_number.includes(searchTerm));

  const getAccessorials = async () => {
    const account = invoice.account_details
      ? invoice.account_details
      : {};
    const result = await fetchAccountAccessorials(account.id, "ALL");
    return result;
  };

  const getAccountLos = async () => {
    const accountCode =
      invoice && invoice.account_details
        ? invoice.account_details.code
        : "";
    const result = await fetchAccountShortLos(accountCode);
    return result;
  };

  const getAccountConfigs = async () => {
    const result = await fetchAccountConfigs(
      invoice.account_details.id,
      userStore.getStateValue("selectedOrg"),
      "ALL"
    );
    return result;
  };

  const getOrgLogo = async () => {
    const orgId = userStore.getStateValue("selectedOrg");
    try {
      const result = await fetchOrganizationDetails(orgId);
      if (_.get(result, 'success')) {
        const organization = _.get(result, 'organization', {});
        const logoUrl = _.get(organization, 'logo.url', "");

        if (!_.isEmpty(logoUrl)) {
          setLogo(logoUrl);
        } else if (!_.isEmpty(orgId)) {
          const urlToFile = `${AppConfig.baseUrl}/org_logos/${orgId}/org_logo.png`;
          const response = await fetch(urlToFile);
          if (response.status === 200) {
            setLogo(urlToFile);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAndReapprove = async () => {
    setIsRepproveLoading(true)
    const selectedIds = selectedInfo?.selectedKeys
      ? _.compact(selectedInfo.selectedKeys)
      : [];
      try {
        const payload = {
          customer_order_ids: selectedIds,
          organization_id: userStore.getStateValue("selectedOrg"),
          invoice_number: invoice.order_number
        };
        if (is_pickup_by_driver === "true") {
          payload.is_pickup_by_driver = "true";
          payload.pickup_order_driver_id = pickup_order_driver_id;
          payload.pickup_order_amount = pickup_order_amount;
        }
        let response = {};
        response = await reapproveBillings(payload);
        if (response.success) {
          const result = response?.invoices ? response.invoices : {};
          const successMessage = result?.message
            ? result.message
            : I18n.t("messages.saved");
          //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
          alertMessage(successMessage);
          handleApproveSuccess("APPROVED")
        } else {
          throw new Error(response.errors[0]);
        }
      } catch (error) {
        alertMessage(error.message, "error", 10);
      } finally {
        setIsRepproveLoading(false)
      }
  };

  const processMappedInvoiceLos = (orders) => {
    return orders.reduce((acc, order) => {
      const orderInfo = order.orders_info[ 0 ];
      acc[ orderInfo.order_id ] = orderInfo.level_of_service;
      return acc;
    }
      , {});
  };

  const attachAccountLos = () => {
    const los = _get(invoice, 'order_info.level_of_service', '');
    setCurrentLos(los);
  };

  const handleAccountConfigResponse = (accountConfigsData) => {
    const accountConfigurations = _.get(accountConfigsData, 'account_configurations', []);
    let newConfig = { ...config };
    _.forEach(accountConfigurations, (setting) => {
      _.set(newConfig, setting.setting_name, setting.setting_value);
    });
    if (_.isString(newConfig.pod_pic_settings) && newConfig.pod_pic_settings === "[]") {
      newConfig.pod_pic_settings = [];
    }
    newConfig.pod_pic_settings = updatePicSettingsWithBol(newConfig.pod_pic_settings);
    if (_.isEmpty(newConfig.communication_preference)) {
      newConfig.communication_preference = "SMSES";
    }
    setConfig(newConfig);
    if (accountConfigsData.errors) {
      renderAlertMessage(accountConfigsData.errors);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessorialsData = await getAccessorials();
      setAccessorials(accessorialsData.accessorials || []);

      const accountLosData = await getAccountLos();
      setLevelOfServices(accountLosData.account.account_los || []);
      attachAccountLos();

      setInvoiceLos(processMappedInvoiceLos(orders));

      const accountConfigsData = await getAccountConfigs();
      // setConfig(accountConfigsData.config || {});
      handleAccountConfigResponse(accountConfigsData);

      await getOrgLogo();
    };

    fetchData();
  }, []);

  const showAddOrdersModal = () => {
    setShowAddOrders(true);
  };

  const getEmails = () => {
    const account = invoice.account_details
      ? invoice.account_details
      : {};
    const accountId = account.id ? account.id : "";
    setIsEmailLoading(true);
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        setContacts(result.contact_emails || []);
        setIsEmailLoading(false);

      } else {
        renderAlertMessage(result.errors);
        setIsEmailLoading(false);
        setContacts([]);
      }
    });
  };

  const CloseButton = () => (
    checkServiceExistance([ "BLUS", "BLSIND" ], "ANY") &&
    (showActions === true || isInvoiceApproved && !isLocked) && (
      <Button
        onClick={ () => {
          props.onCancel();
          if (invoice.status === "APPROVED") {
            getInvoices();
          }
        } }
        icon="close"
        size='small'
        className='marginLeft10'
      >
        { I18n.t("general.close") }
      </Button>
    )
  );
  const handleSave = () => {
    const invoiceSavePayload = {
      new_invoice_lines: [],
      remark: comments || "",
      reference_id:
        invoice.order_group_type === "ORDER"
          ? invoice.customer_order_id
          : invoice.order_number,
      order_group_type: invoice.order_group_type,
      status: invoice.status,
    };
    updateInvoice(invoiceSavePayload).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        setInProgress(false);
        setComments("");
        getInvoices();
        refreshDetails();
      } else {
        alertMessage(result.errors, "error", 10);
        setInProgress(false);
      }
    });
  };


  const SaveButton = () => (
    checkServiceExistance("BLSIND") &&
    showActions === true && (
      <Button type="primary" onClick={ handleSave } icon='save'
        size='small'
      >
        Save
      </Button>
    )
  );

  const SaveAndReapproveButton = () => (
    isInvoiceApproved && !isLocked && (
      <Button type="primary" onClick={ () => handleSaveAndReapprove() } icon='save' loading={ isRepproveLoading }
        size='small'
      >
        Save & Re-Approve
      </Button>
    )
  );


  const handleApproveSuccess = (status) => {
    setInvoice({ ...invoice, status });
  };

  const renderApproveInvoicesWhenApproved = () => {
    if (showActions && !hasConsolidatedOrders && checkServiceExistance("BLUS") && !isLocked && !isReadyOnly && invoice.status === "APPROVED") {
      return (
        <div style={ { marginLeft: 10 } }>
          <ApproveInvoices
            selectedInfo={ {
              selectedKeys: [ invoice.customer_order_id ],
            } }
            action={ invoice.status === "READY_FOR_BILLING" ? "APPROVED" : "REAPPROVED" }
            buttonType="danger"
            isDisabled={ false }
            showActions={ showActions }
            label={ invoice.status === "READY_FOR_BILLING" ? I18n.t("invoices.approve") : I18n.t("invoices.reapprove") }
            handleSuccess={ () => handleApproveSuccess("APPROVED") }
            handleCancel={ null }
            screenFrom="form"
            is_pickup_by_driver={ is_pickup_by_driver }
            pickup_order_driver_id={ pickup_order_driver_id }
            buttonSize="small"
            pickup_order_amount={ pickup_order_amount }
          />
        </div>
      );
    }
    return null;
  };

  const renderApproveInvoicesWhenNotExceptionOrApproved = () => {
    if (showActions && !hasConsolidatedOrders && checkServiceExistance("BLUS") && !isLocked && !isReadyOnly && ![ "EXCEPTION", "APPROVED" ].includes(invoice.status)) {
      return (
        <div style={ { marginLeft: 10 } }>
          <ApproveInvoices
            buttonSize="small"
            selectedInfo={ {
              selectedKeys: [ invoice.customer_order_id ],
            } }
            action={ invoice.status === "READY_FOR_BILLING" ? "APPROVED" : "REAPPROVED" }
            buttonType="danger"
            isDisabled={ false }
            label={ invoice.status === I18n.t("invoices.ready_for_billing") ? I18n.t("invoices.approve") : invoice.status === "APPROVED" ? I18n.t("invoices.payment_confirmed") : I18n.t("invoices.close") }
            handleSuccess={ () => handleApproveSuccess("APPROVED") }
            handleCancel={ null }
            screenFrom="form"
            is_pickup_by_driver={ is_pickup_by_driver }
            pickup_order_driver_id={ pickup_order_driver_id }
            pickup_order_amount={ pickup_order_amount }
          />
        </div>
      );
    }
    return null;
  };

  const BillingPdfDownloadButton = () => (
    showActions === true && (
      <BillingPdfDownload
        className={ "buttonCoolBlue marginLeft10" }
        buttonType="primary"
        size="small"
        label={ I18n.t("general.print") }
        // isDisabled={ selectedInfo.selectedKeys.length === 0 }
        handleSuccess={ () => { } }
        orderIds={selectedInfo.selectedKeys ? [...selectedInfo.selectedKeys] : []}
      />
    )
  );
  // const rendershowEmailModal = () => {
  //   const orderNumbers = invoice.transportation_charges.map(charge => charge.order_number);
  //   const orderCount = orderNumbers.length > 1 ? ('_(' + (orderNumbers.length - 1) + '_more)') : '';
  //   setSubject(orderNumbers?.length ? `${orderNumbers[ 0 ]} ${orderCount} ${'BILLING_PDF'}` : `${orderNumbers}`);
  //   setDescription("Please find the billing attachment");
  //   setShowEmailModal(true);
  //   getEmails();
  // };
  // const BillingActions = ({ showActions, isLocked, isSendingMail, showRemarks }) => {
  //   return (
  //     <Row gutter={ 8 } type='flex'>
  //       { showActions && (
  //         <Col>
  //           <Button
  //             type="primary"
  //             size="small"
  //             disabled={ isLocked }
  //             onClick={ showAddOrdersModal }
  //             icon="plus"
  //           >
  //             Add Orders
  //           </Button>
  //         </Col>
  //       ) }
  //       { showActions && (
  //         <Col>
  //           <Button
  //             type="primary"
  //             size="small"
  //             icon="mail"
  //             onClick={ rendershowEmailModal }
  //             loading={ isSendingMail }
  //             style={ {
  //               backgroundColor: '#1890ff',
  //               borderColor: '#1890ff',
  //               color: '#fff',
  //             } }
  //           >
  //             { I18n.t('general.send_item', { item: I18n.t('invoices.billing') }) }
  //           </Button>
  //         </Col>
  //       ) }
  //       { showActions === false && (
  //         <Col>
  //           <BillingPdfDownload
  //             buttonType="primary"
  //             size="small"
  //             label={ I18n.t("general.print") }
  //             handleSuccess={ () => { } }
  //             orderIds={ [ invoice.customer_order_id ] }
  //           />
  //         </Col>
  //       ) }
  //       { !showRemarks && !isLocked && (
  //         <Row gutter={4} type='flex'>
  //         <Col>
  //           <SaveButton />
  //         </Col>
  //         <Col>
  //           <SaveAndReapproveButton />
  //         </Col>
  //         <Col>
  //           <ApproveInvoicesButton />
  //         </Col>
  //         <Col>
  //           <BillingPdfDownloadButton />
  //         </Col>
  //         <Col>
  //           <CloseButton />
  //         </Col>
  //         </Row>
  //       ) }
  //     </Row>
  //   );
  // };

  const rendershowEmailModal = () => {
    const orderNumbers = invoice.transportation_charges.map(charge => charge.order_number);
    const orderCount = orderNumbers.length > 1 ? `(_${orderNumbers.length - 1}_more)` : '';
    setSubject(orderNumbers?.length ? `${orderNumbers[0]} ${orderCount} BILLING_PDF` : `${orderNumbers}`);
    setDescription("Please find the billing attachment");
    setShowEmailModal(true);
    getEmails();
  };
  
  const renderButton = (type, size, icon, onClick, loading, style, text) => (
    <Col>
      <Button
        type={type}
        size={size}
        icon={icon}
        onClick={onClick}
        loading={loading}
        style={style}
      >
        {text}
      </Button>
    </Col>
  );
  
  const BillingActions = ({ showActions, isLocked, isSendingMail, showRemarks }) => {
    const buttons = [
      { type: "primary", size: "small", icon: "plus", onClick: showAddOrdersModal, text: "Add Orders" },
      { type: "primary", size: "small", icon: "mail", onClick: rendershowEmailModal, loading: isSendingMail, style: { backgroundColor: '#1890ff', borderColor: '#1890ff', color: '#fff' }, text: I18n.t('general.send_item', { item: I18n.t('invoices.billing') }) },
    ];
  
    return (
      <Row gutter={ 8 } type='flex'>
        { showActions && (
          <Col>
            <Button
              type="primary"
              size="small"
              disabled={ isLocked }
              onClick={ showAddOrdersModal }
              icon="plus"
            >
              Add Orders
            </Button>
          </Col>
        ) }
        <Col>
          <Button
            type="primary"
            size="small"
            icon="mail"
            onClick={ rendershowEmailModal }
            loading={ isSendingMail }
            style={ {
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              color: '#fff',
            } }
          >
            { I18n.t('general.send_item', { item: I18n.t('invoices.billing') }) }
          </Button>
        </Col>
        { showActions === false && (
          <Col>
            <BillingPdfDownload
              buttonType="primary"
              size="small"
              label={I18n.t("general.print")}
              handleSuccess={() => { }}
              orderIds={[invoice.customer_order_id]}
            />
          </Col>
        ) }
        { !showRemarks && !isLocked && (
          <>
            <SaveButton />
            <SaveAndReapproveButton />
            {/* <ApproveInvoicesButton /> */ }
            { renderApproveInvoicesWhenApproved() }
            { renderApproveInvoicesWhenNotExceptionOrApproved() }
            <BillingPdfDownloadButton />
            <CloseButton />
          </>
        ) }
      </Row>
    );
  };


  const addOrdersToInvoice = () => {
    const payload = {
      organization_id: userStore.getStateValue("selectedOrg"),
      customer_order_id: invoice.customer_order_id,
      new_customer_order_numbers: additionalOrders,
    };
    // this.setState({
    //   ordersAddingProgress: true,
    // });
    setOrdersAddingProgress(true);
    addOrdersForBillingGroup(payload).then((result) => {
      if (result.success) {
        setOrdersAddingProgress(false);
        setShowAddOrders(false);
        setAdditionalOrders("");
        alertMessage(I18n.t("messages.orders_added"));
        refreshDetails();
        // this.props.getInvoiceDetails(
        //   this.props.currentInvoice.customer_order_id
        // );
      } else {
        renderAlertMessage(result.errors);
        setOrdersAddingProgress(false);
        // this.setState({
        //   ordersAddingProgress: false,
        // });
      }
    });
  };

  const renderAddOrders = () => {
    return (
      <BaseModal
        title={ I18n.t("general.customer_orders") }
        className="customer-orders-modal"
        onCancel={ () => {
          setShowAddOrders(false);
          setAdditionalOrders("");
        } }
        width="65%"
        maskClosable={ false }
      >
        <Spin spinning={ ordersAddingProgress } delay={ 1000 }>
          <div className="tagsInput">
            <Row type="flex" gutter={ 4 }>
              <Col span={ 22 }>
                <TagsInput
                  value={
                    additionalOrders.length ? additionalOrders.split(",") : []
                  }
                  onChange={ (orderNums) =>
                    setAdditionalOrders(orderNums.join(","))
                  }
                  validationRegex={ /^[a-zA-Z0-9_-]+$/ }
                  onlyUnique
                  addOnPaste
                  pasteSplit={ customPasteSplit }
                  inputProps={ {
                    placeholder: "Add Orders",
                  } }
                  addOnBlur={ true }
                  autoFocus
                />
              </Col>
              <Col span={ 2 }>
                <Button type="primary" onClick={ addOrdersToInvoice } icon="plus">
                  Add
                </Button>
              </Col>
            </Row>
          </div>
        </Spin>
      </BaseModal>
    );
  };

  const handleSendingMails = () => {
    if (!billingEmails.length) return alertMessage('Please enter atleast one email address', 'error');

    const account = invoice.account_details
      ? invoice.account_details
      : {};

    const allCustomerOrderIds = orders.map(order => order.customer_order_id);
    const payload = {
      account_id: account.id,
      customer_order_ids: allCustomerOrderIds,
      organization_id: userStore.getStateValue('selectedOrg'),
      email_ids: billingEmails,
      subject: subject,
      description: description,
    };
    setIsSendingMail(true);
    SendInvoiceEmail(payload).then((result) => {
      if (result.success) {
        alertMessage(result.message, 'success', 5);
      } else {
        alertMessage(result.message, 'error', 5);
      }
    }
    ).finally(() => { setIsSendingMail(false); }
    );
  };

  const addBilligEmail = (value) => {
    const validEmails = validateEmailList(value);
    setBillingEmails(validEmails);
  };

  const renderEmailModal = () => {
    const description = "Description"; // I18n.t("general.body")
    const subject = "Subject"; // I18n.t("general.subject")
    return (
      <BaseModal
        title={ I18n.t("general.send_to", { from: I18n.t("invoices.billing"), to: "Emails" }) }
        onCancel={ () => {
          setShowEmailModal(false);
          setBillingEmails([]);
        } }
        width="50%"
        style={ { top: 40 } }
        maskClosable={ false }
      >
        <Spin spinning={ isEmailLoading }>
          <Row className="tagsInput emailForm" type='flex' align='middle' gutter={ 8 }>
            <Col span={ 21 }>
              <FormItem label={ I18n.t('general.email') }>
                <Select
                  value={ billingEmails }
                  onChange={ addBilligEmail }
                  mode="tags"
                  validationRegex={ addBilligEmail }
                  style={ { width: "100%" } }
                  showSearch
                  filterOption={ (input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Enter or select Emails"
                  tokenSeparators={ [ "," ] }
                >
                  {
                    contacts.map(opt => <Option key={ opt }>{ opt }</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
            <Col span={ 3 } className="alignRight">
              <Button onClick={ handleSendingMails } type="primary" loading={ isSendingMail } icon='mail'>
                Send
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={ subject }>
                <Input
                  rows={ 4 }
                  placeholder={ subject }
                  className="sop-form-textArea"
                  value={ subject }
                  // onChange={ (e) => this.setState({ subject: e.target.value }) }
                  onChange={ (e) => setSubject(e.target.value) }
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={ description }>
                <TextArea
                  rows={ 4 }
                  placeholder={ description }
                  className="sop-form-textArea"
                  value={ description }
                  // onChange={ (e) => this.setState({ description: e.target.value }) }
                  onChange={ (e) => setDescription(e.target.value) }
                />
              </FormItem>
            </Col>
          </Row>
        </Spin>

      </BaseModal>
    );
  };

  const refreshCallback = () => {
    if (typeof props.refreshCallback === 'function') {
      props.refreshCallback();
    }
  };

  const [ requireRefresh, setRequireRefresh ] = useState(false);
  const onClose = () => {
    if (requireRefresh) {
      refreshCallback();
    }
    setDetailsVisible(false);
    setRequireRefresh(false);
    // if (is_updated) {
      refreshCallback();
    // }
    // forPopOverClose(this.props.order_no)
  };

  const handleOrderclonedStatus = (isCloned) => {
    setRequireRefresh(isCloned);
  };

  const refreshDetails = () => {
    if (invoice.order_group_type === "MANUAL") {
      getManualInvoiceDetails(invoice.order_number);
    } else {
      refreshBetaScreen();
      getInvoiceDetails(invoice.customer_order_id);
    }
  };

  const getSignatureAndCreds = () => {
    let signatureBy = null;
    let titleByRelation = null;
    let capturedBy = null;
    let timeZone = null;
  
    if (
      !_.isEmpty(selectedOrder) &&
      !_.isEmpty(selectedOrder.pod_pictures) &&
      !_.isEmpty(selectedOrder.pod_pictures[0].pictures)
    ) {
      const signaturePicture = selectedOrder.pod_pictures[0].pictures.find(
        (picture) => picture.image_type === "signature"
      );
      if (signaturePicture) {
        signatureBy = signaturePicture.sign_by;
        titleByRelation = signaturePicture.title_by_relation;
        capturedBy = signaturePicture.captured_at;
      }
      timeZone = selectedOrder.pod_pictures[0].order_tz_short_name;
    }
  
    return (
      <Fragment>
        <Row>
          <Col xs={24}>
            <h4 className="alignLeft">
              {I18n.t("order.sign_by")}{" "}
              <span className="textCaptilize">{signatureBy}</span>{" "}
              {!isEmpty(titleByRelation) && (
                <span className="textCaptilize">( {titleByRelation} )</span>
              )}
              {capturedBy
                ? `at ${formatByTimeConfig(
                    moment.utc(capturedBy),
                    organizationSettings["is_military_time"],
                    "Do MMM YYYY, HH:mm",
                    "Do MMM YYYY, hh:mm A"
                  )} ${timeZone ? timeZone : ""}`
                : ""}
            </h4>
          </Col>
        </Row>
      </Fragment>
    );
  };
  
  


  return (
    <div>

      <Spin spinning={ isLoading } delay={ 500 }>
        { detailsVisible && (
          <Drawer
            placement="right"
            closable={ false }
            visible={ detailsVisible }
            width="90%"
            className="orderDrawerScroll"
          >
            <div className="order_details">
              <DetailsConfigComponent
                order_id={ selectedOrderId }
                key={ selectedOrderId }
                editClick={ handleEditOrder }
                onClose={ onClose }
                showEdit={ props.showEdit }
                tab={ "" }
                updateParentState={ () => { } }
                showCloneButton={ props.showCloneButton }
                handleOrderclonedStatus={ handleOrderclonedStatus }
                setRefreshValue={ (val) => { setRequireRefresh(val); } }
              />
            </div>
          </Drawer>
        ) }
        { showAddOrders && renderAddOrders() }
        { showEmailModal && renderEmailModal() }
        <Row type='flex' justify='space-between' align='middle' style={ {
          marginBottom: '20px',
        } }>
          <Col >
            <img
              src={ logo }
              alt="Fleetenable"
              style={ { maxWidth: '10rem', height: 'auto' } }
            />
          </Col>
          <Col>
            <BillingActions showActions={ showActions } isLocked={ isLocked } isSendingMail={ isSendingMail } />
          </Col>
          <Col >
            <Title level={ 4 } style={ { textAlign: 'right' } }>
              { I18n.t("invoices.total") }: { checkNegitive(invoiceTotal) }
            </Title>
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={ 4 }>
            <Row>
              <Col>
                <Row gutter={ 4 } type='flex' align='middle'>
                  <Col span={ 14 }>
                    <Input.Search
                      placeholder="Search "
                      onSearch={ handleSearch }
                      style={ { marginBottom: '20px' } }
                    />
                  </Col>
                  <Col span={ 10 }>
                    <Button onClick={ handleSort } style={ { marginBottom: '20px' } }>
                      Sort { isAscending ? <Icon type="arrow-up" /> : <Icon type="arrow-down" /> }
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <div style={ { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' } }>
                  { filteredOrders.map(order => (
                    <OrderCard
                      key={ order.customer_order_id }
                      order={ order }
                      orders={ orders }
                      setOrders={ setOrders }
                      selectedOrder={ selectedOrder }
                      onClick={ () => handleOrderClick(order) }
                      selectedOrderId={ selectedOrderId }
                      handleEditOrder={ handleEditOrder }
                      setDetailsVisible={ setDetailsVisible }
                      invoice={ invoice }
                      setIsRemovingGroupOrder={ setIsRemovingGroupOrder }
                      getInvoices={ getInvoices }
                      refreshDetails={ refreshDetails }
                      style={ { border: '1px solid #ccc', backgroundColor: '#f9f9f9', fontSize: '16px' } }
                      setSelectedOrder={ setSelectedOrder }
                      setSelectedOrderId={ setSelectedOrderId }
                      showActions={ showActions }
                      isLocked={ isLocked }
                      accessorials={ accessorials }
                      showisConsolidation= {true}
                    />
                  )) }
                </div>
              </Col>
            </Row>

          </Col>
          <Col span={ 20 } style={ { overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' } }>
            {/* <div style={ { overflowY: 'auto', maxHeight: '70vh' } }> */ }
            <div>
              <OrderDetails
                drivers={ drivers }
                setInvoice={ setInvoice }
                warehouses={ warehouses }
                setIsLoading={ setIsLoading }
                regenerateInvoice={ regenerateInvoice }
                glCodesList={ glCodesList }
                getInvoiceDetails={ getInvoiceDetails }
                getManualInvoiceDetails={ getManualInvoiceDetails }
                order={ selectedOrder }
                invoice={ invoice }
                showActions={ showActions }
                decimalPoints={ decimalPoints }
                isInvoiceLocked={ isInvoiceLocked }
                isLocked={ isLocked }
                invoiceLos={ invoiceLos }
                setInvoiceLos={ setInvoiceLos }
                levelOfServices={ levelOfServices }
                config={ config }
                setOrder={ setSelectedOrder }
                setErrors={ setErrors }
                setInProgress={ setInProgress }
                setIsSaving={ setIsSaving }
                accessorials={ accessorials }
                getInvoices={ getInvoices }
                setOrders={ setOrders }
                orders={ orders }
                handleSaveAndReapprove = {handleSaveAndReapprove}
              />
            </div>
              <OrderPods
                order={ selectedOrder }
                invoice={ invoice }
                showActions={ showActions }
                organizationSettings={ organizationSettings }
                config={ config }
                refreshDetails={ () => getInvoiceDetails(invoice.customer_order_id) }
                getSignatureAndCreds={ getSignatureAndCreds()}
              />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};


const ConfirmButton = ({ action, onConfirm }) => {
  const handleClick = () => {
    // List of allowed actions
    const allowedActions = [ 'Remove' ];

    if (!allowedActions.includes(action.title)) {
      onConfirm();
      return;
    }

    Modal.confirm({
      title: 'Are you sure?',
      content: 'Confirm to proceed',
      onOk () {
        onConfirm();
      },
    });
  };

  return (
    <Tooltip title={ action.title }>
      <Icon
        type={ action.icon }
        style={ action.style }
        onClick={ handleClick }
      />
    </Tooltip>
  );
};

const OrderCard = (props) => {
  const { order, setOrders, onClick, selectedOrderId, handleEditOrder, setDetailsVisible, invoice, setIsRemovingGroupOrder, refreshDetails, getInvoices, isLocked, showActions, orders, setSelectedOrder, setSelectedOrderId, showisConsolidation} = props;
  const isSelected = order.customer_order_id === selectedOrderId;
  const isConsolidated = order.is_consolidated === "true";
  // Function to calculate job total
  const calculateJobTotal = () => {
    const totalPriceList = _.concat(
      _get(order, 'transportation_charges', []),
      _get(order, 'fuel_surcharges', []),
      _get(order, 'accessorial_charges', []),
      _get(order, 'adjustment_charges', [])
    );
    return findNumberTotal(totalPriceList, 'invoice_value', null);
  };

  const actions = [
    { title: 'View', icon: 'eye' },
    { title: 'Edit', icon: 'edit' },
    { title: 'Remove', icon: 'delete', style: { color: '#ff4d4f' } },
  ];

  const removeGroupedOrder = (order_id, invoice) => {
    const orgId = userStore.getStateValue("selectedOrg");
    const payload = {
      organization_id: orgId,
      customer_order_id: order_id,
      order_group_type: invoice.order_group_type,
      fmp_order_id: getFMPOrderFromInvoice(invoice),
    };
    // this.setState({ isRemovingGroupOrder: true });
    setIsRemovingGroupOrder(true);
    removeOrderFromBillingGroup(payload)
      .then((result) => {
        if (result.success) {
          refreshDetails();
          // its currently selected
          if (isSelected) {
            const newOrders = orders.filter((i) => i.customer_order_id !== order_id);
            setOrders(newOrders);
            setSelectedOrderId(newOrders[ 0 ].customer_order_id);
            setSelectedOrder(newOrders[ 0 ]);

          }
          alertMessage(I18n.t("messages.removed_successfully"), 10);
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        setIsRemovingGroupOrder(false);
        getInvoices();
      });
  };

  const handleOrderAction = (action, order) => {
    switch (action) {
      case 'View':
        setDetailsVisible(true);
        break;
      case 'Edit':
        handleEditOrder(order.customer_order_id);
        break;
      case 'Remove':
        removeGroupedOrder(
          order.customer_order_id,
          invoice
        );
        break;
    }
  };

  const showSMP = isLocked ? false : (showActions ? true : false);

  const showOrderDeletion = (orders.length > 1 && invoice.order_group_type === "MOVE") || (invoice.order_group_type === "GROUP" && showActions === true);

  return (
    <Card
      hoverable
      className={ isSelected ? 'selected-order' : '' }
      style={ {
        marginBottom: '5px',
        cursor: 'pointer',
        border: '1px solid #e8e8e8',
        borderRadius: '4px',
        borderColor: isSelected ? '#40a9ff' : '#e8e8e8',
        backgroundColor: isSelected ? '#e6f7ff' : '#fff'
      } }
      size='small'
      onClick={ () => onClick(order) }
      actions={ actions.filter(action => {
        if (action.title === 'Remove') {
          return showOrderDeletion;
        }
        return true;
      }).map((action) => (
        <ConfirmButton
          action={ action }
          onConfirm={ () => {
            // Code to perform the action goes here
            handleOrderAction(action.title, order);
          } }
        />
      )) }
    >
      <Card.Meta
        title={
          <span>
            {showisConsolidation && isConsolidated && (
                <ConsolidationButton
                  consolidation_no={order.consolidation_number}
                  consolidate_order_id={order.consolidate_order_id}
                  handleSuccess={() =>
                    props.getInvoiceDetails(order.customer_order_id)
                  }
                  displayType="icon"
                  className={"marginLeft10"}
                  label={I18n.t("consolidation.consolidation_details")}
                  orders={[order]}
                  key={`conslodiate${order.customer_order_id}`}
                  styleObj={{ marginRight: 7 }}
                />
            )}
            { order.customer_order_number } { " " }
            <Copyable text={ order.customer_order_number } />{ " " }
            { showSMP && order.is_smp_applied && <Badge count={ 'SMP' } style={ { backgroundColor: '#ff4d4f', marginLeft: '10px', padding: '0 6px', borderRadius: '2px', color: '#fff' } } /> }
          </span>
        }
        description={ `Job Total: ${checkNegitive(calculateJobTotal())}` } // Display job total here
      />
    </Card>
  );
};

const InstructionsDisplay = ({ data }) => {
  return (
    <div style={ { border: '1px solid #e8e8e8', padding: '10px', borderRadius: '4px', marginBottom: '10px', backgroundColor: '#fff', marginTop: '5px' } }>
      <Row>
        <Col span={ 24 }>
          <Typography.Text strong>Notes:</Typography.Text>
          <Typography.Text>{ data.notes }</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={ 24 }>
          <Typography.Text strong>Instructions 1:</Typography.Text>
          <Typography.Text>{ data.instructions_from_account_1 }</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={ 24 }>
          <Typography.Text strong>Instructions 2:</Typography.Text>
          <Typography.Text>{ data.instructions_from_account_2 }</Typography.Text>
        </Col>
      </Row>
    </div>
  );
};

const OrderDetails = (props) => {
  const { getInvoiceDetails, order, invoice, isInvoiceLocked, showActions, decimalPoints, invoiceLos, setInvoiceLos, levelOfServices, config, setOrder, setErrors, setInProgress, accessorials, setIsSaving, getInvoices, getManualInvoiceDetails, glCodesList, setOrders, orders, isLocked, setIsLoading , setInvoice , drivers,handleSaveAndReapprove } = props;
  const isReadyOnly = invoice.status === "PAYMENT_CONFIRMED";
  const getNestedValue = (obj, path, defaultValue = 'N/a') => {
    return _get(obj, path, defaultValue);
  };
  // const orderDriverId = _.get(invoice, 'driver_details', '');
  // const [openModal, setOpenModal] = useState(false);
  // const [selectedDriver, setSelectedDriver] = useState("");
  // const [driverCardLoading, setDriverCardLoading] = useState(false);
  // const [currentDriverId, setCurrentDriverId] = useState(_.get(orderDriverId, 'driver_id', ''));
  // const [driverObj, setDriverObj] = useState({
  //   driver_id: _.get(orderDriverId, 'driver_id', ''),
  //   code: _.get(orderDriverId, 'code', ''),
  //   name: _.get(orderDriverId, 'name', ''),
  //   contact: _.get(orderDriverId, 'contact', ''),
  // });

  const [isRegeneratingLos, setIsRegeneratingLos] = useState(false);
  const isTransfer = ['T', 'LH'].includes(order.type_of_order);

  const refreshDetails = () => {
    if (invoice.order_group_type === "MANUAL") {
      getManualInvoiceDetails(invoice.order_number);
    } else {
      getInvoiceDetails(invoice.customer_order_id);
    }
  };

  // const handleDriverChnage = (value) => {
  //   setSelectedDriver(value);
  // }  

  // const handleCancel = () => {
  //   setOpenModal(false);
  // };

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  // };

  // const handleSubmit = async () => {
  //   setDriverCardLoading(true);
  //   const order = invoice.order_info?.order_id;
  //   const orderIds = invoice.transportation_charges.map(
  //     (charge) => charge.order_id
  //   );
  //   const payload = {
  //     order_ids: orderIds,
  //     old_driver_id: currentDriverId,
  //     new_driver_id: selectedDriver,
  //     warehouse_id: currentWh ? currentWh.id : '',
  //   };

  //   try {
  //     const updateResult = await UpdateDriver(payload);
  //     if (updateResult.success) {
  //       alertMessage("Driver Updated Successfully", "success", 10);
  //       const billingResult = await fetchBillingDetails(order);
  //       if (billingResult.success) {
  //         const driverInfo = billingResult?.invoice.driver_details || {};
  //         const driverId = driverInfo?.driver_id;
  //         const driverName = driverInfo?.name;
  //         const driverCode = driverInfo?.code;
  //         const driverNo = driverInfo?.contact;
  //         setCurrentDriverId(driverId);
  //         setDriverObj({
  //           driver_id: driverId,
  //           code: driverCode,
  //           name: driverName,
  //           contact: driverNo,
  //         });
  //         getInvoices();
  //         setSelectedDriver('');
  //         setOpenModal(false);
  //         setDriverCardLoading(false);
  //       }
  //       console.log("billingResult",billingResult)
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setDriverCardLoading(false);
  //     alertMessage("Error while updating driver", "error", 10);
  //     setOpenModal(false);
  //   }
  // };

  // const renderDriverEditModal = () => {
  //   return (
  //     <Modal
  //       title={"Change Driver"}
  //       onCancel={handleCancel}
  //       bodyStyle={{ padding: 10 }}
  //       closable={!driverCardLoading}
  //       maskClosable={!driverCardLoading}
  //       visible={openModal}
  //       footer={[
  //         <Button key="cancel" onClick={handleCancel} disabled={driverCardLoading ? true : false}>
  //           Cancel
  //         </Button>,
  //         <Button key="ok" type="primary" loading={driverCardLoading} onClick={handleSubmit} icon='save'>
  //           Save
  //         </Button>,
  //       ]}
  //     >
  //       <div>
  //         <Row>
  //           <Col xs={24} sm={24} md={24} lg={24}>
  //             <Form>
  //               <span className="textBold">{I18n.t("general.Driver")}:</span>
  //               <Select
  //                 showSearch
  //                 value={selectedDriver ? selectedDriver : ""}
  //                 onChange={handleDriverChnage}
  //                 filterOption={(input, option) => {
  //                   if (option.props.children) {
  //                     return (
  //                       option.props.children
  //                         .toLowerCase()
  //                         .indexOf(input.toLowerCase()) >= 0
  //                     );
  //                   }
  //                 }}
  //                 style={{ width: "80%", height: "20%", marginLeft: 10 }}
  //               >
  //                 <Select.Option key={"driver"} value={""}>
  //                   --select--
  //                 </Select.Option>
  //                 {drivers
  //                   .filter((driver) => driver.id !== currentDriverId)
  //                   .map((driver) => (
  //                     <Select.Option key={driver.id} value={driver.id}>
  //                       {driver.employee_code}
  //                     </Select.Option>
  //                   ))}
  //               </Select>
  //             </Form>
  //           </Col>
  //         </Row>
  //       </div>
  //     </Modal>
  //   );
  // };
  

  const handleDeleteInvoiceItem = (id, itemType, newCharges) => {
    setInProgress(true);
    deleteInvoiceLine(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        if (invoice.status === "APPROVED") {
          handleSaveAndReapprove();
        }
        refreshDetails();
        getInvoices();
        setInProgress(false);
        setOrder({ ...order, [ itemType ]: newCharges });
      } else {
        renderAlertMessage(result.errors);
        setInProgress(false);
      }
    });
  };


  const handleDeleteclick = (recordNO, itemType) => {
    const charges = order[ itemType ];
    const cuttentInvoiceItem = charges[ recordNO ]
      ? (
        itemType !== 'transportation_charges' ?
          charges.sort((a, b) => a.invoice_name ? (a.invoice_name.localeCompare(b.invoice_name)) : 1)[ recordNO ] :
          charges[ recordNO ]
      )
      : {};
    if (cuttentInvoiceItem.id) {
      const newCharges = charges.filter((item, index) => index !== recordNO);
      handleDeleteInvoiceItem(cuttentInvoiceItem.id, itemType, newCharges);
      setOrder({ ...order, [ itemType ]: newCharges });
      // remove with the id
      setOrders(orders.filter((i) => {
        if (i.customer_order_id === order.customer_order_id) {
          i[ itemType ] = newCharges;
        }
        return i;
      }
      ));
    } else {
      // charges[ elementIndex ].price_list.splice(recordNO, 1);
      const newCharges = charges.filter((item, index) => index !== recordNO);
      setErrors([]);
      const modifiedOrder = { ...order, [ itemType ]: newCharges };
      // setOrder({ ...order, [ itemType ]: newCharges });
      setOrder(modifiedOrder);
      setOrders(orders.map((i) => i.customer_order_id === modifiedOrder.customer_order_id ? modifiedOrder : i));
      // this.handleOnChange(itemType, charges, () => {
      //   this.findPriceTotal();
      // });
    }
  };

  const handleAccessorailChange = (recordNO, element, value, itemType, orderNo) => {
    const charges = order[ itemType ];
    const invoiceLines = charges[ recordNO ];
    const accessorials = accessorials || [];
    const currentAccessorial = _.find(accessorials, {
      accessorial_code: value,
    });
    if (
      !isEmpty(currentAccessorial) &&
      !isEmpty(currentAccessorial.standard_code)
    ) {
      const accessorialCodes = accessorials
        .filter(
          (accessorial) =>
            accessorial.standard_code === currentAccessorial.standard_code
        )
        .map((accessorial) => accessorial.accessorial_code);
      const existanceRecords = invoiceLines
        .filter((line) => accessorialCodes.includes(line.accessorial_code))
        .map((line) => line.invoice_name);
      if (existanceRecords.length > 0) {
        confirm({
          title: I18n.t("messages.standard_change_confirm", {
            field: existanceRecords.join(", "),
          }),
          content: <span>{ I18n.t("messages.proceed_confirm") }</span>,
          onOk: () => {
            handleOnItemsChange(
              recordNO,
              element,
              value,
              itemType,
              orderNo
            );
          },
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onCancel: () => { },
        });
      } else {
        handleOnItemsChange(recordNO, element, value, itemType, orderNo);
      }
    } else {
      handleOnItemsChange(recordNO, element, value, itemType, orderNo);
    }
  };

  const handleItemsChange = (recordNO, element, value, itemType, orderNo) => {
    const charges = order[ itemType ];
    if (element === "accessorial_code") {
      handleAccessorailChange(recordNO, element, value, itemType, orderNo);
    } else {
      if (charges.isNew) {
        handleOnItemsChange(recordNO, element, value, itemType, orderNo);
      }
    }
  };

  const handleOnItemsChange = (recordNO, element, value, itemType, orderNo) => {
    // const charges = [ ...invoice[ itemType ] ];
    const charges = order[ itemType ];
    // charges[ elementIndex ].price_list[ recordNO ][ element ] = value;
    charges[ recordNO ][ element ] = value;
    if (element === "accessorial_code" && !isEmpty(value)) {
      const accessorials = accessorials || [];
      const currentAccRecord = _.find(accessorials, {
        accessorial_code: value,
      });
      if (!isEmpty(currentAccRecord)) {
        charges[ recordNO ][ "gl_code" ] = currentAccRecord.gl_code;
      }
    }
    setOrder({ ...order, [ itemType ]: charges });
    setOrders(orders.map((i) => i.customer_order_id === order.customer_order_id ? { ...order, [ itemType ]: charges } : i));

    // this.handleOnChange(itemType, charges, () => {
    //   this.findPriceTotal();
    // });
  };

  const handleSaveItem = (index, itemType) => {
    const charges = order[ itemType ];
    if (index >= 0) {
      const requiredFields = [
        {
          form_field: "invoice_value",
          ui_name: I18n.t("invoices.amount"),
          isRequired: true,
          inputType: "price",
        },
      ];

      const item = charges[ index ];
      const errors = doValidate(requiredFields, item, `Item  ${index + 1}`);
      if (errors.length > 0) {
        setErrors(errors);
        setInProgress(false);
        setIsSaving(false);
      } else {
        let newInvoiceLines = [];
        const invoiceLines = charges.filter((line) => line.isNew === true);
        if (itemType === "accessorial_charges") {
          invoiceLines.forEach((line) => {
            const newLine = Object.assign({}, line);
            const accessorialIndex = _.findIndex(accessorials, [
              "accessorial_code",
              line.accessorial_code,
            ]);
            if (accessorialIndex >= 0) {
              newLine.invoice_name =
                accessorials[ accessorialIndex ].accessorial_name;
              newLine.account_accessorial_id =
                accessorials[ accessorialIndex ].id;
            }
            newLine.invoice_type =
              itemType === "accessorial_charges" ? "Accessorial" : itemType;
            newInvoiceLines.push(newLine);
          });
        } else {
          newInvoiceLines = [ ...invoiceLines ];
        }
        if (invoice.order_group_type === "MANUAL") {
          const data = {
            new_invoice_lines: newInvoiceLines,
            remark: "",
            order_group_type: invoice.order_group_type,
            invoice_number:
              invoice.order_group_type === "ORDER"
                ? invoice.customer_order_id
                : invoice.order_number,
            status: invoice.status,
            account_id: invoice.account_details.id,
            driver_id: invoice.driver_id,
            warehouse_id: invoice.warehouse_id,
          };
          saveNewInvoice(data).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"));
              setInProgress(false);
              setErrors(errors);
              setIsSaving(false);
              getInvoices();
              refreshDetails();
              getManualInvoiceDetails(
                order.customer_order_number
              );
              // remove the new flag from the invoice lines
              const newCharges = charges.map((line) => {
                if (line.isNew) {
                  delete line.isNew;
                }
                return line;
              });
              setOrder({ ...order, [ itemType ]: newCharges });
              setOrders(orders.map((i) => i.customer_order_number === order.customer_order_number ? { ...order, [ itemType ]: newCharges } : i));
            } else {
              alertMessage(result.errors, "error", 10);
              setInProgress(false);
              setIsSaving(false);
            }
          }).then(() => {
            // call save and reapprove if in approval status
            if (invoice.status === "APPROVED") {
              handleSaveAndReapprove();
            }
          });
        } else {
          const data = {
            new_invoice_lines: newInvoiceLines,
            remark: "",
            order_group_type: invoice.order_group_type,
            customer_order_id: order.customer_order_id || "",
            reference_id:
              invoice.order_group_type === "ORDER"
                ? invoice.customer_order_id
                : invoice.order_number,
            status: invoice.status,
          };
          updateInvoiceLines(data).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"));
              setInProgress(false);
              setErrors(errors);
              setIsSaving(false);
              getInvoices();
              getInvoiceDetails(
                invoice.customer_order_id
              );
              // remove the new flag from the invoice lines
              const newCharges = charges.map((line) => {
                if (line.isNew) {
                  delete line.isNew;
                }
                return line;
              });
              setOrder({ ...order, [ itemType ]: newCharges });
              setOrders(orders.map((i) => i.customer_order_id === order.customer_order_id ? { ...order, [ itemType ]: newCharges } : i));
            } else {
              alertMessage(result.errors, "error", 10);
              setInProgress(false);
              setIsSaving(false);
            }
          }).then(() => {
            // call save and reapprove if in approval status
            if (invoice.status === "APPROVED") {
              handleSaveAndReapprove();
            }
          });;
        }
      }
    } else {
      setIsSaving(false);
    }
  };

  const handleInputSaveItem = (recordNO, itemType, orderNo, data) => {
    const charges = order[ itemType ];
    // setIsSaving(true);
    let priceList = charges[ recordNO ];
    priceList.invoice_name = data.invoice_name;
    priceList.gl_code = data.gl_code;
    priceList.invoice_quantity = data.invoice_quantity;
    priceList.invoice_value = data.invoice_value;
    // delete priceList.isNew;

    charges[ recordNO ] = priceList;

    setOrder({ ...order, [ itemType ]: charges });
    setOrders(orders.map((i) => i.customer_order_number === orderNo ? { ...order, [ itemType ]: charges } : i));
    // this.handleOnChange(itemType, charges, () => {
    //   this.findPriceTotal();
    // });
    handleSaveItem(recordNO, itemType, orderNo);
  };

  const handladdItem = (itemType) => {
    const lastCharges = order[ itemType ];
    const data = {
      added_from: "MANUAL",
      invoice_name: "",
      invoice_value: "",
      invoice_quantity: 1,
      isNew: true,
      invoice_type: I18n.t(`invoices.invoice_types.${itemType}`),
      order_item_group_id: null,
      location_id: order.locations?.length === 1 ? order.locations[ 0 ].location_id : ''
    };

    if (itemType !== "adjustment_charges") {
      data.accessorial_code = "";
    }
    data.order_id = order.customer_order_id;
    if (itemType === "fuel_surcharges") {
      data.gl_code = config[ "fuel_surcharge_gl_code" ];
    }
    const newcharges = [ ...lastCharges, data ];
    setOrder({ ...order, [ itemType ]: newcharges });
    setOrders(orders.map((i) => i.customer_order_id === order.customer_order_id ? { ...order, [ itemType ]: newcharges } : i));
  };

  const handleEditItem = (index, itemType, itemId, record, locations) => {
    // Check if the item is being edited
    if (record && record.isEditing) {
      // Clone the order state to avoid mutating it directly
      const updatedOrder = _.cloneDeep(order);  
      // Find the specific item in the itemType array using the itemId
      const charges = (updatedOrder[itemType] || []).map((item) =>
        item.id === itemId ? { ...item, isEditing: true,isNew :false } : item.order_id === record.order_id ? { ...item, isEditing: true,isNew :false } : item
      );  
      // Update the itemType in the order state
      updatedOrder[itemType] = charges;  
      // Update the entire order state
      setOrder(updatedOrder);
      // Update the orders state by mapping over it and finding the matching order
      setOrders((prevOrders) =>
        prevOrders.map((orderItem) =>
          orderItem.customer_order_id === updatedOrder.customer_order_id
            ? updatedOrder
            : orderItem
        )
      );
    }
  };
  


  const iconMapping = {
    name: <Icon type="user" />,
    email: <Icon type="mail" />,
    contact: <Icon type="phone" />,
    address: <Icon type="home" />,
    code:<Icon type="code" />
  };

  const formatAddressString = (address) => {
    // Check if address is null or empty object
    if (!address || Object.keys(address).length === 0) {
      return ""; // Return empty string if address is null or empty
    }

    const cleanedAddress = Object.fromEntries(
      Object.entries(address).filter(([_, value]) =>  !isEmpty(value))
      );
    const addressString = Object.values(cleanedAddress).join(', ');
    return addressString;
  };
  

  const InfoCard = ({ title, details, size = "small" }) => {
    // const { driver_id, ...filteredDriverObj } = driverObj;
    return (
      <Card title={title} size={size} className='info-card-billing-manage' bodyStyle={{ height: 150, fontSize: 12 }}>
        {/* {title === 'Driver Details' ? 
          Object.entries(filteredDriverObj).map(([key, value]) => (
            <p key={key}>
              {iconMapping[key]}
              {" "}
              <strong>{value}</strong>
              {key === 'code' && <EditIcon handleClick={handleOpenModal} />}
            </p>
          ))
        : */}
        { Object.entries(details).map(([key, value]) => (
            <p key={key}>
              {iconMapping[key]}
              {" "}
              <strong>
              { key === "address"
                  ? String(formatAddressString(getNestedValue(order, value)))
                  : getNestedValue(order, value)}
                </strong>
            </p>
          ))
        }
      </Card>
    );
  };

  const _renderCustomerDetails = () => {

    return (
      <Card title={<EndUserTitle stringToAppend={I18n.t("general.details")} />} size="small" bodyStyle={{ height: 150, fontSize: 12 }}>
        {order.customers_details?.length ? (
          order.customers_details.map((detail, index) => (
            <Col xs={24 / order.customers_details.length} style={{ padding: 0 }}>
              <Row>
                {detail.location_type && (
                            <Col xs={24} className="textBold">
                              {detail.location_type
                                ? `${detail.location_type}: `
                                : ""}
                            </Col>
                          )}
                <Col xs={24}>
                  <Profile
                    address={detail.address ? detail.address : {}}
                    profile={(({
                      name,
                      contact,
                      customer_phone_two,
                      email,
                      company_name,
                    }) => ({
                      image: imagePlaceholder,
                      full_name: `${name}`,
                      mobile_number: `${contact} ${
                        !isEmpty(customer_phone_two)
                          ? `,${customer_phone_two}`
                          : ""
                      }`,
                      email,
                      company_name,
                    }))(detail)}
                    displayType="line"
                    showImage={false}
                    withLables={false}
                    inBilling={true}
                    styleObj={{ fontSize: 13, fontWeight: "bold" }}
                  />
                </Col>
                <Col xs={24} style={{ marginLeft: 5 }}>
                  <AddressInfo address={detail.address} showIcon={true} />
                </Col>
              </Row>
            </Col>
          ))
        ) : (
          <Fragment />
        )}
      </Card>
    );
  }

  const isInvoiceApproved = invoice.status === "APPROVED";
  const totalPriceList = _.concat(
    getNestedValue(order, 'transportation_charges', []),
    getNestedValue(order, 'fuel_surcharges', []),
    getNestedValue(order, 'accessorial_charges', []),
    getNestedValue(order, 'adjustment_charges', [])
  );

  const handleApplySmp = (orderId) => {
    const data = {
      customer_order_id: orderId,
    };
    setIsLoading(true);
    applySMP(data).then((result) => {
      if (result.success) {
        setIsLoading(false);
        setInvoice(result.new_invoice)
        const msg = result.message ? result.message : I18n.t("messages.saved");
        refreshDetails();
        getInvoices();
        alertMessage(msg, 10);
      } else {
        setIsLoading(false);
        alertMessage(result.errors, "error", 10);
      }
    });
  };

  const renderModalContent = () => {
    const DriverSettlementApproved = invoice.is_driver_settlement_approved;
    const isConsolidated = orders.find(order => order.is_consolidated === 'true');
    if (!isLocked && DriverSettlementApproved && !isConsolidated) {
      return (
        RenderModalFalseConsolidation()
      );
    } else if (!isLocked && !DriverSettlementApproved && isConsolidated) {
      return (
        RenderModalFalseDriverSettlement()
      );
    } else if (!isLocked && DriverSettlementApproved && isConsolidated) {
      return (
        RenderModalTrueDriverSettlementConsolidation()
      );
    }
    return null;
  };

  const RenderModalFalseConsolidation = () => {
    return (
      <div>
        <Row>
          <h3 style={ { fontWeight: "bold" } }>
            Driver Settlement already approved for this order, New amount will not be affected!
          </h3>
        </Row>
        <Row>
          <h4
            style={ {
              paddingTop: "18px",
              paddingLeft: "50px",
              fontWeight: "600",
            } }
          >
            Do you want to continue ?
          </h4>
        </Row>
      </div>
    );
  };

  const RenderModalFalseDriverSettlement = () => {
    return (
      <div>
        <Row>
          <h4 style={ { fontWeight: "bold" } }>
            This is a consolidated order we are going to reprocess all the orders shown in below
          </h4>
        </Row>
        <Row>
          <div
            style={ {
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "10px",
              padding: "10px",
            } }
          >

            <p style={ { maxHeight: 70, overflowY: "auto" } }>
              { consolidatedOrderNumbers.join(", ") }
            </p>
          </div>
        </Row>
        <Row>
          <h4
            style={ {
              paddingTop: "18px",
              paddingLeft: "3px",
              fontWeight: "600",
              // fontSize: "19px",
            } }
          >
            Do you want to continue ?
          </h4>
        </Row>
      </div>
    );
  };

  const RenderModalTrueDriverSettlementConsolidation = () => {
    return (
      <div>
        <Row>
          <h4 style={ { fontWeight: "bold" } }>
            Driver Settlement already approved for this order, New amount will not be affected!
          </h4>
          <h4 style={ { fontWeight: "bold", paddingLeft: '56px' } }>
            This is a consolidated order we are going to reprocess all the orders shown in below
          </h4>
        </Row>
        <Row>
          <div
            style={ {
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginTop: "10px",
              padding: "10px",
            } }
          >
            <p style={ { maxHeight: 70, overflowY: "auto" } }>
              { consolidatedOrderNumbers.join(", ") }
            </p>
          </div>
        </Row>
        <Row>
          <h4
            style={ {
              paddingTop: "18px",
              paddingLeft: "3px",
              fontWeight: "600",
              // fontSize: "19px",
            } }
          >
            Do you want to continue ?
          </h4>
        </Row>
      </div>
    );
  };


  const regenerateInvoice = (invoiceLosMapping, cb = null) => {
    const losSelected = levelOfServices.find(los => los.code === invoiceLosMapping[ order.customer_order_id ]);
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      customer_order_id: order.customer_order_id,
      level_of_service_id: losSelected ? losSelected.id : "",
      los_code: invoiceLosMapping[ order.customer_order_id ],
      apply_smp: order.is_smp_applied === true,
    };
    setIsRegeneratingLos(true);
    regenerateAnOrderBilling(data).then((result) => {
      if (result.success) {
        setIsRegeneratingLos(false);
        const msg = result.message ? result.message : I18n.t("messages.saved");
        refreshDetails();
        getInvoices();
        alertMessage(msg, 10);
        if (invoice.status === "APPROVED") {
          cb();
        }
      } else {
        setIsRegeneratingLos(false);
        renderAlertMessage(result.errors);
      }
    });
  };

  const reGenerate = () => {
    regenerateInvoice(invoiceLos, handleSaveAndReapprove());
    getInvoiceDetails(invoice.customer_order_id);
  };

  const RegenerateButton = () => (
    <Button
      type="primary"
      size="small"
      disabled={ isLocked }
      loading={ isRegeneratingLos }
      className="fontSize13 cursorPointer textBold buttonTeal marginLeft10"
      icon="sync"
      onClick={ () => {
        const modalContent = renderModalContent();
        if (!modalContent) {
          reGenerate();
        } else {
          Modal.confirm({
            content: modalContent,
            width: 520,
            icon: <Icon type="warning" style={ { fontSize: '40px' } } />,
            onOk: () => { reGenerate(); },
            okText: "Yes",
            okType: "primary",
            cancelText: "No",
            cancelType: "danger",
            onCancel: () => { },
          });
        }
      } }
    >
      Regenerate
    </Button>
  );
  const handleApplyFmp = (orderId) => {
    const account = invoice.account_details
      ? invoice.account_details
      : {};
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      account_id: account?.id ? account.id : "",
      fmp_order_id: orderId,
    };
    setIsLoading(true);
    applyFMP(data).then((result) => {
      if (result.success) {
        setIsLoading(false);
        setInvoice(result.new_invoice)
        const msg = result.message ? result.message : I18n.t("messages.saved");
        refreshDetails();
        getInvoices();
        alertMessage(msg, 10);
      } else {
        setIsLoading(false);
        renderAlertMessage(result.errors);
      }
    });
  };


  const renderTitleSection = (order, invoiceLos) => {
    const showFMPLink = !isLocked && config[ "second_price_type" ] !== "ITEM" && showActions;

    return (
      <div style={ { padding: '10px', margin: '10px', border: '1px solid #ccc', backgroundColor: '#f9f9f9' } }>
        <Row type='flex' justify='space-between' align='middle' gutter={ 16 }>
          <Col>
            <Title level={ 4 }>
              Order Details for { getNestedValue(order, 'customer_order_number') }
            </Title>
          </Col>
          <Col>
            { `${I18n.t("los.label")} :` }
            { checkServiceExistance("LOSI") ? (
              <Select
                size="small"
                value={ _.get(invoiceLos, `${order.customer_order_id}`, "") }
                showSearch
                style={ { width: 200 } }
                filterOption={ (input, option) =>
                  _.includes(_.lowerCase(option.props.children), _.lowerCase(input))
                }
                onChange={ (e) =>
                  setInvoiceLos({ ...invoiceLos, [ order.customer_order_id ]: e })
                }
                disabled={ isReadyOnly || showActions === false }
              >
                <Select.Option key="LOS" value="">
                  -- Select --
                </Select.Option>
                { _.map(levelOfServices, (los) => (
                  <Select.Option key={ los.code } value={ los.code }>
                    { los.name }
                  </Select.Option>
                )) }
              </Select>
            ) : (
              ""
            ) }
          </Col>
          <Col>
            <Row gutter={ 16 } type='flex' align='middle'>
              { checkServiceExistance("UINLOS") && (showActions === true || !isInvoiceLocked) && (
                <Col>
                  <RegenerateButton />
                </Col>
              ) }

              { showFMPLink && order.is_smp_applied === true && <Col>
                  <Button 
                  onClick={ () => handleApplyFmp(order.customer_order_id) }
                  type="primary" icon='check-circle' size="small" className="textBold fontSize12 "
                    style={ { marginTop: 1 } }>Apply&nbsp;
                    {
                      showFMP()
                    }
                  </Button>
              </Col>
              }

              { order.is_smp_applied === false && (
                <Col>
                    <Button
                      type="primary"
                      size="small"
                      className="textBold fontSize12 "
                      style={ { marginTop: 1 } }
                      icon='check-circle'
                      onClick={ () => handleApplySmp(order.customer_order_id) }
                    >
                      Apply SMP
                    </Button>
                </Col>
              ) }
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const jobTotal = findNumberTotal(
    totalPriceList,
    "invoice_value",
    null,
    2
  );

  const instructionsData = _.get(order, 'instructions', {
    notes: '',
    instructions_from_account_1: '',
    instructions_from_account_2: '',
  });

  return (
    <>
    {/* {openModal && renderDriverEditModal()} */}
    <Card
      title={
        renderTitleSection(order, invoiceLos)
      }
      style={ { marginBottom: '20px', border: '1px solid #d9d9d9', backgroundColor: '#f0f2f5', padding: '2px' } }
      size='small'
    >
      <Row gutter={ [ 16, 16 ] }>
        <Col span={ isTransfer ? 10 : 6 }>
          <InfoCard title="Account Details" details={ {
            name: 'account_details.name',
            code: 'account_details.code',
            contact: 'account_details.contact',
            address: 'account_details.address',
          } } />
        </Col>
        <Col span={ isTransfer ? 14 : 6 }>
          {_renderCustomerDetails()}
        </Col>
        <Col span={ isTransfer ? 12 : 6 }>
          <Card title="Driver Details" size="small" bodyStyle={{ height: 150, fontSize: 12 }}>
          {order.drivers_details?.length > 0 ? (
            <NewBillingDrivers
              invoiceStatus={!isEmpty(invoice.status)? invoice.status : ""}
              currentBillingDriver={order.drivers_details ? order.drivers_details : []}
              showActions={showActions}
              order={order}
            />
          ) : <Fragment/>}
          </Card>
        </Col>
        <Col span={ isTransfer ? 12 : 6 }>
          <ZoneDetails orders_info={ order.orders_info } />
        </Col>
      </Row>


      <InstructionsDisplay data={ instructionsData } />

      <Row>
        <Card
          size="small"
          style={ { textAlign: "left" } }
        >
          {/* { renderReferenceSection(order) } */ }
          { renderAllCharges({
            order, isInvoiceApproved, showActions, handladdItem,handleEditItem, handleInputSaveItem, glCodesList, handleDeleteclick, isInvoiceLocked, isReadyOnly, accessorials, handleItemsChange
          }) }
          <Row className="marginTop15">
            <Col
              xs={ 24 }
              className="textBold alignRight textPrimary fontSize18"
              style={ { paddingRight: 85 } }
            >
              Job Total: { checkNegitive(jobTotal) }
            </Col>
          </Row>
        </Card>
      </Row>
    </Card>
    </>
  );
};


export { BillingScreen };