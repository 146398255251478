import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import _ from 'lodash';

export const withDuplicateTabDetection = (WrappedComponent) => {
  return (props) => {
    // This code is used to check whether the current tab is a duplicate tab or not.
    // It checks by using BroadcastChannel API.
    // If there are multiple tabs of the same page, then it sends a message to other tabs saying that it is opened.
    // If the current tab receives a message saying that it is opened, then it means that it is a duplicate tab.


    const [ isDuplicateTab, setIsDuplicateTab ] = useState(false);
    const currentPath = window.location.pathname;

    useEffect(() => {
      if (!_.isUndefined(props.checkForDuplicates) && !props.checkForDuplicates) {
        return;
      }
      const channel = new BroadcastChannel(currentPath);
      channel.postMessage("opened");
      channel.onmessage = (event) => {
        if (event.data === "opened") {
          setIsDuplicateTab(true);
        }
      };

      return () => {
        channel.postMessage("closed");
        channel.close();
      };
    }, [ currentPath, props.checkForDuplicates ]);

    return (
      <WrappedComponent
        { ...props }
        isDuplicateTab={ isDuplicateTab }
      />
    );
  };
};
