import { Button, Col, DatePicker, Icon, Row, Spin, Tooltip } from "antd";
import React, { useContext, useEffect, useState,Fragment } from "react";
import I18n from "../../common/I18n";
import CapacityReportsList from "./CapacityReportsList";
import { FormItem } from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { CapacityReportApi } from "../../api/CapacityReport";
import userStore from "../../stores/UserStore";
import { WarehouseContext } from "../../context/WarehouseContext";
import { ScreenKeys } from "../../containers/constants";
import { UserContext } from "../../context/UserContext";
import { RecordsPerPage } from "../orders/RecordsPerPage";
import { base64ToExcel, renderAlertMessage, responseTOCsv } from "../../helpers/common";
import moment from "moment";
import { isEmpty } from "../../common/Common";
import { checkNegitive } from "../../common/Common";
import { formatWeight } from "../../helpers/orders";
import { DriverSelect } from "../common/SelectDropdowns/BaseSelect";
import { fetchUsers } from "../../api/UsersApi";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
export const CapcityHomeScreen = (props) => {
  const [capacityData, setCapacityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(moment());
  const { RangePicker } = DatePicker;
  const warehouseFilter = useContext(WarehouseContext);
  const { userContext } = props.appContexts;
  const { updateRecordsPerPage } = userContext;
  const [warehouseData, setWarehouseData] = useState([warehouseFilter]);
  const [handleExport, setHandleExport] = useState(false);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const perPageKey = ScreenKeys.CAPACITY_REPORTS_LISTING;
  const recordsPerPageSaved = _.get(
    UserContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved);
  const [totalCurrentWeight,setTotalCurrentWeight] = useState(null);
  const [totalVehicleWeight,setTotalVehicleWeight] = useState(null);
  const [totalRemainingWeight,setTotalRemainingWeight] = useState(null);
  const [drivers,setDrivers] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState();
  const [selectedDriverCode, setSelectedDriverCode] = useState();

  const handleDriverChange = (selectedDriverId) => {
    if (selectedDriverId === null || selectedDriverId === undefined) {
      setSelectedDriverId("");
      setSelectedDriverCode("");
    } else {
      const filteredDriver = drivers.find((driver) => driver.id === selectedDriverId);
      if (filteredDriver) {
        setSelectedDriverId(selectedDriverId);
        setSelectedDriverCode(filteredDriver.employee_code);
      } else {
        setSelectedDriverId("");
        setSelectedDriverCode("");
      }
    }
  };

  const tablePagination = {
    total: pagination?.total_count,
    current: pagination?.current_page,
    pageSize: pagination?.per_page || recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      fetchCapacityData();
      tableOptions.pagination.current = 1;
    }
  };

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved;
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(value, perPageKey);
  };

  const  getDrivers = () => {
    fetchUsers("driver", 1, null).then((result) => {
      if (result.success) {
        setDrivers(result.users || [])
      }
    });
  }

  useEffect(() => {
    getDrivers();
  }, []);

  useEffect(() => {
    fetchCapacityData();
  }, [dateRange,selectedDriverId]);

  useEffect(() => {
    if(handleExport){
      fetchCapacityData();
    }
  }, [handleExport]);

  const handleDateChange = (date) => {
    if (!_.isEmpty(date)) {
      setDateRange(date);
    } else {
      setDateRange(moment());
    }
  };
  
  const handleOnExport = () => {
    setHandleExport(true);
  };

  const fetchCapacityData = async () => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = 1000;
    const dateVal = dateRange;
    const orgId = userStore.getStateValue("selectedOrg");
    const whId = _.get(warehouseData[0], "selectedWarehouses", "");
    const fromDate = _.invoke(dateVal, 'format', 'YYYY-MM-DD') || null;
    const toDate = _.invoke(dateVal, 'format', 'YYYY-MM-DD') || null;
    const exportFile = handleExport;
    const driver = selectedDriverId;

    try {
      const result = await CapacityReportApi.fetch(
        page,
        perPage,
        orgId,
        whId,
        fromDate,
        toDate,
        exportFile,
        driver
      );
      if (result.success) {
        if(exportFile){
          const fileName = _.get(result, "data.filename", "download");
          base64ToExcel(result.data.file, fileName,"EXCEL");
        }else{
          setCapacityData(_.get(result, "data.driver_log_reports", []));
          const driverLogReports = _.get(result, "data.driver_log_reports", []);
          const filteredDriverReports = selectedDriverCode
          ? driverLogReports.filter((report) => report.employee_code === selectedDriverCode)
          : driverLogReports;

          setCapacityData(filteredDriverReports);

            const filteredTotalCurrentWeight = filteredDriverReports.reduce((acc, report) => acc + (report.current_weight || 0), 0);
            const filteredTotalVehicleWeight = filteredDriverReports.reduce((acc, report) => acc + (report.vehicle_weight || 0), 0);
            const filteredTotalRemainingWeight = filteredDriverReports.reduce((acc, report) => acc + (report.empty_space || 0), 0);

            setTotalCurrentWeight(filteredTotalCurrentWeight);
            setTotalVehicleWeight(filteredTotalVehicleWeight);
            setTotalRemainingWeight(filteredTotalRemainingWeight);
            setPagination(_.get(result, "pagination", {}));
        } 
      } else {
        setHandleExport(false);
        renderAlertMessage(result.errors, "error", 10);
      }
    } catch (error) {
      alertMessage(error, "error", 10);
      setHandleExport(false);
    } finally {
      setHandleExport(false);
    }
  };

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={24}>{I18n.t("capacity_report.title")}</Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col>
            <Row type="flex" gutter={8} align="middle" justify="space-around">
              <Col>
                <Row type="flex" gutter={16} align="middle">
                  <Col>
                    <FormItem label={I18n.t("capacity_report.select")}>
                      <DatePicker
                        value={dateRange}
                        format={AppConfig.dateFormat}
                        onChange={(date) => handleDateChange(date)}
                        style={{ width: 300 }}
                        allowClear={
                          !_.isEqual(
                            dateRange.format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD")
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col>
                  <FormItem label={I18n.t('general.driver')} >
                  <DriverSelect
                    showSearch
                    data={drivers}
                    placeholder={I18n.t('location.location')}
                    style={{ width: '200px' }}
                    selectKey="employee_code"
                    selectValue="id"
                    selectedValue="employee_code"
                    onChange={handleDriverChange}
                    value ={selectedDriverId}
                    dropdownMatchSelectWidth={false}
                    defaultOption= "---All---"
                  />
                    </FormItem>
                  </Col>
                  <Col style={{ marginTop: 7 }}>
                      {/* <Tooltip title="Export">
                        <img
                          src={exportIcon}
                          alt="export"
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: !_.isEmpty(capacityData) ? "pointer" : "not-allowed",
                          }}
                          onClick={!_.isEmpty(capacityData) ? handleOnExport : undefined}
                          />
                      </Tooltip> */}
                    <Button
                      className="cursorPointer"
                      disabled={_.isEmpty(capacityData) || handleExport}
                      type="primary"
                      loading={handleExport}
                      onClick={handleOnExport}
                      shape="round"
                    >
                      {handleExport ? (
                        "Exporting..."
                      ) : (
                        <span>
                          Export EXCEL <Icon type="file-excel" theme="filled" />
                        </span>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
              </Col>
            </Row>
          </Col>
          <Col span={16} offset={4}>
            <Spin spinning={loading}>
              <CapacityReportsList
                data={!_.isEmpty(capacityData) ? capacityData : []}
                pagination={{position:"none"}}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, filter, sorter, currentTable)
                }
                footer={() => (
                  !_.isEmpty(capacityData) && (
                    <Fragment>
                    <Row type="flex" className="fontSize12 textBold">
                      <Col
                        style={{ width: "40%" }}
                        className="alignRight paddingRight10"
                      >
                        {" "}
                        Total
                      </Col>
                      <Col style={{ width: '10%' }} className="alignRight">
                        <div>
                          {formatWeight(totalCurrentWeight)}
                          </div>
                      </Col>
                      <Col style={{ width: "25%" }} className="alignRight">
                        <div>
                          {formatWeight(totalVehicleWeight)}
                        </div>
                      </Col>
                      <Col style={{ width: "25%" }} className="alignRight">
                        <div>
                          {formatWeight(totalRemainingWeight)}
                          </div>
                      </Col>
                    </Row>
                  </Fragment>
                  )
                )}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};
