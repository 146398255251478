import { Button, Col, Row, Spin } from "../common/UIComponents";
import React, { useEffect, useState } from "react";
import WrappedVehicleTypeForm from "../components/Vehicle types/VehicleTypeForm";
import BaseModal from "../components/BaseModal";
import VehicleTypeList from "../components/Vehicle types/VehicleTypeList";
import { VehicleTypeApi } from "../api/VehicleType";
import { alertMessage, isEmpty } from "../common/Common";
import AppConfig from "../config/AppConfig";
import Gallery from "../components/common/Gallery";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { ScreenKeys } from "./constants";
import I18n from "../common/I18n";
import { renderAlertMessage } from "../helpers/common";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} }; 
export const VehicleType = (props) => {
  const defaultFilter = {
    sortBy: "none",
    sortByType: "descend",
  };
  const { userContext } = props.appContexts
  const { currentUser , updateRecordsPerPage } = userContext
  const [showModal, setShowModal] = useState(false);
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [existingVehicleId, setExistingVehicleId] = useState(null);
  const [ filterPlaceHolder, setFilterPlaceHolder ] = useState({ ...defaultFilter });
  const perPageKey = ScreenKeys.VEHICLE_TYPES_LISTING;
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved)

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      getVhTypes();
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
    }
  };

  const handleAddClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setExistingVehicleId(null);
  };

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);

  };

  const onSearch = (value) => {
  };

  useEffect(() => {
    getVhTypes();
  }, [recordsPerPage, filterPlaceHolder]);

  const editVhTypes = (id) => {
    setShowModal(true);
    setExistingVehicleId(id);
  };

  const getVhTypes = () => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = recordsPerPage;
    setIsLoading(true);
    VehicleTypeApi.fetch(page, perPage,filterPlaceHolder).then((result) => {
      if (result.success) {
        setVehicleTypeData(result.vehicle_types);
        setExistingVehicleId(result.vehicle_types.id);
        setPagination(result.pagination);
        setIsLoading(false);
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  const deleteVhTypes = (id) => {
    setIsLoading(true);
    VehicleTypeApi.delete(id).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success");
        const newVehicleTypeData = vehicleTypeData.filter(
          (item) => item.id !== id
        );
        setVehicleTypeData(newVehicleTypeData);
      } else {
        renderAlertMessage(result.errors)
      }
    });
    setIsLoading(false);
  };

  const vehicleTypeForm = () => {
    return (
      <BaseModal
        title={existingVehicleId ? I18n.t("vehicleTypes.edit") : I18n.t("vehicleTypes.add")}
        visible={showModal}
        onCancel={closeModal}
        footer={null}
        width={'600px'}
        maskClosable={false}
      >
        <WrappedVehicleTypeForm
          closeModal={closeModal}
          existingVehicleId={existingVehicleId}
          getVhTypes={getVhTypes}
        />
      </BaseModal>
    );
  };

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("menu.vehicle_type")}</Col>
          <Col xs={12} className="page-actions">
            <Button type="primary" onClick={handleAddClick} icon="plus">
              {I18n.t("vehicleTypes.add")}
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading}>
          <Row>
            <Col>
            <Row type="flex" justify="end">
            <Col>
                <RecordsPerPage
                  onChange={onRecordChange}
                  onSearch={onSearch}
                  value={recordsPerPage}
                  defaultValue={recordsPerPage}
                />
              </Col>
            </Row>
              <VehicleTypeList
                data={vehicleTypeData}
                pagination={tablePagination}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination,filter, sorter, currentTable)
                }
                handleDelete={(id) => deleteVhTypes(id)}
                handleEdit={(id) => editVhTypes(id)}
                scroll = {{y:"calc(100vh - 235px)"}}
              />
            </Col>
          </Row>
        </Spin>
      </div>
      {showModal && vehicleTypeForm()}
    </div>
  );
};
