import React, { Component, Fragment } from 'react'
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Select,
  Spin,
  Checkbox,
} from '../../common/UIComponents'
import {
  alertMessage,
  isEmpty,
  customPasteSplit,
  doValidate,
} from '../../common/Common'
import I18n from '../../common/I18n'
import TagsInput from 'react-tagsinput'
import { createManualInvoice } from '../../api/Billing';
import userStore from '../../stores/UserStore';
import FormErrors from '../common/FormErrors';

let requiredFields = [
  {
    form_field: "account_id",
    ui_name: I18n.t("general.account"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "customer_order_numbers",
    ui_name: I18n.t("menu.orders"),
    isRequired: true,
    inputType: "array",
  },
  {
    form_field: "template_method",
    ui_name: "Template",
    isRequired: true,
    inputType: "text",
  },
];

class ManualInvoiceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accounts: this.props.accounts || [],
      warehouses: this.props.warehouses || [],
      drivers: this.props.drivers || [],
      formData: {
        account_id: '',
        account_code: '',
        // invoice_number: '',
        customer_order_numbers: [],
        is_template_zone_billing: false,
        template_method: ""
      },
      isLoading: false,
      billingTemplates: [{ code: "template_zone_based_billing", name: "Billing Based on Zones" }],
      errors: []
    }
  }

  handleOnChange = (element, value) => {
     if (element === 'account_id' && !isEmpty(value)) {
      const account = this.state.accounts.find(account => account.id === value);
      this.setState({
        formData: Object.assign({}, this.state.formData, {
          [element]: value,
          account_code: account?.code ? account.code : '',
        }),
      });
    } else if (element === "is_template_zone_billing") {
      if(value){
        this.setState({
          formData: Object.assign({}, this.state.formData, {
            [element]: value,
            customer_order_numbers: [],
          }),
        });
      }else{
        this.setState({
          formData: Object.assign({}, this.state.formData, {
            [element]: value,
            template_method: "",
          }),
        });
      }
    } else {
      this.setState({
        formData: Object.assign({}, this.state.formData, { [element]: value }),
      });
    }
    
  }

  handleBasicForm = () => {
    const basicForm = this.state.formData
    let requirdItems = requiredFields;
    if (basicForm?.is_template_zone_billing) {
      requirdItems = requirdItems.filter(
        (field) => field.form_field !== "customer_order_numbers"
      );
    } else {
      requirdItems = requirdItems.filter(
        (field) => field.form_field !== "template_method"
      );
    }
    const errors = doValidate(requirdItems, basicForm);
    if (errors.length > 0) {
      this.setState({
        errors,
      })
    } else {
      this.setState({
        errors: [],
      }, () => {
        this.createInvoice();
      })
      
    }
  }

  createInvoice = () => {
    const data = {...this.state.formData};
    data.organization_id = userStore.getStateValue('selectedOrg');
    //data.invoice_number = data.invoice_number ? data.invoice_number.toUpperCase() : '';
    this.setState({
      isLoading: true,
    })
    createManualInvoice(data).then((result) => {
      if (result.success) {
        const response = result.data;
        this.setState({
          errors: result.errors,
          isLoading: false,
        })
        alertMessage(I18n.t("messages.saved"));
        this.props.navaigateInvoiceForm(response);
      } else {
        this.setState({
          errors: result.errors,
          isLoading: false,
        })
      }
    });
  }
  _renderBasicForm = () => {
    const { formData } = this.state;
    return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <FormItem label={I18n.t('general.account')} require>
            <Select
              value={formData.account_id}
              showSearch
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => this.handleOnChange('account_id', e)}
            >
              <Select.Option key="all" value="">
                -- Select Account --
              </Select.Option>
              {this.state.accounts.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {`${account.name} (${account.code})`}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            checked={this.state.formData.is_template_zone_billing}
            onChange={(e) => this.handleOnChange("is_template_zone_billing", e.target.checked)}
          >
            Use Template Based Blling
          </Checkbox>
        </Col>
      </Row>
      {this.state.formData?.is_template_zone_billing ? 
      <Row>
        <Col xs={24} style={{marginTop: 5}}>
          <FormItem label="Select Template" require>
                <Select
                  value={this.state.formData.template_method || ""}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleOnChange("template_method", e)}
                >
                  <Select.Option key="tempate" value="">
                    -- Select --
                  </Select.Option>
                  {this.state.billingTemplates.map((rec) => (
                    <Select.Option key={rec.code} value={rec.code}>
                      {rec.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
        </Col>
      </Row>
      :
      <Row>
        <Col xs={24} style={{marginTop: 5}}>
          <FormItem label={I18n.t('menu.orders')}>
            <TagsInput
              value={formData.customer_order_numbers || []}
              onChange={(e) => this.handleOnChange('customer_order_numbers', e)}
              validationRegex={/^[a-zA-Z0-9_-]+$/}
              onlyUnique
              addOnPaste
              pasteSplit={customPasteSplit}
              inputProps={{
                placeholder: I18n.t('order.add_multiple'),
              }}
              addOnBlur={true}
            />
          </FormItem>
        </Col>
      </Row>}
      {/* <Row>
        <Col xs={24}>
          <FormItem label={I18n.t('account.billing.invoice_no')}>
            <Input
              value={formData.invoice_number}
              onChange={(e) =>
                this.handleOnChange('invoice_number', e.target.value)
              }
              className="textUpperCase"
            />
          </FormItem>
        </Col>
      </Row> */}

      <Row>
        <Col xs={24} className="alignCenter">
          <Button
            onClick={() => {
              this.setState({ errors: [] }, this.props.closeCreateBasicInvoiceFormWindow);
            }}
            className="marginRight5"
            icon="close"
          >
            {I18n.t('general.cancel')}
          </Button>
          <Button type="primary" onClick={() => this.handleBasicForm()} icon="right-circle">
            {I18n.t('general.next')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="marginTop20">
            {FormErrors(this.state.errors)}
        </Col>
      </Row>
    </Fragment>);
  }

  render() {
    return (<Spin spinning={this.state.isLoading} delay={1000}> {<this._renderBasicForm />} </Spin>)
  }
}

export default ManualInvoiceForm
