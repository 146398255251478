import { Col, Icon, Input, Popconfirm, Row, Tooltip, Button} from "antd";
import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";
import { Link } from "react-router-dom";
import SwitchComponent from "../../common/switchComponent";
import { Popover } from "../../common/UIComponents";
import { number } from "prop-types";
import { checkNegitive } from "../../common/Common";

class CreditLimitList extends BaseList {
	constructor(props) {
		super(props);
		
		this.columns = [
			{
				key: "account_name",
				title: I18n.t("account.account_name"),
				dataIndex: "account_name",
				className: "noWrap",
				render: (data, record) => (
					<Tooltip title={data}>
					  {data}
					</Tooltip>
				  ),
				align: "left",
				width: 100
			},
			
			{
				key: "Credit_limit",
				title: I18n.t("menu.credit_limit"),
				dataIndex: "credit_limit",
				render: (data, record, index) => {
					return <Input
					addonBefore ={<Icon type="dollar"/>}
						style={{
						width: 150,
						height: 28,
					}}
						value={data}
						type="number"
						onClick={() =>
							this.props.onCreditTextSelection(index, record)
						}
						onChange={(e) => this.props.onCreditLimitChange(e.target.value, index)}
					/>

				},
				align: "center",
				width: 80,

			},
			{
				key: "Open_invoice",
				title: I18n.t("menu.open_invoices"),
				dataIndex: "invoice_open_amount",
				render: (data, record) =>
					<span className="textUpperCase">{data ? checkNegitive(data): ""}</span>,
				align: "right",
				width: 60

			},
			{
				key: "available_credit",
				title: I18n.t("menu.available_credit"),
				dataIndex: "available_credit",
				render: (data, record) =>
					<span className="textUpperCase">{data ? checkNegitive(data) : ""}</span>,
				align: "right",
				width: 70
			},
			
			{
				key:"created_by",
				title: I18n.t("menu.created_by"),
				dataIndex:"user",
				render:(data,record)=>
				<span className="textUpperCase">{data}</span>,
				align:"left",
				width:60

			},
			{
				key:"created_at",
				title: I18n.t("menu.created_at"),
				dataIndex:"updated_at",
				render:(data,record)=>
				<span>{data}</span>,
				align:"left",
				width:80

			},
			{
				key:"status",
				title: I18n.t("menu.status"),
				dataIndex:"mode",
				render:(data)=>
				<span className="textUpperCase">
				{
					data ? data === "ALLOW" ? "ACTIVE" : data :""
				}</span>,
				align:"left",
				width:60

			},

			{
				key: "desc",
				dataIndex: "description",
				render: (data, record) =>

					<Popover
						title={I18n.t("menu.description")}
						content={
							<div style={{ maxWidth: '300px', overflowY: 'auto' }}>
								{data == null ? " " : data}
							</div>}>
						<Icon type="eye" />
					</Popover>,
				width: 30,
				align:"left"
			},
			{
				key: "action",
				title: I18n.t("menu.action"),
				dataIndex: "status",
				render: (data, record, index) => {
					var mode = data === null || data === "" ? "HOLD"
						: (data === 'HOLD' ? 'ALLOW' : 'HOLD')
					return <div className="line-actions paddingLeft10">
							<Col>
								<SwitchComponent
									style={{
										height:"28px",
										fontWeight:"bold",
										width:"67px",
									}}
									handleChange={(value) =>
										this.props.onStatusChange(record, index, value, true)
									}
									isChecked={
										mode === 'HOLD'
									}
									checkedChildren={'Active'}
									unCheckedChildren={'Hold'}
									checkedValue={'ALLOW'}
									unCheckedValue={'HOLD'}
								/>
							</Col>
						</div>
				},
				width: 50,
				align:"center"
			},
		];

	}

}
export default CreditLimitList;