import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import {
  Col,
  Row,
  Search,
  Select,
  Spin,
  Tooltip,
  Dropdown,
  Icon,
  message,
  Button,
} from "../../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  navigateToAccount,
  refreshIcon,
} from "../../../common/Common";
import AppConfig from "../../../config/AppConfig";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../../context/WarehouseContext";
import {
  convertToTags,
  limitString,
  removeSpecialCharacters,
  renderAlertMessage,
} from "../../../helpers/common";
import { OrgContext } from "../../../context/OrgContext";
import I18n from "../../../common/I18n";
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import { UserContext } from "../../../context/UserContext";
import { ScreenKeys } from "../../../containers/constants";
import { fetchInvoiceStatusReport } from "../../../api/Reports";
import userStore from "../../../stores/UserStore";
import InvoiceStatusReportList from "./InvoiceStatusReportList";
import InvoiceStatusReportFilter from "./InvoiceStatusReportFilter";
import { fetchOrgAccounts } from "../../../api/Account";

const defaultFilter = {
  sortBy: "none",
  sortByType: "descend",
  fromDate: moment(),
  toDate: moment(),
  accountCodes: [],
  warehouseIds: "",
  status: "BOTH",
};

class InvoiceStatusReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceStatuses: [],
      pagination: {},
      reportLoading: false,
      recordsPerPage: props.recordsPerPage,
      filterWindow: false,
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      appliedFiltersTags: [],
      accounts: [],
      searchBy: "Invoice",
      search_item_token: "",
      isItemTokenSearched: false,
      searchByToken: false,
      updateSearchTags: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    const { warehouseData } = this.props;

    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    this.setState(
      (prevState) => ({
        ...prevState,
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          warehouseIds: currentWhId,
        },
      }),
      () => {
        this.getAccounts();
        this.getInvoiceStatusReports();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseData.selectedWarehouses, this.props.warehouseData.selectedWarehouses)) {
      const { warehouseData } = this.props;

      const currentWhId =
        warehouseData?.selectedWarehouses?.length &&
          _.isArray(warehouseData.selectedWarehouses)
          ? warehouseData.selectedWarehouses[0]
          : warehouseData.selectedWarehouses;

      this.setState(
        (prevState) => ({
          ...prevState,
          filterPlaceHolder: {
            ...prevState.filterPlaceHolder,
            warehouseIds: currentWhId,
          },
        }),
        () => {
          this.getAccounts();
          this.getInvoiceStatusReports();
          this.computeTags(this.state.filterPlaceHolder);
        }
      );

    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }

    if (
      prevState.isItemTokenSearched !== this.state.isItemTokenSearched &&
      this.state.isItemTokenSearched
    ) {
      this.tableOptions.pagination.current = 1;
      this.getInvoiceStatusReports();
    }
  }

  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  getInvoiceStatusReports = () => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const { filterPlaceHolder, search_item_token } = this.state;
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    // const payload = {
    //   organization_id,
    //   page: this.tableOptions.pagination.current || 1,
    //   per_page: this.state.recordsPerPage,
    //   from_date: filterPlaceHolder.fromDate,
    //   to_date: filterPlaceHolder.toDate,
    //   account_ids: filterPlaceHolder.accountCodes,
    //   warehouse_id: filterPlaceHolder.warehouseIds,
    //   status: filterPlaceHolder.status,
    // };

    let payload = {
      organization_id,
      page: this.tableOptions.pagination.current || 1,
      per_page: this.state.recordsPerPage,
      warehouse_id: filterPlaceHolder.warehouseIds,
      ...(isEmpty(search_item_token)
        ? {
            from_date: filterPlaceHolder?.fromDate ? filterPlaceHolder.fromDate.format('YYYY-MM-DD HH:mm:ss Z') : null,
            to_date: filterPlaceHolder?.toDate ? filterPlaceHolder.toDate.format('YYYY-MM-DD HH:mm:ss Z') : null,
            account_ids: filterPlaceHolder.accountCodes,
            status: filterPlaceHolder.status,
          }
        : this.state.searchBy === "Invoice"
        ? { invoice_no: _.trim(search_item_token) || "" }
        : { customer_order_number: _.trim(search_item_token) || "" }),
    };

    this.setState({ reportLoading: true });
    fetchInvoiceStatusReport(payload).then((result) => {
      if (result.success) {
        const invoiceStatuses = result.reports_data || [];
        const pagination = result.pagination || {};
        this.setState({
          invoiceStatuses,
          pagination,
          reportLoading: false,
          isItemTokenSearched: false,
        });
      } else {
        renderAlertMessage(result.errors);
        this.setState({
          reportLoading: false,
          invoiceStatuses: [],
          isItemTokenSearched: false,
        });
      }
    });
  };

  getAmountTypeLabel = (amountType) => {
    switch (amountType) {
      case "SUCCESS":
        return "Success";
      case "FAILED":
        return "Failed";
      case "BOTH":
        return "Both";
      default:
        return "";
    }
  };

  computeTags = (filterPlaceHolder) => {
    const { warehouseData } = this.props;
    const { warehouses } = warehouseData;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;
    const keys = [
      "fromDate",
      "toDate",
      "status",
      "accountCodes",
      "warehouseIds",
    ];
    const filteredData = {
      fromDate: {
        label: "Report Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouseIds: {
        label: "Warehouse",
        value:
          warehouses.find(
            (warehouse) => warehouse.id === filterPlaceHolder.warehouseIds
          )?.name || "",
        onClose: () => {
          this.handleOnFilterRemoval("warehouseIds");
        },
        closeable:
          !isEmpty(currentWhId) &&
          filterPlaceHolder.warehouseIds === currentWhId
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder.accountCodes.includes(account.id)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },

      status: {
        label: "Status",
        value: this.getAmountTypeLabel(filterPlaceHolder.status),
        onClose: () => {
          this.handleOnFilterRemoval("status");
        },
        closeable: !["BOTH"].includes(filterPlaceHolder?.status),
      },
    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "warehouseIds":
        filterVariable.warehouseIds = currentWhId;
        break;
      case "status":
        filterVariable.status = defaultFilter.status;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      // Callback to ensure the state is updated before further actions
      this.handleSearch();
    });
  };

  handleSearch = (filter = {}) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, filter);
    this.setState(
      {
        filterWindow: false,
        search_item_token: "",
        searchByToken: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getInvoiceStatusReports();
      }
    );
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getInvoiceStatusReports();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getInvoiceStatusReports();
        }
      );
    }
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  clearFilter = () => {
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const updatedFilterPlaceHolder = {
      ...defaultFilter,
      warehouseIds: currentWhId,
    };

    this.setState(
      {
        filterPlaceHolder: updatedFilterPlaceHolder,
        search_item_token: "",
        searchByToken: false,
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage;
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getInvoiceStatusReports();
        updateRecordsPerPage(ScreenKeys.INVOICE_STATUS_REPORT, value);
      }
    );
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  _renderMainSearchBar = () => {
    const { searchBy } = this.state;
    return (
      <Search
        placeholder={
          searchBy === "Invoice" ? "Search Invoice No" : "Search Order No"
        }
        className="seachWithDropDown invoice-search-bar"
        // enterButton="Search"
        size="default"
        value={
          !isEmpty(this.state.search_item_token)
            ? this.state.search_item_token.toUpperCase()
            : ""
        }
        onSearch={(value) => {
          // this.handleFilterChange('search_order_token', value);
          this.setState(
            {
              search_item_token: value,
              isItemTokenSearched: true,
              searchByToken: value.length > 0 ? true : false,
              updateSearchTags: value.length > 0 ? true : false,
            }
            // () => {
            //   this.computeTags(this.state.filterPlaceHolder);
            // }
          );
        }}
        onChange={(e) => {
          // clear url search params
          // if (window.location.search.indexOf("order_number") > -1) {
          //   const { history } = this.props;
          //   history.replace("/invoices");
          // }
          const filteredString = removeSpecialCharacters(e.target.value);
          this.setState({ search_item_token: filteredString });
        }}
        style={{ width: "100%", textTransform: "uppercase" }}
        allowClear
        suffix={<Fragment>{this._renderSearchBar()}</Fragment>}
        prefix={
          <Row
            type="flex"
            align="middle"
            className="invoiceSearchPrefixes"
            gutter={4}
          >
            <Col>
              <Icon
                type="search"
                onClick={() =>
                  this.setState({
                    isItemTokenSearched: true,
                    searchByToken:
                      _.trim(this.state.search_item_token).length > 0
                        ? true
                        : false,
                  })
                }
              />
            </Col>
            <Col>
              <Select
                size="small"
                onChange={(e) => {
                  this.handleOnChange("searchBy", e);
                  if (
                    !isEmpty(this.state.search_item_token) &&
                    this.state.searchByToken
                  ) {
                    this.setState({
                      isItemTokenSearched: true,
                    });
                  }
                }}
                value={this.state.searchBy}
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "1",
                }}
                defaultValue={searchBy === "Invoice" ? "Invoice" : "Order"}
              >
                <Select.Option key="Invoice" value="Invoice">
                  {I18n.t("general.by_invoice").toUpperCase()}
                </Select.Option>
                <Select.Option key="Order" value="Order">
                  {I18n.t("general.by_order").toUpperCase()}
                </Select.Option>
              </Select>
            </Col>
          </Row>
        }
      />
    );
  };

  _renderSearchBar = () => (
    <Dropdown
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 550,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <InvoiceStatusReportFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            orgData={this.props.orgData}
            warehouseFilter={this.props.warehouseData}
            disableButton={this.state.reportLoading}
            accounts={this.state.accounts}
          />
        </div>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <span style={{ marginRight: "5px" }}>Filter</span> */}
        <Icon
          style={{
            color: "black",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "5px",
          }}
          type="filter"
        />
      </div>
    </Dropdown>
  );

  _renderHeaderInfo = () => {
    return (
      <Row className="page-header">
        <Col xs={12}>{I18n.t("invoice_status_report.title")}</Col>
        <Col xs={2}></Col>
        <Col xs={10} className="alignRight headerOptionDiv">
          {this._renderMainSearchBar()}
          {/* {this._renderSearchBar()} */}
        </Col>
      </Row>
    );
  };

  _renderAppliddFilter = () => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={24} style={{ marginTop: 15 }}>
          <AppliedFiltersTags
            tags={this.state.appliedFiltersTags}
            clearFilter={() => this.clearFilter()}
            isSearching={this.state.searchByToken}
            searchText={this.state.search_item_token}
            searchBy={
              this.state.searchBy
                ? this.state.searchBy.charAt(0).toUpperCase() +
                  this.state.searchBy.slice(1)
                : ""
            }
            updateSearchTags={this.state.updateSearchTags}
            resetSearchTags={() => this.setState({ updateSearchTags: false })}
            screenFrom={"invoiceStatusReport"}
          />
        </Col>
      </Row>
    );
  };

  _renderListOperation = () => {
    return (
      <Row type="flex" justify="end" style={{ marginTop: 10 }}>
        <Col>
          <RecordsPerPage
            onChange={this.onRecordChange}
            value={this.state.recordsPerPage}
            defaultValue={AppConfig.ordersPerPage}
          />
        </Col>
        <Col style={{ marginLeft: 10 }}>
          <Button
            onClick={() => this.getInvoiceStatusReports()}
            icon="reload"
            size="small"
            type="primary"
          >
            Reload
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.reportLoading} delay={1000}>
            <this._renderHeaderInfo />
            <this._renderListOperation />
            <this._renderAppliddFilter />
            <Row>
              <InvoiceStatusReportList
                data={this.state.invoiceStatuses}
                scroll={{ y: "calc(100vh - 280px)", x: "max-content" }}
                navigateToAccount={(account_id) =>
                  navigateToAccount(
                    this.state.accounts,
                    account_id,
                    this.props.history
                  )
                }
                pagination={pagination}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  this.onTableChange(pagination, filter, sorter, currentTable)
                }
              />
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}
export const InvoiceStatusReportsComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.INVOICE_STATUS_REPORT;
  const recordsPerPage = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );

  return (
    <InvoiceStatusReports
      warehouseData={warehouseData}
      orgData={orgData}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default InvoiceStatusReportsComponent;
