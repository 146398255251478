/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import BaseList from '../BaseList'
import _ from 'lodash'
import {
  Button,
  Tooltip,
  Icon,
  Popconfirm,
  Input,
  Row,
  Col,
} from '../../common/UIComponents'
import { isEmpty, currencyConversion } from '../../common/Common'
import I18n from '../../common/I18n'
import AppConfig from '../../config/AppConfig'
import {
  renderWeightHeading,
  checkServiceExistance,
} from '../../helpers/common'
import DeleteIcon from '../common/DeleteIcon'

class LosList extends BaseList {
  constructor(props) {
    super()
    this.columns = []
    this.setColumns(props)
  }

  renderWeightType = (apply_cwt) => {
      return (
        <Fragment>
          {apply_cwt === 'additional_wt'
            ? `(CWT - ${I18n.t('los.additional')})`
            : apply_cwt === 'complete_wt'
            ? `(CWT - ${I18n.t('los.complete')})`
            : ''}
        </Fragment>
      )
    }
 
  renderMinMaxChargeForRange = (record = {}, weight= {}, editableRecord, props) => {
    const minCharge = record[weight.id]?.min_amount ?  currencyConversion(record[weight.id].min_amount) : '';
    const maxCharge = record[weight.id]?.max_amount ?  currencyConversion(record[weight.id].max_amount) : '';
    return (<div>
    {checkServiceExistance('BZUMC') ? (
      <span className="cursorPointer">
        {(record[weight.id] &&
          record[weight.id].weight_min_max_charge_editable === false) ? (
          <Button
            type="link"
            onClick={() =>
              this.props.handleRangeMinMaxRangeChange(
                record.zone_id,
                weight.id,
                true,
              )
            }
            className="fontSize12"
          >
            {(minCharge === 0 && maxCharge === 0) ||
            (isEmpty(minCharge) &&
              isEmpty(maxCharge)) ? (
              <Fragment>{I18n.t('general.add')} Min / Max</Fragment>
            ) : (
              <Fragment>
                {minCharge
                  ? minCharge
                  : I18n.t('general.add')}
                &nbsp;/&nbsp;
                {maxCharge
                  ? maxCharge
                  : I18n.t('general.add')}
              </Fragment>
            )}
          </Button>
        ) : (
          <Fragment>
            <Input
              className="borderNone"
              placeholder="Min"
              style={{
                border: 'none',
                borderBottom: '1px solid #CCC',
                width: 50,
                fontSize: 12,
              }}
              value={
                editableRecord[record.zone_id] && editableRecord[record.zone_id][weight.id] 
                  ? editableRecord[record.zone_id][weight.id].min_amount
                  : ''
              }
              onChange={(e) =>
                props.onChange(
                  record.zone_id,
                  weight.id,
                  'min_amount',
                  e.target.value,
                )
              }
              onKeyDown={(e) =>
                this.props.handleKeyDown(
                  record.zone_id,
                  weight,
                  'min_amount',
                  e,
                )
              }
            />
            &nbsp;/&nbsp;
            <Tooltip
              trigger={['focus']}
              title={I18n.t('los.gt_min_charge')}
              placement="topLeft"
              overlayClassName="numeric-input"
            >
              <Input
                className="borderNone"
                placeholder="Max"
                style={{
                  border: 'none',
                  borderBottom: '1px solid #CCC',
                  width: 50,
                  fontSize: 12,
                }}
                value={
                  editableRecord[record.zone_id] && editableRecord[record.zone_id][weight.id] 
                    ? editableRecord[record.zone_id][weight.id].max_amount
                    : ''
                }
                onChange={(e) =>
                  props.onChange(
                    record.zone_id,
                    weight.id,
                    'max_amount',
                    e.target.value,
                  )
                }
                onKeyDown={(e) =>
                  this.props.handleKeyDown(
                    record.zone_id,
                    weight,
                    'max_amount',
                    e,
                  )
                }
              />
            </Tooltip>
            <Icon
              type="check-circle"
              className="textGreen fontSize15"
              onClick={() =>
                props.handleZoneWeightSave(record.zone_id, weight)
              }
              style={{ padding: '0px 3px' }}
            />
            <Icon
              type="close-circle"
              className="textRed fontSize15"
              onClick={() =>
                props.handleClear(record.zone_id, weight.id, 'range_min_max_charge')
              }
            />
          </Fragment>
        )}
      </span>
    ) : (
      <span className='fontSize12'>
        {minCharge}/{maxCharge}
      </span>
    )}
  </div>)
  }

  setColumns = (props) => {
    const weights = props.weights || []
    const zones = props.data || []
    const showDeleteIcon = props.showDeleteIcon !== false ? true : false
    const showMinMaxcharge = props.showMinMaxcharge !== false ? true : false
    const showMincharge = props.showMincharge !== false ? true : false
    const editableRecord = props.editableRecord ? props.editableRecord : {}
    const doesLosExist = _.isObject(props.los) && !isEmpty(props.los)
    const los = props.los || {}
    const configurations =
      doesLosExist && _.isObject(los.configurations)
        ? los.configurations
        : {}
    const isPalletRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === 'PALLET'
      : false
    const isCabinetRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === 'CABINET'
      : false
    const isHoursRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === "HOURS"
      : false;
    const isMilesRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === "MILES"
      : false;
    const isOrderItemRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === "ITEMS"
      : false;
    const isPerCubeRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === "PER_CUBE"
      : false;
    const isPerPieceRange = _.isEmpty(configurations)
      ? false
      : configurations?.los_range
      ? configurations.los_range === "PIECES"
      : false;
    this.columns.push({
      key: 'Name',
      title: `${I18n.t('account.billing.zones')} / ${
        isPalletRange
          ? I18n.t('general.pallet')
          : isCabinetRange
          ? I18n.t("general.cabinet")
          : isHoursRange
          ? I18n.t("general.hours")
          : isMilesRange
          ? I18n.t("general.miles")
          : isOrderItemRange
          ? I18n.t("los.order_item")
          : isPerCubeRange
          ? I18n.t("los.per_cube") 
          : isPerPieceRange
          ? I18n.t("los.per_piece") 
          : I18n.t("general.weight")
      }`,
      dataIndex: 'zone_name',
      // className: 'customPadding',
    })
    if (showMincharge === true) {
      this.columns.push({
        key: 'min_charge',
        title: `${I18n.t('account.billing.min_charge')}`,
        className: 'customPadding',
        render: (record) => {
          const minCharge = record.min_charge
            ? currencyConversion(record.min_charge)
            : ''
          return (
            <div>
              {checkServiceExistance('BZUMC') ? (
                <span className="cursorPointer anchor_cursor">
                  {record.min_charge_editable === false ? (
                    <Button
                      type="link"
                      onClick={() =>
                        this.props.handleZoneMinCharge(record.zone_id, true)
                      }
                    >
                      {record.min_charge
                        ? minCharge
                        : I18n.t('account.billing.add_cost')}
                    </Button>
                  ) : (
                    <Fragment>
                      <Input
                        className="borderNone"
                        value={
                          editableRecord[record.zone_id]
                            ? editableRecord[record.zone_id].min_charge
                            : ''
                        }
                        style={{
                          border: 'none',
                          borderBottom: '1px solid #CCC',
                          width: 60,
                        }}
                        onChange={(e) =>
                          props.onChange(
                            record.zone_id,
                            '',
                            'min_charge',
                            e.target.value,
                          )
                        }
                        onKeyDown={(e) =>
                          this.props.handleKeyDown(
                            record.zone_id,
                            {},
                            'min_charge',
                            e,
                          )
                        }
                      />

                      <Icon
                        type="check-circle"
                        className="textGreen fontSize15"
                        onClick={() =>
                          props.handleMinChargeSave(record.zone_id)
                        }
                        style={{ padding: '0px 3px' }}
                      />

                      <Icon
                        type="close-circle"
                        className="textRed fontSize15"
                        onClick={() =>
                          props.handleClear(record.zone_id, '', 'min_charge')
                        }
                      />
                    </Fragment>
                  )}
                </span>
              ) : (
                <span>{minCharge}</span>
              )}
            </div>
          )
        },
      })
    }

    if (showMinMaxcharge === true) {
      this.columns.push({
        key: 'min_charge',
        title: `${I18n.t('account.billing.min_charge')} / ${I18n.t(
          'account.billing.max_charge',
        )}`,
        className: 'customPadding',
        render: (record) => {
          const minCharge = record.min_charge
            ? currencyConversion(record.min_charge)
            : ''
          const maxCharge = record.max_charge
            ? currencyConversion(record.max_charge)
            : ''
          return (
            <div>
              {checkServiceExistance('BZUMC') ? (
                <span className="cursorPointer">
                  {record.min_max_charge_editable === false ? (
                    <Button
                      type="link"
                      onClick={() =>
                        this.props.handleZoneMinCharge(record.zone_id, true)
                      }
                      className="fontSize13"
                    >
                      {(record.min_charge === 0 && record.max_charge === 0) ||
                      (isEmpty(record.min_charge) &&
                        isEmpty(record.max_charge)) ? (
                        I18n.t('general.add')
                      ) : (
                        <Fragment>
                          {record.min_charge
                            ? minCharge
                            : I18n.t('general.add')}
                          &nbsp;/&nbsp;
                          {record.max_charge
                            ? maxCharge
                            : I18n.t('general.add')}
                        </Fragment>
                      )}
                    </Button>
                  ) : (
                    <Fragment>
                      <Input
                        className="borderNone"
                        value={
                          editableRecord[record.zone_id] &&
                          editableRecord[record.zone_id].min_charge
                            ? editableRecord[record.zone_id].min_charge
                            : ''
                        }
                        placeholder="Min"
                        style={{
                          border: 'none',
                          borderBottom: '1px solid #CCC',
                          width: 60,
                        }}
                        onChange={(e) =>
                          props.onChange(
                            record.zone_id,
                            '',
                            'min_charge',
                            e.target.value,
                          )
                        }
                        onKeyDown={(e) =>
                          this.props.handleKeyDown(
                            record.zone_id,
                            {},
                            'min_charge',
                            e,
                          )
                        }
                      />
                      &nbsp;/&nbsp;
                      <Tooltip
                        trigger={['focus']}
                        title={I18n.t('los.gt_min_charge')}
                        placement="topLeft"
                        overlayClassName="numeric-input"
                      >
                        <Input
                          className="borderNone"
                          placeholder="Max"
                          value={
                            editableRecord[record.zone_id] &&
                            editableRecord[record.zone_id].max_charge
                              ? editableRecord[record.zone_id].max_charge
                              : ''
                          }
                          style={{
                            border: 'none',
                            borderBottom: '1px solid #CCC',
                            width: 60,
                          }}
                          onChange={(e) =>
                            props.onChange(
                              record.zone_id,
                              '',
                              'max_charge',
                              e.target.value,
                            )
                          }
                          onKeyDown={(e) =>
                            this.props.handleKeyDown(
                              record.zone_id,
                              {},
                              'max_charge',
                              e,
                            )
                          }
                        />
                      </Tooltip>
                      <Icon
                        type="check-circle"
                        className="textGreen fontSize15"
                        onClick={() =>
                          props.handleMinChargeSave(record.zone_id)
                        }
                        style={{ padding: '0px 3px' }}
                      />
                      <Icon
                        type="close-circle"
                        className="textRed fontSize15"
                        onClick={() =>
                          props.handleClear(record.zone_id, '', 'min_max_charge')
                        }
                      />
                    </Fragment>
                  )}
                </span>
              ) : (
                <span>
                  {minCharge}/{maxCharge}
                </span>
              )}
            </div>
          )
        },
      })
    }

    weights.forEach((weight) => {
      const checkMaxRangeValue = !isEmpty(props.takeMaxRangeValue)
        ? props.takeMaxRangeValue
        : false
      this.columns.push({
        key: weight.id,
        title:
          checkServiceExistance('BRCW') && weight.isNew ? (
            <Button
              type="primary"
              size="small"
              onClick={() => this.props.handleWeight(weight)}
              icon="plus"
            >
              {isPalletRange
                ? I18n.t('account.billing.pallet.add')
                : isCabinetRange
                ? I18n.t("account.billing.cabinet.add")
                : isHoursRange
                ? I18n.t("account.billing.hours.add")
                : isMilesRange
                ? I18n.t("account.billing.miles.add")
                : isOrderItemRange
                ? I18n.t("account.billing.orderItem.add")
                : isPerCubeRange
                ? I18n.t("account.billing.perCube.add")
                : isPerPieceRange
                ? I18n.t("account.billing.perPiece.add")
                : I18n.t("account.billing.weights.add")}
            </Button>
          ) : (
            <Row>
              <Col
                xs={22}
                onClick={() => {
                  checkServiceExistance('ULOSWR') &&
                  this.props?.isEditWeightRange
                    ? this.props.editWeightRange(weight, configurations)
                    : null
                }}
                style={
                  this.props?.isEditWeightRange ? { cursor: 'pointer' } : {}
                }
              >
                <div
                  style={
                    this.props?.isEditWeightRange
                      ? {
                          textDecoration: 'underline',
                        }
                      : {}
                  }
                >
                  {renderWeightHeading(weight, checkMaxRangeValue)} (
                  {isPalletRange
                    ? I18n.t('general.pallets')
                    : isCabinetRange
                    ? I18n.t("general.cabinets")
                    : isHoursRange
                    ? I18n.t("general.hours")
                    : isMilesRange
                    ? I18n.t("general.miles")
                    : isOrderItemRange
                    ? I18n.t("los.order_item")
                    : isPerCubeRange
                    ? I18n.t("los.per_cube")
                    : isPerPieceRange
                    ? I18n.t("los.per_piece")
                    : AppConfig.weight_unit}
                  )
                </div>

                {!isEmpty(weight.apply_cwt) &&
                  weight.apply_cwt.length &&
                  !isPerPieceRange && !isPerCubeRange &&!isPalletRange && !isHoursRange && !isMilesRange && !isCabinetRange && !isOrderItemRange &&
                  (
                    <Fragment>
                      <p className="textBold" style={{ fontSize: '0.6rem' }}>
                        {this.renderWeightType(weight.apply_cwt)}
                      </p>
                    </Fragment>
                  )}
                {/* <u onClick={() => this.props.handleWeight(weight)} className="text-decoration-dashed cursorPointer">{`${weight.min_weight} - ${weight.max_weight} (${AppConfig.weight_unit})`}</u> */}
              </Col>
              <Col xs={2}>
                {checkServiceExistance('DWR') && showDeleteIcon !== false && (
                  <Tooltip title={ I18n.t("general.delete") }>
                  <Popconfirm
                    placement="left"
                    title={I18n.t('messages.delete_confirm')}
                    onConfirm={() =>
                      this.props.deleteClick(
                        weight,
                        los && los.los_id
                          ? los.los_id
                          : '',
                      )
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon/>
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm>
                  </Tooltip>
                )}
              </Col>
            </Row>
          ),

        render: (record, index) => {
          const cost =
            record[weight.id] &&
            !isEmpty(record[weight.id].cost) &&
            record[weight.id].cost
              ? record[weight.id].cost
              : ''
          return (
            <div>
              <Fragment>
                {weight.isNew ? (
                  ''
                ) : (
                  <Fragment>
                    {isEmpty(record[weight.id]) ||
                    (record[weight.id] &&
                      record[weight.id].isEditable === false) ? (
                      <Fragment>
                        <Fragment>
                          {checkServiceExistance('UZBRA') ? (
                            cost ? (
                              <span
                                className="cursorPointer anchor_cursor"
                                onClick={() =>
                                  this.props.handleZoneWeight(
                                    record.zone_id,
                                    weight.id,
                                    true,
                                  )
                                }
                              >
                                <Button type="link" size="small">
                                  {currencyConversion(record[weight.id].cost)}
                                </Button>
                              </span>
                            ) : (
                              <span
                                className="cursorPointer anchor_cursor"
                                onClick={() =>
                                  this.props.handleZoneWeight(
                                    record.zone_id,
                                    weight.id,
                                    true,
                                  )
                                }
                              >
                                {I18n.t('account.billing.add_cost')}
                              </span>
                            )
                          ) : (
                            <Fragment>{currencyConversion(cost)}</Fragment>
                          )}
                        </Fragment>
                        {!weight.isNew &&
                        record[weight.id] &&
                        !isEmpty(record[weight.id].breakpoint_weight) &&
                        record[weight.id].breakpoint_weight !== 0 ? (
                          <p className="fontSize11">
                            <Tooltip title={I18n.t('account.billing.bpw')}>
                              <i>
                                BW &nbsp; {record[weight.id].breakpoint_weight}
                              </i>
                            </Tooltip>
                          </p>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Input
                          className="borderNone"
                          style={{
                            border: 'none',
                            borderBottom: '1px solid #CCC',
                            width: 60,
                          }}
                          value={
                            editableRecord[record.zone_id] && editableRecord[record.zone_id][weight.id]
                              ? editableRecord[record.zone_id][weight.id].cost
                              : ''
                          }
                          onChange={(e) =>
                            props.onChange(
                              record.zone_id,
                              weight.id,
                              'cost',
                              e.target.value,
                            )
                          }
                          onKeyDown={(e) =>
                            this.props.handleKeyDown(
                              record.zone_id,
                              weight,
                              'weight',
                              e,
                            )
                          }
                          //onChange={(e)=>this.handleCurrentLosChnage('weight_amount', e.target.value)}
                        />
                        &nbsp;&nbsp;
                        <Icon
                          type="check-circle"
                          className="textGreen fontSize15"
                          onClick={() =>
                            props.handleZoneWeightSave(record.zone_id, weight)
                          }
                        />
                        &nbsp;&nbsp;
                        <Icon
                          type="close-circle"
                          className="textRed fontSize15"
                          onClick={() =>
                            this.props.handleClear(record.zone_id, weight.id, 'weight')
                          }
                        />
                      </Fragment>
                    )}
                     {
                     los?.configurations?.apply_min_max_charge_on_each_range == true &&  
                     this.renderMinMaxChargeForRange(record, weight, editableRecord,  props)
                    }
                  </Fragment>
                )}
              </Fragment>
             
            </div>
          )
        },
        align: 'center',
        // className: 'customPadding',
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    this.columns = []
    this.setColumns(nextProps)
  }
}
export default LosList
