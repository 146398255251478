import React, { Component,useContext,useEffect,useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Col,
  Row,
  Popconfirm,
  Button,
  notification,
  Spin,
  Modal,
} from "../../common/UIComponents";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import RoutesList from "./RoutesList";
import { sendStatusFile } from "../../api/Routes";
import userStore from "../../stores/UserStore";
import { OrgContext } from "../../context/OrgContext";
import { withRouter } from "react-router";
import { renderAlertMessage } from "../../helpers/common";

class GenerateRouteFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeInfo: [],
      currentPreplan: {},
      routes: {
        selectedKeys: [],
        selectedRows: [],
      },
      inProgress: false,
      isInitial: false,
      organizationSettings: {},
      isMilitaryTime : false,
    };
  }

  componentDidMount() {
    this.setDelectedData();
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
  }

  setDelectedData = () => {
    const routeInfo = [...this.state.routeInfo];
    this.setState(
      {
        routes: {
          selectedKeys: routeInfo
            .filter((route) => route.isOptimized === true)
            .map((route) => route.route_id),
          selectedRows: [...routeInfo],
        },
      },
      () => {
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { routeInfo } = props;
    const { currentPreplan } = props;
    return {
      routeInfo,
      currentPreplan,
    };
  }

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedKeys: selectedRowKeys,
        selectedRows,
      }),
      selectAll: false,
    });
  };

  handleClear = () => {
    this.handleSelectChange([], []);
  };

  handleGenerare = () => {
    const preplanRoutes = [...this.state.routes.selectedKeys];

    if (preplanRoutes.length > 0) {
      const notOptimizedRoutes = this.state.routeInfo
        .filter(
          (route) =>
            preplanRoutes.includes(route.route_id) && route.isOptimized === false
        )
        .map((route) => route.displayName);
      if (notOptimizedRoutes.length > 0) {
        Modal.error({
          title: `The following route${notOptimizedRoutes.length > 1 ? "s are" : "is"
            } not optimised`,
          content: (
            <Row>
              <Col>
                <h4>{notOptimizedRoutes.join(", ")}</h4>
              </Col>
              <Col>
                {`Please make sure all selected routes are optimized before generating manifest file.`}
              </Col>
            </Row>
          ),
        });
      } else {
        this.setState({ inProgress: true });
        const requiredData = {
          nav_route_ids: preplanRoutes,
          organization_id: userStore.getStateValue("selectedOrg"),
        };
        sendStatusFile(requiredData).then((result) => {
          if (result.success) {
            this.setState({
              inProgress: false,
            });
            this.props.onCancel();
            notification.success({
              message: I18n.t("messages.confirmed"),
              placement: "bottomRight",
            });
          } else {
            renderAlertMessage(result.errors)
            this.setState({
              inProgress: false,
            });
          }
        });
      }
    }
  };

  render() {
    const { currentPreplan, routeInfo } = this.state;
    const selectedRowKeys = [...this.state.routes.selectedKeys];
    // if (this.state.selectAll === true) {
    //   selectedRowKeys = routeInfo.map(route => route.route_id);
    // } else {
    //   selectedRowKeys = this.state.routes.selectedKeys;
    // }
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      // getCheckboxProps: (record) => ({
      //   disabled: record.optimized === false, // Column configuration not to be checked
      //   route_id: record.route_id,
      // }),
      getCheckboxProps: null,
    };
    const gridSize = currentPreplan.preplan_type === "single_day" ? 8 : 6;
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            <Col md={gridSize}>
              <div className="info-text">
                <h4>{I18n.t("general.name")}:</h4>
                {currentPreplan.name ? currentPreplan.name : "NA"}
              </div>
            </Col>
            <Col md={gridSize}>
              <div className="info-text">
                <h4>{I18n.t("location.locations")}:</h4>
                {currentPreplan.warehouse && currentPreplan.warehouse.name
                  ? currentPreplan.warehouse.name
                  : "NA"}
              </div>
            </Col>
            <Col md={gridSize}>
              <div className="info-text">
                <h4>
                  {I18n.t(
                    `${currentPreplan.preplan_type !== "single_day"
                      ? "info.start_date"
                      : "preplan.deliverable_start_date"
                    }`
                  )}
                  :
                </h4>
                {currentPreplan.delivery_start_date
                  ? moment(new Date(currentPreplan.delivery_start_date)).format(
                    "Do MMM, YYYY"
                  )
                  : "NA"}
              </div>
            </Col>
            {currentPreplan.preplan_type !== "single_day" && (
              <Col md={gridSize}>
                <div className="info-text">
                  <h4> {I18n.t("info.end_date")}:</h4>
                  {currentPreplan.delivery_end_date
                    ? moment(new Date(currentPreplan.delivery_end_date)).format(
                      "Do MMM, YYYY"
                    )
                    : "NA"}
                </div>
              </Col>
            )}
          </Row>
          <hr />
          <div>
            <RoutesList
              isMilitaryTime={this.state.isMilitaryTime}
              rowKey="route_id"
              data={routeInfo || []}
              pagination={{ position: "none" }}
              rowSelection={rowSelection}
              isPreplan={true}
              rowClassName={(record) =>
                record.isOptimized === false
                  ? "blockBgErrorColor"
                  : "table-row-light"
              }
            />
          </div>
          <Row className="relative_bottom_fixed">
            <Col
              xs={24}
              style={{ padding: "10px 10px" }}
              className="alignCenter"
            >
              <Button type="danger" onClick={this.handleClear} icon="close-circle">
                {I18n.t("general.clear")}
              </Button>
              &nbsp;&nbsp;
              <Popconfirm
                placement="rightTop"
                title={I18n.t("messages.confirm")}
                onConfirm={this.handleGenerare}
                okText="Yes"
                cancelText="No"
                disabled={selectedRowKeys.length === 0}
              >
                <Button type="primary" disabled={selectedRowKeys.length === 0} icon="check-circle">
                  {I18n.t("general.confirm")}
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export const GenerateRouteFilePreplan = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <GenerateRouteFile
      organizationSettings={ organizationSettings }
      { ...props }
    />
  );
});

export default GenerateRouteFilePreplan;