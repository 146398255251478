import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";

const requiredFields = [];

class ReferenceTypesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeReference: "",
      isNew: true,
      inProgress: false,
      errors: [],
    };
  }
  handleOnChange = (element, value) => {
    this.setState({
      activeReference: Object.assign({}, this.state.activeReference, {
        [element]: value,
      }),
    });
  };
  render() {
    return (
      <div>
        <Row type="flex" gutter={6} align="center">
          <Col className="centerToBlock textBold">
            {I18n.t("references.reference_create")}
          </Col>
          <Col className="centerToBlock">
            <Input
              value={this.props.reference}
              onChange={(e) =>
                this.props.onHandleInputChanges("reference", e.target.value)
              }
              onPressEnter={this.props.addReference}
            />
          </Col>
          <Col className="centerToBlock">
            <Button
              type="primary"
              onClick={this.props.addReference}
              disabled={!this.props.reference}
              icon='save'
              size='small'
            >
              Save
            </Button>
            <Button
              onClick={this.props.handleCancel}
              style={{ marginLeft: "5px" }}
              icon='close-circle'
              type="danger"
              size='small'
            >
              {" "}
              Clear{" "}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ReferenceTypesForm;
