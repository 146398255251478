import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import React from "react";

class CapacityReportsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "employee_code",
        title: <b>{I18n.t("capacity_report.driver_code")}</b>,
        dataIndex: "employee_code",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "current_weight",
        title: <b>{I18n.t("capacity_report.current_weight")}</b>,
        dataIndex: "current_weight",
        render: (data, record) => <span>{data}</span>,
        width: 100,
        align: "right",
      },
      {
        key: "vehicle_weight",
        title: <b>{I18n.t("capacity_report.vehicle_weight")}</b>,
        dataIndex: "vehicle_weight",
        render: (data, record) => <span>{data}</span>,
        width: 100,
        align: "right",
      },
      {
        key: "empty_space",
        title: <b>{I18n.t("capacity_report.empty_space")}</b>,
        dataIndex: "empty_space",
        render: (data, record) => <span>{data}</span>,
        width: 100,
        align: "right",
      },
    ];
  }
}

export default CapacityReportsList;
