import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { findNumberTotal } from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Alert,
  Popconfirm,
  Button,
  Row,
  Col,
  Tooltip,
  Link,
  Popover,
  Icon,
} from '../../common/UIComponents'
import { findOverloadReasonsWithDetails } from '../../helpers/capacityValidation'
import { formatSurfaceArea, formatWeight } from '../../helpers/orders'
import { stopTypeFromObject } from '../../helpers/stops'
import CapacityOrders from '../orders/CapacityOrders'
import StopCapacityInfo from '../stops/StopCapacityInfo'
import StopSurfaceArea from '../stops/StopSurfaceArea'
import infoIcon from "../../../assets/images/info_icon.svg";

const UnallocatedStops = ({ route, stopKey, deleteClick }) => {
  const stops = route[stopKey] ? route[stopKey] : []
  const unallocatedStops = stops.filter((stop) => stop.status === 'UNALLOCATED')
  if (unallocatedStops.length > 0) {
    const stopIds = unallocatedStops.map((stop) => stop.id)
    const csStopCapacityInfo = unallocatedStops
      .filter((stop) => stop?.location && stop.location.l_type === 'CS')
      .map((stop) => ({ ...stop.capacity_info, overloaded_by: stop.overloaded_by }))
    const weightOverloadedStops = csStopCapacityInfo.filter(stop => ['weight', 'both'].includes(stop.overloaded_by));
    const saOverloadedStops = csStopCapacityInfo.filter(stop => ['surface area', 'both'].includes(stop.overloaded_by));
    const fromAnotherWh = route.isLocked;
    const totalOverloadedWeight = findNumberTotal(
      csStopCapacityInfo, 
      'stop_weight',
    ) // weightOverloadedStops
    const totalOverloadedSA = findNumberTotal(
      csStopCapacityInfo, 
      'stop_surface_area',
    ) // saOverloadedStops
    let orderInfo = [];
    const renderInfoIcon = (orderInfo) => {
      return(
        <Popover
              content={
                <div style={{ maxWidth: 400 }}>
                  <CapacityOrders
                    data={orderInfo}
                    pagination={{ position: 'none'}}
                    scroll={{ y: 100 }}
                  />
                </div>
              }
              //placement={placement}
              title={I18n.t('preplan.capacity.overloaded_orders')} //{I18n.t("menu.orders")}
              getPopupContainer={(triggerNode) => triggerNode}
            >
              <Icon
                component={() => (
                  <img
                    style={{ height: 15, marginTop: -6 }}
                    src={infoIcon}
                  />
                )}
              />
          </Popover>
      )
    }
    const title = (
      <h4>
        Stop{unallocatedStops.length > 1 ? "s" : ""}{" "}
        {/* {unallocatedStops.map((stop) =>{stop.stop_number - 1}).join(", ")}{" "} */}
        {unallocatedStops.map((stop, index) => { 
          const stopType = stopTypeFromObject(stop);
          orderInfo = [].concat(orderInfo, stop.orders);
          return <Fragment>
            <Popover title={`Stop ${stop.stop_number - 1}`} content={
            <div style={{ maxWidth: 500, fontSize: 12 }}>
              <Row>
                <Col xs={24} md={12}>
                <StopCapacityInfo
                  stop={{
                    ...stop.capacity_info,
                    stopType,
                    stop_number: stop.stop_number,
                    status: stop.status,
                  }}
                  showDeptInfo={false}
                  key={stop.id}
                  route={route}
                  />
                </Col>
                <Col xs={24} md={12}>
                <StopSurfaceArea
                  stop={{
                    ...stop.capacity_info,
                    stopType,
                    stop_number: stop.stop_number,
                    status: stop.status,
                  }}
                  route={route}
                  showDeptInfo={false}
                />
                </Col>
                <Col xs={24} className="paddingTop20">
                <div className='textBold textRed'>{I18n.t('preplan.capacity.overloaded_orders')}:</div>
                <div style={{ maxWidth: 400}}>
                  <CapacityOrders
                    data={stop.orders}
                    pagination={{ position: 'none'}}
                    scroll={{ y: 100 }}
                  />
                </div>
                </Col>
              </Row>
            </div>
            }
              >
              <u className='cursorPointer'>{stop.stop_number - 1}</u>
            </Popover>
            {index !== unallocatedStops.length -1  && ", "}
            </Fragment>
        })
        }
        {unallocatedStops.length > 1 ? " are" : " is"} overloaded {renderInfoIcon(orderInfo)}.
        {!fromAnotherWh && (
          <Fragment>
            <Popconfirm
              placement="topRight"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => deleteClick(route.route_id, stopIds)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" size="small">
                Click here
              </Button>{" "}
            </Popconfirm>
            to remove them from route.
          </Fragment>
        )}
      </h4>
    );
    return (
      <Alert
        style={{ marginBottom: 10, marginTop: 10 }}
        message={title}
        type="error"
        description={
          <Row>
            {totalOverloadedWeight > 0 && (
              <Col xs={12}>
                {I18n.t('preplan.capacity.overloaded')}: &nbsp;
                <span className="textBold">
                  {formatWeight(totalOverloadedWeight)}
                </span>
              </Col>
            )}
            {totalOverloadedSA > 0 && (
              <Col xs={12} className={totalOverloadedWeight > 0 ? "alignRight" : 'alignLeft'}>
                <Tooltip title={I18n.t('preplan.surface_area.overloaded')}>
                  {I18n.t('preplan.surface_area.overloaded_sf')}:
                </Tooltip>
                &nbsp;
                <span className="textBold">
                  {formatSurfaceArea(totalOverloadedSA)}
                </span>
              </Col>
            )}
          </Row>
        }
      />
    )
  }
  return <Fragment />
}

export default UnallocatedStops

UnallocatedStops.propTypes = {
  route: PropTypes.shape().isRequired,
  stopKey: PropTypes.string.isRequired,
  deleteClick: PropTypes.func.isRequired,
}
UnallocatedStops.defaultProps = {
  moveStop: null,
}
