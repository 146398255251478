import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import LinehaulComponent from "../../../containers/LineHaul/Linehaul";
import { withRouter } from "react-router";
import moment from "moment";


const Linehaul = ({ route, warehouses, warehouse_id, closeSearchOrders }) => {
  const startTime = route?.r_scheduled_start_datetime
    ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    : moment();
  const endTime = route?.r_scheduled_start_datetime
    ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    : moment();

  return (
    <Fragment>
      <LinehaulComponent
        reqFrom={"dispatch"}
        startTime={startTime}
        endTime={endTime}
        route={route}
        closeSearchOrders={() =>
          closeSearchOrders(true, route.id, "currentRoute")
        }
      />
    </Fragment>
  );
};


Linehaul.propTypes = {
  route: PropTypes.shape().isRequired,
  closeSearchOrders: PropTypes.func.isRequired,
};

Linehaul.defaultProps = {
  route: {},
  closeSearchOrders: () => {},
};

export default withRouter(Linehaul);
