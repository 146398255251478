/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row, Icon, Tooltip } from '../../common/UIComponents';
import { doFormate } from '../../common/Common';
import { secondsToHms } from '../../helpers/common';


const StopContact = ({ stop }) => {
  const actual_start_date_time_with_tz = stop.actual_start_date_time_with_tz ? moment.utc(stop.actual_start_date_time_with_tz).format('lll') : 'NA';
  const actual_end_date_time_with_tz = stop.actual_end_date_time_with_tz ? moment.utc((stop.actual_end_date_time_with_tz)).format('lll') : 'NA';
  const scheduled_start_date_time_with_tz = stop.scheduled_start_date_time_with_tz ? moment.utc(stop.scheduled_start_date_time_with_tz).format('lll') : 'NA';
  const scheduled_end_date_time_with_tz = stop.scheduled_end_date_time_with_tz ? moment.utc(stop.scheduled_end_date_time_with_tz).format('lll') : 'NA';
  const estimated_arrival_date_time_with_tz = stop.estimated_arrival_date_time_with_tz ? moment.utc(stop.estimated_arrival_date_time_with_tz).format('lll') : 'NA';
  const estimated_departure_date_time_with_tz = stop.estimated_departure_date_time_with_tz ? moment.utc(stop.estimated_departure_date_time_with_tz).format('lll') : 'NA';
  const service_duration = stop.service_duration ? secondsToHms(parseInt(stop.service_duration), true, true, false) : '0';
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Row />
        <Row>

          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col sm={2} xs={2} md={1} lg={1}>
                <div className="textCenter">
                  <Icon type="calendar" />
                </div>
              </Col>
              <Col sm={8} xs={8} md={4} lg={4}>
                <div className="info-text textBold">Scheduled</div>
              </Col>
              <Col xs={1}>:</Col>
              <Col sm={11} xs={8} md={16} lg={16}>
                <div className="info-text">
                  <Tooltip placement="topLeft" title="Scheduled Start"><span>{scheduled_start_date_time_with_tz}</span> </Tooltip>&nbsp;-&nbsp;
                  <Tooltip placement="topLeft" title="Scheduled End"><span>{scheduled_end_date_time_with_tz}</span></Tooltip>
                </div>
              </Col>
              <Col sm={4} xs={4} md={2} lg={2} className="alignLeft">
                {stop.stop_tz_short_name}
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col sm={2} xs={2} md={1} lg={1}>
                <div className="textCenter">
                  <Icon type="calendar" />
                </div>
              </Col>
              <Col sm={8} xs={8} md={4} lg={4}>
                <div className="info-text textBold">Estimated</div>
              </Col>
              <Col xs={1}>:</Col>
              <Col sm={11} xs={8} md={16} lg={16}>
                <div className="info-text">
                  <Tooltip title="Estimated Arrival"><span>{estimated_arrival_date_time_with_tz}</span> </Tooltip>&nbsp;-&nbsp;
                  <Tooltip title="Estimated Departure"><span>{estimated_departure_date_time_with_tz}</span></Tooltip>
                </div>
              </Col>
              <Col sm={4} xs={4} md={2} lg={2} className="alignLeft">
                {stop.stop_tz_short_name}
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col sm={2} xs={2} md={1} lg={1}>
                <div className="textCenter">
                  <Icon type="calendar" />
                </div>
              </Col>
              <Col sm={8} xs={8} md={4} lg={4}>
                <div className="info-text textBold">Actual</div>
              </Col>
              <Col xs={1}>:</Col>
              <Col sm={11} xs={8} md={16} lg={16}>
                <div className="info-text">
                  <Tooltip title="Actual Arrival"><span>{actual_start_date_time_with_tz}</span> </Tooltip>&nbsp;-&nbsp;
                  <Tooltip title="Actual Departure"><span>{actual_end_date_time_with_tz}</span></Tooltip>
                </div>
              </Col>
              <Col sm={4} xs={4} md={2} lg={2} className="alignLeft">
                {stop.stop_tz_short_name}
              </Col>
            </Row>
          </Col>
          {false && <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col sm={2} xs={2} md={1} lg={1}>
                <div className="textCenter">
                  <Icon type="clock-circle" />
                </div>
              </Col>
              <Col sm={10} xs={13} md={6} lg={6}>
                <div className="info-text alignLeft textBold">Service Duration</div>
              </Col>
              <Col xs={1}>:</Col>
              <Col sm={11} xs={8} md={16} lg={16}>
                <div className="info-text">
                  <span>{service_duration}</span>
                </div>
              </Col>
            </Row>
          </Col>
  }
        </Row>


      </Col>
    </Row>


  );
};

function fomatString(str, format = '') {
  return doFormate(str, format);
}
export default StopContact;

StopContact.propTypes = {
  stop: PropTypes.shape().isRequired,
};

