import { Icon, Popconfirm, Tooltip } from "antd";
import PropTypes from "prop-types";
import React, {  Fragment } from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";

class RolesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "role_name",
        title: I18n.t("roles.role_name"),
        dataIndex: "role_name",
        // sorter: ({ role_name: a }, { role_name: b }) => a.localeCompare(b),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => <span>{data}</span>,
      },
      {
        key: "role_code",
        title: I18n.t("roles.role_code"),
        dataIndex: "role_code",
        // sorter: ({ role_code: a }, { role_code: b }) => a.localeCompare(b),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => <span className='textUpperCase'>{data}</span>,
      },
      {
        key: "items",
        fixed: "right",
        render: (data) => {
          const { handleDelete, handleEdit, handleConfig } = this.props;
          const { id } = data;
          return (
            <div className="line-actions">
              {!['driver', 'admin'].includes(data.role_code) && 
							<Fragment>
                {/* <Icon
                  type="setting"
                  onClick={() => handleConfig(id)}
                  className="paddinRight5"
                /> */}
                <Tooltip title={I18n.t("general.edit")}>
                  <span><EditIcon handleClick={() => handleEdit(id)}/></span>
                </Tooltip>
                {/* <Popconfirm
								placement="topRight"
								title={I18n.t("messages.delete_confirm")}
								okText="Yes"
								cancelText="No"
							> */}
              <Tooltip title={I18n.t("general.delete")}>
                  <span><DeleteIcon handleClick={() => handleDelete(id)}/></span>  
                </Tooltip>             
              </Fragment>
							}
              {/* </Popconfirm> */}
            </div>
          );
        },
        align: "center",
        width: 120,
      },
    ];
  }
}
export default RolesList;

RolesList.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleConfig: PropTypes.func,
  isLoading: PropTypes.bool,
};
RolesList.defaultProps = {
  data: [],
  pagination: {},
  handleDelete: () => {},
  handleEdit: () => {},
  handleConfig: () => {},
  isLoading: false,
};
