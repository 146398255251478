import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
export const MemoDescriptionApi = {
  fetchMemoDescription: (organizationId) => {
    let url = ApiUrl(`v2/memo_description?organization_id=${organizationId}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { memo_descriptions: "memo_desc_messages" },
    });
  },
  saveMemoDescription: (data) => {
    let url = ApiUrl(`v2/memo_description`)
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  updateMemoDescription: (id, data,organizationId) => {
    let url = ApiUrl(`v2/memo_description/${id}?organization_id=${organizationId}`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  deleteMemoDescription: (id,organizationId) => {
    let url = ApiUrl(`v2/memo_description/${id}?organization_id=${organizationId}`)
    return Call("delete", url,{}, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
};