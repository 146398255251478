import {
  Call,
  ApiUrl,
  optionalPageParams,
  ParseGeneralResponse,
} from "./ApiUtils";
import userStore from "../stores/UserStore";
import { isEmpty } from "../common/Common";

export function fetchScheduledOrderCount(page = 1, perPage = null) {
  const org_id = userStore.getStateValue("selectedOrg");
  const url = ApiUrl(
    `v2/order_count_notifier_jobs?organization_id=${org_id}&${optionalPageParams(
      page,
      perPage,
      false
    )}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { scheduled_orders_count: "scheduled_orders_count" },
  });
}

export function saveScheduledOrderCount(data) {
  const org_id = userStore.getStateValue("selectedOrg");
  const isNew = isEmpty(data.id);
  data.organization_id = org_id;
  const url = isNew
    ? ApiUrl("v2/order_count_notifier_jobs")
    : ApiUrl(`v2/order_count_notifier_jobs/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { scheduled_orders: "scheduled_orders_count" },
    },
    false
  );
}

export function deleteScheduledOrderCount(id) {
  const url = ApiUrl(`v2/order_count_notifier_jobs/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse, {
    responseDataKeys: { message: "Message" },
  });
}
