import _,{ isEmpty } from "lodash";
import moment from "moment";
import { getDisabledHours, getDisabledMinutes } from "../../helpers/date_functions";
import { checkboxColumn } from "../common/columnDefs";
import { alertMessage } from "../../common/Common";
import { renderAlertMessage } from "../../helpers/common";

export const fromTimeDisabledHours = (data, disabledStartHours) => {
  if (data.start_time || data.item_preference) {
    return getDisabledHours("start_time", disabledStartHours, data);
  }
  return [];
};

export const fromTimeDisabledMinutes = (data,
  selectedHour, endHour, endMinute, startHour, startMinute
) => {
  if (data.start_time || data.item_preference) {
    return getDisabledMinutes("start_time", selectedHour, data, endHour, endMinute, startHour, startMinute);
  }
  return [];
};
export const toTimeDisabledHours = (data, disabledEndHours) => {
  if (data.end_time || data.item_preference) {
    return getDisabledHours("end_time", disabledEndHours, data);
  }
  return [];
};

export const toTimeDisabledMinutes = (data,
  selectedHour, startHour, startMinute, endHour, endMinute
) => {
  if (data.end_time || data.item_preference) {
    return getDisabledMinutes("end_time", selectedHour, data, startHour, startMinute, endHour, endMinute);
  }
  return [];
};
export const getWhLocationSlotDetails = (data = [], whLocationCode = '') => {
  // checks for whlocation code and returns the slot details
  if(_.isEmpty(data)) return [];
  if(_.isEmpty(whLocationCode)) return [];
  const filteredWH = data.filter((wh) => wh.location_code === whLocationCode);
  const filteredWhFirstRecord = filteredWH && filteredWH.length ? filteredWH[0] : {};
  if (_.isEmpty(filteredWhFirstRecord.slot_details)) return [];
  return filteredWhFirstRecord.slot_details;
};

export const validateslots = (slotsData = [], isMilitaryTime = false, skipChecks="") => {
    const timeFormat = isMilitaryTime ? 'HH:mm' : 'h:mm A';
    const { errors, slots } = slotsData.reduce(
      (acc, appointment) => {
        // Destructure the appointment object
        const {
          slots,
          appt_date,
          has_expedite,
          start_time,
          end_time,
        } = appointment;

        // Check any option is selected or not
        if(!isEmpty(appt_date) || (slots && slots.length > 0) || !isEmpty(start_time) || !isEmpty(end_time)) {
          // Check if appt_date is valid date
          if (!skipChecks.includes('appt_date')) {
            if (!appt_date) { acc.errors.push('Please select valid date'); return acc; }
            const formattedPreference = moment(appt_date).format(
              'YYYY-MM-DD 00:00:00'
            );
            if (!formattedPreference) {
              acc.errors.push('Please select valid date');
              return acc;
            }
            if(!slots || slots.length === 0){
              acc.errors.push('Please select valid slot');
              return acc;
            }
          }

          const formattedPreference = moment(appt_date).format(
            'YYYY-MM-DD 00:00:00'
          );
          // Check if slots has a valid CUSTOM slot with valid start and end times
          if (slots.includes('CUSTOM')) {
            if (!start_time || !end_time) {
              acc.errors.push('Please select the valid start and end time');
              return acc;
            }

            const formattedStart = moment(
              start_time,
              timeFormat
            ).format('HH:mm');
            const formattedEnd = moment(
              end_time,
              timeFormat
            ).format('HH:mm');
            if (!formattedStart || !formattedEnd || !moment(formattedStart, timeFormat).isValid() || !moment(formattedEnd, timeFormat).isValid()
              || moment(formattedStart).isSameOrAfter(formattedEnd)) {
              acc.errors.push('Please fill valid time slots');
              return acc;
            }

            acc.slots.push({
              ...appointment,
              start_time: formattedStart,
              end_time: formattedEnd,
              appt_date: formattedPreference,
              has_expedite: has_expedite || false,
            });
          } else {
            const formattedStart = moment(
              start_time,
              timeFormat
            ).format('HH:mm');
            const formattedEnd = moment(
              end_time,
              timeFormat
            ).format('HH:mm');

            acc.slots.push({
              ...appointment,
              start_time: formattedStart,
              end_time: formattedEnd,
              appt_date: formattedPreference,
              has_expedite: has_expedite || false,
            });
          }
       }
        return acc;
      },
      { errors: [], slots: [] }
    );
    return { errors, appointments: slots };
  };



export const checkIfOrdershasExceededCreditLimit = (ordersData, creditsExceededAccnts) => {
    const accountCodesWithExceededLimit = creditsExceededAccnts.map(account => account.account_code);
    return ordersData.map((order) => {
      const isLimitExceeded = accountCodesWithExceededLimit.includes(order.account_code);
      return {
        ...order,
        account_limit_exceeded: isLimitExceeded
      };
    }
    );
  };


    // function which takes in the schedule_day_filter and returns the from and to date. 
    export const getFromToDate = (schedule_day_filter , customToDate , customFromDate) => {
      const lastMonthStart = moment().subtract(1, 'month').startOf('month');
      const lastMonthEnd = moment().subtract(1, 'month').endOf('month');
      const lastWeekStart = moment().subtract(1, 'week').startOf('week');
      const lastWeekEnd = moment().subtract(1, 'week').endOf('week');
      const yesterday = moment().subtract(1, 'day');
      const today = moment().startOf('day');
      const tomorrow = moment(today).add(1, 'day');
      const currentWeekStart = moment().startOf('week');
      const currentWeekEnd = moment().endOf('week');
      const currentMonthStart = moment().startOf('month');
      const currentMonthEnd = moment().endOf('month');
      const next3Days = moment().add(3, 'day');
      const next7Days = moment().add(7, 'day');

      switch (schedule_day_filter) {
        case 'Last Month':
          return { fromDate: lastMonthStart, toDate: lastMonthEnd };
        case 'Last Week':
          return { fromDate: lastWeekStart, toDate: lastWeekEnd };
        case 'Yesterday':
          return { fromDate: yesterday, toDate: yesterday };
        case 'Today':
          return { fromDate: today, toDate: today };
        case 'Tomorrow':
          return { fromDate: tomorrow, toDate: tomorrow };
        case 'Today & Tomorrow':
          return { fromDate: today, toDate: tomorrow };
        case 'Current Week':
          return { fromDate: currentWeekStart, toDate: currentWeekEnd };
        case 'Next 3 Days':
          return { fromDate: today, toDate: next3Days };
        case 'Next 7 Days':
          return { fromDate: today, toDate: next7Days };
        case 'Current Month':
          return { fromDate: currentMonthStart, toDate: currentMonthEnd };
        case 'Custom':
          const fromDate = moment(customFromDate, "YYYY-MM-DD HH:mm:ss Z");
          const toDate = moment(customToDate, "YYYY-MM-DD HH:mm:ss Z");
          return { fromDate, toDate };
        default:
          return { fromDate: today, toDate: today };
      }
    }

export const getTimeFrameTitle = (fromDate, toDate) => {
  const today = moment().startOf('day');
  const isBetween = (date, start, end) => date.isBetween(start, end, null, '[]');
  const isSameDay = (date1, date2) => date1.isSame(date2, 'day');

  const timeFrames = {
    'Today': () => isSameDay(fromDate, today) && isSameDay(toDate, today),
    'Tomorrow': () => isSameDay(fromDate, today.clone().add(1, 'day')) && isSameDay(toDate, today.clone().add(1, 'day')),
    'Yesterday': () => isSameDay(fromDate, today.clone().subtract(1, 'day')) && isSameDay(toDate, today.clone().subtract(1, 'day')),
    'Today & Tomorrow': () => isSameDay(fromDate, today) && isSameDay(toDate, today.clone().add(1, 'day')),
    'Next 3 Days': () => isSameDay(fromDate, today) && isSameDay(toDate, today.clone().add(2, 'day')),
    'Next 7 Days': () => isSameDay(fromDate, today) && isSameDay(toDate, today.clone().add(6, 'day')),
    'Last Month': () => isBetween(fromDate, today.clone().subtract(1, 'month').startOf('month'), today.clone().subtract(1, 'month').endOf('month')) &&
      isBetween(toDate, today.clone().subtract(1, 'month').startOf('month'), today.clone().subtract(1, 'month').endOf('month')),
    'Last Week': () => isBetween(fromDate, today.clone().subtract(1, 'week').startOf('week'), today.clone().subtract(1, 'week').endOf('week')) &&
      isBetween(toDate, today.clone().subtract(1, 'week').startOf('week'), today.clone().subtract(1, 'week').endOf('week')),
    'Current Month': () => moment().isSame(fromDate, 'month') && moment().isSame(toDate, 'month'),
    'Current Week': () => moment().isSame(fromDate, 'week') && moment().isSame(toDate, 'week'),
  };

  for (let [ title, condition ] of Object.entries(timeFrames)) {
    if (condition()) return title;
  }

  return 'Custom';
};


  export const getStoredColumns = (currentUser, tableName) => {
    const savedWidths = _.get(currentUser, `column_widths.${tableName}`, {});
    return Object.keys(savedWidths).map((key) => {
      return {
        key,
        width: savedWidths[ key ],
      };
    });
  };
  
  export const getConfiguredColumns = (
    currentCols,
    storedColumns,
    userConfiguration,
    orgConfiguration,
    tableName
  ) => {
    const columns = [];
    const leftFixedColumns = [];
    const rightFixedColumns = [];
    const savedGridList =
      userConfiguration[tableName] || orgConfiguration[tableName];

    if (typeof savedGridList !== "undefined" && Array.isArray(savedGridList)) {
      savedGridList.forEach((column) => {
        const foundColumn = currentCols.find((col) => col.field === column);
        if (foundColumn) {
          const storedWidth = storedColumns.find((col) => col.key === column);
          columns.push({
            ...foundColumn,
            width: storedWidth ? storedWidth.width : foundColumn.width,
          });
        }
      });
      // columns.push(checkboxColumn);
      // if (!actionsColumnIncluded) {
      //   const actionsColumn = currentCols.find((col) => col.field === "id");
      //   if (actionsColumn) {
      //       columns.push(actionsColumn);
      //   }
      // }
    } else {
      columns.push(...currentCols);
    }

    if (!columns.some((col) => col.pinned === "left")) {
      const checkBoxIncluded = currentCols.filter(
        (col) => col.pinned === "left"
      );
      if (checkBoxIncluded.length) {
        leftFixedColumns.push(...checkBoxIncluded);
      }
    }
    if (!columns.some((col) => col.pinned === "right")) {
      const actionsColumnIncluded = currentCols.filter(
        (col) => col.pinned === "right"
      );
      if (actionsColumnIncluded.length) {
        rightFixedColumns.push(...actionsColumnIncluded);
      }
    }
    return [...leftFixedColumns, ...columns, ...rightFixedColumns];
  };


export function magicMinutes(minutes) {
  if (minutes < 0) {
    return "Invalid input: Please provide a non-negative number of minutes.";
  }

  let duration = moment.duration(minutes, 'minutes');
  let result = [];

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths() % 12);
  const weeks = Math.floor(duration.asWeeks() % 4.345);
  const days = Math.floor(duration.asDays() % 7);
  const hours = Math.floor(duration.asHours() % 24);
  const minutesLeft = Math.floor(duration.asMinutes() % 60);

  if (years > 0) {
    result.push(`${years} year${years > 1 ? 's' : ''}`);
  }
  if (months > 0) {
    result.push(`${months} month${months > 1 ? 's' : ''}`);
  }
  if (weeks > 0) {
    result.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
  }
  if (days > 0) {
    result.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    result.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (minutesLeft > 0) {
    result.push(`${minutesLeft} minute${minutesLeft > 1 ? 's' : ''}`);
  }

  return result.join(' ');
} 

export const validateBulkActions = (
  records = [],
  action = "",
  cb = null,
  errorCallback = null,
  isIncludes = true,
) => {
  const checkValidation = (allowedStatuses) => {
    return isIncludes ? 
    records.filter((rec) => !allowedStatuses.includes(rec.status)).map(rec => rec.customer_order_number) : 
    records.filter((rec) => allowedStatuses.includes(rec.status)).map(rec => rec.customer_order_number)
  }
  if(!isEmpty(action)){
    let result = []
    switch(action){
      case 'SCHEDULE': result =  checkValidation(["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"]); break;
      case 'MOVE_TO_RECEIVED':  result = checkValidation(["NEW"]); break;
      case 'QUICK_DISPATCH':  result = checkValidation(["NEW", "RECEIVED", "VERIFIED"]); break;
      case 'MOVE_TO_VERIFY':  result = checkValidation(["RECEIVED"]); break;
      case 'CHANGE_STATUS':  result = checkValidation(["PENDING", "REJECTED"]); break;
      case 'PRINT_LABELS':  result = checkValidation(["PENDING", "REJECTED"]); break;
      case 'CLONE_ORDERS':  result = checkValidation(["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"]); break;
      case 'LINEHAUL':  result = checkValidation(["NEW", "RECEIVED", "VERIFIED"]); break;
      case 'SWITCH_ORDERS':  result = checkValidation(["NEW", "RECEIVED", "VERIFIED"]); break;
      case 'BULK_DELETE':  result = checkValidation([
        "NEW",
        "RECEIVED",
        "VERIFIED",
        "PENDING",
        "APPROVED",
        "REJECTED",
        "ON_HOLD",
      ]) ; break;
      default:  break
    }
    if(result.length === 0){
      cb();
    } else {
      if(errorCallback){
        errorCallback(result)
      } else {
        renderAlertMessage(`${result.join(',')} are not allowed`)
      }
    }
  }
  
};