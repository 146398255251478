import React from 'react';
import BaseList from '../BaseList';
import { compareString, isEmpty, ellipseText } from '../../common/Common';
import I18n from '../../common/I18n';
import { Col, Icon, Popconfirm, Popover, Row, Tooltip } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { checkServiceExistance } from '../../helpers/common';
import SettingIcon from '../common/SettingIcon';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';

class List extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    const columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
        className: "noWrap",
        render: (text, data) => (
          <Tooltip title={data.children ? `${text} (${data.children.length})` : text} placement="topLeft">
            {data.children ? `${text} (${data.children.length})` : text}
          </Tooltip>
        ),
      
        // sorter: (a, b) => compareString(a.name, b.name),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: 'code',
        title: I18n.t('general.code'),
        dataIndex: 'code',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: 'phone_number',
        title: I18n.t('general.mobile_number'),
        dataIndex: 'phone_number',
      },
      {
        key: 'email',
        title: I18n.t('general.email'),
        dataIndex: 'email',
      },
      {
        key: 'contact_person',
        title: I18n.t('account.contact_person'),
        dataIndex: 'contact_person',
        render: data => (
          <Tooltip title={data}>{ellipseText(data, 25)}</Tooltip>
        ),
      },
      {
        key: 'address',
        title: I18n.t('general.address'),
        render: data =>
          // const address = _.values(data.address).splice(1, 6).join();
          (
            data.address &&
            <Popover
              content={
                <div className='table-contact-info'>
                  <AddressInfo address={data.address} />
                </div>
              }
              title={I18n.t('general.address')}
              overlayStyle={{maxWidth: 500}}
              placement="topRight"
            >
              <span> <Icon type="environment" /> </span>
            </Popover>
          ),
        // width: 300,
        align: 'center',
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        fixed: 'right',
        render: (id, data) => (
          <div className='line-actions' style={{
            width: '100%',
          }} >
            <Row type="flex" align="middle">

              <Col >
                <Tooltip title={ I18n.t("tooltip.setting") } placement="left">
                  { checkServiceExistance([ "ACNF", "DZI", "BZI", "AAI", "LOSI", "AADI", "BLCONF", "GSIP", "ACNF", "DIMI", "ADRI", "AINSI" ], 'ANY') && isEmpty(data.primary_account_id) ? (
                    <SettingIcon handleClick={ () => this.props.gotoAccountSettings(id) } />
                  ) : null }
                  &nbsp;
                </Tooltip>
              </Col>

              <Col >
                { checkServiceExistance('ABOLG') && (
                  <Tooltip
                    title={ I18n.t("BillOfRating.bill_of_rating_title") }
                    placement="left"
                  >
                    <Icon
                      type='file-done'
                      className="billOfLadingIcon"
                      onClick={ () => this.props.generateBol(id) }
                    />
                  </Tooltip>
                ) }
              </Col>

              <Col >
                { checkServiceExistance('AU') && (
                  <Tooltip title={ I18n.t("general.edit") }>
                    <span><EditIcon handleClick={ () => this.props.editClick(id) } /></span>
                  </Tooltip>
                ) }
              </Col>

              <Col >
                { checkServiceExistance('AD') && (
                  <Tooltip title={ I18n.t("general.delete") }>
                    <Popconfirm
                      placement="left"
                      title={ I18n.t('messages.delete_confirm') }
                      onConfirm={ () => this.props.deleteClick(id) }
                      okText="Yes"
                      cancelText="No"
                    >
                      <span><DeleteIcon /></span>
                    </Popconfirm>
                  </Tooltip>
                ) }
              </Col>
              <Col >
              { props.accountCodesWithExceededLimit.includes(data.code) && (
                  <Tooltip
                    title={ I18n.t("order.account_limit_exceeded") }
                    placement="left"
                    key="accountLimitExceeded"
                  >
                    <Icon type="info-circle" style={ { color: "red" } } />
                  </Tooltip>
              ) }
                </Col>
            </Row>
          </div >

        ),
        // width: '200px',
      },
    ];
    this.columns = columns.map((column) => ({
      ...column,
      width: this.getMaxWidth(column.dataIndex),
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };

  getMaxWidth = (title) => {
    const maxWidth = 400; // Set a maximum width to avoid column being too wide
    const minWidth = 120; // Set a minimum width to avoid column being too narrow
    const padding = 24; // Set the padding to match the table's default padding
    const columnData = title !== "id" ? this.props.data.map((row) => row[title]): [" Action Icons "];
    let maxLength = Math.max(
      ...columnData.map((text) => text && text.toString().length)
    );

    if (title === "contact_person") {
      if (maxLength > 25) {
        maxLength = 25;
      }
    }
    const width = Math.min(
      maxWidth,
      Math.max(minWidth, maxLength * 8 + padding)
    ); // Set a width based on the maximum length of the characters
    return width;
  };
}

export default List;
