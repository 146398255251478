/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _, { result } from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  DatePicker,
  Select,
  Card,
  Icon,
  Spin,
  Tabs,
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  formatCode,
  findNumberTotal,
  refreshIcon,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../common/FormErrors";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { BatchPaymentApi } from "../../api/BatchPaymentApi";
import UnpaidInvoiceLines from "./UnpaiedLineInvoices";
import userStore from "../../stores/UserStore";
import BulkOperations from "./BulkOperations";
import PaidPayments from "./PaidPayments";
import moment from "moment";
import { renderAlertMessage } from "../../helpers/common";

const { TextArea, Search } = Input;
const { TabPane } = Tabs;

const requiredFields = [
  {
    form_field: "account_id",
    ui_name: I18n.t("general.account"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "check_date",
    ui_name: I18n.t("batch_payments.check_date"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "check_number",
    ui_name: I18n.t("batch_payments.check_number"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "check_total_amount",
    ui_name: I18n.t("batch_payments.check_total_amount"),
    isRequired: true,
    inputType: "price",
  },
  {
    form_field: "memo",
    ui_name: I18n.t("batch_payments.memo"),
    isRequired: false,
    inputType: "text",
  },
];

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batch: this.props.batch ? this.props.batch : {},
      currentPayment: this.props.currentPayment
        ? { ...this.props.currentPayment }
        : {},
      isNew: this.props.isNew,
      orgId: this.props.org_id,
      inProgress: false,
      errors: [],
      accounts: this.props.accounts ? this.props.accounts : [],
      batch_account: this.props.batch_payment ? this.props.batch_payment : {},
      unpaidInvoiceLines: [],
      totalLines: [],
      selectedInfo: {
        keys: [],
        rows: [],
      },
      lineProgress: false,
      activeKey: this.props.showActions ? "unpaid" : "paid",
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    };
  }

  componentDidMount() {
    if (this.state.currentPayment?.id) {
      this.setInitialData();
    }
    if (this.state.currentPayment.account_id) {
      this.getUnpaidLines();
    }
  }

  setInitialData = (currentPayment = null) => {
    if (isEmpty(currentPayment)) {
      currentPayment = _.cloneDeep(this.state.currentPayment);
    }
    currentPayment.check_date = currentPayment?.check_date
      ? moment(this.state.currentPayment.check_date)
      : null;
    (currentPayment.applied_total = Number(
      currentPayment.applied_amount
    ).toFixed(this.state.decimalPoints)),
      (currentPayment.unapplied_total = Number(
        currentPayment.unapplied_amount
      ).toFixed(this.state.decimalPoints)),
      this.setState({
        currentPayment,
      });
  };

  handleOnChange = (element, value) => {
    const currentPayment = Object.assign({}, this.state.currentPayment);
    currentPayment[element] = value;
    this.setState(
      {
        currentPayment,
      },
      () => {
        if (element === "account_id") {
          this.getUnpaidLines();
        }
      }
    );
  };

  getUnpaidLines = () => {
    this.setState({ lineProgress: true });
    BatchPaymentApi.unpaidInvoiceLines(
      this.state.orgId,
      this.state.currentPayment.account_id
    ).then((result) => {
      if (result.success) {
        const lines = result.lines || [];
        this.setState({
          totalLines: [...lines],
          unpaidInvoiceLines: [...lines],
          lineProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ lineProgress: false });
      }
    });
  };

  handleSave = () => {
    let data = Object.assign({}, this.state.currentPayment);
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      data.organization_id = this.state.orgId;
      data.check_date = moment(data.check_date).format("YYYY-MM-DD");
      data.batch_payment_id = this.state.batch.id;
      data.current_user_id = userStore.getStateValue("id");
      let func = () => {}
      if (data.id) {
        data.batch_account_payment_id = data.id;
        func = () => BatchPaymentApi.updateBatchPayment(data);
      } else {
        func = () => BatchPaymentApi.createBatchPayment(data);
      }
      this.setState({ inProgress: true });
      func().then((result) => {
        if (result.success) {
          const batchAccountPayment = result.batch_account_payment
            ? result.batch_account_payment
            : {};
          alertMessage(I18n.t("messages.saved"));
          this.setState({
            errors: [],
            isNew: false,
            inProgress: false,
            currentPayment: {
              ...batchAccountPayment,
              check_date: moment(batchAccountPayment.check_date),
            },
          });
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  applyAmount = () => {
    const errors = [];
    const { currentPayment, batch } = this.state;
    if (errors.length === 0) {
      const modifiedLines = this.state.unpaidInvoiceLines
        .filter((line) => line.is_modified === true)
        .map((line) => ({
          account_invoice_id: line.account_invoice_id,
          organization_id: line.organization_id,
          account_id: line.account_id,
          customer_order_id: line.customer_order_id,
          pay_amount: line.paid_amount,
          batch_account_payment_id: currentPayment?.id ? currentPayment.id : "",
          batch_payment_id: batch?.id ? batch.id : "",
        }));
      const data = {
        applied_invoice_line_amounts: modifiedLines,
      };
      this.setState({ lineProgress: true });
      BatchPaymentApi.applyBatchAccount(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setInitialData(result.batch_account_payment);
          this.setState({
            errors: [],
            isNew: false,
            lineProgress: false,
            showForm: false,
            selectedInfo: {
              rows: [],
              keys: [],
            },
            updatedTime: moment(),
          });
          this.getUnpaidLines();
        } else {
          this.setState({ errors: result.errors, lineProgress: false });
        }
      });
    } else {
      this.setState({ errors, lineProgress: false });
    }
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    const oldKeys = this.state.selectedInfo.keys;
    const removedKeys = oldKeys.filter((key) => !selectedRowKeys.includes(key));
    const addedKeys = selectedRowKeys.filter((key) => !oldKeys.includes(key));

    this.setState(
      {
        selectedInfo: Object.assign({}, this.state.selectedInfo, {
          keys: selectedRowKeys,
          rows: selectedRows,
        }),
      },
      () => {
        this.setPaidData(addedKeys, removedKeys);
      }
    );
  };

  setPaidData = (addedKeys, removedKeys) => {
    const unpaidInvoiceLines = _.cloneDeep(this.state.unpaidInvoiceLines);
    removedKeys.forEach((key) => {
      const index = _.findIndex(unpaidInvoiceLines, { id: key });
      if (index >= 0) {
        unpaidInvoiceLines[index].paid_amount = 0;
        unpaidInvoiceLines[index].is_modified = false;
      }
    });
    addedKeys.forEach((key) => {
      const index = _.findIndex(unpaidInvoiceLines, { id: key });
      if (index >= 0) {
        unpaidInvoiceLines[index].paid_amount = parseFloat(
          unpaidInvoiceLines[index].line_balance_amount
        );
        unpaidInvoiceLines[index].is_modified = true;
      }
    });
    this.setState(
      {
        unpaidInvoiceLines,
      },
      () => {
        this.updatePaymentSummary();
      }
    );
  };

  updatePaymentSummary = () => {
    const unpaidInvoiceLines = this.state.unpaidInvoiceLines.filter(
      (rec) => rec.is_modified
    );
    let sum = findNumberTotal(unpaidInvoiceLines, "paid_amount", null, this.state.decimalPoints);
    sum = Number(sum) + Number(this.state.currentPayment.applied_amount);
    sum = sum.toFixed(this.state.decimalPoints);
    this.setState({
      currentPayment: {
        ...this.state.currentPayment,
        applied_total: sum, // _.sumBy(unpaidInvoiceLines, "paid_amount"),
        unapplied_total: (
          Number(this.state.currentPayment.check_total_amount) - sum
        ).toFixed(this.state.decimalPoints),
      },
    });
  };

  handlePaymentChange = (index, element, value) => {
    const unpaidInvoiceLines = _.cloneDeep(this.state.unpaidInvoiceLines);
    unpaidInvoiceLines[index][element] = value;
    this.setState(
      {
        unpaidInvoiceLines,
      },
      () => {
        this.updatePaymentSummary();
      }
    );
  };

  onSearchNumbers = (search_order_token) => {
    const { totalLines, unpaidInvoiceLines } = this.state;
    const searchToken = !isEmpty(search_order_token) ? search_order_token : "";
    const tokenRemovedToken = _.replace(searchToken, " ", "");
    const tokens = _.split(tokenRemovedToken, ",");
    const lines = totalLines.filter((order) =>
      tokens.some(
        (token) =>
          (order.customer_order_number &&
            order.customer_order_number.indexOf(token) >= 0) ||
          (order.hawb && order.hawb.indexOf(token) >= 0) ||
          (order.mawb && order.mawb.indexOf(token) >= 0) ||
          (order.invoice_number && order.invoice_number.indexOf(token) >= 0)
      )
    );
    this.setState({
      unpaidInvoiceLines: [...lines],
    });
  };

  renderPayments = () => {
    const selectedRowKeys = this.state.selectedInfo.keys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.line_balance_amount == 0, // Column configuration not to be checked
      }),
    };
    return (
      <Spin spinning={this.state.lineProgress}>
        <div className="minimizdTable minimizedTable">
          {/* <Search
              placeholder={
                'Search'
              }
              onSearch={(value) => this.onSearchNumbers(value)}
              style={{ width: 200 }}
              size="small"
              allowClear
            /> */}
          {/* <div className="alignRight">
              <span className="cursorPointer" onClick={() => this.getUnpaidLines()}>
              {refreshIcon(() => {})}
              &nbsp;Refresh
              </span>
           </div> */}
          <UnpaidInvoiceLines
            data={this.state.unpaidInvoiceLines}
            applyAmount={this.applyAmount}
            pagination={{ position: "none" }}
            rowSelection={rowSelection}
            handlePaymentChange={this.handlePaymentChange}
            selectedKeys={[...selectedRowKeys]}
            scroll={{ y: 400 }}
            showInput={(rec) => {
              this.handleSelectChange([...selectedRowKeys, rec.id], [...this.state.selectedInfo.rows, {...rec}])
            }}
          />
          {this.state.selectedInfo.keys.length > 0 && (
            <BulkOperations
              selectedInfo={this.state.selectedInfo}
              loading={this.state.loading}
              applyAmount={this.applyAmount}
              handleCancel={() => this.handleSelectChange([], [])}
            />
          )}
        </div>
      </Spin>
    );
  };

  goToTab = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  refresTheList = (rec) => {
    this.setInitialData(rec);
    this.getUnpaidLines();
  };

  refreshTabs = () => {
    if (this.state.activeKey === "paid") {
      this.setState({
        updatedTime: moment(),
      });
    } else {
      this.getUnpaidLines();
    }
  };

  renderPaymentData = () => {
    const { currentPayment = {} } = this.state;
    return (
      <Tabs
        activeKey={this.state.activeKey}
        onChange={this.goToTab}
        type="card"
        size="small"
        tabBarExtraContent={
          <div>
          <Button className="buttonGreen" onClick={() => this.refreshTabs()} icon="reload" size="small" type="primary">
            {/* {refreshIcon(() => {})} */}
            Refresh
          </Button>
          </div>
        }
      >
        {this.props.showActions && (
          <TabPane tab={I18n.t("batch_payments.unpaid_lines")} key="unpaid">
            {this.renderPayments()}
          </TabPane>
        )}
        <TabPane tab={I18n.t("batch_payments.paid_lines")} key="paid">
          <PaidPayments
            currentPayment={currentPayment}
            showActions={this.props.showActions}
            refresTheList={this.refresTheList}
            updatedTime={this.state.updatedTime}
          />
        </TabPane>
      </Tabs>
    );
  };

  renderForm = () => {
    const { currentPayment = {}, batch = {} } = this.state;
    const expected_total = batch.expected_total_amount
      ? Number(batch.expected_total_amount)
      : 0;
    const isExceeds = !isEmpty(currentPayment.check_total_amount)
      ? currentPayment.check_total_amount > expected_total
      : false;
    const showActions = this.props.showActions;
    return (
      <Row>
        <Col xs={18}>
          <Row gutter={16}>
            <Col md={10}>
              <FormItem label={I18n.t("general.account")} require>
                <Select
                  value={currentPayment.account_id || ""}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size="small"
                  onChange={(e) => this.handleOnChange("account_id", e)}
                  disabled={!showActions || !isEmpty(currentPayment.id)}
                >
                  <Select.Option key="account" value="">
                    -- Select --
                  </Select.Option>
                  {this.state.accounts.map((account) => (
                    <Select.Option key={account.id} value={account.id}>
                      {`${account.name} (${account.code})`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col md={6}>
              <FormItem
                label={
                  <Fragment>
                    <span>{I18n.t("batch_payments.check_date")}</span>
                  </Fragment>
                }
                require
              >
                <DatePicker
                  format="DD MMM, YYYY"
                  size={"small"}
                  value={
                    currentPayment.check_date ? currentPayment.check_date : null
                  }
                  onChange={(e) => this.handleOnChange("check_date", e)}
                  disabled={!showActions}
                />
              </FormItem>
            </Col>
            <Col md={6}>
              <FormItem
                label={
                  <Fragment>
                    <span>{I18n.t("batch_payments.check_number")}</span>
                  </Fragment>
                }
                require
              >
                <Input
                  value={currentPayment.check_number}
                  onChange={(e) =>
                    this.handleOnChange("check_number", e.target.value)
                  }
                  className="textUpperCase"
                  size="small"
                  disabled={!showActions}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={22}>
              <FormItem label={I18n.t("batch_payments.memo")}>
                <TextArea
                  autoSize={true}
                  rows={1}
                  placeholder={I18n.t("batch_payments.memo")}
                  className="sop-form-textArea"
                  value={currentPayment.memo}
                  onChange={(e) => this.handleOnChange("memo", e.target.value)}
                  disabled={!showActions}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col>
              <Row gutter={12}>
                <Col xs={10} className="textBold">
                  {I18n.t("batch_payments.check_total_amount")}
                  <sup className="textREd">*</sup>
                </Col>
                <Col xs={14}>
                  <Input
                    value={currentPayment.check_total_amount}
                    type="number"
                    onChange={(e) =>
                      this.handleOnChange("check_total_amount", e.target.value)
                    }
                    className={`textUpperCase ${
                      isExceeds ? "errorBorder" : ""
                    }`}
                    size="small"
                    disabled={!showActions}
                  />

                  {isExceeds && (
                    <div className="fontSize10 textRed">
                      {"Exceeds with Bal."}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col className="marginTop10">
              <Row gutter={12}>
                <Col xs={10} className="textBold">
                  {I18n.t("batch_payments.applied_total")}
                </Col>
                <Col xs={14}>
                  <Input
                    value={currentPayment.applied_total}
                    type="number"
                    onChange={(e) =>
                      this.handleOnChange("applied_total", e.target.value)
                    }
                    className={`textUpperCase ${currentPayment.unapplied_total < 0 ? 'errorBorder' : ''}`}
                    size="small"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
            <Col className="marginTop10">
              <Row gutter={12}>
                <Col xs={10} className="textBold">
                  {I18n.t("batch_payments.unapplied_total")}
                </Col>
                <Col xs={14}>
                  <Input
                    value={currentPayment.unapplied_total}
                    type="number"
                    onChange={(e) =>
                      this.handleOnChange("unapplied_total", e.target.value)
                    }
                    className={`textUpperCase ${currentPayment.unapplied_total < 0 ? 'errorBorder' : ''}`}
                    size="small"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {showActions && (
            <Row>
              <Col className="alignCenter marginTop10">
                <Button
                  size={"small"}
                  type="danger"
                  disabled={this.state.inProgress}
                  onClick={this.props.onCancel}
                >
                  {I18n.t("general.cancel")}
                </Button>
                &nbsp;
                <Button
                  size={"small"}
                  type="primary"
                  disabled={this.state.inProgress}
                  onClick={this.handleSave}
                >
                  {I18n.t("general.save")}
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { currentPayment = {}, batch = {} } = this.state;
    return (
      <div>
        <Spin spinning={this.state.inProgress}>{this.renderForm()}</Spin>
        {currentPayment.id && this.renderPaymentData()}

        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

Payments.propTypes = {
  batch: PropTypes.shape().isRequired,
  currentPayment: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const PaymentsForm = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const organizationSettings = useContext(OrgContext);
  return (
    <Payments
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default PaymentsForm;
