/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Spin,
  Select,
  Button,
  Row,
  Col,
  FormItem,
  Avatar,
  Badge,
  Popconfirm,
} from "../../common/UIComponents";
import { isEmpty, alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import BaseModal from "../BaseModal";
import { swapDrivers } from "../../api/PreplanApi";
import FormErrors from "../common/FormErrors";

const { Option } = Select;
class SwapDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      source1Error: [],
      source2Error: [],
      assignStatus: false,
      showForm: false,
      driversInfo: {
        source1: "",
        source2: "",
      },
    };
  }

  handleDriverChange = (element, value) => {
    let { source2, source1 } = this.state.driversInfo;
    const { routes, currentWarehouse } = this.props;
    const wh_name = currentWarehouse.name ;
    if (element === "source1") {
      source1 = value;
      if (source2 === value) {
        source2 = "";
        this.setState({ source2Error: [] });
      }
      const source1_index = _.findIndex(routes, [ "route_id",value]);
      const source1_route =
        source1_index >= 0 ? Object.assign({}, routes[source1_index]) : {};
      
        if (!isEmpty(value) && source1_route.warehouse_id !== currentWarehouse.id) {
          this.setState({
            source1Error: [
              `${I18n.t("messages.route_from_another_wh")} ${wh_name}!`
            ],
          });
        } else {
          this.setState({
            source1Error: [],
          });
        }
      
    } else if (element === "source2") {
      source2 = value;
      if (source1 === value) {
        source1 = "";
        this.setState({ source1Error: [] });
      }
      const source2_index = _.findIndex(routes, [ "route_id",value]);
      const source2_route =
        source2_index >= 0 ? Object.assign({}, routes[source2_index]) : {};
        if (!isEmpty(value) && source2_route.warehouse_id !== currentWarehouse.id) {
          this.setState({
            source2Error: [
              `${I18n.t("messages.route_from_another_wh")} ${wh_name}!`,
            ],
          });
        } else {
          this.setState({
            source2Error: [],
          });
        }
      
    }
    this.setState({
      driversInfo: Object.assign({}, this.state.driversInfo, {
        source1,
        source2,
      }),
    });
  };

  // handleError = () => {
  //   const { driversInfo } = this.state;
  //   const { routes, currentWarehouse } = this.props;
  //   const source1_index = _.findIndex(routes, [
  //     "route_id",
  //     driversInfo.source1,
  //   ]);
  //   const source1_route =
  //     source1_index >= 0 ? Object.assign({}, routes[source1_index]) : {};
  //   const source2_index = _.findIndex(routes, [
  //     "route_id",
  //     driversInfo.source2,
  //   ]);
  //   const source2_route =
  //     source2_index >= 0 ? Object.assign({}, routes[source2_index]) : {};   
  //   if ((source1_route.warehouse_id === currentWarehouse.id) && (source2_route.warehouse_id === currentWarehouse.id)) {
  //     this.handleSwapping();
  //   } else {
  //     this.setState({
  //       errors: ["We cant swap as routes are from another warehouse"],
  //     });
  //   }
  // };

  handleSwapping = () => {
    const { driversInfo } = this.state;
    const nav_route_ids = [];
    this.setState({
      assignStatus: true,
    });
    if (!isEmpty(driversInfo.source1) && !isEmpty(driversInfo.source2)) {
      nav_route_ids.push(driversInfo.source1);
      nav_route_ids.push(driversInfo.source2);
      swapDrivers({ nav_route_ids }).then((result) => {
        if (result.success) {
          this.setState({
            assignStatus: false,
          });
          alertMessage(
            result.message
              ? result.message
              : I18n.t("messages.swapped")
          );
          this.onClose(true);
        } else {
          alertMessage(result.error, "error", 10);
        }
      });
    }
  };

  renderSelect = (element) => {
    const { driversInfo } = this.state;
    const usersData = this.props.users || [];
    const users = usersData.filter((user) => !isEmpty(user.route_id) && user.stops && user.stops.length > 0);
    return (
      <Select
        style={{ width: "100%" }}
        className="routes-input"
        onChange={(e) => this.handleDriverChange(element, e)}
        value={driversInfo[element]}
        filterOption={(input, option, index) => {
         return option.props.children.props.children[2]
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        } 
        }
        showSearch
        allowClear
        key={element}
      >
        {users.map((province) => (
          <Option key={province.id} value={province.route_id}>
            <div>
              {province.image && province.image.url ? (
                <Avatar src={`${province.image.url}`} size={30} />
              ) : (
                <Avatar
                  src="./../icons/user_100*100.png"
                  alt="DriverProfile"
                  size={30}
                />
              )}
              &nbsp;
              {province.employee_code
                ? province.employee_code
                : province.full_name
                ? province.full_name
                : `${province.first_name} ${province.last_name}`}
              &nbsp;&nbsp;
              <span className="floatRight">
                <Badge count={province.stops.length > 2 ? province.stops.length - 2 : 0} />
              </span>
            </div>
          </Option>
        ))}
      </Select>
    );
  };

  renderSwapForm = () => {
    this.setState({
      showForm: true,
      driversInfo: {
        source1: '',
        source2: '',
      },
    });
  };

  onClose = (result) => {
    this.setState({
      errors: [],
      source1Error: [],
      source2Error: [],
      showForm: false,
      driversInfo: {
        source1: '',
        source2: '',
      }
    });
    this.props.handleSwapping(result);
  };

  renderForm = () => {
    const { driversInfo } = this.state;
    return (
      <div>
      <Spin spinning={this.state.assignStatus} delay={1000}>
        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={24} style={{ paddingBottom: 10 }}>
                <FormItem label={`${I18n.t("general.Driver")} 1`}>
                  {this.renderSelect("source1")}
                    {this.state.source1Error.length >=0 && <div style={{color: "#f5222d", marginTop: 2}}>{this.state.source1Error[0]}</div>}
                </FormItem>
              </Col>
            </Row>
             <Row>
              <Col xs={24} style={{ paddingBottom: 10 }}>
                <FormItem label={`${I18n.t("general.Driver")} 2`}>
                  {this.renderSelect("source2")}
                    {this.state.source2Error.length >=0 && <div style={{color: "#f5222d", marginTop: 2}}>{this.state.source2Error[0]}</div>}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="alignCenter">
            <Button
              size="small"
              onClick={() => this.setState({
                errors: [],
                    source1Error: [],
                    source2Error: [],
                showForm: false,
                driversInfo: { source1: '', source2: '' },
              })}
              type="danger"
              className="marginLRight10"
            >
              {I18n.t('general.cancel')}
            </Button>{" "}
            <Popconfirm
              placement="topLeft"
              title={I18n.t("messages.confirm")}
              onConfirm={this.handleSwapping}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                loading={this.state.assignStatus}
                disabled={
                  !(
                    !isEmpty(driversInfo.source1) &&
                    !isEmpty(driversInfo.source2)
                  )
                }
                type="primary"
              >
                  {I18n.t("general.swap")}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Spin>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  };

  render() {
    const { orders } = this.state;
    return (
      <Fragment>
          <Button
            onClick={this.renderSwapForm}
            size="small"
            type="primary"
            className="buttonTeal"
            disabled={(this.props.routes && this.props.routes.length <= 1) || this.props.isDisabled}
          >
            {I18n.t('general.swap')}
          </Button>

          {this.state.showForm && (
            <BaseModal
              title={I18n.t('messages.swap_heading')}
              onCancel={() => this.onClose(false)}
              className="surveyModal"
              width="40%"
              style={{ top: 40, overflowY: "auto" }}
            >
              {this.renderForm()}
            </BaseModal>
          )}
      </Fragment>
    );
  }
}
SwapDrivers.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape()),
  reqFrom: PropTypes.string,
};
SwapDrivers.defaultProps = {
  reqFrom: "",
  routes: [],
};

export default SwapDrivers;
