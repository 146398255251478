import React, { Component } from "react";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Switch,
} from "../common/UIComponents";
import FormButtons from "./common/FormButtons";
import { alertMessage,  doValidate, formatCode } from "../common/Common";
import { fetchAccounts, fetchShortFormAccounts } from "../api/Account";
import FormErrors from "./common/FormErrors";
import _ from "lodash";
import I18n from "../common/I18n";
import { renderAlertMessage } from "../helpers/common";

const { TextArea } = Input;

const requiredFields = [
  {
    form_field: "fe_status_code",
    ui_name: I18n.t("activity.feStatusCode"),
    isRequired: true,
    inputType: "code",
  },
  {
    form_field: "account_status_description",
    ui_name: I18n.t("OrderStatus.acc_status_desc"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "location_type",
    ui_name: I18n.t("OrderStatus.location_type"),
    isRequired: true,
    inputType: "text",
  },
];

class OrderStatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData:
        this.props.Data && this.props.Data.id
          ? this.props.Data
          : {
              fe_status_code: "",
              account_status_code: "",
              account_status_description: "",
              location_type: '',
              account_id: "",
              account_code: "",
              take_picture: false,
              is_exception: false,
              take_pod: false,
              enter_comments: false,
            },
      accounts: [],
      organisation: this.props.organization,
      inProgress: false,
      Errors: this.props.errors,
      isNew: this.props.isNew,
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = (cb) => {
    this.setState({ inProgress: true, ...this.state.formData });
    const orgId = this.state.organisation;
    const acc_id = this.state.formData.account_id;
    const acc_code = this.state.formData.account_code;
    fetchShortFormAccounts(orgId, "", true).then((result) => {
      if (result.success) {
        const accounts = result.accounts || [];
        this.setState(
          {
            accounts,
            inProgress: false,
            formData: {
              ...this.state.formData,
              account_id:
                accounts.length > 0
                  ? this.state.isNew === true
                    ? accounts[0].id
                    : acc_id
                  : "",
              account_code:
                accounts.length > 0
                  ? this.state.isNew === true
                    ? accounts[0].code
                    : acc_code
                  : "",
            },
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleOnChange = (element, value) => {
    if (element === "account_id") {
      const accountIndex = _.findIndex(this.state.accounts, ["id", value]);
      if (accountIndex >= 0) {
        const Data = Object.assign({}, this.state.accounts[accountIndex]);

        this.setState({
          formData: Object.assign({}, this.state.formData, {
            [element]: value,
            account_code: Data.code,
          }),
        });
      }
    } else {
      this.setState({
        formData: Object.assign({}, this.state.formData, { [element]: value }),
      });
    }
  };

  handleSwitchChange = (element, value) => {
    if (element === "take_picture") {
      const take_picture = this.state.formData.take_picture;
      value = take_picture === true ? false : true;
    }
    if (element === "is_exception") {
      const is_exception = this.state.formData.is_exception;
      value = is_exception === true ? false : true;
    }
    if (element === "take_pod") {
      const take_pod = this.state.formData.take_pod;
      value = take_pod === true ? false : true;
    }
    if (element === "enter_comments") {
      const enter_comments = this.state.formData.enter_comments;
      value = enter_comments === true ? false : true;
    }
    this.setState({
      formData: Object.assign({}, this.state.formData, { [element]: value }),
    });
  };

  handleSave = () => {
    const errors = doValidate(requiredFields, this.state.formData);
    if (errors.length === 0) {
      this.state.formData.fe_status_code = formatCode(
        this.state.formData.fe_status_code
      );
      this.props.onSuccess(this.state.formData);
    } else {
      this.setState({
        Errors: errors,
        inProgress: false,
      });
    }
  };

  render() {
    return (
      <div>
        <Row gutter={8}>
          <Col xs={8}>
            <FormItem label={I18n.t("activity.feStatusCode")} require>
              <Input
                value={this.state.formData.fe_status_code}
                onChange={(e) =>
                  this.handleOnChange("fe_status_code", e.target.value)
                }
                className="textUpperCase"
              />
            </FormItem>
          </Col>
          <Col xs={8}>
            <FormItem label={I18n.t("OrderStatus.acc_status_code")}>
              <Input
                value={this.state.formData.account_status_code}
                onChange={(e) =>
                  this.handleOnChange("account_status_code", e.target.value)
                }
              />
            </FormItem>
          </Col>
          <Col xs={8}>
            <FormItem label={I18n.t("OrderStatus.location_type")} required>
              <Select
                  value={this.state.formData.location_type}
                  showSearch
                  onChange={(e) => this.handleOnChange("location_type", e)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                   <Select.Option key={'ALL'} value={''}>Select</Select.Option>
                  {['DELIVERY', 'PICKUP'].map((key) => (
                    <Select.Option key={key} value={key}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col>
            <FormItem label={I18n.t("OrderStatus.acc_code")} require>
              <Select
                value={this.state.formData.account_id}
                showSearch
                onChange={(e) => this.handleOnChange("account_id", e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              >
                {this.state.accounts.map((acctype) => (
                  <Select.Option key={acctype.id} value={acctype.id}>
                    {acctype.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormItem label={I18n.t("OrderStatus.acc_status_desc")} require>
              <TextArea
                rows={3}
                placeholder={I18n.t("OrderStatus.description")}
                className="sop-form-textArea"
                value={this.state.formData.account_status_description}
                onChange={(e) =>
                  this.handleOnChange(
                    "account_status_description",
                    e.target.value
                  )
                }
              />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col xs={12}>
            <div style={{ fontWeight: 700 }} className="marginBottom15">
              {I18n.t("OrderStatus.take_pic")}:&nbsp;&nbsp;&nbsp;
              <Switch
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checked={this.state.formData.take_picture === true}
                onChange={(e) => this.handleSwitchChange("take_picture", e)}
                className={
                  !this.state.formData.take_picture ? "switchComponent" : null
                }
              />
            </div>
          </Col>
          <Col xs={12}>
            <div style={{ fontWeight: 700 }} className="marginBottom15">
              {I18n.t("OrderStatus.exception")}:&nbsp;&nbsp;&nbsp;
              <Switch
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checked={this.state.formData.is_exception === true}
                onChange={(e) => this.handleSwitchChange("is_exception", e)}
                className={
                  !this.state.formData.is_exception ? "switchComponent" : null
                }
              />
            </div>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={12}>
            <div style={{ fontWeight: 700 }} className="marginBottom15">
              {I18n.t("OrderStatus.signature")}:&nbsp;&nbsp;&nbsp;
              <Switch
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checked={this.state.formData.take_pod === true}
                onChange={(e) => this.handleSwitchChange("take_pod", e)}
                className={
                  !this.state.formData.take_pod ? "switchComponent" : null
                }
              />
            </div>
          </Col>
          <Col xs={12}>
            <div style={{ fontWeight: 700 }} className="marginBottom15">
              {I18n.t("OrderStatus.enter_comments")}:&nbsp;&nbsp;&nbsp;
              <Switch
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checked={this.state.formData.enter_comments === true}
                onChange={(e) => this.handleSwitchChange("enter_comments", e)}
                className={
                  !this.state.formData.enter_comments ? "switchComponent" : null
                }
              />
            </div>
          </Col>
        </Row>
        <Row>{FormErrors(this.state.Errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default OrderStatusForm;
