import React, { Component } from 'react'
import {
  Button,
  Icon,
  Spin,
  Tabs,
  Tooltip,
} from '../../common/UIComponents'
import {
  fetchShortFormAccounts,
  updateOrgRules,
} from '../../api/Account'
import { alertMessage, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import AppConfig from '../../config/AppConfig'
import FormErrors from '../common/FormErrors'
import { Link } from 'react-router-dom'
import BaseSelect from '../common/SelectDropdowns/BaseSelect'
import DeleteIcon from '../common/DeleteIcon'
import AccountGrouping from './AccountGrouping';
import GeneralRules from './GeneralRules';
import { renderAlertMessage } from '../../helpers/common'
const { TabPane } = Tabs;
// const sessionOrgId = userStore.getStateValue("selectedOrg");

const groupForOptions = [
  { key: "INVOICE", name: "Invoice" },
  { key: "BOL", name: "BOL" },
  { key: "SHIPPER_NOTIFICATIONS", name: "Shipper Notifications" },
];
const defaultGroupOptionAccounts = {
  INVOICE: [],
  BOL: [],
  SHIPPER_NOTIFICATIONS: [],
};

class OrgRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: this.props.orgId || userStore.getStateValue('selectedOrg'),
      inProgress: false,
      config: {},
      previousConfig: {},
      errors: [],
      showError: false,
      activeKey: "account_grouping",
    };
  }

  componentDidMount() {
    // this.getOrgConfigs()
    // this.getAccounts()
  }

  getAccounts = () => {
    const orgId = this.state.organization_id;
    this.setState({
      accountsProgress: true,
    });
    fetchShortFormAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ accountsProgress: false });
      }
    });
  };

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => this.handleSave()}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    const { config } = this.state;
    const { group_accounts = [] } = config;
    const isAllNotFilled =
      _.findIndex(group_accounts, { primary_account_id: "" }) >= 0;
    if (isAllNotFilled) {
      this.setState({
        errors: [I18n.t("errors.fill_all_required_fields")],
        showError: true,
      });
    } else {
      const settings = Object.keys(config).map((key) => ({
        setting_name: key,
        setting_value: config[key],
      }));
      const data = {
        settings,
        organization_id: orgId,
      };
      this.setState({
        inProgress: true,
      });
      updateOrgRules(data).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            errors: [],
            showError: false,
            //   accountConfig: Object.assign({}, this.state.accountConfig, {
            //     [result.acct_config.acct_config.setting_name]:
            //       result.acct_config.acct_config.setting_value,
            //   }),
          });
          alertMessage(result.message);
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            errors: result.errors || [],
            inProgress: false,
            showError: false,
          });
        }
      });
    }
  };

  setConfig = (config) => {
    this.setState({
      config,
    });
  };

  setActiveKey = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  handleDetailsChange = (index, element, value) => {
    const { config } = this.state
    const group_accounts = config.group_accounts || []
    if (group_accounts[index]) {
      group_accounts[index][element] = value
      this.setState(
        {
          config: Object.assign({}, this.state.config, {
            group_accounts,
          }),
        },
        () => {
          this.updateSelectedAccounts()
        },
      )
    }
  }

 validateAccounts = (index, element, value, setting) => {
    const { selectedAccounts, accounts } = this.state;
    if(value){
      const selectedAccountsInGroup = _.compact([].concat(setting.primary_account_id, setting.sub_account_ids))
      if(selectedAccounts[value].length > 0){
        const selectedAccs = selectedAccounts[value].filter(opt => selectedAccountsInGroup.includes(opt));
        if(selectedAccs.length > 0){
          const accString = accounts.filter(account => selectedAccs.includes(account.id)).map(account => account.code).join(', ')
          alertMessage(`${accString} ${selectedAccs.length > 1 ?  'are' : 'is'} already configued for ${value}`, 'error', 10); 
        }else{
          this.handleDetailsChange(index,element, value)
        }
      } else{
        this.handleDetailsChange(index,element, value)
      }
    }
  }

  handleDeleteGroupConfig = (index) => {
    const { config, selectedAccounts } = this.state
    const group_accounts = config.group_accounts || []
    if (
      group_accounts.length > 0 &&
      index >= 0 &&
      index <= group_accounts.length
    ) {
      const deletedConfig = group_accounts[index] ? group_accounts[index] : {}
      group_accounts.splice(index, 1)
      const isAllNotFilled =
        _.findIndex(group_accounts, { primary_account_id: '' }) >= 0
      this.setState(
        {
          config: Object.assign({}, this.state.config, {
            group_accounts,
          }),
        },
        () => {
          if (!isAllNotFilled) {
            this.setState({
              errors: [],
            })
          }
          this.updateSelectedAccounts()
          // this.handleSave()
        },
      )
    }
  }

  _renderCustomerOrderRules = () => {
    const { config, selectedAccounts, accountsProgress } = this.state
    return (
      <Row>
        <Col xs={24}>
          {config?.group_accounts && config.group_accounts.length > 0 ? (
            <Fragment>
              <Row>
                <Col xs={24} className="settingHeader">
                  <span className="textBold">
                    {I18n.t(`configurations.group_accounts.label`)}:
                  </span>
                </Col>
                <Col xs={24} className="marginTop5">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Row>
                        <Col xs={24} md={6}>
                          <span className="fontWeight500">
                            <sup className="textRed">*</sup>
                            {I18n.t(
                              `configurations.group_accounts.grouping_for`,
                            )}
                          </span>
                        </Col>
                        <Col xs={24} md={6}>
                          <span className="fontWeight500">
                            <sup className="textRed">*</sup>
                            {I18n.t(
                              `configurations.group_accounts.primary_account`,
                            )}
                          </span>
                        </Col>
                        <Col xs={24} md={9}>
                          <span className="fontWeight500">
                            {I18n.t(
                              `configurations.group_accounts.sub_accounts`,
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {config.group_accounts
                        .filter(
                          (setting) => !isEmpty(setting.group_account_for),
                        )
                        .map((setting, index) => {
                          const accountsToCheck = this.getAccountsFromGroupForOptions(setting);
                          return (
                            <>
                              <Row
                                gutter={16}
                                className="marginTop10"
                                key={`heading${index}`}
                              >
                                <Col xs={24} md={6}>
                                  <BaseSelect
                                    data={groupForOptions}
                                    value={setting.group_account_for}
                                    onChange={(e) => {
                                      this.validateAccounts(
                                        index,
                                        'group_account_for',
                                        e,
                                        setting
                                      )
                                    }}
                                    selectKey={'key'}
                                    selectValue={'key'}
                                    selectedValue={'name'}
                                    style={{ width: '100%' }}
                                    showSearch={true}
                                    size={'small'}
                                    showSelectOption={false}
                                    dropdownMatchSelectWidth={false}
                                  />
                                </Col>
                                <Col xs={24} md={6}>
                                  <Select
                                    value={setting.primary_account_id}
                                    showSearch
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) =>
                                      this.handleDetailsChange(
                                        index,
                                        'primary_account_id',
                                        e,
                                      )
                                    }
                                    size="small"
                                    optionLabelProp="label"
                                    dropdownMatchSelectWidth={false}
                                    loading={accountsProgress}
                                  >
                                    {isEmpty(setting.primary_account_id) && (
                                      <Select.Option
                                        key="account"
                                        value=""
                                        label="-- Select --"
                                      >
                                        -- Select --
                                      </Select.Option>
                                    )}
                                    {this.state.accounts
                                      .filter(
                                        (account) =>
                                          account.id ===
                                            setting.primary_account_id ||
                                            !accountsToCheck.includes(account.id),
                                      )
                                      .map((account) => (
                                        <Select.Option
                                          key={account.id}
                                          value={account.id}
                                          label={account.code}
                                        >
                                          {`${account.name} (${account.code})`}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                  {this.state.showError &&
                                    isEmpty(setting.primary_account_id) && (
                                      <span className="textRed font500 marginTop5">
                                        {'Please Select'}
                                      </span>
                                    )}
                                </Col>
                                <Col xs={24} md={9}>
                                  <Select
                                    value={setting.sub_account_ids || []}
                                    showSearch
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) =>
                                      this.handleDetailsChange(
                                        index,
                                        'sub_account_ids',
                                        e,
                                      )
                                    }
                                    mode="multiple"
                                    size="small"
                                    maxTagCount={2}
                                    optionLabelProp="label"
                                    loading={accountsProgress}
                                    placeholder="-- Select --"
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                  >
                                    {/* <Select.Option
                                  key="account"
                                  value=""
                                  label={'-- Select --'}
                                >
                                  -- Select --
                                </Select.Option> */}
                                    {this.state.accounts
                                      .filter(
                                        (account) =>
                                          (setting?.sub_account_ids &&
                                            setting.sub_account_ids.includes(
                                              account.id,
                                            )) ||
                                          !accountsToCheck.includes(account.id),
                                      )
                                      .map((account) => (
                                        <Select.Option
                                          key={account.id}
                                          value={account.id}
                                          label={account.code}
                                        >
                                          {`${account.name} (${account.code})`}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Col>
                                <Col
                                  xs={24}
                                  md={3}
                                  className="alignLeft"
                                  style={{ height: 23 }}
                                >
                                  <Popconfirm
                                    placement="left"
                                    title={I18n.t('messages.confirm')}
                                    onConfirm={() =>
                                      this.handleDeleteGroupConfig(index)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteIcon className="textBold fontSize18"/>
                                    &nbsp;&nbsp;&nbsp;
                                  </Popconfirm>
                                  {config.group_accounts.length - 1 === index &&
                                    !isEmpty(setting.primary_account_id) && (
                                      <Tooltip title={I18n.t("general.add")}>
                                      <Icon
                                        type="plus-circle"
                                        onClick={this.addGroupRow}
                                        size="small"
                                        className="marginLeft10 fontSize18"
                                      />
                                      </Tooltip>
                                    )}
                                </Col>
                              </Row>
                            </>
                          )
                        })}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="settingHeader"
                style={{ marginBottom: 20, paddingBottom: 12 }}
              >
                <Row>
                  <Col xs={12}>
                    <span className="textBold">
                      {I18n.t(`configurations.group_accounts.label`)}
                    </span>
                  </Col>
                  <Col xs={12} className="alignRight">
                    <Tooltip title={I18n.t('general.add')}>
                      <Button
                        icon="plus-circle"
                        type="primary"
                        onClick={this.addGroupRow}
                        size="small"
                      >
                        {I18n.t('general.add')}
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </div>
              <Empty
                description={
                  <Fragment>
                    <h4>
                      No account grouping is done.{' '}
                      <Button type="link" onClick={this.addGroupRow}>
                        Click here to configure
                      </Button>
                    </h4>
                  </Fragment>
                }
              />
            </Fragment>
          )}
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className="child-card-container">
        <Tabs
          activeKey={this.state.activeKey}
          onChange={(key) => this.setActiveKey(key)}
          size="small"
          type="card"
        >
          <TabPane
            tab={I18n.t(`configurations.group_accounts.label`)}
            key="account_grouping"
            className='settingSubTabScroll'
          >
            <AccountGrouping activeKey={this.state.activeKey} />
          </TabPane>
          <TabPane
            tab={I18n.t("configurations.org_rules.general_rules")}
            key="org_rules"
            className='settingSubTabScroll'
          >
            <GeneralRules
              activeKey={this.state.activeKey}
              handleAppStateOnChange={this.props.handleAppStateOnChange}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default OrgRules;
