import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Col,
  FormItem,
  Icon,
  Radio,
  Row,
  Select,
} from "../../../common/UIComponents";
import { alertMessage } from "../../../common/Common";
import AppConfig from '../../../config/AppConfig';
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import { fetchFuelSurchargeMasters, saveFuelSurchargeAccountConfig } from '../../../api/FuelSurcharges';
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";

const RadioGroup = Radio.Group;

class FuelSurchargeAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: this.props.config || {},
      isNew: this.props.isNew,
      organizationId: this.props.organizationId,
      inProgress: false,
      errors: [],
      fuel_surcharges: [],
    };
  }

  componentDidMount() {
    this.getOrgFuelSurcharges();
  }

  getOrgFuelSurcharges = () => {
    this.setState({ inProgress: true });
    const org_id = this.state.organizationId;
    fetchFuelSurchargeMasters(null, org_id)
      .then((result) => {
        if (result.success) {
          this.setState({
            fuel_surcharges: result.fuel_surcharge_masters || [],
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleOnChange = (element, value) => {
    this.setState({
      config: Object.assign({}, this.state.config, { [element]: value }),
    });
  }

  handleSave = () => {
    this.setState({ inProgress: true });
    const {config} = this.state;
    const data = { 
      fs_master_id: config.id,
      _surcharge_type: config._surcharge_type,
      organization_id: this.state.organizationId,
      account_id: this.props.accountId
    }
    saveFuelSurchargeAccountConfig(this.props.isNew, data)
      .then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t('messages.saved'));
        } else {
          this.setState({ errors: result.errors, inProgress: false });
          this.setState({ inProgress: false });
        }
      });
  }

  render() {
    const { fuel_surcharges } = this.state;
    return (
      <div>
        <Row gutter={8}>
          <Col xs={12}>
            <FormItem label={I18n.t("fuelSurcharges.surcharge_type")} require>
              <Select
                value={this.state.config._surcharge_type}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={e => this.handleOnChange('_surcharge_type', e)}
              >
                {Object.keys(AppConfig.fuelSurchargeTypes).map(key => (
                  <Select.Option key={key} value={key}>
                    {AppConfig.fuelSurchargeTypes[key]}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label={I18n.t("fuelSurcharges.org_config")} require>
              <Select
                value={this.state.config.id}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props?.children && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={e => this.handleOnChange('id', e)}
                notFoundContent="Not found"
              >
                {_.map(_.filter(fuel_surcharges, ['_surcharge_type', this.state.config._surcharge_type]), (obj) => (
                  <Select.Option key={obj.name} value={obj.id}>
                    {obj.name} 
                    {obj.is_primary && <Icon type="check" className="marginLeft10"/>}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          {FormButtons(
            false,
            this.handleSave,
            this.props.onCancel,
            true,
            true,
            "default",
            I18n.t("general.save"),
            I18n.t("general.cancel")
          )}
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

export default FuelSurchargeAccountForm;

FuelSurchargeAccountForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
