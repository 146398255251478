import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "antd";
import DmsProcessedList from "../components/dms/DmsProcessedList";
import { DmsApi } from "../api/Dms";
import {
  Search,
  Dropdown,
  Icon,
  Tabs,
  Button,
  Spin,
  Select,
  FormItem,
} from "../common/UIComponents";
import { Fragment } from "react";
import DateFilter from "../components/dms/DateFilter";
import userStore from "../stores/UserStore";
// import DmsUnProcessedList from "../components/dms/DmsUnprocessedList";
import OperationsDms from "../components/dms/OperationsDms";
import BaseModal from "../components/BaseModal";
import { fetchOrders } from "../api/OrdersApi";
import { defaultSearchRecoveryfilter } from "../components/dispatches/helpers";
import { alertMessage, randomNumber } from "../common/Common";
import I18n from "../common/I18n";
import { WarehouseContext } from "../context/WarehouseContext";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { isEmpty } from "../common/Common";
import moment from "moment";
import AppConfig from "../config/AppConfig";
import DmsSearchOrderList from "../components/dms/DmsSearchOrderList";
import { removeSpecialCharacters, renderAlertMessage } from "../helpers/common";
import { ScreenKeys } from "./constants";
// import DmsList from "../components/dms/newDms/DmsList";
import DmsCombinedList from "../components/dms/newDms/DmsCombinedList";
import _ from "lodash";
import UnprocessedReasonList from "../components/dms/newDms/UnprocessedReasonList";

const NewDmsConfig = (props) => {
  // const onChange = (key) => {
  // };
  const { userContext } = props.appContexts;
  const { currentUser, updateRecordsPerPage } = userContext;
  const [data, setData] = useState([]);
  const [urlData, setUrlData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [showFilterWindow, setShowFilterWindow] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
    toDate: moment(),
    view_by: "BOTH",
  });
  const defaultFilter = defaultSearchRecoveryfilter(props);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrderKeys, setSelectedOrderKeys] = useState([]);
  const [selectedOrderRows, setSelectedOrderRows] = useState([]);
  const [selectedSearchOrderKeys, setSelectedSearchOrderKeys] = useState([]);
  const [searchOrderToken, setSearchOrderToken] = useState("");
  const [searchKeyPress, setSearchKeyPress] = useState(false);
  const [showlinkModal, setShowLinkModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  // const [isunLoading, setUnIsLoading] = useState(false);
  // const [linkLoading, setLinkLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [totalOrders, setTotalOrders] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [dmsPagination, setDmsPagination] = useState({ current_page: 1 });
  const [combinedPagination, setCombinedPagination] = useState({
    current_page: 1,
  });
  const perPageKey = ScreenKeys.DMS;
  const recordsPerPageSaved = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved);
  // const [orderDetails, setOrderDetails] = useState([]);
  const [docType, setDocType] = useState("");
  // const [dmsData, setDmsData] = useState([]);
  // const [activeTab, setActiveTab] = useState("processList");
  const orgId = userStore.getStateValue("selectedOrg");
  // const whId= userStore.getStateValue("")
  const { selectedWarehouse, selectedWarehouses, isWarehouseMultiSelect } =
    useContext(WarehouseContext);
  const selectedWhIds = isWarehouseMultiSelect
    ? selectedWarehouses
    : [selectedWarehouse];

  let tableOptions = { pagination: {current: 1}, filters: {}, sorter: {} };

  let dmsTablePagination = {
    total: dmsPagination.total_count,
    current: dmsPagination.current_page,
    pageSize: dmsPagination.per_page || recordsPerPage,
  };

  let combinedTablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  const onDmsTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      pagination && pagination.current ? pagination.current : 1;
    dmsTablePagination = { pagination, filters, sorter };
    getOrders();
  };
  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved;
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };

  const onCombinedTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      pagination && pagination.current ? pagination.current : 1;
    tableOptions = { pagination, filters, sorter };
    getCombinedData(filter.view_by, filter.fromDate, filter.toDate,searchText);
    //   getDms(filter.fromDate, filter.toDate, recordsPerPage, AppConfig.perPage);
  };

  const handleTextChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    
    if (searchText === "") {
      getCombinedData(filter.view_by, filter.fromDate, filter.toDate);
    }
  };

  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag);
  };

  const handleRecordSelect = (recordId) => {
    setSelectedRecord(recordId);
  };

  const handleDateFilterChange = (newFilter) => {
    setFilter(newFilter);
    getCombinedData(newFilter.view_by, newFilter.fromDate, newFilter.toDate);
    setSearchText("");
  };
  

  const getCombinedData = async (view_by, fromDate, toDate, searchText = '') => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : (pagination?.current_page || 1);
    const perPage = recordsPerPage;

    try {
      setIsLoading(true);
      const result = await DmsApi.fetchCombined(
        view_by,
        fromDate,
        toDate,
        orgId,
        selectedWhIds,
        page,
        perPage,
        searchText
      );
      if (result.success) {
        const details = result.details ? result.details.map(details => ({
          ...details,
          id: details.picture_id || randomNumber(),
          selectedPages: [],
        })) : [];
        setCombinedData(details || []);
        setPagination(result.pagination || {});

      } else {
        setCombinedData([]);
        renderAlertMessage(result.errors)
      }
    } catch (error) {
      alertMessage("Error fetching DMS:", error);
    } finally {
      setIsLoading(false);
      setSelectedRows([]);
    }
  };

  const handlePictureMultiDeletion = () => {
    const data = { combinedData };
    const id = selectedOrderKeys;

    const refer = "warehouse";
    const warehouse_ids = selectedWhIds;

    const pictureIdsToDelete = selectedOrderKeys.map((selectedKey) => {
      const selectedRecord = data.combinedData.find(
        (record) => record.picture_id === selectedKey // Replace 'key' with the appropriate identifier
      );
      return selectedRecord ? selectedRecord.picture_id : null;
    });
    const payload = {
      selected_ids: pictureIdsToDelete, // Convert array to comma-separated string
      org_id: orgId,
      warehouse_ids: selectedWhIds, // Convert array to comma-separated string
    };

    DmsApi.delete(payload).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));

        getCombinedData(filter.view_by, filter.fromDate, filter.toDate);
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const handleSelectChange = (keys, rows) => {
    const list = [...combinedData];
    const newRows = [...selectedRows]
    const latestSelectedKey = keys.find(key => !selectedOrderKeys.includes(key));
    setSelectedOrderKeys(keys);
    keys.forEach((rowId) => {
      const selectedIndex = _.findIndex(list, {id : rowId})
      if(selectedIndex >= 0){
        if (rowId === latestSelectedKey) {
        list[selectedIndex].selectedPages = list[selectedIndex]?.reason
          ? (list[selectedIndex]?.reason.map(rec => rec.page_number) || [])
          : [];
      }
      }
    })
    
    setCombinedData(list.map(rec=> ({...rec, selectedPages: keys.includes(rec.id) ? rec.selectedPages : []})))
    setSelectedRows(list.filter(rec => keys.includes(rec.id)));
  };

  const handleReasonsSelection = (keys, rows, rowId) => {
    const list = [...combinedData];
    const rowIndex = _.findIndex(list, {id: rowId})
    const newRows = [...selectedRows]
    if(keys.length === 0){
      setSelectedRows(newRows.filter(rec => rec.id !== rowId))
      setSelectedOrderKeys(selectedRowKeys.filter( id => id !== rowId));
      if(rowIndex > -1){
        list[rowIndex].selectedPages  =  [];
        setCombinedData(list)
      }
    }else{
      if(rowIndex > -1){
        list[rowIndex].selectedPages  =  [...keys];
        setCombinedData(list)
        if(!selectedRowKeys.includes(rowId)){
          setSelectedOrderKeys([...selectedRowKeys, rowId]);
        }
        const selectedIndex = _.findIndex(newRows, {id : rowId})
        if(selectedIndex>=0){
          // setSelectedRows[selectedIndex] = list[rowIndex]
          newRows[selectedIndex] = list[rowIndex];
          setSelectedRows([...newRows]);
        }else{
          setSelectedRows([...newRows, {...list[rowIndex]}]);
        }
      }
    }
  }

  const selectedRowKeys = selectedOrderKeys;
  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    // disabled: combinedData.status === "success",

    getCheckboxProps: (record) => ({
      disabled: record.status === "success",
      indeterminate: record?.selectedPages?.length > 0 && record?.reason?.length !== record.selectedPages.length
    }),
  };

  const handleSelectOrder = (selectedOrderRowKeys, selectedOrderRows) => {
    setSelectedSearchOrderKeys(selectedOrderRowKeys);
    setSelectedOrderRows(selectedOrderRows);
  };

  const getSelectedOrderIds = () => {
    const collectedOrderIds = selectedOrderRows.map((a) => a.order.id);
    if (collectedOrderIds && collectedOrderIds.length > 0) {
      return collectedOrderIds;
    } else {
      return null;
    }
  };

  const selectedSearchRowKeys = selectedSearchOrderKeys;

  const rowSelection1 = {
    selectedSearchRowKeys,
    onChange: handleSelectOrder,
  };

  const handleLink = () => {
    setShowLinkModal(true);
  };
  const handleCancel = () => {
    handleSelectChange([], [])
    // setSelectedRows([]);
    // setSelectedOrderKeys([]);
  };
  const closeModal = () => {
    setShowLinkModal(false);
    // setOrderDetails(null);
    setSearchOrderToken("");
    setSelectedRecord(null);
    setSelectedSearchOrderKeys([]);
    setSelectedOrderRows([]);
    setFilteredData(false);
    setTotalOrders([]);
    setDocType("");
    setDmsPagination({});
  };

  const handleLinkSave = () => {
    const selectedOrderIds = getSelectedOrderIds();
    const payload = {
      doc_type: docType,
      organization_id: orgId,
      // order_id: selectedRecord,
      order_ids: selectedOrderIds,
      pictures: selectedRows.map((row) => ({
        parent_id: row.parent_id,
        picture_id: row.picture_id,
        page_numbers: row?.selectedPages || []
        // location_id: row.location_id,
      })),
    };
    // Prevent the default form submission behavior

    DmsApi.createLink(payload)
      .then((response) => {
        if (response.success) {
          const msg = response.message
            ? response.message
            : I18n.t("messages.saved");
          alertMessage(msg, "success", 5);
          closeModal();
          // getpictureurls();
          getCombinedData(filter.view_by, filter.fromDate, filter.toDate);

          // ...
        } else {
          renderAlertMessage(result.errors) // Check the structure of the response
        }
      })
      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setSelectedOrderKeys([]);
        setSelectedSearchOrderKeys([]);
        setSelectedRows([])
        setSelectedOrderRows([]);
        // ...
      });
  };

  const getOrders = () => {
    const page =
      dmsTablePagination.pagination?.current &&
      !isEmpty(dmsTablePagination.pagination.current)
        ? dmsTablePagination.pagination.current
        : 1;
    // Update with your logic for current page
    // const perPage = AppConfig.perPage;
    const perPage = recordsPerPage;

    const orderNumber = searchOrderToken ? _.toUpper(searchOrderToken) : "";
    setIsOrdersLoading(true);
    fetchOrders({
      status: "",
      page,
      perPage,
      orderNumber,
      globalSearch: true,
      fromDate: null,
      todate: null,
      search_type: 'EQUALS',
    })
      .then((result) => {
        setIsOrdersLoading(true);
        if (result.success) {
          setFilteredData(result.orders);
          setDmsPagination(result.pagination || {});
          const total_orders = result.orders.customer_orders || [];
          setTotalOrders(total_orders);
          // setOrderDetails(total_orders[0].order);
        } else {
          setDmsPagination({});
          renderAlertMessage(result.errors)
          setFilteredData(false);
          setTotalOrders([]);
        }
      })
      .finally(() => {
        setIsOrdersLoading(false);
      });
  };

  const handleOnChange = (fieldName, value) => {
    setDocType(value); // Update the selected value
  };

  const handleViewByChange = (value) => {
    setFilter({
      ...filter,
      view_by: value,
    });

    // Call the function to fetch data based on the new filter values
    // getCombinedData(value, filter.fromDate, filter.toDate);
  };

  const linkunProcessedForm = () => {
    return (
      <BaseModal
        title={I18n.t("order.link_to_order")}
        visible={showlinkModal}
        onCancel={closeModal}
        footer={null}
        width={"540px"}
      >
        <Spin spinning={isLoading}>
          <Row gutter={0} display="flex" className="dmsPage">
            <Col className="alignLeft" span={20}>
              {renderSearchBar()}
            </Col>
            <Col className="alignLeft" span={2}>
              <Button
                onClick={() => {
                  if (searchOrderToken.trim() === "") {
                    // Show an alert message when the search token is empty
                    alertMessage("Please enter any value to search", "error");
                  } else {
                    getOrders();
                  }
                }}
                type="primary"
                style={{
                  borderBottomLeftRadius: "50%",
                  borderTopLeftRadius: "50%",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  height: "32px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Spin spinning={isOrdersLoading}>
              {totalOrders && (
                <DmsSearchOrderList
                  rowKey={(record) => record.order.id.toString()}
                  data={totalOrders}
                  pagination={dmsTablePagination}
                  //pagination={{ position: 'none'}}
                  rowSelection={rowSelection1}
                  // selectedRecord={selectedRecord}
                  // onRecordSelect={handleRecordSelect}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    onDmsTableChange(pagination, sorter, currentTable)
                  }
                  scroll={{ y: 300, x: "max-content" }}
                />
              )}
            </Spin>
          </Row>
          &nbsp;&nbsp;
          {selectedSearchOrderKeys.length > 0 ? (
            <Row>
              <Col span={24} className="textBold">
                <span style={{ fontWeight: "bolder", marginTop: 3 }}>
                  Document Type:
                </span>
                &nbsp;&nbsp;
                <Select
                  value={docType}
                  showSearch
                  onChange={(value) => handleOnChange("docType", value)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  required
                  style={{ width: "73%" }}
                >
                  <Option value="POD">POD</Option>
                  <Option value="BOL">BOL</Option>
                  <Option value="user_manual">User Manual</Option>
                </Select>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {selectedSearchOrderKeys.length > 0 ? (
            <Row className="marginTop10">
              <Col xs={24} className="alignCenter">
                <Button onClick={closeModal} className="marginRight5">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={handleLinkSave}
                  disabled={
                    selectedSearchOrderKeys.length === 0 || docType === ""
                  }
                >
                  Link
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Spin>
      </BaseModal>
    );
  };

  useEffect(() => {
    if (filter.fromDate && filter.toDate) {
      getCombinedData(filter.view_by, filter.fromDate, filter.toDate,searchText);
    }
  }, [filter.view_by,recordsPerPage, selectedWhIds]);
  const onSearch = (value) => {};
  const SearchbyOrderinUnprocessed = async (value) => {
    if (searchOrderToken.trim() === "") {
      alertMessage("Please enter any value to search", "error");
    } else {
      setSearchOrderToken(value);
      setSearchKeyPress(true);
      getOrders();
    }
  };

  const closeSearchInsideDateFilter = () => {
    setShowFilterWindow(false);
  };

  const renderSearchBar = () => {
    return (
      <div className="headerOptionDiv">
        <Search
          placeholder={I18n.t("order.filters.placeholder")}
          size="default"
          value={searchOrderToken}
          onSearch={SearchbyOrderinUnprocessed}
          onChange={(e) => {
            // clear url search params
            if (window.location.search.indexOf("customer_order_number") > -1) {
              const { history } = props;
              history.replace("/orders");
            }
            const filteredString = removeSpecialCharacters(e.target.value);
            setSearchOrderToken(filteredString);
          }}
          style={{ width: 410, textTransform: "uppercase" }}
          // allowClear
          suffix={
            <Fragment>
              {searchOrderToken !== "" && (
                <Fragment>
                  {" "}
                  <Icon
                    type="close"
                    onClick={() => {
                      setSearchOrderToken("");
                      setSelectedSearchOrderKeys([]);
                      setSelectedOrderRows([]);
                      setFilteredData(false);
                      setTotalOrders([]);

                      // setSearchKeyPress(true);
                    }}
                  />{" "}
                </Fragment>
              )}
            </Fragment>
          }
          className="ant-input-search"
        />
      </div>
    );
  };

  return (
    <div className="content-outer">
      <div className="content dmsPage">
        <Row className="page-header" justify="space-between">
          <Col xs={12}>DMS Menu</Col>

          <Col
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 10,
            }}
            className="alignRight headerOptionDiv"
          >
            <Search
              size="default"
              style={{ maxWidth: 350 }}
              className="seachWithDropDown recoverySearch search_with_prefix"
              value={searchText}
              // placeholder="Choose Date From Here"
              placeholder="SEARCH ORDER NO, HAWB, MAWB"
              onChange={handleTextChange}
              allowClear  
              onClear={() => {
                setSearchText("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Delete") {
                  setSearchText("");
                }else if(e.key === "Enter")
                {
                  getCombinedData(filter.view_by, filter.fromDate, filter.toDate,searchText);
                  setFilter({
                    ...filter,
                    view_by: 'PROCESSED',
                  });
                }
              }}
              suffix={
                <Fragment>
                   {/* {searchText !== "" && (
                    <Icon
                      type="close-circle"
                      theme="filled"
                      size="small"
                      onClick={() => {
                        setSearchText("");
                      }}
                    />
                  )} */}
                  &nbsp;
                  <Dropdown
                    onVisibleChange={handleVisibleChange}
                    visible={showFilterWindow}
                    trigger={["click"]}
                    overlay={
                      <div
                        style={{
                          backgroundColor: "white",
                          width: 350,
                          padding: 15,
                          marginTop: 10,
                          marginRight: -12,
                        }}
                        className="boxShadow"
                      >
                        <DateFilter
                          filter={filter}
                          onChange={handleDateFilterChange}
                          clearFilter={() => {
                            // Clear the filter logic
                            setFilter({
                              fromDate: null,
                              toDate: null,
                            });
                          }}
                          closeDropDown={closeSearchInsideDateFilter}
                        />
                      </div>
                    }
                  >
                    <Icon
                      type="control"
                      onClick={() => setShowFilterWindow(true)}
                    />
                  </Dropdown>
                </Fragment>
              }
            />
          </Col>
        </Row>

        <Row justify="space-between">
          <Col
            xs={6}
            style={{
              display: "flex",
              paddingRight: 10,
              marginTop: 10,
              justifyContent: "flex-start",
             
            }}
          >
            <span style={{ fontWeight: "bold" }}>View Type:</span>
            &nbsp;&nbsp;
            <Select
              value={filter.view_by}
              showSearch
              onChange={(value) => handleViewByChange(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              // style={{ width: 100 }}
              size="small"
              dropdownMatchSelectWidth={false}
            >
              <Select.Option key="processed" value="PROCESSED">
                Processed
              </Select.Option>
              <Select.Option key="unprocessed" value="UNPROCESSED">
                Unprocessed
              </Select.Option>
              <Select.Option key="both" value="BOTH">
                Both
              </Select.Option>
            </Select>
          </Col>
          <Col xs={8}></Col>

          <Col
            xs={8}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 10,
              marginTop: 10,
            }}
            className="alignRight headerOptionDiv"
          >
            <RecordsPerPage
              onChange={onRecordChange}
              onSearch={onSearch}
              value={recordsPerPage}
              defaultValue={recordsPerPage}
            />
          </Col>
          <Col xs={2}>
            <div
              className="alignRight"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Button
                type="primary"
                size="small"
                icon="redo"
                onClick={() =>
                  getCombinedData(
                    filter.view_by,
                    filter.fromDate,
                    filter.toDate,
                    searchText
                  )
                }
              >
                Refresh
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className={`dmsPage `}>
            <Spin spinning= {isLoading}>
              <DmsCombinedList
              data={combinedData}
              //rowKey={"picture_id"}
              rowSelection={rowSelection }
              handleExport={handleLink}
              pagination={combinedTablePagination}
              tableChange={(pagination, filter, sorter, currentTable) =>
                onCombinedTableChange(pagination, filter, sorter, currentTable)
              }
              // scroll={{ y: 300, x: "max-content" }}
              scroll = {{y: selectedOrderKeys ?.length ? "calc(100vh - 300px)" : "calc(100vh - 270px)",x: "max-content"}}
              handleReasonsSelection={handleReasonsSelection}

              // rowClassName={(record) => (record.status === 'success' ? 'processed-row' : 'unprocessed-row')}
              // rowClassName={(record, index) =>
              //   record.status === 'success' ? "" : "errRow"
              // }
              expandedRowRender={(record) =>
              {
                return filter.view_by !== "PROCESSED" ? (
                  record?.reason && record.reason !== 'NA'? 
                    <div className={`${record.status === "success" ? 'processedList' : ''}`} style={{ marginLeft: 40}}>
                      <div className="notes_content textBold textAllocated" style={{ fontSize: '0.9rem'}}>Select pages to process them</div>
                      <UnprocessedReasonList 
                        rowKey={"page_number"}
                        data={record.reason || []} 
                        size="small" 
                        pagination={{ position: 'none'}}
                        scroll={{
                          y: 200,
                          x: 400
                        }}
                        rowSelection = {{
                          selectedRowKeys: record.selectedPages || [],
                          onChange: (selectedRowKeys, selectedRows) => handleReasonsSelection(selectedRowKeys, selectedRows, record.id),
                        }}
                      />
                    </div>
                : <Fragment />
                )
                :
                undefined
              }
              }

            /></Spin>
          </Col>
        </Row>

        <Row>
          {selectedOrderKeys.length > 0 && (
            <OperationsDms
              handleExport={handleLink}
              handleCancel={handleCancel}
              handleDelete={handlePictureMultiDeletion}
            />
          )}
        </Row>

        {showlinkModal && linkunProcessedForm()}
      </div>
    </div>
  );
};

export default NewDmsConfig;
