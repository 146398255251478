import React, { Component, Fragment, useContext } from 'react';
import moment from 'moment';
import _ from "lodash"
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { Col, Divider, FormItem, Input, Row, Select, Tabs } from '../../common/UIComponents';
import { alertMessage, doValidate,formatCode } from '../../common/Common';
import FormButtons from '../common/FormButtons';
import { saveLocation } from '../../api/LocationsApi';
import FormErrors from '../common/FormErrors';
import AddressForm from '../common/AddressForm';
import { loadGoogleMaps } from '../../utils/Utils';
import MobileInput from '../common/MobileInput';
import userStore from '../../stores/UserStore';
import {fetchBillingAccounts}  from "../../api/BillingAcountsApis"
import ConfigurationForm from "../common/ConfigurationForm";
import { WarehouseContext } from '../../context/WarehouseContext';
import { OrgContext } from '../../context/OrgContext';
import { UserContext } from '../../context/UserContext';
import AddressSearch from '../../stories/components/AddressSearch';
import I18n from '../../common/I18n';
import { fetchContactGroup } from '../../api/ContactGroup';
import LabelInfo from '../configurations/LabelInfo';

const RadioGroup = Radio.Group;
const { TabPane } = Tabs;

const requiredFields = [{
  form_field: 'name',
  ui_name: I18n.t('general.name'),
  isRequired: true,
  inputType: 'text',
},
{
  form_field: 'location_code',
  ui_name: I18n.t('general.code'),
  isRequired: true,
  inputType: 'code',
},
{
  form_field: 'first_name',
  ui_name: I18n.t('location.contact_first_name'),
  isRequired: true,
  inputType: 'text',
},
{
  form_field: 'last_name',
  ui_name: I18n.t('location.contact_last_name'),
  isRequired: false,
  inputType: 'text',
},
{
  form_field: 'phone',
  ui_name: I18n.t('general.contact_no'),
  isRequired: true,
  inputType: 'mobile',
},
{
  form_field: 'email',
  ui_name: I18n.t('general.email'),
  isRequired: false,
  inputType: 'email',
},
{
  form_field: 'l_address',
  ui_name: I18n.t('general.address'),
  isRequired: true,
  inputType: 'address',
}
];
class LocationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.whlocation ? this.props.whlocation : { l_type: 'PICKUP' },
      map: false,
      isNew: this.props.isNew,
      inProgress: false,
      errors: [],
      billingAccountData: [],
      activeKey: "wh_details",
      selectedLocation: this.props.selectedLocation ? this.props.selectedLocation : {},
      contactGroups: []
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOnAddressChange = this.handleOnAddressChange.bind(this);
  }

  componentDidMount() {
    this.modifyInputData();
    this.getBillingAccounts();
    this.getContactGroups();
  }

  // componentDidUpdate(prevProps,prevState ) {
  //     if (!_.isEqual(this.props.location, prevProps.location)) {
  //       //  this.setState({ location: { ...this.props.location } });
  //       this.setState({
  //         location: this.props.location
  //           ? this.props.location
  //           : { l_type: "PICKUP" },
  //       });
  //     }
  // }

  static getDerivedStateFromProps(props, state) {
    const { location } = state;
    location.open_time = location.open_time ? moment(location.open_time, 'HH:mm:ss') : moment();
    location.close_time = location.close_time ? moment(location.close_time, 'HH:mm:ss') : moment();
    return location;
  }

  getBillingAccounts = () => {
    const organization_id = this.props.organization_id ? this.props.organization_id : userStore.getStateValue("selectedOrg");
    this.setState({inProgress:true})
    fetchBillingAccounts(organization_id).then((result) => {
      if (result.success) {
        this.setState({
          billingAccountData: result.integration_companies || [],
          inProgress:false
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({
          inProgress: false,
          billingAccountData:[]
        });
      }
    });
  };

  getContactGroups = () => {
    const accountId = '';
    this.setState({inProgress:true})
    fetchContactGroup(1,null,"","",accountId).then((result) => {
      if (result.success) {
        this.setState({
          contactGroups: result.contacts_groups || [],
          inProgress:false
        });
      } else {
        // alertMessage(result.errors, "error", 10);
        this.setState({
          inProgress: false,
          contactGroups:[]
        });
      }
    });
  };

  modifyInputData = () => {
    const { location } = this.state;
    location.open_time = location.open_time ? moment(location.open_time, 'HH:mm:ss') : moment();
    location.close_time = location.close_time ? moment(location.close_time, 'HH:mm:ss') : moment();
    if (location.zone) {
      location.zone_id = location.zone.id;
    }
    this.setState({
      location,
    }, () => {
      loadGoogleMaps(() => {
        this.setState({
          map: true,
        });
      });
    });
  }


  handleOnChange(element, value) {
    this.setState({ location: Object.assign({}, this.state.location, { [element]: value }) });
  }

  handleMobileChange = (element, value) => {
    this.setState({ location: Object.assign({}, this.state.location, { [element]: value }) });
  };

  handleOnAddressChange(element, value, obj) {
    const address = this.state.location.l_address || {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign(address, obj);
    } else if (element) {
      newAddress[element] = value;
    }
    this.setState({ location: Object.assign({}, this.state.location, { l_address: newAddress }) });
  }

  handleSave() {
    this.setState({ inProgress: true });
    const data = Object.assign({}, this.state.location);
    data.l_type = 'WH';
    const formFields = _.cloneDeep(requiredFields);
    const { currentRole } = this.props.userContextData;
    const isSuperAdmin = currentRole === 'super_admin';   
    formFields.push({
      form_field: 'integration_company_id',
      ui_name: I18n.t("billing_accounts.label"),
      isRequired: isSuperAdmin ? false : true,
      inputType: 'text',
    })
    
    const errors = doValidate(formFields, data);
    if (errors.length === 0) {
      data.location_code = formatCode(data.location_code);
      if(isSuperAdmin){
        data.organization_id = this.props.organization_id;
        data.org_id = this.props.organization_id;
      }
      saveLocation(this.state.isNew, data)
        .then((result) => {
          if (result.success) {
            const locations = result.location || {};
            const selectedLocation = { ...locations }
            this.setState({
              errors: [],
              selectedLocation,
              location: {
                ...this.state.location,
                ...selectedLocation,
              },
            });

            const newLocation = {
              warehouseName: selectedLocation.name,
              warehouseId: selectedLocation.id,
              accounts:[],
            }

            if (this.state.isNew) {
              this.props.warehouseFilter.setWarehouses([ ...this.props.warehouseFilter.warehouses, selectedLocation ]);
              this.props.warehouseFilter.setWarehouseWithAccounts([...this.props.warehouseFilter.warehouseWithAccounts, newLocation]);
            }
            else {
              const modifiedLocationData = this.props.warehouseFilter.warehouses.map((location) => {
                if (location.id === selectedLocation.id) {
                  return selectedLocation;
                }
                return location;
              });
              this.props.warehouseFilter.setWarehouses(modifiedLocationData);
              this.props.warehouseFilter.setWarehouseWithAccounts(
                this.props.warehouseFilter.warehouseWithAccounts.map((wh) => {
                  if (wh.warehouseId === selectedLocation.id) {
                    return {
                      warehouseName: selectedLocation.name,
                      warehouseId: selectedLocation.id,
                      accounts: selectedLocation.accounts ? selectedLocation.accounts : [],
                    };
                  }
                  return wh;
                })
              );
            }
            if (this.state.isNew) {
            this.setState({
              isNew: false,
            })
            this.goToTab("wh_config")
          }
            alertMessage(I18n.t('messages.saved'));
          } else {
            this.setState({ errors: result.errors, inProgress: false });
          }
        }).finally(() => {
          this.setState({ inProgress: false });
        });
    } else {
      this.setState({ errors, inProgress: false });
    }
  }

  hideConfigForm = () => {
    this.props.onCancel()
    this.setState({ selectedLocation: {} });
  }

  goToTab = (key) => {
    this.setState({
      activeKey: key,
    })
  }

  _renderDetails = () =>{
    const { location } = this.state;
    const isSuperAdmin = userStore.superAdmin()
        return (
          <div>
            <Row gutter={16}>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("general.name")} require>
                  <Input
                    value={location.name}
                    onChange={(e) =>
                      this.handleOnChange("name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("general.code")} require>
                  <Input
                    value={location.location_code}
                    onChange={(e) =>
                      this.handleOnChange("location_code", e.target.value)
                    }
                    className="textUpperCase"
                    disabled={!this.state.isNew}
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("location.contact_first_name")} require>
                  <Input
                    value={location.first_name}
                    onChange={(e) =>
                      this.handleOnChange("first_name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("location.contact_last_name")}>
                  <Input
                    value={location.last_name}
                    onChange={(e) =>
                      this.handleOnChange("last_name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("general.email")}>
                  <Input
                    value={location.email}
                    onChange={(e) =>
                      this.handleOnChange("email", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("general.contact_no")} require>
                  <MobileInput
                    handleInputChange={(value, data) => {
                      this.handleMobileChange("phone", value, data);
                    }}
                    value={location.phone || ""}
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("billing_accounts.label")} require={!isSuperAdmin}>
                  <Select
                    value={location.integration_company_id}
                    showSearch
                    onChange={(e) =>
                      this.handleOnChange("integration_company_id", e)
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                    placeholder="-- Select --"
                  >
                    {this.state.billingAccountData.map((company) => (
                      <Select.Option key={company.id} value={company.id}>
                        {company.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col sm={11} xs={22} md={11} lg={11}>
              <strong>{I18n.t('general.warehouse_alert_group')}</strong>
                <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.contact_group_ids"
                    )}
                />
                    <Select
                    mode="multiple"
                    value = {location.contact_group_ids}
                    showSearch
                    allowClear
                    style={{width:"100%"}}
                    placeholder="Select"
                    onChange={(e) =>
                      this.handleOnChange("contact_group_ids", e)
                    }
                    >
                    {this.state.contactGroups.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                    </Select>
              </Col> 
              <Col sm={1} xs={2} md={1} lg={1}>

              
              </Col>

              {/* <Col sm={12} xs={12} md={8} lg={8}>
            <FormItem label='Zone'>
              <Select
                value={location.zone_id}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={e => this.handleOnChange('zone_id', e)}
              >
                {
                  this.props.zones.map(zone => (
                    <Select.Option key={zone.id} value={zone.id}>{zone.name}</Select.Option>
                  ))
                }
              </Select>
            </FormItem>
          </Col> */}
            </Row>
            {/* <Row gutter={16}>
          <Col sm={8} xs={8} md={8} lg={8}>
            <span className='type-font'> Type: </span>
            <RadioGroup
              onChange={e => this.handleOnChange('l_type', e.target.value)}
              value={location.l_type}
            >
              <Radio value='PICKUP'>PICKUP</Radio>
              <Radio value='DROP'>DROP</Radio>
            </RadioGroup>
          </Col>
        </Row> */}
            <Divider orientation="left">
              <sup className="textRed">*</sup>
              {I18n.t("general.address")}
            </Divider>
            {this.state.map &&
            (this.state.isNew || Object.keys(this.state.location).length) ? (
              <>
              <AddressSearch onSelect={ (address) => this.handleOnAddressChange("", "", address.l_address)} />
              <AddressForm
                autoComplete
                address={location.l_address}
                onChange={this.handleOnAddressChange}
                isAdmin
                twoRows
              />
              </>
            ) : (
              <Fragment />
            )}
            <Row>{FormErrors(this.state.errors)}</Row>
            <Row>
              <Col xs={24}>
                {FormButtons(
                  this.state.inProgress,
                  this.handleSave,
                  this.props.onCancel
                )}
              </Col>
            </Row>
          </div>
        );
  }

  render() {
    return (
      <div className="card-container tabHover">
        <Tabs
          type="card"
          activeKey={this.state.activeKey}
          onChange={this.goToTab}
        >
          <TabPane tab={I18n.t("general.details")} key="wh_details">
            {this._renderDetails()}
          </TabPane>
          {this.state.location && this.state.location.id && (
            <TabPane tab={I18n.t("general.config")} key="wh_config">
              <ConfigurationForm
                onSave={this.props.onSuccess}
                onCancel={this.hideConfigForm}
                location={this.state.location}
                warehouseFilter={this.props.warehouseFilter}
                organizationSettings={this.props.organizationSettings}
                organization_id={this.props.organization_id}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}


export const LocationFormComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const userContextData = useContext(UserContext);

  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <LocationForm
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContextData={userContextData}
      {...props}
    />
  );
});


LocationForm.propTypes = {
  location: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

LocationForm.defaultPropTypes = {
  location: {},
  isNew: true,
  zones: [],
};

export default LocationFormComponent;
