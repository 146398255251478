/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Button,
  Select,
  Radio,
} from '../../common/UIComponents'
import I18n from "../../common/I18n";
import SwitchComponent from '../../common/switchComponent';
import LabelInfo from "../configurations/LabelInfo"

const { Option } = Select;
const ConsigneeForm = ({
  config = {},
  handleOnInputChange,
  surveys = [],
  communicationPreferenceValues = {},
  handleCheckBtn,
  handleOnSelect,
}) => {
  const renderForm = () => (
    <Fragment>
      <Row>
        <div className="settingHeader">
          <span className="textBold">
            {I18n.t("configurations.headings.customer")}
          </span>
        </div>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.order_received_label")}
              <LabelInfo
                title={I18n.t("configurations.definitions.order_received_desc")}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.customer_order_received"),
                    value
                  )
                }
                isChecked={
                  config[I18n.t("configurations.customer_order_received")] ===
                  "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23}lg={9}>
              {I18n.t('configurations.appointment_confirmation_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) => handleCheckBtn(I18n.t("configurations.customer_appointment_confirmation"), value)}
                isChecked={config[I18n.t("configurations.customer_appointment_confirmation")] === "true"}
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.arrival_at_stop_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.arrival_at_stop_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.customer_arrival_at_stop"),
                    value
                  )
                }
                isChecked={
                  config[I18n.t("configurations.customer_arrival_at_stop")] ===
                  "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.order_dispatched_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.order_dispatched_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.customer_order_dispatched"),
                    value
                  )
                }
                isChecked={
                  config[I18n.t("configurations.customer_order_dispatched")] ===
                  "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.prior_day_notification_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.prior_day_notification_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.prior_day_notification_consignee"),
                    value
                  )
                }
                isChecked={
                  config[
                    I18n.t("configurations.prior_day_notification_consignee")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23}lg={9}>
              {I18n.t('configurations.one_stop_before_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Button
                className={
                  config.customer_one_stop_before &&
                  config.customer_one_stop_before ===
                    I18n.t('common.true')
                    ? 'btnConfig btnConfigActive'
                    : 'btnConfig'
                }
                onClick={() =>
                  handleCheckBtn(
                    I18n.t('configurations.customer_one_stop_before'),
                    I18n.t('common.true'),
                  )
                }
              >
                Yes
              </Button>
              <Button
                className={
                  config.customer_one_stop_before &&
                  config.customer_one_stop_before ===
                    I18n.t('common.false')
                    ? 'btnConfig btnConfigActive'
                    : 'btnConfig'
                }
                onClick={() =>
                  handleCheckBtn(
                    I18n.t('configurations.customer_one_stop_before'),
                    I18n.t('common.false'),
                  )
                }
              >
                No
              </Button>
            </Col>
          </Row>
        </Col>
        */}
      </Row>
      {/* <Row> */}
      {/* <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23}lg={9}>
              {I18n.t('configurations.near_by_location_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) => handleCheckBtn(I18n.t("configurations.customer_near_by_location"), value)}
                isChecked={config[I18n.t("configurations.customer_near_by_location")] === "true"}
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col> */}
      {/* </Row> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.order_delivered_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.order_delivered_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.customer_order_delivered"),
                    value
                  )
                }
                isChecked={
                  config[I18n.t("configurations.customer_order_delivered")] ===
                  "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.communication_preference")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.communication_preference_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} className="textBold">
              <Radio.Group
                onChange={(e) =>
                  handleCheckBtn("communication_preference", e.target.value)
                }
                value={config.communication_preference}
              >
                {Object.keys(communicationPreferenceValues).map((key) => (
                  <Radio value={key} key={key}>
                    {" "}
                    {communicationPreferenceValues[key]}
                  </Radio>
                ))}
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.survey_for_pickup_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.survey_for_pickup_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Select
                className="width100Per"
                onChange={(val) => handleOnSelect("pickup_feedback", val)}
                value={config.pickup_feedback ? config.pickup_feedback : ""}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "60%", marginRight: "10px" }}
              >
                <Option key="survey" value="">
                  -- None --
                </Option>
                {surveys.map((province) => (
                  <Option key={province.id} value={province.id}>
                    {province.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.survey_for_delivery_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.survey_for_delivery_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Select
                className="width100Per"
                onChange={(val) => handleOnSelect("feedback", val)}
                value={config.feedback ? config.feedback : ""}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "60%", marginRight: "10px" }}
              >
                <Option key="survey" value="">
                  -- None --
                </Option>
                {surveys.map((province) => (
                  <Option key={province.id} value={province.id}>
                    {province.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.consignee_email_editable_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.consignee_email_editable_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.consignee_email_editable_key"),
                    value
                  )
                }
                isChecked={
                  config[
                    I18n.t("configurations.consignee_email_editable_key")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.consignee_address_editable_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.consignee_address_editable_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.consignee_address_editable_key"),
                    value
                  )
                }
                isChecked={
                  config[
                    I18n.t("configurations.consignee_address_editable_key")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t(
                "configurations.consignee_secondary_contact_editable_label"
              )}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.consignee_secondary_contact_editable_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t(
                      "configurations.consignee_secondary_contact_editable_key"
                    ),
                    value
                  )
                }
                isChecked={
                  config[
                    I18n.t(
                      "configurations.consignee_secondary_contact_editable_key"
                    )
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.eta_configuration_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.eta_configuration_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.eta_configuration"),
                    value
                  )
                }
                isChecked={
                  config[I18n.t("configurations.eta_configuration")] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.group_notifications_for_consignee_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.group_notifications_for_consignee_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t("configurations.group_notifications_for_consignee"),
                    value
                  )
                }
                isChecked={
                  config[
                    I18n.t("configurations.group_notifications_for_consignee")
                  ] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
  return <Fragment>{renderForm()}</Fragment>;
};
export default ConsigneeForm;

ConsigneeForm.propTypes = {
  config: PropTypes.shape().isRequired,
  handleOnAddressChange: PropTypes.func.isRequired,
  handleOnInputChange: PropTypes.func.isRequired,
};
