import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Select, Tag, Col, DatePicker, Row } from "../common/UIComponents";

const MultipleDatePicker = (props) => {
  const { onHolidayChange = () => {}, holidaysDates: initialHolidaysDates,
    onWorkingDateChange = () => {}, workingDates: initialWorkingDates,
  } = props;

  const [holidaysDates, setHolidaysDates] = useState(initialHolidaysDates);
  const [workingDates, setWorkingDates] = useState(initialWorkingDates);

  useEffect(() => {
    setHolidaysDates(initialHolidaysDates);
  }, [initialHolidaysDates]);

  useEffect(() => {
    setWorkingDates(initialWorkingDates);
  }, [initialWorkingDates]);

  const handleHolidayChange = (momentDate) => {
    const formattedDate = momentDate.format("YYYY-MM-DD");
    const index = holidaysDates.findIndex((date) => date === formattedDate);
    const temp = [...holidaysDates];

    if (index !== -1) {
      temp.splice(index, 1);
    } else {
      temp.push(formattedDate);
    }
    setHolidaysDates(temp);
    onHolidayChange(temp);
  };

  const handleWorkingDateChange = (momentDate) => {
    const formattedDate = momentDate.format("YYYY-MM-DD");
    const index = workingDates.findIndex((date) => date === formattedDate);
    const temp = [...workingDates];

    if (index !== -1) {
      temp.splice(index, 1);
    } else {
      temp.push(formattedDate);
    }
    setWorkingDates(temp);
    onWorkingDateChange(temp);
  };

  const handleHolidayTagClose = (removedTag) => {
    const updatedDates = holidaysDates.filter((date) => date !== removedTag);
    setHolidaysDates(updatedDates);
    onHolidayChange(updatedDates);
  };

  const handleWorkingDateTagClose = (removedTag) => {
    const updatedDates = workingDates.filter((date) => date !== removedTag);
    setWorkingDates(updatedDates);
    onWorkingDateChange(updatedDates);
  };

  return (
    <div>
      <Row>
        <Col xs={24}>
          <div style={{ fontWeight: 700 }}> Exclude Date(s):</div>
          <Select
            placeholder="Select Excluded Date(s) from below calender"
            mode="tags"
            tokenSeparators={[","]}
            value={holidaysDates}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%", maxHeight: 58, overflowY: "auto" }}
            onChange={(value, option) => {
              setHolidaysDates(value);
              onHolidayChange(value);
            }}
          >
            {holidaysDates.map((rec) => (
              <Select.Option key={rec} value={rec}>
                {rec}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} style={{ marginTop: 20 }}>
          Select Exclude Date(s) :&nbsp;&nbsp;&nbsp;
          <DatePicker
            format="YYYY-MM-DD"
            onChange={handleHolidayChange}
            showTime={{ defaultValue: null }}
            allowClear={false}
            dateRender={(current) => {
              const style = {};
              if (
                holidaysDates?.length &&
                holidaysDates.includes(current.format("YYYY-MM-DD"))
              ) {
                style.border = "2px solid #1890ff";
                style.borderRadius = "50%";
                style.backgroundColor = "#f0f0f0";
              }
              return (
                <div className="ant-calendar-date" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />
          {/* <div>
            {selectedDates.length > 0 ? (
              <div>
                Selected Dates:
                <ul>
                  {selectedDates.map((date) => (
                    <li key={date}>
                      <Tag closable onClose={() => handleTagClose(date)}>
                        {date}
                      </Tag>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p>No dates selected</p>
            )}
          </div> */}
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <div style={{ fontWeight: 700 }}>Include Date(s):</div>
          <Select
            placeholder="Select Included Date(s) from below calendar"
            mode="tags"
            tokenSeparators={[","]}
            value={workingDates}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%", maxHeight: 58, overflowY: "auto" }}
            onChange={(value, option) => {
              setWorkingDates(value);
              onWorkingDateChange(value);
            }}
          >
            {workingDates.map((rec) => (
              <Select.Option key={rec} value={rec}>
                {rec}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} style={{ marginTop: 20 }}>
          Select Include Date(s) :&nbsp;&nbsp;&nbsp;
          <DatePicker
            format="YYYY-MM-DD"
            onChange={handleWorkingDateChange}
            showTime={{ defaultValue: null }}
            allowClear={false}
            dateRender={(current) => {
              const style = {};
              if (
                workingDates?.length &&
                workingDates.includes(current.format("YYYY-MM-DD"))
              ) {
                style.border = "2px solid #1890ff";
                style.borderRadius = "50%";
                style.backgroundColor = "#f0f0f0";
              }
              return (
                <div className="ant-calendar-date" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

MultipleDatePicker.propTypes = {
  onHolidayChange: PropTypes.func,
  holidaysDates: PropTypes.array,
  onWorkingDateChange: PropTypes.func,
  workingDates: PropTypes.array,
};

export default MultipleDatePicker;
