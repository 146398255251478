/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Icon,
  Select,
  Modal,
  Form,
  Spin,
} from "../../common/UIComponents";
import { alertMessage, checkNegitive, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import InvoiceContact from "./InvoiceContact";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import BaseModal from "../BaseModal";
import { fetchUsers } from "../../api/UsersApi";
import { UpdateDriver, fetchBillingDetails } from "../../api/Billing";
import { WarehouseContext } from "../../context/WarehouseContext";

const NewBillingDrivers = (props) => {
  const { invoiceStatus, currentBillingDriver, showActions, order } = props;

  const [currentDriverId, setCurrentDriverId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");
  const warehouseFilter = useContext(WarehouseContext);
  const [warehouses, setWarehouses] = useState([]);
  const [currentDriver, setCurrentDriver] = useState([...currentBillingDriver]);

  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
  }, [warehouseFilter.selectedWarehouses, warehouseFilter.warehouses]);

  useEffect(() => {
    setCurrentDriver([...currentBillingDriver]);
  }, [currentBillingDriver]);

  const currentWh =
    typeof warehouseFilter.selectedWarehouses === "string"
      ? _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses,
        })
      : _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses[0],
        });

  useEffect(() => {
    driversList();
  }, []);

  const driversList = () => {
    setInProgress(true);
    fetchUsers("driver")
      .then((result) => {
        if (result.success) {
          const updatedDrivers = result.users.map((driver) => ({
            id: driver.id,
            employee_code: driver.employee_code,
            full_name: driver.full_name,
          }));

          setDrivers(updatedDrivers);
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const handleDriverChnage = (value) => {
    setSelectedDriver(value);
  };

  const handleEdit = (id) => {
    setCurrentDriverId(id);
    setShowEditModal(true);
  };

  const handleSubmit = async () => {
    const orderIds = [order.customer_order_id];
    const payload = {
      order_ids: orderIds,
      old_driver_id: currentDriverId,
      new_driver_id: selectedDriver,
      warehouse_id: currentWh ? currentWh.id : "",
    };
    setInProgress(true);
    try {
      const updateResult = await UpdateDriver(payload);
      if (updateResult.success) {
        alertMessage("Driver Updated Successfully", "success", 10);
        setShowEditModal(false);
        const billingResult = await fetchBillingDetails(
          order.customer_order_id
        );
        if (billingResult.success) {
          const order_driver_details =
            billingResult.invoice.order_driver_details || [];
          const order_info = _.find(order_driver_details, {
            customer_order_id: order.customer_order_id,
          });
          const drivers_info = order_info?.driver_details
            ? order_info.driver_details
            : [];
          setCurrentDriverId("");
          setCurrentDriver(_.sortBy(drivers_info, "loc_order_sequence"));
          setSelectedDriver("");
        }
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      setInProgress(false);
    }
  };

  const handelCancel = () => {
    setShowEditModal(false);
    setCurrentDriverId("");
  };

  const renderEditModal = () => {
    return (
      <Modal
        title={"Change Driver"}
        onOk={handleSubmit}
        onCancel={() => {
          if (!inProgress) {
            handelCancel();
          }
        }}
        bodyStyle={{ padding: 10 }}
        visible={showEditModal}
        okButtonProps={{
          loading: inProgress,
        }}
        cancelButtonProps={{
          disabled: inProgress,
        }}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form>
                <span className="textBold">{I18n.t("general.Driver")}:</span>
                <Select
                  showSearch
                  value={selectedDriver ? selectedDriver : ""}
                  onChange={handleDriverChnage}
                  disabled={inProgress}
                  filterOption={(input, option) => {
                    if (option.props.children) {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  style={{ width: "80%", height: "20%", marginLeft: 10 }}
                >
                  <Select.Option key={"driver"} value={""}>
                    --select--
                  </Select.Option>
                  {drivers
                    .filter((driver) => driver.id !== currentDriverId)
                    .map((driver) => (
                      <Select.Option key={driver.id} value={driver.id}>
                        {driver.employee_code}
                      </Select.Option>
                    ))}
                </Select>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  return (
    currentDriver?.length > 0 && (
      <Fragment>
        {renderEditModal()}
        <Spin spinning={inProgress}>
          <Row>
            {!isEmpty(currentDriver) && currentDriver?.length && (
              <Col md={24} className="paddingLeft5 ">
                <Row>
                  {currentDriver.map((rec) => (
                    <Col md={24 / currentDriver.length} className="">
                      {rec.type_of_location && (
                        <div className="main_heading textBold fontSize13">
                          {rec.type_of_location}
                        </div>
                      )}
                      <div className="main_heading textBold fontSize13">
                        {rec.driver_name}
                        &nbsp;&nbsp;
                        {!["LH", "T"].includes(rec.type_of_order) &&
                          invoiceStatus ===
                            I18n.t("invoices.ready_for_billing") &&
                          showActions === true && (
                            <EditIcon
                              handleClick={() => handleEdit(rec.driver_id)}
                            />
                          )}
                      </div>
                      <div className="main_heading textBold fontSize13">
                        <Icon type="number" />
                        &nbsp;
                        {rec.driver_code}
                      </div>
                      <div className="fontSize12">
                        <InvoiceContact
                          data={rec}
                          showIcon
                          driverNo={rec.driver_number}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
          </Row>
        </Spin>
      </Fragment>
    )
  );
};

export default NewBillingDrivers;

NewBillingDrivers.propTypes = {};

NewBillingDrivers.defaultProps = {};
