import React, { Component, useContext } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';
import { Col, FormItem, Input, Row, DatePicker, Divider } from '../../common/UIComponents';
import {  alertMessage, doValidate, formatCode, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import FormButtons from '../common/FormButtons';
import { savePackage } from '../../api/Packages';
import FormErrors from '../common/FormErrors';
import AppConfig from '../../config/AppConfig';
import { OrgContext } from '../../context/OrgContext';
import { withRouter } from 'react-router';

const requiredFields = [{
  form_field: 'name',
  ui_name: I18n.t('general.name'),
  isRequired: true,
  inputType: 'name',
},
{
  form_field: 'code',
  ui_name: I18n.t('general.code'),
  isRequired: true,
  inputType: 'code',
},
];

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPackage: !this.props.isNew ? this.props.currentPackage : {},
      map: false,
      isNew: this.props.isNew || false,
      inProgress: false,
      errors: [],
      organizationSettings: {},
      isMilitaryTime: false,
    };
  }

  componentDidMount() {
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
  }
  handleOnChange = (element, value) => {
    this.setState({ currentPackage: Object.assign({}, this.state.currentPackage, { [element]: value }) });
  }

  handleCreditsOnChange = (element, value) => {
    const credits = this.state.currentPackage.credits ? this.state.currentPackage.credits : {};
    credits[element] = value;
    this.handleOnChange('credits', credits);
  }

  dateOnChange = (element, value, dateString) => {
    this.setState({ currentPackage: Object.assign({}, this.state.currentPackage, { [element]: value }) });
  }

  handleSave = () => {
    this.setState({ inProgress: true });
    const data = this.state.currentPackage;
    const errors = doValidate(requiredFields, data);
    if(isEmpty(data.access)){
      data.access = [];
    }
    if (errors.length === 0) {
      data.code = formatCode(data.code);
      savePackage(this.state.isNew, data)
        .then((result) => {
          if (result.success) {
            this.setState({ inProgress: false, errors: [] });
            this.props.onSuccess(I18n.t('messages.saved'));
          } else {
            alertMessage(result.errors, 'error', 10);
            this.setState({ errors: result.errors, inProgress: false });
          }
        });
    } else {
      this.setState({ errors, inProgress: false });
    }
  }

  render() {
    const { currentPackage , isMilitaryTime } = this.state;
    const credits= currentPackage.credits ? currentPackage.credits : {};
    return (
      <div>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t('general.name')} require>
              <Input
                value={currentPackage.name}
                onChange={e => this.handleOnChange('name', e.target.value)}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t('general.code')} require>
              <Input
                value={currentPackage.code}
                onChange={e => this.handleOnChange('code', e.target.value)}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem label={I18n.t('package.price')}>
              <Input
                value={currentPackage.price}
                onChange={e => this.handleOnChange('price', e.target.value)}
              />
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem label={`${I18n.t('package.package_validity')} (${I18n.t('package.in_days')})`}>
              <Input
                type="number"
                value={currentPackage.package_validity}
                onChange={e => this.handleOnChange('package_validity', e.target.value)}
                min={0}
                precision={0}
              />
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem label={I18n.t('package.live_on')}>
              <DatePicker
                format={isMilitaryTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD hh:mm A'}
                value={currentPackage.live_on}
                placeholder="Select Time"
                style={{ width: '100%'}}
                onChange={(value, dateString) => this.dateOnChange('live_on', value, dateString)}
                showTime={isMilitaryTime ? { format: "HH:mm", use12Hours : false} : { format: "hh:mm A" , use12Hours : true }}
              />
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem label={I18n.t('package.shutdown_on')}>
              <DatePicker
                value={currentPackage.shutdown_on}
                placeholder="Select Time"
                style={{ width: '100%'}}
                onChange={(value, dateString) => this.dateOnChange('shutdown_on', value, dateString)}
                format={isMilitaryTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD hh:mm A'}
                showTime={isMilitaryTime ? { format: "HH:mm", use12Hours : false} : { format: "hh:mm A" , use12Hours : true }}
              />
            </FormItem>
          </Col>
           <Col md={12}>
            <FormItem label={`${I18n.t('package.subscription_validity')} (${I18n.t('package.in_days')})`}>
              <Input
                type="number"
                value={currentPackage.subscription_validity}
                onChange={e => this.handleOnChange('subscription_validity', e.target.value)}
                min={0}
                precision={0}
              />
            </FormItem>
          </Col>
        </Row>
        <Divider orientation='left'>{I18n.t('package.credits')}</Divider>
        <Row gutter={16}>
          {
            AppConfig.package_credits.map(credit => (
              <Col md={8}>
              <FormItem label={I18n.t(`package.${credit}`)}>
              <Input
                type="number"
                value={currentPackage.credits && currentPackage.credits[credit] ? currentPackage.credits[credit] : ''}
                onChange={e => this.handleCreditsOnChange(credit, e.target.value)}
                min={0}
                precision={0}
              />
              </FormItem>
          </Col>
            ))
          }
        </Row>
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        <Row>
          <Col xs={24}>
            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)}
          </Col>
        </Row>
      </div>
    );
  }
}

Form.propTypes = {
  currentPackage: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Form.defaultPropTypes = {
  currentPackage: {},
  isNew: true,
};

 const PackageForm = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <Form
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default PackageForm;