import { Col, Descriptions, Icon, Row, Spin, Typography, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { ARApi } from "../../api/ARApi";
import TransactionsInfoList from "./TransactionsInfoList";
import { fetchAccount } from "../../api/Account";
import { defaultTransactionfilter } from "../dispatches/helpers";
import TransactionFilter from "./TransactionFilter";
import { alertMessage, checkNegitive, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";
import TransactionsCheckList from "./TranscationCheckList";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
const TransactionsInfo = (props) => {
  const defaultFilter = defaultTransactionfilter(props);
  const [transcationDetails, setTranscationDetails] = useState([]);
  const [checkDetailsInfo, setCheckDetailsInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({});
  const account_id = props.match.params.accountId;
  const ar_batch_check_id = props.match.params.checkId;
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    ...defaultFilter,
  });
  const [pagination, setPagination] = useState({ current_page: 1 });


  useEffect(() => {
    tableOptions.pagination.current = 1;
    getAccountDetails();
    fetchTranscationDetails();
  }, []);

  useEffect(() => {
    fetchTranscationDetails();
  }, [filterPlaceHolder]);

  const updateFilterSearchValues = (filter) => {
    tableOptions.pagination.current = 1;
    setFilterPlaceHolder(filter);
  };

  const handleGoBack = () => {
    // props.history.push({ pathname: `/view_check_details/${account_id}` });
    const fromPath = localStorage.getItem("lastPath");
    if (fromPath.includes("manage_checks")) {
      props.history.push({ pathname: `/manage_checks` });
    } else {
      props.history.push({ pathname: fromPath });
    }
  };

  const getAccountDetails = () => {
    const accountId = props.match.params.accountId;
    setLoading(true);
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        const account = result.account || [];
        setCurrentAccount(account);
        setLoading(false);
      } else {
        renderAlertMessage(result.errors)
        setCurrentAccount({});
        setLoading(false);
      }
    });
  };

  const fetchTranscationDetails = () => {
    setLoading(true);
    const payLoad = {};
    if (filterPlaceHolder.search_by_type && filterPlaceHolder.search_value) {
      payLoad.search_by_type = filterPlaceHolder.search_by_type; //check_date
      payLoad.search_value = filterPlaceHolder.search_value;
    }
    if(!isEmpty(filterPlaceHolder.sortBy) && !isEmpty(filterPlaceHolder.sortByType)){
      payLoad.sort_by = filterPlaceHolder.sortBy;
      payLoad.sort_order = I18n.t(`general.sort_${filterPlaceHolder.sortByType}`);
    }
    payLoad.page = tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    payLoad.per_page = AppConfig.perPage;
    ARApi.fetchCheckDetailsByCheckNo(
      account_id,
      ar_batch_check_id,
      payLoad
    ).then((result) => {
      if (result.success) {
        setTranscationDetails(result.data.ar_check_transactions);
        setCheckDetailsInfo(result.data.ar_batch_check);
        setPagination(result.pagination)
        setLoading(false);
      } else {
        setLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }
    if (tableOptions.pagination.current !== currentPage) {
      fetchTranscationDetails();
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
    }
  }

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || AppConfig.perPage,
  };

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <span onClick={handleGoBack}>
            <Icon type="arrow-left" style={{ cursor: "pointer" }} />
          </span>
          &nbsp;&nbsp;
          <Col xs={12}>
            {I18n.t("batch_management.transactions_details")}
            &nbsp;
            {!_.isEmpty(checkDetailsInfo) &&
              `${"#"}${checkDetailsInfo?.check_ach_no}`}
            &nbsp; ({currentAccount?.name ? `${currentAccount.name} ` : ""})
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col>
              <Row type="flex" gutter={8}>
                <Col offset={2} span={10}>
                  <TransactionFilter
                    filterPlaceHolder={filterPlaceHolder}
                    defaultFilter={{ ...defaultFilter }}
                    setFilterPlaceHolder={updateFilterSearchValues}
                  />
                </Col>
                {!_.isEmpty(checkDetailsInfo) && (
                  <Col span={11}>
                    <Typography>
                      <Typography.Text strong>
                        Check/ACH Details :
                      </Typography.Text>{" "}
                      <Typography.Text>
                        {checkDetailsInfo ? checkDetailsInfo.check_ach_no : ""}
                      </Typography.Text>
                      <Divider type="vertical" />
                      <Typography.Text strong>M.O.P:</Typography.Text>{" "}
                      <Typography.Text>
                        {checkDetailsInfo ? checkDetailsInfo.pay_mode : ""}
                      </Typography.Text>
                      <Divider type="vertical" />
                      <Typography.Text strong>
                        Amount Received:
                      </Typography.Text>{" "}
                      <Typography.Text>
                        {checkNegitive(checkDetailsInfo?.received_amount)}
                      </Typography.Text>
                    </Typography>
                    {/* <div className="marginTop10">
                      <Typography.Text strong>Warehouse:</Typography.Text>{" "}
                      <Typography.Text>
                        {checkDetailsInfo ? checkDetailsInfo.wh_name : ""}
                      </Typography.Text>
                    </div> */}
                  </Col>
                )}
              </Row>
              {/* <Row type="flex" justify="end">;
                    <Col>
                      <RecordsPerPage
                        onChange={onRecordChange}
                        value={recordsPerPage}
                        defaultValue={recordsPerPage}
                      />
                    </Col>
                  </Row> */}
              <Col>
                {/* <TransactionsInfoList
                  showEdit={false}
                  showDelete={false}
                  data={transcationDetails ? transcationDetails : []}
                  scroll={{ x: "max-content" }}
                  hideColumns={[
                    "batchNo",
                    "checkAchNo",
                    "modeOfPayement",
                    "warehouse",
                  ]}
                  pagination={tablePagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    onTableChange(pagination, filter, sorter, currentTable)
                  }
                /> */}
                <TransactionsCheckList
                  data={transcationDetails ? transcationDetails : []}
                  scroll={{ x: "max-content" }}
                  pagination={tablePagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    onTableChange(pagination, filter, sorter, currentTable)
                  }
                />
              </Col>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default withRouter(TransactionsInfo);
