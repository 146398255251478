/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _, { isEmpty, result } from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Card,
  Select,
  Spin
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  formatCode,
  checkNegitive,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import { loadGoogleMaps } from "../../utils/Utils";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { BatchPaymentApi } from "../../api/BatchPaymentApi";
import { fetchShortFormAccounts } from "../../api/Account";
import PaymentsForm from "./Payments";
import BaseModal from "../BaseModal";
import userStore from "../../stores/UserStore";
import PaymentsList from "./PaymentsList";
import { CreditNotesApi } from "../../api/CreditNotesApi";
import BaseList from "../BaseList";
import CopyableContent from "../common/CopyableContent";
import { MemoDescriptionApi } from "../../api/MemoDesc";
import { renderAlertMessage } from "../../helpers/common";

const { TextArea } = Input;

const requiredFields = [
  {
    form_field: "wh_id",
    ui_name: I18n.t("location.location"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "account_id",
    ui_name: I18n.t("account.account"),
    isRequired: true,
    inputType: "text",
  },
];

const ExtraAmountsList = ({ records = [], pagination = {}, rowSelection={} }) => {
  const columns = [
    {
      title: I18n.t("invoices.number"),
      dataIndex: "account_invoice_number",
      key: "account_invoice_number",
      render: (data) => <CopyableContent text={data} />,
    },
    {
      title: I18n.t("credit_notes.invoice_extra_paid_amount"),
      dataIndex: "invoice_extra_paid_amount",
      key: "invoice_extra_paid_amount",
      render: (data) => (data ? checkNegitive(data) : ""),
      align: "right",
    },
  ];
  return (
    <BaseList
      rowKey={"account_invoice_id"}
      columns={columns}
      rowSelection={rowSelection}
      data={records}
      size="small"
      pagination={{ ...pagination }}
      scroll={{ y: 200 }}
    />
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRec: this.props.currentRec ? this.props.currentRec : { wh_id: props.defaultWH},
      warehouses: props.warehouses ? props.warehouses : [],
      isNew: this.props.isNew,
      orgId: this.props.org_id,
      inProgress: false,
      errors: [],
      accounts: [],
      showForm: false,
      extraPaidInvoices: [],
      approvalDescriptions: [],
      memo_desc : "",
      selectedInfo: {
        rows: [],
        keys: [],
      },
      accountsProgess: false,
      listProgress: false,
      customMemoDesc: "",
      isMemoLoading: false
    };
  }

  handleOptionChange = (element, value) => {
    if (element === "customMemoDesc") {
      this.setState({ [element]: value },()=>{
        this.handleOnChange("credit_note", value);
      })
    } else {
      this.setState({ [element]: value }, () => {
        if(value === "CUSTOM"){
          this.handleOnChange("credit_note", "");
        }else{
          this.handleOnChange("credit_note", value);
          this.setState({ customMemoDesc: "" })
        }
      });
    }
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedInfo: {
        rows: [...selectedRows],
        keys: [...selectedRowKeys],
      }
    })
  };

  getAccounts = () => {
    const { currentRec } = this.state;
    this.setState({
      accountsProgess: true,
    })
    fetchShortFormAccounts(this.state.orgId, currentRec.wh_id, true).then(
      (result) => {
        if (result.success) {
          this.setState({
            accounts: result.accounts,
            accountsProgess: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ accountsProgess: false });
        }
      }
    );
  };


  getMemoDescriptions = () => {
    this.setState({ isMemoLoading: true });
    const organization_id = this.state.orgId;
    MemoDescriptionApi.fetchMemoDescription(organization_id).then((result) => {
      if (result.success) {
        const MemoDescriptions = result.memo_descriptions || [];
        this.setState({ approvalDescriptions: MemoDescriptions, isMemoLoading: false  });
      } else {
        this.setState({ isMemoLoading: false })
        alertMessage(result.errors[0], 'error', 10);
      }
    });
  };

  getExtraPaidInvoices = () => {
    const { currentRec } = this.state;
    if(!isEmpty(currentRec.wh_id) && !isEmpty(currentRec.account_id)){
      this.setState({
        listProgress: true,
      });
      CreditNotesApi.getExtraPaidInvoices({
        organization_id: this.state.orgId,
        account_id: currentRec.account_id,
        warehouse_id: currentRec.wh_id,
      }).then((result) => {
        if (result.success) {
          this.setState({
            listProgress: false,
            extraPaidInvoices: result.extra_paid_invoices || [],
          });
        } else {
          this.setState({
            listProgress: false,
            extraPaidInvoices: [],
          });
          renderAlertMessage(result.errors)
        }
      });
    }
  };


  showFormWindow = (rec = {}) => {
    this.setState({
      showForm: true,
      currentPayment: { ...rec },
    });
  };

  componentDidMount() {
    if (this.state.currentRec.wh_id) {
      this.getAccounts();
    } else {
      const { warehouses } = this.state;
      if (this.state.warehouses.length > 0) {
        this.setState({
          currentRec: { ...this.state.currentRec, wh_id: warehouses[0].id },
        }, () => {
          this.getAccounts();
        });
      }
    }
    this.getMemoDescriptions()
  }

  clearForm = () => {
    this.setState({
      currentRec: {},
      isNew: true,
    });
  };

  handleOnChange = (element, value) => {
    const { wareHouses } = this.state;
    const currentRec = Object.assign({}, this.state.currentRec);
    currentRec[element] = value;
    if(element === 'wh_id'){
      currentRec['account_id'] = '';
    }
    this.setState(
      {
        currentRec,
        errors: [],
      },
      () => {
        if (["account_id"].includes(element)) {
          if(isEmpty(value)) {
            this.setState({
              extraPaidInvoices: [],
            })
            this.handleSelectChange([],[]);
          } else {
            this.getExtraPaidInvoices();
          }
        }
        if(element === "wh_id"){
          this.handleSelectChange([],[]);
          if(isEmpty(value)) {
            this.setState({
              extraPaidInvoices: [],
              accounts: [],
            })
          } else {
            this.setState({
              extraPaidInvoices: [],            
            })
            this.getAccounts();
          }
        }
      }
    );
  };

  handleSave = () => {
    const { currentUser } = this.props.userContext;
    const { _id: userId} = currentUser;
    const { currentRec, accounts, warehouses, selectedInfo } = this.state;
    const errors = doValidate(requiredFields, currentRec);
    if (errors.length === 0) {
      const currentWH = _.find(warehouses, { id: currentRec.wh_id });
      const currentAccount = _.find(accounts, { id: currentRec.account_id });
      const data = {
        organization_id: this.state.orgId,
        payment_details: [],
        credit_note: currentRec.credit_note,
        account_id: currentRec.account_id,
        account_name: currentAccount?.name ? currentAccount.name : "",
        account_code: currentAccount?.code ? currentAccount.code : "",
        wh_id: currentRec.wh_id,
        wh_name: currentWH?.name ? currentWH.name : "",
        ar_check_invoice_ids: [],
        user_id : userId,
      };
      data.payment_details = selectedInfo.rows.map(rec => {
        data.ar_check_invoice_ids.push(rec.ar_check_ids)
        return ({
        account_invoice_id: rec.account_invoice_id,
        account_invoice_number: rec.account_invoice_number,
        invoice_credit_amount: rec.invoice_extra_paid_amount
      })
    })
      data.ar_check_invoice_ids = _.compact(_.flattenDeep(data.ar_check_invoice_ids))
      this.setState({ inProgress: true });
      if (!this.state.isNew) {
        data.id = currentRec.id;
      }
      CreditNotesApi.manageCreditNote(data, this.state.isNew).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({
            errors: [],
            isNew: false,
            inProgress: false,
            currentRec: {}, //result.credit_note || {},
            selectedInfo:{
              rows: [],
              keys: []
            }
          });
          this.props.closeFormOnSubmit()
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  renderForm = () => {
    const { currentRec, warehouses, accounts, extraPaidInvoices, selectedInfo, inProgress } = this.state;
    const isDisabled = selectedInfo.keys.length === 0 || inProgress; //currentRec.status === 'POSTED';
    const rowSelection = {
      selectedRowKeys: selectedInfo.keys,
      onChange: this.handleSelectChange,
      getCheckboxProps: null,
    };
    return (
      <div>
        <Row>
          <Col>
            <Row>
              <Col>
                {/* <FormItem label={I18n.t("location.location")} require>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => this.handleOnChange("wh_id", value)}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={currentRec.wh_id}
                  >
                    {warehouses.map((warehouse) => (
                      <Select.Option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem> */}
              </Col>
              <Col>
                <FormItem
                  label={
                    <Fragment>
                      <span>{I18n.t("account.account")}</span>
                    </Fragment>
                  }
                  require
                >
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => this.handleOnChange("account_id", value)}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    size="small"
                    dropdownMatchSelectWidth={false}
                    spinning={this.state.accountsProgess}
                    value={currentRec.account_id}

                  >
                    <Select.Option key="account_code" value="">
                      -- Select --
                    </Select.Option>
                    {accounts.map((account) => (
                      <Select.Option key={account.id} value={account.id}>
                        {`${account.name} (${account.code})`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <FormItem label={I18n.t("credit_notes.memo_descr")}>
              <Select
                onChange={(value) =>
                  this.handleOptionChange("memo_desc", value)
                }
                value={this.state.memo_desc}
                style={{ width: "100%" }}
                loading={this.state.isMemoLoading}
              >
                {this.state.approvalDescriptions.map((message) => (
                  <Select.Option
                    key={message.id}
                    value={message.memo_desc_message}
                  >
                    {message.memo_desc_message}
                  </Select.Option>
                ))}
                <Select.Option value="CUSTOM">
                  {I18n.t("general.custom_message")}
                </Select.Option>
              </Select>
              {this.state.memo_desc === "CUSTOM" && (
                <TextArea
                  onChange={(e) =>
                    this.handleOptionChange("customMemoDesc", e.target.value)
                  }
                  value={this.state.customMemoDesc}
                  style={{ marginTop: 5 }}
                  rows={3}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  placeholder={I18n.t("general.message")}
                  className="sop-form-textArea"
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24}>
            <Spin spinning={this.state.listProgress}>
              <h4>{I18n.t('menu.invoices')}</h4>
              <ExtraAmountsList
                records={extraPaidInvoices || []}
                pagination={{ position: "none" }}
                rowSelection={rowSelection}
              />
            </Spin>
          </Col>
          <Col xs={24} style={{ height: 120, paddingTop: 45 }}>
            {!isDisabled && (
              <Row className="">
                <Col className="alignCenter">
                  <Button
                    size={"small"}
                    // type="danger"
                    disabled={isDisabled}
                    onClick={this.props.onCancel}
                    className="marginRight5"
                    icon='close'
                  >
                    {I18n.t("general.cancel")}
                  </Button>
                  <Button
                    size={"small"}
                    type="primary"
                    disabled={isDisabled}
                    onClick={this.handleSave}
                    className="marginRight5"
                    icon='save'
                  >
                    {I18n.t("general.save")}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  };

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

Form.propTypes = {
  currentRec: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const CreditNoteForm = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const organizationSettings = useContext(OrgContext);
  return (
    <Form
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default CreditNoteForm;
