import { Call, ApiUrl,  ParseGeneralResponse } from './ApiUtils';

export function fetchSecondItemPrice(orgId) {
  const url = ApiUrl(`v2/discount/get_second_item_price?account_id=${orgId}&operation_code=GSIP`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { second_item_price: ''},
  });
}

export function saveSecondItemPrice(data) {
  data.operation_code = "SSIP";
  const url = ApiUrl('v2/discount/save_second_item_price');
  return Call('put', url, data , ParseGeneralResponse, { responseDataKeys: { second_item_price: '' } });
}