import React from "react";
import BaseList from "../../BaseList";
import { Popconfirm, Icon, Tooltip } from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";

class TemplateParamsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "code",
        title: I18n.t("template_params.code"),
        dataIndex: "code",
        width: 250,
      },
      {
        key: "description",
        title: I18n.t("template_params.description"),
        dataIndex: "description",
      },
      {
        key: "actions",
        title: "",
        dataIndex: "id",
        fixed: "right",
        render: (data, record) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
            <EditIcon handleClick={() => this.props.editClick(data)}/>
            &nbsp;
            </Tooltip>
            <Popconfirm
              placement="left"
              title={I18n.t("template_params.Delete")}
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.props.deleteClick(data)}
            >
              <Tooltip title={I18n.t("general.delete")} placement="right">
              <DeleteIcon/>
              &nbsp;&nbsp;&nbsp;
              </Tooltip>
            </Popconfirm>
          </div>
        ),
        width: 75,
      },
    ];
  }
}

export default TemplateParamsList;
