/* */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polygon } from 'react-google-maps';
import MainMap from '../common/Map';
import MapCustomControl from '../common/MapCustomControl';
import Form from './Form';
import AppConfig from '../../config/AppConfig';

const {
  DrawingManager,
} = require('react-google-maps/lib/components/drawing/DrawingManager');

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onDraw: !props.currentZone.id,
      map: '',
    };
    this.onMapMounted = this.onMapMounted.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.onDraw !== nextProps.onDraw) {
      this.setState({
        onDraw: nextProps.onDraw,
      });
    }
  }
  onMapMounted(mapObj) {
    if (!this.state.map) {
      const map = this.state.map ? this.state.map : mapObj;
      if (this.props.currentZone.location) {
        const bounds = new window.google.maps.LatLngBounds();
        this.props.currentZone.location.forEach((point) => {
          bounds.extend(new window.google.maps.LatLng(point[0], point[1]));
        });
        map.fitBounds(bounds);
      }
      this.setState({
        map,
      });
    }
  }
  render() {
    return (
      <MainMap
        {...this.props}
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
        containerElement={<div style={{ width: '100%', height: '100%' }} />}
        mapElement={<div style={{ width: '100%', height: '85vh' }} />}
        onMapMounted={this.onMapMounted}
        defaultZoom={8}
        defaultCenter={{ lat: 30.9873867, lng: -97.472588 }}
      >
        <div >
          {
            this.state.map ?
              // eslint-disable-next-line no-undef
              <MapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}>
                <Form
                  zoneName={this.props.zoneName}
                  handleChange={this.props.handleChange}
                  handleReDraw={this.props.handleReDraw}
                  onDraw={this.props.onDraw}
                  {...this.props}
                  handleOnSave={this.props.handleOnSave}
                />
              </MapCustomControl> :
              <Fragment />
          }

        </div>
        {
          this.props.currentZone.location && !this.state.onDraw ?
            <Fragment>
              <Polygon
                path={this.props.currentZone.location.map(point => ({ lat: point[0], lng: point[1] }))}
                key={1}
                options={{
                  fillColor: '#2E2E2E',
                  fillOpacity: 0.4,
                  strokeColor: '#2E2E2E',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
                onClick={() => {
                }}
              />
            </Fragment> : <Fragment />
        }
        {
          this.state.onDraw && this.state.map ?
            <Fragment>
              <DrawingManager
                // eslint-disable-next-line no-undef
                drawingMode={google.maps.drawing.OverlayType.POLYGON}
                defaultOptions={{
                  drawingControl: false,
                  drawingControlOptions: {
                    // eslint-disable-next-line no-undef
                    position: google.maps.ControlPosition.TOP_LEFT,
                    drawingModes: [
                      // eslint-disable-next-line no-undef
                      google.maps.drawing.OverlayType.POLYGON,
                    ],
                  },
                  circleOptions: {
                    fillColor: '#ffff00',
                    fillOpacity: 1,
                    strokeWeight: 5,
                    clickable: false,
                    editable: true,
                    zIndex: 1,
                  },
                }}
                onPolygonComplete={(polygon) => {
                  const polygonArray = polygon.getPath().getArray();
                  const geoFenceGeometryData = polygonArray.map(point => ([point.lat(), point.lng()]));
                  this.props.handleSave({ geoFenceGeometryData, ...this.props });
                }}
              />
            </Fragment> : <Fragment />
        }

      </MainMap>
    );
  }
}

Map.propTypes = {
  handleSave: PropTypes.func.isRequired,
  currentZone: PropTypes.shape(),
  onDraw: PropTypes.bool,
  zoneName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReDraw: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
};
Map.defaultProps = {
  currentZone: {},
  onDraw: false,
};

export default Map;
