import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
import { isEmpty } from "../common/Common";

export function fetchUSEnergyinfo(FromDate, ToDate, Region) {
  let url = `v2/fuel_surcharges/us_energy_info?from_date=${FromDate}&to_date=${ToDate}&operation_code=USEI`;
  if (!isEmpty(Region)) {
    url = `${url}&region=${Region}`;
  }

  url = ApiUrl(url);

  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { us_energy_info: "us_energy_info" },
    },
    false
  );
}

export function fetchFuelSurchargeRegions() {
  const url = ApiUrl(`v2/fuel_surcharges/regions?operation_code=FSCR`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { fuel_surcharge_regions: "fuel_surcharge_regions" },
  }, false);
}

