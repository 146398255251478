import React, { Component, Fragment } from "react";
import { Form, Row, Col, Select, Input, Button, Alert, Radio } from "antd";
import PropTypes from "prop-types";
import userStore from "../../stores/UserStore";
import { fetchAccountDims } from "../../api/Account";
import AppConfig from "../../config/AppConfig";
import { createItem, updateItem } from "../../api/OrdersApi";
import { alertMessage, isEmpty } from "../../common/Common";
import _ from "lodash";
import I18n from "../../common/I18n";
const { Option } = Select;

class DIMSInput extends React.Component {
  handleNumberChange = (e, element )=> {
    const number = parseInt(e.target.value || 0, 10);
    if (isNaN(number)) {
      return;
    }
    this.triggerChange({ [element]: number });
  };

  // handleCurrencyChange = currency => {
  //   this.triggerChange({ currency });
  // };

  triggerChange = changedValue => {
    const { onChange, value,handleDimCalculation } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
    if(handleDimCalculation){
      handleDimCalculation('dim',{
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { size, value,length } = this.props;
    return (
      <span>
        L:&nbsp;
        <Input
          type="text"
          size={size}
          value={value.item_length}
          onChange={(e) => this.handleNumberChange(e, 'item_length')}
          style={{ width: 58, marginRight: 3 }}
        />
        X&nbsp;
        W:&nbsp;
        <Input
          type="text"
          size={size}
          value={value.item_width}
          onChange={(e) => this.handleNumberChange(e, 'item_width')}
          style={{ width: 58, marginRight: 3 }}
        />
        X&nbsp;
        H:&nbsp;
        <Input
          type="text"
          size={size}
          value={value.item_height}
          onChange={(e) => this.handleNumberChange(e, 'item_height')}
          style={{ width: 58, marginRight: 3 }}
        />
        <Select
          value={length}
          defaultValue={"inch"}
          size={size}
          style={{ width: '18%' }}
          onChange={this.props.handleLengthChange}
          dropdownMatchSelectWidth={false}
        >
          <Option value="inch">inch</Option>
          <Option value="cm">cm</Option>
          <Option value="feet">feet</Option>
        
        </Select>
      </span>
    );
  }
}
class ItemDetails extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isEditing: false,
      item_type: "",
      accountDim: {},
      isLoading: false,
      customer_order_id: "",
      uom: "lbs",
      length: "inch",
      weight_type: "individual"
    };
  }

  componentDidMount () {
    this.fetchAccountConfigs();
    if (!this.state.isEditing) {
      this.assignItemType();
    }
  }

  handleLengthChange = (value) => {
    this.setState({ length: value });
  }

  convertLengthToInches = (e, length) => {
    let inches;
  
    switch (length) {
        case 'inch':
            inches = e;
            break;
        case 'cm':
            inches = (e * 0.393701).toFixed(2);
            break;
        case 'feet':
            inches = (e * 12).toFixed(2);
            break;
        default:
            console.log('Invalid length unit');
            return;
    }
    return inches;
}

  fetchAccountConfigs () {
    if (Object.keys(this.props.account).length > 0) {
      this.getAccountConfigs();
    }
  }

  componentDidUpdate (prevProps) {
    if ((prevProps.account !== this.props.account)) {
      this.fetchAccountConfigs();
    }
    if (this.state.isNew && prevProps.order !== this.props.order) {
      this.assignItemType();
    }

    if (prevProps.currentItem !== this.props.currentItem && Object.keys(this.props.currentItem).length > 0) {
      this.setState({
        isEditing: true,
        item_type: this.props.currentItem.item_type,
        customer_order_id: this.props.currentItem.customer_order_id,
      });
      const {
        item_name,
        item_model,
        serial_number,
        wh_dock,
        dim_factor,
        item_height,
        item_quantity,
        item_weight,
        item_width,
        item_length,
        dim_weight,
        is_indv_weight
      } = this.props.currentItem;
      const weight_type = isEmpty(is_indv_weight) || is_indv_weight == 1 ? "individual" : "complete";
      const formVals = {
        item_name,
        item_model,
        serial_number,
        wh_dock,
        dim_factor,
        item_height,
        item_quantity,
        item_weight,
        item_width,
        item_length,
        dim_weight,
        weight_type
      };
      this.props.form.setFieldsValue(formVals);
    }
  }

  handleOnChange = (element, value) => {
    let dimWeight = 0;
    const { form } = this.props;
    const { getFieldsValue, getFieldValue } = this.props.form;
    const {
      dim_factor = 1,
      item_width = 1,
      item_height = 1,
      item_length = 1,
      item_weight = 0,
      item_quantity = 1,
      weight_type = "complete"
    } = getFieldsValue();
    const dim = getFieldValue('dim');
    let itemLength = _.get(dim, 'item_length', 1);
    let itemWidth = _.get(dim, 'item_width', 1);
    let itemHeight = _.get(dim,'item_height',1);
    let dimFactor = dim_factor;
    let itemQuantity = item_quantity || 1;
    let itemWeight= item_weight

    if (element === "item_length") {
      itemLength = value;
    }
    if (element === "item_width") {
      itemWidth = value;
    }
    if (element === "item_height") {
      itemHeight = value;
    }
    if (element === "dim_factor") {
      dimFactor = value;
    }
    if (element === "item_quantity") {
      itemQuantity = value;
    }
    if (element === "item_weight") {
      itemWeight = value
    }

      if (!isEmpty((itemWidth)) || !isEmpty((itemHeight)) || !isEmpty((itemLength))
      && !isEmpty(dimFactor) && !isEmpty(itemQuantity)) {
      const configuredMinWeight = !isEmpty(this.state.accountDim.min_weight)
        ? this.state.accountDim.min_weight
        : 0;
      if ((configuredMinWeight === 0 || configuredMinWeight < itemWeight) && dim_factor > 0) {
        let dimsWeight = ((itemWidth * itemHeight * itemLength) / dimFactor).toFixed(2);
        dimWeight = itemQuantity * Math.ceil(dimsWeight);
      }
    }
    form.setFieldsValue({ dim_weight: dimWeight });
  };

  // calculateDimValues = () => {
  //   let dimWeight = 0;
  //   const { form } = this.props;
  //   const { getFieldsValue, getFieldValue } = this.props.form;
  //   const {
  //     dim_factor = 1,
  //     item_width = 1,
  //     item_height = 1,
  //     item_length = 1,
  //     item_weight = 0,
  //   } = getFieldsValue();

  //   if (!isEmpty(getFieldValue('item_width')) || !isEmpty(getFieldValue('item_height')) || !isEmpty(getFieldValue('item_length'))
  //     && !isEmpty(getFieldValue('dim_factor'))) {
  //     const configuredMinWeight = !isEmpty(this.state.accountDim.min_weight)
  //       ? this.state.accountDim.min_weight
  //       : 0;
  //     if (configuredMinWeight < item_weight && dim_factor > 0) {
  //       dimWeight = ((item_width * item_height * item_length) / dim_factor);
  //     }
  //   }
  //   form.setFieldsValue({ dim_weight: dimWeight });
  // };

  assignItemType = () => {
    const { order, form } = this.props;
    if (order.type_of_order !== "X") {
      const orderType = order.type_of_order === "R" || order.type_of_order === "M" ? "RETURN" : order.type_of_order === "D" ? "DELIVERY" : "";
      this.setState({ item_type: orderType });

    }
  };

  getAccountConfigs = () => {
    const { account, form } = this.props;
    const orgId = userStore.getStateValue("selectedOrg");
    const accountId =
      account.primary_account_id || account.id;
    fetchAccountDims(orgId, accountId).then((result) => {
      if (result.success) {
        const accountDims = result.configurations || [];
        const accountDim = accountDims.length > 0 ? accountDims[ 0 ] : {};
        const { dim_factor } = accountDim;
        const { getFieldValue } = this.props.form;
        if (isEmpty(
          getFieldValue("dim_factor")
        )) {
          form.setFieldsValue({ dim_factor });
        }
        this.setState(
          {
            accountDim,
          }
        );
      }
    });
  };

  getOrderID = () => {
    const { order, form, orderConfig } = this.props;
    const { item_type } = this.state;
    const currentItem = form.getFieldsValue();
    let orderId = "";
    let itemType = "";
    if (order.type_of_order === "T") {
      // itemType = item_type === "RETURN" ? "TR" : "TD";
      itemType =  "T";
    } else if (order.type_of_order === "LH") {
      itemType =  "LH";
    } else if (order.type_of_order === "X") {
      itemType = "X";
    }else if(order.type_of_order === "M"){
      itemType = item_type === "RETURN" ? "TR" : "TD";
    } else {
      itemType = item_type === "RETURN" ? "R" : "D";
    }
    const detailsObj = _.find(orderConfig.orderTypes[order.type_of_order].types, {
      type_of_order: itemType,
    });
    if (!isEmpty(detailsObj)) {
      orderId = order[detailsObj.key] ? order[detailsObj.key].id : "";
    }
    return orderId;
  };

  checkLastItem = () => {
    const { items = [], order } = this.props;
    const { isEditing } = this.state;
    return !isEditing && order.quantity - 1 === items.length;
  };

  handleUOMChange = (value) => {
    this.setState({ uom: value });
  }

  convertWeightToLbs = (weight, uom) => {
    if (uom === 'kg') {
      return (Number(weight) * 2.20462262185).toFixed(2); // Convert kgs to lbs
    }
    if (uom === 'g') {
      return (Number(weight) * 0.00220462262185).toFixed(2); // Convert grams to lbs
    }
    if (uom === 'lbs'){
      return (Number(weight) * 1).toFixed(2);
    }
    if (typeof weight === 'number') {
      return weight.toFixed(2); 
    }
    // return "0.00"; 
  }


  handleCreateItem = (vals) => {
    const { account } = this.props;
    const item_weight = vals.item_weight;
    const weightInLbs = this.convertWeightToLbs(item_weight, this.state.uom); 
    const lengthInInches = this.convertLengthToInches(vals.dim.item_length,this.state.length)
    const widthInInches = this.convertLengthToInches(vals.dim.item_width, this.state.length)
    const heightInInches = this.convertLengthToInches(vals.dim.item_height, this.state.length)
    const dimObject = _.pick(vals.dim, ['item_width', 'item_height', 'item_length']);
    let currentItem = {...vals, 
      item_width:widthInInches,
      item_length:lengthInInches,
      item_height:heightInInches ,
      item_weight:weightInLbs,
      item_quantity: vals.item_quantity || 1
    };
    delete currentItem.dim;
    currentItem.account_id = account.id;
    this.setState({ isLoading: true });
    const isLastItem = this.checkLastItem();
    if (isEmpty(currentItem.customer_order_id)) {
      currentItem.customer_order_id = this.getOrderID();
    }
    currentItem.item_type = this.state.item_type;
    createItem(currentItem, !this.state.isEditing).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        const savedItems = result.order_items || [];
        const items = savedItems.map((item) =>
          Object.assign({}, item, { 
            item_id: item.id,
            item_weight: item_weight,
            total_weight: item.item_weight,
          })
        );
        this.setState(
          {
            currentItem: {},
            isEditing: false,
          },
          () => {
            this.assignItemType();
          }
        );
        this.props.saveItem(items, isLastItem, result.order_area, result.order_quantity, result.order_weight);
        this.props.form.resetFields();
        this.setState({ uom: "lbs" })
      } else {
        alertMessage(result.errors, "error", 10);
      }
    }).finally(() => { this.setState({ isLoading: false }); });
  };

  handleSaveItem = (vals) => {
    const item_weight = vals.item_weight;
    const weightInLbs = this.convertWeightToLbs(item_weight, this.state.uom);
    const lengthInInches = this.convertLengthToInches(vals.dim.item_length,this.state.length)
    const widthInInches = this.convertLengthToInches(vals.dim.item_width, this.state.length)
    const heightInInches = this.convertLengthToInches(vals.dim.item_height, this.state.length)
    const { account } = this.props;
    const { weight_type } = (vals);
    const dimObject = _.pick(vals.dim, ['item_width', 'item_height', 'item_length']);
    let currentItem = {
      ...vals,
      item_width:widthInInches,
      item_length:lengthInInches,
      item_height:heightInInches ,
      item_weight:weightInLbs, 
      is_indv_weight: vals.weight_type === 'individual' ? 1 : 0,
      item_quantity: vals.item_quantity || 1
    };
    delete currentItem.dim;

    currentItem.account_id = account.id;
    if (isEmpty(currentItem.customer_order_id)) {
      currentItem.customer_order_id = this.state.customer_order_id;
    }
    currentItem.item_type = this.state.item_type;
    currentItem.weight_type = weight_type;

    this.setState({ isLoading: true });
    updateItem({
      ...this.props.currentItem,
      ...currentItem,
    }, !this.state.isEditing).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        const order_item = result.item || {}
        this.props.saveItem(
          {
            ...this.props.currentItem,
            ...currentItem,
            item_weight: item_weight,
            total_weight: order_item?.item_weight || item_weight,
          },
          true,
          result.order_area,
          result.order_quantity,
          result.order_weight
        );
        this.setState({
          isEditing: false,
          customer_order_id: "",
        });
        this.props.form.resetFields();
        this.setState({ uom: "lbs" });
      } else {
        alertMessage(result.errors, "error", 10);
      }
    }).finally(() => { this.setState({ isLoading: false }); });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { validateFields } = this.props.form;
    validateFields((err, values) => {
      if (!err) {
        const isNew = !this.state.isEditing;
        if (isNew) {
          this.handleCreateItem(values);
        } else {
          this.handleSaveItem(values);
        }
      }
    });
  };

  renderField = (fieldName, labelName, isMandatory, isNumberInput, isDisabled = false, width = "100%", errMsg = '') => {
    const { getFieldDecorator } = this.props.form;
    let formOptions = {
      rules: [ { required: isMandatory, message: "Invalid Value" } ],
    };
    if (!isMandatory && !isNumberInput) {
      formOptions.initialValue = "";
    }
    return (
      <Form.Item
        style={ {
          marginBottom: "0px",
        } }
        label={ labelName }
      >
        { getFieldDecorator(fieldName, formOptions)(<Input
          disabled={ isDisabled }
          onChange={(e) => this.handleOnChange(fieldName, e.target.value)}
          // onChange={ () => {
          //   if (isNumberInput) {
          //     this.calculateDimValues();
          //   }
          // } }
          size={this.props.size}
          type={ isNumberInput ? "number" : "text" } 
          min={isNumberInput ? 1 : null}
          width={{width: "100%"}}
          />) }
      </Form.Item>
    );
  };

  checkDimValues = (rule, value, callback) => {
    if (value.item_height > 0 && value.item_length > 0 && value.item_width > 0) {
      return callback();
    }
    callback('Dim values must greater than zero!');
  };

  render () {
    const { form, ItemDetailsFormRef, currentItem , currentOrg = {}} = this.props;
    const { accountDim } = this.state;
    const
      { getFieldDecorator } = form;
    const { isEditing } = this.state;
    const dimFactor = form.getFieldValue('dim_factor');
    const hasDimFacitor = !isEmpty(dimFactor) ? true : false;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 11 },
        xl: { span: 11 },
        xxl: { span: 11 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 16 },
        lg: { span: 13 },
        xl: { span: 13 },
        xxl: { span: 13 },
      },
    }
    const {dim={}} = currentItem;
    return (
        <Form {...formItemLayout} className="formHorizontal" onSubmit={ this.onSubmit } title={ "formTitle" }>
          {/* ref={ this.props.ItemDetailsFormRef }> */ }
          <div 
          style={ {
            // display: "flex",
            //flexDirection: "column",
            // gap: "10px",
          } }
          className="paymentDetailsCard"
          ref={ItemDetailsFormRef} 
          >
          <Row type="flex" align="middle">
            <Col xs={24} md={22}>
              <Row type="flex" gutter={16}>
                <Col span={ 4 }>
                  { this.renderField(
                    "item_name",
                    I18n.t("order.item_name"),
                    true,
                    false
                  ) }
                </Col>
               
                <Col span={ 4 }>
                  { this.renderField(
                    "item_model",
                    I18n.t("order.model_no"),
                    false,
                    false
                  ) }
                </Col>
                <Col span={ 4 }>
                  { this.renderField(
                    "serial_number",
                    I18n.t("order.serial_no"),
                    false,
                    false
                  ) }
                </Col>
                <Col span={ 4 }>
                  { this.renderField(
                    "item_quantity",
                    I18n.t("order.pcs"),
                    false,
                    true
                  ) }
                </Col>
                <Col span={7}>
                <Row type="flex">
                  <Col xs={14}>
                    <Form.Item
                      label="Weight"
                      labelCol={{ span: 9 }}
                      wrapperCol={{ span: 24 }}
                    >
                      {getFieldDecorator("item_weight", {
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: "Please enter the item's weight",
                        //   },
                        // ],
                      })(
                        <Input
                          onChange={(e) => this.handleOnChange("item_weight", e.target.value)}
                          size={this.props.size}
                          addonAfter={
                            <Select
                              value={this.state.uom}
                              size={this.props.size}
                              onChange={this.handleUOMChange}
                              dropdownMatchSelectWidth={false}
                            >
                              <Select.Option value="lbs">lbs</Select.Option>
                              <Select.Option value="kg">kgs</Select.Option>
                              <Select.Option value="g">grams</Select.Option>
                            </Select>
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={10}>
                    {/* {currentOrg.code} */}
                    <Form.Item>
                      {getFieldDecorator("weight_type", {
                        initialValue: this.state.weight_type,
                        rules: [{ required: true, message: "Please select a weight type" }],
                      })(
                        <Select
                          value={this.state.weight_type}
                          size={this.props.size}
                          dropdownMatchSelectWidth={false}
                        >
                          <Select.Option value="complete">Complete Weight</Select.Option>
                          <Select.Option value="individual">Individual Weight</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
                
                
              </Row>
              <Row type="flex" gutter={16}>
                
             

                {/* <Col  span={2}>
                <Form.Item>
                  {getFieldDecorator("weight_type", {
                    initialValue: this.state.weight_type,
                    rules: [{ required: true, message: "Please select a weight type" }],
                  })(
                    <Select
                      value={this.state.weight_type}
                      size={this.props.size}
                      style={{ marginBottom: 10 }}
                    >
                      <Select.Option value="complete">Complete Weight</Select.Option>
                      <Select.Option value="individual">Individual Weight</Select.Option>
                    </Select>
                  )}
                </Form.Item>
                </Col> */}
                 <Col span={ 4 }>
                  { this.renderField(
                    "wh_dock",
                    I18n.t("order.wh_short_form_dock"),
                    false,
                    false
                  ) }
                </Col>
                <Col span={8}>
                   {/* ${I18n.t("order.weight")} ${I18n.t("order.length")} 
                  ${I18n.t("order.width")} ${I18n.t("order.height")} */}
                  <Form.Item label="DIM" labelCol={{ span : 3 }} wrapperCol={{ span: 21 }} >
                    {getFieldDecorator('dim', {
                      initialValue: { item_length: dim.item_length || 1, item_width: dim.item_width || 1, item_height: dim.item_height || 1 },
                      rules: [{ validator: this.checkDimValues }],
                    })(<DIMSInput size="small" length={this.state.length} handleLengthChange={this.handleLengthChange} handleDimCalculation={this.handleOnChange}/>)}
                  </Form.Item>
              
                  {/* { this.renderField(
                    "item_length",
                    `L: ${
                      currentItem.item_length_uom
                        ? `(${currentItem.item_length_uom})`
                        : ""
                    }`,
                    hasDimFacitor,
                    true,
                    false,
                    100
                  ) }
                
                  { this.renderField(
                    "item_width",
                    `W: ${
                      currentItem.item_width_uom
                        ? `(${currentItem.item_width_uom})`
                        : ""
                    }`,
                    hasDimFacitor,
                    true,
                    false,
                    100
                  ) }
                  { this.renderField(
                    "item_height",
                    `H: ${
                      currentItem.item_height_uom
                        ? `(${currentItem.item_height_uom})`
                        : ""
                    }`,
                    hasDimFacitor,
                    true,
                    false,
                    100
                  ) } */}
                </Col>
               
                {accountDim && !isEmpty(accountDim.dim_factor) &&
                <Fragment>
                  <Col span={ 4 }>
                    <Form.Item
                      style={
                        { marginBottom: "0px" }
                      }
                      label={ `${I18n.t("configurations.dim_factor")}` }>
                      { getFieldDecorator("dim_factor", {
                        initialValue: accountDim?.dim_factor,
                        rules: [ { required: !isEmpty(accountDim.dim_factor), message: "Invalid Value" } ],
                      })(
                        <Select style={ { width: "100%" } }
                          onChange={(e) => this.handleOnChange("dim_factor", e)}
                          size="small"
                        >
                          { AppConfig.dimValues.map((key) => (
                            <Select.Option value={ key } key={ key }>
                              { key }
                            </Select.Option>
                          )) }
                        </Select>
                      ) }
                    </Form.Item>
                  </Col>
                </Fragment>
                }
                {accountDim && !isEmpty(accountDim.dim_factor) &&
                <Fragment>
                  <Col span={ 5 }>
                    { this.renderField(
                      "dim_weight",
                      `${I18n.t("configurations.dim_weight")}`,
                      !isEmpty(accountDim.dim_factor),
                      true,
                      true
                      ) }
                  </Col>
                </Fragment>
                }
              </Row>
            </Col>
            <Col xs={24} md={2}>
              <Row gutter={ 16 } style={{ marginTop: -10}}>
               <Col xs={24} style={{ height: 40 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={ this.state.isLoading }
                    size={this.props.size}
                    style={{ width : 100 }}
                    icon ={isEditing ? "save" : "plus"}
                  >
                    { isEditing ? "Save" : "Add" }
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button
                    onClick={ () => {
                      this.setState({
                        isEditing: false,
                      });
                      this.props.form.resetFields();
                      this.props.clearActiveItem();
                      this.setState({ uom: "lbs" });
                    } }
                    size={this.props.size}
                    type="danger"
                    style={{ width : 100 }}
                    icon="close"
                  >
                    Cancel
                  </Button>{ " " }
                </Col>
               
              </Row>
            </Col>
          </Row>
            
            
            { this.state.accountDim.dim_factor && (
              <Row >
                <Alert message={
                  <Col className="notes_content">
                    Configured DIM values for the Account{ " " }
                    <span className="">{ this.props.account.name }</span>:{ " " }
                    <span className="textBold">
                      { I18n.t("configurations.dim_factor") }:{ " " }
                      { this.state.accountDim.dim_factor }
                    </span>
                    , &nbsp;&nbsp;
                    <span className="textBold">
                      { I18n.t("configurations.dim_min_weight_label") } (
                      { AppConfig.weight_unit }) :{ " " }
                      { this.state.accountDim.min_weight
                        ? this.state.accountDim.min_weight
                        : "NA" }
                    </span>
                  </Col>
                } type="info" showIcon />
              </Row>
            ) }
          </div>
        </Form>
    );
  }
}

const ItemDetailsForm = Form.create({ name: "ItemDetail" })(ItemDetails);
export default ItemDetailsForm;

ItemDetails.propTypes = {
  form: PropTypes.object,
  account: PropTypes.object,
  currentItem: PropTypes.object,
  order: PropTypes.object,
  saveItem: PropTypes.func,
  account: PropTypes.object,
  isItemNew: PropTypes.bool,
  items: PropTypes.array,
};
ItemDetails.defaultProps = {
  account: {},
  currentItem: {},
  order: {},
  saveItem: () => { },
  account: {},
  isItemNew: false,
  items: [],
};
