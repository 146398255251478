import React, { Component, Fragment, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { deleteLocation, fetchLocations } from '../api/LocationsApi';
import { Button, Col, FormItem, Row, Spin, Tabs, Select,Search } from '../common/UIComponents';
import LocationsList from '../components/Locations/List';
import BaseModal from '../components/BaseModal';
import LocationForm from '../components/Locations/Form';
import ZoneZipCodeForm from '../components/configurations/zones/ZoneZipCode';
import { alertMessage,  isEmpty } from '../common/Common';
import I18n from '../common/I18n';
import AppConfig from '../config/AppConfig';
import LocationMap from '../components/Locations/MapZipCodes';
import { saveWHZones, fetchWHZones, deleteWHZone } from '../api/DeliveryZones';
import FormErrors from '../components/common/FormErrors';
import Zones from '../components/Locations/Zones';
import { checkServiceExistance,removeSpecialCharacters, renderAlertMessage } from '../helpers/common';
import { withRouter } from 'react-router';
import { OrgContext } from '../context/OrgContext';
import { UserContext } from '../context/UserContext';
import { fetchOrganizations } from '../api/Organisations';
import userStore from '../stores/UserStore';
import { RecordsPerPage } from '../components/orders/RecordsPerPage';
import { ScreenKeys } from './constants';
class Locations extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      Locations: [],
      pagination: {},
      showFormModal: false,
      showZones: false,
      showMapping: false,
      currentLocation: {},
      inProgress: false,
      zones: [],
      errors: [],
      zoneErrors: [],
      organizationSettings: {},
      isMilitaryTime: false,
      organization_id: '',
      token: props && props.location && !isEmpty(props.location.token) ? props.location.token : '',
      organizations: [],
      recordsPerPage: props.recordsPerPage,
      decimalPoints,
      zeroValue,
      filterPlaceHolder:{
        sortBy: "none",
        sortByType: "descend",
      }
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }


  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContextData;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getLocations()
          updateRecordsPerPage(this.props.perPageKey, value);
      }
    );
  };

  onSearch = (value) => {
  };

  componentDidMount() {
    const { isSuperAdmin } = this.props
    if(!isSuperAdmin){
      this.getLocations();
    }else {
      this.getOrganizations();
    }
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true' }) 
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
  }


  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const orgId = _.find(result.organizations.organizations, [
          "code",
          I18n.t("account.netmove_code"),
        ])._id;
        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id: orgId,
          },
          () => {
            this.tableOptions.current =1;
            this.getLocations();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };
  getDeliveryZones = () => {
    fetchWHZones(this.state.currentLocation.id)
      .then((result) => {
        if (result.success) {
          const zones = result.zones || [];
          if (zones.length === 0) {
            this.setState({ zones: [], showZones: true });
            this.addzone();
          } else {
            this.setState({ zones, showZones: true });
          }
        } else {
          // renderAlertMessage(result.errors)
          this.setState({ zones: [], showZones: true });
          this.addzone();
        }
      });
  }


  getLocations = (initial, cb) => {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    const searchText = this.state.token || '';
    const perPage = initial ? initial.perPage : this.state.recordsPerPage;
    const { isSuperAdmin } = this.props
    const org_id = isSuperAdmin ? this.state.organization_id : userStore.getStateValue('selectedOrg');
    fetchLocations(AppConfig.warehouseCode, page, perPage, true, org_id,"",searchText, this.state.filterPlaceHolder)
      .then((result) => {
        if (result.success) {
          this.setState({
            Locations: result.locations.locations,
            pagination: result.pagination,
            inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }


  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }

  onSearch = (text) => {
    const token = text.trim();
    this.setState(
      {
        token,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getLocations();
      }
    );
  };

  addzone = () => {
    const { zones, decimalPoints } = this.state;
    zones.push({
      name: '',
      zip_codes: [],
      isNew: true,
      min_charge: decimalPoints,
    });
    this.setState({
      zones,
    });
  }

 

  handleAddClick = () => {
    this.setState({ currentLocation: {}, showFormModal: true });
  }

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.Locations, ['id', id]);
    const location = Object.assign({}, this.state.Locations[index]);
    this.setState({ currentLocation: location, showFormModal: true });
  }

  showLocationMapping =(id) => {
    const index = _.findIndex(this.state.Locations, ['id', id]);
    const location = Object.assign({}, this.state.Locations[index]);
    this.setState({ currentLocation: location, showMapping: true });
  }

  showDeliveryZones = (id) => {
    const index = _.findIndex(this.state.Locations, ['id', id]);
    const location = Object.assign({}, this.state.Locations[index]);
    this.setState({ currentLocation: location }, () => {
      this.getDeliveryZones();
    });
  }

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteLocation(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getLocations();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false }, () => {
      this.getLocations();
    });
  }

  handleOnModalClose = () => {
    // if (this.state.showFormModal && this.state.currentLocation.id) {
    //   this.getLocations();
    // }
    // this.setState({ showFormModal: false });
    this.setState({ showFormModal: false },()=>{
      this.getLocations();
    });
  }

  handleOnMAppingModalClose = () => {
    this.setState({ showMapping: false });
  }

  handleOnZoneWindowClose = () => {
    this.setState({ showZones: false, zones: [] });
  }

  handleOnStateChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      if(element === "organization_id"){
        this.tableOptions.pagination.current = 1;
        this.getLocations(value);
      }
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions?.pagination?.current
        ? this.tableOptions.pagination.current
        : 1
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getLocations();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
          }),
        },
        () => {
          this.getLocations();
        }
      );
    }
  }

  handleZoneChange = (index, element, value) => {
    const zones = [...this.state.zones];
    zones[index][element] = value;
    this.setState({
      zones,
    });
  }

  handleZoneDeleteClick = (index, id, isNew) => {
    if (isNew === true) {
      const { zones } = this.state;
      zones.splice(index, 1);
      this.setState({
        zones,
      });
    } else {
      this.handleZoneDelete(id);
    }
  }

  handleZoneDelete = (id) => {
    this.setState({ inProgress: true });
    deleteWHZone({ id })
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.setState({
            inProgress: false,
          });
          this.getDeliveryZones();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleZoneSave = () => {
    const { zones } = this.state;
    const reqZones = zones.filter(record => !isEmpty(record.name)).map((zone) => {
      const formattedZone = {
        name: zone.name,
        zip_codes: zone.zip_codes || [],
        // min_charge: zone.min_charge ? parseFloat(zone.min_charge) : 0.00,
      };
      if (zone.id) {
        formattedZone.id = zone.id;
      }
      return formattedZone;
    });
    const data = {
      warehouse_id: this.state.currentLocation.id,
      zones: reqZones || [],
    };
    this.setState({ inProgress: true });
    saveWHZones(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({ inProgress: false, zoneErrors: [] });
          this.getDeliveryZones();
        } else {
          // alertMessage(result.errors, 'error', 10);
          this.setState({
            zoneErrors: result.errors,
            inProgress: false,
          });
        }
      });
  }

  renderFormModal = () => {
    let title;
    let isNew;
    if (this.state.currentLocation.id) {
      title = I18n.t('location.edit');
      isNew = false;
    } else {
      title = I18n.t('location.add');
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        width="60%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <LocationForm
          whlocation={this.state.currentLocation.id ? this.state.currentLocation : {}}
          organization_id={this.state.organization_id ? this.state.organization_id : ''}
          selectedLocation={this.state.currentLocation}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          zones={this.state.zones}
        />
      </BaseModal>
    );
  }


  renderZonesModal = () => {
    const defaultCoords = this.state.currentLocation && this.state.currentLocation.l_address && this.state.currentLocation.l_address.coordinates && this.state.currentLocation.l_address.coordinates.length >= 2 ? this.state.currentLocation.l_address.coordinates : AppConfig.defaultPoint;
    const defaultPoint = { lat: defaultCoords[1], lng: defaultCoords[0] };
    return (
      <BaseModal
        width='100%'
        height='100%'
        bodyStyle={{ padding: 0 }}
        style={{
          top: 0, overflowY: 'auto', height: '100%',
        }}
        onCancel={() => {}}
        closable={false}
      >
        { false &&
        <Fragment>
          <Row>
            <Col xs={20} />
            <Col xs={4} className="alignRight">
              <Button
                size="small"
                type="primary"
                onClick={this.addzone}
              >
                {I18n.t('zones.add')}
              </Button>
            </Col>
          </Row>
          { this.state.zones.length > 0 &&
          <ZoneZipCodeForm
            zones={this.state.zones}
            onChange={this.handleZoneChange}
            handleSave={this.handleZoneSave}
            handleDelete={(index, id, isNew) => this.handleZoneDeleteClick(index, id, isNew)}
            checkDuplicates={false}
          />
          }

          <Row>
            <Col xs={24}>
              {FormErrors(this.state.zoneErrors)}
            </Col>
          </Row>
        </Fragment>
        }
        <div className="blockBgColor">
          <Zones
            currentLocation={this.state.currentLocation}
            handleClose={this.handleOnZoneWindowClose}
            defaultCenter={defaultPoint}
          />
        </div>
      </BaseModal>
    );
  }

  renderMappingWindow = () => (
    <BaseModal
      title={`${I18n.t('location.mapping_heading')} for the warehouse ${this.state.currentLocation.name}`}
      onCancel={() => this.handleOnMAppingModalClose()}
      width="60%"
      style={{ top: 30 }}
      maskClosable={false}
    >
      <Row>
        <Col xs={24}>
          <LocationMap
            location={this.state.currentLocation}
            onCancel={() => this.handleOnMAppingModalClose()}
          />
        </Col>
      </Row>
    </BaseModal>
  )

  render() {
    const { pagination } = this.state;
    const { isSuperAdmin } = this.props
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col xs={12}>
              {I18n.t('menu.locations')}
            </Col>
            <Col xs={12}>
            <Row gutter={6}justify="end" style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
               <Col xs={12}>
                  <Search
                    size="default"
                    value={this.state.token || ""}
                    placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                      "menu.locations"
                    )}`}
                    onChange={(e) => {
                      const filteredString = removeSpecialCharacters(
                        e.target.value
                      );
                      this.handleOnChange("token", filteredString);
                    }}
                    onSearch={this.onSearch}
                    allowClear
                  />
                </Col> 
            <Col className='page-actions'>
              { checkServiceExistance('WHC') && <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('location.new')}
              </Button>
              }
            </Col>
            </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={24} className='page-actions'>
              <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('location.new')}
              </Button>
            </Col>
          </Row> */}
          <Spin spinning={this.state.inProgress} delay={1000}>
            { 
             isSuperAdmin && 
             <Row>
              <Col sm={24} xs={24} md={5} lg={5}>
                <FormItem label={I18n.t("organization.single")} require>
                  <Select
                    value={this.state.organization_id || ""}
                    showSearch
                    onChange={(e) =>
                      this.handleOnStateChange("organization_id", e)
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                  >
                    {/* <Select.Option key="-1" value="null">
                      -- options --
                    </Select.Option> */}
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            }
            <Row className="page-content">
              <Col xs={24}>
                <Row style={{ float: "right", marginBottom: "-18px" }}>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    onSearch={this.onSearch}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                { checkServiceExistance('WHI') && <LocationsList
                  isMilitaryTime={this.state.isMilitaryTime}
                  data={this.state.Locations}
                  // scroll = {{y:680}}
                  scroll = {{y:"calc(100vh - 270px)"}}
                  editClick={id => this.handleEditClick(id)}
                  deleteClick={id => this.handleDeleteClick(id)}
                  showReviews={id => this.handleShowReviews(id)}
                  showZips={id => this.showLocationMapping(id)}
                  showDeliveryZones={id => this.showDeliveryZones(id)}
                  pagination={{
                    total: pagination.total_count,
                    current: pagination.current_page,
                    pageSize: pagination.per_page || this.state.recordsPerPage,
                  }}
                  tableChange={
                    (tablePagination, filters, sorter) =>
                      this.handleTableChange(tablePagination, filters, sorter)}
                />
              }
              </Col>
            </Row>
          </Spin>
          {this.state.showFormModal &&
            this.renderFormModal()
          }
          {this.state.showZones &&
            this.renderZonesModal()
          }
          {this.state.showMapping &&
            this.renderMappingWindow()
          }
        </div>
      </div>
    );
  }
}
 const LocationsComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const userContextData = useContext(UserContext);
   const { currentRole } = userContextData;
   const isSuperAdmin = currentRole === 'super_admin';
   const perPageKey = isSuperAdmin ? ScreenKeys.WAREHOUSE_LISTING_SUPER_ADMIN : ScreenKeys.WAREHOUSE_LISTING;
   const recordsPerPage = !isSuperAdmin ? _.get(userContextData, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage) : AppConfig.ordersPerPage
  return (
    <Locations
      organizationSettings={organizationSettings}
      userContextData={userContextData}
      recordsPerPage={ recordsPerPage }
      isSuperAdmin={ isSuperAdmin }
      perPageKey={ perPageKey }
      {...props}
    />
  );
});

export default LocationsComponent;