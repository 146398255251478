import BaseList from "./BaseList";
import I18n from "../common/I18n";
export default class Listform extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("csvSetting.fe_column"),
        dataIndex: "fe_column",
        key: "fe_column",
      },
      {
        title: I18n.t("csvSetting.map_column"),
        dataIndex: "mapping_column",
        key: "mapping_column",
      },
    ];
  }
}
