import React, { Fragment } from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'
import BaseList from '../BaseList'
import { checkNegitive } from '../../common/Common'
import I18n from '../../common/I18n'
import { Input, Typography, Icon, Popconfirm } from '../../common/UIComponents'
import { isEmpty } from '../../common/Common'
import Copyable from '../common/Copyable'
import AppConfig from '../../config/AppConfig'
import { checkServiceExistance } from '../../helpers/common'
import OrderBalanceSheet from '../accounts/OrderBalancesheet'

class OrdersDuleList extends BaseList {
  constructor(props) {
    super(props)
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.columns = [
      {
        key: 'customer_order_number',
        title: I18n.t('account.credit_limit.invoice_no'),
        dataIndex: 'invoice_number',
        width: 180,
        render: (data, record, index) => {
          const isEditable = this.props.currentProcessedId === record.id
          return (
            <div>
              <Row type="flex">
                <Col span={16}>
                  <Typography.Text>{data}</Typography.Text>
                </Col>
                <Col span={4}>
                  <Copyable text={data} />
                </Col>
              </Row>
            </div>
          )
        },
      },
      {
        key: 'biling_date',
        title: I18n.t('account.credit_limit.approval_date'),
        dataIndex: 'invoice_approval_date',
        render: (data) => (
          <div>{data ? moment(data).format(AppConfig.dateFormat) : ''}</div>
        ),
      },
      {
        key: 'biling_amount',
        title: I18n.t('account.credit_limit.billing_amount'),
        dataIndex: 'invoice_amount',
        render: (data) => <div>{data ? checkNegitive(data) : ''}</div>,
      },
      {
        key: 'out_standing_amount',
        title: I18n.t('account.credit_limit.out_standing_amount'),
        dataIndex: 'out_standing_amount',
        render: (data, record) => {
          const isEditable = false;
            // this.props.editableRecord &&
            // (record.id in this.props.editableRecord)
          const editableRec = isEditable ? this.props.editableRecord : {};
          const outStandingAmount = record.out_standing_amount ? parseFloat(record.out_standing_amount) : zeroValue;
          return (
            <div>
              {isEditable ? (
                <Fragment>
                  <Input
                    className="borderNone"
                    prefix={<Icon type="dollar" />}
                    value={
                      editableRec[record.id]
                        ? editableRec[record.id]
                        : ''
                    }
                    style={{
                      border: 'none',
                      borderBottom: '1px solid #CCC',
                      width: 100,
                    }}
                    onChange={(e) =>
                      this.props.handleAmountOnChange(
                        record.id,
                        e.target.value,
                      )
                    }
                    onKeyDown={(e) =>
                      this.props.handleKeyDown(
                        record.id,
                        e,
                      )
                    }
                  />

                  <Icon
                    type="check-circle"
                    className="textGreen fontSize15"
                    onClick={() => this.props.handleOrderDueSave(record.id)}
                    style={{ padding: '0px 3px' }}
                  />

                  <Icon
                    type="close-circle"
                    className="textRed fontSize15"
                    onClick={() =>
                      this.props.handleOrderDueClear(record.id)
                    }
                  />
                </Fragment>
              ) : data ? (
                <span
                  className='cursorPointer'
                  onClick={() => this.props.handleEditRec(record)}
                >
                  {checkNegitive(data)}
                </span>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        key: 'actions',
        title: '',
        render: (record) => {
        const outStandingAmount = record.out_standing_amount ? parseFloat(record.out_standing_amount) : zeroValue;
        return (<div>
           {checkServiceExistance('ODD') &&
           (<OrderBalanceSheet 
              currentOrderRec={record} 
              currentCreditLimit={this.props.currentCreditLimit}
              key={record.id}   
              account={this.props.account}
              refreshList={this.props.refreshList}
           />)}
        </div>)},
      },
    ]
  }
}

export default OrdersDuleList
