import React from "react";
import ListOrdering from "./ListOrdering";
import { Tabs } from "antd";
import { PendingOrdersList } from "./PendingOrdersList";
import { QuotesList } from "./QuotesList";
import { ReleasesList } from "./ReleasesList";
import { RecoveriesList } from "./RecoveriesList";
import { RecurringOrdersList } from "./RecurringOrdersList";
import { LineHaulList } from "./LineHaulList";
import I18n from "../../../common/I18n";

function OrderListView(props) {
  const onChange = (key) => {
  };
  return (
    <div className="card-container tabHover">
      <Tabs defaultActiveKey="Orders" type="card" onChange={onChange}>
        <Tabs.TabPane tab={I18n.t("menu.pending_orders")} key="PendingOrders">
          <PendingOrdersList dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("menu.orders")} key="Orders">
          <ListOrdering dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("menu.quotes")} key="Quotes">
          <QuotesList dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("menu.recovery")} key="Recoveries">
          <RecoveriesList dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("menu.releases")} key="Releases">
          <ReleasesList dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("linehaul.label")} key="LineHaul">
          <LineHaulList dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={I18n.t("menu.recurring_orders")}
          key="RecurringOrders"
        >
          <RecurringOrdersList dispContext={props.dispContext} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default OrderListView;
