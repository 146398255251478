import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Map from '../Tasks/Map';
import { Button, Col, Row, Tooltip, Card, Icon } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import { updateStopsOrder } from '../../api/Routes';
import { renderAlertMessage, secondsToHms } from '../../helpers/common';
import I18n from '../../common/I18n';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class RearrangeStops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeId: props.routeId,
      totalStops: props.stopsData,
      updateStatus: props.updateStatus,
      disabled: false,
      render: false,
      ignoredStopsLength: 2,
      stopsData: [],
      ignoredStopTypes: ['WH', 'DV'],
      driverStops: [],
    };
  }

  componentDidMount() {
    this.setStopsData();
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const totalStops = [];
    let i = 1;
    totalStops.push(this.state.firstStop);
    this.state.completedStops.forEach((stop, index) => {
      i += 1;
      stop.stop_number = i;
      totalStops.push(stop);
    });
    const stopsData = reorder(
      this.state.stopsData,
      result.source.index,
      result.destination.index,
    );
    const sortedStops = [];
    stopsData.forEach((stop, index) => {
      i += 1;
      stop.stop_number = i;
      totalStops.push(stop);
      sortedStops.push(stop);
    });

    this.state.driverStops.forEach((stop, index) => {
      i += 1;
      stop.stop_number = i;
      totalStops.push(stop);
    });

    totalStops.push(this.state.lastStop);
    this.setState({ stopsData: sortedStops, totalStops }, () => {
    });
  }

  setStopsData = () => {
    const totalLength = this.state.totalStops.length;
    const firstStop = this.state.totalStops[0];
    const lastStop = this.state.totalStops[this.state.totalStops.length - 1];
    const driverStops = this.state.totalStops.filter(stop => (stop.location && stop.location.l_type === 'DV' && ((stop.status !== 'COMPLETED' && stop.status !== 'ARRIVED')))); // considering driver stops which are not yet started
    const completedStops = this.state.totalStops.filter(stop => (stop.location && stop.location.l_type !== 'WH' && (stop.status === 'COMPLETED' || stop.status === 'ARRIVED')));
    // const completedStops = this.state.totalStops.filter(stop => (stop.status === 'COMPLETED' || stop.status === 'ARRIVED'));
    const stopsData = this.state.totalStops.filter(stop => stop.location && !this.state.ignoredStopTypes.includes(stop.location.l_type) && stop.status !== 'COMPLETED' && stop.status !== 'ARRIVED');
    const ignoredStopsLength = totalLength - completedStops.length - stopsData.length - 1;
    this.setState({
      firstStop,
      lastStop,
      completedStops,
      stopsData,
      render: true,
      ignoredStopsLength,
      driverStops,
    }, () => {
    });
  }


  back = () => {
    this.props.history.push(`/tasks/${this.props.routeId}`);
  }

  handleDisabledChange = (disabled) => {
    this.setState({ disabled });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message, 'success', 1);
  };

  updateOrder = () => {
    this.setState({
      updateStatus: true,
    });
    const rearranged_data = this.state.totalStops.map((stop, index) =>
      ({ stop_id: stop.id, seq_number: stop.stop_number }));
    const updateStopsData = {
      stops: rearranged_data,
      nav_route_id: this.state.routeId,
    };
    updateStopsOrder(this.state.routeId, updateStopsData)
      .then((result) => {
        if (result.success) {
          this.handleSaveSuccess(I18n.t('messages.stops_rearranged'));
          this.setState({
            updateStatus: false,
          });
        } else {
          this.setState({
            updateStatus: false,
          });
          renderAlertMessage(result.errors)
        }
      });
  }

  getCurrentStop = (id) => {

  }


  render() {
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
      userSelect: 'none',
      // padding: grid * 0.75,
      margin: `0 0 ${grid}px 0`,
      // background: isDragging ? 'white' : '#e8e8e8',
      color: isDragging ? '#607ad6' : 'rgba(0, 0, 0, .65)',
      cursor: 'move !important',
      ...draggableStyle,
    });
    const getListStyle = isDraggingOver => ({
      // background: isDraggingOver ? '#e8e8e8' : 'white',
      // padding: grid,
      color: isDraggingOver ? 'rgba(0, 0, 0, .65)' : '#607ad6',
    });
    const { stopsData } = this.state;
    const mapStops = this.state.totalStops.filter(x => x.location && x.location.l_address && x.location.l_address.coordinates && x.location.l_address.coordinates.length >= 2);
    return (
      <div className='content' style={{ marginTop: '-20px' }}>
        <Row type="flex" justify="space-between">
          {/* <Col sm={18} xs={14}>
             <div id="GoogleMaps">
              {
                (stopsData.length > 0) &&
                <Map data={this.state.stopsData} kms={this.state.disabled} mapElement={<div style={{ height: 480, width: 'auto' }} />} />
              }
            </div>
            </Col> */}
          {(this.state.render) &&
            <Col xs={10} style={{ height: '80vh', overflowY: 'auto', padding: 10 }}>
              <div id="right-panel" style={{ float: 'none' }}>
                {this.state.stopsData.length > 0 ? <Fragment>
                  <div>
                    {/* <b>Routes</b> <br /> */}
                    <DragDropContext onDragEnd={this.onDragEnd} >
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {stopsData && stopsData.map((task, index) => {
                              const contact_details = task.contact_details ? task.contact_details : {};
                              const actual_start_date_time_with_tz = task.actual_start_date_time_with_tz ? moment.utc(task.actual_start_date_time_with_tz).format('lll') : 'NA';
                              const actual_end_date_time_with_tz = task.actual_end_date_time_with_tz ? moment.utc((task.actual_end_date_time_with_tz)).format('lll') : 'NA';
                              const scheduled_start_date_time_with_tz = task.scheduled_start_date_time_with_tz ? moment.utc(task.scheduled_start_date_time_with_tz).format('lll') : 'NA';
                              const scheduled_end_date_time_with_tz = task.scheduled_end_date_time_with_tz ? moment.utc(task.scheduled_end_date_time_with_tz).format('lll') : 'NA';
                              const estimated_arrival_date_time_with_tz = task.estimated_arrival_date_time_with_tz ? moment.utc(task.estimated_arrival_date_time_with_tz).format('lll') : 'NA';
                              const estimated_departure_date_time_with_tz = task.estimated_departure_date_time_with_tz ? moment.utc(task.estimated_departure_date_time_with_tz).format('lll') : 'NA';
                              const service_duration = task.service_duration ? secondsToHms(parseInt(task.service_duration), true, true, false) : '0';
                              const stop_name = this.props.formatStopName(contact_details, task.location, task.stop_number);
                              return (<Draggable key={task.location.id} draggableId={task.id} index={index} isDragDisabled={!(task.location && task.location.l_type !== 'WH' && task.status !== 'COMPLETED' && task.status !== 'ARRIVED')}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                    isDragDisabled="true"
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <Fragment key={`timeline_fragment${index}`}>
                                      {false &&
                                        <Card
                                          id={task.location.name}
                                          title={`Stop ${task.stop_number} - ${stop_name}`}
                                          className="currentRouteStopDetails draggableCursor"
                                          size="small"
                                          onClick={() => this.getCurrentStop(task.id)}
                                        >
                                          <Row >

                                            <Col xs={24} sm={24} md={24} lg={24}>
                                              <Row>
                                                <Col sm={2} xs={2} md={1} lg={1}>
                                                  <div className="textCenter">
                                                    <Icon type="calendar" />
                                                  </div>
                                                </Col>
                                                <Col sm={8} xs={8} md={4} lg={4}>
                                                  <div className="info-text textBold">Scheduled</div>
                                                </Col>
                                                <Col xs={1}>:</Col>
                                                <Col sm={11} xs={8} md={16} lg={16}>
                                                  <div className="info-text">
                                                    <Tooltip placement="topLeft" title="Scheduled Start"><span>{scheduled_start_date_time_with_tz}</span> </Tooltip>&nbsp;-&nbsp;
                                                    <Tooltip placement="topLeft" title="Scheduled End"><span>{scheduled_end_date_time_with_tz}</span></Tooltip>
                                                  </div>
                                                </Col>
                                                <Col sm={4} xs={4} md={2} lg={2} className="alignLeft">
                                                  {task.stop_tz_short_name}
                                                </Col>
                                              </Row>
                                            </Col>

                                            <Col xs={24} sm={24} md={24} lg={24}>
                                              <Row>
                                                <Col sm={2} xs={2} md={1} lg={1}>
                                                  <div className="textCenter">
                                                    <Icon type="calendar" />
                                                  </div>
                                                </Col>
                                                <Col sm={8} xs={8} md={4} lg={4}>
                                                  <div className="info-text textBold">Estimated</div>
                                                </Col>
                                                <Col xs={1}>:</Col>
                                                <Col sm={11} xs={8} md={16} lg={16}>
                                                  <div className="info-text">
                                                    <Tooltip title="Estimated Arrival"><span>{estimated_arrival_date_time_with_tz}</span> </Tooltip>&nbsp;-&nbsp;
                                                    <Tooltip title="Estimated Departure"><span>{estimated_departure_date_time_with_tz}</span></Tooltip>
                                                  </div>
                                                </Col>
                                                <Col sm={4} xs={4} md={2} lg={2} className="alignLeft">
                                                  {task.stop_tz_short_name}
                                                </Col>
                                              </Row>
                                            </Col>

                                            <Col xs={24} sm={24} md={24} lg={24}>
                                              <Row>
                                                <Col sm={2} xs={2} md={1} lg={1}>
                                                  <div className="textCenter">
                                                    <Icon type="calendar" />
                                                  </div>
                                                </Col>
                                                <Col sm={8} xs={8} md={4} lg={4}>
                                                  <div className="info-text textBold">Actual</div>
                                                </Col>
                                                <Col xs={1}>:</Col>
                                                <Col sm={11} xs={8} md={16} lg={16}>
                                                  <div className="info-text">
                                                    <Tooltip title="Actual Arrival"><span>{actual_start_date_time_with_tz}</span> </Tooltip>&nbsp;-&nbsp;
                                                    <Tooltip title="Actual Departure"><span>{actual_end_date_time_with_tz}</span></Tooltip>
                                                  </div>
                                                </Col>
                                                <Col sm={4} xs={4} md={2} lg={2} className="alignLeft">
                                                  {task.stop_tz_short_name}
                                                </Col>
                                              </Row>
                                            </Col>

                                            <Col xs={24} sm={24} md={24} lg={24}>
                                              <Row>
                                                <Col sm={2} xs={2} md={1} lg={1}>
                                                  <div className="textCenter">
                                                    <Icon type="clock-circle" />
                                                  </div>
                                                </Col>
                                                <Col sm={10} xs={13} md={6} lg={6}>
                                                  <div className="info-text alignLeft textBold">Service Duration</div>
                                                </Col>
                                                <Col xs={1}>:</Col>
                                                <Col sm={11} xs={8} md={16} lg={16}>
                                                  <div className="info-text">
                                                    <span>{service_duration}</span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>

                                        </Card>
                                      }
                                      <div className="alignLeft textPrimary smallText"><Icon type="home" /> &nbsp;{`Stop ${task.stop_number} - ${stop_name}`}</div>
                                    </Fragment>
                                  </div>
                                )}
                                      </Draggable>);
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  <Col xs={24} className="floatNone" style={{ padding: '0 15%' }}>
                    <Row className="optimize_bottom_fixed">
                      <Col xs={24} className="floatNone" style={{ padding: '0 15%' }}>
                        <Button type="primary" className="buttonOrder" onClick={() => this.updateOrder()} loading={this.state.updateStatus}>
                          Save Stop Sequence
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Row> In KM: <Switch size="small" checked={this.state.disabled} onChange={this.handleDisabledChange} /></Row> */}
                  <div id="directions-s" />
                </Fragment> :
                <h3 className="alignCenter">There are no stops to resequence</h3> }
              </div>
            </Col>
          }
          {
            <Col xs={14}>
              { mapStops.length > 0 && <Map
                data={mapStops}
                kms={this.state.disabled}
                getCurrentStop={id => this.getCurrentStop(id)}
                mapElement={<div style={{ height: '85vh', width: 'auto' }} />}
                driver={this.state.routeStatus !== 'COMPLETED' ? this.state.driverInfo : ''}
                routeStatus={this.state.routeStatus}
                screen="update_stops"

              />
              }
            </Col>
          }

        </Row>
      </div>
    );
  }
}
export default RearrangeStops;
