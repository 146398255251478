import React from "react";
import BaseList from "../../BaseList";
import { Icon,  Popconfirm, Tooltip } from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";
class BillingAccountsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("general.name"),
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: I18n.t("general.code"),
        dataIndex: "code",
        key: "code",
      },
      {
        title: I18n.t("billing_accounts.realm_id"),
        dataIndex: "realm_id",
        key: "realm_id",
      },
      {
        title: I18n.t("general.email"),
        dataIndex: "email",
        key: "email",
      },
      {
        title: I18n.t("general.fax_no"),
        dataIndex: "fax_no",
        key: "fax_no",
      },
      {
        title: I18n.t("general.po_box_no"),
        dataIndex: "po_box_no",
        key: "po_box_no",
      },
      {
        key: "address",
        title: I18n.t("general.address"),
        className:"noWrap",
        render: (data) => {
          const address = _.values(data.address)
            .splice(1, 6)
            .filter(Boolean)
            .join();
          return (
          <Tooltip title={address}> {address} </Tooltip>
          );
        },
        width: 250,
      },

      {
        title: "",
        key: "actions",
        dataIndex: "id",
        fixed: "right",
        className: "width10",
        render: (id, record, index) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
            </Tooltip>
            <Tooltip title={I18n.t("general.delete")}>
            <Popconfirm
              placement="left"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <span><DeleteIcon/></span>
            </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
  }
}
export default BillingAccountsList;
