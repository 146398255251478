import React, { Fragment } from 'react';
import moment from 'moment';
import BaseList from '../BaseList';
import I18n from '../../common/I18n';
import { Icon, Popover, Col, Row, Tooltip } from '../../common/UIComponents';
import AppConfig from '../../config/AppConfig';
import { formatByTimeConfig } from '../../helpers/common';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';
import EyeIcon from '../common/EyeIcon';
import FormIcon from '../common/FormIcon';
import { isEmpty } from '../../common/Common';

class OrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      taskType: this.props.taskType,
      page: props.pagination.current ? props.pagination.current : 1,
      pageSize: props.pagination.pageSize ? props.pagination.pageSize : AppConfig.perPage,
    };
    this.columns = [
      // {
      //   title: '#',
      //   key: 'index',
      //   render: (text, record, index) => ((this.state.page-1) * AppConfig.perPage)+(index+1),
      // },

      {
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
      },

      {
        key: 'order_delivery_time',
        title: I18n.t('general.scheduled_appointment'),
        render: data => (
          <div>
            {data.scheduled_start_datetime ?
              formatByTimeConfig(moment(new Date(data.scheduled_start_datetime)) , this.props.isMilitaryTime , "Do MMM YYYY, HH:mm" , "Do MMM YYYY, hh:mm a") 
              : 'NA'}
          </div>
        ),
      },

      {
        key: 'customer',
        title: I18n.t('general.customer'),
        render: data => (
          <div>
            <Popover
              title={I18n.t('general.customer_details')}
              content={
                <Fragment>

                  <Row>
                    <Col lg={3} md={3} sm={6} xs={6}><Icon type="phone" /></Col>

                    <Col lg={21} md={21} sm={18} xs={18}>{isEmpty(data.customer_phone) ? 'N/A' : data.customer_phone}</Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} sm={6} xs={6}><Icon type="mail" /></Col>

                    <Col lg={21} md={21} sm={18} xs={18}>{isEmpty(data.customer_email) ? 'N/A' : data.customer_email}</Col>
                  </Row>
                </Fragment>
              }
            >
              {isEmpty(data.customer_first_name) ? 'N/A' : `${data.customer_first_name} ${data.customer_last_name}`}
            </Popover>
          </div>
        ),

      },


    ];

    this.columns.push(
      {
        key: 'item_name',
        title: 'Item',
        dataIndex: 'item_name',
      },
      {
        key: 'type_of_order',
        title: 'Order Type',
        dataIndex: 'type_of_order',
        render: data => (
          <div>
            {data === 'X' ? 'Exchange' : (data === 'R') ? 'Return Only' : (data === 'P ' ? 'Delivery' : '')}
          </div>
        ),
      },
    ),

    this.columns.push({
      key: 'items',
      // title: props.screen === "routes" ? 'Items' : 'Details',
      render: (data) => {
        const title = `#${data.customer_order_number}`;
        return (
          <div>
            <EyeIcon handleClick={() => this.props.orderDetailsViewClick(data.id)}/>
            &nbsp;&nbsp;
            {(data.status === 'COMPLETED' || data.editable === false || data.status === 'EXCEPTION') ? '' : 
            <EditIcon handleClick={() => this.props.handleEditOrder(data.id)}/>
            }&nbsp;&nbsp;
            {(data.status === 'COMPLETED') ? <Tooltip title={I18n.t('survey.view_submitted')}>
              <FormIcon handleClick={() => this.props.handleSurveyDetails(data.id)}/>
              </Tooltip> : ''}&nbsp;&nbsp;
            {(data.status === 'COMPLETED' || data.editable === false || data.status === 'EXCEPTION') ? '' :

              <DeleteIcon handleClick={() => this.props.deleteOrderClick(data.id)}/>
            }


          </div>
        );
      },
      align: 'center',

    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pagination) {
      return {
        page: props.pagination.current ? props.pagination.current : 1,
        pageSize: props.pagination.pageSize ? props.pagination.pageSize : AppConfig.perPage,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
}

export default OrdersList;
