/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BaseList from "../BaseList";
import {
  alertMessage,
  checkNegitive,
  compareNumber,
  compareString,
  doFormate,
  ellipseText,
  getFileType,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Popover,
  Col,
  Row,
  Tooltip,
  Popconfirm,
  Typography,
  Link,
  Spin,
  Tabs,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import AddressInfo from "../common/AddressInfo";
import userStore from "../../stores/UserStore";
import OrderAppointments from "../orders/OrderAppointments";
import { getAppointmentTime, retrieveAddress, retrieveOrderValue } from "../../helpers/orders";
import { checkAccessExistance, checkServiceExistance } from "../../helpers/common";
import TypeOfOrder from "../orders/TypeOfOrder";
import ValidateLocationErr from "../orders/ValidateLocationErr";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import ConsigneeDetails from "../common/ConsigneeDetails";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import SettingIcon from "../common/SettingIcon";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import FormIcon from "../common/FormIcon";
import BranchesIcon from "../common/BranchesIcon";
import Copyable from "../common/Copyable";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import BaseList2 from "../BaseList2";
import { VehicleImageData } from "../../common/VehicleImageData";
import EndUserTitle from "../common/EndUserTitle";
import ReleaseToDetails from "../releases/ReleaseToDetails";
import manualBol from "../../../assets/images/manualColor.png";
import ManualBolViewer from "../../common/ManualBolViewer";
import EstimateData from "../../common/EstimateData";
import DisplayTime from "../common/DisplayTime";


const setAddress = (data, type) => {
  const sourcePoint = (data?.origin && Object.keys(data.origin).length !== 0) ? data.origin : data.warehouse_address;
  const destinationPoint = (data?.destination && Object.keys(data.destination).length !== 0) ? 
  data.destination : 
  (["T", "LH"].includes(data.type_of_order) ? data.warehouse_address : data.customer_address);
  const pickUpAddress = (
    <Popover
      title={ data.origin.city ? I18n.t("general.origin_location") : I18n.t("general.warehouse_address") }
      content={ <AddressInfo address={ sourcePoint } /> }
      overlayStyle={ { width: 200 } }
      getPopupContainer={ (triggerNode) => triggerNode.parentNode }
    >
      {
        // data.warehouse_address && data.warehouse_address.city ? `${data.warehouse_address.city} ( ${data.warehouse_address.state} )` : 'NA'
        sourcePoint && sourcePoint.city
          ? `${sourcePoint.location_code ? sourcePoint.location_code : sourcePoint.city}`
          : "NA"
      }
    </Popover>
  );

  const dropAddress = (
    <Popover
      title={ data.destination.city ? I18n.t("general.destination_location") : !["T", "LH"].includes(data.type_of_order) ? I18n.t("general.customer_address") : I18n.t("general.warehouse_address") }
      content={ <AddressInfo address={ destinationPoint } /> }
      overlayStyle={ { width: 200 } }
      getPopupContainer={ (triggerNode) => triggerNode.parentNode }
    >
      {
        // data.customer_address && data.customer_address.city ? `${data.customer_address.city} ( ${data.customer_address.state} )` : 'NA'
        destinationPoint && destinationPoint.city
          ? `${destinationPoint.location_code ? destinationPoint.location_code : destinationPoint.city}`
          : "NA"
      }
    </Popover>
  );
  if (type === "origin") {
    if ([ "R", "TR" ].includes(data.type_of_order)) {
      return dropAddress;
    }
    return pickUpAddress;
  }
  if ([ "R", "TR" ].includes(data.type_of_order)) {
    return pickUpAddress;
  }
  return dropAddress;
};

const setZones = (data) => (
  <Popover
    title="Zones List"
    placement="topLeft"
    content={
      <div>
        { data.order_zone_name && data.order_zone_name.length > 0
          ? `${data.order_zone_name.join(", ")}`
          : "NA" }
      </div>
    }
    overlayStyle={ { width: 250 } }
    getPopupContainer={ (triggerNode) => triggerNode.parentNode }
  >
    { data.order_zone_name && data.order_zone_name.length > 0
      ? `${data.order_zone_name.length > 1
        ? `${data.order_zone_name[ 0 ]} (+${data.order_zone_name.length - 1
        })`
        : data.order_zone_name[ 0 ]
      }`
      : "NA" }
  </Popover>
);

function addColumnsFromConfiguration (columnsToCheck, displayConfiguration , columnsData) {
  const { userConfiguration = {}, orgConfiguration = {} } = displayConfiguration;
  const listColumns = userConfiguration[ columnsToCheck ] || orgConfiguration[ columnsToCheck ];

  if (typeof listColumns !== 'undefined' && Array.isArray(listColumns)) {
    const finalColsOrder = [];
    listColumns.forEach((column) => {
      const foundColumn = columnsData.find((col) => col.key === column);
      if (foundColumn) {
        finalColsOrder.push(foundColumn);
      }
    });

    //const actions = columnsData.find((col) => col.key === 'items');
    const actions = columnsData.find((col) => ['items'].includes(col.key));

    if (actions) {
      finalColsOrder.push(actions);
    }
    return finalColsOrder;
  }else{
    return columnsData;
  }
}

const OrdersList = (props) => {
  const [ resizablecolumns, setResizablecolumns ] = useState([]);
  const hasLocationRecord = _.find(props.data,  (rec) => [...AppConfig.orderTypesWithLocations].includes(rec.type_of_order))
  const hasRoutesAccess = checkAccessExistance('DISPATCH')

  useEffect(() => {
    const { filterPlaceHolder } = props;
    let finalColumns = [
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        align: "center",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'customer_order_number' && filterPlaceHolder.sortByType,
        width: 120,
        // fixed: !props.isRowSelected ? "left" : null,
        render: (data, record) => {
          const textColor =
            props.showTriggerEdit && record.status === "EXCEPTION"
              ? "textRed"
              : "";
          const showEdit = !["REJECTED"].includes(record.status) ? true : false;    
          return (
            <Row
              type="flex"
              // justify="space-between"
              align="middle"
              // style={{
              //   padding: "0 10px 0 5px",
              // }}
              gutter={ 4 }
            // style={{marginTop:23}}
            >
              <Col>
                <TypeOfOrder
                  order={ record }
                  orderTypeKey="type_of_order"
                  relatedOrderKey="related_order"
                  placement="topLeft"
                  showBadge={ true }
                  key={`TypeOfOrder${record.id}`}
                />
              </Col>
              <Col>
                { checkServiceExistance("COS") ? (
                  props.showTriggerEdit ? (
                    <Link
                      className={ textColor }
                      onClick={ () => props.handleEditOrder(record.id) }
                    >
                      { data }
                    </Link>
                  ) : props.showDetailsLink !== false ? (
                    // <CustomerOrderDetails
                    //   order_no={ data }
                    //   order={ {
                    //     id: record.id,
                    //     customer_order_number: data,
                    //   } }
                    //   showEdit={
                    //     ![ "REJECTED" ].includes(record.status)
                    //       ? true
                    //       : false
                    //   }
                    //   editClick={ () => props.handleEditOrder(record.id) }
                    //   refreshCallback={ props.refreshCallback }
                    // />
                    <Link
                      // onClick={() => props.gotoOrderDetails(showEdit)}
                      onClick={() => props.gotoOrderDetails(record.id)}
                    >
                      {data}
                    </Link>
                  ) : (
                    <span className={ textColor }>{ data }</span>
                  )
                ) : (
                  <span className={ textColor }>{ data }</span>
                ) }
              </Col>
              {data && (<Col span={3} >
                <Copyable text={data} />
              </Col>)}
            </Row>
          );
        },
      },
      {
        key: 'status',
        title: I18n.t("general.status"),
        dataIndex: 'status',
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'status' && filterPlaceHolder.sortByType,
        render: (data, record) => retrieveOrderValue(record, 'status', true),
        width: hasLocationRecord ? 160 : 75,      
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        render: (data, record) => (
          <div style={ { textAlign: "center" } }>
            <TypeOfOrder
              order={ record }
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={ true }
            />
          </div>
        ),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'type_of_order' && filterPlaceHolder.sortByType
      },
      {
        key: "appointment",
        title: I18n.t("general.scheduled_appointment"),
        dataIndex: "appointments",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'appointment' && filterPlaceHolder.sortByType,
        width: 185,
        render: (text, data) =>
          data.appointments.length > 0 && (
            <Popover
              size="small"
              content={
                <div className="table-contact-info width500 fontsize12">
                  <OrderAppointments
                    appointments={ data.appointments || [] }
                    timezone={ data.tz_short_form }
                    showPDLabels={["T", "LH"].includes(data.type_of_order) ? true : false}                  />
                </div>
              }
              title={ I18n.t("appointmentForm.preference_title") }
              getPopupContainer={ (triggerNode) => triggerNode.parentNode }
            >
              <span>
                <u className="appointmentText">
                  <span
                    size="small"
                    type="primary"
                    className={
                      data.appointments.filter(
                        (appointment) => appointment.confirmed
                      ).length > 0
                        ? "confirmedBadge appointmentTypeBox"
                        : "scheduledBadge appointmentTypeBox"
                    }
                  />
                  &nbsp;
                  { getAppointmentTime(
                    data.appointments,
                    props.organizationSettings
                  ) }
                </u>
              </span>
            </Popover>
          ),
      },
      {
        key: "consignee",
        title: <EndUserTitle />,
        dataIndex: "consignee",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy=== 'consignee' && filterPlaceHolder.sortByType,
        width: 200,
        render: (data, record) => (
          <div>
            <Popover
              title={<EndUserTitle stringToAppend={I18n.t("general.details")}/>}
              content={
                <ConsigneeDetails
                  record={record}
                  key={`consigneeDetails${record.id}`}
                />
              }
              getPopupContainer={(triggerNode) => triggerNode}
            >
              {isEmpty(record.customer_first_name)
                ? "N/A"
                : `${record.customer_first_name} ${
                    !isEmpty(record.customer_last_name)
                      ? record.customer_last_name
                      : ""
                  }`}
            </Popover>
          </div>
        ),
        align: "left",
      },
      {
        key: 'company_name',
        title: I18n.t('general.company_name'),
        dataIndex: 'company_name',
        render: (text, record) => (
          <div>
            <Popover
              title={"Company Details"}
              key={`prelease_to${record.id}`}
              content={
                <div style={{width:"400px"}} className="table-contact-info fontsize12">
                <ReleaseToDetails
                  details={record}
                  key={`order_to${record.id}`}
                  showAddress = {false}
                />
                </div>
              }
            >
              <u className='text-decoration-dashed'>{text}</u>
            </Popover>
          </div>
        ),
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
          <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: 'created_by',
        title: I18n.t("general.created_by"),
        dataIndex: 'created_by',
        render: (data,record) => (
          <div>{record.created_by|| ''}</div>
        ),
        width: 100
      },
      {
        key: 'cities',
        title: "City",
        dataIndex: 'customer_city',
        render: (data, record) => {
          if (record.type_of_order === "T") {
            return (
              <div>
                <p>P: {record.origin?.city || 'NA'} - D: {record.destination?.city || 'NA'}</p>
              </div>
            );
          } else {
            return <div>{record.customer_city|| ''}</div>;
          }
        },
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'cities' && filterPlaceHolder.sortByType,
        width: 100
      },
      {
        key: 'deliver_by',
        title: I18n.t("general.deliver_by"),
        dataIndex: 'deliver_by',
        render: (data,record) => (
          <div>
            {record.delivery_by ? 
            <DisplayTime  
              dateTimeString={record.delivery_by}
              displayWithDate={true}
              //timeZoneId={record.wh_timezone}
              dateTimeFormat={AppConfig.dateTimeFormat}
              tz_short_name={record.tz_short_form}
              isUTC={true}
            /> : 'NA'}
          </div>
        ),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'deliver_by' && filterPlaceHolder.sortByType,
        width: 100
      },
      {
        key: "city",
        title: "Origin -> Destination",
        dataIndex: "warehouse_address",
        width: 200,
        render: (text, data) => (
          <div style={ { display: "flex" } }>
            <div>{ setAddress(data, "origin") }</div>
            <span>&nbsp;{ " - " }&nbsp;</span>
            <div>{ setAddress(data, "destination") }</div>
          </div>
        ),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'city' && filterPlaceHolder.sortByType
        // align: "center",
      },
      {
        key: "full_address",
        title: "Address",
        dataIndex: "full_address",
        width: 400,
        render: (text, data) => retrieveAddress(data),
        ellipsis: true,
      },
      {
        key: 'priority',
        title: I18n.t("configurations.priority"),
        dataIndex: 'priority',
        render: (data, record) => (
          <div>{record.priority === null || record.priority === false ? "No" : "Yes"}</div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy === 'priority' && filterPlaceHolder.sortByType,
        width: 100
      },
      {
        key: "zipcode",
        title: "Zipcode",
        dataIndex: "customer_address",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'zipcode' && filterPlaceHolder.sortByType,
        width: 90,
        render: (text, data) => {
          if (["T", "LH"].includes(data.type_of_order)) {
            const originZipcode = _.get(data.origin, 'zipcode', '') || '';
            const destinationZipcode = _.get(data.destination, 'zipcode', '') || '';
            return (
              <>
                    P:{" "}
                    {originZipcode ? (
                    <ValidateLocationErr
                      record={{
                        ...data,
                        zipcode: originZipcode,
                        location_id: data.origin.location_id,
                        l_address: data.origin,
                        location_partial_match: data.origin.location_partial_match
                      }}
                      displayKey={"zipcode"}
                      errorKey={"location_partial_match"}
                      errorValue={true}
                      refreshCallback={props.resolvedAddressCallback}
                      loadMaps={true}
                    />
                    ) : "NA"} 
                    &nbsp;{" - "}&nbsp;
                    D:{" "}
                    {destinationZipcode ? (
                    <ValidateLocationErr
                      record={{
                        ...data,
                        zipcode: destinationZipcode,
                        location_id: data.destination.location_id,
                        l_address: data.destination,
                        location_partial_match: data.destination.location_partial_match
                      }}
                      displayKey={"zipcode"}
                      errorKey={"location_partial_match"}
                      errorValue={true}
                      refreshCallback={props.resolvedAddressCallback}
                      loadMaps={true}
                    />
                    ) : "NA"}
              </>
            );
          } else {
            return (
              <div>
                <ValidateLocationErr
                  record={{
                    ...data,
                    zipcode: data.customer_zipcode,
                    location_id: data.cs_location_id,
                    l_address: data.customer_address,
                    location_partial_match: data.location_partial_match
                  }}
                  displayKey={"zipcode"}
                  errorKey={"location_partial_match"}
                  errorValue={ true }
                  refreshCallback={ props.resolvedAddressCallback }
                  loadMaps={ true }
                />
              </div>
            );
          }
        },
        align: "left",
      },
      {
        key: "is_pod_signed_bol_exist",
        title: "BOL",
        dataIndex: "is_pod_signed_bol_exist",
        render: (data,record,is_pod_signed_bol_exist) => {
          const verifyManualBol = _.get(record, "type_of_order",null) !== "T" ? _.get(record, "bol_picture", null) : {
            origin: _.get(record, "bol_picture", null),
            destination: _.get(record, "delivery_bol_picture", null)
          };
          const isVerifyManualBolValid = verifyManualBol 
          ? (typeof verifyManualBol === 'object' 
            ? Object.values(verifyManualBol).some(value => !!value) 
            : !_.isEmpty(verifyManualBol))
          : false;
          return (<div style={{ color: isVerifyManualBolValid ? 'green' : 'red', textAlign: 'center' }}>
            {isVerifyManualBolValid ? 'Exists' : 'Does Not Exist'}
          </div>)

        },
        width: 100,
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              { props.isShipper ?
                record.account_code
                : hasAccess ? (
                <Link
                  className={ hasAccess ? "anchor_cursor" : "" }
                  onClick={ () => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_code);
                    }
                  } }
                >
                  { !isEmpty(data) ? data : record.account_code }
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              ) }
            </div>
          );
        },
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'account_code' && filterPlaceHolder.sortByType,
        align: "left",
        className: 'noWrap'
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (<div>
            {!props.isShipper && hasAccess ? (
              <Link
                className={hasAccess ? "anchor_cursor" : ""}
                onClick={() => {
                  if (hasAccess) {
                    props.navigateToAccount(record.account_code);
                  }
                }}
              >
                {!isEmpty(data) ? data : record.account_name}
              </Link>
            ) : !isEmpty(data) ? (
              data
            ) : (
              record.account_name
            )}
          </div>);
         },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: filterPlaceHolder?.sortBy=== 'account_name' && filterPlaceHolder.sortByType,
        align: "left",
        className: 'noWrap'
      }
    ];
    if(props.screen_from === "dispatches") {
      finalColumns.push({
          key: 'warehouse',
          title: I18n.t("general.warehouse"),
          dataIndex: 'warehouse_code',
          render: (data) => data,
      }, {
        key: 'cities',
        title: I18n.t("general.city"),
        dataIndex: 'customer_city',
        render: (data) => data,
      },); 
    }

    if (props.currentFilter !== "REJECTED") {
      finalColumns.push({
        key: "created_at",
        title: I18n.t("order.age"),
        dataIndex: "created_at",
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'created_at' && filterPlaceHolder.sortByType,
        align: "right",
        render: (created_at, data) => (
          <div className="paddingRight25">
            <Tooltip
              overlayStyle={ { minWidth: 150, maxWidth: "300px" } }
              title={
                data.wh_timezone ? <span>
                  Received on{ " " }
                  { moment(created_at).tz(data.wh_timezone).format(AppConfig.dateTimeFormat) }{ " " }
                  { moment.tz(data.wh_timezone).format("z") }
                </span> : undefined
              }
              getPopupContainer={ (triggerNode) => triggerNode.parentNode }
            >
              <span>{ moment().diff(moment(created_at), "days") }</span>
            </Tooltip>
          </div>
        ),
        align: "center",
      });
    }
    //if (props.showWeight === true) {
    finalColumns.push(
      {
        key: "items_count",
        title: `${I18n.t("order.qty")}`,
        dataIndex: "quantity",
        sorter: (a, b) => compareNumber(a.quantity, b.quantity),
        sortOrder: filterPlaceHolder?.sortBy=== 'items_count' && filterPlaceHolder.sortByType,
        width: 70,
        align: "center",
      },
      {
        key: "weight",
        title: (
          <Fragment>
            <Row>
              <Col className="alignCenter">
                { I18n.t("order.weight_short_form") }
              </Col>
              <Col className="alignCenter"> ({ AppConfig.weight_unit })</Col>
            </Row>
          </Fragment>
        ),
        dataIndex: "weight",
        render: (data, record) => (
          <div className="alignRight paddingRight10">
            { !isEmpty(data) ? data : "" }
          </div>
        ),
        width: 70,
        // sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sorter: (a, b) => compareNumber(a.weight, b.weight),
        sortOrder: filterPlaceHolder?.sortBy=== 'weight' && filterPlaceHolder.sortByType,
        align: "right",
      }
    );
    //}
    if (
      props.currentFilter === "ASSIGNED" ||
      props.currentFilter === "DISPATCHED" ||
      props.currentFilter === "COMPLETED" || props.currentFilter === ""
    ) {
      finalColumns.splice(2, 0, {
        key: "driver",
        title: I18n.t("general.Driver"),
        // render: (data) => <div>{ data.driver_name }</div>,
        render: (data, record) => retrieveOrderValue(record,'driver_name',false),
      });
    }
    finalColumns.push({
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "level_of_service",
        // sorter: (a, b) => compareString(a.levelOfService, b.levelOfService),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        sortOrder: filterPlaceHolder?.sortBy=== 'los' && filterPlaceHolder.sortByType,
        align: "left",
        width: 140,
        render: (data, record) => {
          if (record.type_of_order !== "T") return record.levelOfService;
          const originLos = record?.origin?.level_of_service;
          const destinationLos = record?.destination?.level_of_service;
          const los = originLos && destinationLos ? `${originLos} - ${destinationLos}` : originLos || destinationLos;
          return (
            <div>
              { los }
            </div>
          );
        },
      });
      finalColumns.push({
        key: "zone_name",
        title: "Zones",
        width: 150,
        dataIndex: "zoneName",
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => compareString(a.zoneName, b.zoneName),
        sortOrder: filterPlaceHolder?.sortBy=== 'zone_name' && filterPlaceHolder.sortByType,
        render: (text, data) => (text ? <div className="minWidth">{ setZones(data) }</div> : ''),
        ellipsis: true,
      });
      if (props.screen_from === "orders" || props.screen_from === "dispatches" || props.screen_from === "pendingOrders") {
        finalColumns.push({
          key: "hawb",
          title: I18n.t("general.hawb"),
          width: 115,
          dataIndex: "hawb",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          sortOrder: filterPlaceHolder?.sortBy=== 'hawb' && filterPlaceHolder.sortByType,
          render: (data, record) => (
            <Row
              type="flex"
              style={{ flexFlow: "unset" }}
              gutter={4}
              align="middle"
            >
              <Col>
                {props.showTriggerEdit ? (
                  <Link onClick={() => props.handleEditOrder(record.id)}>
                    {ellipseText(data, null, true, null, false)}
                  </Link>
                ) : props.showDetailsLink !== false ? (
                  <Link onClick={() => props.gotoOrderDetails(record.id)}>
                    {ellipseText(data, null, true, null, false)}
                  </Link>
                ) : (
                  <></>
                )}
              </Col>
              {data && (<Col span={3}>
                <Copyable text={data} />
              </Col>)}
            </Row>
            // <OrderFieldDisplay
            //   data={ record }
            //   toDisplay="hawb"
            //   showTriggerEdit={ props.showTriggerEdit }
            //   editClick={ () => props.handleEditOrder(record.id) }
            //   refreshCallback={ props.refreshCallback }
            //   showEditIcon={
            //     ![ "REJECTED" ].includes(record.status)
            //       ? true
            //       : false
            //   }
            // />
          ),
        });
        finalColumns.push({
          key: "mawb",
          title: I18n.t("general.mawb"),
          width: 115,
          dataIndex: "mawb",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          sortOrder: filterPlaceHolder?.sortBy=== 'mawb' && filterPlaceHolder.sortByType,
          render: (data, record) => (
            <Row
              type="flex"
              style={{ flexFlow: "unset" }}
              gutter={4}
              align="middle"
            >
              <Col>
                {props.showTriggerEdit ? (
                  <Link onClick={() => props.handleEditOrder(record.id)}>
                    {ellipseText(data, null, true, null, false)}
                  </Link>
                ) : props.showDetailsLink !== false ? (
                  <Link onClick={() => props.gotoOrderDetails(record.id)}>
                    {ellipseText(data, null, true, null, false)}
                  </Link>
                ) : (
                  <></>
                )}
              </Col>
              {data && (
                <Col span={3}>
                  <Copyable text={data} />
                </Col>
              )}
            </Row>
            // <OrderFieldDisplay
            //   data={ record }
            //   toDisplay="mawb"
            //   showTriggerEdit={ props.showTriggerEdit }
            //   editClick={ () => props.handleEditOrder(record.id) }
            //   refreshCallback={ props.refreshCallback }
            //   showEditIcon={
            //     ![ "REJECTED" ].includes(record.status)
            //       ? true
            //       : false
            //   }
            // />
          ),
        });
        finalColumns.push({
          key: "reference_1",
          width: 115,
          title: `${I18n.t("order.reference")} 1`,
          dataIndex: "reference_1",
          sorter: true,
          sortDirections: [ "ascend", "descend", "ascend" ],
          sortOrder: filterPlaceHolder?.sortBy=== 'reference_1' && filterPlaceHolder.sortByType,
          render: (text, record) => (
            <OrderFieldDisplay data={ record } toDisplay="reference_1" showPopupContainer={ true } charsToLimit={null} />
          ),
        });
        finalColumns.push({
          key: "reference_2",
          width: 115,
          title: `${I18n.t("order.reference")} 2`,
          dataIndex: "reference_2",
          sorter: true,
          sortDirections: [ "ascend", "descend", "ascend" ],
          sortOrder: filterPlaceHolder?.sortBy=== 'reference_2' && filterPlaceHolder.sortByType,
          render: (text, record) => (
            <OrderFieldDisplay data={ record } toDisplay="reference_2" showPopupContainer={ true } charsToLimit={null} />
          ),
        });
        finalColumns.push({
          key: "vehicle_type",
          title: I18n.t("order.vehicle_type"),
          dataIndex: "vehicle_type",
          render: (data, record) => {
            return (
              <VehicleImageData
                record={record.vehicle_type}
                vhType={props.vhType}
                showVehicleTitle
              />
            );
          },
          width: 130,
      });
      finalColumns.push({
        key:"vehicle_image",
        title: I18n.t("vehicleTypes.imgTitle"),
        dataIndex:"vehicle_image",
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={props.vhType}
              width={"30"}
              borderRadius={"5"}
              align={"center"}
              showImage
            />
          );
        },
        // width: 100,
      });
      finalColumns.push({
        key: "estimate",
        title: `${I18n.t("order.quote")} ($)`,
        dataIndex: "estimate",
        render: (data, record) => { 
          return <EstimateData record={record} updateEstimateVal = {props.updateListEstimate}/>;},
        width: 130,
      });
      }
    if (props.currentFilter === "EXCEPTION") {
      finalColumns.push({
        key: "EXCEPTION",
        title: I18n.t("general.exception_reason"),
        dataIndex: "exception_message",
        render: (text) => ( //Show me the tble response from api
          <div>
            { !isEmpty(text) && (
              <Popover
                title={ I18n.t("general.reason") }
                content={ <div>{ text }</div> }
                placement="topLeft"
                overlayStyle={ { width: 350 } }
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
                <span className="textRed">{ ellipseText(text, 45) }</span>
              </Popover>
            ) }
          </div>
        ),
      });
    }
    if (props.currentFilter === "ON_HOLD") {
      finalColumns.push({
        key: "ONHOLD", 
        title: I18n.t("general.onhold_reason"),
        dataIndex: "on_hold_reason",
        render: (text) => (
          <div>
            { !isEmpty(text) && (
              <Popover
                title={ I18n.t("general.onhold_reason") }
                content={ <div>{ text }</div> }
                placement="topLeft"
                overlayStyle={ { width: 350 } }
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
              <span className="textRed">{ ellipseText(text, 45) }</span> 
              </Popover>
             ) } 
          </div>
        ),
      });
    }
    else if (props.currentFilter === "REJECTED") {
      finalColumns.push({
        key: "REJECTED",
        title: I18n.t("general.reason"),
        dataIndex: "rejection_message",
        render: (text) => (
          <div>
            { !isEmpty(text) && (
              <Popover
                title={ I18n.t("general.reason") }
                content={ <div>{ text }</div> }
                placement="topLeft"
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
                {/* <Icon type="warning" style={{ color: "red" }} />
                &nbsp;&nbsp;&nbsp; */}
                <span className="textRed">{ ellipseText(text, 45) }</span>
              </Popover>
            ) }
          </div>
        ),
      });
    } else {
    }
    if (!_.find(finalColumns, {key: 'status'}) && props.currentFilter === "" || props.screen_from === "dispatches") {
      // finalColumns.push({
      //   key: "status",
      //   title: I18n.t("general.status"),
      //   dataIndex: "status",
      // });
      finalColumns.push({
        key: 'status',
        title: I18n.t("general.status"),
        dataIndex: "status",
        render: (data, record) => retrieveOrderValue(record, 'status', true),
        width: hasLocationRecord ? 160 : 75,
      });
    }

    const renderActionButtons = (data) => {
      const collectedIconsJsx = [];
      const { account_limit_exceeded } = data;
      const currentOrg = props.currentOrg;
      const currentFilter = props.currentFilter === "" ? "ALL" : props.currentFilter;
      const isAllStatus = currentFilter === "ALL" ? true : false;
      const incomingOrgId = _.get(currentOrg, "code", null);
      const hillLogisticsOrgId = "HILL";
      const verifyManualBol = _.get(data, "type_of_order",null) !== "T" ? _.get(data, "bol_picture", null) : {
        origin: _.get(data, "bol_picture", null),
        destination: _.get(data, "delivery_bol_picture", null)
      };
      const isVerifyManualBolValid = verifyManualBol 
      ? (typeof verifyManualBol === 'object' 
        ? Object.values(verifyManualBol).some(value => !!value) 
        : !_.isEmpty(verifyManualBol))
      : false;

      if (account_limit_exceeded) {
        collectedIconsJsx.push(
          <Tooltip
            title={ I18n.t("order.account_limit_exceeded") }
            placement="left"
            key="accountLimitExceeded"
          >
            <Icon type="info-circle" style={ { color: "red" } } />
          </Tooltip>
        );
      }else{
        // empty div to maintain the alignment
        collectedIconsJsx.push(<div key="emptyDiv1"></div>);
      }

      if (
        checkServiceExistance("COSU") &&
        ![ "PENDING", "REJECTED" ].includes(data.status)
      ) {
        collectedIconsJsx.push(
          <Tooltip
            title={ I18n.t("tooltip.change_status") }
            placement="left"
            overlayStyle={ { marginRight: 30 } }
            key="changeStatus"
          >
           {""} <SettingIcon handleClick={ () => props.statusChange(data.id) } />
          </Tooltip>
        );
      }else{
          collectedIconsJsx.push(<div key="emptyDiv2"></div>);
      }

      const shouldRenderEditIcon =
        ![
          "INTRANSIT",
          "PICKEDUP",
          "CLOSED",
          "ARCHIVED",
          "PENDING",
          "REJECTED",
        ].includes(data.status) && checkServiceExistance("SCA");

      if (shouldRenderEditIcon) {
        collectedIconsJsx.push(
          <Tooltip
            title={ I18n.t("tooltip.edit") }
            placement="left"
            key="editIcon"
          >
            {""}<EditIcon handleClick={ () => props.handleEditOrder(data.id) } />
          </Tooltip>
        );
      }else{
          collectedIconsJsx.push(<div key="emptyDiv2"></div>);
      }

      if (checkServiceExistance("OBLR")) {
        collectedIconsJsx.push(
          <Tooltip
            title={ I18n.t("BillOfRating.bill_of_rating_title") }
            placement="left"
            overlayStyle={ { marginRight: 30 } }
            key="billOfRating"
          >
            <Icon
              type="file-done"
              className="billOfLadingIcon"
              onClick={ () =>
                props.handleBillOfRating(data.id, data.customer_order_number)
              }
            />
          </Tooltip>
        );
      }else{
          collectedIconsJsx.push(<div key="emptyDiv2"></div>);
      }

      if(incomingOrgId === hillLogisticsOrgId && isVerifyManualBolValid){
        collectedIconsJsx.push(
          <ManualBolViewer
            verifyManualBol={verifyManualBol}
            manualBol={manualBol}
            key="manualBol"
            title={data}
          />
        );        
      }else{
        collectedIconsJsx.push(<div key="emptyDiv2"></div>);
      }

      if (
        props.screen_from !== "routes" &&
        data.nav_route_status &&
        data.nav_route_name
      ) {
        collectedIconsJsx.push(
          <Tooltip
            title={
              data.nav_route_status
                ? `${data.nav_route_status} - ${data.nav_route_name}`
                : I18n.t("menu.routes")
            }
            placement="left"
            overlayStyle={ {
              width: 250,
              maxWidth: "400px",
              marginRight: "150px",
            } }
            mouseEnterDelay={ AppConfig.tooltipDelay }
            key="branchesIcon"
          >
            {""}<BranchesIcon
              handleClick={ () =>
                hasRoutesAccess ? props.navigateToRoute(
                  data.nav_route_id,
                  data.nav_route_status,
                  data.scheduled_start_datetime_with_tz
                ) : null
              }
            />
          </Tooltip>
        );
      }else{
          collectedIconsJsx.push(<div key="emptyDiv2"></div>);
      }

      if (data.status === "COMPLETED") {
        if (checkServiceExistance("COB")) {
          collectedIconsJsx.push(
            <Tooltip
              title="Starting billing process"
              placement="left"
              overlayStyle={ {
                width: 180,
                maxWidth: "300px",
                marginRight: "100px",
              } }
              mouseEnterDelay={ AppConfig.tooltipDelay }
              key="generateInvoice"
            >
              <Icon
                type="file-pdf"
                style={ { color: "#7c1034" } }
                onClick={ () => props.generateInvoice(data.id, "false") }
              />
            </Tooltip>
          );
        }else{
          collectedIconsJsx.push(<div key="emptyDiv2"></div>);
        }

        collectedIconsJsx.push(
          <Tooltip
            title={ I18n.t("survey.view_submitted") }
            placement="left"
            mouseEnterDelay={ AppConfig.tooltipDelay }
            overlayStyle={ {
              width: 200,
              maxWidth: "300px",
              marginRight: "100px",
            } }
            key="surveyDetails"
          >
            {""}<FormIcon
              handleClick={ () => props.handleSurveyDetails(data.id) }
            />
          </Tooltip>
        );
      }else{
          collectedIconsJsx.push(<div key="emptyDiv2"></div>);
      }

      // Calculate the total span dynamically
      const totalSpan = 24;
      const minSpanPerIcon = 3;
      // const spanPerIcon = isAllStatus || props.currentFilter === 'COMPLETED' ? 3 : totalSpan / collectedIconsJsx.length;
      const spanPerIcon = isAllStatus || props.currentFilter === 'COMPLETED'
      ? minSpanPerIcon
      : totalSpan / collectedIconsJsx.length;


      return (
        <Row gutter={ 8 } type="flex" justify="start">
          { collectedIconsJsx.map((icon, index) => (
            <Col span={ spanPerIcon } key={ index }>
              { icon }
            </Col>
          )) }
        </Row>
      );
    };

    const renderItems = (data) => {
      {
        return (
          <div
            className={
              props.currentFilter === "" ||
              props.currentFilter === "COMPLETED" ||
              props.currentFilter === "EXCEPTION"
                ? "alignLeft table-action-btns"
                : "alignCenter table-action-btns"
            }
          >
            {renderActionButtons(data)}
          </div>
        );
      }
    };

    if (props.showActions !== false) {
      finalColumns.push({
        key: "items",
        fixed: "right",
        render: (data) => renderItems(data),
        align: "center",
        width: props.currentFilter === "" || props.currentFilter === "COMPLETED" ? 150 : 90
      });
    } else if(props.isShipper){
      finalColumns.push({
        key: "view",
        title: I18n.t("general.view"),
        dataIndex: "customer_order_number",
        width: 50,
        align: 'center',
        fixed: "right",
        render: (data, record) =>(
          <CustomerOrderDetails
            order_no={data}
            order={{
              id: record.id,
              customer_order_number: data,
            }}
            showEdit={false}
            showIcon={true}
          />
        )
      })
    }
    

    switch (props.screen_from) {
      case 'orders':
        finalColumns = (addColumnsFromConfiguration('order_list_view_columns', props.displayConfiguration , finalColumns));
        break;
      case 'pendingOrders':
        finalColumns = (addColumnsFromConfiguration('pendingOrders_list_view_columns', props.displayConfiguration , finalColumns));
        break;
      case 'dispatches':
        finalColumns = (addColumnsFromConfiguration('dispatches_unallocated_list', props.displayConfiguration , finalColumns));
        break;
      default:
        console.warn(`Unknown screen_from value: ${props.screen_from}`);
    }

    setResizablecolumns(finalColumns);
  }, [ props ]);

  return (
    <>
    <BaseList2
      data={ props.data }
      rowSelection={ props.rowSelection }
      tableChange={ props.tableChange }
      columns={ resizablecolumns }
      tableName={ "orders_page_orders_list" }
      pagination={ props.pagination }
      scroll={ props.scroll }
      renderExtraHeader={ props.renderExtraHeader }
      // rowClassName={ props.rowClassName }
    />
    </>
    );
};

OrdersList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
  refreshCallback: PropTypes.func,
};

OrdersList.defaultProps = {
  screen_from: "",
  showTriggerEdit: false,
  resolvedAddressCallback: () => { },
  refreshCallback: () => { },
};
export default OrdersList;
