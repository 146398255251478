import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Steps, Popover, Button } from '../../common/UIComponents';

const customDot = (dot, props) => {
  const {
    description,
  } = props;
  if (description) {
    return (
      <Popover content={
        <div style={{ width: '165px', textAlign: 'center' }}>
          {
            description.split('\n').map(text => <div>{text}</div>)
          }
        </div>}
      >
        {dot}
      </Popover>
    );
  }
  return (dot);
};

const Timeline = props => (
  <div>
    <Row gutter={16}>
      <Col xs={24} className="fontSize11">
        <Steps progressDot current={props.current} size="small">
          {
            props.steps
              .map(step => (
                <Steps.Step
                  key={step.key}
                  // icon={<Button size="small" style={{ backgroundColor: `#${step.bgColor}`, color: `#${step.fgColor}` }} >{step.stopNo}</Button>}
                  title={
                    step.location_type === 'WH' ?
                      <Button size="small" style={{ backgroundColor: `#${step.bgColor}`, color: `#${step.fgColor}` }} shape="circle" icon="home" />
                      :
                      <Button size="small" style={{ backgroundColor: `#${step.bgColor}`, color: `#${step.fgColor}` }} >{step.name}</Button>
                  }
                  description={step.description}
                />
              ))
          }
        </Steps>
        {/* <Steps
          // progressDot={(dot, properties) => customDot(dot, properties)}
          // current={props.current}
          // className='route-tracking-steps'
          size="small"
        >
          {
            props.steps
              .map(step => (
                <Steps.Step
                  key={step.name}
                  icon={<Button size="small" style={{ backgroundColor: `#${step.bgColor}`, color: `#${step.fgColor}` }} >{step.stopNo}</Button>}
                  description={step.description}
                />
              ))
          }
        </Steps>
        */}
      </Col>
    </Row>
  </div>
);

Timeline.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape()),
  current: PropTypes.number,
};

Timeline.defaultProps = {
  steps: [],
  current: 0,
};

export default Timeline;
