import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment';


import { Col,FormItem, Input, Row} from '../../common/UIComponents';
import {alertMessage, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import FormButtons from '../common/FormButtons';
import { useForm } from '../../helpers/useForm';
import MobileInput from '../common/MobileInput';
import { loadGoogleMaps } from '../../utils/Utils';
import AddressForm from '../common/AddressForm';
import FormErrors from '../common/FormErrors';
import { saveLocation } from '../../api/PredefinedStops';
import MaterialTimePicker from '../orders/MaterialTimePicker';
import { OrgContext } from '../../context/OrgContext';
import AddressSearch from '../../stories/components/AddressSearch';

const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


function PredefinedStopForm(props) {
    const {
        initialValues = {},
        onCancel = () => { },
        onSave = () => { },
    } = props;
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const [isMilitaryTime , setIsMilitaryTime] = useState(false);

  useEffect(() => {
    if (organizationSettings && organizationSettings.is_military_time ===
      "true") {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [ organizationSettings ]);


    const [gMapsReady, setGMapsReady] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    
    const [values, errors, register, validate] = useForm({
        id: {
            initialValue: null,
            required: false
        },
        location_name: {
            required: true,
            error: "Name is required",
        },
        location_code: {
            required: false
        },
        address_line1: {
            required: true,
            error: I18n.t("predefined_stops.errors.address")
        },
        address_line2: {
            required: false,
            error: I18n.t("predefined_stops.errors.address")
        },
        city: {
            required: true,
            error: I18n.t("predefined_stops.errors.city")
        },
        state: {
            required:true,
            error: I18n.t("predefined_stops.errors.state")

        },
        country: {
            required: true,
            error: I18n.t("predefined_stops.errors.country")
        },
        zipcode: {
            required: true,
            error: I18n.t("predefined_stops.errors.zipcode")
        },
        first_name: {
            required: true,
            error: I18n.t("predefined_stops.errors.firstName")
        },
        last_name: {
            required: false,
            error: I18n.t("predefined_stops.errors.lastName")
        },
        email: {
            required: false,
        },
        phone: {
            required: true,
            error: I18n.t("predefined_stops.errors.phone")
        },
        start_time: {
            required: false,
            initialValue: null,
            excludeErrorWhen: value => value === null,
            error: I18n.t("predefined_stops.errors.start_time"),
            defaultError: "Start time is required"
        },
        end_time: {
            required: false,
            initialValue: null,
            excludeErrorWhen: value => values.start_time === null && value === null,
            onValidate: value => moment(value).isAfter(values.start_time),
            error: I18n.t("predefined_stops.errors.end_time"),
            defaultError: "End time is required",
        },
        l_type: {
            required: false,
            initialValue: "PS",
        },
        company_name: {
            required: false,
            error: I18n.t("predefined_stops.errors.company_name"),
        },
    });

    // same as componentDidMount
    useEffect(()=>{
        loadGoogleMaps(()=> {
            setGMapsReady(true);
        });
        if (initialValues){
            register('id')(initialValues.id);
            register('location_name')(initialValues.name);
            register('location_code')(initialValues.location_code);
            register('first_name')(initialValues.first_name);
            register('last_name')(initialValues.last_name);
            register('email')(initialValues.email);
            register('phone')(initialValues.phone);
            register('start_time')(!isEmpty(initialValues.start_time) ? moment(initialValues.start_time) : null);
            register('end_time')(!isEmpty(initialValues.end_time) ? moment(initialValues.end_time) : null);
            registerAddress(initialValues.l_address);
            register("company_name")(initialValues.company_name);
        }
    },[]);

    const handleSave = async () => {
        const isValidForm = validate();
        if(!isValidForm) return;

        try{

            const {
                id,
                start_time,
                end_time,
                location_code,
                location_name,
                l_type,
                phone,
                email,
                first_name,
                last_name,
                company_name,
            } = values;

            const payload = {
                id,
                first_name,
                last_name,
                start_time,
                end_time,
                location_code: location_code ? location_code.toUpperCase() : '',
                name: location_name,
                l_type,
                phone,
                email,
                l_address: getAddressObjectFromValues(values),
                company_name
            };

            setFormLoading(true);
            //TODO
            const response = await saveLocation(payload, id ===null);

            if(response.success){
                typeof onSave === 'function' && onSave(I18n.t('messages.saved'));
            }else{
                throw new Error(response.errors[0]);
            }

        }catch(error){
            alertMessage(error.message, 'error', 10);
        }finally{
            setFormLoading(false);
        }

    }


    const registerInput = ({ target: { value } }, label) => {
        register(label)(value);
    }

    const getAddressObjectFromValues = (vals) => {
        const obj = {
            address_line1: vals.address_line1,
            address_line2: vals.address_line2,
            state: vals.state,
            country: vals.country,
            city: vals.city,
            zipcode: vals.zipcode,
            l_type: vals.l_type
        };

        return obj;
    }
    const registerAddress = address => {
        
        const {
            address_line1,
            address_line2,
            city,
            state,
            zipcode,
            country
        } = address;

        register('address_line1')(address_line1);
        register('address_line2')(address_line2);
        register('city')(city);
        register('state')(state);
        register('zipcode')(zipcode);
        register('country')(country);

    }

    const handleAddressChange = (element, value, obj) => {

        if(obj){
            registerAddress(obj);
        }else{
            register(element)(value);
        }
    }

    return (
        <div>
            <Row gutter={16} className="padding10">
                <h3 style={{ fontWeight: "initial !important" }} className="marginLeft0">
                   {"Location Information"}
                </h3>
                <Row gutter={16} className="padding10">
                        <Col sm={12} xs={12} md={8} lg={12}>
                            <FormItem label={"Name"} require>
                                <Input
                                    style={{zIndex: 99}} 
                                    value={values.location_name} 
                                    onChange={e => registerInput(e, 'location_name')} 
                                />
                            </FormItem>
                        </Col>
                        <Col sm={12} xs={12} md={8} lg={12}>
                            <FormItem label={"Code"}>
                                <Input 
                                    className="textUpperCase"
                                    style={{ zIndex: 99 }}
                                    value={values.location_code} 
                                    onChange={e => registerInput(e, 'location_code')}
                                    disabled={initialValues}
                                 />
                            </FormItem>
                        </Col>
                    <div className="margin10">
                        {
                            gMapsReady && (
                            <FormItem
                                label={`${I18n.t("general.address")} ${I18n.t("general.search")}`}
                            >
                                <AddressSearch onSelect={ (address) => handleAddressChange("", "", address.l_address)} hidePredefined />
                                <AddressForm
                                    autoComplete
                                    address={getAddressObjectFromValues(values)}
                                    onChange={handleAddressChange}
                                    twoRows
                                />
                            </FormItem>
                            )
                        }
                    </div>
                </Row>
                <Row gutter={16}>
                    <h3 style={{fontWeight:"initial !important"}} className="marginLeft10">Operational Timings: </h3>
                    <Col sm={6} xs={6} md={4} lg={6}>
                        <FormItem label={I18n.t("predefined_stops.start_time")} className="timepickerContainer">
                            <MaterialTimePicker
                                onChange={ register('start_time') }
                                value={ !isEmpty(values.start_time) ? values.start_time : null }
                                computeDisabledHours={ () => [] }
                                computeDisabledMinutes={ () => [] }
                                isMilitaryTime={ isMilitaryTime }
                            />

                        </FormItem>
                    </Col>
                    <Col sm={6} xs={6} md={4} lg={6}>
                        <FormItem label={I18n.t("predefined_stops.end_time")} className="timepickerContainer">
                            <MaterialTimePicker
                                onChange={ register('end_time') }
                                value={ !isEmpty(values.end_time) ? values.end_time : null }
                                computeDisabledHours={ () => [] }
                                computeDisabledMinutes={ () => [] }
                                isMilitaryTime={ isMilitaryTime }
                            />
                        </FormItem>
                    </Col>

                </Row>
            </Row>
            <Row gutter={16}>
                    <h3 style={{ fontWeight: "initial !important" }} className="marginLeft10">
                        {"Contact Information"}
                    </h3>
                    <Col sm={12} xs={12} md={12} lg={8}>
                        <FormItem label={I18n.t("general.company_name")}>
                            <Input 
                                value={values.company_name}
                                onChange={e => registerInput(e, 'company_name')}
                            />
                        </FormItem>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={8}>
                        <FormItem label={"First Name"} require>
                            <Input 
                                value={values.first_name} 
                                onChange={e => registerInput(e, 'first_name')}
                            />
                        </FormItem>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={8}>
                        <FormItem label={"Last Name"}>
                            <Input 
                                value={values.last_name} 
                                onChange={e => registerInput(e, 'last_name')}
                            />
                        </FormItem>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={8}>
                        <FormItem label={I18n.t('general.contact_no')} require>
                            <MobileInput
                                handleInputChange={value => register('phone')(value)}
                                value= {values.phone}
                            />
                        </FormItem>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={8}>
                        <FormItem label={"Email"}>
                            <Input 
                                value={values.email}
                                onChange={e => registerInput(e, 'email')}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    {FormErrors([...new Set(Object.values(errors))])}
                </Row>
                <Row>
                    <Col xs={24}>
                        {FormButtons(formLoading, handleSave, onCancel )}
                    </Col>
                </Row>
        </div>
    )
}

export default PredefinedStopForm;
