/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from "react";
import _ from "lodash";
import { Draggable, Droppable } from "react-beautiful-dnd";
import BaseList from "../BaseList";
import {isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import Leg from "../../components/Tasks/Legs";
import {getOrderTypeLabel } from "../../helpers/orders";
import OrderAccessorials from "../orders/OrderAccessorials";
import {
  Icon,
  Popover,
  Row,
  Col,
  Tooltip,
  Popconfirm,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance } from "../../helpers/common";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import TypeOfOrder from "../orders/TypeOfOrder";
import accessorialIcon from "../../../assets/images/orderAccessorial.svg";
import DeleteIcon from "../common/DeleteIcon";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import EndUserTitle from "../common/EndUserTitle";
import PropTypes from "prop-types";
class OrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  
  setColumns = (props) => {
    this.columns = [
      {
        title: "#",
        key: "index",
        align: "center",
        render: (text, record, index) => index + 1,
        width: 30,
      },
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        width: 150,
        render: (text, record, index) => {
          const isTransfer = ["T", "LH"].includes(record.type_of_order);
          const isDelivery = props.currentStop && props.currentStop.location && props.currentStop.location.type_of_loc && props.currentStop.location.type_of_loc === "DELIVERY" || (props.stopType === "WH" && props.stopIndex === 0);
          const textToDisplay = isTransfer ? ( isDelivery ? text + "(D)" : text + "(P)") : text;
          if (props.showMoveAction === true && checkServiceExistance("NRTO")) {
            return (
              <Droppable droppableId="orderDroppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Draggable
                      key={record.customer_order_id}
                      draggableId={record.customer_order_id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragDisabled="true"
                        >
                          <div>
                            <CustomerOrderDetails
                              exprecord={record}
                              order_no={textToDisplay}
                              order={{
                                id: record.customer_order_id,
                                customer_order_number: text,
                              }}
                              showEdit={true}
                              editClick={() =>
                                this.props.editOrderClick(
                                  record.customer_order_id
                                )
                              }
                            />{" "}
                            &nbsp;&nbsp;
                            {props.stopType === "CS" && <Icon type="menu" />}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </div>
                )}
              </Droppable>
            );
          } else {
            return (
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                style={{
                  padding: "0 10px 0 5px",
                }}
                gutter={4}
              >
                <Col span={18}>
                  <CustomerOrderDetails
                    order_no={textToDisplay}
                    order={{
                      id: record.customer_order_id,
                      customer_order_number: text,
                    }}
                    exprecord={record}
                    showEdit={true}
                    editClick={() =>
                      this.props.editOrderClick(record.customer_order_id)
                    }
                  />
                </Col>
                <Col span={6}>
                  <TypeOfOrder
                    order={record}
                    orderTypeKey="type_of_order"
                    relatedOrderKey="related_order"
                    placement={"top"}
                    showBadge={true}
                  />
                </Col>
              </Row>
            );
          }
        }
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        render: (data, record) => (
          <div style={{ textAlign: "center" }}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
        width: 75,
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        render: (text, record) => (
            <OrderFieldDisplay
              data={record}
              toDisplay="hawb"
              showTriggerEdit={false}
              editClick={() =>
                this.props.editOrderClick(record.customer_order_id)
              }
              showEditIcon={true}
              charsToLimit={null}
            />
        ),
        width: 130,
      },
      {
        key: "mawb",
        dataIndex: "mawb",
        title: I18n.t("general.mawb"),
        render: (text, record) => (
            <OrderFieldDisplay
              data={record}
              toDisplay="mawb"
              showTriggerEdit={false}
              editClick={() =>
                this.props.editOrderClick(record.customer_order_id)
              }
              showEditIcon={true}
              charsToLimit={null}
            />
        ),
        width: 130,
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_1"
            charsToLimit={null}
            showOrderDetailsByReference={true}
            showTriggerEdit={false}
            editClick={() => this.props.editOrderClick(record.customer_order_id)}
          />
        ),
        width: 130,
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        width: 130,
        render: (text, record) => (
          <OrderFieldDisplay
            data={record} toDisplay="reference_2"
            charsToLimit={null}
            showOrderDetailsByReference={true}
            showTriggerEdit={false}
            editClick={() => this.props.editOrderClick(record.customer_order_id)}
          />
        ),
      },

      {
        key: "consignee",
        title: <EndUserTitle />,
        dataIndex: "consignee",
        render: (data,record) => (
          <div style={{ display: "flex" }}>
            <div>{record.customer_first_name || ''}</div>
          </div>
        ),
        width : 180
      },
      {
        key: "company_name",
        title: I18n.t("general.company_name"),
        dataIndex: "company_name",
        render: (data,record) => {
          const company_name = props.currentStop ? _.get(props.currentStop, "location.company_name", '') : '';
          return (
          <div style={{ display: "flex" }}>
            <div>{['LH', 'T'].includes(record.type_of_order) ? company_name: record.company_name}</div>
          </div>
        )
          },
        width : 180
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: "quantity",
        title: I18n.t("order.qty"),
        dataIndex: null,
        // if order type_of_order is lh use pieces else use items_count
        render: (data) => {
          const isLH = data.type_of_order === "LH";
          return (
            <div>
              {isLH ? data.pieces : data.items_count}
            </div>
          );
        },
        width:40,
      },
      {
        key: "weight",
        title: I18n.t("order.weight"),
        dataIndex: "weight",
        render: (data) =>
          !isEmpty(data) ? `${data} ${AppConfig.weight_unit}` : "NA",
      },
      {
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        width: 100,
      },
      {
        key: "account_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "los",
        reder: (data) =>
          !isEmpty(data) ? (
            <Tooltip title={I18n.t("los.label")} content={data}>
              {data}
            </Tooltip>
          ) : (
            ""
          ),
      }
    ];
    

    // if (props.currentStop && props.currentStop.deleted_order_ids && _.isArray(props.currentStop.deleted_order_ids) && props.currentStop.deleted_order_ids.length > 0) {
    //   this.columns.splice(0, 0, {
    //     title: '',
    //     key: 'is_deleted',
    //     align: 'center',
    //     render: (record) => {
    //       const deletedIds = props.currentStop.deleted_order_ids ? props.currentStop.deleted_order_ids : [];
    //       return (<div>
    //         {deletedIds.includes(record.customer_order_id) ? <Icon type="minus-circle" theme="filled" /> : ''}
    //       </div>);
    //     },
    //   });
    // }
    if (props.showBilling !== false && props.StopCard !== true) {
      this.columns.push({
        key: "billing_number",
        title: "Bill Number",
        dataIndex: "billing_number",
      });
    }
    // this.columns.push({
    //   key: "type_of_order",
    //   title: I18n.t("order.type"),
    //   dataIndex: "type_of_order",
    //   render: (text, record) => (
    //     <div>{getOrderTypeLabel(record, "type_of_order", "related_order")}</div>
    //   ),
    // });
    if (props.showAccessorial !== false && props.StopCard !== true) {
      this.columns.push({
        key: "accessorials",
        title: I18n.t("general.accessorial"),
        align: "center",
        render: (data, record) => {
          // let accessorialStatus = false;
          // const accessorials = record.account_accessorials || [];
          // const orderAccessorials = record.order_accessorial || [];
          // const selectedAccessorials = [];
          // const optedAccessorials = [];
          // orderAccessorials.forEach((accessorial) => {
          //   const accIndex = _.findIndex(accessorials, ['accessorial_key', accessorial.accessorial_key]);
          //   if (accIndex >= 0) {
          //     accessorials[accIndex].inputValue = accessorial.accessorial_value;
          //     accessorials[accIndex].is_completed = accessorial.is_completed;
          //     optedAccessorials.push(accessorials[accIndex]);
          //     selectedAccessorials.push(accessorial.accessorial_key);
          //     accessorialStatus = true;
          //   }
          // });
          const currentStop = this.props.currentStop
            ? this.props.currentStop
            : {};
          const stopAccessorials = currentStop.accessorial || [];
          const orderAccessorials = stopAccessorials.filter(
            (accessorial) => accessorial.order_id === record.customer_order_id
          );
          const accessorialStatus = orderAccessorials.length > 0;
          return (
            <div>
              {accessorialStatus && (
                <Popover
                  content={
                    <div style={{ maxWidth: 300 }}>
                      {false && (
                        <OrderAccessorials
                          order={record}
                          accessorials={optedAccessorials}
                          orderAccessorials={selectedAccessorials}
                          onChange={null}
                          isMobile={false}
                          showHeading
                          isReadOnly
                          perRow={1}
                          title={I18n.t("general.accessorial")}
                        />
                      )}
                      {orderAccessorials
                        .filter(
                          (accessorial) =>
                            !isEmpty(accessorial.accessorial_name)
                        )
                        .map((accessorial) => accessorial.accessorial_name)
                        .join(", ")}
                    </div>
                  }
                  placement="left"
                  title={I18n.t("general.accessorial")}
                >
                  <Icon
                    component={() => (
                      <img
                        style={{ height: 15, marginTop: -4 }}
                        src={accessorialIcon}
                      />
                    )}
                  />
                </Popover>
              )}
            </div>
          );
        },
      });
    }
    if (props.showPod !== false && props.StopCard !== true) {
      this.columns.push({
        key: "pod",
        title: "PoD",
        render: (data) => {
          const isWHstop =
            !isEmpty(this.props.stopType) && this.props.stopType === "WH";
          const pictures = [];
          const signatureImgs = [];
          const printPics = [];
          if (!isWHstop) {
            const stopPictures = props.currentStop.pictures || [];
            const orderPictures = stopPictures.filter(
              (picture) => picture.order_id === data.customer_order_id
            );
            if (orderPictures.length > 0) {
              orderPictures.forEach((pic) => {
                if (pic.picture && pic.picture.url) {
                  const formattedImage = pic.picture.url;
                  if (pic.image_type === "normal") {
                    pictures.push(formattedImage);
                  } else if (pic.image_type === "signature") {
                    signatureImgs.push(formattedImage);
                  } else if (pic.image_type === "print") {
                    printPics.push(formattedImage);
                  }
                }
              });
            }
            const signature = signatureImgs.length > 0 ? signatureImgs[0] : "";

            return pictures.length > 0 || (signature && signature !== "") ? (
              <Leg
                pictures={pictures}
                signature={signature}
                pagination={{ position: "none" }}
                size="small"
                showType="iconClick"
              />
            ) : (
              ""
            );
          }
          return "";

          // let orderItems = [].concat(props.currentStop.pickup_order_items, props.currentStop.drop_order_items);
          // orderItems = orderItems.filter(ordItem => ordItem.customer_order_id === data.customer_order_id);
        },
      });
    }
    if (props.showExceptions !== false && props.StopCard !== true) {
      this.columns.push({
        key: "exception",
        render: (data) => (
          <div>
            {data.status === "EXCEPTION" && !isEmpty(data.exception_message) && 
            data?.exception_message?.exception_message && (
              <Popover
                title="Exception"
                overlayClassName="popoverWidth"
                content={
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} className="textRed">
                      {data.exception_message.exception_message}
                    </Col>
                  </Row>
                }
              >
                <Icon type="warning" className="textRed" />
                &nbsp;&nbsp;
              </Popover>
            )}
          </div>
        ),
      });
    }
    const isWHstop = props.stopType && props.stopType === "WH";
    const currentStop = props.currentStop
            ? props.currentStop
            : {};
    const status = currentStop.status ? currentStop.status : "";
    const deletedIds = !isEmpty(currentStop.deleted_order_ids)
            ? currentStop.deleted_order_ids
            : [];
    if (props.showActions === true && !isWHstop && ["PENDING", "UNALLOCATED"].includes(status) ) {
      this.columns.push({
        key: "actions",
        fixed: "right",
        dataIndex: "customer_order_id",
        render: (id) => {
          return (
            <div className="cursorPointer textBold fontSize12" style={{display: "flex", justifyContent: "center"}}>
              {checkServiceExistance("DDO") &&
                !deletedIds.includes(id) && (
                  <Fragment>
                    <Popconfirm
                      placement="topRight"
                      title={I18n.t("messages.delete_confirm")}
                      onConfirm={(e) =>{
                        e.stopPropagation();
                        e.preventDefault();
                        props.deleteOrderClick(id, "order", currentStop.id, '', currentStop.location_type)
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onCancel={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip
                        title={I18n.t("general.delete_order_from_route")}
                        placement="bottomRight"
                      >
                        {/* <img src={unlink} style={{ width: 12, marginRight: 8 }} alt="unlink" />{I18n.t('general.remove')} */}
                        <span><DeleteIcon/></span>
                      </Tooltip>
                    </Popconfirm>
                    {/* props.showMoveAction && checkServiceExistance('NRTO') && <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.move_confirm', { field: '' })}
                  onConfirm={() => props.moveOrder(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title={I18n.t('preplan.move_order')} placement="bottom">

                    <Icon type="menu" /> &nbsp;&nbsp;
                  </Tooltip>
                </Popconfirm>
                */}
                  </Fragment>
                )}
            </div>
          );
        },
        width: 50,
      });
    }

    const isDispatchesPage = props.screen_from === "dispatches";
    const isStopsCard = props.screen_from === 'stops';
    if (!_.isEmpty(props.displayConfiguration) && isDispatchesPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const dispatch_routeOrders_columns = userConfiguration.dispatch_routeOrders_columns || orgConfiguration.dispatch_routeOrders_columns;
      const columns = [];
      if (
        typeof dispatch_routeOrders_columns !== undefined &&
        Array.isArray(dispatch_routeOrders_columns)
      ) {
        const initialColumns = this.columns.filter((col) => ["index"].includes(col.key) );
        if (initialColumns.length > 0) {
          columns.push(...initialColumns);
        }

        dispatch_routeOrders_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "actions");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
    if (!_.isEmpty(props.displayConfiguration) && isStopsCard) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const stops_list_view_columns = userConfiguration.stops_list_view_columns || orgConfiguration.stops_list_view_columns;
      const columns = [];
      if (
        typeof stops_list_view_columns !== undefined &&
        Array.isArray(stops_list_view_columns)
      ) {
        const initialColumns = this.columns.filter((col) => ["index"].includes(col.key) );
        if (initialColumns.length > 0) {
          columns.push(...initialColumns);
        }

        stops_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "actions");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default OrdersList;

OrdersList.propTypes = {
  editOrderClick: PropTypes.func,
};

OrdersList.defaultProps = {
  editOrderClick: () => {},
};