import React, { useEffect, useState } from 'react';
import _, { isEmpty, startsWith} from "lodash";
import { Card, Row, Steps } from 'antd';
import I18n from '../../common/I18n';
import userStore from '../../stores/UserStore';
import { QuoteLocationApis } from '../../api/QuotesApi';
import { alertMessage } from '../../common/Common';
import AppConfig from "../../config/AppConfig"
import ConsigneeDetails from './ConsigneeDetails';
import moment from 'moment';
import { checkIfRequiredFieldsAreFilled, validateMobile } from '../../helpers/common';
import WarehouseInfo from './WarehouseInfo';
const { Step } = Steps;

function QuoteOrderConsigneeDetails (props) {
  const { quoteFormData, currentQuote, setIsLoading ,handleFailure ,handleSuccess , clickedSave , setCurrentQuote,isMilitaryTime,quoteDetailsOnSave,handleOrderDetailsChange } = props;

  const [errors, setErrors] = useState({})
  const[warehouseChecked , setWarehouseChecked] = useState(false);

  const checkIfValid = (details) => {
    const errs = [];
    const orderTypeDetails =  quoteFormData[details.key];
    // const requiredFields = ['first_name' , 'phone_number_one' , {key: 'l_address' , fields: ['address_line1' , 'city' , 'state' , 'country' , 'zipcode']}];
    const requiredFields = [{key: 'l_address' , fields: ['address_line1' , 'city' , 'state' , 'country' , 'zipcode']}];

    const requiredFieldsFilled = checkIfRequiredFieldsAreFilled(orderTypeDetails , requiredFields);
    if(!requiredFieldsFilled) {
      errs.push('Please fill all the required fields');
    }

      if(!isEmpty(orderTypeDetails.phone_number_one) && !validateMobile(orderTypeDetails.phone_number_one)) {
      errs.push('Please enter a valid phone number one');
    }

    const preference = orderTypeDetails.preferences[0];
    if (preference.item_option[ 0 ] === "ANY") {
      if (_.isEmpty(preference.item_preference)) {
        errs.push('Please select Valid Appointment Date')
      }
    } else if (preference.item_option[0] === 'CUSTOM') {
      if (_.isEmpty(preference.item_preference)) {
        errs.push('Please select Valid Appointment Date')
      }
      if (_.isEmpty(preference.start_time) && _.isEmpty(preference.end_time)) {
        errs.push('Please select valid start and end time')
      }
    }
    return {
      errors: { [details.key]: errs },
    }
  }

  const handleWarehouseCheck = (value) => {
    setWarehouseChecked(value);
    setCurrentQuote({...currentQuote , wh_storage: value});
  }

  useEffect(() => {
    if (!_.isEmpty(quoteFormData)) {
      setWarehouseChecked(quoteFormData.wh_storage);
    }
  }, [])
  
  useEffect(() => {
    if (clickedSave) {
      handleSave()
    }
  }, [clickedSave])

  const handleSave = async () => {
    let orderTypeErrors = {}
      let validVals = true
      AppConfig.quoteOrderTypes[currentQuote.type_of_order].types.map((details, index) => {
        const { errors } = checkIfValid(details)
        orderTypeErrors = { ...orderTypeErrors, ...errors }
        if (errors[details.key].length > 0) {
          validVals = false
        }
      })
      if (validVals) {
        const { pickup_params, drop_params } = quoteFormData;

        // check if pickup and drop params are valid
        let pickupParams = _.pick(pickup_params, ['first_name', 'phone_number_one', 'l_address']);
        let dropParams = _.pick(drop_params, ['first_name', 'phone_number_one', 'l_address']);
        const pickuplocation = _.pick(pickup_params.l_address, ['city', 'country', 'state', 'zipcode']);
        const droplocation = _.pick(drop_params.l_address, ['city', 'country', 'state', 'zipcode']);
        const pickupStartTime = moment(pickup_params.preferences[0].start_time).startOf('minute');
        const deliveryStartTime = moment(drop_params.preferences[0].start_time).startOf('minute');
        pickupParams = _.omit(pickupParams, ['l_address']);
        dropParams = _.omit(dropParams, ['l_address']);
        const pickupParamsWithLocation = {...pickupParams, ...pickuplocation};
        const dropParamsWithLocation = {...dropParams, ...droplocation};
        if(_.isEqual(pickupParamsWithLocation, dropParamsWithLocation)){
          alertMessage("Pickup and Drop location should not be same" , "error");
          handleFailure();
          return;
        }

        if ((moment(pickup_params.preferences[ 0 ].item_preference).isAfter(moment(drop_params.preferences[ 0 ].item_preference), 'day')) && ['M', 'T'].includes(currentQuote.type_of_order)) {
          alertMessage("Pickup date should be earlier than drop date", "error", 5);
          handleFailure();
          return;
        }
        if (
          pickup_params.preferences[0].item_option[0] === 'CUSTOM' &&
          drop_params.preferences[0].item_option[0] === 'CUSTOM'
        ) {
          if (
            moment(pickup_params.preferences[0].start_time).isSame(
              moment(drop_params.preferences[0].start_time),
            )
          ) {
            alertMessage('Pickup and drop time should be different', 'error', 5)
            handleFailure()
            return
          }
          if (pickupStartTime.isAfter(deliveryStartTime)) {
            alertMessage(
              'Pickup time should be lesser than drop time',
              'error',
              5,
            )
            handleFailure()
            return
          }
        }

        let locationsSavedSuccessfully = true;
        for (const details of  AppConfig.quoteOrderTypes[ currentQuote.type_of_order ].types) {
          try {
            const result = await handleLocationSave(details.key);
            if (!result) {
              locationsSavedSuccessfully = false;
            }
          } catch (error) {
            console.error('An API call failed. Exiting the loop.');
            locationsSavedSuccessfully = false;
            break; // If an API call fails, exit the loop
          }
        }

        // AppConfig.quoteOrderTypes[ currentQuote.type_of_order ].types.forEach((details, index) => {
        //   if(!handleLocationSave(details.key)){
        //     locationsSavedSuccessfully = false;
        //   }
        //  }
        // );

        if(locationsSavedSuccessfully){
          alertMessage(I18n.t('messages.saved'))
          handleSuccess()
        }else{
          alertMessage(I18n.t('messages.error'), 'error')
        }

      }else{
        setErrors(orderTypeErrors)
        handleFailure();
      }
  }

  const formatPayload = (obj, addressType) => {
    let newObj = {}
    const verifyDropParams = addressType === "drop_params"
    const verifyPickupParams = addressType === "pickup_params"
    const deliverLosId = !isEmpty(quoteDetailsOnSave?.delivery_los_id) ? quoteDetailsOnSave?.delivery_los_id : quoteDetailsOnSave?.drop_params?.los_id;
    const pickupLosId = !isEmpty(quoteDetailsOnSave?.los_id) ? quoteDetailsOnSave?.los_id : quoteDetailsOnSave?.pickup_params?.los_id;
    const deliveryLosName = !isEmpty(quoteDetailsOnSave?.delivery_los_name) ? quoteDetailsOnSave?.delivery_los_name : quoteDetailsOnSave?.drop_params?.los_name;
    const pickupLosName = !isEmpty(quoteDetailsOnSave?.los_name) ? quoteDetailsOnSave?.los_name : quoteDetailsOnSave?.pickup_params?.los_name;
    const deliveryLosCode = !isEmpty(quoteDetailsOnSave?.delivery_level_of_service) ? quoteDetailsOnSave?.delivery_level_of_service : quoteDetailsOnSave?.drop_params?.los_code;
    const pickupLosCode = !isEmpty(quoteDetailsOnSave?.level_of_service) ? quoteDetailsOnSave?.level_of_service : quoteDetailsOnSave?.pickup_params?.los_code;
    const current_role = userStore.currentRole()
    newObj.type_of_order = obj.type_of_order
    newObj.appointments = obj.appointments.map((appt) => ({
      appt_date: moment(appt.item_preference).format('YYYY-MM-DD 00:00:00'),
      slots: appt.item_option,
      has_expedite: appt.has_expedite === true,
      confirmed: true,
      start_time:
        appt.item_option.includes('CUSTOM') && appt.start_time
          ? moment(appt.start_time).format('HH:mm')
          : '',
      end_time:
        appt.item_option.includes('CUSTOM') && appt.end_time
          ? moment(appt.end_time).format('HH:mm')
          : '',
    }))
    newObj.first_name = obj.first_name || ''
    newObj.last_name = obj.last_name
    newObj.phone_number_one = obj.phone_number_one || ''
    newObj.phone_number_two = obj.phone_number_two || ''
    newObj.email = obj.email
    newObj.address = {
      company_name: obj.company_name,
      address_line1: obj.l_address.address_line1,
      address_line2: obj.l_address.address_line2,
      city: obj.l_address.city,
      state: obj.l_address.state,
      country: obj.l_address.country,
      zipcode: obj.l_address.zipcode,
      l_type: obj.l_type,
      id: !isEmpty(obj.id) ? obj.id : '',
      type_of_loc: obj.type_of_loc
        ? obj.type_of_loc
        : addressType && addressType === 'pickup_params'
        ? 'R'
        : 'D',
    }
    newObj.company_name = obj.company_name || ''
    newObj.has_expedite = obj.appointments[0]?.has_expedite === true ? true : false
    newObj.current_role = current_role
    newObj.org_id = obj.organization_id
    newObj.account_id = obj.account_id
    newObj.quote_id = obj.quote_id
    newObj.type_of_loc = obj.type_of_loc
      ? obj.type_of_loc
      : addressType && addressType === 'pickup_params'
      ? 'R'
      : 'D'
      newObj.los_id = verifyDropParams ? deliverLosId : pickupLosId;
      newObj.los_name = verifyDropParams ? deliveryLosName : pickupLosName;
      newObj.los_code = verifyDropParams ? deliveryLosCode : pickupLosCode;
      newObj.wh_storage = verifyPickupParams &&  warehouseChecked ;
      newObj.loc_order_sequence = verifyPickupParams ? 0 : 1;
    return newObj
  }

  const handleLocationSave = async (orderType) => {
    try {
      setIsLoading(true)
      const payload = { ...quoteFormData[orderType] }
      payload.organization_id = userStore.getStateValue('selectedOrg')
      payload.account_id = quoteFormData.account_id
      payload.quote_id = currentQuote.id
      payload.type_of_order = currentQuote.type_of_order
      payload.appointments = payload.preferences
      delete payload.preferences
      const convertedPayload = formatPayload(payload, orderType)
      const response = await QuoteLocationApis.create(convertedPayload)

      if (response.success) {
        const result = response.data
        const { quote } = result
        setCurrentQuote({ ...currentQuote, ...quote })
        if (result.errors && result.errors.length > 0) {
          setErrors({
            ...errors,
            [orderType]: result.errors,
          })
          handleFailure()
          return false
        } else {
          // alertMessage(I18n.t('messages.saved'))
          return true;
        }
      } else {
        handleFailure()
        setErrors({
          ...errors,
          [orderType]: response.errors,
        })
        return false
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row className="quote-order-consignee-details" type="flex" gutter={ 4 }>
      { currentQuote.type_of_order === 'M' || currentQuote.type_of_order === "T" ? ( // if move order then render both pickup and drop
        AppConfig.quoteOrderTypes[currentQuote.type_of_order].types.map((details, index) => {
          return (
            <ConsigneeDetails
              { ...props }
              details={ details }
              key={ index }
              errors={ errors[ details.key ] }
              isMilitaryTime={ isMilitaryTime }
              span={ 12 }
              currentQuote={ currentQuote }
              handleWarehouseCheck={ handleWarehouseCheck }
              warehouseChecked={ warehouseChecked }
            />
          );
        })
      ) : (
        currentQuote.type_of_order === 'D' ? (
          AppConfig.quoteOrderTypes[ currentQuote.type_of_order ].types.map((details, index) => {
            return (
              <Steps progressDot direction={ "vertical" } current={ 1 }>
                <Step status='finish' description={
                  <Card size="small" title={ "Warehouse Details" } style={ {
                    height: '100%',
                  } }>
                    <WarehouseInfo data={ currentQuote.wh_location_details} />
                  </Card>
                } />
                <Step status='finish' description={
                  <ConsigneeDetails
                    { ...props }
                    details={ details }
                    key={ index }
                    errors={ errors[ details.key ] }
                    isMilitaryTime={ isMilitaryTime }
                    span={ 24 }
                  />
                } />
                <Step status='finish' />
              </Steps>
            );
          })
        ) : (
          AppConfig.quoteOrderTypes[ currentQuote.type_of_order ].types.map((details, index) => {
            return (
              <Steps progressDot direction={ "vertical" } current={ 1 }>
                <Step status='finish' description={
                  <ConsigneeDetails
                    { ...props }
                    details={ details }
                    key={ index }
                    errors={ errors[ details.key ] }
                    isMilitaryTime={ isMilitaryTime }
                    span={ 24 }
                  />
                } />
                <Step status='finish' description={
                  <Card size="small" title={ "Warehouse Details" } style={ {
                    height: '100%',
                  } }>
                    <WarehouseInfo data={ currentQuote.wh_location_details } />
                  </Card>
                } />
                <Step status='finish' />
              </Steps>
            );
          })
        )
      )
      }
    </Row>
  );
}

export default QuoteOrderConsigneeDetails
