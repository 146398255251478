import React, { Fragment, useContext, useState } from "react";
import { Button, Modal, DatePicker, Popconfirm, Row, Col, Tooltip, Icon, Input } from "antd";
import moment from "moment";
import { FormItem } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage, formatCode } from "../../common/Common";
import { manageContract, updateLOS } from "../../api/Los";
import { UserContext } from "../../context/UserContext";
import EditIcon from "../common/EditIcon";

const EditLos = ({
  los,
  account_id,
  handleSuccess,
  size="small"
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [ev, setEv] = useState(null);
  const [losName, setLosName] = useState(los?.los_name || '');
  const [losCode, setLosCode] = useState(los?.los_code || '');
  const [isLoading, setIsLoading] = useState(false);
  const { currentOrg } = useContext(UserContext)

  const handleButtonClick = () => {
    setModalVisible(true);
  };

  const handleSave = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    // Handle canceling action
    // setLosName(null);
    // setLosCode(null);
    setModalVisible(false);
  };

  const handleUpdate = () => {
    const oldCode = los?.los_code || '';
    const newCode = losCode ? formatCode(losCode) : '';
    const payload = {
      org_id: currentOrg.id,
      account_id,
      old_los_name: los?.los_name || '',
      old_los_code: oldCode,
      new_los_name: losName,
      new_los_code: newCode
    };
    setIsLoading(true);
    try {
       updateLOS(payload).then((result) => {
        if (result.success) {
          const levelOfServices = result.los|| [];
          alertMessage(result.message || I18n.t("messages.saved"));
          handleCancel()
          handleSuccess(oldCode, newCode, losName, levelOfServices);
        } else {
          renderAlertMessage(result.errors, "error", 10);
        }
      });
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
       <Tooltip title={ I18n.t("accessorials.contract.add") }>
          <EditIcon handleClick={handleButtonClick}/>
       </Tooltip>
      <Modal
        title={'Edit LoS'}
        visible={modalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        size="small"
        footer={null}
        width={400}
        onClick={(event) => {
            event.stopPropagation()
            ev.stopPropagation()

        }}
        maskClosable={false}
      >
        <div className="textBold">
            Level of service : {los.los_name}-{los.los_code}
        </div>
        <Row gutter={16} className="marginTop10">
          <Col className="textBold" xs={4}>
            {I18n.t("general.name")}<sup className="textRed">*</sup>
          </Col>
          <Col xs={1}>:</Col>
          <Col xs={18}>
         
            <Input
                style={{ width: "100%" }}
                value={losName}
                onChange={(e) => {
                    setLosName(e.target.value)
                }}
                size="small"
            />
          </Col>
        </Row>
        <Row gutter={16} className="marginTop10">
          <Col className="textBold" xs={4}>
            {I18n.t("general.code")}<sup className="textRed">*</sup>
          </Col>
          <Col xs={1}>:</Col>
          <Col xs={18}>
            <Input
                    style={{ width: "100%" }}
                    value={losCode}
                    onChange={(e) => {
                        setLosCode(e.target.value)
                    }}
                    //disabled={true}
                    size="small"
                    className="textUpperCase"
                />
          </Col>
        </Row>
        <div className="paddingTop20 alignCenter">
           

            <Button
              type="danger"
              icon="close"
              size="small"
              onClick={handleCancel}
              
            >
              Cancel
            </Button>
          
            {/* <Popconfirm
              placement="topRight"
              title={I18n.t("messages.approve_confirm")}
              onConfirm={handleUpdate}
              okText="Yes"
              cancelText="No"
              disabled={selectedKeys.length === 0}
            > */}
              <Button type="primary" icon="save" size="small" className="marginLeft10"
              loading={isLoading}
                onClick={handleUpdate}>
                Save
              </Button>
            {/* </Popconfirm> */}
          
           
          
        </div>
      </Modal>
    </Fragment>
  );
};

export default EditLos;