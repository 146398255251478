import React, { Fragment } from "react";
import moment from "moment";
import { ellipseText, isEmpty } from "../../../common/Common";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import AppConfig from "../../../config/AppConfig";
import Copyable from "../../common/Copyable";
import CustomerOrderDetails from "../../orders/CustomerOrderDetails";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import { Col, Icon, Link, Popover, Row, Tooltip } from "../../../common/UIComponents";
import PreplanOrderAccessorial from "../../orders/PreplanOrderAccessorial";
import TypeOfOrder from "../../orders/TypeOfOrder";
import DisplayTime from "../../common/DisplayTime";
import noImg from "../../../../assets/images/noImg.png";
import ImageViewer from "../../common/ImageViewer";
import OrderAttachments from "../../orders/OrderAttachments";

class PodReportList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "customer_order_number",
        title: <b>{I18n.t("driver_log_report.cid")}</b>,
        dataIndex: "customer_order_number",
        render: (text, record) => (
          <div>
            <CustomerOrderDetails
              order_no={text}
              order={{
                id: record.id,
                customer_order_number: text,
              }}
              showEdit={false}
            />
          </div>
        ),
        width: "110px",
        align: "left",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "hawb",
        title: <b>{I18n.t("driver_log_report.hawb")}</b>,
        dataIndex: "hawb",
        render: (data, record) => (
          <OrderFieldDisplay
            data={{
              ...record,
              id: record.id,
              customer_order_number: record.customer_order_number,
            }}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
        width: "120px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "mawb",
        title: <b>{I18n.t("driver_log_report.mawb")}</b>,
        dataIndex: "mawb",
        render: (data, record) => (
          <OrderFieldDisplay
            data={{
              ...record,
              id: record.id,
              customer_order_number: record.customer_order_number,
            }}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
        width: "120px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },

      {
        key: "type_of_order",
        title: <b>{I18n.t("general.order_type")}</b>,
        dataIndex: "type_of_order",
        render: (data, record) => (
          <TypeOfOrder
            order={record}
            orderTypeKey="type_of_order"
            relatedOrderKey="related_order"
            placement="topLeft"
            showBadge={true}
          />
        ),
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "status",
        title: <b>{I18n.t("general.status")}</b>,
        dataIndex: "status",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
      },
      {
        key: "account_code",
        title: <b>{I18n.t("driver_log_report.account_code")}</b>,
        dataIndex: "account_code",
        render: (data, record) => <span>{data}</span>,
        width: "110px",
      },
      {
        key: "driver_name",
        title: <b>{I18n.t("general.driver")}</b>,
        dataIndex: "driver_name",
        render: (data, record) => (
          <span>
            {data
              ?.trim("\n")
              .split("\n")
              .map((line, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              ))}
          </span>
        ),
        width: "120px",
      },
      {
        key: "signer_name",
        title: <b>{I18n.t("general.signer_name")}</b>,
        dataIndex: "signer_name",
        render: (data, record) => (
          <span>
            {data
              ?.trim("\n")
              .split("\n")
              .map((line, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              ))}
          </span>
        ),
        width: "120px",
      },
      {
        key: "signer_title",
        title: <b>{I18n.t("general.signed_title")}</b>,
        dataIndex: "signer_title",
        render: (data, record) => (
          <span>
            {data
              ?.trim("\n")
              .split("\n")
              .map((line, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              ))}
          </span>
        ),
        width: "120px",
      },
      {
        key: "signed_at",
        title: <b>{I18n.t("item_exceptions.captured_at")}</b>,
        dataIndex: "signed_at",
        render: (data, record) => (
          <span>
            {data
              ?.trim("\n")
              .split("\n")
              .map((line, index) => (
                <Fragment key={index}>
                  {index > 0 && <br />}
                  {line}
                </Fragment>
              ))}
          </span>
        ),
        //<span>{data || ""}</span>,
        // render: (data, record) => (
        //   <span>
        //     <DisplayTime
        //       dateTimeString={data}
        //       timeFormat={AppConfig.dateTimeFormat}
        //       displayWithDate={true}
        //       isUTC={true}
        //     />

        //   </span>
        // ),
        width: "180px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      // {
      //   key: "pod_url",
      //   title: <b>{"POD"}</b>,
      //   dataIndex: "pod_url",
      //   render: (data, record) => (
      //     record.pod_present ? 
      //     <div>
      //       <Col span={4}>
      //         <PodAttachments
      //           record={record}
      //           placement={"topRight"}
      //           showPopupContainer={false}
      //         />
      //       </Col>
      //     </div> 
      //     : 
      //     <div>
      //       <Tooltip title={"No Pods Found"} >
      //       <img src={noImg} alt="No Image" style={{ width: "15px" }} />
      //       </Tooltip>
      //     </div>
      //   ),
      //   width: "80px",
      // },
      // {
      //   key: "pod_present",
      //   title: <b>{"Pod Present"}</b>,
      //   dataIndex: "pod_present",
      //   render: (data, record) => 
      //   <span>{record.pod_present === null || record.pod_present === false ? "No": "Yes"}</span>,
      //   width: "120px",
      // },
      {
        key: "pod_present",
        title: <b>{"POD"}</b>,
        dataIndex: "pod_url",
        render: (data, record) => (
          record.pod_present ? 
          <div>
            <Col span={4}>
              <ImageViewer 
                isIconBased={true}
                images={record.pod_url}
                showPrintButton={false}
                showDeleteButton={false}
                deleteImage={false}
                showCapturedDate={false}
                orderPictures={false}
                files={false}
                />
            </Col>
          </div> 
          : 
          <div>
            <Tooltip title={"No Pods Found"} >
            <img src={noImg} alt="No Image" style={{ width: "15px" }} />
            </Tooltip>
          </div>
        ),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        width: "80px",
      },
      // {
      //   key: "pod_present",
      //   title: <b>{"Pod Present"}</b>,
      //   dataIndex: "pod_present",
      //   render: (data, record) => 
      //   <span>{record.pod_present === null || record.pod_present === false ? "No": "Yes"}</span>,
      //   width: "120px",
      // },
      {
        key: "actions",
        title: <b>{"Actions"}</b>,
        fixed:'right',
        dataIndex:'id',
        width: '70px',
        render: (id, record, index) => (
          <div className="alignCenter">
            <Tooltip 
            title={ record.pod_present ? "Send Email For Pod" : "Pod is not submitted"} placement="top"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
           
                  <Icon
                   type="mail"
                   theme="twoTone"
                   className="line-actions"
                   onClick={() => this.props.renderEmailModal(record)}
                  />
              </Tooltip>
          </div>
        )},
    ];
  }
}

export default PodReportList;
