import React, { Component, useContext } from "react";
import { Modal } from "antd";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { deleteRole, fetchRolesList, fetchSingleRole } from "../api/RolesApi";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import BaseModal from "../components/BaseModal";
import userStore from "../stores/UserStore";
import RoleForm from "../components/roles/RoleForm";
import PageHeader from "../components/common/PageHeader";
import RolesList from "../components/roles/RolesList";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import AppConfig from "../config/AppConfig";
import { Row, Col, Tabs } from "../common/UIComponents";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";
import ScreenManagement from "../components/roles/ScreenManagement";
import { renderAlertMessage } from "../helpers/common";
const { confirm } = Modal;
const { TabPane } = Tabs
class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedRolesList:[],
      isaddRoleModalVisible: false,
      isEditingRoleModalVisible: false,
      rolesList: [],
      isEditing: false,
      editableData: {},
      pagination: {},
      isLoading: false,
      recordsPerPage: AppConfig.ordersPerPage,
      filterPlaceHolder: {
        sortBy: "none",
        sortByType: "descend",
      },
      recordsPerPage: props.recordsPerPage,
      activeKey:'details'
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  searchFilter = (val) => {
    const searchedVal = _.lowerCase(val);
    let arr = this.state.rolesList;

    if (!_.isEmpty(searchedVal)) {
      arr = this.state.rolesList.filter((e) => {
        return (
          _.lowerCase(e.role_code).includes(searchedVal) ||
          _.lowerCase(e.role_name).includes(searchedVal)
        );
      });
    }

    this.setState({
      searchedRolesList: arr,
    });
  };
    
  componentDidMount() {
    this.getRoles();
  }

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getRoles();
        updateRecordsPerPage(ScreenKeys.ROLES_LISTING, value);
      }
    );
  };

  onSearch = (value) => {
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getRoles();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.getRoles();
        }
      );
    }
  };

  getRoles = () => {
    const page = this.tableOptions.pagination.current;
    const perPage = this.state.recordsPerPage;
    let info = {
      org_id: userStore.getStateValue("selectedOrg"),
      page,
      per_page: 1000,
      sort_by: this.state.filterPlaceHolder.sortBy,
      sort_order: I18n.t(`general.sort_${this.state.filterPlaceHolder.sortByType}`),
    };
    this.setState({ isLoading: true });
    fetchRolesList(info)
      .then((result) => {
        const { success, data, errors,pagination } = result;
        if (success) {
          if (data.length) {
            this.setState(
              {
                searchedRolesList: data,
                rolesList: data,
                pagination: pagination,
              })
          }
        } else {
          renderAlertMessage(errors)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  setAddModalVisible = (isVisible) => {
    this.setState({
      isaddRoleModalVisible: isVisible,
    });
  };
  setEditModalVisible = (isVisible) => {
    this.setState({
      isEditingRoleModalVisible: isVisible,
    });
  };

  setActiveKey = ( key) => {
    this.setState({
      activeKey: key,
    })
  }
  renderAddRoleModal = () => {
   
    return (
      <BaseModal
        title={I18n.t("roles.add_role")}
        className="add-role-modal"
        onCancel={() => {
          this.setState({ isaddRoleModalVisible: false });
        }}
        width={"100%"}
        style={{
          top: 0,
          height: "100%",
        }}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "scroll",
        }}
      >
             <RoleForm
                formTitle={I18n.t("roles.add_role")}
                addNewRole={this.addNewRole}
                setAddModalVisible={this.setAddModalVisible}
                onCancel={() => {
                  this.setState({ isaddRoleModalVisible: false });
                }}
              />
      </BaseModal>
    );
  };
  renderEditRoleModal = () => {
    const { activeKey } = this.state;
    return (
      <BaseModal
        title={I18n.t("roles.edit_role")}
        className="edit-role-modal"
        onCancel={() => {
          this.setState({ isEditingRoleModalVisible: false });
        }}
        width={"100%"}
        style={{
          top: 0,
          height: "100%",
        }}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "scroll",
        }}
      >
         <RoleForm
            formTitle={I18n.t("roles.edit_role")}
            updateRoleList={this.updateRole}
            setEditModalVisible={this.setEditModalVisible}
            editableData={this.state.editableData}
            onCancel={() => {
              this.setState({ isEditingRoleModalVisible: false });
            }}
          />
        {/* <Tabs
            activeKey={activeKey}
            onChange={(key) => this.setActiveKey(key)}
            type="page_actions"
          >
            <TabPane 
              tab={"Actions"} 
              key="details">
              <RoleForm
                formTitle={I18n.t("roles.edit_role")}
                updateRoleList={this.updateRole}
                setEditModalVisible={this.setEditModalVisible}
                editableData={this.state.editableData}
                onCancel={() => {
                  this.setState({ isEditingRoleModalVisible: false });
                }}
            />
         </TabPane>
            <TabPane
              tab={"Screen Access"}
              key="screeb_access"
            >
              <ScreenManagement
                updateRoleList={this.updateRole}
                setEditModalVisible={this.setEditModalVisible}
                currentRole={this.state.editableData}
                onCancel={() => {
                  this.setState({ isEditingRoleModalVisible: false });
                }}
              />
            </TabPane>
          </Tabs> */}
      </BaseModal>
    );
  };
  updateRole = (role) => {
    // find the role in the list and update it
    // const { rolesList } = this.state;
    // const updatedRoleList = rolesList.map((item) => {
    // 	if (item.id === role.id) {
    // 		return role;
    // 	}
    // 	return item;
    // });
    this.setState({
      // rolesList: updatedRoleList,
      isEditingRoleModalVisible: false,
    });
    this.getRoles();
  };

  handleConfig = (id) => {
    this.props.history.push(`/roles/${id}/settings`);
  };
  handleEdit = (id) => {
    this.setState({ isLoading: true });
    fetchSingleRole(id)
      .then((result) => {
        const { success, data, errors } = result;
        if (success) {
          if (Object.keys(data)) {
            this.setState({
              editableData: data,
              isEditing: true,
              isEditingRoleModalVisible: true,
            });
          }
          this.getRoles();
        } else {
          renderAlertMessage(errors)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onDelete = (id) => {
    this.setState({ isLoading: true });
    deleteRole(id)
      .then((result) => {
        if (result.success) {
          const { rolesList } = this.state;
          const newRolesList = rolesList.filter((role) => role._id !== id);
          this.setState({
            rolesList: newRolesList,
          });
          this.getRoles();
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  handleDelete = (id) => {
    confirm({
      title: "Do you want to delete this role? ",
      content: <span className="">{"Do you want to proceed ?"}</span>,
      onOk: () => {
        this.onDelete(id);
      },
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onCancel: () => {},
    });
  };

  addNewRole = (data) => {
    // const { rolesList } = this.state;
    // const newRolesList = [...rolesList, data];
    this.setState({
      // rolesList: newRolesList,
      isaddRoleModalVisible: false,
    });
    this.getRoles();
  };

  render() {
    const { pagination, isaddRoleModalVisible, isEditingRoleModalVisible } = this.state;
    const total = pagination && pagination.total_count ? pagination.total_count : 0;
    const current = pagination && pagination.current_page ? pagination.current_page : 1;
    const pageSize = pagination && pagination.per_page ? pagination.per_page : this.state.recordsPerPage;

    const paginationConfig = {
      total,
      current,
      pageSize,
    };
    const currentPage = paginationConfig.current ? paginationConfig.current : 1;
    return (
      <div className="content-outer routes-classes">
        <div className="content">
          <PageHeader
            searchFilter={this.searchFilter}
            title={I18n.t("menu.roles")}
            setAddModalVisible={this.setAddModalVisible}
            {...this.props}
          />
          {/* <Row className="page-content">
            <Col xs={24}>
              <Row style={{ float: "right", marginBottom: "-18px" }}>
                <RecordsPerPage
                  onChange={this.onRecordChange}
                  onSearch={this.onSearch}
                  value={this.state.recordsPerPage}
                  defaultValue={this.state.recordsPerPage}
                />
              </Row>
            </Col>
          </Row> */}
          <RolesList
            isLoading={this.state.isLoading}
            rowKey="_id"
            handleConfig={this.handleConfig}
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
            currentPage={currentPage}
            pagination={{position:"none"}}   //paginationConfig}
            tableChange={(pagination,filter,sorter,currentTable) =>
              this.onTableChange(pagination,filter,sorter,currentTable
              )
            }
            data={this.state.searchedRolesList}
            // scroll ={{y:680}}
            scroll={{ y: "calc(100vh - 220px)" }}
          ></RolesList>
          {isaddRoleModalVisible && this.renderAddRoleModal()}
          {isEditingRoleModalVisible && this.renderEditRoleModal()}
        </div>
      </div>
    );
  }
}

Roles.propTypes = {
  history: PropTypes.object,
};
Roles.defaultProps = {
  history: {},
};


const RolesWrapper = withRouter((props) => {
  const userContext= useContext(UserContext);
  const perPageKey = ScreenKeys.ROLES_LISTING
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);

  return (
    <Roles
      recordsPerPage={recordsPerPage}
      userContext={userContext}
      {...props}
    />
  );
});

export default RolesWrapper;