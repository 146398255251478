import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData
} from './ApiUtils';
import AppConfig from "../config/AppConfig";
import { formatDate } from '../common/Common';
export const DmsApi = {
  fetch: (fromDate, toDate, orgId, page = 1, perPage = AppConfig.perPage, sortBy = "", sortOrder= "") => {
    const url = ApiUrl(`v2/document_managements/processed_documents?${PageParams(page, perPage)}&from_date=${fromDate}&to_date=${toDate}&org_id=${orgId}&sort_by=${sortBy}&sort_order=${sortOrder}`
    );
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { document_managements: "processed_documents", pagination: 'meta.pagination' },
    });
  },

  fetchurl: (orgId, selectedWhIds, page = 1, perPage = AppConfig.perPage) => {

    const url = ApiUrl(`v2/document_managements/unprocessed_documents?${PageParams(page, perPage)}&organization_id=${orgId}`
    );
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { picture_urls: "processed_documents", pagination: 'meta.pagination' },
    });
  },



  fetchCombined: (view_by,fromDate, toDate, orgId,selectedWhIds,page = 1, perPage = AppConfig.perPage,searchText='') => {
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    const url = ApiUrl(`v2/document_managements/dms_files?view_by=${view_by}&from_date=${formattedFromDate}&to_date=${formattedToDate}&search_text=${searchText}&org_id=${orgId}&${PageParams(page, perPage)}`
    );
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { details: "processed_documents", pagination: 'meta.pagination' },
    });
  },




  // createLink: (payload) => {
  //   // payload.operation_code = 'VTC';
  //   // const url = ApiUrl('v2/master_vehicle_types/export_vh_type_from_master_vh_types');
  //   const url = ApiUrl('v2/document_managements/create_processed_documents');
  //   const formData = setObjectToFormData(payload);
  //   return Call('post', url, formData, ParseGeneralResponse, {
  //     responseDataKeys: { stop_order: 'stop_order_picture' },
  //   });
  // },

  delete:
    (payload) => {
      // data.operation_code = 'OREDP'
      const url = ApiUrl('/v2/document_managements/delete_pictures');
      return Call('delete', url, payload, ParseGeneralResponse, {
        responseDataKeys: { newdata: '' },
      });
    },


  createLink: (payload) => {
    payload.operation_code = 'DMSAF';
    const url = ApiUrl('v2/document_managements/attached_unprocessed_files');
    const formData = setObjectToFormData(payload);
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { message: "message"  }

    });
  },

};



