import { Button, Col, Row,  } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { IntegrationApi } from "../../api/IntegrationApi";
import { alertMessage, randomUUID } from "../../common/Common";
import AppConfig from "../../config/AppConfig";
import BaseModal from "../BaseModal";
import IntegrationsList from "./IntegrationList";
import IntegrationsForm from "./IntegrationsForm";
import I18n from "../../common/I18n";

function Integrations() {
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ activeIntegrationId, setActiveIntegrationId ] = useState(null);
	const [ integrationsData, setIntegrationsData ] = useState([]);
	const [paginationConfig , setPaginationConfig] = useState({
				current: 1,
				pageSize: AppConfig.perPage,
				total: 0,
	});
	const [ tableOptions, setTableOptions ] = useState({
	 pagination: {
	 }
	});


	const convertSettingsToConfig = (integration) => {
			const settings = integration.settings;
			const settingsArray = [];
			_.forEach(settings, (value, key) => {
				settingsArray.push({
					key: randomUUID(),
					config_key: key,
					config_value: value
				});
			});
			integration.config = settingsArray;
			delete integration.settings;
			return integration;
		};


	const processIntegrationsData = (data) => {
		const processedData = data.map((integration) => {
			// convert settings to config
			const integrationWithConfig = convertSettingsToConfig(integration);
			return integrationWithConfig;
		});
		return processedData;
	}

	useEffect(() => {
		setIsLoading(true);
		const paginationQuery = {
			currentPage: tableOptions.pagination.current, sortBy: "CODE", sortOrder: "ASC"
		};
			IntegrationApi.fetch(paginationQuery).then(
				(response) => {
					const { success, integrations, pagination } = response;
					if (success) {
						const processedData = processIntegrationsData(integrations);
						setIntegrationsData(processedData);
						setPaginationConfig({
							current: pagination.current_page,
							pageSize: pagination.per_page || AppConfig.perPage,
							total: pagination.total_count,
						});
					}
					else {
						setIntegrationsData([]);
						alertMessage(response.errors[ 0 ], "error");
					}
				}
			).finally(() => {
				setIsLoading(false);
			});
	}, [ tableOptions ]);


	const onModalClose = () => {
		setIsModalOpen(false);
		setActiveIntegrationId(null);
	};
	const updateIntegrationData = (isEdit , data , isPartialSubmit = false) => {
		data = convertSettingsToConfig(data);
		if(isEdit){
			setIsLoading(true);
			const updatedIntegrationData = integrationsData.map((integration) => {
				if (integration.id === activeIntegrationId) {
					return {
						id : integration.id,
						...data,
					};
				}
				return integration;
			}
			);
			setIntegrationsData(updatedIntegrationData);
		}else{
			setIsLoading(true);
			const newIntegrationData = [...integrationsData, data];
			setIntegrationsData(newIntegrationData);
		}
			setIsLoading(false);
			if(!isPartialSubmit){
				setActiveIntegrationId(null);
				setIsModalOpen(false);
			}
	};
	const renderModal = () => {
		return (
			<BaseModal
				title={
					activeIntegrationId
						? I18n.t("integrations.modal.edit_integration")
						: I18n.t("integrations.modal.create_integration")
				}
				className="integrations-modal"
				onCancel={onModalClose}
				width="60%"
				style={{ top: 20 }}
				maskClosable={false}
			>
				<IntegrationsForm
					isLoading={ isLoading }
					activeIntegrationId={ activeIntegrationId }
					integrationsData={ integrationsData }
					updateIntegrationData={ updateIntegrationData }
					closeIntegrationModal={ onModalClose }
				></IntegrationsForm>
			</BaseModal>
		);
	};

	const handleEditIntegration = (integrationId) => {
		setActiveIntegrationId(integrationId);
		setIsModalOpen(true);
	};

	const handleDeleteIntegration = (integrationId) => {
		setIsLoading(true);
		IntegrationApi.delete(integrationId).then(
			(response) => {
				if (response.success) {
					const updatedIntegrationData = integrationsData.filter((integration) => {
						return integration.id !== integrationId;
					});
					alertMessage(I18n.t("integrations.modal.deleteSuccess"), "success");
					setIntegrationsData(updatedIntegrationData);
				} else {
					alertMessage(response.errors[ 0 ], "error");
				}
			}).finally(() => {
				setIsLoading(false);
			});
	};
	const onTableChange = (tablePagination) => {
		let currentPage = null;
		if (!_.isEmpty(tableOptions.pagination))
			currentPage = tableOptions.pagination.current
		else
			currentPage = 1;

		if (tablePagination.current !== currentPage) {
			setTableOptions({ pagination: tablePagination });
		}
	};

	return (
	      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("menu.integrations")}</Col>
            <Col xs={12} className="page-actions">
							<Button
								type="primary"
								icon="plus"
								className="integrationsWrapper__left__header__button"
								onClick={() => setIsModalOpen(true)}
							>
								{I18n.t("integrations.add_integration")}
							</Button>
            </Col>
          </Row>
					<Row>
						<Col className="integrationsWrapper">
							<IntegrationsList 
								data={ integrationsData }
								handleEdit={ handleEditIntegration } 
								handleDelete={ handleDeleteIntegration}
								isLoading={ isLoading }
								pagination={ integrationsData.length ?  paginationConfig : 
									{
										position: "none",
									}
									}
								tableChange={ (pagination, filter, sorter, currentTable) =>
									onTableChange(pagination, sorter, currentTable)
								}
							/>
						</Col>
					</Row>
						{isModalOpen && renderModal()}
        </div>
      </div>
	);
}

export default Integrations;
