import React from "react";
import moment from "moment";
import _ from "lodash";
import { Icon, Row, Col } from "../../common/UIComponents";
import { Link } from "react-router-dom";
import BaseList from "../BaseList";
import { checkNegitive } from "../../common/Common";
import I18n from "../../common/I18n";
import { momentTime } from "../../helpers/date_functions";
import { formatByTimeConfig } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";

class PaymentHistory extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        width: 55,
      },
      {
        key: "created_at",
        title: I18n.t("general.activity_on"),
        dataIndex: "created_at",
        render: (data) =>
          data
            ? `${formatByTimeConfig(momentTime(
              "",
              data,
              this.props.timeZone
            ), this.props.isMilitaryTime, "MMM Do, YYYY HH:mm", 'MMM Do, YYYY hh:mm A')} ${this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
            }`
            : "",
        // sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      },
      {
        key: "check_number",
        title: I18n.t("account.credit_limit.check_no"),
        dataIndex: "check_number",
      },
      {
        key: "paid_amount",
        title: I18n.t("general.paid_amount"),
        dataIndex: "clear_amount",
        render: (data, record) => (
          <div>{checkNegitive(data)}</div>
        ),
      },
      {
        key: "added_by",
        title: I18n.t("general.activity_by"),
        dataIndex: "added_by",
        className: "textCaptilize",
      },
      {
        key: "check_date",
        title: I18n.t("account.credit_limit.check_date"),
        dataIndex: "check_date",
        render: (data, record) => (
          <div>{data ? moment(data).format(AppConfig.dateFormat) : ''}</div>
        ),
      },
      {
        key: "out_standing_amount",
        title: I18n.t("account.credit_limit.out_standing_amount"),
        dataIndex: "out_standing_amount",
        render: (data, record) => (
          <div>{checkNegitive(data)}</div>
        ),
      },
    ];
  }
}

export default PaymentHistory;
