import React, { Fragment, useState, useEffect } from 'react'
import {
  Icon,
  Form,
  Spin,
  Row,
  DatePicker,
  Tabs,
  InputNumber,
  Input,
  Col,
  Button,
} from '../../common/UIComponents'
import { alertMessage,isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import BaseModal from '../BaseModal'
import { formatFullName, renderAlertMessage } from '../../helpers/common'
import { creditLimitApi } from '../../api/CreditLimitApi'
import moment from 'moment'
import AppConfig from '../../config/AppConfig'

const { TabPane } = Tabs

const DueUpdateForm = ({
  handleSuccess,
  className,
  currentCreditLimit = {},
  currentOrderRec = {},
  form,
  refreshData = () => {},
}) => {
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const zeroValue = Number((0).toFixed(decimalPoints));
  const [loading, setLoading] = useState(false)
  const { getFieldDecorator } = form
  useEffect(() => {
    setFormData()
  }, [currentOrderRec])

  const setFormData = () => {
    form.setFieldsValue({
      amount: currentOrderRec.out_standing_amount,
      check_date: moment(),
      check_number: '',
    })
  }
  const handleOrderDueSave = (e) => {
    e.preventDefault()
    // validate form
    form.validateFields((err, values) => {
      if (!err) {
        if (!isEmpty(currentOrderRec)) {
          const checkDate = form.getFieldValue('check_date')
          const data = {
            account_id: currentOrderRec.account_id,
            organization_id: currentOrderRec.organization_id,
            //clear_amount: form.getFieldValue('amount'),
            account_invoice_id: currentOrderRec.account_invoice_id,
            invoice_number: currentOrderRec.invoice_number,
            clear_amount: form.getFieldValue('amount'), //currentOrderRec.out_standing_amount,
            invoice_amount: currentOrderRec.invoice_amount,
            check_date: checkDate
              ? checkDate.format(AppConfig.dateFormat)
              : null,
            check_number: form.getFieldValue('check_number'),
          }
          setLoading(true)
          creditLimitApi.updateOrderDueAmount(data).then((result) => {
            if (result.success) {
              setLoading(false)
              const msg = result.message
                ? result.message
                : I18n.t('messages.saved')
              alertMessage(msg, 'success', 10)
              const response = result.data ? result.data : {}
              if(isEmpty(response.order_record)){
                response.orderRec = {
                  ...currentOrderRec, 
                  out_standing_amount: response.out_standing_amount ? parseFloat(response.out_standing_amount) : zeroValue
                }
              }
              // setFormData()
              refreshData(response)
              // this.setState({
              //   editableRecord: null,
              //   inProgress: false,
              //   amount_to_clear: '',
              //   showClearForm: false,
              //   currentCreditLimit: response.credit_limit_amount || {},
              // }, () => {
              //   this.setInitialData(response.credit_limit_amount);
              // })
            } else {
              renderAlertMessage(result.errors)
              setLoading(false)
            }
          })
        }
      }
    })
  }
  return (
    <Spin spinning={loading}>
      <Form
        onReset={() => {
          form.resetFields()
        }}
        name="dueForm"
      >
        <Row gutter={8}>
          <Col span={3}>
            <Form.Item label="Amount" name="amount">
              {getFieldDecorator('amount', {
                rules: [
                  {
                    required: true,
                    message: 'invalid input',
                  },
                ],
              })(
                <InputNumber
                  min={0.0}
                  style={{
                    width: '100%',
                  }}
                  precision={decimalPoints}
                  prefix={<Icon type="dollar" />}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label={I18n.t('account.credit_limit.check_no')}
              name="check_number"
            >
              {getFieldDecorator('check_number', {
                rules: [
                  {
                    required: true,
                    message: 'invalid input',
                  },
                ],
              })(
                <Input
                  style={{
                    width: '100%',
                  }}
                />,
              )}
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label={I18n.t('account.credit_limit.check_date')}
              name="check_date"
            >
              {getFieldDecorator(`check_date`, {
                rules: [
                  {
                    required: true,
                    message: 'Invalid Input',
                    type: 'object',
                    whitespace: true,
                  },
                ],
              })(
                <DatePicker
                  showTime={false}
                  format="DD MMM YYYY"
                  style={{ width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={8} style={{ paddingTop: 45 }}>
            <Button
              type="danger"
              htmlType="reset"
              icon="delete"
              className="marginRight5"
            >
              Clear
            </Button>

            <Button
              type="primary"
              icon="save"
              className="marginLeft5"
              onClick={handleOrderDueSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

const DueForm = Form.create({ name: 'dueForm' })(DueUpdateForm)
export default DueForm
