import React from 'react';
import { Form } from 'antd';
import _ from 'lodash';
import { Icon, Input, Table } from '../../../common/UIComponents';
import { alertMessage, isEmpty } from '../../../common/Common';
import { saveFuelSurchargeDetail } from '../../../api/FuelSurcharges';
import EditIcon from '../../common/EditIcon';
import I18n from '../../../common/I18n';
import { renderAlertMessage } from '../../../helpers/common';


const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    return ( 
      <Input
        type="number"
        controls={false}
        style={{width: 85}}
        // addonBefore={<Icon type={this.props.surchargeType === 'MILES' ? 'dollar' : 'percentage'}/>} 
      />
    );
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      surchargeType,
      ...restProps
    } = this.props;
    return (
      <td {...restProps} style={{padding: 5, textAlign: 'right'}}>
        {!editing &&
          <span> 
            {(title === 'From' || title === 'To') && '$'}
            {(title === 'Value' && surchargeType === 'MILES') && '$'}
          </span>
        }
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
        {!editing &&
          <span>
            {(title === 'Value' && surchargeType !== 'MILES') && '%'}
          </span>
        }
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class FuelSurchargeDetailsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.data || [], editingKey: this.props.editingKey || 'new' };
    this.columns = [
      {
        title: 'From',
        dataIndex: 'from',
        editable: this.props.canEdit || false,
        render: (text) => (!isEmpty(text) ? text.toFixed(3) : '')
      },
      {
        title: 'To',
        dataIndex: 'to',
        editable: this.props.canEdit || false,
        render: (text) => (!isEmpty(text) ? text.toFixed(3) : '')
      },
      {
        title: 'Value',
        dataIndex: 'value',
        editable: this.props.canEdit || false,
        render: (text) => (!isEmpty(text) ? text.toFixed(3) : '')
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            this.props.canEdit && (
              editable ? (
                <span>
                  <EditableContext.Consumer>
                    {form => (
                      <Icon 
                        type="check-circle"
                        className="textGreen fontSize15"
                        onClick={() => this.save(form, record.id)}
                        style={{ marginRight: 8 }}
                      />
                    )}
                  </EditableContext.Consumer>
                  {record.id &&
                    <Icon
                      type="close-circle"
                      className="textRed fontSize15"
                      onClick={() => this.cancel(record.id)}
                    />
                  }
                </span>
              ) : (
                  // <Icon
                  //   disabled={editingKey !== ''}
                  //   type='edit'
                  //   onClick={() => this.edit(record.id)}
                  // />
                  <EditIcon handleClick={()=> this.edit(record.id)}/>
              )
            )
          );
        },
      },
    ];
  }

  isEditing = record => record.id === this.state.editingKey;

  cancel = () => {
    // edit last item in data to have new id from value to 0 and to value to 0 
    const data = [...this.state.data];
    const index = _.findIndex(data, ['id', 'new']);
    data.splice(index, 1, {from: 0.0 , to: 0.0, value: 0.0, id: 'new'});
    this.setState({ data, editingKey: 'new' });

  };

  save(form, id) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const range = _.find(this.props.data, ['id', id])
      const newRange = { ...range, ...row }
      newRange.fs_master_id = this.props.fsMasterId;
      newRange.organization_id = this.props.organizationId;
      saveFuelSurchargeDetail(id === 'new' ? true : false, newRange)
        .then((result) => {
            if (result.success) {
              const data = [...this.state.data];
              this.setState({data: []})
              const index = _.findIndex(data, ['id', id]);
              data.splice(index, 1, result.fuel_surcharge_detail);
              if (id === 'new') {
                this.setState({
                  data: [...data, {from: data[data.length - 1].to + 0.001, to: 0.0, value: 0.0, id: 'new'}],
                });
              } else {
                this.setState({ data: data, editingKey: 'new'});
              }
              alertMessage(I18n.t('messages.saved'));
              this.props.onUpdateDetail(newRange);
            } else {
              renderAlertMessage(result.errors)
            }
        });
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      this.setState({data: this.props.data})
    }
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          surchargeType: this.props.surchargeType,
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        {this.state.data.length >= 1 &&
          <Table
            rowKey="_id"
            components={components}
            bordered
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        }
      </EditableContext.Provider>
    );
  }
}

export default Form.create()(FuelSurchargeDetailsList);
