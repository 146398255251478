/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import BaseList from '../BaseList';
import _ from 'lodash';
import { Button, Tooltip, Icon, Popconfirm } from '../../common/UIComponents';
import { isEmpty, currencyConversion } from '../../common/Common';
import AppConfig from '../../config/AppConfig';
import DeleteIcon from '../common/DeleteIcon';
import I18n from '../../common/I18n';

class RateCard extends BaseList {
  constructor(props) {
    super();
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    const rangeUnit = !isEmpty(props.rangeLabel) && AppConfig.units[props.rangeLabel] ? AppConfig.units[props.rangeLabel] : AppConfig.weight_unit;
    const weights = props.weights || [];
    const showMincharge = props.showMincharge === true;
    if (showMincharge === true) {
      this.columns.push({
        key: 'min_charge',
        title: `${I18n.t('account.billing.min_charge')}`,
        className: 'customPadding',
        render: record => (
          <div>
            <span className="cursorPointer anchor_cursor" onClick={() => this.props.handleZoneMinCharge(record)} >
              <Button type="link">
                {
                  record.min_charge ?
                    currencyConversion(record.min_charge) :
                    I18n.t('account.billing.add_cost')
                }
              </Button>
            </span>
          </div>),
        width:100,
      });
    }
    weights.forEach((weight) => {
      this.columns.push({
        key: weight.id,
        title: (weight.min_weight === 0 && weight.max_weight === 0) ? 
         I18n.t('account.billing.min_charge') :
        <span className="fontSize12">
          {/* <u onClick={() => this.props.handleWeight(weight)} className="text-decoration-dashed cursorPointer">{`${weight.min_weight} - ${weight.max_weight} (${AppConfig.weight_unit})`}</u> */}
          <span>{`${weight.min_weight} - ${weight.max_weight} (${rangeUnit})`}</span>
            &nbsp;&nbsp;
          <Popconfirm
            placement="left"
            title={I18n.t('messages.delete_confirm')}
            onConfirm={() => this.props.deleteClick(weight)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteIcon/>
            &nbsp;&nbsp;&nbsp;
          </Popconfirm>
               </span>,
        render: (record, index) => (
          <div className="fontSize12">
            {
              record[weight.id] && !isEmpty(record[weight.id].accessorial_value) && record[weight.id].accessorial_value ?
                <span className="cursorPointer anchor_cursor" onClick={() => this.props.handleEditWeight(record, weight)} >
                  <Button type="link" size='small'>{currencyConversion(record[weight.id].accessorial_value)}</Button>
                </span>
                :
                <span className="cursorPointer anchor_cursor" onClick={() => this.props.handleEditWeight(record, weight)} >
                  {I18n.t('general.add')}
                </span>
            }
            {
              !weight.isNew && record[weight.id] && !isEmpty(record[weight.id].breakpoint_weight) && record[weight.id].breakpoint_weight !== 0 ? <p className="fontSize11"><Tooltip title={I18n.t('account.billing.bpw')}><i>BW &nbsp; {record[weight.id].breakpoint_weight}</i></Tooltip></p> : ''
            }
          </div>
        ),
        align: 'center',
        // className: 'customPadding',
      });
    });
    this.columns.push({
      key: 'new',
      title: <Button type="primary" size="small" onClick={() => this.props.handleAddWeight({})}>
        {I18n.t('account.billing.range.add')}
             </Button>,
      render: (record, index) => (
        <div />
      ),
      align: 'center',
      // className: 'customPadding',
    });
  }

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default RateCard;
