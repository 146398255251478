import React from "react";
import BaseList from "../../BaseList";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import Copyable from "../../common/Copyable";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance } from "../../../helpers/common";
import {
  Col,
  Icon,
  Link,
  Popover,
  Row,
  Tooltip,
} from "../../../common/UIComponents";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import I18n from "../../../common/I18n";
import moment from "moment";
import ShowInvoice from "../../billing_screen/ShowInvoice";
import AppConfig from "../../../config/AppConfig";

class DunningReportsSummaryList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "invoice_number",
        title: "Invoice No",
        dataIndex: "invoice_number",
        render: (text, record) => (
          <Row type="flex" gutter={4}>
            <Col>
              <ShowInvoice
                account={record.account_id}
                invoice_no={text}
                key={text}
                style={{ marginTop: -6 }}
                account_invoice_id={record.id}
                refreshList={() => {}}
                showActions={false}
                showInvoiceActions={false}
                approvalDate={record.approved_at}
                renderType="text"
              />
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        key: "account_name",
        title: I18n.t("account.account_name"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  <Tooltip title={data} placement="topLeft">
                    {" "}
                    {data}{" "}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        key: "approved_at",
        title: "Approved At",
        dataIndex: "approved_at",
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        sorter: (a, b) => compareDate(a.approved_at, b.approved_at),
      },
      {
        key: "age",
        title: "Age/Days",
        dataIndex: "age",
      },
      {
        key: "total_amount",
        title: "Total Amount",
        dataIndex: "total_amount",
        render: (data) => (
          <span className="textUpperCase">
            {data !== undefined ? checkNegitive(data) : 0}
          </span>
        ),
        align: "right",
      },
      {
        key: "balance_amount",
        title: "Balance Amount",
        dataIndex: "balance_amount",
        render: (data) => (
          <span className="textUpperCase">
            {data !== undefined ? checkNegitive(data) : 0}
          </span>
        ),
        align: "right",
      },
    ];
  }
}
export default DunningReportsSummaryList;
