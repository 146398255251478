/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, useContext } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, Col, Row, Search, Spin, Select, message, FormItem } from "../common/UIComponents";
import { alertMessage,  isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { deleteAccount, saveAccount, fetchAccount, fetchShortFormAccounts } from "../api/Account";
import List from "../components/credit_notes/List";
import Form from "../components/credit_notes/Form";
import BaseModal from "../components/BaseModal";
import userStore from "../stores/UserStore";
import AppConfig from "../config/AppConfig";
import {
  base64ToPDF,
  checkServiceExistance,
  removeSpecialCharacters,
  renderAlertMessage,
} from "../helpers/common";
// import DataTransferIcon from "../../images/data-transfer.svg";
import { withRouter } from "react-router";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import { CreditNotesApi } from "../api/CreditNotesApi";
import BaseSelect from "../components/common/SelectDropdowns/BaseSelect";
import { validateEmailList } from "../components/billing_screen/helpers";
import { getContactEmails } from "../api/Billing";
import { UserContext } from "../context/UserContext";

class CreditNotes extends Component {
  constructor(props) {
    super(props);
    const warehouseFilter = props.warehouseFilter ? props.warehouseFilter : {}
    this.state = {
      pagination: {},
      inProgress: false,
      isModalVisible: false,
      billingEmails: [], 
      contacts : [],
      currentId : "",
      showForm: false,
      creditNotes: [],
      currentCrediNote: {},
      orgId: this.props.orgId || userStore.getStateValue("selectedOrg"),
      isNew: true,
      sortOrder: "ascend",
      status: "OPEN",
      filter: {
        wh_id: warehouseFilter.selectedWarehouses ? warehouseFilter.selectedWarehouses : '',
        account_id: '',
        sortBy: "none",
        sortByType: "descend",
      },
      warehouses: warehouseFilter.warehouses ? warehouseFilter.warehouses : [],
      accounts: [],
      accountsProgess: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.getCreditNotes = _.debounce(this.getCreditNotes, 500);
  }

  componentDidMount() {
    this.getCreditNotes();
    if(!isEmpty(this.state.filter.wh_id)){
      this.getAccounts();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseFilter.selectedWarehouses,
        this.props.warehouseFilter.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseFilter.warehouses,
        this.props.warehouseFilter.warehouses
      ) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.setState({
        warehouses: this.props.warehouseFilter?.warehouses ? this.props.warehouseFilter.warehouses : []
      }, () => {
        this.tableOptions.pagination.current = 1;
        this.getCreditNotes();
        this.setState((prevState) => ({
          filter: {
            ...prevState.filter,
            wh_id: this.props.warehouseFilter?.selectedWarehouses,
          },
        }), () => {
          this.getAccounts();
        });
      })
      
    }
    if (
      !_.isEqual(
        prevProps.warehouseFilter.warehouseWithAccounts,
        this.props.warehouseFilter.warehouseWithAccounts
      )
    ) {
      this.getAccounts();
    }

  }

  getEmails = (data) => {
    const accountId = data.account_id
    this.setState({ isEmailLoading: true })
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        this.setState({
          contacts: result.contact_emails || [],
          isEmailLoading: false
        })
      }else{
        renderAlertMessage(result.errors)
        this.setState({ isEmailLoading: false, contacts: [] });
      }
    })
  }

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getCreditNotes();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filter: Object.assign({}, this.state.filter, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getCreditNotes();
        }
      );
    }
  };

  getCreditNotes = () => {
    const { orgId, filter } = this.state;
    const page = this.tableOptions.pagination?.current &&
      !isEmpty(this.tableOptions.pagination.current)
        ? this.tableOptions.pagination.current
        : 1;
    const perPage = AppConfig.perPage;
    this.setState({ inProgress: true, currentCrediNote: {} });
    CreditNotesApi.fetchCreditNotes({
      organization_id: orgId,
      wh_id: filter.wh_id,
      account_id: filter.account_id,
      page,
      perPage,
      sortBy: filter.sortBy,
      sortByType: I18n.t(`general.sort_${filter.sortByType}`),
    }).then((result) => {
      if (result.success) {
        const creditNotes = result.ar_credit_notes || [];
        this.setState({
          creditNotes,
          inProgress: false,
          pagination:
            creditNotes.length > 0 && result.pagination
              ? result.pagination
              : {},
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ creditNotes: [], inProgress: false });
      }
    });
  };

  handleOnChange = (element, value) => {
    this.setState(
      {
        [element]: value,
      },
      () => {
        if (element === "status") {
          this.getCreditNotes();
        }
      }
    );
  };

  getAccounts = () => {
    const { filter } = this.state;

    this.setState({
      accountsProgess: false,
    })
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(filter.wh_id);

    const accounts = accountsData || [];
    const accountObject = _.find(accounts, { id: filter.account_id })
    this.setState({
      accounts,
      accountsProgess: false,
      filter: { ...filter, account_id: accountObject ? accountObject.id : '' }
    });

    // fetchShortFormAccounts(this.state.orgId, filter.wh_id, true).then(
    //   (result) => {
    //     if (result.success) {
    //       const accounts = result.accounts || [];
    //       const accountObject = _.find(accounts, { id: filter.account_id})
    //       this.setState({
    //         accounts,
    //         accountsProgess: false,
    //         filter: {...filter, account_id: accountObject ? accountObject.id : ''}
    //       });
    //     } else {
    //       renderAlertMessage(result.errors)
    //       this.setState({ accountsProgess: false , accounts: []});
    //     }
    //   }
    // );
  };

  handleFilterChange = (element, value) => {
    const filter = {...this.state.filter};
    filter[element] = value;
    this.setState(
      {
        filter,
      },
      () => {
        if(element === 'wh_id'){
          this.getAccounts();
        }
      }
    );
  };


  closeFormWindow = () => {
    this.setState(
      {
        showForm: false,
        currentCrediNote : {}, 
        isNew: true,
      },
      () => {
        this.getCreditNotes();
      }
    );
  };

  showFormWindow = (rec = {}) => {
    this.setState({
      currentCrediNote: { ...rec },
      showForm: true,
      isNew: rec.id ? false : true,
    });
  };

  addBilligEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ billingEmails: validEmails });
  };

  handleSendingMails = (id) =>{
    if (!this.state.billingEmails.length) return alertMessage('Please enter atleast one email address', 'error');    
    const payload = {
      organization_id: userStore.getStateValue('selectedOrg'),
      credit_note_id: id,
      emails: this.state.billingEmails,
    };
    this.setState({ isSendingMail: true });
    CreditNotesApi.downloadCreditNote(payload).then((result) => {
      if (result.success) {
        alertMessage("Emails Send Successfully", 'success', 5);
        this.setState({ billingEmails: [], isModalVisible: false });
      } else {
        alertMessage(result.errors, 'error', 5);
      }
    }
    ).finally(() => { this.setState({ isSendingMail: false }); }
    );
  }

  renderEmailModal = () => {
    return (
      <BaseModal
        title={"Send Credit Memo To Emails"}
        onCancel={() => this.setState({
          isModalVisible: false,
          billingEmails: []
        })}
        width="50%"
        style={{ top: 40 }}
        maskClosable={false}
        visible={this.state.isModalVisible}
      >
        <Spin spinning={this.state.inProgress}>
        <Row className="tagsInput emailForm" type='flex' align='middle' gutter={8}>
          <Col span={ 21 }>
            <FormItem label={I18n.t('general.email')}>
            <Select
                  value = {this.state.billingEmails}
                  onChange={this.addBilligEmail}
                  mode="tags"
                  validationRegex = {this.addBilligEmail}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Enter or select Emails"
                  tokenSeparators={[","]}
                >
                 {
                  this.state.contacts.map(opt => <Option key={opt}>{opt}</Option>)
                 }
                </Select>
                </FormItem>
          </Col>
          <Col span={ 3 } className="alignRight">
              <Button type="primary"  icon='mail' onClick={() => this.handleSendingMails(this.state.currentId)}> 
                Send
              </Button>
          </Col>
        </Row>
        </Spin>
      </BaseModal>
    );
  }

  renderFormModal = () => {
    const warehouseFilter = this.props.warehouseFilter ? this.props.warehouseFilter : {}
    return (
    <BaseModal
      title={
        this.state.isNew
          ? I18n.t("credit_notes.add")
          : I18n.t("credit_notes.update")
      }
      onCancel={() => this.closeFormWindow()}
      width="650px"
      style={{
        top: 20,
        height: "50%",
      }}
      bodyStyle={{
        height: "100%",
        overflowY: "auto",
      }}
      maskClosable={false}
    >
      <Form
        creditNotes={this.state.creditNotes}
        warehouses={warehouseFilter.warehouses}
        isNew={this.state.isNew}
        org_id={this.state.orgId}
        userContext = {this.props.userContextData}
        onCancel={this.closeFormWindow}
        closeFormOnSubmit={this.closeFormWindow}
        defaultWH={ this.state.filter.wh_id ? 
            this.state.filter.wh_id : 
        (warehouseFilter.selectedWarehouses ? warehouseFilter.selectedWarehouses : '')
        }
      />
    </BaseModal>
  )
 };

  renderFilters = () => (
    <Row className="marginBottom10">
      {/* <Col xs={7}>
        <span className="textBold">{I18n.t("location.location")}:</span>
        &nbsp;&nbsp;
        <BaseSelect
          onChange={(e) => this.handleFilterChange('wh_id', e)}
          data={this.state.warehouses}
          size="small"
          selectKey="id"
          selectValue="id"
          selectedValue="name"
          style={{ width: "75%" }}
          value={this.state.filter.wh_id}
          showSearch={true}
        />
      </Col> */}
      <Col xs={6}>
      <span className="textBold">{I18n.t("general.account")}:</span>
        &nbsp;&nbsp;
        <BaseSelect
          onChange={(e) => this.handleFilterChange('account_id', e)}
          data={this.state.accounts}
          size="small"
          selectKey="id"
          selectValue="id"
          selectedValue="name"
          style={{ width: "75%" }}
          value={this.state.filter.account_id}
          showSearch={true}
        />
      </Col>
      <Col xs={2}>
        <Button type="primary" size="small" onClick={this.handleSearch} icon='search'>
            {I18n.t('general.search')}
        </Button>
      </Col>
    </Row>
  );

  handleSearch = () => {
    this.tableOptions.pagination.current = 1;
    this.getCreditNotes();
  }

  revertCreditNote = (id) => {
    this.setState({ inProgress: true });
    CreditNotesApi.revertCreditNote({
      credit_id: id,
    }).then((result) => {
      if (result.success) {
        this.setState({ inProgress: true });
        alertMessage(result?.data?.message ? result.data.message : I18n.t('messages.saved'));
        this.getCreditNotes();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: true });
      }
    });
  };

  openModal = (data) => {
    this.setState({
      isModalVisible: true,
      currentId: data.id,
      accountId : data.account_id
    });
    this.getEmails(data);
  };

  downloadCreditMemo = (id) => {
    const organization_id = this.props.currentOrgData.id;
    message.loading({
      key: "download",
      content: "Downloading PDF...",
    });
    const payload = {
      organization_id,
      credit_note_id: id,
    }
    CreditNotesApi.downloadCreditNote(payload).then((result) => {
      if (result.success) {
        message.destroy();
        base64ToPDF(result.file, result.fileName);
        alertMessage("PDF Downloaded Successfully" , "success", 10);
      } else {
        message.destroy();
        renderAlertMessage(result.errors)
      }
    });
  };

  render() {
    // const { pagination } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || AppConfig.perPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col span={10}>{I18n.t("credit_notes.label")}</Col>
            <Col xs={14}>
              <Row
                justify="end"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Col xs={14}>
                  {/* <Search
                    size="default"
                    value={this.state.token || ""}
                    placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                      "menu.account"
                    )}`}
                    onChange={(e) => {
                      const filteredString = removeSpecialCharacters(
                        e.target.value
                      );
                      this.handleOnChange("token", filteredString);
                    }}
                    onSearch={this.onSearch}
                    allowClear
                  /> */}
                </Col>
                {checkServiceExistance("AC") && (
                  <Col className="page-actions">
                    <Button
                      type="primary"
                      className="marginLeft10"
                      onClick={() => this.showFormWindow()}
                      icon="plus"
                    >
                      {I18n.t("credit_notes.add")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
            {/* <Col span={ 14 }>
              <Row type='flex' justify='end' gutter={4}>
                <Col span={14}>
                  <Search
                    size="default"
                    value={ this.state.token || '' }
                    placeholder={ `${I18n.t(`general.search`)} ${I18n.t('menu.account')}` }
                    onChange={ (e) => this.handleOnChange('token', e.target.value) }
                    onSearch={ this.onSearch }
                    allowClear
                  />
                </Col>
                { checkServiceExistance('AC') && <Col className="page-actions">
                  <Button type="primary" onClick={ () => this.showFormWindow() } icon='plus'>
                    { I18n.t('account.add') }
                  </Button>
                </Col>
                }
              </Row>
            </Col> */}
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            {this.renderFilters()}
            {/* <div className="alignRight">
              Status:&nbsp;
              <Select
                value={this.state.status}
                showSearch
                style={{ width: 200 }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                size="small"
                onChange={(e) => this.handleOnChange("status", e)}
              >
                <Select.Option key={"OPEN"} value={"OPEN"}>
                  Open
                </Select.Option>
                <Select.Option key={"POSTED"} value={"POSTED"}>
                  Posted
                </Select.Option>
              </Select>
            </div> */}
            <Row>
              <div>
              <Col xs={24}>
                <List
                  size="small"
                  // scroll = {{y:680}}
                  //scroll={{ y: "calc(100vh - 240px)" }}
                  data={this.state.creditNotes}
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter) =>
                    this.onTableChange(pagination, filter, sorter)
                  }
                  statusChange={(id) => this.handleAccountStatusChange(id)}
                  revertCreditNote={this.revertCreditNote}
                  downloadCreditMemo={this.downloadCreditMemo}
                  openModal={this.openModal}
                  // expandable={checkServiceExistance('SA')}
                />
                {this.renderEmailModal()}
              </Col>
              </div>
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
      </div>
    );
  }
}
CreditNotes.propTypes = {
  orgId: PropTypes.string,
  showHeadings: PropTypes.bool,
};
CreditNotes.defaultProps = {
  orgId: "",
  showHeadings: true,
};

export const CreditNoteComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, currentOrg: currentOrgData } = useContext(OrgContext);
  const userContextData = useContext(UserContext);
  return (
    <CreditNotes
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      currentOrgData={currentOrgData}
      userContextData = {userContextData}
      {...props}
    />
  );
});

export default CreditNoteComponent;
