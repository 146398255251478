import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";


class InstructionsList extends BaseList {
  constructor (props) {
    super(props);

    this.renderIntegrationButton = (record) => {
      const existingCodes = this.props.orgIntegrationsData.map((orgIntegration) => { return orgIntegration.integration_code; });
      const isExisting = existingCodes.includes(record.code);
      return (
        <Button icon={ isExisting ? null : "plus-circle" } size="small" style={{
          fontSize: "10px",
        }} type="primary" disabled={ isExisting } onClick={ () => {
          this.props.integrateService(
            record.id,
          );
        } }>{ isExisting ? I18n.t("general.added") : I18n.t("general.add") } </Button>
      );
    };

    this.columns = [
      {
        title: I18n.t('general.table_s_no'),
        dataIndex: "id",
        editable: false,
        render: (data, record, index) => {
          return index + 1;
        }
      },
      {
        title: I18n.t('general.name'),
        dataIndex: "name",
        editable: true,
      },
      {
        title: I18n.t('general.code'),
        dataIndex: "code",
        editable: true,
      },
      {
        title: '',
        dataIndex: "",
        editable: false,
        render: (data, record) => {
          return (
            this.renderIntegrationButton(record)
          );
        },
      },
    ];
  }
}
export default InstructionsList;

InstructionsList.propTypes = {
  data: PropTypes.array,
  integrateService: PropTypes.func,
  pagination: PropTypes.object,
  orgIntegrationsData: PropTypes.array,
};
InstructionsList.defaultProps = {
  data: [],
  integrateService: () => { },
  pagination: {
    position: 'none',
  },
  orgIntegrationsData: [],
};
