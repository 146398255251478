import { Col, Icon, Row, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import I18n from "../../common/I18n";
import { checkServiceExistance } from "../../helpers/common";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";

class InstructionTypesList extends BaseList {
	constructor(props) {
		super(props);
		this.columns = [
			{
				key: "instructions_type_name",
				title: I18n.t("general.name"),
				dataIndex: "name",
				sorter: ({ name: a }, { name: b }) => a.localeCompare(b),
				sortDirections: ["ascend", "descend", "ascend"],
				width: "30%",
				render: (data, record) => <span>{data}</span>,
			},
			{
				key: "instruction_type_code",
				title: I18n.t("general.code"),
				dataIndex: "code",
				sorter: ({ code: a }, { code: b }) => a.localeCompare(b),
				sortDirections: ["ascend", "descend", "ascend"],
				render: (data, record) => <span className="textUpperCase">{data}</span>,
				width: "60%",
			}];
			const hasEditAccess = checkServiceExistance('INSU');
			const hasDeleteAccess = checkServiceExistance('INSD');
			if(hasEditAccess || hasDeleteAccess){
			this.columns.push({
				key: "actions",
				render: (data) => {
					const { handleDelete, handleEdit } = this.props;
					return (
						<div className="pageActions instruction_types_list_pageactions">
							<Row gutter={8}>
								{hasEditAccess && <Col span={12}>
									<Tooltip title={I18n.t("general.edit")}>
									<EditIcon handleClick={() => handleEdit(data)}/>
									&nbsp;
									</Tooltip>
								</Col>
								}
								{hasDeleteAccess && <Col span={12}>
								<Tooltip title={I18n.t("general.delete")}>
									<DeleteIcon handleClick={() => handleDelete(data)}/>
									&nbsp;&nbsp;&nbsp;
									</Tooltip>
								</Col>
								}
							</Row>
						</div>
					);
				},
				align: "center",
				width: "10%",
			});
		}
	}
}
export default InstructionTypesList;

InstructionTypesList.propTypes = {
	data: PropTypes.array,
	pagination: PropTypes.object,
	handleDelete: PropTypes.func,
	handleEdit: PropTypes.func,
	handleConfig: PropTypes.func,
	isLoading: PropTypes.bool,
};
InstructionTypesList.defaultProps = {
	data: [],
	pagination: {},
	handleDelete: () => {},
	handleEdit: () => {},
	handleConfig: () => {},
	isLoading: false,
};
