import React, { Component, Fragment } from "react";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Icon,
  Select,
  DatePicker,
} from "../../common/UIComponents";
import {  alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import { saveNewInvoice } from "../../api/Billing";
import { fetchAccountAccessorialWithParams } from "../../api/Account";
import DeleteIcon from "../common/DeleteIcon";
import moment from "moment";
import { renderAlertMessage } from "../../helpers/common";

const dateFormat = "Do MMM YYYY";
const { RangePicker } = DatePicker;

class InvoiceCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: this.props.accounts ? this.props.accounts : [],
      transport_invoice_line: {
        invoice_name: "",
        invoice_value: "0",
        invoice_quantity: "0",
        invoice_type: "",
        accessorial_key: "",
        gl_code: "",
      },
      accessorial_invoice_line: {
        invoice_name: "",
        invoice_value: "0",
        invoice_quantity: "0",
        invoice_type: "",
        accessorial_key: "",
        gl_code: "",
      },
      form: {
        account_id: "",
        fromDate: "",
        toDate: "",
        new_invoice_lines: [],
      },
      accessorials: [],
    };
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    current && current > moment().endOf("day");
  };

  getAccessorials = () => {
    const account_id = this.state.form.account_id;
    fetchAccountAccessorialWithParams(account_id, "DISPATCHER").then(
      (result) => {
        if (result.success) {
          this.setState({
            accessorials: result.accessorials,
            // inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          // this.setState({ inProgress: false });
        }
      }
    );
  };

  handleSelectChange = (element, value) => {
    this.setState(
      {
        form: Object.assign({}, this.state.form, { [element]: value }),
      },
      () => {
        this.getAccessorials();
      }
    );
  };

  handleRangeChange = (dates, dateStrings) => {
    alert(JSON.stringify(dates));
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      this.setState({
        form: Object.assign({}, this.state.form, {
          fromDate: moment().startOf("month"),
          toDate: moment(),
        }),
      });
    } else {
      this.setState({
        form: Object.assign({}, this.state.form, {
          fromDate: dates[0],
          toDate: dates[1],
        }),
      });
    }
    this.props.onChange(dateFilter);
  };

  handleTransInputOnChange = (element, value, invoice_type) => {
    const DIGIT_REGX = /^\d+$/;
    if (element === "invoice_quantity" || element === "invoice_value") {
      if (DIGIT_REGX.test(value)) {
        this.setState({
          transport_invoice_line: Object.assign(
            {},
            this.state.transport_invoice_line,
            {
              [element]: value,
              invoice_type: invoice_type,
            }
          ),
        });
      }
    } else {
      this.setState({
        transport_invoice_line: Object.assign(
          {},
          this.state.transport_invoice_line,
          {
            [element]: value,
            invoice_type: invoice_type,
          }
        ),
      });
    }
  };

  handleAccessInputOnChange = (element, value, invoice_type) => {
    const DIGIT_REGX = /^\d+$/;
    if (element === "invoice_quantity" || element === "invoice_value") {
      if (DIGIT_REGX.test(value)) {
        this.setState({
          accessorial_invoice_line: Object.assign(
            {},
            this.state.accessorial_invoice_line,
            {
              [element]: value,
              invoice_type: invoice_type,
            }
          ),
        });
      }
    } else {
      this.setState({
        accessorial_invoice_line: Object.assign(
          {},
          this.state.accessorial_invoice_line,
          {
            [element]: value,
            invoice_type: invoice_type,
          }
        ),
      });
    }
  };

  handleDeleteTransportationAccess = (index) => {
    const new_invoice_lines = this.state.form.new_invoice_lines;
    new_invoice_lines.splice(index, 1);
    this.setState({
      form: Object.assign({}, this.state.form, {
        new_invoice_lines: new_invoice_lines,
      }),
    });
  };

  handleAddMoreTRansportations = () => {
    const new_invoice_lines = [
      ...this.state.form.new_invoice_lines,
      this.state.transport_invoice_line,
    ];
    this.setState(
      {
        form: Object.assign({}, this.state.form, {
          new_invoice_lines: new_invoice_lines,
        }),
      },
      () => {
        this.setState({
          transport_invoice_line: {
            invoice_name: "",
            invoice_value: "0",
            invoice_quantity: "0",
            invoice_type: "",
            accessorial_key: "",
            gl_code: "",
          },
        });
      }
    );
  };

  handleAddMoreAccessorials = () => {
    const new_invoice_lines = [
      ...this.state.form.new_invoice_lines,
      this.state.accessorial_invoice_line,
    ];
    this.setState(
      {
        form: Object.assign({}, this.state.form, {
          new_invoice_lines: new_invoice_lines,
        }),
      },
      () => {
        this.setState({
          accessorial_invoice_line: {
            invoice_name: "",
            invoice_value: "0",
            invoice_quantity: "0",
            invoice_type: "",
            accessorial_key: "",
            gl_code: "",
          },
        });
      }
    );
  };

  _renderTransportationInvoiceLines = () => {
    return (
      <div style={{ marginTop: "10px", marginBottom: "15px" }}>
        <Row>
          <h4 className="textBold" style={{ textDecoration: "underline" }}>
            {I18n.t("invoices.transportation_charges")}&nbsp;:
          </h4>
        </Row>
        <Row>
          <Col xs={6} className="textBold">
            Name
          </Col>
          <Col xs={4} className="textBold">
            GL Code
          </Col>
          <Col xs={6} className="textBold">
            Units
          </Col>
          <Col xs={4} className="textBold">
            Invoice Value
          </Col>
        </Row>
        {this.state.form.new_invoice_lines &&
          this.state.form.new_invoice_lines.map((invoice_line, index) => {
            if (invoice_line.invoice_type === "Transportation") {
              return (
                <Row key={index}>
                  <Col xs={6} className="paddingRight5">
                    <Input
                      type="text"
                      value={invoice_line.accessorial_key}
                      size="small"
                      onChange={(e) =>
                        this.handleTransInputOnChange(
                          "accessorial_key",
                          e.target.value,
                          "Transportation"
                        )
                      }
                    />
                  </Col>
                  <Col xs={4} className="paddingRight5">
                    <Input
                      type="text"
                      value={invoice_line.gl_code}
                      size="small"
                      onChange={(e) =>
                        this.handleTransInputOnChange(
                          "gl_code",
                          e.target.value,
                          "Transportation"
                        )
                      }
                    />
                  </Col>
                  <Col xs={6} className="paddingRight5">
                    <Input
                      type="number"
                      value={invoice_line.invoice_quantity}
                      size="small"
                      onChange={(e) =>
                        this.handleTransInputOnChange(
                          "invoice_quantity",
                          e.target.value,
                          "Transportation"
                        )
                      }
                    />
                  </Col>
                  <Col xs={4} className="paddingRight5">
                    <Input
                      type="number"
                      min="0"
                      value={invoice_line.invoice_value}
                      size="small"
                      onChange={(e) =>
                        this.handleTransInputOnChange(
                          "invoice_value",
                          e.target.value,
                          "Transportation"
                        )
                      }
                    />
                  </Col>
                  <Col xs={4}>
                    <Button
                      type="link"
                      onClick={() =>
                        this.handleDeleteTransportationAccess(index)
                      }
                    >
                      <DeleteIcon className="fontSize14"/>
                      &nbsp;&nbsp;&nbsp; 
                    </Button>
                  </Col>
                </Row>
              );
            } else {
              return null;
            }
          })}
        <Row>
          <Col xs={6} className="paddingRight5">
            <Input
              type="text"
              value={this.state.transport_invoice_line.accessorial_key}
              size="small"
              onChange={(e) =>
                this.handleTransInputOnChange(
                  "accessorial_key",
                  e.target.value,
                  "Transportation"
                )
              }
            />
          </Col>
          <Col xs={4} className="paddingRight5">
            <Input
              type="text"
              value={this.state.transport_invoice_line.gl_code}
              size="small"
              onChange={(e) =>
                this.handleTransInputOnChange(
                  "gl_code",
                  e.target.value,
                  "Transportation"
                )
              }
            />
          </Col>
          <Col xs={6} className="paddingRight5">
            <Input
              type="number"
              value={this.state.transport_invoice_line.invoice_quantity}
              size="small"
              onChange={(e) =>
                this.handleTransInputOnChange(
                  "invoice_quantity",
                  e.target.value,
                  "Transportation"
                )
              }
            />
          </Col>
          <Col xs={4} className="paddingRight5">
            <Input
              type="number"
              min="0"
              value={this.state.transport_invoice_line.invoice_value}
              size="small"
              onChange={(e) =>
                this.handleTransInputOnChange(
                  "invoice_value",
                  e.target.value,
                  "Transportation"
                )
              }
            />
          </Col>
          <Col xs={4}>
            <Button
              type="link"
              size="small"
              onClick={() => this.handleAddMoreTRansportations()}
            >
              <Icon type="plus-circle" />
              Add
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  _renderAccessorialInvoiceLines = () => {
    return (
      <Fragment>
        <Row>
          <h4 className="textBold" style={{ textDecoration: "underline" }}>
            {I18n.t("invoices.accessorial_charges")}&nbsp;:
          </h4>
        </Row>
        <Row>
          <Col xs={6} className="textBold">
            Name
          </Col>
          <Col xs={4} className="textBold">
            GL Code
          </Col>
          <Col xs={6} className="textBold">
            Units
          </Col>
          <Col xs={4} className="textBold">
            Invoice Value
          </Col>
        </Row>
        {this.state.form.new_invoice_lines &&
          this.state.form.new_invoice_lines.map((invoice_line, index) => {
            if (invoice_line.invoice_type === "Accessorial") {
              return (
                <Row key={index}>
                  <Col xs={6} className="paddingRight5">
                    <Select
                      value={invoice_line.accessorial_key}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className="width100Per"
                      size="small"
                      onChange={(e) =>
                        this.handleAccessInputOnChange(
                          "accessorial_key",
                          e,
                          "Accessorial"
                        )
                      }
                    >
                      <Select.Option value="">
                        {" "}
                        Select {I18n.t("order.accessorial")}
                      </Select.Option>
                      {this.state.accessorials.map((reqRecord) => (
                        <Select.Option
                          key={reqRecord.accessorial_key}
                          value={reqRecord.accessorial_key}
                        >
                          {reqRecord.name}
                        </Select.Option>
                      ))}
                    </Select>
                    {/* <Input
                      type="text"
                      value={invoice_line.accessorial_key}
                      size="small"
                      onChange={(e) =>
                        this.handleAccessInputOnChange(
                          "accessorial_key",
                          e.target.value,
                          "Accessorial"
                        )
                      }
                    /> */}
                  </Col>
                  <Col xs={4} className="paddingRight5">
                    <Input
                      type="text"
                      value={invoice_line.gl_code}
                      size="small"
                      onChange={(e) =>
                        this.handleAccessInputOnChange(
                          "gl_code",
                          e.target.value,
                          "Accessorial"
                        )
                      }
                    />
                  </Col>
                  <Col xs={6} className="paddingRight5">
                    <Input
                      type="text"
                      value={invoice_line.invoice_quantity}
                      size="small"
                      onChange={(e) =>
                        this.handleAccessInputOnChange(
                          "invoice_quantity",
                          e.target.value,
                          "Accessorial"
                        )
                      }
                    />
                  </Col>
                  <Col xs={4} className="paddingRight5">
                    <Input
                      type="number"
                      min="0"
                      value={invoice_line.invoice_value}
                      size="small"
                      onChange={(e) =>
                        this.handleAccessInputOnChange(
                          "invoice_value",
                          e.target.value,
                          "Accessorial"
                        )
                      }
                    />
                  </Col>
                  <Col xs={4}>
                    <Button
                      type="link"
                      onClick={() =>
                        this.handleDeleteTransportationAccess(index)
                      }
                    >
                      <DeleteIcon className="fontSize14"/>
                      &nbsp;&nbsp;&nbsp; 
                    </Button>
                  </Col>
                </Row>
              );
            } else {
              return null;
            }
          })}
        <Row>
          <Col xs={6} className="paddingRight5">
            <Select
              value={this.state.accessorial_invoice_line.accessorial_key}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              className="width100Per"
              size="small"
              onChange={(e) =>
                this.handleAccessInputOnChange(
                  "accessorial_key",
                  e,
                  "Accessorial"
                )
              }
            >
              <Select.Option value="">
                {" "}
                Select {I18n.t("order.accessorial")}
              </Select.Option>
              {this.state.accessorials.map((reqRecord) => (
                <Select.Option
                  key={reqRecord.accessorial_key}
                  value={reqRecord.accessorial_key}
                >
                  {reqRecord.name}
                </Select.Option>
              ))}
            </Select>
            {/* <Input
              type="text"
              value={this.state.accessorial_invoice_line.accessorial_key}
              size="small"
              onChange={(e) =>
                this.handleAccessInputOnChange(
                  "accessorial_key",
                  e.target.value,
                  "Accessorial"
                )
              }
            /> */}
          </Col>
          <Col xs={4} className="paddingRight5">
            <Input
              type="text"
              value={this.state.accessorial_invoice_line.gl_code}
              size="small"
              onChange={(e) =>
                this.handleAccessInputOnChange(
                  "gl_code",
                  e.target.value,
                  "Accessorial"
                )
              }
            />
          </Col>
          <Col xs={6} className="paddingRight5">
            <Input
              type="text"
              value={this.state.accessorial_invoice_line.invoice_quantity}
              size="small"
              onChange={(e) =>
                this.handleAccessInputOnChange(
                  "invoice_quantity",
                  e.target.value,
                  "Accessorial"
                )
              }
            />
          </Col>
          <Col xs={4} className="paddingRight5">
            <Input
              type="number"
              min="0"
              value={this.state.accessorial_invoice_line.invoice_value}
              size="small"
              onChange={(e) =>
                this.handleAccessInputOnChange(
                  "invoice_value",
                  e.target.value,
                  "Accessorial"
                )
              }
            />
          </Col>
          <Col xs={4}>
            <Button
              type="link"
              size="small"
              onClick={() => this.handleAddMoreAccessorials()}
            >
              <Icon type="plus-circle" />
              Add
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  _renderSaveBtn = () => {
    return (
      <Row
        style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
      >
        <Button
          className="marginRight5"
          type="default"
          onClick={() => this.props.closeCreateInvoiceFormWindow()}
        >
          Cancel
        </Button>
        <Button
          className="marginLeft5"
          type="primary"
          onClick={() => this.handleSaveInvoice()}
        >
          Save
        </Button>
      </Row>
    );
  };

  clearCreateInvoiceForm = () => {
    this.setState({
      transport_invoice_line: {
        invoice_name: "",
        invoice_value: "0",
        invoice_quantity: "0",
        invoice_type: "",
        accessorial_key: "",
        gl_code: "",
      },
      accessorial_invoice_line: {
        invoice_name: "",
        invoice_value: "0",
        invoice_quantity: "0",
        invoice_type: "",
        accessorial_key: "",
        gl_code: "",
      },
      form: {
        account_id: "",
        fromDate: "",
        toDate: "",
        new_invoice_lines: [],
      },
    });
  };

  handleSaveInvoice = () => {
    const data = this.state.form;
    saveNewInvoice(data).then((result) => {
      if (result.success) {
        this.props.getInvoices();
        this.clearCreateInvoiceForm();
        alertMessage(I18n.t("messages.saved"));
        // this.props.showManualInvoiceDetails(result.invoice_number);
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  render() {
    return (
      <div>
        <Row>
          <FormItem label={I18n.t("general.account")}>
            <Select
              value={this.state.form.account_id}
              showSearch
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => this.handleSelectChange("account_id", e)}
            >
              <Select.Option key="all" value="">
                -- Select Account --
              </Select.Option>
              {this.state.accounts.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Row>
        {/* <Row>
          <FormItem label={I18n.t("invoices.date")}>
            <RangePicker
              // disabledDate={disabledDate}
              defaultValue={[moment().startOf("month"), moment()]}
              value={[this.state.form.fromDate, this.state.form.toDate]}
              format={dateFormat}
              style={{ width: "100%" }}
              onChange={(dates, dateStrings) =>
                this.handleRangeChange(dates, dateStrings)
              }
              disabled={
                this.state.form[
                  I18n.t("order.filters.schedule_orders.type")
                ] === I18n.t("order.filters.schedule_orders.unscheduled_key")
              }
              disabledDate={this.disabledDate}
            />
          </FormItem>
        </Row> */}
        <Row>
          <this._renderTransportationInvoiceLines />
          <this._renderAccessorialInvoiceLines />
          <this._renderSaveBtn />
        </Row>
      </div>
    );
  }
}

export default InvoiceCreateForm;
