import React, {
  Component,
  useContext,
  useState,
  useEffect,
  Fragment,
} from "react";
import { Button, Col, Icon, Row, Spin, Tooltip } from "antd";
import AppConfig from "../../config/AppConfig";
import { RecordsPerPage } from "../orders/RecordsPerPage";

import { Dropdown, message } from "../../common/UIComponents";
import { checkNegitive, formatDate, isEmpty } from "../../common/Common";
import {
  base64ToPDF,
  convertToTags,
  limitString,
  renderAlertMessage,
} from "../../helpers/common";
import moment from "moment";
import { fetchLocations } from "../../api/LocationsApi";
import AppliedFiltersTags from "../../containers/AppliedFiltersTags";
import { UserContext } from "../../context/UserContext";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { DisplaySettingsContext } from "../../context/DisplaySettings";
import { ScreenKeys } from "../../containers/constants";
import _ from "lodash";
import I18n from "../../common/I18n";
import ReportFilter from "../reports/ReportFilter";
import { fetchOrgAccounts } from "../../api/Account";
import { glCodeReportApi } from "../../api/GlCodeReportApi";
import userStore from "../../stores/UserStore";
import GLCodeReportList from "./GLCodeReportList";

const defaultFilter = {
  fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
  toDate: moment(),
  warehouse_id: [],
  accountCodes: [],
  as_export: false,
  search_order_token: "",
  searchKeyPressed: false,
  sortBy: "none",
  sortByType: "descend",
};

class GlCodeReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gl_code_reports: [],
      total_revenue : null,
      appliedFiltersTags: [],
      warehouses: [],
      organization: "",
      warehouse_id: [],
      organization_id: "",
      inProgress: false,
      listProgress: false,
      accounts: [],
      recordsPerPage: props.recordsPerPage,
      pagination: {},
      filterWindow: false,
      searchText: "",
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      emailProgress: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount() {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    this.setState(
      (prevState) => ({
        ...prevState,
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          warehouseIds: currentWhId,
        },
      }),
      () => {
        this.getWarehouses();
        this.getAccounts();
        this.updateWhFilter();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses)) {
      const { warehouseFilter } = this.props;
      const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
        ? warehouseFilter.selectedWarehouses
        : warehouseFilter?.selectedWarehouses
          ? [warehouseFilter.selectedWarehouses]
          : null;

      this.setState(
        (prevState) => ({
          ...prevState,
          filterPlaceHolder: {
            ...prevState.filterPlaceHolder,
            warehouseIds: currentWhId,
          },
        }),
        () => {
          this.getAccounts();
          this.updateWhFilter();
          this.computeTags(this.state.filterPlaceHolder);
        }
      );
    }


    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }

  getAccounts = () => {
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState(
          {
            accounts: result.accounts || [],
          }
        );
      } else {
        this.setState({
          accounts: [],
        });
      }
    });
  };

  getGLCodeReport = (export_option = null,cb = null) => {
    const { filterPlaceHolder, pagination, accounts } = this.state;
    const { orgData } = this.props;
    const organization_id = userStore.getStateValue("selectedOrg");
    // const page = this.tableOptions.pagination.current;
    // const perPage = 10000;
    const orderNumber = "";
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const payload = {
      fromDate: formattedFromDate,
      todate: formattedToDate,
      // page,
      // perPage,
      orderNumber,
      organization_id,
      sortBy: filterPlaceHolder.sortBy,
      sortByType: filterPlaceHolder.sortByType || "ascend",
      account_codes: filterPlaceHolder?.accountCodes ? accounts.filter(rec => filterPlaceHolder.accountCodes.includes(rec.code)).map(rec => rec.id) : [],
      warehouse_id: filterPlaceHolder.warehouse_id,

    };
    if (export_option) {
      message.loading("Export to PDF in progress..", 0);
      payload.export_option = export_option;
    }
    this.setState({ listProgress: true });
    glCodeReportApi.fetch(payload).then((result) => {
      if (result.success) {
        if (export_option === "pdf") {
          this.setState({ listProgress: false, emailProgress: false });
          const response = result.file;
          if (response) {
            message.destroy();
            const fileName = `${orgData?.currentOrg?.code}_GLCodeReport`;
            base64ToPDF(response, fileName, "PDF");

          }
        } else {
          message.destroy();
          const GLCodeReports = result.GLCodeReports ? result.GLCodeReports : [];
          const totalRevenue = result.TotalRevenue ? result.TotalRevenue : "";
          this.setState({
            gl_code_reports: GLCodeReports,
            total_revenue : totalRevenue,
            // pagination: result.pagination.pagination,
            listProgress: false,
            emailProgress: false,
          });
        }
      } else {
        renderAlertMessage(result.errors || I18n.t('messages.no_record_found'))
        if (export_option) {
          this.setState({
            listProgress: false,
            emailProgress: false,
          });
        } else {
          this.setState({
            gl_code_reports: [],
            listProgress: false,
            pagination: {},
            emailProgress: false,
          });
        }
      }
    });
  };

  clearFilter = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          fromDate: moment().subtract(
            AppConfig.invoices_filter_duration,
            "day"
          ),
          toDate: moment(),
          warehouse_id: currentWhId,
          accountCodes: [],
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "warehouse_id":
        filterVariable.warehouse_id = currentWhId;
        break;
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      this.handleSearch();
    });
  };

  computeTags = (filterPlaceHolder) => {
    const { warehouseFilter } = this.props;
    const { warehouses } = warehouseFilter;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const keys = ["fromDate", "toDate", "warehouse_id", "accountCodes"];

    const filteredData = {
      fromDate: {
        label: "GL Code Report Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(AppConfig.invoices_filter_duration, "day")
              .format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouse_id: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouse_id.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouse_id");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouse_id?.length === 1 &&
          filterPlaceHolder?.warehouse_id[0] === currentWhId[0]
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder?.accountCodes?.includes(account.code)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },

    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  updateWhFilter = () => {
    const { warehouseFilter } = this.props;
    const filterVals = this.state.filterPlaceHolder;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.handleFilterPlaceHolderChange(
      {
        ...filterVals,
        warehouse_id: currentWhId,
      },
      () => {
        this.getGLCodeReport();
      }
    );
  };

  onSearch = (value) => {
    console.log("onSearch", value);
  };

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
      }
    });
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage;
    }
    this.tableOptions.pagination.current = 1;
    this.setState({ recordsPerPage: value }, () => {
      this.tableOptions.pagination.current = 1;
      this.getGLCodeReport();
      updateRecordsPerPage(ScreenKeys.GLCODE_REPORT, value);
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getGLCodeReport();
    } else if (sorter && !isEmpty(sorter.field)) {
      const filterVals = this.state.filterPlaceHolder;
      this.tableOptions.pagination.current = 1;
      this.handleFilterPlaceHolderChange(
        {
          ...filterVals,
          sortBy: sorter.columnKey,
          sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.getGLCodeReport();
        }
      );
    }
    // this.getGLCodeReport();
  };

  tablePagination = () => {
    const { pagination, recordsPerPage } = this.state;
    return {
      total: pagination ? pagination.total_count : 0,
      current: pagination ? pagination.current_page : 1,
      pageSize: pagination
        ? pagination.per_page || recordsPerPage
        : recordsPerPage,
    };
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  onTagClose = (tagsObj) => {
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          ...tagsObj,
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.getGLCodeReport();
      }
    );
  };

  setDefaultvalues = () => {
    setFromDate(moment().subtract(AppConfig.invoices_filter_duration, "day"));
    setToDate(moment());
  };

  handleExport = (mails = [], cb = null) => {
    this.setState({
       emailProgress: true, 
    })
    this.getGLCodeReport("pdf", mails, cb);
  };

  handleSearch = (filter = {}) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, filter);
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getGLCodeReport();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  _renderSearchBar = () => (
    <Dropdown
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 550,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <ReportFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            warehouseFilter={this.props.warehouseFilter}
            onCancel={this.handleVisibleChange}
            orgData={this.props.orgData}
            showAmountCheck={false}
          />
        </div>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "5px" }}>Filter</span>
        <Icon
          style={{
            color: "black",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "5px",
          }}
          type="filter"
        />
      </div>
    </Dropdown>
  );

  _renderHeader = () => (
    <Row className="page-header">
      <Col xs={12}>{I18n.t("gl_code_report.menu")}</Col>
      <Col xs={10}></Col>
      <Col xs={2} className="alignRight headerOptionDiv">
        {this._renderSearchBar()}
      </Col>
    </Row>
  );

  resetSearchTags = () => {
    this.setState({ updateSearchTags: false });
  };
  combinedKey = (record, index) => `${record.id}-${index}`;

  render() {
    const {
      gl_code_reports,
      total_revenue,
      recordsPerPage,
      filterPlaceHolder,
      updateSearchTags,
    } = this.state;
    let revenueNumber = parseFloat(total_revenue);
    let formattedRevenue = checkNegitive(revenueNumber); // revenueNumber.toLocaleString('en-IN', { maximumFractionDigits: 2 });
    return (
      <Spin
        spinning={this.state.inProgress || this.state.listProgress}
        delay={1000}
      >
        <div className="content-outer">
          <div className="content">
            <this._renderHeader />

            <Row type="flex" justify="space-between">
              <Col span={24}>
                <AppliedFiltersTags
                  tags={this.state.appliedFiltersTags}
                  clearFilter={() => this.clearFilter()}
                />
              </Col>
            </Row>

            <Row>
              <Col span={18} offset={3}>
              <Row type="flex" justify="end">
              <Col xs={21}>
              <p className="textBold" style={{ marginTop: 4 }}>
                  <sup className="textRed textBold">*</sup><span className="notes_content">{I18n.t('gl_code_report.filter_notes')}</span>
              </p>
              </Col>
              {/* <Col>
                
                  <span className="filterSetIcon marginRight10">
                    <img
                      src={excelIcon}
                      className="cursorPointer"
                      onClick={this.handleExport}
                      style={{
                        width: 24,
                        marginTop: "-2px",
                      }}
                    />
                  </span>
                )}
                
              </Col> */}
              <Col md={3} className="alignLeft" style={{ marginTop: -2, marginBottom: 11 }}>
              {gl_code_reports?.length > 0 && (
              <Button className="cursorPointer" type="primary" shape="round" onClick={this.handleExport}  >
                <span>Export PDF <Icon type="file-excel" theme="filled" /></span>
              </Button>
              )}
              </Col>
            </Row>
                <GLCodeReportList
                  rowKey={this.combinedKey}
                  data={gl_code_reports}
                  scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
                  pagination={{position:'none'}}
                  footer={() => (
                    !_.isEmpty(gl_code_reports) && (
                    <Fragment>
                      <Row type="flex" className="fontSize14 textBold">
                        <Col style={{width:"55%"}} className="alignRight paddingRight10">
                          Total Revenue
                        </Col>
                        <Col style={{width:"11%"}} className="alignRight">
                           {formattedRevenue}
                        </Col>
                      </Row>
                    </Fragment>
                    )
                  )}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    );
  }
}

export const GlCodeReport = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const userContext = useContext(UserContext);
  const { currentOrg } = userContext;
  const orderConfigContextData = useContext(OrderConfigContext);

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);

  const perPageKey = ScreenKeys.GLCODE_REPORT;
  const recordsPerPage = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  return (
    <GlCodeReportComponent
      warehouseFilter={warehouseFilter}
      orgData={orgData}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      currentOrg={currentOrg}
      userContext={userContext}
      orderConfigContext={orderConfigContextData}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default GlCodeReport;
