import { Tabs } from "antd";
import React from "react";
import ListPreplan from "./ListPreplan";
import RouteListPreplan from "./RouteListPreplan";
import { PreplanStopOrders } from "./PreplanStopOrders";
import I18n from "../../../common/I18n";

export const PreplanListView = (props) => {
  const onChange = (key) => {
  };
  return (
    <div className="card-container tabHover">
      <Tabs defaultActiveKey="UnassignedOrders" type="card" onChange={onChange}>
        <Tabs.TabPane
          tab={I18n.t("menu.preplan_unassigned")}
          key="UnassignedOrders"
        >
          <ListPreplan dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={I18n.t("menu.preplan_routeOrders")}
          key="RouteOrders"
        >
          <RouteListPreplan dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane
        tab={I18n.t("menu.stop_orders")}
        key="StopOrders"
        >
          <PreplanStopOrders dispContext={props.dispContext} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
