/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
  Alert, Col, Row, FormItem, Select, Avatar, Icon,
  Button, Spin, Badge, message, Input, notification, Modal 
} from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import { fetchActiveUsers } from '../../api/UsersApi';
import { capacityValidationForRoute } from '../../api/PreplanApi';
import { fetchTrucks } from '../../api/TrucksApi';
import {
  allocateAllDrivers, allocateTruck, manageRoute,
  updateRoute, moveStop, moveOrder, deleteRoutes,
} from '../../api/Routes';
import truckImg from '../../../assets/images/truck.png';
import { findOverloadReasons } from '../../helpers/capacityValidation';
import { isEmpty } from 'lodash';
import RouteLabel from '../../config/RouteLabel';
import { getLocationIds } from '../../helpers/orders';
import { renderAlertMessage, showFlashMessage } from '../../helpers/common';

const { Option } = Select;
const { TextArea } = Input;
class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.users || [],
      trucks: [],
      inProgress: false,
      routeInfo: this.props.routeInfo || [],
      driversInfo: {
        primary: {
          driverId: this.props.primaryDriver || '',
          selectedDriverId: this.props.showExistDrivers === true && this.props.primaryDriver ? this.props.primaryDriver : '',
        },
        secondary: {
          driverId: this.props.secondaryDriver || '',
          selectedDriverId: this.props.showExistDrivers === true && this.props.secondaryDriver ? this.props.secondaryDriver : '',
        },
      },
      truckInfo: {
        assignedId: this.props.truck || '',
        selectedId: '',
      },
      preplanId: this.props.preplanId || '',
      selectedOrders: this.props.selectedOrders || [],
      routeId: this.props.routeId,
      taskType: this.props.assignTaskType,
      reason: '',
      moveInfo: this.props.moveInfo || {},

    };
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }
  getUsers = () => {
    this.setState({ inProgress: true });
    fetchActiveUsers(this.userTypes, 1, 1000)
      .then((result) => {
        if (result.success) {
          this.setState({
            users: result.users,
            inProgress: false,
          });
        } else {
          this.setState({ inProgress: false });
          renderAlertMessage(result.errors)
        }
      });
  };

  getTrucks = (initial, cb) => {
    this.setState({ inProgress: true });
    fetchTrucks({ status: 'active' }, 1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            trucks: result.trucks,
            inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  getRouteFromUser = (id) => {
    const user = _.find(this.state.users, { id });
    const routeIndex = _.findIndex(this.state.routeInfo, ['route_id', user.route_id]);
    if (routeIndex >= 0) {
      return this.state.routeInfo[routeIndex].route_id;
    }
    return '';
  }

  doProcessBeforeChange = (element, value, type) => {
    if (!isEmpty(value)) {
      const routeId = this.getRouteFromUser(value);
      if (routeId) {
        this.setState({
          routeId,
        });
      }
    }
  }

  handleDriverChange = (element, value, type) => {
    this.setState({
      driversInfo: Object.assign({}, this.state.driversInfo, {
        [type]: {
          ...this.state.driversInfo[type],
          [element]: !_.isNil(value) ? value : '',
        },
      }),
    }, () => {
      this.doProcessBeforeChange(element, value, type);
    });
  }

  handleTruckChange = (element, value) => {
    this.setState({
      truckInfo: Object.assign({}, this.state.truckInfo, {
        [element]: value,
      }),
    });
  }


  assignDrivertoRoute = () => {
    if (this.state.driversInfo.primary.selectedDriverId || this.state.driversInfo.secondary.selectedDriverId) {
      const action_type = RouteLabel.actionLabels.ROUTE_REALLOCATE
      this.setState({
        assignStatus: true,
      });
      allocateAllDrivers(this.state.driversInfo.primary.selectedDriverId, this.state.driversInfo.secondary.selectedDriverId, this.state.routeId, action_type)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.allocated_successfully'));
            const route = result.driver.nav_route ? result.driver.nav_route : {};
            const unprocessedOrders = result.driver.unprocessed_orders || [];
            const errorMessage = route.optimizer_readable_error_message && _.isArray(route.optimizer_readable_error_message) ? route.optimizer_readable_error_message : [];

            if (
              (
                route.optimized === false &&
                errorMessage.length === 0
              ) ||
              (route.optimized === true && this.props.driver_stop_existed === true)
            ) {
              this.props.closeWindow(route, this.state.taskType, unprocessedOrders);
            } else {
              this.props.closeWindow(route, this.state.taskType, unprocessedOrders);
            }
          } else {
            this.setState({
              assignStatus: false,
            });
            renderAlertMessage(result.errors)
          }
        });
    } else if (this.state.truckInfo.selectedId) {
      this.assignTrucktoRoute();
    } else {
      alertMessage('Plesse select either driver or vehicle', 'error', 5);
    }
  };

  assignTrucktoRoute = () => {
    if (this.state.truckInfo.selectedId) {
      this.setState({
        assignStatus: true,
      });
      allocateTruck(this.state.routeId, this.state.truckInfo.selectedId)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.assigned_successfully'));
            this.resetFields();
          } else {
            this.setState({
              assignStatus: false,
            });
            renderAlertMessage(result.errors)
          }
        });
    } else {
      this.setState({
        assignStatus: false,
      });
      alertMessage('Please Select Vehicle', 'error', 5);
    }
  };

  resetFields = () => {
    this.setState({
      assignStatus: false,
      driversInfo: {
        primary: {
          driverId: this.state.driversInfo.primary.selectedDriverId,
          selectedDriverId: '',
        },
        secondary: {
          driverId: this.state.driversInfo.secondary.selectedDriverId,
          selectedDriverId: '',
        },
      },
    });
    this.props.closeWindow();
  }

  moveRouteStop = () => {
    const oldRouteId = this.getRouteFromUser(this.state.driversInfo.primary.driverId);
    const newRouteId = this.getRouteFromUser(this.state.driversInfo.primary.selectedDriverId);
    if (oldRouteId !== newRouteId) {
      const data = {
        desc: this.state.reason,
        stop_id: this.state.moveInfo.refs ? this.state.moveInfo.refs.join(',') : '',
        preplan_id: this.state.preplanId,
        new_nav_route_id: newRouteId || '',
        old_nav_route_id: oldRouteId || '',
      };
      if (isEmpty(newRouteId)) {
        data.primary_driver_id = this.state.driversInfo.primary.selectedDriverId;
      }
      message.loading({ content: I18n.t('messages.updating_route'), key: 'preplan', duration: 2 });
      showFlashMessage('info', I18n.t('messages.updating_route'));
      moveStop(data)
        .then((result) => {
          if (result.success) {
            this.checkMoveRouteElemntResponse(result);
          } else {
            renderAlertMessage(result.errors)
            this.setState({
              inProgress: false,
            });
          }
        });
    } else {
      alertMessage(`Please select the ${I18n.t('general.Driver')} to move`, 'error', 10);
    }
  }

  processMoveRouteOrder = () => {
    const oldRouteId = this.getRouteFromUser(this.state.driversInfo.primary.driverId);
    const navRoutes = [...this.state.routeInfo];
    const routeIndex = _.findIndex(navRoutes, ['route_id', oldRouteId]);
    if (routeIndex >= 0) {
      const currentRoute = navRoutes[routeIndex];
      oldOrders = [currentRoute.customer_order_ids];
      if (oldOrders.length === 1) {
        this.handleRouteDeleteClick(oldRouteId);
      } else {
        this.moveRouteOrder(oldRouteId);
      }
    } else {
      this.handleRouteDeleteClick(oldRouteId);
    }
  }

  handleRouteDeleteClick = (id, newRoute = '') => {
    this.setState({ inProgress: true });
    deleteRoutes(id)
      .then((result) => {
        if (result.success) {
          const unprocessedOrders = result.nav_route && result.nav_route.unprocessed_orders ?
          result.nav_route.unprocessed_orders :
          [];
          alertMessage(I18n.t('messages.preplan_route_deleted'));
          this.setState({
            inProgress: false,
          }, () => {
            this.props.closeWindow(newRoute, this.state.taskType, unprocessedOrders);
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  checkMoveRouteElemntResponse = (result, checkRouteEmpty = true) => {
    const navRoute = isEmpty(result.nav_route) ? {} : result.nav_route;
    const newRoute = navRoute.new_nav_route ? navRoute.new_nav_route : {};
    const oldRoute = navRoute.old_nav_route ? navRoute.old_nav_route : {};
    const unprocessedOrders = navRoute.unprocessed_orders || [];
    const isRouteEmpty = !oldRoute.customer_order_ids || (oldRoute.customer_order_ids && oldRoute.customer_order_ids.length === 0);
    if (checkRouteEmpty && isRouteEmpty) {
      this.handleRouteDeleteClick(oldRoute.id, newRoute.id);
    } else {
      this.props.closeWindow(isRouteEmpty ? newRoute.id : oldRoute.id, this.state.taskType, unprocessedOrders);
    }
  }

  moveRouteOrder = () => {
    const oldRouteId = this.getRouteFromUser(this.state.driversInfo.primary.driverId);
    const newRouteId = this.getRouteFromUser(this.state.driversInfo.primary.selectedDriverId);
    if (oldRouteId !== newRouteId) {
      const data = {
        desc: this.state.reason,
        order_id: this.state.moveInfo.refs ? this.state.moveInfo.refs.join(',') : '',
        preplan_id: this.state.preplanId,
        stop_id: this.state.moveInfo.parent_id,
        new_nav_route_id: newRouteId || '',
        old_nav_route_id: oldRouteId || '',
      };
      if (isEmpty(newRouteId)) {
        data.primary_driver_id = this.state.driversInfo.primary.selectedDriverId;
      }
      message.loading({ content: I18n.t('messages.updating_route'), key: 'preplan', duration: 2 });
      // showFlashMessage('info', I18n.t('messages.updating_route'));
      moveOrder(data)
        .then((result) => {
          if (result.success) {
            this.checkMoveRouteElemntResponse(result, false);
          } else {
            renderAlertMessage(result.errors)
            this.setState({
              inProgress: false,
            });
          }
        });
    } else {
      alertMessage(`Please select new ${I18n.t('general.Driver')} to move`, 'error', 10);
    }
  }

  showRouteValidationError = (capacity_info, routeName, cb, setStopProgress) =>{
    const capacityInfo = capacity_info || {};
    const overloadedBy = findOverloadReasons(capacityInfo);
    Modal.confirm({
      title: "Errors",
      content: (
        <Fragment>
          <h4 >{routeName} : <span className="textRed">{I18n.t('preplan.capacity.capacity_overloaded_by')} {overloadedBy}</span></h4>
          <div className="marginTop10">{I18n.t('messages.still_proceed')}</div>
        </Fragment>
      ),
      width: "40%",
      onOk: () => {
        if(cb) {
          cb();
        }
      },
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        setStopProgress(false);
      }
    });
  }

  validateCapacity = () => {
    const { doCapacityValidation, preplanId, setStopProgress } = this.props;
    const { users , routeId, routeInfo, selectedOrders, driversInfo} = this.state;
    if(!isEmpty(driversInfo.primary.selectedDriverId)){
       if (doCapacityValidation) {
      let oldOrders = [];
      let routeName = "";
      if(routeId){
        const routeIndex = _.findIndex(routeInfo, ['route_id', routeId]);
        if (routeIndex >= 0) {
          oldOrders = [...routeInfo[routeIndex].customer_order_ids];
        }
      }
      const userObject = _.find(users, { id: driversInfo.primary.selectedDriverId});
      const totalOrders = [].concat(oldOrders, selectedOrders);
      if(!isEmpty(userObject)){
        routeName = userObject.displayName;
      }
      const data = { 
        preplanId, 
        order_ids: totalOrders,
        driver_id: driversInfo.primary.selectedDriverId,
      }
      notification.open({
        key: preplanId,
        message: I18n.t('preplan.capacity.validation'),
        description: <div>{I18n.t('messages.capacity_process')}</div>,
        icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
        placement: 'bottomRight',
        duration: 20,
      })
      capacityValidationForRoute(data, preplanId).then((result) => {
        if (result.success) {
          notification.close(preplanId);
          if (!isEmpty(result.preplan) && !result.preplan.capacity_overloaded) {
            this.handleSave();
          } else {
            this.showRouteValidationError(
              result.preplan.capacity_info,
              routeName,
              this.handleSave,
              setStopProgress,
            )
          }
        } else {
          setStopProgress(false)
          notification.error({
            key: preplanId,
            message: routeName,
            description: <div>{result.errors[0]}</div>,
            placement: 'bottomRight',
          });
        }
      })
    } else {
      this.handleSave();
    }
    } else { 
       alertMessage(`Please select ${I18n.t('general.Driver')}`, 'error', 10);
    }
  }

  handleSave = () => {
    if (this.state.taskType === 'move') {
      if (this.state.moveInfo.type === 'move_stop') {
        this.moveRouteStop();
      } else {
        this.moveRouteOrder();
      }
    } else {
      
      let data = {};
       if(!isEmpty(this.state.driversInfo.primary.selectedDriverId)){
        this.setState({
          inProgress: true,
        });
        if (this.state.routeId) {
          let oldOrders = [];
          const routeIndex = _.findIndex(this.state.routeInfo, ['route_id', this.state.routeId]);
          if (routeIndex >= 0) {
            oldOrders = [...this.state.routeInfo[routeIndex].customer_order_ids];
          }
          if (this.state.routeStatus !== 'PREPARE' && this.state.routeStatus !== 'DISPATCHED' && this.state.routeStatus !== 'COMPLETED') {
            data = Object.assign({}, data, {
              nav_route_id: this.state.routeId,
              new_customer_order_ids: this.state.selectedOrders,
              preplan_id: this.state.preplanId,
              primary_driver_id: this.state.driversInfo.primary.selectedDriverId,
              secondary_driver_id: this.state.driversInfo.secondary.selectedDriverId,
              action_type: RouteLabel.actionLabels.ORDER_ADDED,
              location_ids: getLocationIds(this.state.selectedOrders, this.props.selectedOrderRecords, 'locations', 'array')
            });
            message.loading({ content: I18n.t('messages.updating_route'), key: 'preplan', duration: 5 });
            showFlashMessage('info', I18n.t('messages.updating_route'));
            updateRoute(data)
              .then((result) => {
                if (result.success) {
                  const navRoute = result.nav_route ? result.nav_route : {};
                  const unprocessedOrders = result.unprocessed_orders || [];
                  this.props.closeWindow(navRoute, this.state.taskType, unprocessedOrders);
                } else {
                  renderAlertMessage(result.errors)
                  this.setState({
                    inProgress: false,
                  });
                }
              });
          } else {
            data = Object.assign({}, data, { nav_route_id: this.state.routeId, new_customer_order_ids: this.state.routes.selectedOrderKeys });
            /* updateMidRoute(data)
              .then((result) => {
                if (result.success) {
                  if (result.nav_route.nav_route_id !== this.state.routeId) {
                    this.getRouteInfo(result.nav_route.nav_route_id);
                  } else {
                    this.props.history.push({
                      pathname: `/stops/${this.state.routeId}`,
                      showOdrersByDefault: true,
                    });
                  }
                  this.setState({
                    loading: false,
                  });
                } else {
                  renderAlertMessage(result.errors)
                  this.setState({
                    loading: false,
                  });
                }
              }); */
          }
        } else {
          data = Object.assign({}, data, {
            order_ids: this.state.selectedOrders,
            preplan_id: this.state.preplanId,
            primary_driver_id: this.state.driversInfo.primary.selectedDriverId,
            secondary_driver_id: this.state.driversInfo.secondary.selectedDriverId,
            action_type: RouteLabel.actionLabels.ROUTE_CREATED,
            location_ids: getLocationIds(this.state.selectedOrders, this.props.selectedOrderRecords, 'locations', 'array')
          });
          // showFlashMessage('info', I18n.t('messages.creating_route'), 1000);
          message.loading({ content: I18n.t('messages.creating_route'), key: 'preplan', duration: 5 });
          manageRoute(data, !this.state.routeId)
            .then((result) => {
              if (result.success) {
                // this.setState({ routeId: result.routeTasks.nav_route.id }, () => {
                //   this.getOptimizeInfo();
                // });
                const unprocessedOrders = result.routeTasks.unprocessed_orders || [];
                this.props.closeWindow(result.routeTasks.nav_route, this.state.taskType, unprocessedOrders);
              } else {
                renderAlertMessage(result.errors)
                this.setState({
                  inProgress: false,
                });
              }
            });
      }
     } else {
        alertMessage(`Please select ${I18n.t('general.Driver')}`, 'error', 10);
      }
    }
  };

  getOptimizeInfo = () => {
    if (this.state.routeId) {
      this.setState({ inProgress: true });
      // showFlashMessage('info', I18n.t('messages.preplan_process'));
      message.loading({ content: I18n.t('messages.preplan_process'), key: 'preplan', duration: 10 });
      fetchOptimizedStops(this.state.routeId)
        .then((result) => {
          if (result.success) {
            this.setState({ inProgress: false });
            const error_message = result.optimize_route.optimizer_readable_error_message;
            const unprocessedOrders = result.optimize_route.unprocessed_orders || [];
            if (result.optimize_route.optimized === false) {
              // showFlashMessage('error', error_message);
              message.error({ content: error_message, key: 'preplan', duration: 10 });
            } else {
              message.success({ content: I18n.t('messages.preplan_success'), key: 'preplan', duration: 10 });
              // showFlashMessage('success', I18n.t('messages.preplan_success'));
            }
            this.setState({
              is_optimized: result.optimize_route.optimized,
              routeStatus: result.optimize_route.nav_route_status,
              routeName: result.optimize_route.nav_route_name,
              optimize_error: error_message,
              inProgress: false,
            }, () => {
              if (result.optimize_route.optimized !== false) {
                this.props.closeWindow(this.state.routeId, this.state.taskType, unprocessedOrders);
              } else {

              }
            });
          } else {
            // showFlashMessage('error', result.optimize_route.optimizer_readable_error_message);
            this.setState({ inProgress: false });
            message.error({ content: result.errors[0], key: 'preplan', duration: 10 });
          }
        });
    }
  };

  render() {
    const { driversInfo } = this.state;
    let assignString = '';
    if (this.state.taskType === 'move') {
      assignString = I18n.t('preplan.move');
    } else {
      assignString = I18n.t(`preplan.${driversInfo.primary.driverId ? 'allocate' : 'allocate'}`);
    }
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000} >
          <Row>
            <Col xs={24}>
              <Row>
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  <FormItem label={I18n.t('general.Driver')}> {/* `Primary ${I18n.t('general.Driver')}` */}
                    <Select
                      style={{ width: '100%' }}
                      className="routes-input"
                      onChange={e => this.handleDriverChange('selectedDriverId', e, 'primary')}
                      defaultValue={`${assignString}`}
                      value={this.state.driversInfo.primary.selectedDriverId}
                      filterOption={(input, option, index) => option.props.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                    >
                      {this.state.users.map(province =>
                        (this.props.showExistDrivers === true || (province.id !== driversInfo.primary.driverId && province.id !== driversInfo.secondary.driverId)) &&
                        <Option key={province.id}>
                          <div>
                            {
                              province.image && (province.image.url) ?
                                <Avatar src={`${province.image.url}`} size={30} /> : <Avatar src="./../icons/user_100*100.png" alt="DriverProfile" size={30} />
                            }

                            &nbsp;
                            { province.employee_code ? province.employee_code :
                              (province.full_name ? province.full_name : `${province.first_name} ${province.last_name}`)}&nbsp;&nbsp;
                            <span className="floatRight"><Badge count={province.stops.length} /></span>
                          </div>
                        </Option>)}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Col>
            { false && this.state.taskType === 'move' &&
            <Col xs={24}>
              <FormItem label={I18n.t('general.reason')}>
                <TextArea
                  rows={4}
                  placeholder={I18n.t('general.reason')}
                  className='sop-form-textArea'
                  value={this.state.reason}
                  onChange={e => this.handleOnChange('reason', e.target.value)}
                />
              </FormItem>
            </Col>
            }
            { false &&
            <Col xs={24}>
              <Row>
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  <FormItem label={`Secondary ${I18n.t('general.Driver')}`} style={{ marginTop: 10 }}>
                    <Select
                      style={{ width: '100%' }}
                      className="routes-input"
                      onChange={e => this.handleDriverChange('selectedDriverId', e, 'secondary')}
                      defaultValue={`${assignString}`}
                      value={this.state.driversInfo.secondary.selectedDriverId}
                      filterOption={(input, option, index) => option.props.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                    >
                      {this.state.users.map(province =>
                        (this.props.showExistDrivers === true || (province.id !== driversInfo.primary.driverId && province.id !== driversInfo.secondary.driverId)) &&
                        <Option key={province.id}>
                          <div>
                            {
                              province.image && (province.image.url) ?
                                <Avatar src={`${province.image.url}`} size={30} /> : <Avatar src="./../icons/user_100*100.png" alt="DriverProfile" size={30} />
                            }
                            {province.employee_code ? province.employee_code :
                              (province.full_name ? province.full_name : `${province.first_name} ${province.last_name}`)}&nbsp;&nbsp;
                            <span className="floatRight"><Badge count={province.stops.length} /></span>
                          </div>
                        </Option>)}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Col>
            }
            {this.props.showTruck !== false && <Col xs={24} style={{ paddingBottom: 10 }}>
              <FormItem label={I18n.t('truck.single')} style={{ marginTop: 10 }}>
                <Select
                  style={{ width: '100%' }}
                  className="routes-input"
                  onChange={e => this.handleTruckChange('selectedId', e)}
                  defaultValue=""
                  value={this.state.truckInfo.selectedId}
                  filterOption={(input, option, index) => option.props.children.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {this.state.trucks.map(province =>
                    (
                      province.id !== this.state.truckInfo.assignedId &&
                      <Option key={province.id}>
                        <div>
                          {
                            province.image && (province.image.url) ?
                              <Avatar src={`${province.image.url}`} size={30} /> : <Avatar src={truckImg} alt="Truck" size={30} />
                          }
                          &nbsp;{province.employee_code ? province.employee_code : province.name}&nbsp;&nbsp;
                          <span className="floatRight"><Badge count={province.stops.length} /></span>
                        </div>
                      </Option>
                    ))
                  }
                </Select>
              </FormItem>
            </Col>
            }


            {
              this.state.taskType === 'drivers' ?
                <Col xs={24}>
                  <Row>
                    <Col xs={24} className="alignCenter">
                      <Button size="small" className="assignDriver" onClick={() => this.assignDrivertoRoute()} loading={this.state.assignStatus} disabled={this.state.routeStatus === 'COMPLETED'}>{`${assignString}`}</Button>

                    </Col>
                  </Row>
                </Col>
                :
                <Fragment>
                  <Col xs={24}>
                    <Row>
                      <Col xs={24} className="alignCenter">
                        {
                          !this.state.optimize_error_status && ((_.isArray(this.state.optimize_error) && this.state.optimize_error.length > 0)) ?
                            <Button className="assignDriver" onClick={this.props.closeWindow} >{I18n.t('general.close')}</Button> :
                            <Button className="assignDriver" onClick={() => this.validateCapacity()} loading={this.state.assignStatus} disabled={this.state.routeStatus === 'COMPLETED'}>{`${assignString}`}</Button>
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    {!this.state.optimize_error_status && ((_.isArray(this.state.optimize_error) && this.state.optimize_error.length > 0)) &&
                    <Row className="marginZero">
                      <Col className="marginTop10" xs={24} style={{ padding: '0px 10px 10px 10px' }}>
                        <Alert
                          message="Error"
                          description={
                            <div>
                              {this.state.optimize_error.map((error, index) => <Row><Col key={`erroe${index + 1}`}>{error}</Col></Row>)}
                            </div>
                          }
                          type="error"
                          closable
                        />
                      </Col>
                    </Row>
                    }
                  </Col>

                </Fragment>
            }
          </Row>
        </Spin>
      </div>
    );
  }
}
Assignment.propTypes = {
  truck: PropTypes.string,
  primaryDriver: PropTypes.string,
  secondaryDriver: PropTypes.string,
  routeId: PropTypes.string.isRequired,
  closeWindow: PropTypes.func.isRequired,
  showExistDrivers: PropTypes.bool,
  driver_stop_existed: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Assignment.defaultProps = {
  truck: '',
  primaryDriver: '',
  secondaryDriver: '',
  showExistDrivers: false,
  driver_stop_existed: false,
};
export default withRouter(Assignment);
