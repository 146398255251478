import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popconfirm, Button } from "../../common/UIComponents";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import { QuotesApis } from "../../api/QuotesApi";
import { alertMessage} from "../../common/Common";
import I18n from "../../common/I18n";

const QuoteBulkOperations = ({
  selectedInfo,
  handleClear,
  filter,
  setIsListLoading,
  refreshQuotes,
  multipleDeletion,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const changeStatus = async (status) => {
    try {
      setIsListLoading(true)
      const response = await QuotesApis.changeStatus({
        ids: selectedInfo.selectedKeys,
        status,
      })
      if (response.success) {
        const successMessage = response?.message
          ? response.message
          : I18n.t('messages.saved')
        if (response.errors && response.errors.length > 0) {
          renderAlertMessage(result.errors)
        } else {
          alertMessage(successMessage)
          refreshQuotes()
        }
      } else {
        alertMessage(response.errors, 'error', 10)
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setIsListLoading(false)
    }
  }

  const handleDelete = () => {
    multipleDeletion(selectedInfo.selectedKeys)
  }

  return (
    <Row className="bottom_fixed">
      <Col xs={24} style={{ padding: "10px 10px" }} className="alignCenter">
        <Button
          type="default"
          className="clearButtonStyle"
          onClick={handleClear}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        {checkServiceExistance("QD") &&
          ['PENDING'].includes(filter) && (
            <Popconfirm
              placement="rightTop"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" className="buttonStyle">
                Delete
              </Button>
            </Popconfirm>
          )}
        {checkServiceExistance("QOA") && filter === "PENDING" && (
          <Button
            type="primary"
            className="assignDriver"
            onClick={() => changeStatus("APPROVED")}
            disabled={!(selectedInfo.selectedKeys.length > 0)}
          >
            Approve
          </Button>
        )}
        {checkServiceExistance("QOR") && filter === "PENDING" && (
          <Button
            type="primary"
            className="assignDriver buttonMitesse"
            onClick={() => changeStatus("REJECTED")}
            disabled={!(selectedInfo.selectedKeys.length > 0)}
          >
            Reject
          </Button>
        )}
      </Col>
    </Row>
  );
};

QuoteBulkOperations.propTypes = {
    filter: PropTypes.string.isRequired,
    selectedInfo: PropTypes.shape.isRequired,
    handleClear: PropTypes.func.isRequired,
    refreshQuotes: PropTypes.func.isRequired,
    setIsListLoading: PropTypes.func.isRequired,
    multipleDeletion: PropTypes.func.isRequired,
};

export default QuoteBulkOperations;
