import { Popover, Tooltip } from "antd";
import React from "react";
import { ellipseText, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import ShipperConsigneeDetails from "../common/ShipperConsigneeDetails";
import ShipperLogDetails from "../common/ShipperLogDetails";
import moment from "moment";
import AppConfig from "../../config/AppConfig";
import Copyable from "../common/Copyable";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import { checkServiceExistance } from "../../helpers/common";
import { Link } from "../../common/UIComponents";
import DisplayTime from "../common/DisplayTime";
class CustomerLogList extends BaseList {
  constructor(props) {
    super(props);
    this.setColumns(props)
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  setColumns = (props) => {
    this.columns = [
      {
        key: "customer_order_number",
        title: I18n.t("customer_log_report.order_id"),
        dataIndex: "customer_order_number",
        render: (text, record) => (
          <div>
            <CustomerOrderDetails
              order_no={text}
              order={{
                id: record.order_id,
                customer_order_number: text,
              }}
              showEdit={false}
            />
          </div>
        ),
        width: "110px",
        align : "left",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },

      {
        key: "account_code",
        title: I18n.t("customer_log_report.account_id"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "shipper_name",
        title: I18n.t("customer_log_report.shipper_name"),
        dataIndex: "shipper_name",
        className: "noWrap",
        render: (data, record) => (
          
            <Popover
              title={I18n.t("customer_log_report.shipper_details")}
              content={
                <ShipperLogDetails
                  record={record}
                  key={`consigneeDetails${record.id}`}
                />
              }
            >
              {isEmpty(record.shipper_name)
                ? "N/A"
                : (record.shipper_name)
            }
            </Popover>
          
        ),
        
      },
      {
        key: "consignee_name",
        title: I18n.t("customer_log_report.consignee_name"),
        dataIndex: "consignee_name",
        render: (data, record) => (
          <div>
            <Popover
              title={I18n.t("general.customer_details")}
              content={
                <ShipperConsigneeDetails
                  record={record}
                  key={`consigneeDetails${record.id}`}
                />
              }
            >
              {isEmpty(record.consignee_name)
                ? "N/A"
                : <span>{ellipseText(record.consignee_name, 10)}</span>
                }
            </Popover>
          </div>
        ),
        width: "120px"
      },
      {
        key: "dim_weight",
        title: I18n.t("customer_log_report.dim_weight"),
        dataIndex: "dim_weight",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        align : "center",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "effective_service_date",
        title: I18n.t("customer_log_report.service_date"),
        dataIndex: "effective_service_date",
        render: (data, record) => {
          return data ? (
            <span>
              <DisplayTime
                dateTimeString={data}
                displayWithDate={true}
                isUTC={true}
              />
            </span>
          ) : (
            ""
          );
        },
        width: "155px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "hawb",
        title: I18n.t("customer_log_report.hawb"),
        dataIndex: "hawb",
        render: (data, record) => (
          <OrderFieldDisplay
            data={{...record, id: record.order_id, customer_order_number: record.customer_order_number}}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
        width: "120px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "mawb",
        title: I18n.t("customer_log_report.mawb"),
        dataIndex: "mawb",
        render: (data, record) => (
          <OrderFieldDisplay
            data={{...record, id: record.order_id, customer_order_number: record.customer_order_number}}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
        width: "120px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "type_of_order",
        title: I18n.t("customer_log_report.move_type"),
        dataIndex: "type_of_order",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "pieces",
        title: I18n.t("customer_log_report.pieces"),
        dataIndex: "pieces",
        render: (data, record) => <span>{data}</span>,
        width: "120px",
        align : "center",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "weight",
        title: I18n.t("customer_log_report.weight"),
        dataIndex: "weight",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        align : "center",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "reference_1",
        title: I18n.t("customer_log_report.reference_1"),
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1" showPopupContainer={true}/>
        ),
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      // {
      //   key: "status",
      //   title: I18n.t("customer_log_report.status"),
      //   dataIndex: "pod",
      //   render: (data, record) => <span>{data}</span>,
      //   width: "90px",
      //   // sorter: true,
      //   // sortDirections: [ "ascend", "descend", "ascend" ],
      // },
      {
        key: "signed_at",
        title: I18n.t("customer_log_report.pod_date_time"),
        dataIndex: "signed_at",
        render: (data, record) => {
          return data ? (
            <span>
              <DisplayTime
                dateTimeString={data}
                displayWithDate={true}
                isUTC={true}
              />
            </span>
          ) : (
            ""
          );
        },

        width: "140px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "signer_name",
        title: I18n.t("customer_log_report.pod_signer"),
        dataIndex: "signer_name",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "invoice",
        title: I18n.t("customer_log_report.invoice"),
        dataIndex: "invoice",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "order_charge",
        title: I18n.t("customer_log_report.order_charge"),
        dataIndex: "order_charge",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "delivery_date",
        title: I18n.t("customer_log_report.delivery_date"),
        dataIndex: "delivery_date",
        render: (data, record) => {
          return data ? (
            <span>
              <DisplayTime
                dateTimeString={data}
                displayWithDate={true}
                isUTC={true}
              />
            </span>
          ) : (
            ""
          );
        },
        width: "155px",
      },
      {
        key: "warehouse_code",
        title: I18n.t("customer_log_report.terminal"),
        dataIndex: "warehouse_code",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "cus_name",
        title: I18n.t("customer_log_report.cus_name"),
        dataIndex: "consignee_name",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "company",
        title: <b>{I18n.t("customer_log_report.company")}</b>,
        dataIndex: "company_name",
        render: (data, record) => <span>{data}</span>,
        width: "90px"
      },
      {
        key: "completed",
        title: I18n.t("customer_log_report.completed"),
        dataIndex: "completed",
        render: (data, record) => <span>{data}</span>,
        width: "90px"
      },
      {
        key: "move_count",
        title: I18n.t("customer_log_report.move_count"),
        dataIndex: "move_count",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
        // sorter: true,
        // sortDirections: [ "ascend", "descend", "ascend" ],
      },
      {
        key: "level_of_service",
        title: I18n.t("customer_log_report.service_level"),
        dataIndex: "level_of_service",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "cus_id",
        title: I18n.t("customer_log_report.cus_id"),
        dataIndex: "account_code",
        render: (data, record) => <span>{data}</span>,
        width: "90px"
      },
      {
        key: "dispatch_station",
        title: I18n.t("customer_log_report.dispatch_station"),
        dataIndex: "warehouse_code",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "location",
        title: <b>{I18n.t("customer_log_report.location")}</b>,
        dataIndex: "location",
        render: (data, record) => <span>{data}</span>,
        width: "90px"
      },
      {
        key: "warehouse_location",
        title: I18n.t("customer_log_report.warehouse_location"),
        dataIndex: "warehouse_location",
        render: (data, record) => <span>{data}</span>,
        width: "120px"
      },
      {
        key: "special",
        title: I18n.t("customer_log_report.special"),
        dataIndex: "special",
        render: (data, record) => <span>{data}</span>,
        width : "90px"
      },
      {
        key: "appt",
        title: I18n.t("customer_log_report.appt"),
        dataIndex: "appt",
        render: (data, record) => <span>{data}</span>,
        width : "90px"
      },
    ];
  }
}

export default CustomerLogList;
