import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  FormItem,
  Icon,
  Input,
  Row,
  Select,
} from "../../common/UIComponents";
import {alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import {
  saveFuelSurchargeMaster,
  saveFuelSurchargeAccountConfig,
} from "../../api/FuelSurcharges";
import { checkServiceExistance } from "../../helpers/common";
import SwitchComponent from "../../common/switchComponent";

class FuelSurchargeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: this.props.config || {},
      isNew: this.props.isNew,
      organizationId: this.props.organizationId,
      inProgress: false,
      errors: [],
    };
  }

  validateTheFileds = (e) => {
    const data = this.state.config;
    if (
      (data._surcharge_type === "LINE_HAUL" ||
        data._surcharge_type === "MILES") &&
      isEmpty(data.region)
    ) {
      this.setState({
        errors: [I18n.t("errors.fill_all_required_fields")],
      });
    } else {
      this.handleSave(e);
    }
  };

  handleOnChange = (element, value) => {
    const data = { ...this.state.config };

    if (
      (element === "_surcharge_type" && value === "FLAT") ||
      value === "PERCENTAGE"
    ) {
      data.surcharge_value = null;
    }

    if (element === "_frequency" && value !== "BIMONTHLY") {
      data.frequency_value = value === "WEEKLY" ? "MONDAY" : "01";
    }
    this.setState({
      config: Object.assign({}, data, { [element]: value }),
    });
  };

  handleSave = () => {
    this.setState({ inProgress: true });
    const data = this.state.config;
    data.organization_id = this.state.organizationId;
    if (this.props.accountId) {
      data.account_id = this.props.accountId;
      data.account_code = this.props.accountCode;
    }
    if (
      data._surcharge_type === "FLAT" ||
      data._surcharge_type === "PERCENTAGE"
    ) {
      // delete data._frequency;

      data._frequency = null;
      delete data.region;
      delete data.frequency_value;
    }
    if (data.account_id) {
      saveFuelSurchargeAccountConfig(data).then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t("messages.saved"));
        } else {
          this.setState({ errors: result.errors, inProgress: false });
          this.setState({ inProgress: false });
        }
      });
    } else {
      saveFuelSurchargeMaster(this.state.isNew, data).then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t("messages.saved"));
        } else {
          this.setState({ errors: result.errors, inProgress: false });
          this.setState({ inProgress: false });
        }
      });
    }
  };

  computeFrequencyOptions = () => {
    if (this.state.config._frequency === "WEEKLY") {
      return [
        { key: "MONDAY", value: "MONDAY" },
        { key: "TUESDAY", value: "TUESDAY" },
        { key: "WEDNESDAY", value: "WEDNESDAY" },
        { key: "THURSDAY", value: "THURSDAY" },
        { key: "FRIDAY", value: "FRIDAY" },
        { key: "SATURDAY", value: "SATURDAY" },
        { key: "SUNDAY", value: "SUNDAY" },
      ];
    } else {
      return [
        { key: "01", value: "01" },
        { key: "02", value: "02" },
        { key: "03", value: "03" },
        { key: "04", value: "04" },
        { key: "05", value: "05" },
        { key: "06", value: "06" },
        { key: "07", value: "07" },
        { key: "08", value: "08" },
        { key: "09", value: "09" },
        { key: "10", value: "10" },
        { key: "11", value: "11" },
        { key: "12", value: "12" },
        { key: "13", value: "13" },
        { key: "14", value: "14" },
        { key: "15", value: "15" },
        { key: "16", value: "16" },
        { key: "17", value: "17" },
        { key: "18", value: "18" },
        { key: "19", value: "19" },
        { key: "20", value: "20" },
        { key: "21", value: "21" },
        { key: "22", value: "22" },
        { key: "23", value: "23" },
        { key: "24", value: "24" },
        { key: "25", value: "25" },
        { key: "26", value: "26" },
        { key: "27", value: "27" },
        { key: "28", value: "28" },
        { key: "29", value: "29" },
        { key: "30", value: "30" },
        { key: "31", value: "31" },
      ];
    }
  };

  render() {
    const { config } = this.state;
    const formatter =
      config._surcharge_type === "FLAT" ? "dollar" : "percentage";
    const operationCode = this.props.accountId ? "FSCMSAC" : "FSCMC";
    return (
      <div>
        <Row gutter={8}>
          <Col xs={8}>
            <FormItem label={I18n.t("general.name")} require>
              <Input
                value={config.name}
                onChange={(e) => this.handleOnChange("name", e.target.value)}
              />
            </FormItem>
          </Col>
          <Col xs={8}>
            <FormItem label={I18n.t("fuelSurcharges.surcharge_type")} require>
              <Select
                value={config._surcharge_type}
                showSearch
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.handleOnChange("_surcharge_type", e)}
              >
                {Object.keys(AppConfig.fuelSurchargeTypes).map((key) => (
                  <Select.Option key={key} value={key}>
                    {AppConfig.fuelSurchargeTypes[key]}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col xs={8}>
            {(config._surcharge_type === "FLAT" ||
              config._surcharge_type === "PERCENTAGE") && (
              <FormItem
                label={I18n.t("fuelSurcharges.surcharge_value")}
                require
              >
                <Input
                  type="number"
                  value={config.surcharge_value}
                  onChange={(e) =>
                    this.handleOnChange("surcharge_value", e.target.value)
                  }
                  addonBefore={<Icon type={formatter} />}
                />
              </FormItem>
            )}
          </Col>
        </Row>
        <Row gutter={8}>
          {/* { (config._surcharge_type !== 'FLAT' || config._surcharge_type !== 'PERCENTAGE')  &&  */}

          <Col xs={8}>
            {(config._surcharge_type === "LINE_HAUL" ||
              config._surcharge_type === "MILES") && (
              <FormItem label={I18n.t("fuelSurcharges.region")} require>
                <Select
                  value={config.region}
                  showSearch
                  onChange={(e) => this.handleOnChange("region", e)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {this.props.regions.map((data) => (
                    <Select.Option
                      key={data.region_name}
                      value={data.region_name}
                    >
                      {data.region_name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            )}
          </Col>
          <Col xs={8}>
            {(config._surcharge_type === "LINE_HAUL" ||
              config._surcharge_type === "MILES") && (
              <FormItem label={I18n.t("fuelSurcharges.frequency")} require>
                <Select
                  value={config._frequency}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleOnChange("_frequency", e)}
                >
                  {Object.keys(AppConfig.fuelSurchargeFrequencies).map(
                    (key) => (
                      <Select.Option key={key} value={key}>
                        {AppConfig.fuelSurchargeFrequencies[key]}
                      </Select.Option>
                    )
                  )}
                </Select>
              </FormItem>
            )}
          </Col>

          {config._frequency !== "BIMONTHLY" && (
            <Col xs={8}>
              {(config._surcharge_type === "LINE_HAUL" ||
                config._surcharge_type === "MILES") && (
                <FormItem
                  label={I18n.t(
                    config._frequency === "WEEKLY"
                      ? "fuelSurcharges.select_day"
                      : "fuelSurcharges.select_date"
                  )}
                  require
                >
                  <Select
                    onChange={(e) => this.handleOnChange("frequency_value", e)}
                    value={config.frequency_value}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                    showSearch
                  >
                    {this.computeFrequencyOptions().map(({ key, value }) => (
                      <Select.Option key={key} value={value}>
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            </Col>
          )}
          {/* </span> */}
          {/* } */}
        </Row>
        <Row>
          {!this.props.accountId && (
            <Col xs={6}>
              <FormItem label={I18n.t("fuelSurcharges.default")} require>
                <SwitchComponent
                  className="marginTop5 marginLeft10"
                  handleChange={(value) =>
                    this.handleOnChange("is_default", Boolean(value))
                  }
                  isChecked={config.is_default}
                  checkedValue={true}
                  unCheckedValue={false}
                />
              </FormItem>
            </Col>
          )}
        </Row>
        <Row gutter={8}>
          {checkServiceExistance(operationCode) &&
            FormButtons(
              false,
              this.validateTheFileds,
              this.props.onCancel,
              true,
              true,
              "default",
              I18n.t("general.save"),
              I18n.t("general.cancel")
            )}
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

export default FuelSurchargeForm;

FuelSurchargeForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  accountCode: PropTypes.string,
  regions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
