import React, { Component, Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import FormErrors from "../../components/common/FormErrors";
import { Col, Row, Spin, Icon, Card, Popover } from "../../common/UIComponents";
import {
  doFormate,
  ellipseText,
  formatStopTime,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import AddressInfo from "../common/AddressInfo";

import AppConfig from "../../config/AppConfig";
import { getExecutionTime } from "../../helpers/routes";
import { formatByTimeConfig, metersToOtherMeasurements, secondsToHms } from "../../helpers/common";
import {
  checkElementValueExists,
  getOrderTypeLabel,
} from "../../helpers/orders";
import TypeOfOrder from "../orders/TypeOfOrder";
import MenifestOrdersList from "./MenifestOrdersList";
import MenifestOrderItemsList from "./MenifestOrderItemsList";

class MenifestDownloadContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      stops: this.props.stops || [],
      errors: [],
      routeId: this.props.currentRoute.id ? this.props.currentRoute.id : "",
      routeStatus: "",
      currentRoute: this.props.currentRoute || {},
      optimize_error_status: true,
      total_time: 0,
      total_distance: 0,
      driversInfo: {
        primary: {
          driverId: "",
          allotedDriver: {},
          selectedDriverId: "",
        },
        secondary: {
          driverId: "",
          allotedDriver: {},
          selectedDriverId: "",
        },
      },
      organizationSettings: {},
      isMilitaryTime: false,
      orderIds: [],
      activeOrders: [],
      stopNonLhOrders: [],
      stopLhOrders: [],
    };
  }

  componentDidMount() {
    this.getRouteInfo();
    this.getAllStopsOrders();
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
    });
    setTimeout(() => {
      this.sortOrders();
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
      });
    }
    if (prevProps.includeItems !== this.props.includeItems) {
      if (this.props.includeItems === true) {
        const newActiveOrders = this.state.stopNonLhOrders?.length
          ? [
              ...this.state.activeOrders,
              ...this.state.stopNonLhOrders.map((order) => order.id),
            ]
          : this.state.activeOrders;

        this.setState({
          activeOrders: newActiveOrders,
        });
      } else {
        const lhOrderIds = this.state.stopLhOrders.map((order) => order.id);
        const newActiveOrders = this.state?.activeOrders?.length
          ? this.state.activeOrders.filter((orderIds) =>
              lhOrderIds.includes(orderIds)
            )
          : [];
        this.setState({
          activeOrders: newActiveOrders,
        });
      }
    }
  }

  getAllStopsOrders = () => {
    const nonLhOrders =
      this.state?.currentRoute?.customer_orders &&
      this.state.currentRoute?.customer_orders?.length
        ? this.state.currentRoute.customer_orders.filter(
            (order) => !["LH"].includes(order.type_of_order)
          )
        : [];
    const lhOrders = this.state?.currentRoute
      ? this.state.currentRoute?.customer_orders?.length
        ? this.state.currentRoute.customer_orders.filter((order) =>
            ["LH"].includes(order.type_of_order)
          )
        : this.state.currentRoute?.linehauls?.length
        ? this.state.currentRoute.linehauls
        : []
      : [];

    this.setState({
      stopNonLhOrders: nonLhOrders,
      stopLhOrders: lhOrders,
    });
  };

  sortOrders = () => {
    let ord_Ids = [];
    this.state.stops.map((stop) => {
      if (stop.location_type !== "WH") {
        ord_Ids.push(stop.customer_order_ids);
      }
    });
    this.setState({ orderIds: ord_Ids.flat() });
  };

  getStatusText = (location_type) => {
    if (location_type === "D") {
      return "Deliveries";
    } else if (["R", "P"].includes(location_type)) {
      return "Pickups";
    } else {
      return "Pickup & Deliveries";
    }
  };

  getRouteInfo = (callRearrange) => {
    if (this.state.routeId) {
      const { currentRoute } = this.state;
      const route_stops = [];
      currentRoute.stops.forEach((route_stop) => {
        route_stops.push(route_stop.stop_details);
      });
      const sortedRoutes = _.sortBy(route_stops, [
        function (o) {
          return o.stop_order_sequence;
        },
      ]);
      if (sortedRoutes.length > 0) {
        sortedRoutes[sortedRoutes.length - 1].completion_class =
          "inCompletedRoute";
      }
      const { interconnections } = currentRoute;
      sortedRoutes.forEach((stop, index) => {
        if (index > 0) {
          if (stop.status === "COMPLETED") {
            sortedRoutes[index - 1].completion_class = "completedRoute";
          } else {
            sortedRoutes[index - 1].completion_class = "inCompletedRoute";
          }
        }
        sortedRoutes[index].stop_number = index + 1;
        const contact_details = stop.contact_details
          ? stop.contact_details
          : {};
        sortedRoutes[index].formatted_stop_name = this.formatStopName(
          contact_details,
          stop.location,
          index + 1
        );
        const interconnection_index = _.findIndex(interconnections, [
          "fromWaypoint",
          stop.id,
        ]);
        if (interconnection_index >= 0) {
          sortedRoutes[index].interconnection_status = true;
          sortedRoutes[index].distance = interconnections[interconnection_index]
            .distance
            ? metersToOtherMeasurements(
                interconnections[interconnection_index].distance,
                "miles"
              )
            : "NA";
          sortedRoutes[index].time = interconnections[interconnection_index]
            .time
            ? secondsToHms(
                interconnections[interconnection_index].time,
                true,
                true,
                false
              )
            : 0;
          sortedRoutes[index].waiting = interconnections[interconnection_index]
            .waiting
            ? secondsToHms(
                interconnections[interconnection_index].waiting,
                true,
                true,
                false
              )
            : "-";
          sortedRoutes[index].rest = interconnections[interconnection_index]
            .rest
            ? secondsToHms(
                interconnections[interconnection_index].rest,
                true,
                true,
                false
              )
            : "-";
        } else {
          sortedRoutes[index].distance = "NA";
          sortedRoutes[index].time = "NA";
          sortedRoutes[index].waiting = "NA";
          sortedRoutes[index].rest = "NA";
          sortedRoutes[index].interconnection_status = false;
        }
      });
      let totalExecTime = getExecutionTime({ stops: [...sortedRoutes] });
      totalExecTime = totalExecTime ? `${totalExecTime} Approx.` : "NA";
      this.setState({
        inProgress: false,
        stops: sortedRoutes,
        routeStatus: currentRoute.nav_route_status,
        total_time: currentRoute.total_time ? currentRoute.total_time : 0,
        total_distance: currentRoute.total_distance
          ? currentRoute.total_distance
          : 0,
        driversInfo: {
          primary: {
            driverId: currentRoute.primary_driver
              ? currentRoute.primary_driver.id
              : "",
            allotedDriver: currentRoute.primary_driver
              ? currentRoute.primary_driver
              : {},
            selectedDriverId: "",
          },
          secondary: {
            driverId: currentRoute.secondary_driver
              ? currentRoute.secondary_driver.id
              : "",
            allotedDriver: currentRoute.secondary_driver
              ? currentRoute.secondary_driver
              : {},
            selectedDriverId: "",
          },
        },
        totalExecTime,
      });
    }
  };

  formatStopName = (contact_details, location, stop_number) => {
    const name =
      contact_details && contact_details.contact_name
        ? contact_details.contact_name
        : `Stop ${stop_number}`;
    let city_state =
      location && location.l_address
        ? `${location.l_address.city}, ${location.l_address.state}`
        : "";
    if (city_state) {
      city_state = city_state.replace(/(^,)|(,$)/g, "");
      city_state = ` (${city_state})`;
    }
    return name + city_state;
  };

  renderMeasurements = (order) => {
    return (
      <Row>
        <Col>
          {I18n.t("order.qty")}:{" "}
          {order.items_count
            ? order.items_count
            : order.quantity
            ? order.quantity
            : order.pieces
            ? order.pieces
            : 0}
          <span style={{ margin: "2px 5px" }}>/</span>PLTS:{" "}
          {!isEmpty(order.pallets) ? order.pallets : "NA"}{" "}
          <span style={{ margin: "2px 5px" }}>/</span>
          {I18n.t("order.weight_short_form")}:{" "}
          {!isEmpty(order.weight)
            ? `${order.weight} ${AppConfig.weight_unit}`
            : "NA"}
        </Col>
      </Row>
    );
  };

  renderShipperNotes = (order) => {
    return (
      <Row>
        <Col>
        {!isEmpty(order.notes) && (
            <Fragment>
              {I18n.t("general.notes")} :{" "}
              {!isEmpty(order.notes) ? (
                <Popover
                  title="Notes"
                  content={<div>{order.notes}</div>}
                  placement="top"
                  overlayStyle={{ width: 350 }}
                >
                  <span>
                    {ellipseText(order.notes, 50)}
                  </span>
                </Popover>
              ) : (
                "NA"
              )}
              <br />
            </Fragment>
          )}
          {!isEmpty(order.instructions_from_account_1) && (
            <Fragment>
              {I18n.t("general.ans1")} :{" "}
              {!isEmpty(order.instructions_from_account_1) ? (
                <Popover
                  title="INS 1"
                  content={<div>{order.instructions_from_account_1}</div>}
                  placement="top"
                  overlayStyle={{ width: 350 }}
                >
                  <span>
                    {ellipseText(order.instructions_from_account_1, 50)}
                  </span>
                </Popover>
              ) : (
                "NA"
              )}
              <br />
            </Fragment>
          )}

          {!isEmpty(order.instructions_from_account_2) && (
            <Fragment>
              {I18n.t("general.ans2")} :{" "}
              {!isEmpty(order.instructions_from_account_2) ? (
                <Popover
                  title="INS 2"
                  content={<div>{order.instructions_from_account_2}</div>}
                  placement="topLeft"
                  overlayStyle={{ width: 350 }}
                >
                  <span>
                    {ellipseText(order.instructions_from_account_2, 50)}
                  </span>
                </Popover>
              ) : (
                "NA"
              )}
              <br />
            </Fragment>
          )}
          {!isEmpty(order.instructions_from_account_3) && (
            <Fragment>
              {I18n.t("general.ans3")} :{" "}
              {!isEmpty(order.instructions_from_account_3) ? (
                <Popover
                  title="INS 3"
                  content={<div>{order.instructions_from_account_3}</div>}
                  placement="topLeft"
                  overlayStyle={{ width: 350 }}
                >
                  <span>
                    {ellipseText(order.instructions_from_account_3, 50)}
                  </span>
                </Popover>
              ) : (
                "NA"
              )}
              <br />
            </Fragment>
          )}
        </Col>
      </Row>
    );
  };

  handleExpand = (expanded, record) => {
    let activeOrders = [...this.state.activeOrders];

    if (expanded) {
      activeOrders.push(record.customer_order_id); // Push the expanded record's ID to activeOrders
    } else {
      activeOrders = activeOrders.filter(
        (id) => id !== record.customer_order_id
      ); // Remove the collapsed record's ID from activeOrders
    }

    this.setState({ activeOrders }); // Update the state with the modified activeOrders
  };

  render() {
    const { currentRoute, totalExecTime } = this.state;
    let sec_hrs = secondsToHms(this.state.total_time, true, false, false);
    sec_hrs = sec_hrs ? `${sec_hrs}(Approx.)` : "NA";
    const time_distance = this.state.total_distance
      ? `${parseFloat(this.state.total_distance / 1609).toFixed(2)} Miles`
      : "NA";
    const { isMilitaryTime } = this.state;
    const routeType = currentRoute.route_type;

    const getConvertedOrderType = (orderType) => {
      // if (orderType === "TD" || orderType === "MD" || orderType === "TR" || orderType === "D")
      // if(orderType.includes(["TD","MD","TR","D"]))
      if (["TD", "D"].includes(orderType)) {
        return "D";
      } else if (["TR", "R", "P"].includes(orderType)) {
        return "P";
      } else if (["T"].includes(orderType)) {
        return "T";
      } else if (["LH"].includes(orderType)) {
        return "LH";
      } else if (["RL"].includes(orderType)) {
        return "RL";
      } else if (["RC"].includes(orderType)) {
        return "RC";
      } else {
        return "";
      }
    };
    return (
      <Fragment>
        <div>
          <div className="content routes-tasks" style={{ textAlign: "center" }}>
            <Spin spinning={this.state.inProgress} delay={1000}>
              <div style={{ backgroundColor: "#FFF", padding: 5 }}>
                <div
                  style={{
                    width: "49.5%",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  <span className="textBold">
                    {I18n.t("general.routeName")}
                  </span>
                  :&nbsp;&nbsp;{currentRoute.nav_route_name}
                </div>
                <div style={{ width: "49.5", textAlign: "right" }}>
                  <span className="textBold">{I18n.t("general.date")}</span>
                  :&nbsp;&nbsp;
                  {currentRoute.r_scheduled_start_datetime
                    ? moment(currentRoute.r_scheduled_start_datetime).format(
                        "dddd Do, MMM YYYY"
                      )
                    : "NA"}
                </div>
                <div
                  style={{
                    width: "49.5",
                    float: "left",
                    textAlign: "center",
                  }}
                >
                  <span className="textBold">{I18n.t("general.Driver")}</span>
                  :&nbsp;&nbsp;
                  {this.state.driversInfo.primary &&
                  this.state.driversInfo.primary.allotedDriver
                    ? `${this.state.driversInfo.primary.allotedDriver.first_name} ${this.state.driversInfo.primary.allotedDriver.last_name}`
                    : "NA"}
                </div>

                <div style={{ width: "49.5", textAlign: "right" }}>
                  <span className="textBold">
                    {" "}
                    {I18n.t("general.travel_distance")}
                  </span>
                  :&nbsp;&nbsp;{time_distance}{" "}
                  {totalExecTime ? `(${totalExecTime})` : ""}
                </div>
                <hr style={{marginTop: 10}}/>
                <div style={{ height: "calc(100vh - 275px)", overflowY: "auto" }}>
                  {this.state.stops.length > 0 && (
                    <div className="padding5">
                      {this.state.stops.filter(stop => stop.status !== 'CANCELLED').map((stop, index) => {
                        const scheduled_start_date_time_with_tz =
                          stop.scheduled_start_date_time_with_tz
                            ? formatByTimeConfig(
                                moment.utc(
                                  stop.scheduled_start_date_time_with_tz
                                ),
                                isMilitaryTime,
                                "MMM Do HH:mm",
                                "MMM Do hh:mm A"
                              )
                            : "NA";
                        const scheduled_end_date_time_with_tz =
                          stop.scheduled_end_date_time_with_tz
                            ? formatByTimeConfig(
                                moment.utc(
                                  stop.scheduled_end_date_time_with_tz
                                ),
                                isMilitaryTime,
                                "MMM Do HH:mm",
                                "MMM Do hh:mm A"
                              )
                            : "NA";
                        const estimated_arrival_date_time_with_tz =
                          stop.estimated_arrival_date_time_with_tz
                            ? formatByTimeConfig(
                                moment.utc(
                                  stop.estimated_arrival_date_time_with_tz
                                ),
                                isMilitaryTime,
                                "HH:mm",
                                "hh:mm A"
                              )
                            : "NA";
                        const estimated_departure_date_time_with_tz =
                          stop.estimated_departure_date_time_with_tz
                            ? formatByTimeConfig(
                                moment.utc(
                                  stop.estimated_departure_date_time_with_tz
                                ),
                                isMilitaryTime,
                                "HH:mm",
                                "hh:mm A"
                              )
                            : "NA";

                        const service_duration = stop.service_duration
                          ? secondsToHms(
                              parseInt(stop.service_duration),
                              true,
                              true,
                              false
                            )
                          : 0;
                        const isShowScheduled =
                          scheduled_start_date_time_with_tz !== "NA" ||
                          scheduled_end_date_time_with_tz !== "NA";
                        const isShowEstimated =
                          estimated_arrival_date_time_with_tz !== "NA" ||
                          estimated_departure_date_time_with_tz !== "NA";
                        const timeZone = stop.stop_tz_short_name;
                        const contact_details = stop.contact_details
                          ? stop.contact_details
                          : {};
                        const customerPhoneOne = doFormate(
                          contact_details.contact_phone_number_1
                        );
                        const customerPhoneTwo = doFormate(
                          contact_details.contact_phone_number_2
                        );
                        const stopOrderIds =
                          stop.stop_order_sequence === 0
                            ? this.state.orderIds?.filter(data => !stop.deleted_order_ids?.includes(data))
                            : stop.customer_order_ids?.filter(data => !stop.deleted_order_ids?.includes(data)) || [];
                        const stopRecoveries = stop.recovery_ids || [];
                        const stopReleases = stop.release_ids || [];
                        const stopLinehaulIds = stop.linehaul_ids || [];
                        const stopType = stop.location_type;
                        const stopLocationIds = stop?.location_ids
                          ? stop.location_ids
                          : [];
                        let ordersList = [];

                        if (["RC", "WH"].includes(stopType)) {
                          stopRecoveries.map((id) => {
                            const order = currentRoute.recoveries.find(
                              (rec) => id === rec.id
                            );
                            if (!isEmpty(order)) {
                              ordersList = [
                                ...ordersList,
                                {
                                  ...order,
                                  stop_type: "Recovery",
                                  process_type: "RC",
                                  operation_type: stopType === "WH" ? "D" : "P",
                                },
                              ];
                            }
                          });
                        }
                        if (["RL", "WH"].includes(stopType)) {
                          stopReleases.map((id) => {
                            const order = currentRoute.releases.find(
                              (rec) => id === rec.id
                            );
                            if (!isEmpty(order)) {
                              ordersList = [
                                ...ordersList,
                                {
                                  ...order,
                                  stop_type: "Release",
                                  process_type: "RL",
                                  operation_type: stopType === "WH" ? "P" : "D",
                                },
                              ];
                            }
                          });
                        }
                        if (["LH", "WH"].includes(stopType)) {
                          stopLinehaulIds.map((id) => {
                            const order = currentRoute.linehauls.find(
                              (rec) => id === rec.id
                            );
                            if (!isEmpty(order)) {
                              const orderLocations = order.order_locations
                                ? order.order_locations
                                : [];
                              const orderLocationIds = orderLocations.map(
                                (loc) => loc.id
                              );
                              const stopOrderLoc = orderLocationIds.filter(
                                (loc) => stopLocationIds.includes(loc)
                              );
                              let currentLocation = {};
                              if (stopOrderLoc?.length) {
                                const loc = _.find(orderLocations, {
                                  id: stopOrderLoc[0],
                                });
                                currentLocation = loc ? loc : {};
                              }
                              ordersList = [
                                ...ordersList,
                                {
                                  ...order,
                                  stop_type: "Linehaul",
                                  process_type: "LH",
                                  operation_type: stopType === "WH" ? "P" : "D",
                                  company_name: currentLocation.company_name,
                                },
                              ];
                            }
                          });
                        }
                        if (["CS", "WH"].includes(stopType)) {
                          stopOrderIds.map((id) => {
                            const order = currentRoute.customer_orders.find(
                              (order) => id === order.customer_order_id
                            );
                            if (!isEmpty(order)) {
                              if (
                                !["TR", "R", "P"].includes(
                                  order.type_of_order
                                ) ||
                                stop.stop_order_sequence !== 0
                              ) {
                                const orderLocations = order.order_locations
                                  ? order.order_locations
                                  : [];
                                const orderLocationIds = orderLocations.map(
                                  (loc) => loc.id
                                );
                                const stopOrderLoc = orderLocationIds.filter(
                                  (loc) => stopLocationIds.includes(loc)
                                );
                                let currentLocation = {};
                                if (stopOrderLoc?.length) {
                                  const loc = _.find(orderLocations, {
                                    id: stopOrderLoc[0],
                                  });
                                  currentLocation = loc ? loc : {};
                                }
                                const op_type = getConvertedOrderType(
                                  order.type_of_order
                                );
                                ordersList = [
                                  ...ordersList,
                                  {
                                    ...order,
                                    stop_type: "Pickup / Delivery",
                                    process_type: order.type_of_order,
                                    operation_type:
                                      stopType === "WH"
                                        ? op_type === "D"
                                          ? "P"
                                          : "D"
                                        : op_type,
                                    company_name: currentLocation.company_name,
                                  },
                                ];
                              }
                            }
                          });
                        }
                        ordersList = _.compact(ordersList);
                        const operationTypes = _.uniq(
                          ordersList.map((order) => order.operation_type)
                        );
                        const isMultiStop = operationTypes.length > 1;
                        // const isMultiStop = stopType === 'WH' && (_.uniq(ordersList.map(order => order.stop_type))).length > 1;
                        const hasHawb = checkElementValueExists(
                          ordersList,
                          "hawb"
                        );
                        const hasMawb = checkElementValueExists(
                          ordersList,
                          "mawb"
                        );
                        const hillLogsiticsCode = "HILL";
                        const stopOrderTypes =
                          stopType === "CS"
                            ? _.uniq(
                                ordersList.map((order) => order.type_of_order)
                              )
                            : [];
                        const subItemStyle = {
                          paddingLeft: 5,
                          border: "1px solid white",
                          backgroundColor: "#cccccc",
                          paddingTop: "3px",
                          height: "35px",
                        };
                        const itemValStyle = { fontSize: 12 };
                        return (
                          <Fragment key={`timeline_fragment${stop.id}`}>
                            <table
                              width="100%"
                              style={{ marginTop: 30, fontSize: 12 }}
                            >
                              <tr>
                                <td width="46%" align="left" style={{ fontWeight: "bold" }}>
                                  {stop.stop_number}.&nbsp;
                                  {stop.stop_number === 1
                                    ? I18n.t("stop.starting_point")
                                    : stop.stop_number === this.state.stops.length
                                      ? I18n.t("stop.ending_point")
                                      : `${stop.formatted_stop_name} ${stop.company_name ? `- ${stop.company_name}` : ''}`}
                                </td>
                                <td
                                  width="20.67%"
                                  style={{ fontWeight: "bold" }}
                                ></td>
                                <td width="33.33%" align="right">
                                  <Icon type="phone" /> &nbsp;&nbsp;
                                  {`${customerPhoneOne} ${
                                    customerPhoneTwo !== "NA"
                                      ? `,${customerPhoneTwo}`
                                      : ""
                                  }`}
                                </td>
                              </tr>
                              <tr>
                                <td align="left">
                                  {stop.location && stop.location.l_address ? (
                                    <AddressInfo
                                      address={stop.location.l_address}
                                      showIcon
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td />
                                <td align="right">
                                  {(estimated_arrival_date_time_with_tz !==
                                    "NA" ||
                                    estimated_departure_date_time_with_tz !==
                                      "NA") && (
                                    <Fragment>
                                      {I18n.t("routes.estimated_time")} :{" "}
                                      {isShowEstimated ? (
                                        <Fragment>
                                          {formatStopTime(
                                            estimated_arrival_date_time_with_tz,
                                            estimated_departure_date_time_with_tz
                                          )}
                                        </Fragment>
                                      ) : (
                                        "NA"
                                      )}
                                    </Fragment>
                                  )}
                                </td>
                              </tr>
                            </table>
                            {!isEmpty(ordersList) && ordersList.length > 0 && (
                              <Col
                                sm={24}
                                xs={24}
                                md={24}
                                lg={24}
                                className="marginBottom15"
                              >
                                <Row>
                                  <MenifestOrdersList
                                    rowKey="customer_order_id"
                                    scroll={{ x: "max-content" }}
                                    displayConfiguration={
                                      this.props.displayConfiguration
                                    }
                                    expandedRowRender={(record) => {
                                      if (record.type_of_order === "LH") {
                                        const linehaulSubOrders =
                                          currentRoute?.sub_orders?.length > 0
                                            ? currentRoute.sub_orders.filter(
                                                (rec) =>
                                                  record.sub_order_ids.includes(
                                                    rec.id
                                                  )
                                              )
                                            : [];
                                        return (
                                          <Col>
                                            <Row
                                              sm={24}
                                              xs={24}
                                              md={24}
                                              lg={24}
                                              className="marginBottom15"
                                            >
                                              {
                                                <h3
                                                  style={{
                                                    color: "#3f6600",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  {stop.stop_number === 1
                                                    ? I18n.t(
                                                        "stop.linehaul_pickup_details"
                                                      )
                                                    : stop.stop_number ===
                                                      this.state.stops.length
                                                    ? I18n.t(
                                                        "stop.linehaul_delivery_details"
                                                      )
                                                    : stop.formatted_stop_name}{" "}
                                                  &nbsp; &nbsp;
                                                  {`( ${linehaulSubOrders.length} Sub Order(s) )`}
                                                </h3>
                                              }

                                              <MenifestOrdersList
                                                rowKey={"customer_order_id"}
                                                displayConfiguration={
                                                  this.props
                                                    .displayConfiguration
                                                }
                                                scroll={{
                                                  x: "max-content",
                                                  y: "calc(50vh - 280px)",
                                                }}
                                                data={linehaulSubOrders}
                                                pagination={{
                                                  position: "none",
                                                }}
                                                getConvertedOrderType={
                                                  getConvertedOrderType
                                                }
                                                renderMeasurements={
                                                  this.renderMeasurements
                                                }
                                                renderShipperNotes={
                                                  this.renderShipperNotes
                                                }
                                                size="small"
                                              />
                                            </Row>
                                          </Col>
                                        );
                                      } else {
                                        return (
                                          <Col>
                                            <Row
                                              sm={24}
                                              xs={24}
                                              md={24}
                                              lg={24}
                                              className="marginBottom15"
                                            >
                                              <h3
                                                style={{
                                                  color: "#3f6600",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {"Items"}&nbsp;&nbsp;
                                                {`( ${record.item_details.length} )`}
                                              </h3>
                                              <MenifestOrderItemsList
                                                rowKey={"serial_number"}
                                                displayConfiguration={
                                                  this.props
                                                    .displayConfiguration
                                                }
                                                data={record.item_details}
                                                pagination={{
                                                  position: "none",
                                                }}
                                                scroll={{
                                                  x: "max-content",
                                                  y: "calc(50vh - 280px)",
                                                }}
                                                size="small"
                                              />
                                            </Row>
                                          </Col>
                                        );
                                      }
                                    }}
                                    expandedRowKeys={[
                                      ...this.state.activeOrders,
                                    ]}
                                    onExpand={this.handleExpand}
                                    size="small"
                                    data={ordersList.reverse()}
                                    pagination={{ position: "none" }}
                                    getConvertedOrderType={
                                      getConvertedOrderType
                                    }
                                    renderMeasurements={this.renderMeasurements}
                                    renderShipperNotes={this.renderShipperNotes}
                                    stopType={stopType}
                                  />
                                </Row>
                              </Col>
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </Fragment>
    );
  }
}
MenifestDownloadContainer.propTypes = {
  stops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentRoute: PropTypes.shape().isRequired,
};

MenifestDownloadContainer.defaultProps = {
  match: {},
  routeId: "",
  reqFrom: "",
};
export default withRouter(MenifestDownloadContainer);
