import React, { Component, Fragment } from "react";
import _, { isEmpty } from "lodash";
import queryString from 'query-string';
import {
  Button,
  Col,
  Row,
  Spin,
  Upload,
  Icon,
  Checkbox,
  Alert,
  Select,
  Link,
  Drawer,
  Radio,
} from "../common/UIComponents";
import BaseModal from "../components/BaseModal";
import { alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import FormErrors from "../components/common/FormErrors";
import { fetchShortFormAccounts } from "../api/Account";
import userStore from "../stores/UserStore";
import { bulkUpload, uploadOrdersHistory } from "../api/OrdersApi";
import { fetchLocations } from "../api/LocationsApi";
import AppConfig from "../config/AppConfig";
import { getFileType } from "../common/Common";
import UploadedOrderList from "./UploadedOrderList";
import EditOrderForm from "../components/orders/EditOrderForm";
import { fetchOrderDetails } from "../api/OrdersApi";
import NestedTable from "../components/orders/UploadHistory";
import { dynamicFileDownload, renderAlertMessage } from "../helpers/common";
import { ApiUrl } from "../api/ApiUtils";
import { element } from "prop-types";
import UploadSheet from "./UploadSheet";
import UploadPdf from "../components/orders/UploadPdf";

const { Dragger } = Upload;

const fileTypes = [
  { key: "sheet", label: "CSV / EXCEl" },
  { key: "pdf", label: "PDF" },
];

class UploadOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      accounts: [],
      showFormModal: false,
      currentFileInfo: {
        csv_file: "",
        process_by_columns: true,
        contains_header: true,
        account_code: "",
        warehouse_code: "",
        auto_customer_order_no: false,
      },
      inProgress: false,
      isLoading: false,
      fileList: [],
      failed_orders: [],
      successful_orders: [],
      orgId: userStore.getStateValue("selectedOrg"),
      warehouses: [],
      showModal: false,
      showHistory: false,
      uploadOrders: [],
      historyData: [],
      selectedOrderId: "",
      currentOrder: {},
      editForm: false,
      fileType: (queryString.parse(this.props.location.search)).pdf_id ? "pdf" : "sheet",
      
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount() {
    // this.getAccounts();
    // this.getWarehouses();
    this.uploadOrdersHistories();
  }
  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState(
          {
            warehouses: result.locations.locations,
            inProgress: false,
          },
          () => {
            this.setDeafultWarehouse();
          }
        );
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  setDeafultWarehouse = () => {
    if (this.state.warehouses.length === 1) {
      this.setState({
        currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
          warehouse_code: this.state.warehouses[0].location_code,
        }),
      });
    }
  };

  setDeafultAccount = () => {
    if (this.state.accounts.length === 1) {
      this.setState({
        currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
          account_code: this.state.accounts[0].code,
        }),
      });
    }
  };

  getAccounts = (whId) => {
    fetchShortFormAccounts("", whId).then((result) => {
      if (result.success) {
        this.setState(
          {
            accounts: result.accounts,
          },
          () => {
            this.setDeafultAccount();
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  uploadOrdersHistories = (initial) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    uploadOrdersHistory(organization_id, page, perPage).then((result) => {
      if (result.success) {
        if (_.isObject(result.pagination)) {
          this.setState({
            historyData: result.orders_import_logs,
            inProgress: false,
            pagination: result.pagination ? result.pagination : {},
          });
        }
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  handleEditOrderClick = (id) => {
    this.setState(
      {
        selectedOrderId: id,
      },
      () => {
        fetchOrderDetails(id).then((result) => {
          if (result.success) {
            const currentOrder =
              result.orders.length > 0 ? _.find(result.orders, { id: id }) : {};
            if (!currentOrder.id) {
              currentOrder.id = id;
            }
            this.setState(
              {
                currentOrder,
              },
              () => {
                this.setState({ editForm: true });
              }
            );
          } else {
            alertMessage(result.errors[ 0 ], 'error', 10);
          }
        });
      }
    );
  };

  historyOrders = () => {
    const { pagination } = this.state;
    return (
      <BaseModal
        title={I18n.t("order.upload_orders.uploaded_history")}
        width="75%"
        style={{ top: 20 }}
        bodyStyle={{ padding: 5 }}
        onCancel={() => this.setState({ showHistory: false })}
      >
        <NestedTable
          historyData={this.state.historyData}
          //  scroll = {{y:"calc(100vh-300px)"}}
          handleEditOrder={this.handleEditOrderClick}
          pagination={{
            total: pagination.total_count,
            current: pagination.current_page,
            pageSize: pagination.per_page || AppConfig.ordersPerPage,
          }}
          tableChange={(paginationInfo, filters, sorter) =>
            this.onTableChange(paginationInfo, filters, sorter)
          }
        />
      </BaseModal>
    );
  };

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.uploadOrdersHistories();
    }
  };

  renderFileType = () => (
    <Row className="marginBottom10">
      <Col className="textBold alignCenter">
        <span className="textBold">File Type:</span>&nbsp;&nbsp;
        {/* <Select
          value={fileTupe || ""}
          style={{ width: "200px", marginLeft: "15px" }}
          size="small"
          dropdownMatchSelectWidth={false}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={(e) => setFileType(e)}
        >
          <Select.Option key="stops_types" value="">
            -- All --
          </Select.Option>
          {fileTypes.map((rec) => (
            <Select.Option key={rec.key} value={rec.key}>
              {rec.label}
            </Select.Option>
          ))}
        </Select> */}
        <Radio.Group
          value={this.state.fileType}
          onChange={(e) => this.handleOnChange("fileType", e.target.value)}
          size={"small"}
        >
          {fileTypes
            .filter((type) => type.label !== "Linehaul")
            .map((rec) => (
              <Radio key={rec.key} value={rec.key}>
                {rec.label}
              </Radio>
            ))}
        </Radio.Group>
      </Col>
    </Row>
  );

  renderScreen = () => {
    switch (this.state.fileType) {
      case "pdf":
        return <Fragment>{this.uploadPdf()}</Fragment>;
      case "sheet":
        return <Fragment> {this.uploadSheet()}</Fragment>;
      default:
        return <Fragment />;
    }
  };

  uploadSheet = () => {
    return <UploadSheet fileType={this.state.fileType} warehouseFilter = {this.props.appContexts.warehouseContext} />;
  };

  uploadPdf = () => {
    return (
      <UploadPdf
        accounts={this.state.accounts}
        fileType={this.state.fileType}
        paramPdfId={queryString.parse(this.props.location.search).pdf_id}
      />
    );
  };

  closeEditFormModal = () => {
    this.setState({
      editForm: false,
      currentOrder: {},
    });
  };

  render() {
    const props = {
      name: "file",
      onChange: this.handleFileChange,
      multiple: false,
    };

    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("order.upload")}</Col>
            <Col xs={12} className="page-actions">
              <Button
                type="primary"
                onClick={() => this.setState({ showHistory: true })}
                className="marginLeft10"
                icon="file-search"
              >
                {I18n.t("order.uploaded_history")}
              </Button>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={24} className='page-actions'>
              <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('location.new')}
              </Button>
            </Col>
          </Row> */}
          <Row>
            <Col xs={4} />
            <Col xs={16} className="alignCenter">
              {this.renderFileType()}
            </Col>
          </Row>
          <div className="marginTop20">{this.renderScreen()}</div>

          {this.state.showModal && this.redirectOrders()}
          {this.state.showHistory && this.historyOrders()}
          {this.state.editForm && (
            <Drawer
              placement="right"
              closable={false}
              maskClosable={false}
              onClose={this.closeEditFormModal}
              visible={this.state.editForm}
              width="90%"
            >
              <EditOrderForm
                customerDetails={this.state.currentOrder}
                customerOrderId={this.state.currentOrder.id}
                preferences={[]}
                onCancel={this.closeEditFormModal}
                handleSuccess={(
                  message,
                  isOutOfDispatch,
                  isModified,
                  newData
                ) =>
                  this.handleOrderSaveSuccess(
                    message,
                    isOutOfDispatch,
                    isModified,
                    newData
                  )
                }
                // currentRoute={this.state.selectedRouteObject}
                isNew={false}
              />
            </Drawer>
          )}
        </div>
      </div>
    );
  }
}
export default UploadOrders;
