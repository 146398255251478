import React, { Fragment, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Modal,
  Button,
  Upload,
  message,
  Icon,
  Row,
  Col,
  Spin,
  Select,
} from "antd";
//import { Document, Page } from 'react-pdf';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { saveAs } from "file-saver";
import { AccountSelect } from "../common/SelectDropdowns/BaseSelect";
import { FormItem, Link, SideBySideFormItem, TextArea } from "../../common/UIComponents";
import { createOrderFromFile, createOrderFromPdfId } from "../../api/OrdersApi";
import { OrgContext } from "../../context/OrgContext";
import { alertMessage, getFileType, isEmpty } from "../../common/Common";
import FormErrors from "../common/FormErrors";
import BaseModal from "../BaseModal";
import { WarehouseContext } from "../../context/WarehouseContext";
import { fetchShortFormAccounts } from "../../api/Account";
import { fetchLocations } from "../../api/LocationsApi";
import AppConfig from "../../config/AppConfig";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import queryString from "query-string";
import _ from "lodash";


const { Dragger } = Upload;

const UploadPdf = ({ history, fileType, location }) => {
  const urlParams = location?.search ? location.search : null;
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [base64PdfData, setBase64PdfData] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [whLoading, setWhLoading] = useState(false);
  const [accLoading, setAccLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [pdfResponse, setPdfResponse] = useState(null);

  //const [file, setFile] = useState(null);
  const { currentOrg } = useContext(OrgContext);
  const warehouseFilter = useContext(WarehouseContext);
  const [formData, setFormData] = useState({});

  // const customRequest = ({ file, onSuccess, onError }) => {
  //   // Simulate an API request. Replace with your actual API call to handle file upload.
  //   setTimeout(() => {
  //     onSuccess();
  //     message.success(`${file.name} file uploaded successfully`);
  //     setFile(file);
  //   }, 1000);

  //   setTimeout(() => {
  //     onSuccess();
  //     message.success(`${file.name} file uploaded successfully`);
  //     console.log("file", obj)
  //     setUploadedFile(file)

  //   }, 1000);
  // };

  const handleOnChange = (element, value) => {
    setFormData({ ...formData, [element]: value });
  };

  useEffect(() => {
    setFormData({});
    setAccounts([]);
    setFile(null);
    setBase64PdfData(null);
    setPdfResponse(null)
  }, [fileType]);

  useEffect(() => {
    getWarehouses();
    fetchReprocessedOrder();
  }, []);

  useEffect(() => {
    setDeafultWarehouse();
  }, [warehouses]);

  useEffect(() => {
    setDeafultAccount();
  }, [accounts]);

  useEffect(() => {
    if (formData.warehouse_id) {
      getAccounts();
    } else {
      setAccounts([]);
    }
  }, [formData.warehouse_id]);

  const getAccounts = () => {
    setAccLoading(true);
    fetchShortFormAccounts("", formData.warehouse_id)
      .then((result) => {
        if (result.success) {
          setAccounts(result.accounts || []);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setAccLoading(false);
      });
  };

  const setDeafultAccount = () => {
    if (!accounts.find((account) => account.code === formData.account_code)) {
      handleOnChange("account_code", "");
    } else {
      if (accounts.length === 1) {
        handleOnChange("account_code", accounts[0].code);
      }
    }
  };

  const setDeafultWarehouse = () => {
    const currentWh =
      typeof warehouseFilter.selectedWarehouses === "string"
        ? _.find(warehouseFilter.warehouses, {
            id: warehouseFilter.selectedWarehouses,
          }).id
        : _.find(warehouseFilter.warehouses, {
            id: warehouseFilter.selectedWarehouses[0],
          }).id;
    handleOnChange("warehouse_id", currentWh);
  };

  const getWarehouses = () => {
    setWhLoading(true);
    fetchLocations(AppConfig.warehouseCode, 1, null)
      .then((result) => {
        if (result.success) {
          setWarehouses(result?.locations?.locations || []);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setWhLoading(false);
      });
  };

  const fetchReprocessedOrder = () => {
    var searchParams = queryString.parse(urlParams);
    console.log("searchParams", searchParams);
      if( Object.keys(searchParams).length !== 0 && _.has(searchParams, 'pdf_id')){
        if(!isEmpty(searchParams.pdf_id)){
          handleCreate(searchParams.pdf_id);
        }
      }
  }

  const customRequest = (obj) => {
    const { file, onSuccess, onError, originFileObj } = obj;
    setErrors([]);
    setPdfResponse(null)
    // Simulate an API request. Replace this with your actual API call.
    setTimeout(() => {
      //onSuccess();
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          setBase64PdfData(base64Data);
          onSuccess();
          message.success(`${file.name} file uploaded successfully`);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        onError(error);
      }
    }, 1000);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const downloadFile = () => {
    if (file) {
      saveAs(file);
    }
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCreate = (fileId) => {
    setLoading(true);
    let payload = { organization_id: currentOrg.id };
    if(!fileId){
      payload.account_code = formData.account_code;
      payload.order_pdf= file;
      payload.warehouse_ids= formData.warehouse_id;
    } else {
      payload.pdf_id = fileId;
    }

    if(fileId){
      message.loading({
        key: "generate",
        content: 'Please wait we are verifying the details',
      });
      createOrderFromPdfId(payload)
      .then((result) => {
        if (result.success) {
          if(result?.data?.customer_order_id){
            message.success({
              key: "generate",
              content: I18n.t("messages.order_saved"),
            });
            //alertMessage();
            history.push(`/orders/${result.data.customer_order_id}/edit`);
          } else{
            message.error({
              key: "generate",
              content: "Something went wrong!",
            });
          }
        } else {
          setPdfResponse(result?.response || null )
         // setPdfId(result?.response?.uniqueId || "" )
          setErrors(result.errors || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    } else{
      createOrderFromFile(payload)
      .then((result) => {
        if (result.success) {
          if(result?.data?.customer_order_id){
            alertMessage(I18n.t("messages.order_saved"));
            history.push(`/orders/${result.data.customer_order_id}/edit`);
          } else{
            alertMessage("Something went wrong!");
          }
        } else {
          setPdfResponse(result?.response || null )
          //setPdfId(result?.response?.uniqueId || "" )
          setErrors(result.errors || []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
      
    // setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setFile(null);
  };

  const handleMoreFileChange = (info) => {
    const { status } = info.file;
    let fileList = [...info.fileList];
    // in array case
    const fileNamesList = fileList.map((file) => {
      return file.name;
    });
    const filesType = fileNamesList.map((fileName) => {
      return getFileType(fileName);
    });
    // const fileName = info.file.name;
    // const fileType = getFileType(fileName);
    if (filesType.some((item) => !["pdf"].includes(item))) {
      setErrors(["File Type not supporting. Upload .PDF File"]);
      //
    } else {
      if (status === "removed") {
        setFile(null);
        setBase64PdfData(null);
        setPdfResponse(null)
      } else if (status !== "uploading") {
        setFile(info.file.originFileObj);
      }
    }
  };

  const handleLoadError = (error) => {
    console.error("Failed to load PDF:", error);
    // You can display an error message to the user here, or take appropriate action.
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        {/* <Upload.Dragger customRequest={customRequest}>
        <p className="ant-upload-drag-icon">
          <Icon type="file-upload" />
        </p>
        <p className="ant-upload-text">Click or drag a PDF file here to upload</p>
      </Upload.Dragger> */}
        <Row className="uploadForm">
          <Col xs={2} />
          <Col xs={20}>
            <Row className="" gutter={16}>
              <Col xs={6}>
                <FormItem label={I18n.t("location.location")}>
                  <Select
                    value={formData.warehouse_id || ""}
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleOnChange("warehouse_id", e)}
                    loading={whLoading}
                  >
                    <Select.Option key="warehounse" value="">
                      -- Select --
                    </Select.Option>
                    {warehouses.map((warehouse) => (
                      <Select.Option
                        key={warehouse.id}
                        value={warehouse.id}
                      >
                        {warehouse.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col xs={6}>
                <FormItem label={I18n.t("general.account")}>
                  <AccountSelect
                    onChange={(e) => handleOnChange('account_code', e)}
                    data={accounts}
                    size="default"
                    element="selectedAccounts"
                    selectKey="id"
                    selectValue="code"
                    selectedCode="code"
                    selectedValue="name"
                    style={{ width: "100%" }}
                    value={formData.account_code}
                    showSearch={true}
                    dropdownMatchSelectWidth={false}
                    loading={accLoading}
                  />
                </FormItem>
              </Col>
              <Col xs={10}>
                <FormItem label={"File"}>
                  <Dragger
                    customRequest={customRequest}
                    onChange={handleMoreFileChange}
                  >
                    {/* <p className="ant-upload-drag-icon">
             <Icon type="inbox" />
           </p> */}
                    <p className="ant-upload-text" style={{ fontSize: 11 }}>
                      Click or drag files to this area to upload pdf files
                    </p>
                    {/* <p className="ant-upload-hint">Support for a single upload.</p> */}
                  </Dragger>
                </FormItem>
              </Col>
              <Col xs={2}>
                <Button
                  type="primary"
                  className="marginTop20"
                  onClick={() => handleCreate()}
                  loading={loading}
                  disabled={loading}
                  icon={"shopping"}
                >
                  {/* {I18n.t("general.create")} */}
                  Create
                </Button>
              </Col>
            </Row>
            <Row className="marginTop10">{FormErrors(errors)}</Row>
          </Col>
        </Row>

        <Row className="marginTop20 marginBottom20" gutter={16}>
          <Col xs={ pdfResponse ? 3 : 6} />
          {file && base64PdfData && (<Col xs={12}>
            
              <Fragment>
                <span className="textBold">Uploaded File</span>
                <iframe
                  src={base64PdfData}
                  style={{
                    height: 400,
                    width: "100%",
                  }}
                ></iframe>
              </Fragment>
            
          </Col>)}
          { pdfResponse && <Col xs={6}>
              <div>
                <Row>
                  <Col xs={11} className="textBold">Pdf Response</Col>
                  {
                  pdfResponse.uniqueId && 
                  <Fragment>
                    <Col xs={8} className="textBold alignRight">
                      <Link style={{ fontWeight: 'bold'}} onClick={(e)=> {
                        handleCreate(pdfResponse.uniqueId)
                      }} className="textBold">Reprocess&nbsp;<Icon type="reload" /></Link>
                    </Col>
                    <Col xs={5} className="textBold alignRight">
                      <Link style={{ fontWeight: 'bold'}} onClick={(e)=> {
                        window.open(AppConfig.pdf_url+pdfResponse.uniqueId, "_blank");
                      }} className="textBold">Edit&nbsp;<Icon type="edit" /></Link>
                      
                    </Col>
                  
                  </Fragment>
                  }
                </Row>
                <TextArea value={JSON.stringify(pdfResponse, null, 2)} rows={15} />
              </div>
          
          </Col>
          } 
        </Row>

        {false && file && (
          <div>
            {/* <Button type="primary" onClick={downloadFile}>Download Uploaded File</Button> */}

            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={handleLoadError}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={400} // Specify the desired width
                  height={600} // Specify the desired height
                  renderTextLayer={false} // Disable text layer rendering
                  renderInteractiveForms={false} // Disable interactive forms rendering
                  style={{ margin: 0 }}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          </div>
        )}
      </Spin>
    </Fragment>
  );
};

export default withRouter(UploadPdf);
