import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  Radio,
  Icon,
  Spin,
  Card,
  Checkbox,
} from "../../common/UIComponents";
import DriverRateConfigurationList from "./DriverRateConfigurationList";
import { isEmpty, alertMessage,doValidate } from "../../common/Common";
import I18n from "../../common/I18n";
import DriverRateForm from "./DriverRateForm";
import {
  fetchAccountDriverRateDetails,
  saveAccountDriverRate,
} from "../../api/DriverRates";
import { fetchAccountAccessorials } from "../../api/Account";
import FormErrors from "../common/FormErrors";
import { driverRateApi } from "../../api/DriverRatesApi";
import RangeConfiguration from "./RangeConfiguration";

const requiredFields = [
  {
    form_field: "name",
    ui_name: `${I18n.t("general.name")}`,
    isRequired: true,
    inputType: "name",
  },
  {
    form_field: "category",
    ui_name: I18n.t("general.category"),
    isRequired: true,
    inputType: "text",
  },
];

class DRFormByCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverRateDetails: this.props.driverRateDetails,
      account: this.props.account,
      otherRateDetails: [
        {
          accessorial_charge_type: "percentage",
          accessorial_charge_value: "",
          accessorial_name: I18n.t("driver_rates.Transportation"),
          accessorial_type: "Transportation",
        },
        {
          accessorial_charge_type: "percentage",
          accessorial_charge_value: "",
          accessorial_name: I18n.t("driver_rates.fuel_surcharge"),
          accessorial_type: I18n.t("driver_rates.fuel_surcharge"),
        },
      ],
      currentDriverRate: this.props.rateRecord || { cate },
      driverRatesCommonForm: this.props.driverRatesCommonForm || {
        charge_type: "percentage",
        charge_value: "0",
      },
      isNew: this.props.isNew,
      isDriverRateDetailsLoading: false,
      masterAccessorials: this.props.masterAccessorials || [],
      driverRatesList: this.props.driverRatesList || [],
      accessorials: [],
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.getDriverRateConfigurations(this.state.currentDriverRate.id);
    } else {
      this.getAccessorials();
    }
  }

  getAccessorials = () => {
    const account = this.state.account ? this.state.account : {};
    const { currentDriverRate } = this.state;
    fetchAccountAccessorials(account.id, "ALL").then((result) => {
      if (result.success) {
        const accessorials = result.accessorials;
        if (accessorials.length > 0) {
          const driverRateParams = currentDriverRate.driver_rate_params || [];
          accessorials.forEach((accessorial) => {
            const currentRecord = _.find(driverRateParams, {
              account_accessorial_id: accessorial.id,
            });
            accessorial.accessorial_charge_type = !isEmpty(currentRecord)
              ? currentRecord.accessorial_charge_type
              : "percentage";
            accessorial.accessorial_charge_value = !isEmpty(currentRecord)
              ? currentRecord.accessorial_charge_value
              : "";
          });
        }
        this.setState({
          accessorials,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false, errors: result.errors });
      }
    });
  };

  getDriverRateConfigurations = (id) => {
    this.setState({ isDriverRateDetailsLoading: true });
    fetchAccountDriverRateDetails(id).then((result) => {
      if (result.success && result.driver_rate) {
        const driverRate = result.driver_rate || { driver_rate_params: [] };
        if (isEmpty(driverRate.driver_rate_params)) {
          driverRate.driver_rate_params = [];
        }
        this.setState(
          {
            currentDriverRate: driverRate,
            isDriverRateDetailsLoading: false,
          },
          () => {
            this.setOtherRateObjects(true);
            this.getAccessorials();
          }
        );
      } else {
        this.setState({
           currentDriverRate: {},
           isDriverRateDetailsLoading: false,
        });
      }
    });
  };

  saveRate = (data) => {
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      if (!this.state.isNew && data.id) {
        data.account_driver_rate_id = data.id;
      }
      driverRateApi.saveDriverRate(data, this.state.isNew).then((result) => {
        if (result.success) {
          alertMessage("Saved Successfully!");
          const driverRate = result.driver_rate;
          this.setState(
            {
              currentDriverRate: driverRate,
              isNew: false,
              errors: [],
            },
            () => {
              this.setOtherRateObjects(true);
              this.getAccessorials();
            }
          );
        } else {
          this.setState({
            errors: result.errors || [],
          });
        }
      });
    } else {
      this.setState({
        errors,
      });
    }
  };
  updateDriverRate = (driverRate) => {
    this.setState({
      currentDriverRate: {...driverRate}
    })
  }

  handleDriverRateInput = (key_name, value, id, accessorial_type) => {
    if (accessorial_type === "Accessorial") {
      const accessorials = this.state.accessorials;
      const driverRateIndex = _.findIndex(accessorials, ["id", id]);
      if (driverRateIndex >= 0) {
        const driverRate = accessorials[driverRateIndex];
        driverRate[key_name] = value;
        accessorials[driverRateIndex] = driverRate;
        this.setState({
          accessorials,
        });
      }
    } else {
      const driverRates = this.state.otherRateDetails || [];
      const rateIndex = _.findIndex(driverRates, { accessorial_type });
      if (rateIndex >= 0) {
        driverRates[rateIndex][key_name] = value;
      }
      this.setState({
        otherRateDetails: driverRates,
      });
    }
  };

  handleDriverRateConfigurationSaveAll = () => {
    if (
      !isEmpty(this.state.driverRatesCommonForm) &&
      !isEmpty(this.state.driverRatesCommonForm.charge_value)
    ) {
      const driverRatesCommonForm = { ...this.state.driverRatesCommonForm };
      if (isEmpty(driverRatesCommonForm).charge_type) {
        driverRatesCommonForm.charge_type = "percentage";
      }
      const { accessorials, currentDriverRate, account } = this.state;
      const driverRates = [];
      driverRates.push({
        accessorial_type: "Transportation",
        accessorial_charge_type: driverRatesCommonForm.charge_type,
        accessorial_charge_value: driverRatesCommonForm.charge_value,
        accessorial_name: null,
        accessorial_code: null,
        account_accessorial_id: null,
      });
      driverRates.push({
        accessorial_type: I18n.t("driver_rates.fuel_surcharge"),
        accessorial_charge_type: driverRatesCommonForm.charge_type,
        accessorial_charge_value: driverRatesCommonForm.charge_value,
        accessorial_name: null,
        accessorial_code: null,
        account_accessorial_id: null,
      });
      accessorials.forEach((accessorial) => {
        driverRates.push({
          accessorial_type: "Accessorial",
          accessorial_charge_type: driverRatesCommonForm.charge_type,
          accessorial_charge_value: driverRatesCommonForm.charge_value,
          accessorial_name: accessorial.accessorial_name,
          accessorial_code: accessorial.accessorial_code,
          account_accessorial_id: accessorial.id,
        });
      });
      const data = {
        organization_id: account.organization_id,
        account_id: account.id,
        is_default: currentDriverRate.is_default,
        name: currentDriverRate.name,
        category: currentDriverRate.category,
        driver_rate_params: driverRates,
        id: currentDriverRate.id,
        category_configuration: {},
      };
      this.saveRate(data);
    } else {
      const errors = [];
      if (isEmpty(this.state.driverRatesCommonForm.charge_type)) {
        errors.push("Please provide charge type", "error", 10);
      }

      if (isEmpty(this.state.driverRatesCommonForm.charge_value)) {
        errors.push("Please provide charge value", "error", 10);
      }
      this.setState({
        errors,
      });
    }
  };

  handleWeightRangeSave = () => {
    const {currentDriverRate = {}, account} = this.state;
    const errors = [];
    if (isEmpty(currentDriverRate.name)) {
      errors.push("Please provide name", "error", 10);
    }

    if (isEmpty(currentDriverRate.category)) {
      errors.push("Please provide category", "error", 10);
    }
    if (
      errors.length === 0
    ) {
      const data = {
        organization_id: account.organization_id,
        account_id: account.id,
        is_default: currentDriverRate.is_default,
        name: currentDriverRate.name,
        category: currentDriverRate.category,
        driver_rate_params: currentDriverRate.driver_rate_params || [],
        id: currentDriverRate.id,
        category_configuration: currentDriverRate.category_configuration || {},
      };
      this.saveRate(data);
    } else {
      const errors = [];
     
      this.setState({
        errors,
      });
    }
  };

  setOtherRateObjects = (isInitial = true) => {
    const { currentDriverRate, account, otherRateDetails } = this.state;
    const otherRateObjects = otherRateDetails ? [...otherRateDetails] : [];
    if (isInitial) {
      const driverRateParams = currentDriverRate.driver_rate_params || [];
      const filteredParams = driverRateParams.filter(
        (driverRate) => driverRate.accessorial_type !== "Accessorial"
      );
      if (!isEmpty(filteredParams)) {
        filteredParams.forEach((dr, index) => {
          const driverRateParamIndex = _.findIndex(otherRateObjects, {
            accessorial_type: dr.accessorial_type,
          });
          if (driverRateParamIndex >= 0) {
            otherRateObjects[driverRateParamIndex].accessorial_charge_type =
              dr.accessorial_charge_type
                ? dr.accessorial_charge_type
                : "percentage";
            otherRateObjects[driverRateParamIndex].accessorial_charge_value =
              dr.accessorial_charge_value ? dr.accessorial_charge_value : "";
          }
        });
        this.setState({
          otherRateDetails: otherRateObjects,
        });
      }
    } else {
      const driverRates = otherRateObjects.map((dr) => {
        dr.accessorial_code = null;
        dr.account_accessorial_id = null;
        dr.accessorial_charge_type = dr.accessorial_charge_type
          ? dr.accessorial_charge_type
          : "percentage";
        dr.accessorial_charge_value = dr.accessorial_charge_value
          ? dr.accessorial_charge_value
          : "";
        return dr;
      });
      return driverRates;
    }
  };

  handleRatesSingleSave = () => {
    const { accessorials, currentDriverRate, account } = this.state;
    let driverRates = [];
    const driverRateOtherObjects = this.setOtherRateObjects(false);
    driverRates = [...driverRateOtherObjects];
    accessorials.forEach((accessorial) => {
      driverRates.push({
        accessorial_type: "Accessorial",
        accessorial_charge_type: accessorial.accessorial_charge_type,
        accessorial_charge_value: accessorial.accessorial_charge_value,
        accessorial_name: accessorial.accessorial_name,
        accessorial_code: accessorial.accessorial_code,
        account_accessorial_id: accessorial.id,
      });
    });
    const data = {
      organization_id: account.organization_id,
      account_id: account.id,
      is_default: currentDriverRate.is_default,
      name: currentDriverRate.name,
      category: currentDriverRate.category,
      driver_rate_params: driverRates,
      id: currentDriverRate.id,
      category_configuration: {},
    };
    this.saveRate(data);
  };

  handleRatesSingleSave = () => {
    const { accessorials, currentDriverRate, account } = this.state;
    let driverRates = [];
    const driverRateOtherObjects = this.setOtherRateObjects(false);
    driverRates = [...driverRateOtherObjects];
    accessorials.forEach((accessorial) => {
      driverRates.push({
        accessorial_type: "Accessorial",
        accessorial_charge_type: accessorial.accessorial_charge_type,
        accessorial_charge_value: accessorial.accessorial_charge_value,
        accessorial_name: accessorial.accessorial_name,
        accessorial_code: accessorial.accessorial_code,
        account_accessorial_id: accessorial.id,
      });
    });
    const data = {
      organization_id: account.organization_id,
      account_id: account.id,
      is_default: currentDriverRate.is_default,
      name: currentDriverRate.name,
      category: currentDriverRate.category,
      driver_rate_params: driverRates,
      id: currentDriverRate.id,
      category_configuration: {},
    };
    this.saveRate(data);
  };

  handleDriverRateClear = () => {
    this.setState({
      currentDriverRate: Object.assign({}, this.state.currentDriverRate, {
        name: "",
        code: "",
      }),
    });
  };

  handleDriverRateFormInputChage = (element, value) => {
    if (element === "code") {
      value = value.toUpperCase();
    }
    if (element === "is_default") {
      value = value ? "YES" : "NO";
    }
    const currentDriverRate = Object.assign({}, this.state.currentDriverRate);
    if (element === "category_configuration") {
      currentDriverRate["category_configuration"] = {
        ...currentDriverRate["category_configuration"],
        ...value,
      };
    } else {
      currentDriverRate[element] = value;
    }
    if(element === "category") {
      currentDriverRate["category_configuration"] = {
        is_smp_applied: "false",
        apply_cwt_for_fmp: "false",
        apply_cwt_for_smp: "false",
      };
      currentDriverRate["driver_rate_params"] = [{
        fmp: [],
      }]
    }
    this.setState({
      currentDriverRate,
    });
  };

  handleCommonDriverRate = (element, value) => {
    this.setState({
      driverRatesCommonForm: Object.assign(
        {},
        this.state.driverRatesCommonForm,
        {
          [element]: value,
        }
      ),
    });
  };

  _renderCommonAccessorialForm = () => {
    const { driverRatesCommonForm, currentDriverRate } = this.state;
    return (
      <Row gutter={8}>
        <Col xs={6}>
          <FormItem label="Rate Type">
            <Radio.Group
              onChange={(e) =>
                this.handleCommonDriverRate("charge_type", e.target.value)
              }
              value={driverRatesCommonForm.charge_type || "percentage"}
            >
              <Radio value="percentage">Percentage</Radio>
              <Radio value="flat">Flat</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
        <Col xs={6}>
          <FormItem label="Rate Value">
            {/* <span className="chargeValueTxt">{extraMenForm.charge_type === 'percentage' ? '(Based on Transportation Charges)' : null}</span> */}
            <Input
              addonBefore={
                driverRatesCommonForm.charge_type === "flat" ? (
                  <Icon type="dollar" />
                ) : null
              }
              addonAfter={
                isEmpty(driverRatesCommonForm.charge_type) ||
                driverRatesCommonForm.charge_type === "percentage" ? (
                  <Icon type="percentage" />
                ) : null
              }
              type="number"
              value={parseFloat(driverRatesCommonForm.charge_value)}
              onChange={(e) =>
                this.handleCommonDriverRate("charge_value", e.target.value)
              }
              min={0}
              precision={this.state.decimalPoints}
              defaultValue={0}
              size="small"
            />
          </FormItem>
        </Col>
        <Col xs={6}>
          <div className="alignLeft paddingTop20">
            <Button
              type="primary"
              onClick={() => this.handleDriverRateConfigurationSaveAll()}
              disabled={
                isEmpty(currentDriverRate.name) ||
                isEmpty(currentDriverRate.category)
              }
              size="small"
              icon='check-circle'
            >
              Apply for all
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      accessorials,
      otherRateDetails = [],
      currentDriverRate,
    } = this.state;
    const category_configuration = currentDriverRate?.category_configuration
      ? currentDriverRate.category_configuration
      : {};
    const driver_rate_params = currentDriverRate?.driver_rate_params
      ? currentDriverRate.driver_rate_params
      : [];
    return (
      <React.Fragment>
        <Row>
          <Col md={24}>{FormErrors(this.state.errors)}</Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            {/* <Card
              size="small"
              title="Driver Rate"
              bodyStyle={{ textAlign: 'left', padding: 10 }}
            > */}
            <DriverRateForm
              driverRate={this.state.currentDriverRate}
              handleDriverRateFormInputChage={(element, value) =>
                this.handleDriverRateFormInputChage(element, value)
              }
              handleWeightRangeSave={this.handleWeightRangeSave}
              isNew={this.state.isNew}
              showActions={false}
            />
            {/* </Card> */}
          </Col>
        </Row>
        {this.state.currentDriverRate.category === "PL" && (
          <Spin spinning={this.state.isDriverRateDetailsLoading}>
            <Row className="marginTop20">
              <Col xs={24}>
                <Card
                  size="small"
                  title="Accessorial Settings"
                  bodyStyle={{ textAlign: "left", padding: 10 }}
                >
                  <this._renderCommonAccessorialForm />
                </Card>
              </Col>
            </Row>
            <div style={{ marginTop: 25 }}>
              <DriverRateConfigurationList
                data={otherRateDetails}
                handleDriverRateInput={this.handleDriverRateInput}
                handleDriverRateConfigurationSave={
                  this.handleDriverRateConfigurationSave
                }
                size="small"
                pagination={{ position: "none" }}
                displayType="Charges"
                isDisabled={false}
              />
            </div>
            <div style={{ marginTop: 25 }}>
              <DriverRateConfigurationList
                data={accessorials || []}
                handleDriverRateInput={this.handleDriverRateInput}
                handleDriverRateConfigurationSave={
                  this.handleDriverRateConfigurationSave
                }
                size="small"
                pagination={{ position: "none" }}
                displayType="Accessorial"
                isDisabled={false}
              />
            </div>

            <div className="alignCenter marginTop20">
              <Button type="primary" onClick={this.handleRatesSingleSave}>
                 {I18n.t("general.save")} 
              </Button>
            </div>
          </Spin>
        )}
        {!isEmpty(this.state.currentDriverRate.name) && this.state.currentDriverRate.category === "RANGE" && (
          <div>
            <RangeConfiguration
              category_configuration={category_configuration}
              driver_rate_params={driver_rate_params}
              currentDriverRate={currentDriverRate}
              account={this.state.account}
              updateDriverRate={this.updateDriverRate}
              handleConfigurationChange={this.handleDriverRateFormInputChage}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(DRFormByCategory);
