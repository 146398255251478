import { Button, Col, Icon, Row, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DockScreenIncomingLList from "./DockScanIncomingList";
import I18n from "../../../common/I18n";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import { ScreenKeys } from "../../../containers/constants";
import { UserContext } from "../../../context/UserContext";
import { WarehouseContext } from "../../../context/WarehouseContext";
import { base64ToExcel, renderAlertMessage, responseTOCsv } from "../../../helpers/common";
import AppConfig from "../../../config/AppConfig";
import { getIncomingDocks } from "../../../api/DockScanReport";
import { OrgContext } from "../../../context/OrgContext";
import moment from "moment";
import { isEmpty } from "../../../common/Common";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
export const DockScanIncomingScreen = () => {
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [incomingData, setIncomingData] = useState([]);
  const userContextData = useContext(UserContext);
  const warehouseFilter = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const { updateRecordsPerPage } = userContextData;
  const perPageKey = ScreenKeys.DOCK_SCAN_REPORT;
  const recordsPerPageSaved = _.get(
    userContextData,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [clickCsv, setClickCsv] = useState(false);

  useEffect(() => {
    debouncedIncomingDocks();
  }, [
    recordsPerPage,
    warehouseFilter.selectedWarehouses,
    orgData.currentOrg.id,
    clickCsv,
  ]);

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved;
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    if (tableOptions.pagination.current !== currentPage) {
      debouncedIncomingDocks();
    }
  };

  const fetchIncomingDocks = () => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = recordsPerPage;
    const whId = _.get(warehouseFilter, "selectedWarehouses", "");
    const orgId = _.get(orgData, "currentOrg.id", "");
    const isExport = clickCsv ? true : false;
    const reqData = {
      page,
      perPage,
      whId,
      orgId,
      isExport,
    };
    const fileName = `DockScan_report-${moment().format("YYYY-MM-DD")}`;
    if (clickCsv) {
      setExportLoading(true);
    } else {
      setLoading(true);
    }
    getIncomingDocks(reqData)
      .then((response) => {
        if (response.success) {
          if (clickCsv) {
            const { data } = response;
            const { file, filename } = data;
            // responseTOCsv(response.data, fileName, "xlsx");
            base64ToExcel(file, filename);
            setClickCsv(false);
          } else {
            setPagination(response.data.meta.pagination);
            setIncomingData(response.data.details);
            setLoading(false);
          }
        } else {
          setLoading(false);
          renderAlertMessage(response.errors);
        }
      })
      .finally(() => {
        setLoading(false);
        setExportLoading(false);
      });
  };

  const debouncedIncomingDocks = _.debounce(fetchIncomingDocks, 500);

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("dock_scan_report.title")}</Col>
          <Col xs={12} className="page-actions"></Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col>
              <Row type="flex" justify="end" gutter={8}>
                <Col>
                  <Button
                    size="small"
                    className="cursorPointer"
                    type="primary"
                    loading={exportLoading}
                    disabled={exportLoading}
                    onClick={() => {
                      setClickCsv(true);
                    }}
                    shape="round"
                  >
                    {loading ? (
                      "Exporting..."
                    ) : (
                      <span>
                        Export Excel <Icon type="file-excel" theme="filled" />
                      </span>
                    )}
                  </Button>
                </Col>
                <Col>
                  <RecordsPerPage
                    onChange={onRecordChange}
                    value={recordsPerPage}
                    defaultValue={recordsPerPage}
                  />
                </Col>
              </Row>
              <Col className="marginTop10">
                <DockScreenIncomingLList
                  scroll={{ x: "max-content", y: "calc(100vh - 310px)" }}
                  data={_.isEmpty(incomingData) ? [] : incomingData}
                  pagination={tablePagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    onTableChange(pagination, filter, sorter, currentTable)
                  }
                />
              </Col>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};
