import React, {Component} from 'react';
import BaseList from '../BaseList';
import {checkNegitive } from '../../common/Common';
import { formatByTimeConfig } from '../../helpers/common';
import moment from 'moment';
import I18n from '../../common/I18n';

class USEnergyLists extends BaseList {
  constructor(props) {
    super(props); 
    this.columns = [
      {
        title: I18n.t('USEnergyInfo.region_name'),
        dataIndex:'region_name',
        key:'region_name',
        className:'width30',
      },
      {
        title: I18n.t('USEnergyInfo.fuel_price'),
        dataIndex:'fuel_price',
        key:'fuel_price',
        className:'width10',
        render: data => <div className = 'paddingRight25 alignRight'>{data ? checkNegitive(data) : ''}</div>,
      } ,
      {
        title: I18n.t('USEnergyInfo.from_date'),
        dataIndex:'effective_from_date',
        key:'effective_from_date',
        className:'width30 paddingLeft25',
        render: text => (text ? formatByTimeConfig(moment.utc(text), this.props.isMilitaryTime , "MMM Do YYYY, HH:mm UTC" , "MMM Do YYYY, hh:mm A UTC") : 'NA'),
      },
      {
        title: I18n.t('USEnergyInfo.publish_date'),
        dataIndex:'published_on',
        key:'published_on',
        className:'width30',
        render: text => (text ? formatByTimeConfig(moment.utc(text), this.props.isMilitaryTime , "MMM Do YYYY, HH:mm UTC" , "MMM Do YYYY, hh:mm A UTC") : 'NA'),
      },
    ]
  }
}
export default USEnergyLists;
