import React from 'react'
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Col,
  Row,
  Select,
} from 'antd'
import PropTypes from 'prop-types'
import { alertMessage, isEmpty, randomUUID } from '../../common/Common'
import { confirmationPopup } from '../../helpers/common'
import I18n from "../../common/I18n";
import AppConfig from '../../config/AppConfig'
import { QuotesApis } from '../../api/QuotesApi'
import _ from 'lodash';
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'

const EditableContext = React.createContext()

const calculateDimWeight = (record , accountDim) => {
    if(!accountDim) return 0;
    const {
      item_weight = 0,
      dim_factor = accountDim?.dim_factor ? accountDim.dim_factor : 1,
      item_width = 1,
      item_height = 1,
      item_length = 1,
      item_quantity = 1,
    } = record;
    let dimWeight = 0;
    let configuredMinWeight = 0;
    let finalWeight = 0;
    if (
      !isEmpty(
        accountDim
      ) &&
      !isEmpty(
        accountDim.min_weight)
    ) {
      configuredMinWeight = accountDim.min_weight;
    }
    if (configuredMinWeight < item_weight && dim_factor > 0) {
      dimWeight = (
        (item_width * item_height * item_length) /
        dim_factor
      )
      finalWeight = dimWeight * item_quantity;
    }
    return parseFloat(finalWeight).toFixed(2);
  }
class EditableCell extends React.Component {
  

  getInput = (form, record , dataIndex) => {
    if (this.props.inputType === 'number') {
      return <InputNumber min={1} onChange={ (value) => {
        const formValues = form.getFieldsValue();
        const newValue = value;
        const dimWeight = calculateDimWeight({
          ...formValues,
          [dataIndex]: newValue,
        } , this.props.accountDim);
        const {saveDimWeight} = this.props;
        saveDimWeight(record.id , dimWeight);
      }}
      size="small"
     />
    }
    return <Input  size="small"/>
  }

  renderCell = (form) => {
    const { getFieldDecorator } = form
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,accountDim,
      initialValue,
      ...restProps
    } = this.props
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Invalid Input`,
                },
              ],
              initialValue : record[dataIndex] ? record[dataIndex] : initialValue ,
            })(this.getInput(form, record , dataIndex , accountDim))}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    )
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props)
    const { getFieldDecorator } = props.form
    this.state = {
      data: this.props.data,
      editingKey: '',
      loading: false,
      activeRecordId : this.props.activeRecordId
    }

    this.isLastRow = (id, data) => {
      const index = data.findIndex((item) => item.id === id)
      return index === data.length - 1
    }

    this.columns = [
      {
        key: 'index',
        title: '#',
        dataIndex: 'id',
        editable: false,
        render: (data, record, index) => {
          return index + 1
        },
        width: '5%',
      },
      {
        key: 'item_name',
        title: I18n.t('general.name'),
        dataIndex: 'item_name',
        editable: true,
        render: (data, record, index) => {
          return data
        },
        width: '15%',
      },
      {
        key: 'quantity',
        title: I18n.t('order.pieces'),
        dataIndex: 'item_quantity',
        editable: true,
        width: '10%',
        initialValue: 1,
      },
      {
        key: 'weight',
        title: I18n.t('order.weight'),
        dataIndex: 'item_weight',
        editable: true,
        width: '10%',
      },
      {
        key: 'length',
        title: I18n.t('order.length'),
        dataIndex: 'item_length',
        editable: true,
        width: '10%',
      },
      {
        key: 'width',
        title: I18n.t('order.width'),
        dataIndex: 'item_width',
        editable: true,
        width: '10%',
      },
      {
        key: 'height',
        title: I18n.t('order.height'),
        dataIndex: 'item_height',
        editable: true,
        width: '10%',
      },
      {
        key: 'dim_factor',
        title: I18n.t('configurations.dim_factor'),
        dataIndex: 'dim_factor',
        editable: false,
        render: (data, record, index) => {
          const dim_factor = this.props?.accountDim ? this.props.accountDim.dim_factor : '';
          return this.props.viewOnly ? (data || dim_factor) :
            this.props.accountDim &&
              !isEmpty(this.props.accountDim.dim_factor) ? (
              <Select
                style={ { width: '100%' } }
                value={ data || this.props.accountDim.dim_factor }
                onChange={ (value) => {
                  const formValues = props.form.getFieldsValue();
                  const dimWeight = calculateDimWeight({
                    ...formValues,
                    dim_factor: value,
                  }, this.props.accountDim);
                  const newData = this.state.data.map((item) => {
                    if (item.id === this.state.editingKey) {
                      return {
                        ...item,
                        dim_weight: dimWeight,
                        dim_factor: value,
                      };
                    }
                    return item;
                  });
                  this.props.updateItemList(newData);
                } }
                disabled={ this.state.editingKey !== record.id }
                size="small"
              >
                { AppConfig.dimValues.map((key) => (
                  <Select.Option value={ key } key={ key }>
                    { key }
                  </Select.Option>
                )) }
              </Select>
            ) : null
        },
        width: '10%',
      },
      {
        key: 'dim_weight',
        title: I18n.t('configurations.dim_weight'),
        dataIndex: 'dim_weight',
        editable: false,
        render: (data, record, index) => {
          return (
            <Form.Item>
              { getFieldDecorator('dim_weight', {
                initialValue: data || this.props.accountDim?.dim_weight || 0,
              })(
                <Input disabled={true} size="small" />
              ) }
            </Form.Item>
          )
        },
        width: '10%',
      },
    ]
    if (props.showActions) {
      this.columns.push({
        title: '',
        width: '15%',
        dataIndex: 'operation',
        render: (text, record) => {
          const editable = this.isEditing(record)
          const { addAtLastColumn } = this.props
          return (
            <div
              className="actionsWrapper paddingLeft10 fontSize14"
              type="flex"
              gutter={8}
            >
              {editable ? (
                <Row type="flex" gutter={8}>
                  <Col>
                    <EditableContext.Consumer>
                      {(form) => (
                        <Icon
                          type="save"
                          onClick={() => this.save(form, record.id)}
                          style={{ color: '#1890ff', fontSize: 20 }}
                        />
                      )}
                    </EditableContext.Consumer>
                  </Col>
                  <Col>
                   
                      <Icon
                       onClick={() => this.cancel(record.id)}
                        type="close"
                        style={{
                          color: 'red',
                          fontSize: 20,
                          pointerEvents: this.state.data.length === 1 ? 'none' : 'all',
                          opacity: this.state.data.length === 1 ? 0.5 : 1,
                        }}
                      />
                
                  </Col>
                </Row>
              ) : (
                <Row type="flex" gutter={16}>
                  <Col>
                    <EditIcon handleClick={() => this.edit(record.id)}/>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="Sure to Delete?"
                      onConfirm={() => this.delete(record.id)}
                      placement="topLeft"
                    >
                      <DeleteIcon/>
                      &nbsp;&nbsp;
                    </Popconfirm>
                  </Col>
                  {!this.state.editingKey.length &&
                    addAtLastColumn &&
                    this.isLastRow(record.id, this.state.data) && (
                      <Col>
                        <Icon
                          type="plus-circle"
                          onClick={() => this.addNewRow()}
                          style={{ color: '#000', fontSize: 20 }}
                        />
                      </Col>
                    )}
                </Row>
              )}
            </div>
          )
        },
        width: '20%',
      })
    }
  }

  componentDidMount(){
    if(!this.state.data.length)
    {
      this.addNewRow();
    }
    else{
      // remove new unsaved ones
      const nonNewRecords = this.state.data.filter(item => !item.isNew);
      this.setState({ data: nonNewRecords });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.data.length) {
      this.addNewRow();
    }
      if(!_.isEqual(this.props.data, prevProps.data)){
      this.setState({ data: this.props.data }) 
    }

    if(this.props.clickedSave){
      const nonNewRecords = this.state.data.filter(item => !item.isNew);
      if(nonNewRecords.length){
        this.props.handleSuccess()
      }else{
        alertMessage(I18n.t("messages.please_add_item") , "error");
        this.props.handleFailure()
      }
    }

  }

  isEditing = (record) => record.id === this.state.editingKey

  cancel = () => {
    const { data } = this.state
    const newData = data.filter((item) => {
      if (item.id === this.state.editingKey) {
        if (item.isNew) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    })

    this.setState({ data: newData, editingKey: '' })
    // this.props.updateItemList(newData);
  }

  save(form, id) {
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const newData = [...this.state.data]
      const index = newData.findIndex((item) => id === item.id)
      if (index > -1) {
        const item = newData[index]
        const isNewRecord = item.isNew === true
        delete item.isNew
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        const apiPayload = {
          ...row,
          quote_id: this.props.quoteId,
          dim_factor: row.dim_factor || item.dim_factor || this.props.accountDim?.dim_factor || null,
          dim_weight: row.dim_weight || item.dim_weight || null,
        }
        if(!isNewRecord){
          apiPayload.id = item.id
        }
          this.setState({ loading: true })
          // parse to float for truthy values only
          const apiPayloadFloats = {
            ...apiPayload,
            item_weight: apiPayload.item_weight ? parseFloat(apiPayload.item_weight) : 0,
            item_length: apiPayload.item_length ? parseFloat(apiPayload.item_length) : 0,
            item_width: apiPayload.item_width ? parseFloat(apiPayload.item_width) : 0,
            item_height: apiPayload.item_height ? parseFloat(apiPayload.item_height) : 0,
            item_quantity: apiPayload.item_quantity ? parseFloat(apiPayload.item_quantity) : 0,
            dim_weight: apiPayload.dim_weight ? parseFloat(apiPayload.dim_weight) : 0,

          }
          QuotesApis.createOrderItem(apiPayloadFloats)
            .then((res) => {
              if (res.success) {
                newData[index] = res.data
                this.setState({ data: newData, editingKey: '' })
                alertMessage(I18n.t('messages.saved'))
              } else {
                alertMessage(res.errors[0], 'error', 10)
              }
            })
            .finally(() => {
              this.setState({ loading: false })
            })
      }
      this.props.updateItemList(newData)
    })
  }
  onEditConfirm = (id) => {
    const { editingKey, data } = this.state
    const newData = data.filter((item) => item.id !== editingKey)
    this.setState({ data: newData, editingKey: id })
  }

  removeNewRecords = (id) => {
    const nonNewRecords = this.state.data.filter((item) => !item.isNew)
    this.setState({ data: nonNewRecords, editingKey: id })
    this.props.updateItemList(nonNewRecords)
  }
  edit(id) {
    if (this.state.editingKey.length) {
      const confirmationInfo = {
        title: I18n.t('general.are_you_sure'),
        content: I18n.t('messages.unsaved_changes'),
        onConfirm: () => {
          this.removeNewRecords(id)
        },
      }
      confirmationPopup(confirmationInfo)
    } else {
      this.setState({ editingKey: id })
    }
  }
  delete(id) {
    const { data,activeRecordId } = this.state
    this.setState({ loading: true })
    QuotesApis
      .delete_quote_item(id,activeRecordId)
      .then((res) => {
        if (res.success) {
          const newData = data.filter((item) => item.id !== id)
          this.setState({ data: newData,editingKey: '' })
          this.props.updateItemList(newData)
          alertMessage(I18n.t('messages.deleted'))
        } else {
          alertMessage(res.errors[0], 'error', 10)
        }
      })
      .finally(() => {
          this.setState({  loading: false })
      })
  }
  addNewRow = () => {
    const randomId = randomUUID()
    const newData = {
      id: `${randomId}`,
      item_name: `item ${this.state.data.length + 1}`,
      isNew: true,
    }
    this.setState({
      data: [...this.state.data, newData],
      editingKey: newData.id,
    })
    this.props.updateItemList([...this.state.data, newData])
  }

  saveDimWeight = (id, dimWeight) => {
    const { data } = this.state
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          dim_weight: dimWeight,
        }
      }
      return item
    })
    this.setState({ data: newData })
    this.props.updateItemList(newData)
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    }

    const columns = this.columns.map((col) => {
      const numberTypeCols = [
        I18n.t('order.pieces'),
        I18n.t('order.weight'),
        I18n.t('order.height'),
        I18n.t('order.width'),
        I18n.t('order.length'),
      ]
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: numberTypeCols.includes(col.title) ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          handlesave: this.save.bind(this),
          accountDim : this.props.accountDim,
          initialValue: col.initialValue || null,
          saveDimWeight: this.saveDimWeight.bind(this),
        }),
      }
    })

    return (
      <Row
        style={{
          display: 'grid',
          gap: '10px',
        }}
      >
        <EditableContext.Provider value={this.props.form}>
          <Table
            loading={this.state.loading}
            style={{
              ...this.props.style,
            }}
            rowKey={(record) => record.id}
            components={components}
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            size={'small'}
            className="quote_item_table"
          />
        </EditableContext.Provider>
      </Row>
    )
  }
}

const QuoteOrderItemsList = Form.create()(EditableTable)
export default QuoteOrderItemsList

QuoteOrderItemsList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  updateItemList: PropTypes.func,
  handleItemChange: PropTypes.func,
  style: PropTypes.object,
  organization_id: PropTypes.number || PropTypes.string,
  customer_order_id: PropTypes.number || PropTypes.string,
}

QuoteOrderItemsList.defaultProps = {
  data: [],
  loading: false,
  updateItemList: () => {},
  handleItemChange: () => {},
  style: {},
  organization_id: 0,
  customer_order_id: 0,
  showActions: true,
}
