import React, { useEffect, useState } from "react";
import RouteLogContentList from "./RouteLogContentList";
import { getRouteLogApi } from "../../api/Routes";
import { alertMessage, isEmpty } from "../../common/Common";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };

export const RoutelogContent = (props) => {
  const [dispatchLog, SetDispatchLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const { routeId,isMilitaryTime } = props;

  useEffect(() => {
    getRouteLog();
  }, []);

  const getRouteLog = () => {
    setIsLoading(true);
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = AppConfig.ordersPerPage;
    getRouteLogApi({routeId,page, perPage})
      .then((result) => {
        if (result.success) {
          SetDispatchLog(result.activities);
          if (_.isObject(result.pagination)) {
            setPagination({
              total: result.pagination.total_count,
              current: result.pagination.current_page,
              pageSize: result.pagination.per_page || AppConfig.ordersPerPage,
            });
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    getRouteLog();
  };

  return (
    <div>
      {Array.isArray(dispatchLog) && dispatchLog.length > 0 ? (
        <RouteLogContentList
          data={dispatchLog}
          isLoading={isLoading}
          pagination={pagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(pagination, filter, sorter, currentTable)
          }
          isMilitaryTime={isMilitaryTime}
        />
      ) : (
        <RouteLogContentList data={[]} />
      )}
    </div>
  );
};