import _ from 'lodash';
import { Call, ApiUrl, HttpErrorMessage, PageParams, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import zoneStore from '../stores/ZoneStore';

export function fetchZones(page = 1, perPage = AppConfig.perPage) {
  const url = ApiUrl(`v1/zones?${PageParams(page, perPage)}`);
  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { zones: 'zones', pagination: 'meta.pagination' } },
  );
}

function ParseZonesResponse(success, response) {
  const result = { success };
  if (success) {
    result.zones = _.get(response, 'data.zones', []);
    zoneStore.setZones(result.zones);
  } else {
    result.errors = HttpErrorMessage(response);
  }
  return result;
}

export function fetchAllZones() {
  const cachedZones = zoneStore.getZones();
  if (cachedZones.length > 0) {
    return new Promise((resolve) => {
      resolve({ success: true, zones: cachedZones });
    });
  }
  const url = ApiUrl(`v1/zones?${PageParams(1, 10000)}`);
  return Call('get', url, {}, ParseZonesResponse);
}

export function saveZone(isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew ? ApiUrl('v1/zones') : ApiUrl(`v1/zones/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { zones: 'zones' } }, false);
}

export function deleteZone(id) {
  const url = ApiUrl(`v1/zones/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse);
}
