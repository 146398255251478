import React from "react";
import BaseList from "../../BaseList";
import {
  Col,
  Link,
  Row,
  Tooltip,
} from "../../../common/UIComponents";
import { checkNegitive, isEmpty } from "../../../common/Common";
import I18n from "../../../common/I18n";
import AppConfig from "../../../config/AppConfig";
import { checkServiceExistance } from "../../../helpers/common";

class CollectionsReportList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.columns = [
      {
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        key: "acc_code",
        width: "180px",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("account.account_name"),
        dataIndex: "account_name",
        key: "acc_name",
        width: "200px",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  <Tooltip title={data} placement="topLeft"> {data} </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("batch_management.total_billed_amount"),
        dataIndex: "total",
        key: "total",
        width: "90px",
        render: (data) => (
          <div className="paddingRight30" style={{ width: 130}}>{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions
      },
      {
        title: I18n.t("batch_management.balance"),
        dataIndex: "balance",
        key: "balance",
        width: "100px",
        render: (data) => (
          <div className="paddingRight30" style={{
            width: 130
          }}>{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions
      },
    ];
  }


  render() {
    return (
      <Row>
        <Col span={20} offset={2}>
      <BaseList
        columns={this.columns}
        {...this.props}
      />
      </Col>
      </Row>
    );
  }
}

export default CollectionsReportList;
