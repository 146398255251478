import React from 'react';
import PropTypes from "prop-types";
import { Col, Icon, Row, Spin } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { Text } from '../common/UIComponents';
import DeleteIcon from '../components/common/DeleteIcon';

function UploadFiles (props) {
  const { fileList, showUploadList, previewFile, disabled, customRequest, uploadText, uploadHint , isLoading , draggerOptions , isMultiUpload } = props;
  return (
    <Spin spinning={ isLoading } delay={ 1000 }>
      <Row>
        <Col xs={ 24 }>
          <Dragger
            { ...draggerOptions }
            fileList={ fileList }
            showUploadList={ showUploadList }
            previewFile={ previewFile }
            disabled={ disabled }
            customRequest={ customRequest }
            // multiple={ isMultiUpload ? true : false }
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              { uploadText }
            </p>
            <p className="ant-upload-hint">
              { uploadHint }
            </p>
          </Dragger>
          <div className='quoteDocsfileslist'>
          {fileList.length ?  fileList.map((file, index) => (
            <Row key={ index } className="" type='flex' gutter={2}>
              <Col>
                <Icon type="link" />
              </Col>
              <Col>{ file.name }</Col>
              <Col> 
              <DeleteIcon handleClick={() => props.deleteFile(index)}/>
              &nbsp;&nbsp;
              </Col>
            </Row>
          )) 
          : <Text>{"No Files"}</Text> }
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

export default UploadFiles;

UploadFiles.propTypes = {
  fileList: PropTypes.array,
  showUploadList: PropTypes.bool,
  previewFile: PropTypes.bool,
  disabled: PropTypes.bool,
  customRequest: PropTypes.func,
  uploadText: PropTypes.string,
  uploadHint: PropTypes.string,
  isLoading: PropTypes.bool,
  deleteFile: PropTypes.func,
  // isMultiUpload : PropTypes.bool,
};


UploadFiles.defaultProps = {
  fileList: [],
  showUploadList: false,
  previewFile: false,
  disabled: false,
  customRequest: () => {},
  uploadText: 'Click or drag file to this area to upload',
  uploadHint: 'Support for bulk orders upload.',
  isLoading: false,   
  // isMultiUpload : false,
  deleteFile: () => {},
};