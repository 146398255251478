import React from 'react';
import PropTypes from "prop-types";
import {TreeSelect} from "antd"
import Text from 'antd/lib/typography/Text';
import I18n from '../../common/I18n';
const { TreeNode } = TreeSelect;

function AgentCodeFilter (props) {
  const { SelectedAgentCodes, onValueChange , agents, size="small", element = 'agentIds', valueField="id", styleObject={}, showLabel = false } = props;
  return (
    <div className="AccountCodesFilterContainer">
      {showLabel && <Text className="marginRight5 textBold">{"Agent"}:</Text>}
      <TreeSelect
        value={ SelectedAgentCodes }
        style={ { width: '100%', ...styleObject } }
        showSearch
        size={size || "small"}
        className="minWidth"
        dropdownStyle={ {
          maxHeight: 300,
          overflow: "auto",
        } }
        placeholder="--Select--"
        allowClear
        treeDefaultExpandAll
        dropdownMatchSelectWidth={ false }
        onChange={ (e) =>
          onValueChange(element, e)
        }
        treeCheckable
        maxTagCount={ 1 }
        treeNodeFilterProp="title"
      >
        <TreeNode value="ALL" title="All" key="0-1">
          { agents.map((account) => (
            <TreeNode
              value={ account[valueField] }
              title={ `${account.name} (${account.code})` }
              key={ account.code }
            />
          )) }
        </TreeNode>
      </TreeSelect>
    </div>
  );
}

export default AgentCodeFilter;

AgentCodeFilter.propTypes = {
  SelectedAgentCodes: PropTypes.array,
  onValueChange: PropTypes.func,
  agents: PropTypes.array,
};

AgentCodeFilter.defaultProps = {
  SelectedAgentCodes: [],
  onValueChange: () => {},
  agents: [],
};