import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  Row,
  Icon,
  Form,
  Modal,
  DatePicker,
  Spin,
  Button,
  Col,
  FormItem,
} from "../../common/UIComponents";
import { withRouter } from "react-router";
import { updateInvGenDateManual } from "../../api/Billing";
import { alertMessage } from "../../common/Common";
import moment from "moment";
import { momentTime } from "../../helpers/date_functions";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";

const EditInvGenDate = (props) => {
  const { date, record, handleSuccess, timeZoneId } = props;
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [currentInvGenDate, setCurrentInvGenDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOkDate = () => {
    setLoading(true);
    const payload = {
      org_id: record.organization_id,
      new_date: moment(currentInvGenDate).format("YYYY-MM-DD"),
      account_invoice_id: record.id,
      wh_location_id: record.wh_location_id,
    };

    if (payload.new_date === "Invalid date") {
      alertMessage("Please select valid date", "error", 10);
      setInitialData();
      setLoading(false);
      return;
    }

    updateInvGenDateManual(payload).then((result) => {
      if (result.success) {
        alertMessage(result.message);
        setShowInfoWindow(false);
        handleSuccess(currentInvGenDate);
        setLoading(false);
      } else {
        renderAlertMessage(result.errors);
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setCurrentInvGenDate(null);
    setShowInfoWindow(false);
  };

  const setInitialData = () => {
    const dateTimeString = currentInvGenDate
      ? moment.tz(currentInvGenDate, timeZoneId)
      : moment.tz(date, timeZoneId);
    setCurrentInvGenDate(dateTimeString);
    setShowInfoWindow(true);
  };

  const renderModalWindow = () => {
    return (
      <Modal
        title={`Edit Invoice Generated Date ${
          record?.frequency_invoice_number
            ? ` of #${record.frequency_invoice_number}`
            : ""
        }`}
        visible={showInfoWindow}
        onOk={handleOkDate}
        onCancel={handleCancel}
        bodyStyle={{ padding: 10 }}
        width={450}
        maskClosable={false}
        footer={null}
      >
        <Spin spinning={loading} delay={1000}>
          <Form onFinish={handleOkDate}>
            <Row type="flex" gutter={4} align="middle" justify="center">
              <Col sm={14} xs={14} md={14} lg={14}>
                <FormItem label={I18n.t("invoices.invoice_generated_date")}>
                  <DatePicker
                    format="DD MMM, YYYY"
                    value={
                      currentInvGenDate
                        ? moment(currentInvGenDate)
                        : moment(date)
                    }
                    onChange={(date) => setCurrentInvGenDate(date)}
                    style={{ width: "100%" }}
                    allowClear={false}
                    size="small"
                  />
                </FormItem>
              </Col>
              <Col sm={10} xs={10} md={10} lg={10} style={{ marginTop: 7 }}>
                <Row type="flex" gutter={4} justify="center">
                  <Button key="cancel" onClick={handleCancel} size="small">
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button onClick={handleOkDate} type="primary" size="small">
                    {I18n.t("general.save")}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Tooltip title={I18n.t("general.edit")}>
        <Icon
          type="edit"
          style={{ color: "blue" }}
          onClick={() => setInitialData()}
        />
      </Tooltip>

      {showInfoWindow && renderModalWindow()}
    </Fragment>
  );
};

EditInvGenDate.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(EditInvGenDate);
