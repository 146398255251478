import React, { useState, useEffect, useContext } from "react";
import { Tooltip, Popconfirm, Typography, Button, Col, Input, Row, Checkbox, Icon } from "antd";
import { saveAgingReportConfigs } from "../../api/AgingReportConfig";
import { OrgContext } from "../../context/OrgContext";
import I18n from "../../common/I18n";
import { alertMessage } from "../../common/Common";

const AgingReportConfiguration = () => {
    const [ranges, setRanges] = useState([{ from: 0, to: "", isLast: false }]);
    const [agingReportConfig, setAgingReportConfig] = useState({});
    const orgContextData = useContext(OrgContext);
    const { currentOrg, setOrgSettings, orgSettings } = orgContextData;
    const [showAddIcon, setShowAddIcon] = useState(false);

    const getAgingReportConfigurations = () => {
        setAgingReportConfig({
            aging_report_config: orgContextData.orgSettings?.aging_report_display_config ? orgContextData.orgSettings.aging_report_display_config : ""
        });
    }

    useEffect(() => {
        try {
            const config = agingReportConfig.aging_report_config;
            const parsedValues = JSON.parse(config).map((item, index) => ({
                from: index === 0 ? 0 : item[0],
                to: item.length > 1 ? item[1] : "",
                isLast: item.length === 1
            }));
            setRanges(parsedValues);
            const lastItem = parsedValues[parsedValues.length - 1];
            const hasFrom = typeof lastItem.from === 'number';
            const hasTo = typeof lastItem.to === 'number';
            setShowAddIcon(hasFrom && hasTo);
        } catch (error) {
            console.log("Failed to parse aging report configuration:", error);
        }
    }, [agingReportConfig]);

    useEffect(() => {
        getAgingReportConfigurations();
    }, []);

    const handleFromChange = (index, value) => {
        if (index === 0) return;
        const updatedRanges = [...ranges];
        let newValue = value === "" ? "" : parseInt(value, 10); 
        updatedRanges[index].from = newValue;
        setRanges(updatedRanges);
        checkLastRange();
    };

    const handleToChange = (index, value) => {
        const updatedRanges = [...ranges];
        updatedRanges[index].to = value;

        if (index < updatedRanges.length - 1) {
            const nextFromValue = value !== "" ? parseInt(value, 10) + 1 : "";
            updatedRanges[index + 1].from = nextFromValue;
        }

        setRanges(updatedRanges);
        checkLastRange();
    };

    const handleAddRow = () => {
        const lastRange = ranges[ranges.length - 1];
        const lastToValue = lastRange.to;
        const lastFromValue = lastRange.from;

        if (lastToValue === "" || isNaN(lastToValue)) {
            alertMessage("Enter valid range of data", "error");
            return;
        }

        if (lastFromValue > lastToValue) {
            alertMessage("Enter valid range of data", "error");
            return;
        }

        const newFromValue = parseInt(lastToValue, 10) + 1;

        if (ranges.length > 1) {
            const previousToValue = parseInt(ranges[ranges.length - 2].to, 10);
            if (previousToValue + 1 !== lastFromValue) {
                alertMessage("Enter valid range of data", "error");
                return;
            }
        }

        setRanges([...ranges, { from: newFromValue, to: "", isLast: false }]);
    };

    const checkLastRange = () => {
        const lastRange = ranges[ranges.length - 1];
        setShowAddIcon(lastRange && !isNaN(lastRange.to) && lastRange.to !== "");
    };

    const handleDelete = (index) => {
        const updatedRanges = ranges.filter((item, idx) => idx !== index);
        setRanges(updatedRanges);
        setShowAddIcon(true);
    };


    const handleCheckboxChange = (index, checked) => {
        const updatedRanges = [...ranges];
        const lastRange = updatedRanges[index];

        if (!lastRange) return;

        if (checked) {
            const toValue = parseInt(lastRange.to, 10);

            if (lastRange.to === "" || isNaN(toValue)) {
                alertMessage("Enter valid range of data","error");
                return;
            }

            lastRange.isLast = false;
            lastRange.to = toValue;

            const newFromValue = toValue + 1;
            const newRange = { from: newFromValue, to: "", isLast: true };
            updatedRanges.push(newRange);
        } else {
            updatedRanges.pop();
            updatedRanges[updatedRanges.length - 1].isLast = true;
        }

        setRanges(updatedRanges);
        setShowAddIcon(false);
    };

    const handleSave = () => {
        const isValid = ranges.every((range, index) => {
            if (index === ranges.length - 1 && range.isLast) {
                return range.from !== "";
            }
            return range.from !== "" && range.to !== "";
        });

        if (!isValid) {
            alertMessage("Fields cannot be empty", "error");
            return;
        }

        for (let i = 1; i < ranges.length; i++) {
            if (parseInt(ranges[i].from, 10) !== parseInt(ranges[i - 1].to, 10) + 1) {
                alertMessage("Enter valid range of data", "error");
                return;
            }
        }

        const isRangeValid = ranges.every((range, index) => {
            if (range.to !== "") {
                if (parseInt(range.from, 10) > parseInt(range.to, 10)) {
                    alertMessage("Enter valid range of data", "error");
                    return false;
                }
            }
            return true;
        });

        if (!isRangeValid) {
            return;
        }

        for (let i = 0; i < ranges.length - 1; i++) {
            if (parseInt(ranges[i].to, 10) + 1 !== parseInt(ranges[i + 1].from, 10)) {
                alertMessage("Enter valid range of data", "error");
                return;
            }
        }

        const validRanges = ranges.every((range, index) => {
            const { from, to, isLast } = range;
            if (isLast) {
                return index === ranges.length - 1 || (to !== "" && !isNaN(to));
            }
            return to !== "" && !isNaN(to) && from <= to;
        });

        if (!validRanges) {
            alertMessage("Enter valid range of data", "error");
            return;
        }

        if (!ranges[ranges.length - 1]?.isLast) {
            alertMessage("Please mark the Final Range", "error");
            return;
        }

        const settingValue = ranges.map(range => {
            if (range.isLast) {
                return [range.from];
            } else {
                return [range.from, isNaN(range.to) ? null : parseInt(range.to, 10)];
            }
        });

        const payload = {
            setting_name: "aging_report_display_config",
            organization_id: currentOrg.id,
            setting_value: JSON.stringify(settingValue),
        };

        saveAgingReportConfigs(payload, currentOrg.id).then((result) => {
            if (result.success) {
                alertMessage("Saved successfully", "success");
                setOrgSettings({
                    ...orgSettings,
                    aging_report_display_config: JSON.stringify(settingValue),
                });
            } else {
                alertMessage("Something went wrong", "error");
            }
        });
    };

    const getNextCheckboxLabel = () => {
        const lastRange = ranges[ranges.length - 1];
        const nextValue = lastRange && !isNaN(lastRange.to) ? parseInt(lastRange.to, 10) + 1 : '';
        return `${nextValue} or above`;
    };

    return (
        <>
            <Row>
                <Col span={10}>
                    <div style={{ maxHeight: "700px" }}>
                        <div className="settingHeader">
                            <span className="textBold">Aging Report Configuration</span>
                        </div>
                        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                            <Col span={8}>
                                <strong style={{ paddingLeft: "15px" }}>From Days</strong>
                            </Col>
                            <Col span={8}>
                                <strong style={{ paddingLeft: "15px" }}>To Days</strong>
                            </Col>
                        </Row>

                        {ranges.map((range, index) => (
                            <Row gutter={[16, 16]} key={index} align="middle">
                                {!range.isLast && (
                                    <Col span={8}>
                                        <Input 
                                        value={range.from}
                                            placeholder={"From"}
                                        onChange={(e) => handleFromChange(index, e.target.value)}
                                            disabled={index === 0}
                                        />
                                    </Col>
                                )}
                                <Col span={index === ranges.length - 1 && ranges[index]?.isLast ? 16 : 8}>
                                    <Input
                                        value={range.isLast ? `${parseInt(ranges[index - 1]?.to || 0, 10) + 1} or above` : range.to}
                                        placeholder={"To"}
                                        onChange={(e) => handleToChange(index, e.target.value)}
                                        disabled={range.isLast}
                                    />
                                </Col>
                                <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                    {index === ranges.length - 1 && index !== 0 && (
                                        <Tooltip title={I18n.t("general.delete")}>
                                            <Popconfirm
                                                placement="left"
                                                title={I18n.t('messages.confirm')}
                                                onConfirm={() => handleDelete(index)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Icon
                                                    type="delete"
                                                    style={{ color: "red", marginLeft: 10, fontSize: 18 }}
                                                />
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                    {index === ranges.length - 1 && showAddIcon && (
                                        <Tooltip title={I18n.t("general.add")}>
                                            <Icon
                                                type="plus-circle"
                                                style={{ color: "black", marginLeft: 10, fontSize: 18 }}
                                                onClick={handleAddRow}
                                            />
                                        </Tooltip>
                                    )}
                                </Col>
                            </Row>
                        ))}
                        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                            {showAddIcon && ranges[ranges.length - 1]?.to !== "" && !isNaN(ranges[ranges.length - 1]?.to) && (
                                <Col span={8}>
                                    <Checkbox
                                        checked={ranges[ranges.length - 1]?.isLast || false}
                                        onChange={(e) => handleCheckboxChange(ranges.length - 1, e.target.checked)}
                                    >
                                        <span className="textBold">{getNextCheckboxLabel()}</span>
                                    </Checkbox>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Button type="primary" onClick={handleSave}>
                <Icon type="save" />
                Save
            </Button>
            <Col xs={24} className="marginTop20 alignLeft notes_text">
                <i>
                    <Typography.Paragraph>
                        <b>
                            <span className="textBold">Note</span>
                        </b>
                        : {'Ensure that the "Days From" and "Days To" values of each record are consecutive. This ensures the accurate representation of aging periods in the report.'}
                    </Typography.Paragraph>
                </i>
            </Col>
        </>
    );
};

export default AgingReportConfiguration;