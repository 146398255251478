import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Select,
  Icon,
  Alert,
  Button,
} from "antd";
import _, { set } from "lodash";
import { Card } from "../../common/UIComponents";
import CopyableContent from "../common/CopyableContent";
import { BatchManagementApi } from "../../api/BatchManagement";
import { alertMessage } from "../../common/Common";

export const AccountSharingForm = (props) => {
  const {
    accountSharingData,
    accountsForFilter,
    context,
    refreshList,
    setShowAccountSharingModal,
  } = props;
  const decimalPoints = parseInt(
    window.localStorage.getItem("round_off_decimals")
  );
  const refinedAccountForFilter = accountsForFilter.filter(
    (account) => account.id !== accountSharingData.account_id
  );
  const [formData, setFormData] = useState([
    { id: 0, account_id: null, amount: null },
  ]);
  const [error, setError] = useState(null);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const checkId = _.get(accountSharingData, "id", null);
  const formDataLength = formData.length;
  const [totalAmount, setTotalAmount] = useState(0);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    const total = formData.reduce(
      (total, data) => total + (parseFloat(data.amount) || 0),
      0
    );
    setTotalAmount(total);
  }, [formData]);

  useEffect(() => {
    // here disable the save button if the total amount is greater than the check balance amount
    const checkBalanceAmount = parseFloat(accountSharingData.balanced_amount);
    if (totalAmount > checkBalanceAmount) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
  }, [totalAmount, accountSharingData.balanced_amount]);

  const handleChange = useCallback((field, value, id) => {
    setFormData((prevFormData) => {
      return prevFormData.map((data) => {
        if (data.id === id) {
          return { ...data, [field]: value };
        }
        return data;
      });
    });
    setError(null);
    setDisableAddButton(false);
    setDisableSaveButton(false);
  }, []);
  const addFormData = useCallback(() => {
    const newId =
      formData.length > 0 ? formData[formData.length - 1].id + 1 : 0;
    const totalAmount = formData.reduce(
      (total, data) => total + (parseFloat(data.amount) || 0),
      0
    );
    const checkBalanceAmount = parseFloat(accountSharingData.balanced_amount);
    const proposedAmount = parseFloat(
      formData.find((data) => data.id === newId)?.amount || 0
    );

    if (totalAmount + proposedAmount >= checkBalanceAmount) {
      setError(
        "Transfer amount should be less / equal than check balance amount.!"
      );
      setDisableAddButton(true);
      return;
    }

    setFormData((prevFormData) => [
      ...prevFormData,
      { id: newId, account_id: null, amount: null },
    ]);
  }, [formData, accountSharingData]);

  const deleteFormData = useCallback(
    (id) => {
      setFormData((prevFormData) => {
        const newFormData = prevFormData.filter((data) => data.id !== id);
        const totalAmount = newFormData.reduce(
          (total, data) => total + (parseFloat(data.amount) || 0),
          0
        );
        const checkBalanceAmount = parseFloat(
          accountSharingData.balanced_amount
        );

        if (totalAmount < checkBalanceAmount) {
          setError(null);
          setDisableAddButton(false);
          setDisableSaveButton(false);
        }

        return newFormData;
      });
    },
    [accountSharingData]
  );

  const getSelectedAccountIds = () => {
    return formData.map((data) => data.account_id);
  };

  const renderSaveButton = () => {
    return (
      <Row justify="start" type="flex">
        <Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={
              (formData.length === 1 &&
                !formData[0].account_id &&
                !formData[0].amount) ||
              disableSaveButton
            }
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  };

  const handleSubmit = () => {
    const refinedFormData = formData.map(({ id, ...rest }) => rest);
    const payload = {
      organization_id: context.currentOrg.id,
      user_id: context.currentUser.id,
      check_id: checkId,
      account_details: refinedFormData,
    };
    BatchManagementApi.accountSharing(payload).then((response) => {
      if (response.success) {
        alertMessage(response.data.message, "success", 10);
        setShowAccountSharingModal(false);
        refreshList();
      } else {
        alertMessage(response.errors, "error", 10);
      }
    });
  };

  const accountDetails = () => {
    const checkDetails = _.get(accountSharingData, "check_ach_no", "NA");
    const accountCode = _.get(accountSharingData, "account_code", "NA");
    const accountName = _.get(accountSharingData, "account_name", "NA");
    const concatedAccount = `${accountName}  (${accountCode})`;
    const checkBalanceAmount = _.get(
      accountSharingData,
      "balanced_amount",
      "NA"
    );
    const amountReceived = _.get(accountSharingData, "received_amount", "NA");
    const paidAmount = _.get(accountSharingData, "paid_amount", "NA");
    const formatedCheckBalanceAmount =
      parseFloat(checkBalanceAmount).toFixed(decimalPoints);

    return (
      <>
        <Row type="flex" gutter={8}>
          <Card
            className="customCard checkFormCard"
            bordered={false}
            bodyStyle={{ padding: 5, borderRadius: 10 }}
          >
            <Descriptions title="Check Details" size="small">
              <Descriptions.Item label={<strong>Check / Ach Details</strong>}>
                {checkDetails !== "NA" && (
                  <CopyableContent text={checkDetails} />
                )}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Account</strong>}>
                {concatedAccount}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Amount Received</strong>}>
                {amountReceived}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Amount Applied</strong>}>
                {paidAmount}
              </Descriptions.Item>
              <Descriptions.Item label={<strong>Check Balance</strong>}>
                {formatedCheckBalanceAmount}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Row>
      </>
    );
  };

  return (
    <div>
      {accountDetails()}
      <br />
      {error && <Alert message={error} type="error" />}
      <br />
      <Row justify="start" type="flex">
        <Col>
          <h3>Transfer Amount: {totalAmount.toFixed(decimalPoints)}</h3>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ width: "100%" }}
        gutter={16}
        className="textBold"
      >
        <Col xs={24} sm={12} md={8} lg={6}>
          Account
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} className="disableArrows">
          Amount ($)
        </Col>
      </Row>
      <div className="address-form-item">
        {formData.map((data, index) => (
          <FormFields
            key={data.id}
            data={data}
            handleChange={handleChange}
            addFormData={addFormData}
            deleteFormData={deleteFormData}
            accountsForFilter={refinedAccountForFilter}
            selectedAccountIds={getSelectedAccountIds()}
            isLastItem={index === formData.length - 1}
            disableAddButton={disableAddButton}
            formDataLength={formDataLength}
          />
        ))}
        {renderSaveButton()}
      </div>
    </div>
  );
};

const FormFields = ({
  data,
  handleChange,
  addFormData,
  deleteFormData,
  accountsForFilter,
  selectedAccountIds,
  isLastItem,
  disableAddButton,
  formDataLength,
}) => {
  const isFormFilled = !_.isEmpty(data.account_id) && !_.isEmpty(data.amount);

  const renderDeleteIcon = () => {
    // Render delete icon only if there are more than one records in formData or if data.id is not 0
    if (formDataLength > 1 || data.id !== 0) {
      return (
        <Icon
          type="delete"
          style={{
            fontSize: 20,
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => deleteFormData(data.id)}
        />
      );
    }
    return null;
  };

  return (
    <Form>
      <Row justify="center" style={{ width: "100%" }} gutter={16}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item style={{ width: "100%" }}>
            <Select
              value={data.account_id}
              onChange={(value) => handleChange("account_id", value, data.id)}
              style={{ width: "100%" }}
              dropdownMatchSelectWidth={false}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value={null}>---Select----</Select.Option>
              {accountsForFilter
                .filter(
                  (account) =>
                    !selectedAccountIds.includes(account.id) ||
                    account.id === data.account_id
                )
                .map((account) => (
                  <Select.Option key={account.id} value={account.id}>
                    {`${account.name} (${account.code})`}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} className="disableArrows">
          <Form.Item style={{ width: "100%" }}>
            <Input
              type="number"
              value={data.amount}
              onChange={(e) => handleChange("amount", e.target.value, data.id)}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={6}
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Form.Item>
            {isLastItem && (
              <Icon
                type="plus-circle"
                style={{
                  fontSize: 20,
                  marginRight: 8,
                  color:
                    isFormFilled && !disableAddButton
                      ? "rgb(209 137 41)"
                      : "gray",
                  cursor:
                    isFormFilled && !disableAddButton
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={isFormFilled && !disableAddButton ? addFormData : null}
              />
            )}
            {renderDeleteIcon()}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
