import React, { Fragment } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Button,
  Row,
  Col,
  Tooltip
} from "antd";
import PropTypes from "prop-types";
import { randomUUID } from "../../common/Common";
import { confirmationPopup } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import I18n from "../../common/I18n";

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = (form, record) => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return (
      <Input onPressEnter={() => this.props.handleSave(form, record.key)} />
    );
  };

  renderCell = (form) => {
    const { getFieldDecorator } = form;
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput(form, record))}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      editingKey: "",
    };
    this.columns = [
      {
        title: "Key",
        dataIndex: "config_key",
        width: "40%",
        editable: true,
      },
      {
        title: "Value",
        dataIndex: "config_value",
        width: "40%",
        editable: true,
      },
      {
        title: "",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <div className="actionsWrapper line-actions">
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {(form) => (
                      <Tooltip title={I18n.t("general.save")}>
                      <Icon
                        type="save"
                        onClick={() => this.save(form, record.key)}
                        style={{ marginRight: 8, color: "#1890ff" }}
                      />
                      </Tooltip>
                    )}
                  </EditableContext.Consumer>
                  <Popconfirm
                    title="Sure to cancel?"
                    onConfirm={() => this.cancel(record.key)}
                  >
                  <Tooltip title={I18n.t("general.cancel")}>
                    <Icon
                      type="close"
                      style={{
                        color: "red",
                      }}
                    />
                    </Tooltip>
                  </Popconfirm>
                </span>
              ) : (
                <span>
                  <Tooltip title={I18n.t("general.edit")}>
                    <span><EditIcon handleClick={() => this.edit(record.key)}/></span>
                  </Tooltip>
                  <Popconfirm
                    title="Sure to Delete?"
                    onConfirm={() => this.delete(record.key)}
                  >
                    <Tooltip title={I18n.t("general.delete")}>
                      <span><DeleteIcon/></span>
                    </Tooltip>
                  </Popconfirm>
                </span>
              )}
            </div>
          );
        },
      },
    ];
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data && this.props.data.length) {
      this.setState({
        data: this.props.data,
      });
    }
    // if editingKey is changed, props call enableEdit function
    if (prevState.editingKey !== this.state.editingKey) {
      if (this.state.editingKey.length > 0) {
        this.props.toggleConfigTableEdit(true);
      } else {
        this.props.toggleConfigTableEdit(false);
      }
    }
  }

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    // if the editing key has empty config_key  and config_value then delete the row if its new
    const { data } = this.state;
    const newData = data.filter((item) => {
      if (item.key === this.state.editingKey) {
        if (item.config_key === "" && item.config_value === "" && item.isNew) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    this.setState({ data: newData, editingKey: "" });
    this.props.updateIntergrationConfig(newData);
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        // update
        const item = newData[index];
        delete item.isNew;
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });
      } else {
        // new row
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
      this.props.updateIntergrationConfig(newData);
    });
  }
  onEditConfirm = (key) => {
    const { editingKey, data } = this.state;
    const newData = data.filter((item) => item.key !== editingKey);
    this.setState({ data: newData, editingKey: key });
  };

  removeNewRecords = (key) => {
    const nonNewRecords = this.state.data.filter((item) => !item.isNew);
    this.setState({ data: nonNewRecords, editingKey: key });
    this.props.updateIntergrationConfig(nonNewRecords);
  };

  edit(key) {
    if (this.state.editingKey.length) {
      const confirmationInfo = {
        title: I18n.t("general.are_you_sure"),
        content: I18n.t("messages.unsaved_changes"),
        onConfirm: () => {
          this.removeNewRecords(key);
        },
      };
      confirmationPopup(confirmationInfo);
    } else {
      this.setState({ editingKey: key });
    }
  }
  delete(key) {
    const { data } = this.state;
    const newData = data.filter((item) => item.key !== key);
    this.setState({ data: newData, editingKey: "" });
    this.props.updateIntergrationConfig(newData, "delete");
  }
  addNewRow = () => {
    const randomId = randomUUID();
    const newData = {
      key: `${randomId}`,
      config_value: "",
      config_key: "",
      isNew: true,
    };
    this.setState({
      data: [...this.state.data, newData],
      editingKey: newData.key,
    });
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          handleSave: this.save.bind(this),
        }),
      };
    });

    return (
      <Fragment>
        <Row
          gutter={16}
        >
          <Col xs={12}>
            <h4>
              {I18n.t("integrations.configuration")}
              <sup className="textRed">*</sup>
            </h4>{" "}
          </Col>
          <Col xs={12} className="alignRight">
            <Button
              type="primary"
              icon="plus"
              onClick={this.addNewRow}
              style={{
                marginLeft: "auto",
              }}
              size="small"
            >
              Add Config
            </Button>
          </Col>
        </Row>

        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            bordered
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            loading={this.props.loading}
            size="small"
            rowKey={(record) => record.key}
          />
        </EditableContext.Provider>
      </Fragment>
    );
  }
}

const IntegrationConfigTable = Form.create()(EditableTable);
export default IntegrationConfigTable;

IntegrationConfigTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  updateIntergrationConfig: PropTypes.func,
  toggleConfigTableEdit: PropTypes.func,
};

IntegrationConfigTable.defaultProps = {
  data: [],
  loading: false,
  updateIntergrationConfig: () => {},
  toggleConfigTableEdit: () => {},
};
