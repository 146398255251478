import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils'

export function setAccountSetting(data) {
  data.operation_code = 'ACNF'
  // const url = ApiUrl(`v2/account/update_setting`);
  const url = ApiUrl('/v2/account/bulk_update_account_configs')
  return Call('put', url, data, ParseGeneralResponse, {
    responseDataKeys: { acct_config: '' },
  })
}
