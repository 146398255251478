import React from 'react';
import BaseList from '../BaseList';
import {compareString} from '../../common/Common';
import I18n from '../../common/I18n';
import { Icon, Popconfirm } from '../../common/UIComponents';
import DeleteIcon from '../common/DeleteIcon';
import EditIcon from '../common/EditIcon';

class ZonesList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
        sorter: (a, b) => compareString(a.name,b.name)
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: id => (
          <div className='line-actions'>
            {/* <Icon
              type='edit'
              onClick={() => this.props.editClick(id)}
            /> */}
            <EditIcon handleClick={() => this.props.editClick(id)}/>
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.delete_confirm')}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon/>
              &nbsp;&nbsp;&nbsp;
            </Popconfirm>
          </div>
        ),
        width: '75px',
      },
    ];
  }
}

export default ZonesList;
