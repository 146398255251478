import React, { useEffect, useState } from "react";
import { Transfer,Row, Alert, Col } from "antd";
import userStore from "../../stores/UserStore";
import { copyAccount } from "../../api/Account";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";

const AccountCopy = (props) => {
  const { accounts } = props;
  const [isLoading , setIsLoading] = useState(false);
  const [ targetKeys, setTargetKeys ] = useState([]);
  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const [ accountsList, setAccountsList ] = useState([]);
  const [ accountCopyInfoText, setAccountCopyInfoText ] = useState("");



  useEffect(() => {
    const modifiedAccnts = accounts.map((i) => {
      return [
        { ...i, pos: "source", key: i.id + "source", disabled: false },
        { ...i, pos: "target", key: i.id + "target", disabled: false },
      ];
    })
      .flat();

    setAccountsList(modifiedAccnts);
  }, [ accounts ]);

  useEffect(() => {
    const oriTargetKeys = accountsList
      .filter((i) => i.pos === "target")
      .map((item) => item.id + "target");
    setTargetKeys(oriTargetKeys);
  }, [ accountsList ]);

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    const targetKey = selectedKeys.find((i) => i.includes("target"));
      if(!targetKey){
      setAccountCopyInfoText("Please select at least one account to copy");
      setSelectedKeys(selectedKeys)
      return;
    }
    setAccountCopyInfoText("");
    setIsLoading(true);
    const payload = {
      organization_id: userStore.getStateValue("selectedOrg"),
      account_id: selectedKeys.find((i) => i.includes("target")).split("target")[0],
      d_account_ids: moveKeys.map((i) => i.split("source")[ 0 ]),
    };
    copyAccount(payload).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.successfully_done", { process: I18n.t("account.account_copy") }), 'success', 5);
        setSelectedKeys([]);
        setAccountsList(accountsList.map((i) => { return { ...i, disabled: false }; }));
      } else {
        setAccountCopyInfoText(result.message);
      }
    }).finally(() => { setIsLoading(false); });
  };
  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    // only single selection should be on targetKeys, so if targetKeys isnt empty , we should change disabled all other target
    if (targetSelectedKeys.length) {
      const newAccountsList = accountsList.map((item) => {
        if (item.pos === "source") {
          // disable source which match target
          if(item.id + "target" === targetSelectedKeys[ 0 ]){
            return { ...item, disabled: true };
          }
          return item;
        }
        if (targetSelectedKeys.includes(item.id + "target")) {
          return { ...item, disabled: false };
        }
        return { ...item, disabled: true };
      });
      setAccountsList(newAccountsList);
    } else {
      // remove disabled from all target
      const newAccountsList = accountsList.map((item) => {
        if (item.pos === "source") {
          return { ...item, disabled: false };
        }else if(item.pos === "target" && sourceSelectedKeys.includes(item.id + "source")){
            return { ...item, disabled: true };
        }
        return { ...item, disabled: false };
      });
      setAccountsList(newAccountsList);
    }
    if (sourceSelectedKeys.length && targetSelectedKeys.length) {
      const infoText = sourceSelectedKeys.map((key) => {
        const account = accountsList.find((i) => i.id + "source" === key);
        return account.code;
      }).join(", ") + " settings will be copied from " + targetSelectedKeys.map((key) => { const account = accountsList.find((i) => i.id + "target" === key); return account.code; }).join(", ");
      setAccountCopyInfoText(infoText);
    }else{
      setAccountCopyInfoText("");
    }
    setSelectedKeys([ ...sourceSelectedKeys, ...targetSelectedKeys ]);
  };

  return (
    <Row className="accountTransfer" >
      <Col>
        <Transfer
          dataSource={ accountsList }
          titles={ [ "Select accounts to clone", "Source account" ] }
          operations={ [ "Clone Settings" ] }
          listStyle={ {
            width: 250,
            height: 300,
          } }
          disabled={ isLoading }
          targetKeys={ targetKeys }
          selectedKeys={ selectedKeys }
          onChange={ handleChange }
          onSelectChange={ handleSelectChange }
          render={ (item) => `${item.name} (${item.code})`}
          showSearch
          filterOption={ (inputValue, item) => {
            return item.code.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
          }}
          
        />
      </Col>
      { accountCopyInfoText.length > 0 && (
        <Col>
          <Alert message={accountCopyInfoText } type="info" showIcon />
        </Col>
      ) }
    </Row>
  );
};

export default AccountCopy;

AccountCopy.propTypes = {};
AccountCopy.defaultProps = {};