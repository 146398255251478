/* eslint-disable no-underscore-dangle */
import React, { Component, useContext } from "react";
import _ from "lodash";
import {
  Button,
  Col,
  Row,
  Spin,
  Tabs,
  Icon,
  Input,
  Modal,
  Checkbox,
  Search,
  Skeleton,
  Tooltip,
} from "../common/UIComponents";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import userStore from "../stores/UserStore";
import { fetchAccount, fetchAccountBillingConfigs } from "../api/Account";
import ZoneConfig from "../components/configurations/zones/ZoneConfig";
import AccessorialSettings from "../components/configurations/AccessorialSettings";
import LocationMap from "../components/configurations/MapZipCodes";
import OrgnisationForm from "../components/organizations/LookForm";
import { checkServiceExistance, renderAlertMessage } from "../helpers/common";
import DriverRateConfig from "../components/configurations/DriverRateConfig";
import OrgIntegrations from "../components/configurations/OrgIntegrations/OrgIntegrations";
import OrderSettings from "../components/configurations/OrderSettings";
import EventSettings from "../components/configurations/EventSettings";
import moment from 'moment';
import BillingEventSettings from "../components/configurations/BillingEventSettings";
import LOSAdjustmentSettings from "./LOSAdjustmentSettings";
import FuelSurchargeSettings from "../components/configurations/FuelSurchargeSettings";
import BillingAccounts from "../components/configurations/billing_accounts/BillingAccounts";
import OrgRules from "../components/configurations/OrgRules";
import { withRouter } from "react-router";
import { DisplaySettings } from "./DisplaySettings";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import OrgBilling from "../components/configurations/OrgBilling";
import RejectionReason from "../components/configurations/RejectionReason";
import OrgBillingTabs from "../components/configurations/OrganizationBillings";
import { MenuRenderingSetings } from "../components/configurations/MenuRenderingSetings";
import AccessorialComponent from "../components/configurations/accessorials/Accessorials";
import AccountRules from "../components/configurations/AccountRules";


const { TabPane } = Tabs;
const { confirm } = Modal;

class Configurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      showWarningBlock: true,
      surveys: [],
      config: {},
      previousConfig: {},
      accountId: props.match.params.accountId || "",
      account: {},
      podPicConfig: {
        pic_code: "",
        mandatory_for: [],
      },
      activeKey: "1",
      activeConfigKey: "general_setting",
      activeBillingKey: "billing_setting",
      organizationSettings: {},
      billingConfigs :[]
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
      });
    }
  }
  componentDidMount() {
    let params = new URL(window.location.href).searchParams;
    let pathName = this.props.location.pathname
    let accScreen = pathName.includes("account") ? true : false;
    const tab = params.get("tab");
    if (tab) {
      this.setState({ activeKey: tab });
    }
    this.getAccountDetails();
    if (accScreen) {
      this.getAccountConfigs();
    }
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
    });
  }

  getAccountConfigs = () => {
    this.setState({ inProgress: true });
    const orgId = userStore.getStateValue("selectedOrg");
    fetchAccountBillingConfigs(orgId, this.state.accountId).then((result) => {
      if (result.success) {
        const accountConfigurations = result.configurations || [];
        const formedConfigs = {};
        accountConfigurations
          .filter((settingObj) => settingObj.config_type === "BILLING")
          .forEach((setting) => {
            formedConfigs[setting.setting_name] = setting.setting_value;
          });
        this.setState({ billingConfigs: formedConfigs, inProgress: false });
      } else {
        renderAlertMessage(result.errors);
        this.setState({ inProgress: false });
      }
    });
  };

  getAccountDetails = () => {
    this.setState({ inProgress: true });
    const { accountId } = this.state;
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        this.setState({
          account: Object.assign({}, this.state.account, result.account),
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  gotoAccounts = () => {
    this.props.history.push("/accounts");
  };

  onSearch = (token) => {
    if (!isEmpty(token)) {
      this.props.history.push({
        pathname: "/accounts",
        token,
      });
    }
  };

  goBack = (screenFrom = "") => {
    if (!isEmpty(screenFrom)){
      const pathname = screenFrom.startsWith('/') ? screenFrom : `/${screenFrom}`;
      this.props.history.push({
        pathname,
        calledFrom: "otherScreen"
      });
    }
  };

  updateBillingConfigs = (billingConfigs) => {
    this.setState({ billingConfigs });
  }

  _renderHeader = () =>{
  const screenFrom = this.props?.location?.state?.screenFrom ? this.props.location.state.screenFrom : "";
  return (
    <Row className="page-header">
      <Col md={12}>
        {this.state.accountId === "" ? (
          I18n.t("configurations.header_title")
        ) : (
          <div>
            {!isEmpty(screenFrom) && (
              <span
                onClick={() => this.goBack(screenFrom)}
                style={
                  {
                    height: 20,
                    width: 30,
                    cursor: "pointer",
                    marginTop: -2,
                    marginRight: 4,
                  }
                }
              >
                <Icon type="arrow-left" style={{ cursor: "pointer" }} />
              </span>
            )}
            <span
              className="breadCrumbLink"
              onClick={() => this.gotoAccounts()}
            >
              {I18n.t("menu.account")}
            </span>
            &nbsp; /&nbsp;
            <span>
              {this.state.account.name}
                { this.state.account.code ? (
                  <span>
                    { " " }
                    ({ this.state.account.code }
                    {/* <Tooltip
                      title={ I18n.t("order.account_limit_exceeded") }
                      placement="left"
                      key="accountLimitExceeded"
                    >
                      <Icon
                        type="info-circle"
                        style={ {
                          color: this.props.accountCodesWithExceededLimit.includes(
                            this.state.account.code
                          )
                            ? "red"
                            : "inherit",
                        } }
                      />
                    </Tooltip> */}
                  )
                </span>
              ) : (
                ""
              )}
              &nbsp;{I18n.t("configurations.settings")}
            </span>
          </div>
        )}
      </Col>
      <Col md={6} className="page-actions" />
      <Col md={6} className="page-actions">
        {!isEmpty(this.state.accountId) && (
          <Search
            size="default"
            placeholder={`${I18n.t(`general.search`)} ${I18n.t(
              "menu.account"
            )}`}
            onSearch={this.onSearch}
            allowClear
          />
        )}
      </Col>
    </Row>
  );};

  handleTimeChange = (element, value) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: !isEmpty(value) ? moment(value).format("HH:mm") : "",
      }),
    });
  };

  _renderPostDeliveryConfig = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">Proof Of Delivery Configuration</span>
        </div>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={11}>
              {I18n.t("configurations.pod_delivery_item_pictures_lable")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button
                className={
                  this.state.config.pod_delivery_item_pictures &&
                  this.state.config.pod_delivery_item_pictures ===
                    I18n.t("common.true")
                    ? "btnConfig btnConfigActive"
                    : "btnConfig"
                }
                onClick={() =>
                  this.handleCheckBtn(
                    I18n.t("configurations.pod_delivery_item_pictures"),
                    I18n.t("common.true")
                  )
                }
              >
                Yes
              </Button>
              <Button
                className={
                  this.state.config.pod_delivery_item_pictures &&
                  this.state.config.pod_delivery_item_pictures ===
                    I18n.t("common.false")
                    ? "btnConfig btnConfigActive"
                    : "btnConfig"
                }
                onClick={() =>
                  this.handleCheckBtn(
                    I18n.t("configurations.pod_delivery_item_pictures"),
                    I18n.t("common.false")
                  )
                }
              >
                No
              </Button>
            </Col>
          </Row>
          {this.state.config.pod_delivery_item_pictures ===
            I18n.t("common.true") && (
            <>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.pod_picture_settings")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
              </Row>
              <Row>
                {this._renderAllPodPicConfigs()}
                {this._renderPodPicSettings()}
              </Row>
            </>
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={11}>
              {I18n.t("configurations.pod_signature_label")}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button
                className={
                  this.state.config.pod_signature &&
                  this.state.config.pod_signature === I18n.t("common.true")
                    ? "btnConfig btnConfigActive"
                    : "btnConfig"
                }
                onClick={() =>
                  this.handleCheckBtn(
                    I18n.t("configurations.pod_signature"),
                    I18n.t("common.true")
                  )
                }
              >
                Yes
              </Button>
              <Button
                className={
                  this.state.config.pod_signature &&
                  this.state.config.pod_signature === I18n.t("common.false")
                    ? "btnConfig btnConfigActive"
                    : "btnConfig"
                }
                onClick={() =>
                  this.handleCheckBtn(
                    I18n.t("configurations.pod_signature"),
                    I18n.t("common.false")
                  )
                }
              >
                No
              </Button>
            </Col>
          </Row>
          {this.state.config.pod_signature &&
          this.state.config.pod_signature === I18n.t("common.true") ? (
            <>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.signer_title_label")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Button
                    className={
                      this.state.config.is_signature_title_enabled &&
                      this.state.config.is_signature_title_enabled ===
                        I18n.t("common.true")
                        ? "btnConfig btnConfigActive"
                        : "btnConfig"
                    }
                    onClick={() =>
                      this.handleCheckBtn(
                        I18n.t("configurations.is_signature_title_enabled"),
                        I18n.t("common.true")
                      )
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    className={
                      this.state.config.is_signature_title_enabled &&
                      this.state.config.is_signature_title_enabled ===
                        I18n.t("common.false")
                        ? "btnConfig btnConfigActive"
                        : "btnConfig"
                    }
                    onClick={() =>
                      this.handleCheckBtn(
                        I18n.t("configurations.is_signature_title_enabled"),
                        I18n.t("common.false")
                      )
                    }
                  >
                    No
                  </Button>
                </Col>
              </Row>
              {this.state.config.is_signature_title_enabled &&
              this.state.config.is_signature_title_enabled ===
                I18n.t("common.true") ? (
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={11}>
                    {I18n.t("configurations.signer_title")}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Row type="flex" justify="space-around">
                    <Col>
                      <Input
                        className="inputConfig"
                        value={this.state.config.signature_label}
                        onChange={(e) => {
                          this.setState({
                            config: {
                              ...this.state.config,
                              signature_label: e.target.value,
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </Row>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
    </Row>
  );

  _renderAllPodPicConfigs = () => {
    if (
      this.state.config.pod_pic_settings &&
      this.state.config.pod_pic_settings.length > 0
    ) {
      return this.state.config.pod_pic_settings.map((podSet, index) => (
        <Row style={{ marginBottom: "3px" }} key={index}>
          {/* <Col lg={8} style={{ paddingRight: '3px' }}>
            <Input
              className="disabledInput"
              value={podSet.pic_title}
              disabled
            />
          </Col> */}
          <Col lg={6} style={{ paddingRight: "3px" }}>
            <Input className="disabledInput" value={podSet.pic_code} disabled />
          </Col>
          {/* <Col lg={7}>
            <Button
              disabled
              className={
                podSet.mandatory === I18n.t('common.false') ? 'btnConfig btnConfigActive disabledBtn' : 'btnConfig'
              }
              onClick={() => this.handlePodInputChange('mandatory', I18n.t('common.false'))}
            >Optional
            </Button>
            <Button
              disabled
              className={
                podSet.mandatory === I18n.t('common.true') ? 'btnConfig btnConfigActive disabledBtn' : 'btnConfig'
              }
              onClick={() => this.handlePodInputChange('mandatory', I18n.t('common.true'))}
            >Mandatory
            </Button>
          </Col> */}
          <Col lg={7} className="padding5 paddingLeft20">
            <Checkbox
              disabled
              checked={
                !!(
                  podSet.mandatory_for &&
                  podSet.mandatory_for.includes("driver")
                )
              }
            >
              Driver
            </Checkbox>
            <Checkbox
              disabled
              checked={
                !!(
                  podSet.mandatory_for &&
                  podSet.mandatory_for.includes("billing")
                )
              }
            >
              Billing
            </Checkbox>
          </Col>
          <Col lg={4}>
            <Button
              className="btnConfig podBtn"
              onClick={() => this.handleRemovePodConfig(index)}
            >
              <Icon type="close" />
            </Button>
          </Col>
        </Row>
      ));
    }
    return <React.Fragment />;
  };

  handleRemovePodConfig = (index) => {
    const podSettings = [...this.state.config.pod_pic_settings];
    podSettings.splice(index, 1);
    this.setState({
      config: Object.assign({}, this.state.config, {
        pod_pic_settings: [...podSettings],
      }),
    });
  };

  goToTab = (step) => {
    const { history } = this.props;
    if (history.location.search) {
      history.push(history.location.pathname);
    }
    // history.push(`/settings?tab=${step}`);
    this.setState({
      activeKey: step,
    });
  };

  _renderOrgSettings = () => {
    const { isMilitaryTime, organizationSettings } = this.state;
    const orgId = userStore.getStateValue("selectedOrg");
    return (
      <div className="card-container tabHover">
        <Tabs
          activeKey={this.state.activeKey}
          onChange={this.goToTab}
          type="card"
        >
          <TabPane tab={I18n.t("menu.settings")} key="1">
            {/* <div>
              <GeneralSettings accountId={this.state.accountId} />
            </div> */}
            <EventSettings
              isOrgLevelSetting
              organizationSettings={organizationSettings}
              isMilitaryTime={isMilitaryTime}
            />
          </TabPane>
          {checkServiceExistance("DZI") && (
            <TabPane
              tab={I18n.t("configurations.delivery_zipcodes_label")}
              key="3"
              className="settingTabScroll"
            >
              {this.renderZipCodeMapping("org")}
            </TabPane>
          )}
          { checkServiceExistance("DRI") && (
            <TabPane tab={I18n.t("driver_rates.label")} key="driverrates">
              {this.renderDriverRateMapping("org")}
            </TabPane>
          )}
          <TabPane tab={I18n.t("general.profile")} key="profile">
            <Row>
              <Col md={2} />
              <Col md={20}>
                <OrgnisationForm
                  organization={orgId}
                  isNew={false}
                  //onCancel={() => this.handleOnModalClose()}
                  onSuccess={(message) => this.handleSaveSuccess(message)}
                />
              </Col>
            </Row>
          </TabPane>
          {checkServiceExistance(["USEI"], "ANY") && (
            <TabPane
              tab={I18n.t("fuelSurcharges.fuelSurcharge")}
              key="fuel_surcharge_setting"
            >
              <FuelSurchargeSettings />
            </TabPane>
          )}
          <TabPane tab={I18n.t("menu.integrations")} key="orgIntegrations">
            <OrgIntegrations />
          </TabPane>
          {checkServiceExistance(["OINSI", "INSI"], "ANY") && (
            <TabPane
              tab={`${I18n.t("order.label")} ${I18n.t("menu.setup")}`}
              key="7"
            >
              <OrderSettings
                isOrgLevelSetting
              />
            </TabPane>
          )}
          <TabPane tab={"Display Settings"} key={"displaySettings"}>
            <DisplaySettings dispContext = {this.props.dispContext} />
          </TabPane>
          <TabPane tab={"Menu Visibility Settings"} key="menuSettings">
            <MenuRenderingSetings
              organizationSettings={organizationSettings}
            />
            </TabPane>
          <TabPane
            tab={`${I18n.t("account.billing.label")} ${I18n.t("menu.account")}`}
            key="org_billing_acc"
          >
            <BillingAccounts />
          </TabPane>
          <TabPane
            tab={`${I18n.t("configurations.headings.rules")}`}
            key="org_rules"
          >
            <OrgRules handleAppStateOnChange={this.props.handleAppStateOnChange}/>
          </TabPane>
          <TabPane
            tab={`${I18n.t("account.billing.label")}`}
            key="org_billings"
          >
            <OrgBillingTabs
            organizationSettings={organizationSettings}
            currentOrg={this.props.currentOrg}
             />
          </TabPane>
        </Tabs>
      </div>
    );
  };

  // renderReferenceTypes = (refMode = "account") => {
  //   return refMode === "account" ? (
  //     <ReferenceTypes account={this.state.account} refMode={refMode} />
  //   ) : (
  //     <ReferenceTypes refMode={refMode} />
  //   );
  // };
  // renderNotificationTemplates = (refMode = "account") => {
  //   return refMode === "account" ? (
  //     <NotificationTemplates account={this.state.account} refMode={refMode} />
  //   ) : (
  //     <NotificationTemplates refMode={refMode} />
  //   );
  // };

  _renderModuleTabs = () => {
    const { isMilitaryTime, organizationSettings } = this.state;
    return (
      <div className="card-container tabHover">
        <Tabs
          activeKey={this.state.activeKey}
          onChange={this.goToTab}
          type="card"
        >
          {checkServiceExistance(["ACNF"], "ANY") && (
            <TabPane tab={I18n.t("menu.settings")} key="1">
              <EventSettings
                account={this.state.account}
                accountId={this.state.accountId}
                organizationSettings={organizationSettings}
                isMilitaryTime={isMilitaryTime}
              />
            </TabPane>
          )}
          {checkServiceExistance("DZI") && (
            <TabPane
              tab={I18n.t("configurations.delivery_zipcodes_label")}
              key="8"
              className="settingTabScroll"
            >
              {this.renderZipCodeMapping("account")}
            </TabPane>
          )}
          {checkServiceExistance("BZI") && (
            <TabPane tab={I18n.t("account.billing.billing_zones")} key="3"
            className="settingTabScroll"
            >
              <ZoneConfig account={this.state.account} />
            </TabPane>
          )}
          {checkServiceExistance("AAI") && (
            <TabPane tab={I18n.t("menu.accessorial")} key="6">
          <AccessorialComponent
              // <AccessorialSettings
                account={this.state.account}
                goToTab={this.goToTab}
                glCodesList={this.props.glCodesList || []}
              />

              {/* <AccessorialMapping account={this.state.account} goToTab={this.goToTab} /> */}
            </TabPane>
          )}
          {checkServiceExistance(["BZI", "LOSI", "AADI"], "ALL") && (
            <TabPane
              tab="Service Levels & Adjustments"
              key="service_levels_adjustments"
            >
              <LOSAdjustmentSettings
                account={this.state.account}
                goToTab={this.goToTab}
              />
            </TabPane>
          )}
          {/* {checkServiceExistance(["BZI", "LOSI"], "ALL") && (
          <TabPane
            tab={`${I18n.t("los.short_form")} ${I18n.t(
              "account.billing.rate_matrix"
            )}`}
            key="4"
          >
            <Billing account={this.state.account} goToTab={this.goToTab} />
          </TabPane>
        )} */}
          {/* {checkServiceExistance("AADI") && (
          <TabPane tab={I18n.t("adjustments.label")} key="12">
            <Adjustments account={this.state.account} />
          </TabPane>
        )} */}

          {/* {checkServiceExistance("BLCONF") && (
          <TabPane tab={I18n.t("account.billing.label")} key="5">
            {this.renderBillingConfig()}
          </TabPane>
        )} */}
          {checkServiceExistance(["BLCONF", "GSIP"], "ANY") && (
            <TabPane
              tab={`${I18n.t("account.billing.label")} & ${I18n.t(
                "invoices.label"
              )}`}
              key="5"
            >
              <BillingEventSettings
                account={this.state.account}
                goToTab={this.goToTab}
                glCodesList={this.props.glCodesList || []}
                updateBillingConfigs={this.updateBillingConfigs}
              />
            </TabPane>
          )}
          {/* {checkServiceExistance("GSIP") && (
          <TabPane tab="Discount" key="discount">
            <Discounts account={this.state.account} />
          </TabPane>
        )} */}
          {/* <TabPane tab={I18n.t('menu.fuelSurcharge')} key="9">
          <FuelSurcharges account={this.state.account} />
        </TabPane> */}
          {checkServiceExistance("ACNF") && (
            <TabPane tab={I18n.t("menu.rules")} key="account_rules">
              <AccountRules
                account={this.state.account}
                accountId={this.state.accountId}
                parentKey={this.state.activeKey}
              />
            </TabPane>
          )}
          {/* {checkServiceExistance("DIMI") && (
          <TabPane tab={I18n.t("menu.dim_config")} key="11">
            <DimConfigurations account={this.state.account} />
          </TabPane>
        )} */}
          {checkServiceExistance("ADRI") && (
            <TabPane tab={I18n.t("driver_rates.label")} key="driverrates">
              {this.renderDriverRateMapping("account")}
            </TabPane>
          )}
          {/* <TabPane tab={I18n.t("menu.contacts")} key="contacts">
          <Contacts account={this.state.account} />
        </TabPane> */}
          {/* <TabPane tab={I18n.t("menu.contact_group")} key="contact_group">
          <ContactGroup account={this.state.account} />
        </TabPane> */}
          {/* {checkServiceExistance("AINSI") && (
          <TabPane tab={I18n.t("menu.instructions")} key="accountInstructions">
            <Instructions account={this.state.account} />
          </TabPane>
        )} */}
          {/* <TabPane tab={I18n.t("references.types")} key="AccountReferenceTypes">
          {this.renderReferenceTypes("account")}
        </TabPane> */}
          {/* <TabPane
          tab={I18n.t("menu.notification_templates")}
          key="notification_templates"
        >
          {this.renderNotificationTemplates("account")}
        </TabPane> */}
          {checkServiceExistance(["AINSI", "DIMI"], "ANY") && (
            <TabPane
              tab={`${I18n.t("order.label")} ${I18n.t("menu.setup")}`}
              key="7"
            >
              <OrderSettings
                account={this.state.account}
                accountId={this.state.accountId}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  };

  renderZipCodeMapping = (zipCodesFor = "account") =>
    zipCodesFor === "account" ? (
      <LocationMap account={this.state.account} zipCodesFor={zipCodesFor} />
    ) : (
      <LocationMap zipCodesFor={zipCodesFor} />
    );

  // renderBillingConfig = () => (
  //   <BillingConfigurations
  //     account={this.state.account}
  //     goToTab={this.goToTab}
  //   />
  // );

  // _renderFuelSurchargeConfig = () => (
  //   <div>
  //     <div className="settingHeader">
  //       <span className="textBold">
  //         {I18n.t("fuelSurcharges.fuelSurcharge")}
  //       </span>
  //     </div>
  //     <FuelSurcharges account={this.state.account} />
  //   </div>
  // );

  renderDriverRateMapping = (ratesFor = "account") =>
    ratesFor === "account" ? (
      <DriverRateConfig account={this.state.account} ratesFor={ratesFor} billingConfigs={this.state.billingConfigs} />
    ) : (
      <DriverRateConfig ratesFor={ratesFor} />
    );

  render() {
    const { account } = this.state;
    return (
      <div className="content-outer">
        <div className="content">
          <this._renderHeader />
          <Spin spinning={this.state.inProgress} delay={1000}>
            {isEmpty(this.state.accountId) ? (
              <this._renderOrgSettings />
            ) : (
              checkServiceExistance("ACNFD") && (
                <div>
                  {account && account.id ? (
                    <this._renderModuleTabs />
                  ) : (
                    <Skeleton />
                  )}
                </div>
              )
            )}
          </Spin>
        </div>
      </div>
    );
  }
}

export const ConfigurationsComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, accountsExceeded, currentOrg, glCodesList } = useContext(OrgContext);
  const accountCodesWithExceededLimit = accountsExceeded.map(rec => rec.account_code);
  return (
    <Configurations
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      accountCodesWithExceededLimit={ accountCodesWithExceededLimit }
      currentOrg={currentOrg}
      glCodesList={glCodesList}
      {...props}
    />
  );
});

export default ConfigurationsComponent;
