import React, { Fragment } from "react";
import AppConfig from "../../config/AppConfig";
import BaseList from "../../components/BaseList";
import PropTypes from "prop-types";
import { Popconfirm, Col, Popover, Row, Tooltip } from "antd";
import I18n from "../../common/I18n";
import AddressInfo from "../../components/common/AddressInfo";
import CustomerOrderDetails from "../../components/orders/CustomerOrderDetails";
import { isEmpty } from "../../common/Common";
import BranchesIcon from "../../components/common/BranchesIcon";	
import { withRouter } from "react-router";
import EditIcon from "../../components/common/EditIcon";	
import DeleteIcon from "../../components/common/DeleteIcon";
import OrderFieldDisplay from "../../components/orders/OrderFieldDisplay";
import TypeOfOrder from "../../components/orders/TypeOfOrder";
import { momentTime } from "../../helpers/date_functions";
import DisplayTime from "../../components/common/DisplayTime";
import DispatchButton from "../../components/orders/DispatchButton";
import moment from "moment";
class LineHaulList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    const { orderConfig = {
      orderTypes: {}
    }} = props;
    this.columns = [
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        render: (data, record) => {
          const isTransfer = ["LH"].includes(record.type_of_order);
          const isDelivery = props.currentStop && props.currentStop.location && props.currentStop.location.type_of_loc && props.currentStop.location.type_of_loc === "DELIVERY" || (props.stopType === "WH" && props.stopIndex === 0);
          const textToDisplay = isTransfer ? ( isDelivery ? data + "D" : data + "P") : data;
          return (
            <div>
              {!isEmpty(data) && (
                <CustomerOrderDetails
                  order_no={props.screen_from === "dispatch" ? textToDisplay : data}
                  key={data}
                  order={{
                    id: record.customer_order_id,
                    customer_order_number: data,
                  }}
                  showEdit={
                    this.props.screen_from === "dispatch" ? true : false
                  }
                  editClick={() => this.props.editOrderClick(record.id)}
                />
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        minWidth: 120,
        // sorter: (a, b) => {
        //   return a.customer_order_number - b.customer_order_number;
        // },
      },
      {
        key: 'items_count',
        title: I18n.t('order.qty'),
        dataIndex: 'pieces',
        // sorter: (a, b) => { return a.pieces - b.pieces; }
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: 'weight',
        title: I18n.t('order.weight'),
        dataIndex: 'weight',
        // sorter: (a, b) => { return a.weight - b.weight; }
        sorter: true,
        width: 80,
        sortDirections: ["ascend", "descend", "ascend"],
      },{
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
        align: "center",
      },
      {
        key: "appointment_date",
        title: "Scheduled Date",
        dataIndex: "pickup_appointment_date", //appointment_date
        // sorter: (a, b) => {
        //   const dateA = new Date(a.pickup_appointment_date); // 
        //   const dateB = new Date(b.pickup_appointment_date);
        //   return dateA - dateB;
        // },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => data ? <DisplayTime
        key={record.id}
        dateTimeString={data}
        displayWithDate={true}
        isUTC={true}
        timeZoneId={record.timeZoneId}
      />
       : '',
        width:150,
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        render: (text, record) => {
          return (
            <OrderFieldDisplay
              data={{...record, id: record.customer_order_id}}
              toDisplay="hawb"
              showTriggerEdit={false}
              showEditIcon={false}
              charsToLimit={null}
            />
        )},
        // sorter: (a, b) => {
        //   // return a.hawb.localeCompare(b.hawb);
        //   return String(a.hawb).localeCompare(String(b.hawb));
        // },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        render: (text, record) => {
          return (
            <OrderFieldDisplay
              data={{...record, id: record.customer_order_id}}
              toDisplay="mawb"
              showTriggerEdit={false}
              showEditIcon={false}
              charsToLimit={null}
            />
        )},
        // sorter: (a, b) => {
        //   // return a.hawb.localeCompare(b.hawb);
        //   return String(a.mawb).localeCompare(String(b.mawb));
        // },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        minWidth: 120,
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1"  charsToLimit={null}/>
        ),
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        minWidth: 120,
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2"  charsToLimit={null}/>
        ),
      },
      {
        key: 'los',
        title: I18n.t("los.label"),
        dataIndex: 'los',
        render: (data) => data,
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        className: "noWrap",
        dataIndex: "account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data) => <Tooltip title={data}>{data}</Tooltip>,
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        minWidth: 100
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data) => data,
        width: 123,
      },
      // {
      //   key: "pickup_location",
      //   title: "Picks at",
      //   dataIndex: "pickup_location",
      //   render: (data) => data,
      // },
      // {
      //   key: "delivery_location",
      //   title: "Drops at",
      //   dataIndex: "delivery_location",
      //   render: (data) => data,
      // },
    ].filter((column) => {
      if (props.showMiniDetails) {
        const keyToHide = ["pickup_location", "delivery_location"];
        return !keyToHide.includes(column.key);
      }else if(props.columnsNotToDisplay){
        const keysToRestrict = ['appointment_date']
        return !keysToRestrict.includes(column.key);
      }
       else return true;
    });

    if (props.currentStatus === "") {
      this.columns.push({
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        render: (data) => {
          return (
            <span>
              {!isEmpty(data) ? (
                <span className="status">
                  {I18n.t(`status_labels.${String(data).toUpperCase()}`)}
                </span>
              ) : (
                ""
              )}
            </span>
          );
        },
      });
    }

    if (props.showActions === true) {
      this.columns.push({
        key: "items",
        fixed: "right",
        render: (data) => {
          return (
            <Row type="flex" gutter={8} align="middle" justify="end" style={{width: 100}}>
              {(
                <Col>
                  <Tooltip title={I18n.t("general.view")} zIndex={999}>
                    <div>
                      {!isEmpty(data) && (
                        <CustomerOrderDetails
                          order_no={data.customer_order_number}
                          key={`view${data.customer_order_number}`}
                          order={{
                            id: data.customer_order_id,
                            customer_order_number: data.customer_order_number,
                          }}
                          showEdit={false}
                          showIcon={true}
                          tab={"details"} //{"linehaul"}
                        />
                      )}
                    </div>
                  </Tooltip>
                </Col>
              )}
              {props.currentStatus !== "" &&	
                props.currentStatus !== "pending" && (	
                  <Tooltip title={"View Route"} zIndex={999}>	
                    <BranchesIcon	
                      handleClick={() => {	
                        this.props.history.push({	
                          pathname: "/routes",	
                          filter: "ASSIGNED",	
                          routeId: data.nav_route_id,	
                          dateFilter: {	
                            fromDate: data.pickup_appointment_date, // data.schedule_date,	
                            toDate: data.drop_appointment_date,// data.schedule_date,	
                          },	
                        });	
                      }}	
                    />	
                    &nbsp;	
                  </Tooltip>	
                )}
              {(
                <Col>
                  <Tooltip title={I18n.t("general.edit")} zIndex={999}>
                    {/* <Icon	
                    type="edit"	
                    onClick={() => this.props.handleEditClick(data)}	
                  /> */}
                    <EditIcon
                      handleClick={() => this.props.handleEditClick(data)}
                    />
                    &nbsp;
                  </Tooltip>
                </Col>
              )}
              {
                (props.currentStatus === "pending") && (
                  <Fragment>
                  <Col>
                    <DispatchButton
                      orders={{
                        id: data.id,
                        order_number: data.customer_order_number,
                        status: data.status,
                        nav_route_id: data.nav_route_id,
                        type_of_order: data.type_of_order,
                        driver_details: data.driver_details,
                        driver_id: data.driver_id,
                        fleet_id: data.fleet_id,
                        is_location_based: orderConfig.orderTypes[data.type_of_order] ? orderConfig.orderTypes[data.type_of_order].is_location_based : false,
                        scheduled_start_datetime: data.pickup_appointment_date ? data.pickup_appointment_date : moment()
                      }}	
                      getOrderDetails={props.refreshList}	
                      setRefreshValue={() => {}}	
                      showIcon={true}
                      key={data.id}
                    />
                  
                </Col>
                  <Col>	
                    <Popconfirm	
                      placement="left"	
                      title={I18n.t("messages.action_confirm", {	
                        action: "delete",	
                        field: "this",	
                      })}	
                      onConfirm={() => this.props.deleteClick([data.id])}	
                      okText="Yes"	
                      cancelText="No"	
                    >	
                      <Tooltip title={I18n.t("general.delete")} zIndex={999}>	
                        {/* <Icon	
                      type="delete"	
                      className="cursorPointer textRed"	
                    /> */}	
                        <DeleteIcon />	
                        &nbsp;	
                      </Tooltip>	
                    </Popconfirm>	
                  </Col>
                  </Fragment>
                )}
            </Row>
          );
        },
        align: "center",
      });
    }

    const isOrdersPage = props.screen_from === "linehaul";
    if (!_.isEmpty(props.displayConfiguration) && isOrdersPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const linehaul_list_view_columns =
        userConfiguration.linehaul_list_view_columns ||
        orgConfiguration.linehaul_list_view_columns;
      const columns = [];
      if (
        typeof linehaul_list_view_columns !== undefined &&
        Array.isArray(linehaul_list_view_columns)
      ) {
        linehaul_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "items");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  };

  setAddress = (data, type) => {
    const pickUpdata = data?.pickup_location
      ? data.pickup_location.l_address
      : {};
    const pickUpAddress = (
      <Popover
        title={I18n.t("general.pickup_address")}
        content={<AddressInfo address={pickUpdata} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {pickUpdata && pickUpdata.city ? `${pickUpdata.city}` : "NA"}
      </Popover>
    );
    const deliveryData = data?.drop_location
      ? data.drop_location.l_address
      : {};
    const dropAddress = (
      <Popover
        title={I18n.t("general.delivery_address")}
        content={<AddressInfo address={deliveryData} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {deliveryData && deliveryData.city ? `${deliveryData.city}` : "NA"}
      </Popover>
    );
    if (type === "origin") {
      return pickUpAddress;
    }
    return dropAddress;
  };

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

LineHaulList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
};

LineHaulList.defaultProps = {
  screen_from: "",
  showTriggerEdit: false,
  resolvedAddressCallback: () => {},
};
export default withRouter(LineHaulList);