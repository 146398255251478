import { isEmpty } from "../common/Common";
import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";

export const ReferenceTypeApi = {
  fetch: (organization_id, account_id) => {
    let url = ApiUrl("v2/order_references?organization_id=" + organization_id);
    if (!isEmpty(account_id)) {
      url = `${url}&account_id=${account_id}`;
    }
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: { reference_types: "references" },
    });
  },
  update: (id, referenceData) => {
    const url = ApiUrl("v2/order_references/" + id);
    return Call("put", url, referenceData, ParseGeneralResponse, {
      responseDataKeys: { data: "order_reference_type" },
    });
  },
  create: (referenceData) => {
    const url = ApiUrl("v2/order_references");
    return Call("post", url, referenceData, ParseGeneralResponse, {
      responseDataKeys: { data: "order_reference_type" },
    });
  },
  delete: (id) => {
    const url = ApiUrl("v2/order_references/" + id);
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  delete_all: (id) => {
    const url = ApiUrl(
      "v2/order_references/delete_account_order_references?account_id=" + id
    );
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
};
