import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  FormItem,
  DatePicker,
  Button,
  Radio,
  Row,
  Col,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  SideBySideFormItem,
  Select
} from "../../common/UIComponents";
import { fetchOrgAccounts, fetchShortFormAccounts } from "../../api/Account";
import AppConfig from "../../config/AppConfig";
import AccountCodesFilter from "../../containers/Filters/AccountCodesFilter";
import WarehouseCodeFilter from "../common/WarehouseCodeFilter";
import I18n from "../../common/I18n";
import TreeSelectComponent from "../../containers/Filters/TreeSelectComponent";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import AgentCodeFilter from "../../containers/Filters/AgentCodeFilters";
import { VehicleTypeApi } from "../../api/VehicleType";

const { RangePicker } = DatePicker;
const statusCodes = [
  {
    label: "Pending",
    key: "PENDING",
  },
  {
    label: "Approved",
    key: "APPROVED",
  },
];
const views = [
  {
    label: "Summary",
    key: "SUMMARY",
  },
  {
    label: "Detailed",
    key: "DETAILED",
  },
];
const defaultFilterValues = {
  warehouseIds: [],
  companyIds: [],
  accountIds: [],
  vehicle_types: [],
  driverIds: [],
  searchDateType: "SERVICE_DATE",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  filterAmount: "BOTH",
  showAmount: 0.0,
  status: "PENDING",
  viewType: "DETAILED",
  sortBy: "none",
  sortByType: "descend",
  download_as: "pdf",
  agentIds : [],
  driver_type: '',
};
class FilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || Object.assign({}, defaultFilterValues),
      accounts: [],
      agents: this.props.agents || [],
      selectedOption: this.props.selectedOption,
      vehicleTypes: [],
      filteredAccounts: [],
      printOptions: ["hide_revenue", "hide_eligible"],
      reportFormat: [],
      accountsLoading: false,
      filteredBillingCompanies: [],
      filteredDrivers: [],
    };
  }

  componentDidMount() {
    this.getAccounts();
    this.getVehicleTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.setState({ filter: _.cloneDeep(this.props.filter) });
    }
    if (!_.isEqual(prevProps.selectedOption, this.props.selectedOption)) {
      this.setState({ selectedOption: this.props.selectedOption}, () => {
        this.handleOptionChange(this.state.selectedOption)
      });
    }
  }

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
          vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  handleOptionChange = e => {
    const selectedOption = e;
    this.setState(prevState => ({
      selectedOption,
      filter: {
        ...prevState.filter,
        vehicle_types: selectedOption === 'driverIds' ? null : prevState.filter.vehicle_types,
        driverIds: selectedOption === 'vehicle_types' ? null : prevState.filter.driverIds
      }
    }), () => {
      this.props.onSelectedOptionChange(this.state.selectedOption);
    });
  };

  getAccounts = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState(
          {
            accounts: result.accounts || [],
            accountsLoading: false,
          },
          () => {
            this.handleFilterPlaceHolderChange("warehouseIds", currentWhId);
          }
        );
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  setFilteredAccounts = (filteredAccount = []) => {
    const { accountIds } = this.state.filter;
    const filteredAccIds =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.id) : [];

    if (filteredAccIds?.length) {
      const filteredAccountCodes = filteredAccIds.filter((acc_code) =>
        accountIds.includes(acc_code)
      );
      this.handleFilterPlaceHolderChange("accountIds", filteredAccountCodes);
    } else {
      this.handleFilterPlaceHolderChange("accountIds", []);
    }
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function") {
        this.props.handleChildFilterChange(filter);
      }
    });
  };

  handleDateRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.setState({
      filter: dateFilter,
    });
  };

  handleFilterPlaceHolderChange = (element, value) => {
    let printOptions  = [...this.state.printOptions]
    const filter = Object.assign({}, this.state.filter, {
      [element]: value,
    });
    if (element === "filterAmount" && value !== "WITH_AMOUNT") {
      filter.showAmount = 0.0;
    }
    if (element === "viewType"){
      if(value === "SUMMARY"){
        printOptions = printOptions.filter(opt => opt !== 'hide_eligible')
      }
      if(value === "DETAILED"){
        // printOptions = printOptions.filter(opt => opt !== 'hide_revenue');
        // filter.download_as = 'xlsx'
      }
    }
    if(element === "searchDateType" && value === "SETTLEMENT_DATE"){
      filter.status = "APPROVED"
    }
    if(element === "status" && value==="PENDING" && filter.searchDateType === "SETTLEMENT_DATE"){
      filter.searchDateType = "SERVICE_DATE"
    }
    this.setState(
      {
        filter,
        printOptions
      },
      () => {
        if (element === "warehouseIds") {
          const { accounts } = this.state;
          const { billingAccounts, drivers } = this.props;
          const { warehouses } = this.props.warehouseFilter;
          const { warehouseIds } = this.state.filter;

          if (!value || value?.length === 0) {
            const filteredAccounts = [...accounts];
            const filteredBillingCompanies = [...billingAccounts];
            const filteredDrivers = [...drivers];
            this.setState({
              filteredAccounts,
              filteredBillingCompanies,
              filteredDrivers,
            });
          } else {
            const filteredAccounts = accounts.filter((account) =>
              account.warehouse_ids.some((id) => value.includes(id))
            );
            const filteredDrivers = drivers.filter((driver) =>
              driver.warehouse_ids.some((id) => value.includes(id))
            );
            const selectedBillingCompanies = warehouses
              .filter(
                (wh) => value.includes(wh.id) && wh.integration_company_id
              )
              .map((wh) => wh.integration_company_id);
            const filteredBillingCompanies = billingAccounts.filter((bc) =>
              selectedBillingCompanies.includes(bc.id)
            );
            this.setState(
              { filteredAccounts, filteredBillingCompanies, filteredDrivers },
              () => {
                this.setFilteredAccounts(filteredAccounts);
              }
            );
          }
        }
      }
    );
  };

  // handleAccountsSelections = (ele, value) => {
  //   const filter = Object.assign({}, this.state.filter, {
  //     [ele]: value,
  //   });
  //   this.setState({
  //     filter,
  //   });
  // };

  clearForm = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const filter = {
      ..._.cloneDeep(defaultFilterValues),
      warehouseIds : currentWhId,
    };
    this.setState({ filter, selectedOption: 'driverIds' }, () => {
      this.handleSearch();
    });
  };

  _renderRangePicker = () => {
    return (
      <FormItem requireColumn={false}>
        <span className="textBold">Date:</span>&nbsp;
        <Radio.Group
          onChange={(e) => {
            this.handleFilterPlaceHolderChange(
              "searchDateType",
              e.target.value
            );
          }}
          value={this.state.filter.searchDateType}
          className="textBold"
        >
          <Radio value="INVOICE_DATE">Invoice Date</Radio>
          <Radio value="SERVICE_DATE">Service Date</Radio>
          <Radio value="SETTLEMENT_DATE">Settlement Date</Radio>
        </Radio.Group>
        <RangePicker
          value={[this.state.filter.fromDate, this.state.filter.toDate]}
          ranges={AppConfig.dateRanges}
          format={AppConfig.dateFormat}
          style={{ width: "100%" }}
          allowClear
          onChange={this.handleDateRangeChange}
        />
      </FormItem>
    );
  };

  _renderWhFilter = () => {
    const { warehouses } = this.props.warehouseFilter;
    return (
      <Row gutter={16}>
        <Col className="textBold" xs={6}>
          {I18n.t("location.location")}
        </Col>
        <Col xs={18}>
          <WarehouseCodeFilter
            SelectedCodes={this.state.filter.warehouseIds}
            element={"warehouseIds"}
            onValueChange={this.handleFilterPlaceHolderChange}
            warehouses={warehouses}
            size="small"
          />
        </Col>
      </Row>
    );
  };
  _renderPrintOption = () => {
    const { filter, printOptions } = this.state;
    const { viewType } = filter;
    return (
      <>
        <Checkbox.Group value={printOptions} style={{ width: "100%" }} onChange={this.handlePrintOptionChange}>
          <h4 className="textBold" style={{ textAlign: "center" }}>
            Export Options
          </h4>
          <Col xs={24}>
            <Checkbox
              value={"hide_revenue"}
              // disabled={["DETAILED"].includes(viewType)}
            >
              Hide Revenue
            </Checkbox>
          </Col>
          <Col xs={24}>
            <Checkbox
              value={"hide_eligible"}
              disabled={["SUMMARY"].includes(viewType)}
            >
              Hide Eligible
            </Checkbox>
          </Col>
          {/* <Col xs={24}>
          <Checkbox value={"hide_no_calc_indicator"}>Hide No Calc Indicator</Checkbox>
        </Col> */}
          <Col xs={24}>
            <Checkbox value={"print_one_page_per_driver"}>
              Print One Page Per Driver
            </Checkbox>
          </Col>
          <Col xs={24}>
            <Checkbox value={"consolidate_by_agent"}>
              Consolidate By Agent
            </Checkbox>
          </Col>
        </Checkbox.Group>
      </>
    );
  };

  handlePrintOptionChange = (e) => {
    this.setState({
      printOptions: e
    });
  };

  // handleReportFormat = (e) => {
  //   const { value, checked } = e.target;
  //   if (checked) {
  //     this.setState(prevState => ({
  //       reportFormat: [...prevState.reportFormat, value]
  //     }));
  //   } else {
  //     this.setState(prevState => ({
  //       reportFormat: prevState.reportFormat.filter(option => option !== value)
  //     }));
  //   }
  // };

  _renderReportFormat = () => {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };

    // Determine which radio button to check based on the filter.viewType value
    const summaryChecked = this.state.filter.viewType === "SUMMARY";
    const detailedChecked = this.state.filter.viewType === "DETAILED";
    const { filter } = this.state;

    return (
      <>
        {/* <h4 className="textBold" style={{ textAlign: "center" }}>
          Report Format
        </h4> */}
        <div>
          <Row gutter={16} type="flex">
            <Col className="textBold">Download As</Col>
            <Col>
            <Radio.Group
            onChange={(e) =>
              this.handleFilterPlaceHolderChange("download_as", e.target.value)
            }
            value={filter.download_as}
            size={"small"}
          >
            <Radio value="pdf">PDF</Radio> {/* disabled={filter.viewType === 'DETAILED'} */}
            <Radio value="xlsx">Excel</Radio>
          </Radio.Group>
            </Col>
          </Row>
           &nbsp;
        
          {/* <Radio style={radioStyle} value={"summary_pdf"} onChange={this.handleReportFormat} >
            Summary Format
          </Radio>
          <Radio style={radioStyle} value={"summary_excel"} onChange={this.handleReportFormat}>
            Summary Format By Type
          </Radio>
          <Radio style={radioStyle} value={"detailed_format"} onChange={this.handleReportFormat}>
            Detailed Format
          </Radio>
          <Radio style={radioStyle} value={"detailed_format_breakdown"} onChange={this.handleReportFormat}>
            Detailed Format/Breakdown
          </Radio> */}
        </div>
      </>
    );
  };

  _renderDriverFilter = () => {
    return (
      <>
      <Row gutter={16} className="marginTop10 textBold" type="flex">
        <Col xs={16}>
          <Radio.Group onChange={(e) => this.handleOptionChange(e.target.value)} value={this.state.selectedOption}>
              <Radio value="driverIds">{I18n.t('general.driver')}</Radio>
              <Radio value="vehicle_types">{I18n.t('vehicleTypes.title')}</Radio>
              <Radio value="driver_type">{I18n.t('general.driver_type')}</Radio>
            </Radio.Group>
          </Col>
        <Col xs={8}>
        {this.state.selectedOption === 'driverIds' && (
          <TreeSelectComponent
            selectedValues={this.state.filter.driverIds}
            onValueChange={(value) =>
              this.handleFilterPlaceHolderChange("driverIds", value)
            }
            records={this.state.filteredDrivers}
            titleField="employee_code"
            valueField="id"
            showLabel={false}
            size="small"
            element="driverIds"
          />
          )}
          {this.state.selectedOption === 'vehicle_types' && (
            <TreeSelectComponent
            selectedValues={this.state.filter.vehicle_types}
            onValueChange={(value) =>
              this.handleFilterPlaceHolderChange("vehicle_types", value)
            }
            records={this.state.vehicleTypes}
            titleField="name"
            valueField="code"
            showLabel={false}
            size="small"
            element="vehicle_types"
          />
        )}
        {this.state.selectedOption === 'driver_type' && (
          <Select
            value={this.state.filter.driver_type ? this.state.filter.driver_type : ""}
            onChange={(e) => {
              this.handleFilterPlaceHolderChange("driver_type", e);
            }}
            style={{ width: "100%" }}
            size="small"
          >
            <Select.Option value="">---Select---</Select.Option>
            {AppConfig.typeOfDriver.map((type) => (
              <Select.Option key={type.driver_type} value={type.driver_key}>
                {type.driver_type}
              </Select.Option>
            ))}
          </Select>
        )}
        </Col>
        </Row>
      </>
    );
  };

  _renderAccountFilter = () => {
    return (
      <Row type="flex" gutter={16} className="marginTop10">
        <Col xs={6} className="textBold">
          {I18n.t("general.account")}
        </Col>
        <Col xs={18}>
          <AccountCodesFilter
            SelectedAccountCodes={this.state.filter.accountIds}
            accounts={this.state.filteredAccounts}
            size="small"
            onValueChange={this.handleFilterPlaceHolderChange}
            showLabel={false}
            valueField="id"
            element={"accountIds"}
            styleObject={{ width: "100%", marginBottom: 5 }}
          />
        </Col>
      </Row>
    );
  };

  _renderAgentFilter = () => {
    return (
      <Row type="flex" gutter={16} className="marginTop10">
        <Col xs={6} className="textBold">
          {"Agent"}
        </Col>
        <Col xs={18}>
          <AgentCodeFilter
            SelectedAgentCodes={this.state.filter.agentIds}
            agents={this.props.agents || []}
            size="small"
            onValueChange={this.handleFilterPlaceHolderChange}
            showLabel={false}
            valueField="id"
            element={"agentIds"}
            styleObject={{ width: "100%", marginBottom: 5 }}
          />
        </Col>
      </Row>
    );
  };

  _renderBillingComapny = () => {
    return (
      <Row type="flex" gutter={16} className="marginTop10">
        <Col xs={6} className="textBold">
          {I18n.t("general.company")}
        </Col>
        <Col xs={18}>
          <TreeSelectComponent
            selectedValues={this.state.filter.companyIds}
            onValueChange={(value) =>
              this.handleFilterPlaceHolderChange("companyIds", value)
            }
            records={this.state.filteredBillingCompanies}
            titleField="name"
            valueField="id"
            showLabel={false}
            size="small"
            element="companyIds"
          />
        </Col>
      </Row>
    );
  };

  _renderAmountFilter = () => {
    return (
      <FormItem
        label={I18n.t("account.billing.filter.filter_results_with")}
        style={{ marginTop: 10 }}
      >
        <Radio.Group
          onChange={(e) => {
            this.handleFilterPlaceHolderChange("filterAmount", e.target.value);
          }}
          value={this.state.filter.filterAmount}
        >
          <Radio value={"WITH_AMOUNT"}>
            {I18n.t("account.billing.filter.with_amount_label")}
          </Radio>
          <Radio value={"WITHOUT_AMOUNT"}>
            {I18n.t("account.billing.filter.without_amount_label")}
          </Radio>
          <Radio value={"BOTH"}>{I18n.t("general.both")}</Radio>
        </Radio.Group>
      </FormItem>
    );
  };
  _renderSettlementFilter = () => {
    return (
      <FormItem label="Settlement Status">
        <Radio.Group
          value={settelementStatus}
          onChange={(e) => {
            this.handleFilterPlaceHolderChange(
              "settelementStatus",
              e.target.value
            );
          }}
        >
          <Radio value="posted_settlement">Posted Settlement</Radio>
          <Radio value="unposted_settlement">Unposted Settlement</Radio>
          <Radio value="BOTH">Both</Radio>
        </Radio.Group>
      </FormItem>
    );
  };

  handleSearch = () => {
    this.props.onSearch(this.state.filter);
  };

  handleExport = () => {
    const { filter, printOptions, reportFormat } = this.state;
    const updatedFilter = {
      ...filter,
      print_options: printOptions,
      report_format: reportFormat,
    };
    this.props.onSearch(updatedFilter, true);
  };

  _rendershowAmount = (filter) => {
    return (
      <Row type="flex" gutter={16} className="marginTop10">
        <Col xs={10} className="textBold">
          Show Amount {">="}
        </Col>
        <Col xs={14}>
          <Row type="flex">
            <Col>
              <InputNumber
                value={filter.showAmount || 0.0}
                size="small"
                className="fontSize12"
                // onChange={(e) =>
                //   onChange(params.rowIndex, 'charge', e)
                // }
                onChange={(e) => {
                  this.handleFilterPlaceHolderChange("showAmount", e);
                }}
                placeholder={I18n.t("reports.chargable")}
                min={0}
                precision={2}
                style={{ width: 100 }}
              />
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
    );
  };

  renderStatusViewBy = (filter) => {
    return (
      <Row>
        <Col xs={24}>
          <Row gutter={16} type={"flex"}> 
            <Col className="textBold">
            {I18n.t("general.status")}
            </Col>
            <Col>
              <Radio.Group
                onChange={(e) => {
                  this.handleFilterPlaceHolderChange(
                    "status",
                    e.target.value
                  );
                }}
                value={filter.status}
              >
                <Radio value="ALL">All</Radio>
                <Radio value="PENDING">Pending</Radio>
                <Radio value="APPROVED">Approved</Radio>
              </Radio.Group>
            </Col>

          </Row>
          {/*<FormItem label={I18n.t("general.status")} style={{ marginTop: 10 }}>
            
             <BaseSelect
              data={statusCodes}
              className="marginRight10 marginLeft10"
              value={filter.status}
              onChange={(e) => {
                this.handleFilterPlaceHolderChange("status", e);
              }}
              selectKey={"key"}
              selectValue={"key"}
              selectedValue={"label"}
              style={{ width: "90%" }}
              showSearch={true}
              size="small"
              showSelectOption={false}
            /> 
          </FormItem>*/}
        </Col>
        {/* <Col xs={12}>
          <FormItem label={"View By"} style={{ marginTop: 10 }}>
            <BaseSelect
              data={views}
              className="marginRight10 marginLeft10"
              value={filter.viewType}
              onChange={(e) => {
                this.handleFilterPlaceHolderChange("viewType", e);
              }}
              selectKey={"key"}
              selectValue={"key"}
              selectedValue={"label"}
              style={{ width: "90%" }}
              showSearch={true}
              size="small"
              showSelectOption={false}
            />
          </FormItem>
        </Col> */}
      </Row>
    );
  };

  render() {
    const { filter } = this.state;
    return (
      <Fragment>
        <div style={{ display: "flex" }}>
          <div>
            {this._renderWhFilter()}
            {this._renderAgentFilter()}
            {this._renderAccountFilter()}
            {this._renderDriverFilter()}
            {/* {this._renderBillingComapny()} */}
            {this._renderAmountFilter()}
            {filter.filterAmount === "WITH_AMOUNT" &&
              this._rendershowAmount(filter)}
            {this._renderRangePicker()}
            {this.renderStatusViewBy(filter)}
            <div style={{ padding: "10px 16px", textAlign: "center" }}>
              <Button
                type="danger"
                onClick={this.clearForm}
                icon="redo"
                size="small"
              >
                Reset
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                onClick={this.handleSearch}
                type="primary"
                icon="search"
                size="small"
              >
                Search
              </Button>
             
            </div>
          </div>
          {/* <div style={{ border: '1px solid #ccc', padding: '10px', margin: '20px 0'}}></div> */}
          <div style={{ borderLeft: "1px solid #ccc", margin: "0 10px" }}></div>
          <div style={{ width: "500px" }}>
            <Row gutter={16}>
              <Col>{this._renderPrintOption()}</Col>
              <Col>
              <Divider style={{ margin: "10px 0px" }} /> 
              </Col>
                          
              <Col>{this._renderReportFormat()}</Col> 
              <Col> <Button
                onClick={this.handleExport}
                type="primary"
                icon="file-pdf"
                size="small"
                className="buttonTeal"
              >
                Export
              </Button></Col>
              {/* </div> */}
            </Row>

            {/* <div style={{ border: '1px solid #ccc', padding: '10px', margin: '20px 0'}}>
            
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

FilterComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
FilterComponent.defaultProps = {};
export default withRouter(FilterComponent);
