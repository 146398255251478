/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Spin, Button, Select, Input, InputNumber, FormItem } from '../../common/UIComponents'
import {
  alertMessage,
  randomNumber,
  isEmpty,
  doValidate,
  roundDecimal,
} from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import FormErrors from '../common/FormErrors'
import {
  deleteDeduction,
  fetchDeductions,
  fetchDriverDeductions,
  saveDeductions,
  generateStandardDeductions,
  saveDriverDeductions,
  generateDriverStandardDeductions,
  deleteDriverDeductions
} from '../../api/Deductions'
import DedutionsList from './DeductionsList'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import { OrgContext } from '../../context/OrgContext'
import { withRouter } from 'react-router-dom'
import { WarehouseContext } from '../../context/WarehouseContext'
import BaseModal from '../BaseModal'
import GLCodeSelect from '../common/SelectDropdowns/GLCodeSelect';
import WarehouseDeductionList from './WarehouseDeductionList'

const requiredFields = [
  {
    form_field: 'deduction_name',
    ui_name: I18n.t('deductions.name'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'default_amount',
    ui_name: I18n.t('deductions.default_amount'),
    isRequired: true,
    inputType: 'floatNumber',
  },
  {
    form_field: 'gl_code',
    ui_name: I18n.t('deductions.gl_code_label'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'dfu_key',
    ui_name: I18n.t('deductions.dfu_key'),
    isRequired: false,
    inputType: 'text',
  },
]

class DeductionsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      deductions: [],
      currentDriver: props.currentDriver ? props.currentDriver : {},
      showCopyModal: false,
      warehousesDeduction: [],
      refRecord: {}
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    // this.setInitialData();
    this.getDeductions()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.warehousesDeduction.length !== this.state.warehousesDeduction.length){
      // this.getDeductions()
    }
  }

  setDeductions = (deductions, cb = null) => {
    this.setState(
      {
        deductions: deductions.map((deduction) =>
          Object.assign({}, deduction, { temp_id: randomNumber() }),
        ),
      },
      cb,
    )
  }

  filterByWarehouse = () => {

    const preresult = this.state.deductions.reduce((acc, item) => {
      if (!acc[item.warehouse_id]) {
        acc[item.warehouse_id] = [];
      }
      acc[item.warehouse_id].push(item);
      return acc;
    }, {});

    let arr=preresult[null];

    this.setState({deductions:arr})
      
    const warehouseData = this.props.warehouseData.warehouses || [];
    console.log(warehouseData);

    const resultWithWhName = warehouseData.length > 0 ? warehouseData.map((wh) => {
      return {
        ...wh,
        deductions: preresult[wh.id]
      }
    }
    ).filter(wh => wh.deductions):[];


    this.setState({ warehousesDeduction: resultWithWhName })


  }

  getDeductions = () => {
    const { currentDriver } = this.state
    this.setState({ inProgress: true })
    const orgId = userStore.getStateValue('selectedOrg')
    const getMethod = !(currentDriver?.id) ? fetchDeductions : fetchDriverDeductions;
    getMethod(orgId, currentDriver.id).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false })
        const deductions = result.deductions || []
        this.setDeductions(deductions, this.filterByWarehouse)
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  addRow = () => {
    const deductions = !_.isEmpty(this.state.deductions) ? [...this.state.deductions] : [];
    deductions.push({
      deduction_name: '',
      default_amount: 0.0,
      gl_code: '',
      dfu_key: '',
      isNew: true,
      temp_id: randomNumber(),
    })
    this.setState({
      deductions,
    })
  }

  handleDelete = (id) => {
    const { currentDriver } = this.state
    this.setState({ inProgress: true })
    const orgId = userStore.getStateValue('selectedOrg')
    const deleteMethod = !(currentDriver?.id) ? deleteDeduction : deleteDriverDeductions;
    const driver_id = currentDriver.id;
    deleteMethod(id, orgId, driver_id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        const deductions = result.deductions || []
        const newDeductions = this.state.deductions.filter(
          (deduction) => deduction.isNew === true,
          )
          const updatedDeductions = [].concat(deductions, newDeductions)
          this.setDeductions(updatedDeductions, () => {
            this.doValidation(false);
            this.props.updateDeductions(updatedDeductions)
            this.filterByWarehouse()
          })
          // this.setDeductions(deductions, this.filterByWarehouse)
          // this.getDeductions();
          this.setState({ inProgress: false })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleFormChange = (index, element, value) => {
    const deductions = [...this.state.deductions];
    const deduction = deductions[index]; // Retrieve the specific deduction object

    if (element === 'payment_type') {
        const defaultDeductionAmount = deduction['default_amount']; 
        const defaultAmount = parseFloat(defaultDeductionAmount)
        const currentDeductionDefaultAmt = !["PERCENTAGE_ON_GROSS_PAY", "PERCENTAGE_ON_GROSS_REVENUE"].includes(value) ? roundDecimal(defaultAmount) : defaultAmount;
        deduction[element] = value;
        deduction["default_amount"] = currentDeductionDefaultAmt;
    } else {
        deduction[element] = value;
    }

    deductions[index] = deduction; // Update the modified deduction in the array

    this.setState({
        deductions,
    });
}


  handleWrFormChange = (index, element, value, wr_id) => {
    const { warehousesDeduction } = this.state;

    const warehouse = warehousesDeduction.find(warehouse => warehouse.id === wr_id);

    if (warehouse.deductions?.length > 0) {
        const deduction = warehouse.deductions[index];

        if (deduction) {
            deduction[element] = value;
            this.setState({
                warehousesDeduction: [...warehousesDeduction],
            });
        }
    }
}

  doValidation = (callSaveAPI = true) => {
    const deductions = [...this.state.deductions]
    let errors = []
    deductions.forEach((deduction, index) => {
      let currentRecordErrors = []
      const currentTotalErrors = []
      //   if (isEmpty(deduction.deduction_name)) {
      //     currentRecordErrors.push(I18n.t('deductions.name'));
      //   }
      //   if (isEmpty(deduction.default_amount)) {
      //     currentRecordErrors.push(I18n.t('deductions.default_amount'));
      //   }
      //   if (isEmpty(deduction.gl_code)) {
      //     currentRecordErrors.push(I18n.t('deductions.gl_code_label'));
      //   }
      //   if (isEmpty(deduction.dfu_key)) {
      //     currentRecordErrors.push(I18n.t('deductions.dfu_key'));
      //   }

      //   if (currentRecordErrors.length > 0) {
      //     currentTotalErrors.push(`${currentRecordErrors.join(', ')} required in Deduction ${index + 1}`);
      //   }
      if (
        isEmpty(deduction.deduction_name) &&
        isEmpty(deduction.gl_code_label) &&
        isEmpty(deduction.dfu_key) &&
        (isEmpty(deduction.default_amount) || deduction.default_amount === 0.0)
      ) {
        currentTotalErrors.push(
          `Please enter values for Deduction ${index + 1}`,
        )
      } else {
        currentRecordErrors = doValidate(requiredFields, deduction)
        if (currentRecordErrors.length > 0) {
          currentTotalErrors.push(
            `In Deduction ${index + 1}, ${currentRecordErrors.join(', ')}`,
          )
        }
      }

      errors = [].concat(errors, currentTotalErrors)
    })
    if (errors.length > 0) {
      this.setState({
        errors,
      })
    } else {
      this.setState({
        errors: [],
      })
      if (callSaveAPI) {
        this.cancelShowModal();
        this.handleSave()
      }
    }
  }

  handleSave = () => {
    const { currentDriver } = this.state

    const wrDedList = [];
    this.state.warehousesDeduction.forEach(item => {
      if (item.hasOwnProperty('deductions')) {
        wrDedList.push(...item.deductions);
      }
    });

    const deductions = [...this.state.deductions,...wrDedList]
    const orgId = userStore.getStateValue('selectedOrg')
    const cleanedDeductions = deductions.map((deduction) =>
      Object.assign({
        deduction_name: deduction.deduction_name
          ? deduction.deduction_name.toUpperCase()
          : "",
        default_amount: deduction.default_amount
          ? deduction.default_amount
          : 0.0,
        gl_code: deduction.gl_code ? deduction.gl_code.toUpperCase() : "",
        dfu_key: deduction.dfu_key ? deduction.dfu_key.toUpperCase() : "",
        id: deduction ? deduction.id : "",
        payment_type: deduction.payment_type ? deduction.payment_type : "VARIABLE",
        standard_code: deduction.standard_code || '',
        warehouse_id: deduction.warehouse_id,
      })
    );
    const data = {
      deductions: cleanedDeductions,
      organization_id: orgId,
    }
    this.setState({ inProgress: true })
    const saveMethod = !(currentDriver?.id) ? saveDeductions : saveDriverDeductions;
    data.driver_id = currentDriver.id
    saveMethod(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.saved'))
        this.setState({
          inProgress: false,
          errors: [],
        })
        const updatedDeductions = result.deductions || []
        this.setDeductions(updatedDeductions, () => {
          this.props.updateDeductions(updatedDeductions);
          this.filterByWarehouse();
        })
        // this.setDeductions(updatedDeductions, this.filterByWarehouse)
      } else {
        // alertMessage(result.errors, 'error', 10);
        this.setState({
          errors: result.errors,
          inProgress: false,
        })
      }
    })
  }

  handleGenerateStandardDeductions = () => {
    const { currentDriver } = this.state
    this.setState({ inProgress: true })
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
    }
    const genMethod = !(currentDriver?.id) ? generateStandardDeductions : generateDriverStandardDeductions;
    data.driver_id = currentDriver.id
    genMethod(data).then((result) => {
      this.setState({ inProgress: false })
      if (result.success) {
        const deductions = result.deductions || []
        this.setDeductions(deductions, () => {
          this.props.updateDeductions(deductions);
        })
        alertMessage(I18n.t('messages.generated'))
      } else {
        renderAlertMessage(result.errors)
      }
    })
  }

  handleAddingWrDeductions = () => {
    const deductions = this.state.deductions;
    const record = this.state.refRecord;
    const { id, ...newrecord } = record;
    deductions.push(newrecord)

    this.setState({ deductions: deductions }, () => {
    })
    // this.cancelShowModal();
    this.doValidation();
  }


  handleChangeRefRecord = (field, value) => {
    this.setState((prevState) => ({
      refRecord: {
        ...prevState.refRecord,
        [field]: value,
      },
    }));
  };

  CopyDeductionForm = () => {
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const warehousedata = this.props.warehouseData;
    const { glCodesList } = this.props.orgData;
    const record = this.state.refRecord;

    return (
      <div>
        <Row gutter={16}>
          <Col className="gutter-row" span={7}>
            <FormItem label={I18n.t('deductions.warehouse')} >
              <Select
                value={record ? record.warehouse_id : ''}
                onChange={(value) => this.handleChangeRefRecord('warehouse_id', value)}
                size="small"
                style={{ width: "100%" }}
              >
                {warehousedata.warehouses.map((warehouse) => (
                  <Select.Option key={warehouse._id} value={warehouse._id}>
                    {warehouse.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col className="gutter-row" span={4}>
            <FormItem label={I18n.t('deductions.name')} >
              <Input
                disabled={true}
                onChange={(event) => this.handleChangeRefRecord('deduction_name', event.target.value)}
                value={record ? record.deduction_name : ''}
                size="small"
                className="textUpperCase fontSize12"
                placeholder={I18n.t('deductions.name')}
              />
            </FormItem>
          </Col>
          <Col className="gutter-row" span={4}>
            <FormItem label={I18n.t('deductions.default_amount')} >
              <InputNumber
                value={record ? record.default_amount : ''}
                onChange={(value) => this.handleChangeRefRecord('default_amount', value)}
                size="small"
                className="fontSize12 width100Per"
                placeholder={I18n.t('deductions.default_amount')}
                min={0}
                precision={decimalPoints}
              />
            </FormItem>
          </Col>
          <Col className="gutter-row" span={4}>
            <FormItem label={I18n.t('deductions.gl_code')} >
              <GLCodeSelect
                value={record ? record.gl_code : ''}
                onChange={(value) => this.handleChangeRefRecord('gl_code', value)}
                data={glCodesList || []}
                className="textUpperCase fontSize12"
                selectKey={"gl_code"}
                selectValue={"gl_code"}
                selectedValue={"gl_code"}
                showSearch={true}
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                selectedCode={"description"}
                size="small"
              />
            </FormItem>
          </Col>
          <Col className="gutter-row" span={4}>
            <FormItem label={I18n.t('deductions.payment_type')} >
              <Select
                value={record ? record.payment_type : ''}
                onChange={(value) => this.handleChangeRefRecord('payment_type', value)}
                showSearch
                style={{ width: "100%" }}
                size="small"
              >
                <Select.Option key="VARIABLE" value="VARIABLE">
                  {I18n.t("deductions.variable")}
                </Select.Option>
                <Select.Option key="FIXED" value="FIXED">
                  {I18n.t("deductions.fixed")}
                </Select.Option>
                <Select.Option key="PERCENTAGE" value="PERCENTAGE">
                  {I18n.t("deductions.percentage")}
                </Select.Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row type='flex' justify="center" gutter={10} className='marginTop10'>
          <Col>
            <Button
              style={{ marginRight: 8 }}
              type="danger"
              size="small"
              icon='close'
              onClick={() => {
                this.cancelShowModal();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              size="small"
              icon='plus'
              onClick={this.handleAddingWrDeductions}
            >
              {I18n.t('general.add')}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  handleShowModal = (record) => {
    this.setState({
      showCopyModal: true,
      refRecord: {
        ...record,
        deduction_name: record.deduction_name || '',
        default_amount: record.default_amount || '',
        gl_code: record.gl_code || '',
        payment_type: record.payment_type || '',
        warehouse_id: this.props.warehouseData.selectedWarehouses || ''
      }
    });
  };

  handleAddWarehouseDeduction = () => {
    const record = this.state.refRecord;
    if (!_.isEmpty(record)) {
      return (
        <BaseModal
          title={
            <Fragment>
              Add order manually for the driver #
            </Fragment>
          }
          width={1050}
          style={{
            top: 120,
          }}
          onCancel={() => {
            this.cancelShowModal();
          }}
        >
          {this.CopyDeductionForm()}
        </BaseModal>
      );
    }
    else {
      return null;
    }
  };

  cancelShowModal = (Callback = null) => {
    this.setState({
      showCopyModal: false,
      refRecord: {}
    });
  };

  _renderDeductions = () => {
    const { glCodesList } = this.props.orgData;
    return (<div>
      <Row justify='center' type='flex' gutter={16}>
        <Col span={12} >
          <Row>
            <Col md={{ span: 12 }} className="alignRight">
              {checkServiceExistance('DDGSD') && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginBottom: '5px',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  <span onClick={this.handleGenerateStandardDeductions}>
                    Regenerate Standard Deductions
                  </span>
                </div>
              )}
            </Col>
            <Col md={{ span: 12 }} className="alignRight">
              {checkServiceExistance('DMGC') && (
                <Button size="small" type="primary" onClick={this.addRow} icon='plus'>
                  {I18n.t('general.add')} {I18n.t('deductions.label')}
                </Button>
              )}

            </Col>
          </Row>
          <Row className="marginTop10">
            <Col >
              <Fragment>
                <DedutionsList
                  size="small"
                  pagination={{ position: 'none' }}
                  data={this.state.deductions}
                  onChange={this.handleFormChange}
                  handleSave={this.handleSave}
                  deleteClick={(index, id, isNew) =>
                    this.handleDeleteClick(index, id, isNew)
                  }
                  glCodesList={glCodesList}
                  handleShowModal={this.handleShowModal}
                />
              </Fragment>
            </Col>
          </Row>
        </Col>
        {!_.isEmpty(this.state.warehousesDeduction) &&
        <Col span={10} style={{ marginTop: 25 , height: "calc(100vh - 400px)" , overflowY: "scroll" }} >
        {this.state.warehousesDeduction.map((e) => {
          
          return(
            <Fragment >
              <h3 className='marginTop10'>{e.name}</h3>
            <WarehouseDeductionList
              size="small"
              pagination={{ position: 'none' }}
              data={e.deductions}
              onChange={this.handleWrFormChange}
              handleSave={this.handleSave}
              deleteClick={(index, id, isNew) =>
                this.handleDeleteClick(index, id, isNew)
              }
              glCodesList={glCodesList}
            />
          </Fragment>
          )
        })

        }

      </Col>
      }
      </Row>

      <Row className="marginTop10">
        {checkServiceExistance('DMGC') && (
          <Col xs={24} className="alignCenter marginTop10">
            <Button
              type="primary"
              loading={this.state.inProgress}
              onClick={this.doValidation}
              className="marginTop10"
              icon='save'
            >
              {I18n.t('general.save')}
            </Button>
          </Col>
        )}
        <Col xs={24} className="marginTop10">
          {FormErrors(this.state.errors)}
        </Col>
      </Row>
    </div>)
  }

  handleDeleteClick = (index, id, isNew) => {
    if (isNew === true) {
      const { deductions } = this.state
      deductions.splice(index, 1)
      this.setState(
        {
          deductions,
        },
        () => {
          this.doValidation(false)
        },
      )
    } else {
      this.handleDelete(id)
    }
  }

  render() {
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col className="accessorials">
                {checkServiceExistance('DMGL') && <this._renderDeductions />}
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
        {this.state.showCopyModal && this.handleAddWarehouseDeduction()}
      </div>
    )
  }
}

export const Deductions = withRouter((props) => {
  const orgData = useContext(OrgContext);
  const warehouseData = useContext(WarehouseContext);
  return (
    <DeductionsComponent
      orgData={orgData}
      warehouseData={warehouseData}
      {...props}
    />
  );
});

Deductions.propTypes = {
  account: PropTypes.shape().isRequired,
}

export default Deductions
