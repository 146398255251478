/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, useContext } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, Col, Row, Spin, Search } from "../../../common/UIComponents";
import { alertMessage,  isEmpty } from "../../../common/Common";
import I18n from "../../../common/I18n";
import {
  deleteAccessorial,
  fetchAccountAccessorials,
  fetchAccessorialDetails,
  cloneAccountAccessorial,
} from "../../../api/Accessorials";
import { generateStandardAccessorials } from "../../../api/Account";
import FormErrors from "../../common/FormErrors";
import { checkServiceExistance, renderAlertMessage } from "../../../helpers/common";
import AppConfig from "../../../config/AppConfig";
import AccessorialGridList from "./AccessorialGridList";
import { ScreenKeys } from "../../../containers/constants";
import { UserContext } from "../../../context/UserContext";
import { DisplaySettingsContext } from "../../../context/DisplaySettings";
import BaseModal from "../../BaseModal";
import AccessorialConfigurationComponent from "../../billing_components/AccessorialConfiguration";
import AccessorialForm from "../../billing_components/AccessorialForm";
import SlotComponent from "../../billing_components/SlotComponent";
import { withRouter } from "react-router-dom";
import { OrgContext } from "../../../context/OrgContext";
import ZonesConfiguration from "./Zones";
class Accessorials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      search_value: "",
      inProgress: false,
      showMapping: false,
      showConfigurations: false,
      accessorials: [],
      isNew: true,
      gl_Code: "",
      visibility_access: {
        driver: false,
        consignee: false,
      },
      accessible_to: [],
      checkedKeys: {
        selectedKeys: "",
        selectedRows: [],
      },
      account: this.props.account || {},
      accountAccessorials: [],
    };
    this.tableOptions = {
      pagination: { current: 1 },
      filters: {},
      sorter: {},
    };
  }

  componentDidMount() {
    this.getAccountAccessorials();
  }

  getAccountAccessorials = (cb = null) => {
    const page = this.tableOptions.pagination.current;
    // const { perPage } = AppConfig;
    const perPage = 10000;
    const searchText = this.state.search_value || "";
    this.setState({ inProgress: true });
    fetchAccountAccessorials(
      this.state.account.organization_id,
      this.state.account.id,
      "BILLING",
      page,
      perPage,
      searchText
    ).then((result) => {
      if (result.success) {
        const accessorials = result.accessorials || [];
        this.setState(
          {
            accountAccessorials: accessorials,
            inProgress: false,
            pagination: result.pagination ? result.pagination : {},
          },
          () => {
            if (_.isFunction(cb)) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  setConfigProcess = (id) => {
    fetchAccessorialDetails(id).then((result) => {
      if (result.success) {
        const currentAccessorial = result.accessorial || [];
        this.setState({
          isNew: false,
          currentAccessorial,
          showConfigurations: true,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  clearForm = () => {
    this.setState({
      checkedKeys: {
        selectedKeys: "",
        selectedRows: [],
      },
    });
  };

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteAccessorial(id).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false });
        alertMessage(I18n.t("messages.deleted"));
        if (this.state.accountAccessorials.length === 1) {
          if (
            this.tableOptions.pagination &&
            this.tableOptions.pagination.current > 1
          ) {
            this.tableOptions.pagination.current = this.tableOptions.pagination.current - 1;
            this.getAccountAccessorials();;
          } else {
            this.getAccountAccessorials(); 
          }
        } else {
          this.getAccountAccessorials();
        }
        //this.getAccountAccessorials();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      checkedKeys: Object.assign({}, this.state.routes, {
        selectedKeys: selectedRowKeys,
        selectedRows,
      }),
    });
  };

  _renderAccessorial = () => { 
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || AppConfig.perPage,
    };
    return (
    <Row>
      <Col className="accessorials">
      <Col style={{height: "65vh"}} md={{span:22,offset:1}} >
        <AccessorialGridList
            size="small"
            tableName={ScreenKeys.ACCESSORIAL_LISTING}
            rawDataSource={this.state.accountAccessorials}
            pagination={{ position: "none" }}
            userContext={this.props.userContextData}
            // pagination={pagination}
            // tableChange={(pagination, filter, sorter) => this.onTableChange(pagination, filter, sorter)}
            scroll = {{y:"calc(100vh - 350px)"}}
            showDescription={false}
            doConfig={this.setConfigProcess}
            displayConfiguration={this.props.displayConfiguration}
            deleteClick={this.handleDelete}
            showActions
            editClick={this.handleEditAccessorial}
            cloneClick={this.handleCloneAccessorial}
        />
        </Col>
        {/* <AccessorialCodesList
          size="small"
          data={this.state.accountAccessorials}
          //pagination={{ position: "none" }}
          pagination={pagination}
          tableChange={(pagination, filter, sorter) => this.onTableChange(pagination, filter, sorter)}
          scroll = {{y:"calc(100vh - 350px)"}}
          showDescription={false}
          doConfig={this.setConfigProcess}
          deleteClick={this.handleDelete}
          showActions
          editClick={this.handleEditAccessorial}
          cloneClick={this.handleCloneAccessorial}
        /> */}
      </Col>
    </Row>
  )
  }

  handleOnChange = (element, value) => {
    if (element === "search_value") {
      this.setState({
        [element]: value,
      });
    } else {
      this.setState({
        currentAccessorial: Object.assign({}, this.state.currentAccessorial, {
          [element]: value,
        }),
      });
    }
  };

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getAccountAccessorials();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getAccountAccessorials();
        }
      );
    }
  };

  onSearch = (text) => {
    const search_value = text.trim();
    this.setState(
      {
        search_value,
      },
      () => {
        if (search_value === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = text;
        }
        this.tableOptions.pagination.current = 1;
        this.getAccountAccessorials();
      }
    );
  };

  closeAccessorialConfig = () => {
    this.setState({ currentAccessorial: {}, showConfigurations: false, isNew: true, });
  };

  renderAccessorialConfig = () => {
    const { currentAccessorial } = this.state;
    
    const components = currentAccessorial.components ? currentAccessorial.components.map(
      rec => rec.component_code 
    ) : [];
    const haveSlots = components.includes('SLOTS')
    const isZone = components.includes('ZONES')
    return (<BaseModal
      title={`${this.state.currentAccessorial.accessorial_name} ${I18n.t(
        "menu.settings"
      )}`}
      onCancel={() => this.closeAccessorialConfig()}
      width="100%"
      style={{
        top: 0,
        height: "100%",
      }}
      bodyStyle={{
        height: "calc(100vh - 50px)",
        overflowY: "scroll",
      }}
    >
      
      {isZone ?
        <ZonesConfiguration 
        currentAccessorial={{id: currentAccessorial._id, ...currentAccessorial}}
        account={this.state.account}
        onCancel={() => this.closeAccessorialConfig()}
        /> :
        !haveSlots ? 
       <AccessorialConfigurationComponent
          account={this.state.account}
          currentAccessorial={this.state.currentAccessorial}
          onCancel={this.closeAccessorialConfig}
          isNew={this.state.isNew}
          key={"accessorialForm"}
        />
        :
        <SlotComponent 
          currentAccessorial={{id: currentAccessorial._id, ...currentAccessorial}}
          account={this.state.account}
          onCancel={this.closeAccessorialConfig}
          isNew={this.state.isNew}
          key={"accessorialForm"}
        />

      }
    </BaseModal>)
  };

  handleCloneAccessorial = (id) => {
    this.setState({ inProgress: true });
    cloneAccountAccessorial(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.clone_success"));
        this.getAccountAccessorials();
        this.setState({ inProgress: false });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleEditAccessorial = (id) => {
    const { accountAccessorials } = this.state;
    const accessorial = _.find(accountAccessorials, { id });
    const currentAccessorial = !isEmpty(accessorial)
      ? Object.assign({}, accessorial, {
          name: accessorial.accessorial_name,
          code: accessorial.accessorial_code,
          components: accessorial.components
            ? accessorial.components.map(
                (component) => component.component_code
              )
            : [],
        })
      : {};
    this.setState({ currentAccessorial, isNew: false, showMapping: true });
  };

  showMasterMapping = () => {
    this.setState({ showMapping: true });
  };

  renderFormModal = () => (
    <BaseModal
      title={
        this.state.isNew
          ? I18n.t("accessorials.add")
          : I18n.t("accessorials.update")
      }
      onCancel={() => this.closeMasterMapping()}
      width="60%"
      style={{ top: 50 }}
      maskClosable={false}
    >
      <AccessorialForm
        currentAccessorial={this.state.currentAccessorial}
        account={this.state.account}
        onCancel={this.closeMasterMapping}
        isNew={this.state.isNew}
        callConfig={this.callConfig}
        glCodesList={this.props.glCodesList || []}
      />
    </BaseModal>
  );

  callConfig = (id) => {
    this.closeMasterMapping(() => {
      this.setConfigProcess(id);
    });
  };

  closeMasterMapping = (cb = null) => {
    this.setState(
      {
        showMapping: false,
        isNew: true,
      },
      () => {
        this.getAccountAccessorials(cb);
      }
    );
  };

  handleGenerateStandardAccessorials = () => {
    if (this.state.account) {
      this.setState({ inProgress: true });
      const data = {
        account_id: this.state.account.id,
        organization_id: this.state.account.organization_id,
      };
      generateStandardAccessorials(data).then((result) => {
        this.setState({ inProgress: false });
        if (result.success) {
          this.getAccountAccessorials();
          alertMessage("Standard Accessorials Generated Successfully");
        } else {
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  render() {
    // const { pagination } = this.state;

    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row gutter={16}>
              <Col xs={24} className="accessorials">
                <Row>
                  <Col md={{span:6,offset:1}}>
                    {checkServiceExistance("AAGSA") && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          marginBottom: "5px",
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        <span onClick={this.handleGenerateStandardAccessorials}>
                          Regenerate Standard Accessorial
                        </span>
                      </div>
                    )}
                  </Col>
                  {checkServiceExistance("AACAA") && (
                    <Col md={11} className="alignRight">
                      <Button
                        type="primary"
                        icon="plus"
                        onClick={this.showMasterMapping}
                        className="marginBottom10 marginRight10"
                      >
                        {I18n.t(
                          "account.billing.ratecard.add_accessorial_to_account"
                        )}{" "}
                      </Button>
                    </Col>
                  )}
                  <Col md={5} className="alignRight">
                    <Search
                      size="default"
                      value={this.state.search_value || ""}
                      placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                        "menu.accessorial"
                      )}`}
                      onChange={(e) =>
                        this.handleOnChange("search_value", e.target.value)
                      }
                      onSearch={this.onSearch}
                      allowClear
                    />
                  </Col>
                  {checkServiceExistance("AAI") && (
                    <Col xs={24}>
                      <this._renderAccessorial />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>{FormErrors(this.state.errors)}</Row>
          </Spin>
          {this.state.showConfigurations && this.renderAccessorialConfig()}
          {this.state.showMapping && this.renderFormModal()}
        </div>
      </div>
    );
  }
}

const AccessorialComponent = withRouter((props) => {
    const { orgSettings : organizationSettings  }= useContext(OrgContext);
    const userContextData = useContext(UserContext);
     const { currentRole } = userContextData;
     const userContext = userContext
     const isSuperAdmin = currentRole === 'super_admin';
     const displayConfiguration = useContext(DisplaySettingsContext);
     const perPageKey = isSuperAdmin ? ScreenKeys.ACCESSORIAL_LISTING : ScreenKeys.ACCESSORIAL_LISTING;
     const recordsPerPage = !isSuperAdmin ? _.get(userContextData, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage) : AppConfig.ordersPerPage
    return (
      <Accessorials
        organizationSettings={organizationSettings}
        userContextData={userContextData}
        recordsPerPage={ recordsPerPage }
        isSuperAdmin={ isSuperAdmin }
        displayConfiguration = {displayConfiguration}
        perPageKey={ perPageKey }
        {...props}
      />
    );
  });
Accessorials.propTypes = {
  account: PropTypes.shape().isRequired,
};
Accessorials.defaultProps = {};

export default AccessorialComponent;
