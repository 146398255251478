import { Col, Icon, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { ARApi } from "../../api/ARApi";
import { alertMessage } from "../../common/Common";
import { fetchAccount } from "../../api/Account";
import TransactionIndexList from "../account_ar/TransactionIndexList";
import ARChequeFilter from "./ARChequeFilter";
import { defaultARChequefilter } from "../dispatches/helpers";
import I18n from "../../common/I18n";
import moment from "moment";
import { renderAlertMessage } from "../../helpers/common";

const ViewChecksHomePage = (props) => {
  const defaultFilter = defaultARChequefilter(props)
  const [checkDetails, setCheckDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({});
  const accountId = props.match.params.accountId;
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    ...defaultFilter,
  })
  
  const handleGoBack = () => {
    props.history.push({ pathname: "/account_receivables" });
  };
  
  useEffect(() => {
    getAccountDetails();
    //getCheckDetails();
  }, []);

  const updateFilterSearchValues = (filter) => {
    setFilterPlaceHolder(filter)
  }
  useEffect(() => {
    getCheckDetails();
  }, [filterPlaceHolder]);


  const getAccountDetails = () => {
    const accountId = props.match.params.accountId;
    setLoading(true);
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        const account = result.account || [];
        setCurrentAccount(account);
        setLoading(false);
      } else {
        renderAlertMessage(result.errors)
        setCurrentAccount({});
        setLoading(false);
      }
    });
  };

  const getCheckDetails = () => {
    const account_id = props.match.params.accountId;
    setLoading(true);
    const payLoad = {
      batch_no: filterPlaceHolder.batch_no,
      check_ach_no: filterPlaceHolder.check_ach_no,
    }
    if(filterPlaceHolder.dateSearchBy && filterPlaceHolder.dateSearchValue?.fromDate){
      payLoad.search_date_type= filterPlaceHolder.dateSearchBy, //check_date
      payLoad.search_date_from= moment(filterPlaceHolder.dateSearchValue.fromDate).format("YYYY-MM-DD"),
      payLoad.search_date_to= moment(filterPlaceHolder.dateSearchValue.toDate).format("YYYY-MM-DD")
    }
    ARApi.fetchCheckDetails(account_id, payLoad).then((response) => {
      if (response.success) {
        setCheckDetails(response.data.ar_batch_checks);
        setLoading(false);
      } else {
        renderAlertMessage(response.errors)
        setLoading(false);
      }
    });
  };

  const handleRedirect = (id) => {
    props.history.push({pathname: `/check_transaction_info/${accountId}/${id}`});
  };

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <span onClick={handleGoBack}>
            <Icon type="arrow-left" style={{ cursor: "pointer" }} />
          </span>
          &nbsp;&nbsp;
          <Col xs={12}>{I18n.t("batch_management.view_checks_details")}
          &nbsp;
          {currentAccount?.name ? `( ${currentAccount.name} )` : ""}
          </Col>
        </Row>
        <Spin spinning={loading}>
          <ARChequeFilter 
            filterPlaceHolder={filterPlaceHolder}
            defaultFilter={{ ...defaultFilter }}
            setFilterPlaceHolder={updateFilterSearchValues}
          />
          <Row>
            <Col>
              {/* <Row type="flex" justify="end">
                <Col>
                  <RecordsPerPage
                    onChange={onRecordChange}
                    value={recordsPerPage}
                    defaultValue={recordsPerPage}
                  />
                </Col>
              </Row> */}
              <TransactionIndexList 
              data={checkDetails ? checkDetails : []}
              handleRedirect={(id) =>handleRedirect(id)}
              showAccess={true}
              showEdit={false}
              showTransaction={false}
              scroll={{x: "max-content" }}
              columnsToDisplay={[
                "checkAchNo",
                "modeOfPayement",
                "warehouse",
                "amountReceived",
                "paidAmount",
                "balanceAmount",
                "checkAchDate",
                "payDate",
                "status",
                "manage"
              ]}
               />
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};

export default withRouter(ViewChecksHomePage);
