import React from "react";
import BaseList from "../../BaseList";
import {
  Link,
  Switch,
  Popconfirm,
  Tooltip,
} from "../../../common/UIComponents";
import { checkNegitive, isEmpty } from "../../../common/Common";
import { checkServiceExistance } from "../../../helpers/common";
import I18n from "../../../common/I18n";
import AppConfig from "../../../config/AppConfig";
class AgingReportList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      aging_report_display_config: ""
    };
    this.columns = [
      {
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        key: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("account.account_name"),
        dataIndex: "account_name",
        key: "account_name",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  <Tooltip title={data} placement="topLeft"> {data} </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("batch_management.total_billed_amount"),
        dataIndex: "total_billed_amount",
        key: "total_billed_amount",
        render: (data) => (
          <div className="paddingRight30">{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions
      },
      {
        title: I18n.t("batch_management.balance_amount"),
        dataIndex: "inv_balance",
        key: "inv_balance",
        render: (data) => (
          <div className="paddingRight30">{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions
      },
    ];
  }

  componentDidMount() {
    this.getAgingConfigurations();
  }

  getAgingConfigurations = () => {
    const { orgSettings } = this.props.orgData;
    const OrgCode = this.props.orgData.currentOrg.code;
    const defaultConfig = OrgCode === "HDS" ? "[[0,30],[31,60],[61,90],[91,120],[121]]" : "[[0,30],[31,45],[46,60],[61,90],[91,180],[181]]";
    const configString = orgSettings?.aging_report_display_config ?? defaultConfig;
    const configArray = JSON.parse(configString.replace(/'/g, '"'));
    this.setState({
      aging_report_display_config: configArray
    }, () => {
      this.setAgingColumns();
    });
  }

  setAgingColumns = () => {
    const agingColumns = this.state.aging_report_display_config.map((range, index) => {
      const [from, to] = range;
      let title;
      if (index === 0) {
        title = "Current";
      } else {
        title = to ? `${from}-${to} days` : `Over ${from - 1} days`;
      }
      const dataIndex = to ? `${from}-${to}` : `${from}-999999`;
      return {
        title,
        dataIndex,
        key: dataIndex,
        render: (data) => <div className="paddingRight30">{checkNegitive(data)}</div>,
        className: "alignRight",
      };
    });

    const accountNameIndex = this.columns.findIndex(col => col.key === 'account_name');
    this.columns.splice(3, 0, ...agingColumns);
    this.forceUpdate(); 
  }

  render() {
    return (
      <BaseList
        columns={this.columns}
        {...this.props}
      />
    );
  }
}

export default AgingReportList;
