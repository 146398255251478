import { Button, Col, Icon, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { FormItem, TextArea } from "../common/UIComponents";
import { updateInvoice } from "../api/Billing";
import { alertMessage } from "../common/Common";
import Remarks from "../components/billing/Remarks";

export const DriverNotesBetaBilling = ({
  showActions,
  referenceId,
  orderGroupType,
  status,
  getInvoices,
  refreshBetaScreen,
  isLocked,
  remarks,
}) => {
  const [comments, setComments] = useState("");
  const [disableRemarksSave, setDisableRemarksSave] = useState(true);
  const [showRemarks, setShowRemarks] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRemarksChange = (value) => {
    if (value !== "" && value.length > 2) {
      setComments(value);
      setDisableRemarksSave(false);
    } else {
      setComments(value);
      setDisableRemarksSave(true);
    }
  };

  const showRemarksModal = () => {
    setShowRemarks(true);
  };

  const closeRemarksModal = () => {
    setShowRemarks(false);
  };

  const handleSave = () => {
    setLoading(true);
    const invoice = {
      new_invoice_lines: [],
      remark: comments || "",
      reference_id: referenceId,
      order_group_type: orderGroupType,
      status: status,
    };
    updateInvoice(invoice)
      .then((response) => {
        if (response.success) {
          alertMessage("success", I18n.t("messages.saved"));
          setLoading(false);
          setComments("");
          getInvoices();
          closeRemarksModal();
          refreshBetaScreen();
        } else {
          alertMessage("error", I18n.t("messages.error"));
          setLoading(false);
        }
      })
      .catch((error) => {
        alertMessage("error", error);
        setLoading(false);
      });
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Fragment>
          {!showRemarks && showActions === true ? (
            <Col xs={24} className="alignLeft">
              <Button
                type="primary"
                size="small"
                onClick={() => showRemarksModal()}
              >
                <Icon type="plus-circle" />
                &nbsp;{I18n.t("invoices.add_remark")}
              </Button>
              {!_.isEmpty(remarks) && remarks.length > 0 && (
                <Col sm={24} xs={24} md={24} lg={24} className="marginTop10">
                  <FormItem label={I18n.t("general.notes")}>
                    <Remarks data={remarks || []} />
                  </FormItem>
                </Col>
              )}
            </Col>
          ) : (
            <Fragment>
              {showActions === true && (
                <Col xs={24} className="marginTop10">
                  <FormItem
                    label={`${I18n.t("general.add")} ${I18n.t(
                      "general.notes"
                    )}`}
                  >
                    <TextArea
                      rows={4}
                      placeholder={I18n.t("general.notes")}
                      className="sop-form-textArea"
                      value={comments}
                      onChange={(e) => handleRemarksChange(e.target.value)}
                    />
                    {!isLocked && showActions === true && (
                      <div style={{ textAlign: "center" }}>
                        <Button onClick={closeRemarksModal} icon="close">
                          {I18n.t("general.cancel")}
                        </Button>
                        <Button
                          onClick={handleSave}
                          disabled={disableRemarksSave}
                          className="assignDriver"
                          style={{ margin: 10 }}
                          loading={loading}
                          icon="save"
                        >
                          {I18n.t("general.save")}
                        </Button>
                      </div>
                    )}
                  </FormItem>
                </Col>
              )}
            </Fragment>
          )}
        </Fragment>
      </Spin>
    </div>
  );
};
