import React from "react";
import { Col, Icon, Row, Tabs } from "../common/UIComponents";
import OrderListView from "../components/configurations/DisplaySettings/OrderListView";
import { PreplanListView } from "../components/configurations/DisplaySettings/PreplanListView";
import { DispatchViewList } from "../components/configurations/DisplaySettings/DispatchViewList";
import { BillingViewList } from "../components/configurations/DisplaySettings/BillingViewList";
import { InvoiceViewList } from "../components/configurations/DisplaySettings/InvoiceViewList";
import I18n from "../common/I18n";
import { FeInsights } from "../components/configurations/DisplaySettings/FeInsights";
import { LocationViewList } from "../components/configurations/DisplaySettings/LocationViewList";
import { AccessorialViewList } from "../components/configurations/DisplaySettings/AccessorialListView";
import { DriverViewList } from "../components/configurations/DisplaySettings/DriverViewList";

export const DisplaySettings = (props) => {
  const onChange = (key) => {
  };

  const path = window.location.href
  const shouldRenderHeader = path.includes("displaySettings");
  const feInsights = path.includes('configurations')


  return (
    <div className="content-outer">
      <div className="content">
      {shouldRenderHeader && (
        <Row className="page-header">
          <Col xs={24}>{I18n.t('menu.display_settings')}</Col>
        </Row>
      )}
        <div className="card-container tabHover">
          <Tabs
            defaultActiveKey="OrderListView"
            type="card"
            onChange={onChange}
          >
            <Tabs.TabPane
              tab={I18n.t('menu.orders_management')}
              key="OrderListView"
            >
              <OrderListView dispContext={props.dispContext} />
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={I18n.t("menu.preplan_management")}
              key="PreplanListView"
            >
              <PreplanListView dispContext={props.dispContext} />
            </Tabs.TabPane>

            <Tabs.TabPane tab={I18n.t("menu.dispatch")} key="DispatchViewList">
              <DispatchViewList dispContext={props.dispContext} />
            </Tabs.TabPane>

            <Tabs.TabPane tab={I18n.t("menu.billing")} key="BillingViewList">
              <BillingViewList dispContext={props.dispContext} />
            </Tabs.TabPane>

            <Tabs.TabPane tab={I18n.t("menu.invoices")} key="InvoiceViewList">
              <InvoiceViewList dispContext={props.dispContext} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Locations"} key="AccountsiewLst">
              <LocationViewList dispContext={props.dispContext} />
              </Tabs.TabPane>
            <Tabs.TabPane tab={"Accessorials"} key="AccessorialViewList">
              <AccessorialViewList dispContext={props.dispContext} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Driver"} key="DriverViewList">
              <DriverViewList dispContext={props.dispContext} />
            </Tabs.TabPane>
            { feInsights && (
              <Tabs.TabPane tab={
                <span>
                  <Icon type="line-chart" />
                  { I18n.t("general.insights") }
                </span>
              } key="FeInsights">
                <FeInsights dispContext={ props.dispContext } />
              </Tabs.TabPane>
            ) }
          </Tabs>
        </div>
      </div>
    </div>
  );
};
