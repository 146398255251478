import React, { Fragment } from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import { checkNegitive } from "../../common/Common";

class GLCodeReportList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "gl_code",
        title: <b>{I18n.t("gl_code_report.gl_code")}</b>,
        dataIndex: "gl_code",
        render: (data, record) => <span>{data}</span>,
        width: "90px",
      },
      {
        key: "description",
        title: <b>{I18n.t("gl_code_report.description")}</b>,
        dataIndex: "description",
        render: (data, record) => <span>{data}</span>,
        width: "200px",
      },
      {
        key: "amount",
        title: <b>{I18n.t("gl_code_report.amount")}</b>,
        dataIndex: "amount",
        render: (data, record) => 
        <div style={{ width: 100}} className="alignRight">{checkNegitive(data)}</div>,
        width: "90px",
      },
      {
        key: "total_percentage",
        title: <b>{I18n.t("gl_code_report.total_percentage")}</b>,
        dataIndex: "total_percentage",
        render: (data, record) => <span>{data}</span>,
        width: "100px",
        align:'center'
      },
    ];
  }
}

export default GLCodeReportList;
