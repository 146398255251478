import React, { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";

export const PreplanStopOrders = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      hawb: "123456",
      mawb: "123456",
      service_duration: "2:00AM-9:00PM",
      weight: 100,
      pallets:1,
      account_code: "ABC123",
      account_name: "abc123",
      reference_1: "700214577",
      reference_2: "123456",
      los :"White Glove",
      consignee : "Mary",
      company_name : "ABC123",
      routing : "ABCD"
    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      hawb: "123456",
      mawb: "123456",
      service_duration: "12:00AM-5:00PM",
      weight: 200,
      pallets:2,
      account_code: "ABC234",
      account_name: "abc234",
      reference_1: "700214577",
      reference_2: "123456",
      los :"Threshold",
      consignee :"Bob",
      company_name : "ABC123",
      routing : "EFGH"
    },
  ]);
  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "service_duration",
      title: I18n.t("general.duration"),
      dataIndex: "service_duration",
    },
    {
      key: "weight",
      title: `${I18n.t("order.weight")} (lbs)`,
      dataIndex: "weight",
    },
    // {
    //   key: "pallets",
    //   title: I18n.t("order.pallets"),
    //   dataIndex: "pallets",
    // },
    {
      key: "account_code",
      title: I18n.t("account.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "los",
      },

      {
        key: "consignee",
        title: <EndUserTitle/>,
        dataIndex: "account_code",
      },
      {
        key: "company_name",
        title: I18n.t("general.company_name"),
        dataIndex: "company_name",
      },
      {
        key: "routing",
        title: I18n.t("order.routing"),
        dataIndex: "routing",
      },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "stopOrders_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
};
