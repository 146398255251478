import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  FormItem,
  Input,
  Row,
  Icon,
  Collapse,
  Checkbox,
} from '../../common/UIComponents'
import {alertMessage, checkNegitive, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import FormButtons from '../common/FormButtons'
import FormErrors from '../common/FormErrors'
import {
  createOrderQuoteAmount,
  fetchOrderQuoteAmount,
  fetchQuoteOrderAmount,
} from '../../api/OrdersApi'
import { checkServiceExistance } from '../../helpers/common'
import _ from "lodash";

const { Panel } = Collapse
class OrderQuoteAmount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: this.props.orders ? this.props.orders : [],
      inProgress: false,
      errors: [],
      currentForm: {}, 
      quote_orders : [],
      orderIds  : this.props.orderIds
    }
    this.handleSave = this.handleSave.bind(this)
  }

  handleStateOnChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  handleOnChange = (element, value) => {
    this.setState({
      currentForm: Object.assign({}, this.state.currentForm, {
        [element]: value,
      }),
    })
  }
 
  updatePropValues = () => {
    const { handleOnChange } = this.props;
    if(handleOnChange && typeof handleOnChange === "function"){
      handleOnChange('isModified', true);
    }
  }
  handleElementChange(order, location_id, element, value) {
    const currentForm = Object.assign({}, this.state.currentForm);
    if (isEmpty(currentForm[location_id])) {
      currentForm[location_id] = {}
    }
    currentForm[location_id][element] = !isEmpty(value) ? value : ''
    this.handleStateOnChange('currentForm', currentForm)
    // this.updatePropValues();
  }

  handleConfigChange = (order, location_id, element, value) => {
    const currentForm = Object.assign({}, this.state.currentForm)
    if (isEmpty(currentForm[location_id])) {
      currentForm[location_id] = {}
    }
    if (isEmpty(currentForm[location_id].configurations)) {
      currentForm[location_id].configurations = {}
    }
    currentForm[location_id].configurations[element] =
      value === 'true' ? 'true' : 'false'
    // this.updatePropValues();
    this.handleStateOnChange('currentForm', currentForm)
  }

  componentDidMount() {
    this.getOrderQuotes()
    this.getQuoteOrderAmount()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.orders, this.props.orders)) {
      this.setState({
        orders: this.props.orders
      },()=>{
        this.setUpdatedData();
      })
    }
  }

  setUpdatedData = () => {
    const currentForm = {...this.state.currentForm}
    this.state.orders.filter(order => !isEmpty(order.location_id)).forEach((order) => {
      if(isEmpty(currentForm[order.location_id])){
        currentForm[order.location_id] = {
          amount: '',
          configurations: {
            include_fuel_surcharge: 'false',
          },
          order_id: order.id,
        }
      }
    })
    this.setState({
      currentForm,
    })
  }

  setDefaultConfigs = () => {
    const currentForm = {}
    this.state.orders.filter(order => !isEmpty(order.location_id)).forEach((order) => {
      currentForm[order.location_id] = {
        amount: '',
        configurations: {
          include_fuel_surcharge: 'false',
        },
        order_id: order.id, //this.props.order_id
      }
    })
    this.setState({
      currentForm,
    })
  }

  getQuoteOrderAmount = () => {
      const orderIds = this.state.orders.map((order) => order.id)
      if(orderIds?.length > 0){
        fetchQuoteOrderAmount(orderIds[0])
        .then((result) => {
          if (result.success) {
            const quote_orders = result.quote_orders || []
            this.setState({
              inProgress: false,
              quote_orders,
            });
          } else {
            this.setState({
              inProgress: false,
              quote_orders: [],
            });
          }
        })
      }
  }

  getOrderQuotes = () => {
    const orderIds = this.state.orders.map((order) => order.id).join(',')
    const orgId = this.props.org_id
    fetchOrderQuoteAmount(orderIds, orgId).then((result) => {
      if (result.success) {
        const quotes_data = result.quotes || []
        const quotes = quotes_data?.length > 0 ? quotes_data.filter( quote => !isEmpty(quote.location_id)) : [];
        const currentForm = {}
        if (quotes.length > 0) {
          quotes.forEach((quote) => {
            const currentConfig = quote.configurations
              ? { ...quote.configurations }
              : {}
            currentForm[quote.location_id] = {
              amount: quote.amount,
              configurations: {
                include_fuel_surcharge: currentConfig.include_fuel_surcharge
                  ? (currentConfig.include_fuel_surcharge == 'true').toString()
                  : 'false',
              },
              order_id: quote.customer_order_id,
            }
          })
          this.setState({
            quotes,
            currentForm,
          }, () => {
            this.setUpdatedData();
          })
        } else {
          this.setDefaultConfigs()
        }
      } else {
        this.setDefaultConfigs()
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false, currentForm })
      }
    })
  }

  handleSave() {
    const { currentForm } = this.state
    const orderQuotations = []
    const orgId = this.props.org_id || ''
    Object.keys(currentForm).forEach((loc) => {
      orderQuotations.push({
        customer_order_id:  currentForm[loc].order_id, //this.props.order_id,
        amount: currentForm[loc].amount ? currentForm[loc].amount : '',
        configurations: currentForm[loc].configurations
          ? { ...currentForm[loc].configurations }
          : {},
        location_id: loc,
      })
    })
    const data = {
      order_quotations: [...orderQuotations],
      organization_id: orgId,
    }
    this.setState({ inProgress: true })
    createOrderQuoteAmount(data).then((result) => {
      if (result.success) {
        this.updatePropValues();
        this.setState({ inProgress: false })
        alertMessage(result.message)
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false })
      }
    })
  }

  renderButton = (order, location_id, key) => {
    const { currentForm } = this.state
    const currentOrder = currentForm[location_id]
      ? currentForm[location_id]
      : { configurations: {} }
    const currentConfig = currentOrder.configurations
      ? currentOrder.configurations
      : {}
    return (
      <Row className="configLableRow">
        <Col xs={23} sm={24} md={24} lg={24}>
          <Checkbox
            checked={
              currentConfig[key] && currentConfig[key] == I18n.t('common.true')
            }
            onChange={(e) =>
              this.handleConfigChange(
                order,
                location_id,
                key,
                e.target.checked
                  ? I18n.t('common.true')
                  : I18n.t('common.false'),
              )
            }
          >
            {I18n.t(`order.configurations.${key}`)}
          </Checkbox>
        </Col>
      </Row>
    )
  }

  renderConfigValue = (order,location_id, key) => {
    const { currentForm } = this.state
    const currentOrder = currentForm[location_id]
      ? currentForm[location_id]
      : { configurations: {} }
    const currentConfig = currentOrder.configurations
      ? currentOrder.configurations
      : {}
    return (
      <Row className="configLableRow">
        <Col xs={24} sm={24} md={24} lg={24}>
          {I18n.t(`order.configurations.${key}`)}: &nbsp;&nbsp;
          {currentConfig[key] && currentConfig[key] == I18n.t('common.true')
            ? 'Yes'
            : 'No'}
        </Col>
      </Row>
    )
  }

  renderQuoteNo = (order) => (
    <>
      {order.quote_order_number ? `${order.quote_order_number}` : 'NA'}
    </>
  )

  renderQuoteAmount = (order) => (
    <div>
      <h3 className='fontSize14'>
      {"Quote Amount"}:&nbsp;
      <b>
      {order.quote_order_number ? order.quote_order_number : 'NA'}
      </b>
      </h3>
    </div>
  )

  renderTransportationCharges = (order) => (
    <div>
      <Row>
        <Col sm={23} xs={23} md={10} lg={7}>
      <h3 className='fontSize14'>
        {I18n.t("quotes.transportation")}:&nbsp;
        </h3>
        </Col>
        <Col sm={24} xs={24} md={13} lg={13} className='fontSize14'>
        {order.transportation_charge !== 0 ? checkNegitive(order.transportation_charge) : checkNegitive('0')}
      </Col>
      </Row>
    </div>
  );

  renderFuelSurcharges =(order) =>(
    <div>
      <Row>
        <Col sm={23} xs={23} md={10} lg={7}>
      <h3 className='fontSize14'>
      {I18n.t("quotes.fuel_surcharge")}:&nbsp;
      </h3>
      </Col>
      <Col sm={24} xs={24} md={13} lg={13} className='fontSize14'>
      {order.fuel_surcharge !== 0 ? checkNegitive(order.fuel_surcharge) : checkNegitive('0')}
      </Col>
      </Row>
    </div>
  )



  renderAdditionalCharges =(order) =>{
    return (
    <div>
      <Row>
      <Col sm={23} xs={23} md={10} lg={7}>
      <h3 className='fontSize14'>
      {I18n.t("quotes.additional_charges")}:&nbsp;
      </h3>
      </Col>
      <Col sm={24} xs={24} md={13} lg={13} className='fontSize14'>
      {order.additional_charges !== 0 ? checkNegitive(order.additional_charges) : checkNegitive('0')}
      </Col>
      </Row>
    </div>
  );
  }

  renderAccessorialCharges =(order) =>(
    <div>
      <Row>
        <Col sm={23} xs={23} md={10} lg={7}>
      <h3 className='fontSize14'>
      {I18n.t("quotes.accessorial_charges")}:&nbsp;
      </h3>
      </Col>
      <Col sm={24} xs={24} md={13} lg={13} className='fontSize14' >
      {order.accessorial_charges !== 0 ? checkNegitive(order.accessorial_charges) : checkNegitive('0')}
      </Col>
      </Row>
    </div>
  )
  // renderDistanceTime =(order) =>(
  //   <div>
  //     <h3 className='fontSize14'>
  //     {I18n.t("quotes.distance_time")}:&nbsp;
  //     {order.quote_order_number ? order.quote_order_number : 'NA'}
  //     </h3>
  //   </div>
  // )

  // renderDistanceWeight =(order) =>(
  //   <div>
  //     <h3 className='fontSize14'>
  //     {I18n.t("quotes.distance_weight")}:&nbsp;
  //     {order.quote_order_number ? order.quote_order_number : 'NA'}
  //     </h3>
  //   </div>
  // )

  // renderAttempts =(order) =>(
  //   <div>
  //     <h3 className='fontSize14'>
  //     {I18n.t("quotes.attempts")}:&nbsp;
  //     {order.quote_order_number ? order.quote_order_number : 'NA'}
  //     </h3>
  //   </div>
  // )

  genExtra = (order) => (
    <div style={{marginRight:'22px'}}>
     <h3>{I18n.t("quotes.quote_amount") }
       <b>
       {checkNegitive(order.quote_amount ? order.quote_amount : 'NA')}
       </b>
     </h3>
  
    </div>
  );
  render() {
    const { currentForm, orders, quote_orders = [] } = this.state
    const { actionType } = this.props
    return (
      <div>
        <Collapse
          defaultActiveKey={[`quoteAmount`]}
          isActive={true}
          style={{ height: "auto" }}
          expandIconPosition="right"
          className="marginBottom10 customCollapse"
        >
          <Panel
            className="textBold"
            header={<Fragment>{I18n.t('order.quotation_amount')}</Fragment>}
            key={`quoteAmount`}
            size="small"
          >
            {actionType === 'edit' ? (
              <Fragment>
                <Row gutter={16}>
                  {orders.filter(order => !_.isEmpty(order.location_id)).map((order) => (
                    <Col xs={12}>
                      <Col>
                        <h4 className="fontSize14">
                          {`${order.label} ${I18n.t('order.label')} (${
                            order.order_no
                          }${!isEmpty(order.type_of_loc) ?  `-${order.type_of_loc}` :''})`}
                        </h4>
                      </Col>
                      <Col className="marginTop10">
                        Quote {this.renderQuoteNo(order)}
                      </Col>
                      <Col className="marginTop10">
                        {this.renderButton(order.id, order.location_id, 'include_fuel_surcharge')}
                      </Col>
                      <Col>
                        <FormItem label={I18n.t('general.amount')}>
                          <Input
                            value={
                              currentForm[order.location_id]
                                ? currentForm[order.location_id].amount
                                : ''
                            }
                            onChange={(e) =>
                              this.handleElementChange(
                                order.id,
                                order.location_id,
                                'amount',
                                e.target.value,
                              )
                            }
                            prefix={<Icon type="dollar" />}
                            style={{ width: '60%' }}
                          />
                        </FormItem>
                      </Col>
                    </Col>
                  ))}
                </Row>

                {checkServiceExistance('COQA') && (
                  <Row>
                    <Col xs={orders.length > 1 ? 20 : 9}>
                      {FormButtons(
                        this.state.inProgress,
                        this.handleSave,
                        null,
                        false,
                      )}
                    </Col>
                  </Row>
                )}
                <Row>{FormErrors(this.state.errors)}</Row>
              </Fragment>
            ) : (
              <Row gutter={16}>
                {orders.map((order) => (
                  <Col sm={24} xs={24} md={12} lg={12} className="textBold">
                    <h4 className="fontSize14">
                      {`${order.label} ${I18n.t('order.label')} (${
                        order.order_no
                      }${!isEmpty(order.type_of_loc) ?  `-${order.type_of_loc}` :''})`}
                    </h4>
                    <Col className="marginTop10">
                      {this.renderConfigValue(
                        order.id,
                        order.location_id,
                        'include_fuel_surcharge',
                      )}
                    </Col>
                    <Col>
                      {I18n.t('general.amount')}: &nbsp;&nbsp;
                      <span className="">
                        {currentForm[order.location_id] && currentForm[order.location_id].amount
                          ? `${I18n.t('general.price_symbol')} ${
                              currentForm[order.location_id].amount
                            }`
                          : '--'}
                      </span>
                    </Col>
                  </Col>
                ))}
              </Row>
            )}
          </Panel>
          {quote_orders.map((order) => (
          <Panel className="textBold" header = {
              <Fragment>
                {I18n.t("quotes.pre_quote_amount")} {""}(#{this.renderQuoteNo(order)})
            </Fragment> 
            
          } extra={this.genExtra(order)}
          >
            <Row gutter={16}>  
            
              <Col sm={24} xs={24} md={12} lg={12} className="textBold">
                <Col>{this.renderTransportationCharges(order.quotes)}</Col>
                <Col>{this.renderFuelSurcharges(order.quotes)}</Col>
                <Col>{this.renderAccessorialCharges(order.quotes)}</Col>
                <Col>{this.renderAdditionalCharges(order.quotes)}</Col>
              </Col>
          
            </Row>
          </Panel>))}

        </Collapse>
      </div>
    )
  }
}

OrderQuoteAmount.propTypes = {
  currentForm: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

OrderQuoteAmount.defaultPropTypes = {
  currentForm: {},
  isNew: true,
}

export default OrderQuoteAmount
