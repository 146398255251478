import React, { Fragment } from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import CopyableContent from "../../common/CopyableContent";
import { checkNegitive } from "../../../common/Common";
import moment from "moment";
import AppConfig from "../../../config/AppConfig";
import {
  Input,
  Col,
  Icon,
  Popconfirm,
  Row,
  Tooltip,
} from "../../../common/UIComponents";
import DeleteIcon from "../../common/DeleteIcon";
import OrderBillingDetails from "../../billing/OrderBillingDetails";
import { padding5, borderLine } from "../../../helpers/invoice_styles"

const DriversWeeklyReportsRowList = (props) => {
  const isAirCargo = props.isAirCargo()
  const data = props.data || [];
  const fontSize12 = { fontSize: 12};
  const totalGrossRevenue = props.calculateTotalGrossRevenue(data);
  const subTotal = props.calculateSubTotal(data);
  let revenueTotal = 0.00;
  let payTotal = 0.00; 
  const showCity = ["NETMOVE"];
  const verifyOrg = () => {
    return _.get(props.orgContext, "currentOrg.code", null);
  }; 
  const verifyOrgCode = verifyOrg();
  const showCityCol = showCity.includes(verifyOrgCode);
  return (
    <div className="ant-table-body">
      <table  style={{ width: isAirCargo ? 820 : 720  }} >
      <thead className="ant-table-thead">
      <tr type="flex">
        <th style={{ ...padding5, width: "90px" }}>
          {I18n.t("drivers_weekly_report.service_date")}
        </th>
        <th style={{ ...padding5, width: 100 }}>
          {I18n.t("drivers_weekly_report.account")}
        </th>
        <th style={{ ...padding5, width: 100 }}>
          {I18n.t("drivers_weekly_report.job_number")}
        </th>
        {isAirCargo && <th style={{ ...padding5, width: 100 }}>{I18n.t("general.hawb")}</th>}
        <th style={{ ...padding5, width: 130 }}>
          {I18n.t("drivers_weekly_report.customer_name")}
        </th>
        {showCityCol && (
          <>
            <th style={{ ...padding5, width: 130 }}>
              {I18n.t("general.city")}
            </th>
            <th style={{ ...padding5, width: 130 }}>
              {I18n.t("general.state")}
            </th>
            <th style={{ ...padding5, width: 130 }}>
              {I18n.t("general.weight")}
            </th>
          </>
        )}
        <th style={{ ...padding5, width: 110 }}>
          {I18n.t("drivers_weekly_report.gross($)")}
        </th>
        <th style={{ ...padding5, width: 110 }}>
          {I18n.t("drivers_weekly_report.net($)")}
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody style={{ fontSize: 12}} className="ant-table-body">
      {data.map((record, index) => {
        if(record.orderIndex === 0){
          revenueTotal = 0.00;
          payTotal = 0.00;
        }
        revenueTotal = revenueTotal + parseFloat(record.gross_revenue || 0);
        payTotal = payTotal + parseFloat(record.gross_pay || 0);
        return (
          <Fragment>
          <tr key={`order${index}`} type="flex" className={((index + 1) % 2) == 1 ? "table-row-light": "table-row-dark"}>
            <td style={{ ...fontSize12 , ...borderLine, width: 162 }}>
              <span>
                {record.orderIndex === 0 && record.date
                  ? moment.utc(record.date).format(AppConfig.dateFormat)
                  : ""}
              </span>
            </td>
            <td style={{ ...fontSize12 , ...borderLine, width: 120 }}>
              <span>{record.account_code}</span>
            </td>
            <td style={{ ...fontSize12 , ...borderLine, width: 200 }}>
              <Fragment>
                {
                  <Row type={"flex"}>
                    <Col>
                      {record?.is_manual && record.is_manual === true && (
                        <Tooltip title="Manual">
                          <sup className="textRed textBold fontSize10">
                            &nbsp;&nbsp;(M)
                          </sup>
                        </Tooltip>
                      )}
                      {record.customer_order_id ? (
                        <OrderBillingDetails
                          record={{ ...record }}
                          showActions={false}
                        />
                      ) : (
                        <CopyableContent text={record.customer_order_number} />
                      )}
                    </Col>
                    <Col></Col>
                  </Row>
                }
              </Fragment>
            </td>
            { isAirCargo && <td style={{ ...fontSize12 , ...borderLine, width: 200 }}>
              {record.hawb ? <CopyableContent text={record.hawb} /> : ""}
            </td>
            }
            <td style={{ ...fontSize12 , ...borderLine, width: 250 }} className="noWrap">
              <span className="textUpperCase">{record.consignee_name}</span>
            </td>
            {showCityCol && (
                <>
                  <td
                    style={{ ...fontSize12, ...borderLine, width: 250 }}
                    className="noWrap"
                  >
                    <span className="textUpperCase">{record.city}</span>
                  </td>
                  <td
                    style={{ ...fontSize12, ...borderLine, width: 250 }}
                    className="noWrap"
                  >
                    <span className="textUpperCase">{record.state}</span>
                  </td>
                  <td
                    style={{ ...fontSize12, ...borderLine, width: 250 }}
                    className="noWrap"
                  >
                    <span className="textUpperCase">
                      {record.shipment_weight}
                    </span>
                  </td>
                </>
              )}
            <td style={{ ...fontSize12 , ...borderLine, width: 110 }}>
              <Fragment>
                {props.isApproved ? (
                  <div>{checkNegitive(record.gross_revenue)}</div>
                ) : (
                  <Input
                    onChange={(e) =>
                      props.handleOnChange(
                        record.dateIndex,
                        record.orderIndex,
                        "gross_revenue",
                        e.target.value
                      )
                    }
                    value={record.gross_revenue}
                    style={{ width: 100, fontSize: 12 }}
                    size="small"
                    type="number"
                    prefix={<Icon type="dollar" />}
                    min={0}
                    onBlur={(e) =>
                      props.handleOnChange(
                        record.dateIndex,
                        record.orderIndex,
                        "gross_revenue",
                        record.gross_revenue
                          ? parseFloat(record.gross_revenue).toFixed(2)
                          : 0.0
                      )
                    }
                  />
                )}
              </Fragment>
            </td>
            <td style={{ ...fontSize12 , ...borderLine, width: 110 }}>
              {props.isApproved ? (
                <div>{checkNegitive(record.gross_pay)}</div>
              ) : (
                <Input
                  onChange={(e) =>
                    props.handleOnChange(
                      record.dateIndex,
                      record.orderIndex,
                      "gross_pay",
                      e.target.value
                    )
                  }
                  value={record.gross_pay}
                  style={{ width: 100, fontSize: 12 }}
                  size="small"
                  type="number"
                  prefix={<Icon type="dollar" />}
                  min={0}
                  onBlur={(e) =>
                    props.handleOnChange(
                      record.dateIndex,
                      record.orderIndex,
                      "gross_pay",
                      record.gross_pay
                        ? parseFloat(record.gross_pay).toFixed(2)
                        : 0.0
                    )
                  }
                />
              )}
            </td>
            <td style={{ ...fontSize12 , ...borderLine, width: 166 }}>
              <Row type="flex" gutter={8} align="middle" justify="end">
                {/*record.is_manual === true &&*/}
                {!props.hasNew && (
                  <Col>
                    <Tooltip placement="topLeft" title="Delete">
                      <Popconfirm
                        placement="topRight"
                        title={I18n.t("messages.delete_confirm")}
                        onConfirm={() =>
                          props.deleteClick(
                            record.driver_pay_report_id,
                            record.id
                          )
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon styleObj={{ fontSize: 16 }} />
                        &nbsp;&nbsp;
                      </Popconfirm>
                    </Tooltip>
                  </Col>
                )}
                {!props.hasNew && index + 1 === props.data.length && (
                  <Col>
                    <Tooltip placement="topLeft" title={I18n.t("order.add")}>
                      <Icon
                        type="plus-circle"
                        theme="filled"
                        onClick={() => props.handleAddRow(record)}
                        style={{ fontSize: 18 }}
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </td>
          </tr>
          {
            record.is_last_record ? 
            <tr key={`rowTotal`} className="textBold">
            <td colSpan={isAirCargo ? 5 : showCityCol ? 7 : 4 } style={{ ...borderLine}} className="alignRight paddingRight5">Sub Total($)</td>
            <td className="alignRight paddingRight5" style={{ ...borderLine}}>{checkNegitive(revenueTotal)}</td>
            <td className="alignRight paddingRight5" style={{ ...borderLine}}>{checkNegitive(payTotal)}</td>
            <td>{" "}</td>
           </tr>
           :
           <Fragment />
          }
          </Fragment>
        );
      })}
      {
         <tr key={`rowTotal`} className="textBold">
          <td colSpan={isAirCargo ? 5 : showCityCol ? 7 : 4 } className="alignRight paddingRight5">Total($)</td>
          <td className="alignRight paddingRight5">{checkNegitive(totalGrossRevenue)}</td>
          <td className="alignRight paddingRight5">{checkNegitive(subTotal)}</td>
          <td>{" "}</td>
         </tr>
      }
      </tbody>
      </table>
    </div>
  );
};
export default React.memo(DriversWeeklyReportsRowList);
