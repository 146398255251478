import React, { useEffect } from 'react';
import { Row, Col, Select, Button, Form } from 'antd';
import { getAccounts } from '../../helpers/common';

const { Option } = Select;

function IntegrationsFilter (props) {
  const { organizations = [], selectedAccount } = props;
  const [ selectedOrg, setSelectedOrg ] = React.useState(organizations);
  const [ accounts, setAccounts] = React.useState([]);

  useEffect(() => {
    if (organizations.length > 0) {
      // setSelectedOrg(organizations[ 0 ].id);
      handleOrgChange(organizations[ 0 ].id);
    }
  }, [ organizations ]);

  const handleOrgChange = (value) => {
    setSelectedOrg(value);
    props.onOrgChange(value);
    getAccounts(value, null , true).then((accounts) => {
      setAccounts(accounts);
    });
  };

  return (
    <Row gutter={8}>
      <Col span={ 4 }>
        <Form.Item label="Organization" name="organization">
          <Select
            style={ { width: '100%' } }
            placeholder="Select organization"
            value={ selectedOrg }
            // onChange={ (value) => setSelectedOrg(value) }
            onChange={handleOrgChange}
          >
            { organizations.map((org) => (
              <Option key={ org.id } value={ org.id }>
                { org.name }
              </Option>
            )) }
          </Select>
        </Form.Item>
      </Col>
      <Col span={ 4 }>
        <Form.Item label="Account" name="account">
          <Select
            style={ { width: '100%' } }
            value={ selectedAccount }
            placeholder="Select account"
            onChange={ (value) => props.onAccountChange(selectedOrg , value)}
          >
            { accounts.map(account => (
              <Option value={ account.id }>{ account.name }</Option>
            )) }
          </Select>
        </Form.Item>
      </Col>
      {/* <Col span={ 24 }>
        <Button type="primary" style={ { marginRight: 10 } }>Search</Button>
        <Button>Clear</Button>
      </Col> */}
    </Row>
  );
}

export default IntegrationsFilter;
