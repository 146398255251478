import React from 'react';
import _, { isEmpty} from "lodash";
import { Card, Checkbox, Col, Row } from 'antd';
import I18n from '../../common/I18n';
import ConsigneeDetailsForm from './ConsigneeDetailsForm';
import FormErrors from '../common/FormErrors';
import AppConfig from "../../config/AppConfig"
import QuoteAppointment from './QuoteAppointment';

function ConsigneeDetails (props) {
  const { quoteFormData, handleMultipleFieldsChange, handleItemOptionOnChange, handleOrderDetailsChange, isMapLoaded, handleDateChange, askConfirmMessage, handleConfirmChange, clearPreference, accountConfig, handleOnAddressChange , details , errors, isMilitaryTime , span, currentQuote,handleWarehouseCheck,warehouseChecked} = props;


  return (
    <Col key={ details.key } span={ span }>
      <Card size="small" title={ `${details.label} Details` } style={{
        height: '100%',
      }}>
        { _.isObject(quoteFormData[ details.key ]) && (
          <ConsigneeDetailsForm 
            formData={ quoteFormData[ details.key ] || {} }
            handleMultipleFieldsChange={ (data) => handleMultipleFieldsChange(details.key, data) }
            handleOnAddressChange={ (element, value, obj) =>
              handleOnAddressChange(details.key, element, value, obj)
            }
            handleOnChange={ (element, value) =>
              handleOrderDetailsChange(details.key, element, value)
            }
            map={ isMapLoaded }
            predefined_locations={
              //  _.isEmpty(accountConfig.predefined_locations) ? 
              //  "false" : accountConfig.predefined_locations
              true
            }
            request_from="QUOTE"
            includePredefinedStops={ true }
            editableFields={ [
              "email",
              "phone_number_one",
              "phone_number_two",
              "l_address",
            ] }
          />
        ) }

        { _.isObject(quoteFormData[ details.key ]) && (
          <Card
            title={ I18n.t("appointmentForm.preference_title") }
            size="small"
          >

            <QuoteAppointment
              appointment={
                quoteFormData[ details.key ].preferences.length > 0 ?
                  quoteFormData[ details.key ].preferences[ 0 ] : {} }
              itemOnChange={ (element, value) =>
                handleDateChange(details.key, 0, element, value)
              }
              itemChange={ (element, value) =>
                askConfirmMessage(details.key, 0, element, value)
              }
              hasDeliveryZipcodesConfig={
                !_.isEmpty(accountConfig.delivery_zipcodes) && accountConfig.delivery_zipcodes === "true"
              }
              feasible_dates={ quoteFormData[ details.key ].feasible_dates || [] }
              confirmChange={ (element, value) =>
                handleConfirmChange(details.key, 0, element, value)
              }
              itemOptionOnChange={ (
                element,
                value,
                checked,
                type
              ) =>
                handleItemOptionOnChange(
                  details.key,
                  0,
                  element,
                  value,
                  checked,
                  type
                )
              }
              clearPreference={ (index) =>
                clearPreference(details.key, index)
              }
              size="small"
              pagination={ { position: "none" } }
              feasibleDates
              hasCustom={
                quoteFormData ?
                  quoteFormData[ details.key ].preferences.filter(
                    (preference) =>
                      !isEmpty(preference) &&
                      !isEmpty(preference.item_option) &&
                      preference.item_option.includes("CUSTOM")
                  ).length > 0 : true
              }
              showConfirmation={
                false //!this.state.isNew && currentOrder.status !== "NEW"
              }
              showSerialNos={ false }
              disabledStartHours={ AppConfig.dispatcherDisabledStartHours }
              disabledEndHours={ AppConfig.dispatcherDisabledEndHours }
              endHour={ AppConfig.dispatcherEndHour }
              optingType={ "radio" }
              showExpedite
              isMilitaryTime={isMilitaryTime}
              details={ details }
              currentQuote={ currentQuote }
            />
          </Card>
        ) }
        <Row>{ FormErrors(errors) }</Row>
        {currentQuote.type_of_order === 'T' && details.key === 'pickup_params' ? (
          <Row className="marginTop10">
            <Checkbox
            onChange={ (e) =>
              handleWarehouseCheck(e.target.checked)
            }
            checked={warehouseChecked}
            value={warehouseChecked}
            >
              <span className="textBold fontSize14">
                Intermediate Storage Needed (This will get items back to the warehouse.)
              </span>
            </Checkbox>
          </Row>
        ) : null}
      </Card>
    </Col>
  );
}

export default ConsigneeDetails;