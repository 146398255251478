import React from "react";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import { formatByTimeConfig } from "../../../helpers/common";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";
import ImageViewer from "../../common/ImageViewer";
// import img1 from "../../../../images/deli1.jpg"
// import img2 from "../../../../images/deli2.jpg"

function ListBilling(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      order_number: 1001,
      type_of_order: "Pickup",
      hawb: "123456",
      mawb: "123456",
      customer: "John Doe",
      warehouse_name: "Test Warehouse",
      account_code: "ABC123",
      account_name: "abc123",
      datetime:"2022-09-15T10:00:00Z",
      los:"White Glove",
      driver_code:"DC",
      total_amount:1000,
      reference_1: "700214577",
      reference_2: "123456",
      quantity : "2",
      weight: "120 lbs",
      status: "READY FOR BILLING",
      street : "Bridge",
      city : "New York",
      zipcode : "52365",
      company_name : "ABC123",
      routing : "ABCD",
      frequency_invoice_number: '123456',
      dim_weight:"140 lbs",
      driver_notes:"This is driver notes",
      exception_message:"This is test Exception Meassage",
      is_pod_signed_bol_exist : "Y",
      vehicle_type: "ABC234",
    },
    {
      key: 2,
      order_number: 1002,
      type_of_order: "Delivery",
      hawb: "123456",
      mawb: "123456",
      customer: "Jane Smith",
      warehouse_name: "Test Warehouse",
      account_code: "ABC123",
      account_name: "bcd123",
      datetime:"2022-09-15T10:00:00Z",
      los:"Threshold",
      driver_code:"DC",
      total_amount:2000,
      frequency_invoice_number: '123456',
      reference_1: "700214577",
      reference_2: "123456",
      quantity : "3",
      weight: "150 lbs",
      status: "APPROVED",
      street : "Gold",
      city : "Brooklyn",
      zipcode : "52369",
      company_name : "ABC123",
      routing : "EFGH",
      dim_weight:"140 lbs",
      driver_notes:"This is driver notes",
      exception_message:"This is test Exception Meassage",
      is_pod_signed_bol_exist : "N",
      vehicle_type : "ABC456"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "customer",
      title: <EndUserTitle/>,
      dataIndex: "customer",
    },
    {
      key: "warehouse_name",
      title: I18n.t("location.locations"),
      dataIndex: "warehouse_name",
    },
    {
      key: "account_code",
      title: I18n.t("account.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "datetime",
      title: I18n.t("invoices.completion_date"),
      dataIndex: "datetime",
      render: (text, record) => {
        return (
          <div>
            {
              formatByTimeConfig(
                moment.utc(text),
                false,
                'DD MMM  HH:mm',
                'DD MMM  hh:mm A',
              )
            }
          </div>
        );
      }
    },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "los",
    },
    {
      key: "frequency_invoice_number",
      title: I18n.t("account.billing.invoice_no"),
      dataIndex: "frequency_invoice_number",
    },
    {
      key: "driver_code",
      title: I18n.t("general.Driver"),
      dataIndex: "driver_code",
    },
    {
      key: "amount",
      title: I18n.t("invoices.amount"),
      dataIndex: "total_amount",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "quantity",
      title: I18n.t("order.qty"),
      dataIndex: "quantity",
    },
    {
      key: "dim_weight",
      title: I18n.t("configurations.dim_weight"),
      dataIndex: "dim_weight",
    },
    {
      key: "driver_notes",
      title: I18n.t("order.driver_notes"),
      dataIndex: "driver_notes",
    },
    {
      key: "exception_message",
      title: I18n.t("general.exception_message"),
      dataIndex: "exception_message",
    },
    {
      key: 'is_pod_signed_bol_exist',
      title: 'POD/Signed BOL',
      dataIndex: 'is_pod_signed_bol_exist',
    },
    {
      key: "vehicle_type",
      title: I18n.t("order.vehicle_type"),
      dataIndex: "vehicle_type",
    },
    {
      key: "weight",
      title: I18n.t("general.weight"),
      dataIndex: "weight",
    },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "street",
      title: I18n.t("general.street"),
      dataIndex: "street",
    },
    {
      key: "city",
      title: I18n.t("general.city"),
      dataIndex: "city",
    },
    {
      key: "zipcode",
      title: I18n.t("general.zipcode"),
      dataIndex: "zipcode",
    },
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
    
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = 'billing_list_columns'

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )
}

export default ListBilling;
