import React, { Component } from "react";
import _ from "lodash";
import { Tabs } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { checkServiceExistance } from "../../helpers/common";
import GeneralSettings from "../../containers/GeneralSettings";
import Contacts from "./Contacts/Contacts";
import ContactGroup from "./contact_group/ContactGroup";
import NotificationTemplates from "./NotificationTemplates";
import NotificationMsgTemplates from "./NotificationMsgTemplates";
import ScheduledCountNotification from "./ScheduledCountNotification";
import AgingReportConfiguration from "./AgingReportConfiguration";
import Disclaimer from "./Disclaimer";
import DriverTypes from "../common/DriverTypes";

const { TabPane } = Tabs;
class EventSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account || {},
      accountId: this.props.accountId || "",
      isOrgLevelSetting: this.props.isOrgLevelSetting,
      setActiveKey: "general_setting",
    };
  }

  goToTab = (key) => {
    this.setState({ setActiveKey: key });
  };

  renderNotificationTemplates = (refMode = "account") => {
    return refMode === "account" ? (
      // <NotificationTemplates account={this.state.account} refMode={refMode} />
      <NotificationMsgTemplates account={this.state.account} refMode={refMode} />
    ) : (
      // <NotificationTemplates refMode={refMode} />
      <NotificationMsgTemplates refMode={refMode} />
    );
  };

  render() {
    const { accountId, isOrgLevelSetting } = this.state;
    const {organizationSettings , isMilitaryTime} = this.props
    const { account } = this.props;
    return (
      <div className="child-card-container">
        <Tabs
          activeKey={this.state.setActiveKey}
          onChange={this.goToTab}
          type="card"
        >
          {checkServiceExistance("ACNF") && (
            <TabPane
              tab={I18n.t("general.general_config")}
              key="general_setting"
              className="settingSubTabScroll"
            >
              <GeneralSettings scroll = {{y:"calc(100vh - 500px)"}} accountId={accountId} organizationSettings={organizationSettings} isMilitaryTime={isMilitaryTime} />
            </TabPane>
          )}
          <TabPane tab={I18n.t("menu.contacts")} key="contacts">
            {isOrgLevelSetting ? (
              <Contacts isOrgLevel={true} />
            ) : (
              <Contacts account={account} isOrgLevel={false} />
            )}
          </TabPane>
          <TabPane tab={I18n.t("menu.contact_group")} key="contact_group">
            {isOrgLevelSetting ? (
              <ContactGroup isOrgLevel={true} />
            ) : (
              <ContactGroup account={account} isOrgLevel={false}/>
            )}
          </TabPane>
          {isOrgLevelSetting && (
            <TabPane
              tab={I18n.t("menu.scheduled_notification_count")}
              key="scheduled_notification_count"
              className="settingSubTabScroll"
            >
              <ScheduledCountNotification />
            </TabPane>
          )}
          <TabPane
            tab={I18n.t("menu.notification_templates")}
            key="notification_templates"
          >
            {isOrgLevelSetting
              ? this.renderNotificationTemplates("org")
              : this.renderNotificationTemplates("account")}
          </TabPane>
          {isOrgLevelSetting && (
          <TabPane
              tab={I18n.t("menu.aging_report_configurations")}
              key="aging_report_configurations"
              className="settingSubTabScroll"
            >
              <AgingReportConfiguration/>
            </TabPane>
          )}
          {isOrgLevelSetting && (
            <TabPane tab={I18n.t("menu.disclaimer")} key="disclaimer" className="settingSubTabScroll">
              <Disclaimer isOrgLevelInstruction />
            </TabPane>
          )}
          {isOrgLevelSetting && (
          <TabPane tab={I18n.t("menu.driver_types")} key="driver_types" className="settingSubTabScroll">
              <DriverTypes isOrgLevelInstruction />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default EventSettings;
