import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs } from "../../common/UIComponents";
import CustomerOrderRules from "./CustomerOrderRules";
import I18n from "../../common/I18n";
import OrderTypeToLosMapping from "./OrderTypeToLosMapping";
const { TabPane } = Tabs;

const AccountRules = ({ account = {}, accountId = {}, parentKey }) => {
  const [activeKey, setActiveKey] = useState('general_rules')
  const goToTab = (key) => {
    setActiveKey(key)
  }
  useEffect(() => {
    if(parentKey !== 'account_rules'){
      setActiveKey('general_rules')
    }
  }, [parentKey])
  return (
    <div className="child-card-container">
      <Tabs
        activeKey={activeKey}
        onChange={goToTab}
        type="card"
    >
      <TabPane tab={I18n.t("menu.rules")} key="general_rules">
        <CustomerOrderRules
          account={account}
          accountId={accountId}
          activeKey={activeKey}
        />
      </TabPane>
      <TabPane tab={"LoS To Order Types"} key="los_rules">
        <OrderTypeToLosMapping
            account={account}
            accountId={accountId}
            activeKey={activeKey}

        />
      </TabPane>
    </Tabs>
    </div>
    
  );
};

AccountRules.propTypes = {
  account: PropTypes.shape.isRequired,
  accountId: PropTypes.string,
};

AccountRules.defaultProps = {
  account: {},
  accountId: "",
};

export default AccountRules;
