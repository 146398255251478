import React, { Fragment } from 'react';
import BaseList from '../../BaseList';
import PropTypes from 'prop-types';
// import { isEmpty } from '../../common/Common';
// import I18n from '../../common/I18n';
// import Leg from '../../components/Tasks/Legs';

// import AppConfig from '../../config/AppConfig';
// import { formatDimension } from '../../helpers/orders';
// import Copyable from '../common/Copyable';

class UnprocessedReasonList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      // {
      //   title: '#',
      //   key: 'index',
      //   render: (text, record, index) => index + 1,
      // },
      {
        key: 'page_number',
        title: "Page No",
        dataIndex: 'page_number',
        align:"center",
      },
     
      {
        key: 'reason',
        title: 'Reason',
        className: "noWrap",
        dataIndex: 'reason',
      }]

    }
    static propTypes = {
      data: PropTypes.arrayOf(
        PropTypes.shape({
          page_number: PropTypes.number.isRequired,
          reason: PropTypes.string.isRequired,
          // Add other keys and their types if needed
        })
      ).isRequired,
    };

}

export default UnprocessedReasonList;
