import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Col, Row, Card, Empty } from "../../common/UIComponents";
import {
  checkNegitive,
  findNumberTotal,
  findTotal,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import OrderInvoiceLines from "./OrderInvoiceLines";
import { alignCenter, alignRight, borderCollapseInherit, heading, invoiceItem, textBold, fontSize10, alignLeft } from "../../helpers/invoice_styles";
import { findSpanValue } from "../../helpers/billing";
class OrderBillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: this.props.invoiceData || [],
      inProgress: false,
    };
  }

  render() {
    const invoiceData = this.props.invoiceData || {};
    const totalAmount = invoiceData.total_amount;
    const invoice = invoiceData.invoice_lines || [];
    const invoiceCategories = _.uniq(invoice.map((line) => line.item_type));
    const invoiceOrders = _.uniq(invoice.map((line) => line.customer_order_number));
    //const total = findNumberTotal(invoice, "item_value", null, decimalPoints);
    return (
      <div className="invoice_details">
        <Row gutter={16}>

          <div>
            <Card size="small" title={<Fragment>
                <span className="fontSize20 textBold">Invoice Items</span>&nbsp;
                <span className="textBold fontSize20">({I18n.t("invoices.total")}: {checkNegitive(totalAmount)})</span>
              </Fragment>}
              >
              <Row>
                {/* <Col sm={24} xs={24} md={24} lg={12}>

                  <OrderInvoiceLines
                    data={invoice}
                    pagination={{ position: 'none' }}
                  />
                </Col> */}
                {/* { false && invoiceCategories.map((category) => {
                    const invoiceLines = _.filter(
                      invoice,
                      (line) => line.item_type === category
                    );
                    const subTotal = findNumberTotal(
                      invoiceLines,
                      "item_value"
                    );
                    return (
                      <Col sm={24} xs={24} md={24} lg={12}>
                        <h4 style={{ textAlign: "left" }}>{category}</h4>
                        <OrderInvoiceLines
                          data={invoiceLines}
                          footer={() => (
                            <div
                              className="alignRight textBold"
                              style={{ paddingRight: 70 }}
                            >
                              {I18n.t("invoices.sub_total")}
                              :&nbsp;&nbsp;
                              {subTotal}
                            </div>
                          )}
                          pagination={{ position: 'none'}}
                        />
                      </Col>
                    );
                  })} */}

                <Col sm={24} xs={24} md={24} lg={12}>
                  {invoiceOrders.length > 0 ?
                    <Fragment>
                      <table
                        border="1px"
                        cellpadding="5px"
                        cellspacing="0px"
                        width="100%"
                        style={{ ...borderCollapseInherit }}
                      > 
                        <thead>
                          <tr>
                            <td width="4%" style={{ ...heading, ...alignCenter }}>
                              #
                            </td>
                            <td width="58%" className="heading">
                              {I18n.t("general.description")}
                            </td>
                            <td width="16%" className="heading">
                              {I18n.t("invoices.gl_code")}
                            </td>
                            <td width="22%" className="heading alignRight">
                              {I18n.t("invoices.amount")}
                            </td>
                          </tr>
                        </thead>
                        <tbody style={{ height: '60vh', overflowY: 'auto'}}>
                          {invoiceOrders.map((order, index) => {
                            // for not showing zero values in adjustments
                            const invoiceLines = _.filter(
                              invoice,
                              (line) => line.customer_order_number === order
                            );
                            // const subTotal = findNumberTotal(
                            //   invoiceLines,
                            //   "item_value"
                            // );
                            return (invoiceLines.length > 0 ? (
                              <Fragment>

                                <tr>
                                  <td style={{ ...invoiceItem, ...alignCenter }}>
                                    {index + 1}
                                  </td>
                                  <td style={{ ...invoiceItem, ...textBold, ...alignLeft }}>
                                    #{order}&nbsp;

                                  </td>
                                  <td style={{ ...invoiceItem }}>&nbsp;</td>
                                  <td style={{ ...invoiceItem, ...alignRight }}>
                                    &nbsp;
                                  </td>
                                </tr>

                                {invoiceLines// filter(invoiceItem => invoiceItem.invoice_value !== 0)
                                  .map((item, itemIndex) => {
                                    const rowSpan = findSpanValue(invoiceLines, item, itemIndex);;
                                    return (
                                      <tr>
                                        <td style={{ ...invoiceItem, ...alignCenter }}>
                                          {/* {sectionType === "adjustment_charges"
                                ? itemIndex + 1
                                : ""} */}
                                        </td>
                                        <td style={{ ...invoiceItem, ...alignLeft }}>
                                          {item.item_name ? (
                                            <i style={{ ...fontSize10 }}>
                                              {" "}
                                              {item.item_name}{" "}
                                            </i>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        {
                                          rowSpan !== 0 && <td style={{ ...invoiceItem }} rowSpan={rowSpan}>
                                            {rowSpan === 1 ? !isEmpty(item.gl_code) &&
                                              item.gl_code ? (
                                              item.gl_code
                                            ) : (
                                              <Fragment>&nbsp;</Fragment>
                                            ) : rowSpan}
                                          </td>
                                        }
                                        {rowSpan !== 0 && <td style={{ ...invoiceItem, ...alignRight }} rowSpan={rowSpan}>
                                          {checkNegitive(item.item_value)}
                                        </td>
                                        }
                                      </tr>
                                    )
                                  })}
                              </Fragment>
                            ) : (
                              <Fragment />
                            ));
                          })}
                        </tbody>
                      </table>
                      {/* <Row>
                        <Col xs={24} className="alignRight">
                          <h4 className="textBold fontSize20 " style={{ paddingRight: 70 }}>
                            {I18n.t("invoices.total")}: {checkNegitive(totalAmount)}
                          </h4>
                        </Col>
                      </Row> */}
                    </Fragment>

                    :
                    <Empty />
                  }
                </Col>
              </Row>
            </Card>
          </div>



        </Row>
      </div>
    );
  }
}
export default OrderBillingDetails;
