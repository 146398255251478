/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Divider,
  Col,
  Row,
  Tabs,
  Checkbox,
  Select,
  Button,
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  fetchPermitViews,
  savePageAccess,
} from "../../api/ModuleActions";
import { UserRoutes } from "../../config/Routes";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import { renderAlertMessage } from "../../helpers/common";


class ScreenManageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterModules: props.masterModules || [],
      currentModules: props.currentModule || {},
      inProgress: false,
      isNew: false,
      errors: [],
      permitViews: [],
      currentRole: {},
    };
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.currentRole, prevProps.currentRole)) {
      this.setState(
        {
          currentRole: { ...this.props.currentRole },
        },
        () => {
          this.getPermitViews();
        }
      );
    }
  }

  getPermitViews = () => {
    this.setState({ inProgress: true });
    const { currentRole } = this.state;
    fetchPermitViews(currentRole.id).then((result) => {
      if (result.success) {
        const screens = result.screens || [];
        this.setState({
          permitViews: [...screens],
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleActionOnChange = (moduleCode, opCodes) => {
    this.props.handleActionOnChange(moduleCode, opCodes);
  };

  handleSelectAll = (value) => {
    if (value === true) {
      const screens = _.sortBy(
        UserRoutes.filter((rec) => !isEmpty(rec.sequence)),
        "sequence"
      ).map((rec) => rec.code);
      this.setState({
        permitViews: [...screens],
      });
    } else {
      this.setState({
        permitViews: [],
      });
    }
  };

  handleSave = () => {
    const { currentRole, permitViews } = this.state;
    const { setPermitViews } = this.props.userContext;
    const data = {
      permit_views: permitViews,
      role_id: currentRole.id || "",
    };
    this.setState({ inProgress: true });
    savePageAccess(data).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false, errors: [] });
        alertMessage(I18n.t("messages.saved"));
        setPermitViews([...permitViews]);
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ errors: result.errors, inProgress: false });
      }
    });
  };

  render() {
    const { permitViews } = this.state;
    const screens = _.sortBy(
      UserRoutes.filter((rec) => !isEmpty(rec.sequence)),
      "sequence"
    );
    return (
      <div>
        <Row>
          <Col xs={24} className="alignLeft fontSize14">
            <Checkbox
              onChange={(e) => this.handleSelectAll(e.target.checked)}
              indeterminate={
                permitViews.length > 0 &&  permitViews.length < screens.length
              }
              checked={
                permitViews.length > 0 && screens.length === permitViews.length
              }
            >
              Select {I18n.t("general.all")}
            </Checkbox>
          </Col>
        </Row>
        <Row className="marginTop10">
          <Col>
            {screens.length > 0 ? (
              <Checkbox.Group
                value={permitViews}
                onChange={(e) => this.handleOnChange("permitViews", e)}
                style={{ width: "100%" }}
              >
                {screens.map((rec, index) => {
                  return (
                    <Row style={{ zoom: "90%" }}>
                      <Col xs={4} className="textBold">
                        <Checkbox value={rec.code}>{rec.title}</Checkbox>
                      </Col>
                    </Row>
                  );
                })}
              </Checkbox.Group>
            ) : (
              <Fragment />
            )}
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "center",
          }}
        >
          <Col span={24}>
            <Button
              type="danger"
              className="floatBtnCancel"
              onClick={this.props.onCancel}
              icon="close"
            >
              {I18n.t("general.cancel")}
            </Button>
            &nbsp;
            <Button
              type="primary"
              className="floatBtnSave"
              onClick={this.handleSave}
              icon="save"
              loading={this.state.inProgress}
            >
              {I18n.t("general.save")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}


export const ScreenManagement = withRouter((props) => {
  const userContext= useContext(UserContext);
  return (
    <ScreenManageWrapper
      userContext={userContext}
      {...props}
    />
  );
});

export default ScreenManagement;

ScreenManagement.propType = {
  currentRole: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  filterPlaceHolder: PropTypes.shape(),
  setEditModalVisible: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ScreenManagement.defaultProps = {
  currentRole: {},
};