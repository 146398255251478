/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Col, Row, Search, Spin } from '../common/UIComponents';
import { alertMessage, isEmpty } from '../common/Common';
import I18n from '../common/I18n';
import {
  deleteAccount, saveAccount,
  fetchAccounts, fetchPrimaryAccountCodes,
  fetchAccount, fetchSubAccounts, generateBol,
} from '../api/Account';
import List from '../components/accounts/List';
import Form from '../components/accounts/Form';
import BaseModal from '../components/BaseModal';
import userStore from '../stores/UserStore';
import AppConfig from '../config/AppConfig';
import { checkServiceExistance, removeSpecialCharacters, renderAlertMessage } from '../helpers/common';
// import DataTransferIcon from "../../images/data-transfer.svg";
import AccountCopy from '../components/accounts/AccountCopy';
import AccountUploadForm from '../components/accounts/AccountUploadForm';
import AccountClone from '../components/accounts/AccountClone';
import { withRouter } from 'react-router';
import { WarehouseContext } from '../context/WarehouseContext';
import { OrgContext } from '../context/OrgContext';
import { RecordsPerPage } from '../components/orders/RecordsPerPage';
import { UserContext } from '../context/UserContext';
import { ScreenKeys } from './constants';

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      inProgress: false,
      showForm: false,
      accounts: [],
      currentAccount: {},
      isPrimaryCurrentAccount: false,
      primaryAccountCodes: [],
      orgId: this.props.orgId || userStore.getStateValue('selectedOrg'),
      isNew: true,
      sortOrder: 'ascend',
      token: props && props.location && !isEmpty(props.location.token) ? props.location.token : '',
      showAccountCopyModal : false,
      showAccountUploadForm : false, 
      madeChangesinAccountUploadForm : false,
      recordsPerPage: props.recordsPerPage,
      filterPlaceHolder: {
        sortBy: "name",
        sortByType: "ascend",
      },
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.getAccounts = _.debounce(this.getAccounts, 500);
    this.getPrimaryAccountCodes = _.debounce(this.getPrimaryAccountCodes, 500);
    this.getSubAccounts = _.debounce(this.getSubAccounts, 500);
  }


  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getAccounts()
        updateRecordsPerPage(ScreenKeys.ACCOUNTS_LISTING, value);
      }
    );
  };

  onSearch = (value) => {
  };


  getAccountsPageData = () => {
    this.getAccounts();
    if(checkServiceExistance('PA')){
      this.getPrimaryAccountCodes();
    }
  }

  componentDidMount() {
    this.getAccountsPageData();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.showAccountUploadForm !== this.state.showAccountUploadForm) {
      if (!this.state.showAccountUploadForm && this.state.madeChangesinAccountUploadForm) {
        this.setState({ madeChangesinAccountUploadForm: false });
        this.getAccountsPageData();
      }
    }
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) || !_.isEqual(prevProps.warehouseFilter.warehouses , this.props.warehouseFilter.warehouses) && (!this.props.warehouseFilter.isFetchingWarehouses)) {
      this.tableOptions.pagination.current = 1;
      this.getAccountsPageData();
    }
  }

  bolGenerate = (id) => {
    const org_id= this.state.orgId;
    this.setState({ inProgress: true });
    generateBol(id, org_id)
      .then((result) => {
        if (result.success) {
          alertMessage(result.response.message, 10);
          this.setState({ inProgress: false });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  checkToken = () => {
    if(this.props && this.props.location && !isEmpty(this.props.location.token)){
      this.setState({
        token: this.props.location.token,
      }, ()=>{
        this.setInitialData();
      })
    }else{
      this.setInitialData();
    }
  }

  setInitialData = () => {
    
  }

  // onTableChange = (pagination, filters, sorter) => {
  //   this.tableOptions = { pagination, filters, sorter };
  //   this.getAccounts();
  // }

  onTableChange = (pagination, filters, sorter) => {
    const currentPage = this.tableOptions.pagination && this.tableOptions.pagination.current ? this.tableOptions.pagination.current : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getAccounts();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getAccounts();
        }
      );
    }
  }

  onSearch = text => {
    // if(!!text === false) return;
    const token = text.trim();
    this.setState({
      token,
    },() => {
      if (token === "") {
        this.tableOptions = { pagination: {}, filters: {}, sorter: {}};
      } else {
        this.tableOptions.searchText = text;
      }
      this.tableOptions.pagination.current = 1;
      this.getAccounts();
    })
    
  }


  getPrimaryAccountCodes = () => {
    this.setState({ inProgress: true });
    fetchPrimaryAccountCodes({
      skip_wh_check: false,
    })
      .then((result) => {
        if (result.success) {
          const accounts = result.accounts || [];
          this.setState({
            primaryAccountCodes: accounts,
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  getAccounts = () => {
    const page = this.tableOptions.pagination.current;
    const searchText = this.state.token || '';
    const  perPage  = this.state.recordsPerPage;
    this.setState({ inProgress: true, currentAccount: {} });
    fetchAccounts('', page, perPage, this.state.sortOrder, searchText, this.state.filterPlaceHolder)
      .then((result) => {
        if (result.success) {
          const accounts = result.accounts || [];
          this.setState({
            accounts,
            inProgress: false,
            pagination: accounts.length > 0 && result.pagination ? result.pagination : {},
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }


  clearForm = () => {
    this.setState({
      currentAccount: {},
      isNew: true,
    });
  }

  closeFormWindow = () => {
    this.setState({
      showForm: false,
    }, () => {
      this.getAccounts(), 
      this.getPrimaryAccountCodes();
    });
  };

  showFormWindow = () => {
    this.setState({
      currentAccount: {},
      showForm: true,
      isNew: true,
    });
  };


  handleEditClick = (id) => {
    // const index = _.findIndex(this.state.accounts, ['id', id]);
    // const account = Object.assign({}, this.state.accounts[index]);
    fetchAccount(id)
      .then((result) => {
        if (result.success) {
          const account = result.account || {};
          if (isEmpty(account.manager_emails)){
            account.manager_emails=[]
          } else {
            account.manager_emails = account.manager_emails.filter(email => !isEmpty(email));
          }
          if (isEmpty(account.warehouse_ids)){
            account.warehouse_ids=  []; //''
          } else {
            // account.warehouse_ids= _.isArray(account.warehouse_ids) && account.warehouse_ids.length > 0 ? account.warehouse_ids[0] : '';
            account.warehouse_ids= _.isArray(account.warehouse_ids) && account.warehouse_ids.length > 0 ? [...account.warehouse_ids] : [];
          }
          if (!isEmpty(account.phone_number)){
            account.phone_number= account.phone_number.replace(/[^+\w\s]/gi, '')
          }
          // account.logo = !isEmpty(account.logo) && account.logo.url ? account.logo.url : '', 
          this.setState({
            currentAccount: account,
            isNew: false,
            showForm: true,
            isPrimaryCurrentAccount: result.is_primary_account,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  gotoAccountSettings = (id) => {
    this.props.history.push(`/accounts/${id}/settings`);
  }

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteAccount(id, { org_id: this.state.orgId })
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          if (this.state.accounts.length === 1) {
            if (this.tableOptions.pagination && this.tableOptions.pagination.current > 1) {
              this.tableOptions.pagination.current = this.tableOptions.pagination.current - 1;
              this.getAccounts();
              this.getPrimaryAccountCodes();
            } else {
              this.getAccounts();
              this.getPrimaryAccountCodes();
            }
          } else {
            this.getAccounts();
            this.getPrimaryAccountCodes();
          }

          this.setState({
            inProgress: false,
          });
         const updatedAccounts = this.props.warehouseFilter.warehouseWithAccounts.map((wh) => {
          const whAccounts = wh.accounts.filter((acc) => acc.id !== id);
          return{
            ...wh,
            accounts: whAccounts
          };
         })
          this.props.warehouseFilter.setWarehouseWithAccounts(updatedAccounts);
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.accounts, ['id', id]);
    const currentAccount = Object.assign({}, this.state.accounts[index]);
    currentAccount.status = currentAccount.status === 'active' ? 'inactive' : 'active';
    this.setState({ currentAccount, isNew: false }, () => {
      saveAccount(false, this.state.currentAccount)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'));
            this.getSurveys();
          } else {
            alertMessage(result.errors, 'error', 10);
          }
        });
    });
  }

  getSubAccounts = (expanded, record) => {
    if (expanded && record) {
      const accountId = record.id;
      let expandedAccount = this.state.accounts.find(account => account.id === accountId);
      const expandedAccountIndex = this.state.accounts.findIndex(account => account.id === accountId);
      if (checkServiceExistance('SA') && expandedAccount !== undefined && !expandedAccount.sub_accounts) {
        fetchSubAccounts(accountId)
          .then((result) => {
            if (result.success) {
              expandedAccount = Object.assign({}, expandedAccount, { sub_accounts: checkresult.accounts });
              const accounts = [...this.state.accounts];
              accounts[expandedAccountIndex] = expandedAccount;
              this.setState({
                accounts,
              });
            }
          });
      }
    }
  }


  renderFormModal = () => (
    <BaseModal
      title={this.state.isNew ? I18n.t('account.add') : I18n.t('account.edit')}
      onCancel={() => this.closeFormWindow()}
      width="70%"
      style={{ top: 50 }}
      maskClosable={false}
    >
      <Form
        account={this.state.currentAccount}
        accounts={this.state.accounts}
        primaryAccountCodes={this.state.primaryAccountCodes}
        isPrimaryCurrentAccount={this.state.isPrimaryCurrentAccount}
        isNew={this.state.isNew}
        org_id={this.state.orgId}
        onCancel={this.closeFormWindow}
        closeFormOnSubmit={this.closeFormWindow}
      />
    </BaseModal>
  )

  renderAccountCopyModal = () => (
    <BaseModal
      // title={I18n.t('general.copy_item' , { item: I18n.t('general.account') })}
      title={I18n.t('account.clone_settings')}
      onCancel={() => this.setState({ showAccountCopyModal: false })}
      width="40%"
      style={{ top: 50 }}
      maskClosable={false}
    >
      {/* <AccountCopy
        account={this.state.currentAccount}
        accounts={this.state.primaryAccountCodes}
        isPrimaryCurrentAccount={this.state.isPrimaryCurrentAccount}
        isNew={this.state.isNew}
        org_id={this.state.orgId}
        onCancel={() => this.setState({ showAccountCopyModal: false })}
        ></AccountCopy> */}
        <AccountClone 
        accounts={this.state.primaryAccountCodes} 
        onCancel={() => this.setState({ showAccountCopyModal: false })}
        />
    </BaseModal>
  )

  handleMadeChanges = () => {
    this.setState({ madeChangesinAccountUploadForm : true });
  }

  renderAccountUploadForm = () => (
    <BaseModal
      title={I18n.t('general.upload_items' , {field : "Accounts"})}
      onCancel={() => this.toggleAccountUploadForm(false)}
      width="70%"
      style={{ top: 50 }}
      maskClosable={false}
    >
      <AccountUploadForm 
        org_id={this.state.orgId}
        onCancel={() => this.toggleAccountUploadForm(false)}
        handleMadeChanges={this.handleMadeChanges}
      />
    </BaseModal>
  )

  toggleAccountCopyModal = (show) => {
    this.setState({
      showAccountCopyModal: show,
    });
  }
  toggleAccountUploadForm = (show) => {
    this.setState({
      showAccountUploadForm: show,
    });
  }


  render() {
    // const { pagination } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col span={10}>{I18n.t("menu.account")}</Col>
            <Col xs={14}>
              <Row
                justify="end"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Col xs={14}>
                  <Search
                    size="default"
                    value={this.state.token || ""}
                    placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                      "menu.account"
                    )}`}
                    onChange={(e) => {
                      const filteredString = removeSpecialCharacters(
                        e.target.value
                      );
                      this.handleOnChange("token", filteredString);
                    }}
                    onSearch={this.onSearch}
                    allowClear
                  />
                </Col>
                {checkServiceExistance("AC") && (
                  <Col className="page-actions">
                    <Button
                      type="primary"
                      className="marginLeft10"
                      onClick={() => this.showFormWindow()}
                      icon="plus"
                      disabled={this.state.inProgress}
                    >
                      {I18n.t("account.add")}
                    </Button>
                  </Col>
                )}
                <Col className="page-actions">
                  <Button
                    type="primary"
                    className="marginLeft10"
                    onClick={() => this.toggleAccountCopyModal(true)}
                    icon="copy"
                    disabled={this.state.inProgress}
                  >
                    {I18n.t("account.clone_settings")}
                  </Button>
                </Col>
                <Col className="page-actions">
                  <Button
                    type="primary"
                    className="marginLeft10"
                    onClick={() => this.toggleAccountUploadForm(true)}
                    icon="upload"
                    disabled={this.state.inProgress}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* <Col span={ 14 }>
              <Row type='flex' justify='end' gutter={4}>
                <Col span={14}>
                  <Search
                    size="default"
                    value={ this.state.token || '' }
                    placeholder={ `${I18n.t(`general.search`)} ${I18n.t('menu.account')}` }
                    onChange={ (e) => this.handleOnChange('token', e.target.value) }
                    onSearch={ this.onSearch }
                    allowClear
                  />
                </Col>
                { checkServiceExistance('AC') && <Col className="page-actions">
                  <Button type="primary" onClick={ () => this.showFormWindow() } icon='plus'>
                    { I18n.t('account.add') }
                  </Button>
                </Col>
                }
              </Row>
            </Col> */}
          </Row>
          <Row className="page-content">
              <Col xs={24}>
                <Row style={{ float: "right", marginBottom: "-18px" }}>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    onSearch={this.onSearch}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Row>
              </Col>
            </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col className="accounts">
                <List
                  size="small"
                  // scroll = {{y:680}}
                  scroll={{ x: "max-content", y: "calc(100vh - 240px)" }}
                  data={this.state.accounts}
                  gotoAccountSettings={(id) => this.gotoAccountSettings(id)}
                  editClick={(id) => this.handleEditClick(id)}
                  deleteClick={(id) => this.handleDelete(id)}
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter) =>
                    this.onTableChange(pagination, filter, sorter)
                  }
                  statusChange={(id) => this.handleAccountStatusChange(id)}
                  generateBol={this.bolGenerate}
                  accountCodesWithExceededLimit={ this.props.accountCodesWithExceededLimit }
                  // expandable={checkServiceExistance('SA')}
                />
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
        {this.state.showAccountCopyModal && this.renderAccountCopyModal()}
        {this.state.showAccountUploadForm && this.renderAccountUploadForm()}
      </div>
    );
  }
}
Accounts.propTypes = {
  orgId: PropTypes.string,
  showHeadings: PropTypes.bool,
};
Accounts.defaultProps = {
  orgId: '',
  showHeadings: true,
};



export const AccountsComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, accountsExceeded } = useContext(OrgContext);
  const accountCodesWithExceededLimit = accountsExceeded.map(rec => rec.account_code);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.ACCOUNTS_LISTING;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return (
    <Accounts
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      accountCodesWithExceededLimit={ accountCodesWithExceededLimit }
      userContext={ userContext }
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default AccountsComponent; 