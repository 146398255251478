/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, Row, Spin } from '../../common/UIComponents';
import InvoiceHeader from './Header';
import InvoiceOrders from './InvoicesList';

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      orders: this.props.orders || [],
      invoice: this.props.invoice || {},
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount() {
    // this.getInvoices();
  }

_renderInvoiceOrders = () => (
  <InvoiceOrders
    size='small'
    data={this.state.orders}
    editClick={this.handleEditClick}
    onPrintClick={this.handlePrint}
    pagination={{ position: 'none' }}
  />
)

handleEditClick = (id) => {
  this.props.editClick(id, false);
}

handlePrint = (id) => {
  this.props.onPrintClick(id);
}

_renderHeaderInfo = () => {
  const { invoice } = this.state;
  const isReadyOnly = invoice.status === 'PAYMENT_CONFIRMED';
  return (<InvoiceHeader invoice={invoice} isReadyOnly={isReadyOnly} />);
}

render() {
  return (
    <div className='content-outer'>
      <div className='content'>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row className='orderStats'>
            <Col md={24}>
              <this._renderHeaderInfo />
            </Col>
          </Row>
          <Row>
            <Col className="accessorials marginTop40">
              <this._renderInvoiceOrders />
            </Col>
          </Row>
        </Spin>

      </div>
    </div>
  );
}
}

InvoiceDetails.propTypes = {
  account: PropTypes.shape().isRequired,
  invoice: PropTypes.shape().isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  editClick: PropTypes.func.isRequired,
  onPrintClick: PropTypes.func.isRequired,
};

export default InvoiceDetails;
