import {
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Card,
  DatePicker,
  Button,
  Input,
  Radio,
  Icon,
  Upload,
  message,
  Tooltip
} from "antd";
import React, { Fragment, useEffect, useState , useContext} from "react";
import I18n from "../../common/I18n";
import userStore from "../../stores/UserStore";
import { withRouter } from "react-router-dom";
import { BatchManagementApi } from "../../api/BatchManagement";
import { alertMessage } from "../../common/Common";
import moment from "moment";
import { base64ToExcel, checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import { generateReqDecimal } from "../../helpers/array_functions";
import { Link } from "../../common/UIComponents";
import { OrgContext } from "../../context/OrgContext";
const { Dragger } = Upload;

const MbCreateForm = (props) => {
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const zeroValue = Number((0).toFixed(decimalPoints));
  const stepValue = generateReqDecimal(1, decimalPoints);
  const defaultFormData = {
    warehouse_name: currentWh ? currentWh.name : "",
    warehouse: currentWh ? currentWh.id : "",
    account_code: "",
    account_id: "",
    amount_received: "",
    check_ach_details: "",
    payment_method: "check",
    check_date: "",
  };
  const [formData, setFormData] = useState({
    ...defaultFormData,
    account_code: props?.selectedAccount?.code ?  props.selectedAccount.code : "",
    account_id: props?.selectedAccount?.id ?  props.selectedAccount.id : "",
  });
  const { getFieldDecorator, validateFields, setFieldsValue, resetFields } =
    props.form;
  const {
    accountsData,
    warehousesData,
    refreshAccounts,
    existingCheckId,
    batchInfo,
    batchNumberForMatch,
    updatePaidAmount,
    isTriggered
  } = props;
  const dateFormat = "MM/DD/YYYY";
  const batchId = props.match.params.batchId;
  const [buttonClicked, setButtonClicked] = useState(null);
  const [loadOnSubmit, setLoadOnSubmit] = useState(false);
  const [existingCheckIdState, setExistingCheckIdState] =
    useState(existingCheckId);

  const path = window.location.href;
  const allResultsPage = path.includes("manage_checks");
  const managePage = path.includes("manage_batch");
  const [loading, setLoading] = useState(false);
  const [currentFileInfo, setCurrentFileInfo] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [base64PdfData, setBase64PdfData] = useState(null);
  const [paidAmount, setPaidAmount] = useState(null);
  const orgContextData = useContext(OrgContext);
  const { currentOrg} = orgContextData;

  useEffect(() => {
    getArBatchCheckDetails();
  }, []);

  useEffect(() => {
    if(!_.isEmpty(props.selectedAccount)){
      setFormData(prevState => ({
        ...prevState,
        account_code: props.selectedAccount.code,
        account_id: props.selectedAccount.id,
      }));
      setFieldsValue({
        account_code: props.selectedAccount.code,
        account_id: props.selectedAccount.id,
      });
    }
  }, [props.selectedAccount]);

  useEffect(() => {
    setExistingCheckIdState(existingCheckId);
  }, [existingCheckId]);

  useEffect(() => {
    if (existingCheckIdState && existingCheckIdState?.id) {
      const amountToFloat = parseFloat(existingCheckIdState.paid_amount);
      const amountReceived = parseFloat(existingCheckIdState.received_amount).toFixed(decimalPoints);
      setPaidAmount(amountToFloat);
      setFieldsValue({
        check_ach_details: existingCheckIdState.check_ach_no,
        check_date: existingCheckIdState.check_date
          ? moment(existingCheckIdState.check_date)
          : "",
        payment_date: existingCheckIdState.payment_date
        ? moment(existingCheckIdState.payment_date)
        : moment(),
        payment_method: existingCheckIdState.pay_mode,
        amount_received: amountReceived,
        account_code: existingCheckIdState.account_code,
        warehouse: existingCheckIdState.wh_id,
      });
      setButtonClicked(existingCheckIdState.id);
    }
  }, [existingCheckIdState, setFieldsValue]);

  useEffect(() => {
    if (!_.isEmpty(formData.account_code)) {
      let account = {};
      const accountIndex = _.findIndex(accountsData, {
        code: formData.account_code,
      });
      if (accountIndex >= 0) {
        account = accountsData[accountIndex];
      }
      setFormData({
        ...formData,
        account_id: account?.id ? account.id : '',
        account_code: account?.code ? account.code : '',
      });
    } else {
      ("");
    }
  }, [formData.account_code]);

  const currentWh =
    typeof props.warehouseFilter.selectedWarehouses === "string"
      ? _.find(props.warehouseFilter.warehouses, {
          id: props.warehouseFilter.selectedWarehouses,
        })
      : _.find(props.warehouseFilter.warehouses, {
          id: props.warehouseFilter.selectedWarehouses[0],
        });

  const warehouseName = currentWh ? currentWh.name : "";

  // Call account API when warehouse code changes
  useEffect(() => {
    if (!_.isEmpty(formData.warehouse)) {
      // Find the ID of the selected warehouse
      const whRecord = warehousesData.find(
        (wh) => wh.id === formData.warehouse
      );
      const whId = whRecord?.id ? whRecord.id : "";

      const whNAme = whRecord?.name ? whRecord.name : "";

      // Call the API to refresh the accounts
      refreshAccounts(whId);

      // Clear account info from the quote form
      setFormData({
        ...formData,
        account_code: "",
        account_id: "",
        warehouse: whId,
        warehouse_name: whNAme,
      });
    }
  }, [formData.warehouse]);

  // Update account ID when account code changes
  useEffect(() => {
    const newFormData = { ...formData };
    if (!_.isEmpty(formData.account_code)) {
      const accountIndex = _.findIndex(accountsData, {
        code: formData.account_code,
      });
      if (accountIndex >= 0) {
        const account = accountsData[accountIndex];
        newFormData.account_id = account.id;
        newFormData.account_code = account.code;
      }
    } else {
      newFormData.account_id = "";
      newFormData.account_code = "";
    }

    setFormData(newFormData);
  }, [formData.account_code, accountsData]);

  useEffect(() => {
    if (formData.warehouse && warehousesData.length > 0) {
      const selectedWarehouse = warehousesData.find(
        (warehouse) => warehouse.id === formData.warehouse
      );

      if (selectedWarehouse) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          warehouse_name: selectedWarehouse.name,
        }));
      }
    }
  }, [formData.warehouse, warehousesData]);

  const handleOnChange = (element, value) => {
    const updatedFormData = { ...formData, [element]: value };

    // if (element === "warehouse") {
    //   // Find the selected warehouse
    //   const selectedWarehouse = warehousesData.find(
    //     (warehouse) => warehouse.id === value
    //   );

    //   if (selectedWarehouse) {
    //     // Set the warehouse name in the form data
    //     updatedFormData.warehouse_name = selectedWarehouse.name;
    //   } else {
    //     // If the selected warehouse is not found, clear the warehouse name
    //     updatedFormData.warehouse_name = "";
    //   }

    //   setFieldsValue({ account_code: "" });
    // }
    
    setFormData(updatedFormData);
  };


  useEffect (() => {
    if(isTriggered){
      onFinalPaymentEdit();
    }
  }, [isTriggered])

  const onFinalPaymentEdit = () => {
    resetFields();
    setButtonClicked(null);
    setExistingCheckIdState({});
    setPaidAmount(null);
  }

  const validateForm = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      const currentAcc = accountsData.find(
        (account) => account.code === values.account_code
      );
      if(currentOrg.code !== 'HILL' && paidAmount !== null && paidAmount !== 0) {
        updatePaidAmount(values.amount_received,existingCheckIdState?.id);
        return;
      }
      const payload = {
        organization_id: userStore.getStateValue("selectedOrg"),
        current_user_id: userStore.getStateValue("id"),
        wh_id: values.warehouse ? values.warehouse : formData.warehouse,
        wh_name: formData.warehouse_name
          ? formData.warehouse_name
          : warehouseName,
        account_code: values.account_code ? values.account_code : "",
        account_id: currentAcc
          ? currentAcc.id
          : existingCheckIdState.account_id,
        received_amount: values.amount_received ? values.amount_received : "",
        check_ach_no: values.check_ach_details
          ? values.check_ach_details.toUpperCase()
          : "",
        pay_mode: values.payment_method ? values.payment_method : "",
        check_date: values.check_date
          ? values.check_date.format("Do MMM YYYY")
          : "",
        payment_date: values.payment_date
          ? values.payment_date.format("Do MMM YYYY")
          : "",
        ar_batch_id: batchId ? batchId : "",
      };
      if (managePage) {
        payload.batch_number = batchNumberForMatch ? batchNumberForMatch : "";
      } else if (allResultsPage) {
        payload.batch_number = batchInfo ? batchInfo : "";
      } else {
        payload.batch_number = "";
      }
      if (existingCheckIdState?.id) {
        payload.ar_batch_check_id = existingCheckIdState.id;
        setLoadOnSubmit(true);
        BatchManagementApi.updateBatchCheckDetails(payload)
          .then((res) => {
            if (res.success) {
              alertMessage(res.data.message, "success", 10);
              getArBatchCheckDetails();
              setExistingCheckIdState({});
              setLoadOnSubmit(false);
              resetFields();
            } else {
              renderAlertMessage(res.errors)
              setLoadOnSubmit(false);
            }
          })
          .catch((err) => {
            renderAlertMessage(err)
            setLoadOnSubmit(false);
          })
          .finally(() => {
            setLoadOnSubmit(false);
          });
      } else {
        setLoadOnSubmit(true);
        BatchManagementApi.createBatchCheckDetails(payload)
          .then((res) => {
            if (res.success) {
              alertMessage(res.data.message, "success", 10);
              setLoadOnSubmit(false);
              getArBatchCheckDetails();
              resetFields();
            } else {
              renderAlertMessage(res.errors)
              setLoadOnSubmit(false);
            }
          })
          .finally(() => {
            setLoadOnSubmit(false);
          });
      }
    });
  };

  const downloadSampleFile = async () => {
    try {
      setLoading(true);
      const result = await BatchManagementApi.downloadSampleFile();
      if (result.success) {
        base64ToExcel(result.file, "SampleRemittenceSheet", "EXCEL");
      } else {
        renderAlertMessage(result?.errors);
      }
    } catch (error) {
      renderAlertMessage(result?.errors);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    setLoading(false);
    const { currentOrg = {} } = props.orgData;
    const { currentUser = {} } = props.userData;

    const payload = {
      organization_id: currentOrg.id,
      remittance_file: currentFileInfo,
      current_user_id: currentUser.id
    };
    message.loading("Importing EXCEL in progress..");
    BatchManagementApi.exportCheckRemittance(payload).then((result)=> {
      if (result.success) {
        message.destroy();
        alertMessage( "Excel Imported Successfully" , "success", 10);
        props.refreshList();
      } else{
        message.destroy();
        renderAlertMessage(result.errors);
      }
    }).finally(()=>{
      setLoading(false)
    })
  }

  const handleMoreFileChange = (info) => {
     const { status } = info.file;
     let uploadedFileList = [info.fileList[info.fileList.length - 1]];
     if (status === "removed") {
      setFileList([]);
      setBase64PdfData(null);
      setCurrentFileInfo(null);
     } else {
       setFileList(uploadedFileList);
       setCurrentFileInfo(info.file.originFileObj);
     }
   };

  const customRequest = (obj) => {
    const { file, onSuccess, onError, originFileObj } = obj;
    setTimeout(() => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          setBase64PdfData(e.target.result);
          onSuccess();
          message.success(`${file.name} Attachment added Sucessfully`);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        onError(error);
      }
    }, 1000);
  };

  const getArBatchCheckDetails = () => {
    props.refreshList();
    // props.handleLoading(true);
    // BatchManagementApi.fetchBatchCheckDetails(batchId).then((result) => {
    //   if (result.success) {
    //     props.onCheckDetailsUpdate(result.data.ar_batch_checks);
    //     props.handleLoading(false);
    //   } else {
    //     props.handleLoading(false);
    //     alertMessage(result.errors[0], "error", 10);
    //   }
    // });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 11 },
      xl: { span: 11 },
      xxl: { span: 11 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: 13 },
      xl: { span: 13 },
      xxl: { span: 13 },
    },
  };

  const gridSize = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 8,
  };

  const _renderUploadSection = () => {
    return (
      <Fragment>
        <Row type="flex" gutter={16}>
          <Col xs={21}>
            <div style={{ height: 28 }} className="uploadedFileName">
              <span
                style={{
                  stylefontSize: "0.85rem",
                  fontWeight: "bold",
                }}
              >
                Upload File :
              </span>
              <span>
                <Link onClick={downloadSampleFile}>
                  <span
                    className="anchor_cursor"
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      marginLeft: 80,
                    }}
                  >
                    {I18n.t("order.sample_download_link")}
                  </span>
                </Link>
              </span>
              <Dragger
                customRequest={customRequest}
                onChange={handleMoreFileChange}
                fileList={fileList}
                accept=".xlsx"
              >
                <p
                  className="ant-upload-text"
                  style={{ fontSize: 11, marginTop: -3 }}
                >
                  <Icon type="upload" />
                  &nbsp;&nbsp; Click or drag files to this area
                </p>
              </Dragger>
            </div>
          </Col>
          <Col xs={3}>
            <Tooltip
              title={I18n.t("general.export")}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Button
                type="primary"
                shape="circle"
                size="small"
                onClick={handleExport}
                icon={"export"}
                disabled={fileList.length === 0}
                style={{ marginTop: 23, marginLeft: -7 }}
                loading={loading}
              />
            </Tooltip>
          </Col>
        </Row>
      </Fragment>
    );
  }

  return (
    checkServiceExistance(["ARBCC", "ARBCU"], "ANY") && (
      <Fragment>
        <Row style={{ marginTop: 17 }}>
          <Col span={6}>{_renderUploadSection()}</Col>
          <Col span={18}>
            <Card
              className="customCard paymentDetailsCard"
              bordered={false}
              bodyStyle={{
                padding: "0px 10px",
                borderRadius: 10,
                fontSize: 12,
              }}
            >
              <Form {...formItemLayout}>
                <Row type="flex" gutter={8} align="middle">
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.mode_of_payment_label"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("payment_method", {
                        initialValue: "CHECK",
                        rules: [
                          {
                            required: true,
                            message: "Please select an option",
                          },
                        ],
                      })(
                        <Radio.Group
                          onChange={(value) =>
                            handleOnChange("payment_method", value)
                          }
                          disabled={paidAmount !== null && paidAmount !== 0}                        >
                          <Radio value={"CHECK"}>Check</Radio>
                          <Radio value={"ACH"}>ACH</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.account_details"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("account_code", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            handleOnChange("account_code", value)
                          }
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          size="small"
                          dropdownMatchSelectWidth={false}
                          disabled={currentOrg.code !== 'HILL' && paidAmount !== null && paidAmount !== 0}        
                        >
                          <Select.Option key="account_code" value="">
                            -- Select --
                          </Select.Option>
                          {accountsData.map((account) => (
                            <Select.Option
                              key={account.code}
                              value={account.code}
                            >
                              {`${account.name} (${account.code})`}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t("batch_management.amount_received")}`}
                    >
                      {getFieldDecorator("amount_received", {
                        initialValue: zeroValue,
                        rules: [
                          {
                            required: true,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          {
                            validator: (_, value, callback) => {
                              const regex = new RegExp(
                                `^\\d+(\\.\\d{1,${decimalPoints}})?$`
                              );
                              if (value && !regex.test(value)) {
                                callback("Please enter a valid amount");
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <Input
                          style={{ width: "100%" }}
                          step={stepValue}
                          // onClick={(e) => {
                          //   if (e.target.value === "0.00") {
                          //     setFieldsValue({
                          //       amount_received: "",
                          //     });
                          //   }
                          // }}
                          // onFocus={(e) => {
                          //   if (e.target.value === "0.00") {
                          //     setFieldsValue({
                          //       amount_received: "",
                          //     });
                          //   }
                          // }}
                          onChange={(e) =>
                            handleOnChange("amount_received", e.target.value)
                          }
                          size="small"
                          addonBefore={<Icon type="dollar" />}
                          onBlur={(e) => {
                            if (e.target.value) {
                              let invAmount = parseFloat(e.target.value);
                              setFieldsValue({
                                amount_received:
                                  invAmount.toFixed(decimalPoints),
                              });
                            } else {
                              setFieldsValue({
                                amount_received: zeroValue,
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t(
                        "batch_management.check_ach_details"
                      )} ${":"}`}
                    >
                      {getFieldDecorator("check_ach_details", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                          // {
                          //   validator: (_, value, callback) => {
                          //     if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
                          //       callback("Please enter a valid amount");
                          //     }
                          //     callback();
                          //   },
                          // },
                        ],
                      })(
                        <Input
                          style={{ width: "100%", textTransform: "uppercase" }}
                          onChange={(value) =>
                            handleOnChange("check_ach_details", value)
                          }
                          size="small"
                          disabled={paidAmount !== null && paidAmount !== 0}                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t("batch_management.check_date")} ${":"}`}
                    >
                      {getFieldDecorator("check_date", {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            handleOnChange("check_date", value);
                          }}
                          format={dateFormat}
                          size="small"
                          disabled={currentOrg.code !== 'HILL' && paidAmount !== null && paidAmount !== 0}                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize}>
                    <Form.Item
                      label={`${I18n.t("batch_management.payment_date")}${":"}`}
                    >
                      {getFieldDecorator("payment_date", {
                        initialValue: moment(),
                        rules: [
                          {
                            required: true,
                            message: I18n.t("errors.invalid_field", {
                              field: I18n.t("general.value"),
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            handleOnChange("payment_date", value);
                          }}
                          format={dateFormat}
                          size="small"
                          disabled={currentOrg.code !== 'HILL' && paidAmount !== null && paidAmount !== 0}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" gutter={16} justify="center">
                  <Col>
                    <Form.Item {...formItemLayout}>
                      <Button
                        type="primary"
                        onClick={validateForm}
                        icon="dollar"
                        size="small"
                        loading={loadOnSubmit}
                      >
                        {existingCheckIdState &&
                        existingCheckIdState.id &&
                        buttonClicked !== null
                          ? I18n.t("batch_management.update_check_details")
                          : I18n.t("batch_management.add_check_details")}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item {...formItemLayout}>
                      <Button
                        type="danger"
                        onClick={() => {
                          resetFields();
                          setButtonClicked(null);
                          setExistingCheckIdState({});
                          getArBatchCheckDetails();
                          setPaidAmount(null);
                          setFormData(defaultFormData)
                        }}
                        size="small"
                        icon="redo"
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Fragment>
    )
  );
};

const WrappedMbCreateForm = withRouter(Form.create()(MbCreateForm));
export default WrappedMbCreateForm;
