import React, { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";
import { formatByTimeConfig } from "../../../helpers/common";
import moment from "moment";
import I18n from "../../../common/I18n";

export const RecoveriesList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      mawb: 1234,
      hawb: 3456,
      warehouse_code: "ABC",
      pickup_location: "LAS VEGAS",
      warehouse_address: "New York -> Chicago",
      recovery_eta: "2023-04-01T12:00:00Z",
      //recovery_dead_line: "2023-04-25T12:00:00Z",
      status: "IN TRANSIT",
      weight: 500,
      items_count: 5,
      reference_1: "700214577",
      reference_2: "123456",
      account_id : "Test",
      account_name:"ABC123",
      account_code:"abc123",
      exception_message: "This is a test exception message",
      routing : "ABCD"

    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      mawb: 3456,
      hawb: 789,
      warehouse_code: "XYZ",
      pickup_location: "LOS ANGELES",
      warehouse_address: "New York -> Chicago",
      recovery_eta: "2023-04-01T12:00:00Z",
      //recovery_dead_line: "2023-04-25T12:00:00Z",
      status: "COMPLETED",
      weight: 700,
      items_count: 7,
      reference_1: "700214577",
      reference_2: "123456",
      account_id: "Xerox",
      account_name:"DEF456",
      account_code:"def123456",
      exception_message: "This is a test exception message",
      routing : "EFGH"
    },
  ]);

  const [columns, SetColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "warehouse",
      title: I18n.t("general.warehouse"),
      dataIndex: "warehouse_code",
    },
    {
      key: "freight_forwarder",
      title: I18n.t("location.freight_forwarder"),
      dataIndex: "pickup_location",
    },
    {
      key: "city",
      title: "Origin -> Destination",
      dataIndex: "warehouse_address",
    },
    {
      key: "recovery_eta",
      title: I18n.t("recoveries.eta"),
      dataIndex: "recovery_eta",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    // {
    //   key: "recovery_dead_line",
    //   title: I18n.t("recoveries.deadline"),
    //   dataIndex: "recovery_dead_line",
    //   render: (text, record) => {
    //     return (
    //       <div>
    //         {formatByTimeConfig(
    //           moment.utc(text),
    //           false,
    //           "DD MMM  HH:mm",
    //           "DD MMM  hh:mm A"
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "weight",
      title: I18n.t("order.weight"),
      dataIndex: "weight",
    },
    {
      key: "items_count",
      title: I18n.t("order.qty"),
      dataIndex: "pieces",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "EXCEPTION",
      title: I18n.t("general.exception_reason"),
      dataIndex: "exception_message",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
  ]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "recovery_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
};
