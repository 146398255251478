/* */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polygon, Marker, InfoWindow } from 'react-google-maps';
import MainMap from '../../common/Map';
import AppConfig from '../../../config/AppConfig';

import { isArrayEqual } from '../../../common/Common';

class Map extends React.Component {
  constructor(props) {
    super(props);
    const isOpen = [];
    if (props.zones) {
      props.zones.forEach((zone) => {
        isOpen[zone.id] = false;
      });
    }
    this.state = {
      map: '',
      centerLatLng: [],
      isOpen,
    };
    this.onMapMounted = this.onMapMounted.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.zones && nextProps.zones) {
      if (!isArrayEqual(this.props.zones, nextProps.zones)) {
        this.doSetBounds();
      }
    }
  }
  onToggleOpen = (e, id) => {
    const { isOpen } = this.state;
    isOpen[id] = !this.state.isOpen[id];
    this.setState({
      isOpen,
    });
  }

  onMapMounted(mapObj) {
    if (!this.state.map) {
      const map = this.state.map ? this.state.map : mapObj;
      this.setState({
        map,
      }, () => {
        this.doSetBounds();
      });
    }
  }

    doSetBounds = () => {
      if (window.google && this.state.map) {
        this.setState({ inProgress: true, isMapLoading: true }, () => {
          const bounds = new window.google.maps.LatLngBounds();
          if (this.props.zones.length > 0) {
            this.props.zones.filter(zone => zone.polygon_coords && zone.polygon_coords.length > 0).forEach((zone) => {
              zone.polygon_coords.forEach((point) => {
                bounds.extend(new window.google.maps.LatLng(point[0], point[1]));
              });
            });
          } else {
            bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat - 0.017, this.props.defaultCenter.lng - 0.005));
            bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat, this.props.defaultCenter.lng));
            bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat - 0.017, this.props.defaultCenter.lng + 0.005));
          }
          this.state.map.fitBounds(bounds);
          this.center = bounds.getCenter();
          this.setState({
            centerLatLng: { latitude: this.center.lat(), longitude: this.center.lng() },
          });
        });
      }
    }


    render() {
      return (
        <MainMap
          centerLatLng={this.state.centerLatLng}
          {...this.props}
          // eslint-disable-next-line max-len
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: '100%', width: '100%' }} />}
          containerElement={<div style={{ width: '100%', height: '100%' }} />}
          mapElement={<div style={{ width: '100%', height: '84vh' }} />}
          onMapMounted={this.onMapMounted}
          defaultZoom={8}
          defaultCenter={this.props.defaultCenter}
          zoomControl
        >
          {
            this.props.zones && this.props.zones.length > 0 ?
              <Fragment>
                {
                  this.props.zones.filter(zone => zone.polygon_coords && zone.polygon_coords.length > 0)
                    .map((zone, index) =>
                      (
                        <Fragment key={`frag${zone.id}`}>
                          <Polygon
                            path={zone.polygon_coords.map(point => ({ lat: point[0], lng: point[1] }))}
                            key={`polygon${zone.id}`}
                            options={{
                              fillColor: '#dc9f01', // dc9f01
                              fillOpacity: 0.2,
                              strokeColor: '#dc9f01', // dc9f01
                              strokeOpacity: 1,
                              strokeWeight: 1,
                            }}
                            onClick={e => this.onToggleOpen(e, zone.id)}
                          />
                          <Marker
                            key={`marker${zone.id}`}
                            position={{ lat: zone.polygon_coords[0][0], lng: zone.polygon_coords[0][1] }}
                            onClick={e => this.onToggleOpen(e, zone.id)}
                            visible={false}
                          >
                            {this.state.isOpen[zone.id] &&
                            <InfoWindow
                              onCloseClick={e => this.onToggleOpen(e, zone.id)}
                              options={{ alignTop: true, closeBoxURL: '', enableEventPropagation: true }}
                            >
                              <h3>{zone.name}</h3>
                            </InfoWindow>
                            }
                          </Marker>
                        </Fragment>
                      ))
                }
              </Fragment> : <Fragment />
          }

        </MainMap>
      );
    }
}

Map.propTypes = {
  defaultCenter: PropTypes.shape().isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default Map;
