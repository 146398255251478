import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";

export function fetchAdjustments(
  id,
  accountId,
  orgId,
  page = 1,
  perPage = AppConfig.perPage
) {
  const url = ApiUrl(
    `v2/special_day_adjustments?${PageParams(
      page,
      perPage
    )}&account_id=${accountId}&account_adjustment_id=${id}&organization_id=${orgId}&operation_code=SDA`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      adjustments: "",
      pagination: "meta.pagination",
    },
  });
}

export function saveAdjustment(isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew
    ? ApiUrl("v2/special_day_adjustments")
    : ApiUrl(`v2/special_day_adjustments/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, formData, ParseGeneralResponse, {
    responseDataKeys: { adjustment: "special_day_adjustment" },
  });
}

export function deleteAdjustment(id) {
  const url = ApiUrl(`v2/special_day_adjustments/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse);
}

export function saveBeyondAreaAdjustment(isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew
    ? ApiUrl("v2/beyond_area_adjustments")
    : ApiUrl(`v2/beyond_area_adjustments/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, formData, ParseGeneralResponse, {
    responseDataKeys: { adjustment: "beyond_area_adjustment" },
  });
}

export function fetchBeyondAreaAdjustment(accountId, currentAdjustmentId, orgId) {
  const url = ApiUrl(
    `v2/beyond_area_adjustments?account_id=${accountId}&account_adjustment_id=${currentAdjustmentId}&organization_id=${orgId}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      beyondAreaAdjustment: "",
    },
  });
}

export function deleteBeyondAreaAdjustment(id) {
  const url = ApiUrl(`v2/beyond_area_adjustments/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse);
}