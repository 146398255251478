import React from "react";
import BaseList from "../../BaseList";
import { Popconfirm, Icon, Tooltip } from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";

class List extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "event_name",
        title: I18n.t("template_events.name"),
        dataIndex: "event_name",
        width:250,
      },
      {
        key: "event_code",
        title: I18n.t("template_events.code"),
        dataIndex: "event_code",
        width:150,
      },
      {
        key: "template_params",
        title: I18n.t("template_events.params"),
        dataIndex: "template_params",
        render: (data) => (
          <div className="fontSize12">
            {data && data.length > 0 ? data.join(", ") : ""}
          </div>
        ),
      },
      {
        key: "actions",
        title: "",
        dataIndex: "id",
        fixed: "right",
        render: (data, record) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
            <EditIcon handleClick={() => this.props.editClick(data)}/>
            &nbsp;
            </Tooltip>
            <Popconfirm
              placement="left"
              title="Delete Confirm"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.props.deleteClick(data)}
            >
              <Tooltip title={I18n.t("general.delete")} placement="right">
              <DeleteIcon/>
              &nbsp;&nbsp;&nbsp;
              </Tooltip>
            </Popconfirm>
          </div>
        ),
        width: 80,
      },
    ];
  }
}

export default List;
