import { Checkbox } from "antd";
import React, { PureComponent } from "react";
class OrderCheckbox extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false,
		};
	}
	isSelectedOrder(orderId) {
		return this.props.selectedOrderKeys.indexOf(orderId) !== -1;
	}
	componentDidMount() {
		this.setState({ isChecked: this.isSelectedOrder(this.props.orderId) });
	}
	componentDidUpdate(prevProps) {
		const { marker, orderId, unselectMarkerFromCluster, checkForCluster } =
			this.props;

		// update checkbox state when checkboxes in order list are clicked
		const isChecked = this.isSelectedOrder(orderId);
		this.setState({ isChecked });

		if (prevProps.selectedOrderKeys !== this.props.selectedOrderKeys) {
			// check if previously selected order is not in current selected order and remove it from cluster
			if (
				prevProps.selectedOrderKeys.indexOf(orderId) !== -1 &&
				this.props.selectedOrderKeys.indexOf(orderId) === -1
			) {
				unselectMarkerFromCluster(marker);
			}
		}
		// after the order is selected, check if it is in cluster or not
		if (
			prevProps.selectedOrderKeys !== this.props.selectedOrderKeys &&
			this.props.selectedOrderKeys.indexOf(orderId) !== -1 &&
			prevProps.selectedOrderKeys.indexOf(orderId) === -1
		) {
			checkForCluster(marker);
		}
	}

	render() {
		const { orderId, orderNumber, selectMarker,isDisabled } = this.props;

		return (
			<>
				<Checkbox
					style={{
						marginTop: "10px",
					}}
					key={orderId}
					checked={this.state.isChecked}
					onChange={(e) => {
						this.setState({ isChecked: e.target.checked });
						selectMarker(orderId);
					}}
					disabled={isDisabled}
				>
					{orderNumber}
				</Checkbox>
				<br />
			</>
		);
	}
}

export default OrderCheckbox;
