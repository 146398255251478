import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Icon, Popconfirm, Tooltip } from "antd";
import I18n from "../../common/I18n";
import { stateCodeApi } from "../../api/StateCode";
import { alertMessage } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { fetchOrganizationConfigs } from "../../api/Organisations";

const StateCode = (props) => {
  const [states, setStates] = useState([]);
  const orgData = useContext(OrgContext);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    const stateCodeConfig = JSON.parse(
      props.organizationSettings.state_code_config || "[]"
    );
    const newStates = stateCodeConfig.map((stateCode) => ({
      stateName: stateCode.state_name,
      stateCode: stateCode.state_code,
    }));

    setStates((prevStates) => {
      const filteredPrevStates = prevStates.filter(
        (state) => state.stateName !== "" || state.stateCode !== ""
      );
      if (newStates.length === 0) {
        return [...filteredPrevStates, { stateName: "", stateCode: "" }];
      } else {
        return [...newStates, ...filteredPrevStates];
      }
    });
  }, [props.organizationSettings]);

  // const sampleData = Array.from({ length: 167 }, (_, index) => ({
  //   stateName: `State ${index + 1}`,
  //   stateCode: `SC${index + 1}`,
  // }));

  // useEffect(() => {
  //   setStates(sampleData);
  // }, []);

  const handleChange = (index, key, value) => {
    const newStates = [...states];
    newStates[index][key] = value;
    setStates(newStates);
  };

  const addState = () => {
    setStates([...states, { stateName: "", stateCode: "" }]);
  };

  const removeState = (index) => {
    const newStates = [...states];
    newStates.splice(index, 1);
    setStates(newStates);
  };

  const handleSave = () => {
    const invalidStates = states.some((state) => {
      return (
        !state.stateName !== !state.stateCode ||
        !state.stateName ||
        !state.stateCode
      );
    });
    if (invalidStates) {
      alertMessage("State Name and State Code are required", "error", 3);
      return;
    }
    setInProgress(true);
    const organizationId = _.get(orgData, "currentOrg.id", "");
    const statesForResponse = states.map((state) => {
      return {
        state_name: state.stateName,
        state_code: state.stateCode,
      };
    });

    stateCodeApi
      .createStateCodeConfig(statesForResponse, organizationId)
      .then((response) => {
        if (response.success) {
          alertMessage("Saved Successfully", "success", 2);
          fetchOrganizationConfigs(organizationId);
        } else {
          alertMessage("Something Went Wrong!", "error", 2);
          setInProgress(false);
        }
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  const renderStateFields = (state, index) => (
    <>
      <Row type="flex" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Input
            value={state.stateName}
            onChange={(e) => handleChange(index, "stateName", e.target.value)}
            style={{ width: "100%" }}
            autoFocus
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Input
            value={state.stateCode}
            onChange={(e) => handleChange(index, "stateCode", e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={4} style={{ display: "flex", alignItems: "center" }}>
          {states.length !== 1 && (
            <Tooltip title={I18n.t("general.delete")}>
              <Popconfirm
                placement="left"
                title={I18n.t("messages.confirm")}
                onConfirm={() => removeState(index)}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  type="delete"
                  style={{ color: "red", marginLeft: 10, fontSize: 18 }}
                />
              </Popconfirm>
            </Tooltip>
          )}

          {index === states.length - 1 && (
            <>
              <Tooltip title={I18n.t("general.add")}>
                <Icon
                  type="plus-circle"
                  style={{ color: "black", marginLeft: 10, fontSize: 18 }}
                  onClick={addState}
                />
              </Tooltip>
              &nbsp;&nbsp;
              <Button
                type="primary"
                onClick={() => handleSave()}
                loading={inProgress}
              >
                <Icon type="save" /> Save
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <Form>
      <Row
        style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row>
              <Col xs={24} sm={12} md={8} lg={6}>
                <strong>State Name</strong>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <strong>State Short Code</strong>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {states.map((state, index) => renderStateFields(state, index))}
          </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default StateCode;
