import { Call, ApiUrl, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';

export function fetchSurveys(status = '') {
  let url = ApiUrl('v2/survey?operation_code=SRVYI');
  if (status) {
    url = `${url}&status=${status}`;
  }
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { surveys: '' } });
}

export function fetchSubmittedSurveys(id) {
  const url = ApiUrl(`v2/survey/survey_orders?survey_id=${id}&operation_code=SRVYOR`);
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { surveys: 'surveyed_customers' } });
}

export function saveSurvey(isNew, data) {
  data.operation_code = isNew ?  'SRVYC' : 'SRVYU';
  const formData = setObjectToFormData(data);
  const method = isNew ? 'post' : 'put';
  const url = isNew ? ApiUrl('v2/survey') : ApiUrl(`v2/survey/${data.id}`);
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { survey: '' } }, false);
}

export function deleteSurvey(id) {
  const url = ApiUrl(`v2/survey/${id}`);
  return Call('delete', url, { operation_code: 'SRVYD' }, ParseGeneralResponse);
}
