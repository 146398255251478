/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _ from "lodash";
import { Tooltip } from "antd";
import { isEmpty } from '../common/Common';
import I18n from '../common/I18n';

export const checkForSMP = (record) => {
    if(record?.is_smp_applied) {
        return (<Tooltip title={I18n.t("invoices.second_machine_price_applied")}>
        <sup
        className='textRed textBold fontSize10'>&nbsp;&nbsp;(SMP)
        </sup></Tooltip>
        );
    }
    return <Fragment />
}
export const showFMP = () => <Tooltip title={I18n.t("invoices.second_machine_price_applied")}>FMP</Tooltip>

export const getFMPOrderFromInvoice = (invoice) => {
    let order_id = "";
    const orders = invoice?.transportation_charges && invoice.transportation_charges.length > 0
        ? invoice.transportation_charges.filter((order) => order.is_smp_applied === false)
        : [];
    if(orders.length > 0){
      return orders[0].order_id
    }
    return order_id;
}

export const expediteSharpData = [
      { name: 'From Time', code: "from_time", data_type: 'time', visible_to: ['BILLING', 'DISPATCHER'], is_required: true, default_value: '00:00' },
      { name: 'To Time', code: "to_time", data_type: 'time', visible_to: ['BILLING', 'DISPATCHER'], is_required: true, default_value: '23:59' },
      //{ name: 'Min Charge', code: "min_charge", data_type: 'price', visible_to: ['BILLING'], is_required: false, default_value: 0 },
      { name: 'Charge Type', code: "charge_type", data_type: 'string', visible_to: ['BILLING'], is_required: true, default_value: 'flat'},
      { name: 'Charge Value', code: "charge_value", data_type: 'string', visible_to: ['BILLING'], is_required: true, default_value: '0.00' } ,
];
export const findSpanValue = (dataSource, record, index) => {
    if(!isEmpty(record.order_item_group_id)){
        const valueIndex = _.findIndex(dataSource, {order_item_group_id: record.order_item_group_id});
        if(index === valueIndex){
            const rowSpan = dataSource.filter(rec => rec.order_item_group_id === record.order_item_group_id).length
            return rowSpan;
        }
        return 0;
    }
    return 1;
}

export const findIfInvoicePartiallyPaid = (record) => {
    const balance_invoice_amount = Number(record.balance_invoice_amount);
    const frequency_invoice_amount = Number(record.frequency_invoice_amount);
    return balance_invoice_amount < frequency_invoice_amount;
}

export const  handleInvoiceResponse = (result, id) => {
    const currentInvoice =
      !isEmpty(result.invoice) && _.isObject(result.invoice)
        ? result.invoice
        : {
            description: {},
            account_details: {},
            customer_details: {},
          };
    currentInvoice.customer_order_id = id;
    currentInvoice.remarks = result.remarks || [];
    currentInvoice.pickup_driver_pay_details = result.pickup_driver_pay_details
      ? result.pickup_driver_pay_details
      : {};
    if (isEmpty(currentInvoice.accessorial_charges)) {
      currentInvoice.accessorial_charges = [];
    }
    if (isEmpty(currentInvoice.adjustment_charges)) {
      currentInvoice.adjustment_charges = [];
    }
    if (isEmpty(currentInvoice.transportation_charges)) {
      currentInvoice.transportation_charges = [];
    }
    if (isEmpty(currentInvoice.order_info)) {
      currentInvoice.order_info = {};
    }
    const error_logs = result.error_logs || [];
    currentInvoice.status = result.billing_status;
    currentInvoice.error_log = error_logs.filter(
      (err_log) => !isEmpty(err_log.error_log)
    ).map(err_log => err_log.error_log);
    currentInvoice.error_messages = error_logs.filter(
      (err_log) => !isEmpty(err_log.error_message)
    ).map(err_log => err_log.error_message);
    return currentInvoice;
};