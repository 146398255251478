/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react'
import PropTypes, { element } from 'prop-types'
import equal from 'fast-deep-equal'
import moment from 'moment'
import _ from 'lodash'
import {
  Col,
  Row,
  Checkbox,
  MaterialFormItem,
  Popconfirm,
  Button,
  Card,
  Spin,
  Icon
} from '../../common/UIComponents'

import FormButtons from '../common/FormButtons'
import FormErrors from '../common/FormErrors'
import RenderField from '../../common/UIElemets'
import {
  alertMessage,
  isEmpty,
  checkWithinRange,
  randomNumber,
} from '../../common/Common'
import I18n from '../../common/I18n'
import WeightForm from '../configurations/accessorials/WeightForm'
import RateCard from './RateCard'
import AppConfig from '../../config/AppConfig'
import BaseModal from '../BaseModal'
import { expediteSharpData } from '../../helpers/billing'
import {
  fetchAccessorialDetails,
  saveAccessorialConfig,
} from '../../api/Accessorials'
import { renderAlertMessage } from '../../helpers/common'

class SlotComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentConfiguration: this.props.data ? this.props.data : {},
      currentAccessorial: this.props.currentAccessorial
        ? this.props.currentAccessorial
        : {},
      inProgress: false,
      isShowAddWeight: this.props.isShowAddWeight || true,
      errors: [],
      weights: this.props.weights || [],
      isNew: this.props.isNew === true,
      orderTypes: ['P', 'D'],
    }
  }

  clearForm = () => {
    this.setState({
      currentConfiguration: {},
      isNew: true,
    })
  }

  componentDidMount() {
    this.getAccessorialDetails(this.state.currentAccessorial.id)
  }

  getAccessorialDetails = (id) => {
    fetchAccessorialDetails(id).then((result) => {
      if (result.success) {
        const currentAccessorial = result.accessorial || []
        const total_component_params = !isEmpty(currentAccessorial.component_params) && _.isArray(currentAccessorial.component_params) ? currentAccessorial.component_params : []
        const component_params = total_component_params.filter(rec => rec.component_code === 'SLOTS');
        const currentComponent =
          component_params.length > 0
            ? component_params[0]
            : { component_code: 'SLOTS' }
        const currentConfiguration =
          currentComponent.param_values &&
          currentComponent.param_values.length > 0
            ? currentComponent.param_values[0]
            : {}
        this.setState(
          {
            currentAccessorial,
            currentComponent,
            currentConfiguration,
          },
          () => {
            this.setComponentData()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  //   componentDidUpdate(prevProps, prevState) {
  //     if (!equal(prevProps.data, this.props.data)) {
  //       this.setState({ currentConfiguration: this.props.data }, () => {
  //         this.setComponentData()
  //       })
  //     }
  //   }

  setComponentData = () => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    // if(isEmpty(currentConfiguration.component_code)){
    //     currentConfiguration.component_code = this.props.accComponent.code;
    // }
    const expedite = currentConfiguration.expedite || {}
    const isTypeBased = expedite.is_type_based === true
    const orderTypes = isTypeBased ? this.state.orderTypes : ['A']
    const expediteValues = expedite.values || []
    orderTypes.forEach((orderType) => {
      const weightsData = []
      const rangeValues = []
      let windowRecordIndex = _.findIndex(expediteValues, {
        order_type: orderType,
      })
      if (windowRecordIndex < 0) {
        expediteValues.push({ order_type: orderType, values: [] })
        windowRecordIndex = expediteValues.length
      }
      const windowRecords =
        expediteValues[windowRecordIndex] &&
        expediteValues[windowRecordIndex].values
          ? expediteValues[windowRecordIndex].values
          : []
      windowRecords.forEach((rec) => {
        let tempId = randomNumber()
        const existRecord = _.find(weightsData, {
          min_weight: rec.min_value,
          max_weight: rec.max_value,
        })
        if (
          isEmpty(existRecord) &&
          !isEmpty(rec.min_value) &&
          !isEmpty(rec.max_value)
        ) {
          weightsData.push({
            id: tempId,
            min_weight: rec.min_value,
            max_weight: rec.max_value,
            isNew: false,
          })
        } else if (!isEmpty(existRecord)) {
          tempId = existRecord.id
        }
        const rangeCostIndex = _.findIndex(rangeValues, {
          accessorial_key: 'cost',
        })
        if (rangeCostIndex < 0) {
          rangeValues.push({
            accessorial_key: 'cost',
            [tempId]: {
              accessorial_value: rec.cost,
            },
          })
        } else {
          rangeValues[rangeCostIndex][tempId] = {
            accessorial_value: parseFloat(rec.cost),
          }
        }
        expediteValues[windowRecordIndex]['weights'] = weightsData
        expediteValues[windowRecordIndex]['data'] = rangeValues
        //expedite.values = [...expediteValues]
      })
    })
    this.handleOnChange('', '', {
      ...currentConfiguration,
      expedite: {
        is_type_based: isTypeBased,
        values: expediteValues,
      },
    })
  }

  handleSave = () => {
    const currentComponent = Object.assign({}, this.state.currentComponent)
    const account = this.props.account || {}
    let currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    if(currentConfiguration.expedite && currentConfiguration.expedite.values){
      currentConfiguration.expedite.values.forEach((rec,index) => {
        delete rec.weights;
        delete rec.data;
        currentConfiguration.expedite.values[index] = rec;
      })
    }
    const data = { component_params: [] }
    const accessorial_params = {
      min_weight: 0,
      max_weight: 0,
      //component_code: currentComponent.component_code,
      order_type: 'A',
      zone_category: 'ALL_ZONES',
      param_values: [currentConfiguration],
    }
    data.code = !isEmpty(this.state.currentAccessorial.accessorial_code)
      ? this.state.currentAccessorial.accessorial_code
      : ''
    data.account_id = account.id
    data.organization_id = account.organization_id
    const currentParam = {
      component_code: currentComponent.component_code,
      accessorial_params: [accessorial_params],
    }
    data.component_params.push(currentParam)
    const errors = []
    if (errors.length === 0) {
      this.setState({ inProgress: true })
      saveAccessorialConfig(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'))
          this.setState(
            {
              errors: [],
              inProgress: false,
            },
            () => {
              this.getAccessorialDetails(this.state.currentAccessorial._id)
            },
          )
          // this.clearForm();
        } else {
          this.setState({ errors: result.errors, inProgress: false })
        }
      })
    } else {
      this.setState({ errors, inProgress: false })
    }
  }

  handleAddWeight = (section, orderType, weightsData) => {
    this.setState({
      currentRatecard: {
        code: section,
        paramCode: 'cost',
        min_label: 'Min Range',
        max_label: 'Max Range',
        param_values: [
          {
            accessorial_key: 'cost',
            accessorial_value: '',
            accessorial_label: 'Cost',
          },
        ],
        isNew: true,
        orderType: orderType,
      },
      isNew: true,
      showWeightForm: true,
      weights: [...weightsData],
    })
  }

  handleEditWeight = (
    section,
    orderType,
    record,
    weightRecord,
    weightsData,
  ) => {
    const { currentConfiguration } = this.state
    const paramLabel = 'Cost'
    this.setState({
      currentRatecard: {
        code: section,
        paramCode: 'cost',
        min_label: 'Min Range',
        max_label: 'Max Range',
        ...weightRecord,
        param_values: [
          {
            accessorial_key: 'cost',
            accessorial_value:
              record[weightRecord.id] &&
              record[weightRecord.id].accessorial_value
                ? record[weightRecord.id].accessorial_value
                : 0.0,
            accessorial_label: paramLabel,
          },
        ],
        isNew: false,
        orderType: orderType,
      },
      showWeightForm: true,
      isNew: false,
      weights: [...weightsData],
    })
  }

  handleDeleteWeight = (code, orderType, weightRecord, weightsData) => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    const expedite = currentConfiguration.expedite || {}
    const expediteValues = expedite.values || []
    const typeBasedValuesIndex = _.findIndex(expediteValues, {
      order_type: orderType,
    })
    if (typeBasedValuesIndex >= 0) {
      const rangeIndex = _.findIndex(
        expediteValues[typeBasedValuesIndex].values,
        {
          min_value: weightRecord.min_weight,
          max_value: weightRecord.max_weight,
        },
      )
      if (rangeIndex >= 0) {
        expediteValues[typeBasedValuesIndex].values.splice(rangeIndex, 1)
        if(expediteValues[typeBasedValuesIndex].values.length === 0){
          delete expediteValues[typeBasedValuesIndex].weights;
          delete expediteValues[typeBasedValuesIndex].data;
        }
        this.handleOnChange(
          '',
          '',
          {
            ...currentConfiguration,
            expedite: {
              is_type_based: expedite.is_type_based === true,
              values: expediteValues,
            },
          },
          true,
        )
      }
    }
  }

  handleOnChange = (section, element, value, setWeights = false) => {
    let currentConfiguration = Object.assign(
      {},
      this.state.currentConfiguration,
    )
    if (isEmpty(currentConfiguration[section])) {
      currentConfiguration[section] = {}
    }
    if (isEmpty(section)) {
      currentConfiguration = value
    } else if (isEmpty(element)) {
      currentConfiguration[section] = value
    } else {
      currentConfiguration[section][element] = value
    }
    this.setState(
      {
        currentConfiguration,
      },
      () => {
        if (setWeights) {
          this.setComponentData()
        }
      },
    )
    // this.props.onChange([currentConfiguration])
  }

  handleRangeOnChange = (section, orderType, element, value) => {
    const currentConfiguration = Object.assign(
      {},
      this.state.currentConfiguration,
    )
    if (isEmpty(currentConfiguration[section])) {
      currentConfiguration[section] = []
    }
    const currentValues = currentConfiguration[section]
    const currentRecord = _.find(currentValues, { order_type: orderType })
    currentConfiguration[section][element] = value
    this.setState({
      currentConfiguration,
    })
  }
  handleESOnChange = (section, index, element, value) => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    if (isEmpty(currentConfiguration[section])) {
      currentConfiguration[section] = []
    }
    if (currentConfiguration[section].length >= index) {
      if (isEmpty(currentConfiguration[section][index])) {
        currentConfiguration[section][index] = {}
      }
      currentConfiguration[section][index][element] = value
    }
    this.handleOnChange('', '', currentConfiguration)
  }

  handleWeightSave = (record, isNew) => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    const expedite = currentConfiguration.expedite || {}
    const expediteValues = expedite.values || []
    const typeBasedValuesIndex = _.findIndex(expediteValues, {
      order_type: record.orderType,
    })
    const costRecord = _.find(record.param_values, {
      accessorial_key: record.paramCode,
    })
    if (typeBasedValuesIndex >= 0) {
      const rangeIndex = _.findIndex(
        expediteValues[typeBasedValuesIndex].values,
        {
          min_value: record.min_weight,
          max_value: record.max_weight,
        },
      )
      if (rangeIndex >= 0) {
        expediteValues[typeBasedValuesIndex]['values'][rangeIndex] = {
          ...expediteValues[typeBasedValuesIndex]['values'][rangeIndex],
          min_value: record.min_weight,
          max_value: record.max_weight,
          cost: costRecord.accessorial_value,
        }
      } else {
        expediteValues[typeBasedValuesIndex]['values'].push({
          min_value: record.min_weight,
          max_value: record.max_weight,
          cost: costRecord.accessorial_value,
        })
      }
    } else {
      expediteValues.push({
        order_type: record.orderType,
        values: [
          {
            min_value: record.min_weight,
            max_value: record.max_weight,
            cost: costRecord.accessorial_value,
          },
        ],
      })
    }
    this.handleOnChange(
      '',
      '',
      {
        ...currentConfiguration,
        expedite: {
          is_type_based: expedite.is_type_based === true,
          values: expediteValues,
        },
      },
      true,
    )

    this.setState({
      isNew: false,
      showWeightForm: false,
      currentRatecard: {},
    })
  }

  closeWeightForm = () => {
    this.setState({
      currentRatecard: {},
      showWeightForm: false,
    })
  }

  renderWeightModal = () => {
    return (
      <BaseModal
        title={
          this.state.currentRatecard.isNew
            ? I18n.t('account.billing.weights.add')
            : 'Update Rate'
        }
        onCancel={() => this.closeWeightForm()}
        width="40%"
        style={{ top: 50 }}
      >
        <WeightForm
          currentRatecard={this.state.currentRatecard}
          handleSave={this.handleWeightSave}
          weights={this.state.weights || []}
          isNew={this.state.isNew}
        />
      </BaseModal>
    )
  }

  rendeRateCard = (orderType) => {
    const { expedite } = this.state.currentConfiguration
    const values = expedite?.values ? expedite.values : []
    const orderTypeRecord = _.find(values, { order_type: orderType })
    const weights = orderTypeRecord?.weights ? orderTypeRecord.weights : []
    const data = orderTypeRecord?.data ? orderTypeRecord.data : []
    return (
      <Fragment>
        {orderType !== 'A' && (
          <h3>
            {orderType !== 'A'
              ? AppConfig.orderTypeLabels[orderType]
              : I18n.t('general.all')}
          </h3>
        )}
        <RateCard
          key="rateCard"
          data={[...data]}
          weights={[...weights]}
          size="small"
          pagination={{ position: 'none' }}
          handleAddWeight={() =>
            this.handleAddWeight('expedite', orderType, weights)
          }
          showMincharge={false}
          handleEditWeight={(record, weightRecord) =>
            this.handleEditWeight(
              'expedite',
              orderType,
              record,
              weightRecord,
              weights,
            )
          }
          deleteClick={(weightRecord) =>
            this.handleDeleteWeight(
              'expedite',
              orderType,
              weightRecord,
              weights,
            )
          }
          // footer={ () => this.renderWeightRangeFooter(
          //   component.code,
          //   orderType,
          //   param.code,
          // )}
        />
      </Fragment>
    )
  }

  getRepeatedObject = (prevRec = null) => {
    const config = {}
    if (expediteSharpData) {
      expediteSharpData.forEach((rec) => {
        if (rec.code === 'from_time') {
          config[rec.code] = prevRec
            ? prevRec.to_time
              ? moment(prevRec.to_time, 'HH:mm')
                  .add(1, 'minute')
                  .format('HH:mm')
              : ''
            : rec.default_value || ''
        } else if (rec.code === 'to_time') {
          config[rec.code] = !prevRec ? rec.default_value || '' : ''
        } else {
          config[rec.code] = rec.default_value ? rec.default_value : ''
        }
      })
    }
    return config
  }

  addNewESRow = () => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    let currentRecord = currentConfiguration['expedite_sharp']
    if (isEmpty(currentRecord)) {
      currentRecord = []
    }
    const totlaObjects = [...currentRecord]
    const lastObject =
      totlaObjects.length > 0 ? totlaObjects[totlaObjects.length - 1] : null
    const repeatedConfig = this.getRepeatedObject(lastObject)
    currentRecord.push(repeatedConfig)
    this.handleOnChange('expedite_sharp', '', currentRecord)
  }

  renderRepeatedSet = () => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    const currentRecord = currentConfiguration.expedite_sharp
      ? currentConfiguration.expedite_sharp
      : []
    const headings = []
    const gridSize = expediteSharpData.length * 4
    return (
      <Fragment>
        {expediteSharpData.length > 0 && (
          <Row gutter={16} className="marginBottom15">
            <Col>
              <Row gutter={16}>
                {expediteSharpData.map((reqObject) => {
                  headings.push(reqObject.code)
                  return (
                    <Col xs={4}>
                      <span className="textBold">{reqObject.name}</span>
                      {reqObject.is_required && (
                        <sup className="textRed">*</sup>
                      )}
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        )}
        {currentRecord.map((rec, index) => {
          return (
            <Row gutter={16}>
              <Col>
                <Row gutter={16}>
                  {Object.keys(rec).map((recPram) => {
                    const reqObject = _.find(expediteSharpData, {
                      code: recPram,
                    })
                    let currentValue = currentRecord[index][reqObject.code]
                      ? currentRecord[index][reqObject.code]
                      : null
                    if (reqObject.data_type === 'time') {
                      currentValue = moment(currentValue, 'HH:mm')
                    }
                    return (
                      <Col span={4}>
                        {/* <FormItem label={reqObject.name} require={reqObject.is_required}> */}
                        {/*  this.renderField(
                            component,
                            orderType,
                            param,
                            currentConfiguration,
                            zone_id,
                            reqObject,
                            index
                          )
                          */}
                        {/* </FormItem> */}
                        {
                          <RenderField
                            data_type={reqObject.data_type}
                            element={reqObject.code}
                            currentValue={currentValue}
                            handleOnChange={(element, value) =>
                              this.handleESOnChange(
                                'expedite_sharp',
                                index,
                                reqObject.code,
                                value,
                              )
                            }
                            prefix={
                              reqObject.code === 'charge_value' && rec.charge_type === "flat" ? (
                                <Icon type="dollar" />
                              ) : null
                            }
                            suffix={
                              reqObject.code === 'charge_value' && rec.charge_type === "percentage" ? (
                                <Icon type="percentage" />
                              ) : null
                            }
                          />
                        }
                      </Col>
                    )
                  })}
                  <Col xs={2}>
                    <Popconfirm
                      placement="topRight"
                      title={I18n.t('messages.delete_confirm')}
                      onConfirm={() => this.handleDeleteItem(index)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        size="small"
                        type="danger"
                        icon="delete"
                        style={{ width: 20, height: 20, fontSize: 12 }}
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        })}
      </Fragment>
    )
  }
  handleDeleteItem = (index) => {
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    let currentRecord = currentConfiguration['expedite_sharp']
    if (!isEmpty(currentRecord) && currentRecord.length >= index) {
      currentRecord.splice(index, 1)
      this.handleOnChange('expedite_sharp', '', currentRecord)
    }
  }

  renderExpediteSharp = () => {
    return (
      <Fragment>
        <Card
          size="small"
          title={I18n.t('account.components.slots.expedite_sharp')}
          extra={
            <Button
              size="small"
              type="primary"
              onClick={() => this.addNewESRow()}
            >
              {I18n.t('general.add')}
            </Button>
          }
          bodyStyle={{ textAlign: 'left' }}
          className="marginTop10"
        >
          {this.renderRepeatedSet()}
        </Card>
      </Fragment>
    )
  }

  render() {
    const { currentConfiguration, isShowAddWeight } = this.state
    const {
      operational_hours = {},
      expedite = {},
      expedite_sharp = [],
    } = currentConfiguration
    const orderTypes =
      expedite.is_type_based === true ? this.state.orderTypes : ['A']
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
        <div>
          {/* <Row gutter={16}>
            <Col xs={24}>
              <Card
                title={I18n.t('account.components.slots.op_hours')}
                size="small"
                className="marginTop10"
                bodyStyle={{ textAlign: 'left' }}
              >
                <Row gutter={16}>
                  <Col sm={24} xs={24} md={4} lg={4} className="marginTop10">
                    <MaterialFormItem
                      label={I18n.t('account.components.slots.from_time')}
                      require
                    >
                      <RenderField
                        data_type="time"
                        element="from_time"
                        currentValue={
                          operational_hours?.from_time
                            ? moment(operational_hours.from_time, 'HH:mm')
                            : null
                        }
                        handleOnChange={(element, value) =>
                          this.handleOnChange(
                            'operational_hours',
                            element,
                            value,
                          )
                        }
                      />
                    </MaterialFormItem>
                  </Col>
                  <Col sm={24} xs={24} md={4} lg={4} className="marginTop10">
                    <MaterialFormItem
                      label={I18n.t('account.components.slots.to_time')}
                      require
                    >
                      <RenderField
                        data_type="time"
                        element="to_time"
                        currentValue={
                          operational_hours?.to_time
                            ? moment(operational_hours.to_time, 'HH:mm')
                            : null
                        }
                        handleOnChange={(element, value) =>
                          this.handleOnChange(
                            'operational_hours',
                            element,
                            value,
                          )
                        }
                      />
                    </MaterialFormItem>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
          {/* <Row gutter={16}>
            <Col xs={24}>
              <Card
                title={I18n.t('account.components.slots.expedite')}
                size="small"
                className="marginTop10"
                bodyStyle={{ textAlign: 'left' }}
              >
                <Checkbox
                  checked={expedite['is_type_based'] || false}
                  onChange={(e) =>
                    this.handleOnChange(
                      'expedite',
                      'is_type_based',
                      e.target.checked,
                    )
                  }
                >
                  {I18n.t('accessorials.separate_charges_by_order_type')}
                </Checkbox>
                <Row gutter={16}>
                  <Col sm={24} xs={24} md={24} lg={24} className="marginTop10">
                    {orderTypes.map((orderType) => {
                      return this.rendeRateCard(orderType)
                    })}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col xs={24}>{this.renderExpediteSharp()}</Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Row>
                <Col xs={24}>
                  {FormButtons(
                    this.state.inProgress,
                    this.handleSave,
                    this.props.onCancel,
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>{FormErrors(this.state.errors)}</Row>
          {this.state.showWeightForm && this.renderWeightModal()}
        </div>
      </Spin>
    )
  }
}

SlotComponent.propTypes = {
  currentConfiguration: PropTypes.shape().isRequired,
  handleSave: PropTypes.func.isRequired,
  isShowAddWeight: PropTypes.bool.isRequired,
}

export default SlotComponent
