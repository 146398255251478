import React, { Component, Fragment } from "react";
import _ from "lodash";
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Icon,
  Spin
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { fetchSecondItemPrice, saveSecondItemPrice } from "../../api/Discounts";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import SwitchComponent from "../../common/switchComponent";
import LabelInfo from "./LabelInfo";

class Discounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      second_item_discount_enabled: false,
      second_item_discount_price: "",
      second_item_discount_type: "",
      go_second_item_discount_enabled: false,
      go_second_item_discount_price: "",
      go_second_item_discount_type: "",
      account: this.props.account || {},
      isInitial: true,
      inProgress:false,
    };
  }

  componentDidMount() {
    this.getSecondItemPrice();
    this.amountVal(this.state.second_item_discount_type);
    this.goAmountVal(this.state.go_second_item_discount_type);
  }

  initialData = () => {
    let {
      second_item_discount_enabled,
      go_second_item_discount_enabled,
      second_item_discount_type,
      second_item_discount_price,
      go_second_item_discount_type,
      go_second_item_discount_price,
    } = this.state;
    if (!second_item_discount_enabled && isEmpty(second_item_discount_type)) {
      second_item_discount_type = AppConfig.amountTypes.Flat;
    }
    if (!second_item_discount_enabled && isEmpty(second_item_discount_price)) {
      second_item_discount_price = 0;
    }
    if (
      !go_second_item_discount_enabled &&
      isEmpty(go_second_item_discount_type)
    ) {
      go_second_item_discount_type = AppConfig.amountTypes.Flat;
    }
    if (
      !go_second_item_discount_enabled &&
      isEmpty(go_second_item_discount_price)
    ) {
      go_second_item_discount_price = 0;
    }

    this.setState(
      {
        second_item_discount_enabled,
        go_second_item_discount_enabled,
        second_item_discount_type,
        second_item_discount_price,
        go_second_item_discount_type,
        go_second_item_discount_price,
        isInitial: false,
      },
      () => {
        this.amountVal("FLAT"), this.goAmountVal("FLAT");
      }
    );
  };

  getSecondItemPrice = () => {
    this.setState({ inProgress: true });
    fetchSecondItemPrice(this.state.account.id).then((result) => {
      if (result.success) {
        this.setState(
          {
            second_item_discount_price:
              result.second_item_price.second_item_discount_price,
            second_item_discount_type:
              result.second_item_price.second_item_discount_type,
            second_item_discount_enabled:
              result.second_item_price.second_item_discount_enabled === true,
            go_second_item_discount_price:
              result.second_item_price.go_second_item_discount_price,
            go_second_item_discount_type:
              result.second_item_price.go_second_item_discount_type,
            go_second_item_discount_enabled:
              result.second_item_price.go_second_item_discount_enabled === true,
              inProgress: false,
          },
          () => {
            if (this.state.isInitial) {
              this.initialData();

            }
            this.amountVal(this.state.second_item_discount_type);
            this.goAmountVal(this.state.go_second_item_discount_type);
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleOnChange = (element, value) => {
    if (
      this.state.second_item_discount_enabled !== true ||
      this.state.go_second_item_discount_enabled !== true
    ) {
      this.initialData();
    }

    this.setState({
      [element]: value,
    });

    if (element.includes("go_")) {
      this.goAmountVal(value);
    } else {
      this.amountVal(value);
    }
  };

  amountVal = (value) => {
    if (value === "FLAT") {
      this.setState({
        isFlat: true,
        isPer: false,
      });
    } else if (value === "PERCENTAGE") {
      this.setState({
        isPer: true,
        isFlat: false,
      });
    }
  };

  goAmountVal = (value) => {
    if (value === "FLAT") {
      this.setState({
        isGoFlat: true,
        isGoPer: false,
      });
    } else if (value === "PERCENTAGE") {
      this.setState({
        isGoPer: true,
        isGoFlat: false,
      });
    }
  };

  saveItemPrice = () => {
    saveSecondItemPrice({
      organization_id: this.state.account.organization_id,
      account_id: this.state.account.id,
      second_item_discount_enabled: this.state.second_item_discount_enabled,
      second_item_discount_price: this.state.second_item_discount_price,
      second_item_discount_type: this.state.second_item_discount_type,
      go_second_item_discount_enabled:
        this.state.go_second_item_discount_enabled,
      go_second_item_discount_price: this.state.go_second_item_discount_price,
      go_second_item_discount_type: this.state.go_second_item_discount_type,
    }).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        this.getSecondItemPrice();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={this.saveItemPrice}
    >
      <Icon type="save" />
      {I18n.t("general.save")}
    </Button>
  );

  _renderDiscountTab = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Spin spinning={this.state.inProgress} delay={1000}>
      <Row>
        <div className="settingHeader">
          <span className="textBold">Discount Configuration</span>
        </div>
      </Row>
      <Row>
        <Col xs={11}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={11}>
              {I18n.t("discounts.second_item_price_name")}
              <sup className="textRed">*</sup>
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.second_item_price_name_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleOnChange("second_item_discount_enabled", value)
                }
                isChecked={this.state.second_item_discount_enabled === true}
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={true}
                unCheckedValue={false}
              />
            </Col>
          </Row>
          {this.state.second_item_discount_enabled === true && (
            <Fragment>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("discounts.second_item_price_type")}
                  <sup className="textRed">*</sup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Select
                    value={this.state.second_item_discount_type}
                    showSearch
                    className="width100Per"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      this.handleOnChange("second_item_discount_type", e)
                    }
                    style={{ width: "80%", marginRight: "10px" }}
                  >
                    {Object.keys(AppConfig.amountTypes).map((key) => (
                      <Select.Option
                        key={key}
                        value={AppConfig.amountTypes[key]}
                      >
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              {this.state.isFlat ? (
                <Row style={{ paddingTop: 5 }}>
                  <Col xs={23} sm={23} md={23} lg={11} />
                  <Col xs={1} sm={1} md={1} lg={1} />
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Input
                      type="number"
                      addonBefore={<Icon type="dollar" />}
                      value={this.state.second_item_discount_price}
                      onChange={(e) =>
                        this.handleOnChange(
                          "second_item_discount_price",
                          e.target.value
                        )
                      }
                      style={{ width: "80%" }}
                      className="marginRight10"
                    />
                    {/*
                          <InputNumber
                            precision={2}
                            value={this.state.second_item_discount_price}
                            onChange={e => this.handleOnChange('second_item_discount_price', e)}
                            className="width100Per"
                            min={0}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                         />
                        */}
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {this.state.isPer ? (
                <Row style={{ paddingTop: 5 }}>
                  <Col xs={23} sm={23} md={23} lg={11} />
                  <Col xs={1} sm={1} md={1} lg={1} />
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Input
                      type="number"
                      addonAfter={<Icon type="percentage" />}
                      min="0"
                      max="100"
                      precision="2"
                      value={this.state.second_item_discount_price}
                      onChange={(e) =>
                        this.handleOnChange(
                          "second_item_discount_price",
                          e.target.value
                        )
                      }
                      style={{ width: "80%" }}
                      className="marginRight10"
                    />
                    {/*
                         <InputNumber
                        precision={2}
                        value={this.state.second_item_discount_price}
                        addonAfter={<Icon type="percentage" />}
                        onChange={e => this.handleOnChange('second_item_discount_price', e)}
                        className="width100Per"
                        min={0}
                        formatter={value => `${value} %`}
                        parser={value => value.replace('%', '')}
                      />
                        */}
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={11}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={11}>
              {I18n.t("discounts.go_second_item_price_name")}
              <sup className="textRed">*</sup>
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.go_second_item_price_name_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleOnChange("go_second_item_discount_enabled", value)
                }
                isChecked={this.state.go_second_item_discount_enabled === true}
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={true}
                unCheckedValue={false}
              />
            </Col>
          </Row>
          {this.state.go_second_item_discount_enabled === true && (
            <Fragment>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("discounts.go_second_item_price_type")}
                  <sup className="textRed">*</sup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Select
                    value={this.state.go_second_item_discount_type}
                    showSearch
                    className="width100Per"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      this.handleOnChange("go_second_item_discount_type", e)
                    }
                    style={{ width: "80%", marginRight: "10px" }}
                  >
                    {Object.keys(AppConfig.amountTypes).map((key) => (
                      <Select.Option
                        key={key}
                        value={AppConfig.amountTypes[key]}
                      >
                        {key}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              {this.state.isGoFlat ? (
                <Row style={{ paddingTop: 5 }}>
                  <Col xs={23} sm={23} md={23} lg={11} />
                  <Col xs={1} sm={1} md={1} lg={1} />
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Input
                      type="number"
                      addonBefore={<Icon type="dollar" />}
                      value={this.state.go_second_item_discount_price}
                      onChange={(e) =>
                        this.handleOnChange(
                          "go_second_item_discount_price",
                          e.target.value
                        )
                      }
                      style={{ width: "80%" }}
                      className="marginRight10"
                    />
                    {/*
                          <InputNumber
                            precision={2}
                            value={this.state.second_item_discount_price}
                            onChange={e => this.handleOnChange('second_item_discount_price', e)}
                            className="width100Per"
                            min={0}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                         />
                        */}
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {this.state.isGoPer ? (
                <Row style={{ paddingTop: 5 }}>
                  <Col xs={23} sm={23} md={23} lg={11} />
                  <Col xs={1} sm={1} md={1} lg={1} />
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Input
                      type="number"
                      addonAfter={<Icon type="percentage" />}
                      min="0"
                      max="100"
                      precision="2"
                      value={this.state.go_second_item_discount_price}
                      onChange={(e) =>
                        this.handleOnChange(
                          "go_second_item_discount_price",
                          e.target.value
                        )
                      }
                      style={{ width: "80%" }}
                      className="marginRight10"
                    />
                    {/*
                         <InputNumber
                        precision={2}
                        value={this.state.second_item_discount_price}
                        addonAfter={<Icon type="percentage" />}
                        onChange={e => this.handleOnChange('second_item_discount_price', e)}
                        className="width100Per"
                        min={0}
                        formatter={value => `${value} %`}
                        parser={value => value.replace('%', '')}
                      />
                        */}
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Fragment>
          )}
        </Col>
      </Row>
      </Spin>
      {checkServiceExistance("SSIP") && this._renderFloatingBtn()}
    </Row>
  );

  render() {
    return (
      <div>
        <this._renderDiscountTab />
      </div>
    );
  }
}

export default Discounts;
