import React from 'react'
import _ from 'lodash'
import BaseList from '../BaseList'
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Row, Col } from '../../common/UIComponents'
import { formatByTimeConfig } from '../../helpers/common';
import moment from 'moment';
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import { isEmpty } from '../../common/Common';

class List extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  setColumns = (props) => {
    const {isMilitaryTime} = props;
    const currentConfiguration = props.currentConfiguration || {};
    this.columns = [
      {
        key: 'adjustment_type',
        title: I18n.t('adjustments.adjustment_type'),
        dataIndex: 'adjustment_type',
        render: (text, record, index) => {
          const obj = {
            children: text,
            props: {},
          }
         obj.props.rowSpan = record.spanValue || 0;
         return (record.isEditable ? this.props.formatElement(record.id,'adjustment_type') : obj);
        },
        className: 'textCaptilize'
      },
      {
        key: 'special_type',
        title: 'Date / Day',
        dataIndex: 'id',
        render: (text, record, index) => {
          let value = "";
          let currentRecord = {};
          
          if(record.isEditable){
            currentRecord = currentConfiguration[record.id] || {};
          } else{
            currentRecord = {...record};
          }
          if(currentRecord.adjustment_type === 'DATE') {
            value = currentRecord.spl_date ? moment(record.spl_date, 'YYYY-MM-DD').format('DD, MMM YYYY') : '';
          } else{
            value = currentRecord.spl_day;
          }
          const obj = {
            children: value,
            props: {},
          }
         obj.props.rowSpan = record.spanValue || 0;
         return (record.isEditable ? this.props.formatElement(record.id, currentRecord.adjustment_type === 'DATE' ? 'spl_date' : 'spl_day') : obj);
        },
        className: 'textCaptilize'
      },
      {
        key: 'time',
        title: I18n.t('general.time'),
        dataIndex: 'charge_type',
        render: (id, record) => {
          const fromTime = record.from_time ? formatByTimeConfig(moment(record.from_time , 'HH:mm'), isMilitaryTime, 'HH:mm', 'hh:mm A') : '';
          const toTime = record.to_time ? formatByTimeConfig(moment(record.to_time, 'HH:mm'), isMilitaryTime, 'HH:mm', 'hh:mm A') : '';
          return (record.isEditable ? 
          <Row gutter={16}>
              <Col xs={12}>
                  {this.props.formatElement(record.id,'from_time')}
              </Col>
              <Col xs={12}>
                  {this.props.formatElement(record.id,'to_time')}
              </Col>
          </Row>
          :
          <div>
              {fromTime} - {toTime}
          </div>);
        },
      },
      // {
      //   key: 'charge_type',
      //   title: I18n.t('adjustments.charge_type'),
      //   dataIndex: 'charge_type',
      //   className: 'textCaptilize',
      //   render: (text, record) => {
      //     return (record.isEditable ? 
      //     <div>
      //             {this.props.formatElement(record.id,'charge_type')}
      //     </div>
      //     :
      //     <div>
      //         {text}
      //     </div>);
      //   },

      // },
      {
        key: 'charge_value',
        title: props?.screenFrom !== "beyondAreaAdj" ? I18n.t('adjustments.charge_value') : "Charge Per Cost",
        dataIndex: 'charge_value',
        render: (text, record) => {
          return (record.isEditable ? 
          <div>
                  {this.props.formatElement(record.id,'charge_value')}
          </div>
          :
          <div>
              {!isEmpty(text) ? `${record.charge_type && record.charge_type.toLowerCase() === 'percentage' ? `${text} %` : `$ ${text}`}` : ''}
          </div>);
        },
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (id, record) => (
          record.isEditable === false ? <div className="line-actions cursorPointer">
            <EditIcon handleClick={() => this.props.editClick(record)}/>
            <Popconfirm
              placement="left"
              title={I18n.t('messages.delete_confirm')}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon/>
              &nbsp;&nbsp;&nbsp; 
            </Popconfirm>
          </div>
          :
           <div className="line-actions cursorPointer">
            <Icon type="check-circle" onClick={() => this.props.handleSave(record.id)} />
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.clear_confirm')}
              onConfirm={() => this.props.clearClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <Icon className="custom_delete_icon" type="close-circle" />
            </Popconfirm>
          </div>
        ),
        width: '95px',
      },
    ]
     if (props?.screenFrom !== "beyondAreaAdj") {
       this.columns.splice(3, 0,  {
        key: 'charge_type',
        title: I18n.t('adjustments.charge_type'),
        dataIndex: 'charge_type',
        className: 'textCaptilize',
        render: (text, record) => {
          return (record.isEditable ? 
          <div>
                  {this.props.formatElement(record.id,'charge_type')}
          </div>
          :
          <div>
              {text}
          </div>);
        },

      },);
     }
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default List
