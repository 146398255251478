import {
  ApiUrl,
  Call,
  PageParams,
  ParseGeneralResponse,
} from "./ApiUtils";

export const fetchMonthlyCutOff = (data) => {
  const {
    page,
    perPage,
    whId,
    orgId,
    accId = null,
    fromDateFormatted,
    toDateFormatted,
    orderFilter,
    sort_by,
    sort_order
  } = data;
  const url = ApiUrl(
    `v2/account_invoices/monthly_cut_off_report1?${PageParams(
      page,
      perPage
    )}&warehouse_ids=${whId}&organization_id=${orgId}&account_ids=${accId}&from_date=${fromDateFormatted}&to_date=${toDateFormatted}&invoice_frequency=${orderFilter}&sort_by=${sort_by}&sort_order=${sort_order}`
  );
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
};

export const approveMonthlyCutOff = (data) => {
  const url = ApiUrl("v2/account_invoices/monthly_cut_off_approval");
  return Call("PUT", url, data, ParseGeneralResponse);
}
