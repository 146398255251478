import {
  Col,
  Dropdown,
  Icon,
  Row,
  Spin,
  Form,
  Select,
  Button,
  Alert,
} from "antd";
import queryString from "query-string";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { WarehouseContext } from "../../context/WarehouseContext";
import { withRouter } from "react-router-dom";
import { fetchShortFormAccounts } from "../../api/Account";
import WrappedMbCreateForm from "./MbCreateForm";
import TransactionIndexList from "./TransactionIndexList";
import { BatchManagementApi } from "../../api/BatchManagement";
import { Search } from "../../common/UIComponents";
import BatchFilter from "./BatchFilter";
import { alertMessage, isEmpty } from "../../common/Common";
import AppliedFiltersTags from "../../containers/AppliedFiltersTags";
import { defaultManageBatchFilter } from "../dispatches/helpers";
import { convertToTags, renderAlertMessage } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import I18n from "../../common/I18n";
import { OrgContext } from "../../context/OrgContext";
import { UserContext } from "../../context/UserContext";
import BaseModal from "../BaseModal";
import { render } from "react-dom";
import { AccountSharingForm } from "./AccountSharingForm";
import { max } from "lodash";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
const ManageBatch = (props) => {
  const urlParams =   props?.location?.search ? props.location.search : null;
  const urlAccParamValue = urlParams ? queryString.parse(urlParams).account_id : ''
  const [warehouses, setWarehouses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkDetails, setCheckDetails] = useState([]);
  const [batchDetailHeader, setBatchDetailHeader] = useState([]);
  const [existingCheckId, setExistingCheckId] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false)
  const [showFilterWindow, setShowFilterWindow] = useState(false);
  const batchId = props.match.params.batchId;
  const { resetFields } = props.form;
  const [accountsForFilter, setAccountsForFilter] = useState([]);
  const whForFilter = [props.warehouseFilter.warehouses.map((whid) => whid.id)];
  const [loadScreen, setLoadScreen] = useState(false);
  const defaultFilter = defaultManageBatchFilter(props);
  const [updateSearchTags, setUpdateSearchTags] = useState(false);
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    ...defaultFilter,
    account_code : urlAccParamValue ? urlAccParamValue : defaultFilter.account_code
  });
  const [appliedFiltersTags, setAppliedFiltersTags] = useState([]);
  const [batchInfo, setBatchInfo] = useState([]);
  const [batchNumberForMatch, setBatchNumberForMatch] = useState([]);
  const pageUrlLocation = window.location.href;
  const isManageChecksScreen = pageUrlLocation.includes("manage_checks");
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [dialogVisible, setDialogVisible] = useState("");
  const [checkId, setCheckId] = useState("");
  const [newAmount, setNewAmount] = useState(0);
  const  [isFailed, setIsFailed] = useState(false)
  const [isTriggered, setIsTriggered] = useState(false)
  const [showAccountSharingModal, setShowAccountSharingModal] = useState(false)
  const [accountSharingData, setAccountSharingData] = useState({})
  
  
  const computeTags = (filterVariable) => {
    const keys = ["account_code", "status"];
    const filteredData = {
      account_code: {
        label: I18n.t("general.account"),
        value: accountsForFilter.find(
          (acc) => acc.id === filterVariable.account_code
        )?.name
          ? accountsForFilter.find(
              (acc) => acc.id === filterVariable.account_code
            )?.name
          : "",
        closeable: true ,
        onClose: () => {
          handleFilterOnRemoval("account_code");
        },
      },
      status: {
        label: "Status",
        value: filterVariable?.status,
        closeable: filterVariable.status === "BOTH" ? false : true,
        onClose: () => {
          handleFilterOnRemoval("status");
        },
      },
    };
    const tagsInfo = convertToTags({ ...filterVariable }, keys, filteredData);
    setAppliedFiltersTags(tagsInfo);
  };

  const handleDialogVisible = (data) => {
    setDialogVisible(data);
  };

  const handleFilterOnRemoval = (key) => {
    const filterVariable = { ...filterPlaceHolder };
    switch (key) {
      case "account_code":
        filterVariable.account_code = defaultFilter.account_code;
        break;
      case "status":
        filterVariable.status = defaultFilter.status;
        break;
      default:
        break;
    }
    setFilterPlaceHolder(filterVariable);
    updateFilterSearchValues(filterVariable);
  };

  const updateFilterSearchValues = (filteredValue) => {
    const account_code = filteredValue.account_code;
    const status = filteredValue.status;
    setFilterPlaceHolder(Object.assign({}, filteredValue));
    setSearchText("");
    handleSearch("", account_code, status);
    const changeUrl = location?.search?.replace(
      `?account_id=${filterPlaceHolder.account_code}`,
      `?account_id=${account_code}`
    );
    props.history.push({
      pathname: location?.pathname,
      search: account_code ?  changeUrl : "",
    });
  };

  const handleCheckDetailsUpdate = (data) => {
    if (data.length > 0) {
      setCheckDetails(data);
    } else {
      setCheckDetails([]);
      renderAlertMessage("No checks found");
    }
  };

  const handleLoading = (value) => {
    setLoadScreen(value);
  };

  const clearFilter = () => {
    resetFields();
    setSearchText("");
    setIsSearching(false)
    handleSearch("", "", "");
    computeTags(defaultFilter);
    setFilterPlaceHolder({ ...defaultFilter });
  };

  const getAccountsForFilter = (
    orgId = "",
    whId = whForFilter,
    skip_wh = false
  ) => {
    setLoading(true);
    fetchShortFormAccounts(orgId, whId, skip_wh).then((result) => {
      if (result.success) {
        if (result.accounts.length > 0) {
          setAccountsForFilter(result.accounts);
        } else {
          setAccountsForFilter([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const handleSearch = (check_ach_no, account_id, status) => {
    tableOptions.pagination.current = 1;
    setIsSearching(false)
    setFilterPlaceHolder({
      account_code: account_id,
      status: status,
    });
    const filterVariable = {
      account_code: account_id,
      status: status,
    };
    onSearch(check_ach_no, account_id, status);
    computeTags(filterVariable);
    setUpdateSearchTags(true);
    setShowFilterWindow(false);
  };

  const onSearch = async (check_ach_no = "", account_id = "", status = "") => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = AppConfig.perPage;
    const sortBy = filterPlaceHolder.sortBy;
    const sortOrder = I18n.t(`general.sort_${filterPlaceHolder.sortByType}`);
    try {
      setLoading(true);
      const result = await BatchManagementApi.fetchBatchCheckDetails(
        batchId,
        check_ach_no,
        account_id,
        status,
        page,
        perPage,
        sortBy,
        sortOrder
      );
      if (result.success) {
        handleCheckDetailsUpdate(result.data.ar_batch_checks);
        setPagination(result.pagination);
        handleDialogVisible(result.data.message);
      } else {
        renderAlertMessage(result.errors)
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag);
  };

  const handleTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const getAccounts = (whId) => {
    setLoading(true);
    fetchShortFormAccounts("", whId).then((result) => {
      if (result.success) {
        setAccounts(result.accounts);
        setLoading(false);
      } else {
        setLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  useEffect(() => {
    getAccounts();
    setWarehouses(props.warehouseFilter.warehouses);
    getCreatedBatches();
    getAccountsForFilter();
  }, []);

  useEffect(() => {
    computeTags(filterPlaceHolder);
  }, [filterPlaceHolder, accountsForFilter]);

  useEffect(() => {
    onSearch("", filterPlaceHolder.account_code, filterPlaceHolder.status);
  }, [filterPlaceHolder.sortBy, filterPlaceHolder.sortByType]);

  useEffect(() => {
    setWarehouses(props.warehouseFilter.warehouses);
    getAccounts();
  }, [
    props.warehouseFilter.selectedWarehouses,
    props.warehouseFilter.warehouses,
  ]);

  const refreshAccounts = (whId) => {
    getAccounts(whId);
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page,
  }

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }

    if(tableOptions.pagination.current !== currentPage){
      onSearch("", filterPlaceHolder.account_code, filterPlaceHolder.status);
    }
    else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
    }
  }

  const redirectToRemittance = (id) => {
    const batchId = props.match.params.batchId
      ? props.match.params.batchId
      : checkDetails.find((check) => check.id === id).ar_batch_id;
    props.history.push({
      pathname: `/remittances/${batchId}/${id}`,
    });
    localStorage.setItem("lastPath", props.location.pathname);
  };

  const redirectToTransaction = (data) => {
    props.history.push({
      pathname: `/check_transaction_info/${data.account_id}/${data.id}`,
    });
    localStorage.setItem("lastPath", props.location.pathname);
  };

  const handleEdit = (id) => {
    setExistingCheckId(id);
  };
  
  const handleDeleteBatch = (data) => {
    const { id } = data;
    setLoading(true);
    BatchManagementApi.deleteBatch(id)
      .then((result) => {
        if (result.success) {
          alertMessage(result.message ? result.message : I18n.t('messages.deleted'), "success", 10);
          onSearch();
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        setLoading(true);
      });
  }

  const renderSearchBar = (showTitle = false) => {
    return (
      <div className="headerOptionDiv headerFilter">
        {showTitle && (
          <span className="textBold">
            {I18n.t("general.search")}:&nbsp;&nbsp;
          </span>
        )}
        <Search
          placeholder={I18n.t("batch_management.check_number")}
          className="seachWithDropDown recoverySearch search_with_prefix"
          size="default"
          value={searchText}
          onChange={handleTextChange}
          // onSearch={(value) => handleSearch(value, "", "BOTH")}
          onSearch={(value) => {
            handleSearch(value, "", "BOTH");
            setIsSearching(value.length > 0);
          }}
          onClear={() => {
            setSearchText("");
            clearFilter();
          }}
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
          suffix={
            <>
              {searchText !== "" && (
                <Icon
                  type="close-circle"
                  theme="filled"
                  size="small"
                  onClick={() => {
                    setSearchText("");
                    clearFilter();
                  }}
                />
              )}
              &nbsp;
              <Dropdown
                onVisibleChange={handleVisibleChange}
                visible={showFilterWindow}
                trigger={["click"]}
                overlay={
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 350,
                      padding: 15,
                      marginTop: 10,
                      marginRight: -12,
                    }}
                    className="boxShadow"
                  >
                    <BatchFilter
                     // accounts={accountsForFilter}
                      accounts={accounts}
                      warehouseFilter={props.warehouseFilter}
                      // onSearch={handleSearch}
                      onSearch={() => {
                      handleSearch();
                      setSearchText("");
                    }}
                      check_ach_no={searchText}
                      clearFilter={clearFilter}
                      filterPlaceHolder={filterPlaceHolder}
                      defaultFilter={{ ...defaultFilter }}
                      setFilterPlaceHolder={updateFilterSearchValues}
                    />
                  </div>
                }
              >
                <Icon
                  type="control"
                  onClick={() => setShowFilterWindow(true)}
                />
              </Dropdown>
            </>
          }
          prefix={
            <Row type="flex" align="middle" gutter={4}>
              <Col>
                <Icon type="search" />
              </Col>
            </Row>
          }
        />
      </div>
    );
  };

  const getCreatedBatches = () => {
    const batchId = props.match.params.batchId
      ? props.match.params.batchId
      : "";
    setLoading(true);
    BatchManagementApi.fetch().then((result) => {
      if (result.success) {
        setBatchInfo(result.data.batch_number);
        const matchingBatch = result.data.ar_batches.find(
          (batch) => batch.id === batchId
        );
        if (matchingBatch) {
          setBatchDetailHeader(matchingBatch);
          setBatchNumberForMatch(matchingBatch.batch_number);
        } else {
          setBatchDetailHeader(null);
        }
        setLoading(false);
      } else {
        renderAlertMessage(result.errors)
        setLoading(false);
      }
    });
  };

  const url = props.location.pathname;
  const rootPath = localStorage.getItem("rootPath");
  const handleGoBack = () => {
    if (
      url.includes("manage_checks") &&
      rootPath &&
      rootPath.includes("batches")
    ) {
      props.history.push({ pathname: "/batches" });
    } else if (url.includes("manage_checks")) {
      props.history.push({ pathname: "/account_receivables" });
    } else {
      props.history.push({ pathname: "/batches" });
    }
  };

  const openBatchDetails = (id) => {
    props.history.push({ pathname: "manage_batch/" + id });
  };

  const refreshList = () => {
    handleSearch(
      searchText,
      filterPlaceHolder.account_code,
      filterPlaceHolder.status
    );
  };


  const updatePaidAmount = (amount,id) => {
    const basicInfo  = props.userContext;
    const data = {
      check_id : !_.isEmpty(id) ? id :  checkId,
      organization_id:basicInfo.currentOrg.id,
      user_id:basicInfo.currentUser.id,
      new_amount: !_.isEmpty(amount) ? amount :  newAmount
    } 
    BatchManagementApi.editPayAmount(data).then((result) => {
      if(result.success){
        alertMessage(result.data.message, "success", 10);
        setIsTriggered(true)
        refreshList();
      }else{
        renderAlertMessage(result.errors)
        setIsFailed(true)
      }
    }).finally(() => {
        setNewAmount(0)
        setIsFailed(false)
        setIsTriggered(false)
    });
}


const openAccountSharingModal = (data) => {
  setAccountSharingData(data)
  setShowAccountSharingModal(true)

}
  

const renderAccountSharingModal = () => {
  return (
    <>
      <BaseModal
        title={I18n.t("batch_management.account_sharing")}
        visible={showAccountSharingModal}
        onCancel={() => {
          setShowAccountSharingModal(false), setAccountSharingData({});
        }}
        footer={null}
        width={"900px"}
        maskClosable={false}
        bodyStyle={{maxHeight: "calc(100vh - 200px)", overflowY: "auto"}}
      >
        <AccountSharingForm
        accountSharingData={accountSharingData}
        accountsForFilter={accountsForFilter}
        context = {props.userContext}
        refreshList={refreshList}
        setShowAccountSharingModal = {setShowAccountSharingModal}
        />

      </BaseModal>
    </>
  );
};


  const previousLocation = localStorage.getItem("lastPath");
  let showGoback =
    (previousLocation && previousLocation.includes("batches")) ||
    (previousLocation && previousLocation.includes("account_receivables")) ||
    (previousLocation && previousLocation.includes("remittances"))
      ? true
      : false;
  const currentUrl = window.location.href.split("/").pop();
  showGoback =
    showGoback === false && currentUrl === "manage_checks" ? false : true;
  const accParamOfUrl = urlAccParamValue ?  _.find(accounts, {'id': urlAccParamValue})  : {}
  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Fragment>
            {showGoback && (
              <span onClick={handleGoBack}>
                <Icon type="arrow-left" style={{ cursor: "pointer" }} />
              </span>
            )}
            &nbsp;&nbsp;
          </Fragment>
          <Col xs={12}>
            {" "}
            {batchDetailHeader?.batch_number ? (
              <>
                {I18n.t("batch_management.manage_batch")} (
                {batchDetailHeader.batch_number})
              </>
            ) : (
              I18n.t("batch_management.manage_checks") 
            )}
            {showGoback && accParamOfUrl?.name &&
              <span>
                ( {accParamOfUrl.name} )
              </span>
            }

          </Col>
          <Col xs={12}>
            <Row type="flex" justify="end" style={{ marginRight: "22px" }}>
              <Col xs={10} offset={14}>
                {renderSearchBar()}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <WrappedMbCreateForm
              refreshList={refreshList}
              refreshAccounts={refreshAccounts}
              warehousesData={warehouses}
              accountsData={accountsForFilter}
              warehouseFilter={props.warehouseFilter}
              onCheckDetailsUpdate={handleCheckDetailsUpdate}
              existingCheckId={existingCheckId}
              handleLoading={handleLoading}
              batchInfo={batchInfo}
              batchNumberForMatch={batchNumberForMatch}
              selectedAccount={accParamOfUrl}
              orgData={props.orgData}
              userData={props.userContext}
              updatePaidAmount={updatePaidAmount}
              isTriggered={isTriggered}
            />
          </Col>
        </Row>
        {/* {isManageChecksScreen && (
          <div style={{ marginTop: "10px" }}>
            <Alert
              message={"All Batch Checks Are Being Displayed Here.!!"}
              type="info"
              showIcon
              icon={<Icon type="info-circle" theme="filled" />}
            />
          </div>
        )} */}

         {dialogVisible && (
            <div style={{ marginTop: "10px" }}>
              <Alert
                message={dialogVisible}
                type="error"
                showIcon
                icon={<Icon type="info-circle" theme="filled" />}
              />
            </div>
          )}
        <Row type="flex" justify="space-between" style={{ marginTop: "10px" }}>
          <Col span={24}>
            <AppliedFiltersTags
              tags={appliedFiltersTags}
              clearFilter={() => clearFilter()}
              // isSearching={!isEmpty(searchText) ? true : false}
              isSearching={isSearching}
              searchText={searchText}
              updateSearchTags={updateSearchTags}
              resetSearchTags={() => setUpdateSearchTags(false)}
              searchBy={"Check"}
              hideClearButton={false}
            />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <div style={{ marginTop: "10px" }}>
            <TransactionIndexList
              data={checkDetails ? checkDetails : []}
              handleRedirect={(id) => redirectToRemittance(id)}
              handleEdit={(id) => handleEdit(id)}
              handleDelete={handleDeleteBatch}
              scroll={{ x:"max-content", y:"calc(100vh - 360px)" }}
              pagination={tablePagination}
              tableChange={(pagination, filter, sorter, currentTable) =>
                onTableChange(pagination, filter, sorter, currentTable)
              }
              showEdit={true}
              showActions={true}
              showTransaction={true}
              isLoading={loadScreen}
              isManageChecksScreen={isManageChecksScreen}
              openBatchDetails={openBatchDetails}
              redirectToTransaction={(data) => redirectToTransaction(data)}
              setCheckId={(id) => setCheckId(id)}
              setNewAmount={(amount) => setNewAmount(amount)}
              updatePaidAmount={updatePaidAmount}
              isFailed={isFailed}
              screenFrom = "manage_batch"
              openAccountSharingModal={openAccountSharingModal}
            />
          </div>{" "}
        </Spin>
      </div>
      {accountSharingData && renderAccountSharingModal()}
    </div>
  );
};

const ManageBatchComponent = withRouter((props) => {
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const warehouseFilter = useContext(WarehouseContext);
  return (
    <ManageBatch
      warehouseFilter={warehouseFilter}
      orgData={orgData}
      userContext={userContext}
      {...props}
    />
  );
});

const WrappedManageBatchComponent = Form.create()(ManageBatchComponent);
export default WrappedManageBatchComponent;
