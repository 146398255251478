/* eslint-disable no-nested-ternary */
import React from 'react';
import BaseList from '../BaseList';
import {  isEmpty, doFormate } from '../../common/Common';
import { Tooltip } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { formatByTimeConfig } from '../../helpers/common';
import I18n from '../../common/I18n';

class StopsList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'stop_number',
        title: '#',
        width: 50,
        render: (data, record, index) => (
          <div>
            {
              index + 1
            }
          </div>
        ),
      },
      {
        key: 'order_no',
        title: I18n.t('order.order_no'),
        render: (stop) => {
          const stopOrderIds = stop.customer_order_ids || [];
          let ordersList = [];
          if (stopOrderIds && stopOrderIds.length > 0) {
            stopOrderIds.map((id) => {
              const order = this.props.currentRoute.customer_orders.find(order => id === order.customer_order_id);
              ordersList = [...ordersList, order.customer_order_number];
            });
          }
          return (<div>{!isEmpty(ordersList) && ordersList.length > 0 ? ordersList.join(', ') : ''}</div>);
        },
      },
      {
        key: 'formatted_stop_name',
        title: `${I18n.t('general.location')} ${I18n.t('general.name')}`,
        ellipsis: true,
        render: data => (
          <div>
            <Tooltip title={data.formatted_stop_name}>
              <u className='text-decoration-dashed'>{data.contact_details && data.contact_details.contact_name ? data.contact_details.contact_name : data.formatted_stop_name}</u>
            </Tooltip>
          </div>
        ),
      },
      {
        key: 'address',
        title: I18n.t('general.address'),
        render: stop => (
          <div className="alignLeft">{stop.location && stop.location.l_address ? <AddressInfo address={stop.location.l_address} showIcon /> : ''}</div>
        ),
      },
      {
        key: 'contact',
        title: I18n.t('general.contact_no'),
        render: (stop) => {
          const contact_details = stop.contact_details ? stop.contact_details : {};
          const customerPhoneOne = doFormate(contact_details.contact_phone_number_1);
          const customerPhoneTwo = doFormate(contact_details.contact_phone_number_2);
          return (<div className="alignLeft"><p>{customerPhoneOne}</p>{customerPhoneTwo !== 'NA' ? <p>{customerPhoneTwo}</p> : ''}</div>);
        },
      },
      {
        key: 'begins',
        title: I18n.t('general.begins'),
        render: (stop) => {
          const scheduledStartDateTimeWithTZ = stop.scheduled_start_date_time_with_tz ? formatByTimeConfig(stop.scheduled_start_date_time_with_tz, this.props.isMilitaryTime , "HH:mm" , "hh:mm A") : 'NA';
          return (<div className="alignLeft">{scheduledStartDateTimeWithTZ}</div>);
        },
        width:80,
      },
      {
        key: 'ends',
        title: I18n.t('general.ends'),
        render: (stop) => {
          const scheduledEndDateTimeWithTZ = stop.scheduled_end_date_time_with_tz ? formatByTimeConfig(stop.scheduled_end_date_time_with_tz, this.props.isMilitaryTime , "HH:mm" , "hh:mm A") : 'NA';
          return (<div className="alignLeft">{scheduledEndDateTimeWithTZ}</div>);
        },
        width:80,
      },
    ];
  }
}

export default StopsList;
