import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { ReflexContainer, ReflexElement, ReflexHandle, ReflexSplitter } from 'react-reflex';
import PreplanMap from './PreplanMap';
import UnassignedOrders from './UnassignedOrders';
import { isEmpty } from '../../common/Common';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import truckImage from "../../../assets/images/truck_white.png";
import { checkServiceExistance } from '../../helpers/common';
import { Button, Col, Dropdown, Icon, Menu, Popconfirm, Row, Statistic, Tag, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Card } from '../../common/UIComponents';
import ViewToggleButton from '../../containers/ViewToggleButton';
import PreplanHeader from './UnassignedHeader';
import { checkAddressValidation } from '../../helpers/preplan';
import SwitchDetailsModal from './SwitchDetailsModal';
import UnallocatedOrdersGridList from '../dispatches/UnallocatedOrdersGridList';
import {preplanData} from "../dispatches/preplanData";
import { ScreenKeys } from '../../containers/constants';
import { useCallback } from 'react';
import { useRef } from 'react';

function PreplanLeft (props) {
  const {
    handleMapData, applyFilters, handleEditOrderClick, onSearchNumbers,
    orderInfo, filter, setFilter, setAccountCodes, unassignedOrdersInfo,
    goToTab, resetSelectedOrders, routeInfo, currentPreplan, totalOrders,
    getOrders, orderProgress, routeProgress, accountCodes, accounts,
    zone_ids, preplanZones, selectedRoute, showOnMap, setCurrentRouteOnMap,
    getMarkerId, selectedStopMarkerId, clickedRouteId, currentStop,
    handleCurrentRoute, rearrangeForm, openedStopInfoWindow, resetHoveredStop,
    routeMouseOperation, mapData, currentWarehouse, currentRoute,
    handleUnassignOrdersInfo, setAssignModal, setProcessingRoute,
    findDependencyOrders, setZone_ids, setUnassignedOrdersInfo, setOrderInfo,
    handleAssignInfo, resetPreplan, createRoute, setOrderProgress, saveAutoPreplan,
    doCapacityValidation, handleResponse, preplanId, handleAutoPreplan,
    setPreplanProgress, setStopProgress, setRouteProcessedErrors,showMultiAddressResolve,closeMultipleAddressResolve,
    unsavedServiceDurationChanges,orgSettingsForTable,displayConfiguration,screen_from,vhTypes, orderVhTypes, setOrderVhTypes , showStopScreen, orderTypeFilter, setOrderTypeFilter, orderSettings,isMilitaryTime,
    setClusteredOrder, clusteredOrder, currentOrg, setMapSelectedList,showBeta,dispContext
  } = props;

  
  const [switchDetailsModalVisible, setSwitchDetailsModalVisible] = useState(false);
  const [currentGridRef, setCurrentGridRef] = useState(null);
  const isTextAirOrg = currentOrg && currentOrg.code === "TA";
  const [openRerrangeableModal, setOpenRerrangeableModal] = useState(false);
  const prevUnassignedOrdersInfoRef = useRef();

  const openToggleColoumns = () => {
    setOpenRerrangeableModal(true)
  }

  useEffect(() => {
  console.log("PreplanLeft useEffect")
  console.log("unassignedOrdersInfo", orderInfo)
  }, [ orderInfo]); // This effect runs whenever unassignedOrdersInfo changes
  useEffect(() => {
    prevUnassignedOrdersInfoRef.current = unassignedOrdersInfo;
  }, [ unassignedOrdersInfo ]); // This effect runs whenever unassignedOrdersInfo changes

  const handleSwitchDetails = () => {
    setSwitchDetailsModalVisible(true);
  };

  const closeSwitchDetailsModal = () => {
    setSwitchDetailsModalVisible(false);
  };
  const viewOptions = [ 'map', 'list', 'both' ];

  const onResize = (e) => {
    if (e.domElement) {
      e.domElement.classList.add("resizing");
    }
  };

  const onStopResize = (e) => {
    if (e.domElement) {
      e.domElement.classList.remove("resizing");
      handleMapData();
    }
  };

  const [ filteredRowData, setFilteredRowData ] = useState(preplanData);

  // calls when Filter value chnages
  const onFilterValueChange = (element, value) => {
    const newFilter = { ...filter, [ element ]: value };
    setFilter(newFilter);
  };

  const handleSingleSelectChange = (record, selected, selectedRows, e = {}) => {
    let orders = [ ...selectedRows ];
    // const previousRows = [ ...unassignedOrdersInfo.selectedOrderRows ];
    const previousRows = [ ...prevUnassignedOrdersInfoRef.current.selectedOrderRows ];
    const dependencyOrders = findDependencyOrders(
      totalOrders,
      "related_order",
      "id",
      record.id,
      "object",
      "type_of_order"
    );
    const dependencyIds = dependencyOrders.map((order) => order.id);
    const orderIndex = _.findIndex(selectedRows, [ "id", record.id ]);
    if (selected === false) {
      orders = previousRows.filter(
        (order) => !dependencyIds.includes(order.id)
      );
    } else if (selected && orderIndex >= 0) {
      const filteredItems = previousRows.filter(
        (order) => !dependencyIds.includes(order.id)
      );
      if (dependencyOrders.length > 0) {
        orders = [].concat(filteredItems, dependencyOrders);
      } else {
        orders = [ ...filteredItems ];
      }
    }
    handleUnassignOrdersInfo(orders);
  }
  // }, [unassignedOrdersInfo.selectedOrderRows, totalOrders, handleUnassignOrdersInfo]);

  // handling select/  deselect unassigned order from map
  const markSelected = (id) => {
    const prevUnassignedOrdersInfo = unassignedOrdersInfo;
    if (checkServiceExistance('NRURO')) {
      console.log(props.orderInfo)
      const orderIndex = _.findIndex(orderInfo, ["id", id]);
      const element = document.getElementById(id);
        if (element) {
          element.focus();
          const unassignEleCard = document.querySelector(`.uid-${id}`);
          if (unassignEleCard) {
            unassignEleCard.scrollIntoView({
              block: "end",
            });
          }
        }else if(currentGridRef && orderIndex){
          // currentGridRef.ensureIndexVisible(orderIndex)
          // const rowIndex = currentGridRef.getRowNode(id).rowIndex;
          // currentGridRef.ensureIndexVisible(rowIndex);
          //const rowNode = currentGridRef.getRowNode(id);
         
            //  currentGridRef.ensureIndexVisible(orderIndex, 'middle')
            // const columnApi = currentGridRef.columnApi;
            // const allColumns = columnApi.getAllColumns();
            // const currentColumnDefs = allColumns.map(col => col.getColDef());
            setTimeout(() => currentGridRef.setFocusedCell(orderIndex, 'customer_order_number'), 500);
          
        }else{
          console.error(`Element with ID '${id}' not found.`);
        }
        const selectedOrderKeys = [
          ...prevUnassignedOrdersInfo.selectedOrderKeys,
        ];
        const selectedOrderRows = [
          ...prevUnassignedOrdersInfo.selectedOrderRows,
        ];
        
        if(orderIndex >=0){
        if (selectedOrderKeys.includes(id)) {
         selectedOrderKeys.splice(selectedOrderKeys.indexOf(id), 1);
          selectedOrderRows.splice(_.findIndex(selectedOrderRows, ["id", id]), 1);
          handleSingleSelectChange(
            orderInfo[orderIndex],
            false,
            selectedOrderRows
          );
        } else if (orderIndex >= 0) {
          selectedOrderRows.push(orderInfo[orderIndex]);
         selectedOrderKeys.push(id);
          handleSingleSelectChange(
            orderInfo[orderIndex],
            true,
            selectedOrderRows
          );
        }
      }
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    color: isDragging ? "red" : "green",
    ...draggableStyle,
  });

  // const [ showBetaFeature, setShowBetaFeature ] = React.useState(isTextAirOrg);
  const showBetaFeature = showBeta;

  // const handleBetaSwitchChange = (checked) => {
  //   setShowBetaFeature(checked);
  // };

  const renderCartIcon = (count) => (
    <Droppable droppableId="unassignedDroppablefrommap">
      { (provided, snapshot) => (
        <div { ...provided.droppableProps } ref={ provided.innerRef }>
          <Draggable key="fromMap" draggableId="fromMap" index={ 8 }>
            { (provided, snapshot) => (
              <div
                ref={ provided.innerRef }
                { ...provided.draggableProps }
                { ...provided.dragHandleProps }
                style={ getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                ) }
              >
                <div
                  style={ {
                    width: 50,
                    height: 30,
                    borderRadius: 50,
                    backgroundColor: "#607AD6",
                    fontSize: 15,
                    zIndex: 999999,
                  } }
                  className="draggingBlock center"
                >
                  <img src={ truckImage } style={ { width: 25 } } />
                  <sup>
                  {count} {/*  { unassignedOrdersInfo.selectedOrderKeys.length } */}
                  </sup>
                </div>
              </div>
            ) }
          </Draggable>
          { provided && provided.placeholder }
        </div>
      ) }
    </Droppable>
  );

  const UNASSIGNED_ORDERS_PANEL_SIZE = 0.4;
  const MAP_PANEL_SIZE = 0.6;
  const [ currentView, setCurrentView ] = useState('both');

  const handleViewToggle = (newView) => {
    // setCurrentView(newView);
    if(newView === "map"){
      setPanelSize({
        ...panelSize,
        pane_1: 0,
        pane_2: 1
      });
    }else if(newView === "list"){
      setPanelSize({
        ...panelSize,
        pane_1: 1,
        pane_2: 0
      });
    }else{
      setPanelSize({
        ...panelSize,
        pane_1: UNASSIGNED_ORDERS_PANEL_SIZE,
        pane_2: MAP_PANEL_SIZE
      });
    }
  };

  const [ panelSize, setPanelSize ] = useState({
    pane_1: UNASSIGNED_ORDERS_PANEL_SIZE,
    pane_2: MAP_PANEL_SIZE
  });

  const onPanelClick = (value) => {
    if (value === "pane_1") {
      if (panelSize.pane_1 === 1) {
        setPanelSize({
          ...panelSize,
          pane_1: UNASSIGNED_ORDERS_PANEL_SIZE,
          pane_2: MAP_PANEL_SIZE
        });
      } else {
        setPanelSize({
          ...panelSize,
          pane_1: 1,
          pane_2: 0
        });
      }
    }
    if (value === "pane_2") {
      if (panelSize.pane_2 === 1) {
        setPanelSize({
          ...panelSize,
          pane_1: UNASSIGNED_ORDERS_PANEL_SIZE,
          pane_2: MAP_PANEL_SIZE
        });
      } else {
        setPanelSize({
          ...panelSize,
          pane_1: 0,
          pane_2: 1
        });
      }
    }
  };

  function autoPreplan (action) {
    checkAddressValidation(
      action,
      {},
      unassignedOrdersInfo,
      totalOrders,
      resetPreplan,
      createRoute,
      handleUnassignOrdersInfo,
      handleAssignInfo,
      saveAutoPreplan,
      doCapacityValidation,
      handleResponse,
      preplanId,
      handleAutoPreplan,
      setPreplanProgress,
      preplanZones,
      setStopProgress,
      setRouteProcessedErrors,
      currentPreplan,
      setUnassignedOrdersInfo,
    );
  }

  const selectedOrderKeys = unassignedOrdersInfo.selectedOrderKeys;
  const menuItems = [
    // {
    //   key: 'refresh',
    //   icon: 'reload',
    //   text: 'Refresh',
    //   onClick: getOrders,
    // },
    {
      key: 'resolveAddress',
      icon: 'check-circle',
      text: 'Resolve Address',
      disabled: !selectedOrderKeys.length,
      onClick: showMultiAddressResolve,
    },
    {
      key: 'autoPreplan',
      icon: 'schedule',
      text: 'Auto Preplan',
      disabled: !selectedOrderKeys.length || !!routeInfo.length,
      onClick: () => autoPreplan('auto_preplan'),
      condition: !routeInfo.length,
    },
    {
      key: 'allocateRoute',
      icon: 'environment',
      text: 'Allocate Route',
      disabled: !selectedOrderKeys.length || unsavedServiceDurationChanges || !checkServiceExistance('NRURO'),
      onClick: () => autoPreplan('add_route_button'),
      condition: checkServiceExistance('NRURO'),
    },
    // {
    //   key: 'switchDetails',
    //   icon: 'swap',
    //   text: 'Switch Warehouse',
    //   disabled: !selectedOrderKeys.length, 
    //   onClick: handleSwitchDetails,
    // },
  ];


  // Sort the menuItems array based on the text of the actions (excluding "Refresh" and "Clear")
  menuItems.sort((a, b) => {
    if (a.text === 'Refresh') return -1;
    if (b.text === 'Refresh') return 1;
    return a.text.localeCompare(b.text);
  });

  const menu = (
    <Menu>
      { menuItems.map(item => (
        <Menu.Item
          key={ item.key }
          disabled={ item.disabled }
          onClick={ item.onClick }
        >
          <Icon type={ item.icon } />
          { item.text }
        </Menu.Item>
      )) }
    </Menu>
  );

  return (
    <>
     {/* <SwitchDetailsModal
        visible={switchDetailsModalVisible}
        onClose={closeSwitchDetailsModal}
        selectedOrders={unassignedOrdersInfo.selectedOrderRows}
        refreshOrders={getOrders}
      /> */}
      <PreplanHeader
        totalOrders={ totalOrders }
        // handleBetaSwitchChange={ handleBetaSwitchChange }
        showBetaFeature={ showBetaFeature }
        unassignedOrdersInfo={ unassignedOrdersInfo }
        resetSelectedOrders={ resetSelectedOrders }
        menu={ menu }
        currentView={ currentView }
        handleViewToggle={ handleViewToggle }
        viewOptions={ viewOptions }
        getUnassignedOrders={ getOrders }
        currentOrg={currentOrg}
        openToggleColoumns={openToggleColoumns}
      />
      <ReflexContainer orientation="horizontal" style={{
        height: "calc(100vh - 100px)",
      }}>
        <ReflexElement
          flex={ panelSize.pane_1 }
          onStopResize={ onStopResize }
          onResize={ onResize }
          direction={ 1 }
          propagateDimensionsRate={ 200 }
          propagateDimensions={ true }
          style={ { overflow: "hidden" } }
        >
          <div className="pane-content pane_1">
            {/* <UnallocatedOrdersGridList
              rawDataSource={ filteredRowData }
              accountCodesWithExceededLimit={ [] }
              onRowSelection={ () => { } }
              accountsList={ [] }
              displayConfiguration={ {} }
              showTriggerEdit={ true }
              showDelete={ false }
              showActions={ false }
              showDetailsLink={ false }
              showWeight={ true }
              hideAppointmentdate={ true }
              tableName={ScreenKeys.PREPLAN.UNASSIGNED_ORDERS_V2}
            /> */}
          
            <UnassignedOrders
              showBetaFeature={ showBetaFeature }
              accounts={ accounts }
              accountCodes={ accountCodes }
              applyFilters={ applyFilters }
              createRoute={ createRoute }
              currentPreplan={ currentPreplan }
              doCapacityValidation={ doCapacityValidation }
              filter={ filter }
              getOrders={ getOrders }
              goToTab={ goToTab }
              handleAssignInfo={ handleAssignInfo }
              handleAutoPreplan={ handleAutoPreplan }
              handleEditOrderClick={ handleEditOrderClick }
              handleResponse={ handleResponse }
              handleUnassignOrdersInfo={ handleUnassignOrdersInfo }
              onFilterValueChange={ onFilterValueChange }
              onSearchNumbers={ onSearchNumbers }
              orderInfo={ orderInfo }
              orderProgress={ orderProgress }
              preplanId={ preplanId }
              preplanZones={ preplanZones }
              resetPreplan={ resetPreplan }
              resetSelectedOrders={ resetSelectedOrders }
              saveAutoPreplan={ saveAutoPreplan }
              routeInfo={ routeInfo }
              setAccountCodes={ setAccountCodes }
              setAssignModal={ setAssignModal }
              setFilter={ setFilter }
              setOrderInfo={ setOrderInfo }
              setOrderProgress={ setOrderProgress }
              setProcessingRoute={ setProcessingRoute }
              setPreplanProgress={ setPreplanProgress }
              setRouteProcessedErrors={ setRouteProcessedErrors }
              setStopProgress={ setStopProgress }
              setUnassignedOrdersInfo={ setUnassignedOrdersInfo }
              setZone_ids={ setZone_ids }
              showMultiAddressResolve={ showMultiAddressResolve }
              totalOrders={ totalOrders }
              unassignedOrdersInfo={ unassignedOrdersInfo }
              zone_ids={ zone_ids }
              unsavedServiceDurationChanges={ unsavedServiceDurationChanges }
              orgSettingsForTable={ orgSettingsForTable }
              displayConfiguration={ displayConfiguration }
              screen_from={ screen_from }
              vhTypes={vhTypes}
              orderVhTypes={orderVhTypes}
              setOrderVhTypes={setOrderVhTypes}
              orderTypeFilter={orderTypeFilter}
              setOrderTypeFilter={setOrderTypeFilter}
              orderSettings={orderSettings}
              isMilitaryTime={isMilitaryTime}
              clusteredOrder={clusteredOrder}
              setCurrentGridRef={setCurrentGridRef}
              currentGridRef={currentGridRef}
              dispContext={dispContext}
              openRerrangeableModal={openRerrangeableModal}
              setOpenRerrangeableModal={setOpenRerrangeableModal}
            />
          </div>
        </ReflexElement>
        <ReflexSplitter propagate />
        <ReflexElement
          flex={ panelSize.pane_2 }
          onStopResize={ onStopResize }
          onResize={ onResize }
          direction={ -1 }
          propagateDimensionsRate={ 200 }
          propagateDimensions={ true }
          style={ { overflow: "hidden" } }
        >
          <ReflexHandle className="handle">
        </ReflexHandle>
          <div className="pane-content map-container">
            <PreplanMap
              handleUnassignOrdersInfo={ handleUnassignOrdersInfo }
              totalOrders={ totalOrders }
              findDependencyOrders={ findDependencyOrders }
              setUnassignedOrdersInfo={ setUnassignedOrdersInfo }
              unassignedOrdersInfo={ unassignedOrdersInfo }
              orderProgress={ orderProgress }
              routeProgress={ routeProgress }
              orderInfo={ orderInfo }
              selectedRoute={ selectedRoute }
              showOnMap={ showOnMap }
              setCurrentRouteOnMap={ setCurrentRouteOnMap }
              markSelected={ markSelected }
              getMarkerId={ getMarkerId }
              selectedStopMarkerId={ selectedStopMarkerId }
              clickedRouteId={ clickedRouteId }
              currentStop={ currentStop }
              handleCurrentRoute={ handleCurrentRoute }
              rearrangeForm={ rearrangeForm }
              openedStopInfoWindow={ openedStopInfoWindow }
              resetHoveredStop={ resetHoveredStop }
              routeMouseOperation={ routeMouseOperation }
              mapData={ mapData }
              currentWarehouse={ currentWarehouse }
              currentRoute={ currentRoute }
              onPanelClick={ onPanelClick }
              panelSize={ panelSize }
              showStopScreen={showStopScreen}
              setClusteredOrder={setClusteredOrder}
              renderCartIcon={renderCartIcon}
              setMapSelectedList={setMapSelectedList}
              showBetaFeature={showBetaFeature}
            />
            {!showBetaFeature && <div
              style={ {
                width: 30,
                float: "right",
                position: "relative",
                bottom: "14vh",
                right: 30,
              } }
            >
              { unassignedOrdersInfo.selectedOrderKeys
                .length > 0 && renderCartIcon(unassignedOrdersInfo.selectedOrderKeys.length) }
            </div>}
          </div>
        </ReflexElement>
      </ReflexContainer>
    </>

  );
}

export default PreplanLeft;

PreplanLeft.propTypes = {
  handleMapData: PropTypes.func,
  applyFilters: PropTypes.func,
  handleEditOrderClick: PropTypes.func,
  onSearchNumbers: PropTypes.func,
  orderInfo: PropTypes.array,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  setAccountCodes: PropTypes.func,
  unassignedOrdersInfo: PropTypes.object,
  goToTab: PropTypes.func,
  resetSelectedOrders: PropTypes.func,
  routeInfo: PropTypes.array,
  currentPreplan: PropTypes.object,
  totalOrders: PropTypes.array,
  getOrders: PropTypes.func,
  orderProgress: PropTypes.bool,
  routeProgress: PropTypes.bool,
  accountCodes: PropTypes.array,
  orderVhTypes: PropTypes.array,
  accounts: PropTypes.array,
  zone_ids: PropTypes.array,
  preplanZones: PropTypes.array,
  selectedRoute: PropTypes.string,
  showOnMap: PropTypes.bool,
  setCurrentRouteOnMap: PropTypes.func,
  getMarkerId: PropTypes.func,
  selectedStopMarkerId: PropTypes.string,
  clickedRouteId: PropTypes.string,
  currentStop: PropTypes.object,
  handleCurrentRoute: PropTypes.func,
  rearrangeForm: PropTypes.bool,
  preplanId: PropTypes.bool,
  doCapacityValidation: PropTypes.bool,
  openedStopInfoWindow: PropTypes.string,
  resetHoveredStop: PropTypes.func,
  routeMouseOperation: PropTypes.bool,
  mapData: PropTypes.array,
  currentWarehouse: PropTypes.object,
  currentRoute: PropTypes.object,
  handleUnassignOrdersInfo: PropTypes.func,
  setAssignModal: PropTypes.func,
  setProcessingRoute: PropTypes.func,
  findDependencyOrders: PropTypes.func,
  setZone_ids: PropTypes.func,
  setUnassignedOrdersInfo: PropTypes.func,
  handleAutoPreplan: PropTypes.func,
  handleResponse: PropTypes.func,
  setPreplanProgress: PropTypes.func,
  setOrderVhTypes: PropTypes.func,
  setOrderTypeFilter: PropTypes.func,
  currentOrg : PropTypes.object
};

PreplanLeft.defaultProps = {
  handleMapData: () => { },
  applyFilters: () => { },
  handleEditOrderClick: () => { },
  onSearchNumbers: () => { },
  orderInfo: [],
  filter: {},
  setFilter: () => { },
  setAccountCodes: () => { },
  unassignedOrdersInfo: {},
  goToTab: () => { },
  resetSelectedOrders: () => { },
  routeInfo: [],
  currentPreplan: {},
  totalOrders: [],
  getOrders: () => { },
  orderProgress: false,
  routeProgress: false,
  accountCodes: [],
  accounts: [],
  zone_ids: [],
  preplanZones: [],
  selectedRoute: '',
  showOnMap: false,
  orderVhTypes: [],
  setCurrentRouteOnMap: () => { },
  getMarkerId: () => { },
  selectedStopMarkerId: '',
  clickedRouteId: '',
  currentStop: {},
  handleCurrentRoute: () => { },
  rearrangeForm: false,
  openedStopInfoWindow: '',
  doCapacityValidation: false,
  preplanId: '',
  resetHoveredStop: () => { },
  routeMouseOperation: false,
  mapData: [],
  currentWarehouse: {},
  currentRoute: {},
  handleUnassignOrdersInfo: () => { },
  setAssignModal: () => { },
  setProcessingRoute: () => { },
  findDependencyOrders: () => { },
  setZone_ids: () => { },
  setUnassignedOrdersInfo: () => { },
  handleAutoPreplan: () => { },
  handleResponse: () => { },
  setPreplanProgress: () => { },
  setOrderVhTypes: () => { },
  setOrderTypeFilter: () => { },
  currentOrg : {},
};