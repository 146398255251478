import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormItem,
  Input,
  Row,
  message,
} from '../../common/UIComponents';
import I18n from '../../common/I18n';
import FormButtons from '../common/FormButtons';
import { changePassword } from '../../api/UsersApi';
import FormErrors from '../common/FormErrors';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, inProgress: false };
    
  }

  handleOnChange = (element, value) => {
    this.setState({ user: Object.assign({}, this.state.user, { [element]: value }) });
  };

  handleSave = () => {
    const { user } = this.state;
    const { password, password_confirmation } = user;
    const errors = [];
      if (!password) {
      errors.push(I18n.t("messages.new_password_blank"));
    }
    if (!password_confirmation) {
      errors.push(I18n.t('messages.confirm_password_blank'));
    }
  
    if (password !== password_confirmation) {
      errors.push(I18n.t('messages.new_and_confirm_password_blank'));
    }
  
    if (errors.length > 0) {
      this.setState({ errors, inProgress: false });
      return;
    }
    this.setState({ inProgress: true });                                                                                                                                                    
    changePassword({ ...user, role: this.props.userType })
      .then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t('messages.updated'));
          this.setState({ user: {}, errors: '', inProgress: false });
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
        })
        .catch((error) => {
            console.error("Error occurred while calling changePassword API:", error);
      });
  };


  render() {
    const { user } = this.state;
    return (
      <Row  >
        <Col md={{ offset: 8, span: 8 }}>
        <Row>
            <Col>
            <FormItem label={I18n.t('general.current_password')}>
              <Input.Password
                value={user.current_password}
                onChange={e => this.handleOnChange('current_password', e.target.value)}
                type="password"
                autocomplete="off"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
        <Col>
            <FormItem label={I18n.t('general.new_password')}>
              <Input.Password
                value={user.password}
                onChange={e => this.handleOnChange('password', e.target.value)}
                type="password"
                autocomplete="off"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
        <Col>
            <FormItem label={I18n.t('general.confirm_password')}>
              <Input.Password
                value={user.password_confirmation}
                onChange={e => this.handleOnChange('password_confirmation', e.target.value)}
                type="password"
                autocomplete="off"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        <Row>
          <Col xs={24}>
            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel,this.props.showCancel)}
          </Col>
        </Row>
        </Col>
      </Row>
    );
  }
}

ChangePasswordForm.propTypes = {
  userType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showCancel: PropTypes.bool,
};
ChangePasswordForm.defaultProps = {
  showCancel: true, // By default, the Cancel button is not hidden
};

export default ChangePasswordForm;
