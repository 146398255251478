/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Icon, } from '../../common/UIComponents';
import I18n from '../../common/I18n';
import BranchesIcon from '../common/BranchesIcon';
import possibleTimeImg from "../../../assets/images/possibleTimeImg.svg"
import { Card } from 'antd';


const StopDistanceInfo = ({ stop }) => {
  return (
    <Row className="distanceInfo" type='flex' gutter={10}>
      <Col xs={stop?.possible_delay ? 12 : 24} >
        <Card size='small' title="Distance Betweeen Stops" style={{
          width: stop?.possible_delay ? '18rem' : 'auto',
        }}>
        <Row> <Col xs={9}><BranchesIcon/>&nbsp;&nbsp;{I18n.t('routes.distance')}</Col><Col xs={1}>:</Col><Col xs={14}>{stop.distance}</Col></Row>
        <Row> <Col xs={9}><Icon type="clock-circle" />&nbsp;&nbsp;{I18n.t('general.travel_time')}</Col><Col xs={1}>:</Col><Col xs={14}>{stop.time}</Col></Row>
        <Row> <Col xs={9}><Icon type="clock-circle" />&nbsp;&nbsp;{I18n.t('routes.rest')}</Col><Col xs={1}>:</Col><Col xs={14}>{stop.rest}</Col></Row>
        <Row> <Col xs={9}><Icon type="clock-circle" />&nbsp;&nbsp;{I18n.t('routes.waiting')}</Col><Col xs={1}>:</Col><Col xs={14}>{stop.waiting}</Col></Row>
        </Card>
      </Col>

      {stop?.possible_delay &&
        <Col xs={12}>
          <Card size='small' title="ETA  Delay" style={{
            width: stop?.possible_delay ? '18rem' : 'auto',
          }}>
          <Row>
            {" "}
            <Col xs={9}>
              <img
                style={{
                  width: "1rem",
                  height: "1rem",
                }}
                src={possibleTimeImg}
                alt=""
              />
                &nbsp;&nbsp;Delay
            </Col>
            <Col xs={1}>:</Col>
              <Col xs={14}>{stop?.possible_delay ? stop?.possible_delay : '0'}</Col>
          </Row>
          <Row>
            {" "}
            <Col xs={9}>
              <Icon type="book" />
              &nbsp;&nbsp;{I18n.t("general.notes")}
            </Col>
            <Col xs={1}>:</Col>
              <Col xs={14}>{stop?.reason_for_delay ? stop.reason_for_delay : 'Late Notes'}</Col>
          </Row>
          </Card>
        </Col>}
    </Row>
  );
};
export default StopDistanceInfo;

StopDistanceInfo.propTypes = {
  stop: PropTypes.shape().isRequired,
};

