import React, {  useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  fetchSubscriptionData,
  saveSubscriptionData,
} from "../../../api/Contacts";
import { alertMessage } from "../../../common/Common";
import { Button, Col, Row, Spin } from "../../../common/UIComponents";
import FormButtons from "../../common/FormButtons";
import SwitchComponent from "../../../common/switchComponent";
import { isEmpty } from "lodash";
import I18n from "../../../common/I18n";

const DecisiveButton = ({ value = false, toggle = () => {} }) => {
  return (
    <Row>
      <Button
        className={`btnConfig ${value === true && "btnConfigActive"}`}
        onClick={() => toggle(true)}
      >
        Yes
      </Button>
      <Button
        className={`btnConfig ${value === false && "btnConfigActive"}`}
        onClick={() => toggle(false)}
      >
        No
      </Button>
    </Row>
  );
};

function EventSubscriptionForm({
  initialValues,
  onSave = () => {},
  onCancel = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [savedAlertsConfig, setSavedAlertsConfig] = useState({});
  const [alertsConfig , setAlertsConfig] = useState({});
  const [currentAlertConfig , setCurrentAlertConfig] = useState({});

  const handleSave = async () => {
    try {
      setLoading(true);
      
      // set initial values 
      let finalAlertConfig = alertsConfig.map(function (item) {
        return {
          alert_name: item.alert_name,
          alert_code: item.alert_code,
          alert_value: false
        };
      }
      );

      // overwrite with saved values
      Object.entries(currentAlertConfig).forEach(function ([ key, value ]) {
        finalAlertConfig.forEach(function (item) {
          if (item.alert_code === key) {
            if (value !== undefined) {
              item.alert_value = value;
            } else {
              item.alert_value = false;
            }
          }
        });
      });

      const response = await saveSubscriptionData({alerts : finalAlertConfig}, initialValues.id);
      if (response.success) {
        typeof onSave === "function" && onSave(I18n.t("messages.saved"));
      } else {
        throw new Error(response.errors[0]);
      }
    } catch (error) {
      alertMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { alerts} = initialValues;
    const alertValues = {};
    alerts.forEach((alertItem) => {
      alertValues[alertItem.alert_code] =
         alertItem.alert_value = alertItem.alert_value === "true" ? true : false; 
    });
    setSavedAlertsConfig(alertValues);
  }, [initialValues]);

  useEffect(() => {
    setInitializing(true);
    const { level } = initialValues
    const payload = {
      level
    };
    fetchSubscriptionData(payload).then((response) => {
      if (response.success) {
        setAlertsConfig(response.alerts);
        const defaultAlertConfig = {};
        response.alerts.forEach((alertItem) => {
          defaultAlertConfig[ alertItem.alert_code ] = false;
        });
        setCurrentAlertConfig({
          ...defaultAlertConfig,
          ...savedAlertsConfig,
        });
      }
    })
      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setInitializing(false);
      });
  }, [ savedAlertsConfig ]);

  const toggle = (key, value) => {
    setCurrentAlertConfig((prev) => ({ ...prev, [key]: Boolean(value) }));
  };

  const renderAlertsConfig = () => { 
    return (
      Object.keys(currentAlertConfig).length &&
      Object.entries(currentAlertConfig).map(([key, value]) => {
        const alertRecord = _.find(alertsConfig, { alert_code: key});
        const name = !isEmpty(alertRecord) ? alertRecord.alert_name : key;
        return (
          <Row className="configLableRow flexAlignCenter">
            <Col xs={ 10 } sm={ 10 } md={ 10 } lg={ 10 }>
              {name}
            </Col>
            <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 }>
              :
            </Col>
            <Col xs={ 13 } sm={ 13 } md={ 13 } lg={ 13 }>
              {/* <DecisiveButton
                value={ value }
                toggle={ (value) => toggle(key, value) }
              /> */}
              <SwitchComponent  
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={true}
                unCheckedValue={false}     
                isChecked={currentAlertConfig[key]}
                handleChange={(value)=> toggle(key,value)}/>
            </Col>
          </Row>
        );
      })
    )
  }


  return (
    <div>
      <Row>
        <Spin spinning={initializing} delay={0}>
          <Row className="">
            <Row className="padding10">
              {renderAlertsConfig()}
            </Row>
          </Row>
          <Row>{FormButtons(loading, handleSave, onCancel)}</Row>
        </Spin>
      </Row>
    </div>
  );
}

export default EventSubscriptionForm;

EventSubscriptionForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EventSubscriptionForm.defaultProps = {
  initialValues: {},
  onSave: () => {},
  onCancel: () => {},
};