/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Input,
  Button,
  Select,
  Radio,
} from "../../common/UIComponents";
import I18n from "../../common/I18n";
import MobileInput from "../common/MobileInput";
import SwitchComponent from "../../common/switchComponent";
import LabelInfo from "./LabelInfo";

const { Option } = Select;
const ShipperForm = ({
  config = {},
  handleOnInputChange,
  forms = [],
  handleCheckBtn,
  handleOnSelect,
}) => {
  const renderForm = () => (
    <Fragment>
      <Row>
        <div className="settingHeader">
          <span className="textBold">
            {I18n.t("configurations.headings.shipper")}
          </span>
        </div>
      </Row>
      {/* <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.email_for_alerts_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Input
                value={config.email_for_alerts}
                style={{ width: '60%' }}
                className="marginRight10"
                onChange={(e) =>
                  handleOnInputChange(
                    I18n.t('configurations.email_for_alerts'),
                    e.target.value,
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.mobile_number_for_alerts_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <MobileInput
                handleInputChange={(value, data) => {
                  handleOnInputChange(
                    I18n.t('configurations.mobile_number_for_alerts'),
                    value,
                  )
                }}
                inputStyle={{ width: '60%' }}
                value={config.mobile_number_for_alerts}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.order_received_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_order_received'),
                    value,
                  )
                }
                isChecked={
                  config[I18n.t('configurations.shipper_order_received')] ===
                  'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.appointment_confirmation_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_appointment_confirmation'),
                    value,
                  )
                }
                isChecked={
                  config[
                    I18n.t('configurations.shipper_appointment_confirmation')
                  ] === 'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.order_dispatched_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_order_dispatched'),
                    value,
                  )
                }
                isChecked={
                  config[I18n.t('configurations.shipper_order_dispatched')] ===
                  'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.arrival_at_stop_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_arrival_at_stop'),
                    value,
                  )
                }
                isChecked={
                  config[I18n.t('configurations.shipper_arrival_at_stop')] ===
                  'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.near_by_location_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_near_by_location'),
                    value,
                  )
                }
                isChecked={
                  config[I18n.t('configurations.shipper_near_by_location')] ===
                  'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.prior_day_notification_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.prior_day_notification_shipper'),
                    value,
                  )
                }
                isChecked={
                  config[
                    I18n.t('configurations.prior_day_notification_shipper')
                  ] === 'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.order_delivered_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_order_delivered'),
                    value,
                  )
                }
                isChecked={
                  config[I18n.t('configurations.shipper_order_delivered')] ===
                  'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.communication_preference')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} className="textBold">
              <Radio.Group
                onChange={(e) =>
                  handleCheckBtn(
                    'shipper_communication_preference',
                    e.target.value,
                  )
                }
                value={config.shipper_communication_preference}
              >
                {Object.keys(communicationPreferenceValues).map((key) => (
                  <Radio value={key} key={key}>
                    {communicationPreferenceValues[key]}
                  </Radio>
                ))}
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.pickup_order_form_label")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.pickup_order_form_desc"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Select
                className="width100Per"
                onChange={(val) => handleOnSelect("pickup_form_id", val)}
                value={config.pickup_form_id ? config.pickup_form_id : ""}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "60%", marginRight: "10px" }}
              >
                <Option key="form" value="">
                  -- None --
                </Option>
                {forms.map((province) => (
                  <Option key={province.id} value={province.id}>
                    {province.name}
                  </Option>
                ))}
              </Select>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn("is_shipper_pickup_form_mandatory", value)
                }
                isChecked={
                  config["is_shipper_pickup_form_mandatory"] === "true"
                }
                checkedChildren={"Mandatory"}
                unCheckedChildren={"Optional"}
                checkedValue={"true"}
                unCheckedValue={"false"}
                isDisabled={!config.pickup_form_id}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t("configurations.order_form_label")}
              <LabelInfo
                title={I18n.t("configurations.definitions.order_form_desc")}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Select
                className="width100Per"
                onChange={(val) => handleOnSelect("form_id", val)}
                value={config.form_id ? config.form_id : ""}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "60%", marginRight: "10px" }}
              >
                <Option key="form" value="">
                  -- None --
                </Option>
                {forms.map((province) => (
                  <Option key={province.id} value={province.id}>
                    {province.name}
                  </Option>
                ))}
              </Select>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn("is_shipper_delivery_form_mandatory", value)
                }
                isChecked={
                  config["is_shipper_delivery_form_mandatory"] === "true"
                }
                checkedChildren={"Mandatory"}
                unCheckedChildren={"Optional"}
                checkedValue={"true"}
                unCheckedValue={"false"}
                isDisabled={!config.form_id}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.eta_configuration_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <SwitchComponent
                handleChange={(value) =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_eta_configuration'),
                    value,
                  )
                }
                isChecked={
                  config[I18n.t('configurations.shipper_eta_configuration')] ===
                  'true'
                }
                checkedChildren={'Yes'}
                unCheckedChildren={'No'}
                checkedValue={'true'}
                unCheckedValue={'false'}
              />
            </Col>
          </Row>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.eta_alert_interval_label")}
                  <span className="subTextLable">
                    {I18n.t("configurations.eta_alert_interval_subtext_label")}
                  </span>
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.eta_alert_interval_desc"
                      )}
                    />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Input
                    value={
                      config[I18n.t("configurations.eta_alert_interval_key")] ||
                      ""
                    }
                    onChange={(e) =>
                      handleOnInputChange(
                        I18n.t("configurations.eta_alert_interval_key"),
                        e.target.value
                      )
                    }
                    type="number"
                    className="marginRight10"
                    style={{ width: "60%" }}
                    min={0}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t(
                    "configurations.group_shipper_bol_notification_label"
                  )}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.group_shipper_bol_notification_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <SwitchComponent
                    handleChange={(value) =>
                      handleCheckBtn(
                        I18n.t("configurations.group_shipper_bol_notification"),
                        value
                      )
                    }
                    isChecked={
                      config[
                        I18n.t("configurations.group_shipper_bol_notification")
                      ] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
  return <Fragment>{renderForm()}</Fragment>;
};
export default ShipperForm;

ShipperForm.propTypes = {
  config: PropTypes.shape().isRequired,
  handleOnAddressChange: PropTypes.func.isRequired,
  handleOnInputChange: PropTypes.func.isRequired,
};

{
  /* <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.one_stop_before_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Button
                className={
                  config.shipper_one_stop_before &&
                  config.shipper_one_stop_before ===
                    I18n.t('common.true')
                    ? 'btnConfig btnConfigActive'
                    : 'btnConfig'
                }
                onClick={() =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_one_stop_before'),
                    I18n.t('common.true'),
                  )
                }
              >
                Yes
              </Button>
              <Button
                className={
                  config.shipper_one_stop_before &&
                  config.shipper_one_stop_before ===
                    I18n.t('common.false')
                    ? 'btnConfig btnConfigActive'
                    : 'btnConfig'
                }
                onClick={() =>
                  handleCheckBtn(
                    I18n.t('configurations.shipper_one_stop_before'),
                    I18n.t('common.false'),
                  )
                }
              >
                No
              </Button>
            </Col>
          </Row>
        </Col>
       */
}

{
  /*
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.status_response_label')}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>:</Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <Button
                className={
                  config.status_response && config.status_response === I18n.t('common.true') ? 'btnConfig btnConfigActive' : 'btnConfig'
                }
                onClick={() => handleCheckBtn(I18n.t('configurations.status_response'), I18n.t('common.true'))}
              >Yes
              </Button>
              <Button
                className={
                  config.status_response && config.status_response === I18n.t('common.false') ? 'btnConfig btnConfigActive' : 'btnConfig'
                }
                onClick={() => handleCheckBtn(I18n.t('configurations.status_response'), I18n.t('common.false'))}
              >No
              </Button>
            </Col>

          </Row>
        </Col>
      */
}
