/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Col,
  FormItem,
  Input,
  Row,
  Checkbox,
  Radio,
  Select,
} from '../../common/UIComponents'
import {
  alertMessage,
  doValidate,
  isEmpty,
  formatCode,
} from '../../common/Common'
import I18n from '../../common/I18n'
import FormButtons from '../common/FormButtons'
import FormErrors from '../common/FormErrors'
import {
  fetchAccessorialsComponents,
  fetchCodeDetails,
  saveCustomAccessorial,
} from '../../api/Accessorials'
import SwitchComponent from "../../common/switchComponent";
import { renderAlertMessage } from '../../helpers/common'
import BaseSelect from '../common/SelectDropdowns/BaseSelect'
import GLCodeSelect from '../common/SelectDropdowns/GLCodeSelect'


const accessbleTo = ['CONSIGNEE', 'DRIVER']
// const accessbleTo = ["Consignee", "Driver"];
const defaultAccessorial = {
  accessible_to: [],
  components: [],
  accessorial_config_params: { add_fuel: 'false' , warehouse_operation: "false",consolidation_type:'none'}
}
const requiredFields = [
  {
    form_field: 'name',
    ui_name: I18n.t('general.name'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'code',
    ui_name: I18n.t('general.code'),
    isRequired: true,
    inputType: 'code',
  },
  {
    form_field: 'gl_code',
    ui_name: I18n.t('accessorials.gl_code'),
    isRequired: true,
    inputType: 'text',
  },
]

class AccessorialForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentAccessorial: this.props.isNew
        ? { ...defaultAccessorial }
        : this.props.currentAccessorial,
      components: [],
      isNew: this.props.isNew,
      account: this.props.account || {},
      inProgress: false,
      errors: [],
      inputValue: "",
      codeDescription: "",
      codes: [],
      filterCodes: [],
      isLoading: false
    }
  }

  componentDidMount() {
    this.getAccessorialsCodes()
    this.getComponents()
  }

  getAccessorialsCodes = () => {
    this.setState({ isLoading: true })
    fetchCodeDetails().then((result) => {
      if (result.success) {
        this.setState({
          codes: result.data?.codes ? result.data.codes : [],
          isLoading: false
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ isLoading: false })
      }
    })

  }

  getComponents = () => {
    this.setState({ inProgress: true })
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const components = result.components || []
        const { currentAccessorial } = this.state
        if (!this.state.isNew && currentAccessorial.components) {
          const ratesourceEle = components.filter(
            (component) =>
              currentAccessorial.components.includes(component.code) &&
              component.component_section === 'rate_source',
          )
          const resourcesEle = components.filter(
            (component) =>
              currentAccessorial.components.includes(component.code) &&
              component.component_section === 'resources',
          )
          const hybridEle = components.filter(
            (component) =>
              currentAccessorial.components.includes(component.code) &&
              component.component_section === 'hybrid_source',
          )
          currentAccessorial.rate_source =
            ratesourceEle.length > 0 ? ratesourceEle[0].code : ''
          currentAccessorial.resources =
            resourcesEle.length > 0 ? resourcesEle[0].code : ''
          currentAccessorial.hybrid_source =
            hybridEle.length > 0 ? hybridEle[0].code : ''
        }
        if (isEmpty(currentAccessorial.accessorial_config_params)){
          currentAccessorial.accessorial_config_params = {}
        }
        const selectedObject = currentAccessorial.x12_code ? this.state.codes.find((item) => item.code === currentAccessorial.x12_code) : "";        
        this.setState({
          components,
          currentAccessorial,
          inputValue: currentAccessorial?.x12_code ? selectedObject ? selectedObject?.code + `- ${selectedObject?.charge_code_description}` : "" : "",
          inProgress: false,
          filterCodes: selectedObject ? [selectedObject] : []
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  clearForm = () => {
    this.setState({
      currentAccessorial: { ...defaultAccessorial },
      isNew: true,
    })
  }

  handleSearch = (value) => {
    const filteredCodes = value.length >= 2
    ? this.state.codes.filter(item => item.code.toUpperCase().includes(value.toUpperCase()) || item.charge_code_description.toUpperCase().includes(value.toUpperCase()))
      : [];
    this.setState({
      filterCodes: filteredCodes,
    });

  };

  handleCodeChanges = (e) => {
    if (!e) {
      this.setState({
        inputValue: '',
        codeDescription: '',
        filterCodes: []
      })
      return;
    }
    const selectedObject = this.state.codes.find((item) => item.code === e);
    // Do something with the selected object (selectedObject.value)
    this.setState({
      inputValue: selectedObject.code,
      codeDescription: selectedObject.charge_code_description,
       filterCodes: [selectedObject]

    })
  };

  handleOnChange = (element, value) => {
    const accessorial = Object.assign({}, this.state.currentAccessorial);
    if(element === 'hybrid_source' &&  value !== 'none'){ 
      accessorial.resources = 'none';
      accessorial.rate_source = 'none';
    }
    if(element === 'eligible_for_driver_pay'){
      if(accessorial.accessorial_config_params){
        accessorial.accessorial_config_params.consider_for_attempt_driver = 'false';
        if(value){
          accessorial.accessorial_config_params.eligible_for = 'driver';
        } else {
          delete accessorial.accessorial_config_params.eligible_for ;
        }
      } else {
        accessorial.accessorial_config_params = value ? { eligible_for: 'driver', consider_for_attempt_driver : 'false'} : null;
      }
    } else if(['eligible_for', 'add_fuel', 'consolidation_type', 'consider_for_attempt_driver','warehouse_operation' ].includes(element)) {
      accessorial.accessorial_config_params = {...accessorial.accessorial_config_params, [element] : value }
    } else {
      accessorial[element] = value;
    }
    this.setState({
      currentAccessorial: accessorial,
    })
  }

  handleSave = () => {
    const data = Object.assign({}, this.state.currentAccessorial)
    // const components = [...data.components];

    const components = this.state.components
      .filter(
        (component) =>
          component.code === data.resources ||
          component.code === data.rate_source ||
          component.code === data.hybrid_source,
      )
      .map((component) => ({
        component_id: component.id,
        component_name: component.name,
        component_code: component.code,
      }))
    data.components = [...components]
    const errors = doValidate(requiredFields, data)
    // if (isEmpty(data.components) || data.components.length === 0) {
    //   errors.push(
    //     I18n.t("errors.field_required", {
    //       field: I18n.t("accessorials.components"),
    //     })
    //   );
    // }
    // if (isEmpty(data.accessible_to) || data.accessible_to.length === 0) {
    //   errors.push(
    //     I18n.t("errors.field_required", {
    //       field: I18n.t("accessorials.accessible_to"),
    //     })
    //   );
    // }
    if (errors.length === 0) {
      data.code = formatCode(data.code)
      data.gl_code = formatCode(data.gl_code)
      data.account_id = this.state.account.id
      data.organization_id = this.state.account.organization_id
      data.accessorial_config_params = data.accessorial_config_params ? data.accessorial_config_params : { add_fuel: 'false'};
      data.x12_code = this.state.inputValue
      data.charge_code_description = this.state.codeDescription || ""
      this.setState({ inProgress: true })

      saveCustomAccessorial(this.state.isNew, data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'))
          if (this.state.isNew) {
            this.setState({
              errors: [],
              isNew: true,
              inProgress: false,
              inputValue: ""
            })
            this.clearForm()
          } else {
            this.setState({
              errors: [],
              inProgress: false,
              inputValue: ""
            })
          }
          if (
            result.accessorials &&
            result.accessorials.account_accessorial_id
          ) {
            this.props.callConfig(result.accessorials.account_accessorial_id)
          }
        } else {
          this.setState({ errors: result.errors, inProgress: false })
        }
      })
    } else {
      this.setState({ errors, inProgress: false })
    }
  }

  render() {
    const { currentAccessorial } = this.state
    const accessibleTo = this.state.currentAccessorial.accessible_to
    const accessorial_config_params = currentAccessorial.accessorial_config_params; 
    return (
      <div>
        <Row gutter={16}>
          <Row>
            <Col sm={10} xs={10} md={10} lg={10}>
              <FormItem label={I18n.t('general.name')} require>
                <Input
                  value={currentAccessorial.name}
                  onChange={(e) => this.handleOnChange('name', e.target.value)}
                  // disabled={accessibleTo && accessibleTo.includes("AUTO")}
                />
              </FormItem>
            </Col>
            <Col sm={2} xs={2} md={2} lg={2}></Col>
            <Col sm={10} xs={10} md={10} lg={10}>
              <FormItem
                label={
                  <Fragment>
                    <span>{I18n.t('general.code')}</span>&nbsp;
                  </Fragment>
                }
                require
              >
                <Input
                  value={currentAccessorial.code}
                  onChange={(e) => this.handleOnChange('code', e.target.value)}
                  className="textUpperCase"
                  disabled={
                    !isEmpty(currentAccessorial.standard_code) &&
                    currentAccessorial.is_clone === 'false' || !this.state.isNew
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col sm={10} xs={10} md={7} lg={7}>
              <FormItem label={I18n.t('accessorials.gl_code')}  require>
                {/* <Input
                  value={currentAccessorial.gl_code}
                  onChange={(e) =>
                    this.handleOnChange('gl_code', e.target.value)
                  }
                  className="textUpperCase"
                  // disabled={accessibleTo && accessibleTo.includes("AUTO")}
                /> */}
                <GLCodeSelect
                  data={this.props.glCodesList || []}
                  
                  value={currentAccessorial.gl_code}
                  onChange={(e) => {
                    this.handleOnChange('gl_code', e)
                  }}
                  selectKey={"gl_code"}
                  selectValue={"gl_code"}
                  selectedValue={"gl_code"}
                  style={{ width: "100%" }}
                  showSearch={true}
                  dropdownMatchSelectWidth={false}
                  selectedCode={"description"}
                />
              </FormItem>
            </Col>
           
            <Col sm={10} xs={10} md={7} lg={7}>
              <FormItem label={"X12 EDI Code"}>
                <Select
                  value={this.state.inputValue ? this.state.inputValue : undefined}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Search by code"
                  allowClear
                  loading={this.state.isLoading}
                  optionFilterProp="children"
                  onSearch={this.handleSearch}
                  onChange={this.handleCodeChanges}
                  dropdownMatchSelectWidth={false}

                >
                  {!_.isEmpty(this.state.filterCodes) && this.state.filterCodes.map((item) => (
                    <Select.Option key={item.code} value={item.code}>
                      {`${item.code} ${item.charge_code_description ? `- ${item.charge_code_description}` : ""}`}
                    </Select.Option>
                  ))
                  }
                </Select>
              </FormItem>
            </Col>
           
            <Col xs={10} md={10}>
              <FormItem label={I18n.t('accessorials.visible_to')}>
                <Checkbox.Group
                  value={this.state.currentAccessorial.accessible_to}
                  onChange={(e) => this.handleOnChange('accessible_to', e)}
                  style={{ width: '100%' }}
                >
                  <Row className='marginTop10'>
                    {accessbleTo.map((option, qindex) => (
                      <Col md={12} key={option}>
                        <Checkbox
                          value={option}
                          disabled={
                            accessibleTo && accessibleTo.includes('AUTO')
                          }
                        />{' '}
                        {option}&nbsp;&nbsp;
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </FormItem>
            </Col>
          </Row>
          <Row className="marginTop10 marginBottom10">
            <Col xs={7} className="textBold">
              <Checkbox
                checked={accessorial_config_params?.eligible_for}
                onChange={(e) =>
                  this.handleOnChange(
                    'eligible_for_driver_pay',
                    e.target.checked,
                  )
                }
              >
                {I18n.t('accessorials.eligible_for_driver_pay')}
              </Checkbox>
            </Col>
            {accessorial_config_params?.eligible_for && (
              <Col xs={15}>
                <Radio.Group
                  value={
                    accessorial_config_params.eligible_for
                      ? accessorial_config_params.eligible_for
                      : 'driver'
                  }
                  onChange={(e) =>
                    this.handleOnChange('eligible_for', e.target.value)
                  }
                  // style={{ width: "100%" }}
                >
                  <Radio value="driver" /> {I18n.t('general.Driver')}
                  &nbsp;&nbsp;
                  <Radio value="wh_handling" /> {I18n.t('accessorials.warehouse_handling')}
                  &nbsp;&nbsp;
                </Radio.Group>
              </Col>
            )}
          </Row>
          <Row className="marginTop10 marginBottom10">
            <Col xs={7} className="textBold">
              <Checkbox
                checked={accessorial_config_params?.add_fuel && accessorial_config_params.add_fuel === 'true'? true : false}
                onChange={(e) =>
                  this.handleOnChange(
                    'add_fuel',
                    e.target.checked ? 'true' : 'false',
                  )
                }
              >
                {I18n.t('accessorials.add_fuel')}
              </Checkbox>
            </Col>
            {accessorial_config_params?.eligible_for && (
              <Col xs={9} className="textBold">
              <Checkbox
                checked={accessorial_config_params?.consider_for_attempt_driver && accessorial_config_params.consider_for_attempt_driver === 'true'? true : false}
                onChange={(e) =>
                  this.handleOnChange(
                    'consider_for_attempt_driver',
                    e.target.checked ? 'true' : 'false',
                  )
                }
              >
                {I18n.t('accessorials.consider_for_attempt_driver')}
              </Checkbox>
              </Col>)
            }
            
          </Row>
          <Row>
            {accessorial_config_params && (
              <Col xs={24} sm={24} md={24} lg={7} className="textBold">
              <Checkbox
                checked={accessorial_config_params?.warehouse_operation && accessorial_config_params.warehouse_operation === 'true'? true : false}
                onChange={(e) =>
                  this.handleOnChange(
                    'warehouse_operation',
                    e.target.checked ? 'true' : 'false',
                  )
                }
              >
                Warehouse Operation
              </Checkbox>
              </Col>
            )}
             <Col  xs={17} >
              {/* <Checkbox
                checked={accessorial_config_params?.is_consolidated && accessorial_config_params.is_consolidated === 'true'? true : false}
                onChange={(e) =>
                  this.handleOnChange(
                    'is_consolidated',
                    e.target.checked ? 'true' : 'false',
                  )
                }
              >
                {I18n.t('accessorials.is_consolidated')}
              </Checkbox> */}
              <Row type={"flex"} gutter={8}>
                <Col className="textBold"> Consolidation Type:
                </Col>
                <Col>
                <Radio.Group 
                  value={accessorial_config_params?.consolidation_type ? accessorial_config_params?.consolidation_type : 'none' }
                  onChange={(e) =>
                  this.handleOnChange(
                    'consolidation_type',
                    e.target.value
                  )
                }>
                <Radio value={'none'}>None</Radio>
                <Radio value={'consolidate'}>Consolidate</Radio>
                <Radio value={'once_in_consolidate'}>Once In Consolidate</Radio>
              </Radio.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          &nbsp;
         
        
          <Row>
            <FormItem label={I18n.t('accessorials.rate_source')} require>
              <Radio.Group
                value={
                  this.state.currentAccessorial.rate_source
                    ? this.state.currentAccessorial.rate_source
                    : 'none'
                }
                onChange={(e) =>
                  this.handleOnChange('rate_source', e.target.value)
                }
                style={{ width: '100%' }}
                disabled={currentAccessorial && !isEmpty(currentAccessorial.hybrid_source) && currentAccessorial.hybrid_source !== 'none'}
              >
                {this.state.components.map((option, qindex) => {
                  return option.component_section === 'rate_source' ? (
                    <Col md={8} key={option.code}>
                      <Radio value={option.code} /> {option.name}&nbsp;&nbsp;
                    </Col>
                  ) : null
                })}
                <Col md={8} key={'rate_source_none'}>
                  <Radio value={'none'} /> None&nbsp;&nbsp;
                </Col>
              </Radio.Group>
            </FormItem>
          </Row>
          <Row>
            <FormItem label={I18n.t('accessorials.resources')}>
              <Radio.Group
                value={
                  this.state.currentAccessorial.resources
                    ? this.state.currentAccessorial.resources
                    : 'none'
                }
                onChange={(e) =>
                  this.handleOnChange('resources', e.target.value)
                }
                style={{ width: '100%' }}
                disabled={currentAccessorial && !isEmpty(currentAccessorial.hybrid_source) && currentAccessorial.hybrid_source !== 'none'}

              >
                {this.state.components.map((option, qindex) => {
                  return option.component_section === 'resources' ? (
                    <Col md={8} key={option.code}>
                      <Radio value={option.code} /> {option.name}&nbsp;&nbsp;
                    </Col>
                  ) : null
                })}
                <Col md={8} key={'resources_none'}>
                  <Radio value={'none'} /> None&nbsp;&nbsp;
                </Col>
              </Radio.Group>
            </FormItem>
          </Row>
          <Row>
            <FormItem label={I18n.t('accessorials.hybrid_source')}>
              <Radio.Group
                value={
                  this.state.currentAccessorial.hybrid_source
                    ? this.state.currentAccessorial.hybrid_source
                    : 'none'
                }
                onChange={(e) =>
                  this.handleOnChange('hybrid_source', e.target.value)
                }
                style={{ width: '100%' }}
              >
                {this.state.components.map((option, qindex) => {
                  return option.component_section === 'hybrid_source' ? (
                    <Col md={8} key={option.code}>
                      <Radio value={option.code} /> {option.name}&nbsp;&nbsp;
                    </Col>
                  ) : null
                })}
                <Col md={8} key={'hybrid_source_none'}>
                  <Radio value={'none'} /> None&nbsp;&nbsp;
                </Col>
              </Radio.Group>
            </FormItem>
          </Row>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel,
              true,
              true,
              'default',
              I18n.t('general.next'),
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

AccessorialForm.propTypes = {
  account: PropTypes.shape.isRequired,
  currentAccessorial: PropTypes.shape.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default AccessorialForm