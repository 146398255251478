/* eslint-disable import/prefer-default-export */
import React, { Fragment }  from "react";
import _ from "lodash";
import moment from "moment";
import { rearrangeStops, stopTypeFromObject } from "./stops";
import { isEmpty } from "../common/Common";
import { findCommonElementsExisting } from "./array_functions";
import recoveryIcon from '../../assets/images/recovery_red.svg'
import releaseIcon from '../../assets/images/release_green.svg'
import LHIcon from '../../assets/images/surface.png'
import FormIcon from "../components/common/FormIcon";
import { Icon, Tooltip } from "../common/UIComponents";

export const checkRouteIsEmpty = (route, type) => {
  if (type === "order") {
    if (route.customer_order_ids && route.customer_order_ids.length === 0) {
      return true;
    }
  } else if (type === "stop") {
    const stops = route.stops && _.isArray(route.stops) ? route.stops : [];
    const csDepos = stops.filter(
      (stop) => stop.location && stop.location.l_type === "CS"
    );
    if (csDepos.length === 0) {
      return true;
    }
  }
  return false;
};

// find only dependency orders
export const getDependencyOrders = (
  data,
  compareKey,
  sourceKey,
  sourceId,
  responceType = "id"
) => {
  if (
    !isEmpty(compareKey) &&
    !isEmpty(sourceId) &&
    !isEmpty(data) &&
    _.isArray(data)
  ) {
    const filteredRecords = data.filter(
      (record) => record[compareKey] === sourceId
    );
    const records = filteredRecords.map((record) =>
      responceType === "id" ? record[sourceKey] : record
    );
    return records;
  }
  return [];
};

// find only dependency stops
export const getDependencyStops = (route, stopId, responceType = "id", stopKey = 'stops') => {
  if (!isEmpty(route) && !_.isArray(route)) {
    const currentStop = _.find(route[stopKey], { id: stopId });
    if (!isEmpty(currentStop)) {
      const currentDepencyOrders = currentStop.orders
        .filter((order) => !isEmpty(order.related_order))
        .map((order) => order.related_order);
      const dependencystops = route[stopKey].filter(
        (stop) =>
          stop.location &&
          stop.location.l_type === "CS" &&
          stop.id !== stopId &&
          ["PENDING", "UNALLOCATED"].includes(stop.status) &&
          findCommonElementsExisting(
            currentDepencyOrders,
            stop.customer_order_ids
          )
      );
      if (responceType === "id") {
        const stopIds = dependencystops.map((stop) => stop.id);
        return stopIds;
      }
      return dependencystops;
    }
  }
  return [];
};

// find  dependency stops including source id
export const findDependencyStops = (route, stopId, responceType = "id", stopKey = 'stops') => {
  const dependencyStops = getDependencyStops(route, stopId, responceType, stopKey);
  let removalStops = [];
  if (responceType === "id") {
    removalStops =
      dependencyStops.length === 0
        ? [stopId]
        : [].concat([stopId], dependencyStops);
  } else {
    const currentStop = _.find(route[stopKey], { id: stopId });
    removalStops =
      dependencyStops.length === 0
        ? [{ ...currentStop }]
        : [].concat([{ ...currentStop }], dependencyStops);
  }
  return removalStops;
};

export const handleDependencyOrder = (source, orders, typeOfOrder, key) => {
  if (typeOfOrder === "D") {
    return [].concat(orders, [source]);
  }
  return [].concat([source], orders);
};

// find  dependency orders including source id
export const findDependencyOrders = (
  data,
  compareKey,
  sourceKey,
  sourceId,
  responceType = "id",
  orderType = "type_of_order"
) => {
  const dependencyItems = getDependencyOrders(
    data,
    compareKey,
    sourceKey,
    sourceId,
    responceType
  );
  let dependencies = [];
  const currentOrder = _.find(data, { [sourceKey]: sourceId });
  if (responceType === "id") {
    dependencies = handleDependencyOrder(
      sourceId,
      dependencyItems,
      currentOrder[orderType]
    );
    // removalIds = dependencyItems.length === 0 ? [sourceId] : [].concat([sourceId], dependencyItems);
  } else {
    dependencies = handleDependencyOrder(
      currentOrder,
      dependencyItems,
      currentOrder[orderType]
    );
    // removalIds = dependencyItems.length === 0 ? [{ ...currentOrder }] : [].concat([{ ...currentOrder }], dependencyItems);
  }

  return dependencies;
};

// checks order has particular type
export const hasOrderType = (orders, key, orderType = "") => {
  if (!isEmpty(orders) && _.isArray(orders) && orders.length > 0) {
    const hasOrderTypeOrders = orders.filter(
      (order) => order[key] === orderType
    );
    return hasOrderTypeOrders.length > 0;
  }
  return false;
};

// checks stops are following particular type
export const checkDependancyOrderSeq = (
  route,
  sourceIndex,
  destinationIndex
) => {
  const stopsWithoutOrders = route.stops ? [...route.stops] : [];
  const routeOrders = route.customer_orders || [];
  const stops = stopsWithoutOrders.map((stop) => {
    const stopOrders = routeOrders.filter((order) =>
      stop.customer_order_ids.includes(order.customer_order_id)
    );
    return Object.assign({}, stop, { orders: stopOrders });
  });
  route.stops = [...stops];
  const customerStops = stops.filter(
    (stop) => stop.location && stop.location.l_type === "CS"
  );
  if (!isEmpty(stops) && _.isArray(stops) && customerStops[sourceIndex]) {
    const currentStop = customerStops[sourceIndex];
    const stopOrders = currentStop.orders || [];
    let hasCorrectOrder = true;
    if (stopOrders.length > 0) {
      stopOrders.forEach((currentOrder) => {
        if (currentOrder.type_of_order !== "X") {
          const dependencyStops = getDependencyStops(
            route,
            currentStop.id,
            "object"
          );
          if (dependencyStops.length > 0) {
            const dependencyTypeStops = dependencyStops.filter(
              (stop) =>
                stop.orders &&
                hasOrderType(
                  stop.orders,
                  "type_of_order",
                  currentOrder.type_of_order === "R" ? "D" : "R"
                )
            );
            if (dependencyTypeStops.length > 0) {
              dependencyTypeStops.forEach((stop) => {
                const stopIndex = _.findIndex(customerStops, ["id", stop.id]);
                if (stopIndex >= 0) {
                  if (
                    currentOrder.type_of_order === "D" &&
                    destinationIndex <= stopIndex
                  ) {
                    hasCorrectOrder = false;
                    // return false;
                  } else if (
                    currentOrder.type_of_order === "R" &&
                    destinationIndex >= stopIndex
                  ) {
                    hasCorrectOrder = false;
                    // return false;
                  }
                }
              });
            }
          }
        }
      });
    }
    return hasCorrectOrder;
  }
  return true;
};

export const checkDependancyOrderSeqInDispatch = (
  route,
  sourceIndex,
  destinationIndex
) => {
  const stops = route.stops ? [...route.stops] : [];
  const customerStops = stops.filter(
    (stop) => stop.location && stop.location.l_type === "CS"
  );
  if (!isEmpty(stops) && _.isArray(stops) && customerStops[sourceIndex]) {
    const currentStop = customerStops[sourceIndex];
    const stopOrders = currentStop.orders || [];
    let hasCorrectOrder = true;
    if (stopOrders.length > 0) {
      stopOrders.forEach((currentOrder) => {
        if (currentOrder.type_of_order !== "X") {
          const dependencyStops = getDependencyStops(
            route,
            currentStop.id,
            "object"
          );
          if (dependencyStops.length > 0) {
            const dependencyTypeStops = dependencyStops.filter(
              (stop) =>
                stop.orders &&
                hasOrderType(
                  stop.orders,
                  "type_of_order",
                  currentOrder.type_of_order === "R" ? "D" : "R"
                )
            );
            if (dependencyTypeStops.length > 0) {
              dependencyTypeStops.forEach((stop) => {
                const stopIndex = _.findIndex(customerStops, ["id", stop.id]);
                if (stopIndex >= 0) {
                  if (
                    currentOrder.type_of_order === "D" &&
                    destinationIndex <= stopIndex
                  ) {
                    hasCorrectOrder = false;
                    // return false;
                  } else if (
                    currentOrder.type_of_order === "R" &&
                    destinationIndex >= stopIndex
                  ) {
                    hasCorrectOrder = false;
                    // return false;
                  }
                }
              });
            }
          }
        }
      });
    }
    return hasCorrectOrder;
  }
  return true;
};

export const rearrangeBySeq = (orders, sourceKey = "id") => {
  let seqOrders = [];
  orders.forEach((order) => {
    if (_.findIndex(seqOrders, [sourceKey, order[sourceKey]]) < 0) {
      const dependencyOrders = getDependencyOrders(
        orders,
        "related_order",
        sourceKey,
        order[sourceKey],
        "object"
      );
      const sequencedOrders = handleDependencyOrder(
        order,
        dependencyOrders,
        order.type_of_order
      );
      seqOrders = [].concat(seqOrders, sequencedOrders);
    }
  });
  return seqOrders;
};

export const retrieveCustomerStops = (route, rearrangeForm = true) => {
  const ignoredStopTypes = ["WH", "DV"];
  let tempStops = [...(route.stops || [])];
  if (tempStops.length > 2 && rearrangeForm) {
    tempStops = rearrangeStops(tempStops, false);
    const stopsData = tempStops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location.l_type) &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    );
    tempStops = [...stopsData];
  }
  return tempStops;
};

export const setRouteOrders = (stops) => {
  const stopsOrders = [];
  stops.forEach((stop, index) => {
    stop.orders.forEach((orderObject) => {
      if (!_.isNil(orderObject)) {
        const stopType = stopTypeFromObject(stop);
        if (stopType === "CS") {
          const contactDetails = stop.contact_details
            ? stop.contact_details
            : {};
          const scheduled_start_date_time_with_tz = !isEmpty(
            stop.scheduled_start_date_time_with_tz
          )
            ? moment
                .utc(stop.scheduled_start_date_time_with_tz)
                .format("hh:mma")
            : "";
          const scheduled_end_date_time_with_tz = !isEmpty(
            stop.scheduled_end_date_time_with_tz
          )
            ? moment.utc(stop.scheduled_end_date_time_with_tz).format("hh:mma")
            : "";
          const estimated_arrival_date_time_with_tz = !isEmpty(
            stop.estimated_arrival_date_time_with_tz
          )
            ? moment
                .utc(stop.estimated_arrival_date_time_with_tz)
                .format("hh:mma")
            : "";
          const stopName = contactDetails.contact_name
            ? contactDetails.contact_name
            : "";
          const city =
            stop.location &&
            stop.location.l_address &&
            stop.location.l_address.city
              ? stop.location.l_address.city
              : "";
          const zipcode =
            stop.location &&
            stop.location.l_address &&
            stop.location.l_address.zipcode
              ? stop.location.l_address.zipcode
              : "";
          stopsOrders.push(
            Object.assign({}, orderObject, {
              consignee: stopName,
              stop_no: index + 1,
              stop_id: stop.id,
              stop_name: stop.formatted_stop_name,
              stop_sch_start_time: scheduled_start_date_time_with_tz,
              stop_sch_end_time: scheduled_end_date_time_with_tz,
              stop_arrival_time: estimated_arrival_date_time_with_tz,
              city,
              zipcode,
              status: stop.status,
              stop_errors: stop.stop_errors,
              id: orderObject.customer_order_id,
            })
          );
        }
      }
    });
  });
  return stopsOrders;
};

export const renderStopIcon = (type) => {
  switch(type){
    case 'CS': return <Tooltip title=""><Icon type="cart" /></Tooltip>
    case 'RC': return <Tooltip title="Recovery"><Icon
    component={() => (
      <img
        style={{ height: 13, marginTop: -5 }}
        src={
          recoveryIcon
        }
      />
    )}
  /></Tooltip>
    case 'RL': return <Tooltip title="Release"><Icon
    component={() => (
      <img
        style={{ height: 13, marginTop: -5 }}
        src={
          releaseIcon
        }
      />
    )}
  /></Tooltip>
    case 'LH': return <Tooltip title="Linehaul"><Icon
    component={() => (
      <img
        style={{ height: 13, marginTop: -5 }}
        src={
          LHIcon
        }
      />
    )}
  /></Tooltip>
    default: return <Fragment />
  }
}