import React, { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";

export const InsightsDriverPerformance = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      code: "123456",
      name: "John Smith",
      date: "25 April 11:00 AM",
      routes_completed: 5,
      orders_attempted: 10,
      orders_per_route: 2,
      on_time: 4,
      delayed: 1,
      no_data: 5,
      total_distance: 100,
      avg_route_distance: 20,
      total_volume: 200,
      avg_truck_load: 40,
      day: 11,
      week: 17,
      year: 2023,
    },
    {
      key: 2,
      code: "789012",
      name: "Jane Doe",
      date: "01 may 12:00 PM",
      routes_completed: 3,
      orders_attempted: 6,
      orders_per_route: 2,
      on_time: 2,
      delayed: 1,
      no_data: 3,
      total_distance: 60,
      avg_route_distance: 20,
      total_volume: 120,
      avg_truck_load: 40,
      day: 12,
      week: 17,
      year: 2023,
    },
  ]);
  const [columns, setColumns] = useState([
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "routes_completed",
      title: "Routes Completed",
      dataIndex: "routes_completed",
    },
    {
      key: "orders_attempted",
      title: "Orders Attempted",
      dataIndex: "orders_attempted",
    },
    {
      key: "orders_per_route",
      title: "Orders per Route",
      dataIndex: "orders_per_route",
    },
    {
      key: "on_time",
      title: "On-Time",
      dataIndex: "on_time",
    },
    {
      key: "delayed",
      title: "Delayed",
      dataIndex: "delayed",
    },
    {
      key: "no_data",
      title: "No Data",
      dataIndex: "no_data",
    },
    {
      key: "total_distance",
      title: "Total Distance (mi)",
      dataIndex: "total_distance",
    },
    {
      key: "avg_route_distance",
      title: "Avg. Route Distance",
      dataIndex: "avg_route_distance",
    },
    {
      key: "total_volume",
      title: "Total Vol. (lbs)",
      dataIndex: "total_volume",
    },
    {
      key: "avg_truck_load",
      title: "Avg. Truck Load (lbs)",
      dataIndex: "avg_truck_load",
    },
    {
      key: "day",
      title: "Day",
      dataIndex: "day",
    },
    {
      key: "week",
      title: "Week",
      dataIndex: "week",
    },
    {
      key: "year",
      title: "Year",
      dataIndex: "year",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "InsightsDriverPerformance_list_view_columns";


  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );

};
