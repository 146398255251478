import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
} from "../../common/UIComponents";

import { withRouter } from "react-router";

import userStore from "../../stores/UserStore";
import { checkNegitive, findNumberTotal } from "../../common/Common";

const PaymentSelectedInfo = ({
  history,
  match,
  location,
  selectedInfo = {},
  data = [],
  key = "paid_amount",
  id="id"
}) => {
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const orgId = userStore.getStateValue("selectedOrg");
  const selectedRecords = data.filter((rec) =>
    selectedInfo.keys.includes(rec[id])
  );
  const totalPaidAmount = parseFloat(
    findNumberTotal(selectedRecords, "paid_amount", null, decimalPoints)
  );

  return (
    <div>
      <h4 className="textBold">
        Amount To Pay:{" "}
        <span style={{ color: "#00308F" }}>
          {checkNegitive(totalPaidAmount)}
        </span>
        <span>(Selected : {_.uniq(selectedInfo.keys).length})</span>
      </h4>
    </div>
  );
};

PaymentSelectedInfo.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(PaymentSelectedInfo);
