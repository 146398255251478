/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React, { Component,useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import TimePicker from "../../common/TimePicker";
import {
  Col,
  FormItem,
  Row,
  DatePicker,
  Button,
  Checkbox,
} from "../../common/UIComponents";
import {  alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { updateRouteTime } from "../../api/Routes";
import FormErrors from "../common/FormErrors";
import BaseModal from "../BaseModal";
import MaterialTimePicker from "../orders/MaterialTimePicker";
import RouteLabel from "../../config/RouteLabel";
import { OrgContext } from "../../context/OrgContext";
import { renderAlertMessage } from "../../helpers/common";

class TimeEditWindow extends Component {
  // static contextType = OrganizationSettingsContext;
  constructor(props) {
    super(props);
    this.state = {
      currentForm: {
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        stick_start_time: false,
      },
      route: this.props.route || {},
      inProgress: false,
      isModified: false,
      organizationSettings: {},
      isMilitaryTime : false,
    };
  }

  componentDidMount() {
    this.setDateTime();
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }


  setDateTime = () => {
    const route = !isEmpty(this.state.route) ? this.state.route : {};
    this.setState({
      currentForm: {
        startDate: route.route_start_datetime
          ? moment(route.route_start_datetime).format()
          : null,
        endDate: route.route_end_datetime
          ? moment(route.route_end_datetime).format()
          : null,
        startTime: route.route_start_datetime
          ? moment(route.route_start_datetime).format()
          : null,
        endTime: route.route_end_datetime
          ? moment(route.route_end_datetime).format()
          : null,
        stick_start_time: route.stick_start_time === true,
      },
    });
  };

  renderRouteEditModal = () => (
    <BaseModal
      title={`${I18n.t("general.manage")} ${I18n.t("general.route")}`}
      width="400px"
      onCancel={() => this.closeWindow()}
    >
      <Row>
        <Col xs={24}>
          <span className="textBold">{I18n.t("general.Driver")} </span> :{" "}
          {this.state.route.displayName}
        </Col>
        <Col xs={24}>
          <span className="textBold">{`${I18n.t("general.route")} ${I18n.t(
            "info.start_date"
          )}`}</span>{" "}
          :{" "}
          {moment(this.state.route.route_start_datetime).format("MMM DD, YYYY")}
        </Col>
      </Row>
      <Row className="marginTop10">
        {/* <Col xs={24} sm={24} lg={12} md={12} className="padding5">
          <FormItem label='Date'>
            <DatePicker
              style={{ width: '100%', marginLeft: '1%' }}
              placeholder="Select Date"

              format='Do MMM YYYY'
              onChange={e => this.handleOnChange('startDate', e)}
              value={this.state.currentForm.startDate ?
                moment(this.state.currentForm.startDate) :
                null}
              defaultValue={null}
              onOk={this.onOk}
              disabled

            />
          </FormItem>
        </Col>
              */}
        <Col xs={24} sm={24} lg={24} md={24}>
          <FormItem
            label={`${I18n.t("general.route")} ${I18n.t("info.start_time")}`}
            className="timepickerContainer">
            <MaterialTimePicker
              style={ { width: "100%" } }
              value={
                this.state.currentForm.startTime
                  ? moment(this.state.currentForm.startTime)
                  : null
              }
              onChange={ (e) => this.handleOnChange("startTime", e) }
              computeDisabledHours={ () => []
              }
              computeDisabledMinutes={ () => [] }
              isMilitaryTime={ this.state.isMilitaryTime }
            />
          </FormItem>
        </Col>
      </Row>

      {/* <Row>
          <Col xs={24} sm={24} lg={12} md={12} className="padding5" >
            <FormItem label='Date'>
              <DatePicker
                style={{ width: '100%', marginLeft: '1%' }}
                placeholder="Select Date"

                format='Do MMM YYYY'
                onChange={e => this.handleOnChange('endDate', e)}
                value={this.state.currentForm.endDate ? moment(this.state.currentForm.endDate) : null}
                defaultValue={null}
                onOk={this.onOk}
                disabled
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} lg={12} md={12} className="padding5">
            <FormItem label='Time'>
              <TimePicker
                use12Hours
                minuteStep={30}
                format="h:mm A"
                placeholder="Select Time"
                value={this.state.currentForm.endTime ? moment(this.state.currentForm.endTime) : null}
                onChange={e => this.handleOnChange('endTime', e)}
                defaultValue={null}
                style={{ width: '100%', marginLeft: '1%' }}
                showSecond={false}
                // disabledHours={() => getDisabledHours('end_time', disabledEndHours, record)}
                // disabledMinutes={selectedHour => getDisabledMinutes('end_time', selectedHour, record, endHour)}
              />
            </FormItem>
          </Col>

        </Row>
        */}

      <Row>
        <Col xs={24}>
          <Checkbox
            checked={this.state.currentForm.stick_start_time || false}
            onChange={(e) =>
              this.handleOnChange("stick_start_time", e.target.checked)
            }
          >
            {I18n.t("routes.stick_start_time")}
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} lg={24} md={24} className="padding5 alignCenter">
          <Button type="danger" className="margin5" onClick={this.closeWindow}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="margin5"
            onClick={this.handleSave}
            loading={this.state.inProgress}
          >
            Save
          </Button>
        </Col>
      </Row>
      {FormErrors(this.state.errors)}
    </BaseModal>
  );

  clearForm = () => {
    this.setState({
      currentForm: {},
    });
  };

  handleOnChange = (element, value) => {
    if (["startDate", "startTime", "endDate", "endTime"].includes(element)) {
      this.setState({
        currentForm: Object.assign({}, this.state.currentForm, {
          [element]: !isEmpty(value) ? value : null,
        }),
      });
    } else {
      this.setState({
        currentForm: Object.assign({}, this.state.currentForm, {
          [element]: value,
        }),
      });
    }
  };

  closeWindow = (startDateTime, stick_start_time) => {
    this.props.closeWindow(
      this.state.isModified,
      this.state.route.route_id,
      startDateTime,
      stick_start_time
    );
  };

  handleSave = () => {
    const { startDate, endDate, startTime, endTime, stick_start_time } = this.state.currentForm;
    if (!isEmpty(startDate) || !isEmpty(startTime)) {
      const startDatetime = `${moment(startDate).format("YYYY-MM-DD")} ${moment(
        startTime
      ).format("HH:mm")}`;
      const endDateTime = `${moment(endDate).format("YYYY-MM-DD")} ${moment(
        endTime
      ).format("HH:ss")}`;
      const data = {
        nav_route_id: this.state.route.route_id,
        start_date_time: startDatetime,
        end_date_time: endDateTime,
        stick_start_time:  stick_start_time || false,
        action_type: RouteLabel.actionLabels.ROUTE_TIME_MANAGE
      };
      this.setState({ inProgress: false, errors: [] });
      updateRouteTime(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          const navRoute = result.nav_route ? result.nav_route : {};
          // const route = { ...this.state.route };
          // route.route_end_datetime = navRoute.r_scheduled_end_datetime ? moment(navRoute.r_scheduled_end_datetime) : null;
          // route.route_start_datetime = navRoute.r_scheduled_start_datetime ? moment(navRoute.r_scheduled_start_datetime) : null;
          this.setState(
            {
              inProgress: false,
              isModified: true,
            },
            () => {
              // this.setDateTime();
              this.closeWindow(startDatetime, stick_start_time);
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, errors: result.errors });
        }
      });
    } else {
      this.setState({ errors: [I18n.t("messages.all_fields_are_required")] });
    }
  };

  render() {
    return <div>{this.renderRouteEditModal()}</div>;
  }
}

TimeEditWindow.propTypes = {
  route: PropTypes.shape().isRequired,
  closeWindow: PropTypes.func.isRequired,
};

const TimeEditWindowComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <TimeEditWindow
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default TimeEditWindowComponent
