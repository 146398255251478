/* global google */
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import { Draggable, Droppable } from "react-beautiful-dnd";
import React, { Fragment } from "react";
import { compose, withHandlers, withState } from "recompose";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Polyline,
	OverlayView,
} from "react-google-maps";
import MapCustomControl from "../common/MapCustomControl";
import {
	Spin,
	Row,
	Col,
	Icon,
	Tooltip,
	Popover,
	Avatar,
	Button,
} from "../../common/UIComponents";
import { isEmpty} from "../../common/Common";
import styles from "../common/mapStyle.json";
import manlogo from "../../../assets/images/driver.png";
import { setOrderSequence } from "../../helpers/preplan";
import OrderMarker from "./OrderMarker";
import StopRoutes from "./StopRoutes";
import { formatByTimeConfig } from "../../helpers/common";

let getImg;
const optionIcon = {};
let atttributeInfo;
const lineSymbol = {
	path: "M 0,-1 0,1",
	strokeOpacity: 1,
	strokeColor: "#479ada",
	strokeWeight: 2,
	scale: 4,
};
const getPixelPositionOffset = (width, height) => ({
	x: -(width / 2),
	y: -(height / 2),
});

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	zIndex: isDragging ? "9999999 !important" : "inherit",
	color: isDragging ? "red" : "green",
	...draggableStyle,
});

// Function to handle minimize/maximize button click
const handlePanelClick = (props) => {
	props.onPanelClick("pane_2");
};

// Function to toggle route visibility
const toggleRouteVisibility = (props) => {
	props.setRouteVisibility(!props.hideRoutes);
};

// Function to handle show all/show selected button click
const handleShowOnMapClick = (props) => {
	props.setShowOnMap();
};

const MapActions = (props) => {
  const { panelSize, routesCopy, hideRoutes, routes, showOnMap } = props;
  return (
		<Row gutter={ [ 8 , 8 ] }>
			{/* <Col>
				<Button size="small" onClick={() => handlePanelClick(props) }>
					<Icon type={ panelSize.pane_2 === 1 ? "minus-square" : "fullscreen" } />
					{ panelSize.pane_2 === 1 ? " Minimize" : " Maximize" }
				</Button>
			</Col> */}
			<Col>
				{ routesCopy?.length > 0 && (
					<Button
						size="small"
						type="primary"
						icon={ hideRoutes ? "eye" : "eye-invisible" }
						onClick={ () => toggleRouteVisibility(props) }
					>
						{ hideRoutes ? "Show Routes" : "Hide Routes" }
					</Button>
				) }
			</Col>
			<Col>
				{ routes?.length > 0 && (
					<Button
						size="small"
						type="default"
						onClick={ () => handleShowOnMapClick(props) }
					>
						{ showOnMap ? "Show all" : "Show selected" }
					</Button>
				) }
			</Col>
		</Row>
  );
};
const MainMap = compose(
	withState("activeClusterList", "setActiveClusterList", []),
	withState("activeHoveredCluster", "setActiveHoveredCluster", {
		lat: null,
		lng: null,
	}),
	withHandlers({
		onMarkerClustererClick: () => (markerClusterer) => {},
		fetchClusteredOrders: (props) => (marker) => {
			const { orderInfo } = props;
			const clusteredMarkers = orderInfo.reduce((acc, curr) => {
				if (curr.lat === marker.lat && curr.lng === marker.lng) {
					acc.push(curr);
				}
				return acc;
			}, []);

			return clusteredMarkers;
		},
	}),
	withHandlers({
		// here we are using withHandlers again to use the fetchClusteredOrders function
		checkForCluster: (props) => (marker) => {
			// add marker to active clusterlist only if its in a cluster
			const clusteredOrders = props.fetchClusteredOrders(marker);
			if (clusteredOrders.length > 1) {
				const { activeClusterList, setActiveClusterList } = props;
				const { lat, lng } = marker;
				const activeClusterindex = activeClusterList.findIndex(
					({ coords }) => coords.lat === lat && coords.lng === lng
				);
				const isExist = activeClusterindex !== -1;
				if (!isExist) {
					const clusterInfo = {
						coords: { lat, lng },
						selectedMarkersCount: 1,
						clusterLength: clusteredOrders.length,
					};
					setActiveClusterList(activeClusterList.concat(clusterInfo));
				} else {
					// if exist then increment markers count
					const clusterInfo = activeClusterList[activeClusterindex];
					clusterInfo.selectedMarkersCount += 1;
					setActiveClusterList([...activeClusterList]);
				}
			}
		},
		fetchSelectedClustersInfo: (props) => (marker) => {
			const { selectedOrderKeys } = props;
			const clusteredOrders = props.fetchClusteredOrders(marker);
			if (clusteredOrders.length > 0) {
				const selectedOrders = clusteredOrders.filter((order) =>
					selectedOrderKeys.includes(order.id)
				);
				return {
					isFullySelected:
						selectedOrders.length === clusteredOrders.length,
					isPartiallySelected:
						selectedOrders.length > 0 &&
						selectedOrders.length < clusteredOrders.length,
				};
			}
			/* 
			const { activeClusterList } = props;
			const { lat, lng } = marker;
			const activeClusterindex = activeClusterList.findIndex(
				({ coords }) => coords.lat === lat && coords.lng === lng
			);
			const isExist = activeClusterindex !== -1;
			if (isExist) {
				const clusterInfo = activeClusterList[activeClusterindex];
				const { selectedMarkersCount, clusterLength } = clusterInfo;
				return {
					isFullySelected: selectedMarkersCount === clusterLength,
					isPartiallySelected:
						selectedMarkersCount > 0 &&
						selectedMarkersCount < clusterLength,
				};
			} */
			return {
				isFullySelected: false,
				isPartiallySelected: false,
			};
		},

		unselectMarkerFromCluster: (props) => (marker) => {
			const { activeClusterList, setActiveClusterList } = props;
			// check if marker's lat and lng is in activeClusterList
			const { lat, lng } = marker;
			const activeClusterindex = activeClusterList.findIndex(
				({ coords }) => coords.lat === lat && coords.lng === lng
			);
			const isExist = activeClusterindex !== -1;
			if (isExist) {
				const clusterInfo = activeClusterList[activeClusterindex];
				clusterInfo.selectedMarkersCount -= 1;
				setActiveClusterList([...activeClusterList]);
			}
		},
	}),
	withScriptjs,
	withGoogleMap
)((props) => (
	<Spin spinning={false} delay={1000}>
		<GoogleMap
			{...props}
			// defaultCenter={{ lat: 17.6868, lng: 83.2185 }}
			defaultOptions={{
				streetViewControl: false,
				scaleControl: true,
				mapTypeControl: false,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: google.maps.ControlPosition.TOP_RIGHT,
				},
				zoomControl: false,
				fullscreenControl: true,
				fullscreenControlOptions: {
					position: google.maps.ControlPosition.BOTTOM_RIGHT,
				},
				disableDefaultUI: true, // disable default map UI
				draggable: true, // make map draggable
				keyboardShortcuts: false, // disable keyboard shortcuts
				scrollwheel: true, // allow scroll wheel
				styles, // change default map styles
			}}
			defaultCenter={{ lat: 30.9873867, lng: -97.472588 }}
			defaultZoom={2}
			ref={(c) => {
				if (props.onMapMounted) {
					props.onMapMounted(c, props);
				}
			}}
		>
			<MapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}>
				{props.showPreplnControl === true && (
					<Fragment>
						<div className="map-form">
							<Row>
								<MapActions
									{...props}
									// handlePanelClick={props.handlePanelClick}
									toggleRouteVisibility={
										props.toggleRouteVisibility
									}
									handleShowOnMapClick={
										props.handleShowOnMapClick
									}
								/>
							</Row>
						</div>
					</Fragment>
				)}
			</MapCustomControl>
			{!isEmpty(props.orderInfo) &&
				_.isArray(props.orderInfo) &&
				props.orderInfo
					.filter((order) => order.lat && order.lng)
					.map((marker, routeIndex) => (
						<OrderMarker
							key={routeIndex}
							marker={marker}
							isAirCargo={props.isAirCargo}
							setClusteredOrder={props.setClusteredOrder}
							// send active hovered cluster and set active cluster hovered as props
							{...props}
						/>
					))}
			{!isEmpty(props.routes) && _.isArray(props.routes) && (
				<StopRoutes routes={props.routes} {...props} />
			)}

			{!isEmpty(props.driver) &&
				_.isObject(props.driver) &&
				Object.keys(props.driver).length > 0 &&
				props.driver.route_status !== "COMPLETED" &&
				props.driver.latitude &&
				props.driver.longitude && (
					<OverlayView
						position={{
							lat: props.driver.latitude,
							lng: props.driver.longitude,
						}}
						mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
						getPixelPositionOffset={getPixelPositionOffset}
					>
						<div>
							<Popover
								title="Driver Info"
								content={
									<div style={{ textAlign: "left" }}>
										<Row>
											<Col
												md={24}
												lg={24}
												sm={24}
												xs={24}
											>
												{props.driver.name && (
													<Row>
														<Col xs={1}>
															<Icon type="user" />
														</Col>
														<Col xs={23}>
															<Tooltip title="Driver NAme">
																&nbsp;&nbsp;
																{
																	props.driver
																		.name
																}
															</Tooltip>
														</Col>
													</Row>
												)}
												{props.driver.mobile_number && (
													<Row>
														<Col xs={1}>
															<Icon type="phone" />
														</Col>
														<Col xs={23}>
															<Tooltip title="Contact No">
																&nbsp;&nbsp;
																{
																	props.driver
																		.mobile_number
																}
															</Tooltip>
														</Col>
													</Row>
												)}
												{props.driver.tr_datetime
												 ?	formatByTimeConfig(moment(props.driver.tr_datetime) , props.isMilitaryTime , "MMM DD, YYY HH:mm" , "MMM DD, YYY hh:mm A" )
													: ""}
											</Col>
										</Row>
									</div>
								}
							>
								<div className="overLayPin">
									<Avatar
										src={
											props.driver.icon &&
											props.driver.icon.url
												? props.driver.icon.url
												: manlogo
										}
										size={20}
									/>
								</div>
								<div className="pulse" />
							</Popover>
						</div>
					</OverlayView>
				)}

			{!isEmpty(props.routes) &&
				_.isArray(props.routes) &&
				props.routes.map((route, routeIndex) => (
					<Polyline
						key={`polyLine${route.route_id}${routeIndex}`}
						path={route.stop_markers
							.filter((stop) => stop.status !== "CANCELLED")
							.map((stop) => ({ lat: stop.lat, lng: stop.lng }))}
						options={{
							geodesic: true,
							strokeColor: `#${route.bgColor}`,
							strokeOpacity: 1.0,
							strokeWeight:
								route.route_id === props.selectedRoute ? 4 : 2,
						}}
					/>
				))}
			{!isEmpty(props.orderInfo) &&
				_.isArray(props.orderInfo) &&
				!isEmpty(props.selectedOrderKeys) &&
				_.isArray(props.selectedOrderKeys) && (
					<Polyline
						key="currentProcessing"
						path={setOrderSequence(
							props.orderInfo,
							props.selectedOrderKeys
						)}
						options={{
							geodesic: true,
							strokeColor: "#734088",
							strokeOpacity: 1.0,
							strokeWeight: 2,
						}}
					/>
				)}
		</GoogleMap>
	</Spin>
));

/* MainMap.propTypes = {
  onMapMounted: PropTypes.func.isRequired(),
  onToggleOpen: PropTypes.func.isRequired(),
  orderInfo: PropTypes.arrayOf(PropTypes.shape()),
  routes: PropTypes.arrayOf(PropTypes.shape()),
  selectedOrderKeys: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.arrayOf(PropTypes.string).isRequired(),
  selectedRoute: PropTypes.string,
};

MainMap.defaultProps = {
  orderInfo: [],
  routes: [],
  selectedOrderKeys: [],
  selectedRoute: '',
}; */

export default MainMap;
