/* eslint-disable no-nested-ternary */
import React, { Fragment } from "react";
import BaseList from "../BaseList";
import { checkNegitive,isEmpty } from "../../common/Common";
import equal from "fast-deep-equal";
import {
  Icon,
  Popconfirm,
  Tooltip,
  Input,
  Select,
  Row,
  Col,
  Button,
} from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import DeleteIcon from "../common/DeleteIcon";
import _ from "lodash";
import I18n from "../../common/I18n";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import GLCodeSelect from "../common/SelectDropdowns/GLCodeSelect";

const { TextArea } = Input;
class InvoicesList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      uniqRows: [],
      invoiceName: "",
      invoiceCode: this.props.defaultTransportationChargeGLCode,
      invoiceQuantity: 1,
      invoiceAmount: "",
      orderType:"",
      error: "",
      glCodeError: "",
      errorName: "",
      orderError:"",
      isSaved: false,
      recordForEdit: {},
      DriverSettlementApproved : this.props.DriverSettlementApproved,
      hasConsolidatedOrders : this.props.hasConsolidatedOrders,
      isLocked : this.props.isLocked,
      removeEditingVals: this.props.removeEditingVals
    }
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    const { orderData } = props;
    const columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => {
          const currentRowIndex = this.state.uniqRows.findIndex(rec => rec.id === record.id || (!isEmpty(record.order_item_group_id) && rec.order_item_group_id === record.order_item_group_id));
          const obj = {
            children: <Fragment>
              <span>{ currentRowIndex < 0 ? this.state.uniqRows.length + 1 : currentRowIndex + 1}</span>
              <div className="textBold textPrimary">{record.is_fmp_applied === 'true' ? 'FMP' : ''}</div>
            </Fragment>,
            props: {}
          };
          if(!isEmpty(record.order_item_group_id)){
            obj.props.rowSpan = this.findSpanValue(this.props.data, record, index)
            return obj;
          }
          return obj;
        },
        width: 40,
        className: 'alignCenter'
      },
      {
        key: "invoice_name",
        title: I18n.t("general.description"),
        dataIndex: "invoice_name",
        render: (data, record, index) => {
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          return record.isNew && !this.state.isSaved || isEditing ? (
            <Fragment>
              <Row>
                {this.props.invoice_type === "accessorial_charges" ? (
                  <Select
                    value={record.accessorial_code}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="width100Per"
                    size="small"
                    onChange={(e) => {
                      this.props.handleOnChange(
                        index,
                        "accessorial_code",
                        e,
                        this.props.currentSection.order_number
                      )
                      this.setState({ invoiceName: e, invoiceCode: record.gl_code })
                    }}
                  >
                    <Select.Option value="">Select accessorial</Select.Option>
                    {this.props.reqData.map((reqRecord) => (
                      <Select.Option
                        key={reqRecord.accessorial_code}
                        value={reqRecord.accessorial_code}
                      >
                        {reqRecord.accessorial_name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    value={this.state.invoiceName}
                    size="small"
                    onChange={(e) =>{
                      this.props.handleOnChange(
                        index,
                        "invoice_name",
                        e.target.value,
                        this.props.currentSection.order_number
                      )
                      this.setState({ invoiceName: e.target.value })
                    }
                    }
                  />
                )}
              </Row>
              {!isEmpty(this.state.errorName) && isEmpty(this.state.invoiceName) ? <Row><span style={{ color: "red", fontSize: "13px" }}>{this.state.errorName}</span></Row> : ""}
            </Fragment>
          ) : (
            <Fragment>
              {data}{" "}
              {!isEmpty(record.item_description) ? (
                <i className="fontSize10">{record.item_description}</i>
              ) : (
                ""
              )}{" "}{record.added_from === "MANUAL" && <sup className="textRed textBold">M</sup>}
            </Fragment>
          );
        },
        width: this.props.invoice_type === "accessorial_charges" ? 200 : 300,
      },
      {
        key: "location_id",
        title: I18n.t("general.order_type"),
        dataIndex: "location_id",
        width: 100,
        render: (data, record, index) => {
         const locationData = orderData?.locations?.find((id) => id.location_id ===record.location_id)
         const ObtainedVal = locationData?.order_type
         const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          return (
            record.isNew && !this.state.isSaved || isEditing ? (
              <Fragment>
                <Select
                  showSearch
                  value={record.location_id}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => {
                    this.props.handleOnChange(
                      index,
                      "location_id",
                      e,
                      this.props.currentSection.order_number
                    );
                    this.setState({orderType:record.location_id})
                  }}
                  size="small"
                  className="width100Per"
                >
                  {
                    ["T","LH"].includes(orderData?.type_of_order && orderData.locations.length > 1) ? (
                      <Fragment>
                        <Select.Option value=""> 
                          ---Select---
                        </Select.Option>
                        {
                          orderData?.locations?.map((orderType) => (
                            <Select.Option
                              key={orderType.location_id}
                              value={orderType.location_id}
                            >
                              {orderType.order_type}
                            </Select.Option>
                          ))
                        }
                      </Fragment>
                    ) :  
                    orderData?.locations?.map((orderType) => (
                        <Select.Option
                          key={orderType.location_id}
                          value={orderType.location_id}
                        >
                          {orderType.order_type}
                        </Select.Option>
                      ))
                  }
                </Select>
                {!isEmpty(this.state.orderError) && isEmpty(record.location_id) ? <Row><span style={{ color: "red", fontSize: "13px" }}>{this.state.orderError}</span></Row> : ""}
              </Fragment>
            ) : (
              <Fragment>{ObtainedVal}</Fragment>
            )
          );
        }
      },
      {
        key: "gl_code",
        title: I18n.t("invoices.gl_code"),
        dataIndex: "gl_code",
        render: (data, record, index) => {
          const dataSource= this.props.data;
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const child = record.isNew && !this.state.isSaved || isEditing ? (
            // this.props.invoice_type === "accessorial_charges" ? (
            //   <Fragment>
            //     <TextArea
            //       rows={1}
            //       size="small"
            //       placeholder={I18n.t("general.description")}
            //       value={record.invoice_name}
            //       onChange={(e) =>
            //         this.props.handleOnChange(
            //           index,
            //           "invoice_name",
            //           e.target.value,
            //           this.props.currentSection.order_number
            //         )
            //       }
            //     />
            //   </Fragment>
            // ) : (
              <Fragment>
                {/* <Input
                  type="text"
                value={this.state.invoiceCode}
                  placeholder={I18n.t("invoices.gl_code")}
                  size="small"
                  style={{ width: "75%" }}
                onChange={(e) => {
                  this.setState({ invoiceCode: e.target.value })
                }
                }
                /> */}
 
                <GLCodeSelect
                type={this.props.type}
                  invoiceType={this.props.invoice_type}
                  defaultValue={record.gl_code || this.props.defaultTransportationChargeGLCode}
                  data={this.props.glCodesList || []}
                  value={this.state.invoiceCode}
                  onChange={(e) => {
                    this.props.handleOnChange(
                      index,
                      "gl_code",
                      e,
                      this.props.currentSection.order_number
                    );
                    this.setState({ invoiceCode: e })
                  }}
                  selectKey={"gl_code"}
                  selectValue={"gl_code"}
                  selectedValue={"gl_code"}
                  selectedCode={"description"}
                  style={{ width: 120 }}
                  showSearch={true}
                  placeholder={I18n.t("invoices.gl_code")}
                  dropdownMatchSelectWidth={false}
                  size="small"
                />
                {!isEmpty(this.state.glCodeError) && isEmpty(this.state.invoiceCode) ? <Row><span style={{ color: "red", fontSize: "13px" }}>{this.state.glCodeError}</span></Row> : ""}
              </Fragment>
            // )
          ) : (
            <Fragment>
               {data || ""} 
               {/* {record.order_item_group_id} */}
            </Fragment>
          )
          const obj = {
            children: child,
            props: {}
          };
          // if(!isEmpty(record.order_item_group_id)){
          //   obj.props.rowSpan = this.findSpanValue(this.props.data, record, index)
          //   return obj;
          // }
          return obj;
        },
        width: 120,
      },
      {
        key: "invoice_quantity",
        title: I18n.t("invoices.units"),
        dataIndex: "invoice_quantity",
        render: (data, record, index) => {
          let spanValue = ""
          if(!isEmpty(record.order_item_group_id)){
            spanValue = this.findSpanValue(this.props.data, record, index)
          }
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const children = record.isNew && !this.state.isSaved || isEditing ? (
            <Fragment>
              <Input
                type="number"
                value={this.state.invoiceQuantity}
                min="1"
                size="small"
                style={{ width: 100 }}
                onChange={(e) =>
                {
                  this.setState({ invoiceQuantity: e.target.value })
                  if (this.props.invoice_type === "accessorial_charges" || isEditing) {
                  this.props.handleOnChange(
                    index,
                    "invoice_quantity",
                    e.target.value,
                    this.props.currentSection.order_number
                  )
                  }
                }
                }
                
              />
            </Fragment>
          ) : (
            <Fragment>{!isEmpty(spanValue) ? spanValue : (data || "")}</Fragment>
          )
          const obj = {
            children,
            props: {}
          };
          if(!isEmpty(spanValue)){
            obj.props.rowSpan = spanValue
            return obj;
          }
          return obj;
        },
        align: "center",
      },
      {
        key: "invoice_value",
        title: I18n.t("invoices.amount"),
        dataIndex: "invoice_value",
        render: (data, record, index) => {
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const children = record.isNew && !this.state.isSaved || isEditing ? (
            <Fragment>
              <Row>
              <Input
                type="number"
                value={this.state.invoiceAmount}
                // value={data}
                placeholder={I18n.t("invoices.amount")}
                size="small"
                style={{ width: "85%" }}
                addonBefore={I18n.t("general.price_symbol")}
                onChange={(e) => {
                  this.setState({ invoiceAmount: e.target.value })
                  if (this.props.invoice_type === "accessorial_charges" || isEditing) {
                  this.props.handleOnChange(
                    index,
                    "invoice_value",
                    e.target.value,
                    this.props.currentSection.order_number
                  )
                  }
                }
                }
              />
              </Row>
              {!isEmpty(this.state.error) && isEmpty(this.state.invoiceAmount)? <Row><span style={{ color: "red", fontSize: "13px" }}>{this.state.error}</span></Row> : ""}
            </Fragment>
          ) : (
            <Fragment>{checkNegitive(data)}</Fragment>
          )
          const obj = {
            children,
            props: {}
          };
          if(!isEmpty(record.order_item_group_id)){
            obj.props.rowSpan = this.findSpanValue(this.props.data, record, index)
            return obj;
          }
          return obj;
      },
        align: "right",
      },
      {
        key: "actions",
        title: "",
        dataIndex: "isNew",
        render: (data, record, index) =>{
          const { showEditIcon = false } = this.props;
          const currentRowIndex = this.state.uniqRows.findIndex(rec => rec.id === record.id || (!isEmpty(record.order_item_group_id) && rec.order_item_group_id === record.order_item_group_id));
          const isLastRow = currentRowIndex < 0 ? true : currentRowIndex + 1 === this.state.uniqRows.length 
          const checkLocationLength = this.props.orderData?.locations?.length > 1
          const showCharges = (this.props.showAddIcon && isLastRow && this.props.hasNew && this.props.showActions === true) || (this.props.showAddIcon && isLastRow && this.props.hasNew && (!this.props.isLocked))
          const showDelete = (!record.isNew && !this.state.isSaved && this.props.showActions === true) || ((!this.props.isLocked))
          const showEdit = ( !record.isNew && !this.state.isSaved && this.props.showActions === true) || ((!this.props.isLocked))
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const locationData = orderData?.locations?.find((id) => id.location_id ===record.location_id)
          const ObtainedVal = locationData?.order_type

          const children = this.props.isLocked ? <Fragment /> :
            (record.isNew && !this.state.isSaved ? (
              <Row type="flex" align="middle" >
                <Col span={ 8 }>
              {checkServiceExistance("CINL") && (
                <Tooltip placement="topLeft" title="Save">
                  <Icon type="save" theme="twoTone" className="fontSize14 cusorPointer" 
                   onClick={() =>
                    {
                      const isGlCodeEmpty = _.trim(this.state.invoiceCode).length === 0
                      if (isEmpty(this.state.invoiceAmount) || isEmpty(this.state.invoiceName) || isEmpty(record.location_id) || isGlCodeEmpty) {
                        this.setState({
                          error: isEmpty(this.state.invoiceAmount) ? "Cannot be blank" : "",
                          glCodeError: isEmpty(this.state.invoiceCode) ? "Cannot be blank" : "",
                          errorName: isEmpty(this.state.invoiceName) ? (this.props.invoice_type === "accessorial_charges" ? "Select the accessorial" : "cannot be blank") : "",
                          orderError:isEmpty(record.location_id) ? "Cannot be blank" : ""
                        
                        });
                      } else {
                       this.props.saveItem(index, this.props.invoice_type, this.props.currentSection.order_number, {
                         invoice_name: this.state.invoiceName,
                          gl_code: this.state.invoiceCode,
                          invoice_quantity: this.state.invoiceQuantity,
                          invoice_value: this.state.invoiceAmount,
                        });
                        this.setState({
                          invoiceName: "",
                          invoiceCode: "",
                          invoiceQuantity: 1,
                          invoiceAmount: "",
                          isSaved: true,
                          error: "",
                          glCodeError: "",
                          errorName: "",
                          orderType: checkLocationLength ? "" : this.props.orderData?.locations[0]?.location_id,
                          orderError:""
                        });
                      }

                    }
                    }/>
                </Tooltip>
              )}
                </Col>
                <Col span={ 8 }>
              {checkServiceExistance("BLDINVI") && (
                <Tooltip placement="topLeft" title="Delete">
                  <Popconfirm
                    placement="left"
                    title={I18n.t("messages.delete_confirm")}
                    onConfirm={() => {
                      this.props.deleteClick(
                        index,
                        this.props.invoice_type,
                        this.props.currentSection.order_number
                      )
                        this.setState({
                          invoiceName: "",
                          invoiceCode: "",
                          invoiceQuantity: 1,
                          invoiceAmount: "",
                          error: "",
                          glCodeError: "",
                          errorName: '',
                          orderType:"",
                          orderError:""

                        })
                      }
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                        <Icon type="close" style={ { fontSize: 14 } } />
                  </Popconfirm>
                </Tooltip>
              )}
                </Col>
              </Row>
          ) : (
                <Row type="flex" align="middle" >
                 {this.props.invoice_type !== "accessorial_charges" &&  showEditIcon === true && showEdit && !isEditing ? (
                <Col span={8}>
                  <Tooltip placement="topLeft" title="Edit">
                    <Icon
                      type="edit"
                      theme="twoTone"
                      onClick={() => {
                        const updateRecord = {
                          ...record,
                          isEditing: true,
                        };
                        this.props.handleEditItem(
                          index,
                          this.props.invoice_type,
                          _.get(record, "id", ""),
                          updateRecord,
                          this.props.orderData.locations
                        );
                        this.setState({
                          isSaved: false,
                          recordForEdit: record,
                          invoiceName: record.invoice_name,
                          invoiceCode: record.gl_code,
                          invoiceQuantity: record.invoice_quantity,
                          invoiceAmount: record.invoice_value,
                          orderType: ObtainedVal,
                        });
                      }}
                          style={ { fontSize: 15 } }
                    />
                  </Tooltip>
                </Col>
              ) : isEditing && (
                  <>
                    <Col span={ 8 }>
                      <Icon
                        type="save"
                        theme="twoTone"
                        onClick={ () => this.props.editSave(index, this.props.invoice_type, this.props.currentSection.order_number, _.get(record, "id", "")) }
                        style={ { fontSize: 14 } }
                      />
                    </Col>
                    <Col span={ 8 }>
                      <Icon
                        type="close"
                        onClick={ () => {
                          this.props.cancelEditItem(
                            index,
                            this.props.invoice_type,
                            _.get(record, "id", ""),
                          );
                          this.setState({
                            recordForEdit: null,
                            invoiceName: "",
                            invoiceCode: "",
                            invoiceQuantity: 1,
                            invoiceAmount: "",
                            orderType: "",
                          });
                        } }
                        style={ { fontSize: 14 } }
                      />
                    </Col>
                  </>
              )}
              {showDelete && !isEditing && (
                <Col span={8}>
                  <Tooltip placement="topLeft" title="Delete">
                    <Popconfirm
                      placement="left"
                      title={I18n.t("messages.delete_confirm")}
                      onConfirm={() =>
                        this.props.deleteClick(
                          index,
                          this.props.invoice_type,
                          this.props.currentSection.order_number
                        )
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon type="delete" style={{ fontSize: 14, color: "red" }} />
                    </Popconfirm>
                  </Tooltip>
                </Col>
              )}
                  <Col span={ 8 }>
                    { showCharges && !isEditing && (
                  <Tooltip
                    placement="topLeft"
                    title={
                      this.props.invoice_type === "accessorial_charges"
                        ? I18n.t("accessorials.add")
                        : "Add Charge"
                    }
                  >
                    {!_.isEmpty(this.state.recordForEdit) ? (
                      <Popconfirm
                        title="Current editing progress will be lost. Are you sure?"
                        onConfirm={() => {
                          this.setState({
                            recordForEdit: null,
                            invoiceName: "",
                            invoiceCode: "",
                            invoiceQuantity: 1,
                            invoiceAmount: "",
                            orderType: "",
                          });
                          this.props.addItem(
                            this.props.invoice_type,
                            this.props.currentSection.order_number,
                            this.props.orderData.locations
                          );
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Icon
                          type="plus-circle"
                          theme="filled"
                          style={{ fontSize: 14 }}
                        />
                      </Popconfirm>
                    ) : (
                      <Icon
                        type="plus-circle"
                        theme="filled"
                        onClick={() =>
                          this.props.addItem(
                            this.props.invoice_type,
                            this.props.currentSection.order_number,
                            this.props.orderData.locations
                          )
                        }
                        style={{ fontSize: 14 }}
                      />
                    )}
                  </Tooltip>
                    ) }
                  </Col>
                  <Col span={ 8 }>
                  </Col>
            </Row>
          ))
          const obj = {
            children,
            props: {}
          };
          if(!isEmpty(record.order_item_group_id)){
            obj.props.rowSpan = this.findSpanValue(this.props.data, record, index)
            return obj;
          }
          return obj;
        },
        width: 100,
        align: "right",
      },
    ];
    this.columns = [...columns];
  }

  findSpanValue = (dataSource, record, index) => {
    const valueIndex = _.findIndex(dataSource, {order_item_group_id: record.order_item_group_id});
    if(index === valueIndex){
      const rowSpan = dataSource.filter(rec => rec.order_item_group_id === record.order_item_group_id).length
      return rowSpan;
    }
    return 0;
  }

  componentDidMount() {
    if(this.props.invoice_type === "accessorial_charges"){
      const accessorialCodeColumn =
      {
        key: "accessorial_code",
        title: I18n.t("general.code"),
        dataIndex: "accessorial_code",
        render: (data, record, index) => data || "",
        width: 100,
        align: "left",
      }

      this.columns.splice(2, 0, accessorialCodeColumn);
    }
    if(this.props.orderData && this.props.orderData?.locations?.length === 1){
      this.setState({orderType:this.props.orderData?.locations[0]?.location_id})
    }
    this.setUniqData();
  }

  setUniqData = () => {
    const dataSource = this.props.data || [];
    const uniqRows = [];
    dataSource.forEach(rec => {
      if(!isEmpty(rec.order_item_group_id)){
        if(_.findIndex(uniqRows, { order_item_group_id: rec.order_item_group_id}) < 0){
          uniqRows.push(rec)
        }
      } else {
        uniqRows.push(rec)
      }
    });
    this.setState({
      uniqRows,
      isSaved: false
    })
  }


  componentDidUpdate(prevProps, prevState) {
    if(!equal(prevProps.data, this.props.data)) {
       this.setUniqData();
       this.columns = [];
       this.setColumns(this.props);
    }
    if(!equal(prevProps.removeEditingVals, this.props.removeEditingVals)){
      this.setState({
        recordForEdit: null,
        invoiceName: "",
        invoiceCode: "",
        invoiceQuantity: 1,
        invoiceAmount: "",
        orderType: "",
        error: "",
        glCodeError: "",
        errorName: "",
        orderError: "",
      })
    }
  }
}

export default InvoicesList;

InvoicesList.defaultProps = {
  showEditIcon: false,
};