import { isEmpty } from "lodash";
import { ApiUrl, Call, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";

export const PaymentApi = {

    fetchPaymentReports: (data,isExport=false) => {
        const { page, perPage, whId, organization_id, account_id, searchValue, searchKey } = data;
        const accountId = account_id ? account_id : ""
        const baseParams = `organization_id=${organization_id}&account_id=${accountId}&wh_location_id=${whId}&${optionalPageParams(page, perPage, false)}&check_ach_no=${searchValue}&search_type=${searchKey}`;

        let url = ApiUrl(`v2/billing_aging_reports/get_payment_journal_reports?${baseParams}`);
        let exportUrl = `${url}&download_as=excel`;

        return Call("get", isExport ? exportUrl : url, {}, ParseGeneralResponse, {
            responseDataKeys: {
                data: "",
                pagination: "meta.pagination",
            },
        });
    },
}