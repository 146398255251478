/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Spin,
  Tabs,
  Button,
  Drawer,
  Icon,
  Row,
  Col,
  Text,
} from '../../common/UIComponents'
import OrderTitle from './OrderTitle'
import DetailsConfig from '../Tasks/DetailsConfig'
import { checkForSMP } from '../../helpers/billing'
import { withRouter } from 'react-router'
import { isEmpty } from '../../common/Common'
import EditOrderForm from './EditOrderForm'
import I18n from '../../common/I18n'

class EditOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentOrder: this.props.order || {},
      orders: [],
      inProgress: false,
      detailsVisible: false,
      orderNo: this.props.order_no,
      is_updated: false,
      currentPath: this.props.location.pathname,
    }
  }

  // update path on location change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ currentPath: this.props.location.pathname })
    }
  }

  getOrderDetails = () => {
    this.setState({
      detailsVisible: true,
    })
  }

  onClose = () => {
    this.setState({
      detailsVisible: false,
    })
    if (this.state.is_updated) {
      this.refreshCallback()
    }
  }

  handleEdit = () => {
    this.props.editClick()
    this.onClose()
  }

  refreshCallback = () => {
    if (typeof this.props.refreshCallback === 'function') {
      this.props.refreshCallback()
    }
  }

  updateParentState = (element, value) => {
    this.setState({
      [element]: value,
    })
  }
  renderData = () => {
    const { currentOrder } = this.state
    return (
      <Fragment>
        {!isEmpty(this.props.data) ? (
          <div onClick={this.getOrderDetails}>{this.props.data}</div>
        ) : this.props.showIcon ? (
          <Icon type="edit" onClick={this.getOrderDetails} />
        ) : this.state.currentPath === '/billing' ? (
          <Row
            type="flex"
            gutter={4}
            style={{ ...this.props.style, justifyContent: 'start' }}
          >
            <Col>
              <Text className="clickableLink" onClick={this.getOrderDetails}>
                {this.state.orderNo}
              </Text>
            </Col>
            <Col>
              <Icon type="edit" onClick={this.handleEdit} />
              {this.props.showSMP === true && checkForSMP(currentOrder)}
            </Col>
          </Row>
        ) : (
          <Fragment>
            <Text className="clickableLink" onClick={this.getOrderDetails}>
              {this.state.orderNo}
            </Text>
            {this.props.showSMP === true && checkForSMP(currentOrder)}
          </Fragment>
        )}
      </Fragment>
    )
  }
  render() {
    const { currentOrder } = this.state
    const { handleSuccess, onCancel } = this.props
    const { tab } = this.props
    return (
      <Fragment>
       
          {this.props.isCreateButton ? (
            <Button
              type="primary"
              onClick={() => this.getOrderDetails()}
              style={{ marginTop: '2px', marginLeft: 10 }}
            >
              {I18n.t('order.create')}
            </Button>
          ) : (
            this.renderData()
          )}
          {this.state.detailsVisible && (
            <Drawer
              // title={
              //   <OrderTitle
              //     order={currentOrder}
              //     showEdit={this.props.showEdit}
              //     editClick={this.handleEdit}
              //   />
              // }
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.detailsVisible}
              width="90%"
            >
              <div className="order_details">
                <EditOrderForm
                  customerDetails={currentOrder}
                  customerOrderId={currentOrder.id}
                  preferences={[]}
                  onCancel={(...params) => {
                    this.onClose();
                    onCancel(...params)
                  }}
                  handleSuccess={(...params) => {
                    this.onClose();
                    handleSuccess(...params)
                  }}
                  isNew={this.props.isNew}
                />
              </div>
            </Drawer>
          )}
      </Fragment>
    )
  }
}
EditOrder.propTypes = {
  order: PropTypes.shape().isRequired,
  onCancel: PropTypes.func,
  handleSuccess: PropTypes.func,
  reqFrom: PropTypes.string,
}
EditOrder.defaultProps = {
  reqFrom: '',
  handleSuccess: () => {},
  onCancel: () => {}
}

export default withRouter(EditOrder)
