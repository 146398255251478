import React from 'react';
import { Descriptions, Empty } from 'antd';

function WarehouseInfo (props) {
  const { data = {} } = props;
  const buildAddress = (address) => {
    return `${address.address_line1} ${address.address_line2} ${address.city} ${address.state} ${address.country} ${address.zipcode}`;
  };

  if (Object.keys(data).length === 0) {
    return <Empty description="No Warehouse Info" />;
  }

  const { name, l_address, time_zone_name, email, phone_number_one, phone_number_two } = data;
  return (
    <Descriptions layout="horizontal" style={ { padding: 0 } }>
      <Descriptions.Item label="Name">{ name }</Descriptions.Item>
      <Descriptions.Item label="Address">{ l_address ? buildAddress(l_address) : 'NA'}</Descriptions.Item>
      <Descriptions.Item label="Time Zone">{ time_zone_name }</Descriptions.Item>
      <Descriptions.Item label="Email">{ email }</Descriptions.Item>
      <Descriptions.Item label="Phone Number One">{ phone_number_one }</Descriptions.Item>
      <Descriptions.Item label="Phone Number Two">{ phone_number_two }</Descriptions.Item>
    </Descriptions>
  );
}

export default WarehouseInfo;
