import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function fetchAccountZones(accountCode = '') {
  const url = ApiUrl(`v2/billing_zones?account_id=${accountCode}&operation_code=BZI`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { zones: 'billing_zones' },
  });
}

export function saveAccountZones(data, isNew = true) {
  data.operation_code = isNew ? 'BZC': 'BZU';
  const url = isNew ? ApiUrl('v2/billing_zones') : ApiUrl(`v2/billing_zones/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, {}, false);
}

export function deleteAccountZone(data = {}) {
  const url = ApiUrl('v2/billing_zones/delete_zone');
  return Call('delete', url, data, ParseGeneralResponse, {operation_code: 'BZD'}, false);
}


export function saveZoneMinCharge(data) {
  data.operation_code = 'BZUMC';
  const url = ApiUrl('v2/billing_zones/update_min_max_charge');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, {}, false);
}
