import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function fetchOrgActions() {
  const url = ApiUrl(`v2/rbac/modules_n_actions`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { modules: 'rbac' },
  }, false);
}

export function fetchRoleActions(role) {
  const url = ApiUrl(`v2/rbac/role_accesses?role=${role}`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { accesses: 'rbac'} });
}

export function saveRoleActions(isNew, data) {
  const url = ApiUrl('v2/rbac');
  const method = 'post';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { packages: '' } }, true);
}

export function deletePackage(id) {
  const url = ApiUrl(`v2/packages/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse);
}

export function fetchAllModules() {
  const url = ApiUrl(`v2/packages/modules_n_actions_for_packages`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { modules: 'packages'},
  }, false);
}

export function fetchPackageModules(id) {
  const url = ApiUrl(`v2/packages?package_id=${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { packages: 'packages'},
  }, false);
}

export function fetchPermitViews(id) {
  const url = ApiUrl(`v2/rbac?package_id=${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { packages: 'packages'},
  }, false);
}

export function savePageAccess(data) {
  const url = ApiUrl('v2/rbac');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { packages: '' } }, true);
}