import React from 'react'
import ListInvoices from './ListInvoices'

export const InvoiceViewList = (props) => {
  return (
    <div>
        <ListInvoices  dispContext={props.dispContext} />
    </div>
  )
}
