import React from "react";
import { Icon } from "./UIComponents";

// SVG content
const LockSvg = ({
  color = "#33393D",
  width = "24",
  height = "24",
  className,
}) => (
  <svg
    viewBox="0 0 24 24"
    focusable="false"
    data-icon="assign"
    width={width}
    height={height}
    fill={color}
    aria-hidden="true"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00004 0.0166016C4.70483 0.0166016 2.83337 1.88806 2.83337 4.18327V8.13835C1.31127 9.3615 0.333374 11.2347 0.333374 13.3337C0.333374 17.0082 3.32554 20.0003 7.00004 20.0003C10.6745 20.0003 13.6667 17.0082 13.6667 13.3337C13.6667 11.2347 12.6888 9.3615 11.1667 8.13835V4.18327C11.1667 3.03397 10.7054 1.98859 9.95089 1.23405C9.19634 0.479506 8.14933 0.0166016 7.00004 0.0166016ZM7.00004 1.2666C7.81741 1.2666 8.53831 1.58905 9.0671 2.11784C9.59589 2.64663 9.91671 3.3659 9.91671 4.18327V7.34896C9.034 6.91661 8.04694 6.66699 7.00004 6.66699C5.95314 6.66699 4.96608 6.91661 4.08337 7.34896V4.18327C4.08337 2.56181 5.37859 1.2666 7.00004 1.2666ZM7.00004 7.91699C8.14061 7.91699 9.19668 8.26749 10.0681 8.86589C10.1275 8.93531 10.2014 8.99086 10.2845 9.02865C11.5797 10.017 12.4167 11.5729 12.4167 13.3337C12.4167 16.3326 9.99899 18.7503 7.00004 18.7503C4.00109 18.7503 1.58337 16.3326 1.58337 13.3337C1.58337 11.575 2.41818 10.0204 3.71065 9.0319C3.79517 8.99406 3.87022 8.93791 3.93038 8.86751C4.80213 8.26833 5.85865 7.91699 7.00004 7.91699ZM7.00004 10.8337C6.66927 10.8341 6.34846 10.9469 6.09028 11.1537C5.8321 11.3605 5.65188 11.6489 5.5792 11.9716C5.50652 12.2943 5.54571 12.6321 5.69032 12.9296C5.83493 13.2271 6.07638 13.4665 6.37504 13.6087V15.2087C6.37504 15.5537 6.65504 15.8337 7.00004 15.8337C7.34504 15.8337 7.62504 15.5537 7.62504 15.2087V13.6071C7.92278 13.4644 8.16329 13.2249 8.30723 12.9278C8.45117 12.6307 8.49003 12.2935 8.41745 11.9714C8.34487 11.6493 8.16514 11.3614 7.90766 11.1547C7.65019 10.948 7.3302 10.8348 7.00004 10.8337Z"
      fill={color}
      className={className}
    />
  </svg>
);

// Icon component
const LockIcon = (props) => {
  const { styling = {}, className = "" } = props;
  return (
    <>
      <Icon
        component={() => <LockSvg {...props} />}
        {...props}
        className={className}
      />
    </>
  );
};

export default LockIcon;
