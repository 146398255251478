import React, { useState, useEffect } from 'react';
import CarrierIntegration from './CarrierIntegration';
import { Row, Col, Button } from 'antd';
import _ from 'lodash';
import I18n from '../../common/I18n';
import AppConfig from '../../config/AppConfig';
import BaseModal from '../BaseModal';
import PageWrapper from '../../common/PageWrapper';
import { getOrganizations } from '../../helpers/common';
import { fetchOrganizations } from '../../api/Organisations';
import IntegrationsFilter from './IntegrationsFilter';
import { CarrierIntegrationApi, IntegrationApi } from '../../api/IntegrationApi';
import CarrierCustomizeTable from './CarrierCustomizeTable';
import { alertMessage, isEmpty } from '../../common/Common';


function CarrierCustomize () {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ activeIntegrationId, setActiveIntegrationId ] = useState(null);
  const [ integrationsData, setIntegrationsData ] = useState([]);
  const [ paginationConfig, setPaginationConfig ] = useState({
    current: 1,
    pageSize: AppConfig.perPage,
    total: 0,
  });
  const [pagination, setPagination] = useState({ current_page: 1 })
  // const [ tableOptions, setTableOptions ] = useState({
  //   pagination: {
  //   }
  // });
  const [ organizations, setOrganizations ] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || AppConfig.perPage,
  };

	// const onTableChange = (tablePagination) => {
	// 	let currentPage = null;
	// 	if (!_.isEmpty(tableOptions.pagination))
	// 		currentPage = tableOptions.pagination.current
	// 	else
	// 		currentPage = 1;

	// 	if (tablePagination.current !== currentPage) {
	// 		setTableOptions({ pagination: tablePagination });
	// 	}
	// };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    getIntegrationConfig(selectedOrg , selectedAccount);
  };

  useEffect(() => {
    const allOrgs = async () => {
      try {
        const organizations = await getOrganizations(1, null, fetchOrganizations);
        const sortedOrgs = _.sortBy(organizations, 'name');
        setOrganizations(sortedOrgs);
      }
      catch (error) {
        console.error("Error:", error.message);
      }
    };
    allOrgs();
  }, []);

  const getIntegrationConfig = async (selectedorg , selectedAccount) => {
    try {
      setIsLoading(true);
      const page =
        tableOptions.pagination?.current &&
        !isEmpty(tableOptions.pagination.current)
          ? tableOptions.pagination.current
          : 1
      const perPage = AppConfig.perPage;
      const response = await CarrierIntegrationApi.getIntegrationConfig({ organization_id: selectedorg || (organizations?.length ? organizations[ 0 ].id : ""), account_id : selectedAccount, page, perPage });
      if (response && response.data) {
        setIntegrationsData(response.data);
        setPagination(response.pagination);
      }
    }
    catch (error) {
      console.error("Error:", error.message);
    }
    finally {
      setIsLoading(false);
      setSelectedOrg(selectedorg || (organizations?.length ? organizations[ 0 ].id : ""));
      setSelectedAccount(selectedAccount);
    }
  };

  useEffect(() => {
    if (!organizations.length) return;
      getIntegrationConfig(selectedOrg , selectedAccount);
  }, [ organizations , isModalOpen]);

  const onModalClose = () => {
    setIsModalOpen(false);
    setActiveIntegrationId(null);
  };

  const renderModal = () => {
    return (
      <BaseModal
        title={
          activeIntegrationId
            ? "Edit Carrier Integration"
            : "Add Carrier Integration"
        }
        maskClosable={false}
        className="integrations-modal"
        onCancel={ onModalClose }
        width="75%"
      >
        <CarrierIntegration
          isLoading={ isLoading }
          activeIntegrationId={ activeIntegrationId }
          integrationsData={ integrationsData }
          closeIntegrationModal={ onModalClose }
          organizations={ organizations }
          onCancel={ onModalClose }
          onSuccess={ onModalClose }
          selectedOrg={selectedOrg}
          selectedAccount={selectedAccount}
        ></CarrierIntegration>

      </BaseModal>
    );
  };

  const onOrgChange = (value) => {
    getIntegrationConfig(value, null);
  }
  

  const onAccountChange = (selectedorg ,value) => {
    getIntegrationConfig(selectedorg,value);
  }

  return (
    <PageWrapper>
      <Row className="page-header">
        <Col xs={ 12 }>{ I18n.t("menu.carrier_integrations") }</Col>
        <Col xs={ 12 } className="page-actions">
          <Button
            type="primary"
            icon="plus"
            className="integrationsWrapper__left__header__button"
            onClick={ () => setIsModalOpen(true) }
          >
            {`${I18n.t("general.add")} ${I18n.t("menu.carrier_integrations")}`}
          </Button>
        </Col>
      </Row>
      <Row>
      </Row>
      { isModalOpen && renderModal() }
      <IntegrationsFilter organizations={ organizations } onOrgChange={onOrgChange} onAccountChange={onAccountChange} selectedAccount={selectedAccount}/>
      <Row>
        <Col className="integrationsWrapper">
          <CarrierCustomizeTable
            data={ integrationsData }
            rowKey="id"
            size="small"
            handleEdit={ (id) => {
              setActiveIntegrationId(id);
              setIsModalOpen(true);
            }}
            handleDelete={ (id) => {
              CarrierIntegrationApi.deleteIntegrationConfig(id).then((response) => {
                if(response.success){
                   alertMessage("Integration deleted successfully", "success");
                }else{
                  alertMessage("Error while deleting integration", "error");
                }
              }).finally(() => {
                setIntegrationsData(integrationsData.filter((item) => item.id !== id));
              }
              )
            }}
            isLoading={ isLoading }
            pagination={tablePagination}
            tableChange={ (pagination, filter, sorter, currentTable) =>
              onTableChange(pagination,filter, sorter, currentTable)
            }
            scroll = {{y:"calc(100vh - 300px)"}}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default CarrierCustomize;