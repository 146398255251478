import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import QuotesRevisionsList from './QuotesRevisionsList'
import { checkNegitive, isEmpty } from '../../common/Common'
import QuoteSummary from './QuotesSummary'
import { Col, Row } from 'antd'
import I18n from '../../common/I18n'

const QuoteRevisions = ({ revisions, size, styleObj, account_accessorials = [],updateAddOns=[],orderType }) => {
  const renderField = (fieldName, record, type, mode) =>
    checkNegitive(record[fieldName])

    const renderAccessorial = (record, type = 'revision', mode = 'view') => {

      return (
        <div className="amountUpdate textBold">
          <Row gutter={[8,16]}>
            {record.ori_accessorial_charges &&
              record.ori_accessorial_charges.length > 0 && (
                <Col span={24}>
                  <div>
                    <h3 className="label_heading textBold">
                      {I18n.t("general.accessorial")}
                    </h3>
                    <hr style={{ width: 75 }} />
                    <div className="marginTop10">
                      <span className="fontWeight500 textPrimary">
                        {record.ori_accessorial_charges
                          .map((rec) => rec.accessorial_name)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
            {record.add_on_details && record.add_on_details.length > 0 && (
              <Col span={24}>
                <div>
                  <h3 className="label_heading textBold">Custom Charges</h3>
                  <hr style={{ width: 75 }} />
                  <div className="marginTop10">
                    <span className="fontWeight500 textPrimary">
                      {record.add_on_details
                        .map((rec) => rec.add_on_name)
                        .join(", ")}
                    </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
      </div>
    );
  }

  const renderList = () => (
    <QuotesRevisionsList
      rowKey="_id"
      data={revisions}
      size={size}
      style={{ ...styleObj }}
      pagination={{ position: 'none' }}
      expandedRowRender={(record) => {
        let updatedRecord = {...record};
        if(isEmpty(updatedRecord.ori_accessorial_charges)) {
          updatedRecord.ori_accessorial_charges = _.isArray(record.ind_accessorial_charges) ? 
          [...record.ind_accessorial_charges] :
          []
        }
        return (<Fragment>
          {renderAccessorial(updatedRecord)}
          <QuoteSummary
            summaryDetails={updatedRecord}
            type="revision"
            mode="view"
            account_accessorials={account_accessorials}
            updateAddOns={updateAddOns}
            orderType={orderType}
          />
        </Fragment>
      )}}
    />
  )

  return <Fragment>{renderList()}</Fragment>
}

QuoteRevisions.propTypes = {
  revisions: PropTypes.arrayOf(PropTypes.shape).isRequired,
}

export default QuoteRevisions
