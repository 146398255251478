import React, { useState } from 'react'
import { RearrangableTable } from '../../common/RearrangableTable';
import I18n from '../../../common/I18n';

export const RecurringOrdersList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data,SetData] = useState([
    {
      key:1,
      template_name:'XYZ TEMPLATE',
      selected_warehouse:'ABC WAREHOUSE',
      from_time:'8:00AM',
      to_time:'8:00AM-12:00PM',
      account_name:'ABC123',
      account_code:'abc123',
      los:'GOLD',
      order_type:'DELIVERY',
      // reference_1: "700214577",
      // reference_2: "123456",
      hawb: "123456",
      mawb: "123456",
    },{
      key:2,
      template_name:'ABC TEMPLATE',
      selected_warehouse:'TEST WAREHOUSE',
      from_time:'9:00AM',
      to_time:'9:00AM-01:00PM',
      account_name:'ABC456',
      account_code:'abc456',
      los:'PLATINUM',
      order_type:'PICKUP',
      // reference_1: "700214577",
      // reference_2: "123456",
      hawb: "123456",
      mawb: "123456",
    }
  ]);
  const [columns,SetColumns] = useState([
    {
      key: "template_name",
      title: "Name",
      dataIndex: "template_name",
    },
    {
      key: "warehouse",
      title: I18n.t("general.warehouse"),
      dataIndex: "selected_warehouse",
    },
    {
      key: "from_time",
      title: "From Time",
      dataIndex: "from_time",
    },
    {
      key: "to_time",
      title: "To Time",
      dataIndex: "to_time",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "los",
      title: "LOS",
      dataIndex: "los",
    },
    {
      key: "order_type",
      title: I18n.t("general.order_type"),
      dataIndex: "order_type",
    },
    // {
    //   key: "reference_1",
    //   title: `${I18n.t("order.reference")} 1`,
    //   dataIndex: "reference_1",
    // },
    // {
    //   key: "reference_2",
    //   title: `${I18n.t("order.reference")} 2`,
    //   dataIndex: "reference_2",
    // },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "recurringOrders_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
}
