/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import BaseList from "../BaseList";
import moment from "moment";
import {isEmpty, ellipseText } from "../../common/Common";
import reset_password from "../../../assets/images/reset-password.svg"
import {
  Icon,
  Popconfirm,
  Link,
  Switch,
  Tooltip,
  Popover,
  Col,
  Row,
} from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { checkServiceExistance } from '../../helpers/common';
import userStore from "../../stores/UserStore";
import active_mobile from "../../../assets/images/active_mobile.png";
import moreInfo from  "../../../assets/images/moreInfo.png"
import inactive_mobile from "../../../assets/images/inactive_mobile.png";
import AppConfig from "../../config/AppConfig";
import AssignDriverRate from "../driverRates/AssignDriverRate";
import DriverDeductions from "../deductions/DriverDeductions";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";

class UsersList extends BaseList {
  constructor(props) {
    super(props);
    const isAdmin = userStore.admin();
    const isDriver = userStore.driver();
    const { currentUser } = props?.userContext ? props.userContext : {};
    const currentUserId = currentUser?.id;
    const userColumns = [
      {
        key: "name",
        title: I18n.t("general.name"),
        dataIndex: "full_name",
        // sorter: (a, b) => compareString(a.full_name, b.full_name),
        ...AppConfig.sortingOptions,
      },
      {
        key: "mobile",
        title: I18n.t("general.mobile_number"),
        dataIndex: "mobile_number",
      },
    ];
    if (props.userType !== "driver") {
      userColumns.push({
        key: "email",
        title: I18n.t("general.email"),
        dataIndex: "email",
      });
      userColumns.push({
        key: "roles",
        dataIndex: "roles",
        title: I18n.t("general.role"),
        render: (data) => {
          return <span>{!isEmpty(data) ? data.join(", ") : ""}</span>;
        },
        className: 'text-capitalize ',
        ...AppConfig.sortingOptions,
      });
    }
    if (props.userType === "driver") {
      userColumns.push(
        {
          key: "employee_code",
          title: I18n.t("users.emp_code"),
          dataIndex: "employee_code",
          ...AppConfig.sortingOptions,
        },
        // {
        //   key: 'driver_rate',
        //   title: I18n.t('driver_pay.driver_rate'),
        //   render: (record) => {
        //     const driverRate = record.driver_rate && record.driver_rate.name ?
        //       record.driver_rate.name :
        //       '';
        //     return (
        //       <div>
        //         {driverRate ? <Tooltip title={driverRate}>{ellipseText(driverRate, 15)}</Tooltip> : '' }
        //       </div>
        //     );
        //   },
        // },
        {
          key: "fleet",
          title: I18n.t("users.truck"),
          render: (record) => (
            <Fragment>
              {!isEmpty(record.fleet_name) && record.fleet_name}
              {!isEmpty(record.fleet_type) && ` (${record.fleet_type})`}
            </Fragment>
          ),
        },
        {
          key: "warehouses",
          title: I18n.t("general.wh"),
          render: (record) => {
            const warehouses =
              record.warehouses && record.warehouses.length > 0
                ? record.warehouses.join(", ")
                : "";
            return (
              <div>
                {!isEmpty(warehouses) ? (
                  <Tooltip title={warehouses}>{ellipseText(warehouses, 25)}</Tooltip>
                ) : (
                  ""
                )}
              </div>
            );
          }
        },
        {
          key: "zones",
          title: I18n.t("menu.zones"),
          className:"noWrap",
          render: (record) => {
            const zones =
              record.zones && record.zones.length > 0
                ? record.zones.map((zone) => zone.name).join(", ")
                : "";
            return (             
                !isEmpty(zones) ? (
                  <Tooltip title={zones} placement="topLeft">{zones}</Tooltip>
                ) : (
                  ""
                )
            );
          },
        },
        {
          key: "mobile_registrations",
          title: I18n.t("general.mobile_details"),
          render: (id, record) => {
            return (
              <div>
                <Row>
                  <Col xs={24}>
                    { checkServiceExistance('UROTP') && <span className="paddingRight10">
                      <Popconfirm
                        placement="topRight"
                        title={I18n.t("messages.regenerate_otp")}
                        onConfirm={() => this.props.generateClick(record)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip
                          title={
                            <Fragment>
                              <Row>
                                <Col>
                                  {I18n.t("general.generate")}{" "}
                                  {I18n.t("general.otp")}
                                </Col>
                                <Col className="notes_content">
                                  <Row>
                                    <Col xs={24}>
                                      <span>{I18n.t("mobile_detail.latest_otp")} :</span>
                                      <span>
                                      {record.latest_otp
                                        ? record.latest_otp.code
                                        : "N/A"}
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Fragment>
                          }
                          placement="leftTop"
                        >
                          <Icon
                            type="check-circle"
                            theme="twoTone"
                            twoToneColor="#52c41a"
                          />
                        </Tooltip>
                      </Popconfirm>
                    </span>
                    }
                    {!isEmpty(record.mobile_registrations) && (
                      <Popover
                        title={I18n.t("general.mobile_details")}
                        content={
                          <div>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                                {I18n.t("mobile_detail.device_name")}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.mobile_registrations.device_name}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                                {I18n.t("mobile_detail.device_model")}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.mobile_registrations.device_model}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                                {I18n.t("mobile_detail.os_version")}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.mobile_registrations.os_version}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                                {I18n.t("mobile_detail.app_version_name")}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.mobile_registrations.app_version_name}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                                {I18n.t("mobile_detail.app_version_code")}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.mobile_registrations.app_version_code}
                              </Col>
                            </Row>
                          </div>
                        }
                        overlayStyle={{ width: 400 }}
                        placement="rightBottom"
                      >
                        {record.mobile_registrations.os_type}
                      </Popover>
                    )}
                  </Col>
                </Row>
              </div>
            );
          },
        },
        {
          key: "driver_details",
          title: "Driver Details",
          align:"center",
          render: (id, record) => {
            return (
              <div>
                <Row>
                  <Col xs={24}>
                    { checkServiceExistance('UROTP') && <span className="paddingRight10">
                      
                    <Popover
                        title= "Driver Details"
                        content={
                          <div>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.driver_sta')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.sta || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.sta_expiry_date')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.driver_sta_expiry_date ? moment(record.driver_sta_expiry_date).format(AppConfig.date_format): "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.licence_number')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.license_number || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.licence_expiry_date')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.license_expiry_date ? moment(record.license_expiry_date).format(AppConfig.date_format): "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.insurance_number')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.insurance_number || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.insurance_expiry_date')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.insurance_expiry_date ? moment(record.insurance_expiry_date).format(AppConfig.date_format): "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.driver_medical_number')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.medical_number || "NA"}
                              </Col>
                            </Row>
                            <Row>
                                <Col lg={9} md={9} sm={9} xs={9}>
                                {I18n.t('users.driver.medical_expiry_date')}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                :
                                </Col>
                                <Col lg={13} md={13} sm={13} xs={13}>
                                {record.medical_expiry_date ? moment(record.medical_expiry_date).format(AppConfig.date_format): "NA"}
                                </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.contract')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.contract || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.type_of_driver')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.type_of_driver || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.social_security_number')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.social_security_number || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.date_of_birth')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.date_of_birth ? moment(record.date_of_birth).format(AppConfig.date_format): "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.driver_start_date')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.driver_start_date ? moment(record.driver_start_date).format(AppConfig.date_format): "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.driver_termination_date')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.driver_termination_date ? moment(record.driver_termination_date).format(AppConfig.date_format): "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.notes')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.notes || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.emergency_name')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.emergency_name || "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.emergency_contact')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.emergency_contact ? `+${record.emergency_contact}` : "NA"}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={9} md={9} sm={9} xs={9}>
                              {I18n.t('users.driver.relation')}
                              </Col>
                              <Col lg={2} md={2} sm={2} xs={2}>
                                :
                              </Col>
                              <Col lg={13} md={13} sm={13} xs={13}>
                                {record.relation || "NA"}
                              </Col>
                            </Row>
                          </div>
                        }
                        overlayStyle={{ width: 500 }}
                        placement="rightBottom"
                      >
                          <img src={moreInfo} style={{ width: 15 }} />{" "}
                          </Popover>
                    </span>
                    }
                    
                  </Col>
                </Row>
              </div>
            );
          },
        },
        )
    }

    userColumns.push({
      key: "current_sign_in_at",
      title: I18n.t("users.driver.last_signed_at"),
      dataIndex: "current_sign_in_at",
      width: "200px",
      render: (data) => {
        return (
          <span>
            {data
              ? moment(data).format(
                  this.props.isMilitaryTime
                    ? AppConfig.milataryDateTimeFormat
                    : AppConfig.dateTimeFormat
                )
              : "NA"}
          </span>
        );
      },
      ...AppConfig.sortingOptions,
    });

    if (checkServiceExistance("RSU")) {
      userColumns.push({
        key: "active_inactive",
        title: I18n.t("general.active_inactive"),
        dataIndex: "id",
        render: (data, record) => (
          <Switch
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            checked={record.active}
            onChange={(e) => this.props.setStatus("active", e, data)}
            className={!record.active ? "switchComponent" : null}
          />
        ),
        align: "center",
        width: 80
      });
    }
    

    if (props.userType === "driver") {
      userColumns.push({
        key: "sign_in",
        title: I18n.t("general.signin"),
        dataIndex: "sign_in",
        render: (text) => (
          <div className={text ? "activeColor" : "inActiveColor"}>
            {text ? (
              <Tooltip title="Signed In">
                <img src={active_mobile} style={{ width: 13 }} />{" "}
              </Tooltip>
            ) : (
              <Tooltip title="Not yet Sign In">
                <img src={inactive_mobile} style={{ width: 13 }} />
              </Tooltip>
            )}
          </div>
        ),
        align: "center",
        // sorter: (a, b) => {
        //   if ((a.sign_in === b.sign_in)) {
        //     return 0;
        //   }
        //   if (a.sign_in) {
        //     return (-1);
        //   }
        //   return (1);
        // },
        // defaultSortOrder: 'ascend',
        // ...AppConfig.sortingOptions,
      });
      
      if (isAdmin) {
        userColumns.push({
          key: "notify",
          title: "",
          dataIndex: "id",
          render: (id, object) => (
            <div className="line-actions">
              <Link onClick={() => this.props.showNotifyModal(id, object)}>
                <Tooltip title={'Notify'}> <Icon type="notification" /></Tooltip>
              </Link>
            </div>
          ),
          width: 30
        });
      }
    }
    
    userColumns.push({
      key: 'actions',
      title: '',
      fixed: 'right',
      dataIndex: 'id',
      render: (id, record) => (
        <div className="line-actions">
          { checkServiceExistance('DRI') &&
            props.userType === "driver" ? (
            <Fragment>
              <AssignDriverRate driver={record} key={record.id} />
            </Fragment>
          ) : null}
          {checkServiceExistance('DMR') && props.userType === "driver" &&
          (
              <DriverDeductions currentDriver={record} key={record.id} deductions={this.props.deductions}/>
          )}
          {/* {props.userType === "user" ? */}
            <Fragment>
              <Popconfirm
                placement="topRight"
                title={I18n.t("users.users.confirm_send")}
                onConfirm={() => this.props.generatePasswordResetLink(record)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip
                  title={I18n.t("users.users.send_reset_password_link")}
                  placement="left"
                >
                  <Icon
                    component={() => (
                      <img style={{ height: "18px",marginTop: "-11px" }} src={reset_password} />
                    )}
                  />
                </Tooltip>
              </Popconfirm>
            </Fragment>
            {/* : null
          } */}
          {checkServiceExistance("UU") && (
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
            </Tooltip>
          )}
          {checkServiceExistance("UD") && id !== currentUserId  && (
            <Popconfirm
              placement="topRight"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={I18n.t("general.delete")}>
                <span><DeleteIcon/></span>
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      ),
      width: "130px",
    });
    this.columns = userColumns;
  }
}

export default UsersList;
