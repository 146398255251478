/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import AppConfig from '../config/AppConfig';

export const formatRoles = (roles) => {
    if(roles && _.isArray(roles)) {
        return roles.filter(Boolean).map(rec => {
            return  AppConfig.roles[rec];
        }).filter(Boolean).join(' ,');
    }
    return "";
}
