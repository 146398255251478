/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Card, Popover, Typography } from '../../common/UIComponents';
import StopScheduling from './StopScheduling';
import StopContact from './StopContact';


const StopDetails = ({
  stop, currentStop, handleEditStop, getCurrentStop,
}) => (
  <Card
    id={stop.location.name}
    title={stop.formatted_stop_name}
    className={`currentRouteStopDetails ${stop.id === currentStop.id ? 'currentActiveStop' : ''}`}
    size="small"
    onClick={() => getCurrentStop(stop.id)}
    extra={
      <Fragment>
        <Button
          type="default"
          size="small"
          shape="circle"
          icon="edit"
          className="marginRight5"
          onClick={() => handleEditStop(stop.id)}
        />
        {/* <Popconfirm
            placement="topRight"
            title={I18n.t('messages.cancel_confirm')}
            onConfirm={() => this.getCurrentStop(this.state.currentStop.id)}
            okText="Yes"
            cancelText="No"
            >
            <Button type="default" size={"small"} shape="circle" icon="delete" className="marginRight5" />
            </Popconfirm>
        */}
        {stop.stop_errors && stop.stop_errors.length > 0 &&
        <Popover
          title="Exception"
          content={
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Typography.Text>{stop.stop_errors.join(' ,')}</Typography.Text>
              </Col>
            </Row>
          }
        >
          <Button type="danger" size="small" shape="circle" icon="warning" className="marginRight5" />
        </Popover> }
        <Popover
          title="Contact Info"
          content={
            <div style={{ width: 400 }}><StopContact stop={stop} /></div>}
        >
          <Button type="primary" size="small" shape="circle" icon="idcard" className="marginRight5" />
        </Popover>

        {stop.geo_fence_exception &&
        <Popover
          title="Geo Fence Exception"
          content={
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Typography.Text>{stop.geo_fence_exception}</Typography.Text>
              </Col>
            </Row>
          }
        >
          <Button type="danger" size="small" shape="circle" icon="environment" className="marginRight5" />
        </Popover> }


      </Fragment>}
  >
    {/* <span style={{ float: "right", marginTop: "-35px" }}>{stop.status}</span> */}
    <Row />
    <StopScheduling stop={stop} />
  </Card>
);

export default StopDetails;

StopDetails.propTypes = {
  stop: PropTypes.shape().isRequired,
  currentStop: PropTypes.shape(),
  handleEditStop: PropTypes.func.isRequired,
  getCurrentStop: PropTypes.func.isRequired,
};

StopDetails.defaultProps = {
  currentStop: {},
  getCurrentStop: () => {},
};
