import React, { Fragment } from 'react';
import BaseList from '../BaseList';
import {  compareString, isEmpty, ellipseText, checkNegitive } from '../../common/Common';
import I18n from '../../common/I18n';
import { Icon, Input, Popconfirm, Popover, Tooltip } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { checkServiceExistance, formatByTimeConfig } from '../../helpers/common';
import SettingIcon from '../common/SettingIcon';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';
import { momentTime } from '../../helpers/date_functions';
import AppConfig from '../../config/AppConfig';
import CopyableContent from '../common/CopyableContent';

class UnpaidInvoiceLines extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    const columns = [
      // {
      //   key: 'account',
      //   title: I18n.t('general.account'),
      //   dataIndex: 'account_name',
      //   render: (text, data) => text ? ellipseText(text, 20, true) : ''
      // },
      {
        key: 'invoice_number',
        title: I18n.t('invoices.number'),
        dataIndex: 'invoice_number',
        render: (text, data) => <CopyableContent text={text} showTooltip/>
      },
      {
        key: 'customer_order_number',
        title: I18n.t('general.order'),
        dataIndex: 'customer_order_number',
        render: (text, data) => <CopyableContent text={text} showTooltip/>
      },
      {
        key: 'hawb',
        title: I18n.t('general.hawb'),
        dataIndex: 'hawb',
        render: (text, data) => <CopyableContent text={text} showTooltip/>
      },
      {
        key: 'mawb',
        title: I18n.t('general.mawb'),
        dataIndex: 'mawb',
        render: (text, data) => <CopyableContent text={text} showTooltip/>
        
      },
      {
        key: "invoice_date",
        title: I18n.t("invoices.invoice_date"),
        dataIndex: "invoice_date",
        render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : ""
      },
      {
        key: "shipment_date",
        title: I18n.t("batch_payments.shipment_date"),
        dataIndex: "shipment_date",
        render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : ""
      },
      {
        key: 'invoice_total_amount',
        title: I18n.t('invoices.amount'),
        dataIndex: 'invoice_total_amount',
        render: (text, data) => checkNegitive(text),
        align: 'right',
      },
      {
        key: 'invoice_balance_amount',
        title: I18n.t('batch_payments.invoice_balance_amount'),
        dataIndex: 'invoice_balance_amount',
        render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
        align: 'right',
      },
      {
        key: 'line_total_amount',
        title: I18n.t('batch_payments.line_total_amount'),
        dataIndex: 'line_total_amount',
        render: (text, data) => checkNegitive(text),
        align:'right',
      },
      {
        key: 'line_balance_amount',
        title: I18n.t('batch_payments.line_balance_amount'),
        dataIndex: 'line_balance_amount',
        render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
        align: 'right',
      },
      {
        key: 'pay_amount',
        title: I18n.t('batch_payments.pay_amount'),
        dataIndex: 'pay_amount',
        render: (text, data, index) => {
          const line_balance_amount = Number(data.balance_invoice_amount);
          const paid_amount = Number(data.paid_amount);
          const isExceeds = paid_amount > line_balance_amount;
          return (
            props.selectedKeys.includes(data.id) && line_balance_amount != 0 ?
            <Fragment>
            {/* {line_balance_amount}..{data.paid_amount} */}
            <Input 
              onChange={(e) => this.props.handlePaymentChange(index, 'paid_amount', e.target.value)} 
              value={data.paid_amount}
              style={{ width: 90, fontSize: 12 }}
              size="small"
              type="number"
              className={`${isExceeds ? 'errorBorder' : ''}`}
            />
             {isExceeds && <div className='fontSize10 textRed' >{'Exceeds with Bal.'}</div>}
            </Fragment>
            :
            (props.screen_name === "paid" ? 
            <span className='textBold'>{checkNegitive(text)}</span>
            :
            <span className='textBold anchor_cursor' onClick={() => props.showInput(data)}>{checkNegitive(text)}</span>)
            
          )
        },
        align: 'right',
        
      },
     
    ];
    if(props.screen_name === "paid"){
      columns.push({
        key: "paid_at",
        title: I18n.t("batch_payments.paid_at"),
        dataIndex: "created_at",
        render: (text, data) => text ? momentTime(AppConfig.dateFormat, text) : "",
        className: "paddingLeft5",
      })
    }
    if(props.showActions === true){
      columns.push({
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (id, data) => (
          <div className='line-actions'>
            { checkServiceExistance('ARBAD') &&
            <Tooltip title={I18n.t("general.delete")} >
             <Popconfirm
              placement="left"
              title={I18n.t('messages.delete_confirm')}
              onConfirm={() => props.deleteClick(data)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteIcon style={{ fontSize: 12 }} />
              &nbsp;&nbsp;&nbsp; 
            </Popconfirm>
            </Tooltip>
            }
          </div>),
         width: 50,
      })
    }

    this.columns = [...columns];
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };
}

export default UnpaidInvoiceLines;
