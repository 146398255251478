import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import BaseList from '../BaseList';
import I18n from '../../common/I18n';
import { Radio, Icon, Input, Select, Row, Col } from '../../common/UIComponents';

class DriverRateConfigurationList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    const titleData = [];
    const isAccessorial = this.props.displayType === "Accessorial";
    const decimalPoints = window.localStorage.getItem("round_off_decimals");
    const isAccountLevelEnabled = this.props.isAccountLevelEnabled;
    if (isAccessorial) {
      titleData.push({
        title: "S.No",
        key: "index",
        render: (text, record, index) => index + 1,
        width: 55,
      });
    }
    const otherData = [
      {
        key: "accessorial_name",
        title: (
          <div>
            {!isAccessorial ? "Charges" : I18n.t("driver_pay.accessorial_name")}
            <sup className="textRed">*</sup>
          </div>
        ),
        dataIndex: "accessorial_name",
        render: (text) => <div>{text}</div>,
        width: isAccessorial ? 250 : 305,
      },
      {
        key: "charge_type",
        title: (
          <div>
            {I18n.t("driver_pay.rate_type")}
            <sup className="textRed">*</sup>
          </div>
        ),
        dataIndex: "charge_type",
        render: (text, record) => (
          <>
            <Radio.Group
              onChange={(e) =>
                this.props.handleDriverRateInput(
                  "accessorial_charge_type",
                  e.target.value,
                  record.id,
                  isAccessorial ? "Accessorial" : record.accessorial_type
                )
              }
              value={record.accessorial_charge_type}
              disabled={this.props.isDisabled}
            >
              <Radio value="percentage">Percentage</Radio>
              <Radio value="flat">Flat</Radio>
              {record.accessorial_name === I18n.t('driver_rates.fuel_surcharge') && (
                <Radio value="nfc">NFC</Radio>
              )}
            </Radio.Group>
          </>
        ),
        width: 350,
      },
      {
        key: "charge_value",
        title: (
          <div>
            {I18n.t("driver_pay.rate_value")}
            <sup className="textRed">*</sup>
          </div>
        ),
        dataIndex: "charge_value",
        render: (text, record) => (
          
            record.accessorial_charge_type.toLowerCase() === "nfc" ?
            <Row type='flex'>
              <Col className='textBold'>Apply for: </Col>
              <Col>
              <Select
            value={record.apply_on || 'fuel'}                  
            onChange={(e) =>
              this.props.handleDriverRateInput(
                "apply_on", 
                e,
                record.id,
                isAccessorial ? "Accessorial" : record.accessorial_type
              )
            }
          style={{ width: 150, paddingLeft: 5  }}
          showSearch
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          size={"small"}
          disabled={this.props.isDisabled}
        >
          <Select.Option value="fuel">Fuel</Select.Option>
            <Select.Option value="driver_net">Driver Net</Select.Option>
          </Select>
              </Col>
            </Row>
            :
          <Input
            type="number"
            addonBefore={
              record.accessorial_charge_type === "flat" ? (
                <Icon type="dollar" />
              ) : null
            }
            addonAfter={
              record.accessorial_charge_type === "percentage" || record.accessorial_charge_type === "nfc" ? (
                <Icon type="percentage" />
              ) : null
            }
            value={record.accessorial_charge_value}
            onChange={(e) =>
              this.props.handleDriverRateInput(
                "accessorial_charge_value",
                e.target.value,
                record.id,
                isAccessorial ? "Accessorial" : record.accessorial_type
              )
            }
            min={0}
            precision={decimalPoints}
            defaultValue={0}
            size="small"
            disabled={this.props.isDisabled}
          />
        ),
        width: 200,
      },
    ];
   // ...otherData definition
if (isAccountLevelEnabled) {
  otherData.splice(otherData.length - 1, 0, {
    key: "eligible_percentage",
    title: I18n.t("driver_pay.eligible_percentage"),
    dataIndex: "eligible_driver_percentage",
    width: 120,
    render: (text, record) => (
      <Input
        type="number"
        addonAfter={<Icon type="percentage" />}
        value={record.eligible_driver_percentage}
        onChange={(e) =>
          this.props.handleDriverRateInput(
            "eligible_driver_percentage",
            e.target.value,
            record.id,
            isAccessorial ? "Accessorial" : record.accessorial_type
          )
        }
        min={0}
        precision={decimalPoints}
        defaultValue={0}
        size="small"
        disabled={this.props.isDisabled}
      />
    ),
  });
}

this.columns = [...titleData, ...otherData];
    this.columns = [...titleData, ...otherData];
  }
}

export default withRouter(DriverRateConfigurationList);
