import React, { Fragment, useEffect, useState, useContext, useRef } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Tooltip,
  Row,
  Col,
  Icon,
  Button,
  Radio,
  Skeleton,
  Select,
  Search,
  Dropdown,
  Spin,

} from "../../common/UIComponents";
import BaseModal from "../BaseModal";
import { momentTime } from "../../helpers/date_functions";
import FormErrors from "../common/FormErrors";
import SummaryViewComponent from "./SummaryView";

import { withRouter } from "react-router";
import { fetchAccount, fetchShortFormAccounts } from "../../api/Account";
import DetailsViewComponent from "./DetailsView";
import { getAccounts, removeSpecialCharacters, renderAlertMessage } from "../../helpers/common";
import AccountCodesFilter from "../../containers/Filters/AccountCodesFilter";
import userStore from "../../stores/UserStore";
import BaseSelect, { AccountSelect } from "../common/SelectDropdowns/BaseSelect";
import { WarehouseContext } from "../../context/WarehouseContext";
import { isEmpty } from "lodash";
import { alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";

const stopTypes = [
  { key: "SUMMARY", label: "Summary" },
  { key: "DETAILS", label: "Details" },
];
const listTypes = [
  { key: "unpaid", label: "Open" },
  { key: "paid", label: "Closed" },
];
const ARPayment = ({ history, match, location }) => {
  const orgId = userStore.getStateValue("selectedOrg");
  const originUrl = location?.state?.key ? location.state.key : ""
  const check_ach_no = location?.state?.check_ach_no ? location.state?.check_ach_no : ""
  const redirectUrl = location?.state?.redirectUrl ? location.state.redirectUrl : ""
  const warehouseFilter = useContext(WarehouseContext);
  

  const [accountId, setAccountId] = useState(
    match?.params?.accountId ? match.params.accountId : ""
  );
  const [showProgress, setShowProgress] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({});
  const [accounts, setAccounts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [categoryType, setCategoryType] = useState("SUMMARY");
  const [listType, setListType] = useState("unpaid");
  const [searchToken, setSearchToken] = useState(check_ach_no);
  const [checkAchNo, setCheckAchNo] = useState(check_ach_no);
  const [searchBy, setSearchBy] = useState(originUrl ? "CHECK" :'INVOICE');
  const [currentWhTimeZoneId, setCurrentWhTimeZoneId] = useState("")
  const whForFilter = [warehouseFilter.warehouses.map((whid) => whid.id)];
  const prevWarehouseFilter = useRef(warehouseFilter);
  const onSearch = (value) => {
    setSearchToken(value);
    setCheckAchNo(value);
  };

  useEffect(() => {
    getAccounts()
    getCurrentWhTimeZone();
  }, []);
 
  useEffect(() => {
    const { selectedWarehouses, warehouses, isFetchingWarehouses } = warehouseFilter;
    
    if (
      !_.isEqual(
        prevWarehouseFilter.current.selectedWarehouses,
        selectedWarehouses
      ) ||
      (!_.isEqual(prevWarehouseFilter.current.warehouses, warehouses) &&
        !isFetchingWarehouses)
    ) {
      getCurrentWhTimeZone();
    }

    // Update the previous state
    prevWarehouseFilter.current = warehouseFilter;

  }, [warehouseFilter.selectedWarehouses, warehouseFilter.warehouses, warehouseFilter.isFetchingWarehouses]);


  useEffect(() => {
    getAccounts();
  }, [warehouseFilter.warehouseWithAccounts]);

  const getCurrentWhTimeZone = () => {
    const { selectedWarehouses, warehouses = [] } = warehouseFilter;
    const selectedWhIds = Array.isArray(selectedWarehouses)
      ? selectedWarehouses
      : selectedWarehouses
      ? [selectedWarehouses]
      : null;

    const currentWhId = selectedWhIds[0]
    const currentWhTimeZoneId = warehouses?.length
      ? warehouses.find((wh) => wh.id === currentWhId).timeZoneId
      : "";
    setCurrentWhTimeZoneId(currentWhTimeZoneId);
  }

  const getAccounts = (
    orgId = "",
    whId = whForFilter,
    skip_wh = false
  ) => {
    setLoading(true);
    const accountsData = warehouseFilter.findMatchingAccounts(whId);
    setAccounts(accountsData || []);
    setLoading(false);
    // fetchShortFormAccounts(orgId, whId, skip_wh).then((result) => {
    //   if (result.success) {
    //     if (result.accounts.length > 0) {
    //       setAccounts(result.accounts);
    //     } else {
    //       setAccounts([]);
    //     }
    //     setLoading(false);
    //   } else {
    //     setLoading(false);
    //     renderAlertMessage(result.errors)
    //   }
    // });
  };
  useEffect(() => {
    if(accounts.length > 0 && accountId){
      getAccountDetails();
    }
  },[accounts]);

  useEffect(() => {
    if(accounts.length > 0){
      if(accountId){
        getAccountDetails();
      } else {
        setAccountId('')
        setCurrentAccount({})
      }
    }
  },[accountId]);

  const getAccountDetails = () => {
    const currentAcc = _.find(accounts, { id: accountId});
    setCurrentAccount(currentAcc ? currentAcc : {});
    // if(!currentAcc){
    //   setAccountId('')
    // }
    // fetchAccount(accountId).then((result) => {
    //   if (result.success) {
    //     const account = result.account || [];
    //     setCurrentAccount(account);
    //     setLoading(false);
    //   } else {
    //     alertMessage(result.errors[0], "error", 10);
    //     setAccounts([]);
    //     setLoading(false);
    //   }
    // });
    
  };

  const renderFilters = () => (
  
    <Row className="marginBottom10" gutter={8}>
      <Col md={6}>
        <span className="textBold">{I18n.t("general.account")}:</span>
        &nbsp;&nbsp;
        <AccountSelect
          onChange={(e) => setAccountId(e)}
          data={accounts}
          size="default"
          element="selectedAccounts"
          selectKey="id"
          selectValue="id"
          selectedCode="code"
          selectedValue="name"
          style={{ width: "75%" }}
          value={accountId}
          showSearch={true}
        />
       
      </Col>
      <Col md={6}>
        <span className="textBold">{I18n.t("general.view_by")}:</span>
        &nbsp;&nbsp;
        <Select
          value={categoryType}
          showSearch
          onChange={(e) => setCategoryType(e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{ width: "75%" }}
        >
          {stopTypes.filter( type => listType !== 'paid' || type.key !== 'SUMMARY').map((rec) => (
            <Select.Option key={rec.key} value={rec.key}>
              {rec.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col md={6}>
        <span className="textBold">{I18n.t("general.status")}:</span>
        &nbsp;&nbsp;
        <Select
          value={listType}
          showSearch
          onChange={(e) => {
            setListType(e)
            if(e === 'paid'){
              setCategoryType("DETAILS")
            }
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{ width: "80%" }}
        >
          
          {listTypes.map((rec) => (
            <Select.Option key={rec.key} value={rec.key}>
              {rec.label}
            </Select.Option>
            
          ))}
        </Select>
      </Col>
       
       <Col md={6} className="arSearch">
        <Search
          size="default"
          value={searchToken || ""}
          placeholder={
         searchBy === "CHECK" ? `${I18n.t(`general.search`)} ${I18n.t(
            "account_receivables.cheque"
          )}` : searchBy === "INVOICE" ? "Search Invoice No" :  I18n.t("order.filters.placeholder")
        }
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          
          onChange={(e) => {
            // clear url search params
            const filteredString = removeSpecialCharacters(e.target.value, ["\\", "/"]);
            setSearchToken(filteredString);
          }}

          style={{ width: "70%", textTransform: "uppercase",  }}
          className="textUpperCase"
           onSearch={onSearch}
          allowClear
          
          prefix={
            <Row
              type="flex"
              align="middle"
              className="invoiceSearchPrefixes"
              gutter={4}
            >
              
              <Col>
                <Select
                  size="small"
                  onChange={(e) => {
                    setSearchBy(e)
                  }}
                
                  value={searchBy}
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "1",
                  }}
              
                  //  defaultValue={searchBy === "Check" ? "Check" : searchBy === "Invoice" ? "Invoice" : "Order"}    
                >
                  <Select.Option key="Cheque" value="CHECK">
                    BY {I18n.t("general.cheque").toUpperCase()}
                  </Select.Option>
                  <Select.Option key="Invoice" value="INVOICE">
                    {I18n.t("general.by_invoice").toUpperCase()}
                  </Select.Option>
                  <Select.Option key="Order" value="ORDER">
                    {I18n.t("general.by_order").toUpperCase()}
                  </Select.Option>
                  <Select.Option key="HAWB" value="HAWB">
                    {/* {I18n.t("general.by_hawb").toUpperCase()} */}
                    BY HAWB
                  </Select.Option>
                  <Select.Option key="MAWB" value="MAWB">
                    {/* {I18n.t("general.by_mawb").toUpperCase()} */}
                    BY MAWB
                    </Select.Option>                  
                </Select>
              </Col>
            
        
        {/* <Col xs={6}>
          <span className="textBold">
            {I18n.t("account_receivables.cheque")}:
          </span>
          &nbsp;&nbsp;
          <Search
            size="default"
            value={token || ""}
            placeholder={`${I18n.t(`general.search`)} ${I18n.t(
              "account_receivables.cheque"
            )}`}
            onChange={(e) => {
              const filteredString = removeSpecialCharacters(e.target.value);
              setToken(filteredString);
            }}
            onSearch={onSearch}
            allowClear
            style={{ width: "80%" }}
          />
        </Col> */}
        {/* <Col className="textBold alignCenter">

          <Radio.Group
            value={categoryType}
            onChange={(e) => setCategoryType(e.target.value)}
            size={"small"}
          >
            {stopTypes.map((rec) => (
              <Radio key={rec.key} value={rec.key}>
                {rec.label}
              </Radio>
            ))}
          </Radio.Group>
        </Col> */}

      </Row>

    
          }
        />
      </Col>
</Row>
  );
  
  const renderScreen = () => {
    switch (categoryType) {
      case "SUMMARY":
        return <Fragment>{renderInvoices()}</Fragment>;
      case "DETAILS":
        return <Fragment> {renderOrders()}</Fragment>;
      default:
        return <Fragment />;
    }
  };

  const renderOrders = () => {
    return (
      <DetailsViewComponent
        categoryType={categoryType}
        account={currentAccount}
        org_id={orgId}
        listType={listType}
        check_ach_no={checkAchNo}
        searchBy={searchBy}
        currentWhTimeZoneId={currentWhTimeZoneId}
      />
    );
  };
  const renderInvoices = () => {
    return (
      <SummaryViewComponent
        categoryType={categoryType}
        account={currentAccount}
        org_id={orgId}
        listType={listType}
        check_ach_no={checkAchNo}
        searchBy={searchBy}
        currentWhTimeZoneId={currentWhTimeZoneId}
      />
    );
  };

  const renderForm = () => {
    return (
      <div>
        {
         accounts.length === 0 ? 
         <Skeleton />
         :
        <Fragment>
          {renderFilters()}
          {renderScreen()}
          {FormErrors(errors)}
        </Fragment>
        }
      </div>
    );
  };



  const handleGoBack = () => {
    const header = history.location.search;
    const searchParams = new URLSearchParams(header);
    const batchId = searchParams.get('batch') || '';
    const checkId = searchParams.get('check') || '';
    // if both are present
    if (_.isEmpty(batchId) && _.isEmpty(checkId)) {
      history.goBack();
      return;
    } else {
      history.push({
        pathname: `/remittances/${batchId}/${checkId}`,
      }); 
    }
   };

  const currentUrl = window.location.href.split("/").pop();
  const showGoback = (currentUrl !== 'open_invoices')

  return (
    <div className="content-outer">
      <div className="content">
        <Spin spinning={loading}>
          <Row className="page-header">
            <Col xs={12}>
              {showGoback && (
              <span onClick={handleGoBack}>
                <Icon type="arrow-left" style={{ cursor: "pointer" }} />
              </span>
              )}
              &nbsp;&nbsp;
              {currentAccount?.name ? `${currentAccount.name} ` : ""}
              {I18n.t("account_receivables.payments")}
            </Col>
          </Row>
          <div style={{ marginTop: "20px" }}>
            {/* {currentAccount?.id ? renderForm() : <Skeleton />} */}
            {renderForm()}
          </div>
        </Spin>
      </div>
    </div>
  );
};

ARPayment.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(ARPayment);