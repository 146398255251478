import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from '../../../common/UIComponents';
import I18n from '../../../common/I18n';

class ZoneForm extends Component {
  constructor(props) {
    super(props);
    this.state = { inProgress: false, errors: '' };
  }

  render() {
    return (
      <div className="zone-form" style={{ width: 240, right: 0, top: 0 }}>
        {
          false && this.props.currentZone.id ?
            <Row gutter={8}>
              <Col span={24} className="text-right">
                <Button className="zone-redraw" disabled={this.props.onDraw} onClick={this.props.handleReDraw}>
                  {I18n.t('zones.redraw')}
                </Button>
              </Col>
            </Row> :
            <Fragment />
        }
        <Row>
          <Col xs={24} id="zone-Map-Buttons" className="alignCenter">
            <Button size="small" className="zone-redraw" disabled={this.props.onDraw} onClick={this.props.handleReDraw} >
              {I18n.t('zones.redraw')}
            </Button>&nbsp;&nbsp;

            {false &&
              <Fragment>
                <Button size="small" type="danger" disabled={this.state.inProgress} onClick={this.props.handleOnClose} >
                  {I18n.t('general.cancel')}
                </Button>&nbsp;&nbsp;
              </Fragment>
            }

            <Button
              size="small"
              type="primary"
              disabled={this.props.currentZone && this.props.currentZone.geoFenceGeometryData && this.props.currentZone.geoFenceGeometryData.length === 0 }
              loading={this.state.inProgress}
              onClick={this.props.handleOnSave}
            >
              {I18n.t('delivery_zones.get_zipcodes')}
            </Button>&nbsp;&nbsp;


            {/* FormButtons(this.state.inProgress, this.props.handleOnSave, this.props.handleOnClose) */}
          </Col>
        </Row>


      </div>
    );
  }
}

ZoneForm.propTypes = {
  currentZone: PropTypes.shape().isRequired,
  zoneName: PropTypes.string.isRequired,
  onDraw: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  handleReDraw: PropTypes.func.isRequired,
};

ZoneForm.defaultPropTypes = {
  zone: {},
  isNew: true,
};

export default ZoneForm;
