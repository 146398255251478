import React, {useContext}  from 'react'
import BaseList from '../BaseList'
import _ from 'lodash';
import { Icon, Popconfirm, Tooltip } from '../../common/UIComponents';
import {isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import moment from 'moment';
import { checkServiceExistance, formatByTimeConfig } from '../../helpers/common';
// import { OrganizationSettingsContext } from '../../context/OrganizationSettings';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';

import { withRouter } from 'react-router-dom'
import { OrgContext } from '../../context/OrgContext';

//V-TODO: translate
//Location name, Address, operation timings, contact name (first_name + ” ” + last_name), mobile, email. (edited) 

class PredefindStopsList extends BaseList {
    // static contextType = OrganizationSettingsContext;

      componentDidMount(){
        this.setState({ organizationSettings: this.props.organizationSettings });
      }

      componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
          this.setState({ organizationSettings: this.props.organizationSettings });
        }
      }

    constructor(){
        super();
        this.state = { organizationSettings: {} };
        this.columns = [
            {
                key: "name",
                title: "Location Name",
                dataIndex: "name",
                className: "noWrap",
                render: (data) => (
                  <Tooltip title={data} > {data}</Tooltip>
                ),
                width: 150,
                sorter: true,

                sortDirections: ["ascend", "descend", "ascend"],
            },
            {
                key: "location_code",
                title: "Code",
                dataIndex: 'location_code',
                width: 100,
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
            },
            {
                key: 'l_address',
                title: I18n.t('general.address'),
                render: (data) => {
                    const address = _.values(data.l_address).splice(1, 6).filter(Boolean).join();
                    return (
                        <span> {address}</span>
                    );
                },
            },
            {
                key: 'operationTimings',
                title: I18n.t('predefined_stops.operationTimings'),
                render: data => {
                    const  organizationSettings  = this.state;
                    const startTime = moment(data.start_time).isValid() ? formatByTimeConfig(moment(data.start_time) , organizationSettings['is_military_time'] ,  "HH:mm" , "hh:mm A") : 'N/A';
                    const endTime = moment(data.end_time).isValid() ? formatByTimeConfig(moment(data.end_time) , organizationSettings['is_military_time'] ,  "HH:mm" , "hh:mm A") : "N/A";
                    return (
                        <span>{`${startTime} - ${endTime}`}</span>
                    );
                },
                width: 200
            },
            {
                key: "contactName",
                title: "Contact Name",
                className: "noWrap",
                render: data => (
                    <Tooltip title={`${data.first_name || ''} ${data.last_name || ''}`} ><span>
                        {!isEmpty(data.first_name) && `${data.first_name}`}
                        {!isEmpty(data.last_name) && ` ${data.last_name}`}
                    </span></Tooltip> 
                ),
                width: 150
            },
            {
                key: 'phone',
                title: I18n.t('general.phone'),
                dataIndex: 'phone',
                width: 150
            },
            {
                key: "email",
                title: I18n.t("general.email"),
                dataIndex: "email",
                render: (data) => <Tooltip title={data}>{data}</Tooltip>,
                width: 150,
                className: "noWrap",
              },
            {
                key: 'actions',
                title: '',
                dataIndex: 'id',
                fixed: "right",
                render: id => (
                    <div className='line-actions'>
                        {
                            checkServiceExistance('PDSU') &&
                            <Tooltip title={ I18n.t("general.edit")}> 
                                <span><EditIcon handleClick={() => this.props.onEdit(id)} /> </span>
                            </Tooltip> 

                        }
                        {   checkServiceExistance('PDSD') &&
                        <Tooltip title={ I18n.t("general.delete")}> 
                        <Popconfirm
                            placement="left"
                            title={I18n.t('messages.delete_confirm')}
                            onConfirm={() => this.props.onDelete(id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <span><DeleteIcon/></span>
                        </Popconfirm>
                        </Tooltip>
                        }
                    </div>
                ),
                width: '95px',
            },
        ];
    }
}

const PredefindStopsListComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <PredefindStopsList
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default PredefindStopsListComponent;


// name code address phone email
