import React, { useState } from 'react';
import { Table, Input, InputNumber, Form, Icon, Popconfirm, Select, Button, Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types'
import _ from 'lodash';
import EditableTable from '../../common/EditableTable';
import { getAccounts } from '../../helpers/common';
import { v4 as uuidv4 } from 'uuid';
import { CarrierIntegrationApi } from '../../api/IntegrationApi';
import { alertMessage } from '../../common/Common';
import FormErrors from '../common/FormErrors';
const { Option } = Select;

const InboundSettingscolumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    isEditable: true,
    width: '25%'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    isEditable: true,
    width: '25%'
  },
];

class CarrierIntegration extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      outboundSettingsData: [],
      inboundSettingsData: [],
      accountsList: [],
      isSubmitting: false,
      sendStatusUpdate: false,
      formerrors: []
    };
  }

  componentDidMount () {
    const { activeIntegrationId, integrationsData } = this.props;
    if (activeIntegrationId) {
      const activeIntegration = integrationsData.find(integration => integration.id === activeIntegrationId);
      this.fetchAccountsList(activeIntegration.organization_id);
      this.props.form.setFieldsValue({
        orgId: activeIntegration.organization_id,
        accountId: activeIntegration.account_id,
        integration_code: activeIntegration.integration_code,
        integration_type : activeIntegration.integration_type,
        carrier_edi_code: activeIntegration.carrier_edi_code,
        shipper_edi_code: activeIntegration.shipper_edi_code,
        send_status_update: activeIntegration.send_status_update || false
      });

      setTimeout(() => {
        if (activeIntegration.integration_type === 'EDI') {
          this.props.form.setFieldsValue({
            carrier_code: activeIntegration.carrier_code,
            shipper_code: activeIntegration.shipper_code
          });
        }
      }, 100);
      this.setState({
        inboundSettingsData: activeIntegration?.inbound_settings || [],
        outboundSettingsData: activeIntegration?.outbound_settings || [],
        sendStatusUpdate: activeIntegration.send_status_update || false
      });
    }
    if(this.props.selectedOrg){
      this.props.form.setFieldsValue({
        orgId: this.props.selectedOrg,
      });
    }
    if(this.props.selectedAccount){
      this.fetchAccountsList(this.props.selectedOrg);
      this.props.form.setFieldsValue({
        accountId: this.props.selectedAccount,
      });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { orgId, accountId, integration_code, shipper_code, carrier_code , integration_type,carrier_edi_code,shipper_edi_code} = values;
        const { inboundSettingsData, outboundSettingsData, sendStatusUpdate } = this.state;
        let payload = {
          organization_id: orgId,
          account_id: accountId,
          integration_code: String(integration_code).toUpperCase(),
          inbound_settings: inboundSettingsData.map(({ name, ...rest }) => ({
            key: name, ...rest,
          })),
          outbound_settings: outboundSettingsData.map(({ name, ...rest }) => ({
            key: name, ...rest,
          })),
          integration_type : String(integration_type).toUpperCase(),
          shipper_edi_code: shipper_edi_code ? String(shipper_edi_code).toUpperCase() : "",
          carrier_edi_code: carrier_edi_code ? String(carrier_edi_code).toUpperCase() : "",
          send_status_update: sendStatusUpdate
        };

        if (integration_type === 'EDI') {
          payload = {
            ...payload,
            shipper_code: String(shipper_code).toUpperCase(),
            carrier_code: String(carrier_code).toUpperCase(),
          };
        }
        try {
          this.setState({ isSubmitting: true });
          const response = await CarrierIntegrationApi.createIntegrationConfig(payload);
          // const response = this.props.activeIntegrationId ? await CarrierIntegrationApi.updateIntegrationConfig(payload) : await CarrierIntegrationApi.createIntegrationConfig(payload);
          if(response.success){
            this.props.onSuccess();
          }else{
            this.setState({ formerrors : response.errors });
          }
        }
        catch (error) {
          alertMessage(error, "error", 5);
        }
        finally {
          this.setState({ isSubmitting: false });
        }
      }
    });
  };


  fetchAccountsList = (orgId) => {
      getAccounts(orgId, null , true).then((accountsList) => {
        this.setState({ accountsList });
      });
  };

  handleChange = (element, value) => {
    if (element === "orgId") {
      const previousValues = this.props.form.getFieldsValue();
      if (previousValues.accountId) {
        this.props.form.setFieldsValue({ accountId: undefined });
      }
      if (previousValues.integration_code) {
        this.props.form.setFieldsValue({ integration_code: undefined });
      }
      if (previousValues.integration_type) {
        this.props.form.setFieldsValue({ integration_type: undefined });
      }
      this.fetchAccountsList(value);
    } else if (element === "inboundSettings" || element === "outboundSettings") {
      if (value) { 
        this.setState({
          [element + "Data"]: value.map(({ name, ...rest }) => ({
            key: name, ...rest,
          })),
        });
      }
    } else if (element === "send_status_update") {
      this.setState({ sendStatusUpdate: value });
    }
  };


  render () {
    const { getFieldDecorator } = this.props.form;
    const { organizations = [] } = this.props;

    return (
      <div className='customForm'>
      <Form>
        <Row gutter={ 16 }>
          <Col span={ 6 }>
            <Form.Item label="Organization">
              { getFieldDecorator('orgId', {
                rules: [ { required: true, message: 'Please select an organization' } ],
              })(
                <Select onChange={ (value) => this.handleChange("orgId", value) } placeholder="Select an organization">
                  { organizations.map(org => (
                    <Option value={ org.id }>{ org.name }</Option>
                  )) }
                </Select>
              ) }
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item label="Account">
              { getFieldDecorator('accountId', {
                rules: [ { required: true, message: 'Please select an account' } ],
              })(
                <Select placeholder="Select an account">
                  { this.state.accountsList.map(account => (
                    <Option value={ account.id }>{ account.name }</Option>
                  )) }
                </Select>,
              ) }
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item label="Integration Type" name="integration_type">
              { getFieldDecorator('integration_type', {
                rules: [ { required: true, message: 'Please select an integration type' } ],
              })(
                <Select placeholder="Select an integration type">
                  {["EDI", "API" , 'FTP'].map(integration_type => (
                    <Option value={ integration_type }>{ integration_type }</Option>
                  ))}
                </Select>,
              ) }
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item label="Integration Code" name="integration_code">
              { getFieldDecorator('integration_code', {
                rules: [ { required: true, message: 'Please select an integration code' } ],
              })(
               <Input placeholder="Select an integration code" onChange={ (e) => e.target.value = e.target.value.toUpperCase() } />,
              ) }
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={16}>
          {this.props.form.getFieldValue('integration_type') === 'EDI' && (
            <>
              <Col span={6}>
                <Form.Item label="Carrier Code" name="carrierCode">
                  {getFieldDecorator('carrier_code', {
                    rules: [{ required: true, message: 'Please Enter valid Text' }],
                  })(
                    <Input style={{ textTransform: 'uppercase' }} />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Shipper Code" name="shipperCode">
                  {getFieldDecorator('shipper_code', {
                    rules: [{ required: true, message: 'Please Enter valid Text' }],
                  })(
                    <Input style={{ textTransform: 'uppercase' }} />
                  )}
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={6}>
            <Form.Item label="Carrier Edi Code" name="carrierediCode">
              {getFieldDecorator('carrier_edi_code', {
                rules: [{ required: false, message: 'Please Enter valid Text' }],
              })(
                <Input style={{ textTransform: 'uppercase' }} />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Shipper Edi Code" name="shipperEdiCode">
              {getFieldDecorator('shipper_edi_code', {
                rules: [{ required: false, message: 'Please Enter valid Text' }],
              })(
                <Input style={{ textTransform: 'uppercase' }} />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className='marginTop10 marginBottom10'>
          <Col span={6} className='fontWeight500'>
            <Checkbox
              checked={this.state.sendStatusUpdate}
              onChange={(e) => this.handleChange("send_status_update", e.target.checked)}
            >
              Send Status Update
            </Checkbox>
          </Col>
        </Row>
        <Form.Item label="Inbound Settings" name="inboundSettings" className="ant-form-item-required">
          <EditableTable
            columns={ InboundSettingscolumns } data={ this.state.inboundSettingsData.map(i => {
            return { ...i, name: i.key, key: uuidv4() };
          }) } isEditing={ this.props.activeIntegrationId } onSave={ (tableData) => this.handleChange("inboundSettings", tableData) } showCancelBtn={false} />
        </Form.Item>
        <Form.Item label="Outbound Settings" name="outboundSettings" className="ant-form-item-required">
          <EditableTable columns={ InboundSettingscolumns } data={ this.state.outboundSettingsData.map(i => { return { ...i, name: i.key, key: uuidv4() }; }) } isEditing={ this.props.activeIntegrationId }
            onSave={ (tableData) => this.handleChange("outboundSettings", tableData) } showCancelBtn={false} />
        </Form.Item>
        <Row>{FormErrors(this.state.formerrors )}</Row>
        <Form.Item style={ { textAlign: 'center' } }>
          <Button type="danger" disabled={ this.state.isSubmitting } onClick={ this.props.onCancel } icon="close">
            Cancel
          </Button>
          <Button type="primary" style={ { marginLeft: 8 } } loading={ this.state.isSubmitting } onClick={ this.handleSubmit } icon="save">
            Submit
          </Button>
        </Form.Item>
      </Form>
      </div>
    );
  }
}

const CarrierIntegrationForm = Form.create({ name: 'carrier_integration' })(CarrierIntegration);

export default CarrierIntegrationForm;

CarrierIntegrationForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  activeIntegrationId: PropTypes.string,
  organizations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }))
};

CarrierIntegrationForm.defaultProps = {
  activeIntegrationId: null,
  organizations: [],
  onSuccess: () => {},
  onCancel: () => {}
};

