/* eslint-disable react/sort-comp */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {
  Col,
  Input,
  FormItem,
  Row,
  DatePicker,
  Select,
  Radio,
  Button,
} from '../../common/UIComponents'
import I18n from "../../common/I18n";
import AppConfig from '../../config/AppConfig'
import { getRecoveryStatusIcon } from '../../helpers/recovery'
import ZonesList from '../../containers/ZonesList'
import BaseSelect from '../common/SelectDropdowns/BaseSelect'

const { Search } = Input
const { RangePicker } = DatePicker
const { RadioGroup } = Radio.Group

const RecoveryFilter = (props) => {
  const {
    filterPlaceHolder,
    setFilterPlaceHolder,
    onSearch,
    history,
    resetFilter,
    warehouses,
    setSelectedWH,
    accounts,
    setSelectedAccount,
    showScheduleOption = true,
    elementSize = 'small',
    showZones = true,
    freightForwarders = [],
    defaultFilter,
    showDateFilter= true,
  } = props
  const [wHLoading, setWHLoading] = useState(false)
  const [filter, setFilter] = useState({...defaultFilter});

  useEffect(() => {
    setFilter({...filterPlaceHolder});
  }, [filterPlaceHolder])

  const renderWHList = () => (
    <FormItem label={I18n.t('location.location')}>
      <Select
        value={filter.warehouse_id}
        showSearch
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        loading={wHLoading}
        onChange={(e) => {
          setFilter({...filter, warehouse_id: e})
        }}
      >
        <Select.Option key={'WH_ALL'} value={''}>
        -- All --
        </Select.Option>
        {warehouses.map((warehouse) => (
          <Select.Option key={warehouse.location_code} value={warehouse.id}>
           {warehouse.name}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  )
  const renderAccountsList = () => (
    <FormItem label={I18n.t('general.account')}>
      <Select
        value={filter.account_code}
        showSearch
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        loading={wHLoading}
        onChange={(e) => {
          setFilter({...filter, account_code: e})
        }}
      >
        <Select.Option key={'ACCOUNT_ALL'} value={''}>
        -- All --
        </Select.Option>
        {accounts?.map((account) => (
          <Select.Option key={account.code} value={account.code}>{`${account.name} (${account.code})`}</Select.Option>
        ))}
      </Select>
    </FormItem>
  )

  const renderFreightForwarders = () => (
    <FormItem label={I18n.t('location.freight_forwarder')}>
      <BaseSelect
        data={freightForwarders}
        value={
          filter.freight_forwarder
        }
        onChange={(e) => {
          setFilter({
            ...filter,
            freight_forwarder: e,
          })
        }}
        selectKey={'id'}
        selectValue={'id'}
        selectedValue={'name'}
        style={{ width: '100%'}}
        showSearch={true}
      />
    </FormItem>
  )
  

  const { dateSearchBy, dateSearchValue } = filter
  const { recoveryStatuses } = AppConfig
  return (
    <div className="padding10 recoveryFilter">
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        {/* <Col md={24}>{renderWHList()}</Col> */}
        <Col md={24}>{renderFreightForwarders()}</Col>
        
        {
          showZones!==false &&
          <Col md={24}>
            <FormItem label={I18n.t("general.zone")}>
              <ZonesList
                handleFilterPlaceHolderChange={(element, value) => 
                  setFilter({
                  ...filter,
                  [element]: value,
                })}
                zone_ids={filter.zone_ids}
                size={elementSize}
              />
            </FormItem>
          </Col>
        }
        <Col md={24}>{renderAccountsList()}</Col>
        { showDateFilter && <Col md={24}>
          <FormItem label={'Date Search'}>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              gutter={16}
            >
              <Col span={9}>
                <Select
                  size="small"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      dateSearchBy: e,
                      dateSearchValue: {
                        fromDate: moment(),
                        toDate: moment().add(1, 'week'),
                      },
                    })
                  }}
                  className="recoveryFilter__dateSearchBy"
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '1',
                  }}
                  defaultValue={
                    dateSearchBy === 'recovery_eta'
                      ? I18n.t('recoveries.recovery_date')
                      : dateSearchBy === 'recovery_dead_line'
                      ? I18n.t('recoveries.dead_line')
                      : null
                  }
                  value={filter.dateSearchBy}
                  
                >
                  <Select.Option key="recovery_date" value="recovery_eta">
                    Recovery Date
                  </Select.Option>
                  {/* <Select.Option
                    key="recovery_dead_line"
                    value="recovery_dead_line"
                  >
                    {I18n.t('recoveries.deadline')}
                  </Select.Option> */}
                </Select>
              </Col>
              <Col span={15}>

                <RangePicker
                  defaultValue={[moment().startOf('month'), moment()]}
                  value={[filter.dateSearchValue.fromDate, filter.dateSearchValue.toDate]}
                  ranges={AppConfig.dateRanges}
                  size="small"
                  format={AppConfig.dateFormat}
                  onChange={(dates, dateStrings) =>
                   !(dateStrings[0] !== '' && dateStrings[1] !== '') ? 
                   setFilter({
                      ...filter,
                      dateSearchValue: { fromDate: moment(), toDate: moment().add(6, "day") },
                   })
                  :
                    setFilter({
                      ...filter,
                      dateSearchValue: { fromDate: dates[0], toDate: dates[1] },
                    })
                  }
                />
              </Col>
            </Row>
          </FormItem>
        </Col>
        }
       {/* <Col md={24}>
          <FormItem label={I18n.t('general.sort_by')}>
            <Radio.Group
              onChange={(e) =>
                setFilter({
                  ...filter,
                  sort_by: e.target.value,
                })
              }
              value={filter.sort_by}
              size={elementSize}
            >
              <Radio value="none">{I18n.t('order.filters.sortBy.none')}</Radio>
              <Radio value="city">{I18n.t('order.filters.sortBy.city')}</Radio>
              <Radio value="account_code">
                {I18n.t('order.filters.sortBy.account_code')}
              </Radio>
            </Radio.Group>
          </FormItem>
        </Col>{' '}*/}
        { showDateFilter && showScheduleOption && dateSearchBy === 'recovery_eta' && 
          <Col md={24}>
            <FormItem label={I18n.t("recoveries.schedule_times.label")}>
              <Radio.Group
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    [I18n.t("recoveries.schedule_times.type")]: e.target.value,
                  })
                }
                value={filter[I18n.t("recoveries.schedule_times.type")]}
                size={elementSize}
              >
                <Radio
                  value={I18n.t('recoveries.schedule_times.scheduled_key')}
                >
                  {I18n.t('recoveries.schedule_times.SCHEDULED_label')}
                </Radio>
                <Radio
                  value={I18n.t('recoveries.schedule_times.unscheduled_key')}
                >
                  {I18n.t('recoveries.schedule_times.UNSCHEDULED_label')}
                </Radio>
                <Radio value={I18n.t('recoveries.schedule_times.both_key')}>
                  {I18n.t('general.both')}
                </Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        }
        {/* <Col md={24}>
          <FormItem label={'Status'}>
            <Select
              style={{ width: '100%' }}
              value={filter.currentStatus}
              onChange={(value) => {
                setFilter({ ...filter, currentStatus: value })
              }}
            >
              <Select.Option
                value={''}
                key={'ALL'}
                className="selectOptionsWithIcon"
              >
                {I18n.t('general.all')}
              </Select.Option>
              {recoveryStatuses.map(({ key, text }) => (
                <Select.Option
                  value={key}
                  key={key}
                  className="selectOptionsWithIcon"
                >
                  <div>
                    {text} &nbsp;&nbsp;{getRecoveryStatusIcon(key)}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col> */}
        <Row type="flex" justify="center" gutter={4}>
          <Col>
            <Button type="danger" onClick={resetFilter} icon="redo">
              {I18n.t('general.reset')}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                // onSearch(filter)
                setFilterPlaceHolder({...filter}, () => {
                  onSearch({...filter});
                });
              }}
              type="primary"
              icon="search"
            >
              {I18n.t('general.search')}
            </Button>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

RecoveryFilter.propTypes = {}
RecoveryFilter.defaultProps = {}
export default withRouter(RecoveryFilter)
