import React from 'react';
import { Button, Col, Row } from '../../common/UIComponents';
import I18n from '../../common/I18n';
const buttonStyles = {
  layout: {
    marginTop: 15,
  },
  button: {
    width: 125,
    height: 35,
    borderRadius: 25,
    marginLeft: 20,
  },
  buttonCancel: {
    marginLeft: 15,
    width: 120,
    height: 35,
    color: '#1271c2',
    borderRadius: 25,
  },
};

const FormButtons = (
  isInProgress,
  saveCallback,
  cancelCallback = null,
  isCancelVisible = true,
  isSaveVisible = true,
  size = 'default',
  saveText = I18n.t('general.save'),
  cancelText = I18n.t('general.cancel'),
  buttonStyles = {},
  className = 'text-center',
  cancelIcon = 'close',
  saveIcon = 'save'
) => {

  const mergedButtonStyles = {
    layout: {
      marginTop: 15,
    },
    button: {
      width: 120,
      height: 35,
      borderRadius: 25,
      marginLeft: 20,
      ...buttonStyles?.button, // Merge with the passed button styles
    },
    buttonCancel: {
      marginLeft: 15,
      width: 120,
      height: 35,
      color: "#1271c2",
      borderRadius: 25,
      ...buttonStyles?.buttonCancel, // Merge with the passed buttonCancel styles
    },
  };

  return (
  <Row style={mergedButtonStyles.layout}>
    <Col xs={24} className={className}>
      {isCancelVisible &&
        <Button size={size} disabled={isInProgress} onClick={cancelCallback} style={mergedButtonStyles.buttonCancel} icon={cancelIcon}>
          {cancelText}
        </Button>
      }
      {isSaveVisible &&
        <Button size={size} type="primary" loading={isInProgress} onClick={saveCallback} style={mergedButtonStyles.button} icon={saveIcon}>
          {saveText}
        </Button>
      }
    </Col>
  </Row>)
}

export default FormButtons;
