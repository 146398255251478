import _ from "lodash";
import React, { Fragment } from "react";
import I18n from "../../common/I18n";
import { Alert, Modal, Row, Col } from "../../common/UIComponents";

const Errors = (errors, showHeading = false) => (
  <div>
    {errors
      .filter((alert) => alert.orders && alert.orders.length > 0)
      .map((alert) => (
        <Fragment>
          {alert.displayName && <h4>{alert.displayName}</h4>}
          <Row>
            <Col className="textBold">{I18n.t(`messages.${alert.type}`)} :</Col>
            <Col className="paddingLeft15">{alert.orders.join(", ")}</Col>
          </Row>
        </Fragment>
      ))}
  </div>
);

export const findRouteErrors = (routeErrors = []) => {
  const errors  = routeErrors.filter(
      (alert) => alert.orders && alert.orders.length > 0
  );
  return errors;
}

const RouteProcessedErrors = (errors, title = true, showType = "alert") => {
  if(showType === "alert") {
    let routeErrors = errors || [];
    routeErrors = findRouteErrors(routeErrors);
    if (routeErrors.length < 1) return null;
    return (
      <Alert
        style={{ marginBottom: 10, marginTop: 10 , height: "15vh", overflow: "scroll"}} 
        message={title}
        description={Errors(routeErrors)}
        type="error"
      />
    )
  } else {
    let routeErrors = errors || [];
    const errOrders = _.flatten(routeErrors.map(err => err.unprocessed_orders)).map(err => err.orders);
    const hasErrOrders = _.compact(_.flattenDeep(errOrders));
    if (hasErrOrders.length < 1) return null;
    return(
      Modal.warning({
      title,
      content: <div>
        {
          routeErrors.map(err => 
          {
           const currentErrors = findRouteErrors(err.unprocessed_orders);
           return currentErrors.length > 0 ?
           (<div className="marginTop10">
            <h4>{err.displayName}</h4>
            <div className="paddingLeft10">{Errors(currentErrors)}</div>
          </div>)
          :
          <Fragment />
          })
        }
      </div>,
      width: "60%",
      onOk() {},
      
     }));
  }
};

export default RouteProcessedErrors;
