import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import {
  Icon, Tooltip,
} from '../../common/UIComponents'
import I18n from '../../common/I18n'
import BaseModal from '../BaseModal'
import DriverDRForm from './DriverDRForm'


class AssignDriverRate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      driver: this.props.driver,
      account: this.props.account,
      showForm: false,
    }
  }

  showAssignmentForm = () => {
    this.setState({
      showForm: true,
      driverRates: [],
    })
  }

  handleOnModalClose = () => {
     this.setState({
      showForm: false,
      driverRates: [],
    }) 
  }

  renderFormModal = () => {
    const title = `${I18n.t(`driver_pay.driver_rate`)} for ${this.state.driver.employee_code}`;
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        style={{ top: 30 }}
        width="60%"
        maskClosable={false}
      >
        <div>
           <DriverDRForm
            driver={this.state.driver}
            />
        </div>
      </BaseModal>
    )
  }
  
  render() {
    return (
      <React.Fragment>
        <Tooltip title={"Driver Rates"}>
        <Icon type="dollar" onClick={this.showAssignmentForm} style={{color:"#d48806"}} />
        </Tooltip>
        {
            this.state.showForm &&
            this.renderFormModal()
        }
      </React.Fragment>
    )
  }
}

export default withRouter(AssignDriverRate)
