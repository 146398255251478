import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import RecoveryComponent from "../../../containers/Recovery";
import moment from "moment";

const Recoveries = ({ route, warehouses, warehouse_id, closeSearchOrders }) => {
  const startTime = route?.r_scheduled_start_datetime
    ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    : moment();
  const endTime = route?.r_scheduled_start_datetime
    ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    : moment();

  return (
    <Fragment>
      <RecoveryComponent
        actionType={"currentRoute"}
        reqFrom={"dispatch"}
        startTime={startTime}
        endTime={endTime}
        route={route}
        closeSearchOrders={() =>
          closeSearchOrders(true, route.id, "currentRoute")
        }
        scheduled_type="SCHEDULED"
        showActionExceedField={true}
      />
    </Fragment>
  );
};

Recoveries.propTypes = {
  route: PropTypes.shape().isRequired,
  closeSearchOrders: PropTypes.func.isRequired,
};

Recoveries.defaultProps = {
  route: {},
  closeSearchOrders: () => {},
};

export default Recoveries;
