/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { notification, Table, Tabs } from 'antd'
import _, { get, set } from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Col,
  Drawer,
  Icon,
  Row,
  Search,
  Dropdown,
} from '../common/UIComponents'
import {
  alertMessage,
  isEmpty,
  getFileType,
} from '../common/Common'
import I18n from '../common/I18n'
import AppConfig from '../config/AppConfig'
import QuotesList from '../components/quotes/QuotesList'
import QuotesFilter from '../components/quotes/QuotesFilter'
import QuoteForm from '../components/quotes/QuoteForm'
// import { fetchLocations } from '../api/LocationsApi'
import { fetchShortFormAccounts } from '../api/Account'
import { QuotesApis } from '../api/QuotesApi'
import QuoteBulkOperations from '../components/quotes/QuoteBulkOperations'
import { setValueToArrayOfObjects } from '../helpers/array_functions'
import QuotesRevisions from '../components/quotes/QuotesRevisions'
import Title from 'antd/lib/typography/Title'
import { orderConversionValidation } from '../helpers/quotes'
import ViewQuote from '../components/quotes/ViewQuote';
import { WarehouseContext } from '../context/WarehouseContext';
import { OrgContext } from '../context/OrgContext';
import { convertToTags, removeSpecialCharacters, renderAlertMessage } from '../helpers/common';
import { DisplaySettingsContext } from '../context/DisplaySettings'
import { RecordsPerPage } from '../components/orders/RecordsPerPage'
import { ScreenKeys } from './constants';
import AppliedFiltersTags from './AppliedFiltersTags'

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} }
const { TabPane } = Tabs

const Quotes = (props) => {
  const { userContext } = props.appContexts
  const { currentUser , updateRecordsPerPage } = userContext
  const [isLoading, setIsLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [quotes, setQuotes] = useState([])
  const [appliedFiltersTags, setappliedFiltersTags] = useState([])
  const [showFilterWindow, setShowFilterWindow] = useState(false)
  const [searchQuoteToken, setSearchQuoteToken] = useState('')
  const [searchKeyPress, setSearchKeyPress] = useState(false)
  const [revisions, setRevisions] = useState([])
  const [selectedInfo, setSelectedInfo] = useState({
    selectedKeys: [],
    selectedRows: [],
  })
  const [pagination, setPagination] = useState({ current_page: 1 })
  // const [tableOptions, setTableOptions] =  useState({ current_page: 1 })
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    fromDate: moment().subtract(6, 'day'),
    toDate: moment(),
    accountCode: '',
    currentStatus: 'PENDING',
    sortBy: "none",
    sortByType: "descend",
  })
  const [currentRecord, setCurrentRecord] = useState(null)
  const [showQuoteForm, setShowQuoteForm] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [accounts, setAccounts] = useState([])
  const [activeRecordId, setActiveRecordId] = useState(null)
  const [currentFileInfo, setCurrentFileInfo] = useState({})
  const [quoteViewId, setQuoteViewId] = useState(null)
  const [currentViewQuote, setCurrentViewQuote] = useState(null)
  const[dispSetting,setDispSetting] = useState({})
  const[errors,setErrors] = useState([])
  const perPageKey = ScreenKeys.QUOTES_LISTING
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved)
  const[targetAccounts,setTargetAccounts] = useState([])

  // const getWarehouses = () => {
  //   setIsLoading(true)
  //   fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
  //     if (result.success) {
  //       setWarehouses(result.locations.locations)
  //       setIsLoading(false)
  //     } else {
  //       setIsLoading(false)
  //       renderAlertMessage(result.errors)
  //     }
  //   })
  // }


  useEffect(() => {
    getQuotes();
  }, [recordsPerPage]);

  useEffect(() => {
    if(quoteViewId){
     // showQuoteDetails(quoteViewId)
    }
  },[quotes])

  // useEffect(() => {
  //   cloneQuote();
  // }, []);
  
  const recordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };
  
  useEffect(()=>{
    setDispSetting(props.displayConfiguration)
  },[props.displayConfiguration])


  const getAccounts = (whId) => {
    const {warehouseFilter} = props;
    const accountsData =  warehouseFilter.findMatchingAccounts(whId)
     setAccounts(accountsData);
  }

  //getting accounts for the target warehouse

  const getTargetAccounts = (whId) => {
    const {warehouseFilter} = props;
    const getDestinationWarehouses = warehouses.find((wh) =>wh.id !== props.warehouseFilter.selectedWarehouses)
    const pickedWarehouse = !isEmpty(whId) ? whId : getDestinationWarehouses?.id
    const accountsData =  warehouseFilter.findMatchingAccounts(pickedWarehouse)
    setTargetAccounts(accountsData);
  }


  const handleEdit = (record) => {
    if(record) {
       setCurrentRecord({
          ...record,
          delivery_location_id: record.delivery_location_id
            ? record.delivery_location_id
            : record.delivery_location_details.id,
          pickup_location_id: record.pickup_location_id
            ? record.pickup_location_id
            : record.pickup_location_details.id,
        })
      setShowQuoteForm(true)
      setActiveRecordId(record.id)
    }
  }

  const navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(accounts, ['code', account_code])
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, accounts[accountIndex])
      const account_id = !isEmpty(accountData.primary_account_id)
        ? accountData.primary_account_id
        : accountData.id
      props.history.push(`/accounts/${account_id}/settings`)
    }
  }

  const onTagClose = (tagsObj) => {
    setFilterPlaceHolder(
      {
        ...filterPlaceHolder,
        ...tagsObj,
      },
    );
  };

  const clearFilter = () => {
    setFilterPlaceHolder({
      fromDate: moment().subtract(6, 'day'),
      toDate: moment(),
      accountCode: '',
      currentStatus: 'PENDING',
      sortBy: "none",
      sortByType: "descend",
    });
    setSearchQuoteToken('')
    setSearchKeyPress(false)
  };

  const clearSearch = () => {
    setSearchKeyPress(false);
    setSearchQuoteToken('');
  }

  const computeTags = (filterPlaceHolder) => {
    const keys = [
      'fromDate',
      'toDate',
      'accountCode',
      'searchQuoteToken'
    ];
    const filteredData = {
      fromDate: {
        label: "Quote Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          onTagClose({
            fromDate: moment().subtract(6, "day"),
            toDate: moment(),
          });
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(6, "day")
              .format("MMM DD, YYYY")
          ) &&
            _.isEqual(
              filterPlaceHolder.toDate.format("MMM DD, YYYY"),
              moment().format("MMM DD, YYYY")
            )
            ? false
            : true,
      },
      toDate: {
        label: "Quote Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          onTagClose({
            fromDate: moment().subtract(6, "day"),
            toDate: moment(),
          });
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment()
              .subtract(AppConfig.invoices_filter_duration, "day")
              .format("MMM DD, YYYY")
          ) &&
            _.isEqual(
              filterPlaceHolder.toDate.format("MMM DD, YYYY"),
              moment().format("MMM DD, YYYY")
            )
            ? false
            : true,
      },
      accountCode: {
        label: "Account",
        value: accounts?.find((account) => account.code === filterPlaceHolder.accountCode
        )
          ? accounts.find(
            (account) => account.code === filterPlaceHolder.accountCode
          ).name
          : " ",
        onClose: () => {
          onTagClose({ accountCode: "" });
        },
        closeable: true,
      },
    };
    const tagsInfo = convertToTags(
      { ...filterPlaceHolder },
      keys,
      filteredData
    );
    setappliedFiltersTags(tagsInfo);
  };

  useEffect(() => {
    getAccounts(props.warehouseFilter.selectedWarehouses)
    setWarehouses(props.warehouseFilter.warehouses)
    setFilterPlaceHolder({ ...filterPlaceHolder  })
    computeTags(filterPlaceHolder);
  }, [])

  useEffect(() => {
    getAccounts(props.warehouseFilter.selectedWarehouses);
  }, [props.warehouseFilter.selectedWarehouses, props.warehouseFilter.warehouseWithAccounts])

  useEffect(() => {
    getTargetAccounts()
  }, [warehouses,props.warehouseFilter.warehouseWithAccounts])
  // update warehouse filter
  // useEffect(() => {
  //   if (
  //     !_.isEqual(
  //       props.warehouseFilter.selectedWarehouse,
  //       filterPlaceHolder.warehouse_id,
  //     )
  //   ) {
  //     setFilterPlaceHolder({
  //       ...filterPlaceHolder,
  //       warehouse_id: props.warehouseFilter.selectedWarehouse,
  //     })
  //   }
  //   if(!_.isEqual(props.warehouseFilter.warehouses, warehouses)) {
  //     setWarehouses(props.warehouseFilter.warehouses)
  //   }
  // }, [props.warehouseFilter.selectedWarehouse , props.warehouseFilter.warehouses])

  useEffect(() => {
    getAccounts(props.warehouseFilter.selectedWarehouses)
    setWarehouses(props.warehouseFilter.warehouses)
    handleSearch()
    computeTags(filterPlaceHolder);
  }, [filterPlaceHolder , props.warehouseFilter.selectedWarehouses, props.warehouseFilter.warehouses])
  

  useEffect(() => {
    if (!showQuoteForm) { // when form is closed
      if(!_.isEmpty(currentRecord)){ // fetch only when some changes are made in form
        setCurrentRecord(null);
        setActiveRecordId(null);
        debouncedFetchQuotes();
      }
    }
  }, [showQuoteForm])

  const getQuotes = async () => {
    const sortByType = filterPlaceHolder.sortByType || "ascend";
    try {
      setIsLoading(true)
      const page =
        tableOptions.pagination?.current &&
        !isEmpty(tableOptions.pagination.current)
          ? tableOptions.pagination.current
          : 1
      const perPage = recordsPerPage
      const payload = {
        account_code: filterPlaceHolder.accountCode,
        from_time: filterPlaceHolder.fromDate,
        to_time: filterPlaceHolder.toDate,
        sort_by: filterPlaceHolder.sortBy,
        sort_order: I18n.t(`general.sort_${sortByType}`)
      };
      let response = await QuotesApis.fetch(
        true,
        page,
        perPage,
        searchQuoteToken,
        payload,
      )
      if (response.success) {
        const quotes = response?.quote_orders ? response.quote_orders : []
        if (response.errors && response.errors.length > 0) {
          setErrors(response.errors)
          setQuotes([])
          setPagination({})
          handleSelectChange([], [])
        } else {
          setQuotes(quotes)
          handleSelectChange([], [])
          setPagination(response.pagination)
        }
      } else {
        setErrors([...response.errors])
        setQuotes([])
        // throw new Error(response.errors[0])
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      // setSearchKeyPress(false)
      setIsLoading(false)
    }
  }

  const getQuotesRecord = async (id) => {
    try {
      setIsLoading(true)
      const payload = {
        id
      }
      let response = await QuotesApis.fetchOneRecord(
        payload,
      )
      if (response.success) {
        const quotes = response?.quote_orders ? response.quote_orders : []
        if (response.errors && response.errors.length > 0) {
          setErrors(response.errors)
        } else {
          if(quotes?.length){
            handleEdit(quotes[0])
          }
        }
      } else {
        setErrors([...response.errors])
        // throw new Error(response.errors[0])
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setSearchKeyPress(false)
      setIsLoading(false)
    }
  }

  const debouncedFetchQuotes = _.debounce(getQuotes, 1000)

  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag)
  }

  const handleFilterPlaceHolderChange = (filter, cb) => {
    setFilterPlaceHolder(Object.assign({}, filter))
  }

  const handleSearch = () => {
    setShowFilterWindow(false);
    setSearchKeyPress(false)
    setSearchQuoteToken('');
    tableOptions.pagination.current = 1
    if(!props.warehouseFilter.isFetchingWarehouses && props.warehouseFilter.warehouses.length > 0) {
      debouncedFetchQuotes()
    }
  }

  const convertQuoteToOrder = async (id) => {
    try {
      setIsLoading(true)
      let response = await QuotesApis.quoteToOrder(id)
      notification.open({
        key: id,
        message: I18n.t('quotes.order_conversion'),
        description: <div>{I18n.t('quotes.order_conversion_process')}</div>,
        icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
        placement: 'bottomRight',
        duration: 20,
      })
      if (response.success) {
        const result = response?.data ? response.data : {}
        if (result.order_ids.length > 0) {
          props.history.push({
            pathname: `/orders/${result.order_ids[0]}/edit`,
            filterPlaceHolder: {
              search_order_token: result.order_ids[0].customer_order_number,
            },
            searchType: 'byOrders',
            orderObject: result.order_ids[0],
            showQuoteOrder: true,
          })
        }
        notification.success({
          //title: I18n.t('quotes.order_conversion'),,
          message: I18n.t('quotes.conversion_success'),
          placement: 'bottomRight',
          key: id,
        })
      } else {
        notification.close(id)
        setErrors([...response.errors])
        // throw new Error(response.errors[0])
      }
    } catch (error) {
      notification.close(id)
      alertMessage(error.message, 'error', 10)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    if(searchKeyPress)
      debouncedFetchQuotes();
  }, [searchKeyPress])

  const renderSearchBar = () => {
    return (
      <div className="headerOptionDiv">
        <Search
          placeholder="Search Quote"
          size="default"
          value={
            !isEmpty(searchQuoteToken) ? searchQuoteToken.toUpperCase() : ''
          }
          onSearch={(value) => {
            // this.handleFilterChange('search_order_token', value);
            tableOptions.pagination.current = 1
            setSearchQuoteToken(value)
            setSearchKeyPress(true)
          }}
          onChange={(e) => {
            // clear url search params
            if (window.location.search.indexOf('quote_number') > -1) {
              const { history } = props
              history.replace('/quotes')
            }
            const filteredString = removeSpecialCharacters(e.target.value);
            setSearchQuoteToken(filteredString)
          }}
          style={{ width: 400, textTransform: 'uppercase' }}
          // allowClear
          suffix={
            <Fragment>
              {searchQuoteToken !== '' && (
                <Fragment>
                  {' '}
                  <Icon
                    type="close"
                    onClick={clearFilter}
                  />{' '}
                  &nbsp;&nbsp;
                </Fragment>
              )}
              <Dropdown
                onVisibleChange={handleVisibleChange}
                visible={showFilterWindow}
                trigger={['click']}
                overlay={
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: 400,
                      padding: 15,
                      marginTop: 10,
                      marginRight: -12,
                    }}
                    className="boxShadow"
                  >
                    <QuotesFilter
                      filterValues={filterPlaceHolder}
                      onChange={handleFilterPlaceHolderChange}
                      onSearch={handleSearch}
                      onOrderNoSearch={null}
                      warehouseFilter={props.warehouseFilter}
                    />
                  </div>
                }
              >
                <Icon
                  type="control"
                  onClick={() => setShowFilterWindow(true)}
                />
              </Dropdown>
            </Fragment>
          }
          prefix={
            <Row gutter={4}>
              <Col>
                <Icon type="search" onClick={() => setSearchKeyPress(true)} />
              </Col>
            </Row>
          }
        />
      </div>
    )
  }

  const cloneQuote = (id) => {
    QuotesApis.QuoteCreate(id).then((result) => {
        if (result.success) {
          alertMessage("Quote Cloned Successfully");
          getQuotesRecord(result.quote_order_id)
        } else {
          this.setState({inProgress: false});
        }
      });
    };

  const handleDelete = async (ids) => {
    try {
      setIsLoading(true)
      const result = await QuotesApis.delete(ids)
      if (result.success) {
        debouncedFetchQuotes()
        const successMessage = result?.message
          ? result.message
          : I18n.t('messages.rejected')
        if (result.errors && result.errors.length > 0) {
          renderAlertMessage(result.errors)
        } else {
          alertMessage(successMessage)
          debouncedFetchQuotes()
        }
      } else {
        alertMessage(result.errors, 'error', 10)
      }
    } catch (error) {
      renderAlertMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedInfo({
      ...selectedInfo,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    })
  }
  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  }

  const handleOnFilterElementChange = (element, value) => {
    if (element === "sortBy") {
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || "ascend",
      });
      setFilterPlaceHolder(filter);
    } else {
      const filter = Object.assign({}, filterPlaceHolder, {
        [element]: value,
      });
      setFilterPlaceHolder(filter);
    }
    setSearchKeyPress(true);
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }

    if(tableOptions.pagination.current !== currentPage){
      setSearchKeyPress(true)
    }
    else if(sorter && !isEmpty(sorter.field)){
      tableOptions.pagination.current = 1;
        handleOnFilterElementChange("sortBy", {
          sortKey: sorter.columnKey,
          sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
        });
    }
  }

  const genarateQuote = (id) => {
    setCurrentViewQuote(null)
    setIsLoading(true)
    QuotesApis.generateQuote(id)
      .then((result) => {
        if (result.success) {
          const quoteAmount =
            result?.data && !isEmpty(result.data.quote)
              ? result.data.quote.quote_amount
              : 0.0
          const updatedQuotes = setValueToArrayOfObjects(
            [...quotes],
            'id',
            id,
            'quote_amount',
            quoteAmount,
          )
          setQuotes(updatedQuotes)
          if(result.data.quote){
            //setActiveRecordId(result.data.quote.id)
            setCurrentViewQuote(result.data.quote)
          }
          
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setQuoteViewId(id)
        setIsLoading(false)
      })
  }

  const editQuote = (id) => {
    const quote = quotes.find((quote) => quote.id === id)
    if(quote){
      handleEdit(quote)
    }else{
      getQuotesRecord(id)
    }
  }

  const validateQuote = (id, sourceRecord = null) => {
    let record = null;
    if(!isEmpty(sourceRecord)){
      record = {...sourceRecord}
    }else {
      record = _.find(quotes, { id })
    }
    if (!isEmpty(record)) {
      orderConversionValidation(id, record, convertQuoteToOrder, handleEdit, showQuoteForm)
    }
  }

  const data = []
  for (let i = 0; i < 10; i++) {
    data.push({
      key: i,
      name: `file ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`,
    })
  }
  const openQuoteView = (id) => {
    genarateQuote(id)
  }

  const showQuoteDetails = (id) => {
    const quote = quotes.find((quote) => quote.id === id)
    setCurrentViewQuote(quote)
  }

  const refreshAccounts = (whId) => {
    getAccounts(whId)
  }

  const refreshTargetAccounts = (whId) => {
    getTargetAccounts(whId)
  }

  return (
    <div className="quotesPageContainer content-outer routes-classes">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("menu.quotes")}</Col>
          <Col xs={12} className="alignRight">
            <Row
              justify="end"
              gutter={16}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Col className="alignLeft">{renderSearchBar()}</Col>
              <Col className="alignRight page-actions">
                <Button
                  onClick={() => setShowQuoteForm(true)}
                  type={"primary"}
                  icon="plus"
                  size={"default"}
                  className=""
                >
                  {I18n.t("quotes.add")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="page-content marginTop10">
          <Col xs={16}>
            <AppliedFiltersTags
              searchTextLabel={I18n.t("search.quote_search")}
              tags={appliedFiltersTags}
              clearFilter={clearFilter}
              isSearching={searchKeyPress}
              searchText={searchQuoteToken}
            />
          </Col>
          <Col xs={8}>
            <Row style={{float:'right', marginBottom:'-18px'}}>
              <RecordsPerPage
                onChange={recordChange}
                value={recordsPerPage}
                defaultValue={recordsPerPage}
              />
            </Row>
          </Col>
        </Row>
        <QuotesList
          data={quotes}
          scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
          displayConfiguration={dispSetting}
          screen_from="quotes"
          // bodyStyle={{
          //   height: 'calc(100vh - 166px)',
          //   overflowY: 'scroll',
          // }}
          isLoading={isLoading}
          size="small"
          handleEditClick={handleEdit}
          deleteClick={handleDelete}
          cloneQuote = {cloneQuote}
          pagination={tablePagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(pagination, filter, sorter, currentTable)
          }
          genarateQuote={genarateQuote}
          validateQuote={validateQuote}
          quoteViewId={quoteViewId}
          setQuoteViewId={setQuoteViewId}
          editQuote={editQuote}
          refreshQuotes={debouncedFetchQuotes}
          navigateToAccount={navigateToAccount}
          showQuoteDetails={showQuoteDetails}
        />
        {selectedInfo.selectedKeys.length > 0 && (
          <QuoteBulkOperations
            filter={filterPlaceHolder.currentStatus}
            selectedInfo={selectedInfo}
            multipleDeletion={handleDelete}
            handleClear={() => handleSelectChange([], [])}
            refreshQuotes={() => debouncedFetchQuotes()}
            setIsLoading={(value) => setIsLoading(value)}
          />
        )}
      </div>
      {showQuoteForm && (
        <Drawer
          title={null}
          placement="right"
          onClose={() => {
            setShowQuoteForm(false);
          }}
          visible={showQuoteForm}
          width="90%"
          className="quoteDrawer"
        >
          <Title level={4}>
            {isEmpty(activeRecordId)
              ? I18n.t("general.create_item", { item: "Quote" })
              : `${I18n.t("general.edit_item", { item: "Quote" })}: #${
                  currentRecord.quote_order_number
                }`}
          </Title>
          <Tabs onChange={() => {}} type="card">
            <TabPane tab={`Manage Quote`} key="manage_quote_tab">
              <QuoteForm
                warehouses={warehouses}
                accounts={accounts}
                currentQuote={currentRecord}
                isNew={activeRecordId === null}
                setCurrentQuote={setCurrentRecord}
                activeRecordId={activeRecordId}
                setActiveRecordId={(id) => setActiveRecordId(id)}
                setShowQuoteForm={setShowQuoteForm}
                setRevisions={(data) => setRevisions(data)}
                genarateQuote={genarateQuote}
                openQuoteView={openQuoteView}
                validateQuote={validateQuote}
                refreshAccounts={refreshAccounts}
                refreshTargetAccounts={refreshTargetAccounts}
                warehouseFilter={props.warehouseFilter}
                targetAccounts={targetAccounts}
                showQuoteDetails={showQuoteDetails}
              />
            </TabPane>
            <TabPane
              tab="Revisions"
              key="revisions"
              disabled={!currentRecord?.id}
            >
              <div className="marginTop20 fontSize12 itemDetails">
                <QuotesRevisions
                  revisions={revisions}
                  size="small"
                  style={{ fontSize: 12 }}
                />
              </div>
            </TabPane>
            {/* 
            <TabPane tab="Documents" key="documents" disabled={!(currentRecord?.id)}>
              <Row type='flex' gutter={32}>
                <Col span={ 10 }>
                  <Title level={ 4 }>Documents List</Title>
                  <Table columns={ columns } dataSource={ data } pagination={ { pageSize: 50 } } scroll={ { y: 240 } } />,
                </Col>
                <Col span={ 14 }>
                  <Title level={ 4 }>Upload</Title>
                  <UploadFiles
                    draggerOptions={ draggerOptions }
                    fileList={ fileList }
                    showUploadList={ !(fileList.length > 0) }
                    previewFile={ true }
                    disabled={ false }
                    customRequest={ dummyRequest }
                    uploadText="Click or drag file to this area to upload"
                    uploadHint="Support for bulk Files upload."
                    // isMultiUpload={ true }
                    deleteFile={ deleteFile }
                  />
                  <div className="center">
                  <Button type="primary" onClick={ () => { } }>Upload</Button>
                  <Button type="danger" onClick={ () => { } }>Close</Button>
                  </div>
                </Col>
              </Row>
            </TabPane> */}
          </Tabs>
        </Drawer>
      )}
      {currentViewQuote?.id && (
        <ViewQuote
          quote_no={currentViewQuote.quote_order_number}
          quote_id={currentViewQuote.id}
          quoteViewId={quoteViewId}
          setQuoteViewId={setQuoteViewId}
          editQuote={editQuote}
          cloneQuote={cloneQuote}
          refreshQuotes={debouncedFetchQuotes}
          validateQuote={validateQuote}
          content={currentViewQuote.quote_order_number}
          setCurrentViewQuote={setCurrentViewQuote}
          currentViewQuote = {currentViewQuote}
          pickupLocationId = {currentViewQuote.pickup_location_id}
          deliveryLocationId = {currentViewQuote.delivery_location_id}
        />
      )}
    </div>
  );
}

// export default withRouter(Quotes)
export const QuotesComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext)
  return (
    <Quotes
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      {...props}
    />
  );
});

export default QuotesComponent;



QuotesComponent.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  filterPlaceHolder: PropTypes.shape(),
}

QuotesComponent.defaultProps = {
  filterPlaceHolder: {},
}
