/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  FormItem,
  Input,
  Row,
  Checkbox,
  InputNumber,
  Icon,
} from "../../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  checkWithinRange,
} from "../../../common/Common";
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import { privateName } from "@babel/types";
import { _ } from "core-js";
import I18n from "../../../common/I18n";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRatecard: this.props.currentRatecard
        ? this.props.currentRatecard
        : {},
      inProgress: false,
      isShowAddWeight: this.props.isShowAddWeight || true,
      errors: [],
      weights: this.props.weights || [],
      isNew: this.props.isNew === true,
    };
  }

  clearForm = () => {
    this.setState((prevState) => ({
      currentRatecard: {
        ...prevState.currentRatecard,
        max_weight: "",
        min_weight: "",
        param_values: prevState.currentRatecard.param_values.map((rec) => ({
          ...rec,
          accessorial_value: "",
        })),
      },
      isNew: true,
    }));
  };

  componentDidUpdate(prevProps, prevState){
    if(!_.isEqual(prevProps.weights, this.props.weights)){
      this.setState({
        weights: this.props.weights ||[],
      })
    }
  }

  handleOnChange = (element, value) => {
    if (element === "min_weight" || element === "max_weight") {
      this.setState({
        currentRatecard: Object.assign({}, this.state.currentRatecard, {
          [element]: value,
        }),
      });
    } else {
      const { currentRatecard } = this.state;
      const accessorialWeightParams = currentRatecard.param_values;
      const accessorailIndex = _.findIndex(accessorialWeightParams, [
        "accessorial_key",
        element,
      ]);
      if (accessorailIndex >= 0) {
        accessorialWeightParams[accessorailIndex].accessorial_value = value;
      }
      this.setState({
        currentRatecard: Object.assign({}, this.state.currentRatecard, {
          param_values: accessorialWeightParams,
        }),
      });
    }
  };

  handleSave = () => {
    const { currentRatecard } = this.state;
    if (
      !isEmpty(currentRatecard.min_weight) &&
      !isEmpty(currentRatecard.max_weight)
    ) {
      const minWeight = parseFloat(currentRatecard.min_weight);
      const maxWeight = parseFloat(currentRatecard.max_weight);
      if (minWeight > maxWeight) {
        alertMessage(
          I18n.t("messages.min_value_should_greate_than_max"),
          "error",
          "5"
        );
      } else {
        let isExisted = false;
        if (this.state.isNew) {
          // removing non zero values basically for min charge
          const weights = this.state.weights.filter(weight => !isEmpty(weight.min_weight) && weight.max_weight !==0 ); // (weight => weight.min_weight !== 0 && weight.max_weight !==0);
          isExisted = checkWithinRange(
            weights,
            "min_weight",
            "max_weight",
            minWeight || 0,
            maxWeight || 0
          );
        }
        if (isExisted) {
          alertMessage(
            I18n.t("messages.given_vales_are_existing_in_another_range"),
            "error",
            "5"
          );
        } else {
          this.props.handleSave(this.state.currentRatecard);
          this.clearForm();
        }
      }
    } else {
      alertMessage(I18n.t("errors.weights"), "error", "5");
    }
  };

  render() {
    const { currentRatecard, isShowAddWeight } = this.state;
    const decimalPoints= window.localStorage.getItem('round_off_decimals');

    return (
      <div>
        <Row gutter={16}>
          <Col xs={24}>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={24} lg={24} className="marginTop10">
                <FormItem
                  label={!isEmpty(currentRatecard.min_label)? currentRatecard.min_label : I18n.t("account.billing.ratecard.min_weight")}
                  require
                >
                  <InputNumber
                    type="number"
                    value={currentRatecard.min_weight}
                    onChange={(e) => this.handleOnChange("min_weight", e)}
                    disabled={!this.state.isNew}
                    min={0}
                    precision={0}
                    className="width100Per"
                  />
                </FormItem>
              </Col>
              <Col sm={24} xs={24} md={24} lg={24}>
                <FormItem
                  label={currentRatecard.max_label ? currentRatecard.max_label : I18n.t("account.billing.ratecard.max_weight")}
                  require
                >
                  <InputNumber
                    value={currentRatecard.max_weight}
                    onChange={(e) => this.handleOnChange("max_weight", e)}
                    disabled={!this.state.isNew}
                    min={0}
                    precision={0}
                    className="width100Per"
                  />
                </FormItem>
              </Col>
              {!isEmpty(currentRatecard.param_values) &&
                currentRatecard.param_values.map((param) => (
                  <Col sm={24} xs={24} md={24} lg={24}>
                    <FormItem label={param.accessorial_label} require>
                      <Input
                        addonBefore={<Icon type="dollar" />}
                        type="number"
                        onChange={(e) =>
                          this.handleOnChange(
                            param.accessorial_key,
                            e.target.value
                          )
                        }
                        min={0}
                        precision={decimalPoints}
                        className="width100Per"
                        value={param.accessorial_value}
                      />
                    </FormItem>
                  </Col>
                ))}
            </Row>
          </Col>

          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.clearForm,
              true,
              true,
              "default",
              I18n.t("general.save"),
              I18n.t("general.clear")
            )}
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

Form.propTypes = {
  currentRatecard: PropTypes.shape().isRequired,
  handleSave: PropTypes.func.isRequired,
  isShowAddWeight: PropTypes.bool.isRequired,
};

export default Form;
