import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchCurrentUser } from '../api/UsersApi';
import { Col, Row, Spin, Tabs } from '../common/UIComponents';
import UserForm from '../components/users/Form';
import whiteArrow from "../../assets/images/WhiteArrow.png";
import { alertMessage, checkIfDriverLoggedIn } from '../common/Common';
import userStore from '../stores/UserStore';
import AppConfig from '../config/AppConfig';

const { TabPane } = Tabs;

class EditProfile extends Component {
  constructor() {
    super();
    this.state = { user: {}, inProgress: false };
    this.handleProfileSave = this.handleProfileSave.bind(this);
  }


  componentWillMount() {
    this.getUser();
  }

  getUser=() => {
    this.setState({ inProgress: true });
    fetchCurrentUser()
      .then((result) => {
        if (result.success) {
          const user = result.user ? {
            ...result.user,
            warehouse_ids: result.user.warehouse_ids && _.isArray(result.user.warehouse_ids) ? 
            result.user.warehouse_ids : 
            []
          } : 
          {};
          if(user?.roles){
            const mobileRoles = AppConfig.mobileRoles.map((role) => role.role_code);
            user.webRolesData = user.roles.filter((role) => !mobileRoles.includes(role));
            user.mobileRolesData = user.roles.filter((role) => mobileRoles.includes(role));
      
          } else {
            user.webRolesData = [];
            user.mobileRolesData = [];
          }
          if (user?.dv_location?.l_address) {
            user.l_address = user.dv_location.l_address;
          } else {
            user.l_address = {};
          }
          this.setState({ user, inProgress: false });
        } else {
          const err = result.message ? result.message : result.errors[0];
          alertMessage(err, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }


  handleProfileSave(message, user) {
    alertMessage(message);
    const selectedOrg = userStore.getStateValue('selectedOrg');
    user.selectedOrg = selectedOrg;
    userStore.setState(user);
    this.getUser();

    this.props.history.push('/edit_profile');
  }
  handleCancel = () => {
    this.props.history.push('/');
  }
  navigateToScreen = () => {
    const isDriver = checkIfDriverLoggedIn(userStore.currentRole());
    if (isDriver) {
      this.props.history.push(`/weekly_settlement_reports`);
    } else {
      this.props.history.push(`/orders`);
    }  
  }
  
  render() {
    const user = this.state.user || {};
    const isDriver = checkIfDriverLoggedIn(userStore.currentRole());
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col xs={24}>
            {( userStore.currentRole() === "shipper" || isDriver)&&
              <img
                src={whiteArrow}
                alt="whitearrow"
                style={{
                  height: 20,
                  width: 30,
                  cursor: "pointer",
                  marginTop: -2,
                }}
                onClick={this.navigateToScreen}
              />
            }
              Edit Profile
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Tabs defaultActiveKey="1">
              <TabPane tab='' key="1">
                <Row>
                 
                  <Col md={{ offset: 3, span: 18 }}>
                    <UserForm
                    user={user}
                    editProfile
                    isNew={false}
                    onCancel={this.handleCancel}
                    onSuccess={(message, user) => this.handleProfileSave(message, user)}
                    userType={user && user.roles && user.roles.length > 0 ? user.roles[0] : 'customer'}
                    />
                  </Col>
                </Row>
              </TabPane>

            </Tabs>
          </Spin>
        </div>
      </div>
    );
  }
}

export default EditProfile;
EditProfile.propTypes = {
  history: PropTypes.shape().isRequired,
};
