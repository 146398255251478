import React, { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";

export const DispatchStops = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      account_code: "ABC123",
      account_name: "abc123",
      hawb: "123456",
      mawb: "123456",
      reference_1: "123456",
      reference_2: "123456",
      los :"White Glove",
      quantity: "2",
      weight : "100 lbs",
      status: "ASSIGNED",
      consignee:"Bob",
      company_name : "ABC123",
      routing : "ABCD"
    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      account_code: "ABC456",
      account_name: "abc456",
      hawb: "78910",
      mawb: "78910",
      reference_1: "78910",
      reference_2: "78910",
      los:"Threshold",
      quantity: "3",
      weight: "120 lbs",
      status : "COMPLETED",
      consignee :"Mary",
      company_name : "ABC123",
      routing : "EFGH"


    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "account_code",
      title: I18n.t("account.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "los",
    },
    {
      key: "quantity",
      title: I18n.t("order.qty"),
      dataIndex: "quantity",
    },
    {
      key: "weight",
      title: I18n.t("order.weight"),
      dataIndex: "weight",
    },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "consignee",
      title: <EndUserTitle/>,
      dataIndex: "consignee",
    },
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "stops_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
};
