import { Button, Col, Row, Spin } from "../common/UIComponents";
import React, { useContext, useEffect, useState } from "react";
import WrappedVehicleTypeMappingForm from "../components/Vehicletype_mapping/VehicleTypeMappingForm";
import BaseModal from "../components/BaseModal";
import { VehicleTypeMappingApi } from "../api/VehicleTypeMapping";
import { alertMessage, isEmpty } from "../common/Common";
import AppConfig from "../config/AppConfig";
// import Gallery from "../components/common/Gallery";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import VehicleTypeMappingList from "../components/Vehicletype_mapping/vehicleTypeMappingList";
import OperationsVehicleTypeMapping from "../components/Vehicletype_mapping/OperationsVehicleTypeMapping";
import { fetchOrganizations } from "../api/Organisations";
import TreeSelectComponent from "./Filters/TreeSelectComponent";
import userStore from "../stores/UserStore";
import { ScreenKeys } from "./constants";
import { UserContext } from "../context/UserContext";
import _ from "lodash";
import I18n from "../common/I18n";
import { renderAlertMessage } from "../helpers/common";

const VehicleTypeMapping = () => {
  const [showModal, setShowModal] = useState(false);
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [existingVehicleId, setExistingVehicleId] = useState(null);
  const userContext = useContext(UserContext);
  const { updateRecordsPerPage } = userContext;
  const perPageKey = ScreenKeys.VEHICLE_TYPE_MAPPING;
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [ recordsPerPage, setRecordsPerPage ] = useState(recordsPerPageSaved);
  const [organizations, setOrganizations] = useState([]);
  const [organizationIds, setOrganizationIds] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrderKeys, setSelectedOrderKeys] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);

  let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    getVhTypes();
  };

  const handleAddClick = () => {
    setShowModal(true);
  };
  useEffect(() => {
    getOrganizations();
  }, [recordsPerPage]);

  const closeModal = () => {
    setShowModal(false);
    setShowExportModal(false);
    setExistingVehicleId(null);
  };

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };

  const onSearch = (value) => {
  };

  useEffect(() => {
    getVhTypes();
  }, [recordsPerPage]);

  const editVhTypes = (id) => {
    setShowModal(true);
    setExistingVehicleId(id);
  };

  const getVhTypes = () => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = recordsPerPage;
    setIsLoading(true);
    VehicleTypeMappingApi.fetch(page, perPage).then((result) => {
      if (result.success) {
        setVehicleTypeData(result.master_vehicle_types);
        setExistingVehicleId(result.master_vehicle_types.id);
        setPagination(result.pagination);
        setIsLoading(false);
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  const getOrganizations = () => {
    setIsLoading(true);
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        let orgId = "";
        if (result.organizations?.organizations?.length > 0) {
          orgId = result.organizations.organizations.id;
        }
        setOrganizations(result.organizations.organizations);
        setOrganizationIds(orgId);
        setIsLoading(false);
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const handleOrgStateChange = (element, value) => {
    setOrganizationIds(value);
  };

  const deleteVhTypes = (id) => {
    setIsLoading(true);
    VehicleTypeMappingApi.delete(id).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success");
        const newVehicleTypeData = vehicleTypeData.filter(
          (item) => item.id !== id
        );
        setVehicleTypeData(newVehicleTypeData);
      } else {
        renderAlertMessage(result.errors)
      }
    });
    setIsLoading(false);
  };

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedOrderKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };
  const handleExport = () => {
    setShowExportModal(true);
  };

  const selectedRowKeys = selectedOrderKeys;
  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };
  const vehicleTypeForm = () => {
    return (
      <BaseModal
        // title={I18n.t("vehicleTypes.add")}
        title= {existingVehicleId ? "Edit Vehicle Type Mapping" : "Add Vehicle Type Mapping"}
        visible={showModal}
        onCancel={closeModal}
        footer={null}
        width={"600px"}
        maskClosable={false}
      >
        <WrappedVehicleTypeMappingForm
          closeModal={closeModal}
          existingVehicleId={existingVehicleId}
          getVhTypes={getVhTypes}
        />
      </BaseModal>
    );
  };

  const handeExportsave = () => {
    if (!organizationIds || organizationIds.length === 0) {
      alertMessage("Organization IDs are required.", "error");
      return;
    }

    if (!selectedOrderKeys || selectedOrderKeys.length === 0) {
      alertMessage("Vehicle Types are required.", "error");
      return;
    }

    const payload = {
      organization_ids: organizationIds,
      // vehicle_types: selectedRows,
      vehicle_types: selectedOrderKeys,
      role: userStore.currentRole(),
    };
    setExportLoading(true);
    VehicleTypeMappingApi.createexport(payload)
      .then((response) => {
        if (response.success) {
          alertMessage("Added Successfully", "success");
          setShowExportModal(false);
        } else {
          renderAlertMessage(response.errors)
        }
      })

      .catch((error) => {
        alertMessage(error.message, "error", 10);
      })
      .finally(() => {
        setExportLoading(false);
        setSelectedOrderKeys([]);
        setOrganizationIds([]);
      });
  };

  const handleCancel = () => {
    setSelectedRows([]);
    setSelectedOrderKeys([]);
  };

  const ExportorgVehicleTypeForm = () => {
    return (
      <BaseModal
        title="Select Organizations"
        visible={showExportModal}
        onCancel={closeModal}
        footer={null}
        width={"520px"}
        maskClosable={false}
      >
        <Spin spinning={exportLoading}>
          <Row>
            <Col span={24}>
              <TreeSelectComponent
                selectedValues={organizationIds}
                // onValueChange={(element, value) => {
                //   setOrganizationIds(value)
                // }}

                onValueChange={(value) =>
                  handleOrgStateChange(organizationIds, value)
                }
                records={organizations}
                titleField="name"
                valueField="id"
                showLabel={false}
                size="default"
                element="organizationIds"
              />
            </Col>
          </Row>
          &nbsp;&nbsp;
          <Row className="marginTop10">
            <Col xs={24} className="alignCenter">
              <Button onClick={closeModal} className="marginRight5" icon="close">
                Cancel
              </Button>
              <Button type="primary" onClick={handeExportsave} icon="save">
                Save
              </Button>
            </Col>
          </Row>
        </Spin>
      </BaseModal>
    );
  };

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("menu.vehicle_type_mapping")}</Col>
          <Col xs={12} className="page-actions">
            <Button type="primary" onClick={handleAddClick} icon="plus">
              {`${I18n.t("general.add")} ${I18n.t("menu.vehicle_type_mapping")}`}
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading}>
          <Row>
            <Col>
              <Row type="flex" justify="end">
                <Col>
                  <RecordsPerPage
                    onChange={onRecordChange}
                    onSearch={onSearch}
                    value={recordsPerPage}
                    defaultValue={recordsPerPage}
                  />
                </Col>
              </Row>

              <VehicleTypeMappingList
                scroll = {{y:"calc(100vh - 250px)"}}
                data={vehicleTypeData}
                rowSelection={rowSelection}
                pagination={tablePagination}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, sorter, currentTable)
                }
                handleDelete={(id) => deleteVhTypes(id)}
                handleEdit={(id) => editVhTypes(id)}
              />
            </Col>
          </Row>
          <Row>
            {selectedOrderKeys.length > 0 && (
              <OperationsVehicleTypeMapping
                handleExport={handleExport}
                handleCancel={handleCancel}
              />
            )}
          </Row>
        </Spin>
      </div>
      {showModal && vehicleTypeForm()}
      {showExportModal && ExportorgVehicleTypeForm()}
    </div>
  );
};
export default VehicleTypeMapping;
