import React, { Component, Fragment, useContext } from "react";
import _, { result } from "lodash";
import {
  Spin,
  Drawer,
  Col,
  Row,
} from '../common/UIComponents'
import { alertMessage,  isEmpty, formatDate, navigateToAccount } from '../common/Common'
import I18n from "../common/I18n";
import { deleteInvoice, fetchIndividualInvoices, fetchUpdatedInvoiceNo } from '../api/BillingScreenAPI'
import { checkListApi } from '../api/CheckList'
import AppConfig from '../config/AppConfig'
import OrderDetails from '../components/Tasks/OrderDetails'
import userStore from '../stores/UserStore'
import GroupByInvoice from '../components/billing_screen/GroupByInvoice'
import { checkServiceExistance, renderAlertMessage } from '../helpers/common';
import BulkOperations from '../components/billing_screen/BulkOperations'
import { withRouter } from "react-router-dom";
import { OrgContext } from "../context/OrgContext";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { findIfInvoicePartiallyPaid } from "../helpers/billing";
import ExportForm from "./ExportForm";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";
import { RulesApi } from "../api/Rules";
import { setRulesData } from "../helpers/configurations";
import SendInvoice from "../components/billing_screen/SendInvoice";
import moment from "moment";


class IndividualOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // pagination: {},
      inProgress: false,
      listProgress: false,
      account_invoices: [],
      showSurvey: false,
      accounts: this.props.accounts || [],
      currentProccessedIndex: -1,
      currentProcessedId: null,
      editedInvoiceNo: "",
      filterPlaceHolder: props.filter
        ? props.filter
        : {
          fromDate: null,
          toDate: null,
          accountCode: "",
          currentStatus: "PENDING",
        },
      activeKey: "account_wise",
      pagination: {},
      selectedInfo: {
        selectedKeys: [],
        selectedRows: [],
      },
      checkList: [

      ],
      enableChecklistApproval: false,
      organizationSettings: {},
      recordsPerPage: props.recordsPerPage,
      completedOrdersDate: [],
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
    this.onTableChange = this.onTableChange.bind(this)
    // debounce getInvoices
    this.getInvoices = _.debounce(this.getInvoices, 1000)
  }


  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getInvoices()
        updateRecordsPerPage(ScreenKeys.BILLING_SCREEN_LISTING, value);
      }
    );
  };

  onSearch = (value) => {
  };

  componentDidMount() {
    const { screenData } = this.props.searchConfigContext;
    const { INVOICE } = screenData;
    this.getCheckListData()
    this.getInvoices()
    this.getRules()
    this.setState(
      { organizationSettings: this.props.organizationSettings },
      () => { 
        this.updateStateFromContext();
      }
    );
    if (this.props.warehouseFilter.warehouses.length) {
      this.getInvoices()

    }
  }

  updateStateFromContext = () => {
    const { screenData } = this.props.searchConfigContext;
    const { INVOICE } = screenData;
    const currentPage =
      INVOICE.tableOptions.pagination && INVOICE.tableOptions.pagination.current
        ? INVOICE.tableOptions.pagination.current
        : 1;
    this.tableOptions.pagination.current = currentPage;
  };

  static getDerivedStateFromProps(props, state) {
    const { filter, accounts } = props;
    const data = {};
    if (!isEmpty(filter)) {
      data.filterPlaceHolder = filter;
    }
    if (!isEmpty(accounts)) {
      data.accounts = accounts;
    }

    return data;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.warehouseFilter.warehouses.length) {
      if ((!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) || (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) || !_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses) && (!this.props.warehouseFilter.isFetchingWarehouses))) {
        this.tableOptions.pagination.current = 1;
        this.getInvoices();
        this.props.computeTags(this.state.filterPlaceHolder);
      }

      if ((prevProps.searchKeyPress !== this.props.searchKeyPress) && this.props.searchKeyPress) {
        this.tableOptions.pagination.current = 1;
        this.getInvoices();
      }
    }
    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
      });
    }
    if(prevProps.enableFractionFormat !== this.props.enableFractionFormat){
      this.enableDateRangeCalculations().then(result => {
        this.getInvoices();
      });
    }
  };


  enableDateRangeCalculations = () => {
    const { filterPlaceHolder,account_invoices : invoices } = this.state;
    return new Promise((resolve, reject) => {
      const allInvoiceDetails = invoices.flatMap((invoice) => invoice.invoice_details);
      const result = allInvoiceDetails.filter((item) => {
        return this.isDateRange(item.order_completion_date, filterPlaceHolder.fromDate, filterPlaceHolder.toDate)
      });
      const requiredData = result.map((item) => {
        return moment(item.order_completion_date).format("YYYY-MM-DD");
      });
      this.setState({ completedOrdersDate: requiredData }, () => {
        console.log(requiredData, "result");
        resolve(requiredData);
      });
    });
  };

  isDateRange = (date, fromDate, toDate) => {
    const rawDate = moment(date).format("YYYY-MM-DD");
    const rawFromDate = moment(fromDate).format("YYYY-MM-DD");
    const rawToDate = moment(toDate).format("YYYY-MM-DD");
    return rawDate >= rawFromDate && rawDate <= rawToDate;
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function"){
        this.props.handleChildFilterChange(filter);
      }     
    });
  };

  handleOnFilterElementChange = (element, value) => {
    if (element === "sortBy") {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || "ascend",
      });
      this.updateFilter(filter);
    } else {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        [element]: value,
      });
      this.updateFilter(filter);
    }
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getInvoices();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.handleOnFilterElementChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      // this.setState(
      //   {
      //     filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
      //       sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "datetime",
      //       sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
      //     }),
      //   },
      //   () => {
      //     this.getInvoices();
      //   }
      // );
    }
    this.props.updateContext(this.state.pagination, this.tableOptions);
  };

  getCheckListData = () => {
    const organization_id = userStore.getStateValue("selectedOrg")
    checkListApi.fetchCheckList(organization_id).then((result) => {
      if (result.success) {
        this.setState({
          checkList: result.data ? result.data.approval_check_lists : [

          ]
        })
      } else {
        renderAlertMessage(result.errors)
      }

    })
  };

  getRules = () => {
    const organization_id = userStore.getStateValue("selectedOrg")
    RulesApi.fetch(organization_id).then((orgRulesResult) => {
      if (orgRulesResult.success) {
        const config = setRulesData(orgRulesResult.organization_rules);
        this.setState({
          enableChecklistApproval: _.has(
            config.updatedConfig?.org_billing_rules,
            "enable_checklist_approval"
          )
            ? config.updatedConfig.org_billing_rules.enable_checklist_approval
            : "false",
        }); 
      } else {
        alertMessage(orgRulesResult.errors[0], "error", 10);
      }
    })
  }
  getInvoices = () => {
    const { filterPlaceHolder } = this.state;
    this.setState({ listProgress: true });
    const page = this.tableOptions.pagination.current || 1;
    const perPage = this.state.recordsPerPage;
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const { searchBy, searchedOrder } = this.props;
    let data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      page,
      per_page: perPage,
      status: filterPlaceHolder.currentStatus,
      search_value : searchedOrder ? _.trim(searchedOrder) : ""
    };
    
    // if (searchBy === "Invoice") {
    //   data = {
    //     ...data,
    //     invoice_nos: searchedOrder ? _.trim(searchedOrder) : "",
    //   };
    // } else {
    //   data = {
    //     ...data,
    //     customer_order_number: searchedOrder ? _.trim(searchedOrder).replace(/\s/g, "") : "",
    //   };
    // }
    if (isEmpty(searchedOrder) && filterPlaceHolder.viewType === "byOrder") {
      data = {
        ...data,
        account_id: filterPlaceHolder.accountCode,
        from_date: formattedFromDate,
        to_date: formattedToDate,
        wh_location_id: filterPlaceHolder.wh_location_id,
        sort_by: filterPlaceHolder.sortBy,
        sort_order: I18n.t(`general.sort_${filterPlaceHolder.sortByType}`),
        approval_date: filterPlaceHolder.approvalDate,
        frequency_type: filterPlaceHolder.frequencyType 
      };
    }

    fetchIndividualInvoices(data)
      .then((result) => {
        if (result.success) {
          const account_invoices = result.invoices || [];
          const stats = result.stats || {};
          this.props.updateStatsData(stats);
          if (account_invoices.length === 0) {
            alertMessage(I18n.t("messages.no_record_found"), "error", 5);
          }
          this.setState(
            {
              account_invoices,
              data: result.stats || {},
              pagination:
                account_invoices.length > 0 && result.pagination
                  ? result.pagination
                  : {},
              selectedInfo: {
                selectedKeys: [],
                selectedRows: [],
              },
            },
            () => { 
              this.props.updateContext(this.state.pagination, this.tableOptions)
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({ pagination: {} });
        }
      })
      .finally(() => {
        this.setState({
          listProgress: false,
          currentProccessedIndex: -1,
          currentProcessedId: null,
          editedInvoiceNo: "",
        }),
          this.props.resetSearchedKeyPress();
          this.props.handleCompletedDateChanges(false);
      });
  };

  handleDeleteClick = (ids) => {
    this.setState({ inProgress: true });
    const data = {
      invoice_id: ids,
    };
    deleteInvoice(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.deleted"));
          this.getInvoices();
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        this.setState({ inProgress: false });
      });
  };

  handleInvoiceNoEdit = (index, id) => {
    const { account_invoices } = this.state;
    this.setState({
      currentProccessedIndex: index,
      currentProcessedId: id,
      editedInvoiceNo: account_invoices[index].frequency_invoice_number,
    });
  };

  resetParticularRecField = (updatedInvGenDate, id) => {
  this.setState((prevState) => {
    const accountInvoices = [...prevState.account_invoices];
    const invoiceIndex = accountInvoices.findIndex((invoice) => invoice.id === id);

    if (invoiceIndex >= 0) {
      accountInvoices[invoiceIndex].approval_date = updatedInvGenDate;
      return { account_invoices: accountInvoices };
    }

    // Return the existing state if the array is empty or index is out of bounds
    return prevState;
  });
};

getFewerInvoices = (incomingNo) => {
  this.setState({ listProgress: true });
  const { filterPlaceHolder } = this.state;
  const page = this.tableOptions.pagination.current || 1;
  const perPage = this.state.recordsPerPage;
  const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
  const formattedToDate = formatDate(filterPlaceHolder.toDate);
  let data = {
    organization_id: userStore.getStateValue("selectedOrg"),
    page,
    per_page: perPage,
    status: filterPlaceHolder.currentStatus,
  };

  if (!_.isEmpty(this.props.searchedOrder)) {
    // if (this.props.searchBy === "Invoice") {
      data.search_value = this.props.searchedOrder.replace(/\s/g, "");
    // } else {
    //   data.customer_order_number = this.props.searchedOrder.replace(/\s/g, "");
    // }
  } else {
    // Add all other fields only if searchedOrder is empty
    data = {
      ...data,
      account_id: filterPlaceHolder.accountCode,
      from_date: formattedFromDate,
      to_date: formattedToDate,
      search_value: this.props.searchedOrder.replace(/\s/g, ""),
      wh_location_id: filterPlaceHolder.wh_location_id,
      sort_by: filterPlaceHolder.sortBy,
      sort_order: I18n.t(`general.sort_${filterPlaceHolder.sortByType}`),
      approval_date: filterPlaceHolder.approvalDate,
    };
  }

  fetchIndividualInvoices(data).then((result) => {
    if (result.success) {
      const updatedInvoices = _.map(
        this.state.account_invoices,
        (invoice) => {
          if (_.includes(incomingNo, invoice.frequency_invoice_number)) {
            const foundInvoice = _.find(result.invoices, {
              frequency_invoice_number: invoice.frequency_invoice_number,
            });
            return foundInvoice ? foundInvoice : null;
          }
          return invoice;
        }
      ).filter((invoice) => !isEmpty(invoice));
      this.setState({
        account_invoices: updatedInvoices,
        listProgress: false,
      });
    } else if (result.errors) {
      renderAlertMessage(result.errors);
      this.setState({ listProgress: false, pagination: {} });
    }
  });
};

onInvoiceModalUnmount = (orderNos,statuschange) => {
  const statusDidnotChage = statuschange !== undefined ? statuschange : true;
  const invoiceInCurrentList = this.state.account_invoices.filter((invoice) => orderNos.includes(invoice.frequency_invoice_number));
  if (invoiceInCurrentList.length) {
    this.getFewerInvoices(invoiceInCurrentList.map((invoice) => invoice.frequency_invoice_number));
  }
  let status = this.state.filterPlaceHolder.currentStatus.toLowerCase();
  let verifySameStatus = invoiceInCurrentList.every((invoice) => invoice.status.toLowerCase() === status);
  if(statusDidnotChage === false) {
    verifySameStatus = false;
  }
  if(invoiceInCurrentList.length > 0 && !verifySameStatus && !statusDidnotChage ){
    const statsList = ["APPROVED","PENDING"].map((status) => status.toLowerCase());
    let data = {
      ...this.state.data,
      [status]: Math.max(0, this.state.data[status] - invoiceInCurrentList.length),
      [statsList.find(stat => stat !== status)]: this.state.data[statsList.find(stat => stat !== status)] + invoiceInCurrentList.length
    };
    this.setState({ data });
    this.props.updateStatsData(data);
  }
};

  handleInvoiceNoSave = (index, id) => {
    const { editedInvoiceNo } = this.state;

    if (!isEmpty(editedInvoiceNo)) {
      let accountInvoices = [...this.state.account_invoices];
      const selectedRecord = accountInvoices[index];
      const invoiceNo = selectedRecord.frequency_invoice_number;
      const data = {
        organization_id: userStore.getStateValue("selectedOrg"),
        account_id: selectedRecord.account_id,
        invoice_number: invoiceNo,
        new_invoice_number: this.state.editedInvoiceNo,
      };

      if (data.invoice_number !== data.new_invoice_number) {
        this.setState({ inProgress: true });
        fetchUpdatedInvoiceNo(data).then((result) => {
          if (result.success) {
            alertMessage(result.message, "success", 10);
            selectedRecord.frequency_invoice_number = editedInvoiceNo; // edited invoice no will update in UI if it is not duplicate
            this.setState({
              currentProccessedIndex: -1,
              currentProcessedId: null,
              editedInvoiceNo: "",
              inProgress: false,
            });
          } else {
            alertMessage(result.errors, "error", 10);
            this.setState({
              inProgress: false,
              currentProccessedIndex: -1,
              currentProcessedId: null,
              editedInvoiceNo: "",
            });
          }
        });
      } else {
        this.setState({
          currentProccessedIndex: -1,
          currentProcessedId: null,
          editedInvoiceNo: "",
        });
      }
    } else {
      alertMessage("Please Enter Invoice No.", "error", 10);
    }
  };

  handleInvoiceNoCancel = (index, id) => {
    this.setState({
      currentProccessedIndex: -1,
      currentProcessedId: null,
      editedInvoiceNo: "",
    });
  };
  handleKeyPress =(e)=>{
    if (e.key === ' ') {
    e.preventDefault();
  }
  }

  _renderList = () => {
    const { selectedKeys } = this.state.selectedInfo;
    const dataPagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    const { currentStatus } = this.state.filterPlaceHolder;
    const rowSelection = {
      selectedRowKeys: selectedKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: (record) => {
        return ({
          disabled: findIfInvoicePartiallyPaid(record), // Column configuration not to be checked
        })
      },
    };
    const hasStatusChangeAccess = checkServiceExistance("PITQB");
    const { organizationSettings } = this.state
    const { displayConfiguration, ordersListProps, screen_from } = this.props
    return (
      <Fragment>
        {/* <IndividualList
        rowKey="customer_order_id"
        size="small"
        data={this.state.account_invoices}
        pagination={dataPagination}
        tableChange={(pagination, filter, sorter, currentTable) =>
          this.onTableChange(pagination, filter, sorter, currentTable)
        }
      /> */}
        <Row className="marginBottom5">
          <Col>
            <Row>
              <Col xs={20} >
                 <Row type="flex" justify="end" gutter={16}>
                    <Col>
                      <SendInvoice />
                    </Col>
                    <Col className="alignRight" style={{ marginTop: -5 }}>
                    <ExportForm />
                    </Col>
                 </Row>
              </Col>
              <Col xs={4}>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    onSearch={this.onSearch}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <GroupByInvoice
          currentOrg={this.props.currentOrg}
          userContext={this.props.userContext}
          rowKey="id"
          size="small"
          scroll={{ y: "calc(100vh - 365px)" }}
          onKeyPress={this.handleKeyPress}
          accounts={this.props.accounts}
          filterPlaceHolder={this.props.filter}
          data={this.state.account_invoices}
          pagination={dataPagination}
          rowSelection={
            hasStatusChangeAccess //&& currentStatus === "PENDING"
              ? rowSelection
              : undefined
          }
          tableChange={(pagination, filter, sorter, currentTable) =>
            this.onTableChange(pagination, filter, sorter, currentTable)
          }
          refreshList={this.getInvoices}
          deleteClick={this.handleDeleteClick}
          onEditInvoiceNo={this.onEditInvoiceNo}
          currentProccessedIndex={this.state.currentProccessedIndex}
          currentProcessedId={this.state.currentProcessedId}
          handleInvoiceNoEdit={this.handleInvoiceNoEdit}
          handleInvoiceNoSave={this.handleInvoiceNoSave}
          handleInvoiceNoCancel={this.handleInvoiceNoCancel}
          editedInvoiceNo={this.state.editedInvoiceNo}
          handleKeyDown={this.handleKeyDown}
          // navigateToAccount={(id) => this.handleNavigation(id)}
          navigateToAccount={(id) =>
            this.props.handleAccountNavigation(id, this.state.pagination, this.tableOptions)
          }
          organizationSettings={organizationSettings}
          displayConfiguration={displayConfiguration}
          ordersListProps={ordersListProps}
          screen_from={screen_from}
          currentStatus={currentStatus}
          checkList={this.state.checkList}
          enableChecklistApproval={this.state.enableChecklistApproval}
          warehouseFilter={this.props.warehouseFilter}
          resetParticularFied={this.resetParticularRecField}
          onUnmount = {this.onInvoiceModalUnmount}
          selectedInfo={this.state.selectedInfo}
        />
        {hasStatusChangeAccess && selectedKeys.length > 0 && (
          <BulkOperations
            checkList={this.state.checkList}
            selectedInfo={this.state.selectedInfo}
            statusLoading={this.state.statusLoading}
            handleCancel={() => this.handleSelectChange([], [])}
            handleSuccess={this.handleAppproveSuccess}
            currentStatus={currentStatus}
            enableChecklistApproval={this.state.enableChecklistApproval}
          />
        )}
      </Fragment>
    );
  };

  handleAppproveSuccess = (status="",invoiceNos,statusChange) => {
    this.handleSelectChange([], []);
    this.onInvoiceModalUnmount(invoiceNos,statusChange);
  };

  handleStateOnChange = (element, value, cb = null) => {
    this.setState(
      {
        [element]: value,
      },
      () => {
        if (cb) {
          cb();
        }
      }
    );
  };
  handleSelectChange = (selectedKeys, selectedRows) => {
    this.setState({
      selectedInfo: Object.assign({}, this.state.selectedInfo, {
        selectedKeys: selectedKeys,
        selectedRows: selectedRows,
      }),
    });
  };

  onEditInvoiceNo = (index, element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleKeyDown = (index, id, e) => {
    if (e.key === "Enter") {
      this.handleInvoiceNoSave(index, id);
    }
  };

  render() {
    return (
      <div className="content-outer">
        <div className="content">
          <Spin
            spinning={this.state.listProgress || this.state.inProgress}
            delay={1000}
          >
            <this._renderList />
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
        {this.state.detailsVisible && (
          <Drawer
            title={`${I18n.t("order.details")} ${this.state.currentOrder.customer_order_number
              ? `(#${this.state.currentOrder.customer_order_number})`
              : ""
              }`}
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.detailsVisible}
            width="85%"
          >
            <OrderDetails
              currentOrder={this.state.currentOrder}
              order_id={this.state.selectedOrderId}
              surveyDetails={(id) => this.handleSurveyDetails(id)}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

const IndividualOrdersComponent = withRouter((props) => {
  const { orgSettings: organizationSettings, currentOrg } = useContext(OrgContext);
  const userContext= useContext(UserContext);
  const perPageKey = ScreenKeys.BILLING_SCREEN_LISTING;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return (
    <IndividualOrders
      currentOrg={currentOrg}
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default IndividualOrdersComponent;