import {  Button, Col, Drawer, Dropdown, Icon, Modal, Row, Select, Skeleton, Spin } from 'antd'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import { fetchLocations } from '../api/LocationsApi'
import { ReleasesApi } from '../api/ReleasesApi'
import { fetchUsers } from '../api/UsersApi'
import { alertMessage, refreshIcon } from '../common/Common'
import I18n from '../common/I18n'
import { Search } from "../common/UIComponents";
import BaseModal from '../components/BaseModal'
import ReleasesList from '../components/releases/ReleaseList'
import ReleaseFilter from '../components/releases/ReleaseFilter'
import AppConfig from '../config/AppConfig'
import { isEmpty } from '../common/Common'
import AppliedFiltersTags from './AppliedFiltersTags'
import { checkServiceExistance, convertToTags, selectedWarehouseNames, limitString, removeSpecialCharacters, getOrderInfo, renderAlertMessage } from '../helpers/common'
import ReleaseActions from '../components/releases/ReleaseActions'
import ChangeStatusForm from '../components/releases/ChangeStatusForm'
import ReasonWindow from '../components/common/ReasonWindow'
import ReleaseStats from '../components/releases/ReleaseStats'
import { fetchShortFormAccounts } from '../api/Account'
import { OrgContext } from '../context/OrgContext';
import { WarehouseContext } from '../context/WarehouseContext';
import RecoveryPlanForm from './RecoveryPlanForm';
import ReleasesPlanForm from '../components/releases/ReleasesPlanForm'
import { DisplaySettingsContext } from '../context/DisplaySettings'
import { RecordsPerPage } from '../components/orders/RecordsPerPage'
import { defaultSearchReleaseFilter } from '../components/dispatches/helpers'
import _ from "lodash";
import EditOrderFormComponent from '../components/orders/EditOrderForm';
import { addAnyStopToRoute, updateRouteInMiddle } from '../api/Routes'
import { checkIfOrdershasExceededCreditLimit } from '../components/orders/helpers';
import { ScreenKeys } from './constants';
import { UserContext } from '../context/UserContext';

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} }
function Releases(props) {
  const { reqFrom = "menu",  route = null , creditsExceededAccnts } = props;
  const defaultStatus =  'pending'
  const isDispatchScreen = reqFrom === "dispatch";

  const [warehouses, setWarehouses] = useState(props.warehouseFilter.warehouses)
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [drivers, setDrivers] = useState([])
  const [releases, setReleases] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAssigning, setIsAssigning] = useState(false)
  const [pagination, setPagination] = useState({ current_page: 1 })
  const [isInitial, setIsInitial] = useState(true)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false)
  const [showReasonWindow, setShowReasonWindow] = useState(false)
  const [reasonValue, setReasonValue] = useState('')
  const [deletionIds, setDeletionIds] = useState([])
  const [selectedInfo, setSelectedInfo] = useState({
    selectedKeys: [],
    selectedRows: [],
  })
  const [searchKeyPress, setSearchKeyPress] = useState(false)
  const [showFilterWindow, setShowFilterWindow] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const defaultFilter = defaultSearchReleaseFilter(props, defaultStatus)
   const [filterPlaceHolder, setFilterPlaceHolder] = useState({...defaultFilter})
  const [searchBy, setSearchBy] = useState("order_number");
  const [searchText, setSearchText] = useState('')
  const [appliedFiltersTags, setAppliedFiltersTags] = useState([])
  const [currentStatus, setCurrentStatus] = useState(defaultStatus)
  const [updateSearchTags, setUpdateSearchTags] = useState(false)
  const [statsData, setStatsData] = useState({});
  const[dispSetting,setDispSetting] = useState({})
  const userContext = useContext(UserContext)
  const { currentUser , updateRecordsPerPage } = userContext
  const perPageKey = isDispatchScreen ?  ScreenKeys.RELEASES_LISTING_DISPATCH : ScreenKeys.RELEASES_LISTING 
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved)
  const [ showEditForm, setShowEditForm ] = useState(false)
  const[selectedWarehouse, setSelectedWarehouse] = useState(props.warehouseFilter.selectedWarehouses)

    const dynamicDateObject = {
      fromDate: moment(filterPlaceHolder.dateSearchValue.fromDate).format(
        "YYYY-MM-DD"
      ),
      toDate: moment(filterPlaceHolder.dateSearchValue.toDate).format(
        "YYYY-MM-DD"
      ),
    };

    const defaultDateObject = {
      fromDate: moment(defaultFilter.dateSearchValue.fromDate).format(
        "YYYY-MM-DD"
      ),
      toDate: moment(defaultFilter.dateSearchValue.toDate).format("YYYY-MM-DD"),
    };


    const hasWareHouseChanged = (prevWarehouse, currentWarehouse) => {
      return prevWarehouse !== currentWarehouse;
    }


  useEffect(() => {
    debouncedReleases();
  }, [recordsPerPage]);

  const recordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };
  
  const onSearch = (value) => {
  };
  
  const assignRoute = () => {
    if(route && route.id){
      setIsAssigning(true);
      const data = Object.assign({}, data, {
        nav_route_id: route.id,
        required_ids: selectedInfo.selectedKeys,
        stop_type: 'RL',
      });
      addAnyStopToRoute(data).then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.allocated_successfully'), 'success', 10);
            // props.closeSearchOrders(true, route.id, props.actionType);
            handleSelectChange([], [])
            debouncedReleases()
            setIsAssigning(false)
            
        } else {
          renderAlertMessage(result.errors) 
          setIsAssigning(false)
        }
      });
    }
  }
    



  useEffect(()=>{
    setDispSetting(props.displayConfiguration)
  },[props.displayConfiguration])

  const computeTags = (filter) => {
    if(!filter.warehouse_id){
      filter.warehouse_id = 'ALL'
    }
    
    const tagsInfo = convertToTags(
      {...filter, currentStatus: filter.currentStatus !== '' ? filter.currentStatus :  I18n.t('general.all')},
      ['warehouse_id', 'account_code', 'zone_ids', 'currentStatus', 'dateSearchBy', I18n.t("recoveries.schedule_times.type" ), 'release_type'],
      {
        warehouse_id: {
          label: 'Warehouse',
          value:
          selectedWarehouseNames(props.warehouseFilter),
          onClose: () => {},
          // closeable: props.warehouseFilter.warehouses.length > 1 ? true : false,
          closeable: false,
        },
        account_code: {
          label: I18n.t('general.account'),
          value:
          accounts.find(
            (acc) => acc.code === filter.account_code,
          )?.name || '',
          onClose: () => {handleOnFilterRemoval('account_code')},
          closeable: true,
        },
        zone_ids: {
        label: I18n.t('general.zone'),
        value: filter?.zone_ids && filter.zone_ids.length > 0 ? limitString(filter.zone_ids.map(zone => zone.label).join(', '),5 ): 'ALL',
        onClose: () => {handleOnFilterRemoval('zone_ids')},
        closeable: filterPlaceHolder.zone_ids.length > 0 ? true : false,
        },
        currentStatus: {
          label: 'Status',
          value: currentStatus !== ''
              ? I18n.t(`releases.${currentStatus}`)
              : I18n.t('general.all'),
          onClose: () => {handleOnFilterRemoval('currentStatus')},
          closeable: currentStatus === "pending" ? false : true,
        },
        dateSearchBy: {
          label:
            filter.dateSearchBy === 'scheduled_release_date'
              ? I18n.t('releases.scheduled_release_date')
              : I18n.t('releases.actual_release_date'),
          // value: filter.dateSearchValue ? moment(filter.dateSearchValue).format(
          //   'MMM DD, YYYY') : null,
          value: `${moment(filter.dateSearchValue.fromDate).format(
          'MMM DD, YYYY',
        )} - ${moment(filter.dateSearchValue.toDate).format(
          'MMM DD, YYYY',
        )}`,
          onClose: () => {handleOnFilterRemoval('dateSearchBy')},
          closeable: _.isEqual(dynamicDateObject, defaultDateObject) ? false : true,
        },
        ...(filter.dateSearchBy === 'scheduled_release_date' && 
        { 
          [I18n.t("releases.schedule_times.type")] : {
          label: I18n.t("releases.schedule_times.label"),
          value: I18n.t(`releases.schedule_times.${filter[I18n.t("releases.schedule_times.type")]}_label`),
          onClose: () => {handleOnFilterRemoval(I18n.t("recoveries.schedule_times.type" ))},
          closeable: filterPlaceHolder.schedule_orders_type === 'BOTH' ? false : true,
        }
        }
        ),
        release_type: {
          label: I18n.t('releases.release_type.label'),
          value: I18n.t(`releases.${filter.release_type}`), // filter.release_type.split("_").join(" "),
          onClose: () => {handleOnFilterRemoval('release_type')},
          closeable: filterPlaceHolder.release_type === 'both' ? false : true,
        }
      },
    )
    
    setAppliedFiltersTags(tagsInfo)
  }

  const handleOnFilterRemoval = (key) => {
    const filterVariable = { ...filterPlaceHolder };
    switch (key) {
      case "account_code":
        filterVariable.account_code = defaultFilter.account_code;
        break;
      case "zone_ids":
        filterVariable.zone_ids = defaultFilter.zone_ids;
        break;
      case "currentStatus":
        filterVariable.currentStatus = defaultFilter.currentStatus;
        break;
      case "dateSearchBy":
        filterVariable.dateSearchBy = defaultFilter.dateSearchBy;
        filterVariable.dateSearchValue = {
          fromDate: defaultFilter.dateSearchValue.fromDate,
          toDate: defaultFilter.dateSearchValue.toDate,
        };
        break;
      case I18n.t("recoveries.schedule_times.type" ):
        filterVariable.schedule_orders_type =
          defaultFilter.schedule_orders_type;
        break;
      case 'release_type':
        filterVariable.release_type =
        defaultFilter.release_type
      default:
        break;
    }
    computeTags(filterVariable);
    setFilterPlaceHolder(filterVariable);
  };

  useEffect(() => {
    if (searchKeyPress) {
      debouncedReleases()
      computeTags(filterPlaceHolder)
    }
  }, [searchKeyPress])

  useEffect(() => {
    if (!showEditForm) {
      debouncedReleases()
    }
  }, [showEditForm])

  const handleStatusChange = (status, ids) => {
    if (ids.length > 0) {
      const data = {
        recovery_ids: ids,
        status: status,
      }

      ReleasesApi.changeStatus(data).then((result) => {
        if (result.success) {
          alertMessage(
            `${I18n.t('messages.changed_successfully', {
              item: I18n.t('general.status'),
            })}`,
            'success',
            5,
          )
          setReleases(
            releases.map((item, i) => {
              if (ids.includes(item.id)) {
                return { ...item, status: status }
              }
              return item
            }),
          )
        }
      })
    }
  }

  const handleVisibleChange = (flag) => {
    setShowFilterWindow(flag)
  }
  const handleFilterPlaceHolderChange = (filter, cb) => {
    setFilterPlaceHolder(Object.assign({}, filter))
    if (cb) cb()
  }

  const handleOnStatusChange = (element, value) => {
    setSelectedInfo({
      selectedKeys: [],
      selectedRows: [],
    });
    // setFilterPlaceHolder({
    //   ...filterPlaceHolder,
    //   currentStatus: value,
    // });
    setCurrentStatus(value);
  };

  const updateFilterSearchValues = (filter, cb) => {
    const acc = accounts.find((account) => account.code === filter.account_code)
    setSelectedAccount(acc)
    setFilterPlaceHolder(Object.assign({}, filter))
    handleSearch(filter)
  }

  useEffect(() => {
    tableOptions.pagination.current = 1
    setWarehouses(props.warehouseFilter.warehouses)
  }, [props.warehouseFilter.warehouses])

  
  const handleSearch = (filter) => {
    setCurrentStatus(filter.currentStatus)
    setShowFilterWindow(false)
    setSearchText('')
    setIsSearching(false);
    tableOptions.pagination.current = 1
    // debouncedReleases()
    computeTags(filter)
  }

   useEffect(() => {
     if (!isInitial) {
       tableOptions.pagination.current = 1;
       debouncedReleases();
       computeTags(filterPlaceHolder);
     }
   }, [currentStatus]);

   const navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(accounts, ['code', account_code])
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, accounts[accountIndex])
      const account_id = !isEmpty(accountData.primary_account_id)
        ? accountData.primary_account_id
        : accountData.id
      props.history.push(`/accounts/${account_id}/settings`)
    }
  }

  const renderSearchBar = (showTitle = false) => {
    return (
      <div className="headerOptionDiv">
          {
            showTitle && <span className="textBold">
              {I18n.t("general.search")}:&nbsp;&nbsp;
            </span>
          }
        <Search
          placeholder={I18n.t("order.filters.placeholder")}
          className="seachWithDropDown recoverySearch"
          size="default"
          value={!isEmpty(searchText) ? searchText.toUpperCase() : ''}
          onSearch={(value) => {
            setSearchText(value);
            if (!isDispatchScreen) {
              setCurrentStatus("");
            }
            setSearchKeyPress(true);
            setUpdateSearchTags(true);
            setIsSearching(value.length > 0);
          }}
          onChange={(e) => {
            const filteredString = removeSpecialCharacters(e.target.value);
            setSearchText(filteredString)
          }}
          style={{ width: 500, textTransform: 'uppercase' }}
          allowClear
          suffix={
            <>
              {/* {searchText !== '' && (
                <Icon
                  type="close"
                  onClick={() => {
                    setSearchText('')
                    setSearchKeyPress(true)
                  }}
                />
              )} */}
              <Dropdown
                onVisibleChange={handleVisibleChange}
                visible={showFilterWindow}
                trigger={['click']}
                overlay={
                  <div
                    style={{
                      backgroundColor: 'white',
                      width: 500,
                      padding: 15,
                      marginTop: 10,
                      marginRight: -12,
                    }}
                    className="boxShadow"
                  >
                    <ReleaseFilter
                      accounts={accounts}
                      warehouses={warehouses}
                      filterPlaceHolder={filterPlaceHolder}
                      //setFilter={handleFilterPlaceHolderChange}
                      //setSelectedAccount={setSelectedAccount}
                      // onSearch={handleSearch}
                      setCurrentStatus={setCurrentStatus}
                      onOrderNoSearch={null}
                      setSearchText={setSearchText}
                      resetFilter={clearFilter}
                      setFilterPlaceHolder={updateFilterSearchValues}
                      defaultFilter={{ ...defaultFilter }}
                      showTypeFilter={!isDispatchScreen}
                      showDateFilter={!isDispatchScreen}
                    />
                  </div>
                }
              >
                <Icon
                  type="control"
                  onClick={() => setShowFilterWindow(true)}
                />
              </Dropdown>
            </>
          }
          prefix={
            // <Icon type="search" onClick={() => setSearchKeyPress(true)} />
            <Icon
              type="search"
              onClick={() => {
                setSearchKeyPress(true);
                setIsSearching(searchText.length ? true : false);
                setUpdateSearchTags(true);
              }}
            />
          }
          // prefix={
          //   <Row type="flex" align="middle" gutter={4}>
          //     <Col>
          //       <Icon
          //         type="search"
          //         onClick={() => setSearchKeyPress(true)}
          //       />
          //     </Col>
          //     <Col>
          //       <Select
          //         size="small"
          //         onChange={(e) => {
          //           //setSearchOrderToken('');
          //           setSearchBy(e)
          //         }}
          //         style={{
          //           textAlign: 'center',
          //           fontSize: '14px',
          //           lineHeight: '1',
          //           width: '100%',
          //         }}
          //         defaultValue={searchBy}
          //       >
          //         <Select.Option key="hawb" value="hawb">
          //           {I18n.t('general.hawb')}
          //         </Select.Option>
          //         <Select.Option key="mawb" value="mawb">
          //           {I18n.t('general.mawb')}
          //         </Select.Option>
          //         <Select.Option key="order_number" value="order_number">
          //           {I18n.t('general.order')}
          //         </Select.Option>
          //       </Select>
          //     </Col>
          //   </Row>
          // }
        />
      </div>
    )
  }

  const getAccounts = (whId) => {
    const {warehouseFilter} = props;
    const accountsData =  warehouseFilter.findMatchingAccounts(whId)
     setAccounts(accountsData);
  }

  const getDrivers = () => {
    setIsLoading(true)
    fetchUsers('driver', 1, 100000)
      .then((result) => {
        if (result.success) {
          const users = result.users || []
          setDrivers(users)
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getReleases = () => {
    setIsLoading(true)
    const sortByType = filterPlaceHolder.sortByType || "ascend";
    if (warehouses.length > 0) {
      const page =
        tableOptions.pagination?.current &&
        !isEmpty(tableOptions.pagination.current)
          ? tableOptions.pagination.current
          : 1
      const perPage = recordsPerPage
      const payload = {}
      if (!isEmpty(searchText)) {
        payload[searchBy] = _.trim(searchText)
        payload.status = currentStatus;
      } else {
        // if (!isEmpty(props.warehouseFilter.selectedWarehouse)) {
        //   payload.warehouse_code = selectedWH.location_code;
        // }
        // else{
        //   payload.warehouse_code = "";
        // }
        if (!isEmpty(filterPlaceHolder.account_code)) {
          payload.account_code = selectedAccount?.code ? selectedAccount.code : '';
        }
        payload.zone_ids = filterPlaceHolder.zone_ids.map(rec => rec.value).join(',');
        payload.status = currentStatus
        payload[`${filterPlaceHolder.dateSearchBy}_from_date`] =
          !isEmpty(filterPlaceHolder.dateSearchValue) ? moment(filterPlaceHolder.dateSearchValue.fromDate).format("YYYY-MM-DD") : null;
        payload[`${filterPlaceHolder.dateSearchBy}_to_date`] =
          !isEmpty(filterPlaceHolder.dateSearchValue) ? moment(filterPlaceHolder.dateSearchValue.toDate).format("YYYY-MM-DD") : null;
        if (filterPlaceHolder.dateSearchBy === "scheduled_release_date"){
          payload[I18n.t("releases.schedule_times.type")] =
            filterPlaceHolder[I18n.t("releases.schedule_times.type")];
        }
        payload.release_type = filterPlaceHolder.release_type;
        payload.sort_by = filterPlaceHolder.sortBy;
        payload.sort_order = I18n.t(`general.sort_${sortByType}`);
      }
      ReleasesApi.fetch(true, '', page, perPage, '', payload)
        .then((result) => {
          if (result.success) {
            const releases = result.releases || []
            setReleases(releases)
            setSearchKeyPress(false)
            setIsInitial(false)
            setPagination(result.pagination)
            setStatsData(!isEmpty(result.stats) ? result.stats : {})
            handleSelectChange([], [])
          } else {
            setIsInitial(false)
            setStatsData({});
            setSearchKeyPress(false)
            renderAlertMessage(result.errors)
          }
        })
        .finally(() => {
          setIsLoading(false)
          //setCurrentStatus(filterPlaceHolder.currentStatus)
        })
    }
  }

  useEffect(() => {
    // getWarehouses()
    getDrivers()
    // debouncedReleases()
    // computeTags(filterPlaceHolder)
  }, [])

  const debouncedReleases = _.debounce(getReleases, 100)

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  }

  const handleOnFilterElementChange = (element, value) => {
    if (element === "sortBy") {
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || "ascend",
      });
      setFilterPlaceHolder(filter);
    } else {
      const filter = Object.assign({}, filterPlaceHolder, {
        [element]: value,
      });
      setFilterPlaceHolder(filter);
    }
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }
    if (tableOptions.pagination.current !== currentPage) {
      setSearchKeyPress(true);
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      handleOnFilterElementChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
    }
    // debouncedReleases()
  }

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedInfo({
      ...selectedInfo,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    })
  }

  const setDeletionElements = (ids) => {
    setDeletionIds(ids)
    setReasonValue('')
    setShowReasonWindow(true)
  }
  const resetDeletionElements = () => {
    setDeletionIds([])
    setReasonValue('')
    setShowReasonWindow(false)
  }

  const handleDelete = () => {
    setIsLoading(true)
    ReleasesApi.bulkDelete(deletionIds)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'), 'success', 10)
          resetDeletionElements()
          debouncedReleases()
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const [currentOrderObject, setCurrentOrder] = useState({})
  const handleReleaseEdit = (data) => {
    getOrderInfo(data.customer_order_id).then((orderInfo) => {
      setCurrentOrder(orderInfo);
      setShowEditForm(true);
    })
    // const { customer_order_number } = data
    // props.history.push({
    //   pathname: '/orders',
    //   search: '?activeTab=releases',
    //   filterPlaceHolder: {
    //     search_order_token: customer_order_number,
    //   },
    //   searchType: 'byOrders',
    //   orderObject: { ...data, id: data.customer_order_id },
    //   showQuoteOrder: true,
    // })
  }
  const clearFilter = () => {
    setSearchText('')
    setIsSearching(false);
    const defFilter = defaultSearchReleaseFilter(props, defaultStatus)
    const updatedFilter = {
      ...filterPlaceHolder,
      // warehouse_id: warehouses.length > 0 ? warehouses[0].id : '',
      ...defFilter,
     // warehouse_id: '',
    }
    
    setCurrentStatus(defaultStatus);
    setSelectedAccount(null)
    setFilterPlaceHolder(updatedFilter)
    computeTags(updatedFilter)
    setSearchKeyPress(true)
  }

  const clearSearch = () =>{
     setSearchText("");
     setIsSearching(false);
     clearFilter();
  }

  const [ showRouteCreationModal, setShowRouteCreationModal ] = useState(false) 

  const handleCreateRelease = () => {
    setShowRouteCreationModal(true)
  }

  //  on filter change and made appropriate changes
  useEffect(() => {
      debouncedReleases()
      getAccounts(props.warehouseFilter.selectedWarehouses);
      computeTags(filterPlaceHolder)
  }, [filterPlaceHolder])

  useEffect(() => {
    getAccounts(props.warehouseFilter.selectedWarehouses);
  }, [props.warehouseFilter.selectedWarehouses, props.warehouseFilter.warehouseWithAccounts])


  useEffect(() => {
    if (hasWareHouseChanged(props.warehouseFilter.selectedWarehouses, selectedWarehouse)) {
      setSelectedWarehouse(props.warehouseFilter.selectedWarehouses)
      const defFilter = defaultSearchReleaseFilter(props, defaultStatus)
      const updatedFilter = {
        ...filterPlaceHolder,
         ...defFilter,
      }
      setFilterPlaceHolder(updatedFilter)
     }
  }, [props.warehouseFilter.selectedWarehouses])

  const recomputeData = (data) => {
    const accountCodesWithExceededLimit = creditsExceededAccnts.map(account => account.account_code)
    return data.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    }
    );
  }

  const renderStatusChangeModal = () => (
    <BaseModal
      title={I18n.t('general.change_status')}
      onCancel={() => {
        setShowChangeStatusModal(false)
        debouncedReleases()
      }}
      visible={showChangeStatusModal}
      maskClosable={false}
      width={600}
    >
      <ChangeStatusForm
        currentStatus={currentStatus}
        saveStatus={() => {
          setShowChangeStatusModal(false)
          handleSelectChange([], [])
          debouncedReleases()
        }}
        closeModal={() => {
          setShowChangeStatusModal(false)
          debouncedReleases()
        }}
        releases={[...selectedInfo.selectedKeys]}
        processErrors={[...selectedInfo.selectedRows]}
        hasBulkOrders={true}
        isMilitaryTime={true}
        responseData={[...selectedInfo.selectedRows]}
        selectedWarehouse={selectedWarehouse}
      />
    </BaseModal>
  )


  const recomputeSelectedInfo = (selectedInfo) => {
    const validatedOrdersLimits = checkIfOrdershasExceededCreditLimit(selectedInfo.selectedRows, props.creditsExceededAccnts);
    const orderErrors = validatedOrdersLimits.filter(order => order.account_limit_exceeded).map(order => `${order._id}`);
    if (orderErrors.length > 0) {
      const newSelectedKeys = selectedInfo.selectedKeys.filter(key => !orderErrors.includes(key));
      const newSelectedRows = selectedInfo.selectedRows.filter(row => !orderErrors.includes(row._id));
      return {
        selectedKeys: newSelectedKeys,
        selectedRows: newSelectedRows,
      };
    } else {
      return selectedInfo;
    }
  };

  const renderRouteCreationModal = () => (
    <BaseModal
      title={ I18n.t('routes.create') }
      onCancel={ () => {
        setShowRouteCreationModal(false);
        debouncedReleases();
      } }
      visible={ showRouteCreationModal }
      width={selectedInfo.selectedKeys.length > 0 ? '45%' : '55%'}
      style={{
        top: 10,
      }}
      bodyStyle={{
        height: selectedInfo.selectedKeys.length > 0 ? 'auto' : 'auto',
      }}
      maskClosable={false}
    >
      <ReleasesPlanForm
        warehouses={ warehouses }
        // selectedInfo={ selectedInfo }
            selectedInfo={ recomputeSelectedInfo(selectedInfo) }
        drivers={ drivers }
        filterPlaceHolder={ filterPlaceHolder }
        onClose={ (requireUpdate) => {
          setShowRouteCreationModal(false);
          if (requireUpdate) {
            handleSelectChange([], []);
            debouncedReleases();
          }
        } }
        screenName={ 'releases' }
        defaultWhId={props.warehouseFilter.selectedWarehouses}
      />
    </BaseModal>
  )

  const renderReasonModal = () => {
    return (
      <BaseModal
        title={I18n.t('messages.reason_to_delete')}
        onCancel={() => {
          resetDeletionElements()
        }}
        visible={showReasonWindow}
      >
        <div>
          <ReasonWindow
            reasonElement="reason"
            reasonValue={reasonValue}
            handleOnChange={(elemant, value) => setReasonValue(value)}
            closeReasonModal={resetDeletionElements}
            showButtons={true}
            saveReason={handleDelete}

          />
        </div>
      </BaseModal>
    )
  }
  const checkRouteData = () => {
    if (selectedInfo.selectedRows.length == 0) {
      setShowRouteCreationModal(true)
    } else {
      const whCount = _.uniq(
        selectedInfo.selectedRows.map((rec) => rec.warehouse_code),
      ).length
      if (whCount > 1) {
        alertMessage(
          'Please select recoveries specific to only one warehouse to create the route',
          'error',
          5,
        )
      } else {
        const validatedOrdersLimits = checkIfOrdershasExceededCreditLimit(selectedInfo.selectedRows, props.creditsExceededAccnts);
        const orderErrors = validatedOrdersLimits.filter(order => order.account_limit_exceeded).map(order => `${order.customer_order_number}`);
        // setShowRecoveryForm(true)
        // show modal error
        if (orderErrors.length > 0) {
          Modal.error({
            title: 'Orders Exceeded Credit Limit',
            content: (
              <div className="marginTop10">
                {/* <h4>{ I18n.t('order.lh_err_limit_orders') }</h4> */}
                <span className="textRed textBold">{ orderErrors.join(', ') }</span>
              </div>
            ),
            onOk () {
              if (orderErrors.length !== selectedInfo.selectedKeys.length) {
                  setShowRouteCreationModal(true)
              }
            },
          });
        }else{
          setShowRouteCreationModal(true)
        }
      }
    }
  }
  return (
    <div className="content-outer routes-classes">
      <div className="content">
        {!isDispatchScreen ?
         <Row className="page-header">
          <Col xs={12}>{I18n.t("menu.releases")}</Col>
          <Col xs={12} className="alignRight">
            <Row
              justify="end"
              gutter={16}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Col className="alignLeft">{renderSearchBar()}</Col>
              <Col className="alignRight page-actions">
                {
                checkServiceExistance('ORRC') && 
                <Button
                  onClick={() => checkRouteData()}
                  type={'primary'}
                  icon="plus"
                  size={'default'}
                  className=""
                  disabled={
                    currentStatus !== 'pending'
                  }
                >
                  {I18n.t('routes.create')}
                </Button>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        :
        <Row>
          <Col className="alignLeft" xs={18}>{renderSearchBar(true)}</Col>
          <Col xs={6} className="alignRight">
              <Button
                size="small"
                type="primary"
                onClick={assignRoute}
                loading={isAssigning}
                disabled={selectedInfo?.selectedKeys.length === 0}
              >
                {I18n.t("preplan.allocate")}
              </Button>
            </Col>
        </Row>
        }
        {isInitial ? (
          <Skeleton />
        ) : (
            checkServiceExistance('OREI') ? 
            <Row className="page-content">
              {
              !isDispatchScreen &&
              <Col xs={24}>
                <ReleaseStats
                  filter={currentStatus}
                  handleOnFilterChange={(element, value) =>
                    handleOnStatusChange(element, value)
                  }
                  data={statsData}
                />
              </Col>
              }
              <Col xs={24} className="marginTop marginBottom40">
                <Row type='flex' justify='space-between'>
                  <Col span={24}>
                  <AppliedFiltersTags
                    tags={appliedFiltersTags}
                    clearSearch={() => clearSearch()}
                    clearFilter={() => clearFilter()}
                    isSearching={isSearching}
                    // isSearching={!isEmpty(searchText) ? true : false}
                    searchText={searchText}
                    searchBy={"Order"}
                    updateSearchTags={updateSearchTags}
                    resetSearchTags={() => setUpdateSearchTags(false)}
                  />
                  </Col>
                </Row>
                <Row type="flex" justify='end' gutter={props.actionType ? 16 : 8 }>
                 <Col>
                 <RecordsPerPage
                   onChange={recordChange}
                   onSearch={onSearch}
                   value={recordsPerPage}
                   defaultValue={recordsPerPage}
                 />
                </Col>
                {props.actionType === "currentRoute" &&
               <Col>
                  {refreshIcon(debouncedReleases, true, false)}
                </Col>
                }
              </Row>
                <div className='marginTop10'>
                  <ReleasesList
                    // data={releases}
                    data={ recomputeData(releases) }
                    // scroll ={{y:600}}
                    // scroll = {{y:"calc(100vh - 410px)"}}
                    scroll = {{x:"max-content" ,y:"calc(100vh - 410px)"}}
                    displayConfiguration={dispSetting}
                    screen_from='release'
                    isLoading={isLoading}
                    size="small"
                    showActions={!isDispatchScreen}
                    showStatus={true}
                    handleEditClick={handleReleaseEdit}
                    navigateToAccount= {navigateToAccount}
                    deleteClick={setDeletionElements}
                    pagination={tablePagination}
                    tableChange={(pagination, filter, sorter, currentTable) =>
                      onTableChange(pagination, filter, sorter, currentTable)
                    }
                    // rowClassName={ (record, index) =>
                    //   record.account_limit_exceeded ? "errRow" : ""
                    // }
                    showActionExceedField={props.showActionExceedField === true}
                    filterPlaceHolder={filterPlaceHolder}
                    handleStatusChange={handleStatusChange}
                    currentStatus={currentStatus}
                    rowSelection={
                      (currentStatus === "" || !checkServiceExistance(['OREDEL', 'ORECS'], 'ANY'))
                        ? null
                        : {
                            selectedRowKeys:
                              selectedInfo.selectedKeys.length > 0
                                ? selectedInfo.selectedKeys
                                : [],
                            onChange: handleSelectChange,
                            getCheckboxProps: (record) => ({
                              id: `${record.id}`,
                              disabled : props.actionType === "currentRoute"  && record.account_limit_exceeded ? true : false
                            }),              
                            // disabled: (record) => {
                            //   return props.actionType === "currentRoute" && record.account_limit_exceeded;
                            //   }
                          }
                    }
                    // selectedWH={selectedWH}
                  />
                </div>
                {!isDispatchScreen && currentStatus !== "" && selectedInfo.selectedKeys.length > 0 && (
                  <ReleaseActions
                    filter={currentStatus}
                    selectedInfo={selectedInfo}
                    multipleDeletion={() =>
                      setDeletionElements([...selectedInfo.selectedKeys])
                    }
                    handleClear={() => handleSelectChange([], [])}
                    refreshList={() => debouncedReleases()}
                    setIsLoading={(value) => setIsLoading(value)}
                    changeStatus={() => setShowChangeStatusModal(true)}
                      handleCreateRelease={ handleCreateRelease }
                  />
                )}
              </Col>
            </Row>
            :
            <Fragment />
          
        )}

        {showChangeStatusModal && renderStatusChangeModal()}
        {showReasonWindow && renderReasonModal()}
        { showRouteCreationModal && renderRouteCreationModal() }
        { showEditForm && (
          <Drawer
            placement="right"
            closable={ false }
            maskClosable={ false }
            onClose={ () => setShowEditForm(false) }
            visible={ showEditForm }
            width="90%"
          >
            <EditOrderFormComponent
              callFrom="details"
              customerDetails={ currentOrderObject }
              customerOrderId={ currentOrderObject.id }
              preferences={ [] }
              onCancel={ () => setShowEditForm(false) }
              handleSuccess={ () => { } }
              isNew={ false }
            />
          </Drawer>
        ) }
      </div>
    </div>
  );
}

export const ReleasesComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings , accountsExceeded  }= useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext)
  return (
    <Releases
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      creditsExceededAccnts={ accountsExceeded }
      {...props}
    />
  );
}
);

export default ReleasesComponent;