/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _, { result } from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Button,
  DatePicker,
  Select,
  Card,
  Icon,
  Spin,
  Tabs,
  Search,
  Alert,
  Link,
  Checkbox,
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  formatCode,
  findNumberTotal,
  refreshIcon,
  checkNegitive,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../common/FormErrors";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { BatchPaymentApi } from "../../api/BatchPaymentApi";
import UnpaidInvoicesList from "./UnpaidInvoicesList";
import userStore from "../../stores/UserStore";
import BulkOperations from "./BulkOperations";
// import PaidPayments from "./PaidPayments";
import { ARApi } from "../../api/ARApi";
import BaseModal from "../BaseModal";
import TransactionIndexList from "../account_ar/TransactionIndexList";
import List from "../credit_notes/List";

import PaidPayments from "./PaidInvoices";
import { removeSpecialCharacters, renderAlertMessage } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import { RecordsPerPage } from "../orders/RecordsPerPage";
import PaymentSelectedInfo from "./PaymentSelectedInfo";
import SelectedSummary from "./SelectedSummary";
import { UserContext } from "../../context/UserContext";
import { ScreenKeys } from "../../containers/constants";
import moment from "moment";

class SummaryView extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      orgId: this.props.org_id,
      account: this.props.account || {},
      inProgress: false,
      errors: [],
      unpaidInvoiceLines: [],
      createCreditMemo: false,
      totalLines: [],
      selectedInfo: {
        keys: [],
        rows: [],
      },
      selectedInvoices: [],
      extractedData: {},
      cheques: [],
      selectedCheckInfo: {
        keys: [],
        rows: [],
      },
      selectedCNInfo: {
        keys: [],
        rows: [],
      },
      lineProgress: false,
      activeKey: this.props.listType,
      showCheckForm: false,
      searchToken: props.check_ach_no ? props.check_ach_no : '',
      recordsPerPage: props.recordsPerPage,
      pagination: {},
      payWarnings: [],
      creditNotes: [],
      decimalPoints,
      zeroValue,
      filterPlaceHolder: {
        sortBy: "none",
        sortByType: "descend",
      },
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    // if (this.state.account?.id) {
    //   this.getUnpaidLines();
    //   //this.getOpenCheques();
    // }
    this.getUnpaidLines();
  }

  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ createCreditMemo: isChecked ? true : false }); // Set createCreditMemo to true only if checkbox is checked
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getUnpaidLines();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.getUnpaidLines();
        }
      );
    }
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchToken: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.refreshTabs();
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.listType !== this.state.activeKey) {
      this.setState(
        {
          activeKey: this.props.listType,
        },
        () => {
          this.handleSelectChange([], []);
          this.tableOptions.pagination.current = 1;
          this.refreshTabs();
        }
      );
    }
    if (!_.isEqual(this.props.account, this.state.account)) {
      this.setState({
        account: this.props.account || {}
      }, () => {
        this.tableOptions.pagination.current = 1;
        this.handleSelectChange([], []);
        this.refreshTabs();
      })
      
    }
    if (this.props.check_ach_no !== this.state.searchToken ) {
      this.setState({
        searchToken: this.props.check_ach_no,
      }, () => {
        this.tableOptions.pagination.current = 1;
        this.refreshTabs();
      });
    }
    if(prevProps.searchBy !== this.props.searchBy && !isEmpty(this.state.searchToken)){
      this.tableOptions.pagination.current = 1;
      this.refreshTabs();
    }

  }

  updateSelectedRecords = (lines = []) => {
  const { extractedData, zeroValue } = this.state;

  this.setState({
    totalLines: [...lines],
    unpaidInvoiceLines: lines.map((line) => {
      // Check if the id exists in extractedData
      if (extractedData[line.id]) {
        // If it exists, get the discount, credits, and paid_amount values from extractedData
        const { discount, credits, paid_amount } = extractedData[line.id];
        return {
          ...line,
          discount: discount,
          credits: credits,
          paid_amount: paid_amount,
          is_modified: false,
        };
      } else {
        // If it doesn't exist, set discount and credits to zeroValue and calculate paid_amount
        return {
          ...line,
          discount: zeroValue,
          credits: zeroValue,
          paid_amount: checkNegitive(line.balance_invoice_amount, false),
          is_modified: false,
        };
      }
    }),
    // selectedInfo: {
    //   keys: [],
    //   rows: [],
    // },
    payWarnings: [],
  });
}

  getUnpaidLines = () => {
    const { account, searchToken, selectedInfo, orgId } = this.state;
    this.setState({ lineProgress: true });
    const page = this.tableOptions.pagination.current;
    const perPage = this.state.recordsPerPage; // removed AppConfig.ordersPerPage to dynamically adjust record length
    ARApi.fetchInvoiceDetails({
      organization_id: orgId,
      account_id: account?.id ? account.id : '',
      check_ach_no: searchToken,
      view_type: "SUMMARY",
      page,
      perPage,
      search_type: !isEmpty(searchToken) ? this.props.searchBy : '',
      sortBy: this.state.filterPlaceHolder.sortBy,
      sortByType: I18n.t(`general.sort_${this.state.filterPlaceHolder.sortByType}`),
    }).then((result) => {
      if (result.success) {
        const lines = result.ar_invoices || [];
        this.updateSelectedRecords(lines);
        this.setState({
          lineProgress: false,
          pagination: lines.length > 0 ? result.pagination : {},
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          lineProgress: false,
          pagination: {},
          totalLines: [],
          unpaidInvoiceLines: [],
          selectedInfo: {
            rows: [],
            keys: [],
          },
          payWarnings: [],
        });
      }
    });
  };
  getOpenCheques = () => {
    const errorLines = this.doValidateLines();
    if(errorLines.length === 0){
      const { account, selectedInfo, orgId } = this.state;
      this.setState({
        lineProgress: true,
      });
      ARApi.fetchOpenCheques({
        organization_id: orgId,
        account_id: account?.id ? account.id : '',
        account_invoice_ids: selectedInfo.keys,
        stop_validate_check_remittance_and_payment: false,
      }).then((result) => {
        if (result.success) {
          const cheques = result.ar_invoices || [];
          const creditNotes = result.ar_credit_notes || [];
          this.setState({
            cheques: [...cheques],
            lineProgress: false,
            payWarnings: [],
            creditNotes,
          }, () => {
            this.setShowCheckForm();
          });
         
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            lineProgress: false,
          });
        }
      });
    }else{
      alertMessage('Please enter valid amount', 'error', '5')
    }
   
  };

  doValidateLines = () => {
    const modifiedLines = this.state.unpaidInvoiceLines
    .filter((line) => {
      if(line.is_modified !== true){
        return false;
      }
      const line_balance_amount = Number(line.balance_invoice_amount);
      const paid_amount = Number(line.paid_amount);
      const isExceeds = paid_amount > line_balance_amount;
      // if(isExceeds){
      //   return true;
      // }
      return false
    })
    return modifiedLines;
  }

  applyAmount = (checkWarnings = false) => {
    const errorsLines = this.doValidateLines()
    const errors = [];
    if(errorsLines.length > 0){
      errors.push('Please enter valid amount')
    }
    const {  selectedCheckInfo, selectedCNInfo, orgId } = this.state;
    if (errors.length === 0) {
      const payLoad = {
        organization_id: orgId,
        current_user_id: userStore.getStateValue("id"),
        view_type: "SUMMARY",
        ar_batch_check_ids: selectedCheckInfo.keys,
        ar_credit_note_ids: selectedCNInfo.keys,
        payment_details: [],
        stop_validate_check_remittance_and_payment: checkWarnings,
        create_credit_memo: this.state.createCreditMemo
      };
      const modifiedLines = this.state.selectedInvoices
        .filter((line) => line.is_modified === true)
        .map((line) => ({
          account_invoice_id: line.id,
          customer_order_id: "",
          paid_amount: line.paid_amount,
          discount: line.discount,
          credits: line.credits,
        }));
      payLoad.payment_details = modifiedLines;
      this.setState({ lineProgress: true });
      ARApi.applyPayment(payLoad).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState({
            errors: [],
            isNew: false,
            lineProgress: false,
            showForm: false,
            selectedInfo: {
              rows: [],
              keys: [],
            },
            selectedCheckInfo: {
              rows: [],
              keys: [],
            },
            selectedCNInfo: {
              rows: [],
              keys: [],
            },
            showCheckForm: false,
            totalLines: [],
            unpaidInvoiceLines: [],
            updatedTime: moment(),
            payWarnings: [],
            extractedData: {}, 
            selectedInvoices: []
          });
          this.getUnpaidLines();
          // this.getOpenCheques();
        } else {
          const warnings = result.warnings || [];
          if (warnings.length > 0) {
            this.setState({ lineProgress: false, payWarnings: warnings, errors: result.errors || [],});
          } else {
            alertMessage(result.errors.join(","), "error", 10);
            this.setState({
              errors: result.errors || [],
              lineProgress: false,
              payWarnings: [],
            });
          }
        }
      });
    } else {
      this.setState({ errors, lineProgress: false });
    }
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    const oldKeys = this.state.selectedInfo.keys;
    const removedKeys = oldKeys.filter((key) => !selectedRowKeys.includes(key));
    const addedKeys = selectedRowKeys.filter((key) => !oldKeys.includes(key));
    this.setState(
      {
        selectedInfo: Object.assign({}, this.state.selectedInfo, {
          keys: selectedRowKeys,
          rows: selectedRows,
        }),
      },
      () => {
        this.setPaidData(addedKeys, removedKeys);
      }
    );
  };

  handleChequesChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedCheckInfo: Object.assign({}, this.state.selectedCheckInfo, {
        keys: selectedRowKeys,
        rows: selectedRows,
      }),
    });
  };
  handleCNChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedCNInfo: Object.assign({}, this.state.selectedCNInfo, {
        keys: selectedRowKeys,
        rows: selectedRows,
      }),
    });
  };

  setPaidData = (addedKeys, removedKeys) => {
    const unpaidInvoiceLines = _.cloneDeep(this.state.unpaidInvoiceLines);
    removedKeys.forEach((key) => {
      const index = _.findIndex(unpaidInvoiceLines, { id: key });
      if (index >= 0) {
        unpaidInvoiceLines[index].paid_amount = checkNegitive(
          unpaidInvoiceLines[index].balance_invoice_amount,
          false
        );
        unpaidInvoiceLines[index].discount = this.state.zeroValue;
        unpaidInvoiceLines[index].credits = this.state.zeroValue;
        unpaidInvoiceLines[index].is_modified = false;
      }
    });
    addedKeys.forEach((key) => {
      const index = _.findIndex(unpaidInvoiceLines, { id: key });
      if (index >= 0) {
        unpaidInvoiceLines[index].paid_amount = checkNegitive(
          unpaidInvoiceLines[index].balance_invoice_amount,
          false
        );
        unpaidInvoiceLines[index].discount = this.state.zeroValue;
        unpaidInvoiceLines[index].credits = this.state.zeroValue;
        unpaidInvoiceLines[index].is_modified = true;
      }
    });
    this.setState(
      {
        unpaidInvoiceLines,
      },
      () => {
        this.updatePaymentSummary();
      }
    );
  };

  updatePaymentSummary = (index, element, value) => {
    const unpaidInvoiceLines = _.cloneDeep(this.state.unpaidInvoiceLines);
    if (["discount", "credits"].includes(element)) {
      const { balance_invoice_amount, paid_amount, discount, credits } =
        unpaidInvoiceLines[index];
      const sum =
        parseFloat(balance_invoice_amount || 0) -
        (parseFloat(discount || this.state.zeroValue) + parseFloat(credits || this.state.zeroValue));
      unpaidInvoiceLines[index]["paid_amount"] = checkNegitive(sum, false);
    }
    this.setState({
      unpaidInvoiceLines,
    },() => {
      this.updateEditableFields(element,unpaidInvoiceLines[index]);
    });
  };

  updateEditableFields = (element, updatedRecord) => {
    console.log("updated Record - > ", updatedRecord);
    if (updatedRecord) {
      const { id, discount, paid_amount, credits } = updatedRecord;
      const { extractedData } = this.state;
      // If id exists in extractedData, update the values; otherwise, add a new entry
      if (extractedData[id]) {
        this.setState({
          extractedData: {
            ...extractedData,
            [id]: {
              discount: parseFloat(discount),
              paid_amount: parseFloat(paid_amount),
              credits: parseFloat(credits),
            },
          },
        });
      } else {
        this.setState({
          extractedData: {
            ...extractedData,
            [id]: {
              discount: parseFloat(discount),
              paid_amount: parseFloat(paid_amount),
              credits: parseFloat(credits),
            },
          },
        });
      }
    }
    this.updateSelectedInvoices(element, updatedRecord);
  };

  updateSelectedInvoices = (element, updatedRecord) => {
    console.log("update selected invoice called:", element, updatedRecord)
    const selectedInvoices = _.cloneDeep(this.state.selectedInvoices);
    if (element && ["discount", "credits", "paid_amount"].includes(element)) {
        const { id } = updatedRecord;
        const index = selectedInvoices.findIndex(invoice => invoice.id === id);

        if (index !== -1) {
            // If record with the same ID exists, update it
            selectedInvoices[index] = { ...selectedInvoices[index], ...updatedRecord };
        } else {
            // Otherwise, add the updatedRecord to the array
            selectedInvoices.push(updatedRecord);
        }

        this.setState({ selectedInvoices });
    }else {
      const { keys } = this.state.selectedInfo;
      const { extractedData } = this.state
      // Filter unpaidInvoiceLines based on keys array
      const filteredInvoices = this.state.unpaidInvoiceLines.filter((invoice) =>
        keys.includes(invoice.id)
      );

      // Remove invoices from selectedInvoices whose IDs are not in the keys array
      const updatedSelectedInvoices = selectedInvoices.filter((invoice) =>
        keys.includes(invoice.id)
      );

      // Add invoices from filteredInvoices that are not already in selectedInvoices
      filteredInvoices.forEach((invoice) => {
        if (
          !updatedSelectedInvoices.some(
            (selectedInvoice) => selectedInvoice.id === invoice.id
          )
        ) {
          updatedSelectedInvoices.push(invoice);
        }
      });

      // Set selectedInvoices and update extractedData
      this.setState({ selectedInvoices: updatedSelectedInvoices }, () => {
        const updatedExtractedData = {};
        Object.keys(extractedData).forEach((invoiceId) => {
          if (keys.includes(invoiceId)) {
            updatedExtractedData[invoiceId] = extractedData[invoiceId];
          }
        });
        this.setState({ extractedData: updatedExtractedData });
      });
    }
}

  handlePaymentChange = (index, element, value) => {
    const unpaidInvoiceLines = _.cloneDeep(this.state.unpaidInvoiceLines);
    unpaidInvoiceLines[index][element] = value;
    this.setState(
      {
        unpaidInvoiceLines,
      },
      () => {
        this.updatePaymentSummary(index, element, value);
      }
    );
  };
   revertPaidPayments = (customer_order_ids) => {
    const { currentUser } = this.props.userContext;
    const userId = currentUser ? currentUser.id : null;
    this.setState({ inProgress: true });
    ARApi.revertDetailedPaidPayments({
      customer_order_ids: customer_order_ids,
      user_id: userId
    }).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false });
        alertMessage(result?.data?.message ? result.data.message : I18n.t('messages.saved'));
        this.getUnpaidLines();
      } else {
        renderAlertMessage(result.errors);
        this.setState({ inProgress: false });
      }
    });
  };

  onSearchNumbers = (search_order_token) => {
    const { totalLines, unpaidInvoiceLines } = this.state;
    const searchToken = !isEmpty(search_order_token) ? search_order_token : "";
    const tokenRemovedToken = _.replace(searchToken, " ", "");
    const tokens = _.split(tokenRemovedToken, ",");
    const lines = totalLines.filter((order) =>
      tokens.some(
        (token) =>
          (order.customer_order_number &&
            order.customer_order_number.indexOf(token) >= 0) ||
          (order.hawb && order.hawb.indexOf(token) >= 0) ||
          (order.mawb && order.mawb.indexOf(token) >= 0) ||
          (order.invoice_number && order.invoice_number.indexOf(token) >= 0)
      )
    );
    this.setState({
      unpaidInvoiceLines: [...lines],
    });
  };

  resetFilteredData = () => {
    const filteredData = Object.keys(this.state.extractedData)
      .filter((key) => this.state.selectedInfo.keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.state.extractedData[key];
        return obj;
      }, {});

    this.setState({
      extractedData: filteredData,
    });
  };
  
  isExceedAmount = (record) => {
    const original_amount = record.balance_invoice_amount
      ? parseFloat(record.balance_invoice_amount)
      : 0.0;
    const paid_amount = record.paid_amount
      ? parseFloat(record.paid_amount)
      : 0.0;
    const discount = record.discount ? parseFloat(record.discount) : this.state.zeroValue;
    const credits = record.credits ? parseFloat(record.credits) : this.state.zeroValue;
    return original_amount < paid_amount + discount + credits;
  };
  renderPayments = () => {
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage, // removed AppConfig.ordersPerPage to dynamically adjust record length
    };
    const selectedRowKeys = this.state.selectedInfo.keys;
    const rowSelection = {
      selectedRowKeys,
      // onChange: this.handleSelectChange,
      onChange: (selectedRowKeys, selectedRows) => this.handleSelectChange(selectedRowKeys, selectedRows, () => this.resetFilteredData()),
      getCheckboxProps: (record) => ({
        disabled: record.line_balance_amount == 0, // Column configuration not to be checked
      }),
    };
    return (
      <Spin spinning={this.state.lineProgress}>
        <div className="minimizdTable minimizedTable disableArrows">
          <UnpaidInvoicesList
            data={this.state.unpaidInvoiceLines}
            applyAmount={this.applyAmount}
            rowSelection={rowSelection}
            handlePaymentChange={this.handlePaymentChange}
            selectedKeys={[...selectedRowKeys]}
            revertPaidPayments ={this.revertPaidPayments}
            //scroll={{ y: 400 }}
            scroll={{
              y: `calc(100vh - ${
                this.state.selectedInfo.rows.length > 0 ? 300 : 320
              }px)`,
            }}
            showInput={(rec) => {
              const updatedSelectedRowKeys = [...selectedRowKeys, rec.id];
              const updatedSelectedInfoRows = [
                ...this.state.selectedInfo.rows.filter(
                  (item) => item.id !== rec.id
                ), // Filter out existing object with the same id
                { ...rec },
              ];
              const uniqueSelectedRowKeys = Array.from(new Set(updatedSelectedRowKeys));
              this.handleSelectChange(uniqueSelectedRowKeys, updatedSelectedInfoRows);
            }}
            // showInput={(rec) => {
            //   this.handleSelectChange(
            //     [...selectedRowKeys, rec.id],
            //     [...this.state.selectedInfo.rows, { ...rec }]
            //   );
            // }}
            rowClassName={(record, _index) =>
              this.isExceedAmount(record) ? "blockBgErrorColor" : ""
            }
            pagination={pagination}
            tableChange={(pagination, filter, sorter, currentTable) =>
              this.onTableChange(pagination, filter, sorter, currentTable)
            }
            currentWhTimeZoneId={this.props.currentWhTimeZoneId}
          />
          {this.state.selectedInfo.keys.length > 0 && (
            <BulkOperations
              selectedInfo={this.state.selectedInfo}
              loading={this.state.loading}
              applyAmount={this.getOpenCheques}
              handleCancel={() => {
                this.setState({ extractedData: {} });
                this.handleSelectChange([], []);
              }}
              isProceed={true}
            />
          )}
        </div>
      </Spin>
    );
  };
  renderPaidData = () => {
    return (
      <PaidPayments
        showActions={false}
        refresTheList={this.refresTheList}
        updatedTime={this.state.updatedTime}
        account={this.state.account}
        searchToken={this.state.searchToken}
        searchBy={this.props.searchBy}
        orgId={this.state.orgId}
        recordsPerPage={this.state.recordsPerPage}
        userContextData={this.props.userContext}
      />
    );
  };

  goToTab = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  refresTheList = (rec) => {
    this.setInitialData(rec);
    this.getUnpaidLines();
  };

  refreshTabs = () => {
    if (this.state.activeKey === "paid") {
      this.setState({
        updatedTime: moment(),
      });
    } else {
      this.getUnpaidLines();
    }
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.refreshTabs();
        updateRecordsPerPage(ScreenKeys.SUMMARY_VIEW, value);
      }
    );
  };

  renderData = () => {
    const { activeKey } = this.state;
    return (
      <Row>
        <Col xs={24}>
          
          <Row className="marginBottom10">
            <Col md={18} className="alignLeft">
              {
                activeKey === "unpaid" && this.state.selectedInfo.keys.length > 0 && 
                <PaymentSelectedInfo
                selectedInfo={this.state.selectedInfo}
                data={this.state.selectedInvoices}
                key="paid_amount"
              />
              }
              {/* <span className="textBold">
                {I18n.t("account_receivables.cheque")}:
              </span>{" "}
              <Search
                size="small"
                value={this.state.searchToken || ""}
                placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                  "account_receivables.cheque"
                )}`}
                onChange={(e) => {
                  const filteredString = removeSpecialCharacters(
                    e.target.value
                  );
                  this.handleOnChange("searchToken", filteredString);
                }}
                style={{ width: 300, textTransform: "uppercase" }}
                className="textUpperCase"
                onSearch={this.onSearch}
                allowClear
              /> */}
            </Col>
            <Col md={4} className="alignRight">
              <Row type="flex" justify="end">
                <Col>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    value={this.state.recordsPerPage}
                    defaultValue={AppConfig.ordersPerPage}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={2} className="alignRight">
              <Button
                className="buttonGreen"
                onClick={() => this.refreshTabs()}
                icon="reload"
                size="small"
                type="primary"
              >
                {/* {refreshIcon(() => {})} */}
                Refresh
              </Button>
            </Col>
          </Row>
          {activeKey === "unpaid"
            ? this.renderPayments()
            : this.renderPaidData()}
        </Col>
      </Row>
    );
  };

  setShowCheckForm = () => {
    const { searchToken, cheques } = this.state;
    const selectedChequeRows = !isEmpty(searchToken)
      ? cheques.filter((rec) => rec.check_ach_no === searchToken)
      : [];
    this.setState({
      showCheckForm: true,
      selectedCheckInfo: {
        keys: selectedChequeRows.map((rec) => rec.id),
        rows: selectedChequeRows,
      },
    });
  };

  closeShowCheckForm = () => {
    this.setState({
      showCheckForm: false,
      payWarnings: [],
      errors: [],
    });
    this.handleChequesChange([], []);
    this.handleCNChange([], []);
  };

  renderCheckModal = () => {
    const { payWarnings } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedCheckInfo.keys,
      onChange: this.handleChequesChange,
      getCheckboxProps: (record) => ({
        disabled: record.line_balance_amount == 0, // Column configuration not to be checked
      }),
    };
    const rowSelectionOfCN = {
      selectedRowKeys: this.state.selectedCNInfo.keys,
      onChange: this.handleCNChange,
      getCheckboxProps: (record) => ({
        disabled: record.line_balance_amount == 0, // Column configuration not to be checked
      }),
    };
    const { unpaidInvoiceLines, selectedInfo, selectedCheckInfo, selectedCNInfo } = this.state;
    const selectedRecords = unpaidInvoiceLines.filter((rec) =>
      selectedInfo.keys.includes(rec.id)
    );
    const totalPaidAmount = parseFloat(
      findNumberTotal(this.state.selectedInvoices, "paid_amount", null, this.state.decimalPoints)
    );
    const balanceAmount = parseFloat(
      findNumberTotal(selectedCheckInfo.rows, "balanced_amount", null, this.state.decimalPoints)
    );
    const creditAmount = parseFloat(findNumberTotal(selectedCNInfo.rows, "balance_credit_amount", null, this.state.decimalPoints))
    const creditToPay = parseFloat(findNumberTotal(this.state.selectedInvoices, "credits", null, this.state.decimalPoints))
    
    let totalNetBalance = 0.00;
    this.state.unpaidInvoiceLines
    .filter(data => this.state.selectedInfo.keys.includes(data.id))
    .forEach(data => {
        const decimalPoints = window.localStorage.getItem('round_off_decimals');
        const zeroValue = Number((0).toFixed(decimalPoints));
        const line_balance_amount = data.balance_invoice_amount ? Number(data.balance_invoice_amount) : zeroValue;
        const paid_amount = data.paid_amount ? Number(data.paid_amount) : zeroValue;
        const discount = data.discount ? Number(data.discount) : zeroValue;
        const credits = data.credits ? Number(data.credits) : zeroValue;
        const netBalance = line_balance_amount - (paid_amount + discount + credits);
        if (netBalance < 0) {
            totalNetBalance += netBalance;
        }
    });
    const selectedIds = this.state.selectedInfo.keys;
    const selectedInvoices = this.state.unpaidInvoiceLines.filter(data => selectedIds.includes(data.id));
    const frequencyInvoiceNumbers = selectedInvoices.map(data => data.frequency_invoice_number);


    return (
      <BaseModal
        title={`Check Details`}
        onCancel={() => this.closeShowCheckForm()}
        width="90%"
        style={{
          top: 20,
          height: "50%",
        }}
        bodyStyle={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        <Row>
            <h4 style={{ fontWeight: "bold",marginTop:"12px" }}>
            Invoices To Pay For
            </h4>
          </Row>
        <Row>
          <Col xs={6}>
          <Row>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                marginTop: "10px",
                padding: "10px",
              }}
            >
              <p style={{ maxHeight: 70, overflowY: "auto" }}>
                {frequencyInvoiceNumbers.join(", ")}
              </p>
            </div>
          </Row>
          </Col>
          <Col xs={11}/>
          <Col xs={7}>
          <SelectedSummary
            balanceAmount={balanceAmount} 
            creditAmount={creditAmount}  
            totalPaidAmount={totalPaidAmount}
            creditToPay={creditToPay}
          />
          </Col>
        </Row>
        <Row>
        <Col>
          <Checkbox onChange={this.handleCheckboxChange} disabled={totalNetBalance >= 0}>
            {I18n.t('credit_notes.add')} <b>{totalNetBalance < 0 ? `($ ${Math.abs(totalNetBalance).toFixed(2)})` : `($ ${"0.00"})`}</b>
          </Checkbox>
          </Col>
        </Row>
        <Row type="flex" gutter={32}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <h4>{`Check Details`}</h4>
              <TransactionIndexList
                data={this.state.cheques}
                rowSelection={rowSelection}
                pagination={{ position: "none" }}
                showActions={false}
                columnsToDisplay={[
                  "check_ach_no",
                  "received_amount",
                  "paid_amount",
                  "balanced_amount",
                ]}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <h4>{I18n.t('credit_notes.label')}</h4>
               <List
                  data={this.state.creditNotes}
                  rowSelection={rowSelectionOfCN}
                  pagination={{ position: "none" }}
                  showActions={false}
                  columnsToDisplay={[
                    "credit_no",
                    "balance_credit_amount",
                  ]}
               />
         </Col>
        </Row>
        
        {(this.state.selectedCheckInfo.keys.length > 0 ||  this.state.selectedCNInfo.keys.length > 0) && (
          <BulkOperations
            selectedInfo={this.state.selectedInfo}
            loading={this.state.loading}
            applyAmount={this.applyAmount}
            // applyAmount={() => {
            //   this.setState({ extractedData: {}, selectedInvoices: [] });
            //   this.applyAmount();
            // }}
            handleCancel={() => this.closeShowCheckForm()}
            requireFix={false}
          />
        )}
        {payWarnings.length > 0 && (
          <div style={{ marginTop: "10px" }}>
            <Alert
              message={
                <Fragment>
                  <span className="marginRight10">{payWarnings.join(",")}</span>
                  <Link onClick={() => this.applyAmount(true)}>
                    {I18n.t("general.click_here")} to proceed?
                  </Link>
                </Fragment>
              }
              type="error"
              showIcon
              icon={<Icon type="info-circle" theme="filled" />}
            />
          </div>
        )}
      </BaseModal>
    );
  };

  render() {
    return (
      <div>
        {this.renderData()}
        {this.state.showCheckForm && this.renderCheckModal()}
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

SummaryView.propTypes = {
  batch: PropTypes.shape().isRequired,
  currentPayment: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const SummaryViewComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const organizationSettings = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.SUMMARY_VIEW
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  return (
    <SummaryView
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default SummaryViewComponent;
