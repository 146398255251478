/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import _ from 'lodash';
import React  from 'react';
import PropTypes from 'prop-types';
import MainMap from '../dispatches/DispatchMap';
import AppConfig from '../../config/AppConfig';
import { isEmpty } from '../../common/Common';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: '',
      isOpen: [],
      centerLatLng: [],
      doMount: true,
      isMapLoading: true,
      isCallCurrentStop: props.screen === 'stops',
      routes: props.navRoutes.map((route, routeIndex) => ({
        stop_markers: route.nav_stops.filter(stop => stop.lat_long && stop.lat_long.length > 1).
        map((stop, index) => ({
          stop_number: stop.stop_order_sequence + 1,
          id: stop.stop_id,
          formatted_stop_name: stop.contact_name,
          lat: stop.lat_long[1],
          lng: stop.lat_long[0],
          ...stop,
        })),
        ...route,
      })),
      organizationSettings: {},
      isMilitaryTime: false,
    };
  }

  componentDidMount(){
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }

  componentWillReceiveProps(nextProps) {
    const stateData = {};
    const selectedRoute = this.state.selectedRoute;
    if (nextProps.navRoutes.length !== null) {
      if (nextProps.navRoutes.length !== null) {
        const routes = nextProps.navRoutes.map((route, routeIndex) => ({
          stop_markers: _.sortBy(!isEmpty(route.nav_stops) ? route.nav_stops : [], [function (o) { return o.stop_order_sequence; }])
          .filter(stop => stop.lat_long && stop.lat_long.length > 1)
          .map((stop, index) => ({
            stop_number: stop.stop_order_sequence + 1,
            id: stop.stop_id,
            formatted_stop_name: stop.contact_name,
            lat: stop.lat_long[1],
            lng: stop.lat_long[0],
            ...stop,
          })),
          ...route,
        }));
        // routes.originPoint=thi
        let location = [];
        location = nextProps.routeInfo;
        stateData.routes = routes;
        if(selectedRoute !== nextProps.selectedRoute){
          stateData.selectedRoute = nextProps.selectedRoute;
        }
        // stateData.location = location;
        // stateData.driver = nextProps.driver ? nextProps.driver : '';
      }

      this.setState(stateData, () => {
        //if(nextProps.mapViewType === "SELECTED" && selectedRoute !== nextProps.selectedRoute || nextProps.showOrders){
        if(nextProps.mapViewType === "SELECTED" && selectedRoute !== nextProps.selectedRoute){
          this.doSetBounds();
        }
        
      });
    }
  }

  onToggleOpen = (e, i, id) => {
    const isOpen = [];
    isOpen[id] = !this.state.isOpen[id];
    // if (id && this.state.isCallCurrentStop) { this.props.getCurrentStop(id); }
    this.setState({
      isOpen,
    });
  }

  onMapMount = (map) => {
    if (map) {
      this.map = map;
    }
    if (this.state.map === '' && map) {
      this.setState({ map }, () => {
        this.doSetBounds();
      });
    }
  }

  setOrderBounds = (bounds) => {
    let { isInitial } = this.state;
    this.props.orderInfo
      .filter(
        (order) =>
          order.customer_address &&
          order.customer_address.coordinates &&
          order.customer_address.coordinates.length > 1
      )
      .forEach((order) => {
        isInitial = false;
        bounds.extend(
          new window.google.maps.LatLng(
            order.lat,
            order.lng,
          )
        );
      });
    return bounds;
  };


  doSetBounds = () => {
    if (window.google && this.state.map) {
      this.setState({ inProgress: true, isMapLoading: true }, () => {
        let bounds = new window.google.maps.LatLngBounds();
        this.state.routes.forEach((routes) => {
          routes.stop_markers.forEach((stopMarker) => {
            bounds.extend(new window.google.maps.LatLng(stopMarker.lat, stopMarker.lng));
          });
        });
        // bounds = this.setOrderBounds(bounds);
        this.state.map.fitBounds(bounds);
        this.center = bounds.getCenter();
        this.setState({
          centerLatLng: { latitude: this.center.lat(), longitude: this.center.lng() },
        });
      });
    }
  }

  // onMarkerClustererClick = (markerClusterer) => {
  //   const clickedMarkers = markerClusterer.getMarkers();
  //   // eslint-disable-next-line no-console
  // };

  // getCleanedLocations = (arrLocations) => {
  //   const arr1 = arrLocations.slice(1);
  //   arr1.pop();
  //   const data = [];
  //   for (let i = 0; i < arr1.length; i += 1) {
  //     data.push({
  //       lat: arr1[i].location.l_address.coordinates[1],
  //       lng: arr1[i].location.l_address.coordinates[0],
  //     });
  //   }
  //   return data;
  // }

  render() {
    return (
      <MainMap
        // eslint-disable-next-line max-len
        googleMapURL={ `https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places` }
        loadingElement={ <div style={ { height: '100%', width: '100%' } } /> }
        containerElement={ <div style={ { height: '100%', width: '100%' } } /> }
        mapElement={ <div style={ { height: '100%', width: '100%', borderRadius: 10 } } /> }
        centerObjValue={ this.center }
        onMapMounted={ this.onMapMount }
        centerLatLng={ this.state.centerLatLng }
        routes={ this.state.routes }
        isOpen={ this.state.isOpen }
        onToggleOpen={ this.onToggleOpen }
        isMilitaryTime={ this.state.isMilitaryTime }
        { ...this.props }
      />
    );
  }
}

// Map.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
// };

export default Map;
