import { useState, useEffect } from "react";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";
import I18n from "../common/I18n";

export const useForm = (schema) => {
  const [errors, setErrors] = useState({});

  const [values, setValues] = useState({});

  useEffect(() => {
    const _values = Object.keys(schema).reduce((acc, key) => {
      return {
        ...acc,
        [key]:
          schema[key].initialValue !== undefined
            ? schema[key].initialValue
            : "",
      };
    }, {});
    setValues(_values);
  }, []);

  const register = (key) => (value) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  const validate = () => {
    // holds actual errors shown at ui
    const __errors = {};

    const _errors = Object.keys(schema).reduce((acc, key) => {
      const value = values[key];
      const object = schema[key];

      if (object.required) {
        if (
          !value ||
          (value.trim && !value.trim()) ||
          (typeof object.onValidate == "function" &&
            object.onValidate(value) == false)
        ) {
          // not valid block
          if (object.defaultError) {
            __errors[key] = object.defaultError;
          }

          if (object.excludeErrorWhen && object.excludeErrorWhen(value)) {
            // dont include the error
          } else {
            __errors[key] = object.error;
          }

          // do not touch this
          acc[key] = object.error;
        }
      } else if (key === "location_code") {
         if (!isEmpty(values[key]) && !AppConfig.regexCode.test(values[key])) {
          __errors[key] = I18n.t("predefined_stops.errors.code");
          // do not touch this
          acc[key] = object.error
        }
      } else if (key === 'email') {
        if (!isEmpty(values[key]) && !AppConfig.regexEmail.test(values[key])) {
          __errors[key] = I18n.t("predefined_stops.errors.email");
          // do not touch this
          acc[key] = object.error
        } 
      } 
      return acc;
    }, {});
    setErrors(__errors);
    return Object.keys(_errors).length == 0;
  };

  return [values, errors, register, validate, setErrors];
};
