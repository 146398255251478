import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Row,
  Col,
  Spin,
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import moment from "moment";
import BaseModal from "../BaseModal";
import { OrgContext } from "../../context/OrgContext";
import DateTimeSelector from "../../common/DateTimeSelector";
import { grossOrderReports, sendInvoice } from "../../api/BillingScreenAPI";
import { renderAlertMessage } from "../../helpers/common";

const SendInvoiceForm = (props) => {
  const organizationSettings = useContext(OrgContext);
  const isMilitaryTime = organizationSettings.is_military_time === "true";
  const { closeForm,path } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState({
    start_date: null,
    start_time: null,
  });
  useEffect(() => {
    setFormData();
  }, []);

  const setFormData = () => {
    setCurrentForm({
      start_date: moment(),
      start_time: moment(),
    });
  };

  const handleSend = () => {
    const organization_id = organizationSettings.currentOrg.id;
    setIsLoading(true);
    const processDate =
      moment(currentForm.start_date).format("YYYY-MM-DD");
    const payload = {
      organization_id,
      process_date: processDate,
    };

    const requiredHeader = path.includes("drivers") ? grossOrderReports : sendInvoice;

    requiredHeader(payload).then((result) => {
        if (result.success) {
          alertMessage(result?.message ? result.message : "Send Sucessfully !!!", 'success', 10)
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeWindow = () => {
    setCurrentForm({
      start_time: null,
      start_date: null,
    });
    closeForm();
  };

  const renderForm = () => {
    return (
      <Spin spinning={isLoading}>
        <Row className="marginTop10" gutter={32}>
          <Col>
            <DateTimeSelector
              dateProps={{
                format: "Do MMM YYYY",
                label: "Select Date",
                value: currentForm.start_date
                  ? moment(currentForm.start_date)
                  : null,
                onChange: (date) => {
                  setCurrentForm({ ...currentForm, start_date: date });
                },
                style: { width: "100%" },
                allowClear: false,
                // disabledDate: (current) => {
                //   return current && current >= moment().startOf("day");
                // },
              }}
              timeProps={{
                format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                label: "Route start time",
                showTime: {
                  format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                  use12Hours: !isMilitaryTime,
                },
                showSecond: false,
                onChange: (time) => {
                  setCurrentForm({ ...currentForm, start_time: time });
                },
                style: { width: "100%", height: "100%", marginTop: "4px" },
                value: currentForm.start_time
                  ? moment(currentForm.start_time)
                  : null,
                placeholder: "Select Time",
                minuteStep: 1,
                isMilitaryTime,
              }}
              gridStyle={{ row: { type: "", gutter: 0 } }}
              hideTime={true}
            />
          </Col>
          <Col xs={24} className="alignCenter">
            <Button
              className="marginRight10"
              icon="close"
              onClick={closeWindow}
            >
              {I18n.t("general.cancel")}
            </Button>
            <Button
              type="primary"
              icon="notification"
              onClick={handleSend}
            >
              {I18n.t("general.send")}
            </Button>
          </Col>  
          {/* <Col className="notes_content textBold paddingTop20"> <span className="textRed">Note:</span>&nbsp;
            The invoices which are approved on given date will be sent to configured emails </Col> */}
        </Row>
      </Spin>
    );
  };

  return <Fragment>{renderForm()}</Fragment>;
};

const SendInvoice = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { orders, showIcon } = props;
  const path = props.location.pathname;
  const renderAllocateWindow = () => {
    return (
      <BaseModal
        title={!path.includes("drivers") ? I18n.t("invoices.send_invoice") : "Send Settlements" }
        width="400px"
        style={{ top: 50 }}
        visible={showModal}
        onCancel={() => closeModal()}
        maskClosable={false}
      >
        <SendInvoiceForm closeForm={() => closeModal()} {...props} path = {path} />
      </BaseModal>
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      {/* <Button
        type="primary"
        style={{ marginTop: "2px", marginLeft: 10, marginRight: 10 }}
        className="buttonCerulean"
        icon="branches"
        size={"small"}
        onClick={() => setShowModal(true)}
      >
        {I18n.t("menu.routes")}
      </Button> */}
      <Button type="primary" size="small" icon="mail" 
       onClick={() => setShowModal(true)} style={{
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              color: '#fff',
            }}>
              {!path.includes("drivers") ? (I18n.t('general.send_item', { item: I18n.t('general.invoice') })) : "Send Settlements"}
            </Button>
      {showModal && renderAllocateWindow()}
    </Fragment>
  );
};

SendInvoice.propTypes = {

};
SendInvoice.defaultProps = {
 
};
export default withRouter(SendInvoice);
