import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Popconfirm,
  Tooltip,
  Icon,
} from '../../common/UIComponents'
import {
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import { _ } from 'core-js'

const ApproveQuote = ({ quote_id, quote_no, className, displayType, size, validateQuote }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [emails, setEmails] = useState([])
  return (
    <Fragment>
      <Popconfirm
        placement="topRight"
        title={I18n.t('general.are_you_sure')}
        // onConfirm={() => this.props.genarateQuote(record.id, true)}
        onConfirm={() => validateQuote(quote_id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title={I18n.t('quotes.convert_to_order')}>
          {displayType === 'icon' ? (
            <Icon
              onClick={() => quote_id}
              type="check-circle"
              className={'anchor_cursor'}
            />
          ) : (
            <Button
              className={className ? className : ''}
              icon="shopping-cart"
              size={!isEmpty(size) ? size : 'default'}
            >
              {' '}
              {I18n.t('quotes.convert_to_order')}
            </Button>
          )}
        </Tooltip>
      </Popconfirm>
      <Tooltip title="Send Email"></Tooltip>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

ApproveQuote.propTypes = {
  quote_id: PropTypes.string.isRequired,
  quote_no: PropTypes.string.isRequired,
}

export default ApproveQuote
