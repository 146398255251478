/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
import BaseList from "../../BaseList";
import {isEmpty, ellipseText, randomNumber } from "../../../common/Common";
import OrderAccessorials from '../../orders/OrderAccessorials';
import { Icon, Popover, Row, Col, Tooltip, Popconfirm,Tag, Link } from '../../../common/UIComponents';
import AppConfig from '../../../config/AppConfig';
import { formatServiceDuration } from '../../../helpers/common';
import { findDependencyOrders, getDependencyOrders } from '../../../helpers/preplan';
import { checkServiceExistance } from '../../../helpers/common';
import { getOrderTypeLabel } from '../../../helpers/orders';
import TypeOfOrder from '../../orders/TypeOfOrder';
import PreplanOrderAccessorial from '../../orders/PreplanOrderAccessorial';
import PreplanOrderNotes from '../../orders/PreplanOrderNotes';
import DeleteIcon from '../../common/DeleteIcon';
import Copyable from "../../common/Copyable";
import OrderQuantity from "../OrderQuantity";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import EndUserTitle from "../../common/EndUserTitle";
import I18n from "../../../common/I18n";
import CustomerOrderDetails from "../../orders/CustomerOrderDetails";
import OrderAttachments from "../../orders/OrderAttachments";
class OrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      fromAnotherWh: props.currentRoute.isLocked,
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    if (props.stopType === "CS" && props.showMoveAction === true) {
      this.columns.push({
        key: "move_stop_order",
        title: "",
        dataIndex: "customer_order_number",
        render: (data, record, index) =>
          props.stopType === "CS" &&
          props.showMoveAction === true && (
            <Draggable
              key={record.customer_order_id}
              draggableId={record.customer_order_id}
              index={index}
            >
              {(provided, snapshot) => {
                const dependencyItems = getDependencyOrders(
                  props.currentRoute.routeOrders,
                  "related_order",
                  "customer_order_id",
                  record.customer_order_id,
                  "id"
                );
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={this.getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? "#607ad6"
                          : "white",
                        color: snapshot.isDragging ? "#FFF" : "inherit",
                        width: snapshot.isDragging ? 120 : "inherit",
                        height: snapshot.isDragging ? 30 : "inherit",
                        fontSize: snapshot.isDragging ? 12 : "inherit",
                        padding: snapshot.isDragging ? 4 : "inherit",
                        borderRadius: snapshot.isDragging ? 10 : "inherit",
                        // backgroundColor: snapshot.isDragging ? '#607ad6' : 'white',
                        // color: snapshot.isDragging ? '#FFF' : 'inherit',
                        // width: snapshot.isDragging ? 120 : 'inherit',
                        // height: snapshot.isDragging ? 30 : 'inherit',
                        // fontSize: snapshot.isDragging ? 1 : 'inherit',
                        // paddingTop: snapshot.isDragging ? 6 : 'inherit',
                        // borderRadius: snapshot.isDragging ? 10 : 'inherit',
                      }}
                    >
                      {!snapshot.isDragging ? (
                        <Fragment>
                          <Icon type="menu" />
                        </Fragment>
                      ) : (
                        <Fragment>
                          &nbsp;&nbsp;
                          <Icon type="menu" /> &nbsp;{" "}
                          {dependencyItems.length === 0
                            ? data
                            : `Moving ${dependencyItems.length + 1} orders  `}
                        </Fragment>
                      )}
                    </div>
                  </div>
                );
              }}
            </Draggable>
          ),
        width: 20,
      });
    }
    const items = [
      {
        title: "#",
        key: "index",
        align: "center",
        render: (text, record, index) => index + 1,
        width: 30,
      },
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        width: 140,
        render: (text, record, index) => {
          const isTransfer = ["T", "LH"].includes(record.type_of_order);
          // assuming first WH contains delivery orders
          const isDelivery = record.type_of_loc === "DELIVERY" || (props.stopType === "WH" && props.stopIndex === 0);
          const textToDisplay = isTransfer ? ( isDelivery ? text + "(D)" : text + "(P)") : text;
          return (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              padding: "0 10px 0 5px",
            }}
            gutter={8}
          >
            <Col>
              <Row type="flex" gutter={4}>
                <Col>
                  <Link
                    onClick={() =>
                      this.props.orderDetailsViewClick(record.customer_order_id)
                    }
                  >
                    {textToDisplay}
                  </Link>
                </Col>
                <Col>
                  <Copyable text={text} />
                </Col>
              </Row>
            </Col>
            <Col>
              <TypeOfOrder
                order={record}
                orderTypeKey="type_of_order"
                relatedOrderKey="related_order"
                key={`${record.customer_order_id}`}
                showBadge={true}
              />
            </Col>
          </Row>

          )
        },
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        width: 80,
        render: (text, record) => (
          <Row type="flex" style={{ flexFlow: "unset" }} gutter={2}>
            <Col>
              <Link
                onClick={() =>
                  this.props.orderDetailsViewClick(record.customer_order_id)
                }
              >
                <Tooltip title={text}>{ellipseText(text, null)}</Tooltip>
              </Link>
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        width: 80,
        render: (text, record) => (
          <Row type="flex" style={{ flexFlow: "unset" }} gutter={2}>
            <Col>
              <Link
                onClick={() =>
                  this.props.orderDetailsViewClick(record.customer_order_id)
                }
              >
                <Tooltip title={text}>{ellipseText(text, null)}</Tooltip>
              </Link>
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1"  charsToLimit={null}/>
        ),
        width: 100,
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2"  charsToLimit={null}/>
        ),
        width: 100,
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
      },
      {
        key: "service_duration",
        title: (
          <Tooltip title={I18n.t("stop.service_duration")}>
            {I18n.t("general.duration")}
          </Tooltip>
        ),
        dataIndex: "service_duration",
        render: (data, record) => (
          <Fragment>{data ? formatServiceDuration(data * 60) : ""}</Fragment>
        ),
        width: 80,
      },
      {
        key: "weight",
        title: `${I18n.t("order.weight")} (lbs)`,
        dataIndex: "weight",
        render: (data, record) => (
          <Fragment>
            <OrderQuantity record={record} key={`som${record.customer_order_id}`} keyId={`so${record.customer_order_id}`} primaryKey={"customer_order_id"} showActions={true} isMilitaryTime={props.isMilitaryTime} setIsItemVisible={props.setIsItemVisible}>
              {!isEmpty(data) ? `${data}` : "NA"}{" "}
              {!isEmpty(record.items_count) ? `(${record.items_count} pcs)` : ""}
            </OrderQuantity>
          </Fragment>
        ),
        width: 80,
      },
    // {
    //   key: "pallets",
    //   title: I18n.t("order.pallets"),
    //   dataIndex: "pallets",
    //   // render: (data, record) => (
    //   //   <div className="alignRight paddingRight10">
    //   //     {!isEmpty(data) ? data : ""}
    //   //   </div>
    //   // ),
    //   width: 80,
    //   align: "right",
    // },
      {
        key: "account_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        width: 80,
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: "account_name",
        className: "noWrap",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        width: 80,
      },
      
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "levelOfService",
        render: (data,record) => (
          <div style={{ display: "flex" }}>
            <div>{record.los || ''}</div>
          </div>
        ),
        width: 80,
      },
      {
        key: "consignee",
        title: <EndUserTitle  />,
        dataIndex: "consignee",
        render: (data,record) => (
          <div style={{ display: "flex" }}>
            
            <div>
              {
              record.type_of_order === 'T' ? (record?.stop_location?.name ? record.stop_location.name :  record.customer_first_name) : record.customer_first_name
              }
            </div>
          </div>
        ),
        width: 120,
      },
      {
        key: "company_name",
        title: I18n.t("general.company_name"),
        dataIndex: "company_name",
        render: (data,record) => (            
        <div>
            {_.get(record, "stop_location.company_name", "")}
         </div>
        ),
        width: 120,
      },
    ];
    this.columns.push(...items);

    if (props?.data?.length) {
      this.columns.push({
        key: "actions",
        title: "",
        dataIndex: "customer_order_id",
        fixed: !this.state.fromAnotherWh ? "right" : null,
        render: (id, record, index) => {
          const isWHstop = this.props.stopType && this.props.stopType === "WH";
          const status =
            this.props.currentStop && this.props.currentStop.status
              ? this.props.currentStop.status
              : "";
          return (
            <div>
              <Row gutter={6} type="flex" style={{ marginLeft: -2 }}>
                <Col span={5}>
                  <PreplanOrderAccessorial 
                    record={record} 
                    showPopupContainer={false}
                  />
                </Col>

                <Col span={5}>
                  <PreplanOrderNotes
                    record={record}
                    showPopupContainer={false}
                  />
                </Col>
                <Col span={5} style={{ paddingTop: 2}}>
                    <CustomerOrderDetails
                      order_no={ record.customer_order_number }
                      order={ {
                        id: record.customer_order_id,
                        customer_order_number: record.customer_order_number,
                      } }
                      showEdit={ false }
                      showIcon={true}
                      eyeIconStyle={{ fontSize: 15 }}
                    />
                </Col>
                <Col span={4} >
                  <OrderAttachments
                    record={ record }
                    placement={
                      index !== 0 && index === props?.data?.length - 1
                        ? "topRight"
                        : "left"
                    }
                    showPopupContainer={ false }
                  />
                </Col>
                {props.showActions === true && (
                    <div className="cursorPointer textBold fontSize12">
                      {!isWHstop &&
                        ["PENDING", "UNALLOCATED"].includes(status) &&
                        checkServiceExistance("NRROFR") && (
                          <Col span={5}>
                            <Fragment>
                              <Popconfirm
                                placement="left"
                                title={
                                  isEmpty(record.related_order)
                                    ? I18n.t("messages.delete_confirm")
                                    : I18n.t(
                                        "messages.dependency_delete_confirm",
                                        {
                                          field: "order",
                                        }
                                      )
                                }
                                onConfirm={() => props.deleteOrderClick(id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Tooltip
                                  title={I18n.t(
                                    "general.delete_order_from_route"
                                  )}
                                  placement="bottomRight"
                                >
                                  <span>
                                    <DeleteIcon />
                                  </span>
                                </Tooltip>
                              </Popconfirm>
                            </Fragment>
                          </Col>
                        )}
                    </div>
                )}
              </Row>
            </div>
          );
        },
        width: 90,
      })
    }
    const isPreplanStopsPage = props.screen_from === "preplan";
    if (!_.isEmpty(props.displayConfiguration) && isPreplanStopsPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const stopOrders_list_view_columns =
        userConfiguration.stopOrders_list_view_columns ||
        orgConfiguration.stopOrders_list_view_columns;
      const columns = [];
      if (
        typeof stopOrders_list_view_columns !== undefined &&
        Array.isArray(stopOrders_list_view_columns)
      ) {
        const initialColumns = this.columns.filter((col) =>
          ["index"].includes(col.key)
        );
        if (initialColumns.length > 0) {
          columns.push(...initialColumns);
        }
        stopOrders_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.filter((col) =>
          ["notes", "order_accessorials",'actions'].includes(col.key)
        );
        if (actions.length > 0) {
          columns.push(...actions);
        }
        this.columns = columns;
      } else {
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    ...draggableStyle,
  });
}

export default OrdersList;
