/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Card,
  Radio,
  Icon,
  Select,
  Checkbox,
  Button,
  Popconfirm
} from "../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  randomNumber,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import {
  deleteWeightRange,
  fetchAccessorialDetails,
  fetchAccessorialsComponents,
  saveAccessorialConfig,
} from "../../api/Accessorials";
import RateCard from "./RateCard";
import BaseModal from "../BaseModal";
import WeightForm from "../configurations/accessorials/WeightForm";
import AppConfig from "../../config/AppConfig";
import Text from "antd/lib/typography/Text";
import { fetchAccountShortLos } from "../../api/Los";
import { fetchAccountZones } from "../../api/BillingZones";
import { element } from "prop-types";
import MaterialTimePicker from "../orders/MaterialTimePicker";
import moment from "moment";
import { retrieveParamValue } from "../configurations/helper";
import SlotComponent from "./SlotComponent";
import { OrgContext } from "../../context/OrgContext";
import { withRouter } from "react-router";
import { isMobileOnly } from "react-device-detect";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { renderAlertMessage } from "../../helpers/common";
const defaultOrderTypes = ["A", "R", "D"];


class AccessorialConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAccessorial: this.props.currentAccessorial || {},
      components: [],
      isNew: false,
      account: this.props.account || {},
      inProgress: false,
      errors: [],
      currentComponents: [],
      currentConfiguration: [],
      levelOfServices: [],
      zones: [],
      isMilitaryTime: false,
      organizationSettings: {},
      decimalPoints: window.localStorage.getItem('round_off_decimals'),

    };
  }

  componentDidMount() {
    this.getAccountZones();
    this.getLOS();
  }
   componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }

  getLOS = () => {
    const { account } = this.state;
    fetchAccountShortLos(account.code).then((result) => {
      if (result.success) {
        const levelOfServices = result.account.account_los || [];
        this.setState({
          levelOfServices,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ levelOfServices: [] });
      }
    });
  };

  getAccountZones = () => {
    fetchAccountZones(this.state.account.id).then((result) => {
      if (result.success) {
        const zones = result.zones || [];
        this.setState({
          zones,
          inProgress: false,
        }, () => {
          this.getComponents();
        });
      } else {
        renderAlertMessage(result.errors)
        this.getComponents();
        this.setState({ inProgress: false });
      }
    });
  };

  getAccessorialDetails = (id) => {
    fetchAccessorialDetails(id).then((result) => {
      if (result.success) {
        const currentAccessorial = result.accessorial || [];
        this.setState(
          {
            currentAccessorial,
          },
          () => {
            this.setComponents();
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getRepeatedObject = (param, prevRec = null) => {
    const config = {};
    if(param.repeated_set){
      param.repeated_set.forEach(rec => {
        if(rec.code === 'from_time'){
          config[rec.code] = prevRec ? (prevRec.to_time ? moment(prevRec.to_time, "HH:mm").add(1, "minute").format("HH:mm") : '') : (rec.default_value || '') 
        }else if(rec.code === 'to_time'){
          config[rec.code] = !prevRec ? (rec.default_value || '') : ''
        }else{
          config[rec.code] = rec.default_value ? rec.default_value : ''
        }
      })
    }
    return config;
  }

  setComponents = () => {
    const { currentAccessorial, components } = this.state;
    const currentAccessorialComponents = currentAccessorial.components || [];
    const componentCodes = currentAccessorialComponents.map(
      (component) => component.component_code
    );
    const accessorialComponents = components.filter((component) =>
      componentCodes.includes(component.code)
    );
    const currentComponents = [];
    const currentConfiguration = {};
    accessorialComponents.forEach((accComponent, componentIndex) => {
      const params = accComponent.component_params.filter((param) =>
        param.visible_to.includes("BILLING")
      );
      if (params.length > 0) {
        currentComponents.push(
          Object.assign({}, accComponent, { component_params: [...params] })
        );
        currentConfiguration[accComponent.code] = {};
        currentConfiguration[accComponent.code] = {
          isTypeBased: false,
          isZoneBased: accComponent.zone_wise === true,
          zone_category: accComponent.zone_wise === true ? "ALL_ZONES" : "",
        };
        defaultOrderTypes.forEach((order) => {
          currentConfiguration[accComponent.code][order] = {};
        });
        //accComponent.order_types.forEach((type) => {
        defaultOrderTypes.forEach((type) => {
          params.forEach((param) => {
            if (param.data_type === "range") {
              const tempId = randomNumber();
              const rangeData = param.req_min_charge ? [
                  {
                    id: tempId,
                    min_weight: 0,
                    max_weight: 0,
                    isNew: true,
                  },
                ] : [];
              currentConfiguration[accComponent.code][type][param.code] = {
                weightsData: rangeData,
                accessorialWeights: [
                  {
                    accessorial_key: "cost_per_weight",
                    [tempId]: {
                      accessorial_value: "",
                      breakpoint_weight: "",
                    },
                  },
                ],
              };
            } else {
              // if( param.code === 'excess_weight') {
              if (accComponent.isZoneBased === true) {
                currentConfiguration[accComponent.code][type][zone_id] = "";
              }
              // currentConfiguration[accComponent.code][type].is_repeatable = accComponent.is_repeatable === true;
              const repeatedConfig = this.getRepeatedObject(param)
              currentConfiguration[accComponent.code][type][
                param.code
              ] = param.is_repeatable && param.repeated_set ?  [repeatedConfig] : (!isEmpty(param.default_value) ? param.default_value : "");
            }
          });
        });
      }
    });
    if (currentAccessorial?.component_params && _.isArray(currentAccessorial.component_params)) {
    currentAccessorial.component_params.forEach((component) => {
      if (
        !isEmpty(component.order_type) &&
        !isEmpty(currentConfiguration[component.component_code]) &&
        !isEmpty(
          currentConfiguration[component.component_code][component.order_type]
        )
      ) {
        if (component.order_type !== "A") {
          currentConfiguration[component.component_code].isTypeBased = true;
        }
        if (
          currentConfiguration[component.component_code].isZoneBased === true &&
          !isEmpty(component.zone_category)
        ) {
          currentConfiguration[component.component_code].zone_category =
            component.zone_category;
        }
        if (
          !["WEIGHT", "RANGE"].includes(component.component_code) ||
          (["WEIGHT", "RANGE"].includes(component.component_code) &&
            component.min_weight === "0" &&
            component.max_weight === "0")
        ) {
          const zones = this.state.zones || [];
          component.param_values.forEach((param) => {
            if (
              ["WEIGHT", "RANGE"].includes(component.component_code) &&
              param.accessorial_key === "excess_weight"
            ) {
              const maxWeight = this.getMaxWeight(
                currentConfiguration,
                component.component_code,
                [component.order_type]
              );
              const excessWeight = param.accessorial_value
                ? param.accessorial_value
                : maxWeight;
              currentConfiguration[component.component_code][
                component.order_type
              ][param.accessorial_key] = excessWeight;
            } else {
              // checking it is zone based
              if (currentConfiguration[component.component_code].isZoneBased) {
                if (
                  !isEmpty(param.zone_id) &&
                  currentConfiguration[component.component_code][
                    component.order_type
                  ]
                ) {
                  const currentZoneIndex = _.findIndex(zones, [
                    "id",
                    param.zone_id,
                  ]);
                  if (currentZoneIndex >= 0) {
                    if (
                      isEmpty(
                        currentConfiguration[component.component_code][
                          component.order_type
                        ][param.zone_id]
                      )
                    ) {
                      currentConfiguration[component.component_code][
                        component.order_type
                      ][param.zone_id] = {};
                    }
                    currentConfiguration[component.component_code][
                      component.order_type
                    ][param.zone_id][param.accessorial_key] =
                      param.accessorial_value;
                  }
                } else {
                  currentConfiguration[component.component_code][
                  component.order_type
                  ][param.accessorial_key] = param.accessorial_value;
                }
              } else {
                currentConfiguration[component.component_code][
                  component.order_type
                ][param.accessorial_key] = param.accessorial_value;
              }
            }
          });
        } else {
          // Assuming ranges
          component.param_values.forEach((param, index) => {
            const weightsData =
              currentConfiguration[component.component_code] &&
              currentConfiguration[component.component_code][
                component.order_type
              ] &&
              currentConfiguration[component.component_code][
                component.order_type
              ][param.accessorial_key] &&
              currentConfiguration[component.component_code][
                component.order_type
              ][param.accessorial_key].weightsData
                ? [
                    ...(currentConfiguration[component.component_code][
                      component.order_type
                    ][param.accessorial_key] &&
                      currentConfiguration[component.component_code][
                        component.order_type
                      ][param.accessorial_key].weightsData),
                  ]
                : [];
            const accessorialWeights =
              currentConfiguration[component.component_code] &&
              currentConfiguration[component.component_code][
                component.order_type
              ][param.accessorial_key] &&
              currentConfiguration[component.component_code][
                component.order_type
              ][param.accessorial_key].accessorialWeights
                ? [
                    ...(currentConfiguration[component.component_code][
                      component.order_type
                    ][param.accessorial_key] &&
                      currentConfiguration[component.component_code][
                        component.order_type
                      ][param.accessorial_key].accessorialWeights),
                  ]
                : [];
            let tempId = randomNumber();
            const existRecord = _.find(weightsData, {
              min_weight: component.min_weight,
              max_weight: component.max_weight,
            });
            if (
              isEmpty(existRecord) &&
              !isEmpty(component.min_weight) &&
              !isEmpty(component.min_weight)
            ) {
              weightsData.push({
                id: tempId,
                min_weight: component.min_weight,
                max_weight: component.max_weight,
                isNew: false,
              });
            } else if (!isEmpty(existRecord)) {
              tempId = existRecord.id;
            }
            const accWeightIndex = _.findIndex(accessorialWeights, {
              accessorial_key: param.accessorial_key,
            });
            if (accWeightIndex < 0) {
              accessorialWeights.push({
                accessorial_key: param.accessorial_key,
                [tempId]: {
                  accessorial_value: parseFloat(param.accessorial_value),
                  breakpoint_weight: !isEmpty(component.breakpoint_weight)
                    ? component.breakpoint_weight
                    : "",
                },
              });
            } else {
              accessorialWeights[accWeightIndex][tempId] = {
                accessorial_value: parseFloat(param.accessorial_value),
                breakpoint_weight: !isEmpty(component.breakpoint_weight)
                  ? component.breakpoint_weight
                  : "",
              };
            }
            currentConfiguration[component.component_code][
              component.order_type
            ][param.accessorial_key].weightsData = weightsData;
            currentConfiguration[component.component_code][
              component.order_type
            ][param.accessorial_key].accessorialWeights = accessorialWeights;
            /* Start Rohit Code */
            /* if (param.accessorial_key === "cost_per_weight"){
              currentConfiguration[component.component_code][
                component.order_type
              ][param.accessorial_key].weightsData = weightsData;
              currentConfiguration[component.component_code][ component.order_type ][param.accessorial_key].accessorialWeights = accessorialWeights;
            }
            */
            /* End Rohit Code */
          });
        }
      }
    });
  }
    this.setState(
      {
        currentComponents,
        currentConfiguration,
      },
      () => {}
    );
  };

  getComponents = () => {
    this.setState({ inProgress: true });
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const components = result.components || [];
        this.setState(
          {
            components,
            inProgress: false,
          },
          () => {
            this.setComponents();
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  clearForm = () => {
    this.setState({
      isNew: false,
    });
  };

  getMaxWeight = (currentConfiguration, componentCode, orderType) => {
    if (!currentConfiguration[componentCode]) {
      currentConfiguration[componentCode] = {};
    }
    if (!currentConfiguration[componentCode][orderType]) {
      currentConfiguration[componentCode][orderType] = {};
    }
    const currentRecord = currentConfiguration[componentCode][orderType];
    const weightsData = currentRecord["cost_per_weight"]
      ? currentRecord["cost_per_weight"]["weightsData"]
      : {};
    const lastObject =
      weightsData && weightsData.length > 0
        ? weightsData[weightsData.length - 1]
        : {};
    const maxWeight = !isEmpty(lastObject.max_weight)
      ? lastObject.max_weight
      : "";
    return maxWeight;
  };

  retrieveChangedValue = (originalValue, value, innerParamIndex = -1, innerParamRec) => {
    if(innerParamIndex >= 0) {
      let clonedValue = originalValue ? [...originalValue] : [];
      if(clonedValue.length >= innerParamIndex && innerParamRec){
        clonedValue[innerParamIndex][innerParamRec] = value;
      }
      return clonedValue;
    } else{
      return value;
    }
  }

  handleOnChange = (code, orderType, element, value, zoneId, innerParamIndex = -1, innerParamRec = null) => {
    const { currentConfiguration } = this.state;
    if (isEmpty(currentConfiguration[code])) {
      currentConfiguration[code] = {};
    }
    if (isEmpty(orderType)) {
      if (element === "zone_category") {
        defaultOrderTypes.forEach(order => {
          if (isEmpty(currentConfiguration[code])[order]) {
            currentConfiguration[code][order] = {};
          } else {
            currentConfiguration[code][order] = {};
          }
        })
        currentConfiguration[code][element] = this.retrieveChangedValue(currentConfiguration[code][element], value, innerParamIndex, innerParamRec);
      } else {
        currentConfiguration[code][element] = this.retrieveChangedValue(currentConfiguration[code][element], value, innerParamIndex, innerParamRec);
      }
    } else {
      // if (orderType)
      if (isEmpty(currentConfiguration[code])[orderType]) {
        currentConfiguration[code][orderType] = {};
      }
      if (isEmpty(zoneId)) {
        currentConfiguration[code][orderType][element] = this.retrieveChangedValue(currentConfiguration[code][orderType][element], value, innerParamIndex, innerParamRec);
        if (element === "slot_type") {
          if(value === 'all'){
            const slotConfig = currentConfiguration[code][orderType]['slot_conguration'];
            const filteredValue = slotConfig && slotConfig.length > 1 ? slotConfig.filter((slot, i) => i === 0) : (slotConfig ? [...slotConfig] : []);
            currentConfiguration[code][orderType]['slot_conguration'] =  filteredValue
          }
        }
        if (element === "is_excess_config") {
          if (value === false) {
            currentConfiguration[code][orderType]["excess_charge_amount"] = "";
            currentConfiguration[code][orderType]["excess_weight"] = "";
            currentConfiguration[code][orderType]["weight_range_type"] = "complete_weight";
          } else {
            currentConfiguration[code][orderType]["excess_charge_amount"] = "";
            currentConfiguration[code][orderType]["excess_weight"] = "";
            currentConfiguration[code][orderType]["weight_range_type"] = "complete_weight";
          }
        }
        if (element === "weight_range_type") {
          if (value === "complete_weight") {
            currentConfiguration[code][orderType]["excess_weight"] = "";
          } else {
            currentConfiguration[code][orderType][
              "excess_weight"
            ] = this.getMaxWeight(currentConfiguration, code, "A");  // code: WEIGHT
          }
        }
      } else {
        if (isEmpty(currentConfiguration[code][orderType][zoneId])) {
          currentConfiguration[code][orderType][zoneId] = {};
        }
        currentConfiguration[code][orderType][zoneId][element] = value;
      }
    }

    this.setState({
      currentConfiguration,
    });
  };

  handleRepeatableElementChange = (element, index, subElement, value) => {

  }

  handleSave = () => {
    const currentConfiguration = Object.assign(
      {},
      this.state.currentConfiguration
    );
    const data = { component_params: [] };
    data.code = !isEmpty(this.state.currentAccessorial.accessorial_code)
      ? this.state.currentAccessorial.accessorial_code
      : "";
    data.account_id = this.state.account.id;
    data.organization_id = this.state.account.organization_id;
    Object.keys(currentConfiguration).forEach((component) => {
      const currentParam = {
        component_code: component,
        accessorial_params: [],
      };
      const rangeParams = [];
      const selectedTypes = currentConfiguration[component].isTypeBased
        ? ["R", "D"]
        : ["A"];
      if (currentConfiguration[component].isZoneBased) {
        currentParam.zone_category =
          currentConfiguration[component].zone_category;
      }

      selectedTypes.forEach((type) => {
        const params = [];
        Object.keys(currentConfiguration[component][type]).forEach((param) => {
          const currentRecord = currentConfiguration[component][type];
          if (param === "cost_per_weight") {
            const weightsData = currentRecord[param].weightsData || [];
            const accessorialWeights =
              currentRecord[param].accessorialWeights || [];
            weightsData.forEach((weightRecord) => {
              const paramObject = {
                params_with_wt: "true",
                min_weight: parseInt(weightRecord.min_weight),
                max_weight: parseInt(weightRecord.max_weight),
                order_type: type,
                wt_param_values: [],
              };
              accessorialWeights.forEach((accessorial) => {
                paramObject.wt_param_values.push({
                  accessorial_key: accessorial.accessorial_key,
                  accessorial_value: !isEmpty(accessorial[weightRecord.id])
                    ? accessorial[weightRecord.id].accessorial_value
                    : "",
                });
              });
              /* Start Rohit Code */
              /* const isExcessConfig = _.find(paramObject.wt_param_values, {
                accessorial_key: "is_excess_config",
              });
              if(!isExcessConfig){
                paramObject.wt_param_values.push({
                  accessorial_key: "is_excess_config",
                  accessorial_value: false,
                });
              }
              const excessWeight = _.find(paramObject.wt_param_values, {
                accessorial_key: "excess_weight",
              });
              if (!excessWeight) {
                paramObject.wt_param_values.push({
                  accessorial_key: "excess_weight",
                  accessorial_value: 0,
                });
              }
              const excessChargeAmount = _.find(paramObject.wt_param_values, {
                accessorial_key: "excess_charge_amount",
              });
              if (!excessChargeAmount) {
                paramObject.wt_param_values.push({
                  accessorial_key: "excess_charge_amount",
                  accessorial_value: 0,
                });
              }
              */
              /* End Rohit Code */
              rangeParams.push(paramObject);
            });
          } else {
            if (
              currentConfiguration[component].isZoneBased &&
              currentConfiguration[component].zone_category === "INDV_ZONES"
            ) {
              // assuming zone based record
              const currentZoneRecord =
                currentRecord && currentRecord[param]
                  ? currentRecord[param]
                  : {};
              Object.keys(currentZoneRecord).forEach((zoneParam) => {
                const paramObject = {
                  accessorial_key: zoneParam,
                  accessorial_value: currentZoneRecord[zoneParam],
                  zone_id: param,
                };
                params.push(paramObject);
              });
            } else {
              const paramObject = {
                accessorial_key: param,
                accessorial_value: currentRecord[param],
              };
              params.push(paramObject);
            }
          }
        });
        if (params.length > 0) {
          const accessorialParamObject = {
            params_with_wt: "false",
            min_weight: "0",
            max_weight: "0",
            order_type: type,
            wt_param_values: [...params],
          };
          currentParam.accessorial_params = [
            ...currentParam.accessorial_params,
            accessorialParamObject,
          ];
        }
        if (rangeParams.length > 0) {
          currentParam.accessorial_params = [].concat(
            currentParam.accessorial_params,
            rangeParams
          );
        }
      });
      data.component_params.push(currentParam);
    });
    const errors = [];
    if (errors.length === 0) {
      this.setState({ inProgress: true });
      saveAccessorialConfig(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.setState(
            {
              errors: [],
              inProgress: false,
            },
            () => {
              this.getAccessorialDetails(this.state.currentAccessorial._id);
            }
          );
          // this.clearForm();
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };
  // handleSave = () => {
  //   const currentConfiguration = Object.assign(
  //     {},
  //     this.state.currentConfiguration
  //   );
  //   const data = { component_params: [] };
  //   Object.keys(currentConfiguration).forEach((component) => {
  //     const currentParam = {
  //       component_code: component,
  //       accessorial_params: [],
  //     };
  //     const rangeParams = [];
  //     const params = [];
  //     const selectedTypes = currentConfiguration[component].isTypeBased
  //       ? ["R", "D"]
  //       : ["A"];
  //     selectedTypes.forEach((type) => {
  //       Object.keys(currentConfiguration[component][type]).forEach((param) => {
  //         const currentRecord = currentConfiguration[component][type];
  //         if (param === "cost_per_weight") {
  //           const weightsData = currentRecord[param].weightsData || [];
  //           const accessorialWeights =
  //             currentRecord[param].accessorialWeights || [];
  //           weightsData.forEach((weightRecord) => {
  //             const paramObject = {
  //               params_with_wt: "true",
  //               min_weight: weightRecord.min_weight,
  //               max_weight: weightRecord.max_weight,
  //               order_type: type,
  //               wt_param_values: [],
  //             };
  //             accessorialWeights.forEach((accessorial) => {
  //               paramObject.wt_param_values.push({
  //                 accessorial_key: accessorial.accessorial_key,
  //                 accessorial_value: !isEmpty(accessorial[weightRecord.id])
  //                   ? accessorial[weightRecord.id].accessorial_value
  //                   : "",
  //               });
  //             });
  //             rangeParams.push(paramObject);
  //           });
  //         } else {
  //           const paramObject = {
  //             accessorial_key: param,
  //             accessorial_value: currentRecord[param],
  //           };
  //           params.push(paramObject);
  //         }
  //       });
  //       if (params.length > 0) {
  //         const accessorialParamObject = {
  //           params_with_wt: "false",
  //           min_weight: "0",
  //           max_weight: "0",
  //           order_type: type,
  //           wt_param_values: [...params],
  //         };
  //         currentParam.accessorial_params = [accessorialParamObject];
  //       }
  //       if (rangeParams.length > 0) {
  //         currentParam.accessorial_params = [].concat(
  //           currentParam.accessorial_params,
  //           rangeParams
  //         );
  //       }
  //     });
  //     data.component_params.push(currentParam);
  //   });
  //   const errors = [];
  //   if (errors.length === 0) {
  //     data.code = !isEmpty(this.state.currentAccessorial.accessorial_code)
  //       ? this.state.currentAccessorial.accessorial_code
  //       : "";
  //     data.account_id = this.state.account.id;
  //     data.organization_id = this.state.account.organization_id;
  //     this.setState({ inProgress: true });
  //     saveAccessorialConfig(data).then((result) => {
  //       if (result.success) {
  //         alertMessage(I18n.t("messages.saved"));
  //         this.setState({
  //           errors: [],
  //           inProgress: false,
  //         });
  //         // this.clearForm();
  //       } else {
  //         this.setState({ errors: result.errors, inProgress: false });
  //       }
  //     });
  //   } else {
  //     this.setState({ errors, inProgress: false });
  //   }
  // };

  handleAddWeight = (code, orderType, param) => {
    const { currentConfiguration } = this.state;
    // const paramLabel = this.getParamLabel(code, param.code);
    const paramLabel = 'Cost'
    const paramCode =  param.code;
    const currentRecord = currentConfiguration[code][orderType];
    const rangeLabel = this.getRangeLabel(currentRecord, param, '')
    this.setState({
      currentRatecard: {
        code,
        paramCode,
        min_label: `Min ${rangeLabel}`,
        max_label: `Max ${rangeLabel}`,
        rangeLabel: rangeLabel,
        param_values: [
          {
            accessorial_key: paramCode,
            accessorial_value: "",
            accessorial_label: paramLabel,
          },
        ],
        isNew: true,
        orderType,
      },
      isNew: true,
      showWeightForm: true,
      weights: currentRecord[paramCode]?.weightsData ? [...currentRecord[paramCode].weightsData] : [],
    });
  };

  handleDeleteWeight = (code, orderType, paramCode, weightRecord) => {
    const { currentConfiguration, currentAccessorial, account } = this.state;
    if (
      currentConfiguration[code] &&
      currentConfiguration[code][orderType] &&
      currentConfiguration[code][orderType][paramCode]
    ) {
      const data = {
        account_accessorial_id: currentAccessorial._id,
        accessorial_code: currentAccessorial.accessorial_code,
        account_id: account.id,
        weight_param: {
          min_weight: weightRecord.min_weight,
          max_weight: weightRecord.max_weight,
        },
      };

      deleteWeightRange(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.deleted"));
          this.setState(
            {
              errors: [],
              inProgress: false,
            },
            () => {
              this.getAccessorialDetails(currentAccessorial._id);
            }
          );
          // this.clearForm();
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
      // const weightsData =
      //   currentConfiguration[code][orderType][paramCode].weightsData || [];
      // const accessorialWeights =
      //   currentConfiguration[code][orderType][paramCode].accessorialWeights ||
      //   [];
      // const weightIndex = _.findIndex(weightsData, ["id", weightRecord.id]);
      // const newAccWeights = [];
      // if (weightIndex >= 0) {
      //   weightsData.splice(weightIndex, 1);
      //   accessorialWeights.forEach((accessorialWeightObject) => {
      //     delete accessorialWeightObject[weightRecord.id];
      //     newAccWeights.push(accessorialWeightObject);
      //   });
      //   currentConfiguration[code][orderType][paramCode].weightsData = [
      //     ...weightsData,
      //   ];
      //   currentConfiguration[code][orderType][paramCode].accessorialWeights = [
      //     ...newAccWeights,
      //   ];
      // }
      // this.setState(
      //   {
      //     currentConfiguration,
      //   },
      //   () => {
      //     this.handleSave();
      //   }
      // );
    }
  };

  getParamLabel = (code, paramCode) => {
    const { currentComponents } = this.state;
    const currentRecord = _.find(currentComponents, { code });
    if (!isEmpty(currentRecord)) {
      const currentParams = currentRecord.component_params || [];
      const currentParam = _.find(currentParams, { code: paramCode });
      if (!isEmpty(currentParam)) {
        return currentParam.name;
      }
    }
    return "";
  };

  getRangeLabel = (currentRecord, param, defaultValue = '', reqConversion = true) => {
    let rangeLabel = !isEmpty(param.range_type) ? param.range_type : (!isEmpty(currentRecord.range_type) ? currentRecord.range_type : defaultValue);
    rangeLabel = reqConversion ? (rangeLabel.length > 1 ? rangeLabel.charAt(0).toUpperCase() + rangeLabel.slice(1) : rangeLabel) : rangeLabel;
    return rangeLabel;
  };

  renderWeightRangeFooter = (componentCode, orderType, paramCode) => {
    const { currentConfiguration } = this.state;
    const currentRecord = currentConfiguration[componentCode][orderType];
    const { weightsData } = currentRecord[paramCode];
    const lastObject = weightsData[weightsData.length - 1];
    const maxWeight = lastObject.max_weight;
    const isExcessConfig = currentRecord?.is_excess_config
      ? currentRecord.is_excess_config
      : false;
    const excessWeight = currentRecord?.excess_weight
      ? currentRecord.excess_weight
      : maxWeight;
    const excessChargeAmount = currentRecord?.excess_charge_amount
      ? currentRecord.excess_charge_amount
      : 0;

    return (
      <Row>
        <Col>
          <Checkbox
            checked={isExcessConfig}
            onChange={(e) =>
              this.handleOnChange(
                componentCode,
                orderType,
                "is_excess_config",
                e.target.checked
              )
            }
          >
            {"Excess Config"}
          </Checkbox>
        </Col>
        {isExcessConfig && (
          <Col>
            <Text strong>
              In Excess of
              <Input
                style={{
                  width: "100px",
                  textAlign: "center",
                  borderWidth: "0px 0px 1px 0px",
                }}
                onChange={(e) => {
                  this.handleOnChange(
                    componentCode,
                    orderType,
                    "excess_weight",
                    e.target.value || 0
                  );
                }}
                value={excessWeight}
              />
              lbs. cost per lbs $
              <Input
                style={{
                  width: "80px",
                  textAlign: "center",
                  borderWidth: "0px 0px 1px 0px",
                }}
                onChange={(e) => {
                  this.handleOnChange(
                    componentCode,
                    orderType,
                    "excess_charge_amount",
                    e.target.value || 0
                  );
                }}
                value={excessChargeAmount}
              />
            </Text>
          </Col>
        )}
      </Row>
    );
  };

  handleEditWeight = (code, orderType, param, record, weightRecord) => {
    const { currentConfiguration } = this.state;
    const currentRecord = currentConfiguration[code][orderType];
    const paramLabel = "Cost" //this.getParamLabel(code, param.code);
    const paramCode = param.code;
    const rangeLabel = this.getRangeLabel(currentRecord, param, '')
    this.setState({
      currentRatecard: {
        code,
        paramCode,
        min_label: `Min ${rangeLabel}`,
        max_label: `Max ${rangeLabel}`,
        rangeLabel: rangeLabel,
        ...weightRecord,
        param_values: [
          {
            accessorial_key: paramCode,
            accessorial_value:
              record[weightRecord.id] &&
              record[weightRecord.id].accessorial_value
                ? record[weightRecord.id].accessorial_value
                : 0.0,
            accessorial_label: paramLabel,
          },
        ],
        isNew: false,
        orderType,
      },
      showWeightForm: true,
      isNew: false,
      weights: currentRecord[paramCode]?.weightsData ? [...currentRecord[paramCode].weightsData] : [],
    });
  };

 


  renderField = (
    component,
    orderType,
    mainParam,
    currentConfiguration,
    zone_id,
    innerParam = null,
    innerParamIndex = -1,
  ) => {
    const size = "small";
    const { decimalPoints } = this.state;
    const innerParamCode = innerParam ? innerParam.code : ''
    const param = innerParam ? innerParam : mainParam;
    const { currentRecord , currentValue } = retrieveParamValue( component,orderType, mainParam, currentConfiguration,zone_id, innerParamIndex, innerParam);
    switch (param.data_type) {
      case "string":
        return (
          <Row>
            <Col>
              {param.code === "charge_type" ? (
                <Fragment>
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnChange(
                        component.code,
                        orderType,
                        mainParam.code,
                        e.target.value,
                        zone_id,
                        innerParamIndex,
                        innerParamCode,
                      )
                    }
                    value={currentValue}
                    size={size}
                  >
                    <Radio value="percentage">Percentage</Radio>
                    <Radio value="flat">Flat</Radio>
                  </Radio.Group>
                  {currentRecord[param.code] === "percentage" && (
                    <p className="notes_content" style={{ marginTop: 4 }}>
                      {I18n.t("account.billing.percentage_help_text")}
                    </p>
                  )}
                </Fragment>
              ) : param.code === "charge_value" ? (
                <Input
                  type="number"
                  addonBefore={
                    currentRecord.charge_type === "flat" ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentRecord.charge_type === "percentage" ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                  value={currentValue}
                  onChange={(e) =>
                    this.handleOnChange(
                      component.code,
                      orderType,
                      mainParam.code,
                      e.target.value,
                      zone_id,
                      innerParamIndex,
                      innerParamCode,
                    )
                  }
                  className="textUpperCase"
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                />
              ) : (
                <Input
                  value={currentValue}
                  onChange={(e) =>
                    this.handleOnChange(
                      component.code,
                      orderType,
                      mainParam.code,
                      e.target.value,
                      zone_id,
                      innerParamIndex,
                      innerParamCode,
                    )
                  }
                  size={size}
                />
              )}
            </Col>
          </Row>
        );
      case "number":
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentValue}
                onChange={(e) =>
                  this.handleOnChange(
                    component.code,
                    orderType,
                    mainParam.code,
                    e.target.value,
                    zone_id,
                    innerParamIndex,
                    innerParamCode,
                  )
                }
                addonBefore={
                  param.dependency_key &&
                  param.dependency_key === "charge_type" &&
                  currentRecord.charge_type === "flat" ? (
                    <Icon type="dollar" />
                  ) : null
                }
                addonAfter={
                  param.dependency_key &&
                  param.dependency_key === "charge_type" &&
                  currentRecord.charge_type === "percentage" ? (
                    <Icon type="percentage" />
                  ) : null
                }
                className="textUpperCase"
                min={0}
                precision={
                  param.code === "min_charge" || param.code === "max_charge"
                    ? decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        );
      case "price":
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentValue}
                onChange={(e) =>
                  this.handleOnChange(
                    component.code,
                    orderType,
                    mainParam.code,
                    e.target.value,
                    zone_id,
                    innerParamIndex,
                    innerParamCode,
                  )
                }
                addonBefore={<Icon type="dollar" />}
                className="textUpperCase"
                min={0}
                precision={decimalPoints}
              />
            </Col>
          </Row>
        );
      case "Radio":
        return (
          <Row>
            <Col>
              <Row>
                <Radio.Group
                  value={currentValue}
                  onChange={(e) =>
                    this.handleOnChange(
                      component.code,
                      orderType,
                      mainParam.code,
                      e.target.value,
                      zone_id,
                      innerParamIndex,
                      innerParamCode,
                    )
                  }
                  size={size}
                >
                  {param.options.map((option) => (
                    <Radio value={option.value} autoFocus>
                      {option.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Row>
            </Col>
          </Row>
        );
      case "Checkbox":
        return (
          <Row>
            <Col>
              <Checkbox
                checked={currentValue}
                onChange={(e) =>
                  this.handleOnChange(
                    component.code,
                    orderType,
                    mainParam.code,
                    e.target.checked,
                    zone_id,
                    innerParamIndex,
                    innerParamCode,
                  )
                }
              >
                {param.name}
              </Checkbox>
            </Col>
          </Row>
        );
      case "range":
        return (
          <RateCard
            key="rateCard"
            data={currentRecord[param.code]?.accessorialWeights ? [...currentRecord[param.code].accessorialWeights] : []}
            weights={currentRecord[param.code]?.weightsData ? [...currentRecord[param.code].weightsData] : []}
            size="small"
            pagination={{ position: "none" }}
            handleAddWeight={() =>
              this.handleAddWeight(component.code, orderType, param)
            }
            showMincharge={false} // param.req_min_charge === true
            handleEditWeight={(record, weightRecord) =>
              this.handleEditWeight(
                component.code,
                orderType,
                param,
                record,
                weightRecord,
              )
            }
            deleteClick={(weightRecord) =>
              this.handleDeleteWeight(
                component.code,
                orderType,
                param.code,
                weightRecord
              )
            }
            rangeLabel={this.getRangeLabel(currentRecord, param, '', false)}
            // footer={ () => this.renderWeightRangeFooter(
            //   component.code,
            //   orderType,
            //   param.code,
            // )}
          />
        );
      case "Boolean":
        return (
          <Row>
            <Radio.Group
              value={currentValue}
              onChange={(e) =>
                this.handleOnChange(
                  component.code,
                  orderType,
                  mainParam.code,
                  e.target.value,
                  zone_id,
                  innerParamIndex,
                  innerParamCode,
                )
              }
              size={size}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        );
      case "Los":
      case "Select":
        return (
          <Row>
            <Col>
              <Select
                showSearch
                value={currentValue}
                style={{ width: "100%" }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) =>
                  this.handleOnChange(
                    component.code,
                    orderType,
                    mainParam.code,
                    e,
                    zone_id,
                    innerParamIndex,
                    innerParamCode,
                  )
                }
                size={size}
              >
                <Select.Option key={"SELECT_ALL"} value={""}>
                  -- Select --
                </Select.Option>

                {param.data_type === "Los"
                  ? this.state.levelOfServices.map((los, qindex) => (
                      <Select.Option key={los.code} value={los.id}>
                        {los.name}
                      </Select.Option>
                    ))
                  : param.options.map((qoption, qindex) => (
                      <Select.Option
                        key={qoption.option_key}
                        value={qoption.option_key}
                      >
                        {qoption.option_value}
                      </Select.Option>
                    ))}
              </Select>
            </Col>
          </Row>
        );
      case "time":
        return (
          <Row>
            <Col>
              <MaterialTimePicker
                onChange={(e) => this.handleOnChange(
                  component.code,
                  orderType,
                  mainParam.code,
                  e ? moment(e).format("HH:mm") : "",
                  zone_id,
                  innerParamIndex,
                  innerParamCode,
                )}
                value={currentValue ? currentValue : null}
                computeDisabledHours={ () => []}
                computeDisabledMinutes={() => []}
                isMilitaryTime={this.state.isMilitaryTime}
                size="small"
              />
            </Col>
          </Row>
        );
      case "SLOT_CONFIG":
        return (
          <SlotComponent
            key="rateCard"
            data={currentValue && _.isArray(currentValue) && currentValue.length > 0 ? {...currentValue[0]} : {} }
            // weights={[...currentRecord[param.code].weightsData]}
            accComponent={component}
            size="small"
            pagination={{ position: "none" }}
            handleAddWeight={() =>
              this.handleAddWeight(component.code, orderType, param)
            }
            showMincharge={param.req_min_charge === true}
            onChange={(value) =>
              this.handleOnChange(
                component.code,
                orderType,
                mainParam.code,
                value,
                zone_id,
                innerParamIndex,
                innerParamCode,
              )
            }
          />
        );
      
      default:
        break;
    }
  };

  closeWeightForm = () => {
    this.setState({
      currentRatecard: {},
      showWeightForm: false,
    });
  };

  renderWeightModal = () => {
    return (<BaseModal
      title={
        this.state.currentRatecard.isNew
          ? `Add ${this.state.currentRatecard.rangeLabel} Range`
          : "Update Rate"
      }
      onCancel={() => this.closeWeightForm()}
      width="40%"
      style={{ top: 50 }}
    >
      <WeightForm
        currentRatecard={this.state.currentRatecard}
        handleSave={this.handleWeightSave}
        weights={this.state.weights || []}
        isNew={this.state.isNew}
      />
    </BaseModal>);
  };

  handleWeightSave = (record, isNew) => {
    const currentConfiguration = { ...this.state.currentConfiguration };
    const weightsData = [
      ...currentConfiguration[record.code][record.orderType][record.paramCode].weightsData,
    ];
    const accessorialWeights = [
      ...currentConfiguration[record.code][record.orderType][record.paramCode]
        .accessorialWeights,
    ];
    let tempId = randomNumber();
    if (record.isNew) {
      weightsData.push({
        id: tempId,
        min_weight: record.min_weight,
        max_weight: record.max_weight,
        isNew: true,
      });
    } else {
      const currentWeightIndex = _.findIndex(weightsData, ["id", record.id]);
      if (currentWeightIndex >= 0) {
        weightsData[currentWeightIndex].min_weight = record.min_weight;
        weightsData[currentWeightIndex].max_weight = record.max_weight;
        tempId = record.id;
      }
    }
    if (record.param_values.length > 0) {
      record.param_values.forEach((param) => {
        const accWeightIndex = _.findIndex(accessorialWeights, {
          accessorial_key: param.accessorial_key,
        });
        if (accWeightIndex < 0) {
          accessorialWeights.push({
            accessorial_key: param.accessorial_key,
            [tempId]: {
              accessorial_value: parseFloat(param.accessorial_value),
              breakpoint_weight: "",
            },
          });
        } else {
          accessorialWeights[accWeightIndex][tempId] = {
            accessorial_value: parseFloat(param.accessorial_value),
          };
        }
      });
    }
    currentConfiguration[record.code][record.orderType][record.paramCode].weightsData = [
      ...weightsData,
    ];
    currentConfiguration[record.code][record.orderType][
      record.paramCode
    ].accessorialWeights = [...accessorialWeights];
    this.setState(
      {
        currentConfiguration,
        isNew: false,
        showWeightForm: false,
        currentRatecard: {},
      },
      () => {
        this.handleSave();
      }
    );
  };

  renderCategory = (component, orderType) => {
    const { currentConfiguration } = this.state;
    const currentConfigurationValues =
      currentConfiguration[component.code] || {};
    const currentRecord =
      currentConfiguration[component.code] &&
      currentConfiguration[component.code][orderType]
        ? currentConfiguration[component.code][orderType]
        : {};
    return (
      <Fragment>
        {currentConfigurationValues.isZoneBased &&
        currentConfigurationValues.zone_category === "INDV_ZONES" ? (
          <Fragment>
            {this.state.zones.map((zone) => (
              <Fragment>
                <Col md={3} className="paddingTop10 textBold centerToBlock">
                 {zone.name}
                </Col>
                <Col md={21}>
                  <Row>
                      {this.renderParams(component, orderType, zone.id)}
                  </Row>
                </Col>
                
              </Fragment>
            ))}
          </Fragment>
        ) : (
          this.renderParams(component, orderType, "")
        )}
      </Fragment>
    );
  };
  
   addNewRow = (code, orderType, param) => {
    const { currentConfiguration } = this.state;
    const currentRecord = currentConfiguration[code][orderType];
    if(param.is_repeatable){
       if(isEmpty(currentRecord)){
          currentRecord = { [param.code]: []}
       }
       if(isEmpty(currentRecord[param.code])){
          currentRecord[param.code] = [];
       }
       const totlaObjects = currentRecord[param.code]; 
       const lastObject = currentRecord[param.code] && totlaObjects.length > 0 ? totlaObjects[totlaObjects.length-1] : null;
       const repeatedConfig = this.getRepeatedObject(param, lastObject);
       currentRecord[param.code].push(repeatedConfig);
       currentConfiguration[code][orderType] = {...currentRecord};
    }
    this.setState({
      currentConfiguration,
    });
  };

  handleDeleteItem = (code, orderType, param, index) => {
   const { currentConfiguration } = this.state;
    const currentRecord = currentConfiguration[code][orderType];
    if(param.is_repeatable){
       if(!isEmpty(currentRecord) && currentRecord[param.code] && currentRecord[param.code].length >= index ){
           currentRecord[param.code].splice(index, 1)
       }
       currentConfiguration[code][orderType] = {...currentRecord};
    }
    this.setState({
      currentConfiguration,
    });
  };

  renderRepeatedSet = (component, orderType, param, currentConfiguration, zone_id) => {
    const { currentRecord , currentValue } = retrieveParamValue( component,orderType,param,currentConfiguration,zone_id);
    const headings = []
    const gridSize = param.repeated_set.length * 4;
    return <Fragment>
     {
       param.repeated_set.length > 0 &&
       <Row gutter={16} className="marginBottom15">
          <Col>
               <Row gutter={16}> 
                {
                  param.repeated_set.map(reqObject => {
                    headings.push(reqObject.code);
                    return (<Col xs={4}>
                      <span className="textBold">{reqObject.name}</span>{reqObject.is_required && <sup className="textRed">*</sup>}
                    </Col>)
                  })
                }
            </Row>
          </Col>
        </Row>
     }
     {
        currentValue.map((rec, index) => {
          return <Row gutter={16}>
            <Col>
               <Row gutter={16}> 
                   {
                    Object.keys(rec).map((recPram) => {
                    const reqObject = _.find(param.repeated_set, { code: recPram})
                    return (<Col span={4}>
                      {/* <FormItem label={reqObject.name} require={reqObject.is_required}> */}
                        {
                          this.renderField(
                            component,
                            orderType,
                            param,
                            currentConfiguration,
                            zone_id,
                            reqObject,
                            index
                          )
                        }
                      {/* </FormItem> */}
                    </Col>
                    )})
                  }
                  <Col xs={2}>
                    <Popconfirm
                        placement="topRight"
                        title={I18n.t('messages.delete_confirm')}
                        onConfirm={() => this.handleDeleteItem(component.code, orderType, param, index )}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button size="small" type="danger" icon="delete" style={{ width: 20, height: 20, fontSize: 12 }}/>
                      </Popconfirm>
               
                  </Col>
                 
               </Row>
            </Col>
           
          </Row>
        }
      )
    }
    </Fragment>
  }

  renderParams = (component, orderType, zone_id = "") => {
    const { currentConfiguration } = this.state;
    const currentConfigurationValues =
      currentConfiguration[component.code] || {};
    const currentRecord =
      currentConfiguration[component.code] &&
      currentConfiguration[component.code][orderType]
        ? currentConfiguration[component.code][orderType]
        : {};

    return (
      <Fragment>
        {component.component_params.map((param) => {
          const isMetCondition = !isEmpty(param.repeatable_dependency_key) && currentRecord[param.repeatable_dependency_key] === param.repeatable_dependency_value
          if (
            !isEmpty(param.dependency_value) &&
            !isEmpty(param.dependency_key) &&
            currentRecord[param.dependency_key] !== param.dependency_value
          ) {
            return <Fragment />;
          }
          return (
            <Col
              md={
                ["range", "set", "SLOT_CONFIG"].includes(param.data_type)
                  ? 24
                  : param.data_type === "Radio"
                  ? 8
                  : 4
              }
              className={param.data_type === "range" ? "marginTop10" : ""}
            >
              {(
                param.is_repeatable ? (
                <Row>
                  <Col xs={12} className="textBold">
                    {param.name}
                  </Col>
                   {  (!isMetCondition || (isMetCondition && currentRecord[param.code] && currentRecord[param.code].length === 0)) &&
                    <Col xs={12} className="alignCenter">
                      <Button size="small" type="primary" onClick={() => this.addNewRow(component.code, orderType, param )}>{I18n.t('general.add')}</Button>
                   </Col>
                   }
                   <Col xs={24}>
                     { this.renderRepeatedSet(component, orderType, param, currentConfiguration, zone_id)}
                   </Col>
                   
                </Row>
              ) :
              param.data_type !== "Checkbox" ? (
                <FormItem label={param.name} require={param.is_required}>
                  {this.renderField(
                    component,
                    orderType,
                    param,
                    currentConfiguration,
                    zone_id
                  )}
                </FormItem>
              )  :
                this.renderField(
                  component,
                  orderType,
                  param,
                  currentConfiguration,
                  zone_id
                )
              )}
            </Col>
          );
        })}
      </Fragment>
    );
  };

  onCancel = () => {
    this.setState({
      currentAccessorial: {},
      components: [],
      isNew: false,
      account: {},
      inProgress: false,
      errors: [],
      currentComponents: [],
      currentConfiguration: [],
      levelOfServices: [],
      zones: [],
    });
    this.props.onCancel();
  }

  render() {
    const { currentAccessorial, currentConfiguration } = this.state;
    const gridSize = 8;
    const hasPassthroughCharges = currentAccessorial.components.find(
      (cmp) => cmp.component_code === "S_CHARGES"
    );
    const { orderConfig } = this.props;
    return (
      <div>
        <Row>
          <Col md={gridSize}>
            <div className="info-text">
              <h4>{I18n.t("general.name")}:</h4>
              {currentAccessorial.accessorial_name}
            </div>
          </Col>
          <Col md={gridSize}>
            <div className="info-text">
              <h4>{I18n.t("general.code")}:</h4>
              {currentAccessorial.accessorial_code}
            </div>
          </Col>
          <Col md={gridSize}>
            <div className="info-text">
              <h4>{I18n.t("accessorials.gl_code")}:</h4>
              {currentAccessorial.gl_code}
            </div>
          </Col>
        </Row>
        <hr />

        <Row gutter={16}>
          <Col xs={24} md={24}>
            {hasPassthroughCharges && (
              <p className="notes_content textBold marginTop20 marginBottom20 fontSize13">
                <span className="textRed">Note: </span>Passthrough charges will
                be entered by Driver / Dispatcher
              </p>
            )}

            <Fragment>
              {this.state.currentComponents.map((component) => (
                <Card
                  size="small"
                  title={component.name}
                  style={{ marginTop: 10 }}
                  bodyStyle={{ textAlign: "left" }}
                >
                  {component.zone_wise === true && (
                    <Row>
                      <Col xs={24}>
                        <span className="textBold">
                          {I18n.t("accessorials.define_zone_wise")}:
                        </span>
                        {"  "}
                        <Radio.Group
                          onChange={(e) =>
                            this.handleOnChange(
                              component.code,
                              "",
                              "zone_category",
                              e.target.value
                            )
                          }
                          value={
                            currentConfiguration[component.code][
                              "zone_category"
                            ]
                          }
                          style={{ marginTop: 3 }}
                        >
                          <Radio value="ALL_ZONES">All Zones</Radio>
                          <Radio value="INDV_ZONES">Zone Wise</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  )}
                  {component.order_types && component.order_types.length > 1 ? (
                    <Row gutter={8}>
                      <Col xs={24} className="marginTop10 marginBottom5">
                        {/* <Radio.Group
                        onChange={(e) =>
                          this.handleOnChange(
                            component.code,
                            "depends_value",
                            e.target.value
                          )
                        }
                        value={
                          currentConfiguration[component.code][
                            "depends_value"
                          ] || "any"
                        }
                        size="small"
                      >
                        <Radio value="any">Any</Radio>
                        <Radio value="individual">Individual</Radio>
                      </Radio.Group> */}
                        <Checkbox
                          checked={
                            currentConfiguration[component.code][
                              "isTypeBased"
                            ] || false
                          }
                          onChange={(e) =>
                            this.handleOnChange(
                              component.code,
                              "",
                              "isTypeBased",
                              e.target.checked
                            )
                          }
                        >
                          {I18n.t(
                            "accessorials.separate_charges_by_order_type"
                          )}
                        </Checkbox>
                      </Col>
                      {currentConfiguration[component.code]["isTypeBased"] ===
                      true
                        ? component.order_types.map((section) => (
                            <Row gutter={8}>
                              <Col xs={24}>
                                <h3>{orderConfig.orderTypes[section].label}</h3>
                              </Col>
                              <Col xs={24}>
                                {this.renderCategory(component, section)}
                              </Col>
                            </Row>
                          ))
                        : this.renderCategory(component, "A")}
                    </Row>
                  ) : (
                    this.renderCategory(component, "A")
                  )}
                </Card>
              ))}
              <Row>{FormErrors(this.state.errors)}</Row>
              {this.state.currentComponents.length > 0 &&
              <Row>
                <Col xs={24}>
                  {FormButtons(
                    this.state.inProgress,
                    this.handleSave,
                    this.onCancel
                  )}
                </Col>
              </Row>
              }
            </Fragment>
          </Col>
        </Row>
        {this.state.showWeightForm && this.renderWeightModal()}
      </div>
    );
  }
}

AccessorialConfiguration.propTypes = {
  account: PropTypes.shape.isRequired,
  currentAccessorial: PropTypes.shape.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const AccessorialConfigurationComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const {orderConfig = {
    orderTypes: {}
  }}= useContext(OrderConfigContext);

  return (
    <AccessorialConfiguration
      organizationSettings={organizationSettings}
      orderConfig={orderConfig}
      {...props}
    />
  );
});

export default AccessorialConfigurationComponent;
