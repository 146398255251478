import React, { Fragment } from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';
import {isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import { Icon, Popover, Tooltip, Row, Col } from '../../common/UIComponents';
import Leg from '../../components/Tasks/Legs';
import PrintGallery from '../common/PrintGallery';
import ItemAccessorial from '../orders/ItemAccessorial';
import { formatSurfaceArea, formatWeight } from '../../helpers/orders';
import Copyable from '../common/Copyable';
import AppConfig from '../../config/AppConfig';
import CopyableContent from '../common/CopyableContent';

class CapacityOrders extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
        {
            key: "customer_order_number",
            title: I18n.t("order.order_no"),
            dataIndex: "customer_order_number",
            align: "center",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            // fixed: !props.isRowSelected ? "left" : null,
            render: (data, record) => {
              return (
                <Row
                  type="flex"
                  // justify="space-between"
                  align="middle"
                  // style={{
                  //   padding: "0 10px 0 5px",
                  // }}
                  gutter={4}
                  // style={{marginTop:23}}
                >
                  {/* <Col>
                    <TypeOfOrder
                      order={record}
                      orderTypeKey="type_of_order"
                      relatedOrderKey="related_order"
                      placement="topLeft"
                      showBadge={true}
                    />
                  </Col> */}
                  <Col>
                    <CopyableContent text={data} />
                  </Col>
                  {/* <Col span={3} >
                    <Copyable text={data} />
                  </Col> */}
                </Row>
              );
            },
          },
          {
            key: "weight",
            title: (
              <Fragment>
                <Row>
                  <Col className="alignCenter">
                    {I18n.t("order.weight")}&nbsp;({AppConfig.weight_unit})
                  </Col>
                  {/* <Col className="alignCenter"> </Col> */}
                </Row>
              </Fragment>
            ),
            dataIndex: "weight",
            render: (data, record) => (
              <div className="alignRight paddingRight20">
                {!isEmpty(data) ? formatWeight(data) : ""}
              </div>
            ),
            width: 120,
            sortDirections: ["ascend", "descend", "ascend"],
            sorter: (a, b) => compareNumber(a.weight, b.weight),
          },
          {
            key: "surface_area",
            title: (
              <Fragment>
                <Row>
                  <Col className="alignLeft">
                    {I18n.t("order.surface_area")}&nbsp;({AppConfig.units.vol})
                  </Col>
                  {/* <Col className="alignCenter"> ({AppConfig.units.area})</Col> */}
                </Row>
              </Fragment>
            ),
            dataIndex: "surface_area",
            render: (data, record) => (
              <div className="alignRight paddingRight20">
                {!isEmpty(data) ? formatSurfaceArea (data) : ""}
              </div>
            ),
            // sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            sorter: (a, b) => compareNumber(a.surface_area, b.surface_area),
            width: 150
          }];
  }
}

export default CapacityOrders;
