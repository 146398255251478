/* global google */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Marker, InfoWindow, Polyline, OverlayView } from 'react-google-maps'
import MainMap from '../common/Map'
import AddressInfo from '../common/AddressInfo'
import { getAddress } from './Utils'
import AppConfig from '../../config/AppConfig'
import { isEmpty } from '../../common/Common'
import warehouseImg from '../../../assets/images/warehouceMarker.png'
import { Card, Row, Col, Avatar, Popover } from '../../common/UIComponents'
import { contrast } from '../../common/Colors'
import manlogo from '../../../assets/images/driver.png'
import { momentTimeWithShortName } from '../../helpers/date_functions'
import { pinLikeMarker } from '../dispatches/helpers'

// const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');
const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  strokeColor: '#479ada',
  strokeWeight: 2,
  scale: 4,
}
const getPixelPositionOffset = (width, height) => ({
  x: -(width / 4),
  y: -(height / 4),
})

const DisplayAddress = ({ event, job }) => {
  const address = getAddress(event.event_status, job)
  return <AddressInfo address={address} />
}

const markerIcon = (event) =>
  // if (event.task_type) {
  //   return event.task_type === 'PICKUP' ? pickup : drop;
  // }
  ({
    // eslint-disable-next-line no-undef
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: 'blue',
    fillOpacity: 0.4,
    scale: 4.5,
    strokeColor: 'white',
    strokeWeight: 1,
  })
DisplayAddress.propTypes = {
  event: PropTypes.shape().isRequired,
  job: PropTypes.shape().isRequired,
}

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: '',
      isOpen: [],
      events: [],
      tracks: [],
      job: '',
      defaultLat: 80.9873867,
      defaultLng: 15.472588,
      route: { stop_markers: [] },
      centerLatLng: {},
    }
    this.onMapMounted = this.onMapMounted.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {}
    if (props.data.length !== state.tracks.length) {
      const job = props.jobs.length ? props.jobs[0] : ''
      const events = props.data.map((event) => ({
        ...event,
        latitude: parseFloat(event.latitude),
        longitude: parseFloat(event.longitude),
      }))
      const tracks = props.data.map((event) => ({
        lat: parseFloat(event.latitude),
        lng: parseFloat(event.longitude),
      }))
      newState.events = events
      newState.tracks = tracks
      newState.job = job
    }
    if (
      props.route &&
      props.route.stop_markers &&
      state.route.stop_markers &&
      props.route.stop_markers.length !== state.route.stop_markers.length
    ) {
      newState.route = props.route ? { ...props.route } : { stop_markers: [] }
    }
    return newState
  }

  componentDidUpdate(prevProps, prevState) {
    // this.doSetBounds();
    if (
      prevState.route.stop_markers.length !==
        this.state.route.stop_markers.length ||
      prevState.tracks.length !== this.state.tracks.length
    ) {
      this.doSetBounds()
    }
  }

  onToggleOpen(e, i) {
    const isOpen = []
    isOpen[i] = !this.state.isOpen[i]
    this.setState({
      isOpen,
    })
  }

  onMapMounted(map) {
    if (map) {
      this.map = map
    }
    if (this.state.map === '' && map) {
      this.setState({ map }, () => {
        this.doSetBounds()
      })
    }
  }

  onMarkerClustererClick = (markerClusterer) => {
    const clickedMarkers = markerClusterer.getMarkers()
  }

  doSetBounds = () => {
    if (window.google && this.state.map) {
      const bounds = new window.google.maps.LatLngBounds()
      this.state.tracks.forEach((event) => {
        bounds.extend(new window.google.maps.LatLng(event.lat, event.lng))
      })
      const stopMarkers =
        this.state.route && this.state.route.stop_markers
          ? this.state.route.stop_markers
          : []
      stopMarkers.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng))
      })
      this.state.map.fitBounds(bounds)
      this.center = bounds.getCenter()
      this.setState({
        centerLatLng: {
          latitude: this.center.lat(),
          longitude: this.center.lng(),
        },
      })
    }
  }

  render() {
    const eventTimeFormat = this.props.isMilitaryTime ? "MMM DD, YYYY HH:mm" : "MMM DD, YYYY hh:mm A";
    return (
      <MainMap
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
        containerElement={<div style={{ width: '100%', height: '100%' }} />}
        centerLatLng={this.state.centerLatLng}
        mapElement={
          <div
            style={{
              width: '100%',
              height: this.props.hasSteps
                ? 'calc(100vh - 260px)'
                : 'calc(100vh - 80px)',
            }}
          />
        }
        onMapMounted={this.onMapMounted}
        defaultZoom={4}
        defaultCenter={{
          lat: this.state.defaultLat,
          lng: this.state.defaultLng,
        }}
        {...this.props}
      >
        <Fragment>
          {/* <MarkerClusterer
            onClick={this.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
          > */}
          {
            // props.route.status !== 'COMPLETED'
            !isEmpty(this.props.route) &&
              _.isObject(this.props.route) &&
              this.props.route.status !== 'COMPLETED' &&
              !isEmpty(this.state.events) &&
              this.state.events.length > 0 &&
              this.state.events[this.state.events.length - 1].latitude &&
              this.state.events[this.state.events.length - 1].longitude && (
                <OverlayView
                  position={{
                    lat: this.state.events[this.state.events.length - 1]
                      .latitude,
                    lng: this.state.events[this.state.events.length - 1]
                      .longitude,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <div>
                    <Popover
                      title="Driver Info"
                      content={
                        <div style={{ textAlign: 'left' }}>
                          <Row>
                            <Col md={24} lg={24} sm={24} xs={24}>
                              {
                                this.state.events[this.state.events.length - 1]
                                .tr_datetime
                                ? momentTimeWithShortName(eventTimeFormat, this.state.events[this.state.events.length - 1].tr_datetime, this.props.timeZoneId, this.props.tzShortName)
                                : ''
                              }
                            </Col>
                          </Row>
                        </div>
                      }
                    >
                      <div className="overLayPin">
                        <Avatar src={manlogo} size={20} />
                      </div>
                      <div className="pulse" />
                    </Popover>
                  </div>
                </OverlayView>
              )
          }
          {!isEmpty(this.props.route) &&
            _.isObject(this.props.route) &&
            this.props.route.stop_markers &&
            this.props.route.stop_markers.map((marker, stopIndex) => {
              const isStopVisited =
                marker.status &&
                [
                  'ARRIVED',
                  'INCOMPLETE',
                  'EXCEPTION',
                  'COMPLETED',
                  'CANCELLED',
                ].includes(marker.status) &&
                AppConfig.orderStatusColors[marker.status]
              const bgColor = isStopVisited
                ? AppConfig.orderStatusColors[marker.status].color
                : this.props.route.bgColor
              const fgColor = isStopVisited
                ? contrast(bgColor)
                : this.props.route.fgColor
              const isDepoStop = marker.location_type === 'WH' && (stopIndex === 0 || stopIndex === this.props.route.stop_markers.length - 1)
              const stopNo = (marker.location_type === 'WH' || marker.location_type === 'LH' ) && (stopIndex === 0 || stopIndex === this.props.route.stop_markers.length - 1) ? stopIndex + 1 : stopIndex
                   

              return (
                <Marker
                  position={{ lat: marker.lat, lng: marker.lng }}
                  key={marker.id}
                  // icon={(marker.stop_number == 1 || marker.stop_number == route.stop_markers.length) ? {
                  icon={
                    isDepoStop
                      ? {
                          url: warehouseImg,
                          scaledSize: new google.maps.Size(30, 30),
                          anchor: new google.maps.Point(16, 30),
                        }
                      : {
                          url: pinLikeMarker(stopNo, bgColor, fgColor),
                        }
                  }
                  // url: `https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=${stopNo}|${bgColor}|${fgColor}|8`,
                  onMouseOver={(e) => this.onToggleOpen(e, `${marker.id}`)}
                  onMouseOut={(e) => this.onToggleOpen(e, `${marker.id}`)}
                >
                  {this.state.isOpen[`${marker.id}`] && (
                    <InfoWindow
                      // onCloseClick={e => props.onToggleOpen(e, marker.stop_number, `${marker.id}`)}
                      options={{
                        alignTop: true,
                        closeBoxURL: '',
                        enableEventPropagation: true,
                      }}
                    >
                      <div
                        style={{
                          height: 'auto',
                          width: 'auto',
                          padding: 0,
                        }}
                        className="mapInfoWindow"
                      >
                        <Card
                          style={{
                            minWidth: 250,
                            marginTop: 12,
                            fontSize: '14px',
                            color: '#000000',
                            padding: '10px 5px',
                          }}
                          title={`${marker.formatted_stop_name}`}
                        >
                          {/* <Row>
                          <Col md={5} lg={5} sm={12} xs={12}>
                            Name
                          </Col>
                          <Col xs={1}>:</Col>
                          <Col md={18} lg={18} sm={18} xs={18}>
                            {marker.formatted_stop_name ? marker.formatted_stop_name : 'NA'}
                          </Col>
                      </Row> */}
                          <Row>
                            <Col md={5} lg={5} sm={12} xs={12}>
                              Route
                            </Col>
                            <Col xs={1}>:</Col>
                            <Col md={18} lg={18} sm={18} xs={18}>
                              {this.state.route.displayName
                                ? this.state.route.displayName
                                : 'NA'}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} lg={5} sm={12} xs={12}>
                              Status
                            </Col>
                            <Col xs={1}>:</Col>
                            <Col md={18} lg={18} sm={18} xs={18}>
                              {marker.status ? marker.status : 'NA'}
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )
            })}
          {false &&
            !isEmpty(this.props.route) &&
            _.isObject(this.props.route) &&
            this.props.route.stop_markers && (
              <Polyline
                key={`polyLine${this.props.route.route_id}`}
                path={this.props.route.stop_markers.map((stop) => ({
                  lat: stop.lat,
                  lng: stop.lng,
                }))}
                options={{
                  geodesic: true,
                  strokeColor: `#${this.props.route.bgColor}`,
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                }}
              />
            )}
          {this.state.map &&
            this.state.events.map((event, i) => (
              // eslint-disable-next-line no-underscore-dangle
              <Fragment key={event._id}>
                <Marker
                  // eslint-disable-next-line no-underscore-dangle
                  key={event._id}
                  position={{ lat: event.latitude, lng: event.longitude }}
                  onClick={(e) => this.onToggleOpen(e, i)}
                  icon={markerIcon(event)}
                >
                  {this.state.isOpen[i] && (
                    <InfoWindow
                      onCloseClick={(e) => this.onToggleOpen(e, i)}
                      options={{
                        alignTop: true,
                        closeBoxURL: '',
                        enableEventPropagation: true,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: 'white',
                          opacity: 0.75,
                          width: '180px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '14px',
                            fontColor: '#08233B',
                            padding: '10px 5px',
                          }}
                        >
                          {false && event.task_location_name && (
                            <Fragment>
                              <span style={{ fontWeight: 'bold' }}>
                                {event.task_location_name}
                              </span>
                              <br />
                            </Fragment>
                          )}

                          <span style={{ fontSize: 10 }} className="textBold">
                            {event.tr_datetime
                              ? momentTimeWithShortName(eventTimeFormat , event.tr_datetime, this.props.timeZoneId, this.props.tzShortName)
                              : ''}
                          </span>
                        </div>
                        <DisplayAddress event={event} job={this.state.job} />
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              </Fragment>
            ))}
          {/* </MarkerClusterer> */}
          {this.state.tracks.length > 0 && (
            <Polyline
              path={this.state.tracks}
              // options={{
              //   strokeOpacity: 0,
              //   icons: [{
              //     icon: lineSymbol,
              //     offset: '0',
              //     repeat: '20px',
              //   }],
              // }}
              options={{
                geodesic: true,
                strokeColor: '#004a7d',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }}
            />
          )}
        </Fragment>
      </MainMap>
    )
  }
}

Map.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  route: PropTypes.shape(),
  hasSteps: PropTypes.bool,
}
Map.defaultProps = {
  route: {},
  hasSteps: false,
}

export default Map
