/* eslint-disable no-underscore-dangle */
import React, { Component, Fragment } from "react";
import _ from "lodash";
import {
  Button,
  Col,
  Row,
  Spin,
  Select,
  Radio,
  Tabs,
  Icon,
  Input,
} from "../../common/UIComponents";
import { alertMessage, formatCode,  isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import userStore from "../../stores/UserStore";
import {
  setBillingConfig,
  fetchAccountBillingConfigs,
  fetchAccount,
} from "../../api/Account";
import AppConfig from "../../config/AppConfig";
import InvoiceConfigForm from "./InvoiceConfigForm";
import FuelSurcharges from "../../components/configurations/FuelSurcharges";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import Discounts from "./Discounts";
import SwitchComponent from "../../common/switchComponent";
import LabelInfo from "./LabelInfo";
import { fetchUsers } from "../../api/UsersApi";
import { fetchAccountAccessorials } from "../../api/Accessorials";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import GLCodeSelect from "../common/SelectDropdowns/GLCodeSelect";

const { Option } = Select;
const { TabPane } = Tabs;

class Configurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      config: {},
      previousConfig: {},
      accessorials : [],
      account: props.account || {},
      activeKey: "1",
      isInitial: true,
      invoiceEmailAttachmentValues: {
        PDF: I18n.t("general.pdf"),
        EXCEL: I18n.t("general.excel"),
        BOTH: I18n.t("general.both"),
      },
      invoicePdfTemplate: {
        TEMPLATE_1: I18n.t("general.template_1"),
        TEMPLATE_2: I18n.t("general.template_2"),
        TEMPLATE_3: I18n.t("general.template_3"),
        TEMPLATE_4: I18n.t("general.template_4"),
      },
      drivers: [],
      // selectedDriver: "",
    };
  }

  componentDidMount() {
    this.getAccountConfigs();
    this.getDriversList();
    this.getAccountAccessorials();
    // this.initialData();
  }

  componentWillReceiveProps() {
    if (Object.keys(this.state.config).length === 0) {
      this.getAccountConfigs();
    }
  }

  getAccountAccessorials = () => {
		fetchAccountAccessorials(
			this.state.account.organization_id,
			this.state.account.id,
			"BILLING"
		).then((result) => {
			if (result.success) {
				const accessorials = result.accessorials || [];
				this.setState({
					accessorials,
					inProgress: false,
				});
			} else {
				renderAlertMessage(result.errors)
				this.setState({ inProgress: false });
			}
		});
	};

  initialData = () => {
    const { config, previousConfig } = this.state;
    if (isEmpty(config[I18n.t("configurations.start_of_week_key")])) {
      const dafultValue = I18n.t("configurations.start_of_week_fefault_value");
      config[I18n.t("configurations.start_of_week_key")] = dafultValue;
      previousConfig[I18n.t("configurations.start_of_week_key")] = dafultValue;
    }
    if (isEmpty(config.percentage_charge_based_on)) {
      config.percentage_charge_based_on = "TRANS";
      previousConfig.percentage_charge_based_on = "TRANS";
    }
    if (isEmpty(config.driver_charge_type)) {
      config.driver_charge_type = "flat";
      previousConfig.driver_charge_type = "flat";
    }
    if (isEmpty(config.considerable_billing_weight)) {
      config.considerable_billing_weight = "ORDER_WEIGHT";
      previousConfig.considerable_billing_weight = "ORDER_WEIGHT";
    }
    if (isEmpty(config.second_price_type)) {
      config.second_price_type = "ORDER";
      previousConfig.second_price_type = "ORDER";
    }
    if (isEmpty(config.order_grouping_type)) {
      config.order_grouping_type = "INDV";
      previousConfig.order_grouping_type = "INDV";
      if (isEmpty(config[I18n.t("configurations.cap_value_key")])) {
        config[I18n.t("configurations.cap_value_key")] = 0.0;
        previousConfig[I18n.t("configurations.cap_value_key")] = 0.0;
      }
    }
    if (isEmpty(config.driver_charge_value)) {
      config.driver_charge_value = 0;
      previousConfig.driver_charge_value = 0;
    }
    if (isEmpty(config[I18n.t("configurations.enable_expedite_key")])) {
      config[I18n.t("configurations.enable_expedite_key")] = "true";
      config[I18n.t("configurations.considerable_time_window_key")] = "120";
    }
    if (
      isEmpty(config[I18n.t("configurations.enable_expedite_key")]) ||
      config[I18n.t("configurations.enable_expedite_key")] === "false"
    ) {
      config[I18n.t("configurations.considerable_time_window_key")] = "0";
    }

    if (isEmpty(config.invoice_email_frequency)) {
      config.invoice_email_frequency = "DAILY";
      previousConfig.invoice_email_frequency = "DAILY";
    }
    if(isEmpty(config.driver_eligible_on)){
      config.driver_eligible_on = "DRIVER";
      previousConfig.driver_eligible_on = "DRIVER";
    }
    // if (
    //   config[I18n.t("configurations.enable_expedite_key")] === "true" &&
    //   isEmpty(config[I18n.t("configurations.considerable_time_window_key")])
    // ) {
    //   config[I18n.t("configurations.considerable_time_window_key")] = 2;
    // }
    this.setState({ config, previousConfig, isInitial: false });
  };

  getDriversList(initial, cb) {
    this.setState({ inProgress: true });
    fetchUsers("driver").then((result) => {
      if (result.success) {
        this.setState(
          {
            drivers: result.users.map((driver) => ({
              id: driver.id,
              employee_code: driver.employee_code,
            })),
            // selectedDriver: result.users[0].id, for making first driver to appear in dropdown
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  }

  // handleDriverChange = (value) => {
  //   this.setState({ selectedDriver: value });
  // };

  getAccountDetails = () => {
    this.setState({ inProgress: true });
    const { accountId } = this.state;
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        this.setState({
          account: Object.assign({}, this.state.account, result.account),
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getAccountConfigs = () => {
    this.setState({ inProgress: true });
    const { config, previousConfig } = this.state;
    const {updateBillingConfigs} = this.props;
    const orgId = userStore.getStateValue("selectedOrg");
    fetchAccountBillingConfigs(orgId, this.state.account.id).then((result) => {
      if (result.success) {
        const accountConfigurations = result.configurations || [];
        accountConfigurations
          .filter((settingObj) => settingObj.config_type === "BILLING")
          .forEach((setting) => {
            config[setting.setting_name] = setting.setting_value;
            previousConfig[setting.setting_name] = setting.setting_value;
          });
        if (isEmpty(config.invoice_email_attachment)) {
          config.invoice_email_attachment = "PDF";
          previousConfig.invoice_email_attachment = "PDF";
          config.invoice_pdf_template = "TEMPLATE_1";
          previousConfig.invoice_pdf_template = "TEMPLATE_1";
        }
        if (isEmpty(config.invoice_pdf_template)) {
          config.invoice_pdf_template = "TEMPLATE_1";
          previousConfig.invoice_pdf_template = "TEMPLATE_1";
        }
        if (!isEmpty(config.max_amount_per_invoice)) {
          config.invoice_limit_by = "by_amount";
        }else {
          config.invoice_limit_by = "by_order";
        }
        this.setState(
          {
            inProgress: false,
            config,
            previousConfig,
          },
          () => {
            if (this.state.isInitial) {
              this.initialData();
            }
            updateBillingConfigs(config);
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    const data = {
      account_billing_config: this.state.config,
      organization_id: orgId,
      account_id: this.state.account.id,
    };
    if (
      !isEmpty(
        data.account_billing_config[I18n.t("configurations.transport_gl_code")]
      )
    ) {
      const convertedValue = data.account_billing_config[
        I18n.t("configurations.transport_gl_code")
      ].replace(" ", "");
      data.account_billing_config[I18n.t("configurations.transport_gl_code")] =
        convertedValue.toUpperCase();
    }

    if (
      !isEmpty(
        data.account_billing_config[
          I18n.t("configurations.fuel_surcharge_gl_code")
        ]
      )
    ) {
      const convertedValue = formatCode(
        data.account_billing_config[
          I18n.t("configurations.fuel_surcharge_gl_code")
        ]
      );
      data.account_billing_config[
        I18n.t("configurations.fuel_surcharge_gl_code")
      ] = convertedValue.toUpperCase();
    }

    const { default_pickup_driver, pickup_driver_amount } = data.account_billing_config;

    if(this.state.config["account_due_date"] === "true" && isEmpty(this.state.config["due_date"])){
      alertMessage(`${I18n.t('errors.field_required', {field: I18n.t("configurations.due_date")})}`, 'error', 5);
      return;
    }

    if(this.state.config["take_vendor_no"] === "false"){
      data.account_billing_config["vendor_no"] = "";
    }
    if (
      this.state.config["take_vendor_no"] === "true" &&
      isEmpty(this.state.config["vendor_no"])
    ) {
      return alertMessage(`Vendor No is Empty`, "error", 5);
    }

    if (this.state.config["consider_pickup_driver"] === "true") {
      if (isEmpty(default_pickup_driver) || isEmpty(pickup_driver_amount)) {
        const emptyFields = [];
        if (isEmpty(default_pickup_driver)) {
          emptyFields.push("Pickup Driver");
        }
        if (isEmpty(pickup_driver_amount)) {
          emptyFields.push("Pickup Driver Amount");
        }
        const errorMessage = `The following fields are required: ${emptyFields.join(
          ", "
        )}`;
        alertMessage(errorMessage, "error", 10);
        this.setState({ inProgress: false });
        return; // Stop Api Call If No Values Are Present
      }
    }

    if(data.account_billing_config.invoice_limit_by){
      delete data.account_billing_config.invoice_limit_by;
    }

    this.setState({
      inProgress: true,
    });

    setBillingConfig(data, this.state.account.id).then((result) => {
      if (result.success) {
        this.setState({
          inProgress: false,
        });
        this.getAccountConfigs();
        this.handleSaveSuccess(result.acct_config.message);
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleCancel = (element) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: this.state.previousConfig[element],
      }),
    });
  };

  handleCheckBtn = (element, value) => {
    const { config } = this.state;
    if (
      element === "show_survey_on_driver" &&
      value === "true" &&
      this.state.config.feedback === ""
    ) {
      return alertMessage(
        `Please select feedback on ${I18n.t("customer.label")} notifications`,
        "error",
        10
      );
    }

    if (element === I18n.t("configurations.enable_expedite_key")) {
      if (value === "false") {
        config[
          I18n.t("configurations.considerable_time_window_key")
        ] = "0";
        config[
          I18n.t("configurations.considerable_expedite_sharp_time_key")
        ] = "";
      } else {
        config[
          I18n.t("configurations.considerable_time_window_key")
        ] = "2";
      }
    }
    config[element] = value;
    if (element === "customer_send_feedback" && value === "false") {
      config['feedback'] = '';
    }
    if(element === 'account_due_date'){
      config['due_date'] = 30;
    }
    if(element === "invoice_email_attachment"){
      if(value === "EXCEL"){
        config['invoice_pdf_template'] = "";
      } else {
        config['invoice_pdf_template'] = "TEMPLATE_1"; 
      }
    }
    this.setState({
      config,
    })
  };

  handleOnInputChange = (element, value) => {
    const config = Object.assign({}, this.state.config, {
      [element]: value,
    });
    if (
      element === I18n.t("configurations.order_grouping_type_key") &&
      value !== "WEEK"
    ) {
      const dafultValue = I18n.t("configurations.start_of_week_fefault_value");
      config[I18n.t("configurations.start_of_week_key")] = dafultValue;
    }
    this.setState({
      config,
    });
  };

  updateConfig = (invoiceLimitBy) => {
  const config = { ...this.state.config };
      config["invoice_limit_by"] = invoiceLimitBy;
    if (invoiceLimitBy === "by_order") {
      config[I18n.t("configurations.order_choice_key")] = config[I18n.t("configurations.invoice_amount_key")];
      config[I18n.t("configurations.invoice_amount_key")] = null;
    } else if (invoiceLimitBy === "by_amount") {
      config[I18n.t("configurations.invoice_amount_key")] = config[I18n.t("configurations.order_choice_key")];
      config[I18n.t("configurations.order_choice_key")] = null;
    }
  this.setState({
    config,
  });
}


  handleOnSelect = (element, value) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: value,
      }),
    });
  };

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => this.handleSave()}
      style={{ zIndex: 1 }}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  goToTab = (step) => {
    this.setState({
      activeKey: step,
    });
  };

  // _renderFuelSurchargeConfig = () => (
  //   <div>
  //     <div className="settingHeader">
  //       <span className="textBold">
  //         {I18n.t("fuelSurcharges.fuelSurcharge")}
  //       </span>
  //     </div>
  //     <FuelSurcharges account={this.state.account} />
  //   </div>
  // );

  _renderModuleTabs = () => (
    <Row style={{ margin: "10px 10px" }}>
      <Row md={24}>
        <div className="settingHeader">
          <span className="textBold">Billing Configuration</span>
        </div>
      </Row>
      <Row>
        <Col xs={11}>
          {/*<Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.break_weight_label")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.break_weight_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn(
                        I18n.t("configurations.break_weight"),
                        value
                      )
                    }
                    isChecked={
                      this.state.config[
                        I18n.t("configurations.break_weight")
                      ] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
            </Col>
           </Row>*/}
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.consider_pickup_driverpay")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn(
                      "consider_pickup_driver",
                        value
                      )
                    }
                    isChecked={
                      this.state.config['consider_pickup_driver'
                      ] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
              {this.state.config["consider_pickup_driver"
              ] === "true" && (
                <Fragment>
                  <Row className="configLableRow">
                    <Col xs={23} sm={23} md={23} lg={11}>
                      {I18n.t("configurations.consider_pickup_driver")}
                      <sup className="textRed">*</sup>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Select
                        value={this.state.config.default_pickup_driver ? this.state.config.default_pickup_driver : ''}
                        showSearch
                        className="width100Per"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // onChange={this.handleDriverChange}
                        onChange={(e) =>
                          this.handleOnInputChange("default_pickup_driver", e)
                        }
                        style={{ width: "60%", marginRight: "10px" }}
                      >
                         <Select.Option key={'driver'} value={''}>
                            --select--
                          </Select.Option>
                          {this.state.drivers.map((driverDetail) => (
                            <Select.Option
                              key={driverDetail.id}
                              value={driverDetail.id}
                            >
                              {driverDetail.employee_code}
                            </Select.Option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={23} sm={23} md={23} lg={11}>
                      {I18n.t("configurations.consider_pickup_pay")}
                      <sup className="textRed">*</sup>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Input
                        type="number"
                        addonBefore={<Icon type="dollar" />}
                        value={this.state.config.pickup_driver_amount}
                        onChange={(e) =>
                          this.handleOnInputChange(
                           "pickup_driver_amount",
                            e.target.value
                          )
                        }
                        style={{ width: "60%" }}
                        className="marginRight10"
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}

              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("fuelSurcharges.fuelSurcharge")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.fuelSurcharge_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn("apply_fuel_surcharge", value)
                    }
                    isChecked={
                      this.state.config["apply_fuel_surcharge"] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>

              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.skip_multiday_pickup_rate")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.skip_multiday_pickup_rate_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn("skip_multiday_pickup_rate", value)
                    }
                    isChecked={
                      this.state.config["skip_multiday_pickup_rate"] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.account_due_date")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.account_due_date_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn("account_due_date", value)
                    }
                    isChecked={
                      this.state.config["account_due_date"] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
              {this.state.config["account_due_date"
              ] === "true" && (
                <Fragment>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={23} sm={23} md={23} lg={11}>
                      {I18n.t("configurations.due_date")}
                      <sup className="textRed">*</sup>
                      <LabelInfo
                        title={I18n.t(
                          "configurations.definitions.due_date_desc"
                        )}
                      />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                      :
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <Input
                        type="number"
                        // addonBefore={<Icon type="dollar" />}
                        value={this.state.config.due_date}
                        onChange={(e) =>
                          this.handleOnInputChange(
                           "due_date",
                            e.target.value
                          )
                        }
                        style={{ width: "60%" }}
                        className="marginRight10"
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.group_orders_for_billing_label")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.group_orders_for_billing_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn(
                        I18n.t("configurations.group_orders_for_billing"),
                        value
                      )
                    }
                    isChecked={
                      this.state.config[
                        I18n.t("configurations.group_orders_for_billing")
                      ] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.transport_gl_code_label")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.transport_gl_code_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  {/* <Input
                    value={this.state.config.transport_gl_code}
                    style={{ width: "60%", marginRight: "10px" }}
                    className="textUpperCase"
                    onChange={(e) =>
                      this.handleOnInputChange(
                        I18n.t("configurations.transport_gl_code"),
                        e.target.value
                      )
                    }
                  /> */}
                <GLCodeSelect
                  data={this.props.glCodesList || []}
                  
                  value={this.state.config.transport_gl_code}
                  onChange={(e) => {
                    this.handleOnInputChange(
                      I18n.t("configurations.transport_gl_code"),
                      e
                    )
                  }}
                  selectKey={"gl_code"}
                  selectValue={"gl_code"}
                  selectedValue={"gl_code"}
                  style={{ width: "60%", marginRight: "10px" }}
                  showSearch={true}
                  dropdownMatchSelectWidth={false}
                  selectedCode={"description"}
                />

                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={11}>
                  {I18n.t("configurations.fuel_gl_code_label")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.fuel_gl_code_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  {/* <Input
                    value={this.state.config.fuel_surcharge_gl_code}
                    style={{ width: "60%", marginRight: "10px" }}
                    className="textUpperCase"
                    onChange={(e) =>
                      this.handleOnInputChange(
                        I18n.t("configurations.fuel_surcharge_gl_code"),
                        e.target.value
                      )
                    }
                  /> */}
                  <GLCodeSelect
                  data={this.props.glCodesList || []}
                  selectedCode={"description"}
                  value={this.state.config.fuel_surcharge_gl_code}
                  onChange={(e) => {
                    this.handleOnInputChange(
                      I18n.t("configurations.fuel_surcharge_gl_code"),
                      e
                    )
                  }}
                  selectKey={"gl_code"}
                  selectValue={"gl_code"}
                  selectedValue={"gl_code"}
                  style={{ width: "60%", marginRight: "10px" }}
                  showSearch={true}
                />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={13}>
          {/* <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.percentage_charge_based_on")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnInputChange(
                        "percentage_charge_based_on",
                        e.target.value
                      )
                    }
                    value={this.state.config.percentage_charge_based_on}
                  >
                    <Radio value={"TRANS"}>Transportation</Radio>
                    <Radio value={"TRANS_ACCSS"}>
                      Transportation & Accessorial
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
          </Row> */}

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.considerable_billing_weight")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.considerable_billing_weight_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnInputChange(
                        "considerable_billing_weight",
                        e.target.value
                      )
                    }
                    value={this.state.config.considerable_billing_weight}
                  >
                    <Radio value="ORDER_WEIGHT">Order Weight</Radio>
                    <Radio value="DIM_WEIGHT">DIM Weight</Radio>
                    <Radio value="MAX_WEIGHT">Max of the both</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("discounts.second_price_type")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.second_price_type_desc"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnInputChange(
                        "second_price_type",
                        e.target.value
                      )
                    }
                    value={this.state.config["second_price_type"]}
                  >
                    <Radio value={"ORDER"}>Order</Radio>
                    <Radio value={"ITEM"}>Item</Radio>
                  </Radio.Group>
                  {/* <Select
                  value={this.state.config.second_price_type}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) =>
                    this.handleOnInputChange("second_price_type", e)
                  }
                >
                  {Object.keys(AppConfig.contentType).map((key) => (
                    <Select.Option key={key} value={AppConfig.contentType[key]}>
                      {key}
                    </Select.Option>
                  ))}
                </Select> */}
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>

                  {I18n.t("configurations.invoice_email_attachment")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.invoice_email_attachment"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Radio.Group
                    onChange={(e) =>
                      {
                        this.handleCheckBtn(
                          "invoice_email_attachment",
                          e.target.value
                        )
                    }}
                    value={this.state.config["invoice_email_attachment"]}
                  >
                    {Object.keys(this.state.invoiceEmailAttachmentValues).map(
                      (key) => (
                        <Radio value={key} key={key}>
                          {this.state.invoiceEmailAttachmentValues[key]}
                        </Radio>
                      )
                    )}
                  </Radio.Group>
                </Col>
              </Row>
            </Col>

            {(["PDF","BOTH"].includes(this.state.config['invoice_email_attachment'])) && (
              <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.invoice_pdf_template")}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.invoice_pdf_template"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                <Select
                        value={this.state.config["invoice_pdf_template"]}
                        showSearch
                        className="width100Per"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // onChange={this.handleDriverChange}
                        onChange={(e) =>
                          this.handleCheckBtn("invoice_pdf_template", e)
                        }
                        style={{ width: "60%", marginRight: "10px" }}
                      >
                          {Object.keys(this.state.invoicePdfTemplate).map((key) => (
                            <Select.Option
                              key={key}
                              value={key}
                            >
                              {this.state.invoicePdfTemplate[key]}
                            </Select.Option>
                          ))}
                      </Select>
                </Col>
              </Row>
              {/* <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t('configurations.lh_billing_accessorial')}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.lh_billing_accessorial"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                <Select
                        value={this.state.config["linehaul_billing_accessorial"]}
                        showSearch
                        className="width100Per"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) =>
                          this.handleCheckBtn("linehaul_billing_accessorial", e)
                        }
                        style={{ width: "60%", marginRight: "10px" }}
                      >
                          {this.state.accessorials.map(accessorial => (
                            <Select.Option key={accessorial.id} value={accessorial.id}>
                              {accessorial.accessorial_name}
                            </Select.Option>
                          ))}
                      </Select>
                </Col>
              </Row> */}
              <Row className="configLableRow">
              <Col xs={23} sm={23} md={23} lg={9}>
              {I18n.t('configurations.driver_eligible_on')}
                  <LabelInfo
                    title={I18n.t(
                      "configurations.definitions.driver_eligible_on"
                    )}
                  />
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                <Radio.Group
                    onChange={(e) =>
                      this.handleOnInputChange(
                        "driver_eligible_on",
                        e.target.value
                      )
                    }
                    value={this.state.config["driver_eligible_on"]}
                  >
                    <Radio value={"DRIVER"}>Driver Level</Radio>
                    <Radio value={"ACCOUNT"}>Account Level</Radio>
                  </Radio.Group>
                  </Col>
              </Row>
            </Col>
            )}


          </Row>
          {/* <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.enable_expedite")}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>

                  <SwitchComponent
                    handleChange={(value) =>
                      this.handleCheckBtn(
                        I18n.t("configurations.enable_expedite_key"),
                        value
                      )
                    }
                    isChecked={
                      this.state.config[
                        I18n.t("configurations.enable_expedite_key")
                      ] === "true"
                    }
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.considerable_time_window")}
                  <span className="subTextLable">
                    {I18n.t("configurations.min_subtext_label")}
                  </span>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Input
                    type="number"
                    value={
                      this.state.config[
                        I18n.t("configurations.considerable_time_window_key")
                      ]
                    }
                    style={{ width: "40%", marginRight: "10px" }}
                    onChange={(e) =>
                      this.handleOnInputChange(
                        I18n.t("configurations.considerable_time_window_key"),
                        e.target.value
                      )
                    }
                    disabled={
                      isEmpty(
                        this.state.config[
                          I18n.t("configurations.enable_expedite_key")
                        ]
                      ) ||
                      this.state.config[
                        I18n.t("configurations.enable_expedite_key")
                      ] === "false"
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          {/* <Select
                    onChange={(val) =>
                      this.handleOnInputChange(
                        I18n.t("configurations.considerable_time_window_key"),
                        val
                      )
                    }
                    value={
                      this.state.config[
                        I18n.t("configurations.considerable_time_window_key")
                      ]
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "40%"}}
                    disabled={
                      isEmpty(
                        this.state.config[
                          I18n.t("configurations.enable_expedite_key")
                        ]
                      ) ||
                      this.state.config[
                        I18n.t("configurations.enable_expedite_key")
                      ] === "false"
                    }
                  >
                    {Object.keys(AppConfig.timeWindow).map((province) => (
                      <Option key={province} value={province}>
                        {AppConfig.timeWindow[province]}
                      </Option>
                    ))}
                  </Select> */}

          {/* <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={23} lg={9}>
                  {I18n.t("configurations.considerable_expedite_sharp_time_label")}
                    <span className="subTextLable">
                      {I18n.t("configurations.min_subtext_label")}
                    </span>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={24} lg={14}>
                  <Input
                    type="number"
                    value={this.state.config[I18n.t("configurations.considerable_expedite_sharp_time_key")]}
                    style={{ width: "40%", marginRight: "10px" }}
                    onChange={(e) =>
                      this.handleOnInputChange(
                        I18n.t("configurations.considerable_expedite_sharp_time_key"),
                        e.target.value
                      )
                    }
                    disabled={
                      isEmpty(
                        this.state.config[
                          I18n.t("configurations.enable_expedite_key")
                        ]
                      ) ||
                      this.state.config[
                        I18n.t("configurations.enable_expedite_key")
                      ] === "false"
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row> */}
          {/*
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.driver_pay_charge_type")}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnInputChange(
                          "driver_charge_type",
                          e.target.value
                        )
                      }
                      value={this.state.config.driver_charge_type}
                    >
                      <Radio value={"flat"}>Flat</Radio>
                      <Radio value={"percentage"}>Percentage</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={23} lg={9}>
                    {I18n.t("configurations.driver_pay_charge_value")}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14}>
                    <Input
                      addonBefore={
                        this.state.config.driver_charge_type === "flat" ? (
                          <Icon type="dollar" />
                        ) : null
                      }
                      addonAfter={
                        this.state.config.driver_charge_type === "percentage" ? (
                          <Icon type="percentage" />
                        ) : null
                      }
                      type="number"
                      value={parseFloat(this.state.config.driver_charge_value)}
                      onChange={(e) =>
                        this.handleOnInputChange(
                          "driver_charge_value",
                          e.target.value
                        )
                      }
                      min={0}
                      precision={2}
                      defaultValue={0}
                      style={{ width: "60%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          */}
          {/*
      <Row>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Row className="configLableRow">
          <Col xs={23} sm={23} md={23} lg={11}>
            {I18n.t('configurations.with_min_charge_lable')}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}>:</Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Button
              className={
                this.state.config.with_min_charge && this.state.config.with_min_charge === I18n.t('common.true') ? 'btnConfig btnConfigActive' : 'btnConfig'
              }
              onClick={() => this.handleCheckBtn(I18n.t('configurations.with_min_charge'), I18n.t('common.true'))}
            >Yes
            </Button>
            <Button
              className={
                this.state.config.with_min_charge && this.state.config.with_min_charge === I18n.t('common.false') ? 'btnConfig btnConfigActive' : 'btnConfig'
              }
              onClick={() => this.handleCheckBtn(I18n.t('configurations.with_min_charge'), I18n.t('common.false'))}
            >No
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} />
    </Row>
            */}
          <Row className="configLableRow">
            <Col xs={22} sm={22} md={22} lg={9}>
              {I18n.t("configurations.display_move_order_billing_address")}
              <LabelInfo
                title={I18n.t(
                  "configurations.definitions.display_move_order_billing_address"
                )}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Radio.Group
                onChange={(e) =>
                  this.handleOnInputChange(
                    "display_move_order_billing_address",
                    e.target.value
                  )
                }
                value={this.state.config.display_move_order_billing_address ?? "WHTOCONS"}
              >
                <Radio value={"WHTOCONS"}>Warehouse to Consignee</Radio>
                <Radio value={"CONSTOCONS"}>
                  Consignee to Consignee
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row className="configLableRow">
            <Col xs={22} sm={22} md={22} lg={9}>
            {I18n.t("configurations.apply_nyc")}
            <LabelInfo
                title={I18n.t(
                  "configurations.definitions.apply_nyc"
                )}              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <SwitchComponent
                handleChange={(value) =>
                  this.handleCheckBtn("enable_nyc_congestion", value)
                }
                isChecked={
                  this.state.config["enable_nyc_congestion"] === "true"
                }
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checkedValue={"true"}
                unCheckedValue={"false"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Row className="configLableRow">
            <Col md={24}>
              <InvoiceConfigForm
                config={this.state.config}
                updateConfig={this.updateConfig}
                onChange={this.handleOnInputChange}
                handleCheckBtn={this.handleCheckBtn}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <Col md={24}>
          <Row md={24} className="marginTop10">
            <div className="settingHeader">
              <span className="textBold">Discount Configuration</span>
            </div>
          </Row>
          <Row className="configLableRow">
            <Col md={24}>
              {checkServiceExistance("GSIP") && (
                <Discounts account={this.state.account} />
              )}
            </Col>
          </Row>
        </Col>
      </Row> */}
    </Row>
  );

  render() {
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <this._renderModuleTabs />
          {/* {checkServiceExistance("FSCMI") && (
              <this._renderFuelSurchargeConfig />
            )} */}

          {this._renderFloatingBtn()}
        </Spin>
      </div>
    );
  }
}

export default Configurations;
