import { Col, Icon, Popconfirm, Row, Tooltip, Input } from "antd";
import React from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import { checkNegitive } from "../../../common/Common";

class ParticularDriverDeduction extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "Deduction name",
        title: <b>{I18n.t("drivers_weekly_report.deduction_name")}</b>,
        dataIndex: "deduction_name",
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        width: 200,
      },
      {
        key: "gl_code",
        title: <b>{I18n.t("drivers_weekly_report.gl_code")}</b>,
        dataIndex: "gl_code",
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        width: 150,
      },
      {
        key: "amount",
        title: <b>{I18n.t("general.amount")}($)</b>,
        dataIndex: "amount",
        //render: (data, record) => data ? checkNegitive(data) : '',
        render: (data, record, index) => 
        // <Input
        //     // onChange={(e) =>
        //     //   props.handleOnChange(index, "amount", e.target.value)
        //     // }
        //     value={data}
        //     style={{ width: 100, fontSize: 12 }}
        //     size="small"
        //     type="number"
        //     prefix={<Icon type="dollar" />}
        //     min={0}
        //     // onBlur={(e) => 
        //     //   props.handleOnChange(
        //     //     index,
        //     //     "amount",
        //     //     record.amount ? parseFloat(record.amount).toFixed(2) : 0.00
        //     //   )
        //     // }
        //   />,
        <div>  
        {checkNegitive(data)}
        </div>,
        width: 150,
        align: 'right' 
      },
      // {
      //   key: "Summary",
      //   title: <b>{I18n.t("drivers_weekly_report.summary")}</b>,
      //   dataIndex: "summary",
      //   render: (data, record) => <span className="textUpperCase">{data}</span>,
      //   width: 100,
      // },
    ];
  }
}
export default React.memo(ParticularDriverDeduction);
