import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Popconfirm,
  FormItem,
  Select,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import BaseModal from "../BaseModal";
import TemplatesList from "../configurations/templates/List";
import TemplateMsgList from "./templates/TemplateMsgList";
import EventMsgData from "./templates/EventMsgData";
import Form from "../configurations/templates/Form";
import {isEmpty, alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import { NotificationTemplatesApi } from "../../api/NotificationTemplate";
import userStore from "../../stores/UserStore";
import { TemplateParamsApi } from "../../api/TemplateParamsApi";
import { TemplateEventsApi } from "../../api/TemplateEventsApi";
import { renderAlertMessage } from "../../helpers/common";

class NotificationMsgTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFormModal: false,
      templates: [],
      tokens: [],
      totalTokens: [],
      events: [],
      errors: [],
      account: this.props.account || {},
      pagination: {},
      inProgress: false,
      currentTemplate: {},
      refMode: this.props.refMode || "org",
      usingOrgLevel: false,
      event_code: "",
      currentEvent: {},
      expandedKeys: [],
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: "",
    };
  }

  componentDidMount() {
    this.getNotificationEvents();
    this.getNotificationParams();
    this.getNotificationTemplates();
  }

  getNotificationEvents = () => {
    this.setState({ inProgress: true });
    TemplateEventsApi.fetchEvents().then((result) => {
      if (result.success) {
        this.setState({
          events: result.data || [],
          inProgress: false,
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false, templates: [], pagination: {} });
      }
    });
  };

  getNotificationParams = () => {
    this.setState({ inProgress: true });
    TemplateParamsApi.fetch().then((result) => {
      if (result.success) {
        const totalTokens = result.params || [];
        this.setState({
          totalTokens,
          inProgress: false,
          tokens: totalTokens,
        });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false, templates: [], pagination: {} });
      }
    });
  };

  getNotificationTemplates = (
    checkForTemplates = true,
    initial,
    cb = null
  ) => {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    const render_type = "list";
    const organization_id = userStore.getStateValue("selectedOrg");
    const account = this.state?.account ? this.state.account : {};
    const eventCode = this.state.event_code;
    NotificationTemplatesApi.fetch(
      organization_id,
      account.id,
      render_type,
      page,
      perPage,
      eventCode
    ).then((result) => {
      if (result.success) {
        const templates = result.templates || [];
        const newState = {
          templates,
          inProgress: false,
          // pagination: result.pagination || {},
        };
        if (checkForTemplates) {
          newState.usingOrgLevel = templates.filter(
                (rec) =>
                  !isEmpty(rec.account_id) && rec.account_id === account.id
              ).length === 0;
        }
        this.setState({ ...newState });
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false, templates: [], pagination: {} });
      }
    });
  };

  appendToken = (id, element, value) => {
    this.handleOnChange(id, element, value);
  };

  handleOnEventChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      this.tableOptions.pagination.current = 1;
      this.getNotificationTemplates();
    });
  };

  handleOnChange = (id, element, value) => {
    const templates = this.state.templates || [];
    const index = _.findIndex(this.state.templates, ["id", id]);
    const { totalTokens } = this.state;
    let tokens = [...this.state.tokens];
    if (index >= 0) {
      const record = Object.assign({}, this.state.templates[index]);
      if (element === "token") {
        record["message_content"] = `${record["message_content"]} @${value} `;
      } else {
        if (element === "event_code") {
          const rec = _.find(this.state.events, { event_code: value });
          if (!isEmpty(rec)) {
            const currentTokens = rec.codes || [];
            tokens =
              currentTokens.length > 0
                ? totalTokens.filter((rec) => currentTokens.includes(rec.code))
                : [...totalTokens];
          }
        }
        record[element] = value;
      }
      templates[index] = record;
      this.setState({
        templates,
        tokens,
      });
    }
  };

  handleDeleteClick = (record) => {
    this.setState({ inProgress: true });

    const account = this.state?.account ? this.state.account : {};
    const data = { event_code: record.event_code };
    if (!isEmpty(account.id)) {
      data.account_id = account.id;
    }
    data.event_params = [];
    const errors = [];
    if (errors.length === 0) {
      NotificationTemplatesApi.manage(true, data).then((result) => {
        if (result.success) {
          const msg = result.message ? result.message : I18n.t("messages.deleted")
          alertMessage(msg, "success", 10);
          this.setState({ inProgress: false, errors: [] });
          this.handleSuccess();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, errors: [] });
        }
      });
    } else {
      renderAlertMessage(result.errors)
    }
  };

  renderFormModal = () => {
    let title;
    let isNew;
    if (this.state.currentTemplate.id) {
      title = I18n.t("templates.update");
      isNew = false;
    } else {
      title = I18n.t("templates.add");
      isNew = true;
    }
    const currentMessages = [...this.state.currentMessages];
    const currentEvent = { ...this.state.currentEvent };
    return (
      <BaseModal
        title={`Message Template for ${this.state.currentEvent.event_name}`}
        onCancel={() => this.closeFormModal()}
        width="100%"
        style={{
          top: 0,
          height: "100%",
        }}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "scroll",
          padding: 10
        }}
      >
        <Form
          events={this.state.events}
          totalTokens={this.state.totalTokens ? this.state.totalTokens : []}
          handleSuccess={(message) => this.handleSuccess(message)}
          onCancel={() => this.closeFormModal()}
          isNew={isNew}
          currentTemplate={this.state.currentTemplate}
          account={this.state.account}
          deleteClick={this.deleteClick}
          currentEvent={currentEvent}
          currentMessages={currentMessages}
        />
      </BaseModal>
    );
  };

  handleSuccess = (message) => {
    // alertMessage(message);
    this.setState({ currentTemplate: {}, currentEvent: {}, currentMessages: {}, showFormModal: false }, () => {
      this.getNotificationTemplates();
    });
  };

  handleEditClick = (record) => {
    this.setState({
      isNew: false,
      currentEvent: { ...record },
      currentMessages: this.state.templates.filter(
        (rec) => rec.event_code === record.event_code
      ),
      showFormModal: true,
    });
  };

  // handleDelete = (id) => {
  //   this.setState({ inProgress: true });
  //   NotificationTemplatesApi.delete(id).then((result) => {
  //     if (result.success) {
  //       alertMessage(result.message, "success", 10);
  //       this.getNotificationTemplates();
  //     } else {
  //       renderAlertMessage(result.errors)
  //       this.setState({ inProgress: false });
  //     }
  //   });
  // };

  updateNew = () => {
    this.getNotificationTemplates();
    this.closeFormModal();
  };

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
    };
    if (pagination.current !== currentPage) {
      this.getNotificationTemplates();
    }
  };

  closeFormModal = () => {
    this.setState({ showFormModal: false, currentTemplate: {}, currentEvent: {}, currentMessages:{} });
  };

  handleAddClick = (record) => {
    this.setState({
      showFormModal: true,
      currentEvent: { ...record },
      currentMessages: [],
    });
  };

  handleApplyButton = () => {
    if (this.state.usingOrgLevel) {
      this.setState({
        usingOrgLevel: false,
        templates: [],
      });
    } else {
      this.setState({ inProgress: true });
      const account = this.state?.account ? this.state.account : {};
      if (!isEmpty(account.id)) {
        NotificationTemplatesApi.delete_all(account.id).then((result) => {
          if (result.success) {
            alertMessage(I18n.t("messages.deleted"));
            this.setState(
              {
                inProgress: false,
                usingOrgLevel: false,
                templates: [],
              },
              () => {
                this.getNotificationTemplates();
              }
            );
          } else {
            renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
        });
      }
    }
  };

  onExpand = (expanded, record) => {
    const keys = this.state.expandedKeys;
    const expandedKeys = expanded
      ? keys.concat(record.id)
      : keys.filter((k) => k !== record.id);
    this.setState({ expandedKeys });
  };

  render() {
    const { pagination } = this.state;
    const isShowSave =
      this.state.refMode === "org" ||
      (this.state.refMode === "account" && this.state.usingOrgLevel === false);
    return (
      <div className="content">
        <Spin spinning={this.state.inProgress} delay={1000}>
          {this.state.refMode === "account" && (
            <Row className="configLableRow">
              <Col md={24} className="fontSize14 alignCenter">
                <span style={{ fontWeight: 500 }}>
                  {this.state.usingOrgLevel === true ? (
                    <Fragment>
                      {I18n.t(
                        "configurations.overwrite_org_values_with_field",
                        { field: "templates" }
                      )}
                      <Popconfirm
                        placement="topRight"
                        title={I18n.t("general.are_you_sure")}
                        onConfirm={() => this.handleApplyButton()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" size="small" className="fontSize14">
                          <u>Click here</u>
                        </Button>
                      </Popconfirm>
                      {I18n.t("messages.click_to_override")}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to overwrite the existing values"
                        onConfirm={() => this.handleApplyButton()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" size="small" className="fontSize14">
                          <u>Click here</u>
                        </Button>
                      </Popconfirm>
                      {I18n.t("configurations.overwrite_account_level", {
                        field: "templates",
                      })}
                    </Fragment>
                  )}
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <TemplateMsgList
                data={this.state.events}
                rowKey={(record) => record.id}
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                    },
                    className: this.state.expandedKeys.includes(record.id)
                      ? "expand-parent"
                      : "",
                  };
                }}
                onExpand={this.onExpand}
                expandedRowKeys={this.state.expandedKeys}
                expandedRowRender={(record) => {
                  const eventData = this.state.templates.filter(
                    (rec) => rec.event_code === record.event_code
                  );
                  return (
                    <EventMsgData
                      data={eventData}
                      record={record}
                      rowEventId={record.id}
                      key={`data${record.id}`}
                    />
                  );
                }}
                scroll={{ y: "calc(100vh - 320px)" }}
                tokens={this.state.tokens}
                totalTokens={this.state.totalTokens}
                events={this.state.events}
                editClick={(record) => this.handleEditClick(record)}
                deleteClick={(id) => this.handleDeleteClick(id)}
                addClick={(record) => this.handleAddClick(record)}
                pagination={{ position: "none" }}
                handleSuccess={this.handleSuccess}
                showActions={isShowSave}
                account={this.state.account}
                templates={this.state.templates}
                isShowSave={isShowSave}
              />
            </Col>
          </Row>
        </Spin>
        {this.state.showFormModal && this.renderFormModal()}
      </div>
    );
  }
}
export default NotificationMsgTemplates;
