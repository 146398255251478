import React, { Fragment } from "react";
import BaseList from "../../BaseList";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import Copyable from "../../common/Copyable";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance } from "../../../helpers/common";
import {
  Col,
  Icon,
  Link,
  Popover,
  Row,
  Tooltip,
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import moment from "moment";
import ShowInvoice from "../../billing_screen/ShowInvoice";
import _ from "lodash";
import { momentTime } from "../../../helpers/date_functions";
import AppConfig from "../../../config/AppConfig";
import EyeIcon from "../../common/EyeIcon";

class QbReportList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
    if (props) {
      this.setColumns(props);
    }
  }

  setColumns = (props) => {
    this.columns = [
      {
        key: "invoice_number",
        title: I18n.t("account.billing.invoice_no"),
        dataIndex: "frequency_invoice_number",
        width: 140,
        render: (text, record) => (
          <Row type="flex" gutter={4}>
            <Col>
              <ShowInvoice
                account={record.account_id}
                invoice_no={text}
                key={text}
                style={{ marginTop: -6 }}
                account_invoice_id={record.id}
                refreshList={() => {}}
                showActions={false}
                showInvoiceActions={false}
                approvalDate={record.approval_date}
                renderType="text"
              />
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        key: "account_frequency_type",
        title: I18n.t("account.billing.invoice_type"),
        dataIndex: "account_frequency_type",
      },
      {
        key: "wh_location_name",
        title: I18n.t("location.location"),
        dataIndex: "wh_location_name",
        render: (data) => <div>{data ? data : ""}</div>,
        className: "noWrap",
        width: 250,
      },
      {
        key: "account_name",
        title: I18n.t("account.account_name"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record?.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record?.account_id);
                    }
                  }}
                >
                  <Tooltip title={data} placement="topLeft">
                    {" "}
                    {data}{" "}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record?.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record?.account_id);
                    }
                  }}
                >
                  <Tooltip title={data} placement="topLeft">
                    {" "}
                    {data}{" "}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        key: "orders_count",
        title: I18n.t("account.billing.orders_count"),
        width: 100,
        align: "center",
        dataIndex: null,
        render: (data, record) => (
          <Fragment>
            {record?.customer_order_numbers &&
            _.isArray(record.customer_order_numbers) ? (
              <Popover
                content={
                  <div
                    style={{
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    {record.customer_order_numbers.join(", ")}
                  </div>
                }
                title={I18n.t("menu.orders")}
                overlayClassName="popoverWidth"
              >
                <u
                  className="text-decoration-dashed"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {record.customer_order_numbers.length}
                </u>
              </Popover>
            ) : (
              0
            )}
          </Fragment>
        ),
      },
      {
        key: "invoice_approval_date",
        title: I18n.t("invoices.approved_date"),
        dataIndex: "invoice_approval_date",
        width: 160,
        render: (data) => (
          <div>
            {data
              ? momentTime(AppConfig.report_date_format, data, props.timeZoneId)
              : ""}
          </div>
        ),
        sorter: (a, b) => compareDate(a.invoice_approval_date, b.invoice_approval_date),
      },
      {
        key: "generated_date",
        title: I18n.t("invoices.generated_date"),
        dataIndex: "approval_date",
        width: 165,
        render: (data) => {
          return (
            <div>
              {data
                ? momentTime(AppConfig.report_date_format, data, props.timeZoneId)
                : ""}
            </div>
          );
        },
        sorter: (a, b) => compareDate(a.approval_date, b.approval_date),
      },
      {
        key: "invoice_total_amount",
        title: I18n.t("quickbook_report.fe_amount"),
        dataIndex: "frequency_invoice_amount",
        render: (data, record) => {
          const qb_amount = record?.qb_amount
            ? checkNegitive(record.qb_amount)
            : 0.0;
          const fe_amount = record?.frequency_invoice_amount
            ? checkNegitive(record.frequency_invoice_amount)
            : 0.0;
          return (
            <div
              style={{ width: 100 }}
              className={
                !_.isEqual(qb_amount, fe_amount)
                  ? "textRed alignCenter"
                  : "alignCenter"
              }
            >
              {!isEmpty(data) ? checkNegitive(data) : ""}
            </div>
          );
        },
        width: props.viewBy === "SUMMARY" ? 100 : null,
      },
      {
        key: "qb_amount",
        title: I18n.t("quickbook_report.qb_amount"),
        dataIndex: "qb_amount",
        render: (data, record) => {
          const qb_amount = record?.qb_amount
            ? checkNegitive(record.qb_amount)
            : 0.0;
          const fe_amount = record?.frequency_invoice_amount
            ? checkNegitive(record.frequency_invoice_amount)
            : 0.0;
          return (
            <div
              style={{ width: 100 }}
              className={
                !_.isEqual(qb_amount, fe_amount)
                  ? "textRed alignCenter"
                  : "alignCenter"
              }
            >
              {!isEmpty(data) ? checkNegitive(data) : ""}
            </div>
          );
        },
        width: props.viewBy === "SUMMARY" ? 100 : null,
      },
      {
        key: "qb_remarks",
        title: "QB Remarks",
        dataIndex: "qb_remarks",
      },
      {
        key: "qb_status",
        title: I18n.t("quickbook_report.qb_status"),
        dataIndex: "qb_status",
        align: "center",
        render: (data) => (
          <div style={{ width: 100 }}>
            {!isEmpty(data)
              ? typeof data === "string"
                ? data.toLowerCase() === "true"
                  ? "Yes"
                  : "No"
                : data
                ? "Yes"
                : "No"
              : ""}
          </div>
        ),
      },
      {
        key: "processed_to_edi",
        title: "Posted EDI ?",
        dataIndex: "processed_to_edi",
        align: "center",
        render: (data) => (
          <div style={{ width: 100 }}>
            {data && !isEmpty(data) ? (
              typeof data === "string" ? (
                data.toLowerCase() === "true" ? (
                  <span className="textGreen">{"Yes"}</span>
                ) : (
                  <span className="textRed">{"No"}</span>
                )
              ) : data ? (
                <span className="textGreen">{"Yes"}</span>
              ) : (
                <span className="textRed">{"No"}</span>
              )
            ) : (
              <span className="textGreen">{"Yes"}</span>
            )}
          </div>
        ),
      },
    ];

    if (props.viewBy === "SUMMARY") {
      // If viewBy is SUMMARY, remove unnecessary columns
      this.columns = this.columns.filter(
        (column) =>
          column.key === "account_code" ||
          column.key === "account_name" ||
          column.key === "invoice_total_amount" ||
          column.key === "qb_amount" ||
          column.key === "processed_to_edi"
      );

      this.columns.splice(2, 0, {
        key: "invoice_count",
        title: "Invoice Count",
        dataIndex: "invoice_count",
        align: "center",
      });

      const actionColumn = [
        {
          key: "action",
          title: "",
          dataIndex: null,
          render: (data, record) => {
            return (
              <Tooltip title="View Details">
                <span>
                  <EyeIcon handleClick={() => this.props.viewClick(record)} />
                </span>
              </Tooltip>
            );
          },
        },
      ];

      this.columns = this.columns.concat(actionColumn);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }
}
export default QbReportList;
