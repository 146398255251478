import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Col,
  Input,
  FormItem,
  Row,
  DatePicker,
  Select,
  Button,
} from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { fetchShortFormAccounts } from "../../api/Account";
import { fetchLocations } from "../../api/LocationsApi";
import AppConfig from "../../config/AppConfig";

const { Search } = Input;
const { RangePicker } = DatePicker;

class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || {},
    };
  }

  _renderSearchOrder = () => (
    <Search
      onSearch={(value) => {
        this.handleFilterChange("search_order_token", value);
      }}
      onChange={(e) => {
        if (window.location.search.indexOf("order_number") > -1) {
          const { history } = this.props;
          history.replace("/invoices");
        }
        this.setState({
          filter: { ...this.state.filter, search_order_token: e.target.value },
        });
      }}
      value={this.state.filter.search_order_token}
      style={{ width: "100%", textTransform: "uppercase" }}
      className="textUpperCase"
      allowClear
    />
  );

  clearForm = () => {
    const filter = {
      fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
      toDate: moment(),
      view_by: "BOTH",
      accountCode: "",
      wh_location_id: "",
      viewType: "byOrder",
      search_order_token: "",
      currentStatus: "PENDING",
    };
    this.props.onChange(filter, () => {
      this.props.onSearch();
    });
  };

  handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.setState({
      filter: {
        ...this.state.filter,
        fromDate: dateFilter.fromDate,
        toDate: dateFilter.toDate,
      },
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      filter: nextProps.filter || {},
    });
  }

  handleFilterChange = (element, value, isSearch = true) => {
    const filter = {
      ...this.state.filter,
      [element]: value,
      fromDate: this.state.filter.fromDate,
      toDate: this.state.filter.toDate,
    };
    if (
      element === I18n.t("order.filters.schedule_orders.type") &&
      value === I18n.t("order.filters.schedule_orders.unscheduled_key")
    ) {
      filter.fromDate = null;
      filter.toDate = null;
    }
    this.setState({ filter });

    if (isSearch) {
      this.props.onChange(filter);
    }
  };

  render() {
    return (
      <div className="padding10">
        <Row gutter={16}>
          <Col md={24}>
            <FormItem label={I18n.t("invoices.approval_date")}>
              <RangePicker
                defaultValue={[moment().startOf("month"), moment()]}
                value={[this.state.filter.fromDate, this.state.filter.toDate]}
                ranges={AppConfig.dateRanges}
                format={AppConfig.dateFormat}
                style={{ width: "100%" }}
                onChange={(dates, dateStrings) =>
                  this.handleRangeChange(dates, dateStrings)
                }
              />
            </FormItem>
          </Col>
          <Row type="flex" justify="center" gutter={4}>
            <Col>
              <Button type="danger" onClick={this.clearForm} icon="redo">
                {I18n.t("general.reset")}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  this.props.onChange(this.state.filter, () => {
                    this.props.onSearch();
                  });
                  this.props.closeDropDown()
                }}
                type="primary"
                icon="search"
              >
                {I18n.t("general.search")}
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
}

export default withRouter(DateFilter);
