import React, { useState, Fragment, useContext, useEffect } from "react";
import LightOnIcon from "../../common/LightOnIcon";
import { UserContext } from "../../context/UserContext";
import { isEmpty } from "../../common/Common";

const QuickDispatchHint = (props) => {
  const userContext = useContext(UserContext);
  const { updateScreenConfig, currentUser } = userContext;
  const [showDescription, setShowDescription] = useState("");
  useEffect(() => {
    props.setShowDescription(showDescription);
  }, [showDescription]);

  useEffect(() => {
    if(!_.isEmpty(props.currentDriver)){
      setShowDescription(false)
      props.setShowDescription(false);
    }
  }, [props.currentDriver]);


  useEffect(() => {
    const showHint = _.get(
      currentUser,
      `screen_config.order_listing.show_quick_dispatch_hint`,
      true
    );
    setShowDescription(isEmpty(showHint) ? true : showHint)
    props.setShowDescription(showDescription);
  }, [currentUser]);

  return showDescription !== false ? (
    <div className="desc-container">
      <p>How Quick Assign Works</p>
      <p className="description">
        Select a driver to manage routes or adjust orders. Click the assign icon
        to immediately add orders to the end of a driver's route. The bar charts
        show how assigning orders will impact truck capacity based on the
        current route for the day selected. If a driver has no routes, it
        anticipates what creating a route for the current hour would look like.
      </p>
      <span
        className="dismiss-tip"
        onClick={() => {
          setShowDescription(false);
          updateScreenConfig("order_listing", false, "show_quick_dispatch_hint");
        }}
      >
        Dismiss Tip
      </span>
      <div className="icon">
        {" "}
        <LightOnIcon />{" "}
      </div>
    </div>
  ) : (
    <Fragment />
  );
};

export default QuickDispatchHint;