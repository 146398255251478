/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Radio,
  Icon,
  Select,
  Checkbox,
  Modal,
} from "../../common/UIComponents";
import { alertMessage, isEmpty, randomNumber } from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import {
  fetchCategories,
  fetchLosSkeleton,
  saveCategoryData,
  fetchAdjustmentCategoryDetails,
} from "../../api/AdjustmentsApi";
import { fetchAccountShortLos } from "../../api/Los";
// import RateMatrix from "./RateMatrix";
import { fetchAccountZones } from "../../api/BillingZones";
import { checkServiceExistance } from "../../helpers/common";
import DriverRateMatrix from "./DriverRateMatrix";

const { confirm } = Modal;

const options = {
  is_smp_aplied: {
    key: "smp",
    label: "SMP",
  },
  is_fmp_applied: {
    key: "fmp",
    label: "FMP",
  },
};
class RangeConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAdjustment: this.props.adjustment || {},
      category_configuration: this.props.category_configuration || {},
      driver_rate_params: this.props.driver_rate_params || {},
      currentDriverRate: this.props.currentDriverRate || {},
      currentRateParams: {},

      isNew: false,
      account: this.props.account || {},
      inProgress: false,
      errors: [],
      categories: [],
      zones: [],

      isInitial: true,
      isInitialCalled: false,

    };
  }

  componentDidMount() {
    // this.getCategories();
    this.getAccountZones();
    this.setInitialData();
  }

  setInitialData = () => {
    const { category_configuration, driver_rate_params } = this.state;
    const currentRateParams = this.state.currentRateParams
      ? _.cloneDeep(this.state.currentRateParams)
      : {};
    const driverRateParams =
      driver_rate_params && driver_rate_params.length > 0
        ? driver_rate_params[0]
        : {};

    Object.keys(category_configuration).forEach((opt) => {
      currentRateParams[options[opt.key]] = currentRateParams[options[opt.key]]
        ? currentRateParams[options[opt.key]]
        : driverRateParams[opt.key]
        ? driverRateParams[opt.key]
        : { [opt.key]: [] };
    });
    this.setState({
      currentRateParams,
    });
  };

  handleDriverRateFormInputChage = (element, value) => {
    let { category_configuration } = this.state;
    if (element === "category_configuration") {
      category_configuration = {
        ...category_configuration,
        ...value,
      };
      this.setState({
        category_configuration,
      });
    }
  };

  getAccountZones = (element, cb = null) => {
    this.setState({ inProgress: true });
    const { currentConfiguration } = this.state;
    fetchAccountZones(this.state.account.id).then((result) => {
      if (result.success) {
        const zones = result.zones || [];
        this.setState({
          zones: zones.map((zone) => ({
            zone_id: zone.id,
            zone_name: zone.name,
            // min_charge: zone.min_charge,
          })),
          inProgress: false,
        });
      }
    });
  };

  render() {
    const {
      category_configuration,
      driver_rate_params = [],
      currentRateParams,
      zones,
    } = this.state;
    const gridSize = 8;
    return (
      <div>
       <Row>
           {/* <Col xs={8}>
            <Checkbox
              onChange={(e) =>
                this.handleDriverRateFormInputChage("category_configuration", {
                  "apply_cwt_for_fmp": e.target.checked+"",
                })
              }
              checked={category_configuration?.apply_cwt_for_fmp  == "true"}
            >
              Apply CWT for FMP
            </Checkbox>
          </Col> */}
          {/* <Col xs={8}>
            <Checkbox
              onChange={(e) =>
                this.handleDriverRateFormInputChage("category_configuration", {
                  "is_smp_applied": e.target.checked+"",
                })
              }
              checked={category_configuration?.is_smp_applied == "true"}
            >
              Require SMP
            </Checkbox>
          </Col> */}
         
          {/* { 
          category_configuration?.is_smp_applied == "true" && <Col xs={8}>
            <Checkbox
              onChange={(e) =>
                this.handleDriverRateFormInputChage("category_configuration", {
                  apply_cwt_for_smp: e.target.checked+"",
                })
              }
              checked={category_configuration?.apply_cwt_for_smp == "true" }
            >
              Apply CWT for SMP
            </Checkbox>
          </Col>
          } */}
       </Row>
          

        <Row gutter={16} className="marginTop10">
          <Col xs={24} md={24}>
            {_.isArray(zones) && zones.length > 0 && (
              <Row gutter={8}>
                {/* {["is_smp_aplied", "is_fmp_applied"]
                  .filter((opt) => category_configuration[opt] === true)
                  .map((param) => (
                    <Col xs={24}>
                      <h2>{options[param].label}</h2>
                    </Col>
                  ))} */}
                <DriverRateMatrix
                  account={this.props.account}
                  zones={this.state.zones}
                  category_configuration={this.props.category_configuration || {}}
                  driver_rate_params={this.state.driver_rate_params || {}}
                  currentDriverRate={this.props.currentDriverRate || {}}
                  updateDriverRate={this.props.updateDriverRate}
                  handleConfigurationChange={this.props.handleConfigurationChange}
                />
              </Row>
            )}
            <Row>{FormErrors(this.state.errors)}</Row>
            {/* {checkServiceExistance("CADP") && (
              <Row>
                <Col xs={24}>
                  {FormButtons(
                    this.state.inProgress,
                    this.handleSave,
                    this.props.onCancel
                  )}
                </Col>
              </Row>
            )} */}
          </Col>
        </Row>
      </div>
    );
  }
}

RangeConfiguration.propTypes = {
  account: PropTypes.shape.isRequired,
  currentAdjustment: PropTypes.shape.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RangeConfiguration;
