import React from "react";
import BaseList from "./BaseList";
import { Icon, Switch, Popconfirm, Tooltip } from "../common/UIComponents";
import I18n from "../common/I18n";
import EditIcon from "./common/EditIcon";
import DeleteIcon from "./common/DeleteIcon";
class ActivityList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("general.code"),
        dataIndex: "code",
        key: "code",
        className: "width15Per",
      },
      {
        title: I18n.t("activity.subCode"),
        dataIndex: "sub_code",
        key: "sub_code",
        className: "width15Per",
      },
      {
        title: I18n.t("activity.feStatusCode"),
        dataIndex: "fe_status_code",
        key: "fe_status_code",
        className: "width15Per",
      },

      {
        title: I18n.t("general.description"),
        dataIndex: "description",
        key: "description",
        className: "width15Per",
      },
      {
        title: I18n.t("activity.allowDuplicates"),
        key: "allow_duplicates",
        dataIndex: "allow_duplicates",
        className: "width15Per",
        render: (data, record) => (
          <Switch
            checked={record.allow_duplicates === true}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            className={!record.allow_duplicates ? "switchComponent" : null}
          />
        ),
      },
      {
        title: I18n.t("activity.genStatusFile"),
        key: "generate_status_file",
        dataIndex: "generate_status_file",
        className: "width15Per",
        render: (data, record) => (
          <Switch
            checked={record.generate_status_file === true}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            className={!record.generate_status_file ? "switchComponent" : null}
          />
        ),
      },
      {
        title: "",
        key: "actions",
        dataIndex: "id",
        className: "width10",
        fixed: "right",
        render: (id, record, index) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
            </Tooltip>
            <Popconfirm
              placement="left"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={I18n.t("general.delete")}>
                <span><DeleteIcon/></span>
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ];
  }
}
export default ActivityList;
