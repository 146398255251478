import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import _, { debounce } from "lodash";
import moment from "moment";
import {
  Spin,
  Col,
  Row,
  Icon,
  Search,
  Dropdown,
  Button,
  Card,
  FormItem,
  Text,
  Link,
} from "../../common/UIComponents";
import { alertMessage, formatDate, isEmpty, refreshIcon } from "../../common/Common";
import I18n from "../../common/I18n";
import OrderFilter from "../orders/OrderFilter";
import UnallocatedOrdersList from "./UnallocatedOrdersList";
import AppConfig from "../../config/AppConfig";
import { fetchOrders } from "../../api/OrdersApi";
import { getStartTime } from "../../helpers/orders";
import { newDispatchRoute, updateRouteInMiddle } from "../../api/Routes";
import { momentTime } from "../../helpers/date_functions";
import { Alert, Checkbox, Collapse, Drawer, Input, List, Modal, Select, Tooltip } from "antd";
import EditOrderForm from "../orders/EditOrderForm";
import { fetchShortFormAccounts } from "../../api/Account";
import { withRouter } from "react-router";
import {
  handleChangeTableCheckChange,
  rearrangeBySeq,
} from "../../helpers/preplan";
import BaseModal from "../BaseModal";
import IncorrectOrdersMap from "../../containers/IncorrectOrdersMap";
import DriverSelect from "./DriverSelect";
import userStore from "../../stores/UserStore";
import {
  defaultSearchOrdersfilter,
  renderDriverAllocationErrors,
} from "./helpers";
import { convertToTags, getOperationalTimings, limitString, removeSpecialCharacters, renderAlertMessage } from "../../helpers/common";
import AppliedFiltersTags from "../../containers/AppliedFiltersTags";
import OrdersList from "../Tasks/OrdersList";
import { DisplaySettingsContext } from "../../context/DisplaySettings";
import { OrgContext } from "../../context/OrgContext";
import RouteLabel from "../../config/RouteLabel";
import { WarehouseContext } from "../../context/WarehouseContext";
import { RecordsPerPage } from "../orders/RecordsPerPage";
import ViewToggleButton from "../../containers/ViewToggleButton";
import { VehicleTypeApi } from "../../api/VehicleType";
import { UserContext } from "../../context/UserContext";
import { ScreenKeys } from "../../containers/constants";
import EndUserTitle from "../common/EndUserTitle";
import { AccountsFilter, ZonesFilter } from "../common/TreeFilters";
import UnallocatedOrdersGridList from "./UnallocatedOrdersGridList";
import data from "./data";
import { setGridConfigSetting } from '../../api/DisplaySettings';
import { OrderConfigContext } from "../../context/OrderConfigContext";
import DispatchUnallocatedOrdersList from "./DispatchUnallocatedOrdersList";
import { fetchFilteredUser } from "../../api/UsersApi";

const { Panel } = Collapse;

const viewOptions = [ 'map', 'list', 'both' ];
class SearchOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeId: props.routeId,
      route: props.route,
      searchOrderTokens: [],
      filterPlaceHolder: defaultSearchOrdersfilter(this.props),
      filterWindow: false,
      routes: {
        selectedOrderKeys: [],
        selectedOrderRows: [],
        selectedOrderIndexes: [],
      },
      orderInfo: [],
      pagination: {},
      inProgress: false,
      users : [],
      errors: [],
      editForm: false,
      organizationSettings: {},
      displayConfiguration: {},
      isMilitaryTime: false,
      showDriverAllocationModal: false,
      madeChangesInRouteAssigner: false,
      hideRoutes: false,
      isAssigning: false,
      driverAllocationErrors: [],
      allocatedDriver: null,
      markersOpenState: {},
      activeMarker: null,
      invalidDriverAllocation: false,
      allocationErrors: [],
      accounts: [],
      warehouses: this.props.warehouses,
      appliedFiltersTags: [],
      zones: [],
      searchType: "",
      isSearching: false,
      updateSearchTags: false,
      recordsPerPageMax: props.recordsPerPageMax,
      recordsPerPageMin: props.recordsPerPageMin,
      currentView: "both",
      total_orders_data: [],
      currentGridRef: null,
      colDefCheckbox: [],
      columnDefs:[],
      showRerrangeableModal: false,
      rowSelection: {
        selectedRowKeys: [],
        onSelectAll: this.handleSelectAllChange,
        onChange: this.handleShiftSelectChange,
        columnWidth: 30,
        getCheckboxProps: (record) => ({
          disabled: record.id === "1234" || record.account_limit_exceeded,
          name: record.name,
          id: `${record.id}`,
        }),
      },
      staticOrderListProps: {
        size: "small",
        showDelete: false,
        showActions: false,
        showDetailsLink: false,
        showTriggerEdit: true,
        className: props.showRouteAssigner ? "driverAssignList" : "",
        hideAppointmentdate: true,
        hideColumns: [ "type_of_order" ],
        currentRouteDate: props.startTime,
      },
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
    this.getOrders = _.debounce(this.getOrders, 500);
    this.getAccounts = _.debounce(this.getAccounts, 500);
    this.debouncedApiCall = _.debounce(this.debapiCall, 2000);
  }


  onRecordChange = (ele, value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.state[ ele ]
    }
    this.setState(
      {
        [ ele ]: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getOrders();
        // updateRecordsPerPage(ScreenKeys.SEARCH_ORDERS, value);
        if (!this.props.showRouteAssigner) {
          updateRecordsPerPage(ScreenKeys.SEARCH_ORDERS_MINIMIZED, value);
        } else {
          updateRecordsPerPage(ScreenKeys.SEARCH_ORDERS, value);
        }
      }
    );
  };

  debapiCall = (columns) => {
    const orgId = userStore.getStateValue("selectedOrg");
    const userId = userStore.getStateValue("id");

    // setGridConfigSetting({
    //   config_type: "dispatch_unassigned_orders_v2",
    //   config_value: columns,
    //   organization_id: orgId,
    //   user_id: userId,
    // })
  };

  makeDebouncedApiCall = (columns) => {
    this.debouncedApiCall(columns);
  };

  assignFilterCheck = (colDefs) => {
    this.state.columnDefs=colDefs
    const arr = colDefs.map((e) => (e.headerName ? { headerName: e.headerName, checked: true } : null));
    const filteredArr = arr.filter(e => e !== null);
    this.state.colDefCheckbox = filteredArr;
  }
  
  Decision = (obj) => {
    const { colDefCheckbox } = this.state;
  
    const updatedColDefCheckbox = colDefCheckbox.map((item) =>
      item.headerName === obj.headerName ? { ...item, checked: !obj.checked } : item
    );
  
    // Save the updated checkbox state to local storage
    localStorage.setItem('colDefCheckbox', JSON.stringify(updatedColDefCheckbox));
  
    // Trigger any other necessary actions, such as an API call
    this.makeDebouncedApiCall(
      updatedColDefCheckbox
        .filter((item) => item.checked)
        .map((item) => item.headerName)
    );
    this.setState({ colDefCheckbox: updatedColDefCheckbox });
  };

  setColDefCheckbox = (columnName) => {
    this.setState((prevState) => {
      const updatedColDefCheckbox = prevState.colDefCheckbox.map((item) =>
        item.headerName === columnName ? { ...item, checked: false } : item
      );

      this.debouncedApiCall(
        updatedColDefCheckbox
          .filter((item) => item.checked)
          .map((item) => item.headerName),
        updatedColDefCheckbox
      );

      return { colDefCheckbox: updatedColDefCheckbox };
    });
  };


  // closeModal = () => {
  //   this.setState({ modalOpen: false })
  // };

  openRerrangeableModal = () => {
    this.setState({ showRerrangeableModal: true })
  }

  getFilteredUsers = () => {
    fetchFilteredUser()
      .then((result) => {
        if (result.success) {
          this.setState({users: result.users || []});
        } else {
          renderAlertMessage(result.errors)
          this.setState({ users : [] });
      }
    });
  };


  onSearch = (value) => {
  };

  handleColumnVisibilityChange = (event) => {
    if (!event.visible) {
      const columnName = event.column.getColDef().headerName;
      this.setColDefCheckbox(columnName);
    }
  };

  componentDidMount() {
    this.getOrders();
    this.getAccounts();
    this.getFilteredUsers();
    this.setState({
      organizationSettings: this.props.organizationSettings,
      displayConfiguration: this.props.displayConfiguration,
      isMilitaryTime:
        this.props.organizationSettings.is_military_time == "true",
    });
    this.props.fetchavailableDriversInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts();
    }
    if (
      !_.isEqual(
        prevProps.warehouseFilter.selectedWarehouses,
        this.props.warehouseFilter.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseFilter.warehouses,
        this.props.warehouseFilter.warehouses
      ) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    }
    if (
      !_.isEqual(
        prevState.displayConfiguration,
        this.props.displayConfiguration
      )
    )

    if (
      !_.isEqual(
        prevState.organizationSettings,
        this.props.organizationSettings
      )
    ) {
      this.setState({
        displayConfiguration: this.props.displayConfiguration,
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
      },() => {
        // Update OrdersList props with updated state values
        this.updateOrdersListProps();
      });
    }
    if (this.props.actionType === "routeSelection") {
      if (
        !_.isEqual(
          this.state.routes.selectedOrderKeys,
          this.props.unassignedOrdersInfo.selectedKeys
        )
      ) {
        const selectedOrderIndexes = _.map(
          this.props.unassignedOrdersInfo.selectedKeys,
          (key) => {
            return _.findIndex(this.state.orderInfo, (order) => {
              return order.id === key;
            });
          }
        ).filter((index) => index !== -1);

        this.setState({
          routes: {
            selectedOrderKeys: this.props.unassignedOrdersInfo.selectedKeys,
            selectedOrderRows: this.props.unassignedOrdersInfo.selectedRows,
            selectedOrderIndexes,
          },
        });
      }
      if (
        !_.isEqual(
          this.state.filterPlaceHolder.fromDate,
          this.props.startTime
        ) ||
        !_.isEqual(this.state.filterPlaceHolder.toDate, this.props.endTime) ||
        (this.state.filterPlaceHolder.warehouse_id !==
          this.props.warehouse_id &&
          !this.props.showRouteAssigner)
      ) {
        const filterPlaceHolder = { ...this.state.filterPlaceHolder };
        if (
          !_.isEqual(
            this.state.filterPlaceHolder.fromDate,
            this.props.startTime
          ) ||
          !_.isEqual(this.state.filterPlaceHolder.toDate, this.props.endTime)
        ) {
          filterPlaceHolder.fromDate = this.props.startTime;
          filterPlaceHolder.toDate = this.props.endTime;
        }

        if (
          this.state.filterPlaceHolder.warehouse_id !== this.props.warehouse_id
        ) {
          filterPlaceHolder.warehouse_id = this.props.warehouse_id;
        }
        this.setState(
          {
            filterPlaceHolder,
          },
          () => {
            this.getOrders();
          }
        );
      }else if (
        this.props.refreshUnallocated === true &&
        prevProps.refreshUnallocated === false
      ) {
        this.getOrders();
      }
    } else if (
      this.props.refreshUnallocated === true &&
      prevProps.refreshUnallocated === false
    ) {
      this.getOrders();
    }
    if (
      this.state.showRouteAssigner !== prevState.showRouteAssigner &&
      !this.state.showRouteAssigner
    ) {
      if (
        this.state.madeChangesInRouteAssigner &&
        prevState.madeChangesInRouteAssigner !==
          this.state.madeChangesInRouteAssigner
      ) {
        this.props.updateSelectedRouteInfo();
        this.setState({ madeChangesInRouteAssigner: false });
      }
    }
    if (this.props.refreshSearchOrders !== prevProps.refreshSearchOrders) {
      this.getOrders();
    }
    if (prevState.routes.selectedOrderKeys !== this.state.routes.selectedOrderKeys) {
      this.setState({
        rowSelection: {
          ...this.state.rowSelection,
          selectedRowKeys: this.state.routes.selectedOrderKeys,
        },
      });
    }
    if (
      prevProps.showRouteAssigner !== this.props.showRouteAssigner ||
      prevProps.startTime !== this.props.startTime
    ) {
      this.setState({
        staticOrderListProps: {
          ...this.state.staticOrderListProps,
          className: this.props.showRouteAssigner ? "driverAssignList" : "",
          currentRouteDate: this.props.startTime,
        },
      });
    }
  }

  updateOrdersListProps = () => {
    this.setState({
      ordersListProps: {
        displayConfiguration: this.state.displayConfiguration,
      },
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getOrders();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.handleOnFilterElementChange("sortBy", {
        sortKey: sorter.columnKey,
        sortKeyType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
    }
  };

  onSort = (sortKey, sortKeyType) => {
    const toSortLocally = [
      "customer_order_number",
      "type_of_order",
      "hawb",
      "mawb",
      // "warehouse_address",
      // "customer_address",
      // "warehouse",
      "routing",
      "vehicle_type",
      // "vehicle_image",
      "company_name",
      "account_code",
      "account_name",
      // "full_address",
      "items_count",
      "weight",
      // "driver",
      // "status",
      "created_at",
      "los",
      "zone_name",
      "reference_1",
      "reference_2",
      "cities"
      , "zipcode"
    ];
    if (!toSortLocally.includes(sortKey)) {
      this.handleOnFilterElementChange("sortBy", {
        sortKey,
        sortKeyType: sortKeyType === 'asc' ? 'ascend' : 'descend',
      });
    }else{
      this.setState({
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          sortBy: sortKey,
          sortByType: sortKeyType === 'asc' ? 'ascend' : 'descend',
        },
      });
    }
  };

  handleOrderFilterChange = (key, value) => {
    let filterPlaceHolder = this.state.filterPlaceHolder;
    filterPlaceHolder[key] = value;
    this.setState({ filterPlaceHolder: filterPlaceHolder });
    this.getOrders();
  };
  computeTags = (filterPlaceHolder) => {
    const { orderTypeKeysForFilter } = this.props
    const buildSearchVal = () => {
      let searchLabel =
        filterPlaceHolder.search_key_type === "name_search_key"
          ? I18n.t("order.filters.name_search_key")
          : filterPlaceHolder.search_key_type === "phone_search_key"
          ? I18n.t("order.filters.phone_search_key")
          : filterPlaceHolder.search_key_type === "ref_search_key"
          ? I18n.t("order.filters.ref_search_key")
          : "";
      searchLabel = searchLabel.trim();
      if (searchLabel.length) {
        return `${searchLabel} -> ${filterPlaceHolder.search_key_value}`;
      }
      return "";
    };

    const buildSortByLabel = () => {
      let sortByLabel = I18n.t(`order.filters.sortBy.${filterPlaceHolder.sortBy}`);
        // filterPlaceHolder.sortBy === "city"
        //   ? I18n.t("general.city")
        //   : filterPlaceHolder.sortBy === "account_code"
        //   ? I18n.t("general.account")
        //   : "";
      sortByLabel = sortByLabel.trim();
      if (sortByLabel.length) {
        return `${sortByLabel}`;
      }
      return "";
    };

    const keys = [
      "account_codes",
      "warehouse_id",
      "search_key_type",
      "sortBy",
      "zone_ids",
      "order_type",
      "vehicle_type",
      "created_by"
    ];
    const { accounts, warehouses } = this.state;
    const filteredData = {
      account_codes: {
        label: I18n.t("general.accounts"),
        // value:
        //   accounts.find(
        //     (acc) => acc.code === filterPlaceHolder.accountCode,
        //   )?.name || '',
        value: limitString(
          filterPlaceHolder?.account_codes &&
            accounts
              .filter((acc) =>
                filterPlaceHolder.account_codes.includes(acc.code)
              )
              .map((accName) => accName.name)
              .join(", "),
          5
        ),
        onClose: () => this.handleOrderFilterChange("account_codes", []),
        closeable:
          filterPlaceHolder?.account_codes &&
          filterPlaceHolder.account_codes.length > 0,
      },
      warehouse_id: {
        label: "Warehouse",
        value:
          warehouses.find(
            (warehouse) => warehouse.id === filterPlaceHolder.warehouse_id
          )?.name || "",
        onClose: () => this.handleOrderFilterChange("warehouse_id", ""),
        // closeable: warehouses.length > 1 ? true : false,
        closeable: false
      },
      zone_ids: {
        label: I18n.t("general.zone"),
        value:
          filterPlaceHolder?.zone_ids && filterPlaceHolder.zone_ids.length > 0
            ? limitString(
                filterPlaceHolder.zone_ids.map((zone) => zone.label).join(", "),
                5
              )
            : "ALL",
        onClose: () => {
          this.handleOrderFilterChange("zone_ids", []);
        },
        closeable:
          filterPlaceHolder?.zone_ids && filterPlaceHolder.zone_ids.length > 0,
      },
      search_key_type: {
        label: I18n.t("search.by"),
        value:
          filterPlaceHolder?.search_key_value &&
          filterPlaceHolder.search_key_value.length
            ? buildSearchVal()
            : "",
        onClose: () => {
          this.handleOrderFilterChange("search_key_type", "");
          this.handleOrderFilterChange("search_key_value", "");
        },
        closeable: true,
      },
      sortBy: {
        label: I18n.t("general.sort_by"),
        value: filterPlaceHolder.sortBy == "none" ? "" :  filterPlaceHolder.sortBy === 'consignee' ? <EndUserTitle/> : buildSortByLabel(),
        onClose: () => {
          this.handleOrderFilterChange("sortBy", "none");
          this.handleOrderFilterChange("sortByType", "");
        },
        closeable: true,
      },
      order_type: {
        label: I18n.t("order.type"),
        value: filterPlaceHolder.order_type == "" ? "ALL" : orderTypeKeysForFilter[filterPlaceHolder.order_type],
        onClose: () => {
          this.handleOrderFilterChange("order_type", "");
        },
        closeable: filterPlaceHolder.order_type !== '',
      },
      vehicle_type: {
        label: I18n.t("vehicleTypes.title"),
        value: filterPlaceHolder.vehicle_type ? filterPlaceHolder.vehicle_type : null,
        onClose: () => {
          this.handleOrderFilterChange("vehicle_type", "");
        },
        closeable: filterPlaceHolder.vehicle_type !== '',
      },
      created_by: {
        label: I18n.t("general.created_by"),
        value: limitString(this.state.users.filter(user => filterPlaceHolder?.created_by?.includes(user.user_id)).map(user => user.user_name).join(", "),5),
        onClose: () => {
          this.handleOrderFilterChange("created_by", "");
        },
        closeable: filterPlaceHolder.created_by !== '',
      },
    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  getOrders = () => {
    const { filterPlaceHolder, filter } = this.state;
    this.setState({ inProgress: true });
    const page = isEmpty(filterPlaceHolder.search_order_token)
      ? this.tableOptions.pagination.current
      : 1;
    // const perPage = this.state.recordsPerPage;
    const isMinimizedView = !this.props.showRouteAssigner;
    const perPage = this.props.isUsingBetaVersion ? 1000 : isMinimizedView ? this.state.recordsPerPageMin : this.state.recordsPerPageMax;
    const orderNumber = !isEmpty(filterPlaceHolder.search_order_token)
      ? filterPlaceHolder.search_order_token.toUpperCase()
      : "";
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const searchKeyType = filterPlaceHolder.search_key_type;
    const searchKeyValue = filterPlaceHolder.search_key_value;
    const scheduleOrdersType = filterPlaceHolder.schedule_orders_type;
    const zone_ids = filterPlaceHolder.zone_ids || [];
    const order_type = filterPlaceHolder.order_type || "";
    const vehicle_type = filterPlaceHolder.vehicle_type || "";
    const created_by = filterPlaceHolder.created_by || "";
    const globalSearch = true;
    const routeStartDate = momentTime(
      "YYYY-MM-DD",
      this.state.route.route_start_time,
      this.state.route.timeZoneId
    );
    fetchOrders({
      search_type: filterPlaceHolder.search_type || 'EQUALS',
      status: "PREPLAN_ORDERS", //!isEmpty(orderNumber) ? "" : "PREPLAN_ORDERS",
      fromDate: formattedFromDate,
      todate: formattedToDate,
      page,
      perPage,
      orderNumber,
      sortBy: filterPlaceHolder.sortBy,
      sortByType: filterPlaceHolder.sortByType || "ascend",
      account_codes: filterPlaceHolder.account_codes,
      warehouseId: filterPlaceHolder.warehouse_id,
      searchKeyType,
      searchKeyValue,
      scheduleOrdersType,
      globalSearch,
      zone_ids,
      order_type,
      vehicle_type,
      created_by,
      is_dispatch: true,
    })
      .then((result) => {
        if (result.success) {
          // const filter = this.state.filter;
          const total_orders = result.orders.customer_orders;

          const orders = total_orders.map((x) => {
            const orderAppointments = x.order_appointments.filter(
                (appointment) =>
                moment(appointment.start_datetime).isSame(
                    moment(routeStartDate),
                    "day"
                  )
              )
            const csLocation = x.cs_location ? x.cs_location : {};
            const coordinates =
              csLocation.l_address &&
              csLocation.l_address.coordinates &&
              csLocation.l_address.coordinates.length > 0
                ? csLocation.l_address.coordinates
                : [];
            const hasCoordinates = coordinates.length > 0;
            return Object.assign({}, x.order, {
              appointments: _.sortBy((x.order_appointments || []), "start_datetime"),
              nav_route_id: x.nav_route_id,
              nav_route_name: x.nav_route_name,
              nav_route_status: x.nav_route_status,
              scheduled_start_datetime_with_tz:
                x.scheduled_start_datetime_with_tz,
              scheduled_end_datetime_with_tz: x.scheduled_end_datetime_with_tz,
              timeZoneName: x.timeZoneName,
              tz_short_form: x.tz_short_form,
              customer_address:
                csLocation && csLocation.l_address
                  ? {
                      ...csLocation.l_address,
                      location_code: csLocation.l_address.city,
                    }
                  : {},
              warehouse_address:
                x.wh_location && x.wh_location.l_address
                  ? {
                      ...x.wh_location.l_address,
                      location_code: x.wh_location.location_code,
                    }
                  : {},
              zoneName:
                !isEmpty(x.order.order_zone_name) &&
                (_.isArray(x.order.order_zone_name)
                  ? x.order.order_zone_name.join(", ")
                  : ""),
              startTime: getStartTime(orderAppointments),
              wh_timezone: x.wh_location ? x.wh_location.timeZoneId : "EST",
              lat: hasCoordinates ? coordinates[1] : "",
              lng: hasCoordinates ? coordinates[0] : "",
              origin: x.origin && !isEmpty(x.origin.l_address) ? {
                ...x.origin.l_address,  
                status: x.origin.status,
                company_name: x.origin.company_name,
                level_of_service: x.origin.level_of_service,
              }  : {},
              destination: x.destination && !isEmpty(x.destination.l_address) ? {
                ...x.destination.l_address,  
                status: x.destination.status,
                company_name: x.destination.company_name,
                level_of_service: x.destination.level_of_service,
              } : {},
            });
          });
          // if (order_number && orders.length > 0) {
          //   filter = orders[0].status;
          // }
          const selectedOrders =
            this.state.routes && this.state.routes.selectedOrderKeys
              ? this.state.routes.selectedOrderKeys
              : [];
          const selectedOrderRows = orders.filter((order) =>
            selectedOrders.includes(order.id)
          );
          const selectedOrderIndexes = _.map(selectedOrders, (key) => {
            return _.findIndex(this.state.orderInfo, (order) => {
              return order.id === key;
            });
          }).filter((index) => index !== -1);
          this.setState(
            {
              orderInfo: orders,
              total_orders_data: result.orders.customer_orders,
              inProgress: false,
              pagination: orders.length > 0 ? result.pagination : {},
              filterPlaceHolder: Object.assign(
                {},
                this.state.filterPlaceHolder,
                {
                  filter,
                }
              ),
              filter,
              routes: {
                // selectedOrderKeys: selectedOrderRows.map((order) => order.id),
                // selectedOrderRows: [...selectedOrderRows],
                // selectedOrderIndexes
                selectedOrderKeys: [],
                selectedOrderRows: [],
                selectedOrderIndexes: [],
              },
            },
            () => {
              this.updateOrdersNRoutes(orders);
            }
          );
        } else {
          // alertMessage(result.errors[0], 'error', 5);
          this.setState(
            {
              orderInfo: [],
              inProgress: false,
              pagination: {},
              routes: {
                selectedOrderKeys: [],
                selectedOrderRows: [],
                selectedOrderIndexes: [],
              },
            },
            () => {
              this.updateOrdersNRoutes([]);
            }
          );
        }
      })
      .finally(() => {
        this.computeTags(this.state.filterPlaceHolder);
      });
  };

  updateOrdersNRoutes = (orders) => {
    if (this.props.actionType === "routeSelection") {
      this.props.setOrders(orders);
      this.props.setUnassignedInfo({ ...this.state.routes });
    }
  };

  getAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState({
      accounts: accountsData,
    })
  };

  closeFilterWindow = () => {
    this.setState({ filterWindow: false });
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
      searchType: "",
    });
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: Object.assign({}, filter),
      },
      cb
    );
  };

  getRouteDateOrders = () => {
    // const routeStartDate = moment.utc(new Date(this.state.route.route_start_time)).format('YYYY-MM-DD'); // moment(this.state.route.route_start_datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
    const routeStartDate = momentTime(
      "YYYY-MM-DD",
      this.state.route.route_start_time,
      this.state.route.timeZoneId
    );
    let orders = [];
    if (!isEmpty(routeStartDate)) {
      orders = this.state.orderInfo
        .filter(
          (order) =>
            this.state.routes.selectedOrderKeys.includes(order.id) &&
            moment(order.startTime, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD") ===
              routeStartDate
        )
        .map((order) => order.id);
    } else {
      orders = this.state.orderInfo
        .filter((order) =>
          this.state.routes.selectedOrderKeys.includes(order.id)
        )
        .map((order) => order.id);
    }

    return orders;
  };
  getRouteOtherOrders = () => {
    const orders = this.getRouteDateOrders();
    const rejectedOrders = this.state.routes.selectedOrderKeys.filter(
      (order) => !orders.includes(order)
    );
    return rejectedOrders;
  };
  geOtherWHOrders = () => {
    if (this.props.route?.warehouse_id) {
      const sourceWhId = this.props.route.warehouse_id;
      const rejectedOrders = this.state.routes.selectedOrderRows
        .filter((order) => order.wh_location_id !== sourceWhId)
        .map((order) => order.id);
      return rejectedOrders;
    }
    return [];
  };
  getSelectedOrders = (orderIds = [], keyToRetrieve = "") => {
    const orders = this.state.orderInfo.filter((order) =>
      orderIds.includes(order.id)
    );
    if (!isEmpty(keyToRetrieve) && orders.length > 0) {
      const selectedItems = orders.map((order) => order[keyToRetrieve]);
      return selectedItems;
    }
    return orders;
  };

  handleSearch = () => {
    this.setState(
      {
        isSearching: false,
        filterWindow: false,
        filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
          search_order_token: "",
        }),
        searchType: "byFilter",
      },
      () => {
        this.getOrders();
      }
    );
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    const selectedOrderIndexes = _.map(selectedRowKeys, (key) => {
      return _.findIndex(this.state.orderInfo, (order) => {
        return order.id === key;
      });
    }).filter((index) => index !== -1);
    const routes = Object.assign({}, this.state.routes, {
      selectedOrderKeys: selectedRowKeys,
      selectedOrderRows: selectedRows,
      selectedOrderIndexes,
    });
    // check if warehouse is same for all orders in routes if not same then push error
    const whLocationIds = routes.selectedOrderRows.map(
      (order) => order.wh_location_id
    );
    const whLocationId = _.uniq(whLocationIds);
    const errors = [...this.state.errors];
    if (whLocationId.length > 1) {
      errors.push("Selected orders are from different warehouses");
    }
    this.setState(
      {
        routes,
        driverAllocationErrors: errors,
      },
      () => {
        if (this.props.actionType === "routeSelection") {
          this.props.setUnassignedInfo({ ...this.state.routes });
        }
      }
    );
  };

  handleFilterValueChange = (element, value, isSearch = false) => {
    if (isSearch) {
      this.orderNumberSearch(value);
    } else {
      this.setState({
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          [element]: value,
        },
        // searchType: "",
      });
    }
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: !isEmpty(value) ? value : "",
    });
  };

  handleOnFilterElementChange = (element, value) => {
    if (element === "dateFilter") {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        fromDate: value.fromDate,
        toDate: value.toDate,
      });
      this.updateFilter(filter);
    } else if (element === "search_key_type") {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        search_key_type: value.search_key_type,
        search_key_value: value.search_key_type,
      });
      this.updateFilter(filter);
    } else if (element === "sortBy") {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        sortBy: value.sortKey,
        sortByType: value.sortKeyType || "ascend",
      });
      this.updateFilter(filter);
    } else {
      const filter = Object.assign({}, this.state.filterPlaceHolder, {
        [element]: value,
      });
      this.updateFilter(filter);
    }
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      this.handleSearch(filter);
    });
  };

  orderNumberSearch = (value) => {
    let orderSearchFilter = defaultSearchOrdersfilter(this.props);
    orderSearchFilter.warehouse_id = "";
    orderSearchFilter.search_order_token = value;

    this.setState(
      {
        filterPlaceHolder: orderSearchFilter,
        searchType: "byOrders",
        isSearching: value.length > 0 ? true : false,
        updateSearchTags: true,
      },
      () => {
        this.getOrders();
      }
    );
  };

  handleSave = () => {
    this.setState({
      inProgress: true,
    });
    let data = {};
    if (this.state.routeId) {
      if (
        this.state.routeStatus !== "PREPARE" &&
        this.state.routeStatus !== "DISPATCHED" &&
        this.state.routeStatus !== "COMPLETED"
      ) {
        const orders = this.getRouteDateOrders();
        const rejectedOrders = this.getRouteOtherOrders();
        const otherWhOrders = this.geOtherWHOrders();
        if (rejectedOrders.length > 0 || otherWhOrders.length > 0) {
          const apptErrNumbers = this.getSelectedOrders(
            rejectedOrders,
            "customer_order_number"
          );
          const otherWhNumbers = this.getSelectedOrders(
            otherWhOrders,
            "customer_order_number"
          );
          const apptErrors = I18n.t("messages.appt_not_matching_error", {
            field: apptErrNumbers.join(", "),
          });
          const whErrors = I18n.t("messages.wh_not_matching_error", {
            field: otherWhNumbers.join(", "),
          });
          Modal.error({
            key: "error",
            title: "Error",
            content: (
              <Fragment>
                {rejectedOrders.length > 0 && <div>{apptErrors}</div>}
                {otherWhOrders.length > 0 && <div>{whErrors}</div>}
              </Fragment>
            ),
            width: "50%",
            // placement: "bottomRight",
            // duration: 20,
          });
          this.setState({
            inProgress: false,
          });
        } else {
          data = Object.assign({}, data, {
            nav_route_id: this.state.routeId,
            order_ids_to_add: orders,
          });
          updateRouteInMiddle(data).then((result) => {
            if (result.success) {
              this.props.closeSearchOrders(
                true,
                this.state.routeId,
                this.props.actionType
              );
            } else {
              renderAlertMessage(result.errors)
              this.setState({
                inProgress: false,
              });
            }
          });
        }
      }
    }
  };
  renderAllocationSearchBar = () => {
    const isRouteSelection = this.props.actionType === "routeSelection";
    const searchWidth = isRouteSelection ? "450px" : "550px";
    let orderSearchFilter = defaultSearchOrdersfilter(this.props);
    orderSearchFilter.warehouse_id = "";
    return (
      <Search
        placeholder={I18n.t("order.filters.placeholder")}
        // enterButton="Search"
        size={this.props.actionType === "routeSelection" ? "small" : "default"}
        value={
          !isEmpty(this.state.filterPlaceHolder.search_order_token)
            ? this.state.filterPlaceHolder.search_order_token.toUpperCase()
            : ""
        }
        onChange={(e) => {
          this.handleFilterValueChange("search_order_token", e.target.value);
        }}
        onSearch={(value) => this.orderNumberSearch(value)}
        style={{
          width: searchWidth,
          margin:
            this.props.actionType === "currentRoute"
              ? "10px 0px 0px 20px"
              : "1px",
          textTransform: "uppercase",
        }}
        className="add-route-search order-search textUpperCase"
        suffix={
          <Fragment>
            {this.state.filterPlaceHolder.search_order_token !== "" && (
              <Fragment>
                {" "}
                <Icon
                  type="close"
                  onClick={() =>
                    this.handleFilterValueChange("search_order_token", "", true)
                  }
                />{" "}
                &nbsp;&nbsp;
              </Fragment>
            )}
              <Dropdown
                onVisibleChange={this.handleVisibleChange}
                visible={ this.state.filterWindow && this.props.showRouteAssigner}
                trigger={["click"]}
                placement={
                  this.state.showDriverAllocationModal
                    ? "bottomCenter"
                    : "bottomLeft"
                }
                overlay={
                  <div
                    style={{
                      backgroundColor: "white",
                      width: searchWidth,
                      padding: 15,
                      marginTop: 10,
                      marginRight: -12,
                    }}
                    className="boxShadow"
                  >
                    <OrderFilter
                      showOrderNoSearch={false}
                      showWarehouses={true}
                      filter={this.state.filterPlaceHolder}
                      onChange={this.handleFilterPlaceHolderChange}
                      onSearch={this.handleSearch}
                      onOrderNoSearch={null}
                      showDateFilter={isRouteSelection ? false : true}
                      showZones={true}
                      showScheduleOption={ this.props.actionType === 'currentRoute' ? false : (
                        isRouteSelection ? false : true)
                      }
                      // size={"small"}
                      onAccountsChange={(data) => {
                        this.setState({ accounts: data });
                      }}
                      // onWarehousesChange={ (data) => { this.setState({ warehouses: data }); } }
                      onZonesChange={(data) => {
                        this.setState({ zones: data });
                      }}
                      defaultFilter={orderSearchFilter}
                    />
                  </div>
                }
              >
                <Icon
                  type="control"
                  onClick={() => this.handleOnChange("filterWindow", true)}
                />
              </Dropdown>
          </Fragment>
        }
        prefix={
          <Icon
            type="search"
            onClick={() =>
              this.orderNumberSearch(
                this.state.filterPlaceHolder.search_order_token
              )
            }
          />
        }
      />
    );
  };

  handleClearOrderFilter = () => {
    let orderSearchFilter = defaultSearchOrdersfilter(this.props);
    orderSearchFilter.warehouse_id = "";
    this.setState(
      {
        isSearching: false,
        appliedFiltersTags: [],
        // filterPlaceHolder: {
        //   fromDate: this.props.startTime ? this.props.startTime : moment(),
        //   toDate: this.props.endTime ? this.props.endTime : moment().add(2, "day"),
        //   filter: "",
        //   search_order_token: "",
        //   sortBy: "none",
        //   sortByType: "descend",
        //   accountCode: "",
        //   warehouse_id: this.props.showRouteAssigner ? this.props.warehouse_id : !_.isEmpty(this.props.route.warehouse_id) ? this.props.route.warehouse_id : '',
        //   search_key_type: "",
        //   search_key_value: "",
        //   [ I18n.t("order.filters.schedule_orders.type") ]: I18n.t(
        //     "order.filters.schedule_orders.scheduled_key"
        //   ),
        //   zone_ids: [],
        // }
        filterPlaceHolder: orderSearchFilter,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  renderSearchBar = (showTitle = false) => {
    const isRouteSelection = this.props.actionType === "routeSelection";
    const searchWidth = isRouteSelection ? "450px" : "550px";
    if (this.props.showRouteAssigner) {
      return <Fragment />;
    }
    let orderSearchFilter = defaultSearchOrdersfilter(this.props);
    return (
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Col span={24}>
          {
            showTitle && <span className="textBold">
              {I18n.t("general.search")}:
            </span>
          }
          <Search
            placeholder={I18n.t("order.filters.placeholder")}
            // enterButton="Search"
            size={
              this.props.actionType === "routeSelection" ? "small" : "default"
            }
            value={
              !isEmpty(this.state.filterPlaceHolder.search_order_token)
                ? this.state.filterPlaceHolder.search_order_token.toUpperCase()
                : ""
            }
            onChange={(e) =>
            {
              const filteredString = removeSpecialCharacters(e.target.value);
              this.handleFilterValueChange("search_order_token", filteredString)
            }
            }
            onSearch={(value) => this.orderNumberSearch(value)}
            style={{
              width: searchWidth,
              margin:
                this.props.actionType === "currentRoute"
                  ? "10px 0px 0px 20px"
                  : "1px",
              textTransform: "uppercase",
            }}
            className="add-route-search order-search textUpperCase"
            suffix={
              <Fragment>
                {this.state.filterPlaceHolder.search_order_token !== "" && (
                  <Fragment>
                    {" "}
                    <Icon
                      type="close"
                      onClick={() =>
                        this.handleFilterValueChange(
                          "search_order_token",
                          "",
                          true
                        )
                      }
                    />{" "}
                    &nbsp;&nbsp;
                  </Fragment>
                )}
                <Dropdown
                  onVisibleChange={this.handleVisibleChange}
                  visible={this.state.filterWindow}
                  trigger={["click"]}
                  placement={
                    this.state.showDriverAllocationModal
                      ? "bottomCenter"
                      : "bottomLeft"
                  }
                  overlay={
                    <div
                      style={{
                        backgroundColor: "white",
                        width: searchWidth,
                        padding: 15,
                        marginTop: 10,
                        marginRight: -12,
                      }}
                      className="boxShadow"
                    >
                      <OrderFilter
                        showOrderNoSearch={false}
                        showWarehouses={
                          this.state.showDriverAllocationModal ? true : false
                        }
                        filter={this.state.filterPlaceHolder}
                        onChange={this.handleFilterPlaceHolderChange}
                        onSearch={this.handleSearch}
                        onOrderNoSearch={null}
                        showDateFilter={false}
                        showZones={true}
                        showScheduleOption={this.props.actionType === 'currentRoute' ? false : 
                        (isRouteSelection ? false : true)}
                        size={isRouteSelection ? "small" : "middle"}
                        defaultFilter={orderSearchFilter}
                      />
                    </div>
                  }
                >
                  <Icon
                    type="control"
                    onClick={() => this.handleOnChange("filterWindow", true)}
                  />
                </Dropdown>
              </Fragment>
            }
            prefix={
              <Icon
                type="search"
                onClick={() =>
                  this.orderNumberSearch(
                    this.state.filterPlaceHolder.search_order_token
                  )
                }
              />
            }
          />
        </Col>
      </Row>
    );
  };

  closeEditFormModal = () => {
    this.setState({ editForm: false }, () => {
      this.getOrders();
    });
  };

  handleSaveSuccess = (message, reflectDetails = false, id = "") => {
    alertMessage(message);
    if (!reflectDetails) {
      this.setState({ editForm: false }, () => {
        this.getOrders();
      });
    } else {
      this.handleEditOrderClick(id);
    }
  };

  handleEditOrderClick = (id) => {
    const index = _.findIndex(this.state.orderInfo, ["id", id]);
    const orderObject = Object.assign({}, this.state.orderInfo[index]);
    this.setState(
      {
        orderObject,
      },
      () => {
        this.setState({ editForm: true, isNew: false });
      }
    );
  };

  navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(this.state?.accounts, [
      "code",
      account_code,
    ]);
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, this.state?.accounts[accountIndex]);
      const account_id = accountData?.id;
      this.props.history.push(`/accounts/${account_id}/settings`);
    }
  };

  handleSelectAllChange = (selected, selectedRows, changedRows) => {
    if (selected) {
      const orders = rearrangeBySeq(selectedRows);
      this.handleUnassignOrdersInfo(orders);
    } else {
      this.handleUnassignOrdersInfo([]);
    }
  };

  handleUnassignOrdersInfo = (orders = []) => {
    const orderKeys = orders.map((order) => order.id);
    this.handleSelectChange(orderKeys, orders);
  };

  handleShiftSelectChange = (selectedRowKeys, selectedRows) => {
    const { routes, orderInfo } = this.state;
    const orders = handleChangeTableCheckChange(
      selectedRowKeys,
      selectedRows,
      [...routes.selectedOrderRows],
      orderInfo
    );
    this.handleUnassignOrdersInfo(_.uniqBy(orders, "id"));
  };

  scrollCalculator = (customScroll) => {
    if (customScroll) {
      return customScroll;
    }
    const { actionType } = this.props;
    if (actionType === "currentRoute") {
      return { y: "calc(100vh - 350px)" };
    } else {
      if (this.props.panelSize.pane_2 === 1) {
        return { x: "max-content", y: "calc(100vh - 310px)" };
      } else {
        return !this.props.isUsingBetaVersion ? { x: "max-content", y: "calc(30vh - 145px)" } :
          { x: "max-content", y: "calc(38vh - 145px)" };
      }
    }
  };

  setCurrentGridRef = (ref) => {
    this.setState({ currentGridRef: ref });
  };

  isRowSelectable = (row) => {
    return !row.data.account_limit_exceeded;
  };

  renderOrdersList = (customScroll) => {
    const isMinimizedView = !this.props.showRouteAssigner;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || (isMinimizedView ? this.state.recordsPerPageMin : this.state.recordsPerPageMax),
      // position: this.props.actionType === "routeSelection" ? "top" : "bottom",
    };
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    const selectedRowKeys = this.state.routes.selectedOrderKeys;
    const rowSelection = {
      selectedRowKeys,
      // onChange: this.handleSelectChange,
      onSelectAll: this.handleSelectAllChange,
      onChange: this.handleShiftSelectChange,
      columnWidth: 30,
      getCheckboxProps: (record) => ({
        disabled: record.id === "1234" || record.account_limit_exceeded,
        name: record.name,
        id: `${record.id}`,
      }),
    };
    const { actionType } = this.props;

    const accountCodesWithExceededLimit = this.props.accountsExceeded.map(account => account.account_code)
    const modifiedData = this.state.orderInfo.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        }
      } else {
        return order
      }
    }
    )

    const orderListProps = {
      data: modifiedData,
      openInfoModal: (id) => this.openInfoModal(id),
      orderDetailsViewClick: (id) => this.handleOrderDetailsViewClick(id),
      rowSelection: rowSelection,
      pagination: pagination,
      // pagination={ actionType === 'currentRoute' ? pagination : { position: 'none' } }
      tableChange: (pagination, filter, sorter) =>
        this.onTableChange(pagination, filter, sorter),
      navigateToAccount: (account_code) => this.navigateToAccount(account_code),
      currentPage: pagination.current ? pagination.current : 1,
      size: "small",
      handleEditOrder: (id) => this.handleEditOrderClick(id),
      showDelete: false,
      showActions: false,
      showDetailsLink: false,
      showTriggerEdit: true,
      resolvedAddressCallback: this.getOrders,
      scroll: this.scrollCalculator(customScroll),
      organizationSettings: this.state.organizationSettings,
      showWeight: true,
      className: this.props.showRouteAssigner ? "driverAssignList" : "",
      hideAppointmentdate: true,
      hideColumns: ["type_of_order"],
      accounts: this.state?.accounts,
      currentRouteDate: this.props.startTime,
    };

      
    const dynamicOrderListProps = {
      data: modifiedData,
      openInfoModal: (id) => this.openInfoModal(id),
      orderDetailsViewClick: (id) => this.handleOrderDetailsViewClick(id),
      rowSelection: rowSelection,
      pagination: pagination,
      tableChange: (pagination, filter, sorter) =>
        this.onTableChange(pagination, filter, sorter),
      navigateToAccount: (account_code) => this.navigateToAccount(account_code),
      currentPage: pagination.current ? pagination.current : 1,
      handleEditOrder: (id) => this.handleEditOrderClick(id),
      resolvedAddressCallback: this.getOrders,
      scroll: this.scrollCalculator(customScroll),
      organizationSettings: this.state.organizationSettings,
      accounts: this.state?.accounts,
    };

    const handleAccountCodeFiltering = (accountCodes) => {
    }

    const handleZoneFiltering = (zoneIds) => {
    }

    return (
      <Row
        gutter={8}
        className={actionType === "currentRoute" ? "marginTop20" : ""}
      >
        <Col xs={24} className="smallTable">
          <Spin delay={1000} spinning={this.state.inProgress}>
            { actionType !== "currentRoute" ? (
                <Row>
                { this.props.isUsingBetaVersion ? (
                  <Col xs={ 24 } style={ {
                    height: this.scrollCalculator(customScroll).y,
                  } }>
                    <UnallocatedOrdersGridList
                      {...orderListProps}
                      tableName={ScreenKeys.DISPATCH.UNASSIGNED_ORDERS_V2}
                      rawDataSource={this.state.orderInfo}
                      accountCodesWithExceededLimit={accountCodesWithExceededLimit}
                      onRowSelection={this.handleShiftSelectChange}
                      accountsList={ this.state?.accounts } 
                      displayConfiguration={displayConfiguration}
                      vhTypes={this.props.vhType}
                      userContext={this.props.userContext}
                      onSort={ this.onSort }
                      showTriggerEdit={true}
                      showDelete={false}
                      showActions={false}
                      showDetailsLink={false}
                      showWeight={true}
                      hideAppointmentdate={true}
                      handleEditOrder={ (id) => this.handleEditOrderClick(id) }
                      setCurrentGridRef={ (ref) => this.setCurrentGridRef(ref) }
                      assignFilterCheck={this.assignFilterCheck}
                      colDefCheckbox={this.state.colDefCheckbox}
                      Decision={ this.Decision }
                      closeModal={() => this.setState({ showRerrangeableModal: false })}
                      showRerrangeableModal={ this.state.showRerrangeableModal }
                      // height={this.scrollCalculator(customScroll)}
                      isRowSelectable={ this.isRowSelectable }
                      dispContext={ this.props.dispContext }
                      handleColumnVisibilityChange={ this.handleColumnVisibilityChange }
                    />
                  </Col>
                ) : (
                  <Col xs={ 24 }>
                    <UnallocatedOrdersList { ...orderListProps }
                      displayConfiguration={ displayConfiguration }
                      ordersListProps={ ordersListProps }
                      screen_from="dispatches"
                      vhTypes={ this.props.vhType }
                      // rowClassName={(record, index) =>
                      //   record.account_limit_exceeded ? "errRow" : ""
                      // }
                      showActionExceedField={ true }
                    />
                  </Col>
                ) }
              </Row>
            ) : (
              <Row>
                
                { actionType === "currentRoute" && <Col xs={24} className="alignRight">
                  {refreshIcon(() => {
                    this.getOrders();
                  }, true, false)}
                </Col>}
                <Col xs={24}>
                  <OrdersList {...orderListProps}
                    displayConfiguration={displayConfiguration}
                    ordersListProps={ordersListProps}
                    screen_from="dispatches"
                    scroll={{x: "max-content", y: "calc(100vh - 285px)"}}
                    // rowClassName={(record, index) =>
                    //   record.account_limit_exceeded ? "errRow" : ""
                    // }
                      showActionExceedField={ true }
                  />
                </Col>
              </Row>
              
               
            )}
          </Spin>
        </Col>
      </Row>
    );

  };

  toggleDriverAllocationModal = (
    visible = !this.state.showDriverAllocationModal
  ) => {
    this.setState({
      showDriverAllocationModal: visible,
      invalidDriverAllocation: false,
      allocationErrors: [],
      allocatedDriver: null
    });
  };
  handleDriverChange = (driverId) => {
    this.setState({ allocatedDriver: driverId });
    // find route assigned to the driver if any
    const { routes } = this.props;
    
    const driverWithRoute = _.split(driverId, "~");
    const selectedDriverId = _.get(driverWithRoute, "0", "") || '';
    const navRouteId = _.get(driverWithRoute, "1", "") || '';
    const routeIndex = navRouteId ? 
    _.findIndex(routes, { id : navRouteId}) : 
    _.findIndex(routes, {"primary_driver_id": selectedDriverId});
    if (routeIndex >= 0) {
      // get warehouse id from the route
      const warehouseId = routes[routeIndex].warehouse_id;
      const {
        routes: { selectedOrderRows },
      } = this.state;
        if (_.isArray(selectedOrderRows) && selectedOrderRows.length > 0) {
          const selectedWarehouseId = selectedOrderRows[0]?.wh_location_id;
      if (warehouseId === selectedWarehouseId) {
        this.setState({ invalidDriverAllocation: false });
        } else {
          this.setState({ invalidDriverAllocation: true });
        }
      } else {
        this.setState({ invalidDriverAllocation: true });
      }
    } else {
      // new route
      this.setState({ invalidDriverAllocation: false });
    }
  };

  buildErrorsDescription = (errors) => {
    let description = "";
    // errors is an array of strings. build description with new lines for each error
    errors.forEach((error) => {
      description += `${error} \n`;
    });
    return description;
  };
  _handleViewToggle = (newView) => {
    this.setState({ currentView: newView });
  };

  processOrderInfo = (totalorders) => {
    return _.flatMap(totalorders, order => {
      if (["T", "LH"].includes(order.order.type_of_order)) {
        return [
          {
            customer_order_id: order.order.id,
            customer_order_number: order.order.customer_order_number,
            customer_address: !_.isEmpty(order.origin?.l_address) ? order.origin.l_address : {},
            type_of_loc: 'PICKUP',
            hawb: order.order.hawb,
            company_name: order.origin?.company_name || '',
          },
          {
            customer_order_id: order.order.id,
            customer_order_number: order.order.customer_order_number,
            customer_address: !_.isEmpty(order.destination?.l_address) ? order.destination.l_address : {},
            type_of_loc: 'DELIVERY',
            hawb: order.order.hawb,
            company_name: order.destination?.company_name || '',
          }
        ];
      } else {
        return [{
          customer_order_id: order.order.id,
          customer_order_number: order.order.customer_order_number,
          customer_address: !_.isEmpty(order.cs_location?.l_address) ? order.cs_location.l_address : {},
          type_of_loc: 'cs_location',
          hawb: order.order.hawb,
          company_name: order.cs_location?.company_name || '',
        }];
      }
    });
  };

  handleAllocation = () => {
    // check if allocated driver has nav stops from driversinfo
    const { allocatedDriver, organizationSettings } = this.state;
    const { driversInfo } = this.props;
    const driverWithRoute = _.split(allocatedDriver, "~");
    const selectedDriverId = _.get(driverWithRoute, "0", "") || ''
    const route_id = _.get(driverWithRoute, "1", "") || ''

    const driverInfo = driversInfo.find(
      (driver) => driver.id === selectedDriverId
    );
    const { nav_stops_count } = driverInfo;

    if (nav_stops_count > 0) {
      // find route id of the driver
      const { routes } = this.props;
      // const selectedAssignedRoute = routes.find(
      //   (route) => route.primary_driver_id === allocatedDriver
      // );
      // const { id: route_id } = selectedAssignedRoute;

      const payload = {
        nav_route_id: route_id,
        order_ids_to_add: this.state.routes.selectedOrderRows.map(
          (order) => order.id
        ),
      };

      this.setState({ isAssigning: true, allocationErrors: [] });
      updateRouteInMiddle(payload)
        .then((result) => {
          if (result.success) {
            // show success message with driver name
            alertMessage(
              `Orders have been assigned to ${driverInfo.displayName}`,
              "success",
              10
            );
            // close driver allocation modal
            this.toggleDriverAllocationModal(false);
            // call orders list api
            this.getOrders();
            // update routes list fetchRoutes
            this.props.getRoutes();
          } else {
            renderAlertMessage(result.errors)
          }
        })
        .finally(() => {
          this.setState({
            isAssigning: false,
            madeChangesInRouteAssigner: true,
          });
        });
    } else {
       // get warehouse id from single order
       const { wh_location_id } = this.state.routes.selectedOrderRows.length > 0 ? this.state.routes.selectedOrderRows[0] : {};
      // new route
      const selectedWh = this.state.warehouses.find(wh => wh.id === wh_location_id);
      let { start_time = "", end_time = "" } = getOperationalTimings(organizationSettings, selectedWh)
      // const { route_delivery_start_time, route_delivery_end_time } =
      //   this.state.organizationSettings;
      const { dateFilter } = this.props;
      const startDeliveryTime = moment(
        `${moment(dateFilter.fromDate).format(
          "YYYY-MM-DD"
        )} ${start_time}`,
        "YYYY-MM-DD HH:mm"
      ).format();
      const endDeliveryTime = moment(
        `${moment(dateFilter.toDate).format(
          "YYYY-MM-DD"
        )} ${end_time}`,
        "YYYY-MM-DD HH:mm"
      ).format();

      const organization_id = userStore.getStateValue("selectedOrg");

      const payload = {
        organization_id,
        warehouse_id: wh_location_id,
        driver_id: selectedDriverId,
        delivery_end_date: endDeliveryTime,
        delivery_start_date: startDeliveryTime,
        customer_order_ids: this.state.routes.selectedOrderRows.map(
          (order) => order.id
        ),
        action_type: RouteLabel.actionLabels.ROUTE_CREATED,
        action_from: "DISPATCH"
      };

      if(!payload.customer_order_ids.length) {
        alertMessage("Please select orders to assign", "error", 10);
        return;
      }

      this.setState({ isAssigning: true, allocationErrors: [] });
      newDispatchRoute(payload)
        .then((result) => {
          if (result.success) {
            alertMessage(
              `Orders have been assigned to ${driverInfo.displayName}`,
              "success",
              10
            );
            // close driver allocation modal
            this.toggleDriverAllocationModal(false);
            // call orders list api
            this.getOrders();
            // update routes list fetchRoutes
            this.props.getRoutes();
          } else {
            renderAlertMessage(result.errors)
            const allErrors = Object.keys(result)
              .reduce((acc, key) => {
                if (key === "unprocessed_orders") {
                  const errors = result[key].map((error) => error);
                  return [...acc, ...errors];
                }
                return acc;
              }, [])
              .filter((i) => i.orders.length);
            this.setState({ allocationErrors: allErrors });
          }
        })
        .finally(() => {
          this.setState({
            isAssigning: false,
            madeChangesInRouteAssigner: true,
          });
        });
    }
  };
  StringToUnorderedList = (string) => {
    // use antd list
    const stringArray = string.split(",");
    return (
      <List
        size="small"
        // bordered
        dataSource={stringArray}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  };
  handleActiveMarkerUpdate = (activeMarker) => {
    this.setState({ activeMarker });
  };

  render() {
    const { actionType, unassignedOrdersInfo, showDriverAllocationModal , panelSize = {} } =
      this.props;
    const { routes, hideRoutes, driverAllocationErrors } = this.state;
    const { selectedOrderIndexes } = routes;
    const isRouteSelection = actionType === "routeSelection";
    const isSectionExpanded = panelSize.pane_2 === 1;
    const searchWidth = isRouteSelection ? "450px" : "550px";
    let orderSearchFilter = defaultSearchOrdersfilter(this.props);
    return (
      <Fragment>
        {isRouteSelection ? (
          <Card
            size="small"
            title={I18n.t("order.unallocated_orders")}
            // style={ { height: isSectionExpanded && isRouteSelection ? "calc(100vh - 132px)" : "calc(50vh - 132px)" } }
            // bodyStyle={ {
            //   height: isSectionExpanded && isRouteSelection ? "calc(100vh - 168px)" : "calc(50vh - 168px)",
            //   overflowY: "auto",
            //   padding: "5px",
            // }}
            className="borderRadius10 borderLine"
            extra={
              isRouteSelection && !showDriverAllocationModal ? (
                <Row type="flex" justify="end" gutter={8} align="middle">
                  <Col>
                    { !this.props.isUsingBetaVersion ? this.renderSearchBar() : (
                      <Row>
                        <Col>
                          { (
                            <Row type='flex' justify='end' style={ { marginBottom: '10px' } } align='middle' gutter={ 8 }>
                              <Col>
                                <Button onClick={ this.openRerrangeableModal } type='primary' size="small" icon="eye">
                                  Toggle Columns
                                </Button>
                              </Col>
                              <Col>
                                <Input
                                  size="small"
                                  placeholder="Quick Filter"
                                  onChange={ event => this.state.currentGridRef.setQuickFilter(event.target.value) }
                                />
                              </Col>
                              
                              <Col>
                                <Tooltip title="Filter">
                                <Dropdown
                                  onVisibleChange={ this.handleVisibleChange }
                                  visible={this.state.filterWindow && !this.props.showRouteAssigner}
                                  trigger={ [ "click" ] }
                                  placement={
                                    this.state.showDriverAllocationModal
                                      ? "bottomCenter"
                                      : "bottomLeft"
                                  }
                                  overlay={
                                    <div
                                      style={ {
                                        backgroundColor: "white",
                                        width: searchWidth,
                                        padding: 15,
                                        marginTop: 10,
                                        marginRight: -12,
                                      } }
                                      className="boxShadow"
                                    >
                                      <OrderFilter
                                        showOrderNoSearch={ false }
                                        showWarehouses={ true }
                                        filter={ this.state.filterPlaceHolder }
                                        onChange={ this.handleFilterPlaceHolderChange }
                                        onSearch={ this.handleSearch }
                                        onOrderNoSearch={ null }
                                        showDateFilter={ isRouteSelection ? false : true }
                                        showZones={ true }
                                        showScheduleOption={ this.props.actionType === 'currentRoute' ? false : (
                                          isRouteSelection ? false : true)
                                        }
                                        // size={"small"}
                                        onAccountsChange={ (data) => {
                                          this.setState({ accounts: data });
                                        } }
                                        // onWarehousesChange={ (data) => { this.setState({ warehouses: data }); } }
                                        onZonesChange={ (data) => {
                                          this.setState({ zones: data });
                                        } }
                                        defaultFilter={ orderSearchFilter }
                                      />
                                    </div>
                                  }
                                >
                                  <Icon
                                    type="control"
                                    onClick={ () => this.handleOnChange("filterWindow", true) }
                                  />
                                </Dropdown>
                              </Tooltip>
                              </Col>
                            </Row>
                          ) }
                        </Col>

                      </Row>

                    ) }
                  </Col>
                  {/* <Col >
                    <Button
                      size="small"
                      icon={ this.props.panelSize.pane_2 === 1 ? "minus-square" : "fullscreen" }
                      onClick={ () => this.props.onPanelClick("pane_2") }
                    >
                      { this.props.panelSize.pane_2 === 1 ? "Minimize" : "Maximize" }
                    </Button>
                  </Col> */}
               </Row>
              ) : (
                <Fragment />
              )
            }
          >
            <Row>
              {(
              <Col span={24}>
                  { this.state.appliedFiltersTags.length ? (
                  <AppliedFiltersTags
                    tags={this.state.appliedFiltersTags}
                    clearFilter={this.handleClearOrderFilter}
                    clearSearch={this.handleClearOrderFilter}
                    isSearching={this.state.isSearching}
                    searchText={this.state.filterPlaceHolder.search_order_token}
                    searchBy={
                      this.state.searchType === "byOrders"
                        ? "Order"
                        : this.state.searchType
                    }
                    updateSearchTags={this.state.updateSearchTags}
                    resetSearchTags={() => {
                      this.setState({ updateSearchTags: false });
                    }}
                  />
                ) : (
                  <Text type="secondary">
                    {I18n.t("order.filters.no_filters_applied")}
                  </Text>
                )}
              </Col>
              ) }
              <Col span={24}>{this.renderOrdersList()}</Col>
            </Row>
            {/* <Col span={ 24 }>{ this.renderOrdersList() }</Col> */}
          </Card>
        ) : (
          <Fragment>
            <Row>
              <Col xs={22} className="alignLeft">
                {this.renderSearchBar(true)}
              </Col>
              <Col xs={2}>
                
                <Row className="">
                  <Col
                    xs={24}
                    style={{ padding: "10px 10px" }}
                    className="alignRight"
                  >
                    <Button
                      size="small"
                      type="primary"
                      onClick={this.handleSave}
                      loading={this.state.isAssigning}
                      disabled={this.state.routes?.selectedOrderKeys.length === 0}
                    >
                      {I18n.t("preplan.allocate")}
                    </Button>
                  </Col>
                </Row>
              
              </Col>
            </Row>
            {this.renderOrdersList()}
          </Fragment>
        )}

        {this.state.editForm && (
          <Drawer
            placement="right"
            closable={false}
            maskClosable={false}
            onClose={this.closeEditFormModal}
            visible={this.state.editForm}
            width="90%"
          >
            <EditOrderForm
              customerDetails={this.state.orderObject}
              customerOrderId={this.state.orderObject.id}
              preferences={[]}
              onCancel={this.closeEditFormModal}
              handleSuccess={this.handleSaveSuccess}
              isNew={this.state.isNew}
            />
          </Drawer>
        )}
        {this.props.showRouteAssigner && (
          <BaseModal
            title={
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <span>{I18n.t("messages.add_orders_to_route")}</span>
                </Col>
                <Col>
                  <span className="textBold">
                    {moment(this.props.dateFilter.fromDate).format(
                      "Do MMM dddd YYYY"
                    )}
                  </span>
                </Col>
                <Col className="alignRight">
                  <div className="alignRight paddingRight20">
                  {refreshIcon(() => {
                    this.getOrders();
                    this.props.getRoutes();
                  }, true, true)}
                  </div>
                </Col>
              </Row>
            }
            closable
            onCancel={() => {
              this.setState(
                {
                  routes: {
                    selectedOrderKeys: [],
                    selectedOrderRows: [],
                    selectedOrderIndexes: [],
                  },
                  driverAllocationErrors: [],
                  appliedFiltersTags: [],
                  filterPlaceHolder: {
                    fromDate: this.props.startTime
                      ? this.props.startTime
                      : moment(),
                    toDate: this.props.endTime
                      ? this.props.endTime
                      : moment().add(2, "day"),
                    filter: "",
                    search_order_token: "",
                    sortBy: "none",
                    sortByType: "descend",
                    account_codes: [],
                    warehouse_id: this.props.showRouteAssigner
                      ? this.props.warehouse_id
                      : !_.isEmpty(this.props.route.warehouse_id)
                      ? this.props.route.warehouse_id
                      : "",
                    search_key_type: "",
                    search_key_value: "",
                    [I18n.t("order.filters.schedule_orders.type")]: I18n.t(
                      "order.filters.schedule_orders.scheduled_key"
                    ),
                    zone_ids: [],
                  },
                },
                () => {
                  this.props.changesOrdersState();
                }
              );
            }}
            visible={this.props.showRouteAssigner}
            width={"100%"}
            style={{
              top: 0,
              height: "100%",
            }}
            bodyStyle={{
              height: "calc(100vh - 50px)",
              overflowY: "scroll",
              padding: "5px 25px",
            }}
            maskClosable={false}
            className="routeAssignerModal"
          >
            <Spin spinning={this.state.inProgress}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
               <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Row gutter={[4, 4]}>
                      <Col span={24} style={{ textAlign: "center" }}>
                        { !this.props.isUsingBetaVersion ? (
                        <Row gutter={ 16 } type="flex" justify="space-between" align="middle">
                          <Col>
                            { this.renderAllocationSearchBar() }
                          </Col>
                          <Col style={{ marginRight: 40 ,marginTop: 10 }}>
                            <RecordsPerPage
                              showInfinite
                              onChange={ (val) => {
                                this.onRecordChange("recordsPerPageMax", val);
                              } }
                              onSearch={ this.onSearch }
                              value={ this.state.recordsPerPageMax }
                              defaultValue={ this.state.recordsPerPageMax }
                            />
                          </Col>
                        </Row>
                        ) : (
                          <Row type='flex' justify='end' style={ { marginBottom: '10px' } } align='middle' gutter={ 8 }>
                              <Col>
                                <Button onClick={ this.openRerrangeableModal } type='primary' size="small" icon="eye">
                                  Toggle Columns
                                </Button>
                              </Col>
                              <Col>
                                <Input
                                  size="small"
                                  placeholder="Quick Filter"
                                  onChange={ event => this.state.currentGridRef.setQuickFilter(event.target.value) }
                                />
                            </Col>
                            <Col>
                              <Dropdown
                                onVisibleChange={ this.handleVisibleChange }
                                visible={this.state.filterWindow && this.props.showRouteAssigner}
                                trigger={ [ "click" ] }
                                placement={
                                  this.state.showDriverAllocationModal
                                    ? "bottomCenter"
                                    : "bottomLeft"
                                }
                                overlay={
                                  <div
                                    style={ {
                                      backgroundColor: "white",
                                      width: searchWidth,
                                      padding: 15,
                                      marginTop: 10,
                                      marginRight: -12,
                                    } }
                                    className="boxShadow"
                                  >
                                    <OrderFilter
                                      showOrderNoSearch={ false }
                                      showWarehouses={ true }
                                      filter={ this.state.filterPlaceHolder }
                                      onChange={ this.handleFilterPlaceHolderChange }
                                      onSearch={ this.handleSearch }
                                      onOrderNoSearch={ null }
                                      showDateFilter={ isRouteSelection ? false : true }
                                      showZones={ true }
                                      showScheduleOption={ this.props.actionType === 'currentRoute' ? false : (
                                        isRouteSelection ? false : true)
                                      }
                                      // size={"small"}
                                      onAccountsChange={ (data) => {
                                        this.setState({ accounts: data });
                                      } }
                                      // onWarehousesChange={ (data) => { this.setState({ warehouses: data }); } }
                                      onZonesChange={ (data) => {
                                        this.setState({ zones: data });
                                      } }
                                      defaultFilter={ orderSearchFilter }
                                    />
                                  </div>
                                }
                              >
                                <Icon
                                  type="control"
                                  onClick={ () => this.handleOnChange("filterWindow", true) }
                                />
                              </Dropdown>
                             </Col>
                          </Row>
                        ) }
                      </Col>

                      {(
                      <Col span={24}>
                          { this.state.appliedFiltersTags.length > 0 ? (
                          <Row type="flex" justify="start">
                            <Col>
                              <AppliedFiltersTags
                                tags={this.state.appliedFiltersTags}
                                clearFilter={this.handleClearOrderFilter}
                                clearSearch={this.handleClearOrderFilter}
                                isSearching={this.state.isSearching}
                                searchText={
                                  this.state.filterPlaceHolder
                                    .search_order_token
                                }
                                searchBy={
                                  this.state.searchType === "byOrders"
                                    ? "Order"
                                    : this.state.searchType
                                }
                                updateSearchTags={this.state.updateSearchTags}
                                resetSearchTags={() => {
                                  this.setState({ updateSearchTags: false });
                                }}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <Text type="secondary">
                            {I18n.t("order.filters.no_filters_applied")}
                          </Text>
                        )}
                      </Col>
                      ) }
                    </Row>
                  </Col>
                  { (this.state.currentView === 'map' || this.state.currentView === 'both') && (
                    <Col span={12}>
                      {this.state.orderInfo.length > 0 && (
                        <Row>
                          <Row>
                            {!driverAllocationErrors.length  ? (
                              <Col xs={24}>
                                <Alert
                                  description={I18n.t(
                                    "messages.select_orders_from_same_warehouse"
                                  )}
                                  type="info"
                                  showIcon
                                  banner
                                />
                              </Col>
                            ) : (
                              <Col xs={24}>
                                <Alert
                                  description={this.buildErrorsDescription(
                                    driverAllocationErrors
                                  )}
                                  type="error"
                                  showIcon
                                  banner
                                />
                              </Col>
                            )}
                          </Row>
                        </Row>
                      )}
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={ this.state.currentView == 'both' ? 16 : 0 }
                  type="flex"
                >
                  { (this.state.currentView === "list" || this.state.currentView == 'both') && (
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ this.state.currentView == 'both' ? 12 : 24 } xl={ this.state.currentView == 'both' ? 12 : 24 } style={{ minHeight: "calc(100vh - 300px)"}}>
                    {this.renderOrdersList({ x: "max-content", y: "calc(100vh - 300px)" })}
                  </Col>
                  ) }
                  { (this.state.currentView === "map" || this.state.currentView == 'both') && (
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ this.state.currentView == 'both' ? 12 : 24 } xl={ this.state.currentView == 'both' ? 12 : 24 } style={ { height: "calc(100vh - 300px)" } }>
                      { this.props.routes.length > 0 && (
                        <div
                          className="alignRight paddingRight10"
                          style={ {
                            position: "absolute",
                            top: "5px",
                            right: "0px",
                            zIndex: 9,
                          } }
                        >
                          <Button
                            size="small"
                            type="primary"
                            icon={ hideRoutes ? "eye" : "eye-invisible" }
                            onClick={ () => {
                              this.setState({ hideRoutes: !hideRoutes });
                            } }
                          >
                            { hideRoutes ? "Show Drivers" : "Hide Drivers" }
                          </Button>
                        </div>
                      ) }
                      <IncorrectOrdersMap
                        activeMarker={ this.state.activeMarker }
                        containerElement={ <div style={ { height: "100%", width: "100%" } } /> }
                        driversInfo={ this.props.availableDriversInfo }
                        fitOnce={ true }
                        googleMapURL={ `https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places` }
                        handleActiveMarkerUpdate={ this.handleActiveMarkerUpdate }
                        loadingElement={ <Spin spinning={ true } /> }
                        mapElement={ <div style={ { height: "100%", width: "100%" } } /> }
                        orderInfo={ this.processOrderInfo(this.state.total_orders_data) }
                        // orderInfo={ this.state.orderInfo.map((order) => {
                        //   return {
                        //     customer_order_id: order.id,
                        //     customer_order_number: order.customer_order_number,
                        //     customer_address: order.customer_address,
                        //   };
                        // }) }
                        pathName={ this.props.location.pathname }
                        routes={ !hideRoutes ? this.props.routes : [] }
                        rowKeys={ selectedOrderIndexes }
                        selectedOrderKeys={ unassignedOrdersInfo.selectedKeys || [] }
                        showNumbers
                        showRouteSummary={ this.props.showRouteSummary }
                        isMilitaryTime={ this.state.isMilitaryTime }
                      // handleMarkerSelection={ (index) => this.handleMarkerSelection(index , selectedOrderIndexes) }
                      />
                    </Col>
                  ) }
                </Row>
                <Row className="buttonsList">
                  <Col>
                    <Button
                      icon="close"
                      // type="danger"
                      onClick={() => this.props.changesOrdersState()}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      icon="usergroup-add"
                      onClick={() => this.toggleDriverAllocationModal(true)}
                      disabled={
                        selectedOrderIndexes.length === 0 ||
                        driverAllocationErrors.length > 0
                      }
                    >
                      Allocate Driver
                    </Button>
                  </Col>
                  <Col>
                    <ViewToggleButton
                      initialView={ this.state.currentView }
                      onViewToggle={ this._handleViewToggle }
                      viewOptions={ viewOptions }
                      buttonSize="default"
                    />
                  </Col>
                </Row>
              </div>
            </Spin>
          </BaseModal>
        )}

        {this.state.showDriverAllocationModal && (
          <BaseModal
            title="Allocate Driver"
            closable
            onCancel={() => this.toggleDriverAllocationModal(false)}
            width="40%"
            className="driverAllocationModal"
            visible={this.state.showDriverAllocationModal}
            maskClosable={false}
          >
            <Row>
              <Col className='selecetdOrderPanel'>
                <FormItem label={`${I18n.t("general.Driver")}`}>
                  <DriverSelect
                    data={this.props.driversInfo}
                    onChange={this.handleDriverChange}
                    screenFrom="dispatch"
                  />
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    className="marginTop10"
                  >
                    <Panel
                      header="Selected Orders"
                      key="1"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ maxHeight: 70, overflowY: "auto" }}>
                        {this.state.routes.selectedOrderRows
                          .map((order) => order.customer_order_number)
                          .join(", ")}
                      </p>
                    </Panel>
                  </Collapse>
                </FormItem>
              </Col>
              <Row className="buttonsList">
                <Col>
                  <Button
                    icon="close"
                    // type="danger"
                    onClick={() => this.toggleDriverAllocationModal(false)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon="check"
                    onClick={() => {
                      this.handleAllocation();
                    }}
                    loading={this.state.isAssigning}
                    disabled={
                      !this.state.allocatedDriver ||
                      this.state.invalidDriverAllocation
                    }
                  >
                    Allocate
                  </Button>
                </Col>
              </Row>
              {/* if invalid allocation show error */}
              {this.state.invalidDriverAllocation && (
                <Col>
                  <Alert
                    // description="Select Driver from same Warehouse"
                    description={I18n.t(
                      "messages.Select_Driver_from_same_Warehouse"
                    )}
                    type="error"
                    banner
                  />
                </Col>
              )}
              {this.state.allocationErrors.length > 0 && (
                <Alert
                  description={this.StringToUnorderedList(
                    renderDriverAllocationErrors(this.state.allocationErrors)
                  )}
                  type="error"
                  banner
                />
              )}
            </Row>
          </BaseModal>
        )}
      </Fragment>
    );
  }
}

const SearchOrdersComponent = withRouter((props) => {
  const { orgSettings : organizationSettings , accountsExceeded  }= useContext(OrgContext);
  const warehouseFilter = useContext(WarehouseContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.SEARCH_ORDERS
  // const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const recordsPerPage = 4000
  const { orderConfig: { orderTypeKeysForFilter = [] } = {} } = useContext(OrderConfigContext);
  const perPageKeyMax = ScreenKeys.SEARCH_ORDERS;
  const recordsPerPageMax = _.get(userContext, `currentUser.per_page_saved.${perPageKeyMax}`, 400);
  const perPageKeyMin = ScreenKeys.SEARCH_ORDERS_MINIMIZED;
  const recordsPerPageMin = _.get(userContext, `currentUser.per_page_saved.${perPageKeyMin}`, AppConfig.ordersPerPage);

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);
  return (
    <SearchOrders
      organizationSettings={organizationSettings}
      warehouseFilter={warehouseFilter}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      accountsExceeded={accountsExceeded}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      recordsPerPageMax={ recordsPerPageMax }
      recordsPerPageMin={ recordsPerPageMin }
      perPageKey={ perPageKey }
      orderTypeKeysForFilter={orderTypeKeysForFilter}
      {...props}
    />
  );
});

export default SearchOrdersComponent;
