import React, {
  Fragment,
  Component,
  useContext,
  useState,
  useEffect,
} from "react";
import { Button, Col,Drawer, Form, Icon, Input, Popconfirm, Row, Select, Spin, Tooltip } from "antd";
import CustomerLogList from "../CustomerLogs/CustomerLogList";
import AppConfig from "../../config/AppConfig";
import { RecordsPerPage } from "../orders/RecordsPerPage";

import { Search, Dropdown, message } from "../../common/UIComponents";
import { alertMessage, formatDate, isEmpty } from "../../common/Common";
import {
  base64ToExcel,
  checkServiceExistance,
  convertToTags,
  limitString,
  renderAlertMessage,
  responseTOCsv,
} from "../../helpers/common";
import moment from "moment";
import CustomerLogFilter from "../CustomerLogs/CustomerLogFilter";
import { fetchLocations } from "../../api/LocationsApi";
import AppliedFiltersTags from "../../containers/AppliedFiltersTags";
import { UserContext } from "../../context/UserContext";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { DisplaySettingsContext } from "../../context/DisplaySettings";
import { DriverLogs } from "../../api/CustomerLogs";
import DriverLogList from "./DriverLogList";
import { ScreenKeys } from "../../containers/constants";
import _ from "lodash";
import I18n from "../../common/I18n";
import PodReportList from "../reports/pod_reports/PodReportList";
// import ReportFilter from "../reports/ReportFilter";
import PodReportFilter from "../reports/pod_reports/PodReportFilter";
import ExportToEmail from "../reports/ExportToEmail";
import { PodReportApi, SendPodEmail } from "../../api/PodReportApi";
import { getSortingKey } from "../../helpers/orders";
import { fetchOrgAccounts } from "../../api/Account";
import BaseModal from "../BaseModal";
import { SendInvoiceEmail, getContactEmails } from "../../api/Billing";
import { validateEmailList } from "../billing_screen/helpers";
import PODEmailsModal from "../Tasks/PODEmailsModal";
import AddContactByButton from "../configurations/Contacts/AddContactByButton";

const defaultFilter = {
  fromDate: moment().subtract(1, "day"),
  toDate: moment(),
  warehouse_id: [],
  accountCodes: [],
  as_export: false,
  search_order_token: "",
  searchKeyPressed: false,
  sortBy: "none",
  sortByType: "descend",
  orderType: ""
};

const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

class PodReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pod_reports: [],
      appliedFiltersTags: [],
      warehouses: [],
      organization: "",
      showEmailModal: false,
      customerOrderId: null,
      accountId: null,
      invoiceEmails: [],
      contacts: [],
      isEmailLoading: false,
      isSendingMail: false,
      description: "",
      warehouse_id: [],
      organization_id: "",
      inProgress: false,
      listProgress: false,
      accounts: [],
      recordsPerPage: props.recordsPerPage,
      pagination: { current_page: 1 },
      filterWindow: false,
      searchText: "",
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      emailProgress: false,
    };
    this.tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  }

  componentDidMount() {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    this.setState(
      (prevState) => ({
        ...prevState,
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          warehouseIds: currentWhId,
        },
      }),
      () => {
        this.getWarehouses();
        this.getAccounts();
        this.updateWhFilter();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  }
  
  getEmails = (record) => {
    const accountId = this.state.accountId;
    this.setState({isEmailLoading:true});
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        this.setState({
          contacts: result.contact_emails || [],
          isEmailLoading: false,
        });
      } else {
        alertMessage(result.errors[0], "error", 10);
        this.setState({ isEmailLoading: false, contacts: [] });
      }
    });
  };

  toggleEmailModal = (record) => {
    this.setState(
      {
        showEmailModal: true,
        customerOrderId: record.id,
        accountId: record.account_id,
      },
      () => {
        this.getEmails(record);
      }
    );
  };

  addInvoiceEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ invoiceEmails: validEmails });
  };


  handleSendingMails = () => {
    const { invoiceEmails, description, customerOrderId } = this.state;

    this.setState({ isSendingMail: true });

    SendPodEmail(invoiceEmails, description, customerOrderId)
      .then((result) => {
        if (result.success) {
          alertMessage(result.message, "success", 5);
          this.setState({
            showEmailModal: false,
            customerOrderId: null,
            invoiceEmails: [],
            description: "",
          });
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        this.setState({ isSendingMail: false });
      });
  };

  handleContactSaved = (contact) => {
    if (contact?.email) {
      this.setState((prevState) => ({
        invoiceEmails: [...prevState.invoiceEmails, contact.email],
      }));
    }
    this.getEmails();
  };



  renderEmailModal = () => {
    return (
      <Drawer
        title= {<h4 style={{marginLeft:'-16px'}}>Send Pod Email</h4>}
        placement="right"
        width="40%"
        onClose={() =>
          this.setState({
            showEmailModal: false,
            invoiceEmails: [],
            customerOrderId: null,
            description: "",
          })
        }
        visible={this.state.showEmailModal}
        maskClosable={false}
      >
        <Spin spinning={this.state.isEmailLoading}>
          <Row className="tagsInput emailForm" type="flex" align="middle" gutter={8}>
            <Col span={18}>
              <FormItem label="Email">
                <Select
                  mode="tags"
                  value={this.state.invoiceEmails}
                  onChange={this.addInvoiceEmail}
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  placeholder="Enter Emails"
                >
                  {this.state.contacts.map((opt) => (
                    <Option key={opt}>{opt}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={6} className="alignRight">
              <AddContactByButton
                accountId={this.state.accountId}
                saveOn={this.handleContactSaved}
                size="medium"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label="Remarks">
                <TextArea
                  rows={4}
                  placeholder="Description"
                  className="sop-form-textArea"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="center" gutter={4}>
            <Col>
              <Button
                type="danger"
                onClick={() =>
                  this.setState({
                    showEmailModal: false,
                    customerOrderId: null,
                    description: "",
                  })
                }
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                onConfirm={this.handleSendingMails}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" loading={this.state.isSendingMail}>
                  Send
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Spin>
      </Drawer>
    );
  };


  getEmails = (record) => {
    const accountId = this.state.accountId;
    this.setState({isEmailLoading:true});
    getContactEmails(accountId).then((result) => {
      if (result.success) {
        this.setState({
          contacts: result.contact_emails || [],
          isEmailLoading: false,
        });
      } else {
        alertMessage(result.errors[0], "error", 10);
        this.setState({ isEmailLoading: false, contacts: [] });
      }
    });
  };

  toggleEmailModal = (record) => {
    this.setState(
      {
        showEmailModal: true,
        customerOrderId: record.id,
        accountId: record.account_id,
      },
      () => {
        this.getEmails(record);
      }
    );
  };

  addInvoiceEmail = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ invoiceEmails: validEmails });
  };


  handleSendingMails = () => {
    const { invoiceEmails, description, customerOrderId } = this.state;

    this.setState({ isSendingMail: true });

    SendPodEmail(invoiceEmails, description, customerOrderId)
      .then((result) => {
        if (result.success) {
          alertMessage(result.message, "success", 5);
          this.setState({
            showEmailModal: false,
            customerOrderId: null,
            invoiceEmails: [],
            description: "",
          });
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        this.setState({ isSendingMail: false });
      });
  };

  handleContactSaved = (contact) => {
    if (contact?.email) {
      this.setState((prevState) => ({
        invoiceEmails: [...prevState.invoiceEmails, contact.email],
      }));
    }
    this.getEmails();
  };
  

  
  renderEmailModal = () => {
    return (
      <Drawer
        title= {<h4 style={{marginLeft:'-16px'}}>Send Pod Email</h4>}
        placement="right"
        width="40%"
        onClose={() =>
          this.setState({
            showEmailModal: false,
            invoiceEmails: [],
            customerOrderId: null,
            description: "",
          })
        }
        visible={this.state.showEmailModal}
        maskClosable={false}
      >
        <Spin spinning={this.state.isEmailLoading}>
          <Row className="tagsInput emailForm" type="flex" align="middle" gutter={8}>
            <Col span={18}>
              <FormItem label="Email">
                <Select
                  mode="tags"
                  value={this.state.invoiceEmails}
                  onChange={this.addInvoiceEmail}
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  placeholder="Enter Emails"
                >
                  {this.state.contacts.map((opt) => (
                    <Option key={opt}>{opt}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={6} className="alignRight">
              <AddContactByButton
                accountId={this.state.accountId}
                saveOn={this.handleContactSaved}
                size="medium"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label="Remarks">
                <TextArea
                  rows={4}
                  placeholder="Description"
                  className="sop-form-textArea"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="center" gutter={4}>
            <Col>
              <Button
                type="danger"
                onClick={() =>
                  this.setState({
                    showEmailModal: false,
                    customerOrderId: null,
                    description: "",
                  })
                }
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                onConfirm={this.handleSendingMails}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" loading={this.state.isSendingMail}>
                  Send
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Spin>
      </Drawer>
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses)) {
      {
        const { warehouseFilter } = this.props;
        const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
          ? warehouseFilter.selectedWarehouses
          : warehouseFilter?.selectedWarehouses
            ? [warehouseFilter.selectedWarehouses]
            : null;

        this.setState(
          (prevState) => ({
            ...prevState,
            filterPlaceHolder: {
              ...prevState.filterPlaceHolder,
              warehouseIds: currentWhId,
            },
          }),
          () => {
            this.getAccounts();
            this.updateWhFilter();
            this.computeTags(this.state.filterPlaceHolder);
          }
        );
      }
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }
  getAccounts = () => {
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState(
          {
            accounts: result.accounts || [],
          }
        );
      } else {
        this.setState({
          accounts: [],
        });
      }
    });
  };

  getPodReport = (export_option = null, mails = [], cb = null) => {
    const { filterPlaceHolder, pagination, accounts } = this.state;
    const { orgData } = this.props;

    const page = this.tableOptions.pagination.current;
    const perPage = this.state.recordsPerPage; // removed AppConfig.ordersPerPage to dynamically adjust record length
    const orderNumber = "";
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const searchKeyType = "";
    const searchKeyValue = "";
    const scheduleOrdersType = "SCHEDULED";
    const scheduleDayFilter = "";
    const isSaveNsearchCalled = false;
    // this.getOrdersStatus();
    const payload = {
      search_type: "",
      status: "COMPLETED",
      fromDate: formattedFromDate,
      todate: formattedToDate,
      scheduleDayFilter,
      scheduleOrdersType,
      page,
      perPage,
      orderNumber,
      sortBy: filterPlaceHolder.sortBy,
      sortByType: filterPlaceHolder.sortByType || "ascend",
      account_codes: filterPlaceHolder?.accountCodes ? accounts.filter(rec => filterPlaceHolder.accountCodes.includes(rec.code)).map(rec => rec.id) : [],
      warehouse_id: filterPlaceHolder.warehouse_id,
      searchKeyType,
      searchKeyValue,
      globalSearch: false,
      zone_ids: [],
      order_type: filterPlaceHolder.orderType,
      saveNSearch: "",
      vehicle_type: "",
    };
    if (export_option) {
      message.loading("Export to EXCEL in progress..", 0);
      payload.export_option = export_option;
      // payload.mails = mails?.length ? mails.join(",") : "";
      // payload.total_count = pagination?.total_count || 0;
    }
    else {
      this.setState({ listProgress: true });
    }
    PodReportApi.fetchOrders(payload).then((result) => {
      if (result.success) {
        if (export_option) {
          this.setState({ listProgress: false, emailProgress: false });
          const response = result.file;
          if (response) {
            message.destroy();
            const fileName =
            result.fileName || `${orgData?.currentOrg?.code}_PODReport`;
            base64ToExcel(response, fileName, "EXCEL");

          } else if(mails?.length){
            alertMessage((result.message || "File will be emailed to your inbox"),"success",5)
            if(cb){
              cb()
            }
          }
        } else {
          // const filter = this.state.filter;
          message.destroy();
          const orders = result.orders ? result.orders : [];
          this.setState({
            pod_reports: orders,
            pagination: result.pagination,
            listProgress: false,
            emailProgress: false,
          });
        }
      } else {
        renderAlertMessage(result.errors || I18n.t('messages.no_record_found'))
        if (export_option) {
          this.setState({
            listProgress: false,
            emailProgress: false,
          });
        } else {
          this.setState({
            pod_reports: [],
            listProgress: false,
            pagination: {},
            emailProgress: false,
          });
        }
      }
    });
  };

  clearFilter = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          fromDate: moment().subtract(
            1,
            "day"
          ),
          toDate: moment(),
          orderType: "",
          warehouse_id: currentWhId,
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "warehouse_id":
        filterVariable.warehouse_id = currentWhId;
        break;
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "orderType":
        filterVariable.orderType = defaultFilter.orderType;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      this.handleSearch();
    });
  };

  computeTags = (filterPlaceHolder) => {
    const { warehouseFilter } = this.props;
    const { warehouses } = warehouseFilter;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const keys = ["fromDate", "toDate", "warehouse_id", "accountCodes", "orderType"];
    const { orderConfig } = this.props.orderConfigContext;
    const { orderTypeKeysForFilter = {} } = orderConfig;
    const filteredData = {
      fromDate: {
        label: "Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment().subtract(1, "day").format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouse_id: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouse_id.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouse_id");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouse_id?.length === 1 &&
          filterPlaceHolder?.warehouse_id[0] === currentWhId[0]
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder?.accountCodes?.includes(account.code)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },
      orderType: {
        label: "Order Type",
        value: orderTypeKeysForFilter[filterPlaceHolder.orderType],
        onClose: () => {
          this.handleOnFilterRemoval("orderType");
        },
        closeable: true,
      },
    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  updateWhFilter = () => {
    const { warehouseFilter } = this.props;
    const filterVals = this.state.filterPlaceHolder;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.handleFilterPlaceHolderChange(
      {
        ...filterVals,
        warehouse_id: currentWhId,
      },
      () => {
        this.getPodReport();
      }
    );
  };

  onSearch = (value) => {
    console.log("onSearch", value);
  };

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
        // alertMessage(result.errors[0], "error", 10);
      }
    });
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage;
    }
    this.tableOptions.pagination.current = 1;
    this.setState({ recordsPerPage: value }, () => {
      this.tableOptions.pagination.current = 1;
      this.getPodReport();
      updateRecordsPerPage(ScreenKeys.POD_REPORT, value);
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getPodReport();
    } else if (sorter && !isEmpty(sorter.field)) {
      const filterVals = this.state.filterPlaceHolder;
      this.tableOptions.pagination.current = 1;
      this.handleFilterPlaceHolderChange(
        {
          ...filterVals,
          sortBy: sorter.columnKey,
          sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.getPodReport();
        }
      );
    }
    // this.getPodReport();
  };

  tablePagination = () => {
    const { pagination, recordsPerPage } = this.state;
    return {
      total: pagination ? pagination.total_count : 0,
      current: pagination ? pagination.current_page : 1,
      pageSize: pagination
        ? pagination.per_page || recordsPerPage
        : recordsPerPage,
    };
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  onTagClose = (tagsObj) => {
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          ...tagsObj,
        },
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.getPodReport();
      }
    );
  };

  setDefaultvalues = () => {
    setFromDate(moment().subtract(1, "day"));
    setToDate(moment());
  };

  handleExport = (mails = [], cb = null) => {
    this.setState({
       emailProgress: true, 
    })
    this.getPodReport("xlsx", mails, cb);
    // this.setState({ inProgress: true });
    // PodReportApi.fetch({
    //   ...this.state.filterPlaceHolder,

    // }).then((result) => {
    //   if (result.success) {

    //   } else {
    //     alertMessage(result.message, "error", 5);
    //     this.setState({ inProgress: false });
    //   }
    // });
  };

  handleSearch = (filter = {}) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, filter);
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getPodReport();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  _renderSearchBar = () => (
    <Dropdown
      disabled={this.state.emailProgress}
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 550,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <PodReportFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            warehouseFilter={this.props.warehouseFilter}
            onCancel={this.handleVisibleChange}
            orgData={this.props.orgData}
            showAmountCheck={false}
            orderConfigContext={this.props.orderConfigContext}
          />
        </div>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <span style={{ marginRight: "5px" }}>Filter</span>
        <Icon
          style={{
            color: "black",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "5px",
          }}
          type="filter"
        /> */}
        <Button className="cursorPointer"
          shape="round">
          Filter
          <Icon type="caret-down" />
        </Button>
      </div>
    </Dropdown>
  );

  _renderHeader = () => (
    <Row className="page-header" type="flex" justify="space-between">
      <Col>{I18n.t("pod_report.title")}</Col>
      <Col className="headerOptionDiv">
        {this._renderSearchBar()}
      </Col>
    </Row>
  );

  resetSearchTags = () => {
    this.setState({ updateSearchTags: false });
  };
  combinedKey = (record, index) => `${record.id}-${index}`;

  render() {
    const {
      customerLogData,
      pod_reports,
      recordsPerPage,
      filterPlaceHolder,
      updateSearchTags,
    } = this.state;
    return (
      <Spin
        spinning={this.state.inProgress || this.state.listProgress}
        delay={1000}
      >
        <div className="content-outer">
          <div className="content">
            <this._renderHeader />

            <Row
              type="flex"
              justify="space-between"
              style={{
                marginBottom: 10,
                marginTop: 10
              }}>
              <Col >
            <Row type="flex" justify="space-between">
              <Col >
                <AppliedFiltersTags
                  tags={this.state.appliedFiltersTags}
                  clearFilter={() => this.clearFilter()}
                  isSearching={filterPlaceHolder.searchKeyPressed}
                  searchText={filterPlaceHolder.search_order_token}
                  searchBy={"Order"}
                  updateSearchTags={updateSearchTags}
                  resetSearchTags={this.resetSearchTags}
                  clearSearch={this.clearSearch}
                />
              </Col>
            </Row>
              </Col>
              <Col >
            <Row type="flex" gutter={15} justify="space-between">
              <Col>
                  {/* <span className="filterSetIcon marginRight10">
                  <img
                  src={excelIcon}
                  className="cursorPointer"
                  onClick={this.handleExport}
                  style={{
                  width: 24,
                  marginTop: "-2px",
                  }}
                  />
                  </span> */}
                  <Button
                    className="cursorPointer"
                      disabled={this.state.emailProgress || pod_reports?.length === 0}
                    type="primary"
                    size="small"
                      loading={this.state.emailProgress}
                    onClick={this.handleExport} shape="round"
                  >
                      {this.state.emailProgress ? "Exporting..." : <span>Export EXCEL <Icon type="file-excel" theme="filled" /></span>}
                  </Button>
                  {" "}
                {/* {pod_reports?.length > 0 && (
                  <span className="filterSetIcon marginRight10">
                    <ExportToEmail
                      displayType="image"
                      limit={100}
                      availableRecords={this.state.pagination?.total_count || 0}
                      handleReportExport={this.handleExport}
                      handleSendingMails={this.handleExport}
                      loading={this.state.emailProgress}
                    />
                  </span>
                )} */}
              </Col>
              <Col>
                <RecordsPerPage
                  onChange={this.onRecordChange}
                  onSearch={this.onSearch}
                  value={recordsPerPage}
                  defaultValue={recordsPerPage}
                />
              </Col>
            </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <PodReportList
                  rowKey={this.combinedKey}
                  data={pod_reports}
                  renderEmailModal={this.toggleEmailModal}
                  scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
                  pagination={this.tablePagination()}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    this.onTableChange(pagination, filter, sorter, currentTable)
                  }
                />
              </Col>
              {this.state.showEmailModal && this.renderEmailModal()}
            </Row>
          </div>
        </div>
      </Spin>
    );
  }
}

export const PodReport = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });
  const userContext = useContext(UserContext);
  const { currentOrg } = userContext;
  const orderConfigContextData = useContext(OrderConfigContext);

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);

  const perPageKey = ScreenKeys.POD_REPORT;
  const recordsPerPage = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  return (
    <PodReportComponent
      warehouseFilter={warehouseFilter}
      orgData={orgData}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      currentOrg={currentOrg}
      userContext={userContext}
      orderConfigContext={orderConfigContextData}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default PodReport;
