import _, { isEmpty, result, set, template } from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { TemplatesApi } from "./Api";
import OrderAccAccessorial from "../orders/OrderAccAccessorial";
import {
  fetchAccountAccessorials,
  fetchAccountConfigs,
} from "../../api/Account";
import userStore from "../../stores/UserStore";
import TemplateAccessorial from "../orders/TemplateAccessorial";
import { OrgContext } from "../../context/OrgContext";
import { Col, Row, Button, Card, Spin, Skeleton } from "antd";
import {
  fetchAccessorialDetails,
  fetchAccessorialsComponents,
  fetchAccountAccessorials as fetchAccountComponentAccessorials,
} from "../../api/Accessorials";
import { fetchTechnicians } from "../../api/UsersApi";
import {
  renderAlertMessage,
  updatePicSettingsWithBol,
} from "../../helpers/common";
import { alertMessage, randomNumber } from "../../common/Common";
import { UserContext } from "../../context/UserContext";
import I18n from "../../common/I18n";
import { FlatRateForm } from "./FlatRateForm";
function TemplateAccessorialForm(props) {
  const { activeRecordId, drivers } = props;
  const [locations, setLocations] = useState([]);
  const [template, setTemplate] = useState([]);
  const [orgComponents, setOrgComponents] = useState([]);
  const [displayComponents, setDisplayComponents] = useState([]);
  const [noParamComponents, setNoParamComponents] = useState([]);
  const [inProgress, setInprogress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [technicians, setTechnicians] = useState([]);
  const [slotConfig, setSlotConfig] = useState({});
  const [accountComponentAccessorial, setAccountComponentAccessorial] =
    useState([]);
  const [accessorialInfo, setAccessorialInfo] = useState({});
  const [saveKey, setSaveKey] = useState(null);
  const userContextData = useContext(UserContext);
  const { currentUser } = userContextData;

  const [accountConfig, setAccountConfig] = useState({});
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    if (activeRecordId && props.activeKey === 'accessorial') {
      // edit
      // make api call
      getTemplateData();
    }
  }, [activeRecordId, props.activeKey]);

  const getTemplateData = () => {
    TemplatesApi.fetchOne(activeRecordId).then((response) => {
      if (response.success) {
        const { template: data = {} } = response;
        const accInfo = {};
        if (data?.locations?.length) {
          data?.locations.forEach((loc, index) => {
            loc.id = randomNumber();
            data.locations[index].id = loc.id;
            accInfo[loc.id] = {
              isUpdated: false,
              accessorial: loc.template_accessorials || [],
            };
          });
        }
        setAccessorialInfo(accInfo);
        setTemplate(data);
        getUsers();
        getOrgComponents();
      }
    });
  };

  useEffect(()=>{
    if(!isEmpty(template)){
      getAccountConfigs();
    }
  }, [template])

  const getUsers = () => {
    setInprogress(true);
    fetchTechnicians().then((result) => {
      if (result.success) {
        const data = result.users || [];
        setTechnicians([...data]);
        setInprogress(false);
      } else {
        setInprogress(false);
        setTechnicians([]);
      }
    });
  };

  const getOrgComponents = () => {
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const orgComponents = result.components || [];
        const displayComponents = [];
        const noParamComponents = [];
        orgComponents.forEach((component) => {
          if (
            component.component_params &&
            component.component_params.length > 0
          ) {
            const componentParams = component.component_params || [];
            const visibleParams = componentParams.filter((param) =>
              param.visible_to.includes("DISPATCHER")
            );
            if (visibleParams.length > 0) {
              displayComponents.push(component.code);
            } else {
              noParamComponents.push(component.code);
            }
          } else {
            noParamComponents.push(component.code);
          }
        });

        setDisplayComponents(displayComponents);
        setNoParamComponents(noParamComponents);
        setOrgComponents(orgComponents);
      }
    });
  };

  useEffect(() => {
    getAccountComponentAccessorials();
  }, [orgComponents]);

  useEffect(() => {
    if (accessorialInfo && saveKey) {
      const isAllUpdated = Object.keys(accessorialInfo).filter(
        (key) => accessorialInfo[key].isUpdated
      );
      if (isAllUpdated.length === Object.keys(accessorialInfo).length) {
        handleSave(accessorialInfo);
      }
    }
  }, [accessorialInfo]);

  useEffect(() => {
    if (template?.locations) {
      const values = template.locations.map(location => {
          return {
              checkBoxValue: location?.recurring_quotations?.configuration?.include_fuel_surcharge || "false",
              amount: location?.recurring_quotations?.flat_rate || "0"
            }
        });
  
      setFormValues(values);
    }
  }, [template]);

  const handleFlatRateChange = (index, field, value) => {
    const newFormValues = [...formValues];
    if (!newFormValues[index]) {
      newFormValues[index] = {};
    }
    newFormValues[index][field] = value;
    setFormValues(newFormValues);
  };

  const handleSave = () => {
    setIsLoading(true);
    const created_by = currentUser.id;
    const locations = template.locations.map((loc, index) => {
      const accessorial = accessorialInfo?.[loc.id]?.accessorial || [];
      const recurring_quotations = {
        configuration: 
          {
            include_fuel_surcharge: !_.isEmpty(formValues[index])
              ? formValues[index].checkBoxValue.toString()
              : "false",
          },
        flat_rate: formValues[index].amount.toString(),
      };
  
      // If loc.recurring_quotations already has some values, update them
      if (loc.recurring_quotations && loc.recurring_quotations.length > 0) {
        loc.recurring_quotations = loc.recurring_quotations.map((quotation, index) => {
          return recurring_quotations[index] ? recurring_quotations[index] : quotation;
        });
      } else {
        // If loc.recurring_quotations is empty, assign new values
        loc.recurring_quotations = recurring_quotations;
      }
  
      delete loc.id;
      const template_accessorials = accessorial.map(item => ({ ...item, created_by }));
      return { ...loc, template_accessorials };
    });
    const apiPayload = {
      organization_id: template.organization_id,
      template_name: template.template_name,
      locations,
    };

    TemplatesApi.update(activeRecordId, apiPayload)
      .then((result) => {
        if (result.success) {
          alertMessage("Details updated successfully", "success", 10);
          setSaveKey(null);
          getTemplateData();
        } else {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        setIsLoading(false);
       
      });
  };

  const getAccountConfigs = () => {
    if (template.account_id) {
      fetchAccountConfigs(template.account_id, template.organization_id).then(
        (result) => {
          if (result.success) {
            const config = {};
            const accountConfigurations = result.account_configurations || [];
            accountConfigurations.forEach((setting) => {
              config[setting.setting_name] = setting.setting_value;
            });
            if (
              !isEmpty(config.pod_pic_settings) &&
              _.isString(config.pod_pic_settings) &&
              config.pod_pic_settings === "[]"
            ) {
              config.pod_pic_settings = [];
            }
            config.pod_pic_settings = updatePicSettingsWithBol(
              config.pod_pic_settings
            );
            if (isEmpty(config.communication_preference)) {
              config.communication_preference = "SMSES";
            }

            setAccountConfig(config);
          } else {
            renderAlertMessage(result.errors);
            setAccountConfig({});
          }
        }
      );
    }
  };

  const updateAccessorail = (accessorial, id) => {
    const data = { ...accessorialInfo };
    if (!data?.[id]) {
      data[id] = {
        isUpdated: true,
        accessorial: [],
      };
    }
    data[id].accessorial = [...accessorial];
    data[id].isUpdated = true;
    setAccessorialInfo(data);
  };

  const renderFlatRate = (typeOfOrder, currentLocation = null,index) => {
    return (
      <>
        <FlatRateForm
        typeOfOrder={typeOfOrder}
        currentLocation={currentLocation}
        activeRecordId={activeRecordId}
        key={index}
        checkBoxValue={formValues[index]?.checkBoxValue}
        amount={formValues[index]?.amount}
        onChange={(field, value) => handleFlatRateChange(index, field, value)}
        />
        {/* {template?.locations?.length ===1 ? renderFlatRateButton() : null} */}
      </>
    );
  }

  const renderAccessorial = (typeOfOrder, currentLocation = null) => {
    const currentOrder = {};
    const isMilitaryTime = organizationSettings.is_military_time == "true";
    const template_accessorials = currentLocation?.template_accessorials || [];

    return (
      <>
      <TemplateAccessorial
        account_id={template.account_id}
        currentLocation={currentLocation}
        config={accountConfig}
        customer_order_id={currentOrder.id}
        org_id={userStore.getStateValue("selectedOrg")}
        //handleStateOnchange={this.handleStateOnchange}
        onCancel={() => {}}
        handleOnChange={() => {}}
        drivers={drivers}
        isMilitaryTime={isMilitaryTime}
        required_accessorials={
          currentOrder?.required_accessorials
            ? currentOrder.required_accessorials
            : []
        }
        orgComponents={orgComponents}
        displayComponents={displayComponents}
        noParamComponents={noParamComponents}
        technicians={technicians}
        orgId={template.organization_id}
        accountComponentAccessorial={accountComponentAccessorial}
        slotConfig={slotConfig}
        accessorials={template_accessorials}
        locations={template.locations || []}
        saveKey={saveKey}
        updateAccessorail={updateAccessorail}
      />
      {template?.locations?.length && renderButton()}
      </>
    );
  };

  const getDeifinitions = (accessorial) => {
    if (!isEmpty(accessorial)) {
      const components = accessorial.components || [];
      const accComponents = components.map(
        (component) => component.component_code
      );
      const componentDefs = orgComponents.filter(
        (component) =>
          accComponents.includes(component.code) &&
          displayComponents.includes(component.code)
      );
      return componentDefs;
    }
    return [];
  };

  const getSlotDetails = (id, reqCode = "") => {
    getAccessorialDetails(id, reqCode);
  };

  const getAccessorialDetails = (id, reqCode) => {
    fetchAccessorialDetails(id).then((result) => {
      if (result.success) {
        const slotConfigData = { ...slotConfig };
        const currentAccessorial = result.accessorial || [];
        const componentParams = currentAccessorial
          ? currentAccessorial.component_params
          : [];
        const slotParams = !isEmpty(reqCode)
          ? componentParams.filter((param) => param.component_code === reqCode)
          : [...componentParams];
        slotConfigData[id] = slotParams;
        setSlotConfig(slotConfigData);
      } else {
        const slotConfigData = { ...slotConfig };
        slotConfigData[id] = [];
        setSlotConfig(slotConfigData);
        renderAlertMessage(result.errors);
      }
    });
  };

  const getAccountComponentAccessorials = () => {
    if (template?.account_id) {
      setInprogress(true);
      const { organization_id, account_id } = template;
      fetchAccountComponentAccessorials(
        organization_id,
        account_id,
        "DISPATCHER"
      ).then((result) => {
        if (result.success) {
          const accessorials = result.accessorials || [];
          const accComponentAccessorial = accessorials.map((accessorial) => {
            const componentDefs = getDeifinitions(accessorial);
            const slotObject = _.find(componentDefs, { code: "SLOTS" });
            if (!isEmpty(slotObject)) {
              getSlotDetails(accessorial.id, "SLOTS");
            }
            return Object.assign({}, accessorial, {
              componentDefs,
            });
          });
          setAccountComponentAccessorial(
            _.sortBy(accComponentAccessorial, (item) =>
              item.accessorial_name.toLowerCase()
            )
          );
          setInprogress(false);
        } else {
          setInprogress(false);
        }
      });
    }
  };
  const renderButton = () => {
    return (
      <Button type="primary" onClick={() => setSaveKey(randomNumber())} className="floatBtnTemplate">
        {I18n.t("general.save")}
      </Button>
    );
  };

  const renderFlatRateButton = () => {
    return (
      <Button type="primary" onClick={() => {}}>
        {I18n.t("general.save")}
      </Button>
    );
  }

  return (
    <Spin spinning={isLoading}>
      <Row gutter={16}>
      {/* {template?.locations?.length > 1 && (
          <Col xs={24} className="alignRight" style={{ marginBottom: '5px' }}>
            {renderFlatRateButton()}
          </Col>
        )} */}
        {accountComponentAccessorial.length > 0 ? (
          <>
            <Row>
              {template?.locations?.map((loc,index) => (
                <Col span={template?.locations?.length > 1 ? 12 : 24}>
                  <Card
                    size="small"
                    title={loc.type_of_loc ? `${loc.type_of_loc} Flat Rate` : 'Flat Rate'}
                    >
                    {renderFlatRate(template.order_type, loc,index)}
                  </Card>
                </Col>
              ))}
            </Row>
            <Row style={{ marginTop: '20px' }}>
              {template?.locations?.map((loc) => (
                <Col span={template?.locations?.length > 1 ? 12 : 24}>
                <Card
                    size="small"
                    title={loc.type_of_loc ? `${loc.type_of_loc} Accessorial` : 'Accessorial'}
                    >
                    {renderAccessorial(template.order_type, loc)}
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <Skeleton />
        )}
      </Row>
    </Spin>
  );
}

export default withRouter(TemplateAccessorialForm);
