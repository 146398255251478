import { Call, ApiUrl, PageParams, ParseGeneralResponse } from "./ApiUtils";
import { isEmpty } from "../common/Common";
import I18n from "../common/I18n";

export const glCodeReportApi = {
  fetch: (data) => {
    const {
      fromDate,
      todate,
      sortBy = "none",
      sortByType = "ascend",
      account_codes = [],
      warehouse_id = [],
      organization_id = "",
      export_option,
    } = data;
    let url = ApiUrl(
        `v2/billing_aging_reports/gl_code_report?organization_id=${organization_id}&warehouse_ids=${warehouse_id}&from_date=${fromDate}&to_date=${todate}&sort_by=${sortBy}&sort_order=${I18n.t(
          `general.sort_${sortByType}`
        )}&account_ids=${account_codes}`
      );
      if (export_option) {
      url = `${url}&download_as=${export_option}`;
      return Call("get", url, {}, ParseGeneralResponse, {
        responseDataKeys: { file: "gl_code_report", fileName: "filename" },
      });
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { GLCodeReports: "gl_code_report",TotalRevenue:"total_revenue", pagination: "pagination" },
    });
  }
};
