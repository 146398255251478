import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";

export function fetchAdjustments(
  accountId,
  page = 1,
  perPage = AppConfig.perPage
) {
  const url = ApiUrl(
    `v2/account_adjustments?${PageParams(
      page,
      perPage
    )}&account_id=${accountId}&operation_code=AADI`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      adjustments: "account_adjustments",
      pagination: "meta.pagination",
    },
  });
}

export function saveAdjustment(isNew, data) {
  data.operation_code = isNew ? 'AADC' : 'AADU';
  const formData = setObjectToFormData(data);
  const url = isNew
    ? ApiUrl("v2/account_adjustments")
    : ApiUrl(`v2/account_adjustments/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, formData, ParseGeneralResponse, {
    responseDataKeys: { adjustment: "account_adjustment" },
  });
}

export function updateStatus(id, status) {
  const formData = setObjectToFormData({ fleet_id: id, status });
  const url = ApiUrl("v2/fleet/update_fleet_status");
  const method = "put";
  return Call(
    method,
    url,
    formData,
    ParseGeneralResponse,
    { responseDataKeys: { truck: "" } },
    false
  );
}

export function fetchTrucksByType(type) {
  const url = ApiUrl(`v2/fleet?truck_type=${type}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { trucks: "", pagination: "meta.pagination" },
  });
}

export function deleteAdjustment(id) {
  const url = ApiUrl(`v2/account_adjustments/${id}`);
  return Call("delete", url, {operation_code : 'AADD'}, ParseGeneralResponse);
}

export function fetchCategories() {
  const url = ApiUrl(`v2/rule_categories?operation_code=AAGC`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { categories: "rule_categories" },
  });
}

export function fetchLosSkeleton(accountId, losId) {
  const url = ApiUrl(
    `v2/account_adjustments/los_clone_details?account_id=${accountId}&los_id=${losId}&operation_code=LOSCD`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { clone_details: "los_clone_details" },
  });
}

export function saveCategoryData(data) {
  data.operation_code = 'CADP';
  const url = ApiUrl("v2/account_adjustments/create_adjustment_params");
  const method = "post";
  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { accessorials: "accessorials", account_adjustment_param: 'account_adjustment_param' } },
    false
  );
}

export function fetchAdjustmentCategoryDetails(
  accountId,
  adjustmentId,
  adjustmentCode
) {
  const url = ApiUrl(
    `v2/account_adjustments/adjustment_params_details?account_adjustment_id=${adjustmentId}&adjustment_code=${adjustmentCode}&account_id=${accountId}&operation_code=AAPD`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      details: "account_adjustment_param",
      pagination: "meta.pagination",
    },
  });
}

export function fetchAccountAdjustments(accountId) {
  const url = ApiUrl(
    `v2/account_adjustments/account_adjustments_list?account_id=${accountId}&operation_code=AADL`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { adjustments: "account_adjustments" },
  });
}

export function saveLosAdjustments(data) {
  data.operation_code = 'ALOSA';
  const formData = setObjectToFormData(data);
  // const url = isNew ? ApiUrl('v2/account_adjustments') : ApiUrl(`v2/account_adjustments/${data.id}`);
  const url = ApiUrl("v2/level_of_services/add_los_adjustments");
  return Call("post", url, formData, ParseGeneralResponse, {
    responseDataKeys: { adjustment: "account_adjustment" },
  });
}

export function fetchLosAdjustments(accountId, losId) {
  const url = ApiUrl(
    `v2/level_of_services/get_los_adjustments?level_of_service_id=${losId}&operation_code=GLOSA`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { adjustments: "adjustments" },
  });
}
export function updateAdjustMentCategory(data) {
  data.operation_code = "UACP"
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/special_day_adjustments/add_special_day_adjustment_categories");
  return Call("post", url, formData, ParseGeneralResponse, {
    responseDataKeys: { adjustment_category: "" },
  });
}