import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';
import {  withRouter } from 'react-router-dom';
import { Row, Col, Input, Select, TextArea,  Icon, Button, Checkbox, Radio } from '../../common/UIComponents.jsx';
import I18n from '../../common/I18n.js';
import MobileInput from '../common/MobileInput';
import AddressForm from '../common/AddressForm';
import { loadGoogleMaps } from '../../utils/Utils';
import DeleteIcon from '../common/DeleteIcon.jsx';


const { Option } = Select;
const optionFields = ['single_choice', 'multiple_choice', 'dropdown', 'multi_choicd_dropdown'];
let index = 0;
const Question = () => ({
  content: '',
  questionTypeId: '',
  id: '',
  map: false,
  option_type: '',
  options: [],
  formFile: '',
  formUrl: '',
  mandatory: false,
  questionImages: [],
  notes_type_text: '',
  notes_type: '',
});

class Questionnare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isNew: true,
      isLoading: [],
      endLoading: [],
      data: this.props.optionType,
      types: [],
      currentIndex: '',
      loading: false,
      map: false,
    };
  }

    // eslint-disable-next-line react/sort-comp
    Add = () => {
      const { data } = this.state;
      if (Array.isArray(data.options)) {
        index++;
        data.options.push({ option_value: '', opt_order: index });
      } else {
        data.options = [{ option_value: '', opt_order: index }];
      }
      this.setState({
        data,
      });
    };

      DeleteQuestionAnswer = (j) => {
        const { data } = this.state;
        data.options.splice(j, 1);
        this.setState({
          data,
        }, () => {
          this.props.QnAChange(data);
        });
      };

      handleQuestionAnswers = (element, value, j, key) => {
        const { data } = this.state;
        if (j !== undefined) {
          if (key !== undefined) {
            data[element][j][key] = value;
          } else {
            data[element][j] = value;
          }
        } else {
          data[element] = value;
        }
        this.setState({
          data,
        }, () => {
          this.props.QnAChange(data);
        });
      };

      handlePopulateChange = (element, value) => {
        const { data } = this.state;
        data[element] = value;
        this.setState({ data }, () => {
          this.props.QnAChange(data);
        });
      }

      handleInputChange = (element, value, key) => {
        const { data } = this.state;
        data[element][0][key] = value;
        this.setState({ data }, () => {
          this.props.QnAChange(data);
        });
      }

      componentDidMount() {
        loadGoogleMaps(() => {
          this.setState({
            map: true,
          });
        });
      }

      componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
          this.setState({
            data: nextProps.optionType,
          });
        }
      }

      render() {
        const { types } = this.state;
        return (
          <div>
            <Row>
              <Col
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >

                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>


                    <Row className='pt-4'>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {/* <Col xs={2} sm={2} md={2} lg={2} xl={2} className='Oval-div'>
                          <span className='oval1' />
                        </Col> */}
                        <Col xs={24}>
                          {
                            (this.state.data.option_type === 'text') &&
                            <Row >
                              <Col>
                                <Input
                                  type='text'
                                  placeholder='Answer'
                                  style={{ marginRight: 4 }}
                                  disabled
                                  // onChange={e => this.handleInputChange('options', e.target.value, 'option_value')}
                                />
                              </Col>
                            </Row>
                          }
                          {
                            (this.state.data.option_type === 'textbox') &&
                            <Row >
                              <Col>
                                <TextArea
                                  type='text'
                                  placeholder='Answer'
                                  style={{ marginRight: 4 }}
                                  disabled
                                />
                              </Col>
                            </Row>
                          }
                          {/* {
                        (this.state.data.option_type === 'email_field') &&
                        <Row >
                          <Col>
                            <Input
                              type='text'
                              placeholder='Enter Email'
                              style={{ marginRight: 4 }}
                              disabled
                            />
                          </Col>
                        </Row>
                      }
                      {
                        (this.state.data.option_type === 'phone_field') &&
                        <Row >
                          <Col>
                            <MobileInput handleInputChange={this.handleInputChange} disabled />
                          </Col>
                        </Row>
                      } */}
                          {
                            (this.state.data.option_type === 'yes_no') &&
                            <Row>
                              <Radio disabled >Yes</Radio>
                              <Radio disabled >No</Radio>
                            </Row>
                          }

                          {/* {
                        this.state.data.option_type === 'address_field' && this.state.map ?
                          <AddressForm
                            autoComplete
                            address={{}}
                            disabled
                            // onChange={this.handleOnAddressChange}
                            
                          />
                          : <Fragment />
                      } */}
                          { this.state.data.option_type === 'populate' && (
                            <Row>
                              <Col>
                                <span className='textBold'>{I18n.t('questionaries.poulate_field')}:</span> 
                                <Select
                                  placeholder="Select"
                                  style={ { width: 200, paddingLeft: 10 } }
                                  onChange={e => this.handlePopulateChange('pre_populate_field', e)}
                                  showSearch
                                >
                                  { this.props.dbColumns.sort(
                                    (a, b) => a.label.localeCompare(b.label)
                                  ).filter((data) => data.use_in_form).map((data) => (
                                    <Option value={data.fe_column}>{data.label}</Option>
                                  ))}
                                </Select>
                              </Col>
                            </Row>
                          ) }
                          {
                            (this.state.data.option_type === 'single_choice' || this.state.data.option_type === 'multiple_choice' || this.state.data.option_type === 'dropdown' || this.state.data.option_type === 'multi_select_dropdown') ?
                              <Fragment>
                                {
                                  _.map(this.state.data.options, ((q, j) => (
                                    <Row key={j} style={{ marginTop: 10 }} >
                                      <Col xs={24}>
                                        <Input
                                          type='text'
                                          prefix={
                                            // eslint-disable-next-line no-nested-ternary
                                            this.state.data.option_type === 'single_choice' ?
                                              <Radio disabled /> :
                                              this.state.data.option_type === 'multiple_choice' ? <Checkbox disabled /> : <Fragment />
                                          }
                                          suffix={(j !== this.state.data.options.length) &&
                                            <DeleteIcon handleClick={() => this.DeleteQuestionAnswer(j)}/>
                                        }
                                          placeholder={
                                            I18n.t('questionaries.enter_text')
                                            // eslint-disable-next-line no-nested-ternary
                                            // this.state.data.option_type === 'single_choice' ?
                                            //   'Enter Single Choice Option' :
                                            //   this.state.data.option_type === 'multiple_choice' ? 'Enter Multiple Choice Option' : 'Enter Dropdown Option'
                                          }
                                          value={q.option_value}
                                          className="optionInputs"
                                          onChange={e => this.handleQuestionAnswers('options', e.target.value, j, 'option_value')}
                                        />
                                      </Col>
                                      {/* <Col span={2}>
                                        {
                                          this.state.data.questionTypeId === 4 ?
                                            <Radio disabled /> :
                                            <Checkbox disabled />
                                        }
                                      </Col>
                                      <Col span={15}>
                                        <Input
                                          type='text'

                                          placeholder='Add Multiple Option'
                                          style={{ border: '2px solid #fff' }}
                                          value={q.answer}
                                          onChange={e => this.handleQuestionAnswers('questionAnswers', e.target.value, i, j, 'answer')}
                                        />
                                      </Col>
                                      { (j !== this.state.data.questionAnswers.length) &&
                                        <Col span={5}>
                                          <Button
                                            onClick={() => this.DeleteQuestionAnswer(i, j)}
                                            className='Add-other'
                                          >Delete
                                          </Button>
                                        </Col>
                                      } */}
                                    </Row>
                                  )))
                                }
                                <Row>
                                  <Col span={5}>
                                    <Button
                                      onClick={() => this.Add()}
                                      className='marginTop20'
                                    >Add Options
                                    </Button>
                                  </Col>
                                </Row>
                              </Fragment>

                              : <Fragment />
                          }
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </Col>
            </Row>
          </div>
        );
      }
}
export default withRouter(Questionnare);

Questionnare.propTypes = {
  dbColumns: PropTypes.array,
};

Questionnare.defaultProps = {
  dbColumns: [],
};
