import React, { Component, Fragment } from "react";
import {
  Button,
  Col,
  Row,
  Select,
  FormItem,
  Input,
  Radio,
  Spin,
} from "../common/UIComponents";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { fetchOrganizations } from "../api/Organisations";
import { fetchSupport } from "../api/Support";
import { getValueFromArrayOfObjects } from "../helpers/array_functions";
import { renderAlertMessage } from "../helpers/common";

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: {},
      organizations: [],
      organization: "",
      search_key_type: "customer_order_number",
      search_key_value: "",
      pagination: {},
      inProgress: false,
      file_type: "ALL",
      result_file_type: "",
    };
  }

  componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const organizations = result.organizations.organizations || [];
        const organization =
          organizations.length > 0
            ? getValueFromArrayOfObjects(
                organizations,
                "code",
                I18n.t("account.netmove_code"),
                "_id"
              )
            : "";
        this.setState(
          {
            organizations,
            organization,
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getSupport = (cb) => {
    this.setState({ inProgress: true });
    const orgID = this.state.organization;
    const searchType = this.state.search_key_type;
    const searchParam = this.state.search_key_value;
    const type = this.state.file_type;
    fetchSupport(orgID, searchType, searchParam, type).then((result) => {
      if (result.success) {
        this.setState(
          {
            orders: result.orders,
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  renderAPI = () => {
    const { orders } = this.state;
    return (
      <div>
        <Row gutter={16}>
          {this.state.orders.inbound_files &&
          this.state.orders.inbound_files.length > 0 ? (
            <Col span={12}>
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                {I18n.t("support.inbound_files")}
              </h2>
              <pre className="settingSubTabScroll">
                {JSON.stringify(this.state.orders.inbound_files, null, 4)}
              </pre>
            </Col>
          ) : (
            <Col span={12}>
              {this.state.orders &&
              this.state.orders.inbound_files &&
              this.state.orders.inbound_files.length == 0 &&
              (this.state.result_file_type == "ALL" ||
                this.state.result_file_type == "INBOUND") ? (
                <Fragment>
                  <h2 style={{ display: "flex", justifyContent: "center" }}>
                    {I18n.t("support.inbound_files")}
                  </h2>
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    {I18n.t("support.no_inbound_files")}
                  </h3>
                </Fragment>
              ) : null}
            </Col>
          )}

          {this.state.orders.outbound_files &&
          this.state.orders.outbound_files.length > 0 ? (
            <Col span={12}>
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                {I18n.t("support.outbound_files")}
              </h2>
              <pre className="settingSubTabScroll">
                {JSON.stringify(this.state.orders.outbound_files, null, 4)}
              </pre>
            </Col>
          ) : (
            <Col span={12}>
              {this.state.orders &&
              this.state.orders.outbound_files &&
              this.state.orders.outbound_files.length == 0 &&
              (this.state.result_file_type == "ALL" ||
                this.state.result_file_type == "OUTBOUND") ? (
                <Fragment>
                  <h2 style={{ display: "flex", justifyContent: "center" }}>
                    {I18n.t("support.outbound_files")}
                  </h2>
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    {I18n.t("support.no_outbound_files")}
                  </h3>
                </Fragment>
              ) : null}
            </Col>
          )}
        </Row>
      </div>
    );
  };

  handleSearch = () => {
    if (this.state.organization === "" && this.state.search_key_value === "")
      alertMessage(I18n.t("messages.all_fields_required"), "error", 5);
    else if (this.state.organization === "")
      alertMessage(I18n.t("messages.org_id_required"), "error", 5);
    else if (this.state.search_key_type === "")
      alertMessage(I18n.t("messages.search_type_required"), "error", 5);
    else if (this.state.search_key_value === "")
      alertMessage(I18n.t("messages.search_value_required"), "error", 5);
    else {
      this.setState({ result_file_type: this.state.file_type });
      this.getSupport();
    }
  };

  handleFilterChange = (element, value) => {
    if (element === "search_key_value") {
      value = value.trim();
    }
    this.setState({ [element]: value });
  };

  render() {
    return (
      <div className="content-outer">
        <Spin spinning={this.state.inProgress} delay={100}>
          <div className="content">
            <Row className="page-header">
              <Col xs={12}>{I18n.t("searchFiles.title")}</Col>
            </Row>
            <Row>
              <Col sm={24} xs={24} md={5} lg={5}>
                <FormItem label={I18n.t("organization.single")} require>
                  <Select
                    value={this.state.organization}
                    showSearch
                    onChange={(e) => this.handleFilterChange("organization", e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: "100%" }}
                  >
                    <Select.Option key="-1" value="">
                      -- options --
                    </Select.Option>
                    {this.state.organizations.map((orgtype) => (
                      <Select.Option key={orgtype.code} value={orgtype._id}>
                        {orgtype.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={5} lg={5} style={{ left: 20 }}>
                <FormItem label={I18n.t("search.by")} require>
                  <Select
                    value={this.state.search_key_type}
                    onChange={(e) =>
                      this.handleFilterChange("search_key_type", e)
                    }
                    style={{ width: "80%" }}
                    disabled={isEmpty(this.state.organization)}
                  >
                    <Select.Option value="">-- Select --</Select.Option>
                    <Select.Option value="customer_order_number">
                      {I18n.t("support.order_no_search")}
                    </Select.Option>
                    <Select.Option value="customer_order_id">
                      {I18n.t("support.order_id_search")}
                    </Select.Option>
                  </Select>
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={5} lg={5} style={{ right: 20 }}>
                <FormItem label={I18n.t("search.value")} require>
                  <Input
                    value={this.state.search_key_value}
                    onChange={(e) =>
                      this.handleFilterChange(
                        "search_key_value",
                        e.target.value
                      )
                    }
                    disabled={isEmpty(this.state.search_key_type)}
                    onPressEnter={this.handleSearch}
                  />
                </FormItem>
              </Col>

              <Col sm={24} xs={24} md={5} lg={5} className="paddingTop25">
                <Radio.Group
                  onChange={(e) =>
                    this.handleFilterChange("file_type", e.target.value)
                  }
                  defaultValue={this.state.file_type}
                  value={this.state.file_type}
                >
                  <Radio value={I18n.t("support.value.all")} name="opt">
                    {I18n.t("general.all")}
                  </Radio>
                  <Radio value={I18n.t("support.value.inbound")} name="opt">
                    {I18n.t("support.inbound")}
                  </Radio>
                  <Radio value={I18n.t("support.value.outbound")} name="opt">
                    {I18n.t("support.outbound")}
                  </Radio>
                </Radio.Group>
              </Col>
              <Col sm={24} xs={24} md={4} lg={4} className="paddingTop20">
                <Button type="primary" onClick={this.handleSearch} icon={"search"}>
                  {I18n.t("general.search")}
                </Button>
              </Col>
            </Row>
            {this.renderAPI()}
          </div>
        </Spin>
      </div>
    );
  }
}

export default Support;
