import React  from "react";
import { PureComponent } from "react";
import InfoWindow from "react-google-maps/lib/components/InfoWindow";
import Marker from "react-google-maps/lib/components/Marker";
import warehouseImg from "../../../assets/images/warehouceMarker.png";
import { formateArrayToWithPlus } from "../../common/Common";
import { Row, Col, Card } from "../../common/UIComponents";
import { stopMarker } from "../../helpers/map";
import { pinLikeMarker } from "../dispatches/helpers";
class RouteMarker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isInfoWindowOpen: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isSelectedStop || this.state.isHovered) {
      this.setState({
        isInfoWindowOpen: true,
      });
    } else {
      this.setState({
        isInfoWindowOpen: false,
      });
    }
  }

  render() {
    const {
      marker,
      bgColor,
      fgColor,
      showNumbers,
      onToggleOpen,
      showPreplnControl,
      stopIndex,
      orders,
      route,
      setCurrentRoute,
      showStopScreen,
      resetHoveredStop,
    } = this.props;
    const { isInfoWindowOpen } = this.state;
    return (
      <Marker
        position={{
          lat: marker.lat,
          lng: marker.lng,
        }}
        key={marker.id}
        onClick={(e) => {
          // setCurrentRoute({}, route.route_id);
          resetHoveredStop();
          this.setState({ isInfoWindowOpen: true, isHovered: true }, () => {
            onToggleOpen(e, marker.stop_number, `${marker.id}`, "stop", route.route_id);
          });
          //  check for active row either in orderlist or cardlist
          	const hoveredStop = document.querySelector(`.activeRow`) || document.querySelector(`.currentActiveStop`);
            if(hoveredStop) {
              hoveredStop.scrollIntoViewIfNeeded();
            }
            
        }}
        onMouseOut={(e) => {
          // 2 seconds after mouse out, close info window
          this.setState({
            isInfoWindowOpen: false,
            isHovered: false,
          });
          onToggleOpen(e, marker.stop_number, ``, "stop", route.route_id);

          // setTimeout(() => {
          // 	this.setState({ isInfoWindowOpen: false });
          // }, 1000);
        }}
        // icon={(marker.stop_number == 1 || marker.stop_number == route.stop_markers.length) ? {
        icon={
          marker.location_type === "WH"
            ? {
                url: warehouseImg,
                scaledSize: new google.maps.Size(30, 30),
                anchor: new google.maps.Point(16, 30),
              }
            : {
                url: showNumbers
                  ? pinLikeMarker(marker.stop_number - 1, bgColor, fgColor)
                  : pinLikeMarker( null , bgColor , fgColor , 20),
              }
        }
        onMouseOver={(e) => {
          // this.setState({ isInfoWindowOpen: !isInfoWindowOpen });
          resetHoveredStop();
          this.setState({ isInfoWindowOpen: true, isHovered: true }, () => {
            onToggleOpen(e, marker.stop_number, `${marker.id}`, "stop", route.route_id);
          });
          const hoveredStop = showStopScreen ? document.querySelector(`.currentActiveStop`) : document.querySelector(`.activeRow`);
          if(hoveredStop) {
            hoveredStop.scrollIntoViewIfNeeded();
          }
            
          
        }}
      >
        {isInfoWindowOpen && (
          <InfoWindow
            onCloseClick={() => this.setState({ isInfoWindowOpen: false })}
            // onCloseClick={(e) =>
            // 	props.onToggleOpen(
            // 		e,
            // 		marker.stop_number,
            // 		`${marker.id}`
            // 	)
            // }
            options={{
              alignTop: true,
              closeBoxURL: "",
              enableEventPropagation: true,
            }}
          >
            <div
              style={{
                height: "auto",
                width: "auto",
                padding: 0,
              }}
              className="mapInfoWindow"
            >
              {showPreplnControl ? (
                <div className="hintText">
                  {orders.length > 0
                    ? formateArrayToWithPlus(orders)
                    : "No orders"}
                </div>
              ) : (
                <Card
                  style={{
                    minWidth: 250,
                    marginTop: 12,
                    fontSize: "14px",
                    color: "#000000",
                    padding: "10px 5px",
                  }}
                  title={`${marker.contact_name}`}
                >
                  <Row>
                    <Col md={5} lg={5} sm={12} xs={12}>
                      Name
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col md={18} lg={18} sm={18} xs={18}>
                      {marker.formatted_stop_name
                        ? marker.formatted_stop_name
                        : "NA"}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} lg={5} sm={12} xs={12}>
                      Route
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col md={18} lg={18} sm={18} xs={18}>
                      {route.displayName ? route.displayName : "NA"}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} lg={5} sm={12} xs={12}>
                      Status
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col md={18} lg={18} sm={18} xs={18}>
                      {marker.status ? marker.status : "NA"}
                    </Col>
                  </Row>
                </Card>
              )}
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}
export default RouteMarker;
