import moment from 'moment';
import AppConfig from '../config/AppConfig';
import { getOrdinal } from '../helpers/array_functions';
import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';
import { isEmpty } from '../common/Common';

export function saveLos(data, isNew = true) {
  data.operation_code = isNew ? 'LOSC' : 'LOSU'
  const url = isNew ? ApiUrl('v2/level_of_services') : ApiUrl(`v2/level_of_services/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { los: 'level_of_service' } }, false);
}

export function fetchLosDetails(los) {
  const url = ApiUrl(`v2/level_of_services/${los}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { los: 'level_of_service' },
  });
}

export function deleteLos(id, data = {}) {
  const url = ApiUrl(`v2/level_of_services/${id}?operation_code=LOSD`);
  return Call('delete', url, data, ParseGeneralResponse, {}, false);
}

export function fetchLos(id) {
  const url = ApiUrl(`v2/level_of_services?account_id=${id}&operation_code=LOSI`);
  return Call(
    'get', url, {}, ParseGeneralResponse,
    { responseDataKeys: { los: 'los_billing_rates' } },
  );
}

export function cloneLosDetails(los) {
  const url = ApiUrl(`v2/level_of_services/los_clone?los_id=${los}&operation_code=LOSCL`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { los: 'los_billing_rates' },
  });
}

export function fetchShortLos(id) {
  const url = ApiUrl(`v2/level_of_services/los_short_list?order_id=${id}&operation_code=LOSSL`);
  return Call(
    'get', url, {}, ParseGeneralResponse,
    { responseDataKeys: { account: '' } },
  );
}

export function fetchAccountShortLos(accountCode, type_of_order) {
  let url = ApiUrl(`v2/level_of_services/account_los_short_list?account_code=${accountCode}&operation_code=ALSL`);
  if(!isEmpty(type_of_order)){
    url = `${url}&type_of_order=${type_of_order}`
  }
  return Call(
    'get', url, {}, ParseGeneralResponse,
    { responseDataKeys: { account: '' } },
  );
}

export function fetchContractsForLos(accountId, losCode) {
  const url = ApiUrl(`v2/level_of_services/get_los_contracts?account_id=${accountId}&los_code=${losCode}`);
  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { contracts: 'los_contracts' } }
  );
}


export const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return moment(date).format(AppConfig.dateFormat);
};


export const formatContractDate = (contract) => {
  // const formatDateWithOrdinal = (date) => {
  //     const day = new Date(date).getDate();
  //     return new Date(date).toLocaleString("en-US", { month: "short" }) + " " + getOrdinal(day);
  // };
  const formatDateWithOrdinal = (date) => {
    return date ? moment.utc(date).format(AppConfig.dateFormat) : '';
  };

  const startDate = formatDateWithOrdinal(contract.effective_start_date);
  const endDate = contract.effective_end_date ? formatDateWithOrdinal(contract.effective_end_date) : "till now";

  return `${startDate} - ${endDate}`;
};


export function manageContract(data) {
  data.operation_code = 'MLOSC'; //isNew ? 'LOSC' : 'LOSU'
  const url = ApiUrl('v2/level_of_services/los_contract_creation');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { 
    los: 'level_of_services' 
  } }, false);
}

export function updateLOS(data) {
  data.operation_code = 'LOSU'; //isNew ? 'LOSC' : 'LOSU'
  const url = ApiUrl('v2/level_of_services/update_los_name_code');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { 
    los: 'level_of_services' ,
    message: 'message' 
  } }, false);
}

