import userStore from "../stores/UserStore";
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";

//  store the functions related to account and org level instructions
export const AccountInstructionsApi = {
	fetch: (organization_id, accountId, isPagingEnabled, currentPage = 1, sortBy = 'code', sortOrder = 'asc',) => {
		if (isPagingEnabled) {
			const url = ApiUrl('v2/account_instructions?organization_id=' + organization_id + '&account_id=' + accountId + '&page=' + currentPage + '&operation_code=AINSI&sort_by=' + sortBy + '&sort_order=' + sortOrder);
			return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { account_instructions: 'account_instructions', pagination: 'pagination' } });
		} else {
			const url = ApiUrl('v2/account_instructions?organization_id=' + organization_id + '&account_id=' + accountId + '&sort_by=' + sortBy + '&operation_code=AINSI&sort_order=' + sortOrder);
			return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { account_instructions: 'account_instructions' } });
		}
	},
	update: (id, instructionsData) => {
		instructionsData.operation_code='AINSU';
		const url = ApiUrl('v2/account_instructions/' + id);
		const formData = setObjectToFormData(instructionsData);
		return Call('put', url, formData, ParseGeneralResponse, { responseDataKeys: { data: 'account_instruction' } });
	},
	create: (organization_id, accountId, instructionsData) => {
		instructionsData.operation_code='AINSC';
		const url = ApiUrl('v2/account_instructions?organization_id=' + organization_id + '&account_id=' + accountId);
		const formData = setObjectToFormData(instructionsData);
		return Call('post', url, formData, ParseGeneralResponse, { responseDataKeys: { data: 'account_instruction' } });
	},
	delete: (id) => {
		const url = ApiUrl('v2/account_instructions/' + id);
		return Call('delete', url, {operation_code: 'AINSD'}, ParseGeneralResponse, { responseDataKeys: { data: '' } });
	}
};

export const OrgInstructionsApi = {
	fetch: (organization_id, isPagingEnabled, currentPage = 1, sortBy = 'code', sortOrder = 'asc',) => {
		if (isPagingEnabled) {
			const url = ApiUrl('v2/organization_instructions?organization_id=' + organization_id + '&page=' + currentPage + '&operation_code=OINSI&sort_by=' + sortBy + '&sort_order=' + sortOrder);
			return Call('get', url, '', ParseGeneralResponse, {
				responseDataKeys: {
					organization_instructions: 'organization_instructions',
					pagination: 'pagination'
				}
			});
		} else {
			const url = ApiUrl('v2/organization_instructions?organization_id=' + organization_id + '&operation_code=OINSI&sort_by=' + sortBy + '&sort_order=' + sortOrder);
			return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { data: 'organization_instructions' } });
		}
	},
	update: (id, instructionsData) => {
		instructionsData.operation_code='OINSU';
		const url = ApiUrl('v2/organization_instructions/' + id);
		const formData = setObjectToFormData(instructionsData);
		return Call('put', url, formData, ParseGeneralResponse, { responseDataKeys: { data: 'organization_instruction' } });
	},
	create: (organization_id, instructionsData) => {
		instructionsData.operation_code='OINSC';
		const url = ApiUrl('v2/organization_instructions?organization_id=' + organization_id);
		const formData = setObjectToFormData(instructionsData);
		return Call('post', url, formData, ParseGeneralResponse, { responseDataKeys: { data: 'organization_instruction' } });
	},
	delete: (id) => {
		const url = ApiUrl('v2/organization_instructions/' + id);
		return Call('delete', url, {operation_code: 'OINSD'}, ParseGeneralResponse, { responseDataKeys: { data: '' } });
	}
};

export const instructionType = {
	fetch: (organization_id, isPagingEnabled, currentPage = 1, sortBy = 'code', sortOrder = 'asc',) => {
		if (isPagingEnabled) {
			const url = ApiUrl('v2/instruction_types?organization_id=' + organization_id + '&' + PageParams(currentPage) + '&operation_code=INSI&sort_by=' + sortBy + '&sort_order=' + sortOrder);
			return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { instruction_types: 'instruction_types', pagination: 'pagination' } });
		} else {
			const url = ApiUrl('v2/instruction_types?organization_id=' + organization_id + '&operation_code=INSI&sort_by=' + sortBy + '&sort_order=' + sortOrder);
			return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { instruction_types: 'instruction_types' } });
		}
	},
	create: (data) => {
		const organization_id = userStore.getStateValue("selectedOrg");
		const { instructionName, instructionCode, } = data;
		const url = ApiUrl('v2/instruction_types');
		const method = 'post';
		return Call(method, url, { name: instructionName, code: instructionCode, operation_code: 'INSC', organization_id }, ParseGeneralResponse, { responseDataKeys: { data: 'instruction_type' } });
	}
	, update: (data) => {
		const organization_id = userStore.getStateValue("selectedOrg");
		const { id, instructionCode, instructionName } = data;
		const url = ApiUrl('v2/instruction_types/' + id);
		const method = 'put';
		return Call(method, url, { name: instructionName, code: instructionCode, operation_code: 'INSU', organization_id }, ParseGeneralResponse, { responseDataKeys: { data: 'instruction_type' } });
	},
	delete: (id) => {
		const url = ApiUrl('v2/instruction_types/' + id);
		return Call('delete', url, { operation_code: 'INSD' }, ParseGeneralResponse, { responseDataKeys: { data: '' } });
	}

};
