import React, { Component, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Radio } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import pickup from '../../assets/images/minipickup.svg';
import drop from '../../assets/images/minidrop.svg';
import { saveTasks, fetchTasks, deleteTasks, updateTaskOrder, allocateDriver, fetchRoute } from '../api/Routes';
import FormErrors from '../components/common/FormErrors';
import { fetchLocationsByType, fetchLocationsByZone } from '../api/LocationsApi';
import { fetchUsers } from '../api/UsersApi';
import { fetchLocationOrders, fetchTaskOrders } from '../api/OrdersApi';
import {
  Col, Row, Spin, Select, Icon, FormItem,
  TextArea, DatePicker, Button, Popconfirm, Avatar, Drawer
} from '../common/UIComponents';
import whiteArrow from '../../assets/images/WhiteArrow.png';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import DraggableOption from '../components/Tasks/DraggableOption';
import BaseModal from '../components/BaseModal';
import OrdersList from '../components/Tasks/OrdersList';
import OrderDetails from '../components/Tasks/OrderDetails';
import { OrgContext } from '../context/OrgContext';
import EditIcon from '../components/common/EditIcon';
import DeleteIcon from '../components/common/DeleteIcon';
import EyeIcon from '../components/common/EyeIcon';
import BranchesIcon from '../components/common/BranchesIcon';
import { renderAlertMessage } from '../helpers/common';


const userTypes = {
  '/drivers': 'driver',
  '/customers': 'customer',
  '/admins': 'admin',
};
const { Option } = Select;
const RadioGroup = Radio.Group;

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: {
        t_type: 'PICKUP',
        t_start_datetime: moment(),
        orderInfo: [],
        taskOrderInfo: [],
        selectedRowKeys: []
      },
      location: [],
      pickupLocations: [],
      dropLocations: [],
      inProgress: false,
      routes: [],
      locations: [],
      zones: [],
      googleMap: false,
      updateStatus: false,
      errors: [],
      loading: false,
      routeId: this.props.match.params.routeId ? this.props.match.params.routeId : '',
      users: [],
      driverId: '',
      allotedDriver: {},
      assignStatus: false,
      routeInfo: '',
      selectedRowKeys: [],
      orderInfo: [],
      taskOrderInfo: [],
      dropTask: false,
      detailsVisible: false,
      organizationSettings: {},
      isMilitaryTime: false,
    };

    this.userType = userTypes[_.get(props, 'match.path', 'driver')];
  }

  UNSAFE_componentWillMount() {
    this.getPickupLocations();
    this.getDropLocations();
    this.getTasks();
    this.getRouteInfo();
    this.getUsers();
  }

  componentDidMount() {
    if (this.state.routeId) {
      const tasks = Object.assign({}, this.state.tasks, { nav_route_id: this.state.routeId });
      this.setState({
        tasks,
      });
    }
    this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' }) 
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
  }

  onOk = (value) => {
  }

  getTasks = () => {
    if (this.state.routeId) {
      fetchTasks(this.state.routeId)
        .then((result) => {
          if (result.success) {
            let sortedRoutes = [];
            const filteredRoutes=  result.routes.tasks.filter(x => x.t_s_no && x.t_s_no=== 0);
            if(filteredRoutes.length>0){
               sortedRoutes = _.sortBy(result.routes.tasks, [function (o) { return o.l_type; }]); 
            }else{
               sortedRoutes = _.sortBy(result.routes.tasks, [function (o) { return o.t_s_no; }]); 
            }
           
           

            this.setState({
              routes: sortedRoutes,
            });
          } else {
            alertMessage(result.errors, 'error', 10);
            this.setState({
              routes: [],
            });
          }
        });
    }
  }

  getPickupLocations = () => {
    fetchLocationsByType('PICKUP')
      .then((result) => {
        if (result.success) {
          this.setState({
            pickupLocations: result.locations.locations,
          });
          this.setLocations('PICKUP');
        } else {
          this.setState({ error: result.errors[0] });
        }
      });
  }

  getDropLocations = () => {
    fetchLocationsByType('DROP')
      .then((result) => {
        if (result.success) {
          this.setState({
            dropLocations: result.locations.locations,
          });
        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  getLocationsByZone = (value, cb) => {
    // const location = _.filter(this.state.locations, o => (o.zone.id === value));
    // this.setState({
    //   location,
    //   tasks: Object.assign({}, this.state.tasks, { location: '' })
    // }, cb);
    const tasks = this.state.tasks;
    fetchLocationsByZone(tasks.t_type, value)
      .then((result) => {
        if (result.success) {
          this.setState({
            location: result.locations.locations,
            tasks: Object.assign({}, tasks, { location: '' })

          }, cb);
        } else {
          renderAlertMessage(result.errors)
        }
      });
  }
  getLocationOrders = (value, cb) => {
    const tasks = this.state.tasks;
    fetchLocationOrders(value, tasks.t_type)
      .then((result) => {
        if (result.success) {
          this.setState({
            orderInfo: result.orders.customer_orders
          }, cb);
        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  getTaskOrders = (value, cb) => {
    fetchTaskOrders(value)
      .then((result) => {
        if (result.success) {
          this.setState({
            taskOrderInfo: result.orders.customer_orders,
            selectedRowKeys: _.map(result.orders.customer_orders, function (o) { return o.id })
          }, cb);

        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  getZones = (cb) => {
    const zone = _.map(this.state.locations, 'zone');
    const zones = _.uniqWith(zone, _.isEqual);
    this.setState({
      zones,
    }, cb);
  }

  setLocations = (type, cb) => {
    if (type === 'PICKUP') {
      this.setState({
        locations: this.state.pickupLocations,
        location: [],
        tasks: Object.assign({}, this.state.tasks, { zone: '', location: '', orderInfo: [], taskOrderInfo: [], selectedRowKeys: [] })
      }, () => {
        this.getZones(cb);
      });
    } else if (type === 'DROP') {
      this.setState({
        locations: this.state.dropLocations,
        location: [],
        tasks: Object.assign({}, this.state.tasks, { zone: '', location: '', orderInfo: [], taskOrderInfo: [], selectedRowKeys: [] })
      }, () => {
        this.getZones(cb);
      });
    }
  }

  getUsers = () => {
    this.setState({ inProgress: true });
    fetchUsers(this.userTypes, 1, 1000, false, true)
      .then((result) => {
        if (result.success) {
          this.setState({
            users: result.users,
            inProgress: false,
          }, () => {
            this.setAllotedDriver();
          });

        } else {
          this.setState({ inProgress: false });
          renderAlertMessage(result.errors)
        }
      });
  };

  getRouteInfo = () => {
    if (this.state.routeId) {
      fetchRoute(this.state.routeId)
        .then((result) => {
          if (result.success) {
            this.setState({
              routeInfo: result.nav_route,
            }, () => {
              if (result.nav_route.driver && result.nav_route.driver.id) {
                this.setState({
                  driverId: result.nav_route.driver.id
                }, () => {
                  this.setAllotedDriver();
                })
              }
            });
          } else {
            this.setState({ inProgress: false });
            renderAlertMessage(result.errors)
          }
        });
    }
  };


  setOrdersInfo = (selectedCase, cb) => {

    if (selectedCase === "zone") {
      /*** Check If task Id is present ***/
      if (this.state.routes && this.state.tasks.id) {
        const routes = this.state.routes;
        const index = _.findIndex(routes, ['id', this.state.tasks.id]);
        const task = Object.assign({}, routes[index]);
        /*** if zone of the task and selected zone are equal, then need to get all locations of the selected zone whose orders are yet to be in pending in addition to the selected task location if the location is not there in the fetched locations. ***/
        if (this.state.tasks.t_type === task.t_type && task.location.zone.id === this.state.tasks.zone) {
          this.getLocationsByZone(this.state.tasks && this.state.tasks.zone, () => {
            const location = this.state.location;
            const locationindex = _.findIndex(location, ['id', task.location.id]);
            if (locationindex < 0) {
              location.push(task.location);
            }

            this.setState({ location, tasks: Object.assign({}, this.state.tasks, { location: task.location.id }) }, () => {
              this.setOrdersInfo('location');
            });

          });
        } else {
          this.getLocationsByZone(this.state.tasks && this.state.tasks.zone, () => {
            const tasks = Object.assign({}, this.state.tasks, { orderInfo: [], taskOrderInfo: [], selectedRowKeys: [] });
            this.setState({ tasks });
          });
        }

      } else {
        this.getLocationsByZone(this.state.tasks && this.state.tasks.zone, () => {
          const tasks = Object.assign({}, this.state.tasks, { orderInfo: [], taskOrderInfo: [], selectedRowKeys: [] });
          this.setState({ tasks });
        });
      }
    } else if (selectedCase === "location") {
      /*** Check If task Id is present ***/
      if (this.state.routes && this.state.tasks.id) {
        const routes = this.state.routes;
        const index = _.findIndex(routes, ['id', this.state.tasks.id]);
        const task = Object.assign({}, routes[index]);
        /***  if location of the task and selected location are equal, then need to get all pending orders of the selected location which are yet to be in pending in addition to the orders of the selected task. The orders of the selected task should be in selected mode. ***/
        if (this.state.tasks.t_type === task.t_type && task.location.id === this.state.tasks.location) {
          if (task.t_type === "PICKUP") {
            this.getLocationOrders(this.state.tasks && this.state.tasks.location, () => {
              const taskOrderInfo = task.orders;
              const selectedRowKeys = _.map(task.orders, function (o) { return o.id })
              const tasks = Object.assign({}, this.state.tasks, { orderInfo: taskOrderInfo.concat(this.state.orderInfo), taskOrderInfo: taskOrderInfo, selectedRowKeys: selectedRowKeys });
              this.setState({ tasks });
            });
          } else {
            /*** Check if task type is drop user wont get any other orders, they will get only created orders */
            const taskOrderInfo = task.orders;
            const selectedRowKeys = _.map(task.orders, function (o) { return o.id })
            const tasks = Object.assign({}, this.state.tasks, { orderInfo: taskOrderInfo, taskOrderInfo: taskOrderInfo, selectedRowKeys: selectedRowKeys });
            this.setState({ tasks }, () => {
            });

          }

        } else {
          this.getLocationOrders(this.state.tasks && this.state.tasks.location, () => {
            const tasks = Object.assign({}, this.state.tasks, { orderInfo: this.state.orderInfo, taskOrderInfo: [], selectedRowKeys: [] });
            this.setState({ tasks });
          });
        }
      } else {
        this.getLocationOrders(this.state.tasks && this.state.tasks.location, () => {
          const tasks = Object.assign({}, this.state.tasks, { orderInfo: this.state.orderInfo, taskOrderInfo: [], selectedRowKeys: [] });
          this.setState({ tasks });
        });
      }
    }

  };

  handleOnChange = (element, value) => {
    let { tasks } = this.state;
    if (element === 't_type') {
      tasks = Object.assign({}, this.state.tasks, { [element]: value });
      delete tasks.zone;
      // delete tasks.location;
      // delete tasks.orderInfo;
      // delete tasks.selectedRowKeys;
      this.setState({
        tasks,
      }, () => {
        if (value) {
          this.setLocations(value);
        }
      });
    }
    if (element === 'zone') {
      if (value) {
        tasks = Object.assign({}, this.state.tasks, { [element]: value });
        delete tasks.location;
        this.setState({
          tasks //,orderInfo: [],selectedRowKeys: [],taskOrderInfo: []
        }, () => {
          //this.getLocationsByZone(value);
          this.setOrdersInfo('zone');
        });
      }
    }
    if (element === 'location') {
      if (value) {
        tasks = Object.assign({}, this.state.tasks, { [element]: value });
        this.setState({
          tasks,
        }, () => {
          //this.getLocationOrders(value);
          this.setOrdersInfo('location');
        });
      }
    }
    if (element === 't_notes' || 't_start_datetime') {
      tasks = Object.assign({}, this.state.tasks, { [element]: value });
      this.setState({
        tasks,
      });

    }
  }

  handleChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }


  taskStatus = (task) => {
    if (task.t_type === 'PICKUP') {
      return 'hsla(120,100%,75%,0.1)';
    } else if (task.t_type === 'DROP') {
      return '#2E2E2E';
    }
  }

  handleDeleteClick = (id) => {
    deleteTasks(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getTasks();
          this.handleCancel();
        } else {
          alertMessage(result.errors, 'error', 10);
        }
      });
  }

  handleEditClick(id) {
    const index = _.findIndex(this.state.routes, ['id', id]);
    const task = Object.assign({}, this.state.routes[index]);
    task.zone = task.location.zone.id;
    this.setState({
      taskOrderInfo: task.orders,
      dropTask: task.t_type === "DROP" ? true : false,
      selectedRowKeys: _.map(task.orders, function (o) { return o.id })
    }, () => {
      this.setLocations(task.t_type, () => {
        this.setState({
          tasks: Object.assign({}, task, {
            location: '',
            taskOrderInfo: task.orders,
            selectedRowKeys: _.map(task.orders, function (o) { return o.id }),
            orderInfo: [],
          })
        }, () => {
          this.setOrdersInfo('zone');
        });
      });
    });
  }


  handleSaveSuccess = (message) => {
    alertMessage(message);
  }

  handleSelectChange = selectedRowKeys => {
    this.setState({ tasks: Object.assign({}, this.state.tasks, { selectedRowKeys: selectedRowKeys }) });
  };

  handleOrderViewClick = (order_id) => {
  }

  handleSave = () => {
    this.setState({
      loading: true,
    });
    let data = this.state.tasks;
    if (this.state.routeId) {
      data = Object.assign({}, data, { nav_route_id: this.state.routeId, customer_order_ids: this.state.tasks.selectedRowKeys });
    } else {
      data = Object.assign({}, data, { customer_order_ids: this.state.tasks.selectedRowKeys });
    }
    saveTasks(data, !this.state.tasks.id)
      .then((result) => {
        if (result.success) {
          this.handleSaveSuccess(this.state.tasks.id ? I18n.t('messages.updated') : I18n.t('messages.saved'));
          {
            (!this.state.tasks.id) &&
              this.setState({
                routeId: result.routeTasks.nav_route.id,
              }, () => {
                this.getRouteInfo();
              });
          }
          this.setState({
            loading: false,
            dropTask: false,
            tasks: { t_type: 'PICKUP', t_start_datetime: moment(), orderInfo: [], selectedRowKeys: [], taskOrderInfo: [] },
            orderInfo: [],
            selectedRowKeys: [],
            taskOrderInfo: []
          });
          this.getTasks();
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            loading: false,
          });
        }
      });
  };

  back = () => {
    this.props.history.push('/routes');
  }

  draggableMap = () => {
    this.setState({
      googleMap: true,
    });
  }

  handleOnModalClose = () => {
    this.setState({
      googleMap: false,
    });
  }

  updateOrder = (routeData) => {
    this.setState({
      updateStatus: true,
    });
    const Data = routeData.map((task, index) =>
      ({ task_id: task.id, t_s_no: index + 1 }));
    const tasksData = {
      tasks:
        Data,
    };
    updateTaskOrder(tasksData)
      .then((result) => {
        if (result.success) {
          this.handleSaveSuccess(result.order.message);
          this.getTasks();
          this.setState({
            updateStatus: false,
            googleMap: false,
          });
        } else {
          this.setState({
            updateStatus: false,
          });
          renderAlertMessage(result.errors)
        }
      });
  }


  assignDrivertoRoute = () => {
    this.setState({
      assignStatus: true,
    });
    allocateDriver(this.state.selectedDriverId, this.state.routeId)
      .then((result) => {
        if (result.success) {
          const selectedDriverId = this.state.selectedDriverId;
          this.setState({
            assignStatus: false,
            driverId: selectedDriverId
          }, () => {
            this.setState({ selectedDriverId: '' });
            this.setAllotedDriver();
          });
          this.handleSaveSuccess(I18n.t('messages.allocated'));
        } else {
          this.setState({
            assignStatus: false,
          });
          renderAlertMessage(result.errors)
        }
      });
  };

  renderFormModal() {
    return (
      <BaseModal
        title="Opitimize Map"
        onCancel={() => this.handleOnModalClose()}
        width="95%"
        style={{ top: 10, paddingTop: "12px" }}
      >
        <DraggableOption
          routeData={_.filter(this.state.routes, o => (o.t_status !== "INPROGRESS" && o.t_status !== "COMPLETED"))}    // this.state.routes
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          zones={this.state.zones}
          updateStatus={this.state.updateStatus}
          updateOrder={routeData => this.updateOrder(routeData)}
        />
      </BaseModal>
    );
  }

  setAllotedDriver = () => {
    if (this.state.driverId) {
      const index = _.findIndex(this.state.users, ['id', this.state.driverId]);
      const allotedDriver = Object.assign({}, this.state.users[index]);

      this.setState({
        allotedDriver
      });
    }
  }

  handleOrderDetailsViewClick = (id) => {

    const index = _.findIndex(this.state.taskOrderInfo, ['id', id]);
    let currentOrder = {};
    if (index < 0) {
      const orderindex = _.findIndex(this.state.orderInfo, ['id', id]);
      currentOrder = Object.assign({}, this.state.orderInfo[orderindex]);
    } else {
      currentOrder = Object.assign({}, this.state.taskOrderInfo[index]);
    }
    this.setState({ currentOrder: currentOrder, detailsVisible: true });

  }

  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      tasks: {
        t_type: 'PICKUP',
        t_start_datetime: moment(),
        orderInfo: [],
        taskOrderInfo: [],
        selectedRowKeys: []
      },
      dropTask: false
    })
  }


  render() {
    const { tasks, location, zones ,isMilitaryTime} = this.state;
    const assignString = I18n.t('users.driver.' + (this.state.driverId ? 'reassign' : 'assign'));
    const hasSelected = (this.state.tasks.selectedRowKeys) ? this.state.tasks.selectedRowKeys.length > 0 : false;
    const { selectedRowKeys } = this.state.tasks;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: record => ({
        disabled: (tasks.id && tasks.t_type === "DROP"),    // Column configuration not to be checked
      }),
    };
    return (
      <div className='content-outer'>
        <div className='content routes-tasks' style={{ backgroundColor: '#f0f2f5' }}>
          <Row className='page-header' style={{ marginBottom: 0 }}>
            <Col xs={12}>
              {/* New Route */}
              <Row>
                <Link to="/routes" className="backButton" >
                  <img src={whiteArrow} alt="whitearrow" style={{ height: 20, width: 30 }} />
                </Link>
                {this.state.routeId ? I18n.t('routes.update') : I18n.t('routes.new')}
              </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000} >
            <Row>
              <Col xs={15} style={{ padding: 10 }}>
                <Row className="blackBorder" >
                  <Row className='page-header' id="tasks">{tasks.id ? I18n.t('tasks.edit') : I18n.t('tasks.new')}
                  </Row>
                  <Row style={{ padding: '0 15px' }}>
                    <Row gutter={16}>
                      {/* <Col sm={12} xs={12} md={12} lg={12}>
                        <FormItem label='Select Type' >
                          <RadioGroup
                            onChange={e => this.handleOnChange('t_type', e.target.value)}
                            value={tasks.t_type}
                            className="pickUp"
                            defaultValue="PICKUP"
                            style={{ marginTop: 8 }}
                            disabled={this.state.dropTask===true ? true : false}
                            
                          >
                            <Radio value='PICKUP'>Pick Up</Radio>
                            <Radio value='DROP' disabled={true}>Drop</Radio>
                          </RadioGroup>
                        </FormItem>
                      </Col>*/}
                      <Col sm={12} xs={12} md={8} lg={8}>

                        <FormItem label='Select Time' >
                          <DatePicker
                            style={{ width: '100%', marginLeft: '1%' }}
                            placeholder="Select Time"
                            showTime={ isMilitaryTime ? { format: "HH:mm", use12Hours: false } : { format: "hh:mm A", use12Hours: true } }
                            format={isMilitaryTime ? "Do MMM YYYY,HH:mm" : "Do MMM YYYY,hh:mm a"}
                            onChange={e => this.handleOnChange('t_start_datetime', e)}
                            value={tasks.t_start_datetime ? moment(tasks.t_start_datetime) : null}
                            defaultValue={null}
                            onOk={this.onOk}
                            disabled={this.state.dropTask === true ? true : false}
                          />
                        </FormItem>
                      </Col>

                      <Col sm={12} xs={12} md={8} lg={8}>
                        <FormItem label='Select Zone' >
                          <Select
                            style={{ width: '100%' }}
                            onChange={e => this.handleOnChange('zone', e)}
                            placeholder="Select zone"
                            value={tasks.zone}
                            disabled={this.state.dropTask === true ? true : false}
                          >

                            {
                              zones.map(province =>
                                <Option key={province.id} value={province.id}>{province.name}</Option>)
                            }
                          </Select>
                        </FormItem>
                      </Col>
                      <Col sm={12} xs={12} md={8} lg={8}>
                        <FormItem label='Select Location' >
                          <Select
                            style={{ width: '100%' }}
                            onChange={e => this.handleOnChange('location', e)}
                            placeholder="Select location"
                            value={this.state.tasks.location}
                            disabled={this.state.dropTask === true ? true : false}
                          >
                            {
                              location.map(province =>
                                <Option key={province.id} value={province.id}>{province.name}</Option>)
                            }
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col xs={24}>
                        <FormItem label="Order Info">
                          <TextArea
                            className="routes-input"
                            rows={6}
                            onChange={e => this.handleOnChange('t_notes', e.target.value)}
                            value={tasks.t_notes}
                          />
                        </FormItem>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col xs={24} style={{ minHeight: 222 }}>
                        <span style={{ marginLeft: 8 }}>Selected {this.state.tasks.selectedRowKeys.length ? `${this.state.tasks.selectedRowKeys.length}` : '0'} of {this.state.tasks.orderInfo.length} Orders</span>

                        <OrdersList
                          data={this.state.tasks.orderInfo}
                          orderViewClick={id => this.handleOrderViewClick(id)}
                          taskType={this.state.tasks.t_type}
                          rowSelection={rowSelection}
                          pagination={{ position: 'none' }}
                          screen={"routes"}
                          orderDetailsViewClick={id => this.handleOrderDetailsViewClick(id)}

                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} style={{ padding: '10px 10px' }} className="alignCenter">
                        <Button className="buttonModal" onClick={this.handleCancel}  style={{
                            color: '#F5d358',
                            borderRadius: 25,
                            backgroundColor: '#040404',
                        }}>
                          Cancel
                        </Button>

                        {this.state.dropTask === false &&
                          <Button className="buttonModal" onClick={this.handleSave} loading={this.state.loading} disabled={this.state.tasks.selectedRowKeys.length > 0 ? false : true}>
                            Save
                        </Button>
                        }

                      </Col>

                    </Row>

                  </Row>
                </Row>
                {(this.state.allotedDriver) && (this.state.allotedDriver.id) &&
                  <Row>
                    <Col xs={24} style={{ paddingTop: 10 }}>
                      <h3>{I18n.t('users.driver.assigned')}</h3>
                      <Row>
                        <Col xs={10} sm={10} md={4} lg={4}>
                          <div className="driver-profile">
                            {
                              this.state.allotedDriver.image && (this.state.allotedDriver.image.url) ?
                                <Avatar src={`${this.state.allotedDriver.image.url}`} size={100} /> : <Avatar src={'./../icons/user_100*100.png'} alt="DriverProfile" size={100} />
                            }

                          </div>
                        </Col>
                        <Col xs={4} sm={14} md={20} lg={20} className="padding5">
                          <Col>
                            <Col>
                              <div className="info-text" style={{ fontWeight: "bold", marginBottom: "5px" }}>

                                {this.state.allotedDriver.full_name ?
                                  (this.state.allotedDriver.full_name) : 'NA'
                                }
                              </div>
                            </Col>
                            <Col>
                              <div className="info-text">
                                <h4><Icon type="mobile" /></h4>
                                {this.state.allotedDriver.mobile_number ?
                                  (this.state.allotedDriver.mobile_number) : 'NA'
                                }
                              </div>
                            </Col>
                            <Col>
                              <div className="info-text">
                                <h4><Icon type="mail" /></h4>
                                {this.state.allotedDriver.email ?
                                  (this.state.allotedDriver.email) : 'NA'
                                }
                              </div>
                            </Col>
                          </Col>
                        </Col>
                      </Row>


                    </Col>
                  </Row>
                }

                {(this.state.routeId) &&
                  <Row>
                    <Col xs={24}>
                      <Row>
                        <Col xs={12} style={{ paddingBottom: 10 }}>
                          <FormItem label={`${assignString}`} style={{ marginTop: 10 }}>
                            <Select
                              style={{ width: '100%' }}
                              className="routes-input"
                              onChange={e => this.handleChange('selectedDriverId', e)}
                              defaultValue={`${assignString}`}
                              value={this.state.selectedDriverId}
                            >
                              {this.state.users.map(province =>
                                province.id !== this.state.driverId && <Option key={province.id} value={province.id}>{province.full_name}</Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={12} style={{ padding: 10 }}>
                          <Button className="assignDriver" onClick={this.assignDrivertoRoute} loading={this.state.assignStatus}>{`${assignString}`}</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                }
              </Col>
              <Col xs={9} style={{ padding: 10 }}>
                <Row className="blackBorder" style={{ minHeight: 444 }}>
                  <Row >
                    <Col xs={15} className='route-summary'>
                      {I18n.t('tasks.routeSummary')}
                    </Col>
                    <Col xs={9}>
                      {(this.state.routes.length > 0) &&
                        <div className="optimizeRoute">
                          <BranchesIcon/>
                          <a onClick={this.draggableMap}>Optimize Route</a>
                        </div>

                      }
                    </Col>
                  </Row>
                  <Row className="center">
                    <Col sm={20} xs={20} md={22} lg={22} >
                      {this.state.routes &&
                        this.state.routes.map(task => (
                          task.location &&
                          <div
                            className="task-locations"
                            style={{
                              backgroundColor: (task.t_type === 'PICKUP')
                                ? 'hsla(120,100%,75%,0.1)' : 'rgba(255,0,0,0.1)',
                            }}
                            key={task.id}
                          >
                            <Col sm={2}>
                              {(task.t_type === 'PICKUP') ?
                                <img src={pickup} alt="pickup" className="mini-image flex-left" /> :
                                <img src={drop} alt="drop" className="mini-image flex-left" />
                              }
                            </Col>
                            <Col sm={18}>
                              <span className="task-names" >
                                {task.location ? task.location.name : ''}
                              </span>
                            </Col>
                            {(task.t_type === 'DROP') &&
                              <Col sm={2}>

                                <div style={{ align: 'right' }}>
                                  <EyeIcon handleClick={() => this.handleEditClick(task.id)}/>
                                </div>

                              </Col>
                            }
                            {(task.t_type === 'PICKUP' && (task.t_status === 'PENDING' || task.t_status === 'ASSIGNED') && (this.state.routeInfo.r_status === "PENDING" || this.state.routeInfo.r_status === "ASSIGNED")) &&
                              <Col sm={2}>

                                <div style={{ align: 'right' }}>
                                  <EditIcon handleClick={() => this.handleEditClick(task.id)}/>
                                </div>

                              </Col>
                            }
                            {(task.t_type === 'PICKUP' && (task.t_status === 'PENDING' || task.t_status === 'ASSIGNED') && (this.state.routeInfo.r_status === "PENDING" || this.state.routeInfo.r_status === "ASSIGNED")) &&
                              <Col sm={2}>

                                <Popconfirm
                                  placement="topRight"
                                  title={I18n.t('messages.delete_confirm')}
                                  onConfirm={() => this.handleDeleteClick(task.id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteIcon/>
                                  &nbsp;&nbsp;&nbsp;
                                </Popconfirm>

                              </Col>
                            }
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                </Row>
              </Col>

            </Row>


          </Spin>
          {(this.state.errors) &&
            <Row>
              {FormErrors(this.state.errors)}
            </Row>
          }
          {(this.state.googleMap) &&
            this.renderFormModal()
          }
        </div>

        {this.state.detailsVisible && <Drawer
          title={`${I18n.t('order.details')} ${this.state.currentOrder.customer_order_number ? '(#' + this.state.currentOrder.customer_order_number + ')' : ''}`}
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.detailsVisible}
          width={"60%"}
        >
          <OrderDetails currentOrder={this.state.currentOrder} />
        </Drawer>
        }
      </div>
    );
  }
}
 const RoutesComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <Routes
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default RoutesComponent;