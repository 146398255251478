/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { useEffect, Fragment, useState, useContext } from "react";
import _ from "lodash";
import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import moment from "moment";
import {
  DatePicker,
  Select,
  Row,
  Col,
  Icon,
  Spin,
  FormItem,
  Tooltip,
  Popconfirm,
  Button,
  Divider,
  Empty,
} from "../../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  randomNumber,
  checkNegitive,
  base64ToPdf,
} from "../../../common/Common";
import userStore from "../../../stores/UserStore";
import { WeeklyReportApi } from "../../../api/WeeklyReportApi";
import DriversWeeklyDeductionList from "./DriverWeeklyDeductions";
import DriversWeeklyReportsList from "./DriversWeeklyReportsList";
import { getWeekString } from "../../../helpers/date_functions";
import CopyableContent from "../../common/CopyableContent";
import BaseModal from "../../BaseModal";
import { fetchOrderInvoicePdf } from "../../../api/Billing";
import { base64ToPDF, renderAlertMessage } from "../../../helpers/common";
import AddOrdersToWeeklyReport from "../AddOrdersToWeeklyReport";
import { OrgContext } from "../../../context/OrgContext";
import { fetchShortFormAccounts } from "../../../api/Account";
import AddDeductionsToWeeklyReport from "./AddDeductionsToWeeklyReport";
import { deleteGrossOrder } from "../../../api/Reports";
import I18n from "../../../common/I18n";
import DriversWeeklyReportsRowList from "./DriversWeeklyReportsRowList";
import { WarehouseContext } from "../../../context/WarehouseContext";
import DisplayTime from "../../common/DisplayTime";
//import sample from "../../../common/sample.json";

moment.locale("en-gb", {
  week: {
    dow: 1, /// Date offset
  },
});

const { Option } = Select;
const { WeekPicker } = DatePicker;

const DriverWeeklyReports = (props) => {
  const orgId = userStore.getStateValue("selectedOrg");
  const [reports, setReports] = useState({});
  const [originalReports, setOriginalReports] = useState({});
  const [filterData, setFilterData] = useState({ weekPeriodString: moment() });
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [showModal, setShowModal] = useState("");
  const [currentDateRec, setCurrentDateRec] = useState({});
  const [accounts, setAccounts] = useState([]);
  const orgContextData = useContext(OrgContext);
  const { currentOrg, isAirCargo, glCodesList } = useContext(OrgContext);
  const { selectedWarehouses,  getWHDetails} = useContext(WarehouseContext);
  const [isApproved, setIsApproved] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const whDetails  = getWHDetails();




  // to get accounts
  useEffect(() => {
    getAccounts();
  }, [isApproved]);

  // to get details
  useEffect(() => {
    getReports();
  }, [filterData, isApproved]);

  useEffect(() => {
    getReports();
  }, [selectedWarehouses])

  const getAccounts = () => {
    const orgId = currentOrg.id;
    fetchShortFormAccounts(orgId, "", true).then((result) => {
      if (result.success) {
        setAccounts(result.accounts || []);
      } else {
        renderAlertMessage(result.errors)
        setAccounts([]);
      }
    });
  };

  const deleteOrder = (driverPayId, id) => {
    const data = {
      organization_id: orgId,
      driver_pay_report_id: driverPayId,
      gross_order_id: id,
    };
    setIsLoading(true);
    deleteGrossOrder(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        getReports();
        setIsLoading(false);
      } else {
        alertMessage(result.errors, "error", 10);
        setIsLoading(false);
      }
    });
  };

  const setDataFromResponse = (responseReports) => {
    const decimalPoints =   window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    const driversWeeklyPayReports = responseReports.drivers_weekly_pay_reports || []
    const sortedReports = driversWeeklyPayReports.map(report => {
        const sorted_gross_reports = report.gross_reports ? 
        _.sortBy(report.gross_reports, (report) => new Date(report.date)) : [];
        const gross_reports = sorted_gross_reports.map(rec => ({
            ...rec,
            gross_orders: rec.gross_orders ? rec.gross_orders.map(go => ({
                ...go,
                gross_revenue: go.gross_revenue ? parseFloat(go.gross_revenue).toFixed(decimalPoints) : zeroValue,
                gross_pay: go.gross_pay ? parseFloat(go.gross_pay).toFixed(decimalPoints) : zeroValue,
            })) : []

        }))
        const driver_deduction = report.driver_deduction && _.isArray(report.driver_deduction) ? 
        report.driver_deduction.map(rec => ({
            ...rec,
            amount: rec.amount ? parseFloat(rec.amount).toFixed(decimalPoints) : zeroValue,
        })) : [];
        const summary = {
            total_gross: report.total_gross ? parseFloat(report.total_gross).toFixed(decimalPoints) : zeroValue,
            total_net: report.total_net ? parseFloat(report.total_net).toFixed(decimalPoints) : zeroValue,
            total_deductions: report.total_deductions ? parseFloat(report.total_deductions).toFixed(decimalPoints) : zeroValue,
            payable_amount: report.payable_amount ? parseFloat(report.payable_amount).toFixed(decimalPoints) : zeroValue
        }

        return ({...report, gross_reports: _.cloneDeep(gross_reports), driver_deduction, summary})
    })
    return sortedReports;
  }

  const getReports = (isDownload = false) => {
    setIsLoading(true);
    const { weekPeriodString } = filterData;
    const payLoad = {
      start_date:
        moment(weekPeriodString).startOf("week").format("YYYY-MM-DD") ||
        new Date(),
      end_date:
        moment(weekPeriodString).endOf("week").format("YYYY-MM-DD") ||
        new Date(),
      organization_id: orgId,
      pdf_file: "false",
    };
    if (isDownload) {
      payLoad.pdf_file = "true";
      payLoad.doc_type = "PDF";
    }
    WeeklyReportApi.fetchReports(payLoad)
      .then((response) => {
        if (response.success) {
          if (!isDownload) {
            // const responseReports = response.reports || {};
            // setOriginalReports({ ...responseReports});
            // setReports({ ...responseReports});
            // const status =
            // responseReports?.drivers_weekly_pay_reports[0]?.gross_reports[0]
            //     ?.gross_orders[0]?.status;
            const responseReports = response.reports || {};
            const sortedReports = setDataFromResponse(responseReports)
            // const driversWeeklyPayReports = responseReports.drivers_weekly_pay_reports || []
            // const sortedReports = driversWeeklyPayReports.map(report => {
            //   const sorted_gross_reports = report.gross_reports ? 
            //   _.sortBy(report.gross_reports, (report) => new Date(report.date)) : [];
            //   return ({...report, gross_reports: _.cloneDeep(sorted_gross_reports)})
            // })
            setOriginalReports({ ...responseReports,  drivers_weekly_pay_reports: _.cloneDeep(sortedReports)});
            setReports({ ...responseReports,  drivers_weekly_pay_reports: _.cloneDeep(sortedReports)});
            const status =
            responseReports?.drivers_weekly_pay_reports[0]?.gross_reports[0]
                ?.gross_orders[0]?.status;
            if (status == "APPROVED") {
              setIsApproved(true);
            } else {
              setIsApproved(false);
            }
          } else {
            const fileName = response.file_name || `DriversReport.pdf`;
            base64ToPdf(response.drivers_report_pdf, fileName);
          }
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // To save details
  const handleSaveDetails = () => {
    setIsLoading(true);
    WeeklyReportApi.saveDriverReports(reports)
      .then((response) => {
        if (response.success) {
          //setOriginalReports({ ...reports });
          getReports()
          setIsSaved(true);
          alertMessage(I18n.t("messages.saved"), "success", 5);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleApprove = () => {
    setIsLoading(true);
    WeeklyReportApi.approveDriverReports(reports)
      .then((response) => {
        if (response.success) {
          // setOriginalReports({ ...reports });
          console.log("approve-->", response);
          // getReports();
          alertMessage(I18n.t("messages.saved"), "success", 5);
          setIsApproved(true);
          setinvoiceNumber(response.invoice_number);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (element, value) => {
    setFilterData({ ...filterData, [element]: value });
  };

  // const handleOrderChange = (
  //   driverIndex,
  //   dateIndex,
  //   orderIndex,
  //   element,
  //   value
  // ) => {
  //   const report = _.cloneDeep(reports);
  //   report.drivers_weekly_pay_reports[driverIndex].gross_reports[
  //     dateIndex
  //   ].gross_orders[orderIndex][element] = value;
  //   setReports(report);
  //   // const driverIndex = _.findIndex(report.drivers_weekly_pay_reports, { driver_id })
  //   // if(driverIndex >= 0){
  //   //   const driverIndex = _.findIndex(report.drivers_weekly_pay_reports, { driver_id })
  //   //   const orderIndex = _.findIndex(report.drivers_weekly_pay_reports, { driver_id });
  //   //   if(orderIndex >= 0){

  //   //   }
  //   // }
  // };

  const handleOrderChange = ( driverIndex, dateIndex, orderIndex, element, value ) => {
    const updatedDriver = { ...reports.drivers_weekly_pay_reports[driverIndex] };
    const updatedDate = { ...updatedDriver.gross_reports[dateIndex] };
    const updatedOrder = { ...updatedDate.gross_orders[orderIndex] };

    updatedOrder[element] = value;

    updatedDate.gross_orders[orderIndex] = updatedOrder;
    updatedDriver.gross_reports[dateIndex] = updatedDate;

    setReports((prevReports) => {
      const updatedReports = { ...prevReports };
      updatedReports.drivers_weekly_pay_reports[driverIndex] = updatedDriver;
      return updatedReports;
    });
  };

  const handleDeductionsChange = (driverIndex, recordIndex, element, value) => {
    const report = _.cloneDeep(reports);
    // report.drivers_weekly_pay_reports[driverIndex].driver_deduction[
    //   recordIndex
    // ][element] = value;
    // setReports(report);
    // ==== //
    if(report.drivers_weekly_pay_reports[driverIndex] && report.drivers_weekly_pay_reports[driverIndex].driver_deduction && report.drivers_weekly_pay_reports[driverIndex].driver_deduction[
      recordIndex
    ]){
      report.drivers_weekly_pay_reports[driverIndex].driver_deduction[
        recordIndex
      ][element] = value;
      setReports(report);
    }
    // const driverIndex = _.findIndex(report.drivers_weekly_pay_reports, { driver_id })
    // if(driverIndex >= 0){
    //   const driverIndex = _.findIndex(report.drivers_weekly_pay_reports, { driver_id })
    //   const orderIndex = _.findIndex(report.drivers_weekly_pay_reports, { driver_id });
    //   if(orderIndex >= 0){

    //   }
    // }
  };

  const renderSearch = () => {
    
    return (
      <Fragment>
        <h4>
          Company: {"  "}
          {reports.company_name}
        </h4>
        <Row gutter={14} type="flex">
          <Col>
            {`Select ${I18n.t("general.week")} `}
            <WeekPicker
              allowClear={false}
              size="small"
              style={{ width: 200 }}
              placeholder="Select Week"
              onChange={(date, dateString) =>
                handleChange("weekPeriodString", date)
              }
              value={filterData.weekPeriodString}
            />
          </Col>
          {filterData.weekPeriodString && (
            <Col className="textBold">
              Search by:&nbsp;
              {filterData.weekPeriodString &&
                getWeekString(filterData.weekPeriodString)}
            </Col>
          )}
          
        </Row>
      </Fragment>
    );
  };

  const renderActionButtons = () => {
    const isModified = !_.isEqual(reports, originalReports);

    return (
      <div className="alignRight">
        <Button
          type="primary"
          size="small"
          icon="file-pdf"
          className="cursorPointer"
          onClick={() => getReports(true)}
        >
          {I18n.t("general.export")}
        </Button>
        &nbsp;&nbsp;
        {/* {!isApproved && isSaved && ( */}
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.approve_confirm")}
            onConfirm={() => {
              if (isSaved) {
                handleApprove();
              } else {
                alert("Save first");
              }
            }}
            okText="Yes"
            cancelText="No"
            disabled={!isSaved || isLoading}
          >
            <Button size="small" type="primary" icon="file-done" disabled={!isSaved || isLoading}>
              Approve
            </Button>
          </Popconfirm>
        &nbsp;&nbsp;
        {/* {!isApproved && ( */}
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.action_confirm", {
              action: "save",
              field: "",
            })}
            onConfirm={handleSaveDetails}
            okText="Yes"
            cancelText="No"
            disabled={isLoading}
          >
            <Button size="small" type="primary" className="buttonGreen" icon="save" disabled={isLoading}>
              Save Details
            </Button>
          </Popconfirm>
      </div>
    );
  };
  const calculateSubTotal = (data) => {
    return data.reduce((total, record) => {
      const grossPay = parseFloat(record.gross_pay || 0);
      return total + grossPay;
    }, 0);
  };
  const calculateTotalDeductions = (data) => {
    return data.reduce((total, record) => {
      const deductionAmount = parseFloat(record.amount || 0);
      return total + deductionAmount;
    }, 0);
  };
  const calculateTotalGrossRevenue = (data) => {
    return data.reduce((total, record) => {
      const grossRevenue = parseFloat(record.gross_revenue || 0);
      return total + grossRevenue;
    }, 0);
  };

  const canelShowModal = (isModified = false) => {
    setCurrentDateRec({});
    setShowModal("");
    if (isModified === true) {
      getReports();
    }
  };

  const handleShowModal = () => {
    if (showModal === "addOrder") {
      return handleAddOrder();
    } else {
      return handleAddDeduction();
    }
  };

  const findCurrectRec = (record, type) => {
    const drivers_weekly_pay_reports = reports.drivers_weekly_pay_reports || [];
    const driverRecord = _.find(drivers_weekly_pay_reports, {
      driver_id: record.driver_id,
    });
    if (!isEmpty(driverRecord)) {
      if (type === "addDeduction") {
        setCurrentDateRec(driverRecord);
        setShowModal(type);
      } else {
        const driverValues = _.pick(driverRecord, [
          "driver_id",
          "driver_code",
          "driver_name",
          "driver_pay_report_id",
        ]);
        const dateRecord = driverRecord?.gross_reports
          ? driverRecord.gross_reports[record.dateIndex]
          : {};
        setCurrentDateRec({ ...dateRecord, ...driverValues });
        setShowModal(type);
      }
    }
  };

  const handleAddOrder = () => {
    const gross_orders = currentDateRec.gross_orders || [];
    return (
      <BaseModal
        title={
          <Fragment>
            Add order manually for the driver #
            {currentDateRec.driver_code}
          </Fragment>
        }
        className="customer-orders-modal"
        onCancel={() => {
          canelShowModal();
        }}
        width={650}
        style={{
          top: 120,
        }}
      >
        <AddOrdersToWeeklyReport
          currentRecord={currentDateRec}
          size="small"
          grossOrders={gross_orders}
          driverId={currentDateRec.driver_id}
          driverPayId={currentDateRec.driver_pay_report_id}
          handleResponse={() => {
            canelShowModal(true);
          }}
          onCancel={canelShowModal}
          accounts={accounts}
        />
      </BaseModal>
    );
  };

  const handleAddDeduction = () => {
    const deductions = currentDateRec.deductions || [];
    return (
      <BaseModal
        title={
          <Fragment>
            Add deduction to the week{" "}
            {filterData.weekPeriodString &&
              getWeekString(filterData.weekPeriodString)}{" "}
            for the driver #{currentDateRec.driver_code}
          </Fragment>
        }
        className="customer-orders-modal"
        onCancel={() => {
          canelShowModal();
        }}
        width={650}
        style={{
          top: 120,
        }}
      >
        <AddDeductionsToWeeklyReport
          currentRecord={currentDateRec}
          size="small"
          deductions={deductions}
          driverId={currentDateRec.driver_id}
          driverPayId={currentDateRec.driver_pay_report_id}
          handleResponse={() => {
            canelShowModal(true);
          }}
          onCancel={() => canelShowModal()}
          accounts={accounts}
          glCodesList={glCodesList || []}

        />
      </BaseModal>
    );
  };

  const renderList = () => {
    const drivers_weekly_pay_reports = reports.drivers_weekly_pay_reports || [];
    return (
      <Fragment>
        {drivers_weekly_pay_reports.map((rec, driverIndex) => {
          const gross_reports = rec.gross_reports || [];
          const gross_orders = _.flattenDeep(
            gross_reports.map((gross, dateIndex) =>
              gross.gross_orders.map((order, orderIndex) => ({
                ...order,
                orderIndex,
                dateIndex,
                date: gross.date,
                driver_id: rec.driver_id,
                driver_pay_report_id: rec.driver_pay_report_id,
                is_last_record: orderIndex === gross.gross_orders.length-1
              }))
            )
          );

          const subTotal = calculateSubTotal(gross_orders);
          const totalDeductions = calculateTotalDeductions(
            rec.driver_deduction
          );
          const totalGrossRevenue = calculateTotalGrossRevenue(gross_orders);
          return reports?.drivers_weekly_pay_reports?.length > 0 ? (
            <Fragment>
              <h4>
                {rec.driver_code} ({rec.driver_name})
                {rec.invoice_number && (
                  <span className="marginLeft10 textBold primaryText">
                    Invoice: #<CopyableContent text={rec.invoice_number} />
                  </span>
                )}
              </h4>
              <Row gutter={16} className="fontSize12">
                <Col span={12}>
                  <div style={{ width: '100%', overflowX: 'auto' }}>
                  {/* <DriversWeeklyReportsList
                    tableLayout="fixed"
                    //rowKey="customer_order_id"
                    data={gross_orders}
                    // isApproved={isApproved}
                    size="small"
                    style={{ width: 720 }}
                    //scroll={{ y : 300  }}
                    //handleChange={(order_id, element, value)=> handleOrderChange(rec.driver_id, order_id, element, value)}
                    handleOnChange={(dateIndex, orderIndex, element, value) =>
                      handleOrderChange(
                        driverIndex,
                        dateIndex,
                        orderIndex,
                        element,
                        value
                      )
                    }
                    deleteClick={deleteOrder}
                    pagination={{ position: "none" }}
                    footer={() => (
                      <div style={{ width: 800 }}>
                        <Row type="flex" className="fontSize12 textBold">
                          <Col style={{ width: 520 }} className="alignRight">
                            Sub Total($)
                          </Col>

                          <Col
                            style={{ width: 110 }}
                            className="alignRight paddingRight10"
                          >
                            <div>{checkNegitive(totalGrossRevenue)}</div>
                          </Col>

                          <Col style={{ width: 110 }} className="alignRight">
                            <div>{checkNegitive(subTotal)}</div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    handleAddRow={(record) => {
                      findCurrectRec(record, "addOrder");
                    }}
                  /> */}
                  <DriversWeeklyReportsRowList 
                    tableLayout="fixed"
                    //rowKey="customer_order_id"
                    data={gross_orders}
                    // isApproved={isApproved}
                    size="small"
                    style={{ width: isAirCargo() ? 820 : 720 }} //720
                    //scroll={{ y : 300  }}
                    //handleChange={(order_id, element, value)=> handleOrderChange(rec.driver_id, order_id, element, value)}
                    handleOnChange={(dateIndex, orderIndex, element, value) =>
                      handleOrderChange(
                        driverIndex,
                        dateIndex,
                        orderIndex,
                        element,
                        value
                      )
                    }
                    deleteClick={deleteOrder}
                    pagination={{ position: "none" }}
                    footer={() => (
                      <div style={{ width: isAirCargo() ? 800 : 700 }}>
                        <Row type="flex" className="fontSize12 textBold">
                          <Col style={{ width: isAirCargo() ? 520 : 420 }} className="alignRight">
                            Sub Total($)
                          </Col>

                          <Col
                            style={{ width: 110 }}
                            className="alignRight paddingRight10"
                          >
                            <div>{checkNegitive(totalGrossRevenue)}</div>
                          </Col>

                          <Col style={{ width: 110 }} className="alignRight">
                            <div>{checkNegitive(subTotal)}</div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    calculateTotalGrossRevenue={calculateTotalGrossRevenue}
                    handleAddRow={(record) => {
                      findCurrectRec(record, "addOrder");
                    }}
                    calculateSubTotal={calculateSubTotal}
                    isAirCargo={isAirCargo}
                    orgContext={orgContextData}
                  />

                  </div>
                </Col>
                <Col span={8}>
                  <DriversWeeklyDeductionList
                    size="small"
                    tableLayout="fixed"
                    //isApproved={isApproved}
                    data={rec.driver_deduction || []}
                    pagination={{ position: "none" }}
                    style={{ width: 420 }}
                    //scroll={{ y : 300 }}
                    handleOnChange={(recordIndex, element, value) =>
                      handleDeductionsChange(
                        driverIndex,
                        recordIndex,
                        element,
                        value
                      )
                    }
                    totalRecords={rec.driver_deduction.length}
                    deleteClick={() => {}}
                    addRow={() => {
                      findCurrectRec(rec, "addDeduction");
                    }}
                    footer={() => (
                      <Fragment>
                        <Row type="flex" className="fontSize12 textBold">
                          <Col
                            style={{ width: 280 }}
                            className="alignRight paddingRight10"
                          >
                            Total Deduction
                          </Col>

                          <Col style={{ width: 120 }} className="alignRight">
                            <div>{checkNegitive(totalDeductions)}</div>
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                    claimBalance={drivers_weekly_pay_reports[driverIndex]?.claim_balance}
                    loanBalance={drivers_weekly_pay_reports[driverIndex]?.loan_balance}
                    advanceBalance={drivers_weekly_pay_reports[driverIndex]?.advance_balance}
                  />
                </Col>
                <Col span={4}>
                  <Row>
                    <Col span={24} className="paddingTop10 fontSize12 textBold">
                      <h4>Summary</h4>
                      <Row className="custom_line">
                        <Col xs={15} className="textBold">
                          {I18n.t("reports.total_net")}(
                          {I18n.t("general.price_symbol")})
                        </Col>
                        <Col xs={1}>: </Col>
                        <Col xs={6} className="alignRight">
                          {checkNegitive(subTotal, false)}
                        </Col>
                      </Row>
                      <Row className="custom_line">
                        <Col xs={15} className="textBold">
                          {I18n.t("reports.total_deductions")}
                        </Col>
                        <Col xs={1}>: </Col>
                        <Col xs={6} className="alignRight">
                          {checkNegitive(totalDeductions, false)}
                        </Col>
                      </Row>
                      <Row className="custom_line">
                        <Col xs={15} className="textBold">
                          {I18n.t("reports.payable_amount")}(
                          {I18n.t("general.price_symbol")})
                        </Col>
                        <Col xs={1}>: </Col>
                        <Col xs={6} className="alignRight">
                          {checkNegitive(subTotal - totalDeductions, false)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col> 
              </Row>
              <Divider style={{ margin: "5px 0px" }} />
            </Fragment>
          ) : (
            <Empty />
          );
        })}
      </Fragment>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col span={12}>{renderSearch()}</Col>
        <Col span={12}>
          {reports?.drivers_weekly_pay_reports?.length > 0 &&
            renderActionButtons()}
           { reports.last_activity_at && 
             <h4 className="textBold textGreen alignRight marginTop5 paddingRight10  ">Last Activity At:&nbsp;
            <DisplayTime 
            displayWithDate={true} 
            timeZoneId={whDetails?.timeZoneId || ''}
            dateTimeString={reports.last_activity_at}
          /></h4>}
        </Col>
      </Row>
      <Divider style={{ margin: "10px 0px" }} />

      <div
        style={{ overflowY: "auto", maxHeight: "570px", overflowX: "hidden" }}
        className="weeklyReports"
      >
        {renderList()}
      </div>
      {!isEmpty(showModal) && handleShowModal()}
    </Spin>
  );
};

export default DriverWeeklyReports;
