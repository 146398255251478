import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import { checkServiceExistance, toScreenConfigFormat } from "../../helpers/common";
import { ScreenKeys } from "../../containers/constants";
import LocationsGridsComponent from "./LocationsGridsComponent";
// import { setGridConfigSetting } from "../api/DisplaySettings";
import { alertMessage } from "../../common/Common";
import { getPrePlanCol, getWarehouseCols } from "../../components/common/columnDefs";
import { getStoredColumns,getConfiguredColumns } from "../../components/orders/helpers";
import { setGridConfigSetting } from "../../api/DisplaySettings";


const LocationsGridList = (props) => {
  const { rawDataSource, accountCodesWithExceededLimit = [], userContext, tableName} = props;
  const { updateColWidths, currentUser } = userContext || {};
  const [ filteredRowData, setFilteredRowData ] = useState([]);
  const tableColMapping = {
    [ScreenKeys.WAREHOUSE_LISTING] : getWarehouseCols(props)
  };

  const allCols = _.get(tableColMapping, tableName, []);
  const [ columnDefs, setColumnDefs ] = useState(allCols);

  useEffect(() => {
    const modifiedData = rawDataSource?.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    });
    setFilteredRowData(modifiedData);
  }, [ rawDataSource, accountCodesWithExceededLimit ]);

  const ProcessCols = () => {
    const columns = [];
    const hasSomeConfiguration = props.displayConfiguration && (props.displayConfiguration.userConfiguration || props.displayConfiguration.orgConfiguration);
    const storedColumns = getStoredColumns(currentUser, tableName);

    if (!hasSomeConfiguration) {
      columns.push(...allCols);
    } else {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      columns.push(...getConfiguredColumns(allCols, storedColumns, userConfiguration, orgConfiguration, tableName));
    }

    setColumnDefs(columns);
  };


  useEffect(() => {
    ProcessCols();
  }, [ props.displayConfiguration, props.vhTypes ]);

  const handleSaveColumnWidth = ({ colId, newWidth, colState }) => {
    let newColumnDefs = [];
    colState.forEach((col) => {
      if (col.colId === '0') {
        // newColumnDefs.push(checkboxColumn);
      }
      else if (col.colId === 'items') {
        newColumnDefs.push(itemsColdef);
      }
      else {
        const foundCol = _.find(columnDefs, { field: col.colId });
        newColumnDefs.push({ ...foundCol, width: col.width });
      }
    }
    );
    setColumnDefs(newColumnDefs);

    const finalColumnDefs = colState.map((col) => { return { key: col.colId, width: col.width }; });
    updateColWidths(tableName, toScreenConfigFormat(finalColumnDefs));
  };

  const handleSaveColumnIdx = useCallback((colState) => {
    let newOrder = colState.map((col) => col.colId);
    const { currentUser } = userContext;
    const { _id: userId, currentOrg } = currentUser;
    const data = {
      organization_id: currentOrg._id,
      config_type: tableName,
      config_value: newOrder,
      user_id: userId
    };

    setGridConfigSetting(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"), "success", 10);
      } else {
        alertMessage(result.errors[0], "error", 10);
      }
    }
    );

  }, [ tableName ]);

  return (
    <LocationsGridsComponent
    columnDefs={ columnDefs }
    rowData={ filteredRowData }
    onRowSelection={ props.onRowSelection }
    // onSortChanged={ props.onSortChanged }
    onSortChanged={ (colState) => {
      const sortedCol = colState[ 0 ];
      console.log('colState', colState);
      const { colId, sort } = sortedCol; props.onSort(colId, sort);
    }
    }
    totalCols={ allCols }
    saveColumnWidth={ handleSaveColumnWidth }
    onColumnMoved={ handleSaveColumnIdx }
    setCurrentGridRef={ props.setCurrentGridRef }
    handleColumnVisibilityChange={ props.handleColumnVisibilityChange }
    showRerraangeModal={props.showRerrangeableModal}
    closeModal={ props.closeModal }
    isRowSelectable={ props.isRowSelectable }
    rawDataSource ={rawDataSource}
    />
  );
};


export default LocationsGridList;

LocationsGridList.propTypes = {
  rawDataSource: PropTypes.array,
  accountCodesWithExceededLimit: PropTypes.array,
  userContext: PropTypes.object,
  tableName: PropTypes.string,
  onRowSelection: PropTypes.func,
  onSort: PropTypes.func,
  vhTypes: PropTypes.array,
  displayConfiguration: PropTypes.object,
  accountsList: PropTypes.array,
  zonesList: PropTypes.array,
  handleColumnVisibilityChange: PropTypes.func,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  isRowSelectable: PropTypes.func
};

LocationsGridList.defaultProps = {
  rawDataSource: [],
  accountCodesWithExceededLimit: [],
  userContext: {},
  tableName: '',
  onRowSelection: () => { },
  onSort: () => { },
  vhTypes: [],
  displayConfiguration: {},
  accountsList: [],
  zonesList: [],
  handleColumnVisibilityChange: () => { },
  modalOpen: false,
  closeModal: () => { },
  isRowSelectable: () => { }

};