import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Spin,
  Tooltip,
  Link,
} from "../../../common/UIComponents";
import { alertMessage, base64ToPdf } from "../../../common/Common";
import userStore from "../../../stores/UserStore";
import {
  fetchDriverInvoiceDetail,
  fetchDriverPayReport,
} from "../../../api/Reports";
import ReportDetails from "../ReportDetails";
import BaseModal from "../../BaseModal";
import Copyable from "../../common/Copyable";
import DriverInvoiceDetails from "../DriverInvoiceDetails";
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";

const ShowDriverInvoice = ({
  record,
  //   invoice_id,
  //   orders = [],
  //   displayType = "manage",
  //   handleSuccess = () => {},
  //   styleObj = {},
}) => {
  const orgId = userStore.getStateValue("selectedOrg");
  const [isLoading, setIsLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [grossOrders, setGrossOrders] = useState({});
  const [deduction, setDeduction] = useState({});
  const [summary, setSummary] = useState({});
  const [data,setData] = useState({});


  const handleDriverPayView = () => {
    setIsLoading(true);
    fetchDriverInvoiceDetail(record.driver_pay_report_id).then((result) => {
      console.log("driver pay invoice-------->", result);
      if (result.success) {
        setData(result.reports);
        const orders = result.reports?.gross_reports.map(rec => rec.gross_orders ? rec.gross_orders.map(order => ({
          ...order,
          date:rec.date
        })) : []) || []
        setGrossOrders(_.compact(_.flattenDeep(orders)));
        setDeduction(result.reports.driver_deduction);
        setSummary(result.reports.summary)
        setIsLoading(false);
          setShowModal(true);
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
    // fetchDriverPayReport(record.customer_order_ids, record.driver_pay_report_id).then(
    //   (result) => {
    //     if (result.success) {
    //       const reports = result.reports || [];
    //       const updateRecord = reports[0] ? { ...reports[0] } : {};
    //       updateRecord.claim_balance = result.claim_balance;
    //       updateRecord.loan_balance = result.loan_balance;
    //       updateRecord.advance_balance = result.advance_balance;
    //       setCurrentRecord({ ...updateRecord });
    //       setIsLoading(false);
    //       setShowModal(true);
    //     } else {
    //       alertMessage(result.errors[0], "error", 10);
    //       setIsLoading(false);
    //     }
    //   }
    // );
  };

  const closeWindow = () => {
    setShowModal(false);
    setCurrentRecord({});
  };

  const renderWindow = () => {
    return (
      <BaseModal
        title={I18n.t("general.details")}
        width="90%"
        style={{
          top: 20,
          height: "90%",
        }}
        // bodyStyle={{
        //   height: "100%",
        //   overflowY: "auto",
        // }}
        bodyStyle={{
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          padding: 10,
        }}
        visible={showModal}
        onCancel={() => closeWindow()}
      >
        <Spin spinning={isLoading}>
          <DriverInvoiceDetails
            report={grossOrders}
            data={data}
            driverId={record.driver_id}
            driverPayId={record.id}
            currentDriverPayRecord={record}
            driverPayReports={[]}
            deductions={deduction}
            summary={summary}
            currentDriver={{}}
          />
        </Spin>
      </BaseModal>
    );
  };

  return (
    <Fragment>
      <Link onClick={handleDriverPayView}>{record.invoice_number}</Link>&nbsp;
      <Copyable text={record.invoice_number} />
      {showModal && renderWindow()}
    </Fragment>
  );
};

export default ShowDriverInvoice;
