import I18n from "../common/I18n";

function getOrderUpdateMessage(message) {
  const receivedStatus = message.sub_action;
  switch (receivedStatus) {
    case "STATUS":
      return "Order status changed.";
    case "ITEMS_CREATED":
      return "New Item created.";
    case "ITEMS_UPDATED":
      return "Item updated.";
    case "ITEMS_DELETED":
      return "Item got deleted.";
    case "PICTURES":
      return "Pod's got updated";
    case "APPOINTMENTS":
      return "Appointments updated";
    case "ACCESSORIALS":
      return "Accessorials Updated";
    case "ORDER_UPDATED":
      return "order get updated";
    case "ORDER_DELETED":
      return "Order deleted";
    case "PICTURES_UPDATED":
      return "POD's got updated";
    case "STOP_ADDED":
      return "New stop get added";
    case "STOP_DELETED":
      return "Stop get deleted";
    case "SWAPPED":
      return "Two routes got swapped";
    case "ROUTE_OPTIMIZED":
      return "Route got optimized";
    case "ROUTE_REVERTED":
      return "Assigned Route get reverted back";
    case "ROUTE_ASSIGNED":
      return "Route got assigned";
    case "TRANSMIT_ORDERS":
      return "";
    case "TRANSMIT_STOP":
      return "";
    case "STATUS_CHANGED":
      return "Status get changed";
    case "RESEQUENCED":
      return "stop get resequenced";
    default:
      return "";
  }
}

export default {
  actions: {},
};

export { getOrderUpdateMessage };
