import React, { Component, Fragment } from "react";
import {
  Col,
  FormItem,
  Row,
  Select,
  Button,
  Popconfirm,
  Alert,
  Checkbox,
  Spin,
  Modal
} from "../../common/UIComponents";
import { copyAccount } from "../../api/Account";
import userStore from "../../stores/UserStore";
import { alertMessage } from "../../common/Common";
import { isEmpty } from "lodash";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";
import I18n from "../../common/I18n";

const { confirm, warning } = Modal;
const defaultAccountSettings = () => {
  const codes = {};
  AppConfig.accountSetting
    .filter((setting) => setting.value)
    .forEach((setting) => {
      codes[setting.code] = {
        name: setting.name,
        isSelected: true,
        selectedBy: [setting.code],
        dependencies: [...setting.dependencies]
      }
    });

  return codes;
};

class AccountClone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      accounts: this.props.accounts || [],
      sourceAccount: "",
      targetAccount: [],
      accountCopyInfoText: "",
      account_settings: AppConfig.accountSetting,
      settings: defaultAccountSettings(),
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps,prevState) {
    if(prevProps.accounts !== this.props.accounts) {
      this.setState({accounts : this.props.accounts})
    }
  }

  handleOnChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      if (
        !isEmpty(this.state.sourceAccount) &&
        this.state.targetAccount.length
      ) {
        const infoText =
          this.state.targetAccount
            .map((key) => {
              const account = this.state.accounts.find((i) => i.id === key);
              return account.name;
            })
            .join(", ") +
          " settings will be copied from " +
          this.state.accounts.find((i) => i.id === this.state.sourceAccount)
            .name;
        this.setState({ accountCopyInfoText: infoText });
      } else {
        this.setState({ accountCopyInfoText: "" });
      }
    });
  };

  askConfirmMessage = (code, dependencies, value) => {
    if (!value) {
      const settings  = _.cloneDeep(this.state.settings);
      const selectedOne = settings[code]
      delete settings.code;
      const names = Object.values(settings).filter(rec => 
        rec.isSelected && rec.dependencies.includes(code)).map(rec => rec.name)
      if(names.length > 0){
        warning({
          title : `Please Deselect the ${names.join(',')} first, as the ${selectedOne.name}  is required for selected ${names.join(',')}.`,
          content: "",
          onOk: () => {}, // this.handleCheckboxChange(code, dependencies, value);
          okText: "Ok",
          okType: "danger",
          cancelText: "No",
          onCancel: () => {},
        });
      } else {
        this.handleCheckboxChange(code, dependencies, value);
      }
    } else {
      const settings  = _.cloneDeep(this.state.settings);
      const dependenciesList = [...this.state.settings[code].dependencies];
        const names = dependenciesList.filter(rec => 
          !settings[rec].isSelected).map(rec => settings[rec].name);
        if(names?.length){
          const selectedOne = settings[code]
          confirm({
            title : `To clone ${selectedOne.name}, the ${names.join(', ')} ${ names?.length > 1 ? 'are also' :'is also'} necessary`,
            content : "Would you like to proceed by selecting it ?",
            onOk: () => { this.handleCheckboxChange(code, dependencies, value); },
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onCancel: () => {},
          });
      } else {
        this.handleCheckboxChange(code, dependencies, value);
      }
    }
  };

  handleCheckboxChange = (code, dependencies, value) => {
    const { settings } = this.state;
    const updatedSelectedSettings = { ...this.state.settings };
    if (value) {
      updatedSelectedSettings[code].isSelected = true;
      updatedSelectedSettings[code].selectedBy.push(code)
      if(dependencies?.length > 0){
        dependencies.forEach((dependency) => {
          if (!updatedSelectedSettings[dependency].isSelected) {
            updatedSelectedSettings[dependency].isSelected = true;
          }
          updatedSelectedSettings[dependency].selectedBy.push(code);

        });
      }
    } else {
      updatedSelectedSettings[code].isSelected = false;
      updatedSelectedSettings[code].selectedBy = settings[code].selectedBy.filter(rec => rec !== code )
      if(dependencies?.length > 0){
        dependencies.forEach((dependency) => {
          if (updatedSelectedSettings[dependency].isSelected) {
            const selCodes = settings[dependency].selectedBy.filter(rec => rec !== code );
            updatedSelectedSettings[dependency].isSelected = selCodes?.length > 0 ? true : false;
            updatedSelectedSettings[dependency].selectedBy = selCodes;
          }
        });
      }
    }

    // Check if the dependency is already selected or not
    this.setState({
      settings: {...updatedSelectedSettings}
    })
  };

  onCheckAllChange = (e) => {
    const settings = _.cloneDeep(this.state.settings);
    Object.keys(settings).forEach(code => {
      settings[code].isSelected = e.target.checked || false;
      settings[code].selectedBy =  e.target.checked ? [code] : [];
    })
    this.setState({
      settings
    });
  };

  onCloneAccount = () => {
    const { sourceAccount, targetAccount, account_settings, settings } =
      this.state;
    this.setState({ inProgress: true });
    const payload = {
      organization_id: userStore.getStateValue("selectedOrg"),
      account_id: sourceAccount,
      d_account_ids: targetAccount,
      each_setting: account_settings.map((setting) => ({
        code: setting.code,
        value: settings[setting.code].isSelected || false, // includes(setting.code) ? setting.value : false,
      })),
    };

    copyAccount(payload).then((result) => {
      if (result.success) {
        alertMessage(
          I18n.t("messages.successfully_done", {
            process: I18n.t("account.account_copy"),
          }),
          "success",
          5
        );
        this.setState({
          sourceAccount: "",
          targetAccount: [],
          settings: defaultAccountSettings(),
          accountCopyInfoText: "",
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  render() {
    const { account_settings, settings, sourceAccount, targetAccount } =
      this.state;
    const selectedLength = Object.values(settings).filter(rec => rec.isSelected).length
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
      <Fragment>
        <Row gutter={16}>
          <Col md={24}>
            <FormItem label={I18n.t("account.source_account")} require>
              <Select
                value={this.state.sourceAccount || ""}
                showSearch
                onChange={(e) => this.handleOnChange("sourceAccount", e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              >
                <Select.Option key="-1" value="">
                  -- Select --
                </Select.Option>
                {this.state.accounts
                  .filter(
                    (Acc) =>
                      !isEmpty(Acc.id) &&
                      !this.state.targetAccount.includes(Acc.id)
                  )
                  .map((accKey) => (
                    <Select.Option key={accKey.id} value={accKey.id}>
                      {`${accKey.name} (${accKey.code})`}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={24}>
            <FormItem label={I18n.t("account.target_account")} require>
              <Select
                placeholder={I18n.t("account.target_account")}
                value={this.state.targetAccount}
                showSearch
                mode="multiple"
                onChange={(e) => this.handleOnChange("targetAccount", e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              >
                {this.state.accounts
                  .filter(
                    (Acc) =>
                      !isEmpty(Acc.id) && Acc.id != this.state.sourceAccount
                  )
                  .map((accKey) => (
                    <Select.Option key={accKey.id} value={accKey.id}>
                      {`${accKey.name} (${accKey.code})`}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row className="marginBottom10">
          {this.state.accountCopyInfoText.length > 0 && (
            <Col>
              <Alert
                message={this.state.accountCopyInfoText}
                type="info"
                showIcon
              />
            </Col>
          )}
        </Row>
        <Row>
          <span style={{ fontWeight: 700 }}>
            {I18n.t("account.clone_setting_label")}:
          </span>
          <div
            style={{ borderBottom: "1px solid #E9E9E9" }}
            className="marginBottom10"
          >
            <Checkbox
              onChange={this.onCheckAllChange}
              checked={selectedLength === account_settings.length}
              indeterminate={
                selectedLength > 0 &&
                selectedLength !== account_settings.length
              }
            >
              {I18n.t("general.selectAll")}
            </Checkbox>
          </div>
          
            {account_settings.map((item) => (
              <Col xs={12}>
                <Checkbox 
                  checked={settings[item.code].isSelected || false} 
                  key={item.code}
                   onChange={(e) => {
                    this.askConfirmMessage(item.code, item.dependencies, e.target.checked)
                    // if(e.target.checked){
                    //   this.handleCheckboxChange(item.code, item.dependencies, e.target.checked)
                    // } else{
                    //   this.askConfirmMessage(item.code, item.dependencies, e.target.checked)
                    // }
                   }
                  }
                >
                  {item.name}
                </Checkbox>
              </Col>
            ))}
        </Row>
        <Row>
          <Col className="alignCenter marginTop10">
            <Popconfirm
              placement="topLeft"
              title={I18n.t("account.clone_confirmation")}
              onConfirm={this.onCloneAccount}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                type="primary"
                disabled={isEmpty(sourceAccount) || targetAccount.length < 1}
                icon='copy'
              >
                {I18n.t("account.clone_setting_button")}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Fragment>
      </Spin>
    );
  }
}
export default AccountClone;
