/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Spin, Tabs, Collapse, Drawer } from '../../common/UIComponents'
import OrderTitle from './OrderTitle'
import OrderDetails from '../Tasks/OrderDetails'
import SurveyResponse from '../surveys/SurveyResponse'
import BaseModal from '../BaseModal'

const { TabPane } = Tabs
const { Panel } = Collapse
class ShowOrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentOrder: this.props.order,
      orders: [],
      inProgress: false,
      showForm: false,
      showSurvey: false,
      surveyResponseId: '',
      detailsVisible: false,
      orderNo: this.props.order_no,
    }
  }

  getOrderDetails = () => {
    this.setState({
      detailsVisible: true,
    })
  }

  handleSurveyDetails = (id) => {
    this.setState({
      showSurvey: true,
      surveyResponseId: id,
    })
  }

  closeSurveyDetails = () => {
    this.setState({
      showSurvey: false,
    })
  }

  onClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  render() {
    const { orders } = this.state
    return (
      <Fragment>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <span
            onClick={this.getOrderDetails}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {this.state.orderNo}
          </span>
          {this.state.detailsVisible && (
            <Drawer
              title={
                <OrderTitle
                  order={this.state.currentOrder}
                />
              }
              placement="right"
              closable
              onClose={this.onClose}
              visible={this.state.detailsVisible}
              width="85%"
            >
              <OrderDetails
                order_id={this.state.currentOrder.id}
                surveyDetails={this.handleSurveyDetails}
                handleSearch={this.handleSearch}
                key={this.state.currentOrder.id}
              />
            </Drawer>
          )}

          {this.state.showSurvey && (
            <BaseModal
              title="Survey Details"
              onCancel={() => this.closeSurveyDetails()}
              className="surveyModal"
              width="70%"
              style={{ top: 40, overflowY: 'auto' }}
            >
              <SurveyResponse surveyId={this.state.surveyResponseId} />
            </BaseModal>
          )}
        </Spin>
      </Fragment>
    )
  }
}
ShowOrderDetails.propTypes = {
  order: PropTypes.shape().isRequired,
  reqFrom: PropTypes.string,
}
ShowOrderDetails.defaultProps = {
  reqFrom: '',
}

export default ShowOrderDetails
