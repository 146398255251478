import React from "react";
import { Col, Icon, Popover, Popconfirm, Row, Tag, Tooltip } from "antd";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import _ from "lodash";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import DisplayTime from "../common/DisplayTime";
import AppConfig from "../../config/AppConfig";
import { ellipseText, isEmpty } from "../../common/Common";
import moment from "moment";

function ScheduleList ({ scheduleConfig }) {
  const renderConfig = () => {
    if (scheduleConfig) {
      const { days, date, monthly, daily } = scheduleConfig;

      if (days) {
        return (
          <div>
            <Icon type="calendar" /> Days: { days.join(', ') }
          </div>
        );
      } else if (date) {
        return (
          <div>
            <Icon type="calendar" /> Date: { moment(date).format(AppConfig.date_format) }
          </div>
        );
      } else if (monthly) {
        return (
          <div>
            <Icon type="calendar" /> Monthly: { monthly }th day
          </div>
        );
      } else if (daily) {
        return (
          <div>
            <Icon type="calendar" /> Daily
          </div>
        );
      }
    }

    return null; // Handle the case where scheduleConfig is null or undefined
  };

  return (
    <div>
      { renderConfig() }
    </div>
  );
}



class PendingRecurringRoutesList extends BaseList {
  constructor (props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  setColumns = (props) => {
    this.columns = [
      {
        key: "name",
        title: "Route Name",
        dataIndex: "route_name",
        render: (data, record) => {
          return <span>{ data || "" }</span>;
        },
      },
      {
        key: 'Templates',
        title: 'TEMPLATES LIST',
        dataIndex: 'recurring_templates',
        render: (data, record) => {
          const all_uniq_template_names = _.uniq(_.map(data, (recurring_template) => {
            return recurring_template.template.template_name;
          }));
          return (
            <div>
              { !isEmpty(all_uniq_template_names) ? (
                <Popover content={ all_uniq_template_names.join(', ') }>
                  <span>{ ellipseText(all_uniq_template_names.join(', '), 25) }</span>
                </Popover>
              ) : (
                ""
              ) }
            </div>
          );

        }
      },
      {
        key: "Driver",
        title: "Driver",
        dataIndex: "driver_id",
        render: (data, record) => {
          const { drivers } = props;
          const driver = _.find(drivers, { id: data });
          return <span>{ driver ? driver.employee_code : "" }</span>;
        },
      },
      {
        key: "Start Time",
        title: "Start Time",
        dataIndex: "route_start_time",
        render: (data, record) =>
            // route_start_time : "23:30"
          data ? (
            <DisplayTime
              dateTimeString={ moment(data, AppConfig.time_format) }
              timeFormat={ AppConfig.time_format }
              displayWithDate={ true }
              isUTC={ false }
            />
          ) : (
            ""
          ),
      },
      {
        key: "Schedule",
        title: "Schedule",
        dataIndex: "schedule_config",
        render: (data, record) => {
          return <ScheduleList scheduleConfig={ data } />;
        }
      },
      {
        key: "actions",
        render: (data, record) => {
          const { handleDelete, handleEdit , createTemplateRoute } = this.props;
          const { id } = record;
          return (
            <Row type="flex" gutter={ 6 } className="line-actions">
              <Col>
                <Tooltip title={I18n.t("templates.create_route")}>
                  <Icon
                    type="plus-circle"
                    onClick={() => createTemplateRoute(id)}
                    className="SnippetsIcon"
                  />
                </Tooltip>
              </Col>
              <Col >
                <Tooltip title={ I18n.t("general.edit") }>
                  {/* <Icon type="edit" onClick={() => handleEdit(record)}/> */ }
                  <EditIcon handleClick={ () => handleEdit(record) } />
                  &nbsp;
                </Tooltip>
              </Col>
              <Col >
                <Tooltip title={ I18n.t("general.delete") }>
                  <Popconfirm
                    placement="left"
                    title={ I18n.t("messages.delete_confirm") }
                    onConfirm={ () => handleDelete(id) }
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon />
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
            // </div>
          );
        },
        width: "10%",
      },
    ];
  };

  componentWillReceiveProps (nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default PendingRecurringRoutesList;

PendingRecurringRoutesList.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  pagination: PropTypes.object,
};

PendingRecurringRoutesList.defaultProps = {
  handleDelete: () => { },
  handleEdit: () => { },
  pagination: {
    position: "none",
  },
};
