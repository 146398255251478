import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils';

export const VehicleTypeMappingApi = {


  create: (payload) => {
    payload.operation_code = 'VTC';
    const url = ApiUrl('v2/master_vehicle_types');
    const formData = setObjectToFormData(payload);
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { master_vehicle_types: 'master_vehicle_types' },
    });
  },

  createexport: (payload) => {
    // payload.operation_code = 'VTC';
    // const url = ApiUrl('v2/master_vehicle_types/export_vh_type_from_master_vh_types');
    const url = ApiUrl('v2/vehicle_types/export_vh_type_from_master_vh_types');
    const formData = setObjectToFormData(payload);
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { master_vehicle_types: '' },
    });
  },


  update: (id, payload) => {
    payload.operation_code = 'VTU'
    const url = ApiUrl('v2/master_vehicle_types/' + id)
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { master_vehicle_types: 'master_vehicle_types' },
    })
  },



  fetch: (page = 1, perPage = '') => {
    const url = ApiUrl(`v2/master_vehicle_types?${PageParams(page, perPage)}`);
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { master_vehicle_types: 'master_vehicle_types', pagination: 'meta.pagination' },
    });
  },

  delete: (id) => {
    const url = ApiUrl('v2/master_vehicle_types/' + id)
    return Call('delete', url, { operation_code: 'VTD' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  fetchOne: (id) => {

    const url = ApiUrl(`v2/master_vehicle_types/${id}?operation_code=VTS`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  },
}
