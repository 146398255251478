import { Col, Icon, Row,Table } from "antd";
import { join } from "lodash";
import React  from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import EditIcon from "../common/EditIcon";
import Copyable from "../common/Copyable";
export default class ClonedTemplateList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "#",
        key: "index",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: `${I18n.t("general.customer")} ${I18n.t("general.order_number")}`,
        dataIndex: "customer_order_number",
        key: "customer_order_number",
        // render: (data, record) => {
        //   return record?.errors.length > 0 ?
        //    <p>{record.errors.join(errors)}</p>
        //    :this.renderEditableOrder(record.customer_order_id, data);
        // },
        render: (data, record) => {
          const orderNumberElement = (
            <div>
              <span>{data}</span> {/* Display the order number */}
              <span> <Copyable text={data} /></span> {/* Display the copy symbol */}
            </div>
          );
        
          return (
            <>
              {/* {orderNumberElement} */}
              {record?.errors?.length > 0 ? (
                <p>{record.errors[0]}</p>
              ) : (
                this.renderEditableOrder(record.customer_order_id, orderNumberElement)
              )}
            </>
          );
        },
      },
      {
        title: `${I18n.t("templates.template name")}`,
        dataIndex: "template_name",
        key: "template_name       ",
        render: (data, record) => {
          return (record.template_id, data);
        },
      }
    ];
  }

  renderEditableOrder = (customer_order_id ,customer_order_number ) => {
    return (
      <Row type="flex" gutter={4}>
        <Col>
          { customer_order_number }
        </Col>
        <Col>
          <EditIcon handleClick={() => this.props.handleEditOrder(customer_order_id)}/>
        </Col>
      </Row>
    );
  }

  // renderEditableOrder = (orderId ,orderNumber ) => {
  //   return (
  //     <Row type="flex" gutter={4}>
  //       <Col>
  //         { orderNumber }
  //       </Col>
  //       <Col>
  //         <EditIcon handleClick={() => this.props.handleEditOrder(orderId)}/>
  //       </Col>
  //     </Row>
  //   );
  // }

  render() {
    return (
      <div>
        <Table
          dataSource={this.props.data}
          columns={this.columns}
          pagination={false}
        />
      </div>
    );
  }
}