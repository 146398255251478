/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
	Col,
	FormItem,
	Input,
	Row,
	Tabs,
	Checkbox,
	Select
} from "../../common/UIComponents";
import {alertMessage, doValidate, isEmpty, formatCode } from "../../common/Common";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import LosAdjustments from "./LosAdjustments";
import { saveLos } from "../../api/Los";
import { fetchAccountAccessorials as fetchAccountComponentAccessorials } from "../../api/Accessorials";
import AccessorialCodesList from "../configurations/accessorials/AccessorialCodesList";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import {DatePicker, Radio} from "antd"
import I18n from "../../common/I18n";
import { WarehouseContext } from "../../context/WarehouseContext";
import { fetchLocations } from "../../api/PredefinedStops";
import AppConfig from "../../config/AppConfig";
import moment from "moment";
import { fetchAccountBillingConfigs } from "../../api/Account";
import userStore from "../../stores/UserStore";

const requiredFields = [
	{
		form_field: "name",
		ui_name: I18n.t("general.name"),
		isRequired: true,
		inputType: "text",
	},
	{
		form_field: "code",
		ui_name: I18n.t("general.code"),
		isRequired: true,
		inputType: "code",
	},
	{
		form_field: "duration",
		ui_name: I18n.t("general.duration"),
		isRequired: false,
		inputType: "number",
	},
	{
		form_field: "accessorials",
		ui_name: I18n.t("accessorials.accessorial"),
		isRequired: false,
		inputType: "array",
	},
	{
		form_field: "effective_start_date",
		ui_name: I18n.t("accessorials.effective_start_date"),
		isRequired: true,
		inputType: "date",
	},
	{
		form_field: "effective_end_date",
		ui_name: I18n.t("accessorials.effective_end_date"),
		isRequired: false,
		inputType: "date",
	},
];

const { TabPane } = Tabs;
class Form extends Component {
	static contextType = WarehouseContext;
	constructor(props) {
		super(props);
		this.state = {
			currentRecord: this.props.currentLos ? this.props.currentLos : {},
			isNew: this.props.isNew,
			account: this.props.account ? this.props.account : {},
			inProgress: false,
			errors: [],
			accessorials: [],
			detailsSelectedAccessorials: [],
			serviceLevelSelectedAccessorials: [],
			checkedKeys: {
				selectedKeys: [],
				selectedRows: [],
			},
			activeKey: "details",
			predefinedLoc: [],
			configProcess: false,
			config: {},
		};
	}

	componentDidMount() {
		if (this.state.currentRecord.los_id) {
			this.setData();
		}
		this.getAccountAccessorials();
		this.getLocations()
		this.getAccountConfigs()
	}

	handleDetailsSelectChange = (selectedKeys) => {
		this.setState({
			detailsSelectedAccessorials: selectedKeys
		});
	};

	handleServiceLevelSelectChange = (selectedKeys) => {
		this.setState({
			serviceLevelSelectedAccessorials: selectedKeys
		});
	};


	getAccountConfigs = () => {
		this.setState({ configProcess: true });
		const { config } = this.state;
		const orgId = userStore.getStateValue("selectedOrg");
		fetchAccountBillingConfigs(orgId, this.state.account.id).then((result) => {
		  if (result.success) {
			const accountConfigurations = result.configurations || [];
			accountConfigurations
			  .filter((settingObj) => settingObj.config_type === "BILLING")
			  .forEach((setting) => {
				config[setting.setting_name] = setting.setting_value;
			  });
			this.setState(
			  {
				configProcess: false,
				config,			  
			  }, () => {
				if(this.state.isNew){
				  this.handleConfigChange(
					  "apply_fuel_surcharge",
					  config.apply_fuel_surcharge === "true"
				  )
				}
			  }
			)
		  } else {
			renderAlertMessage(result.errors)
			this.setState({ configProcess: false });
		  }
		});
	  };

	goToTab = (key) => {
		this.setState({
			activeKey: key,
		});
	};

	getLocations = () =>{
		const { warehouses = [] } = this.context;
		const payload = {
			page: 1,
			perPage: 50,
			searchText: "",
			sortBy: null,
			sortByType: null,
		};
		fetchLocations(payload).then((result) => {
			if (result.success) {
				const predefinedLoc = result.predefined_stops.predefined_stops || [];
				// Merge warehouses with predefinedLoc
        const mergedLocations = [...warehouses, ...predefinedLoc];
				this.setState({
					predefinedLoc: mergedLocations,
				},()=>{
          //this.setInitialWhData();
        });
			} else {
				renderAlertMessage(result.errors)
			}
		})
	}

	getAccountAccessorials = () => {
		fetchAccountComponentAccessorials(
			this.state.account.organization_id,
			this.state.account.id,
			"BILLING"
		).then((result) => {
			if (result.success) {
				const accessorials = result.accessorials || [];
				const losAccessorial = this.state.currentRecord.accessorial || [];
				const losServiceLevelAccessorials = this.state.currentRecord.service_level_accessorials || [];

				const initialDetailsSelected = losAccessorial || [];
				const initialServiceLevelSelected = losServiceLevelAccessorials || [];
				this.setState({
					accessorials,
					inProgress: false,
					detailsSelectedAccessorials: initialDetailsSelected,
					serviceLevelSelectedAccessorials: initialServiceLevelSelected,
					checkedKeys: {
						selectedKeys: losAccessorial,
						selectedRows: this.state.accessorials.filter((accessorialRecord) =>
							losAccessorial.includes(accessorialRecord.code)
						),
					},
				});
			} else {
				renderAlertMessage(result.errors)
				this.setState({ inProgress: false });
			}
		});
	};

	setData = () => {
		this.setState({
			currentRecord: Object.assign({}, this.state.currentRecord, {
				name: this.state.currentRecord.los_name,
				code: this.state.currentRecord.los_code,
				id: this.state.currentRecord.los_id,
			}),
		});
	};

	setInitialWhData = () => {
    const { selectedWarehouses = null, warehouses = [] } = this.context;
    const currentWhId = Array.isArray(selectedWarehouses)
      ? selectedWarehouses
      : selectedWarehouses
      ? [selectedWarehouses]
      : null;
    if( this.state.isNew || isEmpty(this.state.currentRecord?.distance_from_location_id) ){
      this.setState({
        currentRecord: Object.assign({}, this.state.currentRecord, {
          distance_from_location_id: currentWhId[0],
        }),
      });
    }
  };


	clearForm = () => {
		this.setState({
			currentRecord: {},
			isNew: true,
			checkedKeys: {
				selectedKeys: [],
				selectedRows: [],
			},
		});
	};

	handleOnChange = (element, value, cb = null) => {
		this.setState(
		  {
			currentRecord: Object.assign({}, this.state.currentRecord, {
			  [element]: value,
			}),
		  },
		  () => {
			if (cb) {
			  cb();
			}
		  }
		);
	};
	  

	handleConfigChange = (element, value) => {
		const { currentRecord } = this.state;
		const configurations = currentRecord.configurations || {};
		configurations[element] = value;
		if (element === "consider_distance_from_loc") {
		  if (value) {
			this.handleOnChange("configurations", configurations, () => {
			  this.setInitialWhData();
			});
		  } else {
			this.handleOnChange("configurations", configurations, () => {
			  this.handleOnChange("distance_from_location_id", "");
			});
		  }
		} else {
		  this.handleOnChange("configurations", configurations);
		}
	  };
	  

	handleSave = () => {
		const currentRecord = Object.assign({}, this.state.currentRecord);
		const configurations = currentRecord.configurations || {}; 
		const los_range_value =  configurations?.los_range ? configurations.los_range : "WEIGHT";
		const data = {
			name: currentRecord.name || "",
			duration: currentRecord.duration || "",
			code: currentRecord.code ? currentRecord.code : "",
			account_id: this.state.account.id,
			org_id: this.state.account.organization_id,
			accessorial: this.state.detailsSelectedAccessorials,
			service_level_accessorials: this.state.serviceLevelSelectedAccessorials,
			// distance_from_location_id: currentRecord?.distance_from_location_id ? currentRecord.distance_from_location_id : "",
			configurations: {
				min_charge_as_base_charge: configurations?.min_charge_as_base_charge ? configurations.min_charge_as_base_charge : false,
				break_weight: configurations?.break_weight? configurations.break_weight : false,
				los_range: los_range_value,
				apply_min_max_charge_on_each_range: configurations?.apply_min_max_charge_on_each_range? configurations.apply_min_max_charge_on_each_range : false,
       			consider_distance_from_loc: configurations?.consider_distance_from_loc? configurations.consider_distance_from_loc : false,
		        apply_fuel_surcharge: configurations?.apply_fuel_surcharge ? configurations.apply_fuel_surcharge : false,
			},
			effective_start_date: currentRecord?.effective_start_date ? moment(currentRecord.effective_start_date).format("YYYY-MM-DD"): null,
			effective_end_date: currentRecord?.effective_end_date ? moment(currentRecord.effective_end_date).format("YYYY-MM-DD"): null,
			}
		if (!this.state.isNew) {
			data.id = currentRecord.id;
		}
		if (configurations?.consider_distance_from_loc) {
    	  data.distance_from_location_id = currentRecord?.distance_from_location_id
        ? currentRecord.distance_from_location_id
        : "";
		}	
		const errors = doValidate(requiredFields, data);
		if (errors.length > 0) {
				this.goToTab('details');
		}
		if (errors.length === 0) {
			//data.code = !isEmpty(data.code) ? data.code.replace(" ", "") : "";
			data.code = formatCode(data.code);
			this.setState({ inProgress: true });
			saveLos(data, this.state.isNew).then((result) => {
				if (result.success) {
					alertMessage(I18n.t("messages.saved"));
					const los = result.los || {};
					this.setState({
						errors: [],
						inProgress: false,
						currentRecord: {
						  ...los,
						  effective_start_date: los?.effective_start_date ? 
							moment.utc(los.effective_start_date) : null,
						  effective_end_date: los?.effective_end_date ? 
							moment.utc(los.effective_end_date) : null,

						},
					});
					if (this.state.isNew) {
						if ( this.state.serviceLevelSelectedAccessorials.length === 0) {
							this.goToTab('service_level_accessorials');
						} else {
							this.goToTab('adjustments');
						}
						this.setState({
							isNew: false,
						});
					}
					// this.props.handleSaveSuccess();
				} else {
					this.setState({ errors: result.errors, inProgress: false });
				}
			});
		} else {
			this.setState({ errors, inProgress: false });
		}
	};

	handleSelectChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			checkedKeys: Object.assign({}, this.state.routes, {
				selectedKeys: selectedRowKeys,
				selectedRows,
			}),
		});
	};
	disableDates = (current) =>  {
		const contracts = this.props.contracts || [];
		const uniqueStartDates = [...new Set(contracts.map(los => moment(los.effective_start_date).startOf('day').format()))];
		const highestStartDate = moment(Math.max(...uniqueStartDates.map(date => moment(date))));
		const currentDate = moment(current).startOf('day');
		if(!this.state.currentRecord?.effective_start_date){
		  return false;
		}
		for (const los of contracts) {
			const startDate = moment(los.effective_start_date).startOf('day');
			const endDate = los.effective_end_date ? moment(los.effective_end_date).startOf('day') : null;
			if (endDate && (currentDate.isBetween(startDate, endDate) || currentDate.isBetween(endDate, startDate))){
				return true;
			} else {
				if (currentDate.isSame(startDate)) {
					return true; // Disable dates between effective start and end dates
				}
			}
		}
		return false; // Enable other dates
	}

	_renderAccessorial = (type) => {
		const { currentRecord, predefinedLoc , config} = this.state;
		const { selectedWarehouses = null, warehouses } = this.context;
		const currentWhId = Array.isArray(selectedWarehouses)
		? selectedWarehouses
		: selectedWarehouses
		? [selectedWarehouses]
		: null;
		const currentWhName =
		warehouses?.length > 0
			? (warehouses.find((wh) => wh.id === currentWhId[0])?.first_name || '')
			: "";
		const configurations =
				currentRecord?.configurations
				? currentRecord.configurations
				: {};
		const {
			detailsSelectedAccessorials,
			serviceLevelSelectedAccessorials
		} = this.state;

		let selectedRowKeys = [];
		let onSelectChange = () => { };

		if (type === 'details') {
			selectedRowKeys = detailsSelectedAccessorials;
			onSelectChange = this.handleDetailsSelectChange;

		} else if (type === 'serviceLevel') {
			selectedRowKeys = serviceLevelSelectedAccessorials;
			onSelectChange = this.handleServiceLevelSelectChange;
		}

		const rowSelection = {
			selectedRowKeys,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled: (type === 'details' && serviceLevelSelectedAccessorials.includes(record.accessorial_code)) ||
					(type === 'serviceLevel' && detailsSelectedAccessorials.includes(record.accessorial_code))
			}),
		};
		return (
			<Fragment>
				{type === 'details' ? <Fragment>
					<Row>
						<Col md={9} xs={24}>
							<Checkbox
								checked={
									configurations?.min_charge_as_base_charge
										? configurations.min_charge_as_base_charge
										: false
								}
								onChange={(e) =>
									this.handleConfigChange(
										"min_charge_as_base_charge",
										e.target.checked
									)
								}
							>
								{I18n.t("los.min_charge_as_base_charge")}
							</Checkbox>
						</Col>
						<Col md={6} xs={24}>
							<Checkbox
								checked={
									configurations?.break_weight
										? configurations.break_weight
										: false
								}
								onChange={(e) =>
									this.handleConfigChange(
										"break_weight",
										e.target.checked
									)
								}
							>
								{I18n.t("configurations.apply_break_weight_label")}
							</Checkbox>
						</Col>
						<Col md={9} xs={24}>
							<Checkbox
								checked={
									configurations?.apply_min_max_charge_on_each_range
										? configurations.apply_min_max_charge_on_each_range
										: false
								}
								onChange={(e) =>
									this.handleConfigChange(
										"apply_min_max_charge_on_each_range",
										e.target.checked
									)
								}
							>
								{I18n.t("configurations.apply_min_max_charge_on_each_range")}
							</Checkbox>
						</Col>
					</Row>
					<Row>
						<Col md={15} xs={24} style={{ marginTop: 16 }}>
							<Row type="flex" gutter={16}>
								<Col>
									<Checkbox
										checked={
											configurations?.consider_distance_from_loc
												? configurations.consider_distance_from_loc
												: false
										}
										onChange={(e) =>
											this.handleConfigChange(
												"consider_distance_from_loc",
												e.target.checked
											)
										}
									>
										{I18n.t("los.distance_from_loc")}
									</Checkbox>
								</Col>
								<Col>
								<Select
									showSearch
									value={currentRecord.distance_from_location_id || ""}
									onChange={(e) => this.handleOnChange("distance_from_location_id", e)}
									filterOption={(input, option) => {
										if (option.props.children) {
										return (
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										);
										}
									}}
									style={{ maxWidth: 210, height: "20%", marginLeft: 10 }}
									size="small"
									disabled={!configurations?.consider_distance_from_loc}
									dropdownMatchSelectWidth={false}
									>
									<Select.Option key={"driver"} value={""}>
										-- Select --
									</Select.Option>
									{predefinedLoc.map((loc) => (
										<Select.Option key={loc.id} value={loc.id}>
										{loc.name}
										</Select.Option>
									))}
								</Select>

								</Col>
							</Row>
						</Col>
						{config?.apply_fuel_surcharge === "true" && <Col md={9} xs={24} style={{ marginTop: 16 }} >
							<Checkbox
								checked={
									configurations?.apply_fuel_surcharge
										? configurations.apply_fuel_surcharge
										: false
								}
								onChange={(e) =>
									this.handleConfigChange(
										"apply_fuel_surcharge",
										e.target.checked
									)
								}
							>
								{I18n.t("configurations.apply_fuel_surcharge_label")}
							</Checkbox>
						</Col>
						}
					</Row>
					<Row>
						<Col sm={24} xs={24}>
							<FormItem
								style={{
									display: "flex",
									gap: "4px",
									margin: "8px 0",
									paddingTop: "10px"
								}}
								label={I18n.t("los.los_range")}
								require>
								<Radio.Group
									onChange={(e) =>
										this.handleConfigChange(
											"los_range",
											e.target.value
										)
									}
									value={
										configurations?.los_range
											? configurations.los_range
											: "WEIGHT"
									}
								>
									<Radio value="WEIGHT">
										{I18n.t("general.weight")}
									</Radio>
									<Radio value="PALLET">
										{I18n.t("general.pallet")}
									</Radio>
									<Radio value="CABINET">
										{I18n.t("general.cabinet")}
									</Radio>
									<Radio value="HOURS">
										{I18n.t("BillOfRating.hours")}
									</Radio>
									<Radio value="MILES">
										{I18n.t("general.miles")}
									</Radio>
									<Radio value="ITEMS">
										{I18n.t("los.order_item")}
									</Radio>
									<Radio value="PER_CUBE">
										{I18n.t("los.per_cube")}
									</Radio>
									<Radio value="PIECES">
										{I18n.t("los.per_piece")}
									</Radio>
								</Radio.Group>
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16} className="marginTop10">
						<Col sm={24} xs={24} md={8} lg={7}>
							<FormItem label={I18n.t("general.name")} require>
								<Input
									value={currentRecord.name}
									onChange={(e) => this.handleOnChange("name", e.target.value)}
								/>
							</FormItem>
						</Col>
						<Col sm={24} xs={24} md={8} lg={3}>
							<FormItem
								label={
									<Fragment>
										<span>{I18n.t("general.code")}</span>&nbsp;
									</Fragment>
								}
								require
							>
								<Input
									value={currentRecord.code}
									onChange={(e) => this.handleOnChange("code", e.target.value)}
									className="textUpperCase"
									disabled={!this.state.isNew}
								/>
							</FormItem>
						</Col>
						<Col sm={24} xs={24} md={8} lg={4}>
							<FormItem
								label={
									<span>
										{I18n.t("general.duration")}
										<span style={{ fontSize: "0.6rem" }}>
											{I18n.t("configurations.service_duration_subtext_label")}
										</span>
									</span>
								}
							>
								<Input
									value={currentRecord.duration}
									onChange={(e) =>
										this.handleOnChange("duration", e.target.value)
									}
								/>
							</FormItem>
						</Col>
						<Col sm={24} xs={24} md={8} lg={5}>
						<FormItem
							label={
								<span>
									{I18n.t("accessorials.effective_start_date")}
									
								</span>
							}
							require
						>
							<DatePicker
								value={currentRecord.effective_start_date || null}
								onChange={(e) =>
									this.handleOnChange("effective_start_date", e)
								}
								format={AppConfig.dateFormat}
								disabledDate={this.disableDates}
							/>
						</FormItem>
						</Col>
						<Col sm={24} xs={24} md={8} lg={5}>
						<FormItem
							label={
								<span>
									{I18n.t("accessorials.effective_end_date")}
									
								</span>
							}
						>
							<DatePicker
								value={currentRecord.effective_end_date || null}
								onChange={(e) =>
									this.handleOnChange("effective_end_date", e)
								}
								format={AppConfig.dateFormat}
								//disabledDate={this.disableDates}
								disabledDate={(current) =>
									current && ((currentRecord.effective_start_date && current < moment(currentRecord.effective_start_date).startOf("day")) || this.disableDates(current))
								}
								defaultPickerValue={currentRecord.effective_end_date ? 
								currentRecord.effective_end_date : 
								(currentRecord.effective_start_date || moment())}
							/>
						</FormItem>
						</Col>
					</Row>

				</Fragment> : ''}
				<Row>
					<Col className="accessorials">
						<AccessorialCodesList
							rowKey="accessorial_code"
							size="small"
							data={this.state.accessorials}
							pagination={{ position: "none" }}
							rowSelection={rowSelection}
							showDescription={false}
							showActions={false}
							scroll={{ y: 400 }}
						/>
					</Col>
				</Row>

				<Row>{FormErrors(this.state.errors)}</Row>
				<Row>
					<Col xs={24}>
						{FormButtons(
							this.state.inProgress,
							this.handleSave,
							this.props.onCancel
						)}
					</Col>
				</Row>
			</Fragment>
		);
	};

	render() {
		const { currentRecord } = this.state;
		return (
			<div className="card-container tabHover">
				<Tabs
					activeKey={this.state.activeKey}
					onChange={this.goToTab}
					type="card"
				>
					<TabPane tab={I18n.t("general.details")} key="details">
						{this._renderAccessorial('details')}
					</TabPane>
					<TabPane tab={I18n.t("general.service_level_accessorials")} key="service_level_accessorials">
						{this._renderAccessorial('serviceLevel')}
					</TabPane>
					{checkServiceExistance(['GLOSA', 'AADL'], 'ALL') && currentRecord.id && (
						<TabPane tab={I18n.t("adjustments.label")} key="adjustments">
							<LosAdjustments
								account={this.state.account}
								currentLos={currentRecord}
								onCancel={this.props.onCancel}
								handleSaveSuccess={this.props.handleSaveSuccess}
							/>
						</TabPane>
					)}
				</Tabs>
			</div>
		);
	}
}

Form.propTypes = {
	currentLos: PropTypes.shape().isRequired,
	account: PropTypes.shape().isRequired,
	isNew: PropTypes.bool.isRequired,
	org_id: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default Form;