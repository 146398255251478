import React, { useContext, useEffect, useState, useRef } from "react";
import { Tooltip, Popconfirm } from "../../common/UIComponents";
import { Button, Col, Form, Icon, Input, Row, Spin } from "antd";
import DeleteIcon from "../common/DeleteIcon";
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";
import { alertMessage, formatCode, isEmpty } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { renderAlertMessage } from "../../helpers/common";
import { glCodeApi } from "../../api/GLCodeApi";
const ManageGLCodes = (props) => {
  const [glCodes, setGlCodes] = useState([]);
  const [editableId, setEditableId] = useState(null);
  const [editedGlCodes, setEditedGlCodes] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [showNewRow, setShowNewRow] = useState(false);
  const [newGlCodeObj, setNewGlCodeObj] = useState("");
  const [newDescObj, setNewDescObj] = useState("");
  const [messageError, setMessageError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [showAddButton, setShowAddButton] = useState(true);
  const orgContextData = useContext(OrgContext);
  const { currentOrg, setGlCodesList } = orgContextData;
  const [inProgress, setInProgress] = useState(false);
  const inputRef = useRef(null);

  const getGLCodes = () => {
    const organization_id = currentOrg.id;
    setInProgress(true);
    glCodeApi.fetch(organization_id).then((result) => {
      if (result.success) {
        setGlCodes(result.data ? result.data : []);
        setShowNewRow(result?.data?.length === 0);
        setInProgress(false);
      } else {
        alertMessage(result.errors[0], "error", 5);
        setInProgress(false);
      }
    });
  };


  const handleSave = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const payLoad = {
      gl_code: editedGlCodes,
      description: editedDescription,
      organization_id,
    };
    setInProgress(true);
    glCodeApi.update(id, payLoad).then((result) => {
      if (result.success) {
        setInProgress(false);
        setGlCodes(result?.data?.organization_glcodes || []);
        setGlCodesList(result?.data?.organization_glcodes || [])
        alertMessage("Updated Successfully", "success", 5);
      } else {
          renderAlertMessage(result.errors)
        }
      }
    );
    setEditableId(null);
  };
  const handleCancelEdit = () => {
    setEditableId(null);
    if (showNewRow) {
      if (editableId === null) {
        setNewGlCodeObj("");
        setNewDescObj("");
        setMessageError("");
        setCodeError("");
        setShowAddButton(true);
      }
      setShowNewRow(false);
    }
  };

  useEffect(() => {
    if (glCodes.length === 0) {
      setShowAddButton(true);
      setShowNewRow(true);
    }
  }, [glCodes]);

  useEffect(() => {
    if (!isEmpty(editableId)) {
      inputRef.current.focus();
    }
  }, [editableId]);

  const handleDelete = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    setInProgress(true);
    glCodeApi.delete(id, organization_id).then((result) => {
      if (result.success) {
        setInProgress(false);
        setGlCodes(result?.data?.organization_glcodes || []);
        setGlCodesList(result?.data?.organization_glcodes || [])
        alertMessage(result.data.message, "success", 5);
      } else {
          setInProgress(false);
          renderAlertMessage(result.errors)
        }
      }
    );
  };
  const handleSaveNewRow = () => {
    if (newGlCodeObj.trim() === "" ||
      newDescObj.trim() === ""
    ) {
      if (newGlCodeObj.trim() === "") {
        setCodeError(`${I18n.t("errors.code_error")}`);
      } else {
        setCodeError("");
      }
      if (newDescObj.trim() === "") {
        setMessageError(`${I18n.t("errors.description_error")}`);
      } else {
        setMessageError("");
        
      }
    } else {
      const newRow = {
        gl_code: formatCode(newGlCodeObj),
        description: formatCode(newDescObj),
        organization_id: userStore.getStateValue("selectedOrg"),
      };
      setInProgress(true);
      glCodeApi.save(newRow).then((result) => {
        if (result.success) {
          setInProgress(false);
          setGlCodes(result?.data?.organization_glcodes || []);
          setGlCodesList(result?.data?.organization_glcodes || [])
          alertMessage("Added Successfully", "success", 5);
        } else {
          alertMessage(result.errors[0], "error", 5);
        }
      });
      setNewGlCodeObj("");
      setNewDescObj("");
      setShowNewRow(false);
      setCodeError("");
      setMessageError("");
    }
  };
  useEffect(() => {
    getGLCodes();
  }, []);

  const handleAddNewRow = () => {
    setShowNewRow(true);
    setNewGlCodeObj("");
    setNewDescObj("");
    setMessageError("");
    setCodeError("");
    setShowAddButton(false);
  };


  const handleKeyPress = (event, id, saveItem = "newRow") => {
    console.log(id, event, saveItem);
    if (event.key === "Enter") {
      if(saveItem === "newRow"){
        handleSaveNewRow()
      }else{
        handleSave(id);
      }
    }
  };

  const renderGLCodesList = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={3} style={{ fontWeight: 'bold', paddingLeft: "15px" }}>
          <sup style={{ color: "red" }}>*</sup>
          <span style={{ paddingLeft: "5px" }}>GL Code</span>
        </Col>
        <Col span={10}  style={{ fontWeight: 'bold', paddingLeft: "15px" }}>
        
          <sup style={{ color: "red" }}>*</sup>
          <span style={{ paddingLeft: "5px" }}>
            {"Description"}
          </span>
        
        </Col>
        
        <Col span={6}>
          {!showNewRow && isEmpty(editableId) && (
             <Button
                type="primary"
                onClick={handleAddNewRow}
                icon="plus"
                size="small"
              >
                Add {I18n.t("configurations.gl_code")}
              </Button>
          )}
        </Col>
      </Row>
    );
  };

  const renderInputs = () => {
    return (
      <>
        {glCodes.map(
          (item, index) =>
            item && (
              <Row gutter={[16, 16]} key={item.id || item._id}>
                <Col span={3}>
                  {editableId === item.id ? (
                    <Input
                      value={editedGlCodes}
                      onChange={(e) =>
                        setEditedGlCodes(e.target.value.toUpperCase())
                      }
                      autoFocus
                      
                    />
                  ) : (
                    <Input value={item.gl_code} disabled />
                  )}
                </Col>
                <Col span={10}>
                  {editableId === item.id ? (
                    <Input
                      value={editedDescription}
                      onChange={(e) =>
                        setEditedDescription(e.target.value.toUpperCase())
                      }
                      ref={inputRef} 
                      onKeyPress = {(e) => handleKeyPress(e, item.id, "editableRow")}
                    />
                  ) : (
                    <Input value={item.description} disabled />
                  )}
                </Col>
                <Col span={3} style={{padding:'15px'}}>
                  {editableId === item.id ? (
                    <>
                      <Tooltip title={I18n.t("general.save")}>
                        <Icon
                          type="check-circle"
                          style={{ color: "green" }}
                          size="small"
                          className="fontSize18"
                          onClick={() => handleSave(item.id)}
                        />
                      </Tooltip>
                      <Tooltip title={I18n.t("general.cancel")}>
                        <Icon
                          type="close-circle"
                          style={{ color: "red" }}
                          onClick={() => handleCancelEdit()}
                          size="small"
                          className="marginLeft10 fontSize18"
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      {/* <Tooltip title={I18n.t("general.edit")}>
                        <Icon
                          type="edit"
                          style={{ color: "blue", marginRight: "10px" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleEdit(item.id)}
                        />
                      </Tooltip> */}
                      <Popconfirm
                        placement="left"
                        title={I18n.t("messages.confirm")}
                        onConfirm={() => handleDelete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon className="textBold fontSize18" />
                        &nbsp;&nbsp;&nbsp;
                      </Popconfirm>
                    </>
                  )}
                  {!showNewRow && glCodes.length - 1 === index && (
                    <>
                      {editableId ? null : (
                        <Tooltip title={I18n.t("general.add")}>
                          <Icon
                            type="plus-circle"
                            size="small"
                            style={{ marginTop: 7, color: "black" }}
                            className="marginLeft10 fontSize18"
                            onClick={() => setShowNewRow(true)}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )
        )}
      </>
    );
  };

  const renderNewRow = () => {
    return (
      <>
        {showNewRow && (
          <>
            {editableId ? null : (
              <Row gutter={[16, 16]} key="new-row">
                <Col span={3}>
                  <Input
                    placeholder="GL Code"
                    value={newGlCodeObj}
                    onChange={(e) =>
                      setNewGlCodeObj(e.target.value.toUpperCase())
                    }
                    autoFocus
                  />
                  {codeError && <div style={{ color: "red" }}>{codeError}</div>}
                </Col>
                <Col span={10} className="right-aligned">
                  <Input
                    placeholder="Description"
                    value={newDescObj}
                    onChange={(e) => setNewDescObj(e.target.value.toUpperCase())}
                    onKeyPress={(e) => handleKeyPress(e, "")}
                  />
                  {messageError && (
                    <div style={{ color: "red" }}>{messageError}</div>
                  )}
                </Col>
                <Col span={3} style={{padding:"15px"}}>
                  <Tooltip title={I18n.t("general.save")}>
                    <Icon
                      type="check-circle"
                      style={{ color: "green" }}
                      size="small"
                      className="marginLeft10 fontSize18"
                      onClick={() => handleSaveNewRow()}
                    />
                  </Tooltip>
                  <Tooltip title={I18n.t("general.cancel")}>
                    <Icon
                      type="close-circle"
                      size="small"
                      style={{ color: "red" }}
                      className="marginLeft10 fontSize18"
                      onClick={() => handleCancelEdit()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  };

  const renderGLCodes = () => {
    return (
      <div>
        <Row style={{ margin: "0px 10px 10px 10px" }}>
          {renderGLCodesList()}
          {renderInputs()}
          {renderNewRow()}
        </Row>
      </div>
    );
  };

  return (
    <Spin spinning={inProgress} delay={1000}>
      <Row>
        <Col>
          <div style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}>
            {renderGLCodes()}
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default ManageGLCodes;
