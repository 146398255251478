import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { IntegrationApi, OrgIntegrationApi } from '../../../api/IntegrationApi';
import { alertMessage } from '../../../common/Common';
import userStore from '../../../stores/UserStore';
import IntegrationsTable from './IntegrationsTable';
import PropTypes from "prop-types";
import I18n from '../../../common/I18n';

function OrgIntegrationsForm (props) {
  const {orgIntegrationsData} = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isIntegrating, setIsIntegrating ] = useState(false);
  const [ integrationsData, setIntegrationsData ] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    IntegrationApi.fetch().then((response) => {
      if (response.success) {
        setIntegrationsData(response.integrations);
      }
      else {
        setIntegrationsData([]);
      }
    }
    ).finally(() => {
      setIsLoading(false);
    });

  }, []);


  const integrateService = (integrationId) => {
    const integration = integrationsData.find((integration) => {
      return integration.id === integrationId;
    }
    );
    const orgIntegrationPayload = {
      integration_id: integration.id,
      integration_code: integration.code,
      organization_id: userStore.getStateValue('selectedOrg'),
      status: integration.status ? "ACTIVE" : "INACTIVE",
    };
    setIsIntegrating(true);
    OrgIntegrationApi.integrateService(orgIntegrationPayload).then((response) => {
      if (response.success) {
        alertMessage(I18n.t("integrations.modal.success_msg"), 'success');
      }
      else {
        alertMessage(response.errors[ 0 ], "error", 10);
      }
    }).catch((error) => {
      alertMessage(error.message, "error", 10);
    }
    ).finally(() => {
      setIsIntegrating(false);
      props.refreshIntegrations();
      props.closeModal();
    });
  };

  return (
    <IntegrationsTable
      data={
        integrationsData
      }
      orgIntegrationsData={orgIntegrationsData}
      loading={ isLoading }
      integrateService={ integrateService }
    />
  );

}

export default OrgIntegrationsForm;

OrgIntegrationsForm.propTypes = {
  refreshIntegrations: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  orgIntegrationsData : PropTypes.array.isRequired,
};

OrgIntegrationsForm.defaultProps = {
  refreshIntegrations: () => { },
  closeModal: () => { },
  orgIntegrationsData : [],
};