/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Spin,
  Button,
  Form,
  Select,
  InputNumber,
  TextArea,
} from '../../../common/UIComponents'
import {
  alertMessage,
  isEmpty,
} from '../../../common/Common'
import userStore from '../../../stores/UserStore'
import {
  fetchDeductions,
} from '../../../api/Deductions'

import { driverClaimsApi } from '../../../api/DriverClaims'
import I18n from '../../../common/I18n';

const { Option } = Select
class AdditionalDeductionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      currentRecord: this.props.currentRecord || {},
      errors: [],
      drivers: this.props.drivers || [],
      deductions: [],
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    this.getDeductions();
    if(this.state.currentRecord.id){
     // this.props.form.setFieldsValue({...this.state.currentRecord});
    }
    
  }

  getDeductions = () => {
    this.setState({ inProgress: true })
    const orgId = userStore.getStateValue('selectedOrg')
    fetchDeductions(orgId).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false })
        const deductions = result.deductions || []
        this.setState({
          deductions: deductions.filter(rec => !isEmpty(rec.standard_code)),
        })
      } else {
        this.setState({ inProgress: false, deductions: [] })
      }
    })
  }

  handleFormChange = (element, value) => {
    const currentRecord =  Object.assign({},  this.state.currentRecord, { [element]: value })
    if(element === 'deduction_master_id' ){
      const deductionRec = _.find(this.state.deductions, { id: value});
      if(!isEmpty(deductionRec) && !isEmpty(value)){
        currentRecord.deduction_name = deductionRec.deduction_name
        currentRecord.standard_code = deductionRec.standard_code
      }else {
        currentRecord.deduction_name = ''
        currentRecord.standard_code = ''
      }
    }
    this.setState({
     currentRecord
    })
  }

  handleSaveDetails = (fieldsValue) => {
    this.setState({
      inProgress: true,
    })
    const data = {
      driver_claim_id: this.state.currentRecord.id,
      driver_id: this.props?.currentDriver ? this.props.currentDriver.id : '',
      additional_amount: fieldsValue['additional_amount'],
      // deduction_master_id: fieldsValue['deduction_master_id'],
      remark: fieldsValue['remark'],
      organization_id: this.state.currentRecord.organization_id,
    }
    driverClaimsApi.manageAdditionalAmount(data).then((result) => {
      if (result.success) {
        this.setState({
          inProgress: false,
        }, ()=> {
          const driver_claims = result.driver_claims || [];
          this.props.onClose(driver_claims);
        })
        alertMessage(I18n.t('messages.saved'))
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  getFields() {
    const count = this.state.expand ? 10 : 6
    const decimalPoints =  window.localStorage.getItem('round_off_decimals')
    const { getFieldDecorator } = this.props.form
    const size = 'default'

    const { currentRecord, drivers, deductions } = this.state

    const children = [
       <Col span={24}>
        <Form.Item label={I18n.t('deductions.additional_amount')}>
          {getFieldDecorator('additional_amount', {
            initialValue: currentRecord.additional_amount ? currentRecord.additional_amount : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('deductions.additional_amount')}`,
              },
            ],
          })(
            <InputNumber
              size={size}
              // value={
              //   currentRecord.additional_amount ? currentRecord.additional_amount : ''
              // }
              onChange={(e) => this.handleFormChange('additional_amount', e)}
              precision={decimalPoints}
              className="width100Per"
              min={0}
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={24}>
        <Form.Item label={I18n.t('deductions.remarks')}>
          {getFieldDecorator('remark', {
            initialValue: currentRecord.remark ? currentRecord.remark : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('deductions.remarks')}`,
              },
            ],
          })(
            <TextArea
              className="width100Per"
              rows={4}
              placeholder={I18n.t('deductions.remarks')}
              onChange={e => this.handleFormChange('remark', e.target.value)}
            />,
          )}
        </Form.Item>
      </Col>
    ]
    return children
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
       this.handleSaveDetails(values)
      }
      
    })
  }

  render() {
    return (
      <div className="content-outer">
        <div>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Form
              className="ant-advanced-search-form"
              onSubmit={this.handleSearch}
            >
              <Row gutter={24}>{this.getFields()}</Row>
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={this.props.onClose}
                    type="danger"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    )
  }
}
AdditionalDeductionForm.propTypes = {
  account: PropTypes.shape().isRequired,
}
const WrappedForm = Form.create({ name: 'horizontal_login' })(AdditionalDeductionForm)

export default WrappedForm
