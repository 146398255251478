/* eslint-disable indent */
import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import {
  fetchPreplans,
  deletePreplan,
  fetchPreplan,
  fetchStatus,
  getWarehousesForDropDown,
} from "../api/PreplanApi";
import { fetchActiveUsers } from "../api/UsersApi";
import {
  Button,
  Col,
  Row,
  Spin,
  Select,
  Tooltip,
  Modal,
  Badge,
} from "../common/UIComponents";
import RoutesList from "../components/preplan_management/List";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import BaseModal from "../components/BaseModal";
import AppConfig from "../config/AppConfig";
import Form from "../components/preplan/Form";
import { handleRouteReponse } from "../helpers/preplan";
import PreplanRouteFile from "../components/preplan/GenerateRoueFile";
import ConfirmAllRouteFiles from "../components/preplan/GenerateAllRoueFiles";
import userStore from "../stores/UserStore";
import { checkServiceExistance, renderAlertMessage } from "../helpers/common";
import fileSend from "../../assets/images/file_send_white";
import { OrgContext } from "../context/OrgContext";
import { WarehouseContext } from "../context/WarehouseContext";
import { VehicleTypeApi } from "../api/VehicleType";
import ActionCable from "../ActionCableComponent";

const { Option } = Select;
const { confirm } = Modal;

class PreplanManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preplans: [],
      showFormModal: false,
      currentPreplan: {},
      inProgress: false,
      statusProgress: false,
      assignModal: false,
      filter: props.location.filter ? props.location.filter : "PENDING",
      confirmInfo: false,
      routeData: {},
      pagination: {},
      routeInfo: [],
      currentWarehouse: {},
      sortBy: "delivery_start_date",
      sortOrder: "descend",
      dropdown: {
        warehouses: [],
        selectedWarehouses: [],
      },
      confirmType: "",
      currentOrg: "",
      vehicleTypes: [],
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: "",
      warehouses: [],
      isUsingBetaVersion: false,
    };
    this.onTableChange = this.onTableChange.bind(this);
    // debounce
    this.getPreplans = _.debounce(this.getPreplans, 500);
  }

  componentDidMount() {
    this.getPreplans();
    // this.getStatus();
    // get dropdown data
    this.getVehicleTypes();
    this.getDropDownData();
    this.timer = setInterval(() => {
      if (isEmpty(this.state.currentOrg)) {
        this.getCurrentOrg();
      }
    }, 1000);
    const isUsingBetaVersion = this.props.location.pathname.includes('preplan_management_beta');
    this.setState({ isUsingBetaVersion });
  }

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
			    vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getCurrentOrg = () => {
    const org = userStore.getStateValue("currentOrg");
    this.setState(
      {
        currentOrg: !isEmpty(org) ? org.code : '',
      },
      () => {
        if (!isEmpty(this.state.currentOrg)) {
          clearInterval(this.timer);
        }
      }
    );
  };


  onWhContextChange = (selectedWarehouse) => {
    if (selectedWarehouse) {
      const selectedWhs = this.props.warehouseFilter.warehouses.find((wh) => wh.id === this.props.warehouseFilter.selectedWarehouse);
      this.handleWarehouseDropdownChange(
        [ selectedWhs.name ]
      );
    } else {
      // select all warehouses
      this.handleWarehouseDropdownChange(this.props.warehouseFilter.warehouses.map((wh) => wh.name));
    }
  };
  // here you can load data required for dropdowns/filters in future
  getDropDownData = async () => {
    // try {
    //   const response = await getWarehousesForDropDown();

    //   if (response.success) {
    //     const warehouses = response.locations.locations;
    //     const dropdown = {
    //       ...this.state.dropdown,
    //       warehouses,
    //     };
    //     this.setState({
    //       dropdown,
    //     });
    //   } else {
    //     throw new Error("can't load warehouses for dropdown filter");
    //   }
    // } catch (error) {
    // }




    this.setState({
      dropdown: {
        ...this.state.dropdown,
        warehouses: this.props.warehouseFilter.warehouses,
      },
    }, () => {
      // const selectedWhs = selectedWarehouseNames(props.warehouseFilter),
      // this.onWhContextChange(this.props.warehouseFilter.selectedWarehouse);
    });
  };

  // on warehouse change
  componentDidUpdate (prevProps) {
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) || !_.isEqual(prevProps.warehouseFilter.warehouses , this.props.warehouseFilter.warehouses) && (!this.props.warehouseFilter.isFetchingWarehouses)) {
      // this.onWhContextChange(this.props.warehouseFilter.selectedWarehouse);
      this.tableOptions.pagination.current = 1;
      this.getPreplans();
    }
    if (!_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses)) {
      this.tableOptions.pagination.current = 1;
      this.setState({
        dropdown: {
          ...this.state.dropdown,
          warehouses: this.props.warehouseFilter.warehouses,
        },
      });
    }
  }



  // onTableChange = (pagination, filters, sorter) => {
  //   this.tableOptions = { pagination, filters, sorter };
  //   this.getPreplans();
  // }

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
        this.tableOptions && this.tableOptions.searchText
          ? this.tableOptions.searchText
          : "",
    };
    if (pagination.current !== currentPage) {
      this.getPreplans();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "none",
          sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getPreplans();
        }
      );
    }
  };

  onSearch = (text) => {
    if (text.trim() === "") {
      this.tableOptions = {
        pagination: {},
        filters: {},
        sorter: {},
        searchText: "",
      };
    } else {
      this.tableOptions.searchText = text;
    }
    this.tableOptions.pagination.current = 1;
    this.getPreplans();
  };

  getPreplans = () => {
    this.setState({ inProgress : true });
    const page = this.tableOptions.pagination.current;
    const searchText = this.tableOptions.searchText;
    const filters = {};
    filters.warehouses = this.tableOptions.warehouses;
    const perPage = AppConfig.perPage;
    const { filter } = this.state;

    fetchPreplans(
      "",
      page,
      perPage,
      this.state.sortBy,
      this.state.sortOrder,
      searchText,
      filters
    ).then((result) => {
      const preplans = result.preplan || [];

      if (result.success) {
        this.setState({
          preplans,
          pagination: preplans.length > 0 ? result.pagination : {},
        });
      } else {
        renderAlertMessage(result.errors)
      }
    }).finally(() => {
      this.setState({ inProgress : false });
    })
  };

  getUsers = () => {
    this.setState({ inProgress: true });
    fetchActiveUsers(this.userTypes, 1, 1000, false, false).then((result) => {
      if (result.success) {
        this.setState({
          users: result.users,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  getStatus = () => {
    this.setState({ statusProgress: true });
    fetchStatus().then((result) => {
      if (result.success) {
        this.setState({
          data: result.data,
          statusProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ statusProgress: false });
      }
    });
  };

  handleChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.preplans, ["id", id]);
    const currentPreplan = Object.assign({}, this.state.preplans[index]);
    this.setState({ currentPreplan }, () => {
      this.editPreplan();
    });
  };

  handleRouteGenerate = (id) => {
    if (id) {
      const index = _.findIndex(this.state.preplans, ["id", id]);
      const currentPreplan = Object.assign({}, this.state.preplans[index]);
      this.setState({
        confirmInfo: true,
        confirmType: "all",
        currentPreplan,
      });
      //  this.setState({ inProgress: true });
      //  fetchPreplan(id).then((result) => {
      //     if (result.success) {
      //        const newState = handleRouteReponse(result, this.state);
      //        this.setState({
      //           ...newState,
      //           confirmInfo: true,
      //           confirmType: 'preplan',
      //        });
      //     } else {
      //        this.setState({ inProgress: false });
      //        alertMessage(result.errors[0], 'error', 10);
      //     }
      //  });
    }
  };

  confirmAll = () => {
    this.setState({
      confirmInfo: true,
      confirmType: "all",
      currentPreplan: {},
    });
  };

  editPreplan = () => {
    this.props.history.push({
      pathname: `/preplan/${this.state.currentPreplan.id}`,
      status: this.state.currentPreplan.status,
      showBeta: window.location.pathname.includes("beta"),
    });
  };

  closeFormModal = () => {
    this.setState({ showFormModal: false });
  };
  handleAddClick = () => {
    this.setState({
      showFormModal: true,
    });
  };

  deleteCheck = (id) => {
    const preplans = this.state.preplans || [];
    if (!isEmpty(id)) {
      const currentPreplan = _.find(preplans, { id });
      if (!isEmpty(currentPreplan)) {
        const driverCodes = currentPreplan.driver_codes || [];
        if (driverCodes.length > 0) {
          confirm({
            title: (
              <span style={{ fontWeight: 300, color: "black" }}>
                {I18n.t("messages.preplan_routes_deleted")}
              </span>
            ),
            content: (
              <Fragment>
                <div className="textBold">{driverCodes.join(", ")}</div>
                <div className="marginTop10">
                  {I18n.t("messages.proceed_confirm")}
                </div>
              </Fragment>
            ),
            onOk: () => {
              this.handleDeleteClick(id);
            },
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onCancel: () => {},
          });
        } else {
          this.handleDeleteClick(id);
        }
      }
    }
  };

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deletePreplan(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getPreplans();
        this.setState({
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleOnFilterChange = (element, value) => {
    if (value !== "ALL") {
      this.setState({ [element]: value }, () => {
        this.getPreplans();
      });
    }
    if (value === "ALL") {
      this.setState({ [element]: value });
      this.getPreplans();
    }
  };

  openTrackScreen = (guid) => {
    this.setState({
      showTrack: true,
      guid,
    });
  };

  closeTrackScreen = () => {
    this.setState({
      showTrack: false,
      guid: "",
    });
  };

  assignDriver = (routeId, primaryDriverId) => {
    if (primaryDriverId) {
      this.setState({
        primaryDriverId,
      });
    }
    this.setState({
      assignModal: true,
      routeId,
    });
  };

  closeAssignModal = () => {
    this.setState({
      assignModal: false,
    });
  };

  handleInfoModalClose = () => {
    this.setState({ confirmInfo: false, confirmType: "", currentPreplan: {} });
  };

  renderInfoModal = () => (
    <BaseModal
      title={I18n.t("preplan.route_file_generate")}
      width="85%"
      onCancel={() => this.handleInfoModalClose()}
      style={{ top: 20 }}
    >
      {this.state.confirmType === "preplan" ? (
        <PreplanRouteFile
          routeInfo={this.state.routeInfo}
          currentPreplan={this.state.currentPreplan}
          onCancel={() => this.handleInfoModalClose()}
          onSuccess={(message) => this.handleSaveSuccess(message)}
        />
      ) : (
        this.state.confirmType === "all" && (
          <ConfirmAllRouteFiles
            currentPreplan={this.state.currentPreplan}
            onCancel={() => this.handleInfoModalClose()}
            onSuccess={(message) => this.handleSaveSuccess(message)}
          />
        )
      )}
    </BaseModal>
  );

  renderFormModal = () => (
    <BaseModal
      title={`${I18n.t("preplan.create")}`}
      onCancel={() => this.closeFormModal()}
      width="80%"
      style={{ top: "30" }}
      maskClosable={false}
    >
      <Form 
      vehicleTypes={this.state.vehicleTypes}
      />
    </BaseModal>
  );

  handleWarehouseDropdownChange = (values) => {
    const dropdown = {
      ...this.state.dropdown,
      selectedWarehouses: values,
    };

    this.setState({
      dropdown,
    });

    const ids = values
      .map((value) => {
        const warehouse = dropdown.warehouses.find((wh) => wh.name === value);
        if (warehouse) return warehouse.id;
        return null;
      })
      .filter((v) => v !== null);

    this.tableOptions.warehouses = ids;

    this.getPreplans();
  };

  updateOperation = () => {
    this.getPreplans();
  }

  render() {
    const { currentOrg } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || AppConfig.perPage,
    };

    const selectedWarehouse = this.state.dropdown.selectedWarehouses;
    const dropdownData = {
      values: this.state.dropdown.selectedWarehouses,
      options: this.state.dropdown.warehouses.filter(
        (wh) => !selectedWarehouse.includes(wh.name)
      ),
    };

    return (
      <div className="content-outer routes-classes">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>
              {I18n.t("menu.preplanroutes")}
              {this.state.isUsingBetaVersion && (
                <Badge
                  count="Beta"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    left: "4px",
                    bottom: "12px",
                    right: "0px",
                    border: "none",
                    fontSize: "12px",
                    width: "40px",
                    height: "20px",
                    borderRadius: "5px",
                  }}
                ></Badge>
              )}
            </Col>
            <Col
              xs={12}
              className="page-actions"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {checkServiceExistance(["PPCNR", "PPOD"], "ALL") &&
                !isEmpty(currentOrg) &&
                currentOrg === I18n.t("account.netmove_code") && (
                  <Tooltip title={I18n.t("preplan.route_file_generate")}>
                    <img
                      src={fileSend}
                      onClick={this.confirmAll}
                      style={{ width: 25 }}
                      className="marginRight10"
                    />{" "}
                  </Tooltip>
                )}
              {/* <Col xs={8} style={{ display: "flex", alignItems: "center" }}>
                <Select
                  style={{
                    width: "100%",
                    height: "30px",
                    overflow: "hidden",
                  }}
                  mode="multiple"
                  placeholder="Select Warehouses"
                  showSearch
                  allowClear
                  maxTagCount={2}
                  value={dropdownData.values}
                  onChange={this.handleWarehouseDropdownChange}
                >
                  {dropdownData.options.map((item) => (
                    <Select.Option value={item.name} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col> */}
              {checkServiceExistance("PPC") && (
                <Button
                  type="primary"
                  onClick={() => this.handleAddClick()}
                  icon="plus"
                  className="marginLeft10"
                >
                  {I18n.t("preplan.create")}
                </Button>
              )}
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            {checkServiceExistance("PPI") && (
              <Row>
                <Col className="marginTop">
                  <RoutesList
                    data={this.state.preplans}
                    // scroll = {{y:680}}
                    scroll = {{y:"calc(100vh - 270px)"}}
                    editClick={(id) => this.handleEditClick(id)}
                    generateClick={(id) => this.handleRouteGenerate(id)}
                    deleteClick={(id) => this.deleteCheck(id)}
                    size="small"
                    pagination={pagination}
                    tableChange={(pagination, filter, sorter) =>
                      this.onTableChange(pagination, filter, sorter)
                    }
                    currentOrg={this.state.currentOrg}
                  />
                </Col>
              </Row>
            )}
          </Spin>
          <ActionCable
            currentDate={null}
            currentWhId={this.props.warehouseFilter.selectedWarehouses}
            updateOperation={this.updateOperation}
            screenFrom={"preplanMng"}
          />
          {this.state.showFormModal && this.renderFormModal()}
          {this.state.assignModal && this.renderAssignModal()}
          {this.state.confirmInfo && this.renderInfoModal()}
        </div>
      </div>
    );
  }
}



export const PreplanManagementComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <PreplanManagement
      warehouseFilter={ warehouseFilter }
      organizationSettings={ organizationSettings }
      { ...props }
    />
  );
});

export default PreplanManagementComponent;