import React, { Fragment,useEffect,useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  InputNumber,
  Input,
  Icon,
  Tooltip,
  Popconfirm
} from '../../common/UIComponents'
import _ from 'lodash';
import { checkNegitive } from '../../common/Common'
import { generateReqDecimal } from '../../helpers/array_functions'
import I18n from '../../common/I18n';

const QuoteSummary = ({
  summaryDetails,
  mode,
  type = 'quote',
  onChange,
  onAccessorialAmountChange,
  account_accessorials,
  orderType,
  currentQuote,
  updateAddOns,
  setUpdateAddOns,
  enableDelete,
  setEnableDelete,
  apiCallMade,
  setApiCallMade
}) => {
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const zeroValue = Number((0).toFixed(decimalPoints));
  const stepValue = generateReqDecimal(1, decimalPoints);
  const [additionalChargesList, setAdditionalChargesList] = useState(
      updateAddOns ?[...updateAddOns, { add_on_name: "", add_on_value: 0 }]
      : [{ add_on_name: "", add_on_value: 0 }]
  );

  // useEffect(() => {
  //   if (apiCallMade) {
  //     setAdditionalChargesList(
  //       [...updateAddOns, { add_on_name: "", add_on_value: 0 }]
  //     );
  //     setApiCallMade(false);
  //   }
  // }, [apiCallMade]);


  useEffect(() => {
    if (apiCallMade && !_.isEmpty(updateAddOns)) {
      setAdditionalChargesList([...updateAddOns, { add_on_name: "", add_on_value: 0 }]);
      setApiCallMade(false);
    }
  }, [apiCallMade]);
  
  
  const renderField = (fieldName) =>
    mode === 'edit' ? (
      <Row className={fieldName === 'quote_amount' ? 'fontSize14' : ''}>
        <Col xs={2} style={{ paddingTop: 4}}>$</Col>
        <Col xs={22}>
          <InputNumber
            value={summaryDetails[fieldName]}
            onChange={(e) => onChange(fieldName, e)}
            disabled={fieldName === 'quote_amount'}
            min={0}
            precision={decimalPoints}
            size="small"
            step={stepValue}
            className='amount_input'
            style={fieldName === 'quote_amount' ? { color: '#000', opacity: 1 } : {}}
          />
        </Col>
      </Row>
    ) : (
      checkNegitive(summaryDetails[fieldName])
    )

  const renderAccossorialamount = (fieldName, rec) => {
    const ind_accessorial_charges = summaryDetails['ind_accessorial_charges']
    const location_id = rec?.location_id || ''
    return mode === 'edit' ? (
      <Row>
        <Col xs={2} style={{ paddingTop: 4}}>$</Col>
        <Col xs={22}>
          <InputNumber
            value={location_id ? ind_accessorial_charges[fieldName][location_id] : ind_accessorial_charges[fieldName]}
            onChange={(e) =>
              onAccessorialAmountChange(fieldName, e, location_id)
            }
            min={0}
            precision={decimalPoints}
            size="small"
            step={stepValue}
          />
        </Col>
      </Row>
    ) : (
      rec[fieldName]?.location_id ? checkNegitive(rec[fieldName].location_id) : (rec[fieldName] ? checkNegitive(rec[fieldName]) : '')
    )
  }

  
  const renderNewFields = (charge, index) => {
    const addAdditionalCharge = () => {
      // setAdditionalChargesList([
      //   ...additionalChargesList,
      //   { add_on_name: "", add_on_value: 0 },
      // ]);
      setUpdateAddOns([
        ...additionalChargesList,
        { add_on_name: "", add_on_value: 0 },
      ]);
    };

    const deleteAdditionalCharge = (index) => {
      const updatedList = [...additionalChargesList];
      updatedList.splice(index, 1);
      setAdditionalChargesList(updatedList);
      setUpdateAddOns(updatedList);
    };

    const handleNameChange = (e) => {
      const updatedList = [...additionalChargesList];
      updatedList[index].add_on_name = e.target.value;
      setAdditionalChargesList(updatedList);
      setUpdateAddOns(updatedList);
    };

    const handleValueChange = (value) => {
      const updatedList = [...additionalChargesList];
      updatedList[index].add_on_value = value;
      setAdditionalChargesList(updatedList);
      setUpdateAddOns(updatedList);
    };

    const clearValues = (index) => {
      const updatedList = [...additionalChargesList];
      updatedList[index].add_on_name = "";
      updatedList[index].add_on_value = 0;
      setAdditionalChargesList(updatedList);
      setUpdateAddOns(updatedList);
    }

    return (
      <tr key={index}>
        <td>
          <Input
            value={charge.add_on_name}
            onChange={handleNameChange}
            size="small"
            className="alignLeft"
            autoFocus
            placeholder="Enter description"
          />
        </td>
        <td>:</td>
        <td className="alignRight" width="120px">
          <Row className={"fontSize14"}>
            <Col xs={2} style={{ paddingTop: 4 }}>
              $
            </Col>
            <Col xs={22}>
              <InputNumber
                value={charge.add_on_value}
                onChange={handleValueChange}
                size="small"
                step={stepValue}
              />
            </Col>
          </Row>
        </td>
        <td>
          {index < additionalChargesList.length - 1 || enableDelete ? (
            <Tooltip title={I18n.t("general.delete")} placement="right">
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => deleteAdditionalCharge(index)}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  type="delete"
                  size="small"
                  style={{ marginTop: 7, color: "red" }}
                  className="marginLeft10 fontSize18"
                />
              </Popconfirm>
            </Tooltip>
          ) : (
            // <Tooltip title={I18n.t("general.add")} placement="right">
            //   <Icon
            //     type="plus-circle"
            //     size="small"
            //     style={{ marginTop: 7, color: "black" }}
            //     className="marginLeft10 fontSize18"
            //     onClick={addAdditionalCharge}
            //   />
            // </Tooltip>
            <Tooltip title={I18n.t("general.clear")} placement="right">
              <Icon
                type="close-circle"
                size="small"
                style={{ marginTop: 7, color: "rgb(209 137 41)" }}
                className="marginLeft10 fontSize18"
                onClick={() => clearValues(index)}
              />
            </Tooltip>
          )}
        </td>
      </tr>
    );
  };
  
  const renderSummary = (record) => {
    return (
      <div className="amountUpdate textBold">
        <table style={{ marginTop: 20, width: "auto" }} cellPadding="2px">
          <tbody>
            {orderType === "T" && (
              <>
                <tr>
                  <td className="textBold">
                    Pickup{" "}
                    {I18n.t("invoices.invoice_types.transportation_charges")}{" "}
                    Charges
                  </td>
                  <td>:</td>
                  <td className="alignRight" width="120px">
                    {renderField("pickup_transportation_charge")}
                  </td>
                </tr>
                <tr>
                  <td className="textBold">
                    Delivery{" "}
                    {I18n.t("invoices.invoice_types.transportation_charges")}{" "}
                    Charges
                  </td>
                  <td>:</td>
                  <td className="alignRight" width="120px">
                    {renderField("delivery_transportation_charge")}
                  </td>
                </tr>
              </>
            )}
            {orderType !== "T" ? (
              <tr>
                <td className="textBold">
                  {I18n.t("invoices.invoice_types.transportation_charges")}{" "}
                  Charges
                </td>
                <td>:</td>
                <td className="alignRight" width="120px">
                  {renderField("transportation_charge")}
                </td>
              </tr>
            ) : null}
            {/* {currentQuote?.quote?.is_line_haul === true && (
              <tr>
                <td className="textBold">
                  {I18n.t("invoices.invoice_types.linehaul_charges")}
                </td>
                <td>:</td>
                <td className="alignRight" width="120px">
                  {renderField("linehaul_charge")}
                </td>
              </tr>
            )} */}
            {orderType === "T" && (
              <>
                <tr>
                  <td className="textBold">
                    Pickup{" "}
                    {I18n.t("invoice_lines.fuel_surcharges")}{" "}
                  </td>
                  <td>:</td>
                  <td className="alignRight" width="120px">
                    {renderField("pickup_fuel_surcharge")}
                  </td>
                </tr>
                <tr>
                  <td className="textBold">
                    Delivery{" "}
                    {I18n.t("invoice_lines.fuel_surcharges")}{" "}
                  </td>
                  <td>:</td>
                  <td className="alignRight" width="120px">
                    {renderField("delivery_fuel_surcharge")}
                  </td>
                </tr>
              </>
            )}
            {orderType !== "T" ? (
            <tr>
              <td className="textBold">
                {I18n.t("invoice_lines.fuel_surcharges")}
              </td>
              <td>:</td>
              <td className="alignRight" width="120px">
                {renderField("fuel_surcharge")}
              </td>
            </tr>
            ) : null}
            {mode === "view"
              ? summaryDetails?.ori_accessorial_charges
                ? summaryDetails.ori_accessorial_charges.map((rec) => {
                    return (
                      <tr key={rec}>
                        <td className="textBold">
                          {rec?.accessorial_name || rec?.accessorial || ''}{rec.location_type ? `(${rec.location_type})` :''}
                        </td>
                        <td>:</td>
                        <td className="alignRight" width="120px">
                          {" "}
                          {renderAccossorialamount("accessorial_charge", rec)}
                        </td>
                      </tr>
                    );
                  })
                : null
              : summaryDetails.ori_accessorial_charges.map((rec) => {
                  //const accRec = _.find(account_accessorials, { code: rec })
                  return (
                    <tr key={rec}>
                      <td className="textBold">
                        {rec?.accessorial_name || rec.accessorial} {rec.location_type ? `(${rec.location_type})` :''}
                      </td>
                      <td>:</td>
                      <td className="alignRight" width="120px">
                        {" "}
                        {renderAccossorialamount(rec.accessorial, rec)}
                      </td>
                    </tr>
                  );
                })}
            {/* <tr>
            <td className="textBold">
              {I18n.t('invoices.accessorial_charges')}
            </td>
            <td>:</td>
            <td className="alignRight" width="120px">
              {' '}
              {renderField('accessorial_charges'}
            </td>
          </tr> */}
            <tr>
              <td className="textBold">
                {/* {I18n.t("invoice_lines.additional_charge")} */}
                Special Charges
              </td>
              <td>:</td>
              <td className="alignRight" width="120px">
                {renderField("additional_charges")}
              </td>
            </tr>
            {mode !== "view" ? additionalChargesList.map((charge, index) =>
              renderNewFields(charge, index)
            ): summaryDetails?.add_on_details?.map((charge,index)=>{
              return (
                <tr key={index}>
                  <td className="textBold">
                    {charge.add_on_name}
                  </td>
                  <td>:</td>
                  <td className="alignRight" width="120px">
                    {checkNegitive(charge.add_on_value)}
                  </td>
                </tr>
              )
            })}
            <tr>
              <td colSpan={3}>
                <hr style={{ width: "100%", margin: "5px 0px"  }} />
              </td>
            </tr>
            <tr>
              <td className="textBold alignRight paddingLeft5 paddingTop10 fontSize18">
                {I18n.t("invoices.total_amount")}
                <br />
              </td>
              <td>:</td>
              <td className={ mode!== 'view' ? "alignRight fontSize18 paddingTop10" : "alignRight fontSize17 paddingTop10" } width="120px">
                {renderField("quote_amount", record, type, mode)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return <Fragment>{renderSummary()}</Fragment>
}

QuoteSummary.propTypes = {
  summaryDetails: PropTypes.shape().isRequired,
  onAccessorialAmountChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

QuoteSummary.defaultProps = {
  summaryDetails: {},
  onAccessorialAmountChange: () => {},
  onChange: () => {},
}

export default QuoteSummary
