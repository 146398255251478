/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { Col, Row, Card , Icon, Popconfirm, Tooltip,Popover,Typography } from '../../../common/UIComponents';
import { doFormate, ellipseText, } from '../../../common/Common';
import { checkServiceExistance } from '../../../helpers/common';
import { formatSurfaceArea, formatWeight } from '../../../helpers/orders';
import truck from '../../../../assets/images/truck.png';
import surfaceImage from '../../../../assets/images/surface.png';
import DeleteIcon from '../../common/DeleteIcon';
import { renderDriverZones } from '../../../helpers/preplan';
import I18n from '../../../common/I18n';
import { OrgContext } from '../../../context/OrgContext';

const { Paragraph } = Typography;
const AvailableDriver = ({
  user, deleteDriverClick, doCapacityValidation, driverZones, showCodeWithName = false
}) => {
  const shownName = user.employee_code || `${user.first_name} ${user.last_name}`;
  const fullName = `${user.first_name} ${user.last_name}`;
  const getListStyle = isDraggingOver => ({
    height: 'auto', //isDraggingOver ? 75 : 'auto',
    borderStyle: isDraggingOver ? 'dashed' : '',
  });

  const renderTruckWeightAndSurfaceArea = () => (
    <div style={{ fontSize: '9.5px'}} className="textBold">
      <Tooltip title={
        <Fragment>
        {I18n.t("preplan.capacity.vehicle_weight")} / 
        {I18n.t("preplan.surface_area.vehicle")}
        </Fragment>
      }
      >
        <img src={surfaceImage} style={{ width: 14}} />&nbsp;
        <span>{formatWeight(user.vehicle_weight)} / {formatSurfaceArea(user.vehicle_surface_area)}</span>
      </Tooltip>
    </div>
  );

    const renderTruckType = () => (
    <div style={{ fontSize: '9.5px'}} className="textBold">
      <Tooltip title={
        <Fragment>
        {I18n.t("truck.vehicle_type")}
        </Fragment>
      }
      >
        <img src={truck} style={{ width: 14}} />&nbsp;
        {doFormate(user.vehicle_type)}
      </Tooltip>
    </div>
  );

  const { currentOrg } = useContext(OrgContext);
  const isTextAirOrg = currentOrg && currentOrg.code === "TA";
  return (
    <Fragment>
      <Droppable droppableId={`droppableUserItem${user.id}`} // isDropDisabled={user.isLocked}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <Card
              id={`user${user.id}`}
              size="small"
              bodyStyle={{
                padding: 8,
                paddingLeft: 10,
                // borderLeft: route.optimized === true ? '3px solid green' : 'none',
              }}
              headStyle={{
                fontSize: 12,
              }}
              style={{
                marginBottom: 10,
                border: `2px dotted #${user.bgColor}`,
                borderRadius: 10,
              }}
            >
              <Row>
                <Col xs={22} className="fontsize11 smallText textBold">
                  {!isTextAirOrg ? (ellipseText(shownName, 15)) :  (ellipseText(fullName, 12, true))}
            &nbsp;&nbsp;
                  {/* {shownName !== fullName && showCodeWithName ? ellipseText(fullName, 15, true) : null} */}
                </Col>
                { checkServiceExistance('PPRD') && <Col xs={2} className="fontsize11 smallText textBold">
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm')}
                  onConfirm={() => deleteDriverClick(user.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title={I18n.t("general.delete")}>
                  <DeleteIcon className="textBold"/>
                  {" "}
                  </Tooltip>
                </Popconfirm>
                </Col>
                }
                <Col xs={24} className="fontsize11 smallText">
                  {renderTruckType()}
                </Col>
                {renderDriverZones(user.id, driverZones)}
                {
                  doCapacityValidation &&
                  <Col xs={24} className="fontsize11 smallText">
                    {renderTruckWeightAndSurfaceArea()}
                  </Col>
                }
                <Col xs={24} className="fontsize11 smallText" style={{ paddingTop: 4 }}>
                  <span > No stops are allocated</span>
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </Droppable>
    </Fragment>
  );
};
export default AvailableDriver;

AvailableDriver.propTypes = {
  user: PropTypes.shape().isRequired,
};