import { isEmpty } from 'lodash';
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils';

export const BatchPaymentApi = {
  fetchBatches: (orgId, status="OPEN") => {
      const url = ApiUrl(
        `v2/account_receivables/get_batch_payments?operation_code=OREI&organization_id=${orgId}&status=${status}`,
      );
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
            account_receivables: 'batch_payments',
        },
      });
  },

  manageBatch: (payload, isNew) => {
    let url = ApiUrl(`v2/account_receivables/${isNew ? 'create_batch_payment' : 'update_batch_payment'}`)
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { batch_payment: 'batch_payment' },
    })
  },

  updateBatchAccount: (payload) => {
    const url = ApiUrl('v2/account_receivables/update_batch_payment')
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { account_receivables: 'account_receivables' },
    })
  },

  deleteBatchAccount: (id) => {
    const url = ApiUrl('v2/account_receivables/delete_batch_payment?batch_payment_id=' + id)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {batch_payment: ''}
    })
  },

  getBatchPayments:(id) => {
    const url = ApiUrl('v2/account_receivables/batch_account_payments?batch_payment_id=' + id)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {batch_account_payments: 'batch_account_payments'}
    })
  },
  createBatchPayment: (payload) => {
    const url = ApiUrl('v2/account_receivables/create_batch_account_payment')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { batch_account_payment: 'batch_account_payment' },
    })
  },
  updateBatchPayment: (payload) => {
    const url = ApiUrl('v2/account_receivables/update_batch_account_payment')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { batch_account_payment: 'batch_account_payment' },
    })
  },
  deleteBatchPayment: (id) => {
    const url = ApiUrl('v2/account_receivables/delete_batch_account_payments?batch_account_payment_id=' + id)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {batch_payment: ''}
    })
  },
  unpaidInvoiceLines:(organization_id, account_id) => {
    const url = ApiUrl(`v2/account_receivables/get_unpaied_line_invoices?account_id=${account_id}&organization_id=${organization_id}`)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {lines: 'unpaied_line_invoices'}
    })
  },
  applyBatchAccount: (payload) => {
    const url = ApiUrl('v2/account_receivables/apply_ammount_to_ar_invoice_lines')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { batch_account_payment: 'batch_account_payment' },
    })
  },
  paidInvoiceLines:(id) => {
    const url = ApiUrl(`v2/account_receivables/get_paid_invoices_by_check?batch_account_payment_id=${id}`)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {lines: 'paid_invoice_lines'}
    })
  },
  deletePaidInvoiceLine:(id) => {
    const url = ApiUrl(`v2/account_receivables/delete_invoice_line_payment?batch_payment_invoice_id=${id}`)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {batch_account_payment: 'batch_account_payment'}
    })
  },
  chnageStatus:(id, current_user_id) => {
    const url = ApiUrl(`v2/account_receivables/post_batch_payment?batch_payment_id=${id}&current_user_id=${current_user_id}`)
    return Call('get', url, {} , ParseGeneralResponse,{
      responseDataKeys : {}
    })
  },
  
};

