/* global google */
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import React, { Fragment } from 'react';
import { compose, withHandlers, withState } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
  OverlayView,
} from 'react-google-maps';
import MapCustomControl from '../common/MapCustomControl';
import warehouseImg from '../../../assets/images/warehouceMarker.png';
import { Spin, Row, Col, Card, Icon, Tooltip, Popover, Avatar, Button } from '../../common/UIComponents';
import { isEmpty, formateArrayToWithPlus } from '../../common/Common';
import styles from '../common/mapStyle.json';
import AppConfig from '../../config/AppConfig';
import manlogo from '../../../assets/images/driver.png';
import { setOrderSequence } from '../../helpers/preplan';
import { contrast } from '../../common/Colors';
import { momentTime } from '../../helpers/date_functions';
import { formatByTimeConfig } from '../../helpers/common';
import OrderMarker from '../preplan/OrderMarker';
import AddOrdersToRoute from './AddUnassignedOrdersToRoute';
import { pinLikeMarker } from './helpers';
// import { stopMarker } from '../../helpers/map';

let getImg;
const optionIcon = {};
let atttributeInfo;
const lineSymbol = {

  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  strokeColor: '#479ada',
  strokeWeight: 2,
  scale: 4,
};
const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  zIndex: isDragging ? '9999999 !important' : 'inherit',
  color: isDragging ? 'red' : 'green',
  ...draggableStyle,
});

const handlePanelClick = (props) => {
  props.onPanelClick("pane_1");
};

const ZoneMapButtons = (props) => {
  const { panelSize, routes, showOnMap, setShowOnMap } = props;

  const handleShowOnMapClick = () => {
    setShowOnMap();
  };

  return (
    <Row gutter={ [ 8, 8 ] }>
      <Col>
        { props.showUnassigned && (
          <AddOrdersToRoute
            routes={ props.noncompletedRoutes }
            label={ "Allocate" }
            currentSelected={ props.selectedRouteObject.id }
            buttonType="danger"
            closeSearchOrders={ props.closeSearchOrders }
            selectedInfo={ props.unassignedOrdersInfo }
            size="small"
            isDisabled={
              props.unassignedOrdersInfo.selectedKeys.length === 0 ||
              props.noncompletedRoutes.length === 0
            }
            actionType={ "routeSelection" }
          />
        ) }
      </Col>
      {/* <Col>
        <Button size="small" onClick={ () => handlePanelClick(props) }>
          <Icon type={ panelSize.pane_1 === 1 ? "minus-square" : "fullscreen" } />
          { panelSize.pane_1 === 1 ? " Minimize" : " Maximize" }
        </Button>
      </Col> */}
    </Row>
  );
};

const DispatchMap = compose(
  withState("activeClusterList", "setActiveClusterList", []),
	withState("activeHoveredCluster", "setActiveHoveredCluster", {
		lat: null,
		lng: null,
	}),
  withHandlers({
		onMarkerClustererClick: () => (markerClusterer) => {},
		fetchClusteredOrders: (props) => (marker) => {
			const { orderInfo } = props;
			const clusteredMarkers = orderInfo.reduce((acc, curr) => {
				if (curr.lat === marker.lat && curr.lng === marker.lng) {
					acc.push(curr);
				}
				return acc;
			}, []);

			return clusteredMarkers;
		},
	}),
	withHandlers({
		// here we are using withHandlers again to use the fetchClusteredOrders function
		checkForCluster: (props) => (marker) => {
			// add marker to active clusterlist only if its in a cluster
			const clusteredOrders = props.fetchClusteredOrders(marker);
			if (clusteredOrders.length > 1) {
				const { activeClusterList, setActiveClusterList } = props;
				const { lat, lng } = marker;
				const activeClusterindex = activeClusterList.findIndex(
					({ coords }) => coords.lat === lat && coords.lng === lng
				);
				const isExist = activeClusterindex !== -1;
				if (!isExist) {
					const clusterInfo = {
						coords: { lat, lng },
						selectedMarkersCount: 1,
						clusterLength: clusteredOrders.length,
					};
					setActiveClusterList(activeClusterList.concat(clusterInfo));
				} else {
					// if exist then increment markers count
					const clusterInfo = activeClusterList[activeClusterindex];
					clusterInfo.selectedMarkersCount += 1;
					setActiveClusterList([...activeClusterList]);
				}
			}
		},
		fetchSelectedClustersInfo: (props) => (marker) => {
			const { selectedOrderKeys } = props;
			const clusteredOrders = props.fetchClusteredOrders(marker);
			if (clusteredOrders.length > 0) {
				const selectedOrders = clusteredOrders.filter((order) =>
					selectedOrderKeys.includes(order.id)
				);
				return {
					isFullySelected:
						selectedOrders.length === clusteredOrders.length,
					isPartiallySelected:
						selectedOrders.length > 0 &&
						selectedOrders.length < clusteredOrders.length,
				};
			}
			/* 
			const { activeClusterList } = props;
			const { lat, lng } = marker;
			const activeClusterindex = activeClusterList.findIndex(
				({ coords }) => coords.lat === lat && coords.lng === lng
			);
			const isExist = activeClusterindex !== -1;
			if (isExist) {
				const clusterInfo = activeClusterList[activeClusterindex];
				const { selectedMarkersCount, clusterLength } = clusterInfo;
				return {
					isFullySelected: selectedMarkersCount === clusterLength,
					isPartiallySelected:
						selectedMarkersCount > 0 &&
						selectedMarkersCount < clusterLength,
				};
			} */
			return {
				isFullySelected: false,
				isPartiallySelected: false,
			};
		},

		unselectMarkerFromCluster: (props) => (marker) => {
			const { activeClusterList, setActiveClusterList } = props;
			// check if marker's lat and lng is in activeClusterList
			const { lat, lng } = marker;
			const activeClusterindex = activeClusterList.findIndex(
				({ coords }) => coords.lat === lat && coords.lng === lng
			);
			const isExist = activeClusterindex !== -1;
			if (isExist) {
				const clusterInfo = activeClusterList[activeClusterindex];
				clusterInfo.selectedMarkersCount -= 1;
				setActiveClusterList([...activeClusterList]);
			}
		},
	}),
  withScriptjs,
  withGoogleMap,
)(props => (
  <Spin spinning={false} delay={1000}>
    <GoogleMap
      {...props}
      // defaultCenter={{ lat: 17.6868, lng: 83.2185 }}
      defaultOptions={{
        streetViewControl: false,
        scaleControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        zoomControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_RIGHT,
        },
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scrollwheel: true, // allow scroll wheel
        styles, // change default map styles
      }}
      defaultCenter={{ lat: 30.9873867, lng: -97.472588 }}
      defaultZoom={2}
      ref={(c) => {
        if (props.onMapMounted) {
          props.onMapMounted(c, props);
        }
      }}
    >

      <MapCustomControl position={ google.maps.ControlPosition.TOP_RIGHT }>
            <div className="map-form">
              <Row>
            <ZoneMapButtons
              { ...props }
            />
              </Row>
        </div>
      </MapCustomControl>
      {
        !isEmpty(props.routes) && _.isArray(props.routes) && props.routes.map((route, routeIndex) => route.stop_markers.map((marker, stopIndex) => {
          const orders = marker.orders && marker.orders.length > 0 ? marker.orders.map(order => order.customer_order_number) : [];
          const isStopVisited = marker.status && ['ARRIVED', 'INCOMPLETE', 'EXCEPTION', 'COMPLETED', 'CANCELLED'].includes(marker.status) && AppConfig.orderStatusColors[marker.status];
          const stopBgColor = isStopVisited ? (AppConfig.orderStatusColors[marker.status].color) : route.bgColor;
          const bgColor = !isEmpty(stopBgColor) ? stopBgColor : '000000';
          const fgColor = isStopVisited ? contrast(bgColor) : route.fgColor;
          const isLinehaulRoute = route.route_type === 'line_haul';

          const stopNo = marker.location_type !== 'WH' && (stopIndex === 0 || stopIndex === route.stop_markers.length - 1) ? stopIndex + 1 : stopIndex 
          return (
            <Marker
              position={{ lat: marker.lat, lng: marker.lng }}
              key={marker.id}
              // icon={(marker.stop_number == 1 || marker.stop_number == route.stop_markers.length) ? {
              icon={!isLinehaulRoute && (marker.location_type === 'WH') ? {
                url: warehouseImg,
                scaledSize: new google.maps.Size(30, 30),
                anchor: new google.maps.Point(16, 30),
              } : {
                url: props.showNumbers ? pinLikeMarker(stopNo,bgColor,fgColor)
                  :
                  pinLikeMarker( null , bgColor , fgColor , 20 ),
              }}
              onMouseOver={e => props.onToggleOpen(e, marker.stop_number, `${marker.id}`, 'stop')}
              onMouseOut={e => props.onToggleOpen(e, marker.stop_number, `${marker.id}`, 'stop')}
              onClick={e => {
                props.setCurrentRoute(route.id, marker.id);
                // const currentActiveStop = document.querySelector(`.stop${marker.id}`);
                // if(currentActiveStop) {
                //   currentActiveStop.scrollIntoViewIfNeeded({ block: 'end',  behavior: 'smooth' });
                // }
              }}
            >
              {props.isOpen[`${marker.id}`] &&
              <InfoWindow
              // onCloseClick={e => props.onToggleOpen(e, marker.stop_number, `${marker.id}`)}
                options={{ alignTop: true, closeBoxURL: '', enableEventPropagation: true }}
              >
                <div
                  style={{
                    height: 'auto', width: 'auto', padding: 0,
                  }}
                  className="mapInfoWindow"
                >
                  {
                    props.showPreplnControl ?
                      <span className="hintText" >{orders.length > 0 ? formateArrayToWithPlus(orders) : 'No orders'}</span>
                      :
                      <Card
                        style={{
                          minWidth: 250, marginTop: 12, fontSize: '14px', color: '#000000', padding: '10px 5px',
                        }}
                        title={`${marker.contact_name}`}
                      >
                        <Row>
                          <Col md={5} lg={5} sm={12} xs={12}>
                            Name
                          </Col>
                          <Col xs={1}>:</Col>
                          <Col md={18} lg={18} sm={18} xs={18}>
                            {marker.formatted_stop_name ? marker.formatted_stop_name : 'NA'}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5} lg={5} sm={12} xs={12}>
                            Route
                          </Col>
                          <Col xs={1}>:</Col>
                          <Col md={18} lg={18} sm={18} xs={18}>
                            {route.displayName ? route.displayName : 'NA'}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5} lg={5} sm={12} xs={12}>
                            Status
                          </Col>
                          <Col xs={1}>:</Col>
                          <Col md={18} lg={18} sm={18} xs={18}>
                            {marker.status ? marker.status : 'NA'}
                          </Col>
                        </Row>
                      </Card>
                  }
                </div>
              </InfoWindow>

              }

            </Marker>
          );
        }))
      }
      {
        !isEmpty(props.orderInfo) && _.isArray(props.orderInfo) && props.orderInfo.filter(order => order.lat && order.lng).map((marker, routeIndex) => (
          <OrderMarker
							key={routeIndex}
							marker={marker}
							// send active hovered cluster and set active cluster hovered as props
							{...props}
						/>
        ))
      }

      {/* <Droppable droppableId="testDroppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Draggable key="xxx" draggableId="xxx" index={8}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <OverlayView
                    position={{
                      lat: 17.832624644678056,
                      lng: 83.29962730503513,
                    }}
                    key="test"
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={getPixelPositionOffset}
                  >
                    <div>
                      <Popover
                        title="Driver Info"
                        content={
                          <div style={{ textAlign: 'left' }}>
                            Driver
                          </div>
                        }
                      >
                        <Avatar src={manlogo} size={20} />
                      </Popover>
                    </div>
                  </OverlayView>
                </div>
              )}
            </Draggable>
          </div>
        )}
      </Droppable>
                      */}

      {
        !isEmpty(props.driver) && _.isObject(props.driver) && Object.keys(props.driver).length > 0 && props.driver.route_status !== 'COMPLETED' && props.driver.latitude && props.driver.longitude &&
        <OverlayView
          position={{
            lat: props.driver.latitude,
            lng: props.driver.longitude,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <div>
            <Popover
              title="Driver Info"
              content={

                <div style={{ textAlign: 'left' }}>

                  <Row>
                    <Col md={24} lg={24} sm={24} xs={24}>
                      {
                        props.driver.name &&
                        <Row>
                          <Col xs={1}><Icon type="user" /></Col>
                          <Col xs={23}><Tooltip title="Driver NAme">&nbsp;&nbsp;{props.driver.name}</Tooltip></Col>
                        </Row>

                      }
                      {
                        props.driver.mobile_number &&
                        <Row>
                          <Col xs={1}><Icon type="phone" /></Col>
                          <Col xs={23}><Tooltip title="Contact No">&nbsp;&nbsp;{props.driver.mobile_number}</Tooltip></Col>
                        </Row>

                      }
                      { props.driver.tr_datetime ? formatByTimeConfig(momentTime("", props.driver.tr_datetime, props.driver.timeZoneId) , props.isMilitaryTime , "MMM DD, YYYY HH:mm" , "MMM DD, YYYY hh:mm A" )   : '' }
                      {}
                    </Col>

                  </Row>


                </div>


              }
            >
              <div className="overLayPin"><Avatar src={props.driver.icon && props.driver.icon.url ? props.driver.icon.url : manlogo} size={20} /></div>
              <div className='pulse' />
            </Popover>
          </div>
        </OverlayView>
      }

      {
        !isEmpty(props.routes) && _.isArray(props.routes) && props.routes.map((route, routeIndex) =>
          (<Polyline
            key={`polyLine${route.route_id}${routeIndex}`}
            path={route.stop_markers.filter(stop => stop.status !== 'CANCELLED').map(stop => ({ lat: stop.lat, lng: stop.lng }))}
            options={{
              geodesic: true,
              strokeColor: `#${route.bgColor}`,
              strokeOpacity: 1.0,
              strokeWeight: route.route_id === props.selectedRoute ? 4 : 2,
            }}
          />))
      }
      {
        !isEmpty(props.orderInfo) && _.isArray(props.orderInfo) && !isEmpty(props.selectedOrderKeys) && _.isArray(props.selectedOrderKeys) &&
          (<Polyline
            key="currentProcessing"
            path={setOrderSequence(props.orderInfo, props.selectedOrderKeys)}
            options={{
              geodesic: true,
              strokeColor: '#734088',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
          />)
      }
    </GoogleMap>
  </Spin>

));

/* DispatchMap.propTypes = {
  onMapMounted: PropTypes.func.isRequired(),
  onToggleOpen: PropTypes.func.isRequired(),
  orderInfo: PropTypes.arrayOf(PropTypes.shape()),
  routes: PropTypes.arrayOf(PropTypes.shape()),
  selectedOrderKeys: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.arrayOf(PropTypes.string).isRequired(),
  selectedRoute: PropTypes.string,
};

DispatchMap.defaultProps = {
  orderInfo: [],
  routes: [],
  selectedOrderKeys: [],
  selectedRoute: '',
}; */

export default DispatchMap;
