import { Col, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { checkNegitive } from "../../common/Common";
import CopyableContent from "../common/CopyableContent";
import AppConfig from "../../config/AppConfig";

class TransactionsInfoList extends BaseList {
  constructor(props) {
    super(props);
    const columns = [
      {
        key: "batchNo",
        title: <b>{`${"#"} ${I18n.t("batch_management.batch_name")}`}</b>,
        dataIndex: "ar_batch_number",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "checkAchNo",
        title: <b>{I18n.t("batch_management.check_ach_details")}</b>,
        dataIndex: "check_ach_no",
        render: (data, record) => <span>{data}</span>,
        width: 120,
        className: "noWrap",
      },
      {
        key: "modeOfPayement",
        title: <b>{`${I18n.t("batch_management.mode_of_payment")}`}</b>,
        dataIndex: "pay_mode",
        render: (data, record) => <span>{data}</span>,
        width: 90,
        className: "noWrap",
      },
      {
        key: "warehouse",
        title: <b>{I18n.t("batch_management.warehouse")}</b>,
        dataIndex: "wh_name",
        render: (data, record) => <span>{data}</span>,
        className: "noWrap",
        width: 120,
      },
      {
        key: "account_invoice_number",
        title: <b>#{I18n.t("batch_management.account_invoice_number")}</b>,
        dataIndex: "account_invoice_number",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 100,
        align: "left",
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "invoice_amount",
        title: <b>{I18n.t("batch_management.invoice_amount")} ($)</b>,
        dataIndex: "invoice_amount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 110,
        align: "right",
        ...AppConfig.sortingOptions,
      },
      {
        key: "customer_order_number",
        title: <b>#{I18n.t("batch_management.customer_order_number")}</b>,
        dataIndex: "customer_order_number",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 120,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "hawb",
        title: <b>{I18n.t("general.hawb")}</b>,
        dataIndex: "hawb",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 80,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "mawb",
        title: <b>{I18n.t("general.mawb")}</b>,
        dataIndex: "mawb",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 80,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "invoice_order_amount",
        title: <b>{I18n.t("batch_payments.line_total_amount")}</b>,
        dataIndex: "invoice_order_amount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 100,
        align: "right",
        ...AppConfig.sortingOptions,
      },
      {
        key: "order_received_amount",
        title: <b>{I18n.t("account_receivables.utilized_amount")}</b>,
        dataIndex: "order_received_amount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 150,
        align: "right",
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      // {
      //   key: "balance_invoice_amount",
      //   title: <b>{I18n.t("account_receivables.balance_amount")} </b>,
      //   dataIndex: "balance_invoice_amount",
      //   render: (text, data) => <span className="paddingRight20">{checkNegitive(text)}</span>,
      //   width: 160,
      //   align: "right",
      //   className: "noWrap",
      //   ...AppConfig.sortingOptions,
      // },
      {
        key: "invoice_extra_paid_amount",
        title: <b>{I18n.t("credit_notes.invoice_extra_paid_amount")} </b>,
        dataIndex: "invoice_extra_paid_amount",
        render: (text, data) => <span>{checkNegitive(text)}</span>,
        width: 160,
        align: "right",
        className: "noWrap",
      },
      {
        key: "converted_credit_amount",
        title: <b>{I18n.t("credit_notes.credit_amount")} </b>,
        dataIndex: "converted_credit_amount",
        render: (text, data) => <span>{checkNegitive(text)}</span>,
        width: 160,
        align: "right",
        className: "noWrap",
      },
      // {
      //   key: "ref1",
      //   title: <b>{I18n.t("batch_management.reference_1")}</b>,
      //   dataIndex: "reference_1",
      //   render: (data, record) => <CopyableContent text={data} showTooltip/>,
      //   width: 120,
      //   className: "noWrap",
      // },
      // {
      //   key: "ref2",
      //   title: <b>{I18n.t("batch_management.reference_2")}</b>,
      //   dataIndex: "reference_2",
      //   render: (data, record) => <CopyableContent text={data} showTooltip/>,
      //   width: 120,
      //   className: "noWrap",
      // },
      // {
      //   key: "manage",
      //   title: <b>{I18n.t("general.manage")}</b>,
      //   render: (data) => {
      //     const { handleDelete } = this.props;
      //     const { handleRedirect, handleEdit } = this.props;
      //     const { id } = data;
      //     return (
      //       <div className="pageActions">
      //         <Row gutter={8}>
      //           {this.props.showEdit && (
      //             <Col span={12}>
      //               <Tooltip title={I18n.t("general.edit")}>
      //                 <EditIcon
      //                   handleClick={() => handleEdit(data)}
      //                   style={{
      //                     width: "20px",
      //                     height: "20px",
      //                     cursor: "pointer",
      //                     marginTop: "14px",
      //                   }}
      //                 />
      //               </Tooltip>
      //             </Col>
      //           )}
      //           {this.props.showDelete && (
      //             <Col span={12}>
      //               <Popconfirm
      //                 placement="left"
      //                 title={I18n.t("messages.delete_confirm")}
      //                 onConfirm={() => handleDelete(id)}
      //                 okText="Yes"
      //                 cancelText="No"
      //               >
      //                 <Tooltip title={I18n.t("general.delete")}>
      //                   <DeleteIcon />
      //                 </Tooltip>
      //               </Popconfirm>
      //             </Col>
      //           )}
      //         </Row>
      //       </div>
      //     );
      //   },
      //   align: "center",
      //   width: 100,
      // },
    ];
    if (this.props?.hideColumns?.length > 0) {
      this.columns = columns.filter(
        (col) => !this.props.hideColumns.includes(col.key)
      );
    } else {
      this.columns = [...columns];
    }
  }
}
export default TransactionsInfoList;
