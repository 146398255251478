class ZoneStore {
  constructor() {
    this.state = [];
  }

  getZones() {
    return this.state;
  }

  setZones(zones) {
    this.state = zones || [];
  }
}

const zoneStore = new ZoneStore();

export default zoneStore;
