import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  FormItem,
  Popconfirm,
  Select,
  Avatar,
  Badge,
  Modal,
} from '../../common/UIComponents'
import { alertMessage, isEmpty } from '../../common/Common'
import { _ } from 'core-js'
import BaseModal from '../BaseModal'
import FormErrors from '../common/FormErrors'
import userStore from '../../stores/UserStore'
import { updateRouteInMiddle } from '../../api/Routes'
import I18n from '../../common/I18n'

const AddOrdersToRoute = ({
  selectedInfo,
  routes,
  label,
  currentSelected,
  showExistDrivers = false,
  buttonType,
  className = '',
  isDisabled = false,
  size = 'default',
  closeSearchOrders,
  actionType,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [routeId, setRouteId] = useState(currentSelected)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const showAddingForm = (value) => {
    setShowInfoWindow(value)
  }

  const handleRouteChange = (value) => {
    setRouteId(value)
  }
  const resetValues = (closeModal = false) => {
    setRouteId('')
    setShowInfoWindow(false)
    setErrors([])
    if (closeModal) {
      handleCancel()
    }
  }

  const geOtherWHOrders = () => {
    if (!isEmpty(routeId)) {
      const currentRoute = _.find(routes, { id: routeId })
      if (!isEmpty(currentRoute)) {
        const rejectedOrders = selectedInfo.selectedRows
          .filter((order) => order.wh_location_id !== currentRoute.warehouse_id)
          .map((order) => order.id)
        return rejectedOrders
      }
    }
    return []
  }

  const getSelectedOrders = (orderIds = [], keyToRetrieve = '') => {
    const orders = selectedInfo.selectedRows.filter((order) =>
      orderIds.includes(order.id),
    )
    if (!isEmpty(keyToRetrieve) && orders.length > 0) {
      const selectedItems = orders.map((order) => order[keyToRetrieve])
      return selectedItems
    }
    return orders
  }

  const handleSave = async () => {
    const otherWhOrders = geOtherWHOrders()
    if (otherWhOrders.length > 0) {
      const otherWhNumbers = getSelectedOrders(
        otherWhOrders,
        'customer_order_number',
      )
      const whErrors = I18n.t('messages.wh_not_matching_error', {
        field: otherWhNumbers.join(', '),
      })
      Modal.error({
        key: 'error',
        title: 'Error',
        content: (
          <Fragment>
            <div>{whErrors}</div>
          </Fragment>
        ),
        width: '50%',
        // placement: "bottomRight",
        // duration: 20,
      })
    } else {
      const selectedIds = !isEmpty(selectedInfo)
        ? selectedInfo.selectedKeys
        : []
      try {
        const payload = {
          order_ids_to_add: selectedIds,
          organization_id: userStore.getStateValue('selectedOrg'),
          nav_route_id: routeId,
        }

        setLoading(true)
        let response = await updateRouteInMiddle(payload)
        if (response.success) {
          const result = response?.invoices ? response.invoices : {}
          const successMessage = result?.message
            ? result.message
            : I18n.t('messages.saved')
          alertMessage(successMessage)
          if (result.errors && result.errors.length > 0) {
            setErrors(result.errors)
          } else {
            closeSearchOrders(true, routeId, actionType)
            resetValues()
          }
        } else {
          setErrors([...response.errors])
          // throw new Error(response.errors[0])
        }
      } catch (error) {
        alertMessage(error.message, 'error', 10)
      } finally {
        setLoading(false)
      }
    }
  }

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={`${I18n.t('preplan.allocate')} ${I18n.t(
          'menu.orders',
        )} to ${I18n.t('general.route')}`}
        className="customer-orders-modal"
        onCancel={() => {
          resetValues()
        }}
        width="40%"
      >
        <Row>
          <Col xs={24}>
            <span className="textBold">Selected Orders:&nbsp;</span>
            <span className="notes_content">
              {selectedInfo.selectedRows
                .map((order) => order.customer_order_number)
                .join(',')}
            </span>
          </Col>
        </Row>
        <Row type="flex" gutter={4} align="middle">
          <Col span={21}>
            <FormItem
              label={`${I18n.t('general.route')}`}
              style={{ marginTop: 10 }}
            >
              <Select
                style={{ width: '100%' }}
                className="routes-input"
                onChange={(e) => handleRouteChange(e)}
                defaultValue={''}
                value={routeId}
                filterOption={(input, option, index) =>
                  option.props.children.props.children[2]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                allowClear
              >
                {routes.map((province) => (
                  <Option key={province.id}>
                    <div>
                      {province.primary_driver_image_url ? (
                        <Avatar
                          src={`${province.primary_driver_image_url}`}
                          size={30}
                        />
                      ) : (
                        <Avatar
                          src="./../icons/user_100*100.png"
                          alt="DriverProfile"
                          size={30}
                        />
                      )}
                      &nbsp;&nbsp;
                      {province.displayName ? province.displayName : ''}
                      &nbsp;&nbsp;
                      <span className="floatRight">
                        <Badge count={province.nav_stops_count} />
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={3}>
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.confirm')}
              onConfirm={handleSave}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={loading} className="marginTop25">
                Add
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        {FormErrors(errors)}
      </BaseModal>
    )
  }

  return (
    <Fragment>
      <Button
        onClick={() => setShowInfoWindow(true)}
        type={buttonType ? buttonType : 'primary'}
        icon="plus"
        className={className ? className : ''}
        loading={loading}
        disabled={isDisabled}
        size={size ? size : 'default'}
      >
        {label}
      </Button>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

AddOrdersToRoute.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  statusChange: PropTypes.func.isRequired,
}

export default AddOrdersToRoute
