import { Icon, Tooltip } from "antd";
import React, { useState } from "react";
import BaseModal from "../BaseModal";
import { RoutelogContent } from "./RouteLogContent";
import I18n from "../../common/I18n";

const DispatchRouteLog = (props) => {
  const [showRouteData, setShowRouteData] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const { route,isMilitaryTime, keyId} = props;

  const closeRouteLogModal = () => {
    setShowRouteData(false);
  };

  const showRouteLogModal = (id) => {
    setShowRouteData(true);
    setSelectedRoute(id);
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
      showRouteLogModal(commonId);
  };

  const commonId = route[keyId] ? route[keyId] : ""

  const renderRouteLogModal = () => {
    return (
      <BaseModal
        title={
          <div onClick={(e) => e.stopPropagation()} className="padding10px" >
            {I18n.t("routes.logs", { route: route.displayName })}
          </div>
        }
        onCancel={closeRouteLogModal}
        width="80%"
        footer={<div onClick={(e) => e.stopPropagation()} />}
        bodyStyle={{ padding: "0px" }}
        style={{top:20}}
      >
        <div onClick={(e) => e.stopPropagation()} className="padding10px">
          <RoutelogContent closeModal={closeRouteLogModal} routeId={commonId} isMilitaryTime={isMilitaryTime} />
        </div>
      </BaseModal>
    );
  };

  return (
    <>
      {showRouteData && renderRouteLogModal()}
      <Tooltip title={I18n.t("routes.log")}>
        <Icon
          style={{ width: 13, marginTop: -1, marginRight: 15,color:"#5D1D88" }} // 13c2c2
          type="file-text"
          onClick={handleIconClick}
        />
      </Tooltip>
    </>
  );
};

export default DispatchRouteLog;
