import React, { Fragment } from "react";
import BaseList from "../../BaseList";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import Copyable from "../../common/Copyable";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance } from "../../../helpers/common";
import {
  Col,
  Icon,
  Link,
  Popover,
  Row,
  Tooltip,
} from "../../../common/UIComponents";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import I18n from "../../../common/I18n";
import moment from "moment";
import ShowInvoice from "../../billing_screen/ShowInvoice";
import jsonObjIcon from "../../../../assets/images/json-obj.svg";
import _ from "lodash";
import AppConfig from "../../../config/AppConfig";

class InvoiceStatusReportList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "invoice_number",
        title: "Invoice No",
        dataIndex: "invoice_number",
        width: 150,
        className: "alignRight",
        render: (text, record) => (
          <Row type="flex" gutter={4}>
            <Col>
              <ShowInvoice
                account={record.account_id}
                invoice_no={text}
                key={text}
                style={{ marginTop: -6 }}
                account_invoice_id={record.invoice_id}
                refreshList={() => {}}
                showActions={false}
                showInvoiceActions={false}
                approvalDate={record.invoice_approval_date}
                renderType="text"
              />
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        key: "invoice_amount",
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        render: (data) => (
          <span style={{ paddingRight: 50 }}>
            {!isEmpty(data) ? checkNegitive(data) : 0}
          </span>
        ),
        className: "alignRight",
      },
      {
        key: "invoice_approval_date",
        title: "Approved Date",
        dataIndex: "invoice_approval_date",
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        width: 150,
        sorter: (a, b) => compareDate(a.invoice_approval_date, b.invoice_approval_date),
      },
      {
        key: "orders_count",
        title: I18n.t("account.billing.orders_count"),
        // sorter: (a, b) =>
        //   compareNumber(
        //     a.customer_order_numbers.length,
        //     b.customer_order_numbers.length
        //   ),
        // sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => (
          <Fragment>
            {record?.customer_order_numbers &&
            _.isArray(record.customer_order_numbers) ? (
              <Popover
                content={
                  <div
                    style={{
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    {record.customer_order_numbers.join(", ")}
                  </div>
                }
                title={I18n.t("menu.orders")}
                overlayClassName="popoverWidth"
              >
                <u
                  className="text-decoration-dashed"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {record.customer_order_numbers.length}
                </u>
              </Popover>
            ) : (
              0
            )}
          </Fragment>
        ),
      },
      {
        key: "account_name",
        title: I18n.t("account.account_name"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record?.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record?.account_id);
                    }
                  }}
                >
                  <Tooltip title={data} placement="topLeft">
                    {" "}
                    {data}{" "}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        render: (data) => {
          return (
            <Fragment>
              <span className={data === "SUCCESS" ? "textGreen" : "textRed"}>
                {data}
              </span>
            </Fragment>
          );
        },
      },
      {
        key: "responses",
        title: "Response",
        dataIndex: "responses",
        width: 100,
        render: (data, record) => {
          const responseResult = data?.length
            ? _.compact(data).map((item) => {
                if (item.startsWith("{") && item.endsWith("}")) {
                  return JSON.parse(item);
                } else {
                  // If the item is not a JSON string
                  return { error: item };
                }
              })
            : [];

          return responseResult?.length ? (
            <Popover
              title="Response"
              placement={"topLeft"}
              overlayStyle={{ width: 450 }}
              content={
                <Fragment>
                  <Row>
                    <Col>
                      <pre
                        style={{ margin: 0, maxHeight: 300, overflowY: "auto" }}
                      >
                        {JSON.stringify(responseResult, null, 4)}
                      </pre>
                    </Col>
                  </Row>
                </Fragment>
              }
            >
              <Icon
                component={() => (
                  <img
                    style={{ height: 20, cursor: "pointer" }}
                    src={jsonObjIcon}
                  />
                )}
              />
            </Popover>
          ) : (
            ""
          );
        },
      },
    ];
  }
}
export default InvoiceStatusReportList;
