import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { saveZone, deleteZone, fetchZones } from '../api/ZonesApi';
import { Button, Col, Row, Spin } from '../common/UIComponents';
import ZonesList from '../components/zones/List';
import Main from '../components/zones/Main';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import { renderAlertMessage } from '../helpers/common';

class Zones extends Component {
  constructor() {
    super();
    this.state = {
      zones: [], pagination: {}, currentZone: {}, inProgress: false, showMap: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  componentWillMount() {
    this.getZones();
  }

  getZones() {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    fetchZones(page)
      .then((result) => {
        if (result.success) {
          this.setState({
            zones: result.zones,
            pagination: result.pagination,
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleAddClick() {
    this.setState({ currentZone: {}, showMap: true });
  }

  handleEditClick(id) {
    const index = _.findIndex(this.state.zones, ['id', id]);
    const zone = Object.assign({}, this.state.zones[index]);
    this.setState({ currentZone: zone, showMap: true });
  }

  handleDeleteClick(id) {
    this.setState({ inProgress: true });
    deleteZone(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getZones();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleOnSave = (data) => {
    const apiData = Object.assign(
      { name: data.zoneName, location: JSON.stringify(data.geoFenceGeometryData) },
      { id: data.currentZone.id },
    );
    saveZone(!Object.keys(data.currentZone).length, apiData).then((result) => {
      if (result.success) {
        this.handleSaveSuccess(I18n.t('messages.saved'));        
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
      
    });
  }

  handleSaveSuccess(message) {
    alertMessage(message);
    this.setState({ showMap: false });
    this.getZones();
  }

  handleOnClose() {
    this.setState({ showMap: false });
  }

  handleTableChange(pagination, filters, sorter) {
    this.tableOptions = { pagination, filters, sorter };
    this.getZones();
  }

  render() {
    const { pagination } = this.state;
    return (
      <div className='content-outer'>
        <div className='content'>
          {
            this.state.showMap ?
              <Fragment>
                <Row>
                  <Col>
                    <Main
                      currentZone={this.state.currentZone}
                      handleOnSave={this.handleOnSave}
                      handleOnClose={this.handleOnClose}
                    />
                  </Col>
                </Row>
              </Fragment> :
              <Fragment>
                {/* <Row>
                  <Col>
                    <Button type="primary" onClick={() => this.handleAddClick()}>
                      {I18n.t('general.new')}
                    </Button>
                  </Col>
                </Row> */}
                <Row className='page-header'>
                  <Col xs={12}>
                    {I18n.t('menu.zones')}
                  </Col>
                  <Col xs={12} className='page-actions'>
                    <Button type="primary" onClick={() => this.handleAddClick()}>
                      {I18n.t('general.new')}
                    </Button>
                  </Col>
                </Row>
                <Spin spinning={this.state.inProgress} delay={1000}>
                  <Row>
                    <Col>
                      <ZonesList
                        data={this.state.zones}
                        editClick={id => this.handleEditClick(id)}
                        deleteClick={id => this.handleDeleteClick(id)}
                        pagination={{
                          total: pagination.total_count,
                          current: pagination.current_page,
                          pageSize: pagination.per_page,
                        }}
                        tableChange={
                          (tablePagination, filters, sorter) =>
                            this.handleTableChange(tablePagination, filters, sorter)}
                      />
                    </Col>
                  </Row>
                </Spin>
              </Fragment>
          }
        </div>
      </div>
    );
  }
}

export default Zones;
