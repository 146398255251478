import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Form,
  Select,
  InputNumber,
  Radio,
  Button,
  Icon,
  Row,
  Col,
  Collapse,
  Input,
  Card,
  DatePicker,
  Modal,
  Spin,
  Divider,
  Checkbox,
} from "antd";
import _, { set } from "lodash";
import AppointmentsForm from "../orders/OpinionFomTable";
import AddressForm from "../common/AddressForm";
import PhoneInput from "react-phone-input-2";
import TextArea from "antd/lib/input/TextArea";
import AppConfig from "../../config/AppConfig";
import userStore from "../../stores/UserStore";
import { fetchAccountConfigs } from "../../api/Account";
import { alertMessage,isEmpty } from "../../common/Common";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import { fetchAccountShortLos } from "../../api/Los";
import I18n from '../../common/I18n'
import { processTemplateSequence, adjustTemplateAppointment, handleAppointmentErrors } from './helpers';
import Text from 'antd/lib/typography/Text'
import { getHMSFromTimeString } from '../../helpers/date_functions'
import moment from 'moment'
import { checkEndTime } from '../../helpers/orders'
import {
  checkIfRequiredFieldsAreFilled,
  checkServiceExistance,
  renameKeys,
  renderAlertMessage,
  sortData,
} from "../../helpers/common";
import { FormItem, SideBySideFormItem } from "../../common/UIComponents";
import FormErrors from "../common/FormErrors";
import { TemplatesApi } from "./Api";
import Title from "antd/lib/typography/Title";
import Appointments from "../common/Appointments";
import { getWhLocationSlotDetails, validateslots } from "../orders/helpers";
import { OrgContext } from "../../context/OrgContext";
import AddressSearch from "../../stories/components/AddressSearch";
import { VehicleTypeApi } from "../../api/VehicleType";
import { fetchOrderReferences } from "../../api/OrdersApi";
import { OrderConfigContext } from "../../context/OrderConfigContext";

const { Option } = Select;
const { confirm } = Modal;
const { Panel } = Collapse;

function TemplateForm(props) {
  const {
    form,
    warehouses,
    accounts,
    activeRecordId,
    isGoogleApiMounted,
    closeTemplateForm,
    drivers,
    trucks,
    isFetchingDrivers,
    isFetchingTrucks,
  } = props;
  const [levelOfServicesData, setLevelOfServicesData] = useState([]);
  const { orgSettings: organizationSettings,isAirCargo,currentOrg } = useContext(OrgContext);
  const [accountConfigs, setAccountConfigs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLosLoading, setIsLosLoading] = useState(false);
  const orgId = userStore.getStateValue("selectedOrg");
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const newAppointment = {
    s_no: 1,
    slots: [],
    has_expedite: false,
    start_time: null,
    end_time: null,
  };

  const [driversSorted, setDriversSorted] = useState([]);
  const [trucksSorted, setTrucksSorted] = useState([]);
  const [removeExcessData, setRemoveExcessData] = useState(false);
  const [selectedWh, setSelectedWh] = useState(null);
  const [references, setReferences] = useState([]);
  const [selectedDelLos, setSelectedDelLos] = useState("");
  const [originalLos, setOriginalLos] = useState("");
  const [pickupLos, setPickupLos] = useState("");
  const [addressVal, setAddressVal] = useState(false);
  const [consigneeAddressVal, setConsigneeAddressVal] = useState(false);
  const [enableValidations, setEnableValidations] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [formState, setFormState] = useState({
    template_name: {
      touched: false,
      requiredStarMark: true,
    },
    warehouse_id: {
      touched: false,
      requiredStarMark: true,
    },
    account_id: {
      touched: false,
      requiredStarMark: true,
    },
    order_type: {
      touched: false,
      requiredStarMark: true,
    },
    los: {
      touched: false,
      requiredStarMark: true,
    },
    hawb: {
      touched: false,
      requiredStarMark: currentOrg.code == I18n.t("account.hl_code") ? true : false,
    },
    pickup_los: {
      touched: false,
      requiredStarMark: true,
    },
    delivery_los: {
      touched: false,
      requiredStarMark: false,
    },
    weight: {
      touched: false,
      requiredStarMark: true,
    },
    quantity: {
      touched: false,
      requiredStarMark: true,
    },
    skids_pallets: {
      touched: false,
      requiredStarMark: true,
    },
    surface_area: {
      touched: false,
      requiredStarMark: false,
    },
  });
  const [prevAccId, setPrevAccId] = useState(null);
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);
  const allFieldValues = form.getFieldsValue();

  const handleResetFields = () => {
    const newFields = _.omit(allFieldValues, "order_type");
    Object.keys(newFields).forEach((key) => {
      form.setFieldsValue({ [key]: "" });
    });
    setFormData({});
  };

  const prevAccountIdRef = useRef();

  const getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        setVehicleTypes(result.vehicle_types || []);
      } else {
        renderAlertMessage(result.errors)
        setVehicleTypes([]);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getVehicleTypes();
  }, []);
  
  useEffect(() => {
    if (drivers && drivers.length > 0) {
      setDriversSorted(sortData(drivers, "employee_code"));
    }
  }, [drivers]);

  // resetting account value
  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const account_id = form.getFieldValue("account_id");
      if (_.findIndex(accounts, { id: account_id }) < 0) {
        form.setFieldsValue({ account_id: "" });
      }
    } else {
      form.setFieldsValue({ account_id: "" });
    }
  }, [accounts]);

  // resetting los value
  useEffect(() => {
    if (levelOfServicesData && levelOfServicesData.length > 0) {
      const los = form.getFieldValue("los");
      if (_.findIndex(levelOfServicesData, { code: los }) < 0) {
        form.setFieldsValue({ los: "" });
      }
    } else {
      form.setFieldsValue({ los: "" });
    }
  }, [levelOfServicesData]);

  useEffect(() => {
    if (trucks && trucks.length > 0) {
      setTrucksSorted(sortData(trucks, "name"));
    }
  }, [trucks]);

  useEffect(() => {
    if(!activeRecordId){
      form.setFieldsValue({ nsr: _.get(accountConfigs, "pod_signature","false") === "true" ? true : false });
    }
  },[accountConfigs]);

  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const [numofLhs, setNumofLhs] = useState(2);
  const [appointmentValidationInfo, setAppointmentValidationInfo] = useState({
    start_time: {
      isValid: true,
      error: "",
    },
    end_time: {
      isValid: true,
      error: "",
    },
  });
  const { getFieldDecorator, validateFields } = form;

  const handleTriggerValidation = (val) => {
    setSelectedDelLos(val);
    if (val !== "") {
      setAddressVal(true);
      setConsigneeAddressVal(true);
    } else {
      setAddressVal(false);
      setConsigneeAddressVal(false);
    }
  };

  const handleConsigneeAddressChange = (index, val) => {
        if (index === 1) {
      // if any on these incoming ele changes then
      if (val.target.value !== "") {
        setConsigneeAddressVal(true);
        setAddressVal(true);
        setFormState((prevState) => ({
          ...prevState,
          delivery_los: { ...prevState.delivery_los, requiredStarMark: true },
        }));
      } else if (
        val.target.value === "" &&
        !isEmpty(formData.address_transfer_params_seq_1)
      ) {
        setConsigneeAddressVal(true);
        setAddressVal(true);
        setFormState((prevState) => ({
          ...prevState,
          delivery_los: { ...prevState.delivery_los, requiredStarMark: true },
        }));
      } else {
        setConsigneeAddressVal(false);
        setAddressVal(false);
        setFormState((prevState) => ({
          ...prevState,
          delivery_los: { ...prevState.delivery_los, requiredStarMark: false },
        }));
      }
    }
      };

  const checkIfValid = (orderTypeDetails) => {
    const errs = [];
    // const = quoteFormData[ details.key ];
    const requiredFields = [
      "first_name",
      "service_duration",
      {
        key: "address",
        fields: ["address_line1", "city", "state", "country", "zipcode"],
      },
    ];
    const requiredFieldsFilled = () => {
      if (orderTypeDetails.seq_number === 0) {
        return checkIfRequiredFieldsAreFilled(orderTypeDetails, requiredFields);
      } else if (orderTypeDetails.seq_number === 1 && addressVal) {
        return checkIfRequiredFieldsAreFilled(orderTypeDetails, requiredFields);
      } else if (orderTypeDetails.seq_number === 1 && !addressVal) {
        return true;
      } else {
        true;
      }
    };
//integrated detailed error message for all order types
    if (!requiredFieldsFilled()) {
      const errorMessages = {
        "D": "Please fill all the Delivery details",
        "P": "Please fill all the Pickup details",
        "T": orderTypeDetails.seq_number === 0 ? "Please fill all the Pickup details" : "Please fill all the Delivery details",
        "LH": orderTypeDetails.seq_number === 0 ? "Please fill all the Pickup details" : "Please fill all the Delivery details",
        "R": "Please fill all the Pickup details",
      };
    
      const errorMessage = errorMessages[orderTypeDetails.l_type];
      if (errorMessage) {
        errs.push(errorMessage);
      }
    }

    const preference = _.isArray(orderTypeDetails.appointment)
      ? orderTypeDetails.appointment[0]
      : false;

    if (preference && preference.slots.length) {
      const { errors: SlotErrors } = validateslots(
        orderTypeDetails.appointment,
        organizationSettings.is_military_time === "true",
        "appt_date"
      );
      if (SlotErrors.length > 0) {
        SlotErrors.forEach((error) => {
          errs.push(
            error +
              " for " +
              orderConfig.orderTypes[orderTypeDetails.l_type].label +
              " Details"
          );
        });
      }
    }
    return errs;
  };

  // when the template is new or existing one is being edited , fill the form fields with the data
  const fillFormFields = (data = null) => {
    let initialFormValues = {};
    let currentWhId = null;
    if (data) {
      form.setFieldsValue({ order_type: data.order_type });
      setOrderType(data.order_type);
      Object.keys(data).forEach((key) => {
        if (key === "locations") {
          data[key].forEach((location, index) => {
            const isPickup =
              location.l_type === "TR" ||
              location.l_type === "R" ||
              location.l_type === "P";
            const isLineHaul = ["T", "LH"].includes(location.l_type);
            const reqSequnce = location?.loc_order_sequence ? location?.loc_order_sequence : index;
            // for each location if key is address then add the address fields to the initialFormValues by attaching _drop_params or _pickup_params and _seq_ by checking the l_type
            _.forEach(location, (value, key) => {
              if (key === "l_type" || key === "seq") {
                return;
              }
              // initialFormValues[`${key}_${isPickup ? 'pickup' : 'drop'}_params_seq_${index}`] = key === "appointment" ? adjustTemplateAppointment(value) : value
              initialFormValues[
                `${key}${
                  isPickup ? "_pickup" : isLineHaul ? "_transfer" : "_drop"
                }_params_seq_${reqSequnce}`
              ] =
                key === "appointment"
                  ? adjustTemplateAppointment(value)
                  : value;
            });
          });
        } else {
          if(key === "nsr"){
            data[key] = data[key] === true ? false : true;
          }else if(key ==="account_id"){
            setPrevAccId(data[key]);
          }
          initialFormValues[key] = data[key];
        }
      });
      // If the order type is R, remove the delivery details from the
      // initial form values, and vice versa.
      if (data.order_type === "R") {
        initialFormValues = _.omit(initialFormValues, "delivery_details");
      } else if (data.order_type === "D") {
        initialFormValues = _.omit(initialFormValues, "pickup_details");
      } else if (["T", "LH"].includes(data.order_type)) {
        initialFormValues = _.omit(initialFormValues, "pickup_details");
        initialFormValues = _.omit(initialFormValues, "delivery_details");
        if (data.locations && data.locations.length > 1) {
          setNumofLhs(data.locations.length);
        }
      }
      form.setFieldsValue({ pickup_los: data?.locations[0]?.type_of_loc === "PICKUP" &&  data?.locations[0]?.los_code});
      form.setFieldsValue({ delivery_los: data?.locations[1]?.type_of_loc === "DELIVERY" && data?.locations[1]?.los_code });
    } else {
      currentWhId = Array.isArray(props.warehouseFilter.selectedWarehouses)
        ? props.warehouseFilter.selectedWarehouses[0]
        : props.warehouseFilter.selectedWarehouses;
      initialFormValues = {
        warehouse_id: currentWhId,
        account_id: "",
        los: "",
        // weight: 1,
        quantity: 1,
        hazmat: false,
        order_type: "D",
        template_name: "",
        skids_pallets: 1,
        address_type: "COMMERCIAL",
        reference_1_type: "",
        reference_2_type: "",
        vehicle_type: "",
        pickup_los: "",
        delivery_los: "",
        nsr:false, 
      };
      // driver_id: "",
      // fleet_id: "",
    }
    if (data) {
      currentWhId = initialFormValues.warehouse_id;
      setFormData(initialFormValues);
    }
    form.setFieldsValue(initialFormValues);
    const currentWh = warehouses.find((wh) => wh.id === currentWhId);
    setSelectedWh(currentWh);
  };
  // on mount
  useEffect(() => {
    if (activeRecordId) {
      // edit
      // make api call
      TemplatesApi.fetchOne(activeRecordId).then((response) => {
        if (response.success) {
          const { template } = response;
          fillFormFields(template);
        }
      });
    } else {
      fillFormFields();
    }
  }, [activeRecordId]);

  useEffect(() => {
    // if there is an active record
    const deliveryLos = form.getFieldValue("delivery_los") ? form.getFieldValue("delivery_los") : "";
    if (activeRecordId && deliveryLos !== "") {
      setFormState((prevState) => ({
        ...prevState,
        delivery_los: { ...prevState.delivery_los, requiredStarMark: true },
      }));
      setConsigneeAddressVal(true);
      setAddressVal(true);
    }
  }, [form.getFieldValue("delivery_los")]);

  useEffect(() => {
    if(!_.isEmpty(selectedDelLos)){
      handleTriggerValidation(selectedDelLos);
    }
  }, [selectedDelLos]);

  // turn on the validations as soon as we enter the vals in the adressForm and trigger the required star mark

useEffect(() => {
  setFormState((prevState) => {
    let requiredStarMark;
    if (consigneeAddressVal) {
      requiredStarMark = true;
    } else {
      requiredStarMark = false;
    }

    return {
      ...prevState,
      first_name_transfer_params_seq_1: { ...prevState.first_name_transfer_params_seq_1, requiredStarMark },
      phone_1_transfer_params_seq_1 : { ...prevState.phone_1_transfer_params_seq_1, requiredStarMark },
    }
  });
}, [consigneeAddressVal]);

// enable the required star mark for the delivery los as soon as we enter the vals in the address form

  useEffect(()=>{
    if((enableValidations) && _.isEmpty(originalLos) && !_.isEmpty(formData["address_drop_params_seq_0"]) ){
      setFormState((prevState)=> {
        return {
          ...prevState,
          delivery_los: { ...prevState.delivery_los, requiredStarMark: true, touched: true },
        }
      })
      setAddressVal(true);
      setConsigneeAddressVal(true);
    }else if(!enableValidations){
      setFormState((prevState)=> {
        return {
          ...prevState,
          delivery_los: { ...prevState.delivery_los, requiredStarMark: false },
        }
      })
      setAddressVal(false);
      setConsigneeAddressVal(false);
    }
  },[enableValidations])

  // when form warehouse is changed
  useEffect(() => {
    const whId = form.getFieldValue("warehouse_id");
    if (!_.isEmpty(whId)) {
      props.refreshAccounts(whId);
    } else {
      props.refreshAccounts(whId);
      form.setFieldsValue({ account_id: "" });
    }
  }, [form.getFieldValue("warehouse_id")]);

  // clear  los when the account is changed
  useEffect(() => {
    const currentAccountId = form.getFieldValue("account_id");
    if (!_.isEmpty(prevAccountIdRef.current)  && prevAccountIdRef.current !== currentAccountId) {
      // Previous value exists and is different from the current value
      form.setFieldsValue({ los: "", pickup_los: "", delivery_los: "" });
      setSelectedDelLos("");
      setOriginalLos("");
      setPickupLos("");
      handleTriggerValidation("");
      handleRequiredMarkChange("delivery_los", "");
    }
    // Update the ref with the current value for the next render
    prevAccountIdRef.current = currentAccountId;
  }, [form.getFieldValue("account_id")]);

  // set level of services
  const getLos = (accountCode) => {
    const accountIndex = _.findIndex(accounts, {
      code: accountCode,
    });
    if (accountIndex >= 0) {
      const account = accounts[accountIndex];
      if (!isEmpty(account.primary_account_id)) {
        accountCode = getValueFromArrayOfObjects(
          accounts,
          "id",
          account.primary_account_id,
          "code"
        );
      }
    }
    if (!isEmpty(accountCode)) {
      setIsLosLoading(true);
      fetchAccountShortLos(accountCode, form.getFieldValue("order_type")).then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || []
          levelOfServices.push({
            name: "None",
            code: "NONE",
            duration: "0",
            id: "NONE",
          })
          setLevelOfServicesData(levelOfServices)
          setIsLosLoading(false)
        } else {
          setIsLosLoading(false);
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  useEffect(() => {
    setOriginalLos(form.getFieldValue("los") ? form.getFieldValue("los") : "");
    setSelectedDelLos(form.getFieldValue("delivery_los") ? form.getFieldValue("delivery_los") : "");
    setPickupLos(form.getFieldValue("pickup_los") ? form.getFieldValue("pickup_los") : "");
  }, [form.getFieldValue("los"), form.getFieldValue("delivery_los"), form.getFieldValue("pickup_los")]);

  // getting intial orderType and passing it various functions
 useEffect(() => {
    const orderType = form.getFieldValue("order_type");
    setOrderType(orderType);
    handleKeyParmsChange(orderType);
    handleFormDataOnOrderTypeChange(orderType);
    handleLosChange(orderType);
    const selectedAccountId = form.getFieldValue("account_id");
    if (!_.isEmpty(selectedAccountId)) {
      const accountIndex = _.findIndex(accounts, {
        id: selectedAccountId,
      });
      if (accountIndex >= 0) {
        getLos(accounts[accountIndex].code);
      }

    }
    
    
}, [form.getFieldValue("order_type")]);

  // get los on account change
  useEffect(() => {
    const selectedAccountId = form.getFieldValue("account_id");
    if (!_.isEmpty(selectedAccountId)) {
      let account = {};
      const accountIndex = _.findIndex(accounts, {
        id: selectedAccountId,
      });
      if (accountIndex >= 0) {
        account = accounts[accountIndex];
      }
      if (Object.keys(account).length > 0) {
        getAccountConfigs(account);
        getLos(account.code);
      }
    } else {
      setLevelOfServicesData([]);
    }
  }, [form.getFieldValue("account_id")]);


  const getAccountConfigs = (account) => {
    if (account.code) {
      fetchAccountConfigs(account.id, orgId).then((result) => {
        if (result.success) {
          const accountConfigurations = result.account_configurations || [];
          const config = {};
          accountConfigurations.forEach((setting) => {
            config[setting.setting_name] = setting.setting_value;
          });
          if (
            !_.isEmpty(config.pod_pic_settings) &&
            _.isString(config.pod_pic_settings) &&
            config.pod_pic_settings === "[]"
          ) {
            config.pod_pic_settings = [];
          }
          if (isEmpty(config.communication_preference)) {
            config.communication_preference = "SMSES";
          }
          if (!_.isEqual(prevAccId, account.id)) {
            form.setFieldsValue({ nsr: _.get(config, "pod_signature","false") === "true" ? true : false });
          }
          setAccountConfigs(config);
        } else {
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  const scrollToError = () => {
    const errorFields = document.getElementsByClassName("has-error");
    if (errorFields.length > 0) {
      errorFields[0].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    getReferences();
  }, [form.getFieldValue("account_id")]);

  const getReferences = () => {
    setIsLoading(true);
    const acc_id = form.getFieldValue("account_id");
    fetchOrderReferences(orgId, acc_id).then((result) => {
      if (result.success) {
        setReferences(result.references || []);
      } else {
        renderAlertMessage(result.errors)
        setReferences([]);
      }
      setIsLoading(false);
    });
  };

  const removedEmptyKeys = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        removedEmptyKeys(obj[key]);
      } else if (obj[key] === null || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  };

  const removeExcessDataFromForm = (formValues) => {
    let newFormValues = _.cloneDeep(formValues);
    for(const key in newFormValues) {
      if (key.includes("transfer")) {
        delete newFormValues[key];
      }
    }
    return newFormValues; 
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // validate form
    validateFields((err, values) => {
      err = false;
      for (const key in formState) {
        if (key === 'pickup_los') continue; // Skip the pickup_los field
        if (activeRecordId && ["T", "LH"].includes(values.order_type) && ["los", "weight"].includes(key)) {
          setFormState((prevState) => ({
            ...prevState,
            [key]: { ...prevState[key], requiredStarMark: false, touched: false },
          }));
        } 
        else if (!values[key] && formState[key].requiredStarMark) {
          setFormState((prevState) => ({
            ...prevState,
            [key]: { ...prevState[key], touched: true },
          }));
          err = true;
        } else {
          setFormState((prevState) => ({
            ...prevState,
            [key]: { ...prevState[key], touched: false },
          }));
        }
      }
      
      // Check pickup_los and delivery_los separately
      if (["T", "LH"].includes(values.order_type)) {
        if (!values.pickup_los) {
          setFormState((prevState) => ({
            ...prevState,
            pickup_los: { ...(prevState.pickup_los || {}), touched: true },
          }));
          err = true;
        }
      }
      
      if (!err) {
        values.los_name = getValueFromArrayOfObjects(
          levelOfServicesData,
          "code",
          values.los,
          "name"
        );
        values.account_name = getValueFromArrayOfObjects(
          accounts,
          "id",
          values.account_id,
          "name"
        );
        values.account_code = getValueFromArrayOfObjects(
          accounts,
          "id",
          values.account_id,
          "code"
        );
        const refinedValues = removedEmptyKeys(values);
        let finalPayload = processTemplateSequence({
          ...(removeExcessData ? removeExcessDataFromForm(formData) : formData),
          ...refinedValues,
        });
        let orderTypeErrors = [];
        let validVals = true;
        finalPayload.locations.forEach((location, index) => {
          const errors = checkIfValid(location);
          orderTypeErrors = [...orderTypeErrors, ...errors];
          if (errors.length > 0) {
            validVals = false;
          }
        });
        if (Object.keys(appointmentValidationInfo).length > 0) {
          Object.keys(appointmentValidationInfo).forEach((key) => {
            if (!appointmentValidationInfo[key].isValid) {
              validVals = false;
              orderTypeErrors.push(
                `Please select valid ${
                  key === "start_time" ? "start time" : "end time"
                }`
              );
            }
          });
        }

        // send 24 hour time to backend
        finalPayload.locations = finalPayload.locations.map(
          (location, locIndex) => {
            if (
              ["T", "LH"].includes(finalPayload.order_type)
            ) {
              location.type_of_loc = locIndex === 0 ? "PICKUP" : "DELIVERY";
              location.loc_order_sequence = locIndex === 0 ? 0 : 1;
              if (locIndex === 0) {
                location.is_recovery = values.has_recovery;
              } else {
                location.is_release = values.has_release;
              }
            } else {
              location.type_of_loc =
                location.l_type === "R" ?  "PICKUP" : "DELIVERY";
            }
            const pickupLos = _.get(values, "pickup_los", "");
            const deliveryLos = _.get(values, "delivery_los", "");
            const pickup_los_obj = _.find(levelOfServicesData, {
              code: pickupLos,
            });
            if (pickup_los_obj) {
              finalPayload.pickup_los_id = pickup_los_obj?.id;
              finalPayload.pickup_los_name = pickup_los_obj?.name;
              finalPayload.pickup_los_code = pickup_los_obj?.code;
            }
            const delivery_los_obj = _.find(levelOfServicesData, {
              code: deliveryLos,
            });
            if (delivery_los_obj) {
              finalPayload.delivery_los_id = delivery_los_obj?.id;
              finalPayload.delivery_los_name = delivery_los_obj?.name;
              finalPayload.delivery_los_code = delivery_los_obj?.code;
            }
            if (
              pickup_los_obj &&
              locIndex === 0 &&
              ["T", "LH"].includes(finalPayload.order_type)
            ) {
              location.los_id = pickup_los_obj?.id;
              location.los_name = pickup_los_obj?.name;
              location.los_code = pickup_los_obj?.code;
            } else if (
              delivery_los_obj &&
              locIndex === 1 &&
              ["T", "LH"].includes(finalPayload.order_type)
            ) {
              location.los_id = delivery_los_obj?.id;
              location.los_name = delivery_los_obj?.name;
              location.los_code = delivery_los_obj?.code;
            }

            if (location.appointment) {
              const isMilitaryTime =
                organizationSettings.is_military_time === "true";
              location.appointment = location.appointment.map((appointment) => {
                if (appointment.start_time) {
                  const startTimeString = !isMilitaryTime
                    ? moment(appointment.start_time, "hh:mm A").format("HH:mm")
                    : moment(appointment.start_time, "HH:mm").format("HH:mm");
                  appointment.start_time = startTimeString;
                }
                if (appointment.end_time) {
                  const endTimeString = !isMilitaryTime
                    ? moment(appointment.end_time, "hh:mm A").format("HH:mm")
                    : moment(appointment.end_time, "HH:mm").format("HH:mm");
                  appointment.end_time = endTimeString;
                }
                appointment.item_option = appointment.slots;
                return appointment;
              });
            } else {
              location.appointment = [
                {
                  has_expedite: false,
                  start_time: null,
                  end_time: null,
                  item_option: [],
                },
              ];
            }
            return location;
          }
        );
        if (validVals) {
          let modifiedPayload = {
            ...finalPayload,
            nsr: typeof finalPayload.nsr === 'boolean' ? !finalPayload.nsr : finalPayload.nsr,
          };
          let apiPayload = {
            ...modifiedPayload,
            organization_id: orgId,
          };
          if (activeRecordId) {
            setIsLoading(true);
            TemplatesApi.update(activeRecordId, apiPayload)
              .then((result) => {
                if (result.success) {
                  alertMessage("Details updated successfully", "success", 10);
                } else {
                  renderAlertMessage(result.errors)
                }
              })
              .finally(() => {
                setIsLoading(false);
                closeTemplateForm();
              });
          } else {
            setIsLoading(true);
            TemplatesApi.create(apiPayload)
              .then((result) => {
                if (result.success) {
                  const record = result.template || {}
                  if(record?.id){
                    closeTemplateForm(record);
                  }else{
                    closeTemplateForm()
                  }
                  
                  alertMessage(
                    "Recurring order details saved successfully",
                    "success",
                    10
                  );
                } else {
                  renderAlertMessage(result.errors)
                }
              })
              .finally(() => {
                setIsLoading(false);
               // closeTemplateForm();
              });
          }
        } else {
          setFormErrors(orderTypeErrors);
          scrollToError();
        }
      } else {
      }
    });
  };

  const clearPreferenceConfirm = (orderType, rowPosition) => {
    const preferences = formData[`appointment_${orderType}`] || [];
    if (_.get(preferences[rowPosition], "confirmed") === true) {
      preferences[rowPosition].confirmed = false;
      setFormData({ ...formData, [`appointment_${orderType}`]: preferences });
    }
  };
  const handleItemOptionOnChange = (
    orderType,
    index,
    element,
    value,
    checked,
    type = "radio"
  ) => {
    let preferences = _.cloneDeep(formData[`appointment_${orderType}`]) || [
      newAppointment,
    ];
    if (index <= preferences.length - 1) {
      const optionIndex = preferences[index][element].indexOf(value);
      if (optionIndex >= 0) {
        if (value !== "CUSTOM") {
          preferences[index].start_time = null;
          preferences[index].end_time = null;
        }
        if (checked) {
          if (type === "radio" || ["ANY", "CUSTOM"].includes(value)) {
            preferences[index][element] = [value];
          } else {
            preferences[index][element] = preferences[index][element].filter(
              (ele) => !["ANY", "CUSTOM"].includes(ele)
            );
            preferences[index][element].push(value);
          }
        } else {
          if (value === "CUSTOM") {
            preferences[index].start_time = null;
            preferences[index].end_time = null;
          }
          preferences[index][element].splice(optionIndex, 1);
        }
      } else {
        if (value !== "CUSTOM") {
          preferences[index].start_time = null;
          preferences[index].end_time = null;
        }
        // eslint-disable-next-line no-lonely-if
        if (type === "radio" || ["ANY", "CUSTOM"].includes(value)) {
          preferences[index][element] = [value];
        } else {
          // Assuming getting A, M , E
          preferences[index][element] = preferences[index][element].filter(
            (ele) => !["ANY", "CUSTOM"].includes(ele)
          );
          preferences[index][element].push(value);
        }
      }
      if (
        preferences[index][element].includes("M") &&
        preferences[index][element].includes("A") &&
        preferences[index][element].includes("E")
      ) {
        preferences[index][element] = ["ANY"];
      }
      setFormData({ ...formData, [`appointment_${orderType}`]: preferences });
      clearPreferenceConfirm(orderType, index);
    }
  };
  const handleConfirmChange = (orderType, index, element, value) => {
    let preferences = _.cloneDeep(formData[`appointment_${orderType}`]) || [];
    const modifiedPreferences = preferences.map((preference) =>
      Object.assign({}, preference, { confirmed: false })
    );
    modifiedPreferences[index].confirmed = true;
    setFormData({
      ...formData,
      [`appointment_${orderType}`]: modifiedPreferences,
    });
  };
  const clearPreference = (orderType, rowPosition) => {
    let preferences = _.cloneDeep(formData[`appointment_${orderType}`]) || [];
    if (preferences[rowPosition]) {
      preferences[rowPosition].item_preference = "";
      preferences[rowPosition].slots = [];
      preferences[rowPosition].confirmed = false;
      preferences[rowPosition].start_time = null;
      preferences[rowPosition].end_time = null;
      preferences[rowPosition].has_expedite = false;
      setFormData({ ...formData, [`appointment_${orderType}`]: preferences });
    }
  };

  const askConfirmMessage = (orderType, index, element, value) => {
    if (element === "item_preference" && !isEmpty(value)) {
      if (accountConfigs.delivery_zipcodes === "true") {
        confirm({
          title:
            "Deliveries are not scheduled for this zone on this day of the week",
          content: <span className="">{"Do you want to proceed ?"}</span>,
          onOk: () => {
            handlePreferencesChange(orderType, index, element, value);
          },
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onCancel: () => {},
        });
      } else {
        handlePreferencesChange(orderType, index, element, value);
      }
    } else {
      handlePreferencesChange(orderType, index, element, value);
    }
  };

  const handleOrderDetailsChange = (typeOfOrder, element, value, cb = null) => {
    if ((element === "weight" || element === "quantity") && !isEmpty(value)) {
      value = Math.floor(value);
    }
    setFormData({
      ...formData,
      [`appointment_${typeOfOrder}`]: value,
    });
    if (cb) {
      cb();
    }
  };

  const handlePreferencesChange = (orderType, index, element, value) => {
    const { hourValue } = getHMSFromTimeString(
      organizationSettings.route_delivery_end_time,
      "HH:mm",
      null,
      null
    );
    const dispatcherEndHour = hourValue;
    const preferences = formData[`appointment_${orderType}`] || [];
    if (element === "item_preference") {
      if (value !== null) {
        const formattedValue = moment(value).format("YYYY-MM-DD");

        const ifExists = preferences.filter(
          (preference) =>
            preference.item_preference &&
            moment(preference.item_preference).format("YYYY-MM-DD") ===
              formattedValue
        );
        if (ifExists.length > 0) {
          alertMessage(
            `${I18n.t("tracking.duplicate_appointments")} ${I18n.t(
              "messages.appointment_form.select_another_date"
            )}`,
            "error",
            5
          );
        } else if (index <= preferences.length - 1) {
          preferences[index][element] = value;
          handleOrderDetailsChange(
            orderType,
            "preferences",
            preferences,
            () => {
              clearPreferenceConfirm(orderType, index);
            }
          );
        }
      } else if (index <= preferences.length - 1) {
        preferences[index][element] = value;
        handleOrderDetailsChange(orderType, "preferences", preferences, () => {
          clearPreferenceConfirm(orderType, index);
        });
      }
    } else if (element === "start_time" || element === "end_time") {
      if (value !== null) {
        // For from_date,  to_date
        let hasErrors = false;
        const currentPreference = preferences[index];
        if (element === "start_time") {
          const startHour = moment(value).hour();
          if (startHour > dispatcherEndHour) {
            value = moment(value)
              .set("hour", dispatcherEndHour - 1)
              .set("minute", 0);
          }
          preferences[index].end_time = checkEndTime(
            currentPreference,
            value,
            dispatcherEndHour,
            dispatcherEndHour
          );
        }
        if (element === "end_time") {
          const endHour = moment(value).hour();
          if (endHour > dispatcherEndHour) {
            value = moment(value)
              .set("hour", dispatcherEndHour)
              .set("minute", 0);
          }
          if (!isEmpty(currentPreference.start_time)) {
            const isBefore = value.isBefore(currentPreference.start_time);
            if (isBefore) {
              hasErrors = true;
              alertMessage(
                I18n.t("messages.from_time_exceeds_to_time"),
                "error",
                5
              );
            }
          }
        }
        if (!hasErrors && index <= preferences.length - 1) {
          preferences[index][element] = value;
          preferences[index].item_option = ["CUSTOM"];
          handleOrderDetailsChange(
            orderType,
            "preferences",
            preferences,
            () => {
              clearPreferenceConfirm(orderType, index);
            }
          );
        }

        //Start of if start_time is equal to end_time then expedite will be checked
        if (
          !isEmpty(currentPreference.start_time) &&
          moment(currentPreference.start_time).format("h:mm A") ===
            moment(currentPreference.end_time).format("h:mm A")
        ) {
          currentPreference.has_expedite = true;
        }
        //End of if start_time is equal to end_time then expedite will be checked
      } else if (index <= preferences.length - 1) {
        preferences[index][element] = value;
        if (
          isEmpty(preferences[index].start_time) &&
          isEmpty(preferences[index].end_time)
        ) {
          preferences[index].item_option = [];
        }
        handleOrderDetailsChange(orderType, "preferences", preferences, () => {
          clearPreferenceConfirm(orderType, index);
        });
      }
    } else {
      preferences[index][element] = value;
      handleOrderDetailsChange(orderType, "preferences", preferences);
    }
  };
  const onConsigneeLocationChange = (orderType, values) => {
        const { first_name, last_name, email, phone, company_name, l_address } =
      values;
      l_address.first_name = first_name;
      l_address.last_name = last_name;
      l_address.email = email;
      l_address.phone = phone;
      l_address.company_name = company_name;
    setFormData({
      ...formData,
      // [`predefined_locations_${orderType}`]: values,
      [`address_${orderType}`]: l_address,
    });
    form.setFieldsValue({
      [`first_name_${orderType}`]: first_name,
      [`last_name_${orderType}`]: last_name,
      [`email_${orderType}`]: email,
      [`phone_1_${orderType}`]: phone,
      [`company_name_${orderType}`]: company_name,
    });
    if (orderType === "transfer_params_seq_1") {
      setFormState((prevState) => ({
        ...prevState,
        delivery_los: { ...prevState.delivery_los, requiredStarMark: true },
      }));
      setAddressVal(true);
      setConsigneeAddressVal(true);
    }
    // To enable the feedbacks instantaneuosly in the form
    const fields = ['first_name', 'last_name', 'email', 'phone_1', 'company_name'];
    fields.forEach(field => {
      //for phone_1 the value is getting undefined
      if(field === 'phone_1'){
        handleRequiredMarkChange(`${field}_${orderType}`, values.phone);
      }else{
      handleRequiredMarkChange(`${field}_${orderType}`, values[field]);
      }
    });  
  };

  const _addlineHaul = () => {
    setNumofLhs((numofLhs) => numofLhs + 1);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 11 },
      xl: { span: 10 },
      xxl: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: 13 },
      xl: { span: 14 },
      xxl: { span: 12 },
    },
  };

  const gridSize = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
    xl: 8,
  };

  const gridSizeForAddress = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 4,
    xl: 4,
  };

  // core logic to handle los in the formItems on order type change during the shuffling of data
  const handleLosChange = (val) => {
    const currentVal = val;
    const prevVal = orderType;
    const deliveryLosVal = form.getFieldValue("delivery_los") ? form.getFieldValue("delivery_los") : "";
    if (prevVal === "D" && (currentVal === "T" || currentVal === "LH")) {
      form.setFieldsValue({ delivery_los: !_.isEmpty(originalLos) ? originalLos : "" });
    setFormState((prevState) => ({
      ...prevState,
      los: { ...prevState.los, requiredStarMark: false,touched: false },
      delivery_los: { ...prevState.delivery_los, requiredStarMark: originalLos ?  true : false },
    }))
    handleTriggerValidation(deliveryLosVal);
    } else if ((prevVal === "T" || prevVal === "LH") && currentVal === "D") {
      form.setFieldsValue({ los: selectedDelLos });
      setFormState((prevState) => ({
        ...prevState,
        pickup_los: { ...prevState.pickup_los, requiredStarMark: false,touched: false },
        delivery_los: { ...prevState.delivery_los, requiredStarMark: false,touched: false },
        los: { ...prevState.los, requiredStarMark:  true },
      }))
    } else if (prevVal === "R" && (currentVal === "T" || currentVal === "LH")) {
      form.setFieldsValue({ pickup_los: originalLos });
      setFormState((prevState) => ({
        ...prevState,
        los: { ...prevState.los, requiredStarMark: false,touched: false },
      }))
    } else if ((prevVal === "T" || prevVal === "LH") && currentVal === "R") {
      form.setFieldsValue({ los: pickupLos });
      setFormState((prevState) => ({
        ...prevState,
        delivery_los: { ...prevState.delivery_los, requiredStarMark: false,touched: false },
        los: { ...prevState.los, requiredStarMark:  true },
      }))
    }
  };

  // core logic to handle the data shuffle on order type change
  const handleFormDataOnOrderTypeChange = (val) => {
    const newKeyParms = orderConfig.orderTypes[val]?.types[0].key;
    const oldKeyParms =
      orderType !== val ? orderConfig.orderTypes[orderType]?.types[0].key : null;
    const prevVal = orderType;
    const currentVal = val;
    if (
      !_.isEmpty(formData) &&
      formData.hasOwnProperty("address_drop_params_seq_0") &&
      (currentVal === "R" || currentVal === "M") &&
      prevVal === orderType
    ) {
      setFormData({});
    } else if (
      !_.isEmpty(formData) &&
      formData.hasOwnProperty("address_pickup_params_seq_0") &&
      (currentVal === "D" || currentVal === "M") &&
      prevVal === orderType
    ) {
      setFormData({});
    } else if(prevVal === "D" && (currentVal === "M")){
      setAddressVal(true);
    }
    else if (prevVal === "D" && (currentVal === "T" || currentVal === "LH")) {
      setFormData({
        ...formData,
        [`address_${newKeyParms}_seq_1`]: {
          ...formData[`address_${oldKeyParms}_seq_0`],
        },
      });
      setEnableValidations(true);
      form.setFieldsValue({
        [`first_name_${newKeyParms}_seq_1`]: _.get(formData, `address_${oldKeyParms}_seq_0.first_name`, ""),
        [`first_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${newKeyParms}_seq_0.first_name`, ""),
        [`last_name_${newKeyParms}_seq_1`]: _.get(formData, `address_${oldKeyParms}_seq_0.last_name`, ""),
        [`last_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${newKeyParms}_seq_0.last_name`, ""),
        [`email_${newKeyParms}_seq_1`]: _.get(formData, `address_${oldKeyParms}_seq_0.email`, ""),
        [`email_${newKeyParms}_seq_0`]: _.get(formData, `address_${newKeyParms}_seq_0.email`, ""),
        [`phone_1_${newKeyParms}_seq_1`]: _.get(formData, `address_${oldKeyParms}_seq_0.phone`, ""),
        [`phone_1_${newKeyParms}_seq_0`]: _.get(formData, `address_${newKeyParms}_seq_0.phone`, ""),
        [`company_name_${newKeyParms}_seq_1`]: _.get(formData, `address_${oldKeyParms}_seq_0.company_name`, ""),
        [`company_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${newKeyParms}_seq_0.company_name`, ""),
      });
      setFormState((prevState) => ({
        ...prevState,
        [`first_name_${newKeyParms}_seq_1`]: {
          ...prevState[`first_name_${newKeyParms}_seq_1`],
          requiredStarMark:  consigneeAddressVal ? true : false ,
        },
        [`phone_1_${newKeyParms}_seq_1`]: {
          ...prevState[`phone_1_${newKeyParms}_seq_1`],
          requiredStarMark:  consigneeAddressVal ? true : false ,
        },
        weight: { ...prevState.weight, requiredStarMark: false },
        pickup_los: { ...prevState.pickup_los, requiredStarMark: true },
        delivery_los: { ...prevState.delivery_los, requiredStarMark: selectedDelLos ? true : false },
      }));
    } else if ((prevVal === "T" || prevVal === "LH") && currentVal === "D") {
      if(activeRecordId){
        setRemoveExcessData(true);
      }
      setFormData({
        ...formData,
        [`address_${newKeyParms}_seq_0`]: {
          ...formData[`address_${oldKeyParms}_seq_1`],
        },
        //replace order_type in the form data with currentVal
        order_type: currentVal,
      });
      form.setFieldsValue({
        [`first_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_1.first_name`, ""),
        [`last_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_1.last_name`, ""),
        [`email_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_1.email`, ""),
        [`phone_1_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_1.phone`, ""),
        [`company_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_1.company_name`, ""),
      });
      setFormState((prevState) => ({
        ...prevState,
        [`first_name_${newKeyParms}_seq_1`]: {
          ...prevState[`first_name_${newKeyParms}_seq_1`],
          requiredStarMark: false, touched: false
        },
        [`phone_1_${newKeyParms}_seq_1`]: {
          ...prevState[`phone_1_${newKeyParms}_seq_1`],
          requiredStarMark: false, touched: false
        },
        weight: { ...prevState.weight, requiredStarMark: true },
      }));
      setAddressVal(false);
    } else if ((prevVal === "T" || prevVal === "LH") && currentVal === "R") {
      if(activeRecordId){
        setRemoveExcessData(true);
      }
      setFormData({
        ...formData,
        [`address_${newKeyParms}_seq_0`]: {
          ...formData[`address_${oldKeyParms}_seq_0`],
        },
         //replace order_type in the form data with currentVal
         order_type: currentVal,
      });
      setEnableValidations(false);
      form.setFieldsValue({
        [`first_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.first_name`, ""),
        [`last_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.last_name`, ""),
        [`email_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.email`, ""),
        [`phone_1_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.phone`, ""),
        [`company_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.company_name`, ""),
      });
      setFormState((prevState) => ({
        ...prevState,
        [`first_name_${newKeyParms}_seq_1`]: {
          ...prevState[`first_name_${newKeyParms}_seq_1`],
          requiredStarMark: false,
        },
        [`phone_1_${newKeyParms}_seq_1`]: {
          ...prevState[`phone_1_${newKeyParms}_seq_1`],
          requiredStarMark: false,
        },
        weight: { ...prevState.weight, requiredStarMark: true },
      }));
      setAddressVal(false);
    } else if (prevVal === "R" && (currentVal === "T" || currentVal === "LH")) {
      setFormData({
        ...formData,
        [`address_${newKeyParms}_seq_0`]: {
          ...formData[`address_${oldKeyParms}_seq_0`],
        },
      });
      form.setFieldsValue({
        [`first_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.first_name`, ""),
        [`first_name_${newKeyParms}_seq_1`]: _.get(formData, `address_${newKeyParms}_seq_1.first_name`, ""),
        [`last_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.last_name`, ""),
        [`last_name_${newKeyParms}_seq_1`]: _.get(formData, `address_${newKeyParms}_seq_1.last_name`, ""),
        [`email_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.email`, ""),
        [`email_${newKeyParms}_seq_1`]: _.get(formData, `address_${newKeyParms}_seq_1.email`, ""),
        [`phone_1_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.phone`, ""),
        [`phone_1_${newKeyParms}_seq_1`]: _.get(formData, `address_${newKeyParms}_seq_1.phone`, ""),
        [`company_name_${newKeyParms}_seq_0`]: _.get(formData, `address_${oldKeyParms}_seq_0.company_name`, ""),
        [`company_name_${newKeyParms}_seq_1`]: _.get(formData, `address_${newKeyParms}_seq_1.company_name`, ""),
      });
      setFormState((prevState) => ({
        ...prevState,
        [`first_name_${newKeyParms}_seq_1`]: {
          ...prevState[`first_name_${newKeyParms}_seq_1`],
          requiredStarMark: consigneeAddressVal ?  true : false ,
        },
        [`phone_1_${newKeyParms}_seq_1`]: {
          ...prevState[`phone_1_${newKeyParms}_seq_1`],
          requiredStarMark: consigneeAddressVal ?  true : false ,
        },
        weight: { ...prevState.weight, requiredStarMark: false },
        delivery_los: { ...prevState.delivery_los, requiredStarMark: selectedDelLos ? true : false },
      }));
      setAddressVal(false);
    }
  };

  // To append the keyparms  to the formState fields on handle change of order type
  const handleKeyParmsChange = (val) => {
    const getKeyParms = orderConfig.orderTypes[val];
    let newFormState;
    if (val === "T" || val === "LH") {
      newFormState = [...Array(numofLhs)]?.map((details, index) => {
        const keyParms = "transfer_params" + "_seq_" + index;
        return {
          [`service_duration_${keyParms}`]: {
            touched: false,
            requiredStarMark: true,
          },
          [`first_name_${keyParms}`]: {
            touched: false,
            requiredStarMark: true,
          },
          [`phone_1_${keyParms}`]: {
            touched: false,
            requiredStarMark: true,
          },
        };
      });
    } else {
      newFormState = getKeyParms?.types?.map((details, index) => {
        const keyParms = details.key;
        const keyExtension = keyParms + "_seq_" + index;
        return {
          [`service_duration_${keyExtension}`]: {
            touched: false,
            requiredStarMark: true,
          },
          [`first_name_${keyExtension}`]: {
            touched: false,
            requiredStarMark: true,
          },
          [`phone_1_${keyExtension}`]: {
            touched: false,
            requiredStarMark: true,
          },
        };
      });
    }
  
    setFormState((prevState) => {
      const filteredState = Object.keys(prevState).reduce((acc, key) => {
        if (!key.includes('service_duration') && !key.includes('first_name') && !key.includes('phone_1')) {
          acc[key] = prevState[key];
        }
        return acc;
      }, {});
  
      return {
        ...filteredState,
        ...newFormState?.reduce((acc, curr, index) => {
          const keyParms = val === "T" || val === "LH" ? "transfer_params" + "_seq_" + index : getKeyParms?.types[index]?.key + "_seq_" + index;
          return {
            ...acc,
            [`service_duration_${keyParms}`]: curr[`service_duration_${keyParms}`],
            [`first_name_${keyParms}`]: curr[`first_name_${keyParms}`],
            [`phone_1_${keyParms}`]: curr[`phone_1_${keyParms}`],
          };
        }, {}),
      };
    });
  };

  // using this function to handle the validations on change of order type and trigger feedbacks for formitems
  const handleRequiredMarkChange = (key, val) => {
    setFormState((prevState) => {
      const newState = { ...prevState };
      if (key === "delivery_los") {
        if (!_.isEmpty(val)) {
          newState[key] = {
            ...newState[key],
            requiredStarMark: true,
            touched: false,
          };
        }else {
          newState[key] = {
            ...newState[key],
            requiredStarMark: false,
            touched: false,
          };
        }
      } else {
        newState[key] = {
          ...newState[key],
          touched: val ? false : true,
        };
      }
      return newState;
    });
  };

  const _renderLineHaulLocationForm = (index, orderType) => {
    let keyParams = "transfer_params" + "_seq_" + index;
    let handleValidation = index === 1 && !consigneeAddressVal ? false : true;
    let addressFormVal = index === 1 && !addressVal ? false : true;
    const type = index === 0 ? "Is Recovery" : "Is Release";
    let size = "default";
    return (
      <Collapse
        defaultActiveKey={`transfer_params${index}`}
        className="collapse_pad_small customCollapse customCard"
        style={{ width: "100%" }}
      >
        <Panel
          header={`${index ? "Delivery Details" : "Pickup Details"}`}
          key={`transfer_params${index}`}
          className="marginTop5"
          showArrow={false}
        >
          {" "}
          <Row gutter={[8, 8]} type="flex">
            <Col lg={24}>
              <Card size="small" className="misc-card-details">
                <Row gutter={8} justify="space-between" className="templateFontsize">
                  <Col {...gridSize} className="transfer-checkbox">
                    <Form.Item>
                      {getFieldDecorator(
                        index === 0 ? "has_recovery" : "has_release",
                        {
                          initialValue: false,
                        }
                      )(
                        <Checkbox
                          checked={
                            formData[
                              `has_${index === 0 ? "recovery" : "release"}`
                            ]
                          }
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [`has_${index === 0 ? "recovery" : "release"}`]:
                                e.target.checked,
                            });
                          }}
                        >
                          <span className="textBold fontSize12">{type}</span>
                        </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col {...gridSize} offset={8} className="address-form-item">
                    <Form.Item
                      label={
                        <span>
                          {handleValidation &&
                            formState?.[`service_duration_${keyParams}`]
                              ?.requiredStarMark && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          &nbsp;{I18n.t("general.service_duration")}
                        </span>
                      }
                      hasFeedback={
                        formState?.[`service_duration_${keyParams}`]?.touched
                      }
                      validateStatus={
                        formState?.[`service_duration_${keyParams}`]?.touched
                          ? "error"
                          : ""
                      }
                      labelCol={{ lg: 7, xl: 14, xxl: 11 }}
                      wrapperCol={{ lg: 17, xl: 10, xxl: 13 }}
                    >
                      {getFieldDecorator(`service_duration_${keyParams}`, {
                        initialValue: 20,
                      })(
                        <InputNumber
                          min={1}
                          style={{ width: "60%" }}
                          size="small"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={24}>
              <Card size="small">
                <Col lg={24}>
                  <AddressSearch
                    onSelect={(address) =>
                      onConsigneeLocationChange(keyParams, address)
                    }
                  />
                </Col>
                <Col span={24} style={{fontSize:"12px"}}>
                  {isGoogleApiMounted && (
                    <AddressForm
                      autoComplete={true}
                      includePredefinedStops={false}
                      address={formData[`address_${keyParams}`] || {}}
                      onChange={(element, value, address) => {
                        if (address) {
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: address,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: {
                              ...formData[`address_${keyParams}`],
                              [element]: value,
                            },
                          });
                        }
                      }}
                      onConsigneeLocationChange={() => {}}
                      isAdmin
                      disabled={false}
                      gridSize={gridSizeForAddress}
                      FormItem={SideBySideFormItem}
                      size="small"
                      requiredFields={addressFormVal ? null : []}
                    />
                  )}
                </Col>
                <Divider className="address-margin" dashed />
                <Col {...gridSize} className="address-form-item templateFontsize">
                  <Form.Item
                    label="Company"
                    name={`company_name_${keyParams}`}
                    labelCol={{ lg: 7, xl: 8, xxl: 7 }}
                    wrapperCol={{ lg: 17, xl: 16, xxl: 17 }}
                  >
                    {getFieldDecorator(
                      `company_name_${keyParams}`,
                      {}
                    )(
                      <Input
                        onChange={(val) =>{
                          handleConsigneeAddressChange(index, val)
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: {
                              ...formData[`address_${keyParams}`],
                              company_name: val.target.value,
                            },
                          });
                        }
                      }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize} className="address-form-item templateFontsize">
                  <Form.Item
                    label={
                      <span>
                        {handleValidation &&
                          formState?.[`first_name_${keyParams}`]
                            ?.requiredStarMark && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        &nbsp;{I18n.t("general.first_name")}
                      </span>
                    }
                    hasFeedback={
                      handleValidation &&
                      formState?.[`first_name_${keyParams}`]?.touched
                    }
                    validateStatus={
                      handleValidation &&
                      formState?.[`first_name_${keyParams}`]?.touched
                        ? "error"
                        : ""
                    }
                    labelCol={{ lg: 7, xl: 10, xxl: 8 }}
                    wrapperCol={{ lg: 17, xl: 14, xxl: 16 }}
                  >
                    {getFieldDecorator(
                      `first_name_${keyParams}`,
                      {}
                    )(
                      <Input
                        onChange={(val) => {
                          handleConsigneeAddressChange(index, val);
                          handleRequiredMarkChange(`first_name_${keyParams}`,val)
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: {
                              ...formData[`address_${keyParams}`],
                              first_name: val.target.value,
                            },
                          });          
                        }
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize} className="address-form-item templateFontsize">
                  <Form.Item
                    label={I18n.t("general.last_name")}
                    labelCol={{ lg: 7, xl: 10, xxl: 7 }}
                    wrapperCol={{ lg: 17, xl: 14, xxl: 17 }}
                  >
                    {getFieldDecorator(`last_name_${keyParams}`, {
                      rules: [],
                    })(
                      <Input
                        onChange={(val) =>{
                          handleConsigneeAddressChange(index, val)
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: {
                              ...formData[`address_${keyParams}`],
                              last_name: val.target.value,
                            },
                          });
                        }
                      }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize} className="address-form-item templateFontsize">
                  <Form.Item
                    label={I18n.t("general.email")}
                    labelCol={{ lg: 7, xl: 7, xxl: 7 }}
                    wrapperCol={{ lg: 17, xl: 17, xxl: 17 }}
                  >
                    {getFieldDecorator(
                      `email_${keyParams}`,
                      {}
                    )(
                      <Input
                        onChange={(val) =>{
                          handleConsigneeAddressChange(index, val)
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: {
                              ...formData[`address_${keyParams}`],
                              email: val.target.value,
                            },
                          });
                        }
                      }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize} className="address-form-item templateFontsize">
                  <Form.Item
                    label={
                      <span>
                        {handleValidation &&
                          formState?.[`phone_1_${keyParams}`]
                            ?.requiredStarMark && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        &nbsp; Contact 1
                      </span>
                    }
                    validateStatus={
                      handleValidation &&
                      formState?.[`phone_1_${keyParams}`]?.touched
                        ? "error"
                        : ""
                    }
                    hasFeedback={
                      handleValidation &&
                      formState?.[`phone_1_${keyParams}`]?.touched
                    }
                    labelCol={{ lg: 7, xl: 9, xxl: 8 }}
                    wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
                  >
                    {getFieldDecorator(
                      `phone_1_${keyParams}`,
                      {}
                    )(
                      <PhoneInput
                        defaultCountry="us"
                        onlyCountries={["us", "in"]}
                        disableAreaCodes
                        inputStyle={{ width: "100%", height: 33 }}
                        size={size}
                        onChange={(val) => {
                          handleRequiredMarkChange(`phone_1_${keyParams}`,val)
                          setFormData({
                            ...formData,
                            [`address_${keyParams}`]: {
                              ...formData[`address_${keyParams}`],
                              phone: val,
                            },
                          });
                        }
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize} className="address-form-item templateFontsize">
                  <Form.Item
                    label="Contact 2"
                    labelCol={{ lg: 7, xl: 9, xxl: 7 }}
                    wrapperCol={{ lg: 17, xl: 15, xxl: 17 }}
                  >
                    {getFieldDecorator(
                      `phone_2_${keyParams}`,
                      {}
                    )(
                      <PhoneInput
                        defaultCountry="us"
                        onlyCountries={["us", "in"]}
                        disableAreaCodes
                        inputStyle={{ width: "100%", height: 33 }}
                        size={size}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Card>
            </Col>
            <Col lg={24}>
              <Card
                title={I18n.t("appointmentForm.preference_title")}
                size="small"
              >
                {selectedWh?.location_code && (
                  <Appointments
                    slots={getWhLocationSlotDetails(
                      warehouses,
                      selectedWh?.location_code || ""
                    )}
                    warehouse_code={selectedWh?.location_code}
                    feasible_dates={[]}
                    feasibleDow={[]}
                    isMilitaryTime={
                      organizationSettings.is_military_time === "true"
                    }
                    hasDeliveryZipcodesConfig={true}
                    adjustStyle={{
                      label: 2,
                      wrapper: 22,
                    }}
                    onSlotsChange={(selectedSlot, isCustom) => {
                      const orderType = `appointment_${keyParams}`;
                      //slots = slots && _.isArray(slots) ? [...slots] : [slots];
                      // check if formdata has keys if not return
                      let formAppointments = _.cloneDeep(formData[orderType]);
                      if (!_.has(formData, orderType)) {
                        // create new appointment
                        if (!isEmpty(selectedSlot) || isCustom) {
                          let selectedSlots = isCustom
                            ? ["CUSTOM"]
                            : [selectedSlot];
                          const [start_time, end_time] =
                            selectedSlot.split("-");
                          if (
                            selectedSlots.length === 1 &&
                            selectedSlots[0] === "08:00-20:00"
                          ) {
                            selectedSlots = ["ANY"];
                          }
                          setFormData({
                            ...formData,
                            [orderType]: [
                              {
                                ...newAppointment,
                                slots: selectedSlots,
                                start_time: isCustom ? null : start_time,
                                end_time: isCustom ? null : end_time,
                              },
                            ],
                          });
                        }
                      } else {
                        let newAppointments = [];
                        let selectedSlots = isCustom
                          ? ["CUSTOM"]
                          : [selectedSlot];
                        const [start_time, end_time] = selectedSlot.split("-");
                        if (selectedSlots[0] === "08:00-20:00") {
                          selectedSlots = ["ANY"];
                        }
                        if (selectedSlots.length > 0 || isCustom) {
                          if (
                            selectedSlots.length === 1 &&
                            selectedSlots[0] === "08:00-20:00"
                          ) {
                            selectedSlots = ["ANY"];
                          }
                          if (
                            !_.isEmpty(formAppointments) &&
                            formAppointments.length > 0
                          ) {
                            newAppointments.push({
                              ...formAppointments[0],
                              slots: selectedSlots,
                              start_time: isCustom ? null : start_time,
                              end_time: isCustom ? null : end_time,
                            });
                          } else {
                            newAppointments.push({
                              slots: selectedSlots,
                              has_expedite: false,
                              start_time: isCustom ? null : start_time,
                              end_time: isCustom ? null : end_time,
                            });
                          }
                          formAppointments = newAppointments;
                          setFormData({
                            ...formData,
                            [orderType]: formAppointments,
                          });
                        }
                      }
                    }}
                    clearPreference={(index) => {
                      clearPreference(keyParams, index);
                    }}
                    itemOnChange={(index, element, value) => {
                      const orderType = `appointment_${keyParams}`;
                      const newAppointments = [];
                      let formAppointments = _.cloneDeep(formData[orderType]);
                      if (!_.has(formData, orderType)) {
                        // create new appointment
                        setFormData({
                          ...formData,
                          [orderType]: [
                            {
                              ...newAppointment,
                              [element]: value,
                            },
                          ],
                        });
                      } else {
                        if (
                          !_.isEmpty(formAppointments) &&
                          formAppointments.length > 0
                        ) {
                          newAppointments.push({
                            ...formAppointments[0],
                            [element]: value,
                          });
                        }
                        // formAppointments = newAppointments;
                        setFormData({
                          ...formData,
                          [orderType]: newAppointments,
                        });
                      }
                    }}
                    data={{
                      preferences: _.get(
                        formData,
                        `appointment_${keyParams}`,
                        []
                      ).map((appointment) => {
                        return renameKeys(appointment, {
                          item_option: "slots",
                        });
                      }),
                      showExpedite: true,
                      showDatepicker: false,
                      showSerialNos: false,
                      disabledStartHours: [],
                      disabledEndHours: [],
                      startHour: 8,
                      startMinute: 0,
                      endHour: 20,
                      endMinute: 0,
                      hideDate: true,
                    }}
                    size="small"
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    );
  };

  const _renderLineHaulForm = (orderType) => {
    return (
      <>
        <Row gutter={8}>
          {[...Array(numofLhs)].map((e, i) => (
            <Col lg={12} key={i}>
              {_renderLineHaulLocationForm(i, orderType)}
            </Col>
          ))}
        </Row>
      </>
    );
  };

  const _handleAppointmentErrors = (field, error) => {
    const currenApptErrors = handleAppointmentErrors(
      field,
      error,
      appointmentValidationInfo
    );
    setAppointmentValidationInfo(currenApptErrors);
  };

  const renderShipmentForm = (details, formIndex, orderType) => {
    let { key: keyParams } = details;
    const isPickup = keyParams === "pickup_params";
    const size = "default";
    keyParams = keyParams + "_seq_" + formIndex;
    return (
      <Collapse
        defaultActiveKey={["3"]}
        className="collapse_pad_small customCollapse customCard"
        // className="marginTop20 customCollapse"
      >
        <Panel
          header={isPickup ? "Pickup Details" : "Delivery Details"}
          key="3"
          showArrow={false}
        >
          <Row gutter={orderType === "M" ? [8, 8] : 16} type="flex">
            <Col lg={orderType === "M" ? 24 : 12}>
              <Row gutter={[8, 8]}>
                <Col lg={24}>
                  <Card size="small" className="misc-card-details">
                    <Row gutter={8} className="templateFontsize">
                      <Col {...gridSize} className="address-form-item">
                        <Form.Item
                          label={
                            <span>
                              {formState?.[`service_duration_${keyParams}`]
                                ?.requiredStarMark && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                              &nbsp;
                              {I18n.t("general.service_duration")}
                            </span>
                          }
                          className="service-duration"
                          hasFeedback={
                            formState?.[`service_duration_${keyParams}`]
                              ?.touched
                          }
                          validateStatus={
                            formState?.[`service_duration_${keyParams}`]
                              ?.touched
                              ? "error"
                              : ""
                          }
                          labelCol={{ lg: 7, xl: 14, xxl: 13 }}
                          wrapperCol={{ lg: 17, xl: 10, xxl: 11 }}
                        >
                          {getFieldDecorator(`service_duration_${keyParams}`, {
                            initialValue: 5,
                          })(
                            <InputNumber
                              min={1}
                              size="small"
                              style={{ width: "60%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col lg={24}>
                  <Card
                    title={orderType === "M" ? null : "Consignee Details"}
                    size="small"
                  >
                    <Row gutter={8}>
                      <Col lg={24}>
                        <AddressSearch
                          onSelect={(address) =>
                            onConsigneeLocationChange(keyParams, address)
                          }
                        />
                      </Col>
                      <Col lg={24} style={{fontSize:"12px"}}>
                        {isGoogleApiMounted && (
                          <AddressForm
                            autoComplete={true}
                            includePredefinedStops={false}
                            address={formData[`address_${keyParams}`] || {}}
                            onChange={(element, value, address) => {
                              if (address) {
                                setFormData({
                                  ...formData,
                                  [`address_${keyParams}`]: address,
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  [`address_${keyParams}`]: {
                                    ...formData[`address_${keyParams}`],
                                    [element]: value,
                                  },
                                });
                              }
                            }}
                            onConsigneeLocationChange={() => {}}
                            isAdmin
                            disabled={false}
                            gridSize={gridSizeForAddress}
                            FormItem={SideBySideFormItem}
                            size="small"
                          />
                        )}
                      </Col>
                      <Divider dashed className="address-margin" />
                      <Row gutter={8} type="flex" className="templateFontsize">
                        <Col {...gridSize} className="address-form-item">
                          <Form.Item
                            label="Company"
                            name={`company_name_${keyParams}`}
                            labelCol={{ lg: 7, xl: 8, xxl: 7 }}
                            wrapperCol={{ lg: 17, xl: 16, xxl: 17 }}
                          >
                            {getFieldDecorator(
                              `company_name_${keyParams}`,
                              {}
                            )(<Input
                            onChange={(val) =>
                              setFormData({
                                ...formData,
                                [`address_${keyParams}`]: {
                                  ...formData[`address_${keyParams}`],
                                  company_name: val.target.value,
                                },
                              }) 
                            }
                             />)}
                          </Form.Item>
                        </Col>
                        <Col {...gridSize} className="address-form-item">
                          <Form.Item
                            label={
                              <span>
                                {formState?.[`first_name_${keyParams}`]
                                  ?.requiredStarMark && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                                &nbsp;{I18n.t("general.first_name")}
                              </span>
                            }
                            hasFeedback={
                              formState?.[`first_name_${keyParams}`]?.touched
                            }
                            validateStatus={
                              formState?.[`first_name_${keyParams}`]?.touched
                                ? "error"
                                : ""
                            }
                            labelCol={{ lg: 7, xl: 10, xxl: 9 }}
                            wrapperCol={{ lg: 17, xl: 14, xxl: 15 }}
                          >
                            {getFieldDecorator(
                              `first_name_${keyParams}`,
                              {}
                            )(
                              <Input
                                onChange={(val) =>{
                                  handleRequiredMarkChange(`first_name_${keyParams}`,val)
                                  setFormData({
                                    ...formData,
                                    [`address_${keyParams}`]: {
                                      ...formData[`address_${keyParams}`],
                                      first_name: val.target.value,
                                    },
                                  })
                                }
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col {...gridSize} className="address-form-item">
                          <Form.Item
                            label={I18n.t("general.last_name")}
                            labelCol={{ lg: 7, xl: 10, xxl: 9 }}
                            wrapperCol={{ lg: 17, xl: 14, xxl: 15 }}
                          >
                            {getFieldDecorator(`last_name_${keyParams}`, {
                              rules: [],
                            })(<Input
                              onChange={(val) =>
                                setFormData({
                                  ...formData,
                                  [`address_${keyParams}`]: {
                                    ...formData[`address_${keyParams}`],
                                    last_name: val.target.value,
                                  },
                                })
                              }
                             />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8} type="flex" className="templateFontsize">
                        <Col {...gridSize} className="address-form-item">
                          <Form.Item
                            label={I18n.t("general.email")}
                            labelCol={{ lg: 7, xl: 7, xxl: 7 }}
                            wrapperCol={{ lg: 17, xl: 17, xxl: 17 }}
                          >
                            {getFieldDecorator(
                              `email_${keyParams}`,
                              {}
                            )(<Input
                              onChange={(val) =>
                                setFormData({
                                  ...formData,
                                  [`address_${keyParams}`]: {
                                    ...formData[`address_${keyParams}`],
                                    email: val.target.value,
                                  },
                                })
                              }
                             />)}
                          </Form.Item>
                        </Col>
                        <Col {...gridSize} className="address-form-item">
                          <Form.Item
                            label={
                              <span>
                                {formState?.[`phone_1_${keyParams}`]
                                  ?.requiredStarMark && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                                &nbsp; Contact 1
                              </span>
                            }
                            validateStatus={
                              formState?.[`phone_1_${keyParams}`]?.touched
                                ? "error"
                                : ""
                            }
                            hasFeedback={
                              formState?.[`phone_1_${keyParams}`]?.touched
                            }
                            labelCol={{ lg: 7, xl: 9, xxl: 9 }}
                            wrapperCol={{ lg: 17, xl: 15, xxl: 15 }}
                          >
                            {getFieldDecorator(
                              `phone_1_${keyParams}`,
                              {}
                            )(
                              <PhoneInput
                                defaultCountry="us"
                                onlyCountries={["us", "in"]}
                                disableAreaCodes
                                inputStyle={{ width: "100%", height: 33 }}
                                size={size}
                                onChange={(val) =>{
                                  handleRequiredMarkChange(`phone_1_${keyParams}`,val)
                                  setFormData({
                                    ...formData,
                                    [`address_${keyParams}`]: {
                                      ...formData[`address_${keyParams}`],
                                      phone: val,
                                    },
                                  })
                                }
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col {...gridSize} className="address-form-item">
                          <Form.Item
                            label="Contact 2"
                            labelCol={{ lg: 7, xl: 9, xxl: 7 }}
                            wrapperCol={{ lg: 17, xl: 15, xxl: 17 }}
                          >
                            {getFieldDecorator(
                              `phone_2_${keyParams}`,
                              {}
                            )(
                              <PhoneInput
                                defaultCountry="us"
                                onlyCountries={["us", "in"]}
                                disableAreaCodes
                                inputStyle={{ width: "100%", height: 33 }}
                                size={size}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={orderType === "M" ? 24 : 12}>
              <Card
                title={I18n.t("appointmentForm.preference_title")}
                size="small"
              >
                {/* fill like above values */}
                {selectedWh?.location_code && (
                  <Appointments
                    slots={getWhLocationSlotDetails(
                      warehouses,
                      selectedWh?.location_code || ""
                    )}
                    warehouse_code={selectedWh?.location_code}
                    feasible_dates={[]}
                    feasibleDow={[]}
                    isMilitaryTime={
                      organizationSettings.is_military_time === "true"
                    }
                    hasDeliveryZipcodesConfig={true}
                    adjustStyle={{
                      label: 2,
                      wrapper: 22,
                    }}
                    onSlotsChange={(selectedSlot, isCustom) => {
                      const orderType = `appointment_${keyParams}`;
                      // check if formdata has keys if not return
                      let formAppointments = _.cloneDeep(formData[orderType]);
                      if (!_.has(formData, orderType)) {
                        // create new appointment
                        if (!isEmpty(selectedSlot) || isCustom) {
                          let selectedSlots = isCustom
                            ? ["CUSTOM"]
                            : [selectedSlot];
                          const [start_time, end_time] =
                            selectedSlot.split("-");
                          if (
                            selectedSlots.length === 1 &&
                            selectedSlots[0] === "08:00-20:00"
                          ) {
                            selectedSlots = ["ANY"];
                          }
                          setFormData({
                            ...formData,
                            [orderType]: [
                              {
                                ...newAppointment,
                                slots: selectedSlots,
                                start_time: isCustom ? null : start_time,
                                end_time: isCustom ? null : end_time,
                              },
                            ],
                          });
                        }
                      } else {
                        let newAppointments = [];
                        if (!isEmpty(selectedSlot) || isCustom) {
                          let selectedSlots = isCustom
                            ? ["CUSTOM"]
                            : [selectedSlot];
                          const [start_time, end_time] =
                            selectedSlot.split("-");
                          if (
                            selectedSlots.length === 1 &&
                            selectedSlots[0] === "08:00-20:00"
                          ) {
                            selectedSlots = ["ANY"];
                          }
                          if (
                            !_.isEmpty(formAppointments) &&
                            formAppointments.length > 0
                          ) {
                            newAppointments.push({
                              ...formAppointments[0],
                              slots: selectedSlots,
                              start_time: isCustom ? null : start_time,
                              end_time: isCustom ? null : end_time,
                            });
                          } else {
                            newAppointments.push({
                              slots: selectedSlots,
                              has_expedite: false,
                              start_time: isCustom ? null : start_time,
                              end_time: isCustom ? null : end_time,
                            });
                          }
                          formAppointments = newAppointments;
                          setFormData({
                            ...formData,
                            [orderType]: formAppointments,
                          });
                        }
                      }
                    }}
                    clearPreference={(index) => {
                      clearPreference(keyParams, index);
                    }}
                    itemOnChange={(index, element, value) => {
                      const orderType = `appointment_${keyParams}`;
                      const newAppointments = [];
                      let formAppointments = _.cloneDeep(formData[orderType]);
                      if (!_.has(formData, orderType)) {
                        // create new appointment
                        setFormData({
                          ...formData,
                          [orderType]: [
                            {
                              ...newAppointment,
                              [element]: value,
                            },
                          ],
                        });
                      } else {
                        if (
                          !_.isEmpty(formAppointments) &&
                          formAppointments.length > 0
                        ) {
                          newAppointments.push({
                            ...formAppointments[0],
                            [element]: value,
                          });
                        }
                        // formAppointments = newAppointments;
                        setFormData({
                          ...formData,
                          [orderType]: newAppointments,
                        });
                      }
                    }}
                    data={{
                      preferences: _.get(
                        formData,
                        `appointment_${keyParams}`,
                        []
                      ).map((appointment) => {
                        return renameKeys(appointment, {
                          item_option: "slots",
                        });
                      }),
                      showExpedite: true,
                      showDatepicker: false,
                      showSerialNos: false,
                      disabledStartHours: [],
                      disabledEndHours: [],
                      startHour: 8,
                      startMinute: 0,
                      endHour: 20,
                      endMinute: 0,
                      hideDate: true,
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    );
  };

  const tLhcheck = ["T", "LH"].includes(form.getFieldValue("order_type"));

  const renderOrderDetailsForm = () => {
    const isAirCargoCarrier = isAirCargo();
    return (
      <Panel
        header={I18n.t("recurring_order.details")}
        key="1"
        showArrow={false}
      >
        <Row gutter={8} className="templateFontsize">
          <Row type="flex" gutter={8}>
            <Col {...gridSize} className="custom-form-item">
              <Form.Item
                label={
                  <span>
                    {formState?.template_name?.requiredStarMark && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    &nbsp;Template Name
                  </span>
                }
                // className="custom-form-item"
                labelCol={{ lg: 7, xl: 9, xxl: 8 }}
                wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
                validateStatus={
                  formState?.["template_name"]?.touched ? "error" : ""
                }
                hasFeedback={formState?.["template_name"]?.touched}
              >
                {getFieldDecorator(
                  "template_name",
                  {}
                )(
                  <Input
                    size="small"
                    onChange={(val) => {
                      handleRequiredMarkChange(
                        "template_name",
                        val.target.value
                      );
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...gridSize} className="custom-form-item">
              <Form.Item
                label={
                  <span>
                    {formState?.warehouse_id?.requiredStarMark && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    &nbsp;Warehouse
                  </span>
                }
                className="custom-form-item"
                labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                validateStatus={
                  formState?.["warehouse_id"]?.touched ? "error" : ""
                }
                hasFeedback={formState?.["warehouse_id"]?.touched}
              >
                {getFieldDecorator(
                  "warehouse_id",
                  {}
                )(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      const currentWh = warehouses.find(
                        (wh) => wh.id === value
                      );
                      setSelectedWh(currentWh);
                      handleRequiredMarkChange("warehouse_id", value);
                    }}
                    size="small"
                  >
                    <Select.Option key="warehouse" value="">
                      -- Select --
                    </Select.Option>
                    {warehouses.map((warehouse) => (
                      <Select.Option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...gridSize} className="custom-form-item">
              <Form.Item
                label={
                  <span>
                    {formState?.account_id?.requiredStarMark && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    &nbsp;Account
                  </span>
                }
                className="selected-form-item"
                labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                validateStatus={
                  formState?.["account_id"]?.touched ? "error" : ""
                }
                hasFeedback={formState?.["account_id"]?.touched}
              >
                {getFieldDecorator(
                  "account_id",
                  {}
                )(
                  <Select
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownMatchSelectWidth={false}
                    showSearch
                    size="small"
                    onChange={(value) => {
                      handleRequiredMarkChange("account_id", value);
                    }}
                  >
                    <Select.Option key="account" value="">
                      -- Select --
                    </Select.Option>
                    {accounts?.map((account) => (
                      <Select.Option key={account.id} value={account.id}>
                        {account.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={8}>
            <Col {...gridSize} className="custom-form-item">
              <Form.Item
                label={
                  <span>
                    {formState?.order_type?.requiredStarMark && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    &nbsp;Order Type
                  </span>
                }
                // className="custom-form-item"
                labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                validateStatus={
                  formState?.["order_type"]?.touched ? "error" : ""
                }
                hasFeedback={formState?.["order_type"]?.touched}
              >
                {getFieldDecorator("order_type", {
                })(
                  <Select
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    size="small"
                  >
                    {Object.keys(orderConfig.orderTypes)
                      .filter((key) => {
                        const hideKey = isAirCargoCarrier ? ["X","LH","M"]:["X","LH"];
                        return !hideKey.includes(key);
                      })
                      .map((key) => (
                        <Select.Option value={key} key={key}>
                          {orderConfig.orderTypes[key].label}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {!["T", "LH"].includes(form.getFieldValue("order_type")) ? (
              <>
                <Col {...gridSize} className="custom-form-item">
                  <Form.Item
                    label={
                      <span>
                        {formState?.los?.requiredStarMark && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                        &nbsp;Level of Service
                      </span>
                    }
                    // className="custom-form-item"
                    labelCol={{ lg: 7, xl: 9, xxl: 8 }}
                    wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
                    validateStatus={formState?.["los"]?.touched ? "error" : ""}
                    hasFeedback={formState?.["los"]?.touched}
                  >
                    {getFieldDecorator(
                      "los",
                      {}
                    )(
                      <Select
                        placeholder="-- Select --"
                        showSearch
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        loading={isLosLoading}
                        size="small"
                        onChange={(value) => {
                          handleRequiredMarkChange("los", value);
                        }}
                      >
                        <Select.Option key="LOS" value="">
                          -- Select --
                        </Select.Option>
                        {levelOfServicesData.map((los) => (
                          <Select.Option key={los.code} value={los.code}>
                            {los.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col {...gridSize} className="custom-form-item">
                  <Form.Item
                    label={
                      <span>
                        {formState?.pickup_los?.requiredStarMark && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                        &nbsp;Pickup LOS
                      </span>
                    }
                    // className="custom-form-item"
                    validateStatus={
                      formState?.["pickup_los"]?.touched ? "error" : ""
                    }
                    hasFeedback={formState?.["pickup_los"]?.touched}
                    labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                    wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                  >
                    {getFieldDecorator(
                      "pickup_los",
                      {}
                    )(
                      <Select
                        showSearch
                        placeholder="-- Select --"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        loading={isLosLoading}
                        size="small"
                        onChange={(value) => {
                          handleRequiredMarkChange("pickup_los", value);
                        }}
                      >
                        <Select.Option key="pickup_los" value="">
                          -- Select --
                        </Select.Option>
                        {levelOfServicesData.map((los) => (
                          <Select.Option key={los.code} value={los.code}>
                            {los.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col {...gridSize} className="custom-form-item">
                  <Form.Item
                    label={
                      <span>
                        {formState?.delivery_los?.requiredStarMark && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                        &nbsp;Delivery LOS
                      </span>
                    }
                    className="selected-form-item"
                    labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                    wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                    validateStatus={
                      formState?.["delivery_los"]?.touched ? "error" : ""
                    }
                    hasFeedback={formState?.["delivery_los"]?.touched}
                  >
                    {getFieldDecorator(
                      "delivery_los",
                      {}
                    )(
                      <Select
                        showSearch
                        placeholder="-- Select --"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        loading={isLosLoading}
                        size="small"
                        onChange={(value) => {
                          handleTriggerValidation(value);
                          handleRequiredMarkChange("delivery_los", value);
                        }}
                        value={selectedDelLos}
                      >
                        <Select.Option key="delivery_los" value="">
                          -- Select --
                        </Select.Option>
                        {levelOfServicesData.map((los) => (
                          <Select.Option key={los.code} value={los.code}>
                            {los.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </>
            )}
            {!["T", "LH"].includes(form.getFieldValue("order_type")) ? (
              <Col {...gridSize} className="custom-form-item">
                <Form.Item
                  label="Vehicle Type"
                  className={
                    !tLhcheck ? "selected-form-item" : "custom-form-item"
                  }
                  labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                  wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                >
                  {getFieldDecorator(
                    "vehicle_type",
                    {}
                  )(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="small"
                    >
                      <Select.Option key="vehicle_type" value="">
                        -- Select --
                      </Select.Option>
                      {vehicleTypes.map((code) => (
                        <Select.Option key={code.name} value={code.code}>
                          {code.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Row type="flex" gutter={8}>
            {["T", "LH"].includes(form.getFieldValue("order_type")) ? (
              <Col {...gridSize} className="custom-form-item">
                <Form.Item
                  label="Vehicle Type"
                  className={
                    !tLhcheck ? "selected-form-item" : "custom-form-item"
                  }
                  labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                  wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
                >
                  {getFieldDecorator(
                    "vehicle_type",
                    {}
                  )(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="small"
                    >
                      <Select.Option key="vehicle_type" value="">
                        -- Select --
                      </Select.Option>
                      {vehicleTypes.map((code) => (
                        <Select.Option key={code.name} value={code.code}>
                          {code.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}
            <Col {...gridSize} className="custom-form-item">
              <Form.Item
                label="HAZMAT"
                className="custom-form-item"
                labelCol={{ lg: 7, xl: 8, xxl: 8 }}
                wrapperCol={{ lg: 17, xl: 16, xxl: 16 }}
              >
                {getFieldDecorator(
                  "hazmat",
                  {}
                )(
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col lg={!tLhcheck ? 8 : 8} className="custom-form-item">
              <Form.Item
                label="Address"
                className={tLhcheck ? "selected-form-item" : "custom-form-item"}
                labelCol={{ lg: 9, xl: 7, xxl: 6 }}
                wrapperCol={{ lg: 15, xl: 17, xxl: 18 }}
              >
                {getFieldDecorator(
                  "address_type",
                  {}
                )(
                  <Radio.Group>
                    <Radio value="RESEDENTIAL">
                      {I18n.t("order.resedential_type")}
                    </Radio>
                    <Radio value="COMMERCIAL">
                      {I18n.t("order.commercial_type")}
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col lg={!tLhcheck ? 8 : 10} className="custom-form-item">
              <Form.Item
                label="Require Signature"
                className={tLhcheck ? "selected-form-item" : "custom-form-item"}
                labelCol={{ lg: 9, xl: 9, xxl: 8 }}
                wrapperCol={{ lg: 15, xl: 15, xxl: 16 }}
              >
                {getFieldDecorator(
                  "nsr",
                  {}
                )(
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Panel>
    );
  };

  const renderMeasurmentsForm = () => {
    return (
      <Panel
        header="Measurements"
        key="2"
        showArrow={false}
        className="measurment_panel_style"
      >
        <Row gutter={8} type="flex" className="templateFontsize">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="measurments_form"
          >
            <Form.Item
              label={
                <span>
                  {!['T','LH'].includes(orderType) && formState?.weight?.requiredStarMark && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                  &nbsp;Weight (lbs)
                </span>
              }
              labelCol={{ xl: 13 }}
              wrapperCol={{ xl: 11 }}
              validateStatus={!['T','LH'].includes(orderType) && formState?.["weight"]?.touched ? "error" : ""}
              hasFeedback={!['T','LH'].includes(orderType) && formState?.["weight"]?.touched}
              className="measurments_form"
            >
              {getFieldDecorator(
                "weight",
                {}
              )(
                <InputNumber
                  min={1}
                  style={{ width: 75 }}
                  size="small"
                  onChange={(val) => handleRequiredMarkChange("weight", val)}
                />
              )}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="measurments_form"
          >
            <Form.Item
              label={
                <span>
                  {formState?.quantity?.requiredStarMark && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                  &nbsp;Qty
                </span>
              }
              labelCol={{ xl: 12 }}
              wrapperCol={{ xl: 12 }}
              validateStatus={formState?.["quantity"]?.touched ? "error" : ""}
              hasFeedback={formState?.["quantity"]?.touched}
              className="measurments_form"
            >
              {getFieldDecorator(
                "quantity",
                {}
              )(
                <InputNumber
                  min={1}
                  style={{ width: 75 }}
                  size="small"
                  onChange={(val) => handleRequiredMarkChange("quantity", val)}
                />
              )}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="measurments_form"
          >
            <Form.Item
              label={
                <span>
                  {formState?.skids_pallets?.requiredStarMark && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                  &nbsp;Skids / Pallets
                </span>
              }
              labelCol={{ xl: 15 }}
              wrapperCol={{ xl: 9 }}
              validateStatus={formState?.["skids_pallets"]?.touched ? "error" : ""}
              hasFeedback={formState?.["skids_pallets"]?.touched}
              className="measurments_form"
            >
              {getFieldDecorator(
                "skids_pallets",
                {}
              )(
                <InputNumber
                  min={1}
                  style={{ width: 75 }}
                  size="small"
                  onChange={(val) =>
                    handleRequiredMarkChange("skids_pallets", val)
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="measurments_form"
          >
            <Form.Item
              label={
                <span>
                  {I18n.t("order.surface_area_sf")} (
                  {I18n.t("order.surface_area_units")}
                  <sup>2</sup>)
                </span>
              }
              labelCol={{ xl: 15 }}
              wrapperCol={{ xl: 9 }}
              className="measurments_form"
            >
              {getFieldDecorator(
                "surface_area",
                {}
              )(
                <InputNumber
                  min={1}
                  style={{ width: 75 }}
                  size="small"
                  onChange={(val) =>
                    handleRequiredMarkChange("surface_area", val)
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderReferencesForm = () => {
    return (
      <Panel
        header="References"
        key="ref"
        showArrow={false}
        className="reference_panel_style"
      >
        <Row className="templateFontsize">
          <Col xs={24} sm={24} md={24} lg={12} className="references_form">
            <Form.Item className="references_form"
              label={
                currentOrg.code == I18n.t("account.hl_code") ? <span>
                  {formState?.template_name?.requiredStarMark && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                  &nbsp;HAWB
                </span> : "HAWB"
              }
              validateStatus={
                currentOrg.code == I18n.t("account.hl_code") ? formState?.["hawb"]?.touched ? "error" : "" : null
              }
              hasFeedback={currentOrg.code == I18n.t("account.hl_code") ? formState?.["hawb"]?.touched : null}
            >
              {getFieldDecorator("hawb")(<Input
                onChange={(val) => {
                  currentOrg.code == I18n.t("account.hl_code") ? handleRequiredMarkChange(
                    "hawb",
                    val.target.value
                  ) : null
                }} size="small" />)}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            className="selected_references_form"
          >
            <Form.Item label="MAWB" className="selected_references_form">
              {getFieldDecorator("mawb")(<Input size="small" />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="references_form">
            <Form.Item
              label={`${I18n.t("order.reference_title_short")} 1`}
              className="references_form"
            >
              {getFieldDecorator("reference_1_type", {
                rules: [
                  {
                    required: false,
                    message: "invalid input",
                  },
                ],
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select.Option key="ref" value="">
                    -- Select --
                  </Select.Option>
                  {references
                    .filter(
                      (ref) =>
                        !_.isEmpty(references) &&
                        ref.order_reference_type !==
                          form.getFieldValue("reference_2_type")
                    )
                    .map((ref) => (
                      <Select.Option
                        key={ref.id}
                        value={ref.order_reference_type}
                      >
                        {ref.order_reference_type}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            className="selected_references_form"
          >
            <Form.Item
              label={`${I18n.t("order.reference_value_short_form")} 1`}
              className="selected_references_form"
            >
              {getFieldDecorator("reference_1", {
                rules: [
                  {
                    required: false,
                    message: "invalid input",
                  },
                ],
              })(<Input size="small" />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="references_form">
            <Form.Item
              label={`${I18n.t("order.reference_title_short")} 2`}
              className="references_form"
            >
              {getFieldDecorator("reference_2_type", {
                rules: [
                  {
                    required: false,
                    message: "invalid input",
                  },
                ],
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Select.Option key="reference_2_type" value="">
                    -- Select --
                  </Select.Option>
                  {references
                    .filter(
                      (ref) =>
                        !_.isEmpty(references) &&
                        ref.order_reference_type !==
                          form.getFieldValue("reference_1_type")
                    )
                    .map((ref) => (
                      <Select.Option
                        key={ref.id}
                        value={ref.order_reference_type}
                      >
                        {ref.order_reference_type}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            className="selected_references_form"
          >
            <Form.Item
              label={`${I18n.t("order.reference_value_short_form")} 2`}
              className="selected_references_form"
            >
              {getFieldDecorator("reference_2", {
                rules: [
                  {
                    required: false,
                    message: "invalid input",
                  },
                ],
              })(<Input size="small" style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderNoteForm = () => {
    return (
      <Panel header="Notes" key="notes" showArrow={false}>
        <Row gutter={8} className="templateFontsize">
          <Col lg={24} className="notes-form-item">
            <Form.Item
              label="Notes"
              className="notes-form-item"
              labelCol={{ lg: 2, xl: 2, xxl: 2 }}
              wrapperCol={{ lg: 22, xl: 22, xxl: 22 }}
            >
              {getFieldDecorator("notes")(
                <TextArea style={{ width: "100%" }} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    );
  };

  const renderLabelInfoForm = () => {
    return (
      <Panel header="Label Info" key="label" showArrow={false}>
        <Row gutter={8} className="templateFontsize">
          <Col lg={6} className="label-info-form-item">
            <Form.Item
              label="Origin"
              className="custom-form-item"
              labelCol={{ lg: 7, xl: 9, xxl: 8 }}
              wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
            >
              {getFieldDecorator("origin_port", {
                rules: [
                  {
                    required: false,
                    message: "Invalid Input",
                  },
                ],
              })(<Input size="small" />)}
            </Form.Item>
          </Col>
          <Col lg={6} className="label-info-form-item">
            <Form.Item
              label="Destination"
              className="custom-form-item"
              labelCol={{ lg: 7, xl: 9, xxl: 8 }}
              wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
            >
              {getFieldDecorator("destination_port", {
                rules: [
                  {
                    required: false,
                    message: "Invalid Input",
                  },
                ],
              })(<Input size="small" />)}
            </Form.Item>
          </Col>
          <Col lg={6} className="label-info-form-item">
            <Form.Item
              label="IT Number"
              className="custom-form-item"
              labelCol={{ lg: 7, xl: 9, xxl: 8 }}
              wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
            >
              {getFieldDecorator("it_number", {
                rules: [
                  {
                    required: false,
                    message: "Invalid Input",
                  },
                ],
              })(<Input size="small" />)}
            </Form.Item>
          </Col>
          <Col lg={6} className="label-info-form-item">
            <Form.Item
              label="Routing"
              className="custom-form-item"
              labelCol={{ lg: 7, xl: 9, xxl: 8 }}
              wrapperCol={{ lg: 17, xl: 15, xxl: 16 }}
            >
              {getFieldDecorator("routing", {
                rules: [
                  {
                    required: false,
                    message: "Invalid Input",
                  },
                ],
              })(<Input size="small" />)}
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    );
  };

  const _renderLocalForm = (orderType) => {
    const shipmentOrderTypes = orderConfig.orderTypes[orderType];
    return (
      <>
        <Row gutter={8}>
          {shipmentOrderTypes &&
            shipmentOrderTypes.types.map((details, index) => (
              <Col span={orderType === "M" ? 12 : 24}>
                {renderShipmentForm(details, index, orderType)}
              </Col>
            ))}
        </Row>
      </>
    );
  };

  const _renderShipment = (orderType) => {
    // line haul or local (delivery,pickup)
    if (["T", "LH"].includes(orderType)) {
      return _renderLineHaulForm(orderType);
    } else {
      return _renderLocalForm(orderType);
    }
  };

  return (
    <Form
      onReset={() => {
        form.resetFields();
      }}
      {...formItemLayout}
    >
      <Row
        type="flex"
        justify="space-between"
        gutter={4}
        style={
          {
            // padding: '0 15px',
          }
        }
      >
        <Col>
          {/* <Title level={4}>
            {activeRecordId ? I18n.t('recurring_order.edit') : I18n.t('recurring_order.create')}
          </Title> */}
        </Col>
        <Col style={{ marginTop: -50 }}>
          <Icon
            type="close"
            onClick={() => {
              // close drawer
              closeTemplateForm();
            }}
            className="marginRight10"
          />
        </Col>
      </Row>
      <Spin spinning={isLoading} delay={1000}>
        <Row className="templateFormContainer">
          <Row gutter={8}>
            <Col span={17}>
              <Collapse
                defaultActiveKey={["1"]}
                className="collapse_pad_small customCollapse customCard"
              >
                {renderOrderDetailsForm()}
              </Collapse>
            </Col>
            <Col span={7}>
              <Collapse
                defaultActiveKey={["2"]}
                className="collapse_pad_small customCollapse customCard"
              >
                {renderMeasurmentsForm()}
              </Collapse>
            </Col>
          </Row>
          <Row gutter={8} type="flex">
            <Col span={8}>
              <Collapse
                defaultActiveKey={["ref"]}
                className="collapse_pad_small customCollapse customCard"
              >
                {renderReferencesForm()}
              </Collapse>
            </Col>
            <Col span={16}>
              <Row gutter={8} type="flex">
                <Col span={24}>
                  <Collapse
                    defaultActiveKey={["notes"]}
                    className="collapse_pad_small customCollapse customCard"
                  >
                    {renderNoteForm()}
                  </Collapse>
                </Col>
                <Col span={24}>
                  <Collapse
                    defaultActiveKey={["label"]}
                    className="collapse_pad_small customCollapse customCard"
                  >
                    {renderLabelInfoForm()}
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Col span={ 24 }>
          <Collapse defaultActiveKey={ [ '2' ] } className="collapse_pad_small customCollapse customCard">
            { renderReferencesForm() }
          </Collapse>
        </Col> */}
          {/* {orderConfig.orderTypes[form.getFieldValue('order_type')] &&
            orderConfig.orderTypes[form.getFieldValue('order_type')].types.map(
              (details, index) => (
                <Col span={24}>
                  <Collapse
                    defaultActiveKey={['3']}
                    className="collapse_pad_small customCollapse customCard"
                  >
                    {renderShipmentForm(details, index)}
                  </Collapse>
                </Col>
              ),
            )} */}
          {/* {form. this._renderShipmentForm()} */}
          {/* {form.getFieldValue('order_type') == 'T' ? this._renderLineHaulShipment() : this._renderShipmentForm()} */}
          {_renderShipment(form.getFieldValue("order_type"))}
          {/* <Col span={ 24 }>
            <Row>
              {
                _.isString(form.getFieldValue('order_type')) &&
                orderConfig.orderTypes[ form.getFieldValue('order_type') ].types.map((details, index) => {
                  const detailsType = details.key == "drop_params" ? "delivery_details" : "pickup_details";
                  return (
                    <Col span={ 24 }>
                      { FormErrors(formErrors[ detailsType ] , `Error in ${details.label} Details `) }
                    </Col>
                  );
                })
              }
            </Row>
          </Col> */}
          <Col span={24}>
            <Row>{formErrors.length ? FormErrors(formErrors) : null}</Row>
          </Col>
          <Col span={24}>
            <Row className="buttonsList">
              {!activeRecordId && (
                <Col>
                  <Button
                    type="ghost"
                    onClick={handleResetFields}
                    icon="delete"
                  >
                    Clear
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type="danger"
                  htmlType="button"
                  onClick={() => {
                    closeTemplateForm();
                  }}
                  icon="close"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button type="primary" icon="save" onClick={handleSubmit}>
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
}

const WrappedTemplateForm = Form.create({ name: "Order Template Form" })(
  TemplateForm
);
export default WrappedTemplateForm;
