import userStore from '../stores/UserStore';
import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export const driverClaimsApi = {
  fetch: (
    driver_id
  ) => {
    let url = ApiUrl(`v2/billing/driver_claims?driver_id=${driver_id}&operation_code=DCRI`);
    return Call(
      "get",
      url,
      {},
      ParseGeneralResponse,
      {
        responseDataKeys: {
          driver_claims: "driver_claims",
          remarks: "claim_remarks",
        },
      }
    );
  },
  manage:( data) => {
    data.organization_id = userStore.getStateValue("selectedOrg");
    data.operation_code = 'EDCR'
    const url =  ApiUrl("v2/billing/create_driver_claims")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, 
      {
        responseDataKeys: { message: "message", driver_claim: "driver_claim", remarks: "claim_remarks" },
      }, 
    true);
  },
  manageAdditionalAmount:( data) => {
    data.operation_code = 'ADDCR'
    const url =  ApiUrl("v2/billing/create_additional_claim_balance")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, 
      {
        responseDataKeys: { message: "message", driver_claim: "driver_claim" },
      }, 
    true);
  },
  delete: (id) => {
    const url = ApiUrl(`v2/billing/delete_driver_claim?driver_claim_id=${id}&operation_code=DDCD`);
    return Call("GET", url, { operation_code: 'DDCR'}, ParseGeneralResponse, {
      responseDataKeys: { message: "message", remarks: "claim_remarks" },
    });
  },
};