import AppConfig from "../config/AppConfig";
import {
  ApiUrl,
  Call,
  ParseGeneralResponse,
  optionalPageParams,
} from "./ApiUtils";
export const OpenBalApi = {
  fetchOpenBalReports: (
    page,
    perPage,
    wh_location_id,
    organization_id,
    searchParams,
    whIds,
    categoryType,
    accountCodes = [],
    isExport=false
  ) => {
    const {
      fromDate = "",
      toDate = "",
      warehouse_id = "",
      sortBy,
      sortOrder,
      amountType,
    } = searchParams;
    const warehouseIds = warehouse_id ? warehouse_id : wh_location_id;
    const locationIds =
      typeof warehouseIds === "string"
        ? warehouseIds
        : warehouseIds.length == 0
        ? whIds
        : warehouseIds;
    const from_date = fromDate.format("YYYY-MM-DD HH:mm:ss Z");
    const to_date = toDate.format("YYYY-MM-DD HH:mm:ss Z");
    let url = ApiUrl(
      `v2/billing_aging_reports/get_open_invoices?organization_id=${organization_id}&wh_location_id=${locationIds}&${optionalPageParams(
        page,
        perPage,
        false
      )}&from_date=${from_date}&to_date=${to_date}&sort_by=${sortBy}&sort_order=${sortOrder}&view_by=${categoryType}&account_id=${accountCodes.join(',')}&invoice_amount_filter=${amountType}`
    );
    let exportUrl = `${url}&download_as=excel`;
    return Call("get", isExport ? exportUrl : url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
        file: 'file',
        pagination: "meta.pagination",
      },
    });
  },
};
