
import React from "react";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { formatByTimeConfig } from "../../helpers/common";
import { momentTime } from "../../helpers/date_functions";
import { Typography } from "antd";

class RouteLogContentList extends BaseList {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        align: "center",
        width:70
      },
      {
        key: "created_on",
        title: I18n.t("routes.createdOn"),
        dataIndex: "created_on",
        width:170,
        align: "center",
        render: (data) =>
          data
            ? formatByTimeConfig(
                momentTime("", data),
                this.props.isMilitaryTime,
                "MMM Do, YYYY HH:mm",
                "MMM Do, YYYY hh:mm A"
              )
            : "",
      },
      
      {
        key: "created_by",
        title: I18n.t("routes.createdBy"),
        dataIndex: "created_by",
        align: "center",
        width:180,
      },
      {
        key:"description",
        title: I18n.t("routes.description"),
        dataIndex:"description",
        align:"left",
        render: (text) => <div className="wordWrap"><Typography.Paragraph ellipsis={{rows:2, expandable:true }}>
        {text}
        </Typography.Paragraph>
        </div>
      }
    ];
  }
}
export default RouteLogContentList;