/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Col,
    FormItem,
    Input,
    Row,
    Radio,
    Icon,
    Select,
    Checkbox,
    Modal,
} from "../../common/UIComponents";
import {
    alertMessage,
    isEmpty,
    randomNumber,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import {
    fetchCategories, fetchLosSkeleton, saveCategoryData, fetchAdjustmentCategoryDetails,
} from "../../api/AdjustmentsApi";
import { fetchAccountShortLos } from "../../api/Los";
import RateMatrix from "./RateMatrix";
import { fetchAccountZones } from "../../api/BillingZones";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import LosContracts from "../billing_screen/LosContracts";

const { confirm } = Modal;
class Configuration extends Component {
    constructor(props) {
        super(props);
        const decimalPoints= window.localStorage.getItem('round_off_decimals');
        this.state = {
            currentAdjustment: this.props.adjustment || {},
            components: [],
            isNew: false,
            account: this.props.account || {},
            inProgress: false,
            errors: [],
            categories: [],
            currentConfiguration: { rate_matrix_from: 'Clone' },
            currentCategory: {},
            levelOfServices: [],
            hasValues: false,
            zones: [],
            lastZone: {},
            isInitial: true,
            isInitialCalled: false,
            decimalPoints,
        };
    }

    componentDidMount() {
        // this.getCategories();
        this.getLOS();

    }

    setDefaultBeyondMiles = () =>{
         const { isInitial, lastZone, currentConfiguration } = this.state;
        if (
          isInitial && lastZone &&
          lastZone.zone_type === "location" &&
          currentConfiguration &&
          isEmpty(currentConfiguration.beyond_miles)
        ) {
          currentConfiguration.beyond_miles = "0";
        }

        if (
          isInitial &&
          lastZone &&
          lastZone.zone_type === "distance" &&
          currentConfiguration &&
          isEmpty(currentConfiguration.beyond_miles)
        ) {
          currentConfiguration.beyond_miles = lastZone.max_distance;
        }

        if (currentConfiguration && isEmpty(currentConfiguration.charge_for)){
            currentConfiguration.charge_for = "all"
        }

        if (currentConfiguration?.charge_for && currentConfiguration.charge_for === "all") {
            const zones = this.state.zones || [];
            if (zones.length > 0) {
                currentConfiguration["last_zone_id"] = zones[zones.length - 1].id;
            } else {
                currentConfiguration["last_zone_id"] = "";
            }
        }

        this.setState({ currentConfiguration });
    }
    
    getCategories = (cb) => {
        const { currentAdjustment } = this.state;
        this.setState({ inProgress: true });
        fetchCategories()
            .then((result) => {
                if (result.success) {
                    const categories = result.categories || [];
                    const currentCategory = _.find(categories, { code: currentAdjustment.category_code })
                    this.setState({
                        categories,
                        inProgress: false,
                        currentCategory: isEmpty(currentCategory) ? {} : currentCategory,
                    }, () => {
                        this.getParamDetails();
                        if (cb) {
                            cb();
                        }
                    });
                } else {
                    renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }

    getLOS = () => {
        const { account } = this.state;
        fetchAccountShortLos(account.code).then((result) => {
            if (result.success) {
                const levelOfServices = result.account.account_los || [];
                this.setState({
                    levelOfServices,
                }, () => {
                    this.getCategories();
                    // this.setFirstLosByDefault();
                });
            } else {
                renderAlertMessage(result.errors)
                this.setState({ levelOfServices: [] });
            }
        });

    }

    setFirstLosByDefault = () => {
        if (isEmpty(this.state.currentConfiguration.los_code) && this.state.levelOfServices.length > 0) {
            this.handleOnChange('los_code', this.state.levelOfServices[0].code);
        }
        if (isEmpty(this.state.currentConfiguration.los) && this.state.levelOfServices.length > 0) {
            this.handleOnChange('los', this.state.levelOfServices[0].id);
        }
    }

    getParamDetails = () => {
        fetchAdjustmentCategoryDetails(this.state.account.id, this.state.currentAdjustment.id, this.state.currentAdjustment.code).then((result) => {
            if (result.success) {
                const currentParamDetails = result.details || {};
                this.setState(
                    {
                        currentParamDetails,
                    },
                    () => {
                        this.setData();
                    }
                );
            } else {
                renderAlertMessage(result.errors)
                this.setState({ inProgress: false });
            }
        });
    };

    checkRateMarixSource = () => {
        const { currentConfiguration, currentCategory } = this.state;
        // if (isEmpty(currentConfiguration['rate_matrix_from'])) {
        //     currentConfiguration['rate_matrix_from'] = 'Clone';
        //     this.setState({
        //         currentConfiguration,
        //     });
        // }
        if (currentCategory.code === 'EXML') {
            this.getAccountZones('zones');
        } else if (currentConfiguration['rate_matrix_from'] && currentConfiguration['rate_matrix_from'] !== 'Clone') {
            this.getAccountZones('cost_per_weight');
        }
    }

    setData = () => {
        const { currentAdjustment, currentParamDetails, currentCategory } = this.state;
        const isExml = currentCategory.code === 'EXML';
        let hasValues = false;
        const categoryParams = currentCategory.category_params ? currentCategory.category_params : [];
        const currentConfiguration = {};
        categoryParams.forEach((param) => {
            if (param.data_type === "wt_range") {
                currentConfiguration[param.code] = {
                    weightsData: [],
                    rateCard: [],
                };
            } else {
                currentConfiguration[param.code] =
                    !isEmpty(param.default_value) ? param.default_value : "";
            }
        });

        let zoneSno = 0;
        const weightParams = currentParamDetails.weight_params || [];
        const sortedParams = _.sortBy(weightParams, 'sequence_number');
        sortedParams.forEach((record, index) => {
            if (
                (isEmpty(record.zone_id) &&
                    record.min_weight === "0" &&
                    record.max_weight === "0")
            ) {
                record.param_values.forEach((param) => {
                    currentConfiguration[param.adjustment_key] = param.adjustment_value;
                });
            } else if (!isExml && record.max_weight !== "0") {
                // Assuming ranges
                record.param_values.forEach((param, index) => {
                    const weightsData =
                        currentConfiguration[param.adjustment_key] &&
                            currentConfiguration[param.adjustment_key].weightsData
                            ? [...currentConfiguration[param.adjustment_key].weightsData]
                            : [];
                    const zones =
                        currentConfiguration[param.adjustment_key] &&
                            currentConfiguration[param.adjustment_key].rateCard ?
                            currentConfiguration[param.adjustment_key].rateCard
                            : [];
                    const zoneIndex = _.findIndex(zones, ['zone_id', record.zone_id]);
                    let tempId = randomNumber();
                    const existRecord = _.find(weightsData, {
                        min_weight: record.min_weight,
                        max_weight: record.max_weight,
                    });
                    if (
                        isEmpty(existRecord) &&
                        !isEmpty(record.min_weight) &&
                        !isEmpty(record.max_weight)
                    ) {
                        weightsData.push({
                            id: tempId,
                            min_weight: record.min_weight,
                            max_weight: record.max_weight,
                            isNew: false,
                        });
                    } else if (!isEmpty(existRecord)) {
                        tempId = existRecord.id;
                    }
                    if (!isEmpty(param.adjustment_value)) {
                        hasValues = true;
                    }
                    const costObject = {
                        key: !isEmpty(record.id) ? record.id : '',
                        cost: !isEmpty(param.adjustment_value) ? parseFloat(param.adjustment_value) : '',
                        breakpoint_weight: !isEmpty(record.breakpoint_weight) ? record.breakpoint_weight : '',
                        isEditable: false,
                    };
                    if (zoneIndex < 0) {
                        zoneSno = zoneSno + 1;
                        zones.push({
                            zone_id: record.zone_id,
                            zone_name: record.zone_name || `Zone ${index}`,
                            min_charge_editable: false,
                            min_charge: !isEmpty(record.min_charge) ? parseFloat(record.min_charge) : '',
                            [tempId]: { ...costObject },
                            sequenceNumber: zoneSno,

                        });
                    } else {
                        zones[zoneIndex][tempId] = { ...costObject };
                    }
                    if (!currentConfiguration[param.adjustment_key]) {
                        currentConfiguration[param.adjustment_key] = {
                            weightsData: [],
                            rateCard: [],
                        };
                    }
                    const weights = _.sortBy(weightsData, 'min_weight');
                    currentConfiguration[param.adjustment_key].weightsData = !isEmpty(weights) ? [...weights] : [];
                    currentConfiguration[param.adjustment_key].rateCard = zones;
                });
            }

        });
        if (!isExml) {
            const minWeightRecords = sortedParams.filter(record => !isEmpty(record.zone_id) &&
                record.min_weight === "0" &&
                record.max_weight === "0" && record.param_values);
            minWeightRecords.forEach((record, index) => {
                const zones = currentConfiguration['cost_per_weight'].rateCard || [];
                const zoneIndex = _.findIndex(zones, { zone_id: record.zone_id });
                if (zoneIndex >= 0) {
                    record.param_values.forEach((param) => {
                        currentConfiguration['cost_per_weight'].rateCard[zoneIndex].min_charge = !isEmpty(param.adjustment_value) ? param.adjustment_value : ''
                    });
                }
            });
            if (isEmpty(currentConfiguration['rate_matrix_from'])) {
                currentConfiguration['rate_matrix_from'] = 'Clone';
            }
            // if (isEmpty(currentConfiguration['los']) && this.state.levelOfServices.length > 0) {
            //     currentConfiguration['los'] = this.state.levelOfServices[0].code;
            // }
        }
        this.setState({
            currentConfiguration,
            hasValues,
        }, () => {
            this.checkRateMarixSource();
            if (currentCategory.code !== 'EXML') {
                this.setFirstLosByDefault();
            }
        });
    };

    getAccountZones = (element, cb = null) => {
        this.setState({ inProgress: true });
        const { currentConfiguration } = this.state;
        fetchAccountZones(this.state.account.id)
            .then((result) => {
                if (result.success) {
                    const zones = result.zones || [];
                    const lastZone =  zones.length > 0 ? zones[zones.length-1] : {};
                    if (element === 'zones') {
                        this.setState({
                            zones,
                            inProgress: false,
                            lastZone,
                        }, ()=>{
                            if (!this.state.isInitialCalled) {
                              this.setDefaultBeyondMiles();
                              this.setState({ isInitialCalled: true });
                            }
                            
                        });
                    } else {
                        const { currentConfiguration } = this.state;
                        currentConfiguration[element] = {
                            weightsData: [],
                            rateCard: zones.map(zone => ({
                                zone_id: zone.id,
                                zone_name: zone.name,
                                // min_charge: zone.min_charge,
                            })),
                        }
                        this.setState({
                            currentConfiguration,
                            inProgress: false,
                            lastZone,
                        }, () => {
                            if (!this.state.isInitialCalled) {
                              this.setDefaultBeyondMiles();
                              this.setState({ isInitialCalled: true });
                            }
                            if(cb){
                                cb
                            }
                        });
                    }
                } else {
                    renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }

    getLosRateValues = (los) => {
        const { account, currentConfiguration, currentCategory } = this.state;
        let hasValues = false;
        fetchLosSkeleton(account.id, los).then((result) => {
            if (result.success) {
                const rateZones = result.clone_details || [];
                const zones = [];
                let zoneSno = 0;
                const weightsData = [];
                rateZones.forEach(record => {
                    let tempId = randomNumber();
                    const zoneIndex = _.findIndex(zones, ['zone_id', record.zone_id]);
                    if (currentCategory.code === 'EXML') {
                        if (zoneIndex < 0) {
                            zoneSno = zoneSno + 1;
                            zones.push({
                                zone_id: record.zone_id,
                                zone_name: record.zone_name,
                                sequenceNumber: zoneSno,
                            });
                        }
                        this.setState({
                            zones,
                        });
                    } else {
                        const existRecord = _.find(weightsData, {
                            min_weight: record.min_weight,
                            max_weight: record.max_weight,
                        });
                        if (isEmpty(existRecord) && !isEmpty(record.min_weight) && !isEmpty(record.min_weight)) {
                            weightsData.push({
                                id: tempId,
                                min_weight: record.min_weight,
                                max_weight: record.max_weight,
                                isNew: false,
                            });
                        } else if (!isEmpty(existRecord)) {
                            tempId = existRecord.id;
                        }
                        if (!isEmpty(record.amount)) {
                            hasValues = true;
                        }
                        if (zoneIndex < 0) {
                            zoneSno = zoneSno + 1;
                            zones.push({
                                zone_id: record.zone_id,
                                zone_name: record.zone_name,
                                min_charge: '',
                                min_charge_editable: false,
                                [tempId]: {
                                    key: isEmpty(record.id) ? record.id : '',
                                    cost: record.amount ? parseFloat(record.amount) : 0,
                                    breakpoint_weight: !isEmpty(record.breakpoint_weight) ? record.breakpoint_weight : '',
                                    isEditable: false,

                                },
                                sequenceNumber: zoneSno,
                            });
                        } else {
                            zones[zoneIndex][tempId] = {
                                key: isEmpty(record.id) ? record.id : '',
                                cost: record.amount ? parseFloat(record.amount) : 0,
                                breakpoint_weight: !isEmpty(record.breakpoint_weight) ? record.breakpoint_weight : '',
                                isEditable: false,
                            };
                        }
                        const weights = _.sortBy(weightsData, 'min_weight');
                        currentConfiguration['cost_per_weight'] = {
                            weightsData: [...weights],
                            rateCard: _.cloneDeep(zones),
                        };
                        this.setState({
                            currentConfiguration,
                            hasValues,
                        });
                    }

                });

            }
        })
    }

    clearForm = () => {
        this.setState({
            isNew: false,
        });
    };

    askConfirmMessage = (element, value) => {
        if (['los_code', 'los'].includes(element) && this.state.hasValues) { //!isEmpty(this.state.currentConfiguration.los)) {
            confirm({
                title: I18n.t('adjustments.change_waring', { field: 'adjustment ' }),
                content: <span className="">{"Do you want to proceed ?"}</span>,
                onOk: () => {
                    this.handleOnChange(element, value);
                },
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onCancel: () => { },
            });
        } else {
            this.handleOnChange(element, value);
        }
    }

    handleContractChange = (element, isManual , value, ) => {
      if(isManual){
        this.askConfirmMessage(element, value)
      } else{
        this.handleOnChange(element, value);
      }
    }

    handleOnChange = (element, value) => {
        const { currentConfiguration, currentCategory } = this.state;
        if (isEmpty(currentConfiguration[element])) {
            currentConfiguration[element] = '';
        }
        if (currentCategory.code === 'EXML' && element === 'charge_for') {
            if (value === 'crossed_miles' || value === 'all') {
                const zones = this.state.zones || [];
                if (zones.length > 0) {
                    currentConfiguration['last_zone_id'] = zones[zones.length - 1].id;
                } else {
                    currentConfiguration['last_zone_id'] = '';
                }
            } else {
                currentConfiguration['last_zone_id'] = '';
            }
        }
        if (element === "rate_matrix_from" && currentConfiguration[element] !== 'Clone') {
            currentConfiguration['los_code'] = '';
            currentConfiguration['los'] = '';
        }
        currentConfiguration[element] = value;
        this.setState({
            currentConfiguration,
        }, () => {
            if (element === "los" && checkServiceExistance('LOSCD')) {
                this.getLosRateValues(value);
            }
            this.checkRateMarixSource();
        });
    };


    handleSave = () => {
        const currentConfiguration = Object.assign(
            {},
            this.state.currentConfiguration
        );
        const data = { weight_params: [] };
        data.account_adjustment_id = !isEmpty(this.state.currentAdjustment)
            ? this.state.currentAdjustment.id
            : "";
        data.adjustment_code = !isEmpty(this.state.currentAdjustment)
            ? this.state.currentAdjustment.code
            : "";
        data.account_id = this.state.account.id;
        data.org_id = this.state.account.organization_id;
        const rangeParams = [];
        const params = [];
        const currentParam = {};
        const weightParams = [];
        const minChargeParams = [];
        const { currentCategory } = this.state;
        if (currentCategory.code === "EXML") {
          if (currentConfiguration.apply_additional_fuel_surcharge === false)
            currentConfiguration.fuel_percentage_value = 0;
          
        }  
        Object.keys(currentConfiguration).forEach((param) => {
            
            if (param === "cost_per_weight") {
                const weightsData = currentConfiguration[param].weightsData || [];
                const rateCard =
                    currentConfiguration[param].rateCard || [];
                weightsData.forEach((weightRecord) => {
                    rateCard.forEach((zone) => {
                        weightParams.push({
                            min_weight: parseInt(weightRecord.min_weight),
                            max_weight: parseInt(weightRecord.max_weight),
                            zone_id: zone.zone_id,
                            zone_name: zone.zone_name,
                            sequence_number: zone.sequenceNumber,
                            min_charge_editable: false,
                            param_values: [{
                                adjustment_key: param,
                                adjustment_value: !isEmpty(zone[weightRecord.id])
                                    ? zone[weightRecord.id].cost
                                    : "",
                                // beyond_miles: this.state.beyond_miles || this.state.lastZone.max_distance
                            }],

                        });
                    });


                });
                if (rateCard.length > 0) {
                    rateCard.forEach(zone => {
                        const minChargeObject = {
                            min_weight: "0",
                            max_weight: "0",
                            zone_id: zone.zone_id,
                            zone_name: zone.zone_name,
                            min_charge_editable: false,
                            sequence_number: zone.sequenceNumber,
                            param_values: [{
                                adjustment_key: 'min_charge',
                                adjustment_value: !isEmpty(zone.min_charge)
                                    ? zone.min_charge
                                    : "",
                            }],
                        }
                        weightParams.push(minChargeObject);
                    });
                }
            }  
            else {
                const paramObject = {
                    adjustment_key: param,
                    adjustment_value: currentConfiguration[param],
                };
                params.push(paramObject);
            }
        });
      
        if (params.length > 0) {
            const weightParamObject = {
                min_weight: "0",
                max_weight: "0",
                zone_id: null,
                zone_name: null,
                sequence_number: null,
                param_values: [...params],
            };
            weightParams.push(weightParamObject);
        }
        // if (rangeParams.length > 0) {
        //     data.weight_params = [].concat(
        //         data.weight_params,
        //         rangeParams
        //     );
        // }
        data.weight_params = [...weightParams];
        const errors = [];
        if(currentConfiguration.beyond_miles === ""){
            errors.push("Beyond Miles can't be blank.")
        }
        if (currentConfiguration.charge_per_mile === "") {
          errors.push("Charge Per Mile can't be blank.");
        }
        if (currentConfiguration.fuel_percentage_value === "") {
            errors.push("Fuel Percentage Value can't be blank.");
          }
        if (errors.length === 0) {
            this.setState({ inProgress: true });
            saveCategoryData(data).then((result) => {
                if (result.success) {
                    alertMessage(I18n.t("messages.saved"));
                    this.setState(
                        {
                            errors: [],
                            inProgress: false,
                            isInitial: false
                        },
                        () => {
                            this.getParamDetails();
                        }
                    );
                    // this.clearForm();
                } else {
                    this.setState({ errors: result.errors, inProgress: false });
                }
            });
        } else {
            this.setState({ errors, inProgress: false });
        }
    };

    getParamLabel = (code, paramCode) => {
        const { currentComponents } = this.state;
        const currentRecord = _.find(currentComponents, { code });
        if (!isEmpty(currentRecord)) {
            const currentParams = currentRecord.component_params || [];
            const currentParam = _.find(currentParams, { code: paramCode });
            if (!isEmpty(currentParam)) {
                return currentParam.name;
            }
        }
        return "";
    };

    handleWeightRangeChange = (param, record) => {
        const { currentConfiguration } = this.state;
        const weightsData = [...currentConfiguration[param.code].weightsData]
        const rateCard = [...currentConfiguration[param.code].rateCard]
        const currentRatecardIndex = _.findIndex(rateCard, ['zone_id', record.zone_id]);
        if (currentRatecardIndex >= 0) {
            currentConfiguration[param.code].rateCard[currentRatecardIndex][record.id]['cost'] = record.cost;
        }
        this.setState({
            currentConfiguration,
            hasValues: true,
        }, () => {
            this.handleSave();
        })

    }

    handleMinChargeChange = (param, record) => {
        const { currentConfiguration } = this.state;
        const rateCard = [...currentConfiguration[param.code].rateCard]
        const currentRatecardIndex = _.findIndex(rateCard, ['zone_id', record.zone_id]);
        if (currentRatecardIndex >= 0) {
            currentConfiguration[param.code].rateCard[currentRatecardIndex]['min_charge'] = record.min_charge;
        }
        this.setState({
            currentConfiguration,
            hasValues: true,
        }, () => {
            this.handleSave();
        })

    }

    renderField = (param, currentConfiguration) => {
        const size = "small";
        const { decimalPoints } = this.state;
        const currentDefaultValue = !isEmpty(param.default_value)
            ? param.default_value
            : "";
        const currentValue = !isEmpty(currentConfiguration[param.code])
            ? currentConfiguration[param.code]
            : "";
        const isShowActions = checkServiceExistance('CADP');
        switch (param.data_type) {
            case "string":
                return (
                    <Row>
                        <Col>
                            {param.code === "charge_type" ? (
                                <Fragment>
                                    <Radio.Group
                                        onChange={(e) =>
                                            this.handleOnChange(
                                                param.code,
                                                e.target.value
                                            )
                                        }
                                        value={currentValue}
                                        size={size}
                                    >
                                        <Radio value="percentage">Percentage</Radio>
                                        <Radio value="flat">Flat</Radio>
                                    </Radio.Group>
                                    {currentConfiguration[param.code] === "percentage" && (
                                        <p className="notes_content" style={{ marginTop: 4 }}>
                                            {I18n.t("account.billing.percentage_help_text")}
                                        </p>
                                    )}
                                </Fragment>
                            ) : param.code === "charge_value" ? (
                                <Input
                                    type="number"
                                    addonBefore={
                                        currentConfiguration.charge_type === "flat" ? (
                                            <Icon type="dollar" />
                                        ) : null
                                    }
                                    addonAfter={
                                        currentConfiguration.charge_type === "percentage" ? (
                                            <Icon type="percentage" />
                                        ) : null
                                    }
                                    value={currentValue}
                                    onChange={(e) =>
                                        this.handleOnChange(
                                            param.code,
                                            e.target.value
                                        )
                                    }
                                    className="textUpperCase"
                                    min={0}
                                    precision={decimalPoints}
                                    defaultValue={0}
                                    size={size}
                                />
                            ) : (
                                <Input
                                    value={currentValue}
                                    onChange={(e) =>
                                        this.handleOnChange(
                                            param.code,
                                            e.target.value
                                        )
                                    }
                                    size={size}
                                />
                            )}
                        </Col>
                    </Row>
                );
            case "number":
                return (
                    <Row>
                        <Col>
                            <Input
                                type="number"
                                size={size}
                                value={currentValue}
                                // value={currentValue >= 0 ? currentValue : 0}
                                onChange={(e) =>
                                    this.handleOnChange(
                                        param.code,
                                        e.target.value
                                    )
                                }
                            />
                        </Col>
                    </Row>
                );
            case "price":
                return (
                    <Row>
                        <Col>
                            <Input
                                type="number"
                                size={size}
                                value={currentValue}
                                onChange={(e) =>
                                    this.handleOnChange(
                                        param.code,
                                        e.target.value
                                    )
                                }
                                min={0}
                                precision={decimalPoints}
                                prefix={<Icon type="dollar" />}
                            />
                        </Col>
                    </Row>
                );
            case "Los":
                return (
                    <Row>
                        <Col>
                          <LosContracts
                            los_code={currentConfiguration.los_code}
                            account_id={this.state.account.id}
                            handleChange={(isManual, val) => this.handleContractChange(param.code,isManual, val)}
                            contractId={currentValue}
                            size="small"
                            style={{ width: 225 }}
                            />
                            </Col>
                        </Row>
                );

            case "Checkbox":
                return (
                    <Row>
                        <Col>
                            <Checkbox
                                checked={currentValue}
                                onChange={(e) =>
                                    this.handleOnChange(
                                        param.code,
                                        e.target.checked
                                    )
                                }
                                style={{fontWeight:"bold"}}
                            >
                                {param.name}
                            </Checkbox>
                        </Col>
                    </Row>
                );
            case "Radio":
                return (
                    <Row>
                        <Col>
                            <Row>
                                <Radio.Group
                                    value={currentValue}
                                    onChange={(e) =>
                                        this.handleOnChange(
                                            param.code,
                                            e.target.value
                                        )
                                    }
                                    size={size}
                                >
                                    {
                                        param.options.map(option => (option.value !== 'crossed_miles'?
                                            <Radio value={option.value}>{option.label}</Radio> :
                                            <Radio value={option.value}>
                                            Beyond 
                                            <Input 
                                                value={currentConfiguration.beyond_miles}
                                                onChange={(e) =>
                                                    this.handleOnChange(
                                                    "beyond_miles",
                                                    e.target.value
                                                    )
                                                }
                                                className="marginLeft5 marginRight5"
                                                style={{width: "25%"}}
                                                type="number"
                                                size={size}
                                                min={0}
                                                precision={2}
                                                defaultValue={this.state.lastZone.max_distance}
                                                disabled={currentConfiguration.charge_for !== "crossed_miles"}
                                            />{I18n.t('general.miles')}</Radio>
                                        ))
                                    }
                                </Radio.Group>
                            </Row>
                        </Col>
                    </Row>
                );
            case "wt_range":
                return (
                    <Fragment>
                        {
                            (currentConfiguration[param.code] && currentConfiguration[param.code].rateCard) ?
                                <RateMatrix
                                    key="rateCard"
                                    weights={currentConfiguration[param.code] && currentConfiguration[param.code].weightsData ? [...currentConfiguration[param.code].weightsData] : []}
                                    zones={currentConfiguration[param.code] && currentConfiguration[param.code].rateCard ? [...currentConfiguration[param.code].rateCard] : []}
                                    size="small"
                                    pagination={{ position: "none" }}
                                    handleAddWeight={() =>
                                        this.handleAddWeight(param.code)
                                    }
                                    handleEditWeight={(record, weightRecord) =>
                                        this.handleEditWeight(
                                            param.code,
                                            record,
                                            weightRecord
                                        )
                                    }
                                    deleteClick={(weightRecord) =>
                                        this.handleDeleteWeight(
                                            component.code,
                                            param.code,
                                            weightRecord
                                        )
                                    }
                                    onChange={(record) => this.handleWeightRangeChange(param, record)}
                                    onMinChargeChange={(record) => this.handleMinChargeChange(param, record)}
                                    showActions={isShowActions}
                                />
                                :
                                <Fragment />
                        }
                    </Fragment>
                );
            case "Boolean":
                return (
                    <Row>
                        <Radio.Group
                            value={currentValue}
                            onChange={(e) =>
                                this.handleOnChange(
                                    param.code,
                                    e.target.value
                                )
                            }
                            size={size}
                        >
                            <Radio value>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Row>
                );
            case "los_code":
            case "zones":
            case "Select":
                return (
                    <Row>
                        <Col xs={24}>
                            <Select
                                showSearch
                                value={currentValue}
                                style={{ width: "100%" }}
                                placeholder="Select"
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(e) =>
                                    this.askConfirmMessage(param.code, e)
                                }
                            >
                                <Select.Option
                                    key={`${param.code}select`}
                                    value=""
                                >
                                    --- Select ---
                                </Select.Option>
                                {param.data_type === 'los_code'
                                    ?
                                    this.state.levelOfServices.map((los, qindex) => (
                                        <Select.Option
                                            key={los.code}
                                            value={los.code}
                                        >
                                            {los.name}
                                        </Select.Option>
                                    ))

                                    :
                                    (
                                        param.data_type === 'zones' ?
                                            this.state.zones.map((zone, qindex) => (
                                                <Select.Option
                                                    key={zone.id}
                                                    value={zone.id}
                                                >
                                                    {zone.name}
                                                </Select.Option>
                                            ))
                                            :
                                            param.options.map((qoption, qindex) => (
                                                <Select.Option
                                                    key={qoption.option_key}
                                                    value={qoption.option_key}
                                                >
                                                    {qoption.option_value}
                                                </Select.Option>
                                            ))
                                    )

                                }
                            </Select>
                        </Col>
                    </Row>
                );
            default:
                break;
        }
    };

    checkDependantValues = (param) => {
        const { currentConfiguration } = this.state;
        if (!isEmpty(param.dependency_value)) {
            if (!isEmpty(param.dependency_key) && currentConfiguration[param.dependency_key] === param.dependency_value) {
                return this.renderParams(param);
            }
        } else {
            return this.renderParams(param);
        }
    }

    renderParams = (param) => {
        const { currentConfiguration, currentCategory } = this.state;
        return (
          <Fragment>
            {param.code !== "rate_matrix_from" &&
              (param.code !== "los" ||
                (param.code === "los" &&
                  currentConfiguration.rate_matrix_from === "Clone") ||
                (param.code === "los" && currentCategory.code === "EXML")) && (
                <Col
                  md={param.data_type === "wt_range" ? 24 : 8}
                  className={
                    param.data_type === "wt_range" ? "marginTop10" : ""
                  }
                >
                  {/* <FormItem label={param.name} require={param.is_required}>
                        {this.renderField(
                            param,
                            currentConfiguration
                        )}
                    </FormItem> */}

                  {param.data_type === "Checkbox" ? (
                    this.renderField(param, currentConfiguration)
                  ) : (
                    <FormItem label={param.name} require={param.is_required}>
                      {this.renderField(param, currentConfiguration)}
                    </FormItem>
                  )}
                </Col>
              )}
          </Fragment>
        );
    };

    render() {
        const { currentAdjustment, currentCategory } = this.state;
        const categoryParams = currentCategory.category_params ? currentCategory.category_params : [];
        const gridSize = 8;
        return (
            <div>
                <Row>
                    <Col md={gridSize}>
                        <div className="info-text">
                            <h4>{I18n.t("general.name")}:</h4>
                            {currentAdjustment.name}
                        </div>
                    </Col>
                    <Col md={gridSize}>
                        <div className="info-text">
                            <h4>{I18n.t("general.code")}:</h4>
                            {currentAdjustment.code}
                        </div>
                    </Col>
                    <Col md={gridSize}>
                        <div className="info-text">
                            <h4>{I18n.t('adjustments.category')}:</h4>
                            {currentAdjustment.category_code}
                        </div>
                    </Col>
                </Row>
                <hr />

                <Row gutter={16} className="marginTop10">
                    <Col xs={24} md={24}>
                        <Row gutter={8}>
                            {
                                categoryParams.map((param) => (
                                    this.checkDependantValues(param)
                                ))
                            }
                        </Row>
                        <Row>{FormErrors(this.state.errors)}</Row>
                        {checkServiceExistance('CADP') && 
                            <Row>
                                <Col xs={24}>
                                    {FormButtons(
                                        this.state.inProgress,
                                        this.handleSave,
                                        this.props.onCancel
                                    )}
                                </Col>
                            </Row>
                        }

                    </Col>
                </Row>
                {this.state.showWeightForm && this.renderWeightModal()}
            </div>
        );
    }
}

Configuration.propTypes = {
    account: PropTypes.shape.isRequired,
    currentAdjustment: PropTypes.shape.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default Configuration;
