import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Row,Icon, Form,Modal } from "../../common/UIComponents";
import { withRouter } from "react-router";
import DateTimeSelector from "../../common/DateTimeSelector";
import { fetchBillingDateManual } from "../../api/Billing";
import { alertMessage } from "../../common/Common";
import moment from "moment";
import { momentTime } from "../../helpers/date_functions";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";

const EditBillingDate = ({ date, orderId,onBillingDateUpdated,order_number,time_zone_id,onFinish }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [currentBillingDate, setCurrentBillingDate] = useState(null);


  const handleOkDate = async () => {
    const billingTime= moment.tz(currentBillingDate,time_zone_id)
    const payload = {
      customer_order_id : orderId,
    billing_date : `${moment(billingTime).format("YYYY-MM-DD")} ${moment(billingTime).format("HH:mm")}`,
    };
    fetchBillingDateManual(payload).then((result) => {
      if (result.success) {
        alertMessage("Billing Date Updated Successfully")
        setShowInfoWindow(false);
        onFinish(order_number,false);
      }
      if (result.errors) {
        renderAlertMessage(result.errors)
      }
    });
  };

  const handleCancel = () => {
    setCurrentBillingDate(null);
    setShowInfoWindow(false);
  };

  const setInitialData =() => {
    const dateTimeString = currentBillingDate? moment(currentBillingDate) : moment(date);
    setCurrentBillingDate(dateTimeString)
    setShowInfoWindow(true)
  }

  const renderModalWindow = () => {
    const isMilitaryTime = ["is_military_time"] == "true";
    return (
      <Modal
        title={`Edit Completion Date ${order_number ? ` of #${order_number}`: ''}`}
        centered
        visible={showInfoWindow}
        onOk={handleOkDate}
        onCancel={handleCancel}
        bodyStyle={{ padding: 10 }}
      >
        <Form onFinish={handleOkDate}>
          <Row>
            <DateTimeSelector
              dateProps={{
                format: "Do MMM YYYY",
                label: "Completion Date",
                value : currentBillingDate? moment(currentBillingDate) : moment(date),
                onChange: (date) => {
                  setCurrentBillingDate(date)
                },
                style: { width: "100%" },
                disabledDate: (current) => {
                  return (
                    current && current.valueOf() > Date.now() // disabled the future dates
                  );
                },
                allowClear: false,
              }}
              timeProps={{
                format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                label: "Completion Time",
                showTime: {
                  format: isMilitaryTime ? "HH:mm" : "hh:mm A",
                  use12Hours: !isMilitaryTime,
                },
                showSecond: false,
                onChange: (time) => {
                    setCurrentBillingDate(time)
                },

                style: { width: "100%", height: "100%", marginTop: "5px" },
                value : currentBillingDate ? moment(currentBillingDate, isMilitaryTime) : null,
                placeholder: "Select Time",
                minuteStep: 1,
                className: "formMaterialTimePicker",
                isMilitaryTime,
                allowClear: false,
              }}
              gridStyle={{
                row: { type: "flex", gutter: 4 },
                dateCol: { span: 12 },
                timeCol: { span: 12 },
              }}
            />
          </Row>
        </Form>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Tooltip title={I18n.t("general.edit")}>
        <Icon
          type="edit"
          style={{ color: "blue" }}
          onClick={() => setInitialData()}
        />
      </Tooltip>

      {showInfoWindow && renderModalWindow()}
    </Fragment>
  );
};

EditBillingDate.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(EditBillingDate);
