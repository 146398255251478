/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import _, { filter } from "lodash";
import moment from "moment";
import {
  Col,
  Row,
  Spin,
  Select,
  Icon,
  Statistic,
  Drawer,
  Search,
  Dropdown,
  Button,
  Popover,
  FormItem,
  message,
  Tabs,
  Alert
} from "../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  formatDate,
  base64ToPdf,
  navigateToAccount,
} from "../common/Common";
import I18n from "../common/I18n";
import BaseModal from "../components/BaseModal";
// import userStore from '../stores/UserStore';
import InvoicesList from "../components/billing/InvoicesList";
import FormInvoice from "../components/billing/InvoiceForm";
import Invoice from "../components/billing/Invoice";
import GroupInvoiceDetails from "../components/billing/GroupInvoiceDetails";
import {
  fetchBillingDetails,
  fetchBillingStats,
  fetchInvoices,
  fetchGroupBillingDetails,
  fetchInvoicesWithFilter,
  fetchManualBillingDetails,
  fetchOrderInvoicePdf,
  changeStatusOfInvoices,
  regenerateInvoiceWithNewLos,
  fetchBillingOrdersByAcc,
  getOrderBillingDetails,
  getMonthlyCutoffDetails,
} from "../api/Billing";
import { fetchLocations } from "../api/LocationsApi";
import { fetchUsers } from "../api/UsersApi";
import AppConfig from "../config/AppConfig";
import { fetchOrderDetails } from "../api/OrdersApi";
import { fetchShortFormAccounts } from "../api/Account";
import Filter from "../components/billing/Filter";
import DriverPayDetails from "../components/billing/DriverPayDetails";
import InvoiceCreateForm from "../components/billing/InvoiceCreateForm";
import userStore from "../stores/UserStore";
import {
  checkServiceExistance,
  convertToTags,
  limitString,
  removeSpecialCharacters,
  renderAlertMessage,
  selectedWarehouseNames,
} from "../helpers/common";
import Text from "antd/lib/typography/Text";
import OrderTitle from "../components/orders/OrderTitle";
import EditOrderForm from "../components/orders/EditOrderForm";
import BulkOperations from "../components/billing/BulkOperations";
import AppliedFiltersTags from "./AppliedFiltersTags";
// import { OrganizationSettingsContext } from "../context/OrganizationSettings";
import { withRouter } from "react-router";
import ManualInvoiceForm from "../components/billing/ManualInvoiceForm";
import { getFMPOrderFromInvoice } from "../helpers/billing";
import BillingDriverPay from "./BillingDriverPay";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import BillingAccountOrders from "../components/billing/BillingAccountOrders";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";
import { BillingScreen as BillingScreenBeta } from "./NewBilling";
import { SearchConfigContext } from "../context/SearchConfigContext";
import { DriverNotesBetaBilling } from "./DriverNotesBetaBilling";
import { DatePicker, Modal, Skeleton,Form } from "antd";
import { billinginvoices } from "./tmpresult";
import { VehicleTypeApi } from "../api/VehicleType";

const { TabPane } = Tabs;
class Invoices extends Component {
  // static contextType = OrganizationSettingsContext;
  constructor(props) {
    super(props);
    const { getSelectesWHs } = props.warehouseFilter;
    const warehouse_id = getSelectesWHs()
    this.state = {
      // pagination: {},
      inProgress: false,
      listProgress: false,
      showForm: false,
      showDriverPayForm: false,
      showCreateInvoiceForm: false,
      showInvoiceBasicForm: false,
      selectedOrderId: "",
      currentInvoice: {},
      filter: I18n.t("invoices.ready_for_billing"),
      invoices: [],
      data: {},
      showInvoice: false,
      showGroupOrder: false,
      currentOrder: {},
      filterWindow: false,
      accounts: [],
      editForm: false,
      visibleAccountPopover: false,
      warehouses: [],
      drivers: [],
      vehicleTypes: [],
      createInvoiceForm: {
        account_id: "",
        invoice_number: "",
        warehous_id: "",
        driver_id: "",
      },
      regenerateProgress: false,
      filterPlaceHolder: {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
        approvalDate:moment(),
        los: "",
        filter: "",
        search_order_token: "",
        accountCode: "",
        accountCodes: [],
        warehouse_id,
        driver_id: "",
        search_key_type: "",
        search_key_value: "",
        [I18n.t("order.filters.schedule_orders.type")]: I18n.t(
          "order.filters.schedule_orders.both_key"
        ),
        sortBy: "datetime",
        sortOrder: "descend",
        searchKeyPressed: false,
        invoiceAmountFilter: "BOTH",
        viewType: "byOrder",
        driver_id: null,
        vehicle_types: []
      },
      pagination: {},
      innerTablePagination: {},
      innerTableOptions: { pagination: {}, filters: {}, sorter: {} },
      selectedInfo: {
        selectedKeys: [],
        selectedRows: [],
      },
      appliedFiltersTags : [],
      selectedOption : "driver_id",
      updateSearchTags : false,
      activeKey: "details",
      organizationSettings: {},
      displayConfiguration: {},
      recordsPerPage: props.recordsPerPage,
      activeAccounts: [], // to hold current expanded rows
      isCurrentInvoiceLocked: false,
      currentBillingOrder: null,
      showNewManageScreen: false,
      currentBillingOrderData : {} ,
      // selectedInvoice : {}, 
      modalShow: false,
      currentRecord: {},
      activeRecordId: null,
      isLosChanged: false,
      showMonthlyCutoffModal: false,
      cutoffProgress: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
    this.onInnerTableChange = this.onInnerTableChange.bind(this);
    this.getDebouncedInvoices = _.debounce(this.getBillingOrders, 1000).bind(this);
    this.onTagClose = this.onTagClose.bind(this);
    this.getBillingOrders = _.debounce(this.getBillingOrders.bind(this), 500);
  }

  // Updates the warehouse filter.
  // Called when the user selects a warehouse from the warehouse filter dropdown.

  updateWhFilter = () => {
    const { warehouseFilter } = this.props;
    // const filterVals = this.state.filterPlaceHolder;
    // this.handleFilterPlaceHolderChange({
    //   ...filterVals,
    //   'warehouse_id': warehouseFilter.selectedWarehouse
    // });
    if(!_.isEqual(
      warehouseFilter.warehouses,
      this.state.warehouses
    )){
      this.setState({
        warehouses: warehouseFilter.warehouses || []
      }, () => {
        this.getOrgAccounts();
      })
    }
    this.tableOptions.pagination.current = 1;
    this.getDebouncedInvoices();
    this.getDrivers();
    this.getVehicleTypes();
  };


  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
        activeAccounts: [],
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getBillingOrders();
        updateRecordsPerPage(ScreenKeys.INVOICES_LISTING, value);
      }
    );
  };

  onSearch = (value) => {
  };

  componentDidMount() {
    const fromOtherScreen = this.props?.history?.location?.calledFrom;
    const { screenData } = this.props.searchConfigContext;
    // this.getBillingOrders();
    // this.getOrgAccounts();
    // this.getWarehouses();
    // this.getDrivers();
    this.updateWhFilter();

    const { warehouseFilter } = this.props;
    // if (!warehouseFilter.isWarehouseMultiSelect) {
    //   this.updateWhFilter();
    //   this.setState({
    //     warehouses : warehouseFilter.warehouses
    //   })
    // }
    this.computeTags(
      !isEmpty(fromOtherScreen)
        ? screenData.BILLING.filteredData
        : this.state.filterPlaceHolder
    );
    this.setState({
      displayConfiguration: this.props.displayConfiguration,
      organizationSettings: this.props.organizationSettings,
    },()=>{
      this.updateStateFromContext();
    });
  }

  updateStateFromContext = () =>{
     const { screenData } = this.props.searchConfigContext;
     this.setState({
       filterPlaceHolder: screenData.BILLING.filteredData,
       filter: screenData.BILLING.filter,
     }, () =>{
      const currentPage =
        screenData.BILLING.tableOptions.pagination && screenData.BILLING.tableOptions.pagination.current
          ? screenData.BILLING.tableOptions.pagination.current
          : 1;
      this.tableOptions.pagination.current = currentPage;
     });
  }

  handleSelectedOptionChange = (newSelectedOption, filter) => {
    this.setState(prevState => {
      const updatedFilterPlaceHolder = {
        ...prevState.filterPlaceHolder,
        warehouse_id: filter.warehouse_id,
        driver_id: newSelectedOption === 'driver_id' ? prevState.filterPlaceHolder.driver_id : null,
        vehicle_types: newSelectedOption === 'vehicle_types' ? prevState.filterPlaceHolder.vehicle_types : []
      };
      return {
        selectedOption: newSelectedOption,
        filterPlaceHolder: updatedFilterPlaceHolder
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { warehouseFilter } = this.props;
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, warehouseFilter.warehouseWithAccounts)) {
      this.getOrgAccounts(warehouseFilter.selectedWarehouses);
    }
    if (
      !_.isEqual(
        prevProps.warehouseFilter.selectedWarehouses,
        this.props.warehouseFilter.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseFilter.warehouses,
        this.props.warehouseFilter.warehouses
      ) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.setState({
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          warehouse_id: warehouseFilter.selectedWarehouses,
        }
      }, () => {
        // Now calling updateWhFilter after state is guaranteed to be updated
        this.updateWhFilter();
        this.computeTags(this.state.filterPlaceHolder);
        this.getOrgAccounts(warehouseFilter.selectedWarehouses);
      });
    }
    if (
      !_.isEqual(
        prevState.displayConfiguration,
        this.props.displayConfiguration
      )
    )
      if (
        !_.isEqual(
          prevState.organizationSettings,
          this.context.organizationSettings
        )
      ) {
        this.setState(
          {
            displayConfiguration: this.props.displayConfiguration,
            organizationSettings: this.props.organizationSettings,
          },
          () => {
            // Update OrdersList props with updated state values
            this.updateOrdersListProps();
          }
        );
      }
  }

  updateOrdersListProps = () => {
    this.setState({
      ordersListProps: {
        displayConfiguration: this.state.displayConfiguration,
      },
    });
  };

  onTagClose = (tagsObj) => {
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          ...tagsObj,
        },
      },
      () => {
        this.updateContext();
        this.computeTags(this.state.filterPlaceHolder);
        this.getBillingOrders();
      }
    );
  };

   updateContext = () => {
    const { updateFilteredData } = this.props.searchConfigContext;

    const {
        pagination,
        filterPlaceHolder,
        filter,
        activeAccounts,
        innerTablePagination,
        innerTableOptions
    } = this.state;

    const dataToUpdate = {
        paginationData: pagination,
        filteredData: filterPlaceHolder,
        filter: filter,
        activeAccounts: activeAccounts,
        innerTablePaginationData: innerTablePagination,
        innerTableOptions: innerTableOptions,
        tableOptions: this.tableOptions
    };

    updateFilteredData("BILLING", dataToUpdate);
}

  handleAccountNavigation = (account_code) =>{
    this.updateContext()
    navigateToAccount(
      this.state.accounts,
      account_code,
      this.props.history,
      "billing"
    );
  }

  // computeTags = (filterPlaceHolder) => {
  //   const keys = [
  //     "fromDate",
  //     "toDate",
  //     "accountCodes",
  //     "warehouse_id",
  //     "driver_id",
  //     "invoiceAmountFilter",
  //     "viewType"
  //   ];
  //   const filteredData = {
  //     fromDate: {
  //       label: "Billing Date",
  //       value:
  //         moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
  //         " - " +
  //         moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
  //       onClose: () => {
  //         this.onTagClose({
  //           fromDate: moment().startOf("month"),
  //           toDate: moment(),
  //         });
  //       },
  //       closeable: true,
  //     },
  //     toDate: {
  //       label: "Billing Date",
  //       value:
  //         moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
  //         " - " +
  //         moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
  //       onClose: () => {
  //         this.onTagClose({
  //           fromDate: moment().startOf("month"),
  //           toDate: moment(),
  //         });
  //       },
  //       closeable: true,
  //     },
  //     accountCodes: {
  //       label: "Accounts",
  //       value: limitString(
  //         this.state.accounts
  //           .map((account) => {
  //             if (filterPlaceHolder.accountCodes.includes(account.id)) {
  //               return account.code;
  //             }
  //           })
  //           .filter((item) => !isEmpty(item))
  //           .join(", "),
  //         5
  //       ),
  //       onClose: () => {
  //         this.onTagClose({ accountCodes: [] });
  //       },
  //       closeable: true,
  //     },
  //     warehouse_id: {
  //       label: "Warehouse",
  //       value:
  //         selectedWarehouseNames(this.props.appContexts.warehouseContext),
  //       onClose: () => {},
  //       //  this.props.warehouseFilter.onWarehouseChange(""); 
  //       // value: selectedWarehouseNames(this.props.warehouseFilter),
  //       // onClose: () => {
  //       //   //  this.props.warehouseFilter.onWarehouseChange("");
  //       // },
  //       closeable: false,
  //     },
  //     driver_id: {
  //       label: "Driver",
  //       value:
  //         this.state.drivers.find(
  //           (driver) => driver.id === filterPlaceHolder.driver_id
  //         )?.employee_code || "",
  //       onClose: () => {
  //         this.onTagClose({ driver_id: "" });
  //       },
  //       closeable: true,
  //     },
  //     invoiceAmountFilter: {
  //       label: "Include Orders",
  //       value:
  //         filterPlaceHolder.invoiceAmountFilter === "BOTH"
  //           ? "All Orders"
  //           : filterPlaceHolder.invoiceAmountFilter === "WITH_AMOUNT"
  //           ? "With Amount"
  //           : "Zero Amount",
  //       onClose: () => {
  //         this.onTagClose({ invoiceAmountFilter: "BOTH" });
  //       },
  //       closeable: true,
  //     },
  //     viewType: {
  //       label: I18n.t('general.view_by'),
  //       value:  filterPlaceHolder.viewType === 'byAccount' ? 
  //       `${I18n.t('general.group_by')} ${I18n.t('account.account')}` : 
  //       I18n.t('order.label'),
  //       onClose: () => {
  //         this.onTagClose({ viewType: "byOrder" });
  //       },
  //       closeable: true,
  //     },
  //   };
  //   const tagsInfo = convertToTags(
  //     { ...filterPlaceHolder, warehouse_id: filteredData.warehouse_id },
  //     keys,
  //     filteredData
  //   );
  //   this.setState({ appliedFiltersTags: tagsInfo });
  // };

  onTagClose = (tagsObj) => {
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          ...tagsObj,
        },
      },
      () => {
        this.updateContext()
        this.computeTags(this.state.filterPlaceHolder);
        this.getBillingOrders();
      }
    );
  };

  computeTags = (filterPlaceHolder) => {
    const { getSelectesWHs, warehouses = []} = this.props.warehouseFilter;
    const warehouseIds = filterPlaceHolder?.warehouse_id || getSelectesWHs() || "";
    const tagsInfo = convertToTags(
      filterPlaceHolder,
      [
        "fromDate",
        "toDate",
        "accountCodes",
        "warehouse_id",
        "driver_id",
        "invoiceAmountFilter",
        "viewType",
        "vehicle_types"
      ],
      {
        fromDate: {
          label: "Completion Date",
          value:
            moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
            " - " +
            moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
          onClose: () => {
            this.onTagClose({
              fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
              toDate: moment(),
            });
          },
          closeable:
            _.isEqual(
              filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
              moment()
                .subtract(AppConfig.invoices_filter_duration, "day")
                .format("MMM DD, YYYY")
            ) &&
            _.isEqual(
              filterPlaceHolder.toDate.format("MMM DD, YYYY"),
              moment().format("MMM DD, YYYY")
            )
              ? false
              : true,
        },
        toDate: {
          label: "Completion Date",
          value:
            moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
            " - " +
            moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
          onClose: () => {
            this.onTagClose({
              fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
              toDate: moment(),
            });
          },
          closeable:
            _.isEqual(
              filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
              moment()
                .subtract(AppConfig.invoices_filter_duration, "day")
                .format("MMM DD, YYYY")
            ) &&
            _.isEqual(
              filterPlaceHolder.toDate.format("MMM DD, YYYY"),
              moment().format("MMM DD, YYYY")
            )
              ? false
              : true,
        },
        accountCodes: {
          label: "Accounts",
          value: limitString(
            this.state.accounts
              .map((account) => {
                if (filterPlaceHolder.accountCodes.includes(account.id)) {
                  return account.code;
                }
              })
              .filter((item) => !isEmpty(item))
              .join(", "),
            5
          ),
          onClose: () => {
            this.onTagClose({ accountCodes: [] });
          },
          closeable: true,
        },
        warehouse_id: {
          label: "Warehouse",
          value: warehouses.filter(
              (warehouse) =>  warehouseIds?.includes(warehouse.id)
            ).map(wh => wh.name).join(',') || "",
          onClose: () => {
            this.onTagClose({ warehouse_id: getSelectesWHs() });
          },
          closeable: filterPlaceHolder.warehouse_id?.length === 0 || !_.isEqual(filterPlaceHolder.warehouse_id, getSelectesWHs()),
        },
        driver_id: {
          label: "Driver",
          value:
            this.state.drivers.find(
              (driver) => driver.id === filterPlaceHolder.driver_id
            )?.employee_code || "",
          onClose: () => {
            this.onTagClose({ driver_id: "" });
          },
          closeable: true,
        },
        vehicle_types: {
          label: "Vehicle Types",
          value: limitString(
            this.state.vehicleTypes
              .map((vehicle) => {
                if (filterPlaceHolder?.vehicle_types?.includes(vehicle.code)) {
                  return vehicle.name;
                }
              })
              .filter((item) => !isEmpty(item))
              .join(", "),
            1
          ),
          onClose: () => {
            this.onTagClose({ vehicle_types: [] });
          },
          closeable: true,
        },
        invoiceAmountFilter: {
          label: "Include Orders",
          value:
            filterPlaceHolder.invoiceAmountFilter === "BOTH"
              ? "All Orders"
              : filterPlaceHolder.invoiceAmountFilter === "WITH_AMOUNT"
              ? "With Amount"
              : "Zero Amount",
          onClose: () => {
            this.onTagClose({ invoiceAmountFilter: "BOTH" });
          },
          closeable: !["BOTH"].includes(filterPlaceHolder.invoiceAmountFilter),
        },
        viewType: {
                label: I18n.t('general.view_by'),
                value:  filterPlaceHolder.viewType === 'byAccount' ? 
                `${I18n.t('general.group_by')} ${I18n.t('account.account')}` : 
                I18n.t('order.label'),
                onClose: () => {
                  this.onTagClose({ viewType: "byOrder" });
                },
                closeable: filterPlaceHolder.viewType === 'byAccount',
        },
      }
    );
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getBillingOrders();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "datetime",
            sortOrder: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.getBillingOrders();
        }
      );
    }
    this.setState({ activeAccounts: [] },()=>{
      this.updateContext();
    });
  };

  onInnerTableChange = (pagination, filters, sorter, id) => {
     const currentPage =
      this.state.innerTableOptions.pagination && this.state.innerTableOptions.pagination.current
        ? this.state.innerTableOptions.pagination.current
        : 1;
    const innerTableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getAccountSummary(true, id, innerTableOptions);
    } else if (sorter && !isEmpty(sorter.field)) {
      this.state.innerTableOptions.pagination = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "datetime",
            sortOrder: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.getAccountSummary(true, id, innerTableOptions);
        }
      );
    }
    this.updateContext()
  }

  // onInnerTableChange = (pagination, filters, sorter, id) => {
  //   const innerTableOptions = { pagination, filters, sorter };
  //   console.log("onInnerTableChange");
  //   this.getAccountSummary(true, id, innerTableOptions);
  // };

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
          vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getDrivers = () => {
    this.setState({ inProgress: true });
    fetchUsers("driver", 1, null, true).then((result) => {
      if (result.success) {
        this.setState({
          drivers: result.users,
          inProgress: false,
        });
      } else if (result.errors) {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getOrgAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState({
      accounts: accountsData,
    })
  }

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations,
          inProgress: false,
        });
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  resetSearchTags = () => {
    this.setState({ updateSearchTags: false });
  };

  orderNumberSearch = (value) => {
    const { filterPlaceHolder } = this.state;
    const { getSelectesWHs } = this.props.warehouseFilter;

    if (value.length > 0) {
      this.setState(
        {
          updateSearchTags: true,
          filterPlaceHolder: {
            filter: !isEmpty(value) ? "" : I18n.t("order.default_status"),
            fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
            toDate: moment(),
            search_order_token: value,
            sortBy: "datetime",
            sortOrder: "descend",
            accountCode: "",
            accountCodes: [],
            los: "",
            warehouse_id: getSelectesWHs(),
            driver_id: "",
            search_key_type: "",
            search_key_value: "",
            searchKeyPressed: !isEmpty(value) ? true : false,
            viewType: filterPlaceHolder.viewType,
            vehicle_types: []
          },
        },
        () => {
          this.updateContext()
          this.computeTags(this.state.filterPlaceHolder);
          this.tableOptions.pagination.current = 1;
          this.getBillingOrders();
        }
      );
    } else {
      this.clearFilter();
    }
  };

  handleFilterValueChange = (element, value, isSearch = false) => {
    this.setState(
      {
        filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
          [element]: value,
        }),
      },
      () => {
        if (isSearch) {
          this.orderNumberSearch(value);
        }
      }
    );
  };

  getInvoiceStatus = () => {
    fetchBillingStats().then((result) => {
      if (result.success) {
        this.setState({
          data: result.data[0],
        });
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  formFilterPayLoad = () => {
    const { filterPlaceHolder } = this.state;
    const order_number = !isEmpty(filterPlaceHolder.search_order_token)
      ? filterPlaceHolder.search_order_token.toUpperCase()
      : "";
    const formattedFromDate = formatDate(filterPlaceHolder.fromDate);
    const formattedToDate = formatDate(filterPlaceHolder.toDate);
    const searchKeyType = filterPlaceHolder.search_key_type;
    const searchKeyValue = filterPlaceHolder.search_key_value;
    const searchOrderToken = this.state.filterPlaceHolder.search_order_token;
    const isDriverOptionSelected = this.state.selectedOption === "driver_id";
    const data = {
      status: this.state.filter == 'ON_HOLD' && this.props.organizationSettings.show_hold_bucket == "true" ? 'HOLD' : this.state.filter,
      sort_by: filterPlaceHolder.sortBy,
      sort_order: I18n.t(`general.sort_${filterPlaceHolder.sortOrder}`),
      warehouse_id: filterPlaceHolder.warehouse_id,
      account_id: filterPlaceHolder.accountCodes,
      los_code: filterPlaceHolder.los,
      customer_order_number: order_number,
      driver_id: filterPlaceHolder.driver_id,
      invoice_amount_filter: filterPlaceHolder.invoiceAmountFilter,
    };
    if(searchOrderToken) {
      data.customer_order_number = searchOrderToken 
      ? _.trim(searchOrderToken) : "";
    } else {
      data.from_date =  formattedFromDate
      data.to_date =  formattedToDate
    }
    if (isDriverOptionSelected) {
      data.driver_id = filterPlaceHolder.driver_id;
  } else {
      data.vehicle_types = filterPlaceHolder.vehicle_types;
  }
    return { data, searchKeyType,searchKeyValue} ;
  }

  handleListResponse = (result) => {
    const fromOtherScreen = this.props?.history?.location?.calledFrom;
    const { screenData } = this.props.searchConfigContext;
    const { filterPlaceHolder } = this.state;
    const invoices = result.invoices || [];
    const newState = {
      invoices,
      data: result.stats,
      listProgress: false,
      pagination:
        invoices.length > 0 && result.pagination
          ? result.pagination
          : {},
      activeAccounts: screenData.BILLING?.activeAccounts?.length ? screenData.BILLING.activeAccounts : [] //(invoices?.length > 0 ? [invoices[0].account_id] : [])//!isEmpty(fromOtherScreen) ? screenData.BILLING.activeAccounts : [],
    }
    if(filterPlaceHolder.viewType !== 'byOrder'){
      newState.invoices = invoices.map(rec => ({...rec, orders: [], pagination: {}, 
        selectedInfo: {
          selectedKeys: [],
          selectedRows: []
        },
        tableOptions: { pagination: {}, filters: {}, sorter: {} },
      }))
    }
    this.setState({...newState},
      () => {
        // if (!isEmpty(fromOtherScreen) ){
          const { innerTableOptions, filteredData } = screenData.BILLING;
          if(this.state.activeAccounts?.length){
            this.getAccountSummary(true, this.state.activeAccounts[0], innerTableOptions);
          }
        // }
      }
    );
  }

  getFewBillingOrders = (ordersList) => {
    if(this.state.filterPlaceHolder.viewType === 'byAccount' && this.state.activeAccounts?.length && !refreshAccountWiseBilling){
      this.getAccountSummary(true, this.state.activeAccounts[0])
    }else {
      const { filterPlaceHolder } = this.state;
      const page = this.tableOptions.pagination.current || 1;
      const  perPage  =  this.state.recordsPerPage;
      this.setState({ listProgress: true });
      const { data, searchKeyType, searchKeyValue} = this.formFilterPayLoad()
      data.page = page;
      data.per_page= perPage;
      data.customer_order_number = ordersList.join(",");
      const func = filterPlaceHolder.viewType === 'byOrder' ? fetchInvoicesWithFilter : fetchBillingOrdersByAcc;
      func(data, searchKeyType, searchKeyValue).then(
        (result) => {
          if (result.success) {
            // update in current state list
            const updatedInvoices = _.map(this.state.invoices, invoice => {
              if (_.includes(ordersList, invoice.order_number)){
                const foundInvoice = _.find(result.invoices, { order_number: invoice.order_number });
                return foundInvoice ? foundInvoice : null;
              }
              return invoice;
            }).filter(invoice => !isEmpty(invoice));
            this.setState({ invoices: updatedInvoices, listProgress: false });
          } else if (result.errors) {
            renderAlertMessage(result.errors)
            this.setState({ listProgress: false, pagination: {}});
          }
        }
      );
    }
  };



  getBillingOrders = (refreshAccountWiseBilling = false ) => {
    if(this.state.filterPlaceHolder.viewType === 'byAccount' && this.state.activeAccounts?.length && !refreshAccountWiseBilling){
      this.getAccountSummary(true, this.state.activeAccounts[0])
    }else {
      const { filterPlaceHolder } = this.state;
      const page = this.tableOptions.pagination.current || 1;
      const  perPage  =  this.state.recordsPerPage;
      this.setState({ listProgress: true });
      const { data, searchKeyType, searchKeyValue} = this.formFilterPayLoad()
      data.page = page;
      data.per_page= perPage;
      const func = filterPlaceHolder.viewType === 'byOrder' ? fetchInvoicesWithFilter : fetchBillingOrdersByAcc;
      // this.handleListResponse(billinginvoices)
      func(data, searchKeyType, searchKeyValue).then(
        (result) => {
          if (result.success) {
            if (!result?.invoices?.length) {
              alertMessage(
                I18n.t("messages.no_found", {
                  field: I18n.t("general.records"),
                }),
                "error",
                10
              );
            }
            this.handleListResponse(result)
          } else if (result.errors) {
            renderAlertMessage(result.errors)
            this.setState({ listProgress: false, pagination: {}, activeAccounts: []});
          }
        }
      );
    }
  };

  handleCreateInvoice = () => {
    const showCreateInvoiceForm = this.state.showCreateInvoiceForm;
    this.setState({
      showCreateInvoiceForm: !showCreateInvoiceForm,
    });
    this.renderCreateInvoiceModal();
  };

  _getCreateSampleInvoice = (basicForm, account) => {
    const invoice_number = basicForm.invoice_number;
    const warehouse_details = {};
    const warehouse = this.state.warehouses.find(
      (warehouse) => warehouse.id === basicForm.warehouse_id
    );
    if (warehouse) {
      warehouse_details.name = warehouse.name;
      warehouse_details.email = warehouse.email;
      warehouse_details.contact = warehouse.phone;
      warehouse_details.address = warehouse.l_address;
    }
    const driver_details = {};
    const driver = this.state.drivers.find(
      (driver) => driver.id === basicForm.driver_id
    );
    if (driver) {
      driver_details.name = driver.full_name;
      driver_details.email = "";
      driver_details.code = driver.employee_code;
      driver_details.contact = driver.mobile_number;
      driver_details.address = driver.address;
    }
    return {
      organization_name: "",
      warehouse_details,
      customer_details: {},
      driver_details,
      account_details: {
        id: account.id,
        name: account.name,
        code: account.code,
        email: null,
        contact: null,
        address: null,
      },
      order_group_type: "MANUAL",
      order_number: invoice_number,
      transportation_charges: [
        {
          order_id: invoice_number,
          order_number: invoice_number,
          level_of_service: "",
          price_list: [],
        },
      ],
      driver_transportation_charges: [],
      accessorial_charges: [
        {
          order_id: invoice_number,
          order_number: invoice_number,
          level_of_service: "",
          price_list: [],
        },
      ],
      driver_accessorial_charges: [],
      adjustment_charges: [
        {
          order_id: invoice_number,
          order_number: invoice_number,
          level_of_service: "",
          price_list: [],
        },
      ],
      fuel_surcharges: [
        {
          order_id: invoice_number,
          order_number: invoice_number,
          level_of_service: "",
          price_list: [],
        },
      ],
      total_amount: 0,
      order_info: {},
      transportation_gl_code: "",
      customer_order_id: invoice_number,
      remarks: [],
      status: "READY_FOR_BILLING",
      error_log: [],
      error_messages: [],
      driver_id: basicForm.driver_id,
      warehouse_id: basicForm.warehouse_id,
    };
  };

  handleAccountSelect = (element, value) => {
    this.setState(
      {
        createInvoiceForm: Object.assign({}, this.state.createInvoiceForm, {
          account_id: "",
          invoice_number: "",
        }),
      },
      () => {
        let account = null;
        let invoice_number = "";
        if (value && value !== "" && value !== undefined) {
          account = this.state.accounts.find((account) => account.id === value);
          invoice_number = `${account.code}${moment().format(
            "YYYYMMDDHHmmss"
          )}`;
        }
        this.setState(
          {
            createInvoiceForm: Object.assign({}, this.state.createInvoiceForm, {
              [element]: value,
              invoice_number,
            }),
          },
          () => {
            if (account) {
              const currentInvoice = this._getCreateSampleInvoice(
                invoice_number,
                account
              );
              this.setState({
                visibleAccountPopover: false,
                currentInvoice,
                showForm: true,
                createInvoiceForm: Object.assign(
                  {},
                  this.state.createInvoiceForm,
                  {
                    account_id: "",
                    invoice_number: "",
                  }
                ),
              });
            }
          }
        );
      }
    );
    // }
  };

  _renderAccountsLit = () => (
    <Row>
      <Row>
        <FormItem label={I18n.t("general.account")}>
          <Select
            value={this.state.createInvoiceForm.account_id}
            showSearch
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => this.handleAccountSelect("account_id", e)}
          >
            <Select.Option key="all" value="">
              -- Select Account --
            </Select.Option>
            {this.state.accounts.map((account) => (
              <Select.Option key={account.id} value={account.id}>
                {account.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Row>
      <Row>
        <FormItem label={I18n.t("general.warehouse")}>
          {/* <Select
            value={this.state.createInvoiceForm.warehouse_id}
            showSearch
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => this.handleAccountSelect("warehouse_id", e)}
          >
            <Select.Option key="all" value="">
              -- Select Warehose --
            </Select.Option>
            {this.state.warehouses.map((warehouse) => (
              <Select.Option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select> */}
          <WarehouseFilter isDisabled={true} style={{ width: "100%" }} />
        </FormItem>
      </Row>
    </Row>
  );

  handlePopoverVisibleChange = (visibleAccountPopover) => {
    this.setState({ visibleAccountPopover });
  };

  handleBasicInvoiceForm = () => {
    this.setState({
      showInvoiceBasicForm: true,
    });
  };

  handleMonthlyCutoffSubmit = () => {
    this.setState({cutoffProgress : true})
    getMonthlyCutoffDetails(this.state.filterPlaceHolder,this.state.filter).then((result) => {
      if (result.success) {
        this.setState({cutoffProgress : false, showMonthlyCutoffModal: false})
        this.getBillingOrders()
      } else if (result.errors) {
        renderAlertMessage(result.errors)
        this.setState({cutoffProgress : false})
      }
    }
    );
  }

  renderMonthlyCutoffContent = () => {
    return (
        <Spin spinning={this.state.cutoffProgress}>
          <Form>
            <Form.Item
              label={I18n.t("MonthlyCutoff.cutoff_date")}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
            >
              <DatePicker
                onChange={(date, dateString) =>
                  this.setState({
                    filterPlaceHolder: {
                      ...this.state.filterPlaceHolder,
                      approvalDate: date,
                    },
                  })
                }
                disabledDate={(current) =>
                  (current &&
                    current < moment(this.state.filterPlaceHolder.toDate).startOf("day")) ||
                  current.isAfter(moment().endOf("day"))
                }
                value={this.state.filterPlaceHolder.approvalDate}
                format="MMM DD, YYYY"
              />{" "}
            </Form.Item>
            <Row className="marginTop10">
              <Col xs={24} className="alignCenter">
                <Button
                  className="marginRight5"
                  size="small"
                  onClick={() => this.setState({ showMonthlyCutoffModal: false })}
                  icon="close"
                >
                  {I18n.t("general.cancel")}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.handleMonthlyCutoffSubmit()}
                  className="primary-save"
                  loading={this.state.cutoffProgress}
                  icon="save"
                >
                  {I18n.t("general.save")}
                  </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
    );
  };
  

  openMonthlyCutoffModal = () => {
    return(
      <>
      <BaseModal
        visible={this.state.showMonthlyCutoffModal}
        title="Monthly Cutoff"
        footer={null}
        width={520}
        onCancel={() => this.setState({showMonthlyCutoffModal: false})}
      >
        {this.renderMonthlyCutoffContent()}
        </BaseModal>
      </>
    )
  }

  _renderHeader = () => {
    const { currentOrg, orgRules } = this.props.userContext;
    const lykesORG = currentOrg.code === "LYKES";
    const showMontlyCutOff = _.get(orgRules.org_billing_rules, 'show_monthly_cut_off');
    const createManualOrder = _.get(orgRules.org_billing_rules, 'create_manual_billing');

      return (
      <Row className="page-header">
        <Col xs={ 9 }>{ I18n.t("menu.billing") }</Col>
        <Col xs={ 6 } style={ { display: "flex", justifyContent: "flex-end" } } className="page-actions">
        <Row type="flex" gutter={8}>
          <Col>
            {/* { checkServiceExistance("BLCIN") && showMontlyCutOff && (
            <Button type="primary"  onClick={() => this.setState({showMonthlyCutoffModal: true})} icon="plus">
              Monthly Cutoff
            </Button>
          ) } */}
        </Col>
        <Col>
          { checkServiceExistance("BLCIN") && createManualOrder &&(
            <Button type="primary" onClick={ () => this.handleBasicInvoiceForm() } icon="plus">
              { I18n.t("general.create_billing") }
            </Button>
          ) }
          </Col>
        </Row>
        </Col>
        <Col xs={ 9 } className="alignRight headerOptionDiv">
          { checkServiceExistance("FBLOS") && this._renderSearchBar() }
        </Col>
        </Row>
      )
    }

  handleFilterPlaceHolderChange = (filter, cb) => {
    this.setState(
      {
        filterPlaceHolder: Object.assign({}, filter),
      },
      () => {
        cb && cb();
      }
    );
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  handleSearch = (updatedFilter, cb) => {
    const newState = {
      filterWindow: false,
      activeAccounts: [],
      filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
        ...updatedFilter,
        search_order_token: "",
        searchKeyPressed: false,
      }),
      invoices: [],
    }
    // if(updatedFilter?.viewType && this.state.filterPlaceHolder.viewType !== updatedFilter.viewType){
    //   newState.invoices = [];
    //   newState.activeAccounts = []
    // }
    this.setState(
      {...newState}
      ,
      () => {
        this.updateContext();
        this.tableOptions.pagination.current = 1;
        this.getBillingOrders();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  };

  handleRedirect = () => {
    this.props.history.push({
      pathname:"/consolidate"
    })
    }

  clearSearch = () => {
    this.handleFilterValueChange("search_order_token", "", true);
  };

  _renderSearchBar = () => (
    <Search
      // placeholder={`${I18n.t("search.order_number.multiple")}`}
      placeholder={I18n.t("order.filters.placeholder")}
      // enterButton="Search"
      size="default"
      value={
        !isEmpty(this.state.filterPlaceHolder.search_order_token)
          ? this.state.filterPlaceHolder.search_order_token.toUpperCase()
          : ""
      }
      onChange={ (e) => {
        const filteredString = removeSpecialCharacters(e.target.value);
        this.handleFilterValueChange("search_order_token", filteredString);
      }
      }
      onSearch={(value) => this.orderNumberSearch(value)}
      style={{ textTransform: "uppercase", paddingLeft: "5px" }}
      suffix={
        <Fragment>
          {this.state.filterPlaceHolder.search_order_token !== "" && (
            <Fragment>
              {" "}
              <Icon type="close" onClick={() => this.clearSearch()} />{" "}
              &nbsp;&nbsp;
            </Fragment>
          )}
          <Dropdown
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.filterWindow}
            trigger={["click"]}
            overlay={
              <div
                style={{
                  backgroundColor: "white",
                  width: 550,
                  padding: 15,
                  marginTop: 10,
                  marginRight: -12,
                }}
                className="boxShadow"
              >
                <Filter
                  showOrderNoSearch={false}
                  filter={this.state.filterPlaceHolder}
                  onChange={this.handleFilterPlaceHolderChange}
                  onSearch={this.handleSearch}
                  onOrderNoSearch={null}
                  warehouseFilter={this.props.warehouseFilter}
                  currentOrg={this.props.currentOrg}
                  onSelectedOptionChange={this.handleSelectedOptionChange}
                  getVehicleTypes={this.state.vehicleTypes}
                />
              </div>
            }
          >
            <Icon
              type="caret-down"
              onClick={() => this.handleOnChange("filterWindow", true)}
            />
          </Dropdown>
        </Fragment>
      }
      prefix={
        <Icon
          type="search"
          onClick={() =>
            this.orderNumberSearch(
              this.state.filterPlaceHolder.search_order_token
            )
          }
        />
      }
      className="textUpperCase"
    />
  );


  handleSelectChange = (selectedKeys, selectedRows) => {
    this.setState({
      selectedInfo: Object.assign({}, this.state.selectedInfo, {
        selectedKeys: selectedKeys,
        selectedRows: selectedRows,
      }),
    });
  };

  handleAppproveSuccess = (account_id) => {
    if (this.state.filterPlaceHolder.viewType === "byAccount" && this.state.filter === "READY_FOR_BILLING") {
      this.getBillingOrders(true);
    }else{
      this.getBillingOrders()
    }
    if(account_id){
      this.updateAccountOrders(account_id, [], []);
    } else {
      this.handleSelectChange([], []);
    }
  };

  getAccountSummary = (
    isExpanded,
    id,
    tableOptions = { pagination: {}, filters: {}, sorter: {} }
  ) => {
    if (isExpanded) {
      const account_invoices = [...this.state.invoices];
      const accObjectIndex = _.findIndex(account_invoices, ["account_id", id]);
      if (accObjectIndex >= 0) {
        account_invoices[accObjectIndex].inProgress = true;
        account_invoices[accObjectIndex].tableOptions = tableOptions;
        const record = account_invoices[accObjectIndex].pagination;
        const page = tableOptions.pagination?.current && !isEmpty(tableOptions.pagination.current) ? tableOptions.pagination.current : 1;
        const  perPage  =  this.state.recordsPerPage;
        this.setState({
          invoices: [...account_invoices],
          activeAccounts: [id]
        });
        const { data, searchKeyType, searchKeyValue} = this.formFilterPayLoad();
        data.page = page;
        data.per_page= perPage;
        let payload = { ...data,account_id: id };
        if (this.state.filterPlaceHolder.viewType === "byAccount") {
          payload.customer_order_number = _.trim(this.state.filterPlaceHolder.search_order_token);
        }
        fetchInvoicesWithFilter(payload, searchKeyType, searchKeyValue).then((result) => {
        if (result.success) {
          account_invoices[accObjectIndex].orders = result.invoices || [];
          account_invoices[accObjectIndex].inProgress = false;
          account_invoices[accObjectIndex].pagination = result.pagination || {};
          account_invoices[accObjectIndex].selectedInfo = {
            selectedKeys: [],
            selectedRows: []
          }
          this.setState({
            invoices: [...account_invoices],
          },()=>{
            this.setCurrentExpandedRowPagination(this.state.invoices);
          });
        } else if (result.errors) {
          renderAlertMessage(result.errors)
          account_invoices[accObjectIndex].orders = [];
          account_invoices[accObjectIndex].inProgress = false;
          account_invoices[accObjectIndex].pagination = {};
          account_invoices[accObjectIndex].selectedInfo = {
            selectedKeys: [],
            selectedRows: []
          }
          this.setState({
            invoices: [...account_invoices],
          });
        }
      }
    );
        
      }
    }else {
      this.setState({
        activeAccounts: this.state.activeAccounts.filter(acc_id => id !== acc_id)
      })
    }
  };

  setCurrentExpandedRowPagination = (invoices) =>{
    const currentAccountInv = invoices?.length > 0 ? invoices.find(rec => rec.account_id === this.state.activeAccounts[0]) : {}
    if(!_.isEmpty(currentAccountInv)){
      this.setState({
        innerTablePagination: !_.isEmpty(currentAccountInv.pagination) ? currentAccountInv.pagination : {},
        innerTableOptions: !_.isEmpty(currentAccountInv.tableOptions) ? currentAccountInv.tableOptions : { pagination: {}, filters: {}, sorter: {} }
      }, ()=>{
        this.updateContext()
      });
    }
  }

  onHandlePrint = (record, id) =>{
     this.setState({
      currentRecord: record,
     });
     this.handlePrint(id)
  }

  _renderOrders = () => {
    const { selectedKeys } = this.state.selectedInfo;
    const { filter } = this.state;
    const dataPagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    const rowSelection = {
      selectedRowKeys: selectedKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: (record) => {
        return ({
				disabled: record.is_locked == "true" || record.is_consolidated == "true", // Column configuration not to be checked
			})
    },
    };
    const hasStatusChangeAccess = checkServiceExistance("BLUS");
    const { organizationSettings } = this.state;
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    return (
      <Fragment>
        <InvoicesList
          warehouseFilter={this.props.warehouseFilter}
          currentStatus={this.state.filter}
          rowKey="customer_order_id"
          key="account_orders"
          scroll={{ x:"max-content", y: "calc(100vh - 365px)" }}
          size="small"
          screen_from="billing"
          organizationSettings={organizationSettings}
          displayConfiguration={displayConfiguration}
          ordersListProps={ordersListProps}
          data={this.state.invoices}
          rowSelection={
            hasStatusChangeAccess && filter !== "EXCEPTION"
              ? rowSelection
              : undefined
          }
          getInvoices={this.getBillingOrders}
          editClick={this.handleEditClick}
          handleEditOrder={this.editFormDetails}
          onPrintClick={(record, id)=> this.onHandlePrint(record,id)}
          handleDriverPayList={this.handleDriverPayList}
          editManualInvoice={this.editManualInvoice}
          getBillingOrders={() => this.getBillingOrders()}
          handleOrderDetailsViewClick={(order_id) =>
            this.handleOrderDetailsViewClick(order_id)
          }
          pagination={dataPagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            this.onTableChange(pagination, filter, sorter, currentTable)
          }
          filterPlaceHolder={this.state.filterPlaceHolder}
          navigateToAccount={(account_code) =>
            this.handleAccountNavigation(account_code)
          }
          // setCurrentSelectedInvoice={(record) => {
          //   this.setState({ selectedInvoice: record });
          // }}
          handleBetaManage={ (record) => {
            this.setState({
              currentBillingOrder: record,
              // showNewManageScreen: true
            }, () => {
              if (record.order_group_type === "MANUAL") {
                this.editManualInvoice(record.order_number);
              } else {
                this.handleEditClickBeta(record.customer_order_id);
              }
            });

          } }
          onFinish = {this.onInvoiceFormUnmount}
        />
        {hasStatusChangeAccess &&
          this.state.selectedInfo.selectedKeys.length > 0 && (
            <BulkOperations
              selectedInfo={this.state.selectedInfo}
              statusLoading={this.state.statusLoading}
              handleCancel={() => this.handleSelectChange([], [])}
              handleSuccess={this.handleAppproveSuccess}
              statusChange={() => {}}
              currentStatus={this.state.filter}
              handleRedirect={this.handleRedirect}
            organizationSettings={this.props.organizationSettings}
              userContext={this.props.userContext}
            />
          )}
      </Fragment>
    );
  };
  _renderAccOrders = () => {
    const { filter } = this.state;
    const hasStatusChangeAccess = checkServiceExistance("BLUS");
    const { organizationSettings } = this.state;
    const dataPagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    const {
      displayConfiguration,
      ordersListProps, // Get ordersListProps from props
    } = this.props;
    return (
      <Fragment>
        <BillingAccountOrders
          rowKey="account_id"
          scroll = {{x: 700,y:"calc(100vh - 350px)"}}
          size="small"
          screen_from="billing"
          organizationSettings={organizationSettings}
          displayConfiguration={displayConfiguration}
          ordersListProps={ordersListProps}
          data={this.state.invoices}
          expandedRowKeys={[...this.state.activeAccounts]}
          expandedRowRender={(record) => {
            const { selectedKeys } = record.selectedInfo;
            const dataPagination = {
              total: record.pagination.total_count,
              current: record.pagination.current_page,
              pageSize: this.state.recordsPerPage,
            };
            const rowSelection = {
              selectedRowKeys: selectedKeys,
              onChange: (selectedKeys, selectedRows) => {
                this.updateAccountOrders(record.account_id, selectedKeys,selectedRows )
              },
              getCheckboxProps: (record) => {
                return ({
                disabled: record.is_locked == "true", // Column configuration not to be checked
              })
            },
            };
            const hasStatusChangeAccess = checkServiceExistance("BLI");
            return (<Fragment>
              <Spin spinning={record.inProgress} delay={1000}>
                {checkServiceExistance("BLI") && (
                  <Fragment>
                  <h4>{I18n.t('menu.orders')}</h4>
                  <InvoicesList
                  currentStatus={this.state.filter}
                  warehouseFilter={this.props.warehouseFilter}
                  rowKey="customer_order_id"
                  key="account_orders"
                  scroll = {{ x:"max-content", y: "calc(100vh - 365px)"}}
                  pagination={dataPagination}
                  rowSelection={rowSelection}
                  tableChange={(tablePagination, filters, sorter) =>
                    this.onInnerTableChange(
                      tablePagination,
                      filters,
                      sorter,
                      record.account_id
                    )
                  }
                  size="small"
                  screen_from="billing"
                  organizationSettings={organizationSettings}
                  displayConfiguration={displayConfiguration}
                  ordersListProps={ordersListProps}
                  data={record.orders || []}
                  editClick={this.handleEditClick}
                  handleEditOrder={this.editFormDetails}
                  onPrintClick={(record,id)=>this.onHandlePrint(record,id)}
                  handleDriverPayList={this.handleDriverPayList}
                  editManualInvoice={this.editManualInvoice}
                  getBillingOrders={() => this.getBillingOrders()}
                  handleOrderDetailsViewClick={(order_id) =>
                    this.handleOrderDetailsViewClick(order_id)
                  }
                  filterPlaceHolder={this.state.filterPlaceHolder}
                  navigateToAccount={(account_code) =>
                    this.handleAccountNavigation(account_code)
                  }
                      handleBetaManage={ (record) => {
                        this.setState({
                          currentBillingOrder: record,
                          // showNewManageScreen: true
                        }, () => {
                          if (record.order_group_type === "MANUAL") {
                            this.editManualInvoice(record.order_number);
                          } else {
                            this.handleEditClickBeta(record.customer_order_id);
                          }
                        });

                      } }
                  getInvoices={this.getBillingOrders}
                  isFixed={false}
                  />
                  {hasStatusChangeAccess &&
                    record.selectedInfo.selectedKeys.length > 0 && (
                      <BulkOperations
                        selectedInfo={record.selectedInfo}
                        statusLoading={this.state.statusLoading}
                        handleCancel={() => this.updateAccountOrders(record.account_id,[], [])}
                        handleSuccess={() => this.handleAppproveSuccess(record.account_id)}
                        statusChange={() => {}}
                        currentStatus={this.state.filter}
                        organizationSettings={this.props.organizationSettings}
                      />
                  )}
                  </Fragment>
                )}
              </Spin>
            </Fragment>
          )
          }
          }
          onExpand={(expanded, record) =>
            checkServiceExistance("ACSOB")
              ? this.getAccountSummary(expanded, record.account_id)
              : null
          }
    
          pagination={dataPagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            this.onTableChange(pagination, filter, sorter, currentTable)
          }
          navigateToAccount={(account_code) =>
            this.handleAccountNavigation(account_code)
          }
        />
      </Fragment>
    );
  };

  _renderStatusFilter = () => (
    <div>
      <Row className="marginTop10">
        <Col xs={18} />
        <Col xs={6} className="alignRight">
          {I18n.t("general.status")}: &nbsp;&nbsp;
          <Select
            value={this.state.filter || ""}
            showSearch
            style={{ width: "80%" }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => this.handleOnChange("filter", e)}
          >
            <Select.Option key="select" value="">
              --- Select Status---
            </Select.Option>
            {Object.keys(I18n.t("invoices.status")).map((key) => (
              <Select.Option key={key} value={key}>
                {I18n.t(`invoices.status.${key}`)}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );

  editManualInvoice = (id) => {
    const index = _.findIndex(this.state.invoices, ["order_number", id]);
    if (index >= 0) {
      this.getManualBillingDetails(
        this.state.invoices[index].order_number,
        "showForm"
      );
    }
  };

  findOrders = () => {
    // to find the orders
    const { invoices, activeAccounts} = this.state;
    if(this.state.filterPlaceHolder.viewType === 'byAccount' && activeAccounts?.length){
      const currentRec = _.find(invoices, {account_id: activeAccounts[0]})
      if(currentRec){
        return currentRec.orders;
      }
      return [];
    } else{
      return invoices;
    }
  }

  updateAccountOrders = (account_id, selectedKeys, selectedRows) => {
    const invoices = _.cloneDeep(this.state.invoices)
    if(this.state.filterPlaceHolder.viewType === 'byAccount' && invoices?.length){
      const currentIndex = _.findIndex(invoices, {account_id})
      if(currentIndex >= 0){
        invoices[currentIndex].selectedInfo = {
          selectedKeys,
          selectedRows,
        }
        this.setState({
          invoices,
        })
      }
    }
  }

  handleEditClickBeta = (id, isGroupInvoice) => {
    const orders = this.findOrders();
    const index = _.findIndex(orders, [ "customer_order_id", id ]);
    message.loading("Fetching invoice details...", 0);
    if (index >= 0) {
      if (!isGroupInvoice) {
        this.getBillingDetails(
          orders[ index ].customer_order_id
        );
      getOrderBillingDetails(orders[ index ].customer_order_id).then((res) => {
        message.destroy();
        this.setState({
          showNewManageScreen: true,
          currentBillingOrderData : res.data.map((order) => {
            return {
              ...order,
              driver_details: order.drivers_details[ 0 ],
              customer_details: order.customers_details[ 0 ],
            }}),
        })
      });
      } else {
        this.getGroupBillingDetails(
          orders[ index ].customer_order_id
        );
      }
    }
  };

  handleEditClick = (id,record, isGroupInvoice) => {

    const orders = this.findOrders();
    const index = _.findIndex(orders, ["customer_order_id", id]);
    if (index >= 0) {
      // this.setState({
      //   processingAcc: orders[index]['account_id'],
      //   activeAccounts: [orders[index]['account_id']],
      //   currentRecord:record,
      //   modalShow: true,
      //   activeRecordId : id
      // })
      if (!isGroupInvoice) {
        // this.getBillingDetails(
        //   orders[index].customer_order_id,
        //   "showForm"
        // );
      // this.props.history.push({
      //   pathname: `/billing/${orders[index].customer_order_id}/edit`,
      //   state: { organizationSettings: this.state.organizationSettings }
      // });
        this.setState({
          // processingAcc: orders[ index ][ 'account_id' ],
          // activeAccounts: [ orders[ index ][ 'account_id' ] ],
          currentRecord: record,
          modalShow: true,
          activeRecordId: id
        })
      } else {
        this.setState({
          processingAcc: orders[ index ][ 'account_id' ],
          activeAccounts: [ orders[ index ][ 'account_id' ] ],
        }, () => {
        this.getGroupBillingDetails(
          orders[index].customer_order_id,
          "showGroupOrder"
        );
        })
      }
    }
  };

  handleDriverPayList = (id, isGroupInvoice) => {
    const index = _.findIndex(this.state.invoices, ["customer_order_id", id]);
    if (!isGroupInvoice) {
      if (index >= 0) {
        this.getDriverBillingDetails(
          this.state.invoices[index].customer_order_id,
          "showDriverPayForm"
        );
      }
    } else if (index >= 0) {
      this.getGroupDriverBillingDetails(
        this.state.invoices[index].customer_order_id,
        "showDriverGroupOrder"
      );
    }
  };

  getDriverBillingDetails = (id, modalElemet) => {
    this.setState({ inProgress: true });
    fetchBillingDetails(id).then((result) => {
      if (result.success) {
        const currentInvoice = result.invoice || {
          description: {},
          account_details: {},
          customer_details: {},
        };
        currentInvoice.customer_order_id = id;
        currentInvoice.remarks = result.remarks || [];
        currentInvoice.pickup_driver_pay_details =
          result.pickup_driver_pay_details
            ? result.pickup_driver_pay_details
            : {};
        if (isEmpty(currentInvoice.driver_accessorial_charges)) {
          currentInvoice.driver_accessorial_charges = [];
        }
        if (isEmpty(currentInvoice.adjustment_charges)) {
          currentInvoice.adjustment_charges = [];
        }
        if (isEmpty(currentInvoice.transportation_charges)) {
          currentInvoice.transportation_charges = [];
        }
        if (isEmpty(currentInvoice.order_info)) {
          currentInvoice.order_info = {};
        }
        const error_logs = result.error_logs || [];
        currentInvoice.status = result.billing_status;
        currentInvoice.error_log = error_logs.filter(
          (err_log) => !isEmpty(err_log.error_log)
        );
        currentInvoice.error_messages = error_logs.filter(
          (err_log) => !isEmpty(err_log.error_message)
        );
        this.setState(
          {
            // result.orders.customer_order
            currentInvoice,
            inProgress: false,
          },
          () => {
            if (modalElemet) {
              this.handleOnChange(modalElemet, true);
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          currentInvoice: {},
          inProgress: false,
        });
      }
    });
  };

  handleInvoiceResponse = (result, id) => {
    const currentInvoice =
      !isEmpty(result.invoice) && _.isObject(result.invoice)
        ? result.invoice
        : {
            description: {},
            account_details: {},
            customer_details: {},
          };
    currentInvoice.customer_order_id = id;
    currentInvoice.remarks = result.remarks || [];
    currentInvoice.consolidated_order_numbers = result.consolidated_order_numbers || []
    currentInvoice.is_driver_settlement_approved = result.is_driver_settlement_approved
    currentInvoice.pickup_driver_pay_details = result.pickup_driver_pay_details
      ? result.pickup_driver_pay_details
      : {};
    if (isEmpty(currentInvoice.accessorial_charges)) {
      currentInvoice.accessorial_charges = [];
    }
    if (isEmpty(currentInvoice.adjustment_charges)) {
      currentInvoice.adjustment_charges = [];
    }
    if (isEmpty(currentInvoice.transportation_charges)) {
      currentInvoice.transportation_charges = [];
    } else{
      // const groupedData = _.groupBy(currentInvoice.transportation_charges, 'order_item_group_id');
      // const sortedData = _.mapValues(groupedData, group => _.orderBy(group, 'invoice_name'));
      // currentInvoice.transportation_charges = _.flatMap(sortedData);
    }
    if (isEmpty(currentInvoice.order_info)) {
      currentInvoice.order_info = {};
    }
    const error_logs = result.error_logs || [];
    currentInvoice.status = result.billing_status;
    currentInvoice.error_log = error_logs.filter(
      (err_log) => !isEmpty(err_log.error_log)
    ).map(err_log => err_log.error_log);
    currentInvoice.error_messages = error_logs.filter(
      (err_log) => !isEmpty(err_log.error_message)
    ).map(err_log => err_log.error_message);
    return currentInvoice;
  };

  regenerateInvoiceWithLos = (invoiceLos, cb = null) => {
    this.setState({ regenerateProgress: true });
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      // reference_id:
      //   this.state.currentInvoice.order_group_type !== "MANUAL" // === "ORDER"
      //     ? this.state.currentInvoice.customer_order_id
      //     : this.state.currentInvoice.order_number,
      fmp_order_id: getFMPOrderFromInvoice(this.state.currentInvoice),
      account_id: this.state.currentInvoice.account_details.id,
      orders_info: Object.keys(invoiceLos).map((rec) => ({
        customer_order_id: rec,
        los_code: invoiceLos[rec],
      })),
      // order_type: this.state.currentInvoice.order_group_type === 'MANUAL' ? 'MANUAL' : 'ORDER',
    };
    message.loading({
      key: "generate",
      content: I18n.t("messages.generating"),
    });
    regenerateInvoiceWithNewLos(data)
      .then((result) => {
        if (result.success) {
          message.success({
            key: "generate",
            content: I18n.t("messages.generated"),
          });
          const currentInvoice = this.handleInvoiceResponse(
            result,
            this.state.currentInvoice.customer_order_id
          );
          this.setState(
            {
              // result.orders.customer_order
              currentInvoice,
              regenerateProgress: false,
            },
            () => {
              this.getBillingOrders();
              if (this.state.currentInvoice.status === "APPROVED") {
                cb();
              }
            }
          );
        } else {
          message.error({
            key: "generate",
            content: result.errors.join(","),
          });
        }
      })
      .finally(() => {
        this.setState({ regenerateProgress: false });
      });
  };

  getBillingDetails = (id, modalElemet) => {
    this.setState({ inProgress: true });
    fetchBillingDetails(id, this.state.currentRecord.order_group_type).then((result) => {
      if (result.success) {
        const currentInvoice = this.handleInvoiceResponse(result, id);
        this.setState(
          {
            // result.orders.customer_order
            currentInvoice,
            inProgress: false,
          },
          () => {
            if (modalElemet) {
              this.handleOnChange(modalElemet, true);
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          currentInvoice: {},
          inProgress: false,
        });
      }
    });
  };

  getManualBillingDetails = (id, modalElemet) => {
    this.setState({ inProgress: true });
    fetchManualBillingDetails(id).then((result) => {
      if (result.success) {
        const currentInvoice = result.invoice || {
          description: {},
          account_details: {},
          customer_details: {},
        };
        currentInvoice.customer_order_id = id;
        currentInvoice.remarks = result.remarks || [];
        if (isEmpty(currentInvoice.accessorial_charges)) {
          currentInvoice.accessorial_charges = [];
        }
        if (isEmpty(currentInvoice.adjustment_charges)) {
          currentInvoice.adjustment_charges = [];
        }
        if (isEmpty(currentInvoice.transportation_charges)) {
          currentInvoice.transportation_charges = [];
        }
        if (isEmpty(currentInvoice.order_info)) {
          currentInvoice.order_info = {};
        }
        const error_logs = result.error_logs || [];
        currentInvoice.status = result.billing_status;
        currentInvoice.error_log = error_logs.filter(
          (err_log) => !isEmpty(err_log.error_log)
        );
        currentInvoice.error_messages = error_logs.filter(
          (err_log) => !isEmpty(err_log.error_message)
        );
        this.setState(
          {
            // result.orders.customer_order
            currentInvoice,
            inProgress: false,
          },
          () => {
            if (modalElemet) {
              this.handleOnChange(modalElemet, true);
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          currentInvoice: {},
          inProgress: false,
        });
      }
    });
  };

  getGroupBillingDetails = (id, modalElemet) => {
    this.setState({ inProgress: true });
    fetchGroupBillingDetails(id).then((result) => {
      if (result.success) {
        const currentInvoice = result.invoice || {
          descriptions: [],
          account_details: {},
          customer_details: {},
          warehouse_details: {},
        };
        currentInvoice.customer_order_id = id;
        currentInvoice.remarks = result.remarks || [];
        if (isEmpty(currentInvoice.order_info)) {
          currentInvoice.order_info = {};
        }
        const error_logs = result.error_logs || [];
        currentInvoice.status = result.billing_status;
        currentInvoice.error_log = error_logs.filter(
          (err_log) => !isEmpty(err_log.error_log)
        );
        currentInvoice.error_messages = error_logs.filter(
          (err_log) => !isEmpty(err_log.error_message)
        );
        this.setState(
          {
            // result.orders.customer_order
            currentInvoice,
            inProgress: false,
          },
          () => {
            if (modalElemet) {
              this.handleOnChange(modalElemet, true);
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          currentInvoice: {},
          inProgress: false,
        });
      }
    });
  };

  showFormWindow = () => {
    this.setState({
      showForm: true,
    });
  };

  closeFormWindow = () => {
    this.setState(
      {
        currentInvoice:
          this.state.showGroupOrder === true ? this.state.currentInvoice : {},
        showForm: false,
      },
      () => {
        // this.getBillingOrders();
      }
    );
  };

  closeCreateInvoiceFormWindow = () => {
    this.setState({
      showCreateInvoiceForm: false,
    });
  };

  closeCreateBasicInvoiceFormWindow = () => {
    this.setState({
      showInvoiceBasicForm: false,
    });
  };

  closeDriverPayWindow = () => {
    this.setState(
      {
        currentInvoice:
          this.state.showGroupOrder === true ? this.state.currentInvoice : {},
        showDriverPayForm: false,
      },
      () => {
        this.getBillingOrders();
      }
    );
  };

  handleOnChange = (element, value) => {
    this.setState(
      {
        [element]: value,
      },
      () => {
        if (element === "currentInvoice") {
          this.getBillingOrders();
        }
        if (element === "filter") {
          this.handleSelectChange([], []);
          this.tableOptions.pagination.current = 1;
          // call only when stats count is greater than 0
          let statCount = 0;
          if (!isEmpty(value)) {
            statCount = this.state.data ? this.state.data[value.toLowerCase()] : 0 
            this.setState({ activeAccounts: [] },()=>{
              this.updateContext();
            });
          }
          if (statCount) {
            this.setState({
              activeAccounts: [],
            }, () => {
              this.getBillingOrders();
            })
          } else {
            this.setState({
              invoices: [],
              pagination: {},
            });
          }
        }
      }
    );
  };

  goToTab = (key) => {
    this.setState({
      activeKey: key,
    });
  };



  renderNewBetaManage = () => {
    const currentInvoice = _.cloneDeep(this.state.currentInvoice);
    if (isEmpty(currentInvoice.transportation_charges)) {
      currentInvoice.transportation_charges = [];
    }
    if (isEmpty(currentInvoice.order_info)) {
      currentInvoice.order_info = {};
    }
    if (isEmpty(currentInvoice.fuel_surcharges)) {
      currentInvoice.fuel_surcharges = [];
    }
    if (isEmpty(currentInvoice.accessorial_charges)) {
      currentInvoice.accessorial_charges = [];
    }
    if (isEmpty(currentInvoice.adjustment_charges)) {
      currentInvoice.adjustment_charges = [];
    }
    const isLocked = currentInvoice?.transportation_charges &&
      currentInvoice.transportation_charges.filter(rec => rec.is_locked == 'true').length > 0 ? true : false;

    return (
      <BaseModal
        title={ `${I18n.t("general.manage")} ${I18n.t("invoices.billing")} ${this.state?.currentInvoice?.order_info?.order_number ? `#${this.state.currentInvoice.order_info.order_number}` : ""
          }` }
        onCancel={ () => {
          this.setState({
            currentInvoice:
              this.state.showGroupOrder === true ? this.state.currentInvoice : {},
            showNewManageScreen: false
          });
          if (this.state.filter === "APPROVED") {
            // this.reApproveCall(); // Call method of child component
            this.getBillingOrders();
          }

        } }
        width="100%"
        height="100%"
        bodyStyle={ { padding: 10 } }
        maskClosable={ false }
        destroyOnClose
        style={ {
          top: 0,
          height: "100%",
          minHeight: "100vh",
        } }
      >

        <Tabs
          onChange={ this.goToTab }
          activeKey={ this.state.activeKey }
          size="small"
          type="card"
          tabBarExtraContent={ isLocked ? <Alert message={ I18n.t('general.locked') } type="error" showIcon /> : <Fragment /> }
        >
          <TabPane tab={ I18n.t("general.details") } key="details">
            <Spin
              spinning={ this.state.inProgress || this.state.regenerateProgress }
              delay={ 1000 }
            >
              { Object.keys(this.state.currentInvoice).length && Object.keys(this.state.currentBillingOrderData).length && (
                <BillingScreenBeta
                  currentBillingOrder={ this.state.currentBillingOrder }
                  orders={ this.state.currentBillingOrderData }
                  ref={ (form) => (this.reApproveCall = form?.handleSaveAndReapprove) } // Reference to child method
                  getInvoices={ this.getBillingOrders }
                  currentInvoice={ this.state.currentInvoice }
                  consolidatedOrderNumbers={ this.state.currentInvoice.consolidated_order_numbers }
                  isDriverSettlementApproved={ this.state.currentInvoice.is_driver_settlement_approved }
                  isNew={ this.state.isNew }
                  // onCancel={ () => this.closeFormWindow() }
                  onCancel={()=>{
                    this.setState({
                      showNewManageScreen: false,
                    });
                  }}
                  onChange={ (element, value) => this.handleOnChange(element, value) }
                  getInvoiceDetails={ (orderId) => this.getBillingDetails(orderId) }
                  getManualInvoiceDetails={ (invoice_number) =>
                    this.getManualBillingDetails(invoice_number)
                  }
                  accounts={ this.state.accounts }
                  warehouses={ this.state.warehouses }
                  drivers={ this.state.drivers }
                  showHeaders={ this.state.showGroupOrder !== true }
                  handleOrderDetailsViewClick={ (order_id) =>
                    this.handleOrderDetailsViewClick(order_id)
                  }
                  regenerateInvoice={ this.regenerateInvoiceWithLos }
                  handleEditOrder={ this.editFormDetails }
                  showFirstOne={ false }
                  organizationSettings={ this.props.organizationSettings }
                  showisConsolidation={ true }
                  showActions={ this.state.currentInvoice.status === 'READY_FOR_BILLING' }
                  glCodesList={ this.props.glCodesList || [] }
                  isInvoiceLocked={ isLocked }
                  refreshBetaScreen={ () => {
                    if (this.state.currentBillingOrder.order_group_type === "MANUAL") {
                      this.editManualInvoice(this.state.currentBillingOrder.order_number);
                    }
                    else {
                      this.handleEditClickBeta(this.state.currentBillingOrder.customer_order_id);
                    }
                  } }
                />
              ) }
            </Spin>
          </TabPane>
          <TabPane tab="Pickup Driver Pay" key="driver_pay">
            <BillingDriverPay
              drivers={ this.state.drivers }
              currentInvoice={ this.state.currentInvoice }
              isLocked={ isLocked }
            />
          </TabPane>
          <TabPane tab="Notes" key="driver_notes">
          <DriverNotesBetaBilling
           showActions={this.state.currentInvoice.status === "READY_FOR_BILLING"}
           isLocked={isLocked}
           getInvoices={this.getBillingOrders}
           refreshBetaScreen={() => {
             if (this.state.currentBillingOrder.order_group_type === "MANUAL") {
               this.editManualInvoice(this.state.currentBillingOrder.order_number);
             } else {
               this.handleEditClickBeta(
                 this.state.currentBillingOrder.customer_order_id
               );
             }
           }}
           referenceId={
             this.state.currentInvoice.order_group_type === "ORDER"
               ? this.state.currentInvoice.customer_order_id
               : this.state.currentInvoice.order_number
           }
           orderGroupType={this.state.currentInvoice.order_group_type}
           status={this.state.currentInvoice.status}
           remarks = {this.state.currentInvoice.remarks}
         />
            </TabPane>
        </Tabs>
      </BaseModal>
    );
  };
  // renderFormModal = () => {
  //   const currentInvoice = _.cloneDeep(this.state.currentInvoice);
  //   if (isEmpty(currentInvoice.transportation_charges)) {
  //     currentInvoice.transportation_charges = [];
  //   }
  //   if (isEmpty(currentInvoice.order_info)) {
  //     currentInvoice.order_info = {};
  //   }
  //   if (isEmpty(currentInvoice.fuel_surcharges)) {
  //     currentInvoice.fuel_surcharges = [];
  //   }
  //   if (isEmpty(currentInvoice.accessorial_charges)) {
  //     currentInvoice.accessorial_charges = [];
  //   }
  //   if (isEmpty(currentInvoice.adjustment_charges)) {
  //     currentInvoice.adjustment_charges = [];
  //   }
  //   const isLocked =  currentInvoice?.transportation_charges &&  
  //   currentInvoice.transportation_charges.filter( rec => rec.is_locked == 'true').length > 0 ? true : false;
  //   return (
  //     <BaseModal
  //       title={`${I18n.t("general.manage")} ${I18n.t("invoices.billing")} ${
  //         this.state?.currentInvoice?.order_info?.order_number ? `#${this.state.currentInvoice.order_info.order_number}` : ""
  //       }`}
  //       onCancel={() => {
  //         this.closeFormWindow();
  //         if( this.state.filter === "APPROVED" ){
  //           // this.reApproveCall(); // Call method of child component
  //           this.getBillingOrders()
  //         } 
            
  //       }}
  //       width="100%"
  //       height="100%"
  //       // style={{ top: 20 }}
  //       bodyStyle={{ padding: 0 }}
  //       maskClosable={false}
  //       style={ {
  //         top: 0,
  //         // overflowY: "hidden",
  //         height: "100%",
  //       } }
  //     >
  //       <div className="child-card-container">
  //         <Tabs
  //           onChange={this.goToTab}
  //           activeKey={this.state.activeKey}
  //           size="small"
  //           type="card"
  //           tabBarExtraContent={isLocked ? <Button type="danger" icon="lock" size="small">{I18n.t('general.locked')}</Button> : <Fragment />}
  //         >
  //         <TabPane tab={I18n.t("general.details")} key="details" className="billingFormTabScroll">
  //           <Spin
  //             spinning={this.state.inProgress || this.state.regenerateProgress}
  //             delay={1000}
  //           >
  //             <Form
  //               ref={(form) => (this.reApproveCall = form?.handleSaveAndReapprove)} // Reference to child method
  //               getInvoices={this.getBillingOrders}
  //               currentInvoice={this.state.currentInvoice}
  //               consolidatedOrderNumbers={this.state.currentInvoice.consolidated_order_numbers}
  //               isDriverSettlementApproved = {this.state.currentInvoice.is_driver_settlement_approved}
  //               isNew={this.state.isNew}
  //               onCancel={() => this.closeFormWindow()}
  //               onChange={(element, value) => this.handleOnChange(element, value)}
  //               getInvoiceDetails={(orderId) => this.getBillingDetails(orderId)}
  //               getManualInvoiceDetails={(invoice_number) =>
  //                 this.getManualBillingDetails(invoice_number)
  //               }
  //               accounts={this.state.accounts}
  //               warehouses={this.state.warehouses}
  //               drivers={this.state.drivers}
  //               showHeaders={this.state.showGroupOrder !== true}
  //               handleOrderDetailsViewClick={(order_id) =>
  //                 this.handleOrderDetailsViewClick(order_id)
  //               }
  //                 regenerateInvoice={ (invoiceLos, cb) => this.regenerateInvoiceWithLos(invoiceLos, cb) }
  //               handleEditOrder={this.editFormDetails}
  //               showFirstOne={false}
  //               organizationSettings={this.props.organizationSettings}
  //               showisConsolidation={true}
  //               showActions={ this.state.currentInvoice.status === 'READY_FOR_BILLING'}
  //               glCodesList={this.props.glCodesList || []}
  //               isInvoiceLocked={isLocked}
  //               // selectedInvoice={this.state.selectedInvoice}
  //             />
  //           </Spin>
  //         </TabPane>
  //         <TabPane tab="Pickup Driver Pay" key="driver_pay" className="billingFormTabScroll">
  //           <BillingDriverPay 
  //             drivers={this.state.drivers} 
  //             currentInvoice={this.state.currentInvoice}
  //             isLocked={isLocked}
  //           />
  //         </TabPane>
  //       </Tabs>
  //       </div>
  //     </BaseModal>
  //   );
  // };


  closeModal = () => {
    if (this.state.isLosChanged) {
      this.renderRegenerateModal();
    } else {
      this.setState({ modalShow: false, currentRecord: {}, activeRecordId: null });
      if (this.state.filterPlaceHolder.viewType === 'byAccount' && this.state.activeAccounts?.length) {
        this.getAccountSummary(true, this.state.activeAccounts[ 0 ]);
      }
    }
  }


  onInvoiceFormUnmount = (orderNums, isApproved = false) => {
    const ordersInCurrentList = this.state.invoices.filter((rec) => orderNums.includes(rec.order_number));
    if (ordersInCurrentList.length) {
      this.getFewBillingOrders(ordersInCurrentList.map((rec) => rec.order_number ));
    }
    if (ordersInCurrentList.length > 0 && this.state.filter == 'READY_FOR_BILLING' && isApproved) {
      let status = this.state.filter.toLowerCase();
      let data = {
        ...this.state.data,
        [status]: this.state.data[status] - ordersInCurrentList.length <= 0 ? 0 : this.state.data[status] - ordersInCurrentList.length,
        'approved':this.state.data.approved + orderNums.length,
      };
      this.setState({ data });
    }
  };


  handleIsLosChanged = (value) => {
    this.setState({ isLosChanged: value })
  }

  renderRegenerateModal = () => {
    Modal.warning({
      title: 'Please Regenerate the Invoice',
    });
  };



  renderingModalForm = () => {
    const { modalShow } = this.state;
    return (
      <Modal
        title={`${I18n.t('general.manage')} ${I18n.t('invoices.billing')} ${this.state?.currentRecord?.order_number
          ? `#${this.state.currentRecord.order_number}`
          : ''
          }`}
        visible={modalShow}
        onCancel={this.closeModal}
        width="80%"
        style={{
          top: 10,
          height: "90%",
        }}
        bodyStyle={{ padding: 0 }}
        footer={null}
        destroyOnClose
      >
        <FormInvoice
          activeRecordId={this.state.activeRecordId}
          closeModal={this.closeModal}
          onUnmount={this.onInvoiceFormUnmount}
          handleIsLosChanged={this.handleIsLosChanged}
          handleSuccess={this.handleAppproveSuccess}
          filter={this.state.filter}
          currentOrderGroupType={this.state.currentRecord.order_group_type}
        // selectedInvoice={this.state.selectedInvoice}
        />
      </Modal>
    )
  }

  showManualInvoiceDetails = (invoice_number) => {
    this.getManualBillingDetails(invoice_number);
    this.setState({
      showForm: true,
    });
  };

  renderCreateInvoiceModal = () => (
    <BaseModal
      title={`${I18n.t("general.invoice_form")}`}
      onCancel={() => this.closeCreateInvoiceFormWindow()}
      width="70%"
      style={{ top: 50 }}
      bodyStyle={{ padding: 20 }}
    >
      <InvoiceCreateForm
        accounts={this.state.accounts}
        getInvoices={this.getBillingOrders}
        closeCreateInvoiceFormWindow={() => this.closeCreateInvoiceFormWindow()}
        showManualInvoiceDetails={(invoice_number) =>
          this.showManualInvoiceDetails(invoice_number)
        }
      />
    </BaseModal>
  );

  handleInvoiceCreateForm = (basicForm, account) => {
    const currentInvoice = this._getCreateSampleInvoice(basicForm, account);
    this.setState(
      {
        visibleAccountPopover: false,
        currentInvoice,
        showForm: true,
        createInvoiceForm: Object.assign({}, this.state.createInvoiceForm, {
          account_id: "",
          invoice_number: "",
        }),
      },
      () => {
        this.closeCreateBasicInvoiceFormWindow();
      }
    );
  };

  navigateToForm = (result) => {
    const currentInvoice = this.handleInvoiceResponse(
      result,
      result.customer_order_id
    );
    this.setState(
      {
        currentInvoice,
        inProgress: false,
        currentRecord: result.invoice,
        modalShow: true,
        activeRecordId: result.customer_order_id,
        showInvoiceBasicForm: false,
      },
    );
  };

  // navigateToForm = (result) => {
  //   const currentInvoice = this.handleInvoiceResponse(
  //     result,
  //     result.customer_order_id
  //   );
  //   this.setState(
  //     {
  //       // result.orders.customer_order
  //       currentInvoice,
  //       inProgress: false,
  //     },
  //     () => {
  //       this.handleOnChange("showForm", true);
  //     }
  //   );
  //   this.closeCreateBasicInvoiceFormWindow();
  // };

  renderInvoiceBasicFormModal = () => (
    <BaseModal
      title={`${I18n.t("invoices.create")}`}
      onCancel={() => this.closeCreateBasicInvoiceFormWindow()}
      width="50%"
      style={{ top: 50 }}
      bodyStyle={{ padding: 20 }}
      maskClosable={false}
    >
      <ManualInvoiceForm
        accounts={this.state.accounts}
        warehouses={this.state.warehouses}
        drivers={this.state.drivers}
        handleInvoiceCreateForm={(basicForm, account) =>
          this.handleInvoiceCreateForm(basicForm, account)
        }
        navaigateInvoiceForm={this.navigateToForm}
        closeCreateBasicInvoiceFormWindow={() =>
          this.closeCreateBasicInvoiceFormWindow()
        }
      />
    </BaseModal>
  );

  renderDriverPayModal = () => {
    const currentInvoice = { ...this.state.currentInvoice };
    return (
      <BaseModal
        title={`${I18n.t("invoices.billing")} #${
          this.state.currentInvoice.order_number
        }`}
        onCancel={() => this.closeDriverPayWindow()}
        width="70%"
        style={{ top: 50 }}
        bodyStyle={{ padding: 20 }}
      >
        <DriverPayDetails
          currentInvoice={currentInvoice}
          isNew={this.state.isNew}
          onCancel={() => this.closeDriverPayWindow()}
          onChange={(element, value) => this.handleOnChange(element, value)}
          getInvoiceDetails={(orderId) => this.getBillingDetails(orderId)}
          showHeaders={this.state.showGroupOrder !== true}
          handleOrderDetailsViewClick={(order_id) =>
            this.handleOrderDetailsViewClick(order_id)
          }
          showRemarks={false}
        />
      </BaseModal>
    );
  };

  closeGrouopInvoiceWindow = () => {
    this.setState(
      {
        currentInvoice: {},
        showGroupOrder: false,
      },
      () => {
        this.getBillingOrders();
      }
    );
  };

  renderGroupInvoiceModal = () => (
    <BaseModal
      title={`${I18n.t("general.manage")} ${I18n.t("invoices.billing")} #${
        this.state.currentInvoice.order_number
      }`}
      onCancel={() => this.closeGrouopInvoiceWindow()}
      width="70%"
      style={{ top: 50 }}
      bodyStyle={{ padding: 20 }}
    >
      <GroupInvoiceDetails
        invoice={this.state.currentInvoice}
        orders={this.state.invoices}
        editClick={this.handleEditClick}
        onPrintClick={this.handlePrint}
      />
    </BaseModal>
  );

  renderGroupInvoiceModal = () => (
    <BaseModal
      title={`${I18n.t("general.manage")} ${I18n.t("invoices.billing")} #${
        this.state.currentInvoice.order_number
      }`}
      onCancel={() => this.closeGrouopInvoiceWindow()}
      width="70%"
      style={{ top: 50 }}
      bodyStyle={{ padding: 20 }}
    >
      <GroupInvoiceDetails
        invoice={this.state.currentInvoice}
        orders={this.state.invoices}
        editClick={this.handleEditClick}
        onPrintClick={this.handlePrint}
      />
    </BaseModal>
  );

  handlePrint = (id) => {
    const orders = this.findOrders();
    const index = _.findIndex(orders, ["customer_order_id", id]);
    const manualInvoiceIndex = _.findIndex(orders, [
      "order_number",
      id,
    ]);
    if (manualInvoiceIndex >= 0) {
      this.getManualBillingDetails(
        orders[manualInvoiceIndex].order_number,
        "showInvoice"
      );
    } else if (index >= 0) {
      this.getBillingDetails(
        orders[index].customer_order_id,
        "showInvoice"
      );
    }
  };

  closeInvoice = () => {
    this.setState({
      showInvoice: false,
    });
  };

  printOrderInvoice = (order_id) => {
    this.setState({
      isGeneratingPdf: true,
    });
    fetchOrderInvoicePdf(order_id, "")
      .then((result) => {
        if (result.success) {
          base64ToPdf(result.bol_file, `OrderInvoice-${order_id}.pdf`);
        } else {
        }
      })
      .finally(() => {
        this.setState({
          isGeneratingPdf: false,
        });
      });
  };

  handleShowInvoice = () => (
    <BaseModal
      title={`${I18n.t("invoices.label")} #${
        this.state.currentInvoice.order_number
      }`}
      onCancel={() => this.closeInvoice()}
      width="70%"
      style={{ top: 50 }}
    >
      {checkServiceExistance("GOIP") && (
        <div
          className="alignCenter marginBottom10"
          style={{ cursor: "pointer" }}
          onClick={() =>
            this.printOrderInvoice(this.state.currentInvoice.customer_order_id)
          }
        >
          <Icon type="printer" /> Print
        </div>
      )}
      <Invoice currentInvoice={this.state.currentInvoice} currentOrderGroupType={this.state.currentRecord.order_group_type}/>
    </BaseModal>
  );

  _renderStats = () => {
    let billingStatusArr = [];
    if (this.props.organizationSettings.show_hold_bucket == "true") {
      billingStatusArr = AppConfig.billingStatus;
    } else {
      billingStatusArr = AppConfig.billingStatus.filter((e) => e.key != 'ON_HOLD');
    }


    return <Row className="orderStats" type="flex" justify="center">
      {billingStatusArr.map((record) => (
        <Col
          span={8}
          key={record.key}
          onClick={() => this.handleOnChange("filter", record.key)}
          className="routeLegends"
        >
          <Statistic
            title={record.label}
            className={this.state.filter === record.key && "active"}
            value={
              this.state.data ? this.state.data[record.key.toLowerCase()] : 0
            }
            prefix={<Icon type={record.icon} className={record.className} />}
          />
        </Col>
      ))}
    </Row>
  };

  editFormDetails = (id) => {
    this.handleEditOrderClick(id);
  };

  handleEditOrderClick = (id) => {
    this.setState(
      {
        selectedOrderId: id,
      },
      () => {
        fetchOrderDetails(id).then((result) => {
          if (result.success) {
            const currentOrder =
              result.orders.length > 0 ? _.find(result.orders, { id: id }) : {};
            if (!currentOrder.id) {
              currentOrder.id = id;
            }
            this.setState(
              {
                // result.orders.customer_order
                currentOrder,
              },
              () => {
                this.setState({ editForm: true });
              }
            );
          } else {
            // alertMessage(result.errors[0], 'error', 10);
          }
        });
      }
    );
  };

  closeEditFormModal = () => {
    this.setState({
      editForm: false,
      currentOrder: {},
    });
  };

  handleOrderSaveSuccess = (
    callbackMessage,
    isOutOfDispatch,
    isModified,
    newData
  ) => {
    alertMessage(callbackMessage);
    this.getBillingOrders();
    this.closeEditFormModal();
  };

  clearFilter = () => {
    const { getSelectesWHs } = this.props.warehouseFilter;
    this.setState(
      {
        filterPlaceHolder: {
          ...this.state.filterPlaceHolder,
          fromDate: moment().subtract(
            AppConfig.invoices_filter_duration,
            "day"
          ),
          toDate: moment(),
          search_order_token: "",
          warehouse_id: getSelectesWHs(),
          accountCodes: [],
          searchKeyPressed: false,
          updateSearchTags: false,
          driver_id: "",
          viewType: 'byOrder',
          vehicle_types: []
        },
      },
      () => {
        const { screenData, updateFilteredData } = this.props.searchConfigContext;
        this.updateContext();
        this.computeTags(this.state.filterPlaceHolder);
        this.getBillingOrders();
      }
    );
  };

  render() {
    const { filterPlaceHolder, updateSearchTags } = this.state;
    return (
      <div className="content-outer">
        <div className="content">
          <Spin
            spinning={this.state.listProgress || this.state.inProgress}
            delay={1000}
          >
            {this._renderHeader()}
            {checkServiceExistance("FBLOS") && (
              <Row className="orderStats" type="flex" justify="center">
                <Col span={12}>
                  <this._renderStats />
                </Col>
              </Row>
            )}
            <div style={{ marginTop: "10px" }}>
              <Row type="flex" justify="space-between">
                <Col span={24}>
                  <AppliedFiltersTags
                    tags={this.state.appliedFiltersTags}
                    clearFilter={() => this.clearFilter()}
                    isSearching={filterPlaceHolder.searchKeyPressed}
                    searchText={filterPlaceHolder.search_order_token}
                    searchBy={"Order"}
                    updateSearchTags={updateSearchTags}
                    resetSearchTags={this.resetSearchTags}
                    clearSearch={this.clearSearch}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="end">
              <Col>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    onSearch={this.onSearch}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Col>
              </Row>
            </div>
            {checkServiceExistance("BLI") && (
              <Row>
                <Col className="accessorials marginTop10 billing_data">
                  {
                    filterPlaceHolder.viewType === "byOrder" ?
                     this._renderOrders()
                    :
                    <this._renderAccOrders />
                   }
                </Col>
              </Row>
            )}
          </Spin>
        </div>
        {/* {this.state.showForm && this.renderFormModal()} */}
        {this.renderingModalForm()}
        {this.state.showDriverPayForm && this.renderDriverPayModal()}
        {this.state.showCreateInvoiceForm && this.renderCreateInvoiceModal()}
        {this.state.showInvoiceBasicForm && this.renderInvoiceBasicFormModal()}
        {this.state.showInvoice && this.handleShowInvoice()}
        {this.state.showGroupOrder && this.renderGroupInvoiceModal()}
        {this.state.showMonthlyCutoffModal && this.openMonthlyCutoffModal()}
        {this.state.editForm && (
          <Drawer
            placement="right"
            closable={false}
            maskClosable={false}
            onClose={this.closeEditFormModal}
            visible={this.state.editForm}
            width="90%"
          >
            <EditOrderForm
              customerDetails={this.state.currentOrder}
              customerOrderId={this.state.currentOrder.id}
              preferences={[]}
              onCancel={this.closeEditFormModal}
              handleSuccess={(message, isOutOfDispatch, isModified, newData) =>
                this.handleOrderSaveSuccess(
                  message,
                  isOutOfDispatch,
                  isModified,
                  newData
                )
              }
              currentRoute={this.state.selectedRouteObject}
              isNew={false}
              tab="accessorials"
            />
          </Drawer>
        )}


        { this.state.showNewManageScreen && this.renderNewBetaManage() }
      </div>
    );
  }
}


export const InvoicesComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const searchConfigContextData = useContext(SearchConfigContext);
  const { orgSettings: organizationSettings, glCodesList, currentOrg } = useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  const [ordersListProps, setOrdersListProps] = useState({
    displayConfiguration,
  });

  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.INVOICES_LISTING
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);

  useEffect(() => {
    setOrdersListProps({
      displayConfiguration,
    });
  }, [displayConfiguration]);
  return (
    !warehouseFilter?.selectedWarehouses  ?
    <Skeleton />
    :
    <Invoices
      currentOrg={currentOrg}
      searchConfigContext={searchConfigContextData}
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      ordersListProps={ordersListProps}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      glCodesList={glCodesList}
      {...props}
    />
  );
});

export default withRouter(InvoicesComponent);
