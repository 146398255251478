import _, { isEmpty, result, set } from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { fetchTrucks } from "../api/TrucksApi";
import { fetchUsers } from "../api/UsersApi";
import { fetchOrderDetails } from "../api/OrdersApi";
import { alertMessage } from "../common/Common";
import {
  RecurringRouteApi,
  TemplatesApi,
  saveSettingsTemplateOrder,
} from "../components/Templates/Api";
import TemplateCronConfig from "../components/Templates/TemplateCronConfig";
import TemplateForm from "../components/Templates/TemplateForm";
import TemplatesList from "../components/Templates/TemplatesList";
import AppConfig from "../config/AppConfig";
import {
  // getAccounts,
  getDriverFullDisplayName,
  getWarehouses,
  renderAlertMessage,
} from "../helpers/common";
import userStore from "../stores/UserStore";
import { loadGoogleMaps } from "../utils/Utils";
import {
  Button,
  Col,
  Drawer,
  Row,
  Tabs,
  DatePicker,
  span,
  Text,
  Spin,
  Icon,
  Tooltip,
  Select,
  Modal,
} from "../common/UIComponents";
import TemplateActivityLogs from "./TemplateActivityLogs";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import { DisplaySettingsContext } from "../context/DisplaySettings";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import BaseModal from "../components/BaseModal";
import ClonedTemplateList from "../components/Templates/ClonedTemplateList";
import EditOrderForm from "../components/orders/EditOrderForm";
import TemplateRouteCreationForm from "./TemplateRouteCreationForm";
import TemplateStats from "../components/Templates/TemplateStats";
import RecurringRoutesList from "../components/Templates/RecurringRoutesList";
import PendingRecurringRoutesList from "../components/Templates/PendingRecurringRoutesList";
import moment from "moment";
import { showRouteAssignmentModal } from "../components/dispatches/helpers";
import { ScreenKeys } from "./constants";
import I18n from "../common/I18n";
import TemplateAccessorialForm from "../components/Templates/TemplateAccessorialForm";
import { FlatRateForm } from "../components/Templates/FlatRateForm";
const { TabPane } = Tabs;

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
function Templates(props) {
  const defaultFilter = {
    sortBy: "none",
    sortByType: "descend",
  };
  const { userContext } = props.appContexts
  const { currentUser , updateRecordsPerPage } = userContext
  const [templatesData, setTemplatesData] = useState([]);
  const [routesListData, setRoutesListData] = useState([]);
  const perPageKey = ScreenKeys.TEMPLATES_LISTING
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [paginationConfig, setPaginationConfig] = useState({
    current: 1,
    pageSize: recordsPerPageSaved,
    total: 0,
  });
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved);
  const [isLoading, setIsLoading] = useState(false);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [activeRecordId, setActiveRecordId] = useState(null);
  const [activeRecord, setActiveRecord] = useState(null);
  const [isGoogleApiMounted, setIsGoogleApiMounted] = useState(false);
  const [isFetchingDrivers, setIsFetchingDrivers] = useState(false);
  const [driversData, setDriversData] = useState([]);
  const [trucksData, setTrucksData] = useState([]);
  const [isFetchingTrucks, setIsFetchingTrucks] = useState(false);
  const [activeKey, setActiveKey] = useState("details");
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const[displayCOnfiguration, setDisplayCOnfiugration] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showCreateOrderButton, setShowCreateOrderButton] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showDateWindow, setShowDateWindow] = useState(false);
  const [showScheduleRoutes, setshowScheduleRoutes] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);

  const [convertedOrder, setConvertedOrder] = useState([]);
  const [orderObject, setOrderObject] = useState(null);
  const isMilitaryTime = organizationSettings["is_military_time"] == "true";
  const [ filterPlaceHolder, setFilterPlaceHolder ] = useState({ ...defaultFilter });
  const [recurringRoutesCount, setRecurringRoutesCount] = useState(0);
  const [recurringOrdersCount, setRecurringOrdersCount] = useState(0);


const [editForm, setEditForm] = useState(false);
  const [ showTemplateRouteCreationForm, setShowTemplateRouteCreationForm ] = useState(false);
  const [ currentStat, setCurrentStat ] = useState('ORDERS');
  const [ statsData, setStatsData ] = useState({});
  const [clickedRecordId, setClickedRecordId] = useState(null);
  const [ selectedDriverId, setSelectedDriverId ] = useState(null);


  const isInOrderTab = () => {
    return currentStat === "ORDERS";
  };
  const isInTemplateRouteTab = () => {
    return currentStat === "ROUTES";
  };

  useEffect(() => {
    if(currentStat === "ORDERS"){
      getTemplates();
    }else{
      getRouteTemplates();
    }
  }, [recordsPerPage]);

  const handleOnStatChange = async (stat) => {
    if (currentStat === stat) {
      return;
    }
    tableOptions.pagination.current = 1;
    handleCancel();
    if (stat === "ORDERS") {
      await getTemplates();
      setCurrentStat(stat);
    } else if (stat === "ROUTES") {
      await getRouteTemplates();
      setCurrentStat(stat);
    } else {
      await getPendingRouteTemplates();
      setCurrentStat(stat);
    }
  };

  const recordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);

  };
  
  
  const onSearch = (value) => {};

  useEffect(() => {
    setDisplayCOnfiugration(props.displayConfiguration);
  }, [props.displayConfiguration]);

  useEffect(() => {
    debounceFetchTemplates();
  }, [filterPlaceHolder]);

  const getDrivers = async (initial, cb) => {
    setIsFetchingDrivers(true);
    const result = await fetchUsers();
    if (result.success) {
      setDriversData(result.users);
      setIsFetchingDrivers(false);
    } else {
      renderAlertMessage(result.errors)
      setIsFetchingDrivers(false);
    }
  };

  const getTrucks = () => {
    setIsFetchingTrucks(true);
    fetchTrucks({}, 1, 1000)
      .then((result) => {
        if (result.success) {
          setTrucksData(result.trucks);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsFetchingTrucks(false);
      });
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }
    if (tableOptions.pagination.current !== currentPage) {
      debounceFetchTemplates();
    } else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
    }
  }

  const closeTemplateForm = (record = null) => {
    if(record?.id){
      handleEditTemplate(record)
    }else{
      setShowTemplateForm(false);
      setActiveKey("details");
    }
   
  };

  // showTemplateForm on close fetches the templates again
  useEffect(() => {
    if (!showTemplateForm) {
      debounceFetchTemplates();
    }
  }, [showTemplateForm]);

  useEffect(() => {
    tableOptions.pagination.current = 1;
    debounceFetchTemplates();
  }, [
    props.warehouseFilter.selectedWarehouses,
    props.warehouseFilter.warehouses,
  ]);

  const getPendingRouteTemplates = async () => {
    setIsLoading(true);
    let page = 1;
    let perPage = recordsPerPage;
    let sort = "id";
    let order = "descend";
    if (tableOptions.pagination) {
      page = tableOptions.pagination.current || 1;
      perPage = tableOptions.pagination.pageSize || recordsPerPage;
    }
    if (tableOptions.sorter) {
      sort = tableOptions.sorter.field || "id";
      order = tableOptions.sorter.order || "descend";
    }
    const orgId = userStore.getStateValue("selectedOrg");
    const payload = {
      page,
      per_page: perPage,
      sort,
      order,
      organization_id: orgId,
      warehouse_id: props.warehouseFilter.selectedWarehouses,
    };

    // const apiPayload = transform(payload);
    RecurringRouteApi.fetchPendingRoutes(payload)
      .then((result) => {
        if (result.success) {
          const modifiedRoutesResponse = _.map(
            result.pendingRoutes,
            (route) => {
              const recurring_templates = _.map(
                route.recurring_templates,
                (recurring_template) => {
                  const template = _.find(route.templates, (template) => {
                    return template._id === recurring_template.template_id;
                  });
                  const location = _.find(template.locations, (location) => {
                    return (
                      location.type_of_loc === recurring_template.type_of_loc
                    );
                  });
                  return {
                    ...recurring_template,
                    template: {
                      ...template,
                      locations: location || (template?.locations || []),                    },
                  };
                }
              );
              return {
                ...route,
                recurring_templates,
              };
            }
          );

          delete modifiedRoutesResponse.templates;
          setRoutesListData(modifiedRoutesResponse);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getRouteTemplates = async () => {
    setIsLoading(true);
    let page = 1;
    let perPage = recordsPerPage;
    let sort = "id";
    let order = "descend";
    if (tableOptions.pagination) {
      page = tableOptions.pagination.current || 1;
      perPage = tableOptions.pagination.pageSize || recordsPerPage;
    }
    if (tableOptions.sorter) {
      sort = tableOptions.sorter.field || "id";
      order = tableOptions.sorter.order || "descend";
    }
    const orgId = userStore.getStateValue("selectedOrg");
    const payload = {
      page,
      per_page: perPage,
      sort,
      order,
      organization_id: orgId,
      warehouse_id: props.warehouseFilter.selectedWarehouses,
    };

    // const apiPayload = transform(payload);
    RecurringRouteApi.fetch(payload)
      .then((result) => {
        if (result.success) {
          // setRoutesListData(
          //   result.recurringRoutes
          // );
          const modifiedRoutesResponse = _.map(
            result.recurringRoutes,
            (route) => {
              const recurring_templates = _.map(
                route.recurring_templates,
                (recurring_template) => {
                  const template = _.find(route.templates, (template) => {
                    return template._id === recurring_template.template_id;
                  });
                  const location = template?.locations && recurring_template && _.find(template.locations, (location) => {
                    return (
                      location.type_of_loc === recurring_template.type_of_loc
                    );
                  });
                  return {
                    ...recurring_template,
                    template: {
                      ...template,
                      locations: location || (template?.locations || []),
                    },
                  };
                }
              );
              return {
                ...route,
                recurring_templates,
              };
            }
          );
          delete modifiedRoutesResponse.templates;
          setRoutesListData(modifiedRoutesResponse);
          setRecurringOrdersCount(result.recurring_orders_count)
          setRecurringRoutesCount(result.total_count)

          if (_.isObject(result.pagination)) {
            setPaginationConfig({
              current: result.pagination.current_page,
              pageSize: recordsPerPage,
              total: result.pagination.total_count,
            });
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getTemplates = async () => {
    const warehousesList = await getWarehouses();
    // const accountsList = await getAccounts();
    setWarehouses(warehousesList);
    // setAccounts(accountsList);
    setIsLoading(true);
    let page = 1;
    let perPage = recordsPerPage;
    // let sort = "id";
    // let order = "descend";
    const sortByType = filterPlaceHolder.sortByType || "ascend";
    if (tableOptions.pagination) {
      page = tableOptions.pagination.current || 1;
      perPage = tableOptions.pagination.pageSize || recordsPerPage;
    }
    // if (tableOptions.sorter) {
    //   sort = tableOptions.sorter.field || "id";
    //   order = tableOptions.sorter.order || "descend";
    // }

    const orgId = userStore.getStateValue("selectedOrg");
    const payload = {
      page,
      per_page: perPage,
      sort_by: filterPlaceHolder.sortBy,
      sort_order: I18n.t(`general.sort_${sortByType}`),
      organization_id: orgId,
    };

    // const apiPayload = transform(payload);
    TemplatesApi.fetch(payload)
      .then((result) => {
        if (result.success) {
          // setTemplatesData(
          //   attachCodes(result.templates, accountsList, warehousesList)
          // );
          setTemplatesData(result.templates);
          if (_.isObject(result.pagination)) {
            setPaginationConfig({
              current: result.pagination.current_page,
              pageSize: recordsPerPage,
              total: result.pagination.total_count,
            });
            setRecurringOrdersCount(result.pagination.total_count)
            setRecurringRoutesCount(result.recurring_routes_count)
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const navigateToAccount = (account_code) => {
    const accountIndex = _.findIndex(accounts, ["code", account_code]);
    if (accountIndex >= 0) {
      const accountData = Object.assign({}, accounts[accountIndex]);
      const account_id = !isEmpty(accountData.primary_account_id)
        ? accountData.primary_account_id
        : accountData.id;
      props.history.push(`/accounts/${account_id}/settings`);
    }
  };

  const attachCodes = (data, accounts, warehouses) => {
    return data.map((template) => {
      const account = accounts.find(
        (account) => account.id === template.account_id
      );
      const warehouse = warehouses.find(
        (warehouse) => warehouse.id === template.warehouse_id
      );
      return {
        ...template,
        selected_account: account ? account : "",
        selected_warehouse: warehouse ? warehouse : "",
      };
    });
  };

  const debounceFetchTemplates = _.debounce(getTemplates, 500);

  useEffect(() => {
    setIsLoading(true);
    debounceFetchTemplates();
    if (window.google) {
      setIsGoogleApiMounted(true);
    } else {
      loadGoogleMaps(() => {
        setIsGoogleApiMounted(true);
      });
    }
    getDrivers();
    getTrucks();
  }, []);

  useEffect(() => {
    if (!showTemplateForm) {
      // when form is closed
      setActiveRecordId(null);
      setActiveRecord(null);
      // debouncedFetchQuotes();
    }
  }, [showTemplateForm]);

  const handleTempalteDeletion = (id) => {
    setIsLoading(true);
    TemplatesApi.delete(id)
      .then((result) => {
        if (result.success) {
          alertMessage("Deleted Successfully", "success", 5);
          const newTemplatesData = templatesData.filter(
            (template) => template.id !== id
          );
          setTemplatesData(newTemplatesData);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createTemplateOrder = (templateId) => {
    // navifate to orders screen with passing data to it to create order using template
    props.history.push({
      pathname: "/orders",
      // pathname: '/orders?operation=template_create&template_id=' + templateId,
      // attach operation query params
      search: "?operation=template_create&template_id=" + templateId,
    });
  };

  const handledateModal = (id) => {
    setShowDateWindow(true);
    setClickedRecordId(id);
  }

  const closeDateWindow = () => {
    setShowDateWindow(false); 
    setSelectedDate(null)
    setConvertedOrder([]);
  };

  const [ activeRouteId, setActiverouteId ] = useState(null);

  const handleRouteCreation = (id) => {
    setshowScheduleRoutes(true);
    // check for previous driver in the route
    const routeIndex = _.findIndex(routesListData, ["id", id]);
    const currentRoute = Object.assign({}, routesListData[routeIndex]);
    const driverId = currentRoute?.driver_id;
    setSelectedDriverId(driverId);
    setActiverouteId(id);
  };

  const closeCreateRouteModal = () => {
    setshowScheduleRoutes(false);
    setSelectedDriverId(null);
    setSelectedDate(moment());
    getRouteTemplates();
  };

  const navigateToRoute = (route) => {
    props.history.push({
      pathname: '/routes',
      filter: 'ASSIGNED',
      routeId: route.id,
      dateFilter: {
        fromDate: route.r_scheduled_start_datetime,
        toDate: route.r_scheduled_end_datetime,
      },
    })
  }

  const showCreatedInfo = (routeInfo , recurring_route_activity) => {
    const lastTemplateActivity = _.last(recurring_route_activity);
    showRouteAssignmentModal({
      orderNumbers: lastTemplateActivity.customer_order_numbers,
      route : routeInfo,
      navigateToRoute,
      closeModal: () => Modal.destroyAll(),
    });
  }

  const styleProps = {
    label: 4,
    wrapper: 20,
    ...props.adjustStyle,
  };

  const handleCancel = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setConvertedOrder([]);
    setSelectedRowId([]);
    // setSelectedOrderRows([]);
  };

  const handleEditOrder = (id) => {
    fetchOrderDetails(id).then((result) => {
      if (result.success) {
        const orderIndex = _.findIndex(result.orders, ["id", id]);
        const currentOrder = Object.assign({}, result.orders[orderIndex]);
        setOrderObject(currentOrder);
        setEditForm(true);
      } else {
        renderAlertMessage(result.errors)
        setOrderObject(null);
        setEditForm(false);
      }
    });
  };
  const handleSaveSuccess = (message) => {
    alertMessage(message);
    setEditForm(false);
    setOrderObject({});
  };
  useEffect(() => {
    return () => {
      setConvertedOrder([]);
    };
  }, [showDateWindow]);

  const renderDatewindow = () => {
    const showTable = orderNumbers.length > 0 && convertedOrder.length > 0;
    return (
      <BaseModal
        title="Schedule An Appointment"
        // Set the width to 50%
        style={{ top: 150 }}
        onCancel={closeDateWindow}
        footer={null} // Remove the default footer
      >
        {showDateWindow && (
          <div>
            <Row>
              {/* <Col span={16}><Text strong>Select Date: </Text> <DatePicker onChange={setSelectedDate} /></Col>
               */}
              <Col xs={16}>
                <div style={{ fontWeight: 700 }} className="marginBottom15">
                  Select Date:&nbsp; &nbsp; &nbsp; &nbsp;
                  <DatePicker onChange={setSelectedDate} />
                </div>
              </Col>
              <Col xs={8} className="alignLeft marginTop3">
                <Button
                  type="primary"
                  size="small"
                  onClick={saveSelectedRowAndDate}
                >
                  Save
                </Button>{" "}
              </Col>
            </Row>
            <Spin spinning={inProgress} delay={1000}>
              {showTable && (
                <Row>
                  {orderNumbers.length > 0 && (
                    <ClonedTemplateList
                      data={convertedOrder}
                      orderNumbers={orderNumbers}
                      templateNames={templateNames}
                      handleEditOrder={handleEditOrder}
                    />
                  )}{" "}
                </Row>
              )}
            </Spin>
          </div>
        )}
      </BaseModal>
    );
  };

  const renderScheduleQuotes = () => {
    const currentTemplateName = _.find(routesListData, ["id", activeRouteId])
      ?.route_name;

    return (
      <BaseModal
        title={`Schedule Route ${currentTemplateName ? `(${currentTemplateName})` : ""}` }
        closable={true}
        // Set the width to 50%
        style={{ top: 150 }}
        onCancel={closeCreateRouteModal}
        visible={showScheduleRoutes}
        footer={null} // Remove the default footer
      >
          <div>
            <Row justify="space-between" className="marginBottom15">
              <Col xs={ 6 }>
                <div style={ { fontWeight: 700 } }>Select Date:</div>
              </Col>
              <Col xs={ 12 }>
                <DatePicker onChange={ setSelectedDate } style={ { width: "100%" } } format={ AppConfig.dateFormat } disabledDate={ (current) => current && current < moment().subtract(1, "day") } />
              </Col>
            </Row>
            <Row justify="space-between" className="marginBottom15">
              <Col xs={ 6 }>
                <div style={ { fontWeight: 650 } }>Driver:</div>
              </Col>
              <Col xs={ 12 }>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  onChange={ (value) => setSelectedDriverId(value) }
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={ selectedDriverId || undefined}
                  placeholder="Select Driver"
                >
                  {driversData.map((driver) => (
                    <Select.Option key={driver.id} value={driver.id}>
                      {getDriverFullDisplayName(driver)}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle" gutter={ 8 }>
              <Col
                className="alignCenter marginTop3"
                style={{ marginTop: 10 }}
              >
                <Button
                  type="danger"
                  icon="close"
                  size="small"
                  onClick={ closeCreateRouteModal }
                >
                  Cancel
                </Button>
              </Col>
              <Col
                className="alignCenter marginTop3"
                style={{ marginTop: 10 }}
              >
                <Button
                  type="primary"
                  icon="save"
                  size="small"
                  onClick={ handleInstantRouteCreation }
                  loading={ isCreatingRoute }
                >
                  Save
                </Button>{" "}
              </Col>
            </Row>
          </div>
      </BaseModal>
    );
  };

  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    // const rows = templatesData.filter((row) => selectedRowKeys.includes(row.id));
    // setSelectedRowId(selectedRowKeys.length > 0 ? selectedRows[0].id : null);
    setSelectedRowId(
      selectedRowKeys.length > 0 ? selectedRows.map((row) => row.id) : null
    );

    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
    if (currentStat === "ORDERS") {
      setShowCreateOrderButton(selectedRowKeys.length > 0);
      setShowCancelButton(selectedRowKeys.length > 0);
    }
  };

  const validatePayload = (payload) => {
    const errors = [];
    if (!payload.instant_driver_id) {
      errors.push("Please select a driver");
    }
    if (!payload.route_schedule_date) {
      errors.push("Please select a date");
    }
    return { errors };
  };

  const [isCreatingRoute, setIsCreatingRoute] = useState(false);
  const handleInstantRouteCreation = async () => {
    setIsCreatingRoute(true);
    const payload = {
      id: activeRouteId,
      instant_driver_id: selectedDriverId,
      route_schedule_date: selectedDate,
    };

    const {errors } = validatePayload(payload);
    if (errors.length > 0) {
      renderAlertMessage(errors[0]);
      setIsCreatingRoute(false);
      return;
    }

    RecurringRouteApi.instantRouteCreation(payload).then((result) => {
      if (result.success) {
        const { data: { recurring_route, routes } } = result;
        alertMessage("Route Created Successfully", "success", 10);
        closeCreateRouteModal()
        showCreatedInfo(routes[0] , recurring_route.recurring_route_activity)
      } else {
        renderAlertMessage(result.errors)
      }
    }).finally(() => { setIsCreatingRoute(false); getDrivers(); });
  };


  const saveSelectedRowAndDate = async () => {
    const data = {
      template_ids: !_.isEmpty(selectedRowId) ? selectedRowId : clickedRecordId,
      date: selectedDate,
    };
        setInProgress(true);
    try {
      const result = await saveSettingsTemplateOrder(data);
      if (result.success) {
        const orders = result.orders.orders;
        setConvertedOrder(orders);
        const orderNumbers = orders.map((order) => order.customer_order_number);
        const templateNames = orders.map((order) => order.template_name);
        setOrderNumbers(orderNumbers);
        setTemplateNames(templateNames);
        setInProgress(false);
      } else {
        renderAlertMessage(result.errors)
        setInProgress(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setInProgress(false);
    }
  };

  const getAccounts = (whId) => {
    const {warehouseFilter} = props;
    const accountsData =  warehouseFilter.findMatchingAccounts(whId)
     setAccounts(accountsData);
  }

  const refreshAccounts = (whId) => {
    getAccounts(whId);
  };

  const handleRouteTempalteDeletion = (id) => {
    setIsLoading(true);
    RecurringRouteApi.delete(id)
      .then((result) => {
        if (result.success) {
          alertMessage("Deleted Successfully", "success", 5);
          const newRoutesListData = routesListData.filter(
            (route) => route.id !== id
          );
          setRoutesListData(newRoutesListData);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const shouldDisableButton =
    currentStat === "ROUTES" ||
    (selectedRowKeys.length === 0 && currentStat === "ORDERS");

  const handlePendingRoutesChange = (value) => {
    if (value === "all") {
      tableOptions.pagination.current = 1;
      getRouteTemplates();
    } else {
      tableOptions.pagination.current = 1;
      getPendingRouteTemplates();
    }
  };

  const handleEditTemplate = (record) => {
    setActiveRecordId(record.id);
    setActiveRecord(record);
    setShowTemplateForm(true);
    setActiveKey("details");
  }

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header" type="flex" justify="space-between">
          <Col>{I18n.t("menu.recurring_operations")}</Col>
          <Col className="page-actions">
            <Row type="flex" justify="space-between" gutter={8}>
              <Col>
                <Button
                  type="primary"
                  icon="plus"
                  size="small"
                  onClick={() => {
                    setActiveRecordId(null);
                    setActiveRecord(null);
                    setShowTemplateForm(true);
                    setActiveKey("details");
                  }}
                >
                  Create Template Order
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon="plus"
                  size="small"
                  onClick={() => {
                    setActiveRecordId(null);
                    setActiveRecord(null);
                    setShowTemplateRouteCreationForm(true);
                    setActiveKey("route_details");
                  }}
                >
                  Create Template Route
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <TemplateStats
          currentStat={currentStat}
          onStatChange={handleOnStatChange}
          recurringRoutesCount={recurringRoutesCount}
          recurringOrdersCount={ recurringOrdersCount }
        />
        <Row className="page-content">
          <Col xs={24} style={{marginTop: 10}}>
            <Row
              style={{ float: "right" }}
              type="flex"
              justify="space-between"
              gutter={8}
              align="middle"
            >
              {/* {!isInOrderTab() && (
              <Col>
                <Select
                  defaultValue="all"
                  style={{ width: 120 }}
                  onChange={handlePendingRoutesChange}
                  size="small"
                >
                  <Option value="all">All Routes</Option>
                  <Option value="pending">Pending Routes</Option>
                </Select>
              </Col>
              )} */}
              <Col>
                <RecordsPerPage
                  onChange={recordChange}
                  onSearch={onSearch}
                  value={recordsPerPage}
                  defaultValue={recordsPerPage}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="templatesWrapper">
            {isInOrderTab() ? (
              <TemplatesList
                data={templatesData}
                scroll={{ y: "calc(100vh - 310px)" }}
                displayConfiguration={displayCOnfiguration}
                screen_from={"templates"}
                isLoading={isLoading}
                navigateToAccount={navigateToAccount}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, filter, sorter, currentTable)
                }
                handleEdit={(record) => {
                 handleEditTemplate(record)
                }}
                handleDelete={(id) => {
                  handleTempalteDeletion(id);
                }}
                rowSelection={{
                  selectedRowKeys,
                  onChange: handleRowSelectionChange,
                }}
                createTemplateOrder={createTemplateOrder}
                organizationSettings={organizationSettings}
                pagination={{ ...paginationConfig }}
                handledateModal={ handledateModal }
              />
            ) : isInTemplateRouteTab() ? (
              <RecurringRoutesList
                data={routesListData}
                drivers={driversData}
                scroll={{ y: "calc(100vh - 260px)" }}
                displayConfiguration={displayCOnfiguration}
                screen_from={"templates"}
                isLoading={isLoading}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, sorter, currentTable)
                }
                handleEdit={(record) => {
                  setActiveRecordId(record.id);
                  setActiveRecord(record);
                  setActiveKey("route_details");
                  setShowTemplateRouteCreationForm(true);
                }}
                handleDelete={(id) => {
                  handleRouteTempalteDeletion(id);
                }}
                // rowSelection={{
                //   selectedRowKeys,
                //   onChange: handleRowSelectionChange,
                // }}
                organizationSettings={organizationSettings}
                pagination={{ ...paginationConfig }}
                  handleRouteCreation={ handleRouteCreation }
              />
            ) : (
              <PendingRecurringRoutesList
                data={routesListData}
                drivers={driversData}
                scroll={{ y: "calc(100vh - 310px)" }}
                displayConfiguration={displayCOnfiguration}
                screen_from={"templates"}
                isLoading={isLoading}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, sorter, currentTable)
                }
                handleEdit={(record) => {
                  setActiveRecordId(record.id);
                  setActiveRecord(record);
                  setActiveKey("route_details");
                  setShowTemplateRouteCreationForm(true);
                }}
                handleDelete={(id) => {
                  handleRouteTempalteDeletion(id);
                }}
                rowSelection={{
                  selectedRowKeys,
                  onChange: handleRowSelectionChange,
                }}
                organizationSettings={organizationSettings}
                pagination={{ ...paginationConfig }}
                    handleRouteCreation={ handleRouteCreation }
              />
            )}
          </Col>
          <Row className="bottom_fixed">
            {selectedRowKeys.length > 0 && (
              <Col xs={24} className="alignCenter">
                <Button
                  type="danger"
                  className="floating-Button"
                  onClick={handleCancel}
                  icon="close"
                >
                  Clear
                </Button>{" "}
                &nbsp;&nbsp;
                {isInOrderTab() && (
                  <Button
                    type="primary"
                    icon="shopping-cart"
                    className="floating-Button"
                    onClick={handledateModal}
                  >
                    {`Convert to Order${selectedRowKeys.length > 1 ? "s" : ""}`}
                  </Button>
                )}{" "}
                {isInOrderTab() ? (
                  <Button
                    type="primary"
                    icon="branches"
                    className="floating-Button"
                    onClick={() => {
                      setShowTemplateRouteCreationForm(true);
                      setActiveKey("route_details");
                    }}
                  >
                    Create Template Route
                  </Button>
                ) : (
                    // <Button
                    //   type="primary"
                    //   icon="branches"
                    //   className="floating-Button"
                    //   onClick={() => {}}
                    // >
                    //   {`Create Route${selectedRowKeys.length > 1 ? "s" : ""}`}
                    // </Button>
                    null
                )}
              </Col>
            )}
          </Row>

          {showDateWindow && renderDatewindow()}

          {showScheduleRoutes && renderScheduleQuotes()}

          {editForm && (
            <Drawer
              placement="right"
              closable={false}
              maskClosable={false}
              onClose={() => setEditForm(false)}
              visible={editForm}
              width="90%"
            >
              <EditOrderForm
                customerDetails={orderObject}
                customerOrderId={orderObject.id}
                preferences={[]}
                onCancel={() => setEditForm(false)}
                handleSuccess={handleSaveSuccess}
                isNew={false}
              />
            </Drawer>
          )}

          <Drawer
            placement="right"
            closable={true}
            maskClosable={false}
            onClose={() => {
              setShowTemplateRouteCreationForm(false);
              getRouteTemplates();
            }}
            visible={showTemplateRouteCreationForm}
            destroyOnClose={true}
            width="80%"
            className="templateRouteCreationFormDrawer"
          >
            <div className="templateBlock">
              <Tabs
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
                type="card"
              >
                <TabPane
                  tab={`${
                    activeRecord?.id
                      ? `Edit Template Route ${
                          activeRecord.route_name
                            ? `(${activeRecord.route_name})`
                            : ""
                        }`
                      : "Create Template Route"
                  }`}
                  key="route_details"
                >
                  <TemplateRouteCreationForm
                    selectedTemplates={selectedRows}
                    accounts={accounts}
                    warehouses={warehouses}
                    activeRecord={activeRecord}
                    drivers={driversData}
                    refreshDrivers={getDrivers}
                    organization_id={userStore.getStateValue("selectedOrg")}
                    warehouseId={
                      Array.isArray(props.warehouseFilter.selectedWarehouses)
                        ? props.warehouseFilter.selectedWarehouses[0]
                        : props.warehouseFilter.selectedWarehouses
                    }
                    onCancel={() => {
                      setShowTemplateRouteCreationForm(false);
                      getRouteTemplates();
                    }}
                    onRouteCreation={(data) => {
                      setShowTemplateRouteCreationForm(false);
                      getRouteTemplates();
                      handleOnStatChange("ROUTES");
                    }}
                  />
                </TabPane>
                {!isEmpty(activeRecordId) && (
                  <TabPane tab={I18n.t("templates.logs")} key="logs">
                    <TemplateActivityLogs
                      activeRecordId={activeRecordId}
                      currentStat={currentStat}
                      activeRecord={activeRecord}
                      isMilitaryTime={isMilitaryTime}
                    />
                  </TabPane>
                )}
              </Tabs>
            </div>
          </Drawer>

          {showTemplateForm && (
            <Drawer
              placement="right"
              closable={activeKey !== "details"}
              maskClosable={false}
              onClose={() => {
                setShowTemplateForm(false);
                setActiveKey("details");
              }}
              visible={showTemplateForm}
              width="92%"
            >
              <div className="templateBlock">
                <Tabs
                  activeKey={activeKey}
                  onChange={(key) => setActiveKey(key)}
                  type="card"
                >
                  <TabPane
                    tab={`${
                      activeRecord?.id
                        ? `Edit ${I18n.t("general.details")} ${
                            activeRecord.template_name
                              ? `(${activeRecord.template_name})`
                              : ""
                          }`
                        : "Create Template"
                    }`}
                    key="details"
                  >
                    <TemplateForm
                      activeRecordId={activeRecordId}
                      accounts={accounts}
                      closeTemplateForm={closeTemplateForm}
                      drivers={driversData}
                      handleSuccess={() => {}}
                      isFetchingDrivers={isFetchingDrivers}
                      isFetchingTrucks={isFetchingTrucks}
                      isGoogleApiMounted={isGoogleApiMounted}
                      trucks={trucksData}
                      warehouses={warehouses}
                      refreshAccounts={refreshAccounts}
                      warehouseFilter={props.warehouseFilter}
                    />
                  </TabPane>
                  {!isEmpty(activeRecordId) && (
                    
                    <TabPane
                      tab={I18n.t("templates.scheduling")}
                      key="scheduling"
                    >
                      <TemplateCronConfig
                        activeRecordId={activeRecordId}
                        api={TemplatesApi}
                      />
                    </TabPane>
                  )}
                   {!isEmpty(activeRecordId) && (
                    <TabPane
                      tab={I18n.t("general.accessorial")}
                      key="accessorial"
                    >
                      <TemplateAccessorialForm
                        activeRecordId={activeRecordId}
                        activeRecord={activeRecord}
                        api={TemplatesApi}
                        drivers={driversData}
                        activeKey={activeKey}
                      />
                    </TabPane>
                   
                  )}

                  <TabPane tab={I18n.t("templates.logs")} key="logs">
                    <TemplateActivityLogs
                      activeRecordId={activeRecordId}
                      currentStat={currentStat}
                      isMilitaryTime={isMilitaryTime}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Drawer>
          )}
        </Row>
      </div>
    </div>
  );
}

export const TemplatesComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const displayConfiguration = useContext(DisplaySettingsContext);
  return (
    <Templates
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      displayConfiguration={displayConfiguration}
      {...props}
    />
  );
});

export default TemplatesComponent;

// export default Templates;
// export default withRouter(Templates);
