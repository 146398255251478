import { Call, ApiUrl, PageParams, ParseGeneralResponse } from './ApiUtils';

export const DispatchesApi = {
    printManifestFile: (navRouteId, includeBol, includeItems,includeManifest) => {
        const url = ApiUrl(`v2/dispatch/send_manifest_pdf?nav_route_id=${navRouteId}&include_bol=${includeBol}&include_items=${includeItems}&include_manifest=${includeManifest}`);
        return Call('get', url, {}, ParseGeneralResponse, {
            responseDataKeys: { manifest_file: 'manifest_file' },
        });
    }    
}
