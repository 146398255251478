import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Map from './Map';
import { alertMessage } from '../../common/Common';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneName: props.currentZone.name ? props.currentZone.name : '',
      geoFenceGeometryData: '',
      onDraw: !props.currentZone.id,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleReDraw = this.handleReDraw.bind(this);
  }

  componentDidMount() {}

  handleChange(e) {
    this.setState({
      zoneName: e.target.value,
    });
  }

  handleOnSave() {
    if (((this.state.geoFenceGeometryData || this.props.currentZone.id) && this.state.zoneName)) {
      this.props.handleOnSave({
        geoFenceGeometryData: this.state.onDraw ? this.state.geoFenceGeometryData : this.props.currentZone.location,
        ...this.props,
        zoneName: this.state.zoneName,
      });
    } else {
      alertMessage('Please fill all details');
    }
  }

  handleSave(data) {
    const { geoFenceGeometryData } = data;
    this.setState({
      geoFenceGeometryData,
    });
  }
  handleReDraw() {
    this.setState({
      onDraw: !this.state.onDraw,
    });
  }

  render() {
    return (
      <Fragment>
        <Map
          handleSave={this.handleSave}
          onDraw={this.state.onDraw}
          {...this.props}
          zoneName={this.state.zoneName}
          handleChange={this.handleChange}
          handleReDraw={this.handleReDraw}
          handleOnSave={this.handleOnSave}
        />
      </Fragment>
    );
  }
}
Main.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  currentZone: PropTypes.shape().isRequired,
};
export default Main;
