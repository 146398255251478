import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  DatePicker,
  Popconfirm,
  Row,
  Col,
  Form,
  Select,
  Input,
} from "antd";
import moment from "moment";
import { FormItem, TextArea } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { DriverSettlementApi } from "../../api/DriverSettlementApi";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage } from "../../common/Common";
import BaseSelect, {
  AccountSelect,
  DriverSelect,
} from "../common/SelectDropdowns/BaseSelect";

const AdjComponent = ({
  reports,
  currentOrg,
  filterPlaceHolder,
  handleApproveSuccess,
  setReportLoading,
  accounts,
  drivers,
  form,
  warehouseFilter = {},
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const decimalPoints = window.localStorage.getItem("round_off_decimals");
  const zeroValue = Number((0).toFixed(decimalPoints));

  const initialValues = () => {
    const { selectedWarehouses } = warehouseFilter;
    const formValues = {
      account: accounts?.length ? accounts[0] : "",
      wh_location_id: _.isString(selectedWarehouses)
        ? selectedWarehouses
        : selectedWarehouses?.lemgth
        ? selectedWarehouses[0]
        : "",
      adjustment_date: moment(),
      driver_id: drivers?.length ? drivers[0].id : "",
      amount: zeroValue,
    };
    form.setFieldsValue({ ...formValues });
    setFormData({ ...formValues });
  };

  const handleButtonClick = () => {
    initialValues();
    setModalVisible(true);
  };

  const handleCancel = () => {
    // Handle canceling action
    form.resetFields();
    setModalVisible(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        setIsLoading(true);
        const payload = {
          ...values,
          organization_id: currentOrg?.id || "",
          adjustment_date: values?.adjustment_date
            ? moment(values.adjustment_date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        };

        setReportLoading(true);
        try {
          DriverSettlementApi.saveAdjustments(payload).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"));
              handleCancel();
              handleApproveSuccess();
              setIsLoading(false);
            } else {
              renderAlertMessage(result.errors, "error", 10);
              setIsLoading(false);
            }
          });
        } catch (error) {
          console.error("API call error:", error);
        } finally {
        }
        props.onSuccess(formData);
      }
    });
  };

  const handleFormChange = (element, value) => {
    setFormData({
      ...formData,
      [element]: value,
    });
  };

  const onFinish = (values) => {
    console.log("Form values:", values);
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
    });
    // You can handle form submission logic here
  };

  const handleWarehouseChange = () => {
    form.setFieldsValue({ account: undefined });
  };

  const getFields = () => {
    const count = 10;
    const { getFieldDecorator } = form;
    const warehouses = warehouseFilter?.warehouses || [];
    const size = "small";
    return (
      <Form onSubmit={onFinish}>
        <Form.Item label="Warehouse">
          {getFieldDecorator("wh_location_id", {
            rules: [{ required: true, message: "Please select warehouse" }],
          })(
            <BaseSelect
              data={warehouses || []}
              onChange={(e) => {
                handleFormChange("wh_location_id", e);
              }}
              selectKey={"id"}
              selectValue={"id"}
              selectedValue={"name"}
              value={formData["wh_location_id"]}
              size={size}
              className="width100Per"
              showSearch={true}
            />
          )}
        </Form.Item>
        <Row type="flex" gutter={16}>
          <Col xs={12}>
            <Form.Item label={I18n.t("account.account")}>
              {getFieldDecorator("account_id", {
                initialValue: formData.account_id
                  ? formData.account_id
                  : accounts.length > 0
                  ? accounts[0].id
                  : "",
                rules: [
                  {
                    required: true,
                    message: `Enter ${I18n.t("account.account")}`,
                  },
                ],
              })(
                <AccountSelect
                  data={accounts}
                  onChange={(e) => {
                    handleFormChange("account_id", e);
                  }}
                  selectKey={"id"}
                  selectValue={"id"}
                  selectedValue={"name"}
                  size={size}
                  className="width100Per"
                  showSearch={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={I18n.t("general.Driver")}>
              {getFieldDecorator("driver_id", {
                initialValue: formData.driver_id
                  ? formData.driver_id
                  : drivers.length > 0
                  ? drivers[0].id
                  : "",
                rules: [
                  {
                    required: true,
                    message: `Enter ${I18n.t("general.Driver")}`,
                  },
                ],
              })(
                <DriverSelect
                  showSearch
                  data={drivers}
                  placeholder={I18n.t("general.Driver")}
                  style={{ width: "200px" }}
                  value={formData["driver_id"]}
                  onChange={(e) => handleFormChange("driver_id", e)}
                  dropdownMatchSelectWidth={false}
                  defaultOption="---All---"
                  size="small"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" gutter={16}>
          <Col xs={12}>
            <Form.Item label="Adjustment Date">
              {getFieldDecorator("adjustment_date")(
                <DatePicker 
                format={AppConfig.dateFormat} 
                allowClear={false} 
                disabledDate={
                  current => current && (
                    moment(moment(current).format('YYYY-MM-DD')).isAfter(moment(filterPlaceHolder.toDate).format('YYYY-MM-DD')) ||
                    moment(moment(current).format('YYYY-MM-DD')).isBefore(moment(filterPlaceHolder.fromDate).format('YYYY-MM-DD')) 
                  )
                }
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="Amount">
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: `Enter ${I18n.t("general.amount")}`,
                  },
                ],
              })(<Input size="small" />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Description">
          {getFieldDecorator("description", {
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t("general.description")}`,
              },
            ],
          })(<TextArea size="small" rows={2}/>)}
        </Form.Item>
        <Form.Item>
          <div className="alignRight">
            <Popconfirm
              placement="topRight"
              title={I18n.t("messages.cancel_confirm")}
              onConfirm={handleCancel}
              okText="Yes"
              cancelText="No"
              size="small"
            >
              <Button
                style={{ marginRight: 8 }}
                type="danger"
                size="small"
                icon="close"
              >
                Cancel
              </Button>
            </Popconfirm>

            <Button type="primary" htmlType="submit" size="small" icon="plus" loading={isLoading} disabled={isLoading}>
              {I18n.t("general.add")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div>
      <Button
        size="small"
        type="primary"
        icon="file-done"
        onClick={handleButtonClick}
      >
        {I18n.t("adjustments.add")}
      </Button>
      <Modal
        title={I18n.t("adjustments.add")}
        visible={modalVisible}
        size="small"
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          className="ant-advanced-search-form customForm"
          onSubmit={handleSave}
        >
          <Row gutter={24} className="fontSize12">
            {getFields()}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

const SettlementAdjustments = Form.create({ name: "my_component" })(
  AdjComponent
);

export default SettlementAdjustments;
