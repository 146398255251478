/* eslint-disable react/no-unknown-property */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
// import moment_tz from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import FormErrors from '../../components/common/FormErrors';
import { Col, Row, Spin, Icon, Card } from '../../common/UIComponents';
import { doFormate, formatStopTime, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import AddressInfo from '../common/AddressInfo';
import StopsPrintList from '../../components/dispatches/StopsPrintList';
import OrdersList from './OrdersList';
import AppConfig from '../../config/AppConfig';
import { getExecutionTime } from '../../helpers/routes';
import { formatByTimeConfig, metersToOtherMeasurements, secondsToHms } from '../../helpers/common';
import { checkElementValueExists, getOrderTypeLabel } from '../../helpers/orders';
import TypeOfOrder from "../orders/TypeOfOrder";

class DownloadContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      stops: this.props.stops || [],
      errors: [],
      routeId: this.props.currentRoute.id ? this.props.currentRoute.id : '',
      routeStatus: '',
      currentRoute: this.props.currentRoute || {},
      optimize_error_status: true,
      total_time: 0,
      total_distance: 0,
      driversInfo: {
        primary: {
          driverId: '',
          allotedDriver: {},
          selectedDriverId: '',
        },
        secondary: {
          driverId: '',
          allotedDriver: {},
          selectedDriverId: '',
        },
      },
      organizationSettings: {},
      isMilitaryTime: false,
      orderIds: [],

    };
  }

  UNSAFE_componentWillMount() {
    // this.getUsers();
    // if (this.state.showOdrersByDefault) {
    //   this.getRouteOrders();
    // }
    // this.setData();
  }

  componentDidMount() {
    this.getRouteInfo();
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    setTimeout(() => {
      this.sortOrders();
    }, 100);
  }


  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }

  // getStatusText = (location_type) =>  {
  //   if (location_type === 'RC') {
  //     return 'Recovery';
  //   } else if (location_type === 'RL') {
  //     return 'Release';
  //   } else if (location_type === 'LH') {
  //     return 'Linehaul';
  //   } else {
  //     return "Pickup/Delivery";
  //   }
  // }

  sortOrders = () => {
    let ord_Ids = [];
    this.state.stops.map((stop) =>{
      if(stop.location_type !== 'WH'){
        ord_Ids.push(stop.customer_order_ids)
      }
    })
    this.setState({orderIds: ord_Ids.flat()});
  }

  getStatusText = (location_type) =>  {
    if (location_type === 'D') {
      return 'Deliveries';
    } else if (['R', 'P'].includes(location_type)) {
      return 'Pickups';
    } else {
      return "Pickup & Deliveries";
    }
  }

  getRouteInfo = (callRearrange) => {
    if (this.state.routeId) {
      const { currentRoute } = this.state;
      const route_stops = [];
      currentRoute.stops.forEach((route_stop) => {
        route_stops.push(route_stop.stop_details);
      });
      const sortedRoutes = _.sortBy(route_stops, [function (o) { return o.stop_order_sequence; }]);
      if (sortedRoutes.length > 0) {
        sortedRoutes[sortedRoutes.length - 1].completion_class = 'inCompletedRoute';
      }
      const { interconnections } = currentRoute;
      sortedRoutes.forEach((stop, index) => {
        if (index > 0) {
          if (stop.status === 'COMPLETED') {
            sortedRoutes[index - 1].completion_class = 'completedRoute';
          } else {
            sortedRoutes[index - 1].completion_class = 'inCompletedRoute';
          }
        }
        sortedRoutes[index].stop_number = index + 1;
        const contact_details = stop.contact_details ? stop.contact_details : {};
        sortedRoutes[index].formatted_stop_name = this.formatStopName(contact_details, stop.location, index + 1);
        const interconnection_index = _.findIndex(interconnections, ['fromWaypoint', stop.id]);
        if (interconnection_index >= 0) {
          sortedRoutes[index].interconnection_status = true;
          sortedRoutes[index].distance = interconnections[interconnection_index].distance ? metersToOtherMeasurements(interconnections[interconnection_index].distance, 'miles') : 'NA';
          sortedRoutes[index].time = interconnections[interconnection_index].time ? secondsToHms(interconnections[interconnection_index].time, true, true, false) : 0;
          sortedRoutes[index].waiting = interconnections[interconnection_index].waiting ? secondsToHms(interconnections[interconnection_index].waiting, true, true, false) : '-';
          sortedRoutes[index].rest = interconnections[interconnection_index].rest ? secondsToHms(interconnections[interconnection_index].rest, true, true, false) : '-';
        } else {
          sortedRoutes[index].distance = 'NA';
          sortedRoutes[index].time = 'NA';
          sortedRoutes[index].waiting = 'NA';
          sortedRoutes[index].rest = 'NA';
          sortedRoutes[index].interconnection_status = false;
        }
      });
      let totalExecTime = getExecutionTime({stops: [...sortedRoutes]});
      totalExecTime = totalExecTime ? `${totalExecTime} Approx.` : "NA";
      this.setState({
        inProgress: false,
        stops: sortedRoutes,
        routeStatus: currentRoute.nav_route_status,
        total_time: currentRoute.total_time ? currentRoute.total_time : 0,
        total_distance: currentRoute.total_distance ? currentRoute.total_distance : 0,
        driversInfo: {
          primary: {
            driverId: currentRoute.primary_driver ? currentRoute.primary_driver.id : '',
            allotedDriver: currentRoute.primary_driver ? currentRoute.primary_driver : {},
            selectedDriverId: '',
          },
          secondary: {
            driverId: currentRoute.secondary_driver ? currentRoute.secondary_driver.id : '',
            allotedDriver: currentRoute.secondary_driver ? currentRoute.secondary_driver : {},
            selectedDriverId: '',
          },
        },
        totalExecTime,

      });
    }
  };


  formatStopName = (contact_details, location, stop_number) => {
    const name = contact_details && contact_details.contact_name ? contact_details.contact_name : `Stop ${stop_number}`;
    let city_state = location && location.l_address ? `${location.l_address.city}, ${location.l_address.state}` : '';
    if (city_state) {
      city_state = city_state.replace(/(^,)|(,$)/g, '');
      city_state = ` (${city_state})`;
    }
    return name + city_state;
  }

  renderMeasurements = (order) => {
    return(
      <Row>
        <Col>{I18n.t('order.qty')}: {order.items_count ? order.items_count : order.quantity ? order.quantity : order.pieces ? order.pieces : 0}
        <span style={{ margin: '2px 5px'}}>/</span>PLTS: {!isEmpty(order.pallets) ? order.pallets : 'NA'} <span style={{ margin: '2px 5px'}}>/</span>
        {I18n.t('order.weight_short_form')}: {!isEmpty(order.weight) ? `${order.weight} ${AppConfig.weight_unit}` : 'NA'}</Col>
      </Row>
    )
  }

  render() {
    const { currentRoute, totalExecTime } = this.state;
    let sec_hrs = secondsToHms(this.state.total_time, true, false, false);
    sec_hrs = sec_hrs ? `${sec_hrs}(Approx.)` : 'NA';
    const time_distance = this.state.total_distance ? `${(parseFloat(this.state.total_distance / 1609)).toFixed(2)} Miles` : 'NA';
    const {isMilitaryTime} = this.state;
    const routeType = currentRoute.route_type;

    const getConvertedOrderType = (orderType) => {
      // if (orderType === "TD" || orderType === "MD" || orderType === "TR" || orderType === "D")
      // if(orderType.includes(["TD","MD","TR","D"]))
      if (["TD", "D"].includes(orderType)) {
        return "D";
      } else if (["TR", "R", "P"].includes(orderType)) {
        return "P";
      } else if (["T"].includes(orderType)) {
        return "T";
      } else if (["LH"].includes(orderType)) {
        return "LH";
      } else if (["RL"].includes(orderType)) {
        return "RL";
      } else if (["RC"].includes(orderType)) {
        return "RC";
      }else {
        return "";
      }
    };
    return (
      <div className='content-outer'>
        <div className='content routes-tasks' style={{  textAlign: 'center' }}> { /* backgroundColor: '#f0f2f5' */ }
          <Spin spinning={this.state.inProgress} delay={1000} >
            <table width="100%" align='center'>
              <tr>
                <td width="5%" />
                <td>
                  <div style={{ backgroundColor: '#FFF', padding: 5 }}>
                    <div style={{ width: '49.5%', float: 'left', textAlign: 'left' }} >
                      <span className="textBold">{I18n.t('general.routeName')}</span>:&nbsp;&nbsp;{currentRoute.nav_route_name}
                    </div>
                    <div style={{ width: '49.5', textAlign: 'right' }} >
                      <span className="textBold">{I18n.t('general.date')}</span>:&nbsp;&nbsp;{currentRoute.r_scheduled_start_datetime ? moment(currentRoute.r_scheduled_start_datetime).format('dddd Do, MMM YYYY') : 'NA'}
                    </div>
                    <div style={{ width: '49.5', float: 'left', textAlign: 'center' }} >
                      <span className="textBold">{I18n.t('general.Driver')}</span>:&nbsp;&nbsp;{this.state.driversInfo.primary && this.state.driversInfo.primary.allotedDriver ? `${this.state.driversInfo.primary.allotedDriver.first_name} ${this.state.driversInfo.primary.allotedDriver.last_name}` : 'NA'}
                    </div>

                    <div style={{ width: '49.5', textAlign: 'right' }} >
                      <span className="textBold"> {I18n.t('general.travel_distance')}</span>:&nbsp;&nbsp;{time_distance} {totalExecTime ? `(${totalExecTime})` : ''}
                    </div>
                    <div>
                      <hr />
                      {

                        this.state.stops.length > 0 &&
                        <div className="padding5">
                          {
                            this.state.stops.map((stop, index) => {
                              const scheduled_start_date_time_with_tz = stop.scheduled_start_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.scheduled_start_date_time_with_tz) , isMilitaryTime ,'MMM Do HH:mm' , 'MMM Do hh:mm A') : 'NA';
                              const scheduled_end_date_time_with_tz = stop.scheduled_end_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.scheduled_end_date_time_with_tz) , isMilitaryTime ,'MMM Do HH:mm' , 'MMM Do hh:mm A') : 'NA';
                              const estimated_arrival_date_time_with_tz = stop.estimated_arrival_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.estimated_arrival_date_time_with_tz) , isMilitaryTime ,'HH:mm' , 'hh:mm A') : 'NA';
                              const estimated_departure_date_time_with_tz = stop.estimated_departure_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.estimated_departure_date_time_with_tz) , isMilitaryTime ,'HH:mm' , 'hh:mm A') : 'NA';

                              const service_duration = stop.service_duration ? secondsToHms(parseInt(stop.service_duration), true, true, false) : 0;
                              const isShowScheduled = scheduled_start_date_time_with_tz !== 'NA' || scheduled_end_date_time_with_tz !== 'NA';
                              const isShowEstimated = estimated_arrival_date_time_with_tz !== 'NA' || estimated_departure_date_time_with_tz !== 'NA';
                              const timeZone = stop.stop_tz_short_name;
                              const contact_details = stop.contact_details ? stop.contact_details : {};
                              const customerPhoneOne = doFormate(contact_details.contact_phone_number_1);
                              const customerPhoneTwo = doFormate(contact_details.contact_phone_number_2);
                              const stopOrderIds = stop.stop_order_sequence === 0 ? this.state.orderIds : stop.customer_order_ids || [];
                              const stopRecoveries = stop.recovery_ids || [];
                              const stopReleases = stop.release_ids || [];
                              const stopLinehaulIds = stop.linehaul_ids || [];
                              const stopType = stop.location_type;
                              const stopLocationIds = stop?.location_ids ? stop.location_ids : [];
                              let ordersList = [];

                              if(["RC", "WH"].includes(stopType)) {
                                stopRecoveries.map((id) => {
                                  const order = currentRoute.recoveries.find(rec => id === rec.id);
                                  if(!isEmpty(order)) {
                                    ordersList = [...ordersList, {...order, stop_type: 'Recovery', process_type: 'RC', operation_type: stopType === 'WH' ? 'D' : 'P'}];
                                  }
                                });
                              }
                              if(["RL", "WH"].includes(stopType)) {
                                stopReleases.map((id) => {
                                  const order = currentRoute.releases.find(rec => id === rec.id);
                                  if(!isEmpty(order)) {
                                    ordersList = [...ordersList, {...order, stop_type: 'Release', process_type: 'RL', operation_type: stopType === 'WH' ? 'P' : 'D'}];
                                  }
                                });
                              }
                              if(["LH", "WH"].includes(stopType)) {
                                stopLinehaulIds.map((id) => {
                                  const order = currentRoute.linehauls.find(rec => id === rec.id);
                                  if(!isEmpty(order)) {
                                    const orderLocations = order.order_locations ?  order.order_locations : [];
                                    const orderLocationIds =orderLocations.map(loc => loc.id)
                                    const stopOrderLoc = orderLocationIds.filter(loc => stopLocationIds.includes(loc))
                                    let currentLocation = {};
                                    if(stopOrderLoc?.length){
                                      const loc = _.find(orderLocations, { id: stopOrderLoc[0]})
                                      currentLocation = loc ? loc : {}
                                    }
                                    ordersList = [...ordersList, {...order, stop_type: 'Linehaul',  process_type: 'LH', operation_type: stopType === 'WH' ? 'P' : 'D',  company_name: currentLocation.company_name}];
                                  }
                                });
                              }
                              if(["CS", "WH"].includes(stopType)) {
                                stopOrderIds.map((id) => {
                                  const order = currentRoute.customer_orders.find(order => id === order.customer_order_id);
                                  if(!isEmpty(order)) {
                                    if(!["TR", "R", "P"].includes(order.type_of_order) || stop.stop_order_sequence !== 0){
                                      const orderLocations = order.order_locations ?  order.order_locations : [];
                                      const orderLocationIds = orderLocations.map(loc => loc.id)
                                      const stopOrderLoc = orderLocationIds.filter(loc => stopLocationIds.includes(loc))
                                      let currentLocation = {};
                                      if(stopOrderLoc?.length){
                                        const loc = _.find(orderLocations, { id: stopOrderLoc[0]})
                                        currentLocation = loc ? loc : {}
                                      }
                                      const op_type = getConvertedOrderType(order.type_of_order)
                                      ordersList = [...ordersList, {
                                        ...order,
                                        stop_type: 'Pickup / Delivery',
                                        process_type: order.type_of_order,
                                        operation_type: stopType === 'WH' ? (op_type === 'D' ? "P" : "D") : op_type,
                                        company_name: currentLocation.company_name
                                      }]
                                    }
                                  }
                                });
                              }
                              ordersList = _.compact(ordersList);
                              const operationTypes = _.uniq(ordersList.map(order => order.operation_type));
                              const isMultiStop = operationTypes.length > 1;
                              // const isMultiStop = stopType === 'WH' && (_.uniq(ordersList.map(order => order.stop_type))).length > 1;
                              const hasHawb = checkElementValueExists(ordersList, 'hawb');
                              const hasMawb = checkElementValueExists(ordersList, 'mawb');
                              const hillLogsiticsCode = "HILL"
                              const stopOrderTypes = stopType === 'CS' ? (_.uniq(ordersList.map(order => order.type_of_order))) : [];
                              const subItemStyle  = { paddingLeft: 5,border: '1px solid white',backgroundColor: '#cccccc',paddingTop:'3px',height:'35px' };
                              const itemValStyle  = { fontSize: 12 };

                              return (

                                <Fragment key={`timeline_fragment${stop.id}`}>
                                  <table width="100%" style={{ marginTop: 30, fontSize: 12 }}>
                                    <tr>
                                      <td width="46%" align="left" style={{ fontWeight: 'bold' }}>{stop.stop_number}.&nbsp;{stop.stop_number === 1 ? I18n.t('stop.starting_point') : (stop.stop_number === this.state.stops.length ? I18n.t('stop.ending_point') : stop.formatted_stop_name)}</td>
                                      <td width="20.67%" style={{fontWeight:"bold"}}>
                                        {/* { isMultiStop ?
                                           I18n.t('general.multi_stop') :
                                           (stopOrderTypes.length > 0 && AppConfig.orderTypes[stopOrderTypes[0]] ? `${AppConfig.orderTypes[stopOrderTypes[0]].label} Orders` : this.getStatusText(stopType))
                                        } */}
                                        {/* {
                                          isMultiStop ? this.getStatusText('P&D') : this.getStatusText(operationTypes[0])
                                        } */}
                                      </td>
                                      <td width="33.33%" align="right"><Icon type="phone" /> &nbsp;&nbsp;{`${customerPhoneOne} ${customerPhoneTwo !== 'NA' ? `,${customerPhoneTwo}` : ''}`}</td>
                                    </tr>
                                    <tr>
                                      <td align="left" >
                                        {stop.location && stop.location.l_address ? <AddressInfo address={stop.location.l_address} showIcon /> : ''}
                                      </td>
                                      <td />
                                      <td align="right">
                                        {
                                          (estimated_arrival_date_time_with_tz !== 'NA' || estimated_departure_date_time_with_tz !== 'NA') &&
                                          <Fragment>{I18n.t('routes.estimated_time')} : { isShowEstimated ? <Fragment>{formatStopTime(estimated_arrival_date_time_with_tz, estimated_departure_date_time_with_tz)}</Fragment> : 'NA' }</Fragment>
                                        }
                                      </td>
                                    </tr>
                                    {/* { !isEmpty(ordersList) && ordersList.length > 0 &&
                                    <tr>
                                      <td colSpan="2" >
                                        <table border="1" style={{ width: '100%' }} cellpadding="5px" cellspacong='0px'>
                                          <thead>
                                            <tr>
                                              <th style={{ paddingLeft: 5 }}>#</th>
                                              { hasMawb && <th style={{ paddingLeft: 5 }}>{I18n.t('general.mawb')}</th>}
                                              { hasHawb &&<th style={{ paddingLeft: 5 }}>{I18n.t('general.hawb')}</th>}
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('order.order_no')}</th>
                                              <th style={{ paddingLeft: 5 }}>{"Account"}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('order.qty')}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('general.pallets')}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('order.weight')}</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              ordersList.map((order, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  { hasMawb && <td>{order.mawb}</td>}
                                                  { hasHawb && <td>{order.hawb}</td>}
                                                  <td>{order.customer_order_number}</td>
                                                  <td>{ order.account_code }</td>
                                                  <td>{order.items_count ? order.items_count : (order.quantity ? order.quantity : '')}</td>
                                                  <td>{(!isEmpty(order.pallets) ? order.pallets : 'NA')}</td>
                                                  <td>{(!isEmpty(order.weight) ? `${order.weight} ${AppConfig.weight_unit}` : 'NA')}</td>
                                                </tr>
                                              ))
                                            }
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr> */}
                                    {
                                      !isEmpty(ordersList) && ordersList.length > 0 && (
                                        <tr>
                                          <td colSpan="3">
                                          {
                                            this.props.includeItems === true ?

                                          ordersList.reverse().map((order, index) => (
                                        <table key={index} border="1" paddingTop = "10" style={{ width: '100%',marginTop : "10px" }} cellpadding="5px" cellspacing='0px'>
                                          <thead>
                                            <tr>

                                              <th style={{ paddingLeft: 5 }}>#</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('order.order_no')}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('general.hawb')}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('general.mawb')}</th>
                                              <th style={{ paddingLeft: 5 }}>{'Reference'}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('general.company')}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t("general.order_type_short_form")}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('order.measurements')}</th>
                                              <th style={{ paddingLeft: 5 }}>{ "Account" }</th>
                                              {/* <th style={{ paddingLeft: 5 }}>{I18n.t('order.qty')}</th>
                                              <th style={{ paddingLeft: 5 }}>{I18n.t('general.pallets')}</th>
                                              <th style={{ paddingLeft: 5, width: '6%' }}>{I18n.t('order.weight')}</th> */}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td style={{ ...itemValStyle }}>{index + 1}</td>
                                              <td style={{ ...itemValStyle }}>{order.customer_order_number}</td>
                                              <td style={{ ...itemValStyle }}>{order.hawb}</td>
                                              <td style={{ ...itemValStyle }}>{order.mawb}</td>
                                              <td style={{ ...itemValStyle }}>{order.reference_1}</td>
                                              <td style={{ ...itemValStyle }}>{order.company_name}</td>
                                              <td style={{ ...itemValStyle }}>{getConvertedOrderType(order.process_type)}</td>
                                              <td style={{ ...itemValStyle, textAlign: "left" }}>
                                               {this.renderMeasurements(order)}
                                                
                                              </td>
                                              <td style={{ ...itemValStyle, textAlign: "left" }}>{order.account_code}</td>
                                              {/* <td>{order.items_count ? order.items_count : order.quantity ? order.quantity : order.pieces ? order.pieces : 0}</td>
                                              <td>{!isEmpty(order.pallets) ? order.pallets : 'NA'}</td>
                                              <td className='textRight'>{!isEmpty(order.weight) ? `${order.weight} ${AppConfig.weight_unit}` : 'NA'}</td> */}
                                            </tr>

                                            <tr style={{border : 'none'}}>
                                              <td colSpan={10}>
                                                <div style={{ padding: '15px 10px' }}>
                                                {order.item_details.length > 0 ? (
                                                  <>
                                                  <h3 style={{ paddingBottom: 10, color: "#3f6600", textAlign: "center", fontWeight: '500' }}>{"Items"}({(order.customer_order_number)})</h3>
                                                  <div style={{ margin: '0 -16px' }}>
                                                  <table   style={{ width: '100%'}} cellpadding="5px" cellspacing='0px'>
                                                          <thead>
                                                            <tr>
                                                              <th style={{...subItemStyle}}>{"Sno"}</th>
                                                              <th style={{...subItemStyle}}>{"Item Name"}</th>
                                                              <th style={{...subItemStyle}}>{"Pieces"}</th>
                                                              <th style={{...subItemStyle}}>{"Dim.wt"}</th>
                                                              <th style={{...subItemStyle}}>{"Model Number"}</th>
                                                              <th style={{...subItemStyle}}>{"Serial Number"}</th>
                                                              <th style={{...subItemStyle}}>{"Weight"}</th>
                                                              <th style={{...subItemStyle}}>{"Warehouse Dock"}</th>
                                                              <th style={{...subItemStyle}}>{"Dimensions(L*W*H)"}</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {order.item_details.map((item, itemIndex) => (
                                                              <tr key={itemIndex}>
                                                                <td>{itemIndex + 1}</td>
                                                                <td style={{textAlign:"left",fontSize:''}}>{item.item_name}</td>
                                                                <td>{item.pieces}</td>
                                                                <td>{item.dim_weight}</td>
                                                                <td style={{textAlign:"left"}}>{item.model_number}</td>
                                                                <td style={{textAlign:"left"}}>{item.serial_number}</td>
                                                                <td style={{textAlign:"left"}}>{`${item.weight} Lbs`}</td>
                                                                <td style={{textAlign:"left"}}>{item.wh_dock}</td>
                                                                <td>{`${item.item_length} * ${item.item_width} * ${item.item_height} ( Units : in )`}</td>
                                                              </tr>
                                                            ))}
                                                          </tbody>
                                                  </table>
                                                  </div>
                                                  </>
                                                  ) : ""}
                                                </div>
                                              </td>
                                            </tr>

                                          </tbody>

                                        </table>

                                      ))
                                      :
                                      <table border="1" style={ { width: '100%' } } cellpadding="5px" cellspacong='0px'>
                                              <thead>
                                                <tr>
                                                  <th style={ { paddingLeft: 5 } }>#</th>
                                                  <th style={ { paddingLeft: 5 } }>{ I18n.t('order.order_no') }</th>
                                                  <th style={ { paddingLeft: 5 } }>{ I18n.t('general.hawb') }</th>
                                                  <th style={ { paddingLeft: 5 } }>{ I18n.t('general.mawb') }</th>
                                                  <th style={ { paddingLeft: 5 } }>{"Reference"}</th>
                                                  <th style={{ paddingLeft: 5 }}>{I18n.t('general.company')}</th>
                                                  <th style={ { paddingLeft: 5  } }>{I18n.t("general.order_type_short_form")}</th>
                                                  <th style={{ paddingLeft: 5 }}>{I18n.t('order.measurements')}</th>
                                                  <th style={ { paddingLeft: 5 } }>{ "Account" }</th>
                                                  {/* <th style={ { paddingLeft: 5 } }>{ I18n.t('order.qty') }</th>
                                                  <th style={ { paddingLeft: 5 } }>{ I18n.t('general.pallets') }</th>
                                                  <th style={ { paddingLeft: 5, width: '6%' }}>{ I18n.t('order.weight') }</th> */}

                                                </tr>
                                              </thead>
                                              <tbody>

                                                {
                                                ordersList.reverse().map((order, index) => (
                                                  <tr key={ index }>
                                                    <td style={{ ...itemValStyle }}>{ index + 1 }</td>
                                                    <td style={{ ...itemValStyle }}>{ order.customer_order_number } </td>
                                                    <td style={{ ...itemValStyle }}>{ order.hawb }</td>
                                                    <td style={{ ...itemValStyle }}>{ order.mawb }</td>
                                                    <td style={{ ...itemValStyle }}>{ order.reference_1 }</td>
                                                    <td style={{ ...itemValStyle, textAlign:"left" }}>{ order.company_name }</td>
                                                    <td style={{ ...itemValStyle }}>{getConvertedOrderType(order.process_type)}</td>
                                                   
                                                   
                                                    <td style={{ ...itemValStyle, textAlign:"left" }}>
                                                       {
                                                        this.renderMeasurements(order)
                                                       }
                                                      
                                                    </td>
                                                    <td style={{ ...itemValStyle, textAlign:"left" }}>{ order.account_code }</td>
                                                    {/* <td>{ order.items_count ? order.items_count : order.quantity ? order.quantity : order.pieces ? order.pieces : 0 }</td>
                                                    <td>{ !isEmpty(order.pallets) ? order.pallets : 'NA' }</td>
                                                    <td className='textRight'>{ !isEmpty(order.weight) ? `${order.weight} ${AppConfig.weight_unit}` : 'NA' }</td> */}
                                                  </tr>
                                                ))}
                                              </tbody>
                                              </table>
                                    }
                                            {currentRoute?.sub_orders?.length > 0 && (
                                              <>
                                            <h4 style={{paddingTop: 30,  textAlign : "left", fontWeight: '500'}}>
                                            {stop.stop_number === 1 ? I18n.t('stop.linehaul_pickup_details') : (stop.stop_number === this.state.stops.length ? I18n.t('stop.linehaul_delivery_details') : stop.formatted_stop_name)}
                                            </h4>
                                            <table border="1" style={{ width: '100%' }} cellpadding="5px" cellspacing='0px'>
                                              <thead>
                                                <tr>
                                                  <th style={{ ...subItemStyle }}>#</th>
                                                  {hasHawb && <th style={{ ...subItemStyle }}>{I18n.t('general.hawb')}</th>}
                                                  {hasMawb && <th style={{ ...subItemStyle }}>{I18n.t('general.mawb')}</th>}
                                                  <th style={{ ...subItemStyle }}>{I18n.t('order.order_no')}</th>
                                                  <th style={{ ...subItemStyle }}>{I18n.t('general.company')}</th>
                                                  <th style={{ ...subItemStyle }}>{I18n.t("general.order_type_short_form")}</th>
                                                  <th style={{ ...subItemStyle }}>{I18n.t('order.measurements')}</th>
                                                  <th style={{ ...subItemStyle }}>{ "Account" }</th>
                                                 
                                                  {/* <th style={{ paddingLeft: 5 }}>{I18n.t('order.qty')}</th>
                                                  <th style={{ paddingLeft: 5 }}>{I18n.t('general.pallets')}</th>
                                                  <th style={{ paddingLeft: 5, width: '6%' }}>{I18n.t('order.weight')}</th> */}
                                                  {/* {this.props.includeItems && (
                                                    <>
                                                      <th style={{ paddingLeft: 5 }}>{I18n.t('order.item_name')}</th>
                                                      <th style={{ paddingLeft: 5 }}>{I18n.t('order.serial_no')}</th>
                                                    </>
                                                  )} */}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {currentRoute?.sub_orders.map((sub_order, index) => (
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    {hasHawb && <td>{sub_order.hawb}</td>}
                                                    {hasMawb && <td>{sub_order.mawb}</td>}
                                                    <td>{sub_order.customer_order_number}</td>
                                                    <td style={{textAlign:"left"}}>{ sub_order.company_name }</td>
                                                    <td>{getConvertedOrderType(sub_order.type_of_order)}</td>
                                                   
                                                    
                                                    <td style={{ textAlign: "left" }}>
                                                       {
                                                        this.renderMeasurements(sub_order)
                                                       }
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>{sub_order.account_code}</td>
                                                    {/* <td>{sub_order.items_count ? sub_order.items_count : sub_order.quantity ? sub_order.quantity : sub_order.pieces ? sub_order.pieces : 0}</td>
                                                    <td>{!isEmpty(sub_order.pallets) ? sub_order.pallets : 'NA'}</td>
                                                    <td className='textRight'>{!isEmpty(sub_order.weight) ? `${sub_order.weight} ${AppConfig.weight_unit}` : 'NA'}</td> */}
                                                    {/* { this.props.includeItems &&(
                                                      <>
                                                        <td style={ { display: "table-cell", width: "15%" } }>
                                                          <ul style={ { listStyle: 'none', padding: 0 } }>
                                                            { sub_order?.item_details.map((item, itemIndex) => (
                                                              <li key={ itemIndex } style={ { ...(itemIndex !== sub_order.item_details.length - 1 ? { borderBottom: '1px solid #000', height: 20 } : {  height: 20 }) } }
                                                              >{ item.item_name }</li>
                                                            )) }
                                                          </ul>
                                                        </td>
                                                        <td style={ { display: "table-cell", width: "15%" } }>
                                                          <ul style={ { listStyle: 'none', padding: 0 } }>
                                                            { sub_order?.item_details.map((item, itemIndex) => (
                                                              <li key={ itemIndex } style={ { ...(itemIndex !== sub_order.item_details.length - 1 ? { borderBottom: '1px solid #000',  height: 20  } : {  height: 20 }) } }
                                                              >{ item.serial_number }</li>
                                                            )) }
                                                          </ul>
                                                        </td>
                                                      </>
                                                    ) } */}
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                            </>
                                           )}
                                          </td>
                                        </tr>
                                      )
                                    }
                                  </table>
                                  { false &&
                                  <Card
                                    id={stop.location.name}
                                    title={stop.stop_number === 1 ? I18n.t('stop.starting_point') : (stop.stop_number === this.state.stops.length ? I18n.t('stop.ending_point') : stop.formatted_stop_name)}
                                    className='currentRouteStopDetails marginTop10'
                                    size="small"
                                  >
                                    <Row>
                                      <Col sm={24} xs={24} lg={12} md={12} className="alignLeft">
                                        {stop.location && stop.location.l_address ? <AddressInfo address={stop.location.l_address} showIcon /> : ''}
                                      </Col>
                                      <Col sm={24} xs={24} lg={12} md={12} className="alignRight" />
                                    </Row>
                                    <Row className="marginBottom5">
                                      <Col sm={24} xs={24} lg={12} md={12} className="alignLeft">
                                        {(scheduled_start_date_time_with_tz !== 'NA' || scheduled_end_date_time_with_tz !== 'NA') &&
                                        <Fragment>{I18n.t('order.scheduled_time')} : { isShowScheduled ? <Fragment>{formatStopTime(scheduled_start_date_time_with_tz, scheduled_end_date_time_with_tz, timeZone)}</Fragment> : 'NA' }</Fragment>
                                        }
                                      </Col>
                                      <Col sm={24} xs={24} lg={12} md={12} className="alignRight">
                                        {/* {scheduled_start_date_time_with_tz} - {scheduled_end_date_time_with_tz} */}

                                      </Col>
                                    </Row>
                                    <Row className="marginBottom5">
                                      <Col xs={24}>
                                        <OrdersList
                                          rowKey="customer_order_id"
                                          accessorials={[]}
                                          data={ordersList}
                                          currentStop={stop}
                                          pagination={{ position: 'none' }}
                                          size="small"
                                          showBilling={false}
                                          showAccessorial={false}
                                          showExceptions={false}
                                          showPod={false}
                                          stopType={stop.location && stop.location.l_type ? stop.location.l_type : ''}
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                  }
                                </Fragment>);
                            })
                          }
                        </div>
                      }
                    </div>
                  </div>


                </td>
                <td width="5%" />
              </tr>
            </table>
            { false &&
            <Row>
              <Col lg={24} md={24} xs={24} sm={24} style={{ padding: 10 }}>
                <Row className="blackBorder">
                  <Col xs={24} style={{ padding: 10 }}>
                    <Row>
                      <Col xs={24}>
                        <Row>
                          <Col xs={12} >
                            <span className="textBold">{I18n.t('general.routeName')}</span>:&nbsp;&nbsp;{currentRoute.nav_route_name}
                          </Col>
                          <Col xs={12} className="alignRight">
                            <span className="textBold">{I18n.t('general.date')}</span>:&nbsp;&nbsp;{currentRoute.r_scheduled_start_datetime ? moment(currentRoute.r_scheduled_start_datetime).format('Do, MMM YYYY') : 'NA'}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <span className="textBold">{I18n.t('general.Driver')}</span>:&nbsp;&nbsp;{this.state.driversInfo.primary && this.state.driversInfo.primary.allotedDriver ? `${this.state.driversInfo.primary.allotedDriver.first_name} ${this.state.driversInfo.primary.allotedDriver.last_name}` : 'NA'}
                          </Col>

                        </Row>
                        <Row>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <span className="textBold"> {I18n.t('general.travel_distance')}</span>:&nbsp;&nbsp;
                            <span>
                              {
                                time_distance
                              }
                            </span>
                          </Col>
                          <Col lg={12} md={12} sm={24} xs={24} className="alignRight">
                            <span className="textBold"> {I18n.t('general.travel_time')}</span>:&nbsp;&nbsp;
                            <span>{sec_hrs}</span>
                          </Col>
                        </Row>

                      </Col>
                    </Row>


                  </Col>


                </Row>
                <Row className="blackBorder" style={{ marginTop: 15 }}>
                  <Col xs={24} className="paddingTop10">
                    <Row>

                      <Col sm={24} >
                        { false && <StopsPrintList
                          isMilitaryTime={this.state.isMilitaryTime}
                          data={this.state.stops || []}
                          size="small"
                          pagination={{ position: 'none' }}
                          currentRoute={this.state.currentRoute}
                        />
                        }

                        {
                          this.state.stops.length > 0 &&
                          <div className="padding5">
                            {
                              this.state.stops.map((stop, index) => {
                              const scheduled_start_date_time_with_tz = stop.scheduled_start_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.scheduled_start_date_time_with_tz) , isMilitaryTime ,'MMM Do HH:mm' , 'MMM Do hh:mm A') : 'NA';
                                const scheduled_end_date_time_with_tz = stop.scheduled_end_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.scheduled_end_date_time_with_tz) , isMilitaryTime ,'MMM Do HH:mm' , 'MMM Do hh:mm A') : 'NA';
                                const estimated_arrival_date_time_with_tz = stop.estimated_arrival_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.estimated_arrival_date_time_with_tz) , isMilitaryTime ,'MMM Do HH:mm' , 'MMM Do hh:mm A') : 'NA';
                                const estimated_departure_date_time_with_tz = stop.estimated_departure_date_time_with_tz ? formatByTimeConfig(moment.utc(stop.estimated_departure_date_time_with_tz) , isMilitaryTime ,'MMM Do HH:mm' , 'MMM Do hh:mm A') : 'NA';

                                const service_duration = stop.service_duration ? secondsToHms(parseInt(stop.service_duration), true, true, false) : 0;
                                const isShowScheduled = scheduled_start_date_time_with_tz !== 'NA' || scheduled_end_date_time_with_tz !== 'NA';
                                const isShowEstimated = estimated_arrival_date_time_with_tz !== 'NA' || estimated_departure_date_time_with_tz !== 'NA';
                                const timeZone = stop.stop_tz_short_name;
                                const contact_details = stop.contact_details ? stop.contact_details : {};
                                const customerPhoneOne = doFormate(contact_details.contact_phone_number_1);
                                const customerPhoneTwo = doFormate(contact_details.contact_phone_number_2);
                                const stopOrderIds = stop.customer_order_ids || [];
                                let ordersList = [];
                                if (stopOrderIds && stopOrderIds.length > 0) {
                                  stopOrderIds.map((id) => {
                                    const order = currentRoute.customer_orders.find(order => id === order.customer_order_id);
                                    ordersList = [...ordersList, order];

                                  });
                                }
                                return (

                                  <Fragment key={`timeline_fragment${stop.id}`}>
                                    <Card
                                      id={stop.location.name}
                                      title={stop.stop_number === 1 ? I18n.t('stop.starting_point') : (stop.stop_number === this.state.stops.length ? I18n.t('stop.ending_point') : stop.formatted_stop_name)}
                                      className='currentRouteStopDetails marginTop10'
                                      size="small"
                                    >
                                      <Row>
                                        <Col sm={24} xs={24} lg={12} md={12} className="alignLeft">
                                          {stop.location && stop.location.l_address ? <AddressInfo address={stop.location.l_address} showIcon /> : ''}
                                        </Col>
                                        <Col sm={24} xs={24} lg={12} md={12} className="alignRight" >
                                          <Icon type="phone" /> &nbsp;&nbsp;{`${customerPhoneOne} ${customerPhoneTwo !== 'NA' ? `,${customerPhoneTwo}` : ''}`}
                                        </Col>
                                      </Row>
                                      <Row className="marginBottom5">
                                        <Col sm={24} xs={24} lg={12} md={12} className="alignLeft">
                                          {(scheduled_start_date_time_with_tz !== 'NA' || scheduled_end_date_time_with_tz !== 'NA') &&
                                            <Fragment>{I18n.t('order.scheduled_time')} : { isShowScheduled ? <Fragment>{formatStopTime(scheduled_start_date_time_with_tz, scheduled_end_date_time_with_tz, timeZone)}</Fragment> : 'NA' }</Fragment>
                                          }
                                        </Col>
                                        <Col sm={24} xs={24} lg={12} md={12} className="alignRight">
                                          {/* {scheduled_start_date_time_with_tz} - {scheduled_end_date_time_with_tz} */}
                                          {(estimated_arrival_date_time_with_tz !== 'NA' || estimated_departure_date_time_with_tz !== 'NA') &&
                                          <Fragment>{I18n.t('order.estimated_time')} : { isShowEstimated ? <Fragment>{formatStopTime(estimated_arrival_date_time_with_tz, estimated_departure_date_time_with_tz)}</Fragment> : 'NA' }</Fragment>
                                          }
                                        </Col>
                                      </Row>
                                      <Row className="marginBottom5">
                                        <Col xs={24} className="ordersList">
                                          <OrdersList
                                            rowKey="customer_order_id"
                                            accessorials={[]}
                                            data={ordersList}
                                            currentStop={stop}
                                            pagination={{ position: 'none' }}
                                            size="small"
                                            showBilling={false}
                                            showAccessorial={false}
                                            showExceptions={false}
                                            showPod={false}
                                            stopType={stop.location && stop.location.l_type ? stop.location.l_type : ''}
                                          />
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Fragment>);
                              })
                            }
                          </div>
                        }

                      </Col>
                    </Row>
                  </Col>
                </Row>


              </Col>
            </Row>
            }

          </Spin>
          {(this.state.errors) &&
            <Row>
              {FormErrors(this.state.errors)}
            </Row>
          }
        </div>
      </div>
    );
  }
}

DownloadContainer.propTypes = {
  stops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentRoute: PropTypes.shape().isRequired,
};

DownloadContainer.defaultProps = {
  match: {},
  routeId: '',
  reqFrom: '',
};
export default withRouter(DownloadContainer);
