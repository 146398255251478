import React from 'react'
import BaseList from '../BaseList'
import {
  Button,
  InputNumber,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  Icon,
} from '../../common/UIComponents'
import DeleteIcon from '../common/DeleteIcon'
import EndUserTitle from '../common/EndUserTitle'
import I18n from '../../common/I18n'
import OrderBillingDetails from '../billing/OrderBillingDetails'
import CopyableContent from '../common/CopyableContent'
import { checkNegitive, isEmpty } from '../../common/Common'

class DetailsList extends BaseList {
  constructor(props) {
    super(props)
    const decimalPoints =  window.localStorage.getItem('round_off_decimals')
    const columns = [
      {
        key: 'order_no',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
        render: (text, record) => (
          <div>
             {
                record.customer_order_id && !this.props.isDriverLogin ?  <OrderBillingDetails
                record={{...record}}
                showActions = {false}
                />
              :
              <CopyableContent text={text} />
              }
            {record?.type_of_order && record.type_of_order ==='MANUAL' && (
              <Tooltip title={record.type_of_order}>
                <sup className="textRed textBold fontSize10">
                  &nbsp;&nbsp;(M)
                </sup>
              </Tooltip>
            )}

          </div>
        ),
        width: 110,
      },
      {
        key: 'hawb',
        title: I18n.t('general.hawb'),
        dataIndex: 'hawb',
        render: (text, record) => (
          <div>
            {text ? <CopyableContent text={text} /> : ''}
          </div>
        ),
        width: 100,
      },
      {
        key: 'account',
        title: I18n.t('account.account'),
        dataIndex: 'account_code',
      },
      {
        key: 'consignee',
        title: <EndUserTitle/>,
        dataIndex: 'consignee_name',
        className: 'noWrap'
      },
      {
        key: 'vehicle_type',
        title: "Vehicle Type",
        dataIndex: 'vehicle_type',
        width: 100,
      },
      // {
      //   key: 'address',
      //   title: I18n.t('general.address'),
      //   dataIndex: 'consignee_address',
      //   className: 'minWidth',
      // },
      {
        key: 'gross_revenue',
        title: (
          <div>
            {I18n.t('reports.gross_revenue')}
            <sup className="textRed">*</sup> ({I18n.t('general.price_symbol')})
          </div>
        ),
        dataIndex: 'gross_revenue',
        render: (text, record, index) => (
          this.props.isDriverLogin ? 
            !isEmpty(text) ? checkNegitive(text) : ""
          : 
          <InputNumber
            value={text}
            size="small"
            className="fontSize12"
            onChange={(e) => this.props.onChange(index, 'gross_revenue', e)}
            placeholder={I18n.t('reports.gross_revenue')}
            min={0}
            precision={decimalPoints}
            style={{ width: 100 }}
          />
        ),
        width: 125,
      },
      {
        key: 'gross_pay',
        title: (
          <div>
            {I18n.t('reports.gross_pay')}
            <sup className="textRed">*</sup> ({I18n.t('general.price_symbol')})
          </div>
        ),
        dataIndex: 'gross_pay',
        render: (text, record, index) => (
          this.props.isDriverLogin ? 
            !isEmpty(text) ? checkNegitive(text) : ""
          :
          <InputNumber
            value={text}
            size="small"
            className="fontSize12"
            onChange={(e) => this.props.onChange(index, 'gross_pay', e)}
            placeholder={I18n.t('reports.gross_pay')}
            min={0}
            precision={decimalPoints}
            style={{ width: 100 }}
          />
        ),
        width: 110,
      },
      {
        key: 'action',
        title: '',
        dataIndex: 'id',
        render: (data, record, index) => (
          <Row type="flex" gutter={8} align="middle" justify="end"> 
            { !this.props.hasNew && !this.props.isDriverLogin &&<Col> {/* record.type_of_order ==='MANUAL' && */}
              <Tooltip placement="topLeft" title="Delete">
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm')}
                  onConfirm={() =>
                    this.props.deleteClick(record.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon styleObj={{ fontSize: 16 }}/>
                  &nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
            </Col>
            }
            {false && !this.props.hasNew && index + 1 === this.props.data.length && (
              <Col className="line-actions fontsize11">
                <Tooltip placement="topLeft" title={I18n.t('order.add')}>
                  <Icon
                    type="plus-circle"
                    theme="filled"
                    onClick={this.props.addOrderRow}
                    style={{ fontSize: 18 }}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        ),
        width: 65,
        align: 'left',
      },
      // {
      //   key: 'action',
      //   title: '',
      //   dataIndex: 'id',
      //   render: (id, record, index) => (
      //     this.props.driverPayStatus === 'PENDING' ?
      //     <Button
      //       size="small"
      //       type="primary"
      //       onClick={() => this.props.handleSave(id)}
      //     >
      //       {I18n.t('general.save')}
      //     </Button> : null
      //   ),
      // },
    ];
    if(!props.isAirCargo()){
      this.columns = columns.filter(col => !['hawb'].includes(col.key))
    } else{
      this.columns = [...columns]
    }
   
  }
}

export default DetailsList
