import React, { Component } from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
} from "../../common/UIComponents";
import {  alertMessage, doValidate, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import { saveTruck } from "../../api/TrucksApi";
import FormErrors from "../common/FormErrors";
import imagePlaceholder from "../../../assets/images/truck.png";

const RadioGroup = Radio.Group;
const { TextArea } = Input;
const truckTypes = {
  reefer: "Reefer",
  dryvan: "Dry Van",
  flatbed: "Flat Bed",
};

const truckStatuses = {
  active: "Active",
  inactive: "In Active",
};

const requiredFields = [
  {
    form_field: "name",
    ui_name: `${I18n.t("truck.name")}`,
    isRequired: true,
    inputType: "text",
  },
  // {
  //   form_field: "size",
  //   ui_name: I18n.t("truck.size"),
  //   isRequired: true,
  //   inputType: "text",
  // },
  {
    form_field: "vehicle_weight",
    ui_name: I18n.t("order.weight"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "vehicle_length",
    ui_name: I18n.t("order.length"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "vehicle_width",
    ui_name: I18n.t("order.width"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "vehicle_height",
    ui_name: I18n.t("order.height"),
    isRequired: true,
    inputType: "text",
  },
];

class TruckForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      truck: this.props.truck ? this.props.truck : {},
      isNew: this.props.isNew,
      inProgress: false,
      errors: [],
      // VhTypeCodes:''
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.initialData();
  }

  initialData = () => {
    const { truck, isNew } = this.state;
    if (isNew || isEmpty(truck.vehicle_length)) {
      truck.vehicle_length = 1;
    }
    if (isNew || isEmpty(truck.vehicle_width)) {
      truck.vehicle_width = 1;
    }
    if (isNew || isEmpty(truck.vehicle_height)) {
      truck.vehicle_height = 1;
    }
    if (isNew || isEmpty(truck.vehicle_weight)) {
      truck.vehicle_weight = 1;
    }
    this.setState({ truck });
  };

  handleOnChange(element, value) {
    this.setState({
      truck: Object.assign({}, this.state.truck, { [element]: value }),
    });
  }

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (image) =>
      this.setState({
        image,
      })
    );
  };

  getBase64 = (img, callback) => {
    const data1 = Object.assign({}, this.state.truck, { image: img });
    this.setState({
      truck: data1,
    });
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleSave() {
    const data = this.state.truck;
    if (this.state.isNew && !data.id) {
      data.status = "active";
    }
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      this.setState({ inProgress: true });
      saveTruck(this.state.isNew, data).then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t("messages.saved"));
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  }

  render() {
    const { truck } = this.state;
    const image = this.state.image || this.state.truck.image;
    return (
      <div>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t("truck.name")} require>
              <Input
                value={truck.name}
                onChange={(e) => this.handleOnChange("name", e.target.value)}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t("truck.vin")}>
              <Input
                value={truck.vin}
                onChange={(e) => this.handleOnChange("vin", e.target.value)}
              />
            </FormItem>
          </Col>

          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t("truck.vehicle_make")}>
              <Input
                value={truck.vehicle_make}
                onChange={(e) =>
                  this.handleOnChange("vehicle_make", e.target.value)
                }
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t("truck.vehicle_model")}>
              <Input
                value={truck.vehicle_model}
                onChange={(e) =>
                  this.handleOnChange("vehicle_model", e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12}>
            <Col className="paddingZero">
              {/* <FormItem label={I18n.t('truck.vehicle_type')}>
                <Select
                  value={truck.vehicle_type}
                  showSearch
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.handleOnChange('vehicle_type', e)}
                >
                  {
                    Object.keys(truckTypes).map(truck_type => (
                      <Select.Option key={truck_type} value={truck_type}>{truckTypes[truck_type]}</Select.Option>
                    ))
                  }
                </Select>
              </FormItem> */}
              <FormItem label={I18n.t("truck.vehicle_number")}>
                <Input
                  value={truck.vehicle_number}
                  onChange={(e) =>
                    this.handleOnChange("vehicle_number", e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col className="paddingZero">
              <FormItem label={I18n.t("truck.reference_id")}>
                <Input
                  value={truck.vehicle_reference_id}
                  onChange={(e) =>
                    this.handleOnChange("vehicle_reference_id", e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col className="paddingZero">
              <FormItem label={I18n.t("truck.vehicle_type")}>
                {/* <Input
                  value={truck.vehicle_type}
                  onChange={(e) =>
                    this.handleOnChange("vehicle_type", e.target.value)   // removed the input field and added select field
                  }
                /> */}  
                 <Select
                      style={{ width: "100%" }}
                      showSearch
                      allowClear={true}
                      onChange={(e)=> this.handleOnChange("vehicle_type", e)}
                      value={
                        truck.vehicle_type
                          ? truck.vehicle_type
                          : ""
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option key={"code"} value={""}>
                        ---Select---
                      </Select.Option>
                      {this.props.vehicleTypes.map((code) => (
                        <Select.Option key={code.name} value={code.code}>
                          {code.name}
                        </Select.Option>
                      ))}
                    </Select>
              </FormItem>
            </Col>
            {/* <Col className="paddingZero">
              <FormItem label={I18n.t("truck.size")} require>
                <InputNumber
                  value={truck.size}
                  onChange={(e) => this.handleOnChange("size", e)}
                  min={1}
                  className="width100Per"
                />
              </FormItem>
            </Col> */}
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t("truck.image")}>
              <Upload showUploadList={false} onChange={this.handleChange}>
                {truck.image && !this.state.image && (
                  <div className="borderRadius">
                    <img
                      src={image.url || imagePlaceholder}
                      style={{ height: 120, width: 120 }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {this.state.image && (
                  <div className="borderRadius">
                    <img
                      src={image || imagePlaceholder}
                      style={{ height: 120, width: 120 }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {!this.state.image && !this.state.truck.image && (
                  <div className="borderRadius">
                    <img
                      src={imagePlaceholder}
                      style={{ height: 120, width: 120 }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
              </Upload>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={24} xs={24} md={6} lg={6}>
            <FormItem label={I18n.t("order.weight")} require>
              <InputNumber
                  value={truck.vehicle_weight}
                  onChange={(e) => this.handleOnChange("vehicle_weight", e)}
                  min={1}
                  className="width100Per"
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={6} lg={6}>
            <FormItem label={I18n.t("order.length")} require>
              <InputNumber
                value={truck.vehicle_length}
                onChange={(e) => this.handleOnChange("vehicle_length", e)}
                min={1}
                className="width100Per"
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={6} lg={6}>
            <FormItem label={I18n.t("order.width")} require>
              <InputNumber
                value={truck.vehicle_width}
                onChange={(e) => this.handleOnChange("vehicle_width", e)}
                min={1}
                className="width100Per"
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={6} lg={6}>
            <FormItem label={I18n.t("order.height")} require>
              <InputNumber
                value={truck.vehicle_height}
                onChange={(e) => this.handleOnChange("vehicle_height", e)}
                min={1}
                className="width100Per"
              />
            </FormItem>
          </Col>
        </Row>

        {/* <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12}>
          <FormItem label={I18n.t('truck.size')}>
              <Input
                value={truck.size}
                onChange={e => this.handleOnChange('size', e.target.value)}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12}>
            <FormItem label={I18n.t('truck.status')}>
              <Select
                value={truck.status}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={e => this.handleOnChange('status', e)}
              >
                {
                  Object.keys(truckStatuses).map(truck_type => (
                    <Select.Option key={truck_type} value={truck_type}>{truckStatuses[truck_type]}</Select.Option>
                  ))
                }
              </Select>
            </FormItem>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t("truck.notes")}>
              <TextArea
                rows={2}
                onChange={(e) => this.handleOnChange("notes", e.target.value)}
                value={truck.notes}
                placeholder={I18n.t("truck.notes")}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

TruckForm.propTypes = {
  truck: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

TruckForm.defaultPropTypes = {
  truck: {},
  isNew: true,
};

export default TruckForm;
