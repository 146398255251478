import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Radio } from 'antd';
import { withRouter } from 'react-router-dom';
import { updateRoute, deleteRoutes, updateTaskOrder, allocateDriver, fetchRouteStops, fetchOptimizedStops } from '../api/Routes';
import FormErrors from '../components/common/FormErrors';
import { fetchUsers } from '../api/UsersApi';
import { fetchTaskOrders } from '../api/OrdersApi';
import {
  Alert, Col, Row, Spin, Select, Icon, FormItem,
    Button, Popconfirm, Avatar, Drawer, Timeline, Card
} from '../common/UIComponents';
import whiteArrow from '../../assets/images/WhiteArrow.png';
import { alertMessage,  doFormate } from '../common/Common';
import I18n from '../common/I18n';
import DraggableOption from '../components/Tasks/DraggableOption';
import BaseModal from '../components/BaseModal';
import OrdersList from '../components/Tasks/OrdersList';
import OrderDetails from '../components/Tasks/OrderDetails';
import EditOrderDetails from '../components/Tasks/EditOrderDetails';
import { fetchRouteOrders, fetchOrderDetails } from '../api/OrdersApi';
import { calculateTimeDuration } from '../components/tracking/Utils';

import StopItemDetails from '../components/Routes/StopItemDetails';

import Leg from '../components/Tasks/Legs';
import BranchesIcon from '../components/common/BranchesIcon';
import { checkAccessExistance, renderAlertMessage, secondsToHms } from '../helpers/common';


const userTypes = {
  '/drivers': 'driver',
  '/customers': 'customer',
  '/admins': 'admin',
};
const { Option } = Select;
const RadioGroup = Radio.Group;

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: {
        t_type: 'PICKUP',
        t_start_datetime: moment(),
        orderInfo: [],
        taskOrderInfo: [],
        selectedRowKeys: []
      },
      location: [],
      pickupLocations: [],
      dropLocations: [],
      inProgress: false,
      stops: [],
      locations: [],
      zones: [],
      googleMap: false,
      updateStatus: false,
      errors: [],
      loading: false,
      routeId: this.props.match.params.routeId ? this.props.match.params.routeId : '',
      routeStatus: '',
      users: [],
      driverId: '',
      allotedDriver: {},
      assignStatus: false,
      routeInfo: '',
      selectedRowKeys: [],
      orderInfo: [],
      dropTask: false,
      detailsVisible: false,
      editForm: false,
      listVisible: false,
      route: '',
      routes: {
        selectedOrderKeys: [],
        selectedOrderRows: []
      },
      optimize_error: '',
      optimize_error_status: true,
      currentOrder: {},
      orderObject: {}


    };

    this.userType = userTypes[_.get(props, 'match.path', 'driver')];
  }

  UNSAFE_componentWillMount() {
    // this.getPickupLocations();
    // this.getDropLocations();
    //this.getTasks();
    this.getRouteInfo();
    this.getUsers();
  }

  componentDidMount() {
    if (this.state.routeId) {
      const tasks = Object.assign({}, this.state.tasks, { nav_route_id: this.state.routeId });
      this.setState({
        tasks,
      });
    }
  }


  onOk = (value) => {
  }

  onErrorClose = () => {
    this.setState({
      optimize_error_status: false
    });
  }

  deleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteRoutes(id)
      .then((result) => {
        if (result.success) {
          this.props.history.push({
            pathname: '/orders',
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  confirmRoute = () => {
    this.props.history.push({
      pathname: '/orders',
      filter: 'ASSIGNED',
    });
  }

  handleUpdate = () => {
    this.props.history.push({
      pathname: '/orders',
      filter: 'PENDING',
      routeId: this.state.routeId
    });
  }

  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    });
  };

  showListDrawer = () => {
    this.setState({
      listVisible: true,
    });
  };

  closeListDrawer = () => {
    this.setState({
      listVisible: false,
    });
  };

  showEditForm = () => {
    this.setState({
      editForm: true,
    });
  };

  onClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  closeEditFormModal = () => {
    this.setState({ 'editForm': false });
  }

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ editForm: false, listVisible: false }, () => {
      this.getRouteInfo();
    });
  }

  handleOrderDetailsViewClick = (id) => {
    // const index = _.findIndex(this.state.orderInfo, ['id', id]);
    // const currentOrder = Object.assign({}, this.state.orderInfo[index]);
    // this.setState({ currentOrder: currentOrder, detailsVisible: true });
    const index = _.findIndex(this.state.orderInfo, ['id', id]);
    const orderObject = Object.assign({}, this.state.orderInfo[index]);

    fetchOrderDetails(id)
      .then((result) => {
        if (result.success) {
          this.setState({ //result.orders.customer_order
            currentOrder: result.orders,
            orderObject: orderObject,
          }, () => {
            this.setState({ detailsVisible: true });
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            orderObject: orderObject,
            loading: false,
          });
        }
      });
  }

  handleEditOrderClick = (id) => {

    const index = _.findIndex(this.state.orderInfo, ['id', id]);
    const orderObject = Object.assign({}, this.state.orderInfo[index]);

    fetchOrderDetails(id)
      .then((result) => {
        if (result.success) {
          this.setState({ //result.orders.customer_order
            currentOrder: result.orders,
            orderObject: orderObject,
          }, () => {
            this.setState({ editForm: true });
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({
            orderObject: orderObject,
            loading: false,
          });
        }
      });

  }


  getRouteOrders = () => {
    const { dateFilter, filter } = this.state;
    this.setState({ inProgress: true });
    fetchRouteOrders(this.state.routeId)
      .then((result) => {
        if (result.success) {
          const customer_orders = result.orders.orders_in_route ? result.orders.orders_in_route : [];
          this.setState({
            orderInfo: customer_orders,
            inProgress: false,
            routes: {
              selectedOrderKeys: customer_orders.map(x => {
                return x.id
              }),
              selectedOrderRows: customer_orders,
            },
          }, () => {
            this.setState({ listVisible: true, inProgress: false });
          });
        } else {
          this.setState({ listVisible: true, inProgress: false });
        }
      });
  }

  getTaskOrders = (value, cb) => {
    fetchTaskOrders(value)
      .then((result) => {
        if (result.success) {
          this.setState({
            taskOrderInfo: result.orders.customer_orders,
            selectedRowKeys: _.map(result.orders.customer_orders, function (o) { return o.id })
          }, cb);

        } else {
          renderAlertMessage(result.errors)
        }
      });
  }

  getZones = (cb) => {
    const zone = _.map(this.state.locations, 'zone');
    const zones = _.uniqWith(zone, _.isEqual);
    this.setState({
      zones,
    }, cb);
  }

  setLocations = (type, cb) => {
    if (type === 'PICKUP') {
      this.setState({
        locations: this.state.pickupLocations,
        location: [],
        tasks: Object.assign({}, this.state.tasks, { zone: '', location: '', orderInfo: [], taskOrderInfo: [], selectedRowKeys: [] })
      }, () => {
        this.getZones(cb);
      });
    } else if (type === 'DROP') {
      this.setState({
        locations: this.state.dropLocations,
        location: [],
        tasks: Object.assign({}, this.state.tasks, { zone: '', location: '', orderInfo: [], taskOrderInfo: [], selectedRowKeys: [] })
      }, () => {
        this.getZones(cb);
      });
    }
  }

  getUsers = () => {
    this.setState({ inProgress: true });
    fetchUsers(this.userTypes, 1, 1000, false, false)
      .then((result) => {
        if (result.success) {
          this.setState({
            users: result.users,
            inProgress: false,
          }, () => {
            this.setAllotedDriver();
          });

        } else {
          this.setState({ inProgress: false });
          renderAlertMessage(result.errors)
        }
      });
  };

  getRouteInfo = () => {
    if (this.state.routeId) {
      fetchRouteStops(this.state.routeId)
        .then((result) => {
          if (result.success) {
            let route_stops = [];
            result.stops.stops.map((route_stop) => {
              route_stops.push(route_stop.stop_details);
            });
            const sortedRoutes = _.sortBy(route_stops, [function (o) { return o.stop_order_sequence; }]);
            this.setState({
              routeInfo: route_stops,
              stops: sortedRoutes,
              routeStatus: result.stops.nav_route_status,
              route: result.stops,
            }, () => {
              const driver = result.stops.driver ? result.stops.driver : '';
              if (driver && driver.id) {

                this.setState({
                  driverId: driver.id
                }, () => {
                  this.setAllotedDriver();
                })
              }
            });
          } else {
            this.setState({ inProgress: false });
            renderAlertMessage(result.errors)
          }
        });
    }
  };

  getOptimizeInfo = () => {

    if (this.state.routeId) {
      this.setState({ inProgress: true });
      fetchOptimizedStops(this.state.routeId)
        .then((result) => {
          if (result.success) {
            const sortedRoutes = _.sortBy(result.optimize_route.optimize_route.nav_stops, [function (o) { return o.stop_order_sequence; }]);
            this.setState({
              routeInfo: result.optimize_route.optimize_route.nav_stops,
              stops: sortedRoutes,
              optimize_error_status: result.optimize_route.optimize_route.nav_route.optimized,
              optimize_error: result.optimize_route.optimize_route.nav_route.optimizer_error_message,
              inProgress: false
            }, () => {
              // if (result.optimize_route.optimize_route.nav_routes.driver && result.nav_routes.driver.id) {
              //   this.setState({
              //     driverId: result.nav_route.driver.id
              //   }, () => {
              //     this.setAllotedDriver();
              //   })
              // }
            });
          } else {
            this.setState({ inProgress: false });
            renderAlertMessage(result.errors)
          }
        });
    }
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedOrderKeys: selectedRowKeys,
        selectedOrderRows: []
      })
    }, () => {
    });
  };


  handleChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }


  taskStatus = (task) => {
    if (task.t_type === 'PICKUP') {
      return 'hsla(120,100%,75%,0.1)';
    } else if (task.t_type === 'DROP') {
      return '#2E2E2E';
    }
  }

  handleDeleteClick = (id) => {
    deleteTasks(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getTasks();
          this.handleCancel();
        } else {
          alertMessage(result.errors, 'error', 10);
        }
      });
  }


  handleSave = () => {
    this.setState({
      loading: true,
    });
    let data = {};
    if (this.state.routeId) {
      data = Object.assign({}, data, { nav_route_id: this.state.routeId, customer_order_ids: this.state.routes.selectedOrderKeys });
    } else {
      data = Object.assign({}, data, { customer_order_ids: this.state.routes.selectedOrderKeys });
    }
    updateRoute(data)
      .then((result) => {
        if (result.success) {
          this.setState({
            loading: false,
            listVisible: false

          }, () => {
            this.getRouteInfo();
          });

        } else {
          renderAlertMessage(result.errors)
          this.setState({
            loading: false,
          });
        }
      });
  };

  back = () => {
    this.props.history.push('/routes');
  }

  draggableMap = () => {
    this.setState({
      googleMap: true,
    });
  }

  handleOnModalClose = () => {
    this.setState({
      googleMap: false,
    });
  }

  updateOrder = (routeData) => {
    this.setState({
      updateStatus: true,
    });
    const Data = routeData.map((task, index) =>
      ({ task_id: task.id, t_s_no: index + 1 }));
    const tasksData = {
      tasks:
        Data,
    };
    updateTaskOrder(tasksData)
      .then((result) => {
        if (result.success) {
          this.handleSaveSuccess(result.order.message);
          this.getTasks();
          this.setState({
            updateStatus: false,
            googleMap: false,
          });
        } else {
          this.setState({
            updateStatus: false,
          });
          renderAlertMessage(result.errors)
        }
      });
  }


  assignDrivertoRoute = () => {
    this.setState({
      assignStatus: true,
    });
    allocateDriver(this.state.selectedDriverId, this.state.routeId)
      .then((result) => {
        if (result.success) {
          const selectedDriverId = this.state.selectedDriverId;
          this.setState({
            assignStatus: false,
            driverId: selectedDriverId
          }, () => {
            this.setState({ selectedDriverId: '' });
            this.setAllotedDriver();
          });
          this.handleSaveSuccess(I18n.t('messages.allocated'));
        } else {
          this.setState({
            assignStatus: false,
          });
          renderAlertMessage(result.errors)
        }
      });
  };

  renderFormModal() {
    return (
      <BaseModal
        title="Opitimize Map"
        onCancel={() => this.handleOnModalClose()}
        width="95%"
        style={{ top: 10, paddingTop: '12px' }}
      >
        <DraggableOption
          routeData={_.filter(this.state.routes, o => (o.t_status !== 'INPROGRESS' && o.t_status !== 'COMPLETED'))}    // this.state.routes
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          zones={this.state.zones}
          updateStatus={this.state.updateStatus}
          updateOrder={routeData => this.updateOrder(routeData)}
        />
      </BaseModal>
    );
  }

  setAllotedDriver = () => {
    if (this.state.driverId) {
      const index = _.findIndex(this.state.users, ['id', this.state.driverId]);
      const allotedDriver = Object.assign({}, this.state.users[index]);

      this.setState({
        allotedDriver
      });
    }
  }


  showDrawer = () => {
    this.setState({
      detailsVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      detailsVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      tasks: {
        t_type: 'PICKUP',
        t_start_datetime: moment(),
        orderInfo: [],
        taskOrderInfo: [],
        selectedRowKeys: []
      },
      dropTask: false
    })
  }

  fomatString = (str, format = '') => {
    return doFormate(str, format);
  }

  navigateRouteScreen = () => {
    this.props.history.push({
      pathname: '/routes',
      filter: this.state.routeStatus ? this.state.routeStatus : ''
    });
  }


  render() {
    const { tasks, location, zones, route } = this.state;
    const assignString = I18n.t('general.' + (this.state.driverId ? 'reassign' : 'assign'));
    const hasSelected = (this.state.routes.selectedOrderKeys) ? this.state.routes.selectedOrderKeys.length > 0 : false;
    const { selectedOrderKeys } = this.state.routes;
    const rowSelection = {
      selectedRowKeys: selectedOrderKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: record => ({
        disabled: (tasks.id && tasks.t_type === 'DROP'),    // Column configuration not to be checked
      }),
    };

    let  sec_hrs = secondsToHms(route.total_time,true,false,false);
    sec_hrs = sec_hrs ? sec_hrs : 'NA';
    const time_distance = route.total_distance ? ((parseFloat(route.total_distance/1609)).toFixed(2))+' Miles' : 'NA'
    return (
      <div className='content-outer'>
        <div className='content routes-tasks' style={{ backgroundColor: '#f0f2f5' }}>
          <Row className='page-header' style={{ marginBottom: 0 }}>
            <Col xs={12}>
              {/* New Route */}
              <Row>

                { checkAccessExistance('DISPATCH') && 
                  <img src={whiteArrow} alt="whitearrow" style={{ height: 20, width: 30, cursor: 'pointer' }} onClick={this.navigateRouteScreen} />
                }

                {this.state.routeId ? I18n.t('routes.update') : I18n.t('routes.new')}
              </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000} >

            <Row>
              <Col xs={24} style={{ padding: 10 }}>
                <Row className="blackBorder" style={{ minHeight: 444, padding: '5px 30px' }}>
                  <Row >
                    <Col xs={15} className='route-summary'>
                      {I18n.t('tasks.routeSummary')}
                    </Col>
                    <Col xs={9}  >
                      {false && (this.state.stops.length > 0) &&
                        <div className="optimizeRoute">
                          <BranchesIcon/>
                          <a onClick={this.getOptimizeInfo}>Optimize Route</a>
                        </div>

                      }
                    </Col>
                  </Row>
                  {false && <Row>
                    <Col xs={24} style={{ padding: '0px 25px 25px 25px' }}>
                      {!this.state.optimize_error_status && <Alert
                        message="Error"
                        description={this.state.optimize_error.error_message[0]}
                        type="error"
                        closable

                      />
                      }
                    </Col>
                  </Row>
                  }
                  <Row>
                    <Col xs={24} sm={24} md={16} lg={16}>
                      <Row>
                        <Col xs={10}>
                          <div className="info-text">
                            <h4>{I18n.t('general.name')}</h4> :&nbsp;
                            {route.nav_route_name ?
                              (route.nav_route_name) : 'NA'}
                          </div>
                        </Col>


                        <Col xs={14}>
                          <div className="info-text">
                            <h4> {I18n.t('info.start_date')}</h4> :&nbsp;
                            {route.r_start_datetime ?
                              (moment(new Date(route.r_scheduled_start_datetime)).format('LLL')) : 'NA'
                            }
                          </div>
                        </Col>


                      </Row>
                      <Row>
                        <Col xs={10}>
                          <div className="info-text">
                            <h4>{I18n.t('general.status')} </h4>: &nbsp;
                            {route.nav_route_status ?
                              (route.nav_route_status) : 'NA'}
                          </div>
                        </Col>

                        <Col xs={14}>
                          <div className="info-text">
                            <h4> {I18n.t('info.end_date')} </h4> :&nbsp;
                            {route.r_end_datetime ?
                              (moment(new Date(route.r_scheduled_start_datetime)).format('LLL')) : 'NA'
                            }
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col xs={10}>
                          <div className="info-text">
                            <h4>{I18n.t('info.total_time')} </h4>:&nbsp;
                            {route.total_time ?
                              (route.total_time) : 'NA'}
                          </div>
                        </Col>
                        <Col xs={14}>
                          <div className="info-text">
                            <h4>{I18n.t('info.total_distance')} </h4>: &nbsp;
                            {route.total_distance ?
                              (route.total_distance) : 'NA'}
                          </div>
                        </Col>
                      </Row>

                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      {(this.state.allotedDriver) && (this.state.allotedDriver.id) &&
                        <Row>
                          <Col xs={24} style={{ marginTop: -10 }}>
                            <h3>{I18n.t('users.driver.assigned')}</h3>
                            <Row>
                              <Col xs={24} sm={24} md={7} lg={7}>
                                <div className="driver-profile">
                                  {
                                    this.state.allotedDriver.image && (this.state.allotedDriver.image.url) ?
                                      <Avatar src={`${this.state.allotedDriver.image.url}`} size={70} /> : <Avatar src="./../icons/user_100*100.png" alt="DriverProfile" size={70} />
                                  }

                                </div>
                              </Col>
                              <Col xs={24} sm={24} md={17} lg={17} className="padding5">
                                <Col>
                                  <Col>
                                    <div className="info-text" style={{ fontWeight: 'bold', marginBottom: '5px' }}>

                                      {this.state.allotedDriver.full_name ?
                                        (this.state.allotedDriver.full_name) : 'NA'
                                      }
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="info-text">
                                      <h4><Icon type="mobile" /></h4>
                                      {this.state.allotedDriver.mobile_number ?
                                        (this.state.allotedDriver.mobile_number) : 'NA'
                                      }
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="info-text">
                                      <h4><Icon type="mail" /></h4>
                                      {this.state.allotedDriver.email ?
                                        (this.state.allotedDriver.email) : 'NA'
                                      }
                                    </div>
                                  </Col>
                                </Col>
                              </Col>
                            </Row>


                          </Col>
                        </Row>
                      }

                      {(this.state.routeId) && false &&
                        <Row>
                          <Col xs={24}>
                            <Row>
                              <Col xs={16} style={{ paddingBottom: 10 }}>
                                <FormItem label={`${assignString} Driver`} style={{ marginTop: 10 }}>
                                  <Select
                                    style={{ width: '100%' }}
                                    className="routes-input"
                                    onChange={e => this.handleChange('selectedDriverId', e)}
                                    defaultValue={`${assignString}`}
                                    value={this.state.selectedDriverId}
                                  >
                                    {this.state.users.map(province =>
                                      province.id !== this.state.driverId && <Option key={province.id} value={province.id}>{province.full_name}</Option>)
                                    }
                                  </Select>
                                </FormItem>
                              </Col>
                              <Col xs={8} style={{ padding: 10 }}>
                                <Button className="assignDriver" onClick={this.assignDrivertoRoute} loading={this.state.assignStatus}>{`${assignString}`}</Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      }
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xs={12}>
                      <div className="info-text">
                        <h4>{I18n.t('info.duration')}</h4>
                        {calculateTimeDuration(route.r_start_datetime, route.r_end_datetime)}
                      </div>
                    </Col>
                  </Row> */}


                  <Row className="center timeLine marginTop20" >

                    <Col sm={24} xs={24} md={24} lg={24} >
                      {
                        this.state.stops.length > 0 &&
                        <Timeline>
                          {
                            this.state.stops.map((task, index) => {
                              const actual_start_date_time = task.actual_start_date_time ? moment(new Date(task.actual_start_date_time)).format('LLL') : 'NA';
                              const actual_end_date_time = task.actual_end_date_time ? moment(new Date(task.actual_end_date_time)).format('LLL') : 'NA';
                              const suggested_start_date_time = task.suggested_start_date_time ? moment(new Date(task.suggested_start_date_time)).format('LLL') : 'NA';
                              const suggested_end_date_time = task.suggested_end_date_time ? moment(new Date(task.suggested_end_date_time)).format('LLL') : 'NA';
                              const scheduled_start_date_time = task.scheduled_start_date_time ? moment(new Date(task.scheduled_start_date_time)).format('LLL') : 'NA';
                              const scheduled_end_date_time = task.scheduled_end_date_time ? moment(new Date(task.scheduled_end_date_time)).format('LLL') : 'NA';
                              const estimated_arrival_date_time = task.estimated_arrival_date_time ? moment(new Date(task.estimated_arrival_date_time)).format('LLL') : 'NA';
                              const estimated_departure_date_time = task.estimated_departure_date_time ? moment(new Date(task.estimated_departure_date_time)).format('LLL') : 'NA';
                              const contact_phone_number_1 = this.fomatString(task.contact_phone_number_1);
                              const contact_phone_number_2 = this.fomatString(task.contact_phone_number_2);
                              const contact_name = this.fomatString(task.contact_name);
                              const contact_email = this.fomatString(task.contact_email);
                              const service_duration = task.service_duration ? task.service_duration : '0';

                              return (<Timeline.Item key={index} dot={<Button type="primary" shape="circle">{index + 1}</Button>} color="red">
                                <Card title={task.location_name} className="currentOrder_details" size="small">
                                  <span style={{ float: 'right', marginTop: '-35px' }}>{task.status}</span>
                                  <Row>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="user" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Contact Name</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{contact_name}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="phone" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Contact No.1</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{contact_phone_number_1}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="phone" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Contact No.2</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{contact_phone_number_2}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="mail" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Email</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{contact_email}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {/* s */}
                                    {/* <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Actual End Time</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          {
                                            <div className="info-text">
                                              <span>{actual_end_date_time}</span>
                                            </div>
                                          }
                                        </Col>
                                      </Row>
                                    </Col> */}
                                  </Row>
                                  <Row>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Scheduled Start</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{scheduled_start_date_time}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Scheduled End</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{scheduled_end_date_time}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Estimated Arrival</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{estimated_arrival_date_time}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Estimated Departure</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{estimated_departure_date_time}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Actual Arrival</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{actual_start_date_time}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="calendar" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Actual Departure</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{actual_end_date_time}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                      <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                          <div className="textCenter">
                                            <Icon type="clock-circle" />
                                          </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                          <div className="info-text textBold">Service Duration</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                          <div className="info-text">
                                            <span>{service_duration}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  {task.pickup_order_items.length > 0 &&
                                    <Card size="small" title={`Pickup items (${task.pickup_order_items.length})`} className="marginTop20">
                                      {
                                        task.pickup_order_items.map(x => {
                                          return <StopItemDetails order_item={x} />
                                        })
                                      }
                                    </Card>
                                  }

                                  {task.drop_order_items.length > 0 &&
                                    <Card size="small" title={`Drop  items (${task.drop_order_items.length})`} className="marginTop20">
                                      {
                                        task.drop_order_items.map(x => {
                                          return <StopItemDetails order_item={x} />
                                        })
                                      }
                                    </Card>
                                  }


                                  {/* <Leg picture={task.pictures} signature={task.signature} /> */}


                                  {/* <Fragment>
                                    {
                                      sortedTasks.map((task, index) => (<InfoTask key={task.id} task={task} cardNumber={index + 1} />))
                                    }
                                  </Fragment> */}


                                </Card>
                              </Timeline.Item>)
                            })
                          }
                        </Timeline>
                      }

                    </Col>
                  </Row>
                </Row>
              </Col>
              <Col xs={7}>


                {(this.state.routeId) && false &&
                  <Row>
                    <Col xs={24}>
                      <Row>
                        <Col xs={16} style={{ paddingBottom: 10 }}>
                          <FormItem label={`${assignString} Driver`} style={{ marginTop: 10 }}>
                            <Select
                              style={{ width: '100%' }}
                              className="routes-input"
                              onChange={e => this.handleChange('selectedDriverId', e)}
                              defaultValue={`${assignString}`}
                              value={this.state.selectedDriverId}
                            >
                              {this.state.users.map(province =>
                                province.id !== this.state.driverId && <Option key={province.id} value={province.id}>{province.full_name}</Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={8} style={{ padding: 10 }}>
                          <Button className="assignDriver" onClick={this.assignDrivertoRoute} loading={this.state.assignStatus}>{`${assignString}`}</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                }
              </Col>
            </Row>


          </Spin>
          {(this.state.errors) &&
            <Row>
              {FormErrors(this.state.errors)}
            </Row>
          }
          {(this.state.googleMap) &&
            this.renderFormModal()
          }
        </div>

        {this.state.detailsVisible && <Drawer
          title={`${I18n.t('order.details')} ${this.state.currentOrder.customer_order_number ? '(#' + this.state.currentOrder.customer_order_number + ')' : ''}`}
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.detailsVisible}
          width="80%"
        >
          <OrderDetails currentOrder={this.state.currentOrder} />
        </Drawer>
        }


        {this.state.editForm && <Drawer
          placement="left"
          closable={false}
          onClose={this.closeEditFormModal}
          visible={this.state.editForm}
          width="80%"
        >
          <EditOrderDetails
            orderObject={this.state.orderObject}
            currentOrder={this.state.currentOrder}
            handleCancel={this.closeEditFormModal}
            handleSuccess={this.handleSaveSuccess}
          />
        </Drawer>
        }


        {this.state.listVisible && <Drawer
          title="Orders"
          placement="right"
          closable={false}
          onClose={this.closeEditFormModal}
          visible={this.state.listVisible}
          width="80%"
        >
          <OrdersList
            data={this.state.orderInfo}
            editClick={id => this.handleEditClick(id)}
            openInfoModal={id => this.openInfoModal(id)}
            openTrackScreen={this.openTrackScreen}
            orderDetailsViewClick={id => this.handleOrderDetailsViewClick(id)}
            handleEditOrder={id => this.handleEditOrderClick(id)}
            rowSelection={rowSelection}
            screen_from="routes"
            handleSuccess={(message) => this.handleSaveSuccess(message)}
          />

          {this.state.routes.selectedOrderKeys.length > 0 && <Row>
            <Col xs={24} style={{ padding: '10px 10px' }} className="alignCenter">
              <Button className="buttonModal" onClick={this.closeListDrawer} style={{
                color: '#F5d358',
                borderRadius: 25,
                backgroundColor: '#040404',
              }}>Cancel</Button>


              <Button className="buttonModal" onClick={this.handleSave} loading={this.state.loading} disabled={this.state.routes.selectedOrderKeys.length > 0 ? false : true}>
                {this.state.routeId ? 'Update' : 'Create'} Route
              </Button>


            </Col>

          </Row>
          }

        </Drawer>
        }

      </div>
    );
  }
}
export default withRouter(Routes);