import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popconfirm, Button, Collapse } from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { RecoveriesApis } from "../../api/RecoveriesApi";
import ChangeRecoveryTime from "../../components/orders/ChangeRecoveryTime";
import moment from "moment";
import BaseModal from "../../components/BaseModal";
import DateTimeSelector from "../../common/DateTimeSelector";
import { LineHaulApi } from "../../api/LineHaulApi";
import OptingAppointment from "../../components/orders/OptingAppointment";

const { Panel } = Collapse;

const LineHualActions = ({
  selectedInfo,
  handleClear,
  filter,
  refreshList,
  handleDelete,
  changeStatus,
  isMilitaryTime,
  selectedRows,
  resetList,
  warehouseFilter,
  organizationSettings
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errors, setErrors ] = useState([]);
  const [ formData, setFormData ] = useState({
    drop_date: null,
    drop_time: null,
  });

  // const handleDelete = () => {
  //   multipleDeletion(selectedInfo.selectedKeys);
  // };

  const enableForm = () => {
    setShowForm(true);
  };

  const hideForm = () => {
    setShowForm(false);
  };

  const [ showForm, setShowForm ] = useState(false);

  

  const handleOnChange = (key, value) => {
    const updatedFormData = { ...formData };
    updatedFormData[key] = value
    if (key === "drop_time") {
      if (isEmpty(updatedFormData[ 'drop_date' ])) {
        updatedFormData[ 'drop_date' ] = moment()
      }
    } else if (key === "drop_date") {
      if (isEmpty(updatedFormData[ 'drop_time' ])) {
        updatedFormData[ 'drop_time' ] = moment()
      }
    }
    setFormData(updatedFormData);    
  };

  const handleSchedule = () => {
    if (!moment.isMoment(formData.drop_date)) {
      alertMessage("Please select a date");
      return;
    }
    if (!moment.isMoment(formData.drop_time)) {
      alertMessage("Please select a time");
      return;
    }
    const payload = {
      appointment_date: `${formData.drop_date.format("YYYY-MM-DD")} ${formData.drop_time.format("HH:mm:ss")}`,
      linehaul_ids: selectedInfo.selectedKeys,
    };
    setIsLoading(true);
    LineHaulApi.bulkSchedule(payload)
      .then((result) => {
        if(result.success){
        setIsLoading(false);
        alertMessage("Scheduled successfully" , "success");
        hideForm();
        refreshList();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err);
      })
  };

  const [ isScheduling, setIsScheduling ] = useState(false);

  const renderAppointmentWindow = () => {
    return (
      <BaseModal title="Schedule" visible={ showForm } onCancel={ hideForm }>
        <DateTimeSelector
          dateProps={ {
            label: "Scheduled Date",
            format: "Do MMM YYYY",
            value: formData.drop_date,
            onChange: (date) => {
              // When current date is selected
              handleOnChange("drop_date", date);
            },
            style: { width: "100%", height: "100%" },
            disabledDate: (current) =>
              current && current <= moment().startOf("day"),
          } }
          timeProps={ {
            label: "Scheduled Time",
            format: isMilitaryTime ? "HH:mm" : "hh:mm A",
            showTime: {
              format: isMilitaryTime ? "HH:mm" : "hh:mm A",
              use12Hours: !isMilitaryTime,
            },
            isMilitaryTime,
            onChange: (time) => {
              handleOnChange("drop_time", time);
            },
            value: formData.drop_time,
            style: { width: "100%", height: "100%", marginTop: "5px" },
            minuteStep: 1,
            className: "formMaterialTimePicker",
          } }
          gridStyle={ {
            row: { type: "flex", gutter: 2 },
            dateCol: { span: 12 },
            timeCol: { span: 12 },
          } }
        />
        <Collapse
          bordered={ false }
          defaultActiveKey={ [ "1" ] }
          className="marginTop10"
        >
          <Panel
            header="Selected Orders"
            key="1"
            style={ {
              marginTop: "10px",
            } }
          >
            <p>
              { selectedRows
                .map((order) => order.customer_order_number)
                .join(", ") }
            </p>
          </Panel>
        </Collapse>
        <Row className="buttonsList">
          <Col>
            <Button
              icon="close"
              type="danger"
              onClick={ hideForm }
            >
              { I18n.t("general.cancel") }
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={ isScheduling }
              onClick={ handleSchedule }
              icon="check"
            >
              { I18n.t("general.schedule") }
            </Button>
          </Col>
        </Row>
      </BaseModal>
    );
  };

  const renderDeleteButton = () => {
    return (
      checkServiceExistance("ORDEL") && (
        <Popconfirm
          placement="rightTop"
          title={ I18n.t("messages.delete_confirm") }
          onConfirm={ handleDelete }
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" className="buttonStyle" icon="delete">
            Delete
          </Button>
        </Popconfirm>
      )
    );
  };

  const renderChangeStatusButton = () => {
    return (
      (filter == "assigned" || filter == "dispatched") && (
        <Button
          type="primary"
          className="assignDriver"
          onClick={ changeStatus }
          disabled={ !(selectedInfo.selectedKeys.length > 0) }
          icon="setting"
        >
          { I18n.t("general.change_status") }
        </Button>
      )
    );
  };

  const renderErrorsModal = () => {
    return (
      errors.length > 0 &&
      Modal.error({
        title: "Errors",
        content: (
          <Row>
            <Col>
              <h4>{ errors.join(", ") }</h4>
            </Col>
          </Row>
        ),
        onOk: () => {
          setErrors([]);
          refreshList();
        },
      })
    );
  };

  return (
    <Fragment>
      <Row className="bottom_fixed">
        <Col xs={ 24 } style={ { padding: "10px 10px" } } className="alignCenter">
          <Button
            type="default"
            className="clearButtonStyle"
            onClick={ handleClear }
            icon="close"
          >
            { I18n.t("list.clear_lable") }
          </Button>
          { filter == "pending" && (
            <Fragment>
               <OptingAppointment
                resetOrders={resetList}
                orders={selectedInfo.selectedKeys || []}
                filter={filter === 'pending' ? 'VERIFIED' : (filter === '' ? '' : filter.toUpperCase())}
                warehouseFilter={ warehouseFilter }
                organizationSettings={organizationSettings}
                selectedOrders={selectedInfo.selectedRows || []}
                showSelectedOrder={true}
              />
             {/* <Button
              type="primary"
              onClick={ enableForm }
              style={ { marginTop: "2px", marginLeft: 10 } }
              className="buttonYellow"
              icon="calendar"
            >
              { I18n.t("general.schedule") }
            </Button> */}
            </Fragment>
           
          ) }
          { showForm && renderAppointmentWindow() }
          { filter == "pending" && renderDeleteButton() }
          { filter !== "pending" && renderChangeStatusButton() }
        </Col>
      </Row>
      { renderErrorsModal() }
    </Fragment>
  );
};

LineHualActions.propTypes = {
  filter: PropTypes.string.isRequired,
  selectedInfo: PropTypes.shape.isRequired,
  handleClear: PropTypes.func.isRequired,
  refreshQuotes: PropTypes.func.isRequired,
  setIsListLoading: PropTypes.func.isRequired,
  multipleDeletion: PropTypes.func.isRequired,
};

export default LineHualActions;
