import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'
import { Table, Button, Icon, Input, Popover, Tooltip } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import EditIcon from '../components/common/EditIcon';
import DeleteIcon from '../components/common/DeleteIcon';
import I18n from './I18n';

const EditableTable = ({ columns, data, onSave, onChange , isEditing , showCancelBtn }) => {
  const [ tableData, setTableData ] = useState([]);
  const [ unsavedChanges, setUnsavedChanges ] = useState(false);
  const editableInputRef = useRef(null);

  useEffect(() => {
    if (unsavedChanges) {
      onChange ? onChange(tableData) : null;
    }
  }, [ tableData ]);

  // on mount add a new row with empty values
  useEffect(() => {
      if (data.length === 0 && !isEditing) {
        if(tableData.length === 0)
        handleAddRow();
      } else {
        const newData = _.cloneDeep(data)
        setTableData(newData.map(rec => ({...rec, name: rec.name,key: uuidv4()})));
      }
  }, [ data ]);

  const cleanTableData = (data) => {
    return data.map((row) => {
      const { key, editing, ...cleanRow } = row;
      return cleanRow;
    });
  };
  
  const handleAddRow = () => {
    // const newRow = { key: uuidv4(), editing: true };
    // new row with empty column values
    const columnsWithoutKey = columns.filter((column) => column.dataIndex !== 'key');
    const newRow = columnsWithoutKey.reduce((acc, column) => {
      acc[ column.dataIndex ] = '';
      return acc;
    }, { key: uuidv4(), editing: true });
    setTableData([ ...tableData, newRow ]);
    setUnsavedChanges(true);
  };

  const handleDeleteRow = (key) => {
    let updatedTableData = tableData.filter((row) => row.key !== key);
    if (updatedTableData.length === 0) {
      const newRow = { key: uuidv4(), editing: true, name: '', value: '' };
      updatedTableData = [newRow]
      setTableData([newRow]);
    }
      setTableData(updatedTableData);
    setUnsavedChanges(true);
    if (onSave) {
      onSave(updatedTableData.name);
    }
  };

  const handleSaveRow = (key) => {
    const updatedTableData = [ ...tableData ];
    const index = _.findIndex(updatedTableData, { key });
    updatedTableData[ index ] = _.update(updatedTableData[ index ], 'editing', () => false);
    setTableData(updatedTableData);
    if (onSave) {
      onSave(cleanTableData(updatedTableData));
    }
    setUnsavedChanges(false);
  };

  const handleEditRow = (key) => {
    const updatedTableData = [ ...tableData ];
    updatedTableData.forEach((row) => {
      row.editing = row.key === key;
    });
    setTableData(updatedTableData);
    setTimeout(() => {
      editableInputRef.current.focus();
      // reset
      editableInputRef.current = null;
    }, 0);
  };

  const handleCancelEditRow = (key) => {
    const updatedTableData = [ ...tableData ];
    const index = _.findIndex(updatedTableData, { key });
    updatedTableData[ index ] = _.update(updatedTableData[ index ], 'editing', () => false);
    setTableData(updatedTableData);
    setUnsavedChanges(false);
  };


  const handleInputChange = (record, fieldName, value) => {
    const updatedTableData = [ ...tableData ];
    const index = _.findIndex(updatedTableData, { key: record.key });
    updatedTableData[ index ][ fieldName ] = value;
    setTableData(updatedTableData);
    setUnsavedChanges(true);
  };

  // const handleSaveChanges = () => {
  //   if (onSave) {
  //     onSave(cleanTableData(tableData));
  //   }
  //   setUnsavedChanges(false);
  // };

  // const handleDiscardChanges = () => {
  //   setTableData(_.cloneDeep(data));
  //   setUnsavedChanges(false);
  // };
 

  const editableColumns = [ ...columns ];
  editableColumns.push({
    title: 'Action',
    dataIndex: 'action',
    render: (text, record) => {
      const { editing } = record;
      return (
        <div>
          { editing ? (
            <>
              <Button onClick={ () => handleSaveRow(record.key) } type="primary" size="small" icon="save">
                Save
              </Button>
              {showCancelBtn && (
                <Button onClick={ () => handleCancelEditRow(record.key) } style={ { marginLeft: 8 } } size="small">
                  Cancel
                </Button>
              )}
            </>
          ) : (
            <>
              <span onClick={ () => handleEditRow(record.key) } style={ { marginRight: 8 , cursor: 'pointer'} }>
                <Tooltip title={I18n.t("general.edit")}>
                  <span><EditIcon/></span>
                </Tooltip>
              </span>
              <span onClick={ () => handleDeleteRow(record.key) } style={ { marginRight: 8 , cursor: 'pointer'} }>
                <Tooltip title={I18n.t("general.delete")}>
                  <span><DeleteIcon/></span>
                </Tooltip>
              </span>
              {/* if its last data point add plus sign  */ }
              { _.last(tableData).key === record.key && (
                <span onClick={ () => handleAddRow(record.key) } style={ { marginRight: 8 , cursor: 'pointer'} }> 
                <Tooltip title={I18n.t("general.add")}>
                  <span><Icon type="plus-circle" style={ { color: '#000000'} } /></span>
                  </Tooltip>
                </span>
              ) }
            </>
          ) }
        </div>
      );
    },
  });

  const editableData = tableData.map((row) => {
    const { editing } = row;
    return {
      ...row,
      ...Object.keys(row).reduce((acc, fieldName) => {
        if (fieldName !== 'key' && fieldName !== 'editing') {
          const column = columns.find((c) => c.dataIndex === fieldName);
          if (column && column.isEditable) {
            acc[ fieldName ] = editing ? (
              <Input
                value={ row[ fieldName ] }
                onChange={ (e) => handleInputChange(row, fieldName, e.target.value) }
                ref={ (node) => {
                  if (node && !editableInputRef.current) {
                    editableInputRef.current = node;
                  }
                } }
              />
            ) : row[ fieldName ];
          } else {
            acc[ fieldName ] = row[ fieldName ];
          }
        }
        return acc;
      }, {})
    };
  });



  return (
    <>
      <Table
        columns={ editableColumns }
        dataSource={ editableData }
        pagination={ false }
        bordered
        size="small"
      />
      {/* <Button onClick={ handleAddRow }>
        Add Row
      </Button> */}
      {/* { unsavedChanges && (
        <div style={ { marginTop: 16 } }>
          <Button type="primary" onClick={ handleSaveChanges } style={ { marginRight: 8 } }>
            Save Changes
          </Button>
          <Button onClick={ handleDiscardChanges }>
            Discard Changes
          </Button>
        </div>
      ) } */}
    </>
  );
};
export default EditableTable;

EditableTable.propTypes = {
  isEditing: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    dataIndex: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  showCancelBtn : PropTypes.bool,
}

EditableTable.defaultProps = {
  isEditing : false,
  showCancelBtn : true,
}