import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  FormItem,
  DatePicker,
  Button,
  Radio,
  Select,
} from "../../../common/UIComponents";
import AppConfig from "../../../config/AppConfig";
import AccountCodesFilter from "../../../containers/Filters/AccountCodesFilter";
import WarehouseCodeFilter from "../../common/WarehouseCodeFilter";
import I18n from "../../../common/I18n";
import _ from "lodash";
import { isEmpty } from "../../../common/Common";

const { RangePicker } = DatePicker;
const defaultFilterValues = {
  fromDate: moment(),
  toDate: moment(),
  status: "BOTH",
  accountCodes: [],
  warehouseIds: "",
};
class InvoiceStatusReportFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || Object.assign({}, defaultFilterValues),
      accounts: this.props.accounts,
      filteredAccounts: [],
    };
  }

  componentDidMount() {
    const { warehouseFilter } = this.props;
    const currentWhId =
      warehouseFilter?.selectedWarehouses?.length &&
      _.isArray(warehouseFilter.selectedWarehouses)
        ? warehouseFilter.selectedWarehouses[0]
        : warehouseFilter.selectedWarehouses;

    defaultFilterValues.warehouseIds = currentWhId || "";

    this.handleFilterPlaceHolderChange("warehouseIds", currentWhId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.setState({ filter: _.cloneDeep(this.props.filter) });
    }
  }

  setFilteredAccounts = (filteredAccount = []) => {
    const { accountCodes } = this.state.filter;
    const filteredAccCodes =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.id) : [];

    if (accountCodes?.length) {
      const filteredAccountCodes = filteredAccCodes.filter((acc_code) =>
        accountCodes.includes(acc_code)
      );
      this.handleFilterPlaceHolderChange("accountCodes", filteredAccountCodes);
    } else {
      this.handleFilterPlaceHolderChange("accountCodes", []);
    }
  };

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function") {
        this.props.handleChildFilterChange(filter);
      }
    });
  };

  handleDateRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: moment(),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.setState({
      filter: dateFilter,
    });
  };

  handleFilterPlaceHolderChange = (element, value) => {
    const filter = Object.assign({}, this.state.filter, {
      [element]: value,
    });

    this.setState(
      {
        filter,
      },
      () => {
        if (element === "warehouseIds") {
          const { accounts } = this.state;
          const { warehouses } = this.props.warehouseFilter;
          const { warehouseIds } = this.state.filter;

          if (isEmpty(warehouseIds)) {
            const filteredAccounts = accounts;
            this.setState({ filteredAccounts });
          } else {
            const filteredAccounts = accounts?.filter((account) =>
              account.warehouse_ids.some((id) => warehouseIds.includes(id))
            );
            this.setState({ filteredAccounts }, () => {
              this.setFilteredAccounts(filteredAccounts);
            });
          }
        }
      }
    );
  };

  clearForm = () => {
    const filter = _.cloneDeep(defaultFilterValues);
    this.setState({ filter }, () => {
      this.handleFilterPlaceHolderChange("warehouseIds", filter.warehouseIds);
      this.props.onChange(filter, () => {
        this.props.onSearch();
      });
    });
  };

  _renderRangePicker = () => {
    return (
      <FormItem label="Date Range">
        <RangePicker
          value={[this.state.filter.fromDate, this.state.filter.toDate]}
          ranges={AppConfig.dateRanges}
          format={AppConfig.dateFormat}
          style={{ width: "100%" }}
          allowClear
          onChange={this.handleDateRangeChange}
        />
      </FormItem>
    );
  };

  _renderWhFilter = () => {
    const { warehouses } = this.props.warehouseFilter;
    return (
      <FormItem label={I18n.t("location.location")}>
        <Select
          style={{ width: "100%" }}
          onChange={(value) =>
            this.handleFilterPlaceHolderChange("warehouseIds", value)
          }
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          dropdownMatchSelectWidth={false}
          value={this.state.filter.warehouseIds}
        >
          {warehouses.map((warehouse) => (
            <Select.Option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
    );
  };

  _renderStatusTypeFilter = () => {
    return (
      <FormItem label={I18n.t("account.billing.filter.filter_results_with")}>
        <Radio.Group
          onChange={(e) => {
            this.handleFilterPlaceHolderChange("status", e.target.value);
          }}
          value={this.state.filter.status}
        >
          <Radio value={"SUCCESS"}>Success</Radio>
          <Radio value={"FAILED"}>Failed</Radio>
          <Radio value={"BOTH"}>{I18n.t("general.both")}</Radio>
        </Radio.Group>
      </FormItem>
    );
  };

  handleSearch = () => {
    this.props.onSearch(this.state.filter);
  };

  render() {
    return (
      <Fragment>
        <div>
          {this._renderWhFilter()}
          <AccountCodesFilter
            SelectedAccountCodes={this.state.filter.accountCodes}
            accounts={this.state.filteredAccounts}
            size="default"
            onValueChange={this.handleFilterPlaceHolderChange}
            showLabel={true}
            valueField="id"
            styleObject={{ width: "100%", marginBottom: 5 }}
          />
          {this._renderRangePicker()}
          {this._renderStatusTypeFilter()}
          <div style={{ padding: "10px 16px", textAlign: "center" }}>
            <Button
              type="danger"
              onClick={this.clearForm}
              icon="redo"
              disabled={this.props.disableButton}
            >
              Reset
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              onClick={() => this.handleSearch()}
              type="primary"
              icon="search"
              disabled={this.props.disableButton}
            >
              Search
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

InvoiceStatusReportFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
InvoiceStatusReportFilter.defaultProps = {};
export default withRouter(InvoiceStatusReportFilter);
