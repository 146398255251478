import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import {
  Col,
  Row,
  Search,
  Select,
  Spin,
  Tooltip,
  Dropdown,
  Icon,
  message,
  FormItem,
  Button,
  SideBySideFormItem,
  Radio
} from "../../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  navigateToAccount,
} from "../../../common/Common";
import AppConfig from "../../../config/AppConfig";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../../context/WarehouseContext";
import {
  base64ToExcel,
  base64ToPDF,
  convertToTags,
  limitString,
  removeSpecialCharacters,
  renderAlertMessage,
  responseToPDF,
} from "../../../helpers/common";
import { OrgContext } from "../../../context/OrgContext";
import exportIcon from "../../../../assets/images/exportFile.png";
import I18n from "../../../common/I18n";
import { fetchOrgAccounts } from "../../../api/Account";
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import DunningReportFilter from "./DunningReportFilter";
import { UserContext } from "../../../context/UserContext";
import { ScreenKeys } from "../../../containers/constants";
import { exportDunningNotice, fetchDunningReport } from "../../../api/Reports";
import userStore from "../../../stores/UserStore";
import DunningReportsSummaryList from "./DunningReportsSummaryList";
import DunningReportsDetailsList from "./DunningReportsDetailsList";
import BaseModal from "../../BaseModal";
import { validateEmailList } from "../../billing_screen/helpers";

const defaultFilter = {
  sortBy: "none",
  sortByType: "descend",
  fromDate: moment(),
  toDate: moment(),
  accountCodes: [],
  warehouseIds: "",
  invoiceAmountFilter: "WITH_AMOUNT",
};

class DunningReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dunningReports: [],
      pagination: {},
      reportLoading: false,
      accountsLoading: false,
      exportLoading: false,
      recordsPerPage: props.recordsPerPage,
      filterWindow: false,
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      accounts: [],
      appliedFiltersTags: [],
      categoryType: "SUMMARY",
      isExportModalVisible: false,
      exportEmail: [],
      exportMailfile_type: 'EXCEL'
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    const { warehouseData } = this.props;

    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    this.setState(
      (prevState) => ({
        ...prevState,
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          warehouseIds: currentWhId,
        },
      }),
      () => {
        this.getAccounts();
        this.getDunningReports();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseData.selectedWarehouses, this.props.warehouseData.selectedWarehouses)) {
      const { warehouseData } = this.props;

      const currentWhId =
        warehouseData?.selectedWarehouses?.length &&
          _.isArray(warehouseData.selectedWarehouses)
          ? warehouseData.selectedWarehouses[0]
          : warehouseData.selectedWarehouses;

      this.setState(
        (prevState) => ({
          ...prevState,
          filterPlaceHolder: {
            ...prevState.filterPlaceHolder,
            warehouseIds: currentWhId,
          },
        }),
        () => {
          this.getAccounts();
          this.getDunningReports();
          this.computeTags(this.state.filterPlaceHolder);
        }
      );
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }

  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  getDunningReports = () => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const { filterPlaceHolder } = this.state;
    const payload = {
      organization_id,
      page: this.tableOptions.pagination.current || 1,
      per_page: this.state.recordsPerPage,
      view_by: this.state.categoryType,
      account_id: filterPlaceHolder.accountCodes,
      wh_location_id: filterPlaceHolder.warehouseIds,
      from_date: filterPlaceHolder?.fromDate ? 
      filterPlaceHolder.fromDate.format('YYYY-MM-DD HH:mm:ss Z') : null,
      to_date: filterPlaceHolder?.toDate ? filterPlaceHolder.toDate.format('YYYY-MM-DD HH:mm:ss Z') : null,
      invoice_amount_filter: filterPlaceHolder.invoiceAmountFilter,
    };
    this.setState({ reportLoading: true });
    fetchDunningReport(payload).then((result) => {
      if (result.success) {
        const dunningReports = result.reports_data || [];
        const pagination = result.pagination.pagination || {};
        this.setState({
          dunningReports,
          pagination,
          reportLoading: false,
        });
      } else {
        renderAlertMessage(result.errors);
        this.setState({
          reportLoading: false,
          dunningReports: [],
        });
      }
    });
  };

  getAmountTypeLabel = (amountType) => {
    switch (amountType) {
      case "WITH_AMOUNT":
        return "With Amount";
      case "WITHOUT_AMOUNT":
        return "Without Amount";
      case "BOTH":
        return "Both";
      default:
        return "";
    }
  };

  computeTags = (filterPlaceHolder) => {
    const { warehouseData } = this.props;
    const { warehouses } = warehouseData;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const keys = [
      "fromDate",
      "toDate",
      "accountCodes",
      "warehouseIds",
      "invoiceAmountFilter",
    ];

    const filteredData = {
      fromDate: {
        label: "Report Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouseIds: {
        label: "Warehouse",
        value:
          warehouses.find(
            (warehouse) => warehouse.id === filterPlaceHolder.warehouseIds
          )?.name || "",
        onClose: () => {
          this.handleOnFilterRemoval("warehouseIds");
        },
        closeable:
          !isEmpty(currentWhId) &&
          filterPlaceHolder.warehouseIds === currentWhId
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder.accountCodes.includes(account.id)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },
      invoiceAmountFilter: {
        label: "Amount Type",
        value: this.getAmountTypeLabel(filterPlaceHolder.invoiceAmountFilter),
        onClose: () => {
          this.handleOnFilterRemoval("invoiceAmountFilter");
        },
        closeable: !["WITH_AMOUNT"].includes(
          filterPlaceHolder?.invoiceAmountFilter
        ),
      },
    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "warehouseIds":
        filterVariable.warehouseIds = currentWhId;
        break;
      case "invoiceAmountFilter":
        filterVariable.invoiceAmountFilter = defaultFilter.invoiceAmountFilter;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      // Callback to ensure the state is updated before further actions
      this.handleSearch();
    });
  };

  handleSearch = (filter = {}) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, filter);
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getDunningReports();
      }
    );
  };

  handleExport = () => {
    const { currentOrg = {} } = this.props.orgData;
    const { filterPlaceHolder } = this.state;

    const organization_id = currentOrg.id;
    this.setState({ exportLoading: true });

    const payload = {
      organization_id,
      page: this.tableOptions.pagination.current || 1,
      per_page: this.state.recordsPerPage,
      view_by: this.state.categoryType,
      account_id: filterPlaceHolder.accountCodes,
      wh_location_id: filterPlaceHolder.warehouseIds,
      from_date: filterPlaceHolder?.fromDate ? filterPlaceHolder.fromDate.format("YYYY-MM-DD HH:mm:ss Z") : null,
      to_date: filterPlaceHolder?.toDate ? filterPlaceHolder.toDate.format("YYYY-MM-DD HH:mm:ss Z") : null,
      invoice_amount_filter: filterPlaceHolder.invoiceAmountFilter,
    };
    message.loading("Export to PDF in progress..");
    exportDunningNotice(payload)
      .then((result) => {
        if (result.success) {
          message.destroy();
          alertMessage("File Exported Successfully!!", "success", 10);
          base64ToPDF(
            result.file,
            `${currentOrg.code}_${moment().format(
              "MM-DD-YYYY_hh-mm-ss"
            )}_dunning_notice`
          );
          // responseToPDF(
          //   result.file,
          //   `${currentOrg.code}_${moment().format(
          //     "MM-DD-YYYY_hh-mm-ss"
          //   )}_dunning_notice`
          // );
        } else {
          message.destroy();
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        this.setState({ exportLoading: false });
      });
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getDunningReports();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getDunningReports();
        }
      );
    }
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  clearFilter = () => {
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const updatedFilterPlaceHolder = {
      ...defaultFilter,
      warehouseIds: currentWhId,
    };

    this.setState(
      {
        filterPlaceHolder: updatedFilterPlaceHolder,
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage;
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getDunningReports();
        updateRecordsPerPage(ScreenKeys.DUNNING_NOTICE, value);
      }
    );
  };

  _renderSearchBar = () => (
    <Dropdown
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 550,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <DunningReportFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            orgData={this.props.orgData}
            warehouseFilter={this.props.warehouseData}
            disableButton={this.state.searchLoading}
            accounts={this.state.accounts}
          />
        </div>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "5px" }}>Filter</span>
        <Icon
          style={{
            color: "black",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "5px",
          }}
          type="filter"
        />
      </div>
    </Dropdown>
  );

  _renderHeaderInfo = () => {
    return (
      <Row className="page-header">
        <Col xs={12}>{I18n.t("dunning_notice.title")}</Col>
        <Col xs={10}></Col>
        <Col xs={2} className="alignRight headerOptionDiv">
          {this._renderSearchBar()}
        </Col>
      </Row>
    );
  };

  _renderAppliddFilter = () => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={24} style={{ marginTop: 15 }}>
          <AppliedFiltersTags
            tags={this.state.appliedFiltersTags}
            clearFilter={() => this.clearFilter()}
          />
        </Col>
      </Row>
    );
  };

  _renderLists = () => {
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    switch (this.state.categoryType) {
      case "SUMMARY":
        return (
          <Fragment>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24} md={18} offset={3}>
                <DunningReportsSummaryList
                  scroll={{ y: "calc(100vh - 305px)", x: "max-content" }}
                  navigateToAccount={(account_id) =>
                    navigateToAccount(
                      this.state.accounts,
                      account_id,
                      this.props.history
                    )
                  }
                  data={this.state.dunningReports}
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    this.onTableChange(pagination, filter, sorter, currentTable)
                  }
                />
              </Col>
            </Row>
          </Fragment>
        );
      case "DETAILS":
        return (
          <Fragment>
            <Row style={{ marginTop: 30 }}>
              <Col>
                <DunningReportsDetailsList
                  scroll={{ y: "calc(100vh - 305px)", x: "max-content" }}
                  navigateToAccount={(account_id) =>
                    navigateToAccount(
                      this.state.accounts,
                      account_id,
                      this.props.history
                    )
                  }
                  data={this.state.dunningReports}
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    this.onTableChange(pagination, filter, sorter, currentTable)
                  }
                />
              </Col>
            </Row>
          </Fragment>
        );
      default:
        return <Fragment />;
    }
  };

  onCategoryChange = (element, value) => {
    this.setState({ [element]: value, balReports: [] }, () => {
      this.tableOptions.pagination.current = 1;
      this.getDunningReports();
    });
  };

  _renderListOperation = () => {
    const stopTypes = [
      { key: "SUMMARY", label: "Summary" },
      { key: "DETAILS", label: "Details" },
    ];
    return (
      <Row type="flex" align="middle" gutter={16} justify="end">
        {/* <Col>
          <Button
            disabled={
              this.state.exportLoading || _.isEmpty(this.state.dunningReports)
            }
            className="cursorPointer"
            type="primary"
            size="small"
            shape="round"
            onClick={() => this.toggleEmailModal(true)}
          >
            {this.state.exportLoading ? "Exporting..." : "Export To Email "}{" "}
            <Icon type="file-excel" theme="filled" />
          </Button>
        </Col> */}
        <Col
          className="alignRight"
        // style={{ marginTop: -5, marginBottom: 5, marginRight: 10 }}
        >
          <Fragment>
            {this.state.exportLoading && <Icon type="loading" />}&nbsp;
            <span
              style={{
                stylefontSize: "0.85rem",
                fontWeight: "bold",
              }}
            >
              {this.state.exportLoading ? "Exporting..." : "Export :"}
            </span>
            &nbsp;&nbsp;
            <span className="filterSetIcon">
              <img
                src={exportIcon}
                className="cursorPointer"
                onClick={
                  this.state.exportLoading ? null : () => this.handleExport()
                }
                style={{
                  width: 22,
                  marginTop: "-2px",
                  marginLeft: 3,
                }}
              />
            </span>
          </Fragment>
        </Col>


        <Col>
          <span className="text">{I18n.t("general.view_by")}:</span>
          &nbsp;&nbsp;
          <Select
            value={this.state.categoryType}
            showSearch
            onChange={(e) => this.onCategoryChange("categoryType", e)}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0
            }
            style={{ width: 200 }}
            size="small"
          >
            {stopTypes.map((rec) => (
              <Select.Option key={rec.key} value={rec.key}>
                {rec.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col />
        <Col md={4} style={{ textAlign: "right" }}>
          <RecordsPerPage
            onChange={this.onRecordChange}
            value={this.state.recordsPerPage}
            defaultValue={AppConfig.ordersPerPage}
          />
        </Col>
      </Row>
    );
  }

  toggleEmailModal = (value) => {
    this.setState({
      isExportModalVisible: value,
      exportEmail: []
    });
  };

  exportToEmail = () => {
    console.log(this.state.exportEmail, this.state.exportMailfile_type);

    this.toggleEmailModal(false);
  }

  handleInputChange = (inputType, value) => {
    if (inputType === "email") {
      const validEmails = validateEmailList(value);
      this.setState({ exportEmail: validEmails });
    } else if (inputType === "fileType") {
      this.setState({ exportMailfile_type: value });
    }
  };

  renderEmailExportModal = () => {
    return (
      <BaseModal
        title="Export To Email"
        onCancel={() => this.toggleEmailModal(false)}
        className="surveyModal"
        width="35%"
        style={{ top: 100, overflowY: "auto" }}
      >
        <h4 style={{ fontWeight: "bold", fontSize: "13px" }}>
          {I18n.t("general.export_orders_excel")}
        </h4>
        <Row>
          <Col>
            <FormItem
              label={I18n.t("general.file_type")}
              className="tagsInput emailForm"
            >
              <Radio.Group
                onChange={(e) =>
                  this.handleInputChange("fileType", e.target.value)
                }
                value={this.state.exportMailfile_type}
              >
                <Radio value="EXCEL">{I18n.t("general.excel")}</Radio>
                <Radio value="PDF">{I18n.t("general.export_pdf")}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={I18n.t("general.emails")}
              className="tagsInput emailForm"
            >
              <Select
                value={this.state.exportEmail}
                onChange={(value) =>
                  this.handleInputChange("email", value)
                }
                mode="tags"
                maxTagCount={1}
                tokenSeparators={[","]}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Enter or select Emails"
              >
                {this.props.userContext?.currentUser?.email ? (
                  <Option key={this.props.userContext.currentUser.email}>
                    {this.props.userContext.currentUser.email}
                  </Option>
                ) : (
                  ""
                )}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row justify="center" type="flex" gutter={16}>
          <Col>
            <Button type="danger" onClick={() => this.toggleEmailModal(false)}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() => this.exportToEmail()}>
              Export
            </Button>
          </Col>
        </Row>
      </BaseModal>
    );
  };


  render() {
    const pagination = {
      total: this.state.pagination.total_count || "",
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <this._renderHeaderInfo />
          <this._renderAppliddFilter />
          <this._renderListOperation />
          <Spin spinning={this.state.reportLoading} delay={1000}>
            <this._renderLists />
          </Spin>
        </div>
        {this.state.isExportModalVisible && this.renderEmailExportModal()}
      </div>
    );
  }
}
export const DunningReportsComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.DUNNING_NOTICE;
  const recordsPerPage = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );

  return (
    <DunningReports
      warehouseData={warehouseData}
      orgData={orgData}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default DunningReportsComponent;
