import { Call, ApiUrl, PageParams, ParseGeneralResponse } from './ApiUtils';
import { isEmpty } from '../common/Common';
import _ from "lodash"
import I18n from '../common/I18n';

export function fetchLocations(location_type = 'WH', page = 1, perPage = 1000 , skip_wh_check=false , org_id = null , user = null,searchText = "", filter = {}) {
  perPage = perPage ? perPage : 1000;
  let url = ApiUrl(`v2/locations?${PageParams(page, perPage)}&location_type=${location_type}&operation_code=WHI&user_id=${
      user
        ? user.id
        : ""
    }&skip_wh_check=${skip_wh_check}`);
    if(org_id){
      url = url + `&org_id=${org_id}`
    }
    if (!isEmpty(searchText)) {
      url = `${url}&search_text=${searchText}`;
    }
    if (!_.isEmpty(filter) && !isEmpty(filter.sortBy) && !isEmpty(filter.sortByType)) {
      url = `${url}&sort_by=${filter.sortBy}&sort_order=${I18n.t(`general.sort_${filter.sortByType}`)}`;
    }
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { locations: '', pagination: 'meta.pagination' },
  } , true );
}

export function saveLocation(isNew, data) {
  if (
    data.phone &&
    data.phone.length > 0 &&
    data.phone[0] !== '+') {
    data.phone = `+${data.phone}`;
  }
  data.operation_code = isNew ? 'WHC' : 'WHU';
  if (data.order_capacity) {
    const orderCapacityNumber = parseInt(data.order_capacity);
    data.order_capacity = orderCapacityNumber;
  }
  const url = isNew ? ApiUrl('v2/locations') : ApiUrl(`v2/locations/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { location: "location" },
  });
}

export function saveMapping(data) {
  data.operation_code = 'SWHZC';
  const url = ApiUrl('v2/delivery_zip/save_wh_zip_codes');
  return Call('post', url, data, ParseGeneralResponse);
}

export function getMappingDetails(id) {
  const url = ApiUrl(`v2/delivery_zip?warehouse_id=${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { dow_zipcodes: 'dow_zip_codes' },
  });
}


export function fetchLocationsByType(type) {
  const url = ApiUrl(`v2/locations?location_type=${type}&operation_code=GLBT`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { locations: '', pagination: 'meta.pagination' },
  });
}

export function fetchLocationsByZone(type = 'PICKUP', zone) {
  const url = ApiUrl(`v2/locations?location_type=${type}&zone_id=${zone}&operation_code=GLBT`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { locations: '', pagination: 'meta.pagination' },
  });
}


export function deleteLocation(id) {
  const url = ApiUrl(`v2/locations/${id}`);
  return Call('delete', url, {operation_code: 'WHD'}, ParseGeneralResponse);
}

export function fetchReviewsOfLocation(id, page, perPage) {
  const url = ApiUrl(`v2/feedbacks?location_id=${id}&page=${page}&perPage=${perPage}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { locations: '', pagination: 'meta.pagination' },
  });
}

export function fetchReviewAggregation(id) {
  const url = ApiUrl(`v2/feedbacks/feedback_aggr?location_id=${id}&operation_code=FEDBAG`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { feedbackAggr: '' },
  });
}

export function fetchZipcode() {
  const url = ('https://maps.googleapis.com/maps/api/geocode/json?address=thagarapuvala&sensor=true&key=AIzaSyCNNmfTGsBatXy77JEAcjxuHCR2WSxVhvg');
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { results: '' },
  });
}

export function getZipcodes(data, isNew = true) {
  data.operation_code = 'GZFGC';
  // const formData = setObjectToFormData(data);
  const url = isNew ? ApiUrl(`v2/delivery_zones/generate_zips_from_geo_codes?polygon_coords=${JSON.stringify(data.polygon_coords)}`) : ApiUrl('v2/delivery_zones/generate_zips_from_geo_codes');
  // const method = isNew ? 'post' : 'put';
  return Call('get', url, data, ParseGeneralResponse, { responseDataKeys: { zip_codes: 'zip_codes' } }, false);
}

export function fetchPredefinedWHLocs({search_text}) {
  const url = ApiUrl(`v2/locations/get_pre_defined_and_wh_locations?search_text=${search_text}&limit=5`);
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: { locations: "locations" },
  });
}
