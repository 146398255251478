import { Call, ApiUrl, PageParams, ParseGeneralResponse } from "./ApiUtils";
import { isEmpty } from "../common/Common";
import I18n from "../common/I18n";

export const PodReportApi = {
  fetch: (searchParams) => {
    const {
      page,
      perPage,
      fromDate = "",
      toDate = "",
      warehouse_id = "",
      as_export = "",
      sortBy = "",
      sortByType = "",
      export_option = "",
    } = searchParams;
    const start_date_time = fromDate.format("YYYY-MM-DD HH:mm:ss Z");
    const start_end_time = toDate.format("YYYY-MM-DD HH:mm:ss Z");
    let url = ApiUrl(
      `v2/export_customer_orders/customer_orders_pod_report?${PageParams(
        page,
        perPage
      )}&from_date=${start_date_time}&to_date=${start_end_time}&warehouse_ids=${warehouse_id}&sort_by=${sortBy}&sort_order=${I18n.t(
        `general.sort_${sortByType}`
      )}`
    );
    if (export_option) {
      url = `${url}&export_option=${export_option}`;
      return Call("get", url, {}, ParseGeneralResponse, {
        responseDataKeys: { file: "file", fileName: "filename" },
      });
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { orders: "orders", pagination: "meta.pagination" },
    });
  },
  fetchOrders: (data) => {
    const {
      status = I18n.t("order.default_status"),
      fromDate,
      todate,
      scheduleDayFilter = scheduleDayFilter || "Custom",
      page = 1,
      perPage = AppConfig.perPage,
      orderNumber,
      sortBy = "none",
      sortByType = "ascend",
      account_codes = [],
      warehouseId = "",
      searchKeyType = "",
      searchKeyValue = "",
      scheduleOrdersType = I18n.t("order.filters.schedule_orders.both_key"),
      globalSearch = false,
      zone_ids = [],
      order_type = "",
      exception_code = [],
      saveNSearch = false,
      vehicle_type = "",
      stats_only = false,
      is_dispatch = false,
      search_type= '',
      export_option,
      mails,
      total_count
    } = data;
    let url = "";
    const zoneIds = !isEmpty(zone_ids) && 
    zone_ids.length ? zone_ids.map((zone) => zone.value) : [];
    if (!isEmpty(orderNumber)&& (search_type !== 'CONTAINS')) {
      url = ApiUrl(
        `v2/export_customer_orders/customer_orders_pod_report?customer_order_number=${orderNumber}&global_search=${globalSearch}&${PageParams(
          page,
          perPage
        )}&status=${status}`
      );
      if(is_dispatch){
        url = `${url}&from_date=${fromDate}&to_date=${todate}`
      }
    } else {
      url = ApiUrl(
        `v2/export_customer_orders/customer_orders_pod_report?status=${status}&from_date=${fromDate}&to_date=${todate}&${PageParams(
          page,
          perPage
        )}&schedule_day_filter=${scheduleDayFilter}&sort_by=${sortBy}&sort_order=${I18n.t(
          `general.sort_${sortByType}`
        )}&account_ids=${account_codes}&schedule_orders_type=${scheduleOrdersType}&zone_ids=${zoneIds}&order_type=${order_type}&vehicle_type=${vehicle_type}`
      );
      if (!isEmpty(searchKeyType) && !isEmpty(searchKeyValue)) {
        url = `${url}&${searchKeyType}=${searchKeyValue}&search_type=${search_type? search_type : 'EQUALS'}&search_key=${searchKeyType}&search_value=${searchKeyValue}`;
      }
    }
  
    url = `${url}&operation_code=COI`;
    if (export_option) {
      url = `${url}&export_option=${export_option}&mails=${mails}&total_count=${total_count}`;
      return Call("get", url, {}, ParseGeneralResponse, {
        responseDataKeys: { file: "file", fileName: "filename" },
      });
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { orders: "export_customer_orders", pagination: "meta.pagination" },
    });
  }
};

export function SendPodEmail(emails,remarks,customerOrderId){
  const url = ApiUrl(`v2/customer_orders/send_email_pods?emails=${emails}&remarks=${remarks}&customer_order_id=${customerOrderId}`);
  return Call("post", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      success: "success",
      message: "message",
    },
  });
}
