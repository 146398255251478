import { Tabs } from 'antd'
import React from 'react'
import { InsightsBilling } from './InsightsBilling'
import I18n from '../../../common/I18n';
import { InsightsDriverPerformance } from './InsightsDriverPerformance';
import { InsightsSettlements } from './InsightsSettlements';

export const FeInsights = (props) => {
   const onChange = (key) => {
    };
  return (
    <div className="card-container tabHover">
       <Tabs defaultActiveKey='InsightsBilling' type="card" onChange={onChange}>
       <Tabs.TabPane tab={I18n.t("insights.billing")} key="InsightsBilling">
            <InsightsBilling dispContext={props.dispContext} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={I18n.t("insights.driver_performance")} key="InsightsDriverPerformance">
            <InsightsDriverPerformance dispContext={props.dispContext} />
       </Tabs.TabPane>
       <Tabs.TabPane tab={I18n.t("insights.driver_settlements")} key="InsightsdriverSettlements">
        <InsightsSettlements dispContext={props.dispContext} />
         </Tabs.TabPane>
       </Tabs>
    </div>
  )
}
