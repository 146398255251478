import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  optionalPageParams,
} from "./ApiUtils";
import { isEmpty } from "../common/Common";
import userStore from "../stores/UserStore";
import AppConfig from "../config/AppConfig";

export const NotificationTemplatesApi = {
  fetch: (
    organization_id = "",
    account_id = "",
    render_type = "",
    page = 1,
    perPage = AppConfig.perPage,
    eventCode = ""
  ) => {
    const org_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(`v2/template_messages?organization_id=${organization_id}&event_code=${eventCode}`);
    if (!isEmpty(account_id)) {
      url = `${url}&account_id=${account_id}`;
    }
    if (render_type === "list") {
      url += `&render_type=list`;
      // url += `&render_type=list&${optionalPageParams(page, perPage, false)}`;

    }
    return Call(
      "get",
      url,
      {},
      ParseGeneralResponse,
      {
        responseDataKeys: {
          templates: "template_messages",
          // pagination: "meta.pagination",
        },
      },
      false
    );
  },
  manage: (isNew, data) => {
    data.organization_id = userStore.getStateValue("selectedOrg");
    if (!isEmpty(data.account_id)) {
      data.operation_code = isNew ? "ATMI" : "ATMU";
    } else {
      data.operation_code = isNew ? "OTMI" : "OTMU";
    }
    const url = isNew
      ? ApiUrl("v2/template_messages")
      : ApiUrl(`v2/template_messages/${data.id}`);
    const method = isNew ? "post" : "put";
    return Call(method, url, data, ParseGeneralResponse, 
      {
        responseDataKeys: { message: "message", record: "record" },
      }, 
    true);
  },
  delete: (id) => {
    const url = ApiUrl(`v2/template_messages/${id}`);
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: { message: "message" },
    });
  },
  delete_all: (id) => {
    const url = ApiUrl(
      "v2/template_messages/delete_account_message_templates?account_id=" + id
    );
    return Call("delete", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  getEvents: () => {
    let url = ApiUrl(`v2/template_messages/get_events`);
    return Call(
      "get",
      url,
      {},
      ParseGeneralResponse,
      {
        responseDataKeys: {
          events: "events",
          pagination: "meta.pagination",
        },
      },
      false
    );
  },
  getParams: () => {
    let url = ApiUrl(`v2/template_messages/message_template_params`);
    return Call(
      "get",
      url,
      {},
      ParseGeneralResponse,
      {
        responseDataKeys: {
          tokens: "message_template_params",
          pagination: "meta.pagination",
        },
      },
      false
    );
  },
  fetchDefaultMessage: (notification_to, event_code, communication_mode) => {
    const org_id = userStore.getStateValue("selectedOrg");
    let url = ApiUrl(
      `v2/template_messages/get_default_notification_message?organization_id=${org_id}&notification_to=${notification_to}&event_code=${event_code}&_mode_of_communication=${communication_mode}`
    );
    return Call(
      "get",
      url,
      {},
      ParseGeneralResponse,
      {
        responseDataKeys: {
          template_message: "template_message"
        },
      },
      false
    );
  },
};


export function deleteNotificationTemplates(id) {
  const url = ApiUrl(`v2/template_messages/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}
