import React, { Fragment, useState } from "react";
import AppConfig from "../../../config/AppConfig";
import { RearrangableTable } from "../../common/RearrangableTable";
import { formatByTimeConfig } from "../../../helpers/common";
import moment from "moment";
import I18n from "../../../common/I18n";

export const QuotesList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, SetData] = useState([
    {
      key: 1,
      quote_order_number: 1234,
      type_of_order:'Pickup',
      quote_date: "2022-10-01T12:00:00Z",
      warehouse_address: "Las Vegas",
      account_name: "ABC123",
      account_code: "abc123",
      expiry_date: "2022-11-01T12:00:00Z",
      weight: 500,
      level_of_service: "White Glove",
      quote_amount: 50000,
      status: "APPROVED",
      created_by: "ADMIN",
      // reference_1: "700214577",
      // reference_2: "123456",
      hawb: "123456",
      mawb: "123456",
    },
    {
      key: 2,
      quote_order_number: 3456,
      type_of_order:'Delivery',
      quote_date: "2022-10-02T12:00:00Z",
      warehouse_address: "Las Vegas",
      account_name: "ABC123",
      account_code: "bcd123",
      expiry_date: "2022-11-02T12:00:00Z",
      weight: 500,
      level_of_service: "Threshold",
      quote_amount: 10000,
      status: "PENDING",
      created_by: "ADMIN",
      // reference_1: "700214577",
      // reference_2: "123456",
      hawb: "123456",
      mawb: "123456",
    },
  ]);

  const [columns, SetColumns] = useState([
    {
      key: "quote_no",
      title: I18n.t("quotes.quote_no"),
      dataIndex: "quote_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "quote_date",
      title: I18n.t("quotes.quote_date"),
      dataIndex: "quote_date",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: "city",
      title: "Origin -> Destination",
      dataIndex: "warehouse_address",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "expiry_date",
      title: I18n.t("quotes.expiry_date"),
      dataIndex: "expiry_date",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: "weight",
      title: (
        <Fragment>
          {I18n.t("order.weight")} ({AppConfig.weight_unit})
        </Fragment>
      ),
      dataIndex: "weight",
    },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "level_of_service",
    },
    {
      key: "quote",
      title: I18n.t("order.quote"),
      dataIndex: "quote_amount",
    },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "created_by",
      title: I18n.t("order.last_updated_by"),
      dataIndex: "created_by",
    },
    // {
    //   key: "reference_1",
    //   title: `${I18n.t("order.reference")} 1`,
    //   dataIndex: "reference_1",
    // },
    // {
    //   key: "reference_2",
    //   title: `${I18n.t("order.reference")} 2`,
    //   dataIndex: "reference_2",
    // },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "quoteList_list_view_columns";
  return (
    <div>
      <RearrangableTable
        columns={columns}
        filteredColumns={filteredColumns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        data={data}
        madeChanges={madeChanges}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setFilteredColumns={setFilteredColumns}
        setMadeChanges={setMadeChanges}
        showDragIconForSelectedColumns={false}
        controlDrag={false}
        configType={configType}
        dispContext={props.dispContext}
      />
    </div>
  );
};
