import {  Col, Icon, Popconfirm, Row, Tooltip, } from "antd";
import PropTypes from "prop-types";
import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";

class IntegrationsList extends BaseList {
	constructor(props) {
		super(props);
		this.columns = [
			{
				key: "name",
				title: I18n.t("general.name"),
				dataIndex: "name",
				render: (data, record) => <span >{data}</span>,
				width : "47.5%",
			},
			{
				key: "code",
				title: I18n.t("general.code"),
				dataIndex: "code",
				render: (data, record) => <span className="textUpperCase">{data}</span>,
				width : "47.5%",
			},
			// {
			// 	key : "status",
			// 	title : I18n.t("general.status"),
			// 	dataIndex : "status",
			// 	render : (data, record) => {
			// 		if(data) {
			// 			return I18n.t("general.active");
			// 		} else {
			// 			return I18n.t("general.inactive");
			// 		}
			// 	},
			// 	width : "31.67%",
			// },
			{
				key: "actions",
				fixed:"right",
				title: "",
				render: (data) => {
					const { handleDelete, handleEdit } = this.props;
					const { id } = data;
					return (
						<div className="line-actions">						
						   <Tooltip title={I18n.t("general.edit")}>
						    <span><EditIcon handleClick={() => handleEdit(id)}/></span>
						   </Tooltip>								
			            <Popconfirm
			              placement="left"
			              title={I18n.t("messages.delete_confirm")}
			              onConfirm={() => handleDelete(id)}
			              okText="Yes"
			              cancelText="No"
			             >
						 <Tooltip title={I18n.t("general.delete")}>
						  <span><DeleteIcon/></span>
						  </Tooltip>
			            </Popconfirm>							
					 </div>
					);
				},
				align: "center",
				width : "5%",
			},
		];
	}
}
export default IntegrationsList;

IntegrationsList.propTypes = {
	handleDelete: PropTypes.func.isRequired,
	handleEdit: PropTypes.func.isRequired,
	pagination : PropTypes.object,
	currentAuthorization : PropTypes.string,
	onAuthorization : PropTypes.func,
};

IntegrationsList.defaultProps = {
	handleDelete: () => {},
	handleEdit: () => {},
	pagination : {
		position : "none",
	},
	currentAuthorization : "",
	onAuthorization : () => {},
};