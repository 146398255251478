import userStore from '../stores/UserStore';
import {
    Call,
    ApiUrl,
    PageParams,
    ParseGeneralResponse,
    setObjectToFormData,
  } from './ApiUtils';

export const MobileConfiguration = {
  create: (payload) => {
    payload.operation_code = 'MCC'
    const url = ApiUrl('v2/mobile_configurations')
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { mobile_configurations : 'mobile_configurations' },
    })
  },

    update: (id,payload) => {
    payload.operation_code = 'MCU'
    const url = ApiUrl('v2/mobile_configurations/' + id)
    const formData = setObjectToFormData(payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { mobile_configurations : 'mobile_configurations'  },
    })
  },

  fetch: (organizationId, page =1, perPage ='') => {  
    const url = ApiUrl(`v2/mobile_configurations?${PageParams(
      page,
      perPage,
    )}&organization_id=${organizationId}&operation_code=MCII`);
    return Call('get', url, {}, ParseGeneralResponse, {
      responseDataKeys: { mobile_configurations: 'mobile_configurations', pagination: "meta.pagination" },
    })
  },
  delete: (id) => {
    const url = ApiUrl('v2/mobile_configurations/' + id)
    return Call('delete', url, {operation_code:'MCD'}, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  fetchOne: (id) => {
    
    const url = ApiUrl(`v2/mobile_configurations/${id}?operation_code=MCS`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  },
}
