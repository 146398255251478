import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import { checkServiceExistance, toScreenConfigFormat } from "../../helpers/common";
import { ScreenKeys } from "../../containers/constants";
// import { setGridConfigSetting } from "../api/DisplaySettings";
import { alertMessage } from "../../common/Common";
import { dvCheckboxColumn, getDriverSettlemetCols, getPrePlanCol, getWarehouseCols } from "../../components/common/columnDefs";
import { getStoredColumns,getConfiguredColumns } from "../../components/orders/helpers";
import { setGridConfigSetting } from "../../api/DisplaySettings";
import MyGridComponent from "../dispatches/ReactDataGrid";


const GridListComponent = (props) => {
  const { rawDataSource, accountCodesWithExceededLimit = [], userContext, tableName} = props;
  const { updateColWidths, currentUser } = userContext || {};
  const [ filteredRowData, setFilteredRowData ] = useState([]);
  const [ isInitial, setIsInitial ] = useState(true);
  

  const getDriverColumns = () => {
    const tableColMapping = {
      [ScreenKeys.AIR_CARGO_SETTLEMENTS] : getDriverSettlemetCols(props)
    };
    const cols  = _.get(tableColMapping, tableName, []);
    return cols;
  }

  const allCols = getDriverColumns();
  
  console.log(allCols)
  const [ columnDefs, setColumnDefs ] = useState(allCols);

  useEffect(() => {
    const modifiedData = rawDataSource?.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    });
    setFilteredRowData(modifiedData);
  }, [ rawDataSource, accountCodesWithExceededLimit ]);

  const ProcessCols = () => {
    const columns = [];
    const hasSomeConfiguration = props.displayConfiguration && (props.displayConfiguration.userConfiguration || props.displayConfiguration.orgConfiguration);
    const storedColumns = getStoredColumns(currentUser, tableName);
   // const allCols = getDriverColumns()
    //columns.push(...allCols);
    if (!hasSomeConfiguration) {
      columns.push(...allCols);
    } else {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      columns.push(...getConfiguredColumns(allCols, storedColumns, userConfiguration, orgConfiguration, tableName));
    }

    setColumnDefs(columns);
  };


  useEffect(() => {
    ProcessCols();
  }, [ props.displayConfiguration, props.vhTypes, props.status ]);

  // const handleSaveColumnWidth = ({ colId, newWidth, colState }) => {
  //   if(!isInitial){
  //     let newColumnDefs = [];
  //     colState.forEach((col) => {
  //       if (col.colId === '0') {
  //         newColumnDefs.push(checkboxColumn);
  //       }
  //       else if (col.colId === 'items') {
  //         newColumnDefs.push(itemsColdef);
  //       }
  //       else {
  //         const foundCol = _.find(columnDefs, { field: col.colId });
  //         newColumnDefs.push({ ...foundCol, width: col.width });
  //       }
  //     }
  //     );
  //     setColumnDefs(newColumnDefs);
  
  //     const finalColumnDefs = colState.map((col) => { return { key: col.colId, width: col.width }; });
  //     updateColWidths(tableName, toScreenConfigFormat(finalColumnDefs));
  //   } else {
  //     setIsInitial(false)
  //   }
  // };

  const handleSaveColumnWidth = ({ colId, newWidth, colState }) => {
    let newColumnDefs = [];
    colState.forEach((col) => {
      
      if (col.colId === '0') {
        console.log(col.colId, dvCheckboxColumn)
        newColumnDefs.push(dvCheckboxColumn);
      }
      else if (col.colId === 'items') {
        newColumnDefs.push(itemsColdef);
      }
      else {
        const foundCol = _.find(columnDefs, { field: col.colId });
        newColumnDefs.push({ ...foundCol, width: col.width });
      }
    }
    );
    setColumnDefs(newColumnDefs);

    const finalColumnDefs = colState.map((col) => { return { key: col.colId, width: col.width }; });
    updateColWidths(tableName, toScreenConfigFormat(finalColumnDefs));
  };

  const handleSaveRowIdx = useCallback((colState) => {
    let newOrder = _.compact(colState
      .filter((col) => col.isVisible) // filter out columns where isVisible is false
      .map((col) => col.field || col.colId));    
    const { currentUser } = userContext;
    const { _id: userId, currentOrg } = currentUser;
    const data = {
      organization_id: currentOrg._id,
      config_type: tableName,
      config_value: newOrder,
      user_id: userId
    };
    setGridConfigSetting(data).then((result) => {
      if (result.success) {
        props.dispContext("userConfiguration", {
          ...props.displayConfiguration.userConfiguration,
          [tableName]: [...newOrder],
        });
        alertMessage(I18n.t("messages.saved"), "success", 10);
      } else {
        alertMessage(result.errors[0], "error", 10);
      }
    }
    );

  }, [ tableName ]);
  
    const handleSaveColIdx = useCallback((colState) => {
      let newOrder = _.compact(colState
        .filter((col) => !col.hide)
        .map((col) => col.field || col.colId));
      const { currentUser } = userContext;
      const { _id: userId, currentOrg } = currentUser;
      const data = {
        organization_id: currentOrg._id,
        config_type: tableName,
        config_value: newOrder,
        user_id: userId
      };
      setGridConfigSetting(data).then((result) => {
        if (result.success) {
          props.dispContext("userConfiguration", {
            ...props.displayConfiguration.userConfiguration,
            [tableName]: [...newOrder],
          });
          alertMessage(I18n.t("messages.saved"), "success", 10);
        } else {
          alertMessage(result.errors[0], "error", 10);
        }
      }
      )
    }
      , [ tableName ]);
  
    const handleRowChecked = (colId, checked) => {
      const newColumnDefs = columnDefs.map((col) => {
        if (col.field || col.colId === colId) {
          return { ...col, hide: !checked };
        }
        return col;
      });
      setColumnDefs(newColumnDefs);
    };

    const handleCellData = (params) => {
      const { colDef, data, newValue, oldValue } = params; // Extract relevant data
      if(colDef.field === "item_value"){
        let value = oldValue;
        if(params.data.type_of_order !== "MANUAL" && parseFloat(newValue) < 0){
          alertMessage("Amount should not be lessthan $0.00", 'error')
          value = parseFloat(oldValue) 
        } else{
          const isExceeds = parseFloat(newValue) > parseFloat(params.data.eligible_charge)  
          value = isExceeds ? parseFloat(oldValue) : parseFloat(newValue) 
          if(isExceeds){
            alertMessage(`Amount should not exceeds $` + parseFloat(data.eligible_charge), 'error')
          }
        }
        
        props.onChange(params.data.id)
        params.node.setDataValue(params.colDef.field,value); // Update
        let per = "0";
        if(!isEmpty(params.data.eligible_charge) &&  !isEmpty(value) && parseFloat(params.data.eligible_charge) !== 0){
          per =  (parseFloat(value) * 100) / parseFloat(params.data.eligible_charge);
          per =  ""+per.toFixed(2);
        } 
        props.handleUpdateSettlemntData(params.data.id, {
          item_value: ""+value,
          prev_item_value: ""+value,
          settlement_percentage: per,
        })
        }
      
    }
  
  return (
    <>
    <MyGridComponent
      columnDefs={ columnDefs }
      rowData={ filteredRowData }
      onRowSelection={ props.onRowSelection }
      // onSortChanged={ props.onSortChanged }
      onSortChanged={ (colState) => {
        const sortedCol = colState[ 0 ];
        console.log('colState', colState);
        const { colId, sort } = sortedCol; props.onSort(colId, sort);
      }
      }
      totalCols={ allCols }
      saveColumnWidth={ handleSaveColumnWidth }
      onColumnMoved={ handleSaveColIdx }
      onRowMoved={ handleSaveRowIdx}
      setCurrentGridRef={ props.setCurrentGridRef }
      handleColumnVisibilityChange={props.handleColumnVisibilityChange}
      showRerraangeModal={props.showRerrangeableModal}
      closeModal={ props.closeModal }
      isRowSelectable={ props.isRowSelectable }
      onRowDataUpdated= {props.onRowDataUpdated}
      showPagination={props.showPagination}
      pagination={props.pagination}
      getPageWiseData={props.getPageWiseData}
      onPerPageChanged={props.onPerPageChanged}
      handleRowSelection={ handleRowChecked }
      enableRowClick={props.enableRowClick}
      getRowStyle={props.getRowStyle}
      handleCellData={handleCellData}
    />
    </>
  );
};


export default GridListComponent;

GridListComponent.propTypes = {
  rawDataSource: PropTypes.array,
  accountCodesWithExceededLimit: PropTypes.array,
  userContext: PropTypes.object,
  tableName: PropTypes.string,
  onRowSelection: PropTypes.func,
  onSort: PropTypes.func,
  vhTypes: PropTypes.array,
  displayConfiguration: PropTypes.object,
  accountsList: PropTypes.array,
  zonesList: PropTypes.array,
  handleColumnVisibilityChange: PropTypes.func,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  isRowSelectable: PropTypes.func
};

GridListComponent.defaultProps = {
  rawDataSource: [],
  accountCodesWithExceededLimit: [],
  userContext: {},
  tableName: '',
  onRowSelection: () => { },
  onSort: () => { },
  vhTypes: [],
  displayConfiguration: {},
  accountsList: [],
  zonesList: [],
  handleColumnVisibilityChange: () => { },
  modalOpen: false,
  closeModal: () => { },
  isRowSelectable: () => { }

};