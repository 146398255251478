import React from "react";
import moment from "moment";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";

function ListLocations(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      name: "Location 1",
      location_code: "LOC001",
      l_address: {
        street: "123 Main St",
        city: "City",
        state: "State",
        country: "Country",
        postal_code: "12345",
      },
      email: "location1@example.com",
      first_name: "John Doe",
      phone: "123-456-7890",
      link : 'http:www.google.com',
      zones : "1234"    
    },
    {
      key: 2,
      name: "Location 2",
      location_code: "LOC002",
      l_address: {
        street: "456 Oak St",
        city: "City",
        state: "State",
        country: "Country",
        postal_code: "67890",
      },
      email: "location2@example.com",
      first_name: "Jane Doe",
      phone: "987-654-3210",
      link : 'http:www.google.com',
      zones: "4567"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "location_code",
      title: "Code",
      dataIndex: "location_code",
    },
    {
      key: "l_address",
      title: "Address",
      dataIndex: "l_address",
      render: (address) => {
        const { street, city, state, country, postal_code } = address;
        return `${street}, ${city}, ${state}, ${country} ${postal_code}`;
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "first_name",
      title: "Contact Name",
      dataIndex: "first_name",
    },
    {
      key: "phone",
      title: "Phone",
      dataIndex: "phone",
    },
    {
      key: "link",
      title: "Track Link",
      dataIndex: "link",
    },
    {
      key: "zones",
      title: "Delivery Zones",
      dataIndex: "zones",
    },

  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = "warehouse_listing";

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={setSelectedColumns}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
  />
  )

}

export default ListLocations;