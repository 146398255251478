import React from "react";
import { Icon } from "../common/UIComponents";

// SVG content
const IntransitTruckSvg = ({
  color = "#33393D",
  width = "24",
  height = "24",
  className,
}) => (
  <svg
    viewBox="0 0 24 24"
    focusable="false"
    data-icon="assign"
    width={width}
    height={height}
    fill={color}
    aria-hidden="true"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.3073 8.47467L14.4153 5.81733C14.0493 4.73067 13.034 4 11.8873 4H10C10 2.89733 9.10267 2 8 2H0.5C0.224 2 0 2.224 0 2.5C0 2.776 0.224 3 0.5 3H4.83333C5.10933 3 5.33333 3.224 5.33333 3.5C5.33333 3.776 5.10933 4 4.83333 4H0.5C0.224 4 0 4.224 0 4.5C0 4.776 0.224 5 0.5 5H3.5C3.776 5 4 5.224 4 5.5C4 5.776 3.776 6 3.5 6H0.5C0.224 6 0 6.224 0 6.5C0 6.776 0.224 7 0.5 7H2.16667C2.44267 7 2.66667 7.224 2.66667 7.5C2.66667 7.776 2.44267 8 2.16667 8H0.666667V12.6667C0.666667 13.7693 1.564 14.6667 2.66667 14.6667C3.53467 14.6667 4.268 14.1073 4.544 13.3333H10.1233C10.3993 14.1073 11.1327 14.6667 12.0007 14.6667C12.9527 14.6667 13.748 13.9973 13.9487 13.1053L15.05 12.5607C15.22 12.4767 15.328 12.3033 15.328 12.1133L15.3333 8.634C15.3333 8.58 15.3247 8.526 15.3073 8.47467ZM2.66667 13.6667C2.11533 13.6667 1.66667 13.218 1.66667 12.6667C1.66667 12.1153 2.11533 11.6667 2.66667 11.6667C3.218 11.6667 3.66667 12.1153 3.66667 12.6667C3.66667 13.218 3.218 13.6667 2.66667 13.6667ZM12 13.6667C11.4487 13.6667 11 13.218 11 12.6667C11 12.1153 11.4487 11.6667 12 11.6667C12.5513 11.6667 13 12.1153 13 12.6667C13 13.218 12.5513 13.6667 12 13.6667ZM10 8V5H11.8873C12.604 5 13.2387 5.45667 13.4673 6.13533L14.0933 8H10Z"
      fill={color}
      className={className}
    />
  </svg>
);

// Icon component
const IntransitTruckIcon = (props) => {
  const { styling = {}, className = "" } = props;
  return (
    <>
      <Icon
        component={() => <IntransitTruckSvg {...props} className={className} />}
        style={styling}
        {...props}
      />
    </>
  );
};

export default IntransitTruckIcon;