import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Upload,
  Icon,
  Spin,
  Select,
} from "../../common/UIComponents";
import React, { useState, Fragment } from "react";
import "antd/dist/antd.css";
import { dummyRequest } from "../../helpers/common";
import { LableSettingApi } from "../../api/LableSettingApi";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
// import { fetchShortFormAccounts } from "../../api/Account";

const imgProps = { height: 120, width: 120, margin: -6 };
function LabelForm(props) {
  const [name] = useState(props.Data.name);
  const [code] = useState(props.Data.code);
  const [width] = useState(props.Data.width || "102");
  const [height] = useState(props.Data.height || "152");
  const [isEdit] = useState(props.Data.id ? true : false);
  const [labelCode,setLabelCode] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [sampleLabel, setSampleLabel] = useState(
    props.Data.sample_label && props.Data.sample_label.url
      ? props.Data.sample_label.url
      : null
  );
  const { onCancel } = props;

  const handleChange = (info) => {
    const { form } = props;
    getBase64(info.file.originFileObj, (image) => {
      form.setFieldsValue({ sample_label: info.file.originFileObj });
      setSampleLabel(image);
    });
  };
  

  // const getLabelCode = () => {
  //   fetchShortFormAccounts.then((result) => {
  //     if (result.success) {
  //       setLabelCode(result.labelCode)
  //     } else {
  //       alertMessage(result.errors[0], 'error', 10)
  //     }
  //   })
  // }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { validateFields } = props.form;
    validateFields((err, values) => {
      values.organization_id = props.organization;

      if (!err) {
        if (isEdit) {
          const id = props.Data.id;
          setIsLoading(true);
          LableSettingApi.update(id, { id, ...values }).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"), "success");
              setIsLoading(false);
              onCancel();
            } else {
              result.errors.map((error) => {
                alertMessage(error, "error");
              });
              setIsLoading(false);
            }
          });
        } else {
          const data = {
            organization_id: props.organization,
            name: values.name,
            code: values.code,
            width: values.width ? values.width : "102",
            height: values.height ? values.height : "152",
            sample_label: values.sample_label,
          };
          setIsLoading(true);
          LableSettingApi.create(data).then((result) => {
            if (result.success) {
              alertMessage(I18n.t("messages.saved"), "success");
              setIsLoading(false);
              onCancel();
            } else {
              if (result.errors) {
                alertMessage(result.errors, "error", 10);
              }
              setIsLoading(false);
            }
          });
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <Fragment>
      <Spin spinning={isLoading} delay={1000}>
        <div className="OrderApi_Container">
          <Form onSubmit={handleSubmit}>
            <Row gutter={8}>
              <Col xs={12}>
                <Row>
                  <Col>
                    <Form.Item label={I18n.t("lable_settings.label_name")}>
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "Please Enter Label Name",
                          },
                        ],
                        initialValue: name,
                      })(<Input type="text" value={name} />)}
                    </Form.Item>
                  </Col>

                  <Form.Item
                    label={I18n.t("lable_settings.label_code")}
                    className="ant-form-item-required"
                  >
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          validator: (_, value) => {
                            if (isEmpty(value)) {
                              return Promise.reject("Please Enter Label Code");
                            } else if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
                              return Promise.reject("Label Code is Invalid");
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ],
                      initialValue: code,
                    })(
                      // <Input
                      //   value={code}
                      //   style={{
                      //     textTransform: "uppercase",
                      //   }}
                      // />
                      <Select
                         showSearch
                         placeholder="Select"
                        //  onChange={handleChange}
                         
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        
                        <Option value="ACCELERATED">Accelerated</Option>
                        <Option value="AMERICANLH">American Linehaul</Option>
                        <Option value="FAIR">Forward Air Format 1</Option>
                        <Option value="FAIR2">Forward Air Format 2</Option>
                        <Option value="STANDARD">Standard</Option>
                        <Option value="NEW_PILOT">New Pilot</Option>
                        <Option value="OLD_PILOT">Old Pilot</Option>
                        <Option value="TREND_TRANSPORT">Trend Transport</Option>
                        <Option value="FORWARD_AIR">Forward Air</Option>
                        <Option value="ALL_STATES">All States</Option>
                        <Option value="EFW">Estes Forwarding</Option>
                        <Option value="CEVA">CEVA</Option>
                        {/* {labelCode.map((item) => (
                          <Select.Option
                             key={item.code}
                             value={item.code}
                          
                          >{`${item.label}`}
                          </Select.Option> 
                        ))}  */}
                      </Select>
                    )}
                  </Form.Item>
                </Row>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={I18n.t("lable_settings.sample_file")}
                  style={{ marginLeft: "23%" }}
                >
                  {getFieldDecorator("sample_label", {
                    // valuePropName: 'fileList',
                    // getValueFromEvent: normFile,
                  })(
                    <Upload
                      showUploadList={false}
                      onChange={handleChange}
                      accept="image/png, image/jpeg"
                      listType={!sampleLabel ? "picture-card" : null}
                      customRequest={dummyRequest}
                    >
                      {sampleLabel ? (
                        <div className="borderRadius">
                          <img
                            src={sampleLabel || null}
                            style={{ ...imgProps }}
                            alt="label"
                            className="bearded-young-man"
                          />
                        </div>
                      ) : (
                        <div>
                          <Icon type="plus" />
                          <div className="ant-upload-text">
                            {I18n.t("general.upload")}
                          </div>
                        </div>
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={8}>
              <Col xs={12}>
                <Form.Item label={I18n.t("lable_settings.width")}>
                  {getFieldDecorator("width", {
                    rules: [{ required: false }],
                    initialValue: width,
                  })(<Input type="number" value={width} />)}
                </Form.Item>
              </Col>

              <Col xs={12}>
                <Form.Item label={I18n.t("lable_settings.height")}>
                  {getFieldDecorator("height", {
                    rules: [{ required: false }],
                    initialValue: height,
                  })(<Input type="number" value={height} />)}
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Row type="flex" gutter={16} justify="center">
                  <Col>
                    <Button onClick={onCancel} icon="close">
                      {I18n.t("general.cancel")}
                    </Button>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit" icon="save">
                      {I18n.t("general.save")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Fragment>
  );
}

const Label = Form.create({ name: "label_form" })(LabelForm);

export default Label;
