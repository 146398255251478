import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import _ from "lodash"; // Assuming lodash is used but not imported
import AppConfig from "../../config/AppConfig";
import { checkNegitive } from "../../common/Common";
import Copyable from "../common/Copyable";
import { momentTime } from "../../helpers/date_functions";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";

export default class MonthlyCutOffList extends BaseList {
  constructor(props) {
    super(props);
    const { warehouseFilter = {} } = props;
    const selectedwh = warehouseFilter?.selectedWarehouses?.length && _.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses[0]
      : warehouseFilter.selectedWarehouses;
    const timeZoneId = getValueFromArrayOfObjects(
      warehouseFilter.warehouses, "id", selectedwh, "timeZoneId"
    );
    this.columns = [
      {
        key: "frequency_invoice_number",
        title: <b>{I18n.t("MonthlyCutoff.frequency_invoice_number")}</b>,
        dataIndex: "frequency_invoice_number",
        render: (data, record) => (
          <>
            {data} <Copyable text={data} />
          </>
        ),
      },
      {
        key: "frequency_type",
        title: <b>{I18n.t("MonthlyCutoff.invoice_frequency")}</b>,
        dataIndex: "frequency_type",
      },
      {
        key: "order_number",
        title: <b>{I18n.t("MonthlyCutoff.order_number")}</b>,
        dataIndex: "order_number",
        render: (data, record) => (
          <>
            {data} <Copyable text={data} />
          </>
        ),
      },
      {
        key: "hawb",
        title: <b>{I18n.t("MonthlyCutoff.hawb")}</b>,
        dataIndex: "hawb",
      },
      {
        key: "mawb",
        title: <b>{I18n.t("MonthlyCutoff.mawb")}</b>,
        dataIndex: "mawb",
      },
      // {
      //   key: "account_code",
      //   title: <b>{I18n.t("general.account_code")}</b>,
      //   dataIndex: "account_code",
      //   render: (data, record) => <span>{data}</span>,
      // },
      {
        key: "account_name",
        title: <b>{I18n.t("general.account_name")}</b>,
        dataIndex: "account_name",
        render: (data, record) => <span>{data}({record.account_code})</span>,
      },
      {
        key: "approval_date",
        title: <b>{I18n.t("MonthlyCutoff.invoice_generated_date")}</b>,
        render: (data, record) => momentTime(AppConfig.dateFormat, record.approval_date, timeZoneId),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        // sortOrder: props.filterPlaceHolder?.sortBy === 'approval_date' && props.filterPlaceHolder.sortByType,
      },
      {
        key: "actual_billing_date",
        title: <b>{I18n.t("MonthlyCutoff.cutoff_date")}</b>,
        render: (data, record) => (
          <>
            {momentTime(AppConfig.dateFormat, record.actual_billing_date, timeZoneId)}
          </>
        ),

        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        // sortOrder: props.filterPlaceHolder?.sortBy === 'actual_billing_date' && props.filterPlaceHolder.sortByType,
      },
      {
        key: "balance_invoice_amount",
        title: <b>{I18n.t("MonthlyCutoff.balance_invoice_amount")}</b>,
        dataIndex: "total_amount",
        render: (data, record) => checkNegitive(data),
      },
    ];
  }
}