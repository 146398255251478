import { isEmpty } from 'lodash';
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
  buildQueryString,
} from './ApiUtils';

export const CreditNotesApi = {
  getExtraPaidInvoices: ({organization_id, account_id, warehouse_id }) => {
      const url = ApiUrl(
        `v2/account_receivables/get_extra_paid_invoices?operation_code=ARGEPI&organization_id=${organization_id}&account_id=${account_id}&warehouse_id=${warehouse_id}`,
      );
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
            extra_paid_invoices: 'extra_paid_invoices',
        },
      });
  },
  fetchCreditNotes: ({organization_id, account_id = '', wh_id = '', page = '', perPage = '', sortBy = "", sortByType= "" }) => {
    let url = ApiUrl(
      `v2/account_receivables/credit_note_index?operation_code=ARCRNI&organization_id=${organization_id}&account_id=${account_id}&wh_id=${wh_id}&${PageParams(page,perPage)}`,
    );
    if (!isEmpty(sortBy) && !isEmpty(sortByType)) {
      url = `${url}&sort_by=${sortBy}&sort_order=${sortByType}`;
    }
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: {
        ar_credit_notes: 'ar_credit_notes',
        pagination: "meta.pagination"
      },
    });
  },

  manageCreditNote: (payload, isNew) => {
    payload.operation_code = isNew ?  "ARCRNC" : "ARCRNU"
    let url = ApiUrl(`v2/account_receivables/${isNew ? 'create_credit_note' : 'update_batch_payment'}`)
    const formData = setObjectToFormData(payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { batch_payment: 'batch_payment' },
    })
  },

  revertCreditNote: ({
    credit_id = "",
  }) => {
    const url = ApiUrl(
      `v2/account_receivables/revert_credit_note`
    );
    return Call("delete", url, {credit_id : credit_id}, ParseGeneralResponse, {
      responseDataKeys: { data: "", 
     },
    });
  },

  downloadCreditNote: (data) => {
    let url = ApiUrl(
      `v2/account_receivables/credit_note_pdf_download?${buildQueryString(data)}`
    );
    return Call("GET", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        file: "file",
        fileName: "file_name",
      },
    });
  }
  
};

