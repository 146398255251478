/* eslint-disable react/sort-comp */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Col, Input, FormItem, Row, DatePicker,
  Select, Radio, Button, Form
} from '../../common/UIComponents';
import I18n from '../../common/I18n';
import AppConfig from '../../config/AppConfig';

const { Search } = Input;
const { RangePicker } = DatePicker;

const LineHaulFilter = ({
  filterPlaceHolder,
  setFilterPlaceHolder,
  onSearch,
  history,
  resetFilter,
  warehouseFilter,
  accounts,
  defaultFilter,
  showDateFilter = true
}) => {
  const [ wHLoading, setWHLoading ] = useState(false);
  const { warehouses } = warehouseFilter;
  const [ filter, setFilter ] = useState({ ...defaultFilter });
  const { dateSearchBy, dateSearchValue } = filter;
  const [ showNoAppointmentOrders, setShowNoAppointmentOrders ] = useState(false);

  useEffect(() => {
    setFilter({ ...filterPlaceHolder });
  }, [ filterPlaceHolder ]);

  const handleFilterChange = (newFilter) => {
    setFilter({ ...filter, ...newFilter });
  };

  const handleAccountCodeChange = (e) => {
    handleFilterChange({ account_code: e });
  };

  const handleStartingPointChange = (e) => {
    handleFilterChange({ startingPoint: e });
  };

  const handleDestinationPointChange = (e) => {
    handleFilterChange({ destinationPoint: e });
  };

  const handleDateSearchChange = (dates, dateStrings) => {
    handleFilterChange({
      dateSearchValue: { fromDate: dates[ 0 ], toDate: dates[ 1 ] },
    });
  };

  const handleNoAppointmentOrdersChange = (e) => {
    setShowNoAppointmentOrders(e.target.value);
    if (e.target.value === true) {
      setFilter({ ...filter, dateSearchValue: { fromDate: null, toDate: null } });
    }else{
      setFilter({ ...filter, dateSearchValue: { fromDate: moment(), toDate: moment().add(6, 'day') } });
    }
  };

  const renderAccountsList = () => (
    <FormItem label={ I18n.t('general.account') }>
      <Select
        value={ filter.account_code }
        showSearch
        style={ { width: '100%' } }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={ wHLoading }
        onChange={ handleAccountCodeChange }
      >
        <Select.Option key="ACCOUNT_ALL" value="">
          -- All --
        </Select.Option>
        { accounts.map((account) => (
          <Select.Option key={ account.code } value={ account.code }>
            { `${account.name} (${account.code})` }
          </Select.Option>
        )) }
      </Select>
    </FormItem>
  );

  const renderWarehouseSelect = (label, value, onChange, disabledOptionId) => (
    <Col span={ 24 }>
      <FormItem label={ label } require>
        <Select
          showSearch
          value={ value }
          onChange={ onChange }
          style={ { width: '100%' } }
          filterOption={ (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          { warehouses.map((warehouse) => (
            <Select.Option
              key={ warehouse.id }
              value={ warehouse.id }
              disabled={ warehouse.id === disabledOptionId }
            >
              { warehouse.name }
            </Select.Option>
          )) }
        </Select>
      </FormItem>
    </Col>
  );

  return (
    <Form onSubmit={ () => { } } layout="vertical">
      <div className="padding10">
        <Row style={ { display: 'flex', flexDirection: 'column', gap: '5px' } }>
          <Col md={ 24 }>{ renderAccountsList() }</Col>
          {/* { renderWarehouseSelect(
            `${I18n.t('general.origin')}`,
            filter.startingPoint,
            handleStartingPointChange,
            filter.destinationPoint
          ) }
          { renderWarehouseSelect(
            `${I18n.t('general.destination')}`,
            filter.destinationPoint,
            handleDestinationPointChange,
            filter.startingPoint
          ) } */}
          {showDateFilter && <Col md={ 24 }>
            <FormItem label="Scheduled Date">
              <Row style={ { display: 'flex', alignItems: 'center' } }>
                <Col span={ 24 }>
                  <RangePicker
                    defaultValue={ [ moment().startOf('month'), moment() ] }
                    value={ [ dateSearchValue.fromDate, dateSearchValue.toDate ] }
                    ranges={AppConfig.dateRanges}
                    format={ AppConfig.dateFormat }
                    style={ { width: '100%' } }
                    onChange={ handleDateSearchChange }
                    disabled={ showNoAppointmentOrders }
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>}
          {showDateFilter && <Col md={ 24 }>
            <FormItem label="Show Linehaul Orders">
              <Radio.Group
                onChange={ handleNoAppointmentOrdersChange }
                value={ showNoAppointmentOrders }
                defaultValue={ false }
              >
                <Radio value={ false }>With Scheduled Dates</Radio>
                <Radio value={ true }>Without Scheduled Dates</Radio> 
              </Radio.Group>
            </FormItem>
          </Col>}
          <Row type="flex" justify="center" gutter={ 4 }>
            <Col>
              <Button type="danger" onClick={ resetFilter } icon="redo">
                { I18n.t('general.reset') }
              </Button>
            </Col>
            <Col>
              <Button
                onClick={ () => {
                  setFilterPlaceHolder({ ...filter }, () => {
                  });
                } }
                type="primary"
                icon="search"
              >
                { I18n.t('general.search') }
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </Form>
  );
};

LineHaulFilter.propTypes = {
};
LineHaulFilter.defaultProps = {
};

// export default withRouter(LineHaulFilter);

const LineHaulFilterForm = Form.create({ name: 'linehaul_filter_form' })(LineHaulFilter);
export default LineHaulFilterForm; 