import React, { Fragment } from "react";

const ReloadIcon = ({
  onClick,
  style,
  spin = "",
  color = "#000000",
  width = "15px",
  height = "15px",
}) => {
  const iconStyle = {
    ...style,
    animation: spin ? "spin 2s linear infinite" : "none",
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      onClick={onClick}
      style={iconStyle}
    >
      <g>
        <path
          fill={color}
          d="M233.5,15.5C311.032,10.4992 376.865,35.4992 431,90.5C431.333,81.5 431.667,72.5 432,63.5C434.646,53.69 440.48,46.5234 449.5,42C466.226,35.9143 480.059,39.7477 491,53.5C492.725,56.674 494.058,60.0073 495,63.5C495.99,113.583 495.657,163.583 494,213.5C493.25,214.126 492.416,214.626 491.5,215C442.167,215.667 392.833,215.667 343.5,215C324.562,208.645 316.728,195.478 320,175.5C323.167,163 331,155.167 343.5,152C354.167,151.667 364.833,151.333 375.5,151C338.602,110.247 292.602,92.2472 237.5,97C185.476,104.406 145.643,130.239 118,174.5C106.656,195.205 98.9893,217.205 95,240.5C84.8522,258.823 69.3522,266.323 48.5,263C28.993,256.997 18.6596,243.83 17.5,223.5C29.6233,144.599 69.9566,85.0985 138.5,45C168.379,28.982 200.046,19.1487 233.5,15.5ZM254.5,31.5C329.149,33.4912 389.482,63.6578 435.5,122C440.212,123.443 444.045,122.277 447,118.5C447.333,102.833 447.667,87.1667 448,71.5C451.187,57.6563 459.354,53.4896 472.5,59C476.382,62.2637 478.549,66.4303 479,71.5C479.5,114.165 479.667,156.832 479.5,199.5C435.128,199.974 390.794,199.474 346.5,198C338.129,194.091 334.963,187.591 337,178.5C339.601,171.947 344.434,168.447 351.5,168C366.833,167.667 382.167,167.333 397.5,167C401.277,164.045 402.443,160.212 401,155.5C360.409,99.963 305.575,74.7963 236.5,80C176.688,87.9689 131.521,117.802 101,169.5C90.9417,188.008 84.275,207.675 81,228.5C75.2398,244.296 64.4065,249.796 48.5,245C40.0273,240.352 35.3607,233.185 34.5,223.5C48.0996,143.424 91.4329,85.9237 164.5,51C193.344,38.5333 223.344,32.0333 254.5,31.5Z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M449.5,246.5C475.978,246.982 490.644,260.315 493.5,286.5C482.633,363.442 444.299,422.276 378.5,463C313.019,499.348 244.685,505.682 173.5,482C137.543,468.696 106.376,448.196 80,420.5C79.6667,429.5 79.3333,438.5 79,447.5C72.6446,466.438 59.478,474.272 39.5,471C27,467.833 19.1667,460 16,447.5C15.3333,398.167 15.3333,348.833 16,299.5C16.5,297.667 17.6667,296.5 19.5,296C68.8333,295.333 118.167,295.333 167.5,296C186.438,302.355 194.272,315.522 191,335.5C187.833,348 180,355.833 167.5,359C156.833,359.333 146.167,359.667 135.5,360C172.695,400.963 219.028,418.963 274.5,414C338.437,404.302 382.27,369.468 406,309.5C409.452,298.691 412.119,287.691 414,276.5C419.914,259.414 431.747,249.414 449.5,246.5ZM450.5,263.5C468.64,264.137 477.14,273.47 476,291.5C457.84,381.325 405.34,440.825 318.5,470C220.535,493.345 139.535,466.345 75.5,389C70.788,387.557 66.9547,388.723 64,392.5C63.6667,408.167 63.3333,423.833 63,439.5C59.813,453.344 51.6463,457.51 38.5,452C 34.618,448.736 32.4513,444.57 32,439.5C 31.5,396.835 31.3333,354.168 31.5,311.5C 75.8724,311.026 120.206,311.526 164.5,313C 172.871,316.909 176.037,323.409 174,332.5C 171.399,339.053 166.566,342.553 159.5,343C 144.167,343.333 128.833,343.667 113.5,344C 109.723,346.955 108.557,350.788 110,355.5C 151.235,411.492 206.735,436.659 276.5,431C 346.149,420.943 394.316,383.443 421,318.5C 425.429,305.784 428.762,292.784 431,279.5C 434.791,270.709 441.291,265.376 450.5,263.5Z"
        />
      </g>
    </svg>
  );
};

export default ReloadIcon;
