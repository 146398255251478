import React, { Fragment } from 'react';
import I18n from '../common/I18n';
import { isEmpty } from 'lodash';
import { momentTime } from '../helpers/date_functions';
import AppConfig from '../config/AppConfig';
import BaseList from '../components/BaseList';
import { checkForSMP } from '../helpers/billing';

class SummaryList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: 'order_invoice_number',
        title: 'Invoice Number',
        dataIndex: 'invoice_number',
      },
      {
        key: 'job_number',
        title: I18n.t('invoices.job_number'),
        dataIndex: 'job_number',
        render: (data, record) => (!isEmpty(data) ? <Fragment>{data}{checkForSMP(record)}</Fragment>: <Fragment />)
      },
      {
        key: 'customer_order_reference_no',
        title: I18n.t('invoices.customer_order_reference_no'),
        dataIndex: 'customer_order_reference_number',
        render: (data) => (!isEmpty(data) ? data : <Fragment />)
      }];
      if(props.hasHawb) {
        this.columns.push({
        key: 'hawb',
        title: I18n.t('general.hawb'),
        dataIndex: 'hawb',
        render: (data) => (!isEmpty(data) ? data : <Fragment />)
        });
      }
      if(props.hasMawb) {
        this.columns.push({
        key: 'mawb',
        title: I18n.t('general.mawb'),
        dataIndex: 'mawb',
        render: (data) => (!isEmpty(data) ? data : <Fragment />)
      });
      }
      this.columns.push(
        {
          key: 'datetime',
          title: I18n.t('invoices.service_date'),
          dataIndex: 'service_date',
          width:100,
          // render: data => (
          //   <div>{data ? momentTime(AppConfig.dateFormat, data) : ''}</div>
          // ),
          // sorter: true,
          // sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          key: 'customer',
          title: I18n.t('customer.name'),
          dataIndex: 'customer_name',
        },
        
        {
          key: 'amount',
          title: `${I18n.t('invoices.label')} ${I18n.t('invoices.total')}`,
          dataIndex: 'total_invoice',
          render: data => <div style={{ width: 100 }} className="alignRight">{data}</div>,
        },
      );
  }
}

export default SummaryList;
