// create antd modal to add role with role name and role code
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Spin,
} from "antd";
import React from "react";
import PropTypes from "prop-types";
import { createRole, updateRole } from "../../api/RolesApi";
import I18n from "../../common/I18n";
import { alertMessage, isEmpty } from "../../common/Common";
import ModuleActions from "./ModuleActions";
import { fetchOrgActions, fetchRoleActions } from "../../api/ModuleActions";
import { renderAlertMessage } from "../../helpers/common";

class RoleForm extends React.Component {
  constructor() {
    super();
    this.state = {
      roleName: "",
      roleCode: "",
      isLoading: false,
      isEdit: false,
      currentRole: {},
      currentModules: {},
      masterModules: [],
    };
  }

  // componentDidUpdate(prevProps) {
  //   const prevRecord = prevProps.editableData || {};
  //   const currentRecord = this.props.editableData || {};
  //   if (
  //     currentRecord.id && !_.isEqual(currentRecord.id, prevRecord.id)) {
  //     this.getCurrentRoleActions();
  //   }
  // }

  getModuleDetails = () => {
    this.setState({ moduleProgress: true });
    fetchOrgActions().then((result) => {
      if (result.success) {
        const masterModules = result.modules || [];
        this.setState({
          masterModules,
          moduleProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ moduleProgress: false });
      }
    });
  };

  getCurrentRoleActions = (cb) => {
    const currentRole = this.props.editableData || {};
    if (!isEmpty(currentRole.id) && !isEmpty(currentRole.role_code)) {
      this.setState({ moduleProgress: true });
      fetchRoleActions(currentRole.role_code).then((result) => {
        if (result.success) {
          const accesses = result.accesses || [];
          const modules = _.uniq(accesses.map((access) => access.module_code));
          const currentModules = {};
          modules.map((moduleCode) => {
            currentModules[moduleCode] = accesses
              .filter((access) => access.module_code === moduleCode)
              .map((acess) => acess.operation_code);
          });
          this.setState(
            {
              currentModules,
              moduleProgress: false,
            },
            () => {
              if (cb) {
                cb();
              }
            }
          );
        } else {
          this.setState({ moduleProgress: false, currentModules: {} });
        }
      });
    }
  };

  handleModuleActionChange = (moduleCode, opCodes) => {
    const currentModules = { ...this.state.currentModules };
    if (!currentModules.moduleCode) {
      currentModules[moduleCode] = [];
    }
    currentModules[moduleCode] = opCodes;
    this.setState({
      currentModules,
    });
  };

  handleStateChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  handleSubmit = (e) => {
    const { form, addNewRole, updateRoleList } = this.props;
    const { isEdit } = this.state;
    e.preventDefault();
    this.setState({ moduleProgress: true });
    form.validateFields((err, values) => {
      values.roleCode = values.roleCode.toLowerCase();
      if (!err) {
        const access = [];
        Object.keys(this.state.currentModules).forEach((key) => {
          const currentModule = this.state.currentModules[key];
          currentModule.forEach((op) => {
            access.push({
              module_code: key,
              operation_code: op,
            });
          });
        });
        if (isEdit) {
          updateRole({
            id: this.props.editableData.id,
            ...values,
            access,
          }).then((result) => {
            if (result.success) {
              updateRoleList(result.data);
              this.setState({ moduleProgress: false });
              alertMessage(I18n.t("roles.update.success"), "success");
            }else {
              this.setState({ moduleProgress: false });
              alertMessage(result?.errors ? result.errors.join(',') : '', "error");
            }
          });
        } else {
          createRole({ ...values, access }).then((result) => {
            if (result.success) {
              addNewRole(result.data);
              this.setState({ moduleProgress: false });
              alertMessage(I18n.t("roles.add.success"));
            } else {
              this.setState({ moduleProgress: false });
              alertMessage(result?.errors ? result.errors.join(',') : '', "error");
            }
          });
        }
      }
    });
  };

  componentDidMount() {
    const { form, editableData } = this.props;
    const isEdit = editableData && Object.keys(editableData).length > 0;
    if (isEdit) {
      form.setFieldsValue({
        roleCode: editableData.role_code,
        roleName: editableData.role_name,
      });
      this.setState({ isEdit });
    } else {
      this.setState({ isEdit: false });
    }
    this.getModuleDetails();
    this.getCurrentRoleActions();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isEdit } = this.state;
    return (
      <Form
        // labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        onSubmit={this.handleSubmit}
        title={this.props.formTitle}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={I18n.t("roles.role_name")}>
              {getFieldDecorator("roleName", {
                rules: [
                  {
                    required: true,
                    message: "Please input valid role name!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={I18n.t("roles.role_code")}>
              {getFieldDecorator("roleCode", {
                rules: [
                  {
                    required: true,
                    message: "Please input valid role code!",
                  },
                ],
              })(
                <Input
                  style={{
                    textTransform: "uppercase",
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Spin spinning={this.state.moduleProgress} delay={1000}>
              <ModuleActions
                masterModules={this.state.masterModules}
                currentModules={this.state.currentModules}
                handleActionOnChange={this.handleModuleActionChange}
                handleOnChange={this.handleStateChange}
              />
            </Spin>
          </Col>
        </Row>

        <Row
          style={{
            textAlign: "center",
          }}
        >
          <Col span={24}>
            <Button
              type="danger"
              className="floatBtnCancel"
              onClick={this.props.onCancel}
              icon="close"
              disabled={this.state.moduleProgress}
            >
              {I18n.t("general.cancel")}
            </Button>&nbsp;
            <Button type="primary" className="floatBtnSave" htmlType="submit" icon="save" disabled={this.state.moduleProgress}>
              {isEdit ? I18n.t("general.update") : I18n.t("general.save")}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const RoleFormWrapper = Form.create({ name: I18n.t(`roles.form`) })(RoleForm);
export default RoleFormWrapper;

RoleForm.propTypes = {
  formTitle: PropTypes.string,
  addNewRole: PropTypes.func,
  updateRoleList: PropTypes.func,
  editableData: PropTypes.object,
  onCancel: PropTypes.func,
};
RoleForm.defaultProps = {
  formTitle: "",
  addNewRole: () => {},
  updateRoleList: () => {},
  editableData: {},
  onCancel: () => {},
};
