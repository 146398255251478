import React, { useState } from "react";
import { Form, Input, Button, Table, Row, Col, InputNumber, Select, Radio } from "antd";
import { downloadBarCodes } from "../api/OrdersApi";
import _ from "lodash";
import { base64ToPDF } from "../helpers/common";
import { alertMessage, isEmpty } from "../common/Common";
import CustomerOrderDetails from "../components/orders/CustomerOrderDetails";
import OrderFieldDisplay from "../components/orders/OrderFieldDisplay";

const FormItem = Form.Item;
const { Option } = Select;
const columns = (getFieldDecorator,downloadValue,handleRadioChange) => [
  {
    title: "Order Number",
    dataIndex: "orderNumber",
    key: "orderNumber",
    render: (text, record) => {
      return(
      <FormItem>
        <span>
          <CustomerOrderDetails
            order_no={text}
            order={{
              id: record.orderId,
              customer_order_number: record.orderNumber,
            }}
            showEdit={false}
          />
        </span>
      </FormItem>
    )}
  },
  {
    title: "HAWB",
    dataIndex: "hawb",
    key: "hawb",
    render: (text, record) => (
      
      <FormItem>
        <span>
          <OrderFieldDisplay
            data={{
              ...record,
              id: record.orderId,
              customer_order_number: record.orderNumber,
            }}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        </span>
      </FormItem>
    )
    
  },
  {
    title: "MAWB",
    dataIndex: "mawb",
    key: "mawb",
    render: (text, record) => (
      <FormItem>
        <span>
          <OrderFieldDisplay
            data={{
              ...record,
              id: record.orderId,
              customer_order_number: record.orderNumber,
            }}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        </span>
      </FormItem>
    )
  },
  {
    title: "Download Value",
    dataIndex: "download_value",
    key: "download_value",
    render: (text, record) => (
      <FormItem>
        <Radio.Group size="small" value={record.download_value} onChange={(e) => handleRadioChange(e, record)}>
          <Radio value={"HAWB"} disabled={isEmpty(record.hawb)}>Hawb</Radio>
          <Radio value={"ORDER"}>Order Number</Radio>
        </Radio.Group>
      </FormItem>
    ),
  },
  {
    title: "BOL",
    dataIndex: "bol",
    key: "bol",
    render: (text, record) => (
      <FormItem>
        { getFieldDecorator(`bol[${record.key}]`, {
          initialValue: "",
          // rules: [ {
          //   validator: (_, value, callback) => {
          //     if (value < 0) {
          //       callback("Value must be non-negative");
          //     } else {
          //       callback();
          //     }
          //   }
          // } ]
        })(<InputNumber min={ 0 } size="small" style={{ width: '75px' }} />) }
      </FormItem>
    )
  },
  {
    title: "POD",
    dataIndex: "pod",
    key: "pod",
    render: (text, record) => (
      <FormItem>
        { getFieldDecorator(`pod[${record.key}]`, {
          initialValue: "",
          // rules: [ {
          //   validator: (_, value, callback) => {
          //     if (value < 0) {
          //       callback("Value must be non-negative");
          //     } else {
          //       callback();
          //     }
          //   }
          // } ]
        })(<InputNumber min={ 0 } size="small" style={{ width: '75px' }} />) }
      </FormItem>
    )
  },
  {
    title: "User Manual",
    dataIndex: "userManual",
    key: "userManual",
    render: (text, record) => (
      <FormItem>
        { getFieldDecorator(`userManual[${record.key}]`, {
          initialValue: "",
          // rules: [ {
          //   validator: (_, value, callback) => {
          //     if (value < 0) {
          //       callback("Value must be non-negative");
          //     } else {
          //       callback();
          //     }
          //   }
          // } ]
        })(<InputNumber min={ 0 } size="small" style={{ width: '75px' }} />) }
      </FormItem>
    )
  }
];

const docTypes =  {
  BOL : 'BL',
  POD : 'PD',
  USERMANUAL : 'UM'
}

const isEmptyValue = value => _.isEmpty(value);

const DMSForm = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [downloadValue, setDownloadValue] = useState("HAWB");
  const { selectedOrders = [] } = props;
  const dataSource = selectedOrders.map(({customer_order_number , id,hawb,mawb}, index) => ({ key: index, orderNumber : customer_order_number, orderId : id, hawb :hawb, mawb:mawb,download_value: hawb ? 'HAWB' : 'ORDER'}));
  const [barCodeOrders, setBarCodeOrders] = useState(dataSource);
 // const [selectedDownloadValue, setSelectedDownloadValue] = useState("HAWB");


 const handleApplyButtonClick = (e) => {
  const formVals = props.form.getFieldsValue();
  const newData = barCodeOrders.map(order => ({...order, download_value: (order.hawb === null || order.hawb === "") ? 'ORDER' :formVals.download_value }))
  console.log("newData",newData);
  if (formVals.download_value !== "ORDER") {
    const ordersWithoutHawb = newData.filter(order => order.hawb === null);
    if (ordersWithoutHawb.length > 0) {
        alertMessage("Some of the orders do not have HAWB","error",5);
    }
  }
  setBarCodeOrders(newData)
  //setDownloadValue(value)
};

  const handleRadioChange = (e, record) => {
    const newValue = e.target.value;
    const updatedDataSource = barCodeOrders.map((item) => {
      if (item.key === record.key) {
        return {
          ...item,
          download_value: newValue,
        };
      }
      return item;
    });
    setBarCodeOrders(updatedDataSource);
  
    // Update the form value using setFieldsValue
    // props.form.setFieldsValue({
    //   download_value: newValue,
    // });
  
    //setSelectedDownloadValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        
        const orientation = values.orientation || 'PORTRAIT';
        const download_value = values.download_value || 'HAWB';
        const download_type = values.download_type || 'BARCODE';
        const formValues = {...values}
        delete formValues.orientation;
        delete formValues.download_type;
        delete formValues.download_value;
        const nonEmptyValues = _.omitBy(formValues, isEmptyValue); 
        // const nonEmptyValues = _.mapValues(values, (value) => _.filter(value, (v) => v !== ''));
        const docDetails = Object.keys(nonEmptyValues).reduce((acc, key) => {
          const docType = key.toUpperCase();
          nonEmptyValues[ key ].forEach((count, index) => {
            if (count > 0 && barCodeOrders[ index ]) {
              acc.push({
                doc_type: docTypes[docType] ,
                download_value: barCodeOrders[ index ].download_value,
                no: barCodeOrders[ index ].orderNumber,
                source_id: barCodeOrders[ index ].orderId,
                count,
                source: "ORDER",
                //source_number: barCodeOrders[ index ].customer_order_number
              });
            }
          });

          return acc;
        }, []);

        const payload = {
          org_id: props.org_id,
          doc_details: docDetails,
          orientation,
          download_value,
          download_type
        };

        setLoading(true);
        downloadBarCodes(payload).then((result) => {
          if (result.success) {
            const yearDayMonth = new Date().toLocaleDateString().split("/").reverse().map((e, i) => i == 1 || i == 2 ? e.length == 1 ? "0" + e : e : e).join("");
            base64ToPDF(result.label_file, `Barcodes_${yearDayMonth}.pdf`);
          }
        }).catch((err) => {
          alertMessage("Something went wrong", "error" , 2)
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  };

  // const handleApplyButtonClick = () => {
  //   setDownloadValue(selectedDownloadValue);
  // };

  const { getFieldDecorator } = props.form;

  return (
    <Form layout="vertical" onSubmit={ handleSubmit }>
      <Row>
        <Col xs={7}>
            <Row type="flex" gutter={16}>
            <Col className="textBold">
            {/* {I18n.t("general.orientation")}: */}
            Orientation:
            </Col>
            <Col>
            <FormItem>
            { getFieldDecorator(`orientation`, {
              initialValue: "PORTRAIT",
            })(<Radio.Group style={{ width: 200 }} size="small">
              <Radio value={"PORTRAIT"}>Portrait</Radio>
              <Radio value={"LANDSCAPE"}>Landscape</Radio>
              </Radio.Group>) }
          </FormItem>
            </Col>
          </Row>
        </Col>
        <Col xs={8}>
            <Row type="flex" gutter={16}>
            <Col className="textBold">
            {/* {I18n.t("general.orientation")}: */}
            Download Type:
            </Col>
            <Col>
            <FormItem>
            { getFieldDecorator(`download_type`, {
              initialValue: "TEXT",
            })(<Radio.Group style={{ width: 200 }} size="small">
                <Radio value={"TEXT"}>Text</Radio>
                <Radio value={"BARCODE"}>Barcode</Radio>
              </Radio.Group>) }
          </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
      <Col xs={12}>
            <Row type="flex" gutter={16}>
            <Col className="textBold">
            Download Value:
            </Col>
            <Col>
            <FormItem>
            { getFieldDecorator(`download_value`, {
              initialValue: "HAWB",
            })(<Radio.Group size="small" value={downloadValue}>
              <Radio value={"HAWB"}>Hawb</Radio>
              <Radio value={"ORDER"}>Order Number</Radio>
              </Radio.Group>) }
          </FormItem>
            </Col>
            <Button type="primary" size="small" onClick={handleApplyButtonClick}>
              Apply
            </Button>
          </Row>
        </Col>
      </Row>
      

      
      <Table
        dataSource={ barCodeOrders }
        columns={ columns(getFieldDecorator,downloadValue,handleRadioChange) }
        pagination={{ pageSize: 10 }}
        bordered
        size="small"
        className="barCodeTable"
        rowSelection={ {
          type: "checkbox",
          selectedRowKeys: barCodeOrders.map(({ key }) => key),
          onChange: (selectedRowKeys, selectedRows) => {
            setBarCodeOrders(selectedRows);
          }
        } }
      />

      <Row className="buttonsList">
        <Col>
        <Button
            onClick={ () => props.handleBarCodePrint(false) }
            icon="close"
          >
            Close
          </Button>
          <Button type="primary" htmlType="submit" loading={ loading } disabled={ loading || barCodeOrders.length === 0 } style={ { marginLeft: "8px" } } icon="printer"> 
            Print
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({ name: "my_form" })(DMSForm);