import React, { Fragment } from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';

import { Icon, Popconfirm, Input, InputNumber, Select } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import DeleteIcon from '../common/DeleteIcon';
import I18n from '../../common/I18n';
import BaseSelect from '../common/SelectDropdowns/BaseSelect';
import GLCodeSelect from '../common/SelectDropdowns/GLCodeSelect';

class WarehouseDeductionList extends BaseList {
  constructor(props) {
    super(props);
    const decimalPoints= window.localStorage.getItem('round_off_decimals');
    this.columns = [
      {
        title: '#',
        key: 'index',
        render: (text, record, index) => index + 1,
      },
      {
        key: 'deduction_name',
        title: <div>{I18n.t('deductions.name')}<sup className="textRed">*</sup></div>,
        dataIndex: 'deduction_name',
        render: (text, record, index) => (
          <Input
            disabled={true}
            value={text}
            size="small"
            className="textUpperCase fontSize12"
            placeholder={I18n.t('deductions.name')}
            // onChange={e => this.props.onChange(index, 'deduction_name', e.target.value, record.warehouse_id)}
          />
        ),
      },
      {
        key: 'default_amount',
        title: <div>{I18n.t('deductions.default_amount')}<sup className="textRed">*</sup> (in {I18n.t('general.price_symbol')})</div>,
        dataIndex: 'default_amount',
        render: (text, record, index) => (
          <InputNumber
            value={text}
            size="small"
            className="fontSize12 width100Per"
            onChange={e => this.props.onChange(index, 'default_amount', e, record.warehouse_id)}
            placeholder={I18n.t('deductions.default_amount')}
            min={0}
            precision={decimalPoints}
          />
        ),
      },
      {
        key: 'gl_code',
        title: <div>{I18n.t('deductions.gl_code')}<sup className="textRed">*</sup></div>,
        dataIndex: 'gl_code',
        render: (text, record, index) => (
          <>
            
            <Input
              disabled={true}
              value={text}
              size="small"
              className="fontSize12 width100Per"
              placeholder={I18n.t('deductions.default_amount')}
              min={0}
              precision={decimalPoints}
            />
            {/* <GLCodeSelect
              data={this.props.glCodesList || []}
              className="textUpperCase fontSize12"
              value={text}
              onChange={e => this.props.onChange(index, 'gl_code', e, record.warehouse_id)}
              selectKey={"gl_code"}
              selectValue={"gl_code"}
              selectedValue={"gl_code"}
              showSearch={true}
              style={{ width: '100%'}}
              dropdownMatchSelectWidth={false}
              selectedCode={"description"}
              size="small"
            /> */}
          </>
          
        ),
      },
      {
        key: 'payment_type',
        title: <div>{I18n.t('deductions.payment_type')}<sup className="textRed">*</sup></div>,
        dataIndex: 'payment_type',
        render: (text, record, index) =>{
          return (
            <Select
              value={text}
              showSearch
              onChange={(e) => this.props.onChange(index, "payment_type", e, record.warehouse_id)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              size="small"
            >
              <Select.Option key="VARIABLE" value="VARIABLE">
                {I18n.t("deductions.variable")}
              </Select.Option>
              <Select.Option key="FIXED" value="FIXED">
                {I18n.t("deductions.fixed")}
              </Select.Option>
              <Select.Option key="PERCENTAGE" value="PERCENTAGE">
                {I18n.t("deductions.percentage")}
              </Select.Option>
            </Select>
          );
        }
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (text, record, index) => (
          <Fragment>
              <Popconfirm
                placement="left"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => this.props.deleteClick(index, record.id, record.isNew)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon />
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
          </Fragment>
        ),
      },
    ];
  }
}

export default WarehouseDeductionList;
