/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, useContext } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Button, Col, Row, Search, Spin, Select } from "../common/UIComponents";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import { deleteAccount, saveAccount, fetchAccount } from "../api/Account";
import List from "../components/account_receivables/List";
import Form from "../components/account_receivables/Form";
import BaseModal from "../components/BaseModal";
import userStore from "../stores/UserStore";
import AppConfig from "../config/AppConfig";
import {
  checkServiceExistance,
  removeSpecialCharacters,
  renderAlertMessage,
} from "../helpers/common";
// import DataTransferIcon from "../../images/data-transfer.svg";
import { withRouter } from "react-router";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import { BatchPaymentApi } from "../api/BatchPaymentApi";

class BatchPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      inProgress: false,
      showForm: false,
      batches: [],
      currentBatch: {},
      orgId: this.props.orgId || userStore.getStateValue("selectedOrg"),
      isNew: true,
      sortOrder: "ascend",
      status: 'OPEN',
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.getBatches = _.debounce(this.getBatches, 500);
  }

  getBatchesPageData = () => {
    this.getBatches();
  };

  componentDidMount() {
    this.getBatchesPageData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseFilter.selectedWarehouses,
        this.props.warehouseFilter.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseFilter.warehouses,
        this.props.warehouseFilter.warehouses
      ) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      this.getBatchesPageData();
    }
  }

  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getBatches();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getBatches();
        }
      );
    }
  };

  onSearch = (text) => {
    // if(!!text === false) return;
    const token = text.trim();
    this.setState(
      {
        token,
      },
      () => {
        if (token === "") {
          this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
        } else {
          this.tableOptions.searchText = text;
        }
        this.tableOptions.pagination.current = 1;
        this.getBatches();
      }
    );
  };

  getBatches = () => {
    this.setState({ inProgress: true, currentBatch: {} });
    BatchPaymentApi.fetchBatches(this.state.orgId, this.state.status).then((result) => {
      if (result.success) {
        const batches = result.account_receivables || [];
        this.setState({
          batches,
          inProgress: false,
          pagination:
            batches.length > 0 && result.pagination ? result.pagination : {},
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    }, () => {
      if(element === 'status'){
        this.getBatches();
      }
    });
  };

  clearForm = () => {
    this.setState({
      currentBatch: {},
      isNew: true,
    });
  };

  closeFormWindow = () => {
    this.setState(
      {
        showForm: false,
      },
      () => {
        this.getBatches();
      }
    );
  };

  showFormWindow = (rec = {}) => {
    this.setState({
      currentBatch: { ...rec },
      showForm: true,
      isNew: rec.id ? false : true,
    });
  };

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.batches, ["id", id]);
    if (index >= 0) {
      const account = Object.assign({}, this.state.batches[index]);
      this.showFormWindow(account);
    }
  };

  gotoAccountSettings = (id) => {
    this.props.history.push(`/account_receivables${id}/settings`);
  };

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    BatchPaymentApi.deleteBatchAccount(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getBatches();
        this.setState({
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.batches, ["id", id]);
    const currentBatch = Object.assign({}, this.state.batches[index]);
    currentBatch.status =
      currentBatch.status === "active" ? "inactive" : "active";
    this.setState({ currentBatch, isNew: false }, () => {
      saveAccount(false, this.state.currentBatch).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          this.getSurveys();
        } else {
          alertMessage(result.errors, "error", 10);
        }
      });
    });
  };

  renderFormModal = () => (
    <BaseModal
      title={
        this.state.isNew
          ? I18n.t("batch_payments.add")
          : I18n.t("batch_payments.update")
      }
      onCancel={() => this.closeFormWindow()}
      width="100%"
      style={{
        top: 0,
        height: "100%",
      }}
      bodyStyle={{
        height: "calc(100vh - 50px)",
        overflowY: "auto",
      }}
    >
      <Form
        account={this.state.currentBatch}
        batches={this.state.batches}
        primaryAccountCodes={this.state.primaryAccountCodes}
        isPrimaryCurrentAccount={this.state.isPrimaryCurrentAccount}
        isNew={this.state.isNew}
        org_id={this.state.orgId}
        onCancel={this.closeFormWindow}
        closeFormOnSubmit={this.closeFormWindow}
      />
    </BaseModal>
  );

  render() {
    // const { pagination } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || AppConfig.perPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col span={10}>{I18n.t("menu.batch_payments")}</Col>
            <Col xs={14}>
              <Row
                justify="end"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Col xs={14}>
                  {/* <Search
                    size="default"
                    value={this.state.token || ""}
                    placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                      "menu.account"
                    )}`}
                    onChange={(e) => {
                      const filteredString = removeSpecialCharacters(
                        e.target.value
                      );
                      this.handleOnChange("token", filteredString);
                    }}
                    onSearch={this.onSearch}
                    allowClear
                  /> */}
                </Col>
                {checkServiceExistance("AC") && (
                  <Col className="page-actions">
                    <Button
                      type="primary"
                      className="marginLeft10"
                      onClick={() => this.showFormWindow()}
                      icon="plus"
                    >
                      {I18n.t("batch_payments.add")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
            {/* <Col span={ 14 }>
              <Row type='flex' justify='end' gutter={4}>
                <Col span={14}>
                  <Search
                    size="default"
                    value={ this.state.token || '' }
                    placeholder={ `${I18n.t(`general.search`)} ${I18n.t('menu.account')}` }
                    onChange={ (e) => this.handleOnChange('token', e.target.value) }
                    onSearch={ this.onSearch }
                    allowClear
                  />
                </Col>
                { checkServiceExistance('AC') && <Col className="page-actions">
                  <Button type="primary" onClick={ () => this.showFormWindow() } icon='plus'>
                    { I18n.t('account.add') }
                  </Button>
                </Col>
                }
              </Row>
            </Col> */}
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <div className="alignRight">
              Status:&nbsp;
              <Select
                value={this.state.status}
                showSearch
                style={{ width: 200 }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                size="small"
                onChange={(e) => this.handleOnChange("status", e)}
              >
               
                  <Select.Option key={"OPEN"} value={"OPEN"}>Open</Select.Option>
                  <Select.Option key={"POSTED"} value={"POSTED"}>Posted</Select.Option>

              </Select>
            </div>
            <Row>
              <Col className="batches">
                <List
                  size="small"
                  // scroll = {{y:680}}
                  scroll={{ x: "max-content", y: "calc(100vh - 240px)" }}
                  data={this.state.batches}
                  gotoAccountSettings={(id) => this.gotoAccountSettings(id)}
                  editClick={(id) => this.handleEditClick(id)}
                  deleteClick={(id) => this.handleDelete(id)}
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter) =>
                    this.onTableChange(pagination, filter, sorter)
                  }
                  statusChange={(id) => this.handleAccountStatusChange(id)}
                  generateBol={this.bolGenerate}

                  // expandable={checkServiceExistance('SA')}
                />
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
      </div>
    );
  }
}
BatchPayments.propTypes = {
  orgId: PropTypes.string,
  showHeadings: PropTypes.bool,
};
BatchPayments.defaultProps = {
  orgId: "",
  showHeadings: true,
};

export const BatchPaymentComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  return (
    <BatchPayments
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default BatchPaymentComponent;
