
import React from 'react';
import BaseList from '../BaseList';
import { Row, Col, Icon, Popconfirm, Table, Tooltip } from 'antd';
import DeleteIcon from '../common/DeleteIcon';
import EditIcon from '../common/EditIcon';
import I18n from '../../common/I18n';


class CarrierCustomizeTable extends BaseList {
  constructor (props) {
    super(props);
    this.columns = [
      {
        title: 'Integration Type',
        dataIndex: 'integration_type',
      },
      {
        title: 'Integration Code',
        dataIndex: 'integration_code',
      },
      {
        title: 'Shipper Code',
        dataIndex: 'shipper_code',
      },
      {
        title: 'Carrier Code',
        dataIndex: 'carrier_code',
      },
      {
        title: 'Inbound Settings',
        dataIndex: 'inbound_settings',
        render: (inboundSettings) => (
          <Table
            rowKey={ (record) => record.name}
            size="small"
            columns={ [
              {
                title: 'Name',
                dataIndex: 'key',
              },
              {
                title: 'Value',
                dataIndex: 'value',
              },
            ] }
            dataSource={ inboundSettings }
            pagination={ false }
          />
        ),
      },
      {
        title: 'Outbound Settings',
        dataIndex: 'outbound_settings',
        render: (outboundSettings) => (
          <Table
            rowKey={ (record) => record.name}
            size="small"
            columns={ [
              {
                title: 'Name',
                dataIndex: 'key',
              },
              {
                title: 'Value',
                dataIndex: 'value',
              },
            ] }
            dataSource={ outboundSettings }
            pagination={ false }
          />
        ),
      },
      {
        key: "actions",
        fixed: "right",
        render: (data) => {
          const { handleDelete, handleEdit } = this.props;
          const { id } = data;
          return (
            <div className="line-actions">
              <Row gutter={ 8 }>
                <Col span={ 12 }>
                  {/* <Icon type="edit" style={ { color: '#1890ff', fontSize: 20 } }
                    onClick={ () => handleEdit(id) }
                    className="paddinRight5"
                  /> */}
                  <Tooltip title={I18n.t("general.edit")}>
                  <EditIcon handleClick={ () => handleEdit(id) } style={{fontSize:20}} className="paddinRight5"/>
                  &nbsp;
                  </Tooltip>
                </Col>
                <Col span={ 12 }>
                  <Popconfirm
                    placement="left"
                    title={ I18n.t("messages.delete_confirm") }
                    onConfirm={ () => handleDelete(id) }
                    okText="Yes"
                    cancelText="No"
                  >
                    {/* <Icon type="delete" style={ { color: '#f5222d', fontSize: 20 } } /> */}
                    <Tooltip title={I18n.t("general.delete")}>
                    <DeleteIcon style={{fontSize:20}}/>
                    &nbsp;
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          );
        },
        align: "center",
        width: "5%",
      },
    ];
  }
}
export default CarrierCustomizeTable;