import React, { useEffect, Fragment, useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import I18n from "../../common/I18n";
import ConsolidationList from "./ConsolidationList";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import { alertMessage, checkNegitive } from "../../common/Common";
import { BillingConsolidationApi } from "../../api/BilliingConsolidationApi";
import userStore from "../../stores/UserStore";
import {
  Search,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Radio,
  Icon,
  Button,
  Spin,
  FormItem,
  Table,
  Tooltip,
  Popconfirm,
  Modal,
} from "../../common/UIComponents";
import { formatWeight } from "../../helpers/orders";
import { isEmpty } from "lodash";
import DecimalValue from "../common/DecimalValue";
import AppConfig from "../../config/AppConfig";
import { withRouter } from "react-router-dom";

const { Option } = Select;
const consolidationMethods = [
  {
    value: "CONSOLE_WT",
    label: "Set Consol Weight on Shipment(Pro-Rate/No Min or Cap)",
  },
  {
    value: "RATE_MASTER",
    label: "Rate Master Shipment for Entire Console(0 Rate other Shipments)",
  },
  {
    value: "PRO_RATE",
    label: "Pro-Rate charges across all shipments",
  },
];

const initialFormData = {
  consolidation_method: "CONSOLE_WT", // Set the default value for consolidation_method
  weight_type: "ACT", // Set the default value for weight_type
  order_type: "DL", // Set the default value for order_type
  invoice_line_type: "TRP", // Set the default value for invoice_line_type
  comments: "", // Set the default value for comments
};
const Consolidate = ({
  showHeader = true,
  account = {},
  onCancel = null,
  consolidate_order_id = "",
  location = {},
  history = {},
  match = {},
}) => {
  const orgId = userStore.getStateValue("selectedOrg");
  const [consolidatedOrders, setConsolidatedOrders] = useState(
    location?.orders ? [...location.orders] : []
  );
  const [formData, setFormData] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [orderRefNumber, setOrderRefNumber] = useState("");
  const [consolidatedAccount, setConsolidatedAccount] = useState(
    location?.account || {}
  );
  const [consolidationDetails, setConsolidationDetails] = useState(null);
  const [primaryId, setPrimaryId] = useState("");
  const [allowForSave, setAllowForSave] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [allowApprove, setAllowApprove] = useState(false);
  const [accessorialInfo, setAccessorialInfo] = useState([]);
  const [showrevert, setShowRevert] = useState(false);
  const [showInvoiceModel, setShowInvoiceModel] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [consolidateNumber, setConsolidateNumber] = useState(
    match?.params?.consolidate_no || ""
  );
  const [selectedInfo, setSelectedInfo] = useState({
    keys: [],
    rows: [],
  });
  //
  const [billingStatus, setBillingStatus] = useState("");
  const [showActions, setShowActions] = useState(true);
  useEffect(() => {
    if (!isEmpty(match.params.consolidate_no) && !location?.orders?.length) {
      getConsolidationDetails(match.params.consolidate_no);
    }
  }, [match.params.consolidate_no]);

  useEffect(() => {
    setShowActions(!(billingStatus === 'APPROVED'))
  }, [billingStatus]);

  useEffect(() => {
    setConsolidatedOrders(location?.orders || []);
    setConsolidatedAccount(location?.account || {});
  }, [location]);

  useEffect(() => {
    setAllowForSave(false);
  }, [formData.consolidation_method, formData.weight_type]);

  // To validate orders
  const validateOrders = () => {
    setIsLoading(true);
    const data = {
      organization_id: orgId,
      // customer_order_ids: consolidatedOrders.map(
      //   (order) => order.customer_order_id
      // ),
      consolidation_number: consolidateNumber,
      order_ref_number: orderRefNumber,
      customer_order_ids: orderRefNumber
        ? consolidatedOrders.map((order) => order?.customer_order_id)
        : selectedInfo.keys || [],
    };
    BillingConsolidationApi.verifyOrdersToConsolidate(data)
      .then((response) => {
        if (response.success) {
          setConsolidateNumber(
            response.consolidation_number ? response.consolidation_number : ""
          );
          setConsolidatedOrders(
            response.consolidated_orders ? response.consolidated_orders : []
          );
          setConsolidatedAccount(response?.account ? response.account : null);
          setOrderRefNumber("");
          setAllowForSave(false);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // To get consolidation details
  const getConsolidationDetails = (consolidation_number) => {
    setIsLoading(true);
    BillingConsolidationApi.fetchConsolidationDetails({ consolidation_number })
      .then((response) => {
        if (response.success) {
          const consolidate_order = response.consolidate_order || {};
          setConsolidationDetails({ ...consolidate_order });
          setConsolidatedOrders(
            consolidate_order.consolidated_orders
              ? consolidate_order.consolidated_orders
              : []
          );
          setFormData({
            // weight_type: consolidate_order.weight_type,
            // consolidation_method: consolidate_order.consolidation_method,
            // order_type: consolidate_order.order_type || "",
            // invoice_line_type: consolidate_order.invoice_line_type || "",
            ...initialFormData,
            comments: consolidate_order.comments,
          });
          setConsolidatedAccount(response?.account ? response.account : null);
          setOrderRefNumber("");
          setBillingStatus(response.status)
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const revertConsolidation = (id) => {
    setIsLoading(true);
    BillingConsolidationApi.revertConsolidation({ id })
      .then((response) => {
        if (response.success) {
          alertMessage(
            response.message ? response.message : I18n.t("messages.saved")
          );
          // onCancel();
          handleGoBack();
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // To update consolidation details
  const updateConsolidationDetails = (primary_order_id = "") => {
    setIsLoading(true);
    const data = {
      consolidate_order_id: consolidationDetails.id,
      organization_id: orgId,
      account_id: consolidationDetails?.account_id
        ? consolidationDetails?.account_id
        : "",
      consolidation_method: formData.consolidation_method,
      weight_type: formData.weight_type,
      primary_order_id,
      order_type: formData.order_type || "",
      invoice_line_type: formData.invoice_line_type || "",
      consolidated_orders: consolidatedOrders,
      comments: formData.comments,
      accessorial_consolidations: accessorialInfo,
      consolidation_number: consolidateNumber,
    };
    BillingConsolidationApi.updateConsolidationDetails(data)
      .then((response) => {
        if (response.success) {
          const consolidate_order = response.consolidated_order || {};
          setConsolidationDetails({ ...consolidate_order });
          setConsolidatedOrders(
            consolidate_order.consolidated_orders
              ? consolidate_order.consolidated_orders
              : []
          );
          setPrimaryId(
            consolidate_order?.primary_order_id
              ? consolidate_order.primary_order_id
              : ""
          );
          // setFormData({
          //   weight_type: consolidate_order.weight_type,
          //   consolidation_method: consolidate_order.consolidation_method,
          //   order_type: consolidate_order.order_type || "",
          //   invoice_line_type: consolidate_order.invoice_line_type || "",
          //   comments: consolidate_order.comments,
          // });
          setConsolidatedAccount(response?.account ? response.account : null);
          setOrderRefNumber("");
          setAllowApprove(true);
          alertMessage(I18n.t("messages.saved"), "success", 5);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const changeBillingPrice = (record, e) => {
  //   const { value } = e.target;
  //   const { customer_order_id } = record;
  //   const filteredOrders = consolidatedOrders.map((order) => {
  //     if (order.customer_order_id === customer_order_id) {
  //       return { ...order, actual_billing_price: value };
  //     }
  //     return order;
  //   });
  //   setConsolidatedOrders([...filteredOrders]);
  // }

  const changeConsolidationPrice = (record, value) => {
    const { customer_order_id } = record;
    const filteredOrders = consolidatedOrders.map((order) => {
      if (order?.customer_order_id === customer_order_id) {
        return { ...order, order_consolidate_price: value };
      }
      return order;
    });
    setConsolidatedOrders([...filteredOrders]);
  };

  // To apply consolidation details
  const applyConsolidation = (
    ordersToConsolidate = null,
    primary_order_id = ""
  ) => {
    setIsLoading(true);
    const selectedKeys = selectedInfo.keys || [];
    const data = {
      organization_id: orgId,
      customer_order_ids: ordersToConsolidate
        ? [...ordersToConsolidate]
        : selectedKeys,
      consolidation_number: consolidateNumber,
      account_id: consolidatedAccount?.id ? consolidatedAccount.id : "",
      consolidation_method: formData.consolidation_method,
      weight_type: formData.weight_type,
      primary_order_id,
      order_type: formData.order_type || "",
      invoice_line_type: formData.invoice_line_type || "",
      consolidated_orders: consolidatedOrders,
    };
    BillingConsolidationApi.applyConsolidation(data)
      .then((response) => {
        if (response.success) {
          // setConsolidatedOrders(
          //   response.consolidated_orders ? response.consolidated_orders : []
          // );
          const consolidatedResponse = response.consolidated_orders
            ? response.consolidated_orders
            : [];
          const consolidatedData = _.cloneDeep(consolidatedOrders);
          consolidatedResponse.forEach((rec, index) => {
            const orderIndex = _.findIndex(consolidatedData, {
              customer_order_id: rec?.customer_order_id,
            });
            if (orderIndex >= 0) {
              consolidatedData[orderIndex] = consolidatedResponse[index];
            } else {
              consolidatedData.push(consolidatedResponse[index]);
            }
          });

          // consolidatedData.forEach((rec, index) => {
          //   const orderIndex = _.findIndex(consolidatedResponse, { customer_order_id: rec.customer_order_id});
          //   if(orderIndex >=0){
          //     consolidatedData[index] =  consolidatedResponse[orderIndex]
          //   } else{
          //     consolidatedData.push(consolidatedResponse[orderIndex]);
          //   }
          // })
          setConsolidatedOrders(consolidatedData);
          setAccessorialInfo(response.accessorial_consolidations || []);
          setPrimaryId(
            response?.primary_order_id ? response.primary_order_id : ""
          );
          setAllowForSave(true);
          setIsFormChanged(true);
          alertMessage(I18n.t("messages.applied_successfully"), "success", 5);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleReset = () => {
    setFormData({ ...initialFormData });
  };

  // To confirm & save consolidation details
  const confirmConsolidation = () => {
    if (allowForSave || consolidationDetails?.id) {
      if (consolidationDetails?.id && !isFormChanged) {
        updateConsolidationDetails();
      } else {
        setIsConfirmLoading(true);
        const data = {
          organization_id: orgId,
          account_id: consolidatedAccount?.id ? consolidatedAccount.id : "",
          consolidation_number: consolidateNumber,
          // consolidation_method: formData.consolidation_method,
          // weight_type: formData.weight_type,
          // primary_order_id: formData.consolidation_method === 'RATE_CARD' && !isEmpty(primaryId) ? primaryId : '',
          // order_type: formData.order_type || "",
          // invoice_line_type: formData.invoice_line_type || "",
          consolidated_orders: [...consolidatedOrders],
          //accessorial_consolidations: accessorialInfo,
          comments: formData.comments,
          consolidate_order_id: consolidationDetails?.id || '', 
        };
        BillingConsolidationApi.confirmConsolidation(data)
          .then((response) => {
            if (response.success) {
              const consolidated_order = response.consolidated_order || {};
              setConsolidationDetails({ ...consolidated_order });
              setConsolidatedOrders(
                consolidated_order?.consolidated_orders
                  ? consolidated_order.consolidated_orders
                  : []
              );
              setAccessorialInfo(
                consolidated_order?.accessorial_consolidations
                  ? consolidated_order.accessorial_consolidations
                  : []
              );
              setPrimaryId(
                consolidated_order?.primary_order_id
                  ? consolidated_order.primary_order_id
                  : ""
              );
              setConsolidatedAccount(
                response?.account ? response.account : null
              );
              setIsFormChanged(false);
              alertMessage(I18n.t("messages.saved"), "success", 5);
              setFormData({
                ...initialFormData,
                comments: consolidated_order?.comments || "",
              });
              setShowRevert(true);
              setAllowApprove(false);
            } else {
              alertMessage(response.errors.join(","), "error", 10);
            }
          })
          .catch((error) => {
            alertMessage(error, "error", 10);
            // handle error response
          })
          .finally(() => {
            setIsConfirmLoading(false);
          });
      }
    } else {
      alertMessage("Please apply before confirm", "error", 10);
    }
  };

  // to update prices
  const updatePrices = (orderId, values, updatedConsolidation) => {
    const clonedOrders = _.cloneDeep(consolidatedOrders);
    const orderObjectIndex = _.findIndex(clonedOrders, {
      customer_order_id: orderId,
    });
    if (orderObjectIndex >= 0) {
      const orderObject = consolidatedOrders[orderObjectIndex];
      orderObject["consolidation_prices"] = values;
      orderObject["order_consolidate_price"] = updatedConsolidation;
      clonedOrders[orderObjectIndex] = orderObject;
      setConsolidatedOrders(clonedOrders);
    }
  };
  const handleOnChange = (element, value) => {
    setFormData({ ...formData, [element]: value });
  };
  const deleteClick = (id) => {
    const filteredOrders = consolidatedOrders.filter(
      (order) => order?.customer_order_id !== id
    );
    //applyConsolidation(orders);
    if (id === primaryId) {
      setPrimaryId("");
    }
    setConsolidatedOrders([...filteredOrders]);
  };
  const totalLBS =
    consolidatedOrders &&
    consolidatedOrders.reduce((acc, b) => acc + b.actual_wt, 0);

  const totalDim_wt =
    consolidatedOrders &&
    consolidatedOrders.reduce((acc, b) => acc + b.dim_wt, 0);

  const total =
    consolidatedOrders &&
    consolidatedOrders.reduce(
      (acc, b) => Number(acc) + Number(b.actual_billing_price),
      0
    );
  const totalConsolidate =
    consolidatedOrders &&
    consolidatedOrders.reduce(
      (acc, b) => Number(acc) + Number(b.order_consolidate_price),
      0
    );

  const applyButtonStyle = {
    marginTop: "30px",
    // padding:'20px',
    width: "100px",
    paddingTop: "-5px",
    // width: '10%',
    backgroundColor: "blue",
    borderRadius: "20px",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
  };
  const resetButtonStyle = {
    marginTop: "30px",
    marginRight: "7px",
    // padding:'20px',
    width: "100px",
    paddingTop: "-5px",
    // width: '10%',
    backgroundColor: "red",
    borderRadius: "20px",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
  };

  const applyButtonStyleRed = {
    marginTop: "30px",
    // padding:'20px',
    width: "100px",
    paddingTop: "-5px",
    // width: '10%',
    backgroundColor: "red",
    borderRadius: "20px",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
  };

  const searchButtonStyle = {
    // marginTop: '30px',
    // padding:'20px',
    width: "150px",
    paddingTop: "-5px",
    // width: '10%',
    backgroundColor: "blue",
    buttontype: "primary",
    borderRadius: "20px",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
  };

  const totalOf = {
    width: "90px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid grey",
    padding: "2px",
    fontSize: 12,
  };
  const totalOfForm = {
    width: "90px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid grey",
    padding: "5px",
    marginLeft: "20px",
    backgroundColor: "#dddddd",
  };

  const setPrimaryOrder = (id) => {
    applyConsolidation(null, id);
    setPrimaryId(id);
  };
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const handleCancelInvoice = () => {
    setShowInvoiceModel(false);
    setInvoiceNo("");
  };
  const handleSaveInvoice = () => {
    const data = {
      organization_id: orgId,
      customer_order_ids: consolidatedOrders.map(
        (order) => order?.customer_order_id
      ),
      invoice_number: invoiceNo,
    };
    setIsLoading(true)
    BillingConsolidationApi.approveConsolidationOrderInvoices(data)
      .then((response) => {
        if (response.success) {
          alertMessage(I18n.t("messages.approve"), "success", 5);
          setBillingStatus('APPROVED')
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
      })
      .finally(() => {
        setShowInvoiceModel(false);
        setInvoiceNo("");
        setIsLoading(false)
      });
  };
  const invoiceNoWindow = () => {
    return (
      <Modal
        title={I18n.t("invoices.do_you_want_to_provide_invoice_no")}
        visible={showInvoiceModel}
        okButtonProps={{
          style: { display: "none" },
        }}
        onCancel={handleCancelInvoice}
        closable
        width={400}
        footer={null}
      >
        <div>
          <Row>
          <Col xs={24}>
              <FormItem label={`${I18n.t("account.billing.invoice_no")}(if any)`}>
                <Input
                  value={invoiceNo}
                  onChange={(e) => setInvoiceNo(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="textUpperCase"
                  
                />
              </FormItem>
            </Col>
            <Col xs={24} className="alignRight marginTop20">
              <Button
                type={"default"}
                onClick={handleCancelInvoice}
                style={{ marginRight: 10 }}
              >
                {I18n.t("general.cancel")}
              </Button>
              <Button type={"primary"} onClick={handleSaveInvoice} loading={isLoading}>
                {I18n.t("invoices.approve")}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  // gotoInvoiceDetail = (id, isDetailScreen = true) => {
  //   const { setPaginationData, setRecordPerPage, setSelectedException } = this.props.orderConfigContext;
  //   setPaginationData(this.state.pagination);
  //   setRecordPerPage(this.state.recordsPerPage);
  //   setSelectedException(this.state.exception_selected_codes);
  //   if (isDetailScreen) {
  //     this.props.history.push(`/orders/${id}/details`);
  //   } else {
  //     this.props.history.push(`/orders/${id}/edit`);
  //   }
  // };

  const handleGoBack = () => {
    history.push({ pathname: "/billing" });
  };
  const handleSelectChange = (keys, rows) => {
    setSelectedInfo({ keys, rows });
  };
  return (
    <Spin spinning={isLoading || isConfirmLoading}>
      {showHeader && (
        <Row className="page-header">
          <span onClick={() => handleGoBack()}>
            <Icon
              type="arrow-left"
              style={{ cursor: "pointer", marginRight: 10 }}
            />
          </span>
          <Col xs={12}>{I18n.t("consolidation.label")}</Col>
        </Row>
      )}
      <Row
        style={{
          backgroundColor: "white",
          paddingLeft: 30,
          width: "100%",
          paddingRight: 30,
        }}
        className="marginBottom20"
      >
        <Col
          span={24}
          lg={12}
          gutter={16}
          // style={{
          //   order: 1,
          //   flex: "1 1 calc(50% - 20px)",
          //   padding: "10px",
          //   marginBottom: "20px",
          // }}
        >
          <FormItem label={"Select Consolidation Method:"}>
            <BaseSelect
              data={consolidationMethods}
              className="marginRight10 marginLeft10"
              value={formData.consolidation_method}
              onChange={(e) => {
                handleOnChange("consolidation_method", e);
                setPrimaryId("");
              }}
              selectKey={"value"}
              selectValue={"value"}
              selectedValue={"label"}
              style={{ width: "90%" }}
              showSearch={true}
              disabled={!showActions}
            />
          </FormItem>
          {/* Weight */}
          <FormItem
            label={"Set each shipment's console wt to:"}
            style={{ marginTop: "15px" }}
          >
            {/* <h3 style={{ marginTop: "15px", fontWeight: 800 }}>
            Set each shipment's console wt to:
          </h3> */}
            <Radio.Group
              onChange={(e) => handleOnChange("weight_type", e.target.value)}
              value={formData.weight_type}
              style={{ display: "flex" }}
              disabled={!showActions}
            >
              <Radio value={"ACT"}>Actual</Radio>
              <Radio value={"DIM"}>DIM</Radio>
              <Radio value={"MAX"}>Max of both</Radio>
              <div style={totalOfForm}>
                {formData.weight_type === "ACT"
                  ? totalLBS
                  : formData.weight_type === "DIM"
                  ? totalDim_wt
                  : formData.weight_type === "MAX"
                  ? totalLBS > totalDim_wt
                    ? totalLBS
                    : totalDim_wt
                  : ""}
              </div>
            </Radio.Group>
          </FormItem>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <h3
              style={{
                marginRight: "20px",
                marginTop: "5px",
                fontWeight: 800,
              }}
            >
              Apply For :
            </h3>
            <Radio.Group
              onChange={(e) => handleOnChange("order_type", e.target.value)}
              value={formData.order_type}
              defaultValue={"DL"}
              disabled={!showActions}
            >
              {/* <Radio value={"AL"}>All</Radio> */}
              <Radio value={"DL"}>Delivery</Radio>
              <Radio value={"PU"}>Pickup</Radio>
            </Radio.Group>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <h3
              style={{
                marginRight: "20px",
                marginTop: "5px",
                fontWeight: 800,
              }}
            >
              Apply For :
            </h3>

            <Radio.Group
              onChange={(e) =>
                handleOnChange("invoice_line_type", e.target.value)
              }
              value={formData.invoice_line_type}
              defaultValue={"TRP"}
              disabled={!showActions}
            >
              <Radio value={"TRP"}>Transportation</Radio>
              <Radio value={"ACC"}>Accessorial</Radio>
              <Radio value={"TRP_ACC"}>Transportation & Accessorial</Radio>
            </Radio.Group>
          </div>
          { showActions && <Row>
            <Button
              style={resetButtonStyle}
              onClick={handleReset}
              type="danger"
              icon="redo"
            >
              Reset
            </Button>
            {selectedInfo.keys.length > 0 ? (
              <Button
                style={applyButtonStyle}
                onClick={() => applyConsolidation(null)}
                loading={isLoading}
                buttontype="primary"
                icon="check-circle"
              >
                Apply
              </Button>
            ) : (
              <Tooltip title="Please select invoice to apply">
                <Button
                  style={applyButtonStyle}
                  loading={isLoading}
                  buttontype="primary"
                  icon="check-circle"
                >
                  Apply
                </Button>
              </Tooltip>
            )}
          </Row>

          }
        </Col>

        <Col
          span={24}
          lg={12}
          // style={{
          //   order: 2,
          //   flex: "1 1 calc(50% - 20px)",
          //   padding: "10px",
          //   marginBottom: "20px",
          // }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ fontWeight: "bold" }}>Account: </h4>{" "}
              <span style={{ marginBottom: "5px", marginLeft: 5 }}>
                {consolidatedAccount?.name ? consolidatedAccount.name : ""}
              </span>
            </div>

            {showActions && consolidationDetails?.id && (
              <Button
                style={{ ...applyButtonStyle, width: 100, marginTop: 0 }}
                onClick={() => revertConsolidation(consolidationDetails?.id)}
                loading={isLoading}
                buttontype="primary"
                icon="redo"
              >
                Revert
              </Button>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center", fontWeight: 800 }}>
  <h4 style={{ fontWeight: "bold" }}><sup className="textRed">*</sup>Consolidate number:</h4>
  <span style={{ marginLeft: "5px" }}>
    <Input
      value={consolidateNumber}
      onChange={(e) => setConsolidateNumber(e.target.value)}
      onKeyDown={handleKeyDown}
      style={{ letterSpacing: "0" }}
      disabled={!showActions}
    />
  </span>
</div>
          <FormItem label={I18n.t("general.comments")}>
            <Input.TextArea
              rows={3}
              style={{
                border: "1px solid",
                borderColor: "#d3d3d3",
                height: "100px",
              }}
              value={formData.comments}
              onChange={(e) => handleOnChange("comments", e.target.value)}
              disabled={!showActions}
            />
          </FormItem>
          { showActions && <Row type="flex" className="marginTop20" gutter={16}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>Add Orders</h3>
            </Col>
            <Col style={{ width: "80%" }}>
              <Search
                placeholder={I18n.t("order.filters.placeholder")}
                onSearch={validateOrders}
                onChange={(e) => setOrderRefNumber(e.target.value)}
                value={orderRefNumber}
                enterButton={
                  <span>
                    <Icon type="search" /> Search & Add
                  </span>
                }
                style={{ width: "100%" }}
              />
            </Col>
          </Row>}

          {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: '100%',
                  marginTop: "50px",
                }}
              >
                <h3 style={{fontWeight:800 ,width:"150px"}}>Add Orders</h3>
                <Input
                  style={{
                    border: "1px solid",
                    borderColor: "#d3d3d3",
                    marginRight: "10px",
                  }}
                  value={addOrder}
                  onChange={(e)=>setAddOrder(e.target.value)}
                />
                <Button style={searchButtonStyle} onClick={handleAddOrder}>Search and Add</Button>
              </div> */}
        </Col>
      </Row>
      <div
        className="alignCenter consolidation"
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
          paddingTop: 30,
          marginTop: -20,
        }}
      >
        <Fragment>
          <div className="centerToBlock">
            <ConsolidationList
              scroll={{ y: "calc(100vh - 600px)" }}
              tableLayout="fixed"
              showActions={showActions}
              data={consolidatedOrders}
              style={{
                width:
                  formData.consolidation_method === "RATE_MASTER" ? 1225 : 1100,
                overflow: "hidden",
              }}
              pagination={{
                position: "none",
              }}
              deleteClick={deleteClick}
              consolidation_method={formData.consolidation_method}
              setPrimaryOrder={setPrimaryOrder}
              // scroll={{ overflow: "hidden" }}
              bordered
              confirmConsolidation={confirmConsolidation}
              // changeBillingPrice={changeBillingPrice}
              changeConsolidationPrice={changeConsolidationPrice}
              rowSelection={showActions ? {
                selectedRowKeys: selectedInfo.keys || [],
                onChange: handleSelectChange,
              }: null}
              updatePrices={updatePrices}
              // footer={
              //   formData.consolidation_method ? ( // Check if consolidation_method has a value
              //     () => (
              //       <Row
              //         type={"flex"}
              //         style={{
              //           marginTop:'20px',
              //           width:
              //             formData.consolidation_method === "RATE_MASTER" ? "20%" : "21%",
              //           position: "absolute",
              //           display: "flex",
              //           justifyContent: "space-between",
              //           left:
              //             formData.consolidation_method === "RATE_MASTER" ? "56%" : "62%",
              //         }}
              //       >
              //         <Col style={{ width: "23%" }}>
              //           <div style={totalOf}>{formatWeight(totalLBS)}</div>
              //         </Col>
              //         <Col style={{ width: "23%" }}>
              //           <div style={totalOf}>{totalDim_wt}</div>
              //         </Col>
              //         <Col style={{ width: "30%" }}>
              //           <div style={totalOf}>{checkNegitive(total)}</div>
              //         </Col>
              //       </Row>
              //     )
              //   ) : null // If consolidation_method is falsy, don't render the footer
              // }
              footer={() => (
                <Fragment>
                  <Row type="flex" className="fontSize12 textBold">
                    <Col
                      style={{ width: 735 }}
                      className="alignRight paddingRight10"
                    >
                      {" "}
                      Total
                    </Col>
                    <Col style={{ width: 65 }}>
                      <div>{formatWeight(totalLBS)}</div>
                    </Col>
                    <Col style={{ width: 65 }} className="alignRight">
                      <div>{formatWeight(totalDim_wt, true, "0 lbs")}</div>
                    </Col>
                    <Col style={{ width: 95 }} className="alignRight">
                      <div>{checkNegitive(total)}</div>
                    </Col>
                    <Col style={{ width: 105 }} className="alignRight">
                      <div>{checkNegitive(totalConsolidate)}</div>
                    </Col>
                  </Row>
                </Fragment>
              )}
              rowClassName={(record, _index) =>
                consolidationDetails?.primary_order_id ===
                record?.customer_order_id
                  ? "blockBgErrorColor"
                  : ""
              }
              rowKey={"customer_order_id"}
              size="small"
              primaryId={primaryId}
              selectedKeys={selectedInfo.keys || []}
            />
          </div>

          { showActions && <div className="alignCenter" style={{ marginTop: "20px" }}>
            <Button
              style={resetButtonStyle}
              type="danger"
              icon="close"
              onClick={() => handleGoBack()}
            >
              Cancel
            </Button>
            {allowForSave || consolidationDetails?.id ? (
              <Button
                style={applyButtonStyle}
                onClick={() => confirmConsolidation()}
                loading={isConfirmLoading}
                disabled={!allowForSave && !consolidationDetails?.id}
                icon="save"
              >
                Save
              </Button>
            ) : (
              <Tooltip title="Please click on Apply before Save">
                <Button style={applyButtonStyle} icon="save">
                  Save
                </Button>
              </Tooltip>
            )}
            {consolidationDetails?.id || (allowForSave && allowApprove) ? (
              <Popconfirm
                placement="topRight"
                title={I18n.t("messages.confirm")}
                onConfirm={() => setShowInvoiceModel(true)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" style={{ marginLeft: "10px" }} icon='file-done'>
                  {I18n.t("invoices.approve")}
                </Button>
              </Popconfirm>
            ) : (
              <Tooltip title="Please click on Apply and save before Approve">
                <Button
                  type="primary"
                  icon="file-done"
                  style={{ marginLeft: "10px" }}
                >
                  {I18n.t("invoices.approve")}
                </Button>
              </Tooltip>
            )}
          </div>}

          {showInvoiceModel && invoiceNoWindow()}
        </Fragment>
      </div>
    </Spin>
  );
};

export default withRouter(Consolidate);
