import React, { Component } from "react";
import { TemplateEventsApi } from "../api/TemplateEventsApi";
import { TemplateParamsApi } from "../api/TemplateParamsApi";
import { alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import { Row, Col, Button, Spin } from "../common/UIComponents";
import BaseModal from "../components/BaseModal";
import EventParamsForm from "../components/configurations/template_events/Form";
import EventParamsList from "../components/configurations/template_events/List";
import AppConfig from "../config/AppConfig";

class TemplateEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
      pagination: {},
      showFormModal: false,
      messages: [],
      templates: [],
      currentEvent: {},
      isNew: true,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }
  componentDidMount() {
    this.getTemplateEvents();
    this.getTemplateParams();
  };
  getTemplateEvents = (initial, cb) => {
    this.setState({
      inProgress: true,
      currentEvent: {},
    });
    const { filter } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    TemplateEventsApi.fetchEvents("list", page, perPage).then((result) => {
      if (result.success) {
        this.setState(
          {
            messages: result.data || [],
            inProgress: false,
            pagination: result.pagination,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };
  getTemplateParams = (initial, cb) => {
    this.setState({ inProgress: true, currentTemplate: {} });
    TemplateParamsApi.fetch().then((result) => {
      if (result.success) {
        this.setState(
          {
            templates: result.params || [],
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.tableOptions = { pagination, filters, sorter };
    this.getTemplateEvents();
  }
  handleOnModalClose = () => {
    this.setState({
      showFormModal: false,
      currentEvent: {},
    });
  };
  handleAddClick = () => {
    this.setState({
      showFormModal: true,
    });
  };
  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false, currentEvent: {} }, () => {
      this.getTemplateEvents();
    });
  };
  handleEdit = (id) => {
    const index = _.findIndex(this.state.messages, ["id", id]);
    const currentEvent = Object.assign({}, this.state.messages[index]);
    this.setState({ currentEvent, showFormModal: true });
  };
  handleDelete = (id) => {
    this.setState({ inProgress: true });
    TemplateEventsApi.delete(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("template_events.delete"));
        this.getTemplateEvents();
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };
  renderEventForm = () => {
    let title;
    let isNew;
    if (this.state.currentEvent.id) {
      title = I18n.t("template_events.edit");
      isNew = false;
    } else {
      title = I18n.t("template_events.add_template");
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={this.handleOnModalClose}
        width="40%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <EventParamsForm
          onCancel={this.handleOnModalClose}
          onSuccess={(message) => this.handleSaveSuccess(message)}
          isNew={isNew}
          currentEvent={
            this.state.currentEvent.id ? this.state.currentEvent : {}
          }
          templates={this.state.templates}
        />
      </BaseModal>
    );
  };

  render() {
    const { pagination } = this.state;
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("template_events.template")}</Col>
            <Col xs={12} className="page-actions">
              <Button type="primary" icon="plus" onClick={this.handleAddClick}>
                {I18n.t("template_events.add")}
              </Button>
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col md={{ offset: 2, span: 18 }}>
                <EventParamsList
                  data={this.state.messages}
                  scroll = {{y:"calc(100vh - 240px)"}}
                  size="small"
                  editClick={(id) => this.handleEdit(id)}
                  deleteClick={(id) => this.handleDelete(id)}
                  pagination={{
                    total: pagination.total_count,
                    current: pagination.current_page,
                    pageSize: pagination.per_page,
                  }}
                  tableChange={
                    (tablePagination, filters, sorter) =>
                      this.handleTableChange(tablePagination, filters, sorter)
                  }
                />
              </Col>
            </Row>
          </Spin>
          {this.state.showFormModal && this.renderEventForm()}
        </div>
      </div>
    );
  }
}

export default TemplateEvents;
