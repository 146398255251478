import { Col, Popconfirm, Row, Tooltip, Button } from "antd";
import React from "react";
import DeleteIcon from "../common/DeleteIcon";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import GearIcon from "../common/GearIcon";
import EditIcon from "../common/EditIcon";
import { checkNegitive } from "../../common/Common";
import CopyableContent from "../common/CopyableContent";
import { checkServiceExistance } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import moment from "moment";
import Copyable from "../common/Copyable";

class BatchIndexList extends BaseList {
  constructor(props) {
    super(props);
    const showActions = checkServiceExistance(["ARBU", "ARBCI"], "ANY");
    this.columns = [
      {
        key: "batch_number",
        title: <b>{`${"#"} ${I18n.t("batch_management.batch_name")}`}</b>,
        dataIndex: "batch_number",
        render: (data, record) => <CopyableContent text={data} showTooltip />,
        width: 80,
        ...AppConfig.sortingOptions
      },
      {
        key: "created_at",
        title: <b>{I18n.t("batch_management.createdOn")}</b>,
        dataIndex: "created_at",
        render: (data, record) => (
          <span>{moment(data).format("Do MMM YYYY")}</span>
        ),
        width: 80,
        ...AppConfig.sortingOptions
      },
      {
        key: "capped_amount",
        title: <b>{"Batch Amount"}</b>,
        dataIndex: "capped_amount",
        render: (data, record) => (
          <span className="textBold">{checkNegitive(data)}</span>
        ),
        width: 80,
        align: "right",
        ...AppConfig.sortingOptions
      },
      {
        key: "total_amount",
        title: <b>{I18n.t("batch_management.balance_amount")}</b>,
        dataIndex: "total_amount",
        render: (data, record) => (
          <span className="textBold">{checkNegitive(data)}</span>
        ),
        width: 100,
        align: "right",
        ...AppConfig.sortingOptions
      },
      {
        key: "paid_amount",
        title: <b>{I18n.t("batch_management.applied_remained")}</b>,
        dataIndex: "paid_amount",
        render: (data, record) => (
          <span className="textBold">{checkNegitive(data)}</span>
        ),
        width: 100,
        align: "right",
        ...AppConfig.sortingOptions
      },
      {
        key: "balanced_amount",
        title: <b>{I18n.t("account_receivables.balance_remained")} </b>,
        dataIndex: "balanced_amount",
        render: (text, data) => checkNegitive(text),
        width: 100,
        align: "right",
        ...AppConfig.sortingOptions
      },
    ];
    if (showActions) {
      this.columns.push({
        key: "manage",
        title: <b>{I18n.t("general.manage")}</b>,
        render: (data) => {
          //   const { handleDelete} = this.props;
          const { handleRedirect, handleEdit } = this.props;
          const { id } = data;
          if (checkServiceExistance("ARBI")) {
            return (
              <div className="line-actions-batch paddingLeft10">
                <Row gutter={8} type="flex" justify="end" style={{flexFlow: "nowrap"}}>
                  {checkServiceExistance("ARBU") && (
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => handleEdit(id)}
                        size="small"
                        icon='edit'
                      >
                        {I18n.t("general.edit")}
                      </Button>
                    </Col>
                  )}
                  {checkServiceExistance("ARBCI") && (
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => handleRedirect(id)}
                        size="small"
                        icon='file-text'
                      >
                        {I18n.t("account_receivables.cheque_details")}
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            );
          } else {
            return null;
          }
        },
        align: "center",
        width: 100,
      });
    }
  }
}
export default BatchIndexList;
