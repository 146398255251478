/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import BaseList from '../BaseList'
import {
  checkNegitive,
  compareString,
  ellipseText,
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Icon,
  Popover,
  Col,
  Row,
  Tooltip,
  Popconfirm,
  Typography,
  Link,
} from '../../common/UIComponents'
import AppConfig from '../../config/AppConfig'
import AddressInfo from '../common/AddressInfo'
import userStore from '../../stores/UserStore'
import { checkServiceExistance } from '../../helpers/common'
import TypeOfOrder from '../orders/TypeOfOrder'
import DisplayTime from '../common/DisplayTime'
import CustomerOrderDetails from '../orders/CustomerOrderDetails'
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import Copyable from '../common/Copyable'

const nextSteps = {
  NEW: { readable: 'RECEIVED', key: 'RECEIVED', canChange: true },
  RECEIVED: { readable: 'VERIFIED', key: 'VERIFIED', canChange: true },
  PENDING: { readable: 'ASSIGNED', key: 'ASSIGNED', canChange: false },
  VERIFIED: { readable: 'PREPLAN', key: 'PREPLAN', canChange: false },
  PREPLAN: { readable: 'ASSIGNED', key: 'ASSIGNED', canChange: false },
  ASSIGNED: { readable: 'PREPARE', key: 'PREPARE', canChange: false },
  PREPARE: { readable: 'DISPATCH', key: 'DISPATCHED', canChange: false },
  DISPATCHED: { readable: 'COMPLETE', key: 'COMPLETED', canChange: false },
  PICKEDUP: { readable: 'COMPLETE', key: 'COMPLETED', canChange: false },
  COMPLETED: { readable: '', key: '', canChange: false },
  EXCEPTION: { readable: '', key: '', canChange: false },
  CANCELLED: { readable: '', key: '', canChange: false },
}
const { Text } = Typography

class QuotesList extends BaseList {
  constructor(props) {
    super(props)
    this.columns = []
    this.setColumns(props)
  }
  setColumns = (props) => {
    const {
      quoteViewId,
      setQuoteViewId,
      validateQuote,
      showQuoteDetails,
    } = props
    this.columns = [
      // {
      //   title: '#',
      //   key: 'index',
      //   render: (text, record, index) => ((this.state.page-1) * AppConfig.perPage)+(index+1),
      // },

      {
        key: 'quote_no',
        title: I18n.t('quotes.quote_no'),
        dataIndex: 'quote_order_number',
        // sorter: (a, b) => compareString(a.quote_order_number, b.quote_order_number),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) =>
          this.setLinkColumn(data, record, showQuoteDetails,false),
        width: 140
      },
      {
        key: 'hawb',
        title: I18n.t("general.hawb"),
        dataIndex: 'hawb',
        width: 110,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => 
          this.setLinkColumn(data, record, showQuoteDetails,true)
      },
      {
        key: 'mawb',
        title: I18n.t("general.mawb"),
        dataIndex: 'mawb',
        width: 110,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => 
          this.setLinkColumn(data, record, showQuoteDetails,true)
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="top"
              showBadge={true}
            />
          </div>
        ),
        width: 100,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: 'quote_date',
        title: I18n.t('quotes.quote_date'),
        dataIndex: 'quote_date',
        // sort date using moment
        // sorter: (a, b) => {
        //   const aDate = moment(a.quote_date)
        //   const bDate = moment(b.quote_date)
        //   return aDate.isBefore(bDate) ? -1 : 1
        // },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data) => {
          return (
            <span>
              {moment(data).isValid()
                ? moment.utc(data).format(AppConfig.date_format)
                : ''}
            </span>
          )
        },
        width: 140
      },
      // {
      //   key: "customer",
      //   title: I18n.t("general.customer"),
      //   render: (data) => (
      //     <div>
      //       <Popover
      //         title={I18n.t("general.customer_details")}
      //         content={
      //           <Fragment>
      //             <Row>
      //               <Col lg={3} md={3} sm={6} xs={6}>
      //                 <Icon type="bank" />
      //               </Col>

      //               <Col lg={21} md={21} sm={18} xs={18}>
      //                 {isEmpty(data.company_name)
      //                   ? "N/A"
      //                   : data.company_name}
      //               </Col>
      //             </Row>
      //             <Row>
      //               <Col lg={3} md={3} sm={6} xs={6}>
      //                 <Icon type="phone" />
      //               </Col>

      //               <Col lg={21} md={21} sm={18} xs={18}>
      //                 {isEmpty(data.customer_phone_one)
      //                   ? "N/A"
      //                   : formatUSPhoneNumber(data.customer_phone_one)}
      //               </Col>
      //             </Row>
      //             <Row>
      //               <Col lg={3} md={3} sm={6} xs={6}>
      //                 <Icon type="phone" />
      //               </Col>

      //               <Col lg={21} md={21} sm={18} xs={18}>
      //                 {isEmpty(data.customer_phone_two)
      //                   ? "N/A"
      //                   : formatUSPhoneNumber(data.customer_phone_two)}
      //               </Col>
      //             </Row>
      //             <Row>
      //               <Col lg={3} md={3} sm={6} xs={6}>
      //                 <Icon type="mail" />
      //               </Col>

      //               <Col lg={21} md={21} sm={18} xs={18}>
      //                 {isEmpty(data.customer_email)
      //                   ? "N/A"
      //                   : data.customer_email}
      //               </Col>
      //             </Row>
      //           </Fragment>
      //         }
      //         getPopupContainer={(triggerNode) => triggerNode}
      //       >
      //         {isEmpty(data.customer_first_name)
      //           ? "N/A"
      //           : `${data.customer_first_name} ${
      //               !isEmpty(data.customer_last_name)
      //                 ? data.customer_last_name
      //                 : ""
      //             }`}
      //       </Popover>
      //     </div>
      //   ),
      // },
      {
        key: 'city',
        title: 'Origin -> Destination',
        dataIndex: 'warehouse_address',
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        render: (text, data) => (
          <div style={{ display: 'flex' }}>
            <div>{this.setAddress(data, 'origin')}</div>
            <span>&nbsp;{' - '}&nbsp;</span>
            <div>{this.setAddress(data, 'destination')}</div>
          </div>
        ),
        width: 200,
      },
      // {
      //   key: "city",
      //   title: "City",
      //   dataIndex: "customer_address",
      //   sorter: true,
      //   sortDirections: ["ascend", "descend", "ascend"],
      //   render: (text, data) => (
      //     <div style={{ display: "flex" }}>
      //       <div>{data.customer_address.city || ''}</div>
      //     </div>
      //   ),
      //   width: 75,
      // },
      // {
      //   key: 'type_of_order',
      //   title: I18n.t('order.type'),
      //   render: (record) => (
      //     <TypeOfOrder
      //       order={record}
      //       orderTypeKey="type_of_order"
      //       relatedOrderKey="related_order"
      //       placement={'top'}
      //     />
      //   ),
      //   align: 'center',
      // },
      {
        key: 'account_name',
        title: I18n.t('account.account'),
        dataIndex: 'account_name',
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL")
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? 'anchor_cursor' : ''}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          )
        },
        // sorter: (a, b) => compareString(a.account_code, b.account_code),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        // width: 150
        minWidth: 100
      },
      {
        key: 'account_code',
        title: I18n.t('general.account_code'),
        dataIndex: 'account_code',
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL")
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? 'anchor_cursor' : ''}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          )
        },
        // sorter: (a, b) => compareString(a.account_code, b.account_code),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        // width: 150
        minWidth: 100
      },
      {
        key: 'expiry_date',
        title: I18n.t('quotes.expiry_date'),
        dataIndex: 'expiry_date',
        sorter: (a, b) => new Date(a.expiry_date) - new Date(b.expiry_date),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (expiry_date, record) => (
          <div>
            {expiry_date && 
              moment(expiry_date).isValid()
                ? moment.utc(expiry_date).format(AppConfig.date_format)
                : ''
              // <DisplayTime
              //   isUTC={true}
              //   dateTimeString=""
              //   displayWithDate={true}
              //   key={record.id}
              // />
            }
          </div>
        ),
        width: 140
      },
      {
        key: 'weight',
        title: <Fragment>{I18n.t('order.weight')} ({AppConfig.weight_unit})</Fragment>,
        dataIndex: 'weight',
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (weight) => (weight),
        width: 120,
      },
      // {
      //   key: 'quantity',
      //   title: I18n.t('order.qty'),
      //   dataIndex: 'quantity',
      //   render: (quantity) => quantity,
      // },
      {
        key: 'los',
        title: I18n.t('los.label'),
        dataIndex: "level_of_service",
        // sorter: (a, b) => compareString(a.level_of_service || '', b.level_of_service || ''),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: 150,
        render :(data,record) => {
          if(record.type_of_order !== "T") return record.los_name
          const pickupLos = _.get(record, 'pickup_location_details.los_name');
          const deliveryLos =  _.get(record, 'delivery_location_details.los_name');
          const los = pickupLos && deliveryLos ? `${pickupLos} - ${deliveryLos}` : pickupLos || deliveryLos;
          return(
            <div>
              {los}
            </div>
          );
        }
    },
      {
        key: 'quote',
        title: I18n.t('order.quote'),
        dataIndex: 'quote_amount',
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => {
          return (
            <Tooltip title={'Quote Amount'}>
              <span
                className="anchor_cursor"
                onClick={() => {
                  showQuoteDetails(record.id)
                }}
              >
                {!isEmpty(data) ? checkNegitive(data) : ''}
              </span>
            </Tooltip>
          )
        },
      },
    ]
    //if (props.currentFilter === "") {
    this.columns.push({
      key: 'status',
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      title: I18n.t('general.status'),
      dataIndex: 'status',
    })
    // }
    // this.columns.push({
    //   key: 'order_no',
    //   title: I18n.t('order.order_no'),
    //   dataIndex: 'orders',
    //   render: (orders, record) => {
    //     orders = !isEmpty(orders) && _.isArray(orders) ? orders : [];
    //     const orderObj = orders.length > 0 ? orders[0] : null;
    //     return <Fragment>
    //       {['APPROVED'].includes(record.status) && !isEmpty(orderObj) && (
    //             <CustomerOrderDetails
    //               order_no={orders.map(order => order.order_number).join(', ')}
    //               key={orderObj.order_id}
    //               order={{
    //                 id: orderObj.order_id,
    //                 customer_order_number: orderObj.order_number,
    //               }}
    //               showEdit={false}
    //             />
    //           )}

    //     </Fragment>
    //   }
    // })
    this.columns.push({
      key: "created_by",
      title: I18n.t("order.last_updated_by"),
      dataIndex: "created_by",
      width: 140
    });
    if (this.props.showActions !== false) {
      this.columns.push({
        key: 'items',
        fixed: "right",
        // title: props.screen === "routes" ? 'Items' : 'Details',
        render: (data,record) => {
          const title = `#${data.customer_order_number}`
          return (
            <Row type="flex" gutter={8} align="middle" justify="end">
              {!['APPROVED', 'REJECTED'].includes(data.status) &&
              checkServiceExistance('QU') ? (
                <>
                <Col>
                  <Tooltip title={I18n.t("order.clone")}>
                    <Icon
                      type="snippets"
                      onClick={() => this.props.cloneQuote(record.id)}
                      className="SnippetsIcon"
                    />
                  </Tooltip>
                </Col>
                
                <Col>
                  <Tooltip title={I18n.t('general.edit')}>
                    <EditIcon handleClick={()=> this.props.handleEditClick(data)}/>
                    &nbsp;
                  </Tooltip>
                </Col>
                </>
              ) : (
                ''
              )}
              {!isEmpty(data.quote_amount) && (
                <Col>
                  {data.status === 'PENDING' && checkServiceExistance('QOA') && (
                    <Fragment>
                      <Popconfirm
                        placement="left"
                        title={I18n.t('messages.action_confirm', {
                          action: 'approve',
                          field: 'this',
                        })}
                        onConfirm={() => this.props.validateQuote(data.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title={I18n.t('general.approve')}>
                          <Icon
                            type="check-circle"
                            className="cursorPointer textGreen"
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Fragment>
                  )}
                </Col>
              )}
              {data.status === 'PENDING' && checkServiceExistance('QOD') && (
                <Col>
                  <Popconfirm
                    placement="left"
                    title={I18n.t('messages.action_confirm', {
                      action: 'reject',
                      field: 'this',
                    })}
                    onConfirm={() => this.props.deleteClick(data.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title={I18n.t('general.reject')}>
                  <Icon
                        type="close-circle"
                        className="cursorPointer textRed"
                      />
                      &nbsp;&nbsp;&nbsp;
                    </Tooltip>
                  </Popconfirm>
                </Col>
              )}
              {/* <Popconfirm
                    placement="topRight"
                    title={I18n.t('messages.delete_confirm')}
                    onConfirm={() => this.props.deleteClick(data.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Icon type="delete" className="cursorPointer textRed" />
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm> */}
            </Row>
          )
        },
        align: 'center',
        width: 100,
      })
    }

    const isQuotePage = props.screen_from === "quotes";
    if (!_.isEmpty(props.displayConfiguration) && isQuotePage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const quoteList_list_view_columns = userConfiguration.quoteList_list_view_columns || orgConfiguration.quoteList_list_view_columns;
      const columns = [];
      if (
        typeof quoteList_list_view_columns !== undefined &&
        Array.isArray(quoteList_list_view_columns)
      ) {
        quoteList_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "items");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  }

  setLinkColumn = (data, record, showQuoteDetails, showEllipsisText = true) => {
   
    return (
      <Row type="flex" style={{ flexFlow: "unset" }} gutter={4} align="middle">
        <Col>
          <span
            className="anchor_cursor"
            onClick={() => {
              showQuoteDetails(record.id);
            }}
          >
            {showEllipsisText ? ellipseText(data, 10, true): data}
          </span>
        </Col>
        {data && (
          <Col>
            <Copyable text={data} />
          </Col>
        )}
      </Row>
    );
  };

  setAddress = (data, type) => {
    const pickUpdata = data?.pickup_location_details
      ? data.pickup_location_details.l_address
      : {}
    const pickUpAddress = (
      <Popover
        title={I18n.t('general.pickup_address')}
        content={<AddressInfo address={pickUpdata} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {pickUpdata && pickUpdata.city ? `${pickUpdata.city}` : 'NA'}
      </Popover>
    )
    const deliveryData = data?.delivery_location_details
      ? data.delivery_location_details.l_address
      : {}
    const dropAddress = (
      <Popover
        title={I18n.t('general.delivery_address')}
        content={<AddressInfo address={deliveryData} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {deliveryData && deliveryData.city ? `${deliveryData.city}` : 'NA'}
      </Popover>
    )
    if (type === 'origin') {
      return pickUpAddress
    }
    return dropAddress
  }

  componentWillReceiveProps(nextProps) {
    this.columns = []
    this.setColumns(nextProps)
  }
}

QuotesList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
  cloneQuote : PropTypes.func,
}

QuotesList.defaultProps = {
  screen_from: '',
  showTriggerEdit: false,
  resolvedAddressCallback: () => {},
  cloneQuote : () => {},
}
export default QuotesList
