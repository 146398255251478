import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Row, Col, Select, Radio } from "../../common/UIComponents";
import BaseModal from "../BaseModal";
import stopIcon from "../../../assets/images/add_stop.png";
import { momentTime } from "../../helpers/date_functions";
import FormErrors from "../common/FormErrors";
import Orders from "./add_stops/Orders";
import get from "core-js/library/fn/reflect/get";
import Recoveries from "./add_stops/Recoveries";
import Releases from "./add_stops/Releases";
import Linehaul from "./add_stops/Linehaul";
import { withRouter } from "react-router";
import I18n from "../../common/I18n";

const stopTypes = [
  { key: "nav_stop", label: "Pickup / Delivery" },
  { key: "recovery_stop", label: "Recovery" },
  { key: "release_stop", label: "Release" },
  { key: "linehaul_stop", label: "Linehaul" },
];
const AddStop = ({
  route,
  getRouteStopsInfo,
  warehouses,
  warehouse_id,
  dateFilter,
  closeSearchOrders,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [stopType, setStopType] = useState("nav_stop");

  const resetValues = () => {
    setShowInfoWindow(false);
  };

  const renderStopType = () => (
    <Row className="marginBottom10">
      <Col className="textBold alignCenter">
        <span className="textBold">Stop Type:</span>&nbsp;&nbsp;
        {/* <Select
          value={stopType || ""}
          style={{ width: "200px", marginLeft: "15px" }}
          size="small"
          dropdownMatchSelectWidth={false}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={(e) => setStopType(e)}
        >
          <Select.Option key="stops_types" value="">
            -- All --
          </Select.Option>
          {stopTypes.map((rec) => (
            <Select.Option key={rec.key} value={rec.key}>
              {rec.label}
            </Select.Option>
          ))}
        </Select> */}
        <Radio.Group
          value={stopType}
          onChange={(e) => setStopType(e.target.value)}
          size={"small"}
        >
          {stopTypes.filter((type) =>type.label !=="Linehaul").map((rec) => (
            <Radio key={rec.key} value={rec.key}>
              {rec.label}
            </Radio>
          ))}
        </Radio.Group>
      </Col>
    </Row>
  );

  const renderScreen = () => {
    switch (stopType) {
      case "nav_stop":
        return <Fragment>{renderOrders()}</Fragment>;
      case "release_stop":
        return <Fragment> {renderRelease()}</Fragment>;
      case "recovery_stop":
        return <Fragment>{renderRecovery()}</Fragment>;
      // case "linehaul_stop":
      //   return <Fragment>{renderLH()}</Fragment>;
      default:
        return <Fragment />;
    }
  };

  const renderOrders = () => {
    return (
      <Orders
        route={route}
        stopType={stopType}
        getRouteStopsInfo={getRouteStopsInfo}
        warehouses={warehouses}
        warehouse_id={warehouse_id}
        closeModal={resetValues}
        dateFilter={dateFilter}
        closeSearchOrders={closeSearchOrders}
      />
    );
  };
  const renderRecovery = () => {
    return (
      <Recoveries
        route={route}
        stopType={stopType}
        getRouteStopsInfo={getRouteStopsInfo}
        warehouses={warehouses}
        warehouse_id={warehouse_id}
        closeModal={resetValues}
        dateFilter={dateFilter}
        closeSearchOrders={closeSearchOrders}
      />
    );
  };
  const renderRelease = () => {
    return (
      <Releases
        route={route}
        stopType={stopType}
        getRouteStopsInfo={getRouteStopsInfo}
        warehouses={warehouses}
        warehouse_id={warehouse_id}
        closeModal={resetValues}
        dateFilter={dateFilter}
        closeSearchOrders={closeSearchOrders}
      />
    );
  };

  const renderLH = () => {
    return (
      <Linehaul
        route={route}
        stopType={stopType}
        getRouteStopsInfo={getRouteStopsInfo}
        warehouses={warehouses}
        warehouse_id={warehouse_id}
        closeModal={resetValues}
        dateFilter={dateFilter}
        closeSearchOrders={closeSearchOrders}
      />
    );
  };
  const renderModalWindow = () => {
    // const startTime = route?.r_scheduled_start_datetime
    //   ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    //   : moment();
    // const endTime = route?.r_scheduled_start_datetime
    //   ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    //   : moment();
    const driver =
      route?.id && _.isObject(route.primary_driver)
        ? route.primary_driver?.employee_code ||
          `${route.primary_driver.first_name} ${route.primary_driver.last_name}`
        : "";
    return (
      <BaseModal
        title={
          <Row>
            <Col xs={8}> {`${I18n.t("stop.add")}s to route : ${driver}`}</Col>
            <Col xs={8} className="alignCenter">
              <span className="textBold">
                {I18n.t("info.start_time")}&nbsp;:&nbsp;
                {route.route_start_time ? (
                  <Fragment>
                    {momentTime(
                      "MMM, Do dddd",
                      route.route_start_time,
                      route.timeZoneId
                    )}
                  </Fragment>
                ) : (
                  "NA"
                )}
              </span>
            </Col>
            <Col xs={8} className="alignRight paddingRight25">
              <span className="textBold">
                {I18n.t("general.status")} : {route.nav_route_status}
              </span>
            </Col>
          </Row>
        }
        className="customer-orders-modal"
        onCancel={() => {
          resetValues();
          closeSearchOrders( true, route.id, 'currentRoute')
        }}
        width="100%"
        style={{
          top: 0,
          height: "100%",
        }}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "auto",
        }}
      >
        {/* <Row>
          <Col xs={8}>
            <span className="textBold">
              {I18n.t("general.Driver")}&nbsp;:&nbsp;
              {drivder}
            </span>
          </Col>
          <Col xs={8} className="alignCenter">
            <span className="textBold">
              {I18n.t("info.start_time")}&nbsp;:&nbsp;
              {route.route_start_time ? (
                <Fragment>
                  {momentTime(
                    "MMM, Do",
                    route.route_start_time,
                    route.timeZoneId
                  )}
                </Fragment>
              ) : (
                "NA"
              )}
            </span>
          </Col>
          <Col xs={8} className="alignRight">
            <span className="textBold">
              {I18n.t("general.status")} : {route.nav_route_status}
            </span>
          </Col>
        </Row>
        <hr /> */}
        {renderStopType()}
        {renderScreen()}
        {FormErrors(errors)}
      </BaseModal>
    );
  };

  return (
    <Fragment>
      <Tooltip title={I18n.t("stop.add")}>
        <img
          src={stopIcon}
          className="cursorPointer textPrimary"
          style={{ width: 18, marginTop: -6 }}
          onClick={() => setShowInfoWindow(true)}
          alt="add stop"
        />
        {/* <Icon type="-circle" theme="twoTone" onClick={this.openSearchOrders} className="textBold" style={{ fontSize: 18 }} /> */}
      </Tooltip>

      {showInfoWindow && renderModalWindow()}
    </Fragment>
  );
};

AddStop.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(AddStop);
