/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BaseList from "../BaseList";
import {
  checkNegitive,
  compareNumber,
  compareString,
  doFormate,
  ellipseText,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Popover,
  Col,
  Row,
  Tooltip,
  Popconfirm,
  Typography,
  Link,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import AddressInfo from "../common/AddressInfo";
import userStore from "../../stores/UserStore";
import OrderAppointments from "../orders/OrderAppointments";
import { getAppointmentTime, retrieveAddress, retrieveOrderValue } from "../../helpers/orders";
import { checkAccessExistance, checkServiceExistance } from "../../helpers/common";
import TypeOfOrder from "../orders/TypeOfOrder";
import ValidateLocationErr from "../orders/ValidateLocationErr";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import ConsigneeDetails from "../common/ConsigneeDetails";
import EditOrder from "../orders/EditOrder";
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import SettingIcon from "../common/SettingIcon";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import FormIcon from "../common/FormIcon";
import BranchesIcon from "../common/BranchesIcon";
import Copyable from "../common/Copyable";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import EndUserTitle from "../common/EndUserTitle";
import { formatWeight } from '../../helpers/orders';


const nextSteps = {
  NEW: { readable: "RECEIVED", key: "RECEIVED", canChange: true },
  RECEIVED: { readable: "VERIFIED", key: "VERIFIED", canChange: true },
  PENDING: { readable: "ASSIGNED", key: "ASSIGNED", canChange: false },
  VERIFIED: { readable: "PREPLAN", key: "PREPLAN", canChange: false },
  PREPLAN: { readable: "ASSIGNED", key: "ASSIGNED", canChange: false },
  ASSIGNED: { readable: "PREPARE", key: "PREPARE", canChange: false },
  PREPARE: { readable: "DISPATCH", key: "DISPATCHED", canChange: false },
  DISPATCHED: { readable: "COMPLETE", key: "COMPLETED", canChange: false },
  PICKEDUP: { readable: "COMPLETE", key: "COMPLETED", canChange: false },
  COMPLETED: { readable: "", key: "", canChange: false },
  EXCEPTION: { readable: "", key: "", canChange: false },
  CANCELLED: { readable: "", key: "", canChange: false },
};
const { Paragraph } = Typography;

class OrdersList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
    this.state = {
      page: props.pagination.current ? props.pagination.current : 1,
      pageSize: props.pagination.pageSize
        ? props.pagination.pageSize
        : AppConfig.perPage,
      fixedColumns: ["customer_order_number"],
    };
    if (props) {
      this.setColumns(props);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  setColumns = (props) => {
    const hasLocationRecord = !isEmpty(_.find(props.data,  (rec) => [...AppConfig.orderTypesWithLocations].includes(rec.type_of_order)))
    this.columns = [
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        align: "center",
        sorter: true,
        width:
          this.state &&
          this.state.fixedColumns.includes("customer_order_number")
            ? 120
            : "auto",
        sortDirections: ["ascend", "descend", "ascend"],
        // fixed: !props.isRowSelected ? "left" : null,
        render: (data, record) => {
          const textColor =
            props.showTriggerEdit && record.status === "EXCEPTION"
              ? "textRed"
              : "";
          return (
            <Row
              type="flex"
              // justify="space-between"
              align="middle"
              // style={{
              //   padding: "0 10px 0 5px",
              // }}
              gutter={4}
              // style={{marginTop:23}}
            >
              <Col>
                <TypeOfOrder
                  order={record}
                  orderTypeKey="type_of_order"
                  relatedOrderKey="related_order"
                  placement="topLeft"
                  showBadge={true}
                />
              </Col>
              <Col>
                {checkServiceExistance("COS") ? (
                  props.showTriggerEdit ? (
                    <Fragment>
                      <Link
                        className={textColor}
                        onClick={() => this.props.handleEditOrder(record.id)}
                      >
                        {record.status === "EXCEPTION" ? (
                          <Popover
                            title="Exception"
                            content={!isEmpty(record.exception_message) ? record.exception_message : "Reason Not Available"}
                            overlayClassName="popoverWidth"
                          >
                            {data}{" "}
                          </Popover>
                        ) : (
                          data
                        )}
                      </Link>
                      &nbsp;
                      <Copyable text={data} />
                    </Fragment>
                  ) : props.showDetailsLink !== false ? (
                    <CustomerOrderDetails
                      order_no={data}
                      order={{
                        id: record.id,
                        customer_order_number: data,
                      }}
                      showEdit={
                        !["REJECTED"].includes(record.status) ? true : false
                      }
                      editClick={() => this.props.handleEditOrder(record.id)}
                      refreshCallback={this.props.refreshCallback}
                    />
                  ) : (
                    <span className={textColor}>{data}</span>
                  )
                ) : (
                  <span className={textColor}>{data}</span>
                )}
              </Col>
              {/* <Col span={3} >
                <Copyable text={data} />
              </Col> */}
            </Row>
          );
        },
      },
      {
        key: 'street',
        title: I18n.t("general.street"),
        dataIndex: 'address_line_1',
        render: (data,record) => (
          <div style={{ display: "flex" }}>
          <Tooltip
            title={record.customer_address.address_line1}
            placement="topLeft"
            overlayStyle={{ whiteSpace: "pre-wrap" }}
          >
            <div>
              {record?.customer_address?.address_line1
                ? record.customer_address.address_line1
                : ""}
            </div>
          </Tooltip>
        </div>
        ),
        width: 140,
        className: "noWrap"
      },
      {
        key: 'cities',
        title: I18n.t("general.city"),
        dataIndex: 'city',
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data,record) => (
          <div style={{ display: "flex" }}>
            <div>
            {record?.customer_address?.city ? record.customer_address.city : ""}
              </div>
          </div>
        ),
        width: 120
      },
      {
        key: 'warehouse',
        title: I18n.t("general.warehouse"),
        dataIndex: 'warehouse',
        render: (data,record) => (
          <div style={{ display: "flex" }}>
            <div>{record.warehouse_code|| ''}</div>
          </div>
        ),
        width: 130
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: 'status',
        title: I18n.t("general.status"),
        dataIndex: 'status',
        render: (data,record) => retrieveOrderValue(record, 'status', true),
        width: hasLocationRecord ? 195 : 100,
      },
      {
        key: 'company_name',
        title: I18n.t("general.company_name"),
        dataIndex: 'company_name',
        render: (data, record) => retrieveOrderValue(record, 'company_name', false),
        width: hasLocationRecord ? 200 : 95,   // 130
      },
      {
        key: 'source',
        title: I18n.t("general.source"),
        dataIndex: 'source',
        render: (data, record) => (
          <div style={{ display: "flex" }}>
            <div>{record.source}</div>
          </div>
        )
      },
      {
        key: "full_address",
        title: "Address",
        dataIndex: "full_address",
        width: 400,
        render: (text, data) => retrieveAddress(data)
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        width: 100,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
      },
      {
        key: "appointment",
        title: I18n.t("general.scheduled_appointment"),
        dataIndex: "appointments",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, data) =>{
          const currentAppointment =
            props.screen_from === "dispatches" && props.currentRouteDate
              ? data.appointments.filter((ap) =>
                  moment(ap.start_datetime).isSame(
                    moment(props.currentRouteDate),
                    "day"
                  )
                )
              : data.appointments;
          return (
            <Fragment>
            {currentAppointment.length > 0 && (
            <Popover
              size="small"
              content={
                <div className="table-contact-info width500 fontsize12">
                  <OrderAppointments
                    appointments={data.appointments || []}
                    timezone={data.tz_short_form}
                  />
                </div>
              }
              title={I18n.t("appointmentForm.preference_title")}
              // getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <span>
                <u className="appointmentText">
                  <span
                    size="small"
                    type="primary"
                    className={
                      data.appointments.filter(
                        (appointment) => appointment.confirmed
                      ).length > 0
                        ? "confirmedBadge appointmentTypeBox"
                        : "scheduledBadge appointmentTypeBox"
                    }
                  />
                  &nbsp;
                  {getAppointmentTime(
                    currentAppointment,
                    props.organizationSettings
                  )}
                </u>
              </span>
            </Popover>
            )}
            </Fragment>
          );         
        },
        width: 185,
      },
      {
        key: "consignee",
        title: <EndUserTitle />,
        dataIndex: "consignee",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        ellipsis: true,
        render: (data, record) => (
          <div>
            <Popover
              title={<EndUserTitle stringToAppend={I18n.t("general.details")} />}
              placement="topLeft"
              content={
                <ConsigneeDetails
                  record={record}
                  key={`consigneeDetails${record.id}`}
                />
              }
              // getPopupContainer={(triggerNode) => triggerNode}
            >
              {isEmpty(record.customer_first_name)
                ? "N/A"
                : `${record.customer_first_name} ${
                    !isEmpty(record.customer_last_name)
                      ? record.customer_last_name
                      : ""
                  }`}
            </Popover>
          </div>
        ),
        // width: 200,
        // align: "left",
      },
      {
        key: "city",
        title: "Origin -> Destination",
        dataIndex: "warehouse_address",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, data) => (
          <div style={{ display: "flex" }}>
            <div>{this.setAddress(data, "origin")}</div>
            <span>&nbsp;{" - "}&nbsp;</span>
            <div>{this.setAddress(data, "destination")}</div>
          </div>
        ),
        // width: 200,
        // align: "center",
      },
      {
        key: "zipcode",
        title: "Zipcode",
        dataIndex: "customer_address",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, data) => (
          <div>
            <ValidateLocationErr
              record={{
                ...data,
                zipcode: data.customer_address.zipcode,
                location_id: data.cs_location_id
              }}
              displayKey={"zipcode"}
              errorKey={"location_partial_match"}
              errorValue={true}
              refreshCallback={this.props.resolvedAddressCallback}
              loadMaps={true}
            />
          </div>
        ),
        width: 90,
        align: "left",
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
           const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {!props.isShipper && hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  <Tooltip placement="topLeft" title={record.account_name} >
                  {!isEmpty(data) ? data : record.account_code}
                  </Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
         width: 170,
        align: "left",
     
      },

      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
           const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {!props.isShipper && hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        //  width: 115,
        align: "left",
        minWidth: 100
      },
    ];
    if (props.currentFilter !== "REJECTED") {
      this.columns.push({
        key: "created_at",
        title: I18n.t("order.age"),
        dataIndex: "created_at",
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        sortDirections: ["ascend", "descend", "ascend"],
        align: "right",
        render: (created_at, data) => (
          <div className="paddingRight25">
            <Tooltip
              overlayStyle={{ minWidth: 150, maxWidth: "300px" }}
              title={
                data.wh_timezone ? <span>
                  Received on{" "}
                  {moment(created_at).tz(data.wh_timezone).format(AppConfig.dateTimeFormat)}{" "}
                  {moment.tz(data.wh_timezone).format("z")}
                </span> : undefined
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <span>{moment().diff(moment(created_at), "days")}</span>
            </Tooltip>
          </div>
        ),
        width: 100,
        align: "center",
      });
    }
    //if (props.showWeight === true) {
      this.columns.push(
        {
          key: "items_count",
          title: `${I18n.t("order.qty")}`,
          dataIndex: "quantity",
          sorter: (a, b) => compareNumber(a.quantity, b.quantity),
          align: "center",
        },
        // columns.push({
        //   key: 'weight',
        //   title: I18n.t('order.weight'),
        //   dataIndex: 'weight',
        //   render: (data) => formatWeight(data),
        //   minWidth: 80,
        //   sorter: true,
        //   sortDirections: ['ascend', 'descend', 'ascend'],
        // })
        {
          key: "weight",
          title: (
            <Fragment>
              <Row>
                <Col className="alignCenter">
                  {I18n.t("order.weight_short_form")}
                </Col>
                <Col className="alignCenter"> ({AppConfig.weight_unit})</Col>
              </Row>
            </Fragment>
          ),
          dataIndex: "weight",
          render: (data) => formatWeight(data),
          // sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          sorter: (a, b) => compareNumber(a.weight, b.weight),
          align: "right",
          minWidth: 80,
        }
      );
    //}
    if (
      props.currentFilter === "ASSIGNED" ||
      props.currentFilter === "DISPATCHED" ||
      props.currentFilter === "COMPLETED" || props.currentFilter === ""
      ) {
      this.columns.splice(2, 0, {
        key: "driver",
        title: I18n.t("general.Driver"),
        // render: (data) => <div>{data.driver_name}</div>,
        render: (data, record) => retrieveOrderValue(record,'driver_name',false),
        // width: 60,
      });
    }
    this.columns.push({
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "los_name",
      // sorter: (a, b) => compareString(a.levelOfService, b.levelOfService),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 140,
      align: "left",
    });
    this.columns.push({
      key: "zone_name",
      title: "Zones",
      dataIndex: "zoneName",
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => compareString(a.zoneName, b.zoneName),
      render: (text, data) => (text ? <div className="minWidth">{this.setZones(data)}</div> : ''),
      width: 100,
      ellipsis: true,
    });
    if (this.props.screen_from === "orders" || this.props.screen_from === "dispatches" || this.props.screen_from === "pendingOrders") {
      this.columns.push({
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
            <OrderFieldDisplay
              data={record}
              toDisplay="hawb"
              showTriggerEdit={props.showTriggerEdit}
              editClick={() => this.props.handleEditOrder(record.id)}
              refreshCallback={this.props.refreshCallback}
              showEditIcon={
                !["REJECTED"].includes(record.status)
                  ? true
                  : false
              }
              showPopupContainer={true}
              charsToLimit={null}
            />
        ),
        width: 115,
      });
      this.columns.push({
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
            <OrderFieldDisplay
              data={record}
              toDisplay="mawb"
              showTriggerEdit={props.showTriggerEdit}
              editClick={() => this.props.handleEditOrder(record.id)}
              refreshCallback={this.props.refreshCallback}
              showEditIcon={
                !["REJECTED"].includes(record.status)
                  ? true
                  : false
              }
              showPopupContainer={true}
              charsToLimit={null}
            />
        ),
        width: 115,
      });
      this.columns.push({
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        width: 115,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1" showPopupContainer={true}  charsToLimit={null}/>
        ),
      });
      this.columns.push({
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        width: 115,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2" showPopupContainer={true}  charsToLimit={null}/>
        ),
      });
      this.columns.push({
        key: "vehicle_type",
        title : I18n.t("order.vehicle_type"),
        dataIndex: "vehicle_type",
        render: (data,record) => (
          <div style={{ display: "flex" }}>
            <div>{record.vehicle_type|| ''}</div>
          </div>
        ),

        width: 130,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        
      });



      // this.columns.push({
      //   key: "reference_1_type",
      //   title: `${I18n.t("order.reference_title")} 1`,
      //   dataIndex: "reference_1_type",
      //   width: 100,
      //   render: (text, data) => <div>{ data.reference_1_type }</div>,
      // });
      // this.columns.push({
      //   key: "reference_2_type",
      //   title: `${I18n.t("order.reference_title")} 2`,
      //   dataIndex: "reference_2_type",
      //   width: 100,
      //   render: (text, data) => <div>{ data.reference_2_type }</div>,
      // });
    }
    if (props.currentFilter === "EXCEPTION") {
      this.columns.push({
        key: "EXCEPTION",
        title: I18n.t("general.exception_reason"),
        dataIndex: "exception_message",
        render: (text) => (
          <div>
            {!isEmpty(text) && (
              <Popover
                title={I18n.t("general.reason")}
                content={<div>{text}</div>}
                placement="topLeft"
                overlayStyle={{ width: 350 }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <span className="textRed">{ellipseText(text, 45)}</span>
              </Popover>
            )}
          </div>
        ),
      });
    } if (props.currentFilter === "ON_HOLD") {
      this.columns.push({
        key: "ONHOLD", 
        title: I18n.t("general.onhold_reason"),
        dataIndex: "on_hold_reason",
        render: (text) => (
          <div>
            { !isEmpty(text) && (
              <Popover
                title={ I18n.t("general.onhold_reason") }
                content={ <div>{ text }</div> }
                placement="topLeft"
                overlayStyle={ { width: 350 } }
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
              <span className="textRed">{ ellipseText(text, 45) }</span> 
              </Popover>
             ) } 
          </div>
        ),
      });
    } else if (props.currentFilter === "REJECTED") {
      this.columns.push({
        key: "REJECTED",
        title: I18n.t("general.reason"),
        dataIndex: "rejection_message",
        render: (text) => (
          <div>
            {!isEmpty(text) && (
              <Popover
                title={I18n.t("general.reason")}
                content={<div>{text}</div>}
                placement="topLeft"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {/* <Icon type="warning" style={{ color: "red" }} />
                &nbsp;&nbsp;&nbsp; */}
                <span className="textRed">{ellipseText(text, 45)}</span>
              </Popover>
            )}
          </div>
        ),
      });
    } else {
    }

    // {
    //   key: "quote",
    //   title: I18n.t('order.quote'),
    //   dataIndex: "quotation_amount",
    //   render: (text, data) => <div>
    //   {data.status !== "COMPLETED" ? (<Fragment>
    //           <Tooltip title={I18n.t('order.genetare_quote')}>
    //             {!isEmpty(text) ? <span className="anchor_cursor" onClick={() => this.props.generateInvoice(data.id, "true")}> {checkNegitive(text)}</span> :
    //             <Icon
    //               type="dollar"
    //               onClick={() => this.props.generateInvoice(data.id, "true")}
    //             />}
    //           </Tooltip>
    //           &nbsp;&nbsp;&nbsp;
    //         </Fragment>) :
    //         <span> {checkNegitive(text)}</span>
    //         }
    //   </div>,
    // },
    if (props.currentFilter === "" || props.screen_from === "dispatches") {
      this.columns.push({
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data,record) => retrieveOrderValue(record, 'status', true),
        width: hasLocationRecord ? 160 : 75,
    });
    }
    if (props.showActions !== false) {
      this.columns.push({
        key: "items",
        fixed: "right",
        render: (data) => {
          const title = `#${data.customer_order_number}`;
          const hasRoutesAccess = checkAccessExistance('DISPATCH');
          return (
            <div className={(this.props.currentFilter === "" ||
             this.props.currentFilter === "COMPLETED" ||
              this.props.currentFilter === "EXCEPTION") 
            ? "alignLeft table-action-btns":"alignCenter table-action-btns"
            }>
              
              {checkServiceExistance("COSU") && !['PENDING', 'REJECTED'].includes(data.status) && (
                <Fragment>
                   <Tooltip
                  title={I18n.t("tooltip.change_status")}
                  placement="left"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  overlayStyle={{ marginRight: 30 }}
                >
                  <SettingIcon  handleClick={() => this.props.statusChange(data.id)}/>
                  &nbsp;&nbsp;&nbsp;
                  </Tooltip>
                </Fragment>
              )}

              {![
                "INTRANSIT",
                "PICKEDUP",
                "CLOSED",
                "ARCHIVED",
                "PENDING",
                "REJECTED",
              ].includes(data.status) && checkServiceExistance("SCA") ? (
                <Fragment>
                  <Tooltip
                  title={I18n.t("tooltip.edit")}
                  placement="left"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  // overlayStyle={{ marginRight: 250 }}
                >
                   {/* <EditOrder
                    order={data}
                    showIcon={true}
                    onCancel={this.props.onCancel}
                    handleSuccess={this.props.handleSuccess}
                    isNew={false}
                    /> */}
                  <EditIcon handleClick={() => this.props.handleEditOrder(data.id)}/>
                  &nbsp;&nbsp;&nbsp;
                  </Tooltip>
                </Fragment>
              ) : (
                ""
              )}
              {
                // ['COMPLETED', 'INTRANSIT', 'PICKEDUP', 'CLOSED', 'ARCHIVED', 'EXCEPTION', 'PREPARE', 'DISPATCHED', 'ASSIGNED'].includes(data.status)
                false && checkServiceExistance("COD") && (
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t("messages.delete_confirm")}
                    onConfirm={() => this.props.deleteClick(data.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon/>
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm>
                )
              }
              {checkServiceExistance("OBLR") && (
                <Tooltip
                  title={I18n.t("BillOfRating.bill_of_rating_title")}
                  placement="left"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  overlayStyle={{ marginRight: 30 }}
                >
                  <Icon
                    type="file-done"
                    className="billOfLadingIcon"
                    onClick={ () => this.props.handleBillOfRating(data.id, data.customer_order_number) }
                  />
                </Tooltip>
              )}
                &nbsp;&nbsp;&nbsp;
              {this.props.screen_from !== "routes" &&
                data.nav_route_status &&
                // data.nav_route_status !== "ALLOCATED" &&
                data.nav_route_name && (
                  <Tooltip
                    title={
                      data.nav_route_status
                        ? `${data.nav_route_status} - ${data.nav_route_name}`
                        : I18n.t("menu.routes")
                    }
                    placement="left"
                    overlayStyle={{
                      width: 250,
                      maxWidth: "400px",
                      marginRight: "150px",
                    }}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    mouseEnterDelay={AppConfig.tooltipDelay}
                  >
                    <BranchesIcon 
                      handleClick={() =>
                        hasRoutesAccess ? this.props.navigateToRoute(
                        data.nav_route_id,
                        data.nav_route_status,
                        data.scheduled_start_datetime_with_tz
                      ) : null}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </Tooltip>
                )}
              
              {data.status === "COMPLETED" && (
                <Fragment>
                  {checkServiceExistance("COB") && (
                    <Tooltip
                      title="Starting billing process"
                      placement="left"
                      overlayStyle={{
                        width: 180,
                        maxWidth: "300px",
                        marginRight: "100px",
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      mouseEnterDelay={AppConfig.tooltipDelay}
                    >
                      <Icon
                        type="file-pdf"
                        style={{color:"#7c1034"}} // fa8c16
                        onClick={() =>
                          this.props.generateInvoice(data.id, "false")
                        }
                      />
                      &nbsp;&nbsp;&nbsp;
                    </Tooltip>
                  )}

                  <Tooltip
                    title={I18n.t("survey.view_submitted")}
                    placement="left"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    mouseEnterDelay={AppConfig.tooltipDelay}
                    overlayStyle={{
                      width: 200,
                      maxWidth: "300px",
                      marginRight: "100px",
                    }}
                  >
                    <FormIcon handleClick={() => this.props.handleSurveyDetails(data.id)}/>
                    &nbsp;&nbsp;&nbsp;
                  </Tooltip>
                </Fragment>
              )}
              {false && data.status === "EXCEPTION" && (
                <Tooltip title="Move to Verified">
                  <Icon
                    type="rollback"
                    onClick={() => this.props.moveToPending(data.id)}
                  />{" "}
                </Tooltip>
              )}
            </div>
          );
        },
        align: "center",
        width: 120,
      });
    } else if(props.isShipper){
      this.columns.push({
        key: "view",
        title: I18n.t("general.view"),
        dataIndex: "customer_order_number",
        width: 50,
        align: 'center',
        fixed: "right",
        render: (data, record) =>(
          <CustomerOrderDetails
            order_no={data}
            order={{
              id: record.id,
              customer_order_number: data,
            }}
          showEdit={false}
          showIcon={true}
        />
        )
      })
    }else if (props.showActionExceedField === true) {
      this.columns.push({
        key: 'items',
        width: 40,
        fixed:"right",
        render: (data) => {
          const { account_limit_exceeded } = data;
          return (
            <Row type="flex" gutter={4} align="middle" justify="end">
              {account_limit_exceeded && (
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
              )}
            </Row>
          );
        },
      });
    }
   const isOrdersPage = props.screen_from === "orders";
    const isPendingOrderPage = props.screen_from === 'pendingOrders';
    const isAddStopPage = props.screen_from === 'dispatches';
    if (!_.isEmpty(props.displayConfiguration) && isOrdersPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const order_list_view_columns = userConfiguration.order_list_view_columns || orgConfiguration.order_list_view_columns;
      const columns = [];
      if (
        typeof order_list_view_columns !== undefined &&
        Array.isArray(order_list_view_columns)
      ) {
        order_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.filter((col) => ["items", "view"].includes(col.key));
        if (actions.length > 0) {
          columns.push(...actions);
        }
        this.columns = columns;
      } else {
      }
    }
    if (!_.isEmpty(props.displayConfiguration) && isPendingOrderPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const pendingOrders_list_view_columns = userConfiguration.pendingOrders_list_view_columns || orgConfiguration.pendingOrders_list_view_columns;
      const columns = [];
      if (
        typeof pendingOrders_list_view_columns !== undefined &&
        Array.isArray(pendingOrders_list_view_columns)
      ) {
        pendingOrders_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "items");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }if (!_.isEmpty(props.displayConfiguration) && isAddStopPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const dispatches_unallocated_list = userConfiguration.dispatches_unallocated_list || orgConfiguration.dispatches_unallocated_list;
      const columns = [];
      if (
        typeof dispatches_unallocated_list !== undefined &&
        Array.isArray(dispatches_unallocated_list)
      ) {
        dispatches_unallocated_list.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "items");
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  };

  setZones = (data) => (
    <Popover
      title="Zones List"
      placement="topLeft"
      content={
        <div>
          {data.order_zone_name && data.order_zone_name.length > 0
            ? `${data.order_zone_name.join(", ")}`
            : "NA"}
        </div>
      }
      overlayStyle={{ width: 250 }}
      // getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      {data.order_zone_name && data.order_zone_name.length > 0
        ? `${
            data.order_zone_name.length > 1
              ? `${data.order_zone_name[0]} (+${
                  data.order_zone_name.length - 1
                })`
              : data.order_zone_name[0]
          }`
        : "NA"}
    </Popover>
  );

  setAddress = (data, type) => {
    const sourcePoint =
      data?.origin && Object.keys(data.origin).length !== 0
        ? data.origin
        : data.warehouse_address;
    const destinationPoint =
      data?.destination && Object.keys(data.destination).length !== 0
        ? data.destination
        : data.customer_address;
    const pickUpAddress = (
      <Popover
        title={
          data?.origin?.city
            ? I18n.t("general.origin_location")
            : I18n.t("general.warehouse_address")
        }
        content={<AddressInfo address={sourcePoint} />}
        overlayStyle={{ width: 200 }}
        // getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {
          // data.warehouse_address && data.warehouse_address.city ? `${data.warehouse_address.city} ( ${data.warehouse_address.state} )` : 'NA'
          sourcePoint && sourcePoint.city
            ? `${
                sourcePoint.location_code
                  ? sourcePoint.location_code
                  : sourcePoint.city
              }`
            : "NA"
        }
      </Popover>
    );

    const dropAddress = (
      <Popover
        title={
          data?.destination?.city
            ? I18n.t("general.destination_location")
            : I18n.t("general.customer_address")
        }
        content={ <AddressInfo address={destinationPoint} /> }
        overlayStyle={{ width: 200 }}
        // getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {
          // data.customer_address && data.customer_address.city ? `${data.customer_address.city} ( ${data.customer_address.state} )` : 'NA'
          destinationPoint && destinationPoint.city
          ? `${
              destinationPoint.location_code
                ? destinationPoint.location_code
                : destinationPoint.city
            }`
            : "NA"
        }
      </Popover>
    );
    if (type === "origin") {
      if (["R", "TR"].includes(data.type_of_order)) {
        return dropAddress;
      }
      return pickUpAddress;
    }
    if (["R", "TR"].includes(data.type_of_order)) {
      return pickUpAddress;
    }
    return dropAddress;
  };

  // componentWillReceiveProps(nextProps) {
  //   this.columns = [];
  //   this.setColumns(nextProps);
  // }
}

OrdersList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
  refreshCallback: PropTypes.func,
};

OrdersList.defaultProps = {
  screen_from: "",
  showTriggerEdit: false,
  resolvedAddressCallback: () => {},
  refreshCallback: () => {},
};
export default OrdersList;
