import React, { Component, useContext } from "react";
import {
  Col,
  Row,
  Icon,
  Tooltip,
  Skeleton,
  Button,
} from "../../common/UIComponents";
import DetailsConfig from "../Tasks/DetailsConfig";
import EditOrderForm from "./EditOrderForm";
import whiteArrow from "../../../assets/images/WhiteArrow.png";
import {alertMessage, isEmpty } from "../../common/Common";
import { fetchOrderDetails } from "../../api/OrdersApi";
import { withRouter } from "react-router-dom";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import I18n from "../../common/I18n";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
class OrderConfigurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: props.match.params.orderId || "",
      actionType: props.match.params.actionType || "",
      currentOrder: {},
      isNew: props.match.params.orderId ? false : true,
      orderNos: [],
    };
  }

  componentDidMount = () => {
    if (!isEmpty(this.state.orderId)) {
      this.getOrderDetails();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.actionType !== this.props.match.params.actionType
    ) {
      this.setState({ actionType: this.props.match.params.actionType });
    }
    if(prevProps.match.params.orderId !== this.props.match.params.orderId){
      this.setState({ orderId: this.props.match.params.orderId },
        () => {
          this.getOrderDetails();
        }
        );
    }
  }

  getOrderDetails = () => {
    const { orderId } = this.state;
    const id = orderId;
    fetchOrderDetails(id, "DISPATCHER").then((result) => {
    // Check if the request was successful.
    if (!result.success) {
      // Show an error message.
      renderAlertMessage(result.errors)
    }else{
      const orders = result.orders || [];
      const orderNumbers = orders.map((order) => order.customer_order_number);
      const currentOrderInfo = _.find(orders, { id });
      this.setState({
        orderNos: orderNumbers,
        currentOrder: Object.assign(
          {},
          this.state.currentOrder,
          currentOrderInfo
        ),
      });
    } 
    });
  };

  gotoOrders = () => {
    this.props.history.push("/orders");
  };

  goBack = () => {
    const { filterPlaceHolder } = this.props.orderConfigContext;
    const rootPath = localStorage.getItem("lastPath");
    this.props.history.push({
      pathname: rootPath ? rootPath : "/orders",
      filterPlaceHolder: filterPlaceHolder,
      searchType: "byFilter",
      isFromIndScreen: true,
    });
    localStorage.removeItem("lastPath");
  };

  handleSaveSuccess = (message, reflectDetails = false, id = "") => {
    alertMessage(message);
    if (!reflectDetails) {
      this.getOrderDetails();
    } else {
      this.props.history.push(`/orders/${id}/edit`);
    }
  };

  navigateToEditScreen = (id) => {
    this.props.history.push(`/orders/${id}/edit`);
  };

  navigateToDetailScreen = (id) => {
    this.props.history.push(`/orders/${id}/details`);
  };

  _renderHeader = () => {
    const { userContext } = this.props.appContexts;
    const { currentRole } = userContext;
    const isShipper = currentRole === "shipper";
    const { currentOrder, isEditOrder, actionType, orderId, orderNos } =
      this.state;
    const order_nos = orderNos.length > 0 ? orderNos.join(", ") : "";
    return (
      <Row className="page-header">
        <Col md={12}>
          <div>
            <span>
              <img
                src={whiteArrow}
                alt="whitearrow"
                style={{
                  height: 20,
                  width: 30,
                  cursor: "pointer",
                  marginTop: -2,
                }}
                onClick={() => {
                  this.props.history.push("/orders");
                }}
              />{" "}
              &nbsp;
              <span
                className="breadCrumbLink"
                onClick={() => this.gotoOrders()}
              >
                {I18n.t("general.order")}
              </span>
            </span>
            &nbsp; /&nbsp;
            {isEmpty(actionType) ? (
              <span>{I18n.t("order.create")}</span>
            ) : actionType === "details" ? (
              <span>{`${!isEmpty(order_nos) ? `(${order_nos})` : ""} ${I18n.t(
                "general.details"
              )}`}</span>
            ) : (
              <span>
                {`Edit ${I18n.t("order.details")} ${
                  !isEmpty(order_nos) ? `(${order_nos})` : ""
                }`}
              </span>
            )}
          </div>
        </Col>
        { checkServiceExistance('SCA') && actionType === "details" && !_.isEmpty(currentOrder) && !isShipper && (
          <Col xs={12} className="page-actions">
            <Button
              type="primary"
              onClick={() => {this.navigateToEditScreen(orderId),localStorage.setItem("ordrs-beta",true)}}
              icon="edit"
              size="small"
            >
              {I18n.t("general.edit")}
            </Button>
          </Col>
        )}
        { actionType === "edit" && !_.isEmpty(currentOrder) && (
          <Col xs={12} className="page-actions">
            <Icon
              type="close"
              onClick={() => this.navigateToDetailScreen(orderId)}
              style={{ display: "flex", justifyContent: "right" }}
            />
          </Col>
        )}
      </Row>
    );
  };

  _renderEditForm = () => {
    const { currentOrder, showEditIcon, isDetailScreen, orderId, isNew } =
      this.state;
    return (
      <div>
        {(!isNew && currentOrder?.id) || isNew ? (
          <EditOrderForm
            showHeader={false}
            customerDetails={currentOrder}
            customerOrderId={orderId}
            // templateId={currentOrder.template_id}
            preferences={[]}
            onCancel={() => this.goBack()}
            handleSuccess={this.handleSaveSuccess}
            isNew={isNew}
            fromIndividualScreen={true}
          />
        ) : (
          <Skeleton />
        )}
      </div>
    );
  };
  _renderDetailForm = () => {
    const { currentOrder, showEditIcon, isDetailScreen, editClick, orderId } =
      this.state;
    return (
      <div className="order_details">
        <DetailsConfig
          order_id={orderId}
          key={orderId}
          editClick={() => this.navigateToEditScreen(orderId)}
          closable={false}
          // onClose={this.onClose}
          showEdit={false}
          // tab={tab}
          // updateParentState={this.updateParentState}
          // showCloneButton={this.props.showCloneButton}
          // handleOrderclonedStatus={this.handleOrderclonedStatus}
          fromIndividualScreen={true}
        />
      </div>
    );
  };

  render() {
    const { actionType } = this.state;
    return (
      <div className="content-outer">
        <div className="content indvOrderScreen">
          <this._renderHeader />
          {actionType === "details" ? (
            <this._renderDetailForm />
          ) : (
            <this._renderEditForm />
          )}
        </div>
      </div>
    );
  }
}

export const OrderConfig = withRouter((props) => {
  const orderConfigContextData = useContext(OrderConfigContext);

  return (
    <OrderConfigurations
      orderConfigContext={orderConfigContextData}
      {...props}
    />
  );
});

export default OrderConfig;
