import React, { Fragment } from "react";
import _ from "lodash";
import {  isEmpty } from "../../common/Common";
import {
  Row,
  Col,
  Checkbox,
  Icon,
  Radio,
} from "../../common/UIComponents";

const RadioGroup = Radio.Group;

const QuoteAccessorialRowList = (props) => {

  const processRow = (record, isStandardCode = false) => {
    const orderAccObject = props.orderAccessorials.find(
      (acc) => acc.account_accessorial_id === record.id
    );
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === "los";
    // props.orderAccessorials.map(accessorial => accessorial.account_accessorial_id);
    const isReadOnly = props.isReadOnly;
    let orderComponentParams = [];
    if (!isEmpty(orderAccObject) && !isEmpty(orderAccObject.component_params)) {
      orderComponentParams = orderAccObject.component_params;
    }
    const componentDefinitions =
      !isEmpty(record.componentDefs) &&
      _.isArray(record.componentDefs) &&
      record.componentDefs.length > 0
        ? record.componentDefs.filter((component) =>
            props.displayComponents.includes(component.code)
          )
        : [];
    return (
      
        <Col xs={6} className="fontSize12">
          {!props.isReadOnly ? (
            
              <Checkbox
                checked={!isEmpty(orderAccObject)}
                onChange={
                  isEmpty(record.standard_code)
                    ? (e) =>
                        props.handleAddOrderCompAccessorial(
                          record.id,
                          record.accessorial_code,
                          e.target.checked,
                          record.standard_code === null
                        )
                    : (e) =>
                        props.handleCheckStandardAccessorial(
                          record.id,
                          record.accessorial_code,
                          e.target.checked,
                          record.standard_code === null
                        )
                }
                disabled={isLos}
              >
                {record.accessorial_name}
              </Checkbox>
            
          ) : (
            <Fragment>
              {record.accessorial_type === "los" ||
              (!isEmpty(orderAccObject) &&
                orderAccObject.is_completed === true) ? (
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  className="textGreen"
                />
              ) : (
                <Icon type="check-circle" className="textGrey fontSize14" />
              )}
             <span className="textBold fontSize14"> &nbsp;&nbsp; {record.accessorial_name} {!isStandardCode && (record.accessorial_code)}</span>
            </Fragment>
          )}
        </Col>
    );
  };
  const data = props.data || [];
  return (
    <Fragment>
       <Row>
        {data.map((record) => {
          const isReadOnly = props.isReadOnly;
          return (
            <Fragment>
            
              {processRow(record, false)}
              
            </Fragment>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default QuoteAccessorialRowList;
