import React from "react";
import BaseList from "../BaseList";
import {
  compareString,
  isEmpty,
  ellipseText,
  checkNegitive,
} from "../../common/Common";
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Popover, Tooltip } from "../../common/UIComponents";
import AddressInfo from "../common/AddressInfo";
import {
  checkServiceExistance,
  formatByTimeConfig,
} from "../../helpers/common";
import SettingIcon from "../common/SettingIcon";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { momentTime } from "../../helpers/date_functions";
import AppConfig from "../../config/AppConfig";
import CopyableContent from "../common/CopyableContent";
import EyeIcon from "../common/EyeIcon";

class PaymentsList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    const columns = [
      {
        key: "check_date",
        title: I18n.t("batch_payments.check_date"),
        dataIndex: "check_date",
        render: (text, data) =>
          text ? momentTime(AppConfig.dateFormat, text) : "",
        // sorter: (a, b) => compareString(a.name, b.name),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "check_number",
        title: I18n.t("batch_payments.check_number"),
        dataIndex: "check_number",
        render: (text) => <CopyableContent text={text} showTooltip={true} />
      },
      {
        key: "account",
        title: I18n.t("general.account"),
        dataIndex: "account_name",
      },
      {
        key: "check_total_amount",
        title: I18n.t("batch_payments.check_total_amount"),
        dataIndex: "check_total_amount",
        render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
        align: 'right',
      },
      {
        key: "applied_total",
        title: I18n.t("batch_payments.applied_total"),
        dataIndex: "applied_amount",
        render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
        align: 'right',
      },
      {
        key: "unapplied_total",
        title: I18n.t("batch_payments.unapplied_total"),
        dataIndex: "unapplied_amount",
        render: (text, data) => <span className='textBold'>{checkNegitive(text)}</span>,
        align: 'right',
       },
      {
        key: "memo",
        title: I18n.t("batch_payments.memo"),
        dataIndex: "memo",
        render: (text, data) => (text ?  <div className='paddingLeft5'>{text}</div> : ""),
        ellipsis: true,
      },
      {
        key: "created_by",
        title: I18n.t("general.created_by"),
        dataIndex: "created_by",
      },
      {
        key: "actions",
        title: "",
        dataIndex: "id",
        render: (id, data) => (
          <div className="line-actions" onClick={(e) => e.preventDefault()}>
            {checkServiceExistance("ARBAE") && (
              <Tooltip title={props.showActions ? I18n.t("general.manage") : I18n.t("general.view")}>
                {
                  props.showActions ? 
                 <Icon
                  type="file-text"
                  theme="twoTone"
                  twoToneColor="#1890ff"
                  onClick={() => this.props.viewClick(data)}
                />
                :
                <EyeIcon handleClick={() => this.props.viewClick(data)} />
                }
              </Tooltip>
            )}
            {props.showActions && checkServiceExistance("DAR") && (
              <Tooltip title={I18n.t("general.delete")}>
                <Popconfirm
                  placement="left"
                  title={I18n.t("messages.delete_confirm")}
                  onConfirm={(e) => {
                    e.preventDefault()
                    this.props.deleteClick(id)
                    e.stopPropagation()
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon />
                  &nbsp;&nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
            )}

            {/* {checkServiceExistance("ARBAD") && (
               <Tooltip title={I18n.t('general.view')}>
                 <EyeIcon handleClick={() => this.props.handleFormDetails(id)}/>
             </Tooltip>
              
            )} */}
          </div>
        ),
        // width: '140px',
      },
    ];
    this.columns = [...columns];
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };
}

export default PaymentsList;
