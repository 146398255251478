/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import moment from 'moment';
import PropTypes from 'prop-types'
import {
  Icon,
  Col,
  Row,
  Spin,
  Button,
  Popconfirm,
  Form,
  TextArea,
  Select,
  InputNumber,
  Tooltip,
} from '../../../common/UIComponents'
import {
  alertMessage,
  isEmpty,
} from '../../../common/Common'
import userStore from '../../../stores/UserStore'
import { fetchDeductions } from '../../../api/Deductions'

import BaseSelect from '../../common/SelectDropdowns/BaseSelect'
import { driverClaimsApi } from '../../../api/DriverClaims'
import { checkServiceExistance, renderAlertMessage } from '../../../helpers/common'
import I18n from '../../../common/I18n';

const { Option } = Select
class ReportForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      currentRecord: this.props.currentRecord || {},
      errors: [],
      drivers: this.props.drivers || [],
      deductions: this.props.deductions,
      currentRecordKey: this.props.currentRecordId || "",
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    // this.getDeductions()
    if (this.state.currentRecord.id) {
      // this.props.form.setFieldsValue({...this.state.currentRecord});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.currentRecord, prevProps.currentRecord)) {
      this.setState({ currentRecord: this.props.currentRecord, currentRecordKey: this.props.currentRecordId }, 
        () => {
        this.updateForm();
      });
    }
  }

  onClear = () =>{
    const {form} = this.props;
    const { currentRecord, currentRecordKey } = this.state;
    if (currentRecordKey === currentRecord.deduction_master_id) {
      form.setFieldsValue({
        amount: "",
        balance_amount: "",
        remark: "",
      });
      currentRecord.amount= "";
      currentRecord.balance_amount= "";
      currentRecord.remark= "";
    this.setState({ currentRecord });
    }
  }

  updateForm = () => {
    const { form } = this.props;
    const {currentRecord } = this.state;
    if(currentRecord.deduction_master_id === this.state.currentRecordKey){
    form.setFieldsValue({
      amount: currentRecord.amount ? currentRecord.amount : "",
      balance_amount: currentRecord.balance_amount ? currentRecord.balance_amount : "",
      remark: currentRecord.remark ? currentRecord.remark : "",
    });
  }
  }

  getDeductions = () => {
    this.setState({ inProgress: true })
    const orgId = userStore.getStateValue('selectedOrg')
    fetchDeductions(orgId).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false })
        const deductions = result.deductions || []
        this.setState({
          deductions: deductions.filter((rec) => !isEmpty(rec.standard_code)),
        })
      } else {
        this.setState({ inProgress: false, deductions: [] })
      }
    })
  }

  handleFormChange = (element, value) => {
    const currentRecord = Object.assign({}, this.state.currentRecord, {
      [element]: value,
    })
    if (element === 'deduction_master_id') {
      const deductionRec = _.find(this.state.deductions, { id: value })
      if (!isEmpty(deductionRec) && !isEmpty(value)) {
        currentRecord.deduction_name = deductionRec.deduction_name
        currentRecord.gl_code = deductionRec.gl_code
      } else {
        currentRecord.deduction_name = ''
        currentRecord.gl_code = ''
      }
    }
    this.setState({
      currentRecord,
    })
  }

  handleSaveDetails = (fieldsValue) => {
    this.props.setLoading(true);
    const data = {
      ...this.state.currentRecord,
      // from_date: moment(fieldsValue['from_date']).format('YYYY-MM-DD'),
      // to_date: moment(fieldsValue['to_date']).format('YYYY-MM-DD'),
      driver_id: this.props?.currentDriver ? this.props.currentDriver.id : '',
      amount: fieldsValue['amount'],
      deduction_master_id: this.state.currentRecord.deduction_master_id,
      balance_amount: fieldsValue['balance_amount'],
      remark: fieldsValue['remark'],
    }
    driverClaimsApi.manage(data).then((result) => {
      if (result.success) {
        const updatedRecord = { ...result.driver_claim }
        const remarks = result.remarks ? result.remarks : [];
        this.props.setLoading(false);
        this.setState(
          {
            currentRecord: updatedRecord,
          },
          () => {
            this.props.updateRecord(updatedRecord, remarks)
          },
        )
        alertMessage(I18n.t('messages.saved'))
      } else {
        renderAlertMessage(result.errors)
        this.props.setLoading(false);
      }
    })
  }

  getFields() {
    const count = this.state.expand ? 10 : 6
    const { getFieldDecorator } = this.props.form
    const size = 'default'

    const { currentRecord, drivers, deductions } = this.state

    const children = [
      <Col span={4}>
        <Form.Item>
          {currentRecord.deduction_name}
          {/* {getFieldDecorator('deduction_master_id', {
            initialValue: currentRecord.deduction_master_id
              ? currentRecord.deduction_master_id
              : '',
            rules: [
              {
                required: true,
                message: `Select ${I18n.t('deductions.name')}`,
              },
            ],
          })(
            <BaseSelect
              data={deductions}
              // value={
              //   currentRecord.deduction_master_id
              //     ? currentRecord.deduction_master_id
              //     : ''
              // }
              onChange={(e) => {
                this.handleFormChange('deduction_master_id', e)
              }}
              selectKey={'id'}
              selectValue={'id'}
              selectedValue={'deduction_name'}
            />,
          )} */}
        </Form.Item>
      </Col>,
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator('amount', {
            initialValue: currentRecord.amount ? currentRecord.amount : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('deductions.capital_amount')}`,
              },
            ],
          })(
            <InputNumber
              size={size}
              // value={
              //   currentRecord.amount ? currentRecord.amount : ''
              // }
              onChange={(e) => this.handleFormChange('amount', e)}
              precision={this.state.decimalPoints}
              className="width100Per"
              min={0}
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={4}>
        <Form.Item>
          {getFieldDecorator('balance_amount', {
            initialValue: currentRecord.balance_amount
              ? currentRecord.balance_amount
              : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('deductions.balance_amount')}`,
              },
            ],
          })(
            <InputNumber
              size={size}
              // value={
              //   currentRecord.balance_amount ? currentRecord.balance_amount : ''
              // }
              onChange={(e) => this.handleFormChange('balance_amount', e)}
              precision={this.state.decimalPoints}
              className="width100Per"
              min={0}
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={9} style={{ paddingRight: 24 }}>
        <Form.Item>
          {getFieldDecorator('remark', {
            initialValue: currentRecord.remark ? currentRecord.remark : '',
            rules: [
              {
                required: true,
                message: `Enter ${I18n.t('deductions.remarks')}`,
              },
            ],
          })(
            <TextArea
              className="width100Per"
              autoSize={true}
              placeholder={I18n.t('deductions.remarks')}
              onChange={(e) => this.handleFormChange('remark', e.target.value)}
            />,
          )}
        </Form.Item>
      </Col>,
      <Col span={3} className="centerToBlock" style={{ height: 40 }}>
        <Popconfirm
          placement="topRight"
          title={I18n.t('messages.confirm')}
          onConfirm={this.handleSearch}
          okText="Yes"
          cancelText="No"
        >
          {/* <Icon
              type="save"
              theme="twoTone"
              className="textBold fontSize20 cursorPointer"
            /> */}
          <Button type="primary" size="small" icon='save'>
            {currentRecord.isNew === true ? "Save" : "Update"}
          </Button>
        </Popconfirm>
        {checkServiceExistance('DDCR') && (
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.confirm")}
            onConfirm={() => {
              if (currentRecord.isNew === true) {
                this.onClear();
              } else {
                this.props.deleteClick(currentRecord.id, currentRecord.isNew);
              }
            }}
            okText="Yes"
            cancelText="No"
            disabled={
              isEmpty(currentRecord.amount) &&
              isEmpty(currentRecord.balance_amount) &&
              isEmpty(currentRecord.remark)
            }
          >
            {/* <Icon
              type={currentRecord.isNew === true ? 'close' : 'delete'}
              className="cursorPointer custom_delete_icon textBold fontSize20 marginRight10"
            /> */}
            <Button
              type="danger"
              size="small"
              style={{ marginLeft: 5, marginRight: 20 }}
              disabled={
                isEmpty(currentRecord.amount) &&
                isEmpty(currentRecord.balance_amount) &&
                isEmpty(currentRecord.remark)
              }
              icon='delete'
            >
              {currentRecord.isNew === true ? "Clear" : "Delete"}
            </Button>
          </Popconfirm>
        )}
      </Col>,
      // <Col span={8}>
      //   <Form.Item label={I18n.t('deductions.loan_amount')}>
      //     {getFieldDecorator(I18n.t('deductions.loan_amount'), {
      //       rules: [
      //         {
      //           required: true,
      //           message: `Select ${I18n.t('deductions.loan_amount')}`,
      //         },
      //       ],
      //     })(
      //       <InputNumber
      //         size={size}
      //         value={currentRecord.loan_amount ? currentRecord.loan_amount : ''}
      //         onChange={(e) => this.handleFormChange('amount', e)}
      //         precision={2}
      //         addonAfter={<Icon type="percentage" />}
      //         className="width100Per"
      //         min={0}
      //       />,
      //     )}
      //   </Form.Item>
      // </Col>,
    ]
    return children
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.handleSaveDetails(values)
      }
    })
  }

  render() {
    return (
      <div className="content-outer">
        <div>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Form
              className="ant-advanced-search-form"
              onSubmit={this.handleSearch}
            >
              <Row gutter={24}>{this.getFields()}</Row>
              {/* <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={this.props.onClose}
                    type="danger"
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row> */}
            </Form>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
      </div>
    )
  }
}
ReportForm.propTypes = {
  account: PropTypes.shape().isRequired,
}
const WrappedForm = Form.create({ name: 'horizontal_login' })(ReportForm)

export default WrappedForm
