import React, {  Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Col, Row, Icon } from '../../common/UIComponents'
import { isEmpty } from '../../common/Common'
import AddressInfo from './AddressInfo'
import { formatUSPhoneNumber } from '../../helpers/common'

const ShipperConsigneeDetails = ({ record, showAddress }) => {
  return (
    <Fragment>
      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
        <Icon type="right" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.consignee_name)
            ? 'N/A'
            : (record.consignee_name)}
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="phone" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.consignee_phone_number)
            ? 'N/A'
            : formatUSPhoneNumber(record.consignee_phone_number)}
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} sm={6} xs={6}>
          <Icon type="environment" />
        </Col>

        <Col lg={22} md={22} sm={18} xs={18}>
          {isEmpty(record.consignee_address) ? 'N/A' : <AddressInfo address={record.consignee_address}/>}
        </Col>
      </Row>
      
    </Fragment>
  )
}

ShipperConsigneeDetails.propTypes = {
  record: PropTypes.shape(),
  showAddress: PropTypes.bool,
}

ShipperConsigneeDetails.defaultProps = {
  record: {},
  showAddress: false,
}

export default ShipperConsigneeDetails