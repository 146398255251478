import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import {
  Col,
  Row,
  Button,
  Spin,
  Select,
  Input,
} from '../../common/UIComponents'
import { isEmpty, alertMessage } from '../../common/Common'
import I18n from '../../common/I18n'
import {
  fetchAccountWiseDriverRates,
  fetchOrgDriverRates,
} from '../../api/DriverRates'
import userStore from '../../stores/UserStore'
import {
  fetchDriversRateDetails,
  saveDriverRate,
} from '../../api/DriverRatesApi';
import {Icon} from "antd";
import { renderAlertMessage } from '../../helpers/common'
class DriverDRForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      driver: this.props.driver,
      organisationDetails: {
         dr_id: "",
         dr_type: "ORGANIZATION",
         account_id: null
      },
      currentDriverRate: this.props.rateRecord || {},
      isDriverRateDetailsLoading: false,
      accountsDriverRates: [],
      orgDriverRates: [],
      // eligible_driver_percentage: 100,
      transportation_eligible: 100,
      fuelsurcharge_eligible: 100,
      accessorial_eligible: 100,
    }
  }

  componentDidMount() {
    this.getOrgDriverRates(()=>{
      this.getDriverRateConfigurations();
    })
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  getOrgDriverRates = (cb) => {
    const orgId = userStore.getStateValue('selectedOrg')
    fetchOrgDriverRates(orgId).then((result) => {
      if (result.success) {
        const orgDriverRates = result.driver_rates || []
        this.setState(
          {
            orgDriverRates,
          },
          cb,
        )
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false })
      }
    })
  }
  getDriverRatesWithAccounts = () => {
    const org = userStore.getStateValue('selectedOrg')
    const { currentDriverRate } = this.state
    fetchAccountWiseDriverRates(org).then((result) => {
      if (result.success) {
        const accountsDriverRates = result.driver_rates || []
        if (accountsDriverRates.length > 0) {
          const driverRateParams = currentDriverRate.driver_rate_params || []
          accountsDriverRates.forEach((accountsRate, index) => {
            const accountsRateDriverRates = accountsRate.driver_rate || [];
            const defaultRate = _.find(accountsRateDriverRates, { is_default: 'YES' })
            const currentRecord = _.find(driverRateParams, {
              account_id: accountsRate.account_id,
            });
            accountsRate.dr_id = !isEmpty(currentRecord)
              ? currentRecord.dr_id
              : (!isEmpty(defaultRate) ? defaultRate.dr_id : '');
            accountsDriverRates[index] = accountsRate;
          })
        }
        this.setState({
          accountsDriverRates,
          inProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  getAccessorialCode = (accessorial_master_id) => {
    const { accessorials } = this.state
    const accessorial = accessorials.find(
      (accessrl) => accessrl.id === accessorial_master_id,
    )
    if (accessorial) {
      return accessorial.code
    }
    return null
  }

  getDriverRateConfigurations = () => {
    this.setState({ isDriverRateDetailsLoading: true })
    fetchDriversRateDetails(this.state.driver.id).then((result) => {
      if (result.success) {
        const driverRate = result.driver_rate || { driver_rate_params: [] }
        // const eligible_driver_percentage = driverRate.eligible_driver_percentage || 100
        const transportation_eligible = driverRate.transportation_eligible || 100
        const fuelsurcharge_eligible = driverRate.fuelsurcharge_eligible || 100
        const accessorial_eligible = driverRate.accessorial_eligible || 100
        if (isEmpty(driverRate.driver_rate_params)) {
          driverRate.driver_rate_params = []
        }
        this.setState(
          {
            currentDriverRate: driverRate,
            isDriverRateDetailsLoading: false,
           // eligible_driver_percentage: parseInt(driverRate.eligible_driver_percentage),
            transportation_eligible:parseInt(transportation_eligible),
            fuelsurcharge_eligible:parseInt(fuelsurcharge_eligible),
            accessorial_eligible:parseInt(accessorial_eligible)

          },
          () => {
            this.setOrgnizationObject(true)
            this.getDriverRatesWithAccounts()
          },
        )
      } else {
        this.setState({
          currentDriverRate: {},
          isDriverRateDetailsLoading: false,
        })
      }
    })
  }

  saveRate = (data) => {
    const errors = [] // need to do
    if (errors.length === 0) {
      saveDriverRate(data).then((result) => {
        if (result.success) {
          alertMessage('Saved Successfully!')
          const driverRate = result.driver_rate || { driver_rate_params: [] }
          if (isEmpty(driverRate.driver_rate_params)) {
            driverRate.driver_rate_params = []
          }
          this.setState(
            {
              currentDriverRate: driverRate,
              isNew: false,
              transportation_eligible:driverRate.transportation_eligible,
              fuelsurcharge_eligible:driverRate.fuelsurcharge_eligible,
              accessorial_eligible:driverRate.accessorial_eligible

            },
            () => {
              this.setOrgnizationObject(true)
              this.getDriverRatesWithAccounts()
            },
          )
        } else {
          renderAlertMessage(result.errors)
        }
      })
    } else {
      this.setState({
        errors,
      })
    }
  }

  handleInputChange = (key_name, value, id, type) => {
    if (type === 'ACCOUNT') {
      const accountsDriverRates = this.state.accountsDriverRates || []
      const driverRateIndex = _.findIndex(accountsDriverRates, [
        'account_id',
        id,
      ])
      if (driverRateIndex >= 0) {
        const driverRate = accountsDriverRates[driverRateIndex]
        driverRate[key_name] = value
        accountsDriverRates[driverRateIndex] = driverRate
        this.setState({
          accountsDriverRates,
        })
      }
    } else {
      const driverRate = this.state.organisationDetails || {}
      if (driverRate) {
        driverRate[key_name] = value
      }
      this.setState({
        organisationDetails: driverRate,
      })
    }
  }

  setOrgnizationObject = (isInitial = true) => {
    const { currentDriverRate, organisationDetails, orgDriverRates } = this.state
    if (isInitial) {
      const driverRateParams = currentDriverRate.driver_rate_params || []
      const defaultRate = _.find(orgDriverRates, { is_default: 'YES' })
      const orgRateObject = _.find(driverRateParams, {
        dr_type: 'ORGANIZATION',
      })
      if (!isEmpty(orgRateObject)) {
        orgRateObject.dr_type = 'ORGANIZATION'
        this.setState({
          organisationDetails: orgRateObject,
        })
      }else{
        organisationDetails.dr_id = !isEmpty(defaultRate) ? defaultRate.id : '';
        this.setState({
          organisationDetails,
        })
      }
    } else {
      const driverRate = {}
      driverRate.dr_id = this.state.organisationDetails.dr_id
      driverRate.dr_type = 'ORGANIZATION'
      driverRate.account_id = null
      return driverRate
    }
  }

  handleRatesSave = () => {
    const { accountsDriverRates, driver,transportation_eligible ,fuelsurcharge_eligible,accessorial_eligible} = this.state
    const driverRates = []
    const driverRateTransportObject = this.setOrgnizationObject(false)
    driverRates.push(driverRateTransportObject)
    accountsDriverRates.forEach((driverRate) => {
      driverRates.push({
        dr_id: driverRate.dr_id,
        dr_type: 'ACCOUNT',
        account_id: driverRate.account_id,
      })
    })
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      driver_id: driver.id,
      driver_rate_params: driverRates,
      // eligible_driver_percentage,
      transportation_eligible:!isEmpty(transportation_eligible) ? transportation_eligible : 100,
      fuelsurcharge_eligible:!isEmpty(fuelsurcharge_eligible) ? fuelsurcharge_eligible : 100,
      accessorial_eligible:!isEmpty(accessorial_eligible) ? accessorial_eligible : 100,

    }
    this.saveRate(data)
  }

  renderRow =   (type, driverRate) => {
    return (
      <Row>
        <Col md={12} className="textBold paddingTop15 fontSize12">
          {driverRate.account_name} ({driverRate.account_code}) {I18n.t('menu.driver_rates')}
        </Col>
        <Col md={1} className="textBold paddingTop15">:</Col>
        <Col md={11}>
          <Select
            value={driverRate.dr_id}
            showSearch
            style={{ width: '100%', margin: '10px 10px 0px 10px' }}
            filterOption={(input, option) => {
              const children = option.props.children;
              if (children && children.length > 0) {
                if (Array.isArray(children)) {
                  return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                } else {
                  return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
              }
            }}
            className="fontSize12"
            onChange={(e) =>
              this.handleInputChange('dr_id', e, driverRate.account_id, type)
            }
          >
            <Select.Option key={'SEL_ALL'} value={''}>
              -- Select {I18n.t('driver_pay.driver_rate')} -- 
            </Select.Option>
            {driverRate.driver_rate.map((rate) => (
              <Select.Option key={rate.dr_id} value={rate.dr_id}>
                {rate.dr_name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    )
  }
  renderOrgRow = (type, driverRate) => {
    const orgDriverRates = this.state.orgDriverRates || [];
    return (
      <Row>
        <Col md={12}  className="textBold paddingTop15 fontSize13">
         {I18n.t('driver_rates.transportation_pay')}
        </Col>
        <Col lg={8} className="textBold paddingTop15">:
        <Input 
         value={this.state.transportation_eligible}
         onChange={(e) =>
           this.handleOnChange('transportation_eligible',e.target.value)
         }
        style={{width:"60%",marginLeft:"45px"}} 
        addonAfter={<Icon type="percentage"/>}
        > 
        
        </Input>
        </Col>
        <Col md={12}  className="textBold paddingTop15 fontSize13">
         {I18n.t('driver_rates.fuel')}
        </Col>
        <Col lg={8} className="textBold paddingTop15">:
        <Input 
         value={this.state.fuelsurcharge_eligible}
         onChange={(e) =>
           this.handleOnChange('fuelsurcharge_eligible',e.target.value)
         }
        style={{width:"60%",marginLeft:"45px"}} 
        addonAfter={<Icon type="percentage"/>}
        >   
        </Input>
        </Col>
        <Col md={12}  className="textBold paddingTop15 fontSize13"> 
         {I18n.t('driver_rates.accessorials')}
        </Col>
        <Col lg={8} className="textBold paddingTop15">:
        <Input 
         value={this.state.accessorial_eligible}
         onChange={(e) =>
           this.handleOnChange('accessorial_eligible',e.target.value)
         }
        style={{width:"60%",marginLeft:"45px"}} 
        addonAfter={<Icon type="percentage"/>}
        > 
        
        </Input>
        
        {/* <Input
        
          value={this.state.eligible_driver_percentage}
          onChange={(e) =>
            this.handleOnChange('eligible_driver_percentage',e.target.value)
          }
          style={{width:"60%",marginLeft:"45px"}} 
          addonAfter={<Icon type="percentage"/>}
        /> 
        */}
       </Col>



        <Col lg={12} className="textBold paddingTop15 fontSize12">
          {I18n.t('organization.single')} level {I18n.t('menu.driver_rates')}
        </Col>
        <Col md={1} className="textBold paddingTop15">:</Col>
        <Col md={11}>
          <Select
            value={driverRate.dr_id}
            showSearch
            style={{ width: '100%', margin: '10px 10px 0px 10px' }}
            className="fontSize12"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              this.handleInputChange('dr_id', e, driverRate.account_id, type)
            }
            disabled={orgDriverRates.length === 0}
          >
            <Select.Option key={'org_SEL_ALL'} value="">
              { orgDriverRates.length > 0 ? `-- Select ${I18n.t('driver_pay.driver_rate')} --` : I18n.t('driver_pay.not_configured')}
            </Select.Option>
            {orgDriverRates.map((rate) => (
              <Select.Option key={rate.id} value={rate.id}>
                {rate.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    )
  }

  render() {
    const { accounts, organisationDetails } = this.state
    return (
      <React.Fragment>
        <Spin spinning={this.state.isDriverRateDetailsLoading}>
          <div>
            {this.renderOrgRow('ORGANIZATION', organisationDetails)}
            {this.state.accountsDriverRates.map((driverRate) => (
              <Fragment>{this.renderRow('ACCOUNT', driverRate)}</Fragment>
            ))}
          </div>
          <div className="alignCenter marginTop20">
            <Button type="primary" onClick={this.handleRatesSave} icon='save'>
              {I18n.t('general.save')}
            </Button>
          </div>
        </Spin>
      </React.Fragment>
    )
  }
}

export default withRouter(DriverDRForm)
