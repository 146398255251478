//Subscribe to the Action Cable channel and handle incoming alerts in your React component:
// 1. Import the WebSocket connection from cable.js.
// 2. Subscribe to the channel and handle incoming alerts in your component:
import consumer from "./cable";
import {
  Alert,
  notification,
  Icon,
  Result,
  Button,
  Modal,
  Typography,
} from "./common/UIComponents";
import React, { Fragment, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import userStore from "./stores/UserStore";
import { withRouter } from "react-router-dom";
import { isEmpty } from "./common/Common";
import _ from "lodash";
import { WarehouseContext } from "./context/WarehouseContext";
import { UserContext } from "./context/UserContext";
import { momentTime } from "./helpers/date_functions";
import moment from "moment";
import { OrgContext } from "./context/OrgContext";
import AppConfig from "./config/AppConfig";
import { getOrderUpdateMessage } from "./config/ActionCableConfig";
const { Paragraph, Text } = Typography;

const ActionCableComponent = (props) => {
  const [receivedBrodcastMsg, setReceivedBrodcastMsg] = useState({});
  const [receivedSource, setReceivedSource] = useState([]);
  const [notifyModalVisible, setNotifyModalVisible] = useState(false);

  const { currentUser } = props.userContextData;
  const currentOrgId = userStore.getStateValue("selectedOrg");

  const {
    currentDate,
    currentWhId,
    updateOperation,
    warehouseFilter,
    screenFrom,
  } = props;

  const currentWhData =
    warehouseFilter.warehouses.length &&
    warehouseFilter.warehouses.find((wh) => wh.id === currentWhId);

  const currentWhTimeZoneId = currentWhData?.timeZoneId
    ? currentWhData.timeZoneId
    : "";

  const current_date = momentTime("YYYY-MM-DD", currentDate);

  useEffect(() => {
    const channelName = userStore.getStateValue("selectedOrg");
    // Create the Action Cable consumer and subscription
    const subscription = consumer.subscriptions.create(
      { channel: "BroadcastChannel", room: channelName },
      {
        connected() {
          console.log("Connected to Action Cable server");
        },
        disconnected() {
          console.log("Disconnected from Action Cable server");
        },
        received(data) {
          // Update the received message state
          console.log(data);
          validateReceivedMsg(data);
        },
      }
    );

    // Cleanup: Unsubscribe from the channel and disconnect the consumer
    return () => {
      subscription.unsubscribe();
      consumer.disconnect();
    };
  }, [currentDate, currentWhId]);

  const validateReceivedMsg = (msg) => {
    const updatedSourceOrgId = msg.organization_id;
    const updatedSourceUserId = msg.updated_by;

    const isSameOrg = updatedSourceOrgId === currentOrgId;
    const isCurrentUser = updatedSourceUserId === currentUser.id;

    const updatedSource =
      isSameOrg && !isCurrentUser && msg?.properties?.length
        ? msg.is_bulk_operation ||
          (screenFrom === "preplanMng" && msg.source === "PREPLAN")
          ? msg.properties.filter(
              (source) => source.wh_location_id === currentWhId
            )
          : msg.properties.filter(
              (source) =>
                source.wh_location_id === currentWhId &&
                moment(source.start_datetime, "DD/MM/YYYY HH:mm").format(
                  "YYYY-MM-DD"
                ) === current_date
            )
        : [];

    if (updatedSource.length) {
      setReceivedBrodcastMsg(msg);
      setReceivedSource(updatedSource);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(receivedBrodcastMsg)) {
      const { action_to_perform } = receivedBrodcastMsg;
      if (action_to_perform === "BLOCK_REFRESH") {
        setNotifyModalVisible(true);
      } else if (action_to_perform === "ENTITY_REFRESH") {
        renderNotification(receivedBrodcastMsg);
        if (receivedBrodcastMsg?.source_ids?.length) {
          updateOperation(receivedBrodcastMsg, receivedSource);
        }
      }
    }
  }, [receivedSource]);

  const renderNotificationModal = () => {
    const updatedSource = ["ROUTE", "STOP"].includes(receivedBrodcastMsg.source)
      ? "Route(s)"
      : receivedBrodcastMsg.source === "ORDER"
      ? "Order(s)"
      : "";
    return (
      <Modal
        title={`${updatedSource} Alert !`}
        visible={true}
        footer={null}
        // onCancel={() => {
        //   setNotifyModalVisible(false);
        // }}
        closable={false}
        maskClosable={false}
        className="orderAlert"
      >
        <Result
          status="warning"
          title={`${updatedSource} Alert !`}
          subTitle={`One or more ${updatedSource} got updated`}
          className="resultContent"
          extra={[
            <Button
              type="primary"
              key="cancel"
              onClick={() => {
                setNotifyModalVisible(false);
              }}
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              className="buttonGreen"
              key="reload"
              onClick={() => {
                updateOperation(receivedBrodcastMsg, receivedSource);
                setNotifyModalVisible(false);
              }}
            >
              Reload
            </Button>,
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                You can continue loading or cancel by clicking below button
              </Text>
            </Paragraph>
          </div>
        </Result>
      </Modal>
    );
  };

  const renderNotification = (msg) => {
    const updatedItem = receivedSource
      .map((order) =>
        msg.source === "ORDER" ? order.customer_order_number : order.driver_code
      )
      .join(",");

    const properties = msg?.properties?.length ? msg.properties[0] : {};
    const source = ["ROUTE", "STOP"].includes(msg.source)
      ? "ROUTE"
      : msg.source;
    const sourceDetail = ["ROUTE", "STOP"].includes(msg.source)
      ? properties.driver_code
      : msg.source === "ORDER"
      ? properties.customer_order_number
      : msg.source === "PREPLAN"
      ? properties.preplan_name
      : "";
    const messageHeading = `${source} #${sourceDetail} ${msg.action} !`;
    const MsgDescription = getOrderUpdateMessage(msg);

    return (
      <Fragment>
        {notification.open({
          // key: `${organization_id}_key`,
          message: messageHeading,
          description: MsgDescription,
          icon: (
            <Icon
              type="info-circle"
              style={{ color: "#108ee9", fontSize: "25px" }}
              theme="outlined"
            />
          ),
          placement: "bottomRight",
          duration: 10,
        })}
      </Fragment>
    );
  };

  return <Fragment>{notifyModalVisible && renderNotificationModal()}</Fragment>;
};

export const ActionCable = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const userContextData = useContext(UserContext);
  const orgContextData = useContext(OrgContext);

  return orgContextData?.orgSettings?.require_real_time_updates === "true" ? (
    <Fragment>
      <ActionCableComponent
        warehouseFilter={warehouseFilter}
        userContextData={userContextData}
        {...props}
      />
    </Fragment>
  ) : (
    <Fragment />
  );
});

export default ActionCable;

ActionCable.propTypes = {
  updateOperation: PropTypes.func,
  currentWhId: PropTypes.string,
  screenFrom: PropTypes.string,
};

ActionCable.defaultProps = {
  updateOperation: () => {},
  currentWhId: "",
  screenFrom: "",
};
