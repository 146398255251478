import React, { Fragment, useEffect, useState, useContext } from "react";
import I18n from "../../../common/I18n";
import { Row, Col, Form, Input, Button } from "../../../common/UIComponents";
import FormButtons from "../../common/FormButtons";
import _ from "lodash";
import AddressSearch from "../../../stories/components/AddressSearch";
import AddressForm from "../../common/AddressForm";
import { loadGoogleMaps } from "../../../utils/Utils";
import UploadProfile from "../../../common/UploadImage";
import ImageUpload from "../../../common/UploadImage";
import logoPlaceholder from "../../../../assets/images/logo_placeholder.svg";
import AppConfig from "../../../config/AppConfig";
import { doValidate, formatePhoneNo, isEmpty, validateAddressForm } from "../../../common/Common";
import MobileInput from "../../common/MobileInput";

function BillingAccountsForm(props) {
  const { onCancel, showLoading } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [address, setAddress] = useState({});
  const [addressError, setAddressError] = useState('');
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { getFieldDecorator, getFieldValue } = props.form;
  const { form, currentData } = props;
  useEffect(() => {
    if (!_.isEmpty(currentData) && currentData.id) {
      setAddress(currentData?.address || {});
      setImage(currentData?.logo?.url || null);
      form.setFieldsValue({
        name: currentData.name,
        code: currentData.code,
        realm_id: currentData.realm_id,
        address: currentData.address || {},
        email: currentData.email,
        fax_no: currentData.fax_no,
        po_box_no: currentData.po_box_no,
        phone_number: currentData.phone_number
      });
    }
    if (window.google) {
      setIsMapLoaded(true);
    } else {
      loadGoogleMaps(() => {
        setIsMapLoaded(true);
      });
    }
  }, []);

  useEffect(()=> {
    const newAddr = _.pick(address, ["address_line1", "city", "state", "country", "zipcode"]);
    if(_.compact(Object.values(newAddr)).length > 0){
      const addrError = validateAddressForm(newAddr);
      setAddressError(addrError)
    } else {
      setAddressError('')
    }
  }, [address])

  const handleSubmit = (e) => {
    e.preventDefault();
    const { validateFields } = props.form;

    validateFields((err, values) => {
      if (!err && isEmpty(addressError)) {
        const formData = {
          name: values.name,
          code: values.code.toUpperCase(),
          realm_id: values.realm_id,
          address,
          email: values.email,
          fax_no: values.fax_no,
          po_box_no: values.po_box_no,
          phone_number: values.phone_number,
        };
        if(!isEmpty(imageFile)){
          formData.logo = imageFile;
        }else if(currentData?.logo?.url && isEmpty(image)){
          formData.logo = null;
        }
        props.onSuccess(formData);
      }
    });
  };

  const handleOnAddressChange = (element, value, obj) => {
    const { form } = props;
    if (element === "zipcode" && !isEmpty(value)) {
      value = parseInt(value);
    }
    const l_address = address ? { ...address } : {};
    let newAddress = { ...l_address };
    if (obj) {
      newAddress = { ...l_address, ...obj };
    } else if (element) {
      newAddress[element] = value;
    }
    setAddress(newAddress);
    // form.setFieldsValue({
    //   address: newAddress
    // })
  };

  const handleOnChange = (element, value) => {
    const { form } = props;
    form.setFieldsValue({
      [element]: value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col xs={17}>
          <Row type="flex" gutter={16}>
            <Col>
              <Form.Item
                label={I18n.t("general.name")}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please Enter Name",
                      whitespace: true
                    },
                  ],
                })(<Input className="width100Per" />)}
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label={I18n.t("general.code")}
                style={{ marginBottom: 0 }}
                className="ant-form-item-required"
              >
                {getFieldDecorator("code", {
                  rules: [
                    // {
                    //   required: true,
                    //   //message: "Please Enter Code",
                    // },
                    {
                      validator: (_, value) => {
                        if (isEmpty(value)) {
                          return Promise.reject("Please Enter Code");
                        } else if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
                          return Promise.reject("Code is Invalid");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ],
                })(
                  <Input
                    className="width100Per"
                    style={{
                      textTransform: "uppercase",
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={I18n.t("billing_accounts.realm_id")}>
                {getFieldDecorator("realm_id", {
                  rules: [
                    {
                      required: false,
                      message: "Please Enter Realm Id",
                    },
                  ],
                })(<Input className="width100Per" />)}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={I18n.t("general.email")}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: false,
                      message: "Please Enter Email",
                    },
                    {
                      validator: (_, value, callback) => {
                        if (
                          value &&
                          !(AppConfig.regexEmail.test(String(value).trim().toLowerCase()))
                        ) {
                          callback("Enter Valid Email");
                        } else {
                          callback();
                        }
                      },
                    }
                  ],
                })(<Input className="width100Per" />)}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={I18n.t("general.fax_no")}>
                {getFieldDecorator("fax_no", {
                  rules: [
                    {
                      required: false,
                      message: "Please Enter Fax No",
                    },
                    {
                      validator: (_, value, callback) => {
                        if (
                          value &&
                          !(/^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})([\s\-]?)\d{3}([\s\-]?)\d{4}$/.test(value))
                        ) {
                          callback("Enter Valid No");
                        } else {
                          callback();
                        }
                      },
                    }
                  ],
                })(<Input className="width100Per" />)}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={I18n.t("general.po_box_no")}>
                {getFieldDecorator("po_box_no", {
                  rules: [
                    {
                      required: true,
                      message: `Please Enter ${I18n.t("general.po_box_no")}`,
                      whitespace: true
                    },
                  ],
                })(<Input className="width100Per" />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={7}>
          <h4>{I18n.t("general.logo")}</h4>
          <ImageUpload
            imageUrl={image ? image : null}
            setImage={setImage}
            setFormData={(logo) => setImageFile(logo)}
            placeholder={logoPlaceholder}
            imageProps={{
              width: 200,
              height: 90,
            }}
          />
        </Col>
      </Row>
      
      <Row type="flex" gutter={[16]}>
        <Col xs={8}>
          <Form.Item label={I18n.t("general.contact_no")} className= "formMobileInput">
            {getFieldDecorator("phone_number", {
              rules: [
                {
                  required: false,
                  message: "Please Enter Contact No.",
                },
                {
                  validator: (_, value, callback) => {
                    const Phone_no = formatePhoneNo(value);
                    const countryCodes = ["+1", "+91"];
                    const countryCodePattern = new RegExp(`^(${countryCodes.map((code) => `\\${code}`).join("|")})`);
                    const phoneNumberWithoutCountryCode = Phone_no.replace(
                      countryCodePattern,
                      ""
                    );
          
                    if (!isEmpty(phoneNumberWithoutCountryCode) && phoneNumberWithoutCountryCode.length !== 10) {
                      callback(
                        "Please enter a valid phone number."
                      );
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <MobileInput
                handleInputChange={(value) => {
                  handleOnChange("phone_number", value);
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row className="marginTop10" gutter={[16]}>
        <Col xs={18} className="textBold">
          {I18n.t('general.address')}
        </Col>
        <Col xs={6}  className="alignRight">
          {/* <Button size="small" type="link" onClick={()=> setAddress({})} icon="close">
            {I18n.t('general.clear')}
          </Button> */}
        </Col>
        <Col xs={24} >
          {isMapLoaded && (
            <AddressSearch
              onSelect={(address) =>
                handleOnAddressChange("", "", address.l_address)
              }
              
            />
          )}
          {isMapLoaded && (
            <AddressForm
              autoComplete={true}
              includePredefinedStops={false}
              address={address}
              onChange={handleOnAddressChange}
              isAdmin
              disabled={false}
              twoRows={true}
            />
          )}
          {
            addressError && 
            <div className="has-error">
              <span className="ant-form-explain">{addressError}</span>
            </div>
          }
        </Col>
      </Row>

      <Row>
        <Col>
          <Row type="flex" gutter={16} justify="center">
            <Col>
              <Button onClick={onCancel} icon="close">
                {I18n.t("general.cancel")}
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" icon="save" loading={showLoading}>
                {I18n.t("general.save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
const OrgBillingForm = Form.create()(BillingAccountsForm);
export default OrgBillingForm;
