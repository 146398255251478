import React, { Component } from 'react'
import _ from 'lodash'
import {
  Button,
  Col,
  Row,
  Select,
  Input,
  Form,
  Spin,
} from '../common/UIComponents'
import { alertMessage,isEmpty } from '../common/Common'
import I18n from '../common/I18n'
import { fetchOrganizations } from '../api/Organisations'
import { updateLocationData } from '../api/OrdersApi'
import { renderAlertMessage } from '../helpers/common'

class OrderLatLng extends Component {
  constructor(props) {
    super(props)
    this.state = {
      organizations: [],
      latitude: '',
      longitude: '',
      ordernumber: '',
      organization_id: null,
      inProgress: false,
    }
  }

  componentDidMount() {
    this.getOrganizations()
  }

  handleFilterChange = (name, value) => {
    let newValue = value;
    if (value === "") {
      this.setState({ [name]: "" });
      return;
    }
    if (name === "latitude" || name === "longitude") {
      // Validate that the input is a number
      if (isNaN(value)) {
        return;
      }
      // Convert the input to a number and clamp it to a range
      newValue = name === "latitude"
          ? Math.min(Math.max(parseFloat(value), -90), 90)
          : Math.min(Math.max(parseFloat(value), -180), 180);
       }
    this.setState({ [name]: newValue }, () => {
      this.props.form.setFieldsValue({ [name]: newValue });
    });
  };

  getOrganizations = (cb) => {
    this.setState({ inProgress: true })
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const orgRec = _.find(result.organizations.organizations, [
          'code',
          I18n.t('account.netmove_code'),
          // I18n.t("account.netmove_code") || organization_id
        ])
        let orgId = !isEmpty(orgRec) ? orgRec._id : '';
        if(!orgId) {
          orgId =
            result.organizations?.organizations?.length > 0
              ? result.organizations.organizations[0].id
              : ''
        }
        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id: orgId,
          },
          () => {
            // this.getRoutes();
            // this.getUsers(); //related drivers will be loaded
            if (cb) {
              cb()
            }
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleUpdate = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { organization_id, longitude, latitude, ordernumber } = this.state
        const data = {
          customer_order_number: ordernumber,
          organization_id: organization_id,
          longitude: longitude,
          latitude: latitude,
        }
        this.setState({ inProgress: true })
        updateLocationData(data).then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'), 'success')
            this.setState({ inProgress: false })
            // onCancsetAccountSettingel();
          } else {
            if (result.errors) {
              alertMessage(result.errors, 'error', 10)
            }
            this.setState({ inProgress: false })
          }
        })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { ordernumber, organization_id, longitude, latitude } = this.state

    return (
      <Form ref={this.formRef} onSubmit={this.handleUpdate}>
        <div className="content-outer">
          <Spin spinning={this.state.inProgress} delay={100}>
            <div className="content">
              <Row className="page-header">
                <Col xs={12}>{I18n.t('menu.order_Lat_Lon_Setting')}</Col>
              </Row>
              <Row gutter={16}>
                <Col sm={24} xs={24} md={5} lg={5}>
                  <Form.Item label={I18n.t('organization.single')}>
                    {getFieldDecorator('organization_id', {
                      initialValue: organization_id,
                      rules: [
                        {
                          required: true,
                          message: I18n.t('errors.fieldRequired'),
                        },
                      ],
                    })(
                      <Select
                        // value={this.state.organization_id || ""}
                        showSearch
                        onChange={(e) =>
                          this.handleFilterChange('organization_id', e)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}
                      >
                        {/* <Select.Option key="-1" value="null">
                          -- options --
                        </Select.Option> */}
                        {this.state.organizations.map((orgtype) => (
                          <Select.Option key={orgtype.code} value={orgtype._id}>
                            {orgtype.name}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24} md={4} lg={3}>
                  {/* <Form.Item label={I18n.t("general.order_number")} requireColumn={true} require>
                  <Input
                    value={this.state.ordernumber}
                    
                    onChange={(e) =>
                      this.handleFilterChange("ordernumber", e.target.value)
                    }
                  />
                </Form.Item> */}

                  <Form.Item
                    // label="Order Number">
                    label={I18n.t('general.order_number')}
                  >
                    {/* // required={true}
                
                  // validateStatus={this.state.errors?.ordernumber && "error"}
                  // help={this.state.errors?.ordernumber}
                  // rules={[ */}
                    {/* //   { */}
                    {/* //     required: true,
                  //     message: "Please input the order number",
                  //   },
                  // ]} */}
                    {getFieldDecorator('ordernumber', {
                      initialValue: ordernumber,
                      rules: [
                        {
                          required: true,
                          // message: I18n.t("errors.fieldRequired"),
                          message: 'Field Required',
                        },
                      ],
                    })(
                      <Input
                        // placeholder="please place the Order number"
                        // value={this.state.ordernumber}
                        onChange={(e) =>
                          this.handleFilterChange('ordernumber', e.target.value)
                        }
                      />,
                    )}
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24} md={5} lg={3}>
                  <Form.Item label={I18n.t('general.latitude')}>
                    {getFieldDecorator('latitude', {
                      initialValue: latitude,
                      rules: [
                        {
                          required: true,
                          // message: I18n.t("errors.fieldRequired"),
                          message: 'Field Required',
                        },
                      ],
                    })(
                      <Input
                        type="number"
                        // placeholder="please enter latitude"
                        onChange={(e) =>
                          this.handleFilterChange('latitude', e.target.value)
                        }
                      />,
                    )}
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24} md={5} lg={3}>
                  <Form.Item label={I18n.t('general.longitude')}>
                    {getFieldDecorator('longitude', {
                      initialValue: longitude,
                      rules: [
                        {
                          required: true,

                          // message: I18n.t("errors.fieldRequired"),
                          message: 'Field Required',
                        },
                      ],
                    })(
                      <Input
                        type="number"
                        // placeholder="please enter longitude"
                        // value={this.state.longitude}
                        onChange={(e) =>
                          this.handleFilterChange('longitude', e.target.value)
                        }

                        // value={this.state.setting_key}
                        // onChange={(e) =>
                        //   this.handleFilterChange('setting_key', e.target.value)
                        // }
                      />,
                    )}
                  </Form.Item>
                </Col>

                <Col
                  sm={24}
                  xs={24}
                  md={4}
                  lg={3}
                  style={{ paddingTop: '35px' }}
                >
                  <Form.Item>
                    {/* <Button type="primary" onClick={this.handleUpdate}>
                     */}

                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.inProgress}
                      icon="save"
                      // onClick={this.handleSubmit}
                    >
                      {I18n.t('general.save')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="textBold">
                <sup style={{ color: "red" }}>*</sup>Note:{" "}
                <span className="notes_content">
                  Latitude is specified in degrees within the range [-90, 90]
                  and <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Longitude is specified in degrees within the range [-180,
                  180].
                </span>
              </Row>
              

              {/* {this.renderAPI()} */}
            </div>
          </Spin>
        </div>
      </Form>
    )
  }
}

// export default OrderLatLng;
export default Form.create()(OrderLatLng)
