import React, { Component } from "react";
import { Button, Col, Row } from "antd";
import InstructionTypesList from "./InstructionTypesList";
import Text from "antd/lib/typography/Text";
import { checkServiceExistance } from "../../helpers/common";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";

class InstructionTypes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditing: false,
		};
		this.tableOptions = { pagination: {}, sorter: {} };
	}

	onTableChange = (pagination, sorter) => {
		const currentPage =
			this.tableOptions.pagination && this.tableOptions.pagination.current
				? this.tableOptions.pagination.current
				: 1;
		this.tableOptions = { pagination, sorter };
		if (pagination.current !== currentPage) {
			this.props.getInstructionTypes(
				pagination.current,
				this.tableOptions.sorter?.sortBy
					? this.tableOptions.sorter.sortBy
					: "name",
				this.tableOptions.sorter?.order
					? this.tableOptions.sorter.order
					: "ascend"
			);
		} else if (sorter && !isEmpty(sorter.field)) {
			const sortBy = sorter.field;
			const sortOrder = sorter.order;
			this.tableOptions.pagination.current = 1;
			this.props.getInstructionTypes(pagination.current, sortBy, sortOrder);
		}
	};

	render() {
		const hasLabelAcess = checkServiceExistance(['INSI', 'INSC'], 'ANY');
		const {
			openInstructionTypeModal,
			handleInstructionTypeEdit,
			handleInstructionTypeDelete,
			filteredInstructionTypesData,
			isLoading,
			isOrgLevelInstruction,
		} = this.props;
		return (
			<Col span={12}>
				<div className="instructionsWrapper__left">
					<Row className="instructionWrapperChild" gutter={16}>
						<Row
							type="flex"
							justify="space-between"
							style={{
								padding: "8px",
							}}
						>
						 { hasLabelAcess && 
							<Col className="instructionsWrapper__left__header">
								<Text
									style={{
										fontSize: "14px",
										fontWeight: "bold",
									}}
								>
									{I18n.t("instructions.type.label")}
								</Text>
							</Col>
							}
							{
							checkServiceExistance('INSC') && 
							<Col>
								<Button
									type="primary"
									icon="plus-circle"
									className="instructionsWrapper__left__header__button"
									onClick={() => openInstructionTypeModal()}
								>
									{I18n.t("instructions.add_type")}
								</Button>
							</Col>
							}
						</Row>
						{
							checkServiceExistance('INSI') && <Col className="instructionsWrapper__left__body">
							<InstructionTypesList
								className="instructionsWrapper__left__table"
								scroll = {{y:"calc(100vh - 375px)"}}
								isLoading={isLoading}
								rowKey="_id"
								handleEdit={handleInstructionTypeEdit}
								handleDelete={handleInstructionTypeDelete}
								data={filteredInstructionTypesData}
								pagination={this.props.pagination}
								tableChange={(pagination, filter, sorter, currentTable) =>
									this.onTableChange(pagination, sorter, currentTable)
								}
							></InstructionTypesList>
						</Col>
						}
					</Row>
				</div>
			</Col>
		);
	}
}

export default InstructionTypes;
