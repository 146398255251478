/* eslint-disable no-nested-ternary */
import React, { Fragment, Component } from "react";
import _ from "lodash";
import { isEmpty, alertMessage } from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Spin,
  Modal,
} from "../../common/UIComponents";
import userStore from "../../stores/UserStore";
import FormErrors from "../common/FormErrors";
import FormButtons from "../common/FormButtons";
import {
  fetchOrderAccessorials,
  saveOrderAccessorials,
} from "../../api/OrdersApi";
import {
  fetchAccessorialDetails,
  fetchAccessorialsComponents,
  fetchAccountAccessorials as fetchAccountComponentAccessorials,
} from "../../api/Accessorials";
import { setAccessorialResponse, setTemplateAccessorialObject } from "../../helpers/orders";
import OrderAccessorialRowList from "./OrderAccessorialRowList";
import { fetchTechnicians } from "../../api/UsersApi";
import AppConfig from "../../config/AppConfig";
import { getSlotInfo } from "../configurations/helper";
import { renderAlertMessage } from "../../helpers/common";
import TemplateAccessorialRowList from "../Templates/TemplateAccessorialRowList";

const { confirm } = Modal;
class TemplateAccessorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountComponentAccessorial: [],
      orgComponents: [],
      orderAccessorials: [],
      accountId: props.account_id,
      orgId: props.org_id,
      orderId: props.customer_order_id,
      displayComponents: [],
      currentLocation: props.currentLocation,
      drivers: props.drivers || [],
      technicians: [],
      slotConfig: {},
      authorizeDetails: {},
      decimalPoints: window.localStorage.getItem("round_off_decimals"),
      inProgress: false,
    };
  }

  componentDidMount() {
    // this.getOrgComponents()
    // this.getAccountComponentAccessorials()
    // this.getUsers()
    this.getOrderAccessorials();
    // this.getAccountComponentAccessorials();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account_id !== this.state.accountId) {
      this.setState(
        {
          accountId: nextProps.account_id,
        },
        () => {
          //  this.getAccountComponentAccessorials()
        }
      );
    }

    if (!_.isEqual(nextProps.drivers, this.state.drivers)) {
      this.setState({
        drivers: nextProps.drivers || [],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saveKey !== this.props.saveKey && !isEmpty(this.props.saveKey)) {
      this.handleSave()
    }
    if (!_.isEqual(prevProps.accessorials, this.props.accessorials)) {
      this.getOrderAccessorials(this.props.accessorials);
    }
  }

  getOrderAccessorials = () => {
    const accessorials = this.props.accessorials
    const authorizeDetails = {};
    const allowedIds = {};
    this.props.accountComponentAccessorial.forEach((accessorial) => {
      const components = accessorial.components
        ? accessorial.components.map((record) => record.component_code)
        : [];
      allowedIds[accessorial.id] = [...components];
    });
    const orderAccessorials = setTemplateAccessorialObject(
      accessorials,
      true,
      allowedIds
    );
    orderAccessorials.forEach((rec) => {
      if (!isEmpty(rec.authorized_by)) {
        authorizeDetails[rec.accessorial_code] = {
          authorized_by: rec.authorized_by,
          authorization_code: rec.authorization_code,
          comments: rec.comments,
          is_modified: false,
        };
      }
    });
    this.setState({
      orderAccessorials,
      authorizeDetails,
    });
  };

  updatePropValues = () => {
    const { handleOnChange } = this.props;
    if (handleOnChange && typeof handleOnChange === "function") {
      handleOnChange("isModified", true);
    }
  };

  handleOnParamChange = (
    accParam,
    componentCode,
    accountAccessorialId,
    eleValue
  ) => {
    const { orderAccessorials } = this.state;
    const accountAccessorialParamIndex = _.findIndex(orderAccessorials, [
      "account_accessorial_id",
      accountAccessorialId,
    ]);
    if (accountAccessorialParamIndex >= 0) {
      const accountAccessorialParam = _.cloneDeep(
        orderAccessorials[accountAccessorialParamIndex]
      );
      const componentParams = accountAccessorialParam.component_params
        ? accountAccessorialParam.component_params
        : [];
      const componentIndex = _.findIndex(componentParams, [
        "component_code",
        componentCode,
      ]);
      let currentComponent = {};
      if (componentIndex >= 0) {
        currentComponent = componentParams[componentIndex];
        if (isEmpty(currentComponent.component_values)) {
          currentComponent.component_values = [];
        }
        const paramObjIndex = _.findIndex(currentComponent.component_values, [
          "accessorial_key",
          accParam,
        ]);
        if (paramObjIndex >= 0) {
          currentComponent.component_values[paramObjIndex].accessorial_value =
            eleValue;
        } else {
          currentComponent.component_values.push({
            accessorial_key: accParam,
            accessorial_value: eleValue,
          });
        }
        componentParams[componentIndex] = { ...currentComponent };
      } else {
        currentComponent = {
          component_code: componentCode,
          component_values: [],
        };
        currentComponent.component_values.push({
          accessorial_key: accParam,
          accessorial_value: eleValue,
        });
        componentParams.push({ ...currentComponent });
      }
      accountAccessorialParam.component_params = [...componentParams];
      orderAccessorials[accountAccessorialParamIndex] = {
        ...accountAccessorialParam,
      };

      this.setState({
        orderAccessorials,
      });
      // this.props.handleStateOnchange('orderAccessorials', orderAccessorials);
    }
  };

  handleOnChange = (accountAccessorialId, element, value, source = "") => {
    const { orderAccessorials } = this.state;
    const accountAccessorialParamIndex = _.findIndex(orderAccessorials, [
      "account_accessorial_id",
      accountAccessorialId,
    ]);
    if (accountAccessorialParamIndex >= 0) {
      const accessorialObj = {
        ...orderAccessorials[accountAccessorialParamIndex],
      };
      if (source === "eligible_for") {
        accessorialObj.driver_pay_params = {
          ...accessorialObj.driver_pay_params,
          driver_id: value,
        };
      } else if (element === "attempted_driver_ids") {
        const attempted_driver_ids = accessorialObj.driver_pay_params
          ?.attempted_driver_ids
          ? accessorialObj.driver_pay_params.attempted_driver_ids
          : [];
        if (source >= 0) {
          if (isEmpty(value)) {
            attempted_driver_ids.splice(source, 1);
          } else if (attempted_driver_ids.length >= source) {
            attempted_driver_ids[source] = value;
          }
        } else {
          attempted_driver_ids.push(value);
        }
        accessorialObj.driver_pay_params = {
          ...accessorialObj.driver_pay_params,
          attempted_driver_ids,
        };
      } else if (element === "time_slots") {
        const values = value.split("-");
        accessorialObj.driver_pay_params = {
          ...accessorialObj.driver_pay_params,
          time_slots: { from_time: values[0], to_time: values[1] },
        };
      } else {
        accessorialObj[element] = value;
      }
      orderAccessorials[accountAccessorialParamIndex] = accessorialObj;
      this.setState({
        orderAccessorials,
      });
      // this.props.handleStateOnchange('orderAccessorials', orderAccessorials);
    }
  };

  askConfirmMessage = (title, content, cb) => {
    confirm({
      title,
      content: <span className="">{content}</span>,
      onOk: () => {
        cb();
      },
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onCancel: () => {},
    });
  };

  handleCheckStandardAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode
  ) => {
    const { orderAccessorials } = this.state;
    const { accountComponentAccessorial } = this.props;

    const authorizeDetails = this.state.authorizeDetails
      ? _.cloneDeep(this.state.authorizeDetails)
      : {};
    if (!isEmpty(authorizeDetails[accessorialCode])) {
      authorizeDetails[accessorialCode] = { is_modified: false };
    }
    const accountAccessorial = _.find(accountComponentAccessorial, {
      id: accountAccessorialId,
    });
    if (eleValue) {
      const otherAccessorials = orderAccessorials
        .filter(
          (accessorial) =>
            accessorial.standard_code === accountAccessorial.standard_code
        )
        .map((accessorial) => accessorial.accessorial_code);
      if (otherAccessorials.length > 0) {
        this.askConfirmMessage(
          I18n.t("messages.standard_change_confirm", {
            field: otherAccessorials.join(", "),
          }),
          I18n.t("messages.proceed_confirm"),
          () => {
            this.handleAddOrderCompStandardAccessorial(
              accountAccessorialId,
              accessorialCode,
              eleValue,
              isStandardCode
            );
          }
        );
      } else {
        this.handleAddOrderCompStandardAccessorial(
          accountAccessorialId,
          accessorialCode,
          eleValue,
          isStandardCode
        );
      }
    } else {
      const standardCode = accountAccessorial.standard_code;
      const standardAccessorialIds = accountComponentAccessorial
        .filter((accessorial) => accessorial.standard_code === standardCode)
        .map((accessorial) => accessorial.id);

      // const standardAccessorialIds = standardAccessorial.map(
      //   (accessorial) => accessorial.id
      // );
      const otherAccessorials = orderAccessorials.filter(
        (accessorial) =>
          !standardAccessorialIds.includes(accessorial.account_accessorial_id)
      );
      this.setState({
        orderAccessorials: otherAccessorials,
        authorizeDetails,
      });
    }
  };

  handleAddOrderCompStandardAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode
  ) => {
    const { accountComponentAccessorial } = this.props;
    const { orderAccessorials } = this.state;

    const accountAccessorial = _.find(accountComponentAccessorial, {
      id: accountAccessorialId,
    });
    if (!isEmpty(accountAccessorial)) {
      const standardCode = accountAccessorial.standard_code;
      const standardAccessorialIds = accountComponentAccessorial
        .filter(
          (accessorial) =>
            accessorial.id !== accountAccessorialId &&
            accessorial.standard_code === standardCode
        )
        .map((accessorial) => accessorial.id);
      const otherAccessorials = orderAccessorials.filter(
        (accessorial) =>
          !standardAccessorialIds.includes(accessorial.account_accessorial_id)
      );
      this.setState(
        {
          orderAccessorials: otherAccessorials,
        },
        () => {
          this.handleAddOrderCompAccessorial(
            accountAccessorial.id,
            accountAccessorial.accessorial_code,
            true
          );
        }
      );
    }
  };

  handleAddOrderCompAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
    isStandardCode
  ) => {
    const { orderAccessorials } = this.state;
    const authorizeDetails = this.state.authorizeDetails
      ? _.cloneDeep(this.state.authorizeDetails)
      : {};
    if (!isEmpty(authorizeDetails[accessorialCode])) {
      authorizeDetails[accessorialCode] = { is_modified: false };
    }
    if (eleValue) {
      const accountAccessorial = orderAccessorials.find(
        (acc) => acc.id === accountAccessorialId
      );
      if (accountAccessorial) {
      } else {
        if (isEmpty(authorizeDetails[accessorialCode])) {
          authorizeDetails[accessorialCode] = {};
        }
        authorizeDetails[accessorialCode]["is_modified"] = true;
        const { accountComponentAccessorial } = this.props;
        const accountAccessorialObj = _.find(accountComponentAccessorial, {
          id: accountAccessorialId,
        });
        const accessorialObj = {};
        accessorialObj.account_accessorial_id = accountAccessorialId;
        accessorialObj.accessorial_code = accessorialCode;
        accessorialObj.standard_code = !isEmpty(accountAccessorialObj)
          ? accountAccessorialObj.standard_code
          : "";
        accessorialObj.component_params = [];
        accessorialObj.driver_pay_params =
          !isEmpty(accountAccessorial) && accountAccessorial.driver_pay_params
            ? accountAccessorial.driver_pay_params
            : null;
        // this.props.handleStateOnchange('orderAccessorials', [
        //   ...orderAccessorials,
        //   accessorialObj,
        // ]);
        this.setState({
          orderAccessorials: [...orderAccessorials, accessorialObj],
          authorizeDetails,
        });
      }
    } else {
      const newAccountAccessorials = orderAccessorials.filter(
        (acc) => acc.account_accessorial_id !== accountAccessorialId
      );
      if (isEmpty(authorizeDetails[accessorialCode])) {
        authorizeDetails[accessorialCode] = {};
      }
      authorizeDetails[accessorialCode]["is_modified"] = true;
      this.setState({
        orderAccessorials: [...newAccountAccessorials],
        authorizeDetails,
      });
      // this.props.handleStateOnchange('orderAccessorials', [...newAccountAccessorials]);
    }
    //this.updatePropValues();
  };

  handleSave = () => {
    const { accountComponentAccessorial } = this.props;
    const authorizeDetails = this.state.authorizeDetails
      ? _.cloneDeep(this.state.authorizeDetails)
      : {};
    const orderAccessorials = _.cloneDeep(this.state.orderAccessorials);
    const config = this.props.config || {};
    const accountAccessorialParams = [];
    // orderAccessorials.forEach((orderAccessorial) => {
    //   const object = {
    //     account_accessorial_id: orderAccessorial.account_accessorial_id,
    //     accessorial_code: orderAccessorial.accessorial_code,
    //     accessorial_type: orderAccessorial.accessorial_type,
    //     component_params: [],
    //   };
    //   if (orderAccessorial.component_params.length > 0) {
    //     orderAccessorial.component_params.map((param) => {
    //       accountAccessorialParams.push(
    //         Object.assign({}, object, { component_params: { ...param } })
    //       );
    //     });
    //   } else {
    //     accountAccessorialParams.push(object);
    //   }
    // });
    const errors = [];
    if (false && config.require_accessorial_authorization === "true") {
      orderAccessorials.forEach((acc, index) => {
        if (
          authorizeDetails[acc.accessorial_code] &&
          authorizeDetails[acc.accessorial_code].is_modified &&
          isEmpty(authorizeDetails[acc.accessorial_code].authorized_by)
        ) {
          const reqObject = _.find(accountComponentAccessorial, {
            accessorial_code: acc.accessorial_code,
          });
          errors.push(
            `${I18n.t("accessorials.authorize.by")} is required for ${
              reqObject ? reqObject.accessorial_name : acc.accessorial_code
            }`
          );
        }
        orderAccessorials[index] = {
          ...orderAccessorials[index],
          ...authorizeDetails[acc.accessorial_code],
        };
      });
    }
    if (errors.length > 0) {
      this.setState({
        errors,
      });
    } else {
      const data = {
        organization_id: userStore.getStateValue("selectedOrg"),
        account_id: this.props.account_id,
        customer_order_id: this.props.customer_order_id,
        location_id: this.props.currentLocation,
        account_accessorial_params: [...orderAccessorials],
      };
      this.props.updateAccessorail(orderAccessorials, this.props.currentLocation.id);
      // saveOrderAccessorials(data).then((result) => {
      //   if (result.success) {
      //     this.setState({
      //       inProgress: false,
      //       errors: [],
      //     });
      //     alertMessage(I18n.t("messages.saved"));
      //   } else {
      //     this.setState({
      //       inProgress: false,
      //       errors: result.errors || [],
      //     });
      //   }
      // });
    }
  };

  renderSlotObject = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj
  ) => {
    const slotConfig = this.props.slotConfig;
    const size = "small";
    const slotObject = slotConfig[accessorial.id];
    const paramValues =
      slotObject &&
      slotObject.length > 0 &&
      slotObject[0].param_values &&
      slotObject[0].param_values.length > 0
        ? slotObject[0].param_values
        : {};
    const slotConfigObjects =
      paramValues.length > 0 ? paramValues[0].expedite_sharp : [];
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === "los";
    const isDisabled = isEmpty(orderAccObject) || isLos;
    const value =
      orderAccObject?.driver_pay_params &&
      orderAccObject.driver_pay_params?.time_slots
        ? `${orderAccObject.driver_pay_params.time_slots.from_time}-${orderAccObject.driver_pay_params.time_slots.to_time}`
        : "";
    return (
      <Fragment>
        {slotConfigObjects && (
          <Fragment>
            <Radio.Group
              value={value}
              onChange={(e) =>
                this.handleOnChange(
                  accessorial.id,
                  "time_slots",
                  e.target.value,
                  ""
                )
              }
              disabled={isDisabled}
              size={size}
            >
              {slotConfigObjects.map((slot) => (
                <Radio value={`${slot.from_time}-${slot.to_time}`}>
                  {getSlotInfo(slot, this.props.isMilitaryTime)}
                </Radio>
              ))}
            </Radio.Group>
          </Fragment>
        )}
      </Fragment>
    );
  };

  renderField = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj
  ) => {
    const size = "small";
    const isLos =
      !isEmpty(orderAccObject) && orderAccObject.accessorial_type === "los";
    const isDisabled = isEmpty(orderAccObject) || isLos;
    const { decimalPoints } = this.state;
    switch (paramDefinition.data_type) {
      case "string":
        return (
          <Row>
            <Col>
              {paramDefinition.code === "charge_type" ? (
                <Radio.Group
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnParamChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value
                    )
                  }
                  disabled={isDisabled}
                  size={size}
                >
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="flat">Flat</Radio>
                </Radio.Group>
              ) : paramDefinition.code === "charge_value" ? (
                <Input
                  type="number"
                  addonBefore={
                    currentConfiguration[component.code].charge_type ===
                    "flat" ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentConfiguration[component.code].charge_type ===
                    "percentage" ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                  value={currentConfiguration[component.code][param.code]}
                  onChange={(e) =>
                    this.handleOnParamChange(
                      component.code,
                      param.code,
                      e.target.value
                    )
                  }
                  className="textUpperCase"
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                  disabled={isDisabled}
                />
              ) : (
                <Input
                  size={size}
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnParamChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value
                    )
                  }
                  disabled={isDisabled}
                />
              )}
            </Col>
          </Row>
        );
      case "number":
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnParamChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value
                  )
                }
                disabled={isDisabled}
                min={0}
                precision={
                  paramDefinition.code === "min_charge" ||
                  paramDefinition.code === "max_charge"
                    ? decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        );
      case "price":
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnParamChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value
                  )
                }
                addonBefore={<Icon type="dollar" />}
                disabled={isDisabled}
                min={0}
                precision={decimalPoints}
              />
            </Col>
          </Row>
        );
      case "Boolean":
        return (
          <Row>
            <Radio.Group
              value={currentParamObj && currentParamObj.accessorial_value}
              onChange={(e) =>
                this.handleOnParamChange(
                  paramDefinition.code,
                  accountAccComponent.component_code,
                  accessorial.id,
                  e.target.value
                )
              }
              size={size}
              disabled={isDisabled}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        );
      case "Select":
        return (
          <Select
            showSearch
            size={size}
            value={currentParamObj && currentParamObj.accessorial_value}
            style={{ width: "100%" }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              this.handleOnParamChange(
                paramDefinition.code,
                accountAccComponent.component_code,
                accessorial.id,
                e
              )
            }
            disabled={isDisabled}
          >
            {paramDefinition.options.map((qoption, qindex) => (
              <Select.Option
                key={qoption.option_key}
                value={qoption.option_key}
              >
                {qoption.option_value}
              </Select.Option>
            ))}
          </Select>
        );
      default:
        break;
    }
  };

  handleAuthorizeChange = (code, element, value) => {
    const authorizeDetails = this.state.authorizeDetails
      ? _.cloneDeep(this.state.authorizeDetails)
      : {};
    if (isEmpty(authorizeDetails[code])) {
      authorizeDetails[code] = {};
    }
    authorizeDetails[code][element] = value;
    this.setState({
      authorizeDetails,
    });
  };

  render() {
    const { orderAccessorials, authorizeDetails } = this.state;
    const { orgComponents, displayComponents, accountComponentAccessorial } =
      this.props;
    const losAccessorials = this.state.orderAccessorials
      ? this.state.orderAccessorials
          .filter((accessorial) => accessorial.accessorial_type === "los")
          .map((accessorial) => accessorial.account_accessorial_id)
      : [];
    const predefinedAccessorial = accountComponentAccessorial.filter(
      (accessorial) => losAccessorials.includes(accessorial.id)
    );
    const additionalAccessorial = accountComponentAccessorial.filter(
      (accessorial) => !losAccessorials.includes(accessorial.id)
    );
    return (
      <Spin
        spinning={this.state.inProgress}
        delay={1000}
        className="accessorial_block"
      >
        <div>
          {accountComponentAccessorial.length > 0 && (
            <Fragment>
              {predefinedAccessorial.length > 0 && (
                <div style={{ marginTop: "1rem" }}>
                  <h3>{I18n.t("accessorials.pre_selected")}</h3>
                  <TemplateAccessorialRowList
                    data={predefinedAccessorial}
                    renderField={this.renderField}
                    orderAccessorials={this.state.orderAccessorials}
                    handleAddOrderCompAccessorial={
                      this.handleAddOrderCompAccessorial
                    }
                    handleAddOrderCompStandardAccessorial={
                      this.handleAddOrderCompStandardAccessorial
                    }
                    handleCheckStandardAccessorial={
                      this.handleCheckStandardAccessorial
                    }
                    onChange={this.handleOnChange}
                    displayComponents={displayComponents}
                    size="small"
                    pagination={{ position: "none" }}
                    technicians={this.props.technicians}
                    isReadOnly
                    drivers={[]}
                    renderSlotObject={this.renderSlotObject}
                    handleAuthorizeChange={this.handleAuthorizeChange}
                    authorizeDetails={authorizeDetails}
                    required_accessorials={
                      this.props.required_accessorials || []
                    }
                    locations={this.props.locations}
                    reqFrom="templates"
                  />
                </div>
              )}
              {additionalAccessorial.length > 0 && (
                <div style={{ marginTop: "1rem" }}>
                  <h3>{I18n.t("accessorials.additional")}</h3>
                  <TemplateAccessorialRowList
                    data={additionalAccessorial}
                    renderField={this.renderField}
                    orderAccessorials={this.state.orderAccessorials}
                    handleAddOrderCompAccessorial={
                      this.handleAddOrderCompAccessorial
                    }
                    onChange={this.handleOnChange}
                    handleAddOrderCompStandardAccessorial={
                      this.handleAddOrderCompStandardAccessorial
                    }
                    handleCheckStandardAccessorial={
                      this.handleCheckStandardAccessorial
                    }
                    displayComponents={displayComponents}
                    size="small"
                    pagination={{ position: "none" }}
                    isReadOnly={false}
                    technicians={this.props.technicians}
                    drivers={this.state.drivers}
                    renderSlotObject={this.renderSlotObject}
                    config={this.props.config}
                    handleAuthorizeChange={this.handleAuthorizeChange}
                    authorizeDetails={authorizeDetails}
                    required_accessorials={
                      this.props.required_accessorials || []
                    }
                    locations={this.props.locations}
                    reqFrom="templates"
                  />
                </div>
              )}
              {FormErrors(this.state.errors)}
              {
                // ['NEW', ...AppConfig.notStartedList, 'PREPLAN'].includes(customerDetails.status) &&
                false && <Row className="marginTop10">
                  <Col xs={24} className="alignCenter">
                    {FormButtons(
                      this.state.inProgress,
                      this.handleSave,
                      this.props.onCancel,
                      true,
                      true,
                      "small"
                    )}
                  </Col>
                </Row>
              }
            </Fragment>
          )}
        </div>
      </Spin>
    );
  }
}

export default TemplateAccessorial;
