/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Spin,
  Avatar,
  Select,
  Button,
} from '../../common/UIComponents'
import { alertMessage,isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import FormErrors from '../common/FormErrors'
import { fetchActiveUsers } from '../../api/UsersApi'
import BaseModal from '../BaseModal'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import List from '../reports/deduction_reports/List'
import ReportForm from '../reports/deduction_reports/ReportForm'
import AdditionalDeductionFrom from '../reports/deduction_reports/AdditionalDeductionFrom'
import { driverClaimsApi } from '../../api/DriverClaims'

const { Option } = Select

class DeductionReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      driverClaims: [],
      driverPayReports: [],
      users: [],
      currentRecord: {},
      currentDriver: {},
      showForm: false,
      showAdditionalForm: false,

    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    this.getUsers()
  }

  handleShowForm = () => {
    this.setState({
      showForm: true,
    })
  }

  getUsers = () => {
    this.setState({ inProgress: true })
    fetchActiveUsers('driver', 1, 1000).then((result) => {
      if (result.success) {
        const users = result.users || []
        this.setState(
          {
            users,
            inProgress: false,
            currentDriver: users.length > 0 ? users[0] : {},
          },
          () => {
            this.getReports()
          },
        )
      } else {
        this.setState({ inProgress: false })
        this.getReports()
      }
    })
  }

  getReports = () => {
    this.setState({ inProgress: true })
    const { currentDriver } = this.state
    driverClaimsApi.fetch(currentDriver.id).then(
      (result) => {
        if (result.success) {
          this.setState({ inProgress: false })
          const driverClaims = result.driver_claims || []
          this.setState({
            driverClaims,
          })
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, driverClaims: [] })
        }
      },
    )
  }

  handleChange = (element, value) => {
    if (element === 'driver') {
      const currentDriver = _.find(this.state.users, { id: value })
      this.setState(
        {
          currentDriver: !isEmpty(currentDriver) ? currentDriver : {},
        },
        () => {
          this.getReports()
        },
      )
    } else {
      this.setState({
        [element]: value,
      })
    }
  }

  _renderSearchOptions = () => (
    <Row className="marginTop10" gutter={8}>
      <Col xs={2}> {I18n.t('general.Driver')} :</Col>
      <Col xs={6}>
        <Select
          style={{ width: '100%' }}
          className="routes-input"
          onChange={(e) => this.handleChange('driver', e)}
          value={this.state.currentDriver.id || ''}
          filterOption={(input, option, index) =>
            option.props.children.props.children[2]
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          size="small"
        >
          {this.state.users.map((province) => (
            <Option key={province.id}>
              <div>
                {province.image && province.image.url ? (
                  <Avatar src={`${province.image.url}`} size={20} />
                ) : (
                  <Avatar
                    src="./../icons/user_100*100.png"
                    alt="DriverProfile"
                    size={20}
                  />
                )}
                &nbsp;&nbsp;{province.employee_code || province.full_name}
              </div>
            </Option>
          ))}
        </Select>
      </Col>
      {/* <Col xs={2} className="alignRight">Week :</Col> */}
      <Col xs={16} className="alignRight">
        <Button type="primary" size="small" onClick={this.handleShowForm}>{I18n.t('general.add')}</Button>
      </Col>
    </Row>
  )


  handleCloseForm = (key) => {
    this.setState(
      {
        [key]: false,
        currentRecord: {},
      },
      () => {
        this.getReports()
      },
    )
  }

  closeAdditionalForm = (driver_claims) => {
    const driverClaims = driver_claims.length > 0 ? driver_claims : [];
    this.setState(
      {
        showAdditionalForm: false,
        currentRecord: {},
        driverClaims,
      }
    )
  }

  renderShowForm = () => {
    return (
      <BaseModal
        title={`${this.state.currentDriver ? this.state.currentDriver.employee_code : ''} ${I18n.t('general.details')}`}
        onCancel={() => this.handleCloseForm('showForm')}
        width="50%"
        //bodyStyle={{ padding: 0, height: '100%' }}
        style={{
          top: 20,
          // overflowY: 'hidden',
          // height: '100%',
        }}
      >
        <ReportForm
         currentRecord={this.state.currentRecord}
         currentDriver={this.state.currentDriver}
         drivers={this.state.users}
         onClose={this.handleCloseForm}
         />
      </BaseModal>
    )
  }

  closeAdditionalForm = () => {

  }

  renderAdditionalForm = () => {
    return (
      <BaseModal
        title={`${I18n.t('deductions.add_additional_amount')}`}
        onCancel={() => this.handleCloseForm('showAdditionalForm')}
        width="50%"
        //bodyStyle={{ padding: 0, height: '100%' }}
        style={{
          top: 20,
          // overflowY: 'hidden',
          // height: '100%',
        }}
      >
        <AdditionalDeductionFrom
         currentRecord={this.state.currentRecord}
         currentDriver={this.state.currentDriver}
         onClose={this.closeAdditionalForm}
         />
      </BaseModal>
    )
  }

  handleEditClick = (id, type = 'edit') => {
    const rec = _.find(this.state.driverClaims, {id: id});
    if(!isEmpty(rec)){
      this.setState({
        currentRecord: rec,
      },() => {
        if(type === 'edit') {
          this.handleShowForm()
        } else if(type === 'additions') {
          this.handleShowAdditionalForm()
        }
        
      });
    }
  }

  handleShowAdditionalForm = () => {
    this.setState({
      showAdditionalForm: true,
    })
  }

  _renderReports = () => (
    <div>
      <Row className="marginTop10">
        <Col>
          <Fragment>
            <List
              size="small"
              pagination={{ position: 'none' }}
              data={this.state.driverClaims}
              onChange={this.handleFormChange}
              handleEditClick={(id) => this.handleEditClick(id, 'edit')}
              deleteClick={(id) =>
                this.handleDeleteClick(id)
              }
              handleAdditioalsClick={(id) => this.handleEditClick(id, 'additions')}
              currentDriver={this.state.currentDriver}
            />
          </Fragment>
          <Col xs={24} className="marginTop10">
            {FormErrors(this.state.errors)}
          </Col>
        </Col>
      </Row>
    </div>
  )

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true })
    driverClaimsApi.delete(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.setState({ inProgress: false })
        this.getReports();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    });
  }

  render() {
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              {checkServiceExistance('GOR') && (
                <Col className="accessorials">
                  <this._renderSearchOptions />
                  <this._renderReports />
                </Col>
              )}
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderShowForm()}
        {this.state.showAdditionalForm && this.renderAdditionalForm()}
      </div>
    )
  }
}
DeductionReports.propTypes = {
  account: PropTypes.shape().isRequired,
}

export default DeductionReports
