import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import MainMap from '../common/Map';
import equal from "fast-deep-equal";
import { Row, Col, Card, Icon, Avatar, Tooltip } from '../../common/UIComponents';
import manlogo from '../../../assets/images/driver.png';
import AppConfig from '../../config/AppConfig';
import moment from 'moment';
import { momentTime } from '../../helpers/date_functions';
import { isEmpty } from 'lodash';

const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: '',
      isOpen: [],
      markers: props.data
        .filter(x => x.driver_latest_location && x.driver_latest_location.location !== null)
        .map(user => {
          const dvLatestLocation = user.driver_latest_location;
          const dvLocation = !isEmpty(user.dv_location) ? user.dv_location : {};
          return ({
          id: user.id,
          lat: dvLatestLocation.location[1],
          lng: dvLatestLocation.location[0],
          icon: {
            url: user.image.url,
            size: { width: 60, height: 80 },
            anchor: { x: 15, y: 50 },
            scaledSize: { width: 30, height: 40 },
          },
          track_time: moment(dvLatestLocation.date_time).isValid() ? momentTime(AppConfig.dateTimeFormat, dvLatestLocation.date_time, dvLocation.timeZoneId) : '',
          ...user,
        })
      }),
    };
    this.onMapMounted = this.onMapMounted.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const isUpdated = !equal(nextProps.data, this.props.data);
    const stateData = {};
    if (isUpdated) {
      if (nextProps.data.length) {
        const markers = nextProps.data
        .filter(x => x.driver_latest_location && x.driver_latest_location.location !== null)
        .map(user => {
          const dvLatestLocation = user.driver_latest_location;
          const dvLocation = !isEmpty(user.dv_location) ? user.dv_location : {};
          return ({
          id: user.id,
          lat: dvLatestLocation.location[1],
          lng: dvLatestLocation.location[0],
          icon: {
            url: user.image.url,
            size: { width: 60, height: 80 },
            anchor: { x: 15, y: 50 },
            scaledSize: { width: 30, height: 40 },
          },
          track_time: moment(dvLatestLocation.date_time).isValid() ? momentTime(AppConfig.dateTimeFormat, dvLatestLocation.date_time, dvLocation.timeZoneId) : '',
          ...user,
        })
      }); 
      this.setState({
        markers,
      })
    }
  }
}

  onToggleOpen(e, i) {
    const isOpen = [];
    isOpen[i] = !this.state.isOpen[i];
    this.setState({
      isOpen,
    });
  }

  onMapMounted(mapObj) {
    if (!this.state.map) {
      const map = this.state.map ? this.state.map : mapObj;
      const bounds = new window.google.maps.LatLngBounds();
      this.state.markers.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
      });
      map.fitBounds(bounds);
      this.setState({
        map,
      });
    }
  }

  onMarkerClustererClick = (markerClusterer) => {
    const clickedMarkers = markerClusterer.getMarkers();
    // eslint-disable-next-line no-console
  };

  render() {
    return (
      <MainMap
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
        containerElement={<div style={{ width: '100%', height: '100%' }} />}
        mapElement={<div style={{ width: '100%', height: '100vh' }} />}
        onMapMounted={this.onMapMounted}
        defaultZoom={4}
        defaultCenter={{ lat: 30.9873867, lng: -97.472588 }}
        {...this.props}
      >
        <Fragment>
          <MarkerClusterer
            onClick={this.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
          >
            {this.state.markers.map((marker, i) => (
              <Fragment key={marker.id}>
                <Marker
                  key={marker.id}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  onClick={e => this.onToggleOpen(e, i)}
                  icon={marker.icon}
                >
                  {this.state.isOpen[i] &&
                    <InfoWindow
                      onCloseClick={e => this.onToggleOpen(e, i)}
                      options={{ alignTop: true, closeBoxURL: '', enableEventPropagation: true }}
                    >
                      <div style={{
                        height: "auto", width: "auto", padding: 0,
                      }} className="mapInfoWindow"
                      >

                        <Card
                          style={{ minWidth: 340, marginTop: 12, fontSize: '14px', color: '#000000', padding: '10px 5px' }}
                          title={marker.full_name}

                        >
                          <div style={{ textAlign: "left" }}>

                            <Row>
                              <Col md={5} lg={5} sm={12} xs={12}>
                                {
                                  marker.image && marker.image.url ?
                                    <Avatar src={`${marker.image.url}`} size={50} /> :
                                    <Avatar src={manlogo} alt="DriverProfile" size={50} />
                                }

                              </Col>
                              <Col md={19} lg={19} sm={12} xs={12}>
                                {
                                  marker.full_name &&
                                  <Row>
                                    <Col xs={1}><Icon type="user" /></Col>
                                    <Col xs={23}>&nbsp;&nbsp;{marker.full_name}</Col>
                                  </Row>
                                }
                                {
                                  marker.mobile_number &&
                                  <Row>
                                    <Col xs={1}><Icon type="phone" /></Col>
                                    <Col xs={23}><Tooltip title="Contact No">&nbsp;&nbsp;{marker.mobile_number}</Tooltip></Col>
                                  </Row>

                                }
                                {
                                  this.props.userType !== 'driver' && marker.email &&
                                  <Row>
                                    <Col xs={1}><Icon type="mail" /></Col>
                                    <Col xs={23}><Tooltip title="Email">&nbsp;&nbsp;{marker.email}</Tooltip></Col>
                                  </Row>

                                }
                                {
                                  marker.zone && <Row>
                                    <Col xs={1}><Icon type="appstore" /></Col>
                                    <Col xs={23}><Tooltip title="Zone">&nbsp;&nbsp;{marker.zone ? (marker.zone.name ? marker.zone.name : '') : ''}</Tooltip></Col>
                                  </Row>
                                }
                                {
                                  marker.track_time && <Row>
                                    <Col xs={1}><Icon type="history" /></Col>
                                    <Col xs={23}><Tooltip title="Last Track Time">&nbsp;&nbsp;{marker.track_time }</Tooltip></Col>
                                  </Row>
                                }
                              </Col>

                            </Row>






                          </div>

                        </Card>
                      </div>
                    </InfoWindow>
                  }
                </Marker>
              </Fragment>
            ))}
          </MarkerClusterer>
        </Fragment>
      </MainMap>
    );
  }
}

Map.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userType: PropTypes.string.isRequired,
};

export default Map;
