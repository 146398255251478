import { Button, Col, Icon, Row, Spin } from "antd";
import React, { useContext, useState } from "react";
import SwitchComponent from "../../common/switchComponent";
import I18n from "../../common/I18n";
import LabelInfo from "./LabelInfo";
import { OrgContext } from "../../context/OrgContext";
import { setOrganizationConfig } from "../../api/Organisations";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage } from "../../common/Common";

export const MenuRenderingSetings = (props) => {
  const [settings, setSettings] = useState(props.organizationSettings);
  const [isLoading, setIsLoading] = useState(false);
  const { currentOrg,setOrgSettings } = useContext(OrgContext);
  const [invoiceStatusReportDefault, setInvoiceStatusReportDefault] = useState("false");
  const [invoiceFusionReportDefault, setInvoiceFusionReportDefault] = useState("false");
  const orgId = _.get(currentOrg, "id");

  const renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={handleSaveSettings}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  const handleToggleChange = (key, defaultStateSetter) => (checkedValue) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: checkedValue,
    }));
    // Update default state based on the key
    defaultStateSetter(checkedValue);
  };

  const handleSaveSettings = () => {
    const data = {
      organization_config: settings,
    };
    setIsLoading(true);
    setOrganizationConfig(data, orgId).then((result) => {
      if (result.success) {
        setIsLoading(false);
        setOrgSettings(settings);
        alertMessage(result.org_config.message, "success");
      } else {
        renderAlertMessage(result.errors);
        setIsLoading(false);
      }
    });
  };

  const menuItmes = () => {
    return (
      <div>
        {/* <Row>
        <div className="settingHeader">
          <span className="textBold">
            {I18n.t("configurations.menuSettings.title")}
          </span>
        </div>
      </Row> */}
        <Row className="configLableRow marginTop10">
          <Col xs={23} sm={23} md={23} lg={4}>
            {I18n.t("configurations.menuSettings.invoice_status_report")}
            <LabelInfo
              title={I18n.t(
                "configurations.definitions.invoice_status_report_menu"
              )}
            />
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}>
            :
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            <SwitchComponent
               handleChange={handleToggleChange(
                "invoice_status_report",
                setInvoiceStatusReportDefault
              )}
              isChecked={settings.hasOwnProperty("invoice_status_report")
              ? settings.invoice_status_report === "true"
              : false}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              checkedValue={"true"}
              unCheckedValue={"false"}
            />
          </Col>
        </Row>
        <Row className="configLableRow marginTop20">
          <Col xs={23} sm={23} md={23} lg={4}>
            {I18n.t("configurations.menuSettings.invoice_fusion_report")}
            <LabelInfo
              title={I18n.t(
                "configurations.definitions.invoice_fusion_report_menu"
              )}
            />
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}>
            :
          </Col>
          <Col xs={24} sm={24} md={24} lg={14}>
            <SwitchComponent
              handleChange={handleToggleChange(
                "invoice_fusion_report",
                setInvoiceFusionReportDefault
              )}
              isChecked={settings.hasOwnProperty("invoice_fusion_report")
              ? settings.invoice_fusion_report === "true"
              : false}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              checkedValue={"true"}
              unCheckedValue={"false"}
            />
          </Col>
        </Row>
        {renderFloatingBtn()}
      </div>
    );
  };

  return (
    <Spin spinning={isLoading} delay={1000}>
      {menuItmes()}
    </Spin>
  );
};
