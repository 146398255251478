import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popover } from "../../common/UIComponents";
import { withRouter } from "react-router";
import userStore from "../../stores/UserStore";
import { checkNegitive } from "../../common/Common";
import BaseList from "../BaseList";
import CopyableContent from "../common/CopyableContent";
import I18n from "../../common/I18n";

const SelectedSummary = ({ balanceAmount, creditAmount, totalPaidAmount, creditToPay }) => {
  return (
    <Fragment>
      <Row type="flex">
        <Col md={19} style={{ textAlign: "end" }}>
          <h4>
            Balance Amount ($):{" "}
            {/* <span style={{ color: balanceAmount >= totalPaidAmount ? "#008000" : "red" }}> */}
            {/* </span> */}
          </h4>
        </Col>
        <Col md={5} className="alignRight">
          <h4>{checkNegitive(balanceAmount, false)}</h4>
        </Col>
      </Row>
      <Row type="flex">
        <Col md={19} style={{ textAlign: "end" }}>
          <h4>
            {I18n.t("account_receivables.credits")}:{" "}
            {/* <span style={{ color: balanceAmount >= totalPaidAmount ? "#008000" : "red" }}> */}
            {/* </span> */}
          </h4>
        </Col>
        <Col md={5} className="alignRight">
          <h4>{checkNegitive(creditAmount, false)}</h4>
        </Col>
      </Row>
      <Row type="flex">
        <Col md={19} style={{ textAlign: "end" }}>
          <h4>
            Credits To Pay ($):{" "}
            {/* <span style={{ color: balanceAmount >= totalPaidAmount ? "#008000" : "red" }}> */}
            {/* </span> */}
          </h4>
        </Col>
        <Col md={5} className="alignRight">
          <h4>{checkNegitive(creditToPay, false)}</h4>
        </Col>
      </Row>
      <Row type="flex">
        <Col md={19} style={{ textAlign: "end" }}>
          <h4>
            Amount To Pay($):{" "}
            {/* <span style={{ color: balanceAmount >= totalPaidAmount ? "#008000" : "red" }}> */}
            {/* </span> */}
          </h4>
        </Col>
        <Col md={5} className="alignRight">
          <h4>{checkNegitive(totalPaidAmount, false)}</h4>
        </Col>
      </Row>
      {/* <Row type="flex">
   <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "end" }}>
     <h4>
       Balance Amount ($):{" "}
       {checkNegitive(balanceAmount, false)}
     </h4>
   </Col>
   <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "end" }}>
     <h4>
       {I18n.t("account_receivables.credits")}:{" "}
       {checkNegitive(creditAmount, false)}
     </h4>
   </Col>
   <Col style={{ textAlign: "end" }} xs={24} sm={24} md={24} lg={24} xl={24}>
     <h4>
       Amount To Pay($):{" "}
       <span style={{ color: "#00308F" }}>
         {checkNegitive(totalPaidAmount, false)}
       </span>
     </h4>
   </Col>
 </Row> */}
    </Fragment>
  );
};

SelectedSummary.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(SelectedSummary);
