import React from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import { compareString } from "../../common/Common";
import {
  Icon,
  Popconfirm,
  Tooltip,
  Typography,
} from "../../common/UIComponents";
import { checkServiceExistance, formatFullName } from "../../helpers/common";
import userStore from "../../stores/UserStore";
import AppConfig from "../../config/AppConfig";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import I18n from "../../common/I18n";
const { Paragraph } = Typography;
// const organization_id = userStore.getStateValue("selectedOrg");
class AgentList extends BaseList {
  constructor(props) {
    super(props);
    const isSuperAdmin = userStore.superAdmin();
    this.columns = [
      {
        key: "name",
        title: "Agent Name",
        dataIndex: "name",
        className:"noWrap",
        render: (text) => (
          <Tooltip title={text}>{text}</Tooltip>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "code",
        title: "Agent Code",
        dataIndex: "code",
        sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "l_address",
        title: I18n.t("general.address"),
        className:"noWrap",
        render: (data) => {
          const address = _.values(data.address)
            .splice(1, 6)
            .filter(Boolean)
            .join();
          return (
          <Tooltip title={address}> {address} </Tooltip>
          );
        },
        width: 300,
      },
      {
        key: "contact_name",
        title: I18n.t("location.contact_name"),
        className:"noWrap",
        dataIndex : 'contact_name',
        width: 150,
      },
      {
        key: "phone_number",
        title: I18n.t("general.phone"),
        dataIndex: "phone_number",
        render: (data) => {
            return data ? `+${data}` : "";
        }
      },
      {
        key: "fax",
        title: "Fax",
        dataIndex: "fax",
        render: (data) => {
          return data ? `+${data}` : "";
      }
      },
      {
        key: "email",
        title: I18n.t("general.email"),
        dataIndex: "email",
        render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        className: "noWrap",
      },
    ];
    this.columns.push({
      key: "actions",
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id) => (
        <div className="line-actions">
          {/* <Icon
            type='smile'
            onClick={() => this.props.showReviews(id)}
          /> */}
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)} /></span>
            </Tooltip>
            <Tooltip title={I18n.t("general.delete")}>
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => this.props.deleteClick(id)}
                okText="Yes"
                cancelText="No"
              >
                <span><DeleteIcon /></span>
              </Popconfirm>
            </Tooltip>
        </div>
      ),
      width: "95px",
    });
  }
}

export default AgentList;