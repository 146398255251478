import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from '../../common/UIComponents'
import { checkServiceExistance } from '../../helpers/common'
import ApproveInvoices from './ApproveInvoices'
import BillingPdfDownload from './BillingPdfDownload'
import ConsolidateButton from './ConsolidateButton'
import I18n from '../../common/I18n'
import OnHoldInvoices from './OnHoldInvoices'


const BulkOperations = ({
  selectedInfo,
  handleCancel,
  currentStatus,
  loading,
  handleSuccess,
  handleConsolidate,
  handleRedirect,
  organizationSettings,
  userContext
}) => {
  const size = 2
  const showHoldBucket = organizationSettings.show_hold_bucket=="true";
  return (
    <Row className="bottom_fixed">
      <Col xs={24} style={{ padding: '10px 10px' }} className="alignCenter">
        <Button type="danger" icon="close-circle" onClick={handleCancel}>
          Clear
        </Button>
        &nbsp;&nbsp;
        {currentStatus !== 'APPROVED' && showHoldBucket &&
          <>
          &nbsp;&nbsp;
            <OnHoldInvoices
              selectedInfo={{ ...selectedInfo }}
              action={currentStatus === 'READY_FOR_BILLING' ? 'ON_HOLD' : currentStatus === 'ON_HOLD' ? 'READY_FOR_BILLING' : null}
            label={currentStatus === 'READY_FOR_BILLING' ? 'Hold' : currentStatus === 'ON_HOLD' ? 'Ready For Billing' : null}
              handleSuccess={handleSuccess}
              handleCancel={handleCancel}
              screenFrom="list"
          />
              &nbsp;&nbsp;
        </>
        }
        {currentStatus !== "ON_HOLD" &&
          checkServiceExistance('BLUS') && (
            <>
          <ApproveInvoices
              selectedInfo={{ ...selectedInfo }}
              action={currentStatus === 'READY_FOR_BILLING' ? 'APPROVED' : 'REAPPROVED'}
              buttonType="danger"
              className="assignDriver"
              loading={loading}
              isDisabled={!(selectedInfo.selectedKeys.length > 0)}
              label={currentStatus === 'READY_FOR_BILLING' ? I18n.t('invoices.approve') : I18n.t('invoices.reapprove')}
              handleSuccess={handleSuccess}
              handleCancel={handleCancel}
              screenFrom="list"
              userContext={userContext}
            />
            &nbsp;&nbsp;
            </>
          )
        }
        {/* Consolidate Button */}
        {/* <Button
          type="primary"
          onClick={handleRedirect} 
          disabled={loading || !(selectedInfo.selectedKeys.length > 0)}
        >
          Consolidate
        </Button> */}
        { currentStatus === 'READY_FOR_BILLING' && <ConsolidateButton
          className={"buttonCerulean marginLeft10"}
          buttonType="primary"
          size="default"
          label={I18n.t("consolidation.consolidate")}
          isDisabled={selectedInfo.selectedKeys.length === 0}
          handleSuccess={handleSuccess}
          orders={[...selectedInfo.selectedRows]}
        />
        }
        
        &nbsp;&nbsp;
        {false && checkServiceExistance('BLUS') && (
          <ApproveInvoices
            action={'ADD_TO_EXISTING'}
            selectedInfo={{ ...selectedInfo }}
            buttonType="danger"
            className="assignDriver buttonMitesse"
            loading={loading}
            isDisabled={!(selectedInfo.selectedKeys.length > 0)}
            label={I18n.t('invoices.add_to_existing')}
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
            screenFrom="list"
          />
        )}
        <BillingPdfDownload
          className={"buttonCoolBlue marginLeft10"}
          buttonType="primary"
          size="default"
          label={I18n.t("general.print")}
          isDisabled={selectedInfo.selectedKeys.length === 0}
          handleSuccess={() => {}}
          orderIds={[...selectedInfo.selectedKeys]}
        />
      </Col>
    </Row>
  )
}

BulkOperations.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  statusChange: PropTypes.func.isRequired,
  handleConsolidate: PropTypes.func.isRequired, // Add PropTypes for the new prop
}

export default BulkOperations
