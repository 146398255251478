import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchSurveys, fetchSubmittedSurveys, deleteSurvey, saveSurvey } from '../api/SurveysApi';
import { Button, Col, Row, Spin } from '../common/UIComponents';
import SurveysList from '../components/surveys/List';
import BaseModal from '../components/BaseModal';
import SurveyForm from '../components/surveys/Form';
import { alertMessage,  isEmpty } from '../common/Common';
import I18n from '../common/I18n';
import SubmittedSurveys from '../components/surveys/SubmittedSurveys';
import Questionaries from './Questionaries';
import { checkServiceExistance, renderAlertMessage } from '../helpers/common';


class Surveys extends Component {
  constructor() {
    super();
    this.state = {
      surveys: [],
      currentSurvey: {},
      showFormModal: false,
      showQuestionModal: false,
      inProgress: false,
      isNew: true,
      showSubmittedSurveys: false,
      submittedSurveys: [],
    };
  }

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = (initial, cb) => {
    this.setState({ inProgress: true, currentSurvey: {} });
    fetchSurveys()
      .then((result) => {
        if (result.success) {
          this.setState({
            surveys: result.surveys.surveys,
            inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleAddClick = () => {
    this.setState({ currentSurvey: {}, showFormModal: true });
  }

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.surveys, ['id', id]);
    const currentSurvey = Object.assign({}, this.state.surveys[index]);
    this.setState({ currentSurvey, showFormModal: true });
  }

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteSurvey(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getSurveys();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handlequestionnare = (id) => {
    // this.setState({showQuestionModal:true})
    // this.props.history.push({
    //   pathname: `/questionaries/${id}`,
    //   parentType: I18n.t('questionaries.surveys'),
    // });
    const currentSurvey = _.find(this.state.surveys, ['id', id]);
    this.setState({
      showQuestionModal: true,
      currentSurvey: !isEmpty(currentSurvey) ? currentSurvey : {},
    });
  }

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false }, () => {
      this.getSurveys();
    });
  }

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.surveys, ['id', id]);
    const currentSurvey = Object.assign({}, this.state.surveys[index]);
    currentSurvey.status = currentSurvey.status === 'active' ? 'inactive' : 'active';
    this.setState({ currentSurvey, isNew: false }, () => {
      saveSurvey(this.state.isNew, this.state.currentSurvey)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'));
            this.getSurveys();
          } else {
            alertMessage(result.errors, 'error', 10);
          }
        });
    });
  }

  handleSubmittedSurveys = (id) => {
    const index = _.findIndex(this.state.surveys, ['id', id]);
    const currentSurvey = Object.assign({}, this.state.surveys[index]);
    this.setState({ currentSurvey }, () => {
      fetchSubmittedSurveys(id)
        .then((result) => {
          if (result.success) {
            if (result.surveys.length > 0) {
              this.setState({
                submittedSurveys: result.surveys,
                inProgress: false,
                showSubmittedSurveys: true,
              });
            } else {
              alertMessage(I18n.t('messages.no_surveys_submitted'), 'error', 10);
              this.setState({ inProgress: false });
            }
          } else {
            renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
        });
    });
  }

  handleOnModalClose = () => {
    if (this.state.showFormModal && this.state.currentSurvey.id) {
      this.getSurveys();
    }
    this.setState({ showFormModal: false });
  }

  // eslint-disable-next-line react/sort-comp
  renderFormModal = () => {
    let title;
    let isNew;
    if (this.state.currentSurvey.id) {
      title = I18n.t('survey.edit');
      isNew = false;
    } else {
      title = I18n.t('survey.add');
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        width="40%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <SurveyForm
          survey={this.state.currentSurvey.id ? this.state.currentSurvey : {}}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
        />
      </BaseModal>
    );
  }

  handleQuestionModalClose = () => {
    this.setState({
      currentSurvey: {},
      showQuestionModal: false,
    });
  }

  renderQuestionModal = () => (
    <BaseModal
      title={`Questions of ${this.state.currentSurvey.name}`}
      onCancel={() => this.handleQuestionModalClose()}
      width="95%"
      style={{ top: 20 }}
      maskClosable={false}
    >
      <Questionaries
        currentRecord={this.state.currentSurvey.id ? this.state.currentSurvey : {}}
        onCancel={() => this.handleQuestionModalClose()}
        parentType={I18n.t('questionaries.surveys')}
        parentId={this.state.currentSurvey.id || ''}
      />
    </BaseModal>
  )

  renderSubmittedSurveys = () => (
    <BaseModal
      title={`${I18n.t('survey.customer')} of ${this.state.currentSurvey.name}`}
    

      
      onCancel={() => this.handleonListClose()}
      width="60%"
      style={{ top: 20 }}
    >
      <SubmittedSurveys
        surveys={this.state.submittedSurveys}
        onCancel={() => this.handleonListClose()}
      />
    </BaseModal>
  )

  handleonListClose = () => {
    this.setState({
      showSubmittedSurveys: false,
    });
  }

  render() {
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col xs={12}>
              {I18n.t('menu.surveys')}
            </Col>
            {checkServiceExistance('SRVYC') && <Col xs={12} className='page-actions'>
              <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('survey.new')}
              </Button>
            </Col>}
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col>
              {
                checkServiceExistance('SRVYI') && 
                <SurveysList
                  data={this.state.surveys}
                  scroll = {{y:"calc(100vh - 220px)"}}
                  editClick={id => this.handleEditClick(id)}
                  deleteClick={id => this.handleDeleteClick(id)}
                  pagination={{ position: 'none' }}
                  statusChange={id => this.handleStatusChange(id)}
                  questionnare={id => this.handlequestionnare(id)}
                  openSubmittedSurveys={id => this.handleSubmittedSurveys(id)}
                />
    }
              </Col>
            </Row>
          </Spin>
          {this.state.showFormModal &&
            this.renderFormModal()
          }
          {this.state.showQuestionModal &&
            this.renderQuestionModal()
          }
          { this.state.showSubmittedSurveys &&
            this.renderSubmittedSurveys()
          }
        </div>
      </div>
    );
  }
}

Surveys.propTypes = {
  history: PropTypes.shape().isRequired,
};
export default Surveys;
