/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Col, Row,Select } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import ModuleActions from "../../containers/ModuleActions";
import { fetchRolesList, fetchSingleRole } from "../../api/RolesApi";
import BasePageHeader from "../common/PageHeader";
import { withRouter } from "react-router";
import userStore from "../../stores/UserStore";
import { renderAlertMessage } from "../../helpers/common";

class RolesActionMapping extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inProgress: false,
			rolesList: [],
			currentRole: "",
		};
	}

	componentDidMount() {
		// get role_id from url
		const { match } = this.props;
		const { roleId } = match.params;
		this.getOrgRoles(roleId);
		fetchSingleRole(roleId)
			.then((result) => {
				if (result.success) {
					const data = result.data || {};
					this.setState({
						currentRole: data.role_code,
					});
				} else {
					renderAlertMessage(result.errors)
				}
			})
			.finally(() => {
				this.setState({
					inProgress: false,
				});
			});
	}

	getOrgRoles = () => {
		this.setState({ inProgress: true });
		let orgId = userStore.getStateValue("selectedOrg")
		fetchRolesList({ org_id: orgId})
			.then((result) => {
				if (result.success) {
					const roles = result.data || [];
					this.setState({
						rolesList: roles,
					});
				}
			})
			.finally(() => {
				this.setState({ inProgress: false });
			});
	};

	handleChange = (element, value) => {
		this.setState({
			[element]: value,
		});
	};

	_renderSearchOptions = () => (
		<Row className="marginTop10" gutter={8}>
			<Col xs={16} />
			<Col xs={8} className="alignRight textBold text-capitalize">
				{I18n.t("general.role")}&nbsp;:&nbsp;
				<Select
					showSearch
					onChange={(e) => this.handleChange("currentRole", e)}
					filterOption={(input, option) =>
						option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
						0
					}
					style={{ width: "90%" }}
					value={this.state.currentRole}
					loading={this.state.inProgress}
				>
					{this.state.rolesList.map((role) => (
						<Select.Option key={role} value={role.role_code} className="text-capitalize">
							{role.role_name.replace("_", " ")}
						</Select.Option>
					))}
				</Select>
			</Col>
		</Row>
	);

	_renderConfigs = () => (
		<div>
			<Row className="marginTop10">
				<Col className="padding10">
					<Fragment>
						<ModuleActions currentRole={this.state.currentRole} />
					</Fragment>
				</Col>
			</Row>
		</div>
	);

	_renderHeader = () => {
		return (
			<BasePageHeader
				title={I18n.t("roles.role_mapping")}
				onBack={() => {
					this.props.history.push("/roles");
				}}
				{...this.props}
			/>
			// <PageHeader
			// 	style={{
			// 		border: "1px solid rgb(235, 237, 240)",
			// 	}}
			// 	onBack={() => null}
			// 	title="Title"
			// 	subTitle="This is a subtitle"
			// />
		);
	};

	render() {
		return (
			<div className="content-outer">
				<div className="content">
					<this._renderHeader />
					<Row>
						<Col className="accessorials">
							<this._renderSearchOptions />
							<this._renderConfigs />
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
// RolesActionMapping.propTypes = {
//   account: PropTypes.shape().isRequired,
// };

export default withRouter(RolesActionMapping);
