/* eslint-disable camelcase */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Icon,
  Tooltip,
  Popover,
} from "../../common/UIComponents";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { formatWeight } from "../../helpers/orders";

const StopCapacityInfo = ({ stop, route, showDeptInfo = true }) => {
  const stopType = stop.stopType;
  return (
    <div className="table-contact-info">
      <Row className="weightInfo">
        <Col xs={24}>
          {stopType === "WH" && stop.stop_number === 1 ? (
            <Fragment>
              <Row>
                <Col xs={15} className="">
                  {/*<Icon type="shopping" />*/}
                  {I18n.t("preplan.capacity.vehicle_weight")}
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8}>{formatWeight(route.vehicle_weight)}</Col>
              </Row>
              <Row>
                <Col xs={15} className="">
                  {/*<Icon type="shopping" />*/}
                  <Tooltip title={I18n.t("preplan.capacity.max_accumable_weight")}>
                    {I18n.t("preplan.capacity.max_accumable_weight_sf")}
                  </Tooltip>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8}>{formatWeight(stop.accumulated_weight)}</Col>
              </Row>
              {stop.overloaded_weight > 0 && (
                <Row>
                  <Col xs={15} className="">
                    {/*<Icon type="shopping" />*/}
                    {I18n.t("preplan.capacity.overloaded")}
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={8}>{formatWeight(stop.overloaded_weight)}</Col>
                </Row>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col xs={15} className="">
                  {/*<Icon type="shopping" />*/}
                  { showDeptInfo ? I18n.t("preplan.capacity.current_stop_weight") : I18n.t("preplan.capacity.stop_weight")}
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8}>{formatWeight(stop.stop_weight)}</Col>
              </Row>
              {showDeptInfo && stopType === "CS" && (
                <Row>
                  <Col xs={15} className="">
                    {/*<Icon type="shopping" />*/}
                    <Tooltip title={I18n.t("preplan.capacity.dept_truck_weight")}>
                      {I18n.t("preplan.capacity.dept_truck_weight_sf")}
                    </Tooltip>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={8}>
                    {formatWeight(
                      stop.status !== "UNALLOCATED"
                        ? stop.accumulated_weight
                        : ""
                    )}
                  </Col>
                </Row>
              )}
              {stopType === "WH" && stop.overloaded_weight > 0 && (
                <Row>
                  <Col xs={15} className="">
                    {/*<Icon type="shopping" />*/}
                    <Tooltip title={I18n.t("preplan.capacity.overloaded_weight")}>
                       {I18n.t("preplan.capacity.overloaded")}
                    </Tooltip>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={8}>
                    {formatWeight(
                      stop.overloaded_weight
                    )}
                  </Col>
                </Row>
              )}

              {/* {stop.status !== "UNALLOCATED" && (
                      <Row>
                        <Col xs={15} className="">
                         
                          {I18n.t("preplan.capacity.overloaded")}
                        </Col>
                        <Col xs={1}>:</Col>
                        <Col xs={8}>{formatWeight(stop.overloaded_weight)}</Col>
                      </Row>
                  )} */}
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
  // return (
  //   <Popover
  //     title={<Fragment>{I18n.t("preplan.capacity.info")}</Fragment>}
  //     content={
  //       <div className="table-contact-info">
  //         <Row className="distanceInfo">
  //           <Col xs={24}>
  //             {stopType === "WH" && stop.stop_number === 1 ? (
  //               <Fragment>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;{I18n.t("preplan.capacity.vehicle_weight")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatWeight(route.vehicle_weight)}</Col>
  //                 </Row>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;
  //                     {I18n.t("preplan.capacity.max_accumable_weight")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatWeight(stop.accumulated_weight)}</Col>
  //                 </Row>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;{I18n.t("preplan.capacity.overloaded")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatWeight(stop.overloaded_weight)}</Col>
  //                 </Row>
  //               </Fragment>
  //             ) : (
  //               <Fragment>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;{I18n.t("preplan.capacity.stop_order_weight")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatWeight(stop.stop_weight)}</Col>
  //                 </Row>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;
  //                     {I18n.t("preplan.capacity.departure_truck_weight")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>
  //                     {formatWeight(
  //                       stop.status !== "UNALLOCATED"
  //                         ? stop.accumulated_weight
  //                         : ""
  //                     )}
  //                   </Col>
  //                 </Row>
  //                 {stop.status !== "UNALLOCATED" && (
  //                   <Row>
  //                     {" "}
  //                     <Col xs={12}>
  //                       <Icon type="shopping" />
  //                       &nbsp;&nbsp;{I18n.t("preplan.capacity.overloaded")}
  //                     </Col>
  //                     <Col xs={1}>:</Col>
  //                     <Col xs={11}>{formatWeight(stop.overloaded_weight)}</Col>
  //                   </Row>
  //                 )}
  //               </Fragment>
  //             )}
  //           </Col>
  //         </Row>
  //       </div>
  //     }
  //   >
  //     <span className="textBold">{I18n.t("truck.capacity")}</span>:{" "}
  //     <Tooltip
  //       title={I18n.t("preplan.capacity.accumilated")}
  //       placement="bottom"
  //     >
  //       {formatWeight(stop.stop_weight)}
  //       {stopType === "WH" &&
  //         stop.stop_number === 1 &&
  //         !isEmpty(stop.overloaded_weight) && (
  //           <Fragment>
  //             (<span className="textRed">{stop.overloaded_weight}</span>)
  //           </Fragment>
  //         )}
  //     </Tooltip>
  //   </Popover>
  // );
};
export default StopCapacityInfo;

StopCapacityInfo.propTypes = {
  stop: PropTypes.shape().isRequired,
};
