import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Popconfirm, Select, Tabs } from "antd";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import { validateEmailList } from "./helpers";
import {
  Icon,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Col,
  Button,
  Spin,
  Input,
  TextArea,
  FormItem,
  Link,
} from "../../common/UIComponents";

import {
  alertMessage,
  base64ToPdf,
  isEmpty,
  randomNumber,
} from "../../common/Common";
import I18n from "../../common/I18n";
import BaseModal from "../BaseModal";
import AppConfig from "../../config/AppConfig";
import {
  fetchConsolidatedInvoicePdf,
  fetchOrderInvoicePdf,
  getContactEmails,
  SendInvoiceEmail,
  SendInvoiceSummaryEmail,
} from "../../api/Billing";
import userStore from "../../stores/UserStore";
import { OrgContext } from "../../context/OrgContext";

const SendInvoiceMail = (props) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [invoiceEmails, setInvoiceEmails] = useState([]);
  const [isSendingMail, setIsSendingMail] = useState(false);
  const { orgSettings: organizationSettings, currentOrg } =
    useContext(OrgContext);

  const {
    contacts,
    account,
    isEmailLoading,
    invoice_no,
    exportAs,
    emailSubject,
    emailDescription,
    isDisabled,
  } = props;

  useEffect(() => {
    setDescription(emailDescription);
    setSubject(emailSubject);
  }, [emailSubject, emailDescription]);

  const addInvoiceEmail = (value) => {
    const validEmails = validateEmailList(value);
    setInvoiceEmails(validEmails);
  };

  const handleIconClick = () => {
    const { invoice_body_text } = organizationSettings;
    setShowEmailModal(true);
  };

  const handleSendingMails = () => {
    if (!invoiceEmails.length)
      return alertMessage("Please enter atleast one email address", "error");
    if (isEmpty(subject))
      return alertMessage("Please enter Subject of email", "error");
    if (isEmpty(description))
      return alertMessage("Please enter Description of email", "error");

    const payload = {
      invoice_number: invoice_no,
      account_id: account,
      organization_id: userStore.getStateValue("selectedOrg"),
      export_as: exportAs,
      email_ids: invoiceEmails,
      subject: subject,
      description: description,
    };
    setIsSendingMail(true);
    SendInvoiceSummaryEmail(payload)
      .then((result) => {
        if (result.success) {
          alertMessage(result.message, "success", 5);
          setIsSendingMail(false);
        } else {
          alertMessage(result.message, "error", 5);
        }
      })
      .finally(() => {
        setIsSendingMail(false);
      });
  };

  const renderEmailModal = () => {
    return (
      <BaseModal
        title={I18n.t("general.send_to", {
          from: I18n.t("general.invoice"),
          to: "Emails",
        })}
        onCancel={() => {
          setShowEmailModal(false);
          setInvoiceEmails([]);
        }}
        style={{ top: 40 }}
        maskClosable={false}
      >
        <Spin spinning={isEmailLoading || isSendingMail}>
          <Row className="tagsInput emailForm">
            <Col>
              <FormItem label={I18n.t("general.email")}>
                <Select
                  value={invoiceEmails}
                  onChange={addInvoiceEmail}
                  mode="tags"
                  validationRegex={addInvoiceEmail}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  tokenSeparators={[","]}
                  placeholder="Enter or select Emails"
                >
                  {contacts.map((opt) => (
                    <Option key={opt}>{opt}</Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={I18n.t("general.subject")}>
                <Input
                  rows={4}
                  placeholder={I18n.t("general.subject")}
                  className="sop-form-textArea"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem label={I18n.t("general.description")}>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  rows={4}
                  placeholder={I18n.t("general.description")}
                  className="sop-form-textArea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col className="alignCenter">
              <Popconfirm
                placement="topRight"
                title={I18n.t("messages.confirm")}
                onConfirm={handleSendingMails}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="danger"
                  onClick={() => {
                    setShowEmailModal(false);
                    setInvoiceEmails([]);
                  }}
                  size="small"
                  icon="close"
                  style={{ marginRight: 5 }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  loading={isSendingMail}
                  size="small"
                  icon="export"
                >
                  Send
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Spin>
      </BaseModal>
    );
  };
  return (
    <Fragment>
      <Button
        type="primary"
        icon="mail"
        onClick={handleIconClick}
        size="small"
        style={{
          backgroundColor: isDisabled ? "#d9d9d9" : "#1890ff",
          borderColor: isDisabled ? "#d9d9d9" : "#1890ff",
          color: isDisabled ? "#a0a0a0" : "#fff",
        }}
        disabled={isDisabled}
      >
        Send Mail
      </Button>
      {showEmailModal && renderEmailModal()}
    </Fragment>
  );
};
SendInvoiceMail.propTypes = {
  isDisabled: PropTypes.bool,
};
SendInvoiceMail.defaultProps = {
  emailSubject: "",
  emailDescription: "",
  isDisabled: false,
};
export default SendInvoiceMail;
