/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import { Col, FormItem, Input, Row, Popconfirm, Icon, Tooltip } from '../../../common/UIComponents';
import { alertMessage, isEmpty, randomNumber, customPasteSplit } from '../../../common/Common';
import FormButtons from '../../common/FormButtons';
import FormErrors from '../../common/FormErrors';
import userStore from '../../../stores/UserStore';
import { checkServiceExistance } from '../../../helpers/common';
import DeleteIcon from '../../common/DeleteIcon';
import I18n from '../../../common/I18n';


class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: this.props.zones ? this.props.zones : [],
      inProgress: false,
      errors: [],
    };
  }


  clearForm = () => {
  }

  handleOnChange = (index, element, value, changed, changedIndex) => {
    if (element === 'zip_codes') {
      let intersectedZones = [];
      const errors = [];
      const duplicatedZipCodes = [];
      if (this.props.checkDuplicates === true) {
        if (changed.length > 0) {
          changed.forEach((pincode) => {
            intersectedZones = [];
            intersectedZones = this.state.zones.filter((zone, zoneIndex) => zoneIndex !== index && zone.zip_codes.includes(pincode));
            if (intersectedZones.length > 0) {
              duplicatedZipCodes.push(pincode);
              errors.push(`Zip code ${pincode} already in zone '${intersectedZones.map(zone => zone.name).join(',')}'`);
            }
          });
        }
      }
      if (errors.length > 0) {
        this.setState({
          errors,
        });
        const excludedZipCodes = _.difference(value, duplicatedZipCodes);
        this.props.onChange(index, element, excludedZipCodes);
      } else {
        this.setState({
          errors,
        });
        this.props.onChange(index, element, value);
      }
    } else {
      this.props.onChange(index, element, value);
    }
  }

  handleSave = () => {
    const zones = [...this.state.zones];
    const errors = [];
    zones.forEach((zone, index) => {
      if (isEmpty(zone.name)) {
        errors.push(`Name in zone ${index + 1} is required`);
      } else {
        const matchedRecords = this.state.zones.filter((currentRecord, currentIndex) => index < currentIndex).filter((record, recIndex) => zone.name === record.name);
        if (matchedRecords.length > 0) {
          errors.push(` zone ${index + 1} ${zone.name} already exists `);
        }
      }
      if (zone.zip_codes.length === 0) {
        errors.push(` zone ${index + 1} zip codes are required `);
      }
    });
    if (errors.length > 0) {
      this.setState({
        errors,
      });
    } else {
      this.setState({
        errors: [],
      });
      this.props.handleSave();
    }
  }


  static getDerivedStateFromProps(props, state) {
    if (
      _.isArray(props.zones) && !_.isEqual(props.zones, state.zones)
    ) {
      const data = {};
      data.zones = props.zones;
      return data;
    }
    return {};
  }


  render() {
    return (
      <div className="billing">
        <Row gutter={16} className="marginTop10">
          <Col xs={24} >
            <Row gutter={16} className="marginTop10 textBold">
              <Col xs={6} className="alignCenter">
                {I18n.t('general.name')}
              </Col>
              <Col xs={13} className="alignCenter">
                {`${I18n.t('general.zipcode')}s`}
              </Col>
            </Row>
            <Row gutter={16} className="marginTop10">
              {
                this.state.zones.map((zone, index) => (
                  <Row className="marginTop10" gutter={16} key={`zone${index}`}>
                    <Col xs={6}>
                      <div>
                        <Input
                          value={zone.name}
                          onChange={e => this.handleOnChange(index, 'name', e.target.value)}
                          placeholder={I18n.t('general.name')}
                          disabled={!checkServiceExistance('BZC')}
                        />
                      </div>
                    </Col>
                    <Col xs={16} className="tagsInput">
                      <TagsInput
                        value={zone.zip_codes || []}
                        onChange={(e, changed, changedIndex) => this.handleOnChange(index, 'zip_codes', e, changed, changedIndex)}
                        validationRegex={/^(?=[0-9]*$)(?:.{3}|.{5})$/}
                        addKeys={[9, 13, 32]}
                        inputProps={{
                          placeholder: 'Add a zipcode',
                        }}
                        onlyUnique
                        addOnPaste
                        pasteSplit={customPasteSplit}
                        disabled={!checkServiceExistance('BZC')}
                        addOnBlur={ true }
                      />
                    </Col>
                    {checkServiceExistance('BZD') && <Col xs={1}>
                      <Popconfirm
                        placement="left"
                        title={I18n.t('messages.delete_confirm')}
                        onConfirm={() => this.props.handleDelete(index, zone.id, zone.isNew)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title={I18n.t("general.delete")}>
                        <DeleteIcon/>
                        &nbsp;&nbsp;&nbsp;
                        </Tooltip>
                      </Popconfirm>
                    </Col>
                    }
                  </Row>
                ))
              }
              <Col xs={24}>
                {checkServiceExistance('BZC') &&
                FormButtons(
                  this.state.inProgress,
                  this.handleSave,
                  this.clearForm,
                  false, true, 'default',
                  I18n.t('general.save'),
                  I18n.t('general.clear'),
                )}
              </Col>
            </Row>
            <Row>
              {FormErrors(this.state.errors)}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

Form.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  account: PropTypes.shape().isRequired,
  checkDuplicates: PropTypes.bool,
};

Form.defaultProps = {
  checkDuplicates: false,
};

export default Form;
