import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Col, Row } from '../../common/UIComponents';
import InfoTask from './InfoTask';
import moment from 'moment';
import I18n from '../../common/I18n';
import {calculateTimeDuration} from '../tracking/Utils';

class RouteInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeInfo: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const routeInfo = props.routeInfo;
    return ({
      routeInfo,
    });
  }

  render() {
    const route = this.state.routeInfo;
    const sortedTasks = _.sortBy(route.tasks, [function (o) { return o.t_s_no; }]);
    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="info-text">
              <h4>{I18n.t('general.name')}</h4>
              {route.r_name ?
                (route.r_name) : 'NA' }
            </div>
          </Col>
          <Col xs={12}>
            <div className="info-text">
              <h4>{I18n.t('general.status')} </h4>
              {route.r_status ?
                (route.r_status) : 'NA'}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="info-text">
              <h4> {I18n.t('info.start_date')}</h4>
              {route.r_start_datetime ?
                (moment(new Date(route.r_start_datetime)).format('LLL')) : 'NA'
              }
            </div>
          </Col>
          <Col xs={12}>
            <div className="info-text">
              <h4> {I18n.t('info.end_date')} </h4>
              {route.r_end_datetime ?
                (moment(new Date(route.r_end_datetime)).format('LLL')) : 'NA'
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="info-text">
              <h4>{I18n.t('info.duration')}</h4>
              { calculateTimeDuration(route.r_start_datetime, route.r_end_datetime) }
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="info-driver-details">
          {route.driver ?
            <Row>
              <Col xs={6}>
                <div className="driver-profile">
                  {route.driver.image.url ?
                    <img src={route.driver.image.url} alt="DriverProfile" />
                    : 'Photo NA'
                  }
                </div>
              </Col>
              <Col xs={10}>
                <Col>
                  <Col>
                    <div className="info-text">
                      <h4>{I18n.t('info.driver_name')}</h4>
                      {route.driver.full_name ?
                        (route.driver.full_name) : 'NA'
                      }
                    </div>
                  </Col>
                  <Col>
                    <div className="info-text">
                      <h4>{I18n.t('general.mobile_number')}</h4>
                      {route.driver.mobile_number ?
                        (route.driver.mobile_number) : 'NA'
                      }
                    </div>
                  </Col>
                </Col>
              </Col>
            </Row>
            : 'Driver Info is Not Available'
          }
        </Row>
        <Row>
          <h2 className="task_head">{I18n.t('info.task')}</h2>
          <Fragment>
            {
              sortedTasks.map((task, index) => (<InfoTask key={task.id} task={task} cardNumber={index + 1} />))
            }
          </Fragment>
        </Row>
      </div>
    );
  }
}

export default RouteInfo;
