/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { useEffect, Fragment, useState } from "react";
import _ from "lodash";
import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import moment from "moment";
import {
  DatePicker,
  Select,
  Row,
  Col,
  Icon,
  Spin,
  FormItem,
  Tooltip,
  Popconfirm,
  Button,
  Divider,
  Statistic,
} from "../../../common/UIComponents";
import {
  alertMessage,
  isEmpty,
  randomNumber,
  checkNegitive,
} from "../../../common/Common";
import userStore from "../../../stores/UserStore";
import { WeeklyReportApi } from "../../../api/WeeklyReportApi";
import DriversInvoicesList from "../DriversInvoicesList";
import AppConfig from "../../../config/AppConfig";
import BulkOperations from "../../billing/BulkOperations";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import I18n from "../../../common/I18n";
import SendInvoice from "../../billing_screen/SendInvoice";

moment.locale("en-gb", {
  week: {
    dow: 1, /// Date offset
  },
});

const { Option } = Select;
const { WeekPicker } = DatePicker;
let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} }
const DriverInvoiceReports = (props) => {
  const {tabKey} = props;
  const [reports, setReports] = useState([]);
  const [filterData, setFilterData] = useState({ fromDate: null, toDate: null });
  const [isLoading, setIsLoading] = useState(false);
  const [statsData, setStatsData] = useState({
    pending: 0,
    approved: 0,
  });
  const [selectedInfo, setSelectedInfo] = useState({
    keys: [],
    rows: [],
  });
  const [currentStatus, setCurrentStats] = useState("PENDING");
  const [recordsPerPage, setRecordsPerPage] = useState(AppConfig.perPage)
  const [pagination, setPagination] = useState({ current_page: 1 })

  // to get details
  useEffect(() => {
    tableOptions.pagination.current = 1;
    getReports();
  }, [filterData, currentStatus, recordsPerPage, tabKey]); //to get details

  const changeStatus = (status) => {
    setIsLoading(true);
    WeeklyReportApi.changeStatus({
      status,
      driver_pay_invoice_ids: selectedInfo.keys ? selectedInfo.keys.join(',')  :'',
    })
      .then((response) => {
        if (response.success) {
          getReports();
          handleSelectChange([], [])
          alertMessage(I18n.t("messages.saved"), "success", 5);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  }

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    tableOptions = { pagination, filters, sorter }
    getReports();
  }
  

  const getReports = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    setIsLoading(true);
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1
    const { fromDate, toDate } = filterData;
    const payLoad = {
      start_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") :'',
      end_date: toDate ? moment(toDate).format("YYYY-MM-DD") : '',
      organization_id: orgId,
      status: currentStatus,
      page,
      per_page: recordsPerPage,
      // warehouse_ids: "60251b73822dd6ade7e3301c",
    };
    WeeklyReportApi.fetchInvoices(payLoad)
      .then((response) => {
        if (response.success) {
          const data = response.data || {};
          const reports = data.driver_pay_invoices || [];
          setStatsData({
            pending: data.pending_count || 0,
            approved: data.approved_count || 0,
          })
          setPagination(response?.pagination ? response?.pagination : { current: 1 })
          setReports([...reports]);
        } else {
          alertMessage(response.errors.join(","), "error", 10);
        }
      })
      .catch((error) => {
        alertMessage(error, "error", 10);
        // handle error response
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (element, value) => {
    setFilterData({ ...filterData, [element]: value });
  };
  const  handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[ 0 ] !== '' && dateStrings[ 1 ] !== '')) {
      dateFilter =  { fromDate:  null, toDate: null };
    } else {
      dateFilter =  { fromDate: dates[ 0 ], toDate: dates[ 1 ]};
    }
    setFilterData({ ...filterData, ...dateFilter});
  };

  const renderSearch = () => {
    return (
      <Fragment>
        <span className="textBold"></span>
        {`Select ${I18n.t("general.date")}  `}
        {/* <DatePicker.RangePicker
          size="small"
          style={{ width: 200 }}
          placeholder="Select Date"
          onChange={(date, dateString) =>
            handleChange("weekPeriodString", date)
          }
          value={filterData.weekPeriodString}
        /> */}
        <DatePicker.RangePicker
              defaultValue={ [ moment().startOf('month'), moment() ] }
              value={ [
                filterData.fromDate,
                filterData.toDate,
              ] }
              ranges={AppConfig.dateRanges}
              format={ AppConfig.dateFormat }
              style={ { width: 230 } }
              onChange={ (dates, dateStrings) =>
                handleRangeChange(dates, dateStrings)
              }
            />
        {/* <LocaleProvider locale={locales.de_DE}></LocaleProvider>/LocaleProvider> */}
        &nbsp;&nbsp;
        <SendInvoice/>
      </Fragment>
    );
  };

  const handleSelectChange = (keys, rows) => {
    setSelectedInfo({ keys, rows });
  };

  const renderList = () => {
    return (
      <Fragment>
        <Row gutter={16} className="fontSize12">
          <Col span={18} offset={3}>
            <DriversInvoicesList
              scroll = {{y:"calc(100vh - 320px)"}}
              tableLayout="fixed"
              data={reports}
              size="small"
              // style={{ width: 670 }}

              pagination={tablePagination}
              // rowSelection={{
              //   selectedRowKeys: selectedInfo.keys || [],
              //   onChange: handleSelectChange,
              // }}
              tableChange={(pagination, filter, sorter, currentTable) =>
                onTableChange(pagination, filter, sorter, currentTable)
                
              }
            />
          </Col>
        </Row>
        {selectedInfo?.keys?.length > 0 && (
          <Row className="bottom_fixed">
            <Col
              xs={24}
              style={{ padding: "10px 10px" }}
              className="alignCenter"
            >
              <Button
                type="danger"
                icon="close"
                onClick={() => handleSelectChange([], [])}
              >
                Cancel
              </Button>
              &nbsp;&nbsp; &nbsp;&nbsp;
              {currentStatus === "PENDING" ? (
                <Button
                  type="primary"
                  icon="file-done"
                  onClick={() => changeStatus("APPROVED")}
                >
                  Approve
                </Button>
              ) : (
                <Button
                  type="primary"
                  icon="file-done"
                  onClick={() => changeStatus("PENDING")}
                >
                  Pending
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Fragment>
    );
  };

  const _renderStats = () => {
    // const { currentStatus } = this.state.filterPlaceHolder;
    return (
      <Row className="orderStats">
        {AppConfig.invoiceStatus.map((record) => (
          <Col
            span={12}
            key={record.key}
            onClick={() => setCurrentStats(record.key)}
            className="routeLegends"
          >
            <Statistic
              title={record.label}
              className={currentStatus === record.key && "active"}
              value={statsData ? statsData[record.key.toLowerCase()] : 0}
              prefix={<Icon type={record.icon} className={record.className} />}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const recordChange = (value) => {
    if (!value) {
      value = AppConfig.ordersPerPage;
    }
    tableOptions.pagination.current = 1;  
    setRecordsPerPage(value);
  };


  return (
    <div>
      {/* <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Col span={6}>{_renderStats()}</Col>
      </Row> */}

      <Spin spinning={isLoading}>
        <Row>
          <Col span={3} />
          <Col span={15} className="textBold">{renderSearch()}</Col>
          <Col span={4} className="alignRight">
              <RecordsPerPage
              onChange={recordChange}
              onSearch={() => {}}
              value={recordsPerPage}
              defaultValue={recordsPerPage}
              />
          </Col>
        </Row>
        <Divider style={{ margin: "10px 0px" }} />
        {renderList()}
      </Spin>
    </div>
  );
};

export default DriverInvoiceReports;
