import { Col, Popconfirm, Row, Tooltip, Popover } from "antd";
import React from "react";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { checkNegitive, isEmpty } from "../../common/Common";
import CopyableContent from "../common/CopyableContent";
import AppConfig from "../../config/AppConfig";
import { momentTime } from "../../helpers/date_functions";

class TransactionsCheckList extends BaseList {
  constructor(props) {
    super(props);
    const columns = [
      {
        key: "check_ach_number",
        title: <b>#{I18n.t("payment.check_number")}</b>,
        dataIndex: "check_ach_number",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 130,
        className: "noWrap",
      },
      {
        key: "account_invoice_number",
        title: <b>#{I18n.t("batch_management.account_invoice_number")}</b>,
        dataIndex: "account_invoice_number",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 140,
        align: "left",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "customer_order_number",
        title: <b>#{I18n.t("batch_management.customer_order_number")}</b>,
        dataIndex: "customer_order_number",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 130,
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "hawb",
        title: <b>{I18n.t("general.hawb")}</b>,
        dataIndex: "hawb",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 140,
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "mawb",
        title: <b>{I18n.t("general.mawb")}</b>,
        dataIndex: "mawb",
        render: (text, record) => <CopyableContent text={text} showTooltip />,
        width: 140,
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "account_invoice_amount",
        title: <b>{I18n.t("aging.invoice_total")} ($)</b>,
        dataIndex: "account_invoice_amount",
        render: (text, record) => (
          <span>{isEmpty(record?.remark) ? checkNegitive(text) : ""}</span>
        ),
        width: 120,
        align: "right",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "customer_order_amount",
        title: <b>{I18n.t("account_receivables.job_total")} </b>,
        dataIndex: "customer_order_amount",
        render: (text, record) => (
          <span>{isEmpty(record?.remark) ? checkNegitive(text) : ""}</span>
        ),
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "debited_amount",
        title: <b>{I18n.t("account_receivables.debited_amount")} </b>,
        dataIndex: "check_transaction_amount",
        render: (text, record) => {
          const data = Math.abs(text);
          return (
            <span>
              {record?.transaction_type === "DEBIT" ? checkNegitive(data) : ""}
            </span>
          );
        },
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "credited_amount",
        title: <b>{I18n.t("account_receivables.credited_amount")} </b>,
        dataIndex: "check_transaction_amount",
        render: (text, record) => {
          return (
            <span>
              {record?.transaction_type === "CREDIT" ? checkNegitive(text) : ""}
            </span>
          );
        },
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "customer_order_balance_amount",
        title: <b>{I18n.t("account_receivables.job_balance")} </b>,
        dataIndex: "customer_order_balance_amount",
        render: (text, record) => (
          <span>{isEmpty(record?.remark) ? checkNegitive(text) : ""}</span>
        ),
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "account_invoice_balance_amount",
        title: <b>{I18n.t("account_receivables.invoice_balance_amount")} </b>,
        dataIndex: "account_invoice_balance_amount",
        render: (text, record) => (
          <span>{isEmpty(record?.remark) ? checkNegitive(text) : ""}</span>
        ),
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "check_received_amount",
        title: <b>{I18n.t("account_receivables.check_received")} </b>,
        dataIndex: "check_received_amount",
        render: (text, record) => <span>{checkNegitive(text)}</span>,
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "check_balance_amount",
        title: <b>{I18n.t("account_receivables.check_balance")} </b>,
        dataIndex: "check_balance_amount",
        render: (text, record) => <span>{checkNegitive(text)}</span>,
        width: 160,
        align: "right",
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "created_by_user_name",
        title: <b>{I18n.t("general.user_name")}</b>,
        dataIndex: "created_by_user_name",
        render: (text, record) => (text ? text : ""),
        className: "noWrap",
        // ...AppConfig.sortingOptions,
      },
      {
        key: "created_at",
        title: I18n.t("batch_payments.paid_at"),
        dataIndex: "created_at",
        render: (text, data) =>
          text ? momentTime(AppConfig.dateFormat, text) : "",
        className: "paddingLeft5",
        width: 100,
      },
      {
        key: "remark",
        title: <b>{I18n.t("deductions.remark")}</b>,
        dataIndex: "remark",
        render: (text, record) => {
          return text ? text : "";
        },
        width: 200,
        // ...AppConfig.sortingOptions,
      },
    ];
    if (this.props?.hideColumns?.length > 0) {
      this.columns = columns.filter(
        (col) => !this.props.hideColumns.includes(col.key)
      );
    } else {
      this.columns = [...columns];
    }
  }
}
export default TransactionsCheckList;
