import { ApiUrl, Call, PageParams, ParseGeneralResponse } from "./ApiUtils"

export const CapacityReportApi = {
    fetch: (
        page = 1,
        perPage = "",
        orgId = "",
        whId = "",
        fromDate = "",
        toDate = "",
        exportFile = false,
        driver = ""
    ) => {
        let url = ApiUrl(`v2/driver_log_reports/driver_vehicle_report?${PageParams(page,perPage)}&org_id=${orgId}&warehouse_ids=${whId}&start_date_time=${fromDate}&end_date_time=${toDate}&as_export=${exportFile}&driver_id=${driver}`);
        return Call("get", url, {},ParseGeneralResponse, {
            responseDataKeys : {data: "", pagination: "meta.pagination"}
        })
    }
}