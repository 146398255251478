import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import { Col, Icon, Popconfirm, Row, Tooltip } from "antd";
import Copyable from "../common/Copyable";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
class DmsProcessedList extends BaseList {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "customer_order_number",
                title: I18n.t("dms.order_number"),
                dataIndex: "customer_order_number",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                render: (text, record) => (
                  <div>
                    <CustomerOrderDetails
                      order_no={text}
                      order={{
                        id: record.customer_order_id,
                        customer_order_number: text,
                      }}
                      showEdit={false}
                    />
                  </div>
                ),
              },
              {
                key: "hawb",
                title: I18n.t("dms.hawb"),
                dataIndex: "hawb",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                render: (data, record) => (
                  <OrderFieldDisplay
                    data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
                    toDisplay="hawb"
                    showTriggerEdit={false}
                    showEditIcon={false}
                  />
                ),
              },
              {
                key: "mawb",
                title: I18n.t("dms.mawb"),
                dataIndex: "mawb",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
                render: (data, record) => (
                  <OrderFieldDisplay
                    data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
                    toDisplay="mawb"
                    showTriggerEdit={false}
                    showEditIcon={false}
                  />
                ),
                width: "20%",
              },
              {
                key: "pod",
              
                title: I18n.t("dms.pod"),
                dataIndex: "POD",
                render: (data, record) => <span>{data}</span>,
                width: "10%",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
              },
              {
                key: "bol",
                title: I18n.t("dms.bol"),
                dataIndex: "BOL",
                render: (data, record) => <span>{data}</span>,
                width: "20%",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
              },
              {
                key: "user_manual",
                title: I18n.t("dms.user_manual"),
                dataIndex: "user_manual",
                render: (data, record) => <span>{data}</span>,
                width: "10%",
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"],
              },
            //   {
            //     key: "unprocessed",
            //     title: <b>{I18n.t("dms.unprocessed")}</b>,
            //     dataIndex: "unprocessed",
            //     render: (data, record) => <span>{data}</span>,
            //     width: "20%",
            //   },
        ]
    }
}
export default DmsProcessedList;