import { ApiUrl, Call, ParseGeneralResponse, GenerateURL } from './ApiUtils';

export function fetchTrackDetails(id) {
  const url = ApiUrl(`v2/nav_routes/${id}/route_tracks`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { routeTracks: 'nav_route_tracks' } });
}
export function fetchCustomerDetails(id, visibleTo = 'DRIVER') {
  const url = GenerateURL(`tracking/details?customer_order_id=${id}&visible_to=${visibleTo}&operation_code=TRCDET`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { nav_route_details: '' } });
}

export function fetchRouteTrackDetails(navRouteId) {
  const url = GenerateURL(`tracking/track_route?nav_route_id=${navRouteId}&operation_code=TRCROUT`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { routeTracks: '' } });
}

export default {
  fetchTrackDetails, fetchCustomerDetails, fetchRouteTrackDetails,
};

