import { isEmpty } from '../common/Common'
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from './ApiUtils'

//  store the functions related to account and org level instructions
export const QuotesApis = {
  fetch: (isPagingEnabled, page = 1, perPage, token, payload) => {
    let params = ''
    if (token !== '') {
      params = `&search_quote_no=${token}`
    } else {
      const parameter = Object.keys(payload).map(
        (key) => `${key}=${!isEmpty(payload[key]) ? payload[key] : ''}`,
      )
      params = parameter ? `&${parameter.join('&')}` : ''
    }

    if (isPagingEnabled) {
      const url = ApiUrl(
        `v2/quote_orders?${PageParams(
          page,
          perPage,
        )}${params}&operation_code=QI`,
      )
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          quote_orders: 'quote_orders',
          pagination: 'meta.pagination',
        },
      })
    } else {
      const url = ApiUrl(
        `v2/quote_orders?page=1&per_page=all${params}&operation_code=QI`,
      )
      return Call('get', url, '', ParseGeneralResponse, {
        responseDataKeys: {
          quote_orders: 'quote_orders',
          pagination: 'meta.pagination',
        },
      })
    }
  },
  fetchone: (id) => {
    const url = ApiUrl(`v2/quote_orders/${id}?operation_code=QI`)
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  fetchOneRecord: (payload) => {
    let params = ''
    const parameter = Object.keys(payload).map(
      (key) => `${key}=${!isEmpty(payload[key]) ? payload[key] : ''}`,
    )
    params = parameter ? `&${parameter.join('&')}` : '';
    const url = ApiUrl(
      `v2/quote_orders?page=1&per_page=all${params}&operation_code=QI`,
    )
    return Call('get', url, '', ParseGeneralResponse, {
      responseDataKeys: {
        quote_orders: 'quote_orders',
        pagination: 'meta.pagination',
      },
    })
  },
  update: (id, payload) => {
    payload.operation_code = 'QU'
    const url = ApiUrl('v2/quote_orders/' + id)
    const formData = (payload)
    return Call('put', url, formData, ParseGeneralResponse, {
      responseDataKeys: { quote: 'quote' },
    })
  },
  changeStatus: (payload) => {
    payload.operation_code = 'QOCS'
    const url = ApiUrl('v2/quote_orders/change_status')
    return Call('put', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  create: (payload) => {
    payload.operation_code = 'QC'
    const url = ApiUrl('v2/quote_orders')
    const formData = (payload)
    return Call('post', url, formData, ParseGeneralResponse, {
      responseDataKeys: { quote: 'quote' },
    })
  },
  delete: (id) => {
    const url = ApiUrl('v2/quote_orders/' + id)
    return Call('delete', url, { operation_code: 'QD' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  saveQuoteAccessorials: (data) => {
    data.operation_code = 'QOAA'
    const url = ApiUrl('v2/quote_orders/quote_order_accessorial')
    return Call('PUT', url, data, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  createOrderItem: (payload) => {
    const url = ApiUrl('v2/quote_orders/create_quote_order_items')
    return Call('put', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: 'order_item' },
    })
  },
  delete_quote_item: (id,activeRecordId) => {
    const url = ApiUrl('v2/quote_orders/delete_quote_item')
    return Call('delete', url, { operation_code: 'QD' , item_id : id, quote_id:activeRecordId }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  generateQuote: (id) => {
    const data = {
      quote_id: id,
      operation_code: 'QG',
    }
    const url = ApiUrl('v2/quote_orders/calculate_quote_amount')
    return Call('PUT', url, data, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  changeQuoteAmount: (payload) => {
    const url = ApiUrl('v2/quote_orders/altered_quote_amount')
    return Call('PUT', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  emailQuote: (id, payload) => {
    payload.operation_code = 'QEMAIL'
    const url = ApiUrl(`v2/quote_orders/${id}/email_quote`)
    return Call('POST', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  downloadQuote: (id) => {
    const url = ApiUrl(`v2/quote_orders/${id}/download_quote`)
    return Call('get', url, { operation_code: 'QDWNLD'}, ParseGeneralResponse, {
      responseDataKeys: {
        quote_file: 'quote_file',
      },
    })
  },
  quoteToOrder: (id) => {
    const url = ApiUrl(`v2/quote_orders/approve_quote_order_to_order?quote_id=${id}`)
    return Call('PUT', url, { quote_id: id, operation_code: 'QTO'}, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  QuoteCreate: (id) => {
    const data = {
      quote_id: id,
      operation_code: 'QCLO',
    }
    const url = ApiUrl(`v2/quote_orders/${id}/clone_quote`)
    return Call('post', url, data, ParseGeneralResponse, {
      responseDataKeys: { quote_order_id: 'quote_order_id' },
    })
  },
}

export const QuoteLocationApis = {
  update: (payload) => {
    payload.operation_code = 'QU'
    const url = ApiUrl('v2/quote_orders/create_quote_location')
    return Call('put', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  create: (payload) => {
    payload.operation_code = 'QC'
    const url = ApiUrl('v2/quote_orders/create_quote_location')
    return Call('put', url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
  delete: (id) => {
    const url = ApiUrl('v2/quote_orders/' + id)
    return Call('delete', url, { operation_code: 'QD' }, ParseGeneralResponse, {
      responseDataKeys: { data: '' },
    })
  },
}
