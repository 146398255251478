import React from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import { compareString, ellipseText } from "../../common/Common";
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Popover, Tooltip } from "../../common/UIComponents";
import AddressInfo from "../common/AddressInfo";
import userStore from "../../stores/UserStore";
import { checkServiceExistance } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";

class LocationsList extends BaseList {
	constructor() {
		super();
		const isSuperAdmin = userStore.superAdmin();
		this.columns = [
			{
				key: "name",
				title: I18n.t("general.name"),
				dataIndex: "name",
				render: (text) => ({
					props: {
						className: "wordWrap",
					},
					children: text,
				}),
				sorter: (a, b) => compareString(a.name, b.name),
			},
			{
				key: "code",
				title: I18n.t("organization.code"),
				dataIndex: "code",
			},
			// {
			//   key: 'address',
			//   title: I18n.t('general.address'),
			//   render: (data) => {
			//     const address = _.values(data.address).splice(1, 6).join();
			//     return (
			//       <span> {address}</span>
			//     );
			//   },
			//   width: 300,
			// },
			{
				key: "phone_number",
				title: I18n.t("organization.mobile_number"),
				dataIndex: "phone_number",
			},
			{
				key: "email",
				title: I18n.t("general.email"),
				dataIndex: "email",
			},
			{
				key: "website",
				title: I18n.t("general.website"),
				dataIndex: "website",
			},
			{
				key: "address",
				title: I18n.t("general.address"),
				render: (data) => (
					<Popover
						content={
							<div className="table-contact-info">
								<AddressInfo address={data.address} />
							</div>
						}
						title={I18n.t("general.address")}
					>
						<span>
							{" "}
							<Icon type="environment" />{" "}
						</span>
					</Popover>
				),
				width: 300,
				align: "center",
			},
			{
				key: "description",
				title: I18n.t("general.description"),
				dataIndex: "description",
				render: (data) => (
					<Tooltip title={data}>{ellipseText(data, 25)}</Tooltip>
				),
			},
		];
		// if (!isSuperAdmin) {
		// 	this.columns.push({
		// 		key: "accessorial",
		// 		title: I18n.t("general.accessorial"),
		// 		dataIndex: "id",
		// 		render: (id) => (
		// 			<Icon
		// 				type="control"
		// 				onClick={() => this.props.viewAccessorial(id)}
		// 			/>
		// 		),
		// 		align: "center",
		// 	});
		// }
		this.columns.push({
			key: "actions",
			title: "",
			dataIndex: "id",
			fixed:"right",
			render: (id) => (
				<div className="line-actions">
					{/* <Icon
              type='smile'
              onClick={() => this.props.showReviews(id)}
            /> */}
					{checkServiceExistance("ORGU") && (
						<Tooltip title={I18n.t("general.edit")}>
						<span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
						</Tooltip>
					)}
					{checkServiceExistance("ORGD") && (
						<Popconfirm
							placement="left"
							title={I18n.t("messages.delete_confirm")}
							onConfirm={() => this.props.deleteClick(id)}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip title={I18n.t("general.delete")}>
							<span><DeleteIcon/></span>
							</Tooltip>
						</Popconfirm>
					)}
				</div>
			),
			width: "95px",
		});
	}
}

export default LocationsList;
