import React, { Fragment } from "react";
import {
  Col,
  Icon,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  Button,
  Input,
} from "antd";
import I18n from "../../common/I18n";
import DeleteIcon from "../common/DeleteIcon";
import { checkNegitive } from "../../common/Common";
import DisplayTime from "../common/DisplayTime";
import CopyableContent from "../common/CopyableContent";
import AppConfig from "../../config/AppConfig";
import { formatWeight } from "../../helpers/orders";
import BaseList from "../BaseList";
import { withRouter } from "react-router";
import EndUserTitle from "../common/EndUserTitle";
import EditIcon from "../common/EditIcon";
import EditConsolidatedPrice from "./EditConsolidatedPrice";
import OrderBillingDetails from "./OrderBillingDetails";

class ConsolidationList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }
  handleConsolidationPriceChange = (record, e) => {
    const newValue = e.target.value;
    // record.actual_billing_price = newValue;
    checkNegitive(newValue, false);
    this.setState({});
  };

  setColumns = (props) => {
    const {showActions } = props
    const columns = [
      {
        key: "Move_type",
        title: <b>{I18n.t("consolidation.Move_type")}</b>,
        dataIndex: "order_type",
        render: (data, record) => (
          <span>{data ? AppConfig.orderTypeLabels[data] : ""}</span>
        ),
        width: 90,
      },
      {
        key: "order#",
        title: <b>{I18n.t("consolidation.order#")}</b>,
        dataIndex: "customer_order_number",
        render: (data, record) =>
          data ? (
           <OrderBillingDetails showActions={showActions} record={record} />
          ) : (
            ""
          ),
        width: 100,
      },
      {
        key: "HAWB",
        title: <b>{I18n.t("consolidation.HAWB")}</b>,
        dataIndex: "hawb",
        render: (data, record) =>
          data ? (
            <span className="textUpperCase">
              <CopyableContent text={data} />
            </span>
          ) : (
            ""
          ),
        width: 95,
      },
      {
        key: "MAWB",
        title: <b>{I18n.t("consolidation.MAWB")}</b>,
        dataIndex: "mawb",
        render: (data, record) =>
          data ? (
            <span className="textUpperCase">
              <CopyableContent text={data} />
            </span>
          ) : (
            ""
          ),
        width: 95,
      },
      {
        key: "Consignee",
        title: (
          <b>
            <EndUserTitle />
          </b>
        ),
        dataIndex: "consignee",
        render: (data, record) => <span>{data}</span>,
        className: "noWrap",
        width: 100,
      },
      {
        key: "Pkp/Del Date",
        title: <b>{I18n.t("consolidation.Pkp/Del_Date")}</b>,
        dataIndex: "pu_dl_date",
        render: (data, record) =>
          data ? (
            <DisplayTime
              dateTimeString={data}
              timeFormat={AppConfig.dateTimeFormat}
              displayWithDate={true}
              isUTC={true}
            />
          ) : (
            ""
          ),
        width: 140,
      },
      {
        key: "Pcs",
        title: <b>{I18n.t("consolidation.Pcs")}</b>,
        dataIndex: "pcs",
        render: (data, record) => <span>{data}</span>,
        width: 50,
      },
      {
        key: "Act_Wt",
        title: <b>{I18n.t("consolidation.Act_Wt")}</b>,
        dataIndex: "actual_wt",
        render: (data, record) => <span>{formatWeight(data)}</span>,
        width: 65,
      },
      {
        key: "dim_Wt",
        title: <b>{I18n.t("consolidation.Dim_Wt")}</b>,
        dataIndex: "dim_Wt",
        render: (data, record) => <span>{(formatWeight(record.dim_wt, true , 0))}</span>,
        width: 70,
        className: "alignRight",
      },
      // {
      //   key: "order_consolidate_price",
      //   title: <b>{I18n.t("consolidation.Dim_Wt")}</b>,
      //   dataIndex: "order_consolidate_price",
      //   render: (data, record) => <span>{checkNegitive(data)}</span>,
      //   width: 100,
      // },
      {
        key: "actual_billing_price",
        title: <b>{I18n.t("consolidation.billing_total")}</b>,
        dataIndex: "actual_billing_price",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 95,
        className: "alignRight",
      },
      {
        key: "order_consolidate_price",
        title: <b>{I18n.t("consolidation.consolidated_total")}</b>,
        dataIndex: "order_consolidate_price",
        render: (data, record) => {
          const isPrimaryOrder = record.customer_order_id === this.props.primaryId;
          const isRowSelected = this.props.selectedKeys.includes(record.customer_order_id);
      
          return (
            <div>
              {/* {isPrimaryOrder ? ( */}
                <>
                  {/* <Input
                    value={(data)}
                    onChange={(e) => this.props.changeConsolidationPrice(record, e.target.value)}
                    onBlur={(e) => this.props.changeConsolidationPrice(record, checkNegitive(e.target.value, false))}
                  />
                   */}
                </>
                <div>
                <span>
                  {checkNegitive(data)}
                  &nbsp;&nbsp; &nbsp;
                  </span>
                  {
                    showActions && isRowSelected && 
                  <EditConsolidatedPrice
                      key={record.id}
                      record={record}
                      orderId={record.customer_order_id}
                      order_number={record.customer_order_number}
                      save = {(values, updatedConsolidation) => this.props.updatePrices(record.customer_order_id, values, updatedConsolidation)}
                      
                    />
                  }
                  </div>
            </div>
          );
        },
        width: 115,
        className: "alignRight",
      },
      {
        key: "apply",
        render: (record) => {
          const isPrimaryOrder =
            record.customer_order_id === this.props.primaryId;
          return (
            <Button
              size="small"
              type="primary"
              onClick={() => props.setPrimaryOrder(record.customer_order_id)}
              className={isPrimaryOrder ? "buttonGreen" : ""}
              icon={isPrimaryOrder ? "check" : ""}
              disabled={showActions && (isPrimaryOrder || !props.selectedKeys.includes(record.customer_order_id))}
            >
              Set Primary
            </Button>
          );
        },

        width: 125,
      },
      // {
      //   key: "actions",
      //   render: (record) => (deleteClick
      //     <Fragment>
      //       <Popconfirm
      //         placement="topRight"
      //         title={I18n.t("messages.delete_confirm")}
      //         onConfirm={() => props.(record.customer_order_id)}
      //         okText="Yes"
      //         cancelText="No"
      //       >
      //         <DeleteIcon className={"cursorPointer fontSize14"}/>{" "}
      //         &nbsp;
      //       </Popconfirm>
      //     </Fragment>
      //   ),
      //   width: 50,
      // },
    ];
    this.columns =
      props.consolidation_method === "RATE_MASTER"
        ? [...columns]
        : columns.filter((col) => col.key !== "apply");
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (!_.isEqual(prevProps.data, this.props.data) || prevProps.consolidation_method !== this.props.consolidation_method) {
  //     this.columns = [];
  //     this.setColumns(this.props);
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default withRouter(ConsolidationList);
