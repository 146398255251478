import React, { Component, Fragment, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'antd/dist/antd.css';
import { fetchRouteTrackDetails } from '../api/TrackApi';
import { Col, Row, Spin, Tooltip } from '../common/UIComponents';
import Map from '../components/tracking/Map';
import Timeline from '../components/common/Timeline';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import '../../stylesheets/tracking.scss';
import AppConfig from '../config/AppConfig';
import BaseModal from '../components/BaseModal';
import Info from '../components/Routes/Info';
import { stopTypeFromObject } from '../helpers/stops';
import { contrast } from '../common/Colors';
import StopTimings from '../components/stops/StopTimings';
import { formatByTimeConfig, renderAlertMessage } from '../helpers/common';
import { withRouter } from 'react-router';
import { OrgContext } from '../context/OrgContext';

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: props.guid ? props.guid : '',
      route: { stops: [], stop_markers: [] },
      jobs: [],
      tracks: [],
      steps: [],
      current: 0,
      inProgress: false,
      showInfo: false,
      tzShortName: '',
      timeZoneId: '',
      organizationSettings: {},
      isMilitaryTime: false,
    };
  }

  componentDidMount() {
    this.getDetails(this.state.uid);
    // this.getSteps();
    this.timer = setInterval(() => this.getDetails(this.state.uid), AppConfig.fetchRefreshTime);
    this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' }) 
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.route && props.route.stops && state.route.stops && props.route.stops.length !== state.route.stops.length) {
      newState.route = props.route && props.route.stops.length > 0 ? {
        stop_markers: props.route.stops.map(stop => ({
          lat: stop.location.l_address.coordinates[1],
          lng: stop.location.l_address.coordinates[0],
          location_type: stop.location.l_type,
          ...stop,
        })),
        ...props.route,
      } : { stop_markers: [], stops: [] };
    }
    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.route.stops.length !== this.state.route.stops.length)) {
      this.getSteps();
    }
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
  }

  getDetails(id) {
    this.getTracks(id);
  }

  getTracks(id) {
    fetchRouteTrackDetails(id)
      .then((result) => {
        if (result.success) {
          const tracks = result.routeTracks && result.routeTracks.tracks ? result.routeTracks.tracks : [];
          const timeZoneId = result.routeTracks && result.routeTracks.time_zone_id ? result.routeTracks.time_zone_id : '';
          const tzShortName = result.routeTracks && result.routeTracks.tz_short_name ? result.routeTracks.tz_short_name : ''
          // const sortedTracks = tracks.sort((a, b) => new Date(a.tr_datetime) - new Date(b.tr_datetime)); getting in ascending order
          this.setState({ tracks, inProgress: false, timeZoneId, tzShortName  });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  getSteps = () => {
    const { route } = this.state;
    const stops = route.stops ? route.stops : [];
    let current = 0;
    const taskSteps = stops.map((stop, index) => {
      const isStopVisited = stop.status && ['ARRIVED', 'INCOMPLETE', 'EXCEPTION', 'COMPLETED', 'CANCELLED'].includes(stop.status) && AppConfig.orderStatusColors[stop.status];
      const bgColor = isStopVisited ? AppConfig.orderStatusColors[stop.status].color : route.bgColor;
      const fgColor = isStopVisited ? contrast(bgColor) : route.fgColor;
      const stopType = stopTypeFromObject(stop);
      const isDepoStop = stopType === 'WH' && (stop.stop_order_sequence === 0 || stop.stop_order_sequence == stops.length-1);
      const isLinehaulRoute =  route.route_type === 'line_haul';
      return ({
        name: isDepoStop ? stopType : (stopType === 'LH' && (index === 0 || index === stops.length -1 )? `${index + 1}` : `${index}`),
        description: <Row>
          <Col xs={24}>{`${stop.contact_details && stop.contact_details.contact_name ? stop.contact_details.contact_name : stop.formatted_stop_name}`}</Col>
          <Col xs={24} className="textBold">
            <StopTimings stop={stop} route={route} showTimes />
          </Col>
        </Row>,
        stopNo: index + 1,
        key: stop.id,
        stopType,
        bgColor,
        fgColor,
      });
    });
    if (stops.length > 0) {
      if (route.status === 'COMPLETED') {
        current = stops.length - 1;
      } else {
        const doneStops = stops.filter(stop => ['ARRIVED', 'INCOMPLETE', 'EXCEPTION', 'COMPLETED'].includes(stop.status));
        if (doneStops.length > 0) {
          current = doneStops[doneStops.length - 1].stop_order_sequence;
        }
      }
    }
    this.setState({
      steps: [...taskSteps],
      current,
    });
  }

  updateRouteAndSteps(route) {
    let steps = [];
    let current = 0;
    const startTime = moment(route.r_start_datetime).isValid() ? formatByTimeConfig(moment(route.r_start_datetime), this.state.isMilitaryTime ,'MMM DD, YYYY HH:mm' , "MMM DD, YYYY hh:mm A") : 'NA';
    steps[0] = {
      name: 'START',
      description: `START \n ${startTime}`,
    };
    const sortedTasks = _.sortBy(route.tasks, [function (o) { return o.t_s_no; }]);
    const taskSteps = sortedTasks.map((task, index) => {
      if (task.t_status === 'COMPLETED') {
        current = index + 1;
      }
      const location = `${task.location ? task.location.name : ''} (${task.t_type})`;
      return ({
        name: `T${index + 1}`,
        description: `${location} \n ${this.dateTimeFormat(task.t_reached_datetime, task.t_end_datetime)}`,
      });
    });
    steps = _.concat(steps, taskSteps);
    const endTime = moment(route.r_end_datetime).isValid() ? formatByTimeConfig(moment(route.r_end_datetime), this.state.isMilitaryTime ,'MMM DD, YYYY HH:mm' , "MMM DD, YYYY hh:mm A") : 'NA';
    steps[steps.length] = {
      name: 'END',
      description: `END \n ${endTime}`,
    };
    if (route.r_status === 'COMPLETED') {
      current = steps.length;
    }
    this.setState({
      route,
      current,
      steps,
    });
  }

  dateTimeFormat = (start, end) => {
    const timeFormat = this.state.isMilitaryTime ? 'HH:mm' : 'hh:mm A';
    const startTime = moment(start).isValid();
    const endTime = moment(end).isValid();
    if (startTime && endTime) {
      const StartTime = moment(start).seconds(0).toISOString();
      const EndTime = moment(end).seconds(0).toISOString();
      let duration = '';
      const hours = moment(EndTime).diff(moment(StartTime), 'hours');
      const minutes = moment(EndTime).diff(moment(StartTime), 'minutes') % 60;
      if (hours > 0) duration += `${hours} hour(s) `;
      duration += `${minutes} min(s)`;
      return `${moment(start).format(timeFormat)} - ${moment(end).format(timeFormat)} \n ${duration}`;
    }
    if (startTime) return `${moment(start).format(timeFormat)} - NA`;
    if (endTime) return `NA - ${moment(end).format(timeFormat)}`;
    return 'NA';
  }

  handleInfoModalClose = () => {
    // this.setState({ showInfo: false });

  }

  renderEvents = (route) => {
    if (route) {
      const gridSize = 6;
      return (
        <Fragment>
          {route.displayName &&
            <Fragment>
              <Row>
                <Col md={gridSize} className="paddinLeft5">Tracking details for <b>{route.displayName}</b></Col>
                <Col md={gridSize} className="alignCenter textBold">
                  <Tooltip title="Route Start time">
                    Starts at: {formatByTimeConfig(moment(route.route_start_datetime), this.state.isMilitaryTime ,'MMM Do, HH:mm' , "MMM Do, hh:mm A")} 
                  </Tooltip>
                </Col>
                <Col md={gridSize} className="alignCenter"><div className="fontWeight500">{I18n.t('general.distance')}: &nbsp;{route.total_distance}</div></Col>
                <Col md={gridSize} >
                  <div className="fontWeight500 alignRight" style={{ paddingRight: 15 }}>{I18n.t('general.time')}: &nbsp;{route.totalExecTime}</div>
                </Col>
              </Row>
            </Fragment>
          }
          <div className="timeline timelineStyles marginTop10">
            <Timeline steps={this.state.steps} current={this.state.current} route={route}/>
          </div>

          <Map data={this.state.tracks} jobs={this.state.jobs} route={this.state.route} hasSteps={this.state.steps.length > 0} timeZoneId={this.state.timeZoneId} tzShortName={this.state.tzShortName} isMilitaryTime={
            this.state.isMilitaryTime} />
        </Fragment>);
    }
    return (
      <Fragment />);
  }

  renderInfoModal = () => (
    <BaseModal
      title={I18n.t('info.route_info')}
      width="720px"
      onCancel={() => this.handleInfoModalClose()}
    >
      <Info
        routeInfo={this.state.route}
        onCancel={() => this.handleInfoModalClose()}
        onSuccess={message => this.handleSaveSuccess(message)}
      />
    </BaseModal>
  )

  render() {
    return (
      <div style={{ minHeight: '90vh' }}>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            <Col xs={24}>
              {
                this.state.route ?
                  this.renderEvents(this.state.route) :
                  <Fragment><p>No driver is assigned to this route.</p></Fragment>
              }
            </Col>
          </Row>
        </Spin>
        {this.state.showInfo &&
          this.renderInfoModal()
        }
      </div>
    );
  }
}


Tracking.propTypes = {
  guid: PropTypes.string.isRequired,
  location: PropTypes.shape(),
};

 const TrackingComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <Tracking
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default TrackingComponent;