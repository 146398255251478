import React from 'react';
import PropTypes from 'prop-types';
import { Statistic, Row, Col, Icon, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';

const TemplateStats = ({
  currentStat,
  onStatChange,
  recurringRoutesCount,
  recurringOrdersCount,
  alignText = 'center',
}) => {
  return (
    // <Row className='templateStats '>
    <Row type='flex' align='middle' justify='center' gutter={ 16 }>
      <Col
        span={ 6 }
        onClick={ () => onStatChange('ORDERS') } style={ { textAlign: alignText } } className="routeLegends" >
        <Statistic
          title="Recurring Orders"
          value={recurringOrdersCount}
          prefix={ <Icon type="ordered-list" /> }
          className={`${currentStat === 'ORDERS' ? 'active' : ''}`}
        />
      </Col>

      <Col
        span={ 6 }
        onClick={ () => onStatChange('ROUTES') }
        style={ { textAlign: alignText } }
        className="routeLegends"
      >
        <Statistic
          title="Recurring Routes"
          value={ recurringRoutesCount }
          prefix={ <Icon type="branches" style={ { color: 'brown' } } /> }
          className={`${currentStat === 'ROUTES' ? 'active' : ''}`}
        />
      </Col>
      {/* 
      <Col
        span={ 8 }
        onClick={ () => onStatChange('PENDING_ROUTES') }
        style={ { textAlign: alignText } }
        className={ currentStat === 'PENDING_ROUTES' && 'active' }
      >
        <Statistic
          title="Pending Routes"
          className={currentStat === 'PENDING_ROUTES' && 'active'}
          value={data.total_failed_routes_count}
          prefix={<Icon type="warning" style={{ color: '#f5222d' }} />}
        />
      </Col> */}
    </Row>
  );
};

TemplateStats.propTypes = {
  currentStat: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  handleOnFilterChange: PropTypes.func.isRequired,
};

export default TemplateStats;
