import React, { useState, Fragment, useEffect } from "react";
import { Tooltip, Icon, message, Spin } from "antd";
import UnpaidInvoiceLines from "./UnpaiedLineInvoices";
import { BatchPaymentApi } from "../../api/BatchPaymentApi";
import { alertMessage, refreshIcon } from "../../common/Common";
import { renderAlertMessage } from "../../helpers/common";
import I18n from "../../common/I18n";
const PaidPayments = ({ currentPayment, showActions = true, refresTheList = () => {}, updatedTime = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paidLines, setPaidLines] = useState([]);

  useEffect(() => {
    getPaidLines();
  }, [updatedTime]);

  const getPaidLines = () => {
    setIsLoading(true);
    BatchPaymentApi.paidInvoiceLines(currentPayment.id).then((result) => {
      if (result.success) {
        setIsLoading(false);
        const paidLines = result.lines || [];
        setPaidLines(paidLines);
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const deleteLine = (rec) => {
    setIsLoading(true);
    BatchPaymentApi.deletePaidInvoiceLine(rec.id).then((result) => {
      if (result.success) {
        const currentRec = result.batch_account_payment ? result.batch_account_payment : currentPayment;
        alertMessage(I18n.t("messages.deleted"));
        getPaidLines();
        setIsLoading(false);
        refresTheList({
          ...currentRec,
          applied_total: Number(currentRec.applied_amount),// Number(currentPayment.applied_amount)- Number(rec.pay_amount),
          unapplied_total: Number(currentRec.unapplied_amount),//Number(currentPayment.unapplied_amount) + Number(rec.pay_amount),
        })
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const renderPayments = () => {
    return (
      <div className="minimizdTable">
        {/* <div className="alignRight">
          <span className="cursorPointer" onClick={() => getPaidLines()}>
          {refreshIcon(() => {})}
          &nbsp;Refresh
          </span>
        </div> */}

        <UnpaidInvoiceLines
          data={paidLines}
          deleteClick={deleteLine}
          pagination={{ position: "none" }}
          showActions={showActions}
          selectedKeys={[]}
          screen_name={"paid"}
          scroll={{ y : 400 }}
        />
      </div>
    );
  };

  return <Spin spinning={isLoading}>{renderPayments()}</Spin>;
};

export default PaidPayments;
