import React, { Component, Fragment, useContext } from 'react'
import _, { isEmpty, result } from 'lodash'
import {
  Col,
  Row,
  Button,
  Input,
  Select,
  Icon,
  Spin,
  Link,
  Search,
  Text,
  notification,
  Modal,
  FormItem,
} from '../../common/UIComponents'
import {alertMessage, checkNegitive } from '../../common/Common'
import AppConfig from '../../config/AppConfig'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import SwitchComponent from '../../common/switchComponent'
import LabelInfo from './LabelInfo'
import { creditLimitApi } from '../../api/CreditLimitApi'
import OrdersDuleList from './OrdersDuleList'
import BaseSelect, { AccountSelect } from '../common/SelectDropdowns/BaseSelect'
import { withRouter } from 'react-router'
import { WarehouseContext } from '../../context/WarehouseContext'
import userStore from '../../stores/UserStore'
import { fetchShortFormAccounts } from '../../api/Account'
import List from '../ar_module/List'
import CreditLimitList from '../ar_module/CreditLimitList'
import { alignLeft, marginTop10 } from '../../helpers/invoice_styles'
import { RecordsPerPage } from '../orders/RecordsPerPage'
import TextArea from 'antd/lib/input/TextArea'
import { fetchLocations } from '../../api/LocationsApi'
import moment from 'moment-timezone'
import { OrgContext } from '../../context/OrgContext';
import { UserContext } from '../../context/UserContext';
import I18n from '../../common/I18n'
import { ScreenKeys } from '../../containers/constants';

class CreditLimit extends Component {
  constructor(props) {
    super(props)
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      due_amount: '',
      due_days: '',
      isInitial: true,
      inProgress: false,
      showClearForm: false,
      searchToken: '',
      credit_invoice_type: 'OPEN',
      orders: [],
      account_credit_limit: [],
      accounts: [],
      pagination: {},
      editableRecord: null,
      availableAmount: null,
      payWarnings: [],
      credit_Limit: [],
      selectedInfo: {
        rowKeys: [],
        rowObject: []
      },
      filter: {
        selectedAccounts: [],
      },
      wh_location_id: this.props.warehouseData.selectedWarehouses,
      warehouses: [],
      account_ids: "",
      recordsPerPage: props.recordsPerPage,
      status: 'HOLD',
      showDescriptionWindow: false,
      description: "",
      descriptionIndex: "",
      loading: false,
      decimalPoints,
      zeroValue,
      switchStatus: "HOLD"
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    this.getWarehouses()
    this.getCompletedOrders();
    this.getAccounts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.account, this.props.account) ||
      prevProps.warehouseData.selectedWarehouses !==
      this.props.warehouseData.selectedWarehouses
    ) {
      this.tableOptions.pagination.current = 1;
      this.setState({
        wh_location_id: this.props.warehouseData.selectedWarehouses,
        account: this.props.account
      }, () => {
        this.getCompletedOrders()
        this.getAccounts();
      })
    }
    if (
      !_.isEqual(
        prevProps.warehouseData.warehouseWithAccounts,
        this.props.warehouseData.warehouseWithAccounts
      )
    ) {
      this.getAccounts();
    }    
  }

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getCompletedOrders();
    }
  };

  setInitialData = (account_credit_limit = {}) => {
    account_credit_limit = account_credit_limit ? account_credit_limit : {}
    const { zeroValue } = this.state;
    this.setState({
      credit_limit: !isEmpty(account_credit_limit.credit_limit_amount) ? checkNegitive(account_credit_limit.credit_limit_amount) : zeroValue,
      due_days: !isEmpty(account_credit_limit.due_days) ? checkNegitive(account_credit_limit.due_days) : 0,
      due_amount: !isEmpty(account_credit_limit.credit_due_amount) ? checkNegitive(account_credit_limit.credit_due_amount) : zeroValue,
    })
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    }, () => {
      if (element === 'credit_invoice_type') {
        this.tableOptions.pagination.current = 1;
        this.getCompletedOrders();
      }
    })
  }

  getAccounts = (
    page = this.tableOptions.pagination.current,
    organization_id = userStore.getStateValue('selectedOrg'),
    whId = this.state.wh_location_id
  ) => {
    this.setState({
      inProgress: true,
    })
    const accountsData = this.props.warehouseData.findMatchingAccounts(whId);

    this.setState({
      accounts: accountsData || [],
      inProgress: false,
    })
  }

  handleAmountOnChange = (element, value) => {
    const { editableRecord } = this.state;
    this.setState({
      editableRecord: { ...editableRecord, [element]: value },
    })
  }

  handleEditRec = (record) => {
    const { editableRecord } = this.state;
    if (isEmpty(editableRecord)) {
      this.setState({
        editableRecord: { [record.id]: record.out_standing_amount },
      })
    } else {
      notification.warning({
        key: record.id,
        message: record.customer_order_number,
        description: <div>{I18n.t('messages.save_before_proceed', { field: record.customer_order_number })}</div>,
        placement: 'bottomRight',
        duration: 20,
      })
    }
  }
  handleKeyDown = (id, e) => {
    if (e.key === 'Enter') {
      this.handleOrderDueSave(id)
    } else if (e.key === 'Escape') {
      this.handleOrderDueClear(id)
    }
  }

  handleOrderDueSave = (id) => {
    const account = this.props.account || {}
    const exisRec = _.find(this.state.orders, { id })
    const clear_amount = this.state.editableRecord ? this.state.editableRecord[id] : '';
    if (!isEmpty(exisRec)) {
      const data = {
        account_ids: exisRec.account_ids,
        organization_id: account.organization_id,
        clear_amount,
        customer_order_id: exisRec.customer_order_id,
        customer_order_number: exisRec.customer_order_number,
        out_standing_amount: exisRec.out_standing_amount,
        biling_amount: exisRec.biling_amount,
      }
      this.setState({
        inProgress: true,
      })
      creditLimitApi.updateOrderDueAmount(data).then((result) => {
        if (result.success) {
          const msg = result.message ? result.message : I18n.t('messages.saved');
          alertMessage(msg, 'success', 10)
          const response = result.data ? result.data : {}
          this.setState({
            editableRecord: null,
            inProgress: false,
            amount_to_clear: '',
            showClearForm: false,
            currentCreditLimit: response.credit_limit_amount || {},
          }, () => {
            this.setInitialData(response.credit_limit_amount);
          })
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  formatData = (data = [], timeZone) => {
    if (data?.length) {
      const formattedData = data.map(rec => ({
        ...rec,
        account_name: `${rec.account_name}(${rec.account_code})`,
        credit_limit: checkNegitive(rec.credit_limit, false),
        invoice_open_amount: checkNegitive(rec.invoice_open_amount, false),
        available_credit: checkNegitive(rec.available_credit, false),
        updated_at: rec.updated_at ? moment.tz(rec.updated_at, timeZone).format("MMM Do YYYY HH:mm:ss") : rec.updated_at,
        mode: rec.status
      }))
      return formattedData;
    }
    return []
  }
  getWarehouses = () => {
    this.setState({ warehouseProgress: true });
    fetchLocations(AppConfig.warehouseCode, 1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            warehouses: result.locations.locations,
          },
          );
        } else {
          this.setState({ warehouseProgress: false });
          renderAlertMessage(result.errors);
        }
      });
  };
  getTimeZone = () => {
    let currentWareHouse = this.props.warehouseData.warehouses.find(wh => wh.id === this.state.wh_location_id)
    let timeZone = currentWareHouse?.timeZoneId
    if (timeZone) {
      return timeZone
    } else {
      currentWareHouse = this.state.warehouses.find(wh => wh.id === this.state.wh_location_id[0])
      timeZone = currentWareHouse?.timeZoneId
      return timeZone;
    }
  }
  getCompletedOrders = () => {
    const timeZoneId = this.getTimeZone()
    const page = this.tableOptions.pagination.current;
    const perPage = this.state.recordsPerPage;
    const searchToken = this.state.searchToken;
    const credit_invoice_type = this.state.credit_invoice_type;
    creditLimitApi.fetchComplatedOrders({
      account_ids: this.state.account_ids,
      organization_id: userStore.getStateValue('selectedOrg'),
      wh_location_id: this.state.wh_location_id,
      searchToken, page, perPage,
      credit_invoice_type,
    }).then((result) => {
      if (result.success) {
        this.setState({
          inProgress: false,
          pagination: result.data.pagination.pagination || {},
          account_credit_limit: this.formatData(result.data.account_credit_limits || [], timeZoneId),
        }, () => {
          this.setInitialData(result.account_credit_limit);

        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false, orders: [] })
      }
    })
  }


  handleChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  handleAccountChange = (value) => {
    this.setState({
      account_ids: value,
      page: this.tableOptions.pagination.current = 1
    }, () => {
      this.getAccounts()
      this.getCompletedOrders()
    })
  }


  updateCreditLimit = () => {
    const account = this.props.account || {}
    const data = {
      account_ids: account.ids,
      organization_id: account.organization_id,
      credit_limit_amount: this.state.credit_Limit,
      due_days: this.state.due_days,
    }

    this.setState({
      inProgress: true,
    })
    creditLimitApi.updateCreditLimits(data).then((result) => {
      if (result.success) {
        const response = result.data ? result.data : {}
        const msg = response?.message ? response.message : I18n.t('messages.saved');
        alertMessage(msg, 'success', 10)
        this.setState({
          inProgress: false,
          currentCreditLimit: response.account_credit_limit || {},
          availableAmount: response.avaliable_amount || 0
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }

    })

  }


  updateDueAmount = () => {
    const account = this.props.account || {}
    const data = {
      account_ids: account.ids,
      organization_id: account.organization_id,
      clear_amount: this.state.amount_to_clear,
    }
    this.setState({
      inProgress: true,
    })
    creditLimitApi.updateDueAmount(data).then((result) => {
      if (result.success) {
        alertMessage(msg, 'success', 10)
        const response = result.data ? result.data : {}
        const msg = response.message ? response.message : I18n.t('messages.saved');

        this.setState({
          inProgress: false,
          amount_to_clear: '',
          showClearForm: false,
          currentCreditLimit: response.account_credit_limit || {},

        }, () => {
          this.setInitialData(response.credit_limit_amount);
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }
  getRelatedUsers = () => {
    const view = this.state.view;
    const initial =
      view === "map"
        ? { perPage: 10000, signin: true }
        : { perPage: this.state.recordsPerPage };
    this.getCompletedOrders(initial);
  }

  clearAmount = () => {
    this.setState({
      showClearForm: true,
      amount_to_clear: this.state.zeroValue,
    })
  }
  handleCancel = () => {
    this.setState({
      selectedInfo: {
        rowKeys: [],
        rowObject: []
      },

    })

    this.getCompletedOrders();
  }


  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
        this.tableOptions && this.tableOptions.searchText
          ? this.tableOptions.searchText
          : "",
    };
    if (pagination.current !== currentPage) {
      this.getRelatedUsers();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "none",
          sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getRelatedUsers();
        }
      );
    }
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getCompletedOrders()
        updateRecordsPerPage(ScreenKeys.CREDIT_LIMIT, value)
      }
    );
  };

  handleDetailsSave = () => {
    const filteredLimits = this.state.account_credit_limit.filter(rec => this.state.selectedInfo.rowKeys.includes(rec.account_id))
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      account_credit_limits: this.formatData(filteredLimits)
    }
    creditLimitApi.updateSaveDetails(data).then((result) => {
      if (result.success) {
        const { data } = result
        const { account_credit_details = [] } = data;
        const updatedCreditLimit = this.state.account_credit_limit.map((record) => {
          const searchedCredit = account_credit_details.find(updatedCredit => updatedCredit.account_id === record.account_id)
          if (searchedCredit) {
            return {
              ...searchedCredit,
              credit_limit: checkNegitive(searchedCredit.credit_limit_amount, false),

            }
          }
          else {
            return record
          }
        })

        alertMessage(I18n.t('messages.saved'), 'success', 10)
        this.setState({
          inProgress: false,
          selectedInfo: {
            rowKeys: [],
            rowObject: []
          },
          account_credit_limit: updatedCreditLimit

        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
      this.getAccounts();
      this.getCompletedOrders();
    }).finally(() => {
      this.props.fetchCreditData()
    })

  }

  handleStatusDetails = (status) => {
    const accountDetails = this.state.account_credit_limit[this.state.descriptionIndex]
    const payload = {
      organization_id: userStore.getStateValue('selectedOrg'),
      wh_location_id: this.state.wh_location_id,
      account_id: accountDetails.account_id,
      status: status,
      description: this.state.description

    }

    creditLimitApi.updateStatusDetails(payload).then((result) => {
      if (result.success) {
        const { data } = result
        alertMessage(I18n.t('messages.saved'), 'success', 10)
        this.setState({
          inProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }

      this.getAccounts();
      this.getCompletedOrders();

    })

  }
  handleRecordSelection = (selectedRowKeys, selectedRows) => {
    this.setState(
      {
        selectedInfo: Object.assign({}, this.state.selectedInfo, {
          rowKeys: selectedRowKeys,
          rowObject: selectedRows,
        }),
      },
    );
  };

  rowHandleChange = (record, index) => {
    // const className = this.state.selectedInfo?.rowObject[index];
    // const isClassName = className ? className.status === "ALLOW" : false;
    // console.log(isClassName);
    if (record.status === 'HOLD' || record.available_credit < 0) {
      return "negativeValue"
    }

    // // if (isClassName) {
    //   return "negativeValue"
    // }
    // if(this.state.selectedInfo && isClassName){
    //   return "negativeValue"
    // }
    return ""

  };

  _renderTab = () => {
    const { account_ids, accounts } = this.state


    return (
      <Row >
        <Col xs={6} >
          <span className="textBold">{I18n.t("general.account")}:</span>
          &nbsp;&nbsp;
          <AccountSelect
            onChange={this.handleAccountChange}
            data={accounts}
            size="default"
            element="selectedAccounts"
            selectKey="id"
            selectValue="id"
            selectedCode="code"
            selectedValue="name"
            style={{ width: "75%" }}
            value={account_ids}
            showSearch={true}
            allowClear={true}
          />
        </Col>

        <Row type="flex" justify='end'>
          <Col>
            <RecordsPerPage
              onChange={this.onRecordChange}
              value={this.state.recordsPerPage}
              defaultValue={AppConfig.ordersPerPage}
            />
          </Col>
        </Row>

      </Row>

    )

  };

  onCreditTextSelection =
    (selectedIndex, selectedRecord) => {

      const { rowKeys, rowObject } = this.state.selectedInfo
      const updatedKeys = [selectedRecord.account_id].concat(rowKeys)
      const updatedSelectedData = [selectedRecord].concat(rowObject)
      this.handleRecordSelection(updatedKeys, updatedSelectedData)
    }

  onSaveDescription = (status) => {
    this.handleStatusDetails(status)
    this.setState({
      showDescriptionWindow: false,
      description: ""
    })
  }
  onCancelDescription = () => {
    this.setState({
      showDescriptionWindow: false,
      description: "",

    })
    this.getCompletedOrders();
  }

  render() {

    const { selectedInfo } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };

    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col span={10}>{I18n.t("menu.credit_limit")}</Col>
          </Row>
          <div style={{ marginTop: 20 }}>
            <this._renderTab />
            <Spin spinning={this.state.inProgress} delay={1000}>
              <Row >
                <Col  className="accounts" style={{ marginTop: 20 }} >

                  <CreditLimitList
                    rowKey={"account_id"}
                    scroll={{ y: "calc(100vh - 280px)", x: "max-content" }}
                    data={this.state.account_credit_limit ? this.state.account_credit_limit : []}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedInfo.rowKeys,
                      onChange: this.handleRecordSelection,
                    }}
                    selectedRows={this.state.selectedInfo.rowKeys}
                    onCreditTextSelection={this.onCreditTextSelection
                    }
                    className="credit_limit_table"
                    onCreditLimitChange={(value, dataIndex) => {
                      this.setState({
                        account_credit_limit: this.state.account_credit_limit.map((account, index) => {
                          if (dataIndex == index) {
                            return {
                              ...account,
                              credit_limit: value,
                              available_credit: checkNegitive(checkNegitive(value, false) - account.invoice_open_amount, false) || account.invoice_open_amount,

                            }
                          } else {
                            return account
                          }
                        })
                      })
                    }
                    }
                    onStatusChange={(index, dataIndex, mode, display) => {
                      this.setState({
                        showDescriptionWindow: display,
                        descriptionIndex: dataIndex,
                        switchStatus: mode
                      })
                    }
                    }
                    rowClassName={(record, index) => this.rowHandleChange(record, index)}
                    pagination={pagination}
                    tableChange={(pagination, filters, sorter) =>
                      this.handleTableChange(pagination, filters, sorter)
                    }
                    status={this.state.status}
                  />
                  <Modal
                    title={I18n.t('menu.description')}
                    visible={this.state.showDescriptionWindow}
                    closable
                    onCancel={() => this.onCancelDescription()}
                    footer={null}
                  >
                    <div>
                      <Row>
                        <Col xs={24}>
                          <FormItem label={"Add Description"}>
                            <TextArea
                              value={this.state.description}
                              onChange={(e) => { this.setState({ description: e.target.value }) }}>
                            </TextArea>
                          </FormItem>
                        </Col>
                        <Col xs={24} className="alignRight marginTop20">
                          <Button
                            type={"default"}
                            onClick={() =>
                              this.onCancelDescription()
                            }

                            style={{ marginRight: 10 }}
                          >
                            {I18n.t("general.cancel")}
                          </Button>
                          <Button
                            type={"primary"}
                            onClick={() => this.onSaveDescription(this.state.switchStatus)}
                          >
                            {I18n.t("general.save")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>

                </Col>
              </Row>
            </Spin>
          </div>
          <Row className="bottom_fixed" >
            <Col xs={24} style={{ padding: '10px 10px' }} className="alignCenter">
              {this.state.selectedInfo.rowKeys.length > 0 && (
                <Button
                  type="danger"
                  className="clearButtonStyle"
                  onClick={this.handleCancel}
                  disabled={!(selectedInfo?.rowKeys.length > 0)}

                >
                  Cancel
                </Button>
              )}
              {this.state.selectedInfo.rowKeys.length > 0 && (
                <Button
                  type="primary"
                  className='buttonStyle'
                  onClick={() => this.handleDetailsSave()}
                  disabled={!(selectedInfo?.rowKeys?.length > 0)}

                >
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>

    )
  }
}

export const CreditLimitComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext)
  const { fetchCreditData } = useContext(OrgContext)
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.CREDIT_LIMIT
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);

  return (
    <CreditLimit
      warehouseData={warehouseData}
      fetchCreditData={ fetchCreditData }
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  )
})

export default CreditLimitComponent