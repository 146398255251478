import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";

export function fetchOrderStatus(
  page = 1,
  perPage = AppConfig.perPage,
  org_id
) {
  const url = ApiUrl(
    `v2/order_status_codes?organization_id=${org_id}&${PageParams(
      page,
      perPage
    )}`
  );
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        order_status_codes: "order_status_codes",
        pagination: "meta.pagination",
      },
    },
    false
  );
}

export function deleteOrderStatus(id) {
  const url = ApiUrl(`v2/order_status_codes/${id}`);
  return Call("delete", url, {}, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function saveOrderStatus(isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew
    ? ApiUrl("v2/order_status_codes")
    : ApiUrl(`v2/order_status_codes/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(method, url, formData, ParseGeneralResponse, {}, true);
}
