import React from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import CopyableContent from "../../common/CopyableContent";
import { checkNegitive } from "../../../common/Common";
import moment from "moment";
import AppConfig from "../../../config/AppConfig";
import { Input , Col, Icon, Popconfirm, Row, Tooltip} from "../../../common/UIComponents";
import DeleteIcon from "../../common/DeleteIcon";

class ParticularDriverWeeklyReport extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.columns = [
      // {
      //   key: "driver",
      //   title: <b>{I18n.t("drivers_weekly_report.driver")}</b>,
      //   dataIndex: "driver",
      //   render: (data, record) => <span>{data}</span>,
      //   width: 100,
      // },

      {
        key: "service_date",
        title: <b>{I18n.t("drivers_weekly_report.service_date")}</b>,
        dataIndex: "date",
        render: (data, record) => (
          <span>{moment(data).format(AppConfig.dateFormat)}</span>
        ),
        width: 100,
      },
      {
        key: "Account",
        title: <b>{I18n.t("drivers_weekly_report.account")}</b>,
        dataIndex: "account_code",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "customer_order_number",
        title: <b>{I18n.t("drivers_weekly_report.job_number")}</b>,
        dataIndex: "customer_order_number",
        render: (data, record) => <CopyableContent text={data} />,
        width: 120,
      },
      {
        key: "consignee_name",
        title: <b>{I18n.t("drivers_weekly_report.customer_name")}</b>,
        dataIndex: "consignee_name",
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        width: 150,
        className: "noWrap",
      },
      {
        key: "gross_revenue",
        title: <b>{I18n.t("drivers_weekly_report.gross($)")}</b>,
        dataIndex: "gross_revenue",
        //render: (data, record) => (data ? checkNegitive(data) : ""),
        render: (data, record, index) => (
          // <Input
          //   // onChange={(e) =>
          //   //   props.handleOnChange(record.dateIndex, record.orderIndex, "gross_revenue", e.target.value)
          //   // }
          //   value={data}
          //   style={{ width: 100, fontSize: 12 }}
          //   size="small"
          //   type="number"
          //   prefix={<Icon type="dollar" />}
          //   min={0}
          //   // onBlur={(e) =>
          //   //   props.handleOnChange(
          //   //     record.dateIndex, 
          //   //     record.orderIndex,
          //   //     "gross_revenue",
          //   //     record.gross_revenue ? parseFloat(record.gross_revenue).toFixed(2) : 0.00
          //   //   )
          //   // }
          // />
          <div>
           {checkNegitive(data)}
          </div>
        ),
        width: 150,
      },
      {
        key: "gross_pay",
        title: <b>{I18n.t("drivers_weekly_report.net($)")}</b>,
        dataIndex: "gross_pay",
        render: (data, record) =>  
      //   <Input
      //   // onChange={(e) =>
      //   //   props.handleOnChange(record.dateIndex, record.orderIndex, "gross_pay", e.target.value)
      //   // }
      //   value={data}
      //   style={{ width: 100, fontSize: 12 }}
      //   size="small"
      //   type="number"
      //   prefix={<Icon type="dollar" />}
      //   min={0}
      //   // onBlur={(e) =>
      //   //   props.handleOnChange(
      //   //     record.dateIndex, 
      //   //     record.orderIndex,
      //   //     "gross_pay",
      // record.gross_pay ? parseFloat(record.gross_pay).toFixed(2) : 0.00
      //   //   )
      //   // }
      // />,
      <div>       
      {checkNegitive(data)}
      </div>,
        width: 150,
      },
      
    ];
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default  React.memo(ParticularDriverWeeklyReport);
