import React, { Component, useContext } from 'react';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import { Row, Col, FormItem, DatePicker, Button, Select, Spin } from "../../common/UIComponents";
import { fetchUSEnergyinfo, fetchFuelSurchargeRegions } from "../../api/USEnergyInfo";
import USEnergyLists from './USEnergyLists';
import { withRouter } from 'react-router';
import { OrgContext } from '../../context/OrgContext';
import moment from 'moment';
import { renderAlertMessage } from '../../helpers/common';
import AppConfig from '../../config/AppConfig';

const { RangePicker } = DatePicker;
const dateFormat = 'Do MMM YYYY';
class USEnergyInfo extends Component {
    constructor(props) {
      super(props);
      this.state = {
        US_energy_info: [],
        fuel_surcharges: [],
        inProgress: false,
        region: '',
        filters: {
          fromDate: moment().startOf('week'),
          toDate: moment().endOf('week'),
        },
        organizationSettings: {},
        isMilitaryTime: false
      };
    }

    componentDidMount() {
      this.getFuelSurcharges();
      this.getUSEnergyinfo();
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
    }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }
    getFuelSurcharges = (cb) => {
      this.setState({ inProgress: true });
      fetchFuelSurchargeRegions().then((result) => {
        if (result.success) {
          this.setState(
            {
              fuel_surcharges: result.fuel_surcharge_regions || [],
              inProgress: false,
            },
            () => {
              if (cb) {
                cb();
              }
            }
          );
        } 
        else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    };

    getUSEnergyinfo = (cb) => {
      this.setState({ inProgress: true });
      const FromDate = moment(this.state.filters.fromDate).format('YYYY-MM-DD');
      const ToDate = moment(this.state.filters.toDate).format('YYYY-MM-DD'); 
      const Region = this.state.region;
      fetchUSEnergyinfo(FromDate,ToDate,Region).then((result) => {
        if (result.success) {
          this.setState(
            {
              US_energy_info: result.us_energy_info || [],
              inProgress: false,                          
            },
            () => {
              if (cb) {
                cb();
              }
            }
          );
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
      
    };
  

    handleFilterChange = (element, value) => {
        this.setState({ [element]: value });
    };

    onDateChange = (filter, cb = null) => {
      this.setState({
        filters: Object.assign({}, filter),
      }, cb);
    }
    
    handleRangeChange = (dates, dateStrings) => {
      let dateFilter = {};
      if (!(dateStrings[0] !== '' && dateStrings[1] !== '')) {
        dateFilter = Object.assign({}, this.state.filters, { fromDate: moment().startOf('month'), toDate: moment() });
      } else {
        dateFilter = Object.assign({}, this.state.filters, { fromDate: dates[0], toDate: dates[1] });
      }
      this.onDateChange(dateFilter);
    }

    handleSearch = () => {
       this.getUSEnergyinfo();
    };
    
    
    render() {
          return (
            <div className="content">
              <Spin spinning={this.state.inProgress} delay={1000} >
                <Row>
                    <Col sm={24} xs={24} md={5} lg={5}>
                        <FormItem label={I18n.t('USEnergyInfo.region')}>
                          <Select
                            value={this.state.region}
                            showSearch
                            onChange={(e) => this.handleFilterChange("region", e)}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: "100%" }}
                          >
                            <Select.Option key="-1" value="">All</Select.Option>
                              {this.state.fuel_surcharges.map((data) => (
                                <Select.Option key={data.id} value={data.region_name}>
                                  {data.region_name} 
                                </Select.Option>
                              ))}
                          </Select>
                        </FormItem>
                    </Col>
                    
                    <Col sm={24} xs={24} md={5} lg={5} style={{ left: 20 }}>
                      <FormItem label={I18n.t('USEnergyInfo.date_range')} require>
                        <RangePicker
                          defaultValue={[moment().startOf('month'), moment()]}
                          value={[this.state.filters.fromDate, this.state.filters.toDate]}
                          ranges={AppConfig.dateRanges}
                          format={dateFormat}
                          onChange={(dates, dateStrings) => this.handleRangeChange(dates, dateStrings)}
                        />
                      </FormItem>
                    </Col>

                    <Col
                      sm={24}
                      xs={24}
                      md={4}
                      lg={4}
                      className="paddingTop20"
                      style={{ left: 30 }}
                    >
                      <Button type="primary" onClick={this.handleSearch} icon='search'>
                        {I18n.t('general.search')}
                      </Button>
                    </Col>
                </Row>
                  <USEnergyLists scroll = {{y:"calc(100vh - 375px)"}} data={this.state.US_energy_info} isMilitaryTime={this.state.isMilitaryTime} />
              </Spin>
            </div>
          );
    }
}
 const USEnergyInfoComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <USEnergyInfo
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default USEnergyInfoComponent;