import { Button, Col, message, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { OrgIntegrationApi } from '../../../api/IntegrationApi';
import { alertMessage } from '../../../common/Common';
import I18n from '../../../common/I18n';
import AppConfig from '../../../config/AppConfig';
import userStore from '../../../stores/UserStore';
import BaseModal from '../../BaseModal';
import OrgIntegrationsForm from './OrgIntegrationsForm';
import OrgIntegrationsList from './OrgIntegrationsList';
import BillingAccountIntegrationForm from './BillingAccountIntegrationForm';
import { fetchBillingAccounts } from '../../../api/BillingAcountsApis';
import _ from "lodash";
import { Spin } from "../../../common/UIComponents"
import { renderAlertMessage } from '../../../helpers/common';

function OrgIntegrations () {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ orgIntegrationsData, setOrgIntegrationsData ] = useState([]);
  const [ currentAuthorization, setCurrentAuthorization ] = useState(null);
  const [ isIntegrationModalOpen, setIsIntegrationModalOpen ] = useState(false);
  const [ billingAccountData, setBillingAccountData ] = useState([]);
  const [ currentOrgIntegration, setCurrentOrgIntegration ] = useState({});
  const [ paginationConfig, setPaginationConfig ] = useState({
    current: 1,
    pageSize: AppConfig.perPage,
    total: 0,
  });
  const [ tableOptions, setTableOptions ] = useState({
    pagination: {
    }
  });
  const [checkedKeys,setCheckedKey] = useState({
        selectedKeys: '',
        selectedRows: [],
      })

//  useEffect(() => {
//      getBillingAccounts();
//    }, []);

  const getBillingAccounts = () => {
     const organization_id = userStore.getStateValue("selectedOrg");
     setIsLoading(true);
     fetchBillingAccounts(organization_id).then((result) => {
       if (result.success) {
         setBillingAccountData(result.integration_companies || []);
         setIsLoading(false);
         setIsIntegrationModalOpen(true)
       } else {
         alertMessage(result.errors, "error", 10);
         setIsLoading(false);
         setBillingAccountData([]);
       }
     });
   };

  const fetcheOrgIntegrations = () => {
    const organization_id = userStore.getStateValue('selectedOrg');
    setIsLoading(true);
    OrgIntegrationApi.fetchOrg(organization_id).then((response) => {
      if (response.success) {
        setOrgIntegrationsData(response.org_integrations);
      }
    }).catch((error) => {
      setOrgIntegrationsData([]);
    }).finally(() => {
      setIsLoading(false);
    }
    );

  };
  useEffect(() => {
    fetcheOrgIntegrations();
  }, []);

  const onModalClose = () => {
    
    setIsModalOpen(false);
  };

  const onIntegrationModalClose = () => {
    setIsIntegrationModalOpen(false);
    setCurrentOrgIntegration({});
    setBillingAccountData([]);
  };


  const renderModal = () => {
    return (
      <BaseModal
        title={ I18n.t("integrations.label") }
        className="integrations-modal"
        onCancel={ onModalClose }
        width="45%"
      >
        <OrgIntegrationsForm refreshIntegrations={
          () => fetcheOrgIntegrations()
        } closeModal={ onModalClose } orgIntegrationsData={orgIntegrationsData}/>
      </BaseModal>
    );
  };
  const onAuthorization = (record) => {
    setIsLoading(true)
    const { integration_code, organization_id}  = record;
    setCurrentAuthorization(integration_code);
    OrgIntegrationApi.authorize(organization_id, integration_code).then(
      (response) => {
        if (response.success) {
          if (response.target_url && response.target_url.length)
            window.open(response.target_url, '_blank');
        } else {
          alertMessage(response.errors[ 0 ], 'error');
        }
      }
    ).catch((error) => {
    }
    ).finally(() => {
      setCurrentAuthorization(null);
      setIsLoading(false);
    });
  };

  const onAuthorizationCompany = (record, id) => {
    setIsLoading(true);
    const { integration_code, organization_id } = record;
    setCurrentAuthorization(integration_code);
    const data = {
      organization_id: organization_id,
      integration_app_code: integration_code,
      integration_company_id: id,
    };
    OrgIntegrationApi.authorizeCompany(data)
      .then((response) => {
        if (response.success) {
          alertMessage(response.message, "success")
          if (response.target_url && response.target_url.length)
            window.open(response.target_url, "_blank");
        } else {
          renderAlertMessage(response.errors)
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setCurrentAuthorization(null);
        setIsLoading(false);
      });
  };

  const onTableChange = (tablePagination) => {
    let currentPage = null;
    if (!_.isEmpty(tableOptions.pagination))
      currentPage = tableOptions.pagination.current;
    else
      currentPage = 1;

    if (tablePagination.current !== currentPage) {
      setTableOptions({ pagination: tablePagination });
    }
  };

  const handleUpdate = () => { };

  const unlinkingProgress= () => {
    const hide = message.loading('Unlinking..', 0);
    return hide
  };

  const handleUnlinkIntegration = (integrationId) => {
      setIsLoading(true);
      const hideUnlinkingNotification = unlinkingProgress();
      OrgIntegrationApi.unLinkIntegration(integrationId).then(
        (response) => {
          if(response.success)
          {
            alertMessage(I18n.t("integrations.unlink_success"), 'success');
            fetcheOrgIntegrations();
          }
        }
      ).catch((error) => {
        alertMessage(response.errors[ 0 ], "error", 10);
      }
      ).finally(() => {
        setTimeout(hideUnlinkingNotification, 1000);
        setIsLoading(false);
      }
      );
   };
  const handleDeletion = (orgIntegrationId) => {
    setIsLoading(true);
    OrgIntegrationApi.delete({
      organization_id: userStore.getStateValue('selectedOrg'),
      org_integration_id: orgIntegrationId
    }).then(
      (response) => {
        if (response.success) {
          alertMessage(I18n.t('messages.deleted'));
          setOrgIntegrationsData(orgIntegrationsData.filter((orgIntegration) => {
            return orgIntegration.id !== orgIntegrationId;
          }
          ));
        }
        else {
          alertMessage(response.errors[ 0 ], "error", 10);
        }
      }
    ).catch((error) => {
      alertMessage(response.errors[ 0 ], "error", 10);
    }
    ).finally(() => {
      setIsLoading(false);
    }
    );
  };

  const handleStatusChange = (orgIntegrationId) => {
    const orgIntegration = orgIntegrationsData.find((orgIntegration) => {
      return orgIntegration.id === orgIntegrationId;
    });
    const orgIntegrationWithStatus = {
      ...orgIntegration,
      status: orgIntegration.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    };
    OrgIntegrationApi.changeStatus(orgIntegrationWithStatus).then(
      (response) => {
        if (response.success) {
          alertMessage(I18n.t('messages.updated'));
          setOrgIntegrationsData(orgIntegrationsData.map((orgIntegration) => {
            if (orgIntegration.id === orgIntegrationId) {
              orgIntegration.status === 'ACTIVE' ? orgIntegration.status = 'INACTIVE' : orgIntegration.status = 'ACTIVE';
            }
            return orgIntegration;
          }
          ));
        }
        else {
          alertMessage(response.errors[ 0 ], "error", 10);
        }
      }
    ).catch((error) => {
      alertMessage(response.errors[ 0 ], "error", 10);
    }
    ).finally(() => {
      setIsLoading(false);
    }
    );
  }

  const handleIntegrationModal = (id) =>{
    const index = _.findIndex(orgIntegrationsData, ["id", id]);
   
    if(index >= 0){
      const Data = Object.assign({}, orgIntegrationsData[index]);
      setCurrentOrgIntegration(Data);
      getBillingAccounts();
    }
    
  }

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setCheckedKey({
      ...checkedKeys,
        selectedKeys: selectedRowKeys,
        selectedRows,
    });
  };

  const handleAuthorize = () =>{
  }

  const renderIntegrationModal = () => {
    const selectedRowKeys = checkedKeys.selectedKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: handleSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.id === "1234", // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <BaseModal
        title={I18n.t("billing_accounts.billing_acc_integration")}
        onCancel={onIntegrationModalClose}
        width="30%"
      >
        {/* <Row>
          <Col className="alignRight marginBottom10" style={{ marginTop: -15 }}>
            <Button
              type="primary"
              onClick={handleAuthorize}
              size="small"
              disabled={checkedKeys.selectedKeys.length === 0}
            >
              {I18n.t("general.authorize")}
            </Button>
          </Col>
        </Row> */}
        <Spin spinning={isLoading} delay={1000}>
          <Row>
            <Col>
              <BillingAccountIntegrationForm
                data={billingAccountData}
                onAuthorization={onAuthorizationCompany}
                currentOrgIntegration={currentOrgIntegration}
                pagination={{ position: "none" }}
                // rowSelection={rowSelection}
              />
            </Col>
          </Row>
        </Spin>
      </BaseModal>
    );
  };

  return (
    <Col span={ 24 }>
      <div className="orgIntegrationsWrapper">
        <Row className="orgIntegrationWrapperChild" gutter={ 16 }>
          <Row
            type="flex"
            justify={ "space-between" }
            style={ {
              padding: "8px",
            } }
          >
            <Col>
              <Text
                style={ {
                  fontSize: "14px",
                  fontWeight: "bold",
                } }
              >
                { I18n.t("integrations.label") }
              </Text>
            </Col>
            <Col>
              <Button
                type="primary"
                icon="plus"
                className="orgIntegrationsWrapper__right__button"
                onClick={ () => setIsModalOpen(true) }
              >
                { I18n.t("integrations.add_integration") }
              </Button>
            </Col>
          </Row>
          <Row>
          <Col className="orgIntegrationsWrapper__body">
            <OrgIntegrationsList
              billingAccountIntegration= {handleIntegrationModal}
              onAuthorization={ onAuthorization }
              currentAuthorization={ currentAuthorization }
              className="orgIntegrationsWrapper__body__list"
              isLoading={ isLoading }
              rowKey="_id"
              handleEdit={ handleUpdate }
              handleUnlink={ handleUnlinkIntegration }
              handleDelete={ handleDeletion }
              data={ orgIntegrationsData }
              pagination={ orgIntegrationsData.length ? paginationConfig :
                {
                  position: "none",
                }
              }
              tableChange={ (pagination, filter, sorter, currentTable) =>
                onTableChange(pagination, sorter, currentTable)
              }
              statusChange={handleStatusChange}
            />
          </Col>
          </Row>
          
        </Row>
      </div>
      { isModalOpen && renderModal() }
      { isIntegrationModalOpen && renderIntegrationModal() }
    </Col>

  );
}

export default OrgIntegrations;