import React, { Component, useContext } from "react";
import { deleteLocation, fetchLocations } from "../api/PredefinedStops";
import { alertMessage, isEmpty } from "../common/Common";
import { Button, Col, Row, Spin, Search } from "../common/UIComponents";
import BaseModal from "../components/BaseModal";
import PredefinedStopForm from "../components/PredefinedStops/Form";
import PredefindStopsList  from "../components/PredefinedStops/List";
import AppConfig from "../config/AppConfig";
import { checkServiceExistance, removeSpecialCharacters, renderAlertMessage } from "../helpers/common";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { withRouter } from "react-router-dom";
import { ScreenKeys } from "./constants";
import { UserContext } from "../context/UserContext";
import I18n from "../common/I18n";

export class PredefinedStops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddOrEditForm: false,
      isLoading: false,
      locationToUpdate: null,
      pagination: {},
      locations: [],
      recordsPerPage: AppConfig.ordersPerPage,
      filterPlaceHolder:{
        sortBy: "none",
        sortByType: "descend",
      },
      recordsPerPage: props.recordsPerPage,
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: "",
    };
  }

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getLocations()
        updateRecordsPerPage(ScreenKeys.PREDEFINED_STOPS_LISTING, value);
      }
    );
  };

  onSearch = (value) => {
  };

  componentDidMount() {
    // fetch locations
    this.getLocations();
  }

  // network methods
  getLocations = async (initial, cb) => {
    this.setState({
      isLoading: true,
    });
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : this.state.recordsPerPage;
    const searchText = !isEmpty(this.tableOptions.searchText) ? this.tableOptions.searchText: '';
    const { sortBy, sortByType } = this.state.filterPlaceHolder;
    try {
      const response = await fetchLocations({ page, perPage, searchText, sortBy, sortByType });
      if (response.success) {
        this.setState(
          {
            pagination: response.pagination,
            locations: response.predefined_stops.predefined_stops,
          },
          () => {
            typeof cb === "function" && cb();
          }
        );
      } else {
        renderAlertMessage(result.errors)
      }
    } catch (error) {
      alertMessage("oops, something went wrong", "error", 10);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onSearch = (text) => {
    const filteredString = removeSpecialCharacters(text);
    if (filteredString.trim() === "") {
      this.tableOptions = {
        pagination: {},
        filters: {},
        sorter: {},
        searchText: "",
      };
    } else {
      this.tableOptions.searchText = filteredString;
    }
    this.tableOptions.pagination.current = 1;
    this.getLocations();
  };

  onLocationSave = (message) => {
    alertMessage(message);
    this.setState(
      {
        showAddOrEditForm: false,
      },
      () => {
        this.getLocations();
      }
    );
  };

  onDelete = async (id) => {
    this.setState({
      isLoading: true,
    });
    try {
      const response = await deleteLocation(id);
      if (response.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getLocations();
      } else {
        throw new Error(response.errors[0]);
      }
    } catch (err) {
      alertMessage(err.message, "error", 10);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  onEdit = (id) => {
    const locationToUpdate = this.state.locations.find((l) => l.id === id);
    if (locationToUpdate) {
      this.state.locationToUpdate = locationToUpdate;
      this.setState({
        locationToUpdate: locationToUpdate,
        showAddOrEditForm: true,
      });
    }
  };

  //page methods

  onAdd = () => {
    // open modal
    this.setState({
      locationToUpdate: null,
      showAddOrEditForm: true,
    });
  };

  toggleForm = (flag) => {
    let nextState = flag;

    if (void 0 === flag) {
      nextState = !this.state.showAddOrEditForm;
    }
    this.setState({
      showAddOrEditForm: nextState,
    });
  };

  renderAddOrEditForm = () => {
    let operation = this.state.locationToUpdate ? "Edit" : "Add";

    return (
      <BaseModal
        title={`${operation} ${I18n.t("predefined_stops.single")}`}
        onCancel={() => this.toggleForm(false)}
        width="60%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <PredefinedStopForm
          onCancel={() => this.toggleForm(false)}
          initialValues={this.state.locationToUpdate}
          onSave={this.onLocationSave}
        />
      </BaseModal>
    );
  };


  onTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions?.pagination?.current
        ? this.tableOptions.pagination.current
        : 1
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getLocations();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
          }),
        },
        () => {
          this.getLocations();
        }
      );
    }
  }

  render() {
    const { pagination } = this.state;

    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("menu.predefined_stops")}</Col>
            <Col xs={12}>
              <Row
                justify="end"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Col xs={12}>
                  {checkServiceExistance("PDSS") && <Search
                    size="default"
                    placeholder={`${I18n.t(`general.search`)} Locations`}
                    onSearch={this.onSearch}
                    allowClear
                  />
                  }
                </Col>
                <Col className="page-actions marginLeft10">
                  {checkServiceExistance('PDSC') && (
                    <Button type="primary" onClick={this.onAdd} icon="plus">
                      {I18n.t("predefined_stops.add")}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="page-content">
              <Col xs={24}>
                <Row style={{ float: "right", marginBottom: "-18px" }}>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    onSearch={this.onSearch}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Row>
              </Col>
            </Row>
          <Spin spinning={this.state.isLoading} delay={1000}>
            <Row>
              <Col>
                {checkServiceExistance("PDSI") && (
                  <PredefindStopsList
                    data={this.state.locations}
                    pagination={{
                      total: pagination.total_count,
                      current: pagination.current_page,
                      pageSize: pagination.per_page || this.state.recordsPerPage,
                    }}
                    tableChange={(pagination, filters, sorter) => this.onTableChange(pagination, filters, sorter)}
                    onDelete={this.onDelete}
                    onEdit={this.onEdit}
                    // scroll ={{y:680}}
                    scroll = {{y:"calc(100vh - 240px)"}}
                  />
                )}
              </Col>
            </Row>
          </Spin>
          {this.state.showAddOrEditForm && this.renderAddOrEditForm()}
        </div>
      </div>
    );
  }
}


export const PDWrapper = withRouter((props) => {
  const userContext= useContext(UserContext);
  const perPageKey = ScreenKeys.PREDEFINED_STOPS_LISTING;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);

  return (
    <PredefinedStops
      recordsPerPage={recordsPerPage}
      userContext={userContext}
      {...props}
    />
  );
});

export default PDWrapper;

