import { Button, Col, Input, PageHeader, Row } from "antd";
import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";

const renderExtra = (operationCode, props) => {
  const pathname = props.location.pathname;

  if (pathname === "/roles") {
    return (
      <Row type="flex" gutter={16}>
        <Col align="middle">
          <Input
            onChange={(e) => props.searchFilter(e.target.value)}
            placeholder="Search"
          />
        </Col>
        <Col align="middle">
          <Button
            type="primary"
            key={`${operationCode}_add`}
            className={`${operationCode}_add page-header-button`}
            onClick={() => {
              props.setAddModalVisible(true);
            }}
            icon="plus"
          >
            {I18n.t("roles.add.role")}
          </Button>
        </Col>
      </Row>    
    );
  }
};

export function BasePageHeader(props) {
  const { title, operationCode } = props;
  return (
    <PageHeader
      className="page-header page-actions"
      ghost={false}
      title={title}
      extra={[renderExtra(operationCode, props)]}
      {...props}
    ></PageHeader>
  );
}

BasePageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  operationCode: PropTypes.string.isRequired,
};

BasePageHeader.defaultProps = {
  title: "",
  operationCode: "",
};

export default withRouter(BasePageHeader);
