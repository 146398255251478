import React from 'react';
import BaseList from '../BaseList';
import {   checkNegitive, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import { Link } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import { getValueFromArrayOfObjects } from '../../helpers/array_functions';

class BillingAccountOrders extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      // {
      //   key: 'account_name',
      //   title: I18n.t('account.account'),
      //   dataIndex: 'account_name',
      //   render: (data, record) => (
      //     <div>{data ? `${data} ${record.account_code}` : ''}</div>
      //   ),
      // },

      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(data);
                    }
                  }}
                >
                  {isEmpty(data)
                    ? record.account_name
                    : `${record.account_name} (${data})`}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ''
              )}
            </div>
          );

          // <div>{data ? `${data} ${record.account_code}` : ''}</div>
        },
      },

      {
        key: "account_code",
        title: I18n.t("account.code"),
        dataIndex: "account_code",
      },
      {
        key: "no_of_orders",
        title: I18n.t("order.no_of_orders"),
        dataIndex: "no_of_orders",
      },
      {
        key: "amount",
        title: I18n.t("invoices.amount"),
        dataIndex: "billing_amount",
        render: (data) => (
          <div style={{ width: 100 }} className="alignRight">
            {data ? checkNegitive(data) : ""}
          </div>
        ),
      },
    ];
  }
}

export default BillingAccountOrders;
