import React, { Fragment, useEffect, useState } from 'react'
import I18n from '../common/I18n'
import driverDefaultImag from '../../assets/images/man.png'
import { isEmpty } from 'lodash'
import { RecoveriesApis } from '../api/RecoveriesApi'
import { alertMessage,navigateToAccount } from '../common/Common'
import refresh from '../../assets/images/refresh.png'
import moment from 'moment'
import FormErrors from '../components/common/FormErrors'
import { checkAccessExistance, checkServiceExistance, getDriverDisplayName, getDriverFullDisplayName, renderAlertMessage } from '../helpers/common'
import { getValueFromArrayOfObjects } from '../helpers/array_functions'
import RecoveryList from '../components/recoveries/RecoveryList'
import { navigateToRoute } from '../helpers/routes'
import { 
  Link,
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Card,
  Spin,
  Tooltip,
  Modal,
  Alert,
  Icon,
  Badge,
 } from '../common/UIComponents'
import { withRouter } from 'react-router'
import DateTimeSelector from '../common/DateTimeSelector';

function RecoveryPlan(props) {
  const {
    warehouses,
    form,
    drivers,
    selectedInfo,
    filterPlaceHolder,
    onClose,
    showWarehouses,
    showRecoveryDate,
    showDrivers,
    screenName,
    route_id,
    selectedRecoveryLocations = [],
    defaultWhId = "",
    history,
    displayConfiguration,
    accounts
  } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [locations, setLocations] = useState([])
  const [driverCount, setDriverCount] = useState([])
  const [errors, setErrors] = useState([])
  const[selectedOrders, setSelectedOrders] = useState({
    selectedKeys: [...selectedInfo.selectedKeys],
    selectedRows: [...selectedInfo.selectedRows],
  })

  const { getFieldDecorator, getFieldValue } = form
  const[datesOnMount, setDatesOnMount] = useState(true)
  const[etaOnMount, setEtaOnMount] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        formData.selectedDrivers = values.selectedDrivers
        formData.recoveryDate = values.recoveryDate
        if (screenName !== 'dispatch') {
          createRoute()
        } else {
          addStopsToRoute()
        }
      }
    })
  }


  useEffect(() => {
    checkDatesOnMount()
    checkEtaOnMount()
  }, [formData.recoveryDate,selectedOrders.selectedRows])

  const checkEtaOnMount =() => {
    const dateList = selectedOrders.selectedRows.map((order) => {
      return moment(order.recovery_eta).format('YYYY-MM-DD');
    });
  
    const uniqueDates = _.uniq(dateList);
  
    const areAllDatesEqual = uniqueDates.length === 1;
    setEtaOnMount(areAllDatesEqual)
  }

  const getCountDrivers = () => {
      const recoveryDate = moment(formData.recoveryDate).format()
      setIsLoading(true)
      RecoveriesApis.driverSelection(recoveryDate).then(
        (result) => {
          if (result.success) {
            const driverCount = result.data || []
            setDriverCount(driverCount)
            setIsLoading(false)
          } else {
            setDriverCount([])
            setIsLoading(false)
            renderAlertMessage(result.errors)
          }
        },
      )
  }

  const checkDatesOnMount =(val='') => {
    const dateList = selectedOrders.selectedRows.map((order) => {
      return moment(order.recovery_eta).format('YYYY-MM-DD');
    });
      const formDate = _.compact(formData.recoveryDate ? [!isEmpty(val) ? val : formData.recoveryDate.format('YYYY-MM-DD')] : []);
    const datesBunch = [...dateList, ...formDate];
  
    const uniqueDates = _.uniq(datesBunch);
  
    const areAllDatesEqual = uniqueDates.length === 1;
    setDatesOnMount(areAllDatesEqual)
  }
  
  const getWarehouseId = () => {
    if(selectedInfo.selectedRows[0]){
      const order = selectedInfo.selectedRows[0];
      const warehouse_id = getValueFromArrayOfObjects(warehouses, 'location_code', order.warehouse_code, 'id');
      return warehouse_id;
    }
    return "";
  }

  const createRoute = () => {
    setIsLoading(true)
    const isRecoveriesSelected =  selectedOrders.selectedKeys.length > 0
    let payload = {}
    if (isRecoveriesSelected) {
      const warehouse_id = formData.wh_id ? formData.wh_id : getWarehouseId();
      payload = {
        driver_ids: [formData.selectedDrivers],
        recovery_ids: selectedOrders.selectedKeys,
        recovery_date: formData.recoveryDate ? formData.recoveryDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        wh_location_id: warehouse_id,
        location_ids: selectedOrders.selectedRows.map(rec => rec.pickup_location_id)
      }
    } else {
      payload = {
        driver_ids: formData.selectedDrivers,
        wh_location_id: formData.wh_id,
        recovery_date: formData.recovery_date.format('YYYY-MM-DD'),
        location_ids: formData.location_ids,
      }
    }
    RecoveriesApis.createRoute(payload).then((result) => {
      if (result.success) {
        if(result.route?.route){
          Modal.info({
            key: 'dispathButton',
            // title: `Route #${
            //   !isEmpty(dirver.employee_code) ? dirver.employee_code : (formatFullName(dirver))
            // } has been created`,
            title: `Route has been created`,
            content: (
              <div>
                { checkAccessExistance('DISPATCH') && 
                <Fragment>
                  <u>
                  <Link
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={() => {
                      navigateToRoute(result.route.route, history)
                      Modal.destroyAll()
                    }}
                  >
                    Click here
                  </Link>
                </u>
                 &nbsp;&nbsp; to check the details
                </Fragment>
                }
              </div>
            ),
            onOk() { onClose(true)},
          })
        }else {
          onClose(true)
          alertMessage(result.route.message)
        }

        setIsLoading(false)
        
      } else {
        setIsLoading(false)
        setErrors(result.errors)
      }
    })
  }

  const addStopsToRoute = () => {
    const payload = {
      nav_route_id: route_id,
      location_ids: formData.location_ids,
    }
    setIsLoading(false)
    RecoveriesApis.addStopsToRoute(payload).then((result) => {
      if (result.success) {
        setIsLoading(false)
        onClose(true, route_id)
      } else {
        setIsLoading(false)
        renderAlertMessage(result.errors)
      }
    })
  }

  const refreshIcon = (cb) => (
    <Fragment>
      <img
        src={refresh}
        className="cursorPointer textPrimary marginRight10 "
        onClick={cb}
        style={{ width: 15, marginTop: -3 }}
      />
    </Fragment>
  )

  const handleSeletedOrdersChange = (selectedRowKeys, selectedRows) => {
    setSelectedOrders({
      ...selectedOrders,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  };

  useEffect(() => {
    const initialValues = {
      wh_id: filterPlaceHolder.warehouse_id ? filterPlaceHolder.warehouse_id : defaultWhId,
      recovery_date: moment(), // filterPlaceHolder.dateSearchValue || moment(),
      selectedDrivers:
        screenName === 'dispatch' && drivers.length > 0 ? [drivers[0].id] : [],
        recoveryDate: moment(),
    }
    form.setFieldsValue(initialValues)
    setFormData({ ...formData, ...initialValues })
  }, [filterPlaceHolder])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  useEffect(() => {
    if (!isEmpty(formData.recovery_date) && !isEmpty(formData.wh_id)) {
      getRecoveryLocations()
    }
  }, [formData.recovery_date, formData.wh_id])

  useEffect(() => {
    if (!isEmpty(formData.recoveryDate)) {
      getCountDrivers()
    }
  }, [formData.recoveryDate])

  const onFieldChange = (key, value) => {
    const { form } = props
    form.setFieldsValue({ [key]: value })
    updateFormData(key, value)
    if(key === "recoveryDate"){
      checkDatesOnMount(value)
    }
  }

  const updateFormData = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const getRecoveryLocations = () => {
    const { wh_id, recovery_date } = formData
    if (!isEmpty(wh_id) && !isEmpty(recovery_date)) {
      const recoveryDate = moment(recovery_date).format()
      setIsLoading(true)
      RecoveriesApis.fetchRecoveryLocation(wh_id, recoveryDate).then(
        (result) => {
          if (result.success) {
            const locations = result.locations || []
            setLocations(locations)
            setIsLoading(false)
          } else {
            setLocations([])
            setIsLoading(false)
            renderAlertMessage(result.errors)
          }
        },
      )
    }
  }

  const driversSelected = getFieldValue('selectedDrivers')
  const isRecoveriesSelected = selectedInfo.selectedKeys.length > 0
  const cantBeBlank = {
    required: true,
    message: I18n.t('messages.cannot_be_blank', {
      label: I18n.t('general.field'),
    }),
  }
  const renderLocation = () => (
    <Fragment>
      {!isRecoveriesSelected && (
        <Form.Item label={'Location'} required>
          <Row gutter={8}>
            <Col span={22}>
              {getFieldDecorator('location_ids', {
                rules: [cantBeBlank],
              })(
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    onFieldChange('location_ids', value)
                  }}
                  mode={'multiple'}
                  placeholder="-- Select --"
                >
                  {/* <Select.Option key="warehouse" value="">
                    -- Select --
                  </Select.Option> */}
                  {locations.map((loc) => (
                    <Select.Option
                      key={loc.id}
                      value={loc.id}
                      disabled={
                        selectedRecoveryLocations &&
                        selectedRecoveryLocations.length && selectedRecoveryLocations.includes(
                          loc.l_address.id
                        )
                      }
                    >
                      {loc.name}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Col>
            <Col span={2}>
              <Tooltip title={I18n.t('general.refresh')}>
                {refreshIcon(() => getRecoveryLocations())}
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>
      )}
    </Fragment>
  )
  return (
    <Spin spinning={isLoading}>
      {isRecoveriesSelected ? (
        <div className="marginBottom20 fontSize12">
          {!etaOnMount && (
            <Alert
              message={I18n.t("general.recovery_message")}
              type="error"
              showIcon
              icon={<Icon type="info-circle" theme="filled" />}
              closable
            />
          )}
          {!datesOnMount && (
            <div className="marginTop10">
              <Alert
                message="Scheduled Date is not matching with the selected Recovery ETA's."
                type="error"
                showIcon
                icon={<Icon type="info-circle" theme="filled" />}
                closable
              />
            </div>
          )}
          <h4 className="fontSize13 marginTop10">Selected Orders</h4>
          <div className="smallTable">
            <RecoveryList
              data={
                selectedInfo?.selectedRows ? [...selectedInfo.selectedRows] : []
              }
              size="small"
              scroll={{ x: "max-content", y: 400 }}
              isSorting={true}
              screen_from="recovery"
              displayConfiguration={displayConfiguration}
              showMiniDetails={false}
              pagination={{ position: "none" }}
              rowSelection={{
                selectedRowKeys:
                  selectedOrders.selectedKeys.length > 0
                    ? selectedOrders.selectedKeys
                    : [],
                onChange: handleSeletedOrdersChange,
                getCheckboxProps: (record) => ({
                  id: `${record.id}`,
                }),
              }}
              navigateToAccount={(account_id) =>
                navigateToAccount(accounts, account_id, history)
              }
            />
          </div>
          {/* <Row className="textBold">
            <Col xs={8}>{I18n.t('general.hawb')}</Col>
            <Col xs={8}>{I18n.t('general.mawb')}</Col>
            <Col xs={8}>{I18n.t('order.order_no')}</Col>
          </Row>
          {selectedInfo.selectedRows.map((order) => (
            <Row>
              <Col xs={8}>{order.hawb}</Col>
              <Col xs={8}>{order.mawb}</Col>
              <Col xs={8}>{order.customer_order_number}</Col>
            </Row>
          ))} */}
        </div>
      ) : (
        <div>
          {/* <Row>
             <Col xs={12}>{I18n.t('locations.location')}: &nbsp; AMB</Col>
             <Col xs={12}>{I18n.t('recoveries.recovery_date')}:  &nbsp; 11 Aug 2022</Col>
          </Row>
          <hr/> */}
        </div>
      )}
      <div className="marginTop10">
        <Form {...formItemLayout} onSubmit={handleSubmit} layout={"vertical"}>
          {screenName !== "dispatch" ? (
            <Fragment>
              {showWarehouses !== false && !isRecoveriesSelected && (
                <Form.Item label={I18n.t("location.location")} required>
                  {getFieldDecorator("wh_id", {
                    rules: [cantBeBlank],
                    initialValue: "",
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        onFieldChange("wh_id", value);
                      }}
                      placeholder="-- Select --"
                    >
                      {/* <Select.Option key="warehouse" value="">
                    -- Select --
                  </Select.Option> */}
                      {warehouses.map((warehouse) => (
                        <Select.Option key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              {showRecoveryDate !== false && !isRecoveriesSelected && (
                <Form.Item label={I18n.t("general.recovery_date")} required>
                  {getFieldDecorator("recovery_date", {
                    rules: [cantBeBlank],
                  })(
                    <DatePicker
                      placeholder={I18n.t("general.select_date")}
                      format={"DD MMM YYYY"}
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        onFieldChange("recovery_date", value);
                      }}
                      disabledDate={(current) =>
                        current && current < moment().startOf("day")
                      }
                    />
                  )}
                </Form.Item>
              )}
              {renderLocation()}
              <Form.Item label="Schedule Date" required>
                {getFieldDecorator("recoveryDate", {
                  rules: [cantBeBlank],
                  initialValue: moment(),
                })(
                  <DatePicker
                    format="Do MMM YYYY"
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current.isBefore(moment().startOf("day"))
                    }
                    onChange={(value) => {
                      onFieldChange("recoveryDate", value);
                    }}
                  />
                )}
              </Form.Item>

              {showDrivers !== false && (
                <Form.Item label={I18n.t("general.Driver")} required>
                  {getFieldDecorator("selectedDrivers", {
                    rules: [cantBeBlank],
                    initialValue: isRecoveriesSelected ? "" : [],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) => {
                        const children = option.props.children;
                        if (children && children.length > 0) {
                          if (Array.isArray(children)) {
                            return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          } else {
                            return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }
                        }
                      }}
                      mode={isRecoveriesSelected ? "default" : "multiple"}
                      maxTagCount={3}
                      onChange={(e) => {
                        onFieldChange("selectedDrivers", e);
                      }}
                      placeholder="-- Select --"
                    >
                      {driverCount?.drivers?.map((driver) => (
                        <Select.Option key={driver.driver_id} value={driver.driver_id}>
                          {getDriverDisplayName(driver)}
                          &nbsp;&nbsp;
                          <span className="floatRight">
                            <Badge count={ driver.total_stops} />
                          </span>
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              {!isEmpty(driversSelected) && driversSelected.length > 0 && (
                <div>
                  <h3>
                    {I18n.t("general.selected")}{" "}
                    {isRecoveriesSelected ? (
                      <Fragment> {I18n.t("general.Driver")}</Fragment>
                    ) : (
                      <Fragment> {I18n.t("general.drivers")}</Fragment>
                    )}
                  </h3>
                  <Row gutter={16}>
                    {driverCount?.drivers
                      .filter((driver) => driversSelected.includes(driver.driver_id))
                      .map((driver) => {
                        const driverImage =
                          driver.driver_image
                            ? driver.driver_image
                            : driverDefaultImag;
                        return (
                          <Col xs={isRecoveriesSelected ? 12 : 6}>
                            <Card size="small" className="alignCenter">
                              <img src={driverImage} height="50px" />
                              <div>
                                {driver.driver_name}
                              </div>
                              <div>{driver.employee_code}</div>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              )}
              <div className="alignCenter marginTop10">
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                  <Button type="primary" htmlType="submit" icon="plus-circle">
                    {I18n.t("routes.new")}
                  </Button>
                </Form.Item>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {renderLocation()}

              <Form.Item wrapperCol={{ span: 7, offset: 10 }}>
                {/* {...{
                //   labelCol: { span: 8 },
                //   wrapperCol: { span: 7, offset: 10 },
                // } */}

                <Button type="primary" htmlType="submit" size="small">
                  {I18n.t("routes.update")}
                </Button>
              </Form.Item>
            </Fragment>
          )}
        </Form>
        <div>{FormErrors(errors)}</div>
      </div>
    </Spin>
  );
}
const RecoveryPlanForm =withRouter(Form.create({ name: 'recovery_plan_form' })(
  RecoveryPlan,
))
export { RecoveryPlanForm }

export default RecoveryPlanForm
