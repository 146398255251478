import { Call, ApiUrl, PageParams, ParseGeneralResponse } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import { isEmpty } from '../common/Common';

export function fetchOrgDriverRates(
  orgId,
  page = 1,
  perPage = AppConfig.perPage,
) {
  let url = ApiUrl(`v2/organization_driver_rates?${PageParams(page, perPage)}&operation_code=DRI`);
  if (!isEmpty(orgId)) {
    url = `${url}&organization_id=${orgId}`;
  }
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { driver_rates: "org_driver_rates", pagination: "meta.pagination" },
    },
    true
  );
}

export function fetchDriverRateDetails(id) {
  const url = ApiUrl(`v2/organization_driver_rates/${id}`);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        driver_rate: "org_driver_rate",
      },
    },
    true
  );
}

export function saveOrgDriverRate(isNew, data) {
  const url = isNew ? ApiUrl("v2/organization_driver_rates?operation_code=DRC") : ApiUrl(`v2/organization_driver_rates/${data.id}?operation_code=DRU`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: {
        driver_rate: "org_driver_rate",
      },
  }, true);
}

export function applyOrgDriverRate(data) {
  let url = ApiUrl("v2/account_driver_rates/update_as_org_driver_rate?operation_code=UAODR");
  if(!isEmpty(data.account_id)){
    url =  `${url}&account_id=${data.account_id}`;
  }
  if(!isEmpty(data.organization_driver_rate_id)){
    url =  `${url}&organization_driver_rate_id=${data.organization_driver_rate_id}`;
  }
  const method = "get";
  return Call(method, url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        driver_rates: "account_driver_rate",
      },
  }, true);
}

export function setRateDefault(id) {
  const url =  ApiUrl(`v2/organization_driver_rates/${id}/update_default`);
  return Call('get', url, { operation_code: 'DRUD'}, ParseGeneralResponse,{}, true);
}

export function applyDRToAll(data) {
  data.operation_code = 'AAADR';
  const url =  ApiUrl(`v2/driver_rates/app_driver_rate_params`);
  return Call('post', url, data , ParseGeneralResponse,{ message: 'message'}, true);
}

export function deleteOrgDriverRate(id, data = {}) {
  data.operation_code = 'DRD';
  const url = ApiUrl(`v2/organization_driver_rates/${id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, true);
}

export function fetchAccountDriverRates(
  orgId,
  accountId,
  page = 1,
  perPage = AppConfig.perPage,
) {
  let url = ApiUrl(`v2/account_driver_rates?${PageParams(page, perPage)}&operation_code=ADRI`);
  if (!isEmpty(orgId)) {
    url = `${url}&organization_id=${orgId}`;
  }
  if (!isEmpty(accountId)) {
    url = `${url}&account_id=${accountId}`;
  }
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { driver_rates: "account_driver_rates", pagination: "meta.pagination" },
    },
    true
  );
}

export function fetchAccountDriverRateDetails(id) {
  const url = ApiUrl(`v2/account_driver_rates/${id}?operation_code=ADRS`);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        driver_rate: "account_driver_rate",
      },
    },
    true
  );
}

export function saveAccountDriverRate(isNew, data) {
  const url = isNew ? ApiUrl("v2/account_driver_rates?operation_code=ADRC") : ApiUrl(`v2/account_driver_rates/${data.id}?operation_code=ADRU`);
  const method = isNew ? "post" : "put";
  return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: {
        driver_rate: "account_driver_rate",
      },
  }, true);
}

export function setRateDefaultForAccounts(id) {
  const url =  ApiUrl(`v2/account_driver_rates/${id}/update_default`);
  return Call('get', url, { operation_code: 'ADRUD'}, ParseGeneralResponse,{}, true);
}

export function deleteAccountDriverRate(id, data = {}) {
  data.operation_code='ADRD';
  const url = ApiUrl(`v2/account_driver_rates/${id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, true);
}


export function fetchAccountWiseDriverRates(id) {
  const url = ApiUrl(`v2/account_driver_rates/org_account_dr_list?organization_id=${id}&operation_code=OADRL`);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        driver_rates: "account_driver_rates",
      },
    },
    true
  );
}

export function deleteAllAccountDriverRates(id) {
  const url = ApiUrl(`v2/account_driver_rates/delete_all?account_id=${id}&operation_code=ADRDA`);
  return Call("get", url, {}, ParseGeneralResponse, {}, true);
}
