import React, { useState, useEffect } from "react";
import { Button, Col, Icon, Input, Popconfirm, Card, Radio, Row, Spin } from "antd";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import _ from "lodash";
import DeleteIcon from "../../common/DeleteIcon";
import { fetchAccessorialDetails, saveAccessorialConfig } from "../../../api/Accessorials";
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";
import { alertMessage } from "../../../common/Common";
import FormButtons from "../../common/FormButtons";

const ZonesConfiguration = (props) => {
    const [ zoneData, setZoneData ] = useState({
        zones: [],
    });
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        addRow();
        getAccessorialDetails(props.currentAccessorial._id);
    }, []);

    const getAccessorialDetails = (id) => {
        setInProgress(true);
        fetchAccessorialDetails(id).then((result) => {
            if (result.success) {
                const currentAccessorial = result.accessorial || {};
                const total_component_params = _.isArray(currentAccessorial.component_params) ? currentAccessorial.component_params : [];
                const component_params = total_component_params.filter(rec => rec.component_code === 'ZONES');
                const currentComponent = component_params.length > 0 ? component_params[0] : { component_code: 'ZONES' };
                const currentConfiguration = currentComponent.param_values || [];

                setZoneData({
                    zones: currentConfiguration.length > 0 ? currentConfiguration : [{ zone_name: "", zip_codes: [], charge_type: "flat", charge_value: "", isNew: true }]
                });
            } else {
                renderAlertMessage(result.errors);
            }
            setInProgress(false);
        });
    };

    const handleChange = (index, field, value) => {
        setZoneData((prevData) => {
            const newZones = [...prevData.zones];
            newZones[index][field] = value;
            return { ...prevData, zones: newZones };
        });
    };

    const handleZipCodeChange = (index, zip_codes) => {
        handleChange(index, 'zip_codes', zip_codes);
    };

    const saveCurrentRow = () => {
        setInProgress(true);
        setZoneData((prevData) => {
            const newZones = prevData.zones.map((zone_name) => {
                if (zone_name.isNew) {
                    const { isNew, ...rest } = zone_name; // Remove isNew flag
                    return rest;
                }
                return zone_name;
            });

            const data = {
                component_params: [],
                code: props.currentAccessorial.accessorial_code,
                account_id: props.account._id,
                organization_id: props.account.organization_id,
            };

            const accessorial_params = {
                min_weight: 0,
                max_weight: 0,
                order_type: 'A',
                zone_category: 'ALL_ZONES',
                param_values: newZones, // Directly assign newZones to param_values
            };

            const currentParam = {
                component_code: 'ZONES',
                accessorial_params: [accessorial_params],
            };

            data.component_params.push(currentParam);

            saveAccessorialConfig(data).then((result) => {
                if (result.success) {
                    alertMessage(I18n.t('messages.saved'))
                    getAccessorialDetails(props.currentAccessorial._id);
                } else {
                    console.error('Save failed', result.errors);
                }
                setInProgress(false);
            });

            return { ...prevData, zones: [...newZones] };
        });
    };

    const addRow = () => {
        setZoneData((prevData) => ({
            zones: [...prevData.zones, { zone_name: "", zip_codes: [], charge_type: "flat", charge_value: "", isNew: true }],
        }));
    };

    const deleteRow = (index) => {
        setInProgress(true);
        setZoneData((prevData) => {
            const updatedZones = prevData.zones.filter((_, i) => i !== index);
            return {
                ...prevData,
                zones: updatedZones,
            };
        });
        alertMessage('Deleted Successfully!');
        setInProgress(false);
    };

    return (
        <Spin spinning={inProgress} delay={1000}>
            <div>
                <Row>
                    <Col md={8}>
                        <div className="info-text">
                            <h4>{I18n.t("general.name")}:</h4>
                            {props.currentAccessorial.accessorial_name}
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="info-text">
                            <h4>{I18n.t("general.code")}:</h4>
                            {props.currentAccessorial.accessorial_code}
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="info-text">
                            <h4>{I18n.t("accessorials.gl_code")}:</h4>
                            {props.currentAccessorial.gl_code}
                        </div>
                    </Col>
                </Row>
                <hr />

                <Card
                    title={"Zones"}
                    size="small"
                    className="marginTop10"
                    bodyStyle={{ textAlign: 'left' }}
                >
                    {zoneData.zones.map((e, index) => (
                        <Row key={ index } type="flex" justify="space-around" align="middle">
                            <Col span={ 5 }>
                            <div><strong>Zone Name</strong></div>
                            <Input
                                size="large"
                                value={ e.zone_name }
                                onChange={ (event) => handleChange(index, 'zone_name', event.target.value) }
                            />
                        </Col>
                        <Col span={ 5 } className="tagsInput">
                            <div><strong>Zipcodes</strong></div>
                            <TagsInput
                                value={ e.zip_codes || [] }
                                onChange={ (zip_codes) => handleZipCodeChange(index, zip_codes) }
                                addKeys={ [ 9, 13, 32 ] }
                                onlyUnique
                                validationRegex={/^(?=[0-9]*$)(?:.{3}|.{5})$/}
                                addOnPaste
                                addOnBlur={ true }
                                inputProps={{
                                    placeholder: 'Add a zipcode',
                                  }}
                            />
                        </Col>
                        <Col span={ 5 }>
                            <div><strong>Charge Type</strong></div>
                            <Radio.Group
                                value={ e.charge_type }
                                onChange={ (event) => handleChange(index, 'charge_type', event.target.value) }
                            >
                                <Radio value="percentage">Percentage</Radio>
                                <Radio value="flat">Flat</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={ 5 }>
                            <div><strong>Charge Value</strong></div>
                            { e.charge_type === "percentage" ? (
                                <Input
                                    addonBefore="%"
                                    type="number"
                                    value={ e.charge_value }
                                    onChange={ (event) => {
                                        const value = event.target.value;
                                        // Ensure value is within 0-100 range before calling handleChange
                                        if (value >= 0 && value <= 100) {
                                            handleChange(index, 'charge_value', value);
                                        }
                                    } }
                                />
                            ) : (
                                <Input
                                    addonBefore={ <Icon type="dollar" /> }
                                        value={ e.charge_value }
                                        onChange={ (event) => handleChange(index, 'charge_value', event.target.value) }
                                    />
                            ) }
                        </Col>
                        <Col span={ 2 }>
                                <Popconfirm
                                    placement="topRight"
                                    title={I18n.t('messages.delete_confirm')}
                                    onConfirm={() => deleteRow(index)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        size="small"
                                        type="danger"
                                        icon="delete"
                                        style={{ width: 24, height: 24, fontSize: 16 }}
                                    />
                                </Popconfirm>
                            {index == zoneData.zones.length - 1 &&
                                <Icon style={{ marginLeft: '0.5rem', fontSize: '17px' }} onClick={() => addRow()} type="plus-circle" theme="filled" />
                            }
                        </Col>
                    </Row>
                    ))}
                </Card>

            </div>
            <Row>
                <Col xs={24}>
                    <Row>
                        <Col xs={24}>
                            {FormButtons(
                                inProgress,
                                saveCurrentRow,
                                props.onCancel,
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Spin>
    );
};

export default ZonesConfiguration;