import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Row, Col, Select } from "../../../common/UIComponents";
import BaseModal from "../../BaseModal";
import SearchOrdersComponent from "../SearchOrders";
import moment from "moment";

const Orders = ({ route, closeModal, getRouteStopsInfo, warehouses, warehouse_id, dateFilter, closeSearchOrders, dispContext }) => {
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [stopType, setStopType] = useState("nav_stop");
    const startTime = route?.r_scheduled_start_datetime
    ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    : moment();
  const endTime = route?.r_scheduled_start_datetime
    ? moment(route.r_scheduled_start_datetime).format("YYYY-MM-DD")
    : moment();

    return (
        <Fragment>
            <SearchOrdersComponent
                actionType={"currentRoute"}
                availableDriversInfo={[]}
                changesOrdersState={() => {}}
                closeSearchOrders={(refreshRoute = false, routeId, actionType) => {
                    closeSearchOrders(refreshRoute, routeId, actionType)
                    closeModal()
                }}
                dateFilter={dateFilter}
                driversInfo={[]}
                endTime={moment(endTime)}
                fetchavailableDriversInfo={() => {}}
                getRoutes={() => {}}
                refreshUnallocated={true}
                route={route}
                routeId={route.id}
                routes={[]}
                setOrders={() => {}}
                setUnassignedInfo={() => {}}
                showRouteAssigner={false}
                showRouteSummary={() => {}}
                startTime={moment(startTime)}
                toggleRouteAssigner={() => {}}
                unassignedOrdersInfo={[]}
                dispContext={dispContext}
                updateSelectedRouteInfo={() =>
                    getRouteStopsInfo(route.id)
                }
                warehouses={warehouses}
                warehouse_id={warehouse_id}
            />
        </Fragment>
    );
};

Orders.propTypes = {
    route: PropTypes.shape().isRequired,
};

export default Orders;
