/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Icon,
  Tooltip,
  Popover,
} from '../../common/UIComponents'
import {isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import { formatSurfaceArea, formatVolume } from '../../helpers/orders'

const StopSurfaceArea = ({ stop, route, showDeptInfo = true }) => {
  const stopType = stop.stopType;
  // const formatArea = (sa, showDefault = true) => {
  //   const surfaceArea =  !isEmpty(sa) && sa !== 0 ? sa : (showDefault ? "NA" : "");
  //   return (<Fragment>
  //   {surfaceArea}{!isEmpty(sa) && surfaceArea !== 'NA' ? <Fragment>&nbsp;in<sup>2</sup></Fragment>: ''}
  //   </Fragment>);
  // }
  return (
    <div className="table-contact-info">
      <Row className="volumeInfo">
        <Col xs={24}>
          {stopType === 'WH' && stop.stop_number === 1 ? (
            <Fragment>
              <Row>
                <Col xs={15} className="">
                  {/*<Icon type="shopping" />*/}
                  <Tooltip title={I18n.t('preplan.surface_area.vehicle')}>
                    {I18n.t('preplan.surface_area.vehicle_sf')}
                  </Tooltip>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8}>{formatSurfaceArea(route.vehicle_volume)}</Col>
              </Row>
              <Row>
                <Col xs={15} className="">
                  {/*<Icon type="shopping" />*/}
                  <Tooltip
                    title={I18n.t('preplan.surface_area.max_accumable_sa')}
                  >
                    {I18n.t('preplan.surface_area.max_accumable_sa_sf')}
                  </Tooltip>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8}>{formatSurfaceArea(stop.accumulated_area)}</Col>
              </Row>
              {!isEmpty(stop.overloaded_area) && stop.overloaded_area != 0 && (
                <Row>
                  <Col xs={15} className="">
                    {/*<Icon type="shopping" />*/}
                    <Tooltip title={I18n.t('preplan.surface_area.overloaded')}>
                      {I18n.t('preplan.surface_area.overloaded_sf')}
                    </Tooltip>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={8}>{formatSurfaceArea(stop.overloaded_area)}</Col>
                </Row>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col xs={15} className="">
                  {/*<Icon type="shopping" />*/}
                  <Tooltip
                    title={I18n.t(
                      'preplan.surface_area.current_stop_surface_area',
                    )}
                  >
                    {showDeptInfo ? I18n.t('preplan.surface_area.current_stop_surface_area_sf'): I18n.t('preplan.surface_area.stop_surface_area') }
                  </Tooltip>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={8}>{formatSurfaceArea(stop.stop_surface_area)}</Col>
              </Row>
              {showDeptInfo && stopType === 'CS' && (
                <Row>
                  <Col xs={15} className="">
                    {/*<Icon type="shopping" />*/}
                    <Tooltip
                      title={I18n.t('preplan.surface_area.dept_truck_sa')}
                    >
                      {I18n.t('preplan.surface_area.dept_truck_sa_sf')}
                    </Tooltip>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={8}>
                    {formatSurfaceArea(
                      stop.status !== 'UNALLOCATED'
                        ? stop.accumulated_area
                        : '',
                    )}
                    {/* {formatSurfaceArea(stop.accumulated_area)} */}
                  </Col>
                </Row>
              )}
              {stopType === 'WH' && stop.overloaded_area > 0  && (
                <Row>
                  <Col xs={15} className="">
                    {/*<Icon type="shopping" />*/}
                    <Tooltip
                      title={I18n.t('preplan.surface_area.overloaded')}
                    >
                      {I18n.t('preplan.surface_area.overloaded_sf')}
                    </Tooltip>
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col xs={8}>
                    {formatSurfaceArea(
                      stop.overloaded_area
                    )}
                    {/* {formatSurfaceArea(stop.accumulated_area)} */}
                  </Col>
                </Row>
              )}
              {/* {stop.status !== "UNALLOCATED" && (
               <Row>
                 <Col xs={15} className="">
                   
                   {I18n.t("preplan.volume.overloaded")}
                 </Col>
                 <Col xs={1}>:</Col>
                 <Col xs={8}>{formatSurfaceArea(stop.overloaded_area)}</Col>
               </Row>
             )} */}
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
  )
  // return (
  //   <Popover
  //     title={<Fragment>{I18n.t('preplan.volume.info')}</Fragment>}
  //     content={
  //       <div className="table-contact-info">
  //        <Row className="volumeInfo">
  //           <Col xs={24}>
  //             {stopType === "WH" && stop.stop_number === 1 ? (
  //               <Fragment>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;{I18n.t("preplan.volume.vehicle_volume")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatVolume(route.vehicle_volume)}</Col>
  //                 </Row>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;
  //                     {I18n.t("preplan.volume.max_accumable_volume")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatVolume(stop.accumulated_area)}</Col>
  //                 </Row>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;{I18n.t("preplan.volume.overloaded")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatVolume(stop.overloaded_area)}</Col>
  //                 </Row>
  //               </Fragment>
  //             ) : (
  //               <Fragment>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;{I18n.t("preplan.volumne.stop_order_volume")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>{formatVolume(stop.stop_surface_area)}</Col>
  //                 </Row>
  //                 <Row>
  //                   {" "}
  //                   <Col xs={12}>
  //                     <Icon type="shopping" />
  //                     &nbsp;&nbsp;
  //                     {I18n.t("preplan.volume.departure_truck_volume")}
  //                   </Col>
  //                   <Col xs={1}>:</Col>
  //                   <Col xs={11}>
  //                     {formatVolume(
  //                       stop.status !== "UNALLOCATED"
  //                         ? stop.accumulated_area
  //                         : ""
  //                     )}
  //                   </Col>
  //                 </Row>
  //                 {stop.status !== "UNALLOCATED" && (
  //                   <Row>
  //                     {" "}
  //                     <Col xs={12}>
  //                       <Icon type="shopping" />
  //                       &nbsp;&nbsp;{I18n.t("preplan.volume.overloaded")}
  //                     </Col>
  //                     <Col xs={1}>:</Col>
  //                     <Col xs={11}>{formatVolume(stop.overloaded_area)}</Col>
  //                   </Row>
  //                 )}
  //               </Fragment>
  //             )}
  //           </Col>
  //         </Row>
  //       </div>
  //     }
  //   >
  //     <span className="textBold">{I18n.t("truck.volume")}</span> :{" "}
  //     <Tooltip
  //       title={I18n.t("preplan.volume.accumilated")}
  //       placement="bottom"
  //     >
  //       {formatVolume(stop.stop_surface_area)}
  //        { stopType === 'WH' && stop.stop_number === 1 && !isEmpty(stop.overloaded_area) &&
  //        <Fragment>
  //        (
  //          <span className="textRed">{stop.overloaded_area}</span>
  //        )</Fragment>
  //        }
  //     </Tooltip>
  //   </Popover>
  // );
}
export default StopSurfaceArea

StopSurfaceArea.propTypes = {
  stop: PropTypes.shape().isRequired,
}
