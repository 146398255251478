import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Tooltip,
  Popconfirm,
} from "../../common/UIComponents";
import { Button, Col, Form, Icon, Input, Row, Spin } from "antd";
import DeleteIcon from "../common/DeleteIcon";
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";
import { alertMessage, isEmpty } from "../../common/Common";
import { OrgContext } from "../../context/OrgContext";
import { DocumentTypeApi } from "../../api/DocumentTypes";
import { renderAlertMessage } from "../../helpers/common";
const DocumentTypes = (props) => {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [editableId, setEditableId] = useState(null);
  const [editedDocumentType, setEditedDocumentType] = useState("");
  const [showNewRow, setShowNewRow] = useState(false);
  const [newRowDocumentType, setNewRowDocumentType] = useState("");
  const [messageError, setMessageError] = useState("");
  const [showAddButton, setShowAddButton] = useState(true);
  const orgContextData = useContext(OrgContext);
  const { currentOrg } = orgContextData;
  const [inProgress, setInProgress] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if(props.activeKey === 'document_types'){
      getDocumentTypes()
    } else if(isEmpty(props.activeKey)){
      getDocumentTypes()
    }

  }, [props.activeKey])

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getDocumentTypes = () => {
    const organization_id = currentOrg.id;
    setInProgress(true);
      DocumentTypeApi.fetchDocumentType(organization_id).then((result) => {
      if (result.success) {
        setDocumentTypes(
          result.driver_doc_type
            ? result.driver_doc_type.map((item) => ({
                ...item,
                name: capitalizeFirstLetter(item.name),
              }))
            : [],
        );
        setShowNewRow(result?.driver_doc_type?.length === 0);
        setInProgress(false);
      } else {
        renderAlertMessage(result.errors);
        setInProgress(false);
      }
    });
  };

  const handleEdit = (id) => {
    setEditableId(id);
    const itemToEdit = documentTypes.find((item) => item.id === id);
    if (itemToEdit) {
      setEditedDocumentType(itemToEdit.name);
    }
    setShowNewRow(false);
  };
  const handleSave = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const payLoad = {
      name: editedDocumentType,
    };
    setInProgress(true);
    DocumentTypeApi.updateDocumentType(id, payLoad, organization_id).then(
      (result) => {
        if (result.success) {
          setInProgress(false);
          setDocumentTypes((prevState) =>
            prevState.map((item) =>
              item.id === id
                ? {
                    ...item,
                    name: editedDocumentType,
                  }
                : item
            )
          );
          alertMessage("Updated Successfully","success",5)
        } else {
          renderAlertMessage(result.errors);
        }
      }
    );
    setEditableId(null);
  };
  const handleCancelEdit = () => {
    setEditableId(null);
    if (showNewRow) {
      if (editableId === null) {
        setNewRowDocumentType("");
        setMessageError("");
        setShowAddButton(true);
      }
      setShowNewRow(false);
    }
  };

  useEffect(() => {
    if (documentTypes.length === 0) {
      setShowAddButton(true);
      setShowNewRow(true);
    }
  }, [documentTypes]);

  useEffect(() => {
    if (!isEmpty(editableId)) {
      inputRef.current.focus();
    }
  }, [editableId]);

  const handleDelete = (id) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    setInProgress(true);
    DocumentTypeApi.deleteDocumentType(id, organization_id).then(
      (result) => {
        if (result.success) {
          setInProgress(false);
          const updatedList = documentTypes.filter(
            (item) => item.id !== id
          );
          setDocumentTypes(updatedList);
          alertMessage(result.data.message, "success", 5);
        } else {
          setInProgress(false);
          renderAlertMessage(result.errors);
        }
      }
    );
  };
  const handleSaveNewRow = () => {
    if (
      newRowDocumentType.trim() === "" 
    ) {
      if (newRowDocumentType.trim() === "") {
        setMessageError(`${I18n.t("errors.document_error")}`);
      } else {
        setMessageError("");
      }
     
    } else {
      const newRow = {
        name: newRowDocumentType,
        organization_id: userStore.getStateValue("selectedOrg"),
        type: "driver"
      };
      setInProgress(true);
      DocumentTypeApi.saveDocumentType(newRow).then((result) => {
        if (result.success) {
          setInProgress(false);
          setDocumentTypes((prevState) => [
            ...prevState,
            result.data.driver_doc_type,
          ]);
          alertMessage("Added Successfully",'success',5)
          getDocumentTypes()
        } else {
          renderAlertMessage(result.errors);
        }
      });
      setNewRowDocumentType("");
      setShowNewRow(false);
      setMessageError("");
    }
  };
  useEffect(() => {
   // getDocumentTypes();
  }, []);

  const handleAddNewRow = () => {
    setShowNewRow(true);
    setNewRowDocumentType("");
    setMessageError("");
    setShowAddButton(false);
  };

  const handleKeyPress = (event, id, saveItem = "newRow") => {
    console.log(id, event, saveItem);
    if (event.key === "Enter") {
      if(saveItem === "newRow"){
        handleSaveNewRow()
      }else{
        handleSave(id);
      }
    }
  };

  const handleChangeNewRowDocumentType = (e) => {
    const value = e.target.value || "";
    setNewRowDocumentType(value.charAt(0).toUpperCase() + value.slice(1));
  };

  const renderDocumentTypeTable = () => {
    return (
      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        <Col span={10}>
          <span style={{ fontWeight: 700, paddingLeft: "15px" }}>
            <sup style={{ color: "red" }}>*</sup>
              {"Document Types"}
          </span>
        </Col>
        {(!showNewRow && isEmpty(editableId)) && (<Col span={6}>
            <Button type="primary" onClick={handleAddNewRow} icon="plus" size="small">
              Add New Document Type
            </Button>
        </Col>)}
      </Row>
    );
  };

  const renderInputs = () => {
    return (
      <>
        {documentTypes.map(
          (item, index) =>
            item && (
              <Row gutter={[16, 16]} key={item.id || item._id}>
                <Col span={10}>
                  {editableId === item.id ? (
                    <Input
                      value={editedDocumentType}
                      onChange={(e) =>
                        setEditedDocumentType(e.target.value)
                      }
                      autoFocus
                      ref={inputRef} 
                      onKeyPress = {(e) => handleKeyPress(e, item.id, "editableRow")}
                    />
                  ) : (
                    <Input value={item.name} disabled />
                  )}
                </Col>
                <Col span={6} style={{padding:'15px'}}>
                  {editableId === item.id ? (
                    <>
                      <Tooltip title={I18n.t("general.save")}>
                        <Icon
                          type="check-circle"
                          style={{ color: "green" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleSave(item.id)}
                        />
                      </Tooltip>
                      <Tooltip title={I18n.t("general.cancel")}>
                        <Icon
                          type="close-circle"
                          style={{ color: "red" }}
                          onClick={() => handleCancelEdit()}
                          size="small"
                          className="marginLeft10 fontSize18"
                        />
                      </Tooltip>
                    </>
                  ) : isEmpty(editableId) && !showNewRow && (
                    <>
                      <Tooltip title={I18n.t("general.edit")}>
                        <Icon
                          type="edit"
                          style={{ color: "blue", marginRight: "10px" }}
                          size="small"
                          className="marginLeft10 fontSize18"
                          onClick={() => handleEdit(item.id)}
                        />
                      </Tooltip>
                      <Popconfirm
                        placement="left"
                        title={I18n.t("messages.confirm")}
                        onConfirm={() => handleDelete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon className="textBold fontSize18" />
                        &nbsp;&nbsp;&nbsp;
                      </Popconfirm>
                    </>
                  )}
                  {!showNewRow && documentTypes.length - 1 === index && (
                    <>
                      {editableId ? null : (
                        <Tooltip title={I18n.t("general.add")}>
                          <Icon
                            type="plus-circle"
                            size="small"
                            style={{ marginTop: 7, color: "black" }}
                            className="marginLeft10 fontSize18"
                            onClick={() => setShowNewRow(true)}
                          />
                        </Tooltip>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )
        )}
      </>
    );
  };

  const renderNewRow = () => {
    return (
      <>
        {showNewRow && (
          <>
            {editableId ? null : (
              <Row gutter={[16, 16]} key="new-row">
                <Col span={10} className="right-aligned">
                  <Input
                    placeholder="Document Type"
                    value={newRowDocumentType}
                    onChange={handleChangeNewRowDocumentType}
                    onKeyPress = {(e) => handleKeyPress(e, "")}
                    autoFocus
                  />
                  {messageError && (
                    <div style={{ color: "red" }}>{messageError}</div>
                  )}
                </Col>
                <Col span={6} style={{padding:"15px"}}>
                  <Tooltip title={I18n.t("general.save")}>
                    <Icon
                      type="check-circle"
                      style={{ color: "green" }}
                      size="small"
                      className="marginLeft10 fontSize18"
                      onClick={() => handleSaveNewRow()}
                    />
                  </Tooltip>
                  <Tooltip title={I18n.t("general.cancel")}>
                    <Icon
                      type="close-circle"
                      size="small"
                      style={{ color: "red" }}
                      className="marginLeft10 fontSize18"
                      onClick={() => handleCancelEdit()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  };

  const renderDocumentType = () => {
    return (
      <div>
        <Row style={{ margin: "0px 10px 10px 10px" }}>
          {renderDocumentTypeTable()}
          {renderInputs()}
          {renderNewRow()}
        </Row>
      </div>
    );
  };

  return (
    <Spin spinning={inProgress} delay={1000}>
      <Row>
        <Col>
          <div style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}>
            {renderDocumentType()}
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default DocumentTypes;