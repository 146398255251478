import React from "react";
import BaseList from "../../BaseList";
import { Popconfirm, Icon, Row, Col } from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";

class TemplateParamsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns({ ...props });
  }
  setColumns = (props) => {
    this.columns = [
      {
        key: "code",
        title: I18n.t("general.details"),
        dataIndex: "event_code",
        width: "16%",
        render: (data, record) => (
          <div className="fontSize12">
            <Row>
              <Col>
                <span className="textBold">{I18n.t("templates.event")}:</span>
                &nbsp;&nbsp;{record.event_code}
              </Col>
              <Col>
                <span className="textBold">
                  {I18n.t("templates.mode_of_communication")}:
                </span>
                &nbsp;&nbsp;{record._mode_of_communication}
              </Col>
              <Col>
                <span className="textBold">
                  {I18n.t("templates.notification_to")}:
                </span>
                &nbsp;&nbsp;{record.notification_to}
              </Col>
            </Row>
          </div>
        ),
      },
      // {
      //   key: "mode_of_communication",
      //   title: I18n.t("templates.mode_of_communication"),
      //   dataIndex: "_mode_of_communication",
      //   width: 100,
      // },
      // {
      //   key: "notification_to",
      //   title: I18n.t("templates.notification_to"),
      //   dataIndex: "notification_to",
      //   width: 100,
      // },
      {
        key: "message",
        title: I18n.t("templates.message"),
        dataIndex: "message_content",
        render: (data, record) => (
          <div className="fontSize12">
            <pre>{data}</pre>
          </div>
        ),
      },
    ];
    if (props.showActions === true) {
      this.columns.push({
        key: "actions",
        title: "",
        dataIndex: "id",
        width: "6%",
        render: (data, record) => (
          <div className="line-actions">
            <EditIcon handleClick={() => this.props.editClick(data)}/>
            <Popconfirm
              placement="left"
              title={I18n.t("messages.delete_confirm")}
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.props.deleteClick(data)}
            >
              <DeleteIcon/>
              &nbsp;&nbsp;&nbsp;
            </Popconfirm>
          </div>
        ),
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default TemplateParamsList;
