import React from 'react';
import { Result, Spin } from 'antd';
import MultiRoute from "../../components/preplan/MultiRoute";
import PropTypes from "prop-types";
import { isEmpty } from '../../common/Common';

function PreplanMap (props) {
  const { orderInfo, selectedRoute, showOnMap, setCurrentRouteOnMap, markSelected, getMarkerId, selectedStopMarkerId, clickedRouteId, currentStop, handleCurrentRoute, rearrangeForm, openedStopInfoWindow, resetHoveredStop, routeMouseOperation , mapData , currentWarehouse , orderProgress , routeProgress , unassignedOrdersInfo , currentRoute,findDependencyOrders,handleUnassignOrdersInfo,totalOrders,onPanelClick,panelSize,showStopScreen,setClusteredOrder,
    renderCartIcon, setMapSelectedList, showBetaFeature} = props;

  let routes = [ ...mapData ];
  let showNumbers = false;
  if (
    rearrangeForm &&
    !isEmpty(currentRoute) &&
    !isEmpty(currentRoute.route_id)
  ) {
    const routeIndex = _.findIndex(routes, [
      "route_id",
      currentRoute.route_id,
    ]);
    if (routeIndex >= 0) {
      const cleanedRoute = Object.assign({}, currentRoute, {
        stops: [],
      });
      currentRoute.stops.map((stop) => {
        if (
          !isEmpty(stop.location) &&
          !isEmpty(stop.location.l_address) &&
          !isEmpty(stop.location.l_address.coordinates) &&
          stop.location.l_address.coordinates.length > 1
        ) {
          cleanedRoute.stops.push(stop);
        }
      });
      routes[ routeIndex ] = cleanedRoute;
    }
  }
  if (
    showOnMap &&
    !isEmpty(currentRoute) &&
    !isEmpty(currentRoute.route_id)
  ) {
    routes = routes.filter(
      (route) => route.route_id === currentRoute.route_id
    );
    showNumbers = true;
    //  orderInfo= [];
  }

  const currentWarehouseAddress = currentWarehouse.l_address;

  const markMultiple = (checked, ordersList) => {
    let orders = [];
    const previousRows = [ ...unassignedOrdersInfo.selectedOrderRows ];
    let uniqueDependencies = new Set();
    ordersList.forEach((currentRecord) => {
      const dependencies = findDependencyOrders(
        totalOrders,
        "related_order",
        "id",
        currentRecord,
        "object",
        "type_of_order"
      );
      dependencies.forEach((dependency) => {
        uniqueDependencies.add(dependency);
      });
    });
    const dependencyOrders = Array.from(uniqueDependencies);
    const dependencyIds = dependencyOrders.map((order) => order.id);
    if (checked === false) {
      orders = previousRows.filter(
        (order) => !dependencyIds.includes(order.id)
      );
    } else {
      const filteredItems = previousRows.filter(
        (order) => !dependencyIds.includes(order.id)
      );
      if (dependencyOrders.length > 0) {
        orders = [].concat(filteredItems, dependencyOrders);
      } else {
        orders = [ ...filteredItems ];
      }
    }
    handleUnassignOrdersInfo(orders);
  };
  const MapLoading = ({ loading }) => {
    if (loading) {
      return (
        <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' } }>
          <Spin size="large" />
          <p style={ { marginLeft: '16px' } }>Loading map...</p>
        </div>
      );
    }
    return null;
  };
  const NoDataFound = () => (
    <Result
      title="No Map Data Found"
      subTitle="Sorry, we couldn't find any data matching your criteria."
    />
  );
  // if(orderProgress || routeProgress){
  //   return <MapLoading loading={true} />
  // }

  // if no data is available then show no data component
  if (orderInfo.length === 0 && routes.length === 0) {
    return <NoDataFound />;
  }

  return (
      <Spin spinning={orderProgress || routeProgress}>
          <MultiRoute
            orders={ orderInfo || [] }
            selectedOrderKeys={
              unassignedOrdersInfo.selectedOrderKeys || []
            }
            routeInfo={ routes }
            selectedRoute={ selectedRoute }
            routeMouseOperation={
              rearrangeForm
                ? false
                : routeMouseOperation
            }
            selectedStop={
              currentStop && currentStop.id
                ? currentStop.id
                : ""
            }
            showNumbers={ showNumbers }
            showOnMap={ showOnMap }
            setShowOnMap={ setCurrentRouteOnMap }
            markSelected={ markSelected }
            sendMarkerId={ getMarkerId }
            markMultiple={ markMultiple }
            setClusteredOrder={setClusteredOrder}
            selectedStopMarkerId={ selectedStopMarkerId }
            clickedRouteId={ clickedRouteId }
            warehouseAddr={ currentWarehouseAddress }
            setCurrentRoute={ handleCurrentRoute }
            currentWarehouse={ currentWarehouse }
            rearrangeForm={ rearrangeForm }
            openedStopInfoWindow={ openedStopInfoWindow }
            resetHoveredStop={ resetHoveredStop }
            onPanelClick={ onPanelClick }
            panelSize={ panelSize }
            showStopScreen={ showStopScreen }
            renderCartIcon={renderCartIcon}
            setMapSelectedList={setMapSelectedList}
            showBetaFeature={showBetaFeature}
          />
      </Spin>
  );
}


export default PreplanMap;
PreplanMap.propTypes = {
  orderInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedRoute: PropTypes.shape(),
  showOnMap: PropTypes.bool.isRequired,
  setCurrentRouteOnMap: PropTypes.func.isRequired,
  markSelected: PropTypes.func.isRequired,
  getMarkerId: PropTypes.func.isRequired,
  selectedStopMarkerId: PropTypes.string,
  clickedRouteId: PropTypes.string,
  currentStop: PropTypes.shape(),
  markMultiple: PropTypes.func.isRequired,
  handleCurrentRoute: PropTypes.func.isRequired,
  rearrangeForm: PropTypes.bool.isRequired,
  openedStopInfoWindow: PropTypes.bool.isRequired,
  resetHoveredStop: PropTypes.func.isRequired,
  routeMouseOperation: PropTypes.bool.isRequired,
  mapData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


PreplanMap.defaultProps = {
  orderInfo: [],
  selectedRoute: {},
  showOnMap: false,
  setCurrentRouteOnMap: () => {},
  markSelected: () => {},
  getMarkerId: () => {},
  selectedStopMarkerId: "",
  clickedRouteId: "",
  currentStop: {},
  markMultiple: () => {},
  handleCurrentRoute: () => {},
  rearrangeForm: false,
  openedStopInfoWindow: false,
  resetHoveredStop: () => {},
  routeMouseOperation: false,
  mapData: [],
};