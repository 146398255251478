import React, { Component, Fragment } from "react";
import {
  Button,
  Col,
  Row,
  Icon,
  InputNumber,
  Spin,
  Radio,
  Select,
} from "../../common/UIComponents";
import {
  setAccountConfig,
  fetchAccountConfigs,
  fetchAccount,
} from "../../api/Account";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import userStore from "../../stores/UserStore";
import SwitchComponent from "../../common/switchComponent";
import AppConfig from "../../config/AppConfig";
import LabelInfo from "./LabelInfo";
import { fetchContactGroup } from "../../api/ContactGroup";
import { renderAlertMessage } from "../../helpers/common";
// const sessionOrgId = userStore.getStateValue("selectedOrg");

class CustomerOrderRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account || {},
      org_id: this.props.orgId || userStore.getStateValue("selectedOrg"),
      inProgress: false,
      apiProgress: false,
      config: {},
      ContactGroup : [],
      previousConfig: {},
      accountId: props.accountId || "",
      orderTypeValues: AppConfig.orderTypeValues,
      orderTypeZipcodes : AppConfig.orderTypeZipcodes,
      rules: [
        "move_api_orders_to_pending_label",
        "move_to_verify_by_schedule_label",
        "move_to_exception_on_invalid_number_label",
        "move_to_exception_on_invalid_delivery_zipcodes_label",
        "schedule_eta_orders_label",
        "move_to_exception_on_deadline_label",
        "require_accessorial_authorization_label",
        "consolidated_mawbs_label"
      ].map((rule) => {
        return {
          label: rule,
          value: rule.substring(0, rule.indexOf("_label")),
        };
      }),
    };
  }

  componentDidMount() {
    if (this.state.accountId !== "") {
      this.getAccountConfigs();
      this.getAccountDetails();
      this.getContactGroupNames();
    }
  }

  getAccountDetails = () => {
    this.setState({ inProgress: true });
    const { accountId } = this.state
    fetchAccount(accountId).then((result) => {
      if (result.success) {
        this.setState({
          account: Object.assign({}, this.state.account, result.account),
          inProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });

      }
    });
  };

  getAccountConfigs = () => {
    this.setState({ apiProgress: true });
    const { config, previousConfig } = this.state;
    const orgId = userStore.getStateValue("selectedOrg");
    const { accountId } = this.state;
    fetchAccountConfigs(accountId, orgId).then((result) => {
      if (result.success) {
        const accountConfigurations = result.account_configurations || [];
        accountConfigurations.forEach((setting) => {
          config[setting.setting_name] = setting.setting_value
          previousConfig[setting.setting_name] = setting.setting_value

        })
        if (
          !isEmpty(config.pod_pic_settings) &&
          _.isString(config.pod_pic_settings) &&
          config.pod_pic_settings === "[]"
        ) {
          config.pod_pic_settings = [];
          previousConfig.pod_pic_settings = [];
        }

        this.setState({
          apiProgress: false,
          config,
          previousConfig,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ apiProgress: false });
      }
    });
  };

  getContactGroupNames = () => {
    this.setState({ inProgress: true })
    const { accountId } = this.state
    fetchContactGroup({page: 1, perPage: null,render_type : "list",accountId}).then((result) => {
        if (result.success) {
          this.setState(
            {
              ContactGroup : result.contacts_groups || [],
              inProgress : false,
            },
          )
        } else {
          this.setState({ inProgress: false, ContactGroup: [] })
        }
      })
  }
  // handleSaveSuccess = (message) => {
  //   alertMessage(message);
  // };

  handleCheckBtn = (element, value) => {
    this.setState(
      {
        config: Object.assign({}, this.state.config, {
          [element]: value,
        }),
      },
      () => {
        if (
          false &&
          element === "move_to_exception_on_deadline" &&
          value == "false"
        ) {
          this.handleDetailsChange(
            I18n.t(`configurations.no_of_prior_days_to_deadline`),
            ""
          );
        }
      }
    );
  };

  handleDetailsChange = (element, value) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: value,
      }),
    });
  };

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => this.handleSave()}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    const data = {
      account_config: this.state.config,
      organization_id: orgId,
    };
    this.setState({
      inProgress: true,
    });
    setAccountConfig(data, this.state.accountId).then((result) => {
      if (result.success) {
        this.setState({
          inProgress: false,
          accountConfig: Object.assign({}, this.state.accountConfig, {
            [result.acct_config.acct_config.setting_name]:
              result.acct_config.acct_config.setting_value,
          }),
        });
        alertMessage(result.acct_config.message);
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  renderDependencies = (type) => {
    switch (type) {
      case "move_to_exception_on_deadline":
        const dependentKey = I18n.t(
          `configurations.no_of_prior_days_to_deadline`
        );
        const dependentLabel = I18n.t(
          `configurations.no_of_prior_days_to_deadline_label`
        );
        return (
          <Fragment>
            <Row type="flex" gutter={16}>
              <Col>{dependentLabel} :</Col>
              <Col>
                <InputNumber
                  value={this.state.config[dependentKey]}
                  onChange={(e) => this.handleDetailsChange(dependentKey, e)}
                  size={"small"}
                  style={{ width: 120 }}
                  step={1}
                  min={0}
                />
              </Col>
            </Row>
          </Fragment>
        );
      default:
        break;
    }
    return <Fragment />;
  };

  _renderCustomerOrderRules = () => (
    <Row>
      {Object.entries(this.state.rules).map(([key, { label, value }]) => {
        return (
          <Row key={key}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row className="configLableRow">
                <Col xs={23} sm={23} md={8} lg={8}>
                  {I18n.t(`configurations.${label}`)}
                  {
                    label !== "consolidated_mawbs_label" && ( 
                      <LabelInfo
                        title={I18n.t(`configurations.definitions.${label}_desc`)}
                      />
                    )
                  }
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                  :
                </Col>
                <Col xs={24} sm={24} md={3} lg={3}>
                  <SwitchComponent
                    handleChange={(e) => this.handleCheckBtn(value, e)}
                    isChecked={this.state.config[value] === "true"}
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checkedValue={"true"}
                    unCheckedValue={"false"}
                  />
                </Col>
                {false && (
                  <Col xs={24} sm={24} md={12} lg={12}>
                    {this.state.config[value] === "true" &&
                      this.renderDependencies(value)}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        );
      })}
    </Row>
  );

  render() {
    const { config, previousConfig, orderTypeValues,orderTypeZipcodes } = this.state;
    const dependentKey = I18n.t(`configurations.order_level_notifications_contact_group`);
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress || this.state.apiProgress} delay={1000}>
            <this._renderCustomerOrderRules />
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={8} lg={8}>
                    {I18n.t("configurations.signature_order_type")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.signature_order_type_desc"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5}>
                    <Radio.Group
                      onChange={(e) =>
                        this.handleCheckBtn(
                          "signature_order_type",
                          e.target.value
                        )
                      }
                      value={config.signature_order_type}
                    >
                      {Object.keys(orderTypeValues).map((key) => (
                        <Radio value={key} key={key}>
                          {orderTypeValues[key]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={8} lg={8}>
                    {I18n.t("configurations.validate_delivery_zipcode")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.validate_delivery_zipcode"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5}>
                    <Radio.Group
                      onChange={(e) =>
                        this.handleCheckBtn(
                          "validate_delivery_zipcode",
                          e.target.value
                        )
                      }
                      value={config.validate_delivery_zipcode}
                    >
                      {Object.keys(orderTypeZipcodes).map((key) => (
                        <Radio value={key} key={key}>
                          {orderTypeZipcodes[key]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={8} lg={8}>
                    {I18n.t("configurations.order_level_notifications_contact_group_label")}
                    <LabelInfo
                      title={I18n.t(
                        "configurations.definitions.order_level_notifications_contact_group"
                      )}
                    />
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5}>
                    <Select
                    showSearch
                    key ='order_level_notifications_contact_group'
                    style={{ width: 300}}
                    value ={config.order_level_notifications_contact_group || []}
                    onChange={(e) => this.handleDetailsChange(dependentKey, e)}
                    >
                         {this.state.ContactGroup.map((contacts_groups) => (
                            <Select.Option key={contacts_groups.id} value={contacts_groups.id}>
                              {contacts_groups.name}
                            </Select.Option>
                         ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>     <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Row className="configLableRow">
                  <Col xs={23} sm={23} md={8} lg={8}>
                    {I18n.t("configurations.cloning_accessorial_authorization")}
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    :
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5}>
                    <SwitchComponent
                      handleChange={(value) =>
                        this.handleCheckBtn("cloning_accessorial_authorization", value)
                      }
                      isChecked={
                        this.state.config["cloning_accessorial_authorization"] === "true"
                      }
                      checkedChildren={"Yes"}
                      unCheckedChildren={"No"}
                      checkedValue={"true"}
                      unCheckedValue={"false"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {this._renderFloatingBtn()}
          </Spin>
        </div>
      </div>
    );
  }
}

export default CustomerOrderRules;
