import React, { Fragment } from 'react';
import BaseList from '../BaseList';
import {  compareString, isEmpty, ellipseText, checkNegitive } from '../../common/Common';
import { Icon, Popconfirm, Popover, Tooltip } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { checkServiceExistance } from '../../helpers/common';
import I18n from '../../common/I18n';

class List extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
        render: (text, data) => ({
          props: {
            className: 'wordWrap',
          },
          children: data.children ? `${text} ` + `( ${data.children.length} )` : text,
        }),
        // sorter: (a, b) => compareString(a.name, b.name),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: 'code',
        title: I18n.t('general.code'),
        dataIndex: 'code',
      },
      {
        key: 'phone_number',
        title: I18n.t('general.mobile_number'),
        dataIndex: 'phone_number',
      },
      {
        key: 'email',
        title: I18n.t('general.email'),
        dataIndex: 'email',
      },
      {
        key: 'contact_person',
        title: I18n.t('account.contact_person'),
        dataIndex: 'contact_person',
        render: data => (
          <Tooltip title={data}>{ellipseText(data, 25)}</Tooltip>
        ),
      },
      {
        key: 'credit_limit',
        title: I18n.t('account.credit_limit.label'),
        dataIndex: 'credit_limit_amount',
        render: data => data ? checkNegitive(data) : '',
        align: 'right',
      },
      {
        key: 'out_standing_amount',
        title: I18n.t('account.credit_limit.out_standing_amount'),
        dataIndex: 'credit_due_amount',
        render: data => data ? checkNegitive(data) : '',
        align: 'right',
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: (id, data) => (
          <div className='line-actions paddingLeft10'>
            { checkServiceExistance(["ACOSL"], 'ANY') 
              && 
              <Tooltip
              title={
                <Fragment>{I18n.t('account.credit_limit.due_details')}</Fragment>
              }
              placement="top"
            >
              <Icon
                type="dollar"
                onClick={()=>this.props.detailsClick(id)}
                className={'textBold cursorPointer'}
                style={{color:"blue"}}
              />
            </Tooltip>
          }
          </div>
        ),
      }];
  }
}

export default List;
