import React, { Component, Fragment, useContext } from 'react'
import {
  Button,
  Col,
  Row,
  Icon,
  Spin,
  Radio,
  Input,
} from '../../common/UIComponents'
import {
  fetchOrgRules,
  fetchShortFormAccounts,
  updateOrgRules,
} from '../../api/Account'
import { alertMessage, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import SwitchComponent from '../../common/switchComponent'
import ETARules from './ETARules'
import { RulesApi } from '../../api/Rules'
import { setRulesData } from '../../helpers/configurations'
import AppConfig from '../../config/AppConfig'
import LabelInfo from './LabelInfo'
import { renderAlertMessage } from '../../helpers/common'
import { withRouter } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { OrgContext } from '../../context/OrgContext'
// const sessionOrgId = userStore.getStateValue('selectedOrg')

const groupForOptions = [
  { key: 'INVOICE', name: 'Invoice' },
  { key: 'BOL', name: 'BOL' },
  { key: 'SHIPPER_NOTIFICATIONS', name: 'Shipper Notifications' },
]

const ruleLables = [
  { shipper_eta_rules: 'Shipper ETA Rules'},
  { org_billing_rules: 'BOL' },
]
const defaultGroupOptionAccounts = {
  INVOICE: [],
  BOL: [],
  SHIPPER_NOTIFICATIONS: [],
}

class GeneralRulesComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accounts: [],
      org_id: this.props.orgId || userStore.getStateValue('selectedOrg'),
      inProgress: false,
      accountsProgress: false,
      apiProgress: false,
      config: {},
      previousConfig: {},
      errors: [],
      showError: false,
    }
  }

  componentDidMount() {
    this.getOrgConfigs()
    this.getAccounts()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEmpty(prevProps.activeKey) &&
      !_.isEqual(prevProps.activeKey, this.props.activeKey) &&
      this.props.activeKey === 'org_rules'
    ) {
      this.getOrgConfigs()
    }
  }

  getAccounts = () => {
    const orgId = this.state.organization_id
    this.setState({
      accountsProgress: true,
    })
    fetchShortFormAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ accountsProgress: false })
      }
    })
  }

  getOrgConfigs = () => {
    this.setState({ apiProgress: true })
    const { config, previousConfig } = this.state
    const orgId = userStore.getStateValue('selectedOrg')
    RulesApi.fetch(orgId).then((result) => {
      if (result.success) {
        const organization_rules = result.organization_rules || []
        this.setState({ apiProgress: false })
        if (organization_rules.length > 0) {
          const { updatedConfig, backupConfig} = setRulesData(organization_rules, config, previousConfig);
          this.setState(
            {
              config: updatedConfig,
              previousConfig: backupConfig,
            },
            () => {},
          )
        }
      } else {
        renderAlertMessage(result.errors)
        this.setState({ apiProgress: false })
      }
    })
  }

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => this.handleSave()}
    >
      <Icon type="save" />
      Save
    </Button>
  )

  handleSave = () => {
    const orgId = userStore.getStateValue('selectedOrg')
    const { setOrgRules } = this.props.userContext;
    const {fetchCreditData,accountsExceeded} = this.props.OrgContext;
    const config = _.cloneDeep(this.state.config)
    const rules = Object.keys(config).map((key) => {
      if(key === 'org_billing_rules'){
        if(isEmpty(config[key].start_driver_settlements_on)){
          config[key].start_driver_settlements_on = 'BILLING_APPROVAL'
        }
        if(isEmpty(config[key].invoice_header_address)){
          config[key].invoice_header_address = 'ORG'
        }
        if (isEmpty(config[key].require_prefix_for_invoice)) {
          config[key].require_prefix_for_invoice = 'false'
        } else {
          config[key].no_of_chars_for_invoice_no = isEmpty(
            config[key].no_of_chars_for_invoice_no,
          )
            ? 'ALL'
            : config[key].no_of_chars_for_invoice_no
        }
        if (isEmpty(config[key].invoice_configurations)) {
          config[key].invoice_configurations = 'true'
          config[key].org_invoice_length = 8
        } else {
          config[key].org_invoice_length = isEmpty(
            config[key].org_invoice_length,
          )
            ? "8"
            : config[key].org_invoice_length
        }

        if(
          config[key].move_unsatisfied_orders_to_unassigned === "false" &&
          !isEmpty(config[key].unsatisfied_orders_to_unassigned_apply_for) 
        ){
          config[key].unsatisfied_orders_to_unassigned_apply_for= "";
        }
        ["require_prefix_for_invoice", "require_out_standing_report"].forEach(rec => {
          if(isEmpty(config[key][rec])){
            config[key][rec] = 'false';
          }
        })
        
      }
      if(key === 'shipper_eta_rules'){
        if(isEmpty(config[key].enable_shipper_eta) || config[key].enable_shipper_eta === 'false'){
          config[key] = { enable_shipper_eta : "false" };
        } else {
          if (isEmpty(config[key].schedule_next_n_days)) {
            config[key].schedule_next_n_days = 1;
          }
          if (isEmpty(config[key].move_saturday_orders)) {
            config[key].move_saturday_orders = config[key].schedule_next_n_days
          }
          if (isEmpty(config[key].move_sunday_orders)) {
            config[key].move_sunday_orders = config[key].schedule_next_n_days
          }
        }
      }

      const settings = Object.keys(config[key]).map((setting_name) => ({
        setting_name: setting_name,
        setting_value: config[key][setting_name],
      }))
      return {
        name: ruleLables[key],
        rule_type: key,
        configurations: settings,
      }
    })
    const data = {
      rules,
      organization_id: orgId,
    }
    this.setState({
      apiProgress: true,
    })
    RulesApi.update(data).then((result) => {
      if (result.success) {
        const message = result.message ? result.message : I18n.t('messages.saved')
        if(accountsExceeded){
          fetchCreditData()
        }
        alertMessage(message);
        this.setState({
          apiProgress: false,
          errors: [],
          showError: false,
          //   accountConfig: Object.assign({}, this.state.accountConfig, {
          //     [result.acct_config.acct_config.setting_name]:
          //       result.acct_config.acct_config.setting_value,
          //   }),
        }, () => {
           if(config['org_billing_rules']?.round_off_decimals){
            localStorage.setItem('round_off_decimals', config['org_billing_rules'].round_off_decimals);
           } else {
            localStorage.setItem('round_off_decimals', AppConfig.default_decimal_points);
           }
           this.props.handleAppStateOnChange("organization_rules", config);
           setOrgRules(config);
           // window.location.reload();
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          errors: result.errors || [],
          apiProgress: false,
          showError: false,
        })
      }
    })
  }

  handleDetailsChange = (type, element, value) => {
    if (element === 'no_of_chars_for_invoice_no' && value === 'CUSTOM') {
      value = 2
    }
    const config = this.state.config ? this.state.config : {};
    if(isEmpty(config[type])){
      config[type] = {};
    }
    const subConfig = Object.assign({}, config[type], {
      [element]: value,
    })
    if (element === 'invoice_configurations' && value === 'false') {
      subConfig.org_invoice_length = "8";
      subConfig.require_prefix_for_invoice = 'false';
    }
    if (element === 'enable_shipper_eta' && value === 'true' && isEmpty(subConfig.cut_off_time)) {
      subConfig.cut_off_time = "15:00";
    }
    if (element === 'require_prefix_for_invoice') {
      if (value === 'true') {
        subConfig.no_of_chars_for_invoice_no = 'ALL'
      } else {
        subConfig.no_of_chars_for_invoice_no = ''
      }
    }
    if (element === "sequence_level_for" && value === "organization"){
      subConfig.require_prefix_for_invoice = 'false'
    }
    if (element === "move_unsatisfied_orders_to_unassigned" && value === "true"){
      subConfig.unsatisfied_orders_to_unassigned_apply_for = 'AUTO_PREPLAN'
    }
    this.setState({
      config: Object.assign({}, config, {
        [type]: subConfig,
      }),
    })
  }

  _renderCustomerOrderRules = () => {
    const { config } = this.state
    const billingRules = config['org_billing_rules'] ? config['org_billing_rules'] : {}
    const sequence_level_for = 
      !isEmpty(billingRules.sequence_level_for)
        ? billingRules.sequence_level_for
        : "account" 
    const no_of_chars_for_invoice_no =
      !isEmpty(billingRules.require_prefix_for_invoice) &&
      billingRules.no_of_chars_for_invoice_no !== 'ALL'
        ? 'CUSTOM'
        : 'ALL'
    return (
      <Row>
        <Col xs={24}>
          <Fragment>
            <Row>
              <Col xs={24} className="marginTop5">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Row className="configLableRow">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t(
                          'configurations.org_rules.start_billing_on_manual_completion',
                        )}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              'org_billing_rules',
                              'start_billing_on_manual_completion',
                              value,
                            )
                          }
                          isChecked={
                            billingRules[
                              'start_billing_on_manual_completion'
                            ] === 'true'
                          }
                          checkedChildren={'Yes'}
                          unCheckedChildren={'No'}
                          checkedValue={'true'}
                          unCheckedValue={'false'}
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {/* {I18n.t(
                          'configurations.org_rules.single_invoice_line',
                        )} */}
                        Single Invoice Line
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              'org_billing_rules',
                              'single_invoice_line',
                              value,
                            )
                          }
                          isChecked={
                            billingRules[
                              'single_invoice_line'
                            ] === 'true'
                          }
                          checkedChildren={'Yes'}
                          unCheckedChildren={'No'}
                          checkedValue={'true'}
                          unCheckedValue={'false'}
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t(
                          'configurations.org_rules.credit_limit_validation',
                        )}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              'org_billing_rules',
                              'credit_limit_validation',
                              value,
                            )
                          }
                          isChecked={
                            billingRules[
                              'credit_limit_validation'
                            ] === 'true'
                          }
                          checkedChildren={'Yes'}
                          unCheckedChildren={'No'}
                          checkedValue={'true'}
                          unCheckedValue={'false'}
                        />
                      </Col>
                    </Row>
                    <div className="marginTop10" />
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t(
                          'configurations.org_rules.invoice_configurations',
                        )}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              'org_billing_rules',
                              'invoice_configurations',
                              value,
                            )
                          }
                          // isChecked={
                          //   billingRules[
                          //     ('org_billing_rules', 'invoice_configurations')
                          //   ] === 'true'
                          // }
                          isChecked={
                            billingRules['invoice_configurations'] === 'true'
                          }
                          checkedChildren={'Yes'}
                          unCheckedChildren={'No'}
                          checkedValue={'true'}
                          unCheckedValue={'false'}
                        />
                      </Col>
                    </Row>
                    {billingRules.invoice_configurations === 'true' && (
                      <Fragment>
                        <div className="marginTop10" />

                        <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={9}>
                            {I18n.t(
                              'configurations.org_rules.org_invoice_length',
                            )}
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={14}
                            className="textBold"
                          >
                            <Input
                              value={
                                billingRules['org_invoice_length']
                              }
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  'org_billing_rules',
                                  'org_invoice_length',
                                  e.target.value,
                                )
                              }
                              type="number"
                              className="marginRight10"
                              style={{ width: 100 }}
                              min={1}
                              size="small"
                            />
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                     {billingRules.invoice_configurations === "true" && (
                      <Fragment>
                        <div className="marginTop10" />

                        <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={9}>
                            {I18n.t(
                              'configurations.org_rules.sequence_level_for',
                            )}
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={14}
                            className="textBold"
                          >
                            <Radio.Group
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  'org_billing_rules',
                                  'sequence_level_for',
                                  e.target.value,
                                )
                              }
                              value={sequence_level_for}
                            >
                              <Radio value="account" key="account">
                                Account Level
                              </Radio>
                              <Radio value="organization" key="organization">
                                Organization Level
                              </Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        </Fragment>
                        )}
                      { billingRules.invoice_configurations === 'true' && sequence_level_for === "account" && (
                      <Row className="configLableRow marginTop10">
                        <Col xs={23} sm={23} md={23} lg={9}>
                          {I18n.t(
                            'configurations.org_rules.require_prefix_for_invoice',
                          )}
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1}>
                          :
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14}>
                          <SwitchComponent
                            handleChange={(value) =>
                              this.handleDetailsChange(
                                'org_billing_rules',
                                'require_prefix_for_invoice',
                                value,
                              )
                            }
                            isChecked={
                              billingRules[
                                ('org_billing_rules', 'require_prefix_for_invoice')
                              ] === 'true'
                            }
                            checkedChildren={'Yes'}
                            unCheckedChildren={'No'}
                            checkedValue={'true'}
                            unCheckedValue={'false'}
                          />
                        </Col>
                      </Row>
                      )}
                    { billingRules.invoice_configurations === 'true' && sequence_level_for === "account" && billingRules.require_prefix_for_invoice === 'true' &&(
                      <Fragment>
                        <div className="marginTop10" />

                        <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={9}>
                            {I18n.t(
                              'configurations.org_rules.no_of_chars_for_invoice_no',
                            )}
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={14}
                            className="textBold"
                          >
                            <Radio.Group
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  'org_billing_rules',
                                  'no_of_chars_for_invoice_no',
                                  e.target.value,
                                )
                              }
                              value={no_of_chars_for_invoice_no}
                            >
                              <Radio value="ALL" key="ALL">
                                {I18n.t('general.all')}
                              </Radio>
                              <Radio value="CUSTOM" key="CUSTOM">
                                {I18n.t('general.custom')}
                              </Radio>
                            </Radio.Group>
                            {no_of_chars_for_invoice_no !== 'ALL' && (
                              <div className="marginLeft10">
                                No of characters:&nbsp;
                                <Input
                                  value={
                                    billingRules['no_of_chars_for_invoice_no']
                                  }
                                  onChange={(e) =>
                                    this.handleDetailsChange(
                                      'org_billing_rules',
                                      'no_of_chars_for_invoice_no',
                                      e.target.value,
                                    )
                                  }
                                  type="number"
                                  className="marginRight10"
                                  style={{ width: 100 }}
                                  min={1}
                                  size="small"
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                    <div className="marginTop10" />
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        Use Account Name For Billing Company
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "use_account_name_for_billing_company",
                              value
                            )
                          }
                          isChecked={
                            billingRules['use_account_name_for_billing_company']  === 'true'
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t(
                          "configurations.org_rules.send_single_invoice_line_per_order"
                        )}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "send_single_invoice_line_per_order",
                              value
                            )
                          }
                          isChecked={
                            billingRules['send_single_invoice_line_per_order']  === 'true'
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={9}>
                        {I18n.t(
                          "configurations.org_rules.show_gross_pay_on_weekly_settlement"
                        )}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "show_gross_pay_on_weekly_settlement",
                              value
                            )
                          }
                          isChecked={
                            billingRules['show_gross_pay_on_weekly_settlement']  === 'true'
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>

                    <Row className="configLableRow">
                      <Col xs={23} sm={23} md={23} lg={9}>
                      {I18n.t(
                          'configurations.org_rules.enable_checklist_approval',
                        )}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              'org_billing_rules',
                              'enable_checklist_approval',
                              value,
                            )
                          }
                          isChecked={
                            billingRules[
                            'enable_checklist_approval'
                            ] === 'true'
                          }
                          checkedChildren={'Yes'}
                          unCheckedChildren={'No'}
                          checkedValue={'true'}
                          unCheckedValue={'false'}
                        />
                      </Col>
                    </Row>
                    <ETARules
                      config={config['shipper_eta_rules']}
                      handleOnInputChange={(element, value) =>
                        this.handleDetailsChange(
                          'shipper_eta_rules',
                          element,
                          value,
                        )
                      }
                      handleCheckBtn={(element, value) =>
                        this.handleDetailsChange(
                          'shipper_eta_rules',
                          element,
                          value,
                        )
                      }
                      title={I18n.t('configurations.headings.shipper_eta_rules')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        </Col>
      </Row>
    )
  }
  _renderOtherRules = () => {

    const orgContextData = useContext(OrgContext);
    const { config } = this.state
    const billingRules = config['org_billing_rules'] ? config['org_billing_rules'] : {}
    const unsatisfiedCase = 
      !isEmpty(billingRules.move_unsatisfied_orders_to_unassigned)
        ? billingRules.move_unsatisfied_orders_to_unassigned
        : "false" 
    const unsatisfied_case_apply_for = 
    unsatisfiedCase ==='true' && !isEmpty(billingRules.unsatisfied_orders_to_unassigned_apply_for)
        ? billingRules.unsatisfied_orders_to_unassigned_apply_forOrgContext
      : "AUTO_PREPLAN";
    return (
      <Row>
        <Col xs={24}>
          <Fragment>
            <Row>
              <Col xs={24} className="marginTop5">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="marginTop10" />
                    <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={11}>
                            {"Round Off Decimal Points"}
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            className="textBold"
                          >
                            <Input
                              value={
                                (billingRules['round_off_decimals'])
                              }
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  'org_billing_rules',
                                  'round_off_decimals',
                                  e.target.value,
                                )
                              }
                              type="number"
                              className="marginRight10"
                              style={{ width: 100 }}
                              min={1}
                              size="small"
                            />
                          </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={11}>
                        {I18n.t("configurations.org_rules.move_unsatisfied_orders_to_unassigned")}
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.unsatisfied_orders_to_unassigned"
                          )}
                        />
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "move_unsatisfied_orders_to_unassigned",
                              value
                            )
                          }
                          isChecked={
                            billingRules["move_unsatisfied_orders_to_unassigned"] === "true"
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {I18n.t("configurations.org_rules.invoice_header_address")}
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.invoice_header_address"
                          )}
                        />
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                      <Radio.Group
                          onChange={(e) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "invoice_header_address",
                              e.target.value
                            )
                          }
                          value={billingRules["invoice_header_address"]}
                        >
                          <Radio value="ORG" key="ORG">
                            {I18n.t('organization.single')}
                          </Radio>
                          <Radio value="ACCOUNT" key="ACCOUNT">
                            {I18n.t('account.account')}
                          </Radio>
                          <Radio value="WH" key="WH">
                            {I18n.t('location.location')}
                          </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={11}>
                        {I18n.t("configurations.org_rules.require_driver_effort_insertion")}
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.require_driver_effort_insertion"
                          )}
                        />
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "require_driver_effort_insertion",
                              value
                            )
                          }
                          isChecked={
                            billingRules["require_driver_effort_insertion"] === "true"
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {I18n.t("configurations.org_rules.start_driver_settlements_on")}
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.start_driver_settlements_on"
                          )}
                        />
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14}>
                      <Radio.Group
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  "org_billing_rules",
                                  "start_driver_settlements_on",
                                  e.target.value
                                )
                              }
                              value={billingRules["start_driver_settlements_on"]}
                            >
                              <Radio value="BILLING_APPROVAL" key="BILLING_APPROVAL">
                                Billing
                              </Radio>
                              <Radio value="INVOICE_APPROVAL" key="INVOICE_APPROVAL">
                                Invoice
                              </Radio>
                              <Radio value="ORDER_COMPLETION_WITH_POD" key="ORDER_COMPLETION_WITH_POD">
                                Order Completion
                              </Radio>
                            </Radio.Group>
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={11}>
                        {I18n.t("configurations.org_rules.verify_billing_mawb_no")}
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.verify_billing_mawb_no_info"
                          )}
                        />
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        :
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "verify_billing_mawbs_orders",
                              value
                            )
                          }
                          isChecked={
                            billingRules["verify_billing_mawbs_orders"] === "true"
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>

                    {false && unsatisfiedCase === "true" && (
                      <Fragment>
                        <div className="marginTop10" />

                        <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={11} className='alignRight paddingRight10'>
                            {I18n.t("configurations.org_rules.unsatisfied_orders_to_unassigned_apply_for")}
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} className="textBold">
                            <Radio.Group
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  "org_billing_rules",
                                  "unsatisfied_orders_to_unassigned_apply_for",
                                  e.target.value
                                )
                              }
                              value={unsatisfied_case_apply_for}
                            >
                              <Radio value="AUTO_PREPLAN" key="AUTO_PREPLAN">
                                Auto Preplan
                              </Radio>
                              <Radio value="ROUTE" key="ROUTE">
                                Route
                              </Radio>
                              <Radio value="BOTH" key="BOTH">
                                Both
                              </Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                    <Row className="configLableRow marginTop10">
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {I18n.t("configurations.org_rules.invoice_date_type")}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>:</Col>
                      <Col xs={24} sm={24} md={12} lg={14}>
                        <Radio.Group
                          onChange={(e) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "invoice_date_type",
                              e.target.value
                            )
                          }
                          value={!_.isEmpty(billingRules["invoice_date_type"]) ? billingRules["invoice_date_type"] : 'approval_date'}
                        >
                          <Radio value="approval_date" key="approval_date">
                            Invoice Generation Date
                          </Radio>
                          <Radio value="invoice_approval_date" key="invoice_approval_date">
                            Invoice Approval Date
                          </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {I18n.t("configurations.org_rules.driver_reports_display")}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>:</Col>
                      <Col xs={24} sm={24} md={12} lg={14}>
                        <Radio.Group
                          onChange={(e) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "display_driver_set_reports_by",
                              e.target.value
                            )
                          }
                          value={!_.isEmpty(billingRules["display_driver_set_reports_by"]) ? billingRules["display_driver_set_reports_by"] : 'weekly'}
                        >
                          <Radio value="weekly" key="weekly">
                            Weekly
                          </Radio>
                          <Radio value="daily" key="daily">
                            Daily
                          </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={23} sm={23} md={23} lg={11}>
                        {I18n.t("configurations.org_rules.show_zero_invoice_lines")}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>:</Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange("org_billing_rules", "show_zero_invoice_lines", value)
                          }
                          isChecked={billingRules["show_zero_invoice_lines"] === "true"}
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={"true"}
                          unCheckedValue={"false"}
                        />
                      </Col>
                    </Row>
                      <Row className="configLableRow marginTop10">
                      <Col xs={ 23 } sm={ 23 } md={ 23 } lg={ 11 }>
                        { I18n.t("configurations.org_rules.send_invoice_to_driver_after_invoice_approval") }
                      </Col>
                      <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 }>
                        :
                      </Col>
                      <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 }>
                        <SwitchComponent
                          handleChange={ (value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "send_invoice_to_driver_after_invoice_approval",
                              value
                            )
                          }
                          isChecked={
                            billingRules[ "send_invoice_to_driver_after_invoice_approval" ] === "true"
                          }
                          checkedChildren={ "Yes" }
                          unCheckedChildren={ "No" }
                          checkedValue={ "true" }
                          unCheckedValue={ "false" }
                        />
                      </Col>
                    </Row>
                    {/* config for show billing approve modl */}
                    <Row className="configLableRow marginTop10">
                      <Col xs={ 23 } sm={ 23 } md={ 23 } lg={ 11 }>
                        { I18n.t("configurations.org_rules.show_approve_modal_lable") }
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.show_approve_modal_desc"
                          )}
                        />
                      </Col>
                      <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 }>
                        :
                      </Col>
                      <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 }>
                        <SwitchComponent
                          handleChange={ (value) =>
                            this.handleDetailsChange(
                              "org_billing_rules",
                              "ask_to_provide_invoice_no_upon_billing_approval",
                              value
                            )
                          }
                          isChecked={
                            billingRules[ "ask_to_provide_invoice_no_upon_billing_approval" ] === "true"
                          }
                          checkedChildren={ "Yes" }
                          unCheckedChildren={ "No" }
                          checkedValue={ "true" }
                          unCheckedValue={ "false" }
                        />
                      </Col>
                    </Row>
                    <Row className="configLableRow marginTop10">
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {I18n.t("configurations.org_rules.create_manual_billing")}
                        <LabelInfo
                          title={I18n.t(
                            "configurations.definitions.create_manual_billing"
                          )}
                        />
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>:</Col>
                      <Col xs={24} sm={24} md={12} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange("org_billing_rules", "create_manual_billing", value)
                          }
                          isChecked={
                            billingRules["create_manual_billing"]
                          }
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={true}
                          unCheckedValue={false}
                        />
                      </Col>
                    </Row>
                    {/* <Row className="configLableRow marginTop10">
                      <Col xs={24} sm={24} md={24} lg={11}>
                        {I18n.t("configurations.org_rules.show_monthly_cut_off")}
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>:</Col>
                      <Col xs={24} sm={24} md={12} lg={14}>
                        <SwitchComponent
                          handleChange={(value) =>
                            this.handleDetailsChange("org_billing_rules", "show_monthly_cut_off", value)
                          }
                          isChecked={billingRules["show_monthly_cut_off"]}
                          checkedChildren={"Yes"}
                          unCheckedChildren={"No"}
                          checkedValue={true}
                          unCheckedValue={false}
                        />
                      </Col>
                    </Row> */}
                    <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={11}>
                            {I18n.t(('configurations.org_rules.invoice_logo_width'))}
                            <LabelInfo
                              title={I18n.t(
                                "configurations.definitions.invoice_logo_width_desc"
                              )}
                            />
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            className="textBold"
                          >
                            <Input
                              value={
                                (billingRules['invoice_logo_width'])
                              }
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  'org_billing_rules',
                                  'invoice_logo_width',
                                  e.target.value,
                                )
                              }
                              type="number"
                              className="marginRight10"
                              style={{ width: 100 }}
                              min={1}
                              size="small"
                            />
                          </Col>
                    </Row>
                    <Row className="marginTop10 configLableRow">
                          <Col xs={23} sm={23} md={23} lg={11}>
                            {I18n.t('configurations.org_rules.invoice_logo_height')}
                            <LabelInfo
                              title={I18n.t(
                                "configurations.definitions.invoice_logo_height_desc"
                              )}
                            />
                          </Col>
                          <Col xs={1} sm={1} md={1} lg={1}>
                            :
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            className="textBold"
                          >
                            <Input
                              value={
                                (billingRules['invoice_logo_height'])
                              }
                              onChange={(e) =>
                                this.handleDetailsChange(
                                  'org_billing_rules',
                                  'invoice_logo_height',
                                  e.target.value,
                                )
                              }
                              type="number"
                              className="marginRight10"
                              style={{ width: 100 }}
                              min={1}
                              size="small"
                            />
                          </Col>
                    </Row>
                    
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className="content">
        <Spin spinning={this.state.apiProgress} delay={1000}>
          <Row gutter={32}>
            <Col xs={11}>
              <this._renderCustomerOrderRules />
            </Col>
            <Col xs={11}>
              <this._renderOtherRules />
            </Col>
          </Row>
          {this._renderFloatingBtn()}
        </Spin>
      </div>
    )
  }
}

export const GeneralRules = withRouter((props) => {
  const userContextData = useContext(UserContext);
  const accountLimitExceeded = useContext(OrgContext)
  return <GeneralRulesComponent {...props} userContext={userContextData} OrgContext={accountLimitExceeded} />;
});

export default GeneralRules;

