import React, { Component } from "react";
import { TemplateParamsApi } from "../../../api/TemplateParamsApi";
import {  doValidate, formatCode } from "../../../common/Common";
import { Row, Col, Input, FormItem } from "../../../common/UIComponents";
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import I18n from "../../../common/I18n";

const requiredFields = [
  {
    form_field: "code",
    ui_name: I18n.t("template_params.code"),
    isRequired: true,
    inputType: "code",
  }
];
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      currentTemplate: this.props.currentTemplate
        ? this.props.currentTemplate
        : {},
      errors: [],
      isNew: this.props.isNew,
    };
  }
  handleSave = () => {
    this.setState({ inProgress: true });
    const data = Object.assign({}, this.state.currentTemplate);
    const errors = doValidate(requiredFields, data);
    if (errors.length === 0) {
      data.code = formatCode(data.code);
      TemplateParamsApi.manage(this.state.isNew, data).then((result) => {
        if (result.success) {
          this.setState({ inProgress: true, errors: [] });
          this.props.onSuccess("Template saved");
        } else {
          this.setState({ inProgress: false , errors: result.errors});
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };
  handleOnChange = (element, value) => {
    this.setState({
      currentTemplate: Object.assign({}, this.state.currentTemplate, {
        [element]: value,
      }),
    });
  };
  render() {
    const { currentTemplate } = this.state;
    return (
      <div>
        <Row gutter={16}>
          <Col sm={12} xs={12} md={8} lg={24}>
            <FormItem label={I18n.t("template_params.code")} require>
              <Input
                value={currentTemplate.code}
                onChange={(e) => this.handleOnChange("code", e.target.value)}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12} md={8} lg={24}>
            <FormItem label={I18n.t("template_params.description")}>
              <Input
                value={currentTemplate.description}
                onChange={(e) =>
                  this.handleOnChange("description", e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel
            )}
          </Col>
        </Row>
         <Row><Col>{FormErrors(this.state.errors)}</Col></Row>
      </div>
    );
  }
}

export default Form;
