import React, { useState } from 'react'
import { RearrangableTable } from '../../common/RearrangableTable';

export const InsightsSettlements = (props) => {
  const[isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      name: "John Smith",
      driver_code: "123456",
      deduction_name: "Late Arrival",
      deduction_amount: 50,
      week_range: "09 may 2023 - 15 may 2023",
    },
    {
      key: 2,
      name: "Jane Doe",
      driver_code: "789012",
      deduction_name: "Damaged Equipment",
      deduction_amount: 100,
      week_range: "19 may 2023 - 25 may 2023",
    },
  ]);
  const [columns, setColumns] = useState([
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "driver_code",
      title: "Driver Code",
      dataIndex: "driver_code",
    },
    {
      key: "deduction_name",
      title: "Deduction Name",
      dataIndex: "deduction_name",
    },
    {
      key: "deduction_amount",
      title: "Deduction Amount",
      dataIndex: "deduction_amount",
    },
    {
      key: "week_range",
      title: "Week Range",
      dataIndex: "week_range",
    },
  ]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "InsightsDriverSettlements_list_view_columns";


  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
}
