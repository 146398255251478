/* global google */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polygon, Marker, InfoWindow, Circle } from 'react-google-maps';
import MainMap from '../../common/Map';
import MapCustomControl from '../../common/MapCustomControl';
import Form from './Form';
import AppConfig from '../../../config/AppConfig';
import _ from 'lodash';
import { I18n, isArrayEqual } from '../../../common/Common';
import { Button } from 'antd';

const {
  DrawingManager,
} = require('react-google-maps/lib/components/drawing/DrawingManager');

class Map extends React.Component {
  constructor(props) {
    super(props);
    const isOpen = [];
    if (props.zones) {
      props.zones.forEach((zone) => {
        isOpen[zone.id] = false;
      });
    }
    this.state = {
      map: '',
      centerLatLng: [],
      isOpen,
    };
    this.circle = React.createRef();
    this.onMapMounted = this.onMapMounted.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.currentZone && nextProps.currentZone) {
      if (!isArrayEqual(this.props.currentZone.geoFenceGeometryData, nextProps.currentZone.geoFenceGeometryData) || !isArrayEqual(this.props.zones, nextProps.zones)) {
        this.doSetBounds();
      }
    }
  }
  onToggleOpen = (e, id) => {
    const { isOpen } = this.state;
    isOpen[id] = !this.state.isOpen[id];
    this.setState({
      isOpen,
    });
  }

  onMapMounted(mapObj) {
    // if (!this.state.map) {
    //   const map = this.state.map ? this.state.map : mapObj;
    //   if (this.props.currentZone.polygon_coords) {
    //     const bounds = new window.google.maps.LatLngBounds();
    //     this.props.currentZone.polygon_coords.forEach((point) => {
    //       bounds.extend(new window.google.maps.LatLng(point[0], point[1]));
    //     });
    //     map.fitBounds(bounds);
    //   }
    //   this.setState({
    //     map,
    //   });
    // }
    if (!this.state.map) {
      const map = this.state.map ? this.state.map : mapObj;
      this.setState({
        map,
      }, () => {
        this.doSetBounds();
      });
    }
  }

    doSetBounds = () => {
      if (window.google && this.state.map) {
        this.setState({ inProgress: true, isMapLoading: true }, () => {
          const bounds = new window.google.maps.LatLngBounds();
          if (this.props.currentZone && this.props.currentZone.geoFenceGeometryData && this.props.currentZone.geoFenceGeometryData.length > 0) {
            this.props.currentZone.geoFenceGeometryData.forEach((point) => {
              bounds.extend(new window.google.maps.LatLng(point[0], point[1]));
            });

            this.state.map.fitBounds(bounds);
          } else {
            if (this.props.zones.length > 0) {
              this.props.zones.filter(zone => zone.polygon_coords && zone.polygon_coords.length > 0).forEach((zone) => {
                zone.polygon_coords.forEach((point) => {
                  bounds.extend(new window.google.maps.LatLng(point[0], point[1]));
                });
              });
              bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat, this.props.defaultCenter.lng));
            } else {
              bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat - 0.017, this.props.defaultCenter.lng - 0.005));
              bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat, this.props.defaultCenter.lng));
              bounds.extend(new window.google.maps.LatLng(this.props.defaultCenter.lat - 0.017, this.props.defaultCenter.lng + 0.005));
            }
            this.state.map.fitBounds(bounds);
          }
          this.center = bounds.getCenter();
          this.setState({
            centerLatLng: { latitude: this.center.lat(), longitude: this.center.lng() },
          });
        });
      }
    }

  handleDragEnd = (e) => {
    const geometryData = this.props.currentZone.geoFenceGeometryData;
    if (e.vertex) {
      geometryData[e.vertex] = [e.latLng.lat(), e.latLng.lng()];
    } else if (e.edge >= 0) {
      geometryData.splice(e.edge + 1, 0, [e.latLng.lat(), e.latLng.lng()]);
    }
    this.props.handleSave({ geoFenceGeometryData: geometryData });
  }

  handleCurrentDragEnd = (e) => {
    const geometryData = this.props.currentZone.polygon_coords;
    if (e.vertex) {
      geometryData[e.vertex] = [e.latLng.lat(), e.latLng.lng()];
    } else if (e.edge >= 0) {
      geometryData.splice(e.edge + 1, 0, [e.latLng.lat(), e.latLng.lng()]);
    }
    this.props.handleSave({ geoFenceGeometryData: geometryData });
  }
  getCircleBounds = () => {
    const ne = this.circle.current.getBounds().getNorthEast();
    const sw = this.circle.current.getBounds().getSouthWest();
    const geometryData = [];
    geometryData.push([ne.lat(), ne.lng()]);
    geometryData.push([sw.lat(), sw.lng()]);
    this.props.handleSave({ geoFenceGeometryData: geometryData });
  }


  render() {
    return (
      <MainMap
        centerLatLng={this.state.centerLatLng}
        {...this.props}
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
        containerElement={<div style={{ width: '100%', height: '100%' }} />}
        mapElement={<div style={{ width: '100%', height: '50vh' }} />}
        onMapMounted={this.onMapMounted}
        defaultZoom={8}
        defaultCenter={this.props.defaultCenter}
        zoomControl
      >
        <div >
          { false && this.props.currentZone.isManualDraw && this.props.currentZone.radius &&
            <Fragment>
              {/* <MapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}>
                <Button size="small" type="primary" onClick={() => this.getCircleBounds()}>
                  {I18n.t('general.save')}
                </Button>
          </MapCustomControl> */}
              <Circle
                defaultCenter={{
                  lat: parseFloat(this.props.defaultCenter.lat),
                  lng: parseFloat(this.props.defaultCenter.lng),
                }}
                ref={this.circle}
                radius={parseInt(this.props.currentZone.radius)}
                options={{
                  fillColor: '#dc9f01', // dc9f01
                  fillOpacity: 0.2,
                  strokeColor: '#dc9f01', // dc9f01
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
              />
            </Fragment>
          }
          {
            false && this.state.map ?
              // eslint-disable-next-line no-undef
              <MapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}>
                <Form
                  handleChange={this.props.handleChange}
                  handleReDraw={this.props.handleReDraw}
                  onDraw={this.props.onDraw}
                  {...this.props}
                  handleOnSave={this.props.handleOnSave}
                />
              </MapCustomControl> :
              <Fragment />
          }

        </div>
        {
          this.props.currentZone.geoFenceGeometryData && !this.props.onDraw ?
            <Fragment>
              <Polygon
                onMouseUp={e => this.handleDragEnd(e)}
                path={this.props.currentZone.geoFenceGeometryData.map(point => ({ lat: point[0], lng: point[1] }))}
                key={2}
                options={{
                  fillColor: '#2E2E2E',
                  fillOpacity: 0.4,
                  strokeColor: '#2E2E2E',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
                editable
                clickable
              />
              {/* <Marker
                key="current_marker"
                position={{ lat: this.props.currentZone.geoFenceGeometryData[0][0], lng: this.props.currentZone.geoFenceGeometryData[0][1] }}
                onClick={e => this.onToggleOpen(e, 'current_marker')}
                visible
              /> */}
            </Fragment> : <Fragment />
        }
        {
          this.props.zones && this.props.zones.length > 0 ?
            <Fragment>
              {
                this.props.zones.filter(zone => zone.polygon_coords && zone.polygon_coords.length > 0 && zone.id !== this.props.currentZone.id).map((zone, index) =>
                  (
                    <Fragment>
                      <Polygon
                        path={zone.polygon_coords.map(point => ({ lat: point[0], lng: point[1] }))}
                        key={`polygon${zone.id}`}
                        options={{
                          fillColor: '#dc9f01', // dc9f01
                          fillOpacity: 0.2,
                          strokeColor: '#dc9f01', // dc9f01
                          strokeOpacity: 1,
                          strokeWeight: 1,
                        }}
                        onClick={e => this.onToggleOpen(e, zone.id)}
                      />
                      <Marker
                        key={`marker${zone.id}`}
                        position={{ lat: zone.polygon_coords[0][0], lng: zone.polygon_coords[0][1] }}
                        onClick={e => this.onToggleOpen(e, zone.id)}
                        visible={false}
                      >
                        {this.state.isOpen[zone.id] &&
                        <InfoWindow
                          onCloseClick={e => this.onToggleOpen(e, zone.id)}
                          options={{ alignTop: true, closeBoxURL: '', enableEventPropagation: true }}
                        >
                          <h3>{zone.name}</h3>
                        </InfoWindow>
                        }
                      </Marker>
                    </Fragment>
                  ))
              }
            </Fragment> : <Fragment />
        }
        {
          false && !this.props.currentZone.geoFenceGeometryData && this.props.currentZone.polygon_coords && !this.props.onDraw ?
            <Fragment>
              <Polygon
                path={this.props.currentZone.polygon_coords.map(point => ({ lat: point[0], lng: point[1] }))}
                onMouseUp={e => this.handleCurrentDragEnd(e)}
                key={1}
                options={{
                  fillColor: '#2E2E2E',
                  fillOpacity: 0.4,
                  strokeColor: '#2E2E2E',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
                editable
                clickable
                onClick={() => {
                }}
              />
            </Fragment> : <Fragment />
        }
        {
          this.props.onDraw && this.state.map ?
            <Fragment>
              <DrawingManager
                // eslint-disable-next-line no-undef
                drawingMode={google.maps.drawing.OverlayType.POLYGON}
                defaultOptions={{
                  drawingControl: false,
                  drawingControlOptions: {
                    // eslint-disable-next-line no-undef
                    position: google.maps.ControlPosition.TOP_LEFT,
                    drawingModes: [
                      // eslint-disable-next-line no-undef
                      google.maps.drawing.OverlayType.POLYGON,
                    ],
                  },
                  circleOptions: {
                    fillColor: '#ffff00',
                    fillOpacity: 1,
                    strokeWeight: 5,
                    clickable: false,
                    editable: true,
                    zIndex: 1,
                  },
                }}
                onPolygonComplete={(polygon) => {
                  const polygonArray = polygon.getPath().getArray();
                  const geoFenceGeometryData = polygonArray.map(point => ([point.lat(), point.lng()]));
                  polygon.setMap(null);
                  this.props.handleSave({ geoFenceGeometryData, ...this.props });
                }}
              />
            </Fragment> : <Fragment />
        }

      </MainMap>
    );
  }
}

Map.propTypes = {
  handleSave: PropTypes.func.isRequired,
  currentZone: PropTypes.shape(),
  onDraw: PropTypes.bool,
  zoneName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReDraw: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  defaultCenter: PropTypes.shape().isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
Map.defaultProps = {
  currentZone: {},
  onDraw: false,
};

export default Map;
