/* eslint-disable react/sort-comp */
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Col,
  Input,
  FormItem,
  Row,
  DatePicker,
  Select,
  Radio,
  Button,
} from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { getRecoveryStatusIcon } from "../../helpers/recovery";
import ZonesList from "../../containers/ZonesList";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import { removeSpecialCharacters } from "../../helpers/common";
import { isEmpty } from "../../common/Common";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { RadioGroup } = Radio.Group;

const ARChequeFilter = (props) => {
  const {
    filterPlaceHolder,
    setFilterPlaceHolder,
    onSearch,
    elementSize = "small",
    showDateFilter = true,
    defaultFilter,
  } = props;
  const [filter, setFilter] = useState(_.cloneDeep(defaultFilter));

  // useEffect(() => {
  //   setFilterPlaceHolder({...filter});
  // }, [filter])

  const updateFilter = (element, value) => {
    setFilter({ ...filter, [element]: value });
  };

  const resetFilter = () => {
    setFilter({ ...defaultFilter });
    setFilterPlaceHolder({...defaultFilter});
  };

  const updateParentFilter = (filter) => {
    setFilter({ ...filter })
    setFilterPlaceHolder({...filter});
  }

  const { dateSearchBy, dateSearchValue, batch_no,  check_ach_no } = filter;
  return (
    <div className="padding10 recoveryFilter">
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Col>
          <FormItem label={I18n.t("batch_management.batch_name")}>
            <Search
              placeholder={I18n.t("batch_management.batch_name")}
              className="seachWithDropDown recoverySearch search_with_prefix"
              size={elementSize}
              value={!isEmpty(batch_no) ? batch_no : ""}
              onSearch={(value) => {
                updateParentFilter({...filter, batch_no: value})
              }}
              onChange={(e) => {
                const filteredString = removeSpecialCharacters(e.target.value);
                updateFilter("batch_no", filteredString);
              }}
              style={{ width: 160, textTransform: "uppercase" }}
              onClear={(e) => {
                updateParentFilter({...filter, batch_no: ''})
              }}
              allowClear
            />
          </FormItem>
        </Col>
        <Col>
        <FormItem label={I18n.t("batch_management.check_ach_no")}>
            <Search
              placeholder={I18n.t("batch_management.check_ach_no")}
              className="seachWithDropDown recoverySearch search_with_prefix"
              size={elementSize}
              value={!isEmpty(check_ach_no) ? check_ach_no : ""}
              onSearch={(value) => {
                updateFilter("check_ach_no", value);
                updateParentFilter({...filter, check_ach_no: value})
                //setSearchKeyPress(true)
              }}
              onChange={(e) => {
                const filteredString = removeSpecialCharacters(e.target.value);
                updateFilter("check_ach_no", filteredString);
              }}
              style={{ width: 160, textTransform: "uppercase" }}
              onClear={(e) => {
                updateParentFilter({...filter, check_ach_no: ""})
              }}
              allowClear
            />
          </FormItem>
        </Col>
        {showDateFilter && (
          <Col>
            <FormItem label={"Date Search"}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                gutter={5}
              >
                <Col span={9}>
                  <Select
                    size="small"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        dateSearchBy: e,
                        dateSearchValue: {
                          fromDate: moment(),
                          toDate: moment().add(1, "week"),
                        },
                      });
                    }}
                    className="recoveryFilter__dateSearchBy"
                    style={{
                      textAlign: "center",
                      lineHeight: "1",
                      width: "100%"
                    }}
                    value={dateSearchBy}
                  >
                    <Select.Option key="payment_date" value="payment_date">
                      Payment Date
                    </Select.Option>
                    <Select.Option key="check_date" value="check_date">
                      Check Date
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={15}>
                  <RangePicker
                    defaultValue={[moment().startOf("month"), moment()]}
                    value={[
                      dateSearchValue.fromDate,
                      dateSearchValue.toDate,
                    ]}
                    ranges={{
                      Today: [moment(), moment()],
                      "Today & Tomorrow": [moment(), moment().add(1, "day")],
                      "This Week": [moment().startOf("week"), moment()],
                      "This Month": [moment().startOf("month"), moment()], // moment().endOf('month')
                      // 'This Year': [moment().startOf('year'), moment()],
                      // 'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                      // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    }}
                    size="small"
                    format={AppConfig.dateFormat}
                    onChange={(dates, dateStrings) =>
                      !(dateStrings[0] !== "" && dateStrings[1] !== "")
                        ? setFilter({
                            ...filter,
                            dateSearchValue: {
                              fromDate: null,
                              toDate: null,
                            },
                          })
                        : setFilter({
                            ...filter,
                            dateSearchValue: {
                              fromDate: dates[0],
                              toDate: dates[1],
                            },
                          })
                    }
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>
        )}
        <Row type="flex" justify="center" gutter={4} style={{ marginTop: 20}}>
          
          <Col>
            <Button
              onClick={() => {
                // onSearch(filter)
                setFilterPlaceHolder({ ...filter });
              }}
              type="primary"
              size={elementSize}
            >
              {I18n.t("general.search")}
            </Button>
          </Col>
          <Col>
            <Button type="danger" onClick={resetFilter} size={elementSize}>
              {I18n.t("general.reset")}
            </Button>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

ARChequeFilter.propTypes = {};
ARChequeFilter.defaultProps = {};
export default withRouter(ARChequeFilter);
