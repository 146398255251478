import React, { Component } from "react";
import {
  Col,
  FormItem,
  Input,
  Row,
  Switch,
} from "../common/UIComponents";
import FormButtons from "./common/FormButtons";
import {doValidate, formatCode } from "../common/Common";
import _ from "lodash";
import FormErrors from "./common/FormErrors";
import I18n from "../common/I18n";

const requiredFields = [
  {
    form_field: "code",
    ui_name: I18n.t("general.code"),
    isRequired: true,
    inputType: "code",
  },
  {
    form_field: "sub_code",
    ui_name: I18n.t("activity.subCode"),
    isRequired: false,
    inputType: "code",
  },
  {
    form_field: "fe_status_code",
    ui_name: I18n.t("activity.feStatusCode"),
    isRequired: false,
    inputType: "code",
  },
  {
    form_field: "description",
    ui_name: I18n.t("general.description"),
    isRequired: true,
    inputType: "text",
  },
];

class ActivityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData:
        this.props.Data && this.props.Data.id
          ? this.props.Data
          : {
              code: "",
              sub_code: "",
              fe_status_code: "",
              description: "",
              allow_duplicates: false,
              generate_status_file: false,
            },
      inProgress: false,
      Errors: this.props.errors,
    };
  }

  handleOnChange = (element, value) => {
    this.setState({
      formData: Object.assign({}, this.state.formData, { [element]: value }),
    });
  };

  handleSwitchChange = (element, value) => {
    if (element === "allow_duplicates") {
      const allow_duplicates = this.state.formData.allow_duplicates;
      value = allow_duplicates === true ? false : true;
    }
    if (element === "generate_status_file") {
      const generate_status_file = this.state.formData.generate_status_file;
      value = generate_status_file === true ? false : true;
    }
    this.setState({
      formData: Object.assign({}, this.state.formData, { [element]: value }),
    });
  };

  handleSave = () => {
    const errors = doValidate(requiredFields, this.state.formData);
    if (errors.length === 0) {
      this.state.formData.code = formatCode(this.state.formData.code);
      this.state.formData.sub_code = formatCode(this.state.formData.sub_code);
      this.state.formData.fe_status_code = formatCode(
        this.state.formData.fe_status_code
      );
      this.props.onSuccess(this.state.formData);
    } else {
      this.setState({
        Errors: errors,
        inProgress: false,
      });
    }
  };

  render() {
    return (
      <div>
        <Row gutter={8}>
          <Col xs={12}>
            <FormItem label={I18n.t("general.code")} require>
              <Input
                value={this.state.formData.code}
                onChange={(e) => this.handleOnChange("code", e.target.value)}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label={I18n.t("activity.subCode")}>
              <Input
                value={this.state.formData.sub_code}
                onChange={(e) =>
                  this.handleOnChange("sub_code", e.target.value)
                }
                className="textUpperCase"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem label={I18n.t("activity.feStatusCode")}>
            <Input
              value={this.state.formData.fe_status_code}
              onChange={(e) =>
                this.handleOnChange("fe_status_code", e.target.value)
              }
              className="textUpperCase"
            />
          </FormItem>
        </Row>
        <Row>
          <FormItem label={I18n.t("general.description")} require>
            <Input
              value={this.state.formData.description}
              onChange={(e) =>
                this.handleOnChange("description", e.target.value)
              }
            />
          </FormItem>
        </Row>
        <Row gutter={8}>
          <Col xs={12}>
            <div style={{ fontWeight: 700 }} className="marginBottom15">
              {I18n.t("activity.allowDuplicates")}:&nbsp;&nbsp;&nbsp;
              <Switch
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checked={this.state.formData.allow_duplicates === true}
                onChange={(e) => this.handleSwitchChange("allow_duplicates", e)}
                className={
                  !this.state.formData.allow_duplicates
                    ? "switchComponent"
                    : null
                }
              />
            </div>
          </Col>
          <Col xs={12}>
            <div style={{ fontWeight: 700 }} className="marginBottom15">
              {I18n.t("activity.genStatusFile")}:&nbsp;&nbsp;&nbsp;
              <Switch
                checkedChildren={"Yes"}
                unCheckedChildren={"No"}
                checked={this.state.formData.generate_status_file === true}
                onChange={(e) =>
                  this.handleSwitchChange("generate_status_file", e)
                }
                className={
                  !this.state.formData.generate_status_file
                    ? "switchComponent"
                    : null
                }
              />
            </div>
          </Col>
        </Row>
        <Row>{FormErrors(this.state.Errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default ActivityForm;
