/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import BaseList from '../BaseList';
import {isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import { contrast } from '../../common/Colors';
import { Icon, Dropdown, Button, Tooltip, Badge, Avatar, Menu } from '../../common/UIComponents';
import driver from '../../../assets/images/driver.png';

class RoutesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.columns = [
      {
        key: 'r_name',
        title: I18n.t('general.name'),
        // ellipsis: true,
        render: data => (
          <div
            style={{
              whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px',
            }}
          >
            {
              !isEmpty(data.primary_driver_image_url) ?
                <Tooltip title={data.primary_driver}>
                  <Avatar src={data.primary_driver_image_url} size={20} />
                </Tooltip> :
                (data.primary_driver ?
                  <Tooltip title={data.primary_driver}>
                    <Avatar
                      size={20}
                      className="avatar_list_img"
                    >
                      {data.primary_driver_short_code}
                    </Avatar>
                  </Tooltip> :
                  '')
            }
            {
              !isEmpty(data.secondary_driver_image_url) ?
                <Tooltip title={data.secondary_driver}>
                  <Avatar src={data.secondary_driver_image_url} size={20} />
                </Tooltip> :
                (
                  data.secondary_driver ?
                    <Tooltip title={data.secondary_driver}>
                      {data.secondary_driver_short_code}
                      <Avatar
                        size={20}
                        style={{
                          fontSize: 12, color: `#${data.bgColor}`, backgroundColor: '#FFF', border: '1px solid #757575',
                        }}
                      >
                        {data.secondary_driver_short_code}
                      </Avatar>
                    </Tooltip>
                    :
                    ''
                )
            }
          </div>
        ),
      },
      /* {
        key: 'wh',
        title: I18n.t('order.warehouse_code'),
        dataIndex: 'wh_name',
        ellipsis: true,
        render: data => (
          <div style={{
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px',
          }}
          className="text-capitalize"
          >
            <Tooltip title={data ? <span className="text-capitalize">{data.toLowerCase()}</span> : ''}>{data ? data.toLowerCase() : ''}</Tooltip>
          </div>
        ),
      }, */
      {
        key: 'r_start_date',
        title: I18n.t('days.date'),
        ellipsis: true,
        render: data => (
          <div style={{
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '120px',
          }}
          >
            {data.route_start_time ?
              (moment(new Date(data.route_start_time)).format('MMM, Do'))
              : 'NA'}
          </div>
        ),
        width: 70,
        sorter: (a, b) => {
          if ((a.route_start_time === b.route_start_time)) {
            return 0;
          }
          if (a.route_start_time) {
            return (-1);
          }
          return (1);
        },
        defaultSortOrder: 'ascend',

      },
      {
        key: 'Stops',
        title: I18n.t('preplan.stops'),
        ellipsis: true,
        width: 47,
        align: 'center',
        render: data => (
          <Badge  count={data.nav_stops_count} style={{ backgroundColor: `#${data.bgColor}`, color: `#${contrast(data.bgColor)}` }} />
        ),
      }];


    this.columns.push({
      key: 'reassign',
      width: 60,
      align: 'right',
      render: record => (
        <div>

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={(e) => {
                    this.props.handleComplete(record.id);
                    e.domEvent.stopPropagation();
                  }
                  }
                  disabled={record.status === 'COMPLETED'}
                >
                  <Icon type="check" />&nbsp;{I18n.t('routes.complete')}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={(e) => {
                    this.props.assignDriver(record.id);
                    e.domEvent.stopPropagation();
                  }
                  }
                  disabled={record.status === 'COMPLETED'}
                >
                  <img src={driver} style={{ width: 18 }} alt="driver" />&nbsp;&nbsp;{I18n.t('general.reassign')}

                </Menu.Item>
              </Menu>}
          >
            <Button
              size="small"
              type="default"
              disabled={record.status === 'COMPLETED'}
              className="fontSize12 action_button"
              onClick={(e) => {
                e.stopPropagation();
              }
              }
            >Actions
            </Button>
          </Dropdown>

        </div>
      ),
    });
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default RoutesList;
