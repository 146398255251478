import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { Col, FormItem, Input, Row, TextArea } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import FormButtons from '../common/FormButtons';
import { saveSurvey } from '../../api/SurveysApi';
import FormErrors from '../common/FormErrors';

const RadioGroup = Radio.Group;

class SurveyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: this.props.survey ? this.props.survey : {},
      isNew: this.props.isNew,
      inProgress: false,
      errors: [],
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleOnChange(element, value) {
    this.setState({ survey: Object.assign({}, this.state.survey, { [element]: value }) });
  }

  handleSave() {
    this.setState({ inProgress: true });
    const data = this.state.survey;
    saveSurvey(this.state.isNew, data)
      .then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t('messages.saved'));
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }

  render() {
    const { survey } = this.state;
    return (
      <div>
        <Row gutter={16}>
          <Col sm={12} xs={12} md={8} lg={24}>
            <FormItem label={I18n.t('general.name')}>
              <Input
                value={survey.name}
                onChange={e => this.handleOnChange('name', e.target.value)}
              />
            </FormItem>
          </Col>
          {
             !this.state.isNew ?  <Col sm={12} xs={12} md={8} lg={24}>
             <FormItem label={I18n.t('general.status')}>
             <RadioGroup
                   onChange={e => this.handleOnChange('status', e.target.value)}
                   value={survey.status}
                   style={{ marginTop: 3 }}
                 >
                   <Radio value='active'>Active</Radio>
                   <Radio value='inactive'>Inactive</Radio>
                 </RadioGroup>
             </FormItem>
           </Col>: ''
          }

          <Col sm={12} xs={12} md={8} lg={24}>
            <FormItem label={I18n.t('general.description')}>
              <TextArea
                value={survey.description}
                onChange={e => this.handleOnChange('description', e.target.value)}
              />
            </FormItem>
          </Col>

        </Row>
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
        <Row>
          <Col xs={24}>
            {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)}
          </Col>
        </Row>
      </div>
    );
  }
}

SurveyForm.propTypes = {
  survey: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
};

SurveyForm.defaultPropTypes = {
 survey: {},
 isNew: true,
};

export default SurveyForm;
