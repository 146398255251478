import React, { Component } from "react";
import Stop from "./Stop";

class StopRoutes extends Component {
	render() {
		// Rendering stop markers
		const { routes } = this.props;
		return routes.map((route, routeIndex) => {
			return (
				<Stop
					key={routeIndex}
					routeIndex={routeIndex}
					route={route}
					{...this.props}
				/>
			);
		});
	}
}
export default StopRoutes;
