import React, { Component, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Col,
  Row,
  Popconfirm,
  Button,
  notification,
  Spin,
  Select,
  DatePicker,
  FormItem,
  TreeSelect,
  Modal,
} from "../../common/UIComponents";

import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import RoutesList from "./AllRoutesList";
import { sendStatusFile } from "../../api/Routes";
import userStore from "../../stores/UserStore";
import AppConfig from "../../config/AppConfig";
import { fetchLocations } from "../../api/LocationsApi";
import {
  fetchPreplansRoutes,
  sendPriorDayNotification,
} from "../../api/PreplanApi";
import { momentTime } from "../../helpers/date_functions";
import {
  driverNameFromRoute,
  drivercodeFromRoute,
  formatTime,
  formatDistance,
  renderAlertMessage,
} from "../../helpers/common";
import { isRouteOptimized } from "../../helpers/preplan";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";

const { TreeNode } = TreeSelect;
class GenerateRouteFileComponent extends Component {
  constructor(props) {
    super(props);
    const { currentPreplan } = props;
    const isPreplan = currentPreplan && currentPreplan.id;
    this.state = {
      routeInfo: [],
      currentPreplan,
      routes: {
        selectedKeys: [],
        selectedRows: [],
      },
      filters: {
        startDate:
          isPreplan && currentPreplan.delivery_start_date
            ? moment(currentPreplan.delivery_start_date)
            : moment().add(1, "days"),
        warehouse_ids:
          isPreplan && currentPreplan.warehouse_id
            ? [currentPreplan.warehouse_id]
            : [],
      },
      inProgress: false,
      isInitial: false,
      warehouses: [],
      currentWarehouse: {},
      isPreplan,
      warehousesProgress: false,
      organizationSettings : {},
      isMilitaryTime : false,
    };
  }

  componentDidMount() {
    this.setDelectedData();
    this.getWarehouses();
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'});
  }


  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
    if (!_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses)) {
      this.getWarehouses();
    }
  }

  handleRouteGenerate = () => {
    const { filters, currentPreplan } = this.state;
    let data = {
      start_date: filters.startDate
        ? momentTime("YYYY/MM/DD", filters.startDate)
        : null,
    };
    if (this.state.isPreplan || filters.warehouse_id || filters.startDate) {
      if (this.state.isPreplan) {
        data.pre_plan_id = currentPreplan.id;
      } else {
        data.warehouse_ids =
          filters.warehouse_ids.length > 0
            ? filters.warehouse_ids.join(",")
            : "";
      }
      data.organization_id = userStore.getStateValue("selectedOrg");
      this.setState({ inProgress: true });
      fetchPreplansRoutes(data).then((result) => {
        if (result.success) {
          const routeInfo = result.preplan || [];
          // const allRoutes = preplanDetails.map(
          //    (preplan) => preplan.nav_route_details
          // );
          // const routeInfo = _.flatten(allRoutes);
          const newRoutes = routeInfo.map((route) => {
            const formattedName = driverNameFromRoute(route, "object");
            const formattedCode = drivercodeFromRoute(route, "object");
            return {
              route_id: route.nav_route_id,
              route_name: route.nav_route_name,
              route_start_datetime: route.route_start_datetime,
              route_confirmed_at: route.route_confirmed_at,
              displayName: formattedCode || formattedName || "",
              customer_orders: route.customer_orders || 0,
              stops: route.stops || [],
              total_time: formatTime(route.total_time),
              total_distance: formatDistance(route.total_distance),
              status: route.nav_route_status,
              id: route.nav_route_id,
              warehouse_details: route.warehouse_details || {},
              optimized: route.optimized,
              isOptimized: isRouteOptimized(route),
              notified_prior_day_at: route.notified_prior_day_at,
            };
          });
          this.setState({
            routeInfo: [...newRoutes],
            inProgress: false,
          });
        } else {
          this.setState({ inProgress: false });
          renderAlertMessage(result.errors)
        }
      });
    }
  };

  getWHIds = () => {
    const { warehouses } = this.state;
    return warehouses.map((wh) => wh.id);
  };
  handleFilterChange = (element, value) => {
    const { filters, warehouses } = this.state;
    if (element === "startDate" && isEmpty(value)) {
      value = moment();
    }
    if (element === "warehouse_ids") {
      if ((value.length > 0 && value.includes("ALL")) || value.length === 0) {
        value = this.getWHIds();
      }
    }
    this.setState(
      {
        filters: Object.assign({}, filters, { [element]: value }),
        isPreplan: false,
      },
      () => {
        this.handleRouteGenerate();
      }
    );
  };

  setWarehouse = (id = null, setFirstOne = false) => {
    let currentWarehouse = {};
    let whIndex = -1;
    if (id) {
      whIndex = _.findIndex(this.state.warehouses, { id });
    } else if (setFirstOne) {
      whIndex = 0;
    }

    if (whIndex >= 0) {
      currentWarehouse = this.state.warehouses[whIndex];
    }
    this.setState({ currentWarehouse });
  };

  getWarehouses = () => {
    this.setState({
      warehousesProgress: true,
    });
    const warehouses = this.props.warehouseFilter.warehouses
    this.setState(
      {
        warehouses,  
        warehousesProgress: false,
      },
      () => {
        if (!this.state.isPreplan) {
          const selectedWhs = Array.isArray(this.props.warehouseFilter.selectedWarehouses) ? this.props.warehouseFilter.selectedWarehouses : [this.props.warehouseFilter.selectedWarehouses];
          this.handleFilterChange("warehouse_ids", selectedWhs);
        } else {
          this.handleRouteGenerate();
        }
      }
    );
  };

  setDelectedData = () => {
    const routeInfo = [...this.state.routeInfo];
    this.setState(
      {
        routes: {
          selectedKeys: routeInfo
            .filter((route) => route.isOptimized === true)
            .map((route) => route.route_id),
          selectedRows: [...routeInfo],
        },
      },
      () => {
      }
    );
  };

  // static getDerivedStateFromProps(props, state) {
  //     const { routeInfo } = props;
  //     const { currentPreplan } = props;
  //     return {
  //         routeInfo,
  //         currentPreplan,
  //     };
  // }

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      routes: Object.assign({}, this.state.routes, {
        selectedKeys: selectedRowKeys,
        selectedRows,
      }),
      selectAll: false,
    });
  };

  handleClear = () => {
    this.handleSelectChange([], []);
  };

  handlePriorDayNotification = () => {
    const route_ids = this.state.routes.selectedKeys;
    this.setState({ inProgress: true });
    sendPriorDayNotification(route_ids).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        this.handleRouteGenerate();
        this.setState({ inProgress: false });
      } else {
        this.setState({ inProgress: false });
        alertMessage(result.errors, "error", 10);
      }
    });
  };

  handleGenerare = () => {
    const preplanRoutes = [...this.state.routes.selectedKeys];
    if (preplanRoutes.length > 0) {
      const notOptimizedRoutes = this.state.routeInfo
        .filter(
          (route) =>
            preplanRoutes.includes(route.route_id) &&
            route.isOptimized === false
        )
        .map((route) => route.displayName);
      if (notOptimizedRoutes.length > 0) {
        const uniqRoutes = _.uniq(notOptimizedRoutes);
        Modal.error({
          title: `The following route${uniqRoutes.length > 1 ? "s are" : "is"
            } not optimised`,
          content: (
            <Row>
              <Col>
                <h4>{uniqRoutes.join(", ")}</h4>
              </Col>
              <Col>
                {`Please make sure all selected routes are optimized before generating manifest file.`}
              </Col>
            </Row>
          ),
        });
      } else {
        this.setState({ inProgress: true });
        const requiredData = {
          nav_route_ids: preplanRoutes,
          organization_id: userStore.getStateValue("selectedOrg"),
        };
        sendStatusFile(requiredData).then((result) => {
          if (result.success) {
            this.setState({ inProgress: false });
            this.props.onCancel();
            notification.success({
              message: I18n.t("messages.confirmed"),
              placement: "bottomRight",
            });
          } else {
            renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
        });
      }
    }
  };

  render() {
    const { currentPreplan, routeInfo, isPreplan, warehouses, filters } =
      this.state;
    const selectedRowKeys = [...this.state.routes.selectedKeys];
    // if (this.state.selectAll === true) {
    // selectedRowKeys = routeInfo.map(route => route.route_id);
    // } else {
    // selectedRowKeys = this.state.routes.selectedKeys;
    // }
    const whNames = warehouses
      .filter((warehouse) => filters.warehouse_ids.includes(warehouse.id))
      .map((warehouse) => warehouse.name);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: null,
    };

    // const isConfirmed = routeInfo.every(
    //   (route) => route.route_confirmed_at === null
    // );

    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            {" "}
            {isPreplan && (
              <Col md={6}>
                <div className="info-text">
                  <h4>{I18n.t("general.name")}:</h4>
                  {currentPreplan.name ? currentPreplan.name : "NA"}{" "}
                </div>
              </Col>
            )}
            <Col md={isPreplan ? 12 : 18}>
              <div className="info-text">
                <h4>{I18n.t("location.locations")}:</h4>
                {whNames && whNames.length > 0 ? whNames.join(", ") : "NA"}{" "}
              </div>
            </Col>
            <Col md={6}>
              <div className="info-text">
                <h4>{I18n.t("general.scheduled_date")}:</h4>
                <h4 className="primaryText textBold">
                  {filters.startDate
                    ? moment(new Date(filters.startDate)).format("Do MMM, YYYY")
                    : "NA"}
                </h4>
              </div>
            </Col>
          </Row>
          <hr />
          <div>
            <Row gutter={12}>
              <Col xs={8}>
                <FormItem label="Date">
                  <DatePicker
                    style={{
                      width: "100%",
                      marginLeft: "1%",
                    }}
                    placeholder="Select Date"
                    format="Do MMM YYYY"
                    onChange={(e) => this.handleFilterChange("startDate", e)}
                    value={this.state.filters.startDate}
                    defaultValue={null}
                    onOk={this.onOk}
                  />
                </FormItem>
              </Col>
              <Col xs={8}>
                <FormItem label={I18n.t("location.location")}>
                  <TreeSelect
                    value={this.state.filters.warehouse_ids}
                    showSearch
                    className="minWidth"
                    style={{ width: "100%" }}
                    dropdownStyle={{
                      maxHeight: 300,
                      overflow: "auto",
                    }}
                    placeholder="--Select--"
                    allowClear
                    treeDefaultExpandAll
                    dropdownMatchSelectWidth={false}
                    onChange={(e) =>
                      this.handleFilterChange("warehouse_ids", e)
                    }
                    treeCheckable
                    maxTagCount={1}
                    loading={this.state.warehousesProgress}
                  >
                    <TreeNode value="ALL" title="All" key="0-1">
                      {this.state.warehouses.map((warehouse) => (
                        <TreeNode
                          value={warehouse.id}
                          title={`${warehouse.name}`}
                          key={warehouse.id}
                        />
                      ))}{" "}
                    </TreeNode>
                  </TreeSelect>
                </FormItem>
              </Col>
            </Row>
            <RoutesList
              rowKey="route_id"
              isMilitaryTime={this.state.isMilitaryTime}
              data={routeInfo || []}
              pagination={{ position: "none" }}
              rowSelection={rowSelection}
              isPreplan={this.state.isPreplan}
              rowClassName={(record) =>
                record.isOptimized === false
                  ? "blockBgErrorColor"
                  : "table-row-light"
              }
            />
          </div>
          <Row className="relative_bottom_fixed">
            <Col
              xs={24}
              style={{ padding: "10px 10px" }}
              className="alignCenter"
            >
              <Button type="danger" onClick={this.handleClear} icon="close-circle">
                {I18n.t("general.clear")}{" "}
              </Button>
              &nbsp;&nbsp;
              <Popconfirm
                placement="rightTop"
                title={I18n.t("messages.confirm")}
                onConfirm={this.handleGenerare}
                okText="Yes"
                cancelText="No"
                disabled={selectedRowKeys.length === 0}
              >
                <Button type="primary" disabled={selectedRowKeys.length === 0} icon="check-circle">
                  {I18n.t("general.confirm")}{" "}
                </Button>
              </Popconfirm>
              {/* <Fragment>
              &nbsp;&nbsp;
              <Button
                type="primary"
                disabled={selectedRowKeys.length === 0}
                onClick={this.handlePriorDayNotification}
              >
                {I18n.t("general.send_notify")}
              </Button>
              </Fragment> */}
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export const GenerateRouteFile = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <GenerateRouteFileComponent
      warehouseFilter={ warehouseFilter }
      organizationSettings={ organizationSettings }
      { ...props }
    />
  );
});

export default GenerateRouteFile;