import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { isEmpty } from "../../../common/Common";
import {
  Tooltip,
  Popover,
  Button,
  Spin,
  Row,
  Col,
  Link,
} from "../../../common/UIComponents";
import { fetchOrderItems } from "../../../api/OrdersApi";

import UnprocessedReasonList from "./UnprocessedReasonList";

const ReasonTable = ({
  reasons = [],
  status = "",
  keyId,
  children,
  primaryKey = "id",
  selectedPages = [],
  handleSelectChange,
}) => {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visibleValue) => {
    setVisible(visibleValue);
  };

  // Render the Popover and the button with a custom class name
  return (
    <Popover
      title={
        <Row>
          <Col xs={20} className="fontSize12 textBold">
            Reason
          </Col>
          <Col xs={4} className="alignRight">
            <span onClick={hide} className="clickableLink fontSize11 textBold">
              X
            </span>
          </Col>
        </Row>
      }
      trigger="click"
      placement="left"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={
        <div
          className="smallTable"
          style={{ maxWidth: 550, maxHeight: 350, overflowY: "auto" }}
        >
          <div className="notes_content textBold textAllocated"  style={{ fontSize: '0.9rem'}}>Select pages to process them</div>
          <UnprocessedReasonList
            data={reasons}
            size="small"
            pagination={{ position: "none" }}
            scroll={{
              y: 200,
              x: 400,
            }}
            rowKey={"page_number"}
            rowSelection={{
              selectedRowKeys: selectedPages || [],
              onChange: handleSelectChange,
            }}
          />
        </div>
      }
    >
      <span className="popover-button textBold" id={keyId}>
        {" "}
        <Link onClick={() => {}}>{children}</Link>
      </span>
    </Popover>
  );
};

// ReasonTable.propTypes = {
//   reasons: PropTypes.shape().isRequired,
// };

export default ReasonTable;
