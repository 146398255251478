import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popconfirm, Button } from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import { alertMessage } from "../../common/Common";
import { RecoveriesApis } from "../../api/RecoveriesApi";
import ChangeRecoveryTime from "../orders/ChangeRecoveryTime";
import OptingAppointment from "../orders/OptingAppointment";
import I18n from "../../common/I18n";

const RecoveryActions = ({
  selectedInfo,
  handleClear,
  filter,
  setIsListLoading,
  refreshList,
  multipleDeletion,
  changeStatus,
  warehouseFilter,
  organizationSettings,
  resetRecoveries,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(false)

  const changeIndividualStatus = async (status) => {
    try {
      setIsListLoading(true)
      const response = await RecoveriesApis.changeStatus({
        ids: selectedInfo.selectedKeys,
        status,
      })
      if (response.success) {
        const successMessage = response?.message
          ? response.message
          : I18n.t('messages.saved')
        if (response.errors && response.errors.length > 0) {
          // alertMessage(response.errors[0], 'error', 10)
          setErrors([...response.errors])
        } else {
          alertMessage(successMessage)
          refreshList()
        }
      } else {
        // alertMessage(response.errors, 'error', 10)
        setErrors([...response.errors])
      }
    } catch (error) {
      setErrors([error.message])
      alertMessage(error.message, 'error', 10)
    } finally {
      setIsListLoading(false)
    }
  }

  const handleDelete = () => {
    multipleDeletion(selectedInfo.selectedKeys)
  }

  const resetForm = () => {
    selectedInfo.selectedKeys = []
    selectedInfo.selectedRows = []
    refreshList()
  }

  return (
    <Fragment>
    <Row className="bottom_fixed">
      <Col xs={24} style={{ padding: "10px 10px" }} className="alignCenter">
        <Button
          type="danger"
          onClick={handleClear}
          icon="close"
        >
          {I18n.t('list.clear_lable')}
        </Button>
        &nbsp;&nbsp;
        {/* {checkServiceExistance("ORDEL") &&
            <Popconfirm
              placement="rightTop"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" className="buttonStyle">
                Delete
              </Button>
            </Popconfirm>
        } */}
        {checkServiceExistance("ORCRT") &&  !["completed", 'assigned'].includes(filter) &&
            // <ChangeRecoveryTime
            //   resetRecoveries={resetRecoveries}
            //   recoveries={selectedInfo.selectedKeys || []}
            //   filter={filter}
            // />
            <OptingAppointment
            resetOrders={resetForm}
            orders={selectedInfo.selectedKeys || []}
            filter={filter}
            warehouseFilter={ warehouseFilter }
            organizationSettings={organizationSettings}
            selectedOrders={selectedInfo.selectedRows || []}
            showSelectedOrder={true}
          />
        }
        {checkServiceExistance("ORCS") && filter !== "completed" && (
          <Button
            type="primary"
            className="assignDriver"
            onClick={() =>changeStatus()}
            disabled={!(selectedInfo.selectedKeys.length > 0)}
            icon="setting"
          >
            {I18n.t('general.change_status')}
          </Button>
        )}
      </Col>
    </Row>
    {
        errors.length > 0 && Modal.error({
          title: 'Errors',
          content: (
            <Row>
              <Col>
                <h4>{errors.join(", ")}</h4>
              </Col>
            </Row>
          ),
          onOk: () => {
            setErrors([])
            refreshList()
          },
        })
    }
    </Fragment>
  );
};

RecoveryActions.propTypes = {
    filter: PropTypes.string.isRequired,
    selectedInfo: PropTypes.shape.isRequired,
    handleClear: PropTypes.func.isRequired,
    refreshQuotes: PropTypes.func.isRequired,
    setIsListLoading: PropTypes.func.isRequired,
    multipleDeletion: PropTypes.func.isRequired,
};

export default RecoveryActions;
