/* eslint-disable react/sort-comp */
import React, {  useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  Col, Input, FormItem, Row, DatePicker,
  Select, Button,
} from '../../common/UIComponents';
import I18n from '../../common/I18n';
import { fetchShortFormAccounts } from '../../api/Account';
import { fetchLocations } from '../../api/LocationsApi';
import AppConfig from '../../config/AppConfig';

const { Search } = Input;
const { RangePicker } = DatePicker;

const QuotesFilter = ({
  filterValues,
  onChange,
  onSearch,
  history,
  warehouseFilter
}) => {
  const [accountsLoading, setAccountsLoading] = useState(false)
  const [wHLoading, setWHLoading] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [filter, setFilter] = useState({...filterValues})

  useEffect(() => {
    getAccounts(warehouseFilter.selectedWarehouses);
  }, [warehouseFilter.selectedWarehouses, warehouseFilter.warehouseWithAccounts])

  useEffect(() => {
    setFilter({...filterValues});
  }, [filterValues])


  // const getAccounts = async () => {
  //   try {
  //       setAccountsLoading(true);
  //       const result = await fetchShortFormAccounts();
  //       if (result.success) {
  //           setAccounts(result.accounts)
  //       } else {
  //           setAccounts([])
  //       }
  //   } catch (error) {
  //     alertMessage(error.message, 'error', 10)
  //   } finally {
  //     setAccountsLoading(false);
  //   }
  // };

  const getAccounts = (whId) => {
    const accountsData =  warehouseFilter.findMatchingAccounts(whId)
     setAccounts(accountsData);
  }

  const getWarehouses = async () => {
    try {
        setWHLoading(true);
        const result = await fetchLocations(AppConfig.warehouseCode, 1, null)
        if (result.success) {
            setWarehouses(result.locations.locations)
        } else {
            setWarehouses([])
        }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setWHLoading(false);
    }
  };

  const renderSearchOrder = () => (
    <Search
      placeholder={ `${I18n.t('general.search')} ${I18n.t('order.order_no')}` }
      onSearch={ (value) => {
        handleFilterChange('search_order_token', value);
      } }
      onChange={ (e) => {
        // clear url search params
        if (window.location.search.indexOf("order_number") > -1) {
          history.replace("/invoices");
        }
        setFilter({ ...filter, search_order_token: e.target.value } );
      } }
      value={ filter.search_order_token }
      style={ { width: "100%", textTransform: "uppercase" } }
      className="textUpperCase"
      allowClear
    />
  );

  const renderAccountsList = () => (
    <FormItem label={ I18n.t('account.account') }>
      <Select
        value={ filter.accountCode }
        showSearch
        style={ { width: '100%' } }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={ accountsLoading }
        onChange={ (e) => handleFilterChange('accountCode', e) }
      >
        <Select.Option key='all' value=''>
              -- All --
            </Select.Option>
            {accounts?.map(account => (
              <Select.Option key={account.id} value={account.code}>
                {`${account.name} (${account.code})`}
              </Select.Option>
            ))}
      </Select>
    </FormItem>
  );
  const renderWHList = () => (
    <FormItem label={ I18n.t("location.location") }>
      <Select
        value={ filter.warehouse_id }
        showSearch
        style={ { width: '100%' } }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={ accountsLoading }
        onChange={ (e) => handleFilterChange('warehouse_id', e) }
      >
        <Select.Option key='all' value=''>
          -- All --
        </Select.Option>
        { warehouses.map((warehouse) => (
          <Select.Option
            key={ warehouse.location_code }
            value={ warehouse.id }
          >
            { warehouse.name }
          </Select.Option>
        )) }
      </Select>
    </FormItem>
  );

  const clearForm = () => {
    const filter = {
      fromDate: moment().subtract(6, 'day'),
      toDate: moment(),
      accountCode: '',
      viewType: 'byOrder', // byOrder
      search_order_token: '',
      currentStatus: 'PENDING',
    };
    setFilter(filter);
    onChange(filter , () => { onSearch(); });
  };

  const handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[ 0 ] !== '' && dateStrings[ 1 ] !== '')) {
      dateFilter = Object.assign({}, filter, { fromDate: moment().startOf('month'), toDate: moment() });
    } else {
      dateFilter = Object.assign({}, filter, { fromDate: dates[ 0 ], toDate: dates[ 1 ] });
    }
     setFilter({...filter, fromDate: dateFilter.fromDate, toDate: dateFilter.toDate});
    
  };


  const handleFilterChange = (element, value, isSearch = true) => {
    const updatedFilter = Object.assign({}, filter, { [ element ]: value });
    if (element === I18n.t('order.filters.schedule_orders.type') && value === I18n.t('order.filters.schedule_orders.unscheduled_key')) {
      updatedFilter.fromDate = null;
      updatedFilter.toDate = null;
    }
    setFilter(updatedFilter);
  };

  const handleSearch = () => {
    onChange(filter, () => {
      onSearch()
    })
  }

    return (<div className="padding10">
      <Row gutter={ 16 }>
        <Col md={ 24 }>{ renderAccountsList() }</Col>
        {/* <Col md={ 24 }>{ renderWHList() }</Col> */}
        <Col md={ 24 }>
          <FormItem label={ I18n.t('quotes.quote_date') }>
            <RangePicker
              allowClear={false}
              defaultValue={ [ moment().startOf('month'), moment() ] }
              value={ [
                filter.fromDate,
                filter.toDate,
              ] }
              ranges={AppConfig.dateRanges}
              format={ AppConfig.dateFormat }
              style={ { width: '100%' } }
              onChange={ (dates, dateStrings) =>
                handleRangeChange(dates, dateStrings)
              }
            />
          </FormItem>
        </Col>
        <Row type='flex' justify='center' gutter={ 4 }>
          <Col>
            <Button type="danger" onClick={clearForm } icon="redo">
              { I18n.t('general.reset') }
            </Button>
          </Col>
          <Col>
            <Button onClick={ () => {
              onChange(filter , () => { onSearch(); });
            }
            } type="primary" icon="search">
              { I18n.t('general.search') }
            </Button>
          </Col>
        </Row>
      </Row>
    </div>
    );
  }

QuotesFilter.propTypes = {
};
QuotesFilter.defaultProps = {
};
export default withRouter(QuotesFilter);
