import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Icon,
  Button,
  Text,
} from '../../common/UIComponents'
import { formatTotalDistance, formatTotalTime } from '../../helpers/routes'
import DisplayTime from '../common/DisplayTime'
import BranchesIcon from '../common/BranchesIcon'
import I18n from '../../common/I18n'

const RouteSummary = (props) => {
  const { currentRoute , selectRoute } = props
  const renderTime = () => {
    return currentRoute.route_start_time ? (
      <DisplayTime
        isUTC={false}
        displayWithDate={true}
        dateTimeString={currentRoute.route_start_time}
        key={'updated_on'}
        timeZoneId={currentRoute.timeZoneId}
      />
    ) : (
      'NA'
    )
  }

  const buildStop = (stop, stopIndex) => {
    const stopNumber = stopIndex + 1;
    const isWH = (stopIndex === 0 || stopIndex === currentRoute.nav_stops.length-1) && stop.location && stop.location.l_type === "WH";
    return (
    <div className='fontSize12' key={`s${stop.stop_id}`} style={{ backgroundColor: stopNumber % 2 ===1 ? '#fbf9f9' : '#FFF', padding: '10px 5px'}}>
      <Row>
        <Col xs={24} className="textBold">{ isWH ? <Icon type="home" /> : stopIndex + 1}.{"  "}{stop.contact_name}</Col>
      </Row>
      <Row>
        <Col xs={24} className="paddingLeft10">
          <Row>
            <Col>Status:  {stop.status}</Col>
            <Col>No of Orders: {stop.customer_order_ids.length}</Col>
          </Row>
        </Col>
        
      </Row>
    </div>)}
  
  return (
    <div>
      <Row>
        <Col xs={24} className="textBold">
          {currentRoute.displayName}{" "}({currentRoute.status})
        </Col>
      </Row>
      <Row className='fontSize12'>
        <Col>
          <Icon type="home" /> {I18n.t('location.location')} :{' '}
          {currentRoute.wh_name} ({currentRoute.wh_code})
        </Col>
        <Col>
          <Icon type="clock-circle" /> {I18n.t('routes.starts_at')} :{' '}
          {renderTime()}
        </Col>
        <Col>
          <BranchesIcon/>{I18n.t('general.distance')} :{' '}
          {formatTotalDistance(currentRoute.total_distance)}
        </Col>
        <Col>
          <Icon type="clock-circle" /> {I18n.t('general.travel_time')} :{' '}
          {formatTotalTime(currentRoute.total_time)}
        </Col>
      </Row>
    
      <Row type="flex" justify="space-between" className="marginTop15">
        <Col>
          <Text>Stops ({ currentRoute.nav_stops.length })</Text>
        </Col>
        <Col>
          <Button type="primary" size="small" icon='arrow-right' onClick={ () => { 
            selectRoute(currentRoute)
           }}>Show More Info</Button>
        </Col>
      </Row>
      {
        currentRoute.nav_stops.sort((a, b) => a.stop_order_sequence - b.stop_order_sequence).map((stop, index) => {
          return buildStop(stop, index)
        }
        )
      }
    </div>
  )
}

RouteSummary.propTypes = {
  currentRoute: PropTypes.shape.isRequired,
  selectRoute : PropTypes.func.isRequired
}

RouteSummary.defaultProps = {
  currentRoute: {},
  selectRoute: () => {}
}

export default RouteSummary
