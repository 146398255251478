import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Upload,
  Divider,
  DatePicker,
  TextArea,
  Radio,
  Collapse,
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  isEmpty,
  validateAddressForm,
  formatCode,
  checkIfDriverLoggedIn,
  randomUUID,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import { emailInsuranceDocs, fetchUserDetailsById, saveUser } from "../../api/UsersApi";
import { fetchDriverRatesList } from "../../api/DriverRatesApi";
import { fetchOrganizations } from "../../api/Organisations";
import FormErrors from "../common/FormErrors";
import userStore from "../../stores/UserStore";
import MobileInput from "../common/MobileInput";
import imagePlaceholder from "../../../assets/images/img_placeholder.png";
import AddressForm from "../common/AddressForm";
import { loadGoogleMaps } from "../../utils/Utils";
import { fetchAllDeliveryZones } from "../../api/DeliveryZones";
import WarehouseSelector from "../common/WarehouseSelector";
import { fetchLocations } from "../../api/LocationsApi";
import { fetchTrucks } from "../../api/TrucksApi";
import { fetchRolesList } from "../../api/RolesApi";
import AppConfig from "../../config/AppConfig";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { fetchShortFormAccounts } from "../../api/Account";
import AccountCodesFilter from "../../containers/Filters/AccountCodesFilter";
import AddressSearch from "../../stories/components/AddressSearch";
import { renderAlertMessage } from "../../helpers/common";
import moment from "moment";
import DeleteIcon from "../common/DeleteIcon";
import { Button, Card, Icon, Modal, Popconfirm, Spin, Table, Tabs } from "antd";
import Column from "antd/lib/table/Column";
import BaseModal from "../BaseModal";
import ImageViewer from "../common/ImageViewer";
import { validateEmailList } from "../billing_screen/helpers";
import DriverDocuments from "./DriverDocuments";
import { DriverTypesApi } from "../../api/DriverType";

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const commonRequiredFields = [
  {
    form_field: "first_name",
    ui_name: "First Name",
    isRequired: true,
    inputType: "name",
  },
  {
    form_field: "last_name",
    ui_name: "Last Name",
    isRequired: true,
    inputType: "name",
  },
];
const imgProps = { height: 125, width: 125 };
const RadioGroup = Radio.Group;

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
        ? this.props.user
        : { webRolesData: [], mobileRolesData: [] },
      zones: [],
      all_zones: [],
      trucks: [],
      driver_rates: [],
      isNew: this.props.isNew,
      inProgress: false,
      organizations: [],
      driverTypes: [],
      showNewRow: false,
      edit_profile: this.props.editProfile || false,
      map: false,
      is_hazmat_certified : true,
      isZonesLoading: false,
      isDriverRatesLoading: false,
      warehouses: [],
      isFetchingWarehouses: false,
      selectedWarehouses: [],
      rolesList: [],
      accounts: [],
      warehousesIds: [],
      mobileRoles: [],
      renderGallery: false,
      currentInsuranceRecord : {},
      selectedInfo : {
        selectedKeys: [],
        selectedRowKeys: [],
      },
      expandedRows: [],
      showEmailModal: false,
      subject: "Find the attached Insurance Documents",
      description: "",
      InsuranceEmails: [],
      EmailScreenSpinner : false
    };
  }

  componentDidMount() {
    const isSuperAdmin = userStore.superAdmin();
    const isAdmin = userStore.admin();
    if (!this.props.isNew) {
      this.setAccount();
    }
    if (this.props.userType === "driver") {
      this.loadMap();
      this.getAllZones();
      this.getTrucks();
      // this.getAllDriverRates();
      this.initialData();
      const mobileRoles = this.setDriverRoles(false);
      this.setState({ mobileRoles });
      // this.getRoles();
      if (this.props.isNew) {
        this.handleOnChange("warehouse_ids", ["ALL"]);
        this.getAllZones();
      }
    } else if (isSuperAdmin) {
      this.setAdminRoles();
    } else if (!this.state.edit_profile) {
      const mobileRoles = this.setDriverRoles(false);
      this.setState({ mobileRoles });
      this.getRoles();
      this.loadMap();
      this.getTrucks();
      this.getAllZones();
      if (this.state.user.mobileRolesData.length === 0 && this.state.isNew) {
        this.handleOnChange("warehouse_ids", ["ALL"]);
      }
    }
    this.getOrganizations();
    if (this.state.user.org_id) {
      this.getWarehouses(this.state.user.org_id);
    } else if (!isSuperAdmin) {
      this.getWarehouses();
    }
    if(this.state.user && this.state.user.insurance_details && this.state.user.insurance_details.length > 0){
      this.setState({expandedRows : this.state.user.insurance_details.map((rec) => rec._id)});
    }
    this.getTypeOfDriver()
  }

  setAccount = () => {
    const { user, isNew } = this.state;
    if (user && user.accounts && user.accounts.length) {
      const currentAccIds = user.accounts.map((rec) => rec.id);
      this.setState({ user: { ...user, account_ids: currentAccIds } });
    }
  };

  getAccounts = (org_id) => {
    fetchShortFormAccounts(org_id, this.state.warehousesIds, false).then(
      (result) => {
        if (result.success) {
          this.setState({
            accounts: result.accounts || [],
          });
        } else {
          renderAlertMessage(result.errors)
        }
      }
    );
  };

  getWarehouses = (org_id) => {
    const isSuperAdmin = userStore.superAdmin();
    this.setState({
      isFetchingWarehouses: true,
    });
    fetchLocations(AppConfig.warehouseCode, 1, null, true, org_id)
      .then((result) => {
        if (result.success) {
          const warehouses = result.locations.locations;
          const isEditing = !this.props.isNew;
          const { user } = this.state;
          let selectedWarehouses = [];
          if (isEditing) {
            selectedWarehouses = user.warehouse_ids
              ? warehouses
                  .filter(
                    (wh) =>
                      user.warehouse_ids && user.warehouse_ids.includes(wh.id)
                  )
                  .map((wh) => {
                    return {
                      key: wh.id,
                      label: wh.name,
                    };
                  })
              : [];
          }
          this.setState(
            {
              warehouses,
              selectedWarehouses,
              isFetchingWarehouses: false,
              warehousesIds: warehouses ? warehouses.map((rec) => rec.id) : [],
            },
            () => {
              if (this.props.userType !== "driver") {
                if (this.state.user.org_id) {
                  this.getAccounts(this.state.user.org_id);
                } else if (!isSuperAdmin) {
                  this.getAccounts();
                }
              }
            }
          );
        } else {
          this.setState({
            isFetchingWarehouses: false,
          });
        }
      })
      .finally(() => {});
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.all_zones.length !== this.state.all_zones.length) {
      this.setZones();
    }
  }

  setZones = () => {
    const all_zones = this.state.all_zones || [];
    const zones = this.state.user.warehouse_ids
      ? this.state.user.warehouse_ids.includes("ALL")
        ? [...all_zones]
        : all_zones.filter((zone) =>
            this.state.user.warehouse_ids.includes(zone.warehouse_id)
          )
      : [];
    this.setState({
      zones,
      all_zones,
    });
  };

  initialData = () => {
    const isDriver = this.props.userType === "driver";
    const { user, isNew } = this.state;
    if (isNew && isDriver && _.isEmpty(user.mobileRolesData)) {
      user.mobileRolesData = ["driver"];
    }
    this.setState({ user });
  };
  setAdminRoles = () => {
    this.setState({
      rolesList: [
        {
          role_code: "super_admin",
          role_name: "Super Admin",
        },
        {
          role_code: "admin",
          role_name: "Admin",
        },
      ],
    });
  };
  setDriverRoles = (reqState = true) => {
    const rolesList = [...AppConfig.mobileRoles];
    if (!reqState) {
      return rolesList;
    }
    this.setState({
      rolesList,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps.user, isNew: nextProps.isNew });
  }

  getRoles = () => {
    const organizationId = userStore.getStateValue("selectedOrg");
    fetchRolesList({ org_id: organizationId })
      .then((result) => {
        const { success, data, errors } = result;
        if (success) {
          const roles = data.filter(
            (role) => !["driver", "helper"].includes(role.role_code)
          );
          if (data.length) {
            this.setState({
              rolesList: roles,
            });
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };


  // deleteImage = (id) => {
  //   const insuranceObject = this.state.user.insurance_details.find((ins) => ins.document_proofs.find((proof) => proof._id === id));
  //   const imageId = id;
  //   const insuranceId = _.get(insuranceObject, '_id');
  //   const userId = this.state.user.id;

  //   const payload = {
  //     insurance_id: insuranceId,
  //     user_id: userId,
  //     selected_ids: [imageId]
  //   }

  //   deleteImages(payload).then((result) => {
  //     if (result.success) {
  //       alertMessage("success", result.message);
  //       // this.props.handleEditClick(userId);
  //       // this.handleSave();
  //       const { insurance } = result;
  //       const insuranceDetails = this.state.user.insurance_details.map((record) => {
  //         if(record._id === insuranceId){
  //           return {
  //             ...record,
  //             document_proofs: _.get(insurance, 'document_proofs', [])
  //           }
  //         }
  //         return record;
  //       }
  //       );
  //       const updatedUser = { ...this.state.user, insurance_details: insuranceDetails };
  //       this.setState({ user: updatedUser });
  //     } else {
  //       alertMessage("error", result.errors[0]);
  //     }
  //   });
  // }

  getBase64 = (img, callback) => {
    const data1 = Object.assign({}, this.state.user, { image: img });
    this.setState({
      user: data1,
    });
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  getTrucks = (initial, cb) => {
    fetchTrucks({}, 1, 1000).then((result) => {
      if (result.success) {
        this.setState(
          {
            trucks: result.trucks,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  getAllZones() {
    const { user } = this.state;
    const isDriver = this.props.userType === "driver";
    const mutualCheck =
      isDriver || (user.mobileRolesData && user.mobileRolesData.length > 0);
    this.setState({ isZonesLoading: true });
    fetchAllDeliveryZones(true).then((result) => {
      if (result.success) {
        this.setState(
          { all_zones: result.zones, isZonesLoading: false },
          () => {
            if (!this.state.isNew && mutualCheck) {
              this.removedOrphanIds();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ isZonesLoading: false });
      }
    });
  }

  getAllDriverRates() {
    this.setState({ isDriverRatesLoading: true });
    fetchDriverRatesList().then((result) => {
      if (result.success) {
        this.setState({
          driver_rates: result.driver_rates,
          isDriverRatesLoading: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ isDriverRatesLoading: false });
      }
    });
  }

  getOrganizations = (initial, cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  removedOrphanIds = () => {
    const { user } = this.state;
    const all_zones = this.state.all_zones.map((zone) => zone.id);
    if (user.zone_ids && user.zone_ids.length > 0) {
      const userZones = user.zone_ids.filter((zone) =>
        all_zones.includes(zone)
      );
      this.handleOnChange(
        "zone_ids",
        userZones.length === all_zones.length ? ["ALL"] : userZones
      );
    } else {
      this.handleOnChange("zone_ids", []);
    }
  };

  loadMap = () => {
    loadGoogleMaps(() => {
      this.setState({
        map: true,
      });
    });
  };

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (image) =>
      this.setState({
        image,
      })
    );
  };

  handleInsuranceOnChange = (element, index, value) => {
    const insuranceArr = [...this.state.user.insurance_details];
    insuranceArr[index][element] = value;
    const updatedUser = { ...this.state.user, insurance_details: insuranceArr };
    this.setState({ user: updatedUser });
  };


  handleInsuranceOnDelete = (indexToRemove) => {
    if (indexToRemove !== -1) {
      const insuranceArr = [...this.state.user.insurance_details];
      insuranceArr.splice(indexToRemove, 1);
      const updatedUser = { ...this.state.user, insurance_details: insuranceArr };
      this.setState({ user: updatedUser });
    }
  };

  handleInsuranceOnAddNewLine = () => {
    const newAddingObj = {
      insurance_number: '',
      insurance_expiry_date: '',
      insurance_type: '',
      insurance_company: '',
      proof_type:"INSURANCE",
      _id: "",
      document_proofs: [],
    };
  
    const insuranceDetails = this.state.user.insurance_details || [];
  
    const insuranceArr = [...insuranceDetails, { ...newAddingObj }];
  
    const updatedUser = { ...this.state.user, insurance_details: insuranceArr };
    
    const newExpandedRows = [...this.state.expandedRows, newAddingObj._id];
  
    this.setState({ user: updatedUser, expandedRows: newExpandedRows });
  };
  
  renderUploadDragger = () => {
    const insuranceRecord = this.state.user.insurance_details[this.state.currentInsuranceRecord];

    const fileList = insuranceRecord &&  insuranceRecord.document_proofs ? insuranceRecord.document_proofs.filter((imgs) => !imgs.picture).map((proof) => ({
      uid: proof.ack_id, // Assuming ack_id is unique
      name: proof.document_name,
      status: 'done', // Assuming you want to show these as already uploaded
    })) : [];
  
    const handleRemove = (file) => {
      this.setState((prevState) => {
        const newUser = { ...prevState.user };
        const newDocumentProofs = newUser.insurance_details[prevState.currentInsuranceRecord].document_proofs.filter(proof => proof.ack_id !== file.uid);
        newUser.insurance_details[prevState.currentInsuranceRecord].document_proofs = newDocumentProofs;
        return { user: newUser };
      });
    };

    return (
      <Upload.Dragger
        name="files"
        beforeUpload={(file) => this.handleFileUpload(file, this.state.currentInsuranceRecord)}
        multiple
        showUploadList={{
          showRemoveIcon: true, // Hide the default remove icon
        }}
        onRemove={handleRemove}
        fileList={fileList}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload.
        </p>
      </Upload.Dragger>
    );
  };

  handleEmailSubmit = () => {
    const payload = {
      user_id : this.state.user.id,
      org_id : this.state.user.org_id,
      insurance_ids: this.state.selectedInfo.selectedKeys,
      emails : this.state.InsuranceEmails,
      description : this.state.description,
    }
    emailInsuranceDocs(payload).then((result) => {
      if (result.success) {
        this.setState({ showEmailModal: false, isSendingMail: false, EmailScreenSpinner: false,selectedInfo: {selectedKeys: [], selectedRowKeys: []}  });
        alertMessage("success", result.message);
      } else {
        renderAlertMessage(result.errors)
      }
    });
  }

  handleEmailInputs = (value) => {
    const validEmails = validateEmailList(value);
    this.setState({ InsuranceEmails: validEmails });
  };

  handleManagerEmailInputs = (value) => {
    this.setState((prevState) => {
      const { user } = prevState;
      return {
        user: { ...user, manager_email: value },
      };
    });


    // if (this.isValidEmail(value)) {
    //   this.setState((prevState) => {
    //     const { user } = prevState;
    //     return {
    //       user: { ...user, manager_email: value },
    //     };
    //   });
    // } else {
    //   console.error('Invalid email format');
    // }
  };

  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  closeModal = () => {
    this.setState({ showEmailModal: false, InsuranceEmails: []});
  }

  openModal = () => {
    const hasDocumentObj = this.state.user.insurance_details.some(record => 
      record.document_proofs.some(doc => doc.document_obj)
    );
  
    if(hasDocumentObj){
      alertMessage("Upload Pending Attachments before sending the Email", "error", 3);
      this.setState({selectedInfo: {selectedKeys: [], selectedRowKeys: []} })
      return;
    } 
    this.setState({ showEmailModal: true});
  }

  renderEmailModal = () => {
    return (
      <BaseModal
        title="Send Insurance Docs to Emails"
        width="50%"
        style={{ top: 40 }}
        maskClosable={false}
        onCancel={this.closeModal}
        visible={this.state.showEmailModal}
      >
        <Spin spinning={this.state.EmailScreenSpinner}>
          <Row
            className="tagsInput emailForm"
            type="flex"
            align="middle"
            gutter={8}
          >
            <Col span={21}>
              <FormItem label={I18n.t("general.email")}>
                <Select
                  value={this.state.InsuranceEmails}
                  onChange={this.handleEmailInputs}
                  mode="tags"
                  validationRegex={this.handleEmailInputs}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Enter Emails"
                  tokenSeparators={[","]}
                >
                </Select>
              </FormItem>{" "}
            </Col>
            <Col span={ 3 } className="alignRight">
            <Popconfirm
              placement="topRight"
              title={ I18n.t('messages.confirm') }
              onConfirm={ this.handleEmailSubmit }
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={ this.state.isSendingMail } icon='mail'>
                Send
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <FormItem label="Subject">
              <Input
                rows={4}
                placeholder="Subject"
                className="sop-form-textArea"
                value={this.state.subject}
                onChange={(e) => this.setState({subject: e.target.value})}
              />
            </FormItem>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <FormItem label="Description">
              <TextArea
                rows={4}
                placeholder="Description"
                className="sop-form-textArea"
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
              />
            </FormItem>
          </Col>
          </Row>
        </Spin>
      </BaseModal>
    );
  };

  handleFileUpload =  (info, index) => {
  const newFileObj = {
    document_obj: info, 
    ack_id: `${randomUUID()}_n`,
    captured_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    document_type: info.type,
    document_name: info.name,
  };

  const insuranceArr = [...this.state.user.insurance_details];
  if (!insuranceArr[index].document_proofs) {
    insuranceArr[index].document_proofs = [];
  }
  insuranceArr[index].document_proofs.push(newFileObj);

  const updatedUser = { ...this.state.user, insurance_details: insuranceArr };
  this.setState({ user: updatedUser });
}

  handleOnChange = (element, value) => {
    const isDriver = this.props.userType === "driver";
    const warehouses = this.state.warehouses ? this.state.warehouses : [];
    if (element === "zone_ids") {
      if (
        value.includes("ALL") ||
        (value.length > 0 && value.length === this.state.zones.length)
      ) {
        value = ["ALL"];
      }
    }
    if (element === "warehouse_ids") {
      if (
        value.includes("ALL") ||
        (value.length > 0 && value.length === this.state.warehouses.length)
      ) {
        value = ["ALL"];
      }
    }
    // if (element === "roles" && !isDriver && value.length > 1) {
    //   value = [value[value.length - 1]];
    // }
    // single select

    // if( element==="roles" && value.includes("shipper") ){
    //   this.getAccounts()
    // }
    if (element === "mobileRolesData") {
      if (value.filter((val) => val !== undefined).length === 0) {
        value = [];
        this.setState(
          {
            user: Object.assign({}, this.state.user, {
              mobileRolesData: value,
            }),
          },
          () => this.handleValidate(true)
        );
      }
    }

    this.setState(
      {
        user: Object.assign({}, this.state.user, { [element]: value }),
      },
      () => {
        if (element === "warehouse_ids") {
          const selectedZones = this.state.user.zone_ids
            ? [...this.state.user.zone_ids]
            : [];
          const all_zones = this.state.all_zones || [];
          const zones = value.includes("ALL")
            ? [...all_zones]
            : all_zones.filter((zone) => value.includes(zone.warehouse_id));
          this.setState(
            {
              zones,
              all_zones,
              user: Object.assign({}, this.state.user, {
                zone_ids: zones
                  .filter((zone) => selectedZones.includes(zone.id))
                  .map((zone) => zone.id),
                primary_wh_id:
                  this.state.user[element].length > 0
                    ? this.state.user[element][0] !== "ALL"
                      ? this.state.user[element][0]
                      : warehouses.length > 0
                      ? warehouses[0].id
                      : ""
                    : "",
              }),
            },
            () => {
              this.getFilteredZones();
            }
          );
          this.handleOnChange("account_ids", []);
        } else if (element === "org_id" && this.state.user.org_id) {
          this.getWarehouses(this.state.user.org_id);
        }
        if (element === "webRolesData") {
          const { user } = this.state;
          if (value !== "shipper") {
            delete user.accounts;
            delete user.account_ids;
            this.setState({ user });
          }
        }
      }
    );
  };

  handleMobileChange = (element, value) => {
    this.setState({
      user: Object.assign({}, this.state.user, { [element]: value }),
    });
  };
  handleValidate = (updateState = false) => {
    const isSuperAdmin = userStore.superAdmin();
    const isAdmin = userStore.admin();
    this.setState({ inProgress: true });
    const user = Object.assign({}, this.state.user);
    const isDriver = this.props.userType === "driver";
    const isMobileNemailEmpty = !user.email && !user.mobile_number;
    const requiredFields = [...commonRequiredFields];
    const mutualCheck =
      isDriver || (user.mobileRolesData && user.mobileRolesData.length > 0);
    let addressErrors = "";
    // const isErrorCheckNeeded = this.state.errorCheckCondition;
    if (!isDriver) {
      if (!isEmpty(user.email)) {
        requiredFields.push({
          form_field: "email",
          ui_name: "Email",
          isRequired: false,
          inputType: "email",
        });
      }
      if (!isEmpty(user.mobile_number)) {
        requiredFields.push({
          form_field: "mobile_number",
          ui_name: "Mobile Number",
          isRequired: false,
          inputType: "mobile",
        });
      }
      if (mutualCheck === true) {
        requiredFields.push(
          {
            form_field: "employee_code",
            ui_name: I18n.t("users.emp_code"),
            isRequired: true,
            inputType: "code",
          },
          {
            form_field: "primary_wh_id",
            ui_name: I18n.t("general.primary_wh"),
            isRequired: true,
            inputType: "name",
          }
        );
      }
    }
    if (isDriver) {
      requiredFields.push(
        {
          form_field: "employee_code",
          ui_name: I18n.t("users.emp_code"),
          isRequired: true,
          inputType: "code",
        },
        {
          form_field: "mobile_number",
          ui_name: "Mobile Number",
          isRequired: true,
          inputType: "mobile",
        },
        {
          form_field: "mobileRolesData",
          ui_name: I18n.t("general.mobile_role"),
          isRequired: true,
          inputType: "array",
        },
        {
          form_field: "warehouse_ids",
          ui_name: I18n.t("location.location"),
          isRequired: true,
          inputType: "array",
        },
        {
          form_field: "primary_wh_id",
          ui_name: I18n.t("general.primary_wh"),
          isRequired: true,
          inputType: "name",
        }
      );
    }
    const errors = doValidate(requiredFields, user);
    user.roles = [].concat(user.webRolesData, user.mobileRolesData);

    if (user.roles.length === 0) {
      if(isAdmin && this.props.userType === "user"){
        errors.push(`Either ${I18n.t("general.role")} can't be blank`);
      }
      else{
        errors.push(`Role can't be blank`);
      }
    }
    if (user.webRolesData.includes("super_admin")) {
      user.org_id = [];
    } else if (isEmpty(user.org_id)) {
      user.org_id = userStore.getStateValue("selectedOrg");
    } else {
      user.org_id = user.org_id;
    }

    if (
      !this.props.editProfile &&
      user?.webRolesData &&
      user.webRolesData.includes("shipper") &&
      (isEmpty(user.account_ids) || user?.account_ids.length === 0)
    ) {
      errors.push(`${I18n.t("account.account")} can't be blank`);
    }

    if (!isDriver && isMobileNemailEmpty) {
      errors.push(`Either Email or Mobile Number is required`);
    }
    if (!_.isEmpty(this.state.user.manager_email)) {
      if (!this.isValidEmail(this.state.user.manager_email)) {
        errors.push(`Enter Valid Manager Email`);
      }
    }

    if (
      // (isSuperAdmin || isAdmin) &&
      // user.roles.includes("admin") &&
      (isSuperAdmin || isAdmin) &&
      user?.webRolesData?.includes("admin") &&
      isEmpty(user.org_id)
    ) {
      errors.push(`${I18n.t("organization.single")} can't be blank`);
    }
    if (mutualCheck & !this.props.editProfile) {
      addressErrors = validateAddressForm(user.l_address);
    }
    const totalErrors = [].concat(
      errors,
      !isEmpty(addressErrors) ? [`${addressErrors} in Address`] : []
    );
    if (updateState === false) {
      return totalErrors;
    }
    this.setState({ inProgress: false, errors: totalErrors });
  };
  handleSave = () => {
    const totalErrors = this.handleValidate();
    if (totalErrors.length === 0) {
      this.setState({ inProgress: true });
      const user = Object.assign({}, this.state.user);
      user.roles = [].concat(user.webRolesData, user.mobileRolesData);
      const isDriver = this.props.userType === "driver";
      const mutualCheck =
        isDriver || (user.mobileRolesData && user.mobileRolesData.length > 0);
      if (user["mobile_number"] === "+") {
        user["mobile_number"] = "";
      } else {
        const mobileNo = !isEmpty(user["mobile_number"])
          ? user["mobile_number"]
          : "";
        user["mobile_number"] = mobileNo.replace(/[^0-9]/g, "");
      }
      if (!user.webRolesData) {
        user.webRolesData = [];
      }
      if (!user.mobileRolesData) {
        user.mobileRolesData = [];
      }

      // this.props.userType === 'driver'  keep if driver does not belongs to organization
      if (user.webRolesData.includes("super_admin")) {
        user.org_id = [];
      } else if (isEmpty(user.org_id)) {
        user.org_id = userStore.getStateValue("selectedOrg");
      } else {
        user.org_id = user.org_id;
      }

      if (mutualCheck) {
        if (user.warehouse_ids && user.warehouse_ids.includes("ALL")) {
          user.warehouse_ids = this.state.warehouses.map((wh) => wh.id);
        }
        if (user.zone_ids && user.zone_ids.includes("ALL")) {
          user.zone_ids = this.state.zones.map((zone) => zone.id);
        }
        user.addr_required = true;
      } else {
        user.addr_required = false;
        if (this.state.selectedWarehouses.length === 0) {
          // All warehouses selected
          user.warehouse_ids = this.state.warehouses.map((wh) => wh.id);
        } else {
          user.warehouse_ids = this.state.selectedWarehouses.map(
            (wh) => wh.key
          );
        }
        user.zone_ids = [];
      }

      if (!this.props.editProfile) {
        if (user.webRolesData.includes("shipper")) {
          const selectedAccounts =
            user && user.account_ids && user.account_ids.length
              ? this.state.accounts.filter((acc) =>
                  user.account_ids.includes(acc.id)
                )
              : [];
          const accounts = selectedAccounts.map((record) => ({
            id: record.id,
            name: record.name,
            code: record.code,
          }));

          user.accounts = accounts;
        } else {
          if (user?.accounts) {
            delete user.accounts;
          }
        }
      }

      // if (isSuperAdmin && isEmpty(user.webRolesData)) {
      //   errors.push(`${I18n.t("general.role")} can't be blank`);
      // }

      // if(user?.roles && user.roles.includes("shipper")){
      //   delete user.account_ids;
      // }

      if (user?.roles) {
        delete user.account_ids;
      }
      user.employee_code = formatCode(user.employee_code);
      //user.role = [...user.roles];
      // if (!this.state.isNew) {
      //   user.roles = [user.role];
      // }
      let finalUserData = Object.assign({}, user);
      delete finalUserData.webRolesData;
      const isMobileRoleEmpty = _.isEmpty(finalUserData.mobileRolesData);
      delete finalUserData.mobileRolesData;
      if (isMobileRoleEmpty) {
        const refinedData = _.pick(finalUserData, [
          "first_name",
          "last_name",
          "email",
          "mobile_number",
          "warehouse_ids",
          "roles",
          "org_id",
          "id",
          "accounts",
          "image"
        ]);
        saveUser(this.state.isNew, refinedData)
          .then((result) => {
            if (result.success) {
              this.props.onSuccess(I18n.t("messages.saved"), result.users);
              // this.setState({
              //   image: result.success,
              // });
              this.setState({ inProgress: false, errors: [] });
            } else {
              this.setState({ errors: result.errors, inProgress: false });
            }
          })
          .finally(() => {
            // check if same user is logged in
            const currentUser = userStore.getState();
            if (
              !isEmpty(currentUser.id) &&
              user.id === currentUser.id &&
              !this.state.edit_profile
            ) {
              // reload user
              window.location.reload();
            }
          });
      } else {
        if(this.props.userType === "driver" && isEmpty(finalUserData.is_hazmat_certified) ) {
          finalUserData.is_hazmat_certified = true
        }

        finalUserData.insurance_details = Array.isArray(finalUserData.insurance_details) ? finalUserData.insurance_details.map((record) => {
          const document_proofs = record.document_proofs.map((proof) => {
            return {
              ...proof,
              document_obj: proof.document_obj || null
            };
          });
          return {
            ...record,
            document_proofs
          };
        }) : [];

        saveUser(this.state.isNew, finalUserData)
          .then((result) => {
            if (result.success) {
              this.props.onSuccess(I18n.t("messages.saved"), result.users);
              // this.setState({
              //   image: result.success,
              // });
              this.setState({ inProgress: false, errors: [] });
              if(!checkIfDriverLoggedIn(userStore.currentRole())){
                this.props.onCancel(true);
              }
            } else {
              this.setState({ errors: result.errors, inProgress: false });
            }
          })
          .finally(() => {
            // check if same user is logged in
            const currentUser = userStore.getState();
            if (
              !isEmpty(currentUser.id) &&
              user.id === currentUser.id &&
              !this.state.edit_profile
            ) {
              // reload user
              window.location.reload();
            }
            // this.props.onCancel();
          });
      }
    } else {
      this.setState({ errors: totalErrors, inProgress: false });
    }
  };

  getTypeOfDriver = () => {
    const organization_id = userStore.getStateValue("selectedOrg");
    // setInProgress(true);
    this.setState({ inProgress: true });
  
    const capitalizeFirstLetter = (string) => {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
  
    DriverTypesApi.fetchDriverTypes(organization_id).then((result) => {
      if (result.success) {
        const driverTypesWithCapitalizedNames = result.driver_types ? result.driver_types.map((type) => ({
          ...type,
          name: capitalizeFirstLetter(type.name),
        })) : [];
          const sortedDriverTypes = driverTypesWithCapitalizedNames.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
  
        this.setState({
          driverTypes: sortedDriverTypes || [],
          showNewRow: sortedDriverTypes.length === 0,
          inProgress: false,
        });
      } else {
        alertMessage(result.errors[0], "error", 5);
        this.setState({ inProgress: false });
      }
    });
  };

  handleDelete = (e, item) => {
    const key = _.find(
      this.state.existingFiles,
      (fileObj) => item.id === fileObj.id
    );
    const result = this.state.existingFiles.splice(key, 1);
    const { deletedFiles } = this.state;
    deletedFiles.push(result[0].id);
    this.setState({
      existingFiles: this.state.existingFiles,
      deletedFiles,
    });
  };

  handleOnAddressChange = (element, value, obj) => {
    const address = this.state.user.l_address || {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign(address, obj);
    } else if (element) {
      newAddress[element] = value;
    }
    this.setState({
      user: Object.assign({}, this.state.user, { l_address: newAddress }),
    });
  };

  getFilteredZones = () => {
    const { user, all_zones, zones } = this.state;
    const currentZoneIds =
      zones?.length > 0 ? zones.map((zone) => zone.id) : [];
    const { zone_ids } = user;
    if (
      zone_ids &&
      zone_ids.length > 0 &&
      zone_ids.some((value) => currentZoneIds.includes(value))
    ) {
      const filteredZoneIds = currentZoneIds.filter((zn) =>
        zone_ids.includes(zn)
      );
      this.handleOnChange("zone_ids", filteredZoneIds);
    } else {
      this.handleOnChange("zone_ids", []);
    }
  };

  handleWarehouseChange = (values) => {
    const filteredValues = values.length
      ? values[0].key === ""
        ? values.slice(1)
        : values[values.length - 1].key === ""
        ? []
        : values
      : [];
    this.handleOnChange(
      "warehouse_ids",
      filteredValues.length ? filteredValues.map((v) => v.key) : ["ALL"]
    );
    this.setState({
      selectedWarehouses: filteredValues,
    });
  };

  getWarehouse = () => {
    const { selectedWarehouses } = this.state;
    return selectedWarehouses.length
      ? selectedWarehouses
      : [{ key: "", label: "" }];
  };

  mountGallery = () => {
    return (
      <div className="modal-container">
      <BaseModal
        title={I18n.t("order.upload_images")}
        onCancel={() => this.setState({ renderGallery: false, currentInsuranceRecord:{} })}
        visible={this.state.renderGallery}
        footer={null}
        width="50%"
        maskClosable={false}
        // style={{ top: 210 }}
      >
       {this.renderUploadDragger()}
      </BaseModal>
      </div>
    );
  };

  DriverDetails = () => {
    const isDriver = this.props.userType === "driver";
    const isCustomer = userStore.customer();
    const isSuperAdmin = userStore.superAdmin();
    const {
      user,
      zones,
      driver_rates,
      trucks,
      rolesList,
      warehouses,
      accounts,
      mobileRoles,
    } = this.state;
    const agents = this.props.agents || [];
    const image = this.state.image || this.state.user.image;
    const mutualCheck =
      isDriver || (user.mobileRolesData && user.mobileRolesData.length > 0);
    let primary_wh_list = [];
    let filteredAccount = [];
    if (
      user &&
      user.warehouse_ids &&
      _.isArray(user.warehouse_ids) &&
      user.warehouse_ids.length > 0
    ) {
      if (user.warehouse_ids[0] === "ALL") {
        primary_wh_list = warehouses;
      } else {
        primary_wh_list = user.warehouse_ids
          ? warehouses.filter((wh) => user.warehouse_ids.includes(wh.id))
          : [];
      }
    } else if (user) {
      primary_wh_list = warehouses;
    }

    if (user && user.webRolesData && user.webRolesData.includes("shipper")) {
      if (
        user &&
        user.warehouse_ids &&
        _.isArray(user.warehouse_ids) &&
        user.warehouse_ids.length > 0
      ) {
        if (user.warehouse_ids[0] === "ALL") {
          filteredAccount = accounts;
        } else {
          filteredAccount = user.warehouse_ids
            ? accounts.filter(
                (acc) =>
                  user &&
                  user.warehouse_ids &&
                  user.warehouse_ids.some((record) =>
                    acc.warehouse_ids.includes(record)
                  )
              )
            : [];
        }
      } else {
        filteredAccount = accounts;
      }
    }
    return (
      <div>
        <Row gutter={8}>
          <Col xs={16}>
            <Row gutter={16}>
              <Col xs={12}>
                <FormItem label={I18n.t("general.first_name")} require>
                  <Input
                    value={user.first_name}
                    onChange={(e) =>
                      this.handleOnChange("first_name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label={I18n.t("general.last_name")} require>
                  <Input
                    value={user.last_name}
                    onChange={(e) =>
                      this.handleOnChange("last_name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              {!isDriver && (
                <Col xs={12}>
                  <FormItem label={I18n.t("general.email")}>
                    <Input
                      value={user.email}
                      onChange={(e) =>
                        this.handleOnChange("email", e.target.value)
                      }
                      // disabled={!this.state.isNew}
                    />
                  </FormItem>
                </Col>
              )}
              <Col xs={12}>
                <FormItem
                  label={I18n.t("general.mobile_number")}
                  require={this.props.userType === "driver"}
                >
                  <MobileInput
                    handleInputChange={(value, data) => {
                      this.handleMobileChange("mobile_number", value, data);
                    }}
                    value={user.mobile_number}
                  />
                </FormItem>
              </Col>
              {!this.state.edit_profile && (
                <Fragment>
                  {!isDriver && (
                    <Col xs={12}>
                      <FormItem label={I18n.t("general.web_role")}>
                        <Select
                          //value={user.roles}
                          value={rolesList
                            .filter((role) =>
                              user.webRolesData.includes(role.role_code)
                            )
                            .map((role) => role.role_code)}
                          showSearch
                          style={{ width: "100%" }}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) =>
                            this.handleOnChange("webRolesData", [e])
                          }
                          loading={this.state.isLoading}
                          disabled={this.state.isLoading}
                          allowClear={true}
                          // mode="multiple"
                        >
                          {rolesList.map((role) => (
                            <Option key={role.role_code} value={role.role_code}>
                              {role.role_name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                  )}
                  {!isSuperAdmin && (
                    <Col xs={12}>
                      <FormItem label={I18n.t("general.mobile_role")}>
                        <Select
                          value={mobileRoles
                            .filter((role) =>
                              user.mobileRolesData.includes(role.role_code)
                            )
                            .map((role) => role.role_code)}
                          showSearch
                          style={{ width: "100%" }}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) =>
                            this.handleOnChange("mobileRolesData", [e])
                          }
                          loading={this.state.isLoading}
                          disabled={this.state.isLoading}
                          allowClear={true}
                          // mode="multiple"
                        >
                          {mobileRoles.map((role) => (
                            <Option key={role.role_code} value={role.role_code}>
                              {role.role_name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                  )}
                  {mutualCheck && (
                    <Col xs={12}>
                      <FormItem label={I18n.t("users.emp_code")} require>
                        <Input
                          value={user.employee_code}
                          onChange={(e) =>
                            this.handleOnChange("employee_code", e.target.value)
                          }
                          className="textUpperCase"
                        />
                      </FormItem>
                    </Col>
                  )}
                  {isSuperAdmin &&
                    user.webRolesData &&
                    user.webRolesData.includes("admin") && (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <FormItem label={I18n.t("organization.single")} require>
                          <Select
                            value={user.org_id}
                            showSearch
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.handleOnChange("org_id", e)}
                          >
                            {this.state.organizations.map((organization) => (
                              <Select.Option
                                key={organization.id}
                                value={organization.id}
                              >
                                {organization.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                    )}

                  {( !this.props.editProfile && (isSuperAdmin &&
                    user.webRolesData &&
                    user.webRolesData.includes("admin")) ||
                    !isSuperAdmin) && (
                    <Col xs={12}>
                      <FormItem label={I18n.t("general.warehouse")}>
                        <WarehouseSelector
                          style={{ width: "100%" }}
                          handleWarehouseChange={this.handleWarehouseChange}
                          isFetchingWarehouses={this.state.isFetchingWarehouses}
                          isWarehouseMultiSelect={true}
                          warehouses={warehouses}
                          getWarehouse={this.getWarehouse}
                        />
                      </FormItem>
                    </Col>
                  )}
                  { !this.props.editProfile && !isSuperAdmin &&
                    !isDriver &&
                    user.webRolesData &&
                    user.webRolesData.includes("shipper") && (
                      <Col xs={12}>
                        <FormItem label={I18n.t("account.account")} require>
                          <AccountCodesFilter
                            SelectedAccountCodes={user.account_ids}
                            onValueChange={this.handleOnChange}
                            accounts={filteredAccount}
                            size="default"
                            element="account_ids"
                            valueField="id"
                          />
                        </FormItem>
                      </Col>
                    )}
                </Fragment>
              )}
            </Row>
          </Col>
          <Col xs={8} className="alignCenter">
            <FormItem label={I18n.t("general.image")}>
              <Upload showUploadList={false} onChange={this.handleChange}>
                {this.state.user.image && !this.state.image && (
                  <div className="borderRadius">
                    <img
                      src={image.url || imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {this.state.image && (
                  <div className="borderRadius">
                    <img
                      src={image || imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {!this.state.image && !this.state.user.image && (
                  <div className="borderRadius">
                    <img
                      src={imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
              </Upload>
            </FormItem>
          </Col>
        </Row>

        { !this.props.editProfile && mutualCheck && (
          <>
          <Row gutter={8}>
              <Col xs={8}>
              <FormItem label={I18n.t("users.truck")}>
                <Select
                  value={user.driver_fleet_id}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleOnChange("driver_fleet_id", e)}
                >
                  {trucks.map((truck) => (
                    <Option key={truck.id} value={truck.id}>
                      {`${truck.name} (${truck.vehicle_type})`}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
              <Col xs={8}>
              <FormItem label={I18n.t("general.primary_wh")} require>
                <Select
                  value={user.primary_wh_id}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleOnChange("primary_wh_id", e)}
                  loading={this.state.isZonesLoading}
                  disabled={this.state.isZonesLoading}
                >
                  {primary_wh_list.map((wh) => (
                    <Option key={wh.id} value={wh.id}>
                      {wh.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
              <Col xs={8}>
              <FormItem label={I18n.t("zones.zone")}>
                <Select
                  value={user.zone_ids}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleOnChange("zone_ids", e)}
                  mode="multiple"
                  maxTagCount={3}
                  loading={this.state.isZonesLoading}
                  disabled={this.state.isZonesLoading}
                >
                  {zones.length > 0 && (
                    <Select.Option key="allSC" value="ALL">
                      {I18n.t("general.all")}
                    </Select.Option>
                  )}
                  {zones.map((zone) => (
                    <Option key={zone.id} value={zone.id}>
                      {zone.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={12}>
              <FormItem label={I18n.t("users.agent")}>
                <Select
                  value={user.agent_id}
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => this.handleOnChange("agent_id", e)}
                  allowClear
                >
                  {agents.map((agent) => (
                    <Option key={agent.id} value={agent.id}>
                       {agent.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
              <Col xs={12}>
                <FormItem label={I18n.t("users.manager_email")}>
                  <Input
                    value={user.manager_email}
                    onChange={(e) => this.handleManagerEmailInputs(e.target.value)}
                  />
                </FormItem>
              </Col>
          </Row>
          </>
        )}
        
        
        <Row gutter={8}>
          <Col xs={12}>
            {/* {isDriver && (
              <Row>
                <Col xs={24}>
                  <FormItem label={I18n.t("general.role")} require>
                    <Select
                      value={user.roles}
                      style={{ width: "100%" }}
                      onChange={(e) => this.handleOnChange("roles", e)}
                      mode="multiple"
                    >
                      <Option key="driver" value="driver">
                        {I18n.t("general.driver")}
                      </Option>
                      <Option key="helper" value="helper">
                        {I18n.t("general.helper")}
                      </Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            )}
            )} */}

            {false && isDriver && (
              <Row>
                <Col xs={24}>
                  <FormItem label="Driver Rate">
                    <Select
                      value={userDriverRateId}
                      showSearch
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => this.handleOnChange("driver_rate_id", e)}
                      loading={this.state.isDriverRatesLoading}
                      disabled={this.state.isDriverRatesLoading}
                    >
                      {driver_rates.length > 0 && (
                        <Select.Option key="allSC" value="">
                          Select driver rate type
                        </Select.Option>
                      )}
                      {driver_rates.map((driver_rate) => (
                        <Option key={driver_rate.id} value={driver_rate.id}>
                          {driver_rate.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            )}
          </Col>

          {isCustomer && !this.state.isNew && (
            <Col xs={12}>
              <FormItem label={I18n.t("general.communication_preference")}>
                <Select
                  style={{ width: "100%" }}
                  value={user.communication_preference}
                  onChange={(e) =>
                    this.handleOnChange("communication_preference", e)
                  }
                  defaultValue={user.communication_preference || "mobile"}
                >
                  <Option key="mobile" value="mobile">
                    Mobile
                  </Option>
                  <Option key="email" value="email">
                    Email
                  </Option>
                </Select>
              </FormItem>
            </Col>
          )}
        </Row>
        { !this.props.editProfile && mutualCheck && (
          <Fragment>
            <Divider orientation="left">
              <sup className="textRed">*</sup>
              {I18n.t("general.address")}
            </Divider>
            {this.state.map &&
            mutualCheck &&
            (this.state.isNew || Object.keys(this.state.user).length) ? (
              <>
                <AddressSearch
                  onSelect={(address) =>
                    this.handleOnAddressChange("", "", address.l_address)
                  }
                />
                <AddressForm
                  autoComplete
                  address={user.l_address}
                  onChange={this.handleOnAddressChange}
                  isAdmin
                  twoRows
                />
              </>
            ) : (
              <Fragment />
            )}
          </Fragment>
        )}
        { !this.props.editProfile && mutualCheck && (<Row gutter={16}>
          <Col xs={24}>
            <div className="padding10 marginBottom10 customCollapse">
            <Collapse
              defaultActiveKey={["1"]}
              style={{ height: "auto" }}
              expandIconPosition="right"
              className="marginBottom20"
            >
            <Panel
              size="small"
              header={
                <Row>
                  <Col xs={24} className="textBold">
                  {I18n.t('users.driver.driver_details')}
                  </Col>
                </Row>
              }
              key="1"
            >
            <Row gutter={12}>
            <Col md={6}>
                <FormItem label={I18n.t('users.driver.driver_sta')} >
                  <Input
                    value={user.sta}
                    onChange={(e) =>
                      this.handleOnChange("sta", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              {/* <Col md={8}>
                <FormItem label={I18n.t('users.driver.sta_date')} >
                <DatePicker
                    style={{ width: "100%", marginLeft: "1%" }}
                    value={user.driver_sta_start_date ? moment(user.driver_sta_start_date) : null}
                    placeholder="Select Date"
                    format="Do MMM YYYY"
                    onChange={(date, dateString) => this.handleOnChange("driver_sta_start_date", date ? moment(date).format("YYYY-MM-DD") : null)}
                  />
                </FormItem>
              </Col> */}
              <Col md={6}>
                <FormItem label={I18n.t('users.driver.sta_expiry_date')} >
                  <DatePicker
                    style={{ width: "100%", marginLeft: "1%" }}
                    value={user.driver_sta_expiry_date ? moment(user.driver_sta_expiry_date) : null}
                    placeholder="Select Date"
                    format="Do MMM YYYY"
                    onChange={(date, dateString) => this.handleOnChange("driver_sta_expiry_date", date ? moment(date).format("YYYY-MM-DD") : null)}
                  />
                </FormItem>
              </Col>
              <Col md={6}>
                <FormItem label={I18n.t('users.driver.contract')} >
                  <Input
                    value={user.contract}
                    onChange={(e) =>
                      this.handleOnChange("contract", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={6}>
                    <FormItem label={I18n.t('users.driver.type_of_driver')} >
                    <Select
                      value={user.type_of_driver}
                      showSearch
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) =>
                        this.handleOnChange("type_of_driver", e)
                      }
                      allowClear={true}
                    >
                      {this.state.driverTypes.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                    </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
            <Col md={6}>
                <FormItem label={I18n.t('users.driver.licence_number')} >
                  <Input
                    value={user.license_number}
                    onChange={(e) =>
                      this.handleOnChange("license_number", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              
              <Col md={6}>
                  <FormItem label={I18n.t('users.driver.licence_expiry_date')}>
                      <DatePicker
                          style={{ width: "100%", marginLeft: "1%" }}
                          value={user.license_expiry_date ? moment(user.license_expiry_date) : null}
                          placeholder="Select Date"
                          format="Do MMM YYYY"
                          onChange={(date, dateString) => this.handleOnChange("license_expiry_date", date ? moment(date).format("YYYY-MM-DD") : null)}
                          defaultValue={null}
                      />
                  </FormItem>
              </Col>
                    <Col md={6}>
                <FormItem label={I18n.t('users.driver.driver_medical_number')} >
                  <Input
                    value={user.medical_number}
                    onChange={(e) =>
                      this.handleOnChange("medical_number", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={6}>
                  <FormItem label={I18n.t('users.driver.medical_expiry_date')}>
                      <DatePicker
                          style={{ width: "100%", marginLeft: "1%" }}
                          value={user.medical_expiry_date ? moment(user.medical_expiry_date) : null}
                          placeholder="Select Date"
                          format="Do MMM YYYY"
                          onChange={(date, dateString) => this.handleOnChange("medical_expiry_date", date ? moment(date).format("YYYY-MM-DD") : null)}
                          defaultValue={null}
                      />
                  </FormItem>
              </Col>
                  </Row>


                  <Row gutter={12}>

              <Col md={6}>
                <FormItem label={I18n.t('users.driver.social_security_number')} >
                  <Input
                    value={user.social_security_number}
                    onChange={(e) =>
                      this.handleOnChange("social_security_number", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={6}>
                  <FormItem label={I18n.t('users.driver.date_of_birth')}>
                      <DatePicker
                          style={{ width: "100%", marginLeft: "1%" }}
                          value={user.date_of_birth ? moment(user.date_of_birth) : null}
                          placeholder="Select Date"
                          format="Do MMM YYYY"
                          onChange={(date, dateString) => this.handleOnChange("date_of_birth", date ? moment(date).format("YYYY-MM-DD") : null)}
                          defaultValue={null}
                      />
                  </FormItem>
                    </Col>
              <Col md={6}>
                    <FormItem label={I18n.t('users.driver.driver_start_date')} >
                        <DatePicker
                            style={{ width: "100%", marginLeft: "1%" }}
                            value={user.driver_start_date ? moment(user.driver_start_date) : null}
                            placeholder="Select Date"
                            format="Do MMM YYYY"
                            onChange={(date, dateString) => {
                              console.log("Do MMM YYYY", dateString);
                              this.handleOnChange("driver_start_date", date ? moment(date).format("YYYY-MM-DD") : null)
                            }}
                        />
                    </FormItem>
                </Col>
                <Col md={6}>
                  <FormItem label={I18n.t('users.driver.driver_termination_date')}>
                      <DatePicker
                          style={{ width: "100%", marginLeft: "1%" }}
                          value={user.driver_termination_date ? moment(user.driver_termination_date) : null}
                          placeholder="Select Date"
                          format="Do MMM YYYY"
                          onChange={(date, dateString) => this.handleOnChange("driver_termination_date", date ? moment(date).format("YYYY-MM-DD") : null)}
                          defaultValue={null}
                      />
                  </FormItem>
              </Col>

                  </Row>
                  <Row gutter={12}>

              <Col md={6}>
                <FormItem
                  label= {I18n.t('users.driver.hazmat_certified')}
                >
                  <RadioGroup
                      onChange={(e) =>
                        this.handleOnChange(
                          "is_hazmat_certified",
                          e.target.value
                        )
                      }
                    
                    value={
                      !isEmpty(user.is_hazmat_certified)
                        ? user.is_hazmat_certified
                        : true
                    }
                    style={{ marginTop: 10 }}
                    size={"small"}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>NO</Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={12}>
              
              <Col md={24}>
                <FormItem label= {I18n.t('users.driver.notes')} >
                <TextArea
                    rows={1}
                    placeholder={"Description"}
                    className="sop-form-textArea"
                    autoSize={true}
                    value={user.notes}
                    onChange={(e) =>
                      this.handleOnChange("notes", e.target.value)
                    }              
                    />
                </FormItem>
              </Col>   
            </Row>
                  {/* <Row >
                    <Col>
                      <Card
                        bordered={false}
                        size="small"
                        title={<span style={{ color: 'black' }}>{I18n.t("users.driver.driver_insurance")}</span>}
                        extra={
                          _.isEmpty(this.state.user.insurance_details) && (
                            <Button type="primary" onClick={() => this.handleInsuranceOnAddNewLine()}>
                              <Icon type="plus" />
                              {I18n.t("users.driver.add_driver_insurance")}
                            </Button>
                          )
                        }
                      >
                        {!_.isEmpty(this.state.user.insurance_details) && (
                          <Spin spinning={ this.state.inProgress }>
                          <Table
                            dataSource={this.state.user.insurance_details}
                            className="accessorial-charges-list"
                            rowKey={(record, index) => record._id}
                            pagination={false}
                            bordered
                            expandedRowKeys={this.state.expandedRows}
                            onExpand={(expanded, record) => {
                              if (expanded) {
                                this.setState({ expandedRows: [...this.state.expandedRows, record._id] });
                              } else {
                                this.setState({ expandedRows: this.state.expandedRows.filter(id => id !== record._id) });
                              }
                            }}
                            rowSelection={
                              this.state.user.insurance_details.some(detail => detail._id) ? {
                                selectedRowKeys: this.state.selectedInfo.selectedKeys,
                                onChange: (selectedRowKeys, selectedRows) => {
                                  this.setState({
                                    selectedInfo: {
                                      selectedKeys: selectedRowKeys,
                                      selectedRows: selectedRows,
                                    },
                                  });
                                },
                                getCheckboxProps: record => ({
                                  id: record._id,
                              }),
                              } : undefined
                            }
                            expandedRowRender={(record, index) => (
                              <Row type="flex" gutter={ 4 } align="middle">
                                <Col>
                                  <Button type="primary" onClick={ () => this.setState({ renderGallery: true, currentInsuranceRecord: index }) }>
                                    <Icon type="upload" />
                                    {
                                      record.document_proofs.filter((data) => !data.picture).length > 0 ?
                                        `(${record.document_proofs.filter((data) => !data.picture).length} pending uploads)` : "Upload"
                                    }
                                  </Button>
                                </Col>
                                <Col span={6}>
                                  <ImageViewer
                                    deleteImage={(id) => this.deleteImage(id)}
                                    showPrintButton={false}
                                    orderPictures={record.document_proofs}
                                    showDeleteButton={true}
                                    images={
                                      record && !_.isEmpty(record._id)
                                        ? record.document_proofs
                                          .filter((data) => data.picture)
                                          .map((data) => data.picture.url)
                                        : []
                                    }
                                  />
                                </Col>
                              </Row>
                            ) }
                            footer={ () => {
                              return this.state.selectedInfo.selectedKeys.length > 0 && (
                                <div>
                                  <Button
                                    type="primary"
                                    onClick={() => {this.openModal()}}
                                  >
                                    Email Insurance Docs
                                  </Button>
                                </div>
                              )
                            }
                          }
                          >
                            <Column
                              title="#"
                              dataIndex="index"
                              key="index"
                              render={(text, record, index) => index + 1}
                            />
                            <Column
                              title={I18n.t("users.driver.insurance_number")}
                              dataIndex="insurance_number"
                              key="insurance_number"
                              render={(text, record, index) => (
                                <>
                                  <Input
                                    disabled={record._id}
                                    value={text}
                                    type="number"
                                    onChange={(e) =>
                                      this.handleInsuranceOnChange(
                                        `insurance_number`,
                                        index,
                                        e.target.value
                                      )
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    />
                                </>
                              )}
                            />
                            <Column
                              title={I18n.t("users.driver.insurance_expiry_date")}
                              dataIndex="insurance_expiry_date"
                              key="insurance_expiry_date"
                              render={(text, record, index) => (
                                <DatePicker
                                  style={{ width: "100%" }}
                                  value={text ? moment(text) : null}
                                  placeholder="Select Date"
                                  format="Do MMM YYYY"
                                  onChange={(date, dateString) => {
                                    this.handleInsuranceOnChange(
                                      `insurance_expiry_date`,
                                      index,
                                      date ? moment(date).format("YYYY-MM-DD") : null
                                    );
                                  }}
                                />
                              )}
                            />
                            <Column
                              title={I18n.t("users.driver.insurance_type")}
                              dataIndex="insurance_type"
                              key="insurance_type"
                              render={(text, record, index) => (
                                <Input
                                  value={text}
                                  onChange={(e) =>
                                    this.handleInsuranceOnChange(
                                      `insurance_type`,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            />
                            <Column
                              title={I18n.t("users.driver.insurance_company")}
                              dataIndex="insurance_company"
                              key="insurance_company"
                              render={(text, record, index) => (
                                <Input
                                  value={text}
                                  onChange={(e) =>
                                    this.handleInsuranceOnChange(
                                      `insurance_company`,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            />
                            <Column
                              title=""
                              key="action"
                              render={(text, record, index) => (
                                <Row type="flex" gutter={10} justify="start" style={{ fontSize: 16 }}>
                                  <Col>
                                    <DeleteIcon
                                      disabled={index === 0}
                                      handleClick={() => this.handleInsuranceOnDelete(index)}
                                    />
                                  </Col>
                                  <Col>
                                    {index === this.state.user.insurance_details.length - 1 && (
                                      <Icon
                                        type="plus-circle"
                                        theme="filled"
                                        onClick={() => this.handleInsuranceOnAddNewLine()}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              )}
                            />
                          </Table>
                          </Spin>
                        )}
                      </Card>
                    </Col>
                  </Row> */}

            </Panel>
            </Collapse>
            <Collapse 
              defaultActiveKey={["1"]}
              style={{ height: "auto" }}
              expandIconPosition="right"
              className="marginBottom20"
            >
            <Panel
              size="small"
              header={
                <Row>
                  <Col xs={24} className="textBold">
                  {I18n.t('users.driver.diver_emergency_contact')}
                  </Col>
                </Row>
              }
              key="1"
            >
              <Fragment>
                {/* <Divider orientation="left">
                  <b>{"Emergency Contact"}</b>
                </Divider> */}
                <Row gutter={12}>
                <Col md={8}>
                    <FormItem label={I18n.t('users.driver.emergency_name')}>
                      <Input
                        value={user.emergency_name}
                        onChange={(e) =>
                          this.handleOnChange("emergency_name", e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col md={8}>
                    <FormItem label={I18n.t('users.driver.emergency_contact')} >
                    <MobileInput
                        handleInputChange={(value, data) => {
                          this.handleMobileChange("emergency_contact", value, data);
                        }}
                        value={user.emergency_contact}
                    />
                    </FormItem>
                  </Col>
                  <Col md={8}>
                    <FormItem label= {I18n.t('users.driver.relation')} >
                      <Select
                      value={user.relation}
                      showSearch
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) =>
                        this.handleOnChange("relation", e)
                      }
                      allowClear={true}
                    >
                      {AppConfig.relation.map((data) => (
                        <Option key={data.key} value={data.key}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Fragment>
           
            </Panel>
            </Collapse>
            </div>
          </Col>
        </Row>)}
        {!isDriver && (
          <Row className="textBold">
            <sup style={{ color: "red" }}>*</sup>Note:{" "}
            <span className="notes_content">
              Either Email or Mobile Number can't be blank
            </span>
            <br />
            {!isSuperAdmin && !this.props.editProfile && (
              <span className="notes_content" style={{ marginLeft: 46 }}>
                Either Web Level Roles or Mobile Level Roles can't be blank
              </span>
            )}
          </Row>
        )}
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row type="flex" justify="center" align="center">
          <Col>
            <Button
            type="primary"
            onClick={() => {
              this.handleSave();
            }}
            disabled={this.state.inProgress}
            loading={this.state.inProgress}
            >Save & Close</Button>
          </Col>
        </Row>
        {this.state.renderGallery && this.mountGallery()}
        {this.state.showEmailModal && this.renderEmailModal()}
      </div>
    )
  }

  render() {
    // const userDriverRate = driver_rates.find(
    //   (driverRate) => driverRate.id === user.driver_rate_id
    // );
    // let userDriverRateId = null;
    // if (userDriverRate) {
    //   userDriverRateId = user.driver_rate_id;
    // }
    const userRoles =_.get(this.state.user, "roles" , []);
    return (
      <Tabs type="card">
        <TabPane tab='Details' key="1">
          {this.DriverDetails()}
        </TabPane>
        {userRoles.includes('driver') && <TabPane tab='Documents' key="2">
          <DriverDocuments user={this.state.user} onClose={() => this.props.onCancel()} />
        </TabPane>}
      </Tabs>
    );
  }
}

export const UserFormComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <UserForm
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default UserFormComponent;

UserFormComponent.propTypes = {
  user: PropTypes.shape(),
  isNew: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userType: PropTypes.string,
  editProfile: PropTypes.bool,
};

UserFormComponent.defaultProps = {
  user: {},
  isNew: true,
  userType: "customer",
  editProfile: false,
};
