import React, { Fragment } from 'react'
import moment from 'moment'
import BaseList from '../BaseList'
import { Row, Col } from 'antd'
import {
  checkNegitive,
  alertMessage,
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Icon,
  Popconfirm,
  Popover,
  Input,
  Typography,
  Tooltip
} from '../../common/UIComponents'
import ShowInvoice from './ShowInvoice'
import ShowSummary from './ShowSummary'
import { checkServiceExistance } from '../../helpers/common'
import { fetchUpdatedInvoiceNo } from '../../api/BillingScreenAPI'
import userStore from '../../stores/UserStore'
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import UnlockInvoice from './UnlockInvoice'
import { findIfInvoicePartiallyPaid } from '../../helpers/billing'

class InvoiceDetailsList extends BaseList {
  constructor(props) {
    super(props)
    this.state = {
      account_invoices: this.props.data || [],
      currentProccessedIndex: -1,
      currentProcessedId: null,
      editedInvoiceNo: '',
      inProgress: false,
    }

    this.columns = [
      {
        key: 'invoice_number',
        title: I18n.t('account.billing.invoice_no'),
        dataIndex: 'invoice_number',
        render: (data, record, index) => {
          const isEditable = this.state.currentProcessedId === record.id
          return (
            <div className='copycontent'>
              {isEditable ? (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'column',
                  }}
                >
                  <Col>
                    <Input
                      value={this.state.editedInvoiceNo}
                      size="small"
                      onChange={(e) =>
                        this.onEditInvoiceNo(
                          index,
                          'editedInvoiceNo',
                          e.target.value,
                        )
                      }
                      onKeyDown={(e) => this.handleKeyDown(index, record.id, e)}
                      style={{ width: '95%' }}
                      autoFocus
                    />
                  </Col>
                  <Col>
                    {' '}
                    <Fragment>
                      <Icon
                        type="save"
                        onClick={() =>
                          this.handleInvoiceNoSave(index, record.id)
                        }
                        style={{ color: '#1890ff' }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Popconfirm
                        title={I18n.t('messages.cancel_confirm')}
                        onConfirm={() =>
                          this.handleInvoiceNoCancel(index, record.id)
                        }
                        placement="topLeft"
                      >
                        <Icon
                          type="close"
                          style={{
                            color: 'red',
                          }}
                        />
                      </Popconfirm>
                    </Fragment>
                  </Col>
                </Row>
              ) : (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'column',
                  }}
                >
                  <Col>
                    <Typography.Paragraph copyable>{data}</Typography.Paragraph>
                  </Col>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Col>
                    {record.is_locked != 'true'  && (isEmpty(this.state.currentProcessedId) ||
                      record.id === this.state.currentProcessedId) && (
                      <EditIcon handleClick={() =>
                        this.handleInvoiceNoEdit(index, record.id)}/>
                    )}
                  </Col>{' '}
                </Row>
              )}
            </div>
          )
        },
      },
      {
        key: 'wh_location_name',
        title: I18n.t('location.location'),
        dataIndex: 'wh_location_name',
        render: (data) => <div>{data ? data : ''}</div>,
      },
      {
        key: 'approval_date',
        title: I18n.t('invoices.approval_date'),
        dataIndex: 'billing_date',
      },
      {
        key: 'orders_count',
        title: I18n.t('account.billing.orders_count'),
        dataIndex: 'customer_order_numbers',
        render: (data) => (
          <div>
            {data && _.isArray(data) ? (
              <Popover
                content={data.join(', ')}
                title={I18n.t('menu.orders')}
                overlayClassName="popoverWidth"
              >
                <u className="text-decoration-dashed">{data.length}</u>
              </Popover>
            ) : (
              0
            )}
          </div>
        ),
      },
      {
        key: 'reference_date',
        title: I18n.t('invoices.order_completion_date'),
        dataIndex: 'reference_date',
        // render: (data) => (
        //   <div>
        //     { data ? moment(new Date(data)).format("Do MMM YYYY") : "" }
        //   </div>
        // ),
      },
      {
        key: 'status',
        title: I18n.t('general.status'),
        dataIndex: 'status',
      },
      {
        key: 'amount',
        title: I18n.t('invoices.amount'),
        dataIndex: 'invoice_amount',
        render: (data) => (
          <div style={{ width: 100 }} className="alignRight">
            {data ? checkNegitive(data) : ''}
          </div>
        ),
      },
      {
        key: 'expand',
        title: '',
        render: (record) => {
          const isPaid = findIfInvoicePartiallyPaid(record)
          const seletectedInfo = {
            selectedRows : [record],
          }
          return(
          <div>
            {
              !isPaid && record.is_locked ==='true'  ?
              <Tooltip title={I18n.t('invoices.locked')}>
                {/* <img
                  src={lockButton}
                  className="expandIcon cursorPointer"
                  alt={I18n.t('invoices.locked')}
                  style={{ width: 17, marginTop: -7, marginRight: 7 }}
                /> */}
                <UnlockInvoice
                  buttonType="primary"
                  isDisabled={!(record.is_locked ==='true')}
                  label={I18n.t('invoices.unlock')}
                  invoiceIds={record.id}
                  handleSuccess={this.props.refreshList}
                  displayType="icon"
                />
              </Tooltip>
              :
              (
                record.ar_status === 'OPEN' &&  record.status === 'APPROVED'? 
                  <Tooltip title={I18n.t('invoices.opened')}>
                  <Icon type="safety-certificate"  className="textGrey textBold" style={{ marginRight: 10 , fontSize: 16 }}/>
                 </Tooltip>
                :
                record.ar_status === 'CLOSE' ? <Tooltip title={I18n.t('invoices.closed')}>
                  <Icon type="safety-certificate"  className="textGreen textBold" style={{ marginRight: 10 , fontSize: 16 }}/>
                 </Tooltip>
                :
                <Fragment />
              )
            }
            <ShowInvoice
              account={this.props.account}
              invoice_no={record.invoice_number}
              key={record.invoice_number}
              style={{ marginTop: -6 }}
              invoice_type={this.props.invoice_type}
              status={record.status}
              refreshList={this.props.refreshList}
              account_invoice_id={record.id}
              renderType="icon"
              approvalDate={record.approval_date}
              invoiceApprovalDate={record.invoice_approval_date}
              selectedInfo={seletectedInfo}
            />
            &nbsp; &nbsp;
            {checkServiceExistance('SOIN') && (
              <span>
                <ShowSummary
                  account={this.props.account}
                  invoice={{ ...record }}
                />
              </span>
            )}
            {checkServiceExistance('ID') && record.is_locked != 'true'  && (
              <Popconfirm
                placement="topRight"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() =>
                  this.props.deleteClick(record.id, this.props.account)
                }
                okText="Yes"
                cancelText="No"
              >
                &nbsp; &nbsp;
                <DeleteIcon className="fontSize17"/>
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
            )}
          </div>)
        },
      },
    ]
  }

  handleInvoiceNoEdit = (index, id) => {
    const { account_invoices } = this.state
    this.setState({
      currentProccessedIndex: index,
      currentProcessedId: id,
      editedInvoiceNo: account_invoices[index].invoice_number,
    })
  }

  handleInvoiceNoSave = (index, id) => {
    const { editedInvoiceNo } = this.state

    if (!isEmpty(editedInvoiceNo)) {
      let accountInvoices = [...this.state.account_invoices];
      const selectedRecord = accountInvoices[index];
      const invoiceNo = selectedRecord.invoice_number;
    
      const data = {
        organization_id: userStore.getStateValue('selectedOrg'),
        account_id: this.props.account,
        invoice_number: invoiceNo,
        new_invoice_number: this.state.editedInvoiceNo,
      }
      if (data.invoice_number !== data.new_invoice_number) {
        this.setState({ inProgress: true })
        fetchUpdatedInvoiceNo(data).then((result) => {
          if (result.success) {
            alertMessage(result.message, "success", 10);
            selectedRecord.invoice_number = editedInvoiceNo; // edited invoice no will update in UI if it is not duplicate
            this.setState({
              currentProccessedIndex: -1,
              currentProcessedId: null,
              editedInvoiceNo: '',
              inProgress: false,
            })
          } else {
            alertMessage(result.errors, 'error', 10)
            this.setState({
              inProgress: false,
              currentProccessedIndex: -1,
              currentProcessedId: null,
              editedInvoiceNo: '',
            })
          }
        })
      } else {
        this.setState({
          inProgress: false,
          currentProccessedIndex: -1,
          currentProcessedId: null,
          editedInvoiceNo: '',
        })
      }
    } else {
      alertMessage('Please Enter Invoice No.', 'error', 10)
    }
  }

  handleInvoiceNoCancel = (index, id) => {
    this.setState({
      currentProccessedIndex: -1,
      currentProcessedId: null,
      editedInvoiceNo: '',
    })
  }

  onEditInvoiceNo = (index, element, value) => {
    this.setState({
      [element]: value,
    })
  }

  handleKeyDown = (index, id, e) => {
    if (e.key === 'Enter') {
      this.handleInvoiceNoSave(index, id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        account_invoices: this.props.data,
        currentProcessedId: null,
        editedInvoiceNo: '',
        inProgress: false,
      })
    }
  }
}

export default InvoiceDetailsList
