/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  Col, Input, FormItem, Row, DatePicker,
  Select, Radio, Button,
} from '../../common/UIComponents';
import {  isEmpty, disabledDate} from '../../common/Common';
import I18n from '../../common/I18n';
import { fetchOrgAccounts, fetchShortFormAccounts } from '../../api/Account';
import { fetchLocations } from '../../api/LocationsApi';
import AppConfig from '../../config/AppConfig';
import { fetchAccountShortLos } from '../../api/Los';
import { getValueFromArrayOfObjects } from '../../helpers/array_functions';
import WarehouseFilter from '../common/WarehouseFilter';
import { checkServiceExistance } from '../../helpers/common';
import AccountCodesFilter from '../../containers/Filters/AccountCodesFilter';
import { DriverSelect, WarehouseSelect } from '../common/SelectDropdowns/BaseSelect';
import { fetchUsers } from '../../api/UsersApi';
import WarehouseCodeFilter from '../common/WarehouseCodeFilter';
import { VehicleTypeApi } from '../../api/VehicleType';


const { Search } = Input;
const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const dateFormat = 'Do MMM YYYY';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || {},
      accounts: [],
      selectedOption: 'driver_id',
      warehouses: this.props.warehouses || [],
      accountsLoading: true,
      levelOfServices: [],
      driver_id : null,
      drivers: [],
      driversLoading: true,
    };
  }

  componentDidMount() {
    this.getAccounts();
    // this.getWarehouses();
    this.getDrivers();
    const WarehouseFilter = this.props;
    if (!WarehouseFilter.isWarehouseMultiSelect) {
      this.setState({
        warehouses: WarehouseFilter.warehouses,
      })
    }
  }

  componentDidUpdate(prevProps , prevState){
    const WarehouseFilter = this.props;
    if(prevState.warehouses !== WarehouseFilter.warehouses){
      this.setState({
        warehouses: WarehouseFilter.warehouses,
      })
    }
    if (
        prevProps.warehouseFilter.selectedWarehouses !==
        this.props.warehouseFilter.selectedWarehouses
    ) {
        this.getAccounts();
        this.getDrivers();
      } 
  }
  disabledDate = current =>
    // Can not select days before today and today
    current && current > moment().endOf('day')


  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const { getSelectesWHs = () => {} } = this.props.warehouseFilter;
    const currentWhId = this.props.filter?.warehouse_id?.length ? this.props.filter.warehouse_id : getSelectesWHs()
    const currentOrg = this.props.currentOrg;
    const orgId = currentOrg.id;
    // fetchShortFormAccounts()
    fetchOrgAccounts(orgId).then((result) => {
        if (result.success) {
          this.setState({
            accounts: [...result.accounts],
            filteredAccounts: [...result.accounts],
            accountsLoading: false,
          }, () => {
            this.handleFilterPlaceHolderChange("warehouse_id", currentWhId);
          });
        } else {
          this.setState({
            accountsLoading: false,
          });
        }
      });
  }
  // getWarehouses = () => {
  //   fetchLocations(AppConfig.warehouseCode, 1, null)
  //     .then((result) => {
  //       if (result.success) {
  //         this.setState({
  //           warehouses: result.locations.locations,
  //         });
  //       }
  //     });
  // }

  handleOptionChange = e => {
    const selectedOption = e.target.value;
    this.setState(prevState => ({
      selectedOption,
      filter: {
        ...prevState.filter,
        vehicle_types: selectedOption === 'driver_id' ? [] : (prevState.filter.vehicle_types || []),
        driver_id: selectedOption === 'vehicle_types' ? null : prevState.filter.driver_id
      }
    }), () => {
      this.props.onSelectedOptionChange(this.state.selectedOption, this.state.filter);
    });
  };

  getDrivers = () => {
    this.setState({
      driversLoading: true,
    });
		fetchUsers("driver", 1, null).then((result) => {
			if (result.success) {
				this.setState(
					{
						drivers: result.users || [],
						driversLoading: false,
					},
				);
			}
      else{
        this.setState({
          driversLoading: false,
        });
      }
		});
  }

  handleDateRanges = () => {
    const { filter } = this.state;
    const startDate = filter.fromDate;
    const endDate = filter.toDate;
    if(startDate && endDate){
      let NewDate = null;
      if(!endDate.isSame(moment(), 'day')){
        NewDate = endDate.add(1, 'days');
      }
    this.setState({filter: {...filter,approvalDate: NewDate}});
    }
  };


  // getWarehouses = () => {
  //   fetchLocations(AppConfig.warehouseCode, 1, null)
  //     .then((result) => {
  //       if (result.success) {
  //         this.setState({
  //           warehouses: result.locations.locations,
  //         });
  //       }
  //     });
  // }


  setFilteredAccounts = (filteredAccount = []) => {
    const { accountCodes } = this.state.filter;
    const filteredAccCodes =
      filteredAccount?.length > 0 ? filteredAccount.map((acc) => acc.id) : [];

    if (accountCodes?.length) {
      const filteredAccountCodes = filteredAccCodes.filter((acc_code) =>
        accountCodes.includes(acc_code)
      );
      this.handleFilterPlaceHolderChange("accountCodes", filteredAccountCodes);
    } else {
      this.handleFilterPlaceHolderChange("accountCodes", []);
    }
  };

  handleFilterPlaceHolderChange = (element, value) => {
    this.setState(prevState => {
      const newFilter = {
        ...prevState.filter,
        [element]: value
      };
    if(element === "warehouse_id" && value?.length === 0){
        const { getSelectesWHs = () => {} } = this.props.warehouseFilter;
        newFilter.warehouse_id = getSelectesWHs()
    }

    if (element === 'search_key_type' && value === '') {
      newFilter.search_key_value = '';
    }
    if (element === 'accountCode') {
          newFilter.los = '';
      this.getLos(value);
    }
    if (element === I18n.t('order.filters.schedule_orders.type') && value === I18n.t('order.filters.schedule_orders.unscheduled_key')) {
          newFilter.fromDate = null;
          newFilter.toDate = null;
      }
        if (element === 'driver_id' && value) {
          newFilter.vehicle_types = null;
      }
        if (element === 'vehicle_types' && value) {
          newFilter.driver_id = null;
        }

        return { filter: newFilter };
    }, () => {
      if (element === "warehouse_id") {
        const { accounts } = this.state;
        const { warehouses } = this.props.warehouseFilter;
        const { warehouse_id } = this.state.filter;
  
        if (warehouse_id.length === 0) {
          const filteredAccounts = [...accounts];
          this.setState({ filteredAccounts });
        } else {
          const filteredAccounts = accounts.filter((account) =>
            account.warehouse_ids.some((id) => warehouse_id.includes(id))
          );
          this.setState({ filteredAccounts }, () => {
            this.setFilteredAccounts(filteredAccounts);
          });
        }
      }
    })
  }

  handleSearch = (filter) => {
    this.props.onChange(filter, () => {
      this.props.onSearch();
    });
  }
  getLos = (accountId) => {
    const accountCode = getValueFromArrayOfObjects(this.state.accounts, 'id', accountId, 'code');
    fetchAccountShortLos(accountCode)
      .then((result) => {
        if (result.success) {
          const levelOfServices = result.account.account_los || [];
          this.setState({
            levelOfServices,
          });
        }
      });
  }

  orderNumberSearch = (value) => {
    const filter = Object.assign({}, this.state.filter, { search_order_token: value });
    this.props.onChange(filter, () => {
      this.props.onOrderNoSearch();
    });
  }

  clearForm = () => {
    const { getSelectesWHs } = this.props.warehouseFilter;
    const filter = {
      fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
      toDate: moment(),
      filter: I18n.t('order.default_status'),
      search_order_token: '',
      sortBy: 'datetime',
      sortOrder: 'descend',
      accountCode: '',
      warehouse_id: getSelectesWHs(),
      search_key_type: '',
      search_key_value: '',
      accountCodes: [],
      [I18n.t('order.filters.schedule_orders.type')]: I18n.t('order.filters.schedule_orders.both_key'),
      invoiceAmountFilter : "BOTH",
      viewType: 'byOrder',
      vehicle_types: []
    };
    this.props.onChange(filter, () => {
      this.props.onSearch();
    });
  }

  handleRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== '' && dateStrings[1] !== '')) {
      dateFilter = Object.assign({}, this.state.filter, { fromDate:  moment().subtract(AppConfig.invoices_filter_duration, "day"), toDate: moment() });
      } else {
        dateFilter = Object.assign({}, this.state.filter, { fromDate: dates[0], toDate: dates[1] });
        }
        // this.props.onChange(dateFilter);
        this.setState({
          filter: dateFilter
          }), () =>
        this.handleDateRanges();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filter: nextProps.filter || {},
    });
  }
  _renderWhFilter = () => {
    const { warehouses } = this.props.warehouseFilter;
    return (
      <FormItem label={I18n.t("location.location")}>
        <WarehouseCodeFilter
          SelectedCodes={this.state.filter.warehouse_id}
          element={"warehouse_id"}
          onValueChange={this.handleFilterPlaceHolderChange}
          warehouses={warehouses}
          size="default"
        />
      </FormItem>
    );
  };

  render() {
    return (<Fragment>
      <div>
        {this._renderWhFilter()}
        <FormItem label={I18n.t('general.account')}>
          {/* <Select
            value={this.state.filter.accountCode || ''}
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={e => this.handleFilterPlaceHolderChange('accountCode', e)}
            loading={this.state.accountsLoading}
          >
            <Select.Option key='all' value=''>
              -- All --
            </Select.Option>
            {this.state.accounts.map(account => (
              <Select.Option key={account.id} value={account.id}>
                {`${account.name} (${account.code})`}
              </Select.Option>
            ))}
          </Select> */}
          {/* <TreeSelect
            value={ this.state.filter.accountCodes }
            style={{ width: '100%' }}
            showSearch
            size="small"
            className="minWidth"
            dropdownStyle={ {
              maxHeight: 300,
              overflow: "auto",
            } }
            placeholder="--Select--"
            allowClear
            treeDefaultExpandAll
            dropdownMatchSelectWidth={ false }
            onChange={ (e) =>
              this.handleFilterPlaceHolderChange("accountCodes", e)
            }
            treeCheckable
            maxTagCount={ 1 }
          >
            <TreeNode value="ALL" title="All" key="0-1">
              { this.state.accounts.map((account) => (
                <TreeNode
                  value={ account.code }
                  title={ `${account.name} (${account.code})` }
                  key={ account.code }
                />
              )) }
            </TreeNode>
          </TreeSelect> */}
          <AccountCodesFilter
            SelectedAccountCodes={ this.state.filter.accountCodes }
            onValueChange={ this.handleFilterPlaceHolderChange }
            accounts={ this.state.filteredAccounts }
            size="small"
          />
        </FormItem>
        <FormItem label={ I18n.t('general.view_by') }>
          <Select
            value={ this.state.filter.viewType }
            showSearch
            style={ { width: '100%' } }
            filterOption={ (input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={ (e) => this.handleFilterPlaceHolderChange('viewType', e) }
          >
            <Select.Option value="byAccount">
              { `${I18n.t('general.group_by')} ${I18n.t('account.account')}` }
            </Select.Option>
            <Select.Option value="byOrder">
              { I18n.t('order.label') }
            </Select.Option>
          </Select>
        </FormItem>
        <Radio.Group onChange={this.handleOptionChange} value={this.state.selectedOption} className='fontWeight700 marginTop8'>
            <Radio value="driver_id">{I18n.t('general.driver')}</Radio>
            <Radio value="vehicle_types">{I18n.t('vehicleTypes.title')}</Radio>
          </Radio.Group>
          <Row >
        {this.state.selectedOption === 'driver_id' && (
          <DriverSelect
            showSearch
            data={this.state.drivers}
            value={this.state.filter.driver_id}
            onChange={e => this.handleFilterPlaceHolderChange('driver_id', e)}
            placeholder={I18n.t('location.location')}
            style={{ width: '100%' }}
            selectKey="employee_code"
            selectValue="id"
            selectedValue="employee_code"
          />
        )}
        {this.state.selectedOption === 'vehicle_types' && (
          <Select
            style={{ width: "100%" }}
            showSearch
            mode='multiple'
            maxTagCount={1}
            allowClear={true}
            onChange={(e) => this.handleFilterPlaceHolderChange("vehicle_types", e)}
            value={this.state.filter.vehicle_types || []}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.getVehicleTypes.map((vehicle) => (
              <Select.Option key={vehicle.code} value={(vehicle.code).replace(/\s/g, " ")}>
                {vehicle.name}
              </Select.Option>
            ))}
          </Select>
      )}
      </Row>
        {/* <FormItem label={I18n.t('location.location')} >
        <WarehouseFilter isDisabled={true}
            style={{ width: '100%' }}
         /> 
        </FormItem> */}
        {/*checkServiceExistance('LOSI') && <FormItem label={I18n.t('los.label')} >
          <Select
            value={this.state.filter.los || ''}
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={e => this.handleFilterPlaceHolderChange('los', e)}
          >
            <Select.Option key="LOS" value=''>
              -- Select {I18n.t('los.label')} --
            </Select.Option>
            {this.state.levelOfServices.map(los => (
              <Select.Option key={los.code} value={los.code}>
                {los.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        */}
        <FormItem label={I18n.t('invoices.completion_date')} className="marginTop8">
          <RangePicker
            // disabledDate={disabledDate}
            defaultValue={[moment().startOf('month'), moment()]}
            value={[this.state.filter.fromDate, this.state.filter.toDate]}
            ranges={AppConfig.dateRanges}
            format={dateFormat}
            style={{ width: '100%' }}
            onChange={(dates, dateStrings) => this.handleRangeChange(dates, dateStrings)}
            disabled={this.state.filter[I18n.t('order.filters.schedule_orders.type')] === I18n.t('order.filters.schedule_orders.unscheduled_key')}
            // disabledDate={this.disabledDate}
          />
        </FormItem>
        {/* <Row gutter={16}>
          <Col xs={8}>
            <FormItem label={I18n.t('search.by')}>
              <Select
                value={this.state.filter.search_key_type || ''}
                onChange={e => this.handleFilterPlaceHolderChange('search_key_type', e)}
                className="width100Per"
              >
                <Select.Option value="">-- Select --</Select.Option>
                <Select.Option value="name_search_key">{I18n.t('order.filters.name_search_key')}</Select.Option>
                <Select.Option value="phone_search_key">{I18n.t('order.filters.phone_search_key')}</Select.Option>
                <Select.Option value="ref_search_key">{I18n.t('order.filters.ref_search_key')}</Select.Option>
              </Select>

            </FormItem>

          </Col>
          <Col xs={16}>
            <FormItem label={I18n.t('search.value')}>
              <Search
                placeholder={I18n.t('search.value')}
                size="default"
                value={this.state.filter.search_key_value}
                onChange={e => this.handleFilterPlaceHolderChange('search_key_value', e.target.value)}
                onSearch={value => this.props.onSearch(value)}
                disabled={isEmpty(this.state.filter.search_key_type)}
              />
            </FormItem>

          </Col>
        </Row> */}
        {/* <FormItem label={I18n.t('general.sort_by')} >
          <RadioGroup
            onChange={e => this.handleFilterPlaceHolderChange('sortBy', e.target.value)}
            value={this.state.filter.sortBy}
          >
            <Radio value='none'>{I18n.t('order.filters.sortBy.none')}</Radio>

            <Radio value='account_code'>{I18n.t('order.filters.sortBy.account_code')}</Radio>
          </RadioGroup>
        </FormItem> */}
        {/* <FormItem label={I18n.t('order.filters.schedule_orders.label')} >
          <RadioGroup
            onChange={e => this.handleFilterPlaceHolderChange(I18n.t('order.filters.schedule_orders.type'), e.target.value)}
            value={this.state.filter[I18n.t('order.filters.schedule_orders.type')]}
          >
            <Radio value={I18n.t('order.filters.schedule_orders.scheduled_key')}>{I18n.t('order.filters.schedule_orders.SCHEDULED_label')}</Radio>
            <Radio value={I18n.t('order.filters.schedule_orders.unscheduled_key')}>{I18n.t('order.filters.schedule_orders.UNSCHEDULED_label')}</Radio>
            <Radio value={I18n.t('order.filters.schedule_orders.both_key')}>{I18n.t('general.both')}</Radio>

          </RadioGroup>
        </FormItem>
          */}
        <Col md={ 24 } style={ { marginBottom: 10 } }>
          <FormItem label={I18n.t('account.billing.filter.filter_orders_with')} >
          <Radio.Group onChange={ (e) => {
            this.handleFilterPlaceHolderChange('invoiceAmountFilter', e.target.value);
          } } value={ this.state.filter.invoiceAmountFilter }>
            <Radio value={"WITH_AMOUNT"}>{I18n.t('account.billing.filter.with_amount_label')}</Radio>
            <Radio value={"WITHOUT_AMOUNT"}>{I18n.t('account.billing.filter.without_amount_label')}</Radio>
            <Radio value={"BOTH"}>{I18n.t('general.both')}</Radio>
          </Radio.Group>
          </FormItem>
        </Col>
        <div
          style={{

            padding: '10px 16px',

            textAlign: 'center',
          }}
        >
          <Button type="danger" onClick={this.clearForm} icon='redo' >
            Reset
          </Button> &nbsp;&nbsp;
          <Button onClick={() =>this.handleSearch(this.state.filter)} type="primary" icon='search'>
            Search
          </Button>
        </div>

      </div>
    </Fragment>);
  }
}

Filter.propTypes = {
  filter: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onOrderNoSearch: PropTypes.func,
  warehouses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getVehicleTypes: PropTypes.func.isRequired,
};
Filter.defaultProps = {
  filter: {},
  onOrderNoSearch: null,
};
export default withRouter(Filter);
