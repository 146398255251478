import React from "react";
import { Icon } from "./UIComponents";

// SVG content
const AssignSvg = ({ color = "#33393D", width = "24", height = "24" }) => (
  <svg
    viewBox="0 0 24 24"
    focusable="false"
    data-icon="assign"
    width={width}
    height={height}
    fill={color}
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2C10 0.895 10.895 0 12 0C13.105 0 14 0.895 14 2H17.75C18.9838 2 20 3.0162 20 4.25V12.25C20 12.6642 19.6642 13 19.25 13C18.8358 13 18.5 12.6642 18.5 12.25V4.25C18.5 3.8278 18.1722 3.5 17.75 3.5H16V4.25C16 4.939 15.439 5.5 14.75 5.5H9.25C8.561 5.5 8 4.939 8 4.25V3.5H6.25C5.8278 3.5 5.5 3.8278 5.5 4.25V19.75C5.5 20.1722 5.8278 20.5 6.25 20.5H12.25C12.6642 20.5 13 20.8358 13 21.25C13 21.6642 12.6642 22 12.25 22H6.25C5.0162 22 4 20.9838 4 19.75V4.25C4 3.0162 5.0162 2 6.25 2H10ZM19 14C16.239 14 14 16.239 14 19C14 21.761 16.239 24 19 24C21.761 24 24 21.761 24 19C24 16.239 21.761 14 19 14ZM19 16C19.345 16 19.625 16.28 19.625 16.625V18.375H21.375C21.72 18.375 22 18.655 22 19C22 19.345 21.72 19.625 21.375 19.625H19.625V21.375C19.625 21.72 19.345 22 19 22C18.655 22 18.375 21.72 18.375 21.375V19.625H16.625C16.28 19.625 16 19.345 16 19C16 18.655 16.28 18.375 16.625 18.375H18.375V16.625C18.375 16.28 18.655 16 19 16ZM10.149 13.21C10.706 13.092 11.348 13 12 13C12.652 13 13.294 13.092 13.851 13.209C14.4858 13.344 14.9873 13.7722 15.2366 14.3268C14.6619 14.7902 14.1744 15.3574 13.8027 16H8.68057L8.60495 15.369C8.48395 14.357 9.15195 13.421 10.149 13.21ZM12 7C13.105 7 14 7.895 14 9V10C14 11.105 13.105 12 12 12C10.895 12 9.99995 11.105 9.99995 10V9C9.99995 7.895 10.895 7 12 7Z"
      fill={color}
    />
  </svg>
);

// Icon component
const AssignIcon = (props) => {
  return (
    <>
      <Icon component={() => <AssignSvg {...props} />} {...props} />
    </>
  );
};

export default AssignIcon;