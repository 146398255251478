import React, { Fragment } from 'react'
import moment from 'moment'
import AppConfig from '../../config/AppConfig'
import BaseList from '../BaseList'
import PropTypes from 'prop-types'
import { Popconfirm, Col, Icon, Popover, Row, Tooltip, Select } from 'antd'
import I18n from '../../common/I18n'
import AddressInfo from '../common/AddressInfo'
import { checkServiceExistance, confirmationPopup } from '../../helpers/common'
import _, { isEmpty } from 'lodash'
import CustomerOrderDetails from '../orders/CustomerOrderDetails'
import DisplayTime from '../common/DisplayTime'
import { formatWeight } from '../../helpers/orders'
import { getRecoveryStatusIcon } from '../../helpers/recovery'
import ShowDispatcherNotes from '../common/ShowDriverNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import { withRouter } from 'react-router';
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import BranchesIcon from '../common/BranchesIcon'
import OrderFieldDisplay from '../orders/OrderFieldDisplay'
import TypeOfOrder from '../orders/TypeOfOrder'
import { Link } from '../../common/UIComponents'
import { compareString, ellipseText } from '../../common/Common'

class RecoveryList extends BaseList {
  constructor(props) {
    super(props)
    this.columns = []
    this.setColumns(props)
  }

  setColumns = (props) => {
    let columns = [
      {
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
        render: (data, record) => {
          const isTransfer = ["T"].includes(record.type_of_order);
          const isDelivery = props.currentStop && props.currentStop.location && props.currentStop.location.type_of_loc && props.currentStop.location.type_of_loc === "DELIVERY" || (props.stopType === "WH" && props.stopIndex === 0);
          const textToDisplay = isTransfer ? ( isDelivery ? data + "(D)" : data + "(P)") : data;
          return (
            <div>
              {!isEmpty(data) && (
                <CustomerOrderDetails
                  order_no={props.screenFrom ? textToDisplay : data}
                  key={data}
                  order={{
                    id: record.customer_order_id,
                    customer_order_number: data,
                  }}
                  showEdit={false}
                  //tab="recoveries"
                />
              )}
            </div>
          )
        },
        width: 100,
        // sorter: this.props.isSorting ? ( (a,b) => 
        // compareString(a.customer_order_number, b.customer_order_number) ):false,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
        width: 100
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex:"hawb",
        render: (text, record) =>(
          <OrderFieldDisplay
            data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
            charsToLimit={null}
            // tab="recoveries"
          />
        ),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: 135
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        render: (text, record) => (
          <OrderFieldDisplay
            data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
            charsToLimit={null}
            // tab="recoveries"
          />
        ),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: 135
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1"  charsToLimit={null}/>
        ),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: 130
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2"  charsToLimit={null}/>
        ),
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: 130
      },
      {
        key: 'warehouse',
        title: I18n.t('general.warehouse'),
        dataIndex: 'warehouse_code',
        render: (data) => data,
        width: 100
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
          <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
           const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                 <Tooltip title={record.account_name} placement="topLeft"> {!isEmpty(data) ? data : record.account_code}</Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        //  width: 115,
        width: 170,
        align: "left",
        // minWidth: 100
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
           const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        //  width: 115,
        align: "left",
        minWidth: 100
      },
      
      // {
      //   key: 'recovery_date',
      //   title: I18n.t('recovery.recovery_date'),
      //   dataIndex: 'recovery_date',
      //   sorter: true,
      //   sortDirections: ['ascend', 'descend', 'ascend'],
      //   render: (data) => {
      //     return (
      //       <span>
      //         {moment(data).isValid()
      //           ? moment.utc(data).format(AppConfig.date_format)
      //           : ''}
      //       </span>
      //     )
      //   },
      // },freight_forwarder
      {
        key: 'freight_forwarder',
        title: I18n.t('location.freight_forwarder'),
        dataIndex: 'pickup_location',
        render: (loc, data) => (
          <div style={{ display: 'flex' ,  width: 100}}>
            { loc && <div>{loc.name ? loc.name : (`${loc.first_name} ${loc.last_name}`)}</div>}
          </div>
        ),
        width: 150,
        className: "noWrap"
      },
      {
        key: 'city',
        title: 'Origin -> Destination',
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        dataIndex: 'warehouse_address',
        // ellipses: true,
        render: (text, data) => (
          <div style={{ display: 'flex' }}>
           {
              data && <Fragment>
                <div className="noWrap">{this.setAddress(data, 'origin')}</div>
                <span>&nbsp;{' - '}&nbsp;</span>
                <div className="noWrap">{this.setAddress(data, 'destination')}</div>
              </Fragment>
           }
          </div>
        ),
        // width: '15%',
      },
      {
        key: 'recovery_eta',
        title: I18n.t('recoveries.eta'),
        dataIndex: 'recovery_eta',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (data, record) => {
          // const selectedWH = this.props?.selectedWH ? this.props.selectedWH : {};
          const selectedWH = record?.warehouse ? record.warehouse : {};
          // return data? momentTime(data).format("YYYY-MM-DD hh:mm") : '';
          return (
            <DisplayTime
              key={record.id}
              dateTimeString={data}
              displayWithDate={true}
              isUTC={true}
              timeZoneId={record.pickup_timeZoneId ? record.pickup_timeZoneId : selectedWH.timeZoneId}
              // timeZoneId={'America/Chicago'}
            />
          )
        },
      },
      // {
      //   key: 'recovery_dead_line',
      //   title: I18n.t('recoveries.deadline'),
      //   dataIndex: 'recovery_dead_line',
      //   // sorter: true,
      //   // sortDirections: ['ascend', 'descend', 'ascend'],
      //   render: (data) => {
      //     return (
      //       <span>
      //         {moment(data).isValid()
      //           ? moment.utc(data).format(`${AppConfig.date_format}`)
      //           : ''}
      //       </span>
      //     )
      //   },
      //   width: 100
      // },
    ]

    if (this.props.currentStatus === "exception") {
      columns.push({
        key: "EXCEPTION",
        title: I18n.t("general.exception_reason"),
        dataIndex: "exception_message",
        render: (text) => (
          <div>
            {!isEmpty(text) && (
              <Popover
                title={I18n.t("general.reason")}
                content={<div>{text}</div>}
                placement="topLeft"
                overlayStyle={{ width: 350 }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <span className="textRed">{ellipseText(text, 45)}</span>
              </Popover>
            )}
          </div>
        ),
      })
    }
    if (props.showStatus === true) {
      columns.push({
        key: 'status',
        title: I18n.t('general.status'),
        dataIndex: 'status',
        render: (data, record, index) => {
          // data === 'in_transit'
          return this.props.currentStatus === '' ? (
            // <Select
            //   style={{ width: '100%' }}
            //   defaultValue={'in_transit'}
            //   value={data}
            //   onChange={(value) => {
            //     confirmationPopup({
            //       title: I18n.t('messages.change_status'),
            //       content: I18n.t('messages.change_status_confirm'),
            //       onConfirm: () => {
            //         // this.props.changeStatus(record.id, value);
            //         this.props.handleStatusChange(value, [record.id])
            //       },
            //     })
            //   }}
            // >
            //   {AppConfig.recoveryStatuses.map(({ key, value, text }) => (
            //     <Select.Option
            //       value={key}
            //       key={value}
            //       className="selectOptionsWithIcon"
            //     >
            //       <div>
            //         {text}&nbsp;&nbsp;{getRecoveryStatusIcon(value)}
            //       </div>
            //     </Select.Option>
            //   ))}
            // </Select>
            <span
              className={`${
                data === '' ? 'textAllocated' : 'textCompleted'
              } textBold`}
            >
              {I18n.t(`recoveries.${data}`)}&nbsp;&nbsp;
              {getRecoveryStatusIcon(data)}
            </span>
          ) : (
            <span
              className={`${
                data === 'in_hand' ? 'textAllocated' : 'textCompleted'
              } textBold`}
            >
              {I18n.t(`recoveries.${data}`)}&nbsp;&nbsp;
              {getRecoveryStatusIcon(data)}
            </span>
          )
        },
        width: 140
      })
    }
    columns.push({
      key: 'weight',
      title: I18n.t('order.weight'),
      dataIndex: 'weight',
      render: (data) => formatWeight(data),
      minWidth: 80,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    })
    columns.push({
      key: 'items_count',
      title: I18n.t('order.qty'),
      dataIndex: 'pieces',
      render: (data) => data,
      sorter: true,
      width: 120,
      sortDirections: ['ascend', 'descend', 'ascend'],
    })
    // this.columns.push({
    //   key: 'driver_comments',
    //   title: I18n.t('recoveries.comments'),
    //   dataIndex: 'driver_comments',
    //   render: (data) =>
    //     !isEmpty(data) ? (
    //       <Popover title={I18n.t('recoveries.comments')} content={data}>
    //         <Icon type="message" />
    //       </Popover>
    //     ) : (
    //       ''
    //     ),
    // })

    const isWHstop = props.stopType && props.stopType === "WH";
         const isDispatchScreen = window.location.href.includes('routes')
         if (props.showActionExceedField === true) {
          // Push the column for the "action exceed" field
          columns.push({
            key: 'actionExceed',
            fixed:"right",
            render: (data) => {
              const { account_limit_exceeded } = data;
              return (
                <Row type="flex" gutter={4} align="middle" justify="end">
                  {account_limit_exceeded && (
                    <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                      <Icon type="info-circle" style={{ color: "red" }} />
                    </Tooltip>
                  )}
                </Row>
              );
            },
            align: 'center',
            width: isDispatchScreen ? 20 : 100,
          });
        }
        

        
        
        
    
    
    if (props.showActions === true && !isWHstop) {
      const isDispatchScreen = window.location.href.includes('routes')
      
      columns.push({
        key: 'items',
        fixed: "right",
        render: (data) => {
          const {account_limit_exceeded} = data
          return (
            <Row type="flex" gutter={8} align="middle" justify="end">
              {/* <Tooltip
                title={"View Recovery"}
                placement="left"
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
                <BranchesIcon 
                handleClick={() => {
                  this.props.history.push({
                    pathname: "/routes",
                    filter: "ASSIGNED",
                    routeId,
                    dateFilter: {
                      // fromDate: date,
                      // toDate: date,
                    },
                  });
                } }
                />
              </Tooltip> */}
            
                { account_limit_exceeded && (
                <Col>
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
                </Col>
              ) }
              
              {!isEmpty(data.driver_comments) && (
                <Col>
                  <Popover
                    title={I18n.t('recoveries.comments')}
                    content={data.driver_comments}
                    placement={'leftTop'}
                  >
                    <Icon type="message" />
                  </Popover>
                </Col>
              )}
              {!isDispatchScreen &&
              (<Col>
                <Tooltip
                  title={I18n.t('order.driver_notes')}
                >
                  <ShowDispatcherNotes
                    apiEndpoint={dispatcherNotesApi}
                    notesTitle="Driver Notes"
                    currentRecord={data}
                    key={`notes${data.id}`}
                  />
                  &nbsp;
                </Tooltip>
              </Col>
              )}

              {!isDispatchScreen &&
                checkServiceExistance('ORU') &&
                <Col>
                <Tooltip title={I18n.t('general.edit')}>
                  <EditIcon handleClick={() => this.props.handleEditClick(data)}/>
                  &nbsp;
                </Tooltip>
              </Col>
              }
              {/* {
                checkServiceExistance('ORDEL') && <Col>
                <Popconfirm
                  placement="left"
                  title={I18n.t('messages.delete_confirm', {
                    action: 'reject',
                    field: 'this',
                  })}
                  onConfirm={() => this.props.deleteClick(data.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title={I18n.t('general.delete')}>
                    <DeleteIcon/>
                    &nbsp;&nbsp;
                  </Tooltip>
                </Popconfirm>
              </Col>
              } */}
             {/* { account_limit_exceeded && (
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
              ) } */}
            </Row>
          )
        },
        align: 'center',
        width: isDispatchScreen ? 20 : 100,
      })
    }
    if(props.showSno === true){
      columns.splice(0,0, {
        key: 'index',
        title: '#',
        render: (data, record, index) => index+1,
      });
    }
    this.columns = columns.filter((column) => {
      if(props.showMiniDetails)
      {
        const keyToShow = ['hawb', 'mawb', 'order_no', 'reference_1', 'reference_2' ]
        return keyToShow.includes(column.key)
      }else if(props.columnsNotToDisplay) {
        const keysNotToShow = ["hawb","mawb","reference_1","reference_2","warehouse",'freight_forwarder','city','recovery_eta',"status"]
        return !keysNotToShow.includes(column.key)
      }
      else
        return true
    })
    const isRecoveryPage = props.screen_from === "recovery";
    if (!_.isEmpty(props.displayConfiguration) && isRecoveryPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const recovery_list_view_columns = userConfiguration.recovery_list_view_columns || orgConfiguration.recovery_list_view_columns;
      const columns = [];
      if (
        typeof recovery_list_view_columns !== undefined &&
        Array.isArray(recovery_list_view_columns)
      ) {
        recovery_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => ["items", "actionExceed"].includes(col.key));
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  }

  setAddress = (data, type) => {
    const pickUpdata = data?.pickup_location
      ? data.pickup_location.l_address
      : {}
    const pickUpAddress = (
      <Popover
        title={I18n.t('general.pickup_address')}
        content={<AddressInfo address={pickUpdata} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {pickUpdata && pickUpdata.city ? `${pickUpdata.city}` : 'NA'}
      </Popover>
    )
    const deliveryData = data?.drop_location ? data.drop_location.l_address : {}
    const dropAddress = (
      <Popover
        title={I18n.t('general.delivery_address')}
        content={<AddressInfo address={deliveryData} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {deliveryData && deliveryData.city ? `${deliveryData.city}` : 'NA'}
      </Popover>
    )
    if (type === 'origin') {
      return pickUpAddress
    }
    return dropAddress
  }

  componentWillReceiveProps(nextProps) {
    this.columns = []
    this.setColumns(nextProps)
  }
}

RecoveryList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
}

RecoveryList.defaultProps = {
  screen_from: '',
  showTriggerEdit: false,
  resolvedAddressCallback: () => {},
}
export default withRouter(RecoveryList)
