import React, { Component, Fragment} from 'react'
import { NotificationTemplatesApi } from '../../../api/NotificationTemplate'
import { alertMessage, doValidate, isEmpty } from '../../../common/Common'
import I18n from "../../../common/I18n";
import {
  Row,
  Select,
  Col,
  FormItem,
  Radio,
  Spin,
  Link,
  Mentions,
  Popconfirm,
  Button,
  Alert,
  Divider,
  Tooltip,
} from "../../../common/UIComponents";
import AppConfig from "../../../config/AppConfig";
import FormErrors from "../../common/FormErrors";
import { renderAlertMessage } from '../../../helpers/common';

const defaultValues = {
  CONSIGNEE: {
    TEXT: {
      currentPos: null,
      isDisableEdit: false,
      message_content: "",
      msg_applied_to: [],
    },
    EMAIL: {
      currentPos: null,
      isDisableEdit: false,
      message_content: "",
      msg_applied_to: [],
    },
  },
  SHIPPER: {
    TEXT: {
      currentPos: null,
      isDisableEdit: false,
      message_content: "",
      msg_applied_to: [],
    },
    EMAIL: {
      currentPos: null,
      isDisableEdit: false,
      message_content: "",
      msg_applied_to: [],
    },
  },
};

const notificationTypes = ["TEXT", "EMAIL"];
const messagesTo = ["CONSIGNEE", "SHIPPER"];
const applyToOptions = ["CONSIGNEE_TEXT", "CONSIGNEE_EMAIL", "SHIPPER_TEXT", "SHIPPER_EMAIL"];

const requiredFields = [
  {
    form_field: "event_code",
    ui_name: I18n.t("templates.event"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "notification_to",
    ui_name: I18n.t("templates.notification_to"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "_mode_of_communication",
    ui_name: I18n.t("templates.mode_of_communication"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "message_content",
    ui_name: I18n.t("templates.message"),
    isRequired: true,
    inputType: "text",
  },
];
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTemplate: {},
      tokens: this.props.totalTokens || [],
      isNew: !isEmpty(this.props.isNew) ? this.props.isNew : true,
      inProgress: false,
      currentPos: null,
      isDisableEdit: false,
      currentEvent: {...this.props.currentEvent},
      messages: {},
      defaultMsgLoading: false,
    };
  }

  componentDidMount() {
    if (!isEmpty(this.state.currentEvent)) {
      this.updateTokens(this.state.currentEvent.event_code);
    }
    this.setInitialValues()
  }

  setInitialValues = () => {
    const existingMessages = this.props?.currentMessages ? [...this.props.currentMessages] : []
    const data = _.cloneDeep(defaultValues)
    existingMessages.forEach(rec => {
      if(data[rec.notification_to] && data[rec.notification_to][rec._mode_of_communication]){
        data[rec.notification_to][rec._mode_of_communication].message_content = rec.message_content
      }
    })
    this.setState({
      messages: {...data},
    })
  }

  handleApplyMsgTo = (msgTo, notificationType) =>{
     const messages = _.cloneDeep(this.state.messages);
     const currentTemplate = messages[msgTo][notificationType];
     const appliedTo = currentTemplate.msg_applied_to;
     if (appliedTo && appliedTo.length) {
       if (appliedTo.includes("ALL")) {
         messagesTo.forEach((msgTo) => {
           notificationTypes.forEach((msgType) => {
             messages[msgTo][msgType] = {
               ...currentTemplate,
               msg_applied_to: messages[msgTo][msgType].msg_applied_to,
             };
           });
         });
       } else {
         appliedTo.forEach((rec) => {
           const types = _.split(rec, "_");
           messages[types[0]][types[1]] = {
             ...currentTemplate,
             msg_applied_to: messages[types[0]][types[1]].msg_applied_to,
           };
         });
       }
     }
     this.setState({
       messages,
     });

     alertMessage("Successfully Applied !!", "success", 10);
  }

  handleApplyChange  = (msgTo, notificationType, values) => {
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
     let selectedValues = values;
    //  if (values.length > 0) {
    //   if(values.includes("ALL")){
    //     selectedValues = applyToOptions;
    //   }
    //  }
     if (values[values.length - 1] === "ALL") {
       if (values.length > 0) {
         selectedValues = ["ALL"];
       }
     } else {
       if (values.includes("ALL")) {
         selectedValues = values.filter((value) => value !== "ALL");
       } else {
         selectedValues = values;
       }
     }
    currentTemplate.msg_applied_to = selectedValues;
    messages[msgTo][notificationType] = { ...currentTemplate };
    this.setState({
      messages,
    });
  }

  updateTokens = (eventCode) => {
    const totalTokens = this.props.totalTokens || [];
    let tokens = [...totalTokens];
    if (!isEmpty(eventCode)) {
      const rec = _.find(this.props.events, {
        event_code: eventCode,
      });
      if (!isEmpty(rec)) {
        const currentTokens = rec.template_params || [];
        tokens =
          currentTokens.length > 0
            ? totalTokens.filter((rec) => currentTokens.includes(rec.code))
            : [...totalTokens];
      }
      return tokens;
    }

    return [...this.props.totalTokens];
  };

  appendRecord = (msgTo, notificationType, element, option) => {
    this.handleOnChange(msgTo, notificationType, element, option); // option.value for mention on select
  };

  handleOnChange = (msgTo, notificationType, element, value) => {
    const { totalTokens } = this.props;
    let tokens = [...this.state.tokens];
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    let selectedTemplate = currentTemplate["message_content"];
    if (element === "token") {
      let finalTemplate = `${[
        selectedTemplate.slice(0, currentTemplate.currentPos),
        `{{${value}}}`,
        selectedTemplate.slice(currentTemplate.currentPos),
      ].join(" ")}`;
      currentTemplate["message_content"] = finalTemplate;
      currentTemplate["currentPos"] = currentTemplate["message_content"].length
    } else {
      if (element === "event_code") {
        tokens = this.updateTokens(value);
      }
      currentTemplate[element] = value;
    }
    messages[msgTo][notificationType] = currentTemplate
    this.setState({
      messages,
      tokens,
    });
  };

  onSelectChange = (msgTo, notificationType, value) => {
    let tokens = [...this.state.tokens];
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    const setValue = value;
    const hideEdit = this.handleDisable(msgTo, notificationType, currentTemplate.currentPos);
    let selectedTemplate = currentTemplate["message_content"];
    let index = selectedTemplate.slice(0, currentTemplate.currentPos).lastIndexOf("{{");
    let finalTemplate = `${[
      selectedTemplate.slice(0, index+2),
      `${setValue.value}}}`,
      selectedTemplate.slice(currentTemplate.currentPos),
    ].join("")}`;
    
    if (!hideEdit) {
      currentTemplate["message_content"] = finalTemplate;
    }
    // currentTemplate["message_content"] = finalTemplate;
    currentTemplate["currentPos"] = currentTemplate["message_content"].length;
    messages[msgTo][notificationType] = currentTemplate
    this.setState({
      messages,
      tokens,
    });
  };

  isInRange = (ranges, num) => {
    let inRange = false;
    ranges.forEach((range) => {
      if (num > range.start && num < range.end) {
        inRange = true;
      }
    });
    return inRange;
  };

  hasMatchingPairs = (text) => {
  const stack = [];

  text.split("").forEach(char => {
    if (char === "{") {
      stack.push("{");
    } else if (char === "}") {
      if (stack.length === 0 || stack.pop() !== "{") {
        return false;
      }
    }
  });

  return stack.length === 0;
  }

  handleKeyEvent = (msgTo, notificationType, e) => {
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    currentTemplate.currentPos = e.target.selectionStart + 1
    messages[msgTo][notificationType] = currentTemplate
    this.setState({ messages });
  };

  handleDisable = (msgTo, notificationType, currentPosition) => {
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    const message = currentTemplate.message_content;
    // const pattern = /\{\{[^\}]*\}\}/g;
    const pattern =  /\{\{[^{}]*\}\}/g;
    const matches = [];
    let match;
    while ((match = pattern.exec(message)) !== null) {
      matches.push({
        start: match.index,
        end: match.index + match[0].length,
      });
    }
    const isDisable = this.isInRange(matches, currentPosition);
    return isDisable;
  };

  handleMouseUp = (msgTo, notificationType, e) => {
    const selectedPosIndex = e.target.selectionStart;
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    currentTemplate.currentPos = selectedPosIndex;
    const hideEdit = this.handleDisable(msgTo, notificationType, selectedPosIndex)
    if (hideEdit) {
      currentTemplate.isDisableEdit = true;
    } else {
      currentTemplate.isDisableEdit = false;
      currentTemplate.currentPos =  e.target.selectionEnd;
    }
    messages[msgTo][notificationType] = currentTemplate
    this.setState({ messages });
  };

  handleKeyDown = (msgTo, notificationType, e) => {
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    let cursorPosition = currentTemplate.currentPos;
    const selectedPosIndex = e.target.selectionStart;
    if (e.key === "ArrowUp") {
      currentTemplate.currentPos = 0
    } else if (e.key === "ArrowDown") {
      currentTemplate.currentPos = currentTemplate["message_content"].length
    } else if (e.key === "ArrowLeft" || e.key === "Backspace") {
      currentTemplate.currentPos = cursorPosition - 1
    } else {
      currentTemplate.currentPos = cursorPosition + 1
    }
    const hideEdit = this.handleDisable(msgTo, notificationType, selectedPosIndex);
    if (hideEdit) {
      currentTemplate.isDisableEdit = true;
    } else {
      currentTemplate.isDisableEdit = false;
    }
    messages[msgTo][notificationType] = currentTemplate
    this.setState({ messages });
  };

  handleClear = () => {
    this.setState({
      messages: Object.assign({}, this.state.messages, {
        ...(_.cloneDeep(defaultValues)),
      }),
    });
  };

  handleClick = (event) => {
    event.preventDefault();
  };

  alert = () => {
    alertMessage("you can't modify mentioned attributre!!", "error", 10);
  };

  moveCursorToPosition = (msgTo, notificationType) => {
    const messages = _.cloneDeep(this.state.messages);
    const currentTemplate = messages[msgTo][notificationType];
    const MessageContent = currentTemplate.message_content;
    const myInput = document.getElementById("mention_msg"+msgTo+notificationType);
    myInput.focus();
    myInput.setSelectionRange(MessageContent.length, MessageContent.length);
    currentTemplate.isDisableEdit = false;
    currentTemplate.currentPos = MessageContent.length;
    messages[msgTo][notificationType] = currentTemplate
    this.setState({ messages });
  };

  resetMsgTo = (msgTo, notificationType) => {
    this.setState({ defaultMsgLoading: true });
   
    const notification_to = msgTo;
    const event_code = this.state.currentEvent.event_code;
    const communication_mode = notificationType;

    NotificationTemplatesApi.fetchDefaultMessage(
      notification_to,
      event_code,
      communication_mode
    ).then((result) => {
      if (result.success) {
        let messages = _.cloneDeep(this.state.messages);
        let currentTemplate = messages[msgTo][notificationType];
        currentTemplate.message_content = result.template_message;
        messages[msgTo][notificationType] = { ...currentTemplate };

        this.setState({
          messages,
          defaultMsgLoading: false,
        });
        alertMessage(I18n.t("templates.reset_success"), "success", 10);
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ defaultMsgLoading: false });
      }
    });
  };

  renderNotification = () => {
    const tokens = this.state.tokens || [];
    const { currentEvent } = this.state;
    const currentTemplate = this.state.messages || {};
    let filteredTokens =
        currentEvent && currentEvent.event_code
        ? this.updateTokens(currentEvent.event_code)
        : [];
    const messages = _.cloneDeep(this.state.messages);
    return (
      <Fragment>
        {/* <Row gutter={16} className="marginTop10">
          <Col xs={24}>
            <Row gutter={16}>
              <Col xs={7}>
                <FormItem
                  label={I18n.t("templates.event")}
                  className="marginTop10"
                  require="true"
                >
                  <Select
                    value={currentTemplate.event_code}
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => this.handleOnChange("event_code", e)}
                  >
                    {this.props.events.map((eve) => (
                      <Select.Option key={eve.id} value={eve.event_code}>
                        {eve.event_name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={8}>
                <FormItem
                  label={I18n.t("templates.notification_to")}
                  require="true"
                >
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnChange("notification_to", e.target.value)
                    }
                    value={currentTemplate.notification_to}
                    className="marginTop10"
                  >
                    {AppConfig.notificationModes.map((rec) => (
                      <Radio value={rec}>{rec}</Radio>
                    ))}
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={9} require="true">
                <FormItem label={I18n.t("templates.mode_of_communication")}>
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnChange(
                        "_mode_of_communication",
                        e.target.value
                      )
                    }
                    value={currentTemplate._mode_of_communication}
                    className="marginTop10"
                  >
                    <Radio value={"EMAIL"}>
                      {I18n.t("contactGroup.email_only")}
                    </Radio>
                    <Radio value={"TEXT"}>
                      {I18n.t("contactGroup.text_messages")}
                    </Radio>
                    <Radio value={"BOTH"}>{I18n.t("contactGroup.both")}</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row>
          <Col className='alignRight'>
            <Alert 
              message={<span className="notes_content textBold">
               Note: {`(You can click on any Attribute from attributes section or use \`{{\` to refer the ${I18n.t(
                  "templates.attributes"
                )} while you are typing)`}
              </span>} 
              type="info" 
              showIcon 
              className="alignLeft"
            />
              
          </Col>
        </Row>
        {Object.keys(currentTemplate).length > 0 && messagesTo.map((msgTo) => (
          <Fragment>
            {notificationTypes.map((notificationType) => {
              return (
              <Fragment>
              <Row gutter={16} className="marginTop10">
                <Col xs={19}>
                  <FormItem
                    label={
                      <Fragment>
                       <span className="notes_content">
                        <span className='textCaptilize'>
                          {msgTo ? msgTo.toLowerCase() : ''}&nbsp;{notificationType ? notificationType.toLowerCase() : ''}&nbsp;</span>{I18n.t("templates.message")}
                        </span>
                      </Fragment>
                    }
                    className="marginTop10"
                    require="true"
                  >
                    {/* <Button
                      type="primary"
                      style={{ position: "absolute", top: 4, right: 8 }}
                      onClick={() => this.resetMsgTo(msgTo, notificationType)}
                      size="small"
                    >
                      Reset to Default
                    </Button> */}
                       { 
                       currentTemplate[msgTo][notificationType]?.message_content && 
                       <span
                        style={{
                          fontSize: 12,
                          fontStyle: "italic",
                          fontWeight: 500,
                          position: "absolute", 
                          top: 11, 
                          right: 115
                        }}
                        className="anchor_cursor"
                       
                      >
                        <Popconfirm
                            placement="bottom"
                            title={I18n.t("messages.clear_confirm")}
                            onConfirm={() => this.handleOnChange(msgTo, notificationType, "message_content", "")}
                            okText="Yes"
                            cancelText="No"
                          >
                          <Tooltip title={I18n.t("templates.clear_tooltip")}>
                            <span style={{color: "red"}}>{I18n.t("general.clear")}</span>
                          </Tooltip>
                        </Popconfirm>
                      </span>
                      }
                      <span
                        style={{
                          fontSize: 12,
                          fontStyle: "italic",
                          fontWeight: 'bold',
                          position: "absolute", 
                          top: 11, 
                          right: 8
                        }}
                        className="anchor_cursor"
                        // onClick={() => this.resetMsgTo(msgTo, notificationType)}
                      >
                         <Popconfirm
                            placement="bottom"
                            title={I18n.t("general.are_you_sure")}
                            onConfirm={() =>  this.resetMsgTo(msgTo, notificationType)}
                            okText="Yes"
                            cancelText="No"
                          >
                          <Tooltip title={I18n.t("templates.reset_tooltip")}>
                            <span style={{color: "#102672"}}>{I18n.t("templates.reset")}</span>
                          </Tooltip>
                        </Popconfirm>
                      </span>
                    <Spin spinning={this.state.defaultMsgLoading} delay={1000}>
                    <Mentions
                      style={{ width: "100%" }}
                      rows="4"
                      placeholder={I18n.t("sms.example", {
                        theme: "{{CONSIGNEE_NAME}}",
                      })}
                      onChange={
                        !currentTemplate[msgTo][notificationType].isDisableEdit
                          ? (value) =>  this.handleOnChange(msgTo, notificationType, "message_content", value)
                          : () => this.alert()
                      }
                      // onSelect={(option) =>
                      //   this.appendRecord(currentTemplate.id, "token", option)
                      // }
                      //value={currentTemplate.message_content}
                      value={currentTemplate[msgTo][notificationType].message_content}
                      prefix={["{{"]}
                      onSelect={(value) => this.onSelectChange(msgTo, notificationType, value)}
                      onKeyPress={(e) => this.handleKeyEvent(msgTo, notificationType, e)}
                      onMouseUp={(e) => this.handleMouseUp(msgTo, notificationType, e)}
                      onKeyDownCapture={(e) => this.handleKeyDown(msgTo, notificationType, e)}
                      id={`mention_msg${msgTo+notificationType}`}
                      >
                      {filteredTokens.map((token, index) => (
                        <Option
                          key={`option${currentTemplate.id}${token.code}${index}`}
                          value={token.code}
                          style={{ color: "#004A7D" }}
                        >
                          {token.code}
                        </Option>
                      ))} 
                    </Mentions>
                    </Spin>
                  </FormItem>
                </Col>
                <Col xs={5}>
                  <FormItem
                    label={I18n.t("templates.attributes")}
                    className="marginTop10"
                  >
                    <div
                      style={{
                        height: 82,
                        overflowY: "auto",
                        padding: 5,
                        border: "1px solid #CCC",
                        fontSize: 12,
                        paddingBottom: 15,
                      }}
                    >
                      {filteredTokens.length
                        ? filteredTokens.map((token, index) => (
                            <div className='fontSize12'>
                              <Link
                                key={`link${msgTo}${notificationType}${token}${index}`}
                                onClick={
                                  !currentTemplate[msgTo][notificationType].isDisableEdit
                                    ? () => this.appendRecord(msgTo, notificationType, "token", token.code)
                                    : () => this.moveCursorToPosition(msgTo, notificationType)
                                }
                                style={{ cursor: "pointer", fontSize: 12 }}
                              >
                                {token.code}
                              </Link>
                            </div>
                          ))
                        : I18n.t("templates.alert")}
                    </div>
                  </FormItem>
                </Col>
              </Row>

              { !isEmpty(currentTemplate[msgTo][notificationType].message_content) && <Row gutter={16} className="marginTop10">
                <Col xs={13} className="textBold">
                  Apply the above message to: &nbsp;&nbsp;
                  <Select
                      // value={currentTemplate.event_code}
                      value={currentTemplate[msgTo][notificationType].msg_applied_to}
                      showSearch
                      style={{ width: "60%" }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      mode={"multiple"}
                      onChange={(e) => this.handleApplyChange( msgTo, notificationType, e)}
                      size="small"
                    >
                      <Select.Option key="" value="ALL">ALL</Select.Option>
                      {applyToOptions.filter(rec => rec !== `${msgTo}_${notificationType}`).map((rec) => (
                        <Select.Option key={rec} value={rec} className>
                          {rec ? _.capitalize(rec.replace("_", " ")) : ''}
                        </Select.Option>
                      ))}
                  </Select>
                  <Button
                    type="primary"
                    className="marginLeft10"
                    onClick={() => this.handleApplyMsgTo( msgTo, notificationType)}
                    size="small"
                    disabled={!messages[msgTo][notificationType].msg_applied_to.length}
                    icon='check-circle'
                  >
                    {I18n.t('general.apply')}
                  </Button>
                </Col>
              </Row>
              }
              <Divider style={{ margin: "10px 2px"}} />
             </Fragment>);
              
            })}

          </Fragment>
        ))}

        {/* <Row gutter={16} className="marginTop10">
          <Col xs={19}>
            <FormItem
              label={
                <Fragment>
                  {`${I18n.t("templates.message")}`}&nbsp;
                  <span className="notes_content">
                    {`(You can use \`{{\` to refer the ${I18n.t(
                      "templates.attributes"
                    )} while you are typing)`}
                  </span>
                </Fragment>
              }
              className="marginTop10"
              require="true"
            >
              <Mentions
                style={{ width: '100%' }}
                rows="6"
                placeholder={I18n.t("sms.example", {
                  theme: "{{CONSIGNEE_NAME}}",
                })}
                onChange={
                  !this.state.isDisableEdit
                    ? (value) => this.handleOnChange("message_content", value)
                    : () => this.alert()
                }
                // onSelect={(option) =>
                //   this.appendRecord(currentTemplate.id, "token", option)
                // }
                value={currentTemplate.message_content}
                prefix={["{{"]}
                onSelect={(value) => this.onSelectChange(value)}
                onKeyPress={(e) => this.handleKeyEvent(e)}
                onMouseUp={(e) => this.handleMouseUp(e)}
                onKeyDownCapture={(e) => this.handleKeyDown(e)}
                id={`mention_msg${msgTo+notificationType}`}
              >
                {tokens.map((token, index) => (
                  <Option
                    key={`option${currentTemplate.id}${token.code}${index}`}
                    value={token.code}
                    onClick={this.handleClick}
                  >
                    {token.code}
                  </Option>
                ))}
              </Mentions>
            </FormItem>
          </Col>
          <Col xs={5}>
            <FormItem
              label={I18n.t('templates.attributes')}
              className="marginTop10"
            >
              <div
                style={{
                  height: 135,
                  overflowY: 'auto',
                  padding: 5,
                  border: '1px solid #CCC',
                  fontSize: 12,
                  paddingBottom: 15,
                }}
              >
                {filteredTokens.length
                  ? filteredTokens.map((token, index) => (
                      <div>
                        <Link
                          key={`link${currentTemplate.id}${token}${index}`}
                          onClick={
                            !this.state.isDisableEdit
                              ? () => this.appendRecord("token", token.code)
                              : () => this.moveCursorToPosition()
                          }
                          style={{ cursor: "pointer", fontSize: 12 }}
                        >
                          {token.code}
                        </Link>
                      </div>
                    ))
                  : I18n.t("templates.alert")}
              </div>
            </FormItem>
          </Col>
        </Row> */}
        <Row>
          <Col xs={24} className="alignCenter marginTop10">
            <Button type="danger" onClick={() => this.handleClear()} icon='close-circle'>
              {I18n.t("general.clear")}
            </Button>{" "}
            &nbsp;
            <Button type="primary" onClick={() => this.handleSave()} icon='save'>
              {I18n.t("general.save")}
            </Button>{" "}
            &nbsp;
            {false && !this.state.isNew && (
              <Popconfirm
                placement="topRight"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => this.props.deleteClick(currentTemplate.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">{I18n.t("general.delete")}</Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  };

  handleSave = () => {
    this.setState({ inProgress: true });
  
    const account = this.props?.account ? this.props.account : {};
    const messages = Object.assign({}, this.state.messages);
    const data = { event_code: this.state.currentEvent.event_code};
    if (!isEmpty(account.id)) {
      data.account_id = account.id;
    }
    const event_params = []
    Object.keys(messages).forEach(msgTo => {
      Object.keys(messages[msgTo]).forEach(msgType => {
        if(messages[msgTo] && messages[msgTo][msgType] && messages[msgTo][msgType]['message_content']){
          const record = { notification_to: msgTo, _mode_of_communication: msgType, message_content: messages[msgTo][msgType]['message_content'] }
          event_params.push(record);
        }
      })
    })
    data.event_params = event_params;
    const errors = [];
    if (errors.length === 0) {
      const isNew = this.state.isNew;
      NotificationTemplatesApi.manage(true, data).then((result) => {
        if (result.success) {
          const msg = result.message ? result.message : I18n.t("messages.saved");
          alertMessage(msg, "success", 10);
          this.setState({ inProgress: false, errors: [] });
          this.props.handleSuccess();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false, errors: [] });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  render() {
    const templates = this.props.data || [];
    return (
      <div className="content">
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            <Col>{this.renderNotification()}</Col>
          </Row>
          <Row>
            <Col>{FormErrors(this.state.errors)}</Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
export default Form;
