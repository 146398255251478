/* global google */
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import { Draggable, Droppable } from "react-beautiful-dnd";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polyline,
    OverlayView,
} from "react-google-maps";
import MapCustomControl from "../common/MapCustomControl";
import {
    Spin,
    Row,
    Col,
    Icon,
    Tooltip,
    Popover,
    Avatar,
    Button,
} from "../../common/UIComponents";
import { isEmpty } from "../../common/Common";
import styles from "../common/mapStyle.json";
import { setOrderSequence } from "../../helpers/preplan";
import OrderMarker from "./OrderMarker";
import StopRoutes from "./StopRoutes";
import { formatByTimeConfig } from "../../helpers/common";
import OrderMarkerBeta from "./OrderMarkerBeta";

const MapActions = (props) => {
 const { panelSize, routesCopy, hideRoutes, routes, showOnMap , toggleRouteVisibility , handleShowOnMapClick } = props;
    return (
        <Row gutter={[8, 8]}>
            <Col>
                {routesCopy?.length > 0 && (
                    <Button
                        size="small"
                        type="primary"
                        icon={hideRoutes ? "eye" : "eye-invisible"}
                        onClick={() => toggleRouteVisibility()}
                    >
                        {hideRoutes ? "Show Routes" : "Hide Routes"}
                    </Button>
                )}
            </Col>
            <Col>
                {routes?.length > 0 && (
                    <Button
                        size="small"
                        type="default"
                        onClick={() => handleShowOnMapClick()}
                    >
                        {showOnMap ? "Show all" : "Show selected"}
                    </Button>
                )}
            </Col>
        </Row>
    );
};

const PreplanMainMap = (props) => {
	// const  { selectedOrderKeys } = props
    const {routes} = props;
    const [activeClusterList, setActiveClusterList] = useState([]);
    const [selectedList, setSelectedList] = useState(props.selectedOrderKeys);
    const [activeHoveredCluster, setActiveHoveredCluster] = useState({
        lat: null,
        lng: null,
    });

  // Function to toggle route visibility
    const toggleRouteVisibility = () => {
    	props.setRouteVisibility(!props.hideRoutes);
    };

    // Function to handle show all/show selected button click
    const handleShowOnMapClick = () => {
    	props.setShowOnMap();
    };



    const fetchClusteredOrders = useCallback((marker) => {
        const clusteredMarkers = props.orderInfo.reduce((acc, curr) => {
            if (curr.lat === marker.lat && curr.lng === marker.lng) {
                acc.push(curr);
            }
            return acc;
        }, []);

        return clusteredMarkers;
    }, [props.orderInfo]);

    const checkForCluster = useCallback((marker) => {
        const clusteredOrders = fetchClusteredOrders(marker);
        if (clusteredOrders.length > 1) {
            const { lat, lng } = marker;
            const activeClusterindex = activeClusterList.findIndex(
                ({ coords }) => coords.lat === lat && coords.lng === lng
            );
            const isExist = activeClusterindex !== -1;
            if (!isExist) {
                const clusterInfo = {
                    coords: { lat, lng },
                    selectedMarkersCount: 1,
                    clusterLength: clusteredOrders.length,
                };
                setActiveClusterList(activeClusterList.concat(clusterInfo));
            } else {
                const clusterInfo = activeClusterList[activeClusterindex];
                clusterInfo.selectedMarkersCount += 1;
                setActiveClusterList([...activeClusterList]);
            }
        }
    }, [fetchClusteredOrders, activeClusterList]);

    const fetchSelectedClustersInfo = useCallback((marker) => {
        const clusteredOrders = fetchClusteredOrders(marker);
        const selectedOrders = clusteredOrders.filter((order) =>
            selectedList.includes(order.id)
        );
        return {
            isFullySelected:
                selectedOrders.length === clusteredOrders.length,
            isPartiallySelected:
                selectedOrders.length > 0 &&
                selectedOrders.length < clusteredOrders.length,
        };
    }, [fetchClusteredOrders, selectedList]);

    const unselectMarkerFromCluster = useCallback((marker) => {
        const { lat, lng } = marker;
        const activeClusterindex = activeClusterList.findIndex(
            ({ coords }) => coords.lat === lat && coords.lng === lng
        );
        const isExist = activeClusterindex !== -1;
        if (isExist) {
            const clusterInfo = activeClusterList[activeClusterindex];
            clusterInfo.selectedMarkersCount -= 1;
            setActiveClusterList([...activeClusterList]);
        }
    }, [activeClusterList]);

	 useEffect(() => {
         const { selectedOrderKeys } = props;
         if (_.isEqual([ ...selectedOrderKeys ].sort(), [ ...selectedList ].sort())) {
             return;
         }

        if(selectedOrderKeys.length === 0){
            const selected = []
            setSelectedList([])
        } else {
            // const selected = selectedOrderKeys.filter(o => !_.includes(selectedList, o))
            // setSelectedList([...selectedList, ...selected])
            setSelectedList([...selectedOrderKeys])
        }
		
    }, [props.selectedOrderKeys]);

    const markSelected = (id) => {
        // if the id already exists in the selected list, remove 
        if (selectedList.includes(id)) {
            const list = selectedList.filter(o => o != id);
            setSelectedList(list);
            props.markSelected(id);
        } else {
            const allSelected = [ ...selectedList, id ];
            setSelectedList(allSelected);
            props.markSelected(id);
            // props.markMultiple(true, allSelected);

        }

    }
    // }, [ selectedList ]);

    const markMultiple = (checked, ordersList) => {
        if (checked) {
            const list = _.uniq([ ...selectedList, ...ordersList ]);
            setSelectedList(list);
        } else {
            const list = selectedList.filter(o => !ordersList.includes(o));
            setSelectedList(list);
        }
        props.markMultiple(checked, ordersList);
    }



    return (
        <Spin spinning={false} delay={1000}>
            <GoogleMap
                {...props}
                defaultOptions={{
                    streetViewControl: false,
                    scaleControl: true,
                    mapTypeControl: false,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    zoomControl: false,
                    fullscreenControl: true,
                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                    disableDefaultUI: true,
                    draggable: true,
                    keyboardShortcuts: false,
                    scrollwheel: true,
                    styles,
                }}
                defaultCenter={{ lat: 30.9873867, lng: -97.472588 }}
                defaultZoom={2}
                ref={(c) => {
                    if (props.onMapMounted) {
                        props.onMapMounted(c, props);
                    }
                }}
            >
                <MapCustomControl position={google.maps.ControlPosition.TOP_RIGHT}>
                    {props.showPreplnControl === true && (
                        <Fragment>
                            <div className="map-form">
                                <Row>
                                    <MapActions
                                        {...props}
                                        toggleRouteVisibility={toggleRouteVisibility}
                                        handleShowOnMapClick={handleShowOnMapClick}
                                    />
                                </Row>
                            </div>
                        </Fragment>
                    )}
                </MapCustomControl>
                {!isEmpty(props.orderInfo) &&
                    _.isArray(props.orderInfo) &&
                    props.orderInfo
                        .filter((order) => order.lat && order.lng)
                        .map((marker, routeIndex) => (
                            <OrderMarkerBeta
                                key={routeIndex}
                                marker={marker}
                                isAirCargo={props.isAirCargo}
                                setClusteredOrder={props.setClusteredOrder}
                                // {...props}
                                selectedOrderKeys={selectedList}
								activeClusterList={activeClusterList}
								checkForCluster={checkForCluster}
								fetchSelectedClustersInfo={fetchSelectedClustersInfo}
								unselectMarkerFromCluster={unselectMarkerFromCluster}
								selectedList={selectedList}
								fetchClusteredOrders={fetchClusteredOrders}
								activeHoveredCluster={activeHoveredCluster}
								setActiveHoveredCluster={setActiveHoveredCluster}
                                markSelected={markSelected}
                                markMultiple={markMultiple}
                            />
                        ))}
                        
                {!isEmpty(routes) && _.isArray(routes) && (
                    <StopRoutes 
                    {...props}
                    routes={routes}  />
                )}
                {!isEmpty(routes) &&
                    _.isArray(routes) &&
                    routes.map((route, routeIndex) => (
                        <Polyline
                            key={`polyLine${route.route_id}${routeIndex}`}
                            path={route.stop_markers
                                .filter((stop) => stop.status !== "CANCELLED")
                                .map((stop) => ({ lat: stop.lat, lng: stop.lng }))}
                            options={{
                                geodesic: true,
                                strokeColor: `#${route.bgColor}`,
                                strokeOpacity: 1.0,
                                strokeWeight: route.route_id === props.selectedRoute ? 4 : 2,
                            }}
                        />
                    ))}
                {!isEmpty(props.orderInfo) &&
                    _.isArray(props.orderInfo) &&
                    !isEmpty(selectedList) &&
                    _.isArray(selectedList) && (
                        <Polyline
                            key="currentProcessing"
                            path={setOrderSequence(props.orderInfo, selectedList)}
                            options={{
                                geodesic: true,
                                strokeColor: "#734088",
                                strokeOpacity: 1.0,
                                strokeWeight: 2,
                            }}
                        />
                    )} 
            </GoogleMap>
            <div
                style={{
                    width: 30,
                    float: "right",
                    position: "relative",
                    bottom: "14vh",
                    right: 30,
                }}
            >
                {selectedList.length > 0 && props.renderCartIcon(selectedList.length)}
            </div>
        </Spin>
    );
};

export default withScriptjs(withGoogleMap(PreplanMainMap));