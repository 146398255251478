import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Popconfirm,
  Tooltip,
  Icon,
} from '../../common/UIComponents'
import { alertMessage,  isEmpty } from '../../common/Common'
import { _ } from 'core-js'
import BaseModal from '../BaseModal'
import FormErrors from '../common/FormErrors';
import EmailsInput from '../../containers/Modals/EmailsInput'
import { QuotesApis } from '../../api/QuotesApi'
import I18n from '../../common/I18n'

const EmailQuote = ({
  quote_id,
  quote_no,
  className,
  displayType,
  size,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [emails, setEmails] = useState([])

  const resetValues = (closeModal = false) => {
    setShowInfoWindow(false)
  }

  const handleSendingMails = async () => {
    try {
      const payload = {
        quote_id,
        emails : emails.join(','),
      }
      setLoading(true)
      let response = await QuotesApis.emailQuote(quote_id, payload)
      if (response.success) {
        const result = response?.data ? response.data : {}
        const successMessage = result?.message
          ? result.message
          : I18n.t('messages.saved')
        //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
        if(result.errors && result.errors.length > 0) {
          setErrors(result.errors)
        } else{
          alertMessage(successMessage, 'success', 5)
          resetValues()
          setShowInfoWindow(false);
        }
      } else {
        setErrors([...response.errors])
        // throw new Error(response.errors[0])
      }
    } catch (error) {
      alertMessage(error.message, 'error', 10)
    } finally {
      setLoading(false)
    }
  }

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={ I18n.t("general.send_to", { from: `${I18n.t("quotes.label")} : #${quote_no} `, to: "following emails" }) }
        className="customer-orders-modal"
        onCancel={() => {
          resetValues()
        }}
        width="45%"
      >
        <Row className="tagsInput" type='flex' align='middle' gutter={16}>
          <Col span={ 21 }>
            <EmailsInput emails={ emails } onChange={(mails) => setEmails(mails)} />
          </Col>
          <Col span={ 3 }>
            <Popconfirm
              placement="topRight"
              title={ I18n.t('messages.confirm') }
              onConfirm={ handleSendingMails }
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={ loading } disabled={
                !emails.length
              }>
                Send
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        {FormErrors(errors)}
      </BaseModal>
    )
  }

  return (
    <Fragment>
      <Tooltip title="Send Email">
      { displayType === 'icon' ?  <Icon
        onClick={() => setShowInfoWindow(true)}
        type="mail"
        className={className ? className : ''}
      />
      :
      <Button type="danger" icon='mail' size={!isEmpty(size) ? size : 'default'} onClick={() => setShowInfoWindow(true)}> Email</Button>
      }
      </Tooltip>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

EmailQuote.propTypes = {
  quote_id: PropTypes.string.isRequired,
  quote_no: PropTypes.string.isRequired,
  
}

export default EmailQuote
