import React from "react";
import BaseList from "../../BaseList";
import PropTypes from "prop-types";
import { Icon,Popconfirm, Tooltip } from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import DeleteIcon from "../../common/DeleteIcon";
class ContactsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "name",
        title: "Name",
        render: (data) => <span>{`${data.first_name} ${data.last_name}`}</span>,
      },
      {
        key:"email",
        title:"Email",
        render: (data) => <span>{data.email}</span>
      },
      {
        key:"phone",
        title:"Phone",
        render: (data) => <span>{data.phone}</span>
      },
      // {
      //   key:"delete",
      //   title:"",
      //   render: (data) => <span><Icon type="delete" onClick={() => this.props.handleDelete(data.id)} /></span>
      // }
      {
        key:"delete",
        title:"",
        render:(data)=><span>
          <Popconfirm 
          placement="left"
          title={I18n.t("messages.delete_confirm")}
          onConfirm={()=>this.props.handleDelete(data.id)}
          okText="Yes"
          cancelText="No">
            <Tooltip title={I18n.t("general.delete")}>
              <span><DeleteIcon/></span>
            </Tooltip>
          </Popconfirm>
        </span>
      }
    ];
  }
}

export default ContactsList;

ContactsList.propTypes = {
  pagination : PropTypes.object,
};
ContactsList.defaultProps = {
  pagination :{
    position : "none",
  }
}
