import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Icon,
  Card,
  Tooltip,
  Skeleton,
} from "../../common/UIComponents";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { formatStopName, formatStopAddress } from "../../helpers/stops";
import StopAddress from "../stops/StopAddress";
import { formatStopUTcTime,isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import { OrgContext } from "../../context/OrgContext";
import { renderStopIcon } from "../../helpers/dispatch";

const Stops = (props) => {
  const { stopsData, onDragEnd, onUpdateOrder, isLoading, ignoredStopsLength } =
    props;
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [isMilitaryTime, setIsMilitaryTime] = useState(false);

  useEffect(() => {
    if (
      organizationSettings &&
      organizationSettings.is_military_time === "true"
    ) {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [organizationSettings]);

  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    color: isDraggingOver ? "rgba(0, 0, 0, .65)" : "#607ad6",
  });
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // padding: grid * 0.75,
    margin: `0 0 ${grid}px 0`,
    // background: isDragging ? 'white' : '#e8e8e8',
    color: isDragging ? "#607ad6" : "rgba(0, 0, 0, .65)",
    cursor: "move !important",
    borderRadius: 10,
    padding: 2,
    ...draggableStyle,
  });

  return (
    <Fragment>
      <Col style={{ height: "80vh", overflowY: "auto", padding: 10 }}>
        <div id="right-panel" style={{ float: "none" }}>
          {stopsData.length > 0 ? (
            <Fragment>
              <div>
                {/* <b>Routes</b> <br /> */}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {stopsData &&
                          stopsData.map((task, index) => {
                            const contact_details = task.contact_details
                              ? task.contact_details
                              : {};
                            const stop_name = formatStopName(
                              contact_details,
                              task.location,
                              task.stop_number
                            );
                            const address =
                              task.location && task.location.l_address
                                ? formatStopAddress(task.location.l_address)
                                : "";
                            const estimated_departure_date_time_with_tz =
                              !isEmpty(
                                task.estimated_departure_date_time_with_tz
                              )
                                ? task.estimated_departure_date_time_with_tz
                                : "";
                            const estimated_arrival_date_time_with_tz =
                              !isEmpty(task.estimated_arrival_date_time_with_tz)
                                ? task.estimated_arrival_date_time_with_tz
                                : "";
                            const scheduled_start_date_time_with_tz = !isEmpty(
                              task.scheduled_start_date_time_with_tz
                            )
                              ? task.scheduled_start_date_time_with_tz
                              : "";
                            const scheduled_end_date_time_with_tz = !isEmpty(
                              task.scheduled_end_date_time_with_tz
                            )
                              ? task.scheduled_end_date_time_with_tz
                              : "";
                            const isShowScheduled =
                              task.scheduled_start_date_time_with_tz !== "" ||
                              task.scheduled_end_date_time_with_tz !== "";
                            const isShowEstimated =
                              task.estimated_arrival_date_time_with_tz !== "" ||
                              task.estimated_departure_date_time_with_tz !== "";
                            const timeZone = task.stop_tz_short_name;
                            return (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                                isDragDisabled={
                                  !(
                                    task.location &&
                                    task.location_type !== "WH" &&
                                    task.status !== "COMPLETED" &&
                                    task.status !== "ARRIVED"
                                  )
                                }
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    isDragDisabled="true"
                                    style={getItemStyle(
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Fragment key={`timeline_fragment${index}`}>
                                      <Card
                                        size="small"
                                        bodyStyle={{
                                          paddingLeft: 10,
                                          textAlign: "left",
                                          cursor: "pointer",
                                        }}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Row>
                                          <Col>
                                            <Row>
                                              <Col xs={2}>
                                                <span className="newBadge cursorPointer">
                                                  {ignoredStopsLength + index}{" "}
                                                </span>
                                              </Col>
                                              <Col
                                                xs={21}
                                                className="alighLeft"
                                              >
                                                <span className="marginRight5">{renderStopIcon(task.location_type)}</span>{stop_name}
                                              </Col>
                                              <Col
                                                xs={1}
                                                className="alignRight"
                                              >
                                                <span
                                                  {...provided.dragHandleProps}
                                                >
                                                  <Tooltip
                                                    title={I18n.t(
                                                      "preplan.move_stop"
                                                    )}
                                                    placement="bottom"
                                                  >
                                                    <Icon
                                                      type="menu"
                                                      className="textBold alignRight"
                                                      style={{
                                                        width: 16,
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </span>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col>
                                            <StopAddress
                                              address={address}
                                              title=""
                                            />
                                          </Col>
                                          <Col>
                                            <Row style={{ paddingTop: 5 }}>
                                              <Col
                                                xs={21}
                                                style={{ paddingTop: 1 }}
                                              >
                                                <div className="fontsize11 smallText">
                                                  <Tooltip
                                                    title={I18n.t(
                                                      "order.scheduled_time"
                                                    )}
                                                  >
                                                    {I18n.t(
                                                      "routes.scheduled_time"
                                                    )}
                                                    :&nbsp;
                                                    {isShowScheduled ? (
                                                      <Fragment>
                                                        {formatStopUTcTime(
                                                          scheduled_start_date_time_with_tz,
                                                          scheduled_end_date_time_with_tz,
                                                          timeZone,
                                                          false,
                                                          isMilitaryTime
                                                        )}
                                                      </Fragment>
                                                    ) : (
                                                      "Not available"
                                                    )}
                                                  </Tooltip>
                                                </div>
                                              </Col>
                                              <Col
                                                xs={3}
                                                className="paddingRight5"
                                              />
                                            </Row>
                                          </Col>
                                          <Col>
                                            <Row style={{ paddingTop: 5 }}>
                                              <Col
                                                xs={21}
                                                style={{ paddingTop: 1 }}
                                              >
                                                <div className="fontsize11 smallText">
                                                  <Tooltip
                                                    title={I18n.t(
                                                      "order.estimated_time"
                                                    )}
                                                  >
                                                    {I18n.t(
                                                      "routes.estimated_time"
                                                    )}
                                                    :&nbsp;
                                                    {estimated_arrival_date_time_with_tz !==
                                                      "" ||
                                                    estimated_departure_date_time_with_tz !==
                                                      "" ? (
                                                      <Fragment>
                                                        {isShowEstimated ? (
                                                          <Fragment>
                                                            {formatStopUTcTime(
                                                              estimated_arrival_date_time_with_tz,
                                                              estimated_departure_date_time_with_tz,
                                                              timeZone,
                                                              false,
                                                              isMilitaryTime
                                                            )}
                                                          </Fragment>
                                                        ) : (
                                                          "NA"
                                                        )}
                                                      </Fragment>
                                                    ) : (
                                                      "Not available"
                                                    )}
                                                  </Tooltip>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Card>
                                      {/* <div className="alignLeft textPrimary smallText">
                                        <Icon type="home" /> &nbsp;
                                        {`Stop ${
                                          ignoredStopsLength + index
                                        } - ${stop_name}`}
                                      </div> */}
                                    </Fragment>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <Col xs={24} className="floatNone" style={{ padding: "0 15%" }}>
                <Row className="optimize_bottom_fixed">
                  <Col
                    xs={24}
                    className="floatNone"
                    style={{ padding: "0 15%" }}
                  >
                    <Button
                      type="primary"
                      className="buttonOrder"
                      onClick={onUpdateOrder}
                      loading={isLoading}
                    >
                      Save Stop Sequence
                    </Button>
                  </Col>
                </Row>
              </Col>
              {/* <Row> In KM: <Switch size="small" checked={this.state.disabled} onChange={this.handleDisabledChange} /></Row> */}
              <div id="directions-s" />
            </Fragment>
          ) : (
            //<h3 className="alignCenter">There are no stops to resequence</h3>
            <Skeleton />
          )}
        </div>
      </Col>
    </Fragment>
  );
};
export default Stops;
