/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { doValidate, formatePhoneNo, isEmpty, sqInchesToSqFeet, validateAddressForm } from '../common/Common'
import I18n from '../common/I18n'
import AppConfig from '../config/AppConfig'
import { formatByTimeConfig } from './common'
import { momentTime, isMomentObject } from './date_functions'
import { isOptedExpedite } from './orders'
import { getValueFromArrayOfObjects } from './array_functions'

export const setEditPayload = (
  data,
  currentOrder,
  suppres_schedule_dates,
  skip_warn_hawb_uniqness,
  accounts,
  levelOfServices,
  nsr,
) => {
  const hasExpedite = isOptedExpedite(data.appointments)
  const reqAccObject = getValueFromArrayOfObjects(accounts, "code" , currentOrder.account_code);
  const reqLOSObject = getValueFromArrayOfObjects(levelOfServices, "code" , currentOrder.los);
  const delivery_date = isMomentObject(currentOrder.delivery_date, currentOrder.delivery_time, "DD-MM-YYYY");
  const delivery_time = isMomentObject(currentOrder.delivery_time, currentOrder.delivery_date, "HH:mm");
  const payload = {
    accessorials: data.accessorials,
    appointments: data.appointments,
    customer_order_id: currentOrder.id,
    custom_appt_required: currentOrder.custom_appt_required,
    custom_appt_notes: currentOrder.custom_appt_notes,
    customer_comments: currentOrder.customer_comments,
    customer_notes: currentOrder.customer_notes,
    order_weight: currentOrder.weight,
    customer_order_number: !isEmpty(currentOrder.customer_order_number)
      ? currentOrder.customer_order_number.toUpperCase()
      : '',
    private_notes: currentOrder.private_notes,
    quantity: currentOrder.quantity,
    warehouse_code: currentOrder.warehouse_code,
    account: currentOrder.account_code,
    los: !isEmpty(currentOrder.los) ? currentOrder.los.toUpperCase() : '',
    account_name: !isEmpty(reqAccObject) ? reqAccObject.name : '',
    account_id: !isEmpty(reqAccObject) ? reqAccObject.id : '',
    los_name: !isEmpty(reqLOSObject) ? reqLOSObject.name : '',
    // los_id: !isEmpty(reqLOSObject) ? reqLOSObject.id : '', // contracts
    appt_set: currentOrder.appt_set,
    type_of_order: currentOrder.type_of_order,
    reference_1: currentOrder.reference_1,
    reference_1_type: currentOrder.reference_1_type,
    reference_2: currentOrder.reference_2,
    reference_2_type: currentOrder.reference_2_type,
    vehicle_type:currentOrder.vehicle_type,
    is_hazardous: currentOrder.is_hazardous,
    priority : currentOrder.priority,
    consignee_details : currentOrder.consignee_details || {},
    account_manager_emails: !isEmpty(
      currentOrder.account_manager_emails
    )
      ? currentOrder.account_manager_emails
      : "",
    service_duration: !isEmpty(currentOrder.service_duration)
      ? currentOrder.service_duration
      : '',
    received_at: !isEmpty(currentOrder.received_at_date) && !isEmpty(currentOrder.received_at_time) ? {
        date: moment.isMoment(currentOrder.received_at_date) ? currentOrder.received_at_date.format('DD-MM-YYYY') : currentOrder.received_at_date,
        time: moment.isMoment(currentOrder.received_at_time) ? currentOrder.received_at_time.format('HH:mm') : currentOrder.received_at_time,
      } : null,
    delivery_by: !isEmpty(delivery_date) && !isEmpty(delivery_time) ? delivery_date + ' ' + delivery_time : null,
    contact_customer:
      AppConfig.orderContactCustomer[currentOrder.type_of_order],
    first_name: !isEmpty(currentOrder.customer_first_name)
      ? currentOrder.customer_first_name
      : '',
    last_name: !isEmpty(currentOrder.customer_last_name)
      ? currentOrder.customer_last_name
      : '',
    customer_email: currentOrder.customer_email,
    hawb: currentOrder.hawb,
    mawb: currentOrder.mawb,
    nsr:!nsr,
    lh_billing : currentOrder.lh_billing,
    origin_port : currentOrder.origin_port,
    destination_port : currentOrder.destination_port,
    it_number : currentOrder.it_number,
    routing : currentOrder.routing,
    references : currentOrder.references,
    phone_number_one: currentOrder.customer_phone_one,
    phone_number_two: currentOrder.customer_phone_two,
    company_name: currentOrder.company_name,
    customer_details: {
      address_type: !isEmpty (currentOrder.address_type) ? currentOrder.address_type : AppConfig.default_address_type,
      vehicle_type: !isEmpty (currentOrder.vehicle_type) ? currentOrder.vehicle_type : '',
      company_name: !isEmpty(currentOrder.company_name)
        ? currentOrder.company_name
        : '',

      customer_first_name: !isEmpty(currentOrder.customer_first_name)
        ? currentOrder.customer_first_name
        : '',
      customer_last_name: !isEmpty(currentOrder.customer_last_name)
        ? currentOrder.customer_last_name
        : '',
      customer_email: currentOrder.customer_email,
      customer_phone_one: currentOrder.customer_phone_one,
      customer_phone_two: currentOrder.customer_phone_two,
      address: {
        customer_address_line_1: data.csLocation.address_line1,
        customer_address_line_2: data.csLocation.address_line2,
        customer_city: data.csLocation.city,
        customer_state: data.csLocation.state,
        customer_country: data.csLocation.country,
        customer_zipcode: data.csLocation.zipcode,
        id: data?.csLocation?.id ? data.csLocation.id : ''
      },
    },
    pallets: currentOrder.pallets,
    // length: currentOrder.length,
    // width: currentOrder.width,
    // height: currentOrder.height,
    surface_area: currentOrder.surface_area,
    notes: currentOrder.notes,
    instructions_from_account_1: currentOrder.instructions_from_account_1,
    instructions_from_account_2: currentOrder.instructions_from_account_2,
    instructions_from_account_3: currentOrder.instructions_from_account_3,
    suppres_schedule_dates: suppres_schedule_dates,
    skip_warn_hawb_uniqness: skip_warn_hawb_uniqness,
    has_expedite: hasExpedite,
    operation_code: 'SCA',
  }
  return payload
}

export const setCreatePayload = (
  data,
  currentOrder,
  suppres_schedule_dates,
  skip_warn_hawb_uniqness,
  accounts,
  levelOfServices,
  nsr
) => {
  const hasExpedite = isOptedExpedite(data.appointments)
  const reqAccObject = getValueFromArrayOfObjects(accounts, "code" , currentOrder.account_code);
  const reqLOSObject = getValueFromArrayOfObjects(levelOfServices, "code" , currentOrder.los);
  const delivery_date = isMomentObject(currentOrder.delivery_date, currentOrder.delivery_time, "DD-MM-YYYY");
  const delivery_time = isMomentObject(currentOrder.delivery_time, currentOrder.delivery_date, "HH:mm");
  const payload = {
    appointments: data.appointments,
    address_type: !isEmpty (currentOrder.address_type) ? currentOrder.address_type : AppConfig.default_address_type,
    vehicle_type: !isEmpty (currentOrder.vehicle_type) ? currentOrder.vehicle_type : '',
    customer_order_number: !isEmpty(currentOrder.customer_order_number)
      ? currentOrder.customer_order_number.toUpperCase()
      : '',
    private_notes: currentOrder.private_notes,
    quantity: currentOrder.quantity,
    order_weight: currentOrder.weight,
    warehouse_code: currentOrder.warehouse_code,
    account: currentOrder.account_code,
    los: !isEmpty(currentOrder.los) ? currentOrder.los.toUpperCase() : '',
    account_name: !isEmpty(reqAccObject) ? reqAccObject.name : '',
    account_id: !isEmpty(reqAccObject) ? reqAccObject.id : '',
    los_name: !isEmpty(reqLOSObject) ? reqLOSObject.name : '',
    //los_id: !isEmpty(reqLOSObject) ? reqLOSObject.id : '', // // contarct change
    appt_set: currentOrder.appt_set,
    type_of_order: currentOrder.type_of_order,
    reference_1: currentOrder.reference_1,
    reference_1_type: currentOrder.reference_1_type,
    reference_2: currentOrder.reference_2,
    reference_2_type: currentOrder.reference_2_type,
    is_hazardous: currentOrder.is_hazardous,
    priority : currentOrder.priority,
    consignee_details : currentOrder.consignee_details || {},
    account_manager_emails: !isEmpty(
      currentOrder.account_manager_emails
    )
      ? currentOrder.account_manager_emails
      : "",
    service_duration: !isEmpty(currentOrder.service_duration)
      ? currentOrder.service_duration
      : '',
    received_at: !isEmpty(currentOrder.received_at_date) && !isEmpty(currentOrder.received_at_time) ? {
      date: moment.isMoment(currentOrder.received_at_date) ? currentOrder.received_at_date.format('DD-MM-YYYY') : currentOrder.received_at_date,
      time: moment.isMoment(currentOrder.received_at_time) ? currentOrder.received_at_time.format('HH:mm') : currentOrder.received_at_time,
    } : null,
    delivery_by: !isEmpty(delivery_date) && !isEmpty(delivery_time) ? delivery_date + ' ' + delivery_time : null,
    contact_customer:
      AppConfig.orderContactCustomer[currentOrder.type_of_order],
    company_name: currentOrder.company_name,
    custom_appt_required: currentOrder.custom_appt_required,
    custom_appt_notes: currentOrder.custom_appt_notes,
    customer_comments: currentOrder.customer_comments,
    customer_notes: currentOrder.customer_notes,
    first_name: !isEmpty(currentOrder.customer_first_name)
      ? currentOrder.customer_first_name
      : '',
    last_name: !isEmpty(currentOrder.customer_last_name)
      ? currentOrder.customer_last_name
      : '',
    customer_email: currentOrder.customer_email,
    hawb: currentOrder.hawb,
    mawb: currentOrder.mawb,
    nsr: !nsr,
    origin_port : currentOrder.origin_port,
    destination_port : currentOrder.destination_port,
    it_number : currentOrder.it_number,
    routing : currentOrder.routing,
    references : currentOrder.references,
    phone_number_one: currentOrder.customer_phone_one,
    phone_number_two: currentOrder.customer_phone_two,
    address: {
      company_name: currentOrder?.cs_location?.company_name
        ? currentOrder.cs_location.company_name
        : '',
      customer_address_line_1: data.csLocation.address_line1,
      customer_address_line_2: data.csLocation.address_line2,
      customer_city: data.csLocation.city,
      customer_state: data.csLocation.state,
      customer_country: data.csLocation.country,
      customer_zipcode: data.csLocation.zipcode,
    },
    item_name: '',
    notes: currentOrder.notes,
    pallets: currentOrder.pallets,
    // length: currentOrder.length,
    // width: currentOrder.width,
    // height: currentOrder.height,
    surface_area: currentOrder.surface_area,
    instructions_from_account_1: currentOrder.instructions_from_account_1,
    instructions_from_account_2: currentOrder.instructions_from_account_2,
    instructions_from_account_3: currentOrder.instructions_from_account_3,
    suppres_schedule_dates: suppres_schedule_dates,
    has_expedite: hasExpedite,
    operation_code: 'SCA',
    skip_warn_hawb_uniqness: skip_warn_hawb_uniqness,
    driver_id: currentOrder.driver_id,
    lh_billing : currentOrder.lh_billing,
    fleet_id: currentOrder.fleet_id,
    trailer_number: currentOrder.trailer_number,
  }
  return payload
}

export const setLHCreatePayload = (
  data,
  currentOrder,
  suppres_schedule_dates,
  skip_warn_hawb_uniqness,
  accounts,
  levelOfServices,
  nsr
) => {
  const hasExpedite = isOptedExpedite(data.appointments)
  let pickupLocation = _.find(data.locations, {
    type_of_loc: 'PICKUP',
  })
  const currentLocation = !isEmpty(pickupLocation) ? { ...pickupLocation } : {};
  const reqAccObject = getValueFromArrayOfObjects(accounts, "code" , currentOrder.account_code);
  let reqLOSObject = null;
  if(currentOrder.los){
    reqLOSObject = getValueFromArrayOfObjects(levelOfServices, "code" , currentOrder.los);
    if(reqLOSObject){
      reqLOSObject.los = reqLOSObject.code;
    }
  } else if(pickupLocation){
    reqLOSObject = getValueFromArrayOfObjects(levelOfServices, "code" , pickupLocation.los_code);
    if(reqLOSObject){
      reqLOSObject.los = reqLOSObject.code;
    }
  }
  if(isEmpty(currentLocation.details)){
    currentLocation.details = {}
    currentLocation.l_address = {}
  }
  const locations = _.sortBy(data.locations, "loc_order_sequence");
  const delivery_date = isMomentObject(currentOrder.delivery_date, currentOrder.delivery_time, "DD-MM-YYYY");
  const delivery_time = isMomentObject(currentOrder.delivery_time, currentOrder.delivery_date, "HH:mm");
  const payload = {
    customer_order_number: !isEmpty(currentOrder.customer_order_number)
      ? currentOrder.customer_order_number.toUpperCase()
      : '',
    lh_order_ids: currentOrder?.lh_orders ? currentOrder.lh_orders.map(order => order.id) : [],
    address_type: !isEmpty (currentOrder.address_type) ? currentOrder.address_type : AppConfig.default_address_type,
    vehicle_type: !isEmpty (currentOrder.vehicle_type) ? currentOrder.vehicle_type : '',
    private_notes: currentOrder.private_notes,
    quantity: currentOrder.quantity,
    order_weight: currentOrder.weight,
    warehouse_code: currentOrder.warehouse_code,
    account: currentOrder.account_code,
    account_name: !isEmpty(reqAccObject) ? reqAccObject.name : '',
    account_id: !isEmpty(reqAccObject) ? reqAccObject.id : '',
    los_name: !isEmpty(reqLOSObject) ? reqLOSObject.name : '',
    //los_id: !isEmpty(reqLOSObject) ? reqLOSObject.id : '', // contarct change
    los: reqLOSObject && !isEmpty(reqLOSObject.los) ? reqLOSObject.los.toUpperCase() : '',
    appt_set: currentOrder.appt_set,
    type_of_order: currentOrder.type_of_order,
    reference_1: currentOrder.reference_1,
    reference_1_type: currentOrder.reference_1_type,
    reference_2: currentOrder.reference_2,
    reference_2_type: currentOrder.reference_2_type,
    is_hazardous: currentOrder.is_hazardous,
    priority : currentOrder.priority,
    consignee_details:  currentOrder.consignee_details || {},
    account_manager_emails: !isEmpty(
      currentOrder.account_manager_emails
    )
      ? currentOrder.account_manager_emails
      : "",
    service_duration: !isEmpty(currentOrder.service_duration)
      ? currentOrder.service_duration
      : '',
    received_at: !isEmpty(currentOrder.received_at_date) && !isEmpty(currentOrder.received_at_time) ? {
        date: moment.isMoment(currentOrder.received_at_date) ? currentOrder.received_at_date.format('DD-MM-YYYY') : currentOrder.received_at_date,
        time: moment.isMoment(currentOrder.received_at_time) ? currentOrder.received_at_time.format('HH:mm') : currentOrder.received_at_time,
      } : null,
    delivery_by: !isEmpty(delivery_date) && !isEmpty(delivery_time) ? delivery_date + ' ' + delivery_time : null,
    contact_customer:
      AppConfig.orderContactCustomer[currentOrder.type_of_order],
    company_name: currentLocation.details.company_name,
    has_recovery  : locations.filter((loc) => loc?.details?.is_recovery === true).length > 0,
    has_release  : locations.filter((loc) => loc?.details?.is_release === true).length > 0,
    custom_appt_required: currentOrder.custom_appt_required,
    custom_appt_notes: currentOrder.custom_appt_notes,
    customer_comments: currentOrder.customer_comments,
    customer_notes: currentOrder.customer_notes,
    first_name: !isEmpty(currentLocation.details.customer_first_name)
      ? currentLocation.details.customer_first_name
      : '',
    last_name: !isEmpty(currentLocation.details.customer_last_name)
      ? currentLocation.details.customer_last_name
      : '',
    customer_email: currentLocation.details.customer_email,
    phone_number_one: currentLocation.details.customer_phone_one,
    phone_number_two: currentLocation.details.customer_phone_two,
    hawb: currentOrder.hawb,
    references :currentOrder.references,
    mawb: currentOrder.mawb,
    nsr: !nsr,
    origin_port : currentOrder.origin_port,
    destination_port : currentOrder.destination_port,
    it_number : currentOrder.it_number,
    routing : currentOrder.routing,
    address: {
      company_name: !isEmpty(currentLocation.details.company_name)
        ? currentLocation.details.company_name
        : '',
      customer_address_line_1: currentLocation.l_address.address_line1,
      customer_address_line_2: currentLocation.l_address.address_line2,
      customer_city: currentLocation.l_address.city,
      customer_state: currentLocation.l_address.state,
      customer_country: currentLocation.l_address.country,
      customer_zipcode: currentLocation.l_address.zipcode,
      id: currentOrder.id? currentLocation.id : '',
    },
    item_name: '',
    notes: currentOrder.notes,
    pallets: currentOrder.pallets,
    // length: currentOrder.length,
    // width: currentOrder.width,
    // height: currentOrder.height,
    surface_area: currentOrder.surface_area,
    instructions_from_account_1: currentOrder.instructions_from_account_1,
    instructions_from_account_2: currentOrder.instructions_from_account_2,
    instructions_from_account_3: currentOrder.instructions_from_account_3,
    suppres_schedule_dates: suppres_schedule_dates,
    has_expedite: hasExpedite,
    operation_code: 'SCA',
    skip_warn_hawb_uniqness: skip_warn_hawb_uniqness,
    driver_id: currentOrder.driver_id,
    lh_billing : currentOrder.lh_billing,
    fleet_id: currentOrder.fleet_id,
    trailer_number: currentOrder.trailer_number,
    source_wh: currentOrder.source_wh,
    locations: locations.map((loc, index) => {
      const reqLOSObject = getValueFromArrayOfObjects(levelOfServices, "code" , loc.los_code);
      return (
        {
      ...loc.details,
      is_recovery: loc?.details?.is_recovery === true,
      recovery_status: loc?.details?.recovery_status ? loc.details.recovery_status : '',
      is_release: loc?.details?.is_release === true,
      release_status: loc?.release_status ? loc.release_status : '',
      customer_address_line_1: loc.l_address.address_line1,
      customer_address_line_2: loc.l_address.address_line2,
      customer_city: loc.l_address.city,
      customer_state: loc.l_address.state,
      customer_country: loc.l_address.country,
      customer_zipcode: loc.l_address.zipcode,
      appointments: loc.appointments,
      type_of_loc: loc.type_of_loc,
      l_type: loc.details?.l_type ? loc.details.l_type : 'CS',
      service_duration: loc?.service_duration ? loc.service_duration : 0,
      los_code: loc?.los_code ? loc.los_code.toUpperCase(): (
        !isEmpty(currentOrder.los) ? currentOrder.los.toUpperCase() : ''
      ),
      los_name: !isEmpty(reqLOSObject) ? reqLOSObject.name : '',
      // los_id: !isEmpty(reqLOSObject) ? reqLOSObject.id : '', // contract change
      loc_order_sequence: index,
        }
      );
    }),
    destination_wh: currentOrder.destination_wh,
  }
  if(currentOrder.id){
    payload.id = currentOrder.id;
  }
  return payload
}

export const validateOrder = (details, index, currentOrder, requiredFields, orderRequiredFields) => {
  const errors = []
  let validationErrors = [];
  currentOrder.customer_phone_one = formatePhoneNo(
    currentOrder.customer_phone_one,
  )
  currentOrder.customer_phone_two = formatePhoneNo(
    currentOrder.customer_phone_two,
  )
  const appointments = []
  const accessorials = []
  const preferences = currentOrder.preferences || []
  const accountAccessorials = currentOrder.accountAccessorials || []
  const orderAccessorials = currentOrder.orderAccessorials || []
  let i = 0
  if (preferences.length > 0) {
    preferences.forEach((preference) => {
      if (
        preference.item_preference &&
        preference.item_option.length > 0 &&
        (!preference.item_option.includes('CUSTOM') ||
          (preference.item_option.includes('CUSTOM') &&
            !isEmpty(preference.start_time) &&
            !isEmpty(preference.end_time)))
      ) {
        i += 1
        const slots = preference.item_option ? preference.item_option : []
        appointments.push(
          Object.assign(
            {},
            {
              // s_no: i,
              appt_date: moment(preference.item_preference).format(
                'YYYY-MM-DD 00:00:00',
              ),
              slots,
              confirmed: preference.confirmed,
              has_expedite: preference.has_expedite === true,
              start_time:
                slots.includes('CUSTOM') && preference.start_time
                  ? moment(preference.start_time).format('HH:mm')
                  : '',
              end_time:
                slots.includes('CUSTOM') && preference.end_time
                  ? moment(preference.end_time).format('HH:mm')
                  : '',
            },
          ),
        )
      } else if (
        preference.item_option.includes('CUSTOM') &&
        (isEmpty(preference.start_time) || isEmpty(preference.end_time))
      ) {
        if (!preference.start_time) {
          // ${preference.s_no}
          errors.push(
            `In ${details.label} details, ${I18n.t(
              'messages.appointment_form.validation',
            )}  From time`,
          )
        } else {
          errors.push(
            `In ${details.label} details, ${I18n.t(
              'messages.appointment_form.validation',
            )} To Time`,
          )
        }
      } else if (
        preference.item_preference ||
        preference.item_option.length > 0 ||
        preference.confirmed
      ) {
        if (!preference.item_preference) {
          errors.push(
            `In ${details.label} details, ${I18n.t(
              'messages.appointment_form.validation',
            )} date`,
          )
        } else {
          errors.push(
            `In ${details.label} details, ${I18n.t(
              'messages.appointment_form.validation',
            )} time slot`,
          )
        }
      }
    })

    if (appointments.length > 0) {
      /* const confirmedAppointments = appointments.filter(appointment => appointment.confirmed === true);
            if (confirmedAppointments.length === 0) {
              errors.push(`${I18n.t('messages.appointment_form.confirm_appt_message')}`);
            } */
    }
  }
  orderAccessorials.forEach((accessorial) => {
    const accIndex = _.findIndex(accountAccessorials, [
      'accessorial_key',
      accessorial,
    ])
    if (accIndex >= 0) {
      // if (
      //   accountAccessorials[accIndex].accessorial_type !== "los" &&
      //   accountAccessorials[accIndex].input_type &&
      //   isEmpty(accountAccessorials[accIndex].inputValue)
      // ) {
      //   errors.push(
      //     `In ${
      //       details.label
      //     } details, Value is required for the ${I18n.t(
      //       "order.accessorial"
      //     )} ${accountAccessorials[accIndex].name}`
      //   );
      // }
      accessorials.push({
        item_id: '',
        accessorial_key: accessorial,
        code: accountAccessorials[accIndex].code,
        accessorial_value: accountAccessorials[accIndex].inputValue || '',
        accessorial_type: accountAccessorials[accIndex].accessorial_type || '',
      })
    }
  })

  if (isEmpty(currentOrder.type_of_order)) {
    currentOrder.type_of_order = details.type_of_order
  }

  let allRequiredFields = [...requiredFields]
  // if (
  //   this.state.config[
  //     I18n.t("configurations.move_to_exception_on_invalid_number")
  //   ] === "true"
  // ) {
  //   allRequiredFields = [].concat(allRequiredFields, mobileFields);
  // }

  const customerErrors = doValidate(
    allRequiredFields,
    currentOrder,
    `In ${details.label} details, `,
  )
  let orderErrors = []
  if (index === 0) {
    orderErrors = doValidate(orderRequiredFields, currentOrder)
  }
  const csLocation =
    currentOrder.cs_location && currentOrder.cs_location.l_address
      ? currentOrder.cs_location.l_address
      : {}
  const addressErrors = validateAddressForm(csLocation)
  validationErrors = [].concat(
    validationErrors,
    orderErrors,
    errors,
    customerErrors,
    !isEmpty(addressErrors)
      ? [`${addressErrors} in ${details.label} Address`]
      : [],
  )

  // if (index === 0) {
  //   if (!isEmpty(currentOrder.reference_1_type)) {
  //     if (isEmpty(currentOrder.reference_1)) {
  //       validationErrors.push(
  //         `${I18n.t("order.reference_value")} 1 is required`
  //       );
  //     }
  //   }
  //   if (!isEmpty(currentOrder.reference_2_type)) {
  //     if (isEmpty(currentOrder.reference_2)) {
  //       validationErrors.push(
  //         `${I18n.t("order.reference_value")} 2 is required`
  //       );
  //     }
  //   }
  // }

  return validationErrors
}


// renderOrderPics = (orderType, locationIndex) => {
//     const { formData } = this.state
//     const currentOrder = _.cloneDeep(formData[orderType])
//     const signatureImgs = []
//     const printPics = []
//     const pictures = []
//     let orderPictures = []
//     let currentLocation = {};
//     let dataObject = currentOrder
//     if(currentOrder.is_location_based) {
//       currentLocation = currentOrder.locations && currentOrder.locations.length >= locationIndex ? currentOrder.locations[locationIndex] : {};
//       dataObject = currentLocation
//     }
//     orderPictures = dataObject.pictures || []
//     let signatureObject = {}
//     if (orderPictures.length > 0) {
//       orderPictures.forEach((pic) => {
//         if (pic.picture && pic.picture.url) {
//           const formattedImage = pic.picture.url
//           if (pic.image_type === 'normal') {
//             pictures.push(formattedImage)
//           } else if (pic.image_type === 'signature') {
//             signatureImgs.push(formattedImage)
//             signatureObject = pic
//           } else if (pic.image_type === 'print') {
//             printPics.push(formattedImage)
//           }
//         }
//       })
//     }
//     const signature = signatureImgs.length > 0 ? signatureImgs[0] : ''
//     const { isMilitaryTime } = this.state
//     return (
//       <Card
//         size="small"
//         title={I18n.t('general.pod')}
//         extra={
//           checkServiceExistance('NRASOP') ? (
//             <Tooltip title={I18n.t('order.upload_images')}>
//               <img
//                 src={photoIcon}
//                 onClick={() => this.imageAction(orderType, currentLocation)}
//                 style={{ width: 20, height: 20 }}
//                 alt="upload"
//                 className="marginRight5"
//               />
//             </Tooltip>
//           ) : (
//             <Fragment />
//           )
//         }
//       >
//         <Legs
//           pictures={pictures}
//           signature={signature}
//           pagination={{ position: 'none' }}
//           size="small"
//           showType="seperate"
//           imageAction={() => this.imageAction(orderType)}
//           currentLocation={currentLocation}
//         />
//         {!isEmpty(signatureObject) &&
//           _.isObject(signatureObject) &&
//           signatureObject.sign_by && (
//             <Fragment>
//               <Row>
//                 <Col xs={24}>
//                   <h4 className="alignLeft">
//                     {I18n.t('order.sign_by')}{' '}
//                     <span className="textCaptilize">
//                       {signatureObject.sign_by}
//                     </span>{' '}
//                     {!isEmpty(signatureObject.title_by_relation) && (
//                       <span className="textCaptilize">
//                         ( {signatureObject.title_by_relation} )
//                       </span>
//                     )}
//                     {signatureObject.captured_at
//                       ? ` at ${formatByTimeConfig(
//                           moment(signatureObject.captured_at),
//                           isMilitaryTime,
//                           'Do MMM YYYY, HH:mm',
//                           'Do MMM YYYY, hh:mm A',
//                         )} ${
//                           currentOrder.order_tz_short_name
//                             ? ` ${currentOrder.order_tz_short_name}`
//                             : ''
//                         }`
//                       : ''}
//                   </h4>
//                 </Col>
//               </Row>
//             </Fragment>
//           )}
//       </Card>
//     )
//   }
