import React, { useState } from "react";
import { RearrangableTable } from "../../common/RearrangableTable";
import { formatByTimeConfig } from "../../../helpers/common";
import moment from "moment";
import I18n from "../../../common/I18n";

export const ReleasesList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      mawb: 1234,
      hawb: 3456,
      company_name: "TEST COMPANY",
      scheduled_release_time: "2023-04-01T12:00:00Z",
      status: "PENDING",
      actual_release_time: "2023-04-01T14:00:00Z",
      released_by: "ADMIN",
      release_type: "Demo DocK",
      reference_1: "700214577",
      reference_2: "123456",
      account_name :"Test ",
      account_code: "test",
      exception_message: "This is a test exception message",
      routing : "ABCD"
    },
    {
       key: 1,
      customer_order_number: 1001,
      type_of_order: "Delivery",
      mawb: 3456,
      hawb: 7891,
      company_name: "TEST COMPANY",
      scheduled_release_time: "2023-04-01T13:00:00Z",
      status: "COMPLETED",
      actual_release_time: "2023-04-01T15:00:00Z",
      released_by: "ADMIN",
      release_type: "Demo DocK",
      reference_1: "700214577",
      reference_2: "123456",
      account_name :"Xerox",
      account_code: "xerox",
      exception_message: "This is a test exception message",
      routing : "EFGH"
    },
  ]);

  const [columns, SetColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    
    {
      key: "company_name",
      title: I18n.t("general.company_name"),
      dataIndex: "company_name",
    },
    {
      key: "release_eta",
      title: I18n.t("general.scheduled_time"),
      dataIndex: "scheduled_release_time",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "released_at",
      title: I18n.t("releases.released_at"),
      dataIndex: "actual_release_time",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: "released_by",
      title: I18n.t("releases.released_by"),
      dataIndex: "released_by",
    },
    {
      key: "release_type",
      title: I18n.t("releases.release_type.label"),
      dataIndex: "release_type",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
    },
    {
      key: "EXCEPTION",
      title: I18n.t("general.exception_reason"),
      dataIndex: "exception_message",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "release_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
};
