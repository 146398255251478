import React, { Fragment, useState } from 'react'
import {
  Icon,

  Spin,

  Tooltip,
  Tabs
} from '../../common/UIComponents'
import {
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import userStore from '../../stores/UserStore'
import { _ } from 'core-js'
import BaseModal from '../BaseModal'
import { formatFullName } from '../../helpers/common'
import DriverStandardDeductions from './DriverStandardDesuctions'
import { fetchDriverDeductions } from '../../api/Deductions'
import Deductions from '../users/Deductions'

const { TabPane } = Tabs;

const DriverDeductions = ({
  handleCancel,
  handleSuccess,
  className,
  currentDriver = {},
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deductions, setDeductions] = useState([]);
  const [activeKey, setActiveKey] = useState("deductions");

  const updateDeductions = (deductionsData) => {
    const data = deductionsData ? deductionsData.filter((rec) => !isEmpty(rec.standard_code)) : []
    setDeductions(data)
  }


  const getDeductions = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    fetchDriverDeductions(orgId, currentDriver.id).then((result) => {
      if (result.success) {
        const allDeductions = result.deductions || [];
        setDeductions(
          allDeductions.filter((rec) => !isEmpty(rec.standard_code))
        );
      } else {
        setDeductions([]);
      }
    });
  };

  const resetValues = (closeModal = false) => {
    setLoading(false);
    setDeductions([]);
    setShowInfoWindow(false);
  };

  const setInitialData = () => {
    setShowInfoWindow(true);
    getDeductions();
  };

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={
          <Fragment>
            {I18n.t("deductions.label")}s of {formatFullName(currentDriver)}
            &nbsp;({currentDriver.employee_code})
          </Fragment>
        }
        className="customer-orders-modal"
        onCancel={() => {
          resetValues();
        }}
        width={"100%"}
        style={{
          top: 0,
          height: "100%",
        }}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "scroll",
        }}
      >
        <Spin spinning={loading}>
          <Tabs
            activeKey={activeKey}
            onChange={(key) => setActiveKey(key)}
            type="card"
          >
            <TabPane tab={I18n.t("deductions.more_label")} key="deductions">
              <Deductions
                updateDeductions={updateDeductions}
                currentDriver={currentDriver}
              />
            </TabPane>
            <TabPane tab={I18n.t("deductions.claim_label")} key="standard">
              <DriverStandardDeductions
                deductions={deductions}
                currentDriver={currentDriver}
              />
            </TabPane>
          </Tabs>
        </Spin>
      </BaseModal>
    );
  };

  return (
    <span>
      <Tooltip
        title={
          <Fragment>
            {I18n.t("general.Driver")} {I18n.t("deductions.label")}s
          </Fragment>
        }
        placement="top"
      >
        <Icon
          type="minus-circle"
          onClick={setInitialData}
          style={{color:"green"}}
          icon="plus"
          className={className ? className : ""}
        />
      </Tooltip>
      {showInfoWindow && renderModalWindow()}
    </span>
  );
};

export default DriverDeductions;
