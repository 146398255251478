/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Droppable } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Icon,
  Modal,
  Card,
  Tooltip,
  Popover,
  Popconfirm,
  Typography,
} from "../../../common/UIComponents";
import { doFormate, ellipseText, isEmpty } from "../../../common/Common";
import verified from "../../../../assets/images/verified.png";
import verifiedGrey from "../../../../assets/images/verified_grey.png";
import reseqIcon from "../../../../assets/images/verified_o.png";
import FormattedErrors from "../../common/FormattedErrors";
import { formatWeight, formatSurfaceArea } from "../../../helpers/orders";
import lockIcon from "../../../../assets/images/lock_blue.png";
import unlockIcon from "../../../../assets/images/unlocked.png";
import lockIconRed from "../../../../assets/images/lock_red.png";
import lateImag from "../../../../assets/images/time_warning.png";
import overload from "../../../../assets/images/overload.png";
import truck from "../../../../assets/images/truck.png";
import surfaceImage from "../../../../assets/images/surface.png";
import userStore from "../../../stores/UserStore";
import { getLockedUserName } from "../../../helpers/preplan";
import { checkServiceExistance } from "../../../helpers/common";
import { checkErrors } from "../../../helpers/routes";
import { renderDriverZones } from "../../../helpers/preplan";
import trailerIcon from '../../../../assets/images/trailer_1.svg'
import recoveryIcon from '../../../../assets/images/recovery_r.png'
import DispatchRouteLog from "../../Routes/DispatchRouteLog";
import BranchesIcon from "../../common/BranchesIcon";
import I18n from "../../../common/I18n";
import { OrgContext } from "../../../context/OrgContext";

const { confirm } = Modal;
const { Paragraph } = Typography;
const Route = (props) => {
  const {
    currentWarehouse,
    route,
    rowNumber,
    mouseEnter,
    mouseLeave,
    assignDriver,
    deleteClick,
    preplnDriverZones,
    optimizeRoute,
    setCurrentRoute,
    currentRoute,
    selectedInfo,
    handleLockChange,
    doCapacityValidation,
    unsavedServiceDurationChanges,
    renderDurationalert,
    driverZones,
    isMilitaryTime
  } = props;
  const [visible, setVisible] = useState(false);
  const currentUser = userStore.getStateValue("id");
  const drivers = [route.driversInfo.primary.driverId];
  let hasSecondary = false;
  const hasError =
    route.optimizer_readable_error_message &&
    _.isArray(route.optimizer_readable_error_message) &&
    route.optimizer_readable_error_message.length > 0;

  const hasCapacityError = route?.stops
    ? route.stops.filter((stop) => stop.status === "UNALLOCATED").length > 0
    : false;
  const currentDriverId = route?.driversInfo?.primary
    ? route.driversInfo.primary.driverId
    : "";
  const isNotOptimized =
    route.optimized === false &&
    (!route.optimizer_readable_error_message ||
      (_.isArray(route.optimizer_readable_error_message) &&
        route.optimizer_readable_error_message.length === 0));
  // const isNotOptimized = route.optimization_type === 'auto' && route.optimized === false && (!route.optimizer_readable_error_message || (_.isArray(route.optimizer_readable_error_message) && route.optimizer_readable_error_message.length === 0));
  if (
    route.driversInfo.secondary &&
    route.driversInfo.secondary.allotedDriver &&
    route.driversInfo.secondary.allotedDriver.full_name
  ) {
    hasSecondary = true;
    drivers.push(route.driversInfo.secondary.driverId);
  }
  let driverZoneNames = "";
  if (preplnDriverZones) {
    const driverZones = preplnDriverZones
      .filter((driver) => drivers.includes(driver.driver_id))
      .map((driverZone) => driverZone.driver_zone_name);
    if (driverZones.length > 0) {
      driverZoneNames = _.flattenDeep(driverZones);
    }
  }
  const handleVisibleChange = (flag) => {
    setVisible({ visible: flag });
  };

  const handleMenuClick = (e) => {
    if (e.key !== "3") {
      setVisible(false);
    }
  };

  const onDeleteConfirm = (id) => {
    deleteClick(id);
    setVisible(false);
  };

  const onDeleteCancel = () => {
    setVisible(false);
  };

  const takeConfirmation = (id) => {
    onDeleteCancel();
    confirm({
      title: "Do you want to delete it",
      content:
        "When clicked the OK button, this route will be deleted from this preplan",
      onOk: () => {
        onDeleteConfirm(id);
      },
      onCancel: () => {},
    });
  };
  const { currentOrg } = useContext(OrgContext);
  const isTextAirOrg = currentOrg && currentOrg.code === "TA";
  const renderOptimizeIcon = () =>
    route.optimization_type === "manual" ? (
      <img src={reseqIcon} style={{ width: 16 }} alt="manual" />
    ) : !isNotOptimized ? (
      <img src={verified} style={{ width: 16 }} alt="optimized" />
    ) : (
      <img src={verifiedGrey} style={{ width: 16 }} alt="not_optimized" />
    );

  const renderStyle = () => {
    let obj = {
      marginBottom: 10,
      // backgroundColor: route.optimized === true ? '#FFF' : '#dde2e3',
      backgroundColor: selectedInfo.includes(route.route_id)
        ? "#e0e3ee"
        : "#FFF",
    };
    if (!isNotOptimized) {
      obj = {
        ...obj,
        border: selectedInfo.includes(route.route_id)
          ? `2px solid #${route.bgColor}`
          : `1px solid #${route.bgColor}`,
        borderRadius: 7,
      };
    } else {
      obj = {
        ...obj,
        borderWidth: "1px 1px 1px 6px",
        borderColor: "#888889", // '933015'
        borderStyle: "solid",
      };
    }

    return { ...obj };
  };

  const errorIcon = () => {
    const routeErrors = route.optimizer_readable_error_message || [];
    const appointmentErrors = checkErrors(routeErrors, "appointment");
    return (
      <Popover className="fontSize10" content={FormattedErrors(routeErrors)}>
        {appointmentErrors.length === 0 ? (
          <Icon type="warning" className="textRed routeWarningBlk" />
        ) : (
          <img
            src={lateImag}
            style={{ width: 20, marginBottom: 2, marginTop: 3 }}
            className="textRed routeWarningBlk"
          />
        )}
      </Popover>
    );
  };

  {
    /* <img src={lateImag} style={{ width: 15 }} className="textRed routeWarningBlk"/> */
  }

  const capacityErrIcon = () => (
    <Popover
      className="fontSize10"
      content={FormattedErrors([
        I18n.t("route_optimization.orders_capacity_more_than_vehicle_capacity"),
      ])}
    >
      {/* <Icon type="stop" className="textRed floatRight fontSize15" /> */}
      <img
        src={overload}
        className="floatRight"
        style={{ width: 20, marginTop: -3, marginRight: 2 }}
      />
    </Popover>
  );

  const renderLockIcon = (type = "grey") => {
    return (
      <img
        src={type === "red" ? lockIconRed : lockIcon}
        className="textRed routeWarningBlk"
        style={{ width: 15 }}
      />
    );
  };

  const displayLockStatus = () => {
    const haveLockAccess =
      !unsavedServiceDurationChanges && checkServiceExistance("NRLOCK");
    const haveUnLockAccess =
      !unsavedServiceDurationChanges && checkServiceExistance("NRULOCK");
    return (
      <Fragment>
        {!route.locked && route.warehouse_id === currentWarehouse ? (
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.confirm")}
            onConfirm={() => handleLockChange(route.route_id, true)}
            okText="Yes"
            cancelText="No"
            disabled={!haveLockAccess}
          >
            <Tooltip
              title={`Click to ${I18n.t("routes.lock")}`}
              placement="bottom"
            >
              <img src={unlockIcon} style={{ width: 15 }} />
            </Tooltip>
          </Popconfirm>
        ) : route.locked && route.warehouse_id === currentWarehouse ? (
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.confirm")}
            onConfirm={() => handleLockChange(route.route_id, false)}
            okText="Yes"
            cancelText="No"
            disabled={!haveUnLockAccess}
          >
            <Tooltip
              title={`
              ${route.locked_by !== currentUser ? getLockedUserName(route) : ""}
              Click to ${I18n.t("routes.unlock")}
              `}
              placement="bottom"
            >
              {renderLockIcon("grey")}
            </Tooltip>
          </Popconfirm>
        ) : (
          (route.locked ||
            (route.locked === false &&
              route.warehouse_id !== currentWarehouse)) && (
            <Tooltip
              title={
                route.locked && route.warehouse_id === currentWarehouse
                  ? `${I18n.t("messages.locked")}${
                      route.locked_by_user_name &&
                      route.warehouse_id === currentWarehouse
                        ? ` by ${route.locked_by_user_name}`
                        : ""
                    }`
                  : I18n.t("messages.in_another_warehouse", {
                      field: route.warehouse_name
                        ? ` - ${route.warehouse_name}`
                        : "",
                    })
              }
              placement="bottom"
            >
              {renderLockIcon("red")}
            </Tooltip>
          )
        )}
      </Fragment>
    );
  };

  const renderCount = () => (
    <p
      className="badgeDiv cursorPointer"
      style={{
        backgroundColor: `#${route.bgColor}`,
        color: `#${route.fgColor}`,
        fontSize: 10,
      }}
    >
      {route.stops.length > 2 ? route.stops.length - 2 : 0}
    </p>
  );

  const renderIcons = () => (
    <Row>
      {hasError && (
        <Col xs={24} className="center">
          {errorIcon()}
        </Col>
      )}
      <Col xs={24} className={`center ${hasError ? "marginTop5" : ""}`}>
        {renderCount()}
      </Col>
      <Col xs={24} className="center marginTop5">
        {renderOptimizeIcon()}
      </Col>
      {!isEmpty(route.locked) && (
        <Col xs={24} className="center marginTop5">
          {displayLockStatus()}
        </Col>
      )}
      <Col xs={24}  className=" marginTop5" style={{marginLeft:'4px'}}>
        <DispatchRouteLog route={route} keyId={"route_id"} isMilitaryTime={isMilitaryTime}/>
      </Col>
    </Row>
  );

  const renderDistance = () =>
    !isNotOptimized ? (
      <Fragment>
        <div className="marginTop2 fontWeight500">
          <Tooltip title={I18n.t("general.distance")}>
            {/* <Icon type="branches" /> */}
            <BranchesIcon/>
            &nbsp;{route.total_distance}
          </Tooltip>
        </div>
        <div className="marginTop2 fontWeight500">
          <Tooltip title={I18n.t("general.time")}>
            <Icon type="clock-circle" /> {I18n.t("general.time")}:{" "}
            {route.totalExecTime}
          </Tooltip>
        </div>
      </Fragment>
    ) : (
      <span> Not optimized</span>
    );

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? '#e8e8e8' : 'white',
    // padding: grid,
    height: "auto", //isDraggingOver ? 100 : "auto",
    borderStyle: isDraggingOver ? "dashed" : "",
  });

  const renderWeight = () => (
    <Fragment>
      <Icon type="shopping" />
      &nbsp;{I18n.t("order.weight_short_form")}:{" "}
      {formatWeight(route.total_weight)}
    </Fragment>
  );

  const renderTruckWeight = () => (
    <Fragment>
      <Tooltip title={I18n.t("preplan.capacity.vehicle_weight")}>
        <Icon type="car" />
        &nbsp;{I18n.t("preplan.surface_area.vehicle_short_form")} &nbsp;
        {I18n.t("order.weight_short_form")}:{" "}
        {formatWeight(route.vehicle_weight)}
      </Tooltip>
    </Fragment>
  );
  const renderTruckSurfaceArea = () => (
    <Fragment>
      <Tooltip title={I18n.t("preplan.surface_area.vehicle")}>
        <Icon type="border" />
        &nbsp;{I18n.t("preplan.surface_area.vehicle_short_form")} &nbsp;
        {I18n.t("preplan.surface_area.surface_area_short_form")}:{" "}
        {formatSurfaceArea(route.vehicle_volume)}
      </Tooltip>
    </Fragment>
  );

  const renderTruckWeightAndSurfaceArea = () => (
    <div style={{ fontSize: "9.5px" }}>
      <Tooltip
        title={
          <Fragment>
            {I18n.t("preplan.capacity.vehicle_weight")} /
            {I18n.t("preplan.surface_area.vehicle")}
          </Fragment>
        }
      >
        <img src={surfaceImage} style={{ width: 14 }} />
        &nbsp;
        {formatWeight(route.vehicle_weight)} /{" "}
        {formatSurfaceArea(route.vehicle_volume)}
      </Tooltip>
    </div>
  );

  const renderTruckType = () => (
    <div style={{ fontSize: "9.5px" }}>
      <Tooltip title={<Fragment>{I18n.t("truck.vehicle_type")}</Fragment>}>
        <img src={truck} style={{ width: 14 }} />
        &nbsp;
        {doFormate(route.vehicle_type)}
      </Tooltip>
    </div>
  );
   const renderRouteIcon = () => (
    ['line_haul', 'recovery_route'].includes(route.route_type) ?
      <Fragment>
        <img className="marginLeft10" src={route.route_type === 'recovery_route' ? recoveryIcon : trailerIcon }  style={{ width: 17, marginTop: -3 }} />
        {' '}
      </Fragment> :
      <span className="marginLeft10"/>
  );

  return (
    <Fragment>
      <Droppable
        droppableId={`droppableRouteItem${route.route_id}`}
        isDropDisabled={route.route_type === 'line_haul' || route.isLocked === true}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <Card
              id={route.name}
              size="small"
              onClick={(e) => {
                setCurrentRoute(e, route.route_id, "routeCard");
              }}
              onMouseEnter={() => mouseEnter("selectedRoute", route.route_id)}
              onMouseLeave={() => mouseLeave("selectedRoute", "")}
              className={`boxShadow routeBox ${
                route.status === "ALLOCATED"
                  ? "allocated"
                  : route.status === "ASSIGNED"
                  ? "assigned"
                  : ""
              }`}
              bodyStyle={{
                padding: 8,
                paddingLeft: 10,
                // borderLeft: route.optimized === true ? '3px solid green' : 'none',
              }}
              headStyle={{
                fontSize: 12,
              }}
              style={renderStyle()}
            >
              {/* <span style={{ float: "right", marginTop: "-35px" }}>{stop.status}</span> */}

              {isNotOptimized ? (
                <Row>
                  <Col xs={21} className="fontSize10 smallText textBold">
                    <Row>
                      <Col xs={24}>
                        <Icon
                          type="user"
                          className={
                            route.status === "ALLOCATED"
                              ? "allocated"
                              : route.status === "ASSIGNED"
                              ? "assigned"
                              : ""
                          }
                        />
                        {renderRouteIcon()}
                        {
                          ! isTextAirOrg &&
                        <Col xs={ 24 }>
                          {ellipseText(route.displayName, 12)}
                        </Col>
                        }
                        { isTextAirOrg &&
                          <Col xs={ 24 }>
                            { ellipseText(route.formattedName, 15, true) }
                          </Col>
                        }
                      </Col>
                      <Col xs={24}>{renderTruckType()}</Col>
                      {doCapacityValidation && (
                        <Col xs={24}>{renderTruckWeightAndSurfaceArea()}</Col>
                      )}
                      <Col xs={24}>{renderWeight()}</Col>
                      <Col
                        xs={24}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {renderDriverZones(currentDriverId, driverZones)}
                      </Col>
                      <Col xs={24}>---</Col>
                      <Col xs={24}>---</Col>
                    </Row>
                  </Col>
                  <Col xs={3} className="fontSize10 smallText textBold">
                    <div>{renderIcons()}</div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col
                    xs={21}
                    className="fontSize10 smallText textBold"
                    style={{ paddingTop: 2 }}
                  >
                    <Row>
                      <Col xs={24}>
                        <Icon
                          type="user"
                          className={
                            route.status === "ALLOCATED"
                              ? "allocated"
                              : route.status === "ASSIGNED"
                              ? "assigned"
                              : ""
                          }
                        />
                        {renderRouteIcon()}
                        <span>
                          {! isTextAirOrg &&
                          ellipseText(route.displayName, 12)}
                        </span>{" "}
                          { ellipseText(route.formattedName, 12, true) }
                        &nbsp;&nbsp;
                        {hasCapacityError && (
                          <span className="marginLeft10">
                            {capacityErrIcon()}
                          </span>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        className="fontSize10 smallText"
                        style={{ paddingTop: 4 }}
                      >
                        {renderTruckType()}
                        {doCapacityValidation &&
                          renderTruckWeightAndSurfaceArea()}
                        {renderWeight()}
                        <Col
                          xs={24}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {renderDriverZones(currentDriverId, driverZones)}
                        </Col>
                        {renderDistance()}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3}>{renderIcons()}</Col>

                  {false && (
                    <Col xs={2} className="alignRight">
                      {renderOptimizeIcon()}
                    </Col>
                  )}
                </Row>
              )}
            </Card>
          </div>
        )}
      </Droppable>
    </Fragment>
  );
};
export default Route;

Route.propTypes = {
  rowNumber: PropTypes.number.isRequired,
  route: PropTypes.shape().isRequired,
  assignDriver: PropTypes.func.isRequired,
  mouseEnter: PropTypes.func.isRequired,
  mouseLeave: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  optimizeRoute: PropTypes.func.isRequired,
  preplnDriverZones: PropTypes.arrayOf(PropTypes.shape()),
  selectedInfo: PropTypes.arrayOf(PropTypes.string),
  renderDurationalert: PropTypes.func.isRequired,
};
Route.defaultProps = {
  preplnDriverZones: [],
  selectedInfo: [],
  renderDurationalert: () => {},
};
