import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import {
  Col,
  Row,
  Upload,
  FormItem,
  Button,
  Spin,
  Search,
  Tooltip,
  message,
  Icon,
  DatePicker,
  Dropdown,
  Link,
} from "../../../common/UIComponents";
import {
  alertMessage,
  getFileType,
  isEmpty,
  randomNumber,
} from "../../../common/Common";
import { OrgContext } from "../../../context/OrgContext";
import AppConfig from "../../../config/AppConfig";
import { getStatusReport, exportStatusReport } from "../../../api/Reports";
import { withRouter } from "react-router";
import StatusReportList from "./StatusReportList";
import {
  responseTOCsv,
  dummyRequest,
  limitString,
  convertToTags,
  base64ToExcel,
} from "../../../helpers/common";
import moment from "moment";
import StatusReportFilter from "../../CustomerLogs/StatusReportFilter";
import { WarehouseContext } from "../../../context/WarehouseContext";
import I18n from "../../../common/I18n";
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import { fetchOrgAccounts } from "../../../api/Account";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import { UserContext } from "../../../context/UserContext";
import { ScreenKeys } from "../../../containers/constants";
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const defaultFilter = {
  fromDate: moment(),
  toDate: moment(),
  accountCodes: [],
  warehouseIds: [],
  sortBy: "none",
  sortByType: "descend",
};
class StatusReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadLoading: false,
      exportLoading: false,
      ordersData: [],
      base64Data: null,
      fileList: [],
      currentFileInfo: {},
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      filterWindow: false,
      searchLoading: false,
      appliedFiltersTags: [],
      accountsLoading: false,
      accounts: [],
      isSearched: false,
      recordsPerPage: props.recordsPerPage,
      pagination: {},
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    const { warehouseFilter } = this.props;

    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    defaultFilter.warehouseIds = currentWhId || [];
    this.getAccounts();
    this.computeTags(this.state.filterPlaceHolder);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses)) {
      const { warehouseFilter } = this.props;

      const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
        ? warehouseFilter.selectedWarehouses
        : warehouseFilter?.selectedWarehouses
          ? [warehouseFilter.selectedWarehouses]
          : null;

      defaultFilter.warehouseIds = currentWhId || [];

      this.setState(
        (prevState) => ({
          ...prevState,
          filterPlaceHolder: {
            ...prevState.filterPlaceHolder,
            warehouseIds: currentWhId,
          },
        }),
        () => {
          this.getAccounts();
          this.computeTags(this.state.filterPlaceHolder);        }
      );
    }
    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
    if (
      prevState.isSearched !== this.state.isSearched &&
      !this.state.isSearched
    ) {
      this.setState({
        ordersData: [],
        pagination:{},
        filterPlaceHolder: _.cloneDeep(defaultFilter),
      });
    }
  }

  getAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
          accountsLoading: false,
        });
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  computeTags = (filterPlaceHolder) => {
    const { warehouseFilter } = this.props;
    const { warehouses } = warehouseFilter;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const keys = ["fromDate", "toDate", "accountCodes", "warehouseIds"];

    const filteredData = {
      fromDate: {
        label: "Status Report Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouseIds: {
        label: "Warehouse",
        value: limitString(
          warehouses
            .map((wh) => {
              if (filterPlaceHolder.warehouseIds.includes(wh.id)) {
                return wh.name;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("warehouseIds");
        },
        closeable:
          currentWhId?.length &&
          filterPlaceHolder?.warehouseIds?.length === 1 &&
          filterPlaceHolder?.warehouseIds[0] === currentWhId[0]
            ? false
            : true,
      },
      accountCodes: {
        label: "Accounts",
        value: limitString(
          this.state.accounts
            .map((account) => {
              if (filterPlaceHolder.accountCodes.includes(account.code)) {
                return account.code;
              }
            })
            .filter((item) => !isEmpty(item))
            .join(", "),
          5
        ),
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },
    };
    const tagsInfo = convertToTags(filterPlaceHolder, keys, filteredData);
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "warehouseIds":
        filterVariable.warehouseIds = currentWhId;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      // Callback to ensure the state is updated before further actions
      this.handleSearch();
    });
  };

  customRequest = (obj) => {
    const { file, onSuccess, onError, originFileObj } = obj;
    this.setState({
      errors: [],
    });
    // Simulate an API request. Replace this with your actual API call.
    setTimeout(() => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          this.setState({
            base64Data,
          });
          onSuccess();
          message.success(`${file.name} file uploaded successfully`);
        };

        reader.readAsDataURL(file);
      } catch (error) {
        onError(error);
      }
    }, 1000);
  };

  handleStatusExport = (isExport = false, isRecall = false) => {
    this.setState({ searchLoading: true });
    const { currentOrg = {} } = this.props.orgData;
    const payload = {
      from_date: moment(this.state.filterPlaceHolder.fromDate).format(
        "YYYY-MM-DD"
      ),
      to_date: moment(this.state.filterPlaceHolder.toDate).format("YYYY-MM-DD"),
      account_codes: this.state.filterPlaceHolder.accountCodes.join(","),
      warehouse_ids: this.state.filterPlaceHolder.warehouseIds.join(","),
      export_option: isExport ? "xlsx" : "data",
      organization_code: currentOrg.code,
    };
    if (!isExport) {
      payload.page = !isRecall ? 1 : this.tableOptions.pagination.current || 1;
      payload.per_page = this.state.recordsPerPage;
    }

    if (!isRecall) {
      message.loading(
        isExport
          ? "Export to EXCEL in progress.."
          : "Searching Filtered data in progress..",
        0
      );
    }
    exportStatusReport(payload)
      .then((result) => {
        if (result.success) {
          if (isExport) {
            message.destroy();
            base64ToExcel(
              result.file.file,
              result.file.filename,
              "EXCEL"
            );
            this.handleStatusExport(false, false);
          } else {
            message.destroy();
            this.setState(
              {
                ordersData: result.orders || [],
                pagination: result.pagination || {},
                searchLoading: false,
              },
              () => {
                if (this.state?.ordersData?.length === 0) {
                  alertMessage("No Data Found!!", "error", 10);
                }
              }
            );
          }
        } else {
          alertMessage(
            result?.errors.length ? result.errors[0] : "failed to export file",
            "error",
            10
          );
          if (isExport) {
            this.handleStatusExport(false, false);
          }
        }
      })
      .finally(() => {
        // this.setState({ searchLoading: false });
      });
  };

  handleUpload = (isExport = false, isRecall = false) => {
    if (!isExport) {
      this.setState({ searchLoading: true });
    }
    let loadingKey = isExport ? "exportLoading" : "uploadLoading";
    this.setState({ [loadingKey]: true, isSearched: false });
    const { currentOrg = {} } = this.props.orgData;
    const payload = {
      organization_id: currentOrg.id,
      csv_file: this.state.currentFileInfo,
      is_export: isExport,
    };
    if (!isExport) {
      payload.page = !isRecall ? 1 : this.tableOptions.pagination.current || 1;
      payload.per_page = this.state.recordsPerPage;
    }

    if (!isRecall) {
      message.loading(
        isExport
          ? "Export to EXCEL in progress.."
          : "Extracing data in progress..",
        0
      );
    }
    getStatusReport(payload)
      .then((result) => {
        if (result.success) {
          if (isExport) {
            message.destroy();
            alertMessage("File Exported Successfully!!", "success", 10);
            base64ToExcel(result.file, result.fileName,"EXCEL");
          } else {
            message.destroy();
            if (!isRecall) {
              alertMessage("File Extracted Successfully!!", "success", 10);
            }
            this.setState({
              ordersData: result.orders || [],
              pagination: result.pagination || {},
            });
          }
        } else {
          message.destroy();
          alertMessage(result.errors, "error", 10);
          this.setState({ ordersData: [] });
        }
      })
      .finally(() => {
        this.setState({ [loadingKey]: false });
        this.setState({ searchLoading: false });
      });
  };

  handleMoreFileChange = (info) => {
    const { status } = info.file;
    let uploadedFileList = [info.fileList[info.fileList.length - 1]];
    if (status === "removed") {
      this.setState({
        fileList: [],
        base64Data: null,
        currentFileInfo: {},
      });
    } else {
      this.setState({
        fileList: uploadedFileList,
        currentFileInfo: info.file.originFileObj,
      });
    }
  };

  // beforeUpload = (file) => {
  //   this.setState({
  //     fileList: [file], // Set the fileList to an array with a single file
  //   });
  //   return false; // Prevent default upload behavior
  // };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  handleSearch = (filter = {}, isExport = false) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, filter);
    this.setState(
      {
        filterWindow: false,
        filterPlaceHolder: Filter,
        isSearched: true,
        fileList: [],
      },
      () => {
        this.handleStatusExport(isExport);
      }
    );
  };

  clearFilter = () => {
    this.setState(
      {
        filterPlaceHolder: _.cloneDeep(defaultFilter),
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  _renderSearchBar = () => (
    <Dropdown
      disabled={this.state.exportLoading || this.state.uploadLoading}
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 550,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <StatusReportFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            orgData={this.props.orgData}
            warehouseFilter={this.props.warehouseFilter}
            disableButton={this.state.searchLoading}
            accounts={this.state.accounts}
          />
        </div>
      }
    >
      <Button
        className="cursorPointer" 
          shape="round">
          Filter
          <Icon type="caret-down" />
        </Button>
    </Dropdown>
  );

  _renderHeader = () => (
    <Row className="page-header" type="flex" justify="space-between">
      <Col>EDI Status Report</Col>
      <Col>
        {this._renderSearchBar()}
      </Col>
    </Row>
  );

  handlePublicDownloads = (fileName = "Untitled") => {
    window.open(`${fileName}?q=${randomNumber()}`, "_blank");
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage;
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        if (this.state.isSearched) {
          this.handleStatusExport(false, true);
        } else {
          this.handleUpload(false, true);
        }
        updateRecordsPerPage(ScreenKeys.EDI_STATUS_REPORT, value);
      }
    );
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      if (this.state.isSearched) {
        this.handleStatusExport(false, true);
      } else {
        this.handleUpload(false, true);
      }
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          if (this.state.isSearched) {
            this.handleStatusExport(false, true);
          } else {
            this.handleUpload(false, true);
          }
        }
      );
    }
  };

  render() {
    const { exportLoading, fileList } = this.state;
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };
    return (
      <div className="content-outer">
        <div className="content">
          <this._renderHeader />
          <Row type="flex" align="left" >
            <Col>
              <Link
                onClick={() =>
                  this.handlePublicDownloads("SampleEDIStatusFile.csv")
                }
              >
                <span
                  className="anchor_cursor"
                  style={{ fontSize: "15px", fontWeight: 500 }}
                >
                  {I18n.t("order.sample_download_link")}
                </span>
              </Link>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" >
            <Col span={10} >
              <Row type="flex" justify="space-between">
                <Col className="alignRight" style={{ marginTop: 7 }}>
                  <span
                    style={{
                      stylefontSize: "0.85rem",
                      fontWeight: "bold",
                    }}
                  >
                    Upload File :
                  </span>
                </Col>
                <Col span={16} >
                  <div className="uploadFile">
                    <Dragger
                      customRequest={this.customRequest}
                      onChange={this.handleMoreFileChange}
                      accept=".csv"
                      // beforeUpload={this.beforeUpload}
                      fileList={fileList}
                      disabled={
                        this.state.uploadLoading ||
                        exportLoading ||
                        this.state.searchLoading
                      }
                    >
                      <p className="ant-upload-text" style={{ fontSize: 15 }}>
                        <Icon type="upload" className="marginRightt10" />
                        &nbsp;&nbsp; Click or drag files to this area to upload csv files
                      </p>
                    </Dragger>
                  </div>
                </Col>
                <Col >
                  <Button
                    type="primary"
                    onClick={() => this.handleUpload()}
                    disabled={
                      this.state.fileList.length === 0 ||
                      this.state.uploadLoading ||
                      exportLoading
                    }
                    icon={"download"}
                  >
                    Extract
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col align="middle" >
              <Row type="flex" gutter={13}>
                <Col align="middle">
                  {this.state.fileList?.length > 0 && (
                    <Fragment>
                      <Button
                        size="small"
                        className="cursorPointer"
                        disabled={exportLoading || this.state.uploadLoading}
                        type="primary"
                        loading={exportLoading}
                        onClick={exportLoading ? null : () => this.handleUpload(true)}
                        shape="round"
                      >
                        {this.state.exportLoading ? (
                          "Exporting..."
                        ) : (
                          <span>
                            Export EXCEL <Icon type="file-excel" />
                          </span>
                        )}
                      </Button>
                    </Fragment>
                  )}
                </Col>
                <Col align="middle">
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    value={this.state.recordsPerPage}
                    defaultValue={AppConfig.ordersPerPage}
                    isDisabled={this.state.ordersData?.length === 0}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {this.state.isSearched && (
            <Row type="flex" justify="space-between">
              <Col span={24} style={{ marginTop: 15 }}>
                <AppliedFiltersTags
                  tags={this.state.appliedFiltersTags}
                  clearFilter={() => this.clearFilter()}
                />
              </Col>
            </Row>
          )}
          <Spin spinning={this.state.searchLoading} delay={1000}>
            <Row>
              <Col xs={24} className="marginTop5">
                <StatusReportList
                  data={this.state.ordersData}
                  size="small"
                  scroll={{ y: "calc(100vh - 303px)", x: "max-content" }}
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    this.onTableChange(pagination, filter, sorter, currentTable)
                  }
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}

export const StatusReportsComponent = withRouter((props) => {
  const orgData = useContext(OrgContext);
  const warehouseFilter = useContext(WarehouseContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.EDI_STATUS_REPORT;
  const recordsPerPage = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  return (
    <StatusReports
      orgData={orgData}
      warehouseFilter={warehouseFilter}
      recordsPerPage={recordsPerPage}
      userContext={userContext}
    />
  );
});

export default StatusReportsComponent;
