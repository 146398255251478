import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function fetchDeductions(organizationId) {
  const url = ApiUrl(`v2/deductions?organization_id=${organizationId}&operation_code=DMGL`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { deductions: 'deductions' },
  });
}

export function saveDeductions(data, organizationId) {
  data.operation_code = 'DMGC';
  const url = ApiUrl('v2/deductions/save');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { deductions: 'deductions' } }, false);
}

export function deleteDeduction(id = '', organizationId = '') {
  const url = ApiUrl(`v2/deductions/${id}`);
  return Call('delete', url, { organization_id: organizationId, operation_code: 'DMGD'}, ParseGeneralResponse, { responseDataKeys: { deductions: 'deductions' } }, false);
}

export function generateStandardDeductions(data) {
  data.operation_code = 'DDGSD'
  const url = ApiUrl(`v2/deductions/generate_standard_deductions`);
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {deductions : 'deductions'},
  });
}

export function fetchDriverDeductions(organizationId, driver_id) {
  const url = ApiUrl(`v2/deductions/get_driver_deductions?organization_id=${organizationId}&driver_id=${driver_id}&operation_code=DMGL`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { deductions: 'deductions' },
  });
}

export function saveDriverDeductions(data, organizationId) {
  data.operation_code = 'DDMGC';
  const url = ApiUrl('v2/deductions/create_driver_deductions');
  const method = 'post';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { deductions: 'deductions' } }, false);
}

export function deleteDriverDeductions(id, organizationId, driver_id) {
  const url = ApiUrl(`v2/deductions/delete_driver_deduction?driver_deduction_id=${id}&driver_id=${driver_id}&organization_id=${organizationId}&operation_code=DMGD`);
  return Call('get', url, { organization_id: organizationId,  operation_code: 'DMGD'}, ParseGeneralResponse, { responseDataKeys: { deductions: 'deductions' } }, false);
}

export function generateDriverStandardDeductions(data) {
  data.operation_code = 'DDGSD'
  const url = ApiUrl(`v2/deductions/generate_standard_driver_deductions`);
  return Call("post", url, data, ParseGeneralResponse, {
    responseDataKeys: {deductions : 'deductions'},
  });
}
