import React, { Component } from "react";
import { isEmpty, alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import {
  Row,
  Col,
  Button,
  Spin,
  FormItem,
  Select,
} from "../common/UIComponents";
import BaseModal from "../components/BaseModal";
import OrderStatusForm from "../components/OrderStatusForm";
import OrderStatusList from "../components/OrderStatusList";
import {
  fetchOrderStatus,
  deleteOrderStatus,
  saveOrderStatus,
} from "../api/OrderStatus";
import AppConfig from "../config/AppConfig";
import { fetchOrganizations } from "../api/Organisations";
import { getValueFromArrayOfObjects } from "../helpers/array_functions";
import { renderAlertMessage } from "../helpers/common";

class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentData: {},
      isNew: true,
      showFormModal: false,
      errors: [],
      pagination: {},
      inProgress: false,
      organizations: [],
      organization: "",
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: "",
    };
  }

  componentDidMount() {
    this.getOrganizations();
  }

  getOrderStatus = (initial, cb) => {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    const orgID = this.state.organization;
    fetchOrderStatus(page, perPage, orgID).then((result) => {
      if (result.success) {
        this.setState(
          {
            data: result.order_status_codes || [],
            pagination: result.pagination,
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false, data: [], pagination: {} });
      }
    });
  };

  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const organizations = result.organizations.organizations || [];
        const organization =
          organizations.length > 0
            ? getValueFromArrayOfObjects(
                organizations,
                "code",
                I18n.t("account.netmove_code"),
                "_id"
              )
            : "";
        this.setState(
          {
            organizations,
            organization,
            inProgress: false,
          },
          () => {
            this.getOrderStatus();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleAddClick = () => {
    this.setState({
      showFormModal: true,
      isNew: true,
      currentData: {},
    });
  };

  closeFormModal = () => {
    this.setState({ showFormModal: false });
  };

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.data, ["id", id]);
    const Data = Object.assign({}, this.state.data[index]);
    this.setState({
      currentData: Data,
      showFormModal: true,
      isNew: false,
    });
  };

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteOrderStatus(id).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        this.getOrderStatus();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSuccess = (record) => {
    this.setState({ inProgress: true });
    record.organization_id = this.state.organization;
    saveOrderStatus(this.state.isNew, record).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        this.setState(
          {
            errors: [],
            inProgress: false,
            isNew: true,
            showFormModal: false,
          },
          () => {
            this.getOrderStatus();
          }
        );
      } else {
        this.setState({ errors: result.errors, inProgress: false });
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
        this.tableOptions && this.tableOptions.searchText
          ? this.tableOptions.searchText
          : "",
    };
    if (pagination.current !== currentPage) {
      this.getOrderStatus();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "none",
          sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getOrderStatus();
        }
      );
    }
  };

  handleFilterChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      this.tableOptions.pagination.current = 1;
      this.getOrderStatus();
    });
  };

  renderFormModal = () => (
    <BaseModal
      title={
        this.state.isNew
          ? I18n.t("OrderStatus.add")
          : I18n.t("OrderStatus.edit")
      }
      onCancel={() => this.closeFormModal()}
      maskClosable={false}
      width={600}
    >
      <OrderStatusForm
        Data={this.state.currentData.id ? this.state.currentData : {}}
        onSuccess={(record) => this.handleSuccess(record)}
        onCancel={() => this.closeFormModal()}
        errors={this.state.errors}
        isNew={this.state.isNew}
        organization={this.state.organization}
      />
    </BaseModal>
  );

  render() {
    const { pagination } = this.state;
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t("OrderStatus.menu")}</Col>
            <Col xs={12} className="page-actions">
              <Button
                type="primary"
                onClick={() => this.handleAddClick()}
                icon="plus"
              >
                {`${I18n.t("general.add")} ${I18n.t("OrderStatus.menu")}`}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24} md={5} lg={5}>
              <FormItem label={I18n.t("organization.single")} require>
                <Select
                  value={this.state.organization}
                  showSearch
                  onChange={(e) => this.handleFilterChange("organization", e)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {this.state.organizations.map((orgtype) => (
                    <Select.Option key={orgtype.code} value={orgtype._id}>
                      {orgtype.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>

          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col>
                <OrderStatusList
                  data={this.state.data}
                  scroll = {{y:"calc(100vh - 260px)"}}
                  editClick={(id) => this.handleEditClick(id)}
                  deleteClick={(id) => this.handleDelete(id)}
                  pagination={{
                    total: pagination.total_count,
                    current: pagination.current_page,
                    pageSize: pagination.per_page,
                  }}
                  tableChange={(paginationInfo, filters, sorter) =>
                    this.handleTableChange(paginationInfo, filters, sorter)
                  }
                />
              </Col>
            </Row>
          </Spin>
          {this.state.showFormModal && this.renderFormModal()}
        </div>
      </div>
    );
  }
}

export default OrderStatus;
