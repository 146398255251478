/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Button,
  Typography,
  Popconfirm,
  Alert,
  Modal,
  Collapse
} from "../../common/UIComponents";
import {  alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../common/FormErrors";
import { fetchExceptionMessages } from "../../api/PreplanApi";
import ReasonWindow from "../common/ReasonWindow";
import { RecoveriesApis } from "../../api/RecoveriesApi";
import AppConfig from "../../config/AppConfig";
import DateTimeSelector from "../../common/DateTimeSelector";
import { fetchUsers } from "../../api/UsersApi";
import moment from "moment";
import { changeStatusOfOrders } from "../../api/OrdersApi";
import { renderAlertMessage } from "../../helpers/common";

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel }= Collapse;
class ChangeStatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      arrivalTime: moment().subtract(10, "minute"),
      departureTime: moment().add(10, "minute"),
      errors: [],
      statusErrors: [],
      currentStatus: this.props.currentStatus || "",
      exceptionCode: "",
      exceptionMessage: "",
      recovery: this.props.recoveryObject || {},
      preDispatchStatus: ["in_transit", "in_hand"],
      postDispatchStatus: [
        "assigned",
        "completed"
      ],
      reason: "",
      recoveryStatus: this.props.currentStatus || "",
      exceptions: ["NEW"],
      recoveries: this.props.recoveries || [],
      showReason: false,
      completedInfo: [],
      locationInfo: [],
      isMilitaryTime: props.isMilitaryTime,
      drivers:null,
      selectedDriver: _.get(this.props.responseData, "[0].driver_id") ,
      sign_by:'',
      sign_title:'',
      selectedDate: moment(),
      selectedTime: moment(),
      locationInfo: [],
      drivers:null,
      verifyCompleteStatus: false,
      requireSign: true,
    };
  }

  clearForm = () => {
    this.setState({
      currentItem: {},
      isNew: true,
    });
  };

  componentDidMount() {
    this.getExceptionMessages();
    this.getDriversList();
    // this.checkBulkStatus();
  }

  checkBulkStatus = () => {
    if (this.props.hasBulkOrders) {
      const { status } = this.state;
      delete status.Exception;
      this.setState({
        status,
      });
    }
  };

  handleChange = (element, value) => {
    if (element === "currentStatus") {
      if (
        this.state.postDispatchStatus.includes(this.state.recoveryStatus) &&
        this.state.preDispatchStatus.includes(value)
      ) {
        confirm({
          title: I18n.t("messages.recovery_deleted_from_route"),
          content: <span className="">{I18n.t("messages.proceed_confirm")}</span>,
          onOk: () => {
            this.setState({
              [element]: value,
              showReason: true,
            });
          },
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onCancel: () => {},
        });
      } else if (element === "currentStatus" && value === "completed") {
        if (this.props.recoveries.length > 1) {
          return renderAlertMessage(
            I18n.t("messages.cannot_complete_multiple_recoveries")
          );
        } else {
          const requireSign= this.props?.responseData?.length ?  !this.props.responseData[0].nsr : true

          this.setState({ 
            [element]: value, 
            verifyCompleteStatus: true,
            selectedTime: requireSign ? moment() : null,
            requireSign,
          });
        }
      }else {
        this.setState({ [element]: value, showReason: false, reason: "",verifyCompleteStatus: false });
      }
    } else {
      this.setState({ [element]: value,verifyCompleteStatus: false });
    }
  };
  
  getDriversList(initial, cb) {
    this.setState({ inProgress: true });
    fetchUsers("driver").then((result) => {
      if (result.success) {
        this.setState(
          {
            drivers: result.users.map((driver) => ({
              id: driver.id,
              employee_code: driver.employee_code,
            })),
            // selectedDriver: result.users[0].id, //for making first driver to appear in dropdown
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        alertMessage(result.errors[0], "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  handleDriverChange = (e) => {
    this.setState({ selectedDriver: e});
  };

  getExceptionMessages = () => {
    this.setState({ inProgress: true });
    fetchExceptionMessages().then((result) => {
      if (result.success) {
        const exceptions = [
          ...result.exceptions,
          { exception_code: "CUSTOM", exception_message: "Custom Message" },
        ];
        this.setState({
          exceptions,
          inProgress: false,
        });
      } else {
        this.setState({
          inProgress: false,
          exceptions: [
            { exception_code: "CUSTOM", exception_message: "Custom Message" },
          ],
        });
        renderAlertMessage(result.errors)
      }
    });
  };

  handleSave = async () => {
    this.setState({ inProgress: true, errors: [] });
    const {verifyCompleteStatus } = this.state;
    const errors = this.checkErrors();
    if (errors.length > 0) {
      this.setState({ errors, inProgress: false });
      return;
    }
  
    const payload = this.createPayload();
    const requiredApiCall = verifyCompleteStatus ?  changeStatusOfOrders(payload) : RecoveriesApis.changeStatus(payload);
  
    try {
      const result = await requiredApiCall;
      if (result.success) {
        alertMessage(_.get(result, 'orders.message',"Updated Successfully"), 10);
          this.setState(
          {
            inProgress: false,
            errors: [],
            statusErrors: [],
            showReason: false,
            reason: "",
          },
          () => {
            this.props.saveStatus();
          }
        );
      } else {
        this.setState({ inProgress: false });
        this.processErrors(result.errors);
      }
    } catch (error) {
      this.setState({ inProgress: false });
      // handle error
    }
  };
  
  checkErrors = () => {
    const { exceptionCode, exceptionMessage, sign_by, selectedDriver, selectedDate, selectedTime, arrivalTime, departureTime, currentStatus, requireSign } = this.state;
    const errors = [];
    if (exceptionCode === "CUSTOM" && exceptionMessage === "") {
      errors.push("Message is required");
    } else if (currentStatus === "completed") {
      const fields = [ selectedDriver, selectedDate, arrivalTime, departureTime];
      if(requireSign){
        fields.push(sign_by, selectedTime)
      }
      if (fields.some((field) => !field)) {
        errors.push("All * fields are required");
      }
      if (arrivalTime && departureTime && departureTime.isBefore(arrivalTime)) {
        errors.push("Departure time cannot be less than arrival time");
      }
      if (arrivalTime && selectedTime && selectedTime.isBefore(arrivalTime)) {
        errors.push("Captured time cannot be less than arrival time");
      }
      if (departureTime && selectedTime && departureTime.isBefore(selectedTime)) {
        errors.push("Departure time cannot be less than Captured time");
      }
    }
  
    return errors;
  };
  
  createPayload = () => {
    const {
      recoveries,
      currentStatus,
      showReason,
      reason,
      exceptionCode,
      exceptionMessage,
      sign_by,
      sign_title,
      selectedDate,
      selectedTime,
      selectedDriver,
      arrivalTime,
      departureTime,
      isMilitaryTime,
      completedInfo
    } = this.state;

    const {responseData,selectedWarehouse} = this.props;
  
    const commonData = {
      recovery_ids: recoveries,
    };

    const formatDateTime = (date, time, isMilitaryTime) => {
      const timeFormat = isMilitaryTime ? "HH:mm" : "hh:mm A";
      return `${moment(date).format("YYYY-MM-DD")} ${moment(time).format(
        timeFormat
      )}`;
    };
  
    const capturedTime = selectedTime ? formatDateTime(
      selectedDate,
      selectedTime,
      isMilitaryTime
    ) : null;
    const actualStartTime = formatDateTime(
      selectedDate,
      arrivalTime,
      isMilitaryTime
    );
    const actualEndTime = formatDateTime(
      selectedDate,
      departureTime,
      isMilitaryTime
    );

     //maintain all the data in the completedInfo array
      completedInfo.push({
      ...commonData,
      sign_by,
      signer_title: sign_title,
      captured_at: moment(selectedDate).format("YYYY-MM-DD"),
      captured_time: capturedTime,
      driver_id: selectedDriver,
      actual_start_datetime: actualStartTime,
      actual_end_datetime: actualEndTime,
      customer_order_number: _.get(responseData, "[0].customer_order_number"),
      order_id: _.get(responseData, "[0].customer_order_id"),
      account_id :  _.get(responseData, "[0].account_id"),
      type_of_order: _.get(responseData, "[0].type_of_order"),
      type_of_loc: "PICKUP",
      location_id: _.get(responseData, "[0].pickup_location_id"),
    });
  
    const generalPayload = {
      ...commonData,
      deletion_reason: showReason ? reason : "",
      exception_code: exceptionCode,
      exception_message: exceptionCode === "CUSTOM" ? exceptionMessage : "",
      status: typeof currentStatus === 'string' && currentStatus,
      };

    const completeStatusPayload = {
      order_ids: [_.get(responseData, "[0].customer_order_id")],
      status: typeof currentStatus === 'string' ? currentStatus.toUpperCase() : currentStatus,
      complete_for: "PICKUP",
      captured_info: completedInfo,
      warehouse_id:selectedWarehouse
    };

    return currentStatus === "completed" ? completeStatusPayload : generalPayload;
  };
    
  processErrors = (errors) => {
    if (errors?.length > 0) {
      const errorValue = errors[0];
      if (_.isString(errors)) {
        this.setState({
          errors: [...errors],
          statusErrors: [],
        });
      } else if (_.isObject(errorValue)) {
        const statusErrors = [];
        errors.forEach((errObj) => {
          if (_.isObject(errObj) && errObj.recovery_number) {
            statusErrors.push(errObj);
          }
        });
        this.setState({
          errors: [],
          statusErrors,
        });
      } else {
        this.setState({
          errors,
          statusErrors: [],
        });
      }
    }
  };
 
  renderDateTimeField = (
    loc,
    dateLabel,
    timeLabel,
    key,
    showDate,
    showTime,
    dateReq,
    timeReq,
    timeValue
  ) => {
    const { isMilitaryTime } = this.state;
    return (
      <DateTimeSelector
        dateProps={{
          format: "Do MMM YYYY",
          label: dateLabel,
          value: this.state.selectedDate,
          onChange: (date) => {
            this.setState({ selectedDate: date });
          },
          style: { width: "100%" },
          size: "small",
          disabledDate: (current) => {
            return (
              current && current.valueOf() > Date.now() // disabled the future dates
            );
          },
          dateRequire: dateReq,
          allowClear: false,
        }}
        timeProps={{
          allowClear: false,
          format: isMilitaryTime ? "HH:mm" : "hh:mm A",
          label: timeLabel,
          showTime: {
            format: isMilitaryTime ? "HH:mm" : "hh:mm A",
            use12Hours: !isMilitaryTime,
          },
          showSecond: false,
          onChange: (time) => {
            if (timeLabel === "Arrival Time") {
              this.setState({ arrivalTime: time });
            } else if (timeLabel === "Dept. Time") {          
              this.setState({ departureTime: time });            
            } else {
              this.setState({ selectedTime: time });
            }
          },
          style: { width: "100%", height: "100%", marginTop: "5px" },
          value :  timeValue,
          placeholder: "Select Time",
          minuteStep: 1,
          className: "formMaterialTimePicker",
          isMilitaryTime,
          timeRequire: timeReq,
        }}
        hideDate={!showDate}
        hideTime={!showTime}
        gridStyle={{
          row: { type: "flex", gutter: 4 },
          dateCol: {
            span: showDate && showTime ? 12 : showDate ? 24 : showTime ? 0 : 12,
          },
          timeCol: {
            span: showDate && showTime ? 12 : showTime ? 24 : showDate ? 0 : 12,
          },
        }}
        FormItem={FormItem}
      />
    );
  };
  

  renderCapturedInfo = () => {
    return (
      <div className="order_details_form marginTop10">
        <Row gutter={8}>
          <Col span={6}>
            <FormItem label=" Signed By" name="signed_by" require={this.state.requireSign}>
              <Input
                type="text"
                value={this.state.sign_by}
                maxLength={40}
                size="small"
                onChange={(e) => this.setState({ sign_by: e.target.value })}
                required
                style={{ width: "100%" }}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label=" Signed Title" name="signed_title">
              <Input
                type="text"
                value={this.state.sign_title}
                size="small"
                onChange={(e) => this.setState({ sign_title: e.target.value })}
                required
                style={{ width: "100%" }}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            {this.renderDateTimeField(
              "loc",
              "Completed Date",
              "Completed Time",
              "completed_date",
              true,
              false,
              true,
              false
            )}
          </Col>
          <Col span={6}>
            {this.renderDateTimeField(
              "loc",
              "Completed Date",
              "Captured Time",
              "captured_at",
              false,
              true,
              false,
              this.state.requireSign,
              this.state.selectedTime
            )}
          </Col>
          <Col span={6}>
            <FormItem label="Driver" require>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => this.handleDriverChange(e)}
                value={this.state.selectedDriver ? this.state.selectedDriver : ""}
                filterOption={(input, option) => {
                  if (option.props.children) {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                }}
                showSearch
                size="small"
              >
                <Select.Option key={"driver"} value={""}>
                  --select--
                </Select.Option>
                {this.state.drivers?.map((driverDetail) => (
                  <Select.Option
                    key={driverDetail.id}
                    value={driverDetail.id}
                  >
                    {driverDetail.employee_code}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={6}>
            {this.renderDateTimeField(
              "loc",
              "Arrival Date",
              "Arrival Time",
              "actual_start_datetime",
              false,
              true,
              false,
              true,
              this.state.arrivalTime
            )}
          </Col>
          <Col span={6}>
            {this.renderDateTimeField(
              "loc",
              "Dept. Date",
              "Dept. Time",
              "actual_end_datetime",
              false,
              true,
              false,
              true,
              this.state.departureTime
            )}
          </Col>
        </Row>
      </div>
    );
  };  
  
  render() {
    const currentStatusRecord = _.find(AppConfig.recoveryStatuses, { value : this.state.recoveryStatus})
    return (
      <div className="marginTop10">
        <Row gutter={16}>
          <Col xs={24}>
            <Row
              style={{
                padding: "10",
                backgroundColor: "#fff",
              }}
            >
              <Row>
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  <div className="marginBottom15">
                    <Typography.Text strong>
                      {" "}
                      Current Status :{" "}
                      {!isEmpty(currentStatusRecord)
                        ? currentStatusRecord.text
                        : this.state.recoveryStatus}
                    </Typography.Text>
                  </div>
                  <FormItem label="Select Status">
                    <Select
                      style={{ width: "100%" }}
                      className="routes-input modelInputHeight"
                      onChange={(e) => this.handleChange("currentStatus", e)}
                      defaultValue="Change Status"
                      value={this.state.currentStatus}
                      size="small"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {AppConfig.recoveryStatuses.map((statusRec) => (
                        <Select.Option
                          value={statusRec.value}
                          key={statusRec.value}
                          disabled={
                            statusRec.value === "assigned" ||
                            statusRec.value == this.state.recoveryStatus
                          }
                        >
                          {statusRec.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                {this.state.currentStatus == "completed" ? (
                  <Col xs={24}>{this.renderCapturedInfo()}</Col>
                ) : null}
                <Col xs={24} style={{ paddingBottom: 10 }}>
                  {this.state.currentStatus === "exception" && (
                    <FormItem label="Select Exception">
                      <Select
                        style={{ width: "100%" }}
                        className="routes-input"
                        onChange={(e) => this.handleChange("exceptionCode", e)}
                        defaultValue="Change Status"
                        value={this.state.exceptionCode}
                        size="small"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {this.state.exceptions.map((excep) => (
                          <Select.Option
                            value={excep.exception_code}
                            key={excep.exception_code}
                          >
                            {excep.exception_message}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  )}
                  {this.state.exceptionCode === "CUSTOM" && (
                    <div>
                      <TextArea
                        rows={4}
                        placeholder={I18n.t("general.message")}
                        className="sop-form-textArea"
                        value={this.state.exceptionMessage}
                        onChange={(e) =>
                          this.handleChange("exceptionMessage", e.target.value)
                        }
                      />
                    </div>
                  )}
                  {this.state.showReason && (
                    <div>
                      <FormItem label={I18n.t("messages.reason_to_change")}>
                        <ReasonWindow
                          reasonElement="reason"
                          reasonValue={this.state.reason}
                          handleOnChange={this.handleChange}
                          showButtons={false}
                        />
                      </FormItem>
                    </div>
                  )}
                </Col>
              </Row>
              {this.props.hasBulkOrders && (
                <Row>
                  <Col span={24} className="selecetdOrderPanel">
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      className="marginTop10"
                    >
                      <Panel
                        header="Selected Orders"
                        key="1"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <p style={{ maxHeight: 70, overflowY: "auto" }}>
                          {this.props.processErrors
                            .map((order) => order.customer_order_number)
                            .join(", ")}
                        </p>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  offset={1}
                  xs={22}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <Button onClick={this.props.closeModal} icon="close">
                    Cancel
                  </Button>
                  &nbsp; &nbsp;
                 
                    <Button
                    onClick={this.handleSave}
                      type="primary"
                      loading={this.state.inProgress}
                      disabled={
                        !this.state.currentStatus ||
                        (this.state.showReason && this.state.reason.length < 10)
                      }
                      icon="save"
                    >
                      Update
                    </Button>
               
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {FormErrors(this.state.errors)}
            {this.state.statusErrors.length > 0 && (
              <Alert
                message="Error"
                description={
                  <div>
                    {this.state.statusErrors.map((err) =>
                      _.isObject(err) ? (
                        <Row>
                          <Col xs={8} className="textBold">
                            {err.recovery_number}
                          </Col>
                          <Col xs={16}>
                            {_.isArray(err.errors) ? err.errors.join(",") : ""}
                          </Col>
                        </Row>
                      ) : (
                        <Fragment />
                      )
                    )}
                  </div>
                }
                type="error"
                closable={false}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

ChangeStatusForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveStatus: PropTypes.func.isRequired,
  recoveryObject: PropTypes.shape().isRequired,
  currentStatus: PropTypes.string.isRequired,
};

export default ChangeStatusForm;
