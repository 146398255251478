import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Button, Col, Icon, Row, Tag } from 'antd'
import Text from 'antd/lib/typography/Text'
import FilterTag from '../components/orders/FilterTag'
import I18n from '../common/I18n'

const AppliedFiltersTags = (props) => {
  const { tags, clearFilter, isSearching, searchText, searchTextLabel = '', searchBy, resetSearchTags, updateSearchTags, clearSearch, hideClearButton, screenFrom = "" } = props

  const inInvoiceScreen = _.includes(window.location.href, 'invoices');

  useEffect(() => {
    if(updateSearchTags){
      resetSearchTags();
    }
  }, [ updateSearchTags ]);
  
  const renderSearchTags = () => {
    return searchText
      .split(',')
      .filter(Boolean)
      .map((text, index) => (
        <Tag
          color="blue"
          closable = {false}
          className="filterTag"
          style={ {
            fontSize: '0.85rem',
            fontWeight: 'bold',
          } }
        >
          { text }
        </Tag>
      ));
  };

  return (
    <div className="FilteredTagsContainer">
      {/* flex-flow */}
      <Row type="flex" align="top" gutter={8} style={{ flexFlow: 'nowrap' }}>
        <Col className="paddingLeft15" style={{ minWidth: 80 }}>
          <Text
            style={{
              fontSize: '0.85rem',
              fontWeight: 'bold',
            }}
          >
            {isSearching && searchText.length > 0
              ? searchBy === "Order"
                ? ["invoiceStatusReport", "QbReport"].includes(screenFrom)
                  ? !_.isEmpty(searchTextLabel) ? searchTextLabel : `${I18n.t("search.invoices.order_search")}`
                  : !_.isEmpty(searchTextLabel) ? searchTextLabel : `${I18n.t("general.search_by_key")} `
                : !_.isEmpty(searchTextLabel) ? searchTextLabel : `${I18n.t("general.search_numbers")} `
              : isSearching
                ? ""
                : `Filtered by `}
          </Text>
        </Col>
        <Col> 
          :
        </Col>
        <Col >
          {isSearching ? (
            searchText.length ? ( 
              <div className="searchTags">
                { renderSearchTags() }
                <Button size="small" type="default" onClick={ clearFilter } >
                  Clear
                </Button>
              </div>
            ) : null
          ) : (
            <Row type="flex" gutter={4}>
              
                {tags.map((tag) => {
                  const { label, value, closeable, onClose, filterKey } = tag
                  return (
                    <FilterTag
                      key={label}
                      tagName={label}
                      tagValue={value}
                      InternationalizationText={label}
                      filterValue={value}
                      closable={closeable}
                      onClose={() => {
                        onClose(filterKey)
                      }}
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '0.5rem',
                      }}
                      visible={true}
                    >
                      {value}
                    </FilterTag>
                  )
                })}
              
              &nbsp;
              {
                !hideClearButton &&
                <Button size="small" type="default" onClick={clearFilter}>
                  {' '}
                  Reset filters
                </Button>
              }
            </Row>
          )}
        </Col>
      </Row>
    </div>
  )
}

AppliedFiltersTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearFilter: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
}

AppliedFiltersTags.defaultProps = {
  clearFilter: () => {},
  tags: [],
  clearSearch: () => { },
}

// update the component only if updateSearchTags is true 
export default React.memo(AppliedFiltersTags, (prevProps, nextProps) => {
  // only if searching 
  if(prevProps.isSearching && nextProps.isSearching ){
    // if the search text is not empty
    if(nextProps.searchText.length > 0){
        return prevProps.updateSearchTags === nextProps.updateSearchTags  && prevProps.searchBy === nextProps.searchBy
    }
  }
  return prevProps.tags === nextProps.tags && prevProps.searchBy === nextProps.searchBy && prevProps.isSearching === nextProps.isSearching;
}
);

