import React from "react";
import { Icon, Tooltip } from "../../../common/UIComponents";
import BaseList from "../../BaseList";
import PropTypes from "prop-types";
import { Input, Typography, Popconfirm } from "../../../common/UIComponents";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";
import I18n from "../../../common/I18n";
const { Text } = Typography;
class ReferenceTypesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    this.setColumns({ ...props });
  }

  setColumns = (props) => {
    this.columns = [
      {
        title: "Reference Type",
        dataIndex: "order_reference_type",
        key: "reference_type",
        width: "85%",
        render: (data, record) => {
          return this.props.activeReferenceData.id == record.id ? (
            <Input
              autoFocus
              onChange={(e) => this.props.editHandleChange(e.target.value)}
              value={this.props.activeReferenceData.order_reference_type}
              onPressEnter={this.props.handleUpdate}
            />
          ) : (
            <Text>{data}</Text>
          );
        },
      },
    ];
    if (props.showActions === true) {
      this.columns.push({
        title: "",
        key: "actions",
        fixed:"right",
        dataIndex: "id",
        width: "15%",
        render: (id) => {
          return this.props.activeReferenceData.id == id ? (
            <div className="line-actions">
              <Icon
                type="check-circle"
                className="textGreen fontSize15"
                style={{ padding: "0px 3px" }}
                onClick={() => this.props.handleUpdate(id)}
              />

              <Icon
                type="close-circle"
                className="textRed fontSize15"
                onClick={() => this.props.closeClick(id)}
              />
            </div>
          ) : (
            <div className="line-actions">
              <Tooltip title={ I18n.t("general.edit")}>
                <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
              </Tooltip>
              <Tooltip title={ I18n.t("general.delete")}>
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => this.props.deleteClick(id)}
                okText="Yes"
                cancelText="No"
              >
                <span><DeleteIcon/></span>
              </Popconfirm>
              </Tooltip>
            </div>
          );
        },
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default ReferenceTypesList;

ReferenceTypesList.propTypes = {
  activeReferenceId: PropTypes.number,
};

ReferenceTypesList.defaultProps = {
  activeReferenceId: null,
};
