/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  alertMessage,
} from "../../common/Common";
import I18n from "../../common/I18n";
import { checkServiceExistance, toScreenConfigFormat } from "../../helpers/common";
import MyGridComponent from "./ReactDataGrid";
import { checkboxColumn, getDispatchCols,getPrePlanCol , getOrderListCols  } from "../common/columnDefs";
import { setGridConfigSetting } from "../../api/DisplaySettings";
import { getConfiguredColumns, getStoredColumns } from "../orders/helpers";
import { ScreenKeys } from "../../containers/constants";


const UnallocatedOrdersGridList = (props) => {

  const { rawDataSource, accountCodesWithExceededLimit, userContext, tableName } = props;
  const { updateColWidths, currentUser } = userContext || {};
  const [ filteredRowData, setFilteredRowData ] = useState([]);
  // const dispatchCols = getDispatchCols(props);
  // const [ columnDefs, setColumnDefs ] = useState(dispatchCols);
  const tableColMapping = {
    [ScreenKeys.DISPATCH.UNASSIGNED_ORDERS_V2]: getDispatchCols(props),
    [ScreenKeys.ORDER_LISTING_V2]: getOrderListCols(props),
    [ScreenKeys.PREPLAN.UNASSIGNED_ORDERS]: getPrePlanCol(props)
  };

  const allCols = _.get(tableColMapping, tableName, []);
  const [ columnDefs, setColumnDefs ] = useState(allCols);


  useEffect(() => {
    const modifiedData = rawDataSource?.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    });
    setFilteredRowData(modifiedData);
  }, [ rawDataSource, accountCodesWithExceededLimit ]);

  const ProcessCols = () => {
    const columns = [];
    const hasSomeConfiguration = props.displayConfiguration && (props.displayConfiguration.userConfiguration || props.displayConfiguration.orgConfiguration);
    const storedColumns = getStoredColumns(currentUser, tableName);

    if (!hasSomeConfiguration) {
      columns.push(...allCols);
    } else {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      columns.push(...getConfiguredColumns(allCols, storedColumns, userConfiguration, orgConfiguration, tableName));
    }
    // setColumnDefs(addItemsColdef(columns, itemsColdef));
    setColumnDefs(columns);
  };



  useEffect(() => {
    ProcessCols();
  }, [ props.displayConfiguration, props.vhTypes ]);

  const handleSaveColumnWidth = ({ colId, newWidth, colState }) => {
    let newColumnDefs = [];
    colState.forEach((col) => {
      if (col.colId === '0') {
        newColumnDefs.push(checkboxColumn);
      }
      else if (col.colId === 'items') {
        newColumnDefs.push(itemsColdef);
      }
      else {
        const foundCol = _.find(columnDefs, { field: col.colId });
        newColumnDefs.push({ ...foundCol, width: col.width });
      }
    }
    );
    setColumnDefs(newColumnDefs);

    const finalColumnDefs = colState.map((col) => { return { key: col.colId, width: col.width }; });
    updateColWidths(tableName, toScreenConfigFormat(finalColumnDefs));
  };

  const handleSaveRowIdx = useCallback((colState) => {
    let newOrder = _.compact(colState
      .filter((col) => col.isVisible) // filter out columns where isVisible is false
      .map((col) => col.field || col.colId));    
    const { currentUser } = userContext;
    if(!currentUser) return;
    const { _id: userId, currentOrg } = currentUser;
    const data = {
      organization_id: currentOrg._id,
      config_type: tableName,
      config_value: newOrder,
      user_id: userId
    };
    setGridConfigSetting(data).then((result) => {
      if (result.success) {
        props.dispContext("userConfiguration", {
          ...props.displayConfiguration.userConfiguration,
          [tableName]: [...newOrder],
        });
        alertMessage(I18n.t("messages.saved"), "success", 10);
      } else {
        alertMessage(result.errors[0], "error", 10);
      }
    }
    );

  }, [ tableName ]);

  const handleSaveColIdx = useCallback((colState) => {
    let newOrder = _.compact(colState
      .filter((col) => !col.hide) // filter out columns where isVisible is false
      .map((col) => col.field || col.colId));
    const { currentUser } = userContext;
    const { _id: userId, currentOrg } = currentUser;
    const data = {
      organization_id: currentOrg._id,
      config_type: tableName,
      config_value: newOrder,
      user_id: userId
    };
    setGridConfigSetting(data).then((result) => {
      if (result.success) {
        props.dispContext("userConfiguration", {
          ...props.displayConfiguration.userConfiguration,
          [tableName]: [...newOrder],
        });
        alertMessage(I18n.t("messages.saved"), "success", 10);
      } else {
        alertMessage(result.errors[0], "error", 10);
      }
    }
    )
  }
    , [ tableName ]);

  const handleRowChecked = (colId, checked) => {
    const newColumnDefs = columnDefs.map((col) => {
      if (col.field || col.colId === colId) {
        return { ...col, hide: !checked };
      }
      return col;
    });
    setColumnDefs(newColumnDefs);
  };

  return (
    <MyGridComponent
      columnDefs={ columnDefs }
      rowData={ filteredRowData }
      onRowSelection={props.onRowSelection}
      onSortChanged={ (colState) => {
        const sortedCol = colState[ 0 ];
        const { colId, sort } = sortedCol; props.onSort(colId, sort);
      }
      }
      totalCols={ allCols }
      saveColumnWidth={ handleSaveColumnWidth }
      onColumnMoved={ handleSaveColIdx }
      onRowMoved={ handleSaveRowIdx}
      setCurrentGridRef={ props.setCurrentGridRef }
      handleColumnVisibilityChange={props.handleColumnVisibilityChange}
      showRerraangeModal={props.showRerrangeableModal}
      closeModal={ props.closeModal }
      isRowSelectable={ props.isRowSelectable }
      handleRowSelection={ handleRowChecked }
      selectedIds={ props.selectedIds }
      source={props.source}
    />
  );
};


export default UnallocatedOrdersGridList;

UnallocatedOrdersGridList.propTypes = {
  rawDataSource: PropTypes.array,
  accountCodesWithExceededLimit: PropTypes.array,
  userContext: PropTypes.object,
  tableName: PropTypes.string,
  onRowSelection: PropTypes.func,
  onSort: PropTypes.func,
  vhTypes: PropTypes.array,
  displayConfiguration: PropTypes.object,
  accountsList: PropTypes.array,
  zonesList: PropTypes.array,
  handleColumnVisibilityChange: PropTypes.func,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  isRowSelectable: PropTypes.func,
  source : PropTypes.string
};

UnallocatedOrdersGridList.defaultProps = {
  rawDataSource: [],
  accountCodesWithExceededLimit: [],
  userContext: {},
  tableName: '',
  onSort: () => { },
  vhTypes: [],
  displayConfiguration: {},
  accountsList: [],
  zonesList: [],
  handleColumnVisibilityChange: () => { },
  modalOpen: false,
  closeModal: () => { },
  isRowSelectable: () => { },
  source : null
};