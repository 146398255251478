import { Col, Icon, Popconfirm, Row, Select, Tooltip } from "antd";
import React from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";
import Copyable from "../common/Copyable";
import OrderFieldDisplay from "./OrderFieldDisplay";
import CopyableContent from "../common/CopyableContent";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import { checkServiceExistance } from "../../helpers/common";
import { Link } from "../../common/UIComponents";
import { isEmpty } from "../../common/Common";
class SubOrdersIndexList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "#",
        key: "index",
        align: "center",
        render: (text, record, index) => index + 1,
        width: 50,
      },
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        width: 120,
        render: (text, record) => (
          <div>
            <CustomerOrderDetails
              order_no={text}
              order={{
                id: record.id,
                customer_order_number: text,
              }}
              showEdit={false}
            />
          </div>
        ),
      },
      {
        key: "account",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        width: 120,
        className: 'noWrap',
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "los_name",
        width: 120,
        className: 'noWrap',
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        width: 115,
        render: (data, record) => (
          <OrderFieldDisplay
            data={{
              ...record,
              id: record.id,
              customer_order_number: record.customer_order_number,
            }}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        width: 115,
        render: (data, record) => (
          <OrderFieldDisplay
            data={{
              ...record,
              id: record.id,
              customer_order_number: record.customer_order_number,
            }}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
          />
        ),
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        width: 115,
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_1"
            showPopupContainer={true}
          />
        ),
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        width: 115,
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_2"
            showPopupContainer={true}
          />
        ),
      },
      // {
      //   key: "actions",
      //   render: (data) => {
      //     const { handleDelete, handleEdit } = this.props;
      //     const { id } = data;
      //     return (
      //       <div className="pageActions">
      //         <Row gutter={8}>
      //           {/* <Col span={12}>
      //             <Tooltip title={I18n.t("general.edit")}>
      //               <EditIcon handleClick={() => handleEdit(id)} />
      //               &nbsp;
      //             </Tooltip>
      //           </Col> */}
      //           <Col>
      //             <Popconfirm
      //               placement="left"
      //               title={I18n.t("messages.delete_confirm")}
      //               onConfirm={() => handleDelete(id)}
      //               okText="Yes"
      //               cancelText="No"
      //             >
      //               <Tooltip title={I18n.t("general.delete")}>
      //                 <DeleteIcon />{" "}
      //                 &nbsp;&nbsp;&nbsp;
      //               </Tooltip>
      //             </Popconfirm>
      //           </Col>
      //         </Row>
      //       </div>
      //     );
      //   },
      //   align: "center",
      //   width: 100,
      // },
      {
        key: "target_account",
        title: "Target Account",
        dataIndex: "target_account_id",
        width: 115,
        render: (data, record, index) => {
          const isReadOnly = this.props.isReadOnly;

          return (
            <Row>
              <Col>
                {isReadOnly ? (
                  <div>{record.target_account}</div>
                ) : (
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      this.props.handleUpdateOrder(index, record.id, "target_account_id", e)
                    }
                    value={record.target_account_id}
                    size='small'
                    dropdownMatchSelectWidth={false}
                  >
                    <Select.Option key="account" value=""> -- Select -- </Select.Option>
                    {this.props.accounts.map((account) => (
                      <Select.Option
                        key={account.code}
                        value={account.id}
                      >
                        {`${account.name} (${account.code})`}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
          );
        },
      },      
      {
        key: "target_los",
        title: "Target LOS",
        dataIndex: "target_los_id",
        width: 115,
        render: (data, record,index) => {
          const isReadOnly = this.props.isReadOnly;
          const accountLOS = this.props.accountLOS || {}
          return (
            <Row>
              <Col>
              {isReadOnly ? (
                <div>{record.target_los}</div>
              ) : (
                <Select
                showSearch
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) =>
                  this.props.handleUpdateOrder(index, record.id, "target_los_id", e)
                }
                value={record.target_los_id}
                size='small'
                dropdownMatchSelectWidth={false}
              >
                  <Select.Option key="LOS" value=""> -- Select -- </Select.Option>
                  {_.isArray(accountLOS[record.target_account_id]) && accountLOS[record.target_account_id].map((los) => (
                        <Select.Option
                          key={los.code}
                          value={los.id}
                        >
                          {los.name}
                        </Select.Option>
                      ))}    
                  <Select.Option key="none" value="NONE">None</Select.Option>  
              </Select>
              )}
              </Col>

            </Row>
          );
        },
      },
    ];
  }
}
export default SubOrdersIndexList;
