/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import _ from "lodash";
import {
  Divider,
  Col,
  Row,
  Spin,
  Tabs,
  Checkbox,
  Select,
} from "../common/UIComponents";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import FormButtons from "../components/common/FormButtons";
import FormErrors from "../components/common/FormErrors";
import { fetchOrgActions, fetchRoleActions, saveRoleActions } from "../api/ModuleActions";
import { renderAlertMessage } from "../helpers/common";

const { TabPane } = Tabs;
const { Option } = Select;

class ModuleActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterModules: [],
      currentModules: {},
      currentRole: props.currentRole ? props.currentRole : '',
      inProgress: false,
      isNew: false,
      activeKey: "1",
      errors: [],
    };
  }

  componentDidMount() {
    this.getModuleDetails();
    this.getCurrentRoleActions();
  }

  getModuleDetails = () => {
    this.setState({ inProgress: true });
    fetchOrgActions().then((result) => {
      if (result.success) {
        const masterModules = result.modules || [];
        this.setState({
          masterModules,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (
     props.currentRole !== state.currentRole
    ) {
      const data = { currentRole: props.currentRole };
      return data;
    }
    return {};
  } 

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.currentRole, prevProps.currentRole)) {
      this.getCurrentRoleActions();
    }
  }

  getCurrentRoleActions = (cb) => {
    if (!isEmpty(this.state.currentRole)) {
      this.setState({ inProgress: true });
      fetchRoleActions(this.state.currentRole).then((result) => {
        if (result.success) {
          const accesses = result.accesses || [];
          const modules = _.uniq(accesses.map((access) => access.module_code));
          const currentModules = {};
          modules.map((moduleCode) => {
            currentModules[moduleCode] = accesses
              .filter((access) => access.module_code === moduleCode)
              .map((acess) => acess.operation_code);
          });
          this.setState(
            {
              currentModules,
              inProgress: false,
            },
            () => {
              if (cb) {
                cb();
              }
            }
          );
        } else {
          this.setState({ inProgress: false, currentModules: {} });
        }
      });
    }
  };

  handleOnChange = (moduleCode, opCodes) => {
    const currentModules = { ...this.state.currentModules };
    if (!currentModules.moduleCode) {
      currentModules[moduleCode] = [];
    }
    currentModules[moduleCode] = opCodes;
    this.setState({
      currentModules,
    });
  };

  handleSelectAllInModule = (moduleCode, value) => {
    const { masterModules } = this.state;
    const currentModule = _.find(masterModules, { module_code: moduleCode });
    if (!isEmpty(currentModule)) {
      if (value === true) {
        const operations = currentModule.operations || [];
        const opCodes = operations.map((op) => op.operation_code);
        this.handleOnChange(moduleCode, opCodes);
      } else {
        this.handleOnChange(moduleCode, []);
      }
    }
  };

  handleSelectAllModules = (value) => {
    const { masterModules } = this.state;
    if (value === true) {
      const currentModules = {};
      masterModules.forEach((currentModule) => {
        const operations = currentModule.operations || [];
        currentModules[currentModule.module_code] = operations.map(
          (op) => op.operation_code
        );
      });
      this.setState({
        currentModules,
      });
    } else {
      this.setState({
        currentModules: {},
      });
    }
  };

  handleSave = () => {
    const data = {};
    const access = [];
    Object.keys(this.state.currentModules).forEach((key) => {
      const currentModule = this.state.currentModules[key];
      currentModule.forEach((op) => {
        access.push({
          module_code: key,
          operation_code: op,
        });
      });
    });
    data.access = access;
    data.role= !isEmpty(this.state.currentRole) ? this.state.currentRole.toLowerCase() : '';
    this.setState({ inProgress: true });
    saveRoleActions(false, data).then((result) => {
      if (result.success) {
        this.setState({ inProgress: false, errors: [] });
        alertMessage(I18n.t("messages.saved"));
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ errors: result.errors, inProgress: false });
      }
    });
  };

  render() {
    const { masterModules, currentModules } = this.state;
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Row>
            <Col xs={24} className="alignLeft fontSize14">
              <Checkbox
                onChange={(e) => this.handleSelectAllModules(e.target.checked)}
              >
                Select {I18n.t("general.all")}
              </Checkbox>
            </Col>
          </Row>
          <Row className="marginTop10">
            <Col>
              {masterModules.length > 0 ? (
                <Fragment>
                  {masterModules.map((modul, index) => {
                    const isSelectAllInModule =
                      currentModules && currentModules[modul.module_code]
                        ? currentModules[modul.module_code].length ===
                          modul.operations.length
                        : false;
                    return (
                      <Fragment>
                        <Row style={{ zoom: "90%" }}>
                          <Col xs={4} className="textBold">
                            {modul.module_name}
                          </Col>
                          <Col xs={20}>
                            {modul.operations.length > 0 && (
                              <Row>
                                <Col xs={24}>
                                  <Checkbox
                                    onChange={(e) =>
                                      this.handleSelectAllInModule(
                                        modul.module_code,
                                        e.target.checked
                                      )
                                    }
                                    checked={isSelectAllInModule}
                                  >
                                    {I18n.t("general.all")}
                                  </Checkbox>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Checkbox.Group
                                value={
                                  currentModules &&
                                  currentModules[modul.module_code]
                                    ? currentModules[modul.module_code]
                                    : []
                                }
                                onChange={(e) =>
                                  this.handleOnChange(modul.module_code, e)
                                }
                                style={{ width: "100%" }}
                              >
                                {modul.operations.map((op) => (
                                  <Col xs={6}>
                                    <Checkbox value={op.operation_code}>
                                      {op.operation_name}&nbsp;({op.operation_code})
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Checkbox.Group>
                            </Row>
                          </Col>
                        </Row>
                        <Divider />
                      </Fragment>
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment />
              )}
            </Col>
          </Row>
          <Row>{FormErrors(this.state.errors)}</Row>
          <Row>
            <Col xs={24}>
              {FormButtons(
                this.state.inProgress,
                this.handleSave,
                this.props.onCancel
              )}
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
export default ModuleActions;
