import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Popconfirm,
  Icon,
  Spin,
  Divider,
  Tooltip,
  Alert,
  Checkbox,
} from "../common/UIComponents";
import { DriverSelect } from "../components/common/SelectDropdowns/BaseSelect";
import FormButtons from "../components/common/FormButtons";
import {
  fetchDriverPayInfo,
  saveDriverPayInfo,
  deleteDriverPayInfo,
} from "../api/Billing";
import userStore from "../stores/UserStore";
import { alertMessage, isEmpty } from "../common/Common";
import _ from "lodash";
import DeleteIcon from "../components/common/DeleteIcon";
import I18n from "../common/I18n";
import { renderAlertMessage } from "../helpers/common";

class BillingDriverPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      isDisplayAdd: false,
      customer_orders: [],
      driver_info: [],
      currentInvoice: this.props.currentInvoice || {},
      orderDriverPayments: [],
      clearDisabled: false,
      // saveDisabled: false,
      showError: false,
      delivered_driver: false,
      currentInvoiceDriverId: this.props.currentInvoice?.driver_details
        ?.driver_id
        ? this.props.currentInvoice.driver_details.driver_id
        : null,
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    };
  }

  componentDidMount() {
    this.setInitialData();
    // this.checkButtonDisable();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.currentInvoice, prevProps.currentInvoice)) {
      this.setState(
        {
          currentInvoice: this.props.currentInvoice,
          currentInvoiceDriverId: this.props.currentInvoice?.driver_details
            ?.driver_id
            ? this.props.currentInvoice.driver_details.driver_id
            : null,
        },
        () => {
          this.setInitialData();
        }
      );
    }
  }

  // checkButtonDisable = () => {
  //   const orderDriverPayments = [...this.state.orderDriverPayments];
  //   let driverIsEmptyCount = 0;
  //   orderDriverPayments.forEach((rec, index) => {
  //     if (rec.driver_payments.length === 0) {
  //       driverIsEmptyCount++;
  //     }
  //   });
  //   if (driverIsEmptyCount === orderDriverPayments.length) {
  //     this.setState({ saveDisabled: true });
  //   } else {
  //     this.setState({ saveDisabled: false });
  //   }
  // };

  setInitialData = () => {
    const { currentInvoice } = this.state;
    const orderDriverPayments = currentInvoice?.transportation_charges
      ? currentInvoice?.transportation_charges.map((order) => ({
          order_id: order.order_id,
          order_no: order.order_number,
          driver_payments: [
            { driver_id: "", amount: "", delivered_driver: false },
          ],
        }))
      : [];
    this.setState(
      {
        orderDriverPayments,
      },
      () => {
        this.getdriverPayInfo();
      }
    );
  };

  getdriverPayInfo = () => {
    const { currentInvoice } = this.state;
    this.setState({ inProgress: true });

    const customer_order_ids = currentInvoice?.transportation_charges?.length > 0 ? currentInvoice.transportation_charges.map(
      (order) => order.order_id
    ) : [];
    fetchDriverPayInfo(customer_order_ids).then((result) => {
      if (result.success) {
        const order_driver_payments = result.order_driver_payments || [];
        this.setDriverPayments(order_driver_payments);
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  setDriverPayments = (order_driver_payments) => {
    const { orderDriverPayments } = this.state;
    if (order_driver_payments.length > 0) {
      order_driver_payments.forEach((rec, index) => {
        const orderIndex = _.findIndex(orderDriverPayments, {
          order_id: rec.customer_order_id,
        });
        orderDriverPayments[orderIndex].driver_payments = rec.driver_payments;
        // for setting checkbox to be checked
        const consideredDriver =
          rec.driver_payments.findIndex(
            (record, index) =>
              record.driver_id === this.state.currentInvoiceDriverId
          ) >= 0;
        if (consideredDriver > 0) {
          orderDriverPayments[orderIndex].delivered_driver = true;
        }
        //
      });
    } else {
      orderDriverPayments.forEach((rec, index) => {
        orderDriverPayments[index].driver_payments = [
          { driver_id: "", amount: "", delivered_driver: false },
        ];
      });
    }
    this.setState({
      orderDriverPayments,
      inProgress: false,
    });
    // this.checkButtonDisable();
  };

  handleDetailsChange = (orderIndex, index, element, value) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments[orderIndex].driver_payments[index][element] = value;
    this.setState({
      orderDriverPayments,
    });
  };

  handleSave = () => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    let fieldIsEmptyCount = 0;
    orderDriverPayments.forEach((rec, index) => {
      const driverEmpty =
        rec.driver_payments.findIndex(
          (record, index) =>
            (!isEmpty(record.driver_id) && isEmpty(record.amount)) ||
            (isEmpty(record.driver_id) && !isEmpty(record.amount))
        ) >= 0;

      if (driverEmpty) {
        fieldIsEmptyCount++;
      }
    });
    if (fieldIsEmptyCount > 0) {
      this.setState({
        showError: true,
      });
    } else {
      this.setState({ inProgress: true });
      const customerOrders = [];
      this.state.orderDriverPayments.forEach((order) => {
        // const { ["order_no"]: deletedKey, ...rest } = order;
        const { order_no, delivered_driver, ...rest } = order;
        if (rest?.driver_payments.length > 0) {
          const orderDriverPayments = rest.driver_payments.filter(
            (rec) => !isEmpty(rec.driver_id) && !isEmpty(rec.amount)
          );
          if (orderDriverPayments.length > 0) {
            customerOrders.push({
              ...rest,
              driver_payments: [...orderDriverPayments],
            });
          }
        }
      });
      const data = {
        customer_orders: customerOrders,
        customer_order_ids:
          this.state.currentInvoice?.transportation_charges.map(
            (order) => order.order_id
          ),
        account_id: this.state.currentInvoice.account_details.id,
        organization_id: userStore.getStateValue("selectedOrg"),
      };

      saveDriverPayInfo(data).then((result) => {
        if (result.success) {
          alertMessage("Saved Successfully");
          this.setState(
            {
              inProgress: false,
              showError: false,
              // saveDisabled: false,
            },
            () => {
              this.setInitialData();
            }
          );
        } else {
          alertMessage(result.errors, "error", 10);
          this.setState({ inProgress: false });
        }
      });
    }
  };

  handleClear = () => {
    const { orderDriverPayments } = this.state;
    this.setState({ inProgress: true });
    const customer_order_ids = orderDriverPayments.map(
      (order) => order.order_id
    );
    deleteDriverPayInfo(customer_order_ids).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        this.setState({ showError: false });
        this.setDriverPayments([]);
        this.clearCheckboxes();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  clearCheckboxes = () => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments.forEach((record) => {
      record.delivered_driver = false;
    });
    this.setState({ orderDriverPayments });
  };

  handleCheckedChange = (orderIndex, element, value) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments[orderIndex][element] = value;
    this.setState({ orderDriverPayments }, () => {
      orderDriverPayments.forEach((order, index) => {
        if (orderIndex === index) {
          if (order.delivered_driver && order.delivered_driver === true) {
            if (order.driver_payments.length > 0) {
              if (
                isEmpty(order.driver_payments[0].driver_id) &&
                isEmpty(order.driver_payments[0].amount)
              ) {
                order.driver_payments[0].driver_id =
                  this.state.currentInvoiceDriverId;
                order.driver_payments[0].amount = "";
                order.driver_payments[0].delivered_driver = true;
              } else {
                order.driver_payments.unshift({
                  driver_id: this.state.currentInvoiceDriverId,
                  amount: "",
                  delivered_driver: true,
                });
              }
            } else {
              order.driver_payments.push({
                driver_id: this.state.currentInvoiceDriverId,
                amount: "",
                delivered_driver: true,
              });
            }
          } else if (order.delivered_driver === false) {
            order.driver_payments = order.driver_payments.filter(
              (order) => order.driver_id !== this.state.currentInvoiceDriverId
            );
          }
        }
      });

      this.setState({ orderDriverPayments });
    });
  };

  addDriverPayRow = (orderIndex) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    orderDriverPayments[orderIndex].driver_payments.push({
      driver_id: "",
      amount: "",
    });
    this.setState({
      orderDriverPayments,
    });
    // this.checkButtonDisable();
  };

  removeDriverPayRow = (orderIndex, rowIndex) => {
    const orderDriverPayments = [...this.state.orderDriverPayments];
    if (
      orderDriverPayments.length > 0 &&
      orderDriverPayments[orderIndex].driver_payments.length > 0 &&
      orderIndex >= 0
    ) {
      orderDriverPayments[orderIndex].driver_payments.splice(rowIndex, 1);

      this.setState({
        orderDriverPayments,
        showError: false,
      });
    }
    // this.checkButtonDisable();
  };

  render() {
    const { orderDriverPayments, currentInvoice } = this.state;
    const { drivers, isLocked } = this.props;
    const filteredDrivers = drivers.filter(
      (driver) => driver.id !== this.state.currentInvoiceDriverId
    );
    const isDisabled = isLocked; // isLocked || this.state.currentInvoice.status === "APPROVED";
    return (
      <div className="content">
        <Spin spinning={this.state.inProgress} delay={1000}>
          {orderDriverPayments.map((order, orderIndex) => {
            return (
              <Fragment>
                <Row>
                  <Col xs={12} className="alignLeft">
                    <span
                      style={{
                        fontSize: 14,
                        fontStyle: "italic",
                        fontWeight: "bold",
                        textDecoration: "underline",
                        color: "#395484",
                      }}
                    >
                      For Order #{order.order_no}
                    </span>
                  </Col>
                  {/* <Col xs={12} className="alignRight">
                    <Checkbox
                      checked={order.delivered_driver}
                      onChange={(e) =>
                        this.handleCheckedChange(
                          orderIndex,
                          "delivered_driver",
                          e.target.checked
                        )
                      }
                      className="marginBottom10"
                      disabled={isDisabled}
                    >
                      Consider Delivery Driver Rate
                    </Checkbox>
                  </Col> */}
                </Row>
                {orderDriverPayments &&
                orderDriverPayments[orderIndex].driver_payments.length > 0 ? (
                  <Fragment>
                    <Row className="marginTop10" gutter={32}>
                      <Col xs={24}>
                        <Col xs={8}>
                          <span style={{ fontWeight: 700 }}>
                            <sup style={{ color: "red" }}>*</sup>
                            {I18n.t("general.driver")}:
                          </span>
                        </Col>
                        <Col xs={8}>
                          <span style={{ fontWeight: 700 }}>
                            <sup style={{ color: "red" }}>*</sup>
                            {I18n.t("account.billing.order_amount")}:
                          </span>
                        </Col>
                      </Col>
                    </Row>

                    {orderDriverPayments[orderIndex].driver_payments.map(
                      (driverInfoRow, index) => {
                        const isRowModified =
                          (!isEmpty(driverInfoRow.driver_id) &&
                            isEmpty(driverInfoRow.amount)) ||
                          (isEmpty(driverInfoRow.driver_id) &&
                            !isEmpty(driverInfoRow.amount));

                        return (
                          <Row
                            className="marginTop10"
                            key={`orderRow${order.orderIndex}${index}`}
                            gutter={32}
                          >
                            <Col xs={24} style={{ marginBottom: 6 }}>
                              <Col xs={8}>
                                <Select
                                  showSearch
                                  value={driverInfoRow.driver_id || ""}
                                  onChange={(e) =>
                                    this.handleDetailsChange(
                                      orderIndex,
                                      index,
                                      "driver_id",
                                      e
                                    )
                                  }
                                  filterOption={(input, option) => {
                                    const children = option.props.children;
                                    if (children && children.length > 0) {
                                      if (Array.isArray(children)) {
                                        return children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                      } else {
                                        return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                      }
                                    }
                                  }}
                                  style={{ width: "80%", height: "20%" }}
                                  disabled={
                                    driverInfoRow.driver_id ===
                                    this.state.currentInvoiceDriverId
                                  }
                                >
                                  {driverInfoRow.driver_id !==
                                  this.state.currentInvoiceDriverId
                                    ? filteredDrivers.map((driver) => (
                                        <Select.Option
                                          key={driver.employee_code}
                                          value={driver.id}
                                        >
                                          {driver.employee_code}
                                        </Select.Option>
                                      ))
                                    : this.props.drivers.map((driver) => (
                                        <Select.Option
                                          key={driver.employee_code}
                                          value={driver.id}
                                        >
                                          {driver.employee_code}
                                        </Select.Option>
                                      ))}
                                </Select>
                                <Col>
                                  {isRowModified &&
                                    isEmpty(driverInfoRow.driver_id) &&
                                    this.state.showError && (
                                      <span className="textRed font500 marginTop5">
                                        {"Please Select!"}
                                      </span>
                                    )}
                                </Col>
                              </Col>
                              <Col xs={8}>
                                <Input
                                  type="number"
                                  value={driverInfoRow.amount}
                                  onChange={(e) =>
                                    this.handleDetailsChange(
                                      orderIndex,
                                      index,
                                      "amount",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                  precision={this.state.decimalPoints}
                                  defaultValue={0}
                                  style={{ width: "80%", height: "20%" }}
                                />
                                <Col>
                                  {isRowModified &&
                                    isEmpty(driverInfoRow.amount) &&
                                    this.state.showError && (
                                      <span className="textRed font500 marginTop5">
                                        {"Please Select!"}
                                      </span>
                                    )}
                                </Col>
                              </Col>
                              <Col
                                xs={8}
                                className="alignLeft"
                                style={{ height: 23, marginLeft: -60 }}
                              >
                                {
                                  !isDisabled &&
                                  driverInfoRow.driver_id !==
                                    this.state.currentInvoiceDriverId && (
                                    <Popconfirm
                                      placement="topRight"
                                      title={I18n.t("messages.confirm")}
                                      onConfirm={() =>
                                        this.removeDriverPayRow(
                                          orderIndex,
                                          index
                                        )
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      {/* <Icon
                                        type="delete"
                                        className="textRed textBold fontSize18"
                                      /> */}
                                      <Tooltip title={ I18n.t("general.delete") }>
                                      <DeleteIcon className="textBold fontSize18"/>
                                      &nbsp;&nbsp;
                                      </Tooltip>
                                    </Popconfirm>
                                  )
                                }
                                {
                                  !isDisabled &&
                                  orderDriverPayments[orderIndex]
                                    .driver_payments.length -
                                    1 ===
                                    index &&
                                    !isEmpty(driverInfoRow.driver_id) &&
                                    !isEmpty(driverInfoRow.amount) && (
                                      <Icon
                                        type="plus-circle"
                                        onClick={() =>
                                          this.addDriverPayRow(orderIndex)
                                        }
                                        size="small"
                                        className="marginLeft10 fontSize18"
                                      />
                                    )
                                }
                              </Col>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Alert
                      message={
                        <h4>
                          No Pickup Driver Payment Configuration is done for
                          above order.
                          <Button
                            type="link"
                            onClick={() => this.addDriverPayRow(orderIndex)}
                          >
                            Click here to configure
                          </Button>
                        </h4>
                      }
                      type="info"
                      showIcon
                      style={{ paddingBottom: 0, paddingTop: 2 }}
                    />
                  </Fragment>
                )}
                <Row>
                  <Col xs={24}>
                    <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  </Col>
                </Row>
              </Fragment>
            );
          })}
          <Row>
            <Col className="alignCenter">
              <Button
                type="default"
                className="clearButtonStyle"
                onClick={this.handleClear}
                disabled={isDisabled}
                icon='close-circle'
              >
                {I18n.t("general.clear")}
              </Button>
              &nbsp;&nbsp;
              <Button
                type="primary"
                onClick={this.handleSave}
                disabled={isDisabled}
                icon='save'
              >
                {I18n.t("general.save")}
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default BillingDriverPay;
