export const preplanData = [        
    {
        "id": "6601fc648d998c4eaf50e675",
        "customer_order_number": "378904452",
        "account_code": "PIL1005",
        "weight": 189,
        "order_zones": [
            {
                "zone_id": "637bc927822dd606fe03f8b7",
                "zone_name": "HBR"
            }
        ],
        "quantity": 1,
        "customer_city": "SAINT JOE",
        "customer_zipcode": null,
        "cs_location_id": "6601fc648d998c4eaf50e67b",
        "wh_location_id": "62b5ae30822dd6a5523c1fd7",
        "customer_state": "AR",
        "customer_first_name": "BILL CARTER",
        "customer_last_name": "",
        "customer_phone_one": "00000000",
        "customer_phone_two": "MT",
        "customer_email": null,
        "type_of_order": "T",
        "related_order": null,
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": [
            {
                "customer_order_id": "6601fc648d998c4eaf50e675",
                "accessorial_key": null,
                "accessorial_value": null,
                "accessorial_type": null,
                "accessorial_code": "LIFTGATE"
            },
            {
                "customer_order_id": "6601fc648d998c4eaf50e675",
                "accessorial_key": null,
                "accessorial_value": null,
                "accessorial_type": null,
                "accessorial_code": "RESIDENCE"
            }
        ],
        "notes": "APPROVED 03-50-6832",
        "hawb": "378904452",
        "mawb": "",
        "reference_1_type": null,
        "reference_1": null,
        "reference_2_type": null,
        "reference_2": null,
        "levelOfService": "DEFAULT",
        "vehicle_type": "",
        "locations": [
            {
                "_id": "6601fc648d998c4eaf50e67b",
                "account_id": "6305f617822dd60890494d5d",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": "BILL CARTER",
                "contact_group_ids": [],
                "created_at": "2024-03-25T22:36:20.476Z",
                "customer_order_id": "6601fc648d998c4eaf50e675",
                "deleted_at": null,
                "dstOffset": 3600,
                "email": null,
                "end_time": null,
                "first_name": "BILL CARTER",
                "fleet_id": null,
                "integration_company_id": null,
                "is_beyond_area": true,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "66059a54822dd6b0cde263d4",
                    "address_line1": "952 FOWLER SPRING RD",
                    "address_line2": null,
                    "city": "SAINT JOE",
                    "coordinates": [
                        -92.8760461,
                        36.01368009999999
                    ],
                    "country": "US",
                    "created_at": "2024-03-28T16:27:01.039Z",
                    "state": "AR",
                    "updated_at": "2024-03-28T16:27:01.039Z",
                    "zipcode": "72675"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -92.8760461,
                    36.01368009999999
                ],
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": "DEFAULT",
                "los_id": "64f78e7c822dd633dcc9fa4f",
                "los_name": "Default",
                "modifier_id": null,
                "name": "BILL CARTER",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": null,
                "organization_id": "62b59509822dd6839e8ddb63",
                "phone": "00000000",
                "phone_2": "MT",
                "rawOffset": -21600,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "off_wh",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/Chicago",
                "timeZoneName": "Central Daylight Time",
                "type_of_loc": "PICKUP",
                "type_of_order": "PICKUP",
                "tz_short_form": "CDT",
                "updated_at": "2024-03-28T18:12:22.993Z",
                "version": 6,
                "window_end_time": null,
                "window_start_time": null,
                "id": "6601fc648d998c4eaf50e67b"
            },
            {
                "_id": "6602bfc6f65d083e7158216d",
                "account_id": "6305f617822dd60890494d5d",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "contact_group_ids": [],
                "created_at": "2024-03-26T12:29:58.705Z",
                "customer_order_id": "6601fc648d998c4eaf50e675",
                "deleted_at": null,
                "dstOffset": 3600,
                "email": null,
                "end_time": null,
                "first_name": "bill carter",
                "fleet_id": null,
                "integration_company_id": null,
                "is_beyond_area": true,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "66059a55822dd6b0cde263d7",
                    "address_line1": "952 fowler",
                    "address_line2": null,
                    "city": "saint joe",
                    "coordinates": [
                        -92.8054916,
                        36.03029920000001
                    ],
                    "country": "us",
                    "created_at": "2024-03-28T16:27:01.768Z",
                    "state": "ar",
                    "updated_at": "2024-03-28T16:27:01.768Z",
                    "zipcode": null
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -92.8054916,
                    36.03029920000001
                ],
                "loc_order_sequence": 1,
                "location_code": null,
                "location_partial_match": false,
                "los_code": "DEFAULT",
                "los_id": "64f78e7c822dd633dcc9fa4f",
                "los_name": "Default",
                "modifier_id": null,
                "name": "bill carter",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": null,
                "organization_id": "62b59509822dd6839e8ddb63",
                "phone": null,
                "phone_2": null,
                "rawOffset": -21600,
                "recovery_status": "",
                "release_status": "",
                "release_type": "off_wh",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/Chicago",
                "timeZoneName": "Central Daylight Time",
                "type_of_loc": "DELIVERY",
                "type_of_order": "DELIVERY",
                "tz_short_form": "CDT",
                "updated_at": "2024-03-28T18:12:23.148Z",
                "version": 5,
                "window_end_time": null,
                "window_start_time": null,
                "id": "6602bfc6f65d083e7158216d"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2024-06-14 08:00:00",
                "end_datetime": "2024-06-14 20:00:00",
                "slot_name": "ANY",
                "location_id": "6601fc648d998c4eaf50e67b",
                "type_of_loc": "PICKUP"
            },
            {
                "start_datetime": "2024-06-14 08:00:00",
                "end_datetime": "2024-06-14 20:00:00",
                "slot_name": "ANY",
                "location_id": "6602bfc6f65d083e7158216d",
                "type_of_loc": "DELIVERY"
            }
        ],
        "account_name": "PILOT DELIVERS  - LIT",
        "routing": null,
        "pallets": 1,
        "created_at": "2024-03-25T22:36:20.320Z",
        "customer_address": {
            "_id": "66059a54822dd6b0cde263d4",
            "address_line1": "952 FOWLER SPRING RD",
            "address_line2": null,
            "city": "SAINT JOE",
            "coordinates": [
                -92.8760461,
                36.01368009999999
            ],
            "country": "US",
            "created_at": "2024-03-28T16:27:01.039Z",
            "state": "AR",
            "updated_at": "2024-03-28T16:27:01.039Z",
            "zipcode": "72675"
        },
        "order_zone_name": [
            {
                "zone_id": "637bc927822dd606fe03f8b7",
                "zone_name": "HBR"
            }
        ],
        "zoneName": "HBR",
        "startTime": "2024-06-14 08:00:00"
    },
    {
        "id": "6605b77df65d08ecbdd55e47",
        "customer_order_number": "10006994",
        "account_code": "SOS1001",
        "weight": 272,
        "order_zones": [],
        "quantity": 10,
        "customer_city": null,
        "customer_zipcode": null,
        "cs_location_id": "6605b77df65d08ecbdd55e48",
        "wh_location_id": "62b5ae30822dd6a5523c1fd7",
        "customer_state": null,
        "customer_first_name": ".OAKLAWN PARK RACETRACK",
        "customer_last_name": "",
        "customer_phone_one": "352 615 8661",
        "customer_phone_two": "MT",
        "customer_email": null,
        "type_of_order": "T",
        "related_order": null,
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": [
            {
                "customer_order_id": "6605b77df65d08ecbdd55e47",
                "accessorial_key": null,
                "accessorial_value": null,
                "accessorial_type": null,
                "accessorial_code": "OSD"
            },
            {
                "customer_order_id": "6605b77df65d08ecbdd55e47",
                "accessorial_key": null,
                "accessorial_value": null,
                "accessorial_type": null,
                "accessorial_code": "LIFTGATE"
            },
            {
                "customer_order_id": "6605b77df65d08ecbdd55e47",
                "accessorial_key": null,
                "accessorial_value": null,
                "accessorial_type": null,
                "accessorial_code": "IND"
            }
        ],
        "notes": null,
        "hawb": "00409889",
        "mawb": null,
        "reference_1_type": "REF#",
        "reference_1": "1730PM",
        "reference_2_type": "",
        "reference_2": "",
        "levelOfService": "DEFAULT",
        "vehicle_type": "",
        "locations": [
            {
                "_id": "6605b77df65d08ecbdd55e48",
                "account_id": "6304c829822dd6d73ac759b1",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "contact_group_ids": [],
                "created_at": "2024-03-28T18:31:25.427Z",
                "customer_order_id": "6605b77df65d08ecbdd55e47",
                "deleted_at": null,
                "dstOffset": 3600,
                "email": null,
                "end_time": null,
                "first_name": ".OAKLAWN PARK RACETRACK",
                "fleet_id": null,
                "integration_company_id": null,
                "is_beyond_area": false,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "666b9c6466e0d06a7203ed2a",
                    "address_line1": "2705 CENTRAL AVE 136",
                    "address_line2": null,
                    "city": "HOT SPRINGS",
                    "coordinates": [
                        -93.0591517,
                        34.4840552
                    ],
                    "country": "USA",
                    "created_at": "2024-06-14T01:27:01.362Z",
                    "state": "AR",
                    "updated_at": "2024-06-14T01:27:01.362Z",
                    "zipcode": "71901"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -93.0591517,
                    34.4840552
                ],
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": "DEFAULT",
                "los_id": "65131bf8f65d085065737656",
                "los_name": "Default",
                "modifier_id": null,
                "name": ".OAKLAWN PARK RACETRACK",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": null,
                "organization_id": "62b59509822dd6839e8ddb63",
                "phone": "352 615 8661",
                "phone_2": "MT",
                "rawOffset": -21600,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "off_wh",
                "service_duration": 18,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/Chicago",
                "timeZoneName": "Central Daylight Time",
                "type_of_loc": "PICKUP",
                "type_of_order": "PICKUP",
                "tz_short_form": "CDT",
                "updated_at": "2024-06-14T01:27:07.311Z",
                "version": 5,
                "window_end_time": null,
                "window_start_time": null,
                "id": "6605b77df65d08ecbdd55e48"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2024-06-14 08:00:00",
                "end_datetime": "2024-06-14 20:00:00",
                "slot_name": "ANY",
                "location_id": "6605b77df65d08ecbdd55e48",
                "type_of_loc": "PICKUP"
            }
        ],
        "account_name": "SOS GLOBAL EXPRESS (LIT)",
        "routing": null,
        "pallets": 10,
        "created_at": "2024-03-28T18:31:25.954Z",
        "customer_address": {
            "_id": "666b9c6466e0d06a7203ed2a",
            "address_line1": "2705 CENTRAL AVE 136",
            "address_line2": null,
            "city": "HOT SPRINGS",
            "coordinates": [
                -93.0591517,
                34.4840552
            ],
            "country": "USA",
            "created_at": "2024-06-14T01:27:01.362Z",
            "state": "AR",
            "updated_at": "2024-06-14T01:27:01.362Z",
            "zipcode": "71901"
        },
        "order_zone_name": [],
        "zoneName": "",
        "startTime": "2024-06-14 08:00:00"
    },

    {
        "id": "6579d8522c71845e09bdebd4",
        "customer_order_number": "13114191",
        "account_code": "14RI",
        "weight": 726,
        "order_zones": [
            {
                "zone_id": "61b09190f2d39f1829ee3789",
                "zone_name": "Jersey Mon/Wed/Fri"
            }
        ],
        "quantity": 2,
        "customer_city": "HAINESPORT",
        "customer_zipcode": "08036",
        "cs_location_id": "6579d8522c71845e09bdebd5",
        "wh_location_id": "60251a5f822dd6ade7e33018",
        "customer_state": "NJ",
        "customer_first_name": "JOHNSON CONTROLS INC ",
        "customer_last_name": "",
        "customer_phone_one": "+14143054573",
        "customer_phone_two": "+19999999999",
        "customer_email": "",
        "type_of_order": "D",
        "related_order": null,
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": null,
        "notes": "Ctc:JULIE IRBY ***DRIVER INSTALL RICOH MACHINE***.DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL",
        "hawb": null,
        "mawb": null,
        "reference_1_type": "CustRefNbr",
        "reference_1": "1000-MC19",
        "reference_2_type": "customerDispatchNbr",
        "reference_2": "1000-MC19",
        "levelOfService": "DEFAULT",
        "vehicle_type": null,
        "locations": [
            {
                "_id": "6579d8522c71845e09bdebd5",
                "account_id": "60616fa39bdc5b4065483e20",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "created_at": "2023-12-20T19:32:13.216Z",
                "customer_order_id": "6579d8522c71845e09bdebd4",
                "deleted_at": null,
                "dstOffset": 0,
                "email": "",
                "end_time": null,
                "first_name": null,
                "integration_company_id": null,
                "is_beyond_area": null,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "65aa0df04f547c1ec0a833bf",
                    "address_line1": "6 BERRY DR",
                    "address_line2": "",
                    "city": "HAINESPORT",
                    "coordinates": [
                        -74.81143951416016,
                        39.97724914550781
                    ],
                    "country": "USA",
                    "created_at": null,
                    "state": "NJ",
                    "updated_at": null,
                    "zipcode": "08036"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -74.81143951416016,
                    39.97724914550781
                ],
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": null,
                "los_id": null,
                "los_name": null,
                "modifier_id": null,
                "name": "13114191_CS_LOC",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": "ORDER",
                "organization_id": "60250389822dd692182e04e4",
                "phone": "+14143054573",
                "phone_2": "+19999999999",
                "rawOffset": -18000,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "cross_dock",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/New_York",
                "timeZoneName": "Eastern Standard Time",
                "type_of_loc": null,
                "type_of_order": null,
                "tz_short_form": "EST",
                "updated_at": "2023-12-20T19:32:13.216Z",
                "version": null,
                "window_end_time": null,
                "window_start_time": null,
                "id": "6579d8522c71845e09bdebd5"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2023-12-29 08:00:00",
                "end_datetime": "2023-12-29 17:00:00",
                "slot_name": "CUSTOM",
                "location_id": "6579d8522c71845e09bdebd5",
                "type_of_loc": null
            }
        ],
        "account_name": "RICOH AMERICAS",
        "routing": null,
        "pallets": 1,
        "customer_address": {
            "_id": "65aa0df04f547c1ec0a833bf",
            "address_line1": "6 BERRY DR",
            "address_line2": "",
            "city": "HAINESPORT",
            "coordinates": [
                -74.81143951416016,
                39.97724914550781
            ],
            "country": "USA",
            "created_at": null,
            "state": "NJ",
            "updated_at": null,
            "zipcode": "08036"
        },
        "order_zone_name": [
            {
                "zone_id": "61b09190f2d39f1829ee3789",
                "zone_name": "Jersey Mon/Wed/Fri"
            }
        ],
        "zoneName": "Jersey Mon/Wed/Fri",
        "startTime": "2023-12-29 08:00:00"
    },
    {
        "id": "658338f765fa93501d3b5b39",
        "customer_order_number": "13136391",
        "account_code": "14RI",
        "weight": 262,
        "order_zones": [
            {
                "zone_id": "606c5a31822dd677fa3a6db4",
                "zone_name": "Philadelphia and North East (Everyday)"
            },
            {
                "zone_id": "61b091a3f2d39f1829ee378a",
                "zone_name": "Local"
            }
        ],
        "quantity": 1,
        "customer_city": "PHILADELPHIA",
        "customer_zipcode": "19107",
        "cs_location_id": "658338f765fa93501d3b5b3a",
        "wh_location_id": "60251a5f822dd6ade7e33018",
        "customer_state": "PA",
        "customer_first_name": "RICOH SHORT TERM RENTALS ",
        "customer_last_name": "",
        "customer_phone_one": "+19999999999",
        "customer_phone_two": "+19999999999",
        "customer_email": "",
        "type_of_order": "D",
        "related_order": null,
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": null,
        "notes": "Ctc:CARLY WARNER DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL",
        "hawb": null,
        "mawb": null,
        "reference_1_type": "CustRefNbr",
        "reference_1": "1000-MMPP",
        "reference_2_type": "customerDispatchNbr",
        "reference_2": "1000-MMPP",
        "levelOfService": "DEFAULT",
        "vehicle_type": null,
        "locations": [
            {
                "_id": "658338f765fa93501d3b5b3a",
                "account_id": "60616fa39bdc5b4065483e20",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "created_at": "2023-12-20T19:02:28.057Z",
                "customer_order_id": "658338f765fa93501d3b5b39",
                "deleted_at": null,
                "dstOffset": 0,
                "email": "",
                "end_time": null,
                "first_name": null,
                "integration_company_id": null,
                "is_beyond_area": null,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "65aa0df04f547c1ec0a833c0",
                    "address_line1": "1201 MARKET ST",
                    "address_line2": "",
                    "city": "PHILADELPHIA",
                    "coordinates": [
                        -75.16065216064453,
                        39.95265579223633
                    ],
                    "country": "USA",
                    "created_at": null,
                    "state": "PA",
                    "updated_at": null,
                    "zipcode": "19107"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -75.16065216064453,
                    39.95265579223633
                ],
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": null,
                "los_id": null,
                "los_name": null,
                "modifier_id": null,
                "name": "13136391_CS_LOC",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": "ORDER",
                "organization_id": "60250389822dd692182e04e4",
                "phone": "+19999999999",
                "phone_2": "+19999999999",
                "rawOffset": -18000,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "cross_dock",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/New_York",
                "timeZoneName": "Eastern Standard Time",
                "type_of_loc": null,
                "type_of_order": null,
                "tz_short_form": "EST",
                "updated_at": "2023-12-20T19:02:28.057Z",
                "version": null,
                "window_end_time": null,
                "window_start_time": null,
                "id": "658338f765fa93501d3b5b3a"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2023-12-29 08:00:00",
                "end_datetime": "2023-12-29 17:00:00",
                "slot_name": "CUSTOM",
                "location_id": "658338f765fa93501d3b5b3a",
                "type_of_loc": null
            }
        ],
        "account_name": "RICOH AMERICAS",
        "routing": null,
        "pallets": 1,
        "customer_address": {
            "_id": "65aa0df04f547c1ec0a833c0",
            "address_line1": "1201 MARKET ST",
            "address_line2": "",
            "city": "PHILADELPHIA",
            "coordinates": [
                -75.16065216064453,
                39.95265579223633
            ],
            "country": "USA",
            "created_at": null,
            "state": "PA",
            "updated_at": null,
            "zipcode": "19107"
        },
        "order_zone_name": [
            {
                "zone_id": "606c5a31822dd677fa3a6db4",
                "zone_name": "Philadelphia and North East (Everyday)"
            },
            {
                "zone_id": "61b091a3f2d39f1829ee378a",
                "zone_name": "Local"
            }
        ],
        "zoneName": "Philadelphia and North East (Everyday), Local",
        "startTime": "2023-12-29 08:00:00"
    },
    {
        "id": "65689e8a55f3204c110996da",
        "customer_order_number": "13070761",
        "account_code": "14XE",
        "weight": 500,
        "order_zones": [
            {
                "zone_id": "61b09190f2d39f1829ee3789",
                "zone_name": "Jersey Mon/Wed/Fri"
            }
        ],
        "quantity": 1,
        "customer_city": "OAKLYN",
        "customer_zipcode": "08107",
        "cs_location_id": "65689e8a55f3204c110996db",
        "wh_location_id": "60251a5f822dd6ade7e33018",
        "customer_state": "NJ",
        "customer_first_name": "CONDUENT BUSINESS ",
        "customer_last_name": "",
        "customer_phone_one": "+16096316316",
        "customer_phone_two": "+19999999999",
        "customer_email": "",
        "type_of_order": "R",
        "related_order": null,
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": [
            {
                "customer_order_id": "65689e8a55f3204c110996da",
                "accessorial_key": null,
                "accessorial_value": null,
                "accessorial_type": "",
                "accessorial_code": "EXPD"
            }
        ],
        "notes": "Ctc:DJUANA EXPEDITE BETWEEN 0800     AND 1530    ..DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL",
        "hawb": null,
        "mawb": null,
        "reference_1_type": "CustRefNbr",
        "reference_1": "300219375",
        "reference_2_type": "",
        "reference_2": "",
        "levelOfService": "DEFAULT",
        "vehicle_type": null,
        "locations": [
            {
                "_id": "65689e8a55f3204c110996db",
                "account_id": "60616fa39bdc5b4065483e2c",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "created_at": "2023-12-14T16:05:53.704Z",
                "customer_order_id": "65689e8a55f3204c110996da",
                "deleted_at": null,
                "dstOffset": 0,
                "email": "",
                "end_time": null,
                "first_name": null,
                "integration_company_id": null,
                "is_beyond_area": null,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "65aa0df04f547c1ec0a833c1",
                    "address_line1": "216 HADDON AVE",
                    "address_line2": "",
                    "city": "OAKLYN",
                    "coordinates": [
                        -75.07259368896484,
                        39.906707763671875
                    ],
                    "country": "USA",
                    "created_at": null,
                    "state": "NJ",
                    "updated_at": null,
                    "zipcode": "08107"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": null,
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": null,
                "los_id": null,
                "los_name": null,
                "modifier_id": null,
                "name": "13070761_CS_LOC",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": "ORDER",
                "organization_id": "60250389822dd692182e04e4",
                "phone": "+16096316316",
                "phone_2": "+19999999999",
                "rawOffset": -18000,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "cross_dock",
                "service_duration": 20,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/New_York",
                "timeZoneName": "Eastern Standard Time",
                "type_of_loc": null,
                "type_of_order": null,
                "tz_short_form": "EST",
                "updated_at": null,
                "version": null,
                "window_end_time": null,
                "window_start_time": null,
                "id": "65689e8a55f3204c110996db"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2023-12-29 08:00:00",
                "end_datetime": "2023-12-29 15:30:00",
                "slot_name": "CUSTOM",
                "location_id": "65689e8a55f3204c110996db",
                "type_of_loc": null
            }
        ],
        "account_name": "XEROX - AMBLER",
        "routing": null,
        "pallets": 1,
        "customer_address": {
            "_id": "65aa0df04f547c1ec0a833c1",
            "address_line1": "216 HADDON AVE",
            "address_line2": "",
            "city": "OAKLYN",
            "coordinates": [
                -75.07259368896484,
                39.906707763671875
            ],
            "country": "USA",
            "created_at": null,
            "state": "NJ",
            "updated_at": null,
            "zipcode": "08107"
        },
        "order_zone_name": [
            {
                "zone_id": "61b09190f2d39f1829ee3789",
                "zone_name": "Jersey Mon/Wed/Fri"
            }
        ],
        "zoneName": "Jersey Mon/Wed/Fri",
        "startTime": "2023-12-29 08:00:00"
    },
    {
        "id": "658342b965fa93501d3b631d",
        "customer_order_number": "13136761",
        "account_code": "14RI",
        "weight": 241,
        "order_zones": [
            {
                "zone_id": "61b091a3f2d39f1829ee378a",
                "zone_name": "Local"
            }
        ],
        "quantity": 1,
        "customer_city": "RADNOR",
        "customer_zipcode": "19087",
        "cs_location_id": "658342b965fa93501d3b631e",
        "wh_location_id": "60251a5f822dd6ade7e33018",
        "customer_state": "PA",
        "customer_first_name": "UPHS ",
        "customer_last_name": "",
        "customer_phone_one": "+19999999999",
        "customer_phone_two": "+19999999999",
        "customer_email": "",
        "type_of_order": "R",
        "related_order": null,
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": null,
        "notes": "Ctc:N DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL",
        "hawb": null,
        "mawb": null,
        "reference_1_type": "CustRefNbr",
        "reference_1": "1000-MM75",
        "reference_2_type": "customerDispatchNbr",
        "reference_2": "1000-MM75",
        "levelOfService": "DEFAULT",
        "vehicle_type": null,
        "locations": [
            {
                "_id": "658342b965fa93501d3b631e",
                "account_id": "60616fa39bdc5b4065483e20",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "created_at": "2023-12-20T19:47:25.484Z",
                "customer_order_id": "658342b965fa93501d3b631d",
                "deleted_at": null,
                "dstOffset": 0,
                "email": "",
                "end_time": null,
                "first_name": null,
                "integration_company_id": null,
                "is_beyond_area": null,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "654bee5902e630965f481fc8",
                    "address_line1": "145 KING OF PRUSSIA R",
                    "address_line2": "",
                    "city": "RADNOR",
                    "coordinates": [
                        -75.35435485839844,
                        40.039329528808594
                    ],
                    "country": "USA",
                    "created_at": null,
                    "state": "PA",
                    "updated_at": null,
                    "zipcode": "19087"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -75.35435485839844,
                    40.039329528808594
                ],
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": null,
                "los_id": null,
                "los_name": null,
                "modifier_id": null,
                "name": "13136761_CS_LOC",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": "ORDER",
                "organization_id": "60250389822dd692182e04e4",
                "phone": "+19999999999",
                "phone_2": "+19999999999",
                "rawOffset": -18000,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "cross_dock",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/New_York",
                "timeZoneName": "Eastern Standard Time",
                "type_of_loc": null,
                "type_of_order": null,
                "tz_short_form": "EST",
                "updated_at": "2023-12-20T19:47:25.484Z",
                "version": null,
                "window_end_time": null,
                "window_start_time": null,
                "id": "658342b965fa93501d3b631e"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2023-12-29 08:00:00",
                "end_datetime": "2023-12-29 17:00:00",
                "slot_name": "CUSTOM",
                "location_id": "658342b965fa93501d3b631e",
                "type_of_loc": null
            }
        ],
        "account_name": "RICOH AMERICAS",
        "routing": null,
        "pallets": 1,
        "customer_address": {
            "_id": "654bee5902e630965f481fc8",
            "address_line1": "145 KING OF PRUSSIA R",
            "address_line2": "",
            "city": "RADNOR",
            "coordinates": [
                -75.35435485839844,
                40.039329528808594
            ],
            "country": "USA",
            "created_at": null,
            "state": "PA",
            "updated_at": null,
            "zipcode": "19087"
        },
        "order_zone_name": [
            {
                "zone_id": "61b091a3f2d39f1829ee378a",
                "zone_name": "Local"
            }
        ],
        "zoneName": "Local",
        "startTime": "2023-12-29 08:00:00"
    },
    {
        "id": "6583286dcf99e97a81cb68c9",
        "customer_order_number": "13135761D",
        "account_code": "14RI",
        "weight": 1498,
        "order_zones": [
            {
                "zone_id": "61b091a3f2d39f1829ee378a",
                "zone_name": "Local"
            }
        ],
        "quantity": 2,
        "customer_city": "Ambler",
        "customer_zipcode": "19002",
        "cs_location_id": "6583286dcf99e97a81cb68ca",
        "wh_location_id": "60251a5f822dd6ade7e33018",
        "customer_state": "Pennsylvania",
        "customer_first_name": "Pending Update ",
        "customer_last_name": "",
        "customer_phone_one": "+19999999999",
        "customer_phone_two": "+19999999999",
        "customer_email": "",
        "type_of_order": "TD",
        "related_order": "6583286acf99e97a81cb68be",
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": null,
        "notes": "Ctc: DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL",
        "hawb": null,
        "mawb": null,
        "reference_1_type": "CustRefNbr",
        "reference_1": "NA",
        "reference_2_type": "customerDispatchNbr",
        "reference_2": "NA",
        "levelOfService": "DEFAULT",
        "vehicle_type": null,
        "locations": [
            {
                "_id": "6583286dcf99e97a81cb68ca",
                "account_id": "60616fa39bdc5b4065483e20",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "created_at": "2023-12-20T17:48:57.020Z",
                "customer_order_id": "6583286dcf99e97a81cb68c9",
                "deleted_at": null,
                "dstOffset": 0,
                "email": "",
                "end_time": null,
                "first_name": null,
                "integration_company_id": null,
                "is_beyond_area": null,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "65aa0df04f547c1ec0a833c2",
                    "address_line1": "300 Mathers rd",
                    "address_line2": "",
                    "city": "Ambler",
                    "coordinates": [
                        -75.2366943359375,
                        40.16292190551758
                    ],
                    "country": "USA",
                    "created_at": null,
                    "state": "Pennsylvania",
                    "updated_at": null,
                    "zipcode": "19002"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": null,
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": null,
                "los_id": null,
                "los_name": null,
                "modifier_id": null,
                "name": "13135761D_CS_LOC",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": "ORDER",
                "organization_id": "60250389822dd692182e04e4",
                "phone": "+19999999999",
                "phone_2": "+19999999999",
                "rawOffset": -18000,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "cross_dock",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/New_York",
                "timeZoneName": "Eastern Standard Time",
                "type_of_loc": null,
                "type_of_order": null,
                "tz_short_form": "EST",
                "updated_at": null,
                "version": null,
                "window_end_time": null,
                "window_start_time": null,
                "id": "6583286dcf99e97a81cb68ca"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2023-12-29 08:00:00",
                "end_datetime": "2023-12-29 17:00:00",
                "slot_name": "CUSTOM",
                "location_id": "6583286dcf99e97a81cb68ca",
                "type_of_loc": null
            }
        ],
        "account_name": "RICOH AMERICAS",
        "routing": null,
        "pallets": 1,
        "customer_address": {
            "_id": "65aa0df04f547c1ec0a833c2",
            "address_line1": "300 Mathers rd",
            "address_line2": "",
            "city": "Ambler",
            "coordinates": [
                -75.2366943359375,
                40.16292190551758
            ],
            "country": "USA",
            "created_at": null,
            "state": "Pennsylvania",
            "updated_at": null,
            "zipcode": "19002"
        },
        "order_zone_name": [
            {
                "zone_id": "61b091a3f2d39f1829ee378a",
                "zone_name": "Local"
            }
        ],
        "zoneName": "Local",
        "startTime": "2023-12-29 08:00:00"
    },
    {
        "id": "6583286acf99e97a81cb68be",
        "customer_order_number": "13135761P",
        "account_code": "14RI",
        "weight": 1498,
        "order_zones": [
            {
                "zone_id": "61b09190f2d39f1829ee3789",
                "zone_name": "Jersey Mon/Wed/Fri"
            }
        ],
        "quantity": 2,
        "customer_city": "SALEM",
        "customer_zipcode": "08079",
        "cs_location_id": "6583286acf99e97a81cb68bf",
        "wh_location_id": "60251a5f822dd6ade7e33018",
        "customer_state": "NJ",
        "customer_first_name": "STATE OF NEW JERSEY CUMBER ",
        "customer_last_name": "",
        "customer_phone_one": "+18564987793",
        "customer_phone_two": "+18564987793",
        "customer_email": "",
        "type_of_order": "TR",
        "related_order": "6583286dcf99e97a81cb68c9",
        "location_partial_match": false,
        "status": "VERIFIED",
        "exception_message": null,
        "order_accessorials": null,
        "notes": "Ctc:JANET GONZALEZ DRIVER MUST CALL 1 HOUR PRIOR TO ARRIVAL",
        "hawb": null,
        "mawb": null,
        "reference_1_type": "CustRefNbr",
        "reference_1": "1000-JAJN",
        "reference_2_type": "customerDispatchNbr",
        "reference_2": "1000-JAJN",
        "levelOfService": "DEFAULT",
        "vehicle_type": null,
        "locations": [
            {
                "_id": "6583286acf99e97a81cb68bf",
                "account_id": "60616fa39bdc5b4065483e20",
                "actual_end_datetime": null,
                "actual_start_datetime": null,
                "company_name": null,
                "created_at": "2023-12-20T17:48:53.913Z",
                "customer_order_id": "6583286acf99e97a81cb68be",
                "deleted_at": null,
                "dstOffset": 0,
                "email": "",
                "end_time": null,
                "first_name": null,
                "integration_company_id": null,
                "is_beyond_area": null,
                "is_recovery": false,
                "is_release": false,
                "l_address": {
                    "_id": "65aa0df04f547c1ec0a833c3",
                    "address_line1": "113 MARKET ST",
                    "address_line2": "",
                    "city": "SALEM",
                    "coordinates": [
                        -75.4666519165039,
                        39.57212829589844
                    ],
                    "country": "USA",
                    "created_at": null,
                    "state": "NJ",
                    "updated_at": null,
                    "zipcode": "08079"
                },
                "l_type": "CS",
                "last_name": null,
                "lat_long": [
                    -75.4666519165039,
                    39.57212829589844
                ],
                "loc_order_sequence": 0,
                "location_code": null,
                "location_partial_match": false,
                "los_code": null,
                "los_id": null,
                "los_name": null,
                "modifier_id": null,
                "name": "13135761P_CS_LOC",
                "nav_route_id": null,
                "order_capacity": null,
                "order_type": "ORDER",
                "organization_id": "60250389822dd692182e04e4",
                "phone": "+18564987793",
                "phone_2": "+18564987793",
                "rawOffset": -18000,
                "recovery_status": "in_transit",
                "release_status": "pending",
                "release_type": "cross_dock",
                "service_duration": 0,
                "slot_details": null,
                "start_time": null,
                "status": "PENDING",
                "suggested_end_datetime": null,
                "suggested_start_datetime": null,
                "target_wh_location_id": null,
                "timeZoneId": "America/New_York",
                "timeZoneName": "Eastern Standard Time",
                "type_of_loc": null,
                "type_of_order": null,
                "tz_short_form": "EST",
                "updated_at": "2023-12-20T17:48:53.913Z",
                "version": null,
                "window_end_time": null,
                "window_start_time": null,
                "id": "6583286acf99e97a81cb68bf"
            }
        ],
        "appointments": [
            {
                "start_datetime": "2023-12-29 08:00:00",
                "end_datetime": "2023-12-29 17:00:00",
                "slot_name": "CUSTOM",
                "location_id": "6583286acf99e97a81cb68bf",
                "type_of_loc": null
            }
        ],
        "account_name": "RICOH AMERICAS",
        "routing": null,
        "pallets": 1,
        "customer_address": {
            "_id": "65aa0df04f547c1ec0a833c3",
            "address_line1": "113 MARKET ST",
            "address_line2": "",
            "city": "SALEM",
            "coordinates": [
                -75.4666519165039,
                39.57212829589844
            ],
            "country": "USA",
            "created_at": null,
            "state": "NJ",
            "updated_at": null,
            "zipcode": "08079"
        },
        "order_zone_name": [
            {
                "zone_id": "61b09190f2d39f1829ee3789",
                "zone_name": "Jersey Mon/Wed/Fri"
            }
        ],
        "zoneName": "Jersey Mon/Wed/Fri",
        "startTime": "2023-12-29 08:00:00"
    }
]