import React, { Fragment } from 'react'
import moment from 'moment'
import AppConfig from '../../config/AppConfig'
import BaseList from '../BaseList'
import PropTypes from 'prop-types'
import { Popconfirm, Col, Icon, Popover, Row, Tooltip, Select } from 'antd'
import I18n from '../../common/I18n'
import AddressInfo from '../common/AddressInfo'
import { checkServiceExistance, confirmationPopup } from '../../helpers/common'
import { isEmpty } from 'lodash'
import CustomerOrderDetails from '../orders/CustomerOrderDetails'
import DisplayTime from '../common/DisplayTime'
import { formatWeight } from '../../helpers/orders'
import { getRecoveryStatusIcon } from '../../helpers/recovery'
import ShowDispatcherNotes from '../common/ShowDriverNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import { withRouter } from 'react-router';
import ReleaseToDetails from '../releases/ReleaseToDetails'
import OrderFieldDisplay from '../orders/OrderFieldDisplay'
import TypeOfOrder from '../orders/TypeOfOrder'
class ReleasesList extends BaseList {
  constructor(props) {
    super(props)
    this.columns = []
    this.setColumns(props)
  }

  setColumns = (props) => {
    let columns = [
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        render: (text, record) => {
  
          return (
          <OrderFieldDisplay
            data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
            charsToLimit={null}
            // tab="releases"
          />
        )},
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        render: (text, record) => {
          return (
          <OrderFieldDisplay
            data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
            charsToLimit={null}
            // tab="releases"
          />
        )},
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1"  charsToLimit={null}/>
        ),
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2"  charsToLimit={null}/>
        ),
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="top"
              showBadge={true}
            />
          </div>
        ),
      },
      {
        key: 'warehouse',
        title: I18n.t('general.warehouse'),
        dataIndex: 'warehouse_code',
        render: (data) => data,
      },
      {
        key: 'order_no',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
        render: (data, record) => {
          return (
            <div>
              {!isEmpty(data) && (
                <CustomerOrderDetails
                  order_no={data}
                  key={data}
                  order={{
                    id: record.customer_order_id,
                    customer_order_number: data,
                  }}
                  showEdit={false}
                  // tab="releases"
                />
              )}
            </div>
          )
        },
        // sorter: true,
        // sortDirections: ['ascend', 'descend', 'ascend'],
      },
      // {
      //   key: 'recovery_date',
      //   title: I18n.t('recovery.recovery_date'),
      //   dataIndex: 'recovery_date',
      //   sorter: true,
      //   sortDirections: ['ascend', 'descend', 'ascend'],
      //   render: (data) => {
      //     return (
      //       <span>
      //         {moment(data).isValid()
      //           ? moment.utc(data).format(AppConfig.date_format)
      //           : ''}
      //       </span>
      //     )
      //   },
      // },freight_forwarder
      {
        key: 'company_name',
        title: I18n.t('general.company_name'),
        dataIndex: 'company_name',
        render: (text, record) => (
          <div>
            <Popover
              title={I18n.t('releases.release_to')}
              key={`prelease_to${record.id}`}
              content={
                <div className="table-contact-info width500 fontsize12">
                <ReleaseToDetails
                  details={record}
                  key={`release_to${record.id}`}
                />
                </div>
              }
            >
              <u className='text-decoration-dashed'>{text}</u>
            </Popover>
          </div>
        ),
      },
      {
        key: 'release_eta',
        title: I18n.t('general.scheduled_time'),
        dataIndex: 'scheduled_release_time',
        //sorter: true,
        sorter: (a, b) => new Date(a.scheduled_release_time) - new Date(b.scheduled_release_time),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (data, record) => {
          const selectedWH = this.props?.selectedWH ? this.props.selectedWH : {}
          return (
            <DisplayTime
              key={record.id}
              dateTimeString={data}
              displayWithDate={true}
              isUTC={true}
              timeZoneId={selectedWH.timeZoneId}
            />
          )
        },
      },
    ]
    if (props.showStatus === true) {
      this.columns.push({
        key: 'status',
        title: I18n.t('general.status'),
        dataIndex: 'status',
        render: (data, record, index) => {
          // data === 'in_transit'
          return this.props.currentStatus === '' ? (
            <Select
              style={{ width: '100%' }}
              defaultValue={'in_transit'}
              value={data}
              onChange={(value) => {
                confirmationPopup({
                  title: I18n.t('messages.change_status'),
                  content: I18n.t('messages.change_status_confirm'),
                  onConfirm: () => {
                    // this.props.changeStatus(record.id, value);
                    this.props.handleStatusChange(value, [record.id])
                  },
                })
              }}
            >
              {AppConfig.recoveryStatuses.map(({ key, value, text }) => (
                <Select.Option
                  value={key}
                  key={value}
                  className="selectOptionsWithIcon"
                >
                  <div>
                    {text}&nbsp;&nbsp;{getRecoveryStatusIcon(value)}
                  </div>
                </Select.Option>
              ))}
            </Select>
          ) : (
            <span
              className={`${
                data === 'in_hand' ? 'textAllocated' : 'textCompleted'
              } textBold`}
            >
              {I18n.t(`recoveries.${data}`)}&nbsp;&nbsp;
              {getRecoveryStatusIcon(data)}
            </span>
          )
        },
      })
    }
    columns.push({
      key: 'weight',
      title: I18n.t('order.weight'),
      dataIndex: 'weight',
      render: (data) => formatWeight(data),
    })
    columns.push({
      key: 'pcs',
      title: I18n.t('order.pcs'),
      dataIndex: 'quantity',
      render: (data) => data,
    })
    // this.columns.push({
    //   key: 'driver_comments',
    //   title: I18n.t('recoveries.comments'),
    //   dataIndex: 'driver_comments',
    //   render: (data) =>
    //     !isEmpty(data) ? (
    //       <Popover title={I18n.t('recoveries.comments')} content={data}>
    //         <Icon type="message" />
    //       </Popover>
    //     ) : (
    //       ''
    //     ),
    // })
    if (props.showActions === true) {
      columns.push({
        key: 'items',
        render: (data) => {
          return (
            <Row type="flex" gutter={8} align="middle" justify="end">
              {/* <Tooltip
                title={"View Recovery"}
                placement="left"
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
                <Icon
                  type="branches"
                  onClick={ () => {
                    this.props.history.push({
                      pathname: "/routes",
                      filter: "ASSIGNED",
                      routeId,
                      dateFilter: {
                        // fromDate: date,
                        // toDate: date,
                      },
                    });
                  } }
                />
              </Tooltip> */}
              {!isEmpty(data.driver_comments) && (
                <Col>
                  <Popover
                    title={I18n.t('recoveries.comments')}
                    content={data.driver_comments}
                    placement={'leftTop'}
                  >
                    <Icon type="message" />
                  </Popover>
                </Col>
              )}
              <Col>
                <Tooltip
                  title={I18n.t('order.driver_notes')}
                >
                  <ShowDispatcherNotes
                    apiEndpoint={dispatcherNotesApi}
                    notesTitle="Driver Notes"
                    currentRecord={data}
                    key={`notes${data.id}`}
                  />
                </Tooltip>
              </Col>

              {
                checkServiceExistance('ORU') &&
                <Col>
                <Tooltip title={I18n.t('general.edit')}>
                  <Icon
                    type="edit"
                    onClick={() => this.props.handleEditClick(data)}
                  />
                </Tooltip>
              </Col>
              }
              {
                checkServiceExistance('ORDEL') && <Col>
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm', {
                    action: 'reject',
                    field: 'this',
                  })}
                  onConfirm={() => this.props.deleteClick(data.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title={I18n.t('general.delete')}>
                    <Icon
                      type="delete"
                      className="cursorPointer textRed"
                    />
                  </Tooltip>
                </Popconfirm>
              </Col>
              }
            </Row>
          )
        },
        align: 'center',
      })
    }
    if(props.showSno === true){
      columns.splice(0,0, {
        key: 'index',
        title: '#',
        render: (data, record, index) => index+1,
      });
    }
    this.columns = columns.filter((column) => {
      if(props.showMiniDetails) {
        const keyToShow = ['hawb', 'mawb', 'order_no', 'reference_1', 'reference_2' ]
        return keyToShow.includes(column.key)
      } else {
        return true
      }
     })

    
  }

  setAddress = (data, type) => {
    const pickUpdata = data?.pickup_location
      ? data.pickup_location.l_address
      : {}
    const pickUpAddress = (
      <Popover
        title={I18n.t('general.pickup_address')}
        content={<AddressInfo address={pickUpdata} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {pickUpdata && pickUpdata.city ? `${pickUpdata.city}` : 'NA'}
      </Popover>
    )
    const deliveryData = data?.drop_location ? data.drop_location.l_address : {}
    const dropAddress = (
      <Popover
        title={I18n.t('general.delivery_address')}
        content={<AddressInfo address={deliveryData} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {deliveryData && deliveryData.city ? `${deliveryData.city}` : 'NA'}
      </Popover>
    )
    if (type === 'origin') {
      return pickUpAddress
    }
    return dropAddress
  }

  componentWillReceiveProps(nextProps) {
    this.columns = []
    this.setColumns(nextProps)
  }
}

ReleasesList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
}

ReleasesList.defaultProps = {
  screen_from: '',
  showTriggerEdit: false,
  resolvedAddressCallback: () => {},
}
export default withRouter(ReleasesList)
