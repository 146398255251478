import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Row, Col, Spin, Button, Icon, TreeSelect } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { OrgContext } from "../../context/OrgContext";
import { fetchOrderTypeLos, saveOrderTypeLos } from "../../api/Account";
import { renderAlertMessage } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import TreeSelectComponent from "../../containers/Filters/TreeSelectComponent";
import { fetchAccountShortLos } from "../../api/Los";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import { alertMessage } from "../../common/Common";
const { TabPane } = Tabs;
const { SHOW_PARENT } = TreeSelect;


const OrderTypeToLosMapping = ({ account = {}, accountId = {}, activeKey }) => {
  const [apiProgress, setApiProgress] = useState(false);
  const [configuredLos, setConfiguredLos] = useState({});
  const [levelOfServices, setLevelOfServices] = useState(false);

  const { currentOrg, isAirCargo } = useContext(OrgContext);

  useEffect(() => {
    if(activeKey === "los_rules"){
      getOrderTypeLos()
      getLos()
    }

  }, [activeKey])

  const getOrderTypeLos = () => {
    setApiProgress(true);
    fetchOrderTypeLos(currentOrg.id, accountId).then((result) => {
      if (result.success) {
        handleResponse(result)
        setApiProgress(false);
      } else {
        renderAlertMessage(result.errors);
        setConfiguredLos({});
        setApiProgress(false);
      }
    });
  };

  const handleResponse = (result) => {
    const config = {};
    const accountConfigurations = result.data.los || [];
    accountConfigurations.forEach((setting) => {
        config[setting.order_type] = setting.los_codes?.length > 0 ? setting.los_codes : ["ALL"];
    });
    setConfiguredLos(config);
  }

  const handleSave = () => {
    setApiProgress(true);
    const payload = {
        organization_id: currentOrg.id,
        account_id: account.id,
        order_type_los: Object.keys(configuredLos).map(key => ({
            order_type: key,
            los_codes: configuredLos[key]?.length > 0 ? configuredLos[key]?.includes('ALL') ? null :
            configuredLos[key] : null
        }))
    }
    saveOrderTypeLos(payload).then((result) => {
      if (result.success) {
        setApiProgress(false);
        alertMessage(result?.data?.message || I18n.t('messages.saved'))
        // handleResponse(result)
      } else {
        renderAlertMessage(result.errors);
        setApiProgress(false);
        setConfiguredLos({});
      }
    });
  };

  useEffect(() => {
    // getOrderTypeLos();
    // getLos();
  }, []);

  const handleChange = (key, value) => {
    setConfiguredLos({
        ...configuredLos,
        [key]: value, //value?.includes('ALL') ? ['ALL'] : value
    })
  }

  const getLos = () => {
    fetchAccountShortLos(account.code).then((result) => {
      if (result.success) {
        const account_los = result.account.account_los || [];
        setLevelOfServices(account_los.filter(rec => rec.id !== 'DEFAULT'));
        // setLevelOfServices(account_los);
      } else {
        renderAlertMessage(result.errors);
        setLevelOfServices([]);
      }
    });
  };

  const renderRow = (key, row) => {
    return (
      <Row type="flex" className="marginTop10">
        <Col xs={2} className="textBold">{row.label}:</Col>
        <Col xs={13}>
          <TreeSelectComponent
            selectedValues={configuredLos[key] || ['ALL']}
            onValueChange={(value) =>
              handleChange(key, value)
            }
            records={levelOfServices || []}
            titleField="name"
            valueField="code"
            showLabel={false}
            size="small"
            element="code"
            maxTagCount={levelOfServices.length}
            styleObject={{ minWidth:300 }}
            showCheckedStrategy={SHOW_PARENT}
            useStyleFromParentOnly={true}
          />
          {/* <BaseSelect
            data={levelOfServices || []}
            value={configuredLos[key] || ['ALL']}
            onChange={(e) => {
                handleChange(key, e)
            }}
            selectKey={"id"}
            selectValue={"id"}
            selectedValue={"name"}
            style={{ minWidth:300 }}
            showSearch={true}
            mode="multiple"
            defaultOption="-- All --"
            defaultOptionValue={"ALL"}
          /> */}
        </Col>
      </Row>
    );
  };

  const renderRows = () => {
    const isAirCargoOrg = isAirCargo();
    const hydeTypes = isAirCargoOrg ? ["X", "M"] : ["X"];
    return Object.keys(AppConfig.ORDER_TYPES).filter(o => !hydeTypes.includes(o)).
    map((o) =>
      renderRow(o, AppConfig.ORDER_TYPES[o])
    );
  };

  const _renderFloatingBtn = () => (
    <Button
      className="floatBtnConfig"
      onClick={() => handleSave()}
      type="primary"
      loading={apiProgress}
    >
      <Icon type="save" />
      Save
    </Button>
  );
  return <Spin spinning={apiProgress}>
    {renderRows()}
    {_renderFloatingBtn()}
    </Spin>;
};

OrderTypeToLosMapping.propTypes = {
  account: PropTypes.shape.isRequired,
  accountId: PropTypes.string,
};

OrderTypeToLosMapping.defaultProps = {
  account: {},
  accountId: "",
};

export default OrderTypeToLosMapping;
