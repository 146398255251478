import React, { Fragment } from 'react';
import { Col, Row, Icon } from 'antd';
import { isEmpty } from 'lodash';
import BranchesIcon from '../components/common/BranchesIcon';
import AppConfig from '../config/AppConfig';
import { Card } from '../common/UIComponents';

export const ZoneDetails = ({ orders_info }) => {
  const renderZoneDetails = (zone, index) => (
    <Col key={index} md={24 / orders_info.length} style={{ textAlign: 'left' }}>
      {zone.segment_type && (
        <div className="textBold">{`${zone.segment_type}: `}</div>
      )}
      <div className="textBold">
        <Icon type="account-book" />
        &nbsp;
        {I18n.t("zones.zonename")}&nbsp;:&nbsp;
        <span>{zone.zone_name || "NA"}</span>
      </div>
      <div className="textBold">
        <Icon type="account-book" />
        &nbsp;
        {I18n.t("zones.type")}&nbsp;:&nbsp;
        <span>{zone.zone_type || "NA"}</span>
      </div>
      {zone.zone_type === "location" && (
        <div className="textBold">
          <Icon type="environment" />
          &nbsp;
          {I18n.t("general.zipcode")}&nbsp;:&nbsp;
          <span>{zone.zipcode || "NA"}</span>
        </div>
      )}
      <div className="textBold">
        <BranchesIcon />
        &nbsp;
        {I18n.t("general.distance")}&nbsp;:&nbsp;
        <span>
          {!isEmpty(zone.distance)
            ? `${parseFloat(zone.distance)} ${AppConfig.distance_unit}`
            : "NA"}
        </span>
      </div>
    </Col>  );

  return (
    <Card title="Zone Details" bordered={ false } size="small" bodyStyle={{ height: 150, fontSize: 12 }}>
      <Row>
        { orders_info?.length
          ? orders_info.map(renderZoneDetails)
          : <Fragment /> }
      </Row>
    </Card>
  );
};

export default ZoneDetails;