import React, { Fragment, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import BaseList from "../BaseList";
import {
  compareString,
  compareNumber,
  isEmpty,
  _,
} from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Popover,
  Col,
  Row,
  Tooltip,
  Link,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import OrderActionMenu from "../preplan/rows/OrderActionMenu";
import OrderAppointments from "../orders/OrderAppointments";
import OrderZoneNames from "../orders/OrderZoneNames";
import { checkServiceExistance } from "../../helpers/common";
import TypeOfOrder from "../orders/TypeOfOrder";
import OrderStatus from "../orders/OrderStatus";
import ValidateLocationErr from "../orders/ValidateLocationErr";
import PreplanOrderAccessorial from "../orders/PreplanOrderAccessorial";
import PreplanOrderNotes from "../orders/PreplanOrderNotes";
import Copyable from "../common/Copyable";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import OrderQuantity from "./OrderQuantity";
import {VehicleImageData } from "../../common/VehicleImageData";
import AddressInfo from "../common/AddressInfo";
import BaseList2 from "../BaseList2";
import { ScreenKeys } from "../../containers/constants";
import UnallocatedOrdersGridList from "../dispatches/UnallocatedOrdersGridList";
import { preplanData } from "../dispatches/preplanData";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import moment from "moment";
import OrderAttachments from "../orders/OrderAttachments";

const tootlTipDelay = AppConfig.tooltipDelay;

const UnassignedOrdersList = (props) => {
  const [ resizablecolumns, setResizablecolumns ] = useState([]);
  const [ filteredRowData, setFilteredRowData ] = React.useState(preplanData);
  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
  });
  useEffect(() => {

    let finalColumns = [
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        render: (data, record, index) => {
          return (
            <Row
              type="flex"
              // justify="space-between"
              align="middle"
              style={{
                padding: "0 10px 0 5px",
              }}
              gutter={8}
            >
              <Col>
                <TypeOfOrder
                  order={record}
                  orderTypeKey="type_of_order"
                  relatedOrderKey="related_order"
                  placement={"top"}
                  showBadge={true}
                />
              </Col>
              <Col>
                <Row type="flex" gutter={4}>
                  <Col>
                    <span
                      className={`anchor_cursor ${
                        record.status === "EXCEPTION" ? "textRed" : ""
                      }`}
                      onClick={() => props.handleEditOrder(record.id)}
                    >
                      {!isEmpty(record.exception_message) ? (
                        <OrderStatus
                          record={record}
                          displayKey="customer_order_number"
                          label=""
                          showLabel={false}
                          className="textBold"
                          popoverMsg={
                            record.exception_message
                              ? record.exception_message
                              : ""
                          }
                        />
                      ) : (
                        record.customer_order_number
                      )}
                    </span>
                  </Col>
                  <Col>
                    <Copyable text={data} />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        },
        sorter: (a, b) =>
          compareString(a.customer_order_number, b.customer_order_number),
        // minWidth: 130,
        // align: "left",
        // fixed: "left"
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        render: (data, record) => (
          <div style={{ textAlign: "center" }}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
        sorter: (a, b) => compareString(a.type_of_order, b.type_of_order),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="hawb"
            showTriggerEdit={true}
            editClick={() => props.handleEditOrder(record.id)}
            refreshCallback={props.refreshCallback}
            tootlTipDelay={tootlTipDelay}
            charsToLimit={null}
          />
        ),
        ellipsis: true,
        sorter: (a, b) => compareString(a.hawb, b.hawb),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="mawb"
            showTriggerEdit={true}
            editClick={() => props.handleEditOrder(record.id)}
            refreshCallback={props.refreshCallback}
            tootlTipDelay={tootlTipDelay}
            charsToLimit={null}
          />
        ),
        sorter: (a, b) => compareString(a.mawb, b.mawb),
        sortDirections: ["ascend", "descend", "ascend"],
        ellipsis: true,
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_1"
            tootlTipDelay={tootlTipDelay}
            charsToLimit={null}
            showOrderDetailsByReference={true}
            showTriggerEdit={true}
            editClick={() => props.handleEditOrder(record.id)}
            refreshCallback={props.refreshCallback}
          />
        ),
        sorter: (a, b) => compareString(a.reference_1, b.reference_1),
        sortDirections: ["ascend", "descend", "ascend"],
        ellipsis: true,
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="reference_2"
            tootlTipDelay={tootlTipDelay}
            charsToLimit={null}
            showOrderDetailsByReference={true}
            showTriggerEdit={true}
            editClick={() => props.handleEditOrder(record.id)}
            refreshCallback={props.refreshCallback}
          />
        ),
        sorter: (a, b) => compareString(a.reference_2, b.reference_2),
        sortDirections: ["ascend", "descend", "ascend"],
        ellipsis: true,
      },
      {
        key: "username",
        title: I18n.t("general.customer"),
        dataIndex: "username",
        render: (data, record) => {
          if (["T", "LH"].includes(record.type_of_order)) {
            return (
              <Row>
                { record.locations.map((loc, index) => {
                  const name = `${loc.first_name} ${!isEmpty(loc.last_name) ? loc.last_name : ""}`;
                  return (
                    <Col key={ index }>
                      { loc.type_of_loc === 'PICKUP' ? 'P: ' : 'D: ' } { name } <br />
                    </Col>
                  );
                })
                }
              </Row>
            );
          }
          const name = `${record.customer_first_name} ${
            !isEmpty(record.customer_last_name) ? record.customer_last_name : ""
          }`;
          return (
            <Fragment>
              <Tooltip
                title={name}
                placement="topLeft"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                mouseEnterDelay={tootlTipDelay}
              >
                {name}
              </Tooltip>
            </Fragment>
          );
        },
        sorter: (a, b) =>
          compareString(a.customer_first_name, b.customer_first_name),
        //className: "column-max-content",
        align: "left",
        ellipsis: true,
      },
      {
        key: "account_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        sorter: (a, b) => compareString(a.account_code, b.account_code),
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        className: "noWrap",
        sorter: (a, b) => compareString(a.account_name, b.account_name),
      },
      {
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "levelOfService",
        render: (data, record) => <div>{record.levelOfService || ""}</div>,
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
            <div>{record.routing|| ''}</div>
        ),
        width: 100
      },

      {
        key: "vehicle_type",
        title: I18n.t("order.vehicle_type"),
        dataIndex: "vehicle_type",
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={props.vhTypes}
              showVehicleTitle
            />
          );
        },
        sorter: (a, b) => compareString(a.vehicle_type, b.vehicle_type),
        sortDirections: ["ascend", "descend", "ascend"],
        className: 'noWrap'
      },
      {
        key: "vehicle_image",
        title: I18n.t("vehicleTypes.imgTitle"),
        dataIndex: "vehicle_image",
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={props.vhTypes}
              width={"30"}
              borderRadius={"5"}
              align={"center"}
              showImage
            />
          );
        },
        sorter: (a, b) => compareString(a.vehicle_type, b.vehicle_type),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "company_name",
        title: I18n.t("general.company_name"),
        dataIndex: "company_name",
        render: (data, record) => {
            return (
              <Row>
                {record?.locations?.length && record.locations.map((loc, index) => {
                  const company_name = `${loc.company_name ? loc.company_name : ''}`;
                  const company = company_name ?`${loc.type_of_loc === 'PICKUP' ? 'P: ' : 'D: '} ${company_name}` : '';
                  return (
                    <Col key={index}>
                      {record.type_of_order === "T" || record.type_of_order === "LH" ? (
                        company  
                      ) : (
                        ["P", "D", "TD", "TR", "R"].includes(record.type_of_order) ? company_name : ""
                      )}
                    </Col>
                  );
                })}
              </Row>
            )
          },
        width: 120,
      },
      {
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        render: (data) => data,
      },
      {
        key: "street",
        title: I18n.t("general.street"),
        dataIndex: "street",
        render: (data, record) => (
          <div>
            {record?.customer_address?.address_line1
              ? record.customer_address.address_line1
              : ""}
          </div>
        ),
        className: 'noWrap',
      },

      {
        key: "order_delivery_time",
        title: (
          <Tooltip
            title={I18n.t("preplan.appointment_date_title")}
            placement="top"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            overlayStyle={{ width: 200 }}
            mouseEnterDelay={tootlTipDelay}
          >
            {I18n.t("order.scheduled_short_form")}
          </Tooltip>
        ),
        render: (data) =>
          data.appointments && data.appointments.length > 0 ? (
            <Popover
              size="small"
              content={
                <div className="table-contact-info width500 fontsize12">
                  <OrderAppointments
                    appointments={data.appointments || []}
                    timezone={data.tz_short_form}
                    showConfirmedTime={false}
                    locations={data.locations}
                    showPDLabels={["T", "LH"].includes(data.type_of_order) ? true : false}
                  />
                </div>
              }
              title={I18n.t("appointmentForm.preference_title")}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <OrderAppointments
                appointments={data.appointments || []}
                timezone={data.tz_short_form}
                showConfirmedTime
                locations={data.locations}
                showPDLabels={["T", "LH"].includes(data.type_of_order) ? true : false}
              />
            </Popover>
          ) : (
            ""
          ),
        align: "left",
        sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "zones",
        title: `${I18n.t("zones.zone")}`,
        dataIndex: "zoneName",
        render: (data, record) => (
          <OrderZoneNames
            orderZoneName={
              record.order_zones.map((zone) => zone.zone_name) || []
            }
          />
        ),
        sorter: (a, b) => compareString(a.zoneName, b.zoneName),
        sortDirections: ["ascend", "descend", "ascend"],
        ellipsis: true,
        align: "left",
      },
      {
        key: "items_count",
        title: `${I18n.t("order.qty")}`,
        dataIndex: "quantity",
        sorter: (a, b) => compareNumber(a.quantity, b.quantity),
        align: "left",
        width: 50,
        render: (quantity, record) =>
          quantity ? (
            <OrderQuantity
              record={record}
              key={`unm${record.id}`}
              keyId={`un${record.id}`}
              showActions={true}
              isMilitaryTime={props.isMilitaryTime}
              setIsItemVisible={props.setIsItemVisible}
            >
              {quantity}
            </OrderQuantity>
          ) : (
            ""
          ),
      },
      {
        key: "weight",
        title: (
          <Fragment>
            <Row>
              <Col className="alignCenter">
                {I18n.t("order.weight_short_form")}
              </Col>
              <Col className="alignCenter"> ({AppConfig.weight_unit})</Col>
            </Row>
          </Fragment>
        ),
        dataIndex: "weight",
        render: (data, record) => (
          <div className="alignRight paddingRight10">
            {!isEmpty(data) ? data : ""}
          </div>
        ),
        // sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sorter: (a, b) => compareNumber(a.weight, b.weight),
        align: "left",
      },
      {
        key: "pallets",
        title: I18n.t("order.pallets"),
        dataIndex: "pallets",
      },
      {
        key: "created_at",
        title: I18n.t("order.age"),
        dataIndex: "created_at",
        align: "right",
        render: (created_at) => (
          <div className="paddingRight25">
              <span>{ moment().diff(moment(created_at), "days") }</span>
          </div>
        ),
        align: "center",
      },
      {
        key: 'priority',
        title: I18n.t("configurations.priority"),
        dataIndex: 'priority',
        render: (data, record) => (
          <div>
            {record.priority === null || record.priority === false ? "No" : (
              <span>
                Yes &nbsp; <Icon type="flag" style={{ color: 'red',fontSize:'15px' }} />
              </span>
            )}
          </div>
        ),
        width: 100,
        sorter: (a, b) => compareString(a.priority, b.priority),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "customer_city",
        title: I18n.t("general.city"),
        ellipsis: true,
        dataIndex: "customer_city",
        className: "text-capitalize",
        render: (data, record) => {
          const city = data ? data.toLowerCase() : "";
          const { type_of_order } = record;
          if (["T", "LH"].includes(type_of_order)) {
            const pickup = record.locations.find(
              (loc) => loc.type_of_loc === "PICKUP"
            );
            const drop = record.locations.find(
              (loc) => loc.type_of_loc === "DELIVERY"
            );
            const pickupCity = pickup
              ? pickup.l_address.city.toLowerCase()
              : "";
            const dropCity = drop ? drop.l_address.city.toLowerCase() : "";

            return (
              <div>
                { pickupCity.length > 0 && (
                <Tooltip
                  title={
                    <span className="text-capitalize">{`P: ${pickupCity}`}</span>
                  }
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placement="topRight"
                  mouseEnterDelay={tootlTipDelay}
                >
                  {`P: ${pickupCity}`}
                </Tooltip>
                ) }
                { pickupCity.length > 0 && dropCity.length > 0 && <br /> }
                { dropCity.length > 0 && (
                <Tooltip
                  title={
                    <span className="text-capitalize">{`D: ${dropCity}`}</span>
                  }
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placement="topRight"
                  mouseEnterDelay={tootlTipDelay}
                >
                  {`D: ${dropCity}`}
                </Tooltip>
                ) }
              </div>
            );
          }

          return (
            <Tooltip
              title={<span className="text-capitalize">{city}</span>}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placement="topRight"
              mouseEnterDelay={tootlTipDelay}
            >
              {city}
            </Tooltip>
          );
        },
        sorter: (a, b) => compareString(a.customer_city, b.customer_city),
        sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
      },
      // {
      //   key: 'customer_state',
      //   title: I18n.t('general.state_short_form'),
      //   dataIndex: 'customer_state',
      //   sorter: (a, b) => compareString(a.customer_state, b.customer_state),
      //   width: 60,
      // },
      {
        key: "customer_zip",
        title: I18n.t("general.zipcode_short_form"),
        dataIndex: "customer_zipcode",
        sorter: (a, b) => compareString(a.customer_zipcode, b.customer_zipcode),
        sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        render: (record, data) => {
          const { type_of_order } = data;
          if (["T", "LH"].includes(type_of_order)) {
            const pickup = data.locations.find(
              (loc) => loc.type_of_loc === "PICKUP"
            );
            const drop = data.locations.find(
              (loc) => loc.type_of_loc === "DELIVERY"
            );
            const pickupZip = pickup?.l_address ? pickup?.l_address.zipcode : "";
            const dropZip = drop?.l_address ? drop.l_address.zipcode : "";

            return (
              <div>
                { pickupZip?.length > 0 && (
                  <div>
                    P: <ValidateLocationErr
                      record={ {
                        ...pickup,
                        customer_order_number: `${data.customer_order_number}P`,
                        location_partial_match : pickup?.location_partial_match, 
                        zipcode: pickupZip,
                        location_id: pickup?.id ? pickup.id : ''
                      } }
                      displayKey={ "zipcode" }
                      errorKey={ "location_partial_match" }
                      errorValue={ true }
                      refreshCallback={ () =>
                        props.resolvedAddressCallback()
                      }
                      loadMaps={ false }
                      setOrderInfo={ props.setOrderInfo }
                    />
                  </div>
                ) }
                { dropZip?.length > 0 && (
                  <div>
                    D: <ValidateLocationErr
                      record={ {
                        ...drop,
                        customer_order_number: `${data.customer_order_number}D`,
                        location_partial_match : drop?.location_partial_match,
                        zipcode: dropZip,
                        location_id: drop?.id ? drop.id : ''
                      } }
                      displayKey={ "zipcode" }
                      errorKey={ "location_partial_match" }
                      errorValue={ true }
                      refreshCallback={ () =>
                        props.resolvedAddressCallback()
                      }
                      loadMaps={ false }
                      setOrderInfo={ props.setOrderInfo }
                    />
                  </div>
                ) }
              </div>
            );
          }
          return (
            <div>
              <ValidateLocationErr
                record={{
                  ...data,
                  zipcode: data.customer_zipcode,
                  location_id: data.cs_location_id,
                  l_address: data.customer_address,
                }}
                displayKey={"zipcode"}
                errorKey={"location_partial_match"}
                errorValue={true}
                refreshCallback={() =>
                        props.resolvedAddressCallback()
                }
                loadMaps={false}
                setOrderInfo={props.setOrderInfo}
              />
            </div>
          );
        },
      },
      {
        key: "cs_address",
        title: I18n.t("general.address"),
        dataIndex: "customer_address",
        align: "left",
        // className:"noWrap",
        render: (record, data) => {
          return (
            <div>
              <AddressInfo
                address={record}
                showPopUp={true}
              />
            </div>
          );
        },
      },
    ];

    const isPreplanPage = props.screen_from === "preplan";
    if (!_.isEmpty(props.displayConfiguration) && isPreplanPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const unassigned_list_view_columns =
        userConfiguration.unassigned_list_view_columns ||
        orgConfiguration.unassigned_list_view_columns;
      const columns = [];
      if (
        typeof unassigned_list_view_columns !== undefined &&
        Array.isArray(unassigned_list_view_columns)
      ) {
        unassigned_list_view_columns.forEach((column) => {
          const foundColumn = finalColumns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = finalColumns.filter(
          (col) => col.key === "actions"
        );
        if (actions.length > 0) {
          columns.push(...actions);
        }
        finalColumns = columns;
      } else {
      }
    }

    finalColumns.push({
      key: "actions",
      title: "",
      fixed: "right",
      dataIndex: "id",
      render: (id, record, index) => (
        <div>
          <Row gutter={6} type="flex" style={{marginLeft: -2}}>
            <Col span={5}>
              <PreplanOrderAccessorial
                record={record}
                placement={
                  index !== 0 && index === props.data.length - 1
                    ? "topRight"
                    : "left"
                }
                showPopupContainer={false}
              />
            </Col>
            <Col span={5}>
              <PreplanOrderNotes
                record={record}
                placement={
                  index !== 0 && index === props.data.length - 1
                    ? "topRight"
                    : "left"
                }
                showPopupContainer={false}
              />
            </Col>
            <Col span={5} style={{ paddingTop: 2}}>
                <CustomerOrderDetails
                  order_no={ record.customer_order_number }
                  order={ {
                    id: record.id,
                    customer_order_number: record.customer_order_number,
                  } }
                  showEdit={ false }
                  showIcon={true}
                  eyeIconStyle={{ fontSize: 15 }}
                />
            </Col>
            <Col span={ 4 }>
              <OrderAttachments
                  record={ record }
                  placement={
                    index !== 0 && index === props?.data?.length - 1
                      ? "topRight"
                      : "left"
                  }
                  showPopupContainer={ false }
              />
            </Col>

            {checkServiceExistance("NRURO") && (
              <Col span={5}>
                <div>
                  {record.account_limit_exceeded ? (
                    <Tooltip
                      title={I18n.t("order.account_limit_exceeded")}
                      placement="left"
                    >
                      <Icon type="info-circle" style={{ color: "red" }} />
                    </Tooltip>
                  ) : (
                    <Droppable
                      droppableId={`unassignedDroppable${record.customer_order_number}`}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Draggable
                            key={record.id}
                            draggableId={record.id}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              const selectedList = props.selectedList
                                ? [...props.selectedList]
                                : [];
                              const { isDragging } = snapshot;
                              const displayText = isDragging
                                ? selectedList.length > 1 &&
                                  selectedList.includes(record.id)
                                  ? `Allocating ${selectedList.length} orders`
                                  : record.customer_order_number
                                : "";
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div
                                    style={{
                                      backgroundColor: isDragging
                                        ? "#607ad6"
                                        : "inherit",
                                      color: isDragging ? "#FFF" : "inherit",
                                      width: isDragging ? 180 : 20,
                                      height: isDragging ? 30 : "inherit",
                                      fontSize: isDragging ? 15 : "inherit",
                                      paddingTop: isDragging ? 6 : "inherit",
                                      borderRadius: isDragging ? 10 : "inherit",
                                    }}
                                  >
                                    {!isDragging ? (
                                      <Fragment>
                                        <Icon
                                          type="menu"
                                          className={
                                            isDragging ? "floatRight" : ""
                                          }
                                        />
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        &nbsp;&nbsp;
                                        <Icon type="menu" /> &nbsp;{" "}
                                        {displayText}
                                      </Fragment>
                                    )}
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        </div>
                      )}
                    </Droppable>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      ),
    });

    setResizablecolumns(finalColumns);
  }, [props.displayConfiguration]);

  return (
    <>
      <BaseList2
        data={ props.data }
        columns={ resizablecolumns }
        nonResizableCols={ [ 'actions' ] }
        rowSelection={ props.rowSelection }
        tableName={ ScreenKeys.PREPLAN_MANAGEMENT_MODAL }
        pagination={ props.pagination }
        onColWidthChange={ (colWidths, unsavedChanges) => {
          props.onColWidthChange(colWidths, unsavedChanges);
        }
        }
        showtitle={ false }
        rowClassName={ props?.rowClassName || null }
        size={ props.size || "small" }
      />

      {/* <div style={ { height: "100vh" } }>
        <UnallocatedOrdersGridList
          rawDataSource={ props.data }
          accountCodesWithExceededLimit={ [] }
          // onRowSelection={ props.rowSelection }
          onRowSelection={(selectedRowKeys, selectedRows) => {props.rowSelection(selectedRowKeys, selectedRows ,props.totalOrders)}}
          accountsList={ [] }
          displayConfiguration={ {} }
          showTriggerEdit={ true }
          showDelete={ false }
          showActions={ false }
          showDetailsLink={ false }
          showWeight={ true }
          hideAppointmentdate={ true }
          tableName={ ScreenKeys.PREPLAN.UNASSIGNED_ORDERS_V2 }
        />
      </div> 
     */}
    </>
   
  );
};

export default UnassignedOrdersList;