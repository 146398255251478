/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import moment from 'moment'
import BaseList from '../BaseList'
import {
  checkNegitive,
} from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Typography,
} from '../../common/UIComponents'
import AppConfig from '../../config/AppConfig'
import userStore from '../../stores/UserStore'
const { Text } = Typography

class QuotesRevisionsList extends BaseList {
  constructor(props) {
    super(props)
    this.columns = []
    this.setColumns(props)
  }
  setColumns = (props) => {
    this.columns = [
      {
        title: '#',
        key: 'index',
        render: (text, record, index) => index + 1,
      },
      // {
      //   key: 'transportation_charge',
      //   title: I18n.t('invoices.invoice_types.transportation_charges'),
      //   dataIndex: 'transportation_charge',
      //   sorter: true,
      //   sortDirections: ['ascend', 'descend', 'ascend'],
      //   render: (data, record) => {
      //     return checkNegitive(data)
      //   },
      // },
      // {
      //   key: 'fuel_surcharge',
      //   title: I18n.t('invoice_lines.fuel_surcharges'),
      //   dataIndex: 'fuel_surcharge',
      //   render: (data) => {
      //     return <span>{checkNegitive(data)}</span>
      //   },
      // },
      // {
      //   key: 'accessorial_charges',
      //   title: I18n.t('invoices.accessorial_charges'),
      //   dataIndex: 'accessorial_charges',
      //   render: (data) => {
      //     return <span>{checkNegitive(data)}</span>
      //   },
      // },
      // {
      //   key: 'additional_charges',
      //   title: I18n.t('invoice_lines.additional_charge'),
      //   dataIndex: 'additional_charges',
      //   render: (data) => {
      //     return <span>{checkNegitive(data)}</span>
      //   },
      // },
      // {
      //   key: 'accessorial',
      //   title: I18n.t('general.accessorial'),
      //   dataIndex: 'accessorials',
      //   render: (data) => {
      //     return <span><DisplayMinimumInfo elements={data} minimumToShow={2} /></span>
      //   },
      // },
      {
        key: 'quote',
        title: I18n.t('order.quote'),
        dataIndex: 'quote_amount',
        render: (text, record) => (
          <div className='alignRight paddingRight30'>
            <span>{checkNegitive(text)}</span>
          </div>
        ),
        width: 120,
      },
      {
        key: 'quote_date',
        title: I18n.t('quotes.quote_date'),
        dataIndex: 'updated_at',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (data) => {
          return (
            <span>
              {moment(data).isValid()
                ? moment(data).format(AppConfig.date_format)
                : ''}
            </span>
          )
        },
      },
      {
        key: 'created_by',
        title: I18n.t('quotes.created_by'),
        dataIndex: 'quoted_user_name',
        render: (text) => (
          <div>
            <span>{text}</span>
          </div>
        ),
      },
      
    ]
  }
}

export default QuotesRevisionsList
