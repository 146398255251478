import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import MonthlyCutOffList from "./MonthlyCutOffList";
import { RecordsPerPage } from "../orders/RecordsPerPage";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { approveMonthlyCutOff } from "../../api/MonthlyCutOff";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage } from "../../common/Common";
import BaseModal from "../BaseModal";

const MonthlyCutForm = ({
  warehouses,
  accounts,
  refreshAccounts,
  gatherFormData,
  cutoffData,
  pagination,
  onTableChange,
  recordsPerPage,
  onRecordChange,
  buttonLoading,
  setIsApproved,
  setIsSearching,
  setFormData,
  formData,
  debouncedCutOffData,
  searchLoading,
  orgData,
  setButtonLoading,
  currentPage,
  ObtainedSelectedData,
  uniqueCustomerOrderIds,
  isSearching,
  defaultFormData,
  warehouseFilter,
  setUniqueCustomerOrderIds,
}) => {
  const dateFormat = "Do MMM YYYY";
  const [selectedInfo, setSelectedInfo] = useState({
    selectedRowKeys: [],
    selectedRows: [],
  });
  const [openCutoffDateModal, setOpenCutoffDateModal] = useState(false);  
  const [triggerReload, setTriggerReload] = useState(false);


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 },
    },
  };

  const gridSize = {
    xs: 4,
    sm: 4,
    md: 4,
    lg: 4,
    xl: 4,
  };

  const { RangePicker } = DatePicker;

  // useEffect(() => {
  //   if(_.isEmpty(formData.filterOrder)){
  //     setFormData({... formData, filterOrder: "ALL"})
  //   }
  // },[formData.filterOrder])

  useEffect(() => {
    if (!_.isEmpty(formData.account_code)) {
      let account = {};
      const accountIndex = _.findIndex(accounts, {
        code: formData.account_code,
      });
      if (accountIndex >= 0) {
        account = accounts[accountIndex];
      }
      setFormData({
        ...formData,
        account_id: account?.id ? account.id : "",
        account_code: account?.code ? account.code : "",
      });
    } else {
      ("");
    }
  }, [formData.account_code]);

  // Call account API when warehouse code changes
  useEffect(() => {
    if (!_.isEmpty(formData.warehouse)) {
      // Find the ID of the selected warehouse
      const whRecord = warehouses.find((wh) => wh.id === formData.warehouse);
      const whId = whRecord?.id ? whRecord.id : "";

      const whNAme = whRecord?.name ? whRecord.name : "";

      // Call the API to refresh the accounts
      refreshAccounts(whId);

      // Clear account info from the quote form
      setFormData({
        ...formData,
        account_code: "",
        account_id: "",
        warehouse: whId,
      });
    }
  }, [formData.warehouse]);

  // Update account ID when account code changes
  useEffect(() => {
    const newFormData = { ...formData };
    if (!_.isEmpty(formData.account_code)) {
      const accountIndex = _.findIndex(accounts, {
        code: formData.account_code,
      });
      if (accountIndex >= 0) {
        const account = accounts[accountIndex];
        newFormData.account_id = account.id;
        newFormData.account_code = account.code;
      }
    } else {
      newFormData.account_id = "";
      newFormData.account_code = "";
    }

    setFormData(newFormData);
  }, [formData.account_code, accounts]);

  const handleOnChange = (element, value) => {
    const updatedFormData = { ...formData, [element]: value };
    if (element === "account_code") {
      const accountIndex = _.findIndex(accounts, {
        code: value,
      });
      if (accountIndex >= 0) {
        const account = accounts[accountIndex];
        updatedFormData.account_id = account.id;
        updatedFormData.account_code = account.code;
      }
    }
    setFormData(updatedFormData);
    gatherFormData(updatedFormData);
  };

  const renderCutoffDateModal = () => {
    return (
      <BaseModal
        title="Cutoff Date To Proceed"
        visible={openCutoffDateModal}
        onCancel={() =>setOpenCutoffDateModal(false)}
        footer={null}
        width={"300px"}
      >
        <Row type="flex" align="center" justify="center">
          <Col span={24}>
            <Form.Item label={<b>{I18n.t("MonthlyCutoff.Approval_label")}</b>}>
              <DatePicker
                style={{ width: "100%" }}
                size="small"
                format={dateFormat}
                onChange={(dates, dateStrings) =>
                  handleDateRanges(dates, dateStrings, true)
                }
                value={formData.normalDate}
                // disabledDate={(current) =>
                //   (current &&
                //     current < moment(formData.toDate).startOf("day")) ||
                //   current.isAfter(moment().endOf("day"))
                // }
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleApprove();
              }}
              className="fontSize13 cursorPointer textBold buttonTeal"
              icon="check-circle"
              loading={buttonLoading}
            >
              Approve
            </Button>
          </Col>
        </Row>
      </BaseModal>
    );
  };

  const handleDateRanges = (dates, dateStrings, normalDate = false) => {
    if (normalDate) {
      setFormData({
        ...formData,
        normalDate: dates,
      });
      gatherFormData({
        ...formData,
        normalDate: dates,
      });
      return;
    }

    let toDatePlusOneDay = moment(dates[1]);
    if (!toDatePlusOneDay.isSame(moment(), "day")) {
      toDatePlusOneDay = toDatePlusOneDay.add(1, "days");
    } else {
      toDatePlusOneDay = null;
    }

    setFormData({
      ...formData,
      fromDate: dates[0],
      toDate: dates[1],
      normalDate: toDatePlusOneDay,
    });
    gatherFormData({
      ...formData,
      fromDate: dates[0],
      toDate: dates[1],
      normalDate: toDatePlusOneDay,
    });
  };

  const handleApprove = () => {
    if(_.isEmpty(formData.normalDate)){
      alertMessage("Please select a date to proceed", "error");
      return;
    }
    setButtonLoading(true);
    const payload = {
      organization_id : _.get(orgData, "currentOrg.id", ""),
      customer_order_ids : uniqueCustomerOrderIds,
      new_approval_date : moment(formData.normalDate).format("DD-MM-YYYY"),
    }
    approveMonthlyCutOff(payload).then((response) => {
      if (response.success) {
        setSelectedInfo({
          selectedRowKeys: [],
          selectedRows: [],
        });
        debouncedCutOffData();
        alertMessage("Invoice(s) Processed Successfully", "success");
      }else {
        setIsApproved(false);
        renderAlertMessage(response.errors);
        setButtonLoading(false);
      }
    }).catch((error) => {
      setIsApproved(false);
      renderAlertMessage(error);
      setButtonLoading(false);
    }).finally(() => {
      setButtonLoading(false);
      setOpenCutoffDateModal(false);
      setUniqueCustomerOrderIds([]);
      setFormData({...formData, normalDate: null});
    });
  }

useEffect(() => {
  if(isSearching){
    debouncedCutOffData();
  }
},[isSearching])

useEffect(() => {
  if (triggerReload) {
    debouncedCutOffData();
    setTriggerReload(false);
  }
}, [triggerReload]);

  return (
    <>
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <Card
            className="customCard paymentDetailsCard"
            bordered={false}
            bodyStyle={{
              padding: "0px 10px",
              borderRadius: 10,
              fontSize: 12,
            }}
          >
            <Form {...formItemLayout}>
              <Row type="flex" gutter={16} align="middle" justify="start">
                <Col {...gridSize}>
                  <Form.Item
                    label={
                      <b>{`${I18n.t("batch_management.warehouse")} ${":"}`}</b>
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="small"
                      dropdownMatchSelectWidth={false}
                      value={formData.warehouse ? formData.warehouse : ""}
                      onChange={(value) => handleOnChange("warehouse", value)}
                    >
                      {warehouses.map((warehouse) => (
                        <Select.Option key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...gridSize}>
                  <Form.Item
                    label={
                      <b>{`${I18n.t("MonthlyCutoff.accounts")} ${":"}`}</b>
                    }
                  >
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="small"
                      dropdownMatchSelectWidth={false}
                      value={formData.account_code ? formData.account_code : ""}
                      onChange={(value) =>
                        handleOnChange("account_code", value)
                      }
                    >
                      <Select.Option key="account_code" value="">
                        -- ALL --
                      </Select.Option>
                      {accounts?.map((account) => (
                        <Select.Option key={account.code} value={account.code}>
                          {`${account.name} (${account.code})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...{ ...gridSize, xl: 6 }}>
                  <Form.Item
                    label={<b>{`${I18n.t("MonthlyCutoff.cutoff_date")}  ${":"}`}</b>}
                  >
                    <RangePicker
                      style={{ width: "100%" }}
                      size="small"
                      onChange={(dates, dateStrings) =>
                        handleDateRanges(dates, dateStrings)
                      }
                      format={dateFormat}
                      value={[formData.fromDate, formData.toDate]}
                      allowClear={false}
                      ranges={AppConfig.monthlyCutoffRanges}
                      disabledDate={(current) =>
                        current.isAfter(moment().endOf("day"))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col {...{ ...gridSize, xl: 3 }}>
                  <Form.Item
                    label={<b>{`${I18n.t("MonthlyCutoff.invoice_frequency")}  ${":"}`}</b>}
                  >
                    <Select
                      style={{ width: "100%" }}
                      size="small"
                      value={formData.filterOrder}
                      onChange={(value) => handleOnChange("filterOrder", value)}
                    >
                      <Select.Option value="ALL">---ALL---</Select.Option>
                      <Select.Option value="STOP">STOP</Select.Option>
                      <Select.Option value="INDV">INDIVIDUAL</Select.Option>
                      <Select.Option value="DAILY">DAILY</Select.Option>
                      <Select.Option value="WEEK">WEEKLY</Select.Option>
                      <Select.Option value="GROUP">GROUP</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col {...{ ...gridSize, xl: 7 }}>
                  <Row type="flex" gutter={16} style={{marginTop:10}}>
                    <Col xs={24} sm={12} lg={8} xl={7}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          setIsSearching(true);
                        }}
                        icon="search"
                        loading={searchLoading}
                      >
                        {I18n.t("general.search")}
                      </Button>
                    </Col>
                    <Col xs={24} sm={12} lg={8} xl={7}>
                      <Button
                        type="danger"
                        size="small"
                        icon="redo"
                        onClick={() => {
                          setFormData({ ...defaultFormData });
                          setTriggerReload(true)
                        }}
                        loading={searchLoading}
                      >
                        {I18n.t("general.reset")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={3}></Col>
      </Row>
      <Row type="flex" justify="end" gutter={8}>
        <Col>
          <RecordsPerPage
            onChange={onRecordChange}
            value={recordsPerPage}
            defaultValue={recordsPerPage}
          />
        </Col>
      </Row>
      <Col style={{ marginBottom: 10 }}>
        <MonthlyCutOffList
          data={!_.isEmpty(cutoffData) ? cutoffData : []}
          pagination={pagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(pagination, filter, sorter, currentTable)
          }
          scroll={{ y: "calc(100vh - 375px)", x: "max-content" }}
          rowSelection={{
            selectedRowKeys: selectedInfo.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedInfo({
                selectedRowKeys,
                selectedRows,
              });
              ObtainedSelectedData(selectedRowKeys);
            },
            getCheckboxProps: (record) => ({
              id: `${record.customer_order_id}`,
            }),
          }}
          currentPage={currentPage}
           warehouseFilter = {warehouseFilter}
        />
      </Col>
      <Col className="alignCenter">
        {/* {selectedInfo.selectedRowKeys.length > 0 && ( */}
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setOpenCutoffDateModal(true);
            }}
            className="fontSize13 cursorPointer textBold buttonTeal"
            icon="check-circle"
            loading={buttonLoading}
            disabled={selectedInfo.selectedRowKeys.length === 0}
          >
            Change Cut off Date
          </Button>
        {/* )} */}
      </Col>
      {openCutoffDateModal && renderCutoffDateModal()}
    </>
  );
};

export const WrappedMonthlyCutForm = withRouter(Form.create()(MonthlyCutForm));
