import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";
export const DriverTypesApi = {
  fetchDriverTypes: (organizationId) => {
    let url = ApiUrl(`v2/driver_types?organization_id=${organizationId}`);
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { driver_types: "driver_types" },
    });
  },
  saveDriverTypes: (data) => {
    let url = ApiUrl(`v2/driver_types`)
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  updateDriverTypes: (id, data,organizationId) => {
    let url = ApiUrl(`v2/driver_types/${id}?organization_id=${organizationId}`);
    return Call("put", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
  deleteDriverTypes: (id,organizationId) => {
    let url = ApiUrl(`v2/driver_types/${id}?organization_id=${organizationId}`)
    return Call("delete", url,{}, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  },
};