import userStore from '../stores/UserStore';
import {
    Call,
    ApiUrl,
    PageParams,
    ParseGeneralResponse,
    setObjectToFormData,
  } from './ApiUtils';


  export function saveAgent(isNew, data) {
    if (
      data.phone &&
      data.phone.length > 0 &&
      data.phone[0] !== '+') {
      data.phone = `+${data.phone}`;
    }
    data.operation_code = isNew ? 'AGC' : 'AGU';
    const url = isNew ? ApiUrl('v2/agents') : ApiUrl(`v2/agents/${data.id}`);
    const method = isNew ? 'post' : 'put';
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { agents: "agent" },
    });
  }

  export function deleteAgent(id) {
    const url = ApiUrl(`v2/agents/${id}`);
    return Call('delete', url, {operation_code: 'AGD'}, ParseGeneralResponse);
  }

  export function fetchAgent(organizationId, page =1, perPage ='') {
    const url = ApiUrl(`v2/agents?${PageParams(
        page,
        perPage,
      )}&organization_id=${organizationId}&operation_code=AII`);
      return Call('get', url, {}, ParseGeneralResponse, {
        responseDataKeys: { agents: 'agents', pagination: "meta.pagination" },
      })
  }
  export function fetchOneAgent(id) {
    const url = ApiUrl(`v2/agents/${id}?operation_code=ACS`);
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        data: "",
      },
    });
  }
