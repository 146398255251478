import React from 'react';
import ReactDom from 'react-dom';

import Main from '../src/javascripts/index';

document.addEventListener('DOMContentLoaded', () => {
  ReactDom.render(
    <Main />,
    document.getElementById('main-content'),
  );
});
