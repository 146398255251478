import React, { Fragment } from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';
import { compareString, checkNegitive } from '../../common/Common';
import I18n from '../../common/I18n';
import { Icon, Popconfirm } from '../../common/UIComponents';
import userStore from '../../stores/UserStore';
import { formatByTimeConfig } from '../../helpers/common';
import DeleteIcon from '../common/DeleteIcon';
import SettingIcon from '../common/SettingIcon';
import EditIcon from '../common/EditIcon';
import moment from 'moment';

const isSuperAdmin = userStore.superAdmin();
class LocationsList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
        render: text => ({
          props: {
            className: 'wordWrap',
          },
          children: text,
        }),
        sorter: (a, b) => compareString(a.name, b.name),
      },
      {
        key: 'code',
        title: I18n.t('organization.code'),
        dataIndex: 'code',
      },
      {
        key: 'price',
        title: I18n.t('package.price'),
        dataIndex: 'price',
        render: text => (<Fragment>{checkNegitive(text)}</Fragment>),
      },
      {
        key: 'package_validity',
        title: I18n.t('package.package_validity'),
        dataIndex: 'package_validity',
        align: 'center',
      },
      {
        key: 'subscription_validity',
        title: I18n.t('package.subscription_validity'),
        dataIndex: 'subscription_validity',
        align: 'center',
      },
      {
        key: 'live_on',
        title: I18n.t('package.live_on'),
        dataIndex: 'live_on',
        render: data => (<span> {data ? formatByTimeConfig(moment(data) , this.props.isMilitaryTime , "DD MMM, YYYY  HH:mm" , "DD MMM, YYYY  hh:mm A") : ""} </span>),
        align: 'center',
      },
      {
        key: 'shutdown_on',
        title: I18n.t('package.shutdown_on'),
        dataIndex: 'shutdown_on',
        render: data => (<span> {data ? formatByTimeConfig(moment(data) , this.props.isMilitaryTime , "DD MMM, YYYY  HH:mm" , "DD MMM, YYYY  hh:mm A") : ""} </span>),
        align: 'center',
      },
    ];
    this.columns.push({
      key: 'actions',
      title: '',
      dataIndex: 'id',
      fixed: "right",
      render: id => (
        <div className='line-actions'>
          {/* <Icon
            type='edit'
            onClick={() => this.props.editClick(id)}
          /> */}
          <Tooltip title={I18n.t("general.delete")}>
            <span><EditIcon handleClick={()=> this.props.editClick(id)}/></span>
          </Tooltip>
          <SettingIcon handleClick={() => this.props.doModuleMapping(id)}/>
          <Popconfirm
            placement="left"
            title={I18n.t('messages.delete_confirm')}
            onConfirm={() => this.props.deleteClick(id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title={I18n.t("general.delete")}>
              <span><DeleteIcon/></span>
            </Tooltip>
          </Popconfirm>
        </div>
      ),
      width: '95px',
    });
  }
}

export default LocationsList;
