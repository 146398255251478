import React, { Component } from "react";
import _ from "lodash";
import { Tabs } from "../common/UIComponents";
import I18n from "../common/I18n";
import { checkServiceExistance } from "../helpers/common";
import Adjustments from "./Adjustments";
import Billing from "./Billing";

const { TabPane } = Tabs;
class LOSAdjustmentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account || {},
      setActiveKey: "los",
    };
  }

  goToTab = (key) => {
    this.setState({ setActiveKey: key });
  };

  render() {
    return (
      <div className="child-card-container">
        <Tabs
          activeKey={this.state.setActiveKey}
          onChange={this.goToTab}
          type="card"
        >
          {checkServiceExistance(["BZI", "LOSI"], "ALL") && (
            <TabPane
              tab={`${I18n.t("los.short_form")} ${I18n.t(
                "account.billing.rate_matrix"
              )}`}
              key="los"
              className="settingSubTabScroll"
            >
              <Billing
                account={this.state.account}
                goToTab={this.props.goToTab}
              />
            </TabPane>
          )}
          {checkServiceExistance("AADI") && (
            <TabPane tab={I18n.t("adjustments.label")} key="adjustment">
              <Adjustments account={this.state.account} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default LOSAdjustmentSettings;
