import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, FormItem, Row, Card, DatePicker, Icon, Avatar, Tooltip, Tabs, Checkbox } from '../../common/UIComponents';
import AddressInfo from '../common/AddressInfo';
import { doFormate, alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import customerlogo from '../../../assets/images/customer.png';
import { saveOrder } from '../../api/OrdersApi';
import FormErrors from '../../components/common/FormErrors';
import warehouse from '../../../assets/images/warehouse.png';
import ItemDetails from '../Routes/ItemDetails';
import MaterialTimePicker from '../orders/MaterialTimePicker';
import { OrgContext } from '../../context/OrgContext';
import { withRouter } from 'react-router';

const { TabPane } = Tabs;

class EditOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrder: this.props.currentOrder,
      orderObject: Object.assign({}, this.props.orderObject, {
        scheduled_end_datetime: this.props.currentOrder.scheduled_end_datetime ? this.props.currentOrder.scheduled_end_datetime : null,
        scheduled_start_datetime: this.props.currentOrder.scheduled_start_datetime ? this.props.currentOrder.scheduled_start_datetime : null,

      }),
      routeId: this.props.currentOrder ? (this.props.currentOrder.routeId ? this.props.currentOrder.routeId : '') : '',
      leg_status: {
        WP: 'Warehouse Pickup', WD: 'Warehose Drop', CD: 'Customer Drop', CP: 'Customer Pickup',
      },
      keys: {
        service_install_opted: 'service_install',
        service_uninstall_opted: 'service_uninstall',
        service_load_opted: 'service_load',
        service_unload_opted: 'service_unload',

      },
      errors: [],
      callFrom: this.props.callFrom || '',
      organizationSettings : {},
      isMilitaryTime : false,
    };
  }

  componentDidMount(){
    this.setState({ organizationSettings: this.context.organizationSettings , isMilitaryTime : this.context.organizationSettings.is_military_time == 'true'}) 
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.context.organizationSettings)) {
      this.setState({ organizationSettings: this.context.organizationSettings, isMilitaryTime : this.context.organizationSettings.is_military_time == 'true'})
    }
  }

    fomatString = (str, format = '') => doFormate(str, format)


    static getDerivedStateFromProps(props, state) {
      return { currentOrder: props.currentOrder };
    }

    handleCurrentOrderChange = (element, value) => {
      this.setState({
        orderObject: Object.assign({}, this.state.orderObject, { [element]: value }),
      });
    }

    handleCurrentOrderDateChange = (element, value) => {
      if (element === 'scheduled_start_datetime') {
        const scheduled_end_datetime = value ? moment(new Date(value)).add(4, 'hours') : null;
        this.setState({
          orderObject: Object.assign({}, this.state.orderObject, {
            [element]: value,
            scheduled_end_datetime,
          }),
        });
      }
      /* else {
            const scheduled_start_datetime = this.state.orderObject.scheduled_start_datetime;
            if (value && scheduled_start_datetime) {
                let a =  moment(new Date(scheduled_start_datetime));
                let b =  moment(new Date(value));
                let minutes = (b.diff(a,'minutes'));
                let errors=[];
                if(minutes > 240){
                    errors.push("The duration between scheduled start time and  scheduled end time should not greater than 4 hours. ");
                    alertMessage("The duration between scheduled start time and  scheduled end time should not greater than 4 hours",'error');
                }

                this.setState({
                    orderObject: Object.assign({}, this.state.orderObject, {
                        [element]: value

                    }),
                    errors: errors
                });

            }


        } */
    }

    onCheckBoxChange = (e, element) => {
      this.setState({
        orderObject: Object.assign({}, this.state.orderObject, { [this.state.keys[element]]: e.target.checked }),
      });
    }


    handleSave = () => {
      this.setState({
        loading: true,
      });
      const data = {};
      // if (this.state.routeId) {
      //   data = Object.assign({}, this.state.orderObject, { nav_route_id: this.state.routeId,  });
      // } else {
      //   data = Object.assign({}, this.state.orderObject);
      // }
      data.scheduled_start_datetime = this.state.orderObject.scheduled_start_datetime ? (moment(new Date(this.state.orderObject.scheduled_start_datetime)).format('YYYY-MM-DD HH:mm:ss')) : '';
      data.scheduled_end_datetime = this.state.orderObject.scheduled_end_datetime ? (moment(new Date(this.state.orderObject.scheduled_end_datetime)).format('YYYY-MM-DD HH:mm:ss')) : '';
      data.service_load = this.state.orderObject.service_load;
      data.service_unload = this.state.orderObject.service_unload;
      data.service_install = this.state.orderObject.service_install;
      data.service_uninstall = this.state.orderObject.service_uninstall;
      data.id = this.state.orderObject.id;
      saveOrder(false, data)
        .then((result) => {
          if (result.success) {
            this.setState({
              loading: false,
              errors: [],
            });

            if (this.state.callFrom === 'preplan') {
              this.props.handleSuccess('Saved Successfully');
            } else {
              alertMessage('Saved Successfully');
            }
            //   this.props.history.push({
            //     pathname: '/stops'+this.state.orderObject.nav_route_id,
            //   });
            // if (this.state.orderObject.nav_route_id) {
            //     window.location.href = '/stops/' + this.state.orderObject.nav_route_id;
            // } else {
            //     this.props.handleSuccess('Saved Successfully');
            // }
          } else {
            // alertMessage(result.errors[0], 'error', 10);
            this.setState({
              loading: false,
              errors: result.errors,
            });
          }
        });
    };


    render() {
      const { currentOrder, orderObject ,isMilitaryTime} = this.state;
      // Customer Details
      const cust_name = this.fomatString(`${currentOrder.customer_first_name} ${currentOrder.customer_last_name}`);
      const customer_phone_one = this.fomatString(currentOrder.customer_phone_one);
      const customer_phone_two = this.fomatString(currentOrder.customer_phone_two);

      const cust_email = this.fomatString(currentOrder.customer_email);

      // warhouse Details
      const wh_name = this.fomatString(currentOrder.wh_location.name);
      const wh_phone = this.fomatString(currentOrder.wh_location.phone);
      const wh_email = this.fomatString(currentOrder.wh_location.email);
      const wh_location_code = this.fomatString(currentOrder.wh_location.location_code);


      /* let cust_address = '';
           if (currentOrder.customer_address_line) {
             cust_address = currentOrder.customer_address_line;
         }
         if (currentOrder.customer_city) {
             cust_address += ", " + currentOrder.customer_city;
         }
         if (currentOrder.customer_state) {
             cust_address += ", " + currentOrder.customer_state;
         }
         if (currentOrder.customer_zipcode) {
             cust_address += ", " + currentOrder.customer_zipcode;
         }
         cust_address = cust_address.trim(", ");  */

      const warehouse_code = this.fomatString(currentOrder.warehouse_code);


      // Order Details
      const vin = '';
      const customer_order_number = this.fomatString(currentOrder.customer_order_delivery_timeorder_number);
      const scheduled_start_time = orderObject.scheduled_start_datetime ? moment.utc(orderObject.scheduled_start_datetime).format('LLL') : 'NA';
      const scheduled_end_time = orderObject.scheduled_end_datetime ? moment.utc(orderObject.scheduled_end_datetime).format('LLL') : 'NA';
      const actual_time = currentOrder.actual_start_datetime ? moment(new Date(currentOrder.actual_start_datetime)).format('LLL') : 'NA';
      const suggested_time = currentOrder.suggested_start_time ? moment(new Date(currentOrder.suggested_start_time)).format('LLL') : 'NA';
      const estimated_arrival_date_time = currentOrder.estimated_arrival_date_time ? moment(new Date(currentOrder.estimated_arrival_date_time)).format('LLL') : 'NA';

      const pickup_location_name = this.fomatString(currentOrder.pickup_location_name);
      const drop_location_name = this.fomatString(currentOrder.drop_location_name);
      const order_items_count = this.fomatString(currentOrder.order_items_count);
      const order_notes = this.fomatString(currentOrder.notes);

      const appt_set = this.fomatString(currentOrder.appt_set);
      const billing_number = this.fomatString(currentOrder.billing_number);
      const contact_customer = this.fomatString(currentOrder.contact_customer);
      const cr = this.fomatString(currentOrder.cr);
      const return_authorization = this.fomatString(currentOrder.return_authorization);

      // currentOrder.order_items=[];
      // currentOrder.order_items.push({
      //     id: "1",
      //     item_name:  this.fomatString(currentOrder.item_name),
      //     quantity:  this.fomatString(currentOrder.quantity),
      //     return_model:  this.fomatString(currentOrder.return_model_number),
      //     return_serial:  this.fomatString(currentOrder.new_serial_number),
      //     new_model:  this.fomatString(currentOrder.new_model_number),
      //     new_serial:  this.fomatString(currentOrder.return_serial_number),
      // });
      // const sorted_order_items = [];
      const sorted_order_items = currentOrder.order_items.map((order_item) => {
        const item = {
          id: order_item.id ? order_item.id : moment(),
          item_name: this.fomatString(order_item.item_name),
          quantity: this.fomatString(order_item.item_quantity),
          item_type: this.fomatString(order_item.item_type),
          // return_model: '',
          // return_serial: '',
          // new_model: this.fomatString(order_item.item_model),
          // new_serial: this.fomatString(order_item.serial_number),
          // services_ids: order_item.service_ids,
          item_model: this.fomatString(order_item.item_model),
          item_serial: this.fomatString(order_item.serial_number),
          service_load_opted: !!(order_item.service_load_opted && order_item.service_load_opted),
          service_load_completed: order_item.service_load_completed,
          service_unload_opted: !!(order_item.service_load_opted && order_item.service_load_opted),
          service_unload_completed: order_item.service_unload_completed,
          service_install_opted: !!(order_item.service_install_opted && order_item.service_install_opted),
          service_install_completed: order_item.service_install_completed,
          service_uninstall_opted: !!(order_item.service_uninstall_opted && order_item.service_uninstall_opted),
          service_uninstall_completed: order_item.service_uninstall_completed,
          signature: order_item.signature ? order_item.signature : '',
          pictures: order_item.pictures ? order_item.pictures : [],
          failed_notes: order_item.failed_notes,
          status: 'Pending',

        };
        if (item.service_load_opted || item.service_unload_opted || item.service_install_opted || item.service_uninstall_opted) {
          item.is_services_opted = true;
        }
        item.leg_details = order_item.leg_details ? order_item.leg_details : [];
        return item;
      });

      return (
        <div className="order_details_parent_div">
          <div style={{ float: 'right', marginTop: -50 }}>
            <Button className=" editOrderButton" onClick={this.props.handleCancel} >Cancel</Button>
            <Button type="primary" className="editOrderButton" onClick={this.handleSave} loading={this.state.loading}>
              Save
            </Button>
          </div>
          <Row>
            <Col sm={24}>
              {FormErrors(this.state.errors)}
            </Col>
          </Row>
          <Row>
            <Col sm={24} xs={24} md={8} lg={8} className="currentOrder_details">
              <Card size="small" title="Order Details">

                <Row>
                  <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details">
                    {/* <Row>
                                        <Col sm={2} xs={2} md={2} lg={2}>
                                            <div className="textCenter">
                                                <Icon type="calendar" />
                                            </div>
                                        </Col>
                                        <Col sm={10} xs={13} md={9} lg={9}>
                                            <div className="info-text textBold">Scheduled Time</div>


                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={11} xs={8} md={12} lg={12}>
                                            {
                                                schedule_time !== "NA" && <div className="info-text">
                                                    <span>{schedule_time}</span>
                                                </div>
                                            }
                                        </Col>
                                    </Row> */}
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}>
                        <div className="textCenter">
                          <Icon type="carry-out" />
                        </div>
                      </Col>
                      <Col sm={10} xs={13} md={9} lg={9}>
                        <div className="info-text textBold">RA</div>


                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={11} xs={8} md={12} lg={12}>
                        {
                          return_authorization !== 'NA' && <div className="info-text">
                            <span>{return_authorization}</span>
                          </div>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}>
                        <div className="textCenter">
                          <Icon type="number" />
                        </div>
                      </Col>
                      <Col sm={10} xs={13} md={9} lg={9}>
                        <div className="info-text textBold">BL</div>
                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={11} xs={8} md={12} lg={12}>
                        <div className="info-text">
                          <span>{billing_number}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}>
                        <div className="textCenter">
                          <Icon type="calendar" />
                        </div>
                      </Col>
                      <Col sm={10} xs={13} md={9} lg={9}>
                        <div className="info-text textBold">C/R</div>


                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={11} xs={8} md={12} lg={12}>
                        <div className="info-text">
                          <span>{cr}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}>
                        <div className="textCenter">
                          <Icon type="account-book" />
                        </div>
                      </Col>
                      <Col sm={10} xs={13} md={9} lg={9}>
                        <div className="info-text textBold">Contact Coustomer</div>


                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={11} xs={8} md={12} lg={12}>
                        <div className="info-text">
                          <span>{contact_customer}</span>
                        </div>
                      </Col>
                    </Row>

                    {appt_set !== 'NA' && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}>
                        <div className="textCenter">
                          <Icon type="radius-setting" />
                        </div>
                      </Col>
                      <Col sm={10} xs={13} md={9} lg={9}>
                        <div className="info-text textBold">Appt Set</div>


                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={11} xs={8} md={12} lg={12}>
                        <div className="info-text">
                          <span>{appt_set}</span>
                        </div>

                      </Col>
                    </Row>
                    }

                    {/* <Divider style={{ margin: "8px 0px" }} /> */}
                    {/* <Row >
                                        <Col sm={24} xs={24} md={2} lg={2}>
                                            <div className="textCenter" style={{ alignItems: "center" }}>
                                                {
                                                    <img src={`${pickupImg}`} style={{ width: 15 }} />
                                                }
                                            </div>
                                        </Col>
                                        <Col sm={24} xs={24} md={6} lg={6}>
                                            <div className="info-text textBold">Warehouse</div>
                                        </Col>
                                        <Col xs={1}>:</Col>
                                        <Col sm={24} xs={24} md={15} lg={15}>
                                            {
                                                <div className="info-text">
                                                    <span>Kenko</span>
                                                </div>
                                            }
                                        </Col>
                                    </Row> */}

                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}>
                        <div className="textCenter">
                          {/* {
                                                    <img src={`${calendar}`} style={{ width: 35 }} />
                                                } */}
                          <Icon type="profile" />
                        </div>
                      </Col>
                      <Col sm={10} xs={13} md={9} lg={9}>
                        <div className="info-text textBold">Notes</div>


                      </Col>
                      <Col xs={1}>:</Col>
                      <Col sm={11} xs={8} md={12} lg={12}>
                        <div className="info-text alignLeft">
                          <span>{order_notes}</span>
                        </div>
                      </Col>
                    </Row>


                  </Col>


                </Row>
                {/* <Divider style={{ margin: "2px 0px <14px 0px" }} />
              <div style={{ paddingLeft: 24 }}>
                <Row>
                  <Col sm={12} xs={12} md={24} lg={24} className="textBold font12">Notes : {this.fomatString(currentOrder.driver_notes)}</Col>
                </Row>{leg_details.leg_start_time ? moment(new Date(leg_details.leg_start_time.format('LLL'))) : 'Not Started'}
              </div> */}
              </Card>

            </Col>
            <Col sm={24} xs={24} md={8} lg={8} className="currentOrder_details">
              <Card size="small" title="Warehouse Details">
                <Row style={{ marginTop: 10, minHeight: 100 }}>
                  <Col sm={24} xs={24} md={8} lg={8}>
                    <div className="">
                      <img src={`${warehouse}`} style={{ width: 70 }} />
                    </div>
                  </Col>
                  <Col sm={24} xs={24} md={16} lg={16}>
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="bank" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}>
                        <div className="info-text textBold"> <Tooltip title="Warehouse Name">{wh_name}</Tooltip></div>
                      </Col>
                    </Row>
                    {wh_location_code !== 'NA' && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="tag" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{wh_location_code}</div></Col>
                    </Row>
                    }
                    {wh_email !== 'NA' && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="mail" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}> <div className="info-text">{wh_email}</div></Col>
                    </Row>
                    }
                    {wh_phone !== 'NA' && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="phone" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{wh_phone}</div></Col>
                    </Row>
                    }

                    {this.state.currentOrder.wh_location.l_address && Object.keys(this.state.currentOrder.wh_location.l_address).length > 0 && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="environment" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text alignLeft">
                        <AddressInfo address={this.state.currentOrder.wh_location.l_address} />
                      </div>
                      </Col>
                    </Row>
                    }


                  </Col>
                </Row>


              </Card>

            </Col>
            <Col sm={24} xs={24} md={8} lg={8} className="currentOrder_details">
              <Card size="small" title={I18n.t('customer.details')}>
                <Row style={{ marginTop: 10, minHeight: 100 }}>
                  <Col sm={24} xs={24} md={8} lg={8}>
                    <div className="driver-profile">
                      <Avatar src={`${customerlogo}`} size={70} />
                    </div>
                  </Col>
                  <Col sm={24} xs={24} md={16} lg={16}>
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="user" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text textBold">
                        <Tooltip title={I18n.t('customer.name')}>{cust_name}</Tooltip>
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="mail" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}>{cust_email !== 'NA' && <div className="info-text">{cust_email}</div>}</Col>
                    </Row>
                    {customer_phone_one !== 'NA' && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="phone" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{customer_phone_one}</div></Col>
                    </Row>
                    }
                    {customer_phone_two !== 'NA' && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="phone" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text">{customer_phone_two}</div></Col>
                    </Row>
                    }
                    {this.state.currentOrder.cs_location.l_address && Object.keys(this.state.currentOrder.cs_location.l_address).length > 0 && <Row>
                      <Col sm={2} xs={2} md={2} lg={2}><Icon type="environment" /></Col>
                      <Col sm={22} xs={22} md={22} lg={22}><div className="info-text alignLeft">
                        <AddressInfo address={this.state.currentOrder.cs_location.l_address} />
                      </div>
                      </Col>
                    </Row>
                    }
                  </Col>
                </Row>


              </Card>

            </Col>

            {/* <span style={{ color: "white", float: "right", marginTop: "-20px" }}>{currentOrder.id}</span> */}

          </Row>
          <Row>
            <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details" >

              <Row>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5">
                  <FormItem label='Scheduled Start Date'>
                    <DatePicker
                      style={{ width: '100%', marginLeft: '1%' }}
                      placeholder="Select Date"

                      format='Do MMM YYYY'
                      onChange={e => this.handleCurrentOrderDateChange('scheduled_start_datetime', e)}
                      value={this.state.orderObject.scheduled_start_datetime ? moment(new Date(this.state.orderObject.scheduled_start_datetime)) : null}
                      defaultValue={null}
                      onOk={this.onOk}

                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5">
                  <FormItem label='Scheduled Start Time'
                    className="timepickerContainer">
                    <MaterialTimePicker
                      style={ { width: '100%', marginLeft: '1%' } }
                      value={ this.state.orderObject.scheduled_start_datetime ? moment(new Date(this.state.orderObject.scheduled_start_datetime)) : null }
                      onChange={ e => this.handleCurrentOrderDateChange('scheduled_start_datetime', e) }
                      computeDisabledHours={ () => [] }
                      computeDisabledMinutes={ () => [] }
                      isMilitaryTime={ isMilitaryTime }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5" >
                  <FormItem label='Scheduled End Date'>
                    <DatePicker
                      style={{ width: '100%', marginLeft: '1%' }}
                      placeholder="Select Time"

                      format='Do MMM YYYY'
                      onChange={e => this.handleCurrentOrderChange('scheduled_end_datetime', e)}
                      value={this.state.orderObject.scheduled_end_datetime ? moment(this.state.orderObject.scheduled_end_datetime) : null}
                      defaultValue={null}
                      onOk={this.onOk}

                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5">
                  <FormItem label='Scheduled End Time'
                    className="timepickerContainer">
                    <MaterialTimePicker
                      style={ { width: '100%', marginLeft: '1%' } }
                      value={ this.state.orderObject.scheduled_end_datetime ? moment(this.state.orderObject.scheduled_end_datetime) : null }
                      onChange={ e => this.handleCurrentOrderChange('scheduled_end_datetime', e) }
                      computeDisabledHours={ () => [] }
                      computeDisabledMinutes={ () => [] }
                      isMilitaryTime={ isMilitaryTime }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5" >
                  <FormItem label='Estimated Arrival Date' >
                    {estimated_arrival_date_time}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5" >
                  <FormItem label='Estimated Arrival Time' >
                    {estimated_arrival_date_time}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5" >
                  <FormItem label='Actual Arrival Date' >
                    {actual_time}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} lg={6} md={6} className="padding5" >
                  <FormItem label='Actual Arrival Time' >
                    {actual_time}
                  </FormItem>
                </Col>
              </Row>

            </Col>
          </Row>
          {/* <Row>
                    <Col xs={24} sm={24} md={24} lg={24} style={{ paddingLeft: "20px" }}>
                        <Row>
                            <Col xs={2} className="textBold">
                                Services
                            </Col>
                            <Col xs={3}>

                                <Checkbox
                                    onChange={(e) => this.onCheckBoxChange(e, 'service_load')}
                                    checked={this.state.orderObject.service_load}
                                />
                                &nbsp;
                                Load
                            </Col>
                            <Col xs={3}>

                                <Checkbox
                                    onChange={(e) => this.onCheckBoxChange(e, 'service_unload')}
                                    checked={this.state.orderObject.service_unload}
                                />
                                &nbsp;
                                UnLoad
                            </Col>
                            <Col xs={3}>

                                <Checkbox

                                    onChange={(e) => this.onCheckBoxChange(e, 'service_install')}
                                    checked={this.state.orderObject.service_install}
                                />
                                &nbsp;
                                Install
                            </Col>
                            <Col xs={3}>

                                <Checkbox

                                    onChange={(e) => this.onCheckBoxChange(e, 'service_uninstall')}
                                    checked={this.state.orderObject.service_uninstall}
                                />
                                &nbsp;
                                Uninstall
                            </Col>
                        </Row>

                    </Col>
                </Row> */}
          {/* <Row style={{ margin: 10, minHeight: 100 }}>
                    <Col sm={24} xs={24} md={24} lg={24}>
                        <h4>Item Details</h4>
                        <ItemsList data={sorted_order_items} pagination={{ position: 'none' }} size={'small'} />
                    </Col>
                </Row> */}

          <Row style={{ margin: 10 }}>
            <Col sm={24} xs={24} md={24} lg={24} className="currentOrder_details">
              <h4>Item Details</h4>

              {
                currentOrder.order_items.length > 0 && currentOrder.order_items.map((item, index) => (<Col lg={12} md={12} sm={24} xs={24} className="padding5">
                  <ItemDetails order_item={item} isManage servceChange={(e, element) => this.onCheckBoxChange(e, element)} />
                                                                                                      </Col>))
              }
            </Col>
          </Row>
          <Row>
            <Col xs={8} className="currentOrder_details">
              {(currentOrder.driver_details && currentOrder.driver_details.length > 0) && (currentOrder.driver_details[0].id) &&
              <Card size="small" title={I18n.t('users.driver.assigned')}>
                <Row>
                  <Col xs={24} style={{ paddingTop: 10 }}>

                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7}>
                        <div className="driver-profile">
                          {
                            currentOrder.driver_details[0].image && (currentOrder.driver_details[0].image.url) ?
                              <Avatar src={`${currentOrder.driver_details[0].image.url}`} size={70} /> : <Avatar src="./../icons/user_100*100.png" alt="DriverProfile" size={70} />
                          }

                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={17} lg={17} className="padding5">
                        <Col>
                          <Col>
                            <div className="info-text" style={{ fontWeight: 'bold', marginBottom: '5px' }}>

                              {currentOrder.driver_details[0].first_name ?
                                (currentOrder.driver_details[0].first_name) : 'NA'
                              }
                            </div>
                          </Col>
                          <Col>
                            <div className="info-text">
                              <h4><Icon type="mobile" /></h4>
                              {currentOrder.driver_details[0].mobile_number ?
                                (currentOrder.driver_details[0].mobile_number) : 'NA'
                              }
                            </div>
                          </Col>
                          <Col>
                            <div className="info-text">
                              <h4><Icon type="mail" /></h4>
                              {currentOrder.driver_details[0].email ?
                                (currentOrder.driver_details[0].email) : 'NA'
                              }
                            </div>
                          </Col>
                        </Col>
                      </Col>
                    </Row>


                  </Col>
                </Row>
              </Card>
              }
            </Col>
          </Row>


        </div>
      );
    }
}
EditOrderDetails.propTypes = {
  currentOrder: PropTypes.shape().isRequired,
};

EditOrderDetails.defaultPropTypes = {
  currentOrder: {},
};

const EditOrderDetailsComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <EditOrderDetails
      // warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default EditOrderDetailsComponent;

