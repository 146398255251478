import { Alert, Button, Col, Icon, Row, Select, Table } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useContext, useEffect } from "react";
import ReactDragListView from "react-drag-listview";
import { setGridConfigSetting } from "../../api/DisplaySettings";
import { alertMessage } from "../../common/Common";
import { DisplaySettingsContext } from "../../context/DisplaySettings";
import userStore from "../../stores/UserStore";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";
import { OrgContext } from "../../context/OrgContext";

export const RearrangableTable = (props) => {
  const {
    columns : rawCols,
    filteredColumns,
    isLoading,
    data,
    madeChanges,
    selectedColumns : rawSelectedColumns,
    setSelectedColumns,
    setFilteredColumns,
    setMadeChanges,
    showDragIconForSelectedColumns,
    controlDrag,
    configType,
    setIsLoading,
    showNotes
  } = props;

  const { userConfiguration } = useContext(DisplaySettingsContext);
  const { orgConfiguration } = useContext(DisplaySettingsContext);
  const { currentOrg } = useContext(OrgContext);
  const isAirCargo = currentOrg.carrier_type === 'AIR_CARGO';

  const columns = rawCols.map((col) => {
    if (isAirCargo && col.key === "consignee") {
      return {
        ...col,
        key: "shipper",
        title: "Shipper"
      };
    }
    return col;
  });

  const selectedColumns = rawSelectedColumns.map((col) => { 
    if (isAirCargo && col === "consignee") {
      return "shipper";
    }
    return col;
  });

  const path = window.location.href;


  const applyConfiguration = (configuration, configType, selectedColumns, columns) => {
    if (!_.isEmpty(configuration) && configType in configuration) {
      const configValue = configuration[ configType ] || [];
      const removedNullValues = isAirCargo ? configValue.map((key) => key === "consignee" ? "shipper" : key).filter((key) => selectedColumns.includes(key)) : configValue.filter((key) => selectedColumns.includes(key)); 
      setSelectedColumns(removedNullValues);
      setFilteredColumns(
        _.compact(removedNullValues.map((key) => columns.find((col) => col.key === key)))
      );
    }
  };

  useEffect(() => {
    if (path.includes("displaySettings")) {
      applyConfiguration(userConfiguration, configType, selectedColumns, columns);
    } else {
      applyConfiguration(orgConfiguration, configType, selectedColumns, columns);
    }
  }, [ path, userConfiguration, orgConfiguration, configType ]);

  const onDragEnd = (fromIndex, toIndex) => {
    if (controlDrag && (fromIndex === 0 || toIndex === 0)) {
      return false;
    } 
    const item = selectedColumns.splice(fromIndex, 1)[0];
    const newSelectedColumns = [...selectedColumns];
    newSelectedColumns.splice(toIndex, 0, item);
    setSelectedColumns(newSelectedColumns);
    setFilteredColumns(
      newSelectedColumns.map((key) => columns.find((col) => col.key === key))
    );
    setMadeChanges(true);
  };
  

  const dragProps = {
    onDragEnd,
    nodeSelector: "th",
  };

  const handleChange = (selectedKeys) => {
    setSelectedColumns(selectedKeys);
    setFilteredColumns(
      selectedKeys.map((key) => columns.find((col) => col.key === key))
    );
    setMadeChanges(true);
  };

  const handleCancel = () => {
    setMadeChanges(true);
    const newSelectedColumns = columns.map((col) => col.key);
    setSelectedColumns(newSelectedColumns);
    setFilteredColumns(
      columns.filter((col) => newSelectedColumns.includes(col.key))
    );
  };

  const handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    const userId = userStore.getStateValue("id");
    setIsLoading(true);
      if (showNotes && selectedColumns.length > 10) {
      alertMessage("Number of columns exceeds 10. Please review.", "error", 10);
      setIsLoading(false);
      return;
    }
  
    const data = {
      organization_id: orgId,
      config_type: configType,
      config_value: selectedColumns.map((col) => col === "shipper" ? "consignee" : col),
    };

    if (path.includes("displaySettings")) {
      data.user_id = userId;
    }
    setGridConfigSetting(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"), "success", 10);
          if (path.includes("displaySettings")) {
            props.dispContext("userConfiguration", {
              ...userConfiguration,
              [configType]: [...selectedColumns],
            });
          } else {
            props.dispContext("orgConfiguration", {
              ...orgConfiguration,
              [configType]: [...selectedColumns],
            });
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
        setMadeChanges(false);
      });
  };

  return (
    <div style={{ margin: 20 }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Text strong>Select Columns to Display</Text>
            </Col>
            {showNotes && (
            <Col style={{textAlign:"right"}}span={12}>
            <Text strong>Note :</Text>
            <Text style={{color:"red"}}> You Can Select 10 Columns Only</Text>
            </Col>
            )}
            <Col span={24}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select columns to display"
                onChange={handleChange}
                defaultValue={selectedColumns}
                value={selectedColumns}
              >
                {columns
                  .filter((col) => !_.isEmpty(col))
                  .map((col) => (
                    <Select.Option key={col.key} value={col.key}>
                      {col.title}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text strong>Display View</Text>
            </Col>
            <Col span={24}>
              <Alert
                message={I18n.t("order.drag_column")}
                type="info"
                showIcon
              />
            </Col>
            <Col span={24}>
              <ReactDragListView.DragColumn {...dragProps}>
                <Table
                  scroll={{ x: "max-content" }}
                  className="draggable-table-header order-list-table"
                  columns={filteredColumns
                    .filter((col) => !_.isEmpty(col))
                    .map((col, index) => ({
                      ...col,
                      title: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {col.title}
                          {showDragIconForSelectedColumns && index > 0 && (
                            <Icon type="drag" style={{ marginLeft: 8 }} />
                          )}
                          {!showDragIconForSelectedColumns && (
                            <Icon type="drag" style={{ marginLeft: 8 }} />
                          )}
                        </div>
                      ),
                    }))}
                  pagination={false}
                  dataSource={data}
                  bordered
                />
              </ReactDragListView.DragColumn>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="buttonsList">
        <Col>
          <Button
            loading={isLoading}
            className="saveButton"
            type="primary"
            onClick={handleSave}
            disabled={!madeChanges}
            icon={"save"}
          >
            Save Changes
          </Button>
        </Col>
        <Col>
          <Button
            className="cancelButton"
            variant="danger"
            onClick={handleCancel}
            disabled={!madeChanges}
            icon={"close"}
          >
            Reset To Default
          </Button>
        </Col>
      </Row>
    </div>
  );
};
