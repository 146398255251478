/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Col,
  FormItem,
  Input,
  InputNumber,
  Row,
  Checkbox,
  Radio,
  SideBySideFormItem,
} from "../../../common/UIComponents";
import {
  alertMessage,
  checkWithinRange,
  isEmpty,
  upperFirst,
} from "../../../common/Common";
import FormButtons from "../../common/FormButtons";
import FormErrors from "../../common/FormErrors";
import AppConfig from "../../../config/AppConfig";
import I18n from "../../../common/I18n";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRatecard: this.props.currentRatecard || {},
      inProgress: false,
      errors: [],
      weights: this.props.weights || [],
      aboveBandFrom: false,
      // applyAdditional:this.props.applyAdditional,
      freePallets: this.props.freePallets,
    };
  }

  setInitialValues = () => {
    const { currentRatecard } = this.state;
    const { isEditing } = this.props;
    // // new los
    const { los_range } = currentRatecard;
    currentRatecard.range_type = "PER";
    if (los_range === "CABINET") {
      if (
        !isEmpty(currentRatecard.apply_cwt) &&
        currentRatecard.apply_cwt !== "additional_cb"
      ) {
        this.handleOnChange("apply_cwt", "");
      }
    }

    if (los_range === "WEIGHT") {
      if (
        !isEmpty(currentRatecard.apply_cwt) &&
        currentRatecard.apply_cwt === "additional_cb"
      ) {
        this.handleOnChange("apply_cwt", "");
      }
      if (!isEditing) {
        this.handleOnChange("range_type", "false");
      }
    }

    if (!isEditing) {
      if (los_range === "CABINET") {
        this.handleOnChange("apply_cwt", "");
      } else if (los_range === "HOURS") {
        this.handleOnChange("apply_cwt", "");
      } else if (los_range === "MILES") {
        this.handleOnChange("apply_cwt", "");
      } else if (los_range === "PER_CUBE") {
        this.handleOnChange("apply_cwt", "");
      }else if (los_range === "PIECES") {
        this.handleOnChange("apply_cwt", "")
      }else {
        this.handleOnChange("apply_cwt", "complete_wt");
      }
    }
  }

  componentDidMount() {
    const { currentRatecard } = this.state;
    if (currentRatecard.max_weight === AppConfig.rangeMaxValue) {
      // this.handleStateChange("aboveBandFrom", true);
      this.setState({
        aboveBandFrom: true,
        currentRatecard: {
          ...this.state.currentRatecard,
          max_weight: this.state.currentRatecard?.min_weight || '',
        }
      }, () => {
        this.setInitialValues();
      });
    } else {
      this.setInitialValues()
    }
  }

  clearForm = () => {
    this.setState({
      currentRatecard: {
        los_range: this.state.currentRatecard.los_range || "WEIGHT",
        range_type: this.state.currentRatecard.range_type || "PER",
      },
      isNew: true,
    });
  };

  handleOnChange = (element, value) => {
    this.setState(
      {
        currentRatecard: Object.assign({}, this.state.currentRatecard, {
          [element]: value,
        }),
      },
      () => {
        if (
          this.state.currentRatecard?.los_range === "WEIGHT" &&
          element === "range_type" &&
          value === "true"
        ) {
          this.handleOnChange("apply_cwt", "");
        }
        // this.props.handleReceiveData(this.state.currentRatecard);
      }
    );
  };

  handleStateChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      if (element === "aboveBandFrom") {
        if (value === true) {
          this.handleOnChange(
            "max_weight",
            this.state.currentRatecard.min_weight
              ? this.state.currentRatecard.min_weight
              : ""
          );
        } else {
          this.handleOnChange("max_weight", "");
        }
      }
    });
  };

  handleSave = () => {
    const { currentRatecard, aboveBandFrom, applyAdditional, freePallets } =
      this.state;
    const data = { ...currentRatecard, applyAdditional, freePallets };
    if (
      currentRatecard.los_range === "PALLET" ||
      currentRatecard.los_range === "ITEMS" ||
      currentRatecard.los_range === "PER_CUBE" ||
      currentRatecard.los_range === "HOURS" ||
      currentRatecard.los_range === "MILES" ||
      currentRatecard.los_range === "PIECES"
    ) {
      data.apply_cwt = "";
    }
    if (currentRatecard.los_range === "PALLET") {
    }
    if (currentRatecard.los_range !== "WEIGHT") {
      data.range_type = currentRatecard?.range_type
        ? currentRatecard.range_type
        : "PER";
    }
    if (currentRatecard.los_range === "WEIGHT") {
      data.range_type = currentRatecard?.range_type
        ? currentRatecard.range_type
        : "false";
    }
    if (
      !isEmpty(data.min_weight) &&
      (!isEmpty(data.max_weight) || aboveBandFrom)
    ) {
      const minWeight = parseFloat(data.min_weight);
      if (aboveBandFrom) {
        data.max_weight = AppConfig.rangeMaxValue;
      }
      const maxWeight = parseFloat(data.max_weight);
      if (minWeight > maxWeight) {
        alertMessage(
          I18n.t("messages.min_value_should_greate_than_max"),
          "error",
          "5"
        );
      } else {
        const weights = !isEmpty(this.state.weights) ? this.state.weights : [];
        const isExisted = checkWithinRange(
          weights,
          "min_weight",
          "max_weight",
          minWeight || 0,
          maxWeight || 0
        );
        if (isExisted) {
          alertMessage(
            I18n.t("messages.given_vales_are_existing_in_another_range"),
            "error",
            "5"
          );
        } else {
          this.props.handleSave(data);
        }
      }
    } else {
      alertMessage(I18n.t("errors.weights"), "error", "5");
    }
  };

  computeRangeFieldTitle = (losRange, isMin) => {
    switch (losRange) {
      case "WEIGHT":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_weight")} (${
              AppConfig.weight_unit
            })`
          : `${I18n.t("account.billing.ratecard.max_weight")} (${
              AppConfig.weight_unit
            })`;
      case "PALLET":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_pallets")}`
          : `${I18n.t("account.billing.ratecard.max_pallets")}`;
      case "CABINET":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_cabinets")}`
          : `${I18n.t("account.billing.ratecard.max_cabinets")}`;
      case "HOURS":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_hours")}`
          : `${I18n.t("account.billing.ratecard.max_hours")}`;
      case "MILES":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_miles")}`
          : `${I18n.t("account.billing.ratecard.max_miles")}`;
      case "ITEMS":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_order_items")}`
          : `${I18n.t("account.billing.ratecard.max_order_items")}`;
      case "PER_CUBE":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_weight")}`
          : `${I18n.t("account.billing.ratecard.max_weight")}`;
      case "PIECES":
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_item_piece")}`
          : `${I18n.t("account.billing.ratecard.max_item_piece")}`;
      default:
        return isMin
          ? `${I18n.t("account.billing.ratecard.min_weight")} (${
              AppConfig.weight_unit
            })`
          : `${I18n.t("account.billing.ratecard.max_weight")} (${
              AppConfig.weight_unit
            })`;
    }
  };

  render() {
    const { currentRatecard } = this.state;
    const isPalletRange = currentRatecard.los_range === "PALLET";
    const isCabinetRange = currentRatecard.los_range === "CABINET";
    const isHoursRange = currentRatecard.los_range === "HOURS";
    const isMilesRange = currentRatecard.los_range === "MILES";
    const isOrderItemsRange = currentRatecard.los_range === "ITEMS";
    const isPerCubeRange = currentRatecard.los_range === "PER_CUBE";
    const isPerPieceRange = currentRatecard.los_range === "PIECES";
    const isWeightRange = currentRatecard.los_range === "WEIGHT";

    const cwt_fields = {
      complete_wt: {
        fieldName: isCabinetRange ? "additional_cb" : "complete_wt",
      },
      additional_wt: {
        fieldName: isCabinetRange ? "additional_cb" : "additional_wt",
      },
    };
    return (
      <div className="marginTop10">
        <Row gutter={16}>
          <Col xs={24}>
            <Row gutter={16} className="marginTop10">
              <Col sm={24} xs={24} md={24} lg={24}>
                <FormItem
                  label={this.computeRangeFieldTitle(
                    currentRatecard.los_range,
                    true
                  )}
                  require
                >
                  <InputNumber
                    value={currentRatecard.min_weight}
                    onChange={(e) => this.handleOnChange("min_weight", e)}
                    min={0}
                    precision={0}
                    className="width100Per"
                  />
                </FormItem>
              </Col>
              <Col md={isPalletRange ? 24 : 12}>
                <Row>
                  <Col xs={isPalletRange ? 12 : 24}>
                    <Row>
                      <Checkbox
                        onChange={(e) =>
                          this.handleStateChange(
                            "aboveBandFrom",
                            e.target.checked
                          )
                        }
                        checked={this.state.aboveBandFrom}
                        style={{ marginTop: "5px" }}
                      />
                      &nbsp;
                      <span>
                        {" "}
                        {this.state.aboveBandFrom &&
                        !isEmpty(currentRatecard.min_weight)
                          ? `${currentRatecard.min_weight} or above`
                          : `Min ${upperFirst(
                              currentRatecard.los_range === "PIECES"
                                ? "PIECES"
                                : currentRatecard.los_range
                            )} from or above`}{" "}
                      </span>
                    </Row>
                  </Col>
                </Row>

                {isWeightRange && (
                  <Row className="marginTop8">
                    <Checkbox
                      onChange={(e) =>
                        this.handleOnChange(
                          "range_type",
                          e.target.checked ? "true" : "false"
                        )
                      }
                      checked={
                        currentRatecard?.range_type === "true" ? true : false
                      }
                      style={{ marginTop: "5px" }}
                    />{" "}
                    &nbsp;<span> Per Pound </span>
                  </Row>
                )}
              </Col>
              {!isOrderItemsRange &&
                !isPalletRange &&
                !isHoursRange &&
                !isMilesRange &&
                !isPerCubeRange &&
                !isPerPieceRange && (
                  <Col xs={12}>
                    <Checkbox
                      onChange={(e) =>
                        this.handleOnChange(
                          "apply_cwt",
                          e.target.checked
                            ? cwt_fields.complete_wt.fieldName
                            : ""
                        )
                      }
                      checked={
                        currentRatecard?.apply_cwt
                          ? currentRatecard.apply_cwt.length
                          : false
                      }
                      style={{ marginTop: "5px" }}
                      disabled={currentRatecard.range_type === "true"}
                    />
                    &nbsp;
                    <span>
                      {isCabinetRange
                        ? `${I18n.t("los.additional_cb")}`
                        : isHoursRange
                        ? `${I18n.t("los.additional_hr")}`
                        : `${I18n.t("general.apply")} ${I18n.t(
                            "account.billing.cwt_label"
                          )}`}
                      {/* { isHoursRange ? `${I18n.t("los.additional_hr")}` : `${I18n.t('general.apply')} ${I18n.t('account.billing.cwt_label')}`} */}
                    </span>
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("apply_cwt", e.target.value)
                      }
                      value={currentRatecard.apply_cwt}
                      style={{ marginTop: 3 }}
                      disabled={currentRatecard.range_type === "true"}
                    >
                      {!isCabinetRange && !isHoursRange && !isMilesRange && (
                        <>
                          <Radio value={cwt_fields.complete_wt.fieldName}>
                            {isCabinetRange
                              ? I18n.t("los.complete_cb")
                              : isHoursRange
                              ? `${I18n.t("los.additional_hr")}`
                              : I18n.t("los.complete_wt")}
                          </Radio>
                          <Radio value={cwt_fields.additional_wt.fieldName}>
                            {isCabinetRange
                              ? I18n.t("los.additional_cb")
                              : isHoursRange
                              ? `${I18n.t("los.additional_hr")}`
                              : I18n.t("los.additional_wt")}
                          </Radio>
                        </>
                      )}
                    </Radio.Group>
                  </Col>
                )}
              {isCabinetRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.cabinet_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">
                        {I18n.t("los.per", {
                          field: I18n.t("general.cabinet"),
                        })}
                      </Radio>
                      <Radio value="RANGE">
                        {I18n.t("los.range_type", {
                          field: I18n.t("general.cabinet"),
                        })}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              {isHoursRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.hours_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">
                        {I18n.t("los.per", { field: I18n.t("general.hours") })}
                      </Radio>
                      <Radio value="RANGE">
                        {I18n.t("los.range_type", {
                          field: I18n.t("general.hours"),
                        })}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              {isMilesRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.miles_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">
                        {I18n.t("los.per", { field: I18n.t("general.miles") })}
                      </Radio>
                      <Radio value="RANGE">
                        {I18n.t("los.range_type", {
                          field: I18n.t("general.miles"),
                        })}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              {isPalletRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.pallet_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">
                        {I18n.t("los.per", { field: I18n.t("general.pallet") })}
                      </Radio>
                      <Radio value="RANGE">
                        {I18n.t("los.range_type", {
                          field: I18n.t("general.pallet"),
                        })}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              {isPalletRange && currentRatecard?.range_type == "PER" && (
                <Col xs={24}>
                  <Row>
                    <Col xs={12}>
                      <Checkbox
                        onChange={(e) =>
                          this.handleOnChange(
                            "apply_additional",
                            e.target.checked ? true : false
                          )
                        }
                        checked={currentRatecard.apply_additional}
                        style={{ marginTop: "5px" }}
                      />
                      &nbsp;<span> Apply Additional Pallet </span>&nbsp;&nbsp;
                    </Col>
                    {currentRatecard.apply_additional && (
                      <Col xs={16} style={{ marginTop: "10px" }}>
                        {" "}
                        {/* Add margin to create space */}
                        <SideBySideFormItem label={"Free Pallets"} require>
                          <InputNumber
                            value={currentRatecard.free_pallets || 0}
                            onChange={(e) => {
                              this.handleOnChange("free_pallets", e);
                            }}
                            min={0}
                            precision={0}
                            className="width100Per"
                            size="small"
                            style={{ width: 100 }}
                          />
                        </SideBySideFormItem>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              {isOrderItemsRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.order_item_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">
                        {I18n.t("los.per", {
                          field: I18n.t("los.order_item_type"),
                        })}
                      </Radio>
                      <Radio value="RANGE">
                        {I18n.t("los.range_type", {
                          field: I18n.t("los.order_item_type"),
                        })}
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              {isPerCubeRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.per_cube_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">{I18n.t("los.per_dim_weight")}</Radio>
                      <Radio value="ALL">{I18n.t("los.all_weight")}</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              {isPerPieceRange && (
                <Col sm={24} xs={24}>
                  <FormItem
                    style={{
                      display: "flex",
                      gap: "5px",
                      margin: "10px 0",
                    }}
                    label={I18n.t("los.per_piece_type")}
                    require
                  >
                    <Radio.Group
                      onChange={(e) =>
                        this.handleOnChange("range_type", e.target.value)
                      }
                      value={
                        currentRatecard?.range_type
                          ? currentRatecard.range_type
                          : "PER"
                      }
                    >
                      <Radio value="PER">
                        {I18n.t("los.per_piece_weight")}
                      </Radio>
                      <Radio value="ALL">{I18n.t("los.all_piece")}</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}
              <Col sm={24} xs={24} md={24} lg={24}>
                <FormItem
                  label={this.computeRangeFieldTitle(
                    currentRatecard.los_range,
                    false
                  )}
                  require
                >
                  {!this.state.aboveBandFrom ? (
                    <InputNumber
                      value={currentRatecard.max_weight}
                      onChange={(e) => this.handleOnChange("max_weight", e)}
                      min={0}
                      precision={0}
                      className="width100Per"
                    />
                  ) : (
                    <Input
                      value={
                        !isEmpty(currentRatecard.min_weight)
                          ? `${currentRatecard.min_weight} or above`
                          : ""
                      }
                      className="width100Per"
                      disabled
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.clearForm,
              true,
              true,
              "default",
              I18n.t("general.save"),
              I18n.t("general.clear")
            )}
          </Col>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

Form.propTypes = {
  handleSave: PropTypes.func.isRequired,
  weights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isEditing: false,
};

export default Form;
