// import React from "react";
import React, { Fragment } from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import {
  Icon,
  Link,
  Button,
  Spin,
  Row,
  Col,
  Tooltip,
  Tag,
  Badge,
} from "../../../common/UIComponents";
import BaseModal from "../../BaseModal";

// import { Col, Icon, Popconfirm, Row, Tooltip } from "antd";
import { getFileIcon, getFileType, isEmpty } from "../../../common/Common";
import { TableImageColumn } from "../../../common/TableImageColumn";

// import Copyable from "../../common/Copyable";
import CustomerOrderDetails from "../../orders/CustomerOrderDetails";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import ReasonTable from "./ReasonTable";
import AppConfig from "../../../config/AppConfig";
import DisplayTime from "../../common/DisplayTime";


class DmsCombinedList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      showPdfModal: false,
      modalContentUrl: '',
      loadingPdf: false,
    };
    const tootlTipDelay = AppConfig.tooltipDelay;
    this.columns = [];
    this.setColumns(props)
  }

  setColumns = (props) => {
    this.columns = [
      {
        key: "File",
        title: "Documents",
        dataIndex: "picture_url",
        className: "noWrap",
        render: (data, record) => {
          const url = record.picture_url ? record.picture_url : "";
          const fileType = getFileType(url);

          return (
            <span>
              {url ? (
                fileType === "image" ? (
                  <TableImageColumn
                    record={url}
                    alt="org logo"
                    width="100"
                    height="30"
                  />
                ) : (
                  <Fragment>
                    <Link onClick={() => window.open(url, "_blank")}>
                      <Icon type={getFileIcon(fileType)} />
                      {this.getFileName(record)}
                      {/* <Tooltip title={this.getFileName(record)}> {this.getFileName(record)}</Tooltip> */}
                      &nbsp;&nbsp;&nbsp;
                      <Icon type="download" />
                    </Link>
                  </Fragment>
                )
              ) : (
                "--"
              )}
            </span>
          );
        },
        width: 140,
      },

      {
        key: "customer_order_number",
        title: I18n.t("dms.order_number"),
        dataIndex: "customer_order_number",
        align: "left",
        width: 90,
        render: (text, record) => (
          <div>
            {record.status === "success" &&
            record.customer_order_number &&
            record.customer_order_number !== "NA" ? (
              <CustomerOrderDetails
                order_no={text}
                order={{
                  id: record.customer_order_id,
                  customer_order_number: text,
                }}
                showEdit={false}
              />
            ) : (
              // If Statuss is not 'success', render an empty component or placeholder
              "--" // You can also render some other component or text here if needed
            )}
          </div>
        ),
      },
      {
        key: "hawb",
        title: I18n.t("dms.hawb"),
        dataIndex: "hawb",
        width: 115,        
        render: (data, record) => (
          <div>
            {record.status === "success" &&
            record.hawb &&
            record.hawb !== "NA" ? (
              <OrderFieldDisplay
                data={{
                  ...record,
                  id: record.customer_order_id,
                  customer_order_number: record.customer_order_number,
                }}
                toDisplay="hawb"
                showTriggerEdit={false}
                showEditIcon={false}
              />
            ) : (
              "--"
            )}
          </div>
        ),
      },
      {
        key: "mawb",
        title: I18n.t("dms.mawb"),
        dataIndex: "mawb",
        render: (data, record) => (
          <div>
            {record.status === "success" &&
            record.mawb &&
            record.mawb !== "NA" ? (
              <OrderFieldDisplay
                data={{
                  ...record,
                  id: record.customer_order_id,
                  customer_order_number: record.customer_order_number,
                }}
                toDisplay="mawb"
                showTriggerEdit={false}
                showEditIcon={false}
              />
            ) : (
              "--"
            )}
          </div>
        ),
        width: 115,
      },
      {
        key: "updated_time",
        title: "Updated at",
        dataIndex: "updated_time",
        align: "left",
        width: 100,
        render: (data) => {
          return (
            <span>
              <DisplayTime
              dateTimeString={data}
              displayWithDate={true}
              isUTC={true}
              tz_short_name="UTC"
              />
            </span>
          );
        },
      },
      {
        key: "document_type",
        title: "Document Type",
        dataIndex: "document_type",
        width: 70,
        render: (data, record) => <span>{data}</span>,
      },

      {
        key: "count",
        title: "Count",
        dataIndex: "count",
        width: 60,
        render: (data, record) => <span>{data}</span>,
      },

      // {
      //         key: "status",
      //         title: "Status",
      //         dataIndex: "status",
      //         // render: (data, record) => <span>{data}</span>,
      //         render: (data, record) => (
      //           <span>
      //             {record.status === 'failure' ? (
      //               <Icon type="close-circle" style={{ color: 'red' }} />
      //             ) : (
      //               <Icon type="check-circle" style={{ color: 'green' }} />
      //             )}
      //             {record.status === 'failure' ? ' fail' : ' success'}
      //           </span>
      //         )
      //       },

      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        width:70,
        render: (data, record) => (
          <Tag
            color={
              record.status === "failure"
                ? "#f22c2c"
                : "#138a13"
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 4, color: record.status === "failure" ? "white" : "inherit" }}>
                {record.status === "failure" ? (
                  <Icon type="close-circle" style={{ color: "white" }} />
                ) : (
                  <Icon type="check-circle" style={{ color: "white" }} />
                )}
              </div>
              <span style={{ color: record.status === "failure" ? "white" : "inherit" }}>
                {record.status === "failure" ? "Fail" : "Success"}
              </span>
            </div>
          </Tag>
        )
      },

      // {
      //   key: "reason",
      //   title: "Reason",
      //   dataIndex: "reason",
      //   render: (data, record) => <span>{data}</span>,
      // },
      {
        key: "reason",
        title: (
          <Fragment>
            <Row>
              <Col className="alignCenter">
                <Tooltip
                  // mouseEnterDelay={tootlTipDelay}
                  title="Reason"
                >
                  Reason
                </Tooltip>
              </Col>
              {/* <Col className="alignCenter"> ({AppConfig.weight_unit})</Col> */}
            </Row>
          </Fragment>
        ),

        dataIndex: "reason",
        render: (data, record) => {
          return (
            <Fragment>
              {!isEmpty(data) && data.length > 0 ? (
                <ReasonTable
                  reasons={record.reason || []}
                  // reasons={Array.isArray(record.reason) ? record.reason : []}
                  status={record.status || ""}
                  key={record.picture}
                  keyId={`ro${record.picture}`}
                  itemsRequired={true}
                  selectedPages={record?.selectedPages || []}
                  handleSelectChange={(keys, rows) => this.props.handleReasonsSelection(keys, rows, record.id, this.props.data)}
                >
                  {record.status === "failure" && record?.reason?.length ? (
                    <Fragment>
                      <Badge count={record.reason.length}/>
                      {/* <img
                          src={labelInfo}
                          alt="labelInfo"
                          className="labelInfoImage"
                        /> */}
                    </Fragment>
                    
                  ) : (
                    ""
                  )}
                </ReasonTable>
              ) : <div style={{paddingLeft: "9px"}}>--</div>

              }
            </Fragment>
          );
        },
        width: 45,
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
 

  openPDFpreview = (url) => {
    this.setState({ showPdfModal: true, url });
  };

  getFileName = (record) => {
    if (record?.picture !== "N/A") {
      return record.picture;
    } else {
      return "File";
    }
  };


  handleToggleModal = (url) => {
    this.setState((prevState) => ({
      showPdfModal: !prevState.showPdfModal,
      modalContentUrl: url,
      loadingPdf: true,
      
    }), () => {
      setTimeout(() => {
        this.setState({ loadingPdf: false }); // Set loading state to false after a delay
      }, 2000); 
    });

    
  };

  
  

  getFileType = (url) => {
    if (url && typeof url === 'string') {
      if (url.endsWith('.pdf')) {
        return 'pdf';
      } else if (
        url.endsWith('.png') ||
        url.endsWith('.jpg') ||
        url.endsWith('.jpeg')
      ) {
        return 'image';
      } else {
        return 'other';
      }
    }
    return 'other'; // Default to 'other' if url is undefined or not a string
  };


  renderFormModal = (url) => {
    const { loadingPdf } = this.state;

    return (
     
      <BaseModal
        // title={I18n.t("order.link_to_order")}
        title="PDF Preview"
        visible={this.state.showPdfModal}
        onCancel={this.handleCloseModal}
        footer={null}
        width={"60%"}
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }} // Remove default padding
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.85)" }} // Set background color of the modal backdrop
        maskClosable={false}
      >
       
         
         {this.getFileType(url) === 'image' ? (
          <img src={url} alt="Preview" />
        ) : (
          <Spin spinning={loadingPdf}><iframe src={url} width="100%" height="500px" title="PDF Preview" /></Spin>
        )}
          
      </BaseModal>
    );
  };

  // closeModal = () => {
  //   this.setState({ showPdfModal: false });
  // };
  handleOpenModal = (url) => {
    this.setState({
      showPdfModal: true,
      modalContentUrl: url,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showPdfModal: false,
      modalContentUrl: '',
    });
  };
}

export default DmsCombinedList;
