/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Col, Row, Spin } from '../common/UIComponents';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import { deleteAccessorial, saveAccessorial } from '../api/Accessorials';
import AccessorialList from '../components/accessorials/AccessorilList';
import AccessorialForm from '../components/accessorials/AccessorialForm';
import BaseModal from '../components/BaseModal';
import userStore from '../stores/UserStore';
import { fetchAccountAccessorials } from '../api/Account';
import { renderAlertMessage } from '../helpers/common';

// const sessionOrgId = userStore.getStateValue('selectedOrg');
class Accessorials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // pagination: {},
      inProgress: false,
      showForm: false,
      accessorials: [],
      currentAccessorial: {},
      orgId: this.props.orgId || userStore.getStateValue('selectedOrg'),
      isNew: true,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount() {
    this.getAccessorials();
  }

  getAccessorials = () => {
    this.setState({ inProgress: true, currentAccessorial: {} });
    fetchAccountAccessorials(this.state.orgId)
      .then((result) => {
        if (result.success) {
          this.setState({
            accessorials: result.accessorials,
            inProgress: false,
          }, () => {

          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }


  clearForm = () => {
    this.setState({
      currentAccessorial: {},
      isNew: true,
    });
  }

  closeFormWindow = () => {
    this.setState({
      showForm: false,
    }, () => {
      this.getAccessorials();
    });
  };

  showFormWindow = () => {
    this.setState({
      currentAccessorial: {},
      showForm: true,
      isNew: true,
    });
  };


  handleEditClick = (id) => {
    const index = _.findIndex(this.state.accessorials, ['id', id]);
    const accessorial = Object.assign({}, this.state.accessorials[index],{
      amount_type: this.state.accessorials[index]._amount_type,
      applicable_to: this.state.accessorials[index]._applicable_to,
    });
    this.setState({ currentAccessorial: accessorial, isNew: false, showForm: true });
  }

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteAccessorial(id, { org_id: this.state.orgId })
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getAccessorials();
          this.setState({
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.accessorials, ['id', id]);
    const currentAccessorial = Object.assign({}, this.state.accessorials[index]);
    currentAccessorial.status = currentAccessorial.status === 'active' ? 'inactive' : 'active';
    this.setState({ currentAccessorial, isNew: false }, () => {
      saveAccessorial(false, this.state.currentAccessorial)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'));
            this.getSurveys();
          } else {
            alertMessage(result.errors, 'error', 10);
          }
        });
    });
  }


  renderFormModal = () => (
    <BaseModal
      title={I18n.t('accessorials.manage')}
      onCancel={() => this.closeFormWindow()}
      width="40%"
      style={{ top: 50 }}
    >
      <AccessorialForm
        accessorial={this.state.currentAccessorial}
        isNew={this.state.isNew}
        org_id={this.state.orgId}
      />
    </BaseModal>
  )


  render() {
    // const { pagination } = this.state;
    return (
      <div className='content-outer'>
        <div className='content'>
          {this.props.showHeadings !== false ?
            <Row className='page-header'>
              <Col xs={12}>
                {I18n.t('menu.accessorial')}
              </Col>
              <Col xs={12} className="page-actions">
                <Button type="primary" className="marginRight10" onClick={() => this.showFormWindow()} icon='plus'>
                  {I18n.t('accessorials.add')}
                </Button>
              </Col>
            </Row> :
            <Row>
              <Col xs={24} className="alignRight">
                <Button type="primary" className="margin10" onClick={() => this.showFormWindow()} icon='plus'>
                  {I18n.t('accessorials.add')}
                </Button>
              </Col>
            </Row>
          }
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col className="accessorials">
                <AccessorialList
                  size="small"
                  data={this.state.accessorials}
                  editClick={id => this.handleEditClick(id)}
                  deleteClick={id => this.handleDelete(id)}
                  pagination={{ position: 'none' }}
                  statusChange={id => this.handleAccessorialStatusChange(id)}
                />

              </Col>
            </Row>
          </Spin>

        </div>
        {this.state.showForm &&
          this.renderFormModal()
        }
      </div>
    );
  }
}
Accessorials.propTypes = {
  orgId: PropTypes.string,
  showHeadings: PropTypes.bool,
};
Accessorials.defaultProps = {
  orgId: '',
  showHeadings: true,
};

export default Accessorials;
