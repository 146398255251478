import React from "react";
import moment from 'moment';
import BaseList from "../../BaseList";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance } from "../../../helpers/common";
import { Link } from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import AppConfig from "../../../config/AppConfig";


class PaymentJournalList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
        {  
            key: "checkNo",
            title: I18n.t("payment.check_number"),
            dataIndex: "check_ach_no",
            render: (data) => <CopyableContent text={data} showTooltip/>,
            align:"left",
           
            
          },
        {  
            key: "paymentDate",
            title: I18n.t("payment.pay_date"),
            dataIndex: "payment_date",
            render: (data) => (
            <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
            ),
            width:120,
            align:"left",
            sorter: (a, b) => compareDate(a.payment_date, b.payment_date)
          },
        {  
          key: "invoice_date",
          title: I18n.t("invoices.invoice_date"),
          dataIndex: "inv_date",
          render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        align: "left",
        width: 90,
        sorter: (a, b) => compareDate(a.inv_date, b.inv_date)
      },
      {
        key: "amount",
        title: I18n.t("payment.amt"),
        dataIndex: "amount",
        render: (data) => (
          <span>{data ? checkNegitive(data) : ""}</span>
        ),   
        align: "right",
        width: 90
      },
      {
        key: "acc_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
          }
        },
        {  
          key: "cus_name",
          title: I18n.t("payment.cus_name"),
          dataIndex: "cus_name",
          render: (data) => (
          <span className="textUpperCase">{data}</span>
          ),
          align:"left",
         
        },
        {  
          key: "bal_company",
          title: I18n.t("general.company"),
          dataIndex: "company",
          render: (data) => (
          <span className="textUpperCase">{data}</span>
      ),
          align:"left"
      },

      {  
          key: "invoice_num",
          title: I18n.t("payment.inv_no"),
          dataIndex: "invoice_number",
          render: (data) => <CopyableContent text={data} showTooltip/>,
          align:"left",  
      },
      {  
          key: "entered_by",
          title: I18n.t("payment.entered_by"),
          dataIndex: "entered_by",
          render: (data) => (
          <span className="textUpperCase">{data}</span>
          ),
          align:"left"

      },
      {
        key: "posted",
        title: I18n.t("payment.posted_date"),
        dataIndex: "post_date",
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        align: "left",
        width: 80,
        sorter: (a, b) => compareDate(a.post_date, b.post_date)

      },
      {
        key: "total_amt",
        title: I18n.t("payment.total_amt"),
        dataIndex: "toal_amount",
        render: (data) => (
          <span>{data ? checkNegitive(data) : ""}</span>
        ),
        align: "Right",

      },
    ];
  }
}
export default PaymentJournalList;