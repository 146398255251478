/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Col, Row, Spin, Select, Icon, Input } from '../../common/UIComponents';
import { alertMessage,  isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import userStore from '../../stores/UserStore';
import { fetchAccountDims, saveDimValues } from '../../api/Account';
import AppConfig from '../../config/AppConfig';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import LabelInfo from "../configurations/LabelInfo"


class DimConfigurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      config: {},
      previousConfig: {},
      account: props.account || {},
      activeKey: "1",
      isNew: true,
      isInitial: true,
    };
  }

  componentDidMount() {
    this.getAccountConfigs();
  }

  componentWillReceiveProps(props) {
    if (props.account && props.account.id !== this.state.account.id) {
      this.setState(
        {
          account: props.account,
        },
        () => {
          this.getAccountConfigs();
        }
      );
    }
  }

  initialData = () => {
    const { config, previousConfig } = this.state;
    if (isEmpty(config.dim_factor)) {
      config.dim_factor = "" //AppConfig.dimValues[0];
      previousConfig.dim_factor = "" //AppConfig.dimValues[0];
    }
    this.setState({ config, previousConfig, isInitial: false });
  };

  getAccountConfigs = () => {
    this.setState({ inProgress: true });
    const { config } = this.state;
    const orgId = userStore.getStateValue("selectedOrg");
    fetchAccountDims(orgId, this.state.account.id).then((result) => {
      if (result.success) {
        const accountDims = result.configurations || [];
        const dim = accountDims.length > 0 ? accountDims[0] : {};
        if (Object.keys(dim).length > 0) {
          config.id = dim.id;
          config[I18n.t("configurations.dim_factor_key")] =
            dim[I18n.t("configurations.dim_factor_key")];
          config[I18n.t("configurations.dim_min_weight_key")] =
            dim[I18n.t("configurations.dim_min_weight_key")];
        }
        this.setState(
          {
            inProgress: false,
            config,
            previousConfig: { ...config },
            isNew: false,
          },
          () => {
            if (this.state.isInitial) {
              this.initialData();
            }
          }
        );
      } else {
        //  alertMessage(result.errors, 'error', 10);
        this.setState({ inProgress: false, isNew: true }, () => {
          if (this.state.isInitial) {
            this.initialData();
          }
        });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
  };

  handleSave = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    const dimFactor = I18n.t("configurations.dim_factor_key");
    const minWeight = I18n.t("configurations.dim_min_weight_key");
    if (!isEmpty(this.state.config[dimFactor])) {
      this.setState({
        inProgress: true,
      });
      const data = {
        id: this.state.isNew ? "" : this.state.config.id,
        [minWeight]: this.state.config[minWeight]
          ? this.state.config[minWeight]
          : 0.0,
        [dimFactor]: this.state.config[dimFactor]
          ? this.state.config[dimFactor]
          : "",
        organization_id: orgId,
        account_id: this.state.account.id,
      };
      saveDimValues(data, this.state.isNew).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
          });
          this.getAccountConfigs();
          this.handleSaveSuccess(I18n.t("messages.saved"));
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    } else {
      alertMessage(
        I18n.t("errors.field_required", {
          field: I18n.t("configurations.dim_factor"),
        }),
        "error",
        10
      );
    }
  };

  handleCancel = (element) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: this.state.previousConfig[element],
      }),
    });
  };

  handleOnInputChange = (element, value) => {
    this.setState({
      config: Object.assign({}, this.state.config, {
        [element]: value,
      }),
    });
  };

  _renderFloatingBtn = () => (
    <Button
      type="primary"
      className="floatBtnConfig"
      onClick={() => this.handleSave()}
    >
      <Icon type="save" />
      Save
    </Button>
  );

  goToTab = (step) => {
    this.setState({
      activeKey: step,
    });
  };

  _renderModuleTabs = () => (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={11}>
              {I18n.t("configurations.dim_factor")}
              <sup className="textRed">*</sup>
              <LabelInfo
                title={I18n.t("configurations.definitions.dim_factor_desc")}
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Select
                style={{ width: "100%" }}
                onChange={(e) =>
                  this.handleOnInputChange(
                    I18n.t("configurations.dim_factor_key"),
                    e
                  )
                }
                defaultValue={I18n.t("configurations.dim_factor")}
                value={
                  this.state.config[I18n.t("configurations.dim_factor_key")]
                }
                filterOption={(input, option) =>
                    String(option.props.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                 <Select.Option key={"dim_factor"} value={""}>
                    ---Select---
                  </Select.Option>
                {AppConfig.dimValues.map((key) => (
                  <Select.Option value={key} key={key}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Row className="configLableRow">
            <Col xs={23} sm={23} md={23} lg={11}>
              {I18n.t("configurations.dim_min_weight_label")} (
              {AppConfig.weight_unit})
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              :
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Input
                type="number"
                value={
                  parseFloat(
                    this.state.config[
                      I18n.t("configurations.dim_min_weight_key")
                    ]
                  ) || 0.0
                }
                onChange={(e) =>
                  this.handleOnInputChange(
                    I18n.t("configurations.dim_min_weight_key"),
                    e.target.value
                  )
                }
                min={0}
                precision={2}
                defaultValue={0}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  render() {
    const opCode = this.state.isNew ? "DIMC" : "DIMU";
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <this._renderModuleTabs />
            {checkServiceExistance(opCode) && this._renderFloatingBtn()}
          </Spin>
        </div>
      </div>
    );
  }
}

export default DimConfigurations;
