import { Col, Icon, Popconfirm, Row, Tooltip } from "antd";
import React, { Fragment } from "react";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import ShowInvoice from "../billing_screen/ShowInvoice";
import moment from 'moment';
import CopyableContent from "../common/CopyableContent";
import { checkNegitive } from "../../common/Common";
import AppConfig from "../../config/AppConfig";
import ShowDriverInvoice from "./weekly_reports/ShowDriverInvoice";
import { checkServiceExistance, formateTwoDates } from "../../helpers/common";
import DownloadWeeklyReport from "./DownloadWeeklyReport";
import EmailWeeklyReport from "./EmailWeeklyReport";
import EditIcon from "../common/EditIcon";

class DriversInvoicesList extends BaseList {
  constructor(props) {
    super(props);
    console.warn('driver reports',props.reports)
    this.columns = [
      {
        key: "Invoice",
        title: <b>{I18n.t("drivers_weekly_report.invoice")}</b>,
        dataIndex: "invoice_number",
        render: (data, record) => <ShowDriverInvoice record={record} />,
      //<CopyableContent text={data} />
      //   render: (data, record) => <ShowInvoice
      //   invoice_no={ record.invoice_number }
      //   //account_invoice_id={ record.customer_order_id }
      //   style={{ marginTop: -6 }}
      // />,
        width: 100,
      },
      {
        key: "driver",
        title: <b>{I18n.t("drivers_weekly_report.driver")}</b>,
        dataIndex: "driver_code",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "week_number",
        title: <b>{I18n.t("drivers_weekly_report.week")}</b>,
        dataIndex: "week_number",
        render: (data, record) => (
          <span>
            {formateTwoDates(record.start_of_week, record.end_of_week)}
            {record.start_of_week && record.end_of_week ? (
              <>
                {/* {moment(record.start_of_week).format("MMM DD")} -{" "}
                {moment(record.end_of_week).format("DD YYYY")} */}
              </>
            ) : "NA"}
          </span>
        ),
        width: 100,
      },
      {
        key: "created_at",
        title: <b>{I18n.t("drivers_weekly_report.invoice_date")}</b>,
        dataIndex: "created_at",
        render: (data, record) => 
        {
          return (
            <span>
              {data
                ? moment(data).format(
                      AppConfig.dateFormat
                  )
                : "NA"}
            </span>
          );
        },
        width: 100,
      },
      {
        key: "gross_revenue",
        title: <b>{I18n.t("drivers_weekly_report.gross($)")}</b>,
        dataIndex: "gross_revenue",
        render: (data, record) => <div className="textUpperCase alignRight" style={{ width : 60}}>{checkNegitive(data)}</div>,
        width: 100,
      },
      {
        key: "gross_pay",
        title: <b>{I18n.t("drivers_weekly_report.net($)")}</b>,
        dataIndex: "gross_pay",
        render: (data, record) => <div className="textUpperCase alignRight" style={{ width : 57}}>{checkNegitive(data)}</div>,
        width: 100,

      },
      {
        key: 'actions',
        title: '',
        // dataIndex: 'id',
        render: (text, record, index) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
             <Fragment>
                <DownloadWeeklyReport
                  key={record.id}
                  driver_pay_report_id={record.driver_pay_report_id}
                  driver_code={record.driver_code}
                  week_no={record.week_number}
                  displayType={'icon'}
                />
                &nbsp;&nbsp;
                <EmailWeeklyReport
                  key={record.id}
                  driver_pay_report_id={record.driver_pay_report_id}
                  driver_code={record.driver_code}
                  week_no={record.week_number}
                  displayType={'icon'}
                  className="textVold"
                />
                &nbsp;&nbsp;
              </Fragment>     
           {/* {checkServiceExistance('DPOR') && (
              <Tooltip title={I18n.t("general.edit")}>
                <span><EditIcon handleClick={() => this.props.handleView(record)}/></span>
              </Tooltip>
            )} */}
          </div>
          
        ),
        width: 100,
      },
    ];
  }
}
export default DriversInvoicesList;
