import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Statistic, Icon } from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import { getReleaseStatusIcon } from "../../helpers/release";

const ReleaseStats = ({ filter, handleOnFilterChange, data }) => {
  const totalStats = Math.floor(24 / (AppConfig.releaseStatuses.length + 1));
  return (
    <Row className="orderStats">
      <Col span={2}/>
      <Col
        span={totalStats}
        onClick={() => handleOnFilterChange("filter", "")}
        className="routeLegends"
      >
        <Statistic
          title="All"
          className={filter === "" && "active"}
          value={data.total_count}
          prefix={<Icon type="ordered-list" className="textAll" />}
        />
      </Col>
      {AppConfig.releaseStatuses.map((rec) => (
        <Col
          span={totalStats}
          onClick={() => {
            handleOnFilterChange("filter", rec.value);
          }}
          className="routeLegends"
        >
          <Statistic
            title={rec.text}
            className={filter === rec.value && "active"}
            value={data[rec.value]}
            prefix={getReleaseStatusIcon(rec.value)}
          />
        </Col>
      ))}
    </Row>
  );
};

ReleaseStats.propTypes = {
  filter: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  handleOnFilterChange: PropTypes.func.isRequired,
};

export default ReleaseStats;
