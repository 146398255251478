import { Col, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import BaseList from "../../BaseList";
import DeleteIcon from "../DeleteIcon";
import EditIcon from "../EditIcon";
import I18n from "../../../common/I18n";


class MobileConfigList extends BaseList {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key : "configuration_name",
                title : <b>{I18n.t("MobileConfiguration.mobile_config_name")}</b>,
                dataIndex  : "configuration_name",
                render : (data,record ) => <span>{data}</span>,
            },
            {
                key : "configuration",   
                title : <b>{I18n.t("MobileConfiguration.json_input")}</b>,
                dataIndex : "configuration",
                render: (data,record) => <span>{data}</span>,
            },
            {
                key : "platform",
                title : <b>{I18n.t("MobileConfiguration.platform")}</b>,
                dataIndex  : "platform",
                render : (data,record ) => <span>{data}</span>,
            },
            {
                key : "actions",
                fixed: "right",
                render : (data) => {
                    const {handleEdit,handleDelete} = this.props;
                    const {id} = data;
                    return (
                      <div className="line-actions">
                        <Tooltip title={I18n.t("general.edit")}>
                          <span><EditIcon handleClick={() => handleEdit(id)} /></span>
                        </Tooltip>

                        <Popconfirm
                          placement="left"
                          title={I18n.t("messages.delete_confirm")}
                          onConfirm={() => handleDelete(id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title={I18n.t("general.delete")}>
                            <span><DeleteIcon /></span>
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    );
                },
                align: "center",
                width :"10%"
            }
        ]
    }
}

export default MobileConfigList;