import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import { checkServiceExistance, toScreenConfigFormat } from "../../helpers/common";
import { ScreenKeys } from "../../containers/constants";
// import { setGridConfigSetting } from "../api/DisplaySettings";
import { alertMessage } from "../../common/Common";
import { checkboxColumn, getDriverSettlemetCols, getOrderListCols, getPrePlanCol, getWarehouseCols } from "../../components/common/columnDefs";
import { getStoredColumns,getConfiguredColumns } from "../../components/orders/helpers";
import { setGridConfigSetting } from "../../api/DisplaySettings";
import MyGridComponent from "../dispatches/ReactDataGrid";


const OrdersGridList = (props) => {
  const {
    rawDataSource,
    accountCodesWithExceededLimit = [],
    userContext,
    tableName,
    currentFilter,
    setOptionsForSorting,
    shouldResetRowSelectedKeys,
    filterPlaceHolder,
    onRowDataUpdated,
  } = props;

  const { updateColWidths, currentUser } = userContext || {};
  const [ filteredRowData, setFilteredRowData ] = useState([]);
  const tableColMapping = {
    [ScreenKeys.ORDER_LISTING_V2] : getOrderListCols(props)
  };

  useEffect(() => {
    const filteredColumns = filterColumns(allCols, currentFilter, props.displayConfiguration);
    setColumnDefs(filteredColumns);
    setOptionsForSorting(filteredColumns);
  }, [currentFilter, allCols, props.displayConfiguration]);

  const allCols = _.get(tableColMapping, tableName, []);

  function filterColumns(columns, statusFilter, displayConfiguration) {
    const verifiedCols = columns.filter((col) => col.hasOwnProperty('field'));
    const checkboxRestriction = [
      "",
      "COMPLETED",
      "EXCEPTION",
      "DISPATCHED",
      "CLOSED",
    ];
    
    const initialRefinedColumns = checkboxRestriction.includes(statusFilter)
      ? verifiedCols.filter((column) => column.field !== "0")
      : verifiedCols;
  
    const filteredColumns = initialRefinedColumns.filter((column) => {
      const { isPeculiar, acceptedStatus } = column;
      const isStatusAccepted =
        acceptedStatus.length === 0 || acceptedStatus.includes(statusFilter);
      return (
        (isPeculiar === false && isStatusAccepted) ||
        (isPeculiar === true && !isStatusAccepted)
      );
    });
  
    const columnOrder = displayConfiguration?.userConfiguration?.order_list_view_columns || [];
  
    // Sort the filtered columns based on the order in columnOrder
    const orderedFilteredColumns = filteredColumns.sort((a, b) => {
      const indexA = columnOrder.indexOf(a.field);
      const indexB = columnOrder.indexOf(b.field);
      
      // If both fields are in columnOrder, sort by their position
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      // If only one field is in columnOrder, prioritize it
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      // If neither field is in columnOrder, maintain their original order
      return 0;
    });
  
    // Update the configuration prop to remove the filtered out columns
    const updatedColumnOrder = columnOrder.filter((field) =>
      orderedFilteredColumns.some((column) => column.field === field)
    );
  
    if (displayConfiguration?.userConfiguration) {
      displayConfiguration.userConfiguration.order_list_view_columns_v3 = updatedColumnOrder;
    }
  
    return orderedFilteredColumns;
  }

  const filteredStatusColoumns = filterColumns(allCols, currentFilter);
  const [ columnDefs, setColumnDefs ] = useState(filteredStatusColoumns);

  useEffect(() => {
    const modifiedData = rawDataSource?.map((order) => {
      if (accountCodesWithExceededLimit.includes(order.account_code)) {
        return {
          ...order,
          account_limit_exceeded: true
        };
      } else {
        return order;
      }
    });
    setFilteredRowData(modifiedData);
  }, [ rawDataSource, accountCodesWithExceededLimit ]);

  const ProcessCols = () => {
    const columns = [];
    const hasSomeConfiguration = props.displayConfiguration && (props.displayConfiguration.userConfiguration || props.displayConfiguration.orgConfiguration);
    const storedColumns = getStoredColumns(currentUser, tableName);
    //columns.push(...allCols);
    if (!hasSomeConfiguration) {
      columns.push(...filteredStatusColoumns);
    } else {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      columns.push(...getConfiguredColumns(filteredStatusColoumns, storedColumns, userConfiguration, orgConfiguration, tableName));
    }
    setColumnDefs(columns);
    setOptionsForSorting(columns)
  };


  useEffect(() => {
    ProcessCols();
  }, [ props.displayConfiguration, props.vhTypes ]);

  const handleSaveColumnWidth = ({ colId, newWidth, colState }) => {
    let newColumnDefs = [];
    colState.forEach((col) => {
      if (col.colId === '0') {
        newColumnDefs.push(checkboxColumn);
      }
      else if (col.colId === 'items') {
        newColumnDefs.push(itemsColdef);
      }
      else {
        const foundCol = _.find(columnDefs, { field: col.colId });
        newColumnDefs.push({ ...foundCol, width: col.width });
      }
    }
    );
    setColumnDefs(newColumnDefs);

    const finalColumnDefs = colState.map((col) => { return { key: col.colId, width: col.width }; });
    updateColWidths(tableName, toScreenConfigFormat(finalColumnDefs));
  };

  const handleSaveRowIdx = useCallback((colState) => {
    let newOrder = _.compact(colState
      .filter((col) => col.isVisible) // filter out columns where isVisible is false
      .map((col) => col.field || col.colId));    
    const { currentUser } = userContext;
    const { _id: userId, currentOrg } = currentUser;
    const data = {
      organization_id: currentOrg._id,
      config_type: tableName,
      config_value: newOrder,
      user_id: userId
    };
    setGridConfigSetting(data).then((result) => {
      if (result.success) {
        props.dispContext("userConfiguration", {
          ...props.displayConfiguration.userConfiguration,
          [tableName]: [...newOrder],
        });
        alertMessage(I18n.t("messages.saved"), "success", 10);
      } else {
        alertMessage(result.errors[0], "error", 10);
      }
    }
    );

  }, [ tableName ]);

    const handleSaveColIdx = useCallback((colState) => {
      let newOrder = _.compact(colState
        .filter((col) => !col.hide)
        .map((col) => col.field || col.colId));
      const { currentUser } = userContext;
      const { _id: userId, currentOrg } = currentUser;
      const data = {
        organization_id: currentOrg._id,
        config_type: tableName,
        config_value: newOrder,
        user_id: userId
      };
      setGridConfigSetting(data).then((result) => {
        if (result.success) {
          props.dispContext("userConfiguration", {
            ...props.displayConfiguration.userConfiguration,
            [tableName]: [...newOrder],
          });
          alertMessage(I18n.t("messages.saved"), "success", 10);
        } else {
          alertMessage(result.errors[0], "error", 10);
        }
      }
      )
    }
      , [ tableName ]);

    const handleRowChecked = (colId, checked) => {
      const newColumnDefs = columnDefs.map((col) => {
        if (col.field || col.colId === colId) {
          return { ...col, hide: !checked };
        }
        return col;
      });
      setColumnDefs(newColumnDefs);
      setOptionsForSorting(newColumnDefs);
    };

  return (
    <>
    <MyGridComponent
      filterPlaceHolder={filterPlaceHolder}
      columnDefs={ columnDefs }
      rowData={ filteredRowData }
      onRowSelection={ props.onRowSelection }
      // onSortChanged={ props.onSortChanged }
      onSortChanged={ (colState) => {
        const sortedCol = colState[ 0 ];
        console.log('colState', colState);
        const { colId, sort } = sortedCol; props.onSort(colId, sort);
      }
      }
      totalCols={ filteredStatusColoumns }
      saveColumnWidth={ handleSaveColumnWidth }
      onColumnMoved={ handleSaveColIdx }
      onRowMoved={ handleSaveRowIdx}
      setCurrentGridRef={ props.setCurrentGridRef }
      handleColumnVisibilityChange={props.handleColumnVisibilityChange}
      showRerraangeModal={props.showRerrangeableModal}
      closeModal={ props.closeModal }
      isRowSelectable={ props.isRowSelectable }
      showPagination={props.showPagination}
      pagination={props.pagination}
      getPageWiseData={props.getPageWiseData}
      onPerPageChanged={props.onPerPageChanged}
      handleRowSelection={ handleRowChecked }
      onRowDataUpdated={onRowDataUpdated}
      shouldResetRowSelectedKeys={shouldResetRowSelectedKeys}
      restoreRowSelection={props.restoreRowSelection}
    />
    </>
  );
};


export default OrdersGridList;

OrdersGridList.propTypes = {
  rawDataSource: PropTypes.array,
  accountCodesWithExceededLimit: PropTypes.array,
  userContext: PropTypes.object,
  tableName: PropTypes.string,
  onRowSelection: PropTypes.func,
  onSort: PropTypes.func,
  vhTypes: PropTypes.array,
  displayConfiguration: PropTypes.object,
  accountsList: PropTypes.array,
  zonesList: PropTypes.array,
  handleColumnVisibilityChange: PropTypes.func,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  isRowSelectable: PropTypes.func,
  onRowDataUpdated: PropTypes.func
};

OrdersGridList.defaultProps = {
  rawDataSource: [],
  accountCodesWithExceededLimit: [],
  userContext: {},
  tableName: '',
  onRowSelection: () => { },
  onSort: () => { },
  vhTypes: [],
  displayConfiguration: {},
  accountsList: [],
  zonesList: [],
  handleColumnVisibilityChange: () => { },
  modalOpen: false,
  closeModal: () => { },
  isRowSelectable: () => { },
  onRowDataUpdated: null,

};