/* eslint-disable react/sort-comp */
import React, { Component, useContext } from "react";
import _ from "lodash";
import { deleteOrganization, fetchOrganizations } from "../api/Organisations";
import { Button, Col, Row, Spin } from "../common/UIComponents";
import OrganizationsList from "../components/organizations/List";
import BaseModal from "../components/BaseModal";
import OrganizationForm from "../components/organizations/LookForm";
import { alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import AppConfig from "../config/AppConfig";
import Accessorials from "./Accessorial";
import { checkServiceExistance, renderAlertMessage } from "../helpers/common";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";

class Organizations extends Component {
	constructor (props) {
		super(props);
		this.state = {
			organizations: [],
			pagination: {},
			showFormModal: false,
			showReviews: false,
			currentOrganization: {},
			inProgress: false,
			isNew: false,
			showAccessorialModal: false,
			currentAccessorial: {},
			recordsPerPage: props.recordsPerPage,
		};
		
		this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
	}

	componentDidMount() {
		this.getOrganizations();
	}

	getOrganizations = (initial, cb) => {
		this.setState({ inProgress: true });
		const page = this.tableOptions.pagination.current;
		const perPage = initial ? initial.perPage : this.state.recordsPerPage;
		fetchOrganizations(page, perPage).then((result) => {
			if (result.success) {
				this.setState(
					{
						organizations: result.organizations.organizations,
						pagination: result.pagination,
						inProgress: false,
					},
					() => {
						if (cb) {
							cb();
						}
					}
				);
			} else {
				renderAlertMessage(result.errors)
				this.setState({ inProgress: false });
			}
		});
	};

	handleAddClick = () => {
		this.setState({
			isNew: true,
			currentOrganization: {},
			showFormModal: true,
		});
	};

	handleEditClick = (id) => {
		const index = _.findIndex(this.state.organizations, ["id", id]);
		const organization = Object.assign({}, this.state.organizations[index]);
		this.setState({
			currentOrganization: organization,
			isNew: false,
			showFormModal: true,
		});
	};

	handleDeleteClick = (id) => {
		this.setState({ inProgress: true });
		deleteOrganization(id).then((result) => {
			if (result.success) {
				alertMessage(I18n.t("messages.deleted"));
				this.getOrganizations();
			} else {
				renderAlertMessage(result.errors)
				this.setState({ inProgress: false });
			}
		});
	};

	handleSaveSuccess = (message) => {
		alertMessage(message);
		this.setState({ showFormModal: false }, () => {
			this.getOrganizations();
		});
	};

	handleOnModalClose = () => {
		if (this.state.showFormModal && this.state.currentOrganization.id) {
			this.getOrganizations();
		}
		this.setState({ showFormModal: false, showReviews: false });
	};

	handleTableChange = (pagination, filters, sorter) => {
		this.tableOptions = { pagination, filters, sorter };
		this.getOrganizations();
	};

	handleOnAccesorialModalClose = () => {
		this.setState({
			showAccessorialModal: false,
		});
	};

	renderFormModal = () => {
		let title;
		if (this.state.isNew) {
			title = I18n.t("organization.add");
		} else {
			title = I18n.t("organization.edit");
		}
		return (
			<BaseModal
				title={title}
				onCancel={() => this.handleOnModalClose()}
				width="80%"
				style={{ top: 30 }}
				maskClosable={false}
			>
				<OrganizationForm
					organization={
						this.state.currentOrganization.id
							? this.state.currentOrganization.id
							: ""
					}
					isNew={this.state.isNew}
					onCancel={() => this.handleOnModalClose()}
					onSuccess={(message) => this.handleSaveSuccess(message)}
				/>
			</BaseModal>
		);
	};

	renderAccessorialModal = () => (
		<BaseModal
			title={I18n.t("accessorials.manage")}
			onCancel={() => this.handleOnAccesorialModalClose()}
			width="60%"
			style={{ top: 20 }}
		>
			<Accessorials
				orgId={this.state.currentOrganization.id}
				showHeadings={false}
			/>
		</BaseModal>
	);

	viewAccessorial = (id) => {
		const index = _.findIndex(this.state.organizations, ["id", id]);
		const organization = Object.assign({}, this.state.organizations[index]);
		this.setState({
			currentOrganization: organization,
			showAccessorialModal: true,
		});
	};

	onRecordChange = (value) => {
		const { updateRecordsPerPage } = this.props.userContext;
		if (!value) {
			value = this.props.recordsPerPage;
		}
		this.setState(
		{
			recordsPerPage: value,
		},
		() => {
			this.tableOptions.pagination.current = 1;
			this.getOrganizations();
			updateRecordsPerPage(ScreenKeys.ORGANIZATIONS_LISTING, value);
		}
		);
	};

	render() {
		const { pagination } = this.state;
		return (
			<div className="content-outer">
				<div className="content">
					<Row className="page-header">
						<Col xs={12}>{I18n.t("menu.organizations")}</Col>
						{checkServiceExistance("ORGC") && (
							<Col xs={12} className="page-actions">
								<Button
									type="primary"
									onClick={() => this.handleAddClick()}
									icon="plus"
								>
									{I18n.t("organization.new")}
								</Button>
							</Col>
						)}
					</Row>
					{/* <Row>
            <Col xs={24} className='page-actions'>
              <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('organization.new')}
              </Button>
            </Col>
          </Row> */}
		  			<Row type="flex" justify="end">
						<Col>
							<RecordsPerPage
								onChange={this.onRecordChange}
								value={this.state.recordsPerPage}
								defaultValue={this.state.recordsPerPage}
							/>
						</Col>
					</Row>
					<Spin spinning={this.state.inProgress} delay={1000}>
						<Row>
							<Col>
								{checkServiceExistance("ORGI") && (
									<OrganizationsList
										data={this.state.organizations}
										editClick={(id) =>
											this.handleEditClick(id)
										}
										deleteClick={(id) =>
											this.handleDeleteClick(id)
										}
										showReviews={(id) =>
											this.handleShowReviews(id)
										}
										viewAccessorial={(id) =>
											this.viewAccessorial(id)
										}
										pagination={{
										    total: pagination.total_count,
										    current: pagination.current_page,
										    pageSize: this.state.recordsPerPage,
										  }}
										tableChange={(
											tablePagination,
											filters,
											sorter
										) =>
											this.handleTableChange(
												tablePagination,
												filters,
												sorter
											)
										}
										scroll = {{y:"calc(100vh - 250px)"}}
									/>
								)}
							</Col>
						</Row>
					</Spin>
					{this.state.showFormModal && this.renderFormModal()}
					{this.state.showReviews && this.renderReviewsModal()}
					{this.state.showAccessorialModal &&
						this.renderAccessorialModal()}
				</div>
			</div>
		);
	}
}

export const ORGWrapper = ((props) => {
	const userContext = useContext(UserContext);
	const perPageKey = ScreenKeys.ORGANIZATIONS_LISTING;
	const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);

	return (
		<Organizations
			recordsPerPage={ recordsPerPage }
			userContext={ userContext }
			{ ...props }
		/>
	);
});

export default ORGWrapper;