import React, { useState } from "react";
import { momentTime } from "../../../helpers/date_functions";
import AppConfig from "../../../config/AppConfig";
import { RearrangableTable } from "../../common/RearrangableTable";
import { formatByTimeConfig } from "../../../helpers/common";
import moment from "moment";
import I18n from "../../../common/I18n";

export const LineHaulList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      items_count: 2,
      weight: 100,
      type_of_order: "Pickup",
      appointment_date: "2022-10-01T12:00:00Z",
      mawb: "123456",
      hawb: "123456",
      account_name: "ABC123",
      account_code: "abc123",
      // pickup_location: "ABC WAREHOUSE",
      // delivery_location: "XYZ WAREHOUSE",
      status: "COMPLETED",
      reference_1: "700214577",
      reference_2: "123456",
      routing: "ABCD"
    },
    {
      key: 2,
      customer_order_number: 1002,
      items_count: 5,
      weight: 120,
      type_of_order: "Delivery",
      appointment_date: "2022-10-01T12:00:00Z",
      mawb: "123456",
      hawb: "123456",
      account_name: "ABC123",
      account_code: "def345",
      // pickup_location: "ABC WAREHOUSE",
      // delivery_location: "XYZ WAREHOUSE",
      status: "PENDING",
      reference_1: "700214577",
      reference_2: "123456",
      routing : "EFGH"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type"),
      dataIndex: "type_of_order",
    },
    {
      key: "appointment_date",
      title: "Scheduled Date",
      dataIndex: "appointment_date",
      render: (text, record) => {
        return (
          <div>
            {formatByTimeConfig(
              moment.utc(text),
              false,
              "DD MMM  HH:mm",
              "DD MMM  hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      key: 'items_count',
        title: I18n.t('order.qty'),
      dataIndex: 'pieces',
    },
    {
      key: 'weight',
      title: I18n.t('order.weight'),
      dataIndex: 'weight',
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
      render: (data) => data,
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
      render: (data) => data,
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
      render: (data) => data,
    },
    {
      key: "account_code",
      title: I18n.t("general.account_code"),
      dataIndex: "account_code",
      render: (data) => data,
    },
    // {
    //   key: "pickup_location",
    //   title: "Picks at",
    //   dataIndex: "pickup_location",
    //   render: (data) => data,
    // },
    // {
    //   key: "delivery_location",
    //   title: "Drops at",
    //   dataIndex: "delivery_location",
    //   render: (data) => data,
    // },
    {
      key: "status",
      title: I18n.t("general.status"),
      dataIndex: "status",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
  ]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);

  const configType = "linehaul_list_view_columns";

  return (
    <RearrangableTable
      columns={columns}
      filteredColumns={filteredColumns}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={data}
      madeChanges={madeChanges}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilteredColumns={setFilteredColumns}
      setMadeChanges={setMadeChanges}
      showDragIconForSelectedColumns={false}
      controlDrag={false}
      configType={configType}
      dispContext={props.dispContext}
    />
  );
};
