import React, { Fragment, useEffect, useState, useContext } from "react";
import { alertMessage } from "../common/Common";
import I18n from "../common/I18n";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  FormItem,
  Radio,
  Tooltip,
} from "../common/UIComponents";
import _, { isEmpty } from "lodash";
import AppConfig from "../config/AppConfig";
const { RangePicker } = DatePicker;
import moment from "moment";
import userStore from "../stores/UserStore";
import { dynamicFileDownload, base64ToExcel, responseTOCsv } from "../helpers/common";
import { exportFile } from "../api/BillingScreenAPI";
import FormButtons from "../components/common/FormButtons";
import BaseModal from "../components/BaseModal";
import exportIcon from "../../assets/images/exportFile.png";
import { withRouter } from "react-router-dom";
import { OrgContext } from "../context/OrgContext";

function ExportForm(props) {
  const { currentOrg } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);

  const [fromDate, setFromDate] = useState(
    moment().subtract(AppConfig.invoices_filter_duration, "day")
  );
  const [toDate, setToDate] = useState(moment());
  const [status, setStatus] = useState("ALL");
  const [file_type, setFileType] = useState("EXCEL");
  const [desktop_type, setDesktopType] = useState("SAGE");

  const handleExport = () => {
    setIsLoading(true);
    exportFile(fromDate, toDate, status, file_type, desktop_type)
      .then((result) => {
        if (result.success) {
          if(file_type === "CSV"){
            responseTOCsv(
              result.file,
              `${currentOrg.code}_${moment(fromDate).format(
                "MM-DD-YYYY"
              )}_${moment(toDate).format("MM-DD-YYYY")}`,
              file_type 
            )
          } else {
            base64ToExcel(
              result.file,
              `${currentOrg.code}_${moment(fromDate).format(
                "MM-DD-YYYY"
              )}_${moment(toDate).format("MM-DD-YYYY")}`,
              file_type
            );
          }
          setShowExportModal(false);
          setDefaultValues();
        } else {
          alertMessage(
            result?.errors.length ? result.errors[0] : "failed to export file",
            "error",
            10
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setDefaultValues = () => {
    setFromDate(moment().subtract(AppConfig.invoices_filter_duration, "day"));
    setToDate(moment());
    setStatus("ALL");
    setFileType("EXCEL");
    setDesktopType("SAGE");
  };

  const handleRangeChange = (dates, dateStrings) => {
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      setFromDate(moment().subtract(AppConfig.invoices_filter_duration, "day"));
      setToDate(moment());
    } else {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    }
  };

  const _renderForm = () => {
    return (
      <Fragment>
        <Row gutter={8}>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label="Desktop Type">
              <Select
                value={desktop_type}
                showSearch
                onChange={(e) => setDesktopType(e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              >
                <Select.Option value={"SAGE"}>SAGE</Select.Option>
                <Select.Option value={"QUICKBOOKS"} disabled>QUICKBOOKS (coming soon..) </Select.Option> 
              </Select>{/*  (coming soon..) */}
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t("general.select_date")} require>
              <RangePicker
                defaultValue={[moment().startOf("month"), moment()]}
                value={[fromDate, toDate]}
                ranges={AppConfig.dateRanges}
                format={AppConfig.dateFormat}
                style={{ width: "100%" }}
                onChange={(dates, dateStrings) =>
                  handleRangeChange(dates, dateStrings)
                }
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t("general.file_type")} require>
              <Radio.Group
                onChange={(e) => setFileType(e.target.value)}
                value={file_type}
              >
                <Radio value="EXCEL">{I18n.t("general.excel")}</Radio>
                <Radio value="CSV">{I18n.t("general.csv")}</Radio>
                {/* <Radio value="PDF">{I18n.t("general.pdf")}</Radio> */}
              </Radio.Group>
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t("general.status")} require>
              <Radio.Group
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <Radio value="ALL">{I18n.t("general.all")}</Radio>
                <Radio value="APPROVED">
                  {I18n.t("invoices.status.APPROVED")}
                </Radio>
                <Radio value="PENDING">{I18n.t("status_labels.PENDING")}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="alignCenter" xs={24}>
            <Button
              type="danger"
              icon="close"
              onClick={() => {
                setShowExportModal(false);
                setDefaultValues();
              }}
            >
              {I18n.t("general.close")}
            </Button>{" "}
            &nbsp;
            <Button
              type="primary"
              icon="export"
              onClick={handleExport}
              loading={isLoading}
            >
              {I18n.t("general.export")}
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  const _renderExportModal = () => {
    return (
      <BaseModal
        title={I18n.t("general.export")}
        onCancel={() => {
          setShowExportModal(false);
          setDefaultValues();
        }}
        width="35%"
      >
        {_renderForm()}
      </BaseModal>
    );
  };

  return (
    <div>
      <Col className="headerOptionDiv">
        <Tooltip title="Export">
          <span className="filterSetIcon">
            <img
              src={exportIcon}
              className="cursorPointer"
              onClick={() => {
                setShowExportModal(true);
              }}
              style={{
                width: 22,
                marginTop: "-2px",
                marginLeft: 3,
              }}
            />
          </span>
        </Tooltip>
      </Col>
      {showExportModal && _renderExportModal()}
    </div>
  );
}

const InvoiceExportForm = withRouter((props) => {
  const { currentOrg } = useContext(OrgContext);
  return <ExportForm currentOrg={currentOrg} {...props} />;
});
export default InvoiceExportForm;
