import React, { Component, Fragment, useContext } from 'react';
import moment from 'moment';
import _ from "lodash"
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Col, Divider, FormItem, Input, Row, Select, Tabs, TextArea } from '../../common/UIComponents';
import { alertMessage, doValidate,formatCode } from '../../common/Common';
import FormButtons from '../common/FormButtons';
import FormErrors from '../common/FormErrors';
import AddressForm from '../common/AddressForm';
import { loadGoogleMaps } from '../../utils/Utils';
import MobileInput from '../common/MobileInput';
import userStore from '../../stores/UserStore';
import {fetchBillingAccounts}  from "../../api/BillingAcountsApis"
import { OrgContext } from '../../context/OrgContext';
import { UserContext } from '../../context/UserContext';
import AddressSearch from '../../stories/components/AddressSearch';
import I18n from '../../common/I18n';
import { fetchContactGroup } from '../../api/ContactGroup';
import { saveAgent } from '../../api/Agent';
import { removeSpecialCharacters } from '../../helpers/common';


const requiredFields = [{
  form_field: 'name',
  ui_name:"Agent Name",
  isRequired: true,
  inputType: 'text',
},
{
  form_field: 'code',
  ui_name: "Agent Code",
  isRequired: false,
  inputType: 'text',
},
{
  form_field: 'address',
  ui_name: I18n.t('general.address'),
  isRequired: false,
  inputType: 'address',
}
];
class AgentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: this.props.whlocation ? this.props.whlocation : { l_type: 'PICKUP' },
      map: false,
      isNew: this.props.isNew,
      inProgress: false,
      errors: [],
      showFormModal : false,
      billingAccountData: [],
      activeKey: "wh_details",
      selectedLocation: this.props.selectedLocation ? this.props.selectedLocation : {},
      contactGroups: []
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleOnAddressChange = this.handleOnAddressChange.bind(this);
  }

  componentDidMount() {
    this.modifyInputData();
  }

  modifyInputData = () => {
    const { location } = this.state;
    location.open_time = location.open_time ? moment(location.open_time, 'HH:mm:ss') : moment();
    location.close_time = location.close_time ? moment(location.close_time, 'HH:mm:ss') : moment();
    if (location.zone) {
      location.zone_id = location.zone.id;
    }
    this.setState({
      location,
    }, () => {
      loadGoogleMaps(() => {
        this.setState({
          map: true,
        });
      });
    });
  }


  handleOnChange(element, value) {
    if(element === "code"){
      value = removeSpecialCharacters(value, ['&'])
    }
    this.setState({ location: Object.assign({}, this.state.location, { [element]: value }) });
  }

  handleMobileChange = (element, value) => {
    this.setState({ location: Object.assign({}, this.state.location, { [element]: value }) });
  };

  handleOnAddressChange(element, value, obj) {
    const address = this.state.location.address || {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign(address, obj);
    } else if (element) {
      newAddress[element] = value;
    }
    this.setState({ location: Object.assign({}, this.state.location, { address: newAddress }) });
  }

  handleSave() {
    this.setState({ inProgress: true });
    const data = Object.assign({}, this.state.location);
    data.l_type = 'WH';
    const formFields = _.cloneDeep(requiredFields);
    const { currentRole } = this.props.userContextData;
    let errors = doValidate(formFields, data);
    if(formFields.address && _.compact(Object.values(formFields.address)).length > 0){
      const addrErrors = validateAddressForm(formFields.address);
      errors = [].concat(errors, addrErrors)
    }
    if (errors.length === 0) {
      data.location_code = formatCode(data.location_code);
      data.code = formatCode(data.code)
      data.organization_id = this.props.organization_id;
      saveAgent(this.state.isNew, data)
        .then((result) => {
          if (result.success) {
            const Agents = result.agents || {};
            const selectedLocation = { ...Agents }
            this.setState({
              errors: [],
              selectedLocation,
              showFormModal : false
            });
            this.props.onSuccess(I18n.t('messages.saved'));
          } else {
            this.setState({ errors: result.errors, inProgress: false });
          }
        }).finally(() => {
          this.setState({ inProgress: false });
        });
    } else {
      this.setState({ errors, inProgress: false });
    }
  }


  _renderDetails = () =>{
    const { location } = this.state;
        return (
          <div>
            <Row gutter={16}>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={"Agent Name"} require>
                  <Input
                    value={location.name}
                    onChange={(e) =>
                      this.handleOnChange("name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={"Agent Code"} >
                  <Input
                    value={location.code}
                    className="textUpperCase"
                    onChange={(e) =>
                      this.handleOnChange("code", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("general.contact_no")} >
                  <MobileInput
                    handleInputChange={(value, data) => {
                      this.handleMobileChange("phone_number", value, data);
                    }}
                    value={location.phone_number || ""}
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={"Fax Number"} >
                  <MobileInput
                    handleInputChange={(value, data) => {
                      this.handleMobileChange("fax", value, data);
                    }}
                    value={location.fax || ""}
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={"Contact Name"} >
                  <Input
                    value={location.contact_name}
                    onChange={(e) =>
                      this.handleOnChange("contact_name", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={12} xs={12} md={8} lg={12}>
                <FormItem label={I18n.t("general.email")}>
                  <Input
                    value={location.email}
                    onChange={(e) =>
                      this.handleOnChange("email", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
                <Col sm={24} xs={24} md={24} lg={24}>
                    <FormItem label={"Comments"}>
                    <TextArea
                         rows={3}
                         placeholder={"Description"}
                         className="sop-form-textArea"
                         value={location.comments}
                         onChange={(e) =>
                           this.handleOnChange("comments", e.target.value)
                         }              
                    />
                    </FormItem>
                </Col>
            </Row>
            <Divider orientation="left">
              {I18n.t("general.address")}
            </Divider>
            {this.state.map &&
            (this.state.isNew || Object.keys(this.state.location).length) ? (
              <>
              <AddressSearch onSelect={ (address) => this.handleOnAddressChange("", "", address.l_address)} />
              <AddressForm
                autoComplete
                address={location.address}
                onChange={this.handleOnAddressChange}
                isAdmin
                twoRows
              />
              </>
            ) : (
              <Fragment />
            )}
            <Row>{FormErrors(this.state.errors)}</Row>
            <Row>
              <Col xs={24}>
                {FormButtons(
                  this.state.inProgress,
                  this.handleSave,
                  this.props.onCancel
                )}
              </Col>
            </Row>
          </div>
        );
  }

  render() {
    return (
      <div className="card-container tabHover">
        {this._renderDetails()}
      </div>
    );
  }
}


export const AgentFormComponent = withRouter((props) => {
  const userContextData = useContext(UserContext);

  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <AgentForm
      organizationSettings={organizationSettings}
      userContextData={userContextData}
      {...props}
    />
  );
});


AgentForm.propTypes = {
  location: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

AgentForm.defaultPropTypes = {
  location: {},
  isNew: true,
  zones: [],
  onSuccess: () => {},
};

export default AgentFormComponent;
