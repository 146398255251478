import { Call, ApiUrl, PageParams, ParseGeneralResponse } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import { isEmpty } from '../common/Common';

export function fetchPackages(page = 1, perPage = AppConfig.perPage) {
  let url = "";
  if(isEmpty(perPage)){
    url = ApiUrl(`v2/packages`);
  }else{
    url = ApiUrl(`v2/packages?${PageParams(page, perPage)}`);
  }
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { packages: 'packages', pagination: 'meta.pagination' },
  }, false);
}

export function fetchPackageDetails(id) {
  const url = ApiUrl(`v2/packages/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { package_details: ''} });
}

export function savePackage(isNew, data) {
  const url = isNew ? ApiUrl('v2/packages') : ApiUrl(`v2/packages/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, { responseDataKeys: { packages: '' } }, false);
}

export function deletePackage(id) {
  const url = ApiUrl(`v2/packages/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse);
}

export function fetchAllModules() {
  const url = ApiUrl(`v2/packages/modules_n_actions_for_packages`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { modules: 'packages'},
  }, false);
}

export function fetchPackageModules(id) {
  const url = ApiUrl(`v2/packages?package_id=${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { packages: 'packages'},
  }, false);
}
