import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  setObjectToFormData,
} from "./ApiUtils";
import userStore from "../stores/UserStore";

export function fetchBillingAccounts(org_id) {
  const url = ApiUrl(
    `v2/integrations/integration_companies?organization_id=${org_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { integration_companies: "integration_companies" },
  });
}

export function deleteBillingAccounts(id) {
  const org_id = userStore.getStateValue("selectedOrg");
  const url = ApiUrl(
    `v2/integrations/delete_integration_company?integration_company_id=${id}&organization_id=${org_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { message: "message" },
  });
}

export function saveBillingAccounts(data) {
  data.organization_id = userStore.getStateValue("selectedOrg");
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v2/integrations/create_integration_company");

  return Call("post", url, formData, ParseGeneralResponse, {}, true);
}

export function updateBillingAccounts(id, data) {
  const org_id = userStore.getStateValue("selectedOrg");
  data.organization_id = org_id;
  data.integration_company_id = id;
  const formData = setObjectToFormData(data);
  const url = ApiUrl(`v2/integrations/create_integration_company`);
  return Call("post", url, formData, ParseGeneralResponse, {}, true);
}
