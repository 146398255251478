import React, { Component } from "react";
import { TemplateParamsApi } from "../api/TemplateParamsApi";
import { alertMessage } from "../common/Common";
import {
  Button,
  Col,
  Row,
  Spin,
} from "../common/UIComponents";
import BaseModal from "../components/BaseModal";
import TemplateParamsForm from "../components/configurations/template_params/Form";
import TemplateParamsList from "../components/configurations/template_params/List";
import I18n from "../common/I18n";
import AppConfig from "../config/AppConfig";

class TemplateParams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: false,
      pagination: {},
      showFormModal: false,
      templates: [],
      currentTemplate: {},
      isNew: true,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }
  componentDidMount() {
    this.getTemplateParams();
  }
  getTemplateParams = (initial, cb) => {
    this.setState({ inProgress: true, currentTemplate: {} });
    const { filter } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    TemplateParamsApi.fetch("list", page, perPage).then((result) => {
      if (result.success) {
        this.setState(
          {
            templates: result.params || [],
            inProgress: false,
            pagination: result.pagination,
          },
          () => {
            if (cb) {
              cb();
            }
          }
        );
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.tableOptions = { pagination, filters, sorter };
    this.getTemplateParams();
  }

  handleOnModalClose = () => {
    if (this.state.showFormModal && this.state.currentTemplate.id) {
      this.getTemplateParams();
    }
    this.setState({
      showFormModal: false,
      currentTemplate: {},
    });
  };
  handleAddClick = () => {
    this.setState({
      currentTemplate: {},
      showFormModal: true,
    });
  };
  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false, currentTemplate: {} }, () => {
      this.getTemplateParams();
    });
  };
  renderTemplateForm = () => {
    let title;
    let isNew;
    if (this.state.currentTemplate.id) {
      title = I18n.t("template_params.edit");
      isNew = false;
    } else {
      title = I18n.t("template_params.add_param");
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={this.handleOnModalClose}
        width="40%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <TemplateParamsForm
          onCancel={this.handleOnModalClose}
          currentTemplate={
            this.state.currentTemplate.id ? this.state.currentTemplate : {}
          }
          onSuccess={(message) => this.handleSaveSuccess(message)}
          isNew={isNew}
          
        />
      </BaseModal>
    );
  };
  handleEditClick = (id) => {
    const index = _.findIndex(this.state.templates, ["id", id]);
    const currentTemplate = Object.assign({}, this.state.templates[index]);
    this.setState({ currentTemplate, showFormModal: true });
  };
  handleDelete = (id) => {
    this.setState({ inProgress: true });
    TemplateParamsApi.delete(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("template_params.deleted"));
        this.getTemplateParams();
      } else {
        alertMessage(result.errors, "error", 10);
        this.setState({ inProgress: false });
      }
    });
  };

  render() {
    const { pagination } = this.state;
    return (
      <div className="content-outer">
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>Template Params</Col>
            <Col xs={12} className="page-actions">
              <Button type="primary" icon="plus" onClick={this.handleAddClick}>
                {I18n.t("template_params.add")}
              </Button>
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row className="marginTop20">
              <Col md={{ offset: 4, span: 16 }}>
                <TemplateParamsList
                  size="small"
                  data={this.state.templates}
                  scroll = {{y:"calc(100vh - 260px)"}}
                  editClick={(id) => this.handleEditClick(id)}
                  deleteClick={(id) => this.handleDelete(id)}
                  pagination={{
                    total: pagination.total_count,
                    current: pagination.current_page,
                    pageSize: pagination.per_page,
                  }}
                  tableChange={
                    (tablePagination, filters, sorter) =>
                      this.handleTableChange(tablePagination, filters, sorter)
                  }
                />
              </Col>
            </Row>
          </Spin>
          {this.state.showFormModal && this.renderTemplateForm()}
        </div>
      </div>
    );
  }
}

export default TemplateParams;
