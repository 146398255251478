import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchForms, fetchSubmittedForms, deleteForm, saveForm } from '../api/FormsApi';
import { Button, Col, Row, Spin } from '../common/UIComponents';
import FormsList from '../components/forms/List';
import BaseModal from '../components/BaseModal';
import Form from '../components/forms/Form';
import { alertMessage,  isEmpty } from '../common/Common';
import I18n from '../common/I18n';
import SubmittedForms from '../components/forms/SubmittedForms';
import Questionaries from './Questionaries';
import { checkServiceExistance, renderAlertMessage } from '../helpers/common';


class Forms extends Component {
  constructor() {
    super();
    this.state = {
      forms: [],
      currentForm: {},
      showFormModal: false,
      showQuestionModal: false,
      inProgress: false,
      isNew: true,
      showSubmittedForms: false,
      submittedForms: [],
    };
  }

  componentDidMount() {
    this.getForms();
  }

  getForms = (initial, cb) => {
    this.setState({ inProgress: true, currentForm: {} });
    fetchForms()
      .then((result) => {
        if (result.success) {
          this.setState({
            forms: result.forms.forms,
            inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleAddClick = () => {
    this.setState({ currentForm: {}, showFormModal: true });
  }

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.forms, ['id', id]);
    const currentForm = Object.assign({}, this.state.forms[index]);
    this.setState({ currentForm, showFormModal: true });
  }

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteForm(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getForms();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handlequestionnare = (id) => {
    const currentForm = _.find(this.state.forms, ['id', id]);
    this.setState({
      showQuestionModal: true,
      currentForm: !isEmpty(currentForm) ? currentForm : {},
    });
    // this.props.history.push({
    //   pathname: `/questionaries/${id}`,
    //   parentType: I18n.t('questionaries.forms'),
    // });
  }

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false }, () => {
      this.getForms();
    });
  }

  handleStatusChange = (id) => {
    const index = _.findIndex(this.state.forms, ['id', id]);
    const currentForm = Object.assign({}, this.state.forms[index]);
    currentForm.status = currentForm.status === 'active' ? 'inactive' : 'active';
    this.setState({ currentForm, isNew: false }, () => {
      saveForm(this.state.isNew, this.state.currentForm)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'));
            this.getForms();
          } else {
            alertMessage(result.errors, 'error', 10);
          }
        });
    });
  }

  handleSubmittedForms = (id) => {
    const index = _.findIndex(this.state.forms, ['id', id]);
    const currentForm = Object.assign({}, this.state.forms[index]);
    this.setState({ currentForm }, () => {
      fetchSubmittedForms(id)
        .then((result) => {
          if (result.success) {
            if (result.forms.length > 0) {
              this.setState({
                submittedForms: result.forms,
                inProgress: false,
                showSubmittedForms: true,
              });
            } else {
              alertMessage(I18n.t('messages.no_forms_submitted'), 'error', 10);
              this.setState({ inProgress: false });
            }
          } else {
            renderAlertMessage(result.errors)
            this.setState({ inProgress: false });
          }
        });
    });
  }

  handleOnModalClose = () => {
    if (this.state.showFormModal && this.state.currentForm.id) {
      this.getForms();
    }
    this.setState({ showFormModal: false });
  }

  // eslint-disable-next-line react/sort-comp
  renderFormModal = () => {
    let title;
    let isNew;
    if (this.state.currentForm.id) {
      title = I18n.t('form.edit');
      isNew = false;
    } else {
      title = I18n.t('form.add');
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        width="40%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <Form
          form={this.state.currentForm.id ? this.state.currentForm : {}}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
        />
      </BaseModal>
    );
  }

  handleQuestionModalClose = () => {
    this.setState({
      currentForm: {},
      showQuestionModal: false,
    });
  }

  renderQuestionModal = () => (
    <BaseModal
      title={`Questions of ${this.state.currentForm.name}`}
      onCancel={() => this.handleQuestionModalClose()}
      width="95%"
      style={{ top: 20 }}
      maskClosable={false}
    >
      <Questionaries
        currentRecord={this.state.currentForm.id ? this.state.currentForm : {}}
        onCancel={() => this.handleQuestionModalClose()}
        parentType={I18n.t('questionaries.forms')}
        parentId={this.state.currentForm.id || ''}
      />
    </BaseModal>
  )

  renderSubmittedForms = () => (
    <BaseModal
      title={`${I18n.t('form.customer')} of ${this.state.currentForm.name}`}
      onCancel={() => this.handleonListClose()}
      width="60%"
      style={{ top: 20 }}
    >
      <SubmittedForms
        forms={this.state.submittedForms}
        onCancel={() => this.handleonListClose()}
      />
    </BaseModal>
  )

  handleonListClose = () => {
    this.setState({
      showSubmittedForms: false,
    });
  }

  render() {
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col xs={12}>
              {I18n.t('menu.forms')}
            </Col>
            <Col xs={12} className='page-actions'>
              { checkServiceExistance('FC') && <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {I18n.t('form.new')}
                </Button>
              }
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            { checkServiceExistance('FI') && <Row>
              <Col>
                <FormsList
                  data={this.state.forms}
                  scroll = {{y:"calc(100vh - 220px)"}}
                  editClick={id => this.handleEditClick(id)}
                  deleteClick={id => this.handleDeleteClick(id)}
                  pagination={{ position: 'none' }}
                  statusChange={id => this.handleStatusChange(id)}
                  questionnare={id => this.handlequestionnare(id)}
                  openSubmittedForms={id => this.handleSubmittedForms(id)}
                />
              </Col>
            </Row>
          }
          </Spin>
          {this.state.showFormModal &&
            this.renderFormModal()
          }
          {this.state.showQuestionModal &&
            this.renderQuestionModal()
          }
          { this.state.showSubmittedForms &&
            this.renderSubmittedForms()
          }
        </div>
      </div>
    );
  }
}

Forms.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default Forms;
