import React, { useEffect, useState } from "react";
import { alertMessage, isEmpty } from "../common/Common";
import { RecurringRouteApi, TemplatesApi } from "../components/Templates/Api";
import AppConfig from "../config/AppConfig";
import TemplateActivityLogsList from "./TemplateActivityLogsList";
import _ from "lodash";
import { fetchOrderDetails } from "../api/OrdersApi";
import { Drawer } from "../common/UIComponents";
import EditOrderForm from "../components/orders/EditOrderForm";
import moment from "moment";
import { renderAlertMessage } from "../helpers/common";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };

const TemplateActivityLogs = (props) => {
  const [templateLog, setTemplateLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [selectedTemplateId, setselectedTemplateId] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState({});
  const [editForm, setEditForm] = useState(false);

  function closeEditFormModal() {
    setEditForm(false);
    setCurrentOrderId({});
  }


  function handleSuccess (message, reflectDetails = false, id = '') {
    alertMessage(message);
    if (!reflectDetails) {
      // setEditForm(false),
      ()=> {
        getTemplateLog()
      }
    }
    else {
    handleEditOrderClick(id);
    }
  }


  const handleEditOrderClick = (id) => {
    fetchOrderDetails(id)
      .then((result) => {
        if (result.success) {
          const currentOrderId = result.orders.find((order) => order.id === id) || {
            id,
          };
          setselectedTemplateId(id);
          setCurrentOrderId(currentOrderId);
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setEditForm(true);
      });
  };

  const { activeRecordId } = props;

  useEffect(() => {
    if (props.currentStat === 'ORDERS') {
      getTemplateLog();
    }
  }, []);

  const getTemplateLog = () => {
    setIsLoading(true);
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = AppConfig.ordersPerPage;
    TemplatesApi.getTemplateLog({ activeRecordId, page, perPage })
      .then((result) => {
        if (result.success) {
          setTemplateLog(result.data);
          if (_.isObject(result.pagination)) {
            setPagination({
              total: result.pagination.total_count,
              current: result.pagination.current_page,
              pageSize: result.pagination.per_page || AppConfig.ordersPerPage,
            });
          }
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    getTemplateLog();
  };

  const getLogData = (currentStat, templateLog, activeRecord) => {
    if (currentStat === 'ORDERS') {
      return templateLog.slice().sort((a, b) => {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateB.diff(dateA, "days");
      })
    }
    else if (currentStat === 'ROUTES') {
      return activeRecord && activeRecord.recurring_route_activity ? activeRecord.recurring_route_activity : []
    }
    else {
      return []
    }
  }

  return (
    <div>
      {(Array.isArray(templateLog) && templateLog.length > 0) || (props.currentStat == "ROUTES") ? (
        <TemplateActivityLogsList
          isMilitaryTime={props.isMilitaryTime}
          currentStat={ props.currentStat }
          data={getLogData(props.currentStat, templateLog, props.activeRecord)}
          isLoading={isLoading}
          pagination={pagination}
          tableChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(pagination, filter, sorter, currentTable)
          }
          handleEditOrder={(id) => handleEditOrderClick(id)}
          selectedTemplateId={selectedTemplateId}
          currentOrderId={currentOrderId}
          editForm={editForm}
        />
      ) : (
        <TemplateActivityLogsList  
          currentStat={ props.currentStat }
          data={ [] }
        />
      )}
      {editForm && (
        <Drawer
          placement="right"
          closable={false}
          maskClosable={false}
          onClose={closeEditFormModal}
          visible={editForm}
          width="90%"
        >
          <EditOrderForm
            customerDetails={currentOrderId}
            customerOrderId={currentOrderId.id}
            onCancel={closeEditFormModal}
            isNew={false}
            handleSuccess={handleSuccess}
          />
        </Drawer>
      )}
    </div>
  );
};

export default TemplateActivityLogs;
