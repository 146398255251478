import React from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Switch, Tooltip } from "../../common/UIComponents";
import { formatDimension, formatWeight } from "../../helpers/orders";
import { checkServiceExistance } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import AppConfig from "../../config/AppConfig";

class List extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "name",
        title: I18n.t("truck.name"),
        dataIndex: "name",
		className: "noWrap",
		render: (data) => (
        <Tooltip title= {data}> {data} </Tooltip> 
		),
        ...AppConfig.sortingOptions,
      },
    ];
    if (this.props.showVin !== false) {
      this.columns.push({
        key: "vin",
        title: I18n.t("truck.vin"),
        dataIndex: "vin",
        ...AppConfig.sortingOptions,
      });
    }

		this.columns.push(
			{
				key: "vehicle_make",
				title: I18n.t("truck.vehicle_make"),
				dataIndex: "vehicle_make",
			},
			{
				key: "vehicle_model",
				title: I18n.t("truck.vehicle_model"),
				dataIndex: "vehicle_model",
			},
			{
				key: "vehicle_number",
				title: I18n.t("truck.vehicle_number"),
				dataIndex: "vehicle_number",
			},
			// {
			//   key: 'capacity',
			//   title: I18n.t('truck.capacity'),
			//   dataIndex: 'capacity',
			// },
			// {
			// 	key: "size",
			// 	title: I18n.t("truck.size"),
			// 	dataIndex: "size",
			// },
			{
				key: "vehicle_weight",
				title: I18n.t("general.weight"),
				dataIndex: "vehicle_weight",
				render: (data) => (<div>{formatWeight(data)}</div>),
				...AppConfig.sortingOptions,
			},
			{
				key: "vehicle_type",
				title: I18n.t("truck.vehicle_type"),
				dataIndex: "vehicle_type",
			},
			// {
			// 	key: "vehicle_reference_id",
			// 	title: I18n.t("truck.reference_id"),
			// 	dataIndex: "vehicle_reference_id",
			// },
		{
			key: "dimensions",
			title: (
			<div>
				{I18n.t("order.dimensions")}&nbsp;(<small>L * W * H</small>)
			</div>
			),
			dataIndex: "vehicle_width",
			render: (data, record) => (
			<React.Fragment>
				{formatDimension(
				record.vehicle_length,
				record.vehicle_length_uom,
				record.vehicle_width,
				record.vehicle_width_uom,
				record.vehicle_height,
				record.vehicle_height_uom,
				false
				)}
			</React.Fragment>
			),
		}
		);
		if (this.props.showActions !== false) {
			this.columns.push(
				{
					key: "active_inactive",
					title: I18n.t("general.active_inactive"),
					dataIndex: "id",
					render: (data, record) =>
						checkServiceExistance("FLTUFS") ? (
							<Switch
								disabled={this.props.inProgress}
								checkedChildren={'Yes'}
								unCheckedChildren={'No'}
								checked={record.status === "active"}
								onChange={(e) => this.props.setStatus(data)}
								className={record.status !== "active" ? "switchComponent" : null}
							/>
						) : null,
					align: "center",
				},
				{
					key: "actions",
					title: "",
					dataIndex: "id",
					fixed: "right",
					render: (id) => (
						<div className="line-actions">
							{checkServiceExistance("FLTU") && (
								<Tooltip title={I18n.t("general.edit")}>
									<span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
								</Tooltip>
							)}
							{checkServiceExistance("FLTD") && (
								<Popconfirm
									placement="left"
									title={I18n.t("messages.delete_confirm")}
									onConfirm={() => this.props.deleteClick(id)}
									okText="Yes"
									cancelText="No"
								>
									<Tooltip title={I18n.t("general.delete")}>
										<span><DeleteIcon/></span>
									</Tooltip>
								</Popconfirm>
							)}
						</div>
					),
					width: "95px",
				}
			);
		}
	}
}

export default List;
