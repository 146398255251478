import React from "react";
import BaseList from "../components/BaseList";
import I18n from "../common/I18n";
import CustomerOrderDetails from "../components/orders/CustomerOrderDetails";
export default class UploadedOrderList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "#",
        key: "index",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: I18n.t("general.order_number"),
        dataIndex: "orderNumber",
        key: "orderNumber",
        render: (data, record) => {
          return (
            <div>
              <CustomerOrderDetails
                key={data}
                order_no={data}
                order={{
                  id: record.orderId,
                  customer_order_number: data,
                }}
                showEdit={true}
                editClick={() => this.props.handleEditOrder(record.orderId)}
              />
            </div>
          );
        },
      },
      {
        title: I18n.t("general.status"),
        dataIndex: "orderStatus",
        key: "orderStatus",
      },
    ];
  }
}
