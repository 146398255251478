/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: import each component separetly from antd to avoid including entire antd into packs
// other wise use babel-plugin-import for this and remove style import in App.jsx. Check antd documentation
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Input, Radio, Row, Select, Icon } from './UIComponents'
import { isEmpty } from './Common'
import MaterialTimePicker from '../components/orders/MaterialTimePicker'
import moment from "moment";

const RenderField = (props) => {
  const {
    data_type = 'text',
    element,
    currentValue,
    handleOnChange,
    className = '',
    styleObj = null,
    prefix = null,
    suffix = null,
    displayName = '',
    isMilitaryTime,
    options = [],
  } = props
  const size = 'small';
  const decimalPoints = window.localStorage.getItem('round_off_decimals');
  const renderElement = () => {
    // const { currentRecord , currentValue } = retrieveParamValue( component,orderType, mainParam, currentConfiguration,zone_id, innerParamIndex, innerParam);
    switch (data_type) {
      case 'string':
        return (
          <Row>
            <Col>
              {element === 'charge_type' ? (
                <Fragment>
                  <Radio.Group
                    onChange={(e) => handleOnChange(element, e.target.value)}
                    value={currentValue}
                    size={size}
                  >
                    <Radio value="percentage">Percentage</Radio>
                    <Radio value="flat">Flat</Radio>
                  </Radio.Group>
                </Fragment>
              ) : element === 'charge_value' ? (
                <Input
                  type="number"
                  addonBefore={!isEmpty(prefix) ? prefix : null}
                  addonAfter={!isEmpty(suffix) ? suffix : null}
                  value={currentValue}
                  onChange={(e) => handleOnChange(element, e.target.value)}
                  className={className ? className : ''}
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                />
              ) : (
                <Input
                  value={currentValue}
                  onChange={(e) => handleOnChange(element, e.target.value)}
                  size={size}
                />
              )}
            </Col>
          </Row>
        )
      case 'number':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentValue}
                onChange={(e) => handleOnChange(element, e.target.value)}
                addonBefore={!isEmpty(prefix) ? prefix : null}
                addonAfter={!isEmpty(suffix) ? suffix : null}
                className={className ? className : ''}
                min={0}
                precision={
                  param.code === 'min_charge' || param.code === 'max_charge'
                    ? decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        )
      case 'price':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentValue}
                onChange={(e) => handleOnChange(element, e.target.value)}
                addonBefore={<Icon type="dollar" />}
                className={className ? className : ''}
                min={0}
                precision={decimalPoints}
              />
            </Col>
          </Row>
        )
      case 'Radio':
        return (
          <Row>
            <Col>
              <Row>
                <Radio.Group
                  value={currentValue}
                  onChange={(e) => handleOnChange(e.target.value, value)}
                  size={size}
                >
                  {options.map((option) => (
                    <Radio value={option.value} autoFocus>
                      {option.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Row>
            </Col>
          </Row>
        )
      case 'Checkbox':
        return (
          <Row>
            <Col>
              <Checkbox
                checked={currentValue}
                onChange={(e) => handleOnChange(element, e.target.checked)}
              >
                {displayName}
              </Checkbox>
            </Col>
          </Row>
        )
      case 'Boolean':
        return (
          <Row>
            <Radio.Group
              value={currentValue}
              onChange={(e) => handleOnChange(element, e.target.value)}
              size={size}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        )
      case 'Los':
      case 'Select':
        return (
          <Row>
            <Col>
              <Select
                showSearch
                value={currentValue}
                style={{ width: '100%' }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => handleOnChange(element, e)}
                size={size}
              >
                <Select.Option key={'SELECT_ALL'} value={''}>
                  -- Select --
                </Select.Option>

                {options.map((qoption, qindex) => (
                      <Select.Option
                        key={qoption.option_key}
                        value={qoption.option_key}
                      >
                        {qoption.option_value}
                      </Select.Option>
                    ))}
              </Select>
            </Col>
          </Row>
        )
      case 'time':
        return (
          <Row>
            <Col>
              <MaterialTimePicker
                onChange={(e) =>
                  handleOnChange(element, e ? moment(e).format('HH:mm') : '')
                }
                value={currentValue ? currentValue : null}
                computeDisabledHours={() => []}
                computeDisabledMinutes={() => []}
                isMilitaryTime={isMilitaryTime}
                size="small"
              />
            </Col>
          </Row>
        )
      default:
        break
    }
  }

  return <Fragment>{renderElement()}</Fragment>
}
export default RenderField
