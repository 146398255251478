/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Card,
  Popover,
  Icon,
  Divider,
  Timeline,
  Tooltip,
  Button,
  Popconfirm,
} from "../../../common/UIComponents";
import {
  ellipseText,
  formatStopUTcTime,
  isEmpty,
} from "../../../common/Common";
import FormattedErrors from "../../common/FormattedErrors";
import StopDistanceInfo from "../../stops/StopDistanceInfo";
import { formatStopAddress, rearrangeStops } from "../../../helpers/stops";
import OrdersList from "./Orders";
import { getDependencyStops } from "../../../helpers/preplan";
import { checkServiceExistance, secondsToHms } from "../../../helpers/common";
import StopAddress from "../../stops/StopAddress";
import { checkErrors } from "../../../helpers/routes";
import lateImagIcon from "../../../../assets/images/time_warning.png";
import DeleteIcon from "../../common/DeleteIcon";
import BranchesIcon from "../../common/BranchesIcon";
import { OrgContext } from "../../../context/OrgContext";
import I18n from "../../../common/I18n";

const Stop = (props) => {
  const {
    dragStarts,
    selectedList,
    route,
    onClickStop,
    currentStop,
    moveStop,
    deleteClick,
    rearrangeForm,
    moveOrder,
    orderDetailsViewClick,
    deleteOrderClick,
    displayConfiguration,
    screen_from
  } = props;
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const [isMilitaryTime , setIsMilitaryTime] = useState(false);

  useEffect(() => {
    if (organizationSettings && organizationSettings.is_military_time ===
      "true") {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [ organizationSettings ]);

  const [reseqProcess, setReseqProcess] = useState(true);
  const [showOrdersList, setShowOrdersList] = useState([]);
  const ignoredStopTypes = ["WH", "DV"];
  const isNotOptimized =
    route.optimization_type === "auto" &&
    route.optimized === false &&
    (!route.optimizer_readable_error_message ||
      (_.isArray(route.optimizer_readable_error_message) &&
        route.optimizer_readable_error_message.length === 0)); // || (route.optimization_type === 'manual' && route.optimized === false)
  let tempStops = [...(route.stops || [])];
  if (tempStops.length > 2 && rearrangeForm) {
    tempStops = rearrangeStops(tempStops, false);
    const stopsData = tempStops.filter(
      (stop) =>
        stop.location &&
        !ignoredStopTypes.includes(stop.location_type) &&
        stop.status !== "COMPLETED" &&
        stop.status !== "ARRIVED"
    );
    tempStops = [...stopsData];
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // const onDragEnd = (result) => {
  //   // dropped outside the list
  //   if (!result.destination) {
  //     return;
  //   }

  //   tempStops = reorder(
  //     tempStops,
  //     result.source.index,
  //     result.destination.index,
  //   );
  // };

  // const getListStyle = isDraggingOver => ({
  //   userSelect: 'none',
  // });

  const beforeDrag = () => {
    setReseqProcess(false);
  };

  const showStopOrders = (id) => {
    const ordersList = showOrdersList;
    if (ordersList.includes(id)) {
      ordersList.splice(ordersList.indexOf(id), 1);
    } else {
      ordersList.push(id);
    }

    setShowOrdersList(ordersList);
  };

  const removeStopOrders = (id) => {
    const ordersList = showOrdersList;
    if (ordersList.includes(id)) {
      ordersList.splice(ordersList.indexOf(id), 1);
    }
    setShowOrdersList(ordersList);
  };

  const renderOrders = (stop, stopType, isDragging) => (
    <div className="stopBlock marginTop10 marginLeftM5">
      {!isDragging && (
        <Droppable droppableId={`orderDroppable${stop.id}`}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <OrdersList
                rowKey="customer_order_id"
                data={stop.orders || []}
                rowSelection={null}
                moveOrder={(id) =>
                  moveOrder(id, "move_order", stop.id, route.route_id)
                }
                orderDetailsViewClick={(id) =>
                  orderDetailsViewClick(id, route.route_id, stop.id)
                }
                deleteOrderClick={(id) => deleteOrderClick(id, route.route_id)}
                showAccessorial={false}
                showPod={false}
                showExceptions={false}
                showLos={false}
                showBilling={false}
                showActions
                showMoveAction // ={!isDragging}
                size="small"
                currentStop={stop}
                currentRoute={route}
                stopType={stopType}
                pagination={{ position: "none" }}
                displayConfiguration={displayConfiguration}
                screen_from={screen_from}
                isMilitaryTime={isMilitaryTime}
              >
                {Row}
              </OrdersList>
              {provided && provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );

  const buildStopCard = (stop, stopIndex) => {
    // const actual_start_date_time_with_tz = stop.actual_start_date_time_with_tz ? moment.utc(stop.actual_start_date_time_with_tz).format('MMM Do hh:mm A') : 'NA';
    // const actual_end_date_time_with_tz = stop.actual_end_date_time_with_tz ? moment.utc((stop.actual_end_date_time_with_tz)).format('MMM Do hh:mm A') : 'NA';
    // const scheduled_start_date_time_with_tz = stop.scheduled_start_date_time_with_tz ? moment.utc(stop.scheduled_start_date_time_with_tz).format('MMM Do hh:mm A') : 'NA';
    // const scheduled_end_date_time_with_tz = stop.scheduled_end_date_time_with_tz ? moment.utc(stop.scheduled_end_date_time_with_tz).format('MMM Do hh:mm A') : 'NA';
    // const estimated_arrival_date_time_with_tz = stop.estimated_arrival_date_time_with_tz ? moment.utc(stop.estimated_arrival_date_time_with_tz).format('MMM Do hh:mm A') : 'NA';
    // const estimated_departure_date_time_with_tz = stop.estimated_departure_date_time_with_tz ? moment.utc(stop.estimated_departure_date_time_with_tz).format('MMM Do hh:mm A') : 'NA';
    const address =
    stop.location && stop.location.l_address
      ? formatStopAddress(stop.location.l_address)
      : "";
    const estimated_departure_date_time_with_tz = !isEmpty(
      stop.estimated_departure_date_time_with_tz
    )
      ? stop.estimated_departure_date_time_with_tz
      : "";
    const estimated_arrival_date_time_with_tz = !isEmpty(
      stop.estimated_arrival_date_time_with_tz
    )
      ? stop.estimated_arrival_date_time_with_tz
      : "";
    const scheduled_start_date_time_with_tz = !isEmpty(
      stop.scheduled_start_date_time_with_tz
    )
      ? stop.scheduled_start_date_time_with_tz
      : "";
    const scheduled_end_date_time_with_tz = !isEmpty(
      stop.scheduled_end_date_time_with_tz
    )
      ? stop.scheduled_end_date_time_with_tz
      : "";
    const service_duration = stop.service_duration
      ? secondsToHms(parseInt(stop.service_duration), true, true, false)
      : 0;
    // const isShowScheduled = scheduled_start_date_time_with_tz !== 'NA' || scheduled_end_date_time_with_tz !== 'NA';
    // const isShowEstimated = estimated_arrival_date_time_with_tz !== 'NA' || estimated_departure_date_time_with_tz !== 'NA';
    const isShowScheduled =
      scheduled_start_date_time_with_tz !== "" ||
      scheduled_end_date_time_with_tz !== "";
    const isShowEstimated =
      estimated_arrival_date_time_with_tz !== "" ||
      estimated_departure_date_time_with_tz !== "";
    
    const errors = stop.stop_errors &&
      _.isArray(stop.stop_errors) ? stop.stop_errors : [];
      
    const hasErrors = errors.length > 0;
    const apptErrors = checkErrors(errors, 'appointment');
    const timeZone = stop.stop_tz_short_name;
    const stopType =
      stop.location && stop.location_type ? stop.location_type : "";
    const isSelected =
      stopType === "CS"
        ? selectedList.includes(stop.id)
        : stop.id === currentStop.id;
    const dependencyStops = getDependencyStops(route, stop.id, "id", "stops");
    return (
      <Draggable
        key={stop.id}
        draggableId={stop.id}
        index={stop.stop_number - 2} // WH Stop
        isDragDisabled={stopType !== "CS"}
        onClick={() => removeStopOrders(stop.id)}
        // shouldRespectForcePress
      >
        {(provided, snapshot) => {
          const isDragging = !rearrangeForm && snapshot.isDragging;
          const displayText =
            isDragging &&
            selectedList.length > 1 &&
            selectedList.includes(stop.id)
              ? `Moving ${selectedList.length} stops`
              : ellipseText(stop.formatted_stop_name, 30);

          return (
            <div
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              onTransitionBegin={() => removeStopOrders(stop.id)}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <Card
                size="small"
                onClick={(e) => onClickStop(e, stop.id)}
                className={
                  stop.id === currentStop.id
                    ? "boxShadowActive currentActiveStop"
                    : "boxShadow"
                }
                bodyStyle={{
                  paddingLeft: 10,
                }}
                style={{
                  marginLeft: 8,
                  border: isSelected
                    ? `2px solid #${route.bgColor}`
                    : "1px solid #DADADA",
                  borderRadius: 10,
                  padding: 8,
                  backgroundColor: isDragging ? "#607ad6" : "white",
                  color: isDragging ? "#FFF" : "inherit",
                }}
              >
                <Row>
                  <Col xs={19} className="fontsize11 smallText textBold">
                    <span>
                      {isDragging ? (
                        <span>
                          <Icon type="home" /> &nbsp; {displayText}
                        </span>
                      ) : (
                        <Tooltip title={!rearrangeForm ? stop.formatted_stop_name : undefined}>
                          {displayText}
                        </Tooltip>
                      )}
                    </span>
                    &nbsp;&nbsp;
                  </Col>
                  <Col xs={5} className="blkCenterRight textBold paddingRight7">
                    {hasErrors && (
                      <Popover
                        className="fontsize11 marginTopM3"
                        content={FormattedErrors(stop.stop_errors || [])}
                      >
                       {
                        apptErrors.length === 0 ?
                        <Icon
                          type="warning"
                          className="textRed stopWarningBlk"
                          style={{ marginTop: -1 }}
                        />
                        :
                         <img src={lateImagIcon} style={{ width: 18 }} className="stopWarningBlk" />
                       }
                        &nbsp;&nbsp;
                      </Popover>
                    )}
                    {stop.location && stop.location_type === "CS" && (
                      <span
                        style={{
                          marginTop: -5,
                          marginRight: -16,
                        }}
                      >
                        {!isDragging && checkServiceExistance('NRRSFR') && (
                          <Popconfirm
                            placement="left"
                            title={
                              dependencyStops.length === 0
                                ? I18n.t("messages.delete_confirm")
                                : I18n.t("messages.dependency_delete_confirm", {
                                    field: "stop",
                                  })
                            }
                            onConfirm={() =>
                              deleteClick(route.route_id, [stop.id])
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip
                              title={!rearrangeForm ? I18n.t("general.delete_stop_from_route") : undefined}
                              placement="bottomRight"
                            >
                              {/* <img src={unlink} style={{ width: 12, marginRight: 8 }} alt="unlink" />{I18n.t('general.remove')} */}
                              <DeleteIcon/>
                              &nbsp;&nbsp;
                            </Tooltip>
                          </Popconfirm>
                        )}
                        { checkServiceExistance('NRTS') &&
                        <span {...provided.dragHandleProps}>
                          {/* <Popconfirm
                        placement="topRight"
                        title={I18n.t('messages.move_confirm', { field: '' })}
                        onConfirm={() => moveStop(stop.id, 'move_stop', route.route_id, route.route_id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title={I18n.t('preplan.move_stop')} placement="bottom">
                          <Icon type="menu" className="textBold" style={{ width: 16 }} /> &nbsp;&nbsp;
                        </Tooltip>
                      </Popconfirm> */}
                          <Tooltip
                            title={!rearrangeForm ? I18n.t("preplan.move_stop") : undefined}
                            placement="bottom"
                          >
                            <Icon
                              type="menu"
                              className="textBold"
                              style={{
                                width: 16,
                              }}
                            />{" "}
                            &nbsp;&nbsp;
                          </Tooltip>
                        </span>
                        }
                      </span>
                    )}
                  </Col>
                </Row>
                {stop?.location?.company_name ?
                  (<Row style={{ paddingTop: 5 }}>
                    <Col xs={21} style={{ paddingTop: 1 }} className="fontsize11 smallText">
                      <Icon type="bank" />&nbsp;&nbsp;{stop.location.company_name}
                    </Col>
                  </Row>) :(<Fragment/>)
                }
                {stopType === "CS" && (
                  <StopAddress address={address} title="" showTooltip={!rearrangeForm}/>
                )}
                {!isDragging && stopType === "CS" && (
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={21} style={{ paddingTop: 1 }}>
                      <div className="fontsize11 smallText">
                        <Tooltip title={!rearrangeForm ? I18n.t("order.scheduled_time") : undefined}>
                          {I18n.t("routes.scheduled_time")}
                          :&nbsp;
                          {isShowScheduled ? (
                            <Fragment>
                              {formatStopUTcTime(
                                scheduled_start_date_time_with_tz,
                                scheduled_end_date_time_with_tz,
                                timeZone,false,isMilitaryTime
                              )}
                            </Fragment>
                          ) : (
                            "Not available"
                          )}
                        </Tooltip>
                      </div>
                    </Col>
                    <Col xs={3} className="blkCenterRight paddingRight5" />
                  </Row>
                )}
                {!isDragging && (
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={21} style={{ paddingTop: 1 }}>
                      <div className="fontsize11 smallText">
                        <Tooltip title={!rearrangeForm ? I18n.t("order.estimated_time") : undefined}>
                          {I18n.t("routes.estimated_time")}
                          :&nbsp;
                          {!isNotOptimized &&
                          (estimated_arrival_date_time_with_tz !== "" ||
                            estimated_departure_date_time_with_tz !== "") ? (
                            <Fragment>
                              {isShowEstimated ? (
                                <Fragment>
                                  {formatStopUTcTime(
                                    estimated_arrival_date_time_with_tz,
                                    estimated_departure_date_time_with_tz,
                                    timeZone,false,isMilitaryTime
                                  )}
                                </Fragment>
                              ) : (
                                "NA"
                              )}
                            </Fragment>
                          ) : (
                            "Not available"
                          )}
                        </Tooltip>
                      </div>
                    </Col>
                    <Col xs={3} className="blkCenterRight paddingRight5">
                      <p
                        className="badgeDiv cursorPointer"
                        style={{
                          backgroundColor: `#${route.bgColor}`,
                          color: `#${route.fgColor}`,
                          fontSize: 12,
                          marginTop: -5,
                        }}
                        onClick={() => showStopOrders(stop.id)}
                      >
                        {stop.orders.length}
                      </p>
                    </Col>
                  </Row>
                )}
                {
                  // (selectedList.includes(stop.id) || stop.id === currentStop.id) && stop.orders.length > 0 &&
                }
                {showOrdersList.includes(stop.id) &&
                  renderOrders(stop, stopType, isDragging)}
              </Card>
            </div>
          );
        }}
      </Draggable>
    );
  };

  const buildDistanceinfo = (stop) =>
    stop.interconnection_status && (
      <Timeline.Item
        key={`item_dis${stop.id}`}
        className="connectedItem paddingBottom5"
        dot={
          <div
            style={{
              borderColor: `#${route.bgColor}`,
            }}
            className="connectedDot"
          />
        }
      >
        <Popover
          placement="bottom"
          title="Distance  between stops"
          content={<StopDistanceInfo stop={stop} />}
        >
          <Row className="distanceInfo">
            <Col xs={1}>
              <Divider />
            </Col>
            <Col xs={22}>
              <Row>
                <Col xs={12}>
                  <Row>
                    {" "}
                    <Col xs={24}>
                      &nbsp;&nbsp;
                      <BranchesIcon/>
                      &nbsp;&nbsp;Distance&nbsp;&nbsp;:&nbsp;&nbsp;
                      {stop.distance}&nbsp;
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="alignRight">
                  <Row>
                    {" "}
                    <Col xs={24}>
                      <Icon type="clock-circle" />
                      &nbsp;&nbsp;Time&nbsp;&nbsp;:&nbsp;&nbsp;
                      {stop.time}&nbsp;&nbsp;
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={1}>
              <Divider />
            </Col>
          </Row>
        </Popover>
      </Timeline.Item>
    );

  const buildTimeLineStops = () =>
    tempStops.map((stop, stopIndex) => (
      <Fragment key={`timeline_fragment${stop.id}`}>
        <Timeline.Item
          key={`timeline_item${stop.id}`}
          dot={
            <Button
              style={{
                backgroundColor: `#${route.bgColor}`,
                color: `#${route.fgColor}`,
              }}
              shape="circle"
              size="small"
              //onClick={() => this.getCurrentStop(stop.id)}
              onClick={() => {}}
            >
              {stop.location && stop.location_type === "WH" ? (
                <Icon type="home" />
              ) : (
                stop.stop_number - 1
              )}
            </Button>
          }
          color={route.fgColor}
        >
          <Fragment>{buildStopCard(stop, stopIndex)}</Fragment>
        </Timeline.Item>
        {!rearrangeForm && !isNotOptimized && buildDistanceinfo(stop)}
      </Fragment>
    ));

  const buildStops = () =>
    tempStops.map((stop, stopIndex) => (
      <div key={`stop_fragment${stop.id}`} style={{ marginBottom: 20 }}>
        {buildStopCard(stop, stopIndex)}
      </div>
    ));

  return (
    <Fragment>
      {tempStops && tempStops.length > 0 && (
        <div>
          {reseqProcess ? (
            <Timeline>{buildTimeLineStops()}</Timeline>
          ) : (
            buildStops()
          )}
        </div>
      )}
    </Fragment>
  );
};
export default Stop;

Stop.propTypes = {
  route: PropTypes.shape().isRequired,
  currentStop: PropTypes.shape().isRequired,
  rearrangeForm: PropTypes.bool.isRequired,
  selectedList: PropTypes.arrayOf(PropTypes.string).isRequired,
  moveStop: PropTypes.func,
  deleteClick: PropTypes.func.isRequired,
  onClickStop: PropTypes.func.isRequired,
};

Stop.defaultProps = {
  moveStop: null,
};