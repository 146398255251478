import React from 'react'
import PropTypes from "prop-types";
import BaseModal from '../BaseModal';
import UnassignedRows from "../../components/preplan/rows/UsersList";
import { Button, Col, Row } from 'antd';
import I18n from '../../common/I18n';

function PreplanDrivers(props) {
  const { handleAvailableDriversClose, availableDrivers, rowSelection, resetSelectedAvailableDrivers, handleAddDriversToPreplan, inProgress, checkedAvailableDrivers
  } = props;
  return (
    <BaseModal
      title={ I18n.t("general.drivers") }
      width="50%"
      onCancel={ handleAvailableDriversClose }
      maskClosable={ false }
    >
      <UnassignedRows
        data={ _.sortBy(availableDrivers, "employee_code") }
        showStops={ false }
        showLinks={ false }
        size="small"
        pagination={ { position: "none" } }
        rowSelection={ rowSelection }
        scroll={ { y: 500 } }
      />
      <Row>
        <Col xs={ 24 } className="marginTop20">
          <div className="alignCenter">
            <Button
              size="default"
              type="danger"
              style={ {
                width: "auto",
              } }
              onClick={ resetSelectedAvailableDrivers }
            >
              { I18n.t("general.clear") }
            </Button>
            &nbsp;&nbsp;
            <Button
              size="default"
              type="primary"
              className="marginRight5"
              onClick={ handleAddDriversToPreplan }
              loading={ inProgress }
              disabled={
                checkedAvailableDrivers.selectedKeys.length === 0
              }
            >
              { I18n.t("general.save") }
            </Button>
          </div>
        </Col>
      </Row>
    </BaseModal>

  );
}

export default PreplanDrivers


PreplanDrivers.propTypes = {
  handleAvailableDriversClose: PropTypes.func,
  availableDrivers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rowSelection: PropTypes.shape(),
  resetSelectedAvailableDrivers: PropTypes.func,
  handleAddDriversToPreplan: PropTypes.func,
  inProgress: PropTypes.bool,
  checkedAvailableDrivers: PropTypes.shape(),
}

PreplanDrivers.defaultProps = {
  handleAvailableDriversClose: () => {},
  rowSelection: null,
  availableDrivers: [],
  resetSelectedAvailableDrivers: () => {},
  handleAddDriversToPreplan: () => {},
  inProgress: false,
  checkedAvailableDrivers: {},
}
