import React from "react";
import BaseList from "../BaseList";
import {
  compareString,
  isEmpty,
  checkNegitive,
} from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Link,
  Popconfirm,
  Popover,
  Tooltip,
  Button,
  Row,
  Col,
} from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import CustomIconButton from "../../common/CustomIconButton";
import { withRouter } from "react-router";


class List extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    const columns = [
      {
        key: "account_code",
        title: I18n.t("account.code"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.history.push(
                        `/accounts/${record.account_id}/settings`
                      );
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        width: 110,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: "account_name",
        title: I18n.t("account.account_name"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      props.history.push(
                        `/accounts/${record.account_id}/settings`
                      );
                    }
                  }}
                >
                  <Tooltip placement="topLeft" title= {record.account_name}>{!isEmpty(data) ? data : record.account_code}</Tooltip>
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        width: 170,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      // {
      //   key: "balance_amount",
      //   title: I18n.t("account_receivables.payment_received"),
      //   dataIndex: "available_amount",
      //   render: (data) => (
      //     <div className="alignRight paddingRight10">{checkNegitive(data)}</div>
      //   ),
      //   width: 140,
      //   align: 'right'
      // },
      // {
      //   key: "open_amount",
      //   title: I18n.t("account_receivables.to_pay_amount"),
      //   dataIndex: "open_amount",
      //   render: (data) => (
      //     <div className="alignRight paddingRight10">{checkNegitive(data)}</div>
      //   ),
      //   width: 140,
      //   align: 'right'
      // },
      // {
      //   key: "balance_available",
      //   title: I18n.t("account_receivables.unapplied_funds"),
      //   //dataIndex: "balance_available",
      //   render: (data) => {
      //     const available_amount = checkNegitive(data.available_amount, false);
      //     const open_amount = checkNegitive(data.open_amount, false);
      //   return (
      //     <div className="alignRight paddingRight10">{checkNegitive(available_amount- open_amount)}</div>
      //   )},
      //   width: 140,
      //   align: 'right'
      // },
      {
        key:"account_ammount_received",
        // title: I18n.t("account_receivables.amount_received"),
        title:"Amount Received ($)",
        dataIndex: "account_ammount_received",
        render: (data) => (
          <div className="alignRight">{checkNegitive(data)}</div>
        ),
        width: 130,
        align: 'right',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key:"applied_amount",
        // title: I18n.t("account_receivables.applied_funds"),
        title:"Applied Funds ($)",
        dataIndex: "applied_amount",
        render: (data) => (
          <div className="alignRight">{checkNegitive(data)}</div>
        ),
        width: 120,
        align: 'right',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key:"available_amount",
        // title: I18n.t("account_receivables.unapplied_funds"),
        title:"Unapplied Funds ($)",
        dataIndex: "available_amount",
        render: (data) => (
          <div className="alignRight">{checkNegitive(data)}</div>
        ),
        width: 140,
        align: 'right',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key:"open_amount",
        // title: I18n.t("account_receivables.open_invoice_amount"),
        title:"Open Invoice ($)",
        dataIndex: "open_amount",
        render: (data) => (
          <div className="alignRight paddingRight10">{checkNegitive(data)}</div>
        ),
        width: 120,
        align: 'right',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: "actions",
        // title: I18n.t("general.manage"),
        dataIndex: "account_id",
        render: (id) => (
          <div className="line-actions">
            {checkServiceExistance("BLIND") && (
              <Row type="flex" style={{flexFlow:"nowrap"}}>
                <Col>
                  
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => this.props.handlePayments(id)}
                      icon='pay-circle'
                    >
                      {I18n.t("general.pay")}
                    </Button>
                 
                </Col>
                &nbsp;
                <Col>
                 
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => this.props.handleRedirect(id)}
                      icon='profile'
                    >
                      {I18n.t("general.view_check_details")}
                    </Button>
                  
                </Col>
              </Row>
            )}
          </div>
        ),
        width: 160,
      },
    ];
    this.columns = [...columns]
    // this.columns = columns.map((column) => ({
    //   ...column,
    //   width: this.getMaxWidth(column.dataIndex),
    // }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };

  getMaxWidth = (title) => {
    const maxWidth = 400; // Set a maximum width to avoid column being too wide
    const minWidth = 120; // Set a minimum width to avoid column being too narrow
    const padding = 24; // Set the padding to match the table's default padding
    const columnData =
      title !== "id"
        ? this.props.data.map((row) => row[title])
        : [" Action Icons "];
    let maxLength = Math.max(
      ...columnData.map((text) => text && text.toString().length)
    );

    if (title === "contact_person") {
      if (maxLength > 25) {
        maxLength = 25;
      }
    }
    const width = Math.min(
      maxWidth,
      Math.max(minWidth, maxLength * 8 + padding)
    ); // Set a width based on the maximum length of the characters
    return width;
  };
}

export default withRouter(List);
