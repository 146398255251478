import React from 'react'
import ListBilling from './ListBilling'

export const BillingViewList = (props) => {
  return (
    <div>
        <ListBilling  dispContext={props.dispContext} />
    </div>
  )
}
