import React from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import { compareString } from "../../common/Common";
import {
  Icon,
  Popconfirm,
  Tooltip,
  Typography,
} from "../../common/UIComponents";
import { checkServiceExistance, formatFullName } from "../../helpers/common";
import userStore from "../../stores/UserStore";
import AppConfig from "../../config/AppConfig";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import I18n from "../../common/I18n";
const { Paragraph } = Typography;
// const organization_id = userStore.getStateValue("selectedOrg");
class LocationsList extends BaseList {
  constructor(props) {
    super(props);
    const isSuperAdmin = userStore.superAdmin();
    this.columns = [
      {
        key: "name",
        title: I18n.t("general.name"),
        dataIndex: "name",
        className:"noWrap",
        render: (text) => (
          <Tooltip title={text}>{text}</Tooltip>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "location_code",
        title: "Code",
        dataIndex: "location_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "l_address",
        title: I18n.t("general.address"),
        className:"noWrap",
        render: (data) => {
          const address = _.values(data.l_address)
            .splice(1, 6)
            .filter(Boolean)
            .join();
          return (
          <Tooltip title={address}> {address} </Tooltip>
          );
        },
        width: 300,
      },
      {
        key: "contcat_name",
        title: I18n.t("location.contact_name"),
        className:"noWrap",
        render: (record) => ({
          children:<Tooltip title={formatFullName(record)}>  {formatFullName(record)} </Tooltip>,
        }),
        width: 150,
      },
      {
        key: "phone",
        title: I18n.t("general.phone"),
        dataIndex: "phone",
      },
      {
        key: "email",
        title: I18n.t("general.email"),
        dataIndex: "email",
        render: (data) => <Tooltip title={data}>{data}</Tooltip>,
        className: "noWrap",
      },
    ];
    if (!isSuperAdmin) {
      this.columns.push(
        {
          key: "link",
          title: I18n.t("location.track_link"),
          render: (data) => {
            const organization_id = userStore.getStateValue("selectedOrg");

            return (
              <span>
                <Paragraph
                  style={{ marginBottom: 0 }}
                  copyable={{
                    text: `${
                      window.location.origin
                    }/drivers_tracking/track_driver?organization_id=${userStore.getStateValue(
                      "selectedOrg"
                    )}&location_code=${data.location_code}`,
                  }}
                ></Paragraph>
              </span>
            );
          },
          align: "center",
        },
        {
          key: "zones",
          title: I18n.t("location.delivery_zones"),
          dataIndex: "id",
          render: (id) => (
            <span>
              {checkServiceExistance("DZI") && (
                <Tooltip title="Click to manage Delivery Zones">
                  <Icon
                    type="global"
                    onClick={() => this.props.showDeliveryZones(id)}
                  />
                </Tooltip>
              )}
            </span>
          ),
          align: "center",
        }
        // {
        //   key: 'zipcodes',
        //   title: I18n.t('location.mapping_heading'),
        //   dataIndex: 'id',
        //   render: id => (
        //     <span><Icon type="environment" onClick={() => this.props.showZips(id)} /></span>
        //   ),
        //   align: 'center',
        // },
      );
    }
    this.columns.push({
      key: "actions",
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id) => (
        <div className="line-actions">
          {/* <Icon
            type='smile'
            onClick={() => this.props.showReviews(id)}
          /> */}
          {checkServiceExistance("WHU") && (
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)} /></span>
            </Tooltip>
          )}
          {!isSuperAdmin && checkServiceExistance("WHD") && (
            <Tooltip title={I18n.t("general.delete")}>
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => this.props.deleteClick(id)}
                okText="Yes"
                cancelText="No"
              >
                <span><DeleteIcon /></span>
              </Popconfirm>
            </Tooltip>
          )}
        </div>
      ),
      width: "95px",
    });
  }
}

export default LocationsList;