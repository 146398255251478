import React, { Component, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Row,
  Spin,
  Card,
  Icon,
  Divider,
  Statistic,
} from '../common/UIComponents';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import DriversList from '../components/dashboard/DriversList';
import { fetchStatus, fetchRouteLogs } from '../api/Dashboard';
import { fetchOrdersStatus } from '../api/OrdersApi';
import { fetchUsers, setUserStatus } from '../api/UsersApi';
import AppConfig from '../config/AppConfig';
import { checkServiceExistance, formatByTimeConfig, renderAlertMessage } from '../helpers/common';
import _ from "lodash"
import SettingIcon from '../components/common/SettingIcon';
import BranchesIcon from '../components/common/BranchesIcon';
import { withRouter } from 'react-router';
import { WarehouseContext } from '../context/WarehouseContext';
import { OrgContext } from '../context/OrgContext';
import { UserContext } from '../context/UserContext';

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      drivers: [],
      routeLogs: [],
      data: '',
      inProgress: false,
      orders_data: '',
      organizationSettings: {},
      curentUser : {},
    };
    // debounce function
    this.getStatus = _.debounce(this.getStatus, 1000);
    this.getOrdersStatus = _.debounce(this.getOrdersStatus, 1000);
    this.getDriversList = _.debounce(this.getDriversList, 1000);
    this.getRouteLogs = _.debounce(this.getRouteLogs, 1000);
  }

  componentDidMount() {
    this.getStatus();
    this.getOrdersStatus();
    this.getDriversList();
    this.getRouteLogs();
    this.timer = setInterval(() => {
      this.getStatus();
      this.getRouteLogs();
    }, AppConfig.fetchRefreshTime);
    this.setState({
      organizationSettings: this.props.organizationSettings 
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings });
    }

    // // on warehouse change
    // if (!_.isEqual(prevProps.appContexts.warehouseContext.selectedWarehouses, this.props.appContexts.warehouseContext.selectedWarehouses) || !_.isEqual(prevProps.appContexts.warehouseContext.warehouses , this.props.appContexts.warehouseContext.warehouses) && (!this.props.appContexts.warehouseContext.isFetchingWarehouses)) {
    //   this.getStatus();
    //   this.getOrdersStatus();
    //   this.getDriversList();
    //   this.getRouteLogs();
    // }
    const { selectedWarehouses, warehouses, isFetchingWarehouses } = this.props.appContexts.warehouseContext;
    const { selectedWarehouses: prevSelectedWarehouses, warehouses: prevWarehouses } = prevProps.appContexts.warehouseContext;

    if (!_.isEqual(prevSelectedWarehouses, selectedWarehouses) || !_.isEqual(prevWarehouses, warehouses) && (!isFetchingWarehouses)) {
      this.getStatus();
      this.getOrdersStatus();
      this.getDriversList();
      this.getRouteLogs();
    }

  }

  handleUserStatus = (element, value, id) => {
    const { drivers } = this.state;
    const index = _.findIndex(drivers, ["id", id]);
    const user = Object.assign({}, drivers[index], {
      [element]: value,
    });
    drivers[index][element] = value;
    const data = {
      driver_id: id,
    };
    setUserStatus(data).then((result) => {
      if (result.success) {
        this.setState({ currentUser: user, drivers });
        alertMessage("Changed Successfully", "success", 10);
        this.getDriversList();
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };
  

  getStatus = () => {
    this.setState({ inProgress: true });
    fetchStatus({}, 'DASHBOARD').then((result) => {
      if (result.success) {
        this.setState({
          data: result.data[0],
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getOrdersStatus = () => {
    this.setState({ inProgress: true });
    fetchOrdersStatus().then((result) => {
      if (result.success) {
        this.setState({
          orders_data: result.data[0],
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getDriversList(initial, cb) {
    this.setState({ inProgress: true });
    fetchUsers('driver', 1, 5, true).then((result) => {
      if (result.success) {
        this.setState(
          {
            drivers: result.users,
            inProgress: false,
          },
          () => {
            if (cb) {
              cb();
            }
          },
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  }

  getRouteLogs(initial, cb) {
    this.setState({ isFetchingLogs: true });
    fetchRouteLogs().then((result) => {
      if (result.success) {
        this.setState(
          {
            routeLogs: result.routeLogs,
            isFetchingLogs: false,
          },
          () => {
            if (cb) {
              cb();
            }
          },
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ isFetchingLogs: false });
      }
    });
  }

  redirect = (filter) => {
    this.props.history.push({
      pathname: '/routes',
      filter,
      screenName: 'DASHBOARD',
    });
  };

  redirectOrders = (filter) => {
    this.props.history.push({
      pathname: '/orders',
      filter,
    });
  };

  redirectDrivers = (filter) => {
    this.props.history.push({
      pathname: '/drivers',
      filter,
    });
  };

  roundSummary = (value) => {
    if (!value) {
      return 0;
    }
    return value > 999 ? '999+' : value;
  };

  render() {
    const orderGridSize = 6;
    const {organizationSettings} = this.state;
    const hasOrderAccess = checkServiceExistance("OPSOS");
    const hasDriverAccess = checkServiceExistance("OPSDVS");
    const hasRoutesAccess = checkServiceExistance("OPSDS");

    return (
      <div className='content-outer'>
        <div className="content">
          <Row className="page-header">
            <Col xs={12}>{I18n.t('dashboard.heading')}</Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row className="page-sub-header">
              <Col xs={16}>
                <div className="page-sub-content">
                   { checkServiceExistance(['NRDS', "OPSDS", "OPSDS"], "ALL") && 
                   <Row gutter={8}>
                   
                    <Col xs={18}>
                      <Card
                        bordered
                        loading={this.state.inProgress}
                        style={{ paddingTop: '5px' }}
                      >
                        <Row
                          className="page-sub-header"
                          style={{ marginTop: '-25px' }}
                        >
                          <Col xs={12}>
                            <div
                              style={{ textAlign: 'left' }}
                              className="dashboard_heading"
                            >
                              {' '}
                              {I18n.t('menu.routes')}
                            </div>
                          </Col>
                          { hasRoutesAccess && <Col xs={12} className="page-sub-actions">
                            <Button
                              type="primary"
                              onClick={() => this.redirect('ALL')}
                            >
                              {I18n.t('general.see_more')}
                            </Button>
                          </Col>
                          }
                        </Row>
                        <Divider style={{ margin: '0px 0px 30px 0px' }} />

                        <Row>
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            onClick={() => hasRoutesAccess ? this.redirect('ASSIGNED') : null}
                          >
                            <div className="blockStyle bgAllocated paddingTop2">
                              <Row>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={7} style={{ paddingTop: 12 }}>
                                      <img
                                        src="./icons/allocated_45.png"
                                        className="icon"
                                        alt="icon"
                                      />
                                    </Col>
                                    <Col xs={17} className="paddingTop2">
                                      <h2 className="paddingTopZero marginBottom">
                                        {this.roundSummary(this.state.data.assigned_count)}
                                      </h2>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={1} />
                                    <Col xs={22}>
                                      <hr className="blockHR" />
                                    </Col>
                                    <Col xs={1} />
                                  </Row>
                                  <Row>
                                    <Col xs={24}>
                                      <h4>ASSIGNED</h4>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            onClick={() => hasRoutesAccess ? this.redirect('PREPARE') : null}
                          >
                            <div className="blockStyle bgPending paddingTop2">
                              <Row>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={7} style={{ paddingTop: 12 }}>
                                      <img
                                        src="./icons/pending_45.png"
                                        className="icon"
                                        alt="icon"
                                      />
                                    </Col>
                                    <Col xs={17} className="paddingTop2">
                                      <h2 className="paddingTopZero marginBottom">
                                        {this.roundSummary(this.state.data.prepare_count)}
                                      </h2>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={1} />
                                    <Col xs={22}>
                                      <hr className="blockHR" />
                                    </Col>
                                    <Col xs={1} />
                                  </Row>
                                  <Row>
                                    <Col xs={24}>
                                      <h4>PREPARE</h4>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            onClick={() => hasRoutesAccess ? this.redirect('DISPATCHED') : null}
                          >
                            <div className="blockStyle bgInProgress paddingTop2">
                              <Row>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={7} style={{ paddingTop: 12 }}>
                                      <img
                                        src="./icons/inprogress_45.png"
                                        className="icon"
                                        alt="icon"
                                      />
                                    </Col>
                                    <Col xs={17} className="paddingTop2">
                                      <h2 className="paddingTopZero marginBottom">
                                        {this.roundSummary(this.state.data.dispatched_count)}
                                      </h2>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={1} />
                                    <Col xs={22}>
                                      <hr className="blockHR" />
                                    </Col>
                                    <Col xs={1} />
                                  </Row>
                                  <Row>
                                    <Col xs={24}>
                                      <h4>DISPATCHED</h4>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            onClick={() => hasRoutesAccess ? this.redirect('COMPLETED') : null}
                          >
                            <div className="blockStyle bgCompleted paddingTop2">
                              <Row>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={7} style={{ paddingTop: 12 }}>
                                      <img
                                        src="./icons/completed_45.png"
                                        className="icon"
                                        alt="icon"
                                      />
                                    </Col>
                                    <Col xs={17} className="paddingTop2">
                                      <h2 className="paddingTopZero marginBottom">
                                        {this.roundSummary(this.state.data.completed_count)}
                                      </h2>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={1} />
                                    <Col xs={22}>
                                      <hr className="blockHR" />
                                    </Col>
                                    <Col xs={1} />
                                  </Row>
                                  <Row>
                                    <Col xs={24}>
                                      <h4>COMPLETED</h4>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={6}>
                      <Card
                        title={I18n.t('users.listing.driver')}
                        bordered
                        loading={this.state.inProgress}
                        onClick={() => hasDriverAccess ? this.props.history.push('/drivers') : null}
                      >
                        <Row>
                          <Col xs={11}>
                            <Row className="textBold alignCenter">
                              <Col xs={24}>
                                <span className="font48 colorGrey">
                                  {this.state.data.in_active_drivers_count}
                                </span>{' '}
                                <br /> InActive
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={2} />
                          <Col xs={11}>
                            <Row className="textBold alignCenter">
                              <Col xs={24}>
                                <span className="font48 colorGreen">
                                  {this.state.data.active_drivers_count}
                                </span>{' '}
                                <br /> Active
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                   }
                </div>
                { checkServiceExistance(['COSTATS', "OPSOS"], "ALL") && <Row gutter={8}>
                  <Col xs={24} className="orderStats">
                    <Card
                      bordered
                      loading={this.state.inProgress}
                      style={{ paddingTop: '5px' }}
                      title="Orders"
                    >
                      <Row>
                        <Col lg={24} className="marginDashboard">
                          <div>
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('NEW') : null}
                              className="routeLegends"
                            >
                              <Statistic
                                title="New"
                                value={this.state.orders_data.new_orders}
                                prefix={
                                  <Icon type="diff" className="textCompleted" />
                                }
                              />
                            </Col>

                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('RECEIVED') : null}
                              className="routeLegends"
                            >
                              <Statistic
                                title="Received"
                                value={this.state.orders_data.received_orders}
                                prefix={
                                  <Icon
                                    type="plus-square"
                                    className="textReceived"
                                  />
                                }
                              />
                            </Col>
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('VERIFIED') : null}
                              className="routeLegends"
                            >
                              <Statistic
                                title="Verified"
                                value={this.state.orders_data.verified_orders}
                                prefix={
                                  <Icon
                                    type="check-circle"
                                    className="textPending"
                                  />
                                }
                              />
                            </Col>
                            {/* 
                              <Col
                              span={5}
                              onClick={() => this.redirectOrders('PREPLAN')}
                              className="routeLegends"
                            >
                              <Statistic
                                title="Preplan"
                                value={this.state.orders_data.preplan_orders}
                                prefix={
                                  <Icon type="bulb" className="textPreplan" />
                                }
                              />
                              </Col>
                            */}
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('ASSIGNED') : null}
                              className="routeLegends"
                            >
                              <Statistic
                                title="Assigned"
                                value={this.state.orders_data.assigned_orders}
                                prefix={
                                  <BranchesIcon/>
                                }
                              />
                            </Col>
                          </div>
                          <div className="marginTop10" style={{ paddingTop: 10 }}>
                            {/* <Col
                              span={5}
                              onClick={() => this.redirectOrders('PREPARE')}
                              className="routeLegends paddingTop10"
                            >
                              <Statistic
                                title="Prepare"
                                value={this.state.orders_data.prepare_orders}
                                prefix={
                                  <Icon type="bank" className="textAssigned" />
                                }
                              />
                            </Col>
                              */}
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('DISPATCHED') : null}
                              className="routeLegends paddingTop10"
                            >
                              <Statistic
                                title="Dispatched"
                                value={this.state.orders_data.dispatched_orders}
                                prefix={
                                  <SettingIcon/>
                                }
                              />
                            </Col>
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('COMPLETED') : null}
                              className="routeLegends paddingTop10"
                            >
                              <Statistic
                                title="Completed"
                                value={this.state.orders_data.completed_orders}
                                prefix={
                                  <Icon type="like" className="textCompleted" />
                                }
                              />
                            </Col>
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('CLOSED') : null}
                              className="routeLegends paddingTop10"
                            >
                              <Statistic
                                title="Closed"
                                value={this.state.orders_data.closed_orders}
                                prefix={
                                  <Icon type="check-circle" className="textReceived" />
                                }
                              />
                            </Col>
                            <Col
                              span={orderGridSize}
                              onClick={() => hasOrderAccess ? this.redirectOrders('EXCEPTION') : null}
                              className="routeLegends paddingTop10"
                            >
                              <Statistic
                                title="Exception"
                                value={this.state.orders_data.exception_orders}
                                prefix={
                                  <Icon type="warning" className="textExceptional" />
                                }
                              />
                            </Col>
                          </div>
                        </Col>
                        {/* <Col
                        span={5}
                        onClick={() => this.redirectOrders('CANCELLED')}
                        className="routeLegends"
                      >
                        <Statistic
                          title="Cancelled"
                          value={this.state.orders_data.cancelled_orders}
                          prefix={
                            <Icon type="close" className="textCancelled" />
                          }
                        />
                      </Col> */}
                      </Row>
                    </Card>
                  </Col>
                </Row>
                }
                <div className="page-sub-content">
                  <Row className="page-sub-header">
                    <Col xs={12} className="dashboard_heading">
                      {I18n.t('users.listing.driver')}
                    </Col>
                   { hasDriverAccess && <Col xs={12} className="page-sub-actions">
                      <Button
                        type="primary"
                        onClick={() => this.props.history.push('/drivers')}
                      >
                        {I18n.t('general.see_more')}
                      </Button>
                    </Col>
                   }
                  </Row>
                  <Row>
                    <Col>
                      <Card  style={{ height: 'calc(50vh - 228px)'}}>
                        <DriversList
                          data={this.state.drivers}
                          pagination={{
                            position: 'none',
                          }}
                          scroll={{y: 'calc(50vh - 290px)'}}
                          userContext={this.props.userContext}
                          setStatus={(element, value, id) =>
                            this.handleUserStatus(element, value, id)
                          }
                        />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={1} />
              <Col xs={7} className="dashboard_livefeed">
                <Card
                  style={{ height: 'calc(100vh - 139px)', overflowY: 'auto', textAlign: 'left' }}
                  bordered
                >
                  <h3
                    style={{
                      textAlign: 'left',
                      margin: '0px 0px 20px 0px',
                      fontWeight: 'bold',
                    }}
                    className="dashboard_heading"
                  >
                    Live Feed
                  </h3>
                  <Spin spinning={ this.state.isFetchingLogs}>
                  {Array.isArray(this.state.routeLogs) && this.state.routeLogs.map((routeLog, index) => (
                    <>
                      <Row>
                        <Col xs={4} style={{ textAlign: 'center' }}>
                          <img src="./icons/user.png" alt="icon" />
                        </Col>
                        <Col
                          xs={20}
                          style={{ textAlign: 'left', paddingLeft: '5px' }}
                        >
                          <h4 style={{ marginBottom: '0em' }}>
                            {routeLog.message}
                          </h4>

                          <span style={{ fontSize: 12 }}>
                            {routeLog.action_time
                              ? formatByTimeConfig(new Date(routeLog.action_time), organizationSettings['is_military_time'] , 'MMM DD, YYYY HH:mm', 'MMM DD, YYYY hh:mm A')
                              : 'NA'}
                          </span>
                        </Col>
                      </Row>
                      <Divider style={{ margin: '12px 0px' }} />
                    </>
                  ))}
                  </Spin>
                </Card>
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    );
  }
}



export const DashboardComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const userContext= useContext(UserContext);
  return (
    <Dashboard
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContext={userContext}
      {...props}
    />
  );
});

export default DashboardComponent;

DashboardComponent.propTypes = {
  history: PropTypes.shape().isRequired,
};