import React, { useState } from 'react';
import { Select, Icon } from 'antd';

const { Option } = Select;

const ViewToggleButton = ({ initialView = 'both', onViewToggle, viewOptions, buttonSize = "small" }) => {
  const [ currentView, setCurrentView ] = useState(initialView);

  const handleSelect = (value) => {
    setCurrentView(value);
    onViewToggle && onViewToggle(value);
  };

  let viewIcon, viewLabel;
  switch (currentView) {
    case 'map':
      viewIcon = 'unordered-list';
      viewLabel = 'Expand List';
      break;
    case 'list':
      viewIcon = 'appstore';
      viewLabel = 'Show Map & List';
      break;
    case 'both':
    default:
      viewIcon = 'environment';
      viewLabel = 'Expand Map';
      break;
  }

  return (
    <Select value={ currentView } onSelect={ handleSelect } style={ { width: 200 } } size={ buttonSize }>
      <Option value="map"><Icon type="unordered-list" /> Expand Map</Option>
      <Option value="list"><Icon type="appstore" /> Expand List</Option>
      <Option value="both"><Icon type="environment" /> Show Map & List</Option>
    </Select>
  );
};

export default ViewToggleButton;
