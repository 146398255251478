import React, { useState, useMemo, useCallback } from "react";
import { Drawer, Icon, Progress, Tooltip, Row, Col } from "antd";
import { Search } from "../../common/UIComponents";
import assignIcon from "../../../images/assign_route/assign.svg";
import transitIcon from "../../../images/assign_route/in_transit.svg";
import AssignIcon from "../../common/AssignIcon";
import IntransitTruckIcon from "../../common/IntransitTruckIcon";
import TruckLoadProgress from "./TruckLoadProgress";
import moment from "moment";
import LockIcon from "../../common/LockIcon";
import { lockIconTooltip } from "../../common/Common";
import _ from 'lodash';

const DriverBox = React.memo(({ getDriverInfo, driver, orders, assignToRoute, warehouseData, organizationSettings, userContext, selectedDate }) => {
  const renderDriverBox = useCallback(() => {
    const { route_details = {} } = driver;
    const order_weight = useMemo(() => _.sumBy(orders, 'weight'), [orders]);
    const vehicle_weight = driver.vehicle_weight;
    const routeCarriedWeight = route_details?.route_carried_weight || 0;
    const isOverLoaded = useMemo(() => (order_weight + routeCarriedWeight) > vehicle_weight, [order_weight, routeCarriedWeight, vehicle_weight]);
    const hasTruckAssigned = !_.isEmpty(driver?.vehicle_weight);
    const selectedWh = warehouseData.getWHDetails();
    const isMilitaryTime = organizationSettings.is_military_time === "true";
    const timeFormatString = isMilitaryTime ? "HH:mm" : "hh:mm A";
    const { getWHDetails } = warehouseData;
    const { currentUser } = userContext;
    const currentWarehouse = getWHDetails();
    const showLockIcon = currentUser.id !== route_details?.is_locked_by && route_details?.is_locked;
    const isDateExceeded = useMemo(() => driver.all_routes_completion_date ? moment
      .tz(driver.all_routes_completion_date, selectedWh.timeZoneId)
      .isAfter(moment.tz(`${selectedDate.format("YYYY-MM-DD")} 23:59`, 'YYYY-MM-DD HH:mm', selectedWh.timeZoneId)) : false, [driver.all_routes_completion_date, selectedWh.timeZoneId, selectedDate]);
    
    return (
      <div className="driverBox" onClick={getDriverInfo}>
        <Row>
          <Col xs={hasTruckAssigned ? 10 : 21}>
            <Row type="flex" gutter={8} style={{ lineHeight: 0 }}>
              <Col className="driver_title">{driver.employee_code}</Col>
              {!_.isEmpty(route_details) && (
                <Col style={{ marginTop: 4 }}>
                  <IntransitTruckIcon className="intransit-truck-icon" />
                </Col>
              )}
              <Col style={{marginLeft: -7}}>
                {showLockIcon && lockIconTooltip({ iconClassName: "intransit-truck-icon", userName:route_details?.locked_by_name })}
              </Col>
            </Row>
            <div className="legs_info">
              {driver?.all_routes_completion_date ? (
                <>
                  <span className={`${isDateExceeded ? 'errorText' : ''}`}>
                    {moment
                      .tz(driver.all_routes_completion_date, selectedWh.timeZoneId)
                      .format(`MMM DD, ${timeFormatString}`)}
                  </span>
                  {" "}
                  ({driver.all_routes_pickup_orders}P/
                  {driver.all_routes_delivery_orders}D), {driver.routes_count}{" "}
                  Routes
                </>
              ) : (
                "No Active Routes"
              )}
            </div>
          </Col>
          {hasTruckAssigned && (
            <Col xs={11}>
              <div className="capacity_info">
                {isOverLoaded && (
                  <span style={{ float: "left" }}>
                    <Icon
                      type="warning"
                      style={{
                        color: "#FF2D78",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    />
                  </span>
                )}
                {order_weight + routeCarriedWeight} / {driver.vehicle_weight} lbs
              </div>
              <div className="capacity_info_status">
                <TruckLoadProgress
                  style={{ height: 18 }}
                  currentCarriedWeight={routeCarriedWeight}
                  selectedOrdersWeight={order_weight}
                  truckCapacity={driver.vehicle_weight}
                />
              </div>
            </Col>
          )}
          {orders?.length > 0 && <Col xs={3}>
            <div style={{ width: 40, height: 40 }} className="blkCenterRight">
              <Tooltip title="Assign Now">
                <AssignIcon
                  style={{ fontSize: 24 }}
                  color={isDateExceeded ? "#ff2c78" : "#617AD6"}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click event from bubbling up
                    assignToRoute();
                  }}
                />
              </Tooltip>
            </div>
          </Col>}
        </Row>
      </div>
    );
  }, [getDriverInfo, driver, orders, assignToRoute, warehouseData, organizationSettings, userContext, selectedDate]);
  
  return <div>{renderDriverBox()}</div>;
});

export default DriverBox;
