/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Select,
  Button,
  Typography,
  Alert,
  Modal,
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormErrors from "../../components/common/FormErrors";
import { fetchExceptionMessages } from "../../api/PreplanApi";
import ReasonWindow from "../../components/common/ReasonWindow";
import AppConfig from "../../config/AppConfig";
import { LineHaulApi } from "../../api/LineHaulApi";
import { renderAlertMessage } from "../../helpers/common";

const { TextArea } = Input;
const { confirm } = Modal;
class LhChangeStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      errors: [],
      statusErrors: [],
      currentStatus: this.props.currentStatus || "",
      exceptionCode: "",
      exceptionMessage: "",
      recovery: this.props.recoveryObject || {},
      preDispatchStatus: ["in_transit", "in_hand"],
      postDispatchStatus: [
        "assigned",
        "completed"
      ],
      reason: "",
      recoveryStatus: this.props.currentStatus || "",
      exceptions: ["NEW"],
      showReason: false,
    };
  }

  clearForm = () => {
    this.setState({
      currentItem: {},
      isNew: true,
    });
  };

  componentDidMount() {
    this.getExceptionMessages();
    // this.checkBulkStatus();
  }

  checkBulkStatus = () => {
    if (this.props.hasBulkOrders) {
      const { status } = this.state;
      delete status.Exception;
      this.setState({
        status,
      });
    }
  };

  handleChange = (element, value) => {
    if (element === "currentStatus"){
      if(this.state.postDispatchStatus.includes(this.state.recoveryStatus) &&
      this.state.preDispatchStatus.includes(value)){
        confirm({
        title: I18n.t("messages.recovery_deleted_from_route"),
        content: <span className="">{I18n.t("messages.proceed_confirm")}</span>,
        onOk: () => {
          this.setState({
            [element]: value,
            showReason: true,
          });
        },
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onCancel: () => { },
      });
      }else{
        this.setState({ [element]: value, showReason: false, reason: "" });
      }
      
    } else {
      this.setState({ [element]: value });
    }
  };

  getExceptionMessages = () => {
    this.setState({ inProgress: true });
    fetchExceptionMessages().then((result) => {
      if (result.success) {
        const exceptions = [
          ...result.exceptions,
          { exception_code: "CUSTOM", exception_message: "Custom Message" },
        ];
        this.setState({
          exceptions,
          inProgress: false,
        });
      } else {
        this.setState({
          inProgress: false,
          exceptions: [
            { exception_code: "CUSTOM", exception_message: "Custom Message" },
          ],
        });
        renderAlertMessage(result.errors)
      }
    });
  };

  handleSave = () => {
    this.setState({ inProgress: true, errors: [] });
    const errors = [];
    if (
      this.state.exceptionCode === "CUSTOM" &&
      this.state.exceptionMessage === ""
    ) {
      errors.push("Message is required");
    }
    if (errors.length === 0) {
      
        const data = {
          line_haul_ids: this.props.data.map((item) => item.id), 
          status: this.state.currentStatus,
          deletion_reason: this.state.showReason ? this.state.reason : "",
        };
        LineHaulApi.changeStatus(data).then((result) => {
          if (result.success) {
            alertMessage(I18n.t("messages.processed"), 10);
            this.setState(
              {
                inProgress: false,
                errors: [],
                statusErrors: [],
                showReason: false,
                reason: "",
              },
              () => {
                this.props.saveStatus();
              }
            );
          } else {
            // alertMessage(result.errors[0], 'error', 10);
            this.setState({
              inProgress: false,
            });
            this.processErrors(result.errors);
            // if (this.props.hasBulkOrders) {
            //   const errorOrders = result.errors
            //     ? result.errors.map((error) => error.recovery_number)
            //     : [];
            //   this.props.processErrors(errorOrders);
            // }
          }
        });
      
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  processErrors = (errors) => {
    if (errors?.length > 0) {
      const errorValue = errors[0];
      if (_.isString(errors)) {
        this.setState({
          errors: [...errors],
          statusErrors: [],
        });
      } else if (_.isObject(errorValue)) {
        const statusErrors = [];
        errors.forEach((errObj) => {
          if (_.isObject(errObj) && errObj.recovery_number) {
            statusErrors.push(errObj);
          }
        });
        this.setState({
          errors: [],
          statusErrors,
        });
      } else {
        this.setState({
          errors,
          statusErrors: [],
        });
      }
    }
  };

  render() {
    const currentStatusRecord = _.find(AppConfig.linehaulStatuses , { value : this.state.recoveryStatus})
    return (
      <div className="marginTop10">
        <Row gutter={16}>
          <Col xs={24}>
            <Row
              style={{
                padding: "10",
                backgroundColor: "#fff",
              }}
              className="modelInputHeight"
            >
              <Row>
                <Col xs={22} offset={1} style={{ paddingBottom: 10 }}>
                  <div className="marginBottom15">
                    <Typography.Text strong>
                      {" "}
                      Current Status :{" "}
                      { !isEmpty(currentStatusRecord) 
                        ? currentStatusRecord.text
                        : this.state.recoveryStatus }
                    </Typography.Text>
                  </div>
                  <FormItem label="Select Status">
                    <Select
                      style={{ width: "100%" }}
                      className="routes-input"
                      onChange={(e) => this.handleChange("currentStatus", e)}
                      defaultValue="Change Status"
                      value={this.state.currentStatus}
                      size="small"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {
                        AppConfig.linehaulStatuses.filter(statusRec => statusRec.value !== "completed")
                        .map(statusRec => (
                          <Select.Option
                              value={statusRec.value}
                              key={statusRec.value}
                              disabled={(statusRec.value === this.state.recoveryStatus) || (statusRec.value === "dispatched" && this.state.recoveryStatus == "assigned")}
                            >
                              {statusRec.text}
                            </Select.Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={22} offset={1} style={{ paddingBottom: 10 }}>
                  {this.state.currentStatus === "EXCEPTION" && (
                    <FormItem label="Select Exception">
                      <Select
                        style={{ width: "100%" }}
                        className="routes-input"
                        onChange={(e) => this.handleChange("exceptionCode", e)}
                        defaultValue="Change Status"
                        value={this.state.exceptionCode}
                        size="small"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {this.state.exceptions.map((excep) => (
                          <Select.Option
                            value={excep.exception_code}
                            key={excep.exception_code}
                          >
                            {excep.exception_message}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  )}
                  {this.state.exceptionCode === "CUSTOM" && (
                    <div>
                      <TextArea
                        rows={4}
                        placeholder={I18n.t("general.message")}
                        className="sop-form-textArea"
                        value={this.state.exceptionMessage}
                        onChange={(e) =>
                          this.handleChange("exceptionMessage", e.target.value)
                        }
                      />
                    </div>
                  )}
                  {this.state.showReason && (
                    <div>
                      <FormItem label={I18n.t("messages.reason_to_change")}>
                        <ReasonWindow
                          reasonElement="reason"
                          reasonValue={this.state.reason}
                          handleOnChange={this.handleChange}
                          showButtons={false}
                        />
                      </FormItem>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  offset={1}
                  xs={22}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <Button type="danger" onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                  &nbsp; &nbsp;
                  
                  <Button
                    type="primary"
                    onClick={this.handleSave}
                    loading={this.state.inProgress}
                    disabled={
                      !this.state.currentStatus || this.state.currentStatus == this.props.currentStatus ||
                      (this.state.showReason && this.state.reason.length < 10)
                    }
                  >
                    Update
                  </Button>
                  
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {FormErrors(this.state.errors)}
            {this.state.statusErrors.length > 0 && (
              <Alert
                message="Error"
                description={
                  <div>
                    {this.state.statusErrors.map((err) =>
                      _.isObject(err) ? (
                        <Row>
                          <Col xs={8} className="textBold">
                            {err.recovery_number}
                          </Col>
                          <Col xs={16}>
                            {_.isArray(err.errors) ? err.errors.join(",") : ""}
                          </Col>
                        </Row>
                      ) : (
                        <Fragment />
                      )
                    )}
                  </div>
                }
                type="error"
                closable={false}
              />
            )}
            {this.state.errors.length > 0 && FormErrors(this.state.errors)}
          </Col>
        </Row>
      </div>
    );
  }
}

LhChangeStatus.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveStatus: PropTypes.func.isRequired,
  recoveryObject: PropTypes.shape().isRequired,
  currentStatus: PropTypes.string.isRequired,
};

export default LhChangeStatus;
