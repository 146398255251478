/* eslint-disable react/sort-comp */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  Col, Input, FormItem, Row, DatePicker,
  Select, Radio,Button
} from '../../common/UIComponents';
import { isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import AppConfig from '../../config/AppConfig';
import { getReleaseStatusIcon } from '../../helpers/release';
import ZonesList from "../../containers/ZonesList";

const { Search } = Input;
const { RangePicker } = DatePicker;

const ReleaseFilter = (props) => {
  const {
    // filter,
    // setFilter,
    filterPlaceHolder,
    setFilterPlaceHolder,
    onSearch,
    history,
    resetFilter,
    warehouses,
    setSelectedWH,
    accounts,
    defaultFilter,
    setSelectedAccount,
    elementSize = "small",
    showDateFilter = true,
    showTypeFilter = true
  } = props;
  const [ wHLoading, setWHLoading ] = useState(false);
  const [filter, setFilter] = useState({...defaultFilter});

  useEffect(() => {
    setFilter({...filterPlaceHolder});
  }, [filterPlaceHolder])


  const handleWHChange = (e) => {
    const wh = warehouses.find(wh => wh.id === e);
    setSelectedWH(!isEmpty(wh) ? wh : null)
  }

  const renderWHList = () => (
    <FormItem label={ I18n.t("location.location") }>
      <Select
        value={ filter.warehouse_id || 'ALL'}
        showSearch
        style={ { width: '100%' } }
        filterOption={ (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        loading={ wHLoading }
        onChange={(e) => {
        // handleWHChange(e)
        setFilter({ ...filter, warehouse_id: e });
        }}
      >
        <Select.Option value={'ALL'} key={ 'ALL' } className="selectOptionsWithIcon">{I18n.t('general.all')}</Select.Option>
        { warehouses.map((warehouse) => (
          <Select.Option
            key={ warehouse.location_code }
            value={ warehouse.id }
          >
            { warehouse.name }
          </Select.Option>
        )) }
      </Select>
    </FormItem>
  );

  const renderAccountsList = () => (
    <FormItem label={I18n.t('general.account')}>
      <Select
        value={filter.account_code}
        showSearch
        style={{ width: '100%' }}
        filterOption={(input, option) =>
          option.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        loading={wHLoading}
        onChange={(e) => {
          // const acc = accounts.find((account) => account.code === e)
          // setSelectedAccount(acc)
          setFilter({ ...filter, account_code: e });
        }}
      >
        <Select.Option key={'ACCOUNT_ALL'} value={''}>
        -- All --
        </Select.Option>
        {accounts?.map((account) => (
          <Select.Option key={account.code} value={account.code}>{`${account.name} (${account.code})`}</Select.Option>
        ))}
      </Select>
    </FormItem>
  )

  const renderZones = () => (
    <FormItem label={I18n.t("general.zone")}>
      <ZonesList
        handleFilterPlaceHolderChange={(element, value) =>
          setFilter({
            ...filter,
            [element]: value,
          })
        }
        zone_ids={filter.zone_ids}
        size={elementSize}
      />
    </FormItem>
  );

  const {dateSearchBy , dateSearchValue} = filter;
  const  { releaseStatuses } = AppConfig;
  return (
    <div className="padding10 ReleaseFilter">
      <Row style={ {
        display: 'flex', flexDirection: 'column', gap: '5px',
      } }> 
        {/* <Col md={ 24 }>{ renderWHList() }</Col> */}
        <Col md={24}>{renderZones()}</Col>
        <Col md={24}>{ renderAccountsList() }</Col>
        {/*  Filter by Status 
        <Col md={ 24 }>
          <FormItem label={ "Status" }>
            <Select
              style={ { width: '100%' } }
              value={ filter.currentStatus }
              onChange={ (value) => {
                setFilter({ ...filter, currentStatus: value });
              } }
            >
              <Select.Option value={''} key={ 'ALL' } className="selectOptionsWithIcon">{I18n.t('general.all')}</Select.Option>
              {releaseStatuses.map(({ key, text, value }) => (
                <Select.Option value={value} key={key} className="selectOptionsWithIcon">
                  <div>
                    { text } &nbsp;&nbsp;{ getReleaseStatusIcon(key) }
                  </div>
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Col> */}
        {showDateFilter && <Col md={ 24 }>
          <FormItem label={"Date Search"}>
          <Row style={{
              display: "flex",
              alignItems: "center"
          }}> 
            <Col span={9}>
              <Select size='small'
                onChange={ (e) => {
                  setFilter({ ...filter, dateSearchBy: e , dateSearchValue: filter.dateSearchValue ? filter.dateSearchValue : moment()});
                } }
                className="ReleaseFilter__dateSearchBy"
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "1",
                }} 
                value={
                  dateSearchBy === "scheduled_release_date" ? I18n.t('releases.scheduled_release_date') : dateSearchBy === "actual_release_date" ?  I18n.t('releases.actual_release_date') : null
                }
                defaultValue={
                  dateSearchBy === "scheduled_release_date" ? I18n.t('releases.scheduled_release_date') : dateSearchBy === "actual_release_date" ?  I18n.t('releases.actual_release_date') : null
                }>
                <Select.Option key="scheduled_release_date" value="scheduled_release_date">{I18n.t('releases.scheduled_release_date')}</Select.Option>
                <Select.Option key="actual_release_date" value="actual_release_date">{I18n.t('releases.actual_release_date')}</Select.Option>
              </Select>
            </Col>
            <Col span={15}>
              {/* <DatePicker
                style={ { width: '100%' } }
                format="DD MMM, YYYY"
                onChange={ (e) => setFilter({...filter , dateSearchValue: e}) }
                value={ dateSearchValue }
                allowClear={true}
              /> */}
              <RangePicker
                  defaultValue={[moment().startOf('month'), moment()]}
                  value={[dateSearchValue.fromDate, dateSearchValue.toDate]}
                  ranges={AppConfig.dateRanges}
                  size="small"
                  format={AppConfig.dateFormat}
                  onChange={(dates, dateStrings) =>
                   !(dateStrings[0] !== '' && dateStrings[1] !== '') ? 
                   setFilter({
                      ...filter,
                      dateSearchValue: { fromDate: moment(), toDate: moment().add(6, "day") },
                   })
                  :
                    setFilter({
                      ...filter,
                      dateSearchValue: { fromDate: dates[0], toDate: dates[1] },
                    })
                  }
                />
            </Col>
          </Row>
          </FormItem>
        </Col>
        }
        {showDateFilter && dateSearchBy === 'scheduled_release_date' && 
        ( <Col md={24}>
            <FormItem label={I18n.t("releases.schedule_times.label")}>
              <Radio.Group
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    [I18n.t("releases.schedule_times.type")]: e.target.value,
                  })
                }
                value={filter[I18n.t("releases.schedule_times.type")]}
                size={elementSize}
              >
                <Radio
                  value={I18n.t('releases.schedule_times.scheduled_key')}
                >
                  {I18n.t('releases.schedule_times.SCHEDULED_label')}
                </Radio>
                <Radio
                  value={I18n.t('releases.schedule_times.unscheduled_key')}
                >
                  {I18n.t('releases.schedule_times.UNSCHEDULED_label')}
                </Radio>
                <Radio value={I18n.t('releases.schedule_times.both_key')}>
                  {I18n.t('general.both')}
                </Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        )}
        {showTypeFilter && <Row>
          <Col md={ 24 }>
            <FormItem label={ I18n.t("releases.release_type.label") }>
              <Radio.Group
                onChange={ (e) =>
                  setFilter({
                    ...filter,
                    release_type: e.target.value,
                  })
                }
                value={ filter.release_type }
                size={ elementSize }
              >
                <Radio
                  value={ I18n.t('releases.release_type.options.WAREHOUSE_DOCK_KEY') }
                >
                  { I18n.t('releases.release_type.options.WAREHOUSE_DOCK_LABEL') }
                </Radio>
                <Radio value={ I18n.t('releases.release_type.options.OTHER_KEY') }>
                  { I18n.t('releases.release_type.options.OTHER_LABEL') }
                </Radio>
                <Radio value={ I18n.t('releases.release_type.options.BOTH_KEY') }>
                  { I18n.t('releases.release_type.options.BOTH_LABEL') }
                </Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
        }

          <Row type='flex' justify='center' gutter={ 4 }>
            <Col>
              <Button type="danger" onClick={ resetFilter} icon="redo">
                { I18n.t('general.reset') }
              </Button>
            </Col>
            <Col>
              <Button onClick={ () => {
                // onSearch(filter);
                setFilterPlaceHolder({...filter}, () => {
                  onSearch({...filter});
                });
              }
              } type="primary" icon="search">
                { I18n.t('general.search') }
              </Button>
            </Col>
          </Row>
      </Row>
    </div>
  );
};

ReleaseFilter.propTypes = {
};
ReleaseFilter.defaultProps = {
};
export default withRouter(ReleaseFilter);