import React, { useState, useEffect } from "react";
import { Button, Row, Col, Spin } from "../../../common/UIComponents";
import userStore from "../../../stores/UserStore";
import {
  fetchBillingAccounts,
  deleteBillingAccounts,
  saveBillingAccounts,
  updateBillingAccounts,
} from "../../../api/BillingAcountsApis";
import BaseModal from "../../BaseModal";
import BillingAccountsForm from "./BillingAccountsForm";
import BillingAccountsList from "./BillingAccountsList";
import { alertMessage} from "../../../common/Common";
import _ from "lodash";
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";

function BillingAccounts() {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [billingAccountData, setBillingAccountData] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    getBillingAccounts();
  }, []);

  const getBillingAccounts = () => {
    const organization_id = userStore.getStateValue("selectedOrg");
    setIsLoading(true);
    fetchBillingAccounts(organization_id).then((result) => {
      if (result.success) {
        setBillingAccountData(result.integration_companies || []);
        setIsLoading(false);
      } else {
        alertMessage(result.errors, "error", 10);
        setIsLoading(false);
        setBillingAccountData([]);
      }
    });
  };

  const onFormModalClose = () => {
    setIsFormModalOpen(false);
  };

  const renderFormModal = () => {
    return (
      <BaseModal
        title={
          isNew
            ? I18n.t("billing_accounts.add")
            : I18n.t("billing_accounts.edit")
        }
        onCancel={onFormModalClose}
        width="60%"
        maskClosable={false}
      >
        <BillingAccountsForm
          closeModal={onFormModalClose}
          billingAccountData={billingAccountData}
          onSuccess={handleSuccess}
          onCancel={onFormModalClose}
          currentData={currentData}
          showLoading={isFormLoading}
        />
      </BaseModal>
    );
  };

  const handleEditClick = (id) => {
    const index = _.findIndex(billingAccountData, ["id", id]);
    const Data = Object.assign({}, billingAccountData[index]);
    setIsNew(false);
    setIsFormModalOpen(true);
    setCurrentData(Data);
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteBillingAccounts(id).then((result) => {
      if (result.success) {
        alertMessage(result.message, "success", 10);
        getBillingAccounts();
      } else {
        renderAlertMessage(result.errors)
        setIsLoading(false);
      }
    });
  };

  const handleSuccess = (record) => {
    setFormLoading(true);
    if (isNew) {
      saveBillingAccounts(record).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          setFormLoading(false);
          setIsFormModalOpen(false);
          setIsNew(true);
          getBillingAccounts();
        } else {
          setFormLoading(false);
          alertMessage(result.errors, "error", 10);
        }
      });
    } else {
      let currentDataId = currentData.id;
      if (!_.isEmpty(currentData) && currentData.id) {
      }

      updateBillingAccounts(currentDataId, record).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          setFormLoading(false);
          setIsFormModalOpen(false);
          setIsNew(true);
          getBillingAccounts();
        } else {
          setFormLoading(false);
          alertMessage(result.errors, "error", 10);
        }
      });
    }
  };

  return (
    <div className="content">
      <Row>
        <Col xs={24} className="alignRight marginBottom15">
          <Row>
            <Button
              type="primary"
              onClick={() => {
                setIsFormModalOpen(true);
                setIsNew(true);
                setCurrentData({});
              }}
              icon="plus"
            >
              {I18n.t("billing_accounts.add")}
            </Button>
          </Row>
        </Col>
      </Row>
      <Spin spinning={isLoading} delay={1000}>
        <Row>
          <Col>
            <BillingAccountsList
              scroll = {{y:"calc(100vh - 280px)"}}
              data={billingAccountData}
              editClick={(id) => handleEditClick(id)}
              deleteClick={(id) => handleDelete(id)}
              pagination={{ position: "none" }}
            />
          </Col>
        </Row>
      </Spin>
      {isFormModalOpen && renderFormModal()}
    </div>
  );
}

export default BillingAccounts;
