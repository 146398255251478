import { ApiUrl, Call, ParseGeneralResponse } from "./ApiUtils";

export const saveAgingReportConfigs = (payload,organizationId) => {
    // const url = ApiUrl("v2/organization/update_org_config");
    const url = ApiUrl(`v2/organization/update_org_config?organization_id=${organizationId}`);
    return Call("put", url, payload, ParseGeneralResponse, {
        responseDataKeys: { data: "" }
    });
};

