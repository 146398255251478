import React, { Fragment } from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';
import { checkNegitive } from '../../common/Common';

import { Icon, Tooltip, Popconfirm, Input, InputNumber, Row, Col } from '../../common/UIComponents';
import AppConfig from '../../config/AppConfig';
import DeleteIcon from '../common/DeleteIcon';
import I18n from '../../common/I18n';
import BaseSelect from '../common/SelectDropdowns/BaseSelect';
import GLCodeSelect from '../common/SelectDropdowns/GLCodeSelect';

class DedutionsList extends BaseList {
  constructor(props) {
    super(props);
    const decimalPoints =  window.localStorage.getItem('round_off_decimals')
    this.columns = [
      {
        key: 'deduction_name',
        title: <div>{I18n.t('deductions.name')}</div>,
        dataIndex: 'deduction_name',
        render: (text, record, index) => (
          record.payment_type !== 'FIXED' && !this.props.isDriverLogin && !['CLAIM', 'LOAN', 'ADVANCE'].includes(record.deduction_standard_code)? <Input
            value={text}
            size="small"
            className="textUpperCase fontSize12"
            placeholder={I18n.t('deductions.name')}
            onChange={e => this.props.onChange(index, 'deduction_name', e.target.value)}
            autoFocus={record.isNew}
          />
            :
            <div>
            {text} {['CLAIM', 'LOAN', 'ADVANCE'].includes(record.deduction_standard_code) && <span className='notes_content textBold'>
              ( {this.getAmount(record, this.props)} )
            </span>}
            </div>
        ),
        width: 150,
      },
      {
        key: 'gl_code',
        title: <div>{I18n.t('deductions.gl_code_label')}</div>,
        dataIndex: 'gl_code',
        render: (text, record, index) => (
          record.payment_type !== 'FIXED' && !this.props.isDriverLogin ?
            <>
              {/* <Input
                value={text}
                size="small"
                className="textUpperCase fontSize12"
                placeholder={I18n.t('deductions.gl_code_label')}
                onChange={e => this.props.onChange(index, 'gl_code', e.target.value)}
              /> */}
              <GLCodeSelect
                data={this.props.glCodesList || []}
                size="small"
                className="textUpperCase fontSize12"
                value={text}
                onChange={e => this.props.onChange(index, 'gl_code', e)}
                selectKey={"gl_code"}
                selectValue={"gl_code"}
                selectedValue={"gl_code"}
                showSearch={true}
                style={{ width: '100%'}}
                dropdownMatchSelectWidth={false}
                placeholder={I18n.t('deductions.gl_code_label')}
                selectedCode={"description"}
              />
            </>
          
            :
            <div>{text}</div>
        ),
        width: 100,
      },
      {
        key: 'payment_type',
        title: <div>{I18n.t('deductions.payment_type')}</div>,
        dataIndex: 'payment_type',
        width: 80,
        render: (text) => {
          return (
            <div>
              {text ? AppConfig.deductionTypes[text] : AppConfig.defaultPaymentType}
            </div>
          );
        },
        width: 100,
      },
      {
        key: 'amount',
        title: <div>{I18n.t('deductions.amount')} ({I18n.t('general.price_symbol')})</div>,
        dataIndex: 'amount',
        render: (text, record, index) => (
          record.payment_type !== 'FIXED' && !this.props.isDriverLogin ? <InputNumber
            value={text}
            size="small"
            className="fontSize12 width100Per"
            onChange={e => this.props.onChange(index, 'amount', e)}
            placeholder={I18n.t('deductions.amount')}
            // min={0}
            precision={decimalPoints}
            style={{ width: '90%' }}
            disabled={["PERCENTAGE_ON_GROSS_PAY", "PERCENTAGE_ON_GROSS_REVENUE"].includes(record.payment_type)}
          />
          :
          checkNegitive(text)
        ),
        width: 120,
      },
      {
        key: 'actions',
        title: '',
        dataIndex: 'isNew',
        render: (data, record, index) => (
          !this.props.isDriverLogin && <Fragment>
             <Row type="flex" gutter={16}>
                <Col>
                <Tooltip placement="topLeft" title="Delete">
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm')}
                  onConfirm={() => this.props.deleteClick(index, record.id, record.isNew === true)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon className="fontSize14"/>
                  &nbsp;&nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
                </Col>
                {index + 1 === this.props.totalRecords &&<Col>
                      <Tooltip placement="topLeft" title={this.props.invoice_type === 'accessorial_charges' ? I18n.t('accessorials.add') : 'Add Deduction'}>
                        <Icon type="plus-circle" theme="filled" onClick={this.props.addRow} style={{ fontSize: 18 }} />
                      </Tooltip>
                </Col>
                }
             </Row>
            </Fragment>
        ),
        width: 60,
        align: 'left',
      },
    ];
  }
  getAmount = (record, props) => {
    if(record.deduction_standard_code === 'CLAIM') {
      return checkNegitive(props.claim_balance)
    } else if(record.deduction_standard_code === 'LOAN') {
      return checkNegitive(props.loan_balance)
    } else if(record.deduction_standard_code === 'ADVANCE') {
      return checkNegitive(props.advance_balance)
    }
    return "";
  }
}

export default DedutionsList;