import React, {  useEffect, useState, useContext } from "react";
import _, { set } from "lodash";
import PropTypes from "prop-types";
import { Button, Col, Form, Input, Radio, Row, Select , DatePicker } from "antd";
import I18n from '../../common/I18n';
import AppConfig from '../../config/AppConfig';
import { alertMessage, isEmpty } from "../../common/Common";
import RadioGroup from 'antd/lib/radio/group';
import { QuotesApis } from '../../api/QuotesApi';
import userStore from '../../stores/UserStore';
import moment from 'moment';
import { TextArea } from '../../common/UIComponents';
import { getValueFromArrayOfObjects } from "../../helpers/array_functions";
import { OrgContext } from "../../context/OrgContext";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { renderAlertMessage } from "../../helpers/common";
// import { ORDER_TYPES } from '../../../../../assets/javascripts/constants';

function QuoteOrderDetails (props) {
  const { currentQuote, warehouses, accounts , levelOfServices , quoteFormData , setQuoteFormData, setCurrentQuote , activeRecordId , setActiveRecordId , setIsLoading ,handleFailure ,handleSuccess , clickedSave , toggleClickedSave ,setUnsavedChanges , unsavedChanges , checkForUnsavedChanges,targetLevelOfServices,targetAccounts,obtainSaveDetails} = props;
  const [ errors, setErrors ] = useState([]);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [quote_disclaimer, setQuoteDisclaimer] = useState(organizationSettings.org_quote_disclaimer)
  const { orderConfig = {} } = useContext(OrderConfigContext);
  const {isAirCargo,currentOrg} = useContext(OrgContext)

  useEffect(() => {
    if(clickedSave){
      handleSubmit();
    }
  }, [clickedSave])


  useEffect(() => {
    obtainSaveDetails(quoteFormData)
  }, [quoteFormData])
  
  const fillFormFields = () => {
    const { form } = props;
    let initialValues = {};
    if (activeRecordId) {
      initialValues = {
        warehouse_code: quoteFormData.warehouse_code,
        account_code: quoteFormData.account_code,
        type_of_order: quoteFormData.type_of_order,
        level_of_service: quoteFormData.level_of_service,
        pickup_level_of_service: quoteFormData?.pickup_params?.los_code || "",
        delivery_level_of_service: quoteFormData?.delivery_level_of_service  || quoteFormData?.drop_params?.los_code || "",
        weight: quoteFormData.weight,
        is_hazardous: quoteFormData.is_hazardous,
        is_line_haul: quoteFormData.is_line_haul,
        quote_date: quoteFormData.quote_date ? moment.utc(quoteFormData.quote_date) : null,
        expiry_date: quoteFormData.expiry_date ? moment.utc(quoteFormData.expiry_date) : null,
        mawb: quoteFormData.mawb,
        hawb: quoteFormData.hawb,
        notes: quoteFormData.notes,
        quote_disclaimer: quoteFormData.quote_disclaimer,
        pallets: quoteFormData.pallets,
        target_warehouse_code: quoteFormData.target_warehouse_code,
        target_account_code: quoteFormData.target_account_code,
        target_level_of_service: quoteFormData.target_level_of_service,
      };
    } else {
      const currentWh = typeof props.warehouseFilter.selectedWarehouses === 'string' ? _.find(props.warehouseFilter.warehouses, {id: props.warehouseFilter.selectedWarehouses}) : _.find(props.warehouseFilter.warehouses, {id: props.warehouseFilter.selectedWarehouses[0]});
      const destinationWh = props.warehouseFilter.warehouses.find((wh) => wh.id !== currentWh.id);

      initialValues = {
        //  warehouse_code: quoteFormData.warehouse_code || currentWh,
        warehouse_code: quoteFormData.warehouse_code || (currentWh ? currentWh.location_code : ''),
        account_code: quoteFormData.account_code || "",
        type_of_order: quoteFormData.type_of_order || "D",
        level_of_service: quoteFormData.level_of_service || "",
        // pickup_level_of_service: quoteFormData.pickup_level_of_service || "",
        delivery_level_of_service: quoteFormData.delivery_level_of_service || "",
        weight: quoteFormData.weight || '',
        is_hazardous: quoteFormData.is_hazardous || false,
        is_line_haul: quoteFormData.is_line_haul || false,
        quote_date: quoteFormData.quote_date ? moment.utc(quoteFormData.quote_date) : moment(),
        expiry_date: quoteFormData.expiry_date ? moment.utc(quoteFormData.expiry_date) : moment().add(7, 'day'),
        hawb: quoteFormData.hawb,
        mawb: quoteFormData.mawb,
        notes: quoteFormData.notes,
        quote_disclaimer: quoteFormData.quote_disclaimer || quote_disclaimer,
        pallets: quoteFormData.pallets || 0,
        wh_location_id: currentWh ? currentWh.id : '',
        target_warehouse_code: quoteFormData.target_warehouse_code ||  (destinationWh ? destinationWh.location_code : ''),
        target_account_code: quoteFormData.target_account_code || "",
        target_level_of_service: quoteFormData.target_level_of_service || "",
        target_wh_location_id: destinationWh ? destinationWh.id : '',
        // quote_disclaimer: !isEmpty(quoteFormData.quote_disclaimer)
        //   ? quoteFormData.quote_disclaimer 
        //   : quote_disclaimer,      
      };
    }
    form.setFieldsValue(initialValues);
    // setQuoteFormData({ ...quoteFormData, ...initialValues } );
  }

  useEffect(() => {
    fillFormFields();
  }, [activeRecordId , quoteFormData]);


  useEffect(() => {
    const { form } = props;
    const { level_of_service } = form.getFieldsValue();
    if (level_of_service && level_of_service.length) {
      const losIndex = _.findIndex(levelOfServices, {
        code: level_of_service,
      });
      if (losIndex >= 0) {
        const los = levelOfServices[ losIndex ];
        form.setFieldsValue({
          level_of_service: los.code,
        });
      }
    }else{
      form.setFieldsValue({
        level_of_service: "",
      });
    }
  }, [ levelOfServices ]);

  // useEffect ( () => {
  // },[ quote_disclaimer] )

  const removeEmptyPayloadKeys = (obj) => {
    for(const key in obj){
      if(obj[key] === undefined || obj[key] === null || obj[key] === ""){
        delete obj[key];
      }
    }
  }

  const handleSubmit = (e) => {
    e && e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        // const isNew = !(quoteFormData && currentQuote.id);
        const isNew = !(activeRecordId);
        const currentWh = typeof props.warehouseFilter.selectedWarehouses === 'string' ? _.find(props.warehouseFilter.warehouses, {id: props.warehouseFilter.selectedWarehouses}) : _.find(props.warehouseFilter.warehouses, {id: props.warehouseFilter.selectedWarehouses[0]});
        const destinationWh = props.warehouseFilter.warehouses.find((wh) => wh.id !== currentWh.id);
        const accObj = getValueFromArrayOfObjects(accounts,"code", quoteFormData.account_code);
        const targetAccObj = getValueFromArrayOfObjects(targetAccounts,"code", quoteFormData.target_account_code);
        const payload = {...values};
        try {
          setIsLoading(true)
          payload.organization_id = userStore.getStateValue('selectedOrg')
          payload.account_id = accObj?.id || ''; // quoteFormData.account_id;
          payload.wh_location_id = quoteFormData.wh_location_id ? quoteFormData.wh_location_id : currentWh.id;
          payload.expiry_date = moment(payload.expiry_date).format('YYYY-MM-DD 23:59:59')
          payload.quote_date = moment(payload.quote_date).format('YYYY-MM-DD 23:59:59')
          payload.type_of_order = quoteFormData.type_of_order || "D";
          payload.los_name = getValueFromArrayOfObjects(levelOfServices, "code", quoteFormData.level_of_service, "name");
          payload.los_id = getValueFromArrayOfObjects(levelOfServices, "code", quoteFormData.level_of_service, "id");
          payload.delivery_los_name = !isEmpty(quoteFormData.delivery_level_of_service) ? ( getValueFromArrayOfObjects(levelOfServices, "code", quoteFormData.delivery_level_of_service  , "name")) : _.get(quoteFormData, 'drop_params.los_name', null);
          payload.delivery_los_id = !isEmpty(quoteFormData.delivery_level_of_service) ? getValueFromArrayOfObjects(levelOfServices, "code", quoteFormData.delivery_level_of_service, "id") : _.get(quoteFormData, 'drop_params.los_id', null);
          payload.account_name = getValueFromArrayOfObjects(accounts,"code", quoteFormData.account_code,"name");
          payload.target_wh_location_id = destinationWh ? destinationWh.id : '';
          payload.target_account_id = targetAccObj?.id || '';
          payload.target_account_name = getValueFromArrayOfObjects(targetAccounts,"code", quoteFormData.target_account_code,"name");
          payload.target_los_id =  getValueFromArrayOfObjects(targetLevelOfServices, "code", quoteFormData.target_level_of_service, "id");
          payload.target_los_name = getValueFromArrayOfObjects(targetLevelOfServices, "code", quoteFormData.target_level_of_service, "name");
          payload.target_warehouse_name = destinationWh ? destinationWh.name : '';
          let response = {}
           if (!payload.wh_location_id){
            alertMessage("Please Select warehouse", 'error', 10)
          }
          removeEmptyPayloadKeys(payload)
          if(!isNew){
            obtainSaveDetails(quoteFormData)
            payload.quote_order_number = quoteFormData.quote_order_number;
            response = await QuotesApis.update(quoteFormData.id, payload)
          } else {
            response = await QuotesApis.create(payload)
          }
          
          if (response.success) {
            const quote = response?.quote ? response.quote : {}
            setCurrentQuote((prevQuote) => ({ ...prevQuote, ...quote.quote, wh_location_details: quote.wh_location_details }));
            setActiveRecordId(quote.quote.id)
            obtainSaveDetails(payload)
            handleSuccess()
            // setQuoteFormData({ ...quoteFormData, ...quote })
            const successMessage = response?.message
              ? result.message
              : I18n.t('messages.saved')
            if(response.errors && response.errors.length > 0) {
              setErrors(response.errors)
              renderAlertMessage(response.errors || [])
            } else{
              alertMessage(successMessage)
            }
          } else {
            handleFailure()
            setErrors(response.errors)
            renderAlertMessage(response.errors || [])
          }
        } catch (error) {
          alertMessage(error.message, 'error', 10)
        } finally {
          setIsLoading(false)
        }
      }else{
        toggleClickedSave(false)
      }
    });
  };

  const onFieldChange = (key, value) => {
    setUnsavedChanges(true);
    const { form } = props;
       if(key === 'account_code'){
        form.setFieldsValue({ level_of_service: "" ,pickup_level_of_service:"",delivery_level_of_service:"", account_code: value });
        setQuoteFormData({ ...quoteFormData, level_of_service: "" ,pickup_level_of_service:"",delivery_level_of_service:"", account_code: value })
      }
      else if(key === "target_account_code"){
        form.setFieldsValue({ target_level_of_service: "" ,target_account_code: value });
        setQuoteFormData({ ...quoteFormData, target_level_of_service: "" ,target_account_code: value })
      }
      else if (key === 'type_of_order') {

        const defaultAppointment = [
          {
            s_no: 1,
            // item_preference: "",
            item_preference: moment(),
            item_option: [ 'ANY' ],
            has_expedite: false,
          } ];

        // data shuffling when changing type of order
        const prevTypeOfOrder = form.getFieldsValue().type_of_order;
        function updateQuotes (updateData, key, value) {
          setCurrentQuote(prevData => ({
            ...prevData,
            ...updateData,
          }));
          setQuoteFormData(prevData => ({
            ...prevData,
            ...updateData,
            [ key ]: value,
          }));
        }

        const { delivery_location_details = {}  , pickup_location_details = {} } = currentQuote || {};

        if (value === 'D') {
          const { level_of_service , type_of_order } = form.getFieldsValue() || {};
          updateQuotes({
            delivery_location_id: currentQuote?.delivery_location_id || "",
            delivery_location_details: delivery_location_details,
            // level_of_service: prevTypeOfOrder === "T" ? delivery_location_details.los_code : "",
            level_of_service: prevTypeOfOrder === "T" ? delivery_location_details.los_code : prevTypeOfOrder === 'R' ? level_of_service : "",
          }, key, value);
          form.setFieldsValue({ level_of_service: prevTypeOfOrder === "T" ? delivery_location_details.los_code : prevTypeOfOrder === 'R' ? level_of_service : "" });
        } else if (value === 'R') {
          const { level_of_service , type_of_order } = form.getFieldsValue() || {};
          updateQuotes({
            pickup_location_id: currentQuote?.pickup_location_id || "",
            pickup_location_details: pickup_location_details,
            level_of_service: prevTypeOfOrder === "T" ? pickup_location_details.los_code : prevTypeOfOrder === 'D' ? level_of_service : "", 
          }, key, value);
          form.setFieldsValue({ level_of_service: prevTypeOfOrder === "T" ? pickup_location_details.los_code  : prevTypeOfOrder === 'D' ? level_of_service : "" });
        } else if (value === 'T') {
          const { level_of_service , type_of_order } = form.getFieldsValue() || {};
          const params = type_of_order === "D"
            ? { pickup_params: { preferences: defaultAppointment }, pickup_location_id: null, pickup_location_details: {} , delivery_level_of_service: level_of_service , level_of_service: "" }
            : { drop_params: { preferences: defaultAppointment }, delivery_location_id: null, delivery_location_details: {} , level_of_service: level_of_service , delivery_level_of_service: "" };
          if(type_of_order === "D"){
            form.setFieldsValue({ level_of_service: "" , delivery_level_of_service: level_of_service , pickup_level_of_service: "" });
          }else if(type_of_order === "R"){
            form.setFieldsValue({ pickup_level_of_service: "" , level_of_service: level_of_service , delivery_level_of_service: "" });
          }
          updateQuotes(params, key, value);
        }else if(value === "M"){
          const { level_of_service , type_of_order , delivery_level_of_service } = form.getFieldsValue() || {};
          if(type_of_order === "D" || type_of_order === "R"){
            form.setFieldsValue({ level_of_service });
          }else if(type_of_order === "T"){
            form.setFieldsValue({ level_of_service  , delivery_level_of_service , pickup_level_of_service: level_of_service });
          }
          updateQuotes({ level_of_service }, key, value);
        }
      }
      else if(key === 'quote_disclaimer'){
        setQuoteDisclaimer(value)
        form.setFieldsValue({ [key]: value });
        setQuoteFormData({ ...quoteFormData, [key]: value });
      }
      else if(key === "type_of_order" && value !== "T"){
        form.setFieldsValue({ target_level_of_service: "",pickup_level_of_service: "", delivery_level_of_service: "" });
        setQuoteFormData({ ...quoteFormData, target_level_of_service: "",pickup_level_of_service: "", delivery_level_of_service: "" })
      }
      else{
        form.setFieldsValue({ [ key ]: value });
        setQuoteFormData({ ...quoteFormData, [ key ]: value })
      }
  }

  // const onFieldChange = (key, value) => {
  //   setUnsavedChanges(true);
  //   const { form } = props;

  //   const resetFields = (fields) => {
  //     form.setFieldsValue(fields);
  //     setQuoteFormData(prevData => ({ ...prevData, ...fields }));
  //   };

  //   const handleOrderTypeChange = (value) => {
  //     const defaultAppointment = [ {
  //       s_no: 1,
  //       item_preference: moment(),
  //       item_option: [ 'ANY' ],
  //       has_expedite: false,
  //     } ];

  //     const resetQuoteData = {
  //       delivery_location_id: null,
  //       pickup_location_id: null,
  //       pickup_location_details: {},
  //       delivery_location_details: {},
  //       pickup_params: { preferences: defaultAppointment },
  //       drop_params: { preferences: defaultAppointment },
  //       [ key ]: value,
  //     };

  //     if (value === 'D') {
  //       resetQuoteData.delivery_location_id = null;
  //       resetQuoteData.delivery_location_details = {};
  //     } else if (value === 'R') {
  //       resetQuoteData.pickup_location_id = null;
  //       resetQuoteData.pickup_location_details = {};
  //     }

  //     setCurrentQuote(prevData => ({ ...prevData, ...resetQuoteData }));
  //   };

  //   switch (key) {
  //     case 'account_code':
  //       resetFields({
  //         level_of_service: "",
  //         pickup_level_of_service: "",
  //         delivery_level_of_service: "",
  //         account_code: value
  //       });
  //       break;
  //     case 'target_account_code':
  //       resetFields({
  //         target_level_of_service: "",
  //         target_account_code: value
  //       });
  //       break;
  //     case 'type_of_order':
  //       handleOrderTypeChange(value);
  //       break;
  //     case 'quote_disclaimer':
  //       setQuoteDisclaimer(value);
  //       resetFields({ [ key ]: value });
  //       break;
  //     case 'delivery_level_of_service':
  //       if (activeRecordId) {
  //         form.setFieldsValue({ delivery_level_of_service: value });
  //         setQuoteFormData({ ...quoteFormData, drop_params: { ...quoteFormData.drop_params, los_code: value } })
  //         setDelLosName(getValueFromArrayOfObjects(levelOfServices, "code", value, "name"))
  //         setDelLosId(getValueFromArrayOfObjects(levelOfServices, "code", value, "id"))
  //       }
  //       break;
  //     default:
  //       resetFields({ [ key ]: value });
  //   }
  // };


  const { getFieldDecorator } = props.form;
  const isAirCargoCarrier = isAirCargo();
  return (
    <Form onSubmit={handleSubmit} layout={"vertical"}>
      <Row gutter={16} type="flex">
        <Col span={quoteFormData.type_of_order === "T" ? 4 : 6}>
          <Form.Item label={I18n.t("location.location")}>
            {getFieldDecorator("warehouse_code", {
              rules: [
                {
                  required: true,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <Select
                onChange={(value) => {
                  onFieldChange("warehouse_code", value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {/* <Select.Option key="warehouse" value="">
                  -- Select --
                </Select.Option> */}
                {warehouses.map((warehouse) => (
                  <Select.Option
                    key={warehouse.location_code}
                    value={warehouse.location_code}
                  >
                    {warehouse.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={quoteFormData.type_of_order === "T" ? 4 : 6}>
          <Form.Item label={I18n.t("general.account")}>
            {getFieldDecorator("account_code", {
              rules: [
                {
                  required: true,
                  message: I18n.t("account.account_required", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <Select
                onChange={(value) => {
                  onFieldChange("account_code", value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Select.Option key="account" value="">
                  -- Select --
                </Select.Option>
                {accounts.map((account) => (
                  <Select.Option key={account.code} value={account.code}>
                    {`${account.name} (${account.code})`}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label={I18n.t("order.type")}>
            {getFieldDecorator("type_of_order", {
              initialValue: "D",
              rules: [
                {
                  required: true,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                  min: 1,
                },
              ],
            })(
              <Select
              onChange={(value) => {
                onFieldChange("type_of_order", value);
              }}
                disabled={
                  activeRecordId &&
                  currentQuote.type_of_order === orderConfig.ORDER_TYPES.M.symbol 
                }
            >
              {Object.keys(orderConfig.orderTypes)
                // .filter((key) => key !== "X" && !["LH"].includes(key))
                .filter((key) => {
                  if (isAirCargoCarrier) {
                    return !["X", "LH", "M"].includes(key);
                  } else {
                    return !["X", "LH"].includes(key);
                  }
                })
                .map((key) => (
                  <Select.Option
                    value={key}
                    key={key}
                    // disabled={
                    //   (activeRecordId &&
                    //     ((key === AppConfig.ORDER_TYPES.M.symbol &&
                    //       currentQuote.type_of_order !== AppConfig.ORDER_TYPES.M.symbol) ||
                    //      (key === AppConfig.ORDER_TYPES.T.symbol &&
                    //       currentQuote.type_of_order !== AppConfig.ORDER_TYPES.T.symbol)))
                    // }
                  >
                    {orderConfig.orderTypes[key].label}
                  </Select.Option>
                ))}
            </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={
              quoteFormData.type_of_order === "T"
                ? `${I18n.t("general.pickup")} ${I18n.t("los.label")}`
                : I18n.t("los.label")
            }
          >
            {/* {getFieldDecorator(quoteFormData.type_of_order === "T" ? "pickup_level_of_service" :"level_of_service", { */}
            {getFieldDecorator("level_of_service", {
              rules: [
                {
                  required: true,
                  message: I18n.t("errors.los_required", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <Select
                onChange={(value) => {
                  // onFieldChange(quoteFormData.type_of_order === "T" ? "pickup_level_of_service" : "level_of_service", value);
                  onFieldChange( "level_of_service", value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Select.Option key="LOS" value="">
                  -- Select --
                </Select.Option>
                {levelOfServices.map((los) => (
                  <Select.Option key={los.code} value={los.code}>
                    {los.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {quoteFormData.type_of_order === "T" ? (
          <Col span={4}>
            <Form.Item
              label={`${I18n.t("general.delivery")} ${I18n.t("los.label")}`}
            >
              {getFieldDecorator("delivery_level_of_service", {
                rules: [
                  {
                    required: true,
                    message: I18n.t("errors.los_required", {
                      field: I18n.t("general.value"),
                    }),
                  },
                ],
              })(
                <Select
                  onChange={(value) => {
                    onFieldChange("delivery_level_of_service", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key="del_los" value="">
                    -- Select --
                  </Select.Option>
                  {levelOfServices.map((los) => (
                    <Select.Option key={los.code} value={los.code}>
                      {los.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        ) : null}
        <Col span={4}>
          <Form.Item label={I18n.t("order.weight")}>
            {getFieldDecorator("weight", {
              rules: [
                {
                  required: quoteFormData.type_of_order === "T" ? false : true,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <Input
                type="number"
                precision={0}
                min={0}
                step="1"
                onChange={(e) => {
                  onFieldChange("weight", e.target.value);
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col span={6}>
        <Form.Item label={ I18n.t("quotes.expiry_date") }>
          { getFieldDecorator("expiry_date", { rules: [ { required: true, message: I18n.t("errors.invalid_field", { field: I18n.t("general.value") }) } ] })(
            <DatePicker
              style={{
                width: "100%",
              }}
              format="DD MMM, YYYY"
              onChange={(e) => onFieldChange("expiry_date" , e)}
            />
          ) }
        </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={16} type="flex">
        <Col span={3}>
          <Form.Item label={I18n.t("quotes.quote_date")}>
            {getFieldDecorator("quote_date", {
              rules: [
                {
                  required: false,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <DatePicker
                format="DD MMM, YYYY"
                onChange={(e) => onFieldChange("quote_date", e)}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label={I18n.t("quotes.expiry_date")}>
            {getFieldDecorator("expiry_date", {
              rules: [
                {
                  required: true,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <DatePicker
                format="DD MMM, YYYY"
                onChange={(e) => onFieldChange("expiry_date", e)}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label={I18n.t("general.mawb")}>
            {getFieldDecorator("mawb", {
              rules: [
                {
                  required: false,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <Input
                onChange={(e) => {
                  onFieldChange("mawb", e.target.value);
                }}
                className="width100Per"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label={I18n.t("general.hawb")}>
            {getFieldDecorator("hawb", {
              rules: [
                {
                  required: currentOrg.code == I18n.t("account.hl_code") ? true : false,
                  message: I18n.t(
                    currentOrg.code == I18n.t("account.hl_code")
                      ? "account.hawb_required"
                      : "errors.invalid_field",
                    {
                      field: I18n.t("general.value"),
                    }
                  ),
                },
              ],
            })(
              <Input
                onChange={(e) => {
                  onFieldChange("hawb", e.target.value);
                }}
                className="width100Per"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label={I18n.t("order.pallets")}>
            {getFieldDecorator("pallets", {
              rules: [
                {
                  required: false,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <Input
                type="number"
                precision={0}
                min={0}
                step="1"
                onChange={(e) => {
                  onFieldChange("pallets", e.target.value);
                }}
                className="width100Per"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label={I18n.t("order.is_hazardous")}>
            {getFieldDecorator("is_hazardous", {
              rules: [
                {
                  required: true,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <RadioGroup
                style={{ marginTop: 3 }}
                onChange={(e) => {
                  onFieldChange("is_hazardous", e.target.value);
                }}
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>NO</Radio>
              </RadioGroup>
            )}
          </Form.Item>
        </Col>
        {/* {quoteFormData.type_of_order === AppConfig.ORDER_TYPES.T.symbol ? ( */}
          <Col span={6}>
            <Form.Item label={I18n.t("order.is_lineHaul")}>
              {getFieldDecorator("is_line_haul", {
                rules: [
                  {
                    required: true,
                    message: I18n.t("errors.invalid_field", {
                      field: I18n.t("general.value"),
                    }),
                  },
                ],
              })(
                <RadioGroup
                  style={{ marginTop: 2 }}
                  onChange={(e) => {
                    onFieldChange("is_line_haul", e.target.value);
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>NO</Radio>
                </RadioGroup>
              )}
            </Form.Item>
          </Col>
        {/* ) : null} */}
      </Row>
      {quoteFormData.is_line_haul ? (
        <Row gutter={16} type="flex">
          <Col span={8}>
            <Form.Item
              label={`${I18n.t("general.target")} ${I18n.t(
                "location.location"
              )}`}
            >
              {getFieldDecorator("target_warehouse_code", {
                rules: [
                  {
                    required: true,
                    message: I18n.t("errors.invalid_field", {
                      field: I18n.t("general.value"),
                    }),
                  },
                ],
              })(
                <Select
                  onChange={(value) => {
                    onFieldChange("target_warehouse_code", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {warehouses.map((warehouse) => (
                    <Select.Option
                      key={warehouse.location_code}
                      value={warehouse.location_code}
                    >
                      {warehouse.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={`${I18n.t("general.target")} ${I18n.t("general.account")}`}
            >
              {getFieldDecorator("target_account_code", {
                rules: [
                  {
                    required: true,
                    message: I18n.t("account.account_required", {
                      field: I18n.t("general.value"),
                    }),
                  },
                ],
              })(
                <Select
                  onChange={(value) => {
                    onFieldChange("target_account_code", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key="account" value="">
                    -- Select --
                  </Select.Option>
                  {targetAccounts?.map((account) => (
                    <Select.Option key={account.code} value={account.code}>
                      {`${account.name} (${account.code})`}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={`${I18n.t("general.target")} ${I18n.t("los.label")}`}
            >
              {getFieldDecorator("target_level_of_service", {
                rules: [
                  {
                    required: true,
                    message: I18n.t("errors.los_required", {
                      field: I18n.t("general.value"),
                    }),
                  },
                ],
              })(
                <Select
                  onChange={(value) => {
                    onFieldChange("target_level_of_service", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key="LOS" value="">
                    -- Select --
                  </Select.Option>
                  {targetLevelOfServices.map((los) => (
                    <Select.Option key={los.code} value={los.code}>
                      {los.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      ) : null}
      <Row gutter={16} type="flex">
        <Col span={24}>
          <Form.Item label={I18n.t("general.notes")}>
            {getFieldDecorator("notes", {
              rules: [
                {
                  required: false,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <TextArea
                onChange={(e) => onFieldChange("notes", e.target.value)}
                autoSize={true}
              ></TextArea>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex">
        <Col span={24}>
          <Form.Item label={I18n.t("menu.disclaimer")}>
            {getFieldDecorator("quote_disclaimer", {
              rules: [
                {
                  required: false,
                  message: I18n.t("errors.invalid_field", {
                    field: I18n.t("general.value"),
                  }),
                },
              ],
            })(
              <TextArea
                onChange={(e) =>
                  onFieldChange("quote_disclaimer", e.target.value)
                }
                autoSize={true}
              ></TextArea>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        {/* <Col>
          <Form.Item>
            <Button style={ {
              width: 120,
              height: 35,
              borderRadius: 25,
              marginLeft: 20,
            } } type="primary" htmlType="submit" loading={ isLoading }>
              { I18n.t("general.save") }
            </Button>
          </Form.Item>
        </Col> */}
      </Row>
    </Form>
  );
}
const QuoteDetailsForm = Form.create({ name: "quote_details" })(QuoteOrderDetails);
export { QuoteDetailsForm };

QuoteDetailsForm.propTypes = {
  currentQuote: PropTypes.shape(),
};

QuoteDetailsForm.defaultProps = {
  currentQuote: null
};
