import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Spin } from "antd";
import { fetchOrganizations } from "../api/Organisations";
import MobileConfigList from "../components/common/MobileConfiguration/MobileConfigList";
import { MobileConfiguration } from "../api/MobileConfiguration";
import { alertMessage, isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import BaseModal from "../components/BaseModal";
import MobileConfigurationForms from "../components/common/MobileConfiguration/MobileConfigForm";
import AppConfig from "../config/AppConfig";
import { FormItem } from "../common/UIComponents";
import userStore from "../stores/UserStore";
import Item from "antd/lib/list/Item";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";
import _ from "lodash";
import { renderAlertMessage } from "../helpers/common";

const MobileConfig = () => {
    const [showModal, setShowModal] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [mobileConfigData, setMobileConfigData] = useState([]);
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [existingMobileId, setExistingMobileId] = useState(null);
  const userContext = useContext(UserContext);
  const { updateRecordsPerPage } = userContext;
  const perPageKey = ScreenKeys.MOBILE_CONFIGURATIONS;
  const recordsPerPageSaved = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage);
  const [ recordsPerPage, setRecordsPerPage ] = useState(recordsPerPageSaved);


  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(()=> {
    if(organizationId){
      getMobileConfigFetch();
    }
  },[organizationId, recordsPerPage])
  
  const handleFilterChange = (element, value) => {
    setOrganizationId(value);
  };

  const deleteMobileConfiguration = (id) => {
    setInProgress(true);
    MobileConfiguration.delete(id).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success");
        const mobileData = mobileConfigData.filter((item) => item.id !== id);
        setMobileConfigData(mobileData);
      } else {
        renderAlertMessage(result.errors)
      }
    });
    setInProgress(false)
  };

  const editMobileConfiguration = (id) => {
    setShowModal(true);
    setExistingMobileId(id);
  };

  const getMobileConfigFetch = () => {
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = recordsPerPage;
    setInProgress(true);
    MobileConfiguration.fetch(organizationId,page,perPage).then((result) => {
      if (result.success) {
        setMobileConfigData(result.mobile_configurations);
        setExistingMobileId(result.mobile_configurations.id);
        setPagination(result.pagination);
        setInProgress(false)
      } else {
        renderAlertMessage(result.errors)
      }
    });
  };

  const handleAddClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setExistingMobileId(null);
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
    getMobileConfigFetch();
  };

  

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);

  };


  let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  const tablePagination = {
    total: pagination ? pagination.total_count : 0,
    current: pagination ? pagination.current_page : 1,
    pageSize: pagination ? pagination.per_page || recordsPerPage : recordsPerPage,
  };

  const getOrganizations = () => {
    setInProgress(true);
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const orgRec = _.find(result.organizations.organizations, [
          "code",
          I18n.t("account.netmove_code"),
        ]);
        let orgId = !isEmpty(orgRec) ? orgRec._id : "";
        if (!orgId) {
          orgId =
            result.organizations?.organizations?.length > 0
              ? result.organizations.organizations[0].id
              : "";
        }
        setOrganizations(result.organizations.organizations);
        setOrganizationId(orgId);
        setInProgress(false);
      } else {
        renderAlertMessage(result.errors)
        setInProgress(false);
      }
    });
  };


  const MobileConfigurationForm = ()=> {
    return (
        <BaseModal
        title={
          existingMobileId ? I18n.t("MobileConfiguration.edit") : I18n.t("MobileConfiguration.add")
        }
         visible ={showModal}
         onCancel = {closeModal}
        //  onOk ={validateForm}
         footer ={null}
         width ={"600px"}
         maskClosable={false}
        >
            <MobileConfigurationForms 
            closeModal= {closeModal}
            existingMobileId = {existingMobileId}
            getMobileConfigFetch = {getMobileConfigFetch}

            />
          
        </BaseModal>
    )
  }

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>{I18n.t("MobileConfiguration.menu")}</Col>
          <Col xs={12} className="page-actions">
            <Button type="primary" onClick={() => handleAddClick()} icon="plus">
              {`${I18n.t("general.add")} ${I18n.t("MobileConfiguration.menu")}`}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={24} xs={24} md={5} lg={5}>
            <FormItem label={I18n.t("organization.single")} require>
              <Select
                value={organizationId}
                showSearch
                onChange={(e) => handleFilterChange("organization", e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              >
                {organizations.map((orgtype) => (
                  <Select.Option key={orgtype.code} value={orgtype._id}>
                    {orgtype.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <RecordsPerPage
              onChange={onRecordChange}
              value={recordsPerPage}
              defaultValue={recordsPerPage}
            />
          </Col>
        </Row>
        <Spin spinning={inProgress} delay={1000}>
          <Row>
            <Col>
              <MobileConfigList
                data={mobileConfigData}
                pagination={tablePagination}
                tableChange={(pagination, filter, sorter, currentTable) =>
                  onTableChange(pagination, sorter, currentTable)
                }
                handleDelete={(id) => deleteMobileConfiguration(id)}
                handleEdit={(id) => editMobileConfiguration(id)}
                scroll={{ y: "calc(100vh - 310px)" }}
              />
            </Col>
          </Row>
        </Spin>
        {showModal && MobileConfigurationForm()}
      </div>
    </div>
  );
};

export default MobileConfig;