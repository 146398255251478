/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import {
  Col,
  Row,
  Icon,
  Modal,
  Card,
  Tooltip,
  Popover,
  Spin
} from '../../common/UIComponents'
import {ellipseText} from '../../common/Common'
import I18n from '../../common/I18n'
import verified from '../../../assets/images/verified.png'
import verifiedGrey from '../../../assets/images/verified_grey.png'
import reseqIcon from '../../../assets/images/verified_o.png'
import recoveryIcon from '../../../assets/images/recovery_r.png'
import FormattedErrors from '../common/FormattedErrors'
import driver from '../../../assets/images/driver.png'
import AppConfig from '../../config/AppConfig'
import { momentTime } from '../../helpers/date_functions'
import {
  checkServiceExistance,
  generateAccessMessage,
} from '../../helpers/common'
import { Droppable } from 'react-beautiful-dnd'
// import { OrganizationSettingsContext } from '../../context/OrganizationSettings'
import DeleteIcon from '../common/DeleteIcon'
import BranchesIcon from '../common/BranchesIcon'
import DispatchRouteLog from '../Routes/DispatchRouteLog'
import { OrgContext } from '../../context/OrgContext';
import releaseIcon from '../../../assets/images/release.svg'

const { confirm } = Modal
const Route = (props) => {
  const {
    route,
    rowNumber,
    getRouteStopsInfo,
    assignDriver,
    deleteClick,
    preplnDriverZones,
    currentRoute,
    handleComplete,
    currentStatus,
    isMilitaryTime,
    cardLoading
  } = props
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [visible, setVisible] = useState(false)
  const drivers = [route.primary_driver_id]

  let hasSecondary = false
  const isNotOptimized =
    route.optimization_type === 'auto' &&
    route.optimized === false &&
    (!route.optimizer_readable_error_message ||
      (_.isArray(route.optimizer_readable_error_message) &&
        route.optimizer_readable_error_message.length === 0))
  if (route.secondary_driver_id) {
    hasSecondary = true
    drivers.push(route.secondary_driver_id)
  }

  const routeDetailsVisible = checkServiceExistance('NRSIR')
  const handleVisibleChange = (flag) => {
    setVisible({ visible: flag })
  }

  const handleMenuClick = (e) => {
    if (e.key !== '3') {
      setVisible(false)
    }
  }

  const onDeleteConfirm = (id,route_type) => {
    deleteClick(id,route_type)
    setVisible(false)
  }

  const onDeleteCancel = () => {
    setVisible(false)
  }

  const takeConfirmation = (id, e ,route_type) => {
    e.stopPropagation();
    onDeleteCancel()
    confirm({
      title: 'Do you want to delete it',
      content:
        'When clicked the OK button, this route will be deleted permanently',
      onOk: () => {
        onDeleteConfirm(id, route_type)
      },
      onCancel: () => {},
    })
  }

  const renderIcons = () => {
    const whStopscount = route.nav_stops ? route.nav_stops.filter(
      (stop) => stop.location_type === 'WH'
    ).length : 0;
    return (
    <div className="blkCenterRight" style={{marginLeft: -13}}>
      {route.optimizer_readable_error_message &&
        _.isArray(route.optimizer_readable_error_message) &&
        route.optimizer_readable_error_message.length > 0 && (
          <Popover
            content={FormattedErrors(
              route.optimizer_readable_error_message || [],
            )}
          >
            <Icon
              type="warning"
              className="marginRight5 textBold textRed fontSize12"
            />
          </Popover>
        )}
      {['recovery_route', 'release_route', 'lh_route'].includes(route.route_type) &&
        checkServiceExistance("ORRD") &&
        route.status === "ASSIGNED" && (
          <Tooltip title={I18n.t("general.delete")}>
          <DeleteIcon handleClick={(e) => takeConfirmation(route.id, e , route.route_type)}/>
          &nbsp;
          </Tooltip>
        )}

      {checkServiceExistance("NRA") && <DispatchRouteLog route={route} isMilitaryTime={isMilitaryTime} keyId={'id'} />}

      {checkServiceExistance("NRTNR") &&
        route.route_type === "nav_route" &&
        route.status !== "COMPLETED" && (
          <Tooltip title={I18n.t("routes.complete")}>
            <Icon
              type="check"
              onClick={() => handleComplete(route.id)}
              className="marginRight12 textBold fontSize13"
            />
          </Tooltip>
        )}
      {checkServiceExistance("PPRD") && route.status !== "COMPLETED" && (
        <Tooltip title={I18n.t("general.reassign")} position="topLeft">
          <img
            src={driver}
            style={{ width: 14, marginTop: 3, position: "absolute", marginLeft: -11 }}
            alt="driver"
            onClick={(event) => {
              event.stopPropagation()
              assignDriver(route.id)
            }}
          />
          &nbsp;&nbsp;
        </Tooltip>
      )}
      <div
        className="routeCompletedCount"
        style={{
          backgroundColor: `#004b7d`,
          color: `#fff`,
          fontSize: 12,
        }}
      >
        {
          route.nav_stops.filter(
            (stop) => stop.status == 'COMPLETED' && stop.location_type != 'WH'
          ).length
        }
        / {route.nav_stops_count >= whStopscount ? (route.nav_stops_count - whStopscount) : route.nav_stops_count}
      </div>
    </div>
  )}

  const renderDistance = () =>
    !isNotOptimized ? (
      <Fragment>
        <span>
          <Tooltip title={I18n.t('general.distance')}>
            <BranchesIcon/>
            &nbsp;{route.total_distance}
          </Tooltip>
        </span>
        &nbsp;-&nbsp;
        <span>
          <Tooltip title={I18n.t('general.travel_time')}>
            <Icon type="clock-circle" />
            &nbsp;{route.total_time}
          </Tooltip>
        </span>
      </Fragment>
    ) : (
      <span> Not optimized</span>
    )

  const renderTime = () => {
    const isMilitaryTime = organizationSettings.is_military_time === 'true'
    const timeFormat = isMilitaryTime ? 'MMM, Do HH:mm' : 'MMM, Do hh:mm A'
    return (
      <div className="fontWeight500">
        <Tooltip title={I18n.t('days.date')}>
          <Icon type="clock-circle" /> &nbsp;
          {route.route_start_time ? (
            <Fragment>
              {momentTime(timeFormat, route.route_start_time, route.timeZoneId)}
              {false &&
                moment.utc(new Date(route.route_start_time)).format(timeFormat)}
            </Fragment>
          ) : (
            'NA'
          )}
        </Tooltip>
      </div>
    )
  }

  const renderOptimizeIcon = () =>
    route.optimization_type === 'manual' ? (
      <img src={reseqIcon} style={{ width: 17 }} alt="manual" />
    ) : !isNotOptimized ? (
      <img src={verified} style={{ width: 17 }} alt="optimized" />
    ) : (
      <img src={verifiedGrey} style={{ width: 17 }} alt="not_optimized" />
    )

  const renderStatus = () => {
    const statusRecord = _.find(AppConfig.routeStatus, { key: route.r_status })
    const icon = statusRecord.icon ? statusRecord.icon : 'bulb'
    return currentStatus === 'ALL' ? (
      <Col xs={24} className="fontsize11 smallText marginTop3 textBold">
        {/* I18n.t(`status_labels.${route.r_status}`) */}
        <Icon type={icon} />
        &nbsp;&nbsp;{route.r_status}
      </Col>
    ) : (
      <Fragment />
    )
  }
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? '#e8e8e8' : 'white',
    // padding: grid,
    height: isDraggingOver ? 75 : 'auto',
    borderStyle: isDraggingOver ? 'dashed' : '',
  })

  return (
    <div className={`preplanMgmt routeCard routeCard-${route.id}`}>
      <Spin
        spinning={cardLoading}
        delay={1000}
      >
      <Droppable
        droppableId={`droppableRouteItem${route.id}`}
        isDropDisabled={route.r_status === 'COMPLETED'}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <Card
              id={route.name}
              size="small"
              onClick={() =>
                routeDetailsVisible
                  ? getRouteStopsInfo(route.id)
                  : generateAccessMessage(I18n.t('messages.dont_have_access'))
              }
              className={`boxShadow ${
                route.id === currentRoute.id ? 'currentActiveStop' : ''
              }`}
              bodyStyle={{
                padding: '8px 8px 6px 8px',
                paddingLeft: 10,
                // borderLeft: route.optimized === true ? '3px solid green' : 'none',
              }}
              headStyle={{
                fontSize: 12,
              }}
              style={{
                marginBottom: 10,
                border:
                  currentRoute.id === route.id
                    ? `2px solid #${route.bgColor}`
                    : `1px solid #${route.bgColor}`,
                borderRadius: 10,
                backgroundColor:
                  currentRoute.id === route.id ? '#dde2e3' : '#FFF',
              }}
            >
              {/* <span style={{ float: "right", marginTop: "-35px" }}>{stop.status}</span> */}

              {isNotOptimized ? (
                <Row>
                  <Col
                    xs={10}
                    className={`fontSize12 smallText textBold ${
                      currentStatus !== 'ALL' ? 'blkCenterLeft' : ''
                    }`}
                    style={{ height: 36 }}
                  >
                    <Tooltip title={route.route_type  === "release_route" ? `Release ${route.displayName}` : route.route_type  === "recovery_route" ? `Recovery ${route.displayName}` : route.displayName}>
                      {['recovery_route', 'release_route'].includes(route.route_type) && <Fragment>
                      { route.route_type  === "recovery_route" ? <img src={recoveryIcon}  style={{ width: 17, marginTop: -3 }} /> :

                      <Icon
                        component={() => (
                          <img
                            style={{ height: 13, marginTop: -5 }}
                            src={
                              releaseIcon
                            }
                          />
                        )}
                      />
                      }
                      {' '}</Fragment>}
                      {ellipseText(route.displayName, 10)}
                    </Tooltip>
                    {renderStatus()}
                  </Col>
                  <Col xs={14} style={{ marginTop: -6 }}>
                    {renderIcons()}
                    {/* <div className="alignRight">{renderOptimizeIcon()}</div> */}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col
                    xs={10}
                    className="fontSize12 smallText textBold"
                    style={{ marginTop: -3 }}
                  >
                     <Tooltip title={route.route_type  === "release_route" ? `Release ${route.displayName}` : route.route_type  === "recovery_route" ? `Recovery ${route.displayName}` : route.displayName}>
                      {['recovery_route', 'release_route'].includes(route.route_type) && <Fragment>
                      { route.route_type  === "recovery_route" ? <img src={recoveryIcon}  style={{ width: 17, marginTop: -3 }} /> :

                      <Icon
                        component={() => (
                          <img
                            style={{ height: 13, marginTop: -5 }}
                            src={
                              releaseIcon
                            }
                          />
                        )}
                      />
                      }
                      {' '}</Fragment>}
                      {ellipseText(route.displayName, 10)}
                    </Tooltip>
                    &nbsp;&nbsp;
                  </Col>
                  <Col xs={14} style={{ marginTop: -6 }}>
                    {renderIcons()}
                  </Col>
                  <Col
                    xs={22}
                    className="fontsize11 smallText"
                    style={{ paddingTop: 4 }}
                  >
                    {renderTime()}
                  </Col>
                  <Col xs={2} className="alignRight">
                    {/* {renderOptimizeIcon()} */}
                  </Col>
                  {renderStatus()}
                </Row>
              )}
            </Card>
            {provided && provided.placeholder}
          </div>
        )}
      </Droppable>
    </Spin>
    </div>
  )
}
export default Route

Route.propTypes = {
  rowNumber: PropTypes.number.isRequired,
  route: PropTypes.shape().isRequired,
  assignDriver: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  getRouteStopsInfo: PropTypes.func.isRequired,
  currentRoute: PropTypes.shape().isRequired,
}
Route.defaultProps = {}
