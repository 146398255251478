import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "../../common/UIComponents";
import { doFormate, isEmpty } from "../../common/Common";
import Copyable from "../common/Copyable";
import I18n from "../../common/I18n";
import BaseModal from "../BaseModal";
import { OrderLinkModal } from "./OrderLinkModal";
import { Skeleton, Tooltip } from "antd";

const OrderRefrences = ({
  order,
  goToTab,
  handleMawbClick,
  consolidatedData,
  consolidateLoading,
  getOrderDetails,
  fetchData,
  isConsolidatedPresent
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isDelink, setIsDelink] = useState(false);
  const [activeScreen, setActiveScreen] = useState("");
  const [completedOrders, setCompletedOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [extractedOrderNos, setExtractedOrderNos] = useState([]);

  const handleModalOpen = () => {
    return (
      <>
        <BaseModal
          title={
            !isDelink
              ? I18n.t("general.link_mawb")
              : I18n.t("general.delink_mawb")
          }
          visible={openModal}
          onCancel={() => {
            setOpenModal(false), setActiveScreen("");
            setIsDelink(false);
          }}
          footer={null}
          width={"600px"}
          maskClosable={false}
        >
          <OrderLinkModal
            onCancel={() => {
              setOpenModal(false), setActiveScreen("");
              setIsDelink(false);
            }}
            isDelink={isDelink}
            openModal={openModal}
            activeScreen={activeScreen}
            order={order}
            extractedOrderNos={extractedOrderNos}
            setIsDelink={setIsDelink}
            getOrderDetails={getOrderDetails}
            fetchData={fetchData}
          />
        </BaseModal>
      </>
    );
  };

  useEffect(() => {
    setCompletedOrders(
      consolidatedData.filter((order) => order.status === "COMPLETED")
    );
    setTotalOrders(consolidatedData.length);
    setExtractedOrderNos(
      consolidatedData.map((order) => order.customer_order_number)
    );
  }, [consolidatedData]);

  return (
    <Col>
      {!isEmpty(order.reference_1_type) && (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {order.reference_1_type}:&nbsp;&nbsp;
            </span>
            <span>
              <span>
                {order.reference_1 ? (
                  <>
                    {order.reference_1} <Copyable text={order.reference_1} />
                  </>
                ) : (
                  I18n.t("general.na")
                )}{" "}
                &nbsp;
              </span>
            </span>
          </Col>
        </Row>
      )}
      {!isEmpty(order.reference_2_type) && (
        <Row>
          <Col xs={24}>
            <span className="textBold">
              {order.reference_2_type}:&nbsp;&nbsp;
            </span>
            <span>
              <span>
                {order.reference_2 ? (
                  <>
                    {order.reference_2} <Copyable text={order.reference_2} />
                  </>
                ) : (
                  I18n.t("general.na")
                )}
              </span>
            </span>
          </Col>
        </Row>
      )}
      {!isEmpty(order.hawb) && (
        <Row>
          <Col xs={24}>
            <span className="details_heading">
              {I18n.t("general.hawb")}:&nbsp;&nbsp;
            </span>
            <span>
              <span>{doFormate(order.hawb)}</span> &nbsp;
              <Copyable text={order.hawb} />
            </span>
          </Col>
        </Row>
      )}
      {!isEmpty(order.mawb) && (
        <Row>
          <Col xs={24}>
            <span className="details_heading">
              {I18n.t("general.mawb")}:&nbsp;&nbsp;
            </span>
            <span>
              <span>{doFormate(order.mawb)}</span>&nbsp;
              <Copyable text={order.mawb} />
            </span>
            &nbsp;&nbsp;
            {isConsolidatedPresent && (
              <>
                <Tooltip
                  title={`${completedOrders.length} order(s) completed out of ${totalOrders} orders`}
                >
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      backgroundColor: "#607AD6",
                      color: "white",
                    }}
                    size="small"
                    onClick={() => {
                      goToTab("subOrders");
                      handleMawbClick();
                    }}
                    loading={consolidateLoading}
                  >
                    {`${completedOrders.length} / ${totalOrders}`}
                  </Button>
                </Tooltip>
                &nbsp;&nbsp;
                <span
                  className="anchor_cursor  textBold"
                  onClick={() => {
                    setOpenModal(true), setActiveScreen("link");
                  }}
                >
                  {consolidateLoading ? (
                    <span style={{ display: "inline-block" }}>
                      <Skeleton
                        active
                        title={{ width: "60px" }}
                        paragraph={false}
                      />
                    </span>
                  ) : (
                    "Link"
                  )}
                </span>
                &nbsp;&nbsp;
                <span
                  className="anchor_cursor  textBold"
                  onClick={() => {
                    setOpenModal(true),
                      setIsDelink(true),
                      setActiveScreen("delink");
                  }}
                >
                  {consolidateLoading ? (
                    <span style={{ display: "inline-block" }}>
                      <Skeleton
                        active
                        title={{ width: "60px" }}
                        paragraph={false}
                      />
                    </span>
                  ) : (
                    "De-Link"
                  )}
                </span>
              </>
            )}
          </Col>
        </Row>
      )}
      {openModal && handleModalOpen()}
    </Col>
  );
};
export default OrderRefrences;
