/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _, { result } from "lodash";
import {
  Col,
  FormItem,
  Input,
  Row,
  Divider,
  Select,
  Button,
  Upload,
  Card,
  Checkbox,
} from "../../common/UIComponents";
import {
  alertMessage,
  doValidate,
  isEmpty,
  formatCode,
  customPasteSplit,
} from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import { saveAccount } from "../../api/Account";
import FormErrors from "../common/FormErrors";
import AddressForm from "../common/AddressForm";
import { loadGoogleMaps } from "../../utils/Utils";
import MobileInput from "../common/MobileInput";
import TagsInput from "react-tagsinput";
import imagePlaceholder from "../../../assets/images/logo_placeholder.png";
import { fetchLocations } from "../../api/LocationsApi";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance } from '../../helpers/common';
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import AddressSearch from "../../stories/components/AddressSearch";

const imgProps = { height: 100, width: 250 };
const requiredFields = [
  {
    form_field: "name",
    ui_name: I18n.t("general.name"),
    isRequired: true,
    inputType: "text",
  },
  {
    form_field: "code",
    ui_name: I18n.t("general.code"),
    isRequired: true,
    inputType: "code",
  },
  {
    form_field: "phone_number",
    ui_name: I18n.t("general.contact_no"),
    isRequired: false,
    inputType: "mobile",
  },
  {
    form_field: "email",
    ui_name: I18n.t("general.email"),
    isRequired: false,
    inputType: "email",
  },
  // {
  //   form_field: 'manager_email',
  //   ui_name: I18n.t('account.account_manager_email'),
  //   isRequired: false,
  //   inputType: 'email',
  // },
  {
    form_field: "website",
    ui_name: I18n.t("general.website"),
    isRequired: false,
    inputType: "url",
  },
  {
    form_field: "address",
    ui_name: I18n.t("general.address"),
    isRequired: false,
    inputType: "address",
  },
  {
    form_field: "contact_person",
    ui_name: I18n.t("account.contact_person"),
    isRequired: false,
    inputType: "text",
  },
  {
    form_field: "integration_code",
    ui_name: I18n.t("integrations.modal.integration_code"),
    isRequired: false,
    inputType: "code",
  },
];

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account ? this.props.account : {},
      accounts: this.props.accounts ? this.props.accounts : [],
      primaryAccountCodes: this.props.primaryAccountCodes
        ? this.props.primaryAccountCodes
        : [],
      isPrimaryCurrentAccount: this.props.isPrimaryCurrentAccount
        ? this.props.isPrimaryCurrentAccount
        : false,
      isNew: this.props.isNew,
      orgId: this.props.org_id,
      inProgress: false,
      errors: [],
      map: false,
      logo: "",
      wareHouses: [],
      same_address: false,
    };
  }

  componentDidMount() {
    this.initialData();
    loadGoogleMaps(() => {
      this.setState({
        map: true,
      });
    });
    // this.getWarehouses();
  }

  initialData = () => {
    const { account, isNew } = this.state;
    const defaultWh = this.props?.warehouseFilter?.selectedWarehouses;

    if (isNew) {
      const updatedAccount = { ...account, warehouse_ids: [ defaultWh ] };
      this.setState({
        ...this.state,
        account: updatedAccount,
        wareHouses: this.props.warehouseFilter.warehouses,
      });
    } else {
      this.setState({
        ...this.state,
        wareHouses: this.props.warehouseFilter.warehouses,
      });
    }
  };


  clearForm = () => {
    this.setState({
      account: {},
      isNew: true,
    });
  };

  getPrimaryAccount = (account_id) => {
    const { accounts } = this.state;
    return accounts.filter((account) => account.id === account_id);
  };

  handleOnChange = (element, value) => {
    const { wareHouses } = this.state;
    if (element === "manager_emails") {
      if (_.isString(value) && !isEmpty(value)) {
        value = [value];
      } else if (isEmpty(value)) {
        value = [];
      }
    }
    const account = Object.assign({}, this.state.account);
    account[element] = value;
    // if(element === 'warehouse_ids' && value.includes('ALL')){
    //   account[element] = ["ALL"]
    // }
    if(element === 'integration_code' && (isEmpty(account.warehouse_ids) || account.warehouse_ids.length === 0)){
       account.warehouse_ids = wareHouses.length > 0 ? [wareHouses[0].id] : [];
    }
    this.setState({
      account,
    });
  };

  handleCheckedChange = (element, value) => {
    const account = Object.assign({}, this.state.account);
    this.setState({[element]:value})
    if (element === "same_address" && value) {
      account.billing_address = { ...account.address };
      this.setState({
        account,
      })
    }
  };

  handleMobileChange = (element, value) => {
    this.setState({
      account: Object.assign({}, this.state.account, { [element]: value }),
    });
  };

  compareAddress = () =>{
    const account = Object.assign({}, this.state.account);
    this.setState({ same_address : _.isEqual(account.address,account.billing_address)});
  }

  handleOnAddressChange = (element, value, obj) => {
    const address = this.state.account.address || {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign(address, obj);
    } else if (element) {
      newAddress[element] = value;
    }
    this.setState({
      account: Object.assign({}, this.state.account, { address: newAddress }),
    },
    () => this.compareAddress()
    );
  };

  handleOnBillingAddressChange = (element, value, obj) => {
    const address = this.state.account.billing_address || {};
    let newAddress = Object.assign({}, address);
    if (obj) {
      newAddress = Object.assign(address, obj);
    } else if (element) {
      newAddress[element] = value;
    }
    this.setState({
      account: Object.assign({}, this.state.account, {
        billing_address: newAddress,
      }),
    },
    () => this.compareAddress()
    );
  };
  getBase64 = (img, callback) => {
    const data = Object.assign({}, this.state.account, { logo: img });
    this.setState({
      account: data,
    });
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleChange = (info) => {
    this.getBase64(info.file.originFileObj, (logo) =>
      this.setState({
        logo,
      })
    );
  };

  // handleWareHouseChange = (selectedWareHouses) => {
  //   const { wareHouses } = this.state;
  //   if (selectedWareHouses.includes("ALL") || selectedWareHouses.length === wareHouses.length) {
  //       selectedWareHouses = ["ALL"];
  //   }
  //   this.handleOnChange("warehouse_ids", selectedWareHouses);
  // };

  handleSave = () => {
    let data = Object.assign({}, this.state.account);
    let currentWareHouse = null;
    if (!data.account_id) {
      data.account_id = this.state.orgId;
    }
    if (isEmpty(data.manager_emails)) {
      data.manager_emails = [];
    }
    if (isEmpty(data.warehouse_ids)) {
      data.warehouse_ids = [];
    } else if(data.warehouse_ids.includes('ALL')){
      data.warehouse_ids = this.state.wareHouses.map(wh => wh.id);
    }
    data.warehouse_ids = data.warehouse_ids ? _.flattenDeep(data.warehouse_ids) : []
    if (isEmpty(data.primary_account_id)) {
      delete data.primary_account_id;
    }

    if(typeof(this.props.warehouseFilter.selectedWarehouses) === 'string'){
      currentWareHouse = this.props.warehouseFilter.selectedWarehouses;
    } else {
      currentWareHouse = this.props.warehouseFilter.selectedWarehouses[0];
    }

    const errors = doValidate(requiredFields, data);
    // if(!isEmpty(data.integration_code) && data.warehouse_ids.length === 0){
    if(isEmpty(data.warehouse_ids) || data.warehouse_ids.length === 0){
      errors.push(I18n.t('messages.cannot_be_blank', {label : I18n.t('general.warehouse')}));
    }
    if(isEmpty(data.integration_code) && data.warehouse_ids.length > 0){
      errors.push(I18n.t('messages.cannot_be_blank', {
        label : I18n.t("integrations.modal.integration_code")
      }));
    }
    if (errors.length === 0) {
      data.code = formatCode(data.code);
      this.setState({ inProgress: true });
      saveAccount(this.state.isNew, data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          if (this.state.isNew) {
            this.setState({
              errors: [],
              isNew: true,
              inProgress: false,
              account: {},
              showForm:false
            });
            this.clearForm();
            this.props.closeFormOnSubmit()
          } else {
            this.setState({
              errors: [],
              inProgress: false,
            });
          }
          const updatedAccountWhs = this.props.warehouseFilter.warehouseWithAccounts.map((wh) => {
            let accountExists = false;
            const whAccounts = wh.accounts.map((account) => {
              if (account.id === result.account.id) {
                accountExists = true;
                return {
                  ...account,
                  warehouse_ids: result.account.warehouse_ids,
                };
              }
              return account;
            });
          
            // Check if this warehouse should have the account
            if (result.account.warehouse_ids.includes(wh.warehouseId)) {
              if (!accountExists) {
                const newAccount = {
                  id: result.account.id,
                  name: result.account.name,
                  code: result.account.code,
                  warehouse_ids: result.account.warehouse_ids,
                };
                whAccounts.push(newAccount);
              }
            } else {
              // Remove the account if it's no longer associated with this warehouse
              const index = whAccounts.findIndex(account => account.id === result.account.id);
              if (index !== -1) {
                whAccounts.splice(index, 1);
              }
            }
          
            whAccounts.sort((a, b) => a.name.localeCompare(b.name));
          
            return {
              ...wh,
              accounts: whAccounts,
            };
          });
          
          this.props.warehouseFilter.setWarehouseWithAccounts(updatedAccountWhs);
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  handleClearPrimaryAccounts = () => {
    this.setState({
      account: Object.assign({}, this.state.account, {
        primary_account_id: null,
      }),
    });
  };

  render() {
    const { account, wareHouses } = this.state;
    const logo = this.state.logo || account.logo;
    return (
      <div>
        <Row gutter={16}>
          <Col md={8}>
            <FormItem label={I18n.t("general.name")} require>
              <Input
                value={account.name}
                onChange={(e) => this.handleOnChange("name", e.target.value)}
              />
            </FormItem>
          </Col>
          <Col md={8}>
            <FormItem
              label={
                <Fragment>
                  <span>{I18n.t("general.code")}</span>&nbsp;
                  <span className="hintText"> (CSV / XML mapping code)</span>
                </Fragment>
              }
              require
            >
              <Input
                value={account.code}
                onChange={(e) => this.handleOnChange("code", e.target.value)}
                className="textUpperCase"
                disabled={!this.state.isNew}
              />
            </FormItem>
          </Col>
          <Col md={8}>
            <FormItem label={I18n.t("account.contact_person")}>
              <Input
                value={account.contact_person}
                onChange={(e) =>
                  this.handleOnChange("contact_person", e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={16}>
            <Row gutter={16}>
              <Col md={12}>
                <FormItem label={I18n.t("general.email")}>
                  <Input
                    value={account.email}
                    onChange={(e) =>
                      this.handleOnChange("email", e.target.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col md={12}>
                <FormItem label={I18n.t("general.contact_no")}>
                  <MobileInput
                    handleInputChange={(value, data) => {
                      this.handleMobileChange("phone_number", value, data);
                    }}
                    value={account.phone_number || ""}
                  />
                </FormItem>
              </Col>
              { 
                // checkServiceExistance('PA') && !this.state.isPrimaryCurrentAccount &&
                // <Col md={24}>
                //   <div
                //       style={{
                //         display: "flex",
                //         alignItems: "flex-end",
                //         justifyContent: "space-between",
                //       }}
                //     >
                //       <FormItem
                //         label={I18n.t("account.primary_account")}
                //         requireColumn={false}
                //         style={{ width: "100%" }}
                //       >
                //         <Select
                //           value={this.state.account.primary_account_id || ""}
                //           showSearch
                //           style={{ width: "100%" }}
                //           filterOption={(input, option) =>
                //             option.props.children
                //               .toLowerCase()
                //               .indexOf(input.toLowerCase()) >= 0
                //           }
                //           onChange={(e) =>
                //             this.handleOnChange("primary_account_id", e)
                //           }
                //         >
                //           {this.state.primaryAccountCodes.map((record) =>
                //             record.id !== this.state.account.id ? (
                //               <Select.Option key={record.id} value={record.id}>
                //                 {`${record.name} (${record.code})`}
                //               </Select.Option>
                //             ) : null
                //           )}
                //         </Select>
                //       </FormItem>
                //       {this.state.account.primary_account_id ? (
                //         <Button
                //           style={{ marginLeft: "2px" }}
                //           onClick={() => this.handleClearPrimaryAccounts()}
                //         >
                //           Clear
                //         </Button>
                //       ) : null}
                //   </div>
                // </Col>
              }
            </Row>
          </Col>
          <Col md={8} className="alignCenter">
            <FormItem label={I18n.t("general.logo")}>
              <Upload showUploadList={false} onChange={this.handleChange}>
                {this.state.account.logo && !this.state.logo && (
                  <div className="borderRadius">
                    <img
                      src={logo.url || imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {this.state.logo && (
                  <div className="borderRadius">
                    <img
                      src={logo || imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
                {!this.state.logo && !this.state.account.logo && (
                  <div className="borderRadius">
                    <img
                      src={imagePlaceholder}
                      style={{ ...imgProps }}
                      alt="person"
                      className="bearded-young-man"
                    />
                  </div>
                )}
              </Upload>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8}>
            <FormItem
              label={I18n.t("integrations.modal.integration_code")}
              require
            >
              <Input
                value={account.integration_code}
                onChange={(e) =>
                  this.handleOnChange("integration_code", e.target.value)
                }
                style={{ width: "100%" }}
                className="textUpperCase"
              />
            </FormItem>
          </Col>
          <Col md={8}>
            <FormItem label={I18n.t("general.warehouse")} require>
              <Select
                // value={ account.warehouse_ids || [] }
                value={_.flattenDeep(account.warehouse_ids) || []}
                showSearch
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase)
                }
                onChange={ (values) => {
                  let selectedValues = values;
                  if (values[values.length -1] === "ALL") {
                    if (values.length > 0) {
                      selectedValues = ["ALL"];
                    }
                  } else {
                    if (values.includes("ALL")) {
                      selectedValues = values.filter(value => value !== "ALL");
                    } else {
                      selectedValues = values;
                    }
                  }
                  let newAccount = { ...account, warehouse_ids: selectedValues };
                  this.setState({ account: newAccount });
                } }
                // onChange={(e) => this.handleOnChange("warehouse_ids", e)}
                mode="multiple"
                placeholder={"--- Select ---"}
              >
                {wareHouses.length > 0 && (
                  <Select.Option key="ALL" value="ALL">
                    {I18n.t("general.all")}
                  </Select.Option>
                )}
                {wareHouses.map((wareHouse) => (
                  <Select.Option key={wareHouse.id} value={wareHouse.id}>
                    {wareHouse.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col md={8}>
            <FormItem label={I18n.t("general.fax_no")}>
              <Input
                value={account.fax_number}
                onChange={(e) =>
                  this.handleOnChange("fax_number", e.target.value)
                }
                style={{ width: "100%" }}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={24} lg={24} className="tagsInput">
            <FormItem label={I18n.t("account.account_manager_emails")}>
              {/*
                <Input
                value={account.manager_email}
                onChange={e => this.handleOnChange('manager_email', e.target.value)}
              />
            */}
              <TagsInput
                value={account.manager_emails || []}
                onChange={(e) => this.handleOnChange("manager_emails", e)}
                validationRegex={
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }
                addKeys={[9, 13, 32]}
                inputProps={{
                  placeholder: "Add an email",
                }}
                onlyUnique
                addOnPaste
                pasteSplit={customPasteSplit}
                addOnBlur={ true }
              />
            </FormItem>
          </Col>
        </Row>
        {this.state.map &&
        (this.state.isNew || Object.keys(this.state.account).length) ? (
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <Card
                className="addressCard"
                title="Address"
                size="small"
                style={{ height: "282px" }}
                bodyStyle={{ marginTop: "20px" }}
              >
                <AddressSearch onSelect={ (address) => this.handleOnAddressChange("", "", address.l_address)} />
                <AddressForm
                  autoComplete
                  address={account.address}
                  onChange={this.handleOnAddressChange}
                  isAdmin
                  twoRows
                />
              </Card>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <Card
                className="addressCard"
                title="Billing Address"
                size="small"
              >
                <Checkbox
                  checked={this.state.same_address}
                  onChange={(e) =>
                    this.handleCheckedChange("same_address", e.target.checked)
                  }
                  className="marginBottom10"
                >
                  Same as Correspondence Address
                </Checkbox>
                <AddressSearch onSelect={ (address) => this.handleOnBillingAddressChange("", "", address.l_address)} />
                <AddressForm
                  autoComplete
                  address={account.billing_address}
                  onChange={this.handleOnBillingAddressChange}
                  isAdmin
                  twoRows
                />
              </Card>
            </Col>
          </Row>
        ) : (
          <Fragment />
        )}
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

Form.propTypes = {
  account: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const AccountForm = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const organizationSettings = useContext(OrgContext);
  return (
    <Form
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default AccountForm;