import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import RouteOrders from './RouteOrders';
import { setRouteOrderSequence } from '../../../helpers/preplan';

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver, connectDragSource, connectDropTarget, moveRow,...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />));
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(DragSource('row', rowSource, connect => ({
  connectDragSource: connect.dragSource(),
}))(BodyRow));

class SortingRouteOrders extends React.Component {

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const {currentRoute,setCurrentRoute,currentStop,handleCurrentStop} = this.props;
    setRouteOrderSequence(dragIndex, hoverIndex , currentRoute,setCurrentRoute,currentStop,handleCurrentStop );
  };

  combinedKey = (record) => `${record.customer_order_id}-${record.stop_id}`;

  render() {
    return (
        <RouteOrders
          data={this.props.data}
          //rowKey="customer_order_id"
          rowKey={this.combinedKey}
          bodyRow={DragableBodyRow}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow,
          })}
          size="small"
          route={this.props.route}
          showMoveAction={this.props.showMoveAction}
          showActions={this.props.showActions}
          stopType="CS"
          orderDetailsViewClick={this.props.orderDetailsViewClick}
          pagination={this.props.pagination}
          currentRoute={this.props.currentRoute}
          scroll={this.props.scrollObj}
          selectedInfo={this.props.selectedInfo}
          displayConfiguration={this.props.displayConfiguration}
          screen_from={this.props.screen_from}
          vhType={this.props.vhType}
          isMilitaryTime={this.props.isMilitaryTime}
        />
    );
  }
}


export default SortingRouteOrders;
