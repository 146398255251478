import React, { Fragment } from "react";
import I18n from "../../../common/I18n";
import BaseList from "../../BaseList";
import CopyableContent from "../../common/CopyableContent";
import { checkNegitive } from "../../../common/Common";
import moment from "moment";
import AppConfig from "../../../config/AppConfig";
import { Input , Col, Icon, Popconfirm, Row, Tooltip} from "../../../common/UIComponents";
import DeleteIcon from "../../common/DeleteIcon";
import OrderBillingDetails from "../../billing/OrderBillingDetails";

class DriversWeeklyReportsList extends BaseList {
  constructor(props) {+
    super(props);
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.columns = [
      // {
      //   key: "driver",
      //   title: <b>{I18n.t("drivers_weekly_report.driver")}</b>,
      //   dataIndex: "driver",
      //   render: (data, record) => <span>{data}</span>,
      //   width: 100,
      // },

      {
        key: "service_date",
        title: <b>{I18n.t("drivers_weekly_report.service_date")}</b>,
        dataIndex: "date",
        render: (data, record) => (
          <span>{record.orderIndex === 0 && data ? moment.utc(data).format(AppConfig.dateFormat) : ""}</span>
        ),
        width: 90,
      },
      {
        key: "Account",
        title: <b>{I18n.t("drivers_weekly_report.account")}</b>,
        dataIndex: "account_code",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "customer_order_number",
        title: <b>{I18n.t("drivers_weekly_report.job_number")}</b>,
        dataIndex: "customer_order_number",
        render: (data, record) => 
        <Fragment>
           
            {
              <Row type={"flex"}>
                <Col>
                 {record?.is_manual && record.is_manual === true && (
                    <Tooltip title="Manual">
                      <sup className="textRed textBold fontSize10">
                        &nbsp;&nbsp;(M)
                      </sup>
                    </Tooltip>
                  )}
                 {
                  record.customer_order_id ?  <OrderBillingDetails
                  record={{...record}}
                  showActions = {false}
                 />
                 :
                 <CopyableContent text={data} />
                 }
                </Col>
                <Col>
                </Col>
              </Row>
            }
        </Fragment>,
        width: 100,
      },
      {
        key: "hawb",
        title: <b>{I18n.t("general.hawb")}</b>,
        dataIndex: "hawb",
        render: (data, record) => 
        <Fragment>
          { data ? <CopyableContent text={data} /> : ''}
        </Fragment>,
        width: 100,
      },
      {
        key: "consignee_name",
        title: <b>{I18n.t("drivers_weekly_report.customer_name")}</b>,
        dataIndex: "consignee_name",
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        width: 130,
        className: "noWrap",
      },
      {
        key: "gross_revenue",
        title: <b>{I18n.t("drivers_weekly_report.gross($)")}</b>,
        dataIndex: "gross_revenue",
        //render: (data, record) => (data ? checkNegitive(data) : ""),
        render: (data, record, index) => (
          props.isApproved ?
          <div>
          {checkNegitive(data)}
          </div>
          :
          <Input
            onChange={(e) =>
              props.handleOnChange(record.dateIndex, record.orderIndex, "gross_revenue", e.target.value)
            }
            value={data}
            style={{ width: 100, fontSize: 12 }}
            size="small"
            type="number"
            prefix={<Icon type="dollar" />}
            min={0}
            onBlur={(e) =>
              props.handleOnChange(
                record.dateIndex, 
                record.orderIndex,
                "gross_revenue",
                record.gross_revenue ? parseFloat(record.gross_revenue).toFixed(2) : 0.00
              )
            }
          />

        ),
        width: 110,
      },
      {
        key: "gross_pay",
        title: <b>{I18n.t("drivers_weekly_report.net($)")}</b>,
        dataIndex: "gross_pay",
        render: (data, record) =>  
        props.isApproved ?
        <div>
       {checkNegitive(data)}
        </div>
        :
        <Input
        onChange={(e) =>
          props.handleOnChange(record.dateIndex, record.orderIndex, "gross_pay", e.target.value)
        }
        value={data}
        style={{ width: 100, fontSize: 12 }}
        size="small"
        type="number"
        prefix={<Icon type="dollar" />}
        min={0}
        onBlur={(e) =>
          props.handleOnChange(
            record.dateIndex, 
            record.orderIndex,
            "gross_pay",
            record.gross_pay ? parseFloat(record.gross_pay).toFixed(2) : 0.00
          )
        }
      />,
        width: 110,
      },
      {
        key: 'action',
        title: '',
        dataIndex: 'id',
        render: (data, record, index) => (
          // props.isApproved ? 
          // null
          // :
          <Row type="flex" gutter={8} align="middle" justify="end"> {/*record.is_manual === true &&*/}
            { !this.props.hasNew && <Col>
              <Tooltip placement="topLeft" title="Delete">
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm')}
                  onConfirm={() =>
                    this.props.deleteClick(record.driver_pay_report_id, record.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon styleObj={{ fontSize: 16 }}/>
                  &nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
            </Col>
            }
            {!this.props.hasNew && index + 1 === this.props.data.length && (
              <Col className="line-actions fontsize11">
                <Tooltip placement="topLeft" title={I18n.t('order.add')}>
                  <Icon
                    type="plus-circle"
                    theme="filled"
                    onClick={()=>this.props.handleAddRow(record)}
                    style={{ fontSize: 18 }}
          
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        ),
        width: 68,
        align: 'left',
       // fixed: 'right'
      },
    ];
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}
export default  React.memo(DriversWeeklyReportsList);
