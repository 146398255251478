import React from "react";
import BaseList from "../../BaseList";
import {
  Popconfirm,
  Icon,
  Row,
  Col,
  Divider,
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";

class EventMsgData extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      record: this.props.record,
      data: this.props.data,
    };
  }
  render() {
    const { record } = this.state;
    const data = this.props?.data ? this.props.data : [];
    const { rowEventId } = this.props;
    return (
      <div style={{maxWidth: "99%"}}>
        <Row>
          <Col>
            {data && data.length ? (
              data.map((eventMsgRow, index) => {
                return (
                  <Row
                    className="marginTop10"
                    key={`eventMsgRow${rowEventId}${index}`}
                    gutter={32}
                  >
                    <Col>
                      <Row>
                        <span className="textBold">
                          {eventMsgRow.notification_to}&nbsp;
                          {eventMsgRow._mode_of_communication}
                        </span>
                        <div className="fontSize12">
                          <pre style={{ width: "100%" }} className="no-pre-scroll">
                            {eventMsgRow.message_content}
                          </pre>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div className="textBold">
                No Notification Template(s) are configured.
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default EventMsgData;
