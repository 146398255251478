/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Button,
  Popconfirm,
  Spin,
  Select,
} from '../../common/UIComponents'
import { alertMessage, isEmpty } from '../../common/Common'
import I18n from '../../common/I18n'
import BaseModal from '../BaseModal'
import userStore from '../../stores/UserStore'
import List from '../driverRates/List'
import Form from '../driverRates/Form'
import AccountDRForm from '../driverRates/AccountDRForm'
import {
  applyOrgDriverRate,
  deleteOrgDriverRate,
  deleteAccountDriverRate,
  fetchAccountDriverRates,
  fetchOrgDriverRates,
  setRateDefault,
  setRateDefaultForAccounts,
  deleteAllAccountDriverRates,
  applyDRToAll,
} from '../../api/DriverRates'
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import DRFormByCategory from '../driverRates/DRFormByCategory'
import AppConfig from '../../config/AppConfig'
import { fetchAccountBillingConfigs } from '../../api/Account'

class DriverRateConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inProgress: false,
      errors: [],
      // currentRateRecord: {},
      currentRateRecord: this.props.rateRecord || {},
      driverRates: [],
      account: this.props.account || {},
      ratesFor: this.props.ratesFor ? this.props.ratesFor : '',
      isNew: false,
      pagination: {},
      organization_id: userStore.getStateValue('selectedOrg'),
      hasAccountValues: [],
      defaultRate: '',
      config: this.props.billingConfigs || {},
    }
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
    };
  }

  componentDidMount() {
    this.fetchDriverRates()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.billingConfigs !== this.props.billingConfigs) {
      this.setState({ config: this.props.billingConfigs })
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { account} = state
    return { account }
  }

  closeOrgWindow = () => {
    this.setState(
      {
        showForm: false,
        currentRateRecord: {},
      },
      () => {
        this.fetchDriverRates()
      },
    )
  }

  getAccountDriverRates = (cb) => {
    this.setState({ inProgress: true })
    const page = this.tableOptions.pagination.current;
    const perPage = AppConfig.perPage;
    fetchAccountDriverRates(
      this.state.account.organization_id,
      this.state.account.id,
      page,
      perPage
    ).then((result) => {
      if (result.success) {
        const driverRates = result.driver_rates || []
        const changedDefaultOne =
          driverRates.length === 1 &&
          !isEmpty(driverRates[0].organization_driver_rate_id)
        const hasAccountValues = !(
          driverRates.length === 0 || changedDefaultOne
        )
        this.setState({ inProgress: false })
        if (!hasAccountValues) {
          this.setState(
            {
              hasAccountValues: false,
              inProgress:false,
              defaultRate: changedDefaultOne
                ? driverRates[0].organization_driver_rate_id
                : '',
            },
            () => {
             this.getOrgDriverRates()
            },
          )
        } else {
          this.setState(
            {
              hasAccountValues: true,
              driverRates,
              pagination: driverRates.length > 0 ? result.pagination || {} : {},
            },
            
            cb,
          )
        }
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false })
      }
    })
  }

  getOrgDriverRates = (cb) => {
    this.setState({ inProgress: true })
    const orgId = userStore.getStateValue('selectedOrg')
    const page = this.tableOptions.pagination.current;
    const perPage = AppConfig.perPage;
    fetchOrgDriverRates(orgId, page, perPage).then((result) => {
      if (result.success) {
        const driverRates = result.driver_rates || []
        let defaultRate = ''
        if (isEmpty(this.state.defaultRate)) {
          const defaultRateObj = _.find(driverRates, { is_default: 'YES' })
          if (!isEmpty(defaultRateObj)) {
            defaultRate = defaultRateObj.id
            this.setState({ inProgress: false })
          }
        } else {
          defaultRate = this.state.defaultRate
        }

        this.setState(
          {
            driverRates,
            pagination: driverRates.length > 0 ? result.pagination || {} : {},
            defaultRate,
            inProgress:false,
          },
          cb,
        )
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false })
      }
    })
  }

  fetchDriverRates = (cb) => {
    if (this.state.ratesFor === 'org') {
      this.getOrgDriverRates(cb)
    } else {
      this.getAccountDriverRates(cb)
    }
  }

  handleDeleteClick = (id) => {
    if (this.state.ratesFor === 'org') {
      this.handleDeleteOrgDriverRate(id)
    } else {
      this.handleAccountOrgDriverRate(id)
    }
  }

  handleAddClick = () => {
    this.setState({
      isNew: true,
      currentRateRecord: {},
      showForm: true,
    })
  }

  handleEditClick = (id) => {
    const currentRateRecord = _.find(this.state.driverRates, { id })
    this.setState({
      showForm: true,
      isNew: false,
      currentRateRecord,
    })
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    })
  }

  handleApplyButton = () => {
    const account = { ...this.state.account }
    if (this.state.ratesFor === 'org') {
      account.org_delivery_zip = false
    } else {
      this.handleApplyOrgValue()
    }
  }

  handleRateStatus = (id) => {
    const { driverRates } = this.state
    this.setState({ inProgress: true })
    if (this.state.ratesFor === 'org') {
      setRateDefault(id).then((result) => {
        if (result.success) {
          const index = _.findIndex(driverRates, { is_default: 'YES' })
          if (index >= 0) {
            driverRates[index].is_default = 'NO'
          }
          const currentIndex = _.findIndex(driverRates, { id })
          if (currentIndex >= 0) {
            driverRates[currentIndex].is_default = 'YES'
          }
          this.setState({ driverRates, inProgress: false })
          alertMessage('Changed Successfully', 'success', 10)
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    } else {
      setRateDefaultForAccounts(id).then((result) => {
        if (result.success) {
          const index = _.findIndex(driverRates, { is_default: 'YES' })
          if (index >= 0) {
            driverRates[index].is_default = 'NO'
          }
          const currentIndex = _.findIndex(driverRates, { id })
          if (currentIndex >= 0) {
            driverRates[currentIndex].is_default = 'YES'
          }
          this.setState({ driverRates, inProgress: false })
          alertMessage('Changed Successfully', 'success', 10)
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleApplyOrgValue = () => {
    const { defaultRate } = this.state
    this.setState({ inProgress: true })
    const errors = []
    if (isEmpty(this.state.defaultRate)) {
      errors.push(
        I18n.t('errors.field_required', {
          field: I18n.t('driver_pay.driver_rate'),
        }),
      )
    }
    if (errors.length === 0) {
      const data = {
        account_id: this.state.account ? this.state.account.id : '',
        organization_driver_rate_id: defaultRate,
      }
      applyOrgDriverRate(data).then((result) => {
        if (result.success) {
          this.setState({ inProgress: false, errors: [] })
          alertMessage(I18n.t('messages.saved'))
          this.fetchDriverRates()
        } else {
          this.setState({ errors: [result.errors], inProgress: false })
        }
      })
    } else {
      this.setState({ errors, inProgress: false })
    }
  }

  handleDeleteOrgDriverRate = (id) => {
    this.setState({ inProgress: true })
    deleteOrgDriverRate(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.setState({ inProgress: false })
        if (this.state.driverRates.length === 1) {
          if (
            this.tableOptions &&
            this.tableOptions.pagination &&
            this.tableOptions.pagination.current > 1
          ) {
            this.tableOptions.pagination.current =
              this.tableOptions.pagination.current - 1
            this.fetchDriverRates()
          } else {
            this.fetchDriverRates()
          }
        } else {
          this.fetchDriverRates()
        }
        this.setState({
          inProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleAccountOrgDriverRate = (id) => {
    this.setState({ inProgress: true })
    deleteAccountDriverRate(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.setState({ inProgress: false })
        if (this.state.driverRates.length === 1) {
          if (
            this.tableOptions &&
            this.tableOptions.pagination &&
            this.tableOptions.pagination.current > 1
          ) {
            this.tableOptions.pagination.current =
              this.tableOptions.pagination.current - 1
            this.fetchDriverRates()
          } else {
            this.fetchDriverRates()
          }
        } else {
          this.fetchDriverRates()
        }
        this.setState({
          inProgress: false,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleAllAccountDriverRates = (type) => {
    this.setState({ inProgress: true })
    deleteAllAccountDriverRates(this.state.account.id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.setState(
          {
            inProgress: false,
            currentRateRecord: {},
          },
          () => {
            if (type === 'org') {
              this.handleAddClick()
            } else {
              this.fetchDriverRates()
            }
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  _renderFormWindow = () => {
    const currentRateRecord = this.state.currentRateRecord || {}
    return (
      <BaseModal
        title={`${
          !this.state.isNew ? I18n.t('general.edit') : I18n.t('general.add')
        } ${I18n.t('driver_pay.driver_rate')}`}
        onCancel={this.closeOrgWindow}
        width="70%"
        style={{
          top: 0,
          height: "100%",
        }}
        bodyStyle={{
          height: "calc(100vh - 50px)",
          overflowY: "auto",
        }}
        maskClosable={false}
      >
        {this.state.ratesFor === 'org' ? (
          <Form
            rateRecord={currentRateRecord}
            onSuccess={this.closeOrgWindow}
            onCancel={this.closeOrgWindow}
            isNew={this.state.isNew}
            organization_id={this.state.organization_id}
          />
        ) : (

          // <DRFormByCategory
          <AccountDRForm
            rateRecord={currentRateRecord}
            onSuccess={this.closeOrgWindow}
            onCancel={this.closeOrgWindow}
            isNew={this.state.isNew}
            organization_id={this.state.organization_id}
            account={this.state.account}
            configs={this.state.config}
          />
        )}
      </BaseModal>
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
    };
    if (pagination.current !== currentPage) {
      this.fetchDriverRates();
    }
  };

  renderList = () => {
    const { pagination } = this.state
    return (
      <List
        data={this.state.driverRates}
        scroll = {{y:"calc(100vh - 350px)"}}
        editClick={(id) => this.handleEditClick(id)}
        applyToAll={(id) => this.handleApplyToAll(id)}
        deleteClick={(id) => this.handleDeleteClick(id)}
        setStatus={(id) => this.handleRateStatus(id)}
        pagination={{
          total: pagination.total_count,
          current: pagination.current_page,
          pageSize: pagination.per_page,
        }}
        tableChange={(paginationInfo, filters, sorter) =>
          this.handleTableChange(paginationInfo, filters, sorter)
        }
        ratesFor={this.state.ratesFor}
        size="small"
      />
    )
  }

  handleApplyToAll = (id) => {
    this.setState({ inProgress: true })
    const data = {
    organization_id: this.state.organization_id,
    account_id: this.state.account.id,
    dr_type: "ACCOUNT",
    dr_id: id
    }
    applyDRToAll(data).then((result) => {
      if (result.success) {
        alertMessage(result.message ? result.message : I18n.t('messages.done_successfully', { field : 'Applied'}))
        this.setState(
          {
            inProgress: false,
          }
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleOverrite = () => {
    this.setState(
      {
        driverRates: [],
        hasAccountValues: false,
      },
      () => {
        this.getOrgDriverRates()
      },
    )
  }

  render() {
    const listOpCode = this.state.ratesFor === 'account' ? 'ADRI' : 'DRI';
    const createOpAccess = this.state.ratesFor === 'account' ? checkServiceExistance(['ADRC', 'OADRL'], 'ALL') : checkServiceExistance('DRC');
    
    return (
      <div className="padding10">
        { this.state.ratesFor === 'account' && (
          <Row>
            {checkServiceExistance('UAODR') && !this.state.hasAccountValues && (
              <Col md={24} className="fontSize14 alignCenter marginBottom20">
                <h2>
                  <span className="textBold">
                    <Fragment>
                      {I18n.t('organization.single')}{' '}
                      {I18n.t('menu.driver_rates')}
                    </Fragment>
                  </span>
                </h2>
                &nbsp;&nbsp;
                <Fragment>
                  <Select
                    value={this.state.defaultRate}
                    showSearch
                    style={{ width: 200, margin: '10px 10px 0px 10px' }}
                    // filterOption={(input, option) =>
                    //   option.props.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    filterOption={(input, option) =>
                      {
                        const children = option.props.children;
                        if (_.isArray(children)) {
                          if(typeof children[0] === "string"){
                            return children[0]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0;
                          }
                          else{
                            return children[0]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0;
                          }
                        } else {
                          return children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0;
                        }
                      }
                    }
                    onChange={(e) => this.handleOnChange('defaultRate', e)}
                  >
                    <Select.Option key={'SEL_ALL'} value={''}>
                      {' '}
                      -- Select --
                    </Select.Option>
                    {this.state.driverRates.map((rate) => (
                      <Select.Option key={rate.id} value={rate.id}>
                        {rate.name}
                      </Select.Option>
                    ))}
                  </Select>
                  &nbsp;
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.handleApplyButton()}
                    icon='save'
                  >
                    Save
                  </Button>
                </Fragment>
              </Col>
            )}
            { checkServiceExistance('ADRDA') && 
            <Col md={24} className="fontSize14 alignCenter textBold">
              {!this.state.hasAccountValues ? (
                <div style={{ marginTop: 120, fontSize: 20 }}>
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t('configurations.overwrite_msg', {
                      field: 'Driver Rates',
                    })}
                    onConfirm={() => this.handleAllAccountDriverRates('org')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" size="small" style={{ fontSize: 20 }}>
                      <u>{I18n.t('general.click_here')}</u>
                    </Button>
                  </Popconfirm> {I18n.t('driver_rates.switch_to_accounts')}
                </div>
              ) : (
                <div className="alignCenter fontSize16">
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t('configurations.overwrite_msg', {
                      field: 'Driver Rates',
                    })}
                    onConfirm={() =>
                      this.handleAllAccountDriverRates('account')
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link" size="small" className="fontSize16">
                      <u>{I18n.t('general.click_here')}</u>
                    </Button> {I18n.t('driver_rates.switch_to_org')}
                  </Popconfirm>
                </div>
              )}
            </Col>
        }
          </Row>
        )}
        {(this.state.ratesFor === 'org' ||
          (this.state.ratesFor === 'account' && this.state.hasAccountValues)) && (
          <Fragment>
            {/* this.state.hasAccountValues)) && */}
            <Spin spinning={this.state.inProgress} delay={1000}>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '70%' }}>
                  { createOpAccess && <Row>
                    <Col xs={24} className="alignRight">
                      <Button
                        type="primary"
                        size="small"
                        onClick={this.handleAddClick}
                        icon="plus"
                      >
                        {`${I18n.t('general.add')} ${I18n.t(
                          'driver_pay.driver_rate',
                        )}`}
                      </Button>
                    </Col>
                  </Row>
                  }
                  { checkServiceExistance(listOpCode) && 
                  <div className="marginTop10">{this.renderList()}</div>
                  }
                </div>
              </Row>
            </Spin>
          </Fragment>
        )}
        {this.state.showForm && <this._renderFormWindow />}
      </div>
    )
  }
}

DriverRateConfig.propTypes = {
  account: PropTypes.shape(),
  org_id: PropTypes.string.isRequired,
}

DriverRateConfig.defaultPropTypes = {
  account: {},
  org_id: '',
}

export default DriverRateConfig
