import { Col, Row } from "antd";
import React from "react";

import BaseList from "../BaseList";
import SearchFiles from "../../containers/SearchFiles";

import AppConfig from "../../config/AppConfig";
import {
  checkServiceExistance,
  formatByTimeConfig,
} from "../../helpers/common";

import { momentTime } from "../../helpers/date_functions";


class RouteDriversList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [];
    if (props.data) {
      this.setColumns(props);
    }

    this.state = {
      page: props.pagination.current ? props.pagination.current : 1,
      pageSize: props.pagination.pageSize
        ? props.pagination.pageSize
        : AppConfig.perPage,
    };
  };

  setColumns = (props) => {
    this.columns = [
      // {
      //   title:"S.No",
      //   key: "index",
      //   // render: (Name, record, index) => index + 1,
      //   render: (text, record, index) => ((this.state.page - 1) * (this.state.pageSize)) + (index + 1),
      //   align:"left",
      // },

      {
        key: "1",
        title: "Name",
        dataIndex: "r_name",
        align: "left",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "2",
        title: "Start Date",
        dataIndex: "r_scheduled_start_datetime",
        align: "left",
        render: (data) =>
          data
            ? `${formatByTimeConfig(
                momentTime("", data, this.props.timeZone),
                this.props.isMilitaryTime,
                "MMM Do, YYYY HH:mm",
                "MMM Do, YYYY hh:mm A"
              )} ${
                this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
              }`
            : "",
        // sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      },
      {
        key: "3",
        title: "Status",
        dataIndex: "r_status",
        align: "left",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
      },

      {
        key: "4",
        title: "Route Type",
        dataIndex: "route_type",
        align: "left",
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        render: (data) => {
          switch (data) {
            case "nav_route":
              return "Regular";
            case "recovery_route":
              return "Recovery";
            case "line_haul":
              return "Linehaul";
            default:
              return data;
          }
        },
      },
    ];
  };
}

export default RouteDriversList;
