import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Icon,
  Spin,
} from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import BaseModal from '../BaseModal';
import Tracking from '../../containers/Tracking';
import { fetchNavRoueDetails } from '../../api/Routes';
import { setRouteObject } from '../../helpers/preplan';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import BranchesIcon from '../common/BranchesIcon';

class TrackingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceId: this.props.id ? this.props.id : '',
      route: { stops: [] },
    };
    this.myViewer = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.id !== this.state.sourceId) {
      this.setState({
        sourceId: props.id,
      }, () => {
        this.getRouteStopsInfo();
      });
    }
  }

  getRouteStopsInfo = () => {
    if (checkServiceExistance('NROFT') && this.state.sourceId) {
      this.setState({
        inProgress: true, route: { stops: []},
      }, () => {
        fetchNavRoueDetails(this.state.sourceId)
          .then((result) => {
            if (result.success) {
              const navRoute = result.nav_route ? result.nav_route : {};
              const route = setRouteObject(navRoute, 0, []);
              this.setState({
                route,
                inProgress: false,
              });
            } else {
              this.setState({
                inProgress: false,
              });
              renderAlertMessage(result.errors)
            }
          });
      });
    }
  }

  openTrackScreen = () => {
    this.getRouteStopsInfo();
    this.setState({
      showTrack: true,
    });
  }

  closeTrackScreen = () => {
    this.setState({
      showTrack: false,
    });
  }

  renderTrackingModal() {
    return (
      <BaseModal
        title={`${I18n.t('general.route')} ${this.state.route && this.state.route.displayName ? `: ${this.state.route.displayName}` : ''}`}
        onCancel={() => this.closeTrackScreen()}
        width='100%'
        style={{ top: 5, height: '100vh' }}
        bodyStyle={{ padding: 5, height: 'calc(100vh-50px)' }}
        autoFocus
      >
        <Spin spinning={this.state.inProgress} delay={1000}>
          <Tracking guid={this.state.sourceId} route={this.state.route} />
        </Spin>
      </BaseModal>
    );
  }

  render() {
    return (
      <Fragment>
        <BranchesIcon handleClick={this.openTrackScreen}/>
        { this.state.showTrack &&
        this.renderTrackingModal()
        }
      </Fragment>
    );
  }
}

TrackingScreen.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TrackingScreen;
