import React, { Component, Fragment } from "react";
import _, { isEmpty } from "lodash";
import {
  Button,
  Col,
  Row,
  Spin,
  Upload,
  Icon,
  Checkbox,
  Alert,
  Select,
  Link,
  Drawer
} from "../common/UIComponents";
import BaseModal from "../components/BaseModal";
import { alertMessage } from "../common/Common";
import FormErrors from "../components/common/FormErrors";
import { fetchShortFormAccounts } from "../api/Account";
import userStore from "../stores/UserStore";
import { bulkUpload, uploadOrdersHistory } from "../api/OrdersApi";
import { fetchLocations } from "../api/LocationsApi";
import AppConfig from "../config/AppConfig";
import { getFileType } from "../common/Common";
import UploadedOrderList from "./UploadedOrderList";
import EditOrderForm from "../components/orders/EditOrderForm";
import { fetchOrderDetails } from "../api/OrdersApi";
import NestedTable from "../components/orders/UploadHistory";
import { dynamicFileDownload, renderAlertMessage } from "../helpers/common";
import { ApiUrl } from "../api/ApiUtils";
import I18n from "../common/I18n";

const { Dragger } = Upload;

class UploadSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      accounts: [],
      showFormModal: false,
      currentFileInfo: {
        csv_file: "",
        process_by_columns: true,
        contains_header: true,
        account_code: "",
        warehouse_code: "",
        auto_customer_order_no: false,
      },
      inProgress: false,
      isLoading: false,
      fileList: [],
      failed_orders: [],
      successful_orders: [],
      orgId: userStore.getStateValue("selectedOrg"),
      warehouses: [],
      showModal: false,
      showHistory:false,
      uploadOrders:[],
      historyData:[],
      selectedOrderId: "",
      currentOrder: {},
      editForm: false,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount () {
    this.getAccounts();
    this.getWarehouses();
    this.uploadOrdersHistories();
  }

  componentDidUpdate(prevProps, prevState) { 
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts();
    } else if (
      !_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) ||
      (!_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    }
  }

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  getWarehouses = () => {
    this.setState({ inProgress: false });
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState(
          {
            warehouses: result.locations.locations,
            inProgress: false,
          },
          () => {
            this.setDeafultWarehouse();
          }
        );
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };

  setDeafultWarehouse = () => {
    if (this.state.warehouses.length === 1) {
      this.setState({
        currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
          warehouse_code: this.state.warehouses[ 0 ].location_code,
        }),
      });
    }
  };

  setDefaultAccount = () => {
    if (this.state.accounts?.length === 1) {
      this.setState({
        currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
          account_code: this.state.accounts[0].code,
        }),
      });
    }
  };

getAccounts = (whId) => {
    // Clear currentFileInfo before fetching new accounts
    this.setState({
        currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
            account_code: null,
        }),
    });

    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);

    this.setState(
        {
            accounts: accountsData,
        },
        () => {
            this.setDefaultAccount();
        }
    );
};

  resetFileObject = () => {
    this.setState({
      currentFileInfo: {
        csv_file: "",
        process_by_columns: true,
        contains_header: true,
        account_code: "",
        warehouse_code: "",
        auto_customer_order_no: false,
      },
    });
  };

  handleSave = () => {
    this.setState({ isLoading: true });
    const { currentFileInfo } = this.state;
    bulkUpload(this.state.isNew, currentFileInfo).then((result) => {
      if (result.success) {
        if (
          result.orders &&
          result.orders.errors &&
          result.orders.errors.length > 0
        ) {

          const actualError = _.isEqual( _.get(result, 'orders.errors[0]'), "Net::ReadTimeout") ? I18n.t('order.upload_timeout_error') : _.get(result, 'orders.errors[0]');

          this.setState({
            errors: [ actualError ],
            isLoading: false,
            fileList: [],
          });
        } else {
          this.handleSaveSuccess(I18n.t("messages.processed"));
          this.setState(
            {
              isLoading: false,
              errors: [],
              fileList: [],
              failed_orders: result.orders.failed_orders
                ? result.orders.failed_orders
                : [],
              successful_orders: result.orders.created_order_numbers
                ? result.orders.created_order_numbers
                : [],
            },
            () => {
              this.resetFileObject();
            }
          );
        }
      } else {
          const actualError = _.isEqual( _.get(result, 'orders.errors[0]'), "Net::ReadTimeout") ? I18n.t('order.upload_timeout_error') : _.get(result, 'orders.errors[0]');

        this.setState({
          errors: [ actualError ],
          isLoading: false,
          fileList: [],
        });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.uploadOrdersHistories();
  };

  handleOnModalClose = () => {
    if (this.state.showFormModal && this.state.currentLocation.id) {
      this.getLocations();
    }
    this.setState({ showFormModal: false, showReviews: false });
  };

  handleFileChange = (info) => {
    this.setState({
      inProgress: true,
      errors: [],
      failed_orders: [],
      successful_orders: [],
    });
    const { status } = info.file;
    let fileList = [ ...info.fileList ];
    // in array case
    const fileNamesList = fileList.map((file) => {
      return file.name;
    });
    const filesType = fileNamesList.map((fileName) => {
      return getFileType(fileName);
    });
    // const fileName = info.file.name;
    // const fileType = getFileType(fileName);
    if (filesType.some((item) => ![ "doc", "sheet" ].includes(item))) {
      this.setState({
        errors: [ I18n.t('messages.file_type_err_msg') ],
        inProgress: false
      });
      //
    } else {
      if (status !== "uploading") {
        // info.fileList.splice(0,1);

        this.setState(
          {
            currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
              csv_file: info.file.originFileObj,
            }),
          },
          () => {
          }
        );
      }
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });

      this.setState({ fileList: fileList || [], inProgress: false });
    }
    // if (status === 'done') {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  };

  handleOnChange = (element, value) => {
    this.setState({
      currentFileInfo: Object.assign({}, this.state.currentFileInfo, {
        [ element ]: value,
      }),
    }, () => {
      if (element === 'warehouse_code') {
        if (!_.isEmpty(value)) {
          const whId = this.state.warehouses.find(
            (wh) => wh.location_code === value
          ).id;
          this.getAccounts(whId);
        }
      }
    });
  };

  handleOrderSaveSuccess = (
    callbackMessage,
    isOutOfDispatch,
    isModified,
    newData
  ) => {
    alertMessage(callbackMessage);
    this.closeEditFormModal();
  };

  closeEditFormModal = () => {
    this.setState({
      editForm: false,
      currentOrder: {},
    });
  };

  handleEditOrderClick = (id) => {
    this.setState(
      {
        selectedOrderId: id,
      },
      () => {
        fetchOrderDetails(id).then((result) => {
          if (result.success) {
            const currentOrder =
              result.orders.length > 0 ? _.find(result.orders, { id: id }) : {};
            if (!currentOrder.id) {
              currentOrder.id = id;
            }
            this.setState(
              {
                currentOrder,
              },
              () => {
                this.setState({ editForm: true });
              }
            );
          } else {
            alertMessage(result.errors[ 0 ], 'error', 10);
          }
        });
      }
    );
  };

  uploadOrdersHistories = (initial) => {
    const organization_id = userStore.getStateValue('selectedOrg')
    this.setState({ inProgress: true })
    const page = this.tableOptions.pagination.current;
    const perPage = initial ? initial.perPage : AppConfig.perPage;
    uploadOrdersHistory(organization_id,page,perPage).then((result) => {
      if (result.success) {
        if(_.isObject(result.pagination)){
        this.setState(
          {
            historyData: result.orders_import_logs,
            inProgress: false,
            pagination: result.pagination ? result.pagination : {},
          },
        );
        }
      } else {
        this.setState({ inProgress: false });
        renderAlertMessage(result.errors)
      }
    });
  };
  
  historyOrders =()=> {
    const {pagination} = this.state
    return (
    <BaseModal
      title={I18n.t('order.upload_orders.uploaded_history')}      
      width="75%"
      style={{ top: 20}}
      bodyStyle={{ padding: 5}}
      onCancel={() => this.setState({ showHistory: false })}
    >
      <NestedTable
       historyData={this.state.historyData} 
      //  scroll = {{y:"calc(100vh-300px)"}}
       handleEditOrder ={this.handleEditOrderClick}
       pagination={{
        total: pagination.total_count,
        current: pagination.current_page,
        pageSize:pagination.per_page || AppConfig.ordersPerPage
        
      }}
      tableChange={(paginationInfo, filters, sorter) =>
        this.onTableChange(paginationInfo, filters, sorter)
      }

       />
    </BaseModal>
  )}

  redirectOrders = () => (
    // this.props.history.push({
    //   pathname: "/orders",
    //   filter,
    // });
    <BaseModal
      title={ I18n.t('order.uploaded_order') }
      onCancel={ () => this.setState({ showModal: false }) }
    >
      <UploadedOrderList
        data={ this.state.successful_orders }
        pagination={ { position: 'none' } }
        handleEditOrder={ this.handleEditOrderClick }
      />
    </BaseModal>
  );

  handleCancel = () => {
    window.location.href = "/upload";
  };

  handleDownload = (fileType = "") => {
    const { orgId, currentFileInfo } = this.state;
    if (isEmpty(orgId)) {
      alertMessage(I18n.t('messages.org_id_required'), 'error', 10);
      return;
    }

    if (isEmpty(fileType)) return;
    this.setState({ inProgress: true });
    const url = ApiUrl("v2/csv_column_mappings/order_sample_file_download");
    const data = {
      org_id : this.state.orgId,
      account_code: currentFileInfo.account_code || "", 
      type_of_file : fileType
    };
    let filename = `sample_file.${fileType === 'CSV' ? 'csv' : 'xlsx'}`;

    dynamicFileDownload(url , data , filename).then((result) => {
    }).catch((error) => {
      alertMessage(error, "error", 10);
    }
    ).finally(() => {
      this.setState({ inProgress: false });
    }
    );
  };

  onTableChange = (pagination, filters, sorter) => {
    const currentPage = this.tableOptions.pagination && this.tableOptions.pagination.current ? this.tableOptions.pagination.current : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.uploadOrdersHistories();
    } 
  } 

  render() {
    const props = {
      name: "file",
      onChange: this.handleFileChange,
      multiple: false,
    };

    return (
     
    <div className="content">
        {/* <Row className="page-header">
        <Col xs={12}>{I18n.t("order.upload")}</Col>
        <Col xs={12} className="page-actions">
            <Button
                type="primary"
                onClick={() => this.setState({ showHistory: true })}
                className="marginLeft10"
            >
                {I18n.t("order.upload_history")}
            </Button>
        </Col>
        </Row> */}
        {/* <Row>
        <Col xs={24} className='page-actions'>
            <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
            {I18n.t('location.new')}
            </Button>
        </Col>
        </Row> */}
        <Spin spinning={ this.state.isLoading } delay={ 1000 }>
        <Row
            style={ { marginBottom: 10, fontSize: "15px", fontWeight: 500 } }
        >
            {/* <Col xs={24} className="alignCenter">
            <Button type="link" size="small" onClick={this.handleDownload}>
                <u style={{ fontSize: 15, fontWeight: 500 }}>{I18n.t('order.sample_download_link')}</u>
            </Button>
            {I18n.t("order.sample_download_link")}
            </Col> */}
             <Col xs={ 4 } />
            <Col xs={ 16 } className="alignLeft">
            { I18n.t("order.sample_download_link") }&nbsp;&nbsp;:
            {/* </Col>
            <Col xs={ 12 } className="alignLeft"> */}
            &nbsp;&nbsp;
            <Link onClick={ () => this.handleDownload("CSV") }>
                <span
                className="anchor_cursor"
                style={ { fontSize: "15px", fontWeight: 500 } }
                >
                CSV
                </span>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link onClick={ () => this.handleDownload("XLS") }>
                <span
                className="anchor_cursor"
                style={ { fontSize: "15px", fontWeight: 500 } }
                >
                XLSX
                </span>
            </Link>
            </Col>
        </Row>
        <Row>
            <Col xs={ 4 } />
            <Col xs={ 16 }>
            <Row>
                { FormErrors(this.state.errors) }

                { this.state.successful_orders.length > 0 && (
                <Row>
                    <Col xs={ 24 } style={ { padding: "0px 10px 10px 10px" } }>
                    <Alert
                        description={
                        <div>
                            Successfully uploaded{ " " }
                            <u
                            className="text-decoration-dashed cursorPointer"
                            onClick={ () => this.setState({ showModal: true }) }
                            >
                            { " " }
                            { this.state.successful_orders.length > 0
                                ? this.state.successful_orders.length
                                : "" }{ " " }
                            orders
                            </u>
                        </div>
                        }
                        type="success"
                        closable
                        showIcon
                    />
                    </Col>
                </Row>
                ) }
            </Row>
            {/* <Spin spinning={this.state.inProgress} delay={1000}> */ }
            <Row>
                <Col xs={ 24 }>
                <Dragger
                    { ...props }
                    fileList={ this.state.fileList }
                    showUploadList={ !(this.state.fileList.length > 0) }
                    previewFile={ false }
                    disabled={ this.state.fileList.length > 0 }
                    customRequest={ this.dummyRequest }
                >
                    <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                    Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                    Support for bulk orders upload.{ " " }
                    </p>
                </Dragger>
                { this.state.fileList.map((file, index) => (
                    <Row key={ index } className="textRed">
                    <Col xs={ 1 }>
                        <Icon type="link" />
                    </Col>
                    <Col xs={ 22 }>{ file.name }</Col>
                    </Row>
                )) }
                </Col>
            </Row>
            <Row className="borderBottom">
                <Col
                xs={ 24 }
                sm={ 24 }
                md={ 12 }
                lg={ 3 }
                className="textBold"
                style={ { padding: "15px 0px" } }
                >
                { I18n.t("general.account") }
                </Col>
                <Col
                xs={ 24 }
                sm={ 24 }
                md={ 12 }
                lg={ 8 }
                style={ { padding: "10px 0px" } }
                >
                <Select
                    value={ this.state.currentFileInfo.account_code || "" }
                    showSearch
                    style={ { width: "100%" } }
                    filterOption={ (input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={ (e) => this.handleOnChange("account_code", e) }
                >
                    <Select.Option key="account" value="">
                    -- Select --
                    </Select.Option>
                    { this.state.accounts?.map((account) => (
                    <Select.Option
                        key={ account.code }
                        value={ account.code }
                    >
                        { `${account.name} (${account.code})` }
                    </Select.Option>
                    )) }
                </Select>
                </Col>
            </Row>
            <Row className="borderBottom">
                <Col
                xs={ 24 }
                sm={ 24 }
                md={ 12 }
                lg={ 3 }
                className="textBold"
                style={ { padding: "15px 0px" } }
                >
                { I18n.t("location.location") }
                </Col>
                <Col
                xs={ 24 }
                sm={ 24 }
                md={ 12 }
                lg={ 8 }
                style={ { padding: "10px 0px" } }
                >
                <Select
                    value={ this.state.currentFileInfo.warehouse_code || "" }
                    showSearch
                    style={ { width: "100%" } }
                    filterOption={ (input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={ (e) =>
                    this.handleOnChange("warehouse_code", e)
                    }
                >
                    <Select.Option key="warehounse" value="">
                    -- Select --
                    </Select.Option>
                    { this.state.warehouses.map((warehouse) => (
                    <Select.Option
                        key={ warehouse.id }
                        value={ warehouse.location_code }
                    >
                        { warehouse.name }
                    </Select.Option>
                    )) }
                </Select>
                </Col>
            </Row>
            <Row className="borderBottom">
                <Col xs={ 24 } style={ { padding: "10px 0px" } }>
                <Checkbox
                    checked={
                    this.state.currentFileInfo.auto_customer_order_no
                    }
                    onChange={ (e) =>
                    this.handleOnChange(
                        "auto_customer_order_no",
                        e.target.checked
                    )
                    }
                >
                    { I18n.t("order.auto_generate_order_no") }
                </Checkbox>
                </Col>
            </Row>
            { false && (
                <Fragment>
                <Row className="borderBottom">
                    <Col xs={ 24 } style={ { padding: "10px 0px" } }>
                    <Checkbox
                        checked={ this.state.currentFileInfo.contains_header }
                        onChange={ (e) =>
                        this.handleOnChange(
                            "contains_header",
                            e.target.checked
                        )
                        }
                    >
                        { I18n.t("order.contains_header") }
                    </Checkbox>
                    </Col>
                </Row>
                <Row className="borderBottom">
                    <Col xs={ 24 } style={ { padding: "10px 0px" } }>
                    <Checkbox
                        checked={
                        this.state.currentFileInfo.process_by_columns
                        }
                        onChange={ (e) =>
                        this.handleOnChange(
                            "process_by_columns",
                            e.target.checked
                        )
                        }
                    >
                        { I18n.t("order.process_by_columns") }
                    </Checkbox>
                    </Col>
                </Row>
                </Fragment>
            ) }
            <Row>
                <Col xs={ 24 } className="alignCenter margin10">
                {/* {this.state.fileList.length > 0 && <Col xs={24}>
                                        {FormButtons(this.state.inProgress, this.handleSave, this.props.onCancel)}
                                    </Col>
                                    } */}

                <Button
                    type="danger"
                    className="margin10"
                    onClick={ this.handleCancel }
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    className="margin10"
                    onClick={ this.handleSave }
                    loading={ this.state.inProgress }
                    disabled={ !(this.state.fileList.length > 0) }
                >
                    Save
                </Button>
                </Col>
            </Row>
            {/* </Spin> */ }
            { this.state.failed_orders.length > 0 && (
                <Row>
                <Col xs={ 24 } className="orderUploadErrors">
                    <h4 className="textRed"><u>{ I18n.t("order.exceptional_orders") }</u></h4>
                    <Row>
                    <Col xs={ 3 } className="textBold">
                        Row No
                    </Col>
                    <Col xs={ 5 } className="textBold">
                        Order Number
                    </Col>
                    <Col xs={ 16 } className="textBold">
                        Error
                    </Col>
                    </Row>
                    { this.state.failed_orders.map((processed_order) => (
                    // const processed_order = order[0];
                    <Row>
                        <Col xs={ 3 }>
                        { processed_order.row_number }
                        </Col>
                        <Col xs={ 5 }>
                        { processed_order.customer_order_number }
                        </Col>
                        <Col xs={ 16 }>
                        { processed_order.errors &&
                            processed_order.errors.join(",") }
                        </Col>
                    </Row>
                    )) }
                </Col>
                </Row>
            ) }
            </Col>
            <Col xs={ 6 } />
        </Row>
        </Spin>
        {this.state.showModal && this.redirectOrders()}
        {this.state.showHistory && this.historyOrders()}
        {this.state.editForm && (
        <Drawer
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={this.closeEditFormModal}
        visible={this.state.editForm}
        width="90%"
        >
        <EditOrderForm
            customerDetails={this.state.currentOrder}
            customerOrderId={this.state.currentOrder.id}
            preferences={[]}
            onCancel={this.closeEditFormModal}
            handleSuccess={(message, isOutOfDispatch, isModified, newData) =>
            this.handleOrderSaveSuccess(
                message,
                isOutOfDispatch,
                isModified,
                newData
            )
            }
            // currentRoute={this.state.selectedRouteObject}
            isNew={false}
        />
        </Drawer>
    )}
    </div>
      
    );
  }
}
export default UploadSheet;
