import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
  GenerateURL,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import I18n from "../common/I18n";
import { isEmpty } from "../common/Common";
import userStore from "../stores/UserStore";

export function fetchUsersForWeeklyReport(orgId = null) {
  // const organization_id = userStore.getStateValue('selectedOrg')
  const organization_id = isEmpty(orgId)
    ? userStore.getStateValue("selectedOrg")
    : orgId;
  const url = ApiUrl(
    `v2/driver_rates/get_driver_technicians?organization_id=${orgId}`
  );

  // if (!isEmpty(orgId)) {
  //   url = `${url}&organization_id=${orgId}`;
  // }
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "drivers" },
  });
}

export function fetchDriverRoutes(driverId) {
  const url = ApiUrl(
    `v2/nav_routes/list_all_driver_routes?driver_id=${driverId}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { nav_routes: "nav_routes" },
  });
}

export const moveRoutesToInactive = (driverId, navRouteIds) => {
  const url = "v2/drivers/move_routes_to_inactive";
  const data = {
    driver_id: driverId,
    nav_route_ids: navRouteIds,
  };
  return Call("put", ApiUrl(url), data, ParseGeneralResponse, {
    responseDataKeys: {
      message: "message",
    },
  });
};
