import React from "react";
import BaseList from "../../BaseList";
import I18n from "../../../common/I18n";

export default class DockScreenIncomingLList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "cus_name",
        title: <b>{I18n.t("dock_scan_report.customer_name")}</b>,
        dataIndex: "customer_name",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "consignee_name",
        title: <b>{I18n.t("dock_scan_report.consignee_name")}</b>,
        dataIndex: "consignee_name",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "hawb",
        title: <b>{I18n.t("dock_scan_report.hawb")}</b>,
        dataIndex: "hawb",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "dock_location",
        title: <b>{I18n.t("dock_scan_report.dock_location")}</b>,
        dataIndex: "dock_location",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "scanned_date",
        title: <b>{I18n.t("dock_scan_report.scanned_date")}</b>,
        dataIndex: "scanned_date",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "routing",
        title: <b>{I18n.t("dock_scan_report.routing")}</b>,
        dataIndex: "routing",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "order_type",
        title: <b>{I18n.t("dock_scan_report.order_type")}</b>,
        dataIndex: "order_type",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "total_pieces",
        title: <b>{I18n.t("dock_scan_report.total_pieces")}</b>,
        dataIndex: "total_pieces",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "scanned_pieces",
        title: <b>{I18n.t("dock_scan_report.scanned_pieces")}</b>,
        dataIndex: "scanned_pieces",
        render: (data, record) => <span>{data}</span>,
        width: 110,
      },
      {
        key: "service_date",
        title: <b>{I18n.t("dock_scan_report.service_date")}</b>,
        dataIndex: "service_date",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "item_name",
        title: <b>{I18n.t("dock_scan_report.item_name")}</b>,
        dataIndex: "item_name",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "shipper_name",
        title: <b>{I18n.t("dock_scan_report.shipper_name")}</b>,
        dataIndex: "shipper_name",
        render: (data, record) => <span>{data}</span>,
        width: 130,
      },
      {
        key: "address",
        title: <b>{I18n.t("dock_scan_report.address")}</b>,
        dataIndex: "address",
        render: (data, record) => <span>{data}</span>,
        width: 120,
      },
      {
        key: "city",
        title: <b>{I18n.t("dock_scan_report.city")}</b>,
        dataIndex: "city",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "state",
        title: <b>{I18n.t("dock_scan_report.state")}</b>,
        dataIndex: "state",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "zip_code",
        title: <b>{I18n.t("dock_scan_report.zip_code")}</b>,
        dataIndex: "zip_code",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "mawb",
        title: <b>{I18n.t("dock_scan_report.mawb")}</b>,
        dataIndex: "mawb",
        render: (data, record) => <span>{data}</span>,
        width: 100,
      },
      {
        key: "osd_desc",
        title: <b>{I18n.t("dock_scan_report.osd_desc")}</b>,
        dataIndex: "osd_desc",
        render: (data, record) => <span>{data}</span>,
        width: 130,
      },
      {
        key: "svc",
        title: <b>{I18n.t("dock_scan_report.svc")}</b>,
        dataIndex: "svc",
        render: (data, record) => <span>{data}</span>,
        width: 140,
      },
    ];
  }
}
