import React from 'react';
import BaseList from '../BaseList';
import {  compareString } from '../../common/Common';
import { Icon, Popconfirm, Tooltip, Popover } from '../../common/UIComponents';
import DeleteIcon from '../common/DeleteIcon';
import EditIcon from '../common/EditIcon';
import I18n from '../../common/I18n';

class AccessorialList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
        render: text => ({
          props: {
            className: 'wordWrap',
          },
          children: text,
        }),
        sorter: (a, b) => compareString(a.name, b.name),
      },
      {
        key: 'code',
        title: I18n.t('general.code'),
        dataIndex: 'code',
      },
      {
        key: 'price',
        title: I18n.t('general.price'),
        dataIndex: 'amount',
        render: data => (
          <div>{I18n.t('general.price_symbol')} &nbsp;{data}
          </div>
        ),
      },
      {
        key: 'type',
        title: I18n.t('accessorials.type'),
        dataIndex: '_applicable_to',
        render: text => ({
          props: {
            className: 'wordWrap',
          },
          children: text,
        }),
      },
      {
        key: 'description',
        title: I18n.t('accessorials.amount_type'),
        dataIndex: '_amount_type',
        render: text => ({
          props: {
            className: 'wordWrap',
          },
          children: text,
        }),
      },

      // {
      //   key: 'status',
      //   title: I18n.t('surveys.status'),
      //   render: data => (
      //     <span>

      //       <Tooltip placement="topLeft" title="Change Status">
      //         <Popconfirm
      //           placement="topRight"
      //           title={I18n.t('messages.change_status')}
      //           onConfirm={() => this.props.statusChange(data.id)}
      //           okText="Yes"
      //           cancelText="No"
      //         >
      //           {/* onChange={() => this.props.statusChange(data.id)} */}
      //           <Switch checked={data.status === 'active'} />
      //         </Popconfirm>
      //       </Tooltip>
      //     </span>
      //   ),
      //   width: 300,
      // },
      {
        key: 'actions',
        title: '',
        dataIndex: 'id',
        render: id => (
          <div className='line-actions'>
            <Tooltip placement="topLeft" title="Edit">
              <EditIcon handleClick={() => this.props.editClick(id)}/>
              &nbsp;
            </Tooltip>
            <Tooltip placement="topLeft" title="Delete">
              <Popconfirm
                placement="left"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => this.props.deleteClick(id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon/>
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
  }
}

export default AccessorialList;
