/* eslint-disable react/sort-comp */
import React, { Component, } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Col,
  Row,
  Tabs,
  Select,
} from "../../common/UIComponents";
import {  alertMessage,  isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FormButtons from "../common/FormButtons";
import FormErrors from "../common/FormErrors";
import {
  fetchAccountAdjustments,
  saveLosAdjustments,
  fetchLosAdjustments,
} from "../../api/AdjustmentsApi";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";

const { TabPane } = Tabs;
const { Option } = Select;
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLos: this.props.currentLos ? this.props.currentLos : {},
      account: this.props.account ? this.props.account : {},
      inProgress: false,
      errors: [],
      adjustments: [],
      currentRecord: {},
    };
  }

  componentDidMount() {
    if (this.state.currentRecord.los_id) {
      this.setData();
    }
    this.getAccountAdjustments();
  }

  getAccountAdjustments = () => {
    const { currentRecord } = this.state;
    fetchAccountAdjustments(this.state.account.id).then((result) => {
      if (result.success) {
        const adjustments = result.adjustments || [];
        adjustments.forEach((record) => {
          currentRecord[record.caterogy_code] = "";
        });
        this.setState(
          {
            adjustments,
            inProgress: false,
          },
          () => {
            this.getLosAdjustments();
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getLosAdjustments = () => {
    const { currentLos, currentRecord } = this.state;
    fetchLosAdjustments(this.state.account.id, currentLos.id).then((result) => {
      if (result.success) {
        const adjustments = result.adjustments || [];
        adjustments.forEach((record) => {
          currentRecord[record.category_code] = record.account_adjustment_code;
        });
        this.setState({
          currentRecord,
          inProgress: false,
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleOnChange = (element, value) => {
    this.setState({
      currentRecord: Object.assign({}, this.state.currentRecord, {
        [element]: value,
      }),
    });
  };

  handleSave = () => {
    const { currentRecord, currentLos, adjustments } = this.state;
    const data = {
      level_of_service_id: currentLos.id || "",
      adjustments: [],
    };
    Object.keys(currentRecord).forEach((key) => {
      const currentAdjustment = _.find(adjustments, { caterogy_code: key });
      if (!isEmpty(currentRecord[key]) && !isEmpty(currentAdjustment)) {
        const params = currentAdjustment.adjustments || [];
        const currentParam = _.find(params, {
          account_adjustment_code: currentRecord[key],
        });
        if (!isEmpty(currentParam)) {
          data.adjustments.push({
            category_code: key,
            account_adjustment_code: currentRecord[key],
            account_adjustment_id: currentParam.account_adjustment_id,
          });
        }
      }
    });

    const errors = []; // To do
    if (errors.length === 0) {
      this.setState({ inProgress: true });
      saveLosAdjustments(data, this.state.isNew).then((result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.saved"));
          if (this.state.isNew) {
            this.setState({
              errors: [],
              inProgress: false,
              currentRecord: {},
            });
            this.clearForm();
          } else {
            this.setState({
              errors: [],
              inProgress: false,
            });
          }
          this.props.handleSaveSuccess();
        } else {
          this.setState({ errors: result.errors, inProgress: false });
        }
      });
    } else {
      this.setState({ errors, inProgress: false });
    }
  };

  render() {
    const { adjustments, currentRecord } = this.state;
    return (
      <div>
        {adjustments.map((record) => (
          <Row className="marginTop10" key={`key${record.caterogy_code}`}>
            <Col xs={8}>{record.caterogy_name}</Col>
            <Col xs={16}>
              <Select
                showSearch
                value={currentRecord[record.caterogy_code]}
                style={{ width: "100%" }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.handleOnChange(record.caterogy_code, e)}
              >
                <Select.Option key={`${record.caterogy_code}select`} value="">
                  --- Select ---
                </Select.Option>
                {record.adjustments.map((adjustment, qindex) => (
                  <Select.Option
                    key={adjustment.account_adjustment_id}
                    value={adjustment.account_adjustment_code}
                  >
                    {adjustment.account_adjustment_name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        ))}
        
        {checkServiceExistance('ALOSA') && <Row className="marginTop10">
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel,
            )}
          </Col>
        </Row>
        }
        <Row className="marginTop10">{FormErrors(this.state.errors)}</Row>
      </div>
    );
  }
}

Form.propTypes = {
  currentLos: PropTypes.shape().isRequired,
  account: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Form;
