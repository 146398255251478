/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Divider, Input, Row, Spin } from '../../../common/UIComponents';
import { fetchFuelSurchargeDetails, saveFuelSurchargeMaster } from '../../../api/FuelSurcharges';
import FuelSurchargeDetailsList from './FuelSurchargeDetailsList';
import FuelSurchargeDetailForm from './FuelSurchargeDetailForm';
import BaseModal from '../../BaseModal';
import { alertMessage } from '../../../common/Common';
import { _ } from 'core-js';
import { checkServiceExistance, renderAlertMessage } from '../../../helpers/common';
import I18n from '../../../common/I18n';

class FuelSurchargeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      fsMaster: this.props.fsMaster,
      fsDetails: [],
      showFormModal: false,
      editingKey: '',
      divideFsDetails: false,
      lastLoopCount: 0, 
      lastDividedLength: 0,
    }
    this.handleUpdateRange = this.handleUpdateRange.bind(this);    
  }

  componentDidMount() {
    this.setState({divideFsDetails: true} , () => {
      this.getFuelSurchargeDetails(this.state.fsMaster.id);
    });
  }

  getFuelSurchargeDetails = (masterId) => {
    this.setState({ inProgress: true });
    fetchFuelSurchargeDetails(masterId)
      .then((result) => {
        if (result.success) {
          const data = result.fuel_surcharge_details;
          const detailsLength = data.length;
          const loopCount = detailsLength >= 3 ? 3 : detailsLength;
          const len = Math.ceil((detailsLength + 1) / loopCount);

          let newState = {fsDetails : data };
          if(this.state.divideFsDetails)
          {
            newState = {...newState , lastLoopCount: loopCount , lastDividedLength: len };
          }
          this.setState(newState);
        } else {
          renderAlertMessage(result.errors)
        }
      }).finally(() => { this.setState({ inProgress: false, divideFsDetails: false }); }
      );
  }

  handleAddClick = () => {
    const dummyFsDetail = [
      {
        "from": 0.001,
        "to": 1,
        "value": 0.1,
        "id": "new"
      }
    ]
    this.setState({ fsDetails: dummyFsDetail , lastLoopCount: 1, lastDividedLength: 2, divideFsDetails: true });
  }

  handleSaveSuccess= (messate) => {
    alertMessage(messate, "success", 10);
    this.setState({ showFormModal: false });
    this.getFuelSurchargeDetails(this.state.fsMaster.id);
  }

  handleUpdateRange(newRange) {
    this.getFuelSurchargeDetails(this.state.fsMaster.id);
  }

  handleOnChange = (element, value) => {
    this.setState({
      fsMaster: Object.assign({}, this.state.fsMaster, { [element]: value }),
    });
  }

  saveMaster = ()  => {
    saveFuelSurchargeMaster(false, this.state.fsMaster)
      .then((result) => {
        if (result.success) {
          this.props.onSuccess(I18n.t('messages.saved'));
        } else {
          this.setState({ errors: result.errors, inProgress: false });
          this.setState({ inProgress: false });
        }
      });
  }

  // renderFormModal() {
  //   return (
  //     <BaseModal
  //       title={I18n.t('fuelSurcharges.addRange')}
  //       onCancel={() => this.setState({showFormModal: false})}
  //       style={{ top: 150 }}
  //       width="30%"
  //     >
  //       <FuelSurchargeDetailForm
  //         onCancel={() => this.setState({showFormModal: false})}
  //         onSuccess={message => this.handleSaveSuccess(message)}
  //         fsMasterId={this.state.fsMaster.id}
  //         organizationId={this.props.organizationId}
  //       />
  //     </BaseModal>
  //   );
  // }

  componentDidUpdate (prevProps, prevState) {
    const { fsDetails } = this.state;
    const detailsLength = fsDetails.length;
    if ((prevState.fsDetails !== this.state.fsDetails) && detailsLength > 0) {
      if (this.props.canEdit && !fsDetails.find(({ id }) => id == 'new')) {
        this.setState({ fsDetails: [ ...fsDetails, { from: fsDetails[detailsLength - 1].to + 0.001 , to: 0.0, value: 0.0 , id: 'new' } ] });
      }
    }
  }

  render() {
    const {fsMaster,fsDetails , divideFsDetails , lastLoopCount , lastDividedLength } = this.state;
    const detailsLength = fsDetails.length;
    const loopCount = detailsLength >= 3 ? 3 : detailsLength;
    const len = Math.ceil((detailsLength + 1) / loopCount);
    const {isAccConfig } = this.props;
    const createCode= isAccConfig ? 'FSCMSAC' : 'FSCMC';
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000}>
          {checkServiceExistance(createCode) && (detailsLength === 0 && this.props.canEdit) &&
            <Row>
              <Col lg={4}>
                <Button onClick={() => this.handleAddClick()} style={{ marginLeft: 10, marginBottom: 10 }} type="primary" icon='plus'>
                  {I18n.t('fuelSurcharges.addRange')}
                </Button>
              </Col>
            </Row>
          }
          <Row gutter={[16, 16]} style={ lastLoopCount <= 2 ? {
            "display": "flex",
            "justifyContent" : "center",
          } : {}} >
            { divideFsDetails ? _.range(loopCount).map((i) => {
              return (
                <Col span={8} key={ i }>
                  <FuelSurchargeDetailsList
                    data={ fsDetails.slice(i * len, (i + 1) * len) }
                    pagination={ { position: "none" } }
                    onUpdateDetail={ this.handleUpdateRange }
                    editingKey={ this.state.editingKey }
                    fsMasterId={ fsMaster.id }
                    surchargeType={ fsMaster._surcharge_type }
                    organizationId={ this.props.organizationId }
                    canEdit={ this.props.canEdit && checkServiceExistance('FSCDU') }
                  ></FuelSurchargeDetailsList>
                </Col>
              );
            }) : _.range(lastLoopCount).map((i) => {
              let lastLen = lastDividedLength;
              const lastIndex = i === lastLoopCount - 1
              return (
                <Col span={8} key={ i }>
                  <FuelSurchargeDetailsList
                    data={ lastIndex ? fsDetails.slice(i * lastLen) : fsDetails.slice(i * lastLen, (i + 1) * lastLen) }
                    pagination={ { position: "none" } }
                    onUpdateDetail={ this.handleUpdateRange }
                    editingKey={ this.state.editingKey }
                    fsMasterId={ fsMaster.id }
                    surchargeType={ fsMaster._surcharge_type }
                    organizationId={ this.props.organizationId }
                    canEdit={ this.props.canEdit && checkServiceExistance('FSCDU') }
                  ></FuelSurchargeDetailsList>
                </Col>
              );}
            )}
          </Row>
          <Divider style={{ margin: "20px 0 0" }} />
          <Row>
            <Col lg={24} className="paddingTop10">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div> For each additional </div>
                <div className="marginLeft10">
                  $
                  {this.props.canEdit ? (
                    <Input
                      className="surcharge-adj-input"
                      type="number"
                      value={fsMaster.additional_charge_range}
                      onChange={(e) =>
                        this.handleOnChange("additional_charge_range", e.target.value)
                      }
                      style={{width: 80}}
                    />
                  ) : (
                    fsMaster.additional_charge_range
                  )}
                </div>
                <div className="marginLeft10"> change in the D.O.E National US Average Diesel fuel price above</div>
                <div className="marginLeft10">
                  <span className="textBold">$
                    { this.state.fsDetails.length > 1 ? this.state.fsDetails[this.state.fsDetails.length - 2].to : 0 }
                  </span>
                </div>
                <div className="marginLeft10">per gallon, </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }} className="paddingTop5">
                <div> the surcharge will be increased by </div>
                <div className="marginLeft10">
                  {this.props.canEdit ? (
                    <span>
                      {fsMaster._surcharge_type === 'MILES' && '$'}
                      <Input
                        className="surcharge-adj-input"
                        type="number"
                        value={fsMaster.surcharge_adjustment}
                        onChange={(e) =>
                          this.handleOnChange("surcharge_adjustment", e.target.value)
                        }
                        style={{width: 80}}
                      />
                    </span>
                  ) : (
                    fsMaster.surcharge_adjustment
                  )} {fsMaster._surcharge_type !== 'MILES' && '%'}
                </div>
                <div className="marginLeft10"> 
                  {fsMaster._surcharge_type === 'MILES' ? 'per mile.' : 'of line haul.'}
                </div>
                {this.props.canEdit &&
                  <Button size='default' type="primary" className="marginLeft10" onClick={this.saveMaster} icon="save">
                    {I18n.t('general.save')}
                  </Button>
                }
              </div>
            </Col>
          </Row>
          {/* { this.state.showFormModal && 
            this.renderFormModal()
          } */}
        </Spin>
      </div>
    )
  }
}

export default FuelSurchargeDetails;

FuelSurchargeDetails.propTypes = {
  fsMaster: PropTypes.shape.isRequired,
};
