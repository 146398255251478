/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import _ from 'lodash'
import {   isEmpty, alertMessage } from '../../common/Common'
import I18n from '../../common/I18n'
import {
  Icon,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Spin,
} from '../../common/UIComponents'
import userStore from '../../stores/UserStore'
import {
  fetchOrderAccessorials,
  saveOrderAccessorials,
} from '../../api/OrdersApi'
import {
  fetchAccessorialsComponents,
  fetchAccountAccessorials as fetchAccountComponentAccessorials,
} from '../../api/Accessorials'
import { setAccessorialResponse } from '../../helpers/orders'
import { renderAlertMessage } from '../../helpers/common'

class ViewQuoteAccessorial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountComponentAccessorial: [],
      orgComponents: [],
      orderAccessorials: [],
      accountId: props.account_id,
      orgId: props.org_id,
      orderId: props.customer_order_id,
      displayComponents: [],
      inProgress: false,
      decimalPoints: window.localStorage.getItem('round_off_decimals'),
    }
  }

  componentDidMount() {
    this.getOrgComponents()
    // this.getOrderAccessorials();
    // this.getAccountComponentAccessorials();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.account_id !== this.state.accountId) {
      this.setState(
        {
          accountId: nextProps.account_id,
        },
        () => {
          this.getAccountComponentAccessorials()
        },
      )
    }
  }

  getAccountComponentAccessorials = () => {
    if (!isEmpty(this.state.accountId)) {
      this.setState({ inProgress: true })
      const { orgId, accountId, orgComponents, displayComponents } = this.state
      fetchAccountComponentAccessorials(orgId, accountId, 'DISPATCHER').then(
        (result) => {
          if (result.success) {
            const accessorials = result.accessorials || []
            const accountComponentAccessorial = accessorials.map(
              (accessorial) => {
                const accComponents = accessorial.components.map(
                  (component) => component.component_code,
                )
                const componentDefs = orgComponents.filter(
                  (component) =>
                    accComponents.includes(component.code) &&
                    displayComponents.includes(component.code),
                )
                return Object.assign({}, accessorial, { componentDefs })
              },
            )
            this.setState(
              {
                inProgress: false,
                accountComponentAccessorial,
              },
              () => {
                this.getOrderAccessorials()
              },
            )
          } else {
            this.setState({ inProgress: false })
          }
        },
      )
    }
  }

  getOrderAccessorials = () => {
    const { accountComponentAccessorial } = this.state
    const location_id = this.props.currentQuote?.type_of_order === "T" ? this.props.location_id : null
        fetchOrderAccessorials(this.state.orderId, 'QUOTE',location_id).then((result) => {
      if (result.success) {
        const orderAccessorial = result.accessorials || {}
        const accessorials = orderAccessorial.account_accessorial_params || []
        // const allowedIds = this.state.accountComponentAccessorial.map(
        //   (accessorial) => accessorial.id
        // );
        const allowedIds = {}
        this.state.accountComponentAccessorial.forEach((accessorial) => {
          const components = accessorial.components
            ? accessorial.components.map((record) => record.component_code)
            : []
          allowedIds[accessorial.id] = [...components]
        })
        const orderAccessorials = setAccessorialResponse(
          accessorials,
          true,
          allowedIds,
        )
        // accessorials.forEach((accessorial) => {
        //   const accIndex = _.findIndex(this.state.accountComponentAccessorial, ['id', accessorial.account_accessorial_id]);
        //   if (accIndex >= 0) {
        //     const obj = {
        //       account_accessorial_id: accessorial.account_accessorial_id,
        //       accessorial_code: accessorial.code,
        //       component_params: {
        //         component_code: 'COLLECTION',
        //         component_values: [...accessorial.accessorial_params],
        //       },
        //     };
        //     orderAccessorials.push(obj);
        //   }
        // });
        const orderAccessorialsIds = orderAccessorials.map(
          (order) => order.account_accessorial_id,
        )
        const accountAccessorial = accountComponentAccessorial.filter(
          (accessorial) => orderAccessorialsIds.includes(accessorial.id),
        )
        this.setState({
          orderAccessorials,
          accountComponentAccessorial: accountAccessorial,
        })
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          accountComponentAccessorial: [],
        })
      }
    })
  }

  getOrgComponents = () => {
    fetchAccessorialsComponents().then((result) => {
      if (result.success) {
        const orgComponents = result.components || []
        const displayComponents = []
        const noParamComponents = []
        orgComponents.forEach((component) => {
          if (
            component.component_params &&
            component.component_params.length > 0
          ) {
            const componentParams = component.component_params || []
            const visibleParams = componentParams.filter((param) =>
              param.visible_to.includes('DISPATCHER'),
            )
            if (visibleParams.length > 0) {
              displayComponents.push(component.code)
            } else {
              noParamComponents.push(component.code)
            }
          } else {
            noParamComponents.push(component.code)
          }
        })
        this.setState(
          {
            orgComponents,
            displayComponents,
            noParamComponents,
          },
          () => {
            this.getAccountComponentAccessorials()
          },
        )
      }
    })
  }
  _renderCollectionComp = () => <div />

  handleOnChange = (
    accParam,
    componentCode,
    accountAccessorialId,
    eleValue,
  ) => {
    const { orderAccessorials } = this.state
    const accountAccessorialParamIndex = _.findIndex(orderAccessorials, [
      'account_accessorial_id',
      accountAccessorialId,
    ])
    if (accountAccessorialParamIndex >= 0) {
      const accountAccessorialParam = _.cloneDeep(
        orderAccessorials[accountAccessorialParamIndex],
      )
      const componentParams = accountAccessorialParam.component_params
        ? accountAccessorialParam.component_params
        : []
      const componentIndex = _.findIndex(componentParams, [
        'component_code',
        componentCode,
      ])
      let currentComponent = {}
      if (componentIndex >= 0) {
        currentComponent = componentParams[componentIndex]
        if (isEmpty(currentComponent.component_values)) {
          currentComponent.component_values = []
        }
        const paramObjIndex = _.findIndex(currentComponent.component_values, [
          'accessorial_key',
          accParam,
        ])
        if (paramObjIndex >= 0) {
          currentComponent.component_values[
            paramObjIndex
          ].accessorial_value = eleValue
        } else {
          currentComponent.component_values.push({
            accessorial_key: accParam,
            accessorial_value: eleValue,
          })
        }
        componentParams[componentIndex] = { ...currentComponent }
      } else {
        currentComponent = {
          component_code: componentCode,
          component_values: [],
        }
        currentComponent.component_values.push({
          accessorial_key: accParam,
          accessorial_value: eleValue,
        })
        componentParams.push({ ...currentComponent })
      }
      accountAccessorialParam.component_params = [...componentParams]
      orderAccessorials[accountAccessorialParamIndex] = {
        ...accountAccessorialParam,
      }
      this.setState({
        orderAccessorials,
      })
      // this.props.handleStateOnchange('orderAccessorials', orderAccessorials);
    }
  }

  handleAddOrderCompAccessorial = (
    accountAccessorialId,
    accessorialCode,
    eleValue,
  ) => {
    const { orderAccessorials } = this.state
    if (eleValue) {
      const accountAccessorial = orderAccessorials.find(
        (acc) => acc.id === accountAccessorialId,
      )
      if (accountAccessorial) {
      } else {
        const accountAccessorialObj = {}
        accountAccessorialObj.account_accessorial_id = accountAccessorialId
        accountAccessorialObj.accessorial_code = accessorialCode
        accountAccessorialObj.component_params = []
        // this.props.handleStateOnchange('orderAccessorials', [
        //   ...orderAccessorials,
        //   accountAccessorialObj,
        // ]);
        this.setState({
          orderAccessorials: [...orderAccessorials, accountAccessorialObj],
        })
      }
    } else {
      const newAccountAccessorials = orderAccessorials.filter(
        (acc) => acc.account_accessorial_id !== accountAccessorialId,
      )
      this.setState({
        orderAccessorials: [...newAccountAccessorials],
      })
      // this.props.handleStateOnchange('orderAccessorials', [...newAccountAccessorials]);
    }
  }

  handleSave = () => {
    const { orderAccessorials } = this.state
    this.setState({ inProgress: true, errors: [] })
    const accountAccessorialParams = []
    orderAccessorials.forEach((orderAccessorial) => {
      const object = {
        account_accessorial_id: orderAccessorial.account_accessorial_id,
        accessorial_code: orderAccessorial.accessorial_code,
        component_params: {
          component_code: '',
          component_values: null,
        },
      }
      if (orderAccessorial.component_params.length > 0) {
        orderAccessorial.component_params.map((param) => {
          accountAccessorialParams.push(
            Object.assign({}, object, { component_params: { ...param } }),
          )
        })
      } else {
        accountAccessorialParams.push(object)
      }
    })
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      account_id: this.props.account_id,
      customer_order_id: this.props.customer_order_id,
      account_accessorial_params: [...accountAccessorialParams],
    }
    saveOrderAccessorials(data).then((result) => {
      if (result.success) {
        this.setState({
          inProgress: false,
          errors: [],
        })
        alertMessage(I18n.t('messages.saved'))
      } else {
        this.setState({
          inProgress: false,
          errors: result.errors || [],
        })
      }
    })
  }

  renderField = (
    paramDefinition,
    accountAccComponent,
    accessorial,
    orderAccObject,
    currentParamObj,
  ) => {
    const size = 'small'
    const isDisabled = true;
    const { decimalPoints } = this.state;
    switch (paramDefinition.data_type) {
      case 'string':
        return (
          <Row>
            <Col>
              {paramDefinition.code === 'charge_type' ? (
                <Radio.Group
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={isEmpty(orderAccObject) || isDisabled}
                  size={size}
                >
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="flat">Flat</Radio>
                </Radio.Group>
              ) : paramDefinition.code === 'charge_value' ? (
                <Input
                  type="number"
                  addonBefore={
                    currentConfiguration[component.code].charge_type ===
                    'flat' ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentConfiguration[component.code].charge_type ===
                    'percentage' ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                  value={
                    currentConfiguration[component.code][param.code] ||
                    isDisabled
                  }
                  onChange={(e) =>
                    this.handleOnChange(
                      component.code,
                      param.code,
                      e.target.value,
                    )
                  }
                  className="textUpperCase"
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                />
              ) : (
                <Input
                  size={size}
                  value={currentParamObj && currentParamObj.accessorial_value}
                  onChange={(e) =>
                    this.handleOnChange(
                      paramDefinition.code,
                      accountAccComponent.component_code,
                      accessorial.id,
                      e.target.value,
                    )
                  }
                  disabled={isEmpty(orderAccObject) || isDisabled}
                />
              )}
            </Col>
          </Row>
        )
      case 'number':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                disabled={isEmpty(orderAccObject) || isDisabled}
                min={0}
                precision={
                  paramDefinition.code === 'min_charge' ||
                  paramDefinition.code === 'max_charge'
                    ? decimalPoints
                    : 0
                }
              />
            </Col>
          </Row>
        )
      case 'price':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentParamObj && currentParamObj.accessorial_value}
                onChange={(e) =>
                  this.handleOnChange(
                    paramDefinition.code,
                    accountAccComponent.component_code,
                    accessorial.id,
                    e.target.value,
                  )
                }
                addonBefore={<Icon type="dollar" />}
                disabled={isEmpty(orderAccObject) || isDisabled}
                min={0}
                precision={decimalPoints}
              />
            </Col>
          </Row>
        )
      case 'Boolean':
        return (
          <Row>
            <Radio.Group
              value={currentParamObj && currentParamObj.accessorial_value}
              onChange={(e) =>
                this.handleOnChange(
                  paramDefinition.code,
                  accountAccComponent.component_code,
                  accessorial.id,
                  e.target.value,
                )
              }
              size={size}
              disabled={isDisabled}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        )
      case 'Select':
        return (
          <Select
            showSearch
            size={size}
            value={currentParamObj && currentParamObj.accessorial_value}
            style={{ width: '100%' }}
            placeholder="Select"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) =>
              this.handleOnChange(
                paramDefinition.code,
                accountAccComponent.component_code,
                accessorial.id,
                e,
              )
            }
            disabled={isEmpty(orderAccObject) || isDisabled}
          >
            {paramDefinition.options.map((qoption, qindex) => (
              <Select.Option
                key={qoption.option_key}
                value={qoption.option_key}
              >
                {qoption.option_value}
              </Select.Option>
            ))}
          </Select>
        )
      default:
        break
    }
  }

  getAccValues = (record, displayComponents) => {
    const orderAccObject = this.state.orderAccessorials.find(
      (acc) => acc.account_accessorial_id === record.id,
    )
    let orderComponentParams = []
    if (!isEmpty(orderAccObject) && !isEmpty(orderAccObject.component_params)) {
      orderComponentParams = orderAccObject.component_params
    }
    const componentDefinitions =
      !isEmpty(record.componentDefs) &&
      _.isArray(record.componentDefs) &&
      record.componentDefs.length > 0
        ? record.componentDefs.filter((component) =>
            displayComponents.includes(component.code),
          )
        : []
    if (componentDefinitions.length > 0) {
      return componentDefinitions.map((componentDef) => {
        let paramObject = {}
        if (
          componentDef.component_code === 'COLLECTION' &&
          componentDef.component_params.length > 0
        ) {
          paramObject = _.find(componentDef.component_params, {
            accessorial_key: 'collection_name',
          })
        }

        const orderComponent = orderComponentParams.find(
          (component) => component.component_code === componentDef.code,
        )

        const orderParamValues =
          orderComponent && orderComponent.component_values
            ? orderComponent.component_values
            : []
        let componentParams = componentDef.component_params.filter((param) =>
          param.visible_to.includes('DISPATCHER'),
        )
        return componentParams.map((paramDefinition) => {
          const currentParamObj = orderParamValues.find(
            (cp) => cp.accessorial_key === paramDefinition.code,
          )
          return !isEmpty(currentParamObj) &&
            !isEmpty(currentParamObj.accessorial_value)
            ? `${
                paramDefinition.code === 'things_count' &&
                !isEmpty(paramObject) &&
                paramObject.accessorial_value
                  ? paramObject.accessorial_value
                  : paramDefinition.name
              } : 
  
                    ${currentParamObj.accessorial_value}`
            : ''
        })
      })
    } else {
      return []
    }
  }

  render() {
    const {
      accountComponentAccessorial,
      orgComponents,
      displayComponents,
    } = this.state
    const isDisabled = true
    const orderAccessorials = this.state.orderAccessorials
      ? this.state.orderAccessorials
      : []
    const losAccessorials = this.state.orderAccessorials
      ? this.state.orderAccessorials
          .filter((accessorial) => accessorial.accessorial_type === 'los')
          .map((accessorial) => accessorial.account_accessorial_id)
      : []
    const predefinedAccessorial = accountComponentAccessorial.filter(
      (accessorial) => losAccessorials.includes(accessorial.id),
    )
    const additionalAccessorial = accountComponentAccessorial.filter(
      (accessorial) => !losAccessorials.includes(accessorial.id),
    )
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
        {orderAccessorials.length > 0 && (
          <div className="marginTop20 fontSize12">
            {/* <h3 className="label_heading">{I18n.t('general.accessorial')}</h3> */}
            <h3 className="label_heading">{this.props.location_id === this.props.currentQuote?.pickup_location_details?.id || this.props.pickupLocationId
            ? "Pickup Accessorial" : "Delivery Accessorial"}</h3>
            <hr />
            <div className="marginTop10">
              {predefinedAccessorial.length > 0 && (
                <div>
                  <span className="textBold">
                    <u>{I18n.t('accessorials.pre_selected')}</u>
                  </span> &nbsp;
                  : &nbsp;&nbsp;
                  <span className="fontWeight500">
                    {/* {predefinedAccessorial
                    .map((record) => record.accessorial_name)
                    .join(', ')} */}
                    {predefinedAccessorial
                      .map((record) => {
                        const paramDefValues = this.getAccValues(
                          record,
                          displayComponents,
                        )
                        const paramValues = _.compact(_.flatten(paramDefValues))
                        return `${record.accessorial_name} ${
                          paramValues.length > 0
                            ? `( ${paramValues.join(', ')} )`
                            : ''
                        }`
                      })
                      .join(', ')}
                  </span>
                </div>
              )}
              {false && additionalAccessorial.length > 0 &&
                predefinedAccessorial.length > 0 &&
                ', '}
              {additionalAccessorial.length > 0 && (
                <div>
                  <span className="textBold">
                    <u>{I18n.t('accessorials.additional')}</u>
                  </span>
                  : &nbsp;&nbsp;
                  <span className="fontWeight500">
                    {/* {additionalAccessorial
                    .map((record) => record.accessorial_name)
                    .join(', ')} */}
                    {additionalAccessorial
                      .map((record) => {
                        const paramValues = _.compact(
                          _.flatten(this.getAccValues(record, displayComponents))
                        )
                        return `${record.accessorial_name} ${
                          paramValues.length > 0
                            ? `( ${paramValues.join(', ')} )`
                            : ''
                        }`
                      })
                      .join(', ')}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </Spin>
    )
  }
}

export default ViewQuoteAccessorial
