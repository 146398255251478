/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Col,
  FormItem,
  Input,
  Row,
  Radio,
  Icon,
  Select,
  Checkbox,
  Modal,
  Button,
  DatePicker,
  Spin,
  MaterialFormItem,
} from '../../common/UIComponents'
import {
  alertMessage,
  isEmpty,
  randomNumber,
} from '../../common/Common'
import I18n from '../../common/I18n';
import FormErrors from '../common/FormErrors'
import {
  fetchCategories,
  fetchAdjustmentCategoryDetails,
  updateAdjustMentCategory,
} from '../../api/AdjustmentsApi'
import {
  fetchAdjustments,
  saveAdjustment,
  deleteAdjustment,
} from '../../api/SpecialDayPriceApi'
import { momentTime, stringToMoment } from '../../helpers/date_functions'
import SpecialPriceList from './SpecialPricesList'
import { Empty, Form } from 'antd';
import MaterialTimePicker from '../orders/MaterialTimePicker';
import { withRouter } from 'react-router';
import { WarehouseContext } from '../../context/WarehouseContext';
import { OrgContext } from '../../context/OrgContext';
import { renderAlertMessage } from '../../helpers/common'

const { confirm, info } = Modal
class SpecialDayPrice extends Component {
  // static contextType = OrganizationSettingsContext;
  constructor(props) {
    super(props)
    this.state = {
      currentAdjustment: this.props.adjustment || {},
      account: this.props.account || {},
      inProgress: false,
      listProgress: false,
      errors: [],
      categories: [],
      currentConfiguration: {},
      currentCategory: {},
      adjustments: [],
      madeChanges: false,
      currentAdjustmentCategory: {},
      specialAdjustments: {},
      isEditing: false,
      organizationSettings: {},
      isMilitaryTime: false,
      chargebleConfig: { charg_hours: "TOTAL_HOURS", min_hours: "0", chargable_value : "0", add_fuel: "false"},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
    }
  }

  componentDidMount() {
    this.fetchSpecialAdjustments()
    this.getCategories()
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'}) 
  }

  getDayAndDateAdjustments = (specialAdjustments) => {
  	const spl_date_and_month_adjustments= Object.values(specialAdjustments.spl_date_and_month_adjustments).flat();
  	const spl_day_adjustments = Object.values(specialAdjustments.spl_day_adjustments).flat();
  	const allAdjustments = spl_day_adjustments.concat(spl_date_and_month_adjustments);
  	return allAdjustments
  }

  computeBillingZones = (specialAdjustments , allBillingZones) => {
    const { billing_zones } = specialAdjustments;
    const billingZoneAdjustments = billing_zones.map(zone => {
			const filteredAdjustments = allBillingZones.filter(adjustment => adjustment.billing_zone_id === zone.id);
      return {
        zone,
        adjustments: formatBillingZones(filteredAdjustments)
      };
    });
    const resettedAdjustments =  billingZoneAdjustments;
    return resettedAdjustments;
  }
  
  sortAdjustments = (data) => {
  	const dayAdjustment = data.filter(item => item.adjustment_type === 'DAY');
  	const dateAdjustment = data.filter(item => item.adjustment_type === 'DATE');

  	const dayOfWeek = (day) => {
  		return [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ].indexOf(day);
  	};

  	const sortedDayAdjustment = dayAdjustment.sort((a, b) => {
  		return dayOfWeek(a.spl_day) - dayOfWeek(b.spl_day);
  	}
  	);
  	const sortedDateAdjustment = dateAdjustment.sort((a, b) => {
  		return new Date(a.spl_date) - new Date(b.spl_date);
  	}
  	);
  	const sortedAdjustments = sortedDayAdjustment.concat(sortedDateAdjustment);
  	return sortedAdjustments;
  };


  formatAllAdjustMents = (allAdjustments) => {
  	const firstHit = {};

  	const allAdjustmentsFormatted = allAdjustments.map((adjustment) => {
  		if (adjustment.adjustment_type === 'DAY') {
  			if (!firstHit[ adjustment.spl_day ]) {
  				firstHit[ adjustment.spl_day ] = true
  				adjustment.spanValue = allAdjustments.filter((adj) => adj.spl_day === adjustment.spl_day).length;
  			} else {
  				adjustment.spanValue = 0;
  			}
  		}
  		if(adjustment.adjustment_type === 'DATE'){
  			if(!firstHit[adjustment.spl_date]){
  				firstHit[adjustment.spl_date] = true
  				adjustment.spanValue = allAdjustments.filter((adj) => adj.spl_date === adjustment.spl_date).length;
  			}else{
  				adjustment.spanValue = 0;
  			}
  		}
  		return adjustment;
  	});

  	const allAdjustmentsFormattedWithNewAndEditable = allAdjustmentsFormatted.map((adjustment) => {
  		adjustment.isNew = false;
  		adjustment.isEditable = false;
  		return adjustment;
  	});

  	return allAdjustmentsFormattedWithNewAndEditable;

  };

  fetchSpecialAdjustments = () => {
    this.setState({ listProgress: true , inProgress: true });
    const {account , currentAdjustment, chargebleConfig} = this.state
    const currentAdjustmentId = currentAdjustment.id
    const accountId = account.id
    const orgId = account.organization_id
    fetchAdjustments(currentAdjustmentId, accountId, orgId).then((result) => {
      if (result.success) {
        const specialAdjustments = result.adjustments
        delete specialAdjustments.success
		    const allDayDatesAdjustments = this.getDayAndDateAdjustments(specialAdjustments);
        const sortedDayDatesAdjustments = this.sortAdjustments(allDayDatesAdjustments);
        const formattedDayDatesAdjustments = this.formatAllAdjustMents(sortedDayDatesAdjustments);
        const billingZoneAdjustments = this.computeBillingZones(specialAdjustments, sortedDayDatesAdjustments)
        const categoryParams = specialAdjustments.spl_day_adj_category?.category_params ? specialAdjustments.spl_day_adj_category.category_params : [] 
        categoryParams.filter((rec, index) => index !== 0).forEach(rec => { chargebleConfig[rec.code]= rec.name})
        this.setState({
          specialAdjustments,
          billingZoneAdjustments,
          adjustments: formattedDayDatesAdjustments,
          chargebleConfig,
          currentAdjustmentCategory:
            categoryParams.length > 0
              ? categoryParams[0]
              : { code: "ALL_ZONES", name: "All Zones" },
        });
      } else {
        renderAlertMessage(result.errors)
      }
    }).finally(() => {
      this.setState({ listProgress: false , inProgress: false})
    }
    )
  }


  formatBillingZones = (billingAdjs) => {
  	// create and object to hold the count of spl_day 
  	const spl_day_count = {};
  	const spl_date_count = {};
  	billingAdjs.forEach((billingAdj, index) => {
  		if (billingAdj.adjustment_type === 'DAY') {
  			spl_day_count[ billingAdj.spl_day ] = spl_day_count[ billingAdj.spl_day ] ? spl_day_count[ billingAdj.spl_day ] + 1 : 1;
  		}
  		if (billingAdj.adjustment_type === 'DATE') {
  			spl_date_count[ billingAdj.spl_date ] = spl_date_count[ billingAdj.spl_date ] ? spl_date_count[ billingAdj.spl_date ] + 1 : 1;
  		}
  	});
  	const firsthit = {};
  	const formattedBillingZones =  billingAdjs.map((billingAdj) => {
  		if (billingAdj.adjustment_type === 'DAY') {
        let spanValue = null;
  			if (!firsthit[ billingAdj.spl_day ]) {
  				spanValue = spl_day_count[ billingAdj.spl_day ];
  				firsthit[ billingAdj.spl_day ] = true;
  			} else {
  				spanValue = 0;
  			}
  			spl_day_count[ billingAdj.spl_day ]--;
        return {
          ...billingAdj,
          spanValue
        }
  		}
  		if (billingAdj.adjustment_type === 'DATE') {
        let spanValue = null;
  			if (!firsthit[ billingAdj.spl_date ]) {
  				spanValue = spl_date_count[ billingAdj.spl_date ];
  				firsthit[ billingAdj.spl_date ] = true;
  			} else {
  				spanValue = 0;
  			}
  			spl_date_count[ billingAdj.spl_date ]--;
        
        return {
          ...billingAdj,
          spanValue
        }
  		}

  	}
  	);
  	return formattedBillingZones;
  };

resetIsEditable = (billingAdjs) => {
    const resettedBillingAdjs = billingAdjs.map(({zone , adjustments}) => {
      const resettedAdjustments = adjustments.map((adjustment) => {
        return {
          ...adjustment,
          isEditable: true,
        }
      })
      return {
        zone,
        adjustments: resettedAdjustments
      }
    })
    return resettedBillingAdjs;
  }
resetSpanValues = (billingAdjs) => {
    const resettedBillingAdjs = billingAdjs.map(({zone , adjustments}) => {
      const resettedAdjustments = adjustments.map((adjustment) => {
        return {
          ...adjustment,
          spanValue: 1
        }
      })
      return {
        zone,
        adjustments: resettedAdjustments
      }
    })
    return resettedBillingAdjs;
  }
         

  computeBillingZones = (specialAdjustments, adjustments) => {
    const { isEditing } = this.state;
    const { billing_zones } = specialAdjustments;
    const billingZoneAdjustments = billing_zones.map(zone => {
      const filteredZoneAdjustments = adjustments.filter(adjustment => adjustment.billing_zone_id === zone.id);
      return {
        zone,
        adjustments: this.formatBillingZones(filteredZoneAdjustments)
      };
    });
    const resettedAdjustments = isEditing ? this.resetSpanValues(billingZoneAdjustments) : billingZoneAdjustments;
    return resettedAdjustments;
  }

  handleAdjustmentCategoryChange = (value) => {
    const  adjustment_categories =  this.state.specialAdjustments?.adjustment_categories ? [...this.state.specialAdjustments?.adjustment_categories] : []
    const adjCategory = 
          adjustment_categories.length > 0 ? adjustment_categories[0].category_params[0].options.find(option => option.value === value) : {} ;
    const newAdjustmentCategory = {
      code: adjCategory.value,
      name: adjCategory.label
    }
    confirm({
      title: I18n.t('messages.change_waring'),
      content: <span>{ I18n.t('messages.clear_data') }</span>,
      onOk: () => {
        this.setState({
          currentAdjustmentCategory : newAdjustmentCategory, 
          currentConfiguration: {}
        } , () => {
          this.saveAdjustmentCategory();
          this.fetchSpecialAdjustments();
        });
      },
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onCancel: () => { },
    });
  };

  getCategories = (cb) => {
    const { currentAdjustment } = this.state
    this.setState({ inProgress: true })
    fetchCategories().then((result) => {
      if (result.success) {
        const categories = result.categories || []
        const currentCategory = _.find(categories, {
          code: currentAdjustment.category_code,
        })
        this.setState(
          {
            categories,
            inProgress: false,
            currentCategory: isEmpty(currentCategory) ? {} : currentCategory,
          },
          () => {
            this.getParamDetails()
            if (cb) {
              cb()
            }
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true })
    deleteAdjustment(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        this.fetchSpecialAdjustments()
      } else {
        renderAlertMessage(result.errors)
      }
    }).finally(() => {
      this.setState({ inProgress: false })
    }
    )
  }

  handleClearClick = (id) => {
    const { currentConfiguration, adjustments } = this.state
    if (currentConfiguration[id]) {
      if (currentConfiguration[id].isNew) {
        const currentRecordIndex = _.findIndex(adjustments, ['id', id])
        if (currentRecordIndex >= 0) {
          adjustments.splice(currentRecordIndex, 1)
          this.setState({ adjustments })
        }
      } else {
        this.handleAdjustmentsOnChange(id, 'isEditable', false)
      }
      const newConfiguration = Object.assign({}, currentConfiguration)
      delete newConfiguration[id]
      this.setState({ currentConfiguration: newConfiguration ,madeChanges: false, errors: []  })
      this.fetchSpecialAdjustments()
    }
  }
  removeNewAdjustment = (billingZoneAdjustments, billingZoneId) => {
  	const zone = billingZoneAdjustments.find(zone => zone.zone.id === billingZoneId);
  	const adjustments = zone.adjustments.filter(adjustment => !adjustment.isNew);
  	zone.adjustments = adjustments;
  	return billingZoneAdjustments;
  }

  removeExisintgAdjustment = (billingZoneAdjustments, billingZoneId, adjustmentId) => {
        const newBillingZoneAdjustments = billingZoneAdjustments.map(zone => {
          if (zone.zone.id === billingZoneId) {
            const adjustments = zone.adjustments.filter(adjustment => adjustment.id !== adjustmentId);
            zone.adjustments = adjustments;
          }
          return zone;
        });
        return newBillingZoneAdjustments;
  }
  handleBillingZoneClearClick = (id) => {
    this.setState({ inProgress: true });
    const { currentConfiguration, billingZoneAdjustments } = this.state;
    if (currentConfiguration[ id ]) {
      const { isNew, billing_zone_id , isEditable } = currentConfiguration[ id ];
      if (isNew) {
        const newBillingZoneAdjustments = this.removeNewAdjustment(billingZoneAdjustments, billing_zone_id);
        this.setState({ billingZoneAdjustments: newBillingZoneAdjustments , currentConfiguration: {}, madeChanges: false, errors: [] });
      } else {
        // turn off the editable flag
          const newBillingZoneAdjustments = billingZoneAdjustments.map(zone => {
            if (zone.id === billing_zone_id) {
              const adjustments = zone.adjustments.map(adjustment => {
                if (adjustment.id === id) {
                  adjustment.isEditable = false;
                }
                return adjustment;
              });
              zone.adjustments = adjustments;
            }
            return zone;
          });
          this.setState({ billingZoneAdjustments: newBillingZoneAdjustments , currentConfiguration: {}, madeChanges: false, errors: [] });
        // deleteAdjustment(id).then((result) => {
        //   if (result.success) {
        //     alertMessage(I18n.t('messages.deleted'));
        //     const newBillingZoneAdjustments = this.removeExisintgAdjustment(billingZoneAdjustments, billing_zone_id, id);
        //     this.setState({ billingZoneAdjustments: newBillingZoneAdjustments });
        //   } else {
        //     alertMessage(result.errors[ 0 ], 'error', 10);
        //   }
        // });

      }
      const newConfiguration = Object.assign({}, currentConfiguration);
      delete newConfiguration[ id ];
      this.setState({ currentConfiguration: newConfiguration, madeChanges: false, errors: [], inProgress: false });
      // this.fetchSpecialAdjustments()
    }
  }
  addCategoryparamsOnEdit = (record) => {
      const { currentConfiguration, currentCategory} = this.state
      const categoryParams = currentCategory.category_params || []
      const newConfiguration = {...currentConfiguration}
      if (isEmpty(currentConfiguration)) {
        newConfiguration= {}
      }
      if (isEmpty(currentConfiguration[record.id])) {
        newConfiguration[record.id] = { isNew: false }
      }
      if (!isEmpty(categoryParams)) {
        categoryParams.forEach((param) => {
          if (param.code === 'spl_date') {
            newConfiguration[record.id][param.code] = stringToMoment(
              record[param.code],
              'date',
            )
          } else if (param.code === 'from_time' || param.code === 'to_time') {
            newConfiguration[record.id][param.code] = stringToMoment(
              record[param.code],
              'time',
            )
          } else {
            newConfiguration[record.id][param.code] = record[param.code]
          }
        })
      }

      return newConfiguration
  }
  handleEditClick = (record) => {
    this.setState({
      inProgress: true,
    })
    const { adjustments } = this.state
    const currentAdjuestMentIndex = _.findIndex(adjustments, ['id', record.id])
    if (currentAdjuestMentIndex >= 0) {
      adjustments[currentAdjuestMentIndex].isEditable = true
      adjustments.forEach((adjustment, index) => {
        if (adjustment.adjustment_type === 'DATE') {
          if (adjustment.spl_date === record.spl_date) {
            adjustments[index].spanValue = 1
          }
        } else {
          if (adjustment.spl_day === record.spl_day) {
            adjustments[index].spanValue = 1
          }
        }
      })
      
      this.setState({
        currentConfiguration : this.addCategoryparamsOnEdit(record),
        adjustments,
        inProgress: false,
        isEditing: !this.state.isEditing,
      })
    }
  }

  getParamDetails = () => {
    fetchAdjustmentCategoryDetails(
      this.state.account.id,
      this.state.currentAdjustment.id,
      this.state.currentAdjustment.code,
    ).then((result) => {
      if (result.success) {
        const currentParamDetails = result.details || {}
        this.setState(
          {
            currentParamDetails,
          },
          () => {
            // this.setData()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        // this.setState({ inProgress: false })
      }
    })
  }

  setData = () => {
    const { currentCategory } = this.state
    const categoryParams = currentCategory.category_params
      ? currentCategory.category_params
      : []
    const currentConfiguration = {}
    categoryParams.forEach((param) => {
      currentConfiguration[param.code] = !isEmpty(param.default_value)
        ? param.default_value
        : ''
    })
    this.setState({
      currentConfiguration,
    })
  }

  setEditableToFalse = () => {
    const adjustments = [...this.state.adjustments]
    const newAdjustments = adjustments.map((adjustment) =>
      Object.assign({}, adjustment, { isEditable: false }),
    )
    return newAdjustments
  }

  checkExistingEnabledRecord = (type = 'edit', record = null) => {
    const { adjustments, madeChanges } = this.state
    const editableRecords = adjustments.filter(
      (adjustment) => adjustment.isEditable === true,
    )
    if (madeChanges && editableRecords.length > 0) {
      confirm({
        title: I18n.t('messages.change_waring'),
        content: <span>{I18n.t('messages.proceed_confirm')}</span>,
        onOk: () => {
          const newAdjustments = this.setEditableToFalse()
          this.setState(
            {
              madeChanges: false,
              currentConfiguration: {},
              adjustments: newAdjustments.filter(
                (adjustment) => adjustment.isNew !== true,
              ),
            },
            () => {
              if (type === 'edit') {
                this.handleEditClick(record)
              } else if (type === 'new') {
                this.addItem()
              }
            },
          )
        },
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onCancel: () => {},
      })
    } else {
      const newAdjustments = this.setEditableToFalse()
      this.setState(
        {
          currentConfiguration: {},
          adjustments: newAdjustments.filter(
            (adjustment) => adjustment.isNew !== true,
          ),
        },
        () => {
          if (type === 'edit') {
            this.handleEditClick(record)
          } else if (type === 'new') {
            this.addItem()
          }
        },
      )
    }
  }

  askConfirmMessage = (id, element, value) => {
    if (element === 'los' && this.state.hasValues) {
      //!isEmpty(this.state.currentConfiguration.los)) {
      confirm({
        title: I18n.t('adjustments.change_waring', { field: 'adjustment ' }),
        content: <span className="">{'Do you want to proceed ?'}</span>,
        onOk: () => {
          this.handleOnChange(id, element, value)
        },
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onCancel: () => {},
      })
    } else {
      this.handleOnChange(id, element, value)
    }
  }

  handleOnChange = (id, element, value) => {
    const { currentConfiguration } = this.state
    if (isEmpty(currentConfiguration)) {
      currentConfiguration = {}
    }
    if (isEmpty(currentConfiguration[id])) {
      currentConfiguration[id] = {}
    }
    if (isEmpty(currentConfiguration[id][element])) {
      currentConfiguration[id][element] = ''
    }
    if(element === 'spl_day')
    {
      const selectedWeeksDays = value;
      const filteredOptions = selectedWeeksDays.length 
        ? selectedWeeksDays[ 0 ]=== "" && selectedWeeksDays.length !== 1
          ? selectedWeeksDays.slice(1)
          : selectedWeeksDays[ selectedWeeksDays.length - 1 ]=== ""
            ? [""]
            : selectedWeeksDays
        : [""];
      currentConfiguration[id][element] = filteredOptions;
    }else{
      currentConfiguration[id][element] = value
    }
    this.setState({
      currentConfiguration,
      madeChanges: true,
    })
  }

  handleAdjustmentsOnChange = (id, element, value) => {
    const { adjustments } = this.state
    const currentRecordIndex = _.findIndex(adjustments, ['id', id])
    if (currentRecordIndex >= 0) {
      adjustments[currentRecordIndex][element] = value
    }
    this.setState({
      adjustments,
    })
  }

  handleOnChargeConfigChange = (element, value) => {
    if (element === "charg_hours" && value === "TOTAL_HOURS"){
        this.state.chargebleConfig.min_hours = "0";
        this.state.chargebleConfig.chargable_value = "0";
      }

    this.setState({
      chargebleConfig: Object.assign({}, this.state.chargebleConfig, { [element]: value }),
    });
  };

  handleSave = (id , zoneId) => {
    const { currentCategory } = this.state;
    const categoryParams = currentCategory.category_params
      ? currentCategory.category_params
      : [];
    const currentConfiguration = _.cloneDeep(this.state.currentConfiguration)
    const data = {}
    data.account_adjustment_id = !isEmpty(this.state.currentAdjustment)
      ? this.state.currentAdjustment.id
      : ''
    data.adjustment_code = !isEmpty(this.state.currentAdjustment)
      ? this.state.currentAdjustment.code
      : ''
    data.account_id = this.state.account.id
    data.organization_id = this.state.account.organization_id
    const errors = [];
    const currentRecord = currentConfiguration[id] || {};
    const errorFields = categoryParams.filter(param => param.is_required === true && isEmpty(currentRecord[param.code]) && !(['spl_day', 'spl_date'].includes(param.code))).map(param => param.name);
    if(currentRecord.adjustment_type === 'DATE' && isEmpty(currentRecord['spl_date'])){
      errorFields.push(I18n.t('adjustments.special_date'));
    }else if(currentRecord.adjustment_type === 'DAY' && isEmpty(currentRecord['spl_day']) || (currentRecord['spl_day'].length === 1 && currentRecord['spl_day'][0] === "")){
      errorFields.push(I18n.t('adjustments.day_of_the_week'));
    }
    if(errorFields.length > 0){
      errors.push(`${errorFields.join(', ')} ${errorFields.length ===1 ? 'is' : 'are'} required`);
    }
    if(!isEmpty(currentRecord.from_time) && !isEmpty(currentRecord.to_time)){
      // const isBefore = currentRecord.to_time.isBefore(currentRecord.from_time);
      // if (isBefore) {
      //   errors.push(I18n.t('messages.from_time_exceeds_to_time'));
      // }
    }
    if (errors.length === 0) {
      Object.keys(currentRecord).forEach((param) => {
        if (param === 'spl_date') {
          data[param] = currentRecord[param]
            ? momentTime('YYYY-MM-DD', currentRecord[param])
            : ''
        } else if ((param === 'from_time' || param === 'to_time')) {
          data[param] = momentTime('HH:mm', currentRecord[param])
        } else {
          data[param] = currentRecord[param]
        }
      })
      this.setState({ inProgress: true })
      if (!currentRecord.isNew) {
        data.id = id
      }

      if(this.state.currentAdjustmentCategory.code === "INDV_ZONES"){
        data.billing_zone_id = zoneId
      }
      saveAdjustment(currentRecord.isNew, data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'))
          // currentConfiguration[id] = null
          // delete currentConfiguration[id]
          const newConfig = {}
          Object.keys(currentConfiguration)
            .filter((key) => key !== id)
            .forEach((key) => {
              if (!isEmpty(currentConfiguration[key])) {
                newConfig[key] = {}
                newConfig[key] = Object.assign({}, currentConfiguration[key])
              }
            })
          this.setState(
            {
              errors: [],
              inProgress: false,
              currentConfiguration: newConfig,
              madeChanges: false,
            }
          )
        } else {
          // this.setState({ errors: result.errors, inProgress: false })
          renderAlertMessage(result.errors)
        }
      }).finally(() => {
        this.setState({ inProgress: false })
        this.fetchSpecialAdjustments()
      })
    } else {
      // this.setState({ errors, inProgress: false })
    }
  }

  renderField = (id, param, currentConfiguration = {}) => {
    const size = 'small'
    if (isEmpty(currentConfiguration[id])) {
      currentConfiguration[id] = {}
    }
    const currentValue = !isEmpty(currentConfiguration[id][param.code])
      ? currentConfiguration[id][param.code]
      : ''
    const {isNew} = currentConfiguration[id];
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    switch (param.data_type) {
      case 'string':
        return (
          <Row>
            <Col>
              {param.code === 'charge_type' ? (
                <Fragment>
                  <Radio.Group
                    onChange={(e) =>
                      this.handleOnChange(id, param.code, e.target.value)
                    }
                    value={currentValue}
                    size={size}
                  >
                    <Radio value="percentage">Percentage</Radio>
                    <Radio value="flat">Flat</Radio>
                  </Radio.Group>
                  {currentConfiguration[id][param.code] === 'percentage' && (
                    <p className="notes_content" style={{ marginTop: 4 }}>
                      {I18n.t('account.billing.percentage_help_text')}
                    </p>
                  )}
                </Fragment>
              ) : param.code === 'charge_value' ? (
                <Input
                  type="number"
                  addonBefore={
                    currentConfiguration[id].charge_type === 'flat' ? (
                      <Icon type="dollar" />
                    ) : null
                  }
                  addonAfter={
                    currentConfiguration[id].charge_type === 'percentage' ? (
                      <Icon type="percentage" />
                    ) : null
                  }
                  value={currentValue}
                  onChange={(e) =>
                    this.handleOnChange(id, param.code, e.target.value)
                  }
                  className="textUpperCase"
                  min={0}
                  precision={decimalPoints}
                  defaultValue={0}
                  size={size}
                />
              ) : (
                <Input
                  value={currentValue}
                  onChange={(e) =>
                    this.handleOnChange(id, param.code, e.target.value)
                  }
                  size={size}
                />
              )}
            </Col>
          </Row>
        )
      case 'number':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentValue}
                onChange={(e) =>
                  this.handleOnChange(id, param.code, e.target.value)
                }
              />
            </Col>
          </Row>
        )
      case 'price':
        return (
          <Row>
            <Col>
              <Input
                type="number"
                size={size}
                value={currentValue}
                onChange={(e) =>
                  this.handleOnChange(id, param.code, e.target.value)
                }
                min={0}
                precision={decimalPoints}
                prefix={<Icon type="dollar" />}
              />
            </Col>
          </Row>
        )

      case 'Checkbox':
        return (
          <Row>
            <Col>
              <Checkbox
                checked={currentValue}
                onChange={(e) =>
                  this.handleOnChange(id, param.code, e.target.checked)
                }
              >
                {param.name}
              </Checkbox>
            </Col>
          </Row>
        )
      case 'Radio':
        return (
          <Row>
            <Col>
              <Row>
                <Radio.Group
                  value={currentValue}
                  onChange={(e) =>
                    this.handleOnChange(id, param.code, e.target.value)
                  }
                  size={size}
                  disabled={
                    param.code === 'adjustment_type' &&
                    currentConfiguration[id] &&
                    !currentConfiguration[id].isNew
                  }
                >
                  {param.options.map((option) => (
                    <Radio value={option.value} autoFocus>
                      {option.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Row>
            </Col>
          </Row>
        )
      case 'Boolean':
        return (
          <Row>
            <Radio.Group
              value={currentValue}
              onChange={(e) =>
                this.handleOnChange(id, param.code, e.target.value)
              }
              size={size}
            >
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Row>
        )
      case 'Select':
        return (
          <Row>
            <Col xs={24}>
              <Select
                mode={isNew ? 'multiple' : 'default'}
                maxTagCount={1}
                showSearch
                value={currentValue}
                style={{ width: '100%' }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.askConfirmMessage(id, param.code, e)}
                size={size}
              >
                <Select.Option key={`${param.code}select`} value="">
                  --- Select ---
                </Select.Option>
                {param.options.map((qoption, qindex) => (
                  <Select.Option
                    key={qoption.option_key}
                    value={qoption.option_key}
                  >
                    {qoption.option_value}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )
      case 'time':
        return (
          <Row>
            <Col>
              <MaterialTimePicker
                onChange={(e) => this.handleOnChange(id, param.code, e)}
                value={currentValue ? currentValue : null}
                computeDisabledHours={ () => []}
                computeDisabledMinutes={() => []}
                isMilitaryTime={this.state.isMilitaryTime}
              />
            </Col>
          </Row>
        )
      case 'date':
      case 'date_month':
        return (
          <Row>
            <Col>
              <DatePicker
                format="DD MMM, YYYY"
                size={size}
                value={currentValue ? currentValue : null}
                onChange={(e) => this.handleOnChange(id, param.code, e)}
              />
            </Col>
          </Row>
        )
      default:
        break
    }
  }

  checkDependantValues = (id, param) => {
    const { currentConfiguration } = this.state
    if (!isEmpty(param.dependency_value)) {
      if (
        !isEmpty(param.dependency_key) &&
        currentConfiguration[id][param.dependency_key] ===
          param.dependency_value
      ) {
        return this.renderParams(id, param)
      }
    } else {
      return this.renderParams(id, param)
    }
  }

  renderParams = (id, param) => {
    const { currentConfiguration } = this.state
    const gridSize = 24
    return (
      <Fragment>
        <Col md={gridSize} className="marginTop10">
          <MaterialFormItem label={param.name} require={param.is_required}>
            {this.renderField(id, param, currentConfiguration)}
          </MaterialFormItem>
        </Col>
      </Fragment>
    )
  }

  
  addItem = () => {
    const { currentConfiguration, currentCategory, adjustments } = this.state
    const tempId = randomNumber()
    const record = { id: tempId }
    const categoryParams = currentCategory.category_params
      ? currentCategory.category_params
      : []
    categoryParams.forEach((param) => {
      record[param.code] = !isEmpty(param.default_value)
        ? param.default_value
        : ''
    })
    currentConfiguration[tempId] = { isNew: true, ...record }
    adjustments.push({ isEditable: true, isNew: true, ...record })
    this.setState({
      currentConfiguration,
      adjustments,
    })
  }

  renderAdjustments = () => {
    const { adjustments , inProgress} = this.state
    return (
      <Fragment>
        <SpecialPriceList
          isMilitaryTime={this.state.isMilitaryTime}
          data={adjustments}
          isLoading={inProgress}
          bordered
          size="small"
          pagination={{ position: 'none' }}
          deleteClick={this.handleDeleteClick}
          editClick={(record) =>
            this.checkExistingEnabledRecord('edit', record)
          }
          clearClick={this.handleClearClick}
          handleSave={this.handleSave}
          formatElement={this.formatElement}
          currentConfiguration={this.state.currentConfiguration}
          title={() => (
            <Row>
              <Col xs={12} className="textBold">
                <span>Special Day Prices</span>
              </Col>
              <Col xs={12} className="alignRight">
                {!(
                  adjustments.filter((adjustment) => adjustment.isNew === true)
                    .length > 0
                ) && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.checkExistingEnabledRecord('new')}
                    icon="plus"
                  >
                    {I18n.t('general.add')}
                  </Button>
                )}
              </Col>
            </Row>
          )}
          rowClassName={(record, index) => (record.isNew === true ? 'table-special-row' : (index % 2 === 0 ? 'table-row-light' : 'table-row-dark'))}
        />
      </Fragment>
    )
    // const sortedDateValues = dateValues.sort((a, b) => {
    //   return new Date(a.spl_date) - new Date(b.spl_date)
    // })
  }

  formatElement = (id, code) => {
    const { currentCategory } = this.state
    const categoryParams = currentCategory.category_params
      ? currentCategory.category_params
      : []
    const categoryRecord = _.find(categoryParams, { code })
    if (!isEmpty(categoryRecord)) {
      return this.checkDependantValues(id, categoryRecord)
    }
  }

  addNewBillindZoneAdjustment = (zoneId) => {
    const { billingZoneAdjustments ,currentConfiguration, currentCategory } = this.state
    const zone = _.find(billingZoneAdjustments, { 'zone': { '_id': zoneId } });
    // add new adjustment object to the zone object adjustments array
    const tempId = randomNumber()
    const data = {
      "id": tempId,
      isNew: true,
      isEditable: true,
      billing_zone_id: zoneId,
    };
    const categoryParams = currentCategory.category_params
      ? currentCategory.category_params
      : [];
    categoryParams.forEach((param) => {
      data[ param.code ] = !isEmpty(param.default_value)
        ? param.default_value
        : '';
    }
    );
    const newConfig = { ...currentConfiguration };
    newConfig[tempId] = { isNew: true, ...data}
    zone.adjustments.push(data);


    // replace the zone object in billingZoneAdjustments array with the new zone object
    billingZoneAdjustments.splice(billingZoneAdjustments.indexOf(zone), 1, zone);
    this.setState({
      billingZoneAdjustments, 
      currentConfiguration: newConfig
    });
  };
  editBillindZoneAdjustment = (zoneId, adjustmentId) => {
    const { billingZoneAdjustments ,madeChanges , currentConfiguration, currentCategory } = this.state

    // check if billingZoneAdjustments array which matches zoneid has a isNew adjustment object
    const currentZone = _.find(billingZoneAdjustments, { 'zone': { '_id': zoneId } });
    // check if any adjument in zone adjument having isNew to true
    const existingNewRecord = _.find(currentZone.adjustments, { 'isNew': true });

      if(existingNewRecord){
        const existingAdjustments = currentZone.adjustments.filter(adjustment => adjustment.isNew !== true);
        currentZone.adjustments = existingAdjustments;
        billingZoneAdjustments.splice(billingZoneAdjustments.indexOf(currentZone), 1, currentZone);

        const resettedSpanValues = billingZoneAdjustments.map(zone => {
          const resettedAdjustments = zone.adjustments.map(adjustment => {
            return { ...adjustment, spanValue: 1 }
          })
          return { ...zone, adjustments: resettedAdjustments }
        })

        const zone = _.find(resettedSpanValues, { 'zone': { '_id': zoneId } });
        const adjustment = _.find(zone.adjustments, { 'id': adjustmentId });
        const newAdjustment = { ...adjustment, isEditable: true, isNew: false };
        
        // replace the zone object which matches in billingZoneAdjustments array with the new zone object and keep other zones as it is
        const newBillingZoneAdjustments = resettedSpanValues.map(zone => {
          if (zone.zone._id === zoneId) {
            const newAdjustments = zone.adjustments.map(adjustment => {
              if (adjustment.id === adjustmentId) {
                return newAdjustment
              }
              return adjustment
            })
            return { ...zone, adjustments: newAdjustments }
          }
          return zone
        })

        const newConfig = { ...currentConfiguration };
        newConfig[adjustmentId] = {...adjustment , isNew: false, isEditable:true, }

        confirm({
          title: I18n.t('messages.change_waring'),
          content: <span>{I18n.t('messages.proceed_confirm')}</span>,
          onOk: () => {
            this.setState(
              {
                madeChanges: false,
                currentConfiguration : this.addCategoryparamsOnEdit(newAdjustment),
                billingZoneAdjustments : newBillingZoneAdjustments, 

              },
            )
          },
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onCancel: () => {},
        })
      }else{
        const resettedSpanValues = billingZoneAdjustments.map(zone => {
          const resettedAdjustments = zone.adjustments.map(adjustment => {
            return { ...adjustment, spanValue: 1 }
          })
          return { ...zone, adjustments: resettedAdjustments }
        })

        const zone = _.find(resettedSpanValues, { 'zone': { '_id': zoneId } });
        const adjustment = _.find(zone.adjustments, { 'id': adjustmentId });
        const newAdjustment = { ...adjustment, isEditable: true, isNew: false };
        
        // replace the zone object which matches in billingZoneAdjustments array with the new zone object and keep other zones as it is
        const newBillingZoneAdjustments = resettedSpanValues.map(zone => {
          if (zone.zone._id === zoneId) {
            const newAdjustments = zone.adjustments.map(adjustment => {
              if (adjustment.id === adjustmentId) {
                return newAdjustment
              }
              return adjustment
            })
            return { ...zone, adjustments: newAdjustments }
          }
          return zone
        })

        const newConfig = { ...currentConfiguration };
        newConfig[adjustmentId] = {...adjustment , isNew: false, isEditable:true, billing_zone_id : zoneId}
        this.setState({
          currentConfiguration : this.addCategoryparamsOnEdit(newAdjustment),
          billingZoneAdjustments : newBillingZoneAdjustments
        });
    } 
  };
  renderIndividualZones = () => {
  		const { billingZoneAdjustments , inProgress } = this.state;

      if(inProgress) return <Spin />

      if(!billingZoneAdjustments.length) return (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> )

  		return (
  			<Row gutter={ [
  				0, 16
  			] }>
  				{
  					billingZoneAdjustments.map(({ zone, adjustments }, index) => {
  						return (
  							<Col span={ 24 } key={ index }>
  								<SpecialPriceList
                    isMilitaryTime={this.state.isMilitaryTime}
  									data={ adjustments }
  									bordered
  									size="small"
  									pagination={ { position: 'none' } }
  									deleteClick={ this.handleDeleteClick }
  									editClick={ (record) =>
  										// this.checkExistingEnabledRecord('edit', record)
                      this.editBillindZoneAdjustment(zone.id , record.id)
  									}
  									clearClick={ this.handleBillingZoneClearClick }
  									handleSave={(id) => this.handleSave(id , zone.id)}
  									formatElement={ this.formatElement }
  									currentConfiguration={ this.state.currentConfiguration }
  									title={ () => (
  										<Row>
  											<Col xs={ 12 } className="textBold">
  												<span>{ zone.name }</span>
  											</Col>
  											<Col xs={ 12 } className="alignRight">
  												{ !(
  													adjustments.filter((adjustment) => adjustment.isNew === true)
  														.length > 0
  												) && (
  														<Button
  															type="primary"
  															size="small"
  															onClick={ () =>  this.addNewBillindZoneAdjustment(zone.id) }
                                icon="plus"
  														>
  															{ I18n.t('general.add') }
  														</Button>
  													) }
  											</Col>
  										</Row>
  									) }
  									rowClassName={ (record, index) => (record.isNew === true ? 'table-special-row' : (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')) }
  								/>
  							</Col>
  						);
  					})
  				}
  			</Row>
  		);
  };

  saveAdjustmentCategory = () => {
    const { currentAdjustmentCategory, account, chargebleConfig } = this.state;
    const { id, organization_id } = account;
    const category_params =  [
            {...currentAdjustmentCategory}
          ];
    Object.keys(chargebleConfig).forEach((key) =>{
      category_params.push({ name: chargebleConfig[key], code: key });
    })
     const payload = {
          account_id: id,
          organization_id: organization_id,
          account_adjustment_id: !isEmpty(this.state.currentAdjustment)
            ? this.state.currentAdjustment.id
            : '',
          category_params,
        };
    updateAdjustMentCategory(payload).then((result) => {
      if(result.errors) {
        renderAlertMessage(result.errors)
      }
      if(result.success) {
        alertMessage(I18n.t("adjustments.alerts.adjustment_category_success"), 'success');
      }
    });
  }
  renderDynamicData = () => {
  	const { adjustment_categories } = this.state.specialAdjustments;
  	const category_params
  	 = !isEmpty(adjustment_categories) && adjustment_categories.length > 0 ? adjustment_categories[ 0 ].category_params : [];
  	const data_type = category_params.length > 0 ? category_params[ 0 ].data_type : '';
  	const currentOptions = category_params.length > 0 ? category_params[ 0 ].options: [];

  	switch (data_type) {
  		case 'Select':
  			return (
  				<Select
  					showSearch
  					value={ this.state.currentAdjustmentCategory.code }
  					style={ { width: '100%' } }
  					placeholder="Select"
  					filterOption={ (input, option) =>
  						option.props.children
  							.toLowerCase()
  							.indexOf(input.toLowerCase()) >= 0
  					}
  					onChange={ (e) => this.handleAdjustmentCategoryChange(e.target.value) }
  				>
  					{ currentOptions.map((category) => (
  						<Select.Option
  							key={ category.label }
  							value={ category.value }
  						>
  							{ category.label }
  						</Select.Option>
  					)) }
  				</Select>
  			);
  		case 'Radio':
  			return (
  				<Radio.Group
  					value={ this.state.currentAdjustmentCategory.code }
  					onChange={ (e) => this.handleAdjustmentCategoryChange(e.target.value) }
  				>
  					{ currentOptions.map((category) => (
  						<Radio
  							key={ category.label }
  							value={ category.value }
  						>
  							{ category.label }
  						</Radio>
  					)) }
  				</Radio.Group>
  			);
  		case 'Checkbox':
  			return (
  				<Checkbox.Group
  					value={ this.state.currentAdjustmentCategory.code }
  					onChange={ (e) => this.handleAdjustmentCategoryChange(e.target.value) }
  				>
  					{ currentOptions.map((category) => (
  						<Checkbox.Button
  							key={ category.label }
  							value={ category.value }
  						>
  							{ category.label }
  						</Checkbox.Button>
  					)) }
  				</Checkbox.Group>
  			);
  		default:
  			break;
  	}
  };
  render() {
  	const { currentAdjustment, currentCategory, currentAdjustmentCategory , inProgress ,specialAdjustments } = this.state;
  	const gridSize = 8;
    if(!Object.keys(specialAdjustments).length) return <Spin />;
  	return (
      <div>
        <Row>
          <Row>
            <Col md={gridSize}>
              <div className="info-text">
                <h4>{I18n.t("general.name")}:</h4>
                {currentAdjustment.name}
              </div>
            </Col>
            <Col md={gridSize}>
              <div className="info-text">
                <h4>{I18n.t("general.code")}:</h4>
                {currentAdjustment.code}
              </div>
            </Col>
            <Col md={gridSize}>
              <div className="info-text">
                <h4>{I18n.t("adjustments.category")}:</h4>
                {currentAdjustment.category_code}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                <Fragment>
                  <Row>
                    <Col sm={24} xs={24} md={8} lg={8}>
                      <Form.Item
                        label="Charge Hours"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: "0px",
                        }}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            this.handleOnChargeConfigChange("charg_hours", e.target.value)
                          }
                          value={
                            this.state.chargebleConfig.charg_hours
                              ? this.state.chargebleConfig.charg_hours
                              : "TOTAL_HOURS"
                          }
                        >
                          <Radio value="TOTAL_HOURS">Total Hours</Radio>
                          <Radio value="PER_HOUR">Per Hour</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col sm={24} xs={24} md={8} lg={8}>
                      <Form.Item
                        label="Minimum Hours"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: "0px",
                        }}
                      >
                        <Input
                          type="number"
                          value={this.state.chargebleConfig.min_hours}
                          onChange={(e) =>
                            this.handleOnChargeConfigChange(
                              "min_hours",
                              e.target.value
                            )
                          }
                          min={0}
                          disabled={this.state.chargebleConfig.charg_hours === "TOTAL_HOURS"}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} xs={24} md={8} lg={8}>

                      <Checkbox
                       onChange={(e) =>
                        this.handleOnChargeConfigChange("add_fuel", ""+e.target.checked)
                       }
                       checked={this.state.chargebleConfig.add_fuel === "true"}
                      
                       >
                        Include fuel surcharge
                       </Checkbox>
                    </Col>
                    {/* <Col sm={24} xs={24} md={8} lg={8}>
                      <Form.Item
                        label="Chargable Value"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: "0px",
                        }}
                      >
                        <Input
                          type="number"
                          value={this.state.chargebleConfig.chargable_value}
                          onChange={(e) =>
                            this.handleOnChargeConfigChange(
                              "chargable_value",
                              e.target.value
                            )
                          }
                          min={0}
                          disabled={this.state.chargebleConfig.charg_hours === "TOTAL_HOURS"}
                        />
                      </Form.Item>
                    </Col> */}
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={12}>
                      <Form.Item
                        label="Adjustment Category"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: "0px",
                        }}
                        required={
                          Array.isArray(
                            specialAdjustments["adjustment_categories"]
                          ) &&
                          specialAdjustments["adjustment_categories"].length
                            ? specialAdjustments["adjustment_categories"][0]
                                .category_params[0].is_required
                            : false
                        }
                      >
                        {this.renderDynamicData()}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Button
                        type="primary"
                        size="small"
                        style={{ float: "right" }}
                        onClick={() => this.saveAdjustmentCategory()}
                        icon="save"
                      >
                        {I18n.t("general.save")}
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              }
  					</Col>
  				</Row>
  			</Row>
  			<hr />
  			<Spin spinning={ this.state.listProgress } delay={ 1000 }>
  				<Row>{ FormErrors(this.state.errors) }</Row>
  				{ currentAdjustmentCategory.code === "ALL_ZONES" ? (
  					<Row gutter={ 16 } className="marginTop10">
  						<Col xs={ 24 }>{ this.renderAdjustments() }</Col>
  					</Row>
  				) : currentAdjustmentCategory.code === "INDV_ZONES" ? (
  					<Row gutter={ 16 } className="marginTop10">
  						<Col xs={ 24 }>{ this.renderIndividualZones() }</Col>
  					</Row>
  				) : null }

  			</Spin>
  		</div>
  	);
  }
}

SpecialDayPrice.propTypes = {
  account: PropTypes.shape.isRequired,
  currentAdjustment: PropTypes.shape.isRequired,
  onCancel: PropTypes.func.isRequired,
}

// export default SpecialDayPrice

export const SpecialDayPriceComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  return (
    <SpecialDayPrice
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
}
);

export default SpecialDayPriceComponent;