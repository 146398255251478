import React, { Fragment } from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Tooltip } from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import SettingIcon from "../common/SettingIcon";

class List extends BaseList {
  constructor(props) {
    super(props);
    const showActions = checkServiceExistance(["AADU", "AADD", "AAPD"], "ANY");
    this.columns = [
      {
        key: "name",
        title: I18n.t("adjustments.name"),
        dataIndex: "name",
      },
      {
        key: "code",
        title: I18n.t("adjustments.code"),
        dataIndex: "code",
      },
      {
        key: "category",
        title: I18n.t("adjustments.category"),
        dataIndex: "category_code",
      },
    ];
    if (showActions) {
      this.columns.push({
        key: "actions",
        title: "",
        dataIndex: "id",
        render: (id, record) => (
          <div className="line-actions">
            {checkServiceExistance("AAPD") && (
              
              <Fragment>
                <Tooltip title={ I18n.t("tooltip.setting") }>
                <SettingIcon handleClick={() => this.props.doConfig(record)}/>
                &nbsp;
                </Tooltip>
              </Fragment>
              
            )}
            {checkServiceExistance("AADU") && (
              <Fragment>
                <Tooltip title={ I18n.t("general.edit")}>
                <EditIcon handleClick={() => this.props.editClick(id)}/>
                &nbsp;
                </Tooltip>
              </Fragment>
            )}
            {checkServiceExistance("AADD") && (
              
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => this.props.deleteClick(id)}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title={ I18n.t("general.delete")}>
                <DeleteIcon/>
              &nbsp;&nbsp;&nbsp; 
              </Tooltip>
              </Popconfirm>
            )}
          </div>
        ),
        width: "95px",
      });
    }
  }
}

export default List;
