/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BaseList from "../BaseList";
import {
  checkNegitive,
  compareNumber,
  compareString,
  ellipseText,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Icon,
  Popover,
  Col,
  Row,
  Tooltip,
  Popconfirm,
  Typography,
  Link,
} from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import AddressInfo from "../common/AddressInfo";
import userStore from "../../stores/UserStore";
import OrderAppointments from "../orders/OrderAppointments";
import { getAppointmentTime, retrieveAddress, retrieveOrderValue } from "../../helpers/orders";
import { checkServiceExistance } from "../../helpers/common";
import TypeOfOrder from "../orders/TypeOfOrder";
import ValidateLocationErr from "../orders/ValidateLocationErr";
import ConsigneeDetails from "../common/ConsigneeDetails";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import { renderOrderNumType } from "./helpers";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import SettingIcon from "../common/SettingIcon";
import FormIcon from "../common/FormIcon";
import BranchesIcon from "../common/BranchesIcon";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import { TableImageColumn } from "../../common/TableImageColumn";
import { VehicleImageData } from "../../common/VehicleImageData";
import EndUserTitle from "../common/EndUserTitle";

// color mapping
class OrdersList extends BaseList {
  constructor (props) {
    super(props);
    this.columns = [];
    this.setColumns(props);
    this.state = {
      page: props.pagination.current ? props.pagination.current : 1,
      pageSize: props.pagination.pageSize
        ? props.pagination.pageSize
        : AppConfig.perPage,
      // fixedColumns: [ 'customer_order_number' ],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }


  setColumns = (props) => {
    const showExceed = this.props.showActionExceedField
    const hasLocationRecord = _.find(props.data,  (rec) => [...AppConfig.orderTypesWithLocations].includes(rec.type_of_order))
    this.columns = [
      // {
      //   title: '#',
      //   key: 'index',
      //   render: (text, record, index) => ((this.state.page-1) * AppConfig.perPage)+(index+1),
      // },

      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        sorter: true,
        width: 120,
        // width: this.state && this.state.fixedColumns.includes('customer_order_number') ? 140 : "auto",
        sortDirections: [ "ascend", "descend", "ascend" ],
        // fixed: "left",
        render: (data, record) => {
         const modifiedRecord = { ...record };
          // delete modifiedRecord.type_of_order;
          return renderOrderNumType(data, modifiedRecord, this.props.showTriggerEdit , this.props.handleEditOrder , this.props.refreshCallback , this.props.showDetailsLink);
        },
      },
    
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="hawb"
            showTriggerEdit={this.props.showTriggerEdit}
            editClick={() => this.props.handleEditOrder(record.id)}
            refreshCallback={this.props.refreshCallback}
            charsToLimit={null}
          />
        ),
        width: 140,
      },
      
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (text, record) => (
          <OrderFieldDisplay
            data={record}
            toDisplay="mawb"
            showTriggerEdit={this.props.showTriggerEdit}
            editClick={() => this.props.handleEditOrder(record.id)}
            refreshCallback={this.props.refreshCallback}
            charsToLimit={null}
          />
        ),
        width: 140,
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1" charsToLimit={null}/>
        ),
        width: 140
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2" charsToLimit={null} />
        ),
        width: 140
      },
      {
        key: "appointment",
        title: I18n.t("general.scheduled_appointment"),
        dataIndex: "appointments",
        // sorter: (a, b) => (a.appointments.length > 0 && b.appointments.length > 0 ? moment(a.appointments[0].start_datetime) - moment(b.appointments[0].start_datetime) : -1),
        // // sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (text, data) =>
          data.appointments.length > 0 && (
            <Popover
              size="small"
              content={
                <div className="table-contact-info width500 fontsize12">
                  <OrderAppointments
                    appointments={ data.appointments || [] }
                    timezone={ data.tz_short_form }
                  />
                </div>
              }
              title={ I18n.t("appointmentForm.preference_title") }
              // getPopupContainer={ (triggerNode) => triggerNode.parentNode }
            >
              <span>
                <u className="appointmentText">
                  <span
                    size="small"
                    type="primary"
                    className={
                      data.appointments.filter(
                        (appointment) => appointment.confirmed
                      ).length > 0
                        ? "confirmedBadge appointmentTypeBox"
                        : "scheduledBadge appointmentTypeBox"
                    }
                  />
                  &nbsp;
                  { getAppointmentTime(data.appointments, props.organizationSettings, props.hideAppointmentdate) }
                </u>
              </span>
            </Popover>
          ),
        width: 200,
        align: "center",
        // sorter: (a, b) => {
        //   if ((a.startTime === b.startTime)) {
        //     return 0;
        //   }
        //   if (a.startTime) {
        //     return (-1);
        //   }
        //   return (1);
        // },
        // defaultSortOrder: 'ascend',
      },
      {
        key: "consignee",
        dataIndex: "customer_first_name",
        title: <EndUserTitle />,
        width: 200,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => (
          <div>
            <Popover
              title={ I18n.t("general.customer_details") }
              content={
                <ConsigneeDetails record={ record } key={ `consigneeDetails${record.id}` } />
              }
              // getPopupContainer={ (triggerNode) => triggerNode }
            >
              { isEmpty(record.customer_first_name)
                ? "N/A"
                : `${record.customer_first_name} ${!isEmpty(record.customer_last_name)
                  ? record.customer_last_name
                  : ""
                }` }
            </Popover>
          </div>
        ),
      },
      {
        key: "city",
        title: "Origin -> Destination",
        dataIndex: "warehouse_address",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, data) => (
          <div style={ { display: "flex" } }>
            <div>{ this.setAddress(data, "origin") }</div>
            <span>&nbsp;{ " - " }&nbsp;</span>
            <div>{ this.setAddress(data, "destination") }</div>
          </div>
        ),
        width: 200,
      },
      {
        key: "cities",
        title: "City",
        dataIndex: "customer_address",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, data) => (
          <div style={{ display: "flex" }}>
            <div>
            {data?.customer_address?.city ?  data.customer_address.city : ""}
            </div>
          </div>
        ),
        width: 160,
      },
      {
        key: 'warehouse',
        title: I18n.t('general.warehouse'),
        dataIndex: 'warehouse',
        render: (text, data) => (
              <div style={{ display: "flex" }}>
                <div>
                  {data?.warehouse_address?.city ? data.warehouse_address.city : ""}
                </div>
              </div>
            ),
        width: 100
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
          <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: "street",
        title: I18n.t("general.street"),
        dataIndex: "address_line_1",
        render: (text, data) => (
          <div style={{ display: "flex" }}>
            <div>
            {data?.customer_address?.address_line1 ? data.customer_address.address_line1 : ""}
              </div>
          </div>
        ),
        width:140
      },
      {
        key: 'vehicle_type',
        title: I18n.t('order.vehicle_type'),
        dataIndex: 'vehicle_type',
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={this.props.vhTypes}
              showVehicleTitle
            />
          );
        },
        width: 100
      },
      {
        key:"vehicle_image",
        title: I18n.t("vehicleTypes.imgTitle"),
        dataIndex:"vehicle_image",
        render: (data, record) => {
          return (
            <VehicleImageData
              record={record.vehicle_type}
              vhType={this.props.vhTypes}
              width={"30"}
              borderRadius={"5"}
              align={"center"}
              showImage
            />
          );
        },
        // width: 100,
      },
      {
        key: "zipcode",
        title: "Zipcode",
        dataIndex: "customer_address",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (text, data) => (
          <div>
            <ValidateLocationErr
              record={ {
                ...data,
                zipcode: data.customer_address.zipcode,
                location_id: data.cs_location_id
              } }
              displayKey={ "zipcode" }
              errorKey={ "location_partial_match" }
              errorValue={ true }
              refreshCallback={ this.props.resolvedAddressCallback }
              loadMaps={ false }
            />
          </div>
        ),
        width: 95,
      },
      {
        key: 'company_name',
        title: I18n.t("general.company_name"),
        dataIndex: 'company_name',
        render: (data, record) => retrieveOrderValue(record, 'company_name', false),
        width: hasLocationRecord ? 200 : 95,   // 130
      },
      // {
      //   key: 'warehoue_address',
      //   title: I18n.t('general.origin'),
      //   render: data => (
      // <div>
      //   {
      //     this.setAddress(data, 'origin')
      //   }
      // </div>
      //   ),

      // },
      // {
      //   key: 'customer_address',
      //   title: I18n.t('general.destination'),
      //   render: data => (
      //     <div>
      //       {
      //         this.setAddress(data, 'destination')
      //       }
      //     </div>
      //   ),
      // },
      // {
      //   key: "type_of_order",
      //   title: I18n.t("order.type"),
      //   render: (record) => (<>

      //     <TypeOfOrder
      //       order={ record }
      //       orderTypeKey="type_of_order"
      //       relatedOrderKey="related_order"
      //       placement={ "top" }
      //     />
      //     </>
      //   ),
      //   align: "center",
      // },
      {
        key: "account_code",
        title: I18n.t("account.account_code"),
        dataIndex: "account_code",
        render: (data) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              { hasAccess ? (
                <Link
                  className={ hasAccess ? "anchor_cursor" : "" }
                  onClick={ () => {
                    if (hasAccess) {
                      this.props.navigateToAccount(data);
                    }
                  } }
                >
                  { data }
                </Link>
              ) : (
                data
              ) }
            </div>
          );
        },
        // sorter: (a, b) => compareString(a.account_code, b.account_code),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        width: 100,
      },
      {
        key: "full_address",
        title: "Address",
        dataIndex: "full_address",
        width: 400,
        render: (text, data) => retrieveAddress(data)
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        render: (data,record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              { hasAccess ? (
                <Link
                  className={ hasAccess ? "anchor_cursor" : "" }
                  onClick={ () => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  } }
                >
                  { data }
                </Link>
              ) : (
                data
              ) }
            </div>
          );
        },
        // sorter: (a, b) => compareString(a.account_name, b.account_name),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        width: 100,
      },
    ].filter((i) => !props.hideColumns.includes(i.key));
    if (props.currentFilter !== "REJECTED") {
      this.columns.push({
        key: "created_at",
        title: I18n.t("order.age"),
        dataIndex: "created_at",
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        sortDirections: [ "ascend", "descend", "ascend" ],
        align: "center",
        width: 105,
        render: (created_at, data) => (
          <div className="paddingRight25">
            {/* <Tooltip
              overlayStyle={{ maxWidth: "300px" }}
              title={
                <span>
                  Received on{" "}
                  {moment(created_at).tz(data.wh_timezone).format("lll")}{" "}
                  {moment.tz(data.wh_timezone).format("z")}
                </span>
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            > */}
            <span>{ moment().diff(moment(created_at), "days") }</span>
            {/* </Tooltip> */ }
          </div>
        ),
      });
    }

    if(props.screen_from === "dispatches"){
      this.columns.push(
        {
          key: "type_of_order",
          title: I18n.t("general.order_type"),
          dataIndex: "type_of_order",
          sorter: true,
          sortDirections: [ "ascend", "descend", "ascend" ],
          render: (data, record) => (
            <div style={{textAlign: "center"}}>
              <TypeOfOrder
                order={record}
                orderTypeKey="type_of_order"
                relatedOrderKey="related_order"
                placement="topLeft"
                showBadge={true}
              />
            </div>
          ),
        },
      )
    }
    // if (props.showWeight === true) {
      this.columns.push({
        key: "items_count",
        title: `${I18n.t("order.qty")}`,
        dataIndex: "quantity",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        // sorter: (a, b) => compareNumber(a.quantity, b.quantity),
        align: "center",
      },
        {
          key: "weight",
          title: (
            <Fragment>
              <Row>
                <Col className="alignCenter">
                  { I18n.t("order.weight_short_form") }
                </Col>
                <Col className="alignCenter"> ({ AppConfig.weight_unit })</Col>
              </Row>
            </Fragment>
          ),
          dataIndex: "weight",
          width: 100,
          render: (data, record) => (
            <div className="alignRight paddingRight10">
              { !isEmpty(data) ? data : "" }
            </div>
          ),
          sorter: true,
          sortDirections: [ "ascend", "descend", "ascend" ],
          // sorter: (a, b) => compareNumber(a.weight, b.weight),
          align: "center",
        });
    // }
    if (
      props.currentFilter === "ASSIGNED" ||
      props.currentFilter === "DISPATCHED" ||
      props.currentFilter === "COMPLETED"
    ) {
      this.columns.splice(2, 0, {
        key: "driver",
        title: I18n.t("general.Driver"),
        render: (data) => <div>{ data.driver_name }</div>,
        width: 60,
      });
    }
    if (props.currentFilter === "EXCEPTION") {
      this.columns.push({
        key: "EXCEPTION",
        title: I18n.t("general.exception_reason"),
        dataIndex: "exception_message",
        render: (text) => (
          <div>
            { !isEmpty(text) && (
              <Popover
                title={ I18n.t("general.reason") }
                content={ <div>{ text }</div> }
                placement="topLeft"
                overlayStyle={ { width: 350 } }
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
                <span className="textRed">{ ellipseText(text, 45) }</span>
              </Popover>
            ) }
          </div>
        ),
      });
    } 
    else if (props.currentFilter === "REJECTED") {
      this.columns.push({
        key: "REJECTED",
        title: I18n.t("general.reason"),
        dataIndex: "rejection_message",
        render: (text) => (
          <div>
            { !isEmpty(text) && (
              <Popover
                title={ I18n.t("general.reason") }
                content={ <div>{ text }</div> }
                placement="topLeft"
                getPopupContainer={ (triggerNode) => triggerNode.parentNode }
              >
                {/* <Icon type="warning" style={{ color: "red" }} />
                &nbsp;&nbsp;&nbsp; */}
                <span className="textRed">{ ellipseText(text, 45) }</span>
              </Popover>
            ) }
          </div>
        ),
      });
    } else {
      this.columns.push({
        key: "los",
        title: I18n.t("los.label"),
        dataIndex: "los_name",
        // sorter: (a, b) => compareString(a.levelOfService, b.levelOfService),
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        width: 150,
        align: "left",
      });
      this.columns.push({
        key: "zone_name",
        title: "Zones",
        dataIndex: "zoneName",
        width: 152,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        // sorter: (a, b) => compareString(a.zoneName, b.zoneName),
        align: "left",
        ellipsis: true,
        render: (text, data) => <div>{ this.setZones(data) }</div>,
      });
    }

    // {
    //   key: "quote",
    //   title: I18n.t('order.quote'),
    //   dataIndex: "quotation_amount",
    //   render: (text, data) => <div>
    //   {data.status !== "COMPLETED" ? (<Fragment>
    //           <Tooltip title={I18n.t('order.genetare_quote')}>
    //             {!isEmpty(text) ? <span className="anchor_cursor" onClick={() => this.props.generateInvoice(data.id, "true")}> {checkNegitive(text)}</span> :
    //             <Icon
    //               type="dollar"
    //               onClick={() => this.props.generateInvoice(data.id, "true")}
    //             />}
    //           </Tooltip>
    //           &nbsp;&nbsp;&nbsp;
    //         </Fragment>) :
    //         <span> {checkNegitive(text)}</span>
    //         }
    //   </div>,
    // },
    if (props.currentFilter === "" || props.screen_from === "dispatches") {
      this.columns.push({
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        sorter: true,
        sortDirections: [ "ascend", "descend", "ascend" ],
        render: (data,record) => retrieveOrderValue(record, 'status', true),
        width: hasLocationRecord ? 160 : 75,
      });
    }
    if (this.props.showActions !== false) {
      this.columns.push({
        key: "items",
        // title: props.screen === "routes" ? 'Items' : 'Details',
        render: (data) => {
          const title = `#${data.customer_order_number}`;
          return (
            <div>
              { this.props.screen_from !== "routes" &&
                data.nav_route_status &&
                // data.nav_route_status !== "ALLOCATED" &&
                data.nav_route_name && (
                  // <Tooltip
                  //   title={
                  //     data.nav_route_status
                  //       ? `${data.nav_route_status} - ${data.nav_route_name}`
                  //       : I18n.t("menu.routes")
                  //   }
                  //   placement="left"
                  //   getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  // >
                  // <Icon
                  //   type="branches"
                  //   onClick={ () =>
                  //     this.props.navigateToRoute(
                  //       data.nav_route_id,
                  //       data.nav_route_status,
                  //       data.scheduled_start_datetime_with_tz
                  //     )
                  //   }
                  // />
                  <BranchesIcon handleClick={() =>
                    this.props.navigateToRoute(
                      data.nav_route_id,
                      data.nav_route_status,
                      data.scheduled_start_datetime_with_tz
                    )}/>
                  // &nbsp;&nbsp;&nbsp;
                  // </Tooltip>
                ) }
              { checkServiceExistance("COSU") && ![ 'PENDING', 'REJECTED' ].includes(data.status) && (
                <Fragment>
                  <SettingIcon handleClick={() => this.props.statusChange(data.id)}/>
                  &nbsp;&nbsp;&nbsp;
                </Fragment>
              ) }

              { ![ "INTRANSIT", "PICKEDUP", "CLOSED", "ARCHIVED", "PENDING", "REJECTED" ].includes(
                data.status
              ) && checkServiceExistance("SCA") ? (
                <Fragment>
                  <EditIcon handleClick={() => this.props.handleEditOrder(data.id)}/>
                  &nbsp;&nbsp;&nbsp;
                </Fragment>
              ) : (
                ""
              ) }
              {
                // ['COMPLETED', 'INTRANSIT', 'PICKEDUP', 'CLOSED', 'ARCHIVED', 'EXCEPTION', 'PREPARE', 'DISPATCHED', 'ASSIGNED'].includes(data.status)
                false && checkServiceExistance("COD") && (
                  <Popconfirm
                    placement="topRight"
                    title={ I18n.t("messages.delete_confirm") }
                    onConfirm={ () => this.props.deleteClick(data.id) }
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon/>
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm>
                )
              }
              { data.status === "COMPLETED" && (
                <Fragment>
                  { checkServiceExistance("COB") && (
                    <Tooltip
                      title="Starting billing process"
                      placement="left"
                      getPopupContainer={ (triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Icon
                        type="file-pdf"
                        style={{color:"#fa8c16"}}
                        onClick={ () =>
                          this.props.generateInvoice(data.id, "false")
                        }
                      />
                      &nbsp;&nbsp;&nbsp;
                    </Tooltip>
                  ) }

                  {/* <Tooltip
                    title={I18n.t("survey.view_submitted")}
                    placement="left"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  > */}
                  <FormIcon handleClick={() => this.props.handleSurveyDetails(data.id)}/>
                  &nbsp;&nbsp;&nbsp;
                  {/* </Tooltip> */}

                </Fragment>
              ) }
              { checkServiceExistance("OBLR") && (
                <Tooltip
                  title={ I18n.t("BillOfRating.bill_of_rating_title") }
                  placement="left"
                  getPopupContainer={ (triggerNode) =>
                    triggerNode.parentNode
                  }
                >
                  <Icon
                    type="file-done"
                    onClick={ () => this.props.handleBillOfRating(data.id) }
                  />
                </Tooltip>
              ) }
              { false && data.status === "EXCEPTION" && (
                <Tooltip title="Move to Verified">
                  <Icon
                    type="rollback"
                    onClick={ () => this.props.moveToPending(data.id) }
                  />{ " " }
                </Tooltip>
              ) }
            </div>
          );
        },
        align: "center",
        width: 120,
      });
    }
    if (showExceed === true) {
      const isDispatchScreen = window.location.href.includes('routes')
  
      this.columns.push({
        key: 'items',
        fixed: "right",
        render: (data) => {
          const { account_limit_exceeded } = data
          return (
            <Row type="flex" gutter={8} align="middle" justify="end">
  
              {account_limit_exceeded && (
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
              )}
            </Row>
          )
        },
        align: 'center',
        width: isDispatchScreen ? 20 : 100,
      })
    }

    const isDispatchesPage = props.screen_from === "dispatches";
    if (!_.isEmpty(props.displayConfiguration) && isDispatchesPage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const dispatches_unallocated_list = userConfiguration.dispatches_unallocated_list || orgConfiguration.dispatches_unallocated_list;
      const columns = [];
      if (
        typeof dispatches_unallocated_list !== undefined &&
        Array.isArray(dispatches_unallocated_list)
      ) {
        dispatches_unallocated_list.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => col.key === "items");
        if (actions) {
          columns.push(actions);
        }
       this.columns = columns;
      } else {
      }
    }

  };

  setZones = (data) => (
    <Popover
      title="Zones List"
      placement="topLeft"
      content={
        <div>
          { data.order_zone_name && data.order_zone_name.length > 0
            ? `${data.order_zone_name.join(", ")}`
            : "NA" }
        </div>
      }
      overlayStyle={ { width: 250 } }
      // getPopupContainer={ (triggerNode) => triggerNode.parentNode }
    >
      { data.order_zone_name && data.order_zone_name.length > 0
        ? `${data.order_zone_name.length > 1
          ? `${data.order_zone_name[ 0 ]} (+${data.order_zone_name.length - 1
          })`
          : data.order_zone_name[ 0 ]
        }`
        : "NA" }
    </Popover>
  );

  setAddress = (data, type) => {
    const sourcePoint =
      data?.origin && Object.keys(data.origin).length !== 0
        ? data.origin
        : data.warehouse_address;
    const destinationPoint =
      data?.destination && Object.keys(data.destination).length !== 0
        ? data.destination
        : data.customer_address;
    const pickUpAddress = (
      <Popover
        title={
          data?.origin?.city
            ? I18n.t("general.origin_location")
            : I18n.t("general.warehouse_address")
        }
        content={<AddressInfo address={sourcePoint} />}
        overlayStyle={ { width: 200 } }
        // getPopupContainer={ (triggerNode) => triggerNode.parentNode }
      >
        {
          // data.warehouse_address && data.warehouse_address.city ? `${data.warehouse_address.city} ( ${data.warehouse_address.state} )` : 'NA'
          sourcePoint && sourcePoint.city
            ? `${
                sourcePoint.location_code
                  ? sourcePoint.location_code
                  : sourcePoint.city
              }`
            : "NA"
        }
      </Popover>
    );

    const dropAddress = (
      <Popover
        title={
          data.destination.city
            ? I18n.t("general.destination_location")
            : I18n.t("general.customer_address")
        }
        content={ <AddressInfo address={destinationPoint} /> }
        overlayStyle={ { width: 200 } }
        // getPopupContainer={ (triggerNode) => triggerNode.parentNode }
      >
        {
          // data.customer_address && data.customer_address.city ? `${data.customer_address.city} ( ${data.customer_address.state} )` : 'NA'
          destinationPoint && destinationPoint.city
          ? `${
              destinationPoint.location_code
                ? destinationPoint.location_code
                : destinationPoint.city
            }`
            : "NA"
        }
      </Popover>
    );
    if (type === "origin") {
      if ([ "R", "TR" ].includes(data.type_of_order)) {
        return dropAddress;
      }
      return pickUpAddress;
    }
    if ([ "R", "TR" ].includes(data.type_of_order)) {
      return pickUpAddress;
    }
    return dropAddress;
  };

  // componentWillReceiveProps (nextProps) {
  //   this.columns = [];
  //   this.setColumns(nextProps);
  // }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.pagination) {
  //     return {
  //       page: props.pagination.current ? props.pagination.current : 1,
  //       pageSize: props.pagination.pageSize ? props.pagination.pageSize : AppConfig.perPage,
  //     };
  //   }

  //   // Return null to indicate no change to state.
  //   return null;
  // }
}

OrdersList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
  refreshCallback: PropTypes.func,
  hideColumns: PropTypes.array,
};

OrdersList.defaultProps = {
  screen_from: "",
  showTriggerEdit: false,
  resolvedAddressCallback: () => { },
  refreshCallback: () => { },
  hideColumns: [],
};
export default OrdersList;
