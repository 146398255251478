import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import BaseList from '../../BaseList'
import { checkNegitive } from '../../../common/Common'

import {
  Icon,
  Tooltip,
  Popconfirm,
} from '../../../common/UIComponents'
import { checkServiceExistance } from '../../../helpers/common'
import AppConfig from '../../../config/AppConfig'
import EditIcon from '../../common/EditIcon'
import DeleteIcon from '../../common/DeleteIcon'
import I18n from '../../../common/I18n';

class List extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [
      // {
      //   title: I18n.t('general.from_date'),
      //   key: 'from_date',
      //   dataIndex: 'from_date',
      //   render: (data, record) => <span>
      //   {
      //   data ? moment(data, "YYYY-MM-DD").format(AppConfig.dateFormat) : ''
      //   }
      //   </span>,
      // },
      // {
      //   title: I18n.t('general.to_date'),
      //   key: 'to_date',
      //   dataIndex: 'to_date',
      //   render: (data, record) => <span>
      //   {
      //   data ? moment(data, "YYYY-MM-DD").format(AppConfig.dateFormat) : ''
      //   }
      //   </span>,
      // },
       {
        key: 'deduction_name',
        title: <div>{I18n.t('deductions.name')}</div>,
        dataIndex: 'deduction_name'
      },
      {
        key: 'gl_code',
        title: <div>{I18n.t('deductions.gl_code')}<sup className="textRed">*</sup></div>,
        dataIndex: 'gl_code',
      },
      {
        key: 'claim_amount',
        title: <div>{I18n.t('deductions.claim_amount')}(in {I18n.t('general.price_symbol')})</div>,
        dataIndex: 'claim_amount',
        render: (data) => (
          checkNegitive(data)
        ),
      },
      {
        key: 'balance_amount',
        title: <div>{I18n.t('deductions.balance_amount')}(in {I18n.t('general.price_symbol')})</div>,
        dataIndex: 'balance_amount',
        render: (data) => (
          checkNegitive(data)
        ),
      },
      {
        key: 'actions',
        title: '',
        // dataIndex: 'id',
        render: (text, record, index) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {checkServiceExistance('ADDCR') && (
              <Tooltip
              title={I18n.t('deductions.add_additional_amount')}
              >
              <Icon
                type="plus-circle"
                onClick={() => this.props.handleAdditioalsClick(record.id)}
                className="marginRight5"
              />
              </Tooltip>
            )}
            {checkServiceExistance('EDCR') && (
              <EditIcon handleClick={() => this.props.handleEditClick(record.id)}/>
            )}
            {checkServiceExistance('DDCR') && (
              <Popconfirm
                placement="topRight"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => this.props.deleteClick(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon/>
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
            )}
          </div>
        ),
      },
    ]
  }
}

export default List
