import React from 'react'
import { Icon, Popconfirm, Tooltip,  } from 'antd'
import BaseList from "../BaseList";
import I18n from '../../common/I18n';
import EditIcon from '../common/EditIcon';
import DeleteIcon from '../common/DeleteIcon';
class LabelSettingList extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [
      {
        title: I18n.t('lable_settings.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: I18n.t('lable_settings.code'),
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: `${I18n.t('order.width')} (${I18n.t('lable_settings.width_uom')})`,
        dataIndex: 'width',
        key: 'width',
      },
      {
        title: `${I18n.t('order.height')} (${I18n.t('lable_settings.height_uom')})`,
        dataIndex: 'height',
        key: 'height',
      },
      {
        title: '',
        key: 'actions',
        dataIndex: 'id',
        fixed: "right",
        render: (id, record, index) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
            </Tooltip>
            <Popconfirm
              placement="left"
              title={I18n.t('messages.delete_confirm')}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={I18n.t("general.delete")}>
                <span><DeleteIcon/></span>
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ]
  }
}
export default LabelSettingList
