import { ApiUrl, Call, PageParams, ParseGeneralResponse } from "./ApiUtils";

export const driverMonthlyReportApi = {
  create: (payload) => {
    let url = ApiUrl("v2/driver_routes_reports/monthly_drivers_routes_report");
    return Call("post", url, payload, ParseGeneralResponse, {
      responseDataKeys: { data: ""},
    });
  },
};
