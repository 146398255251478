/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BaseList from '../BaseList'
import I18n from "../../common/I18n";

import { Icon, Popconfirm, Checkbox, Link, Button, Tooltip } from '../../common/UIComponents'
import userStore from '../../stores/UserStore'
import { checkServiceExistance } from '../../helpers/common'
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'

class List extends BaseList {
  constructor(props) {
    super(props)
    const isAdmin = userStore.admin()
    const columns = [
      {
        key: 'name',
        title: I18n.t('general.name'),
        dataIndex: 'name',
      },
    ];
    const ratesFor = props.ratesFor;
    // if(ratesFor === 'account'){
    //   columns.push({
    //     key: 'category',
    //     title: I18n.t('general.category'),
    //     dataIndex: 'category',
    //     render: (data) => data ? I18n.t(`driver_rates.${data}`) : '',
    //   })
    // } else{
    //   columns.push({
    //     key: 'code',
    //     title: I18n.t('general.code'),
    //     dataIndex: 'code',
    //   })
    // }
    columns.push({
      key: 'code',
      title: I18n.t('general.code'),
      dataIndex: 'code',
    })
    const defaultOpCode = ratesFor === 'account' ? 'ADRUD' : 'DRUD';
    if(checkServiceExistance(defaultOpCode)){
      columns.push({
        key: 'active_inactive',
        title: 'Set Default',
        dataIndex: 'is_default',
        render: (data, record) => (
          <Popconfirm
            placement="topRight"
            title={I18n.t('messages.action_confirm', { action: 'change status', field: ''})}
            onConfirm={() => this.props.setStatus(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Checkbox
              checked={data === 'YES'}
            />
          </Popconfirm>
        ),
        align: 'center',
      });
    }
    const applyAllOpCode = ratesFor === 'account' ? 'AAADR' : 'OAADR';
    if(ratesFor === 'account' && checkServiceExistance(applyAllOpCode)){
      columns.push({
        key: 'apply_to_all',
        dataIndex: 'is_default',
        title: 'Apply to all drivers',
        render: (data, record) => (
          <Popconfirm
            placement="topRight"
            title={I18n.t('messages.action_confirm', { action: 'apply this driver rate to all drivers', field: ''})}
            onConfirm={() => this.props.applyToAll(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {/* <u className='anchor_cursor'><Link></Link></u> */}
            <Button type="primary" size="small" icon='check-circle'>Apply</Button>
          </Popconfirm>
        ),
        align: 'center',
      });

    }
    columns.push({
      key: 'actions',
      title: '',
      dataIndex: 'id',
      render: (id) => 
      {
        const editOpCode = this.props.ratesFor === 'account' ? 'ADRU' : 'DRU';
        const deleteOpCode = this.props.ratesFor === 'account' ? 'ADRD' : 'DRD';
        
        return (
          <div className="line-actions">
            {checkServiceExistance(editOpCode) && 
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
             </Tooltip>
             }
            {checkServiceExistance(deleteOpCode) &&
            <Tooltip title={I18n.t("general.delete")} placement="right">
            <Popconfirm
              placement="left"
              title={I18n.t('messages.delete_confirm')}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <span><DeleteIcon/></span>
            </Popconfirm>
            </Tooltip>
            }
          </div>
        );
      },
      width: '75px',
    });
    this.columns = columns;
  }
}

export default List
