/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React  from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon, Popconfirm, Dropdown, Tooltip, Menu } from '../../../common/UIComponents';
import unlink from '../../../../assets/images/unlink.png';
import EditIcon from '../../common/EditIcon';
import EyeIcon from '../../common/EyeIcon';
import I18n from '../../../common/I18n';

const OrderActionMenu = ({
  order, deleteOrderClick, handleEditOrder, handleViewOrder, showDelete, showEdit, showView
}) => (
  <Dropdown overlay={
    <Menu>
      {
        showEdit &&
        <Menu.Item key="1" onClick={() => handleEditOrder(order.customer_order_id)}>
          <EditIcon/>
          &nbsp;{I18n.t('general.edit')}
        </Menu.Item>
      }
      {
        showView &&
        <Menu.Item key="1" onClick={() => handleViewOrder(order.customer_order_id)}>
          <EyeIcon/>
          &nbsp;{I18n.t('general.view')}
        </Menu.Item>
      }
      { showDelete &&
        <Menu.Item key="2">
          <Popconfirm
            placement="topRight"
            title={I18n.t('messages.delete_confirm')}
            onConfirm={() => deleteOrderClick(order.customer_order_id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title={I18n.t("general.delete_order_from_route")}>
              <img src={unlink} style={{ width: 12, marginRight: 8 }} alt="unlink" />&nbsp;{I18n.t('general.remove')}
            </Tooltip>
          </Popconfirm>
        </Menu.Item>
      }
    </Menu>}
  >
    <Icon type="more" />
  </Dropdown>
);
export default OrderActionMenu;

OrderActionMenu.propTypes = {
  order: PropTypes.shape().isRequired,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showView: PropTypes.bool,
  deleteOrderClick: PropTypes.func,
  handleEditOrder: PropTypes.func,
  handleViewOrder: PropTypes.func,
};

OrderActionMenu.defaultProps = {
  deleteOrderClick: null,
  showDelete: true,
  showEdit: true,
  showView: true,
  handleViewOrder: null,
  handleEditOrder: null,
};
