import { Col, Popconfirm, Row, Tooltip } from "antd";
import React from "react";
import BaseList from "../BaseList";
import I18n from "../../common/I18n";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { checkNegitive, isEmpty } from "../../common/Common";
import CopyableContent from "../common/CopyableContent";
import { checkServiceExistance } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import moment from "moment";

class RemittanceIndexList extends BaseList {
  constructor(props) {
    super(props);
    const actionsRestriction = checkServiceExistance(
      ["ARBCIRI", "ARBCIRU", "ARBCIRD"],
      "ANY"
    );
    this.columns = [
      {
        key: "account_invoice_number",
        title: <b>{`${"#"} ${I18n.t("batch_management.invoice_number")}`}</b>,
        dataIndex: "account_invoice_number",
        render: (data, record) => <CopyableContent text={data} showTooltip />,
        width: 100,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "batch_number",
        title: <b>{`${"#"} ${I18n.t("batch_management.batch_name")}`}</b>,
        dataIndex: "batch_number",
        render: (data, record) => <CopyableContent text={data} showTooltip />,
        width: 100,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "check_ach_no",
        title: <b>{`${I18n.t("batch_management.check_ach_details")}`}</b>,
        dataIndex: "check_ach_no",
        render: (data, record) => <CopyableContent text={data} showTooltip />,
        width: 135,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "reference_type",
        title: <b>{`${I18n.t("batch_management.reference_type")}`}</b>,
        dataIndex: "reference_type",
        render: (data, record) => <span>{data}</span>,
        width: 100,
        className: "noWrap",
      },
      {
        key: "reference_no",
        title: <b>{`${"#"}${I18n.t("batch_management.referenece_number")}`}</b>,
        dataIndex: "reference_no",
        render: (data, record) => <CopyableContent text={data} showTooltip />,
        width: 120,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "invoice_date",
        title: <b>{I18n.t("batch_management.invoice_date")}</b>,
        dataIndex: "invoice_date",
        render: (data, record) => (
          <span>{moment(data).format("Do MMM YYYY")}</span>
        ),
        className: "noWrap",
        width: 100,
        ...AppConfig.sortingOptions,
      },
      {
        key: "invoice_amount",
        title: <b>{`${I18n.t("batch_management.invoice_amount")} ${"($)"}`}</b>,
        dataIndex: "invoice_amount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 100,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "paid_amount",
        title: <b>{I18n.t("batch_management.paid_amount")}</b>,
        dataIndex: "paid_amount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 100,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "discount",
        title: <b>{I18n.t("batch_management.discounts")}($)</b>,
        dataIndex: "discount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 100,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "credits",
        title: <b>{I18n.t("batch_management.credits")}($)</b>,
        dataIndex: "credits",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 90,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
      {
        key: "net_amount",
        title: <b>{`${I18n.t("batch_management.net_amount")} ${"($)"}`}</b>,
        dataIndex: "net_amount",
        render: (data, record) => <span>{checkNegitive(data)}</span>,
        width: 100,
        className: "noWrap",
        ...AppConfig.sortingOptions,
      },
    ];
    if (actionsRestriction) {
      this.columns.push({
        key: "manage",
        title: <b>{I18n.t("general.manage")}</b>,
        render: (data) => {
          const { handleDelete } = this.props;
          const { handleRedirect, handleEdit } = this.props;
          const { id, net_amount, net_bal_amount  } = data;
          const isAmountMatched = !isEmpty(net_amount) && !isEmpty(net_bal_amount) && _.isEqual(checkNegitive(net_amount), checkNegitive(net_bal_amount))
          return (
            <div className="pageActions">
              <Row gutter={8}>
                {checkServiceExistance("ARBCIRU") && this.props.showEdit && isAmountMatched && (
                  <Col span={12}>
                    <Tooltip title={I18n.t("general.edit")}>
                      <EditIcon
                        handleClick={() => handleEdit(data)}
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          marginTop: "14px",
                        }}
                      /> {" "}
                    </Tooltip>
                  </Col>
                )}
                {checkServiceExistance("ARBCIRD") && isAmountMatched && (
                  <Col span={12}>
                    <Tooltip title={I18n.t("general.delete")}>
                      <Popconfirm
                        placement="left"
                        title={I18n.t("messages.delete_confirm")}
                        onConfirm={() => handleDelete(id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteIcon />{" "}
                      </Popconfirm>
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </div>
          );
        },
        align: "center",
        width: 80,
        fixed: "right",
      });
    }
  }
}
export default RemittanceIndexList;
