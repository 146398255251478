import { Call, ApiUrl, ParseGeneralResponse, setObjectToFormData } from './ApiUtils';

export function sendDriverNotification(data) {
  data.operation_code= 'SNOTTDS';
  const formData = setObjectToFormData(data);
  const url = ApiUrl('v1/notifications/send_notification_to_drivers');
  const method = 'post';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { message: 'message' } }, false);
}

export default {
  sendDriverNotification,
};
