import React from "react";
import BaseList from "./BaseList";
import { Icon, Switch, Popconfirm, Tooltip } from "../common/UIComponents";
import EditIcon from "./common/EditIcon";
import DeleteIcon from "./common/DeleteIcon";
import I18n from "../common/I18n";
class OrderStatusList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("activity.feStatusCode"),
        dataIndex: "fe_status_code",
        key: "fe_status_code",
        //className: "width15per",
      },
      // {
      //   title: I18n.t("OrderStatus.acc_status_code"),
      //   dataIndex: "account_status_code",
      //   key: "account_status_code",
      //   //className: "width15per",
      // },
      {
        title: I18n.t("OrderStatus.acc_code"),
        dataIndex: "account_code",
        key: "account_code",
      },
      {
        title: I18n.t("form.description"),
        dataIndex: "account_status_description",
        key: "account_status_description",
      },
      {
        title: I18n.t("OrderStatus.location_type"),
        dataIndex: "location_type",
        key: "location_type",
      },
      {
        title: I18n.t("OrderStatus.picture"),
        key: "take_picture",
        dataIndex: "take_picture",
        render: (data, record) => (
          <Switch
            checked={record.take_picture === true}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            className={!record.take_picture ? "switchComponent" : null}
          />
        ),
      },
      {
        title: I18n.t("OrderStatus.exception"),
        key: "is_exception",
        dataIndex: "is_exception",
        render: (data, record) => (
          <Switch
            checked={record.is_exception === true}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            className={!record.is_exception ? "switchComponent" : null}
          />
        ),
      },
      {
        title: I18n.t("general.signature"),
        key: "take_pod",
        dataIndex: "take_pod",
        render: (data, record) => (
          <Switch
            checked={record.take_pod === true}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            className={!record.take_pod ? "switchComponent" : null}
          />
        ),
      },
      {
        title: I18n.t("exceptions.comments"),
        key: "enter_comments",
        dataIndex: "enter_comments",
        render: (data, record) => (
          <Switch
            checked={record.enter_comments === true}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
            className={!record.enter_comments ? "switchComponent" : null}
          />
        ),
      },
      {
        title: "",
        key: "actions",
        dataIndex: "id",
        fixed: "right",
        render: (id, record, index) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
            </Tooltip>
            <Popconfirm
              placement="left"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={I18n.t("general.delete")}>
                <span><DeleteIcon/></span>
              </Tooltip>          
            </Popconfirm>
          </div>
        ),
      },
    ];
  }
}
export default OrderStatusList;
