import React from 'react';
import { Row, Col, Card, Tag, Button, Dropdown, Icon, Tooltip, Switch } from 'antd';
import ViewToggleButton from '../../containers/ViewToggleButton';
import I18n from '../../common/I18n';
import SwitchComponent from '../../common/switchComponent';

const PreplanHeader = ({
  totalOrders,
  unassignedOrdersInfo,
  resetSelectedOrders,
  menu,
  currentView,
  handleViewToggle,
  viewOptions,
  getUnassignedOrders,
  showBetaFeature,
  openToggleColoumns,
  // handleBetaSwitchChange,
  currentOrg
}) => (
  <Row type='flex' justify='space-between' align='middle' className='preplan-header'>
    <Row gutter={ 8 } type='flex' justify='start' align='middle'>
      <Col>
        <Card>
          <Tag color="#3a5a40" style={ { margin: 0 } }>
            <Icon type="info-circle" style={ { marginRight: 5 } } />
            Unassigned - { totalOrders.length }
          </Tag>
        </Card>
      </Col>
      <Col>
          <Button
            type="ghost"
            icon="reload"
            style={ { width: 'auto' } }
            size="small"
            onClick={ getUnassignedOrders }
          >
            { I18n.t('general.refresh') }
          </Button>
      </Col>
      <Col>
          {showBetaFeature && (
              <Button
                onClick={openToggleColoumns}
                type="primary"
                size="small"
                icon="eye"
              >
                Columns
              </Button>
            )}
            </Col>
      {/* { currentOrg.code == 'TA' &&
        <Col>
          <SwitchComponent
            handleChange={(value) => handleBetaSwitchChange(value)}
            isChecked={
              showBetaFeature
            }
            checkedChildren={"Beta"}
            unCheckedChildren={"Beta"}
            checkedValue={true}
            unCheckedValue={false}
          />
        </Col>
      } */}
    </Row>
    <Row gutter={ 8 } type='flex' justify='end' align='middle'>
      <Col>
        <Button
          type="danger"
          style={ { width: 'auto' } }
          size="small"
          disabled={ unassignedOrdersInfo.selectedOrderKeys.length === 0 }
          onClick={ resetSelectedOrders }
        >
          { I18n.t('general.clear') }
        </Button>
      </Col>
      <Col>
        <Dropdown overlay={ menu }>
          <Button type='primary' size='small'>
            Actions <Icon type="down" />
          </Button>
        </Dropdown>
      </Col>
      <Col>
        <ViewToggleButton
          initialView={ currentView }
          onViewToggle={ handleViewToggle }
          viewOptions={ viewOptions }
        />
      </Col>
    </Row>
  </Row>
);

export default PreplanHeader;
