import React, { Fragment} from 'react'
import { Col, Icon, Row, Table,Link } from '../../common/UIComponents'
import I18n from '../../common/I18n'
import moment from 'moment/moment'
import { handlePublicDownloads } from '../../helpers/common'
import BaseList from '../BaseList'
import { isEmpty } from '../../common/Common'

function NestedTable({historyData,handleEditOrder,pagination,tableChange}) {
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: I18n.t('order.upload_orders.upload_rowno'),
        dataIndex: 'row_number',
        key: 'row_number',
        width: 80,
      },
      {
        title: I18n.t('order.upload_orders.upload_orderNo'),
        dataIndex: 'order_number',
        key: 'orderNumber',
        width: 100,
      },
      {
        title: I18n.t('order.upload_orders.upload_reason'),
        dataIndex: 'error_messages',
        key: 'error_messages',
        render: (errors) => <Fragment>
          {errors ? errors.join(', ') : 'NA'}</Fragment>
      },
    ]

    const response =  record?.order_response ?  record.order_response : {}
    const successOrders = response?.success_orders ? response.success_orders : [];
    const failedOrders = response?.failed_orders ? response.failed_orders : [];
    return (
      <div>
        <Row type="flex" gutter={30}>
          <Col>
            <h3>{I18n.t('order.upload_orders.upload_succeedOrder')}:</h3>
          </Col>
          <Col>
          {successOrders.length > 0 ? 
          successOrders.map((order) =>
           (<Link onClick={ () => handleEditOrder(order.order_id)}>{order.order_number}{', '}</Link>)) : 'NA'}
          </Col>
        </Row>
        <h3>{I18n.t('order.upload_orders.upload_failedOrder')}:</h3>
        <Table columns={columns} dataSource={failedOrders} pagination={false} size="small"></Table>
      </div>
    )
  }

  const handleDownload = (filePath) => {
    window.open(filePath, "_blank");
  };

  const columns = [
    {
      title: I18n.t('order.upload_orders.upload_file'),
      dataIndex: 'file_name',
      key: 'file_name',
      render :(data, record) => {
        return (
          <Fragment>
          <span>{data}</span>
          &nbsp;&nbsp;
          { !isEmpty(record.file) && <Icon style={{color:"#003eb3"}}  type="download" onClick={()=>handleDownload(record.file)} />}
        </Fragment>
        )
      }
    },
    {
      title: I18n.t('order.upload_orders.upload_fileType'),
      dataIndex: 'file_extension',
      key: 'file_extension',
    },
    {
      title: I18n.t('order.upload_orders.upload_warehouse'),
      dataIndex: 'warehouse_code',
      key: 'warehouse_code',
    },
    {
      title: I18n.t('order.upload_orders.upload_account'),
      dataIndex: 'account_code',
      key: 'account_code',
    },
    {
      title: I18n.t('order.upload_orders.upload_succeedOrder'),
      dataIndex: 'succeed_orders_count',
      key: 'succeed_orders_count ',
    },
    {
      title: I18n.t('order.upload_orders.upload_failedOrder'),
      dataIndex: 'failed_orders_count',
      key: 'failed_orders_count',
    },
     {
      title: I18n.t('order.upload_orders.upload_date'),
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
      render :(data) => {
        return data ? moment(data).format("MMM DD, YYYY hh:mm A"):''
      }
    },
  ]

  return (
    <BaseList
      columns={columns}
      expandedRowRender={expandedRowRender}
      pagination={pagination}
      tableChange = {tableChange}
      data={historyData}
      size="small"
    />
  )
}
export default NestedTable
