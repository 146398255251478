import React, { Component, Fragment, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import _ from 'lodash'
import {
  Icon,
  Button,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Tooltip,
  message,
  Radio,
  Spin,
} from '../../common/UIComponents'
import logo from '../../../assets/images/logo-thumbnail.png'
import {
  isEmpty,
  alertMessage,
  checkNegitive,
  base64ToPdf,
} from '../../common/Common'
import I18n from '../../common/I18n'
import BaseModal from '../BaseModal'
import SummaryList from '../../containers/SummaryList'
import { exportInvoiceAsExcel, exportInvoiceAsPdf, fetchSummaryDetails } from '../../api/BillingScreenAPI'
import userStore from '../../stores/UserStore'
import AddressInfo from '../common/AddressInfo'
import { momentTime } from '../../helpers/date_functions'
import AppConfig from '../../config/AppConfig'
import {
  alignCenter,
  alignLeft,
  invoiceHeading,
  marginTop10,
  smallContent,
  textBold,
} from '../../helpers/invoice_styles'
import { ApiUrl } from '../../api/ApiUtils'
import ExportXLSX from '../common/exports/ExportXLSX'
import ExportReactCSV from '../common/exports/ExportReactCSV'
import { checkElementValueExists } from '../../helpers/orders'
import { base64ToExcel, dynamicFileDownload, renderAlertMessage } from '../../helpers/common';
import DisplayTime from '../common/DisplayTime'
import moment from 'moment'
import { UserContext } from '../../context/UserContext';
import SendInvoiceMail from './SendInvoiceMail'
import { getContactEmails, getEmailSubjectLine } from '../../api/Billing'
import { OrgContext } from '../../context/OrgContext'

const { Option } = Select
class ShowSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: this.props.account,
      invoice: this.props.invoice || {},
      showForm: false,
      summaryData: [],
      inProgress: false,
      organization: {},
      logo,
      exportLoading: false,
      isExportTo: "PDF",
      contacts:[],
      isEmailLoading: false,
      description: "",
      subject: ""
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if(!_.isEqual(prevProps.invoice, this.props.invoice)){
      this.setState({invoice: this.props.invoice})
    }
  }

  getAccountEmails = () => {
      this.setState({ isEmailLoading: true });
      getContactEmails(this.state.account).then((result) => {
        if (result.success) {
          this.setState({
            contacts: result.contact_emails || [],
            isEmailLoading: false,
          });
        } else {
          alertMessage(result.errors[0], "error", 10);
          this.setState({ isEmailLoading: false, contacts: [] });
        }
      });
    };

  getEmailSubject = async () => {
    const { organizationSettings, currentOrg, invoice } = this.props;
    const { invoice_body_text } = organizationSettings;
    const { invoice_number: invoice_no } = invoice;
    const payload = {
      organization_id: currentOrg.id,
      invoice_number: invoice_no,
      setting_name: "invoice_email_subject",
    };

    const defaultSubject = `${invoice_no} Invoice`;

    try {
      const result = await getEmailSubjectLine(payload);

      if (result.success) {
        const emailSubject = result?.subject ? result.subject : defaultSubject;
        this.setState({
          subject: emailSubject,
        });
      } else {
        this.setState({
          subject: defaultSubject,
        });
        alertMessage(result.errors[0], "error", 10);
      }
    } catch (error) {
      console.error("Error fetching email subject:", error);
    } finally {
      this.setState({
        showEmailModal: true,
        description:
          invoice_body_text ||
          I18n.t("configurations.default_email_body_message"),
      });
    }
  };

  getSummaryInfo = () => {
    this.setState({ inProgress: true })
    const data = {
      invoice_number: this.state.invoice.invoice_number,
      account_id: this.state.account,
      organization_id: userStore.getStateValue('selectedOrg'),
    }
    fetchSummaryDetails(data).then((result) => {
      if (result.success) {
        const details = result.details || [];
        const summaryData = details.order_invoices || [];
        this.setState(
          {
            summaryData: summaryData.map(record => (
              Object.assign({}, record,{
                service_date: record.service_date ? momentTime(AppConfig.dateFormat, record.service_date, record.time_zone_id) : '',
                total_invoice: checkNegitive(record.total_invoice),
              })
            )),
            organization: details.organization_details || {},
            inProgress: false,
          },
          () => {
            this.showSummaryInfo()
            this.getEmailSubject()
            this.getAccountEmails()
          },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  showSummaryInfo = () => {
    this.setState({
      showForm: true,
    })
  }

  handleOnModalClose = () => {
    this.setState({
      showForm: false,
    })
  }

  setRef = (ref) => (this.componentRef = ref)

  handleExportChange = (exportAs) => {
    if (!isEmpty(exportAs)) {
      let params = ''
      const data = {
        invoice_number: this.state.invoice.invoice_number,
        account_id: this.state.account,
        organization_id: userStore.getStateValue('selectedOrg'),
        export_as: exportAs,
      }
      const parameter = Object.keys(data).map((key) => `${key}=${data[key]}`)
      params = parameter.join('&')
      params = params ? `?${params}` : ''
      const url = ApiUrl(
        `v2/billing/summery_order_invoices_csv${params}`,
      )
      if(exportAs === 'CSV'){
        dynamicFileDownload(url , {} , null , 
          "get").then((response) => {
        });
      } else if(exportAs === 'XLS'){
        this.setState({ exportLoading: true })
        message.loading({
          key: "export",
          content: I18n.t("messages.exporting"),
        });
        exportInvoiceAsExcel(url).then((result) => {
          if(result.success) {
            const responseMessage = result.data?.message || "";
            if (isEmpty(responseMessage) && result?.data?.file) {
              base64ToExcel(
                result.data.file,
                result.data.file_name || `${moment().format("MM-DD-YYYY_hh-mm-ss")}_open_blance_report`
              );
              this.setState({ exportLoading: false });
              message.success({
                key: "export",
                content: I18n.t("messages.exporting_success"),
                duration: 5
              });
    
            }
            else {
              message.error({
                key: "export",
                content: responseMessage,
                duration: 10
              });
              this.setState({ exportLoading: false })
            }
          } else {
            message.error({
              key: "export",
              content: result.errors,
              duration: 10
            });
            this.setState({exportLoading: false})
          }
        })
      } else {
        this.setState({ exportLoading: true })
        message.loading({
          key: "export",
          content: I18n.t("messages.exporting"),
        });
        exportInvoiceAsPdf(url).then((result) => {
          if(result.success) {
            const responseMessage = result.data?.message || "";
            if (isEmpty(responseMessage) && result?.data?.file) {
              base64ToPdf(
                result.data.file,
                result.data.file_name || `${moment().format("MM-DD-YYYY_hh-mm-ss")}_open_blance_report`
              );
              this.setState({ exportLoading: false });
              message.success({
                key: "export",
                content: I18n.t("messages.exporting_success"),
                duration: 5
              });
    
            }
            else {
              message.error({
                key: "export",
                content: responseMessage,
                duration: 10
              });
              this.setState({ exportLoading: false })
            }
          } else {
            message.error({
              key: "export",
              content: result.errors,
              duration: 10
            });
            this.setState({exportLoading: false})
          }
        })
      }
      
    }
  }

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }

  renderExportRow = () => {
    return (
      <Row style={{ justifyContent: "flex-end", textAlign: "right" }}>
        <Col xs={11} style={{ fontWeight: 700, textAlign: "right" }}>
          Export/Send As :
        </Col>
        <Col xs={7} style={{ fontWeight: 700, textAlign: "right" }}>
          <Radio.Group
            onChange={(e) => this.handleOnChange("isExportTo", e.target.value)}
            value={this.state.isExportTo}
          >
            <Radio value="PDF">PDF</Radio>
            <Radio value="XLS">EXCEL</Radio>
            <Radio value="CSV">CSV</Radio>
          </Radio.Group>
        </Col>
        <Col xs={5} style={{ textAlign: "right" }}>
          <Row type="flex" gutter={4} style={{flexFlow: "row"}}>
            <Col style={{ marginRight: 10 }}>
              <Button type="primary" icon="download" onClick={() => this.handleExportChange(this.state.isExportTo)} size="small"> Download </Button>
            </Col>
           <Col>
                <SendInvoiceMail
                  account={this.state.account}
                  contacts={this.state.contacts}
                  isEmailLoading={this.state.isEmailLoading}
                  invoice_no={this.state.invoice?.invoice_number || ""}
                  exportAs={this.state.isExportTo}
                  emailSubject={this.state.subject}
                  emailDescription={this.state.description}
                  isDisabled={!["PDF"].includes(this.state.isExportTo)}
                />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  renderFormModal = () => {
    const title = `${I18n.t('invoices.label')} #${
      this.state.invoice.invoice_number
    }`;
    const invoice_date_type = !_.isEmpty(this.props.userContext.orgRules.org_billing_rules.invoice_date_type) ? this.props.userContext.orgRules.org_billing_rules.invoice_date_type : "";
    const summaryData = this.state.summaryData ? [...this.state.summaryData] : [];
    const orgDetails = this.state.organization || {};
    const hasHawb = checkElementValueExists(this.state.summaryData, 'hawb');
    const hasMawb = checkElementValueExists(this.state.summaryData, 'mawb');
    const wscols = [
      {
        wch: Math.max(
          summaryData.map((record) => record.invoice_number ? record.invoice_number.length : 5),
        ),
      },
      {
        wch: Math.max(
          summaryData.map((record) => !isEmpty(record.service_date) ? record.service_date.length : 5),
        ),
      },
      {
        wch: Math.max(
          summaryData.map((record) => !isEmpty(record.customer_name) ? record.customer_name.length : 5),
        ),
      },
      {
        wch: Math.max(
          summaryData.map((record) => !isEmpty(record.total_invoice) ? record.total_invoice.length : 5),
        ),
      },
      {
        wch:
          Math.max(summaryData.map((record) => !isEmpty(record.job_number) ? record.job_number.length : 5)) +
          3,
      },
      {
        wch:
          Math.max(summaryData.map((record) => !isEmpty(record.customer_order_reference_number) ? record.customer_order_reference_number.length : 5)) +
          4,
      },
      {
        wch: 16,
      }
    ]
    const headers = [
      { label: 'Invoice Number', key: 'invoice_number' },
      { label: I18n.t('invoices.service_date'), key: 'service_date' },
      { label: I18n.t('customer.name'), key: 'customer_name' },
      {
        label: `${I18n.t('invoices.label')} ${I18n.t('invoices.total')}`,
        key: 'total_invoice',
      },
      { label: I18n.t('invoices.job_number'), key: 'job_number' },
      { 
        label: I18n.t('invoices.customer_order_reference_number'), 
        key: 'customer_order_reference_number' 
      },
      { 
        label: I18n.t('invoices.is_smp_applied'), 
        key: 'is_smp_applied'
      }
    ]
    if(hasMawb){
      headers.push({ 
        label: I18n.t('general.mawb'), 
        key: 'mawb' 
      });
      wscols.push({
        wch:
          Math.max(summaryData.map((record) => !isEmpty(record.mawb) ? record.mawb.length : 5)) +
          5,
      })
    }
    if(hasHawb){
      headers.push({ 
        label: I18n.t('general.hawb'), 
        key: 'hawb' 
      });
      wscols.push({
        wch:
          Math.max(summaryData.map((record) => !isEmpty(record.hawb) ? record.hawb.length : 5)) +
          6,
      })
    }
    const menu = (
      <Menu onClick={e=> this.handleExportChange(e.key)}>
        {Object.keys(AppConfig.exportOptions).map((key) =>
            <Menu.Item key={key}>{AppConfig.exportOptions[key]}</Menu.Item>
          )}
          {/* : (
            <Menu.Item key={key}>
              <ExportXLSX
                csvData={summaryData}
                fileName={this.state.invoice && this.state.invoice.invoice_number ? this.state.invoice.invoice_number : "Invoice"}
                wscols={wscols}
                headers={headers}
              >
                {AppConfig.exportOptions[key]}
              </ExportXLSX>
            </Menu.Item>
          ),
        )} */}
      </Menu>
    )
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        style={{ top: 30 }}
        width="70%"
      >
        <Spin spinning={this.state.exportLoading}>
        <div className="invoice-box-sibiling marginBottom10">
          <Row>
            <Col>
              {this.renderExportRow()}
              {/* <Dropdown overlay={menu} loading={this.state.inProgress}>
                <Button>
                  {I18n.t('general.export')} <Icon type="down" />
                </Button>
              </Dropdown> */}
              {/* <ExportReactCSV
                csvHeaders={headers}
                csvData={summaryData}
                fileName="Customers_Infomations_csv.csv"
              >
                <img src={csv} style={{ width: 20 }} />
              </ExportReactCSV> */}
            </Col>
          </Row>
        </div>
        <div ref={this.setRef} className="marginTop10">
          <div className="invoice-box" style={{ maxWidth: 850 }}>
            <div className="summary_details">
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={this.state.organization.logo || logo}
                        alt=""
                        style={{ maxWidth: "10rem" }}
                      />
                      <small>
                        <AddressInfo
                          address={(orgDetails && orgDetails.address) || {}}
                          showIcon
                          displayType="line"
                        />
                      </small>
                    </td>
                    <td width="46%" style={{ ...smallContent }}>
                      <h2
                        style={{
                          ...alignCenter,
                          ...invoiceHeading,
                          ...textBold,
                        }}
                      >
                        INVOICE
                      </h2>
                      <table
                        cellpadding="2px"
                        cellspacing="0px"
                        width="100%"
                        style={{ ...alignLeft, ...marginTop10 }}
                      >
                        <tbody>
                          <tr>
                            <td width="30%" style={{ ...alignLeft }}>
                              {I18n.t("invoices.invoice_date")}
                            </td>
                            <td>:</td>
                            <td width="68%">
                              {
                                <DisplayTime
                                  dateTimeString={
                                    this.props.status == "APPROVED" &&
                                    !_.isEmpty(invoice_date_type) &&
                                    invoice_date_type == "invoice_approval_date"
                                      ? this.state.invoice.invoice_approval_date
                                      : this.state.invoice.billing_date
                                  }
                                  timeFormat={AppConfig.dateFormat}
                                  displayWithDate={true}
                                  isUTC={true}
                                />
                              }
                              {/* {momentTime(
                                AppConfig.dateFormat,
                                this.state.invoice.billing_date,FLTNBE-6624-invoice-date-type
                              )}{' '} */}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ ...alignLeft }}>
                              {I18n.t("invoices.number")}
                            </td>
                            <td>:</td>
                            <td>{this.state.invoice.invoice_number}</td>
                          </tr>
                          <tr>
                            <td style={{ ...alignLeft }}>
                              {I18n.t("invoices.total_amount")}
                            </td>
                            <td>:</td>
                            <td>
                              {checkNegitive(this.state.invoice.invoice_amount)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <SummaryList
                data={this.state.summaryData}
                accountId={this.state.account}
                invoiceNo={this.state.invoice.invoice_number}
                pagination={{ position: "none" }}
                hasHawb={hasHawb}
                hasMawb={hasMawb}
                bordered
                size="small"
                id="table-to-xls"
              />
            </div>
          </div>
        </div>
        </Spin>
      </BaseModal>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Tooltip title={I18n.t('general.views.summary')}>
          <Icon
            type="table"
            className="TableIcon"
            onClick={this.getSummaryInfo}
            alt={I18n.t('general.expand')}
            style={{ fontSize: 17 }}
          />
        </Tooltip>
        {this.state.showForm && this.renderFormModal()}
      </React.Fragment>
    )
  }
}


export default withRouter((props) => {
  const usrContext = useContext(UserContext);
  const { orgSettings: organizationSettings, currentOrg } = useContext(OrgContext);
  return (
    <ShowSummary
      userContext={usrContext}
      organizationSettings={organizationSettings}
      currentOrg={currentOrg}
      {...props}
    />
  );
});
