/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Col, FormItem, Row, DatePicker, Select, Radio, Button, Spin, MaterialFormItem,TreeSelect } from '../../common/UIComponents';
import {  alertMessage, isEmpty, doValidate } from '../../common/Common';
import I18n from '../../common/I18n';
import { createPreplanInitial, fetchAvailableDrivers } from '../../api/PreplanApi';
import FormErrors from '../common/FormErrors';
import AppConfig from '../../config/AppConfig';
import { fetchLocations } from '../../api/LocationsApi';
import UsersList from './rows/UsersList';
import { fetchOrganizationConfigs } from '../../api/Organisations';
import userStore from '../../stores/UserStore';
import { fetchAllDeliveryZones } from '../../api/DeliveryZones';
import { getAllDisabledHours, getAllDisabledMinutes, getCurrentTime, isPastTime } from '../../helpers/date_functions';
import { checkServiceExistance, getOperationalTimings } from '../../helpers/common';
import MaterialTimePicker from '../orders/MaterialTimePicker';
import { WarehouseContext } from '../../context/WarehouseContext';
import { OrgContext } from '../../context/OrgContext';
const { TreeNode } = TreeSelect;

const commonRequiredFields = [{
  form_field: 'startDate',
  ui_name: I18n.t('preplan.deliverable_start_date'),
  isRequired: true,
},
{
  form_field: 'startTime',
  ui_name: I18n.t('info.start_time'),
  isRequired: true,
},
{
  form_field: 'warehouse_id',
  ui_name: I18n.t('location.location'),
  isRequired: true,
},
];

const defaultVlaues = {
  preplan_type: 'single_day',
  startDate: null, // moment(moment().add(AppConfig.preplanShownDuration, 'hours'), 'YYYY-MM-DD')
  endDate: null, // moment(moment().add(AppConfig.preplanShownDuration, 'hours'), 'YYYY-MM-DD'),
  optimize_type: 'manual',
  fleet_type: 'driver',
  distribution_type: 'none',
  zone_wise_distribution: false,
};

class Form extends Component {
  // static contextType = OrganizationSettingsContext;
  constructor (props) {
    super(props);
    this.state = {
      currentPreplan: this.props.currentPreplan && !_.isEmpty(this.props.currentPreplan) ? this.props.currentPreplan :
        _.cloneDeep(defaultVlaues),
      isNew: !isEmpty(this.props.isNew) ? this.props.isNew : true,
      inProgress: false,
      errors: [],
      warehouses: [],
      preplanId: '',
      checkedKeys: {
        selectedKeys: '',
        selectedRows: [],
      },
      users: [],
      usersForFilter: [],
      config: {},
      zones: [],
      warehouseZones: [],
      isZonesLoading: false,
      currentWarehouse: {},
      warehouseProgress: false,
      organizationSettings: {},
      isMilitaryTime: false,
      deliveryTimings: {
        startTime: "",
        endTime: "",
      },
      // selectedVehicle:""
      selectedVehicle:[],
    };
  }

  componentDidMount () {
    this.getOrganizationConfigs();
    this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
    if(prevState.currentWarehouse.id !== this.state.currentWarehouse.id){
      this.setPreplanTimings();
    }
  }
  setPreplanTimings = (checkDates = false) => {
    const { organizationSettings, currentPreplan } = this.state;
    const selectedWh = this.state.warehouses.find(wh => wh.id === this.state.currentPreplan.warehouse_id);
    let { start_time = "", end_time = "" } = getOperationalTimings(organizationSettings, selectedWh)
    this.setState({
      deliveryTimings: {
        startTime: start_time, 
        endTime: end_time, 
      }
    })
    if(isEmpty(currentPreplan.startTime) && isEmpty(currentPreplan.endTime)){
      const updatedPreplan =  Object.assign({}, this.state.currentPreplan, {
        startTime: start_time, 
        endTime: end_time, 
      })
      if(checkDates){
        const startDate = selectedWh?.timeZoneId ? moment.tz(`${moment.tz(selectedWh.timeZoneId).format('YYYY-MM-DD')} ${start_time}`, selectedWh.timeZoneId) : moment(`${moment().format('YYYY-MM-DD')} ${start_time}`);
        const endDate = selectedWh?.timeZoneId ? moment.tz(`${moment.tz(selectedWh.timeZoneId).format('YYYY-MM-DD')} ${end_time}`, selectedWh.timeZoneId) : moment(`${moment().format('YYYY-MM-DD')} ${end_time}`);
        updatedPreplan.startDate = startDate;
        updatedPreplan.endDate = endDate;
      }
      this.setState({
        currentPreplan: updatedPreplan,
      },() => {
        this.getAvailableDrivers()
      });
    } else {
      if(checkDates){
        const startDate = selectedWh?.timeZoneId ? moment.tz(`${moment.tz(selectedWh.timeZoneId).format('YYYY-MM-DD')} ${start_time}`, selectedWh.timeZoneId) : moment(`${moment().format('YYYY-MM-DD')} ${start_time}`);
        const endDate = selectedWh?.timeZoneId ? moment.tz(`${moment.tz(selectedWh.timeZoneId).format('YYYY-MM-DD')} ${end_time}`, selectedWh.timeZoneId) : moment(`${moment().format('YYYY-MM-DD')} ${end_time}`);
        this.setState({
          currentPreplan: Object.assign({}, this.state.currentPreplan, {
            startDate,
            endDate,
          }),
        }, () => {
          if(checkDates){
            this.getAvailableDrivers()
          }
        });
      }
    }
  };
  getAllZones () {
    this.setState({ isZonesLoading: true });
    fetchAllDeliveryZones(true)
      .then((result) => {
        if (result.success) {
          this.setState({ zones: result.zones, isZonesLoading: false }, () => {
            if (!this.state.isNew) {
              this.removedOrphanIds();
            }
          });
        } else {
          alertMessage(result.errors[ 0 ], 'error', 10);
          this.setState({ isZonesLoading: false });
        }
        this.getWarehouses();
      });
  }

  removedOrphanIds = () => {
    const { currentPreplan } = this.state;
    const zones = this.state.zones.map(zone => zone.id);
    if (!isEmpty(currentPreplan.zone_ids) && currentPreplan.zone_ids.length > 0) {
      const preplanZones = currentPreplan.zone_ids.filter(zone => zones.includes(zone));
      this.handleOnChange('zone_ids', preplanZones.length === zones.length ? [ 'ALL' ] : preplanZones);
    } else {
      this.handleOnChange('zone_ids', []);
    }
  };

  getOrganizationConfigs = () => {
    this.setState({ inProgress: true });
    const { config } = this.state;
    const orgId = userStore.getStateValue('selectedOrg');
    fetchOrganizationConfigs(orgId)
      .then((result) => {
        if (result.success) {
          result.org_configurations.forEach((setting) => {
            config[ setting.setting_name ] = setting.setting_value;
          });
          this.setState({
            inProgress: false,
            config,
          }, () => {
            this.getAllZones();
           //  this.setOrgSettings();
          });
        } else {
          alertMessage(result.errors[ 0 ], 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  };

  setOrgSettings = () => {
    this.setState({
      currentPreplan: Object.assign({}, this.state.currentPreplan, {
        startTime: this.state.config.route_delivery_start_time, // isFutureTime(this.state.config.route_delivery_start_time) ? this.state.config.route_delivery_start_time : null,
        endTime: this.state.config.route_delivery_end_time, // isFutureTime(this.state.config.route_delivery_end_time) ? this.state.config.route_delivery_end_time : null,
      }),
    }, () => {
      // this.getAvailableDrivers();
    });
  };

  getWarehouses = () => {
    this.setState({ warehouseProgress: true });
    fetchLocations(AppConfig.warehouseCode, 1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            warehouses: result.locations.locations,
          }, () => {
            if (!this.state.preplanId && this.state.warehouses.length > 0) {
              const currentWarehouse = typeof this.props.warehouseFilter.selectedWarehouses === 'string' ? _.find(this.props.warehouseFilter.warehouses, {id: this.props.warehouseFilter.selectedWarehouses}) : _.find(this.props.warehouseFilter.warehouses, {id: this.props.warehouseFilter.selectedWarehouses[0]});
              const warehouseZones = this.getWarehouseZones(currentWarehouse.id);
              // const startDate = moment.tz(`${moment.tz(currentWarehouse.timeZoneId).format('YYYY-MM-DD')} ${this.state.currentPreplan.startTime}`, currentWarehouse.timeZoneId);
              // const endDate = moment.tz(`${moment.tz(currentWarehouse.timeZoneId).format('YYYY-MM-DD')} ${this.state.currentPreplan.endTime}`, currentWarehouse.timeZoneId);
              this.setState({
                warehouseZones,
                currentWarehouse,
                currentPreplan: Object.assign({}, this.state.currentPreplan, {
                  warehouse_id: currentWarehouse.id,
                  // startDate,
                  // endDate,
                }),
                warehouseProgress: false,
              }, () => {
                this.setPreplanTimings(true)
                //this.getAvailableDrivers();
              });
            } else {
              this.setState({ warehouseProgress: false });
            }
          });
        } else {
          this.setState({ warehouseProgress: false });
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      });
  };

  clearForm = () => {
    const { warehouses } = this.state;
    const currentPreplan = Object.assign({}, _.cloneDeep(defaultVlaues), {
      startDate: null,
      endDate: null,
    });
    if (warehouses.length > 0) {
      const currentWarehouse = typeof this.props.warehouseFilter.selectedWarehouses === 'string' ? _.find(this.props.warehouseFilter.warehouses, {id: this.props.warehouseFilter.selectedWarehouses}) : _.find(this.props.warehouseFilter.warehouses, {id: this.props.warehouseFilter.selectedWarehouses[0]});
      currentPreplan.warehouse_id = currentWarehouse.id;
      // currentPreplan.startDate = moment.tz(`${moment.tz(currentWarehouse.timeZoneId).format('YYYY-MM-DD')} ${this.state.deliveryTimings.startTime}`, currentWarehouse.timeZoneId);
      // currentPreplan.endDate = moment.tz(`${moment.tz(currentWarehouse.timeZoneId).format('YYYY-MM-DD')} ${this.state.deliveryTimings.endTime}`, currentWarehouse.timeZoneId);
    }

    this.setState({
      currentPreplan,
      isNew: true,
      checkedKeys: {
        selectedKeys: '',
        selectedRows: [],
      },
    }, () => {
      this.setPreplanTimings(true);
    });
  };

  getWarehouseZones = (warehouse_id) => {
    const { zones } = this.state;
    return zones.filter(zone => zone.warehouse_id === warehouse_id);
  };

  handleOnChange = (element, value) => {
    const { currentPreplan, zones, warehouses,selectedVehicle } = this.state;

    if (element === 'distribution_type') {
      this.setState({
        currentPreplan: Object.assign({}, this.state.currentPreplan, {
          zone_ids: [],
          zone_wise_distribution: value === 'zones',
          enable_distribution: value === 'orders',
          [ element ]: value,
        }),
      }, () => {
        if (value === 'none') {
          this.getAvailableDrivers();
        }
      });
    } else if (element === 'startDate') {
      const {
        startTime, endDate, endTime,
      } = this.state.currentPreplan;
      const { currentWarehouse } = this.state;
      value = !isEmpty(value) ? moment.tz(`${value.format('YYYY-MM-DD')} ${startTime}`, currentWarehouse.timeZoneId) : moment(moment().add(AppConfig.preplanShownDuration, 'hours'), 'YYYY-MM-DD').tz(currentWarehouse.timeZoneId);
      const endDateTime = moment.tz(`${value.format('YYYY-MM-DD')} ${endTime}`, currentWarehouse.timeZoneId).format();
      this.setState({
        currentPreplan: Object.assign({}, this.state.currentPreplan, {
          startDate: value,
          endDate: moment(value).isSameOrBefore(endDateTime) ? moment(new Date(endDateTime)) : value,
          // moment(value).isSameOrBefore(currentPreplan.endDate) ? moment(new Date(currentPreplan.endDate)) : value
        }),
      }, () => {
        this.getAvailableDrivers();
      });
    } else if (element === 'startTime') {
      const {
        startDate, endDate, endTime,
      } = this.state.currentPreplan;
      value = !isEmpty(value) ? value : moment(this.state.deliveryTimings.startTime, 'HH:mm');
      const startDateTime = moment(`${startDate.format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format();
      const endDateTime = moment(`${endDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm').format();
      const isValidEndTime = moment(startDateTime).isSameOrBefore(endDateTime);
      this.setState({
        currentPreplan: Object.assign({}, this.state.currentPreplan, {
          [ element ]: moment(value).format('HH:mm'),
          endTime: isValidEndTime ? endTime : '23:59',
        }),
      }, () => {
        this.getAvailableDrivers();
      });
    } else if (element === 'endTime') {
      const {
        startDate, endDate, startTime,
      } = this.state.currentPreplan;
      value = !isEmpty(value) ? value : moment(this.state.deliveryTimings.endTime, 'HH:mm');
      const startDateTime = moment(`${startDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm').format();
      const endDateTime = moment(`${endDate.format('YYYY-MM-DD')} ${moment(value).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format();
      const isValidEndTime = moment(startDateTime).isSameOrBefore(endDateTime);
      this.setState({
        currentPreplan: Object.assign({}, this.state.currentPreplan, {
          [ element ]: isValidEndTime ? moment(value).format('HH:mm') : '23:59',
        }),
      }, () => {
        this.getAvailableDrivers();
      });
    } else if (element === 'warehouse_id') {
      const warehouseZones = this.getWarehouseZones(value);
      const currentWarehouse = _.find(warehouses, { id: value });
      const isCurrentWarehouse = warehouseZones.find(zone => zone.warehouse_id === value);
      // this.setState({ warehouseZones: warehouseZones, isZonesLoading: false }, () => {
      //   if (!this.state.isNew) {
      //     this.removedOrphanIds();
      //   }
      // });
      // let startDate = moment.tz(`${moment.tz(selectedWh.timeZoneId).format('YYYY-MM-DD')} ${start_time}`, selectedWh.timeZoneId);

      let startDate = moment(`${currentPreplan.startDate.format('YYYY-MM-DD')} ${currentPreplan.startTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
      const isPast = isPastTime(startDate, currentWarehouse.timeZoneId);
      let endDate = null;
      if (!isPast) {
        startDate = currentPreplan.startDate;
        endDate = currentPreplan.endDate;
      } else {
        startDate = getCurrentTime(currentWarehouse.timeZoneId);
        endDate = getCurrentTime(currentWarehouse.timeZoneId);
      }
      if (isCurrentWarehouse !== undefined && isCurrentWarehouse.warehouse_id !== this.state.currentPreplan.warehouse_id) {
        this.setState({
          warehouseZones,
          isZonesLoading: false,
          currentPreplan: Object.assign({}, this.state.currentPreplan, {
            zone_ids: [],
            [ element ]: value,
            startDate,
            endDate,
          }),
          currentWarehouse: !isEmpty(currentWarehouse) ? currentWarehouse : {},
        }, () => {
          this.getAvailableDrivers();
          if (!this.state.isNew) {
            this.removedOrphanIds();
          }
        });
      } else if (isCurrentWarehouse !== undefined) {
        this.setState({
          warehouseZones,
          isZonesLoading: false,
          currentPreplan: Object.assign({}, this.state.currentPreplan, {
            [ element ]: value,
            startDate,
            endDate,
          }),
          currentWarehouse: !isEmpty(currentWarehouse) ? currentWarehouse : {},
        }, () => {
          this.getAvailableDrivers();
          if (!this.state.isNew) {
            this.removedOrphanIds();
          }
        });
      } else {
        this.setState({
          warehouseZones,
          isZonesLoading: false,
          currentPreplan: Object.assign({}, this.state.currentPreplan, {
            zone_ids: [],
            [ element ]: value,
            startDate,
            endDate,
          }),
          currentWarehouse: !isEmpty(currentWarehouse) ? currentWarehouse : {},
        }, () => {
          this.getAvailableDrivers();
          if (!this.state.isNew) {
            this.removedOrphanIds();
          }
        });
      }
    } else if (element === 'zone_ids') {
      if (value.includes('ALL') && currentPreplan.zone_ids !== undefined && currentPreplan.zone_ids.length === this.state.warehouseZones.length) {
        value = [];
      } else if (value.includes('ALL')) {
        const warehouseZones = this.getWarehouseZones(this.state.currentPreplan.warehouse_id);
        value = warehouseZones.map(zone => zone.id);
      } else if (value.includes(null)) {
        value = [];
      }

      this.setState({
        currentPreplan: Object.assign({}, this.state.currentPreplan, {
          [ element ]: value,
        }),
      }, () => {
        this.getAvailableDrivers();
      });
    }else if (element === 'vehicle_type') {
      // ...................
      // let filteredUsers = this.state.usersForFilter;
      // if (value !== "" && value !== undefined) {
      //   filteredUsers = this.state.usersForFilter.filter((user) => {
      //     return user.fleet_details.vehicle_type === value;
      //   });
      // }else if(value === "" || value === undefined){
      //   filteredUsers
      // }
      // this.setState({
      //   selectedVehicle: value,
      //   users: filteredUsers,
      // });
      // ..............
      // const allSelected = value.includes('');
      // let filteredUsers = this.state.usersForFilter;
      // if (!allSelected && value.length > 0) {
      //   filteredUsers = this.state.usersForFilter.filter((user) => {
      //     return value.includes(user.fleet_details.vehicle_type);
      //   });
      // }

      // this.setState({
      //   selectedVehicle: value,
      //   users: filteredUsers,
      // });
    } 
     else {
      this.setState({
        currentPreplan: Object.assign({}, this.state.currentPreplan, { [ element ]: value }),
      }, () => {
        if (element === 'endDate') {
          this.getAvailableDrivers();
        }
      });
    }
  };
  
  handleVehicleTypeChange = (value) => {
    this.setState({
      selectedVehicle: value,
    });
  };

  handleSearchClick = () => {
    const { selectedVehicle, usersForFilter } = this.state;

    const allSelected = selectedVehicle.includes('');
    let filteredUsers = usersForFilter;

    if (!allSelected && selectedVehicle.length > 0 && _.isArray(selectedVehicle)) {
      filteredUsers = usersForFilter.filter((user) => {
        return user.fleet_details && selectedVehicle.map(rec => rec.toLowerCase()).includes(user.fleet_details.vehicle_type?.toLowerCase());
      });
    }
    this.setState({
      searchClicked: true,
      users: filteredUsers,
    });
  };
  getAvailableDrivers = () => {
    const { currentPreplan } = this.state;
    const {
      startDate, endDate, startTime, endTime, zone_ids, warehouse_id
    } = currentPreplan;
    const startDeliveryTime = moment(`${startDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm').format();
    const endDeliveryTime = moment(`${endDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm').format();
    fetchAvailableDrivers(startDeliveryTime, endDeliveryTime, zone_ids, warehouse_id)
      .then((result) => {
        if (result.success) {
          const users = result.users || [];
          const sortedUsers = _.sortBy(users, 'employee_code');
          this.setState({
            users: sortedUsers,
            usersForFilter: _.cloneDeep(sortedUsers),
            inProgress: false,
          });
        } else {
          this.setState({ inProgress: false });
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      });
  };

  handleSave = () => {
    const {
      startDate, endDate, warehouse_id, startTime, endTime, preplan_type, enable_distribution, driver_stop_existed,
      zone_ids, zone_wise_distribution,
    } = this.state.currentPreplan;
    const { checkedKeys } = this.state;
    const requiredFields = [ ...commonRequiredFields ];
    const errors = doValidate(requiredFields, this.state.currentPreplan);
    if (!isEmpty(startDate) && !isEmpty(startTime)) {
      const deliveryStartDate = `${startDate.format('YYYY-MM-DD')} ${startTime}`;
      if (isPastTime(deliveryStartDate, this.state.currentWarehouse.timeZoneId)) {
        errors.push('Start time should be greater than current time');
      }
    }
    if (zone_wise_distribution === true && (isEmpty(zone_ids) || zone_ids.length === 0)) {
      errors.push(I18n.t('errors.field_required', { field: I18n.t('zones.zone') }));
    }
    if (errors.length === 0) {
      const deliveryStartDate = moment(`${startDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm').format();
      let delivery_zone_ids = [];
      if (zone_ids !== undefined && zone_ids.length > 0 && zone_ids.includes('ALL')) {
        delivery_zone_ids = this.state.zones.map(zone => zone.id);
      } else {
        delivery_zone_ids = zone_ids;
      }

      const data = {
        warehouse_id,
        delivery_start_date: deliveryStartDate,
        delivery_end_date: moment(`${endDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm').format(),
        defaultReadyTime: startTime,
        defaultDueTime: endTime,
        driver_ids: checkedKeys.selectedKeys || [],
        preplan_type,
        enable_distribution: !isEmpty(enable_distribution) ? enable_distribution : false,
        driver_stop_existed: !isEmpty(driver_stop_existed) ? driver_stop_existed : false,
        zone_wise_distribution: !isEmpty(zone_wise_distribution) ? zone_wise_distribution : false,
        zone_ids: delivery_zone_ids,
      };
      if (data.delivery_end_date === 'Invalid date' || data.delivery_start_date === 'Invalid date') {
        alertMessage("Please select valid date and time", 'error', 10);
        return;
      }
      this.setState({ inProgress: false, errors: [] });
      createPreplanInitial(data)
        .then((result) => {
          if (result.success) {
            alertMessage(I18n.t('messages.saved'));
            this.setState({
              preplanId: result.preplan.id,
              currentPreplan: result.preplan,
            }, () => {
              this.editPreplan();
            });
          } else {
            alertMessage(result.errors[ 0 ], 'error', 10);
            this.setState({ inProgress: false, errors: result.errors });
          }
        });
    } else {
      this.setState({ errors });
    }
  };

  editPreplan = () => {
    this.props.history.push({
      pathname: `/preplan/${this.state.currentPreplan.id}`,
      status: this.state.currentPreplan.status,
    });
  };

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      checkedKeys: Object.assign({}, this.state.routes, {
        selectedKeys: selectedRowKeys,
        selectedRows,
      }),
    });
  };

  _renderDrivers = () => {
    const selectedRowKeys = this.state.checkedKeys.selectedKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: record => ({
        disabled: record.id === '1234', // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <div>
        <Row type="flex">
       
         <Col span={24} className="alignRight">
            <span style={{ fontWeight: "bolder", marginTop: 3 }}>
              Vehicle Types:
            </span>
            &nbsp;&nbsp;
            <TreeSelect
              value={this.state.selectedVehicle}
              style={{ width: 360 }} // Adjust the width as needed
              showSearch
              size="small"
              dropdownStyle={{
                maxHeight: 300,
                overflow: "auto",
              }}
              placeholder="--Select--"
              allowClear
              treeDefaultExpandAll
              dropdownMatchSelectWidth={false}
              onChange={this.handleVehicleTypeChange}
              treeCheckable
              maxTagCount={1}
              treeNodeFilterProp="title"
            >
              <TreeNode value="ALL" title="All" key="0-1">
                {this.props.vehicleTypes.map((code) => (
                  <TreeNode
                    value={code.name}
                    title={code.name}
                    key={code.name}
                  />
                ))}
              </TreeNode>
            </TreeSelect>{" "}
            &nbsp;&nbsp;
            <Button style={{height:"25px"}} type="primary" onClick={this.handleSearchClick} icon="search">
              Search
            </Button>
          </Col>             
         
        </Row>
        <FormItem
          label={I18n.t("general.drivers")}
          requireColumn={false}
          require
        >
          <UsersList
            data={this.state.users}
            showStops={false}
            showLinks={false}
            size="small"
            pagination={{ position: "none" }}
            rowSelection={rowSelection}
            scroll={{ y: 322 }}
            showZones={false}
            driverZones={[]}
          />
        </FormItem>
      </div>
    );
  };

  render () {
    const {
      currentPreplan, zones, warehouseZones, currentWarehouse,
    } = this.state;
    const currentTimeZone = currentWarehouse.timeZoneId || '';
    const { isMilitaryTime } = this.state;
    const currentOrg = userStore.getStateValue('currentOrg');
    return (
      <div>
        <Spin spinning={ this.state.warehouseProgress || this.state.inProgress || this.state.isZonesLoading }>
          <Row gutter={ 16 }>
            <Col xs={ 10 }>
              { false &&
                <Col xs={ 24 } md={ 24 }>
                  <FormItem
                    label={ I18n.t('preplan.preplan_type') }
                    requireColumn={ false }
                  >
                    <Radio.Group
                      onChange={ e => this.handleOnChange('preplan_type', e.target.value) }
                      value={ currentPreplan.preplan_type }
                    >
                      {
                        Object.keys(AppConfig.preplanTypes).map(key =>
                        (
                          <Radio
                            value={ key }
                            key={ key }
                          > { AppConfig.preplanTypes[ key ] }
                          </Radio>
                        ))
                      }
                    </Radio.Group>

                  </FormItem>
                </Col>
              }
              <Col xs={ 24 } md={ 24 }>
                <FormItem
                  label={ I18n.t('preplan.deliverable_start_date') }
                  require
                  requireColumn={ false }
                >
                  <DatePicker
                    style={ { width: '100%', marginLeft: '1%' } }
                    placeholder="Select Date"
                    format='Do MMM YYYY'
                    onChange={ e => this.handleOnChange('startDate', e) }
                    value={ currentPreplan.startDate ? currentPreplan.startDate : moment(moment().add(AppConfig.preplanShownDuration, 'hours'), 'YYYY-MM-DD').tz(currentTimeZone) }
                    defaultValue={ null }
                    onOk={ this.onOk }
                    disabledDate={
                      current => current && currentTimeZone && moment(moment(current).format('YYYY-MM-DD')).isBefore(moment().tz(currentTimeZone).format('YYYY-MM-DD')) // moment(moment().add(AppConfig.preplanShownDuration - 24, 'hours'), 'YYYY-MM-DD')
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={24} md={24}>
                <MaterialFormItem
                  label={I18n.t('info.start_time')}
                  require
                  requireColumn={ false }
                  className="timepickerContainer">
                  <MaterialTimePicker
                    style={ { width: '100%', marginLeft: '1%' } }
                    onChange={ e => this.handleOnChange('startTime', e) }
                    value={ currentPreplan.startTime ? moment(currentPreplan.startTime, 'HH:mm') : null }
                    computeDisabledHours={
                      () => getAllDisabledHours(currentPreplan.startDate, [], null, null, currentTimeZone) }
                    isMilitaryTime={ isMilitaryTime }
                    computeDisabledMinutes={
                      pickedTime => {
                        const selectedHour = pickedTime ? pickedTime.hour() : 0;
                        return getAllDisabledMinutes(currentPreplan.startDate, [], selectedHour, null, null, currentTimeZone);
                      }
                    }
                  />
                </MaterialFormItem>
              </Col>
              { currentPreplan.preplan_type === 'multi_day' &&
              <Fragment>
                <Col xs={24} md={24}>
                  <FormItem
                    label={I18n.t('preplan.deliverable_end_date')}
                    require
                    requireColumn={false}
                  >
                    <DatePicker
                      style={{ width: '100%', marginLeft: '1%' }}
                      placeholder="Select Date"
                      format='Do MMM YYYY'
                      onChange={e => this.handleOnChange('endDate', e)}
                      value={
                        moment(currentPreplan.startDate).isSameOrBefore(currentPreplan.endDate) ?
                          moment(new Date(currentPreplan.endDate)) :
                          moment(new Date(currentPreplan.startDate))}
                      defaultValue={null}
                      onOk={this.onOk}
                      disabledDate={
                        currentPreplan.startDate ?
                          current => current && (current <= moment(currentPreplan.startDate, 'YYYY-MM-DD')) :
                          current => current && (current <= moment(moment().add(AppConfig.preplanShownDuration - 24, 'hours'), 'YYYY-MM-DD'))
                      }
                    />
                  </FormItem>
                </Col>
                <Col xs={24} md={24}>
                  <MaterialFormItem
                    label={I18n.t('info.end_time')}
                    require
                    requireColumn={false}
                    className="timepickerContainer"
                  >
                      <MaterialTimePicker
                        style={ { width: '100%', marginLeft: '1%' } }
                        value={ currentPreplan.endTime ? moment(currentPreplan.endTime, 'HH:mm') : null }
                        onChange={ e => this.handleOnChange('endTime', e) }
                        computeDisabledHours={
                          () => [] }
                        isMilitaryTime={ isMilitaryTime }
                        computeDisabledMinutes={
                          () => [] }
                      />
                  </MaterialFormItem>
                </Col>
              </Fragment>
              }
              <Col xs={ 24 } md={ 24 }>
                <FormItem label={ I18n.t('location.location') } requireColumn={ false } require>
                  <Select
                    value={ this.state.currentPreplan.warehouse_id || '' }
                    showSearch
                    style={ { width: '100%' } }
                    filterOption={ (input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={ e => this.handleOnChange('warehouse_id', e) }
                  >
                    { this.state.warehouses.map(warehouse => (
                      <Select.Option key={ warehouse.id } value={ warehouse.id }>
                        { warehouse.name }
                      </Select.Option>
                    )) }
                  </Select>
                </FormItem>
              </Col>
              {/* { currentOrg && currentOrg.code == I18n.t('account.netmove_code') && */ }
              <Col xs={ 24 }>
                <FormItem
                  label={ I18n.t('preplan.distribution') }
                  requireColumn={ false }
                >
                  <Radio.Group
                    onChange={ e => this.handleOnChange('distribution_type', e.target.value) }
                    value={ currentPreplan.distribution_type }
                  >
                    <Radio value="none" key="none">{ I18n.t('general.none') }</Radio>
                    <Radio value="zones" key="zones">{ I18n.t('menu.zones') }</Radio>
                    {/* <Radio value="orders" key="orders">{I18n.t('general.drivers')}</Radio> */ }
                  </Radio.Group>
                  { currentPreplan.distribution_type === 'orders' && <div><span className="textBold notes_text">Notes: </span>&nbsp;<span className="notes_content">{ I18n.t('preplan.enable_distribution_notes') } </span> </div> }
                </FormItem>
              </Col>
              {/* } */ }
              {
                checkServiceExistance('PPAZW') &&
                this.state.currentPreplan.zone_wise_distribution !== undefined && this.state.currentPreplan.zone_wise_distribution === true &&
                <Col xs={ 24 } md={ 24 }>
                  <FormItem label={ I18n.t('zones.zone') } require={ currentPreplan.zone_wise_distribution === true }>
                    <Select
                      value={ currentPreplan.zone_ids }
                      showSearch
                      style={ { width: '100%' } }
                      filterOption={ (input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                      onChange={ e => this.handleOnChange('zone_ids', e) }
                      mode="multiple"
                      loading={ this.state.isZonesLoading }
                      disabled={ this.state.isZonesLoading }
                    >
                      { warehouseZones.length > 0 && <Select.Option key='allSC' value='ALL'>{ I18n.t('general.all') }</Select.Option> }
                      {
                        warehouseZones.length > 0 ? warehouseZones.map(zone => (
                          <Select.Option key={ zone.id } value={ zone.id }>{ zone.name }</Select.Option>
                        )) : <Select.Option key="o1" value={ null }>Please select zones</Select.Option>
                      }
                    </Select>
                  </FormItem>
                </Col>
              }
              <Col xs={ 24 } className="marginTop10">
                <FormItem label="Last stop" requireColumn={ false }>
                  <Radio.Group
                    onChange={ e => this.handleOnChange('driver_stop_existed', e.target.value) }
                    value={ this.state.currentPreplan.driver_stop_existed || false }
                    style={ { marginTop: 3 } }
                  >
                    <Radio value={ false }>Warehouse</Radio>
                    <Radio value>{ I18n.t('preplan.driver_choice') }</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>


              { false &&
                <FormItem
                  label={ I18n.t('preplan.optimize_type') }
                  requireColumn={ false }
                >
                  <Radio.Group
                    onChange={ e => this.handleOnChange('optimize_type', e.target.value) }
                    value={ currentPreplan.optimize_type }
                  >
                    {
                      Object.keys(AppConfig.optimizeTypes).map(key =>
                      (
                        <Radio
                          value={ key }
                          key={ key }
                        > { AppConfig.optimizeTypes[ key ] }
                        </Radio>
                      ))
                    }
                  </Radio.Group>
                </FormItem>
              }
            </Col>
            <Col xs={ 14 }>
              <Col xs={ 24 }>
                <this._renderDrivers />
              </Col>
            </Col>
            <Col xs={ 24 }>
              <Col xs={ 24 }>
                <div className="alignCenter">
                  <Button
                    size="default"
                    type="danger"
                    style={ {
                      width: 'auto',
                    } }
                    onClick={ this.clearForm }
                    icon="close-circle"
                  >{ I18n.t('general.clear') }
                  </Button>&nbsp;&nbsp;
                  <Button
                    size="default"
                    type="primary"
                    className="marginRight5"
                    onClick={ () => this.handleSave() }
                    loading={ this.state.inProgress }
                    disabled={ this.state.checkedKeys.selectedKeys.length === 0 }
                    icon='check-circle'
                  >
                    { this.state.isNew ? I18n.t('general.confirm') : I18n.t('general.update') }
                  </Button>

                </div>

              </Col>

            </Col>
          </Row>
          <Row>
            { FormErrors(this.state.errors) }
          </Row>
        </Spin>
      </div>
    );
  }
}

Form.propTypes = {
  currentPreplan: PropTypes.shape(),
  isNew: PropTypes.bool,
};
Form.defaultProps = {
  currentPreplan: {},
  isNew: true,
};
// export default withRouter(Form);

const PrePlanCreateForm= withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  return (
    <Form
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});

export default PrePlanCreateForm;