import React from 'react'
import BaseList from '../BaseList'
import { checkNegitive, isEmpty } from '../../common/Common'
import moment from 'moment'
import DisplayTime from '../common/DisplayTime'
import I18n from '../../common/I18n'

class Remarks extends BaseList {
  constructor(props) {
    super(props)
    this.columns = [
      {
        title: I18n.t('general.table_s_no'),
        key: 'index',
        render: (text, record, index) => index + 1,
        width: 55,
      },
      {
        title: I18n.t('deductions.balance_amount'),
        key: 'due_amount',
        dataIndex: 'due_amount',
        render: (text) => <div className='alignRight'>{checkNegitive(text)}</div>,
        width: 115,
      },
      // {
      //   title: I18n.t('deductions.txn_type'),
      //   key: 'txn_type',
      //   dataIndex: 'txn_type',
      //   width: 180,
      //   className: 'paddingLeft15'
      // },
      {
        title: I18n.t('deductions.updated_by'),
        key: 'updated_by',
        dataIndex: 'updated_by',
        width: 180,
      },
      {
        title: I18n.t('deductions.updated_at'),
        key: 'updated_at',
        dataIndex: 'updated_on',
        width: 180,
        render: (text) => (
          <div>
            {!isEmpty(text) && moment(text).isValid() ? (
              <DisplayTime
                isUTC={false}
                displayWithDate={true}
                dateTimeString={text}
                key={'updated_on'}
                timeZoneId={null}
              />
            ) : (
              'NA'
            )}
          </div>
        ),
      },
      {
        key: 'description',
        title: I18n.t('general.description'),
        dataIndex: 'remark',
      },
    ]
  }
}

export default Remarks
