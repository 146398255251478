import React from 'react';
import I18n from '../../common/I18n';
import List from '../users/List';

class DriversList extends List {
  constructor(props) {
    super(props);
    this.columns.push({
      key: 'sign_in',
      title: I18n.t('general.status'),
      dataIndex: 'sign_in',
      render: text => (
        <div className={text ? 'activeColor' : 'inActiveColor'}>
          {text ? 'Active' : 'InActive'}
        </div>
      ),
      sorter: (a, b) => {
        if ((a.sign_in === b.sign_in)) {
          return 0;
        }
        if (a.sign_in) {
          return (-1);
        }
        return (1);
      },
    });
    this.columns = this.columns.filter(x => x.key !== 'actions' && x.key !== 'email' );
  }
}

export default DriversList;
