/* eslint-disable no-nested-ternary */


import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Marker,
  InfoWindow,
  OverlayView,
  Polyline,
} from 'react-google-maps';
import MainMap from '../common/Map';
import { Card, Icon, Row, Col, Avatar, Tooltip, Popover } from '../../common/UIComponents';
import manlogo from '../../../assets/images/driver.png';
import AppConfig from '../../config/AppConfig';
import I18n from '../../common/I18n';
import { pinLikeMarker } from '../dispatches/helpers';
import { contrast } from '../../common/Colors';

const lineSymbol = {
  strokeOpacity: 1,
  strokeColor: '#479ada',
  strokeWeight: 2,
  scale: 4,
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: '',
      isOpen: [],
      kms: props.kms,
      zoom: 5,
      doMount: true,
      isCallCurrentStop: props.screen === 'stops',
      markers: props.data
        // .filter(x => x.location.l_address.coordinates && x.location.l_address.coordinates !== null)
        .map(task => ({
          id: task.id,
          name: task.name,
          lat: task.location.l_address.coordinates[1],
          lng: task.location.l_address.coordinates[0],
          ...task,
        })),
      location: null,
      driver: props.driver ? props.driver : '',
      routeStatus: props.routeStatus,


    };

    this.onMapMounted = this.onMapMounted.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data.length !== null) {
      const markers = props.data.map(task => ({
        id: task.id,
        name: task.name,
        lat: task.location.l_address.coordinates[1],
        lng: task.location.l_address.coordinates[0],
        ...task,
      }));
      let location = [];
      location = props.data;
      return ({
        markers,
        location,
        driver: props.driver ? props.driver : '',
      });
    }
    return null;
  }

  onToggleOpen(e, i, id) {
    const isOpen = [];
    isOpen[i] = !this.state.isOpen[i];
    if (id && this.state.isCallCurrentStop) { this.props.getCurrentStop(id); }
    this.setState({
      isOpen,
    });
  }

  onMapMounted(mapObj) {
    if (!this.state.map && this.state.doMount) {
      const map = this.state.map ? this.state.map : mapObj;
      const bounds = new window.google.maps.LatLngBounds();
      this.state.markers.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
      });
      if (this.state.driver && this.state.driver.latitude && this.state.driver.longitude) {
        bounds.extend(new window.google.maps.LatLng(this.state.driver.latitude, this.state.driver.longitude));
      }
      map.fitBounds(bounds);
      this.setState({
        map,
      });
    }
  }

  onMarkerClustererClick = (markerClusterer) => {
    const clickedMarkers = markerClusterer.getMarkers();
    // eslint-disable-next-line no-console
  };

  getCleanedLocations = () => {
    const arrLocations = this.state.location;
    const arr1 = arrLocations.slice(1);
    arr1.pop();
    const data = [];
    for (let i = 0; i < arr1.length; i++) {
      data.push({
        lat: arr1[i].location.l_address.coordinates[1],
        lng: arr1[i].location.l_address.coordinates[0],
      });
    }
    return data;
  }

  getCenterLat = () => {
    const arrLocations = this.state.markers;
    return parseFloat(arrLocations[0].coordinates.split(',')[0]);
  }

  getCenterLong = () => {
    const arrLocations = this.state.markers;
    return parseFloat(arrLocations[0].coordinates.split(',')[1]);
  }

  originPoint = () => {
    const arrLocations = this.state.location;
    return arrLocations[0].location.l_address.coordinates;
  }

  destinationPoint = () => {
    const arrLocations = this.state.location;
    return arrLocations[arrLocations.length - 1].location.l_address.coordinates;
  }

  getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  setZoom = (type) => {
    let { zoom } = this.state;
    zoom = type === 'plus' ? zoom + 1 : (type === 'minus' && zoom != 1 ? zoom - 1 : 1);
    this.setState({
      zoom,
      doMount: !this.state.doMount,
    }, () => {
    });
  }

  render() {
    const { driver } = this.state;
    return (
      <MainMap
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
        containerElement={<div style={{ height: '100%', width: '100%' }} />}
        mapElement={<div style={{ height: 800, width: 800 }} />}
        onMapMounted={this.onMapMounted}
        origin={this.originPoint()}
        destination={this.destinationPoint()}
        getCleanedLocations={this.getCleanedLocations()}
        kms={this.props.kms}
        zoom={5}
        {...this.props}
      >
        <Fragment>
          {
            this.state.markers.length > 0 &&
            <Polyline
              key="stops"
              path={this.state.markers.filter(stop => stop.status !== 'CANCELLED')}
              options={{
                geodesic: true,
                strokeColor: '#004a7d',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }}
            />
          }
          {this.state.markers.map((marker, i) => {
            // const stopNumber = marker.stop_number === 1 || marker.stop_number === this.state.markers.length ? 'W' : marker.stop_number - 1;
            // const isLinehaulRoute = this.props.route_type === 'line_haul'
            // const stopNumber = !isLinehaulRoute && (marker.stop_number === 1 || marker.stop_number === this.state.markers.length) ? 'W' : (!isLinehaulRoute ? marker.stop_number - 1 : marker.stop_number);
            // `https://chart.googleapis.com/chart?chst=d_map_spin&chld=1|0|${bgColor}|13|b|${stopNumber}`
            // `https://chart.googleapis.com/chart?chst=d_map_spin&chld=0.6|0|${bgColor}|13|b|${stopNumber}`
            const stopNumber = marker.location_type === 'WH' ? 'W' : ((i === 0 || i === this.state.markers.length - 1) ? marker.stop_number : marker.stop_number -1)
            const isStopVisited = marker.status && ['ARRIVED', 'INCOMPLETE', 'EXCEPTION', 'COMPLETED', 'CANCELLED'].includes(marker.status) && AppConfig.orderStatusColors[marker.status];
            const bgColor = isStopVisited ? AppConfig.orderStatusColors[marker.status].color : '808080';
            const fgColor = contrast(bgColor);
            return (<Fragment key={marker.id}>
              <Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
                onMouseOver={e => this.onToggleOpen(e, marker.stop_number, marker.id)}
                icon={marker.stop_number == 1 ? {
                  url: `https://chart.googleapis.com/chart?chst=d_map_spin&chld=1|0|${bgColor}|13|b|${stopNumber}`,
                } : {
                  url: pinLikeMarker( stopNumber , bgColor , fgColor , 25),
                }}
              >
                {this.state.isOpen[marker.stop_number] &&
                <InfoWindow
                  onCloseClick={e => this.onToggleOpen(e, marker.stop_number)}
                  options={{ alignTop: true, closeBoxURL: '', enableEventPropagation: true }}
                >
                  <div
                    style={{
                      height: 'auto', width: 'auto', padding: 0,
                    }}
                    className="mapInfoWindow"
                  >

                    <Card
                      style={{
                        minWidth: 250, marginTop: 12, fontSize: '14px', color: '#000000', padding: '10px 5px',
                      }}
                      title={marker.location && marker.location.l_type === 'WH' ? I18n.t('location.location') : `Stop ${marker.stop_number - 1}`}
                    >
                      <Row>
                        <Col md={5} lg={5} sm={12} xs={12}>
                          Name
                        </Col>
                        <Col md={19} lg={19} sm={12} xs={12}>
                          {marker.formatted_stop_name ? marker.formatted_stop_name : 'NA'}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} lg={5} sm={12} xs={12}>
                          Status
                        </Col>
                        <Col md={19} lg={19} sm={12} xs={12}>
                          {marker.location && marker.status ? marker.status : 'NA'}
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </InfoWindow>
                }
              </Marker>
            </Fragment>
            );
          })}
          {
            this.state.routeStatus !== 'COMPLETED' && driver !== '' && Object.keys(driver).length > 0 &&
            <OverlayView
              position={{
                lat: driver.lat,
                lng: driver.lng,
              }}
              /*
               * An alternative to specifying position is specifying bounds.
               * bounds can either be an instance of google.maps.LatLngBounds
               * or an object in the following format:
               * bounds={{
               *    ne: { lat: 62.400471, lng: -150.005608 },
               *    sw: { lat: 62.281819, lng: -150.287132 }
               * }}
               */
              /*
               * 1. Specify the pane the OverlayView will be rendered to. For
               *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
               *    Defaults to `OverlayView.OVERLAY_LAYER`.
               */
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              /*
               * 2. Tweak the OverlayView's pixel position. In this case, we're
               *    centering the content.
               */
              getPixelPositionOffset={this.getPixelPositionOffset}
            /*
             * 3. Create OverlayView content using standard React components.
             */
            >
              <div>
                <Popover
                  title="Driver Info"
                  content={

                    <div style={{ textAlign: 'left' }}>

                      <Row>
                        <Col md={5} lg={5} sm={12} xs={12}>
                          {
                            driver.icon && driver.icon.url ?
                              <Avatar src={`${driver.icon.url}`} size={50} /> :
                              <Avatar src={manlogo} alt="DriverProfile" size={50} />
                          }

                        </Col>
                        <Col md={19} lg={19} sm={12} xs={12}>
                          {
                            driver.name &&
                            <Row>
                              <Col xs={1}><Icon type="user" /></Col>
                              <Col xs={23}><Tooltip title="Driver NAme">&nbsp;&nbsp;{driver.name}</Tooltip></Col>
                            </Row>

                          }
                          {
                            driver.mobile_number &&
                            <Row>
                              <Col xs={1}><Icon type="phone" /></Col>
                              <Col xs={23}><Tooltip title="Contact No">&nbsp;&nbsp;{driver.mobile_number}</Tooltip></Col>
                            </Row>

                          }
                          {
                            driver.email &&
                            <Row>
                              <Col xs={1}><Icon type="mail" /></Col>
                              <Col xs={23}><Tooltip title="Email">&nbsp;&nbsp;{driver.email}</Tooltip></Col>
                            </Row>

                          }

                        </Col>

                      </Row>


                    </div>


                  }
                >
                  <div className="pin"><Avatar src={`${driver.icon.url}`} size={25} /></div>
                  <div className='pulse' />
                </Popover>
              </div>
            </OverlayView>
          }


          {/* </MarkerClusterer> */}
        </Fragment>
      </MainMap>
    );
  }
}

Map.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getCurrentStop: PropTypes.func.isRequired,
};
Map.defaultProps = {
  getCurrentStop: () => {},
};

export default Map;
