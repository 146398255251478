import React, { Component } from 'react';
import { Tabs } from 'antd';
import OrgBilling from './OrgBilling';
import MemoDescription from './MemoDescription';
import ManageGLCodes from './ManageGLCodes';
import OrgDunningNotice from './DunningNotices';
import StateCode  from './StateCode';

const { TabPane } = Tabs;

class OrgBillingTabs extends Component {
  constructor(props) {
    super(props);
  }
  handleSubTabChange = (key) => {
    console.log(key);
  }

  render() {
    const orgCode = _.get(this.props.currentOrg, 'code', null)
    return (
      <div className="child-card-container">
        <Tabs defaultActiveKey="1" type="card" onChange={this.handleSubTabChange}>
          <TabPane tab="Billing" key="1">
            <OrgBilling />
          </TabPane>
          <TabPane tab="Memo Descriptions" key="2">
            <MemoDescription />
          </TabPane>
          <TabPane tab="GL Codes" key="gl_codes">
            <ManageGLCodes />
          </TabPane>
          <TabPane tab="Dunning Notice" key="dunning">
            <OrgDunningNotice/> 
          </TabPane>
          {orgCode === "LYKES" && (
            <TabPane tab="State Code" key="state">
              <StateCode {...this.props} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default OrgBillingTabs;