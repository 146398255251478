import React from "react";
import BaseList from "../../BaseList";
import { Icon, Popconfirm, Tooltip, Input } from "../../../common/UIComponents";
import { checkServiceExistance } from "../../../helpers/common";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";
import SettingIcon from "../../common/SettingIcon";
import I18n from "../../../common/I18n";
import { compareString, isEmpty } from "../../../common/Common";

class List extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "name",
        title: I18n.t("general.name"),
        dataIndex: "accessorial_name",
        render: (text) => ({
          props: {
            className: "wordWrap",
          },
          children: text,
        }),
        sorter: (a, b) => compareString(a.accessorial_name, b.accessorial_name),
        sortOrder:"ascend",
        width: 250,
      },
    ];
    if (this.props.showCode !== false) {
      this.columns.push({
        key: "code",
        title: I18n.t("general.code"),
        dataIndex: "accessorial_code",
      });
    }
    if (this.props.showGLNo !== false) {
      this.columns.push({
        key: "gl_code",
        title: I18n.t("general.gl_number"),
        dataIndex: "gl_code",
      });
      this.columns.push({
        key: "accessible_to",
        title: "Accessible To",
        render: (record) => <div>{record.accessible_to.join(", ")}</div>,
      });
    }

    if (this.props.showDescription !== false) {
      this.columns.push({
        key: "description",
        title: I18n.t("general.description"),
        dataIndex: "description",
      });
    }
    if (this.props.showActions !== false) {
      this.columns.push({
        key: "actions",
        render: (record) => (
          <div className="line-actions">
            {
              checkServiceExistance(['AAAAD', 'AACAA', 'AAAC'], 'ALL') && 
              <Tooltip title={ I18n.t("tooltip.setting") }>
            <SettingIcon handleClick={() => this.props.doConfig(record.id)}/>
            &nbsp;
            </Tooltip>
            }
            &nbsp;
            <Tooltip title={ I18n.t("general.edit") }>
            <EditIcon handleClick={() => this.props.editClick(record.id)}/>
            &nbsp;
            </Tooltip>
            {checkServiceExistance('AAAACL') && !isEmpty(record.standard_code) && record.is_clone == 'false' && (
              <Tooltip title={ I18n.t("tooltip.copy") }>
              <Icon
                type="copy"
                onClick={() => this.props.cloneClick(record.id)}
              />
              </Tooltip>
            )}
            {checkServiceExistance('AAD') && (!record.standard_code || 
            (record.standard_code && record.is_clone == 'true')) 
            && (
              <Tooltip title={ I18n.t("general.delete") }>
              <Popconfirm
                placement="left"
                title={I18n.t("messages.delete_confirm")}
                onConfirm={() => this.props.deleteClick(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon/>
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
              </Tooltip>
            )}
          </div>
        ),
        width: "140px",
      });
    }
  }
}

export default List;
