import React from 'react';
import { Row, Col, Spin, Skeleton, Typography } from 'antd';

const { Text } = Typography;

function LoadingHome () {
  return (
    <div className='content-outer'>
      <div className="content">
        <Row type="flex" justify="center" align="middle" style={ { height: '100vh' } } gutter={8}>
          <Col>
            <Spin size="large" />
          </Col>
          <Col>
            <Text strong>Loading...</Text>
          </Col>
        </Row>
      </div>
    </div>

  );
}

export default LoadingHome;