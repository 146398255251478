/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Spin, Modal } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import FuelSurchargeList from './FuelSurchargeList';
import BaseModal from '../../components/BaseModal';
import FuelSurchargeForm from '../../components/configurations/FuelSurchargeForm';
import FuelSurchargeDetails from '../../components/configurations/fuel_surcharges/FuelSurchargeDetails';
import FuelSurchargeAccountForm from '../../components/configurations/fuel_surcharges/FuelSurchargeAccountForm';
import { deleteFuelSurchargeAccountConfig, deleteFuelSurchargeMaster, fetchFuelSurchargeMasters } from '../../api/FuelSurcharges';
import { fetchFuelSurchargeRegions } from "../../api/USEnergyInfo";
import userStore from '../../stores/UserStore';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import AppConfig from '../../config/AppConfig';


// const sessionOrgId = userStore.getStateValue('selectedOrg');
const {confirm} = Modal;
class FuelSurcharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      fuel_surcharges: [],
      account: this.props.account || {},
      org_id: this.props.orgId || userStore.getStateValue('selectedOrg'),
      regions: [],
      currentConfigId: null,
      currentConfig: {
      },
      showFormModal: false,
      showSettingsModal: false,
      showAccountFormModal: false,
      pagination:{}
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
    };
  }

  componentDidMount() {
    this.getFuelSurcharges();
    if(checkServiceExistance('FSCR')){
      this.getFuelSurchargeRegions();
    }
  }

  getFuelSurchargeRegions = () => {
    fetchFuelSurchargeRegions().then((result) => {
      if (result.success) {
        this.setState({
          regions: result.fuel_surcharge_regions || [],
        });
      }
      else {
        renderAlertMessage(result.errors)
      }
    })
  }

  getFuelSurcharges = () => {
    this.setState({ inProgress: true });
    const { account, org_id } = this.state;
    const page = this.tableOptions.pagination.current;
    const perPage = AppConfig.perPage;
    fetchFuelSurchargeMasters(account.id, org_id, page, perPage)
      .then((result) => {
        if (result.success) {
          this.setState({
            fuel_surcharges: result.fuel_surcharge_masters || [],
            pagination: result.pagination || {},
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
    };
    if (pagination.current !== currentPage) {
      this.getFuelSurcharges();
    }
  };

  handleDeleteConfirm = (id) => {
    this.setState({ inProgress: true });
    const payload = {
      account_id: this.state.account.id,
      organization_id: this.state.org_id,
      fs_master_id: id,
    };
    if(!payload.account_id) {
      delete payload.account_id;
      deleteFuelSurchargeMaster(payload).then((result) => {
        if (result.success) {
          alertMessage( I18n.t("messages.deleted") , 'success', 10);
          this.getFuelSurcharges();
        } else {
          renderAlertMessage(result.errors)
        }
      }
      );
    }else{
      deleteFuelSurchargeAccountConfig(payload).then((result) => {
        if (result.success) {
          alertMessage( I18n.t("messages.deleted") , 'success', 10);
          this.getFuelSurcharges();
        } else {
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      }
      ).finally(() => {
        this.setState({ inProgress: false });
      });
    }
  }
  handleDeleteClick = (id) => {
    confirm({
      title: I18n.t('messages.action_confirm', { action : "delete" , field: I18n.t('driver_rates.fuel_surcharge') }),
      content: <span className="">{ "Do you want to proceed ?" }</span>,
      onOk: () => {
        this.handleDeleteConfirm(id);
      },
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onCancel: () => { },
    });
  }


  handleAddClick = () => {
    if (this.state.account.id) {
      this.setState({ currentConfig: {}, showAccountFormModal: true });
    } else {
      const defaultOptions = {
        _frequency
          :
          AppConfig.fuelSurchargeFrequencies["WEEKLY"],
        frequency_value
          :
          "MONDAY",
        _surcharge_type
          :
          "LINE_HAUL"
      }
      this.setState({ currentConfig: defaultOptions ,showFormModal: true });
    }
  };

  handleEditClick = (id) => {      
    const index = _.findIndex(this.state.fuel_surcharges, ['id', id]);
    const fuel_surcharge = Object.assign({}, this.state.fuel_surcharges[index]);
    this.setState({ currentConfig: fuel_surcharge, isNew: false });
    if (this.state.account.id) {
      this.setState({ showAccountFormModal: true });
    } else {
      this.setState({ showFormModal: true });
    }

  }

  handleSettingsClick = (id) => {
    const index = _.findIndex(this.state.fuel_surcharges, ['id', id]);
    const fuel_surcharge = Object.assign({}, this.state.fuel_surcharges[index]);
    this.setState({ currentConfig: fuel_surcharge, isNew: false, showSettingsModal: true });
  }

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false, showAccountFormModal: false });
    this.getFuelSurcharges();
  };

  handleAccountSave = (config) => {
    this.setState({ showFormModal: true, currentConfig: _.omit(config, '_id', 'is_primary') || {} });
  };

  handleOnModalClose = () => {
    this.setState({ showFormModal: false, showAccountFormModal: false });
  };

  handleEditClick(id) {
    const index = _.findIndex(this.state.fuel_surcharges, ['id', id]);
    const config = Object.assign({}, this.state.fuel_surcharges[index]);
    this.setState({ currentConfig: config, showFormModal: true });
  }

  canAddConfig() {
    if (this.state.account.id && this.state.fuel_surcharges.length > 0) {
      return false;
    }
    return true;
  }

  isAccountConfig = () => {
    if (this.state.account && this.state.account.id) {
      return true;
    }
    return false;
  }

  renderAccountFormModal() {
    let title;
    let isNew;
    if (this.state.currentConfig.id) {
      title = I18n.t(`fuelSurcharges.edit_config`);
      isNew = false;
    } else {
      title = I18n.t(`fuelSurcharges.add_config`);
      isNew = true;
    }
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        style={{ top: 150 }}
        width="60%"
        maskClosable={false}
      >
        <FuelSurchargeAccountForm
          config={this.state.currentConfig}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          onNext={data => this.handleAccountSave(data)}
          organizationId={this.state.org_id}
          accountId={this.state.account.id}
          accountCode={this.state.account.code}
          regions={this.state.regions}
        />
      </BaseModal>
    );
  }

  renderFormModal() {
    let title;
    let isNew;
    if (this.state.currentConfig.id) {
      title = I18n.t(`fuelSurcharges.edit_config`);
      isNew = false;
    } else {
      title = I18n.t(`fuelSurcharges.add_config`);
      isNew = true;
    }
    const isAccConfig = this.isAccountConfig();
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        style={{ top: 150 }}
        width="60%"
        maskClosable={false}
      >
        <FuelSurchargeForm
          config={this.state.currentConfig}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          organizationId={this.state.org_id}
          accountId={this.state.account.id}
          accountCode={this.state.account.code}
          regions={this.state.regions}
          isAccConfig={isAccConfig}
        />
      </BaseModal>
    );
  }

  renderSettingsModal() {
    const isAccConfig = this.isAccountConfig();
    return (
      <BaseModal
        title= {`Configurations of ${this.state.currentConfig.name}`}
        onCancel={() => this.setState({showSettingsModal: false})}
        style={{ top: 150 }}
        width="80%"
        maskClosable={false}
      >
        <FuelSurchargeDetails
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          fsMaster={this.state.currentConfig}
          organizationId={this.state.org_id}
          canEdit={!this.state.account.id}
          isAccConfig={isAccConfig}
        />
      </BaseModal>
    );
  }

  render() {
    const isAccConfig = this.isAccountConfig();
    const { pagination } = this.state;
    return (
      <div>
        <Spin spinning={this.state.inProgress} delay={1000}>
          { this.canAddConfig() && checkServiceExistance(isAccConfig ? 'FSCC' : 'FSCMC') &&
            <Row>
              <Col lg={4}>
                <Button onClick={() => this.handleAddClick()} style={{ marginLeft: 10, marginBottom: 10 }} type="primary" icon='plus'>
                  {I18n.t('fuelSurcharges.add_config')}
                </Button>
              </Col>
            </Row>
          }
         {checkServiceExistance(isAccConfig ? 'FSCI' : 'FSCMI') && <Row>
            <FuelSurchargeList
              scroll = {{y:"calc(100vh - 360px)"}}
              data={this.state.fuel_surcharges}
              // pagination={{ position: "none" }}
              pagination={{
                total: pagination.total_count,
                current: pagination.current_page,
                pageSize: pagination.per_page,
              }}
              tableChange={(paginationInfo, filters, sorter) =>
                this.handleTableChange(paginationInfo, filters, sorter)
              }
              onSelect={message => this.handleSaveSuccess(message)}
              settingsClick={id => this.handleSettingsClick(id)}
              onEditClick={id => this.handleEditClick(id)}
              isAccConfig={isAccConfig}
              OnDeleteClick={this.handleDeleteClick}
            />
          </Row>
         }
        </Spin>
        {this.state.showFormModal &&
          this.renderFormModal()
        }
        {this.state.showAccountFormModal &&
          this.renderAccountFormModal()
        }
        {this.state.showSettingsModal &&
          this.renderSettingsModal()
        }
      </div>
    );
  }
}

export default FuelSurcharges;

FuelSurcharges.propTypes = {
  account: PropTypes.shape().isRequired,
  orgId: PropTypes.string.isRequired,
};
