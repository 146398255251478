import AppConfig from "../config/AppConfig";
import { Call, ApiUrl, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";

export const TemplateEventsApi = {
  fetchParams: () => {
    const url = ApiUrl("v2/message_template_params");
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { messages: "" },
    });
  },
  manage: (isNew, data) => {
    data.operation_code = isNew ? 'TEC' : 'TPU';
    const url = isNew
      ? ApiUrl("v2/template_events")
      : ApiUrl(`v2/template_events/${data.id}`);
    const method = isNew ? "post" : "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  delete: (id) => {
    const url = ApiUrl("v2/template_events/" + id);
    return Call("delete", url, { operation_code: 'TED' }, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  },
  fetchEvents: (renderType = "", page = 1, perPage = AppConfig.perPage) => {
    let url = ApiUrl(`v2/template_events?operation_code=TEI`);
    if (renderType === "list") {
      url += `&render_type=list&${optionalPageParams(page, perPage, false)}`;
    }
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: { data: "template_events", pagination: "meta.pagination" },
    });
  },
};
