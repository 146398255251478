import React from 'react';
import BaseList from '../BaseList';
import {checkNegitive, isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import { Link } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import { getValueFromArrayOfObjects } from '../../helpers/array_functions';

class InvoicesList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      // {
      //   key: 'account_name',
      //   title: I18n.t('account.account'),
      //   dataIndex: 'account_name',
      //   render: (data, record) => (
      //     <div>{data ? `${data} ${record.account_code}` : ''}</div>
      //   ),
      // },

      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(data);
                    }
                  }}
                >
                  {isEmpty(data)
                    ? record.account_name
                    : `${record.account_name} (${data})`}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ''
              )}
            </div>
          );

          // <div>{data ? `${data} ${record.account_code}` : ''}</div>
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },

      {
        key: "invoice_type",
        title: I18n.t("account.billing.invoice_type"),
        dataIndex: "invoice_type",
      },
      {
        key: "invoice_count",
        title: I18n.t("account.billing.invoice_count"),
        dataIndex: "invoice_count",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "amount",
        title: I18n.t("invoices.amount"),
        dataIndex: "invoice_amount",
        render: (data) => (
          <div style={{ width: 100 }} className="alignRight">
            {data ? checkNegitive(data) : ""}
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
    ];
  }
}

export default InvoicesList;
