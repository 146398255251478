/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import _, { constant } from 'lodash';
import PropTypes from 'prop-types';
import { Radio, Col, Row, Spin, Button, Modal } from '../../../common/UIComponents';
import { alertMessage, randomNumber, isEmpty } from '../../../common/Common';
import userStore from '../../../stores/UserStore';
import ZoneZipCodeForm from './ZoneZipCode';
import ZoneDistanceForm from './ZoneDistanceForm';
import { deleteAccountZone, fetchAccountZones, saveAccountZones } from '../../../api/BillingZones';
import FormErrors from '../../common/FormErrors';
import { checkServiceExistance, renderAlertMessage } from '../../../helpers/common';
import I18n from '../../../common/I18n';

const { confirm } = Modal;
class ZoneConfig extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      // pagination: {},
      inProgress: false,
      showForm: false,
      account: this.props.account || {},
      zoneTypes: {
        location: 'By Zip codes',
        distance: 'By Distance',
      },
      zones: [],
      zone_type: '',
      disableTypes: false,
      decimalPoints,
      zeroValue,
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }

  componentDidMount() {
    // this.setInitialData();
    this.getAccountZones();
  }

  setInitialData = () => {
    const { account } = this.state;
    const accounts = userStore.getStateValue('accounts');
    const currentAccountZones = accounts[account.code] || {};
    const accountZones = currentAccountZones.zones || [];
    this.setState({
      zone_type: accountZones.zone_type || 'location',
      zones: accountZones.zones || [],
    });
  }

  getAccountZones = () => {
    this.setState({ inProgress: true });
    fetchAccountZones(this.state.account.id)
      .then((result) => {
        if (result.success) {
          const zones = result.zones || [];
          const zone_type = zones.length > 0 ? zones[0].zone_type : 'location';
          const disableTypes = zones.length > 0;
          this.setState({
            zone_type,
            zones,
            inProgress: false,
            disableTypes,
          }, () => {
            this.sortZones();
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  sortZones = () => {
    if (this.state.zone_type === 'distance') {
      this.setState({
        zones: _.sortBy(this.state.zones, 'min_distance'),
      });
    }
  }

  handleTypeChange = (element, value) => {
    if (this.state.zones.length > 0) {
      confirm({
        title: I18n.t('messages.zones_type_change'),
        content: I18n.t('messages.zones_type_change_descr'),
        onOk: () => {
          this.handleStateOnChange(element, value);
        },
        onCancel() {
        },
      });
    } else {
      this.handleStateOnChange(element, value);
    }
  }

  handleStateOnChange = (element, value) => {
    const zones = [...this.state.zones];
    const { zeroValue } =  this.state;
    let modifiedZones = [];
    if (value === 'distance') {
      modifiedZones = zones.map(zone => ({
        name: zone.name,
        min_distance: '',
        max_distance: '',
        min_charge: zeroValue,
      }));
    } else {
      modifiedZones = zones.map(zone => ({
        name: zone.name,
        zip_codes: [],
        min_charge: zeroValue,
      }));
    }
    this.setState({
      [element]: value,
      zones: modifiedZones,
    }, () => {

    });
  }


  addzone = () => {
    const { zones, zeroValue } = this.state;
    if (this.state.zone_type === 'distance') {
      zones.push({
        name: '',
        min_distance: '',
        max_distance: '',
        isNew: true,
        min_charge: zeroValue,
      });
    } else if (this.state.zone_type === 'location') {
      zones.push({
        name: '',
        zip_codes: [],
        isNew: true,
        min_charge: zeroValue,
      });
    }
    this.setState({
      zones,
    });
  }

  handleDelete = (id) => {
    this.setState({ inProgress: true });
    deleteAccountZone({ id })
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.setState({
            inProgress: false,
          });
          this.getAccountZones();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleFormChange = (index, element, value) => {
    const zones = [...this.state.zones];
    zones[index][element] = value;
    this.setState({
      zones,
    });
  }

  handleSave = () => {
    /* const { account, zone_type, zones } = this.state;
    const accounts = userStore.getStateValue('accounts') || {};
    if (!accounts[account.code]) {
      accounts[account.code] = {};
    }
    const accountZones = zones.filter(record => !isEmpty(record.zone_name)).map(zone => ({
      zone_id: randomNumber(),
      ...zone,
    }));
    accounts[account.code].zones = {
      zone_type,
      zones: accountZones || [],
    };
    userStore.setStateValue('accounts', Object.assign({}, accounts));
    this.setState({
      zone_type,
      zones: accountZones || [],
    });
    alertMessage(I18n.t('messages.saved')); */
    const { account, zone_type, zones, zeroValue } = this.state;
    const accountZones = zones.filter(record => !isEmpty(record.name)).map((zone) => {
      const formattedZone = {
        name: zone.name,
        // min_charge: zone.min_charge ? parseFloat(zone.min_charge) : zeroValue,
      };
      if (zone_type === 'distance') {
        formattedZone.max_distance = !isEmpty(zone.max_distance) ? parseFloat(zone.max_distance) : '';
        formattedZone.min_distance = !isEmpty(zone.min_distance) ? parseFloat(zone.min_distance) : '';
      } else {
        formattedZone.zip_codes = zone.zip_codes || [];
      }
      if (zone.id) {
        formattedZone.id = zone.id;
      }
      return formattedZone;
    });
    const data = {
      account_id: account.id,
      zone_type,
      zones: accountZones || [],
    };
    this.setState({ inProgress: true });
    saveAccountZones(data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({ inProgress: false, errors: [] });
          this.getAccountZones();
        } else {
          // alertMessage(result.errors, 'error', 10);
          this.setState({
            errors: result.errors,
            inProgress: false,
          });
        }
      });
  }

  _renderZones = () => (
    <div>
      <Row>
        <Col xs={4} />
        <Col xs={16}>
          <Radio.Group
            onChange={e => this.handleTypeChange('zone_type', e.target.value)}
            value={this.state.zone_type}
            disabled={this.state.disableTypes}
          >
            {
              Object.keys(this.state.zoneTypes).map(key =>
                (
                  <Radio
                    value={key}
                    key={key}
                  > {this.state.zoneTypes[key]}
                  </Radio>
                ))
            }
          </Radio.Group>

        </Col>
        {
        checkServiceExistance('BZC') && 
        <Col xs={4}>
          <Button
            size="small"
            type="primary"
            onClick={this.addzone}
            icon='plus'
          >
            {I18n.t('zones.add')}
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col md={{ offset: 4, span: 16 }}>
          {
            this.state.zones.length > 0 &&
            <Fragment>
              {
                this.state.zone_type === 'distance' ?
                  <ZoneDistanceForm
                    zones={this.state.zones}
                    onChange={this.handleFormChange}
                    account={this.state.account}
                    handleSave={this.handleSave}
                    handleDelete={(index, id, isNew) => this.handleDeleteClick(index, id, isNew)}
                  />
                  :
                  <ZoneZipCodeForm
                    zones={this.state.zones}
                    onChange={this.handleFormChange}
                    account={this.state.account}
                    handleSave={this.handleSave}
                    checkDuplicates
                    handleDelete={(index, id, isNew) => this.handleDeleteClick(index, id, isNew)}
                  />

              }
            </Fragment>
          }
          <Col xs={24}>
            {FormErrors(this.state.errors)}
          </Col>
        </Col>
      </Row>


    </div>
  )

  handleDeleteClick = (index, id, isNew) => {
    if (isNew === true) {
      const { zones } = this.state;
      zones.splice(index, 1);
      this.setState({
        zones,
      });
    } else {
      this.handleDelete(id);
    }
  }


  render() {
    return (
      <div className='content-outer'>
        <div className='content'>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col className="accessorials">
                <this._renderZones />
              </Col>
            </Row>
          </Spin>

        </div>
        {this.state.showForm &&
          this.renderFormModal()
        }
      </div>
    );
  }
}
ZoneConfig.propTypes = {
  account: PropTypes.shape().isRequired,
};

export default ZoneConfig;
