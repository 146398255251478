import React from "react";
import BaseList from "../../BaseList";
import { checkNegitive, compareFloatNumber, compareNumber, compareString, isEmpty } from "../../../common/Common";
import Copyable from "../../common/Copyable";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance } from "../../../helpers/common";
import { Col, Icon, Link, Popover, Row } from "../../../common/UIComponents";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import I18n from "../../../common/I18n";

class OpenBalanceSummaryList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "account_code",
        title: I18n.t("account.account_id"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>  
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        align: "left",
        width: 100,
        sorter: (a, b) => compareString(a.account_code, b.account_code),
      },
      {
        key: "account_name",
        title:I18n.t("account.account_name"),
        dataIndex: "account_name",
        render: (data, record) => {
          const hasAccess =  checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        align: "left",
        width: 100,
        sorter: (a, b) => compareString(a.account_name, b.account_name),
      },
      // {
      //   key: "terminal",
      //   title: I18n.t("aging.terminal"),
      //   dataIndex: "terminal",
      //   render: (data) => (
      //     <span className="textUpperCase">{data}</span>
      //   ),
      //   align: "left",
      //   width:250
      // }, 
      {
        key: "total",
        title: I18n.t("aging.total"),
        dataIndex: "total",
        render: (data) => (
          <span className="textUpperCase">{data !== undefined ? checkNegitive(data) : 0}</span>
        ),
        align: "right",
        width: 100,
        sorter: (a, b) => compareFloatNumber(a.total, b.total)
      },
      {
        key: "balance",
        title: I18n.t("aging.balance"),
        dataIndex: "balance",
        render: (data) => (
          <span className="textUpperCase">{data !== undefined ? checkNegitive(data) : 0}</span>
        ),
        align: "right",
        width: 100,
        sorter: (a, b) => compareFloatNumber(a.balance, b.balance)
      },
     {
        key: "over_pay",
        title: "",
        dataIndex: "over_pay",
        render: (data, record) => (
          <span>
            {!isEmpty(record.over_pay) && record.over_pay > 0 ? (
              <span>
                ( Over Pay:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    fontStyle: "italic",
                  }}
                >
                  {checkNegitive(record.over_pay)}
                </span> )
              </span>
            ) : (
              ""
            )}
          </span>
        ),
        align: "left",
        width: 80,
      },
    ];
  }
}
export default OpenBalanceSummaryList;