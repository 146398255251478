import React, { Fragment } from "react";
import BaseList from "../../BaseList";
import { checkNegitive, compareString, compareNumber, isEmpty, compareFloatNumber, compareDate } from "../../../common/Common";
import moment from 'moment';
import Copyable from "../../common/Copyable";
import CopyableContent from "../../common/CopyableContent";
import { checkServiceExistance, formatUSPhoneNumber } from "../../../helpers/common";
import { Col, Icon, Link, Popover, Row, Tooltip } from "../../../common/UIComponents";
import OrderFieldDisplay from "../../orders/OrderFieldDisplay";
import I18n from "../../../common/I18n";
import AppConfig from "../../../config/AppConfig";

class OpenBalanceList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "accountcode",
        title: I18n.t("account.account_id"),
        dataIndex: "account_code",
        render: (data, record) => {
          const hasAccess =   checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_code ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                  {data}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        align: "left",
        width: 100,
        sorter: (a, b) => compareString(a.account_code, b.account_code)
      },
      {
        key: "accountName",
        title:I18n.t("account.account_name"),
        dataIndex: "account_name",
        className: "noWrap",
        render: (data, record) => {
          const hasAccess =  checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess && record.account_name ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_id);
                    }
                  }}
                >
                <Tooltip title= {data} placement="topLeft"> {data} </Tooltip> 
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                ""
              )}
            </div>
          );
        },
        align: "left",
        sorter: (a, b) => compareString(a.account_name, b.account_name)
      },
      {
        key: "bal_company",
        title: I18n.t("general.company"),
        dataIndex: "company",
        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        align: "left"
      },

      {
        key: "invalid_date",
        title: I18n.t("aging.inv_date"),
        dataIndex: "inv_date",
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width: 120,
        sorter: (a, b) => compareDate(a.inv_date, b.inv_date)
      },
      {
        key: "bal_cid",
        title: I18n.t("aging.cid"),
        dataIndex: "cid",

        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left"
      },
      {
        key: "shipperName",
        title: I18n.t("aging.shipper_name"),
        dataIndex: "shipper_details",
        render: (data) => 
        data ? 
        <Popover
        title={I18n.t("order.shipper_details")}
        content={
          <div>
            <Row>
              <Col lg={2} md={2} sm={6} xs={6}>
                <Icon type="bank" />
              </Col>

              <Col lg={22} md={22} sm={18} xs={18}>
                {isEmpty(data.name) ? 'N/A' : data.name}
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} sm={6} xs={6}>
                <Icon type="code" />
              </Col>

              <Col lg={22} md={22} sm={18} xs={18}>
                {isEmpty(data.code) ? 'N/A' : data.code}
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} sm={6} xs={6}>
                <Icon type="contacts" />
              </Col>

              <Col lg={22} md={22} sm={18} xs={18}>
                {isEmpty(data.contact_person) ? 'N/A' : data.contact_person}
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} sm={6} xs={6}>
                <Icon type="phone" />
              </Col>

              <Col lg={22} md={22} sm={18} xs={18}>
                {isEmpty(data.phone_number)
                  ? 'N/A'
                  : formatUSPhoneNumber(data.phone_number)}
              </Col>
            </Row>
          </div>
        

        }
      >
        {isEmpty(data.name)
          ? ""
          : <u className="text-decoration-dashed cursorPointer" >{data.name}</u>
        }
      </Popover> : <Fragment />,
        align: "left",
        width:190
      },
      {
        key: "consumer_name",
        title: I18n.t("aging.cons_name"),
        dataIndex: "cons_name",

        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        align: "left"
      },
      {
        key: "dim_wt",
        title: I18n.t("aging.dim_weight"),
        dataIndex: "dim_weight",

        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "right"
      },
      {
        key: "effective_ser_date",
        title: I18n.t("aging.effective_service_date"),
        dataIndex: "effctive_service_date",

        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        sorter: (a, b) => compareDate(a.effctive_service_date, b.effctive_service_date)
      },
      {
        key: "acc_hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        render: (data) => <CopyableContent text={data} showTooltip/>,
        align: "left"
      },
      {
        key: "acc_mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        render: (data) =>  <CopyableContent text={data} showTooltip/>,
        align: "left"
      },
      {
        key: "invoice_num",
        title: I18n.t("aging.inv_number"),
        dataIndex: "inv_number",
        render: (data) => <span>{data} <Copyable text={data} /></span>,
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width:120
      },
      
      {
        key: "pieces",
        title: I18n.t("aging.pieces"),
        dataIndex: "pieces",
        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        align: "right",
        sorter: (a, b) => compareNumber(a.pieces, b.pieces)
      },
      {
        key: "terminal",
        title: I18n.t("aging.terminal"),
        dataIndex: "terminal",
        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        align: "left",
        width:190
      },
      {
        key: "ref",
        title: I18n.t("aging.reference_1"),
        dataIndex: "reference_1",
        render: (data, record) => <OrderFieldDisplay data={record} toDisplay="reference_1" />,
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "left",
        width:130
      },
      {
        key: "weight",
        title: I18n.t("aging.weight"),
        dataIndex: "weight",
        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        // sorter: true,
        // sortDirections: ["ascend", "descend", "ascend"],
        align: "right",
        sorter: (a, b) => compareNumber(a.weight, b.weight)
      },
      {
        key: "total",
        title: I18n.t("aging.invoice_total"),
        dataIndex: "total",
        render: (data) => (
          <span className="textUpperCase">{data !== undefined ? checkNegitive(data) : 0}</span>
        ),
        align: "right",
        width: 90,
        sorter: (a, b) => compareFloatNumber(a.total, b.total)
      },
      {
        key: "job_total",
        title: I18n.t("aging.job_total"),
        dataIndex: "job_total",
        render: (data) => (
          <span className="textUpperCase">{data !== undefined ? checkNegitive(data) : 0}</span>
        ),
        align: "right",
        width: 90,
        sorter: (a, b) => compareFloatNumber(a.job_total, b.job_total)
      },
      {
        key: "balance",
        title: I18n.t("aging.balance"),
        dataIndex: "balance",
        render: (data) => (
          <span className="textUpperCase">{data !== undefined ? checkNegitive(data) : 0}</span>
        ),
        align: "right",
        width: 90,
        sorter: (a, b) => compareFloatNumber(a.balance, b.balance)
      },
      {
        key: "age",
        title: I18n.t("aging.age"),
        dataIndex: "age",
        render: (data) => (
          <span className="textUpperCase">{data}</span>
        ),
        align: "right",
        sorter: (a, b) => compareNumber(a.age, b.age)
      },
    ];
  }
}
export default OpenBalanceList;