import React from "react";
import BaseList from "../../BaseList";
import {
  Icon,
  Popconfirm,
  Switch,
  Popover,
  Tooltip,
} from "../../../common/UIComponents";
import I18n from "../../../common/I18n";
import EditIcon from "../../common/EditIcon";
import DeleteIcon from "../../common/DeleteIcon";
class ContactGroupList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("general.name"),
        dataIndex: "name",
        key: "name",
      },
      // {
      //   title: I18n.t("general.code"),
      //   dataIndex: "code",
      //   key: "code",
      // },
      {
        title: I18n.t("general.description"),
        dataIndex: "description",
        key: "description",
        render: (text) => {
          if (text && text.length > 30) {
            return (
              <Popover
                content={
                  <div className="table-contact-info">
                    <span>{text}</span>
                  </div>
                }
                title="Description"
              >
                <u className="text-decoration-dashed">
                  {text.substring(0, 30)}.......
                </u>
              </Popover>
            );
          }
          return <span>{text}</span>;
        },
      },
      // {
      //   key: "contact_ids",
      //   title: "Contacts",
      //   //dataIndex: "contact_group_details.name",
      //   render: (data) => (
      //     <span>{`${_.map(data.contact_ids, "name").join(", ")}`}</span>
      //   ),
      // },
      {
        title: I18n.t("form.status"),
        key: "active",
        // dataIndex: "active",
        // render: (data) =>
        //   (
        //     {/* <Popconfirm
        //       placement="topRight"
        //       title={I18n.t("messages.change_status")}
        //       okText="Yes"
        //       cancelText="No"
        //       onConfirm={() => this.props.statusChange(data.id)}
        //     > */}
        //       <Switch checked={data.active === true} />
        //     {/* </Popconfirm> */}
        //   )
        // ,.
        render: (data) => (
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.change_status")}
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.props.statusChange(data.id)}
          >
            <Switch
              checked={data.active === true}
              checkedChildren={"Yes"}
              unCheckedChildren={"No"}
              className={!data.active ? "switchComponent" : null}
            />
          </Popconfirm>
        ),
      },

      {
        title: "",
        key: "actions",
        dataIndex: "id",
        fixed:"right",
        render: (id, record, index) => (
          <div className="line-actions">
            <Tooltip title={I18n.t("general.edit")}>
              <span><EditIcon handleClick={() => this.props.editClick(id)}/></span>
            </Tooltip>
            <Tooltip title={I18n.t("general.notification")}>
              <span><Icon type="bell" style={{color:"#d48806"}} onClick={() => this.props.onClickSubscribe(id)} /></span>
            </Tooltip>
            <Tooltip title={I18n.t("general.delete")}>
            <Popconfirm
              placement="left"
              title={I18n.t("messages.delete_confirm")}
              onConfirm={() => this.props.deleteClick(id)}
              okText="Yes"
              cancelText="No"
            >
              <span><DeleteIcon/></span>
            </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
  }
}
export default ContactGroupList;
