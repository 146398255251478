import React from "react";
import {
	Button,
	Form,
	Input,
	Row,
	Col,
	Switch,
} from "antd";
import PropTypes from "prop-types";
import I18n from "../../common/I18n";
import IntegrationConfigTable from "./IntegrationConfigTable";
import { confirmationPopup, renderAlertMessage } from "../../helpers/common";
import { IntegrationApi } from "../../api/IntegrationApi";
import { alertMessage } from "../../common/Common";

class IntegrationsFormWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isIntegrationSelected: false,
			selectedIntegrationData: {},
			status: false,
			isNewIntegration: false,
			isEdit: false,
			isEditConfig: false,
			isLoading: false,
			tableData: [
			]
		};
	}

	componentDidMount() {
		const { integrationsData, form, activeIntegrationId } = this.props;
		if (activeIntegrationId) {
			this.setState({ isLoading: true });
			const activeIntegrationData = integrationsData.find(
				(integration) => integration.id === activeIntegrationId
			);
				form.setFieldsValue({
					integrationName : activeIntegrationData.name,
					integrationCode : activeIntegrationData.code,
					integrationStatus : activeIntegrationData.status,
				});
			this.setState({ isEdit: true, isLoading: false , tableData : activeIntegrationData.config}
			);
		} else {
			// reset form
			form.resetFields();
			this.setState({ isEdit: false, isLoading: false });
		}
	}

	updateIntergrationConfig = (data , operation = "") => {
		this.setState({ tableData: data } , () => {
			if(operation === 'delete'){
				 this.onSubmit(null);
			}
		});
	}
	toggleConfigTableEdit = (editStatus) => {
		this.setState({ 
			isEditConfig: editStatus,
		 } , () => {
			 if(!editStatus && this.state.isEdit)
			 {
				 this.onSubmit(null);
			 }
		 });
	};
	
	updateFormData = (values , isPartialSubmit) => {
		const nonNewData = this.state.tableData.filter(i => !i.isNew);
		const fullFormData = {
			name: values.integrationName,
			code: values.integrationCode,
			status: values.integrationStatus,
			config: nonNewData,
		};
		IntegrationApi.update(this.props.activeIntegrationId , fullFormData).then(
			(response) => {
				if(response.success){
					alertMessage(I18n.t("integrations.modal.updateSuccess"), "success");
					this.props.updateIntegrationData(this.state.isEdit, fullFormData , isPartialSubmit);
				}else{
					renderAlertMessage(response.errors)
				}
			});
	}
	processTableData = (data) => {
		const settings = {}
		data.forEach(i => {
			settings[i.config_key] = i.config_value;
		})
		return settings;
	}

	onSubmit = (e) => {
		const isPartialSubmit = e ? false : true;
		e && e.preventDefault();
		const { validateFields } = this.props.form;
		validateFields((err, values) => {
			if (!err) {
				const newValues = this.state.tableData.filter(i => i.isNew)
				
				if(this.state.isEditConfig) {
					const confirmationInfo = {
		        title: I18n.t('general.are_you_sure'),
		        content: I18n.t('messages.unsaved_changes'),
						onConfirm: () => {
							this.updateFormData(values , isPartialSubmit);
						},
					};
					confirmationPopup(confirmationInfo);
				} else {
					if (newValues.length > 0) {
						//  update form with non new values
						this.updateFormData(values , isPartialSubmit);
					} else {
						const fullFormData = {
							name: values.integrationName,
							code: values.integrationCode,
							status: values.integrationStatus,
							// config: this.state.tableData,
							settings : this.processTableData(this.state.tableData),
						};
						if (this.state.isEdit) {
							IntegrationApi.update(this.props.activeIntegrationId,fullFormData).then(
								(response) => {
									if (response.success) {
										alertMessage(I18n.t("integrations.modal.updateSuccess"), "success");
										this.props.updateIntegrationData(this.state.isEdit, fullFormData , isPartialSubmit);
									} else {
										alertMessage(response.errors[ 0 ], "error");
									}
								});
						}else{
							IntegrationApi.create(fullFormData).then(
							(response) => {
								if (response.success) {
									alertMessage(I18n.t("integrations.modal.createSuccess"), "success");
									this.props.updateIntegrationData(this.state.isEdit, response.integration , isPartialSubmit);
								} else {
									renderAlertMessage(response.errors)
								}
							});
						}
						
					}
				}
			}
		});
	};
	onStatusChange = (status) => {
		this.setState({
			status,
		});
	};

	render() {
		const {
			form: { getFieldDecorator },
			formTitle,
			closeIntegrationModal
		} = this.props;
		const { isEdit, isNewIntegration , isLoading , tableData } = this.state;
		const { getFieldsValue} = this.props.form;
		const { integrationStatus } = getFieldsValue();
		return (
			<Form onSubmit={this.onSubmit} title={formTitle}>
				<Row>
					<Row type="flex" gutter={16}>
						<Col span={12}>
							<Form.Item label={I18n.t("general.name")}>
								{getFieldDecorator("integrationName", {
									rules: [
										{
											required: true,
											message: "Please Enter Integration Name",
										},
									],
								})(<Input />)}
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={I18n.t("general.code")}>
								{getFieldDecorator("integrationCode", {
									rules: [
										{
											required: true,
											message: "Please Enter Integration Code",
										},
									],
								})(<Input style={{
									textTransform: "uppercase"
								}} />)}
							</Form.Item>
						</Col>
					</Row>
					<Col>
						<Form.Item
							label={I18n.t("general.status")}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
								{getFieldDecorator("integrationStatus", {
									valuePropName: 'checked' , initialValue : false
								})(
									<Switch
										checkedChildren="Yes"
										unCheckedChildren="No"
										className={ !integrationStatus ? "switchComponent" : null}
									/>
								) }
						</Form.Item>
					</Col>
					<Col>
						
						<IntegrationConfigTable
							data={tableData}
							// data={tableData === null ? [] : tableData}
							loading={isLoading}
							updateIntergrationConfig={this.updateIntergrationConfig}
							toggleConfigTableEdit={this.toggleConfigTableEdit}
							size="small"
						/>
						
					</Col>
					<Col>
						<Row type="flex" gutter={16} justify="center"  style={{ marginTop: '20px' }}>
							<Col>
								<Button
									onClick={() => {
										closeIntegrationModal();
										this.setState({
											isEdit: false,
										});
									}}
									icon="close"
								>
									Cancel
								</Button>{" "}
							</Col>
							<Col>
								<Button
									type="primary"
									htmlType="submit"
									loading={this.state.isLoading}
									icon="save"
								>
									{I18n.t("general.save") } 
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		);
	}
}

const IntegrationsForm = Form.create({ name: "integrations Form" })(
	IntegrationsFormWrapper
);
export default IntegrationsForm;

IntegrationsFormWrapper.propTypes = {
	formTitle: PropTypes.string,
	activeIntegrationId : PropTypes.string,
	closeIntegrationModal: PropTypes.func,
	updateIntegrationData : PropTypes.func,
};
IntegrationsFormWrapper.defaultProps = {
	formTitle: "",
	activeIntegrationId : "",
	closeIntegrationModal: () => {},
	updateIntegrationData : () => {},
};
