/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import _ from 'lodash';
import { fetchPackages, deletePackage } from '../api/Packages';
import { Button, Col, Row, Spin } from '../common/UIComponents';
import List from '../components/packages/List';
import BaseModal from '../components/BaseModal';
import Form from '../components/packages/Form';
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import AppConfig from '../config/AppConfig';
import ModuleMapping from './ModuleMapping';
import moment from 'moment';
import { renderAlertMessage } from '../helpers/common';

class Packages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            pagination: {},
            showFormModal: false,
            showConfiguration: false,
            currentPackage: {},
            inProgress: false,
            isNew: false,
            showConfigurationModal: false,
            currentAccessorial: {},
        };
        this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    }

    componentDidMount() {
        this.getPackages();
    }

    getPackages = (initial, cb) => {
        this.setState({ inProgress: true });
        const page = this.tableOptions.pagination.current;
        const perPage = initial ? initial.perPage : AppConfig.perPage;
        fetchPackages(page, perPage)
            .then((result) => {
                if (result.success) {
                    const packagesList = result.packages || [];
                    const packages = packagesList.map(pack => Object.assign({}, pack,{
                        live_on: pack.live_on ? moment(pack.live_on) : null,
                        shutdown_on: pack.shutdown_on ? moment(pack.shutdown_on) : null,
                    }));
                    this.setState({
                        packages,
                        pagination: packages.length > 0 && result.pagination ? result.pagination : {},
                        inProgress: false,
                    }, () => {
                        if (cb) {
                            cb();
                        }
                    });
                } else {
                    // renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }

    handleAddClick = () => {
        this.setState({ isNew: true, currentPackage: {}, showFormModal: true });
    }

    handleEditClick = (id) => {
        const index = _.findIndex(this.state.packages, ['id', id]);
        const currentPackage = Object.assign({}, this.state.packages[index]);
        this.setState({ currentPackage, isNew: false, showFormModal: true });
    }

    handleDeleteClick = (id) => {
        this.setState({ inProgress: true });
        deletePackage(id)
            .then((result) => {
                if (result.success) {
                    alertMessage(I18n.t('messages.deleted'));
                    this.getPackages();
                } else {
                    renderAlertMessage(result.errors)
                    this.setState({ inProgress: false });
                }
            });
    }

    handleSaveSuccess = (message) => {
        alertMessage(message);
        this.setState({ showFormModal: false }, () => {
            this.getPackages();
        });
    }

    handleOnModalClose = () => {
        if (this.state.showFormModal && this.state.currentPackage.id) {
            this.getPackages();
        }
        this.setState({ showFormModal: false, showConfigurations: false });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.tableOptions = { pagination, filters, sorter };
        this.getPackages();
    }

    handleOnConfigModalClose = () => {
        this.setState({
            showConfigurationModal: false,
        });
    }

    renderFormModal = () => {
        let title;
        if (this.state.isNew) {
            title = I18n.t('package.add');
        } else {
            title = I18n.t('package.update');
        }
        return (
            <BaseModal
                title={title}
                onCancel={() => this.handleOnModalClose()}
                width="60%"
                style={{ top: 20 }}
                maskClosable={false}
            >
                <Form
                    currentPackage={this.state.currentPackage.id ? this.state.currentPackage : {}}
                    isNew={this.state.isNew}
                    onCancel={() => this.handleOnModalClose()}
                    onSuccess={message => this.handleSaveSuccess(message)}
                />
            </BaseModal>
        );
    }

    renderConfigurationModal = () => (
        <BaseModal
            title={`${this.state.currentPackage ? `${this.state.currentPackage.name}: ` : ''} ${I18n.t('package.module_accessbility')}`}
            onCancel={() => this.handleOnConfigModalClose()}
            width="100%"
            style={{ top: 20 }}
        >
            <ModuleMapping currentPackage={this.state.currentPackage} onCancel={this.handleOnConfigModalClose} />
        </BaseModal>
    )

    doModuleMapping = (id) => {
        const index = _.findIndex(this.state.packages, ['id', id]);
        const currentPackage = Object.assign({}, this.state.packages[index]);
        this.setState({ currentPackage, showConfigurationModal: true });
    }

    render() {
        const { pagination } = this.state;
        return (
            <div className='content-outer'>
                <div className='content'>
                    <Row className='page-header'>
                        <Col xs={12}>
                            {I18n.t('menu.packages')}
                        </Col>
                        <Col xs={12} className='page-actions'>
                            <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                                {I18n.t('package.add')}
                            </Button>
                        </Col>
                    </Row>
                    <Spin spinning={this.state.inProgress} delay={1000}>
                        <Row>
                            <Col>
                                <List
                                    data={this.state.packages}
                                    editClick={id => this.handleEditClick(id)}
                                    deleteClick={id => this.handleDeleteClick(id)}
                                    doModuleMapping={id => this.doModuleMapping(id)}
                                    viewAccessorial={id => this.viewAccessorial(id)}

                                    //   pagination={{
                                    //     total: pagination.total_count,
                                    //     current: pagination.current_page,
                                    //     pageSize: pagination.per_page,
                                    //   }}
                                    tableChange={
                                        (tablePagination, filters, sorter) =>
                                            this.handleTableChange(tablePagination, filters, sorter)}
                                />
                            </Col>
                        </Row>
                    </Spin>
                    {this.state.showFormModal &&
                        this.renderFormModal()
                    }
                    {this.state.showConfigurationModal &&
                        this.renderConfigurationModal()
                    }
                </div>
            </div>
        );
    }
}
export default Packages;
