import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  DatePicker,
  Popconfirm,
  Row,
  Col,
  Tooltip,
  Icon,
  Checkbox,
  Select,
} from "antd";
import moment from "moment";
import { FormItem } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";
import { alertMessage, isEmpty } from "../../common/Common";
import { fetchContractsForLos, formatContractDate, manageContract } from "../../api/Los";
import { UserContext } from "../../context/UserContext";
import LosContracts from "../billing_screen/LosContracts";
import { getHighestStartDateRecord } from "../../helpers/configurations";

const AddNewContract = ({
  // handleApprove,
  contract,
  account_id,
  handleSuccess,
  size = "small",
  contracts
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [ev, setEv] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [withValues, setWithValues] = useState(true);
  const [losContract, setLosContract] = useState(null);
  const [selectedContract, setSelectedContract] = useState("");
  const disabledDate = (current) => {
    return contracts.some(item => {
      const start = moment.utc(item.effective_start_date);
      const end = item.effective_end_date ? moment.utc(item.effective_end_date) : null;
      if (end) {
        return current.isSameOrAfter(start) && current.isSameOrBefore(end)
      } else {
        return current < start;
      }
    });
  };

  
  const disableDates = (current) =>  {
    const uniqueStartDates = [...new Set(contracts.map(los => moment(los.effective_start_date).startOf('day').format()))];
    const highestStartDate = moment(Math.max(...uniqueStartDates.map(date => moment(date))));
    const currentDate = moment(current).startOf('day');
    for (const los of contracts) {
        const startDate = moment.utc(los.effective_start_date).startOf('day');
        const endDate = los.effective_end_date ? moment.utc(los.effective_end_date).startOf('day') : null;
        if (startDate.isSame(highestStartDate)) {
            if (endDate && currentDate.isSame(endDate)) {
                return false; // Enable the effective end date
            }
        } else {
            if (currentDate.isBetween(startDate, endDate || moment(), 'day', '[]')) {
                return true; // Disable dates between effective start and end dates
            }
        }
    }
    return false; // Enable other dates
  }

  useEffect(() => {
    if(losContract){
      setInitialData()
    }
  }, [losContract]);

  useEffect(() => {
    const highestStartDateRecord = getHighestStartDateRecord(contracts);
    const dateToBeConsider = highestStartDateRecord?.effective_end_date ? highestStartDateRecord.effective_end_date : (highestStartDateRecord?.effective_start_date ? highestStartDateRecord.effective_start_date : moment() )
    const date = moment.utc(dateToBeConsider).add("1", "day").format("YYYY-MM-DD")
    setFromDate(moment(date).startOf("day"));
    setToDate(null);
  }, [selectedContract]);

  const setInitialData = () => {
    setSelectedContract(contract?.los_id || '')
  }



  const [isLoading, setIsLoading] = useState(false);
  const { currentOrg } = useContext(UserContext);

  const handleButtonClick = () => {
    setLosContract(contract)
    setModalVisible(true);
    setInitialData()
  };

  const handleSave = () => {
    setModalVisible(false);
    handleCancel()
  };

  const handleCancel = () => {
    // Handle canceling action
    setLosContract(null)
    setWithValues(true)
    setFromDate(null);
    setToDate(null);
    setModalVisible(false);
    setSelectedContract('')
  };

  const handleApprove = () => {
    if(isEmpty(fromDate) || isEmpty(selectedContract)){
      alertMessage('Please fill all required(*) fields', 'error');
      return;
    }
    const payload = {
      org_id: currentOrg.id,
      account_id,
      effective_start_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      effective_end_date: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
      los_id: selectedContract,
      is_default: isDefault,
      with_rates: withValues,

    };
    setIsLoading(true);
    try {
      manageContract(payload).then((result) => {
        if (result.success) {
          const levelOfServices = result.los || [];
          alertMessage(I18n.t("messages.saved"));
          handleCancel();
          handleSuccess(levelOfServices);
        } else {
          renderAlertMessage(result.errors, "error", 10);
        }
      });
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Tooltip title={I18n.t("accessorials.contract.add")}>
        <Button
          shape="circle"
          onClick={(e) => {
            setEv(e);
            e.stopPropagation();
            handleButtonClick();
            e.stopPropagation();
          }}
        >
          <Icon type="plus" style={{ color: "green" }} />
        </Button>
      </Tooltip>
      <Modal
        title={I18n.t("accessorials.contract.add")}
        visible={modalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        size="small"
        footer={null}
        width={420}
        onClick={(event) => {
          event.stopPropagation();
          ev.stopPropagation();
        }}
        maskClosable={false}
      >
        <div className="textBold">Level of Service : {losContract?.los_name || ''}</div>
        <Row gutter={16} className="marginTop10">
          <Col className="textBold" xs={9}>
            Source {I18n.t("accessorials.contract.lable")}
            <sup className="textRed">*</sup>
          </Col>
          <Col xs={1}>:</Col>
          <Col xs={14}>
            {losContract && <LosContracts 
              los_code={losContract.los_code}
              account_id={account_id}
              handleChange={(isManual, val) => setSelectedContract(val)}
              contractId={selectedContract}
              size="small"
              style={{ width: 225 }}
            />
           }
          </Col>
        </Row>
        <Row gutter={16} className="marginTop10">
          <Col className="textBold" xs={11}>
            {I18n.t("accessorials.effective_start_date")}
            <sup className="textRed">*</sup>
          </Col>
          <Col xs={1}>:</Col>
          <Col xs={10}>
            <DatePicker
              style={{ width: "100%" }}
              onChange={(e) => {
                // ev.stopPropagation()
                setFromDate(e);
                if (toDate && e && moment(toDate).isBefore(e)) {
                  setToDate(null);
                  renderAlertMessage(
                    `${I18n.t(
                      "accessorials.effective_end_date"
                    )} should be after ${I18n.t(
                      "accessorials.effective_start_date"
                    )}`,
                    "error"
                  );
                }
              }}
              format={AppConfig.dateFormat}
              size="small"
              defaultValue={fromDate}
              allowClear={false}
              disabledDate={disableDates}
              value={fromDate}
            />
          </Col>
        </Row>
        <Row gutter={16} className="marginTop10">
          <Col className="textBold" xs={11}>
            {I18n.t("accessorials.effective_end_date")}
          </Col>
          <Col xs={1}>:</Col>
          <Col xs={10}>
            <DatePicker
              style={{ width: "100%" }}
              onChange={(e) => {
                //ev.stopPropagation()
                console.log(moment(fromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"), moment(e).startOf("day").format("YYYY-MM-DD HH:mm:ss"))
                if (fromDate && e &&  (moment(fromDate).startOf("day")).isAfter(moment(e).startOf("day"))) {
                  setToDate(null);
                  renderAlertMessage(
                    `${I18n.t(
                      "accessorials.effective_end_date"
                    )} should not be before ${I18n.t(
                      "accessorials.effective_start_date"
                    )}`,
                    "error"
                  );
                } else {
                  setToDate(e);
                }
              }}
              format={AppConfig.dateFormat}
              size="small"
              defaultValue={toDate}
              allowClear
              //disabledDate={disableDates}
              defaultPickerValue={toDate ? toDate : (fromDate || moment())}
              disabledDate={(current) =>
                current && ((fromDate && current < moment(fromDate).startOf("day")) || disableDates(current))
              }
              value={toDate}
            />
          </Col>
        </Row>
        {/* <Checkbox
          checked={isDefault}
          onChange={(e) => setIsDefault(e.target.checked)}
          className="marginTop10 textBold"
        >
          {I18n.t("general.marK_as_default")}
        </Checkbox> */}
        <Checkbox
          checked={withValues}
          onChange={(e) => setWithValues(e.target.checked)}
          className="marginTop10 textBold"
        >
          With Rate Charges
        </Checkbox>
        <div className="paddingTop20 alignCenter">
          <Button
            type="danger"
            icon="close"
            size="small"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          {/* <Popconfirm
              placement="topRight"
              title={I18n.t("messages.approve_confirm")}
              onConfirm={handleApprove}
              okText="Yes"
              cancelText="No"
              disabled={selectedKeys.length === 0}
            > */}
          <Button
            type="primary"
            icon="save"
            size="small"
            className="marginLeft10"
            loading={isLoading}
            onClick={handleApprove}
          >
            Save
          </Button>
          {/* </Popconfirm> */}
        </div>
      </Modal>
    </Fragment>
  );
};

export default AddNewContract;
