import React, { useState, useEffect, useContext } from 'react';
import { Form, Select, DatePicker, Button, Row, Col, Alert, Typography, Icon, TimePicker } from 'antd';
import { LineHaulApi } from '../../api/LineHaulApi';
import AppConfig from '../../config/AppConfig';
import { alertMessage } from '../../common/Common';
import LineHaulList from './LineHaulList';
import { checkServiceExistance, getAvailableDrivers, getDriverFullDisplayName, renderAlertMessage } from '../../helpers/common';
import moment from 'moment';
import { Card, FormItem, MaterialFormItem } from '../../common/UIComponents';
import UsersList from '../../components/preplan/rows/UsersList';
import MaterialTimePicker from '../../components/orders/MaterialTimePicker';
import { OrderConfigContext } from '../../context/OrderConfigContext';
import I18n from '../../common/I18n';

const { Text } = Typography;

const LinehaulRoute = (props) => {
  const { getFieldDecorator } = props.form;
  const { warehouses, selectedLhs, appliedFiltersTags, filterPlaceHolder } = props;

  const [ drivers, setDrivers ] = useState([]);
  const startingPoint = appliedFiltersTags.find((item) => item.filterKey === 'startingPoint')?.value;
  const destinationPoint = appliedFiltersTags.find((item) => item.filterKey === 'destinationPoint')?.value;

  const [ lhData, setLhData ] = useState(selectedLhs);
  const [ pagination, setPagination ] = useState({ current_page: 1 });
  const [ isfetchingLhs, setIsfetchingLhs ] = useState(false);
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);
  let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  const [ selectedInfo, setSelectedInfo ] = useState({
    selectedKeys: selectedLhs.map((item) => item.id),
    selectedRows: selectedLhs,
  });
  const [ selectedDrivers, setSelectedDrivers ] = useState({
    selectedKeys: [],
    selectedRows: [],
  });
  const [ routeStartTime, setRouteStartTime ] = useState(moment());
  
  const handleDriverSelection = (selectedRowKeys, selectedRows) => {
    setSelectedDrivers({
      selectedKeys: selectedRowKeys,
      selectedRows,
    });
  };

  const [ isfetchingDrivers, setIsfetchingDrivers ] = useState(false);
  useEffect(() => {

    const orderDate = selectedInfo.selectedRows[ 0 ].schedule_date;
    const startDate = moment(orderDate);
    const endDate = moment(orderDate);
    const startTime = moment(routeStartTime).format('HH:mm');
    setIsfetchingDrivers(true);
    const fetchDriversData = async () => {
      try {
        const availableDrivers = await getAvailableDrivers(startDate, endDate, startTime);
        setDrivers(availableDrivers);
        setIsfetchingDrivers(false);
      } catch (error) {
        console.error(error);
        renderAlertMessage(error.message);
        setIsfetchingDrivers(false);
      }
    };
    fetchDriversData();
  }, [ routeStartTime ]);


  const selectedWH = _.find(warehouses, { id: filterPlaceHolder.startingPoint }) 
  const driversRowSelection = {
    selectedRowKeys: selectedDrivers.selectedKeys,
    onChange: handleDriverSelection,
    getCheckboxProps: record => ({
      name: record.name,
    }),
    type: 'radio',
  };

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedInfo({
      ...selectedInfo,
      selectedKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || AppConfig.ordersPerPage,
  };


  const onTableChange = (pagination, filters, sorter, currentTable) => {
    tableOptions = { pagination, filters, sorter };
    const formVals = props.form.getFieldsValue();
    fetchFilteredLhs(formVals);
  };
  const { getFieldsValue } = props.form;

  const _onRouteSuccess = (result) => {
    alertMessage('Route created successfully', 'success', 5);
    setSelectedInfo({
      selectedKeys: [],
      selectedRows: [],
    });
    setSelectedDrivers({
      selectedKeys: [],
      selectedRows: [],
    });
    setLhData([]);
    props.handleCreationFormClose(result.route);
  };

  const handleAssignDriver = (e) => {
    e.preventDefault();
    const driver_id = selectedDrivers.selectedRows[ 0 ].id;
    const payload = {
      driver_id,
      linehaul_ids: selectedInfo.selectedKeys.join(','),
      schedule_date: moment(selectedInfo.selectedRows[ 0 ].appointment_date).tz(selectedWH.timeZoneId).format('YYYY-MM-DD'),
      // origin_location_id: filterPlaceHolder.startingPoint,
      // destination_location_id: filterPlaceHolder.destinationPoint,
      pickup_location_id: filterPlaceHolder.startingPoint,
      drop_location_id: filterPlaceHolder.destinationPoint,
      organization_id: props.organizationId,
      route_start_time: moment(routeStartTime , props.isMilitaryTime ? 'HH:mm' : 'hh:mm A').format('HH:mm'),
      warehouse_id: filterPlaceHolder.startingPoint,
    };
    setIsfetchingLhs(true);
    LineHaulApi.createRoute(payload)
      .then((result) => {
        // if (result.shipment && result.shipment.id) {
        if (result.route && result.route.id) {
          _onRouteSuccess(result);
        } else {
          alertMessage(result.errors[ 0 ], 'error', 10);
        }
      }
      )
      .finally(() => {
        setIsfetchingLhs(false);
      }
      );
  };


  return (
    <Form layout="vertical">
      <Row type="flex" align="middle" justify="space-between" style={ { margin: '16px 0' } } gutter={ 16 }>
        <Col style={ { fontSize: '16px', fontWeight: 'bold' } }>
          <Row gutter={ 16 } type='flex' align='middle' justify='space-between'>
            <Col >{ startingPoint }</Col>
            <Col ><Icon type="arrow-right" /></Col>
            <Col >{ destinationPoint }</Col>
          </Row>
        </Col>
        <Row gutter={ 16 } type='flex' align='middle' justify='space-between'>
          <Col>
            <label style={ { display: 'flex', alignItems: 'center' } }>
              Route Start Time:
            </label>
          </Col>
          <Col>
            <MaterialTimePicker
              style={ { width: '100%', marginLeft: '1%' } }
              value={ routeStartTime }
              onChange={ (time) => setRouteStartTime(time) }
              computeDisabledHours={ () => [] }
              isMilitaryTime={ props.isMilitaryTime }
              computeDisabledMinutes={ () => [] }
            />
          </Col>
        </Row>
      </Row>

      <Row gutter={ 16 }>
        <Col lg={ 12 } md={ 16 } sm={ 16 } xs={ 24 }>
          <FormItem label="Selected Linehauls" require>
            <LineHaulList
              data={ lhData }
              scroll={ { y: "60vh" , x: "max-content" } }
              isLoading={ isfetchingLhs }
              size="small"
              showMiniDetails={ true }
              showStatus={ true }
              showActions={ false }
              pagination={ tablePagination }
              tableChange={ (pagination, filter, sorter, currentTable) =>
                onTableChange(pagination, filter, sorter, currentTable)
              }
              selectedWH={ selectedWH }
              // find starting point in warehouse list 
              rowSelection={
                (!checkServiceExistance([ 'OREDEL', 'ORECS' ], 'ANY'))
                  ? null
                  : {
                    selectedRowKeys:
                      selectedInfo.selectedKeys.length > 0
                        ? selectedInfo.selectedKeys
                        : [],
                    onChange: handleSelectChange,
                    getCheckboxProps: (record) => ({
                      id: `${record.id}`,
                    }),
                  }
              }
              orderConfig={orderConfig}
            />
          </FormItem>
        </Col>
        <Col lg={ 12 } md={ 8 } sm={ 8 } xs={ 24 }>
          <FormItem label={ I18n.t('general.drivers') } requireColumn={ false } require>
            <UsersList
              showFleetDetails={ true }
              isLoading={ isfetchingDrivers }
              data={ drivers }
              showStops={ false }
              showLinks={ false }
              size="small"
              pagination={ { position: 'none' } }
              rowSelection={ driversRowSelection }
              scroll={ { y: 322 , x: "max-content" } } 
              showZones={ false }
              driverZones={ [] }
            />
          </FormItem>

        </Col>
      </Row>
      { ((selectedInfo.selectedKeys.length == 0 || selectedDrivers.selectedKeys.length == 0)) ? (
        <Alert message="Please select linehauls and a driver to create route" type="info" showIcon />
      ) :
        <Row style={ { textAlign: 'center' } }>
          <Col style={ {
            marginTop: '10px',
          } }>
            <Button type="primary" onClick={ handleAssignDriver } loading={ isfetchingLhs } icon='branches' >
              { isfetchingLhs ? 'Creating Route' : 'Create Route' }
            </Button>
          </Col>
        </Row>
      }
    </Form >
  );
};

const LinehaulRouteForm = Form.create({ name: 'linehaul_route_form' })(LinehaulRoute);

export { LinehaulRouteForm };
