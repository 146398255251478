import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  Row,
  Icon,
  Form,
  Modal,
  DatePicker,
  Spin,
  Button,
  Col,
  FormItem,
  Select,
} from "../../common/UIComponents";
import { withRouter } from "react-router";
import { updateInvGenDateManual } from "../../api/Billing";
import { alertMessage, isEmpty } from "../../common/Common";
import moment from "moment";
import { momentTime } from "../../helpers/date_functions";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";
import { fetchContractsForLos, formatContractDate } from "../../api/Los";
import { getHighestStartDateRecord } from "../../helpers/configurations";

const LosContracts = (props) => {
  const { account_id, los_code , contractId, handleChange, style, size="small", showDefaultValue = true} = props;
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!isEmpty(los_code)){
      getContractsForLos()
    }
  }, [los_code, contractId])

  const getContractsForLos = async () => {
    try {
      setLoading(true)
      const result = await fetchContractsForLos(account_id, los_code);
      if (result.success) {
        const contracts_list = result.contracts || [];
        setContracts(contracts_list);
        const losIndex = _.findIndex(contracts_list, { code: los_code, id:contractId });
        if(losIndex>=0){
          
        }else{
          if(showDefaultValue) {
            const highestStartDateRecord = getHighestStartDateRecord(contracts_list);
            handleChange(false, highestStartDateRecord?.id || '')
          }
          
        }
      }
    } catch (error) {
      const errorMessage =
        "An error occurred while fetching contracts. Please try again later.";
      renderAlertMessage(errorMessage);
    } finally{
        setLoading(false)
    }
  };

  return (
    <Fragment>
      <Select
        size={size}
        // value={this.getContractValue(order.order_id)}
        value={contractId || ""}
        showSearch
        style={{ width: 200, ...style }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        // onChange={(value) => this.handleContractsChange(order.order_id, value)}
        onChange={(value) => {
          handleChange(true, value);
        }}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option key="Contracts" value="">
          -- Select --
        </Select.Option>
        {contracts.map((contract) => (
          <Select.Option key={contract.id} value={contract.id}>
            {formatContractDate(contract)}
          </Select.Option>
        ))}
      </Select>
    </Fragment>
  );
};

LosContracts.propTypes = {
  route: PropTypes.shape().isRequired,
};

export default withRouter(LosContracts);
