import React, { Component,useContext } from 'react';
import _ from 'lodash';
import { Button, Col, Row, Spin } from '../../common/UIComponents';
import BaseModal from '../../components/BaseModal';
import { alertMessage,  isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import AppConfig from '../../config/AppConfig';
import { saveWHZones, deleteWHZone } from '../../api/DeliveryZones';
import { checkServiceExistance, renderAlertMessage } from '../../helpers/common';
import { withRouter } from 'react-router';
import { OrgContext } from '../../context/OrgContext';
import { UserContext } from '../../context/UserContext';
import userStore from '../../stores/UserStore';
import { RecordsPerPage } from '../../components/orders/RecordsPerPage';
import { ScreenKeys } from '../../containers/constants';
import AgentFormComponent from './AgentForm';
import AgentList from './AgentList';
import { deleteAgent, fetchAgent } from '../../api/Agent';
class Agent extends Component {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem('round_off_decimals');
    const zeroValue = Number((0).toFixed(decimalPoints));
    this.state = {
      Locations: [],
      pagination: {},
      showFormModal: false,
      showZones: false,
      showMapping: false,
      currentLocation: {},
      inProgress: false,
      zones: [],
      errors: [],
      zoneErrors: [],
      organizationSettings: {},
      isMilitaryTime: false,
      organization_id: '',
      token: props && props.location && !isEmpty(props.location.token) ? props.location.token : '',
      organizations: [],
      recordsPerPage: props.recordsPerPage,
      decimalPoints,
      zeroValue,
      filterPlaceHolder:{
        sortBy: "none",
        sortByType: "descend",
      }
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }


  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContextData;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getAgents()
          updateRecordsPerPage(this.props.perPageKey, value);
      }
    );
  };


  componentDidMount() {
    const { isSuperAdmin } = this.props
    if(!isSuperAdmin){
      this.getAgents();
    }
    this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true' }) 
  }

  componentDidUpdate (prevProps, prevState) {
    if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
      this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
    }
  }


  getAgents = (initial, cb) => {
    this.setState({ inProgress: true });
    const page = this.tableOptions.pagination.current;
    const { currentOrg } = this.props.userContextData;
    const searchText = this.state.token || '';
    const perPage = initial ? initial.perPage : this.state.recordsPerPage;
    const { isSuperAdmin } = this.props
    const org_id = isSuperAdmin ? this.state.organization_id : (currentOrg?.id || "");
    fetchAgent(org_id, page, perPage)
      .then((result) => {
        if (result.success) {
          this.setState({
            Locations: result.agents,
            pagination: result.pagination,
            inProgress: false,
          }, () => {
            if (cb) {
              cb();
            }
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }


  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  }

  onSearch = (text) => {
    const token = text.trim();
    this.setState(
      {
        token,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getAgents();
      }
    );
  };

 

  handleAddClick = () => {
    this.setState({ currentLocation: {}, showFormModal: true });
  }

  handleEditClick = (id) => {
    const index = _.findIndex(this.state.Locations, ['id', id]);
    const location = Object.assign({}, this.state.Locations[index]);
    this.setState({ currentLocation: location, showFormModal: true });
  }

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteAgent(id)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'));
          this.getAgents();
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false }, () => {
      this.getAgents();
    });
  }

  handleOnModalClose = () => {
    this.setState({ showFormModal: false },()=>{
      this.getAgents();
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
      this.tableOptions?.pagination?.current
        ? this.tableOptions.pagination.current
        : 1
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getAgents();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.tableOptions.pagination.current = 1;
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
          }),
        },
        () => {
          this.getAgents();
        }
      );
    }
  }


  renderFormModal = () => {
    let title;
    let isNew;
    if (this.state.currentLocation.id) {
      title = "Edit Agent";
      isNew = false;
    } else {
      title = "Add Agent";
      isNew = true;
    }
    const { currentOrg } = this.props.userContextData;
    return (
      <BaseModal
        title={title}
        onCancel={() => this.handleOnModalClose()}
        width="60%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <AgentFormComponent
          whlocation={this.state.currentLocation.id ? this.state.currentLocation : {}}
          organization_id={currentOrg?.id ? currentOrg.id : ''}
          selectedLocation={this.state.currentLocation}
          isNew={isNew}
          onCancel={() => this.handleOnModalClose()}
          onSuccess={message => this.handleSaveSuccess(message)}
          zones={this.state.zones}
          showFormModal = {this.state.showFormModal}
        />
      </BaseModal>
    );
  }

  render() {
    const { pagination } = this.state;
    const { isSuperAdmin } = this.props
    return (
      <div className='content-outer'>
        <div className='content'>
          <Row className='page-header'>
            <Col xs={12}>
              {"Agent"}
            </Col>
            <Col xs={12}>
            <Row gutter={6}justify="end" style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
            <Col className='page-actions'>
              { checkServiceExistance('WHC') && <Button type="primary" onClick={() => this.handleAddClick()} icon='plus'>
                {"Create Agent"}
              </Button>
              }
            </Col>
            </Row>
            </Col>
          </Row>
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row className="page-content">
              <Col xs={24}>
                <Row style={{ float: "right", marginBottom: "-18px" }}>
                  <RecordsPerPage
                    onChange={this.onRecordChange}
                    onSearch={this.onSearch}
                    value={this.state.recordsPerPage}
                    defaultValue={this.state.recordsPerPage}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                { checkServiceExistance('WHI') && 
                <AgentList
                  isMilitaryTime={this.state.isMilitaryTime}
                  data={this.state.Locations}
                  scroll = {{y:"calc(100vh - 270px)"}}
                  editClick={id => this.handleEditClick(id)}
                  deleteClick={id => this.handleDeleteClick(id)}
                  pagination={{
                    total: pagination?.total_count,
                    current: pagination?.current_page,
                    pageSize: pagination?.per_page || this.state.recordsPerPage,
                  }}
                  tableChange={
                    (tablePagination, filters, sorter) =>
                      this.handleTableChange(tablePagination, filters, sorter)}
                />
              }
              </Col>
            </Row>
          </Spin>
          {this.state.showFormModal &&
            this.renderFormModal()
          }
        </div>
      </div>
    );
  }
}
 const AgentComponent = withRouter((props) => {
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const userContextData = useContext(UserContext);
   const { currentRole } = userContextData;
   const isSuperAdmin = currentRole === 'super_admin';
   const perPageKey = isSuperAdmin ? ScreenKeys.WAREHOUSE_LISTING_SUPER_ADMIN : ScreenKeys.WAREHOUSE_LISTING;
   const recordsPerPage = !isSuperAdmin ? _.get(userContextData, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage) : AppConfig.ordersPerPage
  return (
    <Agent
      organizationSettings={organizationSettings}
      userContextData={userContextData}
      recordsPerPage={ recordsPerPage }
      isSuperAdmin={ isSuperAdmin }
      perPageKey={ perPageKey }
      {...props}
    />
  );
});

export default AgentComponent;