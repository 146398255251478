import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Popconfirm,
  Tooltip,
  Icon,
  Select,
  Option,
} from "../../common/UIComponents";
import { alertMessage, isEmpty } from "../../common/Common";
import { _ } from "core-js";
import BaseModal from "../BaseModal";
import FormErrors from "../common/FormErrors";
import { emailWeeklyReport } from "../../api/Reports";
import I18n from "../../common/I18n";
import { UserContext } from "../../context/UserContext";
import { validateEmailList } from "../billing_screen/helpers";
import { setBillingConfig } from "../../api/Account";
import excelIcon from "../../../assets/images/excel.png";


const ExportToEmail = ({
  className,
  displayType,
  size = 'small',
  limit = 100,
  availableRecords = 0,
  handleReportExport,
  tooltopTitle ="Export",
  handleSendingMails,
  loading = false,
}) => {
  const userContext = useContext(UserContext);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [emails, setEmails] = useState(
    userContext?.currentUser.email ? [userContext.currentUser.email] : []
  );

  const resetValues = (closeModal = false) => {
    setErrors([]);
    setEmails([]);
    setShowInfoWindow(false);
  };

  const addBilligEmail = (value) => {
    const validEmails = validateEmailList(value);
    setEmails(validEmails);
  };

  // const handleSendingMails = async () => {
  //   try {
  //     const payload = {
  //       driver_pay_report_id,
  //       emails: [...emails],
  //     };
  //     setLoading(true);
  //     let response = await emailWeeklyReport(driver_pay_report_id, payload);
  //     if (response.success) {
  //       const result = response?.data ? response.data : {};
  //       const successMessage = result?.message
  //         ? result.message
  //         : I18n.t("messages.saved");
  //       //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
  //       if (result.errors && result.errors.length > 0) {
  //         setErrors(result.errors);
  //       } else {
  //         alertMessage(successMessage, "success", 5);
  //         resetValues();
  //         setShowInfoWindow(false);
  //       }
  //       setEmails([]);
  //     } else {
  //       setErrors([...response.errors]);
  //       // throw new Error(response.errors[0])
  //     }
  //   } catch (error) {
  //     alertMessage(error.message, "error", 10);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={I18n.t("general.send_to", {
          from: `${I18n.t("reports.lebel")} `,
          to: "following emails",
        })}
        className="customer-orders-modal"
        onCancel={() => {
          resetValues();
        }}
        width="45%"
        maskClosable={false}
      >
        <Row className="tagsInput" type="flex" align="middle" gutter={16}>
          <Col xs={24}>
            <h4 style={{ fontWeight: "bold", fontSize: "13px" }}>
              {I18n.t("general.export_orders")}
            </h4>
          </Col>
          <Col span={21} className="emailForm">
            <Select
              value={emails}
              onChange={addBilligEmail}
              mode="tags"
              maxTagCount={1}
              validationRegex={addBilligEmail}
              tokenSeparators={[","]}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Enter or select Emails"
            >
              {userContext?.currentUser?.email ? (
                <Option key={userContext.currentUser.email}>
                  {userContext.currentUser.email}
                </Option>
              ) : (
                ""
              )}
            </Select>
          </Col>
          <Col span={3}>
            <Popconfirm
              placement="topRight"
              title={I18n.t("messages.confirm")}
              onConfirm={() => handleSendingMails(emails, resetValues)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                loading={loading}
                disabled={!emails.length}
                icon="check-circle"
                style={{ marginLeft: -10 }}
              >
                Send
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        {FormErrors(errors)}
      </BaseModal>
    );
  };

  const handleLimitValidate = () => {
    if(!isEmpty(limit) && limit <= availableRecords){
      setShowInfoWindow(true)
    } else {
      handleReportExport()
    }
  }

  return (
    <Fragment>
      <Tooltip title={tooltopTitle}>
        {displayType === "icon" ? (
          <Icon
            onClick={handleLimitValidate}
            type="mail"
            className={className ? className : ""}
          />
        ) : displayType === "image" ? (
          <img
            src={excelIcon}
            className="cursorPointer"
            onClick={handleLimitValidate}
            style={{
              width: 24,
              marginTop: "-2px",
            }}
          />
        ) : (
          <Button
            type="danger"
            icon="mail"
            size={!isEmpty(size) ? size : "default"}
            onClick={handleLimitValidate}
          >
            {" "}
            Email
          </Button>
        )}
      </Tooltip>
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  );
};

ExportToEmail.propTypes = {
  driver_pay_report_id: PropTypes.string.isRequired,
  driver_code: PropTypes.string.isRequired,
  week_no: PropTypes.string.isRequired,
  handleReportExport: PropTypes.func.isRequired,
  handleSendingMails: PropTypes.func.isRequired
};

ExportToEmail.defaultProps = {
  handleSendingMails: () => {},
  handleReportExport: () => {},
};

export default ExportToEmail;
