import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FormItem, DatePicker, Button } from "../../common/UIComponents";
import { fetchOrgAccounts, fetchShortFormAccounts } from "../../api/Account";
import AppConfig from "../../config/AppConfig";
import AccountCodesFilter from "../../containers/Filters/AccountCodesFilter";
import WarehouseCodeFilter from "../common/WarehouseCodeFilter";
import I18n from "../../common/I18n";
import _ from "lodash";

const { RangePicker } = DatePicker;
const defaultFilterValues = {
  fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
  toDate: moment(),
  warehouse_id: [],
};
class CustomerLogFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || Object.assign({}, defaultFilterValues),
      accounts: this.props.accounts,
      filteredAccounts: [],
      accountsLoading: false,
    };
  }

  componentDidMount() {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;
    this.handleFilterPlaceHolderChange("warehouse_id", currentWhId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.filter, this.props.filter)) {
      this.setState({ filter: _.cloneDeep(this.props.filter) });
    }
  }

 
 

  updateFilter = (filter) => {
    this.handleFilterPlaceHolderChange(filter, () => {
      if (typeof this.props.handleChildFilterChange === "function") {
        this.props.handleChildFilterChange(filter);
      }
    });
  };

  handleDateRangeChange = (dates, dateStrings) => {
    let dateFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
        toDate: moment(),
      });
    } else {
      dateFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    this.setState({
      filter: dateFilter,
    });
  };

  handleFilterPlaceHolderChange = (element, value) => {
    const filter = Object.assign({}, this.state.filter, {
      [element]: value,
    });

    this.setState(
      {
        filter,
      },
    );
  };

  clearForm = () => {
    const { warehouseFilter } = this.props;
    const currentWhId = Array.isArray(warehouseFilter.selectedWarehouses)
      ? warehouseFilter.selectedWarehouses
      : warehouseFilter?.selectedWarehouses
      ? [warehouseFilter.selectedWarehouses]
      : null;

    const filter = {
      fromDate: moment().subtract(AppConfig.invoices_filter_duration, "day"),
      toDate: moment(),
      accountCodes: [],
      warehouse_id: currentWhId,
    };
    this.setState({ filter }, () => {
      this.handleFilterPlaceHolderChange("warehouse_id", currentWhId);
      this.props.onChange(filter, () => {
        this.props.onSearch();
      });
    });
  };

  _renderRangePicker = () => {
    return (
      <FormItem label="Date Range">
        <RangePicker
          value={[this.state.filter.fromDate, this.state.filter.toDate]}
          ranges={AppConfig.dateRanges}
          format={AppConfig.dateFormat}
          style={{ width: "100%" }}
          allowClear
          onChange={this.handleDateRangeChange}
        />
      </FormItem>
    );
  };

  _renderWhFilter = () => {
    const { warehouses } = this.props.warehouseFilter;
    return (
      <FormItem label={I18n.t("location.location")}>
        <WarehouseCodeFilter
          SelectedCodes={this.state.filter.warehouse_id}
          element={"warehouse_id"}
          onValueChange={this.handleFilterPlaceHolderChange}
          warehouses={warehouses}
          size="default"
        />
      </FormItem>
    );
  };

  handleSearch = () => {
    this.props.onSearch(this.state.filter);
  };

  render() {
    return (
      <Fragment>
        <div>
          {this._renderWhFilter()}
          {this._renderRangePicker()}
          <div style={{ padding: "10px 16px", textAlign: "center" }}>
            <Button
              type="danger"
              onClick={this.clearForm}
              icon="redo"
              disabled={this.props.disableButton}
            >
              Reset
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              onClick={() => this.handleSearch()}
              type="primary"
              icon="search"
              disabled={this.props.disableButton}
            >
              Search
            </Button>
            &nbsp;&nbsp;
          </div>
        </div>
      </Fragment>
    );
  }
}

CustomerLogFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
CustomerLogFilter.defaultProps = {};
export default withRouter(CustomerLogFilter);
