import React, { Component, Fragment } from "react";
import _ from "lodash";
import {
  FormItem,
  Input,
  Row,
  Button,
  Col,
  Checkbox,
  Select,
} from "../../common/UIComponents";

const options = [
  {
    option_key: "PL",
    option_label: "Percentage / Flat",
  },
  {
    option_key: "RANGE",
    option_label: "Weight Range",
  },
  // {
  //   option_key: "HOURLY",
  //   option_label: "Hourly",
  // },
];
class DriverRateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverRate: this.props.driverRate,
    };
  }

  render() {
    const { driverRate = {}, isNew } = this.props;
    const { category_configuration = {} } = driverRate;
    return (
      <React.Fragment>
        <Row gutter={8}>
          <Col xs={12}>
            <FormItem label="Rate Name" require>
              <Input
                value={driverRate.name}
                onChange={(e) =>
                  this.props.handleDriverRateFormInputChage(
                    "name",
                    e.target.value
                  )
                }
                size="small"
              />
            </FormItem>
          </Col>
          {/* <Col xs={6}>
            <FormItem label={I18n.t("adjustments.category")} require>
              <Select
                showSearch
                value={driverRate.category}
                style={{ width: "100%" }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) =>
                  this.props.handleDriverRateFormInputChage("category", e)
                }
                size="small"
              >
                {options.map((qoption, qindex) => (
                  <Select.Option
                    key={qoption.option_key}
                    value={qoption.option_key}
                  >
                    {qoption.option_label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col> */}
          <Col xs={12}>
            <FormItem label="Rate Code" require>
              <Input
                value={driverRate.code}
                onChange={(e) =>
                  this.props.handleDriverRateFormInputChage(
                    'code',
                    e.target.value,
                  )
                }
                size="small"
              />
            </FormItem>
          </Col>
          {driverRate?.category && (
            <Fragment>
              {driverRate.category === "RANGE" && (<Col xs={4}>
                <Checkbox
                  onChange={(e) =>
                    this.props.handleDriverRateFormInputChage(
                      "category_configuration",
                      {
                        is_smp_applied: e.target.checked + "",
                      }
                    )
                  }
                  checked={category_configuration?.is_smp_applied == "true"}
                  style={{ marginTop: 20, marginLeft: 10 }}
                >
                  Require SMP
                </Checkbox>
              </Col>)}
              <Col xs={4}>
                <Button
                  type="primary"
                  onClick={() => this.props.handleWeightRangeSave()}
                  style={{ marginLeft: "1rem",  marginTop: 20 }}
                  size="small"
                  icon="save"
                >
                  Save
                </Button>
              </Col>
            </Fragment>
          )}
        </Row>
        {isNew && (
          <Row gutter={8}>
            <Col xs={12}>
              <Checkbox
                checked={driverRate.is_default === "YES"}
                onChange={(e) =>
                  this.props.handleDriverRateFormInputChage(
                    "is_default",
                    e.target.checked
                  )
                }
              >
                Set as Default
              </Checkbox>
            </Col>
          </Row>
        )}

        {this.props.showActions !== false && (
          <Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="default"
                onClick={() => this.props.handleDriverRateClear()}
              >
                Clear
              </Button>
              <Button
                type="primary"
                onClick={() => this.props.handleDriverRateSave()}
                style={{ marginLeft: "1rem" }}
              >
                Save
              </Button>
            </div>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default DriverRateForm;
