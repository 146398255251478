import React from "react";
import BaseList from "../../BaseList";
import { Button } from "../../../common/UIComponents";
import { isEmpty } from "../../../common/Common";
import I18n from "../../../common/I18n";

class BillingAccountIntegrationForm extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: I18n.t("billing_accounts.label"),
        dataIndex: "name",
        key: "name",
        width: "40%",
      },
      {
        key: I18n.t("general.authorization"),
        title: I18n.t("general.authorization"),
        dataIndex: "id",
        width: "60%",
        render: (id, record, index) => {
          const { currentOrgIntegration } = this.props;
          // if (currentOrgIntegration.settings) {
          if (!isEmpty(record.org_integration_id) && record.status === 'ACTIVE') {
            return (
              <Button
                disabled={true}
                style={{
                  background: "rgb(76 209 55 / 65%)",
                  color: "#fff",
                }}
                size="small"
              >
                {I18n.t("general.authorized")}
              </Button>
            );
          } else {
            return (
              <Button
                disabled={!currentOrgIntegration.status}
                type="primary"
                onClick={() =>
                  this.props.onAuthorization(currentOrgIntegration,id)
                }
                size="small"
              >
                {I18n.t("general.authorize")}
              </Button>
            );
          }
        },
      },
    ];
  }
}
export default BillingAccountIntegrationForm;
