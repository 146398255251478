import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  FormItem,
  Input,
  Tabs,
  Row,
  Select,
  Button,
  Icon,
  Switch,
  Popconfirm,
} from '../../../common/UIComponents'
import FormButtons from '../../common/FormButtons'
import {
  alertMessage,
  doValidate,
  formatCode,
} from '../../../common/Common'
import I18n from '../../../common/I18n'
import { saveContactGroup } from '../../../api/ContactGroup'
import _ from 'lodash'
import FormErrors from '../../common/FormErrors'
import { fetchContacts } from '../../../api/Contacts'
import EventSubscriptionForm from '../Contacts/EventSubscriptionForm'
import ContactsList from './ContactsList'
import values from 'core-js/fn/array/values'
const { TextArea } = Input
const { TabPane } = Tabs

const requiredFields = [
  {
    form_field: 'name',
    ui_name: I18n.t('general.name'),
    isRequired: true,
    inputType: 'text',
  },
  // {
  //   form_field: "code",
  //   ui_name: I18n.t("general.code"),
  //   isRequired: true,
  //   inputType: "code",
  // },
]

class ContactGroupForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData:
        this.props.Data && this.props.Data.id
          ? this.props.Data
          : {
              //code: "",
              name: '',
              description: '',
              contact_ids: [],
              alerts: [],
              active: true,
            },
      inProgress: false,
      errors: [],
      isNew: this.props.isNew,
      contacts: [],
      activeKey: 'details',
      selectedContact: this.props.selectedContact
        ? this.props.selectedContact
        : {},
      checkedKeys: {
        selectedKeys: [],
        selectedRows: [],
      },
      searchKey: '',
      selectedContacts: [],
      addingContacts: [],
      infoList: [],
      //isEdit: false,
      //showEventSubscriptionForm: false,
      paginationConfig: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableOptions: {
        pagination: {},
      },
    }
  }

  componentDidMount() {
    this.getContacts()
    if (this.props.isAlertClicked) {
      this.goToTab('alert')
    }
  }

  getContacts = (initial, cb) => {
    const isOrgLevel = this.props.isOrgLevel
    this.setState({ inProgress: true })

    fetchContacts(1, null, isOrgLevel).then((result) => {
      if (result.success) {
        this.setState(
          {
            contacts: result.contacts || [],
            //pagination: result.pagination || {},
            inProgress: false,
          },
          () => {
            if (this.state.searchKey !== '') {
            }
            if (cb) {
              cb()
            }
          },
        )
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({ inProgress: false })
      }
    })
  }

  handleOnChange = (element, value) => {
    const { contacts } = this.state
    const { contact_ids } = this.state.formData
    if (element === 'selectedContacts') {
      if (value.includes('ALL')) {
        // || value.length === this.state.selectedContacts.length
        value = ['ALL']
        this.setState({
          selectedContacts: contacts
            .filter((contact) => !contact_ids.includes(contact.id))
            .map((contact) => contact.id),
        })
      } else {
        this.setState({
          selectedContacts: [...value],
        })
      }
    } else {
      this.setState({
        formData: Object.assign({}, this.state.formData, { [element]: value }),
      })
    }
  }

  addContacts = () => {
    const { selectedContacts } = this.state;
    const{contact_ids} = this.state.formData;
    this.setState({
      formData: Object.assign({}, this.state.formData, {
        contact_ids: [].concat(selectedContacts,contact_ids),
      }),
      selectedContacts: [],
    })
  }

  goToTab = (key) => {
    this.setState({
      activeKey: key,
    })
  }

  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      formData: Object.assign(
        {},
        {
          ...this.state.formData,
          contact_ids: selectedRowKeys,
        },
      ),
    })
  }

  onKeyChange = (element, value) => {
    const keyword = value
    const token = keyword.trim()
    if (token !== '') {
      const filteredResult = this.state.contacts.filter((contactList) => {
        return contactList.first_name
          .toLowerCase()
          .startsWith(token.toLowerCase())
      })
      this.setState({ filteredContacts: filteredResult })
    } else {
      this.setState({ filteredContacts: this.state.contacts })
    }

    this.setState({
      [element]: value,
    })
  }
  onTableChange = (tablePagination) => {
    let currentPage = null
    if (!_.isEmpty(this.state.tableOptions.pagination)) {
      currentPage = this.state.tableOptions.current
    } else {
      currentPage = 1
    }
    if (tablePagination.current !== currentPage) {
      this.setState({
        tableOptions: {
          pagination: tablePagination,
        },
      })
    }
  }

  renderStatus = () => {
    return (
      <Switch
        checked={this.state.formData.active === true}
        checkedChildren={'Yes'}
        unCheckedChildren={'No'}
        className={!this.state.formData.active ? 'switchComponent' : null}
        onChange={() =>
          this.handleOnChange('active', !this.state.formData.active)
        }
      ></Switch>
    )
  }

  _renderDetails = () => {
    // const selectedRowKeys = this.state.checkedKeys.selectedKeys;
    const currentPage =
      this.state.tableOptions &&
      this.state.tableOptions?.pagination &&
      this.state.tableOptions.pagination?.current
        ? this.state.tableOptions.pagination.current
        : 1
    const selectedRowKeys = this.state.formData.contact_ids
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
      getCheckboxProps: (record) => ({
        disabled: record.id === '1234', // Column configuration not to be checked
        name: record.name,
      }),
    }
    const { contacts } = this.state
    const contact_ids = [...this.state.formData.contact_ids]
    const availableContacts = contacts.filter(
      (item) => !contact_ids.includes(item.id),
    )

    const selectedList = contacts.filter((item) =>
      contact_ids.includes(item.id),
        )

    return (
      <Fragment>
        <Row gutter={8}>
          <Col xs={12}>
            <FormItem label={I18n.t('general.name')} require>
              <Input
                value={this.state.formData.name}
                onChange={(e) => this.handleOnChange('name', e.target.value)}
              />
            </FormItem>
          </Col>

          <Col
            xs={12}
            style={{ paddingTop: 25, paddingLeft: 15 }}
            className="textBold paddingtop20"
          >
            {I18n.t('form.status')}:&nbsp;&nbsp;
            {!this.state.isNew ? (
              <Popconfirm
                title={I18n.t('messages.change_status')}
                okText="Yes"
                cancelText="No"
                onConfirm={() =>
                  this.handleOnChange('active', !this.state.formData.active)
                }
              >
                <Switch
                  checked={this.state.formData.active === true}
                  checkedChildren={'Yes'}
                  unCheckedChildren={'No'}
                  className={
                    !this.state.formData.active ? 'switchComponent' : null
                  }
                ></Switch>
              </Popconfirm>
            ) : (
              this.renderStatus()
            )}
          </Col>

          <Col xs={24}>
            <FormItem label={I18n.t('general.description')}>
              <TextArea
                placeholder={I18n.t('OrderStatus.description')}
                className="sop-form-textArea"
                value={this.state.formData.description}
                onChange={(e) =>
                  this.handleOnChange('description', e.target.value)
                }
                autoSize
              />
            </FormItem>
          </Col>
        </Row>
        {/* <Row gutter={8}>
          <Col xs={12}>
            <FormItem label={I18n.t("form.status")} layout="horizontal">
              <Popconfirm
                title={I18n.t("messages.change_status")}
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.handleOnChange('active', !this.state.formData.active)}>
                <Switch
                  checked={this.state.formData.active === true}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}>
                </Switch>
              </Popconfirm>
            </FormItem>
          </Col>
        </Row> */}
        <Row gutter={8}>
          <Col xs={18}>
            <FormItem label="Contacts">
              <Select
                mode="multiple"
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder="Select"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.handleOnChange('selectedContacts', e)}
                value={this.state.selectedContacts || []}
              >
                {availableContacts.length > 0 && (
                  <Select.Option key="all" value="ALL">
                    All
                  </Select.Option>
                )}
                {availableContacts.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.first_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col xs={2}>
            <Button
              type="primary"
              style={{marginTop: 25}}
              onClick={() => this.addContacts()}
              size="small"
              icon="plus"
            >
              {I18n.t('general.add')}
            </Button>
          </Col>
        </Row>
        <Row className="marginTop20">
          <FormItem label={I18n.t('menu.contacts')}>
            <ContactsList
              data={selectedList}
              size="small"
              scroll={{ y: 250 }}
              pagination={{
                current: currentPage,
                pageSize: 5,
                total: this.state.addingContacts.length,
              }}
              // pagination={this.state.paginationConfig}
              tableChange={(pagination, filter, sorter, currentTable) =>
                this.onTableChange(pagination, sorter, currentTable)
              }
              handleDelete={this.handleDelete}
            />
          </FormItem>
        </Row>
        <Row>{FormErrors(this.state.errors)}</Row>
        <Row>
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.props.onCancel,
            )}
          </Col>
        </Row>
      </Fragment>
    )
  }

  handleDelete = (id) => {
    const { contact_ids } = this.state.formData
    const updatedIds = contact_ids.filter((cid) => cid !== id)
    this.handleOnChange('contact_ids', updatedIds)
  }

  handleSave = () => {
    this.setState({
      inProgress: true,
    })
    const data = Object.assign({}, this.state.formData)
    if (this.state.isNew) {
      data.alerts = []
    }
    const isOrgLevel = this.props.isOrgLevel
    const errors = doValidate(requiredFields, this.state.formData)
    if (errors.length === 0) {
      // this.state.formData.code = formatCode(this.state.formData.code);
      const accountId = this.props.account?.id ? this.props.account.id : ''
      saveContactGroup(this.state.isNew, data, isOrgLevel, accountId).then((result) => {
        if (result.success) {
          // alertMessage(I18n.t('messages.saved'))
          const contacts_groups = result.contacts_groups || {}
          const selectedContact = { ...contacts_groups }
          this.setState({
            errors: [],
            inProgress: false,
            selectedContact,
            formData: {
              ...this.state.formData,
              ...selectedContact,
              // contact_ids:
              //   this.state.formData.contact_ids === null ||
              //   this.state.formData.contact_ids[0] === ''
              //     ? []
              //     : this.state.formData.contact_ids,
            },
          })
          if (this.state.isNew) {
            this.setState({
              isNew: false,
            })
            this.goToTab('alert')
          }
          alertMessage(I18n.t('messages.saved'))
          // else {
          //   this.props.onSuccess(I18n.t("messages.saved"));
          // }
        } else {
          this.setState({ errors: result.errors, inProgress: false })
        }
      })
    } else {
      this.setState({
        errors,
        inProgress: false,
      })
    }
  }

  hideSubscriptionForm = () => {
    this.props.onCancel()
    this.setState({ selectedContact: {} })
  }

  // onSubscriptionSuccess = (message) => {
  //   // hide the form
  //   // hide subscription form
  //   alertMessage(message);
  //   this.hideSubscriptionForm();
  // };

  render() {
    return (
      <div className="card-container tabHover">
        <Tabs
          activeKey={this.state.activeKey}
          onChange={this.goToTab}
          type="card"
        >
          <TabPane tab={I18n.t('general.details')} key="details">
            {this._renderDetails()}
          </TabPane>
          {this.state.selectedContact.id && (
            <TabPane
              tab={I18n.t('configurations.contact.alertLabel')}
              key="alert"
            >
              <EventSubscriptionForm
                onCancel={this.hideSubscriptionForm}
                initialValues={this.state.selectedContact}
                onSave={this.props.onSuccess}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    )
  }
}

export default ContactGroupForm

ContactGroupForm.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  isOrgLevel: PropTypes.bool,
  isNew: PropTypes.bool,
  selectedContact: PropTypes.object,
  Data: PropTypes.object,
  isAlertClicked: PropTypes.bool,
}

ContactGroupForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
  isOrgLevel: false,
  isNew: false,
  selectedContact: {},
  Data: {},
  isAlertClicked: false,
}
