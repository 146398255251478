import React, { Component } from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Map from './Map';
import { Button, Col, Row, Switch, Link } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';

import pickupImage from '../../../assets/images/minipickup.svg';
import dropImage from '../../../assets/images/minidrop.svg';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeData: props.routeData,
      updateStatus: props.updateStatus,
      disabled: false,
      render: false,

    };
  }

  componentDidMount() {
    this.setState({
      render: true,
    });
  }

  onDragEnd=(result) => {
    if (!result.destination) {
      return;
    }
    const routeData = reorder(
      this.state.routeData,
      result.source.index,
      result.destination.index,
    );
    this.setState({ routeData });
  }


  back=() => {
    this.props.history.push(`/tasks/${this.props.routeId}`);
  }

  handleDisabledChange = (disabled) => {
    this.setState({ disabled });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message, 'success', 1);
  };


  render() {
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
      userSelect: 'none',
      padding: grid * 0.75,
      margin: `0 0 ${grid}px 0`,
      background: isDragging ? 'white' : '#2E2E2E',
      color: '#F5D358',
      ...draggableStyle,
    });
    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? '#2E2E2E' : 'white',
      padding: grid,
      color: '#F5D358',
    });
    const { routeData } = this.state;
    return (
      <div className='content' style={{marginTop:"-20px"}}>
        <Row type="flex" justify="space-between">
          <Col sm={18} xs={14}>
            <div id="GoogleMaps">
              {
                (routeData.length > 0) &&
                <Map data={this.state.routeData} kms={this.state.disabled} mapElement={<div style={{ height: 480, width: 'auto' }} />} />
              }
            </div>
          </Col>
          { (this.state.render) &&
            <Col sm={6} xs={9} style={{height:480}}>
              <div id="right-panel" style={{float:"none"}}>
            <div style={{height:280,overflowY:"auto"}}>
              <b>Routes</b> <br />
              <DragDropContext onDragEnd={this.onDragEnd} >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {routeData && routeData.map((route, index) => (
                        <Draggable key={route.location.id} draggableId={route.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              {route.location.name}
                              {(route.t_type === 'PICKUP')
                                ?
                                  <img
                                  src={pickupImage}
                                  alt="pickUp"
                                  style={{ height: 20, width: 20, float: 'right' }}
                                />
                                :
                                <img
                                    src={dropImage}
                                    style={{ height: 20, width: 20, float: 'right',marginTop:"5px" }}
                                  />
                              }
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
           
            <Col xs={24} className="floatNone" style={{ padding: '0 15%' }}>
                <Button className="buttonOrder" onClick={() => this.props.updateOrder(this.state.routeData)} loading={this.state.updateStatus}>
            Update Task Order
                </Button>
              </Col>
            <Row> In KM: <Switch size="small" checked={this.state.disabled} onChange={this.handleDisabledChange} /></Row>
            <div id="directions-panel" />
            <Row>
              {/* <Col xs={24} style={{ paddingLeft: '40%', marginTop: 20 }}>
                <Link to={`/tasks/${this.props.routeId}`} className="backButton" onClick={this.back} >
                  <img src={blackArrow} style={{ height: 20, width: 30 }} />
                </Link>
              </Col> */}
            </Row>
          </div>
            </Col>
          }
        </Row>
      </div>
    );
  }
}
export default App;
