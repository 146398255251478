import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spin, Tabs } from '../common/UIComponents';
import Form from '../components/users/ChangePasswordForm';
import whiteArrow from "../../assets/images/WhiteArrow.png";
import { alertMessage, checkIfDriverLoggedIn } from '../common/Common';
import userStore from '../stores/UserStore';

const { TabPane } = Tabs;

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = { inProgress: false };
  }
    handleSave = (message) => {
      alertMessage(message);
    }
    handleCancel = () => {
      this.props.history.push('/');
    }
    navigateToScreen = () => {
      const isDriver = checkIfDriverLoggedIn(userStore.currentRole());
      if (isDriver){
        this.props.history.push(`/weekly_settlement_reports`);
      }
      else{
        this.props.history.push(`/orders`);
      } 
    }

    render() {
      const isDriver = checkIfDriverLoggedIn(userStore.currentRole());
      return (
        <div className='content-outer'>
          <div className='content'>
            <Row className='page-header'>
              <Col xs={24}>
              {(userStore.currentRole() === "shipper"  || isDriver) &&
                <img
                  src={whiteArrow}
                  alt="whitearrow"
                  style={{
                    height: 20,
                    width: 30,
                    cursor: "pointer",
                    marginTop: -2,
                  }}
                  onClick={this.navigateToScreen}
                />
              }
                Change Password
              </Col>
            </Row>
            <Spin spinning={this.state.inProgress} delay={1000}>
              <Tabs defaultActiveKey="1">
                <TabPane key="1">
                  <Form
                    onCancel={this.handleCancel}
                    onSuccess={message => this.handleSave(message)}
                    showCancel={false}
                  />
                  
                </TabPane>
              </Tabs>
            </Spin>
          </div>
        </div>
      );
    }
}

export default ChangePassword;

ChangePassword.propTypes = {
  history: PropTypes.shape().isRequired,
};
