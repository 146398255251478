import { Col, Icon, Row, Tooltip, } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import PropTypes from "prop-types";
import React from "react";
import I18n from "../../common/I18n";
import { checkServiceExistance } from "../../helpers/common";
import BaseList from "../BaseList";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";

class InstructionsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "instruction_code",
        title: I18n.t("general.code"),
        dataIndex: "code",
        sorter: ({ code: a }, { code: b }) => a.localeCompare(b),
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => <span className="textUpperCase">{data}</span>,
        with: "30%",
      },
      {
        key: "instruction_description",
        title: I18n.t("general.description"),
        dataIndex: "description",
        sorter: ({ description: a }, { description: b }) => a.localeCompare(b),
        sortDirections: ["ascend", "descend", "ascend"],
        render: (data, record) => (
          <Paragraph ellipsis={{ rows: 2, expandable: true }}>{data}</Paragraph>
        ),
        width: "60%",
      },
    ];
    const editOpCode = props.isOrgLevelInstruction ? "OINSU" : "AINSU";
    const deleteOpCode = props.isOrgLevelInstruction ? "OINSD" : "OINSD";
    const hasEditAccess = checkServiceExistance(editOpCode);
    const hasDeleteAccess = checkServiceExistance(deleteOpCode);
    if (hasEditAccess || hasDeleteAccess) {
      this.columns.push({
        key: "actions",
        render: (data) => {
          const { handleDelete, handleEdit } = this.props;
          const { id } = data;
          return (
            <div className="pageActions instruction_types_list_pageactions">
              <Row gutter={8}>
                {hasEditAccess && (
                  <Col span={12}>
                    <Tooltip title={ I18n.t("general.edit") }>
                    <EditIcon handleClick={() => handleEdit(id)}/>
                    &nbsp;
                    </Tooltip>
                  </Col>
                )}
                {hasDeleteAccess && (
                  <Col span={12}>
                    <Tooltip title={ I18n.t("general.delete") }>
                    <DeleteIcon handleClick={() => handleDelete(id)} />
                    &nbsp;&nbsp;&nbsp;
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </div>
          );
        },
        align: "center",
        width: "10%",
      });
    }
  }
}
export default InstructionsList;

InstructionsList.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleConfig: PropTypes.func,
  isLoading: PropTypes.bool,
};
InstructionsList.defaultProps = {
  data: [],
  pagination: {},
  handleDelete: () => {},
  handleEdit: () => {},
  handleConfig: () => {},
  isLoading: false,
};
