import React from "react";
import BaseList from "../../BaseList";
import { Row, Col } from "../../../common/UIComponents";
import Copyable from "../../common/Copyable";
import { checkNegitive, compareDate, isEmpty } from "../../../common/Common";
import ShowInvoice from "../../billing_screen/ShowInvoice";
import I18n from "../../../common/I18n";
import AppConfig from "../../../config/AppConfig";
import moment from "moment"; 

class AccountCollectionReportsList extends BaseList {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Invoice Date",
        dataIndex: "approval_date",
        key: "approval_date",
        width: 180,
        render: (data) => (
          <span>{data ? moment.utc(data).format(AppConfig.report_date_format) : ""}</span>
        ),
        sorter: (a, b) => compareDate(a.approval_date, b.approval_date),
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_number",
        key: "invoice_number",
        width: 180,
        render: (text, record) => (
          <Row type="flex" gutter={4}>
            <Col>
              <ShowInvoice
                account={record.account_id}
                invoice_no={text}
                key={text}
                style={{ marginTop: -6 }}
                invoice_type={record.account_frequency_type}
                account_invoice_id={record.account_invoice_id}
                status={record.status}
                refreshList={() => {}}
                is_locked={record.is_locked}
                showActions={false}
                approvalDate={record.approval_date}
                renderType="text"
              />
            </Col>
            {!isEmpty(text) && (
              <Col>
                <Copyable text={text} />
              </Col>
            )}
          </Row>
        ),
      },
      {
        title: I18n.t("general.id"),
        dataIndex: "id",
        key: "id",
        width: 180,
        render: (data) => (
          <div className="paddingRight30">{(data)}</div>
        ),
        className: "alignleft",
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("general.comment"),
        dataIndex: "comment",
        key: "comment",
        width: 300,
        render: (data) => (
          <div className="paddingRight30">{(data)}</div>
        ),
        className: "alignleft",
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("batch_management.age"),
        dataIndex: "age",
        key: "age",
        width: 80,
        render: (data) => (
          <div className="paddingRight30">{(data)}</div>
        ),
        className: "alignleft",
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("invoices.total_amount"),
        dataIndex: "total_amount_due",
        key: "total_amount_due",
        width: 180,
        render: (data) => (
          <div className="paddingRight30" style={{ width: 130}}>{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions,
      },
      {
        title: I18n.t("aging.balance"),
        dataIndex: "balance_due",
        key: "balance_due",
        width: 180,
        render: (data) => (
          <div className="paddingRight30" style={{ width: 130}}>{checkNegitive(data)}</div>
        ),
        className: "alignRight",
        ...AppConfig.sortingOptions,
      },
    ];
  }

  render() {
    const { data, pagination, tableChange, ...otherProps } = this.props;  
    return (
      <BaseList
      columns={this.columns}
      dataSource={data}
      pagination={pagination}
      onChange={tableChange}
      {...otherProps}
      />
    );
  }
}

export default AccountCollectionReportsList;
