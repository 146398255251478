import React from "react";
import BaseList from "../BaseList";
import {
  compareString,
  isEmpty,
  ellipseText,
  checkNegitive,
} from "../../common/Common";
import I18n from "../../common/I18n";
import { Icon, Popconfirm, Popover, Tooltip } from "../../common/UIComponents";
import AddressInfo from "../common/AddressInfo";
import {
  checkServiceExistance,
  formatByTimeConfig,
} from "../../helpers/common";
import SettingIcon from "../common/SettingIcon";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { momentTime } from "../../helpers/date_functions";
import EyeIcon from "../common/EyeIcon";

class List extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.columns = [];
    this.setColumns(props);
  }

  setColumns = (props) => {
    this.setState({
      data: props.data || [],
    });
    const columns = [
      {
        key: "batch_name",
        title: I18n.t("batch_payments.name"),
        dataIndex: "batch_name",
        render: (text, data) => ({
          props: {
            // className: 'wordWrap',
          },
          children: data.children
            ? `${text} ` + `( ${data.children.length} )`
            : text,
        }),
        // sorter: (a, b) => compareString(a.name, b.name),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        key: "expected_total_amount",
        title: I18n.t("batch_payments.expected_total"),
        dataIndex: "expected_total_amount",
        render: (text, data) => (
          <span className="textBold">{checkNegitive(text)}</span>
        ),
        align: "right",
      },
      {
        key: "batch_total",
        title: I18n.t("batch_payments.batch_total"),
        dataIndex: "batch_total_amount",
        render: (text, data) => (
          <span className="textBold">{checkNegitive(text)}</span>
        ),
        align: "right",
      },
      {
        key: "comments",
        title: I18n.t("general.comments"),
        dataIndex: "comments",
        render: (text, data) => (text ? text : ""),
        ellipsis: true,
      },
      {
        key: "created_at",
        title: I18n.t("general.activity_on"),
        dataIndex: "created_at",
        render: (data) =>
          data
            ? `${formatByTimeConfig(
                momentTime("", data, this.props.timeZone),
                this.props.isMilitaryTime,
                "MMM Do, YYYY HH:mm",
                "MMM Do, YYYY hh:mm A"
              )} ${
                this.props.timeZoneName ? ` ${this.props.timeZoneName}` : ""
              }`
            : "",
        // sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      },
      {
        key: "actions",
        title: "",
        dataIndex: "id",
        render: (id, data) => (
          <div className="line-actions">
            {checkServiceExistance("ARBAE") && (
              <Tooltip title={data.status !== 'POSTED' ? I18n.t("general.manage") : I18n.t("general.view")}>
                {
                 data.status !== 'POSTED' ?
                  <Icon
                  type="file-text"
                  theme="twoTone"
                  twoToneColor="#1890ff"
                  onClick={() => this.props.editClick(id)}
                />
                :
                <EyeIcon handleClick={() => this.props.editClick(id)} />
                }
              </Tooltip>
            )}

            {data.status !== 'POSTED' && checkServiceExistance("ARBAD") && (
              <Tooltip title={I18n.t("general.delete")}>
                <Popconfirm
                  placement="left"
                  title={I18n.t("messages.delete_confirm")}
                  onConfirm={() => this.props.deleteClick(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon />
                  &nbsp;&nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        ),
        // width: '140px',
      },
    ];
    this.columns = columns.map((column) => ({
      ...column,
      width: this.getMaxWidth(column.dataIndex),
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.columns = [];
      this.setColumns(this.props);
    }
  }

  // getMaxWidth = (title, label) => {
  //   const dataSource = this.props.data || [];
  //   const columnData = this.props.data
  //     ? this.props.data.map((row) => row[title])
  //     : [];
  //   const maxLength = Math.max(
  //     ...columnData.map((text) => text && text.toString().length)
  //   );
  //   const finalLength = maxLength < 1 && title ? title.length : maxLength;
  //   let textWidth = 0;
  //   if (label.length >= finalLength) {
  //     textWidth = label.length;
  //   } else {
  //     textWidth = finalLength;
  //   }
  //   const width = textWidth * 8 + 24; // Set a width based on the maximum length of the characters and the table's default padding
  //   return width;
  // };

  getMaxWidth = (title) => {
    const maxWidth = 400; // Set a maximum width to avoid column being too wide
    const minWidth = 120; // Set a minimum width to avoid column being too narrow
    const padding = 24; // Set the padding to match the table's default padding
    const columnData =
      title !== "id"
        ? this.props.data.map((row) => row[title])
        : [" Action Icons "];
    let maxLength = Math.max(
      ...columnData.map((text) => text && text.toString().length)
    );

    if (title === "contact_person") {
      if (maxLength > 25) {
        maxLength = 25;
      }
    }
    const width = Math.min(
      maxWidth,
      Math.max(minWidth, maxLength * 8 + padding)
    ); // Set a width based on the maximum length of the characters
    return width;
  };
}

export default List;
