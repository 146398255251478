import React from 'react';
import _ from 'lodash';
import BaseList from '../BaseList';
import { Icon, Tooltip } from '../../common/UIComponents';
import { checkServiceExistance } from '../../helpers/common';
import EyeIcon from '../common/EyeIcon';
import I18n from '../../common/I18n';

class FormsList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
      },
      {
        key: 'customer_name',
        title: I18n.t('order.customer_name'),
        dataIndex: 'customer_name',
      },
      {
        key: 'item_name',
        title: I18n.t('order.item_name'),
        dataIndex: 'item_name',

      }
    ];
    if(checkServiceExistance('OFR')){
      this.columns.push({
      key: 'actions',
      title: '',
      dataIndex: 'order_id',
      render: id => (
        <div className='line-actions'>
          <Tooltip title={I18n.t('form.view_submitted')}>
            <EyeIcon handleClick={() => this.props.handleFormDetails(id)}/>
            &nbsp;
             </Tooltip>
        </div>
      ),
      width: '75px',
      });
    }
  }
}

export default FormsList;
