/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Col,
  Row,
  Spin,
  Button,
  Popconfirm,
  Modal,
  Collapse,
  DatePicker,
  FormItem,
  Select,
  Avatar,
} from '../../common/UIComponents'
import {
  alertMessage,
  randomNumber,
  isEmpty,
  checkNegitive,
} from '../../common/Common'
import userStore from '../../stores/UserStore'
import DedutionsList from './DeductionsList'
import DetailsList from './DetailsList'
import {
  approveReport,
  saveDriverDeductionData,
  saveGrossOrdersData,
  saveReportDetails,
  deleteGrossOrder,
  addWeekPeriodToDriver,
  deleteDeduction
} from '../../api/Reports'
import { getDriverName, renderAlertMessage } from '../../helpers/common'
import AddOrdersToWeeklyReport from './AddOrdersToWeeklyReport'
import { fetchShortFormAccounts } from '../../api/Account'
import { formatRoles } from '../../helpers/user'
import I18n from '../../common/I18n'
import AppConfig from '../../config/AppConfig'
import BaseModal from '../BaseModal'
import moment from 'moment'
import 'moment/locale/en-gb'
import WarehouseCodeFilter from '../common/WarehouseCodeFilter'
import BaseSelect from '../common/SelectDropdowns/BaseSelect'
import { getValueFromArrayOfObjects } from '../../helpers/array_functions'
moment.locale('en-gb', {
  week: {
    dow: 1, /// Date offset
  },
})

const { Panel } = Collapse
const { WeekPicker } = DatePicker;
class ReportDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // pagination: {},
      inProgress: false,
      report: this.props.report || {},
      driverId: this.props.driverId,
      currentDriver: this.props.currentDriver || {},
      currentDriverPayRecord: this.props.currentDriverPayRecord || {},
      driverPayId: this.props.driverPayId,
      driverPayReports: this.props.driverPayReports || [],
      deductions: this.props.deductions || [],
      errors: [],
      accounts: [],
      selectedInfo: {
        selectedOrderKeys: [],
        selectedOrderRows: [],
      },
      showWeekForm: false,
      weekPeriodString: '',
      new_driver: this.props.currentDriver?.id || '',
      new_wh: this.props.currentDriverPayRecord?.wh_location_id || '',
    }
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} }
  }

  componentDidMount() {
    if (this.state.deductions.length === 0 && !this.props.isDriverLogin) {
      this.addDeductionRow()
    } else {
      this.setDeductions(this.state.deductions)
    }
    this.getAccounts()
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.report, prevProps.report)) {
      this.setState({ report: this.props.report});
    }
  }
  getAccounts = () => {
    fetchShortFormAccounts().then((result) => {
      if (result.success) {
        this.setState({
          accounts: result.accounts || [],
        })
      }
    })
  }
  handleSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedInfo: Object.assign({}, this.state.selectedInfo, {
        selectedOrderKeys: selectedRowKeys,
        selectedOrderRows: selectedRows,
      }),
    });
  };

  setDeductions = (deductions = [], cb = null) => {
    this.setState(
      {
        deductions: deductions.map((deduction) =>
          Object.assign({}, deduction, { temp_id: randomNumber() }),
        ),
      },
      cb,
    )
  }

  setResponseData = (
    result,
    takeReports = true,
    takeDriverPayRecords = true,
    takeDeductions = true,
  ) => {
    const data = {}
    if (takeReports === true) {
      const grossOrders = result.reports
      data.report = grossOrders.length > 0 ? grossOrders[0] : {}
        if(data.report){
          if (!isEmpty(result.claim_balance) ) {
            data.report.claim_balance = result.claim_balance
          }
          if (!isEmpty(result.loan_balance)) {
            data.report.loan_balance = result.loan_balance
          }
          if (!isEmpty(result.advance_balance)) {
            data.report.advance_balance = result.advance_balance
          }
      }
    }
    if (takeDeductions === true) {
      const driverDeductions = result.driverDeductions || []
      const updatedDeductions = driverDeductions.filter(
        (deduction) =>
          deduction.driver_pay_report_id === this.state.driverPayId &&
          deduction.driver_id === this.state.currentDriver.id,
      )
      data.deductions = updatedDeductions
    }
    if (takeDriverPayRecords === true) {
      const driverPayReports = result.driverPayReports || []
      const currentDriverPayRecord = _.find(driverPayReports, {
        id: this.state.driverPayId,
      })
      if (!isEmpty(currentDriverPayRecord)) {
        data.currentDriverPayRecord = currentDriverPayRecord
        data.driverPayId = currentDriverPayRecord.id
      } else {
        data.currentDriverPayRecord = {}
        data.driverPayId = ''
      }
    }
    if (result.warnings) {
      Modal.warning({
        title: 'Warning',
        content: <div>{result.warnings.join(',')}</div>,
        width: '60%',
        onOk() {},
      })
    }
    return data
  }

  handleFormChange = (index, element, value) => {
    const gross_orders = [...this.state.report.gross_orders]
    gross_orders[index][element] = value
    this.setState({
      report: Object.assign({}, this.state.report, { gross_orders }),
    })
  }

  handleDeductionChange = (index, element, value) => {
    const deductions = [...this.state.deductions]
    deductions[index][element] = value
    this.setState({
      deductions,
    })
  }

  handleDeductionUpdate = () => {
    const deductions = [...this.state.deductions]
    const data = {
      driver_id: this.state.currentDriver.id,
      driver_pay_report_id: this.state.driverPayId,
      driver_deductions: deductions.map((record) =>
        Object.assign(
          {},
          {
            id: record.id || '',
            deduction_master_id: record.deduction_master_id || '',
            amount: !isEmpty(record.amount) ? record.amount : 0.0,
            deduction_name: !isEmpty(record.deduction_name)
              ? record.deduction_name
              : '',
            gl_code: !isEmpty(record.gl_code) ? record.gl_code : '',
          },
        ),
      ),
    }
    this.setState({ inProgress: true })
    saveDriverDeductionData(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.saved'))
        // const driverDeductions = result.driverDeductions || [];
        // const updatedDeductions = driverDeductions.filter(deduction => deduction.driver_pay_report_id === this.state.driverPayId && deduction.driver_id === this.state.currentDriver.id);
        const newState = this.setResponseData(result, true, false, true)
        this.setState(
          {
            ...newState,
            inProgress: false,
            errors: [],
          },
          () => {
            this.setDeductions(newState.deductions)
          },
        )
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({
          errors: result.errors,
          inProgress: false,
        })
      }
    })
  }

  handleApprove = () => {
    this.setState({
      inProgress: true,
    })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = _.find(driverPayReports, {
      id: this.state.driverPayId,
    })
    if (!isEmpty(currentDriverPayRecord)) {
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: this.state.driverPayId,
        status: 'APPROVED',
      }
      approveReport(data).then((result) => {
        if (result.success) {
          const newState = this.setResponseData(result, true, true, true)
          this.setState({
            ...newState,
            inProgress: false,
          })
          alertMessage(I18n.t('messages.saved'))
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleSaveDetails = () => {
    this.setState({
      inProgress: true,
    })
    const driverPayReports = [...this.state.driverPayReports]
    const currentDriverPayRecord = _.find(driverPayReports, {
      id: this.state.driverPayId,
    })
    if (!isEmpty(currentDriverPayRecord)) {
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: this.state.driverPayId,
        status: 'APPROVED',
      }
      saveReportDetails(data).then((result) => {
        if (result.success) {
          const newState = this.setResponseData(result, true, true, true)
          this.setState({
            ...newState,
            inProgress: false,
          })
          alertMessage(I18n.t('messages.saved'))
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false })
        }
      })
    }
  }

  handleGrossOrderUpdate = () => {
    const grossOrders = [...this.state.report.gross_orders]
    const data = {
      driver_id: this.state.driverId,
      driver_pay_report_id: this.state.driverPayId,
      gross_order_reports: grossOrders.map((record) =>
        Object.assign(
          {},
          {
            id: record.id,
            gross_revenue: !isEmpty(record.gross_revenue)
              ? record.gross_revenue
              : 0.0,
            gross_pay: !isEmpty(record.gross_pay) ? record.gross_pay : 0.0,
          },
        ),
      ),
    }
    this.setState({ inProgress: true })
    saveGrossOrdersData(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.saved'))
        const newState = this.setResponseData(result, true, true, true)
        this.setState({
          ...newState,
          inProgress: false,
          errors: [],
        })
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({
          errors: result.errors,
          inProgress: false,
        })
      }
    })
  }

  _renderTotalDetails = () => (
    <div className="marginTop15 padding10 net_details marginLeft5">
      <Row className="marginTop10">
        {!this.props.isDriverLogin && <Col md={15}>
          <div>
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.approve_confirm')}
              onConfirm={() => this.handleApprove()}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="primary" className="buttonGreen" icon='file-done'>
                {this.state.currentDriverPayRecord.status === 'PENDING'
                  ? 'Approve'
                  : I18n.t('general.reapprove')}
              </Button>
            </Popconfirm>
            &nbsp;&nbsp;
            {['PENDING', 'APPROVED'].includes(
              this.state.currentDriverPayRecord.status,
            ) && (
              <Popconfirm
                placement="topRight"
                title={I18n.t('messages.action_confirm', {action: 'save', field : ''})}
                onConfirm={() => this.handleSaveDetails()}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" type="primary" icon='save'>
                  Save Details
                </Button>
              </Popconfirm>
            )}
          </div>

          {/* {this.state.currentDriverPayRecord.status === 'APPROVED' && (
            <Popconfirm
              placement="topRight"
              title={I18n.t('messages.approve_confirm')}
              onConfirm={() => this.handleSaveDetails()}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" type="primary">
                Save Details
              </Button>
            </Popconfirm>
          )} */}
        </Col>}
        <Col md={9} className="borderLine">
          <h4 className="padding5">Summary</h4>
          <Row className="custom_line padding5">
            <Col xs={15} className="textBold">
              {I18n.t('reports.total_net')}({I18n.t('general.price_symbol')})
            </Col>
            <Col xs={1}>: </Col>
            <Col xs={8}>{checkNegitive(this.state.report.gross_pay)}</Col>
          </Row>
          <Row className="custom_line padding5">
            <Col xs={15} className="textBold">
              {I18n.t('reports.total_deductions')}
            </Col>
            <Col xs={1}>: </Col>
            <Col xs={8}>
              {checkNegitive(this.state.report.total_deductions)}
            </Col>
          </Row>
          <Row className="custom_line padding5">
            <Col xs={15} className="textBold">
              {I18n.t('reports.payable_amount')}({I18n.t('general.price_symbol')})
            </Col>
            <Col xs={1}>: </Col>
            <Col xs={8}>
              {checkNegitive(this.state.report.total_new_payment)}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

  addDeductionRow = () => {
    const deductions = [...this.state.deductions]
    deductions.push({
      deduction_name: '',
      amount: 0.0,
      gl_code: '',
      isNew: true,
      temp_id: randomNumber(),
    })
    this.setState({
      deductions,
    })
  }

  addOrderRow = () => {
    const gross_orders = this.state.report?.gross_orders
      ? [...this.state.report.gross_orders]
      : []
    gross_orders.push({
      customer_order_number: '',
      account_code: '',
      consignee_name: '',
      consignee_address: '',
      gross_revenue: '',
      gross_pay: '',
      isNew: true,
    })
    this.setState(
      {
        report: Object.assign({}, this.state.report, { gross_orders }),
      },
      () => {
        const currentActiveView = document.querySelector(`#newOrderRow`)

        if (currentActiveView) {
          $('#containerDiv').animate({ scrollTop: 0 }, 'fast')
        }
      },
    )
  }

  canelAddOrderRow = (isNew, id) => {
    const gross_orders = [...this.state.report.gross_orders]
    let filteredOrders = []
    if (isNew) {
      filteredOrders = gross_orders.filter((rec) => !rec.isNew)
    } else {
      filteredOrders = gross_orders.filter((rec) => rec.id !== id)
    }
    this.setState({
      report: Object.assign({}, this.state.report, {
        gross_orders: [...filteredOrders],
      }),
    })
  }

  handleDeleteClick = (index, id, isNew) => {
    if (isNew === true) {
      const { deductions } = this.state
      deductions.splice(index, 1)
      this.setState({
        deductions,
      })
    } else{
      const { currentOrg } = this.props.orgData;
      this.setState({
        inProgress: true,
      })
      const data = {
        driver_id: this.state.currentDriver.id,
        driver_pay_report_id: this.state.driverPayId,
        org_id: currentOrg.id,
        driver_deduction_report_id: id
      }
      deleteDeduction(data).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'))
          const newState = this.setResponseData(result, true, true, true)
          this.setState({
            ...newState,
            inProgress: false,
            errors: [],
          })
          // this.handleAddOrdersResponse(result, true, true, true, () => {
          //   this.canelAddOrderRow(false, id)
          // })
        } else {
          alertMessage(result.errors, 'error', 10)
          this.setState({
            inProgress: false,
          })
        }
      })
    }
  }

  _renderHeading = () => {
    const { currentDriverPayRecord, currentDriver, report } = this.state
    const { drivers } = this.props
    const selectedDriverDetails = drivers?.length ? drivers.find( user => user.id === currentDriverPayRecord?.driver_id) : {}
    const currentRole = selectedDriverDetails?.roles ? formatRoles(selectedDriverDetails.roles) : ''
    return (
      <div>
        <Row>
          <Col md={11}>
            <div className="info-text">
              <h4>Report for the week :</h4>
              {report.week_dates}
            </div>
            { currentDriverPayRecord.wh_name && <div className="info-text">
              <h4>{I18n.t('location.location')}:</h4>
              {currentDriverPayRecord.wh_name }
            </div>
            
            }
          </Col>
          <Col md={8}>
            <div className="info-text">
              <h4> {I18n.t('general.status')} :</h4>
              {currentDriverPayRecord.status}
            </div>
             { currentDriverPayRecord.invoice_number && <div className="info-text textBold">
              <h4> {I18n.t('general.invoice')} :</h4>
              #{currentDriverPayRecord.invoice_number}
            </div>
             }
          </Col>
          <Col md={5} className="alignRight">
            <div className="info-text">
              <h4>{I18n.t('general.Driver')} :</h4>
              {selectedDriverDetails ? getDriverName(selectedDriverDetails) : "NA"} {currentRole ? `(${currentRole})` : ''}
            </div>
            <div className="info-text">
              <h4>
                <Icon type="phone" />{' '}
              </h4>
              {currentDriver.mobile_number}
            </div>
          </Col>
        </Row>
        <hr />
      </div>
    )
  }

  _renderDeductions = () => {
    const isEditable = this.state.currentDriverPayRecord.status === 'PENDING'
    const glCodesList = this.props?.orgData?.glCodesList || []
    return (
      <div className="marginTop15 marginLeft5 deductionsBlock">
        <Row>
          <Col xs={12}>
            <h4>Deductions</h4>
          </Col>
          <Col className='alignRight'>
             { this.state.deductions.length === 0 && !this.props.isDriverLogin &&<Button size="small" type="primary" icon="plus-circle" onClick={this.addDeductionRow}>
              {I18n.t('general.add')}
             </Button>
             }
          </Col>
          {false && this.state.report && (
            <Col xs={12} className="alignRight">
              <span className="textBold">
                {I18n.t('deductions.claim_balance')}:&nbsp;
              </span>
              {checkNegitive(this.state.report.claim_balance)}
            </Col>
          )}
        </Row>
        <Fragment>
          <DedutionsList
            size="small"
            pagination={{ position: 'none' }}
            data={this.state.deductions}
            onChange={this.handleDeductionChange}
            handleSave={this.handleSave}
            scroll={{ y: 300 }}
            deleteClick={(index, id, isNew) =>
              this.handleDeleteClick(index, id, isNew)
            }
            totalRecords={this.state.deductions.length}
            addRow={this.addDeductionRow}
            claim_balance={
              this.state.report?.claim_balance
                ? this.state.report.claim_balance
                : 0.0
            }
            loan_balance={
              this.state.report?.loan_balance
                ? this.state.report.loan_balance
                : 0.0
            }
            advance_balance={
              this.state.report?.advance_balance
                ? this.state.report.advance_balance
                : 0.0
            }
            // isEditable={isEditable}
            footer={!this.props.isDriverLogin ? () => (
              <div className="alignRight textBold">
                <Button
                  size="small"
                  type="primary"
                  icon='save'
                  onClick={this.handleDeductionUpdate}
                  disabled={this.state.deductions.length === 0}
                >
                  {I18n.t('general.save')}
                </Button>
              </div>
            ) : null}
            glCodesList={glCodesList}
            isDriverLogin={this.props.isDriverLogin}
          />
        </Fragment>
      </div>
    )
  }

  handleAddOrdersResponse = (
    response,
    takeReports = true,
    takeDriverPayRecords = true,
    takeDeductions = false,
    cb = null,
  ) => {
    const newState = this.setResponseData(
      response,
      takeReports,
      takeDriverPayRecords,
      takeDeductions,
    )
    this.setState(
      {
        ...newState,
        errors: [],
      },
      () => {
        if (cb) {
          cb()
        }
      },
    )
  }
  deleteOrder = (id) => {
    const data = {
      organization_id: userStore.getStateValue('selectedOrg'),
      driver_pay_report_id: this.state.driverPayId,
      gross_order_id: id,
    }
    this.setState({ inProgress: true })
    deleteGrossOrder(data).then((result) => {
      if (result.success) {
        alertMessage(I18n.t('messages.deleted'))
        const newState = this.setResponseData(result, true, true, true)
        this.setState({
          ...newState,
          inProgress: false,
          errors: [],
        })
        // this.handleAddOrdersResponse(result, true, true, true, () => {
        //   this.canelAddOrderRow(false, id)
        // })
      } else {
        alertMessage(result.errors, 'error', 10)
        this.setState({
          inProgress: false,
        })
      }
    })
  }

  _renderDetails = () => {
    const { currentDriverPayRecord } = this.state
    const driverPayStatus = currentDriverPayRecord.status
    const grossRecords = this.state.report.gross_orders || []
    const newRecord = _.find(grossRecords, { isNew: true })
    const existingRecords = grossRecords.filter((rec) => !rec.isNew)
    const selectedRowKeys = this.state.selectedInfo.selectedOrderKeys;
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.handleSelectChange,
    // };
    const rowSelection = driverPayStatus === 'APPROVED' ? null : {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    };
    
    const { isAirCargo } = this.props.orgData;
    return (
        <div className="marginTop15 marginRight5 lefftBlock customTable">
          <Row>
            <Col xs={12}>
              <h4>{I18n.t('menu.orders')}</h4>
            </Col>
            <Col xs={12} className="alignRight">
              {grossRecords.length === 0 && !this.props.isDriverLogin && (
                <Button type="primary" size="small" onClick={this.addOrderRow}>
                  {I18n.t('order.add')}
                </Button>
              )}
            </Col>
          </Row>
          <div style={{ width: '100%', overflowX: 'auto'}}>
            <DetailsList
              size="small"
              pagination={{ position: 'none' }}
              data={existingRecords}
              onChange={this.handleFormChange}
              handleSave={this.handleGrossOrderUpdate}
              driverPayStatus={driverPayStatus}
              //scroll={{ y: document.body.clientHeight - 260 }}
              // isEditable={isEditable}
              addOrderRow={this.addOrderRow}
              hasNew={!isEmpty(newRecord)}
              deleteClick={this.deleteOrder}
              rowSelection={!this.props.isDriverLogin ? rowSelection : null}
              footer={() =>
                grossRecords.length > 0 ? (
                  <div id="newOrderRow">
                    {newRecord && (
                      <div className="appointmentForm">
                        <Collapse
                          defaultActiveKey={['1']}
                          style={{ height: 'auto' }}
                          expandIconPosition="right"
                          className="customCollapse"
                        >
                          <Panel
                            header={<Fragment>{I18n.t('order.add')}</Fragment>}
                            key="1"
                            size="small"
                          >
                            <AddOrdersToWeeklyReport
                              currentRecord={newRecord ? newRecord : {}}
                              size="small"
                              grossOrders={existingRecords}
                              driverId={this.state.driverId}
                              driverPayId={this.state.driverPayId}
                              handleResponse={this.handleAddOrdersResponse}
                              onCancel={() => this.canelAddOrderRow(true, '')}
                              accounts={this.state.accounts}
                            />
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                    {!newRecord && !this.props.isDriverLogin && (
                      <div className="alignRight">
                        <Button type="primary" size="small" onClick={this.addOrderRow} className='marginRight10'>
                          {I18n.t('order.add')}
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          icon='save'
                          onClick={this.handleGrossOrderUpdate}
                        >
                          {I18n.t('general.save')}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Fragment />
                )
              }
              isAirCargo={isAirCargo}
              isDriverLogin={this.props.isDriverLogin}
            />

            {selectedRowKeys.length > 0 && driverPayStatus !== 'APPROVED' && (
              <Row className="bottom_fixed">
                <Col
                  xs={24}
                  style={{ padding: "10px 10px" }}
                  className="alignCenter"
                >
                  <Button
                    type="primary"
                    className="assignDriver"
                    size='small'
                    onClick={() => {
                        this.setState({
                          showWeekForm: true,
                        });
                      }}
                  >
                    Change Week
                  </Button>
                </Col>
              </Row>
            )}
        
          </div>
        </div>
    )
  }
  addWeekToDriver = () => {
    if (!isEmpty(this.state.weekPeriodString)) {
      this.setState({
        inProgress: true,
      });
      const weekPeriodString = this.state.weekPeriodString;
      let year_number = "";
      let week_number = "";
      if (!isEmpty(weekPeriodString)) {
        week_number = moment(weekPeriodString, "MM-DD-YYYY").week();
        year_number = moment(weekPeriodString).format("YYYY");
      }
      const { warehouses  } = this.props.warehouseFilter;
      const whRec = _.find(warehouses, {id: this.state.new_wh});
      const data = {
        organization_id: userStore.getStateValue("selectedOrg"),
        driver_id: this.state.currentDriver.id,
        week_number,
        year: year_number,
        customer_order_ids: this.state.selectedInfo.selectedOrderRows.map(order => order.customer_order_id),
        customer_order_numbers: this.state.selectedInfo.selectedOrderRows.map(order => order.customer_order_number),
        driver_id: this.state.new_driver,
        wh_location_id: this.state.new_wh,
        wh_name: _.get(whRec, "name"),
        gross_report_ids: this.state.selectedInfo.selectedOrderRows.map(order => order.id),
      };
      addWeekPeriodToDriver(data).then((result) => {
        if (result.success) {
          this.setState({
            inProgress: false,
            showWeekForm: false,
            weekPeriodString: "",
            new_driver: this.state.currentDriver.id,
            new_wh: this.state.currentDriverPayRecord.wh_location_id,
            selectedInfo: {
              selectedOrderKeys: [],
              selectedOrderRows: [],
            }
          },()=>{
            this.props.refreshDriverPayView(this.state.currentDriverPayRecord.id);
          });
          alertMessage(
            I18n.t("messages.done_successfully", { field: "Added" })
          );
        } else {
          alertMessage(result.errors[0], "error", 10);
          this.setState({ inProgress: false });
        }
      });
    } else {
      alertMessage(`Please select ${I18n.t("general.week")}` , "error", 10);
    }
  };

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };


 renderWeekModal = () => {
    const drivers = this.props.drivers || [];
    return (
      <BaseModal
        title={
          <Fragment>
            {I18n.t("general.add")} {I18n.t("general.pay").toLowerCase()} to the
            driver {this.state.currentDriver.employee_code}
          </Fragment>
        }
        onCancel={() => {
          this.setState({
            showWeekForm: false,
            weekPeriodString: "",
          });
        }}
        width="30%"
        visible={this.state.showWeekForm}
        style={{
          top: "25%",
        }}
        className="reportDetailsBlock"
        wrapClassName="reportDetailsBlock"
      >
        <Row gutter={16}>
          <Col xs={12}>
            <FormItem label={I18n.t("location.location")}>
              {/* <WarehouseCodeFilter
                SelectedCodes={this.state.new_wh}
                element={"new_wh"}
                onValueChange={this.handleOnChange}
                warehouses={this.props?.warehouseFilter?.warehouses || []}
                size="default"
              /> */}
              <BaseSelect
                data={this.props?.warehouseFilter?.warehouses || []}
                value={this.state.new_wh}
                onChange={(e) => {
                  this.handleOnChange("new_wh", e);
                }}
                selectKey={"id"}
                selectValue={"id"}
                selectedValue={"name"}
                style={{ width: "90%" }}
                showSearch={true}
                dropdownMatchSelectWidth={false}
              />
          </FormItem>

          </Col>
          <Col xs={12}>
            <FormItem label={I18n.t("general.Driver")}>
              <Select
                style={{ width: '100%' }}
                className="routes-input"
                onChange={(e) => this.handleOnChange('new_driver', e)}
                value={this.state.new_driver || ''}
                filterOption={(input, option, index) =>
                  option.props.children.props.children[2]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                size="small"
              >
                {drivers.map((province) => {
                  const rolesList = formatRoles(province.roles);
                  return (<Option key={province.id} value={province.id}>
                    <div>
                      {province.image && province.image.url ? (
                        <Avatar src={`${province.image.url}`} size={20} />
                      ) : (
                        <Avatar
                          src="./../icons/user_100*100.png"
                          alt="DriverProfile"
                          size={20}
                        />
                      )}
                      &nbsp;&nbsp;{province.employee_code || province.full_name} {!isEmpty(rolesList) ? ` (${rolesList})` : ''}
                    </div>
                  </Option>
                )})}
              </Select>
          </FormItem>

          </Col>
          <Col xs={20}>
            <FormItem label={`Select ${I18n.t('general.week')}`}>
              <WeekPicker
                size="small"
                style={{ width: '100%' }}
                placeholder="Select Week"
                onChange={(date, dateString) =>
                  this.handleOnChange('weekPeriodString', date)
                }
                value={this.state.weekPeriodString}
              />
            </FormItem>
          </Col>
          <Col xs={4}>
            <Button
              type="primary"
              className="marginTop20"
              size="small"
              onClick={this.addWeekToDriver}
            >
              {I18n.t('general.save')}
            </Button>
          </Col>
          {this.state.weekPeriodString && (
            <Col
              xs={24}
              className="notes_content textBold"
              style={{ fontSize: 15 }}
            >
              {`${moment(this.state.weekPeriodString).format(
                'Wo',
              )} week - ${moment(this.state.weekPeriodString)
                .startOf('week')
                .format(AppConfig.dateFormat)} ~ ${moment(
                this.state.weekPeriodString,
              )
                .endOf('week')
                .format(AppConfig.dateFormat)}`}
            </Col>
          )}
        </Row>
      </BaseModal>
    )
  }

  render() {
    return (
      <div className="content-outer reportDetailsBlock">
        <div className="content">
          <Spin spinning={this.state.inProgress} delay={1000}>
            <Row>
              <Col xs={24}>
                <this._renderHeading />
                <Row gutter={16}>
                  <Col
                    md={12}
                    style={{ height: 'calc(100vh - 120px)', overflowY: 'auto' }}
                  >
                    <this._renderDetails />
                  </Col>
                  <Col md={12}>
                    <this._renderDeductions />
                    <this._renderTotalDetails />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>
        {this.state.showForm && this.renderFormModal()}
        {this.state.showWeekForm && this.renderWeekModal()}
      </div>
    )
  }
}
ReportDetails.propTypes = {
  account: PropTypes.shape().isRequired,
}

export default ReportDetails
