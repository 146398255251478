import React, {
    useState,
    useRef,
    useEffect,
    useContext,
    useCallback,
    Fragment,
} from "react";
import PropTypes from "prop-types";
import "antd/dist/antd.css";
import {
    Input,
    Select,
    Upload,
    Button,
    Icon,
    List,
    Divider,
    Tabs,
    Checkbox,
    Form,
    message,
    Collapse,
    Row,
    Col,
    Popconfirm,
    Spin,
    Tooltip,
    Modal,
} from "antd";
import { DatePicker } from "antd";
import DisplayTime from "../common/DisplayTime";
import "../../../stylesheets/_layout.scss";
import ImageViewer from "../common/ImageViewer";
import {
    deleteAttachmentInDocuments,
    getDriverDocuments,
    printDocuments,
    sendDocsEmails,
    uploadDocuments,
} from "../../api/UsersApi";
import {
    alertMessage,
    getFileIcon,
    getFileType,
    randomUUID,
} from "../../common/Common";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import I18n from "../../common/I18n";
import { validateEmailList } from "../billing_screen/helpers";
import { printPDF } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import DeleteIcon from "../common/DeleteIcon";
import MixedGallery from "../../stories/MixedGallery";
import BaseModal from "../BaseModal";
import DocumentTypes from "../DocumentTypes/DocumentTypes";
import { DocumentTypeApi } from "../../api/DocumentTypes";

const { Option } = Select; 

const DriverDocuments = (props) => {
    const [emailModal, setEmailModal] = useState(false);
    const [mailOrPrintDocuments, setMailOrPrintDocuments] = useState({});
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [galleryPics, setGalleryPics] = useState([]);
    const [currentDocumentPictures, setCurrentDocumentPictures] = useState([]);
    const { user, onClose } = props;
    const userContext = useContext(UserContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [showNewRow, setShowNewRow] = useState(false);
    const [newDocument, setNewDocument] = useState({
        document_number: "",
        proof_type: "",
        document_expiry_date: null,
        document_proofs: [],
    });

    const resetAll = () => {
        setNewDocument({
            document_number: "",
            proof_type: "",
            document_expiry_date: null,
            document_proofs: [],
        });
        setIsEdit(false);
        setEmails([]);
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const getDocumentTypes = () => {
        setLoading(true);
        DocumentTypeApi.fetchDocumentType().then((result) => {
          if (result.success) {
            const sortedDocumentTypes = result.driver_doc_type
              ? result.driver_doc_type
                  .map((item) => ({
                    ...item,
                    name: capitalizeFirstLetter(item.name),
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name))
              : [];
    
            setDocumentTypes(sortedDocumentTypes);
            setShowNewRow(result?.driver_doc_type?.length === 0);
            setLoading(false);
          } else {
            alertMessage(result.errors[0], "error", 5);
            setLoading(false);
          }
        });
      };

    const idInputRef = useRef(null);

    useEffect(() => {
        if (idInputRef.current) {
            idInputRef.current.focus();
        }
    }, [newDocument.document_number]);

    useEffect(() => {
        fetchDriverDocuments();
        getDocumentTypes();
    }, []);

    const addEmails = (value) => {
        const validEmails = validateEmailList(value);
        setEmails(validEmails);
    };

    const toggleEmailModal = (val) => {
        if (val) {
            setEmailModal(val);
        } else {
            setEmailModal(val);
            setEmails([]);
        }
    };

    const renderUploadDragger = () => {
        const fileList = newDocument.document_proofs.map((proof) => ({
            uid: proof.ack_id,
            name: proof.document_name,
            status: "done",
            key: proof.ack_id,
        }));

        const handleRemove = (file) => {
            setNewDocument((prevDocument) => ({
                ...prevDocument,
                document_proofs: prevDocument.document_proofs.filter(
                    (proof) => proof.ack_id !== file.uid
                ),
            }));
        };

        const beforeUpload = (file) => {
            // if (newDocument.document_proofs.length > 0) {
            //     alertMessage('You can only upload one file!', "error", 3);
            //     return Upload.LIST_IGNORE;
            // }

            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                alertMessage("File must be smaller than 5MB!", "error", 3);
                return Upload.LIST_IGNORE;
            }

            // Immediately add the file to the document_proofs
            const newFileObj = {
                document_obj: file,
                ack_id: `${randomUUID()}_n`,
                captured_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                document_type: file.type,
                document_name: file.name,
            };

            setNewDocument((prevDocument) => ({
                ...prevDocument,
                document_proofs: [...prevDocument.document_proofs, newFileObj],
            }));

            return false; // Prevent default upload behavior
        };
        return (
            <Upload.Dragger
                name="files"
                beforeUpload={beforeUpload}
                multiple={true}
                showUploadList={{
                    showRemoveIcon: true,
                }}
                onRemove={handleRemove}
                fileList={fileList}
            >
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
            </Upload.Dragger>
        );
    };

    const renderGallery = () => {
        return (
            <BaseModal
                visible={lightboxIsOpen}
                title={"Gallery"}
                onCancel={closeLightbox}
                footer={null}
                width="70%"
            >
                <div>
                    <MixedGallery
                        showPrintButton={false}
                        handlePrint={handlePrint}
                        showDeleteButton={false}
                        deleteImage={deleteFile}
                        showCapturedDate={false}
                        files={currentDocumentPictures}
                        currentImage={_.get(galleryPics, "[0].src", "")}
                        closeComponent={closeLightbox}
                    />
                </div>
            </BaseModal>
        );
    };

    const renderNewDocument = () => {
        return (
            <Row>
                <Col>
                    <Form>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={4}>
                                <Form.Item label={<b>Document Id</b>} required>
                                    <Input
                                        placeholder="Enter Document Id"
                                        value={newDocument.document_number}
                                        onChange={(e) =>
                                            handleNewDocumentChange("document_number", e.target.value)
                                        }
                                        ref={idInputRef}
                                    />
                                </Form.Item>
                            </Col>
                            <>
                            <Col span={4}>
                                <Form.Item label={<b>Document Type</b>} required>
                                <Select
                                    value={newDocument.proof_type || undefined}
                                    onChange={handleSelectChange}
                                    placeholder="Select Type"
                                >
                                    {documentTypes.map((type) => (
                                    <Option key={type.id} value={type.id}>
                                        {type.name}
                                    </Option>
                                    ))}
                                    <Option key="" value="">
                                      Others
                                    </Option>
                                </Select>
                                </Form.Item>
                            </Col>

                            <Modal
                                title="Document Types"
                                visible={isModalVisible}
                                onOk={handleModalOk}
                                onCancel={handleModalCancel}
                                width={600}
                                footer={null}
                            >
                                <DocumentTypes />
                            </Modal>
                            </>
                            <Col span={4}>
                                <Form.Item label={<b>Expiry Date</b>}>
                                    <DatePicker
                                        value={newDocument.document_expiry_date}
                                        onChange={(date) =>
                                            handleNewDocumentChange("document_expiry_date", date)
                                        }
                                        placeholder="Select Expiry Date"
                                        format={AppConfig.dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<b>Files</b>} className="driver-docs">
                                    {renderUploadDragger()}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Row type="flex" gutter={10} align="middle" justify="center">
                                    <Col>
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={
                                                isEdit ? updateExistingDocument : addNewDocument
                                            }
                                            icon={isEdit ? "save" : "file-add"}
                                        >
                                            {isEdit ? "Save " : "Add "}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="danger"
                                            size="small"
                                            icon="close"
                                            onClick={resetAll}
                                        >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    };

    const renderDocumentsLists = () => {
        return (
            <Row>
                <Col>
                    <List
                        bordered={true}
                        style={{
                            maxHeight: '60vh',
                            overflowY: 'auto'
                        }}
                        itemLayout="vertical"
                        dataSource={documents}
                        renderItem={(doc, index) => (
                            <List.Item className="doc-item" key={doc.document_number}>
                                <Row type="flex" justify="space-between">
                                    <Col>
                                        <span className="fontSize16 fontWeightBold">
                                            Document Id:
                                        </span>{" "}
                                        <span>{doc.document_number}</span>
                                    </Col>
                                    <Col>
                                        <span className="fontSize16 fontWeightBold">Type:</span>{" "}
                                        <span>
                                            {documentTypes.find((t) => t.id === doc.proof_type)
                                                ?.name || "Unknown"}
                                        </span>
                                    </Col>
                                    <Col>
                                        <span className="fontSize16 fontWeightBold">
                                            Expiry Date:{" "}
                                        </span>{" "}
                                        {doc.document_expiry_date
                                            ? moment(doc.document_expiry_date).format(
                                                AppConfig.dateFormat
                                            )
                                            : " --- NA --- "}
                                    </Col>
                                    <Col>
                                        <Row type="flex" gutter={10}>
                                            <Col>
                                                <Button
                                                    size="small"
                                                    onClick={() => handleDocumentEdit(doc)}
                                                    icon="edit"
                                                    type="primary"
                                                >
                                                    Edit
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Popconfirm
                                                    title="Are you sure you want to delete this document?"
                                                    onConfirm={() => handleDocumentOnDelete(doc)}
                                                    onCancel={() => { }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button icon="delete" size="small" type="danger">
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="marginTop10">
                                    <Col>
                                        <span className="fontSize16 fontWeightBold">
                                            {!_.isEmpty(doc.document_proofs) ? 'Attachments:' : 'No Attachments found'}
                                        </span>
                                    </Col>
                                    <Col xs={24} className="scrollBlock">
                                        <div className="main">
                                            <Row>
                                                <Col>
                                                    <div className="image-container-driverDoc">
                                                        {!_.isEmpty(doc.document_proofs) &&
                                                            doc.document_proofs.map((file, index) =>
                                                                !isEmpty(file.picture.url) ? (
                                                                    <Fragment key={`fgallery${file.ack_id}`}>
                                                                        <div
                                                                            className="ant-upload-list-item"
                                                                            key={`gallery${file.ack_id}`}
                                                                        >
                                                                            <div className="ant-upload-list-item-info">
                                                                                <span
                                                                                    className="ant-upload-list-item-thumbnail"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    {_.includes(file.document_type, '/') &&
                                                                                        _.split(file.document_type, '/')[0] ===
                                                                                        "image" ? (
                                                                                        <img
                                                                                            src={file.picture.url}
                                                                                            alt="attachment"
                                                                                            className="ant-upload-list-item-image"
                                                                                        />
                                                                                    ) : (
                                                                                        <Icon
                                                                                            type={getFileIcon(
                                                                                                file.document_type
                                                                                            )}
                                                                                            style={{
                                                                                                fontSize: 80,
                                                                                                margin: 10,
                                                                                            }}
                                                                                            theme="twoTone"
                                                                                            className="marginTop10"
                                                                                        />
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            <span className="ant-upload-list-item-actions">
                                                                                <Icon
                                                                                    type={ "eye"                                                                                   }
                                                                                    onClick={(event) =>
                                                                                        openLightbox(event, index, doc)
                                                                                    }
                                                                                />
                                                                                <Popconfirm
                                                                                    placement="topRight"
                                                                                    title={I18n.t(
                                                                                        "messages.delete_confirm"
                                                                                    )}
                                                                                    onConfirm={() => deleteFile(file._id)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Tooltip>
                                                                                        <DeleteIcon />
                                                                                        &nbsp;&nbsp;&nbsp;
                                                                                    </Tooltip>
                                                                                </Popconfirm>
                                                                            </span>
                                                                            <Checkbox
                                                                                checked={file.checked || false}
                                                                                onChange={(e) =>
                                                                                    handleCheckbox(doc, file, e)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Fragment>
                                                                ) : null
                                                            )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                {!_.isEmpty(doc.document_proofs) &&
                                    <div style={{ textAlign: "center" }}>
                                        <Button
                                            size="small"
                                            type="primary"
                                            onClick={() =>
                                                _.every(doc.document_proofs, ['checked', true]) ?
                                                    handleCheckAll(doc, false) :
                                                    handleCheckAll(doc, true)
                                            }
                                            className="marginTop10"
                                        >
                                            {_.every(doc.document_proofs, ['checked', true]) ? 'Uncheck All' : 'Check All'}
                                        </Button>
                                    </div>
                                }
                            </List.Item>
                        )}
                    />
                </Col>
                <Col
                    className="marginTop10"
                    style={{
                        textAlign: "center"
                    }}
                >
                    <Button
                        size="small"
                        icon='close'
                        type="danger"
                        onClick={() => onClose(true)}
                    >
                        Close
                    </Button>
                </Col>
            </Row>
        );
    };

    const renderMailingModal = () => {
        return (
            <BaseModal
                title="Email Documents"
                onCancel={() => toggleEmailModal(false)}
                className="surveyModal"
                width="35%"
                maskClosable={false}
            >
                <Row className="emailSelect" align="middle">
                    <Col className="emailForm">
                        <Select
                            value={emails}
                            onChange={addEmails}
                            mode="tags"
                            maxTagCount={4}
                            validationRegex={addEmails}
                            tokenSeparators={[","]}
                            style={{ width: "100%" }}
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Enter or select Emails"
                        >
                            {userContext?.currentUser?.email ? (
                                <Option key={userContext.currentUser.email}>
                                    {userContext.currentUser.email}
                                </Option>
                            ) : (
                                ""
                            )}
                        </Select>
                    </Col>
                    <Col style={{ marginTop: "10px" }}>
                        <Button
                            size="small"
                            type="primary"
                            loading={loading}
                            disabled={!emails.length}
                            onClick={handleSendingMails}
                            icon="check-circle"
                        >
                            Send Email
                        </Button>
                    </Col>
                </Row>
            </BaseModal>
        );
    };

    const renderPrintAndMailButtons = () => {
        return (
            <Row className='marginBottom10' type="flex" justify="end" gutter={10}>
                <Col>
                    <Button
                        type="primary"
                        ghost
                        icon="printer"
                        onClick={handleMultiplePrint}
                        size="small"
                        disabled={_.isEmpty(mailOrPrintDocuments)}
                    >
                        Print
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        icon="mail"
                        onClick={() => toggleEmailModal(true)}
                        size="small"
                        disabled={_.isEmpty(mailOrPrintDocuments)}
                    >
                        Email
                    </Button>
                </Col>
            </Row>
        )
    };

    const updateExistingDocument = () => {
        setIsProgress(true);
        if (!newDocument.document_number.trim()) {
            message.error("Please enter a Document Id");
            setIsProgress(false);
            return;
        }
        if (!_.isEmpty(newDocument._id)) {

            const updatedNewDocumentProofs = newDocument.document_proofs.map(
                (document) =>
                    _.has(document, "document_obj")
                        ? document
                        : _.assign(document, { document_obj: null })
            );

            const updatedNewDoc = {
                ...newDocument,
                document_proofs: updatedNewDocumentProofs,
                document_expiry_date: !_.isEmpty(newDocument.document_expiry_date)
                    ? newDocument.document_expiry_date.utc().format()
                    : "",
            };
            const payload = {
                user_id: user.id,
                document_details: updatedNewDoc,
            };

            uploadDocuments(payload)
                .then((res) => {
                    if (res.success) {
                        if (!_.isEmpty(res.data)) {
                            alertMessage(res.data.message, "success");
                            setDocuments(res.data.document_details);
                        }
                    } else {
                        alertMessage(res.errors, "error");
                    }
                })
                .catch((err) => {
                    alertMessage(err.message, "error");
                })
                .finally(() => {
                    setIsProgress(false);
                    setIsEdit(false);
                });

            setNewDocument({
                document_number: "",
                proof_type: "",
                document_expiry_date: null,
                document_proofs: [],
            });
        } else {
            setIsEdit(false);
        }
    };


    const addNewDocument = () => {
        setIsProgress(true);
        if (!newDocument.document_number.trim()) {
            message.error("Please enter a Document Number");
            setIsProgress(false);
            return;
        }

        if (!newDocument.proof_type) {
            alertMessage("Please select a Document Type", "error");
            setIsProgress(false);
            return;
        }

        const updatedNewDoc = {
            ...newDocument,
            document_expiry_date: !_.isEmpty(newDocument.document_expiry_date)
                ? newDocument.document_expiry_date.utc().format()
                : "",
        };
        const payload = {
            user_id: user.id,
            document_details: updatedNewDoc,
        };
        uploadDocuments(payload)
            .then((res) => {
                if (res.success) {
                    if (!_.isEmpty(res.data)) {
                        alertMessage(res.data.message, "success");
                        setDocuments(res.data.document_details);
                    }
                } else {
                    alertMessage(res.errors, "error");
                }
            })
            .catch((err) => {
                alertMessage(err.message, "error");
            })
            .finally(() => {
                setIsProgress(false);
                setIsEdit(false);
            });
        setNewDocument({
            document_number: "",
            proof_type: "",
            document_expiry_date: null,
            document_proofs: [],
        });
    };

    const fetchDriverDocuments = () => {
        setIsProgress(true);
        getDriverDocuments(user.id)
            .then((res) => {
                if (res.success) {
                    setDocuments(
                        !_.isEmpty(res.data.document_details)
                            ? res.data.document_details
                            : []
                    );
                }
            })
            .catch((err) => {
                alertMessage(err.message, "error");
            })
            .finally(() => {
                setIsProgress(false);
            });
    };

    const handleNewDocumentChange = (field, value) => {
        setNewDocument({ ...newDocument, [field]: value });
    };

    const handleSelectChange = (value) => {
        if (value === "") {
          setIsModalVisible(true);
        }
        handleNewDocumentChange("proof_type", value);
      };
    
      const handleModalOk = () => {
        setIsModalVisible(false); 
      };

      const handleModalCancel = () => {
        setIsModalVisible(false);
        getDocumentTypes()
      };

    const openLightbox = (event, index, file) => {
        event.preventDefault();

        const pictures = !_.isEmpty(file.document_proofs)
            ? file.document_proofs.map((proof) =>
                !_.isEmpty(proof.picture.url) ? proof.picture.url : null
            )
            : [];
        if (pictures.length > 0) {
            const fileList = pictures[index] ? [pictures[index]] : [];
            const fileType = getFileType(pictures[index]);
            if (!_.isEmpty(fileType)) {
                const cleaneData = _.compact(fileList);
                const cleanedPictures = cleaneData.map((pic) => ({
                    src: pic,
                }));
                setLightboxIsOpen(true);
                setGalleryPics(cleanedPictures);
                setCurrentDocumentPictures(pictures);
            }
        }
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    const handleMailOrPrintDocuments = (document, picDoc, checked) => {
        const mailPrintDocuments = mailOrPrintDocuments;
        if (checked) {
            if (_.has(mailPrintDocuments, document._id)) {
                mailPrintDocuments[document._id].push(picDoc._id);
            } else {
                mailPrintDocuments[document._id] = [picDoc._id];
            }
        } else {
            if (_.has(mailPrintDocuments, document._id)) {
                _.remove(mailPrintDocuments[document._id], (id) => id === picDoc._id);
                if (_.isEmpty(mailPrintDocuments[document._id])) {
                    delete mailPrintDocuments[document._id];
                }
            }
        }
        setMailOrPrintDocuments(mailPrintDocuments);
    };

    const handleCheckbox = (document, file, e) => {
        const updatedDocumentProofs = document.document_proofs.map((proof) =>
            proof._id === file._id ? { ...proof, checked: e.target.checked } : proof
        );

        const updatedDocument = {
            ...document,
            document_proofs: updatedDocumentProofs,
        };

        const updatedDocs = documents.map((doc) =>
            doc._id === document._id ? updatedDocument : doc
        );
        setDocuments(updatedDocs);

        handleMailOrPrintDocuments(document, file, e.target.checked);
    };

    const handleCheckAll = (document, val) => {
        const mailPrintDocuments = mailOrPrintDocuments;

        const updatedDocumentProofs = document.document_proofs.map((proof) => ({
            ...proof,
            checked: val,
        }));

        const updatedDocument = {
            ...document,
            document_proofs: updatedDocumentProofs,
        };

        const updatedDocs = documents.map((doc) =>
            doc._id === document._id ? updatedDocument : doc
        );

        setDocuments(updatedDocs);

        if (val) {
            if (_.has(mailPrintDocuments, document._id)) {
                delete mailPrintDocuments[document._id];
            }
            mailPrintDocuments[document._id] = [
                ...document.document_proofs.map((proof) => proof._id),
            ];
        } else {
            if (_.has(mailPrintDocuments, document._id)) {
                delete mailPrintDocuments[document._id];
            }
        }

        setMailOrPrintDocuments(mailPrintDocuments);
    };

    const handleSendingMails = async () => {
        try {
            setLoading(true);
            const payload = {
                user_id: user.id || "",
                org_id: userContext.currentOrg._id || "",
                documents_obj: mailOrPrintDocuments || {},
                emails: [...emails] || [],
            };
            const res = await sendDocsEmails(payload);
            if (res.success) {
                alertMessage(res.data.message, "success");
                toggleEmailModal(false);
            } else {
                alertMessage(res.errors, "error");
            }
        } catch (error) {
            alertMessage(error.message, "error", 10);
        } finally {
            setLoading(false);
        }
    };

    const handleDocumentOnDelete = (doc) => {
        setIsProgress(true);
        const documentToDelete = doc;
        const updatedDocuments = documents.filter(
            (doc) => doc._id !== documentToDelete._id
        );
        const payload = {
            user_id: user.id,
            document_id: documentToDelete._id,
        };
        deleteAttachmentInDocuments(payload)
            .then((res) => {
                if (res.success) {
                    if (!_.isEmpty(res.data)) {
                        alertMessage(res.data.message, "success");

                        setDocuments(
                            documents.filter((doc) => doc._id !== documentToDelete._id)
                        );
                    }
                }
            })
            .catch((err) => {
                alertMessage(err.message, "error");
            })
            .finally(() => {
                setIsProgress(false);
            });
    };

    const handlePrint = (url) => {
        setIsProgress(true);

        let payload = {};
        let selected_ids = [];

        const foundRecord =
            !_.isEmpty(documents) &&
            documents.find((doc) => {
                return doc.document_proofs.some((proof) => {
                    if (proof.picture.url === url) {
                        selected_ids.push(proof._id);
                        return true;
                    }
                    return false;
                });
            });

        payload = {
            user_id: user.id,
            // document_id: {[foundRecord._id]:[...selected_ids]} || {},
            document_id: foundRecord._id || "",
            selected_ids: selected_ids.join(","),
        };

        printDocuments(payload)
            .then((res) => {
                if (res.data.success) {
                    printPDF(res.data.combine_attachments_pdf);
                } else {
                    alertMessage("Failed to print documents", "error");
                }
            })
            .catch((err) => {
                alertMessage(err.message, "error");
            }).finally(() => {
                setIsProgress(false);
            });
    };

    const handleMultiplePrint = () => {
        setIsProgress(true);

        const payload = {
            user_id: user.id || "",
            org_id: userContext.currentOrg._id || "",
            documents_obj: mailOrPrintDocuments || {},
        };

        printDocuments(payload)
            .then((res) => {
                if (res.data.success) {
                    printPDF(res.data.combine_attachments_pdf);
                } else {
                    alertMessage("Failed to print documents", "error");
                }
            })
            .catch((err) => {
                alertMessage(err.message, "error");
            }).finally(() => {
                setIsProgress(false);
            });
    }

    const deleteFile = (id) => {
        let payload = {}, picDoc;
        const foundRecord = documents.find((doc) => {
            return doc.document_proofs.find((proof) => {
                if (proof._id == id) {
                    picDoc = proof;
                    return true;
                }
            });
        });

        if (_.has(mailOrPrintDocuments, foundRecord._id)) {
            if (_.includes(mailOrPrintDocuments[foundRecord._id], picDoc._id)) {
                // handleMailOrPrintDocuments(foundRecord, picDoc._id, false);
                setMailOrPrintDocuments({});
            }
        }

        payload = {
            user_id: user.id,
            document_id: foundRecord._id,
            selected_ids: [id],
        };

        deleteAttachmentInDocuments(payload)
            .then((res) => {
                if (res.success) {
                    alertMessage(res.data.message, "success");

                    const updatedDocs = documents.map((doc) =>
                        doc._id === res.data.user_document._id
                            ? {
                                ...doc,
                                document_proofs: [...res.data.user_document.document_proofs],
                            }
                            : doc
                    );
                    
                    setDocuments(updatedDocs);
                }
            })
            .catch((err) => {
                alertMessage(err.message, "error");
            });
    };

    const handleDocumentEdit = (record) => {
        setIsEdit(true);
        const foundDocument = documents.find((doc) => doc._id === record._id);
        if (foundDocument) {
            setNewDocument({
                ...foundDocument,
                document_expiry_date: foundDocument.document_expiry_date
                    ? moment(foundDocument.document_expiry_date)
                    : null,
            });
        }
    };

    return (
        <Spin spinning={isProgress}>
            {renderNewDocument()}
            {documents.length > 0 && renderPrintAndMailButtons()}
            {renderDocumentsLists()}
            {emailModal && renderMailingModal()}
            {lightboxIsOpen && galleryPics.length > 0 && renderGallery()}
        </Spin>
    );
};

export default DriverDocuments;

DriverDocuments.propTypes = {
    deleteDocument: PropTypes.func,
    deleteImage: PropTypes.func,
};

DriverDocuments.defaultProps = {
    deleteDocument: () => { },
    deleteImage: () => { },
    driverDocuments: [],
};
