import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function fetchBillingRates (accountCode = '') {
  const url = ApiUrl(`v2/billing_rates?account_id=${accountCode}&operation_code=BRI`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { rates: 'Rates' },
  });
}

export function saveWeights (data, isNew = true) {
  data.operation_code = isNew ? 'BRCW' : 'BRCW';
  const url = isNew ? ApiUrl('v2/billing_rates/create_weight') : ApiUrl(`v2/billing_rates/create_weight/${data.id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, {}, false);
}

export function deleteWeights (data) {
  data.operation_code = "DWR"
  const url = ApiUrl('v2/billing_rates/delete_weight_range');
  return Call('POST', url, data, ParseGeneralResponse, {}, false);
}

export function saveZoneCost (data) {
  data.operation_code = 'UZBRA';
  const url = ApiUrl('v2/billing_rates/update_zone_billing_rate_amount');
  const method = 'put';
  return Call(method, url, data, ParseGeneralResponse, {}, false);
}

export function deleteAccountZone (id, data = {}) {
  const url = ApiUrl(`v2/billing_zones/${id}`);
  return Call('delete', url, data, ParseGeneralResponse, {}, false);
}

export function updateLosWeightRange (data) {
  data.operation_code = 'ULOSWR';
  const url = ApiUrl('v2/billing_rates/update_los_weight_range');
  return Call('POST', url, data, ParseGeneralResponse, {}, false);
}