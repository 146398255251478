/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormItem, Input, Row, Select, Radio } from '../../common/UIComponents';
import { alertMessage } from '../../common/Common';
import I18n from '../../common/I18n';
import FormButtons from '../common/FormButtons';
import { saveAccessorial, fetchAllAccessorials } from '../../api/Accessorials';
import FormErrors from '../common/FormErrors';
import AppConfig from '../../config/AppConfig';
import { renderAlertMessage } from '../../helpers/common';

const RadioGroup = Radio.Group;

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAccessorial: this.props.accessorial ? this.props.accessorial : {},
      isNew: this.props.isNew,
      orgId: this.props.org_id,
      inProgress: false,
      errors: [],
      masterAccessorials: [],
    };
  }

  componentDidMount() {
    this.getAccessorials();
  }

  clearForm = () => {
    this.setState({
      currentAccessorial: {},
      isNew: true,
    });
  }

  handleOnChange = (element, value) => {
    this.setState({
      currentAccessorial: Object.assign({}, this.state.currentAccessorial, { [element]: value }),
    });
  }

  handleSave = () => {
    const data = Object.assign({}, this.state.currentAccessorial);
    if (!data.organization_id) {
      data.organization_id = this.state.orgId;
    }

    this.setState({ inProgress: true });
    saveAccessorial(this.state.isNew, data)
      .then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.saved'));
          this.setState({ inProgress: false });
          if (this.state.isNew) {
            this.setState({ isNew: true, inProgress: false, currentAccessorial: {} });
            this.clearForm();
          }
        } else {
          alertMessage(result.errors, 'error', 10);
          this.setState({ inProgress: false });
        }
      });
  }

  /* static getDerivedStateFromProps(props, state) {
    if (
      _.isObject(props.accessorial) && Object.keys(props.accessorial).length > 0 &&
      ((state.isNew === props.isNew && (props.accessorial.id !== state.currentAccessorial.id)) ||
      (state.isNew !== props.isNew))
    ) {
      const data = {};
      data.currentAccessorial = props.accessorial;
      data.isNew = props.isNew;
      return data;
    }
    return {};
  } */

  getAccessorials = () => {
    fetchAllAccessorials(1, null)
      .then((result) => {
        if (result.success) {
          this.setState({
            masterAccessorials: result.accessorials,
            inProgress: false,
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
  }

  render() {
    const { currentAccessorial } = this.state;
    const decimalPoints = window.localStorage.getItem('round_off_decimals');

    return (
      <div>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem label={I18n.t('general.accessorial')}>
              <Select
                value={currentAccessorial.master_accessorial_id}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={e => this.handleOnChange('master_accessorial_id', e)}
                disabled={!this.state.isNew}
              >
                {this.state.masterAccessorials.map(accessorial => (
                  <Select.Option key={accessorial.id} value={accessorial.id}>
                    {accessorial.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col sm={12} xs={12} md={12} lg={24}>
            <FormItem label={I18n.t('accessorials.amount_type')}>
              <Select
                value={currentAccessorial.amount_type}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={e => this.handleOnChange('amount_type', e)}
              >
                {Object.keys(AppConfig.amountTypes).map(key => (
                  <Select.Option key={key} value={AppConfig.amountTypes[key]}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col sm={12} xs={12} md={12} lg={24}>
            <FormItem label={`${I18n.t('general.price')} (in ${I18n.t('general.price_hint')})`}>
              <Input
                type="number"
                value={parseFloat(currentAccessorial.amount)}
                onChange={e => this.handleOnChange('amount', e.target.value)}
                min={0}
                precision={decimalPoints}
              />
            </FormItem>
          </Col>
          <Col sm={12} xs={12} md={12} lg={24}>
            <FormItem label={I18n.t('accessorials.type')}>
              <Select
                value={currentAccessorial.applicable_to}
                showSearch
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={e => this.handleOnChange('applicable_to', e)}
              >
                {Object.keys(AppConfig.accessorialsTypes).map(key => (
                  <Select.Option key={key} value={AppConfig.accessorialsTypes[key]}>
                    {key}
                  </Select.Option>
                ))}
              </Select>

            </FormItem>
          </Col>
          {
            false && !this.state.isNew ?
              <Col sm={12} xs={12} md={8} lg={6}>
                <FormItem label={I18n.t('general.status')}>
                  <RadioGroup
                    onChange={e => this.handleOnChange('status', e.target.value)}
                    value={currentAccessorial.status}
                    style={{ marginTop: 3 }}
                  >
                    <Radio value='active'>Active</Radio>
                    <Radio value='inactive'>Inactive</Radio>
                  </RadioGroup>
                </FormItem>
              </Col> : ''
          }
          <Col xs={24}>
            {FormButtons(
              this.state.inProgress,
              this.handleSave,
              this.clearForm,
              !!this.state.isNew, true, 'default',
              this.state.isNew ? I18n.t('general.save') : I18n.t('general.update'),
              I18n.t('general.clear'),
            )}
          </Col>
        </Row>
        <Row>
          {FormErrors(this.state.errors)}
        </Row>
      </div>
    );
  }
}

Form.propTypes = {
  accessorial: PropTypes.shape().isRequired,
  isNew: PropTypes.bool.isRequired,
  org_id: PropTypes.string.isRequired,
};

export default Form;
