/* eslint-disable camelcase */
import React, { Component, Fragment , useContext } from "react";
import _ from "lodash";
import {
  deleteUser,
  fetchUsers,
  setUserStatus,
  fetchOtp,
  sendPasswordResetLink,
  sendUserPasswordReset,
  fetchUsersList,
  fetchUserDetailsById,
  fetchDrivers
} from "../api/UsersApi";
import {
  Button,
  Col,
  Row,
  Spin,
  Input,
  FormItem,
  Select,
  Tabs,
  Search,
  Result,
  Text,
} from "../common/UIComponents";
import { sendDriverNotification } from "../api/NotificationApi";
import UsersList from "../components/users/List";
import BaseModal from "../components/BaseModal";
import UsersForm from "../components/users/Form";
import Map from "../components/users/Map";
import { alertMessage,isEmpty } from "../common/Common";
import I18n from "../common/I18n";
import AppConfig from "../config/AppConfig";
import Deductions from "../components/users/Deductions";
import WeeklyReport from "../components/users/WeeklyReports";
import { checkServiceExistance, removeSpecialCharacters, renderAlertMessage } from "../helpers/common";
import DeductionReports from "../components/users/DeductionReports";
import { fetchLocations } from "../api/LocationsApi";
import { fetchDeductions } from "../api/Deductions";
import userStore from "../stores/UserStore";
import { withRouter } from "react-router";
import { WarehouseContext } from "../context/WarehouseContext";
import { OrgContext } from "../context/OrgContext";
import { RecordsPerPage } from "../components/orders/RecordsPerPage";
import { fetchOrganizations } from "../api/Organisations";
import { UserContext } from "../context/UserContext";
import { ScreenKeys } from "./constants";
import DriverWeeklyReports from "../components/reports/weekly_reports/DriverWeeklyReports";
import DriversInvoicesList from "../components/reports/DriversInvoicesList";
import DriverInvoiceReports from "../components/reports/weekly_reports/DriverInvoiceReports";
import ManageDriverSettlements from "./ManageDriverSettlements";
import { fetchAgent } from "../api/Agent";
import DocumentTypes from "../components/DocumentTypes/DocumentTypes";

const userTypes = {
  "/drivers": "driver",
  "/customers": "customer",
  "/users": "user",
};
const { Option } = Select;
const { TabPane } = Tabs;
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      pagination: {},
      showFormModal: false,
      currentUser: {},
      inProgress: false,
      isFetchingUsers : false,
      warehouses: [],
      view: "grid",
      organizationSettings: {},
      isMilitaryTime: false,
      notification: {
        driver_ids: [],
        message: "",
      },
      showNotify: false,
      activeKey: "1",
      sortBy: "none",
      sortOrder: "ascend",
      deductions: [],
      recordsPerPage: props.recordsPerPage,
      organization_id: "",
      status : "active",
      organizations: [],
    };
    this.tableOptions = {
      pagination: {},
      filters: {},
      sorter: {},
      searchText: "",
    };
    this.userType = userTypes[_.get(props, "match.path", "driver")];
    // debounce
    //this.getUsers = _.debounce(this.getUsers, 1000);
    this.getWarehouses = _.debounce(this.getWarehouses, 1000);
  }




  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getUsers()
        updateRecordsPerPage(this.props.perPageKey, value);
      }
    );
  };

  onSearch = (value) => {
  };


  componentWillMount() {
    this.getRelatedUsers();
    this.getWarehouses();
    if(this.userType === 'driver'){
      this.getDeductions();
    }
    this.getAgents()
  }

  getAgents = () => {
    const { currentOrg } = this.props.userContext
    this.setState({ inProgress: true });
    const page = 1;
    const perPage = 10000
    const org_id = currentOrg?.id || ''
    fetchAgent(org_id ,page, perPage)
      .then((result) => {
        if (result.success) {
          this.setState({
            agents: result.agents || [],
            inProgress: false,
          });
        } else {
          this.setState({ inProgress: false });
        }
      });
   }

  getRelatedUsers = () => {
    const view = this.state.view;
    const initial =
      view === "map"
        ? { perPage: 10000, signin: true }
        : { perPage:  this.state.recordsPerPage };
    this.getUsers(initial);
  }

  componentWillUnmount() {
    this.checkInterval();
  }

  getDeductions = () => {
    const orgId = userStore.getStateValue('selectedOrg')
    fetchDeductions(orgId).then((result) => {
      if (result.success) {
        const deductions = result.deductions || []
        this.setState({
          deductions: deductions.filter((rec) => !isEmpty(rec.standard_code)),
        })
      } else {
        this.setState({ deductions: [] })
      }
    })
  }

  componentDidMount() {
    const { isSuperAdmin } = this.props
    if(isSuperAdmin){
      this.getOrganizations();
    }
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime:
          this.props.organizationSettings.is_military_time == "true",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
        !_.isEqual(
            prevState.organizationSettings,
            this.props.organizationSettings
        )
    ) {
      this.setState({
        organizationSettings: this.props.organizationSettings,
        isMilitaryTime:
            this.props.organizationSettings.is_military_time == "true",
      });
    }
    if (!_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) || !_.isEqual(prevProps.warehouseFilter.warehouses , this.props.warehouseFilter.warehouses) && (!this.props.warehouseFilter.isFetchingWarehouses)) {
      this.tableOptions.pagination.current = 1;
      this.getUsers();
    }
  }

  checkInterval = () => {
    if(this.timer){
      clearInterval(this.timer);
    }
  }

  onSearch = (text) => {
    // const filteredString = removeSpecialCharacters(text);
    const filteredString = text.trim();
    if (filteredString.trim() === "") {
      this.tableOptions = {
        pagination: {},
        filters: {},
        sorter: {},
        searchText: "",
      };
    } else {
      this.tableOptions.searchText = filteredString;
    }
    this.tableOptions.pagination.current = 1;
    this.goToTab("1");
    this.getRelatedUsers();
  };

  getOrganizations = (cb) => {
    this.setState({ inProgress: true });
    fetchOrganizations(1, null).then((result) => {
      if (result.success) {
        const orgId = _.find(result.organizations.organizations, [
          "code",
          I18n.t("account.netmove_code"),
        ])._id;
        this.setState(
          {
            organizations: result.organizations.organizations,
            inProgress: false,
            organization_id: orgId,
          },
          () => {
            this.tableOptions.current = 1;
            this.getUsers();
            if (cb) {
              cb();
            }
          }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getWarehouses() {
    fetchLocations(AppConfig.warehouseCode, 1, null).then((result) => {
      if (result.success) {
        this.setState({
          warehouses: result.locations.locations,
        });
      }
    });
  }

  getUsers = (initial, cb) => {
    this.setState({ isFetchingUsers: true });
    const page = this.tableOptions.pagination.current;
    const status = this.state.status
    const searchText = this.tableOptions.searchText;
    const requireHelper = this.userType === 'driver' ? true : false;
    const { isSuperAdmin } = this.props
    const org_id = isSuperAdmin ? this.state.organization_id : userStore.getStateValue('selectedOrg');
    // const perPagg = initial ? _.get(initial , "perPage" , _.get(this.state.recordsPerPage)) : this.state.recordsPerPage;
    const perPage = typeof initial === 'string'
      ? this.state.recordsPerPage
      : typeof initial === 'object'
        ? _.get(initial, 'perPage', this.state.recordsPerPage)
        : this.state.recordsPerPage;
    let func = null;
    if (this.userType === "driver") {
      func = fetchDrivers
    } else {
      func = fetchUsersList;
    }

    func(
      this.userType,
      page,
      perPage,
      this.state.sortBy,
      this.state.sortOrder,
      initial ? initial.signin : false,
      searchText,
      requireHelper,
      org_id,
      status
    ).then((result) => {
      if (result.success) {
        this.setState(
            {
              users: result.users,
              pagination: result.pagination || {},
              isFetchingUsers: false,
            },
            () => {
              if (cb) {
                cb();
              }
            }
        );
      } else {
        renderAlertMessage(result.errors)
        this.setState({ isFetchingUsers: false , users: [], pagination: {} });
      }
    });
  }

  toggleView() {
    const view = this.state.view === "map" ? "grid" : "map";
    this.tableOptions.pagination.current = 1;
    const initial =
        view === "map"
            ? { perPage: 10000, signin: true }
            : { perPage: this.state.recordsPerPage };
    if(view === "map") {
      this.setState({
        view,
      }, () => {
        this.getRelatedUsers()
        this.getUsers(initial);
      });

      this.timer = setInterval(() => {
        this.getUsers(initial, () => {
        });
      }, AppConfig.fetchRefreshTime);
    }else {
      this.getUsers(initial, () => {
        this.checkInterval();
        this.setState({
          view,
        });
      });
    }
  }

  handleAddClick = () => {
    this.setState({ currentUser: { webRolesData: [],mobileRolesData:[]}, showFormModal: true });
  };

  handleEditClick = (id) => {
    fetchUserDetailsById(id).then(response => {
      if (response.success) {
        const user = Object.assign({}, response.user);
    // if (user.roles) {
    //   if (user.roles.includes("super_admin")) {
    //     user.roles = "super_admin";
    //   } else if (user.roles.includes("admin")) {
    //     user.roles = "admin";
    //   }
    // }
    // Need to remove when multiple is enabled
    if (user.organization_ids && user.organization_ids.length > 0) {
      user.org_id = user.organization_ids[0];
    }

    if (user.warehouse_ids && user.warehouse_ids.length > 0) {
      user.warehouse_ids = user.warehouse_ids.filter((wh) => !isEmpty(wh));
    } else {
      user.warehouse_ids = [];
    }

    if (user.zones && user.zones.length > 0) {
      user.zone_ids = user.zones.map((zone) => zone.id);
    } else {
      user.zone_ids = [];
    }
    if (user.dv_location && user.dv_location.l_address) {
      user.l_address = user.dv_location.l_address;
    } else {
      user.l_address = {};
    }
        if (user?.roles) {
      const mobileRoles = AppConfig.mobileRoles.map((role) => role.role_code);
      user.webRolesData = user.roles.filter((role) => !mobileRoles.includes(role));
      user.mobileRolesData = user.roles.filter((role) => mobileRoles.includes(role));
    } else {
      user.webRolesData = [];
      user.mobileRolesData = [];
    }
    this.setState({ currentUser: user, showFormModal: true });
      } else {
        renderAlertMessage(response.errors);
      }
    }).catch(error => {
      console.error("Error fetching user details:", error);
    });
  };

  handleDeleteClick = (id) => {
    this.setState({ inProgress: true });
    deleteUser(id).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getRelatedUsers();
        this.setState({ inProgress: false });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handleSaveSuccess = (message) => {
    alertMessage(message);
    this.setState({ showFormModal: false });
    this.getRelatedUsers();
  };

  handleOnModalClose = (ignore) => {
    const { currentUser } = this.state;
    if(ignore){
      this.setState({ showFormModal: false });
      return;
    }
    if (currentUser?.insurance_details && currentUser.insurance_details.length > 0) {
      const invalidDocsExist = currentUser.insurance_details.some(details =>
        details.document_proofs.some(doc => !doc._id)
      );
      if (invalidDocsExist) {
        alertMessage("Please Save the User as the insurance documents are not saved", "error", 10);
        return;
      }
    }

    this.setState({ showFormModal: false });
  };


  handleTableChange = (pagination, filters, sorter) => {
    const currentPage =
        this.tableOptions.pagination && this.tableOptions.pagination.current
            ? this.tableOptions.pagination.current
            : 1;
    this.tableOptions = {
      pagination,
      filters,
      sorter,
      searchText:
          this.tableOptions && this.tableOptions.searchText
              ? this.tableOptions.searchText
              : "",
    };
    if (pagination.current !== currentPage) {
      this.getRelatedUsers();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
          {
            sortBy: !isEmpty(sorter.columnKey) ? sorter.columnKey : "none",
            sortOrder: !isEmpty(sorter.order) ? sorter.order : "ascend",
          },
          () => {
            this.tableOptions.pagination.current = 1;
            this.getRelatedUsers();
          }
      );
    }
  };

  showNotifyModal = (driver_ids, data) => {
    const { notification } = this.state;
    notification.driver_ids = [driver_ids];
    this.setState({
      showNotify: true,
      notification,
    });
  };

  addMultipleDriverNotifications = (element, value) => {
    const notification = Object.assign({}, this.state.notification, {
      [element]: value,
    });
    this.setState({
      notification,
    });
  };

  handleNotifySubmit = () => {
    this.setState({ inProgress: true });
    const { driver_ids, message } = this.state.notification;
    if (driver_ids && message) {
      sendDriverNotification({ driver_ids, message }).then((result) => {
        if (result.success) {
          this.setState({ showNotify: false, inProgress: false }, () => {
            alertMessage(result.message, "success", 10);
          });
        } else {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      });
    }
  };

  closeNotifyModal = () => {
    this.setState({
      showNotify: false,
      notification: {
        appointment_id: "",
        message: "",
      },
    });
  };

  handleGenerate = (record, initial, cb) => {
    let obj = {};
    const userId = record.id;
    fetchOtp(userId).then((result) => {
      if (result.success) {
        const users = [...this.state.users];
        const currentUserIndex = _.findIndex(users, ["id", userId]);
        if (currentUserIndex >= 0) {
          users[currentUserIndex].latest_otp = result.data || {};
          this.setState(
              {
                users,
                inProgress: false,
              },
              () => {
                if (cb) {
                  cb();
                }
              }
          );
        }
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  handlePasswordReset = (record) => {
    const email = !isEmpty(record.email) ? record.email : "";
    const mobile_number = !isEmpty(record.mobile_number) ? record.mobile_number: "";
    const paylod = {
      email,
      mobile_number,
    };
    sendUserPasswordReset(paylod).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.instruction_sent"), "success", 10);
      } else {
        alertMessage(result.error, "error", 10);
      }
    });
  }

  handleNotifyMessage = (e) => {
    const { notification } = this.state;
    notification.message = e.target.value;
    this.setState({
      notification,
    });
  };

  handleUserStatus = (element, value, id) => {
    const { users } = this.state;
    const index = _.findIndex(users, ["id", id]);
    const user = Object.assign({}, users[index], {
      [element]: value,
    });
    users[index][element] = value;
    const data = {
      driver_id: id,
    };
    setUserStatus(data).then((result) => {
      if (result.success) {
        this.setState({ currentUser: user, users });
        alertMessage("Changed Successfully", "success", 10);
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  renderNotifyModal() {
    const { TextArea } = Input;
    return (
        <BaseModal
            title="Send Notification"
            onOk={() => this.sendNotification()}
            onCancel={() => this.closeNotifyModal()}
            maskClosable={false}
        >
          <div>
          <span>
            <b>Driver: </b>
          </span>
            {/* <span>{this.state.notifyDriverName}</span> */}
            <Select
                mode="multiple"
                style={{ width: "100%" }}
                onChange={(val) =>
                    this.addMultipleDriverNotifications("driver_ids", val)
                }
                className="ADD-CRO"
                value={this.state.notification.driver_ids}
                filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
              {this.state.users.map((province) => (
                  <Option key={province.id} value={province.id}>
                    {province.full_name}
                  </Option>
              ))}
            </Select>
            <FormItem label={I18n.t("general.message")}>
              <TextArea rows={4} onChange={(e) => this.handleNotifyMessage(e)} />
            </FormItem>
            <Button
                type="primary"
                onClick={() => this.handleNotifySubmit()}
                icon='check-circle'
                disabled={_.isEmpty(this.state.notification.message)}
                // className="buttonModal"
            >
              {I18n.t("general.send")}
            </Button>
          </div>
        </BaseModal>
    );
  }

  renderFormModal() {
    let title;
    let isNew;
    if (this.state.currentUser.id) {
      title = I18n.t(
          `users${this.userType === "driver" ? `.${this.userType}` : ""}.edit`
      );
      isNew = false;
    } else {
      title = I18n.t(
          `users${this.userType === "driver" ? `.${this.userType}` : ""}.add`
      );
      isNew = true;
    }
    const currentUser = {
      ...this.state.currentUser,
      warehouse_ids: this.state.currentUser?.warehouse_ids
          ? this.state.currentUser.warehouse_ids
          : [],
    };
    return (
        <BaseModal
            title={title}
            onCancel={() => this.handleOnModalClose()}
            style={{ top: 30 }}
            width="80%"
            maskClosable={false}
        >
          <UsersForm
              handleEditClick={() => this.handleEditClick()}
              user={currentUser}
              warehouses={this.state.warehouses}
              isNew={isNew}
              onCancel={(ignore) => this.handleOnModalClose(ignore)}
              onSuccess={(message) => this.handleSaveSuccess(message)}
              userType={this.userType}
              agents={this.state.agents}
          />
        </BaseModal>
    );
  }

  goToTab = (step) => {
    this.setState({
      activeKey: step,
    });
  };
  updateDeductions = (deductions) => {
    this.setState({
      deductions: deductions ? deductions.filter((rec) => !isEmpty(rec.standard_code)) : [],
    })
  }

  renderTabs = () => {
    const { currentOrg, orgRules } = this.props.userContext
    const currentOrgCode = currentOrg?.code ?  currentOrg?.code : "";
    const displayDriverReportsBy = _.get(orgRules, 'org_billing_rules.display_driver_set_reports_by', 'weekly');
    return (
      <div className="card-container tabHover">
        <Tabs
            onChange={this.goToTab}
            type="card"
            activeKey={this.state.activeKey}
        >
          <TabPane tab={I18n.t("users.listing.driver")} key="1">
            {this.renderList()}
          </TabPane>
          {/*checkServiceExistance("DMGL") && (
          <TabPane tab={I18n.t("deductions.master_label")} key="2">
            <Deductions updateDeductions={this.updateDeductions} />
          </TabPane>
        )*/}
          {(displayDriverReportsBy == "weekly") && checkServiceExistance("GOR") && (
              <TabPane tab={I18n.t("reports.weekly_settlement_reports")} key="3">
                  <WeeklyReport warehouseFilter={this.props.warehouseFilter}/>
              </TabPane>
          )}
          {checkServiceExistance("GOR") && (displayDriverReportsBy == "daily") && (
              <TabPane tab={I18n.t("reports.settlement_reports")} key="air_cargo_screen">
                <ManageDriverSettlements warehouseFilter={this.props.warehouseFilter} dispContext={this.props.dispContext} />
              </TabPane>
          )}
          {false && checkServiceExistance('DMR') && <TabPane tab={I18n.t("reports.claim_reports")} key="4">
            <DeductionReports />
          </TabPane>
          }
          {(displayDriverReportsBy == "weekly") && (<TabPane tab={I18n.t("reports.weekly_reports")} key="weekReports">
            {this.renderWeeklyReport()}
          </TabPane>)
          }

          <TabPane tab={I18n.t("reports.driver_invoices")} key="invoiceReports">
            {this.renderDriversInvoices()}
          </TabPane>
          <TabPane tab="Document Type" key="document_types">
            <DocumentTypes  activeKey={this.state.activeKey}/>
          </TabPane>
        </Tabs>
      </div>
  )};

  renderOptions = () =>
      this.userType === "driver" ? this.renderTabs() : this.renderList();

  renderList = () => {
    const { pagination, warehouses, users } = this.state;
    // adding warehouse of each driver...
    const usersList = users?.map((v) =>
        Object.assign(v, {
          warehouses: warehouses
              .filter((wh) => v.warehouse_ids.includes(wh.id))
              .map((wh) => wh.name),
        })
    );

    return (
      <div>
        <Row className="page-content" type="flex" justify="end" gutter={16}>
          <Col>
            <Text strong>{"Status"}</Text>&nbsp;
            <Select
              value={this.state.status || ""}
              showSearch
              size="small"
              style={{ width: 120, marginLeft: 5 }}
              onChange={(e) => this.handleOnStateChange("status", e)}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {AppConfig.ActiveStatus.map((data) => (
                <Select.Option key={data.status} value={data.status}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <RecordsPerPage
              onChange={this.onRecordChange}
              onSearch={this.onSearch}
              value={this.state.recordsPerPage}
              defaultValue={this.state.recordsPerPage}
            />
          </Col>
        </Row>
        <UsersList
          data={usersList}
          // scroll = {{y:680}}
          // scroll = {{y:"calc(100vh - 320px)"}}
          scroll = {{y: this.userType === "driver" ? "calc(100vh - 305px)" : "calc(100vh - 235px)"}}
          editClick={(id) => this.handleEditClick(id)}
          deleteClick={(id) => this.handleDeleteClick(id)}
          showNotifyModal={this.showNotifyModal}
          closeNotifyModal={this.closeNotifyModal}
          setStatus={(element, value, id) =>
            this.handleUserStatus(element, value, id)
          }
          pagination={{
            total: pagination?.total_count || 0,
            current: pagination?.current_page || 1,
            pageSize: this.state.recordsPerPage,
          }}
          tableChange={(paginationInfo, filters, sorter) =>
            this.handleTableChange(paginationInfo, filters, sorter)
          }
          userType={this.userType}
          generateClick={(record) => this.handleGenerate(record)}
          isMilitaryTime={this.state.isMilitaryTime}
          generatePasswordResetLink = {this.handlePasswordReset}
          deductions={this.state.deductions}
          userContext={this.props.userContext}
        />
      </div>
    );
  };

  renderWeeklyReport = () => {
    return(
      <div>
        <DriverWeeklyReports />
      </div>
    )
  }
  renderDriversInvoices = () => {
    return(
      <div>
        <DriverInvoiceReports tabKey={this.state.activeKey}/>
      </div>
    )
  }
  handleOnStateChange = (element, value) => {
    this.setState({ [element]: value }, () => {
      if(element === "organization_id"){
        this.tableOptions.pagination.current = 1;
        this.getUsers(value);
      }
      if(element === "status"){
        this.tableOptions.pagination.current = 1;
        this.getUsers();
      }
    });
  }

  renderFilter = () => (
    <Row>
      <Col sm={24} xs={24} md={5} lg={5}>
        <FormItem label={I18n.t("organization.single")} require>
          <Select
            value={this.state.organization_id || ""}
            showSearch
            onChange={(e) => this.handleOnStateChange("organization_id", e)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            {this.state.organizations.map((orgtype) => (
              <Select.Option key={orgtype.code} value={orgtype._id}>
                {orgtype.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Col>
    </Row>
  );

  render() {
    const { isSuperAdmin } = this.props
    return (
        <div className="content-outer">
          {this.props.isDuplicateTab && (
              <BaseModal title="Alert" closable={false} footer={null} width="50%">
                <Result
                    status="error"
                    title="Duplicate Tab Detected"
                    subTitle="You have already opened this tab. Please close the existing tab and try again."
                    extra={[
                      <Button
                          type="primary"
                          key="console"
                          onClick={() => {
                            window.location.reload();
                          }}
                      >
                        Reload
                      </Button>,
                    ]}
                ></Result>
              </BaseModal>
          )}
          <div className="content">
            <Row className="page-header">
              <Col xs={12}>{I18n.t(`users.listing.${this.userType}`)}</Col>
              <Col
                  xs={12}
                  className="page-actions"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
              >
                <Col xs={8}>
                  <Search
                      placeholder={`${I18n.t(`general.search`)} ${I18n.t(
                          `users.listing.${this.userType}`
                      )}`}
                      onSearch={this.onSearch}
                      allowClear
                      size="default"
                  />
                </Col>
                {checkServiceExistance("UI") && this.userType === "driver" ? (
                    <Button
                        className="page-toggle-button"
                        type="primary"
                        onClick={() => this.toggleView()}
                        icon={this.state.view === "grid" ? "global" : "bars"}
                    >
                      {this.state.view === "grid"
                          ? I18n.t("general.map")
                          : I18n.t("general.list")}
                    </Button>
                ) : (
                    <Fragment />
                )}
                {checkServiceExistance("UC") && (
                    <Button
                        type="primary"
                        className={this.userType !== "driver" ? "marginLeft10" : ""}
                        onClick={() => this.handleAddClick()}
                        icon="plus"
                        disabled={this.state.inProgress}
                    >
                      {I18n.t(
                          `users${
                              this.userType === "driver" ? `.${this.userType}` : ""
                          }.add`
                      )}
                    </Button>
                )}
              </Col>
            </Row>
            <Spin spinning={this.state.inProgress || this.state.isFetchingUsers} >
              {checkServiceExistance("UI") && (
                  <Row>
                    <Col>
                      {this.state.view === "map" ? (
                          <Map data={this.state.users} userType={this.userType} />
                      ) : (<>
                          {isSuperAdmin && <this.renderFilter/>}
                          <this.renderOptions />
                          </>
                      )}
                    </Col>
                  </Row>
              )}
            </Spin>
            {this.state.showFormModal && this.renderFormModal()}
            {this.state.showNotify ? this.renderNotifyModal() : <Fragment />}
          </div>
        </div>
    );
  }
}
export const UsersComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings, isAirCargo } = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const { currentRole } = userContext;
  const isSuperAdmin = currentRole === 'super_admin';
  const perPageKey = isSuperAdmin ?  ScreenKeys.USERS_LISTING_SUPER_ADMIN : ScreenKeys.USERS_LISTING;
  const recordsPerPage = _.get(userContext, `currentUser.per_page_saved.${perPageKey}`, AppConfig.ordersPerPage)
  return (
    <Users
      warehouseFilter={ warehouseFilter }
      organizationSettings={ organizationSettings }
      userContext={ userContext }
      recordsPerPage={ recordsPerPage }
      isSuperAdmin={isSuperAdmin}
      perPageKey={ perPageKey }
      isAirCargo={isAirCargo}
      { ...props }
    />
  );
});
export default UsersComponent;
