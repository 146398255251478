import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import AddressForm from '../components/common/AddressForm';
import AppConfig from '../config/AppConfig';
import IncorrectOrdersMap from './IncorrectOrdersMap';
import { Button,Row, Col, Spin, Badge, Result } from 'antd';
import { confirmationPopup } from '../helpers/common';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import _ from "lodash";
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import { resolveAddressApi } from '../api/OrdersApi';
import userStore from '../stores/UserStore';
import WrongAddressList from './WrongAddressList';
import { validAddressToResolve } from '../helpers/preplan';
import AddressSearch from '../stories/components/AddressSearch';

function ResolveMultiAddress (props) {
  const { unassignedOrdersInfo, orderInfo, setMadeAddressChanges } = props;

  const [ wrongAddress, setWrongAddress ] = useState({});
  const [ wrongAddresses, setWrongAddresses ] = useState([]); // original wrong addresses
  const [ modifiedWrongAddresses, setModifiedWrongAddresses ] = useState([]); // wrong addresses modified by user in the form
  const [ madeChanges, setMadeChanges ] = useState(false);
  const [ isAddressReset, setIsAddressReset ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ rowKeys, setRowKeys ] = useState([]);
  const [ selectedRecordId, setSelectedRecordId ] = useState(null);
  const [ resovledFlag, setResolvedFlag ] = useState(false);
  const [resetCustomMarker, setResetCustomMarker] = useState(false);
  const memoziedValidAddressToResolve = useCallback(validAddressToResolve, []);

  useEffect(() => {
    // const selectedOrdersWithPartialMatch = unassignedOrdersInfo.selectedOrderRows.filter(order => {
    //   const orderMatch = orderInfo.find(o => o.id === order.id);
    //   return orderMatch; // && orderMatch.location_partial_match;
    // });
    // const addressInfo = unassignedOrdersInfo.selectedOrderRows.map(order => {
    //   return {
    //     customer_order_id: order.id,
    //     customer_order_number: order.customer_order_number,
    //     customer_address: order.customer_address,
    //   };
    // });
    const addressInfo = [];
    unassignedOrdersInfo.selectedOrderRows.forEach(order => {
      order.locations.forEach(location => {
        const isTransfer = ['T', 'LH'].includes(order.type_of_order);
        const isPickup =  location.type_of_loc === "PICKUP";
        addressInfo.push({
          customer_order_id: location._id,
          customer_order_number : isTransfer ? order.customer_order_number + (isPickup ? "P" : "D") : order.customer_order_number,
          customer_address: location.l_address,
          type_of_loc: location.type_of_loc,
        });
      });
    });
    setWrongAddresses(addressInfo);
    setModifiedWrongAddresses(addressInfo);
    // select all addresses initially
    setRowKeys([...Array(addressInfo.length).keys()]);

  }, []);

  const onSelectSuggestion = (fetchedAddress) => {
    setWrongAddress(fetchedAddress);
    setModifiedWrongAddresses(modifiedWrongAddresses.map((address, index) => {
      if (rowKeys.includes(index)) {
        return {
          ...address,
          customer_address: fetchedAddress,
        };
      }
      return address;
    }));
    setIsAddressReset(false);
    setMadeChanges(true);
  };

  const handleMarkerSelection = (index) => {
    const selectedOrder = modifiedWrongAddresses.find((order, i) => i === index);
    const selectedOrderCoordinates = selectedOrder.customer_address.coordinates;
    const ordersWithSameCoordinates = modifiedWrongAddresses.filter((order, i) => {
      // compare if both arrays have same values
      return order.customer_address.coordinates.every((value, index) => value === selectedOrderCoordinates[ index ]);
    });
    const ordersWithSameCoordinatesIndexes = ordersWithSameCoordinates.map(order => modifiedWrongAddresses.indexOf(order));
    // combine with previous selected orders havin only unique values
    const finalRowKeys = [ ...new Set([ ...rowKeys, ...ordersWithSameCoordinatesIndexes ]) ];
    setRowKeys(finalRowKeys);
  };

  const onCancel = () => {
    if (madeChanges) {
      const confirmationInfo = {
        title: I18n.t('general.are_you_sure'),
        content: I18n.t('messages.unsaved_changes'),
        onConfirm: () => {
          props.closeModal(resovledFlag);
        },
      };
      confirmationPopup(confirmationInfo);
    } else {
      props.closeModal(resovledFlag);
    }
  };

  const clearFalseNegative = () => {
    const org_id = userStore.getStateValue("selectedOrg");
    setIsLoading(true);
    // get all the orders ids in the selected rows
    const orderIds = rowKeys.map(rowNum => wrongAddresses[ rowNum ].customer_order_id);
    resolveAddressApi.clearFalseNegative(org_id, orderIds).then(() => {
      // filter out the orders that are cleared
      setWrongAddresses(wrongAddresses.filter((address, index) => !rowKeys.includes(index)));
      setRowKeys([]);
      setResolvedFlag(true);
      setMadeAddressChanges(true);
      setWrongAddress({});
      setIsAddressReset(true);
      // remove the orders from modifiedWrongAddresses
      setModifiedWrongAddresses(modifiedWrongAddresses.filter((address, index) => !rowKeys.includes(index)));
      alertMessage(I18n.t('messages.address_corrected'));
    }).finally(() => {
      setIsLoading(false);
    });
  };



  const onResolveAddress = () => {
    if (!memoziedValidAddressToResolve(wrongAddress)) {
        if(_.isObject(wrongAddress) && Object.values(wrongAddress).length === 0){
          alertMessage(I18n.t("messages.select_valid_address") , 'error')
        } else{
          alertMessage(I18n.t("messages.invalid_address") , 'error')
        }
      return;
    }
    const newAddress = {
      ...wrongAddress
    };
    delete newAddress.coordinates;
    const currentOrgId = userStore.getStateValue("selectedOrg");
    setIsLoading(true);
    // resolve multiple addresses 
    const orderIds = rowKeys.map(rowNum => wrongAddresses[ rowNum ].customer_order_id);
    const payload = {
      org_id: currentOrgId,
      order_ids: orderIds,
      address: newAddress,
    };

    handleResetCustomMarker()
    resolveAddressApi.fixWrongAddress(payload).then(() => {
      alertMessage(I18n.t('messages.address_corrected'));
    }).finally(() => {
      setIsLoading(false);
      setWrongAddresses(wrongAddresses.filter((address, index) => !rowKeys.includes(index)));
      setModifiedWrongAddresses(modifiedWrongAddresses.filter((address, index) => !rowKeys.includes(index)));
      setRowKeys([]);
      setMadeChanges(false);
      setWrongAddress({});
      setResolvedFlag(true);
      setMadeAddressChanges(true);
      setIsAddressReset(true);
    }).catch(error => {
    });
  };

  const resetWrongAddress = () => {
    setWrongAddress({});
    setModifiedWrongAddresses(wrongAddresses);
    setSelectedRecordId(null);
    setIsAddressReset(true);
    setMadeChanges(false);
    setRowKeys([]);
  };

  const geoCodeUpdateAddress = (updatedAddress) => {
    const addressToGeocode = `${updatedAddress.address_line1}, ${updatedAddress.city}, ${updatedAddress.state}, ${updatedAddress.country}`;
    geocodeByAddress(addressToGeocode)
      .then(results => getLatLng(results[ 0 ]))
      .then(latLng => {
        const newAddress = {
          ...updatedAddress,
          coordinates: [ latLng.lng, latLng.lat ]
        };
        onSelectSuggestion(newAddress);
      })
      .catch(error =>
        alertMessage(I18n.t("messages.invalid_address") , 'error'));
  };

  const geoCodeUpdateAddressDebounced = _.debounce(geoCodeUpdateAddress, 1000)


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowKeys(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: rowKeys,
  };

  const changeActiveAddress = (customer_order_id) => {
    // replace the modfied address with the new address which belongs to the customer_order_id
    const activeAddress = wrongAddresses.find(address => address.customer_order_id === customer_order_id);

    if (!rowKeys.length) {
      alertMessage(I18n.t('messages.select_address_to_update'), "warning");
      return;
    }
    // get the selected rows
    const selectedAddresses = rowKeys.map(key => { return wrongAddresses[ key ]; });
    const UnselectedAddresses = wrongAddresses.filter((address, index) => { return !rowKeys.includes(index); });
    const updatedAddress = selectedAddresses.map(i => {
      const { ...rest } = i;
      return {
        ...rest,
        customer_address: activeAddress.customer_address,
      };
    });
    setModifiedWrongAddresses([ ...UnselectedAddresses, ...updatedAddress ]);
    setWrongAddress(activeAddress.customer_address);
    setSelectedRecordId(customer_order_id);
  };

  const handleCustomMarkerSelection = (address) => {
    setWrongAddress(address);
    setMadeChanges(true);
  };

  const handleResetCustomMarker = () => {
    setResetCustomMarker(true);
  };

  return (
    <>
      { wrongAddresses.length ? (
        <Row>
          <Col style={ {
            marginBottom: '15px',
          } }>
            <WrongAddressList
              className="wrong-address-list"
              size='small'
              rowSelection={ rowSelection }
              data={ wrongAddresses }
              changeActiveAddress={ changeActiveAddress }
              isLoading={ isLoading }
              selectedRecordId={ selectedRecordId }
              pagination={ { position: 'none' } }
              scroll={ { y: 200 } }
            />
          </Col>
          <Col>
            <Spin spinning={ isLoading }>
              <Row>
                <Col span={ 24 }>
                  <AddressSearch onSelect={ (address) => {
                    onSelectSuggestion(address.l_address);
                  } } />
                  <AddressForm
                    autoComplete={ true }
                    isAddressReset={ isAddressReset }
                    setIsAddressReset={ setIsAddressReset }
                    includePredefinedStops={ false }
                    address={
                      wrongAddress
                    }
                    onChange={ (element, value, obj) => {
                      handleResetCustomMarker();
                      setSelectedRecordId(null);
                      if (element == '') {
                        onSelectSuggestion(obj);
                      } else {
                        const updatedAddress = { ...wrongAddress, [ element ]: value };
                        setWrongAddress(updatedAddress);
                        geoCodeUpdateAddressDebounced(updatedAddress);
                      }
                    } }

                    onConsigneeLocationChange={ () => { } }
                    isAdmin
                    disabled={ false }
                  />
                </Col>
              </Row>
              <div className="resolveAddressMapContainer">
                <IncorrectOrdersMap
                  loadingElement={ <Spin spinning={ true } /> }
                  containerElement={ <div style={ { height: "100%", width: "100%" } } /> }
                  mapElement={ <div style={ { height: "100%", width: "100%" } } /> }
                  googleMapURL={ `https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places` }
                  orderInfo={ modifiedWrongAddresses }
                  rowKeys={ rowKeys }
                  handleMarkerSelection={ handleMarkerSelection }
                  handleCustomMarkerSelection={ handleCustomMarkerSelection }
                  resetCustomMarker={ resetCustomMarker }
                  handleResetCustomMarker={ () => { setResetCustomMarker(false) } }
                />
              </div>
              <div className="resolveAddressButtons" style={ {
                display: 'flex',
                justifyContent: 'center',
                margin: '20px 0 0 0',
                gap: 10,
              } }>
                <Button icon='undo' type="secondary" onClick={ () => {
                  resetWrongAddress();
                } }> { I18n.t("general.clear") } </Button>
                <Badge count={ rowKeys.length } offset={ [ -10, 5 ] } showZero>
                  <Button icon='check-circle' style={ {
                    backgroundColor: '#00a854',
                    color: '#fff',
                    marginRight: '10px',
                  } } onClick={ () => {
                    clearFalseNegative();
                  }
                  }
                    disabled={ rowKeys.length === 0 }>
                    { I18n.t("general.marK_as_verified") } </Button>
                </Badge>
                <Badge count={ rowKeys.length } offset={ [ -10, 5 ] } showZero>
                  <Button icon='save' type="primary" onClick={ () => {
                    onResolveAddress(wrongAddress);
                  } }
                    disabled={ rowKeys.length === 0 }>
                    { I18n.t("general.update") } </Button>
                </Badge>
                <Button icon='close' type="danger" onClick={ () => {
                  onCancel();
                } }> { I18n.t("general.cancel") } </Button>
              </div>
            </Spin>
          </Col>
        </Row>
      ) : (
        <Result
          status="success"
          title={ I18n.t("messages.no_incorrect_address") }
          extra={ [
            <Button type="primary" key="console" onClick={ () => {
              onCancel();
            } }>
              Close
            </Button>,
          ] }
        />
      ) }

    </>
  );
}

export default ResolveMultiAddress;


ResolveMultiAddress.propTypes = {
  unassignedOrdersInfo: PropTypes.object,
  closeModal: PropTypes.func,
};


ResolveMultiAddress.defaultProps = {
  unassignedOrdersInfo: {},
  closeModal: () => { },
};