import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Popconfirm, Button } from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import { withRouter } from "react-router";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import I18n from "../../common/I18n";

const BulkOperations = ({ selectedInfo, applyAmount, handleCancel }) => {
  const size = 2;
  return (
    <Row className="bottom_fixed">
      <Col xs={24} className="alignCenter">
        <Button
          type="danger"
          onClick={handleCancel}
          icon="close"
          size="small"
        >
          Clear
        </Button>
        &nbsp;&nbsp;
        {checkServiceExistance("COD") && (
          <Popconfirm
            placement="top"
            title={I18n.t("messages.action_confirm", {action: 'pay', field: ''})}
            onConfirm={applyAmount}
            okText="Yes"
            cancelText="No"
            
          >
            <Button type="primary"  icon="dollar" size="small">
              Pay Line Amount
            </Button>
          </Popconfirm>
        )}
      </Col>
    </Row>
  );
};

const BulkOperationsComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  return (
    <BulkOperations
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      {...props}
    />
  );
});
BulkOperations.propTypes = {
  filter: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleReceiveToConfirm: PropTypes.func.isRequired,
  multipleOrderDeletion: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  resetOrders: PropTypes.func.isRequired,
  handleMultipleOrdersStatusChange: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  handleStatusResult: PropTypes.func.isRequired,
  enableLoading: PropTypes.func.isRequired,
  handleEditOrder: PropTypes.func.isRequired,
};

BulkOperations.defaultProps = {
  handleStatusResult: () => {},
  enableLoading: () => {},
  handleEditOrder: () => {},
};

export default BulkOperationsComponent;
