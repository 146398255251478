import { Col, Row, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import I18n from "../../common/I18n";
import { fetchShortFormAccounts } from "../../api/Account";
import { WarehouseContext } from "../../context/WarehouseContext";
import { WrappedMonthlyCutForm } from "./MonthlyCutForm";
import { OrgContext } from "../../context/OrgContext";
import { UserContext } from "../../context/UserContext";
import { ScreenKeys } from "../../containers/constants";
import AppConfig from "../../config/AppConfig";
import { fetchMonthlyCutOff } from "../../api/MonthlyCutOff";
import { renderAlertMessage } from "../../helpers/common";
import moment from "moment";
import { alertMessage } from "../../common/Common";

export const MonthlyCutOffScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const warehouseFilter = useContext(WarehouseContext);
  const userContextData = useContext(UserContext);
  const orgData = useContext(OrgContext);
  const { updateRecordsPerPage } = userContextData;
  const perPageKey = ScreenKeys.MONTHLY_CUTOFF;
  const recordsPerPageSaved = _.get(
    userContextData,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageSaved);
  const [pagination, setPagination] = useState({ current_page: 1 });
  let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
  const [cutoffData, setCutOffData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [uniqueCustomerOrderIds, setUniqueCustomerOrderIds] = useState([]);
  const currentWh =
    typeof warehouseFilter.selectedWarehouses === "string"
      ? _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses,
        })
      : _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses[0],
        });

  const defaultFormData = {
    warehouse: currentWh ? currentWh.id : "",
    account_code: "",
    account_id: "",
    normalDate: null,
    fromDate: moment().subtract(15, "days"),
    toDate: moment(),
    filterOrder:"ALL"
  };
  const [formData, setFormData] = useState({
    ...defaultFormData,
  });

  const onRecordChange = (value) => {
    if (!value) {
      value = recordsPerPageSaved;
    }
    tableOptions.pagination.current = 1;
    setRecordsPerPage(value);
    updateRecordsPerPage(perPageKey, value);
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || recordsPerPage,
  };

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1;
    tableOptions = { pagination, filters, sorter };
        // if (tableOptions.pagination.current !== currentPage) {
      debouncedCutOffData(sorter);
    // }
  };

  const gatherFormData = (formData) => {
    setFormData(formData);
  };

  const ObtainedSelectedData = (selectedData) => {
    const selectedCustomerOrderIds = cutoffData
      .filter(item => selectedData.includes(item.id))
      .map(item => item.customer_order_id);
  
    // Combine new ids with existing ones, ensuring uniqueness
    const updatedUniqueIds = Array.from(new Set([...uniqueCustomerOrderIds, ...selectedCustomerOrderIds]));
    setUniqueCustomerOrderIds(updatedUniqueIds);
  };

  const fetchingCutOffData = ( sorterInfo = null) => {
    setLoading(true);
    const page =
      tableOptions.pagination?.current &&
      !isEmpty(tableOptions.pagination.current)
        ? tableOptions.pagination.current
        : 1;
    const perPage = recordsPerPage;
    const orgId = _.get(orgData, "currentOrg.id", "");
    const whId = _.get(formData, "warehouse");
    const accId = _.get(formData, "account_id");
    const fromDateFormatted = moment(formData?.fromDate).format("YYYY-MM-DD");
    const toDateFormatted = moment(formData?.toDate).format("YYYY-MM-DD");
    const orderFilter = _.get(formData, "filterOrder") === "ALL" ? [] : _.get(formData, "filterOrder");
    const payload = {
      page,
      perPage,
      whId,
      orgId,
      accId,
      fromDateFormatted,
      toDateFormatted,
      orderFilter,
      sort_by: sorterInfo?.columnKey || 'actual_billing_date',
      sort_order: sorterInfo?.order ? sorterInfo.order === 'descend' ? 'desc' : 'asc' : 'asc',
    };
    // if (isApproved) {
    //   // if(_.isEmpty(approvedDate)){
    //   //   alertMessage("Please select New Invoice Date", "error");
    //   //   setLoading(false);
    //   //   setButtonLoading(false);
    //   //   return;
    //   // }
    //   setButtonLoading(true);
    //   setLoading(true);
    // }
    fetchMonthlyCutOff(payload)
      .then((response) => {
        if (response.success) {
          setCutOffData(response.data.billing_orders);
          setPagination(response.data.meta.pagination);
          setLoading(false);
          // if (isApproved) {
          //   alertMessage("Approved Successfully", "success");
          //   setButtonLoading(false);
          // }
        } else {
          setLoading(false);
          setButtonLoading(false);
          renderAlertMessage(response.errors);
          setSearchLoading(false);
          setIsSearching(false);
          setIsApproved(false);
          setCutOffData([]);
          setPagination({ current_page: 1 })
        }
      })
      .catch((error) => {
        setLoading(false);
        renderAlertMessage(error);
      })
      .finally(() => {
        setLoading(false);
        setIsApproved(false);
        setIsSearching(false);
        setSearchLoading(false);
      });
  };

  const debouncedCutOffData = _.debounce(fetchingCutOffData, 500);

  useEffect(() => {
    debouncedCutOffData();
  }, [isApproved,recordsPerPage,warehouseFilter.selectedWarehouses, warehouseFilter.warehouses]);

  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
  }, []);

  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
    getAccounts(warehouseFilter.selectedWarehouses);
  }, [warehouseFilter.selectedWarehouses, warehouseFilter.warehouses,warehouseFilter.warehouseWithAccounts]);

  const refreshAccounts = (whId) => {
    getAccounts(whId);
  };


  const getAccounts = (whId) => {
    setLoading(true);
   const accountsData =  warehouseFilter.findMatchingAccounts(whId)
    setAccounts(accountsData);
    setLoading(false);
  }

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={24}>{I18n.t("MonthlyCutoff.title")}</Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col>
              <Col className="marginTop10">
                <WrappedMonthlyCutForm
                  warehouses={warehouses}
                  accounts={accounts}
                  currentWh={currentWh}
                  refreshAccounts={refreshAccounts}
                  onRecordChange={onRecordChange}
                  recordsPerPage={recordsPerPage}
                  onTableChange={onTableChange}
                  gatherFormData={gatherFormData}
                  cutoffData={cutoffData}
                  pagination={tablePagination}
                  buttonLoading={buttonLoading}
                  fetchingCutOffData={fetchingCutOffData}
                  setIsApproved={setIsApproved}
                  setIsSearching={setIsSearching}
                  setFormData={setFormData}
                  formData={formData}
                  debouncedCutOffData={debouncedCutOffData}
                  searchLoading={searchLoading}
                  orgData={orgData}
                  setButtonLoading={setButtonLoading} 
                  currentPage={
                    pagination.current ? pagination.current : 1
                  }
                  ObtainedSelectedData={ObtainedSelectedData}
                  setUniqueCustomerOrderIds={setUniqueCustomerOrderIds}
                  uniqueCustomerOrderIds={uniqueCustomerOrderIds}
                  isSearching={isSearching}
                  defaultFormData={defaultFormData}
                  warehouseFilter={warehouseFilter}
                />
              </Col>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};
