/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  FormItem,
  Input,
} from '../../common/UIComponents'
import {
  isEmpty,
} from '../../common/Common'
import I18n from '../../common/I18n'
import AddressForm from '../common/AddressForm'
import MobileInput from '../common/MobileInput'
import { checkServiceExistance } from '../../helpers/common'
import AddressSearch from '../../stories/components/AddressSearch';

const ConsigneeDetailsForm = (props) => {
    const {
    formData = {},
    handleOnAddressChange,
    handleOnChange,
    map,
    editableFields = [],
    includePredefinedStops = false,
    predefined_locations,
    handleMultipleFieldsChange,
    request_from
  } = props

  const onConsigneeLocationChange = (value) => {
    const { first_name, last_name, name, email, phone, phone_number_one ,phone_number_two, company_name, l_address, id, l_type , type } = value
    if (type === 'predefined') {
    handleMultipleFieldsChange({
      first_name: !isEmpty(first_name) ? first_name: (!isEmpty(name) ? name : ''),
      last_name,
      email: email,
      phone_number_one: !isEmpty(phone_number_one) ? phone_number_one: (!isEmpty(phone) ? phone : ''),
      phone_number_two,
      company_name,
      l_address,
      id,
      l_type
    });
    } else {
      handleOnAddressChange('', '', l_address);
    }
  }

  const renderForm = () => (
    <Fragment>
      {
      checkServiceExistance('PDSI') && 
      editableFields.includes('l_address') && 
      includePredefinedStops === true && 
      window.google && map &&  
      String(predefined_locations).toLowerCase() === "true" && (
        <Row gutter={16}>
          <Col sm={24} xs={24} md={24} lg={24}>
            <FormItem
              label={`${I18n.t('general.location')} ${I18n.t(
                'general.search',
              )}`}
            >
                <AddressSearch onSelect={ (address) => {
                  onConsigneeLocationChange(address)
                } } />
              <AddressForm
                autoComplete={editableFields.includes('l_address')}
                includePredefinedStops={true}
                request_from={request_from}
                address={
                  formData?.l_address
                    ? formData.l_address
                    : {}
                }
                onChange={handleOnAddressChange}
                onConsigneeLocationChange={onConsigneeLocationChange}
                isAdmin
                disabled={!editableFields.includes('l_address')}
              />
            </FormItem>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col sm={24} xs={24} md={24} lg={24} style={{paddingLeft:0, paddingRight:0}}>
          <Col sm={24} xs={24} md={12} lg={8}>
            <FormItem label={I18n.t("general.company_name")}>
              <Input
                value={formData.company_name}
                onChange={(e) =>
                  handleOnChange("company_name", e.target.value)
                }
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={8} span={4}>
            <FormItem label={I18n.t('general.first_name')} >
              <Input
                value={formData.first_name}
                onChange={(e) =>
                  handleOnChange('first_name', e.target.value)
                }
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={8}>
            <FormItem label={I18n.t('general.last_name')}>
              <Input
                value={formData.last_name}
                onChange={(e) =>
                  handleOnChange('last_name', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Col>

        <Col sm={24} xs={24} md={24} lg={24} style={{paddingLeft:0, paddingRight:0}}>
          <Col sm={24} xs={24} md={12} lg={8}>
            <FormItem label={I18n.t('general.email')}>
              <Input
                value={formData.email}
                onChange={(e) =>
                  handleOnChange('email', e.target.value)
                }
                disabled={!editableFields.includes('email')}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={8}>
            <FormItem label={I18n.t('order.contact_number_one')} >
              <MobileInput
                handleInputChange={(value, data) => {
                  handleOnChange('phone_number_one', value, data)
                }}
                value={
                  formData.phone_number_one ? formData.phone_number_one : ''
                }
                disabled={!editableFields.includes('phone_number_one')}
              />
            </FormItem>
          </Col>
          <Col sm={24} xs={24} md={12} lg={8}>
            <FormItem label={I18n.t('order.contact_number_two')}>
              <MobileInput
                handleInputChange={(value, data) => {
                  handleOnChange('phone_number_two', value, data)
                }}
                value={
                  formData.phone_number_two ? formData.phone_number_two : ''
                }
                disabled={!editableFields.includes('phone_number_two')}
              />
            </FormItem>
          </Col>
        </Col>
      </Row>
      {editableFields.includes('l_address') && window.google && map && (
        <Row>
          <Col xs={24}>
            {/* {editableFields.includes('l_address') && (
              <span className="textBold">
                <sup className="textRed">*</sup>&nbsp;
                {`${I18n.t('general.address')} ${I18n.t('general.search')}`}:
              </span>
            )} */}
            <AddressForm
              autoComplete={editableFields.includes('l_address')}
              includePredefinedStops={false}
              address={
                formData?.l_address
                  ? formData.l_address
                  : {}
              }
              onChange={handleOnAddressChange}
              onConsigneeLocationChange={onConsigneeLocationChange}
              isAdmin
              disabled={!editableFields.includes('l_address')}
              twoRows={true}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  )
  return <Fragment>{renderForm()}</Fragment>
}
export default ConsigneeDetailsForm

ConsigneeDetailsForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  handleOnAddressChange: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  predefined_locations : PropTypes.string,
  includePredefinedStops: PropTypes.bool,
  editableFields: PropTypes.array,
  onConsigneeLocationChange: PropTypes.func,
  map: PropTypes.object,
}

ConsigneeDetailsForm.defaultProps = {
  predefined_locations : "true"
}