import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Tooltip,
  Icon,
} from '../../common/UIComponents'
import { alertMessage, base64ToPdf,isEmpty } from '../../common/Common'
import { _ } from 'core-js'
import { QuotesApis } from '../../api/QuotesApi'
import { renderAlertMessage } from '../../helpers/common'

const DownloadQuote = ({ quote_id, quote_no, className, displayType, size }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const resetValues = (closeModal = false) => {
    setErrors([])
  }

  const downloadQuote = () => {
    setIsLoading(false)
    QuotesApis.downloadQuote(quote_id)
      .then((result) => {
        if (result.success) {
          base64ToPdf(result.quote_file, `Quote_${quote_no}.pdf`)
        } else {
          renderAlertMessage(result.errors)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
        <Tooltip title="Download Quote">
          { displayType === 'icon' ? <Icon
            onClick={() => downloadQuote(true)}
            type="download"
            className={className ? className : ''}
          />
          :
          <Button type="primary" size={!isEmpty(size) ? size : 'default'} icon='download' onClick={() => downloadQuote(true)}> Download</Button>
          }
        </Tooltip>
    </Fragment>
  )
}

DownloadQuote.propTypes = {
  quote_id: PropTypes.string.isRequired,
  quote_no: PropTypes.string.isRequired,
}

export default DownloadQuote
