/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from '../../common/UIComponents';
import {isEmpty, formatStopUTcTime } from '../../common/Common';
import I18n from '../../common/I18n';
import { findDelayTime, findResonObject } from '../../helpers/stops';
import { OrgContext } from '../../context/OrgContext';
import { secondsToHms } from '../../helpers/common';

const StopTimings = (props) => {
  const {
    stop, route, showTimes
  } = props;

  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [isMilitaryTime , setIsMilitaryTime] = useState(false);

  useEffect(() => {
    if (organizationSettings && organizationSettings.is_military_time ===
      "true") {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [ organizationSettings ]);

  const name = stop.contact_details && stop.contact_details.contact_name ? stop.contact_details.contact_name : stop.formatted_stop_name;
  const orderIds = stop.customer_order_ids || [];
  const stopErrors = stop.stop_errors || [];
  const stopType = stop.location && stop.location.l_type ? stop.location.l_type : '';
  const delayMins = 0; // findDelayTime(stop, route, 'nav_route_status', stop.stop_tz);
  const totalOrders = _.uniq(_.compact([].concat(stop.customer_order_ids, stop.deleted_order_ids)));
  const deletionReason = findResonObject(stop.deletion_reason, stop.id, 'stop');
  return (
    <Fragment key={`timeline_fragment${stop.id}`}>
      <Row>
        {
          stopType === 'CS' &&
            <Col xs={24} style={{ paddingTop: `${stop.status === 'PENDING' ? '5px' : '3px'}` }}>
              <div className="fontSize10 smallText alignLeft">
                <Tooltip title={I18n.t('order.scheduled_time')}>
                  {I18n.t('routes.scheduled_time')}: &nbsp;
                  {(!isEmpty(stop.scheduled_start_date_time_with_tz) || !isEmpty(stop.scheduled_end_date_time_with_tz)) ?
                    <Fragment>{formatStopUTcTime(stop.scheduled_start_date_time_with_tz, stop.scheduled_end_date_time_with_tz, stop.stop_tz_short_name, showTimes , isMilitaryTime)}</Fragment>
                    :
                    'Not available'
                  }
                </Tooltip>
              </div>
            </Col>
        }
        <Col xs={24} style={{ paddingTop: 3 }}>
          <div className="fontSize10 smallText alignLeft">
            <Tooltip title={I18n.t('order.estimated_time')}>
              {I18n.t('routes.estimated_time')}: &nbsp;
              {(!isEmpty(stop.estimated_arrival_date_time_with_tz) || !isEmpty(stop.estimated_departure_date_time_with_tz)) ?
                <Fragment>{formatStopUTcTime(stop.estimated_arrival_date_time_with_tz, stop.estimated_departure_date_time_with_tz, stop.stop_tz_short_name, showTimes , isMilitaryTime)}</Fragment>
                :
                'Not available'
              }
            </Tooltip>
            { ["PENDING", "UNALLOCATED"].includes(stop.status) && delayMins > 0 && <span className="textRed textBold fontsize11 floatRight"><Tooltip title={secondsToHms(delayMins)}>Late</Tooltip></span>}
          </div>
        </Col>
        { !["PENDING", "UNALLOCATED"].includes(stop.status) &&
          <Col xs={24} style={{ paddingTop: 1 }}>
            <div className="fontSize10 smallText alignLeft">
              <Tooltip title={I18n.t('order.arrived_time')}>
                {I18n.t('routes.actual_time')}: &nbsp;
                {(!isEmpty(stop.actual_start_date_time_with_tz) || !isEmpty(stop.actual_end_date_time_with_tz)) ?
                  <Fragment>{formatStopUTcTime(stop.actual_start_date_time_with_tz, stop.actual_end_date_time_with_tz, stop.stop_tz_short_name, showTimes , isMilitaryTime)}</Fragment>
                  :
                  'Not available'
                }
              </Tooltip>
              { delayMins > 0 && <span className="textRed textBold fontsize11 floatRight"><Tooltip title={secondsToHms(delayMins)}>Late</Tooltip></span>}
            </div>
          </Col>
        }
      </Row>
    </Fragment>);
};

export default StopTimings;

StopTimings.propTypes = {
  stop: PropTypes.shape().isRequired,
};

StopTimings.defaultProps = {
  currentStop: {},
};
