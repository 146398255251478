import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import {
  Col,
  Row,
  Search,
  Select,
  Spin,
  Tooltip,
  Dropdown,
  Icon,
  message,
  Button,
} from "../../../common/UIComponents";
import {
  alertMessage,
  checkNegitive,
  isEmpty,
  navigateToAccount,
  refreshIcon,
} from "../../../common/Common";
import AppConfig from "../../../config/AppConfig";
import { RecordsPerPage } from "../../orders/RecordsPerPage";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { WarehouseContext } from "../../../context/WarehouseContext";
import {
  convertToTags,
  limitString,
  removeSpecialCharacters,
  renderAlertMessage,
  selectedWarehouseNames,
} from "../../../helpers/common";
import { OrgContext } from "../../../context/OrgContext";
import I18n from "../../../common/I18n";
import AppliedFiltersTags from "../../../containers/AppliedFiltersTags";
import { UserContext } from "../../../context/UserContext";
import { ScreenKeys } from "../../../containers/constants";
import { fetchInvoiceStatusReport, fetchQbReport } from "../../../api/Reports";
import userStore from "../../../stores/UserStore";
import { fetchOrgAccounts } from "../../../api/Account";
import QbReportFilter from "./QbReportFilter";
import QbReportList from "./QbReportList";
import { getValueFromArrayOfObjects } from "../../../helpers/array_functions";

const defaultFilter = {
  sortBy: "none",
  sortByType: "descend",
  fromDate: moment().subtract(1, "day"),
  toDate: moment(),
  accountCodes: "",
  reportType: I18n.t("quickbook_report.discrepancy.with_discrepancy_key"),
  // warehouseIds: "",
};

class QbReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qbStatuses: [],
      pagination: {},
      reportLoading: false,
      recordsPerPage: props.recordsPerPage,
      filterWindow: false,
      filterPlaceHolder: _.cloneDeep(defaultFilter),
      appliedFiltersTags: [],
      accounts: [],
      searchBy: "Invoice",
      search_item_token: "",
      isItemTokenSearched: false,
      searchByToken: false,
      updateSearchTags: false,
      exportLoading: false,
      categoryType: "SUMMARY",
      feAmountTotal: "",
      qbAmountTotal: "",
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
    this.onTableChange = this.onTableChange.bind(this);
  }

  componentDidMount() {
    const { warehouseData } = this.props;

    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    this.setState(
      (prevState) => ({
        ...prevState,
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          // warehouseIds: currentWhId,
        },
      }),
      () => {
        this.getOrgAccounts();
        this.getQbReports();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.warehouseData.selectedWarehouses,
        this.props.warehouseData.selectedWarehouses
      ) ||
      (!_.isEqual(
        prevProps.warehouseData.warehouses,
        this.props.warehouseData.warehouses
      ) &&
        !this.props.warehouseData.isFetchingWarehouses)
    ) {
      this.tableOptions.pagination.current = 1;
      this.setFilteredAcc();
    }

    if (
      prevState.isItemTokenSearched !== this.state.isItemTokenSearched &&
      this.state.isItemTokenSearched
    ) {
      this.setState({ categoryType: "DETAILS" }, () => {
        this.tableOptions.pagination.current = 1;
        this.getQbReports();
      });
    }

    if (!_.isEqual(prevState.filterPlaceHolder, this.state.filterPlaceHolder)) {
      this.computeTags(this.state.filterPlaceHolder);
    }
  }

  getReportTypeLabel = (reportFilterBy) => {
    switch (reportFilterBy) {
      case `${I18n.t("quickbook_report.discrepancy.with_discrepancy_key")}`:
        return I18n.t("quickbook_report.discrepancy.WITH_DISCREPANCY_label");
      case `${I18n.t("quickbook_report.discrepancy.without_discrepancy_key")}`:
        return I18n.t("quickbook_report.discrepancy.WITHOUT_DISCREPANCY_label");
      case `${I18n.t("quickbook_report.discrepancy.both_key")}`:
        return I18n.t("general.both");
      default:
        return "";
    }
  };

  setFilteredAcc = () => {
    const { warehouseData } = this.props;
    const { accountCodes } = this.state.filterPlaceHolder;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const filteredAccounts = this.state.accounts?.filter((account) =>
      account.warehouse_ids.some((id) => id === currentWhId)
    );
    const filteredAccIds = filteredAccounts.map((ac_id) => ac_id.id);
    const isAccPresent = filteredAccIds.includes(accountCodes);

    this.setState(
      (prevState) => ({
        filterPlaceHolder: {
          ...prevState.filterPlaceHolder,
          accountCodes: isAccPresent
            ? prevState.filterPlaceHolder.accountCodes
            : "",
        },
      }),
      () => {
        this.getQbReports();
        this.computeTags(this.state.filterPlaceHolder);
      }
    );
  };

  getOrgAccounts = () => {
    this.setState({
      accountsLoading: true,
    });
    const { currentOrg = {} } = this.props.orgData;
    const orgId = currentOrg.id;
    fetchOrgAccounts(orgId).then((result) => {
      if (result.success) {
        const accounts = result.accounts || [];
        this.setState({
          accounts,
          accountsLoading: false,
        });
      } else {
        this.setState({
          accountsLoading: false,
          accounts: [],
        });
      }
    });
  };

  getQbReports = () => {
    const organization_id = userStore.getStateValue("selectedOrg");
    const { filterPlaceHolder, search_item_token } = this.state;
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    let payload = {
      organization_id,
      page: this.tableOptions.pagination.current || 1,
      per_page: this.state.recordsPerPage,
      view_by: this.state.categoryType,
      ...(isEmpty(search_item_token)
        ? {
            from_date: filterPlaceHolder?.fromDate ? filterPlaceHolder.fromDate.format('YYYY-MM-DD HH:mm:ss Z') : null,
            to_date: filterPlaceHolder?.toDate ? filterPlaceHolder.toDate.format('YYYY-MM-DD HH:mm:ss Z') : null,
            account_id: filterPlaceHolder.accountCodes,
            report_type: filterPlaceHolder.reportType,
          }
        : this.state.searchBy === "Invoice"
        ? { invoice_nos: _.trim(search_item_token) || "" }
        : { customer_order_number: _.trim(search_item_token) || "" }),
    };
    this.setState({ reportLoading: true });
    fetchQbReport(payload).then((result) => {
      if (result.success) {
        const qbStatuses = result.reports_data || [];
        const pagination = result.pagination || {};
        const qbTotal = _.sumBy(qbStatuses, (invoice) =>
          parseFloat(invoice.qb_amount)
        );
        const feTotal = _.sumBy(qbStatuses, (invoice) =>
          parseFloat(invoice.frequency_invoice_amount)
        );
        this.setState({
          qbStatuses,
          pagination,
          reportLoading: false,
          isItemTokenSearched: false,
          qbAmountTotal: checkNegitive(qbTotal),
          feAmountTotal: checkNegitive(feTotal),
        });
      } else {
        renderAlertMessage(result.errors);
        this.setState({
          reportLoading: false,
          qbStatuses: [],
          isItemTokenSearched: false,
          qbAmountTotal: 0,
          feAmountTotal: 0,
        });
      }
    });
  };
  computeTags = (filterPlaceHolder) => {
    const { warehouseData } = this.props;

    const keys = [
      "fromDate",
      "toDate",
      "warehouseIds",
      "accountCodes",
      "reportType",
    ];
    const filteredData = {
      fromDate: {
        label: "Approval Date",
        value:
          moment(filterPlaceHolder.fromDate).format("MMM DD, YYYY") +
          " - " +
          moment(filterPlaceHolder.toDate).format("MMM DD, YYYY"),
        onClose: () => {
          this.handleOnFilterRemoval("fromDate");
        },
        closeable:
          _.isEqual(
            filterPlaceHolder.fromDate.format("MMM DD, YYYY"),
            moment().subtract(1, "day").format("MMM DD, YYYY")
          ) &&
          _.isEqual(
            filterPlaceHolder.toDate.format("MMM DD, YYYY"),
            moment().format("MMM DD, YYYY")
          )
            ? false
            : true,
      },
      warehouseIds: {
        label: I18n.t("location.location"),
        value: selectedWarehouseNames(warehouseData),
        onClose: () => {
          this.props.warehouseFilter.onWarehouseChange("");
        },
        closeable: false,
      },
      accountCodes: {
        label: "Account",
        value: this.state.accounts.find(
          (account) => account.id === filterPlaceHolder.accountCodes
        )
          ? this.state.accounts.find(
              (account) => account.id === filterPlaceHolder.accountCodes
            ).code
          : "",
        onClose: () => {
          this.handleOnFilterRemoval("accountCodes");
        },
        closeable: true,
      },
      reportType: {
        label: "Report Type",
        value: this.getReportTypeLabel(filterPlaceHolder.reportType),
        onClose: () => {
          this.handleOnFilterRemoval("reportType");
        },
        closeable:
          filterPlaceHolder.reportType !==
          I18n.t("quickbook_report.discrepancy.with_discrepancy_key"),
      },
    };
    const tagsInfo = convertToTags(
      { ...filterPlaceHolder, warehouseIds: filteredData.warehouseIds },
      keys,
      filteredData
    );
    this.setState({ appliedFiltersTags: tagsInfo });
  };

  handleOnFilterRemoval = (key) => {
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;
    const filterVariable = { ...this.state.filterPlaceHolder };
    switch (key) {
      case "fromDate":
        filterVariable.fromDate = defaultFilter.fromDate;
        filterVariable.toDate = defaultFilter.toDate;
        break;
      case "toDate":
        filterVariable.toDate = defaultFilter.toDate;
        filterVariable.fromDate = defaultFilter.fromDate;
        break;
      case "accountCodes":
        filterVariable.accountCodes = defaultFilter.accountCodes;
        break;
      case "status":
        filterVariable.status = defaultFilter.status;
        break;
      case "reportType":
        filterVariable.reportType = defaultFilter.reportType;
        break;
      default:
        break;
    }
    this.computeTags(filterVariable);
    this.setState({ filterPlaceHolder: filterVariable }, () => {
      // Callback to ensure the state is updated before further actions
      this.handleSearch();
    });
  };

  handleSearch = (filter = {}) => {
    const Filter = Object.assign({}, this.state.filterPlaceHolder, filter);
    this.setState(
      {
        filterWindow: false,
        search_item_token: "",
        searchByToken: false,
        filterPlaceHolder: Filter,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getQbReports();
      }
    );
  };

  handleVisibleChange = (flag) => {
    this.setState({
      filterWindow: flag,
    });
  };

  onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      this.tableOptions.pagination && this.tableOptions.pagination.current
        ? this.tableOptions.pagination.current
        : 1;
    this.tableOptions = { pagination, filters, sorter };
    if (pagination.current !== currentPage) {
      this.getQbReports();
    } else if (sorter && !isEmpty(sorter.field)) {
      this.setState(
        {
          filterPlaceHolder: Object.assign({}, this.state.filterPlaceHolder, {
            sortBy: sorter.columnKey,
            sortByType: !isEmpty(sorter.order) ? sorter.order : "descend",
          }),
        },
        () => {
          this.tableOptions.pagination.current = 1;
          this.getQbReports();
        }
      );
    }
  };

  handleFilterPlaceHolderChange = (filter, cb = null) => {
    this.setState(
      {
        filterPlaceHolder: filter,
      },
      cb
    );
  };

  clearFilter = () => {
    const { warehouseData } = this.props;
    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const updatedFilterPlaceHolder = {
      ...defaultFilter,
      // warehouseIds: currentWhId,
    };

    this.setState(
      {
        filterPlaceHolder: updatedFilterPlaceHolder,
        search_item_token: "",
        searchByToken: false,
      },
      () => {
        this.computeTags(this.state.filterPlaceHolder);
        this.handleSearch();
      }
    );
  };

  onRecordChange = (value) => {
    const { updateRecordsPerPage } = this.props.userContext;
    if (!value) {
      value = this.props.recordsPerPage;
    }
    this.setState(
      {
        recordsPerPage: value,
      },
      () => {
        this.tableOptions.pagination.current = 1;
        this.getQbReports();
        updateRecordsPerPage(ScreenKeys.QB_REPORT, value);
      }
    );
  };

  _renderSearchBar = () => (
    <Dropdown
      onVisibleChange={this.handleVisibleChange}
      visible={this.state.filterWindow}
      trigger={["click"]}
      overlay={
        <div
          style={{
            backgroundColor: "white",
            width: 396,
            padding: 15,
            marginTop: 10,
            marginRight: -12,
          }}
          className="boxShadow"
        >
          <QbReportFilter
            filter={this.state.filterPlaceHolder}
            onChange={this.handleFilterPlaceHolderChange}
            onSearch={this.handleSearch}
            orgData={this.props.orgData}
            warehouseFilter={this.props.warehouseData}
            // disableButton={this.state.reportLoading}
            accounts={this.state.accounts}
          />
        </div>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          style={{
            color: "black",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: "5px",
          }}
          type="filter"
        />
      </div>
    </Dropdown>
  );

  handleOnChange = (element, value) => {
    this.setState({
      [element]: value,
    });
  };

  showDetailView = (rec = {}) => {
    const accId = !_.isEmpty(rec) ? rec.account_id : "";

    if (!isEmpty(accId)) {
      this.setState(
        (prevState) => ({
          categoryType: "DETAILS",
          search_item_token: "",
          searchByToken: false,
          filterPlaceHolder: {
            ...prevState.filterPlaceHolder,
            accountCodes: accId,
          },
        }),
        () => {
          this.tableOptions.pagination.current = 1;
          this.getQbReports();
          this.computeTags(this.state.filterPlaceHolder);
        }
      );
    }
  };

  _renderMainSearchBar = () => {
    const { searchBy } = this.state;
    return (
      <Search
        placeholder={
          searchBy === "Invoice" ? "Search Invoice No" : "Search Order No"
        }
        className="seachWithDropDown"
        // enterButton="Search"
        size="default"
        value={
          !isEmpty(this.state.search_item_token)
            ? this.state.search_item_token.toUpperCase()
            : ""
        }
        onSearch={(value) => {
          this.setState({
            search_item_token: value,
            isItemTokenSearched: true,
            searchByToken: value.length > 0 ? true : false,
            updateSearchTags: value.length > 0 ? true : false,
          });
        }}
        onChange={(e) => {
          const filteredString = removeSpecialCharacters(e.target.value);
          this.setState({ search_item_token: filteredString });
        }}
        style={{ width: "100%", textTransform: "uppercase" }}
        allowClear
        suffix={<Fragment>{this._renderSearchBar()}</Fragment>}
        prefix={
          <Row
            type="flex"
            align="middle"
            className="invoiceSearchPrefixes"
            gutter={4}
          >
            <Col>
              <Icon
                type="search"
                onClick={() =>
                  this.setState({
                    isItemTokenSearched: true,
                    searchByToken:
                      _.trim(this.state.search_item_token).length > 0
                        ? true
                        : false,
                  })
                }
              />
            </Col>
            {/* <Col>
              <Select
                size="small"
                onChange={(e) => {
                  this.handleOnChange("searchBy", e);
                  if (
                    !isEmpty(this.state.search_item_token) &&
                    this.state.searchByToken
                  ) {
                    this.setState({
                      isItemTokenSearched: true,
                    });
                  }
                }}
                value={this.state.searchBy}
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "1",
                }}
                defaultValue={searchBy === "Invoice" ? "Invoice" : "Order"}
              >
                <Select.Option key="Invoice" value="Invoice">
                  {I18n.t("general.by_invoice").toUpperCase()}
                </Select.Option>
                <Select.Option key="Order" value="Order">
                  {I18n.t("general.by_order").toUpperCase()}
                </Select.Option>
              </Select>
            </Col> */}
          </Row>
        }
      />
    );
  };

  _renderHeaderInfo = () => {
    return (
      <Row className="page-header">
        <Col xs={16}>{I18n.t("quickbook_report.title")}</Col>
        <Col xs={2}></Col>
        <Col xs={6} className="alignRight headerOptionDiv">
          {this._renderMainSearchBar()}
        </Col>
      </Row>
    );
  };

  _renderAppliedFilter = () => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={24} style={{ marginTop: 15 }}>
          <AppliedFiltersTags
            tags={this.state.appliedFiltersTags}
            clearFilter={() => this.clearFilter()}
            isSearching={this.state.searchByToken}
            searchText={this.state.search_item_token}
            searchBy={
              this.state.searchBy
                ? this.state.searchBy.charAt(0).toUpperCase() +
                  this.state.searchBy.slice(1)
                : ""
            }
            updateSearchTags={this.state.updateSearchTags}
            resetSearchTags={() => this.setState({ updateSearchTags: false })}
            screenFrom={"QbReport"}
          />
        </Col>
      </Row>
    );
  };

  _renderLists = () => {
    const pagination = {
      total: this.state.pagination.total_count,
      current: this.state.pagination.current_page,
      pageSize: this.state.pagination.per_page || this.state.recordsPerPage,
    };

    const { warehouseData } = this.props;

    const currentWhId =
      warehouseData?.selectedWarehouses?.length &&
      _.isArray(warehouseData.selectedWarehouses)
        ? warehouseData.selectedWarehouses[0]
        : warehouseData.selectedWarehouses;

    const timeZoneId = getValueFromArrayOfObjects(
      warehouseData.warehouses,
      "id",
      currentWhId,
      "timeZoneId"
    );

    const hasMismatchedAmounts =
      this.state?.qbStatuses?.length > 0
        ? this.state.qbStatuses.some((record) => {
            const qb_amount = record?.qb_amount
              ? checkNegitive(record.qb_amount)
              : 0.0;
            const fe_amount = record?.frequency_invoice_amount
              ? checkNegitive(record.frequency_invoice_amount)
              : 0.0;
            return !_.isEqual(qb_amount, fe_amount);
          })
        : false;

    switch (this.state.categoryType) {
      case "SUMMARY":
        return (
          <Fragment>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24} md={18} offset={3}>
                <QbReportList
                  data={this.state.qbStatuses}
                  scroll={{ y: "calc(100vh - 360px)", x: "max-content" }}
                  navigateToAccount={(account_id) =>
                    navigateToAccount(
                      this.state.accounts,
                      account_id,
                      this.props.history
                    )
                  }
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    this.onTableChange(pagination, filter, sorter, currentTable)
                  }
                  timeZoneId={timeZoneId}
                  viewBy={"SUMMARY"}
                  viewClick={(record) => this.showDetailView(record)}
                  footer={() =>
                    this.state?.qbStatuses.length > 0 && (
                      <Fragment>
                        <Row type="flex" className="fontSize12 textBold">
                          <Col
                            style={{ width: "50%" }}
                            className="alignRight paddingRight10"
                          >
                            Total
                          </Col>
                          <Col style={{ width: "22%" }} className="alignRight">
                            <div
                              className={hasMismatchedAmounts ? "textRed" : ""}
                            >
                              {this.state.feAmountTotal}
                            </div>
                          </Col>
                          <Col style={{ width: "12%" }} className="alignRight">
                            <div
                              className={hasMismatchedAmounts ? "textRed" : ""}
                            >
                              {this.state.qbAmountTotal}
                            </div>
                          </Col>
                          <Col
                            style={{ width: "16%" }}
                            className="alignRight"
                          ></Col>
                        </Row>
                      </Fragment>
                    )
                  }
                />
              </Col>
            </Row>
          </Fragment>
        );
      case "DETAILS":
        return (
          <Fragment>
            <Row style={{ marginTop: 30 }}>
              <Col>
                <QbReportList
                  data={this.state.qbStatuses}
                  scroll={{ y: "calc(100vh - 305px)", x: "max-content" }}
                  navigateToAccount={(account_id) =>
                    navigateToAccount(
                      this.state.accounts,
                      account_id,
                      this.props.history
                    )
                  }
                  pagination={pagination}
                  tableChange={(pagination, filter, sorter, currentTable) =>
                    this.onTableChange(pagination, filter, sorter, currentTable)
                  }
                  timeZoneId={timeZoneId}
                  viewBy={"DETAILS"}
                />
              </Col>
            </Row>
          </Fragment>
        );
      default:
        return <Fragment />;
    }
  };

  onCategoryChange = (element, value) => {
    this.setState({ [element]: value, qbStatuses: [] }, () => {
      this.tableOptions.pagination.current = 1;
      this.getQbReports();
    });
  };

  _renderListOperation = () => {
    const stopTypes = [
      { key: "SUMMARY", label: "Summary" },
      { key: "DETAILS", label: "Details" },
    ];

    return (
      <Fragment>
        <Row style={{ marginTop: 10 }}>
          <Col xs={10} className="alignLeft">
            <Row className="textBold">
              <sup style={{ color: "red" }}>*</sup>Note:{" "}
              <span className="notes_content">
                Manual changes in QB invoices will cause report deviations
                within the report
              </span>
            </Row>
          </Col>

          <Col xs={14}>
            <Row type="flex" justify="end">
              {/* <Col>
                <Button
                  className="cursorPointer"
                  disabled={
                    this.state.emailProgress ||
                    this.state.qbStatuses?.length === 0
                  }
                  type="primary"
                  size="small"
                  // loading={this.state.emailProgress}
                  // onClick={this.handleExport}
                  shape="round"
                >
                  {this.state.exportLoading ? (
                    "Exporting..."
                  ) : (
                    <span>
                      Export EXCEL <Icon type="file-excel" theme="filled" />
                    </span>
                  )}
                </Button>
              </Col> */}
              <Col style={{ marginLeft: 10 }}>
                <span className="text">{I18n.t("general.view_by")}:</span>
                &nbsp;&nbsp;
                <Select
                  value={this.state.categoryType}
                  showSearch
                  onChange={(e) => this.onCategoryChange("categoryType", e)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                  size="small"
                >
                  {stopTypes.map((rec) => (
                    <Select.Option key={rec.key} value={rec.key}>
                      {rec.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <RecordsPerPage
                  onChange={this.onRecordChange}
                  value={this.state.recordsPerPage}
                  defaultValue={AppConfig.ordersPerPage}
                />
              </Col>
              {/* <Col style={{ marginLeft: 10 }}>
                <Button
                  onClick={() => this.getQbReports()}
                  icon="reload"
                  size="small"
                  type="primary"
                >
                  Reload
                </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    return (
      <div className="content-outer">
        <div className="content">
          <Spin spinning={this.state.reportLoading} delay={1000}>
            {this._renderHeaderInfo()}
            {this._renderListOperation()}
            {this._renderAppliedFilter()}
            {this._renderLists()}
          </Spin>
        </div>
      </div>
    );
  }
}
export const QbReportsComponent = withRouter((props) => {
  const warehouseData = useContext(WarehouseContext);
  const orgData = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const perPageKey = ScreenKeys.QB_REPORT;
  const recordsPerPage = _.get(
    userContext,
    `currentUser.per_page_saved.${perPageKey}`,
    AppConfig.ordersPerPage
  );

  return (
    <QbReports
      warehouseData={warehouseData}
      orgData={orgData}
      userContext={userContext}
      recordsPerPage={recordsPerPage}
      {...props}
    />
  );
});

export default QbReportsComponent;
