import React, { Fragment, useState, useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  Spin,
} from '../../common/UIComponents'
import {
  alertMessage,
} from '../../common/Common'
import I18n from '../../common/I18n'
import { driverClaimsApi } from '../../api/DriverClaims'
import _ from "lodash";
import FormErrors from '../common/FormErrors'
import ReportForm from '../reports/deduction_reports/ReportForm'
import Remarks from './Remarks'
import { renderAlertMessage } from '../../helpers/common'

const DriverStandardDeductions = ({
  handleCancel,
  handleSuccess,
  className,
  currentDriver = {},
  deductions = [],
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [errors, setErrors] = useState([])
  const [driverClaims, setDriverClaims] = useState([])
  const [remarks, setRemarks] = useState([])

  const getReports = (isInitial) => {
    setIsProcessing(true)
    driverClaimsApi.fetch(currentDriver.id).then((result) => {
      if (result.success) {
        setIsProcessing(false)
        const claims = result.driver_claims || []
        const claimRemarks = result.remarks || []
        setDriverClaims(claims)
        updateRemarks(claimRemarks)
        if (isInitial) {
          showAddingForm(true)
        }
      } else {
        renderAlertMessage(result.errors)
        setIsProcessing(false)
        if (isInitial) {
          showAddingForm(true)
        }
      }
    })
  }

  // useEffect(() => {
  //   if(deductions.length > 0 && driverClaims.length === 0){
  //     addNewRow([...deductions]);
  //   }
  // }, [driverClaims])

  const updateRemarks = (records) => {
    // const cliamRemarks = records.map((rec, index) => ({
    //   id: index + 1,
    //   description: rec,
    // }))
    setRemarks(records)
  }

  useEffect(() => {
    setDriverClaims([])
    setErrors([])
    getReports(true)
  }, [])

  const showAddingForm = (value) => {
    setShowInfoWindow(value)
  }

  const resetValues = (closeModal = false) => {
    setDriverClaims([])
    setErrors([])
    setLoading(false)
    showAddingForm(false)
  }

  const addNewRow = (remainingDeductions = []) => {
    const firstRecord = remainingDeductions.length > 0 ?  remainingDeductions[0] : null;
    setDriverClaims([
      ...driverClaims,
      {
        additional_amount: '',
        remark: '',
        deduction_master_id: firstRecord ? firstRecord.id : '',
        claim_amount: '',
        isNew: true,
        deduction_name: firstRecord ? firstRecord.deduction_name : '',
        gl_code: firstRecord ? firstRecord.gl_code : '',
      },
    ])
  }

  const handleDeleteClick = (id, isNew = false) => {
    if (isNew) {
      const claims = driverClaims.filter((rec) => rec.isNew !== true);
      setDriverClaims(claims);
    } else {
      setLoading(true)
      driverClaimsApi.delete(id).then((result) => {
        if (result.success) {
          alertMessage(I18n.t('messages.deleted'))
          const claimRemarks = result.remarks ? result.remarks : []
          updateRemarks(claimRemarks)
          setLoading(false)
          const claims = driverClaims.filter((rec) => rec.id !== id);
          setDriverClaims(claims)
        } else {
          renderAlertMessage(result.errors)
          setLoading(false)
        }
      })
    }
  }

  const updateRecord = (rec, claimRemarks) => {
    const recIndex = _.findIndex(driverClaims, { id: rec['id'] })
    updateRemarks(claimRemarks)
    if (recIndex >= 0) {
      const records = [...driverClaims]
      records[recIndex] = { ...rec }
      setDriverClaims([...records])
    } else {
      const records = driverClaims.filter((rec) => rec.isNew !== true)
      setDriverClaims([...records, { ...rec }])
    }
  }

  const renderModalWindow = () => {
    const currentDeductions = driverClaims.map((rec) => rec.deduction_master_id)
    const remainingDeductions = deductions.filter(
      (rec) => !currentDeductions.includes(rec.id),
    )
    const hasNewRecords =
      driverClaims.filter((rec) => rec.isNew === true).length > 0
    return (
       <Spin spinning={loading}>
          {deductions.length > 0 ? (
          <Fragment>
            {/* {!hasNewRecords && remainingDeductions.length > 0 && (
              <div className="alignRight marginBottom20">
                <Button size="small" type="primary" onClick={() => addNewRow(remainingDeductions)}>
                  {I18n.t('general.add')}
                </Button>
              </div>
            )} */}

            {/* {driverClaims.length > 0 && ( */}
              <Row gutter={24} className="textBold">
                <Col md={4} className="paddingLeft5">
                  {I18n.t('deductions.name')}
                </Col>
                <Col md={4} className="paddingLeft5">
                  {I18n.t('deductions.capital_amount')}&nbsp;({I18n.t('general.price_symbol')})
                </Col>
                <Col md={4} className="paddingLeft5">
                  {I18n.t('deductions.balance_amount')}&nbsp;({I18n.t('general.price_symbol')})
                </Col>
                <Col md={10} className="paddingLeft5">
                  {I18n.t('deductions.remarks')}
                </Col>
              </Row>
            {/* )} */}
            {deductions.map((rec) => {
              // const filteredDeductions = deductions.filter(
              //   (record) =>
              //     record.id === rec.deduction_master_id ||
              //     !currentDeductions.includes(record.id),
              // )
              const driverClaimRec = _.find(driverClaims, {
                deduction_master_id: rec.id,
              });
              const record = driverClaimRec ? driverClaimRec : {
                additional_amount: "",
                remark: "",
                deduction_master_id: rec ? rec.id : "",
                claim_amount: "",
                isNew: true,
                deduction_name: rec ? rec.deduction_name : "",
                gl_code: rec ? rec.gl_code : "",
              };
              return (
                <ReportForm
                  key={rec.id}
                  currentRecordId={rec.id}
                  currentRecord={record}
                  currentDriver={currentDriver}
                  onClose={resetValues}
                  deductions={deductions}
                  deleteClick={handleDeleteClick}
                  updateRecord={(rec, remarks) => updateRecord(rec, remarks)}
                  setLoading={(status) => setLoading(status)}
                />
              );
            })}

            {FormErrors(errors)}
              <h3>{I18n.t('deductions.remarks')}</h3>
              <Remarks
                data={remarks}
                pagination={{ position: 'none' }}
                scroll={{ y: 400 }}
              />
          </Fragment>
        ) : (
          <div style={{ height: '100%'}} className="centerToBlock">
            <h2>{I18n.t('deductions.no_configuration_msg')}</h2>
          </div>
        )}
       </Spin>
    )
  }

  return (
    <div>
     {renderModalWindow()}
    </div>
  )
}

export default DriverStandardDeductions
