import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
} from '../../common/UIComponents'
import { alertMessage} from '../../common/Common'
import { _ } from 'core-js'
import BaseModal from '../BaseModal'
import FormErrors from '../common/FormErrors';
import DispatcherNotes from '../orders/DispatcherNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import I18n from '../../common/I18n'

const ShowDispatcherNotes = ({
  apiEndpoint,
  notesTitle,
  currentRecord,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [driverNotes, setDriverNotes] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  
  const getDriverNotes = async () => {
    try {
      setLoading(true)
      let response = await dispatcherNotesApi.fetch(currentRecord.id)
      if (response.success) {
        const result = response?.dispatch_notes ? response.dispatch_notes : []
        setDriverNotes(result);
        if(result?.errors && result.errors.length > 0) {
          setErrors(result.errors)
        } else{
          setErrors([])
        }
        setShowInfoWindow(true)
      } else {
        alertMessage(I18n.t('messages.not_found', { field: 'Notes'}), 'error' , 5);
        setDriverNotes([]);
      }
    } catch (error) {
      setDriverNotes([]);
      alertMessage(error.message, 'error', 10)
    } finally {
      setLoading(false)
    }
  }

  const resetValues = () => {
    setShowInfoWindow(false);
    setDriverNotes([])
  }

  const renderModalWindow = () => {
    return (
      <BaseModal
        title={I18n.t('order.driver_notes')}
        className="customer-orders-modal"
        onCancel={() => {
          resetValues()
        }}
        width="80%"
        style={{ top: 30 }}
      >
        <DispatcherNotes
            organization_id={currentRecord.organization_id}
            currentSourceRecord={currentRecord}
            customer_order_notes={driverNotes}
            key={'customer_order_notes'}
            refer_type="release"
            apiEndpoint={apiEndpoint}
            noteTitle={notesTitle}
            show_actions={false}
            show_title={false}
        />
        {FormErrors(errors)}
      </BaseModal>
    )
  }

  return (
    <Fragment>
      <Button
        onClick={() => getDriverNotes(true)}
        icon="message"
        loading={loading}
        show_actions={false}
        size="small"
        type="default"
        shape='circle'
      />
      {showInfoWindow && renderModalWindow()}
    </Fragment>
  )
}

ShowDispatcherNotes.propTypes = {
  apiEndpoint: PropTypes.object.isRequired,
  currentRecord: PropTypes.shape.isRequired,
  notesTitle: PropTypes.string.isRequired,

}

ShowDispatcherNotes.defaultProps = {
  apiEndpoint: {},
  notesTitle: "Notes",
  currentRecord: {},
};
export default ShowDispatcherNotes
