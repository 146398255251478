/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import _ from "lodash";
import React, { useContext }  from "react";
import PropTypes from "prop-types";
import equal from "fast-deep-equal";
import MainMap from "./DynaRoute";
import PreplanMainMap from './PreplanDynamicRoute';
import AppConfig from "../../config/AppConfig";
import { isEmpty } from "../../common/Common";
import { getAddressLatLng } from "../../helpers/stops";
import { withRouter } from "react-router";
import { OrgContext } from "../../context/OrgContext";

// const processOrders = (orderData) => {
//   return orderData.reduce((acc, order) => {
//     if (order.locations && order.locations.length > 0) {
//       order.locations.forEach((location) => {
//         if (
//           location.l_address &&
//           location.l_address.coordinates &&
//           location.l_address.coordinates.length > 1
//         ) {
//           const newOrder = {
//             ...order,
//             lat: location.l_address.coordinates[1],
//             lng: location.l_address.coordinates[0],
//           };
//           if (
//             ["T", "LH"].includes(order.type_of_order) &&
//             (location.type_of_loc === 'PICKUP' || location.type_of_loc === 'DELIVERY')
//           ) {
//             newOrder.type_of_order = location.type_of_loc === 'PICKUP' ? 'R' : 'D';
//           }
//           acc.push(newOrder);
//         }
//       });
//     }
//     return acc;
//   }, []);
// };


const ORDER_TYPES = [ "T", "LH" ];
const LOCATION_TYPES = [ 'PICKUP', 'DELIVERY' ];

const processOrders = (orderData) => {
  return orderData.flatMap(order => {
    if (!order.locations || order.locations.length === 0) {
      return [];
    }

    return order.locations.flatMap(location => {
      const { l_address } = location || {};
      const { coordinates } = l_address || {};

      if (!coordinates || coordinates.length < 2) {
        return [];
      }

      const newOrder = {
        ...order,
        lat: coordinates[ 1 ],
        lng: coordinates[ 0 ],
      };

      if (
        ORDER_TYPES.includes(order.type_of_order) &&
        LOCATION_TYPES.includes(location.type_of_loc)
      ) {
        newOrder.type_of_order = location.type_of_loc === 'PICKUP' ? 'R' : 'D';
      }

      return newOrder;
    });
  });
};

class Map extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      map: "",
      isOpen: [],
      centerLatLng: [],
      hideRoutes:false,
      doMount: true,
      isMapLoading: true,
      isCallCurrentStop: props.screen === "stops",
      routes: [],
      selectedStop: props.selectedStop || "",
      orders: [],
      driver: props.driver ? props.driver : "",
      isInitial: true,
      organizationSettings: {},
      isMilitaryTime: false,
    };

    this.onMapMounted = this.onMapMounted.bind(this);
  }

  updateRoutesFromProps () {
    if (!this.props.routeInfo) return;

    const cleanedRoutes = this.props.routeInfo.filter(route => route.stops.length > 0)
      .map(route => {
        const cleanedStops = route.stops.filter(({ location }) => {
          const { l_address } = location || {};
          const { coordinates } = l_address || {};
          return coordinates && coordinates.length > 1;
        });

        const stop_markers = cleanedStops.map(stop => ({
          lat: stop.location.l_address.coordinates[ 1 ],
          lng: stop.location.l_address.coordinates[ 0 ],
          location_type: stop.location.l_type,
          ...stop,
        }));

        return { ...route, stops: cleanedStops, stop_markers };
      });

    this.setState({ routes: cleanedRoutes });
  }

componentDidMount() {
    this.setState({ orders: processOrders(this.props.orders) });
    this.updateRoutesFromProps();
}

componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.routeInfo, this.props.routeInfo)) {
        // this.setState({ routes: this.props.routeInfo });
        this.updateRoutesFromProps();
    }
    
    if (!_.isEqual(prevProps.orders, this.props.orders)) {
        this.setState({ orders: processOrders(this.props.orders) });
    }
  if (!_.isEqual(prevProps.organizationSettings, this.props.organizationSettings)) {
    this.setState({
      organizationSettings: this.props.organizationSettings,
      isMilitaryTime: this.props.organizationSettings.is_military_time === 'true'
    });
  }

  if (
    !equal(prevProps.clickedRouteId, this.props.clickedRouteId) && !_.isEmpty(this.props.clickedRouteId)  
  ) {
    this.doSetBounds();
  }

}

  // componentDidMount(){
  //   this.setState({ organizationSettings: this.props.organizationSettings , isMilitaryTime : this.props.organizationSettings.is_military_time == 'true'})
  // }

  // componentDidUpdate (prevProps, prevState) {
  //   if (!_.isEqual(prevState.organizationSettings, this.props.organizationSettings)) {
  //     this.setState({ organizationSettings: this.props.organizationSettings, isMilitaryTime: this.props.organizationSettings.is_military_time == 'true' });
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   const isUpdateOrders = !equal(nextProps.orders, this.props.orders);
  //   const stateData = {};
  //   if (nextProps.routeInfo.length !== null) {
  //     if (nextProps.routeInfo.length) {
  //       const routes = nextProps.routeInfo.map((route, routeIndex) => ({
  //         stop_markers: route.stops.map((stop) => ({
  //           contact_name:
  //             stop.contact_details && stop.contact_details.contact_name
  //               ? stop.contact_details.contact_name
  //               : stop.formatted_name,
  //           lat: stop.location.l_address.coordinates[1],
  //           lng: stop.location.l_address.coordinates[0],
  //           location_type: stop.location.l_type,
  //           ...stop,
  //         })),
  //         ...route,
  //       }));
  //       // routes.originPoint=thi
  //       let location = [];
  //       location = nextProps.routeInfo;
  //       stateData.routes = routes;
  //       stateData.location = location;
  //       stateData.driver = nextProps.driver ? nextProps.driver : "";
  //       stateData.selectedStop = nextProps.selectedStop
  //         ? nextProps.selectedStop
  //         : "";
  //     }
  //     if (isUpdateOrders) {
  //       // stateData.orders = nextProps.orders
  //       //   .filter(
  //       //     (order) =>
  //       //       //!order.nav_route_id &&
  //       //       order.customer_address &&
  //       //       order.customer_address.coordinates &&
  //       //       order.customer_address.coordinates.length > 1
  //       //   )
  //       //   .map((order) =>
  //       //     Object.assign({}, order, {
  //       //       lat: order.customer_address.coordinates[1],
  //       //       lng: order.customer_address.coordinates[0],
  //       //     })
  //       //   );
  //       stateData.orders = processOrders(nextProps.orders);
  //     }

  //     this.setState(stateData, () => {
  //       this.doSetBounds();
  //       if (nextProps.selectedStop || nextProps.selectedMarkerId) {
  //         const isOpen = [];
  //         if (nextProps.selectedStop) {
  //           isOpen[nextProps.selectedStop] = true;
  //         } else {
  //           isOpen[nextProps.selectedMarkerId] = true;
  //         }
  //         this.setState({
  //           isOpen,
  //         });
  //       } else {
  //         this.setState({
  //           isOpen: [],
  //         });
  //       }
  //     });
  //   }
  // }

  onToggleOpen = (e, i, id, type, route) => {
    let isOpen = [];
    if (e.domEvent) {
      if (["click", "mouseover"].includes(e.domEvent.type)) {
        isOpen[id] = true;
        if (type === "stop") {
          this.props.sendMarkerId(id, route, e.domEvent.type);
        }
      } else if (e.domEvent.type === "mouseout") {
        this.props.sendMarkerId("");
      }
      this.setState({
        isOpen,
      });
      // else if(e.domEvent.type  === 'mouseout'){
      //   setTimeout(() => {
      //    //  isOpen = [];
      //     this.setState({
      //       isOpen: [],
      //     });
      //   }, 5000)
      //   if(type === 'stop'){
      //    this.props.sendMarkerId('');
      //   }
      // }
    }
    // isOpen[id] = !this.state.isOpen[id];
    // if (this.state.selectedStop || type === 'stop') {
    //   isOpen[id] = true;
    // }
  };

  onMapMount = (map) => {
    if (map) {
      this.map = map;
    }
    if (this.state.map === "" && map) {
      this.setState({ map }, () => {
        this.doSetBounds();
      });
    }
  };

  onMapMounted(mapObj) {
    if (window.google && !this.state.map && this.state.doMount) {
      const map = this.state.map ? this.state.map : mapObj;
      const bounds = new window.google.maps.LatLngBounds();
      this.state.routes.forEach((routes) => {
        routes.stop_markers.forEach((stopMarker) => {
          bounds.extend(
            new window.google.maps.LatLng(stopMarker.lat, stopMarker.lng)
          );
        });
      });
      this.state.orders.forEach((order) => {
        if (order.lat && order.lng) {
          bounds.extend(new window.google.maps.LatLng(order.lat, order.lng));
        }
      });
      if (
        this.state.driver &&
        this.state.driver.latitude &&
        this.state.driver.longitude
      ) {
        bounds.extend(
          new window.google.maps.LatLng(
            this.state.driver.latitude,
            this.state.driver.longitude
          )
        );
      }
      map.fitBounds(bounds);
      this.setState({
        map,
      });
    }
  }

  updateIsInitial = (isInitial) => {
    if (isInitial === false) {
      this.setState({
        isInitial,
      });
    }
  };

  setRouteBounds = (bounds, setSelected = false) => {
    let { isInitial, routes } = this.state;
    let navRoutes = [...routes];
    if (setSelected && !isEmpty(this.props.clickedRouteId)) {
      navRoutes = routes.filter(
        (route) => route.route_id === this.props.clickedRouteId
      );
    }
    navRoutes.forEach((route) => {
      route.stop_markers.forEach((stopMarker) => {
        isInitial = false;
        bounds.extend(
          new window.google.maps.LatLng(stopMarker.lat, stopMarker.lng)
        );
      });
    });
    this.updateIsInitial(isInitial);
    return bounds;
  };

  setOrderBounds = (bounds) => {
    let { isInitial } = this.state;
    this.state.orders
      .filter(
        (order) =>
          order.customer_address &&
          order.customer_address.coordinates &&
          order.customer_address.coordinates.length > 1
      )
      .forEach((order) => {
        isInitial = false;
        bounds.extend(
          new window.google.maps.LatLng(
            order.customer_address.coordinates[1],
            order.customer_address.coordinates[0]
          )
        );
      });
    this.updateIsInitial(isInitial);
    return bounds;
  };

  doSetBounds = () => {
    // if (window.google && this.state.map && (!this.props.selectedOrderKeys || this.props.selectedOrderKeys.length === 0) && this.props.routeMouseOperation === false && isInitial) {
    const isRouteClicked = !isEmpty(this.props.clickedRouteId);
    if (
      window.google &&
      this.state.map &&
      (!this.props.selectedOrderKeys ||
        this.props.selectedOrderKeys.length === 0) &&
      (this.state.isInitial ||
        (!this.state.isInitial &&
          (isRouteClicked ||
            (this.props.routeMouseOperation === false &&
              this.props.showOnMap))))
    ) {
      this.setState({ inProgress: true, isMapLoading: true }, () => {
        let bounds = new window.google.maps.LatLngBounds();
        // if(!this.props.showOnMap && isRouteClicked && this.props.warehouseAddr){
        if (
          !this.state.isInitial &&
          isRouteClicked &&
          this.props.warehouseAddr &&
          !isEmpty(this.props.clickedRouteId)
        ) {
          bounds = this.setRouteBounds(bounds, true);
          this.state.map.fitBounds(bounds);
          this.center = bounds.getCenter();
          this.setState({
            centerLatLng: {
              latitude: this.center.lat(),
              longitude: this.center.lng(),
            },
          });
          // for not zooming
          //  const latLng = getAddressLatLng(
          //     this.props.warehouseAddr,
          //     "latLngLiteral"
          //   );
          //   const zoomLevel = this.state.zoomLevel;
          //   this.state.map.panTo(latLng);

          // bounds.extend(latLng);
          // this.center = latLng;

          // this.state.map.fitBounds(bounds);
          // this.center = bounds.getCenter();

          // this.setState({
          //   centerLatLng: { latitude: this.center.lat(), longitude: this.center.lng() },
          // },
          // ()=>{
          //   this.setZoom(zoomLevel);
          // });
        } else if (this.state.isInitial) {
          if (this.state.isInitial || this.props.showOnMap === false) {
            bounds = this.setOrderBounds(bounds);
          }
          bounds = this.setRouteBounds(bounds);
          if (
            this.state.driver &&
            this.state.driver.latitude &&
            this.state.driver.longitude
          ) {
            bounds.extend(
              new window.google.maps.LatLng(
                this.state.driver.latitude,
                this.state.driver.longitude
              )
            );
          }
          this.state.map.fitBounds(bounds);
          this.center = bounds.getCenter();
          this.setState({
            centerLatLng: {
              latitude: this.center.lat(),
              longitude: this.center.lng(),
            },
          });
        }
      });
    }
  };

  onMarkerClustererClick = (markerClusterer) => {
    const clickedMarkers = markerClusterer.getMarkers();
    // eslint-disable-next-line no-console
  };

  getCleanedLocations = (arrLocations) => {
    const arr1 = arrLocations.slice(1);
    arr1.pop();
    const data = [];
    for (let i = 0; i < arr1.length; i += 1) {
      data.push({
        lat: arr1[i].location.l_address.coordinates[1],
        lng: arr1[i].location.l_address.coordinates[0],
      });
    }
    return data;
  };

  handleZoomChanged = () => {
    this.setZoom();
  };

  setZoom = (newLevel) => {
    if (this.map) {
      this.setState({
        zoomLevel: !isEmpty(newLevel) ? newLevel : this.map.getZoom(),
      });
    }
  };

  setRouteVisibility = (visible) => {
    this.setState({ hideRoutes: visible });
  }
  render() {
    const {isMilitaryTime} = this.state
    const { showBetaFeature } = this.props
    return (
        showBetaFeature 
        ?
        <PreplanMainMap
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "100%", width: "100%" }} />}
        containerElement={<div style={{ height: "100%", width: "100%" }} />}
        mapElement={<div style={{ height: "100%", width: "100%" }} />}
        centerObjValue={this.center}
        onMapMounted={this.onMapMount}
        centerLatLng={this.state.centerLatLng}
        routes={!this.state.hideRoutes ? this.state.routes: []}
        routesCopy={this.state.routes}
        isOpen={this.state.isOpen}
        orderInfo={this.state.orders}
        selectedOrderKeys={this.props.selectedOrderKeys}
        onToggleOpen={this.onToggleOpen}
        onZoomChanged={this.handleZoomChanged}
        showPreplnControl
        isMilitaryTime={isMilitaryTime}
        hideRoutes={this.state.hideRoutes}
        setRouteVisibility={this.setRouteVisibility}
        setClusteredOrder={this.props.setClusteredOrder}
        // hideRoutes={this.props.hideRoutes}
        // zoom={this.state.zoomLevel}
        // origin={this.originPoint()}
        // destination={this.destinationPoint()}
        // getCleanedLocations={this.getCleanedLocations()}
        {...this.props}

      />
      :
      <MainMap
        // eslint-disable-next-line max-len
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "100%", width: "100%" }} />}
        containerElement={<div style={{ height: "100%", width: "100%" }} />}
        mapElement={<div style={{ height: "100%", width: "100%" }} />}
        centerObjValue={this.center}
        onMapMounted={this.onMapMount}
        centerLatLng={this.state.centerLatLng}
        routes={!this.state.hideRoutes ? this.state.routes: []}
        routesCopy={this.state.routes}
        isOpen={this.state.isOpen}
        orderInfo={this.state.orders}
        selectedOrderKeys={this.props.selectedOrderKeys}
        onToggleOpen={this.onToggleOpen}
        onZoomChanged={this.handleZoomChanged}
        showPreplnControl
        isMilitaryTime={isMilitaryTime}
        hideRoutes={this.state.hideRoutes}
        setRouteVisibility={this.setRouteVisibility}
        setClusteredOrder={this.props.setClusteredOrder}
        // hideRoutes={this.props.hideRoutes}
        // zoom={this.state.zoomLevel}
        // origin={this.originPoint()}
        // destination={this.destinationPoint()}
        // getCleanedLocations={this.getCleanedLocations()}
        {...this.props}

      />

    );
  }
}

Map.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()),
  routeInfo: PropTypes.arrayOf(PropTypes.shape()),
  selectedStop: PropTypes.string,
  selectedOrderKeys: PropTypes.arrayOf(PropTypes.string),
};
Map.defaultProps = {
  orders: [],
  routeInfo: [],
  selectedStop: "",
  selectedOrderKeys: [],
};



export const PreplanMap = withRouter((props) => {
  const { orgSettings : organizationSettings, isAirCargo  }= useContext(OrgContext);
  const isAirCargoOrg = isAirCargo()
  return (
    <Map
      organizationSettings={ organizationSettings }
      isAirCargo={isAirCargoOrg}
      { ...props }
    />
  );
});

export default PreplanMap;