/* eslint-disable no-nested-ternary */
import React from 'react';
import BaseList from '../BaseList';
import {  isEmpty } from '../../common/Common';
import I18n from '../../common/I18n';
import { Tooltip,Icon, Popover, Row, Col, Popconfirm } from '../../common/UIComponents';
import AppConfig from '../../config/AppConfig';
import DeleteIcon from '../common/DeleteIcon';

class StopsList extends BaseList {
  constructor() {
    super();
    this.columns = [
      {
        key: 'stop_number',
        title: '#',
        width: 30,
        render: (data, record, index) => (
          <div>
            {
              record.status === 'COMPLETED' ? <Icon type="check-circle" className="textCompleted textBold" /> :
                (['ARRIVED', 'DISPATCHED', 'INCOMPLETE', 'EXCEPTION'].includes(record.status) && AppConfig.orderStatusColors[record.status] ? <span className={`textBold ${AppConfig.orderStatusColors[record.status].className}`}>{index + 1}</span> : (index + 1))
            }
          </div>
        ),
      },
      {
        key: 'formatted_stop_name',
        title: I18n.t('truck.name'),
        ellipsis: true,
        render: data => (
          <div>
            <Tooltip title={data.formatted_stop_name}>
              <u className='text-decoration-dashed'>{data.contact_details && data.contact_details.contact_name ? data.contact_details.contact_name : data.formatted_stop_name}</u>
            </Tooltip>
          </div>
        ),
      },
      {
        key: 'orders',
        title: I18n.t('menu.orders'),
        width: 50,
        align: 'center',
        render: data => (
          <div>
            {data.customer_order_ids.length}
          </div>
        ),
      },
      {
        key: 'exception_message',
        width: 35,
        align: 'right',
        render: (record) => {
          const orderIds = record.customer_order_ids || [];
          return (<div>
            {record.stop_errors && record.stop_errors.length > 0 &&
            <Popover
              title="Exception"
              content={
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} className="textRed">
                    {record.stop_errors.map(error => <Row><Col>{error}</Col></Row>)}
                    {!isEmpty(record.exception_comments) ? <Row><Col>({record.exception_comments} )</Col></Row> : ''}
                  </Col>
                </Row>
              }
            >
              <Icon type="warning" className="textRed" />&nbsp;&nbsp;
            </Popover> }
            {
              record.status !== 'COMPLETED' &&
              <Popconfirm
                placement="topRight"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => this.props.deleteClick(orderIds)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteIcon/>
                &nbsp;&nbsp;&nbsp;
              </Popconfirm>
            }
          </div>);
        },
      },
    ];
  }
}

export default StopsList;
