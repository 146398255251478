import React, { useEffect, useState } from "react";
import WrappedRemittanceCreateForm from "./RemittanceCreateForm";
import RemittanceIndexList from "./RemittanceIndexList";
import { Alert, Col, Icon, Row, Spin } from "antd";
import I18n from "../../common/I18n";
import { withRouter } from "react-router-dom";
import { BatchManagementApi } from "../../api/BatchManagement";
import { alertMessage, isEmpty } from "../../common/Common";
import AppConfig from "../../config/AppConfig";
import { renderAlertMessage } from "../../helpers/common";

let tableOptions = { pagination: { current: 1 }, filters: {}, sorter: {} };
const RemittanceManage = (props) => {
  const defaultFilter = {
    sortBy: "none",
    sortByType: "descend",
  };
  const [remittancesData, setRemittancesData] = useState([]);
  const [headerDetails, setHeaderDetails] = useState([]);
  const [loadScreen, setLoadScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remittanceId, setRemittanceId] = useState(null);
  const [dialogVisible, setDialogVisible] = useState("");
  const [pagination, setPagination] = useState({ current_page: 1 });
  const [filterPlaceHolder, setFilterPlaceHolder] = useState({
    ...defaultFilter,
  });
  const [ refreshRemittenceList, setRefreshRemittenceList ] = useState(false)

  useEffect(() => {
    getArBatchCheckDetails();
  }, []);

  const checkId = props.match.params.checkId;
  const batchId = props.match.params.batchId;

  const handleRemittanceDetails = (data) => {
    setRemittancesData(data);
  };

  const handleLoading = (value) => {
    setLoadScreen(value);
  };

  const handleDialogVisible = (data) => {
    setDialogVisible(data);
  };

  const getArBatchCheckDetails = () => {
    setLoading(true);
    BatchManagementApi.fetchBatchCheckDetails(batchId).then((result) => {
      if (result.success) {
        const matchingCheck = result.data.ar_batch_checks.find(
          (check) => check.id === checkId
        );
        if (matchingCheck) {
          setHeaderDetails(matchingCheck);
        } else {
          alertMessage("No matching check found", "error", 10);
        }
        setLoading(false);
      } else {
        setLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const handleGoBack = () => {
    const fromPath = localStorage.getItem("lastPath")
    if(fromPath.includes("manage_batch")){
      props.history.push({ pathname: `/manage_batch/${batchId}` });
      localStorage.setItem("lastPath", `/remittances`)
    }else{
      props.history.push({ pathname: `/manage_checks` });
      localStorage.setItem("lastPath", `/remittances`)
    }
  };

  const handleEdit = (id) => {
    setRemittanceId(id);
  };
 
  const deleteRemittance = (remittance_id) => {
    setLoading(true);
    BatchManagementApi.deleteRemittance(remittance_id).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success", 10);
        const newRemittanceData = remittancesData.filter(
          (item) => item.id !== remittance_id
        );
        setRemittancesData(newRemittanceData);
        setLoading(false);
      } else {
        setLoading(false);
        renderAlertMessage(result.errors)
      }
    });
  };

  const tablePagination = {
    total: pagination.total_count,
    current: pagination.current_page,
    pageSize: pagination.per_page || AppConfig.perPage,
  }

  const onTableChange = (pagination, filters, sorter, currentTable) => {
    const currentPage =
      tableOptions.pagination && tableOptions.pagination.current
        ? tableOptions.pagination.current
        : 1
    tableOptions = { pagination, filters, sorter }

    if(tableOptions.pagination.current !== currentPage){
      setRefreshRemittenceList(!refreshRemittenceList)
    }
    else if (sorter && !isEmpty(sorter.field)) {
      tableOptions.pagination.current = 1;
      const filter = Object.assign({}, filterPlaceHolder, {
        sortBy: sorter.columnKey,
        sortByType: !isEmpty(sorter.order) ? sorter.order : "ascend",
      });
      setFilterPlaceHolder(filter);
    }
  }

  return (
    <div className="content-outer">
      <div className="content">
        <Row className="page-header">
          <Col xs={12}>
            <span onClick={handleGoBack}>
              <Icon type="arrow-left" style={{ cursor: "pointer" }} />
            </span>
            &nbsp;&nbsp;
            {I18n.t("batch_management.remittances")} (
            {headerDetails.check_ach_no})
          </Col>
        </Row>
        <Row>
          <Col>
            <WrappedRemittanceCreateForm
              handleRemittanceDetails={handleRemittanceDetails}
              remittanceId={remittanceId}
              handleDialogVisible={handleDialogVisible}
              handleLoading={handleLoading}
              remittancesData={remittancesData || []}
              filterPlaceHolder={filterPlaceHolder}
              setPagination={setPagination}
              page={
                tableOptions.pagination?.current &&
                !isEmpty(tableOptions.pagination.current)
                  ? tableOptions.pagination.current
                  : 1
              }
              perPage={AppConfig.perPage}
              refreshRemittenceList={refreshRemittenceList}
              setRefreshRemittenceList={setRefreshRemittenceList}
            />
          </Col>
        </Row>
        {dialogVisible && (
          <div style={{ marginTop: "10px" }}>
            <Alert
              message={dialogVisible}
              type="error"
              showIcon
              icon={<Icon type="info-circle" theme="filled" />}
            />
          </div>
        )}
        <div style={{ marginTop: "15px" }}>
          <RemittanceIndexList
            data={remittancesData ? remittancesData : []}
            scroll={{ x: "max-content" }}
            handleEdit={(id) => handleEdit(id)}
            handleDelete={(id) => deleteRemittance(id)}
            showEdit={true}
            isLoading={loadScreen}
            pagination={tablePagination}
            tableChange={(pagination, filter, sorter, currentTable) =>
              onTableChange(pagination, filter, sorter, currentTable)
            }
          />
        </div>{" "}
      </div>
    </div>
  );
};

export default withRouter(RemittanceManage);
