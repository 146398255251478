import React from "react";
import BaseList from "../BaseList";
import {
  Button,
  InputNumber,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  Icon,
} from "../../common/UIComponents";
import DeleteIcon from "../common/DeleteIcon";
import EndUserTitle from "../common/EndUserTitle";
import I18n from "../../common/I18n";
import OrderBillingDetails from "../billing/OrderBillingDetails";
import CopyableContent from "../common/CopyableContent";
import DisplayTime from "../common/DisplayTime";
import AppConfig from "../../config/AppConfig";
import { checkNegitive } from "../../common/Common";

class SummaryList extends BaseList {
  constructor(props) {
    super(props);
    const decimalPoints = window.localStorage.getItem("round_off_decimals");
    const columns = [
      {
        key: "warehouse_name",
        title: I18n.t("location.location"),
        dataIndex: "wh_name",
      },
      {
        key: "driver",
        title: I18n.t("general.Driver"),
        dataIndex: "driver_code",
      },
      {
        key: "order_no",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        render: (text, record) => (
          <div>
            {record.customer_order_id ? (
              <OrderBillingDetails record={{ ...record }} showActions={false} />
            ) : (
              <CopyableContent text={text} />
            )}
            {record?.type_of_order && record.type_of_order === "MANUAL" && (
              <Tooltip title={record.type_of_order}>
                <sup className="textRed textBold fontSize10">
                  &nbsp;&nbsp;(M)
                </sup>
              </Tooltip>
            )}
          </div>
        ),
        width: 140,
        ellipsis: true,
      },
      {
        key: "location_type",
        title: "Job Type",
        dataIndex: "location_type",
        render: (text, record) => (
          <div>
            {record.location_type === "RECOVERY" || record.location_type === "PICKUP" ? "P/U" :
              (record.location_type === "RELEASE" || record.location_type === "DELIVERY" ? "DEL" : record.location_type)
            }
          </div>
        ),
        width: 100,
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "hawb",
        render: (text, record) => (
          <div>{text ? <CopyableContent text={text} /> : ""}</div>
        ),
        width: 100,
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex: "mawb",
        render: (text, record) => (
          <div>{text ? <CopyableContent text={text} /> : ""}</div>
        ),
        width: 100,
      },
      {
        key: "account",
        title: I18n.t("account.account"),
        dataIndex: "account_code",
        className: "noWrap",
      },
      {
        key: "consignee",
        title: <EndUserTitle />,
        dataIndex: "consignee_name",
        className: "noWrap",
        width: 100,
      },
      {
        key: "company",
        title: I18n.t('general.company'),
        dataIndex: "integration_company_code",
        className: "noWrap",
        
      },
      {
        key: "service_date",
        title: I18n.t("reports.service_date"),
        dataIndex: "order_completion_date",
        className: "noWrap",
        width: 100,
        render: (text, record) => {
          return(
            text ? (
              <DisplayTime
                dateTimeString={text}
                timeFormat={AppConfig.dateFormat}
              />
            ) : (
              ""
            )
          );
        },
      },
      {
        key: "settlement_date",
        title: I18n.t("reports.settlement_date"),
        dataIndex: "settlement_date",
        className: "noWrap",
        width: 100,
        render: (text, record) => {
          return(
            text ? (
              <DisplayTime
                dateTimeString={text}
                timeFormat={AppConfig.dateFormat}
              />
            ) : (
              ""
            )
          );
        },
      },
      {
        key: "gross_revenue",
        title: (
          <div>
            {I18n.t("reports.gross_revenue")}
            <sup className="textRed">*</sup> ({I18n.t("general.price_symbol")})
          </div>
        ),
        dataIndex: "gross_revenue",
        render: (text, record, index) => (
            checkNegitive(text)
        ),
        width: 140,
        align: 'right'
      },
      {
        key: "gross_pay",
        title: (
          <div>
            {I18n.t("reports.gross_pay")}
            <sup className="textRed">*</sup> ({I18n.t("general.price_symbol")})
          </div>
        ),
        dataIndex: "gross_pay",
        render: (text, record, index) => (
          checkNegitive(text)
        ),
        width: 140,
        align: 'right'
      },
      //   {
      //     key: 'action',
      //     title: '',
      //     dataIndex: 'id',
      //     render: (data, record, index) => (
      //       <Row type="flex" gutter={8} align="middle" justify="end">
      //         { !this.props.hasNew && <Col> {/* record.type_of_order ==='MANUAL' && */}
      //           <Tooltip placement="topLeft" title="Delete">
      //             <Popconfirm
      //               placement="topRight"
      //               title={I18n.t('messages.delete_confirm')}
      //               onConfirm={() =>
      //                 this.props.deleteClick(record.id)
      //               }
      //               okText="Yes"
      //               cancelText="No"
      //             >
      //               <DeleteIcon styleObj={{ fontSize: 16 }}/>
      //               &nbsp;&nbsp;
      //             </Popconfirm>
      //           </Tooltip>
      //         </Col>
      //         }
      //         {false && !this.props.hasNew && index + 1 === this.props.data.length && (
      //           <Col className="line-actions fontsize11">
      //             <Tooltip placement="topLeft" title={I18n.t('order.add')}>
      //               <Icon
      //                 type="plus-circle"
      //                 theme="filled"
      //                 onClick={this.props.addOrderRow}
      //                 style={{ fontSize: 18 }}
      //               />
      //             </Tooltip>
      //           </Col>
      //         )}
      //       </Row>
      //     ),
      //     width: 65,
      //     align: 'left',
      //   },
    ];
    if (!props.isAirCargo()) {
      this.columns = columns.filter((col) => !["hawb"].includes(col.key));
    } else {
      this.columns = [...columns];
    }
  }
}

export default SummaryList;
