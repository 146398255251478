import React, { Component } from "react";
import { deleteContact, fetchContactDetails, fetchContacts } from "../../../api/Contacts";
import { alertMessage, isEmpty } from "../../../common/Common";
import { Button, Col, Row, Spin, Search } from "../../../common/UIComponents";
import AppConfig from "../../../config/AppConfig";
import BaseModal from "../../BaseModal";
import ContactList from "./ContactList";
import EventSubscriptionForm from "./EventSubscriptionForm";
import Form from "./Form";
import I18n from "../../../common/I18n";
import { renderAlertMessage } from "../../../helpers/common";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contacts: [],
      showCreateEditForm: false,
      showEventSubscriptionForm: false,
      selectedContact: null,
      pagination: {},
      IsSubscribeInProgress: false,
      searchKey: "",
      //isSearch:false,
      filteredContacts: [],
    };
    this.tableOptions = { pagination: {}, filters: {}, sorter: {} };
  }
  componentDidMount() {
    // fetch contacts
    if (!this.props.isOrgLevel) {
      localStorage.setItem("accountId", this.props.account.id);
    }
    this.getContacts();
  }

  componentWillUnmount() {
    localStorage.removeItem("accountId");
  }

  getContacts = async () => {
    this.setState({
      loading: true,
    });
    try {
      const page = this.tableOptions.pagination.current;
      const perPage = AppConfig.perPage;
      const isOrgLevel = this.props.isOrgLevel;
      const render_type = "list";
      const accountId = this.props.account?.id ? this.props.account.id : '';
      const response = await fetchContacts({
        page,
        perPage,
        render_type,
        isOrgLevel,
        accountId
      });
      if (response.success) {
        this.setState({
          pagination: response.pagination || {},
          contacts: response.contacts || [],
          filteredContacts: response.contacts || [],
          searchKey: "",
        });
      } else {
        this.setState({
          loading: false,
          contacts: [],
          filteredContacts: [],
          pagination: {},
        });
        throw new Error(response.errors[0]);
      }
    } catch (err) {
      alertMessage(err.message, "error", 10);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  onAdd = () => {
    this.setState({
      showCreateEditForm: true,
      selectedContact: null,
    });
  };

  onEditCancel = () => {
    this.setState({
      selectedContact: null,
      showCreateEditForm: false,
    });
  };

  renderCreateOrEditForm = () => {
    const isNew = isEmpty(this.state.selectedContact);
    const accountId = this.props.account?.id ?  this.props.account.id : '';
    return (
      <BaseModal
        title={
          isNew
            ? I18n.t("configurations.contact.add")
            : I18n.t("configurations.contact.edit_contact")
        }
        onCancel={this.onEditCancel}
        width="60%"
        style={{ top: 20 }}
        maskClosable={false}
      >
        <Form
          onCancel={this.onEditCancel}
          onSave={this.onEditCompleted}
          initialValues={this.state.selectedContact}
          isOrgLevel={this.props.isOrgLevel === true}
          accountId={accountId}
        />
      </BaseModal>
    );
  };

  onEditCompleted = (message) => {
    alertMessage(message);
    this.setState(
      {
        showCreateEditForm: false,
      },
      () => {
        this.getContacts();
      }
    );
  };

  // onEdit = (id) => {
  //   const contact = this.state.contacts.find((c) => c.id === id);
  //   this.setState({
  //     selectedContact: contact,
  //     showCreateEditForm: true,
  //   });
  // };

  handleEditClick = (id)=>{
    this.setState({loading:true})
    fetchContactDetails(id).then((result)=>{
      if(result.success){
        this.setState({
          selectedContact:result.contacts ||{},
          loading: false,
          showCreateEditForm: true,
        })
      }else{
        renderAlertMessage(result.errors)
        this.setState({loading:false})
      }
    })
  }

  onDelete = async (id) => {
    this.setState({
      loading: true,
    });
    try {
      const response = await deleteContact(id);
      if (response.success) {
        alertMessage(I18n.t("messages.deleted"));
        this.getContacts();
      } else {
        throw new Error(response.errors[0]);
      }
    } catch (error) {
      alertMessage(err.message, "error", 10);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  hideSubscriptionForm = () => {
    this.setState({ showEventSubscriptionForm: false, selectedContact: null });
  };

  onSubscriptionSuccess = (message) => {
    // hide the form
    // hide subscription form
    alertMessage(message);
    this.hideSubscriptionForm();
  };

  // onSearch = (text) => {
  //   if (text.trim() === "") {
  //     this.tableOptions = {
  //       pagination: {},
  //       filters: {},
  //       sorter: {},
  //       searchText: "",
  //     };
  //   } else {
  //     this.tableOptions.searchText = text;
  //   }
  //   this.tableOptions.pagination.current = 1;
  //   this.getContacts();
  // };

  renderEventSubscriptionForm = () => {
    return (
      <BaseModal
        title={I18n.t("configurations.contact.alert")}
        onCancel={this.hideSubscriptionForm}
        width="50%"
        style={{ top: 20 }}
      >
        <EventSubscriptionForm
          onCancel={this.hideSubscriptionForm}
          initialValues={this.state.selectedContact}
          onSave={this.onSubscriptionSuccess}
        />
      </BaseModal>
    );
  };

  onClickSubscribe = (id) => {
    const contact = this.state.contacts.find((c) => c.id === id);
    this.setState({
      selectedContact: contact,
      showEventSubscriptionForm: true,
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    this.tableOptions = { pagination, filters, sorter };
    this.getContacts();
  };

  onKeyChange = (element, value) => {
    const keyword = value;
    const token = keyword.trim();
    if (token !== "") {
      const filteredResult = this.state.contacts.filter((contactList) => {
        return contactList.first_name
          .toLowerCase()
          .startsWith(token.toLowerCase());
      });
      this.setState({ filteredContacts: filteredResult });
    } else {
      this.setState({ filteredContacts: this.state.contacts });
    }

    this.setState({
      [element]: value,
    });
  };

  render() {
    const { pagination } = this.state;

    return (
      <div className="content">
        <Row className="marginBottom20">
          <Col xs={21} className="alignRight">
            <Search
              placeholder="Search Contacts"
              value={this.state.searchKey || ""}
              // onSearch={this.onSearch}
              onChange={(e) => this.onKeyChange("searchKey", e.target.value)}
              allowClear
              size="default"
              style={{ width: 250 }}
            />
          </Col>
          <Col xs={3} className="alignRight">
            <Button type="primary" onClick={this.onAdd} icon="plus">
              {I18n.t("configurations.contact.add")}
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.loading} delay={1000}>
          <Row>
            <Col>
              <ContactList
                data={this.state.filteredContacts}
                scroll = {{y:"calc(100vh - 375px)"}}
                onEdit={this.handleEditClick}
                onDelete={this.onDelete}
                onClickSubscribe={this.onClickSubscribe}
                pagination={{
                  total: pagination.total_count,
                  current: pagination.current_page,
                  pageSize: pagination.per_page,
                }}
                tableChange={this.onTableChange}
              />
            </Col>
          </Row>
        </Spin>
        {this.state.showCreateEditForm && this.renderCreateOrEditForm()}
        {this.state.showEventSubscriptionForm &&
          this.renderEventSubscriptionForm()}
      </div>
    );
  }
}
