import React from "react";

const PageWrapper = ({ children }) => {
  return (
    <div className="content-outer">
      <div className="content">{ children }</div>
    </div>
  );
};

export default PageWrapper;
