import { isEmpty } from "lodash";
import userStore from "../stores/UserStore";
import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
  buildQueryString,
  optionalPageParams,
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";

//  store the functions related to account and org level instructions
export const DriverSettlementApi = {
  fetch: ({ page = 1, perPage = AppConfig.ordersPerPage, payload }) => {
    const params = buildQueryString(payload, false);
    let url = ApiUrl(
      `v2/driver_shipment_charges?operation_code=GOR&${optionalPageParams(
        page,
        perPage,
        false
      )}${params ? `&${params}`: ''}`
    );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        reports: "driver_shipment_charges",
        pagination: "meta.pagination",
        // driverPayReports: "driver_pay_reports",
        // driverDeductions: "driver_deduction_reports",
      },
    });
  },
  export: ({payload }) => {
    const params = buildQueryString(payload, false);
    const url = ApiUrl(
      `v2/driver_shipment_charges?operation_code=GOR${params ? `&${params}`: ''}`
    );
    // const url = ApiUrl(
    //   `v2/billing_aging_reports/agent_pay_report?operation_code=GOR${params ? `&${params}`: ''}`
    // );
    return Call("get", url, {}, ParseGeneralResponse, {
      responseDataKeys: {
        file: "report",
        data:""
      },
    });
  },
  saveOrderLines: (payload) => {
    let url = ApiUrl(
      `v2/driver_shipment_charges/update_order_settlement_charges`
    );
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        reports: "driver_shipment_charges",
        // driverPayReports: "driver_pay_reports",
        // driverDeductions: "driver_deduction_reports",
      },
    });
  },
  saveAdjustments: (payload) => {
    let url = ApiUrl(
      `v2/driver_shipment_charges/add_settlement_adjustment`
    );
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        reports: "driver_shipment_charges",
        // driverPayReports: "driver_pay_reports",
        // driverDeductions: "driver_deduction_reports",
      },
    });
  },
  saveDetailedLines: (payload) => {
    let url = ApiUrl(
      `v2/driver_shipment_charges/update_order_settlement_charges`
    );
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        reports: "driver_shipment_charges",
        // driverPayReports: "driver_pay_reports",
        // driverDeductions: "driver_deduction_reports",
      },
    });
  },
  approveLines: (payload) => {
    let url = ApiUrl(
      `v2/driver_shipment_charges/approve_daily_wise_settlements`
    );
    return Call("put", url, payload, ParseGeneralResponse, {
      responseDataKeys: {
        reports: "driver_shipment_charges",
        // driverPayReports: "driver_pay_reports",
        // driverDeductions: "driver_deduction_reports",
      },
    });
  },
};
