import React, { Fragment } from 'react'
import moment from 'moment'
import AppConfig from '../../config/AppConfig'
import BaseList from '../BaseList'
import PropTypes from 'prop-types'
import { Popconfirm, Col, Icon, Popover, Row, Tooltip } from 'antd'
import I18n from '../../common/I18n'
import AddressInfo from '../common/AddressInfo'
import CustomerOrderDetails from '../orders/CustomerOrderDetails'
import DisplayTime from '../common/DisplayTime'
import ReleaseToDetails from './ReleaseToDetails'
import { ellipseText, isEmpty } from '../../common/Common'
import ShowDispatcherNotes from '../common/ShowDriverNotes'
import { dispatcherNotesApi } from '../../api/OrdersApi'
import { checkServiceExistance } from '../../helpers/common'
import EditIcon from '../common/EditIcon'
import DeleteIcon from '../common/DeleteIcon'
import OrderFieldDisplay from '../orders/OrderFieldDisplay'
import TypeOfOrder from '../orders/TypeOfOrder'
import { Link } from '../../common/UIComponents'
import { momentTime } from '../../helpers/date_functions'
class ReleasesList extends BaseList {
  constructor(props) {
    super(props)
    this.columns = []
    this.setColumns(props)
  }

  setColumns = (props) => {
    this.columns = [
      {
        key: 'customer_order_number',
        title: I18n.t('order.order_no'),
        dataIndex: 'customer_order_number',
        render: (data, record) => {
          const isTransfer = ["T"].includes(record.type_of_order);
          const isDelivery = props?.currentStop?.location?.type_of_loc && props.currentStop.location.type_of_loc === "DELIVERY" || (props.stopType === "WH" && props.stopIndex === 0);
          const textToDisplay = isTransfer ? ( isDelivery ? data + "(D)" : data + "(P)") : data; 
          return (
            <div>
              {!isEmpty(data) && (
                <CustomerOrderDetails
                  order_no={props.screenFrom ? textToDisplay : data}
                  key={data}
                  order={{
                    id: record.customer_order_id,
                    customer_order_number: data,
                  }}
                  showEdit={false}
                  // tab="releases"
                />
              )}
            </div>
          )
        },
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        // sorter: (a, b) => {
        //   return a.customer_order_number - b.customer_order_number;
        // },
        width: 115,
      },
      {
        key: "type_of_order",
        title: I18n.t("general.order_type"),
        dataIndex: "type_of_order",
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (data, record) => (
          <div style={{textAlign: "center"}}>
            <TypeOfOrder
              order={record}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </div>
        ),
      },
      // {
      //   key: 'mawb',
      //   title: I18n.t('general.mawb'),
      //   dataIndex: 'mawb',
      //   render: (data) => data,
      // },
      // {
      //   key: 'hawb',
      //   title: I18n.t('general.hawb'),
      //   dataIndex: 'hawb',
      //   render: (data) => data,
      // },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex:"hawb",
        width: 115,
        render: (text, record) => {
  
          return (
          <OrderFieldDisplay
            data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
            toDisplay="hawb"
            showTriggerEdit={false}
            showEditIcon={false}
            // tab="releases"
          />
        )},
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        key: "mawb",
        title: I18n.t("general.mawb"),
        dataIndex:"mawb",
        width: 115,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => {
          return (
          <OrderFieldDisplay
            data={{...record, id: record.customer_order_id, customer_order_number: record.customer_order_number}}
            toDisplay="mawb"
            showTriggerEdit={false}
            showEditIcon={false}
            // tab="releases"
          />
        )},
      },
      {
        key: "reference_1",
        title: `${I18n.t("order.reference")} 1`,
        dataIndex: "reference_1",
        width: 115,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_1" />
        ),
        // width: 90
      },
      {
        key: "reference_2",
        title: `${I18n.t("order.reference")} 2`,
        dataIndex: "reference_2",
        width: 115,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record) => (
          <OrderFieldDisplay data={record} toDisplay="reference_2" />
        ),
        // width: 90
      },
      {
        key: 'routing',
        title: I18n.t("order.routing"),
        dataIndex: 'routing',
        render: (data,record) => (
          <div>{record.routing|| ''}</div>
        ),
        width: 100
      },
      {
        key: 'company_name',
        title: I18n.t('general.company_name'),
        dataIndex: 'company_name',
        className: 'noWrap',
        render: (text, record) => (
          <div>
            <Popover
              title={I18n.t('releases.release_to')}
              key={`prelease_to${record.id}`}
              content={
                <div className="table-contact-info width500 fontsize12">
                <ReleaseToDetails
                  details={record}
                  key={`release_to${record.id}`}
                />
                </div>
              }
            >
              <u className='text-decoration-dashed'>{text}</u>
            </Popover>
          </div>
        ),
      },
      {
        key: "account_name",
        title: I18n.t("account.account"),
        dataIndex: "account_name",
        render: (data, record) => {
           const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        //  width: 115,
        align: "left",
        minWidth: 100,
        className: 'noWrap',
      },
      {
        key: "account_code",
        title: I18n.t("general.account_code"),
        dataIndex: "account_code",
        render: (data, record) => {
           const hasAccess = checkServiceExistance(["OPSAS", "ACNF"], "ALL");
          return (
            <div>
              {hasAccess ? (
                <Link
                  className={hasAccess ? "anchor_cursor" : ""}
                  onClick={() => {
                    if (hasAccess) {
                      this.props.navigateToAccount(record.account_code);
                    }
                  }}
                >
                  {!isEmpty(data) ? data : record.account_code}
                </Link>
              ) : !isEmpty(data) ? (
                data
              ) : (
                record.account_code
              )}
            </div>
          );
        },
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        //  width: 115,
        align: "left",
        minWidth: 100,
        className: 'noWrap',
      },
      {
        key: 'release_eta',
        title: I18n.t('general.scheduled_time'),
        dataIndex: 'scheduled_release_time',
        //sorter: true,
        sorter: (a, b) => new Date(a.scheduled_release_time) - new Date(b.scheduled_release_time),
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (data, record) => {
          const selectedWH = this.props?.selectedWH ? this.props.selectedWH : {}
          return (
            <>
            <DisplayTime
              key={record.id}
              dateTimeString={data}
              displayWithDate={true}
              isUTC={true}
              timeZoneId={record.timeZoneId}
            />
            </>
          )
        },
      },
    ]
    if (this.props.currentStatus === "exception") {
      this.columns.push({
        key: "EXCEPTION",
        title: I18n.t("general.exception_reason"),
        dataIndex: "exception_message",
        render: (text) => (
          <div>
            {!isEmpty(text) && (
              <Popover
                title={I18n.t("general.reason")}
                content={<div>{text}</div>}
                placement="topLeft"
                overlayStyle={{ width: 350 }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <span className="textRed">{ellipseText(text, 45)}</span>
              </Popover>
            )}
          </div>
        ),
      })
    }
    if (props.showStatus === true && props.currentStatus === "") {
      this.columns.push({
        key: 'status',
        title: I18n.t('general.status'),
        dataIndex: 'status',
        render: (data) => {
          return (
            <span>
              {!isEmpty(data) ? (
                <Fragment>{I18n.t(`releases.${data}`)}</Fragment>
              ) : (
                ''
              )}
            </span>
          )
        },
      })
    }

    if(props.currentStatus !== "pending"){
      this.columns.push(
        {
          key: "released_at",
          title: I18n.t("releases.released_at"),
          dataIndex: "actual_release_time",
          sorter: true,
          // sorter: (a,b) =>  new Date(a.actual_release_time) - new Date(b.actual_release_time),
          sortDirections: ["ascend", "descend", "ascend"],
          render: (data) => {
            return (
              <span>
                {!isEmpty(data) && moment(data).isValid()
                  ? moment.utc(data).format(`${AppConfig.date_format}`)
                  : ""}
              </span>
            );
          },
          width: 120,
        },
        {
          key: "released_by",
          title: I18n.t("releases.released_by"),
          dataIndex: "released_by",
          className: 'noWrap',
        }
      );
    } else {
      if (props.currentStatus === "pending" && props.filterPlaceHolder.release_type == "both") {
        this.columns.push(
          {
            key: "release_type",
            title: I18n.t("releases.release_type.label"),
            dataIndex: "release_type",
            render: (data) => {
              
              return (
                <Tooltip title={!isEmpty(data) ? I18n.t(`releases.${data}`) : ""}>
                <span>
                  { !isEmpty(data) ? (
                    <Fragment>{ I18n.t(`releases.${data}`) }</Fragment>
                  ) : (
                    ""
                  ) }
                </span>
                </Tooltip>
              );
            },
            className: 'noWrap'
          },
        );
      }
    }

    const isWHstop = props.stopType && props.stopType === "WH";
    if (this.props.showActionExceedField === true) {
      const isDispatchScreen = window.location.href.includes('routes')
      // Push the column for the "action exceed" field
      this.columns.push({
        key: 'actionExceed',
        fixed: "right",
        render: (data) => {
          const { account_limit_exceeded } = data;
          return (
            <Row type="flex" gutter={4} align="middle" justify="end">
              {account_limit_exceeded && (
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
              )}
            </Row>
          );
        },
        align: 'center',
        width: isDispatchScreen ? 20 : 100,
      });
    }
    if (props.showActions === true && !isWHstop) {
      const isDispatchScreen = window.location.href.includes('routes')

      this.columns.push({
        key: 'items',
        width: 120,
        render: (data) => {
          const {account_limit_exceeded} = data
          return (
            <Row type="flex" gutter={8} align="middle" justify="end">
              {/* { !isEmpty(data.latest_update_reason) && (
                <Col>
                  <Popover
                    title={I18n.t("general.reason")}
                    content={<div>{data.latest_update_reason}</div>}
                    placement="topLeft"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Icon type="warning" style={{ color: "red" }} />
                  </Popover>
                </Col>
              )} */}
                { account_limit_exceeded && (
                
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
              
              ) }
              {!isDispatchScreen && (
              <Col>
                <Tooltip
                  title={I18n.t('order.driver_notes')}
                  zIndex={999}>
                  <ShowDispatcherNotes
                    apiEndpoint={dispatcherNotesApi}
                    notesTitle="Driver Notes"
                    currentRecord={data}
                    key={`notes${data.id}`}
                  />
                  &nbsp;
                </Tooltip>
              </Col>
              )}

              { checkServiceExistance('ORED') && !isDispatchScreen && <Col>
                <Tooltip title={I18n.t('general.view')} zIndex={999}>
                  <div>
                    {!isEmpty(data) && (
                      <CustomerOrderDetails
                        order_no={data.customer_order_number}
                        key={`view${data.customer_order_number}`}
                        order={{
                          id: data.customer_order_id,
                          customer_order_number: data.customer_order_number,
                        }}
                        showEdit={false}
                        showIcon={true}
                        tab={"details"}
                      />
                    )}
                  </div>
                </Tooltip>
              </Col>
              }
              { !isDispatchScreen &&
                checkServiceExistance('OREU') && <Col>
                <Tooltip title={I18n.t('general.edit')}
                  zIndex={999}>
                  <EditIcon handleClick={() => this.props.handleEditClick(data)}/>
                   &nbsp;
                </Tooltip>
              </Col>
              }
              {/* {
                checkServiceExistance('OREDEL') && <Col>
                <Popconfirm
                  placement="left"
                  title={I18n.t('messages.action_confirm', {
                    action: 'delete',
                    field: 'this',
                  })}
                  onConfirm={() => this.props.deleteClick([data.id])}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title={I18n.t('general.delete')}
                  zIndex={999}>
                    <DeleteIcon/>
                    &nbsp;&nbsp;&nbsp; 
                  </Tooltip>
                </Popconfirm>
              </Col>
              } */}
              {/* { account_limit_exceeded && (
                <Tooltip title={I18n.t("order.account_limit_exceeded")} placement="left">
                  <Icon type="info-circle" style={{ color: "red" }} />
                </Tooltip>
              ) } */}
            </Row>
          )
        },
        align: 'center',
        fixed: "right",
        width: isDispatchScreen ? 20 : 100,
      })
    }
    const isReleasePage = props.screen_from === "release";
    if (!_.isEmpty(props.displayConfiguration) && isReleasePage) {
      const { userConfiguration = {} } = props.displayConfiguration;
      const { orgConfiguration = {} } = props.displayConfiguration;
      const release_list_view_columns = userConfiguration.release_list_view_columns || orgConfiguration.release_list_view_columns;
      const columns = [];
      if (
        typeof release_list_view_columns !== undefined &&
        Array.isArray(release_list_view_columns)
      ) {
        release_list_view_columns.forEach((column) => {
          const foundColumn = this.columns.find((col) => col.key === column);
          if (foundColumn) {
            columns.push(foundColumn);
          }
        });
        const actions = this.columns.find((col) => ["items", "actionExceed"].includes(col.key));
        if (actions) {
          columns.push(actions);
        }
        this.columns = columns;
      } else {
      }
    }
  }

  setAddress = (data, type) => {
    const pickUpdata = data?.pickup_location
      ? data.pickup_location.l_address
      : {}
    const pickUpAddress = (
      <Popover
        title={I18n.t('general.pickup_address')}
        content={<AddressInfo address={pickUpdata} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {pickUpdata && pickUpdata.city ? `${pickUpdata.city}` : 'NA'}
      </Popover>
    )
    const deliveryData = data?.drop_location ? data.drop_location.l_address : {}
    const dropAddress = (
      <Popover
        title={I18n.t('general.delivery_address')}
        content={<AddressInfo address={deliveryData} />}
        overlayStyle={{ width: 200 }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {deliveryData && deliveryData.city ? `${deliveryData.city}` : 'NA'}
      </Popover>
    )
    if (type === 'origin') {
      return pickUpAddress
    }
    return dropAddress
  }

  componentWillReceiveProps(nextProps) {
    this.columns = []
    this.setColumns(nextProps)
  }
}

ReleasesList.propTypes = {
  screen_from: PropTypes.string,
  showTriggerEdit: PropTypes.bool,
  resolvedAddressCallback: PropTypes.func,
}

ReleasesList.defaultProps = {
  screen_from: '',
  showTriggerEdit: false,
  resolvedAddressCallback: () => {},
}
export default ReleasesList
