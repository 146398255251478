import React from "react";
import _ from "lodash";
import { useState } from "react";
import I18n from "../../../common/I18n";
import { RearrangableTable } from "../../common/RearrangableTable";
import EndUserTitle from "../../common/EndUserTitle";
import { alertMessage } from "../../../common/Common";
import barCode1 from "../../../../assets/images/barcode1.png";

function ManifestFileList(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {
      key: 1,
      customer_order_number: 1001,
      type_of_order: "Pickup",
      hawb: "123456",
      mawb: "123456",
      account_code: "ABC123",
      account_name: "abc123",
      los: "White Glove",
      accessorials: "XYZ",
      reference_1: "700214577",
      reference_2: "123456",
      status : "DISPATCHED",
      consignee : "Bob",
      company_name : "ABC123",
      routing : "ABCD",
      notes : "Notes1",
      measurements : "1",
      comments:"This is test"
    },
    {
      key: 2,
      customer_order_number: 1002,
      type_of_order: "Delivery",
      hawb: "123456",
      mawb: "123456",
      account_code: "ABC456",
      account_name: "abc456",
      los: "Threshold",
      accessorials: "XYZ",
      reference_1: "700214577",
      reference_2: "123456",
      status : "COMPLETED",
      consignee : "Mary",
      company_name : "ABC123",
      routing : "EFGH",
      notes :"Notes2",
      measurements : "2",
      comments:"This is test"
    },
  ]);

  const [columns, setColumns] = useState([
    {
      key: "customer_order_number",
      title: I18n.t("order.order_no"),
      dataIndex: "customer_order_number",
    },
    {
      key: "hawb",
      title: I18n.t("general.hawb"),
      dataIndex: "hawb",
    },
    {
      key: "mawb",
      title: I18n.t("general.mawb"),
      dataIndex: "mawb",
    },
    {
      key: "reference_1",
      title: `${I18n.t("order.reference")} 1`,
      dataIndex: "reference_1",
    },
    {
      key: "company_name",
      title: I18n.t("general.company"),
      dataIndex: "company_name",
    },
    {
      key: "type_of_order",
      title: I18n.t("general.order_type_short_form"),
      dataIndex: "type_of_order",
    },
    {
      title: I18n.t("order.measurements"),
      key: "measurements",
      dataIndex : "measurements"
    },
    {
      title: I18n.t("general.barCode"),
      key: "bar_code",
      render: (record) => {
        return (
          <img
            src={barCode1}
            alt="vehicle"
            style={{ width: 60, height: 60 }}
          />
        );
      },
    },
    {
      key: "account_code",
      title: I18n.t("account.account_code"),
      dataIndex: "account_code",
    },
    {
        key: "notes",
        title: I18n.t("general.notes_info"),
        dataIndex: "notes",
    },
    {
      key: "account_name",
      title: I18n.t("account.account"),
      dataIndex: "account_name",
    },
    {
      key: "los",
      title: I18n.t("los.label"),
      dataIndex: "los",
    },
    {
      key: "accessorials",
      title: I18n.t("general.accessorial"),
      dataIndex: "accessorials",
    },
    {
      key: "reference_2",
      title: `${I18n.t("order.reference")} 2`,
      dataIndex: "reference_2",
    },
    {
      key: "status",
      title: I18n.t("order.status"),
      dataIndex: "status",
    },
    {
      key: "consignee",
      title: <EndUserTitle/>,
      dataIndex: "consignee",
    },
    
    {
      key: "routing",
      title: I18n.t("order.routing"),
      dataIndex: "routing",
    },
    {
      key:"comments",
      title:I18n.t("order.comments"),
      dataIndex:"comments"
    }
  ]);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );
  const [filteredColumns, setFilteredColumns] = useState(
    columns.filter((col) => selectedColumns.includes(col.key))
  );
  const [madeChanges, setMadeChanges] = useState(false);
  const configType = 'dispatch_manifest_file_columns'

  const handleColumnSelection = (selectedCols) => {
    if (selectedCols.length <= 10) {
      setSelectedColumns(selectedCols);
      setFilteredColumns(columns.filter((col) => selectedCols.includes(col.key)));
      setMadeChanges(true);
    } else {
      const currentSelectedColumns = [...selectedColumns];
      const deselectedColumn = currentSelectedColumns.find(
        (col) => !selectedCols.includes(col)
      );
  
      if (deselectedColumn) {
        const updatedSelectedCols = currentSelectedColumns.filter(
          (col) => col !== deselectedColumn
        );
  
        setSelectedColumns(updatedSelectedCols);
        setFilteredColumns(columns.filter((col) => updatedSelectedCols.includes(col.key)));
        setMadeChanges(true);
      } else {
        alertMessage("Number of columns exceeds 10. Please review", "error", 5);
      }
    }
  };

  return (
    <RearrangableTable
    columns={columns}
    filteredColumns={filteredColumns}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    data={data}
    madeChanges={madeChanges}
    selectedColumns={selectedColumns}
    setSelectedColumns={handleColumnSelection}
    setFilteredColumns={setFilteredColumns}
    setMadeChanges={setMadeChanges}
    showDragIconForSelectedColumns={false}
    controlDrag={false}
    configType={configType}
    dispContext={props.dispContext}
    showNotes = {true}
  />
  )
  
}

export default ManifestFileList;
