import {
  Call,
  ApiUrl,
  ParseGeneralResponse,
  setObjectToFormData,
} from "../api/ApiUtils";

export function fetchDbcolumn() {
  let url = ApiUrl(`v2/csv_column_mappings/mapping_columns`);

  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { FeCol: "order_mapping_columns" },
    },
    true
  );
}

export function saveSettings(data) {
  const formData = setObjectToFormData(data);
  let url = ApiUrl("v2/csv_column_mappings");
  return Call("POST", url, formData, ParseGeneralResponse, {}, true);
}
